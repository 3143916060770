import axios from 'axios'
import {HEADERS} from '../UrlAndPaths'
import {get_outlet_expense} from '../../Redux/Expense/outletExpense/outletExpenseSlice'
import store from '../../Redux/store'
import {get_outlet_expense_docNo} from '../../Redux/Expense/outletExpense/outletExpenseDocNoSlice'
// generate outlet expense doc no api call
export const outletExpenseDocNoAPICall= async ()=>{
  await axios.get("expense/generateoutletexpenseno",HEADERS)
  .then((res)=>{
    if(res.status===200){
        store.dispatch(get_outlet_expense_docNo({outletExpDocNoData:res.data}))
    }
  })
  .catch((err)=>{
    console.log(err.response);
    store.dispatch(get_outlet_expense_docNo({outletExpDocNoData:undefined}))

  })
}
// view outlet expense
export const viewOutletExpenseAPICall=async(body,setIsLoading,setErrorSnack,setSnackMsg)=>{
  if(setIsLoading!==undefined) setIsLoading(true)  
  await axios.post("expense/viewoutletExpense",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_outlet_expense({outletExpenseData:res.data}))
            if(setIsLoading!==undefined) setIsLoading(false) 
          }
    })
    .catch((err)=>{
        store.dispatch(get_outlet_expense({outletExpenseData:undefined}))
        console.log(err.response);
        switch (err.response.status) {
          case 404:
              setSnackMsg('No data available..')
            break;
          case 500:
              setSnackMsg('Server error!!')
            break;
          case 401:
              setSnackMsg('Unauthorized!!')
            break;
          case 403:
              setSnackMsg('Forbidden!!')
            break;
        
          default:
              setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
            break;
        }
        setErrorSnack(true)
        if(setIsLoading!==undefined) setIsLoading(false) 
    })
}

// add outlet expense api call
export const addOutletExpenseAPICall=async(
    body,
    updateListener,
    setSuccessSnackbar,
    setErrorSnackbar,
    setSnackMsg,
    setOutletExpense,setLoadingForm
    )=>{
      setLoadingForm!==undefined && setLoadingForm(true)
    await axios.post("expense/addoutLetExpense",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            setSuccessSnackbar(true)
            setSnackMsg('Added Successfully')
            updateListener()
            setOutletExpense(false)
            setLoadingForm!==undefined && setLoadingForm(false)

        }
    })
    .catch((err)=>{
        setLoadingForm!==undefined && setLoadingForm(false)
        setErrorSnackbar(true)
        if (err.response === undefined) {
            setSnackMsg("Network Connection error!!");
          }
          if (err.response.status === 409) {
            setSnackMsg("Already Exist");
          }
    
          if (err.response.status === 401) {
            setSnackMsg("Unauthorized");
          }
          if (err.response.status === 403) {
            setSnackMsg("Forbidden");
          }
        console.log(err.response);
    })
}

// edit outlet expense
export const editOutletExpenseAPICall= async (
  body,
  updateListener,
  setEditDialog,
  setSuccessSnackbar,
  setErrorSnackbar,
  setSnackMsg,setLoadingForm
  )=>{
    setLoadingForm!==undefined && setLoadingForm(true)
  await axios.put("expense/editOutexpense",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      setSuccessSnackbar(true)
      setSnackMsg('Success')
      updateListener()
      setEditDialog(false)
      setLoadingForm!==undefined && setLoadingForm(false)

    }
  })
  .catch((err)=>{
    setLoadingForm!==undefined && setLoadingForm(false)

    setErrorSnackbar(true)
 
     if (err.response.status === 409) {
      setSnackMsg("Already Exist");
    }

    else if (err.response.status === 401) {
      setSnackMsg("Unauthorized");
    }
    else if (err.response.status === 403) {
      setSnackMsg("Forbidden");
    }
    else if (err.response.status === 500) {
      setSnackMsg("Internal server error!!");
    }
    else{
      setSnackMsg(err.response.data);
    }
  })
}