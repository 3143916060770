import { createSlice } from "@reduxjs/toolkit";

export const stockOrderListSlice=createSlice({
    name:"stockOrderListSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        get_stock_order_list:(state,action)=>{
            state.value=action.payload.stockOrderData
        }
    }
})

export const {get_stock_order_list}=stockOrderListSlice.actions;
export default stockOrderListSlice.reducer