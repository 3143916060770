import { Dialog, MenuItem, Select,TextField,Autocomplete } from '@mui/material';
import React, { useState } from 'react'
import CountryWithState from "../../../../Assets/JSON/countryStates.json";
import Currencies from "../../../../Assets/JSON/currencies.json";
import Banks from "../../../../Assets/JSON/banknames.json";
import GSTStateCodes from "../../../../Assets/JSON/gstStateCodes.json";
import { addCreditLimitAPICall, addNewSupplierAPICall, viewCreditLimitAPICall } from '../../../../API/Purchase Manager/supplierAPI';
import SuccessSnackbar from '../../../Single Components/SnackBars/SuccessSnackbar';
import ErrorSnackbar from '../../../Single Components/SnackBars/ErrorSnackbar';
import { useEffect } from 'react';
import { extractStatesFromCountry } from "../../../../Js/generalFunctions";
import { useSelector } from 'react-redux';

export const SupplierAdd = (props) => {
  const { isDialog, closeDialog,updateListenerProp} = props;
  // view credit limit
  const creditLimitList=useSelector((state)=>state.creditLimitSlice.value)

    //*Current Country
  let currentCountry = localStorage.getItem("country");
  let countryMeta = localStorage.getItem("COUNTRY_META");
     //Add Supplier Dialog States
  const addSupplierDialogFormInitialState = {
    supplierName: "",
    mobile: "",
    email: "",
    currency: "0",
    creditLimit: "0",
    taxNo: "",
    country: currentCountry,
    state: "0",
    stateCode: "0",
    openingBalance: "",
    address: "",
    bankName: "0",
    accountNo: "",
    bankCode: "",
  };
  const [addSupplierDialogForm, setAddSupplierDialogForm] = useState(
    addSupplierDialogFormInitialState
  );
  const [addCreditLimitDialog,setAddCreditLimitDialog]=useState(false)
//States of a particular country
const [states, setStates] = useState([]);

const addCreditLimitInitialForm={
  creditLimitAmount:'',
  creditLimitDays:''
}
const [addCreditLimitForm,setAddCreditLimitForm]=useState(addCreditLimitInitialForm)

//Snackbar States
const [successSnackOpen, setSuccessSnackOpen] = useState(false);
const [errorSnackOpen, setErrorSnackOpen] = useState(false);
const [snackMsg, setSnackMsg] = useState("oops!!");
 //Update Listener States
  const [isModify, setIsModify] = useState(false);

 //*Update Listener
 const updateListener = () => {
    setIsModify(!isModify);
  };
 //Close SuccessSnackbar
 const closeSuccessSnackbar = () => {
    setSuccessSnackOpen(false);
  };
  //Close ErrorSnackbar
  const closeErrorSnackbar = () => {
    setErrorSnackOpen(false);
  };

  //*onChange Fn
  const getAddSupplierFormData = (key) => (e) => {
    const { value } = e.target;
    if (key === "supplierName") {
      setAddSupplierDialogForm({
        ...addSupplierDialogForm,
        supplierName: value,
      });
    }
    if (key === "mobile") {
      setAddSupplierDialogForm({ ...addSupplierDialogForm, mobile: value });
    }
    if (key === "email") {
      setAddSupplierDialogForm({ ...addSupplierDialogForm, email: value });
    }
    if (key === "currency") {
      setAddSupplierDialogForm({ ...addSupplierDialogForm, currency: value });
    }
    if (key === "creditLimit") {
      setAddSupplierDialogForm({
        ...addSupplierDialogForm,
        creditLimit: value,
      });
    }
    if (key === "taxNo") {
      setAddSupplierDialogForm({ ...addSupplierDialogForm, taxNo: value });
    }
    if (key === "country") {
      setAddSupplierDialogForm({ ...addSupplierDialogForm, country: value });
    }
    if (key === "state") {
      setAddSupplierDialogForm({ ...addSupplierDialogForm, state: value });
    }
    if (key === "stateCode") {
      setAddSupplierDialogForm({ ...addSupplierDialogForm, stateCode: value });
    }
    if (key === "openingBalance") {
      setAddSupplierDialogForm({
        ...addSupplierDialogForm,
        openingBalance: parseFloat(value),
      });
    }
    if (key === "address") {
      setAddSupplierDialogForm({ ...addSupplierDialogForm, address: value });
    }
    if (key === "bankName") {
      setAddSupplierDialogForm({ ...addSupplierDialogForm, bankName: value });
    }
    if (key === "accountNo") {
      setAddSupplierDialogForm({ ...addSupplierDialogForm, accountNo: value });
    }
    if (key === "bankCode") {
      setAddSupplierDialogForm({ ...addSupplierDialogForm, bankCode: value });
    }
  };

    // credit limit onchange functions
    const getCreditAmount=(e)=>{
      setAddCreditLimitForm({
        ...addCreditLimitForm,creditLimitAmount:e.target.value
      })
    }
    const getCreditDays=(e)=>{
      setAddCreditLimitForm({
        ...addCreditLimitForm,creditLimitDays:e.target.value
      })
    }
  

  //*UseEffects
  useEffect(()=>{
    viewCreditLimitAPICall()
  },[isModify])

  //Extract State from country list
  useEffect(() => {
    let extractedState = extractStatesFromCountry(
      addSupplierDialogForm.country
    );
    setStates(extractedState);
    // console.log(extractedState);
  }, [addSupplierDialogForm.country]);

  //Assign Local Currency to Currency
  useEffect(() => {
    let parsedCountryMeta = JSON.parse(countryMeta);
    setAddSupplierDialogForm({
      ...addSupplierDialogForm,
      currency: parsedCountryMeta?.currency,
    });
  }, []);

  //*onClick fns
  //add Supplier final Submit
  const newSupplierBody = {
    supplierName: addSupplierDialogForm.supplierName,
    emailAddress: addSupplierDialogForm.email,
    creditLimit: addSupplierDialogForm.creditLimit,
    country: addSupplierDialogForm.country,
    statecode: addSupplierDialogForm.stateCode,
    address: addSupplierDialogForm.address,
    currency: addSupplierDialogForm.currency,
    gstIn: addSupplierDialogForm.taxNo,
    state: addSupplierDialogForm.state,
    bankName: addSupplierDialogForm.bankName,
    accountNo: addSupplierDialogForm.accountNo,
    ifscCode: addSupplierDialogForm.bankCode,
    mobile:addSupplierDialogForm.mobile,
    openingBalance:addSupplierDialogForm.openingBalance
  };
  const submitNewSupplier = () => {
    const {
      supplierName,
      mobile,
      email,
      currency,
      creditLimit,
      taxNo,
      country,
      state,
      stateCode,
      openingBalance,
      address,
      bankName,
      accountNo,
      bankCode,
    } = addSupplierDialogForm;
    if (
      supplierName !== "" &&
      mobile !== "" &&
      address !== ""
      )
        {
          addNewSupplierAPICall(
            newSupplierBody,
            setSuccessSnackOpen,
            setErrorSnackOpen,
            setSnackMsg,
            updateListenerProp,
            clearStates
          );
        }
        else{
          alert('Please fill Supplier name, Mobile and address !!!')
        }

    
      
  };

  //Clear States
  const clearStates = () => {
    setAddSupplierDialogForm(addSupplierDialogFormInitialState);
    closeDialog()
    // setAddSupplierDialog(false);
  };

  const bodyToApi={
    enterAmount:parseFloat(addCreditLimitForm?.creditLimitAmount),
    enterDays:parseFloat(addCreditLimitForm?.creditLimitDays)
}
// add credit limit
const addCreditLimitSubmit =()=>{
  addCreditLimitAPICall(
   bodyToApi,
   updateListener,
   setAddCreditLimitDialog
  )
}

  return (
    <>
    <div className="addSupplierDialog">
            <h3>Add Supplier</h3>
            <hr />
            <div className="addSupplierDialog-input-container">
              <div className="global-single-input add-supplier-input">
                <p>Supplier Name</p>
                <input
                  placeholder="Enter Supplier name..."
                  value={addSupplierDialogForm.supplierName}
                  onChange={getAddSupplierFormData("supplierName")}
                />
              </div>
              <div className="global-single-input add-supplier-input">
                <p>Mobile</p>
                <input
                  placeholder="Enter mobile number..."
                  value={addSupplierDialogForm.mobile}
                  onChange={getAddSupplierFormData("mobile")} maxLength="15"
                />
              </div>
              <div className="global-single-input add-supplier-input">
                <p>Email Address</p>
                <input
                  placeholder="Enter email address..."
                  value={addSupplierDialogForm.email}
                  onChange={getAddSupplierFormData("email")}
                />
              </div>
              <div className="global-single-input add-supplier-input">
                <p>Currency</p>
                <Select
                  value={addSupplierDialogForm.currency}
                  onChange={getAddSupplierFormData("currency")}
                >
                  <MenuItem value="0">-select currency-</MenuItem>
                  {Currencies?.map((currency, i) => (
                    <MenuItem
                      value={currency?.cc}
                      key={i}
                    >{`${currency?.cc}-${currency?.name}`}</MenuItem>
                  ))}
                </Select>
                
              </div>
              <div className="global-single-input add-supplier-input auto-complete">
                <p>Credit Limit</p>
                {/* <Select
                  value={addSupplierDialogForm.creditLimit}
                  onChange={getAddSupplierFormData("creditLimit")}
                >
                  <MenuItem value="0">-select credit limit-</MenuItem>
                  <MenuItem value="5">5 days</MenuItem>
                  <MenuItem value="15">15 days</MenuItem>
                </Select> */}
                  <Autocomplete
                  sx={{width:'100%'}}
                  options={creditLimitList||[""]}
                  getOptionLabel={(option)=>
                    option?.enterDays
                  }
                  renderInput={(params) => <TextField {...params}
                  placeholder="Select Credit Limit"
                  />
                
                }
                  onChange={(e,newValue)=>
                   setAddSupplierDialogForm({...addSupplierDialogForm,creditLimit:newValue?.enterDays})
                  }
                  
                />

                <span onClick={()=>setAddCreditLimitDialog(true)} className="suffix-text-blue">Add Credit Limit</span>
              </div>
              <div className="global-single-input add-supplier-input">
                <p>{currentCountry === "india" ? "GST IN" : "VAT"}</p>
                <input
                  placeholder="Enter tax number..."
                  value={addSupplierDialogForm.taxNo}
                  onChange={getAddSupplierFormData("taxNo")}
                />
              </div>
              <div className="global-single-input add-supplier-input ">
                <p>Country</p>
                 <Select
                  value={addSupplierDialogForm.country}
                  onChange={getAddSupplierFormData("country")}
                >
                  <MenuItem value="0">-select a country-</MenuItem>
                  {CountryWithState?.countries?.map((item, i) => (
                    <MenuItem value={item?.country?.toLowerCase()} key={i}>
                      {item?.country}
                    </MenuItem>
                  ))}
                </Select> 
             
              </div>
              <div className="global-single-input add-supplier-input auto-complete">
                <p>State</p>
                {/* <Select
                  value={addSupplierDialogForm.state}
                  onChange={getAddSupplierFormData("state")}
                >
                  <MenuItem value="0">-select state-</MenuItem>
                  {states[0]?.states?.map((state, i) => (
                    <MenuItem value={state} key={i}>
                      {state}
                    </MenuItem>
                  ))}
                </Select> */}
                <Autocomplete
                  sx={{width:'100%'}}
                  options={states[0]?.states||[""]}
                  getOptionLabel={(option)=>
                    option
                  }
                  renderInput={(params) => <TextField {...params}
                  placeholder="Select state"
                  />
                
                }
                  onChange={(e,newValue)=>
                   setAddSupplierDialogForm({...addSupplierDialogForm,state:newValue})
                  }
                  
                />
              </div>
              {currentCountry==='india'&&<div className="global-single-input add-supplier-input auto-complete">
                <p>State Code</p>
                {/* <Select
                  value={addSupplierDialogForm.stateCode}
                  onChange={getAddSupplierFormData("stateCode")}
                >
                  <MenuItem value="0">-select state code-</MenuItem>
                  {GSTStateCodes?.map((item,i)=>(
                    <MenuItem value={item?.code} key={i}>{`${item?.tinNo}-${item?.code}`}</MenuItem>
                  ))}
                </Select> */}
                <Autocomplete
                  sx={{width:'100%'}}
                  options={GSTStateCodes||[""]}
                  getOptionLabel={(option)=>
                    `${option?.tinNo}-${option?.code}`
                  }
                  renderInput={(params) => <TextField {...params}
                  placeholder="Select state  code"
                  />
                
                }
                  onChange={(e,newValue)=>
                   setAddSupplierDialogForm({...addSupplierDialogForm,stateCode:newValue?.code})
                  }
                  
                />

              </div>}
              <div className="global-single-input add-supplier-input">
                <p>Opening Balance</p>
                <input
                  placeholder="Enter opening balance..."
                  value={isNaN(addSupplierDialogForm.openingBalance)?'':addSupplierDialogForm.openingBalance}
                  onChange={getAddSupplierFormData("openingBalance")}
                />
              </div>
              <div className="global-single-input add-supplier-input">
                <p>Address</p>
                <textarea
                  rows={4}
                  placeholder="Enter supplier address..."
                  value={addSupplierDialogForm.address}
                  onChange={getAddSupplierFormData("address")}
                />
              </div>
            </div>
            <hr className="add-supplier-divider" />
            <h3>Bank Details</h3>
            <div className="addSupplierDialog-input-container">
              <div className="global-single-input add-supplier-input auto-complete">
                <p>Bank Name</p>
                {/* <Select
                  value={addSupplierDialogForm.bankName}
                  onChange={getAddSupplierFormData("bankName")}
                >
                  <MenuItem value="0">-select a bank-</MenuItem>
                  {Banks?.map((bank, i) => (
                    <MenuItem key={i} value={bank?.name}>
                      {bank?.name}
                    </MenuItem>
                  ))}
                </Select> */}
                  <Autocomplete
                  sx={{width:'100%'}}
                  options={Banks||[""]}
                  getOptionLabel={(option)=>
                    option?.name
                  }
                  renderInput={(params) => <TextField {...params}
                  placeholder="Select Bank"
                  />
                
                }
                  onChange={(e,newValue)=>
                   setAddSupplierDialogForm({...addSupplierDialogForm,bankName:newValue?.name})
                  }
                  
                />
              </div>
              <div className="global-single-input add-supplier-input">
                <p>Account Number</p>
                <input
                  placeholder="Enter account number..."
                  value={addSupplierDialogForm.accountNo}
                  onChange={getAddSupplierFormData("accountNo")}
                />
              </div>
              <div className="global-single-input add-supplier-input">
                <p>{currentCountry === "india" ? "IFS Code" : "IBAN"}</p>
                <input
                  placeholder="Enter bank code..."
                  value={addSupplierDialogForm.bankCode}
                  onChange={getAddSupplierFormData("bankCode")}
                />
              </div>
            </div>
            <div className="add-supplier-button-container">
              <button
                onClick={clearStates}
                className="btn btn-secondary-outlined"
              >
                Cancel
              </button>
              <button className="btn btn-primary" onClick={submitNewSupplier}>
                Submit
              </button>
            </div>
          </div>

            {/* add credit limit dialog */}
        <Dialog open={addCreditLimitDialog} maxWidth="lg"  onKeyDown={(e)=>e.key === "Escape" && setAddCreditLimitDialog(false)}>
        <div className="supplier-add-credit-limit-container">
          <h3>Add Credit Limit</h3>
          <hr/>
          <div className="add-credit-limit-input-container">
             <div className="global-single-input add-credit-limit-input">
                <p>Enter Amount</p>
                <input
                 value={addCreditLimitForm?.creditLimitAmount}
                 onChange={getCreditAmount}
                />
             </div>
             <div className="global-single-input add-credit-limit-input">
                <p>Enter Days</p>
                <input
                 value={addCreditLimitForm?.creditLimitDays}
                 onChange={getCreditDays}
                />
             </div>

          </div>
          <div className="add-credit-btn-container">
           <button onClick={()=>setAddCreditLimitDialog(false)} className="btn btn-secondary-outlined">Cancel</button>
           <button onClick={addCreditLimitSubmit} className="btn btn-secondary">Submit</button>
          </div>
        </div>
     </Dialog>

     <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
     </>
  )
}
