import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import { companyProfileInfoForUser } from '../../../../../API/Settings/Company Settings/companySettingsAPI';
import { viewAllCurrencyUpdated } from '../../../../../API/Settings/Currency/CurrencyAPI';
import { filterObjFromList } from '../../../../../Js/generalFunctions';
import { PurchaseReportNewAPI } from './purchaseReportNewAPI';
import { getCurrentTime } from '../../../../../Js/Date';
import { setFilterActive } from '../../../../Single Components/listSlice/filterCategorySlice';
import store from '../../../../../Redux/store';

const PurchaseReportNew = () => {
    let navigate = useNavigate();
    const [symbol, setSymbol] = useState(null);
    const [FormDataInfo, setFormDataInfo] = useState([])
    const [selectedBranch, setSelectedBranch] = useState(null);
    const filtereDataOptions = useSelector((state) => state.listCategory);

    const decimalPosition = localStorage.getItem("decimalPosition");

    const currencyListUpdated = useSelector(
        (state) => state.viewUpdatedCurrency.value
    );

    const purchaseList = useSelector((state=>state?. purchaseRport?.purhaseReportNwList))

     console.log(purchaseList);
    const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue
    );

    const handleBranchSelect = (selectedBranch) => {
        setSelectedBranch(selectedBranch); 
      
      };
    const handleDataFromChild = (data) => {
        setFormDataInfo(data)
    };
    const handleRow = (row) => {
        store.dispatch( setFilterActive(window.location.pathname))
        localStorage.setItem("singleViewId",row._id)
        localStorage.setItem("name",row.itemName)
        localStorage.setItem("fromDate", FormDataInfo?.fromDate);
        localStorage.setItem("toDate", FormDataInfo?.toDate);
        localStorage.setItem("branch", FormDataInfo?.branchList?.length === 0 ? '' : FormDataInfo?.branchList[0])
        navigate("/userdashboard/purchasemanager/PurchaseReport/PurchaseReportNew/SingleView")
    }

    useEffect(() => {
        let currencyObj = filterObjFromList(
            "_id",
            currencyListUpdated,
            "currency",
            companyProfileData
        );
        // console.log(currencyObj);
        setSymbol(currencyObj?.symbol);
    }, [companyProfileData, currencyListUpdated]);

    useEffect(() => {
        viewAllCurrencyUpdated({ status: 0 });
        companyProfileInfoForUser();
    }, []);

useEffect(()=>{
    if(FormDataInfo?.length!==0){ let body ={
        fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} ${getCurrentTime()}`,
        endDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} ${getCurrentTime()}`,
        branchId: FormDataInfo?.branchList?.length === 0 ? '' : FormDataInfo?.branchList[0],
        search:FormDataInfo?.search,
    }
    let loadData=window.location.pathname===filtereDataOptions?.filterActive
    !loadData &&  PurchaseReportNewAPI(body);
  }
    },[FormDataInfo]);
   

    return (
        <div className="global-page-parent-container" style={{ margin: "0" }}>
            <div className="global-white-bg-container">
                <div className="justify-space-between" >
                    <div className="sharp-arrow" style={{ width: "125Px" }}> <p>Purchase Report</p></div>
                    <div>
                        <CategorySearchandFilter
                            statusOptions={['PURCHASE ORDER', 'DONE', 'CANCEL']}
                            onData={handleDataFromChild}
                            onBranchSelect={handleBranchSelect}
                        />

                    </div>
                </div>
                <h1 style={{ margin: "1px", fontSize: "large" }}>Purchase Report</h1>

                <div className="global-report-table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th >UOM</th>
                                <th >Opening Qty</th>
                                <th >Purchase Qty</th>
                                <th >Purchase Return Qty</th>
                                <th >Total Quatity</th>
                                <th >Average Unit Cost</th>
                                <th >Average Total Value</th>
                               
                            </tr>
                        </thead>
                        <tbody>
                            {purchaseList?.map((item,index)=>(
                            <tr key={index} onClick={() => handleRow(item)}>
                                <td style={{ textAlignLast: "start", paddingLeft: "10px" }}>{item.itemName}</td>
                                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.uom}</td>
                                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.openingQty?.toFixed(decimalPosition)}</td>
                                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.purchaseQty?.toFixed(decimalPosition)}</td>
                                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.purchaseRet?.toFixed(decimalPosition)}</td>
                                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.totalQuantity?.toFixed(decimalPosition)}</td>
                                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{symbol}&nbsp;{item.cost?.toFixed(decimalPosition)}</td>
                                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{symbol}&nbsp;{item.totalValue?.toFixed(decimalPosition)}</td>
                            </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan={7}>Total</th>
                                <th >{symbol}&nbsp;{purchaseList?.reduce((acc, row) => acc + row.totalValue, 0)?.toFixed(decimalPosition)}</th>
                              
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>

    )
}

export default PurchaseReportNew