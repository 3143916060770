import axios from "axios";
import { HEADERS } from "../UrlAndPaths"
import store from "../../Redux/store";
import { get_MultiCurrencyConfiguration, get_costing_method, get_discount_configuration, get_tax_configuration } from "../../Redux/Prchase Manager/Configuration/ConfigurationSlice";
import { get_purchse_tax_list } from "../../Redux/Prchase Manager/Configuration/PurchaseTaxListSlice";


// costing method
export const addCostingMethodAPICall=(body,updateListner,setState)=>{
    axios.post("purchase/costingMethod",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            setState((prevState)=>({...prevState,message:"Updated",success:true}))
            updateListner()
        }
    })

    .catch((err)=>{
        setState((prevState)=>({...prevState,message:err?.response?.data,error:true}))
    })
}
// get osting method data
export const getCostingMethodDataAPICall=()=>{
    axios.get("purchase/costingMethodView",{},HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_costing_method({constingMethodData:res.data}))
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}

// discount configuration
export const addDiscountConfiguration=(body,updateListner)=>{
    axios.post("purchase/discountConfiguration",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            updateListner()
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}
export const viewDiscountConfiguration=()=>{
    axios.post("purchase/viewDiscountConfiguration",{},HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_discount_configuration({discountConfigData:res.data}))
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}

// tax configuration
// view purchase Tax list api call
export const viewPurchseTaxApiCall=()=>{
    axios.post("purchase/viewPurchaseTaxList",{},HEADERS)
    .then((res)=>{
        if(res.status===200){
          store.dispatch(get_purchse_tax_list({purchseTaxData:res.data}))
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}
export const addTaxConfoguration=(body,updateListner)=>{
    axios.post("purchase/purchaseTaxConfiguration",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            updateListner()
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}

export const viewTaxConfiguration=()=>{
    axios.post("purchase/viewPurchaseTaxConfiguration",{},HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_tax_configuration({taxConfigData:res.data}))
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}


//MultiCurrencyApiCall
export const MultiCurrencyApiCall =(body,updateListner,setState)=>{
    axios.post("purchase/currencyConfiguration",body,HEADERS).then((res)=>{
        if(res.status===200){
            updateListner()
            setState((prevState)=>({...prevState,message:"Updated",success:true}))
        }
    }).catch((err)=>{
        setState((prevState)=>({...prevState,message:err?.response?.data,error:true}))
    })

}

//viewMultiCurrencyConfiguration
export const viewMultiCurrencyConfiguration=()=>{
    axios.get("purchase/viewCurrencyConfiguration",HEADERS).then((res)=>{
        if(res.status === 200){
            store.dispatch(get_MultiCurrencyConfiguration({MultiConfiguration:res.data}))
        }
    }).catch((err)=>{

    })
}