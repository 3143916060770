import {createSlice} from '@reduxjs/toolkit'

export const singleViewPurchaseOrderSlice=createSlice({
 name:'singleViewPurchaseOrder',
 initialState:{
    value:undefined
 },
 reducers:{
    get_purchaseOrder_single_view:(state,action)=>{
        state.value=action.payload.purchaseOrderSingleViewData
    }
 }
})
export const {get_purchaseOrder_single_view} = singleViewPurchaseOrderSlice.actions;
export default singleViewPurchaseOrderSlice.reducer