import { createSlice } from "@reduxjs/toolkit";

export const alterationIdSlice=createSlice({
    name:"alterationIdSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        update_alteration_id:(state,action)=>{
            state.value=action.payload.alterationId
        }
    }
})

export const {update_alteration_id}=alterationIdSlice.actions
export default alterationIdSlice.reducer