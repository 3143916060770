import { createSlice } from "@reduxjs/toolkit";

export const salesOrderSlice = createSlice({
  name: "salesOrderSlice",
  initialState: {
    apiResList: undefined,
    rowData:undefined,
    generateName:undefined,
    history:undefined
  },
  reducers: {
    get_sales_order_res_list: (state, action) => {
      state.apiResList = action.payload.salesOrderData;
    },
    get_sales_order_row_data_list: (state, action) => {
      state.rowData = action.payload.salesOrderSingleData;
    },
    generate_sales_order_name: (state, action) => {
      state.generateName = action.payload.salesOrderQuotname;
    },
    get_sales_order_history:(state,action)=>{
      state.history=action.payload.salesOrderHistory
    }
  },
});

export const {get_sales_order_res_list,
    get_sales_order_row_data_list,
    generate_sales_order_name,
    get_sales_order_history
} = salesOrderSlice.actions;

export default salesOrderSlice.reducer;
