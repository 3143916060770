import { createSlice } from "@reduxjs/toolkit";

export const modelsFromUserSideSlice=createSlice({
    name:'modelsFromUserSideSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_models_from_userSide:(state,action)=>{
            state.value=action.payload.modelsForUser
        }
    }
})

export const {update_models_from_userSide}=modelsFromUserSideSlice.actions
export default modelsFromUserSideSlice.reducer