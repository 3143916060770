import { createSlice } from "@reduxjs/toolkit";

export const cuffModelSlice=createSlice({
    name:"cuffModelSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        update_cuff_model:(state,action)=>{
            state.value=action.payload.cuffModel
        }
    }
})

export const {update_cuff_model}=cuffModelSlice.actions
export default cuffModelSlice.reducer