import { IconButton, Skeleton } from "@mui/material";
import React from "react";
import InputBox from "./InputBox";

const TableBox = ({
  actionBtnIconDelete,
  actionBtnIconView,
  actionBtnIconEdit,
  isLoading,
  renderData,
  headings,
  actionButton,
  isNo,
  viewBtnOnClick,
  deleteBtnOnClick,
  actionBtnCheckBox
}) => {
  return (
    <div
      style={{ margin: "0px", maxHeight: "40vh",width:'100%' }}
      className="global-table-container "
    >
      <table className="global-table ">
        <thead>
          <tr>
            {headings.map((heading, i) => (
              <th key={i}>{heading} </th>
            ))}
            {actionButton && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <>
              {["", "", ""].map((item, index) => (
                <tr key={index}>
                  <td colSpan={8}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={30}
                    />
                  </td>
                </tr>
              ))}
            </>
          ) : renderData.length !== 0 ? (
            renderData.map((r, i) => (
              <React.Fragment key={i}>
                <tr className="data-table-row-head">
                  {isNo && <td>{i + 1}</td>}

                  {Object.values(r).map((data, index) => (
                    <td key={index}>{data}</td>
                  ))}
                  {actionButton && (
                    <td>
                      {actionBtnIconEdit && <IconButton>
                        <i class="bi bi-pencil-square edit-icon"></i>
                      </IconButton>}
                      {actionBtnIconView  && (
                        <IconButton
                        // onClick={viewBtnOnClick(i)}
                        >
                          <i class="bi bi-eye visibility-icon"> </i>
                        </IconButton>
                      )}
                      {actionBtnIconDelete  && (
                        <IconButton
                        // onClick={deleteBtnOnClick(i)}
                        >
                          <i class="bi bi-trash3 delete-icon"></i>
                        </IconButton>
                      )}
                      {actionBtnCheckBox  && (
                       <input type={'checkbox'}  />
                      )}
                    </td>
                  )}
                </tr>
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td style={{ textAlign: "center" }} colSpan={8}>
                No Data
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableBox;
