import { createSlice } from "@reduxjs/toolkit";

export const singleDraftSlice=createSlice({
    name:'singleDraftSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_singleDraft:(state,action)=>{
            state.value=action.payload.singleDraft
        }
    }
})

export const {update_singleDraft}=singleDraftSlice.actions
export default singleDraftSlice.reducer