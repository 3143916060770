import { createSlice } from "@reduxjs/toolkit";

export const employeeCompanyInfoFormSlice=createSlice({
    name:'employeeCompanyInfoFormSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_employee_companyInfo_form:(state,action)=>{
            state.value=action.payload.employeeCompanyInfoForm
        }
    }
})

export const {update_employee_companyInfo_form}=employeeCompanyInfoFormSlice.actions
export default employeeCompanyInfoFormSlice.reducer