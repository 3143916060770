import { createSlice } from "@reduxjs/toolkit";

export const walletOfCustomerSlice = createSlice({
  name: "walletOfCustomerSlice",
  initialState: {
    value: undefined,
  },
  reducers: {
    update_wallet_of_customer: (state, action) => {
      state.value = action.payload.walletOfCustomer;
    },
  },
});

export const { update_wallet_of_customer } = walletOfCustomerSlice.actions;
export default walletOfCustomerSlice.reducer;
