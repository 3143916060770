import axios from 'axios'
import {HEADERS} from '../UrlAndPaths'
import {get_daily_cash_card_summary} from '../../Redux/Report/dailyCashCardSummarySlice'
import store from '../../Redux/store'

// export const dailyCashCardAPICall= async (body) =>{
//     await axios.post("report/dailycashcardreport",body,HEADERS)
//     .then((res)=>{
//         if(res.status===200){
//            store.dispatch(get_daily_cash_card_summary({dailyCashCardData:res.data})) 
//         }
//     })
//     .catch((err)=>{
//         console.log(err.response);
//         store.dispatch(get_daily_cash_card_summary({dailyCashCardData:undefined}))
//     })
// }

export const dailyCashCardAPICall= async (body,setLoadingForm) =>{
    setLoadingForm!==undefined && setLoadingForm(true)
    await axios.post("report/DAILCASHCARD",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
           setLoadingForm!==undefined && setLoadingForm(false)
           store.dispatch(get_daily_cash_card_summary({dailyCashCardData:res.data})) 
        }
    })
    .catch((err)=>{
        console.log(err.response);
        setLoadingForm!==undefined && setLoadingForm(false)
        store.dispatch(get_daily_cash_card_summary({dailyCashCardData:undefined}))
    })
}
