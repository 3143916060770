import React, { useEffect } from "react";
import {
  Dialog,
  IconButton,
  Divider,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import "../../css/Single Components/sizeChart.css";
import CloseIcon from "@mui/icons-material/Close";
import BrandSize from "../../Assets/JSON/brandSize.json";
import UnitsSize from "../../Assets/JSON/units.json";
import OneToHundred from "../../Assets/JSON/oneTohundredNos.json";
export const SizeChart = (props) => {
  let currentCountry = localStorage.getItem("country");
  const {
    open,
    type,
    closeDialog,
    radioChange,
    brandSize,
    customLabel,
    sizeChartForm,
    onChangeSizeChartForm,
    onAddNewSize,
    sizeChartData,
  } = props;
  useEffect(() => {
    currentCountry = localStorage.getItem("country");
  }, []);

  return (
    <>
      <Dialog open={open} maxWidth="lg">
        <div className="size-chart-container">
          <div className="size-chart-top-container">
            <p>Add Size Chart</p>
            <div
              onClick={closeDialog}
              className="size-chart-top-container-close-icon"
            >
              <CloseIcon />
            </div>
          </div>
          <hr />
          <div className="size-chart-second-container">
            <div className="global-radio-button">
              <input
                value="brandSize"
                type="radio"
                id="brandSize"
                name="SizeChart"
                onChange={radioChange}
                defaultChecked
              />
              <label htmlFor="brandSize">Brand Size</label>
              <input
                value="unit"
                type="radio"
                id="unit"
                name="SizeChart"
                onChange={radioChange}
              />
              <label htmlFor="unit">Unit</label>
            </div>

            <div className="size-chart-second-input-container">
              {brandSize === "brandSize" ? (
                <div className="global-single-input">
                  <p>BrandSize</p>
                  <Select
                    value={sizeChartForm?.brand}
                    onChange={onChangeSizeChartForm("brand")}
                  >
                    <MenuItem value="0">-select size-</MenuItem>
                    {BrandSize?.map((r) => (
                      <MenuItem value={r?.name}>{r?.name}</MenuItem>
                    ))}
                  </Select>
                </div>
              ) : brandSize === "unit" ? (
                <div className="global-single-input">
                  <p>Unit</p>
                  <Select
                    value={sizeChartForm?.unit}
                    onChange={onChangeSizeChartForm("unit")}
                  >
                    <MenuItem value="0">-select unit-</MenuItem>
                    {UnitsSize?.map((unit, i) => (
                      <MenuItem key={i} value={unit?.name}>
                        {unit?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              ) : undefined}
              <div className="global-single-input size-chart-size-input">
                <p>Size</p>
                <Select
                  value={sizeChartForm?.size}
                  onChange={onChangeSizeChartForm("size")}
                >
                  {OneToHundred?.map((r, i) => (
                    <MenuItem value={r.no}>{r.no}</MenuItem>
                  ))}
                </Select>
              </div>
              <div className="global-single-input">
                <p>{customLabel}</p>
                <input
                  placeholder={`Enter ${customLabel}...`}
                  value={sizeChartForm?.reorderQtyOrMinimumQty}
                  onChange={onChangeSizeChartForm("reorderQtyOrMinimumQty")}
                />
              </div>
              {currentCountry === "india" && (
                <div className="global-single-input">
                  <p>MRP</p>
                  <input
                    placeholder="Enter MRP (optional) "
                    value={sizeChartForm?.mrp}
                    onChange={onChangeSizeChartForm("mrp")}
                  />
                </div>
              )}
              <div className="global-single-input">
                <p>Price</p>
                <input
                  placeholder="Enter Price (optional)"
                  value={sizeChartForm?.price}
                  onChange={onChangeSizeChartForm("price")}
                />
              </div>
              <div className="global-single-input">
                <p>Calculated Price</p>
                <input disabled value={sizeChartForm?.calculatedPrice} />
              </div>
              <div className="size-chart-add-button">
                <Tooltip title="Add to size chart">
                  <button onClick={onAddNewSize}>+</button>
                </Tooltip>
              </div>
            </div>
          </div>
          <hr className="size-chart-divider" />
          {/* //Size chart Single Item container  */}
          <div className="third-container">
            {sizeChartData?.map((r, i) => {
              if (type === "retail") {
                if (r?.isRetail) {
                  return (
                    <div className="size-chart-second-input-container">
                      <div className="global-single-input">
                        <input value={r?.brandOrUnitSize} disabled />
                      </div>
                      <div className="global-single-input size-chart-size-input">
                        <input value={r?.size} disabled />
                      </div>
                      <div className="global-single-input">
                        <input value={r?.reorderQty} disabled />
                      </div>
                     {currentCountry==='india'&& <div className="global-single-input">
                        <input value={r?.mrp === null ? 0 : r?.mrp} disabled />
                      </div>}
                      <div className="global-single-input">
                        <input
                          value={r?.price === null ? 0 : r?.price}
                          disabled
                        />
                      </div>
                      <div className="global-single-input">
                        <input value={r?.calculatedPrice} disabled />
                      </div>
                      <div className="size-chart-add-delete-button">
                        <button>
                          <i class="bi bi-trash3 delete-icon"></i>
                        </button>
                      </div>
                    </div>
                  );
                }
              } else if (type === "wholesale") {
                if (r?.isRetail === false) {
                  return (
                    <div className="size-chart-second-input-container">
                      <div className="global-single-input">
                        <input value={r?.brandOrUnitSize} disabled />
                      </div>
                      <div className="global-single-input size-chart-size-input">
                        <input value={r?.size} disabled />
                      </div>
                      <div className="global-single-input">
                        <input value={r?.reorderQty} disabled />
                      </div>
                     {currentCountry==='india'&& <div className="global-single-input">
                        <input value={r?.mrp === null ? 0 : r?.mrp} disabled />
                      </div>}
                      <div className="global-single-input">
                        <input
                          value={r?.price === null ? 0 : r?.price}
                          disabled
                        />
                      </div>
                      <div className="global-single-input">
                        <input value={r?.calculatedPrice} disabled />
                      </div>
                      <div className="size-chart-add-delete-button">
                        <button>
                          <i class="bi bi-trash3 delete-icon"></i>
                        </button>
                      </div>
                    </div>
                  );
                }
              }
              <div className="size-chart-second-input-container">
                <div className="global-single-input">
                  <input value={r?.brandOrUnitSize} disabled />
                </div>
                <div className="global-single-input size-chart-size-input">
                  <input value={r?.size} disabled />
                </div>
                <div className="global-single-input">
                  <input value={r?.reorderQty} disabled />
                </div>
                {currentCountry==='india'&&<div className="global-single-input">
                  <input value={r?.mrp === null ? 0 : r?.mrp} disabled />
                </div>}
                <div className="global-single-input">
                  <input value={r?.price === null ? 0 : r?.price} />
                </div>
                <div className="global-single-input">
                  <input value={r?.calculatedPrice} />
                </div>
                <div className="size-chart-add-delete-button">
                  <button>
                    <i class="bi bi-trash3 delete-icon"></i>
                  </button>
                </div>
              </div>;
            })}
          </div>
          <div className="size-chart-button-container">
            <button className="btn btn-secondary-outlined" onClick={closeDialog}>Cancel</button>
            {/* <button className="btn btn-primary">Submit</button> */}
          </div>
        </div>
      </Dialog>
    </>
  );
};
