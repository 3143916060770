import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import { forgotPasswordSettingsAPICall } from "../../../../API/Settings/Utility/forgotPasswordSettings/forgotPasswordSettings";

export const UtilityForgotPassword=()=>{
    const Questions=[{qst:"What is Your DOB"}]
    const [forgotPassType,setForgotPassType]=useState("0")
    const [emailAddress,setEmailAddress]=useState('')
    const [Mobile,setMobile]=useState('')
    const [question,setQuestion]=useState(null)
    const [answer,setAnswer]=useState("")
    const [snackBarStates, setSnackBarStates] = useState({
        success: false,
        error: false,
        message: "",
        alert: false,
      });
  
      const closeSnackbar = () => {
        setSnackBarStates({
          ...snackBarStates,
          success: false,
          error: false,
          alert: false,
          message: "",
        });
      };
    const getForgotPassType=(e)=>{
        setForgotPassType(e.target.value)
    }

    const bodyToApi={
        type:forgotPassType==="0"?"email":forgotPassType==="1"?"sms":"questions",
        value:forgotPassType==="0"?[emailAddress]:forgotPassType==="1"?[Mobile]:[{question:question?.qst,answer:answer}]
    }
    const submitBtnFn=()=>{
        forgotPasswordSettingsAPICall(bodyToApi,setSnackBarStates,snackBarStates)
    }
    console.log(forgotPassType);
    return(
        <div className="utility-forgot-pass-container">
            <div className="utility-forgot-pass-container-section">
                <div className="utility-forgot-pass-container-section-top">
                    <div className="global-radio-button radio-group">
                    <input
                     type="radio" 
                     name="utilityForgotPassOption"
                     id="forgotPassEmail"
                     defaultChecked
                     value={0}
                     onChange={getForgotPassType}
                     />
                    <label htmlFor="forgotPassEmail">Email</label>
                    </div>
                    <div className="global-radio-button radio-group">
                    <input type="radio"
                     name="utilityForgotPassOption"
                     id="forgotPassSms"
                     value={1}
                     onChange={getForgotPassType}
                     />
                    <label htmlFor="forgotPassSms">SMS</label>
                    </div>
                    <div className="global-radio-button radio-group">
                    <input type="radio" 
                    name="utilityForgotPassOption" 
                    id="forgotPassQnA"
                    value={2}
                    onChange={getForgotPassType}
                    />
                    <label htmlFor="forgotPassQnA">Q&A</label>
                    </div>
                </div>
                {
                    forgotPassType==="0"?
                    <div className="global-single-input forgot-pass-input-utility" style={{width:"65%"}}>
                        <input type="text" 
                        placeholder="Enter Email address"
                        value={emailAddress}
                        onChange={(e)=>setEmailAddress(e.target.value)}
                        />
                    </div>
                    :forgotPassType==="1"?
                    <div className="global-single-input forgot-pass-input-utility" style={{width:"65%"}}>
                    <input type="text" 
                    placeholder="Enter Mobile Number" maxLength="15"
                    value={Mobile}
                    onChange={(e)=>setMobile(e.target.value)}
                    />
                    </div>
                    :                    
                    <div className="global-single-input forgot-pass-input-utility auto-complete" style={{width:"65%"}}>
                       <Autocomplete
                    options={Questions||[""]}
                    getOptionLabel={(option) =>
                      option?.qst
                    }
                    renderInput={(params) => (
                        <TextField {...params} placeholder="Select Question" />
                      )}
                     value={question}
                      onChange={(e,newValue)=>setQuestion(newValue)}
                  />
                        <input type="text" 
                        placeholder="Answer .."
                        style={{marginTop:"4px"}}
                        value={answer}
                        onChange={(e)=>setAnswer(e.target.value)}
                        />
                    </div>
                }
                <div className="utility-forgot-pass-container-section-btn">
                    <button className="btn btn-secondary-outlined">Clear</button>
                    <button  onClick={submitBtnFn} className="btn btn-secondary">Submit</button>
                </div>
            </div>

            <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
        </div>
    )
}