import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { getCurrentTime, previousDate, today } from '../../../../Js/Date';
import SearchIcon from "@mui/icons-material/Search";
import CategorySearchandFilter from '../../../Single Components/CategorySearchandFilter';
import { useSelector } from 'react-redux';
import { SalesReportSingleAPIcall } from './SalesReportApi';

export const SalesReportSingleView = () => {
    let navigate = useNavigate();
    const [selectedHeading, setSelectedHeading] = useState("Ledger Balance");
    const [startDate, setStartDate] = useState(previousDate);
    const [endDate, setEndDate] = useState(today);
    const [FormDataInfo,setFormDataInfo]=useState([])
  
    const singleViewID = localStorage.getItem("singleViewById");
    
   const account = localStorage.getItem("account")
   const singleViewId =localStorage.getItem("singleViewById")
   const StartDate = localStorage.getItem("fromDate");
   const EndDate = localStorage.getItem("toDate");

    //initialise values
  
    const decimalPosition = localStorage.getItem("decimalPosition");
  
    const singleView = useSelector((state)=>state?.salesReport?.salesReportSinglelist)
    console.log(singleView);

    const handleDataFromChild = (data) => {
        setFormDataInfo(data)
      };
    
    const getFromDate = (e) => {
      setStartDate(e.target.value);
    };
    const getToDate = (e) => {
      setEndDate(e.target.value);
    };
  
    
    const handleBackpage = () => {
      navigate("/userdashboard/sales/Report/salesReport");
    };
    
    useEffect(()=>{
        SalesReportSingleAPIcall({
     fromDate:  StartDate===''?'':`${StartDate} 00:00:00`,
      endDate: EndDate===''?'':`${EndDate} 23:59:59`,
      productId: singleViewId,
    })
      },[StartDate,EndDate,singleViewId])
      
  return (
    <div className="global-page-parent-container"style={{margin:"0"}}>
      <div className="global-white-bg-container">
        <div className="header-div-path-search" style={{display:"flex", justifyContent:"space-between"}}>
           <div>
             <div className="sharp-arrow" style={{width:"115px"}} onClick={() => handleBackpage()}> <p> Sales Report</p></div>
             <div className="sharp-arrow" style={{marginLeft:"18px",width:"auto"}}> <p>{account}</p></div>
             
            </div>
            </div>
      <div className="secound-main-container-div" style={{paddingTop:"0"}}>
        <div className="table-header" style={{height:"34px"}}>
          <p style={{fontSize:"14px",marginLeft:"-16px"}}>Sales Report By {account}</p>
        </div>
        
      </div>
      <div className="table-container" style={{ maxHeight: "73vh" }}>
        <table className="table">
          <thead>
            <tr>
              <th
                style={{
                 
                  paddingLeft: "12px",
                }}
              >
                Date
              </th>
              <th>Customer</th>
              <th>Invoice No.</th>
              <th>Qty</th>
              <th>sales Price</th>
              <th>Discount</th>
              <th>Sales Amount</th>
            </tr>
          </thead>
          <tbody>
          {singleView?.table?.map((item,index)=>(
              <tr key={index}>
                <td style={{ textAlign: "start", paddingLeft: "12px" }}>
                  {item.date}
                </td>
                <td>{item.customername}</td>
                <td>{item.invoiceNo}</td>
                <td>{item.qty}</td>
                <td>{item.salesPrice}</td>
                <td>--</td>
                <td>{item.salesAmount}</td>
              </tr>
           ))}
          </tbody>
          <tfoot>
            <tr style={{ textAlign: "end" }}>
              <th  colSpan="3" style={{ width: "55%", paddingLeft: "12px" }}>Total</th>        
              <th>{singleView?.qtyTotal}</th>        
              <th colSpan="2">{singleView?.priceTotal}</th>
              <th>{singleView?.amountTotal}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    </div>
  );
};
