import React from "react";
import { useSelector } from "react-redux";
import { WorkOrderAdmin } from "./WorkOrderAdmin";
import { WorkOrderUser } from "./WorkOrderUser";

export const WorkOrder = () => {
  //Role of User [admin or user]
  const userRole = useSelector((state) => state.userRoleSlice.value);

  return (
    <div>
      {userRole === "user" ? (
        <WorkOrderUser />
      ) : userRole === "admin" ? (
        <WorkOrderAdmin />
      ) : undefined}
    </div>
  );
};
