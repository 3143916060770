import React from 'react'
import { useEffect } from 'react';
import { useNavigate } from "react-router";
import { vieWareHouseLocationAPICall } from './wareHouseLocationAPI';
import { useSelector } from 'react-redux';
import store from '../../../../Redux/store';
import { get_wareHouse_location_single_view } from './wareHouseLocationSlice';
import { viewInventoryConfigSettingsAPICall } from './inventoryConfigurationSettingsAPI';
export const InventoryLocation = () => {
    let navigate = useNavigate();
    const wareHouseLocationList=useSelector((state)=>state.wareHouseLocationSlice.value)
    const configurationList = useSelector((state) => state.inventoryConfigSettingSlice.apiResList)

  
    const createLocation=()=>{
        navigate("/userdashboard/inventory/configuration/location/create")
      }

      useEffect(()=>{
        viewInventoryConfigSettingsAPICall()
        vieWareHouseLocationAPICall()
         store.dispatch(get_wareHouse_location_single_view({singleWareHouseLocationData:undefined}))
         localStorage.removeItem("singleViewById")
      },[])

      const viewSingleRow=(data)=>{
          // store.dispatch(get_wareHouse_location_single_view({singleWareHouseLocationData:data}))
        localStorage.setItem("singleViewById",data?._id)
        navigate("/userdashboard/inventory/configuration/location/create")
      }
  return (
    <div className='global-page-parent-container'>
    <p className="breadcrumb">Inventory &gt; Configuration &gt;Location</p>
    {/* table */}
    <div className='global-white-bg-container'>
      <div className='create-button-blue-container'>
        {
          configurationList?.multiLocation &&
          <button  onClick={createLocation}  className='create-button-blue'>Create</button>

        }
      </div>
       <div className='global-table-container'>
         <table className='global-table'>
            <thead>
              <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Ware House</th>
                  <th>Short Code</th>
              </tr>
            </thead>
            <tbody >
              {
               wareHouseLocationList?.length !==0 ?  wareHouseLocationList?.slice(0)?.reverse()?.map((r,i)=>(
                  <tr key={i} onClick={()=>viewSingleRow(r)}>
                  <td>{i+1}</td>
                  <td>{r?.name}</td>
                  <td>{r?.locationType}</td>
                  <td>{r?.warehousename}</td>
                  <td>{r?.shortCode}</td>
               </tr>
                ))
                :
                <tr>
                  <td colSpan={5}>No Data</td>
                </tr>
              }
            
              
            </tbody>
         </table>
       </div>
    </div>
   
  </div>
  )
}
