import { createSlice } from "@reduxjs/toolkit";

export const sequenceSlice=createSlice({
    name:"sequenceSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        get_all_sequence_list:(state,action)=>{
            state.value=action.payload.sequenceListData
        }
    }
})
export const {get_all_sequence_list} = sequenceSlice.actions;
export default sequenceSlice.reducer;