import axios from "axios";
import CountryData from '../../Assets/JSON/countryListWithCode.json'
//Get Current location based on IP
export const getCurrentLocationAPICall = async () => {
  await axios
    .get(`https://ipapi.co/json/`)
    .then((res) => {
      if (res.status === 200) {
        //destructure data
        const {
          ip,
          city,
          country_code_iso3,
          country,
          country_calling_code,
          currency,
          currency_name,
          languages,
          region,
          region_code,
        } = res.data;
        const countryMeta = {
          ip,
          city,
          country: country_code_iso3,
          countryCode: country,
          countryDialCode: country_calling_code,
          currency,
          currencyName: currency_name,
          languages,
          region,
          regionCode: region_code,
        };
        getCurrentCountry(country)
        localStorage.setItem("COUNTRY_META",JSON.stringify(countryMeta))
      }
    })
    .catch((err) => {
      console.log(err.response);
    });
};

//Get Current Country
const getCurrentCountry=(countryCode)=>{
  CountryData.forEach((obj,i)=>{
    if(obj.code===countryCode)
    {
      localStorage.setItem('country',obj.name.toLowerCase())
    }
  })
}

