import {createSlice} from '@reduxjs/toolkit'

export const getInvoiceNoForWorkOrderSlice=createSlice({
    name:'workOrderInvoiceNoList',
    initialState:{
        value:undefined
    },
    reducers:{
        get_invoiceNo_workOrder:(state,action)=>{
            state.value=action.payload.workOrderInvoiceNo
        }
    }
})
export const {get_invoiceNo_workOrder} = getInvoiceNoForWorkOrderSlice.actions;
export default getInvoiceNoForWorkOrderSlice.reducer;