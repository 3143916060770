import { createSlice } from "@reduxjs/toolkit";

export const GeneralReport = createSlice({
name:"generalReport",
initialState:{
    BankAndCash:undefined,
    BankAndCashSingleList:undefined,
    ProductMarginReport:undefined,
    ProductMarginReportSingleList:undefined,
    InvoiceMarginReport:undefined,
    InvoiceMarginReportSingleList:undefined,
    customerReceiptReport:undefined,
    customerReceiptReportSingleView:undefined,
},
reducers:{
    Bank_and_cash_list:(state,action)=>{
        state.BankAndCash=action.payload.BankAndCashData
    },
    Bank_and_cash_single_list:(state,action)=>{
        state.BankAndCashSingleList=action.payload.BankAndCashDataSingle
    },
    product_margin_report_list:(state,action)=>{
        state.ProductMarginReport=action.payload.ProductMarginReportData
    },
    product_margin_report_single_list:(state,action)=>{
        state.ProductMarginReportSingleList=action.payload.ProductMarginReportDataSingle
    },
    invoice_margin_report_list:(state,action)=>{
        state.InvoiceMarginReport=action.payload.InvoiceMarginReportData
    },
    invoice_margin_report_single_list:(state,action)=>{
        state.InvoiceMarginReportSingleList=action.payload.InvoiceMarginReportDataSingle
    },
    customer_receipt_report_list:(state,action)=>{
       state.customerReceiptReport=action.payload.customerReceiptReportList
   },
   customer_receipt_report_single_list:(state,action)=>{
       state.customerReceiptReportSingleView=action.payload.customerReceiptReportSingleList
   }
}

})
export const {customer_receipt_report_list,customer_receipt_report_single_list,
    invoice_margin_report_single_list,invoice_margin_report_list,Bank_and_cash_list,Bank_and_cash_single_list,product_margin_report_list,product_margin_report_single_list
}=GeneralReport.actions;
export default GeneralReport.reducer;