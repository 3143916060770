import {createSlice} from '@reduxjs/toolkit'

export const creditDetailsSlice=createSlice({
    name:'creditDetails',
    initialState:{
        value:undefined
    },
    reducers:{
        get_credit_details:(state,action)=>{
            state.value=action.payload.creditDetailsData
        }
    }
})
export const {get_credit_details}=creditDetailsSlice.actions;
export default creditDetailsSlice.reducer;