import { createSlice } from "@reduxjs/toolkit";

export const fpModelSlice=createSlice({
    name:"fpModelSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        update_fpModel:(state,action)=>{
            state.value=action.payload.fpModel
        }
    }
})

export const {update_fpModel}=fpModelSlice.actions
export default fpModelSlice.reducer