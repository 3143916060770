import { Autocomplete, TextField } from "@mui/material";
import React from "react";

const DropdownInputBox = ({
  isCurrentBalance,
  currentBalance,
  addButtonOnClick,
  addButton,
  labelName,
  options,
  value,
  onChange,
  style,
  placeholder,
  onKeyDown,
  disabled,
  label,
  isGroup,
  containerStyle,
}) => {
  const buttonStyle = () => {
    return {
      width: "2em",
      height: "2em",
      position: "absolute",
      top: "49%",
      cursor: "pointer",
      border: "none",
      background: "rgb(13, 60, 107)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      borderRadius: "5px",
      left: "5px",
    };
  };

  return (
    <div
      className="global-single-input add-purchase-input auto-complete"
      style={{ ...containerStyle, position: "relative" }}
    >
      <p style={{ whiteSpace: "nowrap" }}>{labelName}</p>
      <Autocomplete
        options={options || [""]}
        getOptionLabel={(option) =>
          option?.option ||
          option?.name ||
          option?.country ||
          option?.state ||
          option
        }
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder} />
        )}
        value={value}
        onChange={onChange}
        sx={style}
        onKeyDown={onKeyDown}
        disabled={disabled}
      />
      {isGroup && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "5px",
          }}
        >
          <p style={{ color: "#0e2fff" }}>{label}</p>
        </div>
      )}
      {isCurrentBalance && ( 
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBlockStart: "5px",
          }}
        >
          <p style={{ color: "red" }}>
            Current Balance : {currentBalance ? currentBalance : "00"}
          </p>
        </div>
      )}
      {addButton && (
        <button onClick={addButtonOnClick} style={{ ...buttonStyle() }}>
          +
        </button>
      )}
    </div>
  );
};

export default DropdownInputBox;
