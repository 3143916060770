import { createSlice } from "@reduxjs/toolkit";

export const slCodeSlice=createSlice({
    name:'slCodeSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_slCode_list:(state,action)=>{
            state.value=action.payload.slCode
        }
    }
})

export const {update_slCode_list}=slCodeSlice.actions
export default slCodeSlice.reducer