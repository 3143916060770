import React from "react";
import "../../css/Login/loginComponent.css"
export const LoginUpdatePassword=(props)=>{
    const{backToLogin,label}=props;
    return(
        <>
        <div className="login-update-pass-body">
            <div className="login-update-pass-input-ctn">
                <p>New Password</p>
                <input type="password" />
            </div>
            <div className="login-update-pass-input-ctn">
                <p>Confirm Password</p>
                <input type="password" />
            </div>
        </div>
        <div className="login-update-pass-button">
            <button className="btn btn-secondary loginforgot-pass-btn" onClick={backToLogin}>Update password</button>
        </div>
        
        <div className="login-update-pass-footer">
            <p>Enter {label} or &nbsp;</p>
            <div 
                className="login-content-wrapper-forgot-password" style={{alignSelf:"center",fontSize:"0.6rem",color:"blue"}}
                onClick={backToLogin}
                >
                Back to Login
            </div>
        </div>
        </>
    )
}