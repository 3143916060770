import { createSlice } from "@reduxjs/toolkit";

export const indirectIncomeSlice = createSlice({
  name: "indirectIncomeSlice",
  initialState: {
    openingBalance: "",
    gstApplicable: { option: "yes" },
    natureOfTransaction: { option: "Purchase Taxable" },
    natureOfGoods: { option: "Capital Goods" },
    gstTaxType: { option: "integrated tax" },
    gstRate: "",
    taxType: { option: "integrated tax" },
    rate: "",
    typeOfSupply: { option: "Goods" },
  },
  reducers: {
    setOpeningBalance: (state, action) => {
      state.openingBalance = action.payload;
    },
    setGstApplicable: (state, action) => {
      state.gstApplicable = action.payload;
    },
    setNatureOfTransaction: (state, action) => {
      state.natureOfTransaction = action.payload;
    },
    setNatureOfGoods: (state, action) => {
      state.natureOfGoods = action.payload;
    },
    setGstTaxType: (state, action) => {
      state.gstTaxType = action.payload;
    },
    setGstRate: (state, action) => {
      state.gstRate = action.payload;
    },
    setTaxType: (state, action) => {
      state.taxType = action.payload;
    },
    setRate: (state, action) => {
      state.rate = action.payload;
    },
    setTypeOfSupply: (state, action) => {
      state.typeOfSupply = action.payload;
    },
  },
});

export const {
  setGstApplicable,
  setGstRate,
  setGstTaxType,
  setNatureOfGoods,
  setNatureOfTransaction,
  setOpeningBalance,
  setRate,
  setTaxType,
  setTypeOfSupply,
} = indirectIncomeSlice.actions;
export default indirectIncomeSlice.reducer;
