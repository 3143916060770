import { createSlice } from "@reduxjs/toolkit";

export const invoiceSlice=createSlice({
    name:'invoiceSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_invoice:(state,action)=>{
            state.value=action.payload.invoice
        }
    }
})
export const {update_invoice} = invoiceSlice.actions
export default invoiceSlice.reducer