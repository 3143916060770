import { createSlice } from "@reduxjs/toolkit";

export const generatePayrollVoucherSlice=createSlice({
    name:"generatePayrollVoucherSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        update_staff_payroll_voucher_no:(state,action)=>{
            state.value=action.payload.staffPayrollVoucherNo
        }
    }
})

export const{update_staff_payroll_voucher_no}=generatePayrollVoucherSlice.actions
export default generatePayrollVoucherSlice.reducer