import { createSlice } from "@reduxjs/toolkit";

export const BranchReceiptSlice = createSlice({
  name: "BranchReceiptSlice",
  initialState: {
    value: undefined,
    singleValue:undefined,
    transNo:undefined,
  },
  reducers: {
    get_branch_receipt_list: (state, action) => {
      state.value = action.payload.branchReceiptData;
    },
    get_branch_receipt_single_view:(state,action)=>{
      state.singleValue=action.payload
    },
    get_branch_receipt_transNo:(state,action)=>{
      state.transNo=action.payload.branchReceiptTransNo
    } 
  
  },
});

export const {get_branch_receipt_list,get_branch_receipt_single_view,get_branch_receipt_transNo} = BranchReceiptSlice.actions;

export default BranchReceiptSlice.reducer;