import React, { useEffect, useState } from "react";
import { Select, IconButton, Skeleton, Autocomplete, TextField } from "@mui/material";
import {
  viewAddExpenseTypeAPICall,
  addExpenseTypeDocNoAPICall,
  addExpenseTypeAPICall,
  editExpenseTypeAPICall,
  deleteExpenseTypeAPICall
} from "../../../API/Expense/addExpenseTypeAPI";
import { useSelector } from "react-redux";
import SuccessSnackbar from "../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../Single Components/SnackBars/ErrorSnackbar";
import {deleteAlertMessage, filterObjFromList} from '../../../Js/generalFunctions'
import { chartOfAccountListApiCall } from "../../../API/AccountUpdated/chartOfAccountAPI";
import { LoadingForm } from "../../Single Components/LoadingForm";
export const AddExpenseType = () => {
  const storeCode=localStorage.getItem('branchId')
  // role based rendering
  const userRole = useSelector((state) => state.userRoleSlice.value);
  // view expense type
  const expenseTypeData = useSelector((state) => state.addExpenseType.value);
  // add expense doc no
  const addExpDocNo = useSelector((state) => state.addExpenseTypeDocNo.value);
  const chartOfAccountList = useSelector((state) => state.chartOfAccountsSlice.value)
  const expenseAccList =chartOfAccountList?.filter(item =>item.type === "Expenses" || item.type === "Cost of revenue");

  const [expenseType, setExpenseType] = useState("");
  const [transNo, setTransNo] = useState("");
  const [expenseAcc,setExpenseAcc]=useState(null)

  //Update Listener States
  const [isModify, setIsModify] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  // loading states
  const [isLoading,setIsLoading]=useState(false)
  //Snackbar States
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("Error!!");

  // edit states
  const [isEdit, setIsEdit] = useState(false);
  const [editedId, setEditedId] = useState();

  // delete state
  const [deletedId,setDeletedId]=useState()

  //*Update Listener
  const updateListener = () => {
    setIsModify(!isModify);
  };

  //Close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setSuccessSnackOpen(false);
  };
  //Close ErrorSnackbar
  const closeErrorSnackbar = () => {
    setErrorSnackOpen(false);
  };

  useEffect(() => {
    viewAddExpenseTypeAPICall(setIsLoading,setErrorSnackOpen,setSnackMsg);
    addExpenseTypeDocNoAPICall();
    chartOfAccountListApiCall()
  }, [isModify]);

  // onchange functions
  const getExpenseType = (e) => {
    setExpenseType(e.target.value);
  };
  // add expense type submit button function
  const addExpenseTypeSubmitFn = () => {
    addExpenseTypeAPICall(
      { expenseType,branchId:storeCode,accountId:expenseAcc?._id},
      updateListener,
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      clearStates,setLoadingForm
    );
  };
  const clearStates = () => {
    setExpenseType("");
    setExpenseAcc(null)
  };

  // edit expense type function
  const clickExpenseTypeEditBtn = (id, transNo, expType,data) => {
    setIsEdit(true);
    setEditedId(id);
    setTransNo(transNo);
    setExpenseType(expType);
    setExpenseAcc(filterObjFromList("_id",expenseAccList,"accountId",data))
  };
  // edit submit function
  const bodyToApi = {
    _id: editedId,
    expenseType,
    accountId:expenseAcc?._id
  };
  const expenseTypeEditSubmitBtn = () => {
    editExpenseTypeAPICall(
      bodyToApi,
      setSuccessSnackOpen,
      setSnackMsg,
      updateListener,
      editClearState,
      setLoadingForm
      );
  };

  const editClearState=()=>{
    setExpenseType("");
    setTransNo('')
    setIsEdit(false)
    setExpenseAcc(null)
  }

// delete expense type 
  const deleteExpenseType=(id)=>{
    deleteExpenseTypeAPICall(id,updateListener)
  }
  
  return (
    <div className="global-page-parent-container">
      <p className="breadcrumb">Expense &gt; Add Expense Type</p>
      {
        // userRole === 'admin'?
        //  <div className="add-expense-top-container">
        //    <h3>Expense Type</h3>
        //    <div className="add-expense-input-container">
        //      <div className="global-single-input">
        //        <p>From</p>
        //        <input type="date" disabled/>
        //      </div>
        //      <div className="global-single-input">
        //        <p>To</p>
        //        <input type="date" disabled/>
        //      </div>
        //      <button
          
        //     className="btn btn-primary add-expense-button"
        //   >
        //     Submit
        //   </button>
        //    </div>
        //  </div>
        // :userRole === 'user'?
        <div className="add-expense-top-container">
        <h3>Add Expense Type</h3>
        <hr className="global-hr" />
        <div className="add-expense-input-container">
          <div className="global-single-input">
            <p>Doc No</p>
            <input
              type="text"
              value={isEdit === true ? transNo : addExpDocNo?.transNo}
              disabled
            />
          </div>
          <div className="global-single-input">
            <p>Expense Type</p>
            <input
              type="text"
              value={expenseType}
              onChange={getExpenseType}
              disabled={userRole === "admin"}
            />
          </div>
          <div className="global-single-input auto-complete">
          <p>Expense Account</p>
          <Autocomplete
               disablePortal
               id="combo-box-demo"
               options={expenseAccList||[]}
               getOptionLabel={(option) =>option?.accountName}
               renderInput={(params) => <TextField {...params}  focused />}
              value={expenseAcc}
              onChange={(e,newValue)=>setExpenseAcc(newValue)}
              
/>
          </div>
          <button
            onClick={ isEdit === true ? expenseTypeEditSubmitBtn : addExpenseTypeSubmitFn}
            // disabled={userRole === "admin"}
            className="btn btn-primary add-expense-button"
          >
            {isEdit === true ? "Update" : "Submit"}
          </button>
        </div>
      </div>
      // :undefined
      }
     
     
      
      <div className="global-white-bg-container">
        <div className="global-table-container">
          <table className="global-table">
            <thead>
              <tr>
                <th>No</th>
                {userRole === "admin" && (
                  <>
                    <th>Branch Code</th>
                    <th>Branch Name</th>
                  </>
                )}
                <th>Doc No</th>
                <th>Expense Type</th>
                <th>Expense Account</th>
                <th>Action</th>
              </tr>
            </thead>
            {
              isLoading?
              <tbody>
                <tr>
                  <td colSpan={10}>
                    {/* <Loader /> */}
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                    />
                  </td>
                  </tr> 
                  <tr>
                  <td colSpan={10}>
                    {/* <Loader /> */}
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                    />
                  </td>
                  </tr> 
                  <tr>
                  <td colSpan={10}>
                    {/* <Loader /> */}
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                    />
                  </td>
                  </tr> 
                  <tr>
                  <td colSpan={10}>
                    {/* <Loader /> */}
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                    />
                  </td>
                  </tr>
              </tbody>:
            <tbody>

              {
              expenseTypeData!==undefined ?
              expenseTypeData?.slice(0)
                    .reverse().map((r, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  {userRole === "admin" && (
                    <>
                      <td>{r.branchId}</td>
                      <td>{r.branchName}</td>
                    </>
                  )}

                  <td>{r.transNo}</td>
                  <td>{r.expenseType}</td>
                  <td>{r?.accountName}</td>
                  <td>
                    <IconButton
                      onClick={() =>
                        clickExpenseTypeEditBtn(
                           r._id,
                            r.transNo,
                             r.expenseType,
                             r
                             )
                      }
                    >
                      <i class="bi bi-pencil-square edit-icon"></i>
                    </IconButton>
                    <IconButton 
                       onClick={deleteAlertMessage(r._id,deleteExpenseType)}
                    >
                      <i class="bi bi-trash3 delete-icon"></i>
                    </IconButton>
                  </td>
                </tr>
              ))
            :
            <tr>
              <td colspan={4}>No Data</td>
            </tr>
            }
            </tbody>}
          </table>
        </div>
      </div>
      
      {/* snackbar */}
      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />

      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
     <LoadingForm loading={loadingForm}/>

    </div>
  );
};
