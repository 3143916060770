import { Box, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import InputBox from "../../../components/InputBox";
import DropdownInputBox from "../../../components/DropdownInputBox";
import { FilePicker } from "../../../../../Single Components/filePicker";
const TabPanel = ({ value, index, children }) => {
  return (
    <div style={{ width: "100%" }} role="tabpanel" hidden={value !== index}>
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
};

const EmployeeCreation = () => {
  const [value, setValue] = useState(0);
  const [selectedTabName, setSelectedTabName] = useState("");

  useEffect(() => {
    handleChange(null, 0);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // Set the selected tab name  based on the new value
    switch (newValue) {
      case 0:
        setSelectedTabName("Personal Info");
        break;
      case 1:
        setSelectedTabName("Company Info");
        break;
      case 2:
        setSelectedTabName("Bank Details");
        break;
      case 3:
        setSelectedTabName("Document Details");
        break;
      case 4:
        setSelectedTabName("Tax Details");
        break;
      default:
        break;
    }
  };

  const handleBtnClick = (Name) => {
    switch (Name) {
      case "next":
        setValue(value + 1);
        break;
      case "previous":
        setValue(value - 1);
        break;
      case "submit":
        break;
      case "clear":
        break;

      default:
        break;
    }
  };

  return (
    <div className="purchase-voucher employee-creation">
      <div
        style={{
          padding: "10px 20px",
          background: "white",
          width: "100%",
          borderRadius: "10px",
          boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "#fff" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Personal Info" />
            <Tab label="Company Info" />
            <Tab label="Bank Details" />
            <Tab label="Document Details" />
            <Tab label="Tax Details" />
          </Tabs>
        </Box>
      </div>

      {/* tab 1 */}
      <TabPanel value={value} index={0}>
        <div className="accounting-voucher-menu-sections">
          <div class="employee-creation-input-container">
            <InputBox label={"Staff Name"} />
            <InputBox label={"Gender"} />
            <InputBox label={"Father Name"} />
            <InputBox label={"Marital Status"} />
            <InputBox label={"Contact Number"} />
            <InputBox label={"Blood Group"} />
            <InputBox label={"Emergency Contact Number"} />
            <InputBox label={"Address"} />
            <InputBox label={"Address"} />
            <InputBox label={"DOB"} type={"date"} />
            <DropdownInputBox labelName={"Country"} />
            <DropdownInputBox labelName={"State"} />
          </div>
          <div className=" product-master-image">
            <h5>Image Upload</h5>
            <div className="image-product-master">
              <FilePicker
                id="product2"
                // uploadImageUrl={image1}
                // setImage={setImage1}
                // onChange={(e) => setImage1(e.target.files[0])}
                // onChange={getImage1}
              />
            </div>
          </div>

          <div className="button-container">
            <div></div>
            <div className="button-box">
              <button className="cancel-btn">Clear</button>
              <button
                onClick={() => handleBtnClick("next")}
                className="submit-btn"
              >
                Next
              </button>
            </div>
            <div></div>
          </div>
        </div>
      </TabPanel>
      {/* tab 2 */}
      <TabPanel value={value} index={1}>
        <div className="accounting-voucher-menu-sections tab-two">
          <div className="employee-creation-input-container ">
            <InputBox label={"Employee ID"} />
            <DropdownInputBox labelName={"Department"} />
            <DropdownInputBox labelName={"Designation"} />
            <InputBox label={"Date Of Joining"} type={"date"} />
            <InputBox label={"Date Of Leaving"} type={"date"} />
            <InputBox label={"Work Hours"} type={"time"} />
            <DropdownInputBox labelName={"Outlet Location"} />
            <div></div>
          </div>
        </div>
        <div className="accounting-voucher-menu-sections">
          <div>
            <h3>Salary</h3>
          </div>
          <div className="employee-creation-input-container-tab-two ">
            <DropdownInputBox labelName={"Type"} />
            <InputBox label={"Monthly Salary"} />
          </div>
          <div>
            <h3>Contract Period</h3>
          </div>
          <div className="employee-creation-input-container-tab-two ">
            <InputBox label={"From"} type={"date"} />
            <InputBox label={"To"} type={"date"} />
          </div>
          <div className="button-container">
            <div></div>
            <div className="button-box">
              <button
                onClick={() => handleBtnClick("previous")}
                className="cancel-btn"
              >
                Back
              </button>
              <button
                onClick={() => handleBtnClick("next")}
                className="submit-btn"
              >
                Next
              </button>
            </div>
            <div></div>
          </div>
        </div>
      </TabPanel>
      {/* tab 3 */}
      <TabPanel value={value} index={2}>
        <div className="accounting-voucher-menu-sections tab-three">
          <div className="employee-creation-input-container ">
            <InputBox label={"Account Holder"} />
            <DropdownInputBox labelName={"Bank Name"} />
            <InputBox label={"Account Number"} />
            <InputBox label={"Bank Code (IFSC)"} />
            <DropdownInputBox labelName={"Branch Location"} />
            <div></div>
          </div>
          <div className="button-container">
            <div></div>
            <div className="button-box">
              <button
                onClick={() => handleBtnClick("previous")}
                className="cancel-btn"
              >
                Back
              </button>
              <button
                onClick={() => handleBtnClick("next")}
                className="submit-btn"
              >
                Next
              </button>
            </div>
            <div></div>
          </div>
        </div>
      </TabPanel>
      {/* tab 4 */}
      <TabPanel value={value} index={3}>
        <div className="accounting-voucher-menu-sections"></div>
        <div className="accounting-voucher-menu-sections tab-three">
          <div className="employee-creation-input-container ">
            <DropdownInputBox labelName={"Employee"} />
            <InputBox label={"Employee ID"} />
            <DropdownInputBox labelName={"Document ID"} />
            <InputBox label={"Document Number"} />
            <InputBox label={"Expired Date"} />

            <div>
              <h5>Image Upload</h5>
              <div className="image-product-master">
                <FilePicker
                  id="product2"
                  // uploadImageUrl={image1}
                  // setImage={setImage1}
                  // onChange={(e) => setImage1(e.target.files[0])}
                  // onChange={getImage1}
                />
              </div>
            </div>
          </div>

          <div className="button-container">
            <div></div>
            <div className="button-box">
              <button
                onClick={() => handleBtnClick("previous")}
                className="cancel-btn"
              >
                Back
              </button>
              <button
                onClick={() => handleBtnClick("next")}
                className="submit-btn"
              >
                Next
              </button>
            </div>
            <div></div>
          </div>
        </div>
      </TabPanel>
      {/* tab 5 */}
      <TabPanel value={value} index={4}>
        <div className="accounting-voucher-menu-sections">
          <div className="employee-creation-input-container ">
            <DropdownInputBox labelName={"Applicable Tax Regime "} />
            <InputBox label={"PAN"} />
            <InputBox label={"AADHAAR No"} />
            <InputBox label={"UAN (Universal Account Number)"} />
            <InputBox label={"PF Account No"} />
            <InputBox label={"EPS Account No"} />
            <InputBox label={"Date of Joining for PF"} type={"date"} />
            <InputBox label={"PRAN (PR Account Number)"} />
            <InputBox label={"ESI No"} />
            <InputBox label={"ESI Dispensary Name"} />
          </div>
          <div className="button-container">
            <div></div>
            <div className="button-box">
              <button
                onClick={() => handleBtnClick("previous")}
                className="cancel-btn"
              >
                Back
              </button>
              <button className="submit-btn">Submit</button>
            </div>
            <div></div>
          </div>
        </div>
      </TabPanel>
    </div>
  );
};

export default EmployeeCreation;
