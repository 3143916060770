import axios from "axios"
import { get_all_transfer_no } from "../../Redux/Report/TransferReport/getAllTransferNoSlice"
import { get_transfer_report } from "../../Redux/Report/TransferReport/transferReportSlice"
import store from "../../Redux/store"
import { HEADERS } from "../UrlAndPaths"

// view transfer report list
export const transferReportAPICall= async (body,setIsLoading)=>{
    setIsLoading(true)
    await axios.post("purchase/transferReport",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_transfer_report({transferReportData:res.data}))
            setIsLoading(false)
        }
    })
    .catch((err)=>{
        store.dispatch(get_transfer_report({transferReportData:undefined}))
        console.log(err.response);
        setIsLoading(false)
    })
}
// transfer no list 
export const allTransferNoListAPICall= async (body)=>{
  await axios.post("purchase/getTransferNos",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
    store.dispatch(get_all_transfer_no({allTransferNoList:res.data}))
    }
  })
  .catch((err)=>{
    store.dispatch(get_all_transfer_no({allTransferNoList:undefined}))
    console.log(err.response);
  })
}