import React, { useEffect, useState } from "react";
import { convertDateFormat, getCurrentTime, today } from "../../../../Js/Date";
import { IconButton, Skeleton } from "@mui/material";
import { Datepicker } from "../../../Single Components/Datepicker";
import { useNavigate } from "react-router-dom";
import { AllRfqList, RFQFilterAPICall } from "../../../../API/Purchase Manager/RFQ/ConfirmRfqApiCall";
import { useSelector } from "react-redux";
import store from "../../../../Redux/store";
import { purchaseOrderSingleView, rfqSingleView } from "../../../../Redux/Prchase Manager/RFQ/RfqSlice";
import CategorySearchandFilter from "../../../Single Components/CategorySearchandFilter";
import { PurchaseOrderFilterAPICall } from "../../../../API/Purchase Manager/NewPurchaseOrder/PurchaseOrder";
import CustomPagination from "../../../Single Components/CustomPagination";
import axios from "axios";
import { setFilterActive } from "../../../Single Components/listSlice/filterCategorySlice";

export const RequestForQuatation = () => {
  const filtereDataOptions = useSelector((state) => state.listCategory);

  const branchId = localStorage.getItem("branchId");
  const RfqList = useSelector((state) => state?.RfqSlice?.value);
  // console.log(RfqList?.pages,'eeeeee');
  let navigateTo = useNavigate();
  const [FormDataInfo,setFormDataInfo]=useState([])

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = RfqList?.pages;
  const [selectedBranch, setSelectedBranch] = useState(null);

  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };

  useEffect(() => {
   
    if(FormDataInfo.length!==0){ 
    let loadData=window.location.pathname===filtereDataOptions?.filterActive
    !loadData &&  RFQFilterAPICall({
    fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
    toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
    createdBy: FormDataInfo.createdBy,
    branchList: FormDataInfo.branchList,
    type: FormDataInfo.status,
    search: FormDataInfo.search,
    index: currentPage-1,
  },setIsLoading);
}

}, [FormDataInfo, currentPage]);


  useEffect(() => {
    //  AllRfqList(branchId);
    localStorage.removeItem("singleViewById");
    // store.dispatch(rfqSingleView({ singleRowData: undefined }));
    store.dispatch(purchaseOrderSingleView({ purchaseOrderSingleView: undefined }));
  }, []);

  const handleClickRow = (row) => {
    store.dispatch( setFilterActive(window.location.pathname))
    // store.dispatch(rfqSingleView({ singleRowData: row }));
    localStorage.setItem("singleViewById", row?._id);
    navigateTo("/userdashboard/purchaseManager/RequestForQuatation/singleView")
  };

  return (
    <>
      <div className="global-page-parent-container">
        <div className="purchase-order-main-container">
        
          <div className="justify-space-between"
            style={{ gap: "0 12px", display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}
          >
            {FormDataInfo?.branchList?.length > 0 && (
              <div style={{ marginLeft: '1%' }}>
               <h3>Branch name: 
                  {selectedBranch?.storeCode||filtereDataOptions?.filterCategorylist?.branchList?.storeCode}
                  -{selectedBranch?.branchName||filtereDataOptions?.filterCategorylist?.branchList?.branchName}</h3>
              </div>
            )}
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: FormDataInfo?.branchList?.length ? '72%' : '100%'
            }}>
              <CategorySearchandFilter
                statusOptions={['RFQ', 'CANCEL']}
                onData={handleDataFromChild}
                isCreatedBy={true}
                isStatus={true}
                onBranchSelect={handleBranchSelect}
              />
              <button
                className="btn btn-primary"
                onClick={() =>
                  navigateTo(
                    "/userdashboard/purchaseManager/CreateRequestForQuatation"
                  )
                }
              >
                Create
              </button>
            </div>
          </div>
          <div className="top-bar">
            <h3>Request For Quotation</h3>
            <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
            
          />
            {/* <div className="search-box-container">
              <input
                type="text"
                name=""
                id=""
                placeholder="Search Location..."
                onChange={""}
              />
              <IconButton
                className="icon-btn"
                style={{ position: "absolute", right: "10px" }}
              >
                <i class="bi bi-search search-icon"></i>
              </IconButton>
            </div>
            <div className="date-container">
              <Datepicker type="date" backgroundColor="#f1f2ff" value={today} />
            </div> */}

            {/* <button
              className="btn btn-primary"
              onClick={() =>
                navigateTo(
                  "/userdashboard/purchaseManager/CreateRequestForQuatation"
                )
              }
            >
              Create
            </button> */}
          </div>
          {/* <div className="purchase-order-table-container global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>PO No</th>
                  <th>Supplier Name</th>
                  <th>Uom</th>
                  <th>Date</th>
                  <th>Total</th>
                  <th>Status</th>
                </tr>
              </thead>
              {isLoading ? (
                <tbody>
                  <tr>
                    <td colSpan={10}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={10}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={10}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {RfqList?.length !== 0 ? (
                    RfqList
                      ?.slice(0)
                      ?.reverse()
                      ?.map((r, i) => (
                        <tr key={i} onClick={()=>handleClickRow(r)}>
                          <td>{i+1}</td>
                          <td>{r?.sequence}</td>
                          <td>{r?.supplierName}</td>
                          <td>{r?.purchaseInfo[0]?.unit}</td>
                          <td>{r?.purchaseDate}</td>
                          <td>{r?.total}</td>
                          <td className="table-data-box">
                            {r?.type === "RFQ" ? (
                              <span className="rfq"> RFQ</span>
                            ) : r?.type === "PURCHASE ORDER" ? (
                              <span className="drafted"> PURCHASE ORDER</span>
                            ) : r?.type ==="CANCEL" ? (
                              <span className="Cancel"> CANCEL</span>
                            ):(
                              "-----"
                            )}
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan={10}>No Data</td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
          </div> */}
          <div className="new-global-table-container">
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>PO No</th>
                  <th>Supplier Name</th>
             
                  <th>Date</th>
                  <th>Total</th>
                  <th>CreatedBy</th>
                  <th>Status</th>
                </tr>
              </thead>
              {isLoading ? (
                <tbody>
                  <tr>
                    <td colSpan={10}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={10}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={10}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {RfqList?.list?.length !== 0 ? (
                    RfqList?.list?.slice(0)
                    
                      ?.map((r, i) => (
                        <tr key={i} onClick={() => handleClickRow(r)}>
                          <td>{i + 1}</td>
                          <td>{r?.sequence}</td>
                          <td>{r?.supplierName}</td>              
                          <td>{r?.quotationDate}</td>
                          <td>{r?.total}</td>
                          <td>{r?.CREATEDBY}</td>
                          <td className="table-data-box">
                            {r?.type === "RFQ" ? (
                              <span className="rfq" style={{padding:"5px 25px"}}> RFQ</span>
                            ) : r?.type === "PURCHASE ORDER" ? (
                              <span className="po"> PO</span>
                            ) : r?.type === "CANCEL" ? (
                              <span className="cancel"> CANCEL</span>
                            ) : (
                              "-----"
                            )}
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan={10}>No Data</td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
