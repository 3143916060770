//-----------------------------------------------------
//*Cutting Slip new model Related fns
//Make Single extensible
export const makeCuttingSlipSingleViewExtensible = (
  cuttingSlipSingleViewRes
) => {
  let singleViewResCopy = null;
  if (cuttingSlipSingleViewRes !== undefined) {
    singleViewResCopy = {
      ...cuttingSlipSingleViewRes,
      items: cuttingSlipSingleViewRes?.items?.map((itemsObj) => {
        return {
          ...itemsObj,
          productItems: itemsObj?.productItems?.map((prodItemsObj) => {
            return { ...prodItemsObj };
          }),
        };
      }),
    };
    return singleViewResCopy;
  }
};

//Check Item type (like thob,jalabia,abaya etc...)
const checkItemType = (slipObj) => {
  return slipObj?.type;
};

//Create Type wise Arrays (filter same types into single arrays)
const createTypeWiseArray = (singleViewRes) => {
  if (singleViewRes !== undefined) {
    //destructure Items array for processing
    const { items } = singleViewRes;

    //Arrays initialized for different types
    let thobArray = [],
      jalabiaArray = [],
      salwarArray = [],
      abayaArray = [],
      churidharArray = [],
      shirtArray = [],
      pantArray = [],
      coatArray = [],
      sharvaniArray = [],
      safariArray = [];

    items.forEach((obj) => {
      const itemType = checkItemType(obj);
      switch (itemType) {
        case "thob":
          thobArray.push(obj);
          break;
        case "jalabia":
          jalabiaArray.push(obj);

          break;
        case "salwar":
          salwarArray.push(obj);

          break;
        case "abaya":
          abayaArray.push(obj);

          break;
        case "churidhar":
          churidharArray.push(obj);

          break;
        case "shirt":
          shirtArray.push(obj);

          break;
        case "pant":
          pantArray.push(obj);

          break;
        case "coat":
          coatArray.push(obj);

          break;
        case "sharvani":
          sharvaniArray.push(obj);

          break;
        case "safari":
          safariArray.push(obj);

          break;

        default:
          break;
      }
    });

    return {
      thobArray,
      jalabiaArray,
      salwarArray,
      abayaArray,
      churidharArray,
      shirtArray,
      pantArray,
      coatArray,
      sharvaniArray,
      safariArray,
    };
  }
};

//Create Customer Array and relations array of any model
const createCustomerOrRelationWiseArray = (array, newArray) => {
  array?.forEach((obj) => {
    if (obj.relationId === null) {
      //create an array for main customer
      if (Object.keys(newArray)?.length == 0) newArray.cusMain = [];

      newArray.cusMain?.push(obj);
      console.log(newArray);
    } else {
      //create arrays for each relation
      if (Object.keys(newArray)?.length == 0) newArray[obj.relationId] = [];
      else if (Object.keys(newArray).includes(obj.relationId) === false)
        newArray[obj.relationId] = [];

      newArray[obj.relationId].push(obj);
    }
  });

  return newArray;
};

//Create New Model
const createNewModelCuttingSlip = (newSingleViewRes) => {
  console.log(newSingleViewRes);
  if (newSingleViewRes !== undefined) {
    const {
      orderNo,
      _id,
      customerName,
      // status,
      mobile,
      orderDate,
      deliveryDate,
      items,
      relation,
      salesPerson,
      rack,
      barcodeNumber,
      isCode,
    } = newSingleViewRes;
    const newModel = {
      woId: _id,
      woNo: orderNo,
      cusName: customerName,
      employee:salesPerson,
      relations: relation,
      cusMobile: mobile,
      rack,
      ordDate: orderDate,
      delDate: deliveryDate,
      items: [],
      barcodeNumber:barcodeNumber,
      isCode:isCode,
      // status
    };

    items?.forEach((item) => {
      const { type } = item;
      let exist;
      switch (type) {
        case "thob":
          //create new model for thob

          //1. check items array already hs uniqueId
          //2.if already has push to the object else create new object

          exist = newModel.items?.find(
            (obj) => obj?.uniqueKey === item?.uniqueKey
          );

          if (exist) {
            //item exist merge it
            newModel.items.map((obj) =>
              obj?.uniqueKey === item?.uniqueKey
                ? ((obj.itemId = [...obj.itemId, item?.id]),
                  // (obj.status=item?.status)
                  (obj.materials = [
                    ...obj.materials,
                    ...item?.productItems?.map((material) => {
                      return {
                        mName: material?.itemName,
                        qty: material?.qty,
                        color: material?.colorName,
                        thobModelName: item?.thobModelName,
                        cuffName: item?.cuffName,
                        cuffSize1: item?.cuffSize1,
                        cuffSize2: item?.cuffSize2,
                        cuffClothName: item?.cuffClothName,
                        cuffModelName: item?.cuffModelName,
                        collarName: item?.collarName,
                        collarSize: item?.collarSize,
                        collarClothName: item?.collarClothName,
                        collarModelName: item?.collarModelName,
                        buttonName: item?.buttonName,
                        stitchingName: item?.stitchingName,
                        marModelName: item?.marModelName,
                        fpName: item?.fpName,
                        penName: item?.penName,
                        mobilePocket: item?.mobilePocket,
                        buttonNo: item?.buttonNo,
                        sidePTName: item?.sidePTName,
                        sidePTSize: item?.sidePTSize,
                        remark: item?.remark,
                        thobModel: item?.thobModel,
                        cuffModel: item?.cuffModel,
                        cuffCloth: item?.cuffCloth,
                        cuff: item?.cuff,
                        collar: item?.collar,
                        collarCloth: item?.collarCloth,
                        collarModel: item?.collarModel,
                        button: item?.button,
                        stitching: item?.stitching,
                        marModel: item?.marModel,
                        fpModel: item?.fpModel,
                        pen: item?.pen,
                        sidePtModel: item?.sidePtModel,
                        _uuid:material?._uuid,
                        id:item?.id,
                        mtr: material?.mtr,
                        mtrUsed:material?.mtrUsed,
                        down:item?.down,
                        veedi:item?.veedi,
                        bag:item?.bag,
                        open:item?.open,
                        
                      };
                    }),
                  ]))
                : obj
            );
          } else {
            //item not exist create new object
            newModel.items = [
              ...newModel.items,
              {
                type,
                uniqueKey: item?.uniqueKey,
                relationId: item?.relationId,
                relationName: item?.relationName,
                relations: relation,
                itemId: [item?.id],
                length: item?.length,
                shoulder: item?.shoulder,
                sleeve: item?.sleeve,
                chest: item?.chest,
                stomach: item?.stomach,
                slChest: item?.slChest,
                slSo: item?.slSo,
                marSize: item?.marSize,
                regalSize: item?.regalSize,
                kneeLoose: item?.kneeLoose,
                bottom: item?.bottom,
                neck: item?.neck,
                fpDown: item?.fpDown,
                fpSize1: item?.fpSize1,
                fpSize2: item?.fpSize2,
                neckDButton: item?.neckDButton,
                cuttingMasters:item?.productItems?.map((obj) => obj?.cuttingMaster).flat(),
                tailors:item?.productItems?.map((obj)=>obj.tailor).flat(),
                down:item?.down,
                veedi:item?.veedi,
                bag:item?.bag,
                open:item?.open,
                sidePTSize: item?.sidePTSize,
                // status:item?.status,
                materials: item?.productItems?.map((material) => {
                  return {
                    mName: material?.itemName,
                    qty: material?.qty,
                    color: material?.colorName,
                    status:material?.status,
                    thobModelName: item?.thobModelName,
                    cuffName: item?.cuffName,
                    cuffSize1: item?.cuffSize1,
                    cuffSize2: item?.cuffSize2,
                    cuffClothName: item?.cuffClothName,
                    cuffModelName: item?.cuffModelName,
                    collarName: item?.collarName,
                    collarSize: item?.collarSize,
                    collarClothName: item?.collarClothName,
                    collarModelName: item?.collarModelName,
                    buttonName: item?.buttonName,
                    stitchingName: item?.stitchingName,
                    marModelName: item?.marModelName,
                    fpName: item?.fpName,
                    penName: item?.penName,
                    mobilePocket: item?.mobilePocket,
                    buttonNo: item?.buttonNo,
                    sidePTName: item?.sidePTName,
                    sidePTSize: item?.sidePTSize,
                    remark: item?.remark,
                    thobModel: item?.thobModel,
                    cuffModel: item?.cuffModel,
                    cuffCloth: item?.cuffCloth,
                    cuff: item?.cuff,
                    collar: item?.collar,
                    collarCloth: item?.collarCloth,
                    collarModel: item?.collarModel,
                    button: item?.button,
                    stitching: item?.stitching,
                    marModel: item?.marModel,
                    fpModel: item?.fpModel,
                    pen: item?.pen,
                    sidePtModel: item?.sidePtModel,
                    _uuid:material?._uuid,
                    id:item?.id,
                    mtr: material?.mtr,
                    mtrUsed:material?.mtrUsed,
                    down:item?.down,
                    veedi:item?.veedi,
                    bag:item?.bag,
                    open:item?.open,
                        
                  };
                }),
              },
            ];
          }

          break;
        case "jalabia":
          //create new model for jalabia

          exist = newModel.items?.find(
            (obj) => obj?.uniqueKey === item?.uniqueKey
          );

          if (exist) {
            //item exist merge it
            newModel.items.map((obj) =>
              obj?.uniqueKey === item?.uniqueKey
                ? ((obj.itemId = [...obj.itemId, item?.id]),
                // (obj.status=item?.status)
                  (obj.materials = [
                    ...obj.materials,
                    ...item?.productItems?.map((material) => {
                      return {
                        mName: material?.itemName,
                        qty: material?.qty,
                        color: material?.colorName,
                        jalabiaModelName: item?.jalabiaModelName,
                        neckName: item?.neckName,
                        stitchingName: item?.stitchingName,
                        remark: item?.remark,
                        marModelName: item?.marModelName,
                        ModelName: item?.ModelName,
                        fpName: item?.fpName,
                        fpSize1: item?.fpSize1,
                        fpSize2: item?.fpSize2,
                        jalabiaModel: item?.jalabiaModel,
                        neckModel: item?.neckModel,
                        stitching: item?.stitching,
                        model: item?.model,
                        fpModel: item?.fpModel,
                        _uuid:material?._uuid,
                        id:item?.id
                      };
                    }),
                  ]))
                : obj
            );
          } else {
            //item not exist create new object
            newModel.items = [
              ...newModel.items,
              {
                type,
                uniqueKey: item?.uniqueKey,
                relationId: item?.relationId,
                relationName: item?.relationName,
                relations: relation,
                itemId: [item?.id],
                length: item?.length,
                shoulder: item?.shoulder,
                sleeve: item?.sleeve,
                chest: item?.chest,
                stomach: item?.stomach,
                slChest: item?.slChest,
                slSto: item?.slSto,
                neck: item?.neck,
                bottom: item?.bottom,
                sleeveSize: item?.sleeveSize,
                regalSize: item?.regalSize,
                neckDownButton: item?.neckDownButton,
                mar: item?.mar,
                fpSize1: item?.fpSize1,
                fpSize2: item?.fpSize2,
                cuttingMasters:item?.productItems?.map((obj) => obj?.cuttingMaster).flat(),
                tailors:item?.productItems?.map((obj)=>obj.tailor).flat(),
                // status:item?.status,
                materials: item?.productItems?.map((material) => {
                  return {
                    mName: material?.itemName,
                    qty: material?.qty,
                    color: material?.colorName,
                    jalabiaModelName: item?.jalabiaModelName,
                    neckName: item?.neckName,
                    stitchingName: item?.stitchingName,
                    remark: item?.remark,
                    marModelName: item?.marModelName,
                    ModelName: item?.ModelName,
                    fpName: item?.fpName,
                    fpSize1: item?.fpSize1,
                    fpSize2: item?.fpSize2,
                    jalabiaModel: item?.jalabiaModel,
                    neckModel: item?.neckModel,
                    stitching: item?.stitching,
                    model: item?.model,
                    fpModel: item?.fpModel,
                    _uuid:material?._uuid,
                    id:item?.id,
                    status:material?.status,
                    mtr: material?.mtr,
                    mtrUsed:material?.mtrUsed
                  };
                }),
              },
            ];
          }

          break;
        case "salwar":
          //create new model for salwar
          exist = newModel.items?.find(
            (obj) => obj?.uniqueKey === item?.uniqueKey
          );

          if (exist) {
            //item exist merge it
            newModel.items.map((obj) =>
              obj?.uniqueKey === item?.uniqueKey
                ? ((obj.itemId = [...obj.itemId, item?.id]),
                // (obj.status=item?.status)
                  (obj.materials = [
                    ...obj.materials,
                    ...item?.productItems?.map((material) => {
                      return {
                        mName: material?.itemName,
                        qty: material?.qty,
                        color: material?.colorName,
                        elasticWidthNumber: item?.elasticWidthNumber,
                        remarks: item?.remarks,
                        elasticWidth: item?.elasticWidth,
                        _uuid:material?._uuid,
                        id:item?.id,
                        
                      };
                    }),
                  ]))
                : obj
            );
          } else {
            //item not exist create new object
            newModel.items = [
              ...newModel.items,
              {
                type,
                uniqueKey: item?.uniqueKey,
                relationId: item?.relationId,
                relationName: item?.relationName,
                relations: relation,
                itemId: [item?.id],
                length: item?.length,
                waist: item?.waist,
                bottom: item?.bottom,
                elasticSize: item?.elasticSize,
                ply: item?.ply,
                thighLoose: item?.thighLoose,
                cuttingMasters:item?.productItems?.map((obj) => obj?.cuttingMaster).flat(),
                tailors:item?.productItems?.map((obj)=>obj.tailor).flat(),
                // status:item?.status,
                materials: item?.productItems?.map((material) => {
                  return {
                    mName: material?.itemName,
                    qty: material?.qty,
                    color: material?.colorName,
                    elasticWidthNumber: item?.elasticWidthNumber,
                    remarks: item?.remarks,
                    elasticWidth: item?.elasticWidth,
                    _uuid:material?._uuid,
                    id:item?.id,
                    status:material?.status,
                    mtr: material?.mtr,
                    mtrUsed:material?.mtrUsed
                  };
                }),
              },
            ];
          }

          break;
        case "abaya":
          //create new model for abaya
          exist = newModel.items?.find(
            (obj) => obj?.uniqueKey === item?.uniqueKey
          );

          if (exist) {
            //item exist merge it
            newModel.items.map((obj) =>
              obj?.uniqueKey === item?.uniqueKey
                ? ((obj.itemId = [...obj.itemId, item?.id]),
                // (obj.status=item?.status)
                  (obj.materials = [
                    ...obj.materials,
                    ...item?.productItems?.map((material) => {
                      return {
                        mName: material?.itemName,
                        qty: material?.qty,
                        color: material?.colorName,
                        remarks: item?.remarks,
                        _uuid:material?._uuid,
                        id:item?.id
                      };
                    }),
                  ]))
                : obj
            );
          } else {
            //item not exist create new object
            newModel.items = [
              ...newModel.items,
              {
                type,
                uniqueKey: item?.uniqueKey,
                relationId: item?.relationId,
                relationName: item?.relationName,
                relations: relation,
                itemId: [item?.id],
                sleeveFromNeck: item?.sleeveFromNeck,
                sleeveFromShoulder: item?.sleeveFromShoulder,
                sleeveWidth: item?.sleeveWidth,
                arms: item?.arms,
                bust: item?.bust,
                waist: item?.waist,
                hip: item?.hip,
                length: item?.length,
                cuttingMasters:item?.productItems?.map((obj) => obj?.cuttingMaster).flat(),
                tailors:item?.productItems?.map((obj)=>obj.tailor).flat(),
                // status:item?.status,
                materials: item?.productItems?.map((material) => {
                  return {
                    mName: material?.itemName,
                    qty: material?.qty,
                    color: material?.colorName,
                    remarks: item?.remarks,
                    _uuid:material?._uuid,
                    id:item?.id,
                    status:material?.status,
                    mtr: material?.mtr,
                    mtrUsed:material?.mtrUsed
                  };
                }),
              },
            ];
          }
          break;
        case "churidhar":
          //create new model for churidhar

          exist = newModel.items?.find(
            (obj) => obj?.uniqueKey === item?.uniqueKey
          );

          if (exist) {
            
            //item exist merge it
            newModel.items.map((obj) =>
              obj?.uniqueKey === item?.uniqueKey
                ? ((obj.itemId = [...obj.itemId, item?.id]),
                // (obj.status=item?.status)
                  (obj.materials = [
                    ...obj.materials,
                    ...item?.productItems?.map((material) => {
                      return {
                        mName: material?.itemName,
                        qty: material?.qty,
                        color: material?.colorName,
                        remarks: item?.remarks,
                        _uuid:material?._uuid,
                        id:item?.id
                      };
                    }),
                  ]))
                : obj
            );
          } else {
            //item not exist create new object
            newModel.items = [
              ...newModel.items,
              {
                type,
                uniqueKey: item?.uniqueKey,
                relationId: item?.relationId,
                relationName: item?.relationName,
                itemId: [item?.id],
                relations: relation,
                shoulder: item?.shoulder,
                frontNeck: item?.frontNeck,
                backNeck: item?.backNeck,
                waist: item?.waist,
                hip: item?.hip,
                fullLength: item?.fullLength,
                armRound: item?.armRound,
                armHole: item?.armHole,
                sleeveLength: item?.sleeveLength,
                cuttingMasters:item?.productItems?.map((obj) => obj?.cuttingMaster).flat(),
                tailors:item?.productItems?.map((obj)=>obj.tailor).flat(),
                // status:item?.status,
                materials: item?.productItems?.map((material) => {
                  return {
                    mName: material?.itemName,
                    qty: material?.qty,
                    color: material?.colorName,
                    remarks: item?.remarks,
                    _uuid:material?._uuid,
                    id:item?.id,
                    status:material?.status,
                    mtr: material?.mtr,
                    mtrUsed:material?.mtrUsed
                  };
                }),
              },
            ];
          }

          break;
        case "shirt":
          //create new model for shirt

          exist = newModel.items?.find(
            (obj) => obj?.uniqueKey === item?.uniqueKey
          );

          if (exist) {
            console.log("item Exist");
            //item exist merge it
            newModel.items.map((obj) =>
              obj?.uniqueKey === item?.uniqueKey
                ? ((obj.itemId = [...obj.itemId, item?.id]),
                // (obj.status=item?.status)
                  (obj.materials = [
                    ...obj.materials,
                    ...item?.productItems?.map((material) => {
                      return {
                        mName: material?.itemName,
                        qty: material?.qty,
                        color: material?.colorName,
                        remarks: item?.remarks,
                        cupModelName: item?.cupModelName,
                        stitchingModelname: item?.stitchingModelname,
                        marModelName: item?.marModelName,
                        bottomModelName: item?.bottomModelName,
                        colorModelName: item?.colorModelName,
                        cup: item?.cup,
                        stitching: item?.stitching,
                        marModel: item?.marModel,
                        bottomModel: item?.bottomModel,
                        colour: item?.colour,
                        _uuid:material?._uuid,
                        id:item?.id
                      };
                    }),
                  ]))
                : obj
            );
          } else {
            //item not exist create new object
            newModel.items = [
              ...newModel.items,
              {
                type,
                uniqueKey: item?.uniqueKey,
                relationId: item?.relationId,
                relationName: item?.relationName,
                itemId: [item?.id],
                relations: relation,
                length: item?.length,
                shoulder: item?.shoulder,
                sleeveFull: item?.sleeveFull,
                chest: item?.chest,
                waist: item?.waist,
                body: item?.body,
                neck: item?.neck,
                regal: item?.regal,
                kneeLoose: item?.kneeLoose,
                disco: item?.disco,
                canvas: item?.canvas,
                seat1: item?.seat1,
                seat2: item?.seat2,
                seat3: item?.seat3,
                cuttingMasters:item?.productItems?.map((obj) => obj?.cuttingMaster).flat(),
                tailors:item?.productItems?.map((obj)=>obj.tailor).flat(),
                // status:item?.status,
                materials: item?.productItems?.map((material) => {
                  return {
                    mName: material?.itemName,
                    qty: material?.qty,
                    color: material?.colorName,
                    remarks: item?.remarks,
                    cupModelName: item?.cupModelName,
                    stitchingModelname: item?.stitchingModelname,
                    marModelName: item?.marModelName,
                    bottomModelName: item?.bottomModelName,
                    colorModelName: item?.colorModelName,
                    cup: item?.cup,
                    stitching: item?.stitching,
                    marModel: item?.marModel,
                    bottomModel: item?.bottomModel,
                    colour: item?.colour,
                    _uuid:material?._uuid,
                    id:item?.id,
                    status:material?.status,
                    mtr: material?.mtr,
                    mtrUsed:material?.mtrUsed
                  };
                }),
              },
            ];
          }
          break;
        case "pant":
          //create new model for pant
          exist = newModel.items?.find(
            (obj) => obj?.uniqueKey === item?.uniqueKey
          );

          if (exist) {
            console.log("item Exist");
            //item exist merge it
            newModel.items.map((obj) =>
              obj?.uniqueKey === item?.uniqueKey
                ? ((obj.itemId = [...obj.itemId, item?.id]),
                // (obj.status=item?.status)
                  (obj.materials = [
                    ...obj.materials,
                    ...item?.productItems?.map((material) => {
                      return {
                        mName: material?.itemName,
                        qty: material?.qty,
                        color: material?.colorName,
                        remarks: item?.remarks,
                        _uuid:material?._uuid,
                        id:item?.id
                      };
                    }),
                  ]))
                : obj
            );
          } else {
            //item not exist create new object
            newModel.items = [
              ...newModel.items,
              {
                type,
                uniqueKey: item?.uniqueKey,
                relationId: item?.relationId,
                relationName: item?.relationName,
                itemId: [item?.id],
                relations: relation,
                length: item?.length,
                inside: item?.inside,
                waist: item?.waist,
                seat: item?.seat,
                thigh: item?.thigh,
                bottom: item?.bottom,
                ply: item?.ply,
                belt: item?.belt,
                sidePocket: item?.sidePocket,
                backPocket: item?.backPocket,
                pleat: item?.pleat,
                loops: item?.loops,
                cuttingMasters:item?.productItems?.map((obj) => obj?.cuttingMaster).flat(),
                tailors:item?.productItems?.map((obj)=>obj.tailor).flat(),
                // status:item?.status,
                materials: item?.productItems?.map((material) => {
                  return {
                    mName: material?.itemName,
                    qty: material?.qty,
                    color: material?.colorName,
                    remarks: item?.remarks,
                    _uuid:material?._uuid,
                    id:item?.id,
                    status:material?.status,
                    mtr: material?.mtr,
                    mtrUsed:material?.mtrUsed
                  };
                }),
              },
            ];
          }
          break;
        case "coat":
          //create new model for coat
          exist = newModel.items?.find(
            (obj) => obj?.uniqueKey === item?.uniqueKey
          );

          if (exist) {
            console.log("item Exist");
            //item exist merge it
            newModel.items.map((obj) =>
              obj?.uniqueKey === item?.uniqueKey
                ? ((obj.itemId = [...obj.itemId, item?.id]),
                // (obj.status=item?.status)
                  (obj.materials = [
                    ...obj.materials,
                    ...item?.productItems?.map((material) => {
                      return {
                        mName: material?.itemName,
                        qty: material?.qty,
                        color: material?.colorName,
                        remarks: item?.remarks,
                        _uuid:material?._uuid,
                        id:item?.id
                      };
                    }),
                  ]))
                : obj
            );
          } else {
            //item not exist create new object
            newModel.items = [
              ...newModel.items,
              {
                type,
                uniqueKey: item?.uniqueKey,
                relationId: item?.relationId,
                relationName: item?.relationName,
                itemId: [item?.id],
                relations: relation,
                length: item?.length,
                shoulder: item?.shoulder,
                sleeve: item?.sleeve,
                body: item?.body,
                waist: item?.waist,
                seat: item?.seat,
                front: item?.front,
                back: item?.back,
                sleeveLoose: item?.sleeveLoose,
                fpButton: item?.fpButton,
                backOpen: item?.backOpen,
                cuttingMasters:item?.productItems?.map((obj) => obj?.cuttingMaster).flat(),
                tailors:item?.productItems?.map((obj)=>obj.tailor).flat(),
                // status:item?.status,
                materials: item?.productItems?.map((material) => {
                  return {
                    mName: material?.itemName,
                    qty: material?.qty,
                    color: material?.colorName,
                    remarks: item?.remarks,
                    _uuid:material?._uuid,
                    id:item?.id,
                    status:material?.status,
                    mtr: material?.mtr,
                    mtrUsed:material?.mtrUsed
                  };
                }),
              },
            ];
          }

          break;
        case "sharvani":
          //create new model for sharvani
          exist = newModel.items?.find(
            (obj) => obj?.uniqueKey === item?.uniqueKey
          );

          if (exist) {
            console.log("item Exist");
            //item exist merge it
            newModel.items.map((obj) =>
              obj?.uniqueKey === item?.uniqueKey
                ? ((obj.itemId = [...obj.itemId, item?.id]),
                // (obj.status=item?.status)
                  (obj.materials = [
                    ...obj.materials,
                    ...item?.productItems?.map((material) => {
                      return {
                        mName: material?.itemName,
                        qty: material?.qty,
                        color: material?.colorName,
                        remarks: item?.remarks,
                        cupModelName: item?.cupModelName,
                        stitchingModelname: item?.stitchingModelname,
                        marModelName: item?.marModelName,
                        bottomModelName: item?.bottomModelName,
                        colorModelName: item?.colorModelName,
                        cup: item?.cup,
                        stitching: item?.stitching,
                        marModel: item?.marModel,
                        bottomModel: item?.bottomModel,
                        colour: item?.colour,
                        _uuid:material?._uuid,
                        id:item?.id
                      };
                    }),
                  ]))
                : obj
            );
          } else {
            //item not exist create new object
            newModel.items = [
              ...newModel.items,
              {
                type,
                uniqueKey: item?.uniqueKey,
                relationId: item?.relationId,
                relationName: item?.relationName,
                itemId: [item?.id],
                relations: relation,
                length: item?.length,
                shoulder: item?.shoulder,
                sleeveFull: item?.sleeveFull,
                chest: item?.chest,
                waist: item?.waist,
                body: item?.body,
                neck: item?.neck,
                regal: item?.regal,
                kneeLoose: item?.kneeLoose,
                disco: item?.disco,
                canvas: item?.canvas,
                seat1: item?.seat1,
                seat2: item?.seat2,
                seat3: item?.seat3,
                cuttingMasters:item?.productItems?.map((obj) => obj?.cuttingMaster).flat(),
                tailors:item?.productItems?.map((obj)=>obj.tailor).flat(),
                // status:item?.status,
                materials: item?.productItems?.map((material) => {
                  return {
                    mName: material?.itemName,
                    qty: material?.qty,
                    color: material?.colorName,
                    remarks: item?.remarks,
                    cupModelName: item?.cupModelName,
                    stitchingModelname: item?.stitchingModelname,
                    marModelName: item?.marModelName,
                    bottomModelName: item?.bottomModelName,
                    colorModelName: item?.colorModelName,
                    cup: item?.cup,
                    stitching: item?.stitching,
                    marModel: item?.marModel,
                    bottomModel: item?.bottomModel,
                    colour: item?.colour,
                    _uuid:material?._uuid,
                    id:item?.id,
                    status:material?.status,
                    mtr: material?.mtr,
                    mtrUsed:material?.mtrUsed
                  };
                }),
              },
            ];
          }
          break;
        case "safari":
          //create new model for safari
          exist = newModel.items?.find(
            (obj) => obj?.uniqueKey === item?.uniqueKey
          );

          if (exist) {
            console.log("item Exist");
            //item exist merge it
            newModel.items.map((obj) =>
              obj?.uniqueKey === item?.uniqueKey
                ? ((obj.itemId = [...obj.itemId, item?.id]),
                // (obj.status=item?.status)
                  (obj.materials = [
                    ...obj.materials,
                    ...item?.productItems?.map((material) => {
                      return {
                        mName: material?.itemName,
                        qty: material?.qty,
                        color: material?.colorName,
                        remarks: item?.remarks,
                        cupModelName: item?.cupModelName,
                        stitchingModelname: item?.stitchingModelname,
                        marModelName: item?.marModelName,
                        bottomModelName: item?.bottomModelName,
                        colorModelName: item?.colorModelName,
                        cup: item?.cup,
                        stitching: item?.stitching,
                        marModel: item?.marModel,
                        bottomModel: item?.bottomModel,
                        colour: item?.colour,
                        _uuid:material?._uuid,
                        id:item?.id
                      };
                    }),
                  ]))
                : obj
            );
          } else {
            //item not exist create new object
            newModel.items = [
              ...newModel.items,
              {
                type,
                uniqueKey: item?.uniqueKey,
                relationId: item?.relationId,
                relationName: item?.relationName,
                itemId: [item?.id],
                relations: relation,
                length: item?.length,
                shoulder: item?.shoulder,
                sleeveFull: item?.sleeveFull,
                chest: item?.chest,
                waist: item?.waist,
                body: item?.body,
                neck: item?.neck,
                regal: item?.regal,
                kneeLoose: item?.kneeLoose,
                disco: item?.disco,
                canvas: item?.canvas,
                seat1: item?.seat1,
                seat2: item?.seat2,
                seat3: item?.seat3,
                cuttingMasters:item?.productItems?.map((obj) => obj?.cuttingMaster).flat(),
                tailors:item?.productItems?.map((obj)=>obj.tailor).flat(),
                // status:item?.status,
                materials: item?.productItems?.map((material) => {
                  return {
                    mName: material?.itemName,
                    qty: material?.qty,
                    color: material?.colorName,
                    remarks: item?.remarks,
                    cupModelName: item?.cupModelName,
                    stitchingModelname: item?.stitchingModelname,
                    marModelName: item?.marModelName,
                    bottomModelName: item?.bottomModelName,
                    colorModelName: item?.colorModelName,
                    cup: item?.cup,
                    stitching: item?.stitching,
                    marModel: item?.marModel,
                    bottomModel: item?.bottomModel,
                    colour: item?.colour,
                    _uuid:material?._uuid,
                    id:item?.id,
                    status:material?.status,
                    mtr: material?.mtr,
                    mtrUsed:material?.mtrUsed
                  };
                }),
              },
            ];
          }
          break;

        default:
          break;
      }
    });
    // console.log(newModel);
    //   setCuttingSlipNewModel(newModel);
    return newModel;
    //TODO set newModel into a state and render it in ui instead of already rendered state
  } else {
    //   setCuttingSlipNewModel([]);
    return null;
  }
};

// console.log(cuttingSlipNewModel);

//Check Static measurements are same or not (entry point)
export const splitRelationsOrCustomer = (singleViewRes) => {
  const typeArrays = createTypeWiseArray(singleViewRes);
  console.log(singleViewRes);
  //list of slip object based on customer id
  let clientThobs = {};
  let clientJalabias = {};
  let clientSalwars = {};
  let clientAbayas = {};
  let clientChuridhars = {};
  let clientShirts = {};
  let clientPants = {};
  let clientCoats = {};
  let clientSharvanis = {};
  let clientSafaris = {};

  //Keys(mainCus and each relation ids) of each model
  let thobKeys = [];
  let jalabiaKeys = [];
  let salwarKeys = [];
  let abayaKeys = [];
  let churidharKeys = [];
  let shirtKeys = [];
  let pantKeys = [];
  let coatKeys = [];
  let sharvaniKeys = [];
  let safariKeys = [];

  // let thobsToMerge=[]

  //create customer array and relations array of thob model
  createCustomerOrRelationWiseArray(typeArrays?.thobArray, clientThobs);
  //create customer array and relations array of jalabia model
  createCustomerOrRelationWiseArray(typeArrays?.jalabiaArray, clientJalabias);
  //create customer array and relations array of salwar model
  createCustomerOrRelationWiseArray(typeArrays?.salwarArray, clientSalwars);
  //create customer array and relations array of abaya model
  createCustomerOrRelationWiseArray(typeArrays?.abayaArray, clientAbayas);
  //create customer array and relations array of churidhar model
  createCustomerOrRelationWiseArray(
    typeArrays?.churidharArray,
    clientChuridhars
  );
  //create customer array and relations array of shirt model
  createCustomerOrRelationWiseArray(typeArrays?.shirtArray, clientShirts);
  //create customer array and relations array of pant model
  createCustomerOrRelationWiseArray(typeArrays?.pantArray, clientPants);
  //create customer array and relations array of coat model
  createCustomerOrRelationWiseArray(typeArrays?.coatArray, clientCoats);
  //create customer array and relations array of sharvani model
  createCustomerOrRelationWiseArray(typeArrays?.sharvaniArray, clientSharvanis);
  //create customer array and relations array of safari model
  createCustomerOrRelationWiseArray(typeArrays?.safariArray, clientSafaris);

  //TODO client based filtered arrays created
  //TODO check static keys, if same merge rest in each client based arrays
  //Create items array in all types
  //check array is for main customer or not
  //if main customer check all objects and merge
  //else check corresponding relation objects and merge
  thobKeys = Object.keys(clientThobs);
  jalabiaKeys = Object.keys(clientJalabias);
  salwarKeys = Object.keys(clientSalwars);
  abayaKeys = Object.keys(clientAbayas);
  churidharKeys = Object.keys(clientChuridhars);
  shirtKeys = Object.keys(clientShirts);
  pantKeys = Object.keys(clientPants);
  coatKeys = Object.keys(clientCoats);
  sharvaniKeys = Object.keys(clientSharvanis);
  safariKeys = Object.keys(clientSafaris);

  //Add uniqueKey in thob cutting slips
  if (thobKeys.length > 0) {
    for (let i = 0; i < thobKeys.length; i++) {
      const clientSlips = clientThobs[thobKeys[i]];

      for (let j = 0; j < clientSlips?.length; j++) {
        const pivObj = clientSlips[j];
        console.log(pivObj);
        for (let k = j + 1; k <= clientSlips.length; k++) {
          const restObj = clientSlips[k];

          if (restObj !== undefined) {
            if ("uniqueKey" in restObj) continue;
            else if (
              pivObj.length === restObj.length &&
              pivObj.shoulder === restObj.shoulder &&
              pivObj.sleeve === restObj.sleeve &&
              pivObj.chest === restObj.chest &&
              pivObj.stomach === restObj.stomach &&
              pivObj.slChest === restObj.slChest &&
              pivObj.slSo === restObj.slSo &&
              pivObj.marSize === restObj.marSize &&
              pivObj.regalSize === restObj.regalSize &&
              pivObj.kneeLoose === restObj.kneeLoose &&
              pivObj.bottom === restObj.bottom &&
              pivObj.neck === restObj.neck &&
              pivObj.fpDown === restObj.fpDown &&
              pivObj.fpSize1 === restObj.fpSize1 &&
              pivObj.fpSize2 === restObj.fpSize2 &&
              pivObj.neckDButton === restObj.neckDButton
            ) {
              //objects are same  marge to one
              //add merged objects into an array
              restObj.uniqueKey = `${i}${j}t`;
              console.log("Objects are same");
            } else {
              //objects are not same leave as it is
              console.log("Objects are different");
            }
          } else {
            //No more objects to check
            console.log("uniqueKey" in pivObj);

            if ("uniqueKey" in pivObj === false) pivObj.uniqueKey = `${i}${j}t`;
            //default add to new structure
            console.log(
              "No more objects, Total No of thob objects : " + (j + 1)
            );
          }
        }

        console.log(pivObj);
      }

      //compare objects inside clientSlips array and merge if they are equal
    }
  }
  //Add uniqueKey in Jalabia cutting slips
  if (jalabiaKeys.length > 0) {
    for (let i = 0; i < jalabiaKeys.length; i++) {
      const clientSlips = clientJalabias[jalabiaKeys[i]];

      for (let j = 0; j < clientSlips?.length; j++) {
        const pivObj = clientSlips[j];
        console.log(pivObj);
        for (let k = j + 1; k <= clientSlips.length; k++) {
          const restObj = clientSlips[k];

          if (restObj !== undefined) {
            if ("uniqueKey" in restObj) continue;
            else if (
              pivObj.length === restObj.length &&
              pivObj.shoulder === restObj.shoulder &&
              pivObj.sleeve === restObj.sleeve &&
              pivObj.chest === restObj.chest &&
              pivObj.stomach === restObj.stomach &&
              pivObj.slChest === restObj.slChest &&
              pivObj.slSto === restObj.slSto &&
              pivObj.neck === restObj.neck &&
              pivObj.bottom === restObj.bottom &&
              pivObj.sleeveSize === restObj.sleeveSize &&
              pivObj.regalSize === restObj.regalSize &&
              pivObj.neckDownButton === restObj.neckDownButton &&
              pivObj.mar === restObj.mar &&
              pivObj.fpSize1 === restObj.fpSize1 &&
              pivObj.fpSize2 === restObj.fpSize2
            ) {
              //objects are same  marge to one
              //add merged objects into an array
              restObj.uniqueKey = `${i}${j}j`;
              console.log("Objects are same");
            } else {
              //objects are not same leave as it is
              console.log("Objects are different");
            }
          } else {
            //No more objects to check
            console.log("uniqueKey" in pivObj);

            if ("uniqueKey" in pivObj === false) pivObj.uniqueKey = `${i}${j}j`;
            //default add to new structure
            console.log(
              "No more objects, Total No of jalabia objects : " + (j + 1)
            );
          }
        }

        console.log(pivObj);
      }

      //compare objects inside clientSlips array and merge if they are equal
    }
  }
  //Add uniqueKey in Salwar cutting slips
  if (salwarKeys.length > 0) {
    for (let i = 0; i < salwarKeys.length; i++) {
      const clientSlips = clientSalwars[salwarKeys[i]];

      for (let j = 0; j < clientSlips?.length; j++) {
        const pivObj = clientSlips[j];
        console.log(pivObj);
        for (let k = j + 1; k <= clientSlips.length; k++) {
          const restObj = clientSlips[k];

          if (restObj !== undefined) {
            if ("uniqueKey" in restObj) continue;
            else if (
              pivObj.length === restObj.length &&
              pivObj.waist === restObj.waist &&
              pivObj.bottom === restObj.bottom &&
              pivObj.elasticSize === restObj.elasticSize &&
              pivObj.ply === restObj.ply &&
              pivObj.thighLoose === restObj.thighLoose
            ) {
              //objects are same  marge to one
              //add merged objects into an array
              restObj.uniqueKey = `${i}${j}sal`;
              console.log("Objects are same");
            } else {
              //objects are not same leave as it is
              console.log("Objects are different");
            }
          } else {
            //No more objects to check
            console.log("uniqueKey" in pivObj);

            if ("uniqueKey" in pivObj === false)
              pivObj.uniqueKey = `${i}${j}sal`;
            //default add to new structure
            console.log(
              "No more objects, Total No of Salwar objects : " + (j + 1)
            );
          }
        }

        console.log(pivObj);
      }

      //compare objects inside clientSlips array and merge if they are equal
    }
  }
  //Add uniqueKey in Abaya cutting slips
  if (abayaKeys.length > 0) {
    for (let i = 0; i < abayaKeys.length; i++) {
      const clientSlips = clientAbayas[abayaKeys[i]];

      for (let j = 0; j < clientSlips?.length; j++) {
        const pivObj = clientSlips[j];
        console.log(pivObj);
        for (let k = j + 1; k <= clientSlips.length; k++) {
          const restObj = clientSlips[k];

          if (restObj !== undefined) {
            if ("uniqueKey" in restObj) continue;
            else if (
              pivObj.sleeveFromNeck === restObj.sleeveFromNeck &&
              pivObj.sleeveFromShoulder === restObj.sleeveFromShoulder &&
              pivObj.sleeveWidth === restObj.sleeveWidth &&
              pivObj.arms === restObj.arms &&
              pivObj.bust === restObj.bust &&
              pivObj.waist === restObj.waist &&
              pivObj.hip === restObj.hip &&
              pivObj.length === restObj.length
            ) {
              //objects are same  marge to one
              //add merged objects into an array
              restObj.uniqueKey = `${i}${j}aba`;
              console.log("Objects are same");
            } else {
              //objects are not same leave as it is
              console.log("Objects are different");
            }
          } else {
            //No more objects to check
            console.log("uniqueKey" in pivObj);

            if ("uniqueKey" in pivObj === false)
              pivObj.uniqueKey = `${i}${j}aba`;
            //default add to new structure
            console.log(
              "No more objects, Total No of abaya objects : " + (j + 1)
            );
          }
        }

        console.log(pivObj);
      }

      //compare objects inside clientSlips array and merge if they are equal
    }
  }
  //Add uniqueKey in Churidhar cutting slips
  if (churidharKeys.length > 0) {
    for (let i = 0; i < churidharKeys.length; i++) {
      const clientSlips = clientChuridhars[churidharKeys[i]];

      for (let j = 0; j < clientSlips?.length; j++) {
        const pivObj = clientSlips[j];
        console.log(pivObj);
        for (let k = j + 1; k <= clientSlips.length; k++) {
          const restObj = clientSlips[k];

          if (restObj !== undefined) {
            if ("uniqueKey" in restObj) continue;
            else if (
              pivObj.shoulder === restObj.shoulder &&
              pivObj.frontNeck === restObj.frontNeck &&
              pivObj.backNeck === restObj.backNeck &&
              pivObj.waist === restObj.waist &&
              pivObj.hip === restObj.hip &&
              pivObj.fullLength === restObj.fullLength &&
              pivObj.armRound === restObj.armRound &&
              pivObj.armHole === restObj.armHole &&
              pivObj.sleeveLength === restObj.sleeveLength
            ) {
              //objects are same  marge to one
              //add merged objects into an array
              restObj.uniqueKey = `${i}${j}chu`;
              console.log("Objects are same");
            } else {
              //objects are not same leave as it is
              console.log("Objects are different");
            }
          } else {
            //No more objects to check
            console.log("uniqueKey" in pivObj);

            if ("uniqueKey" in pivObj === false)
              pivObj.uniqueKey = `${i}${j}chu`;
            //default add to new structure
            console.log(
              "No more objects, Total No of churidhar objects : " + (j + 1)
            );
          }
        }

        console.log(pivObj);
      }

      //compare objects inside clientSlips array and merge if they are equal
    }
  }
  //Add uniqueKey in Shirt cutting slips
  if (shirtKeys.length > 0) {
    for (let i = 0; i < shirtKeys.length; i++) {
      const clientSlips = clientShirts[shirtKeys[i]];

      for (let j = 0; j < clientSlips?.length; j++) {
        const pivObj = clientSlips[j];
        console.log(pivObj);
        for (let k = j + 1; k <= clientSlips.length; k++) {
          const restObj = clientSlips[k];

          if (restObj !== undefined) {
            if ("uniqueKey" in restObj) continue;
            else if (
              pivObj.length === restObj.length &&
              pivObj.shoulder === restObj.shoulder &&
              pivObj.sleeveFull === restObj.sleeveFull &&
              pivObj.chest === restObj.chest &&
              pivObj.waist === restObj.waist &&
              pivObj.body === restObj.body &&
              pivObj.neck === restObj.neck &&
              pivObj.regal === restObj.regal &&
              pivObj.kneeLoose === restObj.kneeLoose &&
              pivObj.disco === restObj.disco &&
              pivObj.canvas === restObj.canvas &&
              pivObj.seat1 === restObj.seat1 &&
              pivObj.seat2 === restObj.seat2 &&
              pivObj.seat3 === restObj.seat3
            ) {
              //objects are same  marge to one
              //add merged objects into an array
              restObj.uniqueKey = `${i}${j}shir`;
              console.log("Objects are same");
            } else {
              //objects are not same leave as it is
              console.log("Objects are different");
            }
          } else {
            //No more objects to check
            console.log("uniqueKey" in pivObj);

            if ("uniqueKey" in pivObj === false)
              pivObj.uniqueKey = `${i}${j}shir`;
            //default add to new structure
            console.log(
              "No more objects, Total No of Shirt objects : " + (j + 1)
            );
          }
        }

        console.log(pivObj);
      }

      //compare objects inside clientSlips array and merge if they are equal
    }
  }
  //Add uniqueKey in Pant cutting slips
  if (pantKeys.length > 0) {
    for (let i = 0; i < pantKeys.length; i++) {
      const clientSlips = clientPants[pantKeys[i]];

      for (let j = 0; j < clientSlips?.length; j++) {
        const pivObj = clientSlips[j];
        console.log(pivObj);
        for (let k = j + 1; k <= clientSlips.length; k++) {
          const restObj = clientSlips[k];

          if (restObj !== undefined) {
            if ("uniqueKey" in restObj) continue;
            else if (
              pivObj.length === restObj.length &&
              pivObj.inside === restObj.inside &&
              pivObj.waist === restObj.waist &&
              pivObj.seat === restObj.seat &&
              pivObj.thigh === restObj.thigh &&
              pivObj.bottom === restObj.bottom &&
              pivObj.ply === restObj.ply &&
              pivObj.belt === restObj.belt &&
              pivObj.sidePocket === restObj.sidePocket &&
              pivObj.backPocket === restObj.backPocket &&
              pivObj.pleat === restObj.pleat &&
              pivObj.loops === restObj.loops
            ) {
              //objects are same  marge to one
              //add merged objects into an array
              restObj.uniqueKey = `${i}${j}pant`;
              console.log("Objects are same");
            } else {
              //objects are not same leave as it is
              console.log("Objects are different");
            }
          } else {
            //No more objects to check
            console.log("uniqueKey" in pivObj);

            if ("uniqueKey" in pivObj === false)
              pivObj.uniqueKey = `${i}${j}pant`;
            //default add to new structure
            console.log(
              "No more objects, Total No of pant objects : " + (j + 1)
            );
          }
        }

        console.log(pivObj);
      }

      //compare objects inside clientSlips array and merge if they are equal
    }
  }

  //Add uniqueKey in coat cutting slips
  if (coatKeys.length > 0) {
    for (let i = 0; i < coatKeys.length; i++) {
      const clientSlips = clientCoats[coatKeys[i]];

      for (let j = 0; j < clientSlips?.length; j++) {
        const pivObj = clientSlips[j];
        console.log(pivObj);
        for (let k = j + 1; k <= clientSlips.length; k++) {
          const restObj = clientSlips[k];

          if (restObj !== undefined) {
            if ("uniqueKey" in restObj) continue;
            else if (
              pivObj.length === restObj.length &&
              pivObj.shoulder === restObj.shoulder &&
              pivObj.sleeve === restObj.sleeve &&
              pivObj.body === restObj.body &&
              pivObj.waist === restObj.waist &&
              pivObj.seat === restObj.seat &&
              pivObj.front === restObj.front &&
              pivObj.back === restObj.back &&
              pivObj.sleeveLoose === restObj.sleeveLoose &&
              pivObj.fpButton === restObj.fpButton &&
              pivObj.backOpen === restObj.backOpen
            ) {
              //objects are same  marge to one
              //add merged objects into an array
              restObj.uniqueKey = `${i}${j}coat`;
              console.log("Objects are same");
            } else {
              //objects are not same leave as it is
              console.log("Objects are different");
            }
          } else {
            //No more objects to check
            console.log("uniqueKey" in pivObj);

            if ("uniqueKey" in pivObj === false)
              pivObj.uniqueKey = `${i}${j}coat`;
            //default add to new structure
            console.log(
              "No more objects, Total No of coat objects : " + (j + 1)
            );
          }
        }

        console.log(pivObj);
      }

      //compare objects inside clientSlips array and merge if they are equal
    }
  }
  //Add uniqueKey in Sharvani cutting slips
  if (sharvaniKeys.length > 0) {
    for (let i = 0; i < sharvaniKeys.length; i++) {
      const clientSlips = clientSharvanis[sharvaniKeys[i]];

      for (let j = 0; j < clientSlips?.length; j++) {
        const pivObj = clientSlips[j];
        console.log(pivObj);
        for (let k = j + 1; k <= clientSlips.length; k++) {
          const restObj = clientSlips[k];

          if (restObj !== undefined) {
            if ("uniqueKey" in restObj) continue;
            else if (
              pivObj.length === restObj.length &&
              pivObj.shoulder === restObj.shoulder &&
              pivObj.sleeveFull === restObj.sleeveFull &&
              pivObj.chest === restObj.chest &&
              pivObj.waist === restObj.waist &&
              pivObj.body === restObj.body &&
              pivObj.neck === restObj.neck &&
              pivObj.regal === restObj.regal &&
              pivObj.kneeLoose === restObj.kneeLoose &&
              pivObj.disco === restObj.disco &&
              pivObj.canvas === restObj.canvas &&
              pivObj.seat1 === restObj.seat1 &&
              pivObj.seat2 === restObj.seat2 &&
              pivObj.seat3 === restObj.seat3
            ) {
              //objects are same  marge to one
              //add merged objects into an array
              restObj.uniqueKey = `${i}${j}shar`;
              console.log("Objects are same");
            } else {
              //objects are not same leave as it is
              console.log("Objects are different");
            }
          } else {
            //No more objects to check
            console.log("uniqueKey" in pivObj);

            if ("uniqueKey" in pivObj === false)
              pivObj.uniqueKey = `${i}${j}shar`;
            //default add to new structure
            console.log(
              "No more objects, Total No of sharvani objects : " + (j + 1)
            );
          }
        }

        console.log(pivObj);
      }

      //compare objects inside clientSlips array and merge if they are equal
    }
  }
  //Add uniqueKey in Safari cutting slips
  if (safariKeys.length > 0) {
    for (let i = 0; i < safariKeys.length; i++) {
      const clientSlips = clientSafaris[safariKeys[i]];

      for (let j = 0; j < clientSlips?.length; j++) {
        const pivObj = clientSlips[j];
        console.log(pivObj);
        for (let k = j + 1; k <= clientSlips.length; k++) {
          const restObj = clientSlips[k];

          if (restObj !== undefined) {
            if ("uniqueKey" in restObj) continue;
            else if (
              pivObj.length === restObj.length &&
              pivObj.shoulder === restObj.shoulder &&
              pivObj.sleeveFull === restObj.sleeveFull &&
              pivObj.chest === restObj.chest &&
              pivObj.waist === restObj.waist &&
              pivObj.body === restObj.body &&
              pivObj.neck === restObj.neck &&
              pivObj.regal === restObj.regal &&
              pivObj.kneeLoose === restObj.kneeLoose &&
              pivObj.disco === restObj.disco &&
              pivObj.canvas === restObj.canvas &&
              pivObj.seat1 === restObj.seat1 &&
              pivObj.seat2 === restObj.seat2 &&
              pivObj.seat3 === restObj.seat3
            ) {
              //objects are same  marge to one
              //add merged objects into an array
              restObj.uniqueKey = `${i}${j}saf`;
              console.log("Objects are same");
            } else {
              //objects are not same leave as it is
              console.log("Objects are different");
            }
          } else {
            //No more objects to check
            console.log("uniqueKey" in pivObj);

            if ("uniqueKey" in pivObj === false)
              pivObj.uniqueKey = `${i}${j}saf`;
            //default add to new structure
            console.log(
              "No more objects, Total No of safari objects : " + (j + 1)
            );
          }
        }

        console.log(pivObj);
      }

      //compare objects inside clientSlips array and merge if they are equal
    }
  }

  let newModelCuttingSlip = createNewModelCuttingSlip(singleViewRes);
  // console.log(thobKeys);
  // console.log(jalabiaKeys);
  // console.log(salwarKeys);
  // console.log(churidharKeys);
  return newModelCuttingSlip;
};
//-----------------------------------------------------
