import { createSlice } from "@reduxjs/toolkit";

export const workOrderNosSlice=createSlice({
    name:'workOrderNosSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_work_order_nos:(state,action)=>{
            state.value=action.payload.orkOrderNos
        }
    }
})
export const {update_work_order_nos} = workOrderNosSlice.actions
export default workOrderNosSlice.reducer