import {createSlice} from '@reduxjs/toolkit'

export const recentSaleSlice=createSlice({
    name:"recentSaleSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        get_recent_sale:(state,action)=>{
            state.value=action.payload.recentSaleData
        }
    }
})
export const {get_recent_sale}=recentSaleSlice.actions;
export default recentSaleSlice.reducer