import { createSlice } from "@reduxjs/toolkit";

export const wareHouseSlice = createSlice({
  name: "wareHouseSlice",
  initialState: {
    value: undefined,
    singleValue:undefined
  },
  reducers: {
    get_wareHouse_list: (state, action) => {
      state.value = action.payload.wareHouseData;
    },
    get_wareHouse_single_view:(state,action)=>{
      state.singleValue=action.payload.singleWareHouseData
    }
  
  },
});

export const {get_wareHouse_list,get_wareHouse_single_view} = wareHouseSlice.actions;

export default wareHouseSlice.reducer;