import { IconButton } from "@mui/material";
import React, { useState } from "react";
import "../../../../css/Settings/Utility/utilityCorrections.css"
import { Datepicker } from "../../../Single Components/Datepicker";

export const UtilityCorrections=()=>{
    const [fromDate,setFromDate]=useState("")
    const [toDate,setToDate]=useState("")


return(
    <div className="utility-corrections-container">
        <div className="utility-corrections-container-top">
            <p>Order Listed</p>
            <div className="utility-corrections-container-top-right">
                <Datepicker type="date" boxShadow="0 0 8px 2px #eee" value={fromDate} onChange={(e)=>setFromDate(e.target.value)}/>
                <Datepicker type="date" boxShadow="0 0 8px 2px #eee" value={toDate} onChange={(e)=>setToDate(e.target.value)}/>
            </div>
        </div>
    <div className="global-table-container" style={{width:'98%'}}>
        <table className="global-table">
            <thead>
                <tr>
                    <th>Order ID</th>
                    <th>Date</th>
                    <th>Customer Name</th>
                    <th>Location</th>
                    <th>Amount</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>LKGZAHH322</td>
                    <td>07-12-2022</td>
                    <td>Neehaad ali</td>
                    <td>Jaka</td>
                    <td>4,0001</td>
                    <td><IconButton>
                            <i class="bi bi-pencil-square edit-icon"></i>
                        </IconButton></td>
                    <td><IconButton>
                            <i class="bi bi-trash3 delete-icon"></i>
                        </IconButton></td>
                </tr>
                <tr>
                    <td>LKGZAHH322</td>
                    <td>07-12-2022</td>
                    <td>Neehaad ali</td>
                    <td>Jaka</td>
                    <td>4,0001</td>
                    <td><IconButton>
                            <i class="bi bi-pencil-square edit-icon"></i>
                        </IconButton></td>
                    <td><IconButton>
                            <i class="bi bi-trash3 delete-icon"></i>
                        </IconButton></td>
                </tr>
                <tr>
                    <td>LKGZAHH322</td>
                    <td>07-12-2022</td>
                    <td>Neehaad ali</td>
                    <td>Jaka</td>
                    <td>4,0001</td>
                    <td><IconButton>
                            <i class="bi bi-pencil-square edit-icon"></i>
                        </IconButton></td>
                    <td><IconButton>
                            <i class="bi bi-trash3 delete-icon"></i>
                        </IconButton></td>
                </tr>
                <tr>
                    <td>LKGZAHH322</td>
                    <td>07-12-2022</td>
                    <td>Neehaad ali</td>
                    <td>Jaka</td>
                    <td>4,0001</td>
                    <td><IconButton>
                            <i class="bi bi-pencil-square edit-icon"></i>
                        </IconButton></td>
                    <td><IconButton>
                            <i class="bi bi-trash3 delete-icon"></i>
                        </IconButton></td>
                </tr>
                <tr>
                    <td>LKGZAHH322</td>
                    <td>07-12-2022</td>
                    <td>Neehaad ali</td>
                    <td>Jaka</td>
                    <td>4,0001</td>
                    <td><IconButton>
                            <i class="bi bi-pencil-square edit-icon"></i>
                        </IconButton></td>
                    <td><IconButton>
                            <i class="bi bi-trash3 delete-icon"></i>
                        </IconButton></td>
                </tr>
            </tbody>
            </table>
        </div>
    </div>
)
}