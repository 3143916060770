import { createSlice } from "@reduxjs/toolkit";

export const employeeDocViewSlice=createSlice({
    name:'employeeDocViewSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_employee_doc_proof_list:(state,action)=>{
            state.value=action.payload.employeeDocView
        }
    }
})

export const {update_employee_doc_proof_list}=employeeDocViewSlice.actions
export default employeeDocViewSlice.reducer