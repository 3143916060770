import { createSlice } from "@reduxjs/toolkit";

export const productCareSlice=createSlice({
    name:'productCareSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_productCare_list:(state,action)=>{
            state.value=action.payload.productCareList
        }
    }
})

export const {update_productCare_list}=productCareSlice.actions
export default productCareSlice.reducer