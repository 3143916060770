import {createSlice} from '@reduxjs/toolkit'

export const allAlterationIdSlice=createSlice({
    name:'allAlterationId',
    initialState:{
        value:undefined
    },
    reducers:{
      get_all_alteration_id:(state,action)=>{
        state.value=action.payload.allAlterationIdData
      }
    }
})
export const {get_all_alteration_id} = allAlterationIdSlice.actions;
export default allAlterationIdSlice.reducer