import React, { useEffect, useRef, useState } from "react";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useSelector } from "react-redux";
import { today } from "../../../../../Js/Date";
import { accountRecievableAgingReportSingleViewApi } from "./partnerReportApi";
import { useNavigate } from "react-router-dom";
import { LoadingForm } from "../../../../Single Components/LoadingForm";
import Template from "./AccountAgingSingleViewPrint"
import { companyProfileInfoForUser } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import ReactToPrint from "react-to-print";
import { convertArrayToExcel } from "../../../../../Js/generalFunctions";

const AccountReceivableAgingReportSingleView = () => {
  let paymentsPrintNew = useRef();

  const companyProfileData =useSelector(
    (state) => state?.companyProfileSlice?.userValue)

  const singleViewId = localStorage.getItem("singleViewById");
 const [isLoading,setIsLoading] = useState(true)
 const EndDate = localStorage.getItem("toDate");
  const navigate = useNavigate();
  const { AccountReceivableAgingReportListSingleView } = useSelector(
    (res) => res.PartnerReport
  );


  const initialvalue ={
    label:"Account Payable Aging Report",
    sumOfcurrent:0,
    sumOf31:0,
    sumOf61:0,
    sumOf91:0,
    sumOf121:0,
    sumOf151:0,
    sumOfAbove151:0,
    sumOfTotal:0,
  
  companyLogo: "",
  companyName: "",
  companyAddress: "",
  companyContact: "",
  }
    
    const [data,setData]=useState([])
    const [formValues,setFormValues]=useState(initialvalue)
  
  useEffect(()=>{
    setFormValues({...formValues,
      sumOfcurrent:AccountReceivableAgingReportListSingleView ?.sumOfcurrent||0,
    sumOf31:AccountReceivableAgingReportListSingleView ?.sumOf31||0,
    sumOf61:AccountReceivableAgingReportListSingleView ?.sumOf61||0,
    sumOf91:AccountReceivableAgingReportListSingleView ?.sumOf91||0,
    sumOf121:AccountReceivableAgingReportListSingleView ?.sumOf121||0,
    sumOf151:AccountReceivableAgingReportListSingleView ?.sumOf151||0,
    sumOfAbove151:AccountReceivableAgingReportListSingleView ?.sumOfAbove151||0,
    sumOfTotal:AccountReceivableAgingReportListSingleView ?.sumOfTotal||0,
  
      companyLogo: companyProfileData?.logo || "",
      companyName: companyProfileData?.companyName || "",
      companyAddress: companyProfileData?.companyAddress || "",
      companyContact: companyProfileData?.contactNumber || "",
  
    });
    setData(AccountReceivableAgingReportListSingleView ?.list)
  },[AccountReceivableAgingReportListSingleView ,companyProfileData])

  const downloadList=()=>{
    convertArrayToExcel(AccountReceivableAgingReportListSingleView ?.list,"payableReport")
  }

// console.log(AccountReceivableAgingReportListSingleView,'ggg')

  useEffect(() => {
    companyProfileInfoForUser();
    accountRecievableAgingReportSingleViewApi({
      todayDate:EndDate?EndDate: `${today} 23:59:59`,
      customerId: singleViewId,
    },setIsLoading);
  }, []);

  return (
    <div className="in-or-out-container">
      <div className="in-or-out-report-wrapper">
        <div className="sub-nav-head">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "0.8em",
            }}
          >
          
            <div
              style={{
                background: "#d2deff",
                borderRadius: "0",
                maxWidth: "20em",
                cursor:'pointer'
              }}
              className="path-name-wrapper"
              onClick={() => {
                navigate(
                  "/userdashboard/accounts/report/partnerReport/AccountReceivableAgingReport"
                );
                localStorage.removeItem("singleViewById");
              }}
            >
              <h3 className="path-name" style={{ color: "black" }}>
                Account Receivable Aging Report
              </h3>
            </div>
            <div
              className="path-name-wrapper"
              style={{
                maxWidth: "17em",
                transition: "max-width 1s ease-in-out",
              }}
            >
              <h3 className="path-name">
                {AccountReceivableAgingReportListSingleView.customerName}
              </h3>
            </div>
            <div >
          <Tooltip title="Share">
          <IconButton>
            <i class="bi bi-share" style={{ backgroundColor: "#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>

        <ReactToPrint
          trigger={() => (
            <IconButton className="printer-div" style={{ padding: "1px" }}>
              <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
            </IconButton>
          )}
          content={() => paymentsPrintNew}
        />

        <Tooltip title="Download">
          <IconButton onClick={downloadList}>
            <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>

        </div>
          </div>
        </div>
        <div className="table-wrapper-container">
          <TableContainer sx={{ maxHeight: "75vh" }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              className="transaction-table"
              sx={{ padding: 0 }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Particular</TableCell>
                  <TableCell>Current</TableCell>
                  <TableCell>1 to 30</TableCell>
                  <TableCell>31 to 60</TableCell>
                  <TableCell>61 to 90</TableCell>
                  <TableCell>91 to 120</TableCell>
                  <TableCell>121 to 150</TableCell>
                  <TableCell>150 Above</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {AccountReceivableAgingReportListSingleView?.list?.length >
                0 ? (
                  AccountReceivableAgingReportListSingleView?.list?.map(
                    (item, index) => (
                      <TableRow
                        key={index}
                        className={index % 2 === 0 ? "even-row " : ""}
                      >
                        <TableCell>{item?.invoiceDate}</TableCell>
                        <TableCell>{item?.name}</TableCell>
                        <TableCell className="amount-td">{item?.categories?.current}</TableCell>
                        <TableCell className="amount-td">{item?.categories?.upto30days}</TableCell>
                        <TableCell className="amount-td">{item?.categories?.upto60days}</TableCell>
                        <TableCell className="amount-td">{item?.categories?.upto90days}</TableCell>
                        <TableCell className="amount-td">{item?.categories?.upto120days}</TableCell>
                        <TableCell className="amount-td">{item?.categories?.upto150days}</TableCell>
                        <TableCell className="amount-td">
                          {item?.categories?.morethan150days}
                        </TableCell>
                        <TableCell className="amount-td">{item?.total}</TableCell>
                      </TableRow>
                    )
                  )
                ) : (
                  <TableRow>
                    <TableCell className="no-data-td" colSpan={9}>
                      NO DATA
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter style={{ position: "sticky", bottom: 0 }}>
                <TableRow className="input-and-output-total">
                  <TableCell>Total </TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    {AccountReceivableAgingReportListSingleView?.sumOfcurrent}
                  </TableCell>
                  <TableCell>
                    {AccountReceivableAgingReportListSingleView?.sumOf31}
                  </TableCell>
                  <TableCell>
                    {AccountReceivableAgingReportListSingleView?.sumOf61}
                  </TableCell>
                  <TableCell>
                    {AccountReceivableAgingReportListSingleView?.sumOf91}
                  </TableCell>
                  <TableCell>
                    {AccountReceivableAgingReportListSingleView?.sumOf121}
                  </TableCell>
                  <TableCell>
                    {AccountReceivableAgingReportListSingleView?.sumOf151}
                  </TableCell>
                  <TableCell>
                    {AccountReceivableAgingReportListSingleView?.sumOfAbove151}
                  </TableCell>
                  <TableCell>
                    {AccountReceivableAgingReportListSingleView?.currencySymbol}{" "}
                    {AccountReceivableAgingReportListSingleView?.sumOfTotal}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </div>
      <LoadingForm loading={isLoading} />

      <div style={{ display: "none" }}>
  <div ref={(el) => (paymentsPrintNew = el)}>
      <Template  data={data} formValues={formValues}/> 
  </div>
</div>
    </div>
  );
};

export default AccountReceivableAgingReportSingleView;
