import { createSlice } from "@reduxjs/toolkit";

export const rackListSlice=createSlice({
    name:'rackListSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_rackList:(state,action)=>{
            state.value=action.payload.rackList
        }
    }
})
export const {update_rackList} = rackListSlice.actions
export default rackListSlice.reducer