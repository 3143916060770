import React, { useEffect, useState } from "react";
import payRollSideMenu from "../../../../../Assets/JSON/payRollSideMenu.json";
import "../../../../../css/Account/createInvoice/payroll.css";
import { List, ListItemButton, ListItemText, Typography } from "@mui/material";
import EmployeeCreation from "./payRollGroups/EmployeeCreation";
import PayrollInvoice from "./payRollGroups/PayrollInvoice";
import EmployeeGroupCreation from "./payRollGroups/EmployeeGroupCreation";

const PayRoll = () => {
  const [active, setActive] = useState(0);
  const [selectListItem, setSelectListItem] = useState({ id: null, name: "" });

  useEffect(() => {
    handleListItemClick(0, 1, " Employee Creation");
  }, []);

  const handleListItemClick = (index, id, name) => {
    setActive(index);
    setSelectListItem({ id, name });
  };

  const listStyle = {
    width: "100%",
    bgcolor: "background.paper",
    marginBlockStart: "10px",
  };

  const listItemTextStyle = () => {
    return {
      fontWeight: "600",
      fontSize: "0.9rem",
    };
  };
  return (
    <>
      <div className="global-page-parent-container">
        <p className="breadcrumb">
          Accounts &gt; Create Invoice &gt; Payroll &gt; {selectListItem.name}
        </p>
        <div className="accounting-container">
          <div className="accounting-side-menu">
            <List
              sx={{ ...listStyle }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              {payRollSideMenu.map(({ id, name }, index) => (
                <>
                  <ListItemButton
                    key={index}
                    sx={{ paddingLeft: "2em" }}
                    className={active === index ? "isactive" : ""}
                    onClick={() => handleListItemClick(index, id, name)}
                  >
                   <ListItemText
                      primary={
                        <Typography sx={{ ...listItemTextStyle() }}>
                          {name}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </>
              ))}
            </List>
          </div>
          <div className="accounting-voucher-menu">
            {selectListItem.id === 1 && <EmployeeCreation/>}
            {selectListItem.id === 2 && <EmployeeGroupCreation />}
            {selectListItem.id === 3 && <PayrollInvoice/>}
          </div>
        </div>
      </div>
    </>
  );
};

export default PayRoll;
