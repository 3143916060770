import { CalendarMonthOutlined, KeyboardArrowDown } from "@mui/icons-material";
import React from "react";
import { month } from "../../Js/Date";

export const Datepicker = (props) => {
    const {type,backgroundColor,boxShadow,height,onChange,value,defaultValue,color,width,borderRadius}=props
  return (
    <div className="global-date-input-container" style={{backgroundColor,boxShadow,height,width,borderRadius}}>
      <CalendarMonthOutlined className="calendar-icon"style={{color}} />
      <input className="global-date-picker" defaultValue={defaultValue} value={value} onChange={onChange} type={type} style={{backgroundColor,color}} />
      {/* <KeyboardArrowDown className="arrow-down" /> */}
    </div>
  );
};
