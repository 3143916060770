import {createSlice} from '@reduxjs/toolkit'

export const allProductListIndexSlice=createSlice({
    name:'allProductIndexSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_all_product_index:(state,action)=>{
            state.value=action.payload.allProductListData
        }
    }
})
export const {get_all_product_index} = allProductListIndexSlice.actions;
export default allProductListIndexSlice.reducer;