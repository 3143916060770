import axios from "axios"
import { get_generate_purchasewopo_id } from "../../Redux/Prchase Manager/PurchaseWOPO/generatePurchasewopoIdSlice"
import { get_all_purchasewopo_id } from "../../Redux/Prchase Manager/PurchaseWOPO/getAllPurchasewopoIdSlice";
import store from "../../Redux/store"
import { HEADERS } from "../UrlAndPaths";

// generate purchse wo po id
export const generateIdPurchaseWOPOAPICall= async (body)=>{
   await axios.post("purchase/generateinvoicenowpo",body,HEADERS)
   .then((res)=>{
    if(res.status===200){
        store.dispatch(get_generate_purchasewopo_id({purchaseWoPoId:res.data}))
    }
   })
   .catch((err)=>{
    store.dispatch(get_generate_purchasewopo_id({purchaseWoPoId:undefined}))
    console.log(err.response);
   })
}

// add purchase wo po
export const addPurchaseWOPOAPICall= async(
    body,
    finishclearState,
    setSuccessSnackOpen,
    setErrorSnackOpen,
    setSnackMsg,
    updateListener,
    closeVoucher
    )=>{
  await axios.post("purchase/addpurchaseWopo",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
        // console.log('purchase added');
        updateListener()
        setSuccessSnackOpen(true)
        setSnackMsg('Purchase wopo Added')
        finishclearState()
        closeVoucher()
    }
  })
  .catch((err)=>{
    setErrorSnackOpen(true)
    if (err.response === undefined) {
        setSnackMsg("Network Connection error!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Category Already Exist");
      }
    
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden");
      }
    console.log(err.response);
  })
}

/// all purchase wopo id 
export const allPurchaseWopoIdAPICall=async(body)=>{
  await axios.post("purchase/listwpoinvoices",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_all_purchasewopo_id({allPurchasewopoId:res.data}))
    }
  })
  .catch((err)=>{
    store.dispatch(get_all_purchasewopo_id({allPurchasewopoId:undefined}))
    console.log(err.response);
  })
}