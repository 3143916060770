import { createSlice } from "@reduxjs/toolkit";

export const loansLiabilitySlice = createSlice({
  name: "loansLiabilitySlice",
  initialState: {
    openingBalance: "",
    bankAccountDetails: { option: "Yes" },
    bankName: "",
    bankAccountNo: "",
    ifscCode: "",
    branchName: "",
    loanNo:'',
    startingDate:'',
    endDate:'',
    monthlyPaidAmount:'',
    totalLoanAmount:'',

   
  },
  reducers: {
    setOpeningBalance: (state, action) => {
      state.openingBalance = action.payload;
    },
    setBankAccountDetails: (state, action) => {
      state.bankAccountDetails = action.payload;
    },
    setBankName: (state, action) => {
      state.bankName = action.payload;
    },
    setBankAccountNo: (state, action) => {
      state.bankAccountNo = action.payload;
    },
    setIfscCode: (state, action) => {
      state.ifscCode = action.payload;
    },
    setBranchName: (state, action) => {
      state.branchName = action.payload;
    },
    setLoanNo: (state, action) => {
      state.loanNo = action.payload;
    },
    setStartingDate: (state, action) => {
      state.startingDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setMonthlyPaidAmount: (state, action) => {
      state.monthlyPaidAmount = action.payload;
    },
    setTotalLoanAmount: (state, action) => {
      state.totalLoanAmount = action.payload;
    },
  },
});

export const {
  setBankAccountDetails,
  setBankAccountNo,
  setBankName,
  setBranchName,
  setIfscCode,
  setOpeningBalance,
  setEndDate,
  setLoanNo,
  setMonthlyPaidAmount,
  setStartingDate,
  setTotalLoanAmount,
  
} = loansLiabilitySlice.actions;
export default loansLiabilitySlice.reducer;
