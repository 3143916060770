import { createSlice } from "@reduxjs/toolkit";

export const shiftTransferOrderListInvoiceNoSlice=createSlice({
    name:'shiftTransferOrderListInvoiceNo',
    initialState:{
        value:undefined
    },
    reducers:{
        get_all_order_invoiceNo:(state,action)=>{
            state.value=action.payload.allOrdersInvoiveNoList
        }
    }
})
export const {get_all_order_invoiceNo} = shiftTransferOrderListInvoiceNoSlice.actions;
export default shiftTransferOrderListInvoiceNoSlice.reducer