import React,{useState,useRef,useEffect} from 'react'
import {Select,IconButton,MenuItem, Autocomplete, TextField, Skeleton} from '@mui/material'
import ReactToPrint from "react-to-print";
import { salesReportAPICall } from '../../../../API/Report/salesReportAPI';
import { useSelector } from "react-redux";
import { viewAllBranchesAPICall } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import { today,currentTime,previousDate, convertDateFormat, getCurrentTime } from "../../../../Js/Date";
import {viewOrderInvoiceNoAPICall} from '../../../../API/Sales/viewOrderInvoiceNoAPI'
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { sortGeneral } from '../../../../Js/generalFunctions';
// css
//import '../../../../css/Report/stockReport.css'

export const SalesReport = () => {
  const storeCode=localStorage.getItem('branchId')
  let salesReportPrint=useRef()
  // role based rendering
  const userRole = useSelector((state) => state.userRoleSlice.value);
  // sales report data
  const salesReportData=useSelector((state)=>state.salesReportSlice.value)
  // all branch list
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  // all invoice no list
  const allInvoiceNoList = useSelector((state)=>state.viewOrderInvoiceNoSlice.value)
// login response
const loginResponse = useSelector((state) => state.loginResponseSlice.value);

  const [branch,setBranch]=useState(null)
  const [invoiceNo,setInvoiceNo]=useState(null)
  const [payment,setPayment]=useState()
  const [fromDate,setFromDate]=useState(previousDate)
  const [toDate,setToDate]=useState(today)
  const [time, setTime] = useState(null);
  const [isLoading,setIsLoading]=useState(false)
  const [isAscending,setIsAscending]=useState(false)
  const [salesReportDataFilter,setSalesReportDataFilter]=useState([])
  const [branchWithAll,setBranchWithAll]=useState([])
  const [salesReportTotalPaid,setSalesReportTotalPaid]=useState(0)

  // get current time fn
  useEffect(() => {
    setInterval(() => {
      setTime(`${new Date().getHours()}:${new Date().getMinutes()}`);
    }, 1000);
  }, [time]);

   useEffect(()=>{
      viewAllBranchesAPICall()
      viewOrderInvoiceNoAPICall({branchId:userRole === "user" ? storeCode:branch})
      salesReportAPICall({branchCode:userRole==="user"?loginResponse?.branchId:branch,fromDate:`${fromDate} ${getCurrentTime()}`,endDate:`${toDate} ${getCurrentTime()}`},setIsLoading)
   },[branch])

  
   const getBranch=(e)=>{
    setBranch(e.target.value)
   }
   const getInvoiceNo=(e)=>{
    setInvoiceNo(e.target.value)
   }
   const getFromDate=(e)=>{
    setFromDate(e.target.value)
   }
   const getToDate=(e)=>{
   setToDate(e.target.value)
   }

   // add -All- into branch list
   useEffect(()=>{
       if(allBranchesList !== undefined){
         let allBranchList=allBranchesList?.map((obj)=>{
          return obj
         })
         setBranchWithAll([{storeCode:"-All",branchName:""},...allBranchList])
       }
   },[allBranchesList])

  const formData=new FormData();
  {
    userRole ==="user"?
    // branch !== null && branch !== undefined &&
      formData.append("branchCode",loginResponse?.branchId)
     
     :
    //  branch !== null && branch !== undefined &&
      formData.append("branchCode",branch==="-All"?null:branch)
     
  }
   
    {
      invoiceNo !== null && invoiceNo !== undefined &&
      formData.append("invoiceId",invoiceNo)
    }
    {
      fromDate !== undefined &&
      formData.append("fromDate",`${fromDate} 00:00:00`)
    }
    {
      toDate !== undefined &&
      formData.append("endDate",`${toDate} 23:59:59`)
    }
  
     // sales report submit btn function
    const salesReportSubmitBtnFn=()=>{
      salesReportAPICall(formData,setIsLoading)
    }

  // map sales report data(response) to state
  useEffect(()=>{
     if(salesReportData !== undefined){
      setSalesReportDataFilter(salesReportData?.map(obj=>{return {...obj}}))
     }
  },[salesReportData])


    // sorting
    // sort ascending order by from date
    const sortFromDateAscending=()=>{
      setIsAscending(!isAscending)
      let sorted = sortGeneral(salesReportDataFilter, "Date", "fromDate", true);
      setSalesReportDataFilter(sorted)

    }
    // sort desending order by from date
    const sortFromDateDescending=()=>{
      setIsAscending(!isAscending)
      let sorted = sortGeneral(salesReportDataFilter, "Date", "fromDate", false);
      setSalesReportDataFilter(sorted)
    }

// sort ascending order by order total
const sortOrderTotalAscending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(salesReportDataFilter, "Number", "orderTotal", true);
  setSalesReportDataFilter(sorted)
}
// sort desending order by order total
const sortOrderTotalDescending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(salesReportDataFilter, "Number", "orderTotal", false);
  setSalesReportDataFilter(sorted)
}

// branch code sorting
const sortBranchCodeAscending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(salesReportDataFilter, "String", "branchCode", true);
  setSalesReportDataFilter(sorted)
}
const sortBranchCodeDescending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(salesReportDataFilter, "String", "branchCode", false);
  setSalesReportDataFilter(sorted)
}
// branch name sorting
const sortBranchNameAscending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(salesReportDataFilter, "String", "branchName", true);
  setSalesReportDataFilter(sorted)
}
const sortBranchNameDescending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(salesReportDataFilter, "String", "branchName", false);
  setSalesReportDataFilter(sorted)
}
console.log(salesReportDataFilter);


useEffect(()=>{
  if(salesReportDataFilter.length!==0){
    let orderTotal=[]
    orderTotal=salesReportDataFilter.map((obj)=>
              obj?.orderTotal
              )
    let totalPaid=0
    totalPaid=orderTotal.reduce((a,b)=>a+b,0)
    setSalesReportTotalPaid(totalPaid.toFixed(2))
  }
  else{
    setSalesReportTotalPaid(0)
  }
},[salesReportDataFilter])
  return (
    <>
    <div className="global-page-parent-container">
    <p className="breadcrumb">Report &gt; Sales Report</p>
    <div className="daily-report-top-container">
      <h3>Sales Report</h3>
      <hr className="global-hr"/>
      <div className="daily-report-top-input-container" >
        {userRole === 'admin' &&
       <div className="global-single-input auto-complete">
        <p>Branch</p>
        <Autocomplete
             sx={{width:"100%"}}
             options={branchWithAll|| [""]}
             getOptionLabel={(option) => `${option?.storeCode}-${option?.branchName}`}

             renderInput={(params) => <TextField {...params} 
             placeholder="Select Branch"
             />}
            
             onChange={(e, newValue) =>
              setBranch(newValue?.storeCode)
             }
            />
       </div>
        }
       <div className="global-single-input auto-complete">
        <p>Invoice No</p>
         {/* <Select
           value={invoiceNo}
           onChange={getInvoiceNo}
         >
          <MenuItem value="0">Select Invoice...</MenuItem>
          {allInvoiceNoList?.map((item,i)=>(
            <MenuItem key={item._id} value={item.ORDERID}>{item.ORDERID}</MenuItem>
          ))}
         </Select> */}
         <Autocomplete
             sx={{width:"100%"}}
             options={allInvoiceNoList|| [""]}
             getOptionLabel={(option) => option?.ORDERID}

             renderInput={(params) => <TextField {...params} 
             placeholder="Select InvoiceNo"
             />}
            
             onChange={(e, newValue) =>
              setInvoiceNo(newValue?.ORDERID)
             }
            />
       </div>
       
       <div className="global-single-input">
        <p>From</p>
        <input type="date" defaultValue={fromDate} onChange={getFromDate}/>
       </div>
       <div className="global-single-input">
        <p>To</p>
        <input type="date" defaultValue={toDate} onChange={getToDate}/>
       </div>
       <button onClick={salesReportSubmitBtnFn} className="btn btn-primary report-submit-btn">Submit</button>
      
      </div>
     
    </div>
    {/* {salesReportData !== undefined ? */}
    <div className="global-white-bg-container">
     <div className="stock-report-print-download-icon-container" style={{justifyContent:"flex-end"}}>
      <div className="stock-report-total-paid-ctn">
         <p style={{fontSize:"0.6rem",fontWeight:"800"}}>Total</p>
         <p style={{fontSize:"0.8rem",fontWeight:"700"}}>{`Rs ${salesReportTotalPaid}`}</p>
      </div>
     <ReactToPrint
        trigger={()=>(
            <IconButton className="print-icon-container">
              <i class="bi bi-printer printer-icon"></i>
            </IconButton>
        )}
        content={()=>salesReportPrint}
        pageStyle="@page { size: auto;  margin: 15mm 0 10mm 0; } }"
      />  
     <IconButton className="download-icon-container">
       <i class="bi bi-arrow-down-circle download-icon"></i>
     </IconButton>
     </div>
     <div className="global-table-container">
        <table className="global-table">
           <thead>
             <tr>
              <th>No</th>
              {userRole === 'admin' &&
              <>
              <th>
                Branch Code
                 {
                isAscending ?(
                  <IconButton
                    onClick={sortBranchCodeAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortBranchCodeDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
              </th>
              <th>
                Branch Name
                {
                isAscending ?(
                  <IconButton
                    onClick={sortBranchNameAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortBranchNameDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
              </th>
              </>
               }
              <th>
                From Date
                {
                isAscending ?(
                  <IconButton
                    onClick={sortFromDateAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortFromDateDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
              </th>
              <th>Invoice No</th>
              <th>Payment Method</th>
              <th>
                Order Total
                {
                isAscending ?(
                  <IconButton
                    onClick={sortOrderTotalAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortOrderTotalDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
              </th>
             </tr>
           </thead>
           {
            isLoading ? (
              <tbody>
              <tr>
                   <td colSpan={10}>
                     <Skeleton
                       variant="rectangular"
                       animation="wave"
                       width={"100%"}
                       height={30}
                     />
                   </td>
                 </tr>
                 <tr>
                   <td colSpan={10}>
                     <Skeleton
                       variant="rectangular"
                       animation="wave"
                       width={"100%"}
                       height={30}
                     />
                   </td>
                 </tr>
                 <tr>
                   <td colSpan={10}>
                     <Skeleton
                       variant="rectangular"
                       animation="wave"
                       width={"100%"}
                       height={30}
                     />
                   </td>
                 </tr>
           </tbody>
            ):(
              <tbody>
              {salesReportDataFilter?.length !==0 ? salesReportDataFilter?.map((r,i)=>(
                 <tr key={i}>
                 <td>{i+1}</td>
                 {userRole === 'admin' && 
                 <>
                 <td>{r.branchCode}</td>
                 <td>{r.branchName}</td>
                 </>
                  }
                 <td>{convertDateFormat(r.fromDate)}</td>
                 <td>{r.invoiceNo}</td>
                 <td >
                   {r.paymentType?.map((k)=>(
                     <p>
                      {k.type}
                      </p>
                   ))}
                 </td>
                 <td>{r.orderTotal?.toFixed(2)}</td>
               </tr>
              ))
              :
              <tr>
                <td style={{textAlign:"center"}} colSpan={7}>No Data</td>
              </tr>
            }
             
             </tbody>
            )
           }
          
        </table>
     </div>
    </div>
{/* :
     <div className="global-white-bg-container">
       <h3 className='no-data'>No Record</h3>
     </div>
} */}
    </div>

    {/* sales report print view */}
    <div 
      style={{display:'none'}}
     >
       <div ref={(el)=>(salesReportPrint = el)}>
          <div className='print-view-top-container'>
            <div className='print-view-top-left-container'  style={{width:"35%"}}>
            <h6 style={{margin:"0 0 0 2%"}}>Printed On : {convertDateFormat(today)}&nbsp;{getCurrentTime()}</h6>
           
            </div>
            <div className='print-view-top-middle-container'  style={{width:"30%"}}>
              {userRole === "admin" ? (
                <h3>{loginResponse?.data?.profile?.companyName}</h3>
              ) : userRole === "user" ? (
                <h3>{loginResponse?.storeName}</h3>
              ) : undefined}
                 <p>SALES REPORT</p>
                 <h5 style={{margin:"0",textAlign:"center"}}>{`(${convertDateFormat(fromDate)} - ${convertDateFormat(toDate)})`}</h5>
            </div>

              <div className='print-view-top-right-container'  style={{width:"35%"}}>
                

          
              {userRole ==="user" && <p>{loginResponse?.branchId}-{loginResponse?.branchName}</p>}

            </div>
          </div>
          <div className='print-view-table-container report-print-view-table-with-clr'>
          <table>
          <thead>
             <tr>
              <th>No</th>
              {/* {userRole === 'admin' &&
              <>
              <th>Branch Code</th>
              <th>Branch Name</th>
              </>
               } */}
              <th>From Date</th>
              <th>Invoice No</th>
              <th>Payment Method</th>
              <th>Order Total</th>
             </tr>
           </thead>
           <tbody>
            {Array.from(new Set(salesReportDataFilter.map(r => r.branchName))).map((branchName,i)=>(
              <React.Fragment key={i}>
                  {
                    userRole==='admin' &&
                    <tr>
                      <td colSpan="6">Branch Name :&nbsp;{branchName} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      Branch ID : &nbsp; {salesReportDataFilter.find(item => item.branchName === branchName).branchCode}
                      </td>
                    </tr>
                  }
                  {
                    salesReportDataFilter.filter(r=> r.branchName === branchName).map((r,j)=>(
                      <tr key={j}>
                        <td>{j+1}</td>
                        <td>{convertDateFormat(r.fromDate)}</td>
                        <td>{r.invoiceNo}</td>
                        <td >
                          {r.paymentType?.map((k)=>(
                            <p>
                              {k.type}
                              </p>
                          ))}
                        </td>
                        <td>{r.orderTotal}</td>
                      </tr>
                    ))}

              </React.Fragment>
            ))}
           {/* {salesReportDataFilter?.map((r,i)=>(
               <tr key={i}>
               <td>{i+1}</td>
               {userRole === 'admin' && 
               <>
               <td>{r.branchCode}</td>
               <td>{r.branchName}</td>
               </>
                }
               <td>{convertDateFormat(r.fromDate)}</td>
               <td>{r.invoiceNo}</td>
               <td >
                 {r.paymentType?.map((k)=>(
                   <p>
                    {k.type}
                    </p>
                 ))}
               </td>
               <td>{r.orderTotal}</td>
             </tr>
            ))} */}
           </tbody>
        </table>
        
          </div>
     
       </div>
     </div>
    </>
  )
}
