import { createSlice } from "@reduxjs/toolkit";

export const creditSummarySlice=createSlice({
    name:"creditSummary",
    initialState:{
        value:undefined
    },
    reducers:{
        get_credit_summary:(state,action)=>{
            state.value=action.payload.creditSummaryData
        }
    }

})
export const {get_credit_summary} = creditSummarySlice.actions;
export default creditSummarySlice.reducer