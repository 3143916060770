import axios from "axios";
import store from "../../../../../Redux/store";
import { HEADERS, HEADER_FORM_DATA } from "../../../../../API/UrlAndPaths";
import { generate_debit_note_name, get_bill_lists_of_customer, get_customer_bill_row_data_list, get_debit_note_list } from "../Slice/debitNoteSlice";
import { debitNoteSingleViewApiCall } from "../../../../../API/Settings/Company Settings/companySettingsAPI";


// view debitNotes 

export const viewDebitNotesAPICall = async (body, setIsLoading) => {
  setIsLoading !== undefined && setIsLoading(true)
  await axios.post("account/viewDebitNotes", body, HEADERS)
      .then((res) => {
          if (res.status === 200) {
              store.dispatch(get_debit_note_list({ debitNoteData: res.data }))
              setIsLoading !== undefined && setIsLoading(false)
          }
      })
      .catch((err) => {
          store.dispatch(get_debit_note_list({ debitNoteData: undefined }))
          setIsLoading !== undefined && setIsLoading(false)
})
}
export const  DebitNotesFilterAPICall =(body,setIsLoading)=>{
  setIsLoading!==undefined&&setIsLoading(true)
  axios.post("account/searchDebitNotes",body,{
    headers: {
      'Content-Type': 'application/json',
    },
   
  })
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_debit_note_list({debitNoteData:res.data}))
      setIsLoading!==undefined&&setIsLoading(false)
    }
  })
  .catch((err)=>{
    store.dispatch(get_debit_note_list({debitNoteData:undefined}))
    setIsLoading!==undefined&&setIsLoading(false)
  })

}

// get debit note draft number 
export const generateDebitNoteNumber = async (body) => {
  try {
    const response = await axios.post(
      "account/generateDebitNoteDraftNo",
      body,
      HEADERS
    );
    if (response.status === 200) {
      store.dispatch(generate_debit_note_name({debitNoteName:response.data}));
    }
  } catch (error) {
      store.dispatch(generate_debit_note_name({debitNoteName:undefined}));
      console.error(`Failed to fetch data: ${error.message}`);
  }
};


// get list invoice for debit note
export const getListInvoice = async (body) => {
  try {
    const response = await axios.post("account/listinvoicefordebitnote", body, HEADERS);
    if (response.status === 200) {
      store.dispatch(get_bill_lists_of_customer({vendorBillListData:response.data}));
    }
  } catch (error) {
    console.error(`Failed to fetch data: ${error.message}`);
    store.dispatch(get_bill_lists_of_customer({vendorBillListData:undefined}));
  }
};
// get selected bill data
export const getSelectedBillData = async (body) => {
  try {
    const response = await axios.post("account/getVendorBillInvoices", body, HEADERS);
    if (response.status === 200) {
      store.dispatch(get_customer_bill_row_data_list({singleVendorBillData:response.data}));
    }
  } catch (error) {
    store.dispatch(get_customer_bill_row_data_list({singleVendorBillData:undefined}));
  }
};

// draft debit note api call

export const draftDebitNoteAPICall = async (body,postFn,setState,state,setEditDataId,setLoadingForm) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  await axios.post("account/addDebitNoteDraft", body, HEADER_FORM_DATA)
      .then((res) => {
          if (res.status === 200) {
              setLoadingForm!==undefined && setLoadingForm(false)
              setState({...state,message:"Debit Note Drafted",success:true})
              postFn()
              setEditDataId(res.data?._id)
              debitNoteSingleViewApiCall({_id:res.data?._id})
              localStorage.setItem("singleViewById", res.data?._id);
          }
      })
      .catch((err) => {
          setLoadingForm!==undefined && setLoadingForm(false)
          if(err.response.status === 500){
            setState({...state,message:"Internal Server error !",error:true})
          }
          else{
            setState({...state,message:err?.response?.data,error:true})
          }
        
      })
}
// edit debit note api call

export const editDebitNoteAPICall = async (body,postFn,setState,state,setLoadingForm) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  await axios.put("account/editDebitNote", body, HEADER_FORM_DATA)
      .then((res) => {
          if (res.status === 200) {
              setLoadingForm!==undefined && setLoadingForm(false)
              setState({...state,message:"Debit Note Edited",success:true})
              postFn()
          }
      })
      .catch((err) => {
          setLoadingForm!==undefined && setLoadingForm(false)
          if(err.response.status === 500){
            setState({...state,message:"Internal Server error !",error:true})
          }
          else{
            setState({...state,message:err?.response?.data,error:true})
          }
          
      })
}
// post debit note api call

export const postDebitNoteAPICall = async (body,postFn,setState,state,setLoadingForm) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  await axios.post("account/postDebitNote", body, HEADER_FORM_DATA)
      .then((res) => {
          if (res.status === 200) {
              setLoadingForm!==undefined && setLoadingForm(false)
              setState({...state,message:"Debit Note Posted",success:true})
              postFn()
          debitNoteSingleViewApiCall({_id:res.data._id})

          }
      })
      .catch((err) => {
          setLoadingForm!==undefined && setLoadingForm(false)
          if (err.response.status === 500) {
            setState({...state,message:"Internal Server error !",error:true})
          } else {
            setState({...state,message:err?.response?.data,error:true})
          }
          
      })
}
  //post registered payment 
  export const debitNoteRegisterPaymentPostAPICall = (
    body,registerPymentCancelBtn,setSnackBarStates,setLoadingForm
  ) => {
    setLoadingForm!==undefined && setLoadingForm(true)
    axios
      .post("account/registerPaymentFromDebitNote", body, HEADERS)
      .then((res) => {
        if (res.status === 200) {
          setLoadingForm!==undefined && setLoadingForm(false)
          debitNoteSingleViewApiCall({_id:body.debitNoteId})
            setSnackBarStates((prev)=>({...prev,message: "payment registered", success: true }))
            registerPymentCancelBtn()
        }
      })
      .catch((err) => {
        setLoadingForm!==undefined && setLoadingForm(false)
        if (err.response.status === 500) {
          setSnackBarStates((prev)=>({ ...prev, message: "Internal Server error !", error: true }));
        } else {
          setSnackBarStates((prev)=>({ ...prev, message: err?.response?.data, error: true }));
        }
        
      });
  };
