import React from "react";
import InputBox from "../InputBox";
import { useDispatch, useSelector } from "react-redux";
import {
  setAccountHolderName,
  setAccountNo,
  setBranch,
  setCity,
  setCountry,
  setIfscCode,
  setOpeningBalance,
  setOverDraftLimit,
  setRegion,
  setStreet,
} from "../../../../../Redux/Account/bankOverDraftSlice";
import SubmitBtn from "../SubmitBtn";

const BankOverDraft = () => {
  const dispatch = useDispatch();
  const {
    overDraftLimit,
    accountHolderName,
    accountNo,
    ifscCode,
    region,
    street,
    city,
    branch,
    country,
    openingBalance,
  } = useSelector((state) => state.bankOverDraftSlice);

  const bankOverDraftSlice = useSelector((state) => state.bankOverDraftSlice);
  const handleBankOverDraftSubmit = () => {
    console.log(bankOverDraftSlice);
  };
  return (
    <div
      style={{ padding: "2% 4%" }}
      className="ledger-section-container-input-bank "
    >
      <div>
        {/*Account Holder Name */}
        <InputBox
          label={"Account Holder Name"}
          value={accountHolderName}
          onChange={(e) => dispatch(setAccountHolderName(e.target.value))}
        />
        {/*Account no*/}
        <InputBox
          label={"Account no"}
          value={accountNo}
          onChange={(e) => dispatch(setAccountNo(e.target.value))}
        />

        {/*IFSC Code  */}
        <InputBox
          label={"IFSC Code"}
          value={ifscCode}
          onChange={(e) => dispatch(setIfscCode(e.target.value))}
        />
      </div>
      <div>
        {/*Set over draft Limit  */}
        <InputBox
          label={"Set over draft Limit"}
          value={overDraftLimit}
          onChange={(e) => dispatch(setOverDraftLimit(e.target.value))}
        />

        {/*Region */}
        <InputBox
          label={"Region"}
          value={region}
          onChange={(e) => dispatch(setRegion(e.target.value))}
        />

        {/*Street */}
        <InputBox
          label={"Street"}
          value={street}
          onChange={(e) => dispatch(setStreet(e.target.value))}
        />
      </div>
      <div>
        {/*City */}
        <InputBox
          label={"City"}
          value={city}
          onChange={(e) => dispatch(setCity(e.target.value))}
        />

        {/*Branch */}
        <InputBox
          label={"Branch"}
          value={branch}
          onChange={(e) => dispatch(setBranch(e.target.value))}
        />

        {/*Country */}
        <InputBox
          label={"Country"}
          value={country}
          onChange={(e) => dispatch(setCountry(e.target.value))}
        />
      </div>
      <div>
        {/*Opening Balance */}
        <InputBox
          label={"Opening Balance"}
          value={openingBalance}
          onChange={(e) => dispatch(setOpeningBalance(e.target.value))}
          containerStyle={{width:'31%'}}

        />
      </div>
      {/* submitButton */}
      <SubmitBtn onClick={handleBankOverDraftSubmit} />
    </div>
  );
};

export default BankOverDraft;
