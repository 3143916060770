import { createSlice } from "@reduxjs/toolkit";

export const colorListSlice=createSlice({
    name:'colorListSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_color_list:(state,action)=>{
            state.value=action.payload.colorList
        }
    }
})

export const {update_color_list}=colorListSlice.actions
export default colorListSlice.reducer