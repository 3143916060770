import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import store from "../../../../../Redux/store";
// import { get_branch_transfer_single_view } from './BranchTransferSlice';
import { Skeleton } from "@mui/material";
import { VendorPaymentFilterAPICall, viewVendorPaymentListAPICall } from "../API/vendorPaymenAPICall";
import { get_vendor_payment_row_data_list } from "../Slice/vendorPaymentSlice";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import { viewAllCurrencyUpdated } from "../../../../../API/Settings/Currency/CurrencyAPI";
import { companyProfileInfoForUser } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import { filterObjFromList } from "../../../../../Js/generalFunctions";
import CustomPagination from "../../../../Single Components/CustomPagination";
import { getCurrentTime } from "../../../../../Js/Date";
import axios from "axios";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";

export const VendorPayments = () => {
  const tableListData = useSelector(
    (state) => state.vendorPaymentSlice.apiResList
  );
  let navigate = useNavigate();

  const filtereDataOptions = useSelector((state) => state.listCategory);

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages =tableListData?.pages;
  const [FormDataInfo,setFormDataInfo]=useState([])
  const [symbol, setSymbol] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const createVendorPayment = () => {
    navigate("/userdashboard/accounts/vendor/createvendorPayments");
  };
  
  // console.log(totalPages);

  const currencyListUpdated = useSelector(
    (state) => state?.viewUpdatedCurrency?.value
  );

  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );

  const handlePageChange = (page) => {

    setCurrentPage(page);
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };

  useEffect(() => {
   
    if (FormDataInfo.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData &&  VendorPaymentFilterAPICall({
      fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
      toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
    branchList: FormDataInfo.branchList,
    // type:[1],
    status: FormDataInfo.status,
    search: FormDataInfo.search,
    index: currentPage-1,
  }, setIsLoading);
}

}, [FormDataInfo, currentPage]);

useEffect(() => {
  let currencyObj = filterObjFromList(
    "_id",
    currencyListUpdated,
    "currency",
    companyProfileData
  );
  // console.log(currencyObj);
  setSymbol(currencyObj?.symbol);
}, [companyProfileData, currencyListUpdated]);

useEffect(() => {
  viewAllCurrencyUpdated({ status: 0 });
  companyProfileInfoForUser();
}, []);


  useEffect(() => {
    localStorage.removeItem("singleViewById");
    store.dispatch(
      get_vendor_payment_row_data_list({
        vendorPaymentSingleRowData: undefined,
      })
    );
    // viewVendorPaymentListAPICall(setIsLoading);
  }, []);

  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };

  const handleClickRow = (row) => {
    store.dispatch( setFilterActive(window.location.pathname))
    localStorage.setItem("singleViewById", row._id);
    // store.dispatch(
    //   get_vendor_payment_row_data_list({ vendorPaymentSingleRowData: row })
    // );
    navigate("/userdashboard/accounts/vendor/createvendorPayments");
  };

  return (
    <div className="global-page-parent-container">
      {/* table */}
      <div className="global-white-bg-container">
      <div className="justify-space-between"
        style={{gap:"0 12px",display: 'flex', justifyContent: 'space-between', alignItems: 'end'}}
        >
          {FormDataInfo?.branchList?.length > 0 && (
              <div style={{ marginLeft: '1%' }}>
                <h3>Branch name: 
                  {selectedBranch?.storeCode||filtereDataOptions?.filterCategorylist?.branchList?.storeCode}
                  -{selectedBranch?.branchName||filtereDataOptions?.filterCategorylist?.branchList?.branchName}</h3>
              </div>
            )} 
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap:'9px',
              width: FormDataInfo?.branchList?.length ? '72%' : '100%'
            }}>
          <CategorySearchandFilter
          statusOptions={['COMPLETED','DRAFT',"RETURNED"]}
          onData={handleDataFromChild}
          // isCreatedBy={true}
          isStatus={true}
          onBranchSelect={handleBranchSelect}
          />
                 <button onClick={createVendorPayment} className="create-button-blue">
            Create
          </button>
          </div>
        </div>
        <div className="create-button-blue-container">
          <h3>Payments</h3>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />
        </div>
        <div className="new-global-table-container">
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Payment Type</th>
                <th>Supplier</th>
                <th>Total Amount</th>
                <th>Created By</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={7}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : tableListData?.list !== undefined &&
               tableListData?.list?.length !== 0 ? (
                tableListData?.list
                  ?.slice(0)
                 
                  .map((r, i) => (
                    <tr
                      key={i}
                      onClick={() => handleClickRow(r)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{r?.paymentDate}</td>
                      <td>{r?.name}</td>
                      <td>{r?.PAYMENTTYPE}</td>
                      <td>{r?.vendor}</td>
                      <td>{symbol}&nbsp;{r?.totalAmount}</td>
                      <td>{r?.CREATEDBY}</td>
                      <td className="table-data-box">
                        {r?.status === "COMPLETED" ? (
                          <span className="post"> Posted</span>
                        ) : r?.status === "DRAFT" ? (
                          <span className="draft"> Drafted</span>
                        ) :r?.status === "RETURNED" ? (
                          <span className='return'> RETURN</span>
                        ) : (
                          "---"
                        )}
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan={7}>No Data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
