import {createSlice} from '@reduxjs/toolkit'

export const shiftDenominationDialogSlice=createSlice({
    name:'shiftDenominationDialog',
    initialState:{
        value:undefined
    },
    reducers:{
        get_shift_denomination_dialog:(state,action)=>{
            state.value=action.payload.shiftDenomination
        }
    }
})
export const {get_shift_denomination_dialog} = shiftDenominationDialogSlice.actions;
export default shiftDenominationDialogSlice.reducer