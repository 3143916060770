import React, { useRef } from "react";
import DropdownInputBox from "../../../components/DropdownInputBox";
import InputBox from "../../../components/InputBox";
import SubmitBtn from "../../../components/SubmitBtn";
import ReactToPrint from "react-to-print";
import { IconButton, Skeleton } from "@mui/material";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

//rest data
const renderData = [
  {
    particular: "product",
    amount: "444",
  },
  {
    particular: "product",
    amount: "879",
  },
  {
    particular: "product",
    amount: "788",
  },
];

const PayrollStatement = ({ selectListItem }) => {
  const componentRef = useRef();
  const isLoading = false;
  return (
    <div id="currentAsset-report" className="pay-in-slip payment-report">
      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="accounting-voucher-menu-sections"
      >
        <h3 style={{ margin: "2% 1%" }}>{selectListItem}</h3>
        <div className="input-current-asset-reports">
          <DropdownInputBox
            labelName={"Select"}
            placeholder={"Select Option"}
          />
          <InputBox label={"From"} type={"date"} />
          <InputBox label={"To"} type={"date"} />
        </div>
        <SubmitBtn
          containerStyle={{ justifyContent: "flex-end", paddingRight: "2%" }}
        />
      </div>
      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="accounting-voucher-menu-sections"
      >
        <div className="action-btn-container">
          <div>
            <ReactToPrint
              trigger={() => (
                <IconButton sx={{ color: "blue" }}>
                  <i className="bi bi-printer "></i>
                </IconButton>
              )}
              content={() => componentRef.current}
            />
            <IconButton className="download-btn">
              <ArrowCircleDownIcon sx={{ fontSize: 30 }} />
            </IconButton>
          </div>
        </div>
        <div className="global-table-container">
          <table className="global-table">
            <thead>
              <tr>
                <th>Particular</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [...Array(3)].map((_, index) => (
                  <tr key={index}>
                    <td colSpan={7}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : renderData.length !== 0 ? (
                renderData?.reverse()?.map((r, i) => (
                  <React.Fragment key={i}>
                    <tr className="data-table-row-head">
                      <td>{r?.particular}</td>
                      <td>{r?.amount}</td>
                    </tr>
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan={7}>No Records</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* print */}
      <div style={{ display: "none" }}>
        <div
          ref={componentRef}
          id="currentAsset-report"
          className="print-preview-container"
        >
          <div className="print-head-container">
            <div className="brand-name">
              <h2>Leeyet Textile</h2>
            </div>
            <div className="select-table-head">
              <h3>{selectListItem}</h3>
            </div>
            <div className="duration-date">
              <h3>{"01/04/2023"}</h3>
              <strong>TO</strong>
              <h3>{"01/12/2023"}</h3>
            </div>
          </div>
          <div className="global-table-wrapper">
            <div className="global-table-container">
              <table className="global-table">
                <thead>
                  <tr>
                    <th>Particular</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    [...Array(3)].map((_, index) => (
                      <tr key={index}>
                        <td colSpan={7}>
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={40}
                          />
                        </td>
                      </tr>
                    ))
                  ) : renderData.length !== 0 ? (
                    renderData?.reverse()?.map((r, i) => (
                      <React.Fragment key={i}>
                        <tr className="data-table-row-head">
                          <td>{r?.particular}</td>
                          <td>{r?.amount}</td>
                        </tr>
                      </React.Fragment>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7}>No Records</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayrollStatement;
