import {createSlice} from '@reduxjs/toolkit'

export const outletExpenseSlice=createSlice({
   name:'outletExpenseSlice',
   initialState:{
    value:undefined
   },
   reducers:{
    get_outlet_expense:(state,action)=>{
        state.value=action.payload.outletExpenseData
    }
   }
})
export const {get_outlet_expense} = outletExpenseSlice.actions;
export default outletExpenseSlice.reducer;