import { createSlice } from "@reduxjs/toolkit";

export const allEmployeeDocSlice=createSlice({
    name:'allEmployeeDocSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_all_employee_docs_list:(state,action)=>{
            state.value=action.payload.allEmployeeDocs
        }
    }
})

export const {update_all_employee_docs_list}=allEmployeeDocSlice.actions
export default allEmployeeDocSlice.reducer