import { createSlice } from "@reduxjs/toolkit";
export const Overtime=createSlice({
    name:'overtime',
    initialState:{
        ViewOvertimeList:undefined,
        ViewOvertimeListById:undefined,
        viewOvertimeType:undefined,
        addOvertimeType:undefined,
        addOvertime:undefined,
    },
    reducers:{
        view_Overtime_list:(state,action)=>{
            state.ViewOvertimeList=action.payload.viewOvertimeListData
        },
        view_Overtime_By_id:(state,action)=>{
            state.ViewOvertimeListById=action.payload.viewOvertimeById
        },
        view_overtime_type:(state,action)=>{
            state.viewOvertimeType=action.payload.viewOvertimeTypeData
        },
        generate_overtime_type:(state,action)=>{
            state.addOvertimeType=action.payload.addOvertimeTypeData
        },
        generate_overtime:(state,action)=>{
            state.addOvertime=action.payload.addOvertimeData
        }
    }
})
export const {view_Overtime_list,view_Overtime_By_id,view_overtime_type,generate_overtime,generate_overtime_type}=Overtime.actions;
export default Overtime.reducer;