import React from 'react'
import {IconButton,Autocomplete,TextField} from '@mui/material'

export const CardAuto = () => {
    const data=[
        { name:""}
      ]
  
  return (
    <div className="card-manual-container">
    <div className="card-manual-top-container">
       <div className="global-single-input card-auto-single auto-complete">
        <p>Select Device</p>
       <Autocomplete
          sx={{width:'100%'}}
          options={data}
          renderInput={(params) => <TextField {...params}/>}
          getOptionLabel={(option)=>option?.name}
        />
        </div> 
    </div>
    <button className="btn btn-primary card-manual-button">Submit</button>
    </div>
  )
}
