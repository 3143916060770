import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Skeleton } from "@mui/material";
import { getPriceListApi } from "./priceListAPI";

const PriceList = () => {
  const [isLoading, setIsLoading] = useState(true);
  let navigate = useNavigate();

  const {priceListData} = useSelector(
    (state) => state.priceListSlice
  );
  
  useEffect(()=>{
    if(priceListData.length == 0 ){
      setIsLoading(false)
    }
  },[priceListData])

  console.log(priceListData)
  const createLandedCost = () => {
    navigate("/userdashboard/sales/orders/pricelist/create");
  };

  useEffect(() => {
    getPriceListApi(setIsLoading)
  }, []);

  const singleViewFn = (data) => {
    localStorage.setItem('singleViewById', data._id);
    navigate("/userdashboard/sales/orders/pricelist/create")
   
  };

  return (
    <div className="global-page-parent-container">
    {/* table */}
    <div className="global-white-bg-container">
      <div className="create-button-blue-container">
        <h3>Price List</h3>
        <button onClick={createLandedCost} className="create-button-blue">
          Create
        </button>
      </div>
      <div className="new-global-table-container">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Name</th>
              <th>Apply On</th>
              <th>Active</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              [...Array(10)].map((r, i) => (
                <tr key={i}>
                  <td colSpan={8}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
              ))
            ) : priceListData?.length !== 0 ? (
              priceListData
                ?.slice(0)
                ?.reverse()
                ?.map((r, i) => {
                  if (r?.status !== "Available") {
                    return (
                      <tr onClick={() => singleViewFn(r)}>
                        <td>{r?.date}</td>
                        <td>{r?.name}</td>
                        <td>{r?.applyOnDate}</td>
                        <td>
                        <input
                          className="toggle-checkbox"
                          type="checkbox"
                          checked={r?.activeStatus}
                        />
                      </td>
                      </tr>
                    );
                  }
                })
            ) : (
              <tr>
                <td colSpan={7}>No Data</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  </div>
  )
}

export default PriceList