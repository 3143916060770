import {createSlice} from '@reduxjs/toolkit'

export const pettyCashReceiptSlice=createSlice({
    name:'pettyCashReceiptSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_petty_cash_receipt:(state,action)=>{
            state.value=action.payload.pettyCashReceiptData
        }
    }
})
export const {get_petty_cash_receipt}=pettyCashReceiptSlice.actions;
export default pettyCashReceiptSlice.reducer;