import axios from "axios"
import { get_purchaseWoPo } from "../../Redux/Report/purchaseWoPoSlice"
import store from "../../Redux/store"
import { HEADERS } from "../UrlAndPaths"

export const purchaseWoPoAPICall= async (body,setIsLoading)=>{
    setIsLoading(true)
    await axios.post("report/purchasewopoReport",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_purchaseWoPo({purchseWoPoList:res.data}))
            setIsLoading(false)
        }
    })
    .catch((err)=>{
        store.dispatch(get_purchaseWoPo({purchseWoPoList:undefined}))
        console.log(err.response);
        setIsLoading(false)
    })
}