import {createSlice} from '@reduxjs/toolkit'

export const addExpenseTypeDocNoSlice=createSlice({
    name:'addExpenseTypeDocNo',
    initialState:{
        value:undefined
    },
    reducers:{
      get_add_expense_type_doc_no:(state,action)=>{
        state.value=action.payload.addExpenseTypeDocNoData
      }
    }
})
export const {get_add_expense_type_doc_no} = addExpenseTypeDocNoSlice.actions;
export default addExpenseTypeDocNoSlice.reducer;