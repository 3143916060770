import {createSlice} from '@reduxjs/toolkit'

export const viewCreditSlice=createSlice({
    name:'creditSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_credit_list:(state,action)=>{
            state.value=action.payload.creditData
        }
    }
})
export const {get_credit_list} = viewCreditSlice.actions;
export default viewCreditSlice.reducer;