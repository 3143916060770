import React, { useEffect, useState } from 'react'
import { IconButton, Skeleton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CustomPagination from '../../../../Single Components/CustomPagination';
import { filterObjFromList } from '../../../../../Js/generalFunctions';
import { viewAllCurrencyUpdated } from '../../../../../API/Settings/Currency/CurrencyAPI';
import { companyProfileInfoForUser } from '../../../../../API/Settings/Company Settings/companySettingsAPI';
import { set_Back_button_action } from '../../../../Single Components/listSlice/filterCategorySlice';
import { ReceivableDueReportSingleViewAPIcall } from './partnerReportApi';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import { convertDateFormat, today } from '../../../../../Js/Date';

function ReceivableDueReportSingleView() {
    let navigate = useNavigate();
    let location = useLocation()
    const dispatch = useDispatch();

    const currencyListUpdated = useSelector(
        (state) => state.viewUpdatedCurrency.value
    );
    const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue)

        const SingleListData = useSelector((state) => state?.PartnerReport?.ReceivableDueReportSingleView)

    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [symbol, setSymbol] = useState(null);
    const [FormDataInfo, setFormDataInfo] = useState([])
    const singleViewId = localStorage.getItem("receivableSingleViewId")

    const totalPages = 10;
    const decimalPosition = localStorage.getItem("decimalPosition");


    const handlePageChange = (page) => {

        setCurrentPage(page);
    };
    const handleDataFromChild = (data) => {
        setFormDataInfo(data)
    };

    const handleClick = () => {
        localStorage.removeItem("receivableSingleViewId")
        navigate('/userdashboard/accounts/report/partnerReport/ReceivableDueReport');
        dispatch(set_Back_button_action({ backButtonClickAction: true }));
    };
    useEffect(() => {

      ReceivableDueReportSingleViewAPIcall({
        customerId: singleViewId,
        todayDate: FormDataInfo?.toDate === '' ? `${today} 23:59:59` : `${FormDataInfo?.toDate} 23:59:59`,
        search: FormDataInfo?.search,
        index: currentPage-1,

      })
    }, [FormDataInfo,singleViewId])

    useEffect(() => {
        let currencyObj = filterObjFromList("_id", currencyListUpdated, "currency", companyProfileData)
        setSymbol(currencyObj?.symbol)
    }, [companyProfileData, currencyListUpdated])

    useEffect(() => {
        viewAllCurrencyUpdated({ status: 0 });
        companyProfileInfoForUser();
    }, [])
    return (
        <div className="global-page-parent-container">
            {/* table */}
            <div className="global-white-bg-container">
                <div className='create-button-blue-container' style={{ margin: "1%", display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
                    <div>
                        <h3 style={{ display: 'inline-block' }}>
                            <span
                                style={{ color: 'gray', cursor: 'pointer' }}
                                onClick={handleClick}
                            >
                                Receivable Due Report
                            </span>
                            <ArrowForwardIosIcon style={{ fontSize: '0.9rem' }} />
                            {SingleListData?.customerName}
                       
                        </h3>
                    </div>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width: '50%'
                    }}>


                          <CategorySearchandFilter
                            onData={handleDataFromChild}
                            style={{ width: "100%" }}
                            isFromDate={false}

                        />
                        <IconButton
                            style={{ width: "100px" }}

                            className="print-icon-container">
                            <i class="bi bi-printer printer-icon"></i>
                        </IconButton>
                        <IconButton
                            style={{ width: "100px" }}
                            className="download-icon-container">
                            <i class="bi bi-arrow-down-circle download-icon"></i>
                        </IconButton>
                    </div>

                </div>

                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                }}>
                    <CustomPagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}

                    />
                </div>
                <div className="customer-receipt-table-container">
                    <table>
                        <thead>
                            <tr>

                                <th>Reference</th>
                                <th>Invoice Date</th>
                                <th>Due Date</th>
                                <th>Due Days</th>
                                <th>Not Due Amount</th>
                                <th>Over Due 0-30</th>
                                <th>Over Due 31-60</th>
                                <th>Over Due 61-90</th>
                                <th>Over Due 91-120</th>
                                <th>Over Due 120 Above</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                          
                            {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={8}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : SingleListData?.list !== undefined && SingleListData?.list?.length !== 0 ? (
                SingleListData?.list
                  ?.slice(0)
                  ?.map((r, i) => (
                    <tr key={i}>
                      
                      <td>{r?.name}</td>
                      <td>{convertDateFormat(r?.invoiceDate)}</td>
                      <td>{convertDateFormat(r?.dueDate)}</td>
                      <td>{r?.days}</td>
                      <td>{symbol}&nbsp;{r?.categories?.current?.toFixed(decimalPosition)}</td>
                      <td>{symbol}&nbsp;{r?.categories?.upto30days?.toFixed(decimalPosition)}</td>
                      <td>{symbol}&nbsp;{r?.categories?.upto60days?.toFixed(decimalPosition)}</td>
                      <td>{symbol}&nbsp;{r?.categories?.upto90days?.toFixed(decimalPosition)}</td>
                      <td>{symbol}&nbsp;{r?.categories?.upto120days?.toFixed(decimalPosition)}</td>
                      <td>{symbol}&nbsp;{r?.categories?.morethan120days?.toFixed(decimalPosition)}</td>
                      <td>{symbol}&nbsp;{r?.total?.toFixed(decimalPosition)}</td>

                    </tr>
                  ))
              ) : (
                <tr>
                  <td style={{ textAlign: 'center' }} colSpan={9}>No Data</td>
                </tr>
              )}

                        </tbody>
                        <tfoot >
                            <tr>
                                <th style={{ textAlign: 'start', fontSize: '1rem', paddingLeft: '3%' }} colSpan={4}>TOTAL</th>
                                <th >{symbol}&nbsp;{SingleListData !== undefined && SingleListData?.sumOfCurrent?.toFixed(decimalPosition)}</th>
                                <th >{symbol}&nbsp;{SingleListData !== undefined && SingleListData?.sumOf30?.toFixed(decimalPosition)}</th>
                                <th >{symbol}&nbsp;{SingleListData !== undefined && SingleListData?.sumOf60?.toFixed(decimalPosition)}</th>
                                <th >{symbol}&nbsp;{SingleListData !== undefined && SingleListData?.sumOf90?.toFixed(decimalPosition)}</th>
                                <th >{symbol}&nbsp;{SingleListData !== undefined && SingleListData?.sumOf120?.toFixed(decimalPosition)}</th>
                                <th >{symbol}&nbsp;{SingleListData !== undefined && SingleListData?.sumOfAbove120?.toFixed(decimalPosition)}</th>
                              
                                <th >{symbol}&nbsp;{SingleListData !== undefined && SingleListData?.sumOfTotal?.toFixed(decimalPosition)}</th>
                            </tr>

                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ReceivableDueReportSingleView