import { createSlice } from "@reduxjs/toolkit";

export const relationshipSlice = createSlice({
  name: "relationshipSlice",
  initialState: {
    value: undefined,
  },
  reducers: {
    update_relationship_list: (state, action) => {
      state.value = action.payload.relationList;
    },
  },
});

export const { update_relationship_list } = relationshipSlice.actions;

export default relationshipSlice.reducer;
