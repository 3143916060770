import { createSlice } from "@reduxjs/toolkit";

export const empHrmSlice=createSlice({
    name:'empHrmSlice',
    initialState:{
        value:undefined,
       
    },
    reducers:{
        view_emp_hrm:(state,action)=>{
            state.value=action.payload.empHrmData
        }
    }
})

export const {view_emp_hrm}=empHrmSlice.actions
export default empHrmSlice.reducer