import { createSlice } from "@reduxjs/toolkit";

export const generateGrnTransactionIdSlice=createSlice({
    name:'generateGrnTransactionIdSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        create_grn_transaction_id:(state,action)=>{
            state.value=action.payload.grnTransactionId
        }
    }
})

export const {create_grn_transaction_id}=generateGrnTransactionIdSlice.actions;
export default generateGrnTransactionIdSlice.reducer;