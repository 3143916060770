import { createSlice } from "@reduxjs/toolkit";

export const glLinkCodeSlice=createSlice({
    name:'glLinkCodeSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_glLinkCode_list:(state,action)=>{
            state.value=action.payload.glLinkCode
        }
    }
})

export const {update_glLinkCode_list}=glLinkCodeSlice.actions
export default glLinkCodeSlice.reducer