import { createSlice } from "@reduxjs/toolkit";

export const collarClothSlice=createSlice({
    name:"collarClothSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        update_collar_cloth:(state,action)=>{
            state.value=action.payload.collarCloth
        }
    }
})

export const {update_collar_cloth}=collarClothSlice.actions
export default collarClothSlice.reducer