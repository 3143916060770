import React, { useState, useEffect } from "react";
import { IconButton, Dialog, Select, Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
import {
  receivedTransferAPICall,
  receivedTransferSingleViewAPICall,
  receivedTransferEditAPICall
} from "../../../../../API/Purchase Manager/transferAPI";
import { calculateTotalCost } from "../../../../../Js/generalFunctions";
import { convertDateFormat } from "../../../../../Js/Date";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
export const RecivedTransfer = () => {
  // received transfer list
  const receivedTransferList = useSelector(
    (state) => state.receivedTransferSlice.value
  );
  // received transfer single view
  const receivedTransferSingleView = useSelector(
    (state) => state.receivedTransferSingleViewSlice.value
  );

  const [receivedTransferView, setReceivedTransferView] = useState(false);
  const [receivedTransferEditDialog, setReceivedTransferEditDialog] =
    useState(false);
  const [itemsArray, setItemsArray] = useState([]);
  const [editableRow, setEditableRow] = useState([]);
  const [editableRowIndex, setEditableRowIndex] = useState(null);
  const [dataForBackEnd,setDataForBackEnd]=useState([])
  const [isModify,setIsModify]=useState(false)
  // loading state
  const [isLoading,setIsLoading]=useState(false)
  const [snackMsg, setSnackMsg] = useState("oops!!!");
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [searchKeyword,setSearchKeyword]=useState("")
  const [filteredSearchList,setFilteredSearchList]=useState([])
  const closeErrorSnack = () => {
    setErrorSnackOpen(false);
  };
  const editableFormInitialState = {
    productName: "",
    receivedQty: 0,
    unit: "",
    unitCost: "",
    totalCost: "",
  };
  const [editableForm, setEditableForm] = useState(editableFormInitialState);

 const updateListner=()=>{
  setIsModify(!isModify)
 }

  useEffect(() => {
    receivedTransferAPICall(setIsLoading,setErrorSnackOpen,setSnackMsg);
  },[isModify]);

  // open received transfer single view
  const openReceivedTransferView = (id) => {
    setReceivedTransferView(true);
    receivedTransferSingleViewAPICall({ id });
  };

  const cancelReceivedTransferEditDialog = () => {
    setReceivedTransferEditDialog(false);
    setReceivedTransferView(true);
  };

  // map single view response into state
  useEffect(() => {
    if (receivedTransferSingleView !== undefined) {
      const {products} =receivedTransferSingleView


      setItemsArray(products?.map(obj=>{return {...obj,receivedQty:obj?.transferQty}}));
    }
  }, [receivedTransferSingleView]);

  


  // open editable dialog
  const openReceivedTransferEditDialog = (singleList, index) => {
    setEditableRow(singleList);
    setEditableRowIndex(index);
    setReceivedTransferView(false);
    setReceivedTransferEditDialog(true);
  };
  //  console.log(editableRow);

  // set editable form
  useEffect(() => {
    setEditableForm({
      ...editableForm,
      productName: editableRow?.itemName,
      receivedQty: editableRow?.receivedQty,
      unit: editableRow?.unit,
      unitCost: Number(editableRow?.outletCost)/Number(editableRow?.receivedQty),
      totalCost: editableRow?.outletCost,
    });
  }, [editableRow]);

  // change transfer qty
  const getTransferQty = (e) => {
    const {value}=e.target
    setEditableForm({ ...editableForm, receivedQty: value });
    itemsArray[editableRowIndex].receivedQty=value
  };
  // console.log(editableForm);
  // change total cost
  useEffect(() => {
    let totalCost = calculateTotalCost(
      editableForm?.unitCost,
      editableForm?.receivedQty
    );
    setEditableForm({ ...editableForm, totalCost });
  }, [editableForm?.receivedQty]);

  // edit button click function
  const clickReceivedQtyEditBtn = () => {
    if (editableRowIndex !== null) {
      let editedRow = itemsArray.map((obj, i) => {
        if (i === editableRowIndex) {
          return {
            ...obj,
            receivedQty: parseFloat(editableForm?.receivedQty),
            totalCost: parseFloat(editableForm?.totalCost),
          };
        } else {
          return obj;
        }
      });
      setItemsArray(editedRow);
      cancelReceivedTransferEditDialog();
    }
  };



  // final api call
  const bodyToApi={
    id:receivedTransferSingleView?._id,
    loc:receivedTransferSingleView?.location,
    transferItems:itemsArray
  }
  const clickFinishBtnFn=()=>{
  // console.log(bodyToApi);
    receivedTransferEditAPICall(bodyToApi,setReceivedTransferView,updateListner)

  }
  const getSearchKeyword=(e)=>{
    setSearchKeyword(e.target.value)
  }
  const SearchFilter=(chars,listToFilter)=>{
    let searchedArray=[]
    let filteredArray=[]
    if(chars!==""){
      searchedArray=listToFilter.filter((obj)=>
      obj?.transNo?.toLowerCase()?.includes(chars?.toLowerCase())||
      obj?.fromLoc?.toLowerCase()?.includes(chars?.toLowerCase())||
      obj?.toLoc?.toLowerCase()?.includes(chars?.toLowerCase())
    )
      filteredArray=searchedArray
    }
    else{
      filteredArray=listToFilter
    }
    return filteredArray
  }
  useEffect(()=>{
    if(receivedTransferList!==undefined){
      let filteredArray=[]
      setFilteredSearchList(receivedTransferList)
      filteredArray=SearchFilter(searchKeyword,receivedTransferList)
      setFilteredSearchList(filteredArray)    
    }
    else{
      setFilteredSearchList([])
    }
    },[searchKeyword,receivedTransferList])
  return (
    <div className="global-page-parent-container">
      <p className="breadcrumb">
        Purchase Manage &gt; Transfer &gt; Received Transfer
      </p>
      <div className="transfer-container">
      <div className="stock-out-product-top-container" style={{alignItems:"center"}}>
          <h3>Received Transfer</h3>
            <div className="view-order-search-container view-order-admin-search-container"
              style={{width:"25%"}}>
                  <input
                    placeholder="Search Transaction Id,Location"
                    value={searchKeyword}
                    onChange={getSearchKeyword}
                  />
                  <IconButton>
                      <i class="bi bi-search search-icon-order"></i>
                  </IconButton>
              </div>
          </div>
        <div className="global-table-container transfer-table">
          <table className="global-table">
            <thead>
              <tr>
                <th>No</th>
                <th>Transaction No</th>
                <th>Location From</th>
                <th>Location To</th>
                <th>Date</th>
                <th>Margin</th>
                <th>status</th>
                <th>View</th>
                <th>Delete</th>
              </tr>
            </thead>
            {
            isLoading?
              <tbody>
                  <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr>
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr>
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr>
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr>
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr> 
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr> 
                          <tr>
                            <td colSpan={10}>
                              {/* <Loader /> */}
                              <Skeleton
                                  variant="rectangular"
                                  animation="wave"
                                  width={"100%"}
                                  height={40}
                              />
                            </td>
                          </tr> 
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr>
              </tbody>:
            <tbody>
              {filteredSearchList?.length!==0?
              filteredSearchList?.slice(0)?.reverse()?.map((r, i) => (
             
                    <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{r?.transNo}</td>
                    <td>{r?.fromLoc}</td>
                    <td>{r?.toLoc}</td>
                    <td>{convertDateFormat(r?.transferDate)}</td>
                    <td>{r?.margin}</td>
                    <td>
                      <p className=
                      {
                        r?.status==="Pending"? "status-pending":
                        r?.status==="confirmed" ?"status-completed"
                        :undefined
                      }
                      >{r?.status}</p>
                    </td>
  
                    <td>
                      <IconButton onClick={() => openReceivedTransferView(r._id)}>
                        <i class="bi bi-eye visibility-icon"></i>
                      </IconButton>
                    </td>
                    <td>
                      <IconButton>
                        <i class="bi bi-trash3 delete-icon"></i>
                      </IconButton>
                    </td>
                  </tr>
                 
               
               )):
               <tr><td colSpan={9} style={{textAlign:"center"}}>No Records</td></tr>
               }
            </tbody>}
          </table>
        </div>
      </div>

      {/* received transfer view */}
      <Dialog open={receivedTransferView} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setReceivedTransferView(false)}>
        <div className="received-stock-dialog-container">
          <h3>Received Transfer</h3>
          <div className="received-stock-dialog-top-container">
            <div className="global-single-input received-stock-input">
              <p>Transaction ID</p>
              <input
                value={receivedTransferSingleView?.transNo}
              />
            </div>
            <div className="global-single-input received-stock-input">
              <p>Location</p>
              <input
                value={receivedTransferSingleView?.location}
              />
            </div>
          </div>
          <div className="received-stock-dialog-table-container">
            <h3>Received Products</h3>
            <div className="global-table-container received-stock-table">
              <table className="global-table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Product Name</th>
                    <th>Transfer Qty</th>
                    <th>Unit</th>
                    <th>Unit Cost</th>
                    <th>Total Cost</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {itemsArray !== undefined &&
                    itemsArray?.map((r, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{r?.itemName}</td>
                        <td>{r?.receivedQty}</td>
                        <td>{r?.unit}</td>
                        <td>{(Number(r?.outletCost)/Number(r?.receivedQty))?.toFixed(2)}</td>
                        <td>{r?.outletCost}</td>
                        <td>
                          <IconButton
                            onClick={() => openReceivedTransferEditDialog(r, i)}
                          >
                            <i class="bi bi-pencil-square edit-icon"></i>
                          </IconButton>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="received-stock-single-view-button-container">
            <button
              onClick={() => setReceivedTransferView(false)}
              className="btn btn-secondary-outlined"
            >
              Cancel
            </button>
            <button onClick={clickFinishBtnFn} className="btn btn-secondary">Finish</button>
          </div>
        </div>
      </Dialog>

      {/* received transfer edit dialog */}
      <Dialog open={receivedTransferEditDialog} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setReceivedTransferEditDialog(false)}>
        <div className="received-stock-dialog-container">
          <h3>Edit</h3>
          <div className="received-stock-dialog-top-container">
            <div className="global-single-input received-stock-input">
              <p>Transaction ID</p>
              <input  value={receivedTransferSingleView?.transNo}/>
            </div>
            <div className="global-single-input received-stock-input">
              <p>Location</p>
              <input  value={receivedTransferSingleView?.location}/>
            </div>
          </div>
          <div className="received-stock-dialog-table-container">
            <h3>Received Products</h3>
            <div className="received-stock-edit-table-container">
              <div className="global-single-input edit-table-input">
                <p>Product Name</p>
                <input disabled value={editableForm?.productName} />
              </div>
              <div className="global-single-input edit-table-input adj-qty">
                <p>Transfer Qty</p>
                <input
                  value={editableForm?.receivedQty}
                  onChange={getTransferQty}
                />
              </div>
              <div className="global-single-input edit-table-input">
                <p>Unit</p>
                <input disabled value={editableForm?.unit} />
              </div>
              <div className="global-single-input edit-table-input">
                <p>Unit Cost</p>
                <input disabled value={editableForm?.unitCost} />
              </div>
              <div className="global-single-input edit-table-input">
                <p>Total Cost</p>
                <input disabled value={editableForm?.totalCost} />
              </div>
            </div>
          </div>
          <div className="received-stock-single-view-button-container">
            <button
              onClick={cancelReceivedTransferEditDialog}
              className="btn btn-secondary-outlined"
            >
              Cancel
            </button>
            <button
              onClick={clickReceivedQtyEditBtn}
              className="btn btn-secondary"
            >
              Edit
            </button>
          </div>
        </div>
      </Dialog>
      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnack}
        message={snackMsg}
      />
    </div>
  );
};
