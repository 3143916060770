import { createSlice } from "@reduxjs/toolkit";

export const goodsReceivedNoteUpdatedSlice = createSlice({
  name: "goodsReceivedNoteUpdatedSlice",
  initialState: {
    apiResList: undefined,
    rowData:undefined,
    generateName:undefined,
    grnViewList:undefined
  },
  reducers: {
    get_updated_grn_list_api_res_list: (state, action) => {
      state.apiResList = action.payload.grnUpdatedResData;
    },
    get_grn_list_row_data_list: (state, action) => {
      state.rowData = action.payload.grnListSingleRowData;
    },
    generate_updated_grn_name: (state, action) => {
      state.generateName = action.payload.grnUpdatedName
    },
   get_grn_list_of_purchase_order:(state, action)=>{
    state.grnViewList=action.payload.grnPoViewList;
   }
   
  },
});

export const {get_updated_grn_list_api_res_list,get_grn_list_row_data_list,generate_updated_grn_name,get_grn_list_of_purchase_order} = goodsReceivedNoteUpdatedSlice.actions;

export default goodsReceivedNoteUpdatedSlice.reducer;