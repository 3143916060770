import axios from "axios";
import store from "../../Redux/store";
import { get_stock_report } from "../../Redux/Report/stockReportSlice";
import { HEADERS,HEADER_FORM_DATA } from "../UrlAndPaths";

export const stockReportAPICall= async (formData,setIsLoading,setLoadingForm)=>{
    setIsLoading(true)
    setLoadingForm!==undefined && setLoadingForm(true)

    await axios.post("report/stockreport1",formData,HEADER_FORM_DATA)
    .then((res)=>{
        if(res.status===200){
            setLoadingForm!==undefined && setLoadingForm(false)
            store.dispatch(get_stock_report({stockReportData:res.data}))
           setIsLoading(false)
        }
    })
    .catch((err)=>{
        setLoadingForm!==undefined && setLoadingForm(false)
        store.dispatch(get_stock_report({stockReportData:undefined}))
        setIsLoading(false)
    })
}