import axios from "axios";
import store from "../../../../../Redux/store";
import { HEADERS } from "../../../../../API/UrlAndPaths";
import { view_pay_head_list,view_payHead_by_id,edit_establishment_id,generate_payHead } from "./payHeadSlice";


export const ViewPayHeadAPI =async(body)=>{
    await axios
    .post('employee/viewPayHeadList',body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(view_pay_head_list({payHeadListViewData:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(view_pay_head_list({payHeadListViewData:undefined}))
    })
}
export const ViewPayHeadByIdAPI =async(body)=>{
    await axios
    .post('employee/viewPayHeadById',body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(view_payHead_by_id({payHeadViewDataById:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(view_payHead_by_id({payHeadViewDataById:undefined}))
    })
}
export const AddPayHeadAPI =async(body,setOpen)=>{
    await axios
    .post('employee/addPayHead',body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(generate_payHead({addPayHead:res.data}))
            setOpen(false)
            // ViewPayHeadAPI()
        }
    })
    .catch((err)=>{
        store.dispatch(generate_payHead({addPayHead:undefined}))
    })
}
export const UpdatePayHeadByIdAPI =async(body,setDialogueClose)=>{
    await axios
    .post('employee/editPayHead',body,HEADERS)
    .then((res)=>{
        if(res.status===201){
            // store.dispatch(edit_establishment_id({updateEstablishmentId:res.data}))
            ViewPayHeadAPI()
            setDialogueClose(false)
        }
    })
    .catch((err)=>{
        store.dispatch(edit_establishment_id({updateEstablishmentId:undefined}))
    })
}