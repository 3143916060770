import React from "react";
import InputBox from "../InputBox";
import { useDispatch, useSelector } from "react-redux";
import { setOpeningBalance } from "../../../../../Redux/Account/cashInHandSlice";
import SubmitBtn from "../SubmitBtn";

const CashInHand = () => {
  const dispatch = useDispatch();
  const { openingBalance } = useSelector((state) => state.cashInHandSlice);
  const cashInHandSlice = useSelector((state) => state.cashInHandSlice);
  const handleOpeningBalanceSubmit = () => {
    console.log(cashInHandSlice);
  };

  return (
    <div
      style={{ padding: "2% 4%" }}
      className="ledger-section-container-input-bank "
    >
      <div>
        {/*Opening Balance*/}
        <InputBox
          label={"Opening Balance"}
          containerStyle={{ width: "31%" }}
          value={openingBalance}
          onChange={(e) => dispatch(setOpeningBalance(e.target.value))}
        />
      </div>
      {/* submitButton */}
      <SubmitBtn onClick={handleOpeningBalanceSubmit} />
    </div>
  );
};

export default CashInHand;
