import { createSlice } from "@reduxjs/toolkit";

export const partnerReport = createSlice({
    name: "PartnerReport",
    initialState: {
      AccountPayable:undefined,
      AccountPayableSingleView:undefined,
      AccountRecievable:undefined,
      AccountRecievableSingleView:undefined,
      ReceivableDueReport:undefined,
      ReceivableDueReportSingleView:undefined,
      PayableDueReport:undefined,
      PayableDueReportSingleView:undefined,
      AccountReceivableAgingReportList:[],
      AccountReceivableAgingReportListSingleView:[],
      AccountPayableAgingReportList:[],
      AccountPayableAgingReportListSingleView:[]

    },

    reducers: {
        setAccountPayable:(state,action)=>{
            state.AccountPayable=action.payload.accountPayableList
        },
        AccountPayable_single_list:(state,action)=>{
            state.AccountPayableSingleView=action.payload.accountPayableSingleList
        },

        Account_Recievable:(state,action)=>{
            state.AccountRecievable=action.payload.accountRecievableList
        },
        Account_Recievable_single_view:(state,action)=>{
            state.AccountRecievableSingleView=action.payload.accountRecievableSingleList
        },
        setAccountReceivableAgingReport:(state,action)=>{
            state.AccountReceivableAgingReportList=action.payload
        },
        setAccountReceivableAgingReportSingleView:(state,action)=>{
            state.AccountReceivableAgingReportListSingleView=action.payload
        },
        setAccountPayableAgingReport:(state,action)=>{
            state.AccountPayableAgingReportList=action.payload
        },
        setAccountPayableAgingReportSingleView:(state,action)=>{
            state.AccountPayableAgingReportListSingleView=action.payload
        },
        view_receivable_due_report_list:(state,action)=>{
            state.ReceivableDueReport=action.payload.ReceivableDueReportData
        },
        receivable_due_report_single_view_list:(state,action)=>{
            state.ReceivableDueReportSingleView=action.payload.ReceivableDueReportSingleViewData
        },
        view_payable_due_report_list:(state,action)=>{
            state.PayableDueReport=action.payload.PayableDueReportData
        },
        payable_due_report_single_view_list:(state,action)=>{
            state.PayableDueReportSingleView=action.payload.PayableDueReportSingleViewData
        },
    }
})
export const {payable_due_report_single_view_list,view_payable_due_report_list,receivable_due_report_single_view_list,view_receivable_due_report_list,
    setAccountPayableAgingReportSingleView,setAccountPayableAgingReport,setAccountReceivableAgingReportSingleView,setAccountReceivableAgingReport,setAccountPayable,AccountPayable_single_list,Account_Recievable, Account_Recievable_single_view
}=partnerReport.actions;
export default partnerReport.reducer;