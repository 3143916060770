import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { IconButton, Skeleton } from "@mui/material";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import InputBox from "../../../../../components/InputBox";

const CashFlowStatementDirect = ({ selectListItem }) => {
  const componentRef = useRef();
  return (
    <div id="currentAsset-report">
      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="accounting-voucher-menu-sections"
      >
        <h3 style={{ margin: "2% 1%" }}>{selectListItem}</h3>
        <div className="input-current-asset-reports">
          <InputBox label={"From"} type={"date"} />
          <InputBox label={"To"} type={"date"} />
        </div>
      </div>
      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="accounting-voucher-menu-sections"
        id="cashflow-statement-direct"
      >
        <div className="action-btn-container">
          <div>
            <ReactToPrint
              trigger={() => (
                <IconButton sx={{ color: "blue" }}>
                  <i className="bi bi-printer "></i>
                </IconButton>
              )}
              content={() => componentRef.current}
            />
            <IconButton className="download-btn">
              <ArrowCircleDownIcon sx={{ fontSize: 30 }} />
            </IconButton>
          </div>
        </div>

        <div className="global-table-wrapper">
          <table className="global-table">
            <thead>
              <tr>
                <th></th>
                <th className="amount-column">Amount</th>
                <th className="amount-column">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr className="data-table-row-head">
                <td className="data-row">
                  <h3 className="main-head">
                    Cash Flow From Operating Activities
                  </h3>
                  <ul className="data-row-list">
                    <li>Cash Received from Customers</li>
                    <li>[ - ] Cash paid to Suppliers</li>
                    <li>[ - ] Cash payment for Interest</li>
                    <li>[ - ] Cash payment for Taxes</li>
                  </ul>
                  <p className="net-cash-flow">
                    Net Cash Flow from Operating Activities
                  </p>
                </td>
                <td className="data-row ">
                  <ul className="data-row-amount ">
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                  </ul>
                </td>
                <td className="data-row">
                  <ul className="data-row-amount ">
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>10000</li>
                  </ul>
                </td>
              </tr>

              <tr className="data-table-row-head">
                <td className="data-row">
                  <h3 className="main-head">
                    Cash Flow From Investing Activities
                  </h3>
                  <ul className="data-row-list">
                    <li>Purchase of Fixed Assets</li>
                    <li>Plant and Machinery</li>
                    <li>Furniture and Fixtures</li>
                  </ul>
                  <p className="net-cash-flow">
                    Net Cash Flow from Investing Activities
                  </p>
                </td>
                <td className="data-row ">
                  <ul className="data-row-amount ">
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                  </ul>
                </td>
                <td className="data-row ">
                  <ul className="data-row-amount ">
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>10000</li>
                  </ul>
                </td>
              </tr>

              <tr className="data-table-row-head">
                <td className="data-row">
                  <h3 className="main-head">
                    Cash Flow From Financing Activities
                  </h3>
                  <ul className="data-row-list">
                    <li>Process for Borrowings</li>
                    <li>[ - ] Payment of Dividend</li>
                  </ul>
                  <p className="net-cash-flow">
                    Net Cash Flow from Financing Activities
                  </p>
                </td>
                <td className="data-row ">
                  <ul className="data-row-amount ">
                    <li>0.00</li>
                    <li>0.00</li>
                  </ul>
                </td>
                <td className="data-row ">
                  <ul className="data-row-amount ">
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>10000</li>
                  </ul>
                </td>
              </tr>

              <tr id="total-box-input" className="data-table-row-head">
                <td>
                  <ul className="list-total">
                    <li>
                      <h3>Net Increase / Decrease in Cash for the period</h3>
                    </li>
                    <li>
                      <h5>Cash in the Beginning for the period</h5>
                    </li>
                    <li>
                      <h3>Cash at the End of period</h3>
                    </li>
                  </ul>
                </td>
                <td></td>
                <td>
                  <div className="list-total-wrapper">
                    <div>
                      <input type="text" />
                    </div>
                    <div>
                      <input type="text" />
                    </div>
                    <div>
                      <input type="text" />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* print out  */}
        <div style={{ display: "none" }}>
          <div ref={componentRef} className="print-preview-container">
            <div className="print-head-container">
              <div className="brand-name">
                <h2>Leeyet Textile</h2>
              </div>
              <div className="select-table-head">
                <h3>{selectListItem}</h3>
              </div>
              <div className="duration-date">
                <h3>{"01/04/2023"}</h3>
                <strong>TO</strong>
                <h3>{"01/12/2023"}</h3>
              </div>
            </div>

         
            <div className="global-table-wrapper">
          <table className="global-table account-print-table">
            <thead>
              <tr>
                <th></th>
                <th className="amount-column">Amount</th>
                <th className="amount-column">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr className="data-table-row-head">
                <td className="data-row">
                  <h3 className="main-head">
                    Cash Flow From Operating Activities
                  </h3>
                  <ul className="data-row-list">
                    <li>Cash Received from Customers</li>
                    <li>[ - ] Cash paid to Suppliers</li>
                    <li>[ - ] Cash payment for Interest</li>
                    <li>[ - ] Cash payment for Taxes</li>
                  </ul>
                  <p className="net-cash-flow">
                    Net Cash Flow from Operating Activities
                  </p>
                </td>
                <td className="data-row ">
                  <ul className="data-row-amount ">
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                  </ul>
                </td>
                <td className="data-row">
                  <ul className="data-row-amount ">
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>10000</li>
                  </ul>
                </td>
              </tr>

              <tr className="data-table-row-head">
                <td className="data-row">
                  <h3 className="main-head">
                    Cash Flow From Investing Activities
                  </h3>
                  <ul className="data-row-list">
                    <li>Purchase of Fixed Assets</li>
                    <li>Plant and Machinery</li>
                    <li>Furniture and Fixtures</li>
                  </ul>
                  <p className="net-cash-flow">
                    Net Cash Flow from Investing Activities
                  </p>
                </td>
                <td className="data-row ">
                  <ul className="data-row-amount ">
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                  </ul>
                </td>
                <td className="data-row ">
                  <ul className="data-row-amount ">
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>10000</li>
                  </ul>
                </td>
              </tr>

              <tr className="data-table-row-head">
                <td className="data-row">
                  <h3 className="main-head">
                    Cash Flow From Financing Activities
                  </h3>
                  <ul className="data-row-list">
                    <li>Process for Borrowings</li>
                    <li>[ - ] Payment of Dividend</li>
                  </ul>
                  <p className="net-cash-flow">
                    Net Cash Flow from Financing Activities
                  </p>
                </td>
                <td className="data-row ">
                  <ul className="data-row-amount ">
                    <li>0.00</li>
                    <li>0.00</li>
                  </ul>
                </td>
                <td className="data-row ">
                  <ul className="data-row-amount ">
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>10000</li>
                  </ul>
                </td>
              </tr>

              <tr id="total-box-input" className="data-table-row-head">
                <td>
                  <ul className="list-total">
                    <li>
                      <h3>Net Increase / Decrease in Cash for the period</h3>
                    </li>
                    <li>
                      <h5>Cash in the Beginning for the period</h5>
                    </li>
                    <li>
                      <h3>Cash at the End of period</h3>
                    </li>
                  </ul>
                </td>
                <td></td>
                <td>
                  <div className="list-total-wrapper">
                    <div>
                      <input type="text" />
                    </div>
                    <div>
                      <input type="text" />
                    </div>
                    <div>
                      <input type="text" />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashFlowStatementDirect;
