import React,{useState,useEffect} from 'react'
import '../../../css/Sales/oldStock.css'
import {IconButton,Dialog,Select, Skeleton} from '@mui/material'
import {damagedGoodsAPICall,damagedGoodsSingleViewAPICall} from '../../../API/Sales/damagedGoodsAPI'
import {useSelector} from 'react-redux'
import ErrorSnackbar from '../../Single Components/SnackBars/ErrorSnackbar'


export const DamagedGoods = () => {
  const damagedGoodsList = useSelector((state)=>state.damagedGoodSlice.value)
  const damagedGoodsSingle =useSelector((state)=>state.damagedGoodsSingle.value)
// login response
const loginResponse = useSelector((state) => state.loginResponseSlice.value);
// role based rendering
const userRole = useSelector((state) => state.userRoleSlice.value);
  const [damagedGoodsView,setDamagedGoodsView]=useState(false)
  const [isLoading,setIsLoading]=useState(false)
  const [snackMsg, setSnackMsg] = useState("oops!!!");
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [searchKeyword,setSearchKeyword]=useState("")
  const [filteredSearchList,setFilteredSearchList]=useState([])
  const closeErrorSnack = () => {
    setErrorSnackOpen(false);
  };
  useEffect(()=>{
    damagedGoodsAPICall({branchId:loginResponse?.branchId},setIsLoading,setErrorSnackOpen,setSnackMsg)
  },[])

  const openDamagedGoodsSingleView=(id)=>{
    setDamagedGoodsView(true)
    damagedGoodsSingleViewAPICall({_id:id})
  }
  const getSearchKeyword=(e)=>{
    setSearchKeyword(e.target.value)
  }
  const SearchFilter=(chars,listToFilter)=>{
    let searchedArray=[]
    let filteredArray=[]
    if(chars!==""){
      searchedArray=listToFilter.filter((obj)=>
      obj?.transNo?.toLowerCase()?.includes(chars?.toLowerCase())||
      obj?.customerName?.toLowerCase()?.includes(chars?.toLowerCase())||
      obj?.invoiceNo?.toLowerCase()?.includes(chars?.toLowerCase())
    )
      filteredArray=searchedArray
    }
    else{
      filteredArray=listToFilter
    }
    return filteredArray
  }
  useEffect(()=>{
    if(damagedGoodsList!==undefined){
      let filteredArray=[]
      setFilteredSearchList(damagedGoodsList)
      filteredArray=SearchFilter(searchKeyword,damagedGoodsList)
      setFilteredSearchList(filteredArray)    
    }
    else{
      setFilteredSearchList([])
    }
    },[searchKeyword,damagedGoodsList])
  return (
    <div className="global-page-parent-container">
    <p className="breadcrumb">Sales &gt; Old Damaged Goods</p>
    <div className="global-white-bg-container sales-old-stock">
    <div className="stock-out-product-top-container" style={{alignItems:"center"}}>
          <h3>Damaged Goods </h3>
          <div className="view-order-search-container view-order-admin-search-container"
            style={{width:"25%"}}>
                <input
                  placeholder="Search Transaction Id,supplier name,invoice no"
                  value={searchKeyword}
                  onChange={getSearchKeyword}
                />
                <IconButton>
                    <i class="bi bi-search search-icon-order"></i>
                </IconButton>
            </div>
        </div>
      <div className="global-table-container">
        <table className="global-table">
           <thead>
             <tr>
              <th>No</th>
              {
                userRole === 'admin' &&
                <>
                 <th>Branch Code</th>
                 <th>Branch Name</th>
                </>
             
              }
              
              <th>Transaction ID</th>
              <th>Supplier Name</th>
              <th>Invoice No</th>
              <th>Paid Amount</th>
              <th>Balance Amount</th>
              <th>View</th>
             </tr>
           </thead>
           {
            isLoading?
              <tbody>
                  <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr>
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr>
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr>
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr>
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr> 
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr> 
                          <tr>
                            <td colSpan={10}>
                              {/* <Loader /> */}
                              <Skeleton
                                  variant="rectangular"
                                  animation="wave"
                                  width={"100%"}
                                  height={40}
                              />
                            </td>
                          </tr> 
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr>
              </tbody>:
           <tbody>
            {filteredSearchList?.length!==0?
              filteredSearchList?.slice(0)?.reverse()?.map((r,i)=>(
                <tr>
                <td>{i+1}</td>
                  {
                    userRole === 'admin' &&
                    <>
                     <td>{r?.branchCode}</td>
                     <td>{r?.branchName}</td>
                    </>
                  }
               
                <td>{r?.transNo}</td>
                <td>{r?.customerName}</td>
                <td>{r?.invoiceNo}</td>
                <td>{r?.paidAmount}</td>
                <td>{r?.balanceAmount}</td>
                <td>
                   <IconButton onClick={()=>openDamagedGoodsSingleView(r?._id)}>
                      <i class="bi bi-eye visibility-icon"></i>
                    </IconButton>
                </td>
              </tr>
              ))
            :
            <tr><td colSpan={9} style={{textAlign:"center"}}>No Records</td></tr>
            }
           
           
           </tbody>}
        </table>
      </div>
    </div>

    {/* old stock view  */}
       <Dialog open={damagedGoodsView} maxWidth="llg" onKeyDown={(e)=>e.key === "Escape" && setDamagedGoodsView(false)}>
         <div className="sales-old-stock-view">
          <div className="sales-old-stock-view-top">
            <h3>View</h3>
            <hr className="global-hr"/>
            <div className="sales-old-stock-view-top-input-container">
            <div className="global-single-input">
                 <p>Transaction ID</p>
                 <input type="text" disabled value={damagedGoodsSingle?.transactionId}/>
               </div>
               <div className="global-single-input">
                 <p>Supplier Name</p>
                 <input disabled value={damagedGoodsSingle?.customerName} ></input>
               </div>
               <div className="global-single-input">
                 <p>Invoice</p>
                 <input disabled value={damagedGoodsSingle?.invoice}></input>
                 {/* <p className="suffix-text">Total Amount:5000.00 USD</p> */}
               </div>
               <div className="global-single-input" style={{width:"17%"}} >
                 <p>Paid Amount</p>
                 <input type="text" disabled value={damagedGoodsSingle?.paidAmount}/>
               </div>
               <div className="global-single-input" style={{width:"17%"}}>
                 <p>Balance Amount</p>
                 <input type="text" disabled value={damagedGoodsSingle?.balanceAmount}/>
               </div>
            </div>
          </div>
          {/* table */}
          <div className="global-table-container old-stock-table">
            <table className="global-table">
               <thead>
                <tr>
                  <th>No</th>
                  <th>Product Name</th>
                  <th>Return Date</th>
                  <th>Qty</th>
                  <th>Rate</th>
                  <th>Amount</th>
                  <th>Return Qty</th>
                  <th>Rate</th>
                  <th>Amount</th>
                  <th>Type</th>
                </tr>
               </thead>
               <tbody>
                {
                  damagedGoodsSingle?.returninfo?.map((r,i)=>(
                  <tr>
                   <td>{i+1}</td>
                   <td>{r?.itemName}</td>
                   <td>{r?.returnDate}</td>
                   <td>{r?.originalQty}</td>
                   <td>{r?.originalRate}</td>
                   <td>{r?.originalAmt}</td>
                   <td>{r?.returnQty}</td>
                   <td>{r?.rate}</td>
                   <td>{r?.amount}</td>
                   <td>{r?.reasonType}</td>
                 </tr>
                  ))
                }
                 
                 
               </tbody>
            </table>
          </div>
          <div style={{display:"flex",justifyContent:"center"}}>
            <button onClick={()=>setDamagedGoodsView(false)} className="btn btn-secondary-outlined">Cancel</button>
            <button className="btn btn-primary">Submit</button>
         </div>
         </div>
       </Dialog>
       <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnack}
        message={snackMsg}
      />
    </div>
  )
}
