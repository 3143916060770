import axios from "axios";
import { HEADERS } from "../../UrlAndPaths";

// employee apply Loan

export const applyLeaveStaffAPICall=async(
    body,
    updateListener,
    setSucces,
    setError,
    setMsg
    )=>{
    await axios.post('employee/staffLeave',body,HEADERS)
            .then((res)=>{
                if(res.status === 200){
                    updateListener()
                    setSucces(true)
                    setMsg('Applied For Leave Successfully')
                }
            }).catch((err)=>{
                setError(true)
                console.error(err.response);
            })
}