import React, { useState } from 'react'
import CustomPagination from '../../Single Components/CustomPagination'
import CategorySearchandFilter from '../../Single Components/CategorySearchandFilter'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { AddContractAPI, AddContractTypeAPI, ListContractAPI, ListContractTypeAPI, ListSponserAPI, delConTypeAPICall, editConTypeAPICall, editContractAPICall, singleViewContractAPI } from './ContractApi'
import { Autocomplete, Button, Dialog, IconButton, TextField, Tooltip } from '@mui/material'
import { getAllDepartmentAPICall, getAllDesignationAPICall } from '../../../API/Staff/HRM/departmentAndDesignationAPI'
import SuccessSnackbar from '../../Single Components/SnackBars/SuccessSnackbar'
import ErrorSnackbar from '../../Single Components/SnackBars/ErrorSnackbar'
import { viewAllBranchesAPICall } from '../../../API/Settings/Company Settings/companySettingsAPI'
import { currentTime } from '../../../Js/Date'
import { ListConfigurationAPI } from './configuration/configurationAPI'
import { findObjFromList } from '../../../Js/generalFunctions'
import store from '../../../Redux/store'
import { get_contract_single_id } from './contractSlice'
import expired from '../../../Assets/statusRibbon/expired.png'
import running from '../../../Assets/statusRibbon/running.png'



const Contract = () => {

    const [openSuccessSnack, setOpenSuccessSnack] = useState(false);
    const [openErrorSnack, setOpenErrorSnack] = useState(false);
    const [snackMsg, setSnackMsg] = useState("oops!!");
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [openConAdd, setOpenConAdd] = useState(false);
    const [editConTypesField, setEditConTypeField] = useState(false);
    const [conName, setConName] = useState("");
    const [editConTypeId, setEditConTypeId] = useState("");


    const listContract = useSelector((state) => state.contractSlice.contractList)

    const allActiveEmpList = useSelector(
        (state) => state.allActiveEmployeeSlice.value
    );

    const allDepartmentList = useSelector(
        (state) => state.allDepartmentSlice.value
    );

    const allDesignationList = useSelector(
        (state) => state.allDesignationListSlice.value
    );

    const allContractTypeList = useSelector(
        (state) => state.contractSlice.contractTypeList
    );

    const allBranchesList = useSelector(
        (state) => state.allBranchesSlice.value);

    const listSponser = useSelector(
        (state) => state.contractSlice?.sponserList)

    const listPayTemplate = useSelector(
        (state) => state.configurationSlice?.payTemplateList)

    const SingleView = useSelector(
        (state) => state.contractSlice?.contractSingle)

    console.log(SingleView);


    //Close SuccessSnackbar
    const closeSuccessSnackbar = () => {
        setOpenSuccessSnack(false);
    };

    //Close ErrorSnackbar
    const closeErrorSnackbar = () => {
        setOpenErrorSnack(false);
    };
    const handleClickOpen = () => {
        store.dispatch(get_contract_single_id(undefined))
        setIsDialogOpen(true);
    };
    const handleClose = () => {

        setIsDialogOpen(false);
    };

    //edit 
    const editNewConType = () => {
        if (conName !== "") {
            editConTypeAPICall(
                {
                    _id: editConTypeId,
                    type: conName,
                },
                setOpenSuccessSnack,
                setOpenErrorSnack,
                setSnackMsg
            );
            setConName("");
            setEditConTypeField(false);
        }
    }

    //edit contract
    const clickEditConType = (conTypeBody) => () => {
        setEditConTypeField(true);
        setConName(conTypeBody?.type);
        setEditConTypeId(conTypeBody?._id);
        // assignDocType(docTypeBody)
    };

    //delete contract
    const clickDelConType = (id) => () => {

        delConTypeAPICall({ _id: id }, setOpenErrorSnack, setSnackMsg);

    };

    

    //add contract
    const createNewConType = () => {
        if (conName !== "") {
            AddContractTypeAPI(
                { type: conName },
                setOpenSuccessSnack,
                setOpenErrorSnack,
                setSnackMsg
            );
            // updateListener();
            setConName("");
        } else {
            console.log("try");
        }
    };



    const FormInitial = {
        payTemplate: null,
        employee: null,
        department: null,
        designation: null,
        contractType: null,
        sponser: null,
        branch: null,
        durationFrom: null,
        durationTo: null,
        duration: "",
        durationType: "",
        reference: "",
        isEdit: false,
        id: ""
    }
    const [formValues, setFormValues] = useState(FormInitial)

    //month difference
    const monthDifference = (dateFrom, dateTo) => {
        const fromDate = new Date(dateFrom);
        const toDate = new Date(dateTo);
        let months;
        months = (toDate.getFullYear() - fromDate.getFullYear()) * 12;
        months -= fromDate.getMonth();
        months += toDate.getMonth();
        return months <= 0 ? 0 : months;
    };


    const getFormInfo = (key) => (e, newValue) => {
        const { value } = e.target;
        const updatedFormValues = { ...formValues, [key]: value !== 0 ? value : newValue };

        const durationFrom = updatedFormValues.durationFrom;
        const durationTo = updatedFormValues.durationTo;

        if (durationFrom && durationTo) {
            let monthsDiff = monthDifference(durationFrom, durationTo);
            let durationType = "Month";

            if (monthsDiff >= 12) {
                monthsDiff = Math.floor(monthsDiff / 12);
                durationType = "Years";
            }

            updatedFormValues.duration = monthsDiff.toString();
            updatedFormValues.durationType = durationType;
        }

        setFormValues(updatedFormValues);
    };
    const handleSave = () => {
        let body = {
            currentDate: currentTime,
            employeeId: formValues?.employee?._id,
            departmentId: formValues?.department?._id,
            designationId: formValues?.designation?._id,
            contractTypeId: formValues?.contractType?._id,
            sponsorId: formValues?.sponser?._id,
            branchId: formValues?.branch?._id,
            durationFrom: formValues?.durationFrom,
            durationTo: formValues?.durationTo,
            reference: formValues?.reference,
            payTemplateId: formValues?.payTemplate?._id
        }
        AddContractAPI(body, setOpenSuccessSnack,
            setOpenErrorSnack,
            setSnackMsg)
    }
    const handleRowClick = (id) => {
        singleViewContractAPI({ _id: id })
        setIsDialogOpen(true)
    }

    const handleEdit = () => {
        if (formValues.isEdit === true) {
            setFormValues({ ...formValues, isEdit: false })
        }
        else {
            let body = {
                currentDate: currentTime,
                employeeId: formValues?.employee?._id,
                departmentId: formValues?.department?._id,
                designationId: formValues?.designation?._id,
                contractTypeId: formValues?.contractType?._id,
                sponsorId: formValues?.sponser?._id,
                branchId: formValues?.branch?._id,
                durationFrom: formValues?.durationFrom,
                durationTo: formValues?.durationTo,
                reference: formValues?.reference,
                payTemplateId: formValues?.payTemplate?._id,
                contractId: formValues?.id
            }
            const draftFn = () => {
                setFormValues({ ...formValues, isEdit: true });
            };
            editContractAPICall(body, draftFn, setOpenSuccessSnack,
                setOpenErrorSnack,
                setSnackMsg)
        }
    }

    useEffect(() => {
        if (SingleView !== undefined) {
            setFormValues({
                ...formValues,
                employee: findObjFromList("_id", allActiveEmpList, "employeeId", SingleView),
                department: findObjFromList("_id", allDepartmentList, "departmentId", SingleView),
                designation: findObjFromList("_id", allDesignationList, "designationId", SingleView),
                contractType: findObjFromList("_id", allContractTypeList, "contractTypeId", SingleView),
                sponser: findObjFromList("_id", listSponser, "sponsorId", SingleView),
                branch: findObjFromList("_id", allBranchesList, "branchId", SingleView),
                durationFrom: SingleView?.durationFrom,
                durationTo: SingleView?.durationTo,
                payTemplate: findObjFromList("_id", listPayTemplate, "payTemplateId", SingleView),
                reference: SingleView?.reference,
                isEdit: true,
                id: SingleView?._id,
                status: SingleView?.status
            })
        } else {
            setFormValues(FormInitial)
        }
    }, [SingleView])


    useEffect(() => {
        ListContractAPI()
        getAllDepartmentAPICall()
        getAllDesignationAPICall()
        ListContractTypeAPI()
        ListSponserAPI()
        viewAllBranchesAPICall();
        ListConfigurationAPI()
    }, [])
    return (
        <div className="global-page-parent-container">
            <div className="global-white-bg-container">
                <div className="justify-space-between"
                    style={{ gap: "0 12px", justifyContent: "flex-end" }}
                >

                    <button
                        className="establish-create"
                        onClick={handleClickOpen}
                    >
                        Create
                    </button>
                </div>
                <div className="create-button-blue-container">
                    <h3>Contract</h3>

                </div>
                <div className="new-global-table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Department</th>
                                <th>Designation</th>
                                <th>Contract Type</th>
                                <th>Sponsor</th>
                                <th>Branch</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Working sche</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listContract?.map((item, index) => (
                                <tr key={index} onClick={() => handleRowClick(item._id)}>
                                    <td>{item?.employee}</td>
                                    <td>{item?.department}</td>
                                    <td>{item?.designation}</td>
                                    <td>{item?.contractType}</td>
                                    <td>{item?.sponsor}</td>
                                    <td>{item?.branch}</td>
                                    <td>{item?.from}</td>
                                    <td>{item?.to}</td>
                                    <td>--</td>
                                    <td className="table-data-box">
                                        {item?.status === "DRAFTED" ? (
                                            <span className="draft"> DRAFT</span>
                                        ) : item?.status === "POSTED" ? (
                                            <span className="post"> POST</span>
                                        ) : item?.status === "CANCELLED" ? (
                                            <span className="cancel">CANCEL</span>
                                        ) : item?.status === "Running" ? (
                                            <span className="running">RUNNING</span>
                                        ) : (
                                            <span>{item?.status}</span>
                                        )}
                                    </td>
                                </tr>

                            ))}
                        </tbody>
                    </table>

                </div>
            </div>

            {/* add contract */}

            <Dialog open={isDialogOpen}maxWidth='lg' sx={{ overflow: "hidden" }}>
                <div style={{ width: "62vw", height: "45vh" }}>
                    <div className="global-white-bg-container ">
                        <div className="food_config_secound_header" style={{ paddingInline: "0" }}>
                            <div className="post-ribbon" style={{ right: "0px", top: "-3px" }}>
                                {formValues?.status === "Expired" && <img src={expired} alt="" />}
                                {/* {formValues?.status === "POSTED" && <img src={postRibbon} alt="" />} */}
                                {formValues?.status === "Running" && <img src={running} alt="" />}

                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Tooltip title="Back">
                                    <IconButton onClick={handleClose}>
                                        <i className="bi bi-chevron-left" style={{ fontSize: '30px', color: 'black', cursor: 'pointer' }}></i>
                                    </IconButton>
                                </Tooltip>
                                {SingleView !== undefined ? (
                                    <Tooltip title="Edit">
                                        <IconButton onClick={handleEdit}>
                                            <i
                                                class={formValues?.isEdit ?
                                                    "bi bi-pencil-square edit-icon1" :
                                                    "bi bi-check2-square edit-icon"
                                                }
                                            ></i>
                                        </IconButton>
                                    </Tooltip>
                                ) : (

                                    <Tooltip title="Save">
                                        <IconButton onClick={handleSave}>
                                            <i className="bi bi-floppy" style={{ color: 'black' }}></i>
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {/* <button  className='create-button-blue' style={{ backgroundColor: "rgb(245 112 52)", marginLeft: "10px" }}>Cancel</button> */}
                            </div>
                        </div>
                        <hr className="global-hr" />
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                            <div className="new-global-single-input auto-complete-new">
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={allActiveEmpList || []}
                                    getOptionLabel={(option) => option?.staff_name}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField
                                            // onChange={(e) => customerListApi({ search: e.target.value })}
                                            {...params}
                                            label="Employee*"
                                            focused
                                        />
                                    )}
                                    onChange={getFormInfo("employee")}
                                    value={formValues?.employee}
                                    disabled={formValues?.isEdit}
                                />

                            </div>
                            <div className="new-global-single-input auto-complete-new">
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={allDepartmentList || []}
                                    getOptionLabel={(option) => option?.departmentName}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField
                                            // onChange={(e) => customerListApi({ search: e.target.value })}
                                            {...params}
                                            label="Department*"
                                            focused
                                        />
                                    )}

                                    onChange={getFormInfo("department")}
                                    value={formValues?.department}
                                    disabled={formValues?.isEdit}
                                />

                            </div>
                            <div className="new-global-single-input auto-complete-new">
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={allDesignationList || []}
                                    getOptionLabel={(option) => option?.position}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField
                                            // onChange={(e) => customerListApi({ search: e.target.value })}
                                            {...params}
                                            label="Designation*"
                                            focused
                                        />
                                    )}
                                    onChange={getFormInfo("designation")}
                                    value={formValues?.designation}
                                    disabled={formValues?.isEdit}
                                />

                            </div>

                            <div className="new-global-single-input auto-complete-new">
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={allContractTypeList || []}
                                    getOptionLabel={(option) => option?.type}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField
                                            // onChange={(e) => customerListApi({ search: e.target.value })}
                                            {...params}
                                            label="Contract Type*"
                                            focused
                                        />
                                    )}

                                    onChange={getFormInfo("contractType")}
                                    value={formValues?.contractType}
                                    disabled={formValues?.isEdit}
                                />
                                   
                                <p style={{ margin: "0", alignSelf: "self-end", color: "red", cursor: "pointer" }} onClick={() => setOpenConAdd(true)}  disabled={formValues?.isEdit}>Add Contract Type</p>
                            </div>

                        </div>

                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                            <div className="new-global-single-input auto-complete-new">
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={listSponser || []}
                                    getOptionLabel={(option) => option?.name}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField
                                            // onChange={(e) => customerListApi({ search: e.target.value })}
                                            {...params}
                                            label="Sponser*"
                                            focused
                                        />
                                    )}

                                    onChange={getFormInfo("sponser")}
                                    value={formValues?.sponser}
                                    disabled={formValues?.isEdit}
                                />

                            </div>
                            <div className="new-global-single-input auto-complete-new">
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={allBranchesList || []}
                                    getOptionLabel={(option) => option?.branchName}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField
                                            // onChange={(e) => customerListApi({ search: e.target.value })}
                                            {...params}
                                            label="Branch*"
                                            focused
                                        />
                                    )}

                                    onChange={getFormInfo("branch")}
                                    value={formValues?.branch}
                                    disabled={formValues?.isEdit}
                                />

                            </div>
                            <div className='new-global-single-input'>
                                <TextField
                                    value={formValues?.durationFrom}
                                    onChange={getFormInfo("durationFrom")}
                                    id="outlined-basic"
                                    label="Duration From*"
                                    variant="outlined"
                                    type="date"
                                    focused
                                    disabled={formValues?.isEdit}
                                />
                                <p className="doc-validation-alert"></p>
                            </div>
                            <div className='new-global-single-input'>
                                <TextField
                                    value={formValues?.durationTo}
                                    onChange={getFormInfo("durationTo")}
                                    id="outlined-basic"
                                    label="Duration To*"
                                    variant="outlined"
                                    type="date"
                                    focused
                                    disabled={formValues?.isEdit}
                                />
                                <p className="doc-validation-alert"></p>
                            </div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div className="new-global-single-input auto-complete-new">
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={listPayTemplate || []}
                                    getOptionLabel={(option) => option?.tempName}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField
                                            // onChange={(e) => customerListApi({ search: e.target.value })}
                                            {...params}
                                            label="Pay Template*"
                                            focused
                                        />
                                    )}
                                    onChange={getFormInfo("payTemplate")}
                                    value={formValues?.payTemplate}
                                    disabled={formValues?.isEdit}
                                />

                            </div>
                            <div className="new-global-single-input" style={{ width: "14%" }}>
                                <TextField
                                    value={formValues?.duration}
                                    // onChange={getFormInfo("paymentReference")}
                                    id="outlined-basic"
                                    label="Duration"
                                    variant="outlined"
                                    type="text"
                                    focused
                                    disabled={formValues?.isEdit}
                                />
                            </div>
                            <div className="new-global-single-input" style={{ width: "15%" }}>
                                <TextField
                                    value={formValues?.durationType}
                                    // onChange={getFormInfo("paymentReference")}
                                    id="outlined-basic"
                                    label="Duration Type"
                                    variant="outlined"
                                    type="text"
                                    focused
                                    disabled={formValues?.isEdit}
                                />
                            </div>
                            <div className="new-global-single-input">
                                <TextField
                                    value={formValues?.reference}
                                    onChange={getFormInfo("reference")}
                                    id="outlined-basic"
                                    label="Reference"
                                    variant="outlined"
                                    type="text"
                                    focused
                                    disabled={formValues?.isEdit}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>

            {/* add contract type */}
            <Dialog
                open={openConAdd}
                maxWidth="md"
                onKeyDown={(e) => e.key === "Escape" && setOpenConAdd(false)}
            >
                <div className="add-rack-dialog-parent" style={{ width: "45vw" }}>
                    <h3>Add Contract Type</h3>
                    <div className="add-rack-form-container">
                        <div className="global-single-input rack-description-input">
                            <p>Contract Type</p>
                            <input
                                placeholder="Adhar,Passport....etc"
                                value={conName}
                                onChange={(e) => {
                                    setConName(e.target.value);
                                }}
                                onKeyDown={(e) => e.key === "Enter" && createNewConType()}
                            />
                        </div>
                        <button
                            className="btn btn-secondary add-rack-btn"
                            onClick={editConTypesField ? editNewConType : createNewConType}
                        >
                            {editConTypesField ? (
                                <p style={{ margin: "0", fontSize: "1rem" }}>Edit</p>
                            ) : (
                                "+"
                            )}
                        </button>
                    </div>
                    <div className="rack-table-container global-table-container">
                        <table className="global-table">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Document Name</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allContractTypeList ? (
                                    allContractTypeList
                                        ?.slice(0)
                                        ?.reverse()
                                        ?.map((r, i) => (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{r.type}</td>
                                                <td>
                                                    <IconButton onClick={clickEditConType(r)}>
                                                        <i class="bi bi-pencil-square edit-icon"></i>
                                                    </IconButton>
                                                    <IconButton onClick={clickDelConType(r._id)}>
                                                        <i class="bi bi-trash3 delete-icon"></i>
                                                    </IconButton>
                                                </td>
                                            </tr>
                                        ))
                                ) : (
                                    <tr>
                                        <td colSpan={3}>No data</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="btn-parent">
                        <button
                            className="btn btn-secondary rack-close-btn"
                            onClick={() => {
                                setOpenConAdd(false);
                                // setEditDocTypeField(false);
                            }}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </Dialog>


            <SuccessSnackbar
                open={openSuccessSnack}
                handleClose={closeSuccessSnackbar}
                message={snackMsg}
            />
            <ErrorSnackbar
                open={openErrorSnack}
                handleClose={closeErrorSnackbar}
                message={snackMsg}
            />
        </div>

    )
}

export default Contract