import axios from "axios";
import { HEADERS } from "../../UrlAndPaths";
import store from "../../../Redux/store";
import { update_pending_payment_voucher_list } from "../../../Redux/Prchase Manager/Payment Voucher/pendingPaymentVoucherSlice";

export const viewPendingPurchaseVoucherAPICall=async(setIsLoading,setErrorSnack,setSnackMsg)=>{
    if(setIsLoading!==undefined)setIsLoading(true)
    await axios.get('purchase/pendingpaymentvoucher',HEADERS).then((res)=>{
        if (res.status === 200){
            console.log('fetched');
            store.dispatch(update_pending_payment_voucher_list({
                pendingPaymentVoucherData: res.data
            }))
            if(setIsLoading!==undefined)setIsLoading(false)
        }

    }).catch((err) =>{
        console.error(err.response);
        store.dispatch(update_pending_payment_voucher_list({
            pendingPaymentVoucherData: undefined
        }))
        switch (err.response.status) {
            case 404:
                setSnackMsg('No data available..')
              break;
            case 500:
                setSnackMsg('Server error!!')
              break;
            case 401:
                setSnackMsg('Unauthorized!!')
              break;
            case 403:
                setSnackMsg('Forbidden!!')
              break;
          
            default:
                setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
              break;
          }
          setErrorSnack(true)
        if(setIsLoading!==undefined)setIsLoading(false)
    })
}