import React, { useEffect, useState } from "react";
import "../../../../../css/Account/reports/financialReports.css";
import "../../../../../css/Account/reports/inventoryReports.css";
import "../../../../../css/Account/reports/payrollReport.css";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import gstReportsGroup from "../../../../../Assets/JSON/gstReportsGroup.json";
import Gstr1 from "./gstReportsGroup/Gstr1";
import Gstr2 from "./gstReportsGroup/Gstr2";
import Gstr3B from "./gstReportsGroup/Gstr3B";
import EwayBill from "./gstReportsGroup/EwayBill";
import GSTAnnualComputation from "./gstReportsGroup/GSTAnnualComputation";
import UpdatePartyGstIn from "./gstReportsGroup/UpdatePartyGstIn";

const GstReports = () => {
  const [active, setActive] = useState(0);

  useEffect(() => {
    handleListItemClick(0, 1, "Stock item List");
  }, []);

  const [selectedReport, setSelectedReport] = useState({ id: null, name: "" });

  const handleListItemClick = (index, id, name) => {
    setActive(index);
    setSelectedReport({ id, name });
  };

  const listItemTextStyle = () => {
    return {
      fontWeight: "600",
      fontSize: "0.9rem",
    };
  };

  return (
    <div className="global-page-parent-container">
      <p className="breadcrumb">
        Accounts &gt; Reports &gt; GST Reports &gt; {selectedReport.name}
      </p>

      <div className="accounting-container">
        <div className="accounting-side-menu">
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              marginBlockStart: "10px",
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {gstReportsGroup.map(({ id, name }, index) => (
              <React.Fragment key={index}>
                <ListItemButton
                  sx={{ paddingLeft: "2em" }}
                  className={active === index ? "isactive" : ""}
                  onClick={() => handleListItemClick(index, id, name)}
                >
                  <ListItemText
                    primary={
                      <Typography sx={listItemTextStyle}>{name}</Typography>
                    }
                  />
                </ListItemButton>
              </React.Fragment>
            ))}
          </List>
        </div>
        <div className="accounting-voucher-menu">
          {selectedReport.id === 1 && (
            <Gstr1 selectListItem={selectedReport.name} />
          )}
          {selectedReport.id === 2 && (
            <Gstr2 selectListItem={selectedReport.name} />
          )}
          {selectedReport.id === 3 && (
            <Gstr3B selectListItem={selectedReport.name} />
          )}
          {selectedReport.id === 4 && (
            <EwayBill selectListItem={selectedReport.name} />
          )}
          {selectedReport.id === 5 && (
            <GSTAnnualComputation selectListItem={selectedReport.name} />
          )}
          {selectedReport.id === 6 && (
            <UpdatePartyGstIn selectListItem={selectedReport.name} />
          )}
        </div>
      </div>
    </div>
  );
};

export default GstReports;
