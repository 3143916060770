import axios from "axios"
import {HEADERS, HEADER_FORM_DATA} from '../UrlAndPaths'
import {get_stock_summary} from '../../Redux/Report/stockSummaryReportSlice'
import store from '../../Redux/store'

//Changed payload type Formdata to JSON by vaishnav on 17/02/2023
export const stockSummaryReportAPICall= async (body,setIsLoading)=>{
  setIsLoading(true)
  await axios.post("report/stockSummeryReport",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
    store.dispatch(get_stock_summary({stockSummaryData:res.data}))
    setIsLoading(false)
    }
  })
  .catch((err)=>{
    store.dispatch(get_stock_summary({stockSummaryData:undefined}))
    console.log(err.response);
    setIsLoading(false)
  })
}