import { createSlice } from "@reduxjs/toolkit";

export const posSettingSlice = createSlice({
  name: "posSettingSlice",
  initialState: {
    apiResList: undefined,
  },
  reducers: {
    get_pos_settings_api_res_list: (state, action) => {
      state.apiResList = action.payload.posSettingsResData;
    },
  
  },
});

export const { 
    get_pos_settings_api_res_list,
   
} = posSettingSlice.actions;

export default posSettingSlice.reducer;