import { Autocomplete, MenuItem, Select, Switch, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { viewAllAllowedBranchesAPICall, viewAllBranchesAPICall } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import {
  getAllDepartmentAPICall,
  getAllDesignationAPICall,
} from "../../../../../API/Staff/HRM/departmentAndDesignationAPI";
import {
  addEmployeeQrCodeGenerationAPICall,
  getAllInactiveEmployeesAPICall,
} from "../../../../../API/Staff/HRM/employeeAPI";
import { today } from "../../../../../Js/Date";
import { update_employee_companyInfo_form } from "../../../../../Redux/Staff/HRM/Employee/employeeCompanyInfoFormSlice";
import store from "../../../../../Redux/store";
// css
// '../../../../../css/Staff/HRM/AddEmployee/addEmployee.css'

export const CompanyInfo = (props) => {
  const { moveToBack, moveToNext } = props;
  //*Redux States
  //Inactive Employees
  const allInactiveEmployeeList = useSelector(
    (state) => state.allInactiveEmployeeSlice.value
  );
  //Department List
  const allDepartmentList = useSelector(
    (state) => state.allDepartmentSlice.value
  );
  //Designation List
  const allDesignationList = useSelector(
    (state) => state.allDesignationListSlice.value
  );
  const userRole=useSelector((state)=>state.userRoleSlice.value)
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  //all branches List
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
   // all allowed branches list
   const allowedBranchList = useSelector(
    (state) => state.allAllowedBranchesSlice.value
  );
  
  //Employee Qr
  const empQrCode = useSelector((state) => state.empQrSlice.value);
  const storeCode=localStorage.getItem('branchId')
  //Company Info Form
  const companyInfoFormInitialState = {
    empId: "0",
    dpt: "0",
    designation: "0",
    doj: today,
    dol: "",
    workHours: "",
    outlet: "0",
    allowedLoc:[],
    allowedLocFilter:[],
    salaryType: "0",
    monthlySalary: "",
    contractFrom: today,
    contractTo: "",
    status: true,
    qrCode: null,
  };
  const [companyInfoForm, setCompanyInfoForm] = useState(
    companyInfoFormInitialState
  );
  //Filtered Designation State
  const [filteredDesignation, setFilteredDesignation] = useState([]);

  // validation states
  const [empIdAlert,setEmpIdAlert]=useState(false)
  const [deptAlert,setDeptAlert]=useState(false)
  const [designationAlert,setDesignationAlert]=useState(false)
  const [dateOfJAlert,setDateOfJAlert]=useState(false)
  const [workHrAlert,setWorkHrAlert]=useState(false)
  const [outletAlert,setOutletAlert]=useState(false)
  const [allowedLocAlert,setAllowedLocArray]=useState(null)
  const [salaryTypeAlert,setSalaryTypeAlert]=useState(false)
  const [mnthlySalaryAlert,setMnthlySalaryAlert]=useState(false)
  const [generateQrAlert,setGenerateQrAlert]=useState(false)
  const [allowedBranchArray,setAllowedBranchArray]=useState([])
  const [allBranchArray,setAllBranchArray]=useState([])
  useEffect(() => {
    getAllInactiveEmployeesAPICall({branchId:storeCode});
    getAllDepartmentAPICall();
    getAllDesignationAPICall();
    viewAllBranchesAPICall();
    viewAllAllowedBranchesAPICall({ employeeId: loginResponse?.data?._id });
  }, []);

  //*useEffects
  //Call designation Filter function
  useEffect(() => {
    filterDesignationByDepartment();
  }, [companyInfoForm.dpt]);
  //Assign Generated qr code to its state
  useEffect(() => {
    if (empQrCode !== undefined) {
      setCompanyInfoForm({ ...companyInfoForm, qrCode: empQrCode });
    }
  }, [empQrCode]);

  // filter Designation as per department selected
  const filterDesignationByDepartment = () => {
    let filteredDesignation = allDesignationList?.filter(
      (obj, i) => obj?.departmentId === companyInfoForm.dpt
    );
    setFilteredDesignation(filteredDesignation);
  };

  //* onChange Fn
  const getCompanyInfoData = (key) => (e,newValue) => {
    const { value, checked } = e.target;

    if (key === "empId") {
      setCompanyInfoForm({ ...companyInfoForm, empId: value });
      setEmpIdAlert(false)
      }
    if (key === "dpt") {
      setCompanyInfoForm({ ...companyInfoForm, dpt: value });
      setDeptAlert(false)
    }
    if (key === "designation") {
      setCompanyInfoForm({ ...companyInfoForm, designation: value });
      setDesignationAlert(false)
    }
    if (key === "doj") {
      setCompanyInfoForm({ ...companyInfoForm, doj: value });
      setDateOfJAlert(false)
    }
    if (key === "dol") {
      setCompanyInfoForm({ ...companyInfoForm, dol: value });

    }
    if (key === "workHours") {
      setCompanyInfoForm({ ...companyInfoForm, workHours: value });
      setWorkHrAlert(false)
      if(value===""){
        setWorkHrAlert(true)
      }
    }
    if (key === "outlet") {
      setCompanyInfoForm({ ...companyInfoForm, outlet: value });
      setOutletAlert(false)
    }
    if(key==="allowedLoc"){
      setCompanyInfoForm({ ...companyInfoForm, allowedLoc: newValue});
    }
    if (key === "salaryType") {
      setCompanyInfoForm({ ...companyInfoForm, salaryType: value });
      setSalaryTypeAlert(false)
    }
    if (key === "monthlySalary") {
      setCompanyInfoForm({ ...companyInfoForm, monthlySalary: value });
      setMnthlySalaryAlert(false)
      if(value===""){
        setMnthlySalaryAlert(true)
      }
    }
    if (key === "contractFrom") {
      setCompanyInfoForm({ ...companyInfoForm, contractFrom: value });

    }
    if (key === "contractTo") {
      setCompanyInfoForm({ ...companyInfoForm, contractTo: value });
    }
    if (key === "status") {
      setCompanyInfoForm({ ...companyInfoForm, status: checked });
    }
  };

  useEffect(()=>{
   let data=companyInfoForm?.allowedLoc?.map((obj)=>{
       return{
        outletLocation:obj?._id
       }
    })
    setCompanyInfoForm({ ...companyInfoForm, allowedLocFilter: data });
  },[companyInfoForm?.allowedLoc])

  console.log(companyInfoForm?.allowedLocFilter);
  // console.log(companyInfoForm?.allowedLoc);
  //*onClick Fns
  //Click Next Button

 
  const clickNextButton = () => {
    const {
      empId,
      dpt,
      designation,
      doj,
      dol,
      workHours,
      outlet,
      salaryType,
      monthlySalary,
      contractFrom,
      contractTo,
      status,
      qrCode,
      allowedLocFilter,
      allowedLoc
    } = companyInfoForm;

    if (empId === "0") {
      setEmpIdAlert(true)
    } else if (dpt === "0") {
      setDeptAlert(true)
    } else if (designation === "0") {
      setDesignationAlert(true)
    } 
    // else if (doj === "") {
    //   setDateOfJAlert(true)
    // } else if (workHours === "") {
    //   setWorkHrAlert(true)
    // } 
    else if (outlet === "0") {
      setOutletAlert(true)
    }  
    else if(allowedLoc === null){
      setAllowedLocArray(true)
    }
    // else if (salaryType === "0") {
    //   setSalaryTypeAlert(true)
    // } else if (monthlySalary === "") {
    //   setMnthlySalaryAlert(true)
    // } else if (qrCode === null) {
    //   setGenerateQrAlert(true)
    // } 
    else {
      store.dispatch(
        update_employee_companyInfo_form({
          employeeCompanyInfoForm: companyInfoForm,
        })
      );
      moveToNext();
    }
  };
  //Click Back Button
  const clickBackButton = () => {
    moveToBack();
  };

  //Generate Employee Qr
  const generateQrBody={
    empId: companyInfoForm.empId,
    name: allInactiveEmployeeList?.staff_name,
  }
  const generateEmployeeQr = () => {
    if (companyInfoForm.empId !== "0") {
      addEmployeeQrCodeGenerationAPICall(generateQrBody);
      setGenerateQrAlert(false)
    } else {
      setEmpIdAlert(true)
    }
  };

  // allowed branch
  useEffect(()=>{
    if(allowedBranchList !== undefined){
      let data=allowedBranchList?.filter((obj)=>{
        if(obj?._id!==companyInfoForm.outlet){
          return obj
        }
      })
      setAllowedBranchArray(data?data:allowedBranchList)
    }
   
  },[companyInfoForm.outlet,allowedBranchList])

  // all branch
  useEffect(()=>{
    if(allBranchesList !== undefined){
      let data=allBranchesList?.filter((obj)=>{
        if(obj?._id!==companyInfoForm.outlet){
          return obj
        }
      })
      setAllBranchArray(data?data:allBranchesList)
    }
  
  },[companyInfoForm.outlet,allBranchesList])
  console.log(allowedBranchArray);


  return (
    <div className="personal-info-container">
      <div className="personal-info-input-container">
        <div className="global-single-input add-employee-input">
          <p>Employee ID</p>
          <Select
            value={companyInfoForm.empId}
            onChange={getCompanyInfoData("empId")}
          >
            <MenuItem value="0">-select employee id-</MenuItem>
            {allInactiveEmployeeList?.map((item, i) => (
              <MenuItem
                key={i}
                value={item?._id}
              >{`EMP${item?.emp_id}-${item?.staff_name}`}</MenuItem>
            ))}
          </Select>
          {empIdAlert?
            <p className="doc-validation-alert">Select a Employee !!</p>
          :""}
        </div>
        <div className="global-single-input add-employee-input">
          <p>Department</p>
          <Select
            value={companyInfoForm.dpt}
            onChange={getCompanyInfoData("dpt")}
          >
            <MenuItem value="0">-select department-</MenuItem>
            {allDepartmentList
              ?.slice(0)
              ?.reverse()
              ?.map((item, i) => (
                <MenuItem key={i} value={item?._id}>
                  {item?.departmentName}
                </MenuItem>
              ))}
          </Select>
          {deptAlert?
            <p className="doc-validation-alert">Select a department !!</p>
          :""}
        </div>
        <div className="global-single-input add-employee-input">
          <p>Designation</p>
          <Select
            value={companyInfoForm.designation}
            onChange={getCompanyInfoData("designation")}
          >
            <MenuItem value="0">-select designation-</MenuItem>
            {filteredDesignation?.length > 0 ? (
              filteredDesignation?.map((item, i) => (
                <MenuItem value={item?._id} key={i}>
                  {item?.position}
                </MenuItem>
              ))
            ) : (
              <MenuItem>No Designation</MenuItem>
            )}
          </Select>
          {designationAlert?
            <p className="doc-validation-alert">Select a designation !!</p>
          :""}
        </div>
        <div className="global-single-input add-employee-input">
          <p>Date of Joining</p>
          <input
            type="date"
            value={companyInfoForm.doj}
            onChange={getCompanyInfoData("doj")}
          />
         {dateOfJAlert?
            <p className="doc-validation-alert">Fill this field !!</p>
          :""}
        </div>
        <div className="global-single-input add-employee-input">
          <p>Date of Leaving</p>
          <input
            type="date"
            value={companyInfoForm.dol}
            onChange={getCompanyInfoData("dol")}
          />
        </div>
        <div className="global-single-input add-employee-input">
          <p>Work Hours</p>
          <input
            type="text"
            value={companyInfoForm.workHours}
            onChange={getCompanyInfoData("workHours")}
            placeholder="Enter work hours..."
          />
          {workHrAlert?
            <p className="doc-validation-alert">Fill this field !!</p>
          :""}
        </div>
        <div className="global-single-input add-employee-input">
          <p>Branch</p>
          <Select
            value={companyInfoForm.outlet}
            onChange={getCompanyInfoData("outlet")}
          >
            <MenuItem value="0">-select a branch-</MenuItem>
            {allBranchesList?.map((item, i) => (
              <MenuItem value={item?._id} key={i}>
                {`${item?.storeCode}-${item?.branchName}`}
              </MenuItem>
            ))}
          </Select>
          {outletAlert?
            <p className="doc-validation-alert">Select a value !!</p>
          :""}
        </div>
        <div className="global-single-input add-employee-input auto-complete " style={{margin:"2% 5% 1% 1%",borderRadius:"4px"}}>
            <p>Allowed Branches</p>
            {/* <input type="text" /> */}
             <Autocomplete
               
                    multiple
                    // options={allowedBranchList !== undefined && allBranchesList !== undefined && userRole === "user" ? allowedBranchArray : allBranchArray||[]}
                    options={userRole === "user" ? allowedBranchArray : allBranchArray||[]}
                  
                    getOptionLabel={(option) => `${option?.storeCode}-${option?.branchName}`}
                    renderInput={(params)=>(
                      <TextField {...params} />
                    )}
                    filterSelectedOptions
                    value={companyInfoForm?.allowedLoc}
                    onChange={getCompanyInfoData("allowedLoc")}
                
                  />
                     {allowedLocAlert?
            <p className="doc-validation-alert">Select a value !!</p>
          :""}
        </div>
      </div>
      <hr className="global-hr" />
      <div className="company-info-salary-container">
        <h4>Salary</h4>
        <div className="personal-info-input-container">
          <div className="global-single-input add-employee-input">
            <p>Type</p>
            <Select
              value={companyInfoForm.salaryType}
              onChange={getCompanyInfoData("salaryType")}
            >
              <MenuItem value="0">-select type-</MenuItem>
              <MenuItem value="Full time">Full time</MenuItem>
              <MenuItem value="Part time">Part time</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
            {salaryTypeAlert?
            <p className="doc-validation-alert">Select a value !!</p>
          :""}
          </div>
          <div className="global-single-input add-employee-input">
            <p>Monthly Salary</p>
            <input
              type="text"
              value={companyInfoForm.monthlySalary}
              onChange={getCompanyInfoData("monthlySalary")}
              placeholder="Enter monthly salary..."
            />
            {mnthlySalaryAlert?
            <p className="doc-validation-alert">Fill this field !!</p>
          :""}
          </div>
        </div>
      </div>
      <div className="company-info-salary-container">
        <h4>Contract period</h4>
        <div className="personal-info-input-container">
          <div className="global-single-input add-employee-input">
            <p>From</p>
            <input
              type="date"
              value={companyInfoForm.contractFrom}
              onChange={getCompanyInfoData("contractFrom")}
            />
          </div>
          <div className="global-single-input add-employee-input">
            <p>To</p>
            <input
              type="date"
              value={companyInfoForm.contractTo}
              onChange={getCompanyInfoData("contractTo")}
            />
          </div>
        </div>
      </div>
      <hr className="global-hr" />
      <div className="status-qr-container">
        <div className="company-info-status-container">
          <h5>Status:</h5>
          <div className="company-info-status-switch-container">
            <Switch
              disabled
              label="active"
              defaultChecked
              value={companyInfoForm.status}
              onChange={getCompanyInfoData("status")}
            />
            <p>Inactive&nbsp;/&nbsp;Active</p>
          </div>
        </div>
        <div className="qr-code-container" style={{position:"relative"}}>
          <button
            className="btn btn-primary btn-qr"
            onClick={generateEmployeeQr}
          >
            Generate QR Code
          </button>
          <div className="qr-image">
            {companyInfoForm.qrCode !== null && (
              <img src={companyInfoForm.qrCode} alt="employee qr" />
            )}
          </div>
          {generateQrAlert?
            <p className="doc-validation-alert" style={{left:"2%",textAlign:"start",top:"65%",fontWeight:"600"}}>Select a value !!</p>
          :""}
        </div>
      </div>

      <div className="add-employee-button-container">
        <button className="btn btn-primary-outlined" onClick={clickBackButton}>
          Back
        </button>
        <button className="btn btn-primary" onClick={clickNextButton}>
          Next
        </button>
      </div>
    </div>
  );
};
