import { createSlice } from "@reduxjs/toolkit";

export const branchOrDivisionSlice = createSlice({
  name: "branchOrDivisionSlice",
  initialState: {
    openingBalance:'',
  },
  reducers: {
    setOpeningBalance: (state, action) => {
      state.openingBalance = action.payload;
    },
  },
});

export const {setOpeningBalance} = branchOrDivisionSlice.actions;
export default branchOrDivisionSlice.reducer;
