import React, { useState,useEffect } from "react";
import { editCompanyProfileInfoAPICall,viewAllUpiProvider,addUpiProviderAPICall } from "../../../../API/Settings/Company Settings/companySettingsAPI";
import "../../../../css/Settings/CompanySettings/accountSettings.css";
import { FilePicker } from "../../../Single Components/filePicker";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import {useSelector} from 'react-redux'
import {Select,MenuItem} from '@mui/material'
export const AccountSettings = (props) => {
  const { profileData, updateListener } = props;
  //  view all upi provider api call
  const upiProvider=useSelector((state)=>state.upiProviderSlice.value)

 
  const [isModify,setIsModify]=useState(false)

  //Profile Account Form states
  const profileAccountFormInitialState = {
    accHolderName: profileData?.bank?.accountHolderName,
    accType: profileData?.bank?.accountType,
    accNo: profileData?.bank?.accountNumber,
    reAccNo: "",
    bankCode: profileData?.bank?.ifscCode,
  };
  const [profileAccountForm, setProfileAccountForm] = useState(
    profileAccountFormInitialState
  );

  // upi provider form states
  const upiProviderForm={
    upiName:'',
    upiId:''
    }

  const [addUpiProviderForm,setAddUpiProviderForm]=useState(upiProviderForm)
  const [qrCodeImage, setQrCodeImage] = useState(null);
  //Snackbar states
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false);
  const [openErrorSnack, setOpenErrorSnack] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!");
  console.log(profileData);

  useEffect(()=>{
    viewAllUpiProvider()
  },[isModify])

  //Close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setOpenSuccessSnack(false);
  };
  //Close errorSnackbar
  const closeErrorSnackbar = () => {
    setOpenErrorSnack(false);
  };

  //*onChange Fns
  const getProfileAccountFormData = (key) => (e) => {
    const { value } = e.target;

    if (key === "accHolderName") {
      setProfileAccountForm({ ...profileAccountForm, accHolderName: value });
    }
    if (key === "accType") {
      setProfileAccountForm({ ...profileAccountForm, accType: value });
    }
    if (key === "accNo") {
      setProfileAccountForm({ ...profileAccountForm, accNo: value });
    }
    if (key === "reAccNo") {
      setProfileAccountForm({ ...profileAccountForm, reAccNo: value });
    }
    if (key === "bankCode") {
      setProfileAccountForm({ ...profileAccountForm, bankCode: value });
    }
  };

  //form data body
  const accountSettingsFormData = new FormData();
  accountSettingsFormData.append(
    "accountHolderName",
    profileAccountForm.accHolderName
  );
  accountSettingsFormData.append("accountType", profileAccountForm.accType);
  accountSettingsFormData.append("accountNumber", profileAccountForm.accNo);
  accountSettingsFormData.append("ifscCode", profileAccountForm.bankCode);
  //Edit Account Settings
  const editAccountSettings = () => {
    editCompanyProfileInfoAPICall(
      accountSettingsFormData,
      setOpenSuccessSnack,
      setOpenErrorSnack,
      setSnackMsg,
      updateListener
    );
  };

  //add upi form onChange function
  const getUpiName=(e)=>{
    setAddUpiProviderForm({
      ...addUpiProviderForm,upiName:e.target.value
    })
  }

  const getUpiId=(e)=>{
     setAddUpiProviderForm({
      ...addUpiProviderForm,upiId:e.target.value
     })
  }
  const formData=new FormData();
   formData.append("upiName",addUpiProviderForm.upiName)
   formData.append("upiId",addUpiProviderForm.upiId)
   formData.append("qrcode",qrCodeImage)

  // add upi provider
  const addUpiProviderFn=()=>{
    addUpiProviderAPICall(
      formData,
      setIsModify,
      isModify,
      clearState,
      setOpenSuccessSnack,
      setOpenErrorSnack,
      setSnackMsg
      )
  }

  const clearState=()=>{
    setAddUpiProviderForm(upiProviderForm) 
    setQrCodeImage(null)
  }
  return (
    <>
      <div className="account-settings-container">
        <h4>Bank Account Details</h4>
        <div className="account-settings-input-container">
          <div className="profile-input-container">
            <p>Account Holder Name</p>
            <input
              placeholder="Enter account holder name..."
              value={profileAccountForm.accHolderName}
              onChange={getProfileAccountFormData("accHolderName")}
            />
          </div>
          <div className="profile-input-container">
            <p>Account Type</p>
            <input
              placeholder="Enter account type..."
              value={profileAccountForm.accType}
              onChange={getProfileAccountFormData("accType")}
            />
          </div>
          <div className="profile-input-container">
            <p>Account Number</p>
            <input
              placeholder="Enter account number..."
              value={profileAccountForm.accNo}
              onChange={getProfileAccountFormData("accNo")}
            />
          </div>
          <div className="profile-input-container">
            <p>Re-Enter Account Number</p>
            <input
              placeholder="Re enter account number..."
              value={profileAccountForm.reAccNo}
              onChange={getProfileAccountFormData("reAccNo")}
            />
          </div>
          {/* <div className='account-settings-account-info'>
        <p>Do You Have IFS Code?</p>
        <div className='account-settings-account-info-radio-container'>
          <div className='account-info-radio-yes'>
          <input defaultChecked type="radio" id="yes" value="yes" name="ifsc"/>
          <label htmlFor='yes'>Yes</label>
          </div>
          <div className='account-info-radio-yes'>
          <input type="radio" id="no" value="no" name="ifsc"/>
          <label htmlFor='no'>No</label>
          </div>
        </div>
      </div> */}
          <div className="profile-input-container account-settings-ifsc-container">
            <p>IFSC&nbsp;/&nbsp;IBAN Code</p>
            <input
              placeholder="Enter IFSC / IBAN..."
              value={profileAccountForm.bankCode}
              onChange={getProfileAccountFormData("bankCode")}
            />
            {/* <button className='btn btn-primary'> Verify IFSC </button> */}
          </div>
        </div>
      </div>

      <div className="account-settings-container">
        <h4>UPI Details</h4>
        <div className="account-settings-upi-details-container">
          <div className="account-settings-upi-details-input-container">
            <div className="profile-input-container upi-details-input">
              <p>UPI Name</p>
              <Select
                value={addUpiProviderForm.upiName}
                onChange={getUpiName}
                sx={{
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid black",
                  },
                }}
              >
              <MenuItem value="google pay">GooglePay</MenuItem>
              <MenuItem value="phonepe">PhonePe</MenuItem>
              <MenuItem value="paytm">Paytm</MenuItem>
              </Select>
            </div>
            <div className="profile-input-container upi-details-input">
              <p>UPI ID</p>
              <input 
                value={addUpiProviderForm.upiId}
                onChange={getUpiId}
              />
            </div>
          </div>
          <div className="qr-code-upload-container">
            <p>QR Code Upload</p>
            <FilePicker
              id="qrcodeImage"
              uploadImageUrl={qrCodeImage}
              setImage={setQrCodeImage}
              onChange={(e) => setQrCodeImage(e.target.files[0])}
            />
          </div>
        </div>
        <div className="account-settings-upi-details-update-btn">
          <button onClick={addUpiProviderFn} className="btn btn-primary">Update</button>
        </div>
         <div className="global-table-container" style={{maxHeight:"51vh"}}>
           <table className="global-table">
             <thead>
                <tr>
                   <th>Upi Name</th>
                   <th>Upi ID</th>
                   <th>Qr Code</th>
                </tr>
             </thead>
             <tbody>
              {
                upiProvider?.map((r,i)=>(
                  <tr>
                  <td>{r.upiName}</td>
                  <td>{r.upiId}</td>
                  <td><img src={r.qrcode}
                   style={{ height: 50, width: 50, objectFit: "fill",marginTop:"8%" }}
                  /></td>
                </tr>
                ))
              }
            
             </tbody>
           </table>
         </div>
       
      </div>
      <button
        className="btn btn-primary profile-info-save-btn"
        onClick={editAccountSettings}
      >
        Save
      </button>
      {/* Snackbars  */}
      <SuccessSnackbar
        open={openSuccessSnack}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={openErrorSnack}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </>
  );
};
