import { createSlice } from "@reduxjs/toolkit";

export const allDepartmentSlice=createSlice({
    name:'allDepartmentSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_all_department_list:(state,action)=>{
            state.value=action.payload.allDepartments
        }
    }
})

export const {update_all_department_list}=allDepartmentSlice.actions
export default allDepartmentSlice.reducer