import axios from 'axios'
import {get_card_manual} from '../../../Redux/Settings/CardAndUpiType/cardManualSlice'
import store from '../../../Redux/store'
import { HEADERS,HEADER_FORM_DATA } from '../../UrlAndPaths'

// view card manual data api call
export const viewCardManualAPICall=async()=>{
    await axios.post("settings/viewcard",{},HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_card_manual({cardManualData:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(get_card_manual({cardManualData:undefined}))
        console.log(err.response);
    })
}

// add card manual 
export const addCardManualAPICall=async(
    formData,
    updateListener,
    setSuccessSnackbar,
    setErrorSnackbar,
    setSnackMsg,
    clearState
    )=>{
    await axios.post("settings/addcard",formData,HEADER_FORM_DATA)
    .then((res)=>{
        if(res.status===200){
            setSuccessSnackbar(true)
            setSnackMsg('Success')
            updateListener()
            clearState()
        }
    })
    .catch((err)=>{
        setErrorSnackbar(true)
        if (err.response === undefined) {
            setSnackMsg("Network Connection error!!");
         }
          if (err.response.status === 409) {
            setSnackMsg("Already Exist");
          }
      
          if (err.response.status === 401) {
            setSnackMsg("Unauthorized");
          }
          if (err.response.status === 403) {
            setSnackMsg("Forbidden");
          }
        console.log(err.response);
    })
}

// edit card manual
export const editCardManualAPICall= async (
    formData,
    updateListener,
    setSuccessSnackbar,
    setErrorSnackbar,
    setSnackMsg,
    clearState
    )=>{
  await axios.put("settings/editcard",formData,HEADER_FORM_DATA)
    .then((res)=>{
        if(res.status===200){
            setSuccessSnackbar(true)
            setSnackMsg('Success')
            updateListener()
            clearState()
        }
    })
    .catch((err)=>{
        setErrorSnackbar(true)
        if (err.response === undefined) {
            setSnackMsg("Network Connection error!!");
         }
          if (err.response.status === 409) {
            setSnackMsg("Already Exist");
          }
      
          if (err.response.status === 401) {
            setSnackMsg("Unauthorized");
          }
          if (err.response.status === 403) {
            setSnackMsg("Forbidden");
          }
        console.log(err.response);
    })
  
}