import { Dialog,Select,IconButton,MenuItem, Skeleton } from '@mui/material'
import React,{useState} from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { today,currentTime, getCurrentTime } from '../../../../../../Js/Date'
import { viewAllBranchesAPICall } from '../../../../../../API/Settings/Company Settings/companySettingsAPI'
import { applyForSingleLoanAPI, viewAllEmployeeLoan, viewEmployeeSingleLoan } from '../../../../../../API/Staff/HRM/employeeLoanAPI'

import '../../../../../../css/Staff/HRM/Tab/empLoanDetails.css'
import SuccessSnackbar from '../../../../../Single Components/SnackBars/SuccessSnackbar'
import ErrorSnackbar from '../../../../../Single Components/SnackBars/ErrorSnackbar'

export const EmpLoanDetails = (props) => {

   //single employee details 
  const {singleEmpData}=props
  const currentEmpID = localStorage.getItem("EMP_ID");

  // useSelector
  // login response
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  // role based rendering
  const userRole = useSelector((state) => state.userRoleSlice.value);

   //States   
  const [applyLoanDialog,setApplyLoanDialog]=useState(false)
  const [empLoanDetailsView,setEmpLoanDetailsView]=useState(false)
  const [isModify,setIsModify]=useState(false);

  const [openErrorSnack, setOpenErrorSnack] = useState(false);
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false);
  const [filteredBranchName, setFilteredBranchName] = useState([]);
  const [filteredLoanId, setFilteredLoanId] = useState([]);
  const [singleLoanView, setSingleLoanView] = useState([]);
  const [snackMsg, setSnackMsg] = useState("oops!!!");

  //   validation states
  const [showChequeAlert,setShowChequeAlert]=useState(false);
  const [showActLoanAlert,setShowActLoanAlert]=useState(false);
  const [showMnthToPayAlert,setShowMnthToPayAlert]=useState(false);
  const [showGuarentorAlert,setShowGuarentorAlert]=useState(false);
  //Loading State
  const [isLoading, setIsLoading] = useState(true);
   // open dialog call
  const openSingleViewDialog=()=>{
   setEmpLoanDetailsView(true);
   }
  const OpenApplyLoan=()=>{
   setApplyLoanDialog(true)
   filterBranchNameById();
   }

// extracting loan id from viewloans
   const viewSingleAppliedLoan=(id)=>{
   if(filteredLoanId !== undefined){
      const filteredSingleView=filteredLoanId?.filter((obj)=>{
         if(obj?._id === id){
            return obj
         }
      })
      setSingleLoanView(filteredSingleView)
   }
  }
  //   updateListener

   const updateListener=()=>{
   setIsModify(!isModify)
  }
   const closeSuccessSnack = () => {

      setOpenSuccessSnack(false);
   };
   const closeErrorSnack = () => {
      setOpenErrorSnack(false);
   };

// employee Loan List
   const empAllLoanList =useSelector(
      (state)=>state.allEmployeeLoanSlice.value
   )
// All Branches List
    const allBranchList =useSelector(
      (state)=>state.allBranchesSlice.value
    )


    const dateAndTime=`${today} ${getCurrentTime()}`;

    console.log(singleEmpData);
// apply loan initial body  
   const empLoanInfoFormInitialState ={
      staff_name:singleEmpData?.staff_name,
      emp_id:singleEmpData?.emp_id,
      contactnumber:singleEmpData?.contactnumber,
      departmentName:singleEmpData?.departmentName,
      designationName:singleEmpData?.designationName,
      branchId:singleEmpData?.branchId,
      bankName:singleEmpData?.bank,
      ac_holder_name:singleEmpData?.ac_holder,
      ac_no:singleEmpData?.ac_no,
      bank_code:singleEmpData?.bank_code,
      bankLocation:singleEmpData?.bankLocation,
      balanceAmt:singleEmpData?.balanceAmount,


      chequeNo:"",
      actLoanAmt:"",
      monthToPay:"",
      
      guarenteeId:"",
      postingDate:"",
      loanReqDate:dateAndTime,
   }   
   const [empLoanInfoForm,setEmpLoanInfoForm]=useState(empLoanInfoFormInitialState)

// apply loan payloadBody 
   const newAppliedLoanBody ={
      empId:currentEmpID,
      branchId:filteredBranchName.length!==0 &&filteredBranchName[0].storeCode,
      // chequeNo:empLoanInfoForm.chequeNo,
      actualLoanAmount:empLoanInfoForm.actLoanAmt,
      // monthlyPay:empLoanInfoForm.monthToPay,
      // guarantes:empLoanInfoForm.guarenteeId,
      loanRequestedDate:empLoanInfoForm.loanReqDate,
      // balanceAmount:empLoanInfoForm.balanceAmt,
      balanceAmount:0,
      // loanStatus:false,
      // bank:empLoanInfoForm.bankName,
      // postingDate:empLoanInfoForm.loanReqDate

   }

// onChange of empLoanInfoform 
   const getAddLoanDeptForm =(key) =>(e,newValue) =>{
   
      const {value}=e.target
      if(key ==="cheque"){
         setShowChequeAlert(false)
         setEmpLoanInfoForm({...empLoanInfoForm,chequeNo:value})
         if(value===""){
            setShowChequeAlert(true)
         }

      }
      if(key ==="actLoanAmt"){
         setShowActLoanAlert(false)
         setEmpLoanInfoForm({...empLoanInfoForm,actLoanAmt:value})
         if(value===""){
            setShowActLoanAlert(true)
         }
      }
      if(key ==="MntToPay"){
         setShowMnthToPayAlert(false)
         setEmpLoanInfoForm({...empLoanInfoForm,monthToPay:value})
         if(value===""){
            setShowMnthToPayAlert(true)
         }
      }
      // if(key ==="balanceAmt"){
      //    setShowBalanceAmtAlert(false)
      //    setEmpLoanInfoForm({...empLoanInfoForm,balanceAmt:value})
      //    if(value===""){
      //       setShowBalanceAmtAlert(true)
      //    }
      // }

      if(key ==="guarntId"){
         setShowGuarentorAlert(false)
         setEmpLoanInfoForm({...empLoanInfoForm,guarenteeId:value})
         if(value===""){
            setShowGuarentorAlert(true)
         }
      }
   }
   
// extracting branchName from branchId
   
   const filterBranchNameById =()=>{
      
      let filteredBranchName=allBranchList?.filter(
         (obj,i)=>obj?.storeCode === empLoanInfoForm.branchId
      );
      setFilteredBranchName(filteredBranchName);
   }
   



// Clear states
   const clearStates= ()=>{
      setEmpLoanInfoForm(empLoanInfoFormInitialState);
      setApplyLoanDialog(false)
      setShowChequeAlert(false)
      setShowActLoanAlert(false)
      setShowMnthToPayAlert(false)
      setShowGuarentorAlert(false)
   }

// empLoanInfoForm Submit Click 
   const clickApplyNewLoan =()=>{
      const{chequeNo,actLoanAmt,monthToPay,guarenteeId}=empLoanInfoForm;

         // if(chequeNo===""){
         //    setShowChequeAlert(true)
         // }
         // else if(actLoanAmt===""){
         //    setShowActLoanAlert(true)
         // }
         // else if(monthToPay===""){
         //    setShowMnthToPayAlert(true)
         // }
         // else if(guarenteeId===""){
         //    setShowGuarentorAlert(true)
         // }

         // else{
         
         applyForSingleLoanAPI(
            newAppliedLoanBody,
            updateListener,
            setOpenErrorSnack,
            setOpenSuccessSnack,
            setSnackMsg,
            clearStates
         )
         // }
      }
      

// View Branches API call
   useEffect(() => {
      viewAllBranchesAPICall();
      viewAllEmployeeLoan(
         setIsLoading,
         setSnackMsg
      );
    },[isModify]);

// extracting loan id from _id
    useEffect(()=>{
      let filteredLoanId=empAllLoanList?.filter(
         (obj)=>{
            if(obj.empId ===  currentEmpID){
               return obj;
            }
         }
      )
      setFilteredLoanId(filteredLoanId)
    },[empAllLoanList])


   //  console.log(singleEmpData);

return (
   <div className='employee-personal-info-main-container'>
     
     <div className="department-container">
        <div className="employee-loan-top-container">
      
          <button
            className="emp-apply-loan-btn"
            onClick={OpenApplyLoan}
          >
            Apply For Loan
          </button>
        </div>
        <div className="global-table-container">
          <table className="global-table">
            <thead>
              <tr>
                <th>Loan ID</th>
                <th>Loan Amount</th>
                <th>Interest Rate</th>
                <th>Tenure</th>

                {/* <th>Actual Loan Amount</th> */}
                <th>Status</th>
                <th>View</th>
              </tr>
            </thead>
            {
            isLoading?
            <tbody>
            <tr>
              <td colSpan={6}>
                {/* <Loader /> */}
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width={"100%"}
                  height={40}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={6}>
                {/* <Loader /> */}
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width={"100%"}
                  height={40}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={6}>
                {/* <Loader /> */}
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width={"100%"}
                  height={40}
                />
              </td>
            </tr>
            
          </tbody>
               :

            <tbody>
               {filteredLoanId.length!==0 ? (
                  filteredLoanId
                  ?.map((r,i)=>(
                     <tr key={i}>
                        <td>{r?.loanId}</td>
                        <td>{r?.actualLoanAmount}</td>
                        <td>{r?.interestAmount}</td>
                        <td>{r?.tenur}</td>
                        {/* <td>{r?.loanAmount}</td> */}
                        <td>{r?.loanStatus==="Approved"?(
                           <p className="status-text-stock-out-pending">Accepted</p>)
                           :r?.loanStatus==="Rejected"?(
                              <p className="status-text-stock-out-reject">Rejected</p>)
                              :(
                                 <p className="status-text-stock-out">Pending</p>)
                           }
                        </td>
                        <td>
                           <IconButton onClick={openSingleViewDialog}
                                       onMouseDown={()=>viewSingleAppliedLoan(r?._id)}>
                              <i class="bi bi-eye visibility-icon"></i>
                           </IconButton>
                        </td>
                     </tr>
                   ))
                   ) :
                   (
                      <tr>
                        <td colSpan={6}>No Records </td>
                     </tr>
                   )}
                  
               
            </tbody>
         }
          </table>
        </div>
      </div>

        {/* apply for loan Dialog */}
        <Dialog
        open={applyLoanDialog} maxWidth="lg" onClose={()=>setApplyLoanDialog(false)} onKeyDown={(e)=>e.key === "Escape" && setApplyLoanDialog(false)}>
            <div className='dashboard-employee-loan-apply-loan'>
              <h4>Employee Details</h4>
              <div className='dashboard-employee-loan-apply-loan-input-container'>
                <div className='global-single-input apply-loan-input'>
                   <p>Employee Name</p>
                   <input type="text"
                     value={empLoanInfoForm.staff_name}
                     disabled />
                </div>
                <div className='global-single-input apply-loan-input'>
                   <p>Employee ID</p>
                   <input type="text"
                    value={empLoanInfoForm.emp_id}
                    disabled />
                </div>
                <div className='global-single-input apply-loan-input'>
                   <p>Contact</p>
                   <input type="text"
                    value={empLoanInfoForm.contactnumber}
                    disabled/>
                </div>
                <div className='global-single-input apply-loan-input'>
                   <p>Department</p>
                   <Select value="1" disabled>
                     <MenuItem value="1">{empLoanInfoForm.departmentName}</MenuItem>
                   </Select>
                </div>
               
                <div className='global-single-input apply-loan-input'>
                   <p>Designation</p>
                   <Select value="1" disabled>
                     <MenuItem value="1">{empLoanInfoForm.designationName}</MenuItem>
                   </Select>
                </div>
                
                {filteredBranchName[0] === undefined?(<div className='global-single-input apply-loan-input'>
                   <p>Branch</p>
                   <input type="text" 

                   value="No Branches Available"

                   disabled
                   />
                </div>):(
                     <div className='global-single-input apply-loan-input'>
                     <p>Branch</p>
                     <input type="text" 
                     value={filteredBranchName[0].branchName}
                     disabled
                     />
                  </div>
                )}
                
                {/* <div className='global-single-input apply-loan-input'>
                   <p>Cheque No</p>
                   <input type="text" 
                   onChange={getAddLoanDeptForm("cheque")}/>
                   {showChequeAlert?
                   <p className='validation-alert-red'>Fill This Field !!</p>
                   :""}
                   
                </div> */}
              </div>
            </div>
            <div className='dashboard-employee-loan-apply-loan'>
              <h4>Loan Details</h4>
              <div className='dashboard-employee-loan-apply-loan-input-container'>
                <div className='global-single-input apply-loan-input'>
                   <p>Actual Loan Amount</p>
                   <input type="number"
                    onChange={getAddLoanDeptForm("actLoanAmt")}/>
                    {showActLoanAlert?
                   <p className='validation-alert-red'>Fill This Field !!</p>
                   :""}

                </div>
                
                {/* <div className='global-single-input apply-loan-input'>
                   <p>Per Month To Pay</p>
                   <input type="number"
                   onChange={getAddLoanDeptForm("MntToPay")} />
                   {showMnthToPayAlert?
                   <p className='validation-alert-red'>Fill This Field !!</p>
                   :""}
                </div> */}

               
                <div className='global-single-input apply-loan-input'>
                   <p>Balance Amount</p>
                   <input type="number"
                   value={empLoanInfoForm.balanceAmt}
                   disabled />
                </div>
                
                {/* <div className='global-single-input apply-loan-input'>
                   <p>Guarentor's ID</p>
                   <input type="text"
                   onChange={getAddLoanDeptForm("guarntId")} />
                   {showGuarentorAlert?
                   <p className='validation-alert-red'>Fill This Field !!</p>
                   :""}
                </div> */}
                
              </div>
            </div>
            <div className='dashboard-employee-loan-apply-loan'>
              <h4>Account Information</h4>
              <div className='dashboard-employee-loan-apply-loan-input-container'>
                <div className='global-single-input apply-loan-input'>
                   <p>Bank Name</p>
                   <input type="text" 
                   value={empLoanInfoForm.bankName}
                   disabled/>
                </div>
                <div className='global-single-input apply-loan-input'>
                   <p>Account Holder Name</p>
                   <input type="text" 
                   value={empLoanInfoForm.ac_holder_name}
                   disabled/>
                </div>
                <div className='global-single-input apply-loan-input'>
                   <p>Account No</p>
                   <input type="text" 
                   value={empLoanInfoForm.ac_no}
                   disabled/>
                </div>
                <div className='global-single-input apply-loan-input'>
                   <p>IFSC</p>
                   <input type="text" 
                   value={empLoanInfoForm.bank_code}
                   disabled/>
                </div>
                
                <div className='global-single-input apply-loan-input'>
                   <p>Branch</p>
                   <input type="text"
                   value={empLoanInfoForm.bankLocation}
                   disabled />
                </div>
                
                
              </div>
            </div>
            <div className='dashboard-employee-loan-apply-loan'>
              <h4>Dates</h4>
              <div className='dashboard-employee-loan-apply-loan-input-container'>
                <div className='global-single-input apply-loan-input'>
                   <p>Loan Requested Date</p>
                   <input type="date" 
                   value={today} disabled/>
                </div>
              </div>
            </div>
            <div className='dashboard-employee-loan-apply-loan-btn-container'>
              <button onClick={clearStates} className='btn btn-secondary-outlined'>Cancel</button>
              <button id="applyNewLoanSubmitBtn" className='btn btn-primary' onClick={clickApplyNewLoan}>Submit</button>
            </div>
        </Dialog>

        {/* employee loan details view */}
        <Dialog open={empLoanDetailsView} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setEmpLoanDetailsView(false )}>
        <h3  style={{margin:"4% 0 0 4%"}}>Loan ID :{singleLoanView[0]?.loanId}</h3>
            <div className='dashboard-employee-loan-apply-loan'>
              <h4>Loan Details</h4>
              <div className='dashboard-employee-loan-apply-loan-input-container'>
                <div className='global-single-input apply-loan-input'>
                   <p>Employee Name</p>
                   <input type="text"
                     value={empLoanInfoForm.staff_name}
                     disabled />
                </div>
                <div className='global-single-input apply-loan-input'>
                   <p>Employee ID</p>
                   <input type="text"
                    value={empLoanInfoForm.emp_id}
                    disabled />
                </div>
                <div className='global-single-input apply-loan-input'>
                   <p>Contact</p>
                   <input type="text"
                    value={empLoanInfoForm.contactnumber}
                    disabled/>
                </div>
                <div className='global-single-input apply-loan-input'>
                   <p>Department</p>
                   <Select value="1" disabled>
                     <MenuItem value="1">{empLoanInfoForm.departmentName}</MenuItem>
                   </Select>
                </div>
               
                <div className='global-single-input apply-loan-input'>
                   <p>Designation</p>
                   <Select value="1" disabled>
                     <MenuItem value="1">{empLoanInfoForm.designationName}</MenuItem>
                   </Select>

                </div>
                
                {filteredBranchName[0] === undefined?(<div className='global-single-input apply-loan-input'>
                   <p>Branch</p>
                   <input type="text" 
                   value={empLoanInfoForm.designationName}
                   disabled
                   />
                </div>):(
                     <div className='global-single-input apply-loan-input'>
                     <p>Branch</p>
                     <input type="text" 
                     value={filteredBranchName[0].branchName}
                     disabled
                     />
                  </div>
                )}
                
                {/* <div className='global-single-input apply-loan-input'>
                   <p>Cheque No</p>
                   <input type="text" 
                     disabled
                     value={singleLoanView[0]?.chequeNo}
                  />
                </div> */}
              </div>
            </div>
            <div className='dashboard-employee-loan-apply-loan'>
              <h4>Loan Details</h4>
              <div className='dashboard-employee-loan-apply-loan-input-container'>
                <div className='global-single-input apply-loan-input'>
                   <p>Loan Amount</p>
                   <input type="number"
                    disabled
                    value={singleLoanView[0]?.actualLoanAmount}/>

                </div>
                
                
                
                {/* <div className='global-single-input apply-loan-input'>

                   <p>Per Month To Pay</p>
                   <input type="number"
                   disabled
                   value={singleLoanView[0]?.monthlyPay}/>
                </div> */}

                
               
                <div className='global-single-input apply-loan-input'>
                   <p>Balance Amount</p>
                   <input type="number"

                   disabled
                   value={singleLoanView[0]?.balanceAmount}/>

                </div>
                
                {/* <div className='global-single-input apply-loan-input'>
                   <p>Guarentor's ID</p>
                   <input type="text"

                   disabled 
                   value={singleLoanView[0]?.guarantes}/>

                </div> */}
                
                
              </div>
            </div>
            <div className='dashboard-employee-loan-apply-loan'>
              <h4>Account Information</h4>
              <div className='dashboard-employee-loan-apply-loan-input-container'>
                <div className='global-single-input apply-loan-input'>
                   <p>Bank Name</p>
                   <input type="text" 
                   value={empLoanInfoForm.bankName}
                   disabled/>
                </div>
                <div className='global-single-input apply-loan-input'>
                   <p>Account Holder Name</p>
                   <input type="text" 
                   value={empLoanInfoForm.ac_holder_name}
                   disabled/>
                </div>
                <div className='global-single-input apply-loan-input'>
                   <p>Account No</p>
                   <input type="text" 
                   value={empLoanInfoForm.ac_no}
                   disabled/>
                </div>
                <div className='global-single-input apply-loan-input'>
                   <p>IFSC</p>
                   <input type="text" 
                   value={empLoanInfoForm.bank_code}
                   disabled/>
                </div>
                
                <div className='global-single-input apply-loan-input'>
                   <p>Branch</p>
                   <input type="text"
                   value={empLoanInfoForm.bankLocation}
                   disabled />
                </div>
                
              </div>
            </div>
            <div className='dashboard-employee-loan-apply-loan'>
              <h4>Dates</h4>
              <div className='dashboard-employee-loan-apply-loan-input-container'>
                <div className='global-single-input apply-loan-input'>
                   <p>Loan Requested Date</p>
                   <input type="date" 
                   value={singleLoanView[0]?.loanRequestedDate!== undefined && singleLoanView[0]?.loanRequestedDate?.split(" ")[0]}
                   disabled/>
                </div>
              </div>
            </div>
            <div className='dashboard-employee-loan-apply-loan-btn-container'>
              <button onClick={()=>setEmpLoanDetailsView(false)} className='btn btn-secondary'>Close</button>
            </div>
        </Dialog>
        <SuccessSnackbar

        open={openSuccessSnack}

        handleClose={closeSuccessSnack}
        message={snackMsg}
      />
      <ErrorSnackbar

        open={openErrorSnack}

        handleClose={closeErrorSnack}
        message={snackMsg}
      />
   </div>


  )
}
