import React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material";
const StyledButton = styled(IconButton)({
  padding: "1em",
  height: "0.5em",
  width: "0.5em",
  color: "#0032fc",
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
});

const MuiEditButton = ({ editClicked, editSaveFn, editBtnEnableFn }) => {
  return (
    <Tooltip title={editClicked ? "Save" : "Edit"} arrow>
      <StyledButton onClick={editClicked ? editSaveFn : editBtnEnableFn}>
        {editClicked ? (
          <i class="bi bi-check2-square edit-icon"></i>
        ) : (
          <i class="bi bi-pencil-square edit-icon1"></i>
        )}
      </StyledButton>
    </Tooltip>
  );
};

export default MuiEditButton;
