import React, { useEffect, useState } from "react";
import recurringPostSideMenu from "../../../../../Assets/JSON/recurringPostSideMenu";
import "../../../../../css/Account/createInvoice/payroll.css";
import "../../../../../css/Account/createInvoice/recurringPost.css";
import { List, ListItemButton, ListItemText, Typography } from "@mui/material";
import AddRecurringPost from "./recurringPostGroup/AddRecurringPost";
import RecurringPostingList from "./recurringPostGroup/RecurringPostingList";
import ExecuteRecurringPost from "./recurringPostGroup/ExecuteRecurringPost";
import PendingRecurringPost from "./recurringPostGroup/PendingRecurringPost";

const RecurringPost = () => {
  const [active, setActive] = useState(0);
  const [selectListItem, setSelectListItem] = useState({ id: null, name: "" });
  const renderData = [
    {
      glAccountCode: 1000,
      glAccount: "rent",
      debit: 5000,
      credit: 2000,
      debitBalance: 3000,
      creditBalance: 800,
    },
    {
      glAccountCode: 8000,
      glAccount: "sbi",
      debit: 7000,
      credit: 9000,
      debitBalance: 3000,
      creditBalance: 1100,
    },
    {
      glAccountCode: 7000,
      glAccount: "cash",
      debit: 3000,
      credit: 2000,
      debitBalance: 3000,
      creditBalance: 2000,
    },
  ];
  const headings = [
    "No",
    "G/L Account Code",
    "G/L Account",
    "Debit",
    "Credit",
    "Debit Balance",
    "Credit Balance",
  ];
  useEffect(() => {
    handleListItemClick(0, 1, " Add Recurring Post");
  }, []);

  const handleListItemClick = (index, id, name) => {
    setActive(index);
    setSelectListItem({ id, name });
  };

  const listStyle = {
    width: "100%",
    bgcolor: "background.paper",
    marginBlockStart: "10px",
  };

  const listItemTextStyle = () => {
    return {
      fontWeight: "600",
      fontSize: "0.9rem",
    };
  };

  return (
    <>
      <div className="global-page-parent-container recurring-post">
        <p className="breadcrumb">
          Accounts &gt; Create Invoice &gt; Recurring Posting &gt;{" "}
          {selectListItem.name}
        </p>
        <div className="accounting-container">
          <div className="accounting-side-menu">
            <List
              sx={{ ...listStyle }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              {recurringPostSideMenu.map(({ id, name }, index) => (
                <>
                  <ListItemButton
                    key={index}
                    sx={{ paddingLeft: "2em" }}
                    className={active === index ? "isactive" : ""}
                    onClick={() => handleListItemClick(index, id, name)}
                  >
                    <ListItemText
                      primary={
                        <Typography sx={{ ...listItemTextStyle() }}>
                          {name}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </>
              ))}
            </List>
          </div>
          <div className="accounting-voucher-menu">
            {selectListItem.id === 1 && (
              <AddRecurringPost headings={headings} renderData={renderData} />
            )}
            {selectListItem.id === 2 && (
              <RecurringPostingList
                headings={headings}
                renderData={renderData}
              />
            )}
            {selectListItem.id === 3 && (
              <ExecuteRecurringPost
                headings={headings}
                renderData={renderData}
              />
            )}
            {selectListItem.id === 4 && (
              <PendingRecurringPost
                headings={headings}
                renderData={renderData}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RecurringPost;
