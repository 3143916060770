import React from "react";
import InputBox from "../InputBox";
import DropdownInputBox from "../DropdownInputBox";
import SubmitBtn from "../SubmitBtn";
import { useDispatch, useSelector } from "react-redux";
import {
  setBankAccountDetails,
  setBankAccountNo,
  setBankName,
  setBranchName,
  setGstApplicable,
  setGstRate,
  setGstTaxType,
  setIfscCode,
  setNatureOfGoods,
  setNatureOfTransaction,
  setOpeningBalance,
  setRate,
  setTaxType,
  setTypeOfSupply,
} from "../../../../../Redux/Account/currentAssetSlice";
import natureOfTransactionOption from "../../../../../Assets/JSON/ledgerNatureOfTransactionOption.json";
import natureOfGoodsOption from "../../../../../Assets/JSON/ledgerNatureOfGoodsOptions.json";
import taxTypeOption from "../../../../../Assets/JSON/ledgerTaxTypeOption.json";
import typeOfSupplyOption from "../../../../../Assets/JSON/ledgerTypeOfSupply.json";
const CurrentAsset = () => {
    const gstApplicableOption = [{ option: "yes" }, { option: "No" }];
    const dispatch = useDispatch();
    const bankAccountDetailsOption = [{ option: "Yes" }, { option: "No" }];
    const {
      openingBalance,
      bankAccountDetails,
      bankName,
      bankAccountNo,
      ifscCode,
      branchName,
      gstApplicable,
      natureOfTransaction,
      natureOfGoods,
      gstTaxType,
      gstRate,
      taxType,
      rate,
      typeOfSupply,
    } = useSelector((state) => state.currentAssetSlice);
    const directExpenses = useSelector((state) => state.currentAssetSlice);
    const handleOpeningBalanceSubmit = () => {
      console.log(directExpenses);
    };
  
  return (
    <>
    <div className="ledger-section-container-input-bank  ">
      <div style={{ padding: "2% 4%" }} className="bottom-line">
        {/*Opening Balance*/}
        <InputBox
          label={"Opening Balance"}
          value={openingBalance}
          onChange={(e) => dispatch(setOpeningBalance(e.target.value))}
          containerStyle={{width:'31%'}}

        />
      </div>
      <div
        style={{ padding: "2% 4%" }}
        className="ledger-section-container-input-bank bottom-line"
      >
        <div>
          {/*bank account details */}

          <DropdownInputBox
            labelName={"bank account details"}
            value={bankAccountDetails}
            options={bankAccountDetailsOption}
            onChange={(e, value) => dispatch(setBankAccountDetails(value))}
          />
          {/*bank Name*/}
          <InputBox
            label={"Bank Name"}
            value={bankName}
            onChange={(e) => dispatch(setBankName(e.target.value))}
          />
          {/*bank account number  */}
          <InputBox
            label={"Bank account number"}
            value={bankAccountNo}
            onChange={(e) => dispatch(setBankAccountNo(e.target.value))}
          />
        </div>
        <div style={{ justifyContent: "flex-start"}}>
          {/*IFSC Code  */}
          <InputBox
            label={"IFSC Code"}
            value={ifscCode}
            onChange={(e) => dispatch(setIfscCode(e.target.value))}
            containerStyle={{width:'31%'}}

          />

          {/*branch name */}
          <InputBox
            label={"Branch Name"}
            value={branchName}
            onChange={(e) => dispatch(setBranchName(e.target.value))}
            containerStyle={{width:'31%'}}

          />
        </div>
      </div>
      <div
        style={{ padding: "2% 4%" }}
        className="ledgerSection-input-container"
      >
        <div>
          {/* gst applicable */}
          <DropdownInputBox
            options={gstApplicableOption}
            value={gstApplicable}
            labelName={"GST Applicable"}
            onChange={(e, value) => dispatch(setGstApplicable(value))}
          />

          {/* Nature of Transaction */}

          <DropdownInputBox
            options={natureOfTransactionOption}
            value={natureOfTransaction}
            labelName={"Nature of Transaction"}
            onChange={(e, value) => dispatch(setNatureOfTransaction(value))}
          />
          {/* Nature of Goods */}

          <DropdownInputBox
            options={natureOfGoodsOption}
            value={natureOfGoods}
            labelName={"Nature of Goods"}
            onChange={(e, value) => dispatch(setNatureOfGoods(value))}
          />
        </div>

        <div>
          <div className="tax-rate-input-section">
            {/* gst Tax Type */}
            <div style={{ width: "15em" }}>
              <DropdownInputBox
                options={taxTypeOption}
                value={gstTaxType}
                labelName={"GST Tax Type"}
                onChange={(e, value) => dispatch(setGstTaxType(value))}
                style={{ width: "13.5em" }}
              />
            </div>
            {/*gst  Rate */}
            <div
              className="global-single-input add-purchase-input auto-complete"
              style={{ width: "5em" }}
            >
              <p>GST Rate(%)</p>
              <input
                type="number"
                value={gstRate}
                onChange={(e) => dispatch(setGstRate(e.target.value))}
              />
            </div>
          </div>
          <div className="tax-rate-input-section">
            {/*  Tax Type */}
            <div style={{ width: "15em" }}>
              <DropdownInputBox
                options={taxTypeOption}
                value={taxType}
                labelName={"Tax Type"}
                onChange={(e, value) => dispatch(setTaxType(value))}
                style={{ width: "14em" }}
              />
            </div>

            {/* Rate */}
            <div
              className="global-single-input add-purchase-input auto-complete"
              style={{ width: "5em" }}
            >
              <p>Rate(%)</p>
              <input
                type="number"
                value={rate}
                onChange={(e) => dispatch(setRate(e.target.value))}
              />
            </div>
          </div>
        </div>

        <div>
          {/* Type of Supply */}
          <DropdownInputBox
            options={typeOfSupplyOption}
            value={typeOfSupply}
            labelName={"Type of Supply"}
            onChange={(e, value) => dispatch(setTypeOfSupply(value))}
            containerStyle={{width:'31%'}}

          />
        </div>
      </div>

      {/* submitButton */}
    </div>
    <SubmitBtn onClick={handleOpeningBalanceSubmit} />
  </>
  )
}

export default CurrentAsset