import { createSlice } from "@reduxjs/toolkit";

export const viewRolesAndPermissionSlice=createSlice({
    name:'viewRolesAndPermissionSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_roles_and_permission:(state,action)=>{
            state.value=action.payload.rolesAndPermission
        }
    }
})

export const {update_roles_and_permission}=viewRolesAndPermissionSlice.actions
export default viewRolesAndPermissionSlice.reducer