import React, { useEffect, useState } from 'react'
import { MultiCurrencyApiCall, viewMultiCurrencyConfiguration } from '../../../../../API/Purchase Manager/configuration';
import { useSelector } from 'react-redux';
import ErrorSnackbar from '../../../../Single Components/SnackBars/ErrorSnackbar';
import SuccessSnackbar from '../../../../Single Components/SnackBars/SuccessSnackbar';

export const CurrencyConfig = () => {
    const MultiCurrencyView =  useSelector(
      (state) => state.ConfigurationSlice.MultiCurrency);
    
    const [multiCurrency,setMultiCurrency]=useState(false);
    const [isModify, setIsModify] = useState(false);
    const [snackBarStates, setSnackBarStates] = useState({
      success: false,
      error: false,
      message: "",
      alert: false
    });
    const closeSnackbar = () => {
      setSnackBarStates({
        ...snackBarStates,
        success: false,
        error: false,
        alert: false,
        message: "",
      });
    };
    const updateListner = () => {
      setIsModify(!isModify);
    };
  

    useEffect(()=>{
      viewMultiCurrencyConfiguration()
    },[isModify]);

    useEffect(()=>{
      setMultiCurrency(MultiCurrencyView?.isMultiCurrency)
    },[MultiCurrencyView])

    //getMultiCurrency
    const getMultiCurrency =(e)=>{

        MultiCurrencyApiCall({
          isMultiCurrency: e.target.checked
      },updateListner,setSnackBarStates);
    }


  return (
    <div className='costing-method-container' style={{flexDirection:"column"}}>
    <div className='global-single-input'>
       <p>Currency</p>
    </div>
    <div className='discount-config-checkbox-container'>
      <input 
        type="checkbox" 
        id="dicountConfig"
        checked={multiCurrency}
        onChange={getMultiCurrency}
        
        />
      <label htmlFor="dicountConfig">Multi Currency</label>
    </div>

      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />

      <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
  </div>
  )
}
