import axios from "axios";
import { HEADERS, HEADER_FORM_DATA } from "../../../../../API/UrlAndPaths";
import store from "../../../../../Redux/store";
import {
  get_branch_receipt_list,
  get_branch_receipt_single_view,
  get_branch_receipt_transNo,
} from "./BranchReceiptSlice";

export const viewAllBranchReceiptAPICall = (body, setIsLoading) => {
  setIsLoading !== undefined && setIsLoading(true);
  axios
    .post("inventory/listDestinationBranchTransfer", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          get_branch_receipt_list({ branchReceiptData: res.data })
        );
        setIsLoading !== undefined && setIsLoading(false);
      }
    })
    .catch((err) => {
      console.log(err.response);
      setIsLoading !== undefined && setIsLoading(false);
    });
};
export const BranchReceiptFilterAPICall = (body,setIsLoading) => {
  axios
    .post("inventory/listDestinationBranchTransfer", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(get_branch_receipt_list({ branchReceiptData: res.data }));
        setIsLoading!==undefined&&setIsLoading(false)
      }
    })
    .catch((err) => {
      store.dispatch(get_branch_receipt_list({ branchReceiptData: undefined }));
      setIsLoading!==undefined&&setIsLoading(false)
    });
};

export const editBranchReceiptAPICall = (
  body,
  setSuccessSnackOpen,
  setErrorSnackOpen,
  setSnackMsg,
  editFn,setLoadingForm
) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  axios
    .post("inventory/editDestinationBranchTransfer", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setLoadingForm!==undefined && setLoadingForm(false)

        setSnackMsg("Edited succesfully");
        setSuccessSnackOpen(true);
        editFn !== undefined && editFn();
        getSingleViewData({id:res.data._id})
      }
    })
    .catch((err) => {
      setLoadingForm!==undefined && setLoadingForm(false)
      switch (err.response.status) {
        case 404:
          setSnackMsg(err?.response?.data);
          break;
        case 500:
          setSnackMsg("Server error!!");
          break;
        case 401:
          setSnackMsg("Unauthorized!!");
          break;
        case 403:
          setSnackMsg("Forbidden!!");
          break;
        case 409:
          setSnackMsg("Duplicated");
        default:
          setSnackMsg(
            "Unseen error occurred,Please contact to EYET Support!!!!"
          );
          break;
      }
      setErrorSnackOpen(true);
    });
};

export const postBranchReceiptAPICall = (
  body,
  setSuccessSnackOpen,
  setErrorSnackOpen,
  setSnackMsg,setLoadingForm
) => {
  setLoadingForm!==undefined && setLoadingForm(true)

  axios
    .post("inventory/validateReceipt", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Branch transfere posted");
        setSuccessSnackOpen(true);
        // editFn!==undefined && editFn()
        getSingleViewData({id:res.data._id})
        setLoadingForm!==undefined && setLoadingForm(false)
      }
    })
    .catch((err) => {
      setLoadingForm!==undefined && setLoadingForm(false)

      switch (err.response.status) {
        case 404:
          setSnackMsg(err.response.data);
          break;
        case 500:
          setSnackMsg("Server error!!");
          break;
        case 401:
          setSnackMsg("Unauthorized!!");
          break;
        case 403:
          setSnackMsg("Forbidden!!");
          break;
        case 409:
          setSnackMsg("Duplicated");
        default:
          setSnackMsg(
            "Unseen error occurred,Please contact to EYET Support!!!!"
          );
          break;
      }
      setErrorSnackOpen(true);
    });
};

// get single view data
export const getSingleViewData = async (body) => {
  try {
    let response = await axios.post(
      `inventory/listDestinationBranchTransferById`,
      body,
      HEADERS
    );
    if (response.status === 200) {
      console.log("response.data", response.data);
      store.dispatch(get_branch_receipt_single_view(response.data));
    }
  } catch (error) {
    console.log("Error: ", error);
    throw new Error(error);
  }
};
