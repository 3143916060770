import {createSlice} from '@reduxjs/toolkit'

export const purchaseReportSingleViewSlice=createSlice({
    name:'purchaseReportSingleView',
    initialState:{
        value:undefined
    },
    reducers:{
        get_purchase_report_single_view:(state,action)=>{
            state.value=action.payload.purchaseReportSingleData
        }
    }
})
export const {get_purchase_report_single_view} = purchaseReportSingleViewSlice.actions;
export default purchaseReportSingleViewSlice.reducer