import { createSlice } from "@reduxjs/toolkit";

export const allSupplierListSlice=createSlice({
    name:'allSupplierListSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_all_supplier_list:(state,action)=>{
            state.value=action.payload.allSupplierList
        }
    }
})

export const {update_all_supplier_list}=allSupplierListSlice.actions
export default allSupplierListSlice.reducer