import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import CategorySearchandFilter from '../../../Single Components/CategorySearchandFilter';
import { useSelector } from 'react-redux';
import { viewAllCurrencyUpdated } from '../../../../API/Settings/Currency/CurrencyAPI';
import { companyProfileInfoForUser } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import { convertArrayToExcel, filterObjFromList } from '../../../../Js/generalFunctions';
import { StockMoveAPIcall } from './ReportAPI';
import { getCurrentTime } from '../../../../Js/Date';
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import ReactToPrint from 'react-to-print';
import Template from "./StockMovePrint"
import * as XLSX from "xlsx";
import { setFilterActive } from '../../../Single Components/listSlice/filterCategorySlice';
import store from '../../../../Redux/store';
import { setTableLoadingGlobal } from '../../../../Redux/Dashboard/mainDashBoardSlice';
import { LoadingForm } from '../../../Single Components/LoadingForm';


export const StockMove = () => {
  let paymentsPrintNew = useRef();
  let navigate = useNavigate();
  const [symbol, setSymbol] = useState(null);
  const [FormDataInfo, setFormDataInfo] = useState([])
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const decimalPosition = localStorage.getItem("decimalPosition");
  const decimalPositionStock = localStorage.getItem("decimalPositionStock");
  const [isLoading,setIsLoading] = useState(true)
  const [selectedBranch, setSelectedBranch] = useState(null);
  const stockMove = useSelector((state) => state?.ReportSlice?.StockMove)
  const {tableLoading} = useSelector((state) => state.mainDashBoardSlice);

  // console.log(stockMove);

  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );

  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );

  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };
  const initialvalue = {
    label: "Stock Move",
    totalBalance: 0,
    totalCredit: 0,
    totalDebit: 0,

    companyLogo: "",
    companyName: "",
    companyAddress: "",
    companyContact: "",
  }

  const [data, setData] = useState([])
  const [formValues, setFormValues] = useState(initialvalue)

  useEffect(() => {
    setFormValues({
      ...formValues,
      companyLogo: companyProfileData?.logo || "",
      companyName: companyProfileData?.companyName || "",
      companyAddress: companyProfileData?.companyAddress || "",
      companyContact: companyProfileData?.contactNumber || "",

    });
    setData(stockMove)
  }, [stockMove, companyProfileData])


  const handleClickRow = (row) => {
    store.dispatch( setFilterActive(window.location.pathname))
    localStorage.setItem("vendor", row?.productName)
    localStorage.setItem("stock_id", row?._id);
    store.dispatch(setTableLoadingGlobal(true))
    navigate("/userdashboard/inventory/reports/stockMove/singleView")
  
  }

  // console.log(stockMove?._id);

  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    // console.log(currencyObj);
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);

  useEffect(() => {
    companyProfileInfoForUser();
  }, []);

  const downloadList = () => {
    const worksheet = XLSX.utils.table_to_sheet(document.getElementById("table_data"));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Stock Move.xlsx");
  }

  useEffect(() => {
    if (FormDataInfo?.length !== 0) {
      localStorage.setItem("branch", (FormDataInfo?.branchList?.length === 0 ? '' : FormDataInfo?.branchList[0]))
      localStorage.setItem("location", FormDataInfo?.location?.length === 0 ? '' : FormDataInfo?.location[0])
      localStorage.setItem("fromDate", FormDataInfo?.fromDate)
      localStorage.setItem("ToDate", FormDataInfo?.toDate)

      let body = {
        fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
        endDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
        location: FormDataInfo?.location?.length === 0 ? '' : FormDataInfo?.location[0],
        branchId: FormDataInfo?.branchList?.length === 0 ? '' : FormDataInfo?.branchList[0],

        search: FormDataInfo?.search,
      }
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData && StockMoveAPIcall(body,setIsLoading)
    }
  }, [FormDataInfo])



  return (
    <div className="global-page-parent-container" style={{ margin: "0" }}>
      <div className="global-white-bg-container">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="justify-space-between" >
            <div className="sharp-arrow" style={{ width: "147Px" }}> <p>Stock Move Report</p></div>
            <div style={{ marginLeft: "19px" }}>
              <Tooltip title="Share">
                <IconButton>
                  <i class="bi bi-share" style={{ backgroundColor: "#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
                </IconButton>
              </Tooltip>

              <ReactToPrint
                trigger={() => (
                  <IconButton className="printer-div" style={{ padding: "1px" }}>
                    <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
                  </IconButton>
                )}
                content={() => paymentsPrintNew}
              />

              <Tooltip title="Download">
                <IconButton onClick={downloadList}>
                  <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
                </IconButton>
              </Tooltip>

            </div>
          </div>
          <div>
            <CategorySearchandFilter
              statusOptions={['PURCHASE ORDER', 'DONE', 'CANCEL']}
              onData={handleDataFromChild}
              isLocation={true}
              onBranchSelect={handleBranchSelect}
            />

          </div>
        </div>
        <h1 style={{ margin: "1px", fontSize: "large" }}>Stock Move Report</h1>

        <div className="global-report-table-container">
          <table id='table_data'>
            <thead>
              <tr >
                <th>Item</th>
                <th>UOM</th>
                <th>Opening</th>
                <th>In</th>
                <th>Out</th>
                <th>Closing</th>
                <th>Cost</th>
                <th>Values</th>
              </tr>
            </thead>
            <tbody>
              {
              tableLoading?
                [...Array(15)].map((_,i)=>(
                  <tr>
                    <td colSpan={15}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                ))
              :
              stockMove!==undefined?
              stockMove?.map((item, index) => (
                <tr key={index} onClick={() => handleClickRow(item)}>
                  <td style={{ textAlignLast: "start", paddingLeft: "10px" }}>{item.productName}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.uom}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.openingBalance}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.totalInDate?.toFixed(decimalPositionStock)}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.totalOutDate?.toFixed(decimalPositionStock)}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.closingBalance?.toFixed(decimalPosition)}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{symbol}&nbsp;{item.cost?.toFixed(decimalPosition) || 0?.toFixed(decimalPosition)}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{symbol}&nbsp;{item.value?.toFixed(decimalPosition)}</td>
                </tr>
              ))
              :
              <tr>
                <td colSpan={8}>No Data</td>
              </tr>
            }
            </tbody>
            {
            stockMove!==undefined &&  tableLoading===false &&
            <tfoot>
              <tr>
                <th colSpan={7}>Total</th>
                <th >{symbol}&nbsp;{(stockMove?.reduce((sum, item) => sum + item?.value, 0)?.toFixed(decimalPosition))}</th>
              </tr>
            </tfoot>}
          </table>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div ref={(el) => (paymentsPrintNew = el)}>
          <Template data={data} formValues={formValues} />
        </div>
      </div>
      <LoadingForm loading={isLoading} />
    </div>
  )
}