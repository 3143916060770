import axios from "axios";
import store from "../../../../Redux/store";
import { HEADERS } from "../../../../API/UrlAndPaths";
import { view_customer_credit_report_list,customer_credit_report_single_list1,customer_credit_report_single_list2 } from "./CustomerCreditReportSlice";



export const ViewCustomerCreditReportAPICall = async (body,setIsLoading) => {
  setIsLoading!==undefined&&setIsLoading(true)
  await axios
    .post('sales/posCustomerCreditReport', body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("áll active employees for Hrm fetched");
        store.dispatch(view_customer_credit_report_list({ customerCreditReportData: res.data }));
        setIsLoading!==undefined&&setIsLoading(false)
      }
    })
    .catch((err) => {
      store.dispatch(view_customer_credit_report_list({ customerCreditReportData: undefined }));
      setIsLoading!==undefined&&setIsLoading(false)
      console.error(err.response);
    });
};
export const CustomerCreditReportFirstSingleViewAPICall = async (body,setIsLoading) => {
  await axios
    .post('sales/posCustomerCreditReportByCustomer', body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("áll active employees for Hrm fetched");
        store.dispatch(customer_credit_report_single_list1({ custmrCreditRprtFirstSingleView: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(customer_credit_report_single_list1({ custmrCreditRprtFirstSingleView: undefined }));
      console.error(err.response);
    });
};
export const CustomerCreditReportSecondSingleViewAPICall = async (body) => {
  await axios
    .post('sales/posCustomerCreditReportByCustomerForInvoice', body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("áll active employees for Hrm fetched");
        store.dispatch(customer_credit_report_single_list2({ custmrCreditRprtSecondSingleView: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(customer_credit_report_single_list2({ custmrCreditRprtSecondSingleView: undefined }));
      console.error(err.response);
    });
};