import { createSlice } from "@reduxjs/toolkit";

export const purchaseOrderIdSlice=createSlice({
    name:'purchaseOrderIdSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_po_id:(state,action)=>{
            state.value=action.payload.poId
        }
    }
})

export const {update_po_id}=purchaseOrderIdSlice.actions
export default purchaseOrderIdSlice.reducer