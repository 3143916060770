import { Dialog, IconButton, MenuItem, Select, Skeleton, Tooltip } from "@mui/material";
import React from "react";
import { useState } from "react";
import "../../../css/Offer/offerListed.css"
import { AddOffer } from "./AddOffer";
import { deleteOfferAPICall, editOfferAPICall, viewAddedOfferAPICall } from "../../../API/Offer/OfferAPI";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import ErrorSnackbar from "../../Single Components/SnackBars/ErrorSnackbar";
        
export const OffersListed=()=>{
    const storeCode=localStorage.getItem('branchId')
    const[offerView,setOfferView]=useState(false)
    const[offerEdit,setOfferEdit]=useState(false)
    const[openAddOffer,setOpenAddOffer]=useState(false)
    const[singleOfferView,setSingleOfferView]=useState([])
    const[isModify,setIsModify]=useState(false);
    const[conditionsArray,setConditionsArray]=useState([]);

    // loading states
    const [isLoading,setIsLoading]=useState(false)
    const [snackMsg, setSnackMsg] = useState("oops!!!");
    const [errorSnackOpen, setErrorSnackOpen] = useState(false);
    
    const [searchKeyWord,setSearchKeyWord]=useState("")
    const [filteredOfferList,setFilteredOfferList]=useState([])
    const allOfferList = useSelector(
            (state)=>state.allOfferListSlice.value
            );

    const offerListBody=singleOfferView[0]!==undefined &&{
        
    
        productId:singleOfferView[0].productId,
        productImg:singleOfferView[0].ProductImageUrl===undefined?"noImg":
                    singleOfferView[0].ProductImageUrl[0],
        offerType:singleOfferView[0].offer[0].offerTypes,
        percentage:singleOfferView[0].offer[0].percentage,
        buy:singleOfferView[0].offer[0].buyGet!==null&&singleOfferView[0].offer[0].buyGet.buy,
        get:singleOfferView[0].offer[0].buyGet!==null&&singleOfferView[0].offer[0].buyGet.get,
        quantity:singleOfferView[0].offer[0].quantity!==null&&singleOfferView[0].offer[0].quantity.quantity,
        qntPrice:singleOfferView[0].offer[0].quantity!==null&&singleOfferView[0].offer[0].quantity.price,
        startDate:singleOfferView[0].startDate,
        endDate:singleOfferView[0].endDate,
        conditions:singleOfferView[0].conditions,
        prdId:singleOfferView[0].prodId,
        calculatedPrice:singleOfferView[0].calculatedPrice,
        
    }
    const percentageViewAmt=offerListBody.calculatedPrice!==undefined &&
                offerListBody.calculatedPrice-(offerListBody.percentage/100*offerListBody.calculatedPrice) 
    const quantityViewAmt=offerListBody.qntPrice
    const buyGetViewAmt=offerListBody.buy*offerListBody.calculatedPrice

    const offerEditBodyInitial={
        _id:"",
        offerType:"",
        genderType: "",
        category: "",
        subcategory: "",
        occassion: "",
        productId: "",
        dimension: "",
        offerTypes:"",
        percentage:[],
        buy:"",
        get:"",
        quantity:"",
        qntPrice:"",
        startDate:"",
        endDate:"",
        conditions:"",
        branchId:"",
        productImg:"",
        prdId:"",
        calculatedPrice:""
    };
    const [offerEditBody,setOfferEditBody] = useState(
        offerEditBodyInitial);

    const editOfferPayload=
            {
            _id:offerEditBody._id,
            offerType:offerEditBody.offerType,
            genderType:offerEditBody.genderType,
            category:offerEditBody.category,
            subcategory:offerEditBody.subcategory,
            occassion:offerEditBody.occassion,
            productId:offerEditBody.productId,
            dimension:offerEditBody.dimension,
            offer: {
                offerTypes: offerEditBody.offerTypes,
                percentage: offerEditBody.offerTypes==="percentage"?
                offerEditBody.percentage:null
                ,
                buyGet:offerEditBody.offerTypes==="buyget"?{
                    buy:offerEditBody.buy,
                    get:offerEditBody.get
                }:null,
                quantity:offerEditBody.offerTypes==="quantity"?{
                    quantity:offerEditBody.quantity,
                    price:offerEditBody.qntPrice
                }:null
                    },
                    startDate:offerEditBody.startDate,
                    endDate:offerEditBody.endDate,
                    conditions: 
                    conditionsArray
                    ,
                    branchId:offerEditBody.branchId
                }
    
    const clickOfferSingleEdit=(
        id,
        offerType,
        genderType,
        category,
        subcategory,
        occassion,
        productId,
        dimension,
        branchId,
        offerTypeSelect,
        percentage,
        buyGet,
        quantity,
        startDate,
        endDate,
        conditions,
        imageUrl,
        prdId,
        calculatedPrice
        )=>{
        setOfferEdit(true)
        setOfferEditBody({...offerEditBody,
                        _id:id,
                        offerType:offerType,
                        genderType:genderType,
                        category:category,
                        subcategory:subcategory,
                        occassion:occassion,
                        productId:productId,
                        dimension:dimension,
                        offerTypes:offerTypeSelect,
                        percentage:percentage,
                        buy:offerTypeSelect==="buyget"?
                                buyGet.buy:null,
                        get:offerTypeSelect==="buyget"?
                                buyGet.get:null,
                        quantity:offerTypeSelect==="quantity"?
                                quantity.quantity:null,
                        qntPrice:offerTypeSelect==="quantity"?
                                quantity.price:null,
                        startDate:startDate,
                        endDate:endDate,
                        branchId:branchId,
                        productImg:imageUrl===undefined?"No image" :imageUrl[0],
                        prdId:prdId,
                        calculatedPrice:calculatedPrice
        })
        setConditionsArray(conditions.map((obj)=>obj))
    }    
    const percentageEditAmt=offerEditBody.calculatedPrice!==undefined &&
    offerEditBody.calculatedPrice-(offerEditBody.percentage/100*offerEditBody.calculatedPrice) 
    const quantityEditAmt=offerEditBody.qntPrice
    const buyGetEditAmt=offerEditBody.buy*offerEditBody.calculatedPrice
    
    const handlechangeOffer =(e)=>{
            setOfferEditBody({...offerEditBody,offerTypes:e.target.value})                
    }
    const closeErrorSnack = () => {
        setErrorSnackOpen(false);
    };
    const getEditOfferFormData =(key) =>(e)=>{
        const {value} =e.target;

        if(key === "percentage"){
            setOfferEditBody({...offerEditBody,percentage:value})
        }
        if(key === "buy"){
            setOfferEditBody({...offerEditBody,buy:value})
        }
        if(key === "get"){
            setOfferEditBody({...offerEditBody,get:value})
        }
        if(key === "quantity"){
            setOfferEditBody({...offerEditBody,quantity:value})
        }
        if(key === "price"){
            setOfferEditBody({...offerEditBody,qntPrice:value})
        }
        if(key === "start"){
            setOfferEditBody({...offerEditBody,startDate:value})
        }
        if(key === "endDate"){
            setOfferEditBody({...offerEditBody,endDate:value})
        }
        if(key === "conditions"){
            setOfferEditBody({...offerEditBody,conditions:value})
        }
    }

    const clickOfferSingleView=(id)=>(e)=>{
        setOfferView(true)
        if(allOfferList !== undefined){
            const filteredSingleView=allOfferList?.filter((obj)=>{
                if(obj?._id === id){
                    return obj
                }
            })
            setSingleOfferView(filteredSingleView)
        }
    }

    const clickAddCondition=()=>{
        if(offerEditBody.conditions!==""){
            setConditionsArray([...conditionsArray,offerEditBody.conditions])
            setOfferEditBody({...offerEditBody,conditions:""})
        }
    }
    const deleteTableRow=(index)=>{
        console.log("helpo");
        conditionsArray.splice(index,1)
        setConditionsArray([...conditionsArray])        
    }
    
    const clickDeleteOffer=(id)=>(e)=>{
        deleteOfferAPICall({_id:id})
        setIsModify(!isModify)
    }

    const clickSubmitEdit=()=>{
        editOfferAPICall(editOfferPayload)
        setOfferEdit(false)
        setIsModify(!isModify);
    }
    const editCloseClick=()=>{
        setOfferEdit(false)
        setOfferEditBody(offerEditBodyInitial)
    }
    const getSearchKeyword=(e)=>{
        setSearchKeyWord(e.target.value)
    }
    const searchFilterList=(chars,listTofilter)=>{
        let searchedArray=[]
        let filteredArray=[]
        if(chars!==""){
            searchedArray=listTofilter.filter((obj)=>
                obj?.branchId?.toLowerCase()?.includes(chars?.toLowerCase())||
                obj?.prodId?.toLowerCase()?.includes(chars?.toLowerCase())||
                obj?.branchName?.toLowerCase()?.includes(chars?.toLowerCase())||
                obj?.itemName?.toLowerCase()?.includes(chars?.toLowerCase())
            )
            filteredArray=searchedArray
        }
        else{
            filteredArray=listTofilter
        }
        return filteredArray;
    }
    useEffect(()=>{
        
        if(allOfferList!==undefined){
            setFilteredOfferList(allOfferList)
            let filteredArray=searchFilterList(searchKeyWord,allOfferList)
            setFilteredOfferList(filteredArray)
        }
        else{
            setFilteredOfferList([])
        }
    },[searchKeyWord,allOfferList])

    
    useEffect(()=>{
        viewAddedOfferAPICall(setIsLoading,setErrorSnackOpen,setSnackMsg,{branchId:storeCode})
    },[isModify])
    
return(
    <>
    <div className="global-page-parent-container">
        <p className="breadcrumb">Offer &gt; Offer Listed</p>
        <div className="offer-listed-container">
            <div className="offer-listed-container-top">
            <h3>Offer Listed</h3>
            <div className="supplier-manage-top-container-right">
              <div className="view-order-search-container view-order-admin-search-container">
                  <input
                      placeholder="Branch,Product Details"
                       value={searchKeyWord}
                       onChange={getSearchKeyword}
                      />
                  <IconButton>
                      <i class="bi bi-search search-icon-order"></i>
                  </IconButton>
              </div>
              <button 
              style={{width:"30%"}}
              className="btn btn-primary" 
              onClick={()=>{setOpenAddOffer(true)}}>
                Add Offer</button>
            </div>
           
            </div>
            <table className="global-table">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Branch Code</th>
                        <th>Branch Name</th>
                        <th>Product ID</th>
                        <th>Product Name</th>
                        <th>Image</th>
                        <th>Edit</th>
                        <th>View</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                {
                isLoading?
                <tbody>
                    <tr>
                           <td colSpan={10}>
                              {/* <Loader /> */}
                              <Skeleton
                                 variant="rectangular"
                                 animation="wave"
                                 width={"100%"}
                                 height={40}
                              />
                           </td>
                           </tr>
                           <tr>
                           <td colSpan={10}>
                              {/* <Loader /> */}
                              <Skeleton
                                 variant="rectangular"
                                 animation="wave"
                                 width={"100%"}
                                 height={40}
                              />
                           </td>
                           </tr>
                           <tr>
                           <td colSpan={10}>
                              {/* <Loader /> */}
                              <Skeleton
                                 variant="rectangular"
                                 animation="wave"
                                 width={"100%"}
                                 height={40}
                              />
                           </td>
                           </tr>
                           <tr>
                           <td colSpan={10}>
                              {/* <Loader /> */}
                              <Skeleton
                                 variant="rectangular"
                                 animation="wave"
                                 width={"100%"}
                                 height={40}
                              />
                           </td>
                           </tr>
                           <tr>
                           <td colSpan={10}>
                              {/* <Loader /> */}
                              <Skeleton
                                 variant="rectangular"
                                 animation="wave"
                                 width={"100%"}
                                 height={40}
                              />
                           </td>
                           </tr> 
                           <tr>
                           <td colSpan={10}>
                              {/* <Loader /> */}
                              <Skeleton
                                 variant="rectangular"
                                 animation="wave"
                                 width={"100%"}
                                 height={40}
                              />
                           </td>
                           </tr> 
                           <tr>
                           <td colSpan={10}>
                              {/* <Loader /> */}
                              <Skeleton
                                 variant="rectangular"
                                 animation="wave"
                                 width={"100%"}
                                 height={40}
                              />
                           </td>
                           </tr> 
                           <tr>
                           <td colSpan={10}>
                              {/* <Loader /> */}
                              <Skeleton
                                 variant="rectangular"
                                 animation="wave"
                                 width={"100%"}
                                 height={40}
                              />
                           </td>
                           </tr>
                </tbody>:
                <tbody>
                    {
                        filteredOfferList.length!==0 ?
                            (filteredOfferList
                            .slice(0)
                            .reverse()
                            .map((r,i)=>(
                                <tr key={i}>
                                <td>{i+1}</td>
                                <td>{r?.branchId}</td>
                                <td>{r?.branchName}</td>
                                <td>{r?.prodId}</td>
                                <td>{r?.itemName}</td>
                                <td className="offerlist-table-image">
                                    {
                                        r?.ProductImageUrl===null?
                                        <img src={
                                            r?.ProductImageUrl ===undefined?"noImg":r?.ProductImageUrl} 
                                            alt={r?.ProductImageUrl ===undefined?"undefined img":"L"} />
                                        :<img src={
                                            r?.ProductImageUrl ===undefined?"noImg":r?.ProductImageUrl[0]} 
                                            alt={r?.ProductImageUrl ===undefined?"undefined img":"No Image Available"} />
                                        }
                                </td>
                                <td><IconButton onClick={()=>clickOfferSingleEdit(
                                    r?._id,
                                    r?.offerType,
                                    r?.genderType,
                                    r?.category,
                                    r?.subcategory,
                                    r?.occassion,
                                    r?.productId,
                                    r?.dimension,
                                    r?.branchId,
                                    r?.offer[0].offerTypes,
                                    r?.offer[0].percentage,
                                    r?.offer[0].buyGet,
                                    r?.offer[0].quantity,
                                    r?.startDate,
                                    r?.endDate,
                                    r?.conditions,
                                    r?.ProductImageUrl,
                                    r?.prodId,
                                    r?.calculatedPrice
                                    )}>
                                        <i class="bi bi-pencil-square edit-icon"></i>
                                    </IconButton></td>
                                <td><IconButton onClick={clickOfferSingleView(r?._id)}><i class="bi bi-eye visibility-icon"></i></IconButton></td>
                                <td><IconButton onClick={clickDeleteOffer(r?._id)}><i class="bi bi-trash3 delete-icon"></i></IconButton></td>
                                
                            </tr>
                            ))
                    
                            )
                    :(
                        <tr>
                            <td colSpan={8}>No Records</td>
                        </tr>
                    )
                }
                </tbody>
                }
            </table>
        </div>

        <Dialog open={offerEdit} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setOfferEdit(false)}>
            <div className="offer-listed-view-container">
                <h3>Edit</h3>
                <hr className="global-hr" />
                <h4>Product ID&nbsp;:&nbsp;{offerEditBody.prdId}</h4>
                <h4>Product Price &nbsp;:&nbsp;{offerEditBody.calculatedPrice}</h4>
                <img src={offerEditBody.productImg} alt="productImg" style={{width:'25%'}}/>
                <div className="global-single-input offer-view-single-input">
                    <p>Choose Offer</p>
                    <Select value={offerEditBody.offerTypes} onChange={handlechangeOffer}>
                        <MenuItem value="percentage">Percentage</MenuItem>
                        <MenuItem value="buyget">Buy And get</MenuItem>
                        <MenuItem value="quantity">Quantity</MenuItem>
                    </Select>
                    <Tooltip title="Selected offer will be applied to the above product">
                    <div className="offer-view-qmark-ctn">
                        ?
                    </div>
                    </Tooltip>
                </div>
                <div className="global-single-input offer-view-single-input">
                    {
                        offerEditBody.offerTypes==="percentage"?
                        <>
                        <p>Percentage (%)</p>
                        <input type="number"
                        value={offerEditBody.percentage}
                        onChange={getEditOfferFormData("percentage")}
                        />
                        </>
                        :
                        offerEditBody.offerTypes==="buyget"?
                        <>
                        <p>Buy</p>
                        <input type="number"
                        value={offerEditBody.buy}
                        onChange={getEditOfferFormData("buy")}
                        />
                        <p>Get</p>
                        <input type="number"
                        value={offerEditBody.get}
                        onChange={getEditOfferFormData("get")}
                        />
                        </>:
                        offerEditBody.offerTypes==="quantity"?
                        <>
                        <p>Quantity</p>
                        <input type="number"
                        value={offerEditBody.quantity}
                        onChange={getEditOfferFormData("quantity")}
                        />
                        <p>Price</p>
                        <input type="number"
                        value={offerEditBody.qntPrice}
                        onChange={getEditOfferFormData("price")}
                        />
                        </>
                        :""
                    }

                    
                </div>

                {
                    offerEditBody.offerTypes==="percentage"?    
                    <h3 style={{color:'red'}}>Current Amount  &nbsp; &nbsp; :&nbsp; {isNaN(percentageEditAmt)
                                                                                    ?0
                                                                                    :percentageEditAmt    
                                                                                    }
                    </h3>
                    :
                    offerEditBody.offerTypes==="buyget"?
                    <h3 style={{color:'red'}}>Current Amount &nbsp; &nbsp; :&nbsp; {isNaN(buyGetEditAmt)
                        ?0
                        :buyGetEditAmt}</h3>
                    :<h3 style={{color:'red'}}>Current Amount &nbsp; &nbsp; :&nbsp;{isNaN(quantityEditAmt)
                        ?0
                        :quantityEditAmt}</h3>
                    }
                    
                <div className="global-single-input offer-view-single-input">
                    <p>Sale Start Date</p>
                    <input type="date"
                    value={offerEditBody.startDate}
                    onChange={getEditOfferFormData("start")}
                    />
                    <Tooltip title="Offer will be available from this date onwards">
                    <div className="offer-view-qmark-ctn">
                        ?
                    </div>
                    </Tooltip>
                </div>
                <div className="global-single-input offer-view-single-input">
                    <p>Sale End Date</p>
                    <input type="date"
                    value={offerEditBody.endDate}
                    onChange={getEditOfferFormData("endDate")}
                    />

                    <Tooltip title="Offer will only available till this date">
                    <div className="offer-view-qmark-ctn">
                        ?
                    </div>
                    </Tooltip>
                </div>
                <div className="global-single-input offer-view-single-input">
                    <p>Conditions</p>
                    <input type="text"
                    value={offerEditBody.conditions}
                    onChange={getEditOfferFormData("conditions")}
                    />
                    <div className="add-condition-btn"
                    onClick={clickAddCondition}
                    >+</div>
                    <div className="add-offer-conditions-list">
                    {
                        conditionsArray?.length !==0 ?
                        conditionsArray !==undefined && 
                        conditionsArray
                        .map((value,i)=>{
                        return(
                            <div className="add-offer-condition-row" key={i}>
                            <ul>
                                <li>{value}</li>
                            </ul>
                            <IconButton 
                                onClick={()=>deleteTableRow(i)}
                                >
                                <i class="bi bi-trash3 delete-icon"></i>
                            </IconButton>
                            
                            </div>
                        )
                        }):""   
                    }
                </div>
                    <Tooltip title="To delete an existing condition,you should add a new condition first.">
                    <div className="offer-view-qmark-ctn">
                        ?
                    </div>
                    </Tooltip>
                </div>
                <div className="offer-view-button">
                    <button className="btn btn-secondary-outlined" onClick={editCloseClick}>Close</button>
                    
                        <button className="btn btn-primary" onClick={clickSubmitEdit}>Edit</button>
                        
                </div>
            </div>
        </Dialog>
        <Dialog open={offerView} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setOfferView(false)}>
            <div className="offer-listed-view-container view-dialog">
                <h3>Offer View</h3>
                <hr className="global-hr" />
                <h4>Product ID&nbsp;:&nbsp;{offerListBody.prdId}</h4>
                <h4>Product Price &nbsp;:&nbsp;{offerListBody.calculatedPrice}</h4>
                <img src={offerListBody.productImg} alt="productImg" style={{width:'25%'}}/>
                <div className="global-single-input offer-view-single-input">
                    <p>Selected Offer</p>
                    <input type="text" placeholder="Percentage" disabled="true"
                    value={offerListBody.offerType}
                    />
                </div>
                <div className="global-single-input offer-view-single-input">
                    {
                        offerListBody.offerType==="percentage"?
                        <>
                        <p>Percentage (%)</p>
                        <input type="text" placeholder="50%" disabled="true"
                        value={`${offerListBody.percentage}%`}
                        />
                        </>
                        :
                        offerListBody.offerType==="buyget"?
                        <>
                        <p>Buy</p>
                        <input type="text" placeholder="50%" disabled="true"
                        value={offerListBody.buy}
                        />
                        <p>Get</p>
                        <input type="text" placeholder="50%" disabled="true"
                        value={offerListBody.get}
                        />
                        </>:
                        offerListBody.offerType==="quantity"?
                        <>
                        <p>Quantity</p>
                        <input type="text" placeholder="50%" disabled="true"
                        value={offerListBody.quantity}
                        />
                        <p>Price</p>
                        <input type="text" placeholder="50%" disabled="true"
                        value={offerListBody.qntPrice}
                        />
                        </>
                        :""
                    }
                </div>
                    {
                    offerListBody.offerType==="percentage"?    
                    <h3 style={{color:'red'}}>Current Amount &nbsp; &nbsp; :&nbsp;{isNaN(percentageViewAmt)?0:percentageViewAmt}</h3>
                    :
                    offerListBody.offerType==="buyget"?
                    <h3 style={{color:'red'}}>Current Amount &nbsp; &nbsp; :&nbsp;{isNaN(buyGetViewAmt)?0:buyGetViewAmt}</h3>
                    :<h3 style={{color:'red'}}>Current Amount &nbsp; &nbsp; :&nbsp;{isNaN(quantityViewAmt)?0:quantityViewAmt}</h3>
                    }

                <div className="global-single-input offer-view-single-input">
                    <p>Sale Start Date</p>
                    <input type="date" disabled="true"
                    value={offerListBody.startDate}
                    />
                </div>
                <div className="global-single-input offer-view-single-input">
                    <p>Sale End Date</p>
                    <input type="date" disabled="true"
                    value={offerListBody.endDate}
                    />
                </div>
                <div className="global-single-input offer-view-single-input">
                    <p>Conditions</p>
                    <div className="add-offer-conditions-list">
                    {
                        offerListBody.conditions?.length !==0 ?
                        offerListBody.conditions !==undefined && 
                        offerListBody.conditions
                        .map((value,i)=>{
                        return(
                            <div className="add-offer-condition-row" key={i}>
                            <ul>
                                <li>{value}</li>
                            </ul>
                            
                            </div>
                        )
                        }):""   
                    }
                </div>
                </div>
                <div className="offer-view-button">
                    <button className="btn btn-secondary-outlined" onClick={()=>{setOfferView(false)}}>Close</button>
                </div>
            </div>
        </Dialog>
        <Dialog open={openAddOffer} onClose={()=>{setOpenAddOffer(false)}} onKeyDown={(e)=>e.key === "Escape" && setOpenAddOffer(false)}>
                <AddOffer  breadcrumb="false" width={{width:"100%"}} closeDialog={()=>{setOpenAddOffer(false)}} updateListener={()=>{setIsModify(!isModify)}} />
        </Dialog> 
    </div>
    <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnack}
        message={snackMsg}
      />
    </>
)
}