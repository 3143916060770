import React,{useState} from 'react'
import '../../../../css/Settings/CompanySettings/forgotPassword.css'
import {MenuItem, Select} from '@mui/material'
import OTPInput from "otp-input-react";

export const ForgotPassword = () => {
  const [status,setStatus]=useState('mobileVerification')
  const [OTP, setOTP] = useState("");
  

  const sendOtpBtnFn=()=>{
    setStatus('otpVerification')
  }
  const verifyBtnFn=()=>{
    setStatus('resetPassword')
  }
  const closeOtpBtnFn=()=>{
    setStatus('mobileVerification')
  }
  return (
    <div className='account-settings-container'>
    <h4>Forgot Password</h4>
    {status==='mobileVerification'?
      <div className="forgot-password-container">
        <h3>Mobile Verification</h3>
        <p>Please Enter Your Mobile Number</p>
        <div className="forgot-password-number-input-container">
          <p>Mobile</p>
          <div className="global-single-input forgot-password-input">
            <Select value='india-code'><MenuItem value='india-code'>+91</MenuItem></Select>
            <input
             placeholder="Enter Mobile Number..." maxLength="15"
            />
          </div>
        </div>
        <button onClick={sendOtpBtnFn} className="btn btn-primary forgot-password-send-otp-btn">Send OTP</button>
      </div>
      :status==='otpVerification'?
      <div className="forgot-password-container">
         <h3>Enter OTP</h3>
         <p style={{width:'53%',textAlign:"center"}}>We have sent you an access code via SMS for<br/> mobile number 
            verification</p>

          <div className="otp-input-container">
               <OTPInput
                value={OTP}
                onChange={setOTP}
                autoFocus
                OTPLength={4}
                otpType="number"
              />
          </div>

          <div className="forgot-password-otp-button-container">
            <button onClick={closeOtpBtnFn} className="btn btn-primary-outlined verify-btn">Back</button>
           <button onClick={verifyBtnFn} className="btn btn-primary verify-btn">Verify</button>

          </div>
          <div>
            <p>Don't Receive the OTP?</p>
            <h4 style={{textAlign:"center"}}>Resend Code</h4>
          </div>
      
      </div>
      :status==="resetPassword"?
      <div className="account-settings-container">
          <div className="profile-input-container">
            <p>New Password</p>
            <input
            />
           </div>
           <div className="profile-input-container">
            <p>Confirm Password</p>
            <input
            />
           </div>
           <div className='account-settings-reset-password-btn-container'>
              <button className='btn btn-primary'>Update Password</button>
           </div>
    
      </div>
      :undefined}

    </div>

  )
}
