import { createSlice } from "@reduxjs/toolkit";


export const paymentInvoiceNoListSlice=createSlice({
    name:'paymentInvoiceNoList',
    initialState:{
        value:undefined
    },
    reducers:{
        get_payment_invoice_list:(state,action)=>{
            state.value=action.payload.paymentInvoiceNoListData
        }
    }
})
export const {get_payment_invoice_list} = paymentInvoiceNoListSlice.actions;
export default paymentInvoiceNoListSlice.reducer