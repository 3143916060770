import { createSlice } from "@reduxjs/toolkit";

export const suppliersSlice = createSlice({
  name: "suppliersSlice",
  initialState: {
    openingBalance: "",
    bankAccountDetails: { option: "Yes" },
    bankName: "",
    bankAccountNo: "",
    ifscCode: "",
    branchName: "",
    name: "",
    address: "",
    street: "",
    pinCode: "",
    state: { option: "kerala" },
    country: { option: "india" },
    phoneNumber: "",
    defaultCreditPeriod: "",
    panOrItNumber: "",
    gstInOrUin: "",
    registrationType: "",
  },
  reducers: {
    setOpeningBalance: (state, action) => {
      state.openingBalance = action.payload;
    },
    setBankAccountDetails: (state, action) => {
      state.bankAccountDetails = action.payload;
    },
    setBankName: (state, action) => {
      state.bankName = action.payload;
    },
    setBankAccountNo: (state, action) => {
      state.bankAccountNo = action.payload;
    },
    setIfscCode: (state, action) => {
      state.ifscCode = action.payload;
    },
    setBranchName: (state, action) => {
      state.branchName = action.payload;
    },

    setName: (state, action) => {
      state.name = action.payload;
    },
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    setPinCode: (state, action) => {
      state.pinCode = action.payload;
    },
    setDefaultCreditPeriod: (state, action) => {
      state.defaultCreditPeriod = action.payload;
    },
    setState: (state, action) => {
      state.state = action.payload;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    setPanOrItNumber: (state, action) => {
      state.panOrItNumber = action.payload;
    },
    setGstInOrUin: (state, action) => {
      state.gstInOrUin = action.payload;
    },
    setRegistrationType: (state, action) => {
      state.registrationType = action.payload;
    },
  },
});

export const {
  setAddress,
  setBankAccountDetails,
  setBankAccountNo,
  setBankName,
  setBranchName,
  setCountry,
  setDefaultCreditPeriod,
  setGstInOrUin,
  setIfscCode,
  setName,
  setOpeningBalance,
  setPanOrItNumber,
  setPhoneNumber,
  setPinCode,
  setRegistrationType,
  setState,
} = suppliersSlice.actions;
export default suppliersSlice.reducer;
