import axios from 'axios'
import {HEADERS} from '../UrlAndPaths'
import store from '../../Redux/store'
import {get_petty_cash_balance} from '../../Redux/Expense/outletExpense/viewPettyCashBalanceSlice'


export const viewPettyCashBalanceAPICall= async ()=>{
  await axios.get("expense/getpettybalance",HEADERS)
  .then((res)=>{
    if(res.status===200){
        store.dispatch(get_petty_cash_balance({pettyCashBalanceData:res.data}))
    }
  })
  .catch((err)=>{
    store.dispatch(get_petty_cash_balance({pettyCashBalanceData:undefined}))
    console.log(err.response);
  })
}