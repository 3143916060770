import axios from "axios";
import { get_rewards_and_points } from "../../Redux/RewardsAndPoints/rewardsAndPointsSlice";
import store from "../../Redux/store";
import { HEADERS, HEADER_QUERY } from "../UrlAndPaths";

//View Rewards
export const rewardsAndPointsAPICall = async (body) => {
  // setIsLoading(true);
  await axios
    .post("rewards/viewrewards", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(get_rewards_and_points({ rewardsAndPoints: res.data }));
        // setIsLoading(false);
      }
    })
    .catch((err) => {
      store.dispatch(get_rewards_and_points({ rewardsAndPoints: undefined }));
      console.log(err.response);
      // setIsLoading(false);
    });
};

//Convert Point to amount in add rewards
export const convertPointToAmountAPICall = async () => {
  let response = await axios.post("settings/viewPointRatio", {}, HEADERS);
  return response;
};

//Submit Reward and points
export const submitRewardAndPointsAPICall = async (
  body,
  setSuccessSnack,
  setErrorSnack,
  setSnackMsg,
  clearStates
) => {
  await axios
    .post("rewards/addrewards", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        //show success msg and clear form
        setSnackMsg("Reward points added successfully 👍");
        setSuccessSnack(true);
        clearStates();
      }
    })
    .catch((err) => {
      //show error message
      switch (err.response.status) {
        case 403:
          setSnackMsg("Unauthorized!!");
          break;
        case 409:
          setSnackMsg("Conflict!!");
          break;
        case 500:
          setSnackMsg("Server error!!");
          break;

        default:
          setSnackMsg("ERR_CODE999!!");
          break;
      }
      setErrorSnack(true);
    });
};

// delete reward and points
export const deleteRewardsAndPointsAPICall = async (
  _id,
  updateListner,
  setSuccessSnack,
  setErrorSnack,
  setSnackMsg
) => {
  axios
    .delete("rewards/deleteRewarsds", {
      headers: HEADER_QUERY,
      data: {
        _id,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Reward points Deleted successfully 👍");
        setSuccessSnack(true);
        updateListner();
      }
    })
    .catch((err) => {
      console.log(err.response);
      switch (err.response.status) {
        case 403:
          setSnackMsg("Unauthorized!!");
          break;
        case 409:
          setSnackMsg("Conflict!!");
          break;
        case 500:
          setSnackMsg("Server error!!");
          break;

        default:
          setSnackMsg("ERR_CODE999!!");
          break;
      }
      setErrorSnack(true);
    });
};

// edit reward and points
export const editRewardAndPointsAPICall = async (
  body,
  updateListner,
  setOpenErrorSnackbar,
  setOpenSuccessSnackbar,
  setSnackMsg,
  setEditProduct,
  setEditCategory
) => {
  await axios
    .put("rewards/editrewards", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        // console.log('edit rewards and points');
        setSnackMsg("Reward points Edited successfully 👍");
        setOpenSuccessSnackbar(true);
        setEditProduct(false);
        setEditCategory(false);
        updateListner();
      }
    })
    .catch((err) => {
      console.log(err.response);
      switch (err.response.status) {
        case 403:
          setSnackMsg("Unauthorized!!");
          break;
        case 409:
          setSnackMsg("Conflict!!");
          break;
        case 500:
          setSnackMsg("Server error!!");
          break;

        default:
          setSnackMsg("ERR_CODE999!!");
          break;
      }
      setOpenErrorSnackbar(true);
    });
};

//add reward and point settings
export const addRewardAndPointSettingsAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  clearStates,
  updateListener
) => {
  await axios
    .post("settings/addpointRation", body, HEADERS)
    .then((res) => {
      if(res.status===200)
      {
        setSnackMsg('Ratio updated successfully...')
        setSuccess(true)
        clearStates()
        updateListener()
      }
    })
    .catch((err)=>{
      setSnackMsg(err.response.data.msg)
      setError(true)
    });
};
