import { createSlice } from "@reduxjs/toolkit";

export const branchWiseRackListSlice=createSlice({
    name:'branchWiseRackListSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_branchWise_rackList:(state,action)=>{
            state.value=action.payload.branchWiseRackList
        }
    }
})
export const {update_branchWise_rackList} = branchWiseRackListSlice.actions
export default branchWiseRackListSlice.reducer