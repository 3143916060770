import axios from 'axios'
import {HEADERS} from '../UrlAndPaths'
import store from '../../Redux/store'
import {get_petty_cash_docNo} from '../../Redux/Expense/PettyCashReceipt/pettyCashReciptDocNoSlice'
import {get_petty_cash_receipt} from '../../Redux/Expense/PettyCashReceipt/pettyCashReceiptSlice'


// generate petty cash doc no
export const generatePettyCashDocNoAPICall= async ()=>{
 await axios.get("expense/generatepettycashno",HEADERS)
 .then((res)=>{
    if(res.status===200){
        store.dispatch(get_petty_cash_docNo({pettyCashDocNo:res.data}))
    }
 })
 .catch((err)=>{

    console.log(err.response);
    store.dispatch(get_petty_cash_docNo({pettyCashDocNo:undefined}))
 })
}

// view petty cash receipt data
export const viewPettyCashReceiptAPICall=async(body,setIsLoading,setErrorSnack,setSnackMsg)=>{
  if(setIsLoading!==undefined) setIsLoading(true)  
  await axios.post("expense/viewPettyCash",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_petty_cash_receipt({pettyCashReceiptData:res.data}))
            if(setIsLoading!==undefined) setIsLoading(false)
          }
    })
    .catch((err)=>{
        console.log(err.response);
        store.dispatch(get_petty_cash_receipt({pettyCashReceiptData:undefined}))
        switch (err.response.status) {
          case 404:
              setSnackMsg('No data available..')
            break;
          case 500:
              setSnackMsg('Server error!!')
            break;
          case 401:
              setSnackMsg('Unauthorized!!')
            break;
          case 403:
              setSnackMsg('Forbidden!!')
            break;
        
          default:
              setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
            break;
        }
        setErrorSnack(true)
        if(setIsLoading!==undefined) setIsLoading(false)
    })
}

// add petty cash receipt api call
export const addPettyCashReceiptAPICall= async (
    body,
    updateListener,
    setSuccessSnackbar,
    setErrorSnackbar,
    setSnackMsg,
    setAddPettyCashDialog,setLoadingForm
    )=>{
      setLoadingForm(true)
   await axios.post("expense/addpettyCash",body,HEADERS)
   .then((res)=>{
    if(res.status===200){
        setSuccessSnackbar(true)
        setSnackMsg('Added Successfully')
        updateListener()
        setAddPettyCashDialog(false)
        setLoadingForm(false)
    }
   })
   .catch((err)=>{
    setLoadingForm(false)
    setErrorSnackbar(true)
      if (err.response === undefined) {
        setSnackMsg("Network Connection error!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Already Exist");
      }

      if (err.response.status === 401) {
        setSnackMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden");
      }

    console.log(err.response);
   })
}

// edit petty cash receipt
export const editPettyCashReceipt= async (
    body,
    updateListener,
    setSuccessSnackbar,
    setErrorSnackbar,
    setSnackMsg,
    setEditPettyCashDialog,setLoadingForm
    )=>{
      setLoadingForm(true)

   await axios.put("expense/editPettyCash",body,HEADERS)
   .then((res)=>{
    if(res.status===200){
      setLoadingForm(false)

        setSuccessSnackbar(true)
        setSnackMsg('Success...')
        updateListener()
        setEditPettyCashDialog(false)
    }
   })
   .catch((err)=>{
    setLoadingForm(false)

    setErrorSnackbar()
    if (err.response === undefined) {
        setSnackMsg("Network Connection error!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Already Exist");
      }

      if (err.response.status === 401) {
        setSnackMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden");
      }
    console.log(err.response);

   })
}