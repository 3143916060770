import { createSlice } from "@reduxjs/toolkit";

export const bankReconciliation= createSlice({
    name:"bankReconciliation",
    initialState:{
        bankReconciliationtable:undefined,
        bankReconciliationSave:undefined
    },
    reducers:{
        set_bank_reconciliation_table:(state,action)=>{
            state.bankReconciliationtable=action.payload.bankReconciliationTableData
        },
        set_bank_reconciliation_save:(state,action)=>{
            state.bankReconciliationSave=action.payload.bankReconciliationSaveData
        },
        set_bank_reconciliation_list:(state,action)=>{
            state.bankReconciliationListData=action.payload.bankReconciliationfullList
        },
        set_bank_reconciliation_Singlelist:(state,action)=>{
            state.bankReconciliationSingleListData=action.payload.bankReconciliationSingleList
        }

    }
})
export const {set_bank_reconciliation_table,set_bank_reconciliation_save,set_bank_reconciliation_list, set_bank_reconciliation_Singlelist}= bankReconciliation.actions;
export default bankReconciliation.reducer;