import { createSlice } from "@reduxjs/toolkit";

export const salesReturnSingleViewSlice=createSlice({
    name:'salesReturnSingleView',
    initialState:{
        value:undefined
    },
    reducers:{
        get_sales_return_singleView:(state,action)=>{
            state.value=action.payload.salesReturnSingleViewData
        }
    }
})
export const {get_sales_return_singleView} = salesReturnSingleViewSlice.actions;
export default salesReturnSingleViewSlice.reducer