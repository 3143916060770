import { createSlice } from "@reduxjs/toolkit";

export const paymentVoucherSlice=createSlice({
    name:'paymentVoucherSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_payment_voucher_list:(state,action)=>{
            state.value=action.payload.paymentVoucherData
        }
    }
})

export const {update_payment_voucher_list} =paymentVoucherSlice.actions;
export default paymentVoucherSlice.reducer;