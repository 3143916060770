import React, { useEffect, useRef, useState } from 'react'
import { categoryListAPICall } from '../../Inventory/Configuration/Category/CategoryAPI';
import { useSelector } from 'react-redux';
import axios from 'axios';
import store from '../../../../Redux/store';
import { get_pos_reports_list } from './posSalesReportAPI';
import { HEADERS } from '../../../../API/UrlAndPaths';
import CategorySearchandFilter from "../../../Single Components/CategorySearchandFilter"
import { convertDateFormat, getCurrentTime } from '../../../../Js/Date';
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import { LoadingForm } from '../../../Single Components/LoadingForm';
import PossalesFilter from '../../../Single Components/PossalesFilter';
import ReactToPrint from 'react-to-print';
import { convertArrayToExcel } from '../../../../Js/generalFunctions';
import Template from "./PosSalesReportPrint"
import { companyProfileInfoForUser } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import * as XLSX from "xlsx";

export const posReportsAPICall = async (body, setIsLoading) => {
  setIsLoading !== undefined && setIsLoading(true)
  await axios.post("sales/posSaleReport", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(get_pos_reports_list({ posReportsData: res.data }))
        setIsLoading !== undefined && setIsLoading(false)
      }
    }
    )
    .catch((err) => {
      store.dispatch(get_pos_reports_list({ posReportsData: undefined }))
      setIsLoading !== undefined && setIsLoading(false)

    }


    )
}


export const PosSalesReport = () => {
//   const invoiceType=[
//     { label: "work order", type: "1" 
      
//     },
//     {
//         label:"billing", type: "2" 
//     },
//     {
//         label:"altretion", type: "3" 
//     },
//     {
//         label:"return", type: "4" 
//     },
   
// ]
  let paymentsPrintNew = useRef();
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const decimalPosition = localStorage.getItem("decimalPosition");


  const CategoryList = useSelector((state) => state.categorySlice.value);
  const posSalesReportsList = useSelector((state) => state.posReports.reportsList)

  const [category, setCategory] = useState(true)
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  // const [paymentMethod,setPaymentMethod]=useState(false)
  const [selectedBranch, setSelectedBranch] = useState(null);
console.log(FormDataInfo,'FormDataInfo')

// const downloadList=()=>{
  
//  let keyNames=[
//   "dateMillisec",
//   "shiftId",
//   "orderNo",
//   "customer",

//   "totalStitchingRate",
//   "discount",
//   "orderTotal",
//   "deliveryDiscount",
//   "paymentTotal",
//   "status"
//  ]

//  if (category === true && CategoryList) {
//   const customerIndex = categoryList.map((item)=>(item.categoryName))
//   console.log(customerIndex);
//   const customerIndexInKeyNames = keyNames.indexOf("customer");
  
//   keyNames.splice(customerIndexInKeyNames + 1, 0, ...customerIndex);

//   console.log(keyNames);
//   }
  


//  let headerObj={
//   dateMillisec:"Date",
//   shiftId:"Shift Code",
//   orderNo:"Invoice No",
//   customer:"Customer",
//   totalStitchingRate:"Stiching",
//   discount:"Discount",
//   orderTotal:"Order Total ",
//   deliveryDiscount:"Additional Discount",
//   paymentTotal:"Paid",
//   status:"Status",
// }


//  let TotalObj={
//   dateMillisec:"",
//   shiftId:"",
//   orderNo:"",
//   customer:"TOTAL",
//   totalStitchingRate:posSalesReportsList?.grandtotal?.netStitchingRate?.toFixed(2),
//   discount:posSalesReportsList?.grandtotal?.netDiscount?.toFixed(2),
//   orderTotal:posSalesReportsList?.grandtotal?.netOrderTotal?.toFixed(2),
//   deliveryDiscount:posSalesReportsList?.grandtotal?.netDeliveryDiscount?.toFixed(2),
//   paymentTotal:posSalesReportsList?.grandtotal?.netPaidTotal?.toFixed(2),
//   status:"",
//     }

//     const modifiedArray = [];
//     posSalesReportsList?.report.forEach((dailyList) => {

//     const currentList = dailyList?.data.map((obj) => {
//       const newObj = {};
//       keyNames.forEach((key) => {
//         newObj[key] = obj[key];
//       });
//       return newObj;

//     }) ;

//     let tableTotalObj={
//       dateMillisec:"",
//       shiftId:"",
//       orderNo:"",
//       customer:"TOTAL",
//       totalStitchingRate:dailyList?.subTotal?.stitchingRate?.toFixed(2),
//       discount:dailyList?.subTotal?.discount?.toFixed(2),
//       orderTotal:dailyList?.subTotal?.orderTotal?.toFixed(2),
//       deliveryDiscount:dailyList?.subTotal?.deliveryDiscount?.toFixed(2),
//       paymentTotal:dailyList?.subTotal?.paidTotal?.toFixed(2),
//           status:"",
//         }
//     let shiftObj={
//       dateMillisec:dailyList?.branchName,
//       shiftId:"",
//       orderNo:"",
//       customer:"",
//       totalStitchingRate:"",
//       discount:"",
//       orderTotal:"",
//       deliveryDiscount:"",
//       paymentTotal:"",
//       status:"",
//     }

   
   
//     modifiedArray.push(headerObj);
//     modifiedArray.push(shiftObj);
//     modifiedArray.push(...currentList);
//     modifiedArray.push(tableTotalObj);

//   })
//   modifiedArray.push(TotalObj);
//   console.log(modifiedArray);
//   convertArrayToExcel(modifiedArray,"POS sales Report")
// }

const downloadList = () => {
  const worksheet = XLSX.utils.table_to_sheet(document.getElementById("table_data"));
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, "POS Sales Report.xlsx");
};
const companyProfileData = useSelector(
  (state) => state?.companyProfileSlice?.userValue
);
const handleBranchSelect = (selectedBranch) => {
  setSelectedBranch(selectedBranch);
 
};
  function convertToCamelCase(inputStr) {
    return inputStr.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }

  const initialvalue ={
    label:"POS Sales Report",
    netStitchingRate:0,
    netDiscount:0,
    netOrderTotal:0,
    netDeliveryDiscount:0,
    netPaidTotal:0,
    
    companyLogo: "",
    companyName: "",
    companyAddress: "",
    companyContact: "",
    }
      
      const [data,setData]=useState([])
      const [categoryList,setCategoryList]=useState([])
      const [formValues,setFormValues]=useState(initialvalue)
    
    useEffect(()=>{
      setFormValues({...formValues,
        netStitchingRate:posSalesReportsList?.grandtotal?.netStitchingRate || 0,
        netDiscount:posSalesReportsList?.grandtotal?.netDiscount || 0,
        netOrderTotal:posSalesReportsList?.grandtotal?.netOrderTotal || 0,
        netDeliveryDiscount:posSalesReportsList?.grandtotal?.netOrderTotal || 0,
        netPaidTotal:posSalesReportsList?.grandtotal?.netPaidTotal || 0,
    
        companyLogo: companyProfileData?.logo || "",
        companyName: companyProfileData?.companyName || "",
        companyAddress: companyProfileData?.companyAddress || "",
        companyContact: companyProfileData?.contactNumber || "",
    
      });
      setCategoryList(CategoryList)
      setData(posSalesReportsList?.report)
    },[posSalesReportsList,companyProfileData,CategoryList])

  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };

  useEffect(() => {
    categoryListAPICall();
    companyProfileInfoForUser();
  }, [])

  useEffect(() => {
   
    if (FormDataInfo?.length !== 0) {
      // console.log(FormDataInfo?.branchList,'FormDataInfo?.branchList')
      posReportsAPICall({
        branchId: FormDataInfo?.branchList,
        search: FormDataInfo?.search,
        shiftId: FormDataInfo?.shiftCode ? FormDataInfo?.shiftCode : '',
        types: FormDataInfo?.invoiceNo ? FormDataInfo?.invoiceNo : [],
        customer: FormDataInfo?.customer?.name,
        status: FormDataInfo?.status?.length === 0 ? '' : convertToCamelCase(FormDataInfo?.status[0]),
        fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
        toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
      }, setIsLoading)
    }
  }, [FormDataInfo])

  return (
    <div className="global-page-parent-container" style={{ margin: "0" }}>

      <div className="global-white-bg-container">
        <div className='justify-space-between' style={{ paddingBottom: "22px" }}>
         <div style={{display:"flex",alignItems:"center"}}>
          <div className="sharp-arrow">
            <p>POS Sales Report</p>

          </div>
          <div style={{marginLeft:"19px"}}>
          <Tooltip title="Share">
          <IconButton>
            <i class="bi bi-share" style={{ backgroundColor: "#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>

        <ReactToPrint
          trigger={() => (
            <IconButton className="printer-div" style={{ padding: "1px" }}>
              <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
            </IconButton>
          )}
          content={() => paymentsPrintNew}
        />

        <Tooltip title="Download">
          <IconButton onClick={downloadList}>
            <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>
       
        </div>
         {FormDataInfo?.branchList?.length > 0 && (
              <div >
                <h3>Branch name: 
                  {selectedBranch?.storeCode||filtereDataOptions?.filterCategorylist?.branchList?.storeCode}
                  -{selectedBranch?.branchName||filtereDataOptions?.filterCategorylist?.branchList?.branchName}</h3>
              </div>
            )}
        </div>
          <PossalesFilter
            onData={handleDataFromChild}
            statusOptions={['Complete', 'Pending', 'Printing', 'Delivered', 'Order Returned', 'Delivey Returned', 'Return']}
            // isInvoiceNo={true}
            isCustomer={true}
            isStatus={true}
            isShiftCode={true}
            isPosSalesReportPage={true}
            isInvoiceNo={true}
            onBranchSelect={handleBranchSelect} 
            shouldFetchData={true}
            // invoiceList={invoiceType}

          />
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", alignItems: "center", margin: "6px 0" }}>
            <input
              type="checkbox"
              style={{ accentColor: "#1d1da7", cursor: "pointer" }}
              id="posSalesReportCat"
              onChange={(e) => setCategory(e.target.checked)}
              checked={category === true}
            //  disabled={posSalesReportsList === undefined}
            />
            <label htmlFor="posSalesReportCat" style={{ color: "#1d1da7", fontSize: "0.8rem", fontWeight: 600, cursor: "pointer" }}>Category</label>
          </div>
          {/* <div style={{display:"flex",alignItems:"center",margin:"6px 0 6px 6px"}}>
      <input 
        type="checkbox"  
        style={{accentColor:"#1d1da7",cursor:"pointer"}}
        onChange={(e)=>setPaymentMethod(e.target.checked)}
        />
      <label htmlFor="" style={{color:"#1d1da7",fontSize:"0.8rem",fontWeight:600,cursor:"pointer"}}>Payment Method</label>
    </div> */}
        </div>
        <div className='global-report-table-container' style={{ maxHeight: "80vh" }}>
          <table id='table_data'>
            <thead>
              <tr>
                <th style={{ width: "8%" }}>Date</th>
                <th>Shift Code</th>
                <th>Invoice No</th>
                <th>Customer</th>
                {/* {
                paymentMethod &&
                  <th>Payment Method</th>
               } */}

                {
                  category === true && CategoryList?.map((r) => (
                    <th>{r?.categoryName}</th>
                  ))
                }

                <th>Stitching</th>
                <th>Discount</th>


                <th>Order Total</th>
                <th>Additional Discount</th>
                <th>Paid</th>
                <th>Current Status</th>
              </tr>
            </thead>
            <tbody>
            
           
             
              {!isLoading && posSalesReportsList?.report === undefined &&
                <tr>
                  <td style={{fontSize:'1rem'}} colSpan={20}>USE FILTER TO FETCH DATA</td>
                </tr>
              }
              {/* { posSalesReportsList?.report === undefined &&
                <tr>
                  <td style={{fontSize:'1rem'}} colSpan={20}>NO DATA</td>
                </tr>
              } */}

              {posSalesReportsList?.report !== undefined &&
                posSalesReportsList?.report?.map((r, i) => (
                  <>

              <tr key={i}>
                <td style={{color:"blue",backgroundColor: "transparent", border: "none",fontWeight:"600",fontSize:"1rem"}}>{r?.branchName}</td>
              </tr>
              {
                r?.data.map((k, i) => (
                  <tr style={{ height: "38px" }} key={i}>
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{convertDateFormat(k?.dateMillisec)}</td>
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{k?.shiftId}</td>
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{k?.orderNo}</td>
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{k?.customer}</td>
                    {/* {
                        paymentMethod &&
                        <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>
                        {k?.payment[0]?.type?.map((m,i)=>(
                          <span>
                          {i === k?.payment[0]?.type?.length - 1
                            ?m
                            :m + ","}
                        </span>
                        ))}
                      </td>
                    } */}
                   
                    {
                      category &&  CategoryList?.map((category) => (
                        <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>
                          {k.lines.find((line) => line.categoryId === category._id)
                            ? k.lines.find((line) => line.categoryId === category._id).amount?.toFixed(decimalPosition)
                            : 0?.toFixed(decimalPosition)}
                        </td>
                      ))
                    }
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{k?.totalStitchingRate?.toFixed(decimalPosition)}</td>
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{k?.discount?.toFixed(decimalPosition)}</td>
                  
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{k?.orderTotal?.toFixed(decimalPosition)}</td>
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{k?.deliveryDiscount?.toFixed(decimalPosition)}</td>

                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{k?.paymentTotal?.toFixed(decimalPosition)}</td>

                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }} className='table-data-box'>
                      {
                        k?.status?.toLowerCase()==="complete"?
                        <span className='post'>Complete</span>
                        :k?.status?.toLowerCase()==="pending"?
                        <span className='draft'>Pending</span>
                        :k?.status?.toLowerCase()==="printing"?
                        <span className='rfq'>Printing</span>
                        :k?.status?.toLowerCase()==="delivered"?
                        <span className='po'>Delivered</span>
                        : k?.status?.toLowerCase()==='orderreturned'?
                        <span className='cancel'>Order Returned</span>
                        :k?.status==='delivey Returned'?
                        <span className='cancel'>Delivery Returned</span>
                        :k?.status?.toLowerCase()==='return'?
                        <span className='sales-order'>Return</span>
                        :k?.status
                      }
                     
                      </td>
                  </tr>
               
                ))
              }  
              <tr>
                 <td colSpan="4" style={{textAlign:"right"}}>
                    <p style={{margin:"0 8px",fontSize:"0.9rem",fontWeight:600}}>Total</p>
                 </td>

                 {category &&
            CategoryList?.map((category) => (
              <td key={category._id}>
                {/* Calculate and display the grand total for each category */}
                {r.data.reduce((orderAcc, k) => {
                  const line = k.lines.find((line) => line.categoryId === category._id);
                  return orderAcc + (line ? line.amount : 0);
                }, 0)?.toFixed(decimalPosition)}
              </td>
            ))}
               
             
                 <td>{r?.subTotal?.stitchingRate?.toFixed(decimalPosition)}</td>
                 <td>{r?.subTotal?.discount?.toFixed(decimalPosition)}</td>
               
                 <td>{r?.subTotal?.orderTotal?.toFixed(decimalPosition)}</td>
                 <td>{r?.subTotal?.deliveryDiscount?.toFixed(decimalPosition)}</td>
                 <td>{r?.subTotal?.paidTotal?.toFixed(decimalPosition)}</td>

                 <td></td>
              </tr>
             
              </>
                ))
              }
            </tbody>



            <tfoot>
              <tr>
                <th colSpan="4">Total</th>
                {category &&
                  CategoryList?.map((category) => (
                    <th key={category._id}>
                      {/* Calculate and display the grand total for each category */}
                      {posSalesReportsList?.report?.reduce((acc, r) => {
                        return (
                          acc +
                          r.data.reduce((orderAcc, k) => {
                            const line = k.lines.find((line) => line.categoryId === category._id);
                            return orderAcc + (line ? line.amount : 0);
                          }, 0)
                        );
                      }, 0)?.toFixed(decimalPosition)}
                    </th>
                  ))}
                <th>{posSalesReportsList?.grandtotal?.netStitchingRate?.toFixed(decimalPosition)}</th>
                <th>{posSalesReportsList?.grandtotal?.netDiscount?.toFixed(decimalPosition)}</th>

                <th>{posSalesReportsList?.grandtotal?.netOrderTotal?.toFixed(decimalPosition)}</th>
                <th>{posSalesReportsList?.grandtotal?.netDeliveryDiscount?.toFixed(decimalPosition)}</th>
                <th>{posSalesReportsList?.grandtotal?.netPaidTotal?.toFixed(decimalPosition)}</th>

                <th></th>
              </tr>
            </tfoot>
          </table>

        </div>
      </div>
      <LoadingForm loading={isLoading} />
      <div style={{ display: "none" }}>
  <div ref={(el) => (paymentsPrintNew = el)}>
      <Template  data={data} formValues={formValues} CategoryList={CategoryList} category={category}/> 
  </div>
</div>
    </div>
  )
}
