import React from "react";
import InputBox from "../../../components/InputBox";
import VoucherTransactionPage from "../../../components/VoucherTransactionPage";
import TableBox from "../../../components/TableBox";
import TextAreaInputBox from "../../../components/TextAreaInputBox";
import DropdownInputBox from "../../../components/DropdownInputBox";

const AddRecurringPost = ({ headings, renderData }) => {
  const selectedVoucher = "Add Recurring Post";
  return (
    <div>
      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="accounting-voucher-menu-sections"
      >
        <h3>{selectedVoucher}</h3>

        <div className="accounting-payment-voucher-input-field">
          <InputBox label={"VCH No."} />
          <InputBox label={"Instance"} />
          <InputBox label={"Description"} />
          <InputBox label={"Date"} type={"date"} />
          <InputBox label={"Ref. No."} />
          <DropdownInputBox labelName={"Frequency"} />
          <InputBox label={"Next Execution"} type={"date"} />
          <InputBox label={"Valid Until"} />
          <TextAreaInputBox label={"Remark"} />
        </div>
      </div>
      <VoucherTransactionPage />
      <div className="accounting-voucher-menu-sections">
        <TableBox
          isLoading={false}
          renderData={renderData}
          headings={headings}
          isNo={true}
        />
        <div style={{ justifyContent: "flex-end" }} class="button-container">
          <div>
            <button>Cancel</button>
            <button>Add</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRecurringPost;
