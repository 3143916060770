import axios from "axios";
import { HEADERS } from "../../../../API/UrlAndPaths";
import store from "../../../../Redux/store";
import { get_config_api_res_list } from "./glConfigurationSlice";

// add gl config

export const setGlConfigAPICall=async(body,setState,state,setLoadingForm)=>{
    setLoadingForm(true)
    await axios.post("purchase/addglconfig",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                viewGlConfigAPICall()
                setState({...state,message:"GL Configuration Updated",success:true})
                setLoadingForm(false)
            }
        })
        .catch((err)=>{
            setLoadingForm(false)
            if(err.response.status===500){
                setState({...state,message:"Internal Server Error !!",error:true})
            }else{
                setState({...state,message:err?.response?.data,error:true})
            }
        })
}

// view config settings
export const viewGlConfigAPICall=async(setLoadingForm)=>{
    setLoadingForm!==undefined && setLoadingForm(true)
    await axios.post("purchase/viewglconfig",{},HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(get_config_api_res_list({resData:res.data}))
                localStorage.setItem("decimalPosition",res.data.accounts.decimalPoint)
                localStorage.setItem("decimalPositionStock",res.data.accounts.stockDecimalPoint)
                setLoadingForm!==undefined && setLoadingForm(false)
            }
        })
        .catch((err)=>{
            store.dispatch(get_config_api_res_list({resData:undefined}))
            setLoadingForm!==undefined && setLoadingForm(false)
        })
}