import React, { useEffect, useState } from "react";
import "../../../../css/Product Master/Pages/productMaster.css";
import { Select, MenuItem, IconButton, Dialog, Tooltip, TextField, Autocomplete } from "@mui/material";
import { FilePicker } from "../../../Single Components/filePicker";
import { AddCategoryPopup } from "../../../Single Components/AddCategoryPopup";
import { ColorPickerPopup } from "../../../Single Components/ColorPickerPopup";
import { SizeChart } from "../../../Single Components/SizeChart";
import {
  addAccessoryProductAPICall,
  addNeckStyleAPICall,
  addNewAccessoryCategoryAPICall,
  addNewAccessorySubCategoryAPICall,
  addNewColorAPICall,
  addNewFittingAPICall,
  addNewMaterialAPICall,
  addNewMaterialCategoryAPICall,
  addNewMaterialProductAPICall,
  addNewPatternAPICall,
  addNewProductCareAPICall,
  addNewReadymadeBrandAPICall,
  addNewSizeIntoSizeChartAPICall,
  addNewStyleAPICall,
  addNewUnitAPICall,
  addReadyMadeCategoryAPICall,
  addReadyMadeOccasionAPICall,
  addReadymadeProductAPICall,
  addReadymadeSubCategoyAPICall,
  deleteAccessoryCategoryAPICall,
  deleteMaterialCategoryAPICall,
  deleteReadymadeCategoryAPICall,
  generateProductIdAPICall,
  viewAllAccessoryCategoryAPICall,
  viewAllAccessorySubCategoryAPICall,
  viewAllColorsAPICall,
  viewAllFittingsAPICall,
  viewAllMaterialAPICall,
  viewAllMaterialCategoryAPICall,
  viewAllNeckStyleAPICall,
  viewAllPatternsAPICall,
  viewAllProductCareAPICall,
  viewAllReadymadeBrandAPICall,
  viewAllReadymadeCategoriesAPICall,
  viewAllReadymadeOccasionAPICall,
  viewAllReadymadeSubCategoryAPICall,
  viewAllStylesAPICall,
  viewAllUnitsAPICall,
  viewSizeChartAPICall,
} from "../../../../API/Product Master/ProductMasterAPI";
import { useSelector } from "react-redux";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import { today } from "../../../../Js/Date";
import CountryListWithCode from "../../../../Assets/JSON/countryListWithCode.json";
import ReturnPolicy from "../../../../Assets/JSON/returnPolicy.json";
import HSNC from "../../../../Assets/JSON/gstHsnCodes.json";
import {
  calculatePrice,
  validateFileSize,
} from "../../../../Js/generalFunctions";
import { getTaxAPICall } from "../../../../API/TaxAPI";
import { AutoAwesome } from "@mui/icons-material";
import { viewCompanyProfileInfoAPICall } from "../../../../API/Settings/Company Settings/companySettingsAPI";
import jwtDecode from "jwt-decode";
export const ProductMaster = () => {
  let country = localStorage.getItem("country");

  //Redux States Data
  // tax
  // const isTax=useSelector((state)=>state.isTax.value)
  // console.log(isTax);
  const TOKEN = localStorage.getItem("userToken");
  const isTax=jwtDecode(TOKEN)?.isTax
  //readymade category list
  const readyMadeCategoryList = useSelector(
    (state) => state.allReadymadeCategories.value
  );
  //readymade sub category list
  const readyMadeSubCategoryList = useSelector(
    (state) => state.allReadymadeSubCategorySlice.value
  );
  //Ready made occasion
  const readymadeOccasionList = useSelector(
    (state) => state.readymadeOccasionSlice.value
  );
  //Color List
  const colorList = useSelector((state) => state.colorListSlice.value);
  //Product ID
  const productId = useSelector((state) => state.productIdSlice.value);
  //All Brand List
  const allBrandList = useSelector((state) => state.readymadeBrandSlice.value);
  // All Fittings List
  const allFittingsList = useSelector((state) => state.allFittingsSlice.value);
  // All Product Care List
  const allProductCareList = useSelector(
    (state) => state.productCareSlice.value
  );
  // All Neck Style List
  const allNeckStyleList = useSelector((state) => state.neckStyleSlice.value);
  // All Units List
  const allUnitsList = useSelector((state) => state.unitsSlice.value);
  // All Readymade Styles List
  const allReadymadeStyleList = useSelector(
    (state) => state.readymadeStyleSlice.value
  );
  // All Pattern List
  const allPatternList = useSelector((state) => state.patternSlice.value);
  // All cloth Material List
  const allMaterialList = useSelector((state) => state.materialSlice.value);
  // All Material Category List
  const allMaterialCatList = useSelector(
    (state) => state.allMaterialCategorySlice.value
  );
  // All Accessory Category List
  const allAccessoryCatList = useSelector(
    (state) => state.allAccessoryCategorySlice.value
  );
  // All Accessory SubCategory List
  const allAccessorySubCatList = useSelector(
    (state) => state.allAccessorySubCategorySlice.value
  );
  // size Chart
  const viewSizeChartData = useSelector((state) => state.sizeChartSlice.value);
  //Get Tax
  const taxRate=useSelector((state)=>state.taxSlice.value)
  // view profile
  const companyProfileData = useSelector(
    (state) => state.companyProfileSlice.value
  );
  //Update Listener State
  const [isModify, setIsModify] = useState(false);
  //Current Country
  const [currentCountry, setCurrentCountry] = useState(
    country != null ? country : "0"
  );
  useEffect(() => {
    let country = localStorage.getItem("country");
    if (country !== null) {
      setCurrentCountry(country);
    }
  }, [isModify]);
  // console.log(currentCountry);
  const hsnFiltered=[...new Set(HSNC.map(item => item.hsn))]

  // Snackbar States
  const [successSnack, setSuccessSnack] = useState(false);
  const [errorSnack, setErrorSnack] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!");
  //Main Category Selection
  const [mainCategory, setMainCategory] = useState("2");
  // Retail wholesale type
  const [type, setType] = useState("retail");
  // size chart states
  const [brandSize, setBrandSize] = useState("brandSize");

  // popup states
  // readymade
  const [addCategoryPopup, setAddCategoryPopup] = useState(false);
  const [addSubCategoryPopup, setAddSubCategoryPopup] = useState(false);
  const [addOccasionPopup, setAddOccasionPopup] = useState(false);
  const [addBrandPopup, setAddBrandPopup] = useState(false);
  const [addFittingPopup, setAddFittingPopup] = useState(false);
  const [addProductCarePopup, setAddProductCarePopup] = useState(false);
  const [addNeckStylePopup, setAddNeckStylePopup] = useState(false);
  const [addUnitPopup, setAddUnitPopup] = useState(false);
  const [addStyleNamePopup, setStyleNamePopup] = useState(false);
  const [addPatternPopup, setAddPatternPopup] = useState(false);
  const [addMaterialPopup, setAddMaterialPopup] = useState(false);
  const [addColourPopup, setAddColourPopup] = useState(false);
  const [addSizeChart, setAddSizeChart] = useState(false);
  const [sizeChartWholesale, setSizeChartWholesale] = useState(false);
  //General Form
  const [addReturnPolicyPopup, setAddReturnPolicyPopup] = useState(false);
  // material
  const [addMaterialCatPopup, setAddMaterialCatPopup] = useState(false);
  // accessories
  const [accAddCategoryPopup, setAccAddCategoryPopup] = useState(false);
  const [accAddSubCategory, setAccAddSubCategory] = useState(false);
  //Selectable size chart
  const [openSelectableSizeChart, setOpenSelectableSizeChart] = useState();

  //Integration States
  //Readymade Gender
  const [selectedReadymadeGender, setSelectedReadymadeGender] = useState("0");
  //Add Readymade Category
  const [readymadeCatName, setReadymadeCatName] = useState("");
  const [selectedReadyMadeCatId, setSelectReadyMadeCatId] = useState("0");
  //Add Readymade SubCategory input
  const [readymadeSubCatName, setReadymadesubCatName] = useState("");
  const [selectedReadymadeSubCatId, setSelectedReadymadeSubCatId] =
    useState("0");
  // Add Occasion Input
  const [occasionName, setOccasionName] = useState("");
  const [selectedOccasion, setSelectedOccasion] = useState("0");
  //Add Color input
  const [newColorCode, setNewColorCode] = useState("#000000");
  const [newColorName, setNewColorName] = useState("");
  const [selectedReadymadeColor, setSelectedReadymadeColor] = useState("0");
  //Add brand
  const [newBrandName, setNewBrandName] = useState("");
  const [selectedBrandId, setSelectedBrandId] = useState("0");
  //Add Fitting
  const [newFittingName, setNewFittingName] = useState("");
  //Add Product Care
  const [newProductCareText, setNewProductCareText] = useState("");
  const [selectedProductCare, setSelectedProductCare] = useState("0");
  //Add Neck Style
  const [newNeckStyleName, setNewNeckStyleName] = useState("");
  //Add new Units
  const [newUnitName, setNewUnitName] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("0");
  //Add Style
  const [newStyleName, setNewStyleName] = useState("");
  //Add Pattern
  const [newPatternName, setNewPatternName] = useState("");
  const [selectedPattern, setSelectedPattern] = useState("0");
  //Add Material
  const [newMaterial, setNewMaterial] = useState("");
  const [selectedMaterial, setSelectedMaterial] = useState("0");

  //Material Composition
  const [materialComposition, setMaterialComposition] = useState("");

  //*Material Form Section
  //New Material Category
  const [newMaterialCatName, setNewMaterialCatName] = useState("");
  const [selectedMaterialCatId, setSelectedMaterialCatId] = useState("0");
  //Material Gender
  const [selectedMaterialGender, setSelectedMaterialGender] = useState("0");

  //Material Form
  const materialFormInitialState = {
    productName: "",
    productNameArabic: "",
    hsnCode: "0",
    hsCode: "0",
    tax: 0,
    releaseDate: today,
    qty: "",
    price: "",
    mrp: "",
    calculatedPrice: 0,
    materialComposition: "",
    reOrderOrWholesaleQty: "",
  };
  const [materialForm, setMaterialForm] = useState(materialFormInitialState);
  //*Accessories Form Section
  //Accessory category
  const [newAccessoryCatName, setNewAccessoryCatName] = useState("");
  const [selectedAccessoryCatId, setSelectedAccessoryCatId] = useState("0");
  //Accessory subcategory
  const [newAccessorySubCatName, setNewAccessorySubCatName] = useState("");
  const [selectedAccessorySubCatId, setSelectedAccessorySubCatId] =
    useState("0");
  const accessoryFormInitialState = {
    prodName: "",
    prodNameArabic: "",
    releaseDate: today,
    taxRate:0
  };
  const [accessoryForm, setAccessoryForm] = useState(accessoryFormInitialState);
  const [hsCodeFiltered,setHsCodeFiltered]=useState([])
  //Readymade Product Main Form
  const readymadeFormInitialState = {
    prodName: "",
    prodNameArabic: "",
    releaseDate: today,
    fitting: "0",
    neckStyle: "0",
    materialComposition: "",
    styleName: "0",
    taxRate:0
  };
  const [readymadeForm, setReadymadeForm] = useState(readymadeFormInitialState);
  //Product Id State
  const [productID, setProductID] = useState("####");

  //General form fields
  const [newReturnPolicy, setNewReturnPolicy] = useState("");
  const [selectedReturnPolicy, setSelectedReturnPolicy] = useState("0");
  const [additionalInfoArray, setAdditionalInfoArray] = useState([]);

  //HSN and HS Code
  const [hsnCode, setHsnCode] = useState("0");
  const [hsCode, setHsCode] = useState("0");

  const generalFormFieldsInitialState = {
    countryOfOrigin: currentCountry,
    returnPolicy: "0",
    description: "",
    additionalInfo: "",
    youtubeUrl: "",
  };
  const [generalFormFields, setGeneralFormFields] = useState(
    generalFormFieldsInitialState
  );
  //General form Images States
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [image4, setImage4] = useState(null);

  //Size Chart
  const sizeChartInitialData = {
    brand: "0",
    unit: "0",
    size: "0",
    reorderQtyOrMinimumQty: "",
    mrp: "",
    price: "",
    calculatedPrice: 0,
  };
  const [sizeChartForm, setSizeChartForm] = useState(sizeChartInitialData);

  //Select Size Chart states
  const [selectableSizeChart, setSelectableSizeChart] = useState([]);

  //Selected Size Chart
  const [selectedSizeChart, setSelectedSizeChart] = useState([]);
  //Size Selection Message
  const [sizeSelectionMsg, setSizeSelectionMsg] = useState("");
  //File size validation result
  const [fileSizeResult1, setFileSizeResult1] = useState(null);
  const [fileSizeResult2, setFileSizeResult2] = useState(null);
  const [fileSizeResult3, setFileSizeResult3] = useState(null);
  const [fileSizeResult4, setFileSizeResult4] = useState(null);

  //Check file size
  // useEffect(() => {
  //   if(image1 !== null );
  //   {
  //     let result = validateFileSize(image1, 1, 400);
  //     setFileSizeResult1(result)

  //   }
  // }, [image1]);
  // useEffect(() => {
  //   if(image2 !== null );
  //   {
  //     let result = validateFileSize(image2, 1, 400);
  //     setFileSizeResult2(result)
  //   }
  // }, [image2]);
  // useEffect(() => {
  //   if(image3 !== null );
  //   {
  //     let result = validateFileSize(image3, 1, 400);
  //     setFileSizeResult3(result)
  //   }
  // }, [image3]);
  // useEffect(() => {
  //   if(image4 !== null );
  //   {
  //     let result = validateFileSize(image4, 1, 400);
  //     setFileSizeResult4(result)
  //   }
  // }, [image4]);

  //*Tax Rate
  const [tax, setTax] = useState(0);
  //Close Success Snack Bar
  const closeSuccessSnackbar = () => {
    setSuccessSnack(false);
  };
  //Open Error Snack Bar
  const closeErrorSnackbar = () => {
    setErrorSnack(false);
  };

  //Get Readymade Gender
  const getReadymadeGender = (e) => {
    setSelectedReadymadeGender(e.target.value);
  };
  //Get Ready Made category Name
  const getReadyMadeCatName = (e) => {
    setReadymadeCatName(e.target.value);
  };
  //Get Selected ReadymadeCategory Id
  const getSelectedReadyMadeCategoryId = (e) => {
    setSelectReadyMadeCatId(e.target.value);
  };

  //Get Readymade SubCategory Name
  const getReadymadeSubCatName = (e) => {
    setReadymadesubCatName(e.target.value);
  };

  //Get Selected Readymade SubCategory Id
  const getSelectedReadymadeSubcatId = (e) => {
    setSelectedReadymadeSubCatId(e.target.value);
  };

  //Get Readymade Occasion Name
  const getReadymadeOccasionName = (e) => {
    setOccasionName(e.target.value);
  };
  //Get Selected Occasion Name
  const getSelectedReadymadeOccasionId = (e) => {
    setSelectedOccasion(e.target.value);
  };

  //Get Picked Color
  const getPickedColor = (e) => {
    setNewColorCode(e.target.value);
  };
  //get Picked Color Name
  const getPickedColorName = (e) => {
    setNewColorName(e.target.value);
  };

  //Get New Brand Name
  const getNewBrandName = (e) => {
    setNewBrandName(e.target.value);
  };

  //Get new Fitting Name
  const getNewFittingName = (e) => {
    setNewFittingName(e.target.value);
  };

  //Get Product Care Name
  const getNewProductCare = (e) => {
    setNewProductCareText(e.target.value);
  };

  //Get new NeckStyle Name
  const getNewNeckStyleName = (e) => {
    setNewNeckStyleName(e.target.value);
  };
  //Get new Unit Name
  const getNewUnitName = (e) => {
    setNewUnitName(e.target.value);
  };
  //Get new Style Name
  const getNewStyleName = (e) => {
    setNewStyleName(e.target.value);
  };
  //Get new Pattern Name
  const getNewPatternName = (e) => {
    setNewPatternName(e.target.value);
  };
  //Get new Material Name
  const getNewMaterialName = (e) => {
    setNewMaterial(e.target.value);
  };

  //* Material  onChange
  //Add Material Category
  const getMaterialCategoryName = (e) => {
    setNewMaterialCatName(e.target.value);
  };
  //Get Material Gender
  const getMaterialGender = (e) => {
    setSelectedMaterialGender(e.target.value);
  };
  //Get Material Category Id
  const getMaterialCategoryId = (e) => {
    setSelectedMaterialCatId(e.target.value);
  };
  //Get Material Form values
  const getMaterialFormData = (key) => (e,newValue) => {
    const { value } = e.target;
    if (key === "productName") {
      setMaterialForm({ ...materialForm, productName: value });
    }
    if (key === "productNameArabic") {
      setMaterialForm({ ...materialForm, productNameArabic: value });
    }
    if (key === "hsnCode") {
      setMaterialForm({ ...materialForm, hsnCode: newValue!==null?newValue :"0" });
    }
    if (key === "hsCode") {
      setMaterialForm({ ...materialForm, hsCode:newValue!==null? newValue?.hs :"0" });
    }
    if (key === "tax") {
      setMaterialForm({ ...materialForm, tax: value });
      getTaxAPICall({price:Number(materialForm.price),vatorgst:Number(value)})
    }
    if (key === "releaseDate") {
      setMaterialForm({ ...materialForm, releaseDate: value });
    }
    if (key === "qty") {
      setMaterialForm({ ...materialForm, qty: value });
    }
    if (key === "price") {
      // console.log('entered into price');
      // console.log(taxRate.value);
      setMaterialForm({ ...materialForm, price: value});
      getTaxAPICall({price:Number(value),vatorgst:Number(materialForm.tax)})


    }
    if (key === "mrp") {
      setMaterialForm({ ...materialForm, mrp: value });
    }
    if (key === "calculatedPrice") {
      setMaterialForm({ ...materialForm, calculatedPrice: value });
    }
    if (key === "materialComposition") {
      setMaterialForm({ ...materialForm, materialComposition: value });
    }
    if (key === "reOrderOrWholesaleQty") {
      setMaterialForm({ ...materialForm, reOrderOrWholesaleQty: value });
    }
  };

  //* Accessory  OnChange
  //Add Accessory Category
  const getAccessoryCategoryName = (e) => {
    setNewAccessoryCatName(e.target.value);
  };
  //Add Accessory SubCategory
  const getAccessorySubCategoryName = (e) => {
    setNewAccessorySubCatName(e.target.value);
  };

  //Get Accessories Category Id
  const getAccessorySelectedCategoryId = (e) => {
    setSelectedAccessoryCatId(e.target.value);
  };
  //Get Accessories SubCategory Id
  const getAccessorySelectedSubCategoryId = (e) => {
    setSelectedAccessorySubCatId(e.target.value);
  };

  //Get Accessory Form Data
  const getAccessoryFormData = (key) => (e) => {
    // const { value } = e.target.;
    if (key === "prodName") {
      setAccessoryForm({ ...accessoryForm, prodName: e.target.value });
    }
    if (key === "prodNameArabic") {
      setAccessoryForm({ ...accessoryForm, prodNameArabic: e.target.value });
    }
    if (key === "releaseDate") {
      setAccessoryForm({ ...accessoryForm, releaseDate: e.target.value });
    }
    if (key === "taxRate") {
      setAccessoryForm({ ...accessoryForm, taxRate: e.target.value });
    }
  };

  //*Readymade Main Form onchange fns
  //Get Readymade Form Data (ProdName,ProdNameArabic,ReleaseDate,Fitting,NeckStyle,StyleName)
  const getReadymadeFormData = (key) => (e) => {
    if (key === "prodName") {
      setReadymadeForm({ ...readymadeForm, prodName: e.target.value });
    }
    if (key === "prodNameArabic") {
      setReadymadeForm({ ...readymadeForm, prodNameArabic: e.target.value });
    }
    if (key === "releaseDate") {
      setReadymadeForm({ ...readymadeForm, releaseDate: e.target.value });
    }
    if (key === "fitting") {
      setReadymadeForm({ ...readymadeForm, fitting: e.target.value });
    }
    if (key === "neckStyle") {
      setReadymadeForm({ ...readymadeForm, neckStyle: e.target.value });
    }
    if (key === "styleName") {
      setReadymadeForm({ ...readymadeForm, styleName: e.target.value });
    }
    if (key === "taxRate") {
      setReadymadeForm({ ...readymadeForm, taxRate: e.target.value });
    }
  };
  //*Common States in All forms
  //Get Color Pk
  const getColor = (e) => {
    setSelectedReadymadeColor(e.target.value);
  };
  //Get brand ID
  const getBrandId = (e) => {
    setSelectedBrandId(e.target.value);
  };
  //get Product Care
  const getProductCareId = (e) => {
    setSelectedProductCare(e.target.value);
  };
  //Get Material Composition
  const getMaterialComposition = (e) => {
    setMaterialComposition(e.target.value);
  };
  //Get Unit
  const getUnitId = (e) => {
    setSelectedUnit(e.target.value);
  };
  //Get Pattern
  const getSelectedPatternId = (e) => {
    setSelectedPattern(e.target.value);
  };
  //Get Material
  const getSelectedMaterialId = (e) => {
    setSelectedMaterial(e.target.value);
  };
  //Get HSN Code
  const getHsnCode = (e) => {
    setHsnCode(e.target.value);
  };
  //Get HS Code
  const getHsCode = (e) => {
    setHsCode(e.target.value);
  };

  //*size chart onChange
  const getSizeChartData = (key) => (e) => {
    const { value } = e.target;
    if (key === "brand") {
      setSizeChartForm({ ...sizeChartForm, brand: value });
    }
    if (key === "unit") {
      setSizeChartForm({ ...sizeChartForm, unit: value });
    }
    if (key === "size") {
      setSizeChartForm({ ...sizeChartForm, size: value });
    }
    if (key === "reorderQtyOrMinimumQty") {
      setSizeChartForm({ ...sizeChartForm, reorderQtyOrMinimumQty: value });
    }
    if (key === "mrp") {
      setSizeChartForm({ ...sizeChartForm, mrp: value });
    }
    if (key === "price") {
      setSizeChartForm({ ...sizeChartForm, price: value,calculatedPrice:value });
    }
    if (key === "calculatedPrice") {
      setSizeChartForm({ ...sizeChartForm, calculatedPrice: value });
    }
  };
  //* General Form [Right Side]
  // Get Values from General Form
  const getGeneralFormData = (key) => (e) => {
    if (key === "countryOfOrigin") {
      setGeneralFormFields({
        ...generalFormFields,
        countryOfOrigin: e.target.value,
      });
    }
    if (key === "returnPolicy") {
      setGeneralFormFields({
        ...generalFormFields,
        returnPolicy: e.target.value,
      });
    }
    if (key === "description") {
      setGeneralFormFields({
        ...generalFormFields,
        description: e.target.value,
      });
    }
    if (key === "additionalInfo") {
      setGeneralFormFields({
        ...generalFormFields,
        additionalInfo: e.target.value,
      });
    }
    if (key === "youtubeUrl") {
      setGeneralFormFields({
        ...generalFormFields,
        youtubeUrl: e.target.value,
      });
    }
  };
  //Get New Return Policy Name
  const getNewReturnPolicyName = (e) => {
    setNewReturnPolicy(e.target.value);
  };

  //Get Image 1
  const getImage1 = (e) => {
    let file = e.target.files[0];
    let result = validateFileSize(file, 1, 400);

    if (result?.isValid) {
      setImage1(file);
    } else {
      alert("Select image with size less than 400 kb,current size "+result?.actualFileSize+" kb");
    }
  };
  //Get Image 2
  const getImage2 = (e) => {
    let file = e.target.files[0];
    let result = validateFileSize(file, 1, 400);

    if (result?.isValid) {
      setImage2(file);
    } else {
      alert("Select image with size less than 400 kb,current size "+result?.actualFileSize+" kb");
    }
  };
  //Get Image 3
  const getImage3 = (e) => {
    let file = e.target.files[0];
    let result = validateFileSize(file, 1, 400);

    if (result?.isValid) {
      setImage3(file);
    } else {
      alert("Select image with size less than 400 kb,current size "+result?.actualFileSize+" kb");
    }
  };
  //Get Image 4
  const getImage4 = (e) => {
    let file = e.target.files[0];
    let result = validateFileSize(file, 1, 400);

    if (result?.isValid) {
      setImage4(file);
    } else {
      alert("Select image with size less than 400 kb,current size "+result?.actualFileSize+" kb");
    }
  };

  //*Click functions

  //Click Readymade Add Category btn
  const addNewReadyMadeCategory = () => {
    //Call API
    if (readymadeCatName !== "" && readymadeCatName !== undefined) {
      addReadyMadeCategoryAPICall(
        {
          categoryName: readymadeCatName,
        },
        updateListener,
        setErrorSnack,
        setSuccessSnack,
        setSnackMsg,
        setReadymadeCatName
      );
    }
  };

  //Click Readymade Add SubCategory Btn
  const addNewReadyMadeSubCategory = () => {
    if (readymadeSubCatName !== "" && readymadeSubCatName !== undefined) {
      addReadymadeSubCategoyAPICall(
        {
          categoryId: selectedReadyMadeCatId,
          subCategoryName: readymadeSubCatName,
        },
        updateListener,
        setErrorSnack,
        setSuccessSnack,
        setSnackMsg,
        setReadymadesubCatName
      );
    }
  };
  //Click Readymade Add Occasion Name
  const addNewReadymadeOccasion = () => {
    addReadyMadeOccasionAPICall(
      {
        categoryId: selectedReadyMadeCatId,
        subCategoryId: selectedReadymadeSubCatId,
        occassionName: occasionName,
      },
      updateListener,
      setErrorSnack,
      setSuccessSnack,
      setSnackMsg,
      setOccasionName
    );
  };
  //Click popup Add Color btn
  const addNewColorPlusBtn = () => {
    addNewColorAPICall(
      { colorName: newColorName, colorCode: newColorCode },
      updateListener,
      setErrorSnack,
      setSuccessSnack,
      setSnackMsg,
      setNewColorName
    );
  };
  //Click Popup Add New Brand Btn
  const addNewBrandBtn = () => {
    addNewReadymadeBrandAPICall(
      { brandName: newBrandName },
      updateListener,
      setErrorSnack,
      setSuccessSnack,
      setSnackMsg,
      setNewBrandName
    );
  };
  //Click popup Add New Fitting
  const addNewFittingBtn = () => {
    addNewFittingAPICall(
      { fittingName: newFittingName },
      updateListener,
      setErrorSnack,
      setSuccessSnack,
      setSnackMsg,
      setNewFittingName
    );
  };
  //Click popup Add New Product Care
  const addNewProductCare = () => {
    addNewProductCareAPICall(
      { productCareName: newProductCareText },
      updateListener,
      setErrorSnack,
      setSuccessSnack,
      setSnackMsg,
      setNewProductCareText
    );
  };
  //Click popup add New Neck Style
  const addNewNeckStyle = () => {
    addNeckStyleAPICall(
      { neckStyleName: newNeckStyleName },
      updateListener,
      setErrorSnack,
      setSuccessSnack,
      setSnackMsg,
      setNewNeckStyleName
    );
  };
  //Click popup add New Unit Name
  const addNewUnit = () => {
    addNewUnitAPICall(
      { unitName: newUnitName },
      updateListener,
      setErrorSnack,
      setSuccessSnack,
      setSnackMsg,
      setNewUnitName
    );
  };
  //Click popup add New Style Name
  const addNewStyle = () => {
    addNewStyleAPICall(
      { styleName: newStyleName },
      updateListener,
      setErrorSnack,
      setSuccessSnack,
      setSnackMsg,
      setNewStyleName
    );
  };
  //Click popup add New Pattern Name
  const addNewPattern = () => {
    addNewPatternAPICall(
      { patternName: newPatternName },
      updateListener,
      setErrorSnack,
      setSuccessSnack,
      setSnackMsg,
      setNewPatternName
    );
  };
  //Click popup add New Material
  const addNewMaterial = () => {
    addNewMaterialAPICall(
      { clothMaterialName: newMaterial },
      updateListener,
      setErrorSnack,
      setSuccessSnack,
      setSnackMsg,
      setNewMaterial
    );
  };

  //*Add Material Category Click
  const addNewMaterialCategory = () => {
    addNewMaterialCategoryAPICall(
      { materialCategoryName: newMaterialCatName },
      updateListener,
      setErrorSnack,
      setSuccessSnack,
      setSnackMsg,
      setNewMaterialCatName
    );
  };

  //* Add Accessory Category Click
  //Add Accessory Category
  const addNewAccessoryCategory = () => {
    addNewAccessoryCategoryAPICall(
      { accessoryCategory: newAccessoryCatName },
      updateListener,
      setErrorSnack,
      setSuccessSnack,
      setSnackMsg,
      setNewAccessoryCatName
    );
  };
  //Add Accessory Sub Category
  const addNewAccessorySubCategory = () => {
    addNewAccessorySubCategoryAPICall(
      {
        accessoryCategoryId: selectedAccessoryCatId,
        accessorySubCategory: newAccessorySubCatName,
      },
      updateListener,
      setErrorSnack,
      setSuccessSnack,
      setSnackMsg,
      setNewAccessoryCatName
    );
  };
  //*Size chart
  const addNewSizeBody = {
    brandId: selectedBrandId,
    type: brandSize === "brandSize" ? true : false,
    brandOrUnitSize:
      brandSize === "brandSize" ? sizeChartForm.brand : sizeChartForm.unit,
    size: parseFloat(sizeChartForm.size),
    isRetail: type === "retail" ? true : false,
    minimumQty:
      type === "retail"
        ? null
        : parseFloat(sizeChartForm.reorderQtyOrMinimumQty),
    reorderQty: parseFloat(sizeChartForm.reorderQtyOrMinimumQty),
    mrp: parseFloat(sizeChartForm.mrp),
    price: parseFloat(sizeChartForm.price),
    calculatedPrice: parseFloat(sizeChartForm.calculatedPrice),
  };
  const addNewSizeBtnClick = () => {
    if (brandSize === "brandSize") {
      if (
        sizeChartForm.brand !== "0" &&
        sizeChartForm.reorderQtyOrMinimumQty !== ""
      ) {
        addNewSizeIntoSizeChartAPICall(
          addNewSizeBody,
          updateListener,
          setErrorSnack,
          setSuccessSnack,
          setSnackMsg,
          clearStates
        );
      } else {
        alert("Brand size and re order qty are mandatory!!");
      }
    } else if (brandSize === "unit") {
      if (
        sizeChartForm.unit !== "0" &&
        sizeChartForm.reorderQtyOrMinimumQty !== ""
      ) {
        addNewSizeIntoSizeChartAPICall(
          addNewSizeBody,
          updateListener,
          setErrorSnack,
          setSuccessSnack,
          setSnackMsg,
          clearStates
        );
      } else {
        alert("Unit and re order qty are mandatory!!");
      }
    }
  };

  //Size Chart Submit Button Click
  const sizeChartSubmitBtnClick = () => {
    // console.log(selectedSizeChart.length);
    if (selectedSizeChart.length > 0) {
      setSizeSelectionMsg("Size selected!!!");
      setOpenSelectableSizeChart(false);
    } else {
      alert("Select a size");
      setSizeSelectionMsg(" ");
    }
  };
  //sizeChart Cancel Button Click
  const sizeChartCancelBtnClick = () => {
    setSelectedSizeChart([]);
    setOpenSelectableSizeChart(false);
  };

  //*General Form Fields
  //Add return Policy Popup Click(temporarily commented that button 29/08/22)
  const addReturnPolicyBtnClick = () => {
    //TODO Call ADD Return POlicy API Call(this option is currently hidden)
  };

  //Click Add Additional Info btn
  const addAdditionalInfo = () => {
    setAdditionalInfoArray([
      ...additionalInfoArray,
      generalFormFields.additionalInfo,
    ]);
    setGeneralFormFields({ ...generalFormFields, additionalInfo: "" });
  };
  //Remove Info From Array
  const removeAdditionalInfoItem = (index) => () => {
    additionalInfoArray.splice(index, 1);
    setAdditionalInfoArray([...additionalInfoArray]);
  };

  //*Update Listener
  const updateListener = () => {
    setIsModify(!isModify);
  };

  //* Various Fn Definitions
  // map size chart response to new State
  const createSelectableSizeChart = () => {
    setSelectableSizeChart(
      viewSizeChartData?.map((obj, i) => ({
        ...obj,
        disabled: true,
        checked: false,
      }))
    );
  };
  //Size Row CheckBox Click FN
  const sizeRowCheckBoxClick = (index) => (e) => {
    selectableSizeChart.forEach((obj, i) => {
      if (i === index) {
        if (e.target.checked) {
          selectableSizeChart[i].checked = true;
          selectableSizeChart[i].disabled = false;

          setSelectedSizeChart([
            ...selectedSizeChart,
            {
              index: i,
              brandUnit: selectableSizeChart[i]?.brandOrUnitSize,
              size: selectableSizeChart[i]?.size,
              reorder: selectableSizeChart[i]?.reorderQty,
              mrp: selectableSizeChart[i]?.mrp,
              price: selectableSizeChart[i]?.price,
              calculatedPrice: selectableSizeChart[i]?.calculatedPrice,
            },
          ]);
        } else {
          selectedSizeChart?.splice(
            selectedSizeChart?.indexOf(
              selectedSizeChart?.filter((obj) => obj.index === i)
            ),
            1
          );
          setSelectedSizeChart([...selectedSizeChart]);
          selectableSizeChart[i].checked = false;
          selectableSizeChart[i].disabled = true;
        }
      }
    });
    setSelectableSizeChart([...selectableSizeChart]);
  };
  //OnChange Function for Mapped SizeChart Array
  const changeMrpAndPrice = (index, key) => (e) => {
    let selectedSizeChartIndex;
    const { value } = e.target;
    selectableSizeChart.forEach((obj, i) => {
      selectedSizeChartIndex = selectedSizeChart?.indexOf(
        selectedSizeChart?.filter((obj) => obj.index === i)[0]
      );
      // console.log(selectedSizeChartIndex);
      console.log(selectedSizeChart?.filter((obj) => obj.index === i));
      // console.log(selectedSizeChart);
      if (i === index) {
        if (key === "mrp") {
          if (value !== "") {
            selectableSizeChart[i].mrp = parseFloat(value);
            // selectedSizeChart[selectedSizeChartIndex].mrp=parseFloat(value);
          } else {
            selectableSizeChart[i].mrp = parseFloat(0);
            // selectedSizeChart[selectedSizeChartIndex].mrp=parseFloat(0);
          }
        }
        if (key === "price") {
          let calculatedPrice = 0;
          if (value !== "") {
            calculatedPrice = calculatePrice(value, tax);
            selectableSizeChart[i].price = parseFloat(value);
            selectableSizeChart[i].calculatedPrice =
              parseFloat(calculatedPrice);
            selectedSizeChart[selectedSizeChartIndex].price = parseFloat(value);
            selectedSizeChart[selectedSizeChartIndex].calculatedPrice =
              parseFloat(calculatedPrice);
            //Checking Mrp is larger than Calculated Price
            // if(calcuatedPrice>selectableSizeChart[i].mrp)
            // {
            //   selectableSizeChart[i].calculatedPrice= selectableSizeChart[i].mrp
            // }
            // else{
            //   selectableSizeChart[i].calculatedPrice=calcuatedPrice
            // }
          } else {
            selectableSizeChart[i].price = parseFloat(0);
            selectableSizeChart[i].calculatedPrice = calculatePrice(0, tax);
            selectedSizeChart[selectedSizeChartIndex].price = parseFloat(0);
            selectedSizeChart[selectedSizeChartIndex].calculatedPrice =
              parseFloat(0);
          }
        }
      }
    });
    setSelectableSizeChart([...selectableSizeChart]);
    setSelectedSizeChart([...selectedSizeChart]);
  };



  // console.log(selectableSizeChart);
  // console.log(selectedSizeChart);
  //*API Calls
  // useEffect(()=>{
  //   getTaxAPICall()
  // },[isModify])
  //Set tax rate to state
  useEffect(()=>{
    if(taxRate!==undefined)
    {
      const {calculatedPrice,tax}=taxRate
      setTax(parseFloat(tax))
    }
  },[taxRate])
  useEffect(() => {
    generateProductIdAPICall({ type: mainCategory });
  }, [mainCategory, isModify]);
  //Call Readymade Category API Call
  useEffect(() => {
    viewAllReadymadeCategoriesAPICall();
  }, [selectedReadyMadeCatId, isModify]);
  //Call Readymade sub Category API Call
  useEffect(() => {
    viewAllReadymadeSubCategoryAPICall();
  }, [selectedReadymadeSubCatId, isModify]);
  //Call Readymade Occasion API Call
  useEffect(() => {
    viewAllReadymadeOccasionAPICall();
  }, [selectedOccasion, isModify]);
  //Call View Color API
  useEffect(() => {
    viewAllColorsAPICall();
  }, [selectedReadymadeColor, isModify]);
  //Call View Brands API
  useEffect(() => {
    viewAllReadymadeBrandAPICall();
  }, [selectedBrandId, isModify]);
  //Call View Fittings API
  useEffect(() => {
    viewAllFittingsAPICall();
  }, [readymadeForm.fitting, isModify]);
  //Call View Product Care API
  useEffect(() => {
    viewAllProductCareAPICall();
  }, [selectedProductCare, isModify]);
  //Call View Neck Style API
  useEffect(() => {
    viewAllNeckStyleAPICall();
  }, [readymadeForm.neckStyle, isModify]);
  //Call Units API
  useEffect(() => {
    viewAllUnitsAPICall();
  }, [selectedUnit, isModify]);
  //Call ReadymadeStyle API
  useEffect(() => {
    viewAllStylesAPICall();
  }, [readymadeForm.styleName, isModify]);
  //Call Patterns API
  useEffect(() => {
    viewAllPatternsAPICall();
  }, [selectedPattern, isModify]);
  //Call View Materials API
  useEffect(() => {
    viewAllMaterialAPICall();
  }, [selectedMaterial, isModify]);

  //*Material Product Form Section
  //Call View Material Category API
  useEffect(() => {
    viewAllMaterialCategoryAPICall();
  }, [selectedMaterialCatId, isModify]);

  //*Accessory Product Form Section
  //Call View Accessory Category API
  useEffect(() => {
    viewAllAccessoryCategoryAPICall();
  }, [selectedAccessoryCatId, isModify]);
  //Call View Accessory SubCategory API
  useEffect(() => {
    if (selectedAccessoryCatId !== "0") {
      viewAllAccessorySubCategoryAPICall({
        accessoryCategoryId: selectedAccessoryCatId,
      });
    }
  }, [selectedAccessoryCatId, selectedAccessorySubCatId, isModify]);

  //*Size Chart
  //Call View Accessory SubCategory API
  useEffect(() => {
    if (selectedBrandId !== "0") {
      viewSizeChartAPICall({ brandId: selectedBrandId });
    }
  }, [selectedBrandId, isModify]);
  //Assign size chart Response to front end State
  useEffect(() => {
    setSelectableSizeChart([]);
    createSelectableSizeChart();
  }, [viewSizeChartData]);

  //Assign generated product ID
  useEffect(() => {
    setProductID(productId?.prodId);
  }, [productId]);

  //*Call CalculatePrice Fn in Material Case
  useEffect(() => {
    // let calculatedPrice = 0;
    // calculatedPrice = calculatePrice(materialForm.price, tax);
    // if (!isNaN(calculatedPrice)) {
    //   setMaterialForm({
    //     ...materialForm,
    //     calculatedPrice,
    //   });
    // } else {
    //   setMaterialForm({
    //     ...materialForm,
    //     calculatedPrice: 0,
    //   });
    // }

    setMaterialForm({...materialForm,calculatedPrice:taxRate?.value||0})
  }, [taxRate?.value]);

  //Get WholeSale or Retail From Radio
  const getCheckedType = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setType(value);
      // console.log(value);
    }
  };

  //Get Radio Values from Size Chart
  const getCheckedSizeChart = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setBrandSize(value);
      // console.log(value);
    }
  };

  //Clear States
  const clearStates = () => {
    setSizeChartForm(sizeChartInitialData);
  };

  // delete category
 const clickDeleteCategoryAdded=(key,id)=>()=>{
  if(key==="materialCat"){
    deleteMaterialCategoryAPICall({_id:id},
                                  updateListener,
                                  setSuccessSnack,
                                  setErrorSnack,
                                  setSnackMsg
                                  )
  }
  if(key==="readymadeCat"){
    deleteReadymadeCategoryAPICall({_id:id},
                                  updateListener,
                                  setSuccessSnack,
                                  setErrorSnack,
                                  setSnackMsg
                                  )
  }
  if(key==="accesoryCat"){
    deleteAccessoryCategoryAPICall({accessoryCategoryId:id},
                                  updateListener,
                                  setSuccessSnack,
                                  setErrorSnack,
                                  setSnackMsg
                                  )
  }
 }
  //* Create Material Product Form Data
  const materialFormData = new FormData();
  materialFormData.append("mainCategoryId", 2);
  materialFormData.append("genderCategoryId", selectedMaterialGender);
  materialFormData.append("categoryId", selectedMaterialCatId);
  materialFormData.append("productName", materialForm.productName);
  materialFormData.append("nativeproductName", materialForm.productNameArabic);
  materialFormData.append("hsnCode", materialForm.hsnCode);
  materialFormData.append("hsCode", materialForm.hsCode);
  materialFormData.append("vatorgst",materialForm.tax!=='' && parseFloat(materialForm.tax));
  materialFormData.append("releaseDate", materialForm.releaseDate);
  materialFormData.append("color", selectedReadymadeColor);
  materialFormData.append("brand", selectedBrandId);
  materialFormData.append("unit", selectedUnit);
  materialFormData.append("mrp", materialForm.mrp);
  materialFormData.append("price", materialForm.price);
  materialFormData.append("calculatedPrice", materialForm.calculatedPrice);
  materialFormData.append("productCare", selectedProductCare);
  materialFormData.append(
    "materialComposition",
    materialForm.materialComposition
  );
  materialFormData.append("pattern", selectedPattern);
  materialFormData.append("reorder", materialForm.reOrderOrWholesaleQty);
  materialFormData.append("returnPolicy", generalFormFields.returnPolicy);
  materialFormData.append("origin", generalFormFields.countryOfOrigin);
  materialFormData.append("description", generalFormFields.description);
  materialFormData.append(
    "additionalInfo",
    JSON.stringify(additionalInfoArray)
  );
  materialFormData.append("prodImg", image1);
  materialFormData.append("prodImg", image2);
  materialFormData.append("prodImg", image3);
  materialFormData.append("prodImg", image4);
  materialFormData.append("isRetail", type === "retail" ? true : false);
  // materialFormData.append("size", null);
  materialFormData.append("youtubeUrl", generalFormFields.youtubeUrl);
  // materialFormData.append("colorCode", null);

  //*Readymade Form Form Data
  const readymadeFormData = new FormData();
  readymadeFormData.append("mainCatgeoryId", 0);
  readymadeFormData.append("genderCategoryId", selectedReadymadeGender);
  readymadeFormData.append("categoryId", selectedReadyMadeCatId);
  readymadeFormData.append("subCategoryId", selectedReadymadeSubCatId);
  readymadeFormData.append("occassionId", selectedOccasion);
  readymadeFormData.append("productName", readymadeForm.prodName);
  readymadeFormData.append("nativeproductName", readymadeForm.prodNameArabic);
  readymadeFormData.append("releaseDate", readymadeForm.releaseDate);
  readymadeFormData.append("color", selectedReadymadeColor);
  readymadeFormData.append("brand", selectedBrandId);
  readymadeFormData.append("fitting", readymadeForm.fitting);
  readymadeFormData.append("productCare", selectedProductCare);
  readymadeFormData.append("neckStyle", readymadeForm.neckStyle);
  readymadeFormData.append("materialComposition", materialComposition);
  readymadeFormData.append("unit", selectedUnit);
  readymadeFormData.append("styleName", readymadeForm.styleName);
  readymadeFormData.append("pattern", selectedPattern);
  readymadeFormData.append("clothMaterial", selectedMaterial);
  readymadeFormData.append("returnPolicy", generalFormFields.returnPolicy);
  readymadeFormData.append("origin", generalFormFields.countryOfOrigin);
  readymadeFormData.append("description", generalFormFields.description);
  readymadeFormData.append("isRetail", type === "retail" ? true : false);
  readymadeFormData.append("hsnCode", hsnCode);
  readymadeFormData.append("hsCode", hsCode);
  readymadeFormData.append("vatorgst",readymadeForm.taxRate!=='' && parseFloat(readymadeForm.taxRate));
  readymadeFormData.append("prodImg", image1);
  readymadeFormData.append("prodImg", image2);
  readymadeFormData.append("prodImg", image3);
  readymadeFormData.append("prodImg", image4);
  readymadeFormData.append(
    "additionalInfo",
    JSON.stringify(additionalInfoArray)
  );
  readymadeFormData.append("size", JSON.stringify(selectedSizeChart)); //the size field is temp. it should be changed as per coding completes
  readymadeFormData.append("youtubeUrl", generalFormFields.youtubeUrl);

  
  //* Accessory Form Form Data
  const accessoryFormData = new FormData();
  accessoryFormData.append("mainCategoryId", 1);
  accessoryFormData.append("categoryId", selectedAccessoryCatId);
  accessoryFormData.append("subCategoryId", selectedAccessorySubCatId);
  accessoryFormData.append("productName", accessoryForm.prodName);
  accessoryFormData.append("nativeproductName", accessoryForm.prodNameArabic);
  // accessoryFormData.append("nativeproductName", accessoryForm.prodNameArabic);
  accessoryFormData.append("releaseDate", accessoryForm.releaseDate);
  accessoryFormData.append("color", selectedReadymadeColor);
  accessoryFormData.append("vatorgst",accessoryForm.taxRate!=='' && parseFloat(accessoryForm.taxRate));
  // accessoryFormData.append('size')
  accessoryFormData.append("unit", selectedUnit);
  // accessoryFormData.append('styleName',)
  accessoryFormData.append("material", selectedMaterial);
  accessoryFormData.append("brand", selectedBrandId);
  accessoryFormData.append("returnPolicy", generalFormFields.returnPolicy);
  accessoryFormData.append("origin", generalFormFields.countryOfOrigin);
  accessoryFormData.append("description", generalFormFields.description);
  accessoryFormData.append(
    "additionalInfo",
    JSON.stringify(additionalInfoArray)
  );
  accessoryFormData.append("isRetail", type === "retail" ? true : false);
  accessoryFormData.append("hsnCode", hsnCode);
  accessoryFormData.append("hsCode", hsCode);
  accessoryFormData.append("prodImg", image1);
  accessoryFormData.append("prodImg", image2);
  accessoryFormData.append("prodImg", image3);
  accessoryFormData.append("size", JSON.stringify(selectedSizeChart));
  accessoryFormData.append("youtubeUrl", generalFormFields.youtubeUrl);

  // console.log(accessoryForm);
  // console.log(accessoryFormData);
  //Material Product Final Form Submission FN
  const materialFormFinalSubmit = () => {
    if(selectedMaterialGender==='0')
    {
      alert('Select gender!!')
    }
    else if(selectedMaterialCatId==='0')
    {
      alert('Select category!!')
    }
    else if(materialForm.productName==='')
    {
      alert('Enter product name!!')
    }
    else if(selectedReadymadeColor==='0')
    {
      alert('Select colour!!')
    }
    else if(selectedUnit==='0')
    {
      alert('Select unit!!')
    }
    else if(materialForm.price==='')
    {
      alert('Enter Price!!')
    }
    else if(materialForm.reOrderOrWholesaleQty==='')
    {
      alert('Enter Minimum Reorder quantity!!')
    }
    else
    {
      addNewMaterialProductAPICall(
        materialFormData,
        updateListener,
        setErrorSnack,
        setSuccessSnack,
        setSnackMsg,
        clearMaterialForm
      );
    }



    // if (
    //   selectedMaterialGender !== "0" &&
    //   selectedMaterialCatId !== "0" &&
    //   materialForm.productName !== "" &&
    //   selectedReadymadeColor !== "0" &&
    //   selectedBrandId !== "0" &&
    //   selectedUnit !== "0" &&
    //   materialForm.price !== "" &&
    //   selectedProductCare !== "0" &&
    //   materialForm.materialComposition !== " " &&
    //   materialForm.pattern !== "0" &&
    //   selectedMaterial !== "0" &&
    //   materialForm.reOrderOrWholesaleQty !== ""
    // ) {
    //   addNewMaterialProductAPICall(
    //     materialFormData,
    //     updateListener,
    //     setErrorSnack,
    //     setSuccessSnack,
    //     setSnackMsg,
    //     clearMaterialForm
    //   );
    // } else {
    //   alert("Please fill all fields!!");
    // }
  };

  //Readymade Form Final Form Submission Fn
  const readymadeFormFinalSubmit = () => {
    if(selectedReadymadeGender==='0')
    {
      alert('Select gender!!')
    }
    else if(selectedReadyMadeCatId==='0')
    {
      alert('Select category!!')
    }
    else if(selectedReadymadeSubCatId==='0')
    {
      alert('Select sub category!!')
    }
    else if(selectedOccasion==='0')
    {
      alert('Select an occasion!!')
    }
    else if(readymadeForm.prodName==='')
    {
      alert('Enter product name!!')
    }
    else if(selectedReadymadeColor==='0')
    {
      alert('Select colour!!')
    }
    else if(selectedSizeChart.length===0)
    {
      alert('Select size!!')
    }
    else if(selectedUnit==='0')
    {
      alert('Select unit!!')
    }
    else
    {
      addReadymadeProductAPICall(
        readymadeFormData,
        updateListener,
        setErrorSnack,
        setSuccessSnack,
        setSnackMsg,
        clearReadymadeForm
      );
    }



    // if (
    //   selectedReadymadeGender !== "0" &&
    //   selectedReadyMadeCatId !== "0" &&
    //   selectedReadymadeSubCatId !== "0" &&
    //   selectedOccasion !== "0" &&
    //   readymadeForm.prodName !== "" &&
    //   selectedReadymadeColor !== "0" &&
    //   selectedBrandId !== "0" &&
    //   readymadeForm.fitting !== "0" &&
    //   selectedUnit !== "0" &&
    //   selectedProductCare !== "0" &&
    //   readymadeForm.neckStyle !== "0" &&
    //   materialComposition !== "" &&
    //   readymadeForm.styleName !== "0" &&
    //   selectedMaterial !== "0" &&
    //   selectedPattern !== "0"
    // ) {
    //   addReadymadeProductAPICall(
    //     readymadeFormData,
    //     updateListener,
    //     setErrorSnack,
    //     setSuccessSnack,
    //     setSnackMsg,
    //     clearReadymadeForm
    //   );
    // } else {
    //   alert("Please fill all fields!!");
    // }
  };
  //Accessory Form Final Form Submission Fn
  const accessoryFormFinalSubmit = () => {

    if(selectedAccessoryCatId==='0')
    {
      alert('Select category!!')
    }
    else if(selectedAccessorySubCatId==='0')
    {
      alert('Select sub category!!')
    }
    else if(accessoryForm.prodName==='')
    {
      alert('Enter product name!!')
    }
    else if(selectedSizeChart.length===0)
    {
      alert('Select size!!')
    }
    else if(selectedReadymadeColor==='0')
    {
      alert('Select colour!!')
    }
    else if(selectedUnit==='0')
    {
      alert('Select unit!!')
    }
    else
    {
      addAccessoryProductAPICall(
        accessoryFormData,
        updateListener,
        setErrorSnack,
        setSuccessSnack,
        setSnackMsg,
        clearAccessoryForm
      );
    }


    // if (
    //   selectedAccessoryCatId !=='0'&&
    //   selectedAccessorySubCatId !=='0'&&
    //   accessoryForm.prodName !== "" &&
    //   selectedReadymadeColor !== "0" &&
    //   selectedBrandId !== "0" &&
    //   selectedUnit !== "0" &&
    //   selectedMaterial !== "0"
    // ) {
    //   addAccessoryProductAPICall(
    //     accessoryFormData,
    //     updateListener,
    //     setErrorSnack,
    //     setSuccessSnack,
    //     setSnackMsg,
    //     clearAccessoryForm
    //   );
    // } else {
    //   alert("Please fill all fields!!");
    // }
  };

 
  //Clear Material form After Successful API Call
  const clearMaterialForm = () => {
    setMaterialForm(materialFormInitialState);
    setHsnCode("0");
    setHsCode("0");
    setSelectedReadymadeColor("0");
    setSelectedBrandId("0");
    setSelectedUnit("0");
    setSelectedProductCare("0");
    setSelectedPattern("0");
    setSelectedMaterial("0");
    setGeneralFormFields(generalFormFieldsInitialState);
    setImage1(null);
    setImage2(null);
    setImage3(null);
    setImage4(null);
  };

  //Clear Readymade Form After Successful API Call
  const clearReadymadeForm = () => {
    setReadymadeForm(readymadeFormInitialState);
    setHsnCode("0");
    setHsCode("0");
    setSelectedReadymadeColor("0");
    setSelectedProductCare("0");
    setSelectedBrandId("0");
    setMaterialComposition("");
    setSelectedUnit("0");
    setSelectedPattern("0");
    setSelectedMaterial("0");
    setGeneralFormFields(generalFormFieldsInitialState);
    setImage1(null);
    setImage2(null);
    setImage3(null);
    setImage4(null);
    setSelectedSizeChart([])
    setSizeSelectionMsg('')
  };

  //Clear Accessory Form After Successful API Call
  const clearAccessoryForm = () => {
    setAccessoryForm(accessoryFormInitialState);
    setHsnCode("0");
    setHsCode("0");
    setSelectedBrandId("0");
    setSelectedReadymadeColor("0");
    setSelectedUnit("0");
    setSelectedMaterial("0");
    setGeneralFormFields(generalFormFieldsInitialState);
    setImage1(null);
    setImage2(null);
    setImage3(null);
    setImage4(null);
    setSelectedSizeChart([])
    setSizeSelectionMsg('')
  };

  useEffect(()=>{
    let filteredArray=[]
    filteredArray=HSNC.filter((obj)=>
        obj.hsn===materialForm.hsnCode
    )
    setHsCodeFiltered(filteredArray)
  },[materialForm.hsnCode])
  useEffect(()=>{
    let filteredArray=[]
    filteredArray=HSNC.filter((obj)=>
        obj.hsn===hsnCode
    )
    setHsCodeFiltered(filteredArray)
  },[hsnCode])
  
useEffect(()=>{
  viewCompanyProfileInfoAPICall()
},[isModify])
  // console.log(companyProfileData);
  return (
    <div className="product-master-container">
      <div className="product-master-left-main-container">
        <div className="global-page-parent-container product-master-left-container">
          <div className="product-master-left-top-container">
            <p>Choose Category</p>
            <div className="global-radio-button">
              <input
                value="retail"
                type="radio"
                id="retail"
                name="categoryType"
                onChange={getCheckedType}
                defaultChecked
              />
              <label htmlFor="retail">Retail</label>
              <input
                value="wholesale"
                type="radio"
                id="wholesale"
                name="categoryType"
                onChange={getCheckedType}
              />
              <label htmlFor="wholesale">Wholesale</label>
            </div>
          </div>
          <div className="product-master-input-container">
            <div className="global-single-input product-master-input">
              <p>Main Category</p>
              <Select
                value={mainCategory}
                onChange={(e) => setMainCategory(e.target.value)}
              >
                <MenuItem value="2">Material</MenuItem>
                <MenuItem value="0">Readymade</MenuItem>
                <MenuItem value="1">Accessories</MenuItem>
              </Select>
            </div>

            {/* readymade */}
            {/* {mainCategory === "0" ? (
              <>
                <div className="global-single-input product-master-input">
                  <p>Gender</p>
                  <Select
                    value={selectedReadymadeGender}
                    onChange={getReadymadeGender}
                  >
                    <MenuItem value="0">-select a gender-</MenuItem>
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="boy">Boy</MenuItem>
                    <MenuItem value="girl">Girl</MenuItem>
                  </Select>
                </div>
                <div className="global-single-input product-master-input">
                  <p>Category</p>
                  <Select
                    value={selectedReadyMadeCatId}
                    onChange={getSelectedReadyMadeCategoryId}
                  >
                    <MenuItem value="0">-select category-</MenuItem>
                    {readyMadeCategoryList !== undefined ? (
                      readyMadeCategoryList?.map((item, i) => (
                        <MenuItem value={item?._id} key={item?._id}>
                          {item?.categoryName}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                  <p
                    className="suffix-button"
                    onClick={() => setAddCategoryPopup(true)}
                  >
                    Add Category
                  </p>
                </div>
                <div
                  className={
                    selectedReadyMadeCatId !== "0"
                      ? "global-single-input product-master-input"
                      : "global-single-input product-master-input disabled-input-container"
                  }
                >
                  <p>Sub Category</p>
                  <Select
                    value={selectedReadymadeSubCatId}
                    onChange={getSelectedReadymadeSubcatId}
                    disabled={selectedReadyMadeCatId !== "0" ? false : true}
                  >
                    <MenuItem value="0">-select sub category-</MenuItem>
                    {readyMadeSubCategoryList !== undefined ? (
                      readyMadeSubCategoryList
                        ?.slice(0)
                        .reverse()
                        ?.map((item, i) => {
                          if (item?.categoryId === selectedReadyMadeCatId) {
                            return (
                              <MenuItem value={item?._id} key={item?._id}>
                                {item?.subCategoryName}
                              </MenuItem>
                            );
                          }
                        })
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                  <p
                    className="suffix-button"
                    onClick={() => {
                      selectedReadyMadeCatId !== "0" &&
                        setAddSubCategoryPopup(true);
                    }}
                  >
                    Add Sub Category
                  </p>
                </div>
              </>
            ) : mainCategory === "2" ? (
              <>
                <div className="global-single-input product-master-input">
                  <p>Gender</p>
                  <Select
                    value={selectedMaterialGender}
                    onChange={getMaterialGender}
                  >
                    <MenuItem value="0">-select gender-</MenuItem>
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                  </Select>
                </div>
                <div className="global-single-input product-master-input">
                  <p>Category</p>
                  <Select
                    value={selectedMaterialCatId}
                    onChange={getMaterialCategoryId}
                  >
                    <MenuItem value="0">-select category-</MenuItem>
                    {allMaterialCatList
                      ?.slice(0)
                      ?.reverse()
                      ?.map((r, i) => (
                        <MenuItem key={i} value={r?._id}>
                          {r?.materialCategoryName}
                        </MenuItem>
                      ))}
                  </Select>
                  <p
                    className="suffix-button"
                    onClick={() => setAddMaterialCatPopup(true)}
                  >
                    Add Category
                  </p>
                </div>
              </>
            ) : mainCategory === "1" ? (
              <>
                <div className="global-single-input product-master-input">
                  <p>Category</p>
                  <Select
                    value={selectedAccessoryCatId}
                    onChange={getAccessorySelectedCategoryId}
                  >
                    <MenuItem value="0">-select category-</MenuItem>
                    {allAccessoryCatList
                      ?.slice(0)
                      ?.reverse()
                      ?.map((r, i) => (
                        <MenuItem key={i} value={r?._id}>
                          {r?.accessoryCategory}
                        </MenuItem>
                      ))}
                  </Select>
                  <p
                    className="suffix-button"
                    onClick={() => setAccAddCategoryPopup(true)}
                  >
                    Add Category
                  </p>
                </div>
                <div
                  className={
                    selectedAccessoryCatId !== "0"
                      ? "global-single-input product-master-input"
                      : "global-single-input product-master-input disabled-input-container"
                  }
                >
                  <p>Sub Category</p>
                  <Select
                    value={selectedAccessorySubCatId}
                    onChange={getAccessorySelectedSubCategoryId}
                    disabled={selectedAccessoryCatId !== "0" ? false : true}
                  >
                    <MenuItem value="0">-select subcategory-</MenuItem>
                    {allAccessorySubCatList
                      ?.slice(0)
                      ?.reverse()
                      ?.map((r, i) => (
                        <MenuItem key={i} value={r?._id}>
                          {r?.accessorySubCategory}
                        </MenuItem>
                      ))}
                  </Select>
                  <p
                    className="suffix-button"
                    onClick={() =>
                      selectedAccessoryCatId !== "0" &&
                      setAccAddSubCategory(true)
                    }
                  >
                    Add Sub Category
                  </p>
                </div>
              </>
            ) : undefined} */}
             <div className="global-single-input product-master-input auto-complete">
                <p>Gender</p>
                  <Autocomplete
                     sx={{width:"100%"}}
                     options={[""]}
                     getOptionLabel={(option)=>option}
                     renderInput={(params)=>(
                      <TextField {...params} placeholder="select Gender" />
                     )}
                     />
             </div>
             <div className="global-single-input product-master-input auto-complete">
                <p>Category</p>
                  <Autocomplete
                     sx={{width:"100%"}}
                     options={[""]}
                     getOptionLabel={(option)=>option}
                     renderInput={(params)=>(
                      <TextField {...params} placeholder="select Category" />
                     )}
                     />
                       <p
                    className="suffix-button"
                    onClick={() => setAddCategoryPopup(true)}
                  >
                    Add Category
                  </p>
             </div>
             <div className="global-single-input product-master-input auto-complete">
                <p>Sub Category</p>
                  <Autocomplete
                     sx={{width:"100%"}}
                     options={[""]}
                     getOptionLabel={(option)=>option}
                     renderInput={(params)=>(
                      <TextField {...params} placeholder="select Sub Category" />
                     )}
                     />
                      <p
                    className="suffix-button"
                    onClick={() => {
                        setAddSubCategoryPopup(true);
                    }}
                  >
                    Add Sub Category
                  </p>
                     
             </div>
             <div className="global-single-input product-master-input auto-complete">
                <p>Occasion</p>
                  <Autocomplete
                     sx={{width:"100%"}}
                     options={[""]}
                     getOptionLabel={(option)=>option}
                     renderInput={(params)=>(
                      <TextField {...params} placeholder="select Occasion" />
                     )}
                     />
                      <p
                     className="suffix-button"
                     onClick={() => {
                        setAddOccasionPopup(true);
                     }}
                  >
                    Add Occasion
                  </p>
             </div>
               {/* <div
                  className={
                    // selectedReadyMadeCatId !== "0" &&
                    // selectedReadymadeSubCatId !== "0"
                      // ? 
                      "global-single-input product-master-input"
                      // : "global-single-input product-master-input disabled-input-container"
                  }
                >
                  <p>Occasion</p>
                  <Select
                    value={selectedOccasion}
                    onChange={getSelectedReadymadeOccasionId}
                    // disabled={
                    //   selectedReadyMadeCatId !== "0" &&
                    //   selectedReadymadeSubCatId !== "0"
                    //     ? false
                    //     : true
                    // }
                  >
                    <MenuItem value="0">-select an occasion-</MenuItem>
                    {readymadeOccasionList
                      ?.slice(0)
                      ?.reverse()
                      ?.map((item, i) => {
                        if (
                          item?.subCategoryId === selectedReadymadeSubCatId &&
                          item?.categoryId === selectedReadyMadeCatId
                        ) {
                          return (
                            <MenuItem value={item?._id} key={item?._id}>
                              {item?.occassionName}
                            </MenuItem>
                          );
                        }
                      })}
                  </Select>
                  <p
                    className="suffix-button"
                    onClick={() => {
                      selectedReadyMadeCatId !== "0" &&
                        selectedReadymadeSubCatId !== "0" &&
                        setAddOccasionPopup(true);
                    }}
                  >
                    Add Occasion
                  </p>
                </div> */}
            <div className="global-single-input product-master-input">
               <p>Product Type</p>
               <div className="product-master-product-type">
                  <div className="product-master-product-type-radio-grp">
                     <div className="product-master-product-type-radio-grp-single">
                      <input type="radio" id="Storable" name="productType" />
                      <label htmlFor="Storable">Storable</label>
                     </div>
                     <div className="product-master-product-type-radio-grp-single">
                      <input type="radio" id="Service" name="productType"/>
                      <label htmlFor="Service">Service</label>
                     </div>
                  </div>
                  <div className="product-master-product-type-radio-grp-single">
                      <input type="radio" id="Consumables" name="productType"/>
                      <label htmlFor="Consumables">Consumables</label>
                  </div>
               </div>
            </div>
          </div>
        </div>
        {/* <div className="product-master-submit-btn">
        <button className="btn btn-secondary">Submit</button>
        </div> */}

        <div className="global-page-parent-container product-master-left-second-container">
          <p className="product-info-head">Product Information</p>
          {mainCategory === "0" ? (
            <div className="product-master-category-filter-input">
              <div className="global-single-input product-master-input">
                <p>Product ID</p>
                <input disabled placeholder="PROD1" value={productID} />
              </div>
              <div className="global-single-input product-master-input">
                <p>Product Name</p>
                <input
                  value={readymadeForm.prodName}
                  placeholder="Enter product name..."
                  onChange={getReadymadeFormData("prodName")}
                />
              </div>
              {currentCountry !== "india" && (
                <div className="global-single-input product-master-input arabic-name arabic">
                  <p>اسم المنتج</p>
                  <input
                    value={readymadeForm.prodNameArabic}
                    placeholder="أدخل اسم المنتج ..."
                    onChange={getReadymadeFormData("prodNameArabic")}
                  />
                </div>
              )}

               <div className="global-single-input product-master-input auto-complete">
                    <p>UOM</p>
                    <Autocomplete
                     sx={{width:"100%"}}
                     options={[""]}
                     getOptionLabel={(option)=>option}
                     renderInput={(params)=>(
                      <TextField {...params} placeholder="select" />
                     )}
                    />
                  </div>
                  <div className="global-single-input product-master-input auto-complete">
                    <p>Purchase UOM</p>
                    <Autocomplete
                     sx={{width:"100%"}}
                     options={[""]}
                     getOptionLabel={(option)=>option}
                     renderInput={(params)=>(
                      <TextField {...params} placeholder="select" />
                     )}
                    />
                  </div>
                  <div className="global-single-input product-master-input auto-complete">
                    <p>Vendor Tax</p>
                    <Autocomplete
                     sx={{width:"100%"}}
                     options={[""]}
                     getOptionLabel={(option)=>option}
                     renderInput={(params)=>(
                      <TextField {...params} placeholder="select" />
                     )}
                    />
                  </div>
                  <div className="global-single-input product-master-input auto-complete">
                    <p>Customer Tax</p>
                    <Autocomplete
                     sx={{width:"100%"}}
                     options={[""]}
                     getOptionLabel={(option)=>option}
                     renderInput={(params)=>(
                      <TextField {...params} placeholder="select" />
                     )}
                    />
                  </div>
                  <div className="global-single-input product-master-input">
                <p>Cost</p>
                <input
                  type="text"
                />
              </div>
              <div className="global-single-input product-master-input">
                <p>Sales Price</p>
                <input
                  type="text"
                />
              </div>
                

              {currentCountry === "india" && (
                <>
                  <div className="global-single-input product-master-input auto-complete">
                    <p>HSN Code</p>
                    {/* <Select value={hsnCode} onChange={getHsnCode}>
                      <MenuItem value="0">-select hsn code-</MenuItem>
                      {HSNC?.map((item, i) => (
                        <MenuItem key={i} value={item?.hsn}>
                          {item?.hsn}
                        </MenuItem>
                      ))}
                    </Select> */}
                    <Autocomplete
                     sx={{width:"100%"}}
                     options={hsnFiltered}
                     getOptionLabel={(option)=>option}
                     renderInput={(params)=>(
                      <TextField {...params} placeholder="select hsn code"/>
                     )}
                     onChange={(e,newValue)=>{
                       if(newValue===null){
                        setHsnCode("0")
                       }
                       else{
                         setHsnCode(newValue)
                       }
                    }}
                    />
                  </div>
                  <div className="global-single-input product-master-input auto-complete">
                    <p>HS Code</p>
                    <Autocomplete
                     sx={{width:"100%"}}
                     options={hsCodeFiltered}
                     getOptionLabel={(option)=>option?.hs}
                     renderInput={(params)=>(
                      <TextField {...params} placeholder="select hs code" />
                     )}
                     onChange={(e,newValue)=>{
                      if(newValue===null){
                        setHsCode("0")
                      }
                      else{
                        setHsCode(newValue?.hs)
                      }
                     }}
                    />
                  </div>
                </>
              )}
              {/* {
                isTax === true&&
                <div className="global-single-input product-master-input">
                <p>{currentCountry === "india" ? "GST" : "VAT"}</p>
                <input type="text" placeholder="Enter tax in % ..." value={readymadeForm.taxRate} onChange={getReadymadeFormData("taxRate")}/>
              </div>
            
              } */}
                <div className="global-single-input product-master-input">
                <p>Colour</p>
                <Select value={selectedReadymadeColor} onChange={getColor}>
                  <MenuItem value="0">-select colour-</MenuItem>
                  {colorList
                    ?.slice(0)
                    ?.reverse()
                    ?.map((color, i) => (
                      <MenuItem
                        value={color?._id}
                        key={color?._id}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {color?.colorName}
                        <div
                          style={{
                            backgroundColor: color?.colorCode,
                            width: "15px",
                            height: "15px",
                            marginLeft: "4px",
                            borderRadius: "4px",
                          }}
                        ></div>
                      </MenuItem>
                    ))}
                </Select>
                <p
                  className="suffix-button"
                  onClick={() => setAddColourPopup(true)}
                >
                  Add Colour
                </p>
              </div>
              <div className="global-single-input product-master-input">
                <p>Release Date</p>
                <input
                  type="date"
                  value={readymadeForm.releaseDate}
                  onChange={getReadymadeFormData("releaseDate")}
                />
              </div>
           
              <div className="global-single-input product-master-input">
                <p>Brand</p>
                <Select value={selectedBrandId} onChange={getBrandId}>
                  <MenuItem value="0">-select brand-</MenuItem>
                  {allBrandList
                    ?.slice(0)
                    ?.reverse()
                    ?.map((brand, i) => (
                      <MenuItem key={i} value={brand?._id}>
                        {brand?.brandName}
                      </MenuItem>
                    ))}
                </Select>
                <p
                  className="suffix-button"
                  onClick={() => setAddBrandPopup(true)}
                >
                  Add Brand
                </p>
              </div>
              <div
                className={
                  selectedBrandId !== "0"
                    ? "global-single-input product-master-input"
                    : "global-single-input product-master-input disabled-input-container"
                }
              >
                <p>Size</p>
                <input
                  value={sizeSelectionMsg}
                  placeholder={
                    selectedBrandId !== "0"
                      ? "Click to select size"
                      : "Select a Brand first"
                  }
                  disabled={selectedBrandId !== "0" ? false : true}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setOpenSelectableSizeChart(true);
                  }}
                />
                <p
                  className="suffix-button"
                  onClick={() => {
                    if (selectedBrandId !== "0") {
                      if (type === "retail") {
                        setAddSizeChart(true);
                      } else if (type === "wholesale") {
                        setSizeChartWholesale(true);
                      }
                    }
                  }}
                >
                  View Size Chart
                </p>
              </div>
              <div className="global-single-input product-master-input">
                <p>Fitting</p>
                <Select
                  value={readymadeForm.fitting}
                  onChange={getReadymadeFormData("fitting")}
                >
                  <MenuItem value="0">-select fitting-</MenuItem>
                  {allFittingsList
                    ?.slice(0)
                    ?.reverse()
                    ?.map((r, i) => (
                      <MenuItem key={i} value={r?._id}>
                        {r?.fittingName}
                      </MenuItem>
                    ))}
                </Select>
                <p
                  className="suffix-button"
                  onClick={() => setAddFittingPopup(true)}
                >
                  Add Fitting
                </p>
              </div>
              <div className="global-single-input product-master-input">
                <p>Product Care</p>
                <Select value={selectedProductCare} onChange={getProductCareId}>
                  <MenuItem value="0">-select product care-</MenuItem>
                  {allProductCareList
                    ?.slice(0)
                    ?.reverse()
                    ?.map((r, i) => (
                      <MenuItem key={i} value={r?._id}>
                        {r?.productCareName}
                      </MenuItem>
                    ))}
                </Select>
                <p
                  className="suffix-button"
                  onClick={() => setAddProductCarePopup(true)}
                >
                  Add Product Care
                </p>
              </div>
              <div className="global-single-input product-master-input">
                <p>Neck Style</p>
                <Select
                  value={readymadeForm.neckStyle}
                  onChange={getReadymadeFormData("neckStyle")}
                >
                  <MenuItem value="0">-select neck style-</MenuItem>
                  {allNeckStyleList
                    ?.slice(0)
                    ?.reverse()
                    ?.map((r, i) => (
                      <MenuItem key={i} value={r?._id}>
                        {r?.neckStyleName}
                      </MenuItem>
                    ))}
                </Select>
                <p
                  className="suffix-button"
                  onClick={() => setAddNeckStylePopup(true)}
                >
                  Add Neck Style
                </p>
              </div>
              <div className="global-single-input product-master-input">
                <p>Material Composition</p>
                <input
                  value={materialComposition}
                  placeholder="Enter material composition..."
                  onChange={getMaterialComposition}
                />
              </div>
          
              {/* <div className="global-single-input product-master-input">
                <p>Unit</p>
                <Select value={selectedUnit} onChange={getUnitId}>
                  <MenuItem value="0">-select unit-</MenuItem>
                  {allUnitsList
                    ?.slice(0)
                    ?.reverse()
                    ?.map((r, i) => (
                      <MenuItem key={i} value={r?._id}>
                        {r?.unitName}
                      </MenuItem>
                    ))}
                </Select>
                <p
                  className="suffix-button"
                  onClick={() => setAddUnitPopup(true)}
                >
                  Add Unit
                </p>
              </div> */}
              <div className="global-single-input product-master-input">
                <p>Style Name</p>
                <Select
                  value={readymadeForm.styleName}
                  onChange={getReadymadeFormData("styleName")}
                >
                  <MenuItem value="0">-select style name-</MenuItem>
                  {allReadymadeStyleList
                    ?.slice(0)
                    ?.reverse()
                    ?.map((r, i) => (
                      <MenuItem key={i} value={r?._id}>
                        {r?.styleName}
                      </MenuItem>
                    ))}
                </Select>
                <p
                  className="suffix-button"
                  onClick={() => setStyleNamePopup(true)}
                >
                  Add Style Name
                </p>
              </div>
              <div className="global-single-input product-master-input">
                <p>Pattern</p>
                <Select value={selectedPattern} onChange={getSelectedPatternId}>
                  <MenuItem value="0">-select pattern-</MenuItem>
                  {allPatternList
                    ?.slice(0)
                    ?.reverse()
                    ?.map((r, i) => (
                      <MenuItem key={i} value={r?._id}>
                        {r?.patternName}
                      </MenuItem>
                    ))}
                </Select>
                <p
                  className="suffix-button"
                  onClick={() => setAddPatternPopup(true)}
                >
                  Add Pattern
                </p>
              </div>
              <div className="global-single-input product-master-input">
                <p>Material</p>
                <Select
                  value={selectedMaterial}
                  onChange={getSelectedMaterialId}
                >
                  <MenuItem value="0">-select material-</MenuItem>
                  {allMaterialList
                    ?.slice(0)
                    ?.reverse()
                    ?.map((r, i) => (
                      <MenuItem key={i} value={r?._id}>
                        {r?.clothMaterialName}
                      </MenuItem>
                    ))}
                </Select>
                <p
                  className="suffix-button"
                  onClick={() => setAddMaterialPopup(true)}
                >
                  Add Material
                </p>
              </div>
              {/* <div className="global-single-input product-master-input">
              <p>Re Order</p>
              <Select></Select>
              <p className="suffix-button">Add Reorder</p>
           </div> */}
            </div>
          ) : mainCategory === "2" ? (
            <div className="product-master-category-filter-input">
              <div className="global-single-input product-master-input">
                <p>Product ID</p>
                <input disabled placeholder="PROD1" value={productID} />
              </div>
              <div className="global-single-input product-master-input">
                <p>Product Name</p>
                <input
                  placeholder="Enter product name..."
                  value={materialForm.productName}
                  onChange={getMaterialFormData("productName")}
                />
              </div>
              {currentCountry !== "india" && (
                <div className="global-single-input product-master-input arabic-name arabic">
                  <p>اسم المنتج</p>
                  <input
                    placeholder="أدخل اسم المنتج ..."
                    value={materialForm.productNameArabic}
                    onChange={getMaterialFormData("productNameArabic")}
                  />
                </div>
                
              )}
                <div className="global-single-input product-master-input auto-complete">
                    <p>UOM</p>
                      <Autocomplete
                     sx={{width:"100%"}}
                     options={[""]}
                     getOptionLabel={(option)=>option}
                     renderInput={(params)=>(
                      <TextField {...params} placeholder="select hsn code"/>
                     )}
                    />
                  </div>
                  <div className="global-single-input product-master-input auto-complete">
                    <p>Purchase UOM</p>
                      <Autocomplete
                     sx={{width:"100%"}}
                     options={[""]}
                     getOptionLabel={(option)=>option}
                     renderInput={(params)=>(
                      <TextField {...params} placeholder="select hsn code"/>
                     )}
                    />
                  </div>
                  <div className="global-single-input product-master-input auto-complete">
                    <p>Vendor Tax</p>
                      <Autocomplete
                     sx={{width:"100%"}}
                     options={[""]}
                     getOptionLabel={(option)=>option}
                     renderInput={(params)=>(
                      <TextField {...params} placeholder="select hsn code"/>
                     )}
                    />
                  </div>
                  <div className="global-single-input product-master-input auto-complete">
                    <p>Customer Tax</p>
                      <Autocomplete
                     sx={{width:"100%"}}
                     options={[""]}
                     getOptionLabel={(option)=>option}
                     renderInput={(params)=>(
                      <TextField {...params} placeholder="select hsn code"/>
                     )}
                    />
                  </div>
                  <div className="global-single-input product-master-input">
                <p>Cost</p>
                <input
                  type="text"
                  placeholder="Enter"
              
                />
              </div>
              <div className="global-single-input product-master-input">
                <p>Sales Price</p>
                <input
                  type="text"
                  placeholder="Enter"
              
                />
              </div>
              {currentCountry === "india" ? (
                <>
                  <div className="global-single-input product-master-input auto-complete">
                    <p>HSN Code</p>
                    {/* <Select
                      value={materialForm.hsnCode}
                      onChange={getMaterialFormData("hsnCode")}
                    >
                      <MenuItem value="0">-select hsn code-</MenuItem>
                      {HSNC?.map((item, i) => (
                        <MenuItem key={i} value={item?.hsn}>
                          {item?.hsn}
                        </MenuItem>
                      ))}
                    </Select> */}
                      <Autocomplete
                     sx={{width:"100%"}}
                     options={hsnFiltered}
                     getOptionLabel={(option)=>option}
                     renderInput={(params)=>(
                      <TextField {...params} placeholder="select hsn code"/>
                     )}
                     onChange={getMaterialFormData("hsnCode")}
                    />
                  </div>
                  <div className="global-single-input product-master-input auto-complete">
                    <p>HS Code</p>
                    {/* <Select
                      value={materialForm.hsCode}
                      onChange={getMaterialFormData("hsCode")}
                    >
                      <MenuItem value="0">-select hs code-</MenuItem>
                      <MenuItem value={500300124}>500300124</MenuItem>
                      <MenuItem value={500445784}>500445784</MenuItem>
                    </Select> */}
                    <Autocomplete
                     sx={{width:"100%"}}
                     options={hsCodeFiltered}
                     getOptionLabel={(option)=>option?.hs}
                     renderInput={(params)=>(
                      <TextField {...params} placeholder="select hs code" />
                     )}
                     onChange={getMaterialFormData("hsCode")}
                    />
                  </div>
                </>
              ) : undefined}
              {
                isTax === true &&
                <div className="global-single-input product-master-input">
                <p>{currentCountry === "india" ? "GST" : "VAT"}</p>
                <input
                  type="text"
                  placeholder="Enter tax in % ..."
                  value={materialForm.tax}
                  onChange={getMaterialFormData("tax")}
                />
              </div>
              }
             
              <div className="global-single-input product-master-input">
                <p>Release Date</p>
                <input
                  type="date"
                  value={materialForm.releaseDate}
                  onChange={getMaterialFormData("releaseDate")}
                />
              </div>
              <div className="global-single-input product-master-input">
                <p>Colour</p>
                <Select value={selectedReadymadeColor} onChange={getColor}>
                  <MenuItem value="0">-select colour-</MenuItem>
                  {colorList
                    ?.slice(0)
                    ?.reverse()
                    ?.map((color, i) => (
                      <MenuItem
                        value={color?._id}
                        key={color?._id}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {color?.colorName}
                        <div
                          style={{
                            backgroundColor: color?.colorCode,
                            width: "15px",
                            height: "15px",
                            marginLeft: "4px",
                            borderRadius: "4px",
                          }}
                        ></div>
                      </MenuItem>
                    ))}
                </Select>
                <p
                  className="suffix-button"
                  onClick={() => setAddColourPopup(true)}
                >
                  Add Colour
                </p>
              </div>
              <div className="global-single-input product-master-input">
                <p>Brand</p>
                <Select value={selectedBrandId} onChange={getBrandId}>
                  <MenuItem value="0">-select brand-</MenuItem>
                  {allBrandList
                    ?.slice(0)
                    ?.reverse()
                    ?.map((brand, i) => (
                      <MenuItem key={i} value={brand?._id}>
                        {brand?.brandName}
                      </MenuItem>
                    ))}
                </Select>
                <p
                  className="suffix-button"
                  onClick={() => setAddBrandPopup(true)}
                >
                  Add Brand
                </p>
              </div>
               <div className="global-single-input product-master-input">
                <p>Quantity</p>
                <input
                  placeholder="Enter quantity..."
                  value={materialForm.qty}
                  onChange={getMaterialFormData("qty")}
                />
              </div> 
              {/* <div className="global-single-input product-master-input">
                <p>Unit</p>
                <Select value={selectedUnit} onChange={getUnitId}>
                  <MenuItem value="0">-select unit-</MenuItem>
                  {allUnitsList
                    ?.slice(0)
                    ?.reverse()
                    ?.map((r, i) => (
                      <MenuItem key={i} value={r?._id}>
                        {r?.unitName}
                      </MenuItem>
                    ))}
                </Select>
                <p
                  className="suffix-button"
                  onClick={() => setAddUnitPopup(true)}
                >
                  Add Unit
                </p>
              </div> */}
              {/* <div className="global-single-input product-master-input">
                <p>Price</p>
                <input
                  placeholder="Enter price..."
                  value={materialForm.price}
                  onChange={getMaterialFormData("price")}
                />
              </div> */}
              {/* {currentCountry === "india" && (
                <div className="global-single-input product-master-input">
                  <p>MRP</p>
                  <input
                    placeholder="Enter MRP..."
                    value={materialForm.mrp}
                    onChange={getMaterialFormData("mrp")}
                  />
                </div>
              )} */}
              {/* <div className="global-single-input product-master-input">
                <p>Calculated Price</p>
                <input
                  value={materialForm.calculatedPrice}
                  onChange={getMaterialFormData("calculatedPrice")}
                  disabled
                />
              </div> */}
              
              <div className="global-single-input product-master-input">
                <p>Material Composition</p>
                <input
                  placeholder="Enter material composition details..."
                  value={materialForm.materialComposition}
                  onChange={getMaterialFormData("materialComposition")}
                />
              </div>
              <div className="global-single-input product-master-input">
                <p>Product Care</p>
                <Select value={selectedProductCare} onChange={getProductCareId}>
                  <MenuItem value="0">-select product care-</MenuItem>
                  {allProductCareList
                    ?.slice(0)
                    ?.reverse()
                    ?.map((r, i) => (
                      <MenuItem key={i} value={r?._id}>
                        {r?.productCareName}
                      </MenuItem>
                    ))}
                </Select>
                <p
                  className="suffix-button"
                  onClick={() => setAddProductCarePopup(true)}
                >
                  Add Product Care
                </p>
              </div>

              {/* <div className="global-single-input product-master-input">
                <p>Stock</p>
                <input />
                <p className="suffix-text">Available Stock&nbsp;:&nbsp;750</p>
              </div> */}
              <div className="global-single-input product-master-input">
                <p>Pattern</p>
                <Select value={selectedPattern} onChange={getSelectedPatternId}>
                  <MenuItem value="0">-select pattern-</MenuItem>
                  {allPatternList
                    ?.slice(0)
                    ?.reverse()
                    ?.map((r, i) => (
                      <MenuItem key={i} value={r?._id}>
                        {r?.patternName}
                      </MenuItem>
                    ))}
                </Select>
                <p
                  className="suffix-button"
                  onClick={() => setAddPatternPopup(true)}
                >
                  Add Pattern
                </p>
              </div>
              <div className="global-single-input product-master-input">
                <p>Material</p>
                <Select
                  value={selectedMaterial}
                  onChange={getSelectedMaterialId}
                >
                  <MenuItem value="0">-select material-</MenuItem>
                  {allMaterialList
                    ?.slice(0)
                    ?.reverse()
                    ?.map((r, i) => (
                      <MenuItem key={i} value={r?._id}>
                        {r?.clothMaterialName}
                      </MenuItem>
                    ))}
                </Select>
                <p
                  className="suffix-button"
                  onClick={() => setAddMaterialPopup(true)}
                >
                  Add Material
                </p>
              </div>
              <div className="global-single-input product-master-input">
                <p>{type === "wholesale" ? "Minimum Qty" : "Re Order Qty"}</p>
                <input
                  placeholder={
                    type === "wholesale"
                      ? "Enter minimum qty..."
                      : "Enter reorder qty..."
                  }
                  value={materialForm.reOrderOrWholesaleQty}
                  onChange={getMaterialFormData("reOrderOrWholesaleQty")}
                />
              </div>
            </div>
          ) : mainCategory === "1" ? (
            <div className="product-master-category-filter-input">
              <div className="global-single-input product-master-input">
                <p>Product ID</p>
                <input disabled placeholder="PROD1" value={productID} />
              </div>
              <div className="global-single-input product-master-input">
                <p>Product Name</p>
                <input
                  value={accessoryForm.prodName}
                  placeholder="Enter product name..."
                  onChange={getAccessoryFormData("prodName")}
                />
              </div>
              {currentCountry !== "india" && (
                <div className="global-single-input product-master-input arabic-name arabic">
                  <p>اسم المنتج</p>
                  <input
                    value={accessoryForm.prodNameArabic}
                    placeholder="...أدخل اسم المنتج"
                    onChange={getAccessoryFormData("prodNameArabic")}
                  />
                </div>
              )}
              <div className="global-single-input product-master-input auto-complete">
                    <p>UOM</p>
                    <Autocomplete
                     sx={{width:"100%"}}
                     options={[""]}
                     getOptionLabel={(option)=>option}
                     renderInput={(params)=>(
                      <TextField {...params} placeholder="select hsn code"/>
                     )}
                    
                    />
                  </div>
                  <div className="global-single-input product-master-input auto-complete">
                    <p>Purchase UOM</p>
                    <Autocomplete
                     sx={{width:"100%"}}
                     options={[""]}
                     getOptionLabel={(option)=>option}
                     renderInput={(params)=>(
                      <TextField {...params} placeholder="select hsn code"/>
                     )}
                    
                    />
                  </div>
                  <div className="global-single-input product-master-input auto-complete">
                    <p>Vendor Tax</p>
                    <Autocomplete
                     sx={{width:"100%"}}
                     options={[""]}
                     getOptionLabel={(option)=>option}
                     renderInput={(params)=>(
                      <TextField {...params} placeholder="select hsn code"/>
                     )}
                    
                    />
                  </div>
                  <div className="global-single-input product-master-input auto-complete">
                    <p>Customer Tax</p>
                    <Autocomplete
                     sx={{width:"100%"}}
                     options={[""]}
                     getOptionLabel={(option)=>option}
                     renderInput={(params)=>(
                      <TextField {...params} placeholder="select hsn code"/>
                     )}
                    
                    />
                  </div>
                  <div className="global-single-input product-master-input">
                <p>Cost</p>
                <input
                  type="text"
                />
              </div>
              <div className="global-single-input product-master-input">
                <p>Sales Price</p>
                <input
                  type="text"
                />
              </div>
              {currentCountry === "india" && (
                <>
                  <div className="global-single-input product-master-input auto-complete">
                    <p>HSN Code</p>
                    {/* <Select value={hsnCode}>
                      <MenuItem value="0">-hsn code-</MenuItem>
                      {HSNC?.map((item, i) => (
                        <MenuItem key={i} value={item?.hsn}>
                          {item?.hsn}
                        </MenuItem>
                      ))}
                    </Select> */}
                    <Autocomplete
                     sx={{width:"100%"}}
                     options={hsnFiltered}
                     getOptionLabel={(option)=>option}
                     renderInput={(params)=>(
                      <TextField {...params} placeholder="select hsn code"/>
                     )}
                     onChange={(e,newValue)=>{
                       if(newValue===null){
                        setHsnCode("0")
                       }
                       else{
                         setHsnCode(newValue)
                       }
                    }}
                    />
                  </div>
                  <div className="global-single-input product-master-input auto-complete">
                    <p>HS Code</p>
                    <Autocomplete
                     sx={{width:"100%"}}
                     options={hsCodeFiltered}
                     getOptionLabel={(option)=>option?.hs}
                     renderInput={(params)=>(
                      <TextField {...params} placeholder="select hs code" />
                     )}
                     onChange={(e,newValue)=>{
                      if(newValue===null){
                        setHsCode("0")
                      }
                      else{
                        setHsCode(newValue?.hs)
                      }
                     }}
                    />
                  </div>
                </>
              )}
              {
                isTax === true &&
                <div className="global-single-input product-master-input">
                <p>{currentCountry === "india" ? "GST" : "VAT"}</p>
                <input type="text" placeholder="Enter tax in % ..."  value={accessoryForm.taxRate} onChange={getAccessoryFormData("taxRate")}/>
              </div>
              }
            
              <div className="global-single-input product-master-input">
                <p>Release Date</p>
                <input
                  type="date"
                  value={accessoryForm.releaseDate}
                  onChange={getAccessoryFormData("releaseDate")}
                />
              </div>
              <div className="global-single-input product-master-input">
                <p>Brand</p>
                <Select value={selectedBrandId} onChange={getBrandId}>
                  <MenuItem value="0">-select brand-</MenuItem>
                  {allBrandList
                    ?.slice(0)
                    ?.reverse()
                    ?.map((brand, i) => (
                      <MenuItem key={i} value={brand?._id}>
                        {brand?.brandName}
                      </MenuItem>
                    ))}
                </Select>
                <p
                  className="suffix-button"
                  onClick={() => setAddBrandPopup(true)}
                >
                  Add Brand
                </p>
              </div>
              <div
                className={
                  selectedBrandId !== "0"
                    ? "global-single-input product-master-input"
                    : "global-single-input product-master-input disabled-input-container"
                }
              >
                <p>Size</p>
                <input
                  placeholder={
                    selectedBrandId !== "0"
                      ? "Click to select size"
                      : "Select a Brand first"
                  }
                  value={sizeSelectionMsg}
                  disabled={selectedBrandId !== "0" ? false : true}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setOpenSelectableSizeChart(true);
                  }}
                />
                <p
                  className="suffix-button"
                  onClick={() => {
                    if (selectedBrandId !== "0") {
                      if (type === "retail") {
                        setAddSizeChart(true);
                      } else if (type === "wholesale") {
                        setSizeChartWholesale(true);
                      }
                    }
                  }}
                >
                  View Size Chart
                </p>
              </div>
              <div className="global-single-input product-master-input">
                <p>Colour</p>
                <Select value={selectedReadymadeColor} onChange={getColor}>
                  <MenuItem value="0">-select colour-</MenuItem>
                  {colorList
                    ?.slice(0)
                    ?.reverse()
                    ?.map((color, i) => (
                      <MenuItem
                        value={color?._id}
                        key={color?._id}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {color?.colorName}
                        <div
                          style={{
                            backgroundColor: color?.colorCode,
                            width: "15px",
                            height: "15px",
                            marginLeft: "4px",
                            borderRadius: "4px",
                          }}
                        ></div>
                      </MenuItem>
                    ))}
                </Select>
                <p
                  className="suffix-button"
                  onClick={() => setAddColourPopup(true)}
                >
                  Add Colour
                </p>
              </div>
            
              {/* <div className="global-single-input product-master-input">
                <p>Unit</p>
                <Select value={selectedUnit} onChange={getUnitId}>
                  <MenuItem value="0">-select unit-</MenuItem>
                  {allUnitsList
                    ?.slice(0)
                    ?.reverse()
                    ?.map((r, i) => (
                      <MenuItem key={i} value={r?._id}>
                        {r?.unitName}
                      </MenuItem>
                    ))}
                </Select>
                <p
                  className="suffix-button"
                  onClick={() => setAddUnitPopup(true)}
                >
                  Add Unit
                </p>
              </div> */}
              <div className="global-single-input product-master-input">
                <p>Material</p>
                <Select
                  value={selectedMaterial}
                  onChange={getSelectedMaterialId}
                >
                  <MenuItem value="0">-select material-</MenuItem>
                  {allMaterialList
                    ?.slice(0)
                    ?.reverse()
                    ?.map((r, i) => (
                      <MenuItem key={i} value={r?._id}>
                        {r?.clothMaterialName}
                      </MenuItem>
                    ))}
                </Select>
                <p
                  className="suffix-button"
                  onClick={() => setAddMaterialPopup(true)}
                >
                  Add Material
                </p>
              </div>
             
              <div className="global-single-input product-master-input">
            <p>Re Order</p>
            <Select></Select>
            <p className="suffix-button">Add Reorder</p>
         </div>
            </div>
          ) : undefined}
        </div>
      </div>

      <div className="product-master-right-main-container">
        <div className="global-page-parent-container product-master-right-container">
          <div className="global-single-input product-master-input">
            <p>Country Of Origin</p>
            <Select
              value={generalFormFields.countryOfOrigin}
              onChange={getGeneralFormData("countryOfOrigin")}
            >
              <MenuItem value="0">-select country-</MenuItem>
              {CountryListWithCode?.map((country, i) => (
                <MenuItem value={country?.name?.toLowerCase()} key={i}>
                  {country?.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="global-single-input product-master-input">
            <p>Return Policy</p>
            <Select
              value={generalFormFields.returnPolicy}
              onChange={getGeneralFormData("returnPolicy")}
            >
              <MenuItem value="0">-select return policy-</MenuItem>
              {ReturnPolicy.map((item, i) => (
                <MenuItem value={item.value} key={i}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            {/* <p className="suffix-button">Add Return Policy</p> */}
          </div>
          <div className="global-single-input product-master-description-input">
            <p>Add Description</p>
            <textarea
              rows={6}
              value={generalFormFields.description}
              placeholder="Enter description of product..."
              onChange={getGeneralFormData("description")}
            />
          </div>
          <div className="global-single-input product-master-additional-info">
            <p>Additional Info</p>
            <div className="product-master-additional-info-sub">
              <input
                placeholder="Enter additional information..."
                value={generalFormFields.additionalInfo}
                onChange={getGeneralFormData("additionalInfo")}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    addAdditionalInfo();
                  }
                }}
              />
              <button onClick={addAdditionalInfo}>+</button>
            </div>
            <div>
              {additionalInfoArray?.map((r, i) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>{r}</div>
                  <div>
                    <Tooltip title="Delete" placement="right" arrow>
                      <IconButton onClick={removeAdditionalInfoItem(i)}>
                        <i
                          class="bi bi-trash3 delete-icon"
                          style={{ fontSize: "0.9rem" }}
                        ></i>
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="global-single-input product-master-additional-info">
            <p>Youtube URL</p>
            <div className="product-master-additional-info-sub">
              <input
                placeholder="Paste product video url..."
                value={generalFormFields.youtubeUrl}
                onChange={getGeneralFormData("youtubeUrl")}
              />
              {/* <button>+</button> */}
            </div>
          </div>
          <div className="global-single-input product-master-image">
            <p>Image Upload</p>
            <div className="image-product-master">
              {/* <FilePicker
                type="video"
                id="product1"
                uploadImageUrl={video1}
                setImage={setVideo1}
                onChange={(e) => setVideo1(e.target.files[0])}
              /> */}
              <FilePicker
                id="product2"
                uploadImageUrl={image1}
                setImage={setImage1}
                // onChange={(e) => setImage1(e.target.files[0])}
                onChange={getImage1}
              />
              <FilePicker
                id="product3"
                uploadImageUrl={image2}
                setImage={setImage2}
                // onChange={(e) => setImage2(e.target.files[0])}
                onChange={getImage2}
              />
              <FilePicker
                id="product4"
                uploadImageUrl={image3}
                setImage={setImage3}
                // onChange={(e) => setImage3(e.target.files[0])}
                onChange={getImage3}
              />
              <FilePicker
                id="product5"
                uploadImageUrl={image4}
                setImage={setImage4}
                // onChange={(e) => setImage4(e.target.files[0])}
                onChange={getImage4}
              />
            </div>
          </div>
        </div>
        <div>
          <button
            className="btn btn-secondary-outlined"
            onClick={
              mainCategory === "0"
                ? clearReadymadeForm
                : mainCategory === "1"
                ? clearAccessoryForm
                : mainCategory === "2"
                ? clearMaterialForm
                : undefined
            }
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={
              mainCategory === "0"
                ? readymadeFormFinalSubmit
                : mainCategory === "1"
                ? accessoryFormFinalSubmit
                : mainCategory === "2"
                ? materialFormFinalSubmit
                : undefined
            }
          >
            Submit
          </button>
        </div>
      </div>

      {/* readymade popup */}
      {/* add category popup */}
      <AddCategoryPopup
        open={addCategoryPopup}
        label="Add Category"
        closePopup={() => setAddCategoryPopup(false)}
        value={readymadeCatName}
        onChange={getReadyMadeCatName}
        addBtnClick={addNewReadyMadeCategory}
      >
        {readyMadeCategoryList !== undefined ? (
          readyMadeCategoryList
            ?.slice(0)
            .reverse()
            ?.map((r, i) => (
              <tr>
                <td>{i + 1}</td>
                <td>{r?.categoryName}</td>
                <td>
                  <IconButton onClick={clickDeleteCategoryAdded("readymadeCat",r?._id)}>
                    <i class="bi bi-trash3 delete-icon"></i>
                  </IconButton>
                </td>
              </tr>
            ))
        ) : (
          <tr>
            <td colSpan={3}>No Records</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* add sub category popup */}
      <AddCategoryPopup
        open={addSubCategoryPopup}
        label="Add Sub Category"
        closePopup={() => setAddSubCategoryPopup(false)}
        value={readymadeSubCatName}
        onChange={getReadymadeSubCatName}
        addBtnClick={addNewReadyMadeSubCategory}
      >
        {readyMadeSubCategoryList !== undefined ? (
          readyMadeSubCategoryList
            ?.slice(0)
            ?.reverse()
            ?.map((r, i) => {
              if (r?.categoryId === selectedReadyMadeCatId) {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{r?.subCategoryName}</td>
                    <td>
                      <IconButton>
                        <i class="bi bi-trash3 delete-icon"></i>
                      </IconButton>
                    </td>
                  </tr>
                );
              }
            })
        ) : (
          <tr>
            <td colSpan={3}>No Records</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* add occasion popup */}
      <AddCategoryPopup
        open={addOccasionPopup}
        label="Add Occasion"
        closePopup={() => setAddOccasionPopup(false)}
        value={occasionName}
        onChange={getReadymadeOccasionName}
        addBtnClick={addNewReadymadeOccasion}
      >
        {readymadeOccasionList !== undefined ? (
          readymadeOccasionList
            ?.slice(0)
            ?.reverse()
            ?.map((r, i) => {
              if (
                r?.subCategoryId === selectedReadymadeSubCatId &&
                r?.categoryId === selectedReadyMadeCatId
              ) {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{r?.occassionName}</td>
                    <td>
                      <IconButton>
                        <i class="bi bi-trash3 delete-icon"></i>
                      </IconButton>
                    </td>
                  </tr>
                );
              }
            })
        ) : (
          <tr>
            <td colSpan={3}>No Records</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* add brand popup   */}
      <AddCategoryPopup
        open={addBrandPopup}
        label="Add Brand"
        closePopup={() => setAddBrandPopup(false)}
        value={newBrandName}
        onChange={getNewBrandName}
        addBtnClick={addNewBrandBtn}
      >
        {allBrandList
          ?.slice(0)
          ?.reverse()
          ?.map((brand, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{brand?.brandName}</td>
              <td>
                <IconButton disabled>
                  <i class="bi bi-trash3 delete-icon"></i>
                </IconButton>
              </td>
            </tr>
          ))}
      </AddCategoryPopup>

      {/* add fitting popup */}
      <AddCategoryPopup
        open={addFittingPopup}
        label="Add Fitting"
        closePopup={() => setAddFittingPopup(false)}
        value={newFittingName}
        onChange={getNewFittingName}
        addBtnClick={addNewFittingBtn}
      >
        {allFittingsList !== undefined ? (
          allFittingsList
            ?.slice(0)
            ?.reverse()
            ?.map((r, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{r?.fittingName}</td>
                <td>
                  <IconButton>
                    <i class="bi bi-trash3 delete-icon"></i>
                  </IconButton>
                </td>
              </tr>
            ))
        ) : (
          <tr>
            <td colSpan={3}>No Records</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* add product care popup */}
      <AddCategoryPopup
        open={addProductCarePopup}
        label="Add Product Care"
        closePopup={() => setAddProductCarePopup(false)}
        value={newProductCareText}
        onChange={getNewProductCare}
        addBtnClick={addNewProductCare}
      >
        {allProductCareList !== undefined ? (
          allProductCareList
            ?.slice(0)
            ?.reverse()
            ?.map((r, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{r?.productCareName}</td>
                <td>
                  <IconButton>
                    <i class="bi bi-trash3 delete-icon"></i>
                  </IconButton>
                </td>
              </tr>
            ))
        ) : (
          <tr>
            <td colSpan={3}>No Records</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* add neck style popup   */}
      <AddCategoryPopup
        open={addNeckStylePopup}
        label="Add Neck Style"
        closePopup={() => setAddNeckStylePopup(false)}
        value={newNeckStyleName}
        onChange={getNewNeckStyleName}
        addBtnClick={addNewNeckStyle}
      >
        {allNeckStyleList !== undefined ? (
          allNeckStyleList
            ?.slice(0)
            ?.reverse()
            ?.map((r, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{r?.neckStyleName}</td>
                <td>
                  <IconButton>
                    <i class="bi bi-trash3 delete-icon"></i>
                  </IconButton>
                </td>
              </tr>
            ))
        ) : (
          <tr>
            <td colSpan={3}>No Records</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* add unit  popup */}
      <AddCategoryPopup
        open={addUnitPopup}
        label="Add Unit"
        closePopup={() => setAddUnitPopup(false)}
        value={newUnitName}
        onChange={getNewUnitName}
        addBtnClick={addNewUnit}
      >
        {allUnitsList !== undefined ? (
          allUnitsList
            ?.slice(0)
            ?.reverse()
            ?.map((r, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{r?.unitName}</td>
                <td>
                  <IconButton>
                    <i class="bi bi-trash3 delete-icon"></i>
                  </IconButton>
                </td>
              </tr>
            ))
        ) : (
          <tr>
            <td colSpan={3}>No Records</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* add style Name */}
      <AddCategoryPopup
        open={addStyleNamePopup}
        label="Add Style Name"
        closePopup={() => setStyleNamePopup(false)}
        value={newStyleName}
        onChange={getNewStyleName}
        addBtnClick={addNewStyle}
      >
        {allReadymadeStyleList !== undefined ? (
          allReadymadeStyleList
            ?.slice(0)
            ?.reverse()
            ?.map((r, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{r?.styleName}</td>
                <td>
                  <IconButton>
                    <i class="bi bi-trash3 delete-icon"></i>
                  </IconButton>
                </td>
              </tr>
            ))
        ) : (
          <tr>
            <td colSpan={3}>No Records</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* add pattern popup */}
      <AddCategoryPopup
        open={addPatternPopup}
        label="Add Pattern"
        closePopup={() => setAddPatternPopup(false)}
        value={newPatternName}
        onChange={getNewPatternName}
        addBtnClick={addNewPattern}
      >
        {allPatternList !== undefined ? (
          allPatternList
            ?.slice(0)
            ?.reverse()
            ?.map((r, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{r?.patternName}</td>
                <td>
                  <IconButton>
                    <i class="bi bi-trash3 delete-icon"></i>
                  </IconButton>
                </td>
              </tr>
            ))
        ) : (
          <tr>
            <td colSpan={3}>No Records</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* add material popup */}
      <AddCategoryPopup
        open={addMaterialPopup}
        label="Add Material"
        closePopup={() => setAddMaterialPopup(false)}
        value={newMaterial}
        onChange={getNewMaterialName}
        addBtnClick={addNewMaterial}
      >
        {allMaterialList !== undefined ? (
          allMaterialList
            ?.slice(0)
            ?.reverse()
            ?.map((r, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{r?.clothMaterialName}</td>
                <td>
                  <IconButton>
                    <i class="bi bi-trash3 delete-icon"></i>
                  </IconButton>
                </td>
              </tr>
            ))
        ) : (
          <tr>
            <td colSpan={3}>No Records</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* material popup */}
      {/* add category */}
      <AddCategoryPopup
        open={addMaterialCatPopup}
        label="Add Category"
        closePopup={() => setAddMaterialCatPopup(false)}
        value={newMaterialCatName}
        onChange={getMaterialCategoryName}
        addBtnClick={addNewMaterialCategory}
      >
        {allMaterialCatList !== undefined ? (
          allMaterialCatList
            ?.slice(0)
            ?.reverse()
            ?.map((r, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{r?.materialCategoryName}</td>
                <td>
                  <IconButton onClick={clickDeleteCategoryAdded("materialCat",r?._id)}>
                    <i class="bi bi-trash3 delete-icon"></i>
                  </IconButton>
                </td>
              </tr>
            ))
        ) : (
          <tr>
            <td colSpan={3}>No Records</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* accessories */}
      {/* categories */}
      <AddCategoryPopup
        open={accAddCategoryPopup}
        label="Add Category"
        closePopup={() => setAccAddCategoryPopup(false)}
        value={newAccessoryCatName}
        onChange={getAccessoryCategoryName}
        addBtnClick={addNewAccessoryCategory}
      >
        {allAccessoryCatList !== undefined ? (
          allAccessoryCatList
            ?.slice(0)
            ?.reverse()
            ?.map((r, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{r?.accessoryCategory}</td>
                <td>
                  <IconButton onClick={clickDeleteCategoryAdded("accesoryCat",r?._id)}>
                    <i class="bi bi-trash3 delete-icon"></i>
                  </IconButton>
                </td>
              </tr>
            ))
        ) : (
          <tr>
            <td colSpan={3}>No Records</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* add sub category */}
      <AddCategoryPopup
        open={accAddSubCategory}
        label="Add Sub Category"
        closePopup={() => setAccAddSubCategory(false)}
        value={newAccessorySubCatName}
        onChange={getAccessorySubCategoryName}
        addBtnClick={addNewAccessorySubCategory}
      >
        {allAccessorySubCatList !== undefined ? (
          allAccessorySubCatList
            ?.slice(0)
            ?.reverse()
            ?.map((r, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{r?.accessorySubCategory}</td>
                <td>
                  <IconButton>
                    <i class="bi bi-trash3 delete-icon"></i>
                  </IconButton>
                </td>
              </tr>
            ))
        ) : (
          <tr>
            <td colSpan={3}>No Records</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* General Form  */}
      {/* Return Policy Popup */}
      <AddCategoryPopup
        open={addReturnPolicyPopup}
        label="Add Sub Category"
        closePopup={() => setAddReturnPolicyPopup(false)}
        value={newReturnPolicy}
        onChange={getNewReturnPolicyName}
        addBtnClick={addReturnPolicyBtnClick}
      >
        {allAccessorySubCatList !== undefined ? (
          allAccessorySubCatList
            ?.slice(0)
            ?.reverse()
            ?.map((r, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{r?.accessorySubCategory}</td>
                <td>
                  <IconButton>
                    <i class="bi bi-trash3 delete-icon"></i>
                  </IconButton>
                </td>
              </tr>
            ))
        ) : (
          <tr>
            <td colSpan={3}>No Records</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* color picker pop readymade,Material & Accessories  */}
      <ColorPickerPopup
        open={addColourPopup}
        label="Add Colour"
        closePopup={() => setAddColourPopup(false)}
        value={newColorCode}
        onColorChange={getPickedColor}
        onAddClick={addNewColorPlusBtn}
        colorName={newColorName}
        onColorNameChange={getPickedColorName}
      >
        {colorList
          ?.slice(0)
          ?.reverse()
          ?.map((color, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {color?.colorName}{" "}
                <div
                  style={{
                    backgroundColor: color?.colorCode,
                    width: "15px",
                    height: "15px",
                    marginLeft: "4px",
                    borderRadius: "4px",
                  }}
                ></div>
              </td>
              <td>
                <IconButton>
                  <i class="bi bi-trash3 delete-icon"></i>
                </IconButton>
              </td>
            </tr>
          ))}
      </ColorPickerPopup>

      {/* size chart readymade */}
      {type === "retail" ? (
        <SizeChart
          open={addSizeChart}
          type={type}
          customLabel="Reorder Qty"
          closeDialog={() => setAddSizeChart(false)}
          radioChange={getCheckedSizeChart}
          brandSize={brandSize}
          sizeChartForm={sizeChartForm}
          onChangeSizeChartForm={getSizeChartData}
          onAddNewSize={addNewSizeBtnClick}
          sizeChartData={viewSizeChartData}
        ></SizeChart>
      ) : type === "wholesale" ? (
        <SizeChart
          open={sizeChartWholesale}
          type={type}
          customLabel="Minimum Qty"
          closeDialog={() => setSizeChartWholesale(false)}
          radioChange={getCheckedSizeChart}
          brandSize={brandSize}
          sizeChartForm={sizeChartForm}
          onChangeSizeChartForm={getSizeChartData}
          onAddNewSize={addNewSizeBtnClick}
          sizeChartData={viewSizeChartData}
        ></SizeChart>
      ) : undefined}

      <SuccessSnackbar
        open={successSnack}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={errorSnack}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />

      {/* Selectable Size Chart  */}

      <Dialog
        open={openSelectableSizeChart}
        maxWidth="lg"
        onClose={() => {
          setOpenSelectableSizeChart(false);
        }}
        onKeyDown={(e)=>e.key === "Escape" && setOpenSelectableSizeChart(false)}
      >
        <div className="selectable-size-chart-parent">
          <h2>Select Size</h2>
          <hr className="global-hr" />
          <div className="size-chart-second-input-container">
            <div className="global-single-input">
              <p>BrandSize</p>
            </div>

            <div className="global-single-input size-chart-size-input">
              <p>Size</p>
            </div>
            <div className="global-single-input">
              <p>Reorder qty</p>
            </div>
            {currentCountry === "india" && (
              <div className="global-single-input">
                <p>MRP</p>
              </div>
            )}
            <div className="global-single-input">
              <p>Price</p>
            </div>
            <div className="global-single-input">
              <p>Calculated Price</p>
            </div>
          </div>
          <div className="third-container">
            {selectableSizeChart?.length > 0 ? (
              selectableSizeChart?.map((r, i) => {
                if (type === "retail") {
                  if (r?.isRetail) {
                    return (
                      <div className="size-chart-second-input-container">
                        <div className="global-single-input">
                          <input value={r?.brandOrUnitSize} disabled />
                        </div>
                        <div className="global-single-input size-chart-size-input">
                          <input value={r?.size} disabled />
                        </div>
                        <div className="global-single-input">
                          <input value={r?.reorderQty} disabled />
                        </div>
                        {currentCountry === "india" && (
                          <div className="global-single-input">
                            <input
                              value={r?.mrp === null ? 0 : r?.mrp}
                              disabled={r?.disabled}
                              onChange={changeMrpAndPrice(i, "mrp")}
                            />
                          </div>
                        )}
                        <div className="global-single-input">
                          <input
                            value={r?.price === null ? 0 : r?.price}
                            disabled={r?.disabled}
                            onChange={changeMrpAndPrice(i, "price")}
                          />
                        </div>
                        <div className="global-single-input">
                          <input value={r?.calculatedPrice} disabled />
                        </div>

                        <input
                          type="checkbox"
                          name=""
                          id=""
                          className="select-size"
                          checked={r?.checked}
                          onClick={sizeRowCheckBoxClick(i)}
                        />
                      </div>
                    );
                  }
                } else if (type === "wholesale") {
                  if (r?.isRetail === false) {
                    return (
                      <div className="size-chart-second-input-container">
                        <div className="global-single-input">
                          <input value={r?.brandOrUnitSize} disabled />
                        </div>
                        <div className="global-single-input size-chart-size-input">
                          <input value={r?.size} disabled />
                        </div>
                        <div className="global-single-input">
                          <input value={r?.reorderQty} disabled />
                        </div>
                        <div className="global-single-input">
                          <input
                            value={r?.mrp === null ? 0 : r?.mrp}
                            disabled={r?.disabled}
                            onChange={changeMrpAndPrice(i, "mrp")}
                          />
                        </div>
                        <div className="global-single-input">
                          <input
                            value={r?.price === null ? 0 : r?.price}
                            disabled={r?.disabled}
                            onChange={changeMrpAndPrice(i, "price")}
                          />
                        </div>
                        <div className="global-single-input">
                          <input value={r?.calculatedPrice} disabled />
                        </div>

                        <input
                          type="checkbox"
                          name=""
                          id=""
                          className="select-size"
                          checked={r?.checked}
                          onClick={sizeRowCheckBoxClick(i)}
                        />
                      </div>
                    );
                  }
                }
              })
            ) : (
              <p className="no-data">No Sizes Created</p>
            )}
          </div>
          <div className="size-chart-button-container">
            <button
              className="btn btn-secondary-outlined"
              onClick={sizeChartCancelBtnClick}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                // console.log(selectedSizeChart);
                sizeChartSubmitBtnClick();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
