import { Box, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import PhysicalStockVerification from "./inventoryTabGroup/PhysicalStockVerification";
import TransferMaterials from "./inventoryTabGroup/TransferMaterials";
import ManufacturingVoucherDashboard from "./inventoryTabGroup/ManufacturingVoucherDashboard";
import '../../../../../css/Account/createInvoice/inventory.css'
const TabPanel = ({ value, index, children }) => {
  return (
    <div style={{ width: "100%" }} role="tabpanel" hidden={value !== index}>
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
};

const Inventory = () => {
  const [value, setValue] = useState(0);
  const [selectedTabName, setSelectedTabName] = useState('');

  useEffect(()=>{
    handleChange(null,0)
  },[])

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // Set the selected tab name  based on the new value
    switch (newValue) {
      case 0:
        setSelectedTabName('Physical stock verification');
        break;
      case 1:
        setSelectedTabName('Transfer Materials');
        break;
      case 2:
        setSelectedTabName('Manufacturing Voucher Dashboard');
        break;
      default:
        break;
    }
  };

  return (
    <div className="global-page-parent-container">
      <p className="breadcrumb">
        Accounts &gt; Create Invoice &gt; Inventory &gt; {selectedTabName}
      </p>
      <div className="purchase-voucher">
        <div
          style={{
            padding: "10px 20px",
            background: "white",
            width: "100%",
            borderRadius: "10px",
            boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
          }}
        >
          <Box sx={{ borderBottom: 1, borderColor: "#fff" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Physical stock verification" />
              <Tab label="Transfer Materials" />
              <Tab label="Manufacturing Voucher Dashboard" />
            </Tabs>
          </Box>
        </div>
        <TabPanel value={value} index={0}>
          <PhysicalStockVerification />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TransferMaterials />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ManufacturingVoucherDashboard />
        </TabPanel>
      </div>
    </div>
  );
};

export default Inventory;
