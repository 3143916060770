import { IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { Datepicker } from "../../../../Single Components/Datepicker";
import { ViewBalanceSheetAPIcall } from "./TrailBalanceAPI";
import { LoadingForm } from "../../../../Single Components/LoadingForm";
import { today } from "../../../../../Js/Date";
import { viewAllCurrencyUpdated } from "../../../../../API/Settings/Currency/CurrencyAPI";
import { companyProfileInfoForUser } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import { filterObjFromList } from "../../../../../Js/generalFunctions";
import "./BalanceSheet.css";

const BalanceSheetNew = () => {
  let componentRef = useRef();
  const userInfo = useSelector((state) => state.loginResponseSlice.value);
  const decimalPosition = localStorage.getItem("decimalPosition");
  const balanceSheetListData = useSelector(
    (state) => state?.trailbalance?.balanceSheetListData
  );
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );
  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );

  const [loading, setLoading] = useState(false);
  const [symbol, setSymbol] = useState(null);
  const [updatedBalanceSheetArray, setUpdatedBalanceSheetArray] = useState([]);
  const [equityLiabilitySum, setEquityLiabilitySum] = useState(0);

  useEffect(() => {
    if (balanceSheetListData) {
      // Define a function to sort accounts by accountName
      function sortAccounts(accounts) {
        return accounts.slice().sort((a, b) => {
          if (a.accountName < b.accountName) return -1;
          if (a.accountName > b.accountName) return 1;
          return 0;
        });
      }

      // Define the order for each subHead
      const subHeadOrder = {
        Asset: [
          "Receivables",
          "Bank and cash",
          "Current asset",
          "Non current asset",
          "Prepayments",
          "Fixed assets",
        ],
        Equity: ["Previous year earnings", "Current year earnings", "Equity"],
        Liability: [
          "Payable",
          "Credit card",
          "Current liability",
          "Non current liability",
        ],
      };

      // Initialize the final array
      const transformedResult = [];

      // Define a function to calculate the total sum
      function calculateTotal(body) {
        let total = 0;
        body.forEach((item) => {
          total += parseFloat(item.value);
        });
        return total.toFixed(2);
      }

      // Initialize the sum of equity and liability
      let equityLiabilitySum = 0;

      // Define a function to process each array type
      function processArray(arrayType, subHead) {
        const body = [];
        const subHeadItems = subHeadOrder[subHead];
        subHeadItems.forEach((subItem) => {
          const item = balanceSheetListData.currentYearData[arrayType].find(
            (account) => account._id === subItem
          );
          if (item) {
            const sortedAccounts = sortAccounts(item.accounts);
            const subData = [];
            sortedAccounts.forEach((account) => {
              subData.push({
                name: account.accountName,
                value: account.closingBalance,
              });
            });
            body.push({
              name: item._id,
              value: item.totalClosingBalance,
              subData: subData,
            });

            // If the subHead is Equity or Liability, add its totalClosingBalance to the sum
            if (subHead === "Equity" || subHead === "Liability") {
              equityLiabilitySum += parseFloat(item.totalClosingBalance);
            }
          } else {
            body.push({
              name: subItem,
              value: 0,
              subData: [],
            });
          }
        });

        transformedResult.push({
          subHead: subHead,
          body: body,
          total: calculateTotal(body),
        });
      }

      // Process each array type
      processArray("assetArray", "Asset");
      processArray("equityArray", "Equity");
      processArray("liabilityArray", "Liability");

      // Set the state with the sum of equity and liability
      setEquityLiabilitySum(equityLiabilitySum);

      // Set the state with the transformed result
      setUpdatedBalanceSheetArray(transformedResult);
    }
  }, [balanceSheetListData]);

  const [toDate, setToDate] = useState(today);
  const getToDate = (e) => {
    setToDate(e.target.value);
  };

  const [showInsideData, setShowInsideData] = useState({
    0: {
      Asset: {
        0: true,
      },
    },
    1: {
      Equity: {
        0: true,
      },
    },
    2: {
      Liability: {
        0: true,
      },
    },
  });
  //function for switching  to view data
  const toggleInsideData = (index, key, subIndex) => {
    setShowInsideData((prevState) => {
      const newState = { ...prevState };
      const currentState = newState[index]?.[key]?.[subIndex];
      newState[index] = {
        ...newState[index],
        [key]: {
          ...newState[index]?.[key],
          [subIndex]: !currentState,
        },
      };
      return newState;
    });
  };

  //rerender when date change
  useEffect(() => {
    if (toDate) {
      setLoading(true);
      ViewBalanceSheetAPIcall(
        {
          // startDate: toDate,
          endDate: toDate,
        },
        setLoading
      );
    }
  }, [toDate]);

  // initial data
  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, []);

  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);

  return (
    <div className="balance-sheet-main-container">
      {/* header */}
      <div className="header-for-balance-sheet">
        <div className="balance-sheet-action-btns">
          <div>
            <Tooltip title="Share">
              <IconButton>
                <i
                  className="bi bi-share"
                  style={{
                    backgroundColor: "#9797e9",
                    color: "white",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                ></i>
              </IconButton>
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Print">
              <div>
                <ReactToPrint
                  trigger={() => (
                    <IconButton
                      className="printer-div"
                      style={{ padding: "1px" }}
                    >
                      <i
                        style={{
                          color: "white",
                          backgroundColor: " #d787e7",
                          fontSize: "medium",
                          padding: "8px",
                          borderRadius: "2px",
                        }}
                        className="bi bi-printer"
                      ></i>
                    </IconButton>
                  )}
                  content={() => componentRef}
                />
              </div>
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Download">
              <IconButton>
                <i
                  className="bi bi-arrow-down-circle"
                  style={{
                    backgroundColor: "rgb(255 118 133 / 69%)",
                    color: "white",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                ></i>
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div>
          <Datepicker
            type="date"
            color="rgb(38, 74, 149)"
            backgroundColor="#f1f2ff"
            value={toDate}
            onChange={getToDate}
          />
          <p className="doc-validation-alert">{""}</p>
        </div>
      </div>
      {/* body */}
      <div className="balance-sheet-body">
        <div className="balance-sheet-body-head">
          <span>Balance Sheet</span>
          <span>({toDate?.split("-").reverse().join("-")})</span>
        </div>
        <div className="balance-sheet-body-table-container">
          <table>
            <thead>
              <tr>
                <th>Perticular</th>
                <th>Account</th>
              </tr>
            </thead>
            <tbody>
              {updatedBalanceSheetArray?.length > 0 ? (
                updatedBalanceSheetArray.map((res, i) => {
                  return (
                    <React.Fragment key={i}>
                      {/* Subhead */}
                      <tr
                        className="bs-sub-head"
                        onClick={() => toggleInsideData(i, res?.subHead, 0)}
                      >
                        <td colSpan={2}>{res.subHead}</td>
                      </tr>
                      {/* Inside data */}
                      {showInsideData[i]?.[res.subHead]?.[0] &&
                        res?.body?.map((item, j) => {
                          return (
                            <React.Fragment key={j}>
                              <tr
                                className="bs-main-body"
                                onClick={() =>
                                  toggleInsideData(i, res.subHead, j + 1)
                                }
                              >
                                <td className="bs-bold">{item.name}</td>
                                <td className="bs-txt-end bs-total-amt">
                                  {symbol}&nbsp;{" "}
                                  {item?.value?.toFixed(decimalPosition)}
                                </td>
                              </tr>
                              {/* Sub Data */}
                              {showInsideData[i]?.[res.subHead] &&
                              showInsideData[i][res.subHead][j + 1] &&
                              item?.subData?.length > 0
                                ? item?.subData?.map((sub, k) => {
                                    return (
                                      <React.Fragment key={k}>
                                        <tr
                                          className={`bs-sub-data ${
                                            showInsideData[i]?.[res.subHead]?.[
                                              j + 1
                                            ]
                                              ? ""
                                              : ""
                                          }`}
                                        >
                                          <td>{sub?.name}</td>
                                          <td className="bs-txt-end">
                                            {symbol}&nbsp;
                                            {sub?.value?.toFixed(
                                              decimalPosition
                                            )}
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    );
                                  })
                                : showInsideData[i][res.subHead][j + 1] && (
                                    <tr>
                                      {" "}
                                      <td colSpan={2} className="bs-no-data">
                                        No Data
                                      </td>
                                    </tr>
                                  )}
                            </React.Fragment>
                          );
                        })}
                      {/* Total */}
                      {i !== 1 && (
                        <tr className="bs-footer-total">
                          <td>Total</td>
                          <td>
                            {symbol}&nbsp;
                            {Number(
                              i !== 0 ? equityLiabilitySum : res?.total
                            )?.toFixed(decimalPosition)}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })
              ) : (
                <tr>
                  <td className="bs-no-data" colSpan="2">
                    No Data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <LoadingForm loading={loading} />
        {/* print balance-sheet  */}
        <div className="balance-sheet-print-container">
          <div style={{ display: "none" }}>
            <div
              ref={(el) => (componentRef = el)}
              className="print-container-trail-balance"
              style={{ "@media print": { "@page": { size: "landscape" } } }}
            >
              <div className="trailbalnce-print-header">
                <div className="company-logo-container">
                  <img
                    height={100}
                    width={100}
                    src="http://placehold.it/100x100"
                    alt=""
                  />
                </div>
                <div className="trail-balance-print-title">
                  <span>Balance Sheet</span>
                  <span style={{ fontSize: "13px" }}> As Off {toDate}</span>
                </div>
                <div className="company-details-container">
                  <span>{userInfo?.data?.profile?.companyName} </span>
                  <span>{userInfo?.data?.profile?.companyAddress} </span>
                  <span>{userInfo?.data?.profile?.companyEmail}</span>
                </div>
              </div>
              <div>
                <div
                  style={{ overflow: "unset" }}
                  className="balance-sheet-body-table-container"
                >
                  <table>
                    <thead>
                      <tr>
                        <th>Perticular</th>
                        <th>Account</th>
                      </tr>
                    </thead>
                    <tbody>
                      {updatedBalanceSheetArray?.length > 0 ? (
                        updatedBalanceSheetArray.map((res, i) => {
                          return (
                            <React.Fragment key={i}>
                              {/* Subhead */}
                              <tr
                                className="bs-sub-head"
                                onClick={() =>
                                  toggleInsideData(i, res?.subHead, 0)
                                }
                              >
                                <td colSpan={2}>{res.subHead}</td>
                              </tr>
                              {/* Inside data */}
                              {showInsideData[i]?.[res.subHead]?.[0] &&
                                res.body?.map((item, j) => {
                                  return (
                                    <React.Fragment key={j}>
                                      <tr
                                        className="bs-main-body"
                                        onClick={() =>
                                          toggleInsideData(
                                            i,
                                            res.subHead,
                                            j + 1
                                          )
                                        }
                                      >
                                        <td className="bs-bold">{item.name}</td>
                                        <td className="bs-txt-end bs-total-amt">
                                          {symbol}&nbsp;{" "}
                                          {item?.value?.toFixed(
                                            decimalPosition
                                          )}
                                        </td>
                                      </tr>
                                      {/* Sub Data */}
                                      {showInsideData[i]?.[res.subHead] &&
                                      showInsideData[i][res.subHead][j + 1] &&
                                      item?.subData?.length > 0
                                        ? item?.subData?.map((sub, k) => {
                                            return (
                                              <React.Fragment key={k}>
                                                <tr
                                                  className={`bs-sub-data ${
                                                    showInsideData[i]?.[
                                                      res.subHead
                                                    ]?.[j + 1]
                                                      ? ""
                                                      : ""
                                                  }`}
                                                >
                                                  <td>{sub?.name}</td>
                                                  <td className="bs-txt-end">
                                                    {symbol}&nbsp;
                                                    {sub?.value?.toFixed(
                                                      decimalPosition
                                                    )}
                                                  </td>
                                                </tr>
                                              </React.Fragment>
                                            );
                                          })
                                        : showInsideData[i][res.subHead][
                                            j + 1
                                          ] && (
                                            <tr>
                                              {" "}
                                              <td
                                                colSpan={2}
                                                className="bs-no-data"
                                              >
                                                No Data
                                              </td>
                                            </tr>
                                          )}
                                    </React.Fragment>
                                  );
                                })}
                              {/* Total */}
                              {i !== 1 && (
                                <tr className="bs-footer-total">
                                  <td>Total</td>
                                  <td>
                                    {symbol}&nbsp;
                                    {Number(
                                      i !== 0 ? equityLiabilitySum : res?.total
                                    )?.toFixed(decimalPosition)}
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <tr>
                          <td className="bs-no-data" colSpan="2">
                            No Data
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BalanceSheetNew;
