import { createSlice } from "@reduxjs/toolkit";

export const getAllGrnNoSlice=createSlice({
    name:"allGrnNoSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        get_all_grn_no:(state,action)=>{
            state.value=action.payload.allGrnNoList
        }
    }
})
export const {get_all_grn_no} = getAllGrnNoSlice.actions;
export default getAllGrnNoSlice.reducer