import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch } from "react-redux";
import { posCategoryListAPICall } from "../Modules/Inventory/Configuration/POSCategory/posCategoryAPI";
import { categoryListAPICall } from "../Modules/Inventory/Configuration/Category/CategoryAPI";
import { getAllActiveEmployeesAPICall } from "../../API/Staff/HRM/employeeAPI";
import { viewAllAllowedBranchesAPICall } from "../../API/Settings/Company Settings/companySettingsAPI";
import { vieWareHouseLocationAPICall } from "../Modules/Inventory/Configuration/wareHouseLocationAPI";
import { getAllNewProductList } from "../Modules/Accounts/Vendor/API/vendorBillAPI";
import { viewAllBranchesAPICall } from "../../API/Settings/Company Settings/companySettingsAPI";
import { posReportsAPICall } from "../Modules/Report/POSSalesReport/PosSalesReport";
import { viewAllCustomersAPICall } from "../../API/Customer/customerAPI";
import { set_Back_button_action, set_Filter_CategoryList } from "./listSlice/filterCategorySlice";
import { previousDate, today } from "../../Js/Date";

function PossalesFilter(props) {
  const {
    isSearch = true,
    statusOptions,
    // invoiceList,
    isInvoiceNo,
    isCustomer,
    isShiftCode,
    isStatus,
    isBranch,
    isDate,
    isToDate,
    isFromDate,
    isFilter,
    isPosSalesReportPage,
    shouldFetchData = true,

  } = props;

  const dispatch = useDispatch();

  const allowedBranchList = useSelector((state) => state.allAllowedBranchesSlice.value);
  const customerList = useSelector((state) => state.allCustomerListSlice.value);
  const posSalesReportsList = useSelector((state) => state.posReports.reportsList)
  const allActiveEmpList = useSelector((state) => state.allActiveEmployeeSlice.value);
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  const CategoryList = useSelector((state) => state.categorySlice.value);


  const [listBranch, setListBranch] = useState()
  useEffect(() => {
    if (allowedBranchList) {
      setListBranch([{ storeCode: "", branchName: "All" }, ...allowedBranchList])
    }

  }, [allowedBranchList])


  // const addAllOption = (options) => {
  //   return [{ storeCode: "", branchName: "All" }, ...allowedBranchList];
  // };

  // console.log(addAllOption,'addAllOption')

  const filtereDataOptions = useSelector((state) => state.listCategory);

  const dropDownCtnRef = useRef(null);
  const [dropdownState, setDropdownState] = useState({
    date: false,
    branch: false,
    status: false,
    invoiceNo: false,
    customer: false,
    shiftCode: false,
  });
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [search, setSearch] = useState("");
  const [selectedOption, setSelectedOption] = useState("date");
  const [selectedDateOption, setSelectedDateOption] = useState("from");
  const filteredItemsInitial = {
    fromDate: '',
    toDate: '',
    branchList: null,
    invoiceNo: null,
    customer: null,
    shiftCode: null,
    status: null,
    search: "",
    searchQuery: ""
  };
  const [filteredItems, setfilteredItems] = useState(filteredItemsInitial);

  const resetFormData = () => {
    posReportsAPICall({
      branchId: [],
      search: '',
      shiftId: '',
      customer: '',
      status: '',
      fromDate: '',
      toDate: '',
    });
    setfilteredItems(filteredItemsInitial);
    setShowFilterDropdown(false);
    props.onBranchSelect(filteredItemsInitial);
    dispatch(set_Back_button_action({ backButtonClickAction: false }));
  };
  const invoiceList = [
    {
      id: 1, label: "work order", type: 1

    },
    {
      id: 2, label: "billing", type: 2
    },
    {
      id: 3, label: "altretion", type: 3
    },
    {
      id: 4, label: "return", type: 4
    },

  ]

  // console.log("previous date",previousDate)
  const invoiceNoArray = posSalesReportsList?.report?.map(reportItem => reportItem?.data?.map(order => order?.orderNo)).flat()
  const shiftCodeArray = posSalesReportsList?.report?.map(reportItem => reportItem?.data?.map(order => order?.shiftId)).flat()
  const duplicateshift = [...new Set(shiftCodeArray)]

  const handleOptionClick = (option) => {
    dispatch(set_Back_button_action({ backButtonClickAction: false }));
    setDropdownState((prevState) => ({
      ...Object.fromEntries(Object.keys(prevState).map(key => [key, key === option ? !prevState[key] : false]))
    }));
  };

  const handleSearchClick = () => {
    if (searchQuery.trim() !== "") {
      setfilteredItems((prevItems) => ({
        ...prevItems,
        search: searchQuery,
      }));
    } else {
      setfilteredItems(filteredItemsInitial)
    }
  };
  // console.log(filteredItems,'filteredItemsInitial')

  const handleFromDateChange = (event) => {
    dispatch(set_Back_button_action({ backButtonClickAction: false }));
    const newFromDate = event.target.value;
    setfilteredItems((prevItems) => ({
      ...prevItems,
      fromDate: newFromDate,
    }));
  };

  const handleToDateChange = (event) => {
    dispatch(set_Back_button_action({ backButtonClickAction: false }));
    const newToDate = event.target.value;
    setfilteredItems((prevItems) => ({
      ...prevItems,
      toDate: newToDate,
    }));
  };

  // const handleDropdownChange = (key) => (event, value) => {
  //   dispatch(set_Back_button_action({ backButtonClickAction: false }));
  //   if (key === "branchList") {
  //     setfilteredItems((prevItems) => ({
  //       ...prevItems,
  //       branchList: value,
  //       location: null,
  //     }));
  //     props?.onBranchSelect(value);
  //     vieWareHouseLocationAPICall({ branchId: value?._id });
  //   } else {
  //     setfilteredItems((prevItems) => ({
  //       ...prevItems,
  //       [key]: value,
  //     }));
  //   }
  // };
  // Inside the component
  //   const handleDropdownChange = (key) => (event, value) => {
  //     console.log("handleDropdownChange called with key:", key, "and value:", value);
  //     dispatch(set_Back_button_action({ backButtonClickAction: false }));
  //     if (key === "branchList") {     
  //         if (value.branchName === "All") {
  //             setfilteredItems((prevItems) => ({
  //                 ...prevItems,
  //                 branchList: value,
  //             }));

  //             props?.onBranchSelect([]); // Pass an empty string to the parent component
  //         } else if (value) { // Check if value is not null or undefined
  //             console.log("Value is not All. Setting branchList to:", value);
  //             setfilteredItems((prevItems) => ({
  //                 ...prevItems,
  //                 branchList: value,
  //                 location: null,
  //             }));
  //             props?.onBranchSelect(value);
  //             vieWareHouseLocationAPICall({ branchId: value?._id });
  //         }
  //     } else {
  //         setfilteredItems((prevItems) => ({
  //             ...prevItems,
  //             [key]: value,
  //         }));
  //     }
  // };
  const handleDropdownChange = (key) => (event, value) => {
    console.log("handleDropdownChange called with key:", key, "and value:", value);
    dispatch(set_Back_button_action({ backButtonClickAction: false }));
    if (key === "branchList") {
      if (value.branchName === "All") {
        setfilteredItems((prevItems) => ({
          ...prevItems,
          branchList: value,
        }));
        props?.onBranchSelect([]); // Pass an empty string to the parent component
      } else if (value) { // Check if value is not null or undefined
        console.log("Value is not All. Setting branchList to:", value);
        setfilteredItems((prevItems) => ({
          ...prevItems,
          branchList: value,
          location: null,
        }));
        props?.onBranchSelect(value);
        vieWareHouseLocationAPICall({ branchId: value?._id });
      }
    } else {
      setfilteredItems((prevItems) => ({
        ...prevItems,
        [key]: value,
      }));
    }
  };


  const filteredPayloadDatas = {
    fromDate: filteredItems.fromDate,
    toDate: filteredItems.toDate,
    createdBy:
      filteredItems.createdBy === null ? [] : [filteredItems.createdBy?._id],
    branchList: filteredItems?.branchList?.branchName === "All" ? [] : filteredItems?.branchList ? [filteredItems?.branchList._id] : [],

    category:
      filteredItems.category === null ? [] : [filteredItems.category?._id],

    status:
      filteredItems.status === null ? [] : [filteredItems.status],
    search: filteredItems.search,

    invoiceNo: filteredItems.invoiceNo === null ? [] : [filteredItems?.invoiceNo?.type],
    customer: filteredItems.customer,
    shiftCode: filteredItems.shiftCode,

    searchQuery: filteredItems.searchQuery
  };

  const filterSelected = [
    // filteredItems.fromDate !== '' && "true",
    // filteredItems.toDate !== '' && "true",
    filteredItems.branchList !== null && "true",
    filteredItems.status !== null && "true",
    filteredItems.invoiceNo !== null && "true",
    filteredItems.customer !== null && "true",
    filteredItems.shiftCode !== null && "true",
  ].filter(Boolean);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropDownCtnRef.current &&
        !dropDownCtnRef.current.contains(event.target)
      ) {
        setShowFilterDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // useEffect(() => {
  //   props.onData(filteredPayloadDatas);
  //   if(!filtereDataOptions?.backButtonClicked){
  //     dispatch(set_Filter_CategoryList({ filterCategorylistData: filteredItems }))
  //   }
  // }, [filteredItems]);

  const handleApplyFilter = () => {
    console.log(filteredPayloadDatas, '....filteredPayloadDatas')
    props.onData(filteredPayloadDatas);
  };

  useEffect(() => {
    if (isPosSalesReportPage) {
      setfilteredItems(prevState => ({
        ...prevState,
        fromDate: previousDate,
        toDate: today
      }));
    } else {
      setfilteredItems(prevState => ({
        ...prevState,
        fromDate: "",
        toDate: ""
      }));
    }
  }, [isPosSalesReportPage]);


  useEffect(() => {
    // getAllNewProductList();
    allBranchesList === undefined && viewAllBranchesAPICall()
    CategoryList === undefined && categoryListAPICall();
    allActiveEmpList === undefined && getAllActiveEmployeesAPICall();
    allowedBranchList === undefined && viewAllAllowedBranchesAPICall({ employeeId: loginResponse?.data?._id });

  }, []);

  useEffect(() => {
    if (filtereDataOptions?.backButtonClicked) {
      setfilteredItems(filtereDataOptions?.filterCategorylist || filteredItemsInitial)
    }
  }, [filtereDataOptions])

  return (
    <div
      className="right-side-search-div"
      style={{ width: "562px", justifyContent: "end", position: "relative" }}
    >
      {isFilter === undefined && (
        <div
          className="view-order-search-container"
          onClick={() => setShowFilterDropdown(!showFilterDropdown)}
          style={{
            border: "none",
            width: "8%",
            position: "relative",
            padding: "18px",
            cursor: "pointer",
            backgroundColor: "#DEE3F8",
          }}
        >
          <i
            className="bi bi-funnel"
            style={{ color: " #5c5ce5", marginTop: "-14px", fontSize: "0.9rem" }}
          ></i>
          <p
            style={{
              marginTop: "33px",
              marginLeft: "-30px",
              color: "#5c5ce5",
              fontSize: "0.8rem",
            }}
          >
            Filter
          </p>
          {filterSelected.length !== 0 && (
            <span
              style={{
                position: "absolute",
                top: "-10px",
                right: "0",
                borderRadius: "50%",
                height: "20px",
                width: "20px",
                textAlign: "center",
                backgroundColor: "#0500a4",
                color: "#fff",
                fontSize: "0.8rem",
              }}
            >
              {filterSelected.length}
            </span>
          )}
        </div>
      )}
      {showFilterDropdown ? (
        <div ref={dropDownCtnRef} className={`filter-ctn-dropdown `} style={{top:"81%", left: !isSearch ?"44%":"35%"}} >
          {isDate === undefined && (
            <div
              className={`justify-space-between align-items-center filter-dropdown-menu ${selectedOption === "date" ? "selected" : ""
                }`}
              onClick={() => handleOptionClick("date")}
            >
              <p style={{ marginLeft: "10px" }}>
                {" "}
                Date
                {filteredItems.fromDate.length > 0 && (
                  <sup>
                    <FiberManualRecordIcon
                      sx={{ color: "#0500a4", fontSize: "0.7rem" }}
                    />
                  </sup>
                )}
              </p>
              <ChevronRightIcon />
            </div>
          )}
          {dropdownState.date && (
            <div className="filter-ctn-dropdowndate">
              {isFromDate === undefined && (
                <div
                  className={`date-optns ${selectedDateOption === "from" ? "selected" : ""
                    }`}
                  onClick={() => setSelectedDateOption("from")}
                >
                  <label style={{ marginLeft: "15px" }} htmlFor="startdate">
                    From:{" "}
                  </label>
                  <input
                    type="date"
                    className={`date-optns ${selectedDateOption === "from" ? "selected" : ""
                      }`}
                    onChange={handleFromDateChange}
                    value={filteredItems.fromDate}
                  />
                </div>
              )}

              {isToDate === undefined && (
                <div
                  className={`date-optns ${selectedDateOption === "to" ? "selected" : ""
                    }`}
                  onClick={() => setSelectedDateOption("to")}
                >
                  <label style={{ marginLeft: "15px" }} htmlFor="startdate">
                    To:{" "}
                  </label>
                  <input
                    type="date"
                    className={`date-optns ${selectedDateOption === "to" ? "selected" : ""
                      }`}
                    onChange={handleToDateChange}
                    value={filteredItems.toDate}
                  />
                </div>
              )}
            </div>
          )}
          {isBranch === undefined && (
            <div
              className={`justify-space-between align-items-center filter-dropdown-menu ${selectedOption === "branch" ? "selected" : ""
                }`}
              onClick={() => handleOptionClick("branch")}
            >
              <p style={{ marginLeft: "10px" }}>
                {" "}
                Branch
                {filteredItems?.branchList !== null && (
                  <sup>
                    <FiberManualRecordIcon
                      sx={{ color: "#0500a4", fontSize: "0.7rem" }}
                    />
                  </sup>
                )}
              </p>
              {dropdownState.branch ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </div>
          )}
          {dropdownState.branch && (
            <div
              style={{ width: "100%", margin: "8px 0" }}
              className="new-global-single-input auto-complete-new"
            >
              {/* <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={addAllOption(allowedBranchList) || []}
                getOptionLabel={(option) => option ? `${option?.storeCode}  ${option?.branchName}` : ""}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} focused />}
                value={filteredItems?.branchList}
                onChange={handleDropdownChange("branchList")}
              /> */}
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={listBranch || []}
                getOptionLabel={(option) => option ? `${option?.storeCode}  ${option?.branchName}` : ""}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} focused />}
                value={filteredItems?.branchList}
                onChange={handleDropdownChange("branchList")}
              />
              <p className="doc-validation-alert"></p>
            </div>
          )}


          {isInvoiceNo && (
            <div
              className={`justify-space-between align-items-center filter-dropdown-menu ${selectedOption === "invoiceNo" ? "selected" : ""
                }`}
              onClick={() => handleOptionClick("invoiceNo")}
            >
              <p style={{ marginLeft: "10px" }}>
                {" "}
                Invoice Type
                {filteredItems?.invoiceNo?.length > 0 && (
                  <sup>
                    <FiberManualRecordIcon
                      sx={{ color: "#0500a4", fontSize: "0.7rem" }}
                    />
                  </sup>
                )}
              </p>
              {dropdownState.invoiceNo ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </div>
          )}
          {dropdownState.invoiceNo && (
            <div
              style={{ width: "100%", margin: "8px 0" }}
              className="new-global-single-input auto-complete-new"
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={invoiceList || []}
                getOptionLabel={(option) => option?.label}
                sx={{ width: 300 }}
                renderOption={(props, option) => (
                  <li {...props} key={option?.id}>
                    <span>{option?.label}</span>
                  </li>
                )}
                renderInput={(params) => <TextField {...params} focused />}
                value={filteredItems.invoiceNo}
                // value={filteredItems.invoiceNo ? filteredItems.invoiceNo : null}

                onChange={handleDropdownChange("invoiceNo")}
              />
              <p className="doc-validation-alert"></p>
            </div>
          )}
          {/* {isInvoiceNo && (
            <div
              className={`justify-space-between align-items-center filter-dropdown-menu ${selectedOption === "invoiceNo" ? "selected" : ""
                }`}
              onClick={() => handleOptionClick("invoiceNo")}
            >
              <p style={{ marginLeft: "10px" }}>
                {" "}
                Invoice Type
                {filteredItems?.invoiceNo?.length > 0 && (
                  <sup>
                    <FiberManualRecordIcon
                      sx={{ color: "#0500a4", fontSize: "0.7rem" }}
                    />
                  </sup>
                )}
              </p>
              {dropdownState.invoiceNo ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </div>
          )}
          {dropdownState.invoiceNo && (
            <div
              style={{ width: "100%", margin: "8px 0" }}
              className="new-global-single-input auto-complete-new"
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={invoiceNoArray || []}
                getOptionLabel={(option) => option}
                sx={{ width: 300 }}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    <span>{option}</span>
                  </li>
                )}
                renderInput={(params) => <TextField {...params} focused />}
                value={filteredItems.invoiceNo}
                onChange={handleDropdownChange("invoiceNo")}
              />
              <p className="doc-validation-alert"></p>
            </div>
          )} */}
          {isCustomer && (
            <div
              className={`justify-space-between align-items-center filter-dropdown-menu ${selectedOption === "customer" ? "selected" : ""
                }`}
              onClick={() => handleOptionClick("customer")}
            >
              <p style={{ marginLeft: "10px" }}>
                {" "}
                Customer
                {filteredItems?.customer?.length > 0 && (
                  <sup>
                    <FiberManualRecordIcon
                      sx={{ color: "#0500a4", fontSize: "0.7rem" }}
                    />
                  </sup>
                )}
              </p>
              {dropdownState.customer ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </div>
          )}
          {dropdownState.customer && (
            <div
              style={{ width: "100%", margin: "8px 0" }}
              className="new-global-single-input auto-complete-new"
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={customerList || []}
                getOptionLabel={(option) => option?.name}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} focused />}
                value={filteredItems.customer}
                // renderOption={(props, option) => (
                //   <li {...props} key={option._id}>
                //     <span>{option.name}</span>
                //   </li>
                // )}
                onChange={handleDropdownChange("customer")}
              />
              <p className="doc-validation-alert"></p>
            </div>
          )}
          {isShiftCode && (
            <div
              className={`justify-space-between align-items-center filter-dropdown-menu ${selectedOption === "shiftCode" ? "selected" : ""
                }`}
              onClick={() => handleOptionClick("shiftCode")}
            >
              <p style={{ marginLeft: "10px" }}>
                {" "}
                Shift code
                {filteredItems?.shiftCode?.length > 0 && (
                  <sup>
                    <FiberManualRecordIcon
                      sx={{ color: "#0500a4", fontSize: "0.7rem" }}
                    />
                  </sup>
                )}
              </p>
              {dropdownState.shiftCode ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </div>
          )}
          {dropdownState.shiftCode && (
            <div
              style={{ width: "100%", margin: "8px 0" }}
              className="new-global-single-input auto-complete-new"
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={duplicateshift || []}
                getOptionLabel={(option) => option}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} focused />}
                value={filteredItems?.shiftCode}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    <span>{option}</span>
                  </li>
                )}
                onChange={handleDropdownChange("shiftCode")}
              />
              <p className="doc-validation-alert"></p>
            </div>
          )}


          {isStatus && (
            <div
              className={`justify-space-between align-items-center filter-dropdown-menu ${selectedOption === "status" ? "selected" : ""
                }`}
              onClick={() => handleOptionClick("status")}
            >
              <p style={{ marginLeft: "10px" }}>
                Status
                {filteredItems?.status !== null && (
                  <sup>
                    <FiberManualRecordIcon
                      sx={{ color: "#0500a4", fontSize: "0.7rem" }}
                    />
                  </sup>
                )}
              </p>
              {dropdownState.status ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </div>
          )}
          {dropdownState.status && (
            <div
              style={{ width: "100%", margin: "8px 0" }}
              className="new-global-single-input auto-complete-new"
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={statusOptions}
                getOptionLabel={(option) => option}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} focused />}
                value={filteredItems.status}
                onChange={handleDropdownChange("status")}
              />
              <p className="doc-validation-alert"></p>
            </div>
          )}

          <div
            className="justify-center"
            style={{ justifyContent: "space-between", paddingRight: "8px" }}
          >

            <button onClick={handleApplyFilter} className='filter-apply-btn'>Apply</button>
            <p
              onClick={() => {
                resetFormData();
                // Call the API with the initial state

              }}
              style={{ color: "#e8a990", margin: "7px 0", cursor: "pointer" }}
            >
              Clear All
            </p>

            {/* <p
              onClick={() => {
                dispatch(set_Back_button_action({ backButtonClickAction: false }));
                setfilteredItems(filteredItemsInitial);
                setShowFilterDropdown(false);
                props.onBranchSelect(filteredItemsInitial);
              }}
              style={{ color: "#e8a990", margin: "7px 0", cursor: "pointer" }}
            >
              Clear All
            </p> */}
          </div>
        </div>
      ) : null}
      {isSearch &&

        <div className="view-order-search-container" style={{ width: "35%", position: "relative" }}>
          <p className="search-head">Search</p>
          <input
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value)
              if (e.target.value === "") {

                setfilteredItems(filteredItems)
                // handleSearchClick()
              }
            }}
            onKeyDown={(e) => e.key === "Enter" && handleSearchClick()}
          />
          <SearchIcon
            className="billing-search-icon"
            style={{ color: "#736ff3" }}
            onClick={() => handleSearchClick()}
          />
        </div>
      }
    </div>
  );
}

export default PossalesFilter;
