import { createSlice } from "@reduxjs/toolkit";

export const stitchingSlice=createSlice({
    name:"stitchingSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        update_stitching:(state,action)=>{
            state.value=action.payload.stitching
        }
    }
})

export const {update_stitching}=stitchingSlice.actions
export default stitchingSlice.reducer