import React from 'react'
import { useNavigate } from 'react-router-dom';
import { InternalTransferFilterAPICall, deleteInternalTransferAPICall, viewInternalTransferAPICall } from './InternalTransferAPI';
import { get_internal_transfer_single_view } from './InternalTransferSlice';
import store from '../../../../../Redux/store';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { IconButton, Skeleton } from '@mui/material';
import ErrorSnackbar from '../../../../Single Components/SnackBars/ErrorSnackbar';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import CustomPagination from '../../../../Single Components/CustomPagination';
import axios from 'axios';
import { setFilterActive } from '../../../../Single Components/listSlice/filterCategorySlice';

export const InternalTransfer = () => {
  const filtereDataOptions = useSelector((state) => state.listCategory);
  let navigate = useNavigate();
  const internalTransferList = useSelector((state) => state.internalTransferSlice.value)
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = internalTransferList?.pages;
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
    alert: false
  });

  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      alert: false,
      message: "",
    });
  };

  const createInternalTransfer = () => {
    navigate("/userdashboard/inventory/operation/InternalTransfer/create")
  }
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };
  useEffect(() => {
    // viewInternalTransferAPICall(setIsLoading)
    localStorage.removeItem("singleViewById")
    store.dispatch(get_internal_transfer_single_view({ singleInternalTransferData: undefined }))
  }, [])

  const deleteJournalEntryClick = (id) => () => {
    deleteInternalTransferAPICall({ id })
  }

  const deletePostedJournal = () => {
    setSnackBarStates({ ...snackBarStates, message: "posted inventory Adjustment entrie can't be deleted!! ", error: true })
  }
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };
  const singleViewFn = (data) => {
    store.dispatch( setFilterActive(window.location.pathname))
    navigate("/userdashboard/inventory/operation/InternalTransfer/create")
    localStorage.setItem("singleViewById", data?._id)
    // store.dispatch(get_internal_transfer_single_view({ singleInternalTransferData: data }))
  }
  useEffect(() => {
   
    if(FormDataInfo.length!==0){ 
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData &&   InternalTransferFilterAPICall({
    // fromDate: `${FormDataInfo.fromDate} ${getCurrentTime()}`,
    // toDate: `${FormDataInfo.toDate} ${getCurrentTime()}`,
    branchList:FormDataInfo.branchList,
    source: FormDataInfo.fromBranch,
    destination: FormDataInfo.destinationBranch,
    status: FormDataInfo.status,
    search: FormDataInfo.search,
    index: currentPage-1,
  },setIsLoading);
};

}, [FormDataInfo, currentPage]);
  return (
    <div className='global-page-parent-container'>
      {/* table */}
      <div className='global-white-bg-container'>
      <div className="justify-space-between"
          style={{ gap: "0 12px", justifyContent: "flex-end" }}
        >
          <CategorySearchandFilter
            statusOptions={['Completed', 'Drafted','Cancel']}
            onData={handleDataFromChild}
            isStatus={true}
            isFromBranch={true}
            isDestinationBranch={true}
            isBranch={false}
            isDate={false}
            onBranchSelect={handleBranchSelect}
          />
          <button onClick={createInternalTransfer} className='create-button-blue'>Create</button>

        </div>
        <div className='create-button-blue-container'>
          <h3>Internal Transfer</h3>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />
          {/* <button onClick={createInternalTransfer} className='create-button-blue'>Create</button> */}
        </div>
        <div className='new-global-table-container'>
          <table>
            <thead>
              <tr>

                <th>Name</th>
                <th>From</th>
                <th>To</th>
                <th>Contact</th>
                <th>Schedule Date</th>
                <th>Status</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody >
              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={8}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) :
                (internalTransferList?.list?.length !== 0 || internalTransferList?.list === undefined) ? internalTransferList?.list?.slice(0)?.map((r, i) => (
                  <tr>

                    <td  onClick={() => singleViewFn(r)}>{r?.NAME}</td>
                    <td  onClick={() => singleViewFn(r)}>{r?.sourcename}</td>
                    <td  onClick={() => singleViewFn(r)}>{r?.destinationname}</td>
                    <td  onClick={() => singleViewFn(r)}>{r?.contactPerson}</td>
                    <td  onClick={() => singleViewFn(r)}>{r?.date}</td>
                    <td className='table-data-box'  onClick={() => singleViewFn(r)}>
                      {r?.status === "Completed" ? (
                        <span className='post'> POST</span>
                      ) : r?.status === "Drafted" ? (
                        <span className='draft'> DRAFT</span>
                      ) : r?.status === "Cancel" ? (
                        <span className='cancel'> CANCEL</span>
                      ) : (
                        "---"
                      )}
                    </td>

                    {/* <td><span className='status-pending' style={{borderRadius:"4px",backgroundColor:"rgb(91 241 161)",margin:"2% 0"}}>{r?.status}</span></td> */}
                    <td>
                      <IconButton
                        onClick={r?.status !== "Cancel" ? deletePostedJournal : deleteJournalEntryClick(r?._id)}
                      >
                        <i className={`bi bi-trash3 ${r?.status !== "Cancel"
                            ? "edit-icon-disabled"
                            : "delete-icon"
                          }`}></i>
                      </IconButton>
                    </td>
                  </tr>
                ))
                  :
                  <tr>
                    <td colSpan={7}>No Data</td>
                  </tr>
              }

            </tbody>
          </table>
        </div>
      </div>
      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
    </div>
  )
}
