import axios from 'axios'
import { getCurrentTime, today } from '../../../Js/Date';
import {HEADERS} from '../../UrlAndPaths'
import { getShiftStatusAPICAll } from './viewShiftAPI';
import { setErrorSnack, setSnackMsg, setSubmitLoading } from '../../../Redux/Dashboard/mainDashBoardSlice';
import store from '../../../Redux/store';

let branchId=localStorage.getItem('branchId')
//shift start api call
export const startManualShiftAPICall=async(
    body,
    setShiftBtnState,
    shiftBtnState,
    handleReset,
    handleStart
    )=>{

    store.dispatch(setSubmitLoading(true))
    await axios.post("settings/startmanualshift",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            handleReset()
            handleStart()
            setShiftBtnState(!shiftBtnState);
            getShiftStatusAPICAll({branchId,curTime:`${today} ${getCurrentTime()}`})
            store.dispatch(setSubmitLoading(false))
            
        }
    })
    .catch((err)=>{
        store.dispatch(setSubmitLoading(false))
        let errorMsg=""
        if(err.response.status===404){
            errorMsg='Server Error'
        }
        if (err.response.status === 403) {
            errorMsg='Forbidden'
        }
        if (err.response.status === 401) {
            errorMsg='Unauthorized'
        }
        if (err.response.status === 500) {
            errorMsg='Server Error'
        }
        if(err.response.status===undefined){
            errorMsg='Network Connection error!!'
        }

        store.dispatch(setSnackMsg(errorMsg))
        store.dispatch(setErrorSnack(true))
        
    })
}

// shift end api call
export const endManualShiftAPICall= async (
    body,
    handlePause,
    setShiftEndPasswordDialog,
    setShiftBtnState,
    shiftBtnState
    )=>{
        store.dispatch(setSubmitLoading(true))
        await axios.post("settings/endmanualshift",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
            store.dispatch(setSubmitLoading(false))
            handlePause()
            setShiftEndPasswordDialog(false);
            setShiftBtnState(!shiftBtnState);
            localStorage.removeItem('shiftStatus')
        }
    })
    .catch((err)=>{
        store.dispatch(setSubmitLoading(false))
        let errorMsg=""
        if(err.response.status===404){
            errorMsg='Server Error'
        }
        if (err.response.status === 403) {
            errorMsg='Forbidden'
        }
        if (err.response.status === 401) {
            errorMsg='Unauthorized'
        }
        if (err.response.status === 500) {
            errorMsg='Server Error'
        }
        if(err.response.status===undefined){
            errorMsg='Network Connection error!!'
        }

        store.dispatch(setSnackMsg(errorMsg))
        store.dispatch(setErrorSnack(true))
       
    })
}

export const addShiftDenominationAPICall=async(body,denominationUpdated)=>{
    await axios.post("settings/addDenomination",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                denominationUpdated()
            }
        })
        .catch((err)=>{
            console.error(err.response.status);
        })
}

// 