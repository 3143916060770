import { Dialog, IconButton, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { AddSponseAPI, ListSponserAPI, editSponserAPICall, singleViewSponserAPI } from './ContractApi';
import { useSelector } from 'react-redux';
import SuccessSnackbar from '../../Single Components/SnackBars/SuccessSnackbar';
import ErrorSnackbar from '../../Single Components/SnackBars/ErrorSnackbar';
import store from '../../../Redux/store';
import { get_sponser_id } from './contractSlice';

const Sponser = () => {

const listSponser= useSelector((state)=>state.contractSlice?.sponserList)

const singleView= useSelector((state)=>state.contractSlice?.sponserSingle)
console.log(singleView);

 const [openSuccessSnack, setOpenSuccessSnack] = useState(false);
 const [openErrorSnack, setOpenErrorSnack] = useState(false);
const [snackMsg, setSnackMsg] = useState("oops!!");
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState({
    isEdit:false,
    _id: '',
    name: '',
    number: '',
    expiryDate: '',
});
 //Close SuccessSnackbar
 const closeSuccessSnackbar = () => {
  setOpenSuccessSnack(false);
};
//Close ErrorSnackbar
const closeErrorSnackbar = () => {
  setOpenErrorSnack(false);
};

const handleCreateClick = () => {
  store.dispatch(get_sponser_id(undefined))
  setFormData({
      id: '',
      name: '',
      number: '',
      expiryDate: '',
  });
  setOpenDialog(true);
};

//save
const handleSave =()=>{
let body ={
  name:formData?.name,
  number:formData?.number,
  expiryDate:formData?.expiryDate

}
  AddSponseAPI(body, setOpenSuccessSnack,
    setOpenErrorSnack,
    setSnackMsg)
}

const handleCardClick =(id)=>{
  singleViewSponserAPI({_id:id})
  setOpenDialog(true);
  
}
useEffect(()=>{
  if(singleView!==undefined){
   setFormData({...formData,
    id:singleView?._id,
    name: singleView?.name,
    number: singleView?.number,
    expiryDate: singleView?.expiryDate,
    isEdit:true
  })

  }
},[singleView])

const editOnClick =()=>{
  if(formData.isEdit===true){
    setFormData({ ...formData, isEdit:false })
   
  }
  else{
    let body ={
      _id:formData?.id,
      name:formData?.name,
      number:formData?.number,
      expiryDate:formData?.expiryDate
    
    }
    const draftFn = () => {
      setFormData({ ...formData,isEdit: true});
    };
    editSponserAPICall(body,draftFn, setOpenSuccessSnack,
      setOpenErrorSnack,
      setSnackMsg)
  }

}

useEffect(()=>{
  ListSponserAPI()
},[])

  return (
    <div className="global-page-parent-container">
    <div className="establish-head">
        <div className="create-button-blue-container">
            <h3>Sponser</h3>
            <button 
            onClick={handleCreateClick}
            className="establish-create">
                Create Sponser
            </button>
        </div>
        <div className='establishment-card-container'>
        {listSponser !== undefined &&
                        listSponser?.length !== 0 &&
                        listSponser?.map((item, index) => (
                    <div  key={index} className='establishment-card'  onClick={() => handleCardClick(item._id)}>
                        <div className="establishment-card-text-container">
                            <h5>{item?.name}</h5>
                            <h5>{item?.number}</h5>
                            <h5>{item?.expiryDate}</h5>
                        </div>
                    </div>
                        ))}
        </div>
    </div>

    <Dialog
                open={openDialog}
                onClose={() => {
                    setOpenDialog(false)
                    setFormData({
                        _id: '',
                        name: '',
                        number: '',
                        expiryDate: '',
                    });
                }}
                maxWidth="auto"
                sx={{ padding: "7px 36px" }}
            >
                <div className='dialogue-ctn'>
                    <div>
                      {singleView!==undefined && (
                        <Tooltip title="Edit">
                            <IconButton onClick={editOnClick}>
                            <i
                    class={formData?.isEdit ? 
                      "bi bi-pencil-square edit-icon1":
                      "bi bi-check2-square edit-icon"
                    }
                  ></i>
                            </IconButton>
                        </Tooltip>
                        )}
                        {singleView===undefined && (
                        <Tooltip title="Save">
                            <IconButton  onClick={handleSave}>
                                <i className="bi bi-floppy save-icon"></i>
                            </IconButton>
                        </Tooltip>
                        )}
                    </div>
                    <hr className="global-hr" />

                    <div className='establish-dialogue-form'>

                        <div style={{ width: "auto" }} className="new-global-single-input">
                            <TextField
                                id="outlined-basic"
                                label="Name"
                                variant="outlined"
                                type="text"
                                focused
                                value={formData?.name}
                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                disabled={formData?.isEdit}
                            />
                        </div>





                        <div style={{ width: "auto" }} className="new-global-single-input">
                            <TextField
                                id="outlined-basic"
                                label="Sponser No"
                                variant="outlined"
                                type="number"
                                focused
                                value={formData?.number}
                                onChange={(e) => setFormData({ ...formData, number: e.target.value })}
                                disabled={formData?.isEdit}


                            />
                        </div>
                    </div>
                    <div style={{ width: "47%" }} className="new-global-single-input">                   
                        <TextField
                            id="outlined-basic"
                            label="Sponser Expiry"
                            variant="outlined"
                            type="date"
                            focused
                            value={formData?.expiryDate}
                            onChange={(e) => setFormData({ ...formData, expiryDate: e.target.value })}
                            disabled={formData?.isEdit}

                        />
                    </div>
                </div>
            </Dialog>

            <SuccessSnackbar
        open={openSuccessSnack}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={openErrorSnack}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </div>
  )
}

export default Sponser