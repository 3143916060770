import React, { useLayoutEffect, useState } from "react";
import "../../../../css/Orders/workOrder.css";
import {
  Select,
  IconButton,
  MenuItem,
  Dialog,
  Backdrop,
  CircularProgress,
  Autocomplete,
  TextField,
  Badge,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ThobWO } from "./ThobWO";
import { JalabiaWO } from "./JalabiaWO";
import { SalwarWO } from "./SalwarWO";
import { AbayaWO } from "./AbayaWO";
import { ChuridharWO } from "./ChuridharWO";
import { ShirtWO } from "./ShirtWO";
import { PantWO } from "./PantWO";
import { CoatWO } from "./CoatWO";
import { SharvaniWO } from "./SharvaniWO";
import { SafariWO } from "./SafariWO";
import { PaymentsWO } from "./PaymentsWO";
import { dateAfterFiveDays, today } from "../../../../Js/Date";
import { useEffect } from "react";
import {
  createNewWorkOrderAPICall,
  editWorkOrderAPICall,
  generateWoNoAPICall,
  getCustomerMeasurements,
  getHeldWorkOrdersAPICall,
  getModelsFromOutletAPICall,
  viewWoSettingsAPICall,
  workOrderHoldAPICall,
} from "../../../../API/Orders/workOrderAPI";
import { useSelector } from "react-redux";
import { getAllActiveEmployeesAPICall } from "../../../../API/Staff/HRM/employeeAPI";
import {
  calculateWorkOrderTotalAmount,
  extractObjectForSingleView,
  extractSingleObjectGeneral,
} from "../../../../Js/generalFunctions";
import {
  addNewCustomerRelationshipAPICall,
  customerListApi,
  generateCustomerRelationIdAPICall,
  viewAllCustomerRelationsAPICall,
} from "../../../../API/Customer/customerAPI";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";

import store from "../../../../Redux/store";
import { update_wallet_of_customer } from "../../../../Redux/Credit and Wallet/walletOfCustomerSlice";
import { update_relationship_list } from "../../../../Redux/Customer/relationshipSlice";
import { update_credit_of_customer } from "../../../../Redux/Customer/singleCustomerCreditSlice";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import { PrintOutlined } from "@mui/icons-material";
import { addNewColorAPICall, deleteAddedColorAPICall, viewAllColorsAPICall } from "../../../../API/Product Master/ProductMasterAPI";
import { Invoice } from "../../Sales/Billing/Invoice";
import { workOrderSingleViewAPICall } from "../../../../API/Orders/viewOrdersAPI";
import { useMemo } from "react";
import { update_customer_measurements } from "../../../../Redux/Orders/WorkOrder/customerMeasurmentsSlice";
import uuid from "react-uuid";
import {
  changeMeasurementStructure,
  createItemsDemoArrayInCaseOfEdit,
  getProductMeasurements,
} from "../../../../Js/workOrderUserFunctions";
import { ViewOrderUser } from "../ViewOrder/ViewOrderUser";
import { EditPayment } from "../../../Single Components/Payment/EditPayment";
import { getAllTaxSettingsListAPICall } from "../../../../API/Settings/TaxSettings/taxSettingsAPI";
import { AddCustomerBilling } from "../../Sales/Billing/AddCustomerBilling";
import { LoadingForm } from "../../../Single Components/LoadingForm";
import RefreshIcon from '@mui/icons-material/Refresh';
import { viewAllMaterialProductsWithStockAPICall } from "../../../../API/Product List/productListAPI";
export const WorkOrderUser = () => {
  //*From local storage
  //Load previous work order id from local storage [for direct order from view orders (+ btn)]
  const prevOrderId = localStorage.getItem("ORD_ID_NEW_ORD");
  const isRelation = JSON.parse(localStorage.getItem("IS_REL"));
  const relationId = localStorage.getItem("REL_ID");
  //Work order edit ls states
  const isWoEdit = JSON.parse(localStorage.getItem("IS_WO_EDIT"));
  const editingWoId = localStorage.getItem("WO_EDIT_ID");
  const { branchPk } = JSON.parse(localStorage.getItem("login_meta"));

  const storeCode = localStorage.getItem("branchId");
  const userRole=useSelector((state)=>state.userRoleSlice.value)
  //*Redux states
  //Auto generated Work order Id object
  const workOrderId = useSelector((state) => state.workOrderIdSlice.value);
  //Login Response From Redux
  const userInfo = useSelector((state) => state.loginResponseSlice.value);
  //Active Employee List
  const allActiveEmpList = useSelector(
    (state) => state.allActiveEmployeeSlice.value
  );
  //List of All Customers
  const customerList = useSelector((state) => state.allCustomerListSlice.value);
  //Models Added by ADMIN
  const modelsForOutlet = useSelector(
    (state) => state.modelsFromUserSideSlice.value
  );
  //List of All Material products
  const materialProductList = useSelector(
    (state) => state.productsWithStockSlice.value
  );
  const productLoading = useSelector(
    (state) => state.productsWithStockSlice.loadingProductList
  );

  //List of All Customer relationships
  const relationList = useSelector((state) => state.relationshipSlice.value);
  //Customer Relationship id
  const customerRelationId = useSelector(
    (state) => state.customerRelationIdSlice.value
  );
  //Login response to get current branch pk
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  //Generated Invoice
  const generatedInvoice = useSelector((state) => state.invoiceSlice.value);
  //New WO id
  const newWorkOrderResponse = useSelector(
    (state) => state.newWorkOrderResponseSlice.value
  );
  //all created colors list
  const allCreatedColorsList = useSelector(
    (state) => state.colorListSlice.value
  );
  //Single view of a work order (response of orkOrderSingleViewAPICall())
  const singleViewOfAWorkOrder = useSelector(
    (state) => state.workOrderSingleDataSlice.value
  );
  //Customer or relation measurement data
  const clientMeasurement = useSelector(
    (state) => state.customerMeasurementSlice.value
  );
  //get held products list
  const heldWorkOrderList = useSelector(
    (state) => state.heldWorkOrderSlice.value
  );
  const allTaxList = useSelector((state) => state.taxSettingsSlice.value);
// work order settings
  const workOrderSettings=useSelector((state)=>state.viewWorkOrderSettingsSlice.value)
  //Front end uuid
  const [_uuid, setUuid] = useState(null);

  const [dressType, setDressType] = useState(null);
  const [activeBtn, setActiveBtn] = useState("shirtBtn");
  const [paymentBtn, setPaymentBtn] = useState(false);
  const [editPaymentBtn, setEditPaymentBtn] = useState(false);
  //Open Add New Customer Dialog
  const [openAddNewCustomer, setOpenAddNewCustomer] = useState(false);
  const [openAddColor, setOpenAddColor] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);

  const [colorFormValues,setColoFormValues]=useState({
    colorName:"",
    colorChange:""
  })
  //Open Add Relationship dialog
  const [addRelationship, setAddRelationShip] = useState(false);
  const [filteredSingleViewWorkOrder, setFilteredSingleViewWorkOrder] =
    useState([]);
  //Update Listener States
  const [isModify, setIsModify] = useState(false);
  // Snackbar States
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false);
  const [openErrorSnack, setOpenErrorSnack] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops..");
  //isLoading or not
  const [isLoading, setIsLoading] = useState(false);
  //work order Retail or wholesale radio state
  const [orderType, setOrderType] = useState("retail");

  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isEnableInputs, setIsEnableInputs] = useState(false);
  
  const [editLineClicked, setEditLineClicked] = useState(false);
  //Add Relationship form
  const addRelationshipFormInitialState = {
    customerId: "##",
    name: "",
    relationship: "",
    buildingName: "",
    streetName: "",
    landmark: "",
    mobileNo: "",
    email: "",
    reference: "",
    newName:null
  };
  const [addRelationshipForm, setAddRelationshipForm] = useState(
    addRelationshipFormInitialState
  );
 
  //Single Customer Extracted
  const [singleCustomerObj, setSingleCustomerObj] = useState([]);
  //work order user form 1
  const workOrderUserForm1InitialState = {
    orderNo: "##",
    customerId: "0",
    contact: "",
    salesMan: "0",
    orderDate: today,
    deliveryDate: dateAfterFiveDays,
    relationShip: "0",
  };
  const [workOrderUserForm1, setWorkOrderUserForm1] = useState(
    workOrderUserForm1InitialState
  );
  //Thob Model Form
  const thobModelFormInitialState = {
    thobModel: "0",
    length: "",
    shoulder: "",
    sleeve: "",
    chest: "",
    stomach: "",
    slChest: "",
    slSo: "",
    neck: "",
    bottom: "",
    marSize: "",
    marModel: "0",
    cuff: "0",
    cuffSize1: "",
    cuffSize2: "",
    cuffCloth: "0",
    remark: "",
    cuffModel: "0",
    collar: "0",
    collarSize: "",
    collarCloth: "",
    collarModel: "",
    regalSize: "",
    kneeLoose: "",
    fpDown: "",
    fpModel: "",
    fpSize1: "",
    fpSize2: "",
    pen: "0",
    sidePtModel: "0",
    stitching: "0",
    button: "0",
    neckDButton: "",
    sidePTSize: "",
    buttonNo: "",
    mobilePocket: "no",
    down:"",
    veedi:"",
    bag:"",
    open:""
  };
  

  const [thobModelForm, setThobModelForm] = useState(thobModelFormInitialState);
  //Jalabia model Form
  const jalabiaModelFormInitialState = {
    jalabiaModel: "0",
    length: "",
    shoulder: "",
    sleeve: "",
    chest: "",
    stomach: "",
    slChest: "",
    slSto: "",
    neck: "",
    bottom: "",
    sleeveSize: "",
    neckModel: "0",
    stitching: "0",
    remark: "",
    regalSize: "",
    neckDownButton: "",
    mar: "",
    model: "0",
    fpModel: "0",
    fpSize1: "",
    fpSize2: "",
  };
  const [jalabiaModelForm, setJalabiaModelForm] = useState(
    jalabiaModelFormInitialState
  );
  //Salwar modelForm
  const salwarModelFormInitialState = {
    length: "",
    waist: "",
    bottom: "",
    elasticSize: "",
    elasticWidth: "0",
    ply: "",
    thighLoose: "",
    remarks: "",
  };
  const [salwarModelForm, setSalwarModelForm] = useState(
    salwarModelFormInitialState
  );
  //Abaya Model Form
  const abayaModelFormInitialState = {
    sleeveFromNeck: "",
    sleeveFromShoulder: "",
    sleeveWidth: "",
    arms: "",
    bust: "",
    waist: "",
    hip: "",
    length: "",
    remarks: "",
  };
  const [abayaForm, setAbayaForm] = useState(abayaModelFormInitialState);
  //Churidhar Form

  const churidharFormInitialState = {
    shoulder: "",
    frontNeck: "",
    backNeck: "",
    waist: "",
    hip: "",
    fullLength: "",
    armRound: "",
    armHole: "",
    sleeveLength: "",
    remarks: "",
  };
  const [churidharForm, setChuridharForm] = useState(churidharFormInitialState);

  const [churidharFormRemarksArray, setChuridharFormRemarksArray] = useState(
    []
  );

  // state to determine held order  or other orders
  const [isHeldOrder, setIsHeldOrder] = useState(false);
  //Held Order State
  const [heldPayingObject, setHeldPayingObject] = useState(null);

  const addButtonFunction = () => {
    setChuridharFormRemarksArray([
      ...churidharFormRemarksArray,
      churidharForm.remarks,
    ]);
    setChuridharForm({ ...churidharForm, remarks: "" });
  };
  
  //shirt Form
  const shirtFormInitialState = {
    length: "",
    shoulder: "",
    sleeveFull: "",
    chest: "",
    waist: "",
    body: "",
    neck: "",
    cup: "0",
    regal: "",
    kneeLoose: "",
    disco: "",
    canvas: "",
    remarks: "",
    stitching: "0",
    marModel: "0",
    bottomModel: "0",
    colour: "0",
    seat1: "",
    seat2: "",
    seat3: "",
  };

  const [shirtForm, setShirtForm] = useState(shirtFormInitialState);
  //pant Form
  const pantFormInitialState = {
    length: "",
    inside: "",
    waist: "",
    seat: "",
    thigh: "",
    bottom: "",
    ply: "",
    belt: "",
    sidePocket: "",
    backPocket: "",
    pleat: "",
    loops: "",
    remarks: "",
  };
  const [pantForm, setPantForm] = useState(pantFormInitialState);
  //coat Form
  const coatFormInitialState = {
    length: "",
    shoulder: "",
    sleeve: "",
    body: "",
    waist: "",
    seat: "",
    front: "",
    back: "",
    sleeveLoose: "",
    fpButton: "",
    backOpen: "",
    remarks: "",
  };
  const [coatForm, setCoatForm] = useState(coatFormInitialState);
  //Sharvani & Safari Form
  const sharvaniAndSafariFormInitialState = {
    length: "",
    shoulder: "",
    sleeveFull: "",
    chest: "",
    waist: "",
    body: "",
    neck: "",
    cup: "0",
    regal: "",
    kneeLoose: "",
    disco: "",
    canvas: "",
    remark: "",
    stitching: "0",
    marModel: "0",
    bottomModel: "0",
    colour: "0",
    seat1: "",
    seat2: "",
    seat3: "",
  };
  const [sharvaniForm, setSharvaniForm] = useState(
    sharvaniAndSafariFormInitialState
  );
  const [safariForm, setSafariForm] = useState(
    sharvaniAndSafariFormInitialState
  );
  //Models allowed to the outlet
  const [modelList, setModelList] = useState([]);

  // Validation states
  const [productAlert, setProductAlert] = useState(false);
  const [productQtyAlert, setProductQtyAlert] = useState(false);
  const [productRateAlert, setProductRateAlert] = useState(false);
  const [customerAlert, setCustomerAlert] = useState(false);
  const [salesManAlert, setSalesManAlert] = useState(false);
  const [addProductsAlert, setAddProductsAlert] = useState(false);
  const [relationNameAlert, setRelationNameAlert] = useState(false);
  const [relationMobileAlert, setRelationMobileAlert] = useState(false);
  const [selectedTaxes, setSelectedTaxes] = useState([]);
  const [taxList, setTaxList] = useState([]);

  //Select Products Form
  const selectProductsFormInitialState = {
    productId: "0",
    productColor: "",
    productColorId: "",
    qty: "",
    itemRate: "",
    stitchRate: 0,
    amount: 0,
    taxes:[]
  };
  const [selectProductsForm, setSelectProductsForm] = useState(
    selectProductsFormInitialState
  );
  //Selected product object
  const [productSingleObj, setProductSingleObj] = useState([]);

  //product items array for backend
  const [productItemsForBackend, setProductItemsForBackend] = useState([]);

  //Selected Product Items Expanded
  const [productItemsExpanded, setProductItemsExpanded] = useState([]);

  //Selected Product Items Collapsed
  const [productItemsCollapsed, setProductItemsCollapsed] = useState([]);
  //state holding Shirt and Suit id if it is.
  const [shirtAndSuitId, setShirtAndSuitId] = useState("shirt");
  //Items demo array for backend
  const [itemsDemoArray, setItemsDemoArray] = useState([]);
  //Items Demo single object
  const [itemsDemoObject, setItemsDemoObject] = useState({});
  //child product Items object
  const [childProductItemsObject, setChildProductItemsObject] = useState({});
  const [exclusiveTaxes, setExclusiveTaxes] = useState(0);
  const [inclusiveTaxes, setInclusiveTaxes] = useState(0);

  //Work order total amount
  const [workOrderTotal, setWorkOrderTotal] = useState(0);
  

  //Billing Component Form Fields
  const billingFormInitialState = {
    subTotal: 0,
    shipmentCharge: 0,
    couponCode: "",
    rewardPoints: 0,
    paidAmount: 0,
    wallet: "",
    creditAmount: "",
  };
  const [billingForm, setBillingForm] = useState(billingFormInitialState);

  const [totalsInfo,setTotalsInfo]=useState({
    subTotal:0,
    exclusiveTax:0,
    inclusiveTax:0,
  })

  //Payment Total Amount
  const [paymentTotalAmount, setPaymentTotalAmount] = useState(30);
  //Multiple Payment or not
  const [isMultiplePayment, setIsMultiplePayment] = useState(false);

  // in case of edit product row
  const [productEditRow,setProductEditRow]=useState(null)
  const [validationAlert,setValidationAlert]=useState({})
  //invoice dialog
  const [invoiceDialog, setInvoiceDialog] = useState(false);
  //Selected Product object for autocomplete
  const [selectedMaterialObj, setSelectedMaterialObj] = useState(null);
  //Selected Colour single Object for autocomplete
  const [selectedColorSingleObj, setSelectedColorSingleObj] = useState(null);
  //Selected Customer Object
  const [selectedCustomerObj, setSelectedCustomerObj] = useState(null);
  //Shirt and suit common elements to enable and disable shirt and suit radio
  const [shirtAndSuitCommonElements, setShirtAndSuitCommonElements] = useState([
    "Shirt",
    "Pant",
    "Coat",
    "Sharvani",
    "Safari",
  ]);
  //Initial array which holds all the measurements objects
  const [allMeasurementObjectsArray, setAllMeasurementObjectsArray] = useState(
    []
  );
  const [isHeld,setIsHeld]=useState(false)

  //Open View Order Shortcut dialog
  const [openViewOrderShortDialog, setOpenViewOrderShortDialog] =
    useState(false);

  //Previous Payment form details for edit payment
  const [prevPayments, setPrevPayments] = useState(null);
  //Previous payment details object
  const [previousPaymentObject, setPreviousPaymentObject] = useState([]);
  const [editItemToBackEnd,setEditItemToBackEnd]=useState([])

  let date = new Date();
  let invoicePrint = useRef();
  let heldProductBtnRef = useRef();
  //Shirt And suit array
  const shirtAndSuits = ["shirt", "pant", "coat", "sharvani", "safari"];
  const initialCusRender = useRef(true);
  //*onChange fns
  const getColorFormChange=(key)=>(e)=>{
    const {value}=e.target;
    setColoFormValues({...colorFormValues,[key]:value})
    setValidationAlert({})
  }
//Click popup Add Color btn
  const addNewColorPlusBtn = () => {
    let functions=()=>{
      setColoFormValues({...colorFormValues,colorChange:"",colorName:""})
    }
    if(colorFormValues?.colorName===""){
      setValidationAlert({...validationAlert,colorName:"Fill all required fields !!"})
    }
    else if(colorFormValues?.colorChange===""){
      setValidationAlert({...validationAlert,colorChange:"Fill all required fields !!"})
    }
    else{

      addNewColorAPICall(
        { colorName: colorFormValues?.colorName, colorCode:  colorFormValues?.colorChange },
        functions,setSnackMsg,setOpenErrorSnack,setOpenSuccessSnack
        );
      }
  };

  const deleteAddedColor=(id)=>()=>{
    deleteAddedColorAPICall({_id:id},setSnackMsg,setOpenErrorSnack,setOpenSuccessSnack)
  }
  //Get User work order initial form
  const getWorkOrderUserForm1 = (key) => (e) => {
    const { value } = e.target;

    if (key === "customerId") {
      setWorkOrderUserForm1({ ...workOrderUserForm1, customerId: value });
    }
    if (key === "salesMan") {
      setWorkOrderUserForm1({ ...workOrderUserForm1, salesMan: value });
      setSalesManAlert(false);
    }
    if (key === "orderDate") {
      setWorkOrderUserForm1({ ...workOrderUserForm1, orderDate: value });
    }
    if (key === "deliveryDate") {
      setWorkOrderUserForm1({ ...workOrderUserForm1, deliveryDate: value });
    }
    if (key === "relationShip") {
      setWorkOrderUserForm1({ ...workOrderUserForm1, relationShip: value });
    }
  };
  
  //get Thob model Form data
  const getThobModelFormData = (key) => (e) => {
    const { value } = e.target;
    switch (key) {
      case "thobModel":
        setThobModelForm({ ...thobModelForm, thobModel: value });
        break;
      case "length":
        setThobModelForm({ ...thobModelForm, length: value });
        break;
      case "shoulder":
        setThobModelForm({ ...thobModelForm, shoulder: value });
        break;
      case "sleeve":
        setThobModelForm({ ...thobModelForm, sleeve: value });
        break;
      case "chest":
        setThobModelForm({ ...thobModelForm, chest: value });
        break;
      case "stomach":
        setThobModelForm({ ...thobModelForm, stomach: value });
        break;
      case "slChest":
        setThobModelForm({ ...thobModelForm, slChest: value });
        break;
      case "slSo":
        setThobModelForm({ ...thobModelForm, slSo: value });
        break;
      case "neck":
        setThobModelForm({ ...thobModelForm, neck: value });
        break;
      case "bottom":
        setThobModelForm({ ...thobModelForm, bottom: value });
        break;
      case "marSize":
        setThobModelForm({ ...thobModelForm, marSize: value });
        break;
      case "marModel":
        setThobModelForm({ ...thobModelForm, marModel: value });
        break;
      case "cuff":
        setThobModelForm({ ...thobModelForm, cuff: value });
        break;
      case "cuffSize1":
        setThobModelForm({ ...thobModelForm, cuffSize1: value });
        break;
      case "cuffSize2":
        setThobModelForm({ ...thobModelForm, cuffSize2: value });
        break;
      case "cuffCloth":
        setThobModelForm({ ...thobModelForm, cuffCloth: value });
        break;
      case "remark":
        setThobModelForm({ ...thobModelForm, remark: value });
        break;
      case "cuffModel":
        setThobModelForm({ ...thobModelForm, cuffModel: value });
        break;
      case "collar":
        setThobModelForm({ ...thobModelForm, collar: value });
        break;
      case "collarSize":
        setThobModelForm({ ...thobModelForm, collarSize: value });
        break;
      case "collarCloth":
        setThobModelForm({ ...thobModelForm, collarCloth: value });
        break;
      case "collarModel":
        setThobModelForm({ ...thobModelForm, collarModel: value });
        break;
      case "regalSize":
        setThobModelForm({ ...thobModelForm, regalSize: value });
        break;
      case "kneeLoose":
        setThobModelForm({ ...thobModelForm, kneeLoose: value });
        break;
      case "fpDown":
        setThobModelForm({ ...thobModelForm, fpDown: value });
        break;
      case "fpModel":
        setThobModelForm({ ...thobModelForm, fpModel: value });
        break;
      case "fpSize1":
        setThobModelForm({ ...thobModelForm, fpSize1: value });
        break;
      case "fpSize2":
        setThobModelForm({ ...thobModelForm, fpSize2: value });
        break;
      case "pen":
        setThobModelForm({ ...thobModelForm, pen: value });
        break;
      case "sidePtModel":
        setThobModelForm({ ...thobModelForm, sidePtModel: value });
        break;
      case "stitching":
        setThobModelForm({ ...thobModelForm, stitching: value });
        break;
      case "button":
        setThobModelForm({ ...thobModelForm, button: value });
        break;
      case "neckDButton":
        setThobModelForm({ ...thobModelForm, neckDButton: value });
        break;
      case "sidePTSize":
        setThobModelForm({ ...thobModelForm, sidePTSize: value });
        break;
      case "buttonNo":
        setThobModelForm({ ...thobModelForm, buttonNo: value });
        break;
      case "mobilePocket":
        setThobModelForm({ ...thobModelForm, mobilePocket: value });
        break;
      case "down":
        setThobModelForm({ ...thobModelForm, down: value });
        break;
      case "veedi":
        setThobModelForm({ ...thobModelForm, veedi: value });
        break;
      case "bag":
        setThobModelForm({ ...thobModelForm, bag: value });
        break;
      case "open":
        setThobModelForm({ ...thobModelForm, open: value });
        break;
      default:
        break;
    }
  };
  //get jalabia model form data
  const getJalabiaModelFormData = (key) => (e) => {
    const { value } = e.target;

    switch (key) {
      case "jalabiaModel":
        setJalabiaModelForm({ ...jalabiaModelForm, jalabiaModel: value });
        break;
      case "length":
        setJalabiaModelForm({ ...jalabiaModelForm, length: value });
        break;
      case "shoulder":
        setJalabiaModelForm({ ...jalabiaModelForm, shoulder: value });
        break;
      case "sleeve":
        setJalabiaModelForm({ ...jalabiaModelForm, sleeve: value });
        break;
      case "chest":
        setJalabiaModelForm({ ...jalabiaModelForm, chest: value });
        break;
      case "stomach":
        setJalabiaModelForm({ ...jalabiaModelForm, stomach: value });
        break;
      case "slChest":
        setJalabiaModelForm({ ...jalabiaModelForm, slChest: value });
        break;
      case "slSto":
        setJalabiaModelForm({ ...jalabiaModelForm, slSto: value });
        break;
      case "neck":
        setJalabiaModelForm({ ...jalabiaModelForm, neck: value });
        break;
      case "bottom":
        setJalabiaModelForm({ ...jalabiaModelForm, bottom: value });
        break;
      case "sleeveSize":
        setJalabiaModelForm({ ...jalabiaModelForm, sleeveSize: value });
        break;
      case "neckModel":
        setJalabiaModelForm({ ...jalabiaModelForm, neckModel: value });
        break;
      case "stitching":
        setJalabiaModelForm({ ...jalabiaModelForm, stitching: value });
        break;
      case "remark":
        setJalabiaModelForm({ ...jalabiaModelForm, remark: value });
        break;
      case "regalSize":
        setJalabiaModelForm({ ...jalabiaModelForm, regalSize: value });
        break;
      case "neckDownButton":
        setJalabiaModelForm({ ...jalabiaModelForm, neckDownButton: value });
        break;
      case "mar":
        setJalabiaModelForm({ ...jalabiaModelForm, mar: value });
        break;
      case "model":
        setJalabiaModelForm({ ...jalabiaModelForm, model: value });
        break;
      case "fpModel":
        setJalabiaModelForm({ ...jalabiaModelForm, fpModel: value });
        break;
      case "fpSize1":
        setJalabiaModelForm({ ...jalabiaModelForm, fpSize1: value });
        break;
      case "fpSize2":
        setJalabiaModelForm({ ...jalabiaModelForm, fpSize2: value });
        break;

      default:
        break;
    }
  };
  //get salwar model form data
  const getSalwarModelFormData = (key) => (e) => {
    const { value } = e.target;
    switch (key) {
      case "length":
        setSalwarModelForm({ ...salwarModelForm, length: value });
        break;
      case "waist":
        setSalwarModelForm({ ...salwarModelForm, waist: value });
        break;
      case "bottom":
        setSalwarModelForm({ ...salwarModelForm, bottom: value });
        break;
      case "elasticSize":
        setSalwarModelForm({ ...salwarModelForm, elasticSize: value });
        break;
      case "elasticWidth":
        setSalwarModelForm({ ...salwarModelForm, elasticWidth: value });
        break;
      case "ply":
        setSalwarModelForm({ ...salwarModelForm, ply: value });
        break;
      case "thighLoose":
        setSalwarModelForm({ ...salwarModelForm, thighLoose: value });
        break;
      case "remarks":
        setSalwarModelForm({ ...salwarModelForm, remarks: value });
        break;

      default:
        break;
    }
  };
  //get abaya model form data
  const getAbayaModelFormData = (key) => (e) => {
    const { value } = e.target;

    switch (key) {
      case "sleeveFromNeck":
        setAbayaForm({ ...abayaForm, sleeveFromNeck: value });
        break;
      case "sleeveFromShoulder":
        setAbayaForm({ ...abayaForm, sleeveFromShoulder: value });
        break;
      case "sleeveWidth":
        setAbayaForm({ ...abayaForm, sleeveWidth: value });
        break;
      case "arms":
        setAbayaForm({ ...abayaForm, arms: value });
        break;
      case "bust":
        setAbayaForm({ ...abayaForm, bust: value });
        break;
      case "waist":
        setAbayaForm({ ...abayaForm, waist: value });
        break;
      case "hip":
        setAbayaForm({ ...abayaForm, hip: value });
        break;
      case "length":
        setAbayaForm({ ...abayaForm, length: value });
        break;
      case "remarks":
        setAbayaForm({ ...abayaForm, remarks: value });
        break;

      default:
        break;
    }
  };
  //get churidhar form data
  const getChuridharFormData = (key) => (e) => {
    const { value } = e.target;

    switch (key) {
      case "shoulder":
        setChuridharForm({ ...churidharForm, shoulder: value });
        break;
      case "frontNeck":
        setChuridharForm({ ...churidharForm, frontNeck: value });
        break;
      case "backNeck":
        setChuridharForm({ ...churidharForm, backNeck: value });
        break;
      case "waist":
        setChuridharForm({ ...churidharForm, waist: value });
        break;
      case "hip":
        setChuridharForm({ ...churidharForm, hip: value });
        break;
      case "fullLength":
        setChuridharForm({ ...churidharForm, fullLength: value });
        break;
      case "armRound":
        setChuridharForm({ ...churidharForm, armRound: value });
        break;
      case "armHole":
        setChuridharForm({ ...churidharForm, armHole: value });
        break;
      case "sleeveLength":
        setChuridharForm({ ...churidharForm, sleeveLength: value });
        break;
      case "remarks":
        setChuridharForm({ ...churidharForm, remarks: value });
        break;

      default:
        break;
    }
  };
  //get shirt Form data
  const getShirtFormData = (key) => (e) => {
    const { value } = e.target;

    switch (key) {
      case "length":
        setShirtForm({ ...shirtForm, length: value });
        break;
      case "shoulder":
        setShirtForm({ ...shirtForm, shoulder: value });
        break;
      case "sleeveFull":
        setShirtForm({ ...shirtForm, sleeveFull: value });
        break;
      case "chest":
        setShirtForm({ ...shirtForm, chest: value });
        break;
      case "waist":
        setShirtForm({ ...shirtForm, waist: value });
        break;
      case "body":
        setShirtForm({ ...shirtForm, body: value });
        break;
      case "neck":
        setShirtForm({ ...shirtForm, neck: value });
        break;
      case "cup":
        setShirtForm({ ...shirtForm, cup: value });
        break;
      case "regal":
        setShirtForm({ ...shirtForm, regal: value });
        break;
      case "kneeLoose":
        setShirtForm({ ...shirtForm, kneeLoose: value });
        break;
      case "disco":
        setShirtForm({ ...shirtForm, disco: value });
        break;
      case "canvas":
        setShirtForm({ ...shirtForm, canvas: value });
        break;
      case "remarks":
        setShirtForm({ ...shirtForm, remarks: value });
        break;
      case "stitching":
        setShirtForm({ ...shirtForm, stitching: value });
        break;
      case "marModel":
        setShirtForm({ ...shirtForm, marModel: value });
        break;
      case "bottomModel":
        setShirtForm({ ...shirtForm, bottomModel: value });
        break;
      case "colour":
        setShirtForm({ ...shirtForm, colour: value });
        break;
      case "seat1":
        setShirtForm({ ...shirtForm, seat1: value });
        break;
      case "seat2":
        setShirtForm({ ...shirtForm, seat2: value });
        break;
      case "seat3":
        setShirtForm({ ...shirtForm, seat3: value });
        break;

      default:
        break;
    }
  };
  //get pant form data
  const getPantFormData = (key) => (e) => {
    const { value } = e.target;

    switch (key) {
      case "length":
        setPantForm({ ...pantForm, length: value });
        break;
      case "inside":
        setPantForm({ ...pantForm, inside: value });
        break;
      case "waist":
        setPantForm({ ...pantForm, waist: value });
        break;
      case "seat":
        setPantForm({ ...pantForm, seat: value });
        break;
      case "thigh":
        setPantForm({ ...pantForm, thigh: value });
        break;
      case "bottom":
        setPantForm({ ...pantForm, bottom: value });
        break;
      case "ply":
        setPantForm({ ...pantForm, ply: value });
        break;
      case "belt":
        setPantForm({ ...pantForm, belt: value });
        break;
      case "sidePocket":
        setPantForm({ ...pantForm, sidePocket: value });
        break;
      case "backPocket":
        setPantForm({ ...pantForm, backPocket: value });
        break;
      case "pleat":
        setPantForm({ ...pantForm, pleat: value });
        break;
      case "loops":
        setPantForm({ ...pantForm, loops: value });
        break;
      case "remarks":
        setPantForm({ ...pantForm, remarks: value });
        break;

      default:
        break;
    }
  };
  //get coat model form data
  const getCoatModelFormData = (key) => (e) => {
    const { value } = e.target;
    switch (key) {
      case "length":
        setCoatForm({ ...coatForm, length: value });
        break;
      case "shoulder":
        setCoatForm({ ...coatForm, shoulder: value });
        break;
      case "sleeve":
        setCoatForm({ ...coatForm, sleeve: value });
        break;
      case "body":
        setCoatForm({ ...coatForm, body: value });
        break;
      case "waist":
        setCoatForm({ ...coatForm, waist: value });
        break;
      case "seat":
        setCoatForm({ ...coatForm, seat: value });
        break;
      case "front":
        setCoatForm({ ...coatForm, front: value });
        break;
      case "back":
        setCoatForm({ ...coatForm, back: value });
        break;
      case "sleeveLoose":
        setCoatForm({ ...coatForm, sleeveLoose: value });
        break;
      case "fpButton":
        setCoatForm({ ...coatForm, fpButton: value });
        break;
      case "backOpen":
        setCoatForm({ ...coatForm, backOpen: value });
        break;
      case "remarks":
        setCoatForm({ ...coatForm, remarks: value });
        break;

      default:
        break;
    }
  };
  //get sharvani Form data
  const getSharvaniFormData = (key) => (e) => {
    const { value } = e.target;

    switch (key) {
      case "length":
        setSharvaniForm({ ...sharvaniForm, length: value });
        break;
      case "shoulder":
        setSharvaniForm({ ...sharvaniForm, shoulder: value });
        break;
      case "sleeveFull":
        setSharvaniForm({ ...sharvaniForm, sleeveFull: value });
        break;
      case "chest":
        setSharvaniForm({ ...sharvaniForm, chest: value });
        break;
      case "waist":
        setSharvaniForm({ ...sharvaniForm, waist: value });
        break;
      case "body":
        setSharvaniForm({ ...sharvaniForm, body: value });
        break;
      case "neck":
        setSharvaniForm({ ...sharvaniForm, neck: value });
        break;
      case "cup":
        setSharvaniForm({ ...sharvaniForm, cup: value });
        break;
      case "regal":
        setSharvaniForm({ ...sharvaniForm, regal: value });
        break;
      case "kneeLoose":
        setSharvaniForm({ ...sharvaniForm, kneeLoose: value });
        break;
      case "disco":
        setSharvaniForm({ ...sharvaniForm, disco: value });
        break;
      case "canvas":
        setSharvaniForm({ ...sharvaniForm, canvas: value });
        break;
      case "remark":
        setSharvaniForm({ ...sharvaniForm, remark: value });
        break;
      case "stitching":
        setSharvaniForm({ ...sharvaniForm, stitching: value });
        break;
      case "marModel":
        setSharvaniForm({ ...sharvaniForm, marModel: value });
        break;
      case "bottomModel":
        setSharvaniForm({ ...sharvaniForm, bottomModel: value });
        break;
      case "colour":
        setSharvaniForm({ ...sharvaniForm, colour: value });
        break;
      case "seat1":
        setSharvaniForm({ ...sharvaniForm, seat1: value });
        break;
      case "seat2":
        setSharvaniForm({ ...sharvaniForm, seat2: value });
        break;
      case "seat3":
        setSharvaniForm({ ...sharvaniForm, seat3: value });
        break;

      default:
        break;
    }
  };
  //get Safari Form data
  const getSafariFormData = (key) => (e) => {
    const { value } = e.target;

    switch (key) {
      case "length":
        setSafariForm({ ...safariForm, length: value });
        break;
      case "shoulder":
        setSafariForm({ ...safariForm, shoulder: value });
        break;
      case "sleeveFull":
        setSafariForm({ ...safariForm, sleeveFull: value });
        break;
      case "chest":
        setSafariForm({ ...safariForm, chest: value });
        break;
      case "waist":
        setSafariForm({ ...safariForm, waist: value });
        break;
      case "body":
        setSafariForm({ ...safariForm, body: value });
        break;
      case "neck":
        setSafariForm({ ...safariForm, neck: value });
        break;
      case "cup":
        setSafariForm({ ...safariForm, cup: value });
        break;
      case "regal":
        setSafariForm({ ...safariForm, regal: value });
        break;
      case "kneeLoose":
        setSafariForm({ ...safariForm, kneeLoose: value });
        break;
      case "disco":
        setSafariForm({ ...safariForm, disco: value });
        break;
      case "canvas":
        setSafariForm({ ...safariForm, canvas: value });
        break;
      case "remark":
        setSafariForm({ ...safariForm, remark: value });
        break;
      case "stitching":
        setSafariForm({ ...safariForm, stitching: value });
        break;
      case "marModel":
        setSafariForm({ ...safariForm, marModel: value });
        break;
      case "bottomModel":
        setSafariForm({ ...safariForm, bottomModel: value });
        break;
      case "colour":
        setSafariForm({ ...safariForm, colour: value });
        break;
      case "seat1":
        setSafariForm({ ...safariForm, seat1: value });
        break;
      case "seat2":
        setSafariForm({ ...safariForm, seat2: value });
        break;
      case "seat3":
        setSafariForm({ ...safariForm, seat3: value });
        break;

      default:
        break;
    }
  };

  //Get Select Product Form
  const getSelectProductForm = (key) => (e, newValue) => {
    const { value } = e.target;
    switch (key) {
      case "productId":
        setSelectProductsForm({ ...selectProductsForm, productId: value });
        break;
      case "colour":
        setSelectProductsForm({ ...selectProductsForm, colour: value });
        break;
      case "qty":
        setSelectProductsForm({ ...selectProductsForm, qty: value });
        setProductQtyAlert(false);
        if (value === "") {
          setProductQtyAlert(true);
        }
        break;
      case "itemRate":
        setSelectProductsForm({ ...selectProductsForm, itemRate: value });
        setProductRateAlert(false);
        if (value === "") {
          setProductRateAlert(true);
        }

        break;
      case "stitchRate":
        setSelectProductsForm({ ...selectProductsForm, stitchRate: value?value:0 });
        break;
      case "productColor":
        setSelectProductsForm({
          ...selectProductsForm,
          productColorId: newValue?._id,
        });
        setSelectedColorSingleObj(newValue);
        break;
      default:
        break;
    }
  };

  //Get Add Relationship Form data
  const getAddRelationshipForm = (key) => (e,newValue) => {
    
    const { value } = e.target;
    console.log(newValue);
    console.log(value);
    switch (key) {
      case "name":
        setAddRelationshipForm({ ...addRelationshipForm, name: value });
        setRelationNameAlert(false);
        if (value === "") {
          setRelationNameAlert(true);
        }
        break;
      case "relationship":
        setAddRelationshipForm({ ...addRelationshipForm, relationship: value });
        break;
      case "buildingName":
        setAddRelationshipForm({ ...addRelationshipForm, buildingName: value });
        break;
      case "streetName":
        setAddRelationshipForm({ ...addRelationshipForm, streetName: value });
        break;
      case "landmark":
        setAddRelationshipForm({ ...addRelationshipForm, landmark: value });
        break;
      case "newName":
        setAddRelationshipForm({ ...addRelationshipForm, newName:newValue,mobileNo:newValue?.mobileNo});
        break;
       case "newNameInput":
        setAddRelationshipForm({ ...addRelationshipForm, newNameInput:value});
        break;
      case "mobileNo":
        setAddRelationshipForm({ ...addRelationshipForm, mobileNo:value });
        setRelationMobileAlert(false);
        if (value === "") {
          setRelationMobileAlert(true);
        }
        break;
      case "email":
        setAddRelationshipForm({ ...addRelationshipForm, email: value });
        break;
      case "reference":
        setAddRelationshipForm({ ...addRelationshipForm, reference: value });
        break;

      default:
        break;
    }
  };


  //*Fns
  const generateUuid = () => {
    let newUuid = uuid();
    return newUuid;
  };

  //Set New Work order id
  const assignNewWorkOrderId = () => {
    if (workOrderId !== undefined) {
      const { transNo, prefix, status } = workOrderId;
      setWorkOrderUserForm1({
        ...workOrderUserForm1,
        orderNo: `${prefix}${transNo}`,
        orderDate: today,
        deliveryDate: dateAfterFiveDays,
      });
    }
  };

  const [editedProductIndex,setEditedProductIndex]=useState(null)

  //check product material object in items list array (for edit , delete purpose) [delete items]
  const findProductObject = (selectedObject, itemList, fIndex) => {
    const { _uuid } = selectedObject;
    let matchedObjIndex = null;

    for (let i = 0; i < itemList.length; i++) {
      const { productItems } = itemList[i];

      matchedObjIndex = productItems.findIndex((obj) => obj._uuid.match(_uuid));
      // if(matchedObj!==null && matchedObj!==undefined) break;
      if (matchedObjIndex != -1) {
        if (productItems.length == 1) {
          //delete complete obj
          setEditedProductIndex(i)
          console.log(itemList[i],i);
          itemList.splice(i, 1);
        } else {
          //1.Delete matched obj from the list product Items
          productItems.splice(matchedObjIndex, 1);
        }
        //2.Delete the item from front end list
        deleteItemsFromProductItemsExpanded(fIndex);

        break;
      }

    }

    // itemList.forEach((objP) => {
    //   matchedObj=objP.productItems.find(obj=>obj._uuid===_uuid)
    // });

  };

  //Add Measurement Single object into array if no exist
  const addMeasurementSingleObjectToArray = (measurementObject) => {
    let existObj = allMeasurementObjectsArray.find(
      (obj) =>
        obj.clientId === measurementObject.clientId &&
        obj.type === measurementObject.type
    );

    if (existObj) {
      //find existing objects index
      let index = allMeasurementObjectsArray.findIndex(
        (obj) =>
          obj.clientId === measurementObject.clientId &&
          obj.type === measurementObject.type
      );

      //replace the object for updated data
      allMeasurementObjectsArray.splice(index, 1, measurementObject);
      existObj = null;
    } else {
      //add to array
      setAllMeasurementObjectsArray([
        ...allMeasurementObjectsArray,
        measurementObject,
      ]);
      existObj = null;
    }

  };

  //Create Measurement Update Single Type Object
  const createMeasurementUpdateSingleTypeObj = (itemType) => {
    const { customerId, relationShip } = workOrderUserForm1;

    const typeMeasurementObject = {
      clientId: relationShip !== "0" ? relationShip : customerId,
      clientType: relationShip !== "0" ? 1 : 0,
      type: itemType,
      measurement:
        itemType === "thob"
          ? thobModelForm
          : itemType === "jalabia"
          ? jalabiaModelForm
          : itemType === "salwar"
          ? salwarModelForm
          : itemType === "abaya"
          ? abayaForm
          : itemType === "churidhar"
          ? churidharForm
          : itemType === "shirt"
          ? shirtForm
          : itemType === "pant"
          ? pantForm
          : itemType === "coat"
          ? coatForm
          : itemType === "sharvani"
          ? sharvaniForm
          : itemType === "safari"
          ? safariForm
          : undefined,
    };

    addMeasurementSingleObjectToArray(typeMeasurementObject);
  };

  //Set all model states into initial state
  const setTypeFormStatesInitial = () => {

    setThobModelForm(thobModelFormInitialState);
    setJalabiaModelForm(jalabiaModelFormInitialState);
    setSalwarModelForm(salwarModelFormInitialState);
    setAbayaForm(abayaModelFormInitialState);
    setChuridharForm(churidharFormInitialState);
    setShirtForm(shirtFormInitialState);
    setPantForm(pantFormInitialState);
    setCoatForm(coatFormInitialState);
    setSharvaniForm(sharvaniAndSafariFormInitialState);
    setSafariForm(sharvaniAndSafariFormInitialState);
  };

  //Set Customer or relation measurements into state
  const assignCustomerOrRelationMeasurements = (measurementRes) => {
    if (measurementRes !== undefined) {
      //destructuring each model measurements,each one is an array
      const {
        thob,
        jalabia,
        salwar,
        abaya,
        churidhar,
        shirt,
        pant,
        coat,
        sharvani,
        safari,
      } = measurementRes;
      //.....
      //check and assign
      //1.Thob
      if (thob?.length > 0) {
        setThobModelForm(thob[0]?.measurement);
      } else {
        setThobModelForm(thobModelFormInitialState);
      }
      //2.Jalabia
      if (jalabia?.length > 0) {
        setJalabiaModelForm(jalabia[0]?.measurement);
      } else {
        setJalabiaModelForm(jalabiaModelFormInitialState);
      }
      //3.salwar
      if (salwar?.length > 0) {
        setSalwarModelForm(salwar[0]?.measurement);
      } else {
        setSalwarModelForm(salwarModelFormInitialState);
      }
      //4.abaya
      if (abaya?.length > 0) {
        setAbayaForm(abaya[0]?.measurement);
      } else {
        setAbayaForm(abayaModelFormInitialState);
      }
      //5.churidhar
      if (churidhar?.length > 0) {
        setChuridharForm(churidhar[0]?.measurement);
      } else setChuridharForm(churidharFormInitialState);
      //6.shirt
      if (shirt?.length > 0) {
        setShirtForm(shirt[0]?.measurement);
      } else setShirtForm(shirtFormInitialState);
      //7.pant
      if (pant?.length > 0) {
        setPantForm(pant[0]?.measurement);
      } else setPantForm(pantFormInitialState);
      //8.coat
      if (coat?.length > 0) {
        setCoatForm(coat[0]?.measurement);
      } else setCoatForm(coatFormInitialState);
      //9.sharvani
      if (sharvani?.length > 0) {
        setSharvaniForm(sharvani[0]?.measurement);
      } else setSharvaniForm(sharvaniAndSafariFormInitialState);
      //10.safari
      if (safari?.length > 0) {
        setSafariForm(safari[0]?.measurement);
      } else setSafariForm(sharvaniAndSafariFormInitialState);
    } else {
      //set all states initial
      setTypeFormStatesInitial();
    }
  };

  //Extract single customer object
  const extractSingleCusObj = (cusList, cusId) => {
    let extractedCusObj = null;
    if (cusList !== undefined) {
      extractedCusObj = cusList?.filter((obj) => obj?._id === cusId);
      setSelectedCustomerObj(extractedCusObj[0]);
    } else {
      extractedCusObj = null;
      setSelectedCustomerObj(null);
    }
  };

  //Extract single product object or single color Object [extract any object with pk in _id key]
  const extractSingleProdObj = (fullList, pk) => {
    if (fullList !== undefined) {
      let firstObj;
      let extractedObj = fullList.filter((obj) => obj._id === pk);

      [firstObj] = extractedObj;
      return firstObj;
    } else return null;
  };

  //Extract types from prev order
  const extractTypesFromPrevOrder = (woSingleViewData) => {
    let types = [];
    if (woSingleViewData !== undefined) {
      const { items } = woSingleViewData;
      types = items?.map((obj) => obj?.type);

    }
  };

  //Extract Customer object from entire list based on previous order
  const extractCustomerObjBasedOnPrevOrder = (woSingleViewData) => {
    if (woSingleViewData !== undefined) {
      let cusPk = null;
      if (isRelation) cusPk = relationId;
      else cusPk = woSingleViewData?.cusId;
      extractSingleCusObj(customerList, cusPk);
      extractTypesFromPrevOrder(woSingleViewData);
    }
  };

  //* In case of Edit Order-
  //Find Models Present in Editing Order
  const findModelsInEditingOrder = (singleOrderView) => {
    if (singleOrderView !== undefined) {
      let typesInEditingOrder = singleOrderView?.items?.map((obj) => obj?.type);

      return typesInEditingOrder;
    }
  };

  //Create ProductItemsExpanded Array for front end in case of editing (work orders preview Section)
  const createProductItemsExpandedInCaseOfEdit = (singleOrderView) => {
    if (typeof singleOrderView === "object") {
      // create Items Expanded Array
      const { cusId, items } = singleOrderView;
      if (Array.isArray(items)) {
        let newProductItemsExpandedArray = [];
        items?.forEach((item) => {
          const { productItems } = item;
          productItems?.forEach((prodItem) => {
            newProductItemsExpandedArray.push({
              _uuid: prodItem?._uuid,
              type: item?.type,
              item: prodItem?.itemName,
              qty: prodItem?.qty,
              color: prodItem?.colorName,
              itemRate: prodItem?.itemRate,
              stitchRate: prodItem?.stitchRate===""?0:prodItem?.stitchRate,
              amount: prodItem?.amount,
              productId: prodItem?.itemId,
              colorId: prodItem?.colorId,
              cusId: singleOrderView?.cusId,
              relationId: item?.relationId,
              status: item?.status,
              poscat:prodItem?.poscat,
              mainCatgeoryId:prodItem?.mainCatgeoryId,
              taxes:prodItem?.taxes,
              exclusiveTax:prodItem?.exclusiveTax,
              inclusiveTax:prodItem?.inclusiveTax,
              includedPrice:prodItem?.includedPrice,
              isOutCloth:prodItem?.isOutCloth,
              modelItemId:prodItem?.modelItemId,
              measurements: {
                thobModelForm: getProductMeasurements(item, "thob"),
                jalabiaModelForm: getProductMeasurements(item, "jalabia"),
                salwarModelForm: getProductMeasurements(item, "salwar"),
                abayaForm: getProductMeasurements(item, "abaya"),
                churidharForm: getProductMeasurements(item, "churidhar"),
                shirtForm: getProductMeasurements(item, "shirt"),
                pantForm: getProductMeasurements(item, "pant"),
                coatForm: getProductMeasurements(item, "coat"),
                sharvaniForm: getProductMeasurements(item, "sharvani"),
                safariForm: getProductMeasurements(item, "safari"),
              },
            });
          });
        });

        setProductItemsExpanded([...newProductItemsExpandedArray]);
      }
    }
  };

  //fill edit values to states
  const fillEditValuesToStates = (singleOrderView) => {
    if (singleOrderView !== undefined) {
      let customerObj = null,
        model = null,
        itemsDemoArray = [];
      const { orderNo, cusId, orderDate, deliveryDate, items } =
        singleOrderView;
      let customer = extractSingleObjectGeneral(customerList, "_id", cusId);
      if (customer !== undefined && customer !== null) [customerObj] = customer;
      setWorkOrderUserForm1({
        ...workOrderUserForm1,
        orderNo,
        orderDate,
        deliveryDate,
      });

      setSelectedCustomerObj(customerObj);
      let models = findModelsInEditingOrder(singleOrderView);
      if (models !== undefined && models !== null) [model] = models;
      if (shirtAndSuits?.includes(model)) {
        setDressType("shirtAndSuit");
        setActiveBtn(`${model.toLowerCase()}Btn`);
      } else {
        setDressType(model?.toLowerCase());
      }

      //1.Create Front end Order Preview List
      createProductItemsExpandedInCaseOfEdit(singleOrderView);
      //2.Create Backend Order Data
      itemsDemoArray = createItemsDemoArrayInCaseOfEdit(singleOrderView);
       setItemsDemoArray(itemsDemoArray);
      //Summary Create new fns instead of Functions which triggered when Add Button events invoked
    }
  };

  //hide invoice print button on scroll
  const hideInvoicePrint = () => {
    document.getElementById("invoicePrintIcon").style.display = "none";
  };
  //Update Listener
  const updateListener = () => {
    setIsModify(!isModify);
  };
  //Close Success snackbar
  const closeSuccessSnackbar = () => {
    setOpenSuccessSnack(false);
  };
  //Close Error snackbar
  const closeErrorSnackbar = () => {
    setOpenErrorSnack(false);
  };
  //Open Add New Customer Dialog
  const openAddNewCustomerDialog = () => {
    setOpenAddNewCustomer(true);
  };
  //Close Add New Customer Dialog
  const closeAddNewCustomerDialog = () => {
    setOpenAddNewCustomer(false);
    // initialCusRender.current = false;
    customerListApi()
    // viewAllCustomersAPICall({branchId:userRole==="admin"?null:branchPk})
  };
  //Open Add relationship dialog
  const openAddRelationshipDialog = () => {
    if (workOrderUserForm1?.customerId !== "0") {
      setAddRelationShip(true);
    } else {
      setCustomerAlert(true);
    }
  };
  const ClickShirtBtn = () => {
    document.getElementById("shirtBtn")?.classList.add("focus");
    document.getElementById("pantBtn")?.classList.remove("focus");
    document.getElementById("coatBtn")?.classList.remove("focus");
    document.getElementById("sharvaniBtn")?.classList.remove("focus");
    document.getElementById("safariBtn")?.classList.remove("focus");
    setShirtAndSuitId("shirt");
    setActiveBtn("shirtBtn");
  };
  const clickPantBtn = () => {
    document.getElementById("shirtBtn")?.classList.remove("focus");
    document.getElementById("pantBtn")?.classList.add("focus");
    document.getElementById("coatBtn")?.classList.remove("focus");
    document.getElementById("sharvaniBtn")?.classList.remove("focus");
    document.getElementById("safariBtn")?.classList.remove("focus");
    setShirtAndSuitId("pant");
    setActiveBtn("pantBtn");
  };
  const clickCoatBtn = () => {
    document.getElementById("shirtBtn")?.classList.remove("focus");
    document.getElementById("pantBtn")?.classList.remove("focus");
    document.getElementById("coatBtn")?.classList.add("focus");
    document.getElementById("sharvaniBtn")?.classList.remove("focus");
    document.getElementById("safariBtn")?.classList.remove("focus");
    setShirtAndSuitId("coat");
    setActiveBtn("coatBtn");
  };
  const clickSharvaniBtn = () => {
    document.getElementById("shirtBtn")?.classList.remove("focus");
    document.getElementById("pantBtn")?.classList.remove("focus");
    document.getElementById("coatBtn")?.classList.remove("focus");
    document.getElementById("sharvaniBtn")?.classList.add("focus");
    document.getElementById("safariBtn")?.classList.remove("focus");
    setShirtAndSuitId("sharvani");
    setActiveBtn("sharvaniBtn");
  };
  const clickSafariBtn = () => {
    document.getElementById("shirtBtn")?.classList.remove("focus");
    document.getElementById("pantBtn")?.classList.remove("focus");
    document.getElementById("coatBtn")?.classList.remove("focus");
    document.getElementById("sharvaniBtn")?.classList.remove("focus");
    document.getElementById("safariBtn")?.classList.add("focus");
    setShirtAndSuitId("safari");
    setActiveBtn("safariBtn");
  };
  //onchange fn for listen change of models [like thob,jalabia, etc]
  const dressTypeChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setDressType(value);
    }
  };

  //Open Add Payment Component
  const paymentButtonClick = () => {
    window.location = "#woUserHead";
    setPaymentBtn(true);
    // $('.workOrderPaymentTop').
    // $("html, body").animate({ scrollTop: 0 }, "slow");
  };
  const paymentContainerClose = () => {
    setPaymentBtn(false);
    setIsHeld(false)
  };

  //Open Edit Payment Component
  const editPaymentButtonClick = () => {
    window.location = "#woUserHead";
    setEditPaymentBtn(true);
  };
  const editPaymentContainerClose = () => {
    setEditPaymentBtn(false);
  };

  //set models for users into state
  const setModelsForUsers = (responseData) => {
    if (responseData !== undefined) {
      const { model } = responseData;
      setModelList(model);
    }
  };

  //create an array of product items for backend (not used the state anywhere)
  const createProductItemsBackend = () => {
    const { productId, productColorId, qty, itemRate, stitchRate, amount,taxes } =
      selectProductsForm;
    setProductItemsForBackend([
      ...productItemsForBackend,
      {
        type: dressType === "shirtAndSuit" ? shirtAndSuitId : dressType,
        itemId: productId,
        relationId:
          workOrderUserForm1?.relationShip === "0"
            ? null
            : workOrderUserForm1?.relationShip,
        colorId: productColorId,
        qty: parseFloat(qty),
        itemRate: parseFloat(itemRate),
        stitchRate: parseFloat(stitchRate===""?0:stitchRate),
        amount: parseFloat(amount),
        offer: null,
        taxes:selectedMaterialObj?.taxes,

        poscat:selectedMaterialObj?.poscat,
        mainCatgeoryId:selectedMaterialObj?.mainCatgeoryId,
        _uuid:generateUuid(),
        isOutCloth:selectedMaterialObj?.selectedMaterialObj
        
          // _uuid: "003e5ac8-9b7d-3a87-6e90-b2e7ec769a58",
          // status: "pending",
          // vatorgst: 0,
      }
    ]);
  };

  //Create product Items Expanded
  const createProductItemsExpanded = (uuid) => {

    if (productSingleObj?.length > 0) {
      const { productId, productColor, qty, itemRate, stitchRate, amount } =
        selectProductsForm;


      let inclusiveTax=0;
      let exclusiveTax=0;
      let totalAmt=Number(qty)*Number(itemRate)
      if(selectedTaxes?.length!==0 && selectedTaxes[0]?.includedPrice){
        let taxArray=selectedTaxes?.map((obj)=>obj?.amount)

        let totalOfTax=taxArray.reduce((sum,item)=>sum+item,0)
        let taxableAmount=(totalAmt/(totalOfTax+100))*100

        let totalInclTax = taxArray.reduce((total, taxPercentage) => {
          const discountAmount = (taxPercentage / 100) * taxableAmount;
          return total + discountAmount;
        }, 0);
        inclusiveTax=totalInclTax || 0
      }
      else if(selectedTaxes?.length!==0 && selectedTaxes[0]?.includedPrice===false){
        let totalTax=selectedTaxes?.reduce((sum,item)=>sum + item?.amount,0)
        let totalExclTax=(totalAmt*totalTax)/100
        exclusiveTax=totalExclTax
      }


      const { productName,isOutCloth } = productSingleObj[0];
      setProductItemsExpanded([
        ...productItemsExpanded,
        {
          _uuid: uuid,
          type: dressType === "shirtAndSuit" ? shirtAndSuitId : dressType,
          item: productName,
          qty,
          color: selectedColorSingleObj?.colorName,
          itemRate,
          stitchRate:stitchRate===""?0:stitchRate,
          amount,
          productId,
          colorId: selectedColorSingleObj?._id,
          cusId:
            workOrderUserForm1?.customerId !== "0"
              ? workOrderUserForm1?.customerId
              : null,
          relationId:
            workOrderUserForm1?.relationShip !== "0"
              ? workOrderUserForm1?.relationShip
              : null,
          poscat:selectedMaterialObj?.poscat,
          mainCatgeoryId:selectedMaterialObj?.mainCatgeoryId,
          taxes:selectedTaxes,
          exclusiveTax:exclusiveTax,
          inclusiveTax:inclusiveTax,
          isOutCloth:isOutCloth,
          measurements: {
            thobModelForm,
            jalabiaModelForm,
            salwarModelForm,
            abayaForm,
            churidharForm,
            shirtForm,
            pantForm,
            coatForm,
            sharvaniForm,
            safariForm,
          },
        },
      ]);
    }
  };

  //Create product Items collapsed
  const createProductItemsCollapsed = (expandedArray) => {
    let thobData = { item: null, qty: 0, rate: 0, amount: 0 };
    let jalabiaData = { item: null, qty: 0, rate: 0, amount: 0 };
    let salwarData = { item: null, qty: 0, rate: 0, amount: 0 };
    let abayaData = { item: null, qty: 0, rate: 0, amount: 0 };
    let churidharData = { item: null, qty: 0, rate: 0, amount: 0 };
    let shirtData = { item: null, qty: 0, rate: 0, amount: 0 };
    let pantData = { item: null, qty: 0, rate: 0, amount: 0 };
    let coatData = { item: null, qty: 0, rate: 0, amount: 0 };
    let sharvaniData = { item: null, qty: 0, rate: 0, amount: 0 };
    let safariData = { item: null, qty: 0, rate: 0, amount: 0 };

    if (expandedArray.length > 0) {
      expandedArray?.forEach((obj) => {
        switch (obj?.type) {
          case "thob":
            thobData.item = "Thob";
            thobData.qty += parseFloat(obj?.qty);
            thobData.rate += parseFloat(obj?.itemRate);
            thobData.amount += parseFloat(obj?.amount);
            // setProductItemsCollapsed([...productItemsCollapsed,thobData])
            break;
          case "jalabia":
            jalabiaData.item = "Jalabia";
            jalabiaData.qty += parseFloat(obj?.qty);
            jalabiaData.rate += parseFloat(obj?.itemRate);
            jalabiaData.amount += parseFloat(obj?.amount);
            // setProductItemsCollapsed([...productItemsCollapsed,jalabiaData])
            break;
          case "salwar":
            salwarData.item = "Salwar";
            salwarData.qty += parseFloat(obj?.qty);
            salwarData.rate += parseFloat(obj?.itemRate);
            salwarData.amount += parseFloat(obj?.amount);
            // setProductItemsCollapsed([...productItemsCollapsed,salwarData])
            break;
          case "abaya":
            abayaData.item = "Abaya";
            abayaData.qty += parseFloat(obj?.qty);
            abayaData.rate += parseFloat(obj?.itemRate);
            abayaData.amount += parseFloat(obj?.amount);
            // setProductItemsCollapsed([...productItemsCollapsed,abayaData])
            break;
          case "churidhar":
            churidharData.item = "Churidhar";
            churidharData.qty += parseFloat(obj?.qty);
            churidharData.rate += parseFloat(obj?.itemRate);
            churidharData.amount += parseFloat(obj?.amount);
            // setProductItemsCollapsed([...productItemsCollapsed,churidharData])
            break;
          case "shirt":
            shirtData.item = "Shirt";
            shirtData.qty += parseFloat(obj?.qty);
            shirtData.rate += parseFloat(obj?.itemRate);
            shirtData.amount += parseFloat(obj?.amount);
            // setProductItemsCollapsed([...productItemsCollapsed,shirtData])
            break;
          case "pant":
            pantData.item = "Pant";
            pantData.qty += parseFloat(obj?.qty);
            pantData.rate += parseFloat(obj?.itemRate);
            pantData.amount += parseFloat(obj?.amount);
            // setProductItemsCollapsed([...productItemsCollapsed,pantData])
            break;
          case "coat":
            coatData.item = "Coat";
            coatData.qty += parseFloat(obj?.qty);
            coatData.rate += parseFloat(obj?.itemRate);
            coatData.amount += parseFloat(obj?.amount);
            // setProductItemsCollapsed([...productItemsCollapsed,coatData])
            break;
          case "sharvani":
            sharvaniData.item = "Sharvani";
            sharvaniData.qty += parseFloat(obj?.qty);
            sharvaniData.rate += parseFloat(obj?.itemRate);
            sharvaniData.amount += parseFloat(obj?.amount);
            // setProductItemsCollapsed([...productItemsCollapsed,sharvaniData])
            break;
          case "safari":
            safariData.item = "Safari";
            safariData.qty += parseFloat(obj?.qty);
            safariData.rate += parseFloat(obj?.itemRate);
            safariData.amount += parseFloat(obj?.amount);
            // setProductItemsCollapsed([...productItemsCollapsed,safariData])
            break;

          default:
            break;
        }
        setProductItemsCollapsed([
          thobData,
          jalabiaData,
          salwarData,
          abayaData,
          churidharData,
          shirtData,
          pantData,
          coatData,
          sharvaniData,
          safariData,
        ]);
      });
    } else {
      setProductItemsCollapsed([]);
    }
  };

  //Clear Select products form after add
  const clearSelectProductForm = () => {
    setSelectProductsForm(selectProductsFormInitialState);
    setSelectedMaterialObj(null);
    setSelectedTaxes([])
    setSelectedColorSingleObj(null);
  };

  //Click add select product button
  const addSelectedProduct = () => {
    // const hasOutClothProduct = productItemsExpanded.some(product => product.isOutCloth);
     const hasSameIsOutClothProduct = productItemsExpanded.some(product => product.isOutCloth === selectedMaterialObj.isOutCloth);

    const { productId, qty, itemRate, stitchRate, amount } = selectProductsForm;  // destructuring....

    createWorkOrderBodyArray();
    if (productId === "0") {
      setProductAlert(true);
    } else if (qty === "") {
      setProductQtyAlert(true);
    } else if (itemRate === "") {
      setProductRateAlert(true);
    } else {
      if (workOrderSettings?.outClothOrder) {
        if (hasSameIsOutClothProduct || productItemsExpanded.length === 0) {
          // createProductItemsBackend();
          createProductItemsExpanded(_uuid);
          clearSelectProductForm();
          createMeasurementUpdateSingleTypeObj(dressType);
          setAddProductsAlert(false);
        } else {
          setSnackMsg("cannot add this type")
          setOpenErrorSnack(true)
        }
      }
  else{
    createProductItemsBackend();
    createProductItemsExpanded(_uuid);
    clearSelectProductForm();
    createMeasurementUpdateSingleTypeObj(dressType);
    setAddProductsAlert(false);
  }
    
     
    }
  };

console.log(addRelationshipForm?.newNameInput);
console.log(addRelationshipForm?.newName);
 
  //add new relationship body
  const addNewRelationshipBody = {
    refferedBy: workOrderUserForm1?.customerId,
    // name: addRelationshipForm?.name,
    name: addRelationshipForm?.newName===null || addRelationshipForm?.newName===undefined ? addRelationshipForm?.newNameInput : addRelationshipForm?.newName?.name,
   
    mobileNo: addRelationshipForm?.mobileNo,
    buildingName: addRelationshipForm?.buildingName,
    streetName: addRelationshipForm?.streetName,
    landMark: addRelationshipForm?.landmark,
    email: addRelationshipForm?.email,
    reference: addRelationshipForm?.reference,
    relType: addRelationshipForm?.relationship,
  };
  const addNewRelationship = () => {
    const { name, mobileNo } = addRelationshipForm;
    // if (addRelationshipForm?.newName===null || addRelationshipForm?.newName===undefined||addRelationshipForm?.newName===""||addRelationshipForm?.newName===undefined) {
    //   setRelationNameAlert(true);
    // } else if (mobileNo === "") {
    //   setRelationMobileAlert(true);
    // } else {
      addNewCustomerRelationshipAPICall(
        addNewRelationshipBody,
        setOpenSuccessSnack,
        setOpenErrorSnack,
        setSnackMsg,
        updateListener,
        clearAddRelationshipForm,setLoadingForm
      );
      console.log(addNewRelationshipBody);
      
    // }
  };
  //Clear add relationship form
  const clearAddRelationshipForm = () => {
    setAddRelationshipForm(addRelationshipFormInitialState);
    updateListener();
  };

  //*Create workOrder Final Body Objects  Algo... step 1 (onMouseDown Fn)
  const createWorkOrderBodyObjects = () => {
    // console.log("measured hereeee");
    if (selectProductsForm?.productId === "0") {
      setProductAlert(true);
    } else if (selectProductsForm?.qty === "") {
      setProductQtyAlert(true);
    } else if (selectProductsForm.itemRate === "") {
      setProductRateAlert(true);
    } else {
      let typeOfDress = null;
      let singleProductObj = {};
      if (dressType === "shirtAndSuit") {
        typeOfDress = shirtAndSuitId;
      } else {
        typeOfDress = dressType;
      }

      //generate a uuid
      let newUuid =productEditRow===null? generateUuid():productEditRow?._uuid;

      setUuid(newUuid);

      //Step 1 create an object of single products

      singleProductObj._uuid = newUuid;
      singleProductObj.itemId = selectProductsForm?.productId;
      singleProductObj.relationId =
        workOrderUserForm1?.relationShip !== "0"
          ? workOrderUserForm1?.relationShip
          : null;
      singleProductObj.colorId =
        selectProductsForm?.productColorId === ""
          ? null
          : selectProductsForm?.productColorId;
      singleProductObj.qty = parseFloat(selectProductsForm?.qty);
      singleProductObj.itemRate = parseFloat(selectProductsForm?.itemRate);
      singleProductObj.stitchRate = parseFloat(selectProductsForm?.stitchRate);
      singleProductObj.amount = parseFloat(selectProductsForm?.amount);
      singleProductObj.offer = null; //set offer from material product list data in future
      singleProductObj.status = "pending";
      singleProductObj.poscat = selectedMaterialObj?.poscat;
      singleProductObj.mainCatgeoryId = selectedMaterialObj?.mainCatgeoryId;
      singleProductObj.taxes = selectedTaxes;
      singleProductObj.includedPrice = selectedTaxes?.length!==0 ?selectedTaxes[0]?.includedPrice:null;
      singleProductObj.isOutCloth=selectedMaterialObj?.isOutCloth


      //Step 2 create an object of single measurements

      switch (typeOfDress) {
        case "thob":
          setItemsDemoObject({
            ...thobModelForm,
            productItems: [],
            type: "thob",
            relationId:
              workOrderUserForm1?.relationShip !== "0"
                ? workOrderUserForm1?.relationShip
                : null,
          });
          break;
        case "jalabia":
          setItemsDemoObject({
            ...jalabiaModelForm,
            productItems: [],
            type: "jalabia",
            relationId:
              workOrderUserForm1?.relationShip !== "0"
                ? workOrderUserForm1?.relationShip
                : null,
          });
          break;
        case "salwar":
          setItemsDemoObject({
            ...salwarModelForm,
            productItems: [],
            type: "salwar",
            relationId:
              workOrderUserForm1?.relationShip !== "0"
                ? workOrderUserForm1?.relationShip
                : null,
          });
          break;
        case "abaya":
          setItemsDemoObject({
            ...abayaForm,
            productItems: [],
            type: "abaya",
            relationId:
              workOrderUserForm1?.relationShip !== "0"
                ? workOrderUserForm1?.relationShip
                : null,
          });
          break;
        case "churidhar":
          setItemsDemoObject({
            ...churidharForm,
            productItems: [],
            type: "churidhar",
            relationId:
              workOrderUserForm1?.relationShip !== "0"
                ? workOrderUserForm1?.relationShip
                : null,
          });
          break;
        case "shirt":
          setItemsDemoObject({
            ...shirtForm,
            productItems: [],
            type: "shirt",
            relationId:
              workOrderUserForm1?.relationShip !== "0"
                ? workOrderUserForm1?.relationShip
                : null,
          });
          break;
        case "pant":
          setItemsDemoObject({
            ...pantForm,
            productItems: [],
            type: "pant",
            relationId:
              workOrderUserForm1?.relationShip !== "0"
                ? workOrderUserForm1?.relationShip
                : null,
          });
          break;
        case "coat":
          setItemsDemoObject({
            ...coatForm,
            productItems: [],
            type: "coat",
            relationId:
              workOrderUserForm1?.relationShip !== "0"
                ? workOrderUserForm1?.relationShip
                : null,
          });
          break;
        case "sharvani":
          setItemsDemoObject({
            ...sharvaniForm,
            productItems: [],
            type: "sharvani",
            relationId:
              workOrderUserForm1?.relationShip !== "0"
                ? workOrderUserForm1?.relationShip
                : null,
          });
          break;
        case "safari":
          setItemsDemoObject({
            ...safariForm,
            productItems: [],
            type: "safari",
            relationId:
              workOrderUserForm1?.relationShip !== "0"
                ? workOrderUserForm1?.relationShip
                : null,
          });
          break;

        default:
          break;
      }

      setChildProductItemsObject(singleProductObj);
      
    }

  };

 


  //* Create WorkOrder Final Body Array Algo... step 2 (onMouseClick Fn)
  const createWorkOrderBodyArray = () => {

    let isParentExist = false;
    if (itemsDemoArray.length === 0) {
      //push itemsDemoObject into itemsDemoArray
      if(isWoEdit && productEditRow!==null){
        itemsDemoObject.id=productEditRow?.modelItemId
      }
      itemsDemoObject?.productItems?.push(childProductItemsObject);


      setItemsDemoArray([...itemsDemoArray, itemsDemoObject]);
    } else {

      for (let i = 0; i < itemsDemoArray.length; i++) {
        
        let obj = itemsDemoArray[i];
       
        //Check  length of objects are same
        if (Object.keys(obj).length === Object.keys(itemsDemoObject).length) {
          let objKeys = Object.keys(obj);
          let demoObjKeys = Object.keys(itemsDemoObject);
          let sameObjCount = 0;
          let isSameKeys = false;


          //check all keys of obj with items demo object
          for (let i = 0; i < demoObjKeys.length; i++) {
            if (objKeys[i] === demoObjKeys[i]) {
              isSameKeys = true;
            } else {
              isSameKeys = false;
              break;
            }
          }


          //Check All key values are same only if both objects keys are same
          if (isSameKeys) {
            for (let i = 0; i < demoObjKeys.length; i++) {
              const key = demoObjKeys[i];
              if (key !== "productItems") {
                if (obj[key] === itemsDemoObject[key]) {
                  sameObjCount += 1;
                }
              }
            }
          }

          //if sameObjCount = demoObjKeys.length-1 the objects are equal
          if (sameObjCount == demoObjKeys.length - 1) {
            if(isWoEdit && productEditRow!==null ){

              obj.id=productEditRow?.modelItemId
            }
            obj?.productItems?.push(childProductItemsObject);
            isParentExist = true;
            break;
          } else {
          }

          if (isParentExist) break;
        } else {
          //TODO create new parent object
        }
        if (isParentExist) break;
      }
      if (!isParentExist) {
        if(isWoEdit && productEditRow!==null){
          itemsDemoObject.id=productEditRow?.modelItemId
        }
        itemsDemoObject?.productItems?.push(childProductItemsObject);
        setItemsDemoArray([...itemsDemoArray, itemsDemoObject]);
      }
    }
    
    setProductEditRow(null)
    setEditedProductIndex(null)

  };

  // const createWorkOrderBodyArray = () => {

  //   // Check if itemsDemoArray is empty
  //   if (itemsDemoArray.length === 0) {
  //     // If empty, directly push itemsDemoObject with productItems
  //     itemsDemoObject.productItems.push(childProductItemsObject);
  //     setItemsDemoArray([...itemsDemoArray, { ...itemsDemoObject }]);
  //     return;
  //   }
  
  
  //   const matchingParent = itemsDemoArray.find((obj) => {
  //     return obj.type === itemsDemoObject.type && obj.relationId === itemsDemoObject.relationId;
  //   });
  //   if (matchingParent) {
  //     // If matching parent exists, push childProductItemsObject to its productItems array
  //     matchingParent.productItems.push(childProductItemsObject);
  //   } else {
  //     // If no matching parent is found, create a new parent object with productItems
  //     let newParent={}
  //     if(isWoEdit && productEditRow!==null){  
  //       newParent = { ...itemsDemoObject, productItems: [childProductItemsObject],id:productEditRow?.modelItemId };
  //     }else{
  //       newParent = { ...itemsDemoObject, productItems: [childProductItemsObject] };
  //     }

  //     setItemsDemoArray([...itemsDemoArray, newParent]);
  //   }
  //   setProductEditRow(null)
  // };

  //Calculate total Amount of Order
  const calculateTotalAmount = () => {
    let totalAmount = 0;
    let exclusiveTax = 0;
    let inclusiveTax = 0;

    productItemsExpanded.forEach((obj) => {
      totalAmount += parseFloat(obj?.amount);
      exclusiveTax += parseFloat(obj?.exclusiveTax);
      inclusiveTax += parseFloat(obj?.inclusiveTax);
    });
    setExclusiveTaxes(exclusiveTax)
    setInclusiveTaxes(inclusiveTax)
    setWorkOrderTotal(totalAmount+exclusiveTax);

  };

  // Calculate total Credit of a Customer
  const calculateTotalCreditAmount = (customerCredit) => {
    let totalCredit = 0;
    if (customerCredit !== undefined) {
      customerCredit.forEach((obj) => {
        totalCredit += parseFloat(obj?.balance);
      });
    } else {
      totalCredit = 0;
    }
    return totalCredit;

  };


  const clearWorkOrderState = () => {
    clearSelectProductForm();
    setOrderType("retail");
    setWorkOrderUserForm1(workOrderUserForm1InitialState);
    setSelectedCustomerObj(null);
    setItemsDemoArray([]);
    setWorkOrderTotal(0);
    setChildProductItemsObject({});
    setItemsDemoObject({});
    setProductItemsCollapsed([]);
    setProductItemsExpanded([]);
    setProductItemsForBackend([]);
    setProductSingleObj([]);
    setSingleCustomerObj([]);

    if (userInfo !== undefined) {
      setWorkOrderUserForm1({
        ...workOrderUserForm1,
        salesMan: userInfo?.data?._id,
      });
    }
    setWorkOrderUserForm1(workOrderUserForm1InitialState);
    setThobModelForm(thobModelFormInitialState);
    setJalabiaModelForm(jalabiaModelFormInitialState);
    setSalwarModelForm(salwarModelFormInitialState);
    setAbayaForm(abayaModelFormInitialState);
    setChuridharForm(churidharFormInitialState);
    setShirtForm(shirtFormInitialState);
    setPantForm(pantFormInitialState);
    setCoatForm(coatFormInitialState);
    setSharvaniForm(sharvaniAndSafariFormInitialState);
    setSafariForm(sharvaniAndSafariFormInitialState);
    localStorage.removeItem("ORD_ID_NEW_ORD");
    localStorage.removeItem("REL_ID");
    localStorage.removeItem("IS_REL");
    updateListener();
    setIsHeldOrder(false);
    setHeldPayingObject(null);
    // setPaymentBtn(false)
  };
  //Open Invoice Dialog
  const openInvoiceDialog = () => {
    setInvoiceDialog(true);
  };
  //*New work order without payment
  const newWorkOrderWOPaymentBody = useMemo(
    () => ({
      isRetail: orderType === "retail" ? true : false,
      cusId:
        workOrderUserForm1?.customerId !== "0"
          ? workOrderUserForm1?.customerId
          : null,
      contactNo: selectedCustomerObj?.mobileNo,
      salesMan:
        workOrderUserForm1?.salesMan !== "0"
          ? workOrderUserForm1?.salesMan
          : null,
      orderDate: isHeldOrder
        ? `${workOrderUserForm1?.orderDate}`
        : `${
            workOrderUserForm1?.orderDate
          } ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
      deliveryDate: workOrderUserForm1?.deliveryDate,
      branchId: loginResponse?.branchPk,
      items: itemsDemoArray,
      total: workOrderTotal,
      measurement: allMeasurementObjectsArray,
      heldOrderId: isHeldOrder ? heldPayingObject?._id : null,
      // discount: 0,
    }),
    [
      orderType,
      workOrderUserForm1?.customerId,
      workOrderUserForm1?.contact,
      workOrderUserForm1?.salesMan,
      workOrderUserForm1?.orderDate,
      workOrderUserForm1?.deliveryDate,
      itemsDemoArray,
      workOrderTotal,
      allMeasurementObjectsArray,
      heldPayingObject,
      isHeldOrder,
    ]
  );
  const itemsFirstItem = itemsDemoArray && itemsDemoArray.length > 0 ? itemsDemoArray[0] : null;

  const updatedItemsArray = itemsDemoArray && itemsDemoArray.length > 0
    ? itemsDemoArray.map(item => ({ ...item, id: itemsFirstItem.id }))
    : [];


  //Work order Edit body without payment
  const editWorkOrderWOPaymentBody = {
    salesMan:
      workOrderUserForm1?.salesMan !== "0"
        ? workOrderUserForm1?.salesMan
        : null,
    orderDate: `${
      workOrderUserForm1?.orderDate
    } ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
    deliveryDate: workOrderUserForm1?.deliveryDate,
    orderId: editingWoId,
    cusId:
      workOrderUserForm1?.customerId !== "0"
        ? workOrderUserForm1?.customerId
        : null,
    branchId: loginResponse?.branchPk,
    // items: itemsDemoArray,
    items:editItemToBackEnd,
    total: workOrderTotal,
    measurement: allMeasurementObjectsArray,
    editDate: today,
    // discount: 0,
  };


 useEffect(()=>{
  const mergedItemsArray = [];

  itemsDemoArray?.forEach(item => {
    const existingItem = mergedItemsArray.find(element => element.id === item.id);
  
    if (existingItem) {
     
      existingItem.productItems = existingItem.productItems || [];
    
      existingItem.productItems = existingItem.productItems.concat(item.productItems);
    } else {
   
      mergedItemsArray.push({...item});
    }
  });
  setEditItemToBackEnd(mergedItemsArray)
},[itemsDemoArray])

  

  


















  //* Create work order without payment
  const createWorkOrderWithoutPayment = () => {
    const { customerId, salesMan } = workOrderUserForm1;
    if (
      window.confirm("Would you like to create work order without payment?") ===
      true
    ) {
      if (customerId === "0" || customerId===undefined) {
        setCustomerAlert(true);
      } else if (salesMan === "0") {
        setSalesManAlert(true);
      } else if (itemsDemoArray.length === 0) {
        setAddProductsAlert(true);
      } else {
        createNewWorkOrderAPICall(
          newWorkOrderWOPaymentBody,
          setIsLoading,
          setOpenSuccessSnack,
          setOpenErrorSnack,
          setSnackMsg,
          updateListener,
          clearWorkOrderState,
          openInvoiceDialog
        );
        
      }
    } else {
      alert("Action denied.");
    }
  };

  //Edit Work Order Without payment
  const editWorkOrderWithoutPayment = () => {
    const { customerId, salesMan } = workOrderUserForm1;

    if (customerId === "0") {
      setCustomerAlert(true);
    } else if (salesMan === "0") {
      setSalesManAlert(true);
    } else if (itemsDemoArray.length === 0) {
      setAddProductsAlert(true);
    } else {
      editWorkOrderAPICall(
        editWorkOrderWOPaymentBody,
        setIsLoading,
        setOpenSuccessSnack,
        setOpenErrorSnack,
        setSnackMsg,
        updateListener,
        clearWorkOrderState,
        clearEditForms
      );
     
    }
  };

  //*Payment Place Order Fn
  const paymentPlaceOrder = () => {
    console.log(billingForm);
  };

  //* Delete Items From Work Order preview box
  const deleteItemsFromProductItemsExpanded = (index) => {
    productItemsExpanded?.reverse()?.splice(index, 1);
    setProductItemsExpanded([...productItemsExpanded]);
    
  };

  //* Edit Items from work order preview box
  const editItemsFromWorkOrderPreview = (row, itemList, fIndex) => () => {

    setProductEditRow(row)
    const {
      productId,
      colorId,
      qty,
      itemRate,
      stitchRate,
      cusId,
      relationId,
      type,
      measurements,
      isOutCloth
    } = row;
    const shirtAndSuit = ["shirt", "pant", "coat", "sharvani", "safari"];
    let sProduct = extractSingleProdObj(materialProductList, productId);
    let sColor = extractSingleProdObj(allCreatedColorsList, colorId);
    let sCustomer = extractSingleProdObj(customerList, cusId);
    setEditLineClicked(true)
    //Make first letter capital
    const fLetter = type.charAt(0).toUpperCase();
    //First letter capitalized version
    const capType = type.replace(type.charAt(0), fLetter);

    setSelectedMaterialObj(sProduct);
    setSelectedColorSingleObj(sColor);

    setSelectedTaxes(row?.taxes || [])

    setSelectProductsForm({
      ...selectProductsForm,
      qty,
      itemRate,
      stitchRate,
      productId,
      isOutCloth,
      productColorId: colorId,
    });
    setSelectedCustomerObj(sCustomer);
    setWorkOrderUserForm1({
      ...workOrderUserForm1,
      relationShip: relationId || "0",
      customerId: cusId,
    });

    //to check corresponding radios and shirt and suit btn focus
    if (shirtAndSuit.includes(type)) {
      //shirt and suiting
      document.getElementById("shirtAndSuit").checked = true;
      setDressType("shirtAndSuit");
      switch (type) {
        case "shirt":
          ClickShirtBtn();
          break;
        case "pant":
          clickPantBtn();
          break;
        case "coat":
          clickCoatBtn();
          break;
        case "sharvani":
          clickSharvaniBtn();
          break;
        case "safari":
          clickSafariBtn();
          break;

        default:
          break;
      }
    } else {
      //other models
      document.getElementById(capType).checked = true;
      setDressType(type);
    }

    //Restore Entered measurements
    setThobModelForm(measurements.thobModelForm);
    setJalabiaModelForm(measurements.jalabiaModelForm);
    setSalwarModelForm(measurements.salwarModelForm);
    setAbayaForm(measurements.abayaForm);
    setChuridharForm(measurements.churidharForm);
    setShirtForm(measurements.shirtForm);
    setPantForm(measurements.pantForm);
    setCoatForm(measurements.coatForm);
    setSharvaniForm(measurements.sharvaniForm);
    setSafariForm(measurements.safariForm);

    //Remove selected Row from front end table
    findProductObject(row, itemList, fIndex);

  };

  //Filter by name and mob no in same input field
  // const filterOptions = createFilterOptions({
  //   matchFrom: "any",
  //   stringify: (option) => option?.name + option.mobileNo,
  // });
  const filterOptions = (options, { inputValue }) => {
    const lowerCasedInput = inputValue.toLowerCase();
    return options.filter(option =>
      option.name.toLowerCase().includes(lowerCasedInput) ||
      option.mobileNo.includes(lowerCasedInput)
    );
  };
  //Clear Edit Forms
  const clearEditForms = () => {
    localStorage.removeItem("IS_WO_EDIT");
    localStorage.removeItem("WO_EDIT_ID");
    assignNewWorkOrderId();
    setSelectedCustomerObj(null);
    setActiveBtn(null);
    setDressType(null);
    setItemsDemoArray([]);
    setProductItemsExpanded([]);
    updateListener();
    setEditPaymentBtn(false);
    setIsHeldOrder(false);
    setHeldPayingObject(null);
  };

  //Click View Orders ShortCut Btn
  const clickViewOrdersShortcut = () => {
    //TODO
    setOpenViewOrderShortDialog(true);
  };
  const closeViewOrderShortcutDialog = () => {
    setOpenViewOrderShortDialog(false);
  };

  //Show Snackbar if editing model type  is not permitted to edit in an outlet
  const showEditNotPermittedInfo = (modelType) => () => {
    setSnackMsg(`Edit is not permitted on ${modelType}!!`);
    setOpenErrorSnack(true);
  };

  //Open Held Orders Dialog in view order page
  const openHeldOrders = () => {
    setPaymentBtn(true);
    setIsHeld(true)
    setTimeout(() => {
      heldProductBtnRef.current.click();
    }, 5);
  };
  //Hold order Body
  const holdOrderBody = useMemo(
    () => ({
      // orderId: workOrderId?.transNo,
      _id: isHeldOrder ? heldPayingObject?._id : null, // null in first time hold,,  _id in other times hold
      cusId: workOrderUserForm1.customerId,
      branchId: storeCode,
      order: newWorkOrderWOPaymentBody,
    }),
    [
      workOrderUserForm1.customerId,
      storeCode,
      newWorkOrderWOPaymentBody,
      isHeldOrder,
      heldPayingObject,
    ]
  );

  //Click pay btn in Held order dialog on paymentsWo component
  const clickPayHeldOrder = (payingIndex) => {

    if (heldWorkOrderList !== undefined) {
      let payingObject = heldWorkOrderList[payingIndex];
      setIsHeldOrder(true);
      setHeldPayingObject(payingObject);
    }
  };

  //Clear Hold mode btn click
  const clearHoldModeBtnClick = () => {
    clearWorkOrderState();
  };

  // name auto select
  // const handleInputChange = (e, newValue) => {
   
  //   let filteredSuggestions = customerList.filter((obj) =>
  //     obj.name.toLowerCase().includes(newValue.toLowerCase())
    
     
  //   );
  //   setSuggestions(filteredSuggestions);

  //   setShowSuggestions(newValue !== "");
  //   if (newValue === "") {
  //     setSuggestions([]);
  //   }
   
  // };
  /// new code on mobile search
  const handleInputChange = (e, newValue) => {
    console.log(newValue);
    // let filteredSuggestions = customerList.filter((obj) => {
    //   return obj.mobileNo.toLowerCase().includes(newValue.toLowerCase());
    // });
     let filteredSuggestions = customerList.filter((obj) =>
       obj.name.toLowerCase().includes(newValue.toLowerCase())
    
     
     );
    setSuggestions(filteredSuggestions);
    setShowSuggestions(newValue !== "");
    if (newValue === "") {
      setSuggestions([]);
    }
  };
 


  const handleCusNameChange = (event, newValue) => {
    if (newValue !== null) {
      setShowSuggestions(false);
      // setCusName(newValue.name);
      setAddRelationshipForm({
        ...addRelationshipForm,
        name: newValue.name,
        mobileNo: newValue.mobileNo,
        buildingName: newValue.buildingName,
        streetName: newValue.streetName,
        landmark: newValue.landMark,
        email: newValue.email,
      });
      setIsEnableInputs(true);
      // setAddRelationshipForm({...addRelationshipForm,customerId:newValue.cusId})
    } else {
      // setCusName(event.target.value)
      setAddRelationshipForm({
        ...addRelationshipForm,
        name: event.target.value,
      });
      setShowSuggestions(true);
    }
  };
  const handleTextFieldChange = (e) => {
    setAddRelationshipForm({ ...addRelationshipForm, name: e.target.value });
    // setCusName(e.target.value)
    if (e.target.value === "" || suggestions.length === 0) {
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    if (suggestions.length === 0) {
      setShowSuggestions(false);
      setAddRelationshipForm({
        ...addRelationshipForm,
        mobileNo: "",
        buildingName: "",
        streetName: "",
        landmark: "",
        email: "",
      });
    }
  }, [suggestions]);


  //*useEffects

  //Call Previous work order details if local storage has its id
  useEffect(() => {
    if (prevOrderId !== null) {
      workOrderSingleViewAPICall({ orderId: prevOrderId },setLoadingForm);
    }
    if (editingWoId !== null) {
      workOrderSingleViewAPICall({ orderId: editingWoId },setLoadingForm);
    }
    customerListApi()
    
  }, []);

  //Initial calls
  useEffect(() => {
    generateWoNoAPICall({ branchId: storeCode });
    getAllActiveEmployeesAPICall();
    // viewAllCustomersAPICall({branchId:userRole==="admin"?null:branchPk})

    if (userInfo?.branchPk !== undefined) {
      getModelsFromOutletAPICall({ branchId: userInfo?.branchPk });
    }
    generateCustomerRelationIdAPICall();
    // viewAllMaterialProductsAPICall();
    // viewAllMaterialProductsWithStockAPICall({
    //   itemType: 2,
    //   branchId: storeCode,
    // });
    viewAllCustomerRelationsAPICall({ _id: workOrderUserForm1?.customerId });
    viewAllColorsAPICall();
    getAllTaxSettingsListAPICall();
    viewWoSettingsAPICall({branchId:branchPk})
  }, [isModify]);
  //Set Last value of customer list selected
  useEffect(() => {
    if (!initialCusRender.current) {
      if (customerList !== undefined) {
        setSelectedCustomerObj(customerList[customerList?.length - 1]);
      }
    } 
  }, [openAddNewCustomer, customerList]);


  //Call Function like extract customer .. as single order data as dependency
  useEffect(() => {
    if (prevOrderId !== null) {
      extractCustomerObjBasedOnPrevOrder(filteredSingleViewWorkOrder);
    }
    if (isWoEdit) {
      //call Fill edit values to states
      if (customerList !== undefined) {
        fillEditValuesToStates(filteredSingleViewWorkOrder);
      }

      if (filteredSingleViewWorkOrder.length !== 0) {
        //destructure previous payment details and save to state
        const {
          shipmentCharge,
          total: prevTotal,
          usedPoints,
          usedWallet,
          discount,
          paymentDetails,
        } = filteredSingleViewWorkOrder;
        let prevPaymentForm = {
          shipmentCharge,
          prevTotal,
          usedPoints,
          usedWallet,
          discount,
          paidAmount:
            paymentDetails !== null
              ? paymentDetails.length == 1
                ? paymentDetails.map((obj) => ({ total: obj.paidAmount }))[0]
                : paymentDetails.reduce((accu, cur) => ({
                    total: accu.paidAmount + cur.paidAmount,
                  }))
              : 0,
        };

        setPrevPayments(prevPaymentForm);
        setPreviousPaymentObject(paymentDetails);
      }
    }
  }, [filteredSingleViewWorkOrder, customerList]);
  useEffect(() => {
    if (
      singleViewOfAWorkOrder !== undefined &&
      filteredSingleViewWorkOrder.length === 0
    ) {
      setFilteredSingleViewWorkOrder(singleViewOfAWorkOrder);
      
    }
  }, [singleViewOfAWorkOrder]);
  

 
  //fill form in case of pay held order
  useEffect(() => {
    if (isHeldOrder) {
      fillEditValuesToStates(heldPayingObject?.order);
    }
  }, [heldPayingObject]);

  //Set selected material product id into state
  useEffect(() => {
    if (selectedMaterialObj !== null) {
      setSelectProductsForm({
        ...selectProductsForm,
        productId: selectedMaterialObj?._id,
      });
    } else {
      setSelectProductsForm({ ...selectProductsForm, productId: "0" });
    }
  }, [selectedMaterialObj]);
  //Set selected Customer id into state
  useEffect(() => {
    if (selectedCustomerObj !== null) {
      setWorkOrderUserForm1({
        ...workOrderUserForm1,
        customerId: selectedCustomerObj?._id,
      });
    } else {
      setWorkOrderUserForm1({
        ...workOrderUserForm1,
        customerId: "0",
        relationShip: "0",
      });
    }
  }, [selectedCustomerObj]);

  //Set work order id to state
  useEffect(() => {
    if (!isWoEdit) assignNewWorkOrderId();
  }, [workOrderId]);
  //Set Customer Relation id generated to its state
  useEffect(() => {
    if (customerRelationId !== undefined) {
      const { prefix } = customerRelationId;
      setAddRelationshipForm({ ...addRelationshipForm, customerId: prefix });
    }
  }, [customerRelationId]);

  //Extract Selected Customer Info obj for contact number and Call customer relationship by customer id,get customer wallet
  useEffect(() => {
    let extractedCustomer = extractObjectForSingleView(
      workOrderUserForm1?.customerId,
      customerList
    );
    setWorkOrderUserForm1({
      ...workOrderUserForm1,
      contact: extractedCustomer[0]?.mobileNo || "",
    });
    setSingleCustomerObj(extractedCustomer);

    if (workOrderUserForm1?.customerId !== "0") {
      //Call Relationship by customer id
      viewAllCustomerRelationsAPICall({ _id: workOrderUserForm1?.customerId });
      // //Get Wallet amount of customer
      // viewWalletTotalByCustomerIdAPICall({ cus_id: workOrderUserForm1?.customerId });
      // getCreditOfCustomerAPICall({_id:workOrderUserForm1?.customerId})
    } else {
      store.dispatch(update_relationship_list({ relationList: undefined }));
      store.dispatch(
        update_wallet_of_customer({ walletOfCustomer: undefined })
      );
      store.dispatch(update_credit_of_customer({ singleCusCredit: undefined }));
    }
  }, [workOrderUserForm1?.customerId]);
  //Set Logged in SalesMan id to state
  useEffect(() => {
    if (userInfo !== undefined) {
      setWorkOrderUserForm1({
        ...workOrderUserForm1,
        salesMan: userInfo?.data?._id,
      });
    }
  }, [userInfo, isModify]);
  //Set models for user to state
  useEffect(() => {
    setModelsForUsers(modelsForOutlet);
  }, [modelsForOutlet]);
  //extract single product object
  useEffect(() => {
    if (selectProductsForm?.productId !== "0") {
      let singleObj = extractObjectForSingleView(
        selectProductsForm?.productId,
        materialProductList
      );
      setProductSingleObj(singleObj);
    }
  }, [selectProductsForm?.productId]);
  //Set Product Color to state from single obj
  useEffect(() => {
    if (productSingleObj?.length > 0) {
      const { colorName, color, calculatedPrice } = productSingleObj[0];
      if (calculatedPrice !== null) {
        setSelectProductsForm({
          ...selectProductsForm,
          productColor: colorName,
          // productColorId: color,
          itemRate: calculatedPrice,
        });
      } else {
        setSelectProductsForm({
          ...selectProductsForm,
          // productColor: "",
          productColorId: null,
          itemRate: 0,
        });
      }
    }
  }, [productSingleObj]);
  //Call held product list
  useEffect(() => {
    getHeldWorkOrdersAPICall({
      branchId: storeCode,
      cusId: workOrderUserForm1.customerId,
    });
  }, [workOrderUserForm1.customerId]);

  //Calculate Total Amount
  useEffect(() => {
    const { qty, itemRate, stitchRate } = selectProductsForm;
    let amount = calculateWorkOrderTotalAmount(itemRate, stitchRate, qty);
    setSelectProductsForm({ ...selectProductsForm, amount });
  }, [
    selectProductsForm?.qty,
    selectProductsForm?.itemRate,
    selectProductsForm?.stitchRate,
  ]);
  //Call create productList function
  useEffect(() => {
    createProductItemsCollapsed(productItemsExpanded);
    calculateTotalAmount();

    if(productItemsExpanded.length!==0){

      setTotalsInfo({...totalsInfo,
        subTotal:productItemsExpanded?.reduce((sum,item)=>sum+(item?.amount|| 0),0),
        exclusiveTax:productItemsExpanded?.reduce((sum,item)=>sum+(item?.exclusiveTax ||0),0),
        inclusiveTax:productItemsExpanded?.reduce((sum,item)=>sum+(item?.inclusiveTax || 0),0)
      })
    }
    else{
      setTotalsInfo({...totalsInfo,
        subTotal:0,
        exclusiveTax:0,
        inclusiveTax:0
      })
    }
  }, [productItemsExpanded]);

  //Assign SubTotal To Payment Form State
  useEffect(() => {
    setBillingForm({ ...billingForm, subTotal: workOrderTotal });
  }, [workOrderTotal]);

  // Calculate billing Total Amount (subTotal+shipment charge  as of now)
  useEffect(() => {
    let totalAmount =
      parseFloat(billingForm?.subTotal) +
      parseFloat(billingForm?.shipmentCharge);
    if (isNaN(totalAmount)) {
      setPaymentTotalAmount(billingForm?.subTotal);
    } else {
      setPaymentTotalAmount(totalAmount);
    }
  }, [billingForm?.subTotal, billingForm?.shipmentCharge]);

  //Call getCustomerMeasurements API
  // useEffect(() => {
  //   const { customerId, relationShip } = workOrderUserForm1;
  //   console.log(customerId)
  //   console.log(relationShip);
  //   if (customerId !== "0") {
  //     getCustomerMeasurements({
  //       clientId: customerId,
  //       clientType: 0,
  //     });
  //   } else {
  //     store.dispatch(
  //       update_customer_measurements({ customerMeasurements: undefined })
  //     );
  //     setTypeFormStatesInitial();
  //   }
  //   if (relationShip !== "0") {
  //     getCustomerMeasurements({
  //       clientId: relationShip,
  //       clientType: 1,
  //     });
  //   } else {
  //     store.dispatch(
  //       update_customer_measurements({ customerMeasurements: undefined })
  //     );
  //     setTypeFormStatesInitial();
  //   }
  // }, [workOrderUserForm1.customerId, workOrderUserForm1.relationShip]);

  useEffect(() => {
    const { customerId, relationShip } = workOrderUserForm1;
    console.log(customerId)
    console.log(relationShip);
 
    if (relationShip !== "0") {
      getCustomerMeasurements({
        clientId: relationShip,
        clientType: 1,
      },setTypeFormStatesInitial);
    } else {
      getCustomerMeasurements({
           clientId: customerId,
            clientType: 0,
          },setTypeFormStatesInitial);
      
    }
  }, [workOrderUserForm1.customerId, workOrderUserForm1.relationShip]);

  // console.log(clientMeasurement);

  //Assign Customer or relation measurements
  useEffect(() => {
    if (isHeldOrder) {
      let measurement = changeMeasurementStructure(
        heldPayingObject?.order?.measurement
      );
      assignCustomerOrRelationMeasurements(measurement);
    } else assignCustomerOrRelationMeasurements(clientMeasurement);
  }, [clientMeasurement, isHeldOrder, heldPayingObject]);


  // //Set Last value of customer list selected
  // useEffect(() => {
  //   if (customerList !== undefined) {
  //     setSelectedCustomerObj(customerList[customerList?.length - 1]);
  //   }

  // }, [customerList, isModify]);



const addProductButtonDisabled=selectedMaterialObj===null || selectProductsForm?.qty==="" || selectedColorSingleObj===null || selectProductsForm?.itemRate===""


  useEffect(()=>{
    if(selectedTaxes.length===0 ){
      setTaxList(allTaxList!==undefined?allTaxList:[])
    }
    else {
      let filteredArray=allTaxList?.filter(
        (option) => option.includedPrice === selectedTaxes[0]?.includedPrice
        );
        setTaxList(filteredArray)
    }
  },[allTaxList,selectedTaxes])


  // const [heldOrderTotal,setHeldOrderTotal]=useState(0)

    // calculate held order total
    // useEffect(()=>{
    //   let total = 0;
    //   heldWorkOrderList?.forEach((obj)=>{
    //     total+=obj.total;
    //   })
    //   setHeldOrderTotal(total)
    
    // },[heldWorkOrderList])
    // console.log(heldOrderTotal);
    // console.log(heldProductBtnRef);




  // console.log(itemsDemoObject,"demoObj");
  // console.log(itemsDemoArray,"payload");


  // console.log(addRelationshipForm);
  return (
    <>
      <div
        className="global-page-parent-container work-order-main-container"
        id="woUserPage"
      >
        {/* <p className="breadcrumb">Orders &gt; Work Order</p> */}

        {/* top container */}
        <div className="work-order-top-container">
          <div className="work-order-top-first-container">
            <div className="work-order-top-first-left-container">
              <h3 id="woUserHead">Order Details</h3>
              <div className="global-radio-button work-order-top-radio">
                <input
                  defaultChecked
                  type="radio"
                  id="workOrderRetail"
                  value="retail"
                  name="retailWholesale"
                  onChange={(e) => {
                    setOrderType(e.target.value);
                  }}
                />
                <label htmlFor="workOrderRetail">Retail</label>
                <input
                  type="radio"
                  id="workOrderWholesale"
                  value="wholesale"
                  name="retailWholesale"
                  onChange={(e) => {
                    setOrderType(e.target.value);
                  }}
                />
                <label htmlFor="workOrderWholesale">Wholesale</label>
              </div>
            </div>
            <div className="work-order-top-first-right-container">
              {heldWorkOrderList !== undefined && (
                <Badge
                  badgeContent={heldWorkOrderList?.length}
                  color={"warning"}
                  max={10}
                  anchorOrigin={{ vertical: "top", horizontal: "20px" }}
                >
                  <button
                    className="btn btn-primary held-order-btn"
                    style={{ width: "40%" }}
                    onClick={openHeldOrders}
                  >
                    Held Orders
                  </button>
                </Badge>
              )}
              {isHeldOrder && (
                <button
                  className="btn btn-primary clear-hold-mode-btn"
                  style={{ width: "40%" }}
                  onClick={clearHoldModeBtnClick}
                >
                  Clear Hold mode
                </button>
              )}
              <button
                className="btn btn-primary"
                style={{ width: "40%" }}
                onClick={clickViewOrdersShortcut}
                // ref={heldProductBtnRef}
              >
                View Orders
              </button>
              {/* <button className="btn btn-primary work-order-add-new-btn">Add New Order</button> */}
              {isWoEdit && (
                <button
                  className="btn btn-primary work-order-cutting-slip-btn"
                  onClick={clearEditForms}
                >
                  Clear & Create new order
                </button>
              )}
            </div>
          </div>
          <div className="work-order-top-second-container">
            <div className="global-single-input work-order-top-container-input">
              <p>Order No</p>
              <input type="text" readOnly value={workOrderUserForm1?.orderNo} />
            </div>
            <div
              className="global-single-input auto-complete  work-order-customer-inp-container"
              style={{ position: "relative" }}
            >
              <p>Customer</p>
              <Autocomplete
                sx={{ width: "100%" }}
                value={selectedCustomerObj}
                options={customerList || []}
                getOptionLabel={(option) => option.name}
                filterOptions={filterOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search by mobile or name..."
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    <span>{option.name}</span>
                  </li>
                )}
                onChange={(e, newValue) => {
                  setSelectedCustomerObj(newValue);
                  // Your additional logic here
                }}
              />
              {/* <Select
                value={workOrderUserForm1?.customerId}
                onChange={getWorkOrderUserForm1("customerId")}
              >
                <MenuItem value="0">-select a customer-</MenuItem>
                {customerList
                  ?.slice(0)
                  ?.reverse()
                  ?.map((item, i) => (
                    <MenuItem
                      value={item?._id}
                      key={i}
                    >{`${item?.cusId}-${item?.name}`}</MenuItem>
                  )) || <MenuItem>No data</MenuItem>}
              </Select> */}
              <span
                className="suffix-text-blue"
                onClick={openAddNewCustomerDialog}
              >
                Add Customer
              </span>
              {customerAlert ? (
                <p className="doc-validation-alert" style={{ top: "10%" }}>
                  Select a Customer !!
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="global-single-input work-order-top-container-input">
              <p>Contact</p>
              <input
                type="text"
                readOnly
                value={selectedCustomerObj?.mobileNo || ""}
                // onChange={getWorkOrderUserForm1("contact")}
              />
            </div>
            <div
              className="global-single-input work-order-top-container-input"
              style={{ position: "relative" }}
            >
              <p>Salesman</p>
              <Select
                value={workOrderUserForm1?.salesMan}
                onChange={getWorkOrderUserForm1("salesMan")}
              >
                <MenuItem value="0">-select salesman-</MenuItem>
                {allActiveEmpList
                  ?.slice(0)
                  ?.reverse()
                  ?.map((item, i) => (
                    <MenuItem key={i} value={item?._id}>
                      {item?.staff_name}
                    </MenuItem>
                  )) || <MenuItem>No data</MenuItem>}
              </Select>
              {salesManAlert ? (
                <p className="doc-validation-alert" style={{ top: "10%" }}>
                  Select a salesman !!
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="global-single-input work-order-top-container-input">
              <p>Order Date</p>
              <input
                type={"date"}
                value={workOrderUserForm1?.orderDate}
                onChange={getWorkOrderUserForm1("orderDate")}
              />
            </div>
            <div className="global-single-input work-order-top-container-input">
              <p>Delivery Date</p>
              <input
                type={"date"}
                value={workOrderUserForm1?.deliveryDate}
                onChange={getWorkOrderUserForm1("deliveryDate")}
              />
            </div>
            <div className="global-single-input work-order-top-container-input">
              <p>Relationship</p>
              <Select
                value={workOrderUserForm1?.relationShip}
                onChange={getWorkOrderUserForm1("relationShip")}
              >
                <MenuItem value="0">-select relationship-</MenuItem>
                {relationList
                  ?.slice(0)
                  ?.reverse()
                  ?.map((r, i) => (
                    <MenuItem key={i} value={r?._id}>
                      {r?.name}
                    </MenuItem>
                  )) || <MenuItem>No data</MenuItem>}
              </Select>
              <span
                className="suffix-text-blue"
                onClick={openAddRelationshipDialog}
              >
                Add Relationship
              </span>
            </div>
          </div>
          <div className="work-order-top-third-container">
            <div className="work-order-top-third-radio-container">
              <div className="global-radio-button work-order-dress-type-radio">

                {(modelList?.length > 0 &&
                  modelList?.map((model, i) => {
                    if (
                      model !== "Shirt" &&
                      model !== "Pant" &&
                      model !== "Coat" &&
                      model !== "Sharvani" &&
                      model !== "Safari"
                    ) {
                      return (
                        <>
                          <input
                            type="radio"
                            id={model}
                            value={model.toLowerCase()}
                            name="type"
                            checked={dressType === model.toLowerCase()}
                            onClick={dressTypeChange}
                            disabled={isWoEdit&& productEditRow!==null}
                          />
                          <label htmlFor={model}>{model}</label>
                        </>
                      );
                    }
                  })) || <span>No models added by admin</span>}


                {modelList?.length > 0 &&
                  modelList?.some((item) =>
                    shirtAndSuitCommonElements?.includes(item)
                  ) && (
                    <div className="shirt-suit-radio-container">
                      <input
                        type="radio"
                        id="shirtAndSuit"
                        value="shirtAndSuit"
                        name="type"
                        checked={dressType === "shirtAndSuit"}
                        onChange={dressTypeChange}
                        disabled={isWoEdit&& productEditRow!==null}

                      />
                      <label htmlFor="shirtAndSuit">Shirt & Suit</label>

                      <div
                        className={
                          dressType === "shirtAndSuit"
                            ? "shirt-suit-dropdown-container"
                            : "hide-radio-dropDown"
                        }
                      >
                        {modelList?.map((model, i) => {
                          if (
                            model !== "Thob" &&
                            model !== "Jalabia" &&
                            model !== "Salwar" &&
                            model !== "Abaya" &&
                            model !== "Churidhar"
                          ) {
                            return (
                              <div
                                id={`${model?.toLowerCase()}Btn`}
                                className={model==="Shirt"?"non-focus focus":"non-focus"}
                                onClick={
                                  model === "Shirt"
                                    ? ClickShirtBtn
                                    : model === "Pant"
                                    ? clickPantBtn
                                    : model === "Coat"
                                    ? clickCoatBtn
                                    : model === "Sharvani"
                                    ? clickSharvaniBtn
                                    : model === "Safari"
                                    ? clickSafariBtn
                                    : undefined
                                }
                              >
                                {model}
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  )}
              </div>
            </div>
            {/* <div className="work-order-top-third-button-container">
              <button className="btn btn-secondary-outlined">Cancel</button>
              <button className="btn btn-primary">Submit</button>
            </div> */}
          </div>
        </div>

        {/* middle container */}
        {dressType !== null && (
          <div>
            <h3>Product Details</h3>
            {dressType === "thob" ? (
              <ThobWO
                formValues={thobModelForm}
                onChange={getThobModelFormData}
              />
            ) : dressType === "jalabia" ? (
              <JalabiaWO
                formValues={jalabiaModelForm}
                onChange={getJalabiaModelFormData}
              />
            ) : dressType === "salwar" ? (
              <SalwarWO
                formValues={salwarModelForm}
                onChange={getSalwarModelFormData}
              />
            ) : dressType === "abaya" ? (
              <AbayaWO
                formValues={abayaForm}
                onChange={getAbayaModelFormData}
              />
            ) : dressType === "churidhar" ? (
              <ChuridharWO
                formValues={churidharForm}
                onChange={getChuridharFormData}
                addButtonClickFn={addButtonFunction}
                remarksList={churidharFormRemarksArray}
              />
            ) : activeBtn === "shirtBtn" ? (
              <ShirtWO formValues={shirtForm} onChange={getShirtFormData} />
            ) : activeBtn === "pantBtn" ? (
              <PantWO formValues={pantForm} onChange={getPantFormData} />
            ) : activeBtn === "coatBtn" ? (
              <CoatWO formValues={coatForm} onChange={getCoatModelFormData} />
            ) : activeBtn === "sharvaniBtn" ? (
              <SharvaniWO
                formValues={sharvaniForm}
                onChange={getSharvaniFormData}
              />
            ) : activeBtn === "safariBtn" ? (
              <SafariWO formValues={safariForm} onChange={getSafariFormData} />
            ) : undefined}
          </div>
        )}

        {/* bottom container */}
        {dressType !== null && (
          <div className="work-order-bottom-container" style={{position:"relative"}}>
            <h4>Select Products</h4>
                {
                selectedCustomerObj===null &&  
                <p className="workorder-add-product-validation" style={{top:"0",right:"0%"}}>
                  Select a Customer !!
                </p>}
            <div
              className="work-order-bottom-products-container"
              style={{ position: "relative" }}
            >

              {addProductsAlert ? (
                <p className="workorder-add-product-validation">
                  Add a product !!
                </p>
              ) : (
                ""
              )}
              <div
                className="global-single-input work-order-product-input auto-complete"
                style={{ position: "relative",minWidth:"200px"}}
              >
                <p>Product</p>
                <div className="autocomplete-refresh-ctn">
                 {
                 materialProductList===undefined &&
                 <IconButton sx={{padding:"2px 5px"}}>
                    {
                      productLoading?
                      <CircularProgress size={25}/>
                      :
                      <RefreshIcon onClick={()=>
                        viewAllMaterialProductsWithStockAPICall({
                        itemType: 2,
                        branchId: branchPk,
                      })}
                      />
                      
                      }
                  </IconButton>}
                  <Autocomplete
                    sx={{ width: "100%" }}
                    options={materialProductList || []}
                    getOptionLabel={(option) => option?.productName}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Search product..." />
                      )}
                    value={selectedMaterialObj}
                    // value={(option)=>option?._id}
                    onChange={(e, newValue) => {
                      setSelectedMaterialObj(newValue);
                      setProductAlert(false);
                      setSelectedTaxes((newValue?.taxes!==null?newValue?.taxes :[] )|| [])
                      if (newValue === null) {
                        setProductAlert(true);
                      }
                    }}
                    renderOption={(props, option) => (
                      <li {...props} key={option._id}>
                        <span>{option.productName}</span>
                      </li>
                    )}
                    disabled={selectedCustomerObj===null}
                  />
                </div>
                {productAlert ? (
                  <p className="doc-validation-alert">Select a product !!</p>
                ) : (
                  ""
                )}
              </div>
              <div className="global-single-input work-order-product-input auto-complete">
                <p>Product Colour</p>
                {/* <input
                  type="text"
                  value={selectProductsForm?.productColor}
                  disabled
                /> */}
                <Autocomplete
                  sx={{ width: "100%" }}
                  value={selectedColorSingleObj}
                  options={allCreatedColorsList || []}
                  getOptionLabel={(option) => option?.colorName}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Search color..." />
                  )}
                  // value={(option)=>option?._id}
                  onChange={getSelectProductForm("productColor")}
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      <span>{option.colorName}</span>
                    </li>
                  )}
                />
                <p
                style={{cursor:"pointer",color:"#70afff"}}
                onClick={()=>setOpenAddColor(true)}
                className="doc-validation-alert">ADD COLOR</p>
              </div>
              <div className="global-single-input work-order-product-qty">
                <p>Qty</p>
                <input
                  placeholder="Enter qty..."
                  type="text"
                  value={selectProductsForm?.qty}
                  onChange={getSelectProductForm("qty")}
                />
                {productQtyAlert ? (
                  <p className="doc-validation-alert">Enter Quantity !!</p>
                ) : (
                  ""
                )}
              </div>
              <div className="global-single-input work-order-product-qty">
                <p>Item Rate</p>
                <input
                  placeholder="Enter rate..."
                  type="text"
                  value={selectProductsForm?.itemRate}
                  onChange={getSelectProductForm("itemRate")}
                />
                {productRateAlert ? (
                  <p className="doc-validation-alert">Enter Rate !!</p>
                ) : (
                  ""
                )}
              </div>
              <div className="global-single-input work-order-product-qty">
                <p>Stitch Rate</p>
                <input
                  type="text"
                  value={selectProductsForm?.stitchRate}
                  onChange={getSelectProductForm("stitchRate")}
                  // onKeyDown={(e) =>
                  //   e.key === "Enter" && createWorkOrderBodyObjects()
                  // }
                  // onKeyUp={(e) => e.key === "Enter" && addSelectedProduct()}
                />
              </div>
              <div
              style={{minWidth:"150px"}}
              className="global-single-input work-order-product-qty auto-complete-new">
                <p>Tax</p>
                <Autocomplete
                  multiple
                  sx={{ width: "100%" }}
                  options={taxList?.filter((item)=>item?.status===true) || []}
                  getOptionLabel={(option) => option?.taxName}
                  renderInput={(params) => (
                    <TextField {...params}  />
                  )}
                  onChange={(e, newValue) =>{ 
                    setSelectedTaxes(newValue)
                  }}
                  value={selectedTaxes}
                 />
              </div>
              <div className="global-single-input work-order-product-qty">
                <p>Amount</p>
                <input
                  type="text"
                  value={selectProductsForm?.amount?.toFixed(2)}
                  disabled
                />
              </div>
              
              <button
               
                className="btn btn-primary work-order-product-submit-btn"
                onClick={addSelectedProduct}
                onMouseDown={createWorkOrderBodyObjects}
                
                // onKeyDown={(e) =>
                //   e.key === "Enter" && createWorkOrderBodyObjects()
                // }
                // onKeyUp={(e) => e.key === "Enter" && addSelectedProduct()}
                disabled={addProductButtonDisabled}
              >
                {
                productEditRow===null?
                "Add":"Edit"}

              </button>
            </div>
            {/* bottom table */}
            <div className="work-order-bottom-second-container">
              <div className="work-order-bottom-second-left-container" style={{display:"flex",flexDirection:"column"}}>
                <table className="work-order-bottom-second-left-table-container">
                  <thead>
                    <tr>
                      {/* <th className="table-left-th">No</th> */}
                      <th>Type</th>
                      <th>Qty</th>
                      <th>Rate</th>
                      <th className="table-right-th">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(productItemsCollapsed?.length > 0 &&
                      productItemsCollapsed?.map((r, i) => {
                        if (r?.item !== null) {
                          return (
                            <tr key={i}>
                              {/* <td className="table-left-td">{1}</td> */}
                              <td className="table-left-td">{r?.item}</td>
                              <td>{r?.qty}</td>
                              <td>{r?.rate}</td>
                              <td className="table-right-td">
                                {r?.amount?.toFixed(2)}
                              </td>
                            </tr>
                          );
                        }
                      })) || (
                      <tr>
                        <td colSpan={4}>No products added</td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <div
                  className="new-global-single-input"
                  style={{ maxWidth: "250px" ,
                          alignSelf:"end",
                          width:"auto",
                          marginRight:"12%"
                }}
                >
                  <div className="subtotal-ctn">
                    <div className="subtotal-ctn-left">
                      <p>Sub Total</p>
                      {totalsInfo?.inclusiveTax !== 0 && <p>Inclusive Tax</p>}

                      {totalsInfo?.exclusiveTax !== 0 && <p>Exclusive Tax</p>}
                      {/* {totalsInfo?.exclusiveTax === 0 &&
                        totalsInfo?.inclusiveTax === 0 && <p>Tax</p>} */}
                    </div>
                    <div className="subtotal-ctn-right">
                      <p>{totalsInfo?.subTotal?.toFixed(2)}</p>
                      {totalsInfo?.inclusiveTax !== 0 && (
                        <p>{totalsInfo?.inclusiveTax?.toFixed(2)}</p>
                      )}
                      {totalsInfo?.exclusiveTax !== 0 && (
                        <p>{totalsInfo?.exclusiveTax?.toFixed(2)}</p>
                      )}
                      {/* {totalsInfo?.exclusiveTax === 0 &&
                        totalsInfo?.inclusiveTax === 0 && <p>0.00</p>} */}
                    </div>
                  </div>
                  <hr
                    className="global-hr"
                    style={{ width: "80%", marginLeft: "25%" }}
                  />
                  <div className="subtotal-ctn">
                    <div className="subtotal-ctn-left">
                      <p style={{ color: "#000", fontWeight: "600" }}>Total</p>
                    </div>
                    <div className="subtotal-ctn-right">
                      <p>{(totalsInfo?.exclusiveTax+totalsInfo?.subTotal)?.toFixed(2) || 0} </p>
                    </div>
                  </div>



                  {/* <div style={{ border: "2px solid orange"}}>
             newdesing
            </div> */}

                </div>
              </div>
              {/* Work Orders Preview table */}
              <div className="work-order-bottom-second-right-container">
                <p>Work Orders Preview</p>
                <div className="global-table-container work-order-second-right-table-container">
                  <table className="global-table">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Type</th>
                        <th>Item</th>
                        <th>Qty</th>
                        <th>Colour</th>
                        <th>Rate</th>
                        <th>Stitch Rate</th>
                        <th>Amount</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(productItemsExpanded?.length > 0 &&
                        productItemsExpanded
                          ?.slice(0)
                          ?.reverse()
                          ?.map((r, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td style={{ textTransform: "capitalize" }}>
                                {r?.type}
                              </td>
                              <td>{r?.item}</td>
                              <td>{r?.qty}</td>
                              <td>{r?.color || "-NIL-"}</td>
                              <td>{r?.itemRate}</td>
                              <td>{r?.stitchRate}</td>
                              <td>{r?.amount?.toFixed(2)}</td>
                              <td>
                                <IconButton
                                  className="work-order-action-btn"
                                  // disabled={
                                  //   r?.status === "completed" ||
                                  //   r?.status === "delivered"
                                  // }
                                  disabled={!(
                                    !r?.status ||
                                    r?.status === "printing" ||
                                    r?.status === "pending"
                                  ) || (productEditRow!==null)}
                                  style={
                                    (!(
                                      !r?.status ||
                                      r?.status === "printing" ||
                                      r?.status === "pending"
                                    ) ||  (productEditRow!==null))
                                      ? { cursor: "not-allowed", opacity: 0.7 }
                                      : {}
                                  }
                                  onClick={
                                    modelList?.includes(
                                      `${r?.type
                                        .charAt(0)
                                        ?.toUpperCase()}${r?.type.slice(1)}`
                                    )
                                      ? editItemsFromWorkOrderPreview(
                                          r,
                                          itemsDemoArray,
                                          i
                                        )
                                      : showEditNotPermittedInfo(
                                          `${r?.type
                                            .charAt(0)
                                            ?.toUpperCase()}${r?.type.slice(1)}`
                                        )
                                  }
                                >
                                  <i class="bi bi-pencil-square edit-icon"></i>
                                </IconButton>
                              </td>
                              <td>
                                <IconButton
                                  className="work-order-action-btn"
                                  disabled={!(
                                    !r?.status ||
                                    r?.status === "printing" ||
                                    r?.status === "pending"
                                  ) ||  (productEditRow!==null)}
                                  style={
                                    (!(
                                      !r?.status ||
                                      r?.status === "printing" ||
                                      r?.status === "pending"
                                    )|| (productEditRow!==null))
                                      ? { cursor: "not-allowed", opacity: 0.7 }
                                      : {}
                                  }
                                  // disabled={
                                  //   r?.status === "completed" ||
                                  //   r?.status === "delivered"
                                  // }
                                  onClick={() =>
                                    findProductObject(r, itemsDemoArray, i)
                                  }
                                  // onClick={deleteItemsFromProductItemsExpanded(i)}
                                >
                                  <i class="bi bi-trash3 delete-icon"></i>
                                </IconButton>
                              </td>
                            </tr>
                          ))) || (
                        <tr>
                          <td colSpan={10}>No products added</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="work-order-bottom-second-button-container">
              <div className="work-order-bottom-second-button-group">
                <button
                  className="btn btn-secondary-outlined work-order-clear-btn"
                  onClick={clearWorkOrderState}
                >
                  Clear
                </button>
                {
                  itemsDemoArray?.length!==0 &&
                <button
                  className="btn btn-primary work-order-clear-btn"
                  onClick={
                    isWoEdit
                      ? editWorkOrderWithoutPayment
                      : createWorkOrderWithoutPayment
                  }
                >
                  {isWoEdit ? "Update Order" : "Create Order"}
                </button>}
              </div>
              {
                itemsDemoArray?.length!==0 && (isWoEdit===null|| isWoEdit===false) &&
                <button
                onClick={isWoEdit ? editPaymentButtonClick : paymentButtonClick}
                className="btn btn-primary work-order-payment-btn"
                // disabled={isWoEdit}
                >
                Payment
              </button>
              }
            </div>
          </div>
        )}
        {paymentBtn === true && (
          <PaymentsWO
            closePayments={paymentContainerClose}
            subTotal={workOrderTotal} //imp
            bodyWOPayment={newWorkOrderWOPaymentBody}
            finalAPI={createNewWorkOrderAPICall}
            setOpenSuccessSnack={setOpenSuccessSnack}
            setOpenErrorSnack={setOpenErrorSnack}
            setSnackMsg={setSnackMsg}
            clearWOState={clearWorkOrderState}
            updateListenerProp={updateListener}
            // values={billingForm}
            // onChange={getPaymentsForm}
            cusId={workOrderUserForm1?.customerId} // imp
            placeOrderClick={paymentPlaceOrder}
            // totalAmount={paymentTotalAmount}
            isMultiplePayment={setIsMultiplePayment}
            branchId={branchPk}
            heldProductBtnRef={heldProductBtnRef}
            holdOrderAPICall={workOrderHoldAPICall}
            holdOrderBody={holdOrderBody}
            heldProductList={heldWorkOrderList}
            heldPayFn={clickPayHeldOrder}
            closePaymentWindow={paymentContainerClose}
            exclusiveTax={exclusiveTaxes}
            inclusiveTax={inclusiveTaxes}
            isHeld={isHeld}
          />
        )}
        {editPaymentBtn && (
          <EditPayment
            closePayments={editPaymentContainerClose}
            subTotal={workOrderTotal}
            prevPaymentForm={prevPayments}
            prevPaymentObject={previousPaymentObject}
            bodyWoPayment={editWorkOrderWOPaymentBody}
            finalAPICall={editWorkOrderAPICall}
            setIsLoading={setIsLoading}
            setOpenSuccessSnack={setOpenSuccessSnack}
            setOpenErrorSnack={setOpenErrorSnack}
            setSnackMsg={setSnackMsg}
            updateListener={updateListener}
            clearWorkOrderState={clearWorkOrderState}
            clearEditForms={clearEditForms}
          />
        )}
      </div>

      {/* Add New Customer Dialog  */}
      <Dialog
        open={openAddNewCustomer}
        maxWidth="lg"
        onClose={closeAddNewCustomerDialog}
      >
        <AddCustomerBilling
          closeDialog={closeAddNewCustomerDialog}
          setSelectedCustomerObj={setSelectedCustomerObj}
        />

      </Dialog>

      {/* add relationship */}
      <Dialog open={addRelationship} maxWidth="lg">
        <div className="add-relationship-dialog">
          <div className="add-relationship-dialog-top-container">
            <div className="add-relationship-dialog-top-heading-container">
              <h3>Relationships</h3>
              <div
                onClick={() => setAddRelationShip(false)}
                className="add-relationship-dialog-top-heading-close-icon-container"
              >
                <CloseIcon sx={{ color: "red" }} />
              </div>
            </div>
            <hr className="global-hr" />
            <div className="add-relationship-dialog-top-heading-container">
              <div className="add-relationship-dialog-top-left-container">
                <div className="add-relation-name-content-single-container">
                  <h4 className="name">Name</h4>
                  <h4>:&nbsp;{singleCustomerObj[0]?.name}</h4>
                </div>
                <div className="add-relation-name-content-single-container">
                  <p className="name">Contact</p>
                  <p>:&nbsp;{singleCustomerObj[0]?.mobileNo}</p>
                </div>
              </div>
              <div className="add-relationship-dialog-top-left-container">
                <div className="add-relation-name-content-single-container">
                  <p className="name">Email</p>
                  <p>:&nbsp;{singleCustomerObj[0]?.email}</p>
                </div>
                {/* <div className="add-relation-name-content-single-container">
                  <p className="name">Joining Date</p>
                  <p>:&nbsp;12-02-2022</p>
                </div> */}
              </div>
            </div>
          </div>

          <div className="add-relationship-dialog-top-container">
            <h3>Add Relationship</h3>
            <div className="add-relationship-dialog-input-container">
              <div className="global-single-input">
                <p>Customer ID</p>
                <input
                  type="text"
                  style={{ backgroundColor: "#fff" }}
                  value={addRelationshipForm?.customerId}
                  disabled
                />
              </div>
              <div
                // className="global-single-input auto-complete auto-complete-name"
                className="global-single-input auto-complete-freesolo"
                style={{ position: "relative" }}
              >
                <p>Name</p>
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  options={customerList || []}
                  getOptionLabel={(option) => option.name}
                  value={addRelationshipForm?.newName}
                  onChange={getAddRelationshipForm("newName")}
                  filterOptions={filterOptions}
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      <span>{option.name}</span>
                    </li>
                  )}
                  renderInput={(params) => <TextField {...params}    onChange={getAddRelationshipForm("newNameInput")} />}
      />
                 {/* <Autocomplete
                 freeSolo
                 id="free-solo-2-demo"
                 disableClearable
                 options={customerList || []}
                 getOptionLabel={(option) => option.name}
                 value={addRelationshipForm?.newName}
                 onChange={getAddRelationshipForm("newName")}
                 filterOptions={filterOptions}
                 renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    <span>{option.name}</span>
                  </li>
                )}
                 renderInput={(params) => (
                  <TextField
                      {...params}
                      // label="Search input"
                      InputProps={{
                     ...params.InputProps,
                      type: 'search',
                        }}
                    />
                     )}
                  />  */}

                {/* <Autocomplete
                  id="customerRelName"
                  options={suggestions}
                  getOptionLabel={(option) => option.name}
                  inputValue={addRelationshipForm?.name}
                  onInputChange={handleInputChange}
                  open={showSuggestions}
                  onChange={handleCusNameChange}
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      <span>{option.name}</span>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={addRelationshipForm?.name}
                      onChange={handleTextFieldChange}
                      placeholder="Enter Name ..."
                    />
                  )}
                  clearOnBlur={false}
                  clearOnEscape={false}
                  disableClearable
                  disableOpenOnFocus
                /> */}
                  
              
                {relationNameAlert ? (
                  <p className="doc-validation-alert">Fill this field !!</p>
                ) : (
                  ""
                )}
              </div>
              <div className="global-single-input">
                <p>Relationship With Customer</p>
                <input
                  type="text"
                  style={{ backgroundColor: "#fff" }}
                  placeholder="Enter relation..."
                  value={addRelationshipForm?.relationship}
                  onChange={getAddRelationshipForm("relationship")}
                />
              </div>
              <div className="global-single-input">
                <p>Building Name</p>
                <input
                  type="text"
                  style={{ backgroundColor: "#fff" }}
                  placeholder="Enter building name..."
                  value={addRelationshipForm?.buildingName}
                  onChange={getAddRelationshipForm("buildingName")}
                />
              </div>
              <div className="global-single-input">
                <p>Street Name</p>
                <input
                  type="text"
                  style={{ backgroundColor: "#fff" }}
                  placeholder="Enter street name..."
                  value={addRelationshipForm?.streetName}
                  onChange={getAddRelationshipForm("streetName")}
                />
              </div>
              <div className="global-single-input">
                <p>Landmark</p>
                <input
                  type="text"
                  style={{ backgroundColor: "#fff" }}
                  placeholder="Enter landmark..."
                  value={addRelationshipForm?.landmark}
                  onChange={getAddRelationshipForm("landmark")}
                />
              </div>
              <div
                className="global-single-input"
                style={{ position: "relative" }}
              >
                <p>Mobile No</p>
                <input
                  type="text"
                  style={{ backgroundColor: "#fff" }}
                  placeholder="Enter mobile no..."
                  value={addRelationshipForm?.mobileNo}
                  onChange={getAddRelationshipForm("mobileNo")}
                  maxLength="15"
                />
                {relationMobileAlert ? (
                  <p className="doc-validation-alert">Fill this field !!</p>
                ) : (
                  ""
                )}
              </div>
              <div className="global-single-input">
                <p>Email</p>
                <input
                  type="text"
                  style={{ backgroundColor: "#fff" }}
                  placeholder="Enter email..."
                  value={addRelationshipForm?.email}
                  onChange={getAddRelationshipForm("email")}
                />
              </div>
              <div className="global-single-input">
                <p>Reference</p>
                <input
                  type="text"
                  style={{ backgroundColor: "#fff" }}
                  placeholder="Enter reference..."
                  value={addRelationshipForm?.reference}
                  onChange={getAddRelationshipForm("reference")}
                  onKeyDown={(e) => e.key === "Enter" && addNewRelationship()}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                className="btn btn-secondary-outlined"
                onClick={clearAddRelationshipForm}
              >
                Clear
              </button>
              <button className="btn btn-primary" onClick={addNewRelationship}>
                Submit
              </button>
            </div>
          </div>
          {/* table */}
          <div
            className="global-table-container"
            style={{ border: "1px solid rgb(209 206 206)", margin: "2% 0" }}
          >
            <table className="global-table">
              <thead>
                <tr>
                  <th>Customer ID</th>
                  <th>Name</th>
                  <th>Relationship</th>
                  <th>Street Name</th>
                  <th>Contact</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {relationList
                  ?.slice(0)
                  ?.reverse(0)
                  ?.map((r, i) => (
                    <tr key={i}>
                      <td>{r?.ID}</td>
                      <td>{r?.name}</td>
                      <td>{r?.relType}</td>
                      <td>{r?.streetName}</td>
                      <td>{r?.mobileNo}</td>
                      <td>{r?.email}</td>
                      <td>
                        <IconButton>
                          <i class="bi bi-pencil-square edit-icon"></i>
                        </IconButton>
                        <IconButton>
                          <i class="bi bi-trash3 delete-icon"></i>
                        </IconButton>
                      </td>
                    </tr>
                  )) || (
                  <tr>
                    <td colSpan={7}>No data</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Dialog>

      {/* //Billing invoice Dialog  */}
      <Dialog
        open={invoiceDialog}
        maxWidth="lg"
        onClose={() => {
          setInvoiceDialog(false);
        }}
      >
        <div className="billing-parent-wrapper" onScroll={hideInvoicePrint}>
          <div ref={(el) => (invoicePrint = el)}>
            <Invoice
              data={generatedInvoice}
              // invoiceData={generatedInvoice}
            />
          </div>
          <div className="invoice-print-icon-container" id="invoicePrintIcon">
            {/* <Tooltip title="Print" placement="right" arrow> */}
            <ReactToPrint
              trigger={() => (
                <IconButton
                  className="invoice-print-btn"
                  sx={{
                    backgroundColor: "#d3e3ec",
                  }}
                  // onClick={invoiceDialogClose}
                >
                  <PrintOutlined
                    className="print-icon"
                    sx={{ color: "#000" }}
                  />
                </IconButton>
              )}
              content={() => invoicePrint}
            />
            {/* </Tooltip> */}
          </div>
        </div>
      </Dialog>
      {/* Backdrop on loading  */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 500 }}
        open={isLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* View Order shortcut Dialog  */}
      <Dialog
        open={openViewOrderShortDialog}
        onClose={closeViewOrderShortcutDialog}
        maxWidth="lg"
      >
        <ViewOrderUser />
      </Dialog>
      {/* add color popup */}
      <Dialog
        open={openAddColor}
        maxWidth="lg"
        onKeyDown={(e) => {
          e.key === "Escape" && setOpenAddColor(false);
        }}
      >
        <div className="add-popup-container">
          <p>Add colour</p>
          <div className="add-color-popup-input-container" style={{position:"relative"}}>
            <input
              className="input-container"
              placeholder="Colour Name..."
              value={colorFormValues?.colorName}
              onChange={getColorFormChange("colorName")}
            />
            <Tooltip title="Colour Picker">
              <input
                type="color"
                className="color-input-container"
                value={colorFormValues?.colorChange}
                onChange={getColorFormChange("colorChange")}
                onKeyDown={(e) => {
                  e.key === "Enter" && addNewColorPlusBtn();
                }}
              />
            </Tooltip>

            <button
              onClick={addNewColorPlusBtn}
            >
              +
            </button>
            <p className="doc-validation-alert">{validationAlert?.colorName}</p>
            <p className="doc-validation-alert">{validationAlert?.colorChange}</p>
          </div>
          <div className="add-popup-table">
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Color Name</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>{allCreatedColorsList
                ?.slice(0)
                ?.reverse()
                ?.map((color, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {color?.colorName}{" "}
                      <div
                        style={{
                          backgroundColor: color?.colorCode,
                          width: "15px",
                          height: "15px",
                          marginLeft: "4px",
                          borderRadius: "4px",
                        }}
                      ></div>
                    </td>
                    <td>
                      <IconButton onClick={deleteAddedColor(color?._id)}>
                        <i class="bi bi-trash3 delete-icon"></i>
                      </IconButton>
                    </td>
                  </tr>
                ))}</tbody>
            </table>
          </div>
          <div className="add-popup-button-container">
            <button onClick={()=>setOpenAddColor(false)} className="btn btn-secondary">
              Close
            </button>
          </div>
        </div>
      </Dialog>
      <SuccessSnackbar
        open={openSuccessSnack}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={openErrorSnack}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
      <LoadingForm loading={loadingForm} />

    </>
  );
};
