import axios from 'axios'
import {get_sales_report} from '../../Redux/Report/salesReportSlice'
import store from '../../Redux/store'
import { HEADER_FORM_DATA } from '../UrlAndPaths'


export const salesReportAPICall= async (formData,setIsLoading)=>{
    setIsLoading(true)
    await axios.post("report/salesReport",formData,HEADER_FORM_DATA)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_sales_report({salesReportData:res.data}))
            setIsLoading(false)
        }
    })
    .catch((err)=>{
        store.dispatch(get_sales_report({salesReportData:undefined}))
        console.log(err.response);
        setIsLoading(false)
    })
}