import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { IconButton } from "@mui/material";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import InputBox from "../../../../../components/InputBox";

const IncomeStatement = ({ selectListItem }) => {
  const componentRef = useRef();

  return (
    <div id="currentAsset-report">
      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="accounting-voucher-menu-sections"
      >
        <h3 style={{ margin: "2% 1%" }}>{selectListItem}</h3>
        <div className="input-current-asset-reports">
          <InputBox label={"From"} type={"date"} />
          <InputBox label={"To"} type={"date"} />
        </div>
      </div>
      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="accounting-voucher-menu-sections"
        id="cashflow-statement-direct"
      >
        <div className="action-btn-container">
          <div>
            <ReactToPrint
              trigger={() => (
                <IconButton sx={{ color: "blue" }}>
                  <i className="bi bi-printer "></i>
                </IconButton>
              )}
              content={() => componentRef.current}
            />
            <IconButton className="download-btn">
              <ArrowCircleDownIcon sx={{ fontSize: 30 }} />
            </IconButton>
          </div>
        </div>
        <div className="global-table-wrapper">
          <h3 style={{ textAlign: "center" }}>
            Trading , Profit and Loss Account for the year end 31 - 03 - 2022
          </h3>
          <table className="global-table">
            <thead style={{ height: "0" }}>
              <tr>
                <th></th>
                <th style={{ width: "10%" }}></th>
                <th style={{ width: "10%" }}></th>
              </tr>
            </thead>
            <tbody>
              <tr className="data-table-row-head">
                <td className="data-row">
                  <ul className="data-row-list">
                    <li>Sales</li>
                    <li>Less : Sales Return</li>
                  </ul>
                  <p className="profit ">Net Sales</p>
                </td>
                <td className="data-row ">
                  <ul className="data-row-amount  ">
                    <li>0.00</li>
                    <li>0.00</li>
                  </ul>
                  <p className="profit ">&nbsp;</p>
                </td>
                <td className="data-row ">
                  <ul className="data-row-amount  ">
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                  </ul>
                  <p className="profit-amount ">1000</p>
                </td>
              </tr>
              <tr className="data-table-row-head">
                <td className="data-row">
                  <h3 className="main-head">Cost of Good Sold</h3>
                  <ul className="data-row-list">
                    <li>Opening Inventory</li>
                    <li>Add Purchases</li>
                    <li>Add Carriage Inward</li>
                    <li>Cost of Goods Available for Sales</li>
                    <li>Less Returns Outward</li>
                    <li>Less Closing Inventory</li>
                  </ul>
                  <p style={{ color: "green" }} className="net-cash-flow">
                    Total Cost of Goods Sold
                  </p>
                  <p className="net-cash-flow">Gross profit b / d</p>
                </td>
                <td className="data-row ">
                  <h3 className="main-head"> &nbsp; </h3>
                  <ul className="data-row-amount">
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                  </ul>
                  <p className=" profit-amount">1000</p>
                  <p className="important">1000</p>
                </td>
                <td className="data-row ">
                  <h3 className="main-head"> &nbsp; </h3>
                  <ul className="data-row-amount">
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                  </ul>
                  <p className="total-amount important">1000</p>
                  <p className="total-amount important">1000</p>
                </td>
              </tr>
              <tr className="data-table-row-head">
                <td className="data-row">
                  <h3 className="main-head">Add Other Income</h3>
                  <ul className="data-row-list">
                    <li>Interest on Deposit</li>
                    <li>Discount Received</li>
                    <li>Commission Received</li>
                    <li>Bad Debt Recovered</li>
                  </ul>
                  <p className="net-cash-flow">Total Income</p>
                </td>
                <td className="data-row"></td>
                <td className="data-row ">
                  <h3 className="main-head">&nbsp;</h3>
                  <ul className="data-row-amount ">
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                  </ul>
                  <p className="data-row-amount">0.00</p>
                </td>
              </tr>
              <tr className="data-table-row-head">
                <td className="data-row">
                  <h3 className="main-head">Less Operating Expenses</h3>
                  <ul className="data-row-list">
                    <li>Insurance</li>
                    <li>Transport</li>
                    <li>Rent Expenses</li>
                    <li>Carriage Outward</li>
                    <li>Salary</li>
                    <li>Advertisement</li>
                    <li>Depreciation</li>
                    <li>Discount Allowed</li>
                    <li>Bad Debt Written Off</li>
                  </ul>
                </td>
                <td className="data-row"></td>
                <td className="data-row ">
                  <h3 className="main-head">&nbsp;</h3>
                  <ul className="data-row-amount">
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                  </ul>
                </td>
              </tr>
              <tr id="total-box-input" className="data-table-row-head">
                <td>
                  <ul className="list-total">
                    <li></li>
                    <li></li>
                    <li>
                      <h3>Net Profit b / d</h3>
                    </li>
                  </ul>
                </td>
                <td></td>
                <td>
                  <h4>00</h4>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div
          id="cashflow-statement-indirect"
          ref={componentRef}
          className="print-preview-container"
        >
          <div className="print-head-container">
            <div className="brand-name">
              <h2>Leeyet Textile</h2>
            </div>
            <div className="select-table-head">
              <h3>{selectListItem}</h3>
            </div>
            <div className="duration-date">
              <h3>{"01/04/2023"}</h3>
              <strong>TO</strong>
              <h3>{"01/12/2023"}</h3>
            </div>
          </div>
          <div className="global-table-wrapper">
          <h3 style={{ textAlign: "center" }}>
            Trading , Profit and Loss Account for the year end 31 - 03 - 2022
          </h3>
          <table className="global-table">
            <thead style={{ height: "0" }}>
              <tr>
                <th></th>
                <th style={{ width: "10%" }}></th>
                <th style={{ width: "10%" }}></th>
              </tr>
            </thead>
            <tbody>
              <tr className="data-table-row-head">
                <td className="data-row">
                  <ul className="data-row-list">
                    <li>Sales</li>
                    <li>Less : Sales Return</li>
                  </ul>
                  <p className="profit ">Net Sales</p>
                </td>
                <td className="data-row ">
                  <ul className="data-row-amount  ">
                    <li>0.00</li>
                    <li>0.00</li>
                  </ul>
                  <p className="profit ">&nbsp;</p>
                </td>
                <td className="data-row ">
                  <ul className="data-row-amount  ">
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                  </ul>
                  <p className="profit-amount ">1000</p>
                </td>
              </tr>
              <tr className="data-table-row-head">
                <td className="data-row">
                  <h3 className="main-head">Cost of Good Sold</h3>
                  <ul className="data-row-list">
                    <li>Opening Inventory</li>
                    <li>Add Purchases</li>
                    <li>Add Carriage Inward</li>
                    <li>Cost of Goods Available for Sales</li>
                    <li>Less Returns Outward</li>
                    <li>Less Closing Inventory</li>
                  </ul>
                  <p style={{ color: "green" }} className="net-cash-flow">
                    Total Cost of Goods Sold
                  </p>
                  <p className="net-cash-flow">Gross profit b / d</p>
                </td>
                <td className="data-row ">
                  <h3 className="main-head"> &nbsp; </h3>
                  <ul className="data-row-amount">
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                  </ul>
                  <p className=" profit-amount">1000</p>
                  <p className="important">1000</p>
                </td>
                <td className="data-row ">
                  <h3 className="main-head"> &nbsp; </h3>
                  <ul className="data-row-amount">
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                  </ul>
                  <p className="total-amount important">1000</p>
                  <p className="total-amount important">1000</p>
                </td>
              </tr>
              <tr className="data-table-row-head">
                <td className="data-row">
                  <h3 className="main-head">Add Other Income</h3>
                  <ul className="data-row-list">
                    <li>Interest on Deposit</li>
                    <li>Discount Received</li>
                    <li>Commission Received</li>
                    <li>Bad Debt Recovered</li>
                  </ul>
                  <p className="net-cash-flow">Total Income</p>
                </td>
                <td className="data-row"></td>
                <td className="data-row ">
                  <h3 className="main-head">&nbsp;</h3>
                  <ul className="data-row-amount ">
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                  </ul>
                  <p className="data-row-amount">0.00</p>
                </td>
              </tr>
              <tr className="data-table-row-head">
                <td className="data-row">
                  <h3 className="main-head">Less Operating Expenses</h3>
                  <ul className="data-row-list">
                    <li>Insurance</li>
                    <li>Transport</li>
                    <li>Rent Expenses</li>
                    <li>Carriage Outward</li>
                    <li>Salary</li>
                    <li>Advertisement</li>
                    <li>Depreciation</li>
                    <li>Discount Allowed</li>
                    <li>Bad Debt Written Off</li>
                  </ul>
                </td>
                <td className="data-row"></td>
                <td className="data-row ">
                  <h3 className="main-head">&nbsp;</h3>
                  <ul className="data-row-amount">
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                  </ul>
                </td>
              </tr>
              <tr id="total-box-input" className="data-table-row-head">
                <td>
                  <ul className="list-total">
                    <li></li>
                    <li></li>
                    <li>
                      <h3>Net Profit b / d</h3>
                    </li>
                  </ul>
                </td>
                <td></td>
                <td>
                  <h4>00</h4>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
          </div>
          </div>

    </div>
  );
};

export default IncomeStatement;
