import axios from "axios"
import { HEADERS } from "../../../../../API/UrlAndPaths"
import store from "../../../../../Redux/store"
import { get_branch_settings_list, get_branch_settings_row_data_list } from "./BranchSettingsSlice"

// VIEW ALL BRANCH SETTINGS
export const viewAllBranchSettingsAPICall=(body,setIsLoading)=>{
    setIsLoading !== undefined && setIsLoading(true);
    axios.post("settings/viewbranchsettings",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_branch_settings_list({branchSettingsData:res.data}))
            setIsLoading !== undefined && setIsLoading(false);
        }
    })
    .catch((err)=>{
        store.dispatch(get_branch_settings_list({branchSettingsData:undefined}))
        setIsLoading !== undefined && setIsLoading(false);
    })
}

// branch settings single view
export const branchSettingsSingleView=(body)=>{
  
    axios.post("settings/viewUserbranchSettings",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_branch_settings_row_data_list({branchSettingsSingleData:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(get_branch_settings_row_data_list({branchSettingsSingleData:undefined}))

    })
}