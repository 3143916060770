import { Dialog, IconButton, MenuItem, Select } from "@mui/material";
import React from "react";
import { useState } from "react";
import "../../../../css/Staff/Payroll/staffPayrollItem.css"
export const StaffPayrollItem=()=>{

    const[payrollAddition,setPayrollAddition]=useState(false)
    const[payrollOvertime,setPayrollOvertime]=useState(false)
    const[payrollDeduction,setPayrollDeduction]=useState(false)

    return(
        <>
        <div className="global-page-parent-container">
            <p className="breadcrumb">Staff &gt; Payroll &gt; Payroll Item</p>
            <div className="staff-payroll-item-container">
                <div className="staff-payroll-item-container-left">
                    <div className="staff-payroll-item-container-top">
                        <h3>Additions</h3>
                        <button className="btn" 
                        onClick={()=>{setPayrollAddition(true)}}>
                            Add Additions</button>
                    </div>
                    <div className="global-table-container staff-payroll-item-table">
                        <table className="global-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Category</th>
                                    <th>Amount</th>
                                    <th style={{textAlign:"center"}}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Leave balance amount </td>
                                    <td>Monthly remuneration </td>
                                    <td>150.00 </td>
                                    <td style={{textAlign:"center"}}>
                                        <IconButton>
                                            <i className="bi bi-pencil-square edit-icon"></i>
                                        </IconButton>
                                        <IconButton>
                                            <i className="bi bi-trash3 delete-icon"></i>
                                        </IconButton>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Arrears of salary</td>
                                    <td>Additional remuneration</td>
                                    <td>500.00 </td>
                                    <td style={{textAlign:"center"}}>
                                        <IconButton>
                                            <i className="bi bi-pencil-square edit-icon"></i>
                                        </IconButton>
                                        <IconButton>
                                            <i className="bi bi-trash3 delete-icon"></i>
                                        </IconButton>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Gratuity</td>
                                    <td>Monthly remuneration </td>
                                    <td>1500.00 </td>
                                    <td style={{textAlign:"center"}}>
                                        <IconButton>
                                            <i className="bi bi-pencil-square edit-icon"></i>
                                        </IconButton>
                                        <IconButton>
                                            <i className="bi bi-trash3 delete-icon"></i>
                                        </IconButton>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="staff-payroll-item-container-left">
                    <div className="staff-payroll-item-container-top">
                        <h3>Overtime</h3>
                        <button className="btn btn-staff-payroll-deduction" onClick={()=>{setPayrollOvertime(true)}}>
                            Add Overtime</button>
                    </div>
                    <div className="global-table-container staff-payroll-item-table">
                        <table className="global-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Category</th>
                                    <th>Amount</th>
                                    <th style={{textAlign:"center"}}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Normal day OT</td>
                                    <td>Hourly</td>
                                    <td>1500.00</td>
                                    <td style={{textAlign:"center"}}>
                                        <IconButton>
                                            <i className="bi bi-pencil-square edit-icon"></i>
                                        </IconButton>
                                        <IconButton>
                                            <i className="bi bi-trash3 delete-icon"></i>
                                        </IconButton>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Public holiday OT</td>
                                    <td>Hourly</td>
                                    <td>200.00</td>
                                    <td style={{textAlign:"center"}}>
                                        <IconButton>
                                            <i className="bi bi-pencil-square edit-icon"></i>
                                        </IconButton>
                                        <IconButton>
                                            <i className="bi bi-trash3 delete-icon"></i>
                                        </IconButton>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Rest day OT</td>
                                    <td>Hourly</td>
                                    <td>120.00</td>
                                    <td style={{textAlign:"center"}}>
                                        <IconButton>
                                            <i className="bi bi-pencil-square edit-icon"></i>
                                        </IconButton>
                                        <IconButton>
                                            <i className="bi bi-trash3 delete-icon"></i>
                                        </IconButton>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="staff-payroll-item-container-left">
                    <div className="staff-payroll-item-container-top">
                        <h3>Deductions</h3>
                        <button className="btn btn-staff-payroll-deduction" onClick={()=>{setPayrollDeduction(true)}}>
                            Add Deductions</button>
                    </div>
                    <div className="global-table-container staff-payroll-item-table">
                        <table className="global-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th style={{textAlign:"center"}}>Amount</th>
                                    <th style={{textAlign:"center"}}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Absent amount</td>
                                    <td style={{textAlign:"center"}}>1500.00 </td>
                                    <td style={{textAlign:"center"}}>
                                        <IconButton>
                                            <i className="bi bi-pencil-square edit-icon"></i>
                                        </IconButton>
                                        <IconButton>
                                            <i className="bi bi-trash3 delete-icon"></i>
                                        </IconButton>
                                    </td>
                                </tr>
                                <tr>
                                <td>Advance</td>
                                    <td style={{textAlign:"center"}}>500.00 </td>
                                    <td style={{textAlign:"center"}}>
                                        <IconButton>
                                            <i className="bi bi-pencil-square edit-icon"></i>
                                        </IconButton>
                                        <IconButton>
                                            <i className="bi bi-trash3 delete-icon"></i>
                                        </IconButton>
                                    </td>
                                </tr>
                                <tr>
                                <td>Unpaid leave</td>
                                    <td style={{textAlign:"center"}}>800.00 </td>
                                    <td style={{textAlign:"center"}}>
                                        <IconButton>
                                            <i className="bi bi-pencil-square edit-icon"></i>
                                        </IconButton>
                                        <IconButton>
                                            <i className="bi bi-trash3 delete-icon"></i>
                                        </IconButton>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            <Dialog open={payrollAddition} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setPayrollAddition(false )}>
                <div className="staff-payroll-overtime-container">
                    <h3>Add Additions</h3>
                    <div className="global-single-input">
                        <p>Name</p>
                        <input type="text" />
                    </div>
                    <div className="global-single-input">
                        <p>Category</p>
                        <Select>
                            <MenuItem>Munnshe</MenuItem>
                        </Select>
                    </div>
                    <div className="global-single-input">
                        <p>Amount</p>
                        <input type="text" />
                    </div>
                    <div className="global-single-input">
                        <p>Assigne</p>
                        <div className="global-radio-button payroll-add-radio-button">
                            <input type="radio" name="payrollAsigne" id="noAssign" defaultChecked/>
                            <label htmlFor="noAssign">No Assigne</label>
                            <input type="radio" name="payrollAsigne" id="allEmployee"/>
                            <label htmlFor="allEmployee">All employees</label>
                            <input type="radio" name="payrollAsigne" id="selectedEmployee"/>
                            <label htmlFor="selectedEmployee">Select Employee</label>
                        </div>
                        <Select>
                            <MenuItem>Munshe</MenuItem>
                        </Select>
                    </div>
                    <div className="staff-payroll-overtime-container-button">
                        <button className="btn btn-secondary-outlined" onClick={()=>{setPayrollAddition(false)}}>Cancel</button>
                        <button className="btn btn-secondary">Submit</button>
                    </div>
                </div>
            </Dialog>
            <Dialog open={payrollOvertime} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setPayrollOvertime(false )}>
                <div className="staff-payroll-overtime-container">
                    <h3>Add Overtime</h3>
                    <div className="global-single-input">
                        <p>Name</p>
                        <input type="text" />
                    </div>
                    <div className="global-single-input">
                        <p>Amount Type</p>
                        <Select>
                            <MenuItem>
                                bulk
                            </MenuItem>
                        </Select>
                    </div>
                    <div className="global-single-input">
                        <p>Amount</p>
                        <input type="text" />
                    </div>
                    <div className="staff-payroll-overtime-container-button">
                        <button className="btn btn-secondary-outlined" onClick={()=>{setPayrollOvertime(false)}}>Cancel</button>
                        <button className="btn btn-secondary">Submit</button>
                    </div>
                </div>
            </Dialog>
            <Dialog open={payrollDeduction} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setPayrollDeduction(false )}>
                <div className="staff-payroll-overtime-container">
                    <h3>Add Deduction</h3>
                    <div className="global-single-input">
                        <p>Name</p>
                        <input type="text" />
                    </div>
                    <div className="global-single-input">
                        <p>Amount</p>
                        <input type="text" />
                    </div>
                    <div className="global-single-input">
                        <p>Assigne</p>
                        <div className="global-radio-button payroll-add-radio-button">
                            <input type="radio" name="payrollAsigne" id="noAssign" defaultChecked/>
                            <label htmlFor="noAssign">No Assigne</label>
                            <input type="radio" name="payrollAsigne" id="allEmployee"/>
                            <label htmlFor="allEmployee">All employees</label>
                            <input type="radio" name="payrollAsigne" id="selectedEmployee"/>
                            <label htmlFor="selectedEmployee">Select Employee</label>
                        </div>
                        <Select>
                            <MenuItem>Munshe</MenuItem>
                        </Select>
                    </div>
                    <div className="staff-payroll-overtime-container-button">
                        <button className="btn btn-secondary-outlined" onClick={()=>{setPayrollDeduction(false)}}>Cancel</button>
                        <button className="btn btn-secondary">Submit</button>
                    </div>
                </div>
            </Dialog>
        </div>
        </>
    )
}