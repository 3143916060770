import axios from 'axios'
import {get_sales_orderList} from '../../Redux/Sales/OrderList/viewOrderListSlice'
import {get_single_view_order_list} from '../../Redux/Sales/OrderList/singleViewOrderListSlice'
import {HEADERS, HEADER_QUERY} from '../../API/UrlAndPaths'
import store from '../../Redux/store'

// view sales order list
export const salesOrderListAPICall= async (setIsLoading,setErrorSnack,setSnackMsg,body)=>{
  if(setIsLoading!==undefined) setIsLoading(true)
  await axios.post("sales/viewOrder",body,HEADERS)
  .then((res)=>{
    if(res.status === 200){
        store.dispatch(get_sales_orderList({salesOrderListData:res.data}))
        if(setIsLoading!==undefined) setIsLoading(false)
    }
  })
  .catch((err)=>{
    store.dispatch(get_sales_orderList({salesOrderListData:undefined}))
    console.log(err.response);
    switch (err.response.status) {
      case 404:
          setSnackMsg('No data available..')
        break;
      case 500:
          setSnackMsg('Server error!!')
        break;
      case 401:
          setSnackMsg('Unauthorized!!')
        break;
      case 403:
          setSnackMsg('Forbidden!!')
        break;
    
      default:
          setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
        break;
    }
    setErrorSnack(true)
    if(setIsLoading!==undefined) setIsLoading(false)
  })
}

// view sales single view order list
export const singleViewSalesOrderListAPICall= async (body)=>{
    await axios.post("sales/viewOrderbyId",body,HEADERS)
    .then((res)=>{
        if(res.status === 200){
            store.dispatch(get_single_view_order_list({singleViewOrderListData:res.data}))
        }
    })
    .catch((err)=>{
        console.log(err.response);
        store.dispatch(get_single_view_order_list({singleViewOrderListData:undefined}))
    })
}

// delete order list
export const deleteOrderListAPICall= async (
  _id,
  updateListner,
  setSuccesSnackOpen,
  setErrorSnackOpen,
  setSnackMsg
  )=>{
 await axios.delete("sales/deleteOrder",{
  headers:HEADER_QUERY,
  data:{
    _id
  }
 })
 .then((res)=>{
    if(res.status === 200){
       updateListner()
       setSnackMsg('Deleted successfully')
       setSuccesSnackOpen(true)
    }
 })
 .catch((err)=>{
  console.log(err.response);
  switch (err.response.status) {
    case 404:
        setSnackMsg('No data available..')
      break;
    case 500:
        setSnackMsg('Server error!!')
      break;
    case 401:
        setSnackMsg('Unauthorized!!')
      break;
    case 403:
        setSnackMsg('Forbidden!!')
      break;
  
    default:
        setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
      break;
  }
  setErrorSnackOpen(true)
 })
}