import { createSlice } from "@reduxjs/toolkit";

export const deliveryFilterDataSlice=createSlice({
    name:'deliveryFilterDataSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_delivery_filter_data:(state,action)=>{
            state.value=action.payload.deliveryFilterData
        }
    }
})
export const {update_delivery_filter_data} = deliveryFilterDataSlice.actions
export default deliveryFilterDataSlice.reducer