import { createSlice } from "@reduxjs/toolkit";

export const allStockOutSlice=createSlice({
    name:'allStockOutSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_all_stockout_list:(state,action)=>{
            state.value=action.payload.allStockOut
        }
    }
})

export const {update_all_stockout_list}=allStockOutSlice.actions
export default allStockOutSlice.reducer