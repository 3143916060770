import {createSlice} from '@reduxjs/toolkit'

export const orderNoListSlice=createSlice({
    name:'orderNoListSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_orderNo_List:(state,action)=>{
            state.value=action.payload.orderNoList
        }
    }
})
export const {get_orderNo_List} =orderNoListSlice.actions;
export default orderNoListSlice.reducer