import { IconButton, Skeleton } from "@mui/material";
import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { viewPendingPurchaseVoucherAPICall } from "../../../../API/Purchase Manager/Payment Voucher/pendingPaymentVoucherAPI";
import "../../../../css/Purchase Manager/pendingPaymentVoucher.css";
import ReactToPrint from "react-to-print";
import { convertDateFormat, today } from "../../../../Js/Date";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
export const PendingPaymentVoucher = () => {
  let pendingPaymentVoucherSinglePrint=useRef
  let pendingPaymentVoucherPrintFullBody=useRef
  const [singleRowId,setSingleRowId]=useState(null)
  const [time, setTime] = useState(null);
  const [searchKeyword,setSearchKeyword]=useState("")
  // loading state
  const [isLoading,setIsloading]=useState(false)
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!");
  const [filteredPaymentList,setFilteredPaymentList]=useState([])

  const pendingPaymentVoucherData = useSelector(
    (state) => state.pendingPaymentVoucherSlice.value
  );
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  const userRole = useSelector((state) => state.userRoleSlice.value);
  const getSearchKeyword=(e)=>{
    setSearchKeyword(e.target.value)
  }
  //Close ErrorSnackbar
  const closeErrorSnackbar = () => {
    setErrorSnackOpen(false);
  };
  const SearchFilter=(chars,paymentList)=>{
    let searchedArray=[]
    let filteredArray=[]
    if(chars!==""){
      searchedArray=paymentList.filter((obj)=>
        obj?.transNo?.toLowerCase()?.includes(chars?.toLowerCase())||
        obj?.wpoId?.toLowerCase()?.includes(chars?.toLowerCase())||
        obj?.supplierName?.toLowerCase()?.includes(chars?.toLowerCase())
      )
      filteredArray=searchedArray
    }
    else{
      filteredArray=paymentList
    }
    return filteredArray
  }
  useEffect(()=>{
    if(pendingPaymentVoucherData!==undefined){
      let filteredArray=[]
      setFilteredPaymentList(pendingPaymentVoucherData)
      filteredArray=SearchFilter(searchKeyword,pendingPaymentVoucherData)
      setFilteredPaymentList(filteredArray)    
    }
    else{
      setFilteredPaymentList([])
    }
  },[searchKeyword,pendingPaymentVoucherData])
  useEffect(()=>{
    setInterval(()=>{
      setTime(`${new Date().getHours()}:${new Date().getMinutes()}`)
    })
  },[time])
  useEffect(()=>{
    viewPendingPurchaseVoucherAPICall(
      setIsloading,setErrorSnackOpen,setSnackMsg);
  },[])
  return (
    <>
      <div className="global-page-parent-container pending-payment-voucher-parent-container">
        <p className="breadcrumb">
          Purchase Manage &gt; Pending Payment Voucher
        </p>
        <div className="pending-payment-voucher-main-container">
          <div className="pending-payment-voucher-main-container-top">

            <h3>Pending Payment Voucher</h3>
            <div className="supplier-manage-top-container-right">
              <div className="view-order-search-container view-order-admin-search-container">
                  <input
                      placeholder="Transaction ID,Supplier Name,GRN ID"
                       value={searchKeyword}
                       onChange={getSearchKeyword}
                      />
                  <IconButton>
                      <i class="bi bi-search search-icon-order"></i>
                  </IconButton>
              </div>
              <ReactToPrint
              trigger={()=>( 
                <IconButton className="print-icon-container">
                  <i class="bi bi-printer printer-icon"></i>
                </IconButton>
                )}
              content={()=>pendingPaymentVoucherPrintFullBody}
              pageStyle="@page { size: auto;  margin: 0 0 0 0; } }"
              /> 
            </div>
            
            </div>
          <div className="global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Transaction ID</th>
                  <th>Branch Code</th>
                  <th>Branch Name</th>
                  <th>GRN ID</th>
                  <th>Supplier</th>
                  <th>Date</th>
                  <th>Credit Amount</th>
                  <th>Last Paid Date</th>
                  <th>Last Paid Amount</th>
                  <th>Print</th>
                </tr>
              </thead>
              {
                isLoading?
                <tbody>
                    <tr>
                           <td colSpan={11}>
                              {/* <Loader /> */}
                              <Skeleton
                                 variant="rectangular"
                                 animation="wave"
                                 width={"100%"}
                                 height={40}
                              />
                           </td>
                           </tr>
                           <tr>
                           <td colSpan={11}>
                              {/* <Loader /> */}
                              <Skeleton
                                 variant="rectangular"
                                 animation="wave"
                                 width={"100%"}
                                 height={40}
                              />
                           </td>
                           </tr>
                           <tr>
                           <td colSpan={11}>
                              {/* <Loader /> */}
                              <Skeleton
                                 variant="rectangular"
                                 animation="wave"
                                 width={"100%"}
                                 height={40}
                              />
                           </td>
                           </tr>
                           <tr>
                           <td colSpan={11}>
                              {/* <Loader /> */}
                              <Skeleton
                                 variant="rectangular"
                                 animation="wave"
                                 width={"100%"}
                                 height={40}
                              />
                           </td>
                           </tr>
                           <tr>
                           <td colSpan={11}>
                              {/* <Loader /> */}
                              <Skeleton
                                 variant="rectangular"
                                 animation="wave"
                                 width={"100%"}
                                 height={40}
                              />
                           </td>
                           </tr> 
                           <tr>
                           <td colSpan={11}>
                              {/* <Loader /> */}
                              <Skeleton
                                 variant="rectangular"
                                 animation="wave"
                                 width={"100%"}
                                 height={40}
                              />
                           </td>
                           </tr> 
                           <tr>
                           <td colSpan={11}>
                              {/* <Loader /> */}
                              <Skeleton
                                 variant="rectangular"
                                 animation="wave"
                                 width={"100%"}
                                 height={40}
                              />
                           </td>
                           </tr> 
                           <tr>
                           <td colSpan={11}>
                              {/* <Loader /> */}
                              <Skeleton
                                 variant="rectangular"
                                 animation="wave"
                                 width={"100%"}
                                 height={40}
                              />
                           </td>
                           </tr>
                </tbody>:
              <tbody>
                {filteredPaymentList.length!==0?(
                  filteredPaymentList
                  ?.slice(0)
                  ?.reverse()
                  ?.map((r,i)=>(
                  <tr key={i}>
                  <td>{i+1}</td>
                  <td>{r?.transNo}</td>
                  <td>{r?.branchId}</td>
                  <td>{r?.branchName}</td>
                  <td>{r?.wpoId}</td>
                  <td>{r?.supplierName}</td>
                  <td>{r?.date==="no date"?'##':convertDateFormat(r?.date)}</td>
                  <td>{r?.creditAmount}</td>
                  <td>{convertDateFormat(r?.lastPaidDate)}</td>
                  <td>{r?.lastPaidAmount}</td>
                  <td>
                    <ReactToPrint
                       trigger={()=>( 
                        <IconButton >
                           <i class="bi bi-printer printer-icon"></i>
                        </IconButton>
                        )}
                       
                       onBeforeGetContent={()=>
                       {
                        setSingleRowId(r);
                        return Promise.resolve()
                      }
                      } 
                    
                      content={()=>pendingPaymentVoucherSinglePrint}
                    /> 
                   
                  </td>
                </tr>

                  ))

                ): (
                  <tr>
                  <td colSpan={11} style={{textAlign:"center"}}>No Records</td>
                </tr>)}
              </tbody>
              }
            </table>
          </div>
        </div>
      </div>

      {/* pending payment voucher single print */}
      <div 
       style={{display:"none"}}
      >
        {singleRowId !== null &&
         <div ref={(el)=>(pendingPaymentVoucherSinglePrint = el)}>
         <div className='print-view-top-container'>
            <div className='print-view-top-left-container' style={{width:"30%"}}>
              <h4>Trans ID : {singleRowId?.transNo}</h4>
            </div>
            <div className='print-view-top-middle-container' style={{width:"40%"}}>
                 <h3>PENDING PAYMENT VOUCHER</h3>
            </div>
            <div className='print-view-top-right-container' style={{width:"30%"}}>
                <h4 style={{textAlign:'right'}}>{convertDateFormat(today)}</h4>
            </div>
          </div>
           <div className="pending-payment-voucher-single-print">
                  <div className="pending-payment-voucher-body-section">
                    <div className="pending-payment-voucher-body-section-left">
                      <p>GRN ID &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        :{singleRowId?.wpoId}</p>
                    </div>
                    <div className="pending-payment-voucher-body-section-left">
                      <p>Supplier Name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        :{singleRowId?.supplierName}</p>
                    </div>

                  </div>
                  <div className="pending-payment-voucher-body-section">
                    <div className="pending-payment-voucher-body-section-right">
                      <p>Credit Amount &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        :{singleRowId?.creditAmount}</p>
                    </div>
                    <div className="pending-payment-voucher-body-section-right">
                      <p>Credit Date &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        :{convertDateFormat(singleRowId?.date)}</p>
                    </div>
                  </div>
                  <div className="pending-payment-voucher-body-section">
                    <div className="pending-payment-voucher-body-section-right">
                      <p>Last Paid Amount &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        :{singleRowId?.lastPaidAmount}</p>
                    </div>
                    <div className="pending-payment-voucher-body-section-right">
                      <p>Last Paid Date &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        :{convertDateFormat(singleRowId?.lastPaidDate)}</p>
                    </div>
                  </div>
                  <div className="pending-payment-voucher-body-signature-section">
                        <div className="pending-payment-voucher-body-signature-section-box"></div>
                        <div className="pending-payment-voucher-body-signature-section-box">
                          <p>Signature</p>
                        </div>
                  </div>
           </div>
         </div>
        }
      </div>


      <div style={{display:'none'}}>
        <div ref={(el)=>(pendingPaymentVoucherPrintFullBody=el)}>
          <div className='print-view-top-container'>
              <div className='print-view-top-left-container' style={{width:"30%"}}>
              <h6 style={{margin:"0 0 0 2%"}}>Printed On : {convertDateFormat(today)}&nbsp;{time}</h6>
              
              </div>
              <div className='print-view-top-middle-container' style={{width:"40%"}}>
              {userRole === "admin" ? (
                  <h3>{loginResponse?.data?.profile?.companyName}</h3>
                ) : userRole === "user" ? (
                  <h3>{loginResponse?.storeName}</h3>
                ) : undefined}

                  <p>PENDING PAYMENT VOUCHER</p>
              </div>
              <div className='print-view-top-right-container' style={{width:"30%"}}>
              {
                userRole==="user" ?(
                  <p>{loginResponse?.branchId}-{loginResponse?.branchName}</p>
                ):undefined  
              }
              </div>
            </div>
            <div className='print-view-table-container report-print-view-table-with-clr'>

            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Transaction <br />ID</th>
                  {
                    userRole === 'admin' &&
                    <>
                    <th>Branch <br />Code</th>
                    <th>Branch <br />Name</th>
                    </>
                  }
                  <th>GRN ID</th>
                  
                  <th>Supplier</th>
                  <th>Date</th>
                  <th>Credit<br /> Amount</th>
                  <th>Last Paid<br /> Date</th>
                  <th>Last Paid<br /> amount</th>
                </tr>
              </thead>
              <tbody>
              {
                pendingPaymentVoucherData?.length !==0 ? 
                pendingPaymentVoucherData
                ?.slice(0)
                ?.reverse()
                ?.map((r,i)=>(
                    <tr>
                    <td>{i+1}</td>
                    <td>{r?.transNo}</td>
                    {
                      userRole === 'admin' &&
                      <>
                      <td>{r?.branchId}</td>
                      <td>{r?.branchName}</td>
                      </>
                    }
                    
                    <td>{r?.wpoId}</td>
                    <td>{r?.supplierName}</td>
                    <td>{r?.date==="no date"?'##':convertDateFormat(r?.date)}</td>
                    <td>{r?.creditAmount}</td>
                    <td>{convertDateFormat(r?.lastPaidDate)}</td>
                    <td>{r?.lastPaidAmount}</td>
                  </tr>
                  ))
                  :
                  <tr>
                    <td colSpan={11}>No Data</td>
                  </tr>
                }
              </tbody>
            </table>
            </div>
        </div>
      </div>
      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </>
  );
};
