import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import store from '../../../../../Redux/store';
import { Skeleton } from '@mui/material';
import { get_sales_order_row_data_list } from './salesOrderSlice';
import { salesOrderFilterAPICall, viewSalesOrdersListAPICall } from './salesOrderAPI';
import { convertDateFormat, getCurrentTime } from '../../../../../Js/Date';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import CustomPagination from '../../../../Single Components/CustomPagination';
import axios from 'axios';
import { setFilterActive } from '../../../../Single Components/listSlice/filterCategorySlice';

export const SalesOrder = () => {
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const tableListData = useSelector((state) => state.salesOrderSlice.apiResList);
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const totalPages = tableListData?.pages;

  let navigate = useNavigate();

  const handleDataFromChild = (data) => {
    console.log(data);
    setFormDataInfo(data)
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };

  const userRole = useSelector((state) => state.userRoleSlice.value)
  const userBranchId = localStorage.getItem("branchId")



  const createBranchTransfer = () => {
    navigate("/userdashboard/sales/orders/salesOrder/create")

  }
  const [isLoading, setIsLoading] = useState(false)

  const singleViewFn = (row) => {
    store.dispatch( setFilterActive(window.location.pathname))
    //  store.dispatch(get_sales_order_row_data_list({salesOrderSingleData:row}))
    //navigate("/userdashboard/sales/orders/salesOrder/create")
    localStorage.setItem("singleViewById", row?._id)
    navigate("/userdashboard/sales/orders/salesOrder/singleView")
  }
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };

  useEffect(() => {
   
    if (FormDataInfo?.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData &&salesOrderFilterAPICall({
        statusType: 2,
        fromDate: FormDataInfo?.fromDate === '' ? '' : `${FormDataInfo?.fromDate} 00:00:00`,
      toDate: FormDataInfo?.toDate === '' ? '' : `${FormDataInfo?.toDate} 23:59:59`,
        createdBy: FormDataInfo?.createdBy,
        branchList: FormDataInfo?.branchList,
        status: FormDataInfo?.status?.length === 0 ? '' : FormDataInfo?.status[0],
        search: FormDataInfo?.search,
      }, setIsLoading);
    }
 
  }, [FormDataInfo]);

 
  console.log(FormDataInfo);



  useEffect(() => {
    // if (userRole === "user") {
    //   viewSalesOrdersListAPICall({ branchId: userBranchId }, setIsLoading)
    // }
    // else {
    //   viewSalesOrdersListAPICall({}, setIsLoading)
    // }
    localStorage.setItem("singleViewById", "")
    store.dispatch(get_sales_order_row_data_list({ salesOrderSingleData: undefined }))
  }, [])

  return (
    <div className='global-page-parent-container'>
      {/* table */}
      <div className='global-white-bg-container'>
        <div className="justify-space-between"
          style={{ gap: "0 12px", display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}
        >
          {FormDataInfo?.branchList?.length > 0 && (
              <div style={{ marginLeft: '1%' }}>
               <h3>Branch name: 
                  {selectedBranch?.storeCode||filtereDataOptions?.filterCategorylist?.branchList?.storeCode}
                  -{selectedBranch?.branchName||filtereDataOptions?.filterCategorylist?.branchList?.branchName}</h3>
              </div>
            )}
             <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap:'9px',
              width: FormDataInfo?.branchList?.length ? '72%' : '100%'
            }}>
          <CategorySearchandFilter
            statusOptions={['SALES ORDER', 'DONE']}
            onData={handleDataFromChild}
            isCreatedBy={true}
            isStatus={true}
            onBranchSelect={handleBranchSelect}
          />
          <button onClick={createBranchTransfer} className='create-button-blue'>Create</button>
          </div>
        </div>
        <div className='create-button-blue-container'>
          <h3>Sales Order</h3>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />
          {/* <button onClick={createBranchTransfer}  className='create-button-blue'>Create</button> */}
        </div>
        <div className='new-global-table-container'>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Customer</th>
                <th>Total</th>
                <th>Created By</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody >
              {

                isLoading ? (
                  [...Array(10)].map((r, i) => (
                    <tr key={i}>
                      <td colSpan={8}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                  ))
                ) :

                  tableListData?.list !== undefined && tableListData?.list?.length !== 0 ? tableListData?.list?.slice(0)?.map((r, i) => (
                    <tr onClick={() => singleViewFn(r)}>
                      <td>{convertDateFormat(r?.quotationDate)}</td>
                      <td>{r?.sequenceName || r?.NAME}</td>
                      <td>{r?.CUSTOMER}</td>
                      <td>{r?.total}</td>
                      <td>{r?.CREATEDBY}</td>
                      <td className='table-data-box'>
                        {r?.status === "SALES ORDER" ? (
                          <span className='sales-order'> Sale Order</span>
                        ) : r?.status === "DONE" ? (
                          <span className='post'> Done</span>
                        ) : (
                          <span>{r?.status}</span>
                        )}
                      </td>
                    </tr>
                  ))
                    :
                    <tr>
                      <td colSpan={7}>No Data</td>
                    </tr>
              }
            </tbody>

          </table>
        </div>
      </div>

    </div>
  )
}
