import { createSlice } from "@reduxjs/toolkit";

export const searchUserSlice = createSlice({
  name: "searchUserSlice",
  initialState: {
    value: undefined,
  },
  reducers: {
    update_search_user_response: (state, action) => {
      state.value = action.payload.searchUserData;
    },
  },
});

export const { update_search_user_response } = searchUserSlice.actions;
export default searchUserSlice.reducer;
