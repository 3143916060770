import { createSlice } from "@reduxjs/toolkit";

export const customerRelationIdSlice = createSlice({
  name: "customerRelationIdSlice",
  initialState: {
    value: undefined,
    customerValue:undefined,
  },
  reducers: {
    update_customer_relation_id: (state, action) => {
      state.value = action.payload.customerRelationId;
    },
    single_customer_history:(state,action)=>{
      state.customerValue = action.payload.customerHistory;
    }
  },
});

export const { update_customer_relation_id ,single_customer_history} = customerRelationIdSlice.actions;

export default customerRelationIdSlice.reducer;
