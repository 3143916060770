import { createSlice } from "@reduxjs/toolkit";

export const viewInvoiceNoListAddPurchseReturnSlice=createSlice({
    name:'addPurchseReturnInvoiceList',
    initialState:{
        value:undefined
    },
    reducers:{
        get_paurchseReturn_invoiceNO:(state,action)=>{
            state.value=action.payload.purchaseReturnInvoice
        }
    }
})
export const {get_paurchseReturn_invoiceNO} = viewInvoiceNoListAddPurchseReturnSlice.actions;
export default viewInvoiceNoListAddPurchseReturnSlice.reducer