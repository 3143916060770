import React, { useEffect } from "react";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useDispatch, useSelector } from "react-redux";
import { setFilterActive, setPaginationNumber } from "./listSlice/filterCategorySlice";

const CustomPagination = ({setFormDataInfo, currentPage, totalPages, onPageChange }) => {
 
  const filtereDataOptions = useSelector((state) => state.listCategory);
 
  const maxPagesToShow = 4;
  const pageNumbers = Array.from({ length: Math.min(totalPages, maxPagesToShow) }, (_, i) => i + 1);
  const dispatch=useDispatch()
  const handlePrevPage = () => {
    const prevPage = currentPage - 1;
    if (prevPage >= 1) {
      dispatch(setFilterActive(false));
      dispatch(setPaginationNumber(prevPage));
      onPageChange(prevPage);
    }
  };

  const handleNextPage = () => {
    const nextPage = currentPage + 1;
    if (nextPage <= totalPages) {
      onPageChange(nextPage);
      dispatch(setPaginationNumber(nextPage));
      dispatch(setFilterActive(false));

    }
  };

 

  const handleInputChange = (e) => {
    dispatch(setFilterActive(false));

    const value = e.target.value;
    let finalValue;

    if (value === "") {
      setFormDataInfo = ""; 
    } else {
        const numericValue = Number(value);
        if (isNaN(numericValue)) {

            finalValue = 1; 
        } else {
            finalValue = Math.max(1, Math.min(numericValue, totalPages));
        }
    }

    onPageChange(finalValue === ""? null:finalValue);
    dispatch(setPaginationNumber(finalValue === ""? null:finalValue));

    setFormDataInfo((prev)=>({...prev,currentPage:finalValue === ""? null:finalValue}))
  };

  useEffect(() => {
    let loadData = window.location.pathname === filtereDataOptions?.filterActive 
    if (loadData) {
      onPageChange(filtereDataOptions?.paginationIndex || 1)
    }
  }, [filtereDataOptions])
  return (
    <div className="custom-paginations-ctn">
      <span>Page : </span>
      {pageNumbers.filter((obj,i)=>i<=4).map((page) => (
        <a
          key={page}
          className={currentPage === page ? "actives" : ""}
          onClick={() => {
            dispatch(setFilterActive(false))
            onPageChange(page)
            dispatch(setPaginationNumber(page));
          }}
            
        >
          {page}
        </a>
      ))}
      <span>
        <a
          onClick={handlePrevPage}
          className={currentPage === 1}
        >
          <KeyboardArrowLeftIcon />
        </a>
        <a
          onClick={handleNextPage}
          className={currentPage === totalPages}
        >
          <KeyboardArrowRightIcon />
        </a>
      </span>

      <input
        type="Number"
        value={currentPage}
        onChange={handleInputChange}
        className="current-page"
        onFocus={(e)=>e.target.select()}
      />
      <div className="pagination-info">
        <span>of {totalPages || 0}</span>
      </div>
    </div>
  );
};

export default CustomPagination;
