import axios from "axios";
import { update_empCountGraph } from "../../../Redux/Staff/Staff Dashboard/empCountGraphSlice";
import { update_expenseGraph } from "../../../Redux/Staff/Staff Dashboard/expenseGraphSlice";
import { update_noticeboard } from "../../../Redux/Staff/Staff Dashboard/noticeboardSlice";
import store from "../../../Redux/store";
import { HEADERS, HEADER_QUERY } from "../../UrlAndPaths";

//Get total expense graph
export const totalExpenseGraphAPICall = async (body) => {
  await axios
    .post("staffDashboad/totalExpenseGraph", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        //create redux and save
        store.dispatch(update_expenseGraph({ expenseGraphData: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_expenseGraph({ expenseGraphData: undefined }));
    });
};

//Employee Count Graph API Call
export const employeeCountChartAPICall = async () => {
  await axios
    .post("staffDashboad/employeeGenderCount", {}, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        //todo create slice
        store.dispatch(update_empCountGraph({ empCount: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_empCountGraph({ empCount: undefined }));
    });
};

//View Notice Board
export const getNoticeboardAPICall = async () => {
  await axios
    .get("staffDashboad/viewNoticeBoard", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        //Create slice and save
        store.dispatch(update_noticeboard({ noticeboard: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_noticeboard({ noticeboard: undefined }));
    });
};

//Add New Notice in board
export const addNewNoticeInBoard = async (body,successSnack,errorSnack,snackMsg,updateListener,clearState,closeDialog) => {
  await axios
    .post("staffDashboad/addNoticeBoard", body, HEADERS)
    .then((res)=>{
      if(res.status===200)
      {
        snackMsg('New notice added successfully 👍')
        successSnack(true)
        updateListener()
        clearState()
        closeDialog()
      }
    })
    .catch((err)=>{
      snackMsg(err.response.data.msg)
      errorSnack(true)
    });
};
// delete a notice

export const deleteSingleNoticeAPICall=async(body,updateListener)=>{
  await axios.delete("staffDashboad/deleteNoticeBoad",{headers:HEADER_QUERY,data:body})
    .then((res)=>{
      if(res.status===200)
      {
        updateListener()
      }
    })
    .catch((err)=>{
      console.error(err.response.status)
    })
}