import React from 'react'
import '../../css/Single Components/creditAndWallet.css'
import { CallMadeOutlined, CallReceivedOutlined } from "@mui/icons-material";
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { filterObjFromList } from '../../Js/generalFunctions';
import { companyProfileInfoForUser } from '../../API/Settings/Company Settings/companySettingsAPI';
import { viewAllCurrencyUpdated } from '../../API/Settings/Currency/CurrencyAPI';
import { useState } from 'react';

export const CreditAndWallet = (props) => {
    const {label,background,width,amount} =props

    const currencyListUpdated = useSelector(
      (state) => state.viewUpdatedCurrency.value
    );
  
    const companyProfileData =useSelector(
      (state) => state?.companyProfileSlice?.userValue)
      
    // login response
   const loginResponse = useSelector((state) => state.loginResponseSlice.value);

   const [symbol,setSymbol]=useState(null)

   useEffect(()=>{
    let currencyObj=filterObjFromList("_id",currencyListUpdated,"currency",companyProfileData)
    setSymbol(currencyObj?.symbol || "")
  },[companyProfileData,currencyListUpdated])

  useEffect(()=>{
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  },[])
  return (
    //  <div className="credit-wallet-container">
    //    <div className="credit-wallet-left-container">
    //      <div className="card-container" style={{background:background}}>
    //        <h5>Card</h5>
    //        <h3 className="card-amount">Rs 50,000.00</h3>
    //        <p>**** **** 0123</p>
    //        <hr className="card-line-1" />
    //        <hr className="card-line-2" />
    //        <hr className="card-line-3" />
    //      </div>
    //    </div>
    //   <div className="credit-wallet-right-container">
    //     <h4>Total {label}</h4>
    //     <p>Rs 50,000.00</p>
    //     <div className="credit-wallet-amount-container">
    //         <div className="credit-wallet-single-amount-container">
    //            <CallMadeOutlined sx={{color:"#eb0000",fontSize:"1.3rem"}}/>
    //            <p>Rs 10,000.00</p>
    //         </div>
    //         <div className="credit-wallet-single-amount-container second-amt-container">
    //            <CallReceivedOutlined sx={{color:"#00c965",fontSize:"1.3rem"}}/>
               
    //            <p>Rs 10,000.00</p>
    //         </div>
    //     </div>
    //   </div> 
    // </div>

    <div className="credit-card-container" style={{background:background,width:width}}>
       <h5>{label}</h5>
       <h3 className="card-amount">{symbol} {amount}</h3>
       <p>**** **** 0123</p>
       <hr className="card-line-1" />
       <hr className="card-line-2" />
       <hr className="card-line-3" />
    </div>
    

  )
}
