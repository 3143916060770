import { createSlice } from "@reduxjs/toolkit";

export const productSingleVieSlice=createSlice({
    name:'productSingleViewSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_product_singleView:(state,action)=>{
            state.value=action.payload.productSingleView
        }
    }
})

export const {update_product_singleView} =productSingleVieSlice.actions
export default productSingleVieSlice.reducer
