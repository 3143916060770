import React, { useEffect, useState } from 'react'
import { viewAllCurrencyUpdated } from '../../../../API/Settings/Currency/CurrencyAPI';
import { companyProfileInfoForUser } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import { filterObjFromList } from '../../../../Js/generalFunctions';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IconButton, Skeleton } from '@mui/material';
import CustomPagination from '../../../Single Components/CustomPagination';
import { convertDateFormat } from '../../../../Js/Date';
import CategorySearchandFilter from '../../../Single Components/CategorySearchandFilter';
import { CustomerCreditReportSecondSingleViewAPICall } from './CustomerCreditReportAPI';

function CustomerCreditReportSingleView2() {
    let navigate = useNavigate();
    let location = useLocation()
  
    const currencyListUpdated = useSelector(
      (state) => state.viewUpdatedCurrency.value
    );
    const companyProfileData = useSelector(
      (state) => state?.companyProfileSlice?.userValue)
  
      const SingleListData = useSelector((state) => state?.customerCreditReport?.customerCreditSecondSingleView)
    console.log(SingleListData,'llllllll')
    const [FormDataInfo, setFormDataInfo] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [symbol, setSymbol] = useState(null);
    const handleDataFromChild = (data) => {
        setFormDataInfo(data);
    };

    const singleViewId = localStorage.getItem("SingleViewId1")
    const ref = localStorage.getItem("Ref")
    const refValue=Number(ref)

    const totalPages = 10;
    const decimalPosition = localStorage.getItem("decimalPosition");

    const { customerName } = location.state;

    const handlePageChange = (page) => {
  
      setCurrentPage(page);
    };

    const handleBackClick = (row) => {
      localStorage.removeItem("SingleViewId1")
      navigate('/userdashboard/report/CustomerCreditReport')
  };
    const handleSingleViewClick = (row) => {
      localStorage.removeItem("SingleViewId1")
      navigate('/userdashboard/report/CustomerCreditReport/SingleView1', { state: { customerName } });

  };
    useEffect(() => {

      CustomerCreditReportSecondSingleViewAPICall({
        _id: singleViewId,
        ref: refValue,
      })
    }, [singleViewId,ref])
  
    useEffect(() => {
      let currencyObj = filterObjFromList("_id", currencyListUpdated, "currency", companyProfileData)
      setSymbol(currencyObj?.symbol)
    }, [companyProfileData, currencyListUpdated])
    
    useEffect(() => {
      viewAllCurrencyUpdated({ status: 0 });
      companyProfileInfoForUser();
  }, [])
  return (
    <div className="global-page-parent-container">
    {/* table */}
    <div className="global-white-bg-container">
      {/* <div className='create-button-blue-container' style={{ margin: "1%", display: 'flex', justifyContent: 'flex-end', alignItems: 'end' }}>
        

        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          width: '100%'
        }}>


          <CategorySearchandFilter
            // statusOptions={['POSTED', 'DRAFT']}
            onData={handleDataFromChild}
            style={{ width: "100%" }}
            isLocation={true}
            isCategory={true}
            isposCategory={true}
            isDate={false}

          // isStatus={true}
          />
          <IconButton
            style={{ width: "100px" }}

            className="print-icon-container">
            <i class="bi bi-printer printer-icon"></i>
          </IconButton>
          <IconButton
            style={{ width: "100px" }}
            className="download-icon-container">
            <i class="bi bi-arrow-down-circle download-icon"></i>
          </IconButton>
        </div>

      </div> */}

<div className='create-button-blue-container' style={{ margin: "1%", display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
          <div>
          <h3 style={{ display: 'inline-block' }}>
            <span
                style={{ color: 'gray', cursor: 'pointer' }}
                onClick={handleBackClick}
            >
                Customer credit Report
            </span>
            <ArrowForwardIosIcon style={{ fontSize: '0.9rem' }} />
            <span style={{  cursor: 'pointer' }} onClick={handleSingleViewClick}>
            {location.state.customerName}
            </span>
            <ArrowForwardIosIcon style={{ fontSize: '0.9rem' }} />
            {location.state.invoiceNo}
        </h3>
          </div>

          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '50%'
          }}>


            <CategorySearchandFilter
            // statusOptions={['POSTED', 'DRAFT']}
            onData={handleDataFromChild}
            style={{ width: "100%" }}
          

          // isStatus={true}
          /> 
            <IconButton
              style={{ width: "100px" }}

              className="print-icon-container">
              <i class="bi bi-printer printer-icon"></i>
            </IconButton>
            <IconButton
              style={{ width: "100px" }}
              className="download-icon-container">
              <i class="bi bi-arrow-down-circle download-icon"></i>
            </IconButton>
          </div>

        </div>

      <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}

          />
        </div>
      <div className="customer-receipt-table-container">
        <table>
          <thead>
            <tr>

              <th>TYPE</th>
              <th>PRODUCT</th>
              <th>QTY</th>
              <th>RATE</th>
              <th>RETURN QTY</th>
              <th>RETURN AMOUNT</th>
              <th>STITCHING AMOUNT</th>
              <th>TAX</th>
              <th>TOTAL</th>
            </tr>
          </thead>
          <tbody>

            {isLoading ? (
              [...Array(10)].map((r, i) => (
                <tr key={i}>
                  <td colSpan={10}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
              ))
            ) : SingleListData?.list !== undefined && SingleListData?.list?.length !== 0 ? (
              SingleListData?.list
                ?.slice(0)
                ?.map((r, i) => (
                  <tr key={i}>
            
                   <td>{r?.type}</td>
                   <td>{r?.product}</td>
                   <td>{r?.qty?.toFixed(decimalPosition)}</td>
                   <td>{symbol}&nbsp;{r?.rate?.toFixed(decimalPosition)}</td>
                   <td>{r?.returnQty?.toFixed(decimalPosition)}</td>
                   <td>{symbol}&nbsp;{r?.returnAmount?.toFixed(decimalPosition)}</td>
                   <td>{symbol}&nbsp;{r?.stitchRate?.toFixed(decimalPosition)}</td>
                   <td>{symbol}&nbsp;{r?.tax?.toFixed(decimalPosition)}</td>
                   <td>{symbol}&nbsp;{r?.total?.toFixed(decimalPosition)}</td>
                   {/* <td>{symbol}&nbsp;{r?.amountDue?.toFixed(decimalPosition)}</td> */}

                  </tr>
                ))
            ) : (
              <tr>
                <td style={{ textAlign: 'center' }} colSpan={9}>No Data</td>
              </tr>
            )}

          </tbody>
      
        </table>
      </div>
    </div>
  </div>
  )
}

export default CustomerCreditReportSingleView2