import axios from "axios";
import store from "../Redux/store";
import { update_tax_slice } from "../Redux/Tax/taxSlice";
import { HEADERS } from "./UrlAndPaths";

export const getTaxAPICall = async (body) => {
  await axios.post("readymade/gettaxedvalue", body, HEADERS).then((res)=>{
    if(res.status===200)
    {
        store.dispatch(update_tax_slice({tax:res.data}))
    }
  }).catch((err)=>{
    console.error(err.response);
    store.dispatch(update_tax_slice({tax:undefined}))
  });
};
