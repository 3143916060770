import { createSlice } from "@reduxjs/toolkit";

export const customerMeasurementSlice=createSlice({
    name:"customerMeasurementSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        update_customer_measurements:(state,action)=>{
            state.value=action.payload.customerMeasurements
        }
    }
})

export const {update_customer_measurements}=customerMeasurementSlice.actions
export default customerMeasurementSlice.reducer