import {
  Autocomplete,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import "../../../css/Staff/RewardsAndPoints/addRewards.css";

import DummyImage from "../../../Assets/Images/no-image.png";

import {
  convertPointToAmountAPICall,
  submitRewardAndPointsAPICall,
} from "../../../API/RewardsAndPoints/RewardsAndPointsAPI";
import SuccessSnackbar from "../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../Single Components/SnackBars/ErrorSnackbar";
import { categoryListAPICall } from "../Inventory/Configuration/Category/CategoryAPI";
import { newProdListForBarcode } from "../GenerateQrOrBarcode/GenearteQrBarcodeAPI";
import { companyProfileInfoForUser } from "../../../API/Settings/Company Settings/companySettingsAPI";
import { filterObjFromList } from "../../../Js/generalFunctions";
import { viewAllCurrencyUpdated } from "../../../API/Settings/Currency/CurrencyAPI";
export const AddRewards = () => {
  //*Redux States
  // const allProductsList = useSelector(
  //   (state) => state.allProductsListSlice.value
  // );
  const CategoryList = useSelector((state) => state.categorySlice.value);
  const allProductsList = useSelector(
    (state) => state.GenerateQrBarcodeSlice.apiResList
  );
  const companyProfileData =useSelector(
    (state) => state?.companyProfileSlice?.userValue)
  const storeCode=localStorage.getItem('branchId')
  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );


  const [activeTab, setActiveTab] = useState("product");
  const [selected, setSelected] = useState("one");
  const [selectedAmount, setSelectedAmount] = useState("one");
  const [selectedDepartment, setSelectedDepartment] = useState("one");
  const [rewardImage, setRewardImage] = useState(null);
  //selected Product Type
  const [prodType, setProdType] = useState(2);
  //Selected Product Object
  const [selectedProdObj, setSelectedProdObj] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  //Point amount form initial State for both product and category
  const pointAmountFormInitialState = {
    point: 0,
    amount: 0.0,
  };
  //Product Point amount form
  const [productPointAmountForm, setProductPointAmountForm] = useState(
    pointAmountFormInitialState
  );
  //Category point amount form
  const [categoryPointAmountForm, setCategoryPointAmountForm] = useState(
    pointAmountFormInitialState
  );
  //Convert POint to amount api response
  const [pointToAmountRes, setPointToAmountRes] = useState(null);
  const [companyInfoData, setCompanyInfoData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [snackMsg, setSnackMsg] = useState("ERR_CODE999!");

  const handleChange = (event) => {
    setSelected(event.target.value);
  };
  const handleChangeDepartment = (event) => {
    setSelectedDepartment(event.target.value);
  };
  const handleChangeAmount = (event) => {
    setSelectedAmount(event.target.value);
  };

  //Close Snackbars
  const closeSuccessSnackbar=()=>{
    setOpenSuccessSnackbar(false)
  }
  const closeErrorSnackbar=()=>{
    setOpenErrorSnackbar(false)
  }

  const productMenuClick = () => {
    document.getElementById("productMenuBtn").classList.add("active");
    document.getElementById("departmentMenuBtn").classList.remove("active");
    setActiveTab("product");
  };
  const departmentMenuClick = () => {
    document.getElementById("departmentMenuBtn").classList.add("active");
    document.getElementById("productMenuBtn").classList.remove("active");
    setActiveTab("department");
  };

  //Convert Points to Amount from front end by response data
  const calculatePointsByAmount = (point = 0, ratio = 0) => {
    let amount = 0;
    amount = Number(point) * Number(ratio);
    console.log(amount);
    return amount;
  };
  //Set Converted amount and value to state
  const setConvertedAmountAndValueToState = (rType, value, convertedAmount) => {
    if (rType === "product")
      setProductPointAmountForm({
        ...productPointAmountForm,
        point: value,
        amount: convertedAmount,
      });

    if (rType === "category")
      setCategoryPointAmountForm({
        ...categoryPointAmountForm,
        point: value,
        amount: convertedAmount,
      });
  };
  //Onchange functions


  //Get Selected Product
  const getSelectedProduct = (e, newValue) => {
    setSelectedProdObj(newValue);
    console.log(newValue);
  };
  //Get Point entered and some logic
  const getPointsEntered = (rType) => (e) => {
    const { value } = e.target;
    let convertedAmount = 0;
    let res = convertPointToAmountAPICall();
    res
      .then((res) => {
        setPointToAmountRes(res.data);
        convertedAmount = calculatePointsByAmount(value, res.data?.ratio);
        setConvertedAmountAndValueToState(rType, value, convertedAmount);
      })
      .catch((err) => {
        setPointToAmountRes(null);
        convertedAmount = calculatePointsByAmount(value, 0);
        setConvertedAmountAndValueToState(rType, value, convertedAmount);
        console.error(err.response);
      });

  };

  // console.log(productPointAmountForm);
  //Clear States
  const clearStates = () => {
    
    setProductPointAmountForm(pointAmountFormInitialState)
    setCategoryPointAmountForm(pointAmountFormInitialState)
    setSelectedProdObj(null)
    
  };
  //Final Submit body
  const finalSubmitBody = {
    productName:activeTab === "department"
    ? selectedCategory?.categoryName
    : activeTab === "product"
    ? selectedProdObj?.productName
    : null,
    productId:
      activeTab === "department"
        ? selectedCategory?._id
        : activeTab === "product"
        ? selectedProdObj?._id
        : null,
    point:
      activeTab === "department"
        ? Number(categoryPointAmountForm.point)
        : activeTab === "product"
        ? Number(productPointAmountForm.point)
        : null,
    amount:
      activeTab === "department"
        ? categoryPointAmountForm.amount
        : activeTab === "product"
        ? productPointAmountForm.amount
        : null,
    isCategory:
      activeTab === "department"
        ? true
        : activeTab === "product"
        ? false
        : null,
  };
  //Final Submit Button Fn
  const clickFinalSubmitBtn = () => {
    if(activeTab==='product')
    {
      if(selectedProdObj===null)
    {
      alert('Select a product!!')
    }
    else if(productPointAmountForm.point===0 || productPointAmountForm.point==='')
    {
      alert('Enter Valid Points!!')
    }
    else
    {
      submitRewardAndPointsAPICall(
        finalSubmitBody,
        setOpenSuccessSnackbar,
        setOpenErrorSnackbar,
        setSnackMsg,
        clearStates
      );
    }
    
    }
    if(activeTab==='department')
    {
      if(selectedCategory===null)
    {
      alert('Select a category!!')
    }
    else if(categoryPointAmountForm.point===0 || categoryPointAmountForm.point==='')
    {
      alert('Enter Valid Points!!')
    }
    else
    {
      submitRewardAndPointsAPICall(
        finalSubmitBody,
        setOpenSuccessSnackbar,
        setOpenErrorSnackbar,
        setSnackMsg,
        clearStates
      );
    }
  }
    
  };

  //useEffects
  useEffect(() => {
    companyProfileInfoForUser({})
    newProdListForBarcode({})
    categoryListAPICall();
    viewAllCurrencyUpdated({ status: 0 });

  }, []);

  useEffect(()=>{
    if(companyProfileData){
      
      setCompanyInfoData(filterObjFromList("_id",currencyListUpdated,"currency",companyProfileData))
    }
  },[companyProfileData,currencyListUpdated])

  return (
    <>
      <div className="global-page-parent-container">
        <p className="breadcrumb"> Rewards & Points &gt; Add Rewards</p>
        <div className="rewards-add-rewards-ctn">
          <div className="rewards-add-rewards-ctn-top-menu">
            <div
              className="tab-menu-button active"
              id="productMenuBtn"
              onClick={productMenuClick}
            >
              <Typography fontSize={"0.9rem"} fontWeight={600}>
                PRODUCT
              </Typography>
            </div>
            <div
              className="tab-menu-button"
              id="departmentMenuBtn"
              onClick={departmentMenuClick}
            >
              <Typography fontSize={"0.9rem"} fontWeight={600}>
                CATEGORY
              </Typography>
            </div>
          </div>
          <div>
            {activeTab === "product" ? (
              <div className="rewards-add-rewards-ctn-body">
                <h4 style={{margin:"0"}}>Add Rewards</h4>
                <hr className="global-hr" />
                <div className="rewards-add-rewards-ctn-body-section">
                  <div className="rewards-add-rewards-ctn-body-section-ctn">
                  
                    
                    <div className="global-single-input add-reward-product-input auto-complete">
                      <p>Product Name</p>
                      <Autocomplete
                        sx={{ width: "100%" }}
                        options={allProductsList || []}
                        getOptionLabel={(option) => `${option?.productName}`}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select product.."
                          />
                        )}
                        value={selectedProdObj}
                        onChange={getSelectedProduct}
                      />

                    </div>
                    <div className="global-single-input add-employee-input">
                      <p>Image Preview</p>
                      <img
                        src={
                          (selectedProdObj?.imageUrl.length !== 0 &&
                            selectedProdObj?.imageUrl[0]) ||
                          DummyImage
                        }
                        alt=""
                      />
             
                    </div>
                  </div>
                  <div className="rewards-add-rewards-ctn-body-section-ctn">
                    <div className="global-single-input">
                      <p>Product ID</p>
                      <input
                        type="text"
                        placeholder="S12D23"
                        value={selectedProdObj?.productId || ""}
                        readOnly
                      />
                    </div>

                    <div className="rewards-add-rewards-ctn-body-section-right">
                      <div className="global-single-input">
                        <p>Point</p>
                        <input
                          type="text"
                          value={productPointAmountForm.point}
                          onChange={getPointsEntered("product")}
                        />
                      </div>
                      <div className="global-single-input">
                        <p>Amount</p>
                        <div className="add-reward-amount-input">
                          <input
                          style={{borderRadius: "0"}}
                            type="text"
                            readOnly
                            value={productPointAmountForm.amount}
                          />
                          <input
                            style={{ borderRadius: "0", textAlign: "end" }}
                            type="text"
                            value={companyInfoData?.symbol || ""}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="rewards-add-rewards-ctn-body-button"
                  style={{ alignSelf: "center" }}
                >
                  <button className="btn btn-secondary-outlined" onClick={clearStates}>Cancel</button>
                  <button
                    className="btn btn-primary"
                    onClick={clickFinalSubmitBtn}
                  >
                    Submit
                  </button>
                </div>
              </div>
            ) : activeTab === "department" ? (
              <div className="rewards-add-rewards-ctn-body">
                <h4>Add Rewards</h4>
                <hr className="global-hr" />
                <div className="rewards-add-rewards-ctn-body-section" style={{flexDirection:"column"}}>
                  <div className="rewards-add-rewards-ctn-body-section-ctn">
                        <div className="global-single-input auto-complete"
                          // style={{ width: "46%" }}
                        >
                          <p>Main Category</p>
                          <Autocomplete
                            sx={{ width: "100%" }}
                            options={CategoryList || []}
                            getOptionLabel={(option) => option?.categoryName}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select Category.."
                              />
                            )}
                            value={selectedCategory}
                            onChange={(e, newValue) => { setSelectedCategory(newValue) }}
                          />
                        </div>
                    
                    
                  </div>
                  <div className="rewards-add-rewards-ctn-body-section-ctn" style={{flexDirection:"row",margin:"2% 0"}}>
                  <div className="global-single-input">
                      <p>Point</p>
                      <input
                        type="text"
                        value={categoryPointAmountForm.point}
                        onChange={getPointsEntered("category")}
                      />
                    </div>
                      <div className="global-single-input">
                      <p>Amount</p>
                      <div className="add-reward-amount-input">
                        <input
                        style={{borderRadius:"0"}}
                          type="text"
                          value={categoryPointAmountForm.amount}
                          readOnly
                        />
                        <input
                        style={{borderRadius:"0",textAlign:"end"}}
                          type="text"
                          value={companyInfoData?.symbol || ""}
                          readOnly
                        />
                        {/* <Select
                          value={selectedAmount}
                          onChange={handleChangeAmount}
                          style={{ width: "55%", fontSize: "0.8rem" }}
                        >
                          <MenuItem value="one">Rs</MenuItem>
                          <MenuItem value="two">$</MenuItem>
                        </Select> */}
                      </div>
                    </div>
                    
                  </div>
                </div>
                <div
                  className="rewards-add-rewards-ctn-body-button"
                  style={{ alignSelf: "center" }}
                >
                  <button className="btn btn-secondary-outlined" onClick={clearStates}>Cancel</button>
                  <button
                    className="btn btn-primary"
                    onClick={clickFinalSubmitBtn}
                  >
                    Submit
                  </button>
                </div>
              </div>
            ) : undefined}
          </div>
        </div>
      </div>


      <SuccessSnackbar open={openSuccessSnackbar} handleClose={closeSuccessSnackbar} message={snackMsg}/>
      <ErrorSnackbar open={openErrorSnackbar} handleClose={closeErrorSnackbar} message={snackMsg}/>
    </>
  );
};
