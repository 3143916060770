import { createSlice } from "@reduxjs/toolkit";

export const payHead=createSlice({
    name:'payHead',
    initialState:{
        payHeadListView:undefined,
        payHeadViewById:undefined,
        generatePayHead:undefined,
    },
    reducers:{
        view_pay_head_list:(state,action)=>{
            state.payHeadListView=action.payload.payHeadListViewData
        },
        view_payHead_by_id:(state,action)=>{
            state.payHeadViewById=action.payload.payHeadViewDataById
        },
        edit_establishment_id:(state,action)=>{
            state.editEstablishmentId=action.payload.updateEstablishmentId
        },
        generate_payHead:(state,action)=>{
            state.generatePayHead=action.payload.addPayHead
        }
    }
})
export const {view_pay_head_list,view_payHead_by_id,edit_establishment_id,generate_payHead}= payHead.actions;
export default payHead.reducer;