import React, { useRef } from "react";
import InputBox from "../../../components/InputBox";
import ReactToPrint from "react-to-print";
import { IconButton, Skeleton } from "@mui/material";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import DropdownInputBox from "../../../components/DropdownInputBox";

//rest data
const renderData = [
  {
    date: "10/06/23",
    type: "issued",
    particular: "samsung",
    instrumentNo: "123456",
    instrumentDate: "10/06/23",
    status: "pending",
    amount: "10000",
  },
  {
    date: "29/08/23",
    type: "issued",
    particular: "samsung",
    instrumentNo: "123456",
    instrumentDate: "10/06/23",
    status: "pending",
    amount: "10000",
  },
];

const PostdatedChequeSummary = ({ selectListItem }) => {
  const componentRef = useRef();
  const isLoading = false;
  return (
    <div
      id="currentAsset-report"
      className="post-dated-cheque-summary attendance-report pay-in-slip  stock-item-monthly-summary  "
    >
      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="accounting-voucher-menu-sections"
      >
        <h3 style={{ margin: "2% 1%" }}>{selectListItem}</h3>
        <div className="input-current-asset-reports">
          <DropdownInputBox
            labelName={"Bank Name"}
            placeholder={"Select Option"}
          />
          <InputBox label={"Date"} type={"date"} />
          <div className="submit-btn-container">
            <button className="ledger-input-container-btn">Submit</button>
          </div>
        </div>
      </div>
      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="accounting-voucher-menu-sections"
      >
        <div className="action-btn-container">
          <div>
            <ReactToPrint
              trigger={() => (
                <IconButton sx={{ color: "blue" }}>
                  <i className="bi bi-printer "></i>
                </IconButton>
              )}
              content={() => componentRef.current}
            />
            <IconButton className="download-btn">
              <ArrowCircleDownIcon sx={{ fontSize: 30 }} />{" "}
            </IconButton>
          </div>
        </div>
        <div className="table-container">
          <div className="global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Type</th>
                  <th>Particular</th>
                  <th>Instrument No</th>
                  <th>Instrument Date</th>
                  <th>Status</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  [...Array(3)].map((_, index) => (
                    <tr key={index}>
                      <td colSpan={7}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                  ))
                ) : renderData.length !== 0 ? (
                  renderData?.reverse()?.map((r, i) => (
                    <React.Fragment key={i}>
                      <tr className="data-table-row-head">
                        <td>{r?.date}</td>
                        <td>{r?.type}</td>
                        <td>{r?.particular}</td>
                        <td>{r?.instrumentNo}</td>
                        <td>{r?.instrumentDate}</td>
                        <td>{r?.status}</td>
                        <td>{r?.amount}</td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>No Records</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* total container  */}
      <div className="total-amount-wrapper">
        <h4 className="total-head">Total</h4>
        <input disabled className="total-input" type="number" />
      </div>

      <div style={{ display: "none" }}>
        <div
          ref={componentRef}
          id="currentAsset-report"
          className="print-preview-container"
        >
          <div className="print-head-container">
            <div className="brand-name">
              <h2>Leeyet Textile</h2>
            </div>
            <div className="select-table-head">
              <h3>{selectListItem}</h3>
            </div>
            <div className="duration-date">
              <h3>{"01/04/2023"}</h3>
              <strong>TO</strong>
              <h3>{"01/12/2023"}</h3>
            </div>
          </div>
          <div className="global-table-wrapper">
            <div className="global-table-container">
              <table className="global-table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Particular</th>
                    <th>Instrument No</th>
                    <th>Instrument Date</th>
                    <th>Status</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    [...Array(3)].map((_, index) => (
                      <tr key={index}>
                        <td colSpan={7}>
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={40}
                          />
                        </td>
                      </tr>
                    ))
                  ) : renderData.length !== 0 ? (
                    renderData?.reverse()?.map((r, i) => (
                      <React.Fragment key={i}>
                        <tr className="data-table-row-head">
                          <td>{r?.date}</td>
                          <td>{r?.type}</td>
                          <td>{r?.particular}</td>
                          <td>{r?.instrumentNo}</td>
                          <td>{r?.instrumentDate}</td>
                          <td>{r?.status}</td>
                          <td>{r?.amount}</td>
                        </tr>
                      </React.Fragment>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7}>No Records</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="total-amount-wrapper">
              <h4 className="total-head">Total</h4>
              <input disabled className="total-input" type="number" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostdatedChequeSummary;
