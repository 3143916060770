import React, { useState } from "react";
import "../../../../css/Orders/workOrderAdmin.css";
import { Select, MenuItem, Dialog, IconButton } from "@mui/material";
import { AddTailorPopup } from "../../../Single Components/AddTailorPopup";
import { ThobWO } from "../WorkOrder/ThobWO";
import { JalabiaWO } from "../WorkOrder/JalabiaWO";
import { SalwarWO } from "../WorkOrder/SalwarWO";
import { ChuridharWO } from "../WorkOrder/ChuridharWO";
import { ShirtWO } from "../WorkOrder/ShirtWO";
import { PantWO } from "../WorkOrder/PantWO";
import { CoatWO } from "../WorkOrder/CoatWO";
import { SharvaniWO } from "../WorkOrder/SharvaniWO";
import { SafariWO } from "../WorkOrder/SafariWO";
import { AbayaWO } from "../WorkOrder/AbayaWO";
import { today } from "../../../../Js/Date";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { viewAllBranchesAPICall } from "../../../../API/Settings/Company Settings/companySettingsAPI";
import {
  addModelsToOutletsAPICall,
  createRackAPICall,
  getModelsFromOutletAPICall,
  viewRackListAPICall,
} from "../../../../API/Orders/workOrderAPI";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";

export const WorkOrderAdmin = () => {
  //* Redux State
  //List of all branches
  const companyBranchList = useSelector(
    (state) => state.allBranchesSlice.value
  );
  //Rack List
  const rackList = useSelector((state) => state.rackListSlice.value);
  //Models Added by ADMIN
  const modelsForOutlet = useSelector(
    (state) => state.modelsFromUserSideSlice.value
  );


  const [addCategoryPopup, setAddCategoryPopup] = useState(false);
  const [openRackDialog, setOpenRackDialog] = useState(false);
  const [addRackAlert, setAddRackAlert] = useState(false);
  const [openAddRackPopup, setOpenAddRackPopup] = useState(false);
  const [addLocation, setAddLocation] = useState(false);
  const [selectProduct, setSelectProduct] = useState("0");
  const [isOutcloth,setIsOutCloth]=useState(false)

  //Update Listener States
  const [isModify, setIsModify] = useState(false);
  //Snackbar states
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false);
  const [openErrorSnack, setOpenErrorSnack] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!");
  //List of all branches
  const [branchList, setBranchList] = useState([]);
  //Rack list front end state
  const [rackListInitial,setRackListInitial]=useState([])
  //Admin Cloth categories
  const categoriesInitialState = [
    { name: "Thob", isSelected: false },
    { name: "Jalabia", isSelected: false },
    { name: "Salwar", isSelected: false },
    { name: "Abaya", isSelected: false },
    { name: "Churidhar", isSelected: false },
    { name: "Shirt", isSelected: false },
    { name: "Pant", isSelected: false },
    { name: "Coat", isSelected: false },
    { name: "Sharvani", isSelected: false },
    { name: "Safari", isSelected: false },
  ];
  const [categories, setCategories] = useState(categoriesInitialState);
  //Selected Categories
  const [selectedCategories, setSelectedCategories] = useState([]);
  //Selected Branches
  const [selectedBranches, setSelectedBranches] = useState([]);
  //Selected Racks only
  const [selectedRacks,setSelectedRacks]=useState([])
  //Checked Radio [qr or bar code]
  const [checkedRadio, setCheckedRadio] = useState("qrCode");
  //selected categories for backend
  const [selectedCategoriesForBackend, setSelectedCategoriesForBackend] =
    useState([]);
  const [selectedBranchesForBackend, setSelectedBranchesForBackend] = useState(
    []
  );
  //Selected Racks for backend
  const [selectedRacksForBackend,setSelectedRacksForBackend]=useState([])
  //Call back to determine api call is success or not
  const [isApiSuccess, setApiSuccess] = useState(false);
  //Rack Description state
  const [rackDescription, setRackDescription] = useState("");

  // validation states
  const [categoriesAlert,setCategoriesAlert]=useState(false)
  const [racksAlert,setRacksAlert]=useState(false)
  const [branchAlert,setBranchAlert]=useState(false)

  // radio branch
  const [branchRadio,setBranchRadio]=useState()

  // models for outlet response map state
  const [oldModels,setOldModels]=useState([])

  //Close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setOpenSuccessSnack(false);
  };
  //Close ErrorSnackbar
  const closeErrorSnackbar = () => {
    setOpenErrorSnack(false);
  };
  //Close Add Rack Dialog
  const closeRackDialog = () => {
    setOpenRackDialog(false);
  };
  //Open Add Rack Dialog
  const openAddRackDialog = () => {
    setOpenRackDialog(true);
  };
  //Update Listener
  const updateListener = () => {
    setIsModify(!isModify);
  };

  //* Clear States
  // const clearAllStates=()=>{
  //   setSelectedCategories([])
  //   setSelectedBranches([])
  //   setSelectedRacks([])
  //   updateListener()
  //   setSelectedCategoriesForBackend([])
  //   setSelectedBranchesForBackend([])
  //   setSelectedRacksForBackend([])
  // }





  //*useEffects
  useEffect(() => {
    viewAllBranchesAPICall();
    viewRackListAPICall();
  }, [isModify]);

  //Set Branch list into front end array to remove non extensibility
  useEffect(() => {
    if (companyBranchList !== undefined) {
      let newArray = companyBranchList?.map((obj, i) => {
        return {
          ...obj,
          isSelected: false,
        };
      });
      setBranchList(newArray);
    }
  }, [companyBranchList]);

  //Set Rack List into front end Array
  useEffect(() => {
    if (rackList !== undefined) {
      let newArray = rackList?.map((obj, i) => {
        return {
          ...obj,
          isSelected: false,
        };
      });
      setRackListInitial(newArray);
    }
  }, [rackList]);






  //Crete selected Categories for backend
  useEffect(() => {
    createSelectedCategoriesForBackend(selectedCategories);
  }, [selectedCategories]);
  //Crete selected Branches for backend
  useEffect(() => {
    createSelectedBranchesForBackend(selectedBranches);
  }, [selectedBranches]);
  //Create selected racks for backend
  useEffect(()=>{
    createSelectedRacksForBackend(selectedRacks)
  },[selectedRacks])



  
  //*onChange Fns
  //Select a Category
  const selectCategoryChange = (i) => (e) => {
    const { checked } = e.target;
    // console.log(checked);
    if (checked) {
      categories[i].isSelected = true;
      setCategories([...categories]);
    } else {
      categories[i].isSelected = false;
      setCategories([...categories]);
    }
  };

  //Select Rack
  const selectRackChange=(i)=>(e)=>{
    const {checked}=e.target
    if(checked)
    {
      
      rackListInitial[rackListInitial?.length-(i+1)].isSelected=true
      setRackListInitial([...rackListInitial])
    }
    else
    {
      rackListInitial[rackListInitial?.length-(i+1)].isSelected=false
      setRackListInitial([...rackListInitial])
    }
  }

  //Select Location
  const selectLocationChange = (e) => {
    const { checked,value } = e.target;
    if (checked) {
      setBranchRadio(value)
    }
    
    // if (checked) {
    //   branchList[i].isSelected = true;
    //   setBranchList([...branchList]);
    // } else {
    //   branchList[i].isSelected = false;
    //   setBranchList([...branchList]);
    // }
  };
 

  //Select qr or barcode
  const selectQrOrBarCode = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setCheckedRadio(value);
    }
  };
  //Get Rack Description
  const getRackDescription = (e) => {
    setRackDescription(e.target.value);
    setAddRackAlert(false)
    if(e.target.value===""){
      setAddRackAlert(true)
    }
  };

  //*onClick fn
  //Select Category Submit Btn Fn
  const clickSelectCategorySubmit = () => {
    let filteredArray = categories.filter((obj) => obj?.isSelected === true);
    setSelectedCategories(filteredArray);

    setAddCategoryPopup(false);
  };

  //Select Location Submit Fn
  const clickSelectLocationSubmit = () => {
    // let filteredArray = branchList.filter((obj) => obj?.isSelected === true);
    // setSelectedBranches(filteredArray);
    setAddLocation(false);
  };

  //Select Racks Submit Fn
  const selectedRacksSubmit=()=>{
    let filteredArray=rackListInitial.filter((obj)=>obj?.isSelected===true)
    setSelectedRacks(filteredArray)
    setOpenAddRackPopup(false)
  }

  // console.log(selectedRacks);

  //create selected categories for backend data
  const createSelectedCategoriesForBackend = (selectedCategories) => {
    let categoriesBackend = selectedCategories?.map((obj) => obj?.name);
    setSelectedCategoriesForBackend(categoriesBackend);
  };
  //create selected Branches for backend data

    const createSelectedBranchesForBackend = (selectedBranches) => {
     let branchesBackend = selectedBranches?.map((obj) => obj?._id);
      setSelectedBranchesForBackend(branchesBackend);
  };

  //Create Selected Racks for backend data
  const createSelectedRacksForBackend=(selectedRacks)=>{
    let racksBackend=selectedRacks?.map((obj)=>obj?.description)
    setSelectedRacksForBackend(racksBackend)
  }
  // console.log(selectedRacksForBackend);



  //Add Models to branches Body
  const addModelsToBranchesBody = {
    categories: {
      model: selectedCategoriesForBackend,
      isQrcode: checkedRadio === "qrCode" ? true : false,
    },
      // location: selectedBranchesForBackend,
     location:[branchRadio],
    rack:selectedRacksForBackend,
    outClothOrder:isOutcloth
  };
  // console.log(isOutcloth);
  //Submit Models to Branches
  const submitModelsToBranches = () => {
    if(branchRadio === undefined){
      setBranchAlert(true)
    }
     else if (selectedCategories?.length == 0) {
      setCategoriesAlert(true)
    } else if (selectedRacksForBackend?.length == 0) {
      setRacksAlert(true)
    } 
    // else if (selectedBranches?.length == 0) {
    //   setBranchAlert(true)
    // } 
    else {
      addModelsToOutletsAPICall(
        addModelsToBranchesBody,
        setApiSuccess,
        setOpenSuccessSnack,
        setOpenErrorSnack,
        setSnackMsg,
        // clearAllStates
      );
    }
  };

  //Clear Add New Rack State
  const clearAddRack = () => {
    setRackDescription("");
  };

  //Create New rack
  const createNewRack = () => {
    if (rackDescription !== "") {
      createRackAPICall(
        { description: rackDescription },
        setOpenSuccessSnack,
        setOpenErrorSnack,
        setSnackMsg,
        updateListener,
        clearAddRack
      );
    }
    else
    {
      setAddRackAlert(true)
    }
  };

  /// new changes 27-04-2023
  useEffect(()=>{
    getModelsFromOutletAPICall({branchId:branchRadio});
  },[branchRadio])




useEffect(()=>{
  const combinedArray = categories.map(category => {
    return {
      name: category.name,
      isSelected: modelsForOutlet?.model?.includes(category.name)
    };
  });
  console.log(combinedArray);
  setCategories(combinedArray)
},[modelsForOutlet])

// console.log(modelsForOutlet?.model);





  return (
    <div className="global-page-parent-container work-order-main-container">
      <p className="breadcrumb">Orders &gt; Work Order</p>
      <div className="work-order-admin-top-container">
        <div className="work-order-admin-top-input-container">
          <div className="global-single-input disabled-input-container">
            <p>Order NO</p>
            <input type="text" disabled />
          </div>
          <div className="global-single-input disabled-input-container">
            <p>Customer</p>
            <Select disabled></Select>
          </div>
          <div className="global-single-input disabled-input-container">
            <p>Contact </p>
            <input disabled />
          </div>
          <div className="global-single-input disabled-input-container">
            <p>Salesman </p>
            <input disabled />
            <p className="suffix-text-blue">Add Salesman</p>
          </div>
          <div className="global-single-input disabled-input-container">
            <p>Order Date</p>
            <input type="date" value={today} disabled />
          </div>
          <div className="global-single-input disabled-input-container">
            <p>Delivery Date</p>
            <input type="date" disabled />
          </div>
        </div>
        <div className="work-order-admin-top-input-container">

           {/* location selection */}
           <div className="work-order-admin-top-input-btn">
            <input
              placeholder="Add Location"
              onClick={() => {setAddLocation(true)
                              setBranchAlert(false)
                              }}
              className="admin-work-order-click-input"
            />
             {branchAlert?
               <p className="doc-validation-alert" style={{top:"-70%",fontWeight:"600"}}>Select a branch!!</p>
            :""}
          </div>

          {/* category selection */}
          <div className="work-order-admin-top-input-btn">
            <input
              placeholder="Add Category"
              onClick={() => {
                setAddCategoryPopup(true)
                setCategoriesAlert(false)
              }}
              className="admin-work-order-click-input"
            />
            {categoriesAlert?
              <p className="doc-validation-alert" style={{top:"-70%",fontWeight:"600"}}>Select Category!!</p>
            :""}
          </div>

          {/* rack selection */}
          <div className="work-order-admin-top-input-btn rack-input-container">
            <input
              placeholder="Add Rack"
              className="admin-work-order-click-input"
              onClick={() => {setOpenAddRackPopup(true)
                              setRacksAlert(false)
                              }}
            />
            {racksAlert?
              <div className="doc-validation-alert work-order-validation">Select a Rack!!</div>
            :""}
            <p onClick={openAddRackDialog}>Create Rack</p>
          </div>

         

          {/* qr code and barcode radio */}
          <div className="work-order-admin-radio-btn-container">
            <div className="global-radio-button work-order-admin-radio-btn">
              <input
                defaultChecked
                type="radio"
                id="workOrderQr"
                value="qrCode"
                name="qrBarCode"
                onChange={selectQrOrBarCode}
              />
              <label htmlFor="workOrderQr">QR Code</label>
              <input
                type="radio"
                id="workOrderBarcode"
                value="barCode"
                name="qrBarCode"
                onChange={selectQrOrBarCode}
              />
              <label htmlFor="workOrderBarcode">Barcode</label>
            </div>
          </div>
          <div className="work-order-admin-radio-btn-container">
            <input 
               type="checkbox" 
               id="workOrderOutCloth" 
               style={{cursor:"pointer",accentColor:"#000"}}
               checked={isOutcloth}
               onChange={(e)=>setIsOutCloth(e.target.checked)}
                />

            <label htmlFor="workOrderOutCloth" style={{fontSize:"0.8rem",fontWeight:500,marginLeft:"4px",cursor:"pointer"}}>Out Cloth Order Seperately</label>

          </div>
        </div>
        <div className="work-order-admin-top-btn-container">
          <button className="btn btn-secondary-outlined" 
          // onClick={clearAllStates}
          >Clear</button>
          <button className="btn btn-primary" onClick={submitModelsToBranches}>
            Submit
          </button>
        </div>
      </div>

      {isApiSuccess && (
        <>
          <div className="work-order-admin-bottom-first-container">
            <h3>Product Details</h3>
            <div className="global-single-input work-order-admin-select">
              <Select
                value={selectProduct}
                onChange={(e) => setSelectProduct(e.target.value)}
              >
                <MenuItem value="0">-select model-</MenuItem>
                {selectedCategoriesForBackend?.map((item, i) => (
                  <MenuItem value={item?.toLowerCase()} key={i}>
                    {item}
                  </MenuItem>
                ))}
                {/* <MenuItem value="thob">Thob</MenuItem>
              <MenuItem value="jalabia">Jalabia</MenuItem>
              <MenuItem value="salwar">Salwar</MenuItem>
              <MenuItem value="abaya">Abaya</MenuItem>
              <MenuItem value="churidhar">Churidhar</MenuItem>
              <MenuItem value="shirt">Shirt</MenuItem>
              <MenuItem value="pant">Pant</MenuItem>
              <MenuItem value="coat">Coat</MenuItem>
              <MenuItem value="sharvani">Sharvani</MenuItem>
              <MenuItem value="safari">Safari</MenuItem> */}
              </Select>
            </div>
          </div>
          {selectProduct === "thob" ? (
            <ThobWO />
          ) : selectProduct === "jalabia" ? (
            <JalabiaWO />
          ) : selectProduct === "salwar" ? (
            <SalwarWO />
          ) : selectProduct === "abaya" ? (
            <AbayaWO />
          ) : selectProduct === "churidhar" ? (
            <ChuridharWO />
          ) : selectProduct === "shirt" ? (
            <ShirtWO />
          ) : selectProduct === "pant" ? (
            <PantWO />
          ) : selectProduct === "coat" ? (
            <CoatWO />
          ) : selectProduct === "sharvani" ? (
            <SharvaniWO />
          ) : selectProduct === "safari" ? (
            <SafariWO />
          ) : undefined}

          {/* <div className="work-order-admin-bottom-cancel-submit-container">
          <button className="btn btn-secondary-outlined">Cancel</button>
          <button className="btn btn-primary">Next</button>
        </div> */}
        </>
      )}

      {/* add category popup */}
      <AddTailorPopup
        open={addCategoryPopup}
        label="Add Category"
        cancelBtn={() => setAddCategoryPopup(false)}
        submitFn={clickSelectCategorySubmit}
      >
        {categories?.map((r, i) => (
          <tr>
            <td>{i + 1}</td>
            <td>{r?.name}</td>
            <td>
              <input
                onClick={selectCategoryChange(i)}
                // value={r?.isSelected}
                checked={r?.isSelected}
                type="checkbox"
              />
            </td>
          </tr>
        ))}
      </AddTailorPopup>

      {/* Add Rack Dialog  */}
      <Dialog open={openRackDialog} maxWidth="md"  onKeyDown={(e)=>e.key === "Escape" && setOpenRackDialog(false)}>
        <div className="add-rack-dialog-parent">
          <h3>Create Rack</h3>
          <div className="add-rack-form-container">
            {/* <div className="global-single-input">
              <p>Code</p>
              <input disabled/>
            </div> */}
            <div className="global-single-input rack-description-input">
              <p>Description</p>
              <input
                placeholder="Rack,Hanger,Shelf etc..."
                value={rackDescription}
                onChange={getRackDescription}
                onKeyDown={(e)=>e.key==='Enter'&& createNewRack()}
              />
              {addRackAlert?
              <p className="doc-validation-alert">Fill This Field !!</p>
              :""
              }
            </div>
            <button
              className="btn btn-secondary add-rack-btn"
              onClick={createNewRack}
            >
              +
            </button>
          </div>
          <div className="rack-table-container global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {rackList
                  ?.slice(0)
                  ?.reverse()
                  ?.map((r, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{r?.description || "-Nil-"}</td>
                      <td>
                        <IconButton>
                          <i class="bi bi-trash3 delete-icon"></i>
                        </IconButton>
                      </td>
                    </tr>
                  )) || (
                  <tr>
                    <td colSpan={3}>No data</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="btn-parent">
            <button
              className="btn btn-secondary rack-close-btn"
              onClick={closeRackDialog}
            >
              Close
            </button>
          </div>
        </div>
      </Dialog>

      {/* Choose Rack popup */}
      <AddTailorPopup
        open={openAddRackPopup}
        label="Add Rack"
        cancelBtn={() => setOpenAddRackPopup(false)}
        submitFn={selectedRacksSubmit}
      >
        {rackListInitial?.slice(0)?.reverse()?.map((r, i) => (
          <tr>
            <td>{i + 1}</td>
            <td>{r?.description || '-NIL-'}</td>
            <td>
              <input
                onClick={selectRackChange(i)}
                checked={r?.isSelected}
                type="checkbox"
              />
            </td>
          </tr>
        ))}
      </AddTailorPopup>

      {/* add location popup */}
      <AddTailorPopup
        open={addLocation}
        label="Add Location"
        cancelBtn={() => setAddLocation(false)}
        submitFn={clickSelectLocationSubmit}
      >
        {branchList?.map((r, i) => (
          <tr>
            <td>{i + 1}</td>
            <td>{`${r?.storeCode}-${r?.branchName}`}</td>
            <td>
              <input
                type="radio"
                name="locationRadio"
                style={{ accentColor: "black", cursor: "pointer" }}
                value={r?._id}
                // checked={branchRadio==r?.isSelected?true:false}
                onClick={selectLocationChange}
               
                
                
              />
            </td>
          </tr>
        ))}
      </AddTailorPopup>

      <SuccessSnackbar
        open={openSuccessSnack}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={openErrorSnack}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </div>
  );
};
