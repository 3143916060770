import { createSlice } from "@reduxjs/toolkit";

export const singleEmployeeLoanSlice=createSlice({
    name:'singleEmployeeLoanSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_single_employee_loan:(state,action)=>{
            state.value=action.payload.singleEmpLoan
        }
    }
})

export const {update_single_employee_loan}=singleEmployeeLoanSlice.actions
export default singleEmployeeLoanSlice.reducer