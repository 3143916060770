import { createSlice } from "@reduxjs/toolkit";

export const deliveryIdSlice=createSlice({
    name:'deliveryIdSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_delivery_id:(state,action)=>{
            state.value=action.payload.deliveryId
        }
    }
})
export const {update_delivery_id} = deliveryIdSlice.actions
export default deliveryIdSlice.reducer