import React, { useRef, useState } from "react";
import InputBox from "../../../components/InputBox";
import ReactToPrint from "react-to-print";
import { Dialog, IconButton, Skeleton } from "@mui/material";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import DropdownInputBox from "../../../components/DropdownInputBox";
import SubmitBtn from "../../../components/SubmitBtn";

//rest data
const renderData = [
  {
    employeeName: "jowher",
    employeeID: "1111",
    department: "Accounts",
    totalEarnings: "0.00",
    totalDeduction: "0.00",
    netSalary: "0.00",
  },
  {
    employeeName: "jowher",
    employeeID: "1111",
    department: "Accounts",
    totalEarnings: "0.00",
    totalDeduction: "0.00",
    netSalary: "0.00",
  },
];
const renderDataOne = [
  {
    userId: "123",
    earnings: [
      {
        payType: "Basic Salary",
        amount: "0.00",
      },
      {
        payType: "Traveling Allowance",
        amount: "545",
      },
      {
        payType: "DA",
        amount: "0.00",
      },
      {
        payType: "HRA",
        amount: "0.00",
      },
      {
        payType: "OTA",
        amount: "0.00",
      },
    ],
    deduction: [
      {
        payType: "Advance Salary",
        amount: "50000",
      },
      {
        payType: "personal loan",
        amount: "2222",
      },

      {
        payType: "Other Expenses",
        amount: "555",
      },
      {
        payType: "Provident Fund",
        amount: "555",
      },
    ],
  },
];
const PaymentReport = ({ selectListItem }) => {
  const componentRef = useRef();
  const isLoading = false;
  const [paymentReportView, setPaymentReportView] = useState(false);
  const numToWords = require("num-words");
  const netSalary = 1565;

  return (
    <div id="currentAsset-report" className="pay-in-slip payment-report">
      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="accounting-voucher-menu-sections"
      >
        <h3 style={{ margin: "2% 1%" }}>{selectListItem}</h3>
        <div className="input-current-asset-reports">
          <DropdownInputBox
            labelName={"Select"}
            placeholder={"Select Option"}
          />
          <InputBox label={"From"} type={"date"} />
          <InputBox label={"To"} type={"date"} />
        </div>
        <SubmitBtn
          containerStyle={{ justifyContent: "flex-end", paddingRight: "2%" }}
        />
      </div>
      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="accounting-voucher-menu-sections"
      >
        <div className="action-btn-container">
          <div>
            <ReactToPrint
              trigger={() => (
                <IconButton sx={{ color: "blue" }}>
                  <i className="bi bi-printer "></i>
                </IconButton>
              )}
              content={() => componentRef.current}
            />
            <IconButton className="download-btn">
              <ArrowCircleDownIcon sx={{ fontSize: 30 }} />
            </IconButton>
          </div>
        </div>
        <div className="table-container">
          <div className="global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>Employee Name</th>
                  <th>Employee ID</th>
                  <th>Department</th>
                  <th>Total Earnings</th>
                  <th>Total Deduction</th>
                  <th>Net Salary</th>
                  <th>view</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  [...Array(3)].map((_, index) => (
                    <tr key={index}>
                      <td colSpan={7}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                  ))
                ) : renderData.length !== 0 ? (
                  renderData?.reverse()?.map((r, i) => (
                    <React.Fragment key={i}>
                      <tr className="data-table-row-head">
                        <td>{r?.employeeName}</td>
                        <td>{r?.employeeID}</td>
                        <td>{r?.department}</td>
                        <td>{r?.totalEarnings}</td>
                        <td>{r?.totalDeduction}</td>
                        <td>{r?.netSalary}</td>
                        <td>
                          <IconButton
                            onClick={() => setPaymentReportView(true)}
                          >
                            <i className="bi bi-eye visibility-icon"></i>
                          </IconButton>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>No Records</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {/* Payment Report view dialog */}
        <Dialog
          open={paymentReportView}
          maxWidth="md"
          PaperProps={{ style: { width: "60vw" } }}
          onKeyDown={(e) => e.key === "Escape" && setPaymentReportView(false)}
        >
          <>
            <div className="payment-report payroll-payslip-container-header">
              <h3>Report View</h3>
            </div>
            <div className="payroll-payslip-container-body">
              <div className="payroll-payslip-container-body-section">
                <div className="payroll-payslip-container-body-top">
                  <div className="payroll-payslip-container-body-top-section">
                    <p>Employee Name: {"david"}</p>
                    <p>Employee ID: {"12454"}</p>
                  </div>
                  <div className="payroll-payslip-container-body-top-section">
                    <p>Department: Accounts</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="earnings-and-deduction attendance-value-table">
              <table id="earnings-and-deduction-table">
                <thead className="earnings-and-deduction-head">
                  <tr id="main-head" className="payment-report">
                    <th colSpan={2}>Earnings</th>
                    <th colSpan={2}>Deduction</th>
                  </tr>
                  <tr id="sub-head">
                    <th>Pay Type</th>
                    <th>Amount</th>
                    <th>Pay Type</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody className="earnings-and-deduction-body">
                  {renderDataOne.map((data, i) => (
                    <React.Fragment key={i}>
                      {data.earnings.map((earning, j) => (
                        <tr key={j}>
                          <td className="earnings">{earning.payType}</td>
                          <td className="earnings">{earning.amount}</td>
                          {data.deduction[j] ? (
                            <>
                              <td className="earnings">
                                {data.deduction[j].payType}
                              </td>
                              <td className="deduction">
                                {data.deduction[j].amount}
                              </td>
                            </>
                          ) : (
                            // If no deduction is available for this earning, leave the cells empty
                            <>
                              <td className="earnings"></td>
                            </>
                          )}
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                  <tr>
                    <td className="earnings table-total">Total Earnings</td>
                    <td className="earnings table-total">Rs21,600.00</td>
                    <td className="earnings table-total">Total Deduction</td>
                    <td className="table-total">Rs1,000.00</td>
                  </tr>
                </tbody>
              </table>
              <div className="payroll-payslip-container-body-total">
                <div className="payroll-payslip-container-body-total-section">
                  <h3>Net Salary</h3>
                  <p>Inwords</p>
                </div>
                <div
                  className="payroll-payslip-container-body-total-section"
                  style={{ width: "75%" }}
                >
                  <h3>{`Rs ${netSalary}`}</h3>
                  <p>{numToWords(netSalary)?.toUpperCase()}</p>
                </div>
              </div>
            </div>

            <div className="staff-payroll-edit-bottom-container">
              <button
                className="btn "
                onClick={() => setPaymentReportView(false)}
              >
                Close
              </button>
            </div>
          </>
        </Dialog>
      </div>
      {/* print */}
      <div style={{ display: "none" }}>
        <div
          ref={componentRef}
          id="currentAsset-report"
          className="print-preview-container"
        >
          <div className="print-head-container">
            <div className="brand-name">
              <h2>Leeyet Textile</h2>
            </div>
            <div className="select-table-head">
              <h3>{selectListItem}</h3>
            </div>
            <div className="duration-date">
              <h3>{"01/04/2023"}</h3>
              <strong>TO</strong>
              <h3>{"01/12/2023"}</h3>
            </div>
          </div>
          <div className="global-table-wrapper">
            <div className="global-table-container">
              <table className="global-table">
                <thead>
                  <tr>
                    <th>Employee Name</th>
                    <th>Employee ID</th>
                    <th>Department</th>
                    <th>Total Earnings</th>
                    <th>Total Deduction</th>
                    <th>Net Salary</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    [...Array(3)].map((_, index) => (
                      <tr key={index}>
                        <td colSpan={7}>
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={40}
                          />
                        </td>
                      </tr>
                    ))
                  ) : renderData.length !== 0 ? (
                    renderData?.reverse()?.map((r, i) => (
                      <React.Fragment key={i}>
                        <tr className="data-table-row-head">
                          <td>{r?.employeeName}</td>
                          <td>{r?.employeeID}</td>
                          <td>{r?.department}</td>
                          <td>{r?.totalEarnings}</td>
                          <td>{r?.totalDeduction}</td>
                          <td>{r?.netSalary}</td>
                        </tr>
                      </React.Fragment>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7}>No Records</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentReport;
