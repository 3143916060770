import { createSlice } from "@reduxjs/toolkit";

export const journalEntrySlice = createSlice({
  name: "journalEntrySlice",
  initialState: {
    journalEntryList: undefined,
    editClickData:undefined,
    generateName:undefined
  },
  reducers: {
    get_journal_entry_list: (state, action) => {
      state.journalEntryList = action.payload.journalEntryData;
    },
    update_journal_entry_list_by_row: (state, action) => {
      state.editClickData = action.payload.journalEntryRowData;
    },
    generate_journal_entry_name: (state, action) => {
      state.generateName = action.payload.journalEntryName;
    }
  },
});

export const { get_journal_entry_list,update_journal_entry_list_by_row,generate_journal_entry_name } = journalEntrySlice.actions;

export default journalEntrySlice.reducer;
