import { createSlice } from "@reduxjs/toolkit";

export const purchaseWPOSlice = createSlice({
  name: "purchaseWPOSlice",
  initialState: {
    apiResList: undefined,
    rowData:undefined,
    generateName:undefined,
    history:undefined
  },
  reducers: {
    get_purchase_w_po_list: (state, action) => {
      state.apiResList = action.payload.purchaseWPOData;
    },
    get_purchase_w_po_row_data_list: (state, action) => {
      state.rowData = action.payload.purchaseWPOSingleData;
    },
    generate_purchase_w_po_name: (state, action) => {
      state.generateName = action.payload.purchaseWPOName;
    },
    get_purchasewopo_history:(state,action)=>{
      state.history=action.payload.historyData
    }
  },
});

export const {
  get_purchase_w_po_list,
  get_purchase_w_po_row_data_list,
  generate_purchase_w_po_name,
  get_purchasewopo_history
} = purchaseWPOSlice.actions;

export default purchaseWPOSlice.reducer;
