import { createSlice } from "@reduxjs/toolkit";

export const materialProductSlice=createSlice({
    name:'materialProductSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_material_product_list:(state,action)=>{
            state.value=action.payload.materialProducts
        }
    }
})

export const {update_material_product_list} = materialProductSlice.actions
export default materialProductSlice.reducer