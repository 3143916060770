import React, { useEffect } from 'react'
import { useNavigate } from "react-router";
import { JournalFilterAPICall, journalListApiCall } from '../../../../API/AccountUpdated/journalAPI';
import { useSelector } from 'react-redux';
import store from '../../../../Redux/store';
import { save_single_journal } from '../../../../Redux/AccountUpdated/journalSlice';
import CategorySearchandFilter from '../../../Single Components/CategorySearchandFilter';
import { useState } from 'react';
import CustomPagination from '../../../Single Components/CustomPagination';
import { LoadingForm } from '../../../Single Components/LoadingForm';
import axios from 'axios';
import { setFilterActive } from '../../../Single Components/listSlice/filterCategorySlice';
export const Journal = () => {
  const journalTypes=[
    { label: "sales", type: "subheading" 
      
    },
    {
        label:"purchase"
    },
    {
        label:"cash"
    },
    {
        label:"bank"
    },
    {
        label:"miscellaneous"
    },
]
  let navigate = useNavigate();
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const journalList = useSelector((state) => state.journalSlice.tableList)
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingForm,setLoadingForm]=useState(false)
  const [selectedBranch, setSelectedBranch] = useState(null);
  const totalPages = journalList?.pages;

  const createJournalFn = () => {
    navigate("/userdashboard/accounts/accounting/journal/createjournal")
  }
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };

  console.log(journalList?.list);

  useEffect(() => {
    // journalListApiCall()
    store.dispatch(save_single_journal({ singleJournalData: undefined }))
  }, [])

  const viewSingleRow = (data) => {
    store.dispatch( setFilterActive(window.location.pathname))
    store.dispatch(save_single_journal({ singleJournalData: data }))

    navigate("/userdashboard/accounts/accounting/journal/createjournal")
  }
  useEffect(() => {
  
    if (FormDataInfo.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData && JournalFilterAPICall({
        branchId: FormDataInfo.branchList,
        type: FormDataInfo.type,
        search: FormDataInfo.search,
        index: currentPage - 1,
      }, setIsLoading);
    }
    
  }, [FormDataInfo, currentPage
  ]);

  return (
    <div className='global-page-parent-container'>
      {/* <p className="breadcrumb">Accounts &gt; Accounting &gt;Journal</p> */}
      {/* table */}
      <div className='global-white-bg-container'>
        <div className="justify-space-between"
          style={{ gap: "0 12px", display: 'flex', justifyContent: 'space-between', alignItems: 'end'}}
        >
            {FormDataInfo?.branchList?.length > 0 && (
              <div style={{ marginLeft: '1%' }}>
               <h3>Branch name: 
                  {selectedBranch?.storeCode||filtereDataOptions?.filterCategorylist?.branchList?.storeCode}
                  -{selectedBranch?.branchName||filtereDataOptions?.filterCategorylist?.branchList?.branchName}</h3>
              </div>
            )} 
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap:'9px',
              width: FormDataInfo?.branchList?.length ? '72%' : '100%'
            }}>
          <CategorySearchandFilter
            statusOptions={['ACTIVE', 'INACTIVE']}
            onData={handleDataFromChild}
            isDate={false}
            isType={true}
            typeList={journalTypes}
            onBranchSelect={handleBranchSelect}

          />
          <button onClick={createJournalFn} className='create-button-blue'>Create</button>
          </div>
        </div>
        <div className='create-button-blue-container' style={{ margin: "0 1%" }}>
          <h3>Journal</h3>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />
        </div>
        {/* <div className="justify-center" style={{ justifyContent: "flex-end" }}>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div> */}
        <div className='create-button-blue-container'>
          {/* <button onClick={createJournalFn} className='create-button-blue'>Create</button> */}
        </div>
        <div className='global-table-container'>
          <table className='global-table'>
            <thead>
              <tr>
                <th>No</th>
                <th>Journal Name</th>
                <th>Type</th>
                <th>Short Code</th>
                <th>Default Account</th>
              </tr>
            </thead>
            <tbody >
              {
                journalList?.list?.slice(0)?.reverse()?.map((r, i) => (
                  <tr onClick={() => viewSingleRow(r)} style={{ cursor: "pointer" }} title="View Details">
                    <td>{i + 1}</td>
                    <td>{r?.journalName}</td>
                    <td>{r?.type}</td>
                    <td>{r?.shortCode}</td>
                    <td>{r?.defaultAccountName}</td>
                    <td></td>
                  </tr>
                ))
              }

            </tbody>
          </table>
        </div>
      </div>
        
    </div>
  )
}
