import axios from "axios";
import { HEADERS } from "../UrlAndPaths";
import store from "../../Redux/store";



//AddChartOfAccountApiCall

export const AddChartOfAccountApiCall = (
  addChartOfAccount,
  setSuccessSnackOpen,
  setErrorSnackOpen,
  setSnackMsg,
  clearState,setLoadingForm
) => {

  setLoadingForm!==undefined && setLoadingForm(true)
  axios
    .post("account/addchartscategory", addChartOfAccount, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Created Successfully !!");
        setLoadingForm!==undefined && setLoadingForm(false)
        setSuccessSnackOpen(true);
        clearState()
      }
    })
    .catch((err) => {

      setLoadingForm!==undefined && setLoadingForm(false)
      if (err.response.status === 500) {
        setSnackMsg("Internal Server Error !!");
      } else {
        setSnackMsg(err?.response?.data)
      }
      setErrorSnackOpen(true);
    });
};

// edit api call
export const editChartOfAccountsAPICall=(
  body,
  isEditTrueFn,
  setSuccessSnackOpen,
  setErrorSnackOpen,
  setSnackMsg,setLoadingForm
  )=>{
    setLoadingForm!==undefined && setLoadingForm(true)
  axios.post("account/editchartofcategory",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      setSnackMsg('Success')
      setSuccessSnackOpen(true)
      isEditTrueFn()
      setLoadingForm!==undefined && setLoadingForm(false)
    }
  })
  .catch((err)=>{
    setLoadingForm!==undefined && setLoadingForm(false)
    switch (err.response.status) {
      case 404:
          setSnackMsg(err.response.data)
        break;
      case 500:
          setSnackMsg('Server error!!')
        break;
      case 401:
          setSnackMsg('Unauthorized!!')
        break;
      case 403:
          setSnackMsg('Forbidden!!')
        break;
      case 409:
          setSnackMsg("Duplicated")
      default:
          setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
        break;
    }
  setErrorSnackOpen(true)
  })
}
