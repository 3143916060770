import {
  Autocomplete,
  Dialog,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "../../../../css/Staff/Payroll/staffAddPayRoll.css";
import { useSelector } from "react-redux";
import { getAllActiveEmployeesAPICall } from "../../../../API/Staff/HRM/employeeAPI";
import Banks from "../../../../Assets/JSON/banknames.json";
import {
  generatePayrollVoucherAPICall,
  staffAddPayrollAPICall,
} from "../../../../API/Staff/HRM/payroll/staffPayrollAPI";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
export const StaffPayrollAddPayroll = () => {
  const allActiveEmpList = useSelector(
    (state) => state.allActiveEmployeeSlice.value
  );

  const voucherNoGenerated = useSelector(
    (state) => state.generatePayrollVoucherSlice.value
  );

  const [addPayrollPayment, setAddPayrollPayment] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [selectedEmployeeName, setSelectedEmployeeName] = useState(null);
  const [selectedAllowance, setSelectedAllowance] = useState(null);
  const [selectedDeduction, setSelectedDeduction] = useState(null);

  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!!");

  const [fillFieldsAlert, setFillFieldsAlert] = useState(false);
  const [employeeAlert, setEmployeeAlert] = useState(false);
  const [amountAlert, setAmountAlert] = useState(false);
  const [bankNameAlert, setBankNameAlert] = useState(false);
  const [accNoAlert, setAccNoAlert] = useState(false);
  const [ifscAlert, setIfscAlert] = useState(false);

  const addPayrollInitialBody = {
    voucherNo: "",
    date: "",
    empId: "",
    branchId: "",
    voucherNo: "",

    basicSalary: "",
    hraAllowance: "",
    daAllowance: "",
    bonus: "",
    travelAllowance: "",
    overTimeHr: "",
    otherAllowance: "",
    educationAllowance: "",

    loan: "",
    emiMonthly: "",
    epf: "",
    esi: "",
    tds: "",
    leave: "",
    otherDeduction: "",
    medicalClaim: "",

    totalEarning: 0,
    totalDeduction: 0,
    paidAmount: "",

    paymentAmt: "",
    paymentMethod: "cheque",
    bankName: "",
    accNo: "",
    ifscCode: "",
  };

  const [addPayrollBody, setAddPayrollBody] = useState(addPayrollInitialBody);
  const allowanceList = [
    "Allowance for transport employee",
    "Bonus",
    "Border/remote area allowance",
    "Children education allowance",
    "Children hostel expenditure allowance",
    "commission(as fixed percentage of turnover",
    "counder insurgency allowance",
    "entertainment allowance",
    "field area allowance",
    "gratuity",
    "high altitude allowance",
    "highly active field area allowance",
    "hill area compensatory allowance",
    "island duty allowance",
    "leave encashment on retirment",
    "leave travel assistance",
    "medical reimbursment",
    "mining /underground allowance",
    "modified field area allowance",
    "other earning/allowances(fully exempt)",
    "other earning /allowance(fully taxable",
    "other earning/allowance(user defined)",
    "tribal or scheduled area allowance",
    "voluntary retirment compensation",
  ];
  const deductionList = [
    "Standard deduction",
    "professional tax(tax on employment)",
    "Life insurance premium",
    "Equity linked Saving Scheme",
    "Health insurance Premiums",
  ];
  const totalPayableSalary =
    addPayrollBody.totalEarning - addPayrollBody.totalDeduction;
  const balanceAmount = totalPayableSalary - addPayrollBody.paidAmount;
  const closeSuccessSnack = () => {
    setSuccessSnackOpen(false);
  };
  const closeErrorSnack = () => {
    setErrorSnackOpen(false);
  };
  const clickPayment = () => {
    if (addPayrollBody?.empId === "") {
      setFillFieldsAlert(true);
      setEmployeeAlert(true);
    } else {
      setAddPayrollBody({ ...addPayrollBody, paymentAmt: totalPayableSalary });
      setAddPayrollPayment(true);
    }
  };
  const addPayrollOnChange = (key) => (e, newValue) => {
    const { value } = e.target;
    if (key === "date") {
      setAddPayrollBody({ ...addPayrollBody, date: value });
    }
    if (key === "empId") {
      setSelectedEmployeeId(newValue);
      setSelectedEmployeeName(newValue);
      setAddPayrollBody({
        ...addPayrollBody,
        empId: newValue?._id,
        branchId: newValue?.branchId,
      });
      setFillFieldsAlert(false);
      setEmployeeAlert(false);
      if (newValue === null) {
        setEmployeeAlert(true);
        setAddPayrollBody({ ...addPayrollBody, empId: "" });
      }
    }
    if (key === "staffName") {
      setSelectedEmployeeId(newValue);
      setSelectedEmployeeName(newValue);
      setAddPayrollBody({
        ...addPayrollBody,
        empId: newValue?._id,
        branchId: newValue?.branchId,
      });
      setFillFieldsAlert(false);
      setEmployeeAlert(false);
      if (newValue === null) {
        setEmployeeAlert(true);
        setAddPayrollBody({ ...addPayrollBody, empId: "" });
      }
    }
    if (key === "basicSalary") {
      setAddPayrollBody({ ...addPayrollBody, basicSalary: value });
      setIsChanged(!isChanged);
    }
    if (key === "hraAllowance") {
      setAddPayrollBody({ ...addPayrollBody, hraAllowance: value });
      setIsChanged(!isChanged);
    }
    if (key === "daAllowance") {
      setAddPayrollBody({ ...addPayrollBody, daAllowance: value });
      setIsChanged(!isChanged);
    }
    if (key === "bonus") {
      setAddPayrollBody({ ...addPayrollBody, bonus: value });
      setIsChanged(!isChanged);
    }
    if (key === "travelAllowance") {
      setAddPayrollBody({ ...addPayrollBody, travelAllowance: value });
      setIsChanged(!isChanged);
    }
    if (key === "educationAllowance") {
      setAddPayrollBody({ ...addPayrollBody, educationAllowance: value });
      setIsChanged(!isChanged);
    }
    if (key === "overTimeHr") {
      setAddPayrollBody({ ...addPayrollBody, overTimeHr: value });
      setIsChanged(!isChanged);
    }
    if (key === "loan") {
      setAddPayrollBody({ ...addPayrollBody, loan: value });
      setIsChanged(!isChanged);
    }
    if (key === "emiMonthly") {
      setAddPayrollBody({ ...addPayrollBody, emiMonthly: value });
      setIsChanged(!isChanged);
    }
    if (key === "epf") {
      setAddPayrollBody({ ...addPayrollBody, epf: value });
      setIsChanged(!isChanged);
    }
    if (key === "esi") {
      setAddPayrollBody({ ...addPayrollBody, esi: value });
      setIsChanged(!isChanged);
    }
    if (key === "tds") {
      setAddPayrollBody({ ...addPayrollBody, tds: value });
      setIsChanged(!isChanged);
    }
    if (key === "paidAmount") {
      setAddPayrollBody({ ...addPayrollBody, paidAmount: value });
      setIsChanged(!isChanged);
    }
    if (key === "leave") {
      setAddPayrollBody({ ...addPayrollBody, leave: value });
      setIsChanged(!isChanged);
    }
    if (key === "medicalClaim") {
      setAddPayrollBody({ ...addPayrollBody, medicalClaim: value });
      setIsChanged(!isChanged);
    }
    if (key === "otherAllowanceSelect") {
      setSelectedAllowance(newValue);
      if (newValue === null) {
        setAddPayrollBody({ ...addPayrollBody, otherAllowance: "" });
        setIsChanged(!isChanged);
      }
    }
    if (key === "otherDeductionSelect") {
      setSelectedDeduction(newValue);
      if (newValue === null) {
        setAddPayrollBody({ ...addPayrollBody, otherDeduction: "" });
        setIsChanged(!isChanged);
      }
    }
    if (key === "otherAllowance") {
      setAddPayrollBody({ ...addPayrollBody, otherAllowance: value });
      setIsChanged(!isChanged);
    }
    if (key === "otherDeduction") {
      setAddPayrollBody({ ...addPayrollBody, otherDeduction: value });
      setIsChanged(!isChanged);
    }
    if (key === "paymentAmt") {
      setAddPayrollBody({ ...addPayrollBody, paymentAmt: value });
      setAmountAlert(false);
      if (value === "") {
        setAmountAlert(true);
      }
    }
    if (key === "paymentMethod") {
      setAddPayrollBody({ ...addPayrollBody, paymentMethod: value });
    }
    if (key === "accNo") {
      setAddPayrollBody({ ...addPayrollBody, accNo: value });
      setAccNoAlert(false);
      if (value === "") {
        setAccNoAlert(true);
      }
    }
    if (key === "ifscCode") {
      setAddPayrollBody({ ...addPayrollBody, ifscCode: value });
      setIfscAlert(false);
      if (value === "") {
        setIfscAlert(true);
      }
    }
  };

  const addPayrollPayloadBody = {
    emp_id: addPayrollBody?.empId,
    branchId: addPayrollBody?.branchId,
    earnings: {
      basic_salary: Number(addPayrollBody?.basicSalary),
      hra_allowance: Number(addPayrollBody?.hraAllowance),
      da_allowance: Number(addPayrollBody?.daAllowance),
      travel_allowance: Number(addPayrollBody?.travelAllowance),
      over_time: Number(addPayrollBody?.overTimeHr),
      other_allowance: Number(addPayrollBody?.otherAllowance),
      education_allowance: Number(addPayrollBody?.educationAllowance),
      bonus: Number(addPayrollBody?.bonus),
      total_earnings: Number(addPayrollBody?.totalEarning),
    },
    deductions: {
      emi: Number(addPayrollBody?.emiMonthly),
      epf: Number(addPayrollBody?.epf),
      esi: Number(addPayrollBody?.esi),
      TDS: Number(addPayrollBody?.tds),
      other_deduction: Number(addPayrollBody?.otherDeduction),
      medical_claim: Number(addPayrollBody?.medicalClaim),
      loan: Number(addPayrollBody?.loan),
      total_deduction: Number(addPayrollBody?.totalDeduction),
    },
    payment: {
      amount: Number(addPayrollBody?.paymentAmt),
      paymentMethod: addPayrollBody?.paymentMethod,
      bankName: addPayrollBody?.bankName,
      accountNo: addPayrollBody?.accNo,
      IFSC: addPayrollBody?.ifscCode,
    },
  };
  const payrollVoucherSelect = () => {
    console.log(addPayrollBody);
  };
  const clearStates = () => {
    setAddPayrollBody(addPayrollInitialBody);
    setSelectedDeduction(null);
    setSelectedAllowance(null);
    setAddPayrollPayment(null);
    generatePayrollVoucherAPICall();
  };

  const addPayrollFinalSubmit = () => {
    if (addPayrollBody?.paymentAmt === "") {
      setAmountAlert(true);
    } else if (addPayrollBody?.bankName === "") {
      setBankNameAlert(true);
    } else if (addPayrollBody?.accNo === "") {
      setAccNoAlert(true);
    } else if (addPayrollBody?.ifscCode === "") {
      setIfscAlert(true);
    } else {
      staffAddPayrollAPICall(
        addPayrollPayloadBody,
        clearStates,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg
      );
    }
  };

  useEffect(() => {
    voucherNoGenerated !== undefined &&
      setAddPayrollBody({
        ...addPayrollBody,
        voucherNo: voucherNoGenerated?.transeNo,
      });
  }, [voucherNoGenerated]);
  useEffect(() => {
    getAllActiveEmployeesAPICall();
    generatePayrollVoucherAPICall();
  }, []);
  useEffect(() => {
    let parsedObj = Object.fromEntries(
      Object.entries(addPayrollBody).map(([key, value]) => [
        key,
        isNaN(parseFloat(value)) ? 0 : parseFloat(value),
      ])
    );

    let sum =
      parsedObj.basicSalary +
      parsedObj.hraAllowance +
      parsedObj.daAllowance +
      parsedObj.bonus +
      parsedObj.travelAllowance +
      parsedObj.educationAllowance +
      parsedObj.overTimeHr +
      parsedObj.otherAllowance;
    let sumDeductions =
      parsedObj.emiMonthly +
      parsedObj.epf +
      parsedObj.esi +
      parsedObj.tds +
      parsedObj.leave +
      parsedObj.medicalClaim +
      parsedObj.otherDeduction;
    setAddPayrollBody({
      ...addPayrollBody,
      totalEarning: sum,
      totalDeduction: sumDeductions,
    });
  }, [isChanged]);
  return (
    <>
      <div className="global-page-parent-container">
        <p className="breadcrumb">Staff &gt; Payroll &gt;Add Payroll</p>
        <div className="staff-add-payroll-container">
          <div className="staff-add-payroll-top-ctn">
            <h3>Payroll Voucher</h3>
            <div className="staff-add-payroll-top-ctn-input-field">
              <div className="global-single-input add-payroll-input">
                <p>Voucher No</p>
                <input type="text" disabled value={addPayrollBody?.voucherNo} />
              </div>
              <div className="global-single-input add-payroll-input">
                <p>Date</p>
                <input
                  type="date"
                  value={addPayrollBody.date}
                  onChange={addPayrollOnChange("date")}
                />
              </div>
              <div className="global-single-input add-payroll-input auto-complete">
                <p>Employee Id</p>
                <Autocomplete
                  sx={{ width: "100%" }}
                  options={allActiveEmpList}
                  getOptionLabel={(option) => option?.emp_id}
                  renderInput={(params) => <TextField {...params} />}
                  value={selectedEmployeeId}
                  onChange={addPayrollOnChange("empId")}
                />
                {employeeAlert ? (
                  <p className="doc-validation-alert">Select an employee !!</p>
                ) : (
                  ""
                )}
              </div>
              <div className="global-single-input add-payroll-input auto-complete">
                <p>Employee</p>
                <Autocomplete
                  sx={{ width: "100%" }}
                  options={allActiveEmpList}
                  getOptionLabel={(option) => option?.staff_name}
                  renderInput={(params) => <TextField {...params} />}
                  value={selectedEmployeeName}
                  onChange={addPayrollOnChange("staffName")}
                />
                {employeeAlert ? (
                  <p className="doc-validation-alert">Select an employee !!</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="staff-add-payroll-top-ctn-btn-ctn">
              <button className="btn btn-secondary-outlined">Clear</button>
              <button
                className="btn btn-primary"
                onClick={payrollVoucherSelect}
              >
                Submit
              </button>
            </div>
          </div>
          <div className="staff-add-payroll-body-ctn">
            <div className="staff-add-payroll-body-container-parent">
              <div className="staff-add-payroll-body-section">
                <h4>Salary Information</h4>
                <p className="add-payroll-body-earning-p">Earnings</p>
                <div className="staff-add-payroll-body-section-input-field">
                  <div className="global-single-input">
                    <p>Basic Salary</p>
                    <input
                      type="text"
                      placeholder="0.00"
                      value={addPayrollBody.basicSalary}
                      onChange={addPayrollOnChange("basicSalary")}
                    />
                  </div>
                  <div className="global-single-input">
                    <p>HRA Allowance</p>
                    <input
                      type="text"
                      placeholder="0.00"
                      value={addPayrollBody.hraAllowance}
                      onChange={addPayrollOnChange("hraAllowance")}
                    />
                  </div>
                  <div className="global-single-input">
                    <p>DA Allowance</p>
                    <input
                      type="text"
                      placeholder="0.00"
                      value={addPayrollBody.daAllowance}
                      onChange={addPayrollOnChange("daAllowance")}
                    />
                  </div>
                  <div className="global-single-input">
                    <p>Bonus</p>
                    <input
                      type="text"
                      placeholder="0.00"
                      value={addPayrollBody.bonus}
                      onChange={addPayrollOnChange("bonus")}
                    />
                  </div>
                  <div className="global-single-input">
                    <p>Travel Allowance</p>
                    <input
                      type="text"
                      placeholder="0.00"
                      value={addPayrollBody.travelAllowance}
                      onChange={addPayrollOnChange("travelAllowance")}
                    />
                  </div>
                  <div className="global-single-input">
                    <p>Education Allowance</p>
                    <input
                      type="text"
                      placeholder="0.00"
                      value={addPayrollBody.educationAllowance}
                      onChange={addPayrollOnChange("educationAllowance")}
                    />
                  </div>
                  <div className="global-single-input">
                    <p>Over Time(H)</p>
                    <input
                      type="text"
                      placeholder="0.00"
                      value={addPayrollBody.overTimeHr}
                      onChange={addPayrollOnChange("overTimeHr")}
                    />
                  </div>
                </div>
                <div className="staff-add-payroll-body-section-bottom-ctn">
                  <hr className="global-hr" />
                  <div className="global-single-input auto-complete">
                    <p>Other Allowance</p>
                    <Autocomplete
                      sx={{ width: "100%" }}
                      options={allowanceList}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => <TextField {...params} />}
                      onChange={addPayrollOnChange("otherAllowanceSelect")}
                    />
                  </div>
                  {selectedAllowance !== null && (
                    <div className="payroll-body-section-bottom-ctn-extra">
                      <p>{selectedAllowance}</p>
                      <div className="global-single-input">
                        <input
                          type="text"
                          placeholder="0.00"
                          onChange={addPayrollOnChange("otherAllowance")}
                          value={addPayrollBody.otherAllowance}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="staff-add-payroll-body-section-footer">
                <p>Total Earnings</p>
                <div className="global-single-input">
                  <input
                    type="text"
                    placeholder="0.00"
                    value={addPayrollBody.totalEarning}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="staff-add-payroll-body-container-parent">
              <div className="staff-add-payroll-body-section">
                <h4>Salary Information</h4>
                <p
                  className="add-payroll-body-earning-p"
                  style={{ color: "red" }}
                >
                  Deductions
                </p>
                <div className="staff-add-payroll-body-section-input-field">
                  <div className="staff-add-payroll-body-section-input-two">
                    <div className="global-single-input">
                      <p>Loan</p>
                      <input
                        type="text"
                        placeholder="0.00"
                        value={addPayrollBody.loan}
                        onChange={addPayrollOnChange("loan")}
                      />
                    </div>
                    <div
                      className="global-single-input"
                      style={{ marginRight: 0 }}
                    >
                      <p>EMI (Monthly)</p>
                      <input
                        type="text"
                        placeholder="0.00"
                        value={addPayrollBody.emiMonthly}
                        onChange={addPayrollOnChange("emiMonthly")}
                      />
                    </div>
                  </div>
                  <div className="global-single-input">
                    <p>EPF</p>
                    <input
                      type="text"
                      placeholder="0.00"
                      value={addPayrollBody.epf}
                      onChange={addPayrollOnChange("epf")}
                    />
                  </div>
                  <div className="global-single-input">
                    <p>ESI</p>
                    <input
                      type="text"
                      placeholder="0.00"
                      value={addPayrollBody.esi}
                      onChange={addPayrollOnChange("esi")}
                    />
                  </div>
                  <div className="global-single-input">
                    <p>TDS</p>
                    <input
                      type="text"
                      placeholder="0.00"
                      value={addPayrollBody.tds}
                      onChange={addPayrollOnChange("tds")}
                    />
                  </div>
                  <div className="global-single-input">
                    <p>Leave</p>
                    <input
                      type="text"
                      placeholder="0.00"
                      value={addPayrollBody.leave}
                      onChange={addPayrollOnChange("leave")}
                    />
                  </div>
                  <div className="global-single-input">
                    <p>Medical Claim</p>
                    <input
                      type="text"
                      placeholder="0.00"
                      value={addPayrollBody.medicalClaim}
                      onChange={addPayrollOnChange("medicalClaim")}
                    />
                  </div>
                </div>
                <div className="staff-add-payroll-body-section-bottom-ctn">
                  <hr className="global-hr" />
                  <div className="global-single-input auto-complete">
                    <p>Other Deductions</p>
                    <Autocomplete
                      sx={{ width: "100%" }}
                      options={deductionList}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => <TextField {...params} />}
                      onChange={addPayrollOnChange("otherDeductionSelect")}
                    />
                  </div>
                  {selectedDeduction !== null && (
                    <div className="payroll-body-section-bottom-ctn-extra">
                      <p>{selectedDeduction}</p>
                      <div className="global-single-input">
                        <input
                          type="text"
                          placeholder="0.00"
                          value={addPayrollBody.otherDeduction}
                          onChange={addPayrollOnChange("otherDeduction")}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="staff-add-payroll-body-section-footer">
                <p>Total Deduction</p>
                <div className="global-single-input">
                  <input
                    type="text"
                    placeholder="0.00"
                    value={addPayrollBody.totalDeduction}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="staff-add-payroll-footer-ctn">
            <div className="staff-add-payroll-footer-ctn-section">
              <div className="staff-add-payroll-footer-ctn-left-section global-single-input">
                <div className="staff-add-payroll-footer-ctn-right">
                  <h4>Total Payable Salary</h4>
                </div>
                <input
                  type="text"
                  disabled
                  placeholder="0.00"
                  value={totalPayableSalary}
                />
              </div>
              <div className="staff-add-payroll-footer-ctn-left-section global-single-input">
                <div className="staff-add-payroll-footer-ctn-right">
                  <p>Paid Amount</p>
                </div>
                <input
                  type="text"
                  placeholder="0.00"
                  value={addPayrollBody.paidAmount}
                  onChange={addPayrollOnChange("paidAmount")}
                />
              </div>
              <div className="staff-add-payroll-footer-ctn-left-section global-single-input">
                <div className="staff-add-payroll-footer-ctn-right">
                  <p>Balance Amount</p>
                </div>
                <input
                  type="text"
                  disabled
                  placeholder="0.00"
                  value={isNaN(balanceAmount) ? "0" : balanceAmount}
                />
              </div>
            </div>
          </div>
          <div
            className="staff-add-payroll-button-ctn"
            style={{ position: "relative" }}
          >
            {fillFieldsAlert ? (
              <p
                className="doc-validation-alert"
                style={{ top: "75%", fontWeight: "700" }}
              >
                fill the fields above !!
              </p>
            ) : (
              ""
            )}
            <button className="btn btn-red" onClick={clearStates}>
              Clear
            </button>
            <button
              className="btn btn-primary"
              style={{ marginRight: 0 }}
              onClick={clickPayment}
            >
              Payment
            </button>
          </div>
        </div>
      </div>

      <Dialog maxWidth="lg" open={addPayrollPayment}>
        <div className="staff-payroll-payment-ctn">
          <h4>Payment</h4>
          <div className="staff-payroll-payment-ctn-input-field">
            <div className="global-single-input">
              <p>Amount</p>
              <input
                type="number"
                value={addPayrollBody?.paymentAmt}
                onChange={addPayrollOnChange("paymentAmt")}
              />
              {amountAlert ? (
                <p className="doc-validation-alert">fill this field !!</p>
              ) : (
                ""
              )}
            </div>
            <div className="global-single-input">
              <p>Payment Method</p>
              <Select
                value={addPayrollBody?.paymentMethod}
                onChange={addPayrollOnChange("paymentMethod")}
              >
                <MenuItem value="cheque">Cheque</MenuItem>
                <MenuItem value="netbanking">NET Banking</MenuItem>
                <MenuItem value="upi">UPI</MenuItem>
              </Select>
            </div>
            <div className="global-single-input auto-complete">
              <p>Bank Name</p>
              <Autocomplete
                sx={{ width: "100%" }}
                options={Banks}
                getOptionLabel={(option) => option?.name}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Bank Name" />
                )}
                onChange={(e, newValue) => {
                  setAddPayrollBody({
                    ...addPayrollBody,
                    bankName: newValue?.name,
                  });
                  setBankNameAlert(false);
                  if (newValue === null) {
                    setBankNameAlert(true);
                  }
                }}
                //  value={addPayrollBody?.bankName}
              />
              {bankNameAlert ? (
                <p className="doc-validation-alert">fill this field !!</p>
              ) : (
                ""
              )}
            </div>
            <div className="global-single-input">
              <p>Account No</p>
              <input
                type="text"
                value={addPayrollBody?.accNo}
                onChange={addPayrollOnChange("accNo")}
              />
              {accNoAlert ? (
                <p className="doc-validation-alert">fill this field !!</p>
              ) : (
                ""
              )}
            </div>
            <div className="global-single-input">
              <p>IFSC</p>
              <input
                type="text"
                value={addPayrollBody?.ifscCode}
                onChange={addPayrollOnChange("ifscCode")}
              />
              {ifscAlert ? (
                <p className="doc-validation-alert">fill this field !!</p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="staff-payroll-payment-ctn-buttons">
            <button
              className="btn btn-secondary-outlined"
              onClick={() => setAddPayrollPayment(false)}
            >
              Cancel
            </button>
            <button className="btn btn-primary" onClick={addPayrollFinalSubmit}>
              Payment
            </button>
          </div>
        </div>
      </Dialog>

      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnack}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnack}
        message={snackMsg}
      />
    </>
  );
};
