import { createSlice } from "@reduxjs/toolkit";

export const buttonSlice=createSlice({
    name:"buttonSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        update_button:(state,action)=>{
            state.value=action.payload.button
        }
    }
})

export const {update_button}=buttonSlice.actions
export default buttonSlice.reducer