import {
  Autocomplete,
  Checkbox,
  Dialog,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useState, useRef } from "react";
import "../../../../css/Orders/printCuttingSlip.css";
import { AddTailorPopup } from "../../../Single Components/AddTailorPopup";
import { ThobCuttingSlip } from "./ThobCuttingSlip";
import ReactToPrint from "react-to-print";
import { JalabiaCuttingSlip } from "./JalabiaCuttingSlip";
import { SalwarCuttingSlip } from "./SalwarCuttingSlip";
import { AbayaCuttingSlip } from "./AbayaCuttingSlip";
import { ChuridharCuttingSlip } from "./ChuridharCuttingSlip";
import { ShirtCuttingSlip } from "./ShirtCuttingSlip";
import { PantCuttingSlip } from "./PantCuttingSlip";
import { CoatCuttingSlip } from "./CoatCuttingSlip";
import { SharwaniCuttingSlip } from "./SharwaniCuttingSlip";
import { SafariCuttingSlip } from "./SafariCuttingSlip";
import { useEffect } from "react";
import { getWorkOrderNosAPICall } from "../../../../API/Orders/workOrderAPI";
import { useSelector } from "react-redux";
import { viewAllCustomersAPICall } from "../../../../API/Customer/customerAPI";
import {
  generateQRCodeForPCSAPICall,
  printCuttingSlipFilterAPICall,
  updateStatusOnPrintCuttingSlipAPICall,
} from "../../../../API/Orders/printCuttingSlipAPI";
import { workOrderSingleViewAPICall } from "../../../../API/Orders/viewOrdersAPI";
import { getAllActiveEmployeesAPICall } from "../../../../API/Staff/HRM/employeeAPI";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { type } from "@testing-library/user-event/dist/type";
import {
  makeCuttingSlipSingleViewExtensible,
  splitRelationsOrCustomer,
} from "../../../../Js/newModelCuttingSlip";
import { convertDateFormat } from "../../../../Js/Date";
import { ThobCuttingSlip1 } from "./ThobCuttingSlip1";
import { viewCuttingSlipModelAPICall } from "../../../../API/Settings/CuttingSlipModel/cuttingSlipMoldelAPI";
import { JalabiaCuttingSlip1 } from "./JalabiaCuttingSlip1";
import { SalwarCuttingSlip1 } from "./SalwarCuttingSlip1";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const PrintCuttingSlip = (props) => {
  const { isDialog, isShortCut, closeShortCut, singleViewDialogOpen } = props;
  const currentBranchId = localStorage.getItem("branchId");
  const userLoginMeta = localStorage.getItem('login_meta')
  const userBranchId=JSON.parse(userLoginMeta)?.branchPk;
  //*Redux States
  //List of All Work order numbers
  const allWorkOrderNosList = useSelector(
    (state) => state.workOrderNosSlice.value
  );
  //List of All Customers
  const customerList = useSelector((state) => state.allCustomerListSlice.value);
  //Filtered Data
  const filterResult = useSelector(
    (state) => state.printCuttingSlipFilteredData.value
  );
  //print cutting slip single view
  const printCuttingSlipSingleView = useSelector(
    (state) => state.workOrderSingleDataSlice.value
  );
  //Active Employee List
  const allActiveEmpList = useSelector(
    (state) => state.allActiveEmployeeSlice.value
  );
  //Generated qr code
  const generatedQrCode = useSelector((state) => state.qrForPcsSlice.value);
  // cutting slip template
  const cuttingSlipTemplate=useSelector((state)=>state.cuttingSlipSlice.value)

  let thobCuttingSlip = useRef();
  let jalabiaCuttingSlip = useRef();
  let salwarCuttingSlip = useRef();
  let abayaCuttingSlip = useRef();
  let churidharCuttingSlip = useRef();
  let shirtCuttingSlip = useRef();
  let pantCuttingSlip = useRef();
  let coatCuttingSlip = useRef();
  let sharwaniCuttingSlip = useRef();
  let safariCuttingSlip = useRef();

  //Printing Dress Type Object
  const [printingTypeObj, setPrintingTypeObj] = useState(null);
  // print cutting slip single view
  const [printCuttingSlipView, setPrintCuttingSlipView] = useState(false);
  const [printCuttingSlipTailorPopup, setPrintCuttingSlipTailorPopup] =
    useState(false);
  const [
    printCuttingSlipCuttingMasterPopup,
    setPrintCuttingSlipCuttingMasterPopup,
  ] = useState(false);

  //Selected WO ID obj
  const [selectedWoNoObj, setSelectedWoNoObj] = useState(null);
  const [selectedWoNoInt, setSelectedWoNoInt] = useState(null);
  //Selected Customer Obj
  const [selectedCustomerObj, setSelectedCustomerObj] = useState(null);
  //Filter Form State
  const filterFormInitialState = {
    orderId: null,
    mobileNo: "",
    cusId: null,
  };
  const [filterFormState, setFilterFormState] = useState(
    filterFormInitialState
  );

  //Selected Cutters
  const [selectedCutters, setSelectedCutter] = useState(null);
  //* New states for cutting slip model change //
  const [cuttingSlipNewModel, setCuttingSlipNewModel] = useState(null);

  //New Model cuttingSlip filtered
  const [cuttingSlipNewModelFiltered, setCuttingSlipNewModelFiltered] =
    useState([]);
  const [selectedRelationId, setSelectedRelationId] = useState(null);

  // disable print button 
  const [disable,setDisable]=useState(false)

  // console.log(selectedCutters);
  // console.log(cuttingSlipNewModelFiltered);

  //*Fns
  //Assign cuttingMaster and tailor on user selection
  const assignCuttingMasterAndTailor =
    (singleCuttingSlip, type) => (e, newValue) => {
      if (type === "cuttingMaster") {
        //Write cutting master assigning code
        let cuttingMasterName = newValue.map((obj) => obj?.staff_name);
        singleCuttingSlip.cuttingMasters = cuttingMasterName;
      }
      if (type === "tailor") {
        //Write tailor assigning code
        let tailorName = newValue.map((obj) => obj?.staff_name);
        singleCuttingSlip.tailors = tailorName;
      }
    };

  //Filter NewModel cuttingSlip as per Relation selected
  const filterNewModelCuttingSlipByRelation = (relId, fullList) => {
    console.log(fullList);
    if (fullList !== undefined) {
      if (relId === undefined) setCuttingSlipNewModelFiltered(fullList?.items);
      else {
        let filtered = fullList?.items?.filter(
          (obj) => obj?.relationId === relId
        );
        setCuttingSlipNewModelFiltered(filtered);
      }
    } else {
      setCuttingSlipNewModelFiltered([]);
    }
  };
  //-----------------------------------------------------
  //NEW CUTTING SLIP MODEL CREATION CODES MOVED TO A SEPARATE FILE NAMED newModelCuttingSlip.js
  //-----------------------------------------------------

  //Extract integer from work order number string value
  const extractWoNoInt = (stringValue) => {
    let result = stringValue?.match(/\d+/g)[1];
    // let result = stringValue?.replace(/\D/g,'');
    console.log(result);
    return result;
  };
  //Get Customer Phone No
  const getCustomerContact = (e) => {
    setFilterFormState({ ...filterFormState, mobileNo: e.target.value });
  };

  //Click Filter Submit Fn
  const filterSubmit = () => {
    const { orderId, mobileNo, cusId } = filterFormState;
    printCuttingSlipFilterAPICall({
      orderNo: orderId,
      mobileNo: mobileNo !== "" ? mobileNo : null,
      cusId,
      branchId:currentBranchId
    });
  };

  //Open Print Cutting slip Single view
  const openPrintCuttingSlipSingleView = (orderId) => () => {
    workOrderSingleViewAPICall({ orderId });
    setPrintCuttingSlipView(true);

    console.log(orderId);
  };

  //Click Print Cutting Slip Icon btn
  const printSlipBtnClick = (r) => () => {
    setPrintingTypeObj(r);
  };

  //*useEffects
  //initial API Calls
  useEffect(() => {
    getWorkOrderNosAPICall({ branchId: currentBranchId });
    viewAllCustomersAPICall({branchId:userBranchId});
    getAllActiveEmployeesAPICall();
    filterSubmit();
    viewCuttingSlipModelAPICall()
  }, []);

  //Call to extract integer part from string wo no, Assign Work Order No integer part in state
  useEffect(() => {
    if (selectedWoNoObj !== null) {
      let intWoNo = extractWoNoInt(selectedWoNoObj?.orderNo);
      setSelectedWoNoInt(intWoNo);
      setFilterFormState({ ...filterFormState, orderId: intWoNo });
    } else {
      setFilterFormState({ ...filterFormState, orderId: null });
    }
  }, [selectedWoNoObj]);
  //Assign Selected Customer ID to state
  useEffect(() => {
    setFilterFormState({
      ...filterFormState,
      cusId: selectedCustomerObj?._id || null,
    });
  }, [selectedCustomerObj]);

  //Generate qr code for print cutting slip
  const generatePrintCuttingSlipQr = () => {
    console.log("clicked");
    if (printCuttingSlipSingleView !== undefined) {
      generateQRCodeForPCSAPICall({
        _id: printCuttingSlipSingleView?._id,
      });
    }
  };
  //cutting slip new model calls
  useEffect(() => {
    if (printCuttingSlipSingleView !== undefined) {
      generatePrintCuttingSlipQr();
      let singleViewCopy = makeCuttingSlipSingleViewExtensible(
        printCuttingSlipSingleView
      );
      console.log(singleViewCopy);
      let newModelCuttingSlip = splitRelationsOrCustomer(singleViewCopy);
      console.log(newModelCuttingSlip);
      setCuttingSlipNewModel(newModelCuttingSlip);
      console.log("useEffect invoked");
      console.count("print cutting slip called");
    }
  }, [printCuttingSlipSingleView]);

  // console.log(cuttingSlipNewModel);
  //call filter by relationId
  useEffect(() => {
    filterNewModelCuttingSlipByRelation(
      selectedRelationId?.relationId,
      cuttingSlipNewModel
    );
  }, [selectedRelationId, cuttingSlipNewModel]);

  //*-----------------Cutting slip model merge code ----------------------------------//

  // console.log(cuttingSlipNewModel);
  // console.log(printCuttingSlipSingleView);
  // console.log(cuttingSlipNewModelFiltered);

 console.log(cuttingSlipNewModelFiltered);

 // disable print button function
 useEffect(()=>{
   cuttingSlipNewModelFiltered?.map((r)=>{
    r?.materials?.every((item)=>{
      if(item?.status==='pending'){
       setDisable(false)
      }
      else{
       setDisable(true)
      }
    })
   })
  
 },[cuttingSlipNewModelFiltered])
 console.log(disable);

 console.log(cuttingSlipNewModelFiltered);
  


  return (
    <div className="global-page-parent-container">
      {!isDialog && (
        <p className="breadcrumb">Orders &gt; Print Cutting Slip</p>
      )}
      <div className="print-cutting-slip-container">
        <div className="print-cutting-slip-top-container">
          <h3>Print Cutting Slip</h3>
          <hr className="global-hr" />
          <div className="print-cutting-slip-top-input-container">
            <div className="global-single-input auto-complete">
              <p>Order No</p>
              <Autocomplete
                sx={{ width: "100%" }}
                options={allWorkOrderNosList || [""]}
                getOptionLabel={(option) => option?.orderNo}
                renderInput={(params) => (
                  <TextField {...params} placeholder="select order number..." />
                )}
                onChange={(e, newValue) => setSelectedWoNoObj(newValue)}
              />
            </div>
            <div className="global-single-input">
              <p>Mobile</p>
              <input
                placeholder="Enter mobile..."
                value={filterFormState?.mobileNo}
                onChange={getCustomerContact}
                maxLength="15"
              />
            </div>
            <div className="global-single-input auto-complete">
              <p>Name</p>
              <Autocomplete
                sx={{ width: "100%" }}
                options={customerList || [""]}
                getOptionLabel={(option) => `${option?.cusId}-${option?.name}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="select customer name..."
                  />
                )}
                onChange={(e, newValue) => setSelectedCustomerObj(newValue)}
              />
            </div>
            {/* <div className='global-single-input'>
              <p>Relationship</p>
              <input/>
            </div> */}
            <button
              className="btn btn-primary print-cutting-slip-submit-btn"
              onClick={filterSubmit}
            >
              Submit
            </button>
          </div>
        </div>
        <div className="global-table-container print-cutting-slip-table">
          <table className="global-table ">
            <thead>
              <tr>
                <th>No</th>
                <th>Order No</th>
                <th>Customer</th>
                <th>Mobile</th>
                <th>Date</th>
                <th>Delivery Date</th>
                <th>Net Amount</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {filterResult
                ?.slice(0)
                ?.reverse()
                ?.map((r, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{r?.orderNo}</td>
                    <td>{r?.customer}</td>
                    <td>{r?.mobile}</td>
                    <td>{convertDateFormat(r?.orderDate)}</td>
                    <td>{r?.deliveryDate !== null ? convertDateFormat(r?.deliveryDate) : "-NIL-"}</td>
                    <td>{r?.netAmount?.toFixed(2)}</td>
                    <td>
                      <IconButton
                        onMouseDown={openPrintCuttingSlipSingleView(r?._id)}
                        // onClick={generatePrintCuttingSlipQr}
                      >
                        <i class="bi bi-eye visibility-icon"></i>
                      </IconButton>
                    </td>
                  </tr>
                )) || (
                <tr>
                  <td colSpan={8}>No data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* print cutting slip single view */}
      <Dialog
        open={isShortCut ? singleViewDialogOpen : printCuttingSlipView}
        // onClose={() => setPrintCuttingSlipView(false)}
        maxWidth="lg"
      >
        <div className="print-cutting-slip-single-view">
          <div className="print-cutting-slip-top-container">
            <h3>Print Cutting Slip</h3>
            <hr className="global-hr" />
            <div className="print-cutting-slip-top-input-container">
              <div className="global-single-input">
                <p>Order No</p>
                <input disabled value={cuttingSlipNewModel?.woNo} />
              </div>
              <div className="global-single-input">
                <p>Mobile</p>
                <input disabled value={cuttingSlipNewModel?.cusMobile} />
              </div>
              <div className="global-single-input">
                <p>Name</p>
                <input disabled value={cuttingSlipNewModel?.cusName} />
              </div>
              <div className="global-single-input auto-complete">
                <p>Relation</p>
                <Autocomplete
                  options={
                    cuttingSlipNewModel?.relations || [
                      { relationName: "No relations" },
                    ]
                  }
                  getOptionLabel={(option) => `${option?.relationName}`}
                  value={selectedRelationId}
                  // style={{ width: 500 }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Relation" />
                  )}
                  onChange={(e, newValue) => setSelectedRelationId(newValue)}
                />
                {/* <Select>
                  {cuttingSlipNewModel?.relations ? (
                    cuttingSlipNewModel?.relations?.map((rel, i) => (
                      <MenuItem key={i} value={rel?.relationId}>{rel?.relationName}</MenuItem>
                    ))
                  ) : (
                    <MenuItem>No relations </MenuItem>
                  )}
                </Select> */}
              </div>
            </div>
          </div>
          {cuttingSlipNewModelFiltered?.map((r) => (
            <div className="print-cutting-slip-view-top-container">
              <div className="print-cutting-slip-view-heading-container">
                <h3>
                  {r.type?.toUpperCase()} × {r?.materials?.length} -{" "}
                  {r?.relationName !== "null"
                    ? r?.relationName
                    : cuttingSlipNewModel?.cusName}
                </h3>
                <ReactToPrint
                  trigger={() => (
                    <IconButton
                      // disabled={r?.materials[0]?.status==='pending'?false:true}
                    
                      // disabled={disable}
                      title="Print"
                      onClick={() => {
                        console.log("Icon pressed");
                      }}
                    >
                     {/* {disable===false? <i class="bi bi-printer printer-icon"></i> : <i class="bi bi-check2-circle"></i>} */}
                     {<i class="bi bi-printer printer-icon"></i>}
                    </IconButton>
                  )}
                  onBeforeGetContent={() => {
                    setPrintingTypeObj(r);
                    updateStatusOnPrintCuttingSlipAPICall({
                      orderId: cuttingSlipNewModel?.woId || null,
                      itemId: r?.itemId,
                    });
                    return Promise.resolve();
                  }}
                  // onBeforeGetContent={printSlipBtnClick(r)}
                  content={() =>
                    r.type === "thob"
                      ? thobCuttingSlip
                      : r.type === "jalabia"
                      ? jalabiaCuttingSlip
                      : r.type === "salwar"
                      ? salwarCuttingSlip
                      : r.type === "abaya"
                      ? abayaCuttingSlip
                      : r.type === "churidhar"
                      ? churidharCuttingSlip
                      : r.type === "shirt"
                      ? shirtCuttingSlip
                      : r.type === "pant"
                      ? pantCuttingSlip
                      : r.type === "coat"
                      ? coatCuttingSlip
                      : r.type === "sharvani"
                      ? sharwaniCuttingSlip
                      : r.type === "safari"
                      ? safariCuttingSlip
                      : undefined
                  }
                />
              </div>
              {/* {console.log(r)} */}
              {r?.type === "thob" ? (
                <div className="view-order-status-content-input-container">
                  <div className="global-single-input view-order-single-view-status">
                    <p>Thob Model</p>
                    <input value={r?.materials[0]?.thobModelName || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Length</p>
                    <input value={r?.length || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>{cuttingSlipTemplate?.template == "template2" ? "Sleeve":"Shoulder"}</p>
                    <input value={r?.shoulder || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>{cuttingSlipTemplate?.template == "template2" ? "Sada":"Sleeve"}</p>
                    <input value={r?.sleeve || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>{cuttingSlipTemplate?.template == "template2" ? "Regal":"Chest"}</p>
                    <input value={r?.chest || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>{cuttingSlipTemplate?.template == "template2" ? "Body":"Stomach"}</p>
                    <input value={r?.stomach || "-NIL-"} />
                  </div>
                  {
                    cuttingSlipTemplate?.template !== "template2"&&
                  //   <div className="global-single-input view-order-single-view-status">
                  //   <p>Mar Model</p>
                  //   <input value={r?.materials[0]?.cuffName || "-NIL-"} />
                  // </div>
                  //   :
                   <div className="global-single-input view-order-single-view-status">
                    <p>S.L Chest</p>
                    <input value={r?.slChest || "-NIL-"} />
                  </div>
                  }
                  
                  <div className="global-single-input view-order-single-view-status">
                    <p>{cuttingSlipTemplate?.template == "template2" ? "Notes":"S.L So"}</p>
                    <input value={r?.slSo || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Neck</p>
                    <input value={r?.neck || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>{cuttingSlipTemplate?.template == "template2" ? "PC":"Bottom"}</p>
                    <input value={r?.bottom || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>{cuttingSlipTemplate?.template == "template2" ? "Bottom":"Mar Size"}</p>
                    <input value={r?.marSize || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Mar Model</p>
                    <input value={r?.materials[0]?.marModelName || "-NIL-"} />
                  </div>
                  {
                    cuttingSlipTemplate?.template !== "template2" &&
                    <>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Cuff</p>
                    <input value={r?.materials[0]?.cuffName || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Cuff Size</p>
                    <input
                      value={
                        `${r?.materials[0]?.cuffSize1} ${r?.materials[0]?.cuffSize2}` ||
                        "-NIL-"
                      }
                    />
                  </div>
                  </>
                  }
                  
                  {/* <div className="global-single-input view-order-single-view-status">
                    <p>Cuff Size2</p>
                    <input value={r?.cuffSize2 || "-NIL-"} />
                  </div> */}
                  <div className="global-single-input view-order-single-view-status">
                    <p>{cuttingSlipTemplate?.template == "template2" ? "Stitching Model":"Cuff Cloth"}</p>
                    <input value={r?.materials[0]?.cuffClothName || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Remark</p>
                    <input value={r?.materials[0]?.remark || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>{cuttingSlipTemplate?.template == "template2" ? "Button":"Cuff Model"}</p>
                    <input value={r?.materials[0]?.cuffModelName || "-NIL-"} />
                  </div>

                  {/* neck d button ,side pt size missing  */}
                  {
                     cuttingSlipTemplate?.template == "template2"?
                     <div className="global-single-input view-order-single-view-status">
                     <p>Shoulder</p>
                     <input value={r?.neckDButton || "-NIL-"} />
                   </div>
                     :
                     <div className="global-single-input view-order-single-view-status">
                     <p>Collar</p>
                     <input value={r?.materials[0]?.collarName || "-NIL-"} />
                   </div>
                  }
                 
                  <div className="global-single-input view-order-single-view-status">
                    <p>{cuttingSlipTemplate?.template == "template2" ? "L Shape":"Collar Size"}</p>
                    <input value={r?.materials[0]?.collarSize || "-NIL-"} />
                  </div>
                  {
                    cuttingSlipTemplate?.template == "template2"?
                    <div className="global-single-input view-order-single-view-status">
                    <p>Sleeve</p>
                    <input
                      value={r?.materials[0]?.collarCloth || "-NIL-"}
                    />
                  </div>
                    :
                    <div className="global-single-input view-order-single-view-status">
                    <p>Collar Cloth</p>
                    <input
                      value={r?.materials[0]?.collarClothName || "-NIL-"}
                    />
                  </div>
                  }
                {
                  cuttingSlipTemplate?.template == "template2" ?
                  <div className="global-single-input view-order-single-view-status">
                    <p>Sleeve Model</p>
                    <input
                      value={r?.materials[0]?.collarModel || "-NIL-"}
                    />
                  </div>
                  :
                   <div className="global-single-input view-order-single-view-status">
                    <p>Collar Model</p>
                    <input
                      value={r?.materials[0]?.collarModelName || "-NIL-"}
                    />
                  </div>
                }
                 
                  <div className="global-single-input view-order-single-view-status">
                    <p>{cuttingSlipTemplate?.template == "template2" ? "Side":"Regal Size"}</p>
                    <input value={r?.regalSize || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>{cuttingSlipTemplate?.template == "template2" ? "Centre":"Knee Loose"}</p>
                    <input value={r?.kneeLoose || "-NIL-"} />
                  </div>
                  {/* <div className="global-single-input view-order-single-view-status">
                    <p>Muttu Loose</p>
                    <input value={r?.kneeLoose || "-NIL-"} />
                  </div> */}
                  <div className="global-single-input view-order-single-view-status">
                    <p>{cuttingSlipTemplate?.template == "template2" ? "Mar":"FP Down"}</p>
                    <input value={r?.fpDown || "-NIL-"} />
                  </div>
                  { 
                      cuttingSlipTemplate?.template == "template2" ?
                      <div className="global-single-input view-order-single-view-status">
                      <p>PT Down</p>
                      <input
                        value={
                          `${r?.materials[0]?.cuffSize1} ${r?.materials[0]?.cuffSize2}` ||
                          "-NIL-"
                        }
                      />
                    </div>
                    :
                    <div className="global-single-input view-order-single-view-status">
                    <p>FP Model</p>
                    <input value={r?.materials[0]?.fpName || "-NIL-"} />
                  </div>

                  }
                 
                  {
                      cuttingSlipTemplate?.template == "template2"?
                     <div className="global-single-input view-order-single-view-status">
                     <p>PT Size</p>
                     <input value={r?.fpSize1 || "-NIL-"} />
                   </div>
                   :
                   <div className="global-single-input view-order-single-view-status">
                    <p>FP Size</p>
                    <input value={`${r?.fpSize1}  ${r?.fpSize2}` || "-NIL-"} />
                  </div>
                  }
                  {
                     cuttingSlipTemplate?.template == "template2" &&
                     <div className="global-single-input view-order-single-view-status">
                     <p>PT Canvas</p>
                     <input value={r?.materials[0]?.stitchingName || "-NIL-"} />
                   </div>
                  }
                  
                  <div className="global-single-input view-order-single-view-status">
                    <p>Pen</p>
                    <input value={r?.materials[0]?.penName || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Side PT Model</p>
                    <input value={r?.materials[0]?.sidePTName || "-NIL-"} />
                  </div>
                  {
                    cuttingSlipTemplate?.template == "template2"?
                    <div className="global-single-input view-order-single-view-status">
                    <p>Down,Veedi,Bag,Open</p>
                    <input value={r?.fpSize2|| "-NIL-"} />
                  </div>
                  :

                  <div className="global-single-input view-order-single-view-status">
                    <p>Stitching</p>
                    <input value={r?.materials[0]?.stitchingName || "-NIL-"} />
                  </div>
                  }
                  <div className="global-single-input view-order-single-view-status">
                    <p>Button No</p>
                    <input value={r?.materials[0]?.buttonNo || "-NIL-"} />
                  </div>
                </div>
              ) : r?.type === "jalabia" ? (
                <div className="view-order-status-content-input-container">
                  <div className="global-single-input view-order-single-view-status">
                    <p>Jalabia Model</p>
                    <input
                      value={r?.materials[0]?.jalabiaModelName || "-NIL-"}
                    />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Length</p>
                    <input value={r?.length || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>{cuttingSlipTemplate?.template == "template2" ? "Sleeve":"Shoulder"}</p>
                    <input value={r?.shoulder || "-NIL-"} />
                  </div>
                 {
                  cuttingSlipTemplate?.template == "template2" ?
                  <div className="global-single-input view-order-single-view-status">
                  <p>Loose</p>
                  <input value={r?.materials[0]?.neckName || "-NIL-"} />
                </div>
                :
                <div className="global-single-input view-order-single-view-status">
                <p>Sleeve</p>
                <input value={r?.sleeve || "-NIL-"} />
              </div>
                 }
                 
                  <div className="global-single-input view-order-single-view-status">
                    <p>{cuttingSlipTemplate?.template == "template2" ? "Regal":"Chest"}</p>
                    <input value={r?.chest || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>{cuttingSlipTemplate?.template == "template2" ? "Body":"Stomach"}</p>
                    <input value={r?.stomach || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>{cuttingSlipTemplate?.template == "template2" ? "Mar":"S.L Chest"}</p>
                    <input value={r?.slChest || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>{cuttingSlipTemplate?.template == "template2" ? "PT":"S.L Sto"}</p>
                    <input value={r?.slSto || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Neck</p>
                    <input value={r?.neck || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>{cuttingSlipTemplate?.template == "template2" ? "PT":"Bottom"}</p>
                    <input value={r?.bottom || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>{cuttingSlipTemplate?.template == "template2" ? "Bottom":"Sleeve Size"}</p>
                    <input value={r?.sleeveSize || "-NIL-"} />
                  </div>
                  {
                    cuttingSlipTemplate?.template == "template2" ?
                    <div className="global-single-input view-order-single-view-status">
                    <p>Notes</p>
                    <input value={r?.sleeve || "-NIL-"} />
                    </div>
                    :
                    <div className="global-single-input view-order-single-view-status">
                    <p>Neck Model</p>
                    <input value={r?.materials[0]?.neckName || "-NIL-"} />
                  </div>
                  }
                  {
                    cuttingSlipTemplate?.template == "template2" ?
                    <div className="global-single-input view-order-single-view-status">
                    <p>Stitching</p>
                    <input value={r?.materials[0]?.remark || "-NIL-"} />
                  </div>
                    :
                    <div className="global-single-input view-order-single-view-status">
                    <p>Stitching</p>
                    <input value={r?.materials[0]?.stitchingName || "-NIL-"} />
                  </div>
                  }
                 {
                   cuttingSlipTemplate?.template == "template2" ?
                   <div className="global-single-input view-order-single-view-status">
                   <p>Button</p>
                   <input value={r?.materials[0]?.stitchingName || "-NIL-"} />
                 </div>
                   :
                   <div className="global-single-input view-order-single-view-status">
                   <p>Remark</p>
                   <input value={r?.materials[0]?.remark || "-NIL-"} />
                 </div>
                 }
                 
                  <div className="global-single-input view-order-single-view-status">
                    <p>{cuttingSlipTemplate?.template == "template2" ? "Shoulder":"Regal Size"}</p>
                    <input value={r?.regalSize || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Neck down button</p>
                    <input value={r?.neckDownButton || "-NIL-"} />
                  </div>
                  {
                    cuttingSlipTemplate?.template !== "template2" &&
                    <div className="global-single-input view-order-single-view-status">
                      <p>Mar</p>
                      <input value={r?.mar || "-NIL-"} />
                    </div>
                  }
                  {
                    cuttingSlipTemplate?.template == "template2"?
                    <div className="global-single-input view-order-single-view-status">
                    <p>Centre</p>
                    <input value={r?.materials[0]?.fpSize1 || "-NIL-"} />
                  </div>
                    :
                    <div className="global-single-input view-order-single-view-status">
                    <p>model</p>
                    <input value={r?.materials[0]?.ModelName || "-NIL-"} />
                  </div>
                  }
                
                  <div className="global-single-input view-order-single-view-status">
                    <p>{cuttingSlipTemplate?.template == "template2" ? "Side":"FP Model"}</p>
                    <input value={r?.materials[0]?.fpName || "-NIL-"} />
                  </div>
                  {
                    cuttingSlipTemplate?.template == "template2"?
                    <div className="global-single-input view-order-single-view-status">
                    <p>mar Type</p>
                    <input value={r?.materials[0]?.ModelName || "-NIL-"} />
                  </div>
                  :
                    <div className="global-single-input view-order-single-view-status">
                    <p>FP Size</p>
                    <input value={r?.materials[0]?.fpSize1 || "-NIL-"} />
                  </div>
                  }
                  
                </div>
              ) : r?.type === "salwar" ? (
                <div className="view-order-status-content-input-container">
                  <div className="global-single-input view-order-single-view-status">
                    <p>Length</p>
                    <input value={r?.length || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Waist</p>
                    <input value={r?.waist || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Bottom</p>
                    <input value={r?.bottom || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Elastic size</p>
                    <input value={r?.elasticSize || "-NIL-"} />
                  </div>
                 {
                  cuttingSlipTemplate?.template == "template2"?
                  <div className="global-single-input view-order-single-view-status">
                  <p>Inside Loose</p>
                  <input
                    value={r?.materials[0]?.remarks || "-NIL-"}
                  />
                </div>
                : <div className="global-single-input view-order-single-view-status">
                <p>Elastic width</p>
                <input
                  value={r?.materials[0]?.elasticWidthNumber || "-NIL-"}
                />
              </div>
                 }
                 
                  <div className="global-single-input view-order-single-view-status">
                    <p>{cuttingSlipTemplate?.template == "template2" ? "Thigh Loose":"Ply"}</p>
                    <input value={r?.ply || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>{cuttingSlipTemplate?.template == "template2" ? "Notes":"Thigh Loose"}</p>
                    <input value={r?.thighLoose || "-NIL-"} />
                  </div>
                  {
                    cuttingSlipTemplate?.template == "template2" ? 
                    <div className="global-single-input view-order-single-view-status">
                    <p>Type</p>
                    <input value={r?.materials[0]?.elasticWidthNumber || "-NIL-"} />
                  </div>
                  :
                  <div className="global-single-input view-order-single-view-status">
                  <p>Remarks</p>
                  <input value={r?.materials[0]?.remarks || "-NIL-"} />
                </div>
                  }
                  
                </div>
              ) : r?.type === "abaya" ? (
                <div className="view-order-status-content-input-container">
                  <div className="global-single-input view-order-single-view-status">
                    <p>Sleeve From Neck</p>
                    <input value={r?.sleeveFromNeck} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Sleeve Frm Shoulder</p>
                    <input value={r?.sleeveFromShoulder} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Sleeve Width</p>
                    <input value={r?.sleeveWidth} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Arms</p>
                    <input value={r?.arms} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Bust</p>
                    <input value={r?.bust} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Waist</p>
                    <input value={r?.waist} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Hip</p>
                    <input value={r?.hip} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Length</p>
                    <input value={r?.length} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Notes</p>
                    <input value={r?.materials[0]?.remarks} />
                  </div>
                </div>
              ) : r?.type === "churidhar" ? (
                <div className="view-order-status-content-input-container">
                  <div className="global-single-input view-order-single-view-status">
                    <p>Shoulder</p>
                    <input value={r?.shoulder || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Front Neck</p>
                    <input value={r?.frontNeck || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Back Neck</p>
                    <input value={r?.backNeck || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Waist</p>
                    <input value={r?.waist || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Hip</p>
                    <input value={r?.hip || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Full Length</p>
                    <input value={r?.fullLength || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Arm Round</p>
                    <input value={r?.armRound || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Arm Hole</p>
                    <input value={r?.armHole || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Sleeve Length</p>
                    <input value={r?.sleeveLength || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Notes</p>
                    <input value={r?.materials[0]?.remarks || "-NIL-"} />
                  </div>
                </div>
              ) : r?.type === "shirt" ? (
                <div className="view-order-status-content-input-container">
                  <div className="global-single-input view-order-single-view-status">
                    <p>Length</p>
                    <input value={r?.length || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Shoulder</p>
                    <input value={r?.shoulder || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Sleeve Full</p>
                    <input value={r?.sleeveFull || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Chest</p>
                    <input value={r?.chest || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Waist</p>
                    <input value={r?.waist || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Body</p>
                    <input value={r?.body || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Neck</p>
                    <input value={r?.neck || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Cup</p>
                    <input value={r?.materials[0]?.cupModelName || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Regal</p>
                    <input value={r?.regal || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Knee Loose</p>
                    <input value={r?.kneeLoose || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Disco</p>
                    <input value={r?.disco || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Canvas</p>
                    <input value={r?.canvas || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Notes</p>
                    <input value={r?.materials[0]?.remarks || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Stitching</p>
                    <input
                      value={r?.materials[0]?.stitchingModelname || "-NIL-"}
                    />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Mar Model</p>
                    <input value={r?.materials[0]?.marModelName || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Bottom Model</p>
                    <input
                      value={r?.materials[0]?.bottomModelName || "-NIL-"}
                    />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Colour</p>
                    <input value={r?.materials[0]?.color || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Seat 1</p>
                    <input value={r?.seat1 || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Seat 2</p>
                    <input value={r?.seat2 || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Seat 3</p>
                    <input value={r?.seat3 || "-NIL-"} />
                  </div>
                </div>
              ) : r?.type === "pant" ? (
                <div className="view-order-status-content-input-container">
                  <div className="global-single-input view-order-single-view-status">
                    <p>Length</p>
                    <input value={r?.length} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Inside</p>
                    <input value={r?.inside} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Waist</p>
                    <input value={r?.waist} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Seat</p>
                    <input value={r?.seat} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Thigh</p>
                    <input value={r?.thigh} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Bottom</p>
                    <input value={r?.bottom} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Ply</p>
                    <input value={r?.ply} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Belt</p>
                    <input value={r?.belt} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Side Pocket</p>
                    <input value={r?.sidePocket} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Back Pocket</p>
                    <input value={r?.backPocket} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Pleat</p>
                    <input value={r?.pleat} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Loops</p>
                    <input value={r?.loops} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Remarks</p>
                    <input value={r?.materials[0]?.remarks} />
                  </div>
                </div>
              ) : r?.type === "coat" ? (
                <div className="view-order-status-content-input-container">
                  <div className="global-single-input view-order-single-view-status">
                    <p>Length</p>
                    <input value={r?.length} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Shoulder</p>
                    <input value={r?.shoulder} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Sleeve</p>
                    <input value={r?.sleeve} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Body</p>
                    <input value={r?.body} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Waist</p>
                    <input value={r?.waist} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Seat</p>
                    <input value={r?.seat} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Front</p>
                    <input value={r?.front} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Back</p>
                    <input value={r?.back} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Sleeve Loose</p>
                    <input value={r?.sleeveLoose} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>FP Button</p>
                    <input value={r?.fpButton} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Back Open</p>
                    <input value={r?.backOpen} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Remarks</p>
                    <input value={r?.materials[0]?.remarks} />
                  </div>
                </div>
              ) : r?.type === "sharvani" ? (
                <div className="view-order-status-content-input-container">
                  <div className="global-single-input view-order-single-view-status">
                    <p>Length</p>
                    <input value={r?.length || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Shoulder</p>
                    <input value={r?.shoulder || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Sleeve Full</p>
                    <input value={r?.sleeveFull || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Chest</p>
                    <input value={r?.chest || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>waist</p>
                    <input value={r?.waist || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Body</p>
                    <input value={r?.body || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Neck</p>
                    <input value={r?.neck || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Cup</p>
                    <input value={r?.materials[0]?.cupName || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Regal</p>
                    <input value={r?.regal || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Knee Loose</p>
                    <input value={r?.kneeLoose || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Disco</p>
                    <input value={r?.disco || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Canvas</p>
                    <input value={r?.canvas || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Notes</p>
                    <input value={r?.materials[0]?.remarks || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Stitching</p>
                    <input value={r?.stitchingName || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Mar Model</p>
                    <input value={r?.materials[0]?.marModelName || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Bottom Model</p>
                    <input
                      value={r?.materials[0]?.bottomModelName || "-NIL-"}
                    />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Colour</p>
                    <input value={r?.materials[0]?.color || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Seat 1</p>
                    <input value={r?.seat1 || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Seat 2</p>
                    <input value={r?.seat2 || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Seat 3</p>
                    <input value={r?.seat3 || "-NIL-"} />
                  </div>
                </div>
              ) : r?.type === "safari" ? (
                <div className="view-order-status-content-input-container">
                  <div className="global-single-input view-order-single-view-status">
                    <p>Length</p>
                    <input value={r?.length || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Shoulder</p>
                    <input value={r?.shoulder || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Sleeve Full</p>
                    <input value={r?.sleeveFull || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Chest</p>
                    <input value={r?.chest || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>waist</p>
                    <input value={r?.waist || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Body</p>
                    <input value={r?.body || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Neck</p>
                    <input value={r?.neck || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Cup</p>
                    <input value={r?.materials[0]?.cupName || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Regal</p>
                    <input value={r?.regal || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Knee Loose</p>
                    <input value={r?.kneeLoose || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Disco</p>
                    <input value={r?.disco || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Canvas</p>
                    <input value={r?.canvas || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Remarks</p>
                    <input value={r?.materials[0]?.remark || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Stitching</p>
                    <input value={r?.materials[0]?.stitchingName || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Mar Model</p>
                    <input value={r?.materials[0]?.marModelName || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Bottom Model</p>
                    <input
                      value={r?.materials[0]?.bottomModelName || "-NIL-"}
                    />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Colour</p>
                    <input value={r?.materials[0]?.color || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Seat 1</p>
                    <input value={r?.seat1 || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Seat 2</p>
                    <input value={r?.seat2 || "-NIL-"} />
                  </div>
                  <div className="global-single-input view-order-single-view-status">
                    <p>Seat 3</p>
                    <input value={r?.seat3 || "-NIL-"} />
                  </div>
                </div>
              ) : undefined}

              {/* ... */}
              <div className="print-cutting-slip-view-input-container">
                <div className="global-single-input auto-complete">
                  <p>Cutting Master</p>

                  <Autocomplete
                    size="small"
                    multiple
                    options={allActiveEmpList || [{ staff_name: "No data" }]}
                    disableCloseOnSelect
                    getOptionLabel={(option) => `${option?.staff_name}`}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option?.staff_name}
                      </li>
                    )}
                    // style={{ width: 500 }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="select cutters" />
                    )}
                    onChange={assignCuttingMasterAndTailor(r, "cuttingMaster")}
                  />
                </div>
                <div className="global-single-input auto-complete">
                  <p>Tailor</p>
                  {/* <input
                    value={r?.tailor}
                    onClick={() => setPrintCuttingSlipTailorPopup(true)}
                    placeholder="Add Tailor"
                  /> */}
                  <Autocomplete
                    size="small"
                    multiple
                    options={allActiveEmpList || [{ staff_name: "No data" }]}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option?.staff_name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option?.staff_name}
                      </li>
                    )}
                    // style={{ width: 500 }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="select tailors" />
                    )}
                    onChange={assignCuttingMasterAndTailor(r, "tailor")}
                    // onChange={(e,newValue)=>setSelectedCutter(newValue)}
                  />
                </div>
                {/* <div className="global-single-input">
                  <p>Product</p>
                  <Select></Select>
                </div> */}
                {/* <Tooltip
                  title="update cutting master and tailor"
                  arrow
                  placement="right"
                >
                  <button className="btn btn-primary tailor-update-btn">
                    Update
                  </button>
                </Tooltip> */}
              </div>
            </div>
          ))}

          <div className="print-cutting-slip-btn-container">
            <button
              onClick={
                isShortCut
                  ? closeShortCut
                  : () => setPrintCuttingSlipView(false)
              }
              className="btn btn-secondary-outlined"
            >
              Cancel
            </button>
            {/* <button className='btn btn-primary'>Print</button> */}
          </div>
        </div>
      </Dialog>

      {/* print cutting slip single view add cutting master pop up */}
      <AddTailorPopup
        open={printCuttingSlipCuttingMasterPopup}
        label="Add Cutting Master"
        cancelBtn={() => setPrintCuttingSlipCuttingMasterPopup(false)}
      >
        <tr>
          <td>01</td>
          <td>Deepak</td>
          <td>
            <input type="checkbox" />
          </td>
        </tr>
      </AddTailorPopup>

      {/* print cutting slip single view add tailor popup  */}
      <AddTailorPopup
        open={printCuttingSlipTailorPopup}
        label="Add Tailor"
        cancelBtn={() => setPrintCuttingSlipTailorPopup(false)}
      >
        <tr>
          <td>01</td>
          <td>Deepak</td>
          <td>
            <input type="checkbox" />
          </td>
        </tr>
      </AddTailorPopup>

      {/* thob cutting slip */}
      <div 
            style={{ display: "none" }}
      >
        <style type="text/css" media="print">
          {
            "\
           @page { size: landscape; }\
          "
          }
        </style>
        <div ref={(el) => (thobCuttingSlip = el)}>
        {
          cuttingSlipTemplate?.template==="template1"? 
          
          
           <ThobCuttingSlip
            qr={generatedQrCode?.qrcode}
            cuttingSlipData={cuttingSlipNewModel}
            printingObject={printingTypeObj}
          /> 
       
         
           :cuttingSlipTemplate?.template==="template2"?
          <ThobCuttingSlip1
          qr={generatedQrCode?.qrcode}
          cuttingSlipData={cuttingSlipNewModel}
          printingObject={printingTypeObj}
          />  
          :undefined
        } 
       
          
        </div>
      </div>

      {/* jalabia cutting slip */}
      <div 
          style={{ display: "none" }}
      >
        <div ref={(el) => (jalabiaCuttingSlip = el)}>
          {
             cuttingSlipTemplate?.template==="template1"? 
             <JalabiaCuttingSlip
             qr={generatedQrCode?.qrcode}
             cuttingSlipData={cuttingSlipNewModel}
             printingObject={printingTypeObj}
          />
            :cuttingSlipTemplate?.template==="template2"?
            <JalabiaCuttingSlip1
             qr={generatedQrCode?.qrcode}
             cuttingSlipData={cuttingSlipNewModel}
             printingObject={printingTypeObj}
           />
           :undefined
          } 
         
        </div>
      </div>

      {/* salwar cutting slip */}
      <div 
           style={{ display: "none" }}
      >
        <div ref={(el) => (salwarCuttingSlip = el)}>
          {
            cuttingSlipTemplate?.template==="template1"? 
            <SalwarCuttingSlip
            qr={generatedQrCode?.qrcode}
            cuttingSlipData={cuttingSlipNewModel}
            printingObject={printingTypeObj}
          />
           :cuttingSlipTemplate?.template==="template2"?
             <SalwarCuttingSlip1
             qr={generatedQrCode?.qrcode}
            cuttingSlipData={cuttingSlipNewModel}
             printingObject={printingTypeObj}
             />
             :undefined
           } 
         
        </div>
      </div>

      {/* abaya cutting slip */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (abayaCuttingSlip = el)}>
          <AbayaCuttingSlip
            qr={generatedQrCode?.qrcode}
            cuttingSlipData={cuttingSlipNewModel}
            printingObject={printingTypeObj}
          />
        </div>
      </div>

      {/* churidhar cutting slip */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (churidharCuttingSlip = el)}>
          <ChuridharCuttingSlip
            qr={generatedQrCode?.qrcode}
            cuttingSlipData={cuttingSlipNewModel}
            printingObject={printingTypeObj}
          />
        </div>
      </div>

      {/* shirt cutting slip */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (shirtCuttingSlip = el)}>
          <ShirtCuttingSlip
            qr={generatedQrCode?.qrcode}
            cuttingSlipData={cuttingSlipNewModel}
            printingObject={printingTypeObj}
          />
        </div>
      </div>

      {/* pant cutting slip */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (pantCuttingSlip = el)}>
          <PantCuttingSlip
            qr={generatedQrCode?.qrcode}
            cuttingSlipData={cuttingSlipNewModel}
            printingObject={printingTypeObj}
          />
        </div>
      </div>

      {/*coat cutting slip */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (coatCuttingSlip = el)}>
          <CoatCuttingSlip
            qr={generatedQrCode?.qrcode}
            cuttingSlipData={cuttingSlipNewModel}
            printingObject={printingTypeObj}
          />
        </div>
      </div>

      {/*sharwani cutting slip */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (sharwaniCuttingSlip = el)}>
          <SharwaniCuttingSlip
            qr={generatedQrCode?.qrcode}
            cuttingSlipData={cuttingSlipNewModel}
            printingObject={printingTypeObj}
          />
        </div>
      </div>

      {/* safari cutting slip */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (safariCuttingSlip = el)}>
          <SafariCuttingSlip
            qr={generatedQrCode?.qrcode}
            cuttingSlipData={cuttingSlipNewModel}
            printingObject={printingTypeObj}
          />
        </div>
      </div>
    </div>
  );
};
