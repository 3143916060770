import { createSlice } from "@reduxjs/toolkit";

export const allCustomerListSlice = createSlice({
  name: "allCustomerListSlice",
  initialState: {
    value: undefined,
    filteredList:undefined
  },
  reducers: {
    update_all_customer_list: (state, action) => {
      state.value = action.payload.customerList;
    },
    update_filtered_customer_list: (state, action) => {
      state.filteredList = action.payload.filterCustomer;
    },
  },
});

export const { update_all_customer_list,update_filtered_customer_list } = allCustomerListSlice.actions;

export default allCustomerListSlice.reducer;