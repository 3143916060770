import { createSlice } from "@reduxjs/toolkit";

export const viewSmsHeaderSlice=createSlice({
    name:'smsHeaderSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_sms_header:(state,action)=>{
            state.value=action.payload.smsHeader
        }
    }
})
export const {get_sms_header} = viewSmsHeaderSlice.actions;
export default viewSmsHeaderSlice.reducer;