import React, { useRef, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Skeleton,
} from "@mui/material";
import ReactToPrint from "react-to-print";

const PendingRecurringPost = () => {
  const [selectAll, setSelectAll] = useState(false);
  const [renderData, setRenderData] = useState([
    {
      PostingDate: "2020-01-01",
      PostingNumber: 1,
      Description: "Description",
      NextExecutionDate: "2020-01-01",
      Instance: "Instance",
      action: false,
    },
    {
      PostingDate: "2020-01-01",
      PostingNumber: 2,
      Description: "Description",
      NextExecutionDate: "2020-01-01",
      Instance: "Instance",
      action: true,
    },
    {
      PostingDate: "2020-01-01",
      PostingNumber: 2,
      Description: "Description",
      NextExecutionDate: "2020-01-01",
      Instance: "Instance",
      action: true,
    },
  ]);

  const handleSelectAll = (event) => {
    const checked = event.target.checked;
    setSelectAll(checked);

    const updatedRenderData = renderData.map((item) => ({
      ...item,
      action: checked,
    }));
    setRenderData(updatedRenderData);
  };

  const handleSelectCheckBox = (event, index) => {
    const checked = event.target.checked;
    const updatedRenderData = [...renderData];
    updatedRenderData[index].action = checked;
    setRenderData(updatedRenderData);

    const allChecked = updatedRenderData.every((item) => item.action);
    setSelectAll(allChecked);
  };

  const isLoading = false;
  const componentRef = useRef();
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  return (
    <div
      style={{
        background: "white",
        padding: "10px 20px",
        width: "100%",
      }}
      className="accounting-voucher-menu-sections recurring-posting-list"
      id="recurring-posting-list"
    >
      <h3>Pending Recurring Post</h3>

      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="gender"
          name="gender"
          value={selectedOption}
          onChange={handleOptionChange}
        >
          <FormControlLabel
            value="Current"
            control={<Radio style={{ color: "black" }} />}
            label="Current"
          />
          <FormControlLabel
            value="Recurring Post Date"
            control={<Radio style={{ color: "black" }} />}
            label="Recurring Post Date"
          />
        </RadioGroup>
      </FormControl>

      <div>
        <div className="accounting-voucher-menu-sections">
          <div className="global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Posting Date</th>
                  <th>Posting Number</th>
                  <th>Description</th>
                  <th>Next Execution Date</th>
                  <th>Instance</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    {[...Array(7)].map((_, index) => (
                      <td key={index}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    ))}
                  </tr>
                ) : renderData.length !== 0 ? (
                  renderData.reverse().map((r, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{r.PostingDate}</td>
                      <td>{r.PostingNumber}</td>
                      <td>{r.Description}</td>
                      <td>{r.NextExecutionDate}</td>
                      <td>{r.Instance}</td>
                      <td>
                        <input
                          type="checkbox"
                          checked={r.action}
                          onChange={(event) => handleSelectCheckBox(event, i)}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>No Records</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="input-check-box">
          <div>
            <h5>Select All</h5>
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
            />
          </div>
        </div>
      </div>

      <div className="button-container">
        <div>
          <IconButton>
            <i className="bi bi-pencil-square edit-icon"></i>
          </IconButton>

          <ReactToPrint
            trigger={() => (
              <IconButton>
                <i className="bi bi-printer printer-icon"></i>
              </IconButton>
            )}
            content={() => componentRef.current}
          />
        </div>
        <div>
          <button style={{ backgroundColor: "green" }} className="stop-execute">
            Execute
          </button>
          <button className="cancel">Cancel</button>
        </div>
      </div>

      <div style={{ display: "none" }}>
        <div ref={componentRef} style={{ padding: "10px" }}>
          <h4
            style={{
              whiteSpace: "nowrap",
              textAlign: "center",
              width: "100%",
              textTransform: "uppercase",
            }}
          >
           Pending Recurring Post
          </h4>
          <div className="accounting-voucher-menu-sections">
            <div className="global-table-container">
              <table className="global-table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Posting Date</th>
                    <th>Posting Number</th>
                    <th>Description</th>
                    <th>Next Execution Date</th>
                    <th>Instance</th>
                  </tr>
                </thead>
                <tbody>
                  {renderData.map((r, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{r.PostingDate}</td>
                      <td>{r.PostingNumber}</td>
                      <td>{r.Description}</td>
                      <td>{r.NextExecutionDate}</td>
                      <td>{r.Instance}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingRecurringPost;
