import React from "react";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { IconButton } from "@mui/material";

const StyledButton = styled(IconButton)({
  padding: "1em",
  height: "0.5em",
  width: "0.5em",
  color: "black",
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
});

const MuiBackBtn = ({ onClick }) => {
  return (
    <Tooltip title="Go Back" arrow>
      <StyledButton onClick={() => onClick()}>
        <ArrowBackIosNewIcon fontSize="medium" />
      </StyledButton>
    </Tooltip>
  );
};

export default MuiBackBtn;
