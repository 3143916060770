import { createSlice } from "@reduxjs/toolkit";

export const stockAdjIdSlice=createSlice({
    name:'stockAdjIdSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_stock_adj_id:(state,action)=>{
            state.value=action.payload.stockAdjId
        }
    }
})

export const {update_stock_adj_id}=stockAdjIdSlice.actions
export default stockAdjIdSlice.reducer