import { createSlice } from "@reduxjs/toolkit";

export const cuttingSlipModelSlice=createSlice({
    name:"cuttingSlipSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        get_cutting_SlipModel:(state,action)=>{
            state.value=action.payload.cuttingSlipTemplate
        }
    }
})
export const {get_cutting_SlipModel} = cuttingSlipModelSlice.actions;
export default cuttingSlipModelSlice.reducer