import React, { useEffect, useState } from 'react'

const StockMoveSinglePrint = (props) => {
    const { data, formValues,lineTotals } = props
    // const [symbol,setSymbol] =useState(null)
    const decimalPosition = localStorage.getItem("decimalPosition");
  const decimalPositionStock = localStorage.getItem("decimalPositionStock");
  const [unitCost,setUnitCost]=useState(0)
  useEffect(()=>{
    if(data.length!==0 && data[0]?.stockMoves.length!==0){
        setUnitCost(data[0]?.cost)
      }
  },[])
  return (
    <div className="print-main-div">
    <div className='header-div'>
        <div>
            <img src={formValues?.companyLogo} alt="" style={{height:"62px"}}/>
        </div>
        <div>
            <h1 style={{ fontSize: "larger", margin: "0" }}>{formValues?.label}</h1>
            {/* <p style={{margin:"0"}}>aaaaaaa</p> */}
        </div>
        <div>
            <h1 style={{ fontSize: "small", margin: "0" }}>companyName</h1>
            <p style={{ margin: "0", fontSize: "smaller" }}>{formValues?.companyName}</p>
            <p style={{ margin: "0", fontSize: "smaller" }}>{formValues?.companyAddress}</p>
            <p style={{ margin: "0", fontSize: "smaller" }}>{formValues?.companyContact}</p>
        </div>
    </div>

    <div className="global-report-table-container" style={{ overflow: "visible" }} >
        <table>
            <thead>
                <tr>
                <th>Date</th>
                <th>Particular</th>
                <th>In</th>
                <th>Out</th>
                <th>Closing</th>
                {/* <th>Unit Cost</th> */}
                <th>Value</th>
                </tr>
            </thead>
            <tbody>
                <>
                <tr style={{ height: "38px" }}>
                    <td style={{ backgroundColor: "#e7e7e7" }}>&nbsp;</td>
                    <td style={{ backgroundColor: "#e7e7e7", fontSize: "1rem" }}>Opening Balance</td>
                    <td style={{ backgroundColor: "#e7e7e7" }}>{data[0]?.openingBalance?.toFixed(decimalPositionStock)}</td>
                    <td style={{ backgroundColor: "#e7e7e7" }}>{0?.toFixed(decimalPositionStock)}</td>
                    <td style={{ backgroundColor: "#e7e7e7" }}>{data[0]?.openingBalance?.toFixed(decimalPosition)}</td>
                    {/* <td style={{ backgroundColor: "#e7e7e7" }}>&nbsp;</td> */}
                    <td style={{ backgroundColor: "#e7e7e7" }}>&nbsp;{isNaN((Number(unitCost) * Number(data[0]?.openingBalance?.toFixed(decimalPositionStock)))?.toFixed(decimalPosition)) ? (0.00) : (Number(unitCost) * Number(data[0]?.openingBalance?.toFixed(decimalPositionStock)))?.toFixed(decimalPosition)}</td>
                </tr>
                {data[0]?.stockMoves?.map((item, index) => (
                    <tr key={index} >
                        <td style={{ textAlignLast: "start", paddingLeft: "10px" }}>{item.convertedDate}</td>
                        <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.reference}</td>
                        <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.inQuantity?.toFixed(decimalPositionStock)}</td>
                        <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.outQuantity?.toFixed(decimalPositionStock)}</td>
                        <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.closingBalance?.toFixed(decimalPosition)}</td>
                        {/* <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.unitCost?.toFixed(decimalPosition)}</td> */}
                        <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.value?.toFixed(decimalPosition)}</td>
                    </tr>
                ))}
                </>
            </tbody>
            <tfoot>
            <tr>
                <th colSpan={2}>Total</th>
                <th>{lineTotals?.totalIn}</th>
                <th>{lineTotals?.totalOut}</th>
                <th>{lineTotals?.totalClosing}</th>
                {/* <th>{singleView[0]?.stockMoves?.reduce((acc, row) => acc + row.closingBalance, 0)?.toFixed(decimalPosition)}</th> */}
                {/* <th>{symbol}&nbsp;{singleView[0]?.stockMoves?.reduce((acc, row) => acc + row.unitCost, 0)?.toFixed(decimalPosition)}</th> */}
                <th>{lineTotals?.totalValue}</th>
              </tr>
            </tfoot>
        </table>
    </div>
</div>
  )
}

export default StockMoveSinglePrint