import { Typography } from "@mui/material";
import React, { useState } from "react";
import "../../../../../css/Staff/HRM/AddEmployee/addEmployee.css";
import { BankDetails } from "./BankDetails";
import { CompanyInfo } from "./CompanyInfo";
import { PersonalInfo } from "./PersonalInfo";

export const AddEmployee = () => {
  const [activeTab, setActiveTab] = useState("personalInfo");

  const personalInfoMenuClick = () => {
    document.getElementById("personalInfoBtn").classList.add("active");
    document.getElementById("companyInfoBtn").classList.remove("active");
    document.getElementById("bankDetailsBtn").classList.remove("active");
    setActiveTab("personalInfo");
  };
  const companyInfoMenuClick = () => {
    document.getElementById("personalInfoBtn").classList.remove("active");
    document.getElementById("companyInfoBtn").classList.add("active");
    document.getElementById("bankDetailsBtn").classList.remove("active");
    setActiveTab("companyInfo");
  };
  const bankDetailsMenuClick = () => {
    document.getElementById("personalInfoBtn").classList.remove("active");
    document.getElementById("companyInfoBtn").classList.remove("active");
    document.getElementById("bankDetailsBtn").classList.add("active");
    setActiveTab("bankDetails");
  };





  return (

    <div className="global-page-parent-container">
      <p className="breadcrumb">Staff &gt; HRM &gt; Add Employee </p>
      <div className="company-settings-container">
        <div className="company-settings-tab-container">
          <div className="company-settings-tab-menu-container">
            <div className="company-settings-tab-menus-container add-employee-menu-container">
              <div
                className="tab-menu-button active"
                id="personalInfoBtn"
                onClick={personalInfoMenuClick}
              >
                <Typography fontSize={"0.9rem"} fontWeight={600}>
                  Personal Info
                </Typography>
              </div>
              <div
                className="tab-menu-button"
                id="companyInfoBtn"
                onClick={companyInfoMenuClick}
              >
                <Typography fontSize={"0.9rem"} fontWeight={600}>
                  Company Info
                </Typography>
              </div>
              <div
                className="tab-menu-button"
                id="bankDetailsBtn"
                onClick={bankDetailsMenuClick}
              >
                <Typography fontSize={"0.9rem"} fontWeight={600}>
                  Bank Details
                </Typography>
              </div>
              <div
                style={{ cursor: "not-allowed" }}
                className="tab-menu-button"
                id="loanDetailsBtn"
                // onClick={loanDetailsMenuClick}
              >
                <Typography
                  fontSize={"0.9rem"}
                  fontWeight={600}
                  sx={{ color: "#edeef0" }}
                >
                  Loan Details
                </Typography>
              </div>
              <div
                style={{ cursor: "not-allowed" }}
                className="tab-menu-button"
                id="leaveDetailsBtn"
                // onClick={leaveDetailsMenuClick}
              >
                <Typography
                  fontSize={"0.9rem"}
                  fontWeight={600}
                  sx={{ color: "#edeef0" }}
                >
                  Leave Details
                </Typography>
              </div>
              <div
                style={{ cursor: "not-allowed" }}
                className="tab-menu-button"
                id="paySlipsBtn"
                // onClick={paySlipMenuClick}
              >
                <Typography
                  fontSize={"0.9rem"}
                  fontWeight={600}
                  sx={{ color: "#edeef0" }}
                >
                  Payslips
                </Typography>
              </div>
              <div
                style={{ cursor: "not-allowed" }}
                className="tab-menu-button"
                id="documentBtn"
                // onClick={documentMenuClick}
              >
                <Typography
                  fontSize={"0.9rem"}
                  fontWeight={600}
                  sx={{ color: "#edeef0" }}
                >
                  Document Details
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div>
          {activeTab === "personalInfo" ? (
            <PersonalInfo navigateToNextTab={companyInfoMenuClick}/>
          ) : activeTab === "companyInfo" ? (
            <CompanyInfo moveToBack={personalInfoMenuClick} moveToNext={bankDetailsMenuClick}/>
          ) : activeTab === "bankDetails" ? (
            <BankDetails moveToBack={companyInfoMenuClick}/>
          ) : undefined}
        </div>
      </div>
    </div>
  );
};
