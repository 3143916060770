import { createSlice } from "@reduxjs/toolkit";
import store from "../../../../Redux/store";
import axios from "axios";
import { HEADERS } from "../../../../API/UrlAndPaths";


export const posReportSlice=createSlice({
    name:"posReportsSlice",
    initialState:{
        reportsList: undefined,
    },
    reducers: {
        get_pos_reports_list: (state, action) => {
          state.reportsList = action.payload.posReportsData;
        }
      },
})
export const {get_pos_reports_list} = posReportSlice.actions;

export default posReportSlice.reducer;


