import { createSlice } from "@reduxjs/toolkit";

export const customerIdSlice = createSlice({
  name: "customerIdSlice",
  initialState: {
    value: undefined,
  },
  reducers: {
    update_customer_id: (state, action) => {
      state.value = action.payload.customerId;
    },
  },
});

export const { update_customer_id } = customerIdSlice.actions;

export default customerIdSlice.reducer;
