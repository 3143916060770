import React from "react";

const TextAreaInputBox = ({
  label,
  onChange,
  value,
  disabled,
  placeholder,
  containerStyle,
  inputBoxStyle,
  type,
  rows,
  cols,
}) => {
  return (
    <div
      className="global-single-input add-purchase-input auto-complete"
      style={containerStyle}
    >
      <p>{label}</p>
      <textarea
        rows={rows ? rows : "4"}
        cols={cols ? cols : "50"}
        style={inputBoxStyle}
        type={type ? type : "text"}
        disabled={disabled ? disabled : false}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export default TextAreaInputBox;
