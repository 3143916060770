import { createSlice } from "@reduxjs/toolkit";

export const jalabiaModelSlice=createSlice({
    name:"jalabiaModelSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        update_jalabia_model:(state,action)=>{
            state.value=action.payload.jalabiaModel
        }
    }
})

export const {update_jalabia_model}=jalabiaModelSlice.actions
export default jalabiaModelSlice.reducer