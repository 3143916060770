import {createSlice} from '@reduxjs/toolkit'

export const singleCustomerOrderSlice=createSlice({
    name:"singleCustomerOrderSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        get_single_customer_order:(state,action)=>{
            state.value=action.payload.singleCustomerOrder
        },
    },
})
export const {get_single_customer_order} = singleCustomerOrderSlice.actions;
export default singleCustomerOrderSlice.reducer;
