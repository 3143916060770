 import { createSlice } from "@reduxjs/toolkit";

 export const allCustomerListWithFilterSlice=createSlice({
    name:'allCustomerWithFilter',
    initialState:{
        value:undefined
    },
    reducers:{
        get_all_customer_Filter:(state,action)=>{
            state.value=action.payload.allCustomerFilterData
        }
    }
 })
 export const {get_all_customer_Filter} = allCustomerListWithFilterSlice?.actions;
 export default allCustomerListWithFilterSlice?.reducer