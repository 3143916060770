import axios from 'axios'
import {HEADERS,HEADER_QUERY} from '../../API/UrlAndPaths'
import store from '../../Redux/store'
import {get_add_expense_type} from '../../Redux/Expense/AddExpenseType/addExpenseTypeSlice'
import {get_add_expense_type_doc_no} from '../../Redux/Expense/AddExpenseType/addExpenseTypeDocNoSlice'

// add expense doc no api call
export const addExpenseTypeDocNoAPICall= async ()=>{
 await axios.get('expense/generateexpensetypeno',HEADERS)
 .then((res)=>{
    if(res.status===200){
      store.dispatch(get_add_expense_type_doc_no({addExpenseTypeDocNoData:res.data}))
    }
 })
 .catch((err)=>{
  store.dispatch(get_add_expense_type_doc_no({addExpenseTypeDocNoData:undefined}))
  console.log(err.response);
 })
}

// view expense type
export const viewAddExpenseTypeAPICall= async (setIsLoading,setErrorSnack,setSnackMsg)=>{
  if(setIsLoading!==undefined) setIsLoading(true)
  await axios.post("expense/viewexpenseType",{},HEADERS)
  .then((res)=>{
    if(res.status===200){
        store.dispatch(get_add_expense_type({addExpenseTypeData:res.data}))
        if(setIsLoading!==undefined) setIsLoading(false)
      }
  })
  .catch((err)=>{
    store.dispatch(get_add_expense_type({addExpenseTypeData:undefined}))
    console.log(err.response);
    switch (err.response.status) {
      case 404:
          setSnackMsg('No data available..')
        break;
      case 500:
          setSnackMsg('Server error!!')
        break;
      case 401:
          setSnackMsg('Unauthorized!!')
        break;
      case 403:
          setSnackMsg('Forbidden!!')
        break;
    
      default:
          setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
        break;
    }
    setErrorSnack(true)
    if(setIsLoading!==undefined) setIsLoading(false) 
  })
}

// add expense type
export const addExpenseTypeAPICall= async (
  body,
  updateListener,
  setSuccessSnackOpen,
  setErrorSnackOpen,
  setSnackMsg,
  clearStates,setLoadingForm
  )=>{
    setLoadingForm!==undefined && setLoadingForm(true)
  await axios.post("expense/addexpenseType",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      setSuccessSnackOpen(true)
      setSnackMsg('Expense Type Added Successfully')
      updateListener()
      clearStates()
    setLoadingForm!==undefined && setLoadingForm(false)

    }
  })
  .catch((err)=>{
    setLoadingForm!==undefined && setLoadingForm(false)

    setErrorSnackOpen(true);
    if (err.response === undefined) {
      setSnackMsg("Network Connection error!!");
    }
    if (err.response.status === 409) {
      setSnackMsg("Category Already Exist");
    }

    if (err.response.status === 401) {
      setSnackMsg("Unauthorized");
    }
    if (err.response.status === 403) {
      setSnackMsg("Forbidden");
    }

   
    console.log(err.response);
  })
}

// edit expense type
export const editExpenseTypeAPICall= async(body,setSuccessSnackOpen,setSnackMsg,updateListener,editClearState,setLoadingForm)=>{
  setLoadingForm!==undefined && setLoadingForm(true)

  await axios.put("expense/editexpenseType",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      setSuccessSnackOpen(true)
      setSnackMsg('Updated Successfully')
      updateListener()
      setLoadingForm!==undefined && setLoadingForm(false)
      editClearState()
    }
  })
  .catch((err)=>{
    setLoadingForm!==undefined && setLoadingForm(false)
    console.log(err.response);
  })
}

// delete expense type
export const deleteExpenseTypeAPICall= async (_id,updateListener)=>{
  await axios.delete("expense/deleteexpenseType",{
    headers: HEADER_QUERY,
    data: {
      _id,
    },
  })
  .then((res)=>{
    if(res.status===200){
      updateListener()
    }
  })
  .catch((err)=>{
    console.log(err.response);
  })
}