import React, { useRef, useState } from "react";
import "../../../../css/Report/jobCompletionReport.css"
import { Autocomplete, IconButton, Skeleton, TextField } from "@mui/material";
import { useEffect } from "react";
import { viewAllBranchesAPICall } from "../../../../API/Settings/Company Settings/companySettingsAPI";
import { useSelector } from "react-redux";
import { getWorkOrderNosAPICall } from "../../../../API/Orders/workOrderAPI";
import { viewAllCustomersAPICall } from "../../../../API/Customer/customerAPI";
import { getAllActiveEmployeesAPICall } from "../../../../API/Staff/HRM/employeeAPI";
import { viewJobCompletionReportAPICall } from "../../../../API/Report/jobCompletionReportAPI";
import { today,getCurrentTime,convertDateFormat } from "../../../../Js/Date";
import ReactToPrint from "react-to-print";
import { LoadingForm } from "../../../Single Components/LoadingForm";
export const JobCompletionReport =()=>{

    let jobCompletionReportPrint = useRef();
    const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
    const workOrderNoList = useSelector((state) => state.workOrderNosSlice.value);
    const allCustomerList = useSelector((state) => state.allCustomerListSlice.value);
     // login response
    const userRole = useSelector((state) => state.userRoleSlice.value);
    const loginResponse = useSelector((state) => state.loginResponseSlice.value);
    const allActiveEmpList = useSelector(
        (state) => state.allActiveEmployeeSlice.value
      );
    const jobComReportList = useSelector(
        (state) => state.jobCompletionReportSlice.value
      );
    const [isLoading, setIsLoading] = useState(false);
    const [filteredTailorList, setFilteredTailorList] = useState([]);
    const [filteredCuttingMasterList, setFilteredCuttingMasterList] = useState([]);
    const jobCompletionReportInitialForm={
        branchId:"",
        branchPk:null,
        workOrderNo:null,
        cusId:null,
        tailorId:null,
        cuttingMasterId:null,
        fromDate:null,
        toDate:null
    }
    const [jobComReportBody,setJobComReportBody]=useState(jobCompletionReportInitialForm)
    const getReportFilterChange=(key)=>(e,newValue)=>{
        const{value}=e.target 
        if(key==="branch"){
            if(newValue===null){
                setJobComReportBody({...jobComReportBody,branchPk:null})
            }
            else{
                setJobComReportBody({...jobComReportBody,branchId:newValue?.storeCode,branchPk:newValue?._id})
                getWorkOrderNosAPICall({branchId:newValue?.storeCode})
                viewAllCustomersAPICall({branchId:newValue?.storeCode})
            }
        }
        if(key==="workorderNo"){
            if(newValue===null){
               setJobComReportBody({...jobComReportBody,workOrderNo:null})
            }
            else{
               setJobComReportBody({...jobComReportBody,workOrderNo:newValue?._id})
            }
        }
        if(key==="customer"){
            if(newValue===null){
                setJobComReportBody({...jobComReportBody,cusId:null})
             }
             else{
                setJobComReportBody({...jobComReportBody,cusId:newValue?._id})
             }
        }
        if(key==="tailor"){
            
            if(newValue===null){
                setJobComReportBody({...jobComReportBody,tailorId:null})
             }
             else{
                setJobComReportBody({...jobComReportBody,tailorId:newValue?._id})
             }
        }
        if(key==="cuttingMaster"){
            
            if(newValue===null){
                setJobComReportBody({...jobComReportBody,cuttingMasterId:null})
             }
             else{
                setJobComReportBody({...jobComReportBody,cuttingMasterId:newValue?._id})
        }
        }
        if(key==="fromDate"){
            
            if(value===""){
                setJobComReportBody({...jobComReportBody,fromDate:null})
             }
             else{
                setJobComReportBody({...jobComReportBody,fromDate:value})
        }
        }
        if(key==="toDate"){
            
            if(value===""){
                setJobComReportBody({...jobComReportBody,toDate:null})
             }
             else{
                setJobComReportBody({...jobComReportBody,toDate:value})
        }
        }
    }

    // form data payload

    const jobCompletionReportFormData=new FormData();
    
    jobComReportBody.workOrderNo !==null && jobCompletionReportFormData.append("orderNo",jobComReportBody.workOrderNo);
    jobComReportBody.cusId !==null &&  jobCompletionReportFormData.append("cusId",jobComReportBody.cusId);
    jobComReportBody.fromDate !==null &&  jobCompletionReportFormData.append("fromDate",`${jobComReportBody.fromDate} 00:00:00`);
    jobComReportBody.toDate !==null &&  jobCompletionReportFormData.append("toDate",`${jobComReportBody.toDate} 23:59:59`);
    jobComReportBody.tailorId !==null &&  jobCompletionReportFormData.append("tailor",jobComReportBody.tailorId);
    jobComReportBody.cuttingMasterId !==null &&  jobCompletionReportFormData.append("cuttingMaster",jobComReportBody.cuttingMasterId);
    jobComReportBody.branchPk !==null &&  jobCompletionReportFormData.append("branchId",jobComReportBody.branchPk);
    userRole==="user" && jobCompletionReportFormData.append("branchId",loginResponse.branchPk);
    
    const jobComReportSubmitClick=()=>{
        viewJobCompletionReportAPICall(jobCompletionReportFormData,setIsLoading)
    }

    useEffect(()=>{
      //  viewJobCompletionReportAPICall(jobCompletionReportFormData,setIsLoading)
        viewAllBranchesAPICall()
        getWorkOrderNosAPICall()
        viewAllCustomersAPICall()
        getAllActiveEmployeesAPICall()
    },[])

    useEffect(() => {
        let filteredArray = allActiveEmpList?.filter((obj) => {
           if(jobComReportBody?.branchId === "-All"){
            if (obj.designationName.toLowerCase() === "tailor") {
              return obj;
             }
           }
           else{
            if(obj.branchId===jobComReportBody?.branchId || loginResponse?.data?.branchId){
              if (obj.designationName?.toLowerCase() === "tailor") {
                return obj;
               }
             }
           }
        });
        let filteredCuttingArray=allActiveEmpList?.filter((obj) => {
            if(jobComReportBody?.branchId === "-All"){
             if (obj.designationName.toLowerCase() === "cutting master") {
               return obj;
              }
            }
            else{
             if(obj.branchId===jobComReportBody?.branchId || loginResponse?.data?.branchId){
               if (obj.designationName?.toLowerCase() === "cutting master") {
                 return obj;
                }
              }
            }
         });
        setFilteredTailorList(filteredArray);
        setFilteredCuttingMasterList(filteredCuttingArray);
      }, [allActiveEmpList,jobComReportBody?.branchId]);

      useEffect(()=>{
        if(userRole!==undefined){
            userRole==="user" && 
            setJobComReportBody({...jobComReportBody,branchId:loginResponse?.branchId})
            getWorkOrderNosAPICall({branchId:loginResponse?.branchId})
            viewAllCustomersAPICall({branchId:loginResponse?.branchPk})
        }
      },[userRole,loginResponse])
    
    return(
        <div className="global-page-parent-container">
            <p className="breadcrumb">Report &gt; Job Completion Report</p>
            <div className="daily-report-top-container">
                <h3>Job Completion Report</h3>
                <hr className="global-hr"/>
                <div className="job-com-report-top-ctn-body">
                    {
                        userRole==="admin" &&
                        <div className="global-single-input auto-complete">
                            <p>Branch </p>
                            <Autocomplete
                            sx={{width:"100%"}}
                            options={allBranchesList || [{storeCode:"no",branchName:"data"}]}
                            getOptionLabel={(option)=>`${option?.storeCode}-${option?.branchName}`}
                            renderInput={(params)=>(
                                <TextField {...params} placeholder="Select a Branch"/>
                            )}
                            onChange={getReportFilterChange("branch")}
                            />
                        </div>
                    }
                    
                    <div className="global-single-input auto-complete">
                        <p>Workorder No</p>
                        <Autocomplete
                         sx={{width:"100%"}}
                         options={workOrderNoList || [""]}
                         getOptionLabel={(option)=>option?.orderNo}
                         renderInput={(params)=>(
                            <TextField {...params} placeholder="Select a workorder no"/>
                         )}
                         onChange={getReportFilterChange("workorderNo")}
                        />
                    </div>
                    <div className="global-single-input auto-complete">
                        <p>Customer</p>
                        <Autocomplete
                         sx={{width:"100%"}}
                         options={allCustomerList || [""]}
                         getOptionLabel={(option)=>option?.name}
                         renderInput={(params)=>(
                            <TextField {...params} placeholder="Select a customer"/>
                         )}
                         onChange={getReportFilterChange("customer")}
                        />
                    </div>
                    <div className="global-single-input auto-complete">
                        <p>Tailor</p>
                        <Autocomplete
                         sx={{width:"100%"}}
                         options={filteredTailorList || [""]}
                         getOptionLabel={(option)=>option?.staff_name}
                         renderInput={(params)=>(
                            <TextField {...params} placeholder="Select a tailor"/>
                         )}
                         onChange={getReportFilterChange("tailor")}
                        />
                    </div>
                    <div className="global-single-input auto-complete">
                        <p>Cutting Master</p>
                        <Autocomplete
                         sx={{width:"100%"}}
                         options={filteredCuttingMasterList || [""]}
                         getOptionLabel={(option)=>option?.staff_name}
                         renderInput={(params)=>(
                            <TextField {...params} placeholder="Select a cutting master"/>
                         )}
                         onChange={getReportFilterChange("cuttingMaster")}
                        />
                    </div>
                    <div className="global-single-input">
                        <p>From</p>
                        <input 
                        type="date"
                        value={jobComReportBody?.fromDate}
                        onChange={getReportFilterChange("fromDate")}
                        />
                    </div>
                    <div className="global-single-input">
                        <p>To</p>
                        <input type="date" 
                        value={jobComReportBody?.toDate}
                        onChange={getReportFilterChange("toDate")}
                        />
                    </div>
                    <button className="btn btn-primary job-com-report-top-ctn-btn" onClick={jobComReportSubmitClick}>Submit</button>
                </div>
            </div>
            <div className="global-table-container">
                <div className="job-com-table-icon-ctn">

                    <ReactToPrint
                      trigger={()=>(
                        <IconButton className="print-icon-container">
                            <i class="bi bi-printer printer-icon"></i>
                        </IconButton>
                      )}
                      content={()=>jobCompletionReportPrint}
                      pageStyle="@page { size: auto;  margin: 15mm 0 10mm 0; } }"
                    />
                    
                    <IconButton className="download-icon-container">
                        <i class="bi bi-arrow-down-circle download-icon"></i>
                    </IconButton>
                    <div className="commission-report-summary" style={{right:"60%"}}>
                        <input type="checkbox" id="jobCompletionSummary" />
                        <label htmlFor="jobCompletionSummary">Summary</label>
                    </div>
                </div>
                <table className="global-table">
                    <thead>
                        <tr>
                        <th>No</th>
                        <th>Workorder No</th>
                        <th>Prod Code</th>
                        <th>Prod Name</th>
                        <th>Type</th>
                        <th>Model</th>
                        <th>Length</th>
                        <th>Tailor Comm</th>
                        <th>Tailor </th>
                        <th>Used Qty</th>
                        <th>Wastage</th>
                        <th>Cutting Master</th>
                        <th>Completed Date</th>
                        <th>Completed Qty</th>
                        </tr>
                    </thead>
                
                    <tbody>
                        {
                            jobComReportList!==undefined?
                            jobComReportList?.map((r,i)=>(
                                <tr key={i}>
                                    <td>{i+1}</td>
                                    <td>{r?.workorderNo}</td>
                                    <td>{r?.productCode}</td>
                                    <td>{r?.productName}</td>
                                    <td>{r?.type}</td>
                                    <td>{r?.model}</td>
                                    <td>{r?.length}</td>
                                    <td>{r?.tailorCommission}</td>
                                    <td>{r?.tailor!==null&&
                                        r?.tailor.map((r,i)=>
                                        <p>{r}</p>
                                        )}</td>
                                    <td>{r?.usedQty}</td>
                                    <td>{r?.wastage}</td>
                                    <td>{r?.cutter!==null&&
                                            r?.cutter.map((r,i)=>
                                            <p>{r}</p>
                                        )}
                                    </td>
                                    <td>{convertDateFormat(r?.date)}</td>
                                    <td>{r?.completedQty}</td>
                                </tr>
                            ))
                            
                        :
                        <tr>
                            <td colSpan={14}>Search Records</td>
                        </tr>
                        }
                        
                    </tbody>
                    
                </table>
            </div>
        
        {/* job completion report print */}
        <div 
           style={{ display: "none" }}
      >
        <div ref={(el) => (jobCompletionReportPrint = el)} >
          <div className="print-view-top-container">
            <div
              className="print-view-top-left-container"
              style={{ width: "25%" }}
            >
              <h6>Printed On :{convertDateFormat(today)}&nbsp;{getCurrentTime()}</h6>
            </div>
            <div
              className="print-view-top-middle-container"
              style={{ width: "50%" }}
            >
              {userRole === "admin" ? (
                <h3>{loginResponse?.data?.profile?.companyName}</h3>
              ) : userRole === "user" ? (
                <h3>{loginResponse?.storeName}</h3>
              ) : undefined}

              <p>JOB COMPLETION REPORT</p>
            </div>
            <div
              className="print-view-top-right-container"
              style={{ width: "25%" }}
            >
              {jobComReportBody.fromDate !== null && jobComReportBody.fromDate !== ''  && <p>From : {convertDateFormat(jobComReportBody.fromDate)}</p>}
              {jobComReportBody.toDate !== null && jobComReportBody.toDate !== '' && <p>To :{convertDateFormat(jobComReportBody.toDate)}</p>}
             
            </div>
          </div>
          <div className="print-view-table-container report-print-view-table-with-clr">
            <table>
              <thead>
                <tr>
                    <th>No</th>
                    <th>Workorder No</th>
                    <th>Prod Code</th>
                    <th>Prod Name</th>
                    <th>Type</th>
                    <th>Model</th>
                    <th>Length</th>
                    <th>Tailor Comm</th>
                    <th>Tailor </th>
                    <th>Used Qty</th>
                    <th>Wastage</th>
                    <th>Cutting Master</th>
                    <th>Completed Date</th>
                    <th>Completed Qty</th>
                </tr>
              </thead>
              <tbody>
              {
                            jobComReportList!==undefined?
                            jobComReportList?.map((r,i)=>(
                                <tr key={i}>
                                    <td>{i+1}</td>
                                    <td>{r?.workorderNo}</td>
                                    <td>{r?.productCode}</td>
                                    <td>{r?.productName}</td>
                                    <td>{r?.type}</td>
                                    <td>{r?.model}</td>
                                    <td>{r?.length}</td>
                                    <td>{r?.tailorCommission}</td>
                                    <td>{r?.tailor!==null&&
                                        r?.tailor.map((r,i)=>
                                        <p>{r}</p>
                                        )}</td>
                                    <td>{r?.usedQty}</td>
                                    <td>{r?.wastage}</td>
                                    <td>{r?.cutter!==null&&
                                            r?.cutter.map((r,i)=>
                                            <p>{r}</p>
                                        )}
                                    </td>
                                    <td>{convertDateFormat(r?.date)}</td>
                                    <td>{r?.completedQty}</td>
                                </tr>
                            ))
                            
                        :
                        <tr>
                            <td colSpan={14}>No data</td>
                        </tr>
                        }

                
              </tbody>
            </table>
          </div>
          
        </div>
      </div>
      <LoadingForm loading={isLoading} />
    </div>
    )
}