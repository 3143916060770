import React from 'react'
import BillPayableStatement from './outstandingReportsGroup/BillPayableStatement'
import BillReceivableStatement from './outstandingReportsGroup/BillReceivableStatement'
import GroupOutstanding from './outstandingReportsGroup/GroupOutstanding'
import LedgerOutstanding from './outstandingReportsGroup/LedgerOutstanding'

const OutstandingReports = ({ selectedDropdownList,selectListItem,renderData }) => {
  return (
    <>
      <div className="accounting-voucher-menu" id>
        {selectedDropdownList === 1 && <BillPayableStatement  selectListItem={selectListItem}  renderData={renderData} />}
        {selectedDropdownList === 2 && <BillReceivableStatement selectListItem={selectListItem} renderData={renderData}/>}
        {selectedDropdownList === 3 && <GroupOutstanding selectListItem={selectListItem}  renderData={renderData}/>}
        {selectedDropdownList === 4 && <LedgerOutstanding selectListItem={selectListItem}  renderData={renderData}/>}
      </div>
    </>
  )
}

export default OutstandingReports