import React, { useEffect, useState } from 'react'
import './InvoiceMarginReport.css'
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import ReactToPrint from "react-to-print";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLocation, useNavigate } from 'react-router-dom';
import { InvoiceMarginReportSingleViewAPIcall } from './GeneralReportAPI';
import { useSelector } from 'react-redux';
import { filterObjFromList } from '../../../../../Js/generalFunctions';

function InvoiceMarginReportSingleView() {
    let navigate = useNavigate();
    let location=useLocation()

    const invoiceSingleListData = useSelector((state) => state?.generalReport?.InvoiceMarginReportSingleList)
    console.log(invoiceSingleListData,'ffffff')
    const currencyListUpdated = useSelector(
        (state) => state.viewUpdatedCurrency.value
    );
    const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue)

    const [FormDataInfo, setFormDataInfo] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [symbol, setSymbol] = useState(null);

    const decimalPosition = localStorage.getItem("decimalPosition");
    const singleViewId = localStorage.getItem("invoiceSingleViewId")

    const handleDataFromChild = (data) => {
        setFormDataInfo(data);
    };

    useEffect(() => {
        let currencyObj = filterObjFromList("_id", currencyListUpdated, "currency", companyProfileData)
        setSymbol(currencyObj?.symbol)
    }, [companyProfileData, currencyListUpdated])


    useEffect(() => {
        if (FormDataInfo.length !== 0) {
            InvoiceMarginReportSingleViewAPIcall({
                _id: singleViewId,
                search: FormDataInfo?.search,

            })
        }
    }, [FormDataInfo, singleViewId])


    return (
        <div className="in-or-out-container">
            <div className="in-or-out-report-wrapper">
                <div className="sub-nav-head">
                <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "0.8em",
            }}
          >
           
            <div
              style={{
                background: "#d2deff",
                borderRadius: "0",
                maxWidth: "20em",
                cursor:'pointer'
              }}
              className="path-name-wrapper"
              onClick={() => {
                navigate(
                  "/userdashboard/accounts/report/generalReport/InvoiceMarginReport"
                );
                localStorage.removeItem("invoiceSingleViewId");
              }}
            >
              <h3 className="path-name" style={{ color: "black" }}>
              Invoice Margin Report
              </h3>
            </div>
            <div
              className="path-name-wrapper"
              style={{
                maxWidth: "17em",
                transition: "max-width 1s ease-in-out",
              }}
            >
              <h3 className="path-name">
                {location?.state?.invoiceNo}
              </h3>
            </div>
          </div>
                    {/* <div>
                        <div className="path-name-wrapper" style={{ backgroundColor: '#c3d4f7', maxWidth: "17em" }}>
                            <h3 style={{ color: '#06066f' }} className="path-name">Invoice Margin Report</h3>
                        </div>
                    </div> */}
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width:'62%'
                    }}>
                        <CategorySearchandFilter
                            onData={handleDataFromChild}
                            isDate={false}
                            isBranch={false}
                            isFilter={false}

                        />
                        <IconButton
                            style={{ width: "100px" }}

                            className="print-icon-container">
                            <i class="bi bi-printer printer-icon"></i>
                        </IconButton>
                        <IconButton
                            style={{ width: "100px" }}
                            className="download-icon-container">
                            <i class="bi bi-arrow-down-circle download-icon"></i>
                        </IconButton>
                    </div>
                </div>
                <div className="invoice-table-container">
                    <div className="invoice-main-div">
                        <h2>Invoice Margin Report</h2>
                        <table>
                            <thead>
                                <tr>
                                    <th>PRODUCT</th>
                                    <th>QTY</th>
                                    <th>UNIT PRICE</th>
                                    <th>NET SALES</th>
                                    <th>NET COST</th>
                                    <th>PROFIT</th>
                                    <th>PROFIT MARGIN %</th>
                                </tr>
                            </thead>
                            <tbody>

                                {isLoading ? (
                                    [...Array(10)].map((r, i) => (
                                        <tr key={i}>
                                            <td colSpan={8}>
                                                <Skeleton
                                                    variant="rectangular"
                                                    animation="wave"
                                                    width={"100%"}
                                                    height={40}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                ) : invoiceSingleListData?.report !== undefined && invoiceSingleListData?.report?.length !== 0 ? (
                                    invoiceSingleListData?.report
                                        ?.slice(0)
                                        ?.map((r, i) => (
                                            <tr key={i}>
                                                <td>{r?.productName}</td>
                                                <td>{r?.qty?.toFixed(decimalPosition)}</td>
                                                <td>{symbol}&nbsp;{r?.unitPrice?.toFixed(decimalPosition)}</td>
                                                <td>{symbol}&nbsp;{r?.netSale?.toFixed(decimalPosition)}</td>
                                                <td>{symbol}&nbsp;{r?.cost?.toFixed(decimalPosition)}</td>
                                                <td>{symbol}&nbsp;{r?.profit?.toFixed(decimalPosition)}</td>
                                                <td>{r?.profitMargin?.toFixed(decimalPosition)}</td>
                                            </tr>
                                        ))
                                ) : (
                                    <tr>
                                        <td style={{ textAlign: 'center' }} colSpan={7}>No Data</td>
                                    </tr>
                                )}
                            </tbody>
                            <tr>
                                <td style={{ fontWeight: 'bold' }} colSpan={3} >Total</td>
                                <td style={{ fontWeight: 'bold' }}>{symbol}&nbsp;{invoiceSingleListData?.total !== undefined && invoiceSingleListData?.total?.netSale?.toFixed(decimalPosition)}</td>
                                <td style={{ fontWeight: 'bold' }}>{symbol}&nbsp;{invoiceSingleListData?.total !== undefined && invoiceSingleListData?.total?.cost?.toFixed(decimalPosition)}</td>
                                <td style={{ fontWeight: 'bold' }}>{symbol}&nbsp;{invoiceSingleListData?.total !== undefined && invoiceSingleListData?.total?.profit?.toFixed(decimalPosition)}</td>
                                <td style={{ fontWeight: 'bold' }}>{invoiceSingleListData?.total !== undefined && invoiceSingleListData?.total?.profitMargin?.toFixed(decimalPosition)}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            {/* <LoadingForm loading={isLoading} /> */}
        </div>
    )
}

export default InvoiceMarginReportSingleView