import { createSlice } from "@reduxjs/toolkit";

export const GenerateQrBarcodeSlice = createSlice({
  name: "GenerateQrBarcodeSlice",
  initialState: {
    apiResList: undefined,
    singleView:undefined,
  },
  reducers: {
   get_prod_list_qrBarcode: (state, action) => {
      state.apiResList = action.payload.prodListqrBarcodeData;
    },
    get_prod_list_qrBarcode_single: (state, action) => {
        state.singleView = action.payload.prodListqrBarcodeSingleData;
      },


    
  }
});

export const {
    get_prod_list_qrBarcode,
    get_prod_list_qrBarcode_single
} = GenerateQrBarcodeSlice.actions;

export default GenerateQrBarcodeSlice.reducer;
