import React,{useState,useEffect} from 'react'
import '../../../../css/Settings/CompanySettings/companySettings.css'
import {Typography} from '@mui/material'
import {ProfileInfo} from '../CompanySettings/ProfileInfo'
import {AccountSettings} from '../CompanySettings/AccountSettings'
import {GstAndTax} from '../CompanySettings/GstAndTax'
import {ForgotPassword} from '../CompanySettings/ForgotPassword'
import { viewCompanyProfileInfoAPICall } from '../../../../API/Settings/Company Settings/companySettingsAPI'
import { useSelector } from 'react-redux'
export const CompanySettings = () => {
    const [activeTab, setActiveTab] = useState("profileInfo");

    //*Redux States
    //Company Profile Data
    const companyProfileData = useSelector(
    (state) => state.companyProfileSlice.value
    );

    const [isModify,setIsModify]=useState(false)
    let currentCountry=localStorage.getItem('country');
    useEffect(() => {
      currentCountry=localStorage.getItem('country');
    }, []);

    //Update Listener
    const updateListener=()=>{
      setIsModify(!isModify)
    }




    //* API Calls
    useEffect(() => {
      viewCompanyProfileInfoAPICall();
    }, [isModify]);




    const profileInfoMenuClick = () => {
        document.getElementById("profileInfoBtn").classList.add("active");
        document.getElementById("accountSettingsBtn").classList.remove("active");
        document.getElementById("gstAndTaxBtn").classList.remove("active");
        document.getElementById("forgotPasswordBtn").classList.remove("active");
        setActiveTab("profileInfo");
      };
      const accountSettingsMenuClick = () => {
        document.getElementById("profileInfoBtn").classList.remove("active");
        document.getElementById("accountSettingsBtn").classList.add("active");
        document.getElementById("gstAndTaxBtn").classList.remove("active");
        document.getElementById("forgotPasswordBtn").classList.remove("active");
    
        setActiveTab("accountSettings");
      };
      const gstAndTaxMenuClick = () => {
        document.getElementById("profileInfoBtn").classList.remove("active");
        document.getElementById("accountSettingsBtn").classList.remove("active");
        document.getElementById("gstAndTaxBtn").classList.add("active");
        document.getElementById("forgotPasswordBtn").classList.remove("active");
    
        setActiveTab("gstAndTax");
      };
      const forgotPasswordMenuClick = () => {
        document.getElementById("profileInfoBtn").classList.remove("active");
        document.getElementById("accountSettingsBtn").classList.remove("active");
        document.getElementById("gstAndTaxBtn").classList.remove("active");
        document.getElementById("forgotPasswordBtn").classList.add("active");
    
        setActiveTab("forgotPassword");
      };



  return (
    <div className="company-settings-container">
       <div className="company-settings-tab-container">
         <div className="company-settings-tab-menu-container">
           <div className="company-settings-tab-menus-container">
           <div
                className="tab-menu-button active"
                id="profileInfoBtn"
                onClick={profileInfoMenuClick}
              >
                <Typography fontSize={"0.9rem"} fontWeight={600}>
                  Profile Info
                </Typography>
              </div>
              <div
                className="tab-menu-button"
                id="accountSettingsBtn"
                onClick={accountSettingsMenuClick}
              >
                <Typography fontSize={"0.9rem"} fontWeight={600}>
                  Account Settings
                </Typography>
              </div>
              <div
                className="tab-menu-button"
                id="gstAndTaxBtn"
                onClick={gstAndTaxMenuClick}
              >
                <Typography fontSize={"0.9rem"} fontWeight={600}>
                  {currentCountry==='india'?'GST & TAX':'VAT & TAX'}
                </Typography>
              </div>

              <div
                className="tab-menu-button"
                id="forgotPasswordBtn"
                onClick={forgotPasswordMenuClick}
              >
                <Typography fontSize={"0.9rem"} fontWeight={600}>
                  Forgot Password
                </Typography>
              </div>
           </div>
         </div>
        </div> 
        <div>
        {activeTab==='profileInfo'?
          <ProfileInfo/>
         :activeTab==='accountSettings'?
         <AccountSettings profileData={companyProfileData} updateListener={updateListener} />
         :activeTab==='gstAndTax'?
         <GstAndTax profileData={companyProfileData} updateListener={updateListener}/>
         :activeTab==='forgotPassword'?
         <ForgotPassword/>
         :undefined     
        }
        </div>
    </div>
  )
}
