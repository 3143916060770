import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import store from "../../../../../Redux/store";
import { Skeleton } from "@mui/material";
import { listLCApi } from "./LandedCostAPI";
import { singleView } from "./LandedCostSlice";

export const LandedCost = () => {
  const [isLoading, setIsLoading] = useState(true);

  let branchId = localStorage.getItem("branchId");
  let navigate = useNavigate();
  const {landedCostLis} = useSelector(
    (state) => state.landedCostSlice
  );
  
  useEffect(()=>{
    if(landedCostLis.length == 0 ){
      setIsLoading(false)
    }
  },[landedCostLis])

  console.log(landedCostLis)
  const createLandedCost = () => {
    navigate("/userdashboard/inventory/operation/landedcost/create");
  };

  useEffect(() => {
    listLCApi(branchId,setIsLoading)
    store.dispatch(singleView(undefined))
  }, []);

  const singleViewFn = (data) => {
    localStorage.setItem('singleViewById', data.id);
    navigate("/userdashboard/inventory/operation/landedcost/create")
  };

  return (
    <div className="global-page-parent-container">
      {/* table */}
      <div className="global-white-bg-container">
        <div className="create-button-blue-container">
          <h3>Landed Cost</h3>
          <button onClick={createLandedCost} className="create-button-blue">
            Create
          </button>
        </div>
        <div className="new-global-table-container">
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Branch</th>
                <th>Name</th>
                <th>GRN</th>
                <th>Vendor</th>
                <th>created By</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={8}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : landedCostLis?.length !== 0 ? (
                landedCostLis
                  ?.slice(0)
                  ?.reverse()
                  ?.map((r, i) => {
                    if (r?.status !== "Available") {
                      return (
                        <tr onClick={() => singleViewFn(r)}>
                          <td>{r?.date}</td>
                          <td>{r?.branchStoreCode}</td>
                          <td>{r?.name}</td>
                          <td>{r?.grn}</td>
                          <td>{r?.vendor}</td>
                          <td>{r?.createdBy}</td>
                          {/* <td className="table-data-box">
                            {r?.status === "Completed" ? (
                              <span className="post"> POST</span>
                            ) : r?.status === "Drafted" ? (
                              <span className="draft"> DRAFT</span>
                            ) : (
                              <span>{r?.status}</span>
                            )}
                          </td> */}
                        </tr>
                      );
                    }
                  })
              ) : (
                <tr>
                  <td colSpan={7}>No Data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
