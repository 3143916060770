import React,{useState,useRef} from 'react'
import {Select,IconButton,Dialog, Autocomplete, TextField, Skeleton} from '@mui/material'
import '../../../../css/Report/TransferReport.css'
import ReactToPrint from "react-to-print";
import { useEffect } from 'react';
import { allTransferNoListAPICall, transferReportAPICall } from '../../../../API/Report/transferReportAPI';
import { useSelector } from 'react-redux';
import { viewAllBranchesAPICall } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import { transferSingleViewAPICall } from '../../../../API/Purchase Manager/transferAPI';
import { convertDateFormat, getCurrentTime, today } from '../../../../Js/Date';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { sortGeneral } from '../../../../Js/generalFunctions';
// css
//import '../../../../css/Report/stockReport.css'
export const TransferReport = () => {
    let transferReportPrint=useRef()
    // transfer report data
    const transferReportList=useSelector((state)=>state.transferReportSlice.value)
    // login response
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  // role based rendering
  const userRole = useSelector((state) => state.userRoleSlice.value);
   // all branch list
   const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
   // transfer single view 
  const transferSingleViewData=useSelector((state)=>state.transferSingleViewSlice.value)
  // transfer no list
  const transferNoList=useSelector((state)=>state.allTransferNoSlice.value)

    const [transferReportView,setTransferReportView]=useState(false)
    const [transId,setTransId]=useState(null)
    const [fromLocation,setFromLocation]=useState(null)
    const [toLocation,setToLocation]=useState(null)
    const [locationListExcludeCurrent,setLocationListExcludeCurren]=useState([])
    const [isLoading,setIsLoading]=useState(false)
    const [time, setTime] = useState(null);
    const [isAscending,setIsAscending]=useState(false)
    const [transferReportFilter,setTransferReportFilter]=useState([])

    // get current time fn
    useEffect(() => {
      setInterval(() => {
        setTime(`${new Date().getHours()}:${new Date().getMinutes()}`);
      }, 1000);
    }, [time]);


    useEffect(()=>{
      if(userRole==="user"){
        allTransferNoListAPICall({branchId:loginResponse.branchId})
      }
      else{
        allTransferNoListAPICall()
      }
    },[])

  useEffect(()=>{
    transferReportAPICall({},setIsLoading)
    viewAllBranchesAPICall()
    
  },[])

  // extract current branch from all branch list
  useEffect(()=>{
    let currentBranch=[]
    currentBranch =allBranchesList?.filter((obj)=>{
      if(obj._id===loginResponse.branchPk){
        return obj
      }
    })
    if(currentBranch !== undefined) setFromLocation(currentBranch[0])
  },[allBranchesList,loginResponse.branchPk])

  //extract location exclude current location
  useEffect(()=>{
    let locationList=[]
    locationList=allBranchesList?.filter((obj)=>{
      if(obj._id !== fromLocation?._id){
        return obj
      }
    })
    setLocationListExcludeCurren(locationList)
  },[fromLocation,allBranchesList])

  // submit button function
  const transferReportSubmitBtn=()=>{
    transferReportAPICall({
      transNo:transId,
      fromLoc:fromLocation?.storeCode,
      toLoc:toLocation?.storeCode
    },setIsLoading)
  }

  /// transfer single view
  const openTrnasferSingleView=(id)=>{
    setTransferReportView(true)
    transferSingleViewAPICall({id})
  }

  // map trnsfer report response to state
  useEffect(()=>{
    if(transferReportList !== undefined){
      setTransferReportFilter(transferReportList?.map(obj=>{ return {...obj}}))
    }
  },[transferReportList])

  const sortMarginAscending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(transferReportFilter, "Number", "margin", true);
    setTransferReportFilter(sorted) 
  }
  const sortMarginDescending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(transferReportFilter, "Number", "margin", false);
    setTransferReportFilter(sorted) 
  }
  const sortStatusAscending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(transferReportFilter, "String", "status", true);
    setTransferReportFilter(sorted) 
  }
  const sortStatusDescending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(transferReportFilter, "String", "status", false);
    setTransferReportFilter(sorted)
  }
  // branch code sorting
  const sortBranchCodeAscending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(transferReportFilter, "String", "branchCode", true);
    setTransferReportFilter(sorted)
  }
  const sortBranchCodeDescending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(transferReportFilter, "String", "branchCode", false);
    setTransferReportFilter(sorted)
  }
  // branch name sorting
  const sortBranchNameAscending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(transferReportFilter, "String", "branchName", true);
    setTransferReportFilter(sorted)
  }
 const sortBranchNameDescending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(transferReportFilter, "String", "branchName", false);
  setTransferReportFilter(sorted)
 }

  return (
    <div className="global-page-parent-container">
    <p className="breadcrumb">Report &gt; Transfer Report</p>
    <div className="daily-report-top-container">
      <h3>Transfer Report</h3>
      <hr className="global-hr"/>
      <div className="daily-report-top-input-container" >
       {/* <div className="global-single-input">
        <p>Branch</p>
        <Select></Select>
       </div> */}
       <div className="global-single-input auto-complete">
        <p>Transaction ID</p>
        <Autocomplete
          sx={{width:'100%'}}
          options={transferNoList||[""]}
          getOptionLabel={(option)=> userRole === "user" ?`${option?.prefix}${option?.transNo}`:userRole==="admin"?`${option?.prefix}${option?.transNo}-${option?.branchCode}` :undefined}
          renderInput={(params)=>
            <TextField  {...params}
             placeholder="Select Transaction ID"
            />
          }
          
          onChange={(e,newValue)=>setTransId(newValue?.transNo)}
        />
       </div>
       <div className="global-single-input auto-complete">
        <p>Location From</p>
        <Autocomplete
          sx={{width:'100%'}}
          options={allBranchesList||[""]}
          getOptionLabel={(option)=>`${option?.storeCode}-${option?.branchName}`}
          renderInput={(params)=>
            <TextField  {...params}
             placeholder="Select Branch"
            />
          }
          disabled={userRole==='user'}
          value={fromLocation}
          onChange={(e,newValue)=>setFromLocation(newValue)}
        />
       </div>
       <div className="global-single-input auto-complete">
        <p>Location To</p>
        <Autocomplete
          sx={{width:'100%'}}
          options={locationListExcludeCurrent||[""]}
          getOptionLabel={(option)=>`${option?.storeCode}-${option?.branchName}`}
          renderInput={(params)=>
            <TextField  {...params}
             placeholder="Select Branch"
            />
          }
         
          value={toLocation}
          onChange={(e,newValue)=>setToLocation(newValue)}
        />
       </div>
       <button onClick={transferReportSubmitBtn} className="btn btn-primary report-submit-btn">Submit</button>
      
      </div>
     
    </div>
    <div className="global-white-bg-container">
    
     <div className="global-table-container">
        <table className="global-table">
           <thead>
             <tr>
              <th>No</th>
              {
                userRole ==="admin"&&
                <>
                <th>
                  Branch Code
                  {
                isAscending ?(
                  <IconButton
                    onClick={sortBranchCodeAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortBranchCodeDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
                </th>
                <th>
                  Branch Name
                  {
                isAscending ?(
                  <IconButton
                    onClick={sortBranchNameAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortBranchNameDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
                </th>
                </>
              }
              
              <th>Transaction ID</th>
              <th>Location From</th>
              <th>Location To</th>
              <th>
                Margin
                {
                isAscending ?(
                  <IconButton
                    onClick={sortMarginAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortMarginDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
              </th>
              <th>
                Status
                {
                isAscending ?(
                  <IconButton
                    onClick={sortStatusAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortStatusDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
              </th>
              <th>View</th>
             </tr>
           </thead>
           {
            isLoading ?(
              <tbody>
                 <tr>
                      <td colSpan={10}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={30}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={10}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={30}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={10}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={30}
                        />
                      </td>
                    </tr>
              </tbody>
            ):(
              <tbody>
              {
                transferReportFilter?.length !==0 ? transferReportFilter?.map((r,i)=>(
                  <tr>
                  <td>{i+1}</td>
                  {
                    userRole === "admin" &&
                    <>
                    <td>{r?.branchCode}</td>
                    <td>{r?.branchName}</td>
                    </>
                  }
                  
                  <td>{r?.transNo}</td>
                  <td>{r?.fromLoc}</td>
                  <td>{r?.toLOc}</td>
                  <td>{r?.margin}</td>
                  <td><p
                   className={
                    r.status==="Pending"?
                     'stock-adj-status-pending'
                    :r.status==='Completed'?
                    'stock-adj-status-confirm'
                    :r.status==='Confirmed'?
                    'stock-adj-status-complete'
                    :undefined
                  }
                  >{r?.status}</p>
                  </td>
                  <td>
                   <IconButton onClick={()=>openTrnasferSingleView(r?._id)}>
                     <i class="bi bi-eye visibility-icon"></i>
                   </IconButton>
                  </td>
                </tr>
                ))
                :
                <tr>
                  <td  colSpan={10}>No Data</td>
                </tr>
              }
            
             </tbody>
            )
           }
          
        </table>
     </div>
    </div>
     {/* transfer report view */}
    <Dialog open={transferReportView} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setTransferReportView(false)}>
      <div className="transfer-report-single-view-container">
        <div style={{display:"flex",justifyContent:"space-between"}}>
        <h3>Transfer Report View</h3>
        <div className="stock-report-print-download-icon-container">
        <ReactToPrint
        trigger={()=>(
            <IconButton className="print-icon-container">
              <i class="bi bi-printer printer-icon"></i>
            </IconButton>
        )}
        content={()=>transferReportPrint}
        pageStyle="@page { size: auto;  margin: 15mm 0 10mm 0; } }"
      />  
          <IconButton className="download-icon-container">
            <i class="bi bi-arrow-down-circle download-icon"></i>
           </IconButton>
        </div>
        </div>
        
        <div className="transfer-report-single-view-input-container">
          <div className="global-single-input">
            <p>Transaction ID</p>
             <input type="text" disabled value={transferSingleViewData?.transNo}/>
          </div>
          <div className="global-single-input">
            <p>Location From</p>
             <input type="text" disabled value={transferSingleViewData?.fromLoc}/>
          </div>
          <div className="global-single-input">
            <p>Location To</p>
             <input type="text" disabled  value={transferSingleViewData?.toLoc}/>
          </div>
          <div className="global-single-input">
            <p>Date</p>
             <input type="date" disabled value={transferSingleViewData?.transferDate}/>
          </div>
          <div className="global-single-input">
            <p>Margin</p>
             <input type="text" disabled value={transferSingleViewData?.margin}/>
          </div>
          <div className="global-single-input">
            <p>Remarks</p>
             <input type="text" disabled value={transferSingleViewData?.remarks} />
          </div>
          
        </div>
        <hr/>
        <div className="global-table-container">
         <table className="global-table">
           <thead>
            <tr>
              <th>No</th>
              <th>Product Name</th>
              <th>Stock Qty</th>
              <th>Transfer Qty</th>
              <th>Unit</th>
              <th>Unit Cost</th>
              <th>Total Cost</th>
              <th>SP Margin</th>
              <th>Outlet Cost</th>
            </tr>
           </thead>
           <tbody>
           {
                transferSingleViewData?.transferItems?.map((r,i)=>(
                  <tr key={i}>
                    <td>{i+1}</td>
                    <td>{r?.itemName}</td>
                    <td>{r?.stockQty}</td>
                    <td>{r?.transferQty}</td>
                    <td>{r?.unit}</td>
                    <td>{r?.unitCost}</td>
                    <td>{r?.totalCost}</td>
                    <td>{r?.spMargin}</td>
                    <td>{r?.outletCost}</td>
                  </tr>
                ))
               }
           </tbody>
         </table>
        </div>
        <div className="stock-adj-report-view-btn-container">
          <button onClick={()=>setTransferReportView(false)} className="btn btn-primary">Close</button>
        </div>
      </div>
    </Dialog>


    {/* transfer report print */}
    <div 
       style={{display:'none'}}
     >
       <div ref={(el)=>(transferReportPrint = el)}>
          <div className='print-view-top-container'>
            <div className='print-view-top-left-container' style={{width:'30%'}}>
                <h6 style={{margin:"0 0 0 2%"}}>Printed On : {convertDateFormat(today)}&nbsp;{getCurrentTime()}</h6>
                <p>Trans ID&nbsp;:&nbsp;{transferSingleViewData?.transNo}</p>
                
            </div>
            <div className='print-view-top-middle-container' style={{width:'40%'}}>
            {userRole === "admin" ? (
                <h3>{loginResponse?.data?.profile?.companyName}</h3>
              ) : userRole === "user" ? (
                <h3>{loginResponse?.storeName}</h3>
              ) : undefined}
                 <p>TRANSFER REPORT</p>
            </div>
            <div className='print-view-top-right-container' style={{width:'30%'}}>
                {userRole === "user" && <p>{loginResponse?.branchId}&nbsp;-&nbsp;{loginResponse?.branchName}</p> }
            </div>
          </div>
          <hr  className="print-report-hr"/>
          <div className='print-view-top-container' style={{padding: "0 6% 2% 6%"}}>
            <div className='print-view-top-left-container' style={{width:'40%'}}>
                <p>From&nbsp;:&nbsp;{transferSingleViewData?.fromLoc}</p>
                <p>To&nbsp;:&nbsp;{transferSingleViewData?.toLoc}</p>
               
            </div>
            <div className='print-view-top-middle-container' style={{width:'20%'}}>
             
            </div>
            <div className='print-view-top-right-container' style={{width:'40%'}}>
                <p>Transfer Date&nbsp;:&nbsp;{convertDateFormat(transferSingleViewData?.transferDate)}</p>
                <div className="print-report-input-container">
                  <p>Remarks</p>
                  <input type="text" name="" id="" value={transferSingleViewData?.remarks}/>
                </div>
            </div>
          </div>
          <div className='print-view-table-container'>
          <table>
          <thead>
            <tr>
              <th>No</th>
              <th>Product Name</th>
              <th>Stock Qty</th>
              <th>Transfer Qty</th>
              <th>Unit</th>
              <th>Unit Cost</th>
              <th>Total Cost</th>
              <th>SP Margin</th>
              <th>Outlet Cost</th>
            </tr>
           </thead>
           <tbody>
           {
                transferSingleViewData?.transferItems?.map((r,i)=>(
                  <tr key={i}>
                    <td>{i+1}</td>
                    <td>{r?.itemName}</td>
                    <td>{r?.stockQty}</td>
                    <td>{r?.transferQty}</td>
                    <td>{r?.unit}</td>
                    <td>{r?.unitCost}</td>
                    <td>{r?.totalCost}</td>
                    <td>{r?.spMargin}</td>
                    <td>{r?.outletCost}</td>
                  </tr>
                ))
               }
           </tbody>
        </table>
        
          </div>
     
       </div>
     </div>
    </div>
  )
}
