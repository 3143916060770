import {createSlice} from '@reduxjs/toolkit'
export const shiftReopenDialogSlice=createSlice({
    name:'shiftReopenDialog',
    initialState:{
        value:undefined
    },
    reducers:{
        get_shift_reopen_dialog:(state,action)=>{
            state.value=action.payload.shiftReopen
        }
    }
});
export const {get_shift_reopen_dialog} = shiftReopenDialogSlice.actions;
export default shiftReopenDialogSlice.reducer;