import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { addEmployeeCompanyAndBankSettingsAPICall } from "../../../../../../API/Staff/HRM/employeeAPI";
import Banks from "../../../../../../Assets/JSON/banknames.json";
import ErrorSnackbar from "../../../../../Single Components/SnackBars/ErrorSnackbar";
import SuccessSnackbar from "../../../../../Single Components/SnackBars/SuccessSnackbar";
// css
// import '../../../../../../css/Staff/HRM/Tab/empPersonalInfo.css'

export const EmpBankDetails = (props) => {
  const { singleEmpData, updateListener } = props;
  const currentEmpId = localStorage.getItem("EMP_ID");

  //Snackbar states
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState();
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState();
  const [snackMsg, setSnackMsg] = useState();
  //Emp bank Details form
  const empBankDetailsFormInitialState = {
    accHolder: singleEmpData?.ac_holder,
    accNo: singleEmpData?.ac_no,
    bankName: null,
    bankCode: singleEmpData?.bank_code,
    branchLoc: singleEmpData?.bankLocation,
    taxCode: singleEmpData?.pan,
  };
  const [empBankDetailsForm, setEmpBankDetailsForm] = useState(
    empBankDetailsFormInitialState
  );
//Selected Bank Object
const [selectedBankObject,setSelectedBankObject]=useState({name:singleEmpData?.bank})
const [defaultBank,setDefaultBank]=useState(null)
//Close SuccessSnackbar
const closeSuccessSnackbar = () => {
  setOpenSuccessSnackbar(false);
};
//Close ErrorSnackbar
const closeErrorSnackbar = () => {
  setOpenErrorSnackbar(false);
};





  //*onChange
  const getEmpBankFormData = (key) => (e) => {
    const { value } = e.target;

    if (key === "accHolder") {
      setEmpBankDetailsForm({ ...empBankDetailsForm, accHolder: value });
    }
    if (key === "accNo") {
      setEmpBankDetailsForm({ ...empBankDetailsForm, accNo: value });
    }
    if (key === "bankName") {
      setEmpBankDetailsForm({ ...empBankDetailsForm, bankName: value });
    }
    if (key === "bankCode") {
      setEmpBankDetailsForm({ ...empBankDetailsForm, bankCode: value });
    }
    if (key === "branchLoc") {
      setEmpBankDetailsForm({ ...empBankDetailsForm, branchLoc: value });
    }
    if (key === "taxCode") {
      setEmpBankDetailsForm({ ...empBankDetailsForm, taxCode: value });
    }
  };

  //*onClick Fn
  const bodyToUpdateCompanySettings = {
    id: currentEmpId,
    department: singleEmpData?.department,
    designation: singleEmpData?.designation,
    date_of_join: singleEmpData?.date_of_join,
    workHour: parseFloat(singleEmpData?.workHour),
    salaryType: singleEmpData?.salaryType,
    monthlySalary: parseFloat(singleEmpData?.monthlySalary),
    contractPeriodFrm: singleEmpData?.contractPeriodFrm,
    contractPeriodTo: singleEmpData?.contractPeriodTo,
    status: singleEmpData?.status,
    ac_holder: empBankDetailsForm?.accHolder,
    ac_no: empBankDetailsForm?.accNo,
    bank: empBankDetailsForm?.bankName,
    bank_code: empBankDetailsForm?.bankCode,
    bankLocation: empBankDetailsForm?.branchLoc,
    pan: empBankDetailsForm?.taxCode,
    qrcode: singleEmpData?.qrcode,
    outletLocation: singleEmpData?.outletLocation,
  };
  const clickBankDetailsUpdateFn = () => {
    addEmployeeCompanyAndBankSettingsAPICall(
      bodyToUpdateCompanySettings,
      setOpenSuccessSnackbar,
      setOpenErrorSnackbar,
      setSnackMsg,
      updateListener
    );
  };

  // //
  // useEffect(()=>{
  //   if(empBankDetailsForm!==undefined)
  //   {

  //     setDefaultBank({...defaultBank,name:singleEmpData?.bankName})
  //   }
  // },[singleEmpData])



//Set Bank Name to state
useEffect(() => {
  if (selectedBankObject !== null) {
    setEmpBankDetailsForm({
      ...empBankDetailsForm,
      bankName: selectedBankObject?.name,
    });
  } else {
    setEmpBankDetailsForm({ ...empBankDetailsForm, bankName: "0" });
  }
  }, [selectedBankObject]);









  return (
    <>
      <div className="employee-personal-info-main-container">
        <div className="employee-personal-info-container">
          <div className="employee-personal-info-single-input-container">
            <div className="global-single-input add-employee-input">
              <p>Account Holder</p>
              <input
                type="text"
                placeholder="Enter Account Holder..."
                value={empBankDetailsForm.accHolder}
                onChange={getEmpBankFormData("accHolder")}
              />
            </div>
            <div className="global-single-input add-employee-input">
              <p>Account Number</p>
              <input
                type="text"
                placeholder="Enter Account Number..."
                value={empBankDetailsForm.accNo}
                onChange={getEmpBankFormData("accNo")}
              />
            </div>
            <div className="global-single-input add-employee-input auto-complete">
              <p>Bank Name</p>
              <Autocomplete
              sx={{ width: "100%" }}
              value={selectedBankObject}
              options={Banks || [""]}
              getOptionLabel={(option) => option?.name}
              renderInput={(params) => (
                <TextField {...params} placeholder="select bank" />
              )}
              // value={(option)=>option?._id}
              onChange={(e, newValue) => setSelectedBankObject(newValue)}
            />
              {/* <Select
                value={empBankDetailsForm.bankName}
                onChange={getEmpBankFormData("bankName")}
              >
                {Banks?.map((bank, i) => (
                  <MenuItem key={i} value={bank?.name}>
                    {bank?.name}
                  </MenuItem>
                ))}
              </Select> */}
            </div>
            <div className="global-single-input add-employee-input">
              <p>IFSC / IBAN</p>
              <input
                type="text"
                placeholder="Enter IFSC / IBAN..."
                value={empBankDetailsForm.bankCode}
                onChange={getEmpBankFormData("bankCode")}
              />
            </div>
            <div className="global-single-input add-employee-input">
              <p>Branch Location</p>
              <input
                type="text"
                placeholder="Enter branch location..."
                value={empBankDetailsForm.branchLoc}
                onChange={getEmpBankFormData("branchLoc")}
              />
            </div>
            <div className="global-single-input add-employee-input">
              <p>PAN / Income Tax Code</p>
              <input
                type="text"
                placeholder="Enter tax code..."
                value={empBankDetailsForm.taxCode}
                onChange={getEmpBankFormData("taxCode")}
              />
            </div>
          </div>
          <button className="btn btn-primary employee-upadte-btn btn-font-size-less" onClick={clickBankDetailsUpdateFn}>
            Update
          </button>
        </div>
      </div>

      <SuccessSnackbar
        open={openSuccessSnackbar}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={openErrorSnackbar}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </>
  );
};
