import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const MuiAutocomplete = (props) => {
  const { options, label, value, onChange, isEdit, validationAlert } = props;

  return (
    <div style={{ height: "2em" }}>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={options || []}
        renderInput={(params) => (
          <TextField
            sx={{
              "& .MuiInputBase-input": {
                height: "1.5rem",
              },
            }}
            {...params}
            label={label || "label"}
            focused
          />
        )}
        value={value}
        onChange={onChange}
        disabled={isEdit}
      />

      <p className="doc-validation-alert">{validationAlert}</p>
    </div>
  );
};

export default MuiAutocomplete;
