import { createSlice } from "@reduxjs/toolkit";

export const singleCustomerCreditSlice = createSlice({
  name: "singleCustomerCreditSlice",
  initialState: {
    value: undefined,
  },
  reducers: {
    update_credit_of_customer: (state, action) => {
      state.value = action.payload.singleCusCredit;
    },
  },
});

export const { update_credit_of_customer } = singleCustomerCreditSlice.actions;
export default singleCustomerCreditSlice.reducer;
