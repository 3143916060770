import React, { useRef } from "react";
import InputBox from "../../../components/InputBox";
import ReactToPrint from "react-to-print";
import { IconButton, Skeleton } from "@mui/material";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

//rest data
const renderData = [
  {
    employeeName: "jowher",
    employeeID: "1111",
    department: "Accounts",
    casualLeave: "2",
    holiday: "5",
    leave: "2",
    overTime: "12:00hrs",
    present: "26",
  },
  {
    employeeName: "shakeeb",
    employeeID: "1111",
    department: "Accounts",
    casualLeave: "2",
    holiday: "5",
    leave: "2",
    overTime: "12:00hrs",
    present: "26",
  },
];

const AttendanceReport = ({ selectListItem }) => {
  const componentRef = useRef();
  const isLoading = false;
  return (
    <div
      id="currentAsset-report"
      className="attendance-report pay-in-slip  stock-item-monthly-summary"
    >
      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="accounting-voucher-menu-sections"
      >
        <h3 style={{ margin: "2% 1%" }}>{selectListItem}</h3>
        <div className="input-current-asset-reports">
          <InputBox label={"From"} type={"date"} />
          <InputBox label={"To"} type={"date"} />
          <div className="submit-btn-container">
            <button className="ledger-input-container-btn">Submit</button>
          </div>
        </div>
      </div>
      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="accounting-voucher-menu-sections"
      >
        <div className="action-btn-container">
          <div>
            <ReactToPrint
              trigger={() => (
                <IconButton sx={{ color: "blue" }}>
                  <i className="bi bi-printer "></i>
                </IconButton>
              )}
              content={() => componentRef.current}
            />
            <IconButton className="download-btn">
              <ArrowCircleDownIcon sx={{ fontSize: 30 }} />{" "}
            </IconButton>
          </div>
        </div>
        <div className="table-container">
          <div className="global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>Employee Name</th>
                  <th>Employee ID</th>
                  <th>Department</th>
                  <th>Casual Leave</th>
                  <th>Holiday</th>
                  <th>Leave</th>
                  <th>Over Time</th>
                  <th>Present</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  [...Array(3)].map((_, index) => (
                    <tr key={index}>
                      <td colSpan={7}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                  ))
                ) : renderData.length !== 0 ? (
                  renderData?.reverse()?.map((r, i) => (
                    <React.Fragment key={i}>
                      <tr className="data-table-row-head">
                        <td>{r?.employeeName}</td>
                        <td>{r?.employeeID}</td>
                        <td>{r?.department}</td>
                        <td>{r?.casualLeave}</td>
                        <td>{r?.holiday}</td>
                        <td>{r?.leave}</td>
                        <td>{r?.overTime}</td>
                        <td>{r?.present}</td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>No Records</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div
          ref={componentRef}
          id="currentAsset-report"
          className="print-preview-container"
        >
          <div className="print-head-container">
            <div className="brand-name">
              <h2>Leeyet Textile</h2>
            </div>
            <div className="select-table-head">
              <h3>{selectListItem}</h3>
            </div>
            <div className="duration-date">
              <h3>{"01/04/2023"}</h3>
              <strong>TO</strong>
              <h3>{"01/12/2023"}</h3>
            </div>
          </div>
          <div className="global-table-wrapper">
            <div className="global-table-container">
              <table className="global-table">
                <thead>
                  <tr>
                    <th>Employee Name</th>
                    <th>Employee ID</th>
                    <th>Department</th>
                    <th>Casual Leave</th>
                    <th>Holiday</th>
                    <th>Leave</th>
                    <th>Over Time</th>
                    <th>Present</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    [...Array(3)].map((_, index) => (
                      <tr key={index}>
                        <td colSpan={7}>
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={40}
                          />
                        </td>
                      </tr>
                    ))
                  ) : renderData.length !== 0 ? (
                    renderData?.reverse()?.map((r, i) => (
                      <React.Fragment key={i}>
                        <tr className="data-table-row-head">
                          <td>{r?.employeeName}</td>
                          <td>{r?.employeeID}</td>
                          <td>{r?.department}</td>
                          <td>{r?.casualLeave}</td>
                          <td>{r?.holiday}</td>
                          <td>{r?.leave}</td>
                          <td>{r?.overTime}</td>
                          <td>{r?.present}</td>
                        </tr>
                      </React.Fragment>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7}>No Records</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendanceReport;
