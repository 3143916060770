import axios from 'axios'
import {get_daily_report} from '../../Redux/Report/dailyReportSlice'
import {HEADERS, HEADER_FORM_DATA} from '../UrlAndPaths'
import Store from '../../Redux/store'

export const dailyReportAPICall= async (formData,setLoadingForm)=>{
   setLoadingForm!==undefined && setLoadingForm(true)
   await axios.post("report/dailyreport",formData,HEADERS)
   .then((res)=>{
     if(res.status===200){
        Store.dispatch(get_daily_report({dailyReportData:res.data}))
        setLoadingForm!==undefined && setLoadingForm(false)
     }
   })
   .catch((err)=>{
    Store.dispatch(get_daily_report({dailyReportData:undefined}))
    setLoadingForm!==undefined && setLoadingForm(false)
    console.log(err.response);
   })
}

