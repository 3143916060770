import React, { useEffect, useState } from 'react'
import './InvoiceMarginReport.css'
import { IconButton, Skeleton } from '@mui/material';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import { Bar, Line } from "react-chartjs-2";
import ReactToPrint from "react-to-print";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from 'react-router-dom';
import { ViewProductMarginReportAPIcall } from './GeneralReportAPI';
import { useSelector } from 'react-redux';
import { filterObjFromList } from '../../../../../Js/generalFunctions';
import CustomPagination from '../../../../Single Components/CustomPagination';
import { viewAllCurrencyUpdated } from '../../../../../API/Settings/Currency/CurrencyAPI';
import { companyProfileInfoForUser } from '../../../../../API/Settings/Company Settings/companySettingsAPI';
import { setFilterActive } from '../../../../Single Components/listSlice/filterCategorySlice';
import store from '../../../../../Redux/store';

function ProductMarginReport() {

    let navigate = useNavigate();
    const filtereDataOptions = useSelector((state) => state.listCategory);
    const tableListData = useSelector((state) => state?.generalReport?.ProductMarginReport)
    console.log(tableListData, 'ppp')
    const totalPages = tableListData?.pages;
    const currencyListUpdated = useSelector(
        (state) => state.viewUpdatedCurrency.value
    );
    const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue)

    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = (page) => {

        setCurrentPage(page);
    };
    const [FormDataInfo, setFormDataInfo] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [symbol, setSymbol] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const decimalPosition = localStorage.getItem("decimalPosition");

    const handleDataFromChild = (data) => {
        setFormDataInfo(data);
    };
    const handleBranchSelect = (selectedBranch) => {
        setSelectedBranch(selectedBranch); 
      
      };
    const handleRowClick = (row) => {
        store.dispatch( setFilterActive(window.location.pathname))
        localStorage.setItem("productSingleViewId", row?._id);
        navigate("/userdashboard/accounts/report/generalReport/productMarginReport/SingleView", { state: { productName: row?.productName } });
    };

    // const labels =
    //     [
    //         'Product1', 'Product2', 'Product3', 'Product4', 'Product5', 'Product6', 'Product7', 'Product8', 'product9', 'product10',
    //         'Product11', 'Product12', 'Product13', 'Product14', 'Product15', 'Product16', 'Product17', 'Product18', 'product19', 'product20'
    //         , 'Product21', 'Product22', 'Product23', 'Product24', 'Product25',
    //     ];
    const labels = tableListData?.report?.map(item => item?.productName) || [];

    const option = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
                text: "Chart.js Bar Chart",
            },
        },
        scales: {
            y: {
                suggestedMin: 0,
                suggestedMax: 100,
                ticks: {
                    stepSize: 10,
                },
            },
        },
    };

    //   const datas = {
    //     labels,
    //     datasets: [
    //       {
    //         label: "",
    //         // data: salesPercData,
    //         backgroundColor: "#2dcf21",
    //         barThickness: 15,
    //       },
    //     ],
    //   };
    // const datas = {
    //     labels,
    //     datasets: [
    //         {
    //             label: "Profit Margin",
    //             data: [45, 75, 50, 90, 20, 40, 20, 30, 70, 40, 60, 80, 20, 50, 70, 30, 80, 60, 40, 20, 90, 50, 70, 30, 60],
    //             backgroundColor: "#05b30e",
    //             barThickness: 35,
    //         },
    //     ],
    // };
    const datas = {
        labels,
        datasets: [
            {
                label: "Profit Margin",
                data: tableListData?.report?.map(item => item.profitMargin?.toFixed(decimalPosition)) || [],
                backgroundColor: "#05b30e",
                barThickness: 35,
            },
        ],
    };

    useEffect(() => {
        let currencyObj = filterObjFromList("_id", currencyListUpdated, "currency", companyProfileData)
        setSymbol(currencyObj?.symbol)
    }, [companyProfileData, currencyListUpdated])
    useEffect(() => {
        viewAllCurrencyUpdated({ status: 0 });
        companyProfileInfoForUser();
    }, [])

    useEffect(() => {
        if (FormDataInfo?.length !== 0) {
            let body = {
                fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo?.fromDate} 00:00:00`,
                toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo?.toDate} 23:59:59`,
                branchId: FormDataInfo?.branchList,
                search: FormDataInfo?.search,
                index: currentPage-1,
            }
            let loadData=window.location.pathname===filtereDataOptions?.filterActive
            !loadData &&   ViewProductMarginReportAPIcall(body);
        }
    }, [FormDataInfo, currentPage])

    return (
        <div className="in-or-out-container">
            <div className="in-or-out-report-wrapper">
                <div className="sub-nav-head">
                    <div>
                        <div className="path-name-wrapper" style={{ backgroundColor: '#c3d4f7', maxWidth: "17em" }}>
                            <h3 style={{ color: '#06066f' }} className="path-name">Product Margin Report</h3>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}>
                        <CategorySearchandFilter
                            onData={handleDataFromChild}
                            onBranchSelect={handleBranchSelect}
                        // isBranch={false}
                        />
                        <IconButton
                            style={{ width: "100px" }}

                            className="print-icon-container">
                            <i class="bi bi-printer printer-icon"></i>
                        </IconButton>
                        <IconButton
                            style={{ width: "100px" }}
                            className="download-icon-container">
                            <i class="bi bi-arrow-down-circle download-icon"></i>
                        </IconButton>
                    </div>
                </div>
                <div className="invoice-table-container">
                    <div className="invoice-main-div">
                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}>
                            <CustomPagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={handlePageChange}

                            />
                        </div>

                        {/* <h3>Product Margin Report</h3> */}
                        <table>
                            <thead>
                                <tr>
                                    <th>PRODUCT</th>
                                    <th>QTY</th>
                                    <th>NET SALE</th>
                                   
                                    <th>COST</th>
                                    <th>PROFIT</th>
                                    <th>PROFIT MARGIN %</th>
                                </tr>
                            </thead>
                            <tbody>

                                {isLoading ? (
                                    [...Array(10)].map((r, i) => (
                                        <tr key={i}>
                                            <td colSpan={8}>
                                                <Skeleton
                                                    variant="rectangular"
                                                    animation="wave"
                                                    width={"100%"}
                                                    height={40}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                ) : tableListData?.report !== undefined && tableListData?.report?.length !== 0 ? (
                                    tableListData?.report
                                        ?.slice(0)
                                        ?.map((r, i) => (
                                            <tr key={i} onClick={() => handleRowClick(r)}>
                                                <td>{r?.productName}</td>
                                                <td>{r?.qty?.toFixed(decimalPosition)}</td>
                                                <td>{symbol}&nbsp;{r?.netSale?.toFixed(decimalPosition)}</td>
                                               
                                                <td>{symbol}&nbsp;{r?.cost?.toFixed(decimalPosition)}</td>
                                                <td>{symbol}&nbsp;{r?.profit?.toFixed(decimalPosition)}</td>
                                                <td>{r?.profitMargin?.toFixed(decimalPosition)}</td>
                                            </tr>
                                        ))
                                ) : (
                                    <tr>
                                        <td style={{ textAlign: 'center' }} colSpan={5}>No Data</td>
                                    </tr>
                                )}
                            </tbody>
                            <tr>
                                <th>Total</th>
                                <th>{tableListData?.total !== undefined && tableListData?.total?.qty?.toFixed(decimalPosition)}</th>
                                <th>{symbol}&nbsp;{tableListData?.total !== undefined && tableListData?.total?.netSale?.toFixed(decimalPosition)}</th>
                                
                                <th>{symbol}&nbsp;{tableListData?.total !== undefined && tableListData?.total?.cost?.toFixed(decimalPosition)}</th>
                                <th>{symbol}&nbsp;{tableListData?.total !== undefined && tableListData?.total?.profit?.toFixed(decimalPosition)}</th>
                                <th>{tableListData?.total !== undefined && tableListData?.total?.profitMargin?.toFixed(decimalPosition)}</th>
                            </tr>
                        </table>
                    </div>
                </div>

                <div style={{ marginTop: '6%' }} className="payments-container">
                    <div style={{ width: "100%", position: "relative", overflowX: 'auto' }}>
                        <Bar options={option} data={datas} />
                    </div>
                </div>
            </div>
            {/* <LoadingForm loading={isLoading} /> */}
        </div>
    )
}

export default ProductMarginReport