import axios from "axios"
import { get_expense_report } from "../../Redux/Report/expenseReportSlice"
import store from "../../Redux/store"
import { HEADERS } from "../UrlAndPaths"

export const expenseReportAPICall= async (body,setIsLoading)=>{
  setIsLoading(true)
  await axios.post("report/viewExpenseReport",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
        store.dispatch(get_expense_report({expenseReportData:res.data}))
        setIsLoading(false)
    }
  })
  .catch((err)=>{
    console.log(err.response);
    store.dispatch(get_expense_report({expenseReportData:undefined}))
    setIsLoading(false)
  })
}