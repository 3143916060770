import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {useSelector } from 'react-redux';
import store from '../../../../../Redux/store';
import { get_vendor_bill_row_data_list } from '../Slice/vendorBillSlice';
import { convertDateFormat } from '../../../../../Js/Date';
import { viewVendorBillAPICall } from '../API/vendorBillAPI';
import { Skeleton } from '@mui/material';

export const VendorBills = () => {

  let navigateTo=useNavigate()
  const tableListData=useSelector((state)=>state.vendorBillSlice.apiResList);
  
  const [isLoading,setIsLoading]=useState(false)
  const handleClickRow=(row)=>{
    store.dispatch(get_vendor_bill_row_data_list({vendorBillSingleRowData:row}))
    navigateTo("/userdashboard/accounts/vendor/createvendorBills")
  }

  useEffect(()=>{
    store.dispatch(get_vendor_bill_row_data_list({vendorBillSingleRowData:undefined}))
    viewVendorBillAPICall(setIsLoading)
  },[])
  return (
    <div className='global-page-parent-container'>
      {/* <p className="breadcrumb">Accounts &gt; Vendors &gt; Vendor Bills</p> */}

      <div className="global-white-bg-container">
        <div className="create-button-blue-container">
          <button className='create-button-blue' 
           onClick={()=>navigateTo("/userdashboard/accounts/vendor/createvendorBills")}>
            Create</button>
        </div>

        <div className="global-table-container">
          <table className="global-table">
            <thead>
              <tr>
              <th>No</th>
              <th>Name</th>
              <th>Vendor</th>
              <th>Bill Date</th>
              <th>Due Date</th>
              <th>Bill Refference</th>
              <th>Total</th>
              <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={8}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : tableListData !== undefined || tableListData?.length === 0 ? (
                tableListData?.slice(0)?.reverse().map((r, i) => (
                  <tr key={i} onClick={() => handleClickRow(r)} style={{ cursor: "pointer" }}>
                    <td>{i + 1}</td>
                    <td>{r?.name}</td>
                    <td>{r?.vendor}</td>
                    <td>{convertDateFormat(r?.billDate)}</td>
                    <td>{r?.dueDate==="no date"?r?.dueDate:convertDateFormat(r?.dueDate)}</td>
                    <td>{r?.billreference}</td>
                    <td>{r?.total}</td>
                    <td className='table-data-box'>
                      {r?.status === "COMPLETED" ? (
                        <span className='pending'> Posted</span>
                      ) : r?.status==="DRAFT"? (
                        <span className='drafted'> Drafted</span>
                      ) : (
                        "---"
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={8}>No data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
