import {createSlice} from '@reduxjs/toolkit'

export const allUpiProviderSlice=createSlice({
    name:'upiProviderSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_upi_provider:(state,action)=>{
            state.value=action.payload.upiProviderData
        }
    }
})
export const { get_upi_provider} = allUpiProviderSlice.actions;
export default allUpiProviderSlice.reducer;