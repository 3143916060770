import React from "react";
import "../../../css/Customer/addCustomer.css";
import { MenuItem, Select } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import {
  addNewCustomerAPICall,
  editCustomerAPICall,
  generateCustomerIdAPICall,
  validateCustomerExistsAPICall,
  viewAllCustomersAPICall,
} from "../../../API/Customer/customerAPI";
import { useSelector } from "react-redux";
import SuccessSnackbar from "../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../Single Components/SnackBars/ErrorSnackbar";
export const AddCustomer = (props) => {
  const {
    isDialog,
    closeDialog,
    focusNewCustomer,
    updateListenerProp,
    isEdit = false,
    customerPrevData = null,
  } = props;
  const userLoginMeta = localStorage.getItem('login_meta')
  const storeCodeLs=JSON.parse(userLoginMeta)?.branchPk;
  //*Redux States
  const customerId = useSelector((state) => state.customerIdSlice.value);
  //List of All Customers
  const customerList = useSelector((state) => state.allCustomerListSlice.value);
  //Update ListenerState
  const [isModify, setIsModify] = useState(false);
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!");
  const [editingId, setEditingId] = useState(null);
  //Add Customer Form
  const customerFormInitialState = {
    customerId: "##",
    custName: "",
    mobNo: "",
    mobNo2: "",
    buildingName: "",
    streetName: "",
    landmark: "",
    email: "",
    refferedBy: "0",
    gstNo: "",
  };
  const [customerForm, setCustomerForm] = useState(customerFormInitialState);
  //Customer status code for validate customer
  const [validationStatus, setValidationStatus] = useState(null);
  //Enable inputs
  const [isEnableInputs, setIsEnableInputs] = useState(false);

  // validation states
  const [custNameAlert, setCustNameAlert] = useState(false);
  const [custMobAlert, setCustMobAlert] = useState(false);
  const [custExistAlert, setCustExistAlert] = useState(false);
  const [mobExistAlert, setMobExistAlert] = useState(false);

  //Update Listener
  const updateListener = () => {
    setIsModify(!isModify);
  };
  //close success snackbar
  const closeSuccessSnackbar = () => {
    setSuccessSnackOpen(false);
  };
  //close error snackbar
  const closeErrorSnackbar = () => {
    setErrorSnackOpen(false);
  };

  //*onChange
  const getCustomerForm = (key) => (e) => {
    const { value } = e.target;

    if (key === "custName") {
      setCustomerForm({ ...customerForm, custName: value });
      setCustNameAlert(false);
      if (value === "") {
        setCustNameAlert(true);
      }
      if (customerForm.mobNo !== "") {
        validateCustomerExistsAPICall(
          {
            name: value,
            mobileNo: customerForm.mobNo,
            branchId: storeCodeLs,
          },
          setValidationStatus
        );
      }
    }
    if (key === "mobNo") {
      setCustomerForm({ ...customerForm, mobNo: value });
      setCustMobAlert(false);
      setCustExistAlert(false);
      if (value === "") {
        setCustMobAlert(true);
      }
      if (customerForm.custName !== "") {
        validateCustomerExistsAPICall(
          {
            name: customerForm.custName,
            mobileNo: value,
            branchId: storeCodeLs,
          },
          setValidationStatus
        );
      }
    }
    if (key === "mobNo2") {
      setCustomerForm({ ...customerForm, mobNo2: value });
    }
    if (key === "buildingName") {
      setCustomerForm({ ...customerForm, buildingName: value });
    }
    if (key === "streetName") {
      setCustomerForm({ ...customerForm, streetName: value });
    }
    if (key === "landmark") {
      setCustomerForm({ ...customerForm, landmark: value });
    }
    if (key === "email") {
      setCustomerForm({ ...customerForm, email: value });
    }
    if (key === "refferedBy") {
      setCustomerForm({ ...customerForm, refferedBy: value });
    }
    if (key === "gstNo") {
      setCustomerForm({ ...customerForm, gstNo: value });
    }
  };

  //*Functions
  //Clear Form
  const clearStates = () => {
    setCustomerForm(customerFormInitialState);
    setValidationStatus(null);
    setIsEnableInputs(false);
  };
  //Cancel button
  const cancelAddCustomer = () => {
    clearStates();
    updateListener();
    closeDialog();
    setValidationStatus(null);
  };

  //assign customer data in case of edit
  const assignCustomerDataInEditMode = (custPrevData) => {
    if (custPrevData !== null) {
      const {
        _id,
        name,
        mobileNo,
        buildingName,
        streetName,
        email,
        gst,
        points,
        cusId,
        branchId,
        relId,
        alternateNumber,
        landMark,
        BRANCHID,
        relation,
        refferedBy
      } = custPrevData;

      setCustomerForm({
        ...customerForm,
        customerId: cusId,
        custName: name,
        mobNo: mobileNo,
        mobNo2: alternateNumber,
        buildingName,
        streetName,
        email,
        gstNo: gst,
        landmark:landMark,
        refferedBy
      });
      setEditingId(_id);
    }
  };

  //

  //Add New Customer Body
  const addNewCustBody = {
    name: customerForm?.custName,
    mobileNo: customerForm?.mobNo,
    buildingName: customerForm?.buildingName,
    streetName: customerForm?.streetName,
    landMark: customerForm?.landmark,
    email: customerForm?.email,
    refferedBy: customerForm?.refferedBy,
    gst: customerForm?.gstNo,
    alternateNumber: customerForm?.mobNo2,
    branchId: storeCodeLs,
    isCustomer:true
  };
  const addNewCustomer = () => {
    if (customerForm.custName === "") {
      setCustNameAlert(true);
    } else if (customerForm.mobNo === "") {
      setCustMobAlert(true);
    } else if (validationStatus === 403) {
      setCustExistAlert(true);
    } else if (!setIsEnableInputs) {
      setMobExistAlert(true);
    } else {
      addNewCustomerAPICall(
        addNewCustBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        clearStates,
        closeDialog
      );
      // console.log(addNewCustBody);
    }
  };

  //Edit body
  const editBody = {
    _id: editingId,
    name: customerForm.custName,
    mobileNo: customerForm.mobNo,
    buildingName: customerForm.buildingName,
    streetName: customerForm.streetName,
    landMark: customerForm.landmark,
    email: customerForm.email,
    refferedBy: customerForm.refferedBy,
    gst: customerForm.gstNo,
    alternateNumber: customerForm.mobNo2,
  };

  //Edit customer
  const editCustomer = () => {
    if (customerForm.custName === "") {
      setCustNameAlert(true);
    } else if (customerForm.mobNo === "") {
      setCustMobAlert(true);
    } else {
      editCustomerAPICall(
        editBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        clearStates,
        updateListenerProp,
        cancelAddCustomer
      );
    }
  };

  //*useEffects
  useEffect(() => {
    generateCustomerIdAPICall({});
    viewAllCustomersAPICall({ branchId: storeCodeLs });
  }, [isModify]);
  //Set Customer Id to State
  useEffect(() => {
    if (isEdit === false)
      setCustomerForm({ ...customerForm, customerId: customerId?.prefix });
  }, [customerId]);

  useEffect(() => {
    if (validationStatus === 409) {
      if (
        window.confirm(
          "Mobile No already registered!! would you like to add new customer??"
        ) === true
      ) {
        setIsEnableInputs(true);
      } else {
        setIsEnableInputs(false);
      }
    } else if (validationStatus === 403) {
      setIsEnableInputs(false);
      setCustExistAlert(true);
    } else if (validationStatus === 404) {
      setIsEnableInputs(true);
    }
  }, [validationStatus]);

  //Call fill customer details in case of edit
  useEffect(() => {
    if (isEdit) {
      assignCustomerDataInEditMode(customerPrevData);
      setIsEnableInputs(true);
    }
  }, [customerPrevData]);

  return (
    <div className="global-page-parent-container" style={{height:"auto"}}>
      {!isDialog && <p className="breadcrumb">Customer &gt; Add Customer</p>}
      <div className="global-white-bg-container" style={{ padding: "2% 3%" }}>
        <div className="add-customer-heading-radio-container">
          <h3>{isEdit ? "Edit Customer" : "Add Customer"}</h3>
        </div>

        {/* input container */}
        <div className="add-customer-input-container">
          <div className="global-single-input">
            <p>Customer ID</p>
            <input type="text" value={customerForm?.customerId} disabled />
          </div>
          <div className="global-single-input">
            <p>Name</p>
            <input
              type="text"
              required
              placeholder="Enter customer name..."
              value={customerForm?.custName}
              onChange={getCustomerForm("custName")}
            />
            {custNameAlert ? (
              <p className="doc-validation-alert">Fill this field !!</p>
            ) : (
              ""
            )}
            {custExistAlert ? (
              <p
                className="doc-validation-alert"
                style={{ left: "0", textAlign: "start" }}
              >
                Customer already exists !!
              </p>
            ) : (
              ""
            )}
          </div>
          <div className="global-single-input">
            <p>Mobile No</p>
            <input
              type="text"
              required
              placeholder="Enter customer contact..."
              value={customerForm?.mobNo}
              onChange={getCustomerForm("mobNo")}
              maxLength="15"
            />
            {custMobAlert ? (
              <p className="doc-validation-alert">Fill this field !!</p>
            ) : (
              ""
            )}
            {mobExistAlert ? (
              <p
                className="doc-validation-alert"
                style={{ left: "0", textAlign: "start" }}
              >
                Mobile No Already registered !!
              </p>
            ) : (
              ""
            )}
          </div>
          <div className="global-single-input">
            <p>Mobile No 2</p>
            <input
              type="text"
              required
              placeholder="Enter customer contact..."
              value={customerForm?.mobNo2}
              onChange={getCustomerForm("mobNo2")}
              maxLength="15"
            />
          </div>
          <div className="global-single-input">
            <p>Building Name</p>
            <input
              disabled={!isEnableInputs}
              type="text"
              placeholder="Enter building name..."
              value={customerForm?.buildingName}
              onChange={getCustomerForm("buildingName")}
            />
          </div>
          <div className="global-single-input">
            <p>Street Name</p>
            <input
              disabled={!isEnableInputs}
              type="text"
              placeholder="Enter street name..."
              value={customerForm?.streetName}
              onChange={getCustomerForm("streetName")}
            />
          </div>
          <div className="global-single-input">
            <p>Land Mark</p>
            <input
              disabled={!isEnableInputs}
              type="text"
              placeholder="Enter landmark..."
              value={customerForm?.landmark}
              onChange={getCustomerForm("landmark")}
            />
          </div>
          <div className="global-single-input">
            <p>Email</p>
            <input
              disabled={!isEnableInputs}
              type="text"
              placeholder="Enter email..."
              value={customerForm?.email}
              onChange={getCustomerForm("email")}
            />
          </div>
          <div className="global-single-input">
            <p>Referred By</p>
            <Select
              disabled={!isEnableInputs}
              value={customerForm?.refferedBy}
              onChange={getCustomerForm("refferedBy")}
            >
              <MenuItem value="0">-select-</MenuItem>
              {customerList
                ?.slice(0)
                ?.reverse()
                ?.map((item, i) => (
                  <MenuItem
                    value={item?._id}
                    key={i}
                  >{`${item?.cusId}-${item?.name}`}</MenuItem>
                )) || <MenuItem>No data</MenuItem>}
            </Select>
          </div>
          <div className="global-single-input">
            <p>GST IN</p>
            <input
              disabled={!isEnableInputs}
              type="text"
              placeholder="Enter GST number..."
              value={customerForm?.gstNo}
              onChange={getCustomerForm("gstNo")}
            />
          </div>
        </div>
        <div className="add-customer-button-container">
          <button
            className="btn btn-secondary-outlined"
            onClick={cancelAddCustomer}
          >
            {!isDialog ? "Cancel" : "Close"}
          </button>
          <button
            disabled={!isEnableInputs}
            className="btn btn-primary"
            onClick={isEdit ? editCustomer : addNewCustomer}
          >
            {isEdit ? "Update" : "Submit"}
          </button>
        </div>
      </div>

      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </div>
  );
};
