import { createSlice } from "@reduxjs/toolkit";

export const getWorkOrderDetailsForSalesReturnSlice=createSlice({
    name:'workOrderDetailsForSalesReturn',
    initialState:{
        value:undefined
    },
    reducers:{
        get_sales_return_details:(state,action)=>{
            state.value=action.payload.workOrderDetails
        }
    }
})
export const {get_sales_return_details} = getWorkOrderDetailsForSalesReturnSlice.actions;
export default getWorkOrderDetailsForSalesReturnSlice.reducer