import axios from "axios";
import store from "../../../../../Redux/store";
import { HEADERS, HEADER_FORM_DATA } from "../../../../../API/UrlAndPaths";
import {
  customer_payment_single_view,
  generate_customer_payment_name,
  get_customer_payment_api_res_list,
  get_customer_payment_row_data_list,
  set_customer_invoice,
} from "./customerPaymentSlice";

// list vendor bill apicall
export const viewCustomerPaymentListAPICall = async (body, setIsLoading) => {
  setIsLoading !== undefined && setIsLoading(true);
  await axios
    .post(`account/viewCustomersPayments`, body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          get_customer_payment_api_res_list({
            customerPaymentResData: res.data,
          })
        );
        setIsLoading !== undefined && setIsLoading(false);
      }
    })
    .catch((err) => {
      store.dispatch(
        get_customer_payment_api_res_list({ vendorPaymentResData: undefined })
      );
      setIsLoading !== undefined && setIsLoading(false);
    });
};
export const CustomerPaymentFilterAPICall =(body,setIsLoading)=>{
  setIsLoading!==undefined&&setIsLoading(true)

  axios.post("account/searchCustomerPayments",body,{
    headers: {
      'Content-Type': 'application/json',
    },
   
  })
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_customer_payment_api_res_list({customerPaymentResData:res.data}))
      setIsLoading!==undefined&&setIsLoading(false)
  
    }
  })
  .catch((err)=>{
    store.dispatch(get_customer_payment_api_res_list({customerPaymentResData:undefined}))
    setIsLoading!==undefined&&setIsLoading(false)
  
  })

}
// generate vendor bill name 
export const generateCustomerPaymentNameAPICall = async (body) => {
  await axios
    .post(`account/generateCustomerPaymentDraftNo`, body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          generate_customer_payment_name({ customerPaymentName: res.data })
        );
      }
    })
    .catch((err) => {
      store.dispatch(
        generate_customer_payment_name({ customerPaymentName: undefined })
      );
    });
};
// add vendor bill api call

export const draftCustomerPaymentAPICall = async (
  body,
  postFn,
  setState,
  state,
  setEditDataId
) => {
  await axios
    .post("account/addCustomerPaymentsDraft", body, HEADER_FORM_DATA)
    .then((res) => {
      if (res.status === 200) {
        localStorage.setItem("singleViewById", res.data._id);
        setState({
          ...state,
          message: "customer payment drafted",
          success: true,
        });
        postFn();
        setEditDataId(res.data?._id);
        customerPaymentSingleViewApi({ _id: res.data._id });
        localStorage.setItem("singleViewById", res.data._id);
      }
    })
    .catch((err) => {
      setState({ ...state, message: "Something went wrong !!", error: true });
    });
};
// edit vendor call

export const editCustomerPaymentAPICall = async (
  body,
  postFn,
  setState,
  state
) => {
  await axios
    .put("account/editCustomerPayment", body, HEADER_FORM_DATA)
    .then((res) => {
      if (res.status === 200) {
        setState({
          ...state,
          message: "customer payment edited",
          success: true,
        });
        postFn();
        customerPaymentSingleViewApi({ _id: res.data._id });
      }
    })
    .catch((err) => {
      setState({ ...state, message: "Something went wrong !!", error: true });
    });
};
// post vendorPayment

export const postCustomerPaymentAPICall = async (
  body,
  postFn,
  setState,
  state
) => {
  await axios
    .post("account/postCustomerPayment", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setState({
          ...state,
          message: "Customer payment posted !!",
          success: true,
        });
        postFn !== undefined && postFn();
        customerPaymentSingleViewApi({ _id: res.data._id });
      }
    })
    .catch((err) => {
      setState({ ...state, message: "Something went wrong !!", error: true });
    });
};

// customer payment single view api
export const customerPaymentSingleViewApi = async (body) => {
  await axios
    .post("account/customerPaymentById", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log(res.data);
        store.dispatch(
          get_customer_payment_row_data_list({
            customerPaymentSingleRowData: res.data,
          })
        );
      }
    })
    .catch((err) => {
      // setState({...state,message:"Something went wrong !!",error:true})
    });
};

export const showCustomerInvoiceApi = async (body)=>{
  try {
    let response = await axios.post("account/listCustomrInvoiceByCustomerId",body,HEADERS)
    if(response.status === 200){
      console.log("response.data",response.data);
      store.dispatch(set_customer_invoice(response.data))
    }
  } catch (error) {
    console.error(error.message)
  }
}

//update payment
export const updateCustomerPaymentAPI = async (
  body,
  setImportPopup,
  setSnackBarStates
) => {
  try {
    let response = await axios.post(
      "account/updateCustomerPayment",
      body,
      HEADERS
    );
    if (response.status === 200) {
      customerPaymentSingleViewApi({ _id: response.data._id });
      setImportPopup(false);
      setSnackBarStates({
        success: true,
        message: " customer payment has been updated successfully.",
        alert: false,
      });
    }
  } catch (error) {
    setSnackBarStates({ error: true, message: error.message, alert: false });
  }
};

//return payment
export const returnCustomerPaymentAPI = async (
  body,
  setAlertPopup,
  setSnackBarStates
) => {
  try {
    let response = await axios.post(
      "account/returnCustomerPayment",
      body,
      HEADERS
    );
    if (response.status === 200) {
      customerPaymentSingleViewApi({ _id: response.data._id });
      setSnackBarStates({
        success: true,
        message: " customer payment has been return successfully.",
        alert: false,
      });
      setAlertPopup(false);
    }
  } catch (error) {
    setSnackBarStates({ error: true, message: error.message, alert: false });
  }
};
export const CustomerPaymentSingleViewAPI = async (body) => {
  await axios
    .post(`account/customerPaymentById`, body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          customer_payment_single_view({ paymentSingleView: res.data })
        );
      }
    })
    .catch((err) => {
      store.dispatch(
        customer_payment_single_view({ paymentSingleView: undefined })
      );
    });
};