import axios, { Axios } from "axios";
import { update_employee_genderCount } from "../../Redux/Dashboard/employeeGenderCountSlice";
import store from "../../Redux/store";
import { HEADERS,HEADER_FORM_DATA } from "../UrlAndPaths";
import {get_dashboard_product_list} from '../../Redux/Dashboard/dashboardProductListSlice'
import {get_recent_sale} from '../../Redux/Dashboard/recentSaleSlice'
import {get_total_revenue_graph} from '../../Redux/Dashboard/totalRevenueSlice'
import {get_credit_details} from '../../Redux/Dashboard/creditDetailsSlice'
import {get_quickAccess} from '../../Redux/Dashboard/quickAccessSlice'
import {get_expired_doc} from '../../Redux/Dashboard/expiredDocumentSlice'
import { get_fast_selling_list } from "../../Redux/Dashboard/fastSellingSlice";
import { get_cash_inor_out_details } from "../../Redux/Dashboard/cashInOrOutSlice";
import { setErrorSnack, setSnackMsg, setSuccessSnack } from "../../Redux/Dashboard/mainDashBoardSlice";
//Count of employees as per gender
export const getCountOfEmployeesAPICall = async (body) => {
  await axios
    .post("dashboard/genderCnt",body,HEADERS)
    .then((res) => {
        if(res.status===200)
        {
            store.dispatch(update_employee_genderCount({employeeGenderCount:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(update_employee_genderCount({employeeGenderCount:undefined}))
        console.error(err.response);
    });
};

// product list api call
export const getDashboardProductListAPICall= async (body,setIsLoading)=>{
setIsLoading(true)
await axios.post("readymade/viewallproductsbytype",body,HEADERS)
.then((res)=>{
    if(res.status===200){
        store.dispatch(get_dashboard_product_list({dashboardProductList:res.data}))
        setIsLoading(false)
    }
})
.catch((err)=>{
    console.log(err.response);
    store.dispatch(get_dashboard_product_list({dashboardProductList:undefined}))
    setIsLoading(false)
})
}

//  recent sale api call
export const getRecentSaleAPICall= async(body,setIsLoading)=>{
setIsLoading(true)
 await axios.post("dashboard/recentsales",body,HEADERS)
 .then((res)=>{
    if(res.status===200){
        store.dispatch(get_recent_sale({recentSaleData:res.data}))
        setIsLoading(false)
    }
 })
 .catch((err)=>{
    console.log(err.response);
    setIsLoading(false)
 })
}

// total revenue graph api call
export const getTotalRevenueGraphAPICall= async (body)=>{
 await axios.post("dashboard/totalRevenueGraph",body,HEADERS)
 .then((res)=>{
    if(res.status===200){
        store.dispatch(get_total_revenue_graph({totalGraphData:res.data}))
    }
 })
 .catch((err)=>{
    console.log(err.response);
 })
}


// credit details api call

export const getCreditDetailsAPICall= async (body,setIsLoading)=>{
setIsLoading(true)
await axios.post("dashboard/creditdetails",body,HEADERS)
.then((res)=>{
    if(res.status===200){
        store.dispatch(get_credit_details({creditDetailsData:res.data}))
        setIsLoading(false)
    }
})
.catch((err)=>{
    console.log(err.response);
    setIsLoading(false)
})
}

// view quick access api call
export const viewAllQuickAccess= async ()=>{
  await axios.post("dashboard/viewQuickAccess",{},HEADERS)
  .then((res)=>{
    if(res.status===200){
        store.dispatch(get_quickAccess({quickAccessData:res.data}))
    }
  })
  .catch((err)=>{
    console.log(err.response);
  })
}

// add quick access api call
export const addQuickAccessAPICall= async (body,updateListener)=>{
    await axios.post("dashboard/addquickaccess",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            updateListener()
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}

//view expired document 
export const viewExpiredDocAPICall= async (setIsLoading)=>{
  setIsLoading(true)
  await axios.post("dashboard/viewStaffExpiredDocument",{},HEADERS)
  .then((res)=>{
    if(res.status===200){
     store.dispatch(get_expired_doc({expiredDocData:res.data}))
     setIsLoading(false)
    }
  })
  .catch((err)=>{
     store.dispatch(get_expired_doc({expiredDocData:undefined}))
     setIsLoading(false)
     console.log(err.response);
  })
}

// fast selling api call
export const getFastSellingAPICall =async(body,setIndex,setDataError)=>{
    await axios.post("dashboard/fastSelling",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_fast_selling_list({
                fastSellingData:res.data
            }))
            setDataError(false)
            }
        }).catch((err)=>{
            switch (err.response.status) {
                case 422:
                    setIndex(true)
                    setDataError(true)
                    break;
                case 404:
                    setDataError(true)
                default:
                    break;
            }
        })
        
    
}

/// add cash in or out api cal
export const addCashInOrOutAPICall=(body,setCashInOrOutDialog)=>{
    axios.post("settings/addcashinorout",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(setSnackMsg("Success"))
            store.dispatch(setSuccessSnack(true))
            viewCashInOrCashOutAPICall()
            setCashInOrOutDialog(false)
        }
    })
    .catch((err)=>{
        let errorMsg=""
        if(err.response.status===404){
            errorMsg='Server Error'
        }
        if (err.response.status === 403) {
            errorMsg='Forbidden'
        }
        if (err.response.status === 401) {
            errorMsg='Unauthorized'
        }
        if (err.response.status === 500) {
            errorMsg='Server Error'
        }
        if(err.response.status===undefined){
            errorMsg='Network Connection error!!'
        }

        store.dispatch(setSnackMsg(errorMsg))
        store.dispatch(setErrorSnack(true))
    })
}

// view cash in or out api call
export const viewCashInOrCashOutAPICall=()=>{
    axios.post("settings/listcashinorout",{},HEADERS)
    .then((res)=>{
        if(res.status===200){
          store.dispatch(get_cash_inor_out_details({cashInOrOutData:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(get_cash_inor_out_details({cashInOrOutData:undefined}))
        console.log(err.response);
    })
}