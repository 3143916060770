import { createSlice } from "@reduxjs/toolkit";

export const workOrderIdSlice=createSlice({
    name:"workOrderIdSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        update_work_order_id:(state,action)=>{
            state.value=action.payload.workOrderId
        }
    }
})

export const {update_work_order_id}=workOrderIdSlice.actions
export default workOrderIdSlice.reducer