import { createSlice } from "@reduxjs/toolkit";

export const creditToSupplierSlice = createSlice({
  name: "creditToSupplierSlice",
  initialState: {
    value: undefined,
  },
  reducers: {
    update_credit_to_supplier: (state, action) => {
      state.value = action.payload.creditToSupplier;
    },
  },
});

export const { update_credit_to_supplier } = creditToSupplierSlice.actions;
export default creditToSupplierSlice.reducer;
