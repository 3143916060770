import { createSlice } from "@reduxjs/toolkit";

export const viewPurchaseByIdSlice=createSlice({
    name:"viewPurchaseByIdSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        get_viewPurchase_byId:(state,action)=>{
            state.value=action.payload.viewPurchaseByIdList
        }
    }
})
export const {get_viewPurchase_byId}=viewPurchaseByIdSlice.actions;
export default viewPurchaseByIdSlice.reducer;