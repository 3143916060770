import { createSlice } from "@reduxjs/toolkit";

export const neckModelSlice=createSlice({
    name:"neckModelSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        update_neck_model:(state,action)=>{
            state.value=action.payload.neckModel
        }
    }
})

export const {update_neck_model}=neckModelSlice.actions
export default neckModelSlice.reducer