import {
    createSlice
} from "@reduxjs/toolkit";

export const allFittingsSlice = createSlice({
    name: 'allFittingsSlice',
    initialState: {
        value: undefined
    },
    reducers: {
        update_fittings_list: (state, action) => {
            state.value = action.payload.allFittings
        }
    }
})

export const {
    update_fittings_list
} = allFittingsSlice.actions
export default allFittingsSlice.reducer