import { Autocomplete, TextField } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getAllTaxSettingsListAPICall } from '../../../../../API/Settings/TaxSettings/taxSettingsAPI'
import { useState } from 'react'
import { addTaxConfoguration, viewPurchseTaxApiCall, viewTaxConfiguration } from '../../../../../API/Purchase Manager/configuration'

export const TaxConfig = () => {
  const purchsetaxList=useSelector((state)=>state.PurchaseTaxListSlice.value)
  const viewTaxConfig=useSelector((state)=>state.ConfigurationSlice.taxValue)

  const [purchaseTax,setPurchaseTax]=useState(null)
  const [isModify,setIsModify]=useState(false)

  const updateListner=()=>{
    setIsModify(!isModify)
  }

  useEffect(()=>{
    viewPurchseTaxApiCall()
    viewTaxConfiguration()
  },[isModify])

  const filterObjFromList=(key,list,item)=>{
    let filteredArray=[]
    filteredArray=list?.filter((obj)=>
        obj[key]===item
    )
    return filteredArray?.length!==0&& filteredArray !== undefined ? filteredArray[0]:[];
  }

  useEffect(()=>{
    setPurchaseTax(filterObjFromList("_id",purchsetaxList,viewTaxConfig?.taxId))
  },[viewTaxConfig])



  const getPurchaseTax=(e,newValue)=>{
    addTaxConfoguration({taxId:newValue?._id},updateListner)
  }

 
  return (
    <div className='costing-method-container'>
       <div className='global-single-input' style={{justifyContent:"center"}}>
         <p>Purchase Tax</p>
       </div>
       <div className='global-single-input auto-complete' style={{width:"70%",border:"1px solid #000"}}>
       <Autocomplete
                sx={{ width: "100%" }}
                options={purchsetaxList||["No Data"]}
                getOptionLabel={(option) =>option?.taxName}
                renderInput={(params) => (
                  <TextField {...params}  />
                )}
                value={purchaseTax}
                onChange={getPurchaseTax}
              
             />
       </div>
     
    </div>
  )
}
