import { createSlice } from "@reduxjs/toolkit";

export const branchSettingsSliceNew = createSlice({
  name: "branchSettingsSliceNew",
  initialState: {
    apiResList: undefined,
    rowData:undefined
  },
  reducers: {
    get_branch_settings_list: (state, action) => {
      state.apiResList = action.payload.branchSettingsData;
    },
    get_branch_settings_row_data_list: (state, action) => {
      state.rowData = action.payload.branchSettingsSingleData;
    }
  },
});

export const { get_branch_settings_list,get_branch_settings_row_data_list} = branchSettingsSliceNew.actions;

export default branchSettingsSliceNew.reducer;
