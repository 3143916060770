import { createSlice } from "@reduxjs/toolkit";

export const staffAllPayrollSlice=createSlice({
    name:'staffAllPayrollSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_all_staff_payroll_slice:(state,action)=>{
            state.value=action.payload.staffAllPayrollData
        }
    }
})

export const{update_all_staff_payroll_slice}=staffAllPayrollSlice.actions
export default staffAllPayrollSlice.reducer