import {createSlice} from "@reduxjs/toolkit"

export const addExpenseTypeSlice=createSlice({
    name:'addExpenseType',
    initialState:{
        value:undefined
    },
    reducers:{
        get_add_expense_type:(state,action)=>{
            state.value=action.payload.addExpenseTypeData
        }
    }
})
export const {get_add_expense_type} = addExpenseTypeSlice.actions

export default addExpenseTypeSlice.reducer