import axios from 'axios'
import store from '../../Redux/store'
import {get_all_commissionReport} from '../../Redux/Report/commissionReportSlice'
import {HEADERS}  from '../../API/UrlAndPaths'

export const commissionReportAPICall= async (body,setIsLoading)=>{
 setIsLoading(true)
 await axios.post("workorder/getCommissionReport",body,HEADERS)
 .then((res)=>{
    if(res.status===200){
        store.dispatch(get_all_commissionReport({commissionReportData:res.data}))
        setIsLoading(false)
      }
 })
 .catch((err)=>{
    console.log(err.response);
    store.dispatch(get_all_commissionReport({commissionReportData:undefined}))
    setIsLoading(false)
   })
 
}