import { createSlice } from "@reduxjs/toolkit";

export const cupSlice=createSlice({
    name:"cupSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        update_cup:(state,action)=>{
            state.value=action.payload.cup
        }
    }
})

export const {update_cup}=cupSlice.actions
export default cupSlice.reducer