import React from "react";
import InputBox from "../../../components/InputBox";
import DropdownInputBox from "../../../components/DropdownInputBox";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import TableBox from "../../../components/TableBox";

const heading = [
  "No",
  "Item",
  " HSN/ SAC Code",
  "Name",
  "Qty",
  "Selling Rate",
  "Discount",
  "CGST",
  "SGST",
  "IGST",
  "Total",
];

const renderData = [
  {
    item: "furniture",
    hsn: "000",
    name: "table",
    qty: "52",
    sellingRate: 5999,
    discount: "50%",
    cgst: "0%",
    sgst: "0%",
    igst: "0%",
    total: "5655",
  },
  {
    item: "furniture",
    hsn: "000",
    name: "table",
    qty: "52",
    sellingRate: 5999,
    discount: "50%",
    cgst: "0%",
    sgst: "0%",
    igst: "0%",
    total: "5655",
  },
  {
    item: "furniture",
    hsn: "000",
    name: "table",
    qty: "52",
    sellingRate: 5999,
    discount: "50%",
    cgst: "0%",
    sgst: "0%",
    igst: "0%",
    total: "5655",
  },
];

const SalesReturnVoucher = ({ selectedVoucher }) => {
  return (
    <div className="purchase-return-voucher">
      <div className="accounting-voucher-menu-sections">
        <h3>{selectedVoucher}</h3>
        <div  style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "1em 0",
            
            }}
            className="item-invoice-input-container">
          <InputBox label={"Invoice No"} />
          <InputBox label={"Invoice No"} />
          <InputBox label={"Date"} type={"date"} />
          <DropdownInputBox
            labelName={"Customer"}
            isCurrentBalance={true}
            currentBalance={5000}
          />
          <DropdownInputBox
            labelName={"Ledger Account"}
            isCurrentBalance={true}
            currentBalance={5000}
          />
          
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
          className="button-container"
        >
          <div></div>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              style={{
                background: "#fff",
                border: "1px solid black",
                color: "black",
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
            <button
              style={{
                background: "#0011f8",
                border: "none",
                color: "white",
              }}
              className="submit-btn"
            >
              Submit
            </button>
          </div>
          <div></div>
        </div>
      </div>
      <div className="accounting-voucher-menu-sections">
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div style={{ width: "100%", display: "flex" }}>
            <DropdownInputBox labelName={"Item Information"} />
            <InputBox label={"HSN / SAC Code"} />
            <InputBox label={"Stock/Qty"} />
            <InputBox label={"Quantity "} />
            <InputBox label={"Unit"} />
            <InputBox label={"Rate"} />
            <InputBox label={"IGST"} />
            <InputBox label={"SGST"} />
            <InputBox label={"CGST"} />
          </div>

          <div className="container">
            <InputBox label={"Total"} containerStyle={{ width: "17%" }} />

            <div className="voucher-add">
              <button className="add-button">
                <AddIcon />
              </button>
              <button className="delete-button">
              <i class="bi bi-trash3 delete-icon"></i>
            
              </button>
            </div>
          </div>
        </div>
        <TableBox
          isLoading={false}
          isNo={true}
          headings={heading}
          renderData={renderData}
        />

        <div
          className="total-container"
          style={{
            padding: "1em",
            background: "#edeef0",
            display: "flex",
            justifyContent: "flex-end",
            gap:' 3%'
          }}
        >
          <div className="gst-input-field ">
            <div className="gst-input-box">
              <h5>CGST</h5>
              <InputBox inputBoxStyle={{ background: "white" }} />
            </div>
            <div className="gst-input-box">
              <h5>IGST</h5>
              <InputBox inputBoxStyle={{ background: "white" }} />
            </div>
            <div className="gst-input-box">
              <h5>CGST</h5>

              <InputBox inputBoxStyle={{ background: "white" }} />
            </div>
          </div>
          <div>
            <div className="gst-input-field purchase-return-inputs">
              <div>
                <h5>Discount</h5>
                <InputBox />
              </div>
              <div>
                <h4>Total Balance</h4>
                <InputBox containerStyle={{ width: "52%" }} />
              </div>
              <div>
                <h5>CESS</h5>
                <InputBox />
              </div>
              <div>
                <h5>CESS</h5>
                <InputBox />
              </div>

              <div>
                <h5>Round Off</h5>
                <InputBox />
              </div>
              <div>
                <h4>Grand Total</h4>
                <InputBox containerStyle={{ width: "52%" }} />
              </div>
              <div>
                <h5>Received Amount</h5>
                <InputBox />
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
          className="button-container"
        >
          <div></div>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              style={{
                background: "#fff",
                border: "1px solid black",
                color: "black",
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
            <button
              style={{
                background: "#0011f8",
                border: "none",
                color: "white",
              }}
              className="submit-btn"
            >
              Submit
            </button>
          </div>
          <button
            style={{ background: "#f38a00", border: "none", color: "white" }}
            className="save-draft-btn"
          >
            Save Draft
          </button>
        </div>
      </div>
    </div>
  );
};

export default SalesReturnVoucher;
