import { createSlice } from "@reduxjs/toolkit";

export const getAllPurchsewopoIdSlice=createSlice({
    name:'purchasewopoIdSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_all_purchasewopo_id:(state,action)=>{
            state.value=action.payload.allPurchasewopoId
        }
    }
   
})
export const {get_all_purchasewopo_id} = getAllPurchsewopoIdSlice.actions;
export default getAllPurchsewopoIdSlice.reducer