import { createSlice } from "@reduxjs/toolkit";

export const purchaseWoPoSlice=createSlice({
    name:'purchaseWoPoSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_purchaseWoPo:(state,action)=>{
            state.value=action.payload.purchseWoPoList
        }
    }
})
export const {get_purchaseWoPo} = purchaseWoPoSlice.actions;
export default purchaseWoPoSlice.reducer