// convert Measurement update object into backend compatible object
export const createMeasurementUpdateBackedCompatibleObject = (
  frontEndObject = {},
  type = "thob"
) => {
  let backendObjectArray = [];
  //product items array and model type id
  const { materials, itemId } = frontEndObject;
  let items = null;
  console.log(type);
  // count length of materials array and create objects equal to the count and return as an array
  //Check item id array if it contains > 1 ids , then it is merged else it is single

  /*
        1. check itemId length
            if length > 1 the object is merged by 2 same type of object
            else the object is not merged.

        2. check materials length
            create objects equal to the length of materials array
            return array of new objects
    
    */
  switch (type) {
    case "thob":
      items = materials?.map((product) => {
        return {
          id: product.id,
          thobModel: product.thobModel,
          length: frontEndObject.length,
          shoulder: frontEndObject.shoulder,
          sleeve: frontEndObject.sleeve,
          chest: frontEndObject.chest,
          stomach: frontEndObject.stomach,
          slChest: frontEndObject.slChest,
          slSo: frontEndObject.slSo,
          neck: frontEndObject.neck,
          bottom: frontEndObject.bottom,
          marSize: frontEndObject.marSize,
          marModel: product.marModel,
          cuff: product.cuff,
          cuffSize1: product.cuffSize1,
          cuffSize2: product.cuffSize2,
          cuffCloth: product.cuffCloth,
          remark: product.remark,
          cuffModel: product.cuffModel,
          collar: product.collar,
          collarSize: product.collarSize,
          collarCloth: product.collarCloth,
          collarModel: product.collarModel,
          regalSize: frontEndObject.regalSize,
          kneeLoose: frontEndObject.kneeLoose,
          fpDown: frontEndObject.fpDown,
          fpModel: product.fpModel,
          fpSize1: frontEndObject.fpSize1,
          fpSize2: frontEndObject.fpSize2,
          pen: product.pen,
          sidePtModel: product.sidePtModel,
          stitching: product.stitching,
          button: product.button,
          neckDButton: frontEndObject.neckDButton,
          sidePTSize: product.sidePTSize,
          buttonNo: product.buttonNo,
          mobilePocket: product.mobilePocket,
          down:frontEndObject.down,
          veedi:frontEndObject.veedi,
          bag:frontEndObject.bag,
          open:frontEndObject.open,

        };
      });
      break;
    case "jalabia":
      items = materials?.map((product) => {
        return {
          id: product.id,
          jalabiaModel: product.jalabiaModel,
          length: frontEndObject.length,
          shoulder: frontEndObject.shoulder,
          sleeve: frontEndObject.sleeve,
          chest: frontEndObject.chest,
          stomach: frontEndObject.stomach,
          slChest: frontEndObject.slChest,
          slSto: frontEndObject.slSto,
          neck: frontEndObject.neck,
          bottom: frontEndObject.bottom,
          sleeveSize: frontEndObject.sleeveSize,
          neckModel: product.neckModel,
          stitching: product.stitching,
          remark: product.remark,
          regalSize: frontEndObject.regalSize,
          neckDownButton: frontEndObject.neckDownButton,
          mar: frontEndObject.mar,
          model: product.model,
          fpModel: product.fpModel,
          fpSize1: frontEndObject.fpSize1,
          fpSize2: frontEndObject.fpSize2,
        };
      });
      break;
    case "salwar":
      items = materials?.map((product) => {
        return {
          id: product.id,
          length: frontEndObject.length,
          waist: frontEndObject.waist,
          bottom: frontEndObject.bottom,
          elasticSize: frontEndObject.elasticSize,
          elasticWidth: product.elasticWidth,
          ply: frontEndObject.ply,
          thighLoose: frontEndObject.thighLoose,
          remarks: product.remarks,
        };
      });
      break;
    case "abaya":
      items = materials?.map((product) => {
        return {
          id: product.id,
          sleeveFromNeck: frontEndObject.sleeveFromNeck,
          sleeveFromShoulder: frontEndObject.sleeveFromShoulder,
          sleeveWidth: frontEndObject.sleeveWidth,
          arms: frontEndObject.arms,
          bust: frontEndObject.bust,
          waist: frontEndObject.waist,
          hip: frontEndObject.hip,
          length: frontEndObject.length,
          remarks: product.remarks,
        };
      });
      break;
    case "churidhar":
      items = materials?.map((product) => {
        return {
          id: product.id,
          shoulder: frontEndObject.shoulder,
          frontNeck: frontEndObject.frontNeck,
          backNeck: frontEndObject.backNeck,
          waist: frontEndObject.waist,
          hip: frontEndObject.hip,
          fullLength: frontEndObject.fullLength,
          armRound: frontEndObject.armRound,
          armHole: frontEndObject.armHole,
          sleeveLength: frontEndObject.sleeveLength,
          remarks: product.remarks,
        };
      });
      break;
    case "shirt":
      items = materials?.map((product) => {
        return {
          id: product.id,
          length: frontEndObject.length,
          shoulder: frontEndObject.shoulder,
          sleeveFull: frontEndObject.sleeveFull,
          chest: frontEndObject.chest,
          waist: frontEndObject.waist,
          body: frontEndObject.body,
          neck: frontEndObject.neck,
          cup: product.cup,
          regal: frontEndObject.regal,
          kneeLoose: frontEndObject.kneeLoose,
          disco: frontEndObject.disco,
          canvas: frontEndObject.canvas,
          remarks: product.remarks,
          stitching: product.stitching,
          marModel: product.marModel,
          bottomModel: product.bottomModel,
          colour: product.colour,
          seat1: frontEndObject.seat1,
          seat2: frontEndObject.seat2,
          seat3: frontEndObject.seat3,
        };
      });
      break;
    case "pant":
      items = materials?.map((product) => {
        return {
          id: product.id,
          length: frontEndObject.length,
          inside: frontEndObject.inside,
          waist: frontEndObject.waist,
          seat: frontEndObject.seat,
          thigh: frontEndObject.thigh,
          bottom: frontEndObject.bottom,
          ply: frontEndObject.ply,
          belt: frontEndObject.belt,
          sidePocket: frontEndObject.sidePocket,
          backPocket: frontEndObject.backPocket,
          pleat: frontEndObject.pleat,
          loops: frontEndObject.loops,
          remarks: product.remarks,
        };
      });
      break;
    case "coat":
      items = materials?.map((product) => {
        return {
          id: product.id,
          length: frontEndObject.length,
          shoulder: frontEndObject.shoulder,
          sleeve: frontEndObject.sleeve,
          body: frontEndObject.body,
          waist: frontEndObject.waist,
          seat: frontEndObject.seat,
          front: frontEndObject.front,
          back: frontEndObject.back,
          sleeveLoose: frontEndObject.sleeveLoose,
          fpButton: frontEndObject.fpButton,
          backOpen: frontEndObject.backOpen,
          remarks: product.remarks,
        };
      });
      break;
    case "sharvani":
      items = materials?.map((product) => {
        return {
          id: product.id,
          length: frontEndObject.length,
          shoulder: frontEndObject.shoulder,
          sleeveFull: frontEndObject.sleeveFull,
          chest: frontEndObject.chest,
          waist: frontEndObject.waist,
          body: frontEndObject.body,
          neck: frontEndObject.neck,
          cup: product.cup,
          regal: frontEndObject.regal,
          kneeLoose: frontEndObject.kneeLoose,
          disco: frontEndObject.disco,
          canvas: frontEndObject.canvas,
          remarks: product.remarks,
          stitching: product.stitching,
          marModel: product.marModel,
          bottomModel: product.bottomModel,
          colour: product.colour,
          seat1: frontEndObject.seat1,
          seat2: frontEndObject.seat2,
          seat3: frontEndObject.seat3,
        };
      });
      break;
    case "safari":
      items = materials?.map((product) => {
        return {
          id: product.id,
          length: frontEndObject.length,
          shoulder: frontEndObject.shoulder,
          sleeveFull: frontEndObject.sleeveFull,
          chest: frontEndObject.chest,
          waist: frontEndObject.waist,
          body: frontEndObject.body,
          neck: frontEndObject.neck,
          cup: product.cup,
          regal: frontEndObject.regal,
          kneeLoose: frontEndObject.kneeLoose,
          disco: frontEndObject.disco,
          canvas: frontEndObject.canvas,
          remarks: product.remark,
          stitching: product.stitching,
          marModel: product.marModel,
          bottomModel: product.bottomModel,
          colour: product.colour,
          seat1: frontEndObject.seat1,
          seat2: frontEndObject.seat2,
          seat3: frontEndObject.seat3,
        };
      });
      break;
    default:
      break;
  }


  return items


};
