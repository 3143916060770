import "../../../../../../../css/Account/reports/financialReports.css";
import CashFlowStatementDirect from "./finalReportsGroup/CashFlowStatementDirect";
import CashFlowStatementIndirect from "./finalReportsGroup/CashFlowStatementIndirect";
import FundFlowStatement from "./finalReportsGroup/FundFlowStatement";
import IncomeStatement from "./finalReportsGroup/IncomeStatement";
import BalanceSheet from "./finalReportsGroup/BalanceSheet";

const FinalReports = ({ selectedDropdownList,selectListItem }) => {
  return (
    <>
      <div className="accounting-voucher-menu" id>
        {selectedDropdownList === 1 && <CashFlowStatementDirect  selectListItem={selectListItem} />}
        {selectedDropdownList === 2 && <CashFlowStatementIndirect selectListItem={selectListItem}/>}
        {selectedDropdownList === 3 && <FundFlowStatement selectListItem={selectListItem} />}
        {selectedDropdownList === 4 && <IncomeStatement selectListItem={selectListItem} />}
        {selectedDropdownList === 5 && <BalanceSheet selectListItem={selectListItem} />}
      </div>
    </>
  );
};

export default FinalReports;
