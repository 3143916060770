import {createSlice} from '@reduxjs/toolkit'

export const singleViewOrderListSlice=createSlice({
    name:'singleViewOrderList',
    initialState:{
        value:undefined
     },
     reducers:{
        get_single_view_order_list:(state,action)=>{
            state.value=action.payload.singleViewOrderListData
        }
     }
})
export const {get_single_view_order_list} = singleViewOrderListSlice.actions;
export default singleViewOrderListSlice.reducer