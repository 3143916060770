import { createSlice } from "@reduxjs/toolkit";

export const materialSlice=createSlice({
    name:'materialSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_material_list:(state,action)=>{
            state.value=action.payload.materials
        }
    }
})

export const {update_material_list}=materialSlice.actions
export default materialSlice.reducer