import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { IconButton } from "@mui/material";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import InputBox from "../../../../../components/InputBox";

const BalanceSheet = ({ selectListItem }) => { 
  const componentRef = useRef();

  return (
    <div id="currentAsset-report">
      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="accounting-voucher-menu-sections"
      >
        <h3 style={{ margin: "2% 1%" }}>{selectListItem}</h3>
        <div className="input-current-asset-reports">
          <InputBox label={"From"} type={"date"} />
          <InputBox label={"To"} type={"date"} />
        </div>
      </div>
      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="accounting-voucher-menu-sections"
        id="cashflow-statement-direct"
      >
        <div className="action-btn-container">
          <div>
            <ReactToPrint
              trigger={() => (
                <IconButton sx={{ color: "blue" }}>
                  <i className="bi bi-printer "></i>
                </IconButton>
              )}
              content={() => componentRef.current}
            />
            <IconButton className="download-btn">
              <ArrowCircleDownIcon sx={{ fontSize: 30 }} />
            </IconButton>
          </div>
        </div>
        <div className="global-table-wrapper">
          <table className="global-table">
            <thead>
              <tr>
                <th></th>
                <th className="amount-column">2023</th>
                <th className="amount-column">2024</th>
              </tr>
            </thead>
            <tbody>
              <tr className="data-table-row-head">
                <td className="data-row">
                  <h3 className="main-head">ASSETS</h3>
                  <ul className="data-row-list">
                    <li>Current Assets</li>
                    <ul>
                      <li>Cash and Cash Equivalents</li>
                      <li>Short Term Investment</li>
                      <li>Accounts Receivable Net</li>
                      <li>Other Receivables</li>
                      <li>Inventory</li>
                      <li>Customer</li>
                      <li>Prepaid Expenses</li>
                    </ul>
                    <p className="net-cash-flow">Total Current Asset</p>
                    <li>Investments</li>
                    <li>Property , Plants and Equipment - Net</li>
                    <ul>
                      <li>Land</li>
                      <li>Land Improvements</li>
                      <li>Buildings</li>
                      <li>Equipments</li>
                      <li>Less : Accumulated Depreciation</li>
                    </ul>
                  </ul>
                  <p className="net-cash-flow">
                    Total Property , Plant and Equipment
                  </p>
                  <ul className="data-row-list">
                    <li>Intangible Assets</li>
                    <ul className="data-row-list">
                      <li>Goodwill</li>
                      <li>Other Intangible Assets</li>
                    </ul>
                  </ul>
                  <p className="net-cash-flow">Total </p>
                </td>
                <td className="data-row ">
                  <h3 className="main-head">&nbsp;</h3>
                  <ul className="data-row-amount">
                    <li className="total-amount">0.00</li>
                    <ul className="data-row-amount">
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                    </ul>
                    <p className="important">0.00</p>
                    <li className="total-amount">0.00</li>
                    <li className="total-amount">0.00</li>
                    <ul className="data-row-amount">
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                    </ul>
                  </ul>
                  <p className="important">0.00</p>
                  <ul className="data-row-amount">
                    <li className="total-amount">0.00</li>
                    <ul className="data-row-amount">
                      <li>0.00</li>
                      <li>0.00</li>
                    </ul>
                  </ul>
                  <p className="important">0.00</p>
                </td>
                <td className="data-row ">
                  <h3 className="main-head">&nbsp;</h3>
                  <ul className="data-row-amount">
                    <li className="total-amount">0.00</li>
                    <ul className="data-row-amount">
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                    </ul>
                    <p className="important">0.00</p>
                    <li className="total-amount">0.00</li>
                    <li className="total-amount">0.00</li>
                    <ul className="data-row-amount">
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                    </ul>
                  </ul>
                  <p className="important">0.00</p>
                  <ul className="data-row-amount">
                    <li className="total-amount">0.00</li>
                    <ul className="data-row-amount">
                      <li>0.00</li>
                      <li>0.00</li>
                    </ul>
                  </ul>
                  <p className="important">0.00</p>
                </td>
              </tr>
              <tr id="total-box-input" className="data-table-row-head">
                <td>
                  <ul className="list-total">
                    <li>
                      <h3 style={{ color: "blue" }}>Total Assets</h3>
                    </li>
                  </ul>
                </td>
                <td>
                  <h4>00</h4>
                </td>
                <td>
                  <h4>00</h4>
                </td>
              </tr>

              <tr className="data-table-row-head">
                <td className="data-row">
                  <h3 className="main-head">LIABILITIES</h3>
                  <ul className="data-row-list">
                    <li>Current Liability</li>
                    <ul>
                      <li>Short - Term Loans Payable</li>
                      <li>Current portion of Long - Term debt</li>
                      <li>Accounts Payable</li>
                      <li>Accrued Compensation and Benefits</li>
                      <li>Income Tax Payable</li>
                      <li>Other Accrued Liabilities</li>
                      <li>Deferred Revenues</li>
                      <li>Suppliers</li>
                    </ul>
                    <li>Profit and Loss</li>
                    <p className="net-cash-flow">Total Current Liabilities</p>
                    <li>Long - Term Liabilities</li>
                    <ul>
                      <li>Notes Payable</li>
                      <li>Bonds Payable</li>
                      <li>Deferred Income Taxes</li>
                    </ul>
                  </ul>
                  <p className="net-cash-flow">Total Long - Term Liabilities</p>
                  <ul className="data-row-list">
                    <li>Total Long - Term Liabilities</li>
                    <ul className="data-row-list">
                      <li>Common Stock</li>
                      <li>Retains Earnings</li>
                      <li>Accum other Comprehensive Income</li>
                    </ul>
                  </ul>
                  <p className="net-cash-flow">Total Stock Holders Equity</p>
                </td>

                <td className="data-row ">
                <h3 className="main-head">&nbsp;</h3>
                  <ul className="data-row-amount">
                    <li className="total-amount">0.00</li>
                    <ul className="data-row-amount">
                     <li>0.00</li>
                     <li>0.00</li>
                     <li>0.00</li>
                     <li>0.00</li>
                     <li>0.00</li>
                     <li>0.00</li>
                     <li>0.00</li>
                     <li>0.00</li>
                    </ul>
                    <li className="total-amount">0.00</li>
                    <p className="important">0.00</p>
                    <li className="total-amount">0.00</li>
                    <ul className="data-row-amount">
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                    </ul>
                  </ul>
                  <p className="important">0.00</p>
                  <ul className="data-row-amount">
                    <li className="total-amount">0.00</li>
                    <ul className="data-row-amount">
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                    </ul>
                  </ul>
                  <p className="important">0.00</p>
                </td>

                <td className="data-row ">
                <h3 className="main-head">&nbsp;</h3>
                  <ul className="data-row-amount">
                    <li className="total-amount">0.00</li>
                    <ul className="data-row-amount">
                     <li>0.00</li>
                     <li>0.00</li>
                     <li>0.00</li>
                     <li>0.00</li>
                     <li>0.00</li>
                     <li>0.00</li>
                     <li>0.00</li>
                     <li>0.00</li>
                    </ul>
                    <li className="total-amount">0.00</li>
                    <p className="important">0.00</p>
                    <li className="total-amount">0.00</li>
                    <ul className="data-row-amount">
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                    </ul>
                  </ul>
                  <p className="important">0.00</p>
                  <ul className="data-row-amount">
                    <li className="total-amount">0.00</li>
                    <ul className="data-row-amount">
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                    </ul>
                  </ul>
                  <p className="important">0.00</p>
                </td>
              </tr>
              <tr id="total-box-input" className="data-table-row-head">
                <td>
                  <ul className="list-total">
                    <li>
                      <h3 style={{ color: "blue" }}>
                        Total Liabilities and Stock Holders Equity
                      </h3>
                    </li>
                  </ul>
                </td>
                <td>
                  <h4>00</h4>
                </td>
                <td>
                  <h4>00</h4>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
       {/* print out  */}
       <div style={{ display: "none" }}>
        <div
          id="cashflow-statement-indirect"
          ref={componentRef}
          className="print-preview-container"
        >
          <div className="print-head-container">
            <div className="brand-name">
              <h2>Leeyet Textile</h2>
            </div>
            <div className="select-table-head">
              <h3>{selectListItem}</h3>
            </div>
            <div className="duration-date">
              <h3>{"01/04/2023"}</h3>
              <strong>TO</strong>
              <h3>{"01/12/2023"}</h3>
            </div>
          </div>
          <div className="global-table-wrapper">
          <table className="global-table">
            <thead>
              <tr>
                <th></th>
                <th className="amount-column">2023</th>
                <th className="amount-column">2024</th>
              </tr>
            </thead>
            <tbody>
              <tr className="data-table-row-head">
                <td className="data-row">
                  <h3 className="main-head">ASSETS</h3>
                  <ul className="data-row-list">
                    <li>Current Assets</li>
                    <ul>
                      <li>Cash and Cash Equivalents</li>
                      <li>Short Term Investment</li>
                      <li>Accounts Receivable Net</li>
                      <li>Other Receivables</li>
                      <li>Inventory</li>
                      <li>Customer</li>
                      <li>Prepaid Expenses</li>
                    </ul>
                    <p className="net-cash-flow">Total Current Asset</p>
                    <li>Investments</li>
                    <li>Property , Plants and Equipment - Net</li>
                    <ul>
                      <li>Land</li>
                      <li>Land Improvements</li>
                      <li>Buildings</li>
                      <li>Equipments</li>
                      <li>Less : Accumulated Depreciation</li>
                    </ul>
                  </ul>
                  <p className="net-cash-flow">
                    Total Property , Plant and Equipment
                  </p>
                  <ul className="data-row-list">
                    <li>Intangible Assets</li>
                    <ul className="data-row-list">
                      <li>Goodwill</li>
                      <li>Other Intangible Assets</li>
                    </ul>
                  </ul>
                  <p className="net-cash-flow">Total </p>
                </td>
                <td className="data-row ">
                  <h3 className="main-head">&nbsp;</h3>
                  <ul className="data-row-amount">
                    <li className="total-amount">0.00</li>
                    <ul className="data-row-amount">
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                    </ul>
                    <p className="important">0.00</p>
                    <li className="total-amount">0.00</li>
                    <li className="total-amount">0.00</li>
                    <ul className="data-row-amount">
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                    </ul>
                  </ul>
                  <p className="important">0.00</p>
                  <ul className="data-row-amount">
                    <li className="total-amount">0.00</li>
                    <ul className="data-row-amount">
                      <li>0.00</li>
                      <li>0.00</li>
                    </ul>
                  </ul>
                  <p className="important">0.00</p>
                </td>
                <td className="data-row ">
                  <h3 className="main-head">&nbsp;</h3>
                  <ul className="data-row-amount">
                    <li className="total-amount">0.00</li>
                    <ul className="data-row-amount">
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                    </ul>
                    <p className="important">0.00</p>
                    <li className="total-amount">0.00</li>
                    <li className="total-amount">0.00</li>
                    <ul className="data-row-amount">
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                    </ul>
                  </ul>
                  <p className="important">0.00</p>
                  <ul className="data-row-amount">
                    <li className="total-amount">0.00</li>
                    <ul className="data-row-amount">
                      <li>0.00</li>
                      <li>0.00</li>
                    </ul>
                  </ul>
                  <p className="important">0.00</p>
                </td>
              </tr>
              <tr id="total-box-input" className="data-table-row-head">
                <td>
                  <ul className="list-total">
                    <li>
                      <h3 style={{ color: "blue" }}>Total Assets</h3>
                    </li>
                  </ul>
                </td>
                <td>
                  <h4>00</h4>
                </td>
                <td>
                  <h4>00</h4>
                </td>
              </tr>

              <tr className="data-table-row-head">
                <td className="data-row">
                  <h3 className="main-head">LIABILITIES</h3>
                  <ul className="data-row-list">
                    <li>Current Liability</li>
                    <ul>
                      <li>Short - Term Loans Payable</li>
                      <li>Current portion of Long - Term debt</li>
                      <li>Accounts Payable</li>
                      <li>Accrued Compensation and Benefits</li>
                      <li>Income Tax Payable</li>
                      <li>Other Accrued Liabilities</li>
                      <li>Deferred Revenues</li>
                      <li>Suppliers</li>
                    </ul>
                    <li>Profit and Loss</li>
                    <p className="net-cash-flow">Total Current Liabilities</p>
                    <li>Long - Term Liabilities</li>
                    <ul>
                      <li>Notes Payable</li>
                      <li>Bonds Payable</li>
                      <li>Deferred Income Taxes</li>
                    </ul>
                  </ul>
                  <p className="net-cash-flow">Total Long - Term Liabilities</p>
                  <ul className="data-row-list">
                    <li>Total Long - Term Liabilities</li>
                    <ul className="data-row-list">
                      <li>Common Stock</li>
                      <li>Retains Earnings</li>
                      <li>Accum other Comprehensive Income</li>
                    </ul>
                  </ul>
                  <p className="net-cash-flow">Total Stock Holders Equity</p>
                </td>

                <td className="data-row ">
                <h3 className="main-head">&nbsp;</h3>
                  <ul className="data-row-amount">
                    <li className="total-amount">0.00</li>
                    <ul className="data-row-amount">
                     <li>0.00</li>
                     <li>0.00</li>
                     <li>0.00</li>
                     <li>0.00</li>
                     <li>0.00</li>
                     <li>0.00</li>
                     <li>0.00</li>
                     <li>0.00</li>
                    </ul>
                    <li className="total-amount">0.00</li>
                    <p className="important">0.00</p>
                    <li className="total-amount">0.00</li>
                    <ul className="data-row-amount">
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                    </ul>
                  </ul>
                  <p className="important">0.00</p>
                  <ul className="data-row-amount">
                    <li className="total-amount">0.00</li>
                    <ul className="data-row-amount">
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                    </ul>
                  </ul>
                  <p className="important">0.00</p>
                </td>

                <td className="data-row ">
                <h3 className="main-head">&nbsp;</h3>
                  <ul className="data-row-amount">
                    <li className="total-amount">0.00</li>
                    <ul className="data-row-amount">
                     <li>0.00</li>
                     <li>0.00</li>
                     <li>0.00</li>
                     <li>0.00</li>
                     <li>0.00</li>
                     <li>0.00</li>
                     <li>0.00</li>
                     <li>0.00</li>
                    </ul>
                    <li className="total-amount">0.00</li>
                    <p className="important">0.00</p>
                    <li className="total-amount">0.00</li>
                    <ul className="data-row-amount">
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                    </ul>
                  </ul>
                  <p className="important">0.00</p>
                  <ul className="data-row-amount">
                    <li className="total-amount">0.00</li>
                    <ul className="data-row-amount">
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                    </ul>
                  </ul>
                  <p className="important">0.00</p>
                </td>
              </tr>
              <tr id="total-box-input" className="data-table-row-head">
                <td>
                  <ul className="list-total">
                    <li>
                      <h3 style={{ color: "blue" }}>
                        Total Liabilities and Stock Holders Equity
                      </h3>
                    </li>
                  </ul>
                </td>
                <td>
                  <h4>00</h4>
                </td>
                <td>
                  <h4>00</h4>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
          </div>
          </div>
    </div>
  );
};

export default BalanceSheet;
