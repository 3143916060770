import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    listInputOrOutput: [],
};

const vatReportSlice = createSlice({
  name: 'vatReportSlice',
  initialState,
  reducers: {
    setListInputOrOutputReport: (state, action) => {
      state.listInputOrOutput = action.payload;
    },
  },
});

export const { setListInputOrOutputReport } = vatReportSlice.actions;

export default vatReportSlice.reducer;