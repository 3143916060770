import axios from "axios";
import { HEADERS, HEADER_FORM_DATA } from "../../UrlAndPaths";
import store from "../../../Redux/store";
import { get_Cheque_manual } from "../../../Redux/Settings/CardAndUpiType/chequeManualSlice";

//view Cheque manual api call
export const viewChequeManualAPICall = () => {
  axios
    .post("settings/viewCheque", {}, HEADERS)
    .then((res) => {
      console.log("res ponsed data--->", res);
      if (res.status === 200) {
        store.dispatch(get_Cheque_manual({ chequeManualData: res.data }));
      }
    })
    .catch((err) => {
      console.log("SOmething wrong.....");
      store.dispatch(get_Cheque_manual({ chequeManualData: undefined }));
    });
};

// add cheque manual api call
export const addChequeManualAPICall = (
  formData,
  updateListener,
  setSuccessSnackbar,
  setErrorSnackbar,
  setSnackMsg,
  clearState
) => {
  axios
    .post("settings/addCheque", formData, HEADER_FORM_DATA)
    .then((res) => {
      console.log("response is--->", res);
      if (res.status === 200) {
        setSuccessSnackbar(true);
        setSnackMsg("Success");
        updateListener();
        clearState();
      }
    })
    .catch((err) => {
      console.log("Something is wrong.....");
      setErrorSnackbar(true);
      if (err.response === undefined) {
        setSnackMsg("Network Connection error!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Already Exist");
      }

      if (err.response.status === 401) {
        setSnackMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden");
      }
      console.log(err.response);
    });
};

// edit cheque Manual apiCall

export const editChequeManualAPIcall = (
    formData,
    updateListener,
    setSuccessSnackbar,
    setErrorSnackbar,
    setSnackMsg,
    clearState
) => {
     axios.put('settings/editCheque',formData,HEADER_FORM_DATA)
     .then((res)=>{
        if(res.status ===200){
            setSuccessSnackbar(true)
            setSnackMsg('Success')
            updateListener()
            clearState()
        }
     })
     .catch((err)=>{

        setErrorSnackbar(true)
        if (err.response === undefined) {
            setSnackMsg("Network Connection error!!");
         }
          if (err.response.status === 409) {
            setSnackMsg("Already Exist");
          }
      
          if (err.response.status === 401) {
            setSnackMsg("Unauthorized");
          }
          if (err.response.status === 403) {
            setSnackMsg("Forbidden");
          }
        console.log(err.response);
     });



};
