import axios from "axios";
import { HEADERS, HEADER_QUERY } from "../../../UrlAndPaths";
import store from "../../../../Redux/store";
import { update_all_leave_type_list } from "../../../../Redux/Staff/LEAVE/allLeaveTypeSlice";

export const viewLeaveTypesAPI=async(setIsLoading,setErrorSnack,setSnackMsg)=>{
    if(setIsLoading !==undefined)setIsLoading(true)
    await axios.post("employee/viewLeavetype",{},HEADERS).then((res)=>{
        if(res.status===200){
            store.dispatch(update_all_leave_type_list({
                allLeaveType:res.data
            }))
            if(setIsLoading !==undefined)setIsLoading(false)
        }
    }).catch((err)=>{
        store.dispatch(update_all_leave_type_list({
            allLeaveType:undefined
        }))
        console.error(err.response)
        switch (err.response.status) {
            case 404:
                setSnackMsg('No data available..')
              break;
            case 500:
                setSnackMsg('Server error!!')
              break;
            case 401:
                setSnackMsg('Unauthorized!!')
              break;
            case 403:
                setSnackMsg('Forbidden!!')
              break;
          
            default:
                setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
              break;
          }
          setErrorSnack(true)
        if(setIsLoading !==undefined)setIsLoading(false)
    })
}

export const addLeaveTypeAPICall=async(body,
                            setSuccess,
                            setError,
                            setMsg,
                            updateListener,
                            clearState
    )=>{
        await axios.post('employee/addLeaveType',body,HEADERS)
            .then((res)=>{
                if(res.status===200){
                    updateListener();
                    setMsg("Success");
                    setSuccess(true);
                    clearState();
                }
            }).catch((err)=>{
                console.error(err.response)
                setError(true);
            })
        }


export const editLeaveTypeAPICall=async(body,setSuccess,
                                        setError,
                                        setMsg,
                                        updateListener)=>{
        await axios.put('employee/editLeaveType',body,HEADERS)
                    .then((res)=>{
                        if(res.status===200){
                            updateListener();
                            setMsg("Edited Successfully");
                            setSuccess(true);
                        }
                    }).catch((err)=>{
                        console.error(err.response)
                        setError(true);
                    })

            }

export const deleteLeaveTypeAPICall=async(body,updateListener)=>{
    await axios.delete('employee/deleteLeaveType',{
        headers:HEADER_QUERY,
        data:body
    })
    .then((res)=>{
        if(res.status===200){
            updateListener()
        }
    })
    .catch((err)=>{
        console.error(err.response);
    })
}