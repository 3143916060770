import { Autocomplete, IconButton, MenuItem, Select, TextField } from "@mui/material";
import React, { useState } from "react";
import "../../../../css/Staff/Loan/staffAddNewLoan.css"
import { FilePicker } from "../../../Single Components/filePicker";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getAllActiveEmployeesAPICall, getSingleEmployeeAPICall } from "../../../../API/Staff/HRM/employeeAPI";
import { viewAllBranchesAPICall } from "../../../../API/Settings/Company Settings/companySettingsAPI";
import documents from "../../../../Assets/JSON/documentType.json"
import { addNewLoanAdminAPICall, viewAllEmployeeLoan } from "../../../../API/Staff/HRM/employeeLoanAPI";
import { getCurrentTime, today } from "../../../../Js/Date";

export const StaffAddNewLoan= ()=>{
    const loanEmpId=localStorage.getItem("loanIdApprove")
    const loanSingleId=localStorage.getItem("singleLoaniId")
    // states
    const [selectedEmployeeObj, setSelectedEmployeeObj] = useState(null);
    const [selectedBranchObj, setSelectedBranchObj] = useState(null);
    const [filteredEmployee, setFilteredEmployee] = useState([]);
    const [filteredBranchName, setFilteredBranchName] = useState([]);
    const [showEmployeeDetails, setShowEmployeeDetails] = useState(true);
    
    // add documents states
    const [addDocumentTable, setAddDocumentTable] = useState([]);
    const [addDocImg, setAddDocImg] = useState(null);
    const [docTypeAlert,setDocTypeAlert]=useState(false);
    const [docNameAlert,setDocNameAlert]=useState(false);
    const [docImgAlert,setDocImgAlert]=useState(false);
    const [isModify,setIsModify]=useState(false);

    // validationsttes
    const [selectBranchAlert,setSelectBranchAlert]=useState(false);
    const [selectEmpAlert,setSelectEmpAlert]=useState(false);
    const [paymentMtdAlert,setPaymentMtdAlert]=useState(false);
    const [startDateAlert,setStartDateAlert]=useState(false);
    const [interestAmtAlert,setInterestAmtAlert]=useState(false);
    const [interestTypeAlert,setInterestTypeAlert]=useState(false);
    const [loanTypeAlert,setLoanTypeAlert]=useState(false);
    const [interestRateAlert,setInterestRateAlert]=useState(false);
    const [endDateAlert,setEndDateAlert]=useState(false);
    const [remainingAmtAlert,setRemainingAmtAlert]=useState(false);
    const [loanAmtAlert,setLoanAmtAlert]=useState(false);
    const [tenureAlert,setTenureAlert]=useState(false);
    const [xtraIntAmtAlert,setXtraIntAmtAlert]=useState(false);
    const [singleLoanView, setSingleLoanView] = useState([]);
      // useSelector Slice allbranches,allEmployees
      const allActiveEmpList = useSelector(
        (state) => state.allActiveEmployeeSlice.value
      );
    const allBranchesList = useSelector(
        (state) => state.allBranchesSlice.value
        );
    const singleEmpData = useSelector((state) => state.singleEmployeeSlice.value);

    const empAllLoanList =useSelector(
        (state)=>state.allEmployeeLoanSlice.value
     )

    const selectedEmpFormInitialState ={
        empDetailsId:loanEmpId,
        empId:"",
        contact:"",
        department:"",
        designation:"",
        branchName:"",
        branchId:"",
        bankName:"",
        accHolderName:"",
        AccntNo:"",
        ifscCode:"",
        bankBranch:"",
        empName:"",
        paymentMethod:"Fully amortising payment",
        loanType:"",
        loanAmt:"",
        startDate:"",
        interestRate:"",
        tenure:"",
        interestAmt:"",
        endDate:"",
        xtraInterestAmt:"",
        interestType:"",
        remainingAmt:"",
        loanReqDate:`${today} ${getCurrentTime()}`,
        docType:"",
        docNo:"",
        docImg:""
    }
    const[selectedEmpForm,setSelectedEmpForm]=useState(selectedEmpFormInitialState);
    
    const loanTypeList=["Interest free loan","Salary loan","Education loan",
                        "Car loan","Emergency loan","Home loan","Personal loan"
                        ]
    const interestTypeList=["Fixed interest rate","Variable interest rate","Annual percentage rate",
                        "Prime interest rate","Discount interest rate","Simple interest rate","Compound interest rate"
                        ]
    const clearStates=()=>{
        localStorage.removeItem('loanIdApprove')
        localStorage.removeItem('singleLoaniId')
        setSelectedEmpForm(selectedEmpFormInitialState);
        setAddDocImg(null)
        setIsModify(!isModify)
    }

    // clear Document Table
    // const clearDocumentTable=()=>{
    //     setAddLoanDocumentForm(addLoanDocumentFormInitialState)
    //     setAddDocImg(null)
    // }
    // add document submit call
    // const clickSubmitDoc=()=>{
    //     if(selectedEmpForm?.docType===""){
    //         setDocTypeAlert(true)
    //     }
    //     else if(selectedEmpForm?.docNo===""){
    //         setDocNameAlert(true)
    //     }
    //     else if(selectedEmpForm?.docImg===null){
    //         setDocImgAlert(true)
    //     }

    //     else{
    //     setAddDocumentTable([...addDocumentTable,
    //         {
    //         docType:addLoanDocumentForm?.docType,
    //         docNo:addLoanDocumentForm?.docNo,
    //         docImg:addLoanDocumentForm?.docImg,
    //     }
    //     ])
    //     clearDocumentTable()
    //     }
    // }

    // delete documentlisted row
    // const deleteTableRow=(index)=>{
    //     addDocumentTable.splice(index,1)
    //     setAddDocumentTable([...addDocumentTable])        
    // }
    
    // Onchange of Branch & employee selecting
    const getEmployeeNameChange=(key)=>(e,newValue)=>{
        const {value}=e.target
        if(key ==="branchName"){
            setSelectedBranchObj(newValue)
            if(newValue!== null){

                setSelectedEmpForm({...selectedEmpForm,branchId:newValue.storeCode !== undefined && newValue.storeCode})
                
                setSelectBranchAlert(false)
            }
            if(newValue===null){
                setSelectedEmpForm(selectedEmpFormInitialState)
                setSelectedEmployeeObj(null)
                setSelectBranchAlert(true)
            }
                 
        }
        if(key ==="empName"){
            if(newValue===""){
                setSelectedEmpForm(selectedEmpFormInitialState)
                setSelectEmpAlert(true)
            }
            else{
                setSelectEmpAlert(false)
                setSelectedEmployeeObj(newValue)}
        }
        if(key === "paymntMthd"){
            setSelectedEmpForm({...selectedEmpForm,paymentMethod:value})
            if(selectedEmpForm.empId==="" || selectedEmpForm.branchId===""){
                setSelectEmpAlert(true)
                setSelectBranchAlert(true)
            }
            setPaymentMtdAlert(false)
            if(value===""){
                setPaymentMtdAlert(true)
            }
            
        }         
        if(key === "startDate"){
            setSelectedEmpForm({...selectedEmpForm,startDate:value})
            setStartDateAlert(false)
            if(value===""){
                setStartDateAlert(true)
            }
        }         
        if(key === "interestAmt"){
            setSelectedEmpForm({...selectedEmpForm,interestAmt:value})
            setInterestAmtAlert(false)
            if(value===""){
                setInterestAmtAlert(true)
            }
        }            
        if(key === "interestRate"){
            setSelectedEmpForm({...selectedEmpForm,interestRate:value})
            setInterestRateAlert(false)
            if(value===""){
                setInterestRateAlert(true)
            }
        }         
        if(key === "endDate"){
            setSelectedEmpForm({...selectedEmpForm,endDate:value})
            setEndDateAlert(false)
            if(value===""){
                setEndDateAlert(true)
            }
        }         
        if(key === "remainingAmt"){
            setSelectedEmpForm({...selectedEmpForm,remainingAmt:value})
            setRemainingAmtAlert(false)
            if(value===""){
                setRemainingAmtAlert(true)
            }
        }         
        if(key === "loanAmt"){
            setSelectedEmpForm({...selectedEmpForm,loanAmt:value})
            setLoanAmtAlert(false)
            if(value===""){
                setLoanAmtAlert(true)
            }
        }         
        if(key === "tenure"){
            setSelectedEmpForm({...selectedEmpForm,tenure:value})
            setTenureAlert(false)
            if(value===""){
                setTenureAlert(true)
            }
        }         
        if(key === "xtraInterestAmt"){
            setSelectedEmpForm({...selectedEmpForm,xtraInterestAmt:value})
            setXtraIntAmtAlert(false)
            if(value===""){
                setXtraIntAmtAlert(true)
            }
        }         
    }

// add new loan submit
    const submitAddNewLoan=()=>{
        if(selectedEmpForm?.branchId===""){
            setSelectBranchAlert(true)
        }
        else if(selectedEmpForm?.empId===""){
            setSelectEmpAlert(true)
        }
        else if(selectedEmpForm?.paymentMethod===""){
            setPaymentMtdAlert(true)
        }
        else if(selectedEmpForm?.loanType===""){
            setLoanTypeAlert(true)
        }
        else if(selectedEmpForm?.loanAmt===""){
            setLoanAmtAlert(true)
        }
        else if(selectedEmpForm?.startDate===""){
            setStartDateAlert(true)
        }
        else if(selectedEmpForm?.interestRate===""){
            setInterestRateAlert(true)
        }
        else if(selectedEmpForm?.tenure===""){
            setTenureAlert(true)
        }
        else if(selectedEmpForm?.interestAmt===""){
            setInterestAmtAlert(true)
        }
        else if(selectedEmpForm?.endDate===""){
            setEndDateAlert(true)
        }
        else if(selectedEmpForm?.xtraInterestAmt===""){
            setXtraIntAmtAlert(true)
        }
        else if(selectedEmpForm?.interestType===""){
            setInterestTypeAlert(true)
        }
        else if(selectedEmpForm?.remainingAmt===""){
            setRemainingAmtAlert(true)
        }
        else{
            addNewLoanAdminAPICall(addLoanBodyToApi,clearStates)
            // clearStates();
            // console.log(addLoanBodyToApi);
        }
    }

    // onchange of Doc image selecting
    const filePickerChange=(e) =>{ 
        if(e.target.files[0]===undefined){
            setSelectedEmpForm({
                ...selectedEmpForm,docImg:null})
            setDocImgAlert(true)
        }
        else{
            setDocImgAlert(false)
            setAddDocImg(e.target.files[0]);
            setSelectedEmpForm({
            ...selectedEmpForm,docImg:URL.createObjectURL(e.target.files[0])
            })
        }
    }


    const addLoanBodyToApi={
        empId:selectedEmpForm.empDetailsId,
        branchId:selectedEmpForm.branchId,
        actualLoanAmount:selectedEmpForm.loanAmt,
        tenur:selectedEmpForm.tenure,
        // balanceAmount:selectedEmpForm.empDetailsId,
        paymentMethod:selectedEmpForm.paymentMethod,
        loanType:selectedEmpForm.loanType,
        loanstartDate:selectedEmpForm.startDate,
        loanRequestedDate:singleLoanView.length===0?selectedEmpForm.loanReqDate:singleLoanView[0]?.loanRequestedDate,
        interestRate:selectedEmpForm.interestRate,
        interestAmount:selectedEmpForm.interestAmt,
        endDate:selectedEmpForm.endDate,
        extraInterestAmount:selectedEmpForm.xtraInterestAmt,
        interestType:selectedEmpForm.interestType,
        documentType:selectedEmpForm.docType,
        documentNumber:selectedEmpForm.docNo,
        imageUrl:selectedEmpForm.docImg,
    }
    // extracting singleLoan object from allLoans
   useEffect(()=>{
       if(empAllLoanList !== undefined){
          const filteredSingleView=empAllLoanList?.filter((obj)=>{
             if(obj?._id === loanSingleId){
                return obj
             }
          })
          setSingleLoanView(filteredSingleView)
       }
   },[loanEmpId,empAllLoanList])
   console.log(singleLoanView);
   console.log(addLoanBodyToApi);


    useEffect(() => {
        if(loanEmpId!==null){
        getSingleEmployeeAPICall({ id: loanEmpId });
        }
      }, [isModify]);
    // API call getall branches&employee 
    useEffect(()=>{
        getAllActiveEmployeesAPICall();
        viewAllBranchesAPICall();
        viewAllEmployeeLoan()
    },[])

    // extracting employees on selected branches
    useEffect(()=>{
        let filteredData=[];
             filteredData =allActiveEmpList?.filter(
            (obj)=>{
                if(obj.branchId===selectedEmpForm.branchId){
                    return obj;
                }
            }
        );
        setFilteredEmployee(filteredData);

        let filteredBranchData = allBranchesList?.filter((obj) => {
            if (obj.storeCode === selectedEmpForm.branchId) {
              return obj;
            }
          });
          setFilteredBranchName(filteredBranchData);
    },[selectedEmpForm?.branchId])
    console.log(selectedEmpForm);
    useEffect(()=>{
        setSelectedEmpForm({...selectedEmpForm,branchId:singleEmpData?.branchId,
                            empId:singleEmpData?.emp_id,department:singleEmpData?.departmentName,
                            designation:singleEmpData?.designationName,contact:singleEmpData?.contactnumber,
                            bankName:singleEmpData?.bank,accHolderName:singleEmpData?.ac_holder,
                            AccntNo:singleEmpData?.ac_no,ifscCode:singleEmpData?.bank_code,
                            bankBranch:singleEmpData?.bankLocation,empName:singleEmpData?.staff_name})
    },[singleEmpData])


    // extracting details of selected employees
    useEffect(()=>{
        if(selectedEmployeeObj !== null && selectedEmployeeObj !== undefined){
            console.log(selectedEmployeeObj);
            const {emp_id,contactnumber,departmentName,designationName,bank,bank_code,bankLocation,ac_no,ac_holder,_id}=selectedEmployeeObj;
            setSelectedEmpForm({...selectedEmpForm, empId:emp_id, contact:contactnumber,empDetailsId:_id,
            department:departmentName, designation:designationName,
            bankName:bank, accHolderName:ac_holder,
            AccntNo:ac_no, ifscCode:bank_code,bankBranch:bankLocation        
            })
        }
        if(selectedEmployeeObj === null){
            setSelectedEmpForm({...selectedEmpForm,empId:"", contact:"" ,
            department:"", designation:""})
         }
        if(selectedBranchObj === null){
            setSelectedEmpForm({...selectedEmpForm,empId:"", contact:"" ,
            department:"", designation:""})
        }
    },[selectedEmployeeObj,selectedBranchObj])

    useEffect(()=>{
        if(loanEmpId===null){
            setShowEmployeeDetails(false)
        }
        else{
            setShowEmployeeDetails(true)
        }
    },[clearStates])
    return(
        <>
        <div className="global-page-parent-container">
            <p className="breadcrumb">Staff &gt; Loan &gt; Add New Loan</p>
            <div className="staff-add-new-loan-ctn">
                <h3>Create New Loan</h3>
                {showEmployeeDetails?
                <button className="btn add-loan-clear-btn" onClick={clearStates}>Clear X</button>
                :""
                }
                <div className="staff-add-new-loan-ctn-section">
                    <h4>Employee Details</h4>
                    <div className="staff-add-new-loan-ctn-section-container">
                        <div className="staff-add-new-loan-ctn-section-column">
                            <div className="global-single-input auto-complete validation-parent-input">
                                
                                {showEmployeeDetails?
                                    <>
                                    <p>Branch Name</p>
                                    <input type="text" 
                                        value={filteredBranchName!==undefined && filteredBranchName[0]?.branchName?.toUpperCase()}
                                        disabled />
                                    </>
                                    :
                                    <>
                                    <p>Select Branch</p>
                                    <Autocomplete
                                    sx={{width:"100%"}}
                                    options={allBranchesList || [" "]}
                                    getOptionLabel={(option) => option?.branchName}
                                    renderInput={(params)=> (
                                        <TextField {...params} placeholder="Select A Branch"
                                        />
                                        )}
                                    onChange={getEmployeeNameChange("branchName")}
                                    />
                                    {selectBranchAlert?
                                    <p className="doc-validation-alert">Select A Branch !!</p>:""}
                                    </>
                                }
                            </div>
                            
                            <div className="global-single-input">
                                <p>Department</p>
                                <input type="text" 
                                value={selectedEmpForm?.department}
                                disabled />
                            </div>
                            {/* <div className="global-single-input">
                                <p>Department Manager</p>
                                <input type="text" 
                                value={selectedEmpForm?.empId}/>
                            </div> */}
                        </div>
                        <div className="staff-add-new-loan-ctn-section-column">
                        <div className="global-single-input auto-complete validation-parent-input">
                                
                                { showEmployeeDetails?
                                    <>
                                        <p>Employee Name</p>
                                        <input type="text" 
                                        value={selectedEmpForm?.empName}
                                        disabled />
                                    
                                    </>:

                                    <>
                                    <p>Employee Name</p>
                                    <Autocomplete
                                    sx={{width:"100%"}}
                                    // defaultValue={noOption}
                                    options={filteredEmployee || [""]}
                                    getOptionLabel={(option) => `${option?.emp_id}-${option?.staff_name}`}
                                    renderInput={(params)=> (
                                        <TextField {...params} placeholder="Select Employee"
                                        />
                                        )}
                                    onChange={getEmployeeNameChange("empName")}
                                    />
                                    {selectEmpAlert?
                                    <p className="doc-validation-alert">Select An Employee !!</p>:""}
                                    </>

                                }
                                
                                
                            </div>
                            
                            <div className="global-single-input">
                                <p>Designation</p>
                                <input type="text" 
                                value={selectedEmpForm?.designation}
                                disabled />
                            </div>
                        </div>
                        <div className="staff-add-new-loan-ctn-section-column">
                            <div className="global-single-input">
                                <p>Employee ID</p>
                                <input type="text" 
                                value={selectedEmpForm?.empId}
                                disabled
                                />
                            </div>
                            <div className="global-single-input">
                                <p>Contact</p>
                                <input type="text" 
                                value={selectedEmpForm?.contact}
                                disabled />
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="staff-add-new-loan-ctn-section">
                    <h4>Loan Details</h4>
                    <div className="staff-add-new-loan-ctn-section-container">
                        <div className="staff-add-new-loan-ctn-section-column">
                            <div className="global-single-input validation-parent-input">
                                <p>Payment Method</p>
                                <Select value={selectedEmpForm?.paymentMethod} onChange={getEmployeeNameChange("paymntMthd")}>
                                    <MenuItem value="Fully amortising payment">Fully amortising payment</MenuItem>
                                    <MenuItem value="Interest only payment">Interest only payment</MenuItem>
                                    <MenuItem value="graduated payment">graduated payment</MenuItem>
                                    <MenuItem value="Negative amortisation">Cheque</MenuItem>
                                </Select>
                                {/* <input type="text" value={selectedEmpForm?.paymentMethod}
                                onChange={getEmployeeNameChange("paymntMthd")} /> */}
                                {paymentMtdAlert?
                                <p className="doc-validation-alert">Select A Payment Method !!</p>:""}
                            </div>
                            <div className="global-single-input validation-parent-input">
                                <p>Start Date</p>
                                <input type="date" value={selectedEmpForm?.startDate}
                                onChange={getEmployeeNameChange("startDate")} />
                                {startDateAlert?
                                <p className="doc-validation-alert">Please Fill This !!</p>:""}
                            </div>
                            <div className="global-single-input validation-parent-input">
                                <p>Interest Amount</p>
                                <input type="text" value={selectedEmpForm?.interestAmt}
                                onChange={getEmployeeNameChange("interestAmt")} />
                                {interestAmtAlert?
                                <p className="doc-validation-alert">Please Fill This !!</p>:""}
                            </div>
                            <div className="global-single-input validation-parent-input auto-complete">
                                <p>Interest Type</p>
                                <Autocomplete 
                                 sx={{width:"100%"}}
                                 options={interestTypeList}
                                 getOptionLabel={(option)=>option}
                                 renderInput={(params)=>(
                                    <TextField
                                      {...params} 
                                      placeholder="Select a interest type"
                                    />
                                 )}
                                 value={selectedEmpForm?.interestType}
                                 onChange={(e,newValue)=>{
                                    setSelectedEmpForm({...selectedEmpForm,interestType:newValue})
                                    setInterestTypeAlert(false)
                                    if(newValue===null){
                                        setInterestTypeAlert(true)
                                    }
                                 }}
                                />
                                {interestTypeAlert?
                                <p className="doc-validation-alert">Please Fill This !!</p>:""}
                            </div>
                        </div>
                        <div className="staff-add-new-loan-ctn-section-column">
                            <div className="global-single-input validation-parent-input auto-complete">
                                <p>Loan Type</p>
                                <Autocomplete
                                sx={{width:"100%"}}
                                options={loanTypeList}
                                getOptionLabel={(option)=>option}
                                renderInput={(params)=>(
                                    <TextField {...params} 
                                    placeholder="Select a Loan type"
                                    />
                                    )}
                                value={selectedEmpForm?.loanType}
                                onChange={(e,newValue)=>{
                                    setSelectedEmpForm({...selectedEmpForm,loanType:newValue})
                                    setLoanTypeAlert(false)
                                    if(newValue===null){
                                        setLoanTypeAlert(true)
                                    }
                                }}
                                />
                                {loanTypeAlert?
                                <p className="doc-validation-alert">Please Fill This !!</p>:""}
                            </div>
                            <div className="global-single-input validation-parent-input">
                                <p>Interest Rate</p>
                                <input type="text" value={selectedEmpForm?.interestRate}
                                onChange={getEmployeeNameChange("interestRate")} />
                                {interestRateAlert?
                                <p className="doc-validation-alert">Please Fill This !!</p>:""}
                            </div>
                            <div className="global-single-input validation-parent-input">
                                <p>End Date</p>
                                <input type="date" value={selectedEmpForm?.endDate}
                                onChange={getEmployeeNameChange("endDate")} />
                                {endDateAlert?
                                <p className="doc-validation-alert">Please Fill This !!</p>:""}
                            </div>
                            <div className="global-single-input validation-parent-input">
                                <p>Remaining Amount</p>
                                <input type="text" value={selectedEmpForm?.remainingAmt}
                                onChange={getEmployeeNameChange("remainingAmt")} />
                                {remainingAmtAlert?
                                <p className="doc-validation-alert">Please Fill This !!</p>:""}
                            </div>
                        </div>
                        <div className="staff-add-new-loan-ctn-section-column">
                            <div className="global-single-input validation-parent-input">
                                <p>Loan Amount</p>
                                <input type="text" value={selectedEmpForm?.loanAmt}
                                onChange={getEmployeeNameChange("loanAmt")} />
                                {loanAmtAlert?
                                <p className="doc-validation-alert">Please Fill This !!</p>:""}
                            </div>
                            <div className="global-single-input validation-parent-input">
                                <p>Tenure</p>
                                <input type="text" value={selectedEmpForm?.tenure}
                                onChange={getEmployeeNameChange("tenure")} />
                                {tenureAlert?
                                <p className="doc-validation-alert">Please Fill This !!</p>:""}
                            </div>
                            <div className="global-single-input validation-parent-input">
                                <p>Extra Interest Amount</p>
                                <input type="text" value={selectedEmpForm?.xtraInterestAmt}
                                onChange={getEmployeeNameChange("xtraInterestAmt")} />
                                {xtraIntAmtAlert?
                                <p className="doc-validation-alert">Please Fill This !!</p>:""}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="staff-add-new-loan-ctn-section">
                    <h4>Bank Details</h4>
                    <div className="staff-add-new-loan-ctn-section-container">
                        <div className="staff-add-new-loan-ctn-section-column">
                            <div className="global-single-input">
                                <p>Bank Name</p>
                                <input type="text" value={selectedEmpForm?.bankName}
                                disabled />
                            </div>
                            <div className="global-single-input">
                                <p>IFSC Code</p>
                                <input type="text" value={selectedEmpForm?.ifscCode}
                                disabled />
                            </div>
                        </div>
                        <div className="staff-add-new-loan-ctn-section-column">
                            <div className="global-single-input">
                                <p>Account Holder Name</p>
                                <input type="text" value={selectedEmpForm?.accHolderName}
                                disabled />
                            </div>
                            <div className="global-single-input">
                                <p>Branch</p>
                                <input type="text" value={selectedEmpForm?.bankBranch}
                                disabled />
                            </div>
                        </div>
                        <div className="staff-add-new-loan-ctn-section-column">
                            <div className="global-single-input">
                                <p>Account No</p>
                                <input type="text" value={selectedEmpForm?.AccntNo}
                                disabled />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="staff-add-new-loan-ctn-section">
                    <h4>Add Document</h4>
                    <div className="staff-add-new-loan-ctn-section-container">
                        <div className="staff-add-new-loan-ctn-section-column">
                            <div className="global-single-input validation-parent-input">
                                <p>Select Document Type</p>
                                <Select
                                value={selectedEmpForm?.docType}
                                onChange={(e) =>{
                                    setSelectedEmpForm({
                                      ...selectedEmpForm,
                                      docType: e.target.value,
                                    });
                                    setDocTypeAlert(false) 
                                }
                                  }
                                //   disabled
                                >
                                {documents?.map((item, i) => (
                                    <MenuItem value={item?.name} key={i}>
                                        {item?.name}
                                    </MenuItem>
                                ))}
                                </Select>
                                {docTypeAlert?
                                <p className="doc-validation-alert">Select A Document !!</p>:""}
                            </div>
                        </div>
                        <div className="staff-add-new-loan-ctn-section-column">
                            <div className="global-single-input validation-parent-input">
                                <p>Document No</p>
                                <input type="text" placeholder="Enter Document No" 
                                onChange={(e)=>{
                                setSelectedEmpForm({
                                    ...selectedEmpForm,docNo:e.target.value
                                });
                                setDocNameAlert(false);
                                if(e.target.value===""){
                                    setDocNameAlert(true)
                                }
                            }   
                            }
                                value={selectedEmpForm?.docNo}
                                />
                                {docNameAlert?
                                <p className="doc-validation-alert">Enter The Details !!</p>:""}
                            </div>
                        </div>
                        <div className="staff-add-new-loan-ctn-section-column">
                            <div className="global-single-input loan-add-image validation-parent-input">
                                <p>Upload Image</p>
                                    
                                <FilePicker
                                    id="documentApplyProofImg"
                                    uploadImageUrl={addDocImg}
                                    setImage={setAddDocImg}
                                    onChange={filePickerChange}
                                />
                                {docImgAlert?
                                <p className="doc-validation-alert file-picker-validation">Select a File !!</p>:""}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <button onClick={clickSubmitDoc} className="btn btn-primary" style={{alignSelf:"center"}} >Submit</button>

                <table className="global-table staff-add-loan-table">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Document No</th>
                            <th>Image</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {addDocumentTable?.length !==0 ?
                            addDocumentTable
                        ?.map((r,i)=>(
                            <tr key={i}>
                                <td>{r?.docType}</td>
                                <td>{r?.docNo}</td>
                                <td><img src={r?.docImg} alt="Doc Img" /></td>
                                <td><IconButton onClick={()=>deleteTableRow(i)}>
                                    <i class="bi bi-trash3 delete-icon"></i>
                                    </IconButton></td>
                            </tr>
                        ))
                        :<tr >
                            <td colspan={4}>No Documents Added</td>
                        </tr>
                        
                        }
                        
                    </tbody>
                </table> */}

                <div className="staff-add-new-loan-ctn-bottom">
                    <button className="btn btn-secondary-outlined" onClick={clearStates}>Cancel</button>
                    <button className="btn btn-secondary" onClick={submitAddNewLoan}>Submit</button>
                </div>
            </div>
        </div>
        </>
    );
};