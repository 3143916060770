import { createSlice } from "@reduxjs/toolkit";

export const allReadymadeCategoriesSlice=createSlice({
    name:'allReadymadeCategories',
    initialState:{
        value:undefined
    },
    reducers:{
        update_allReadymade_categories:(state,action)=>{
            state.value=action.payload.allReadymadeCategories
        }
    }
})
export const {update_allReadymade_categories}=allReadymadeCategoriesSlice.actions
export default allReadymadeCategoriesSlice.reducer