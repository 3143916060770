// import { createSlice } from "@reduxjs/toolkit";

import { createSlice } from "@reduxjs/toolkit";

// export const OpeningBalanceSlice = createSlice ({
//     name:"OpeningBalanceSlicee",
//     initialState:{
//         OpeningBalance:undefined,
//     },
//     reducers:{
//         view_opening_balance:(state,action)=>{
//             state.OpeningBalance=action.payload.ViewOpening;
//         }
//     }
// });
// export const {view_opening_balance}= OpeningBalanceSlice.actions;
// export default OpeningBalanceSlice.reducer

export const OpeningBalanceSlice = createSlice({
    name:"Openingbalance",
    initialState:{
        balance:undefined,
        customerVendor:undefined
    },
    reducers:{
        view_opening_balance:(state,action)=>{
          state.balance=action.payload.ViewOpening;
    },
    view_customer_vendor_list:(state,action)=>{
          state.customerVendor=action.payload.customerVendorData
    }
}
});
export const { view_opening_balance,view_customer_vendor_list}=OpeningBalanceSlice.actions
export default OpeningBalanceSlice.reducer