import { createSlice } from "@reduxjs/toolkit";

export const transferReportSlice=createSlice({
    name:'transferReportSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_transfer_report:(state,action)=>{
            state.value=action.payload.transferReportData
        }
    }
})
export const { get_transfer_report} = transferReportSlice.actions;
export default transferReportSlice.reducer;