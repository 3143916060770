import { createSlice } from "@reduxjs/toolkit";

export const fastSellingSlice=createSlice({
    name:"fastSelllingSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        get_fast_selling_list:(state,action)=>{
            state.value=action.payload.fastSellingData
        }
    }
})

export const {get_fast_selling_list}=fastSellingSlice.actions;
export default fastSellingSlice.reducer