import {createSlice} from '@reduxjs/toolkit'

export const commissionReportSlice=createSlice({
   name:'commissionReportSlice',
   initialState:{
    value:undefined
   },
   reducers:{
    get_all_commissionReport:(state,action)=>{
     state.value=action.payload.commissionReportData
    }
}
})

export const {get_all_commissionReport} = commissionReportSlice.actions;
export default commissionReportSlice.reducer