import { createSlice } from "@reduxjs/toolkit";
export const utilityMenuSlice = createSlice({
  name: "utilityMenuSlice",
  initialState: {
    value: 'utilitySettingsPayments',
  },
  reducers: {
    update_select_utility: (state, action) => {
      state.value = action.payload.selectUtility;
    }
  },
});
export const { update_select_utility } = utilityMenuSlice.actions;
export default utilityMenuSlice.reducer;