import React from "react";
import "../../../../css/Sales/invoice.css";
import Logo from "../../../../Assets/Images/Logo.png";
import barcode from "../../../../Assets/Images/barcode.png";
import qrcode from "../../../../Assets/Images/qrCodeImage.png";
import { convertDateFormat } from "../../../../Js/Date";
import Barcode from "react-barcode";
import QRCode from "react-qr-code";

export const Invoice = (props) => {
  const { data,isCopy} = props;
  //  console.log(data);
  //  console.log(data?.orderDate?.split(" ")[0]);
  //  console.log(data?.orderDate?.split(" ")[1]);

  console.log(data);

  return (
    <div className="invoice-main-container">
      <div className="invoice-first-container">
        <div className="invoice-first-left-container">
          <img src={data?.companyLogo} alt="logo" />
          <h4>{data?.companyName}</h4>
          <h4 className="arabic-text"> {data?.nativecompanyName!==null ?data?.nativecompanyName : ""}</h4>
          <p>{data?.website}</p>
        </div>
        <div className="invoice-first-right-container">
        {
          isCopy == true && <h5 style={{textAlign:"right"}}>Copy</h5>
        }
          <h5>
            Date&nbsp;:&nbsp;
            {data?.orderDate !== undefined && convertDateFormat(data?.orderDate?.split(" ")[0])}
          </h5>
          <h5 className="arabic-text date-arabic">تاريخ</h5>
          <h5>
            Time&nbsp;:&nbsp;
            {data?.orderDate !== undefined && data?.orderDate?.split(" ")[1]}
          </h5>
          <h5 className="arabic-text date-arabic">زمن</h5>
          <h5>
            Del.Date&nbsp;:&nbsp;
            {data?.deliveryDate !== undefined ? convertDateFormat(data?.deliveryDate):""}
          </h5>
        </div>
      </div>
      <hr className="invoice-hr" />
      <div className="invoice-second-container">
        <div className="invoice-second-label-data-container">
          <div className="vat-label">
            <h5>VAT</h5>
            <h5 className="arabic-text">ضريبة القيمة المضافة</h5>
          </div>
          <div className="vat-data">
            <h5>:&nbsp;{data?.vat}</h5>
            <h5></h5>
          </div>
        </div>
        <div className="invoice-second-label-data-container">
          <div className="vat-label">
            <h5>Customer Care</h5>
            <h5 className="arabic-text">رعاية العميل</h5>
          </div>
          <div className="vat-data">
            <h5>:&nbsp;{data?.customercare}</h5>
            <h5></h5>
          </div>
        </div>
      </div>
      <hr className="invoice-hr" />
      <div className="invoice-third-container">
        <div className="invoice-third-left-container">
          <h5>{data?.address}</h5>
        </div>
        <div className="invoice-third-right-container">
          <h5 className="arabic-text"> {data?.nativeAddress || ""}</h5>
        </div>
      </div>
      <hr className="invoice-hr" />
      
      <div className="invoice-fourth-container">
        <div className="invoice-fourth-left-container">
          <div className="invoice-fourth-left-first-container">
            <div className="invoice-fourth-left-first-label-container">
              <h6>Staff ID</h6>
              <h6 className="arabic-text">معرف الموظفين</h6>
            </div>
            <div className="invoice-fourth-left-first-data-container">
              <h6>&nbsp;:&nbsp;{data?.empId}</h6>
              <h6></h6>
            </div>
          </div>
          <div className="invoice-fourth-left-first-container">
            <div className="invoice-fourth-left-first-label-container">
              <h6>Staff Name</h6>
              <h6 className="arabic-text">اسم الموظفين</h6>
            </div>
            <div className="invoice-fourth-left-first-data-container">
              <h6>&nbsp;:&nbsp;{data?.staff_name}</h6>
              <h6></h6>
            </div>
          </div>
        </div>

        <div className="invoice-fourth-right-container">
          <div className="invoice-fourth-left-first-container">
            <div className="invoice-fourth-left-first-label-container">
              <h6>Order No</h6>
              <h6 className="arabic-text">رقم الطلب</h6>
            </div>
            <div className="invoice-fourth-left-first-data-container">
              <h6>&nbsp;:&nbsp;{data?.billNo}</h6>
              <h6></h6>
            </div>
          </div>
          <div className="invoice-fourth-left-first-container">
            <div className="invoice-fourth-left-first-label-container">
              <h6>POS ID</h6>
              <h6 className="arabic-text">معرف نقطة البيع</h6>
            </div>
            <div className="invoice-fourth-left-first-data-container">
              <h6>&nbsp;:&nbsp;---</h6>
              <h6></h6>
            </div>
          </div>
        </div>
      </div>
      <hr className="invoice-hr" style={{marginBottom:'4px'}}/>
      <div className="invoice-second-container">
        <div className="invoice-second-label-data-container">
          <div className="vat-label">
            <h5>Cus. Name</h5>
            <h5 className="arabic-text">اسم الزبون</h5>
          </div>
          <div className="vat-data">
            <h5>:&nbsp;{data?.customerName || '--'}</h5>
            <h5></h5>
          </div>
        </div>
        <div className="invoice-second-label-data-container">
          <div className="vat-label">
            <h5>Cus. Mobile</h5>
            <h5 className="arabic-text">جوال العميل</h5>
          </div>
          <div className="vat-data">
            <h5>:&nbsp;{data?.mobileNo || '--'}</h5>
            <h5></h5>
          </div>
        </div>
      </div>


      
      {/* table */}
      <div className="billing-invoice-table-container">
        <table>
          <thead>
            <tr>
              <th>
                <div className="heading-container">
                  <p>Item</p>
                  <p className="arabic-text">العنصر</p>
                </div>
              </th>
              <th>
                <div className="heading-container">
                  <p>Product</p>
                  <p className="arabic-text">منتج</p>
                </div>
              </th>

              <th>
                <div className="heading-container">
                  <p>Qty</p>
                  <p className="arabic-text">الكمية</p>
                </div>
              </th>
              <th>
                <div className="heading-container">
                  <p>Price</p>
                  <p className="arabic-text">سعر</p>
                </div>
              </th>
              <th>
                <div className="heading-container">
                  <p>Value</p>
                  <p className="arabic-text">سعر</p>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.items?.map((r, i) => (
              <tr key={i}>
                <td>
                  <div className="content-container">
                    <p>{r?.type}</p>
                    <p className="arabic-text">
                      {r?.type === "thob"
                        ? "التوب"
                        : r?.type === "jalabia"
                        ? "جلابية"
                        : r?.type === "salwar"
                        ? "السلوار"
                        : r?.type === "abaya"
                        ? "عباية"
                        : r?.type === "churidhar"
                        ? "شوريدار"
                        : r?.type === "shirt"
                        ? "قميص"
                        : r?.type === "pant"
                        ? "يلهث"
                        : r?.type === "coat"
                        ? "معطف"
                        : r?.type === "sharvani"
                        ? "شرواني"
                        : r?.type === "safari"
                        ? "سفاري"
                        : undefined}
                    </p>
                  </div>
                </td>
                <td>
                  <div className="content-container">
                    <p>{r?.name}</p>
                    <p >{r?.nativeName}</p>
                  </div>
                </td>
                <td>{r?.quantity}</td>
                <td>{r?.price}</td>
                <td>{r?.total}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <hr className="invoice-hr-dashed" />
      <div className="invoice-sub-total-container">
        <div className="invoice-sub-total-first-container">
          <div className="invoice-sub-total-first-left-container">
            <h5>SUB TOTAL</h5>
            <h5 className="arabic-text">المجموع الفرعي</h5>
          </div>
          <div className="invoice-sub-total-first-right-container">
            <h5>{data?.subTotal?.toFixed(2)}</h5>
            <h5></h5>
          </div>
        </div>
        <div className="invoice-sub-total-first-container">
          <div className="invoice-sub-total-first-left-container">
            <h5>DISCOUNT</h5>
            <h5 className="arabic-text">تخفيض</h5>
          </div>
          <div className="invoice-sub-total-first-right-container">
            <h5>{data?.discount}</h5>
            <h5></h5>
          </div>
        </div>
        {
        data?.taxTable!==undefined &&
        data?.taxTable?.list?.length !== 0 && (
          <>
      <hr className="invoice-hr-dashed" />
         <table className="invoice-bill-tax-table">
              <thead>
                <th style={{textAlign:"start"}}>
                  <h5>
                  TYPE
                  </h5>
                </th>
                <th style={{textAlign:"end"}}>
                  <h5>
                  TAX
                  </h5>
                  </th>
                <th style={{textAlign:"end"}}><h5>
                  AMOUNT
                  </h5></th>
              </thead>
              <tbody>
                {
                  data?.taxTable?.list?.map((r,i)=>(

                    <tr key={i}>
                      <td style={{textAlign:"start"}}>{r?.name}</td>
                      <td style={{textAlign:"end"}}>{r?.amount}%</td>
                      <td style={{textAlign:"end"}}>{r?.taxamount?.toFixed(2)}</td>
                    </tr>
                  ))
                }
                <tr>
                  <td style={{borderTop:"1px solid #e7e7e7"}}>&nbsp;</td>
                  <td style={{borderTop:"1px solid #e7e7e7",textAlign:"end"}}>TOTAL</td>
                  <td style={{borderTop:"1px solid #e7e7e7",textAlign:"end"}}>{data?.taxTable?.total?.toFixed(2) || 0.00}</td>
                </tr>
              </tbody>
         </table>
         </>
        )
      } 
      </div>
      <hr className="invoice-hr-dashed" />
      <div className="invoice-grand-total-container">
        {
        data?.taxTable!==undefined &&
        
        data?.taxTable?.list?.length !== 0 && (
          <div className="invoice-grand-total-first-container">
            <h5>Inclusive Of All Taxes</h5>
            <h5 className="arabic-text">شاملة لجميع الضرائب</h5>
          </div>
        )}
        <div className="invoice-grand-total-second-container">
          <div>
            <h4>Grand Total</h4>
            <h4 className="arabic-text">المبلغ الإجمالي</h4>
          </div>
          <div>
            <h4>{data?.grandTotal?.toFixed(2)}</h4>
            <h4></h4>
          </div>
        </div>
        <div className="invoice-grand-total-second-container">
          <div>
            <h5>Paid Amount</h5>
            <h5 className="arabic-text">المبلغ المدفوع</h5>
          </div>

          <div>
            <div className="justify-space-between" style={{ gap: "6px", marginBottom: "2px",justifyContent:"flex-end" }}>
              <h5>{data?.paidAmount?.toFixed(2)}</h5>
            </div>

            {
              data?.paymentDetails?.map((r,i)=>(
                <div className="justify-space-between" style={{ gap: "6px" }}>
                  <p style={{ margin: "0", fontSize: "0.7rem" }}>{r?.type}</p>
                  <p style={{ margin: "0", fontSize: "0.7rem" }}>{r?.amount}</p>
                </div>
              ))
            }
            
          </div>

        </div>
        <div className="invoice-grand-total-second-container">
          <div>
            <h5>Balance</h5>
            <h5 className="arabic-text">الرصيد</h5>
          </div>
          <div>
            <h5>{data?.balance?.toFixed(2)}</h5>
            <h5></h5>
          </div>
        </div>
        <h5 className="saved-amount-container">
          *** YOU HAVE SAVED {data?.discount || 0} ***{" "}
        </h5>

        {/* barcode qrcode */}
        <div className="barcode-qrcode-container">
          {/* <img
            src={data?.barcode}
            alt="barcode"
            className="barcode-qrcode-container-barcode"
          /> */}
          <Barcode
              format="CODE128"
              value={data?.barcode || "null"}
              width={1}
              fontSize={16}
              height={60}
              margin={"2px"}
          />
          {/* <img
            src={data?.qrcode}
            alt="qrcode"
            className="barcode-qrcode-container-qrcode"
          /> */}
           <QRCode
                        size={256}
                        style={{
                          height: "auto",
                          width: "100px",
                        }}
                         value={"https://example.com"}
                        // value={"https://textile.eyeterp.com/userdashboard/sales/payments"}
                        viewBox={`0 0 256 256`}
                      />
        </div>
      </div>
      {
      data?.creditCleared!==undefined &&
      data?.creditCleared?.list?.length !== 0 && (
          <>
      <hr className="invoice-hr-dashed" />
          <h5 style={{margin:"0",marginTop:"10px"}}>Credit Paid</h5>
         <table className="invoice-bill-tax-table">
              <tbody>
                {
                  data?.creditCleared?.list?.map((r,i)=>(

                    <tr key={i}>
                      <td style={{textAlign:"start",borderTop:"1px solid #e7e7e7"}}>{r?.purchaseId}</td>
                      <td style={{borderTop:"1px solid #e7e7e7"}}>&nbsp;</td>
                      <td style={{textAlign:"end",borderTop:"1px solid #e7e7e7"}}>{r?.balance?.toFixed(2)}</td>
                    </tr>
                  ))
                }
                <tr>
                  <td style={{borderTop:"1px solid #e7e7e7"}}>&nbsp;</td>
                  <td style={{borderTop:"1px solid #e7e7e7",textAlign:"end"}}>TOTAL</td>
                  <td style={{borderTop:"1px solid #e7e7e7",textAlign:"end"}}>{data?.creditCleared?.total?.toFixed(2) || 0.00}</td>
                </tr>
              </tbody>
         </table>
         </>
        )
      } 
      <hr className="invoice-hr-dashed" />
      <div className="terms-conditions-container">
        <div className="terms-conditions-left-container">
          <h5>Terms & Conditions</h5>
          <p>{data?.terms || ""}</p>
        </div>
        <div className="terms-conditions-right-container">
          <h5 className="arabic-text">الأحكام والشروط</h5>
          <p className="arabic-text">{data?.nativeTerms || ""}</p>
          {/* <p className="arabic-text">
          وريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه ... بروشور او فلاير على سبيل المثال ... او نماذج مواقع انترنت ...

وعند موافقه العميل المبدئيه على التصميم يتم ازالة هذا النص من التصميم ويتم وضع النصوص النهائية المطلوبة للتصميم ويقول البعض ان وضع النصوص التجريبية بالتصميم قد تشغل المشاهد عن وضع الكثير من الملاحظات او الانتقادات للتصميم الاساسي.

          </p> */}
        </div>
      </div>
      <hr className="invoice-hr-dashed" />
      <div className="invoice-last-container">
        <h4>THANK YOU FOR YOUR VISIT</h4>
        <h4>شكرا لزيارتكم</h4>
      </div>
    </div>
  );
};
