import React,{useEffect, useState} from 'react'
import { IconButton, Dialog, Select, Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
import { receivedTransferAPICall,
   receivedTransferEditAPICall, 
   receivedTransferSingleViewAPICall,
   confirmTransferListAPICall
  } from '../../../../../API/Purchase Manager/transferAPI';
import { calculateTotalCost } from '../../../../../Js/generalFunctions';
import { convertDateFormat } from '../../../../../Js/Date';
import ErrorSnackbar from '../../../../Single Components/SnackBars/ErrorSnackbar';

export const ConfirmTransfer = () => {
 // received transfer and confirm transfer list
 const receivedTransferList = useSelector(
  (state) => state.receivedTransferSlice.value
);
  // received transfer  and confirm transfer single view
  const receivedTransferSingleView = useSelector(
    (state) => state.receivedTransferSingleViewSlice.value
  );
  const storeCode=localStorage.getItem('branchId')
// confirm transfer list
  const confirmTransferList=useSelector((state)=>state.confirmTransfer.value)
  
  const [confirmTransferSingleView,setConfirmTransferSingleView]=useState(false)
   const [confirmTransferEditDialog,setConfirmTransferDialog]=useState(false)

   const [itemsArray,setItemsArray]=useState([])
   const [editableRow,setEditableRow]=useState([])
   const [editableRowIndex,setEditableRowIndex]=useState()
   const ediatbleFormInitialState={
    productName:'',
    transferQty:'',
    receivedQty:'',
    unit:'',
    unitCost:"",
    totalCost:''

   } 
   const [editableForm,setEditableForm]=useState(ediatbleFormInitialState)
   const [isModify,setIsModify]=useState(false)
    // loading state
  const [isLoading,setIsLoading]=useState(false)
  const [snackMsg, setSnackMsg] = useState("oops!!!");
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [searchKeyword,setSearchKeyword]=useState("")
  const [filteredSearchList,setFilteredSearchList]=useState([])
  const closeErrorSnack = () => {
    setErrorSnackOpen(false);
  };

   const updateListner=()=>{
    setIsModify(!isModify)
   }

   
   // close edit dialog
   const closeConfirmTransferEditDialog=()=>{
    
    setConfirmTransferDialog(false)
    setConfirmTransferSingleView(true)
   }

   useEffect(() => {
    receivedTransferAPICall({branchId:storeCode});
    confirmTransferListAPICall(setIsLoading,setErrorSnackOpen,setSnackMsg,{branchId:storeCode})
  }, [isModify]);

  // open single view dialog
  const openConfirmTransferSingleView=(id)=>{
    setConfirmTransferSingleView(true)
    receivedTransferSingleViewAPICall({ id});
   }

   // map single view response into state
   useEffect(()=>{
    if (receivedTransferSingleView !== undefined) {
      const {products}=receivedTransferSingleView
      setItemsArray(products?.map(obj=>{return {...obj}}))
    }
   },[receivedTransferSingleView])


   // open edit dialog
   const openConfirmTransferEditDialog=(singleList,index)=>{
    setEditableRow(singleList)
    setEditableRowIndex(index)
    setConfirmTransferSingleView(false)
    setConfirmTransferDialog(true)
   }

   // set editable form
   useEffect(()=>{
     setEditableForm({
      ...editableForm,
      productName:editableRow?.itemName,
      transferQty:editableRow?.transferQty,
      receivedQty:editableRow?.receivedQty,
      unit:editableRow?.unit,
      unitCost:editableRow?.unitCost,
      totalCost:editableRow?.totalCost
     })
   },[editableRow])

   // change transfer qty
   const getTransferQty=(e)=>{
     const {value}=e.target
     setEditableForm({
      ...editableForm,transferQty:value
     })
   }
   
   // change total cost
   useEffect(()=>{
    let totalCost=calculateTotalCost(
      editableForm?.unitCost,
      editableForm?.transferQty
    );
    setEditableForm({...editableForm,totalCost})
   },[editableForm?.transferQty])

   // edit btn click function
   const clickEditBtnFn=()=>{
    if(editableRowIndex !== null){
      let editedRow = itemsArray?.map((obj,i)=>{
        if(i===editableRowIndex){
          return {
            ...obj,
            transferQty:parseFloat(editableForm?.transferQty),
            totalCost:parseFloat(editableForm?.totalCost)
          }
        }
        else{
          return obj;
        }
      })
      setItemsArray(editedRow)
      closeConfirmTransferEditDialog()
    }
   }

   // final api call
   const bodyToApi={
    id:receivedTransferSingleView?._id,
    loc:receivedTransferSingleView?.location,
    transferItems:itemsArray
   }
   const clickFinishBtnFn=()=>{
    //  console.log(bodyToApi);
     receivedTransferEditAPICall(bodyToApi,setConfirmTransferSingleView,updateListner)
  
    }
    const getSearchKeyword=(e)=>{
      setSearchKeyword(e.target.value)
    }
    const SearchFilter=(chars,listToFilter)=>{
      let searchedArray=[]
      let filteredArray=[]
      if(chars!==""){
        searchedArray=listToFilter.filter((obj)=>
        obj?.transNo?.toLowerCase()?.includes(chars?.toLowerCase())||
        obj?.fromLoc?.toLowerCase()?.includes(chars?.toLowerCase())||
        obj?.toLoc?.toLowerCase()?.includes(chars?.toLowerCase())
      )
        filteredArray=searchedArray
      }
      else{
        filteredArray=listToFilter
      }
      return filteredArray
    }
    useEffect(()=>{
      if(confirmTransferList!==undefined){
        let filteredArray=[]
        setFilteredSearchList(confirmTransferList)
        filteredArray=SearchFilter(searchKeyword,confirmTransferList)
        setFilteredSearchList(filteredArray)    
      }
      else{
        setFilteredSearchList([])
      }
      },[searchKeyword,confirmTransferList])

  return (
    <div className="global-page-parent-container">
    <p className="breadcrumb">
      Purchase Manage &gt; Transfer &gt; Confirm Transfer
    </p>
    <div className="transfer-container">
    <div className="stock-out-product-top-container" style={{alignItems:"center"}}>
          <h3>Confirm Transfer</h3>
            <div className="view-order-search-container view-order-admin-search-container"
              style={{width:"25%"}}>
                  <input
                    placeholder="Search Transaction Id,Location"
                    value={searchKeyword}
                    onChange={getSearchKeyword}
                  />
                  <IconButton>
                      <i class="bi bi-search search-icon-order"></i>
                  </IconButton>
              </div>
          </div>
      <div className="global-table-container transfer-table">
        <table className="global-table">
          <thead>
            <tr>
              <th>No</th>
              <th>Transaction No</th>
              <th>Location From</th>
              <th>Location To</th>
              <th>Date</th>
              <th>Margin</th>
              <th>status</th>
              <th>View</th>
              <th>Delete</th>
            </tr>
          </thead>
          {
            isLoading?
              <tbody>
                  <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr>
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr>
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr>
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr>
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr> 
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr> 
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr> 
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr>
              </tbody>:
          <tbody>
              {filteredSearchList?.length!==0?
              filteredSearchList?.slice(0)?.reverse()?.map((r, i) => (
                    <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{r?.transNo}</td>
                    <td>{r?.fromLoc}</td>
                    <td>{r?.toLoc}</td>
                    <td>{convertDateFormat(r?.transferDate)}</td>
                    <td>{r?.margin}</td>
                    <td>
                      <p className=
                      {
                        r?.status==="Pending"? "status-pending":
                        r?.status==="Confirmed" ?"status-confirm"
                        :undefined
                      }
                      >{r?.status}</p>
                    </td>
  
                    <td>
                      <IconButton onClick={() => openConfirmTransferSingleView(r?._id) }>
                        <i class="bi bi-eye visibility-icon"></i>
                      </IconButton>
                    </td>
                    <td>
                      <IconButton>
                        <i class="bi bi-trash3 delete-icon"></i>
                      </IconButton>
                    </td>
                  </tr>
                 
               )):<tr><td colSpan={9} style={{textAlign:"center"}}>No Records</td></tr>}
            </tbody>}
        </table>
      </div>
    </div>

    {/* received transfer view */}
    <Dialog open={confirmTransferSingleView} maxWidth="lg"  onKeyDown={(e)=>e.key === "Escape" && setConfirmTransferSingleView(false)}>
      <div className="received-stock-dialog-container">
        <h3>Received Transfer</h3>
        <div className="received-stock-dialog-top-container">
          <div className="global-single-input received-stock-input">
            <p>Transaction ID</p>
            <input
              value={receivedTransferSingleView?.transNo}
            />
          </div>
          <div className="global-single-input received-stock-input">
            <p>Location</p>
            <input
               value={receivedTransferSingleView?.location}
            />
          </div>
        </div>
        <div className="received-stock-dialog-table-container">
          <h3>Received Products</h3>
          <div className="global-table-container received-stock-table">
            <table className="global-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Product Name</th>
                  <th>Transfer Qty</th>
                  <th>Rece Qty</th>
                  <th>Unit</th>
                  <th>Unit Cost</th>
                  <th>Total Cost</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
              {itemsArray !== undefined &&
                    itemsArray?.map((r, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{r?.itemName}</td>
                        <td>{r?.transferQty}</td>
                        <td>{r?.receivedQty}</td>
                        <td>{r?.unit}</td>
                        <td>{r?.unitCost}</td>
                        <td>{r?.totalCost}</td>
                        <td>
                          <IconButton
                            onClick={() => openConfirmTransferEditDialog(r, i)}
                          >
                            <i class="bi bi-pencil-square edit-icon"></i>
                          </IconButton>
                        </td>
                      </tr>
                    ))}
              </tbody>
              
            </table>
          </div>
        </div>
        <div className="received-stock-single-view-button-container">
          <button
            onClick={()=>setConfirmTransferSingleView(false)}
            className="btn btn-secondary-outlined"
          >
            Cancel
          </button>
          <button 
          onClick={clickFinishBtnFn}
          className="btn btn-secondary">Finish</button>
        </div>
      </div>
    </Dialog>

    {/* received transfer edit dialog */}
    <Dialog open={confirmTransferEditDialog}  maxWidth="lg"  onKeyDown={(e)=>e.key === "Escape" && setConfirmTransferDialog(false)}>
      <div className="received-stock-dialog-container">
        <h3>Edit</h3>
        <div className="received-stock-dialog-top-container">
          <div className="global-single-input received-stock-input">
            <p>Transaction ID</p>
            <input  
            value={receivedTransferSingleView?.transNo}
            />
          </div>
          <div className="global-single-input received-stock-input">
            <p>Location</p>
            <input 
             value={receivedTransferSingleView?.location}
            />
          </div>
        </div>
        <div className="received-stock-dialog-table-container">
          <h3>Received Products</h3>
          <div className="received-stock-edit-table-container">
            <div className="global-single-input edit-table-input">
              <p>Product Name</p>
              <input disabled  
              value={editableForm?.productName}
              />
            </div>
            <div className="global-single-input edit-table-input adj-qty">
              <p>Transfer Qty</p>
              <input
                value={editableForm?.transferQty}
                onChange={getTransferQty}
              />
            </div>
            <div className="global-single-input edit-table-input">
              <p>Rece Qty</p>
              <input
                value={editableForm?.receivedQty}
              />
            </div>
            <div className="global-single-input edit-table-input">
              <p>Unit</p>
              <input disabled  
               value={editableForm?.unit}
              />
            </div>
            <div className="global-single-input edit-table-input">
              <p>Unit Cost</p>
              <input disabled  value={editableForm?.unitCost}/>
            </div>
            <div className="global-single-input edit-table-input">
              <p>Total Cost</p>
              <input disabled  value={editableForm?.totalCost}/>
            </div>
          </div>
        </div>
        <div className="received-stock-single-view-button-container">
          <button
           onClick={closeConfirmTransferEditDialog}
            className="btn btn-secondary-outlined"
          >
            Cancel
          </button>
          <button
            onClick={clickEditBtnFn}
            className="btn btn-secondary"
          >
            Edit
          </button>
        </div>
      </div>
    </Dialog>
    <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnack}
        message={snackMsg}
      />
  </div>
  )
}
