import React,{useState,useRef} from 'react'
import {Select,IconButton,MenuItem, Autocomplete, TextField, Skeleton} from '@mui/material'
import '../../../../css/Report/ExpenseReport.css'
import ReactToPrint from "react-to-print";
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { expenseReportAPICall } from '../../../../API/Report/expenseReportAPI';
import { getAllActiveEmployeesAPICall } from '../../../../API/Staff/HRM/employeeAPI';
import { convertDateFormat, getCurrentTime, today } from '../../../../Js/Date';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { sortGeneral } from '../../../../Js/generalFunctions';
export const ExpenseReport = () => {
  let staffExpenseReportPrint=useRef()
  let outletExpenseReportPrint=useRef()
  let pettyCashReceiptPrint=useRef()
  
  // expense report list
  const expenseReportList=useSelector((state)=>state.expenseReportSlice.value)
   //Active Employee List
   const allActiveEmpList = useSelector(
    (state) => state.allActiveEmployeeSlice.value
  );
   // login response
   const loginResponse = useSelector((state) => state.loginResponseSlice.value);
   // role based rendering
   const userRole = useSelector((state) => state.userRoleSlice.value);

  const [selectCategory,setSelectCategory]=useState("staff expense")
  const [employee,setEmployee]=useState(null)
  const [employeeDetails,setEmployeeDetails]=useState(null)
  const [singleEmployee,setSingleEmployee]=useState()
  const [number,setNumber]=useState(null)
  const [time, setTime] = useState(null);
  const [fromDate,setFromDate]=useState('')
  const [toDate,setToDate]=useState('')
  const [isLoading,setIsLoading]=useState(false)
  const [isAscending,setIsAscending]=useState(false)
  const [expenseReportFilter,setExpenseReportFilter]=useState([])

  // validation states
  const [employeeALert,setEmployeeALert]=useState(false)
  // get current time fn
  useEffect(() => {
    setInterval(() => {
      setTime(`${new Date().getHours()}:${new Date().getMinutes()}`);
    }, 1000);
  }, [time]);

 
 useEffect(()=>{
  getAllActiveEmployeesAPICall();
  // expenseReportAPICall({},setIsLoading)
 },[])


 const getCategory=(e)=>{
  setSelectCategory(e.target.value)
  setExpenseReportFilter([])
  setEmployee(null)
  setEmployeeDetails(null)
 }
console.log(employeeDetails);
 const getFromDate=(e)=>{
  setFromDate(e.target.value)
 }
 const getToDate=(e)=>{
  setToDate(e.target.value)
 }

  const expenseSubmitBtnFn=()=>{
    // if(selectCategory==='staff expense'&& employee===null || employee===undefined){
    //   setEmployeeALert(true)
    // }
    // else{
      expenseReportAPICall({
        category:selectCategory,
        empId:employee,
        fromDate:fromDate !== "" ? fromDate :null,
        toDate:toDate !== "" ?toDate:null
      },setIsLoading)
    // }
   
  }

  //filter single employee
  useEffect(()=>{
     let singleEmp=[]
     singleEmp=allActiveEmpList?.filter((obj)=>{
     return  obj._id===employee
     })
     setSingleEmployee(singleEmp)
  },[employee])
  

  useEffect(()=>{
    setNumber(
      singleEmployee !== undefined && employee !== null && employee !== undefined ? singleEmployee[0]?.contactnumber : ''
    )
  },[singleEmployee])

  // map expense report list to state
  useEffect(()=>{
     if(expenseReportList !== undefined){
      setExpenseReportFilter(expenseReportList?.map(obj=>{ return {...obj}}))
     }
     else{
      setExpenseReportFilter([])
     }
  },[expenseReportList])
  console.log(expenseReportList);
  // staff expense sorting
  // staff expense expense type sorting
  const sortStaffExpenseTypeAscending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(expenseReportFilter, "String", "expense", true);
    setExpenseReportFilter(sorted)
  }

  const sortStaffExpenseTypeDescending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(expenseReportFilter, "String", "expense", false);
    setExpenseReportFilter(sorted)
  }

  // staff expense date sorting
  const sortStaffExpenseDateAscending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(expenseReportFilter, "Date", "date", true);
    setExpenseReportFilter(sorted)
  }
  const sortStaffExpenseDateDescending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(expenseReportFilter, "Date", "date", false);
    setExpenseReportFilter(sorted)
  }

  //  staff expense amount sorting
  const sortStaffExpenseAmountAscending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(expenseReportFilter, "Number", "amount", true);
    setExpenseReportFilter(sorted)
  }
  const sortStaffExpenseAmountDescending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(expenseReportFilter, "Number", "amount", false);
    setExpenseReportFilter(sorted)
  }
 // branch name sorting
 const sortStaffExpenseBranchCodeAscending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(expenseReportFilter, "String", "branchCode", true);
  setExpenseReportFilter(sorted)
 }
 const sortStaffExpenseBranchCodeDescending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(expenseReportFilter, "String", "branchCode", false);
  setExpenseReportFilter(sorted)
 }
 // branch code sorting
 const sortStaffExpenseBranchNameAscending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(expenseReportFilter, "String", "branchName", true);
  setExpenseReportFilter(sorted)
 }
 const sortStaffExpenseBranchNameDescending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(expenseReportFilter, "String", "branchName", false);
  setExpenseReportFilter(sorted)
 }

  // outlet expense sorting 
  // date sorting
const sortOutletExpenseDateAscending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(expenseReportFilter, "Date", "date", true);
  setExpenseReportFilter(sorted)
}
const sortOutletExpenseDateDescending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(expenseReportFilter, "Date", "date", false);
  setExpenseReportFilter(sorted) 
}

// amount sorting
const sortOutletExpenseAmountAscending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(expenseReportFilter, "Number", "amount", true);
  setExpenseReportFilter(sorted)
}
const sortOutletExpenseAmountDescending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(expenseReportFilter, "Number", "amount", false);
  setExpenseReportFilter(sorted)
}

//last paid amount sorting
const sortOutletExpenseLPAmountAscending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(expenseReportFilter, "Number", "lastPaidAmount", true);
  setExpenseReportFilter(sorted)
}
const sortOutletExpenseLPAmountDescending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(expenseReportFilter, "Number", "lastPaidAmount", false);
  setExpenseReportFilter(sorted)
}

// last paid date sorting
const sortOutletExpenseLPDateAscending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(expenseReportFilter, "Date", "lastPaidDate", true);
  setExpenseReportFilter(sorted)
}
const sortOutletExpenseLPADateDescending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(expenseReportFilter, "Date", "lastPaidDate", false);
  setExpenseReportFilter(sorted)
}
// branch code sorting
const sortOutletExpenseBranchCodeAscending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(expenseReportFilter, "String", "branchId", true);
  setExpenseReportFilter(sorted)
}
const sortOutletExpenseBranchCodeDescending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(expenseReportFilter, "String", "branchId", false);
  setExpenseReportFilter(sorted)
}
// branch name
const sortOutletExpenseBranchNameAscending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(expenseReportFilter, "String", "branchName", true);
  setExpenseReportFilter(sorted)
}
const sortOutletExpenseBranchNameDescending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(expenseReportFilter, "String", "branchName", false);
  setExpenseReportFilter(sorted)
}


// petty cash receipt sorting
// date sorting
const sortPettyCashDateAscending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(expenseReportFilter, "Date", "date", true);
  setExpenseReportFilter(sorted)
}
const sortPettyCashDateDescending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(expenseReportFilter, "Date", "date", false);
  setExpenseReportFilter(sorted)
}

// amount sorting
const sortPettyCashAmountAscending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(expenseReportFilter, "Number", "amount", true);
  setExpenseReportFilter(sorted)
}
const sortPettyCashAmountDescending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(expenseReportFilter, "Number", "amount", false);
  setExpenseReportFilter(sorted)
}
// branch code sorting
const sortPettyCashBranchCodeAscending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(expenseReportFilter, "String", "branchId", true);
  setExpenseReportFilter(sorted)
}
const sortPettyCashBranchCodeDescending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(expenseReportFilter, "String", "branchId", false);
  setExpenseReportFilter(sorted)
}
// branch name sorting
const sortPettyCashBranchNameAscending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(expenseReportFilter, "String", "branchName", true);
  setExpenseReportFilter(sorted)
}
const sortPettyCashBranchNameDescending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(expenseReportFilter, "String", "branchName", false);
  setExpenseReportFilter(sorted)
}




  
 

  return (
    <div className="global-page-parent-container">
    <p className="breadcrumb">Report &gt; Expense Report</p>
    <div className="daily-report-top-container">
      <h3>Expense Report</h3>
      <hr className="global-hr"/>
      <div className="daily-report-top-input-container expense-top-main-container" >
       <div className="global-single-input">
        <p>Select Category</p>
        <Select
         value={selectCategory}
         onChange={getCategory}
        >
          <MenuItem value="staff expense">Staff Expense</MenuItem>
          <MenuItem value="outlet expense">Outlet Expense</MenuItem>
          <MenuItem value="pettycash receipt">Petty Cash Receipt</MenuItem>
        </Select>
       </div>
       {selectCategory==="staff expense" &&
       <>
       <div className="global-single-input auto-complete">
        <p>Name</p>
        <Autocomplete
          sx={{width:"100%"}}
          options={allActiveEmpList|| [""]}
          getOptionLabel={(option) => `${option?.emp_id}-${option?.staff_name}`}
          renderInput={(params) => <TextField {...params} 
          placeholder="Select Employee"
          />}
         onChange={(e, newValue) =>{
           setEmployeeALert(false)
           setEmployee(newValue?._id)
           setEmployeeDetails(newValue)
           if(newValue===null){
            setEmployeeALert(true)
           }
              }
             }
            />
          {
            employeeALert?
            <p className="doc-validation-alert">Select an employee !!</p>:""
          }
       </div>
       <div className="global-single-input">
        <p>Contact</p>
        <input  value={number} />
       </div>
       </>
       }
       <div className="global-single-input">
        <p>From</p>
        <input type="date" onChange={getFromDate}/>
       </div>
       <div className="global-single-input">
        <p>To</p>
        <input type="date" onChange={getToDate}/>
       </div>
      </div>
      <div className="expense-report-button-container">
      <button onClick={expenseSubmitBtnFn} className="btn btn-primary report-submit-btn">Submit</button>
      </div>
     
     
    </div>
    <div className="global-white-bg-container">
      {selectCategory==='staff expense'?
       <div className="expense-container">

          <div className="expense-top-container">
           <h4>Staff Expense Report</h4>
           <div className="expense-report-print-download-icon-container">
           <ReactToPrint
             trigger={()=>(
            <IconButton className="print-icon-container">
              <i class="bi bi-printer printer-icon"></i>
            </IconButton>
           )}
            content={()=>staffExpenseReportPrint}
            pageStyle="@page { size: auto;  margin: 15mm 0 10mm 0; } }"
           />  
             <IconButton className="download-icon-container">
              <i class="bi bi-arrow-down-circle download-icon"></i>
             </IconButton>
          </div>
        </div>
        <div className="global-table-container">
           <table className="global-table">
             <thead>
               <tr>
                <th>No</th>
                {
                  userRole === "admin" &&
                  <>
                <th>
                  Branch Code
                  {
                isAscending ?(
                  <IconButton
                    onClick={sortStaffExpenseBranchCodeAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortStaffExpenseBranchCodeDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
                </th>
                <th>
                  Branch Name
                  {
                isAscending ?(
                  <IconButton
                    onClick={sortStaffExpenseBranchNameAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortStaffExpenseBranchNameDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
                </th>  
                  </>

                }
                
                <th>
                  Expense
                  {
                isAscending ?(
                  <IconButton
                    onClick={sortStaffExpenseTypeAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortStaffExpenseTypeDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
                </th>
                <th>Notes</th>
                <th>
                  Date
                  {
                isAscending ?(
                  <IconButton
                    onClick={sortStaffExpenseDateAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortStaffExpenseDateDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
                </th>
                <th>
                  Amount
                  {
                isAscending ?(
                  <IconButton
                    onClick={sortStaffExpenseAmountAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortStaffExpenseAmountDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
                </th>
               </tr>
             </thead>
             {
              isLoading ?(
                <tbody>
                <tr>
                   <td colSpan={11}>
                     <Skeleton
                       variant="rectangular"
                       animation="wave"
                       width={"100%"}
                       height={30}
                     />
                   </td>
                 </tr>
                 <tr>
                   <td colSpan={11}>
                     <Skeleton
                       variant="rectangular"
                       animation="wave"
                       width={"100%"}
                       height={30}
                     />
                   </td>
                 </tr>
                 <tr>
                   <td colSpan={11}>
                     <Skeleton
                       variant="rectangular"
                       animation="wave"
                       width={"100%"}
                       height={30}
                     />
                   </td>
                 </tr>
           </tbody>
              ):(
                <tbody>
                {
                  expenseReportFilter?.length!==0?
                 expenseReportFilter?.map((r,i)=>(
                   <tr>
                     <td>{i+1}</td>
                     {
                      userRole === "admin" &&
                      <>
                     <td>{r?.branchCode}</td>
                     <td>{r?.branchName}</td> 
                      </>
                     }
                   
                     <td>{r?.expense}</td>
                     <td>{r?.narration}</td>
                     <td>{convertDateFormat(r?.date)}</td>
                     <td>{r?.amount}</td>
                   </tr>
                 )):
                 <tr>
                  {
                  userRole === "admin"?
                  <td colspan={7}>No Records</td>:
                  <td colspan={5}>No Records</td>                  
                  }
                 </tr>
                }
               
              </tbody>
              )
             }
            
           </table>
        </div>
       
       </div>
       : selectCategory==='outlet expense'?
       <div className="expense-container">

         <div className="expense-top-container">
           <h4>Outlet Expense Report</h4>
          <div className="expense-report-print-download-icon-container">
           <ReactToPrint
             trigger={()=>(
            <IconButton className="print-icon-container">
              <i class="bi bi-printer printer-icon"></i>
            </IconButton>
           )}
           content={()=>outletExpenseReportPrint}
           pageStyle="@page { size: auto;  margin: 15mm 0 10mm 0; } }"
            />  
            <IconButton className="download-icon-container">
             <i class="bi bi-arrow-down-circle download-icon"></i>
            </IconButton>
           </div>
         </div>
         <div className="global-table-container">
          <table className="global-table">
            <thead>
              <tr>
               <th>No</th>
               {
                userRole === "admin" &&
                <>
                <th>
                  Branch Code
                  {
                isAscending ?(
                  <IconButton
                    onClick={sortOutletExpenseBranchCodeAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortOutletExpenseBranchCodeDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
                </th>
                <th>
                  Branch Name
                  {
                isAscending ?(
                  <IconButton
                    onClick={sortOutletExpenseBranchNameAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortOutletExpenseBranchNameDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
                </th>
                </>
               }
               
               <th>Doc No</th>
               <th>Expense Type</th>
               <th>Notes</th>
               <th>
                Date
                {
                isAscending ?(
                  <IconButton
                    onClick={sortOutletExpenseDateAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortOutletExpenseDateDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
               </th>
               <th>
                Amount
                {
                isAscending ?(
                  <IconButton
                    onClick={sortOutletExpenseAmountAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortOutletExpenseAmountDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
              </th>
               <th>Paid By</th>
               <th>Credit Amount</th>
               <th>
                Last Paid Amount
                {
                isAscending ?(
                  <IconButton
                    onClick={sortOutletExpenseLPAmountAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortOutletExpenseLPAmountDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
              </th>
               <th>
                Date
                {
                isAscending ?(
                  <IconButton
                    onClick={sortOutletExpenseLPDateAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortOutletExpenseLPADateDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
              </th>
              </tr>
            </thead>
            {
              isLoading?(
                <tbody>
              <tr>
                   <td colSpan={10}>
                     <Skeleton
                       variant="rectangular"
                       animation="wave"
                       width={"100%"}
                       height={30}
                     />
                   </td>
                 </tr>
                 <tr>
                   <td colSpan={10}>
                     <Skeleton
                       variant="rectangular"
                       animation="wave"
                       width={"100%"}
                       height={30}
                     />
                   </td>
                 </tr>
                 <tr>
                   <td colSpan={10}>
                     <Skeleton
                       variant="rectangular"
                       animation="wave"
                       width={"100%"}
                       height={30}
                     />
                   </td>
                 </tr>
           </tbody>
              ):(
           <tbody>
            {   expenseReportFilter.length!==0?
                expenseReportFilter?.map((r,i)=>(
               <tr>
                <td>{i+1}</td>
                {
                  userRole === "admin" &&
                  <>
                  <td>{r?.branchId}</td>
                  <td>{r?.branchName}</td>
                  </>
                }
                
                <td>{r?.transNo}</td>
                <td>{r?.expenseType}</td>
                <td>{r?.narration}</td>
                <td>{convertDateFormat(r?.date)}</td>
                <td>{r?.amount}</td>
                <td>{r?.paidBy}</td>
                <td>---</td>
                <td>{r?.lastPaidAmount}</td>
                <td>{convertDateFormat(r?.lastPaidDate)}</td>
              </tr>
                ))
              :<tr>
              {
              userRole === "admin"?
              <td colspan={13}>No Records</td>:
              <td colspan={10}>No Records</td>                  
              }
             </tr>
            }
            </tbody>
              )
            }
            
          </table>
         </div>
      
       </div>
       : selectCategory==='pettycash receipt'?
       <div className="expense-container">

          <div className="expense-top-container">
         <h4>Petty Cash Receipt</h4>
          <div className="expense-report-print-download-icon-container">
          <ReactToPrint
             trigger={()=>(
            <IconButton className="print-icon-container">
              <i class="bi bi-printer printer-icon"></i>
            </IconButton>
           )}
           content={()=>pettyCashReceiptPrint}
           pageStyle="@page { size: auto;  margin: 15mm 0 10mm 0; } }"
            />  
            <IconButton className="download-icon-container">
             <i class="bi bi-arrow-down-circle download-icon"></i>
            </IconButton>
         </div>
          </div>
          <div className="global-table-container">
          <table className="global-table">
            <thead>
              <tr>
               <th>No</th>
               {
                userRole === "admin" &&
                <>
                <th>
                  Branch Code
                  {
                isAscending ?(
                  <IconButton
                    onClick={sortPettyCashBranchCodeAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortPettyCashBranchCodeDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
                </th>
                <th>
                  Branch Name
                  {
                isAscending ?(
                  <IconButton
                    onClick={sortPettyCashBranchNameAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortPettyCashBranchNameDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
                </th>
                </>
               }
             
               <th>Doc No</th>
               <th>Outlet Code</th>
               <th>
                 Date
                 {
                isAscending ?(
                  <IconButton
                    onClick={sortPettyCashDateAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortPettyCashDateDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
              </th>
               <th>
                Amount
                {
                isAscending ?(
                  <IconButton
                    onClick={sortPettyCashAmountAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortPettyCashAmountDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
              </th>
              </tr>
            </thead>
            {
              isLoading ? (
              <tbody>
              <tr>
                   <td colSpan={10}>
                     <Skeleton
                       variant="rectangular"
                       animation="wave"
                       width={"100%"}
                       height={30}
                     />
                   </td>
                 </tr>
                 <tr>
                   <td colSpan={10}>
                     <Skeleton
                       variant="rectangular"
                       animation="wave"
                       width={"100%"}
                       height={30}
                     />
                   </td>
                 </tr>
                 <tr>
                   <td colSpan={10}>
                     <Skeleton
                       variant="rectangular"
                       animation="wave"
                       width={"100%"}
                       height={30}
                     />
                   </td>
                 </tr>
           </tbody>
              ):(
             <tbody>
            { 
                expenseReportFilter.length!==0?
                expenseReportFilter?.map((r,i)=>(
               <tr>
                <td>{i+1}</td>
                {
                  userRole ==="admin" &&
                  <>
                  <td>{r?.branchId}</td>
                  <td>{r?.branchName}</td>
                  </>
                }
                
                <td>{r?.transNo}</td>
                <td>---</td>
                <td>{convertDateFormat(r?.date)}</td>
                <td>{r?.amount}</td>
                
              </tr>
                ))
              :<tr>
              {
              userRole === "admin"?
              <td colspan={7}>No Records</td>:
              <td colspan={5}>No Records</td>                  
              }
             </tr>
            }
            </tbody>
              )
            }
            
          </table>
          </div>
      
      </div>
       : undefined
        }   
    </div>

    {/* staff expense report */}
    <div 
      style={{display:'none'}}
     >
       <div ref={(el)=>(staffExpenseReportPrint = el)}>
          <div className='print-view-top-container'>
            <div className='print-view-top-left-container' style={{width:'35%'}}>
              <h6>Printed On:{convertDateFormat(today)}&nbsp;{getCurrentTime()}</h6>
              <p style={{fontSize:"0.9rem",marginLeft:"0%"}}>Employee Name :{employeeDetails!==null &&employeeDetails.staff_name}</p>
              <p style={{fontSize:"0.9rem",marginLeft:"0%"}}>Employee ID &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:{employeeDetails!==null &&employeeDetails.emp_id}</p>
            </div>
            <div className='print-view-top-middle-container' style={{width:'30%'}}>
            {userRole === "admin" ? (
                <h3>{loginResponse?.data?.profile?.companyName}</h3>
              ) : userRole === "user" ? (
                <h3>{loginResponse?.storeName}</h3>
              ) : undefined}
                 <p>STAFF EXPENSE REPORT</p>
                 {
                  fromDate!==""&& toDate!=="" &&
                 <h5 style={{margin:"0",textAlign:"center"}}>{`(${convertDateFormat(fromDate)}-${convertDateFormat(toDate)})`}</h5>
                 }
            </div>
            <div className='print-view-top-right-container' style={{width:'35%'}}>
               
            </div>
          </div>
          <div className='print-view-table-container report-print-view-table-with-clr'>

          <table>
          <thead>
               <tr>
                <th>No</th>
                {
                  userRole ==="admin"&&
                  <>
                  <th>Branch Code</th>
                  <th>Branch Name</th>
                  </>
                }
                
                <th>Expense</th>
                <th>Notes</th>
                <th>Date</th>
                <th>Amount</th>
               </tr>
             </thead>
             <tbody>
             {
                expenseReportFilter?.map((r,i)=>(
                  <tr>
                    <td>{i+1}</td>
                    {
                      userRole === "admin" &&
                      <>
                       <td>{r?.branchCode}</td>
                       <td>{r?.branchName}</td>
                      </>
                    }
                   
                    <td>{r?.expense}</td>
                    <td>{r?.narration}</td>
                     <td>{convertDateFormat(r?.date)}</td>
                    <td>{r?.amount}</td>
                  </tr>
                ))
               }
             </tbody>
          </table>
          </div>
     
       </div>
     </div>

     {/* outlet expense report */}
     <div 
      style={{display:'none'}}
     >
       <div ref={(el)=>(outletExpenseReportPrint = el)}>
          <div className='print-view-top-container'>
            <div className='print-view-top-left-container' style={{width:'35%'}}>
            <h6>Printed On:{convertDateFormat(today)}&nbsp;{getCurrentTime()}</h6>
            </div>
            <div className='print-view-top-middle-container' style={{width:'30%'}}>
                <h3>TEXTILE NAME</h3>
                 <p>OUTLET EXPENSE REPORT</p>
                 {
                  fromDate!==""&& toDate!=="" &&
                 <h5 style={{margin:"0",textAlign:"center"}}>{`(${convertDateFormat(fromDate)}-${convertDateFormat(toDate)})`}</h5>
                 }
            </div>  
            <div className='print-view-top-right-container' style={{width:'35%'}}>
             
            </div>
          </div>
          <div className='print-view-table-container report-print-view-table-with-clr'>

          <table>
          <thead>
              <tr>
               <th>No</th>
               {
                userRole ==="admin"&&
                <>
                <th>Branch Code</th>
                <th>Branch Name</th>
                </>
               }
               
               <th>Doc No</th>
               <th>Expense Type</th>
               <th>Notes</th>
               <th>Date</th>
               <th>Amount</th>
               <th>Paid By</th>
               <th>Credit Amount</th>
               <th>Last Paid Amount</th>
               <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {
                expenseReportFilter?.map((r,i)=>(
               <tr>
                <td>{i+1}</td>
                {
                  userRole === "admin" &&
                  <>
                   <td>{r?.branchId}</td>
                   <td>{r?.branchName}</td>
                  </>
                }
               
                <td>{r?.transNo}</td>
                <td>{r?.expenseType}</td>
                <td>{r?.narration}</td>
                 <td>{convertDateFormat(r?.date)}</td>
                <td>{r?.amount}</td>
                <td>{r?.paidBy}</td>
                <td>---</td>
                <td>{r?.lastPaidAmount}</td>
                <td>{convertDateFormat(r?.lastPaidDate)}</td>
              </tr>
                ))
              }
            </tbody>
          </table>
          </div>
     
       </div>
     </div>

     {/* petty cash receipt */}
     <div 
      style={{display:'none'}}
     >
       <div ref={(el)=>(pettyCashReceiptPrint = el)}>
          <div className='print-view-top-container'>
            <div className='print-view-top-left-container' style={{width:'35%'}}>
            <h6>Printed On:{convertDateFormat(today)}&nbsp;{getCurrentTime()}</h6>
            </div>
            <div className='print-view-top-middle-container' style={{width:'30%'}}>
                <h3>TEXTILE NAME</h3>
                 <p>PETTY CASH RECEIPT</p>
                 {
                  fromDate!==""&& toDate!=="" &&
                 <h5 style={{margin:"0",textAlign:"center"}}>{`(${convertDateFormat(fromDate)}-${convertDateFormat(toDate)})`}</h5>
                 }
            </div>
            <div className='print-view-top-right-container' style={{width:'35%'}}>
                
            </div>
          </div>
          <div className='print-view-table-container report-print-view-table-with-clr'>

          <table>
          <thead>
              <tr>
               <th>No</th>
               {
                userRole === "admin" &&
                <>
                 <th>Branch Code</th>
                 <th>Branch Name</th>
                </>
               }
               
               <th>Doc No</th>
               <th>Outlet Code</th>
               <th>Date</th>
               <th>Amount</th>
              </tr>
            </thead>
            <tbody>
            {
                expenseReportFilter?.map((r,i)=>(
               <tr>
                <td>{i+1}</td>
                {
                  userRole ==="admin"&&
                  <>
                   <td>{r?.branchId}</td>
                   <td>{r?.branchName}</td>
                  </>
                }
               
                <td>{r?.transNo}</td>
                <td>---</td>
                <td>{convertDateFormat(r?.date)}</td>
                <td>{r?.amount}</td>
                
              </tr>
                ))
              }
            </tbody>
          </table>
          </div>
     
       </div>
     </div>


    </div>
  )
}
