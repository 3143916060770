import { createSlice } from "@reduxjs/toolkit";

export const establishmentId=createSlice({
    name:'establishmentId',
    initialState:{
        establishmentIdView:undefined,
        establishIdViewById:undefined,
        generateEstablishId:undefined,
    },
    reducers:{
        view_establishmentId:(state,action)=>{
            state.establishmentIdView=action.payload.establishmentIdViewData
        },
        view_establishid_by_id:(state,action)=>{
            state.viewEstablishIdById=action.payload.establishmentIdViewDataById
        },
        edit_establishment_id:(state,action)=>{
            state.editEstablishmentId=action.payload.updateEstablishmentId
        },
        generate_establishId:(state,action)=>{
            state.generateEstablishId=action.payload.addEstablishId
        }
    }
})
export const {view_establishmentId,view_establishid_by_id,edit_establishment_id,generate_establishId}= establishmentId.actions;
export default establishmentId.reducer;