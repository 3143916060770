import React from "react";
import InputBox from "../InputBox";
import { useDispatch, useSelector } from "react-redux";
import { setOpeningBalance } from "../../../../../Redux/Account/miscExpensesSlice";
import SubmitBtn from "../SubmitBtn";

const MiscExpensesAsset = () => {
  const dispatch = useDispatch();
  const { openingBalance } = useSelector((state) => state.miscExpensesSlice);
  const longTermInvestment = useSelector((state) => state.miscExpensesSlice);
  const handleMiscExpensesAssetSubmit = () => {
    console.log(longTermInvestment);
  };
  return (
    <div
      style={{ padding: "2% 4%" }}
      className="ledger-section-container-input-bank "
    >
      <div>
        {/*Opening Balance*/}
        <InputBox
          label={"Opening Balance"}
          value={openingBalance}
          onChange={(e) => dispatch(setOpeningBalance(e.target.value))}
          containerStyle={{width:'31%'}}

        />
      </div>
      {/* submitButton */}
      <SubmitBtn onClick={handleMiscExpensesAssetSubmit} />
    </div>
  );
};

export default MiscExpensesAsset;
