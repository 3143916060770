import React, { useEffect, useState } from 'react'
import CustomPagination from '../../../Single Components/CustomPagination';
import CategorySearchandFilter from '../../../Single Components/CategorySearchandFilter';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BankReconciliationListAPI } from './BankReconciliationAPI';
import { set_bank_reconciliation_Singlelist, set_bank_reconciliation_table } from './BankReconciliationSlice';
import store from '../../../../Redux/store';

export const BankReconciliationNew = () => {
    let navigate = useNavigate();
    const [FormDataInfo, setFormDataInfo] = useState([])
    const [currentPage, setCurrentPage] = useState(3);

    const listData= useSelector((state)=> state?.bankReconciliation?.bankReconciliationListData)
    console.log(listData);
    
    const totalPages = 4; 
    const handlePageChange = (page) => {
    
        setCurrentPage(page);
      };

      const handleDataFromChild = (data) => {
        setFormDataInfo(data)
      };

      const handleCreate=()=>{
        navigate("/userdashboard/accounts/Reconciliation/BankReconciliation/Create")
      }

      const handleClickRow = (row) =>{
        localStorage.setItem("bank_id", row?._id)
        navigate("/userdashboard/accounts/Reconciliation/BankReconciliation/Create")
      }

      useEffect(()=>{
        BankReconciliationListAPI()
        store.dispatch(set_bank_reconciliation_Singlelist({bankReconciliationSingleList:undefined}))
        store.dispatch(set_bank_reconciliation_table({bankReconciliationTableData:undefined}))

      },[])
  return (
    <div className="global-page-parent-container">
    {/* table */}
    <div className="global-white-bg-container">
      <div className="justify-space-between"
        style={{ gap: "0 12px", justifyContent: "flex-end" }}
      >
        <CategorySearchandFilter
          statusOptions={[ 'DRAFT','CANCEL']}
          onData={handleDataFromChild}
          isCreatedBy={true}
          isStatus={true} />
        <button
         onClick={()=>{handleCreate()}}
          className="create-button-blue"
        >
          Create
        </button>
      </div>
      <div className="create-button-blue-container">
        <h3>Bank Reconciliation</h3>
        <CustomPagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
       
      </div>
      <div className="new-global-table-container">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Journal</th>
              <th>Referance</th>
              <th>Reconcliated Amount</th>
              <th>Bank Charge</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {listData?.slice().reverse()?.map((item,index)=>(
                  <tr key={index} onClick={()=>handleClickRow(item)}>
                    <td>{item.date}</td>
                    <td>{item.journal}</td>
                    <td>{item.reference}</td>
                    <td>{item.reconcilatedAmount}</td>
                    <td>{item.bankCharge}</td>
                    <td className='table-data-box'> {item?.status === "Drafted" ? (
                          <span className='draft'> DRAFT</span>
                        ) : item?.status === "COMPLETED" ? (
                          <span className='post'> POST</span>
                        )   :
                        item.status==="In-progress" ?(
                           <span className="inprogress"> In Progress</span>
                        ):  (
                          <span>{item?.status}</span>
                        )}</td>
                  </tr>
                  ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
);
};
