import {createSlice} from '@reduxjs/toolkit'

export const customerSupplierListSlice=createSlice({
    name:'customerSuppList',
    initialState:{
        value:undefined
    },
    reducers:{
        get_customerSuppList:(state,action)=>{
            state.value=action.payload.customerSuppData
        }
    }
})
export const {get_customerSuppList} = customerSupplierListSlice.actions;
export default customerSupplierListSlice.reducer;