import { Dialog, IconButton, MenuItem, Select, Skeleton } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  createDesignationAPICall,
  editDesignationAPICall,
  getAllDepartmentAPICall,
  getAllDesignationAPICall,
} from "../../../../API/Staff/HRM/departmentAndDesignationAPI";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";

// CSS
// import '../../../../css/Staff/HRM/department.css'

export const Designation = () => {
  //*Redux State
  //Department List
  const allDepartmentList = useSelector(
    (state) => state.allDepartmentSlice.value
  );
  //Designation List
  const allDesignationList = useSelector(
    (state) => state.allDesignationListSlice.value
  );

  //Update Listener States
  const [isModify, setIsModify] = useState(false);
  const [addDesignation, setAddDesigntion] = useState(false);
  const [editDesignation, setEditDesignation] = useState(false);
  //Snackbar States
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!");

// validation states

  const [departmentAlert,setDepartmentAlert]=useState(false)
  const [designationNameAlert,setDesignationNameAlert]=useState(false)
// loading states
  const [isLoading,setIsLoading]=useState(false)

  //Add Designation Form state
  const addDesignationInitialState = {
    department: "0",
    designation: "",
    details: "",
  };
  const [addDesignationForm, setAddDesignationForm] = useState(
    addDesignationInitialState
  );

  //Edit Designation form state
  const editDesignationInitialState = {
    id:null,
    department: null,
    designation: "",
    details: "",
  };
  const [editDesignationForm, setEditDesignationForm] = useState(
    editDesignationInitialState
  );

  //Close SuccessSnackBar
  const closeSuccessSnackbar = () => {
    setSuccessSnackOpen(false);
  };
  //Close SuccessSnackBar
  const closeErrorSnackbar = () => {
    setErrorSnackOpen(false);
  };

  //Update Listener Fn
  const updateListener = () => {
    setIsModify(!isModify);
  };

  //* useEffects
  //Initial API Calls
  useEffect(() => {
    getAllDepartmentAPICall();
    getAllDesignationAPICall(
      setIsLoading,
      setErrorSnackOpen,
      setSnackMsg
    );
  }, [isModify]);

  //* onChange Fns
  const getAddDesignationFormData = (key) => (e) => {
    const { value } = e.target;
    if (key === "department") {
      setAddDesignationForm({ ...addDesignationForm, department: value });
      setDepartmentAlert(false)
    }
    if (key === "designation") {
      setAddDesignationForm({ ...addDesignationForm, designation: value });
      setDesignationNameAlert(false)
      if(value===""){
        setDesignationNameAlert(true)
      }
    }
    if (key === "details") {
      setAddDesignationForm({ ...addDesignationForm, details: value });
    }
  };

  //*Click functions
  const designationBody = {
    position: addDesignationForm.designation,
    details: addDesignationForm.details,
    departmentId: addDesignationForm.department,
  };
  const submitNewDesignation = () => {
    const { designation, details, department } = addDesignationForm;
    if (department === "0") {
      setDepartmentAlert(true)      
    }
    else if(designation===""){
      setDesignationNameAlert(true)
    }
    else {
      createDesignationAPICall(
        designationBody,
        updateListener,
        setErrorSnackOpen,
        setSuccessSnackOpen,
        setSnackMsg,
        clearStates
      );
    }
  };

  //Click Edit Designation Fn
  const clickEditDesignation = (id,department, designation, details) => () => {
    setEditDesignationForm({id, department, designation, details });
    setEditDesignation(true);
  };

  //Submit edit designation
  const submitEditDesignation = () => {
    editDesignationAPICall(
      {
        position: editDesignationForm.designation,
        details: editDesignationForm.details,
        id: editDesignationForm.id,
        departmentId: editDesignationForm.department,
      },
      updateListener,
      setErrorSnackOpen,
      setSuccessSnackOpen,
      setSnackMsg
    );
  };

  //Clear States
  const clearStates = () => {
    setAddDesignationForm(addDesignationInitialState);
    setAddDesigntion(false);
  };

  return (
    <div className="global-page-parent-container">
      <p className="breadcrumb">Staff &gt; HRM &gt; Designation </p>
      <div className="department-container">
        <div className="department-top-container">
          <h3>Designation</h3>
          <button
            className="designation-button"
            onClick={() => setAddDesigntion(true)}
          >
            Add Designation
          </button>
        </div>
        <div className="global-table-container">
          <table className="global-table">
            <thead>
              <tr>
                <th>No</th>
                <th>Department</th>
                <th>Designation</th>
                <th>Details</th>
                <th>Edit</th>
                {/* <th>Delete</th> */}
              </tr>
            </thead>
            {
              isLoading?
              <tbody>
              <tr>
                <td colSpan={9}>
                  {/* <Loader /> */}
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width={"100%"}
                    height={40}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={9}>
                  {/* <Loader /> */}
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width={"100%"}
                    height={40}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={9}>
                  {/* <Loader /> */}
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width={"100%"}
                    height={40}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={9}>
                  {/* <Loader /> */}
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width={"100%"}
                    height={40}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={9}>
                  {/* <Loader /> */}
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width={"100%"}
                    height={40}
                  />
                </td>
              </tr>
            </tbody>:
            <tbody>
              {allDesignationList !== undefined ? (
                allDesignationList
                  ?.slice(0)
                  ?.reverse()
                  ?.map((r, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{r?.departmentName?.toUpperCase()}</td>
                      <td>{r?.position?.toUpperCase()}</td>
                      <td>{r?.details !== "" ? r?.details?.toUpperCase() : "-NIL-"}</td>
                      <td>
                        <IconButton
                          onClick={clickEditDesignation(
                            r?._id,
                            r?.departmentId,
                            r?.position,
                            r?.details
                            )}
                            >
                          <i class="bi bi-pencil-square edit-icon"></i>
                        </IconButton>
                      </td>
                      {/* <td>
                        <IconButton disabled className="disabled-icon-button">
                          <i class="bi bi-trash3 delete-icon"></i>
                        </IconButton>
                      </td> */}
                    </tr>
                  ))
                  ) : (
                    <tr>
                  <td colSpan={4}>No data</td>
                </tr>
              )}
            </tbody>
        }
          </table>
        </div>
      </div>

      {/* add designation dialog */}
      <Dialog open={addDesignation} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setAddDesigntion(false )}>
        <div className="add-department-dialog-container">
          <h3>Add Designation</h3>
          <hr className="global-hr" />
          <div className="global-single-input department-input">
            <p>Department</p>
            <Select
              value={addDesignationForm.department}
              onChange={getAddDesignationFormData("department")}
            >
              <MenuItem value="0">-select a department-</MenuItem>
              {allDepartmentList?.map((item, i) => (
                <MenuItem value={item?._id} key={i}>
                  {item?.departmentName}
                </MenuItem>
              ))}
            </Select>
              {
                departmentAlert?
                <p className="doc-validation-alert">Select a Department !!</p>
              :""
              }
          </div>
          <div className="global-single-input department-input">
            <p>Designation</p>
            <input
              type="text"
              placeholder="Enter new designation name..."
              value={addDesignationForm.designation}
              onChange={getAddDesignationFormData("designation")}
            />
            {
              designationNameAlert?
              <p className="doc-validation-alert">Enter This Field</p>
              :""
            }
          </div>
          <div className="global-single-input department-input">
            <p>Details</p>
            <textarea
              name=""
              id=""
              cols="30"
              rows="5"
              placeholder="Enter details of new designation... "
              value={addDesignationForm.details}
              onChange={getAddDesignationFormData("details")}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  submitNewDesignation();
                }
              }}
            />
          </div>

          <div className="department-button-container">
            <button
              onClick={clearStates}
              className="btn btn-secondary-outlined"
            >
              Cancel
            </button>
            <button className="btn btn-primary" onClick={submitNewDesignation}>
              Submit
            </button>
          </div>
        </div>
      </Dialog>
      {/* edit designation dialog */}
      <Dialog open={editDesignation} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setEditDesignation(false )}>
        <div className="add-department-dialog-container">
          <h3>Edit Designation</h3>
          <hr className="global-hr" />
          <div className="global-single-input department-input">
            <p>Department</p>
            <Select
              value={editDesignationForm.department}
              onChange={(e) =>
                setEditDesignationForm({
                  ...editDesignationForm,
                  department: e.target.value,
                })
              }
            >
              <MenuItem value="0">-select a department-</MenuItem>
              {allDepartmentList?.map((item, i) => (
                <MenuItem value={item?._id} key={i}>
                  {item?.departmentName}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="global-single-input department-input">
            <p>Designation</p>
            <input
              type="text"
              placeholder="Enter new designation name..."
              value={editDesignationForm.designation}
              onChange={(e) =>
                setEditDesignationForm({
                  ...editDesignationForm,
                  designation: e.target.value,
                })
              }
            />
          </div>
          <div className="global-single-input department-input">
            <p>Details</p>
            <textarea
              name=""
              id=""
              cols="30"
              rows="5"
              placeholder="Enter details of new designation... "
              value={editDesignationForm.details}
              onChange={(e) =>
                setEditDesignationForm({
                  ...editDesignationForm,
                  details: e.target.value,
                })
              }
              onKeyDown={(e)=>{if(e.key==='Enter'){submitEditDesignation()}}}
            />
          </div>

          <div className="department-button-container">
            <button
              onClick={() => setEditDesignation(false)}
              className="btn btn-secondary-outlined"
            >
              Cancel
            </button>
            <button className="btn btn-primary" onClick={submitEditDesignation}>
              Submit
            </button>
          </div>
        </div>
      </Dialog>

      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </div>
  );
};
