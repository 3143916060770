import { createSlice } from "@reduxjs/toolkit";

export const allInactiveEmployeeSlice=createSlice({
    name:'allInactiveEmployeeSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_all_inactive_employee_list:(state,action)=>{
            state.value=action.payload.inactiveEmployees
        }
    }
})

export const {update_all_inactive_employee_list}=allInactiveEmployeeSlice.actions
export default allInactiveEmployeeSlice.reducer