import {createSlice} from '@reduxjs/toolkit'

export const viewQuotationIdSlice=createSlice({
    name:'quotationId',
    initialState:{
        value:undefined
    },
    reducers:{
        get_quotation_id:(state,action)=>{
            state.value=action.payload.quotationIdList
        }
    }
})
export const {get_quotation_id} = viewQuotationIdSlice.actions;
export default viewQuotationIdSlice.reducer;
