import { get_utility_payment_list } from "../../../../Redux/Settings/Utility/PaymentTypeAndAmount/paymentListSlice"
import store from "../../../../Redux/store"
import {HEADERS} from '../../../../API/UrlAndPaths'
import axios from 'axios'
import { get_payment_invoice_list } from "../../../../Redux/Settings/Utility/PaymentTypeAndAmount/paymetsInvoiceNoListSlice"

// utility payment list

export const utilityPaymentTypeListAPICall= async (body,setIsLoading,setErrorSnack,setSnackMsg)=>{



  if(setIsLoading!==undefined) setIsLoading(true)
   await axios.post("settings/fetchPayments",body,HEADERS)
   .then((res)=>{
     if(res.status===200){
        store.dispatch(get_utility_payment_list({utilityPaymentData:res.data}))
        if(setIsLoading!==undefined) setIsLoading(false)
      }
    })
    .catch((err)=>{
      store.dispatch(get_utility_payment_list({utilityPaymentData:undefined}))
      switch (err.response.status) {
        case 404:
            setSnackMsg('No data available..')
          break;
        case 500:
            setSnackMsg('Server error!!')
          break;
        case 401:
            setSnackMsg('Unauthorized!!')
          break;
        case 403:
            setSnackMsg('Forbidden!!')
          break;
      
        default:
            setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
          break;
      }
      setErrorSnack(true)
      if(setIsLoading!==undefined) setIsLoading(false)
      console.log(err.response);
   })
}

// payment invoice no list api call
export const paymentInvoiceNoListAPICall=async(body)=>{
    await axios.post("credit/paymentInvoicesforutility",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_payment_invoice_list({paymentInvoiceNoListData:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(get_payment_invoice_list({paymentInvoiceNoListData:undefined}))
        console.log(err.response);
    })
}
//change payment method api call
export const changePaymentMethodAPICall= async (body,updateListner,closeAnchorPaymentType)=>{
   await axios.put("credit/paymentchangetocashcard",body,HEADERS)
   .then((res)=>{
     if(res.status===200){
        updateListner()
        closeAnchorPaymentType()
     }
   })
   .catch((err)=>{
    console.log(err.response);
   })
}


// update payment APICall

export const updateUtilityPaymentAPICall=async(body,updateListner,setSuccess,setError,setSnackMsg)=>{
  await axios.put("credit/editUtilityPayment",body,HEADERS)
    .then((res)=>{
      if(res.status===200){
        updateListner()
        setSnackMsg("Payment edited ")
        setSuccess(true)
      }
    })
    .catch((err)=>{
      console.error(err.response);
      setError(true)
    })
}