import {
  Autocomplete,
  Dialog,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  createFilterOptions,
} from "@mui/material";

import draft from "../../../../../Assets/statusRibbon/draft.png";
import post from "../../../../../Assets/statusRibbon/post.png";

import React, { useEffect, useRef, useState } from "react";
import Banner from "../../../../../Assets/Images/banner-_2_.png";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import uploadFile from "../../../../../Assets/Images/Icon feather-upload.png";
import { CloseOutlined } from "@mui/icons-material";
import returned from "../../../../../Assets/statusRibbon/return.png";
import logoImage from "../../../../../Assets/Images/Icon ionic-logo-buffer.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  vendorBillRegisterPaymentNewAPICall,
  vendorListApi,
  viewVendorListAPICall,
} from "../API/vendorListAPICall";
import {
  companyProfileInfoForUser,
  viewAllBranchesAPICall,
  viewCompanyProfileInfoAPICall,
} from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import { journalListApiCall } from "../../../../../API/AccountUpdated/journalAPI";
import {
  addVendorBillNewApiCall,
  editVendorBillNewAPICall,
  generateVendorBillNameAPICall,
  getAllNewProductList,
  postVendorBillNewAPICall,
  returnVendorBillAPI,
  vendorBillSingleViewApi,
} from "../API/vendorBillAPI";
import {
  filterObjFromList,
  roundNumericFields,
} from "../../../../../Js/generalFunctions";
import { viewAllCurrencyUpdated } from "../../../../../API/Settings/Currency/CurrencyAPI";
import { viewMultiCurrencyConfiguration } from "../../../../../API/Purchase Manager/configuration";
import { getAllTaxSettingsListAPICall } from "../../../../../API/Settings/TaxSettings/taxSettingsAPI";
import {
  filterUomByPurchseUomAPICall,
  filterUomByPurchseUomEditAPICall,
  viewUOMListAPICall,
  viewUOMSubListAPICall,
} from "../../../Purchase Manager/Pages/UnitOfMeasurment/unitOfMeasurmentAPICall";
import { chartOfAccountListApiCall } from "../../../../../API/AccountUpdated/chartOfAccountAPI";
import { viewGlConfigAPICall } from "../../Configuration/glConfigAPI";
import { getCurrentTime, today } from "../../../../../Js/Date";
import SuccessSnackbar from "../../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
import "../accountsVendor.css";
import paidRibbon from "../../../../../Assets/statusRibbon/paid.png";
import { productSearchApi } from "../../../../../API/Purchase Manager/RFQ/ConfirmRfqApiCall";
import { LoadingForm } from "../../../../Single Components/LoadingForm";
import PaymentTerms from "../../../components/Dialog/paymentTerms/PaymentTerms";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";
export const CreateVendorBills1 = () => {
  const dispatch = useDispatch();
  let singleViewId = localStorage.getItem("singleViewById");
  const VendorList = useSelector((state) => state.vendorsListSlice.apiResList);
  const vendorBillSingleData = useSelector(
    (state) => state.vendorBillSlice?.rowData
  );
  const userRole = useSelector((state) => state.userRoleSlice.value);
  // const userBranchId = localStorage.getItem("branchId");
  const userLoginMeta = localStorage.getItem("login_meta");
  const userBranchId = JSON.parse(userLoginMeta)?.branchPk;
  const numToWords = require("num-words");
  const generatedName = useSelector(
    (state) => state.vendorBillSlice.generateName
  );

  // const companyProfileData = useSelector(
  //   (state) => state.companyProfileSlice.value
  // );

  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );

  const configurationList = useSelector(
    (state) => state.glConfigurationSlice.apiResList
  );
  const decimalPosition = Number(localStorage.getItem("decimalPosition"));
  const uomSubList = useSelector(
    (state) => state.unitOfMeasurmentSlice.subUomList
  );

  const chartOfAccountList = useSelector(
    (state) => state.chartOfAccountsSlice.value
  );
  const controlAndExpenseAccList = chartOfAccountList?.filter(
    (item) => item.type === "Cost of revenue" || item.type === "Expenses"
  );
  const allNewProductList = useSelector(
    (state) => state.vendorBillSlice.NewProductList
  );
  const allTaxList = useSelector((state) => state.taxSettingsSlice.value);
  const allTaxListPurchaseType = allTaxList?.filter(
    (item) => item?.taxType === "Purchase" && item?.status === true
  );

  const MultiCurrencyView = useSelector(
    (state) => state.ConfigurationSlice.MultiCurrency
  );

  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );

  const containerRef = useRef(null);
  const journalList = useSelector((state) => state.journalSlice.value);

  const journalSalesList = journalList?.filter(
    (item) => item?.type === "purchase"
  );
  const filterUomByPurchase = useSelector(
    (state) => state.unitOfMeasurmentSlice.filterByPurchaseList
  );

  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  const customerList = useSelector(
    (state) => state.vendorsListSlice.apiResList
  );
  const paymentTermsList = useSelector(
    (state) => state.vendorsListSlice?.paymentTermsList
  );
  const addRowData = [{ value: "Add Header" }, { value: "Add Line" }];
  const [logView, setLogView] = useState(false);
  const [log, setLog] = useState([]);
  const [imageDisplay, setImageDisplay] = useState([]);
  const [isTableModified, setIsTableModified] = useState(false);
  const [registerPayment, setRegisterPayment] = useState(false);
  const [image, setImage] = useState([]);
  const [validationAlert, setValidationAlert] = useState({});
  const [isActiveTable, setIsActiveTable] = useState("invoiceLines");
  const [addRowInput, setAddRowInput] = useState(addRowData[1]);
  const [paymentTypeList, setPaymentTypeList] = useState([]);

  const [showPostBtn, setShowPostBtn] = useState(false); // show Post Btn state
  const [editClicked, setEditClicked] = useState(false);
  const [showEditBtn, setShowEditbtn] = useState(false); // show Edit btn

  const [showSaveBtn, setShowSaveBtn] = useState(true);
  const [disableinput, setDisableInput] = useState(false);
  const [alertPopup, setAlertPopup] = useState(false);
  const [handlePaymentTermsbtn, setHandlePaymentTermsbtn] = useState("");

  // const [editClickedId, setEditClickedId] = useState({});
  const [RegisterPaymentShow, setRegisterPaymentShow] = useState(false);

  const [totalsInfo, setTotalsInfo] = useState({
    subTotal: 0.0,
    inclusiveTax: 0.0,
    exclusiveTax: 0.0,
    total: 0.0,
  });

  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
  });

  let navigateTo = useNavigate();


  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      message: "",
    });
  };

  const formInitialState = {
    name: "###",
    vendor: null,
    branch: null,
    billReference: "",
    billDate: today,
    paymentReference: "",
    accountingDate: today,
    journal: null,
    status: "",
    isGrnBill: false,
    currency: null,
    product: null,
    label: "",
    account: null,
    qty: "",
    uom: "",
    unitPrice: "",
    taxes: null,
    discount: "",
    subTotal: "",
    expireDate: today,
    visibleOnly: false,
    itemTotal: 0,
    taxAmt: 0,
    customernotes: "",
    termsandconditions: "",
    paymentTerms: null,

    registerPaymentDate: today,
    paymentType: null,
    // type: null,
    type: "Manual",
    chequeNo: "",
    registerPaymentChequeDate: "",
    registerPaymentAmount: "",
    paymentsDone: [],
    isEdit: false,
    paymentStatus: false,
  };
  const [formValues, setFormValues] = useState(formInitialState);

  const [tableData, setTableData] = useState([
    {
      type: "lines",
      id: Date.now(),
      product: null,
      label: "",
      account: null,
      uom: null,
      qty: "",
      unitPrice: "",
      discount: "",
      tax: [],
      amount: "",
      inclusive: "",
    },
  ]);

  const [filteredTaxOptions, setFilteredTaxOptions] = useState(
    tableData?.map(() => allTaxListPurchaseType || [])
  );

  const [filteredUOMOptions, setFilteredUOMOptions] = useState(
    tableData?.map(() => filterUomByPurchase || [])
  );
  const [rowIndex, setRowIndex] = useState(null);
  const [loadingForm, setLoadingForm] = useState(false);

  const formData = new FormData();
  const getFormInfo = (key) => (e, newValue) => {
    const { value } = e.target;
    const keyCode = e.keyCode || e.which;
    if (key === "registerPaymentAmount") {
      if (vendorBillSingleData?.amountDue > value) {
        setFormValues({ ...formValues, [key]: value !== 0 ? value : newValue });
      } else {
        setFormValues({
          ...formValues,
          [key]: value !== 0 ? vendorBillSingleData?.amountDue : newValue,
        });
      }
    } else if (keyCode === 13) {
      setValidationAlert({});
      setFormValues({
        ...formValues,
        [key]: newValue !== null ? newValue : null,
      });
    } else {
      setValidationAlert({});
      setFormValues({
        ...formValues,
        [key]:
          value !== 0 && value !== undefined
            ? value
            : newValue !== null
            ? newValue
            : null,
      });
    }
  };

  const removeImageFn = (i) => {
    image.splice(i, 1);
    imageDisplay.splice(i, 1);
    setImage([...image]);
    setImageDisplay([...imageDisplay]);
  };
  const [addRowScroll, setAddRowScroll] = useState(false);
  //addRow
  const addRow = () => {
    if (addRowInput?.value === "Add Header") {
      setTableData([
        ...tableData,
        { type: "header", id: Date.now(), value: "" },
      ]);
    } else if (addRowInput?.value === "Add Line") {
      setTableData([
        ...tableData,
        {
          type: "lines",
          id: Date.now(),
          product: null,
          label: "",
          account: null,
          uom: null,
          qty: "",
          unitPrice: "",
          discount: "",
          tax: [],
          amount: "",
          inclusive: "",
        },
      ]);
    }

    // setAddRowInput(null);
    setAddRowScroll(!addRowScroll);
  };

  //handleInputChange
  const handleInputChange = (id, field) => (e, newValue) => {
    const { value } = e.target;
    const updatedData = tableData?.map((row, index) => {
      if (index === id) {
        if (field === "product") {
          setRowIndex(index);
          const selectedTaxId = filterObjFromList(
            "_id",
            allTaxList,
            "vendorTax",
            newValue
          )?.includedPrice;

          const newFilteredTaxOptions =
            selectedTaxId !== undefined
              ? allTaxListPurchaseType.filter(
                  (option) => option.includedPrice === selectedTaxId
                )
              : allTaxListPurchaseType;
          filteredTaxOptions[index] = newFilteredTaxOptions;
          // filterUomByPurchseUomAPICall({ purchaseuom: newValue?.uom });

          return {
            ...row,
            [field]: newValue,
            label: newValue?.productName || "",
            account: filterObjFromList(
              "_id",
              chartOfAccountList,
              "purchaseControlAccount",
              configurationList?.purchase
            ),
            uom: filterObjFromList("_id", uomSubList, "purchaseuom", newValue),
            qty: newValue !== null ? newValue?.stock : "",
            unitPrice: newValue?.cost || "",
            discount: newValue !== null ? 0 : "",
            tax:
              filterObjFromList("_id", allTaxList, "vendorTax", newValue) ===
              null
                ? []
                : [filterObjFromList("_id", allTaxList, "vendorTax", newValue)],
            amount:
              newValue !== null ? newValue?.cost * newValue?.stock : "",
            inclusive: selectedTaxId !== undefined ? selectedTaxId : "",
          };
        } else if (field === "tax") {
          const selectedTaxId =
            newValue?.length !== 0 ? newValue[0]?.includedPrice : undefined;
          const newFilteredTaxOptions =
            selectedTaxId !== undefined
              ? allTaxListPurchaseType.filter(
                  (option) => option.includedPrice === selectedTaxId
                )
              : allTaxListPurchaseType;
          filteredTaxOptions[index] = newFilteredTaxOptions;
          return {
            ...row,
            tax: newValue,
            inclusive: selectedTaxId !== undefined ? selectedTaxId : "",
          };
        } else if (field === "qty") {
          let totalAmt = 0;
          let totalItemAmt =
            Number(value)?.toFixed(decimalPosition) * Number(row?.unitPrice);
          let discount = (Number(row?.discount) / 100) * totalItemAmt;

          totalAmt = totalItemAmt - discount;
          return { ...row, [field]: e.target.value, amount: totalAmt };
        } else if (field === "unitPrice") {
          let totalAmt = 0;
          let totalItemAmt =
            Number(value)?.toFixed(decimalPosition) * Number(row?.qty);
          let discount = (Number(row?.discount) / 100) * totalItemAmt;

          totalAmt = totalItemAmt - discount;
          return { ...row, [field]: e.target.value, amount: totalAmt };
        } else if (field === "discount") {
          let totalAmt = 0;
          let totalItemAmt = Number(row?.qty) * Number(row?.unitPrice);
          let discount =
            (Number(value)?.toFixed(decimalPosition) / 100) * totalItemAmt;

          totalAmt = totalItemAmt - discount;
          return { ...row, [field]: e.target.value, amount: totalAmt };
        } else {
          return { ...row, [field]: value === 0 ? newValue : e.target.value };
        }
      }
      return row;
    });

    setTableData(updatedData);
    setIsTableModified(!isTableModified);
  };

  const clickLogView = () => {
    setLogView(!logView);
  };

  // const log = [
  //   { name: "munshid", type: "rfq", date: "27-10-2023 15:48" },
  //   { name: "munshid", type: "rfq", date: "27-10-2023 15:48" },
  //   { name: "munshid", type: "rfq", date: "27-10-2023 15:48" },
  //   { name: "munshid", type: "rfq", date: "27-10-2023 15:48" },
  //   { name: "munshid", type: "rfq", date: "27-10-2023 15:48" },
  //   { name: "munshid", type: "rfq", date: "27-10-2023 15:48" },
  //   { name: "munshid", type: "rfq", date: "27-10-2023 15:48" },
  //   { name: "munshid", type: "rfq", date: "27-10-2023 15:48" },
  // ];

  const changeImage = (e) => {
    setImage([...image, e.target.files[0]]);
    setImageDisplay([...imageDisplay, URL.createObjectURL(e.target.files[0])]);
  };

  //useEffect

  //for set default currency

  //Tax calculation start
  const calculateAndSetCalculatedValues = () => {
    const calculatedValues = tableData?.map((row) => {
      if (row?.inclusive === true) {
        let totalAmt =
          Number(row?.recievedQuantity || row?.qty) * Number(row?.unitPrice);
        let taxArray = row?.tax?.map((obj) => obj?.amount);

        let totalOfTax = taxArray.reduce((sum, item) => sum + item, 0);
        let taxableAmount = (totalAmt / (totalOfTax + 100)) * 100;

        let discount =
          (taxableAmount?.toFixed(decimalPosition) * row?.discount) / 100;

        let subtotalBeforeTax =
          taxableAmount?.toFixed(decimalPosition) -
          discount?.toFixed(decimalPosition);

        let totalDiscount = taxArray.reduce((total, taxPercentage) => {
          const discountAmount = (taxPercentage / 100) * subtotalBeforeTax;
          return total + discountAmount;
        }, 0);

        return { ...row, inclusiveTax: totalDiscount, exclusiveTax: 0 };
      } else if (row?.inclusive === false) {
        let totalAmt =
          Number(row?.recievedQuantity || row?.qty) * Number(row?.unitPrice);

        let discount =
          (totalAmt?.toFixed(decimalPosition) * row?.discount) / 100;

        let subtotalBeforeTax =
          totalAmt?.toFixed(decimalPosition) -
          discount?.toFixed(decimalPosition);

        let totalTax = row?.tax?.reduce((sum, item) => sum + item?.amount, 0);

        let totalExclTax = (subtotalBeforeTax * totalTax) / 100;

        return { ...row, exclusiveTax: totalExclTax, inclusiveTax: 0 };
      } else {
        return { ...row, exclusiveTax: 0, inclusiveTax: 0 };
      }
    });
    setTableData(calculatedValues);
  };

  //handleDelete
  const handleDelete = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };

  const departmentMenuClick = () => {
    document.getElementById("productMenuBtn").classList.remove("active");
    document.getElementById("departmentMenuBtn").classList.add("active");
    setIsActiveTable("others");
  };

  const productMenuClick = () => {
    document.getElementById("productMenuBtn").classList.add("active");
    document.getElementById("departmentMenuBtn").classList.remove("active");
    setIsActiveTable("invoiceLines");
  };
  const backButtonFunction = () => {
    if (formValues?.isEdit === false) {
      const userConfirmed = window.confirm(
        "Changes you made may not be saved."
      );

      if (userConfirmed) {
        localStorage.removeItem("singleViewById");
        navigateTo("/userdashboard/accounts/vendor/vendorBills");
      }
    } else {
      localStorage.removeItem("singleViewById");
      navigateTo("/userdashboard/accounts/vendor/vendorBills");
    }
  };

  //handleMouseEnter
  const handleMouseEnter = () => {
    const updatedArray = roundNumericFields(tableData, [
      "qty",
      "unitPrice",
      "discount",
      "amount",
      "recievedQuantity",
    ]);
    setTableData(updatedArray);
  };

  //closeRegistePayment
  const closeRegistePayment = () => {
    setRegisterPayment(false);
  };

  //SaveBtnFun

  const SaveBtnFun = (key) => () => {
    dispatch( setFilterActive(false))
    //formData
    formData.append("journelId", formValues?.journal?._id);
    formData.append("vendorId", formValues?.vendor?._id);
    formData.append("branchId", formValues?.branch?._id);
    formData.append("billreference", formValues?.billReference);
    formData.append("billDate", formValues?.billDate);
    formData.append("paymentReference", formValues?.paymentReference);
    formData.append("accountingDate", formValues?.accountingDate);
    formData.append("currency", formValues?.currency?._id); // currency feild ??
    // formData.append("paymentTerms", formValues?.paymentTerms?._id)
    formValues?.paymentTerms !== null && formData.append("paymentTerms", formValues?.paymentTerms?._id)
    formData.append("customerNote", formValues?.customernotes);
    formData.append("termsAndCondition", formValues?.termsandconditions);
    formData.append(
      "otherInfo",
      JSON.stringify({ expiryDate: formValues?.expireDate })
    ); // stringfy

    formData.append(
      "tax",
      Number(totalsInfo?.inclusiveTax?.toFixed(decimalPosition)) +
        Number(totalsInfo?.exclusiveTax?.toFixed(decimalPosition))
    );
    formData.append("total", totalsInfo?.total?.toFixed(decimalPosition));
    formData.append("amountDue", 0);
    formData.append("postDate", `${today} ${getCurrentTime()}`);
    image.forEach((file, index) => {
      formData.append("file", file);
    });

    const productInfo = tableData.map((item) => {
      if (item.type === "header") {
        return {
          line_type: item.type,
          label: item?.value,
        };
      } else {
        return {
          line_type: item.type,
          _id: item.product?._id,
          mainCatgeoryId: item?.product?.mainCatgeoryId || item?.mainCatgeoryId,
          product: item?.product?.productName,
          prodType: "",
          label: item?.label,
          uomName: item?.product?.uomName || item?.uomName,
          uom: item?.product?.uom || item?.uom,
          purchaseUomName: item?.uom?.uomName,
          purchaseuom: item?.uom?._id,
          qty: Number(item?.qty),
          accountId: item?.account?._id,
          unitPrice: Number(item?.unitPrice),
          cost: "",
          discount: Number(item?.discount),
          includedPrice: item?.tax[0] ? item?.tax[0]?.includedPrice : null,
          // taxes: item.tax.map((singleTax) => ({
          //   _id: singleTax?._id,
          //   taxComputation: singleTax?.taxComputation,
          //   amount: singleTax?.amount,
          // })),
          taxes: item?.tax ? item?.tax : null,
          lineTotal: Number(item?.amount),
        };
      }
    });

    formData.append("itemInfo", JSON.stringify(productInfo));

    // draft
    if (key === "draft") {
      if (
        formValues?.vendor?._id === null ||
        formValues?.vendor?._id === undefined
      ) {
        setValidationAlert({
          ...validationAlert,
          vendor: "fill this field !!",
        });
      } else if (
        formValues.branch?.storeCode === null ||
        formValues.branch?.storeCode === undefined
      ) {
        setValidationAlert({
          ...validationAlert,
          branch: "fill this field !!",
        });
      } else if (
        formValues.billDate === null ||
        formValues.billDate === undefined ||
        formValues.billDate === ""
      ) {
        setValidationAlert({
          ...validationAlert,
          billDate: "fill this field !!",
        });
      } else if (
        formValues?.accountingDate === null ||
        formValues?.accountingDate === undefined ||
        formValues?.accountingDate === ""
      ) {
        setValidationAlert({
          ...validationAlert,
          accountingDate: "fill this field !!",
        });
      } else if (
        formValues?.journal === null ||
        formValues?.journal === undefined
      ) {
        setValidationAlert({
          ...validationAlert,
          journal: "fill this field !!",
        });
      } else if (
        formValues?.currency === null ||
        formValues?.currency === undefined
      ) {
        setValidationAlert({
          ...validationAlert,
          currency: "fill this field !!",
        });
      } else {
        let draftFn = () => {
          setFormValues({ ...formValues, status: "DRAFT", isEdit: true });
        };
        addVendorBillNewApiCall(
          formData,
          setSnackBarStates,
          snackBarStates,
          setShowPostBtn,
          setShowEditbtn,
          setDisableInput,
          draftFn,
          setLoadingForm
        );
      }
    }
    if (key === 'return') {
      returnVendorBillAPI({
        id: singleViewId,
        postDate: `${today} ${getCurrentTime()}`
      }, setAlertPopup, setSnackBarStates)
    }

    // edit
    if (key === "edit") {
      formData.append("id", singleViewId);

      let editFn = () => {
        setFormValues({ ...formValues, isEdit: true });
      };

      editVendorBillNewAPICall(
        formData,
        setSnackBarStates,
        snackBarStates,
        setEditClicked,
        setShowPostBtn,
        setDisableInput,
        editFn,
        setLoadingForm
      );
    }
    //post
    if (key === "post") {
      let postFn = () => {
        setFormValues({ ...formValues, status: "COMPLETED", isEdit: true });
      };
      // formData.append("_id", vendorBillSingleData?._id);
      formData.append("_id", singleViewId);
      postVendorBillNewAPICall(
        formData,
        setSnackBarStates,
        snackBarStates,
        setRegisterPaymentShow,
        setShowPostBtn,
        setShowEditbtn,
        singleViewId,
        postFn,
        setDisableInput,
        setLoadingForm
      );
    }
    if (key === "registerPaymentPost") {
      // formData.append(
      //   "vendorBillId",
      //    vendorBillSingleData?._id
      // );

      formData.append("vendorBillId", singleViewId);

      formData.append("paymentTypeId", formValues?.paymentType?._id);
      formData.append("chequeDate", formValues?.registerPaymentChequeDate);
      formData.append("chequeNo", formValues?.chequeNo);
      formData.append(
        "type",
        formValues.type == "Manual" ? 1 : formValues.type == "Cheque" ? 2 : ""
      ); //type
      formData.append("totalAmount", formValues?.registerPaymentAmount); //
      formData.append("paymentDate", formValues?.registerPaymentDate);
      formData.append(
        "amountInWords",
        numToWords(Math.floor(formValues?.registerPaymentAmount))
      );
      //validation

      //checking
      const fieldsToCheck = [
        "registerPaymentDate",
        "paymentType",
        "type",
        "chequeNo",
        "registerPaymentChequeDate",
        "registerPaymentAmount",
      ];
      const isFieldEmpty = (field) =>
        field === null || field === undefined || field === "";
      if (singleViewId) {
        let allFieldsValid = true;
        for (const fieldName of fieldsToCheck) {
          if (
            (fieldName === "registerPaymentChequeDate" &&
              formValues.type === "Cheque") ||
            fieldName !== "registerPaymentChequeDate"
          ) {
            if (isFieldEmpty(formValues[fieldName])) {
              setValidationAlert({
                ...validationAlert,
                [fieldName]: "fill this field !!",
              });
              allFieldsValid = false;
              break;
            }
          }
        }

        if (allFieldsValid) {
          let sendObj = {
            vendorBillId: singleViewId,
            paymentTypeId: formValues?.paymentType?._id,
            chequeDate: formValues?.registerPaymentChequeDate,
            chequeNo: formValues?.chequeNo,
            type:
              formValues.type == "Manual"
                ? 1
                : formValues.type == "Cheque"
                ? 2
                : "",
            totalAmount: formValues?.registerPaymentAmount,
            paymentDate: formValues?.registerPaymentDate,
            amountInWords: numToWords(
              Math.floor(formValues?.registerPaymentAmount)
            ),
            postDate: `${today} ${getCurrentTime()}`,
          };

          vendorBillRegisterPaymentNewAPICall(
            sendObj,
            setSnackBarStates,
            snackBarStates,
            closeRegistePayment,
            singleViewId
          );
        }
      }
    }
  };
  const editBtnFun = () => {
    setEditClicked(true);
    setDisableInput(false);
    setShowPostBtn(false);
    setFormValues({ ...formValues, isEdit: false });
  };

  //clearRegisterForm
  const clearRegisterForm = () => {
    setFormValues({
      ...formValues,
      registerPaymentDate: "",
      paymentType: null,
      type: null,
      chequeNo: "",
      registerPaymentChequeDate: "",
      registerPaymentAmount: "",
    });
  };

  const registerPymentCancelBtn = () => {
    setRegisterPayment(false);
    clearRegisterForm();
  };

  useEffect(() => {
    if (vendorBillSingleData !== undefined) {
      setLog(vendorBillSingleData?.log || []);

      setFormValues({
        ...formValues,
        vendor:vendorBillSingleData?.vendorAddress,
        branch: filterObjFromList(
          "_id",
          allBranchesList,
          "branchId",
          vendorBillSingleData
        ),
        billReference: vendorBillSingleData?.billreference,
        billDate: vendorBillSingleData?.billDate,
        paymentReference: vendorBillSingleData?.paymentReference,
        // accountingDate: vendorBillSingleData?.accountingDate,
        journal: filterObjFromList(
          "_id",
          journalList,
          "journelId",
          vendorBillSingleData
        ),
        currency: filterObjFromList(
          "_id",
          currencyListUpdated,
          "currency",
          vendorBillSingleData
        ),
        name: vendorBillSingleData?.name,
        customernotes: vendorBillSingleData?.customerNote,
        termsandconditions: vendorBillSingleData?.termsAndCondition,
        expireDate: vendorBillSingleData?.dueDate,
        paymentsDone: vendorBillSingleData?.paymentsDone,
        currencyName: vendorBillSingleData?.currencyName,
        status: vendorBillSingleData?.status,
        paymentStatus: vendorBillSingleData?.paymentStatus,
        currencyDetails: vendorBillSingleData?.currencyDetails,
        amountDue: vendorBillSingleData?.amountDue,
        isEdit: true,
        paymentStatus: vendorBillSingleData?.paymentStatus,
        registerPaymentAmount: vendorBillSingleData?.amountDue,
        paymentTerms:filterObjFromList(
          "_id",
          paymentTermsList,
          "paymentTerms",
          vendorBillSingleData
        )
      });

      setShowEditbtn(formValues.status === "DRAFT" ? true : false);
      setShowSaveBtn(formValues.status === "" ? true : false);
      setDisableInput(formValues.status === "DRAFT" ? true : false);
      setShowPostBtn(formValues.status === "DRAFT" ? true : false);
    } else {
      setFormValues({
        ...formValues,
        currency: filterObjFromList(
          "_id",
          currencyListUpdated,
          "currency",
          companyProfileData
        ),
        
      });
    }
  }, [
    vendorBillSingleData,
    chartOfAccountList,
    companyProfileData,
    currencyListUpdated,
  ]);

  useEffect(() => {
    if (vendorBillSingleData !== undefined) {
      const updatedArray = vendorBillSingleData?.itemInfo?.map((r, i) => {
          // filterUomByPurchseUomEditAPICall(
          //   { purchaseuom: r?.uom },
          //   i,
          //   filteredUOMOptions
          // );

        if (r?.line_type === "Line" || r?.line_type === "lines") {
          return {
            type: r?.line_type,
            product:r?.prodObj,
            label: r?.label,
            account: filterObjFromList(
              "_id",
              chartOfAccountList,
              "accountId",
              r
            ),
            uom: filterObjFromList("_id", uomSubList, "purchaseuom", r),
            qty: r?.qty,
            unitPrice: r?.unitPrice,
            discount: r?.discount,
            tax: r?.taxes,
            amount:
               r?.qty * r?.unitPrice -
              ((r?.qty) * r?.unitPrice * r?.discount) /
              // (r?.recievedQuantity || r?.qty) * r?.unitPrice -
              // ((r?.recievedQuantity || r?.qty) * r?.unitPrice * r?.discount) /
                100,
            inclusive: r?.includedPrice,
          };
        } else {
          return {
            type: r?.line_type,
            header: r?.label,
          };
        }
      });
      setTableData(updatedArray);
      setIsTableModified(!isTableModified);
      setFilteredTaxOptions(updatedArray?.map(() => allTaxList || []));
    }
  }, [vendorBillSingleData?.itemInfo]);

  //default journalName
  useEffect(() => {
    if (vendorBillSingleData === undefined && configurationList !== undefined) {
      setFormValues({
        ...formValues,
        journal: filterObjFromList(
          "_id",
          journalList,
          "purchaseJournal",
          configurationList?.purchase
        ),
      });
    }
  }, [configurationList,journalList]);

  useEffect(() => {
    if (vendorBillSingleData === undefined) {
      let userBranch = {
        branchId: userBranchId,
      };
      allBranchesList !== undefined &&
        userRole === "user" &&
        setFormValues({
          ...formValues,
          branch: filterObjFromList(
            "_id",
            allBranchesList,
            "branchId",
            userBranch
          ),
        });
    }
  }, [userRole, allBranchesList]);

  useEffect(() => {
    if (filterUomByPurchase !== undefined) {
      filteredUOMOptions[rowIndex] = filterUomByPurchase;
    } else {
      filteredUOMOptions[rowIndex] = [];
    }
  }, [filterUomByPurchase]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [addRowScroll]);

  useEffect(() => {
    // viewVendorListAPICall();
    // vendorListApi();
    viewAllBranchesAPICall();
    journalListApiCall();
    //  getAllNewProductList();
    // productSearchApi({search:""})
    // viewUOMListAPICall();
    viewUOMSubListAPICall();
    getAllTaxSettingsListAPICall();
    // viewAllCurrencyUpdated({ status: 0 });
    viewMultiCurrencyConfiguration();
    chartOfAccountListApiCall();
    // viewGlConfigAPICall();
    // viewCompanyProfileInfoAPICall();
    companyProfileInfoForUser();
    if (singleViewId) {
      vendorBillSingleViewApi(
        { _id: singleViewId },
        setDisableInput,
        setShowSaveBtn
      );
    }
  }, []);

  useEffect(() => {
    calculateAndSetCalculatedValues();
  }, [isTableModified]);

  useEffect(() => {
    setTotalsInfo({
      ...totalsInfo,
      subTotal: tableData?.reduce(
        (sum, item) => sum + Number(item?.amount || 0),
        0
      ),
      inclusiveTax: tableData?.reduce(
        (sum, item) => sum + Number(item?.inclusiveTax || 0),
        0
      ),
      exclusiveTax: tableData?.reduce(
        (sum, item) => sum + Number(item?.exclusiveTax || 0),
        0
      ),
      total:
        tableData?.reduce((sum, item) => sum + Number(item?.amount || 0), 0) +
        tableData?.reduce(
          (sum, item) => sum + Number(item?.exclusiveTax || 0),
          0
        ),
    });
  }, [tableData]);

  //Tax calculation End

  // generateVendorBillName
  useEffect(() => {
    if (
      formValues.branch !== null &&
      formValues.journal !== null &&
      formValues.billDate !== ""
    ) {
      formValues.name === "###" &&
        generateVendorBillNameAPICall({
          branchId: formValues.branch?._id,
          journelId: formValues.journal?._id,
          billDate: formValues.billDate,
        });
    } else {
      setFormValues({ ...formValues, name: "###" });
    }
  }, [formValues.branch, formValues.journal, formValues.billDate]);

  useEffect(() => {
    // if(editedData!==undefined){
    //   setFormValues({...formValues,name:editedData?.name})
    // }
    // else{
    setFormValues({
      ...formValues,
      name: generatedName !== undefined ? generatedName : "###",
    });
    // }
  }, [generatedName]);

  // filter payment type
  useEffect(() => {
    if (journalList !== undefined) {
      let stockValuationAccList = journalList?.filter(
        (item) => item.type === "bank" || item.type === "cash"
      );
      setPaymentTypeList(stockValuationAccList);
    }
  }, [journalList]);

  // Amount due
  useEffect(() => {
    setFormValues({
      ...formValues,
      registerPaymentAmount: totalsInfo?.total?.toFixed(decimalPosition),
    });
  }, [totalsInfo?.total]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const shouldShowAlert = !formValues?.isEdit;
      if (shouldShowAlert) {
        event.preventDefault();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formValues?.isEdit]);

  const filterOptions = createFilterOptions({
    stringify: (option) => option.productName + option.partNo,
  });

 
  return (
    <div className="global-page-parent-container">
      
      <div
        className="new-global-white-bg-container"
        style={{ padding: "0 2%" }}
      >
        <div className="new-global-white-bg-icon-container">
          <div className="new-global-white-bg-icon-container-left">
            <Tooltip title="Back">
              <IconButton onClick={backButtonFunction}>
                <i class="bi bi-chevron-left"></i>
              </IconButton>
            </Tooltip>

            {formValues?.status === "DRAFT" && (
              <Tooltip title="Edit">
                <IconButton>
                  {editClicked ? (
                    <i
                      class="bi bi-check2-square edit-icon"
                      style={{ fontSize: "1.5rem" }}
                      onClick={SaveBtnFun("edit")}
                    ></i>
                  ) : (
                    <i
                      class="bi bi-pencil-square edit-icon1"
                      onClick={editBtnFun}
                    ></i>
                  )}
                </IconButton>
              </Tooltip>
            )}

            {formValues?.status === "" && (
              <Tooltip title="SaveBtn">
                <IconButton onMouseEnter={handleMouseEnter}>
                  <i
                    class="bi bi-floppy save-icon"
                    onClick={SaveBtnFun("draft")}
                  ></i>
                </IconButton>
              </Tooltip>
            )}

            {formValues?.status === "DRAFT" && !editClicked && (
              <>
              <button className="btn btn-post" onClick={SaveBtnFun("post")}>
                Post
              </button>
              <button
                   className="btn btn-primary"
                  style={{
                    backgroundColor: "#eb4034",
                    height: "32px",
                    width: "15%",
                  }}
                  onClick={() => setAlertPopup(true)}
                >
                  Return
                </button>
              </>
            )}

            {formValues?.status === "COMPLETED" &&
              !formValues?.paymentStatus && (
                <button
                  onClick={() => setRegisterPayment(true)}
                  style={{ width: "23%" }}
                  className="btn btn-primary"
                >
                  Register Payment
                </button>
              )}
          </div>

          {/* <div className="status-container">
            <p>Draft</p>
          </div> */}
          <div className="status-container">
            {formValues?.status === "DRAFT" && <img src={draft} alt="" />}
            {formValues?.status === "COMPLETED" && <img src={post} alt="" />}
            {formValues?.status === "RETURNED" && (
              <img src={returned} alt="" />
            )}
            {formValues?.status === "PAID" &&
              formValues?.paymentStatus === true && <img src={paidRibbon} />}
          </div>
        </div>
        <hr className="global-hr" />
        {/* <h4 className="global-name">INV/2023/10/0001</h4> */}
        <h4 className="global-name">{formValues?.name}</h4>
        <div className="new-global-input-container">
          <div className="new-global-single-input auto-complete-new">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={customerList || []}
              getOptionLabel={(option) => option?.name}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  onChange={(e) => vendorListApi({ search: e.target.value })}
                  {...params}
                  label="Vendor*"
                  focused
                />
              )}
              value={formValues?.vendor}
              onChange={getFormInfo("vendor")}
              disabled={formValues?.isEdit}
            />
            <p className="doc-validation-alert">{validationAlert?.vendor}</p>
          </div>
          <div className="new-global-single-input">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={allBranchesList || []}
              getOptionLabel={(option) =>
                `${option?.storeCode}-${option?.branchName}`
              }
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Branch*" focused />
              )}
              value={formValues?.branch}
              onChange={getFormInfo("branch")}
              disabled={formValues?.isEdit}
            />
            <p className="doc-validation-alert">{validationAlert?.branch}</p>
          </div>

          <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="Bill Reference*"
              variant="outlined"
              type="text"
              focused
              value={formValues?.billReference}
              onChange={getFormInfo("billReference")}
              disabled={formValues?.isEdit}
            />
          </div>

          <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="Bill Date*"
              variant="outlined"
              type="date"
              focused
              value={formValues.billDate}
              onChange={getFormInfo("billDate")}
              disabled={formValues?.isEdit}
            />
            <p className="doc-validation-alert">{validationAlert?.billDate}</p>
          </div>

          <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="Payment Reference*"
              variant="outlined"
              type="text"
              focused
              value={formValues.paymentReference}
              onChange={getFormInfo("paymentReference")}
              disabled={formValues?.isEdit}
            />
          </div>
          {/* <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="Accounting Date*"
              variant="outlined"
              type="date"
              focused
              value={formValues?.accountingDate}
              onChange={getFormInfo("accountingDate")}
              disabled={formValues?.isEdit}
            />
            <p className="doc-validation-alert">
              {validationAlert?.accountingDate}
            </p>
          </div> */}

          {/* <div className='new-global-single-input'>
            <TextField id="outlined-basic" label="Contact Person*" variant="outlined" type="text" focused />
          </div> */}

          <div className="new-global-single-input">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={journalSalesList || []}
              getOptionLabel={(option) => option?.journalName}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Journal*" focused />
              )}
              value={formValues?.journal}
              onChange={getFormInfo("journal")}
              disabled={formValues?.isEdit}
            />
            <p className="doc-validation-alert">{validationAlert?.journal}</p>
          </div>

          <div className="new-global-single-input">
            {/* <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={[]}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Currency*" focused />
              )}
            /> */}
            {MultiCurrencyView !== undefined &&
            MultiCurrencyView?.isMultiCurrency ? (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={currencyListUpdated || []}
                getOptionLabel={(option) => option?.name}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Currency*" focused />
                )}
                value={formValues?.currency}
                onChange={getFormInfo("currency")}
                // disabled={formValues?.isEdit}
                disabled={formValues?.isEdit}
              />
            ) : (
              <TextField
                value={formValues?.currency?.name || ""}
                sx={{ caretColor: "transparent" }}
                className="disabled-input"
                id="outlined-basic"
                label="Currency*"
                variant="outlined"
                type="text"
                focused
                title="Multi Currency not enabled"
                // disabled={formValues?.isEdit}
                disabled={formValues?.isEdit}
              />
            )}
            <p className="doc-validation-alert">{validationAlert?.currency}</p>
          </div>
          <div
              className="new-global-single-input auto-complete-new"
              style={{ width: "22%" }}
            >
              <div className="" style={{ position: "relative" }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={paymentTermsList || []}
                  getOptionLabel={(option) => option?.paymentTerm}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Payment Terms*" focused />
                  )}
                  value={formValues?.paymentTerms || null}
                  onChange={getFormInfo("paymentTerms")}
                  disabled={formValues?.isEdit}
                />
                {!formValues.isEdit && (
                  <button
                    onClick={() => {
                      setFormValues((prev) => ({
                        ...prev,
                        paymentTerms: null,
                      }));
                      setHandlePaymentTermsbtn(!handlePaymentTermsbtn);
                    }}
                    className="add-row-btn autocomplete-inside-plusbtn"
                  >
                    +
                  </button>
                )}
              </div>
            </div>
            <div className="new-global-single-input">
              <TextField
                value={formValues?.lpoNo}
                onChange={getFormInfo("lpoNo")}
                id="outlined-basic"
                label="LPO No."
                variant="outlined"
                type="text"
                focused
                disabled={formValues?.isEdit}
              />
            </div>
        </div>
      </div>
      <div className="new-global-white-bg-container">
        <div
          className="rewards-add-rewards-ctn-top-menu accounts-table-toggle"
          style={{ marginBottom: "1%" }}
        >
          <div
            className="tab-menu-button active"
            id="productMenuBtn"
            onClick={productMenuClick}
          >
            <Typography fontSize={"0.9rem"} fontWeight={600}>
              Invoice Lines
            </Typography>
          </div>
          <div
            className="tab-menu-button"
            id="departmentMenuBtn"
            onClick={departmentMenuClick}
          >
            <Typography fontSize={"0.9rem"} fontWeight={600}>
              Other Info
            </Typography>
          </div>
        </div>
        {isActiveTable === "invoiceLines" ? (
          <>
            <div className="global-product-table">
              <table>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Label</th>
                    <th>Account</th>
                    <th>UOM</th>
                    <th>Qty</th>
                    <th>UnitPrice</th>
                    <th>Discount</th>
                    <th>Tax</th>
                    <th>Amount</th>
                  </tr>
                </thead>

                <tbody>
                  {tableData?.map((item, index) => (
                    <tr
                      key={index}
                      ref={index === tableData.length - 1 ? containerRef : null}
                    >
                      {item.type === "header" ? (
                        <>
                          <td colSpan="9">
                            <input
                              type="text"
                              //  value={item.value}
                              onChange={(e) =>
                                handleInputChange(index, "value")
                              }
                            />
                          </td>
                          <td style={{ border: "none", width: "4%" }}>
                            <IconButton
                              onClick={() => handleDelete(index)}
                              style={{ height: "30px" }}
                            >
                              <i
                                className="bi bi-trash3"
                                style={{ color: "#db0000", fontSize: "1.3rem" }}
                              ></i>
                            </IconButton>
                          </td>
                        </>
                      ) : (
                        <>
                          <td style={{ minWidth: "100px" }}>
                            <div className="product-table-auto-complete">
                              <Autocomplete
                                options={allNewProductList || []}
                                style={{ maxHeight: "100px" }}
                                getOptionLabel={(option)=>option?.productName}
                                renderOption={(props, option) => (
                                  <li {...props} key={option._id}>
                                    <span>{option.productName}</span>
                                  </li>
                                )}
                                filterOptions={filterOptions}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseEnter}
                                  />
                                )}
                                disabled={formValues?.isEdit}
                                value={item?.product}
                                onChange={handleInputChange(index, "product")}
                              />
                            </div>
                          </td>
                          <td style={{ minWidth: "100px" }}>
                            <input
                              type="text"
                              onChange={handleInputChange(index, "label")}
                              value={item?.label}
                              disabled={formValues?.isEdit}
                            />
                          </td>
                          <td style={{ minWidth: "100px" }}>
                            <div className="product-table-auto-complete">
                              <Autocomplete
                                options={controlAndExpenseAccList || []}
                                style={{ maxHeight: "100px" }}
                                getOptionLabel={(option) => option?.accountName}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                disabled={formValues?.isEdit}
                                value={item?.account}
                                onChange={handleInputChange(index, "account")}
                              />
                            </div>
                          </td>
                          <td style={{ minWidth: "100px" }}>
                            <div className="product-table-auto-complete">
                              <Autocomplete
                                    options={uomSubList?.filter((obj)=>obj?.parentCatId === (item?.product?.parentUomId)) || []}
                                // options={filteredUOMOptions[index] || []}
                                style={{ maxHeight: "100px" }}
                                getOptionLabel={(option) => option?.uomName}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                value={item?.uom}
                                disabled={formValues?.isEdit}
                                onChange={handleInputChange(index, "uom")}
                              />
                            </div>
                          </td>
                          <td style={{ maxWidth: "85px" }}>
                            <input
                              type="text"
                              style={{ textAlign: "right" }}
                              onChange={handleInputChange(index, "qty")}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseEnter}
                              disabled={formValues?.isEdit}
                              value={item?.qty}
                            />
                          </td>
                          <td style={{ maxWidth: "85px" }}>
                            <input
                              type="text"
                              style={{ textAlign: "right" }}
                              onChange={handleInputChange(index, "unitPrice")}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseEnter}
                              value={item?.unitPrice}
                              disabled={formValues?.isEdit}
                            />
                          </td>
                          <td td style={{ maxWidth: "85px" }}>
                            <input
                              type="text"
                              style={{ textAlign: "right" }}
                              onChange={handleInputChange(index, "discount")}
                              value={item?.discount}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseEnter}
                              disabled={formValues?.isEdit}
                            />
                          </td>
                          <td style={{ width: "216px" }}>
                            <div className="product-table-auto-complete">
                              <Autocomplete
                                multiple
                                options={filteredTaxOptions[index] || []}
                                // style={{ maxHeight: "100px" }}
                                getOptionLabel={(option) => option?.taxName}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                value={item?.tax}
                                disabled={formValues?.isEdit}
                                onChange={handleInputChange(index, "tax")}
                              />
                            </div>
                          </td>
                          <td style={{ maxWidth: "90px" }}>
                            <input
                              type="text"
                              style={{ textAlign: "right" }}
                              readOnly
                              // onChange={handleInputChange(index, "amount")}
                              value={item?.amount}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseEnter}
                              disabled={formValues?.isEdit}
                            />
                          </td>
                          <td style={{ border: "none", width: "4%" }}>
                            <IconButton
                              onClick={() => handleDelete(index)}
                              style={{ height: "30px" }}
                              disabled={formValues?.isEdit}
                            >
                              <i
                                className="bi bi-trash3"
                                style={{ color: "#db0000", fontSize: "1.3rem" }}
                              ></i>
                            </IconButton>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="add-row-btn-container">
              <div
                className="global-single-input auto-complete"
                style={{ width: "76%" }}
              >
                <Autocomplete
                  options={addRowData || []}
                  getOptionLabel={(option) => option?.value}
                  renderInput={(params) => <TextField {...params} />}
                  value={addRowInput}
                  disabled={formValues?.isEdit}
                  onChange={(e, newValue) => setAddRowInput(newValue)}
                />
              </div>
              <button
                onClick={() => addRow()}
                className="add-row-btn"
                disabled={formValues?.isEdit}
              >
                +
              </button>
            </div>
          </>
        ) : (
          <div
            className="new-global-input-container"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {/* <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="PayTerms*"
                variant="outlined"
                type="text"
                focused
              />
            </div> */}
            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="Expire Date*"
                variant="outlined"
                type="date"
                focused
                value={formValues.expireDate}
                disabled={formValues?.isEdit}
                onChange={getFormInfo("expireDate")}
              />
            </div>

            <button
              className="btn btn-primary"
              style={{ backgroundColor: "#4e4444" }}
              onClick={() =>
                navigateTo(
                  "/userdashboard/accounts/accounting/journalEntriesCreate"
                )
              }
            >
              Journal Entry
            </button>

            {/* <div className="new-global-single-input" style={{ width: "35%" }}>
              <p>Delivery Terms*</p>
              <textarea name="" id="" cols="50" rows="7"></textarea>
            </div> */}
          </div>
        )}
      </div>

      {isActiveTable === "invoiceLines" && (
        <div
          className="new-global-white-bg-container"
          style={{
            display: "flex",
            flexWrap: "wrap",
            padding: "8px 2%",
          }}
        >
          <div className="new-global-single-input" style={{ width: "35%" }}>
            <p>Customer Notes</p>
            <textarea
              name=""
              id=""
              cols="50"
              rows="5"
              value={formValues?.customernotes}
              disabled={formValues?.isEdit}
              onChange={getFormInfo("customernotes")}
            ></textarea>
          </div>

          <div className="new-global-single-input" style={{ width: "35%" }}>
            <p>Terms & Conditions</p>
            <textarea
              name=""
              id=""
              cols="50"
              rows="5"
              value={formValues?.termsandconditions}
              disabled={formValues?.isEdit}
              onChange={getFormInfo("termsandconditions")}
            ></textarea>
          </div>

          <div
            className="new-global-single-input"
            style={{ maxWidth: "250px" }}
          >
            <div className="subtotal-ctn">
              <div className="subtotal-ctn-left">
                <p>Sub Total</p>
                {totalsInfo?.inclusiveTax !== 0 && <p>Inclusive Tax</p>}

                {totalsInfo?.exclusiveTax !== 0 && <p>Exclusive Tax</p>}
                {totalsInfo?.exclusiveTax === 0 &&
                  totalsInfo?.inclusiveTax === 0 && <p>Tax</p>}
              </div>
              <div className="subtotal-ctn-right">
                <p>{totalsInfo?.subTotal?.toFixed(decimalPosition)}</p>
                {totalsInfo?.inclusiveTax !== 0 && (
                  <p>{totalsInfo?.inclusiveTax?.toFixed(decimalPosition)}</p>
                )}
                {totalsInfo?.exclusiveTax !== 0 && (
                  <p>{totalsInfo?.exclusiveTax?.toFixed(decimalPosition)}</p>
                )}
                {totalsInfo?.exclusiveTax === 0 &&
                  totalsInfo?.inclusiveTax === 0 && <p>0.00</p>}
              </div>
            </div>
            <hr
              className="global-hr"
              style={{ width: "80%", marginLeft: "25%" }}
            />
            <div className="subtotal-ctn">
              <div className="subtotal-ctn-left">
                <p style={{ color: "#000", fontWeight: "600" }}>Total</p>
              </div>
              <div className="subtotal-ctn-right">
                <p>{totalsInfo?.total?.toFixed(decimalPosition)} </p>
              </div>
            </div>

            <div className="balanceAmountdiv">
              {formValues?.paymentsDone &&
                formValues?.paymentsDone.map((res, index) => (
                  <div className="paydiv" style={{ display: "flex" }}>
                    <p>{res?.name}</p>
                    <p>
                      {formValues?.currencyDetails?.symbol}
                      {res?.totalAmount}
                    </p>
                  </div>
                ))}

              {formValues?.status === "COMPLETED" &&
                !formValues?.paymentStatus && (
                  <div className="paydiv">
                    <p>AmountDue</p>
                    <p> {formValues?.currencyDetails?.symbol}</p>
                    <p>{formValues?.amountDue}</p>
                  </div>
                )}
            </div>
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", width: "60%" }}
          >
            <div className="file-picker-new">
              <input type="file" id="fileUpload" onChange={changeImage} />
              <label htmlFor="fileUpload">
                <img src={uploadFile} alt="" />
                Upload File
              </label>
            </div>
            <div style={{ display: "flex", margin: "0 0 0 22px" }}>
              {imageDisplay?.map((r, i) => (
                <div className="uploaded-image-list">
                  <img src={r} alt="" />
                  <div className="image-remove-icon-new">
                    <IconButton onClick={() => removeImageFn(i)}>
                      <CloseOutlined
                        sx={{ color: "#fd0301", fontSize: "0.9rem" }}
                      />
                    </IconButton>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <div
        className="new-global-white-bg-container new-log-container"
        style={{ padding: "4px" }}
      >
        <div
          style={{ padding: "8px", cursor: "pointer" }}
          onClick={clickLogView}
        ></div>
        <div
          className={logView ? "pointer-up" : "pointer"}
          onClick={clickLogView}
          style={{ cursor: "pointer" }}
        ></div>
        {logView && (
          <div className="single-log-container">
            {log?.map((r, i) => (
              <div className="single-log-item">
                <div className="single-log-item-line-image">
                  <hr className="single-log-item-line" />
                  <div className="single-log-item-image">
                    <img src={logoImage} alt="" />
                  </div>
                  <hr className="single-log-item-line" />
                </div>
                <p className="heading">{r?.status}</p>
                <p className="name">{r?.empId}</p>
                <p className="date-time">{r?.date}</p>
              </div>
            ))}
          </div>
        )}

        {/* <div className='single-log-container-scroll-line'></div> */}
      </div>
      <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
 <Dialog
        open={alertPopup}
        onClose={() => {
          alertPopup();
        }}
        maxWidth="lg"
      >
        <div style={{ textAlign: "center", paddingTop: "24px" }}>
          {" "}
          <ReportProblemOutlinedIcon
            sx={{ color: " #d50808", fontSize: "xx-large" }}
          />
        </div>
        <div
          className="shift-end-button-password-container"
          style={{ width: "25vw", padding: "2% 4%" }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              margin: "2% 0",
              alignSelf: "center",
              paddingBottom: "14px",
            }}
          >
            Are you sure you want to return
          </div>
          <hr
            style={{
              backgroundColor: "#bfbfc3",
              width: "100%",
              height: "2px",
              marginBottom: "27px",
            }}
          ></hr>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              style={{ height: "28px", width: "28%" }}
              className="btn btn-primary"
              onClick={SaveBtnFun("return")}
            >
              Yes
            </button>
            <button
              onClick={() => setAlertPopup(false)}
              style={{ height: "28px", width: "28%" }}
              className="btn btn-secondary-outlined"
            >
              No
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog 
          maxWidth="md" open={registerPayment} onClose={() => setRegisterPayment(false)}>
        <div className="payment-popup">
          <h4>Register Payment</h4>
          <hr className="global-hr" />

          <div  className="global-input-field-container">
            <div
              className="new-global-single-input"
              style={{ width: "47%", padding: "6px" }}
            >
              <TextField
                id="outlined-basic"
                label="Date*"
                variant="outlined"
                type="date"
                focused
                value={formValues?.registerPaymentDate}
                onChange={getFormInfo("registerPaymentDate")}
              />
              <p className="doc-validation-alert">
                {validationAlert?.registerPaymentDate}
              </p>
            </div>
            <div
              className="new-global-single-input auto-complete-new"
              style={{ width: "45%", padding: "6px" }}
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={paymentTypeList || []}
                getOptionLabel={(option) => option?.journalName}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Payment Type*" focused />
                )}
                value={formValues?.paymentType}
                onChange={getFormInfo("paymentType")}
              />
              <p className="doc-validation-alert">
                {validationAlert?.paymentType}
              </p>
            </div>
            <div
              className="new-global-single-input auto-complete-new"
              style={{ width: "47%", padding: "6px" }}
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={["Manual", "Cheque"]}
                getOptionLabel={(option) => option}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Type*" focused />
                )}
                value={formValues?.type}
                onChange={getFormInfo("type")}
              />
              <p className="doc-validation-alert">{validationAlert?.type}</p>
            </div>
            <div
              className="new-global-single-input"
              style={{ width: "45%", padding: "6px" }}
            >
              <TextField
                id="outlined-basic"
                label={
                  formValues?.type === "Cheque"
                    ? "Cheque No."
                    : "Transaction No"
                }
                variant="outlined"
                type="text"
                focused
                value={formValues?.chequeNo}
                onChange={getFormInfo("chequeNo")}
              />
              <p className="doc-validation-alert">
                {validationAlert?.chequeNo}
              </p>
            </div>
            {formValues?.type === "Cheque" && (
              <>
                <div
                  className="new-global-single-input"
                  style={{ width: "47%", padding: "6px" }}
                >
                  <TextField
                    id="outlined-basic"
                    label="Cheque Date*"
                    variant="outlined"
                    type="date"
                    focused
                    value={formValues?.registerPaymentChequeDate}
                    onChange={getFormInfo("registerPaymentChequeDate")}
                  />
                  <p className="doc-validation-alert">
                    {validationAlert?.registerPaymentChequeDate}
                  </p>
                </div>
              </>
            )}

            <div
              className="new-global-single-input"
              style={{ width: "47%", padding: "6px" }}
            >
              <TextField
                id="outlined-basic"
                label="Amount*"
                variant="outlined"
                type="number"
                focused
                value={formValues?.registerPaymentAmount}
                onChange={getFormInfo("registerPaymentAmount")}
              />
              <p className="doc-validation-alert">
                {validationAlert?.registerPaymentAmount}
              </p>
            </div>
          </div>
          <hr className="global-hr" />
          <div>
            <button
              className="btn btn-primary"
              onClick={SaveBtnFun("registerPaymentPost")}
            >
              Post
            </button>
            <button
              className="btn btn-secondary-outlined"
              onClick={registerPymentCancelBtn}
            >
              Cancel
            </button>
          </div>
        </div>
      </Dialog>
      <PaymentTerms handleDialog={handlePaymentTermsbtn} setHandleDialog={setHandlePaymentTermsbtn} />
      <LoadingForm loading={loadingForm} />
    </div>
  );
};
