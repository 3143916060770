import { createSlice } from "@reduxjs/toolkit";

export const productIdSlice=createSlice({
    name:'productIdSlice',
    initialState:{
        value:'####'
    },
    reducers:{
        update_productID:(state,action)=>{
            state.value=action.payload.productID
        }
    }
})

export const {update_productID}=productIdSlice.actions
export default productIdSlice.reducer