import {createSlice} from '@reduxjs/toolkit'

export const viewPurchaseReturnListSlice=createSlice({
    name:'viewPurchaseReturnList',
    initialState:{
        value:undefined
    },
    reducers:{
        get_purchase_return_list:(state,action)=>{
            state.value=action.payload.purchaseReturnList
        }
    }
})
export const {get_purchase_return_list} = viewPurchaseReturnListSlice.actions;
export default viewPurchaseReturnListSlice.reducer;