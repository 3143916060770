import { createSlice } from "@reduxjs/toolkit";

export const patternSlice=createSlice({
    name:'patternSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_pattern_list:(state,action)=>{
            state.value=action.payload.pattern
        }
    }
})

export const {update_pattern_list}=patternSlice.actions
export default patternSlice.reducer