import React, { useState, useRef } from "react";
import "../../../../css/Orders/alteration.css";
import {
  Menu,
  Checkbox,
  IconButton,
  Dialog,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  Skeleton,
  Tooltip,
  createFilterOptions,
} from "@mui/material";
import { PaymentsWO } from "../WorkOrder/PaymentsWO";
import { useEffect } from "react";
import { viewAllCustomersAPICall } from "../../../../API/Customer/customerAPI";
import { useSelector } from "react-redux";
import { convertDateFormat, previousDate, today } from "../../../../Js/Date";
import models from "../../../../Assets/JSON/models.json";
import { viewAllMaterialProductsAPICall } from "../../../../API/Product List/productListAPI";
import {
  alterationPayBalanceAPICall,
  generateAlterationIdAPICall,
  singleAlterationAPICall,
} from "../../../../API/Orders/AlterationAPI";
import { getAllActiveEmployeesAPICall } from "../../../../API/Staff/HRM/employeeAPI";
import { getWorkOrderNosAPICall } from "../../../../API/Orders/workOrderAPI";
import { viewAllMaterialProductsWithStockAPICall } from "../../../../API/Product List/productListAPI";
import {
  addAlterationAPICall,
  getAllAlterationsAPICall,
  getAllAlterationAPICall,
  editAlterationAPICall,
} from "../../../../API/Orders/AlterationAPI";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { KeyboardArrowDown } from "@mui/icons-material";
import { AddCustomer } from "../../Customer/AddCustomer";
import ReactToPrint from "react-to-print";
import { viewAllBranchesAPICall } from "../../../../API/Settings/Company Settings/companySettingsAPI";
import { extractSingleObjectGeneral } from "../../../../Js/generalFunctions";
import { LoadingForm } from "../../../Single Components/LoadingForm";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export const Alteration = () => {
  let alterationReportPrint = useRef();
  let alterationReportSummary = useRef();
  let alterationAdminReportPrint = useRef();
  let alterationAdminReportSummary = useRef();

  //Permission State from LS
  const loginMeta=JSON.parse(localStorage.getItem('login_meta'))
  const {permission}=loginMeta
  const {altEdit}=permission?.pointOfSale?.orders || {altEdit:false}
  const userBranchId=loginMeta?.branchPk;
  const branchId=localStorage.getItem('branchId')

  //*Redux States
  // login response
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  // role based rendering
  const userRole = useSelector((state) => state.userRoleSlice.value);
  // list of all alteration
  const alterationList = useSelector((state) => state.allAlterationSlice.value);
  //List of All Customers
  const customerList = useSelector((state) => state.allCustomerListSlice.value);

  //Generated Transaction id
  const addAlterationId = useSelector((state) => state.alterationIdSlice.value);
  //Active Employee List
  const allActiveEmpList = useSelector(
    (state) => state.allActiveEmployeeSlice.value
  );
  //List of All Work order numbers
  const allWorkOrderNosList = useSelector(
    (state) => state.workOrderNosSlice.value
  );
  //List of All Material products
  const materialProductList = useSelector(
    (state) => state.productsWithStockSlice.value
  );
  // alteration single view
  const alterationSingleView = useSelector(
    (state) => state.singleAlterationSlice.value
  );
  // all branch list
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  // all alteration id
  const allAlterationIdList = useSelector(
    (state) => state.allAlterationId.value
  );

  const [isModify, setIsModify] = useState(false);
  const [addAlteration, setAddAlteration] = useState(false);
  const [editAlteration, setEditAlteration] = useState(false);
  const [editedId, setEditedId] = useState();
  const [alterationRadioType, setAlterationRadioType] = useState("paid");
  const [payments, setPayments] = useState(false);
  const [paymentsEdit, setPaymentsEdit] = useState(false);
  const [viewAlteration, setViewAlteration] = useState(false);
   //Multiple Payment or not
   const [isMultiplePayment, setIsMultiplePayment] = useState(false);

 
  const [loadingForm,setLoadingForm]=useState(false)
  // Add alteration forms and states
  const [customerName, setCustomerName] = useState(null);
  //Form 1

  const addAlterationForm1InitialState = {
    transactionId: "##",
    customer: null,
    date: today,
  };
  const [addAlterationForm1, setAddAlterationForm1] = useState(
    addAlterationForm1InitialState
  );
  
  //Edit Alteration Form States
  const editAlterationFormInitialState = {
    id: null,
    oldOrdNo: "",
    oldQty: "",
    oldRate: "",
    oldAmount: "",
    oldNotes: "",
    transId: "",
    customer: "",
    date: "",
    altType: "",
    materials: [],
    materialUsedQty: 0,
    materialUnitRate: 0,
    woId: "",
    item: "",
    noOfCloths: "",
    stitchingRate: "",
    cuttingMaster: [],
    tailor: [],
    tailorCommission: 0,
    amount: 0,
    remarks: "",
    altPayType: "",
    staff: "",
    reason: "",
  };
  const [editAlterationForm, setEditAlterationForm] = useState(
    editAlterationFormInitialState
  );



  //State for type of order
  const [orderType, setOrderType] = useState("readymade");
  //determine is it out cloth or not
  const [isOutClothChecked, setIsOutClothChecked] = useState(false);
  const [alterationMaterialCheck, setAlterationMaterialCheck] = useState(false);
  //Add Alteration form 2
  const addAlterationForm2InitialState = {
    item: "0",
    material: "",
    usedQty: "",
    noOfCloths: 0,
    cuttingMaster: [],
    remarks: "",
    tailor: [],
    tailorCommission: "",
    amountType: "",
    workOrderId: "",
    price: "",
    totalAmount: "",
    oldDelOrderNo: "",
    oldDelQty: 0,
    oldDelPrice: 0,
    oldDelAmount: "",
    oldDelNotes: "",
    stitchingRate: 0,
  };
  const [addAlterationForm2, setAddAlterationForm2] = useState(
    addAlterationForm2InitialState
  );
  // Add Alteration Form 3
  const addAlterationForm3InitialState = {
    staff: "0",
    reason: "",
  };
  const [addAlterationForm3, setAddAlterationForm3] = useState(
    addAlterationForm3InitialState
  );

  //Filtered List by designation
  // filtered by cutting master
  const [cutterList, setCutterList] = useState([]);
  // filtered by tailor
  const [tailorList, setTailorList] = useState([]);

  const [cuttingMasterIdList, setCuttingMasterIdList] = useState([]);
  const [tailorIdList, setTailorIdList] = useState([]);

  // view alteration state
  // item list
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [extractedAlteration, setExtractedAlteration] = useState([]);

  // tailor list
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open1 = Boolean(anchorEl1);

  const [viewCuttingMaster, setViewCuttingMaster] = useState("0");
  const [viewTailor, setViewTailor] = useState("0");
  const [isLoading, setIsLoading] = useState(false);

  // create customer state
  const [addCustomerDialog, setAddCustomerDialog] = useState(false);
  const [isDialog, setIsDialog] = useState(false);

  // alteration filter states
  const [branch, setBranch] = useState();
  const [transactionId, setTransactionId] = useState();
  const [customer, setCustomer] = useState();
  const [fromDate, setFromDate] = useState(previousDate);
  const [toDate, setToDate] = useState(today);

  // alteration summary checkbox state
  const [alterationSummary, setAlterationSummary] = useState(false);
  // extract user data from all list
  const [extractedData, setExtractedData] = useState([]);
  //Editing branch Code
  const [editingBranchCode, setEditingBranchCode] = useState(null);

  // pay balance state
  const [paymentDialog,setPaymentDialog]=useState(false)
  const [currentPayingObj,setCurrentPayingObj]=useState(null)

   // demo state for invoice 
  const [openInvoiceDialog,setOpenInvoiceDialog]=useState(false)

  

  const [successSnackOpen,setSuccessSnackOpen]=useState(false)
  const [errorSnackOpen,setErrorSnackOpen]=useState(false)
  const [snackMsg, setSnackMsg] = useState("oops!!");
  
  // validation states
  const [customerAlert,setCustomerAlert]=useState(false)
  const [workOrderAlert,setWorkOrderAlert]=useState(false)
  const [OrderNoAlert,setOrderNoAlert]=useState(false)
  const [materialAlert,setMaterialAlert]=useState(false)
  const [usedQtyAlert,setUsedQtyAlert]=useState(false)
  const [unitRateAlert,setUnitRateAlert]=useState(false)
  const [noOfClothsAlert,setNoOfClothAlert]=useState(false) 
  const [stitchingRateAlert,setStitchingRateAlert]=useState(false)
  const [oldDelQtyAlert,setOldDelQtyAlert]=useState(false)
  const [oldDelRateAlert,setOldDelRateAlert]=useState(false)
  

  let date = new Date();

  // console.log(customerList.length-1);
  // view alteration onChange function
  //item onChange function


  // demo fn for invoice 
  const clearBillingForm=()=>{

  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // tailor onChange function
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  // customer name initial value
  // useEffect(() => {
  //   if (customerList !== undefined) {
  //     setCustomerName(customerList[customerList?.length - 1]);
  //   }
  // }, [customerList, isModify]);

  // set selected customer id into state
  useEffect(() => {
    if (customerName !== null) {
      setAddAlterationForm1({
        ...addAlterationForm1,
        customer: customerName?._id,
      });
    }
  }, [customerName]);

  //update Listener
  const updateListener = () => {
    setIsModify(!isModify);
  };
  //Close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setSuccessSnackOpen(false);
  };
  //Close Error Snackbar
  const closeErrorSnackbar = () => {
    setErrorSnackOpen(false);
  };

  //*onChange Fns

  // open add alteration onchange function
  const openAddAlterationDialog = () => {
    setAddAlteration(true);
    setOrderType("readymade");
    clearState();
  };

  //Get alteration form 1 data
  const getAddAlterationForm1 = (key) => (e) => {
    const { value } = e.target;
    if (key === "customer") {
      setAddAlterationForm1({ ...addAlterationForm1, customer: value });
      console.log(value);
    }
    if (key === "date") {
      setAddAlterationForm1({ ...addAlterationForm1, date: value });
    }
  };
  //Get alteration form 2 data
  const getAddAlterationForm2 = (key) => (e) => {
    const { value } = e.target;
    if (key === "item") {
      setAddAlterationForm2({ ...addAlterationForm2, item: value });
    }
    if (key === "material") {
      setAddAlterationForm2({ ...addAlterationForm2, material: value });
    }
    if (key === "usedQty") {
      setAddAlterationForm2({ ...addAlterationForm2, usedQty: value });
      setUsedQtyAlert(false)
      if(value===""){
        setUsedQtyAlert(true)
      }
    }
    if (key === "noOfCloths") {
      setAddAlterationForm2({
        ...addAlterationForm2,
        noOfCloths: value,
      });
    }
    if (key === "stitchingRate") {
      setAddAlterationForm2({
        ...addAlterationForm2,
        stitchingRate:value,
      });
    }
    if (key === "cuttingMaster") {
      setAddAlterationForm2({ ...addAlterationForm2, cuttingMaster: value });
    }
    if (key === "remarks") {
      setAddAlterationForm2({ ...addAlterationForm2, remarks: value });
    }
    if (key === "tailor") {
      setAddAlterationForm2({ ...addAlterationForm2, tailor: value });
    }
    if (key === "tailorCommission") {
      setAddAlterationForm2({
        ...addAlterationForm2,
        tailorCommission: parseFloat(value),
      });
    }
    if (key === "amountType") {
      setAddAlterationForm2({ ...addAlterationForm2, amountType: value });
    }
    if (key === "price") {
      setAddAlterationForm2({ ...addAlterationForm2, price: value });
      setUnitRateAlert(false)
      if(value===""){
        setUnitRateAlert(true)
      }
    }
    if (key === "oldDelOrderNo") {
      setAddAlterationForm2({
        ...addAlterationForm2,
        oldDelOrderNo: value,
      });
      setOrderNoAlert(false)
      if(value===""){
        setOrderNoAlert(true)
      }
    }
    if (key === "oldDelQty") {
      setAddAlterationForm2({
        ...addAlterationForm2,
        oldDelQty:value,
      });
      setUsedQtyAlert(false)
      if(value===""){
        setUsedQtyAlert(true)
      }
    }
    if (key === "oldDelPrice") {
      setAddAlterationForm2({
        ...addAlterationForm2,
        oldDelPrice: value,
      });
      setUnitRateAlert(false)
      if(value===""){
        setUnitRateAlert(true)
      }
    }
    if (key === "oldDelAmount") {
      setAddAlterationForm2({
        ...addAlterationForm2,
        oldDelAmount: parseFloat(value),
      });
    }
    if (key === "oldDelNotes") {
      setAddAlterationForm2({ ...addAlterationForm2, oldDelNotes: value });
    }
  };
  //Get alteration form 3 data
  const getAddAlterationForm3 = (key) => (e) => {
    const { value } = e.target;
    switch (key) {
      case "staff":
        setAddAlterationForm3({ ...addAlterationForm3, staff: value });
        break;
      case "reason":
        setAddAlterationForm3({ ...addAlterationForm3, reason: value });
        break;

      default:
        break;
    }
  };
  //Get order type
  const getOrderType = (e) => {
    setOrderType(e.target.value);
    console.log(e.target.value);
  };
  //Get is outCloth
  const getIsOutCloth = (e) => {
    setIsOutClothChecked(e.target.checked);
  };

  // summary checkbox function
  const alterationSummaryCheckFn = () => {
    setAlterationSummary(!alterationSummary);
  };

  //*Fns
  const addAlterationSubmitBtn = () => {
    setAddAlteration(false);
  };
  const changeAlterationRadioType = (e) => {
    setAlterationRadioType(e.target.value);
  };
  const alterationMaterialCheckboxFn = (e) => {
    setAlterationMaterialCheck(!alterationMaterialCheck);
  };
  //  console.log(alterationMaterialCheck)

  const openPaymentDialog = () => {
    if (customerName === null) {
      setCustomerAlert(true)
    } 
   
    else if (
      orderType === "workorder" &&
      addAlterationForm2.workOrderId === ""
    ) {
      setWorkOrderAlert(true)
    } else if (
      orderType === "oldDelivery" &&
      addAlterationForm2.oldDelOrderNo === ""
    ) {
      setOrderNoAlert(true)
    } 
    else if(orderType === "oldDelivery" &&  (addAlterationForm2?.oldDelQty===""||Number(addAlterationForm2?.oldDelQty)===0)){
      setOldDelQtyAlert(true)
    }
    else if(orderType === "oldDelivery" && (addAlterationForm2?.oldDelPrice===""||Number(addAlterationForm2?.oldDelPrice)===0)){
      setOldDelRateAlert(true)
    }
    else if (alterationMaterialCheck === true) {
        if (
          addAlterationForm2.material === ""){
            setMaterialAlert(true)
           }
        else if(
          addAlterationForm2.usedQty === "" 
        ){
          setUsedQtyAlert(true)
        }
        else if(
          addAlterationForm2.price === ""
        ){
          setUnitRateAlert(true)
        }
        else {
        setPayments(true);
        setAddAlteration(false);
      }
    } 
    else if( orderType !== "oldDelivery" &&(Number( addAlterationForm2?.noOfCloths)===0||addAlterationForm2?.noOfCloths==="")){
      setNoOfClothAlert(true)
    }
    else if( orderType !== "oldDelivery" && (Number(addAlterationForm2?.stitchingRate)===0||addAlterationForm2?.stitchingRate==="")){
      setStitchingRateAlert(true)
    }
    else {
      setPayments(true);
      setAddAlteration(false);
    }
  };
  
  const openEditPaymentDialog = () => {
    if (editAlterationForm.customer?._id === null) {
        alert(" select customer.");
    } else if (alterationMaterialCheck === true) {
      if (
        editAlterationForm?.materials.length === 0 ||
        editAlterationForm?.materialUsedQty === 0 ||
        editAlterationForm?.materialUnitRate === 0
      ) {
        alert("Select material,usedQty,Unit Rate...");
      } else {
        setPaymentsEdit(true);
      
      }
    } else {
      setPaymentsEdit(true);
      setEditAlteration(false)
     
    }
  };
  // console.log(alterationMaterialCheck);
  const closePaymentDialog = () => {
    setPayments(false);
  };
  const closePaymentEditDialog = () => {
    setPaymentsEdit(false);
  };
  // console.log(payments);

  //*useEffects
  useEffect(() => {
    // viewAllCustomersAPICall({branchId:userBranchId});
    viewAllMaterialProductsAPICall({branchId});
    getAllActiveEmployeesAPICall();
    getWorkOrderNosAPICall({branchId});
    // viewAllMaterialProductsWithStockAPICall({ itemType: 2,branchId });
    viewAllBranchesAPICall();
  }, []);

  useEffect(() => {
    // getAllAlterationsAPICall({branchId}, setIsLoading);
    generateAlterationIdAPICall({branchId});
    getAllAlterationAPICall({ branchId: branch });
  }, [isModify, branch]);

  //Set alteration id to state
  useEffect(() => {
    if (addAlterationId !== undefined) {
      const { prefix, altNo } = addAlterationId;
      setAddAlterationForm1({
        ...addAlterationForm1,
        transactionId: `${prefix}${altNo}`,
      });
    }
  }, [addAlterationId]);

  //Filter Tailor by designation (TAILOR)
  const filterTailorByDesignation = (fullList) => {
    let _tailors = fullList.filter(
      (obj) => obj?.designationName?.toLowerCase() === "TAILOR".toLowerCase()
    );

    setTailorList(_tailors);
  };
  //Filter cuttingMaster by designation (CUTTING MASTER)
  const filterCutterByDesignation = (fullList) => {
    let _cutter = fullList.filter(
      (obj) =>
        obj?.designationName?.toLowerCase() === "CUTTING MASTER".toLowerCase()
    );

    setCutterList(_cutter);
  };
  useEffect(() => {
    if (allActiveEmpList !== undefined) {
      filterTailorByDesignation(allActiveEmpList);
      filterCutterByDesignation(allActiveEmpList);
    }
  }, [allActiveEmpList]);

  // console.log(addAlterationForm3.staff);

  useEffect(() => {
    setAddAlterationForm2({
      ...addAlterationForm2,
      totalAmount:
        parseFloat(addAlterationForm2.usedQty) *
        parseFloat(addAlterationForm2.price),
    });
  }, [addAlterationForm2.usedQty, addAlterationForm2.price]);

  // total amount
  useEffect(() => {
    setAddAlterationForm2({
      ...addAlterationForm2,
      amountType:
        (parseFloat(addAlterationForm2?.stitchingRate) +
          parseFloat(
            addAlterationForm2?.totalAmount
              ? addAlterationForm2?.totalAmount
              : 0
          )) *
        addAlterationForm2?.noOfCloths,
    });
  }, [
    addAlterationForm2?.stitchingRate,
    addAlterationForm2?.totalAmount,
    addAlterationForm2?.noOfCloths,
  ]);

  // extract selected cutting Master ids
  useEffect(() => {
    extractSelectedCutterIds(addAlterationForm2.cuttingMaster);
  }, [addAlterationForm2.cuttingMaster]);

  const extractSelectedCutterIds = (list) => {
    let extractedData = list?.map((obj) => obj?._id);
    setCuttingMasterIdList(extractedData);
  };

  // extract selected tailor ids
  useEffect(() => {
    extractSelectedTailorIds(addAlterationForm2.tailor);
  }, [addAlterationForm2.tailor]);

  const extractSelectedTailorIds = (list) => {
    let extractedData = list?.map((obj) => obj?._id);
    setTailorIdList(extractedData);
  };

  // console.log(addAlterationForm2);
  // console.log(allWorkOrderNosList);

  const finalBodyToApi = {
    cusId: addAlterationForm1.customer,
    altDate: `${
      addAlterationForm1.date
    } ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
    type: orderType,
    workorderNo:
      orderType === "workorder"
        ? addAlterationForm2.workOrderId
        : orderType === "oldDelivery"
        ? addAlterationForm2.oldDelOrderNo
        : null,
    oldDelivery:
      orderType === "oldDelivery"
        ? {
            qty: addAlterationForm2.oldDelQty,
            unitPrice:Number(addAlterationForm2.oldDelPrice),
            amount:Number(addAlterationForm2.oldDelAmount),
            notes: addAlterationForm2.oldDelNotes,
          }
        : null,
    items:
      orderType !== "oldDelivery" && alterationMaterialCheck === true
        ? [
            {
              itemId: addAlterationForm2.material,
              usedQty:Number(addAlterationForm2.usedQty),
              price:Number(addAlterationForm2.price),
              amount:Number(addAlterationForm2.totalAmount),
              offer: null,
              vatorgst:extractSingleObjectGeneral(materialProductList,'_id',addAlterationForm2.material)?.length!==0 && extractSingleObjectGeneral(materialProductList,'_id',addAlterationForm2.material) !==null &&
                      extractSingleObjectGeneral(materialProductList,'_id',addAlterationForm2.material)[0]?.vatorgst
            },
          ]
        : [],
    noCloths: parseFloat(addAlterationForm2.noOfCloths),
    cuttingMaster: cuttingMasterIdList,
    tailor: tailorIdList,
    remarks: addAlterationForm2.remarks,
    tailoringCommission: addAlterationForm2.tailorCommission,
    stitchingRate: addAlterationForm2?.stitchingRate,
    staff:
      alterationRadioType === "free"
        ? addAlterationForm3.staff === "0"
          ? null
          : addAlterationForm3.staff
        : null,
    reason: alterationRadioType === "free" ? addAlterationForm3.reason : null,
    total:
      orderType == "oldDelivery"
        ? addAlterationForm2.oldDelAmount
        : addAlterationForm2.amountType,
    branchId:branchId,
    isFree:alterationRadioType==="free"
  };
  // console.log(customerName);

  // add alteration final submit button function
  const addAlterationSubmitBtnFn = () => {
    if (customerName === null) {
      setCustomerAlert(true)
    } else if (
      orderType === "workorder" &&
      addAlterationForm2.workOrderId === ""
    ) {
      setWorkOrderAlert(true)
    } else if (
      orderType === "oldDelivery" &&
      addAlterationForm2.oldDelOrderNo === ""
    ) {
      setOrderNoAlert(true)
    }
    else {
      addAlterationAPICall(
        finalBodyToApi,
        setLoadingForm,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        clearState,
        // openInvoiceDialog,
        // clearBillingForm,
        setAddAlteration
      );
      console.log(finalBodyToApi);
    }
  };

  // clear state
  const clearState = () => {
    // setAddAlterationForm1(addAlterationForm1InitialState);
    setAddAlterationForm2(addAlterationForm2InitialState);
    setAddAlterationForm3(addAlterationForm3InitialState);
  };

  //alteration single view
  const openSingleViewDialog = (id) => {
    setViewAlteration(true);
    singleAlterationAPICall({ id: id });
  };

  // create customer  dialog close function
  const closeCreateCustomerDialog = () => {
    setAddCustomerDialog(false);
    setIsDialog(false);
  };

  // old delivery amount calculation
  useEffect(() => {
    setAddAlterationForm2({
      ...addAlterationForm2,
      oldDelAmount:
        parseFloat(addAlterationForm2?.oldDelQty) *
        parseFloat(addAlterationForm2?.oldDelPrice),
    });
  }, [addAlterationForm2?.oldDelQty, addAlterationForm2?.oldDelPrice]);

  // top container submit button function
  const AlterationSubmitBtnFn = () => {
    getAllAlterationsAPICall(
      {
        branchId: branch,
        transNo: transactionId,
        cusId: customer,
        fromDate:
          fromDate === null || fromDate === undefined || fromDate === ""
            ? null
            : `${fromDate} 00:00:00`,
        endDate:
          toDate === null || toDate === undefined || fromDate === ""
            ? null
            : `${toDate} 23:59:59`,
      },
      setIsLoading
    );
  };

  // extract user data from all list
  useEffect(() => {
    let extractData = alterationList?.retList?.filter((obj) => {
      if (obj.branchId === loginResponse.branchId) {
        return obj;
      }
    });
    setExtractedData(extractData);
  }, [alterationList]);
  // console.log(extractedData[0].list);

  //Get Alteration edit form values
  const getAlterationEditFormValues = (key) => (e, newValue) => {
    const { value } = e.target;

    switch (key) {
      case "customer":
        setEditAlterationForm({ ...editAlterationForm, customer: newValue });
        break;
      case "date":
        setEditAlterationForm({ ...editAlterationForm, date: value });
        break;
      case "oldOrdNo":
        setEditAlterationForm({ ...editAlterationForm, oldOrdNo: value });
        break;
      case "oldQty":
        setEditAlterationForm({ ...editAlterationForm, oldQty: value });
        break;
      case "oldRate":
        setEditAlterationForm({ ...editAlterationForm, oldRate: value });
        break;
      case "oldAmount":
        setEditAlterationForm({ ...editAlterationForm, oldAmount: value });
        break;
      case "Amount":
        setEditAlterationForm({ ...editAlterationForm, amount: value });
        break;
      case "notes":
        setEditAlterationForm({ ...editAlterationForm, oldNotes: value });
        break;
      case "woId":
        setEditAlterationForm({ ...editAlterationForm, woId: newValue });
        break;
      case "noOfCloth":
        setEditAlterationForm({ ...editAlterationForm, noOfCloths: value });
        break;
      case "stitchingRate":
        setEditAlterationForm({ ...editAlterationForm, stitchingRate: value });
        break;
      case "materials":
        setEditAlterationForm({ ...editAlterationForm, materials: newValue });
        break;
      case "usedQty":
        setEditAlterationForm({
          ...editAlterationForm,
          materialUsedQty: value,
        });
        break;
      case "unitRate":
        setEditAlterationForm({
          ...editAlterationForm,
          materialUnitRate: value,
        });
        break;
      case "cuttingMaster":
        setEditAlterationForm({
          ...editAlterationForm,
          cuttingMaster: newValue,
        });
        break;
      case "tailor":
        setEditAlterationForm({ ...editAlterationForm, tailor: newValue });
        break;
      case "tailorCommission":
        setEditAlterationForm({
          ...editAlterationForm,
          tailorCommission: value,
        });
        break;
      case "amount":
        setEditAlterationForm({ ...editAlterationForm, amount: value });
        break;
      case "remarks":
        setEditAlterationForm({ ...editAlterationForm, remarks: value });
        break;
      case "staff":
        setEditAlterationForm({ ...editAlterationForm, staff: newValue });
        break;
      case "reason":
        setEditAlterationForm({ ...editAlterationForm, reason: value });
        break;
      default:
        break;
    }
  };

  //Assign Edit alteration form with data
  const assignEditAlterationForm = (editingAltObj) => {
    if (editingAltObj !== undefined) {
      const {
        _id,
        altNo,
        date,
        cusId,
        tailoringCommission,
        type,
        CUTTINGMASTER,
        TAILOR,
        items,
        amount,
        noCloths,
        payment,
        stichingRate,
        paidAmount,
        discount,
        balance,
        oldDelivery,
        staff,
        reason,
      } = editingAltObj;

      let materialItem = null,
        materialUsedQty = 0,
        materialUnitPrice = 0;
      let oldAmount = 0,
        oldNotes = "",
        oldOrdNo = "",
        oldQty = 0,
        oldRate = 0;
      //Check Material selected or not
      if (items?.length > 0) {
        const [firstItem] = items;
        const { itemId, usedQty, price } = firstItem;
        console.log(firstItem);
        setAlterationMaterialCheck(true);
        materialItem = extractSingleObjectGeneral(
          materialProductList,
          "_id",
          itemId
        );
        materialUsedQty = usedQty;
        materialUnitPrice = price;
      } else setAlterationMaterialCheck(false);

      if (oldDelivery !== null) {
        const { qty, unitPrice, amount, notes, workorderNo } = oldDelivery;
        oldAmount = amount;
        oldNotes = notes;
        oldOrdNo = workorderNo;
        oldQty = qty;
        oldRate = unitPrice;
      }

      //Extract Single Customer Object
      let singleCusObj = extractSingleObjectGeneral(customerList, "_id", cusId);
      //Extract Single Staff
      let singleStaff = extractSingleObjectGeneral(
        allActiveEmpList,
        "_id",
        staff
      );
      //Extract cutting masters list
      let cuttingMasters = [];
      CUTTINGMASTER?.forEach((master) => {
        const singleMaster = extractSingleObjectGeneral(
          cutterList,
          "_id",
          master
        );
        const [firstItem] = singleMaster;
        if (firstItem !== undefined) cuttingMasters.push(firstItem);
      });
      //Extract tailors List
      let tailors = [];
      TAILOR?.forEach((tailor) => {
        const singleTailor = extractSingleObjectGeneral(
          tailorList,
          "_id",
          tailor
        );
        const [firstItem] = singleTailor;
        if (firstItem !== undefined) tailors.push(firstItem);
      });

      if (payment?.length > 0) setAlterationRadioType("paid");
      else setAlterationRadioType("free");
      console.log(materialItem);

      //Setting Order type [readymade,workorder,oldDelivery]
      setOrderType(type);

      //Assign values to edit alteration form
      setEditAlterationForm({
        ...editAlterationForm,
        id: _id,
        transId: altNo,
        customer: singleCusObj !== null ? singleCusObj[0] : null,
        date,
        cuttingMaster: cuttingMasters,
        tailor: tailors,
        altType: type,
        noOfCloths: noCloths,
        stitchingRate: stichingRate,
        tailorCommission: tailoringCommission,
        amount,
        materials: materialItem,
        materialUsedQty,
        materialUnitRate: materialUnitPrice,
        oldAmount,
        oldNotes,
        oldOrdNo,
        oldQty,
        oldRate,
        staff: singleStaff !== null ? singleStaff[0] : null,
        reason,
      });
    }
  };
  // console.log(editAlterationForm);
  //Open Edit Alteration Dialog
  const openEditAlterationDialog = (editingAltObj, branchCode) => {
    viewAllMaterialProductsWithStockAPICall({
      branchId: branchCode,
      itemType: 2,
    });
    setEditingBranchCode(branchCode);
    setEditAlteration(true);
    assignEditAlterationForm(editingAltObj, branchCode);
    console.log(editingAltObj);
  };

  

  //* Edit alteration Final Body  //TODO
  const editAlterationFinalBody = {
    alterationId: editAlterationForm.id,
    branchId: editingBranchCode,
    date: editAlterationForm.date,
    customerId: editAlterationForm.customer?._id,
    items:
      orderType !== "oldDelivery" && alterationMaterialCheck === true
        ? [
            {
              itemId: editAlterationForm.materials!==null && editAlterationForm.materials[0]?._id,
              usedQty: editAlterationForm.materialUsedQty,
              price: editAlterationForm.materialUnitRate,
              amount: editAlterationForm.amount,
              offer: null,
            },
          ]
        : [],
    oldDelivery:
      orderType === "oldDelivery"
        ? {
            qty: editAlterationForm.oldQty,
            unitPrice: editAlterationForm.oldRate,
            amount: editAlterationForm.oldAmount,
            notes: editAlterationForm.oldNotes,
          }
        : null,
    noCloths: editAlterationForm.noOfCloths,
    cuttingMaster: editAlterationForm.cuttingMaster?.map((obj) => obj?._id),
    tailor: editAlterationForm.tailor?.map((obj) => obj?._id),
    remarks: editAlterationForm.remarks,
    tailoringCommission: Number(editAlterationForm.tailorCommission),
    stitchingRate: Number(editAlterationForm.stitchingRate),
    total:
      orderType == "oldDelivery"
        ? Number(editAlterationForm.oldAmount)
        : Number(editAlterationForm.amount),
    branchId
  };
  

  //Edit Alteration without payment submit
  const editAlterationSubmitWoPayment = () => {
    editAlterationAPICall(
      editAlterationFinalBody,
      setIsLoading,
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      updateListener,
      clearState
    );
  };

  //Clear Alteration Edit Form
  const clearAlterationEditForm=()=>{
    setEditAlterationForm(editAlterationFormInitialState)
  }
  

  // pay balance functions
  const openBalancePaymentScreen=(obj)=>{
      setCurrentPayingObj(obj)
      setPaymentDialog(true)
   }  

  const closeBalancePaymentScreen=()=>{
    setCurrentPayingObj(null)
     setPaymentDialog(false)
  }

   //Clear View orders state
   const clearViewOrderUser = () => {
    console.log("Cleared");
    updateListener();
  };

   //Filter by name and mob no in same input field
  //  const filterOptions = createFilterOptions({
  //   matchFrom: "any",
  //   stringify: (option) => option?.name + option.mobileNo,
  // });
  const filterOptions = (options, { inputValue }) => {
    const lowerCasedInput = inputValue.toLowerCase();
    return options.filter(option =>
      option.name.toLowerCase().includes(lowerCasedInput) ||
      option.mobileNo.includes(lowerCasedInput)
    );
  };

  
  return (
    <div className="global-page-parent-container">
      <p className="breadcrumb">Orders &gt; Alteration</p>
      <div
        className="daily-report-top-container"
        style={{ marginBottom: "1%" }}
      >
        <h3>Alteration</h3>
        <hr className="global-hr" />
        <div className="daily-report-top-input-container expense-top-main-container">
          {userRole === "admin" && (
            <div className="global-single-input auto-complete">
              <p>Branch</p>
              <Autocomplete
                sx={{ width: "100%" }}
                options={allBranchesList || [""]}
                getOptionLabel={(option) =>
                  `${option?.storeCode}-${option?.branchName}`
                }
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Branch" />
                )}
                onChange={(e, newValue) => setBranch(newValue?.storeCode)}
              />
            </div>
          )}
          <div className="global-single-input auto-complete">
            <p>Transaction ID</p>
            <Autocomplete
              sx={{ width: "100%" }}
              options={allAlterationIdList || [""]}
              getOptionLabel={(option) => option?.altNo}
              renderInput={(params) => (
                <TextField {...params} placeholder="select Transaction Id..." />
              )}
              onChange={(e, newValue) =>
                setTransactionId(newValue?.alteratinNo)
              }
            />
          </div>
          <div className="global-single-input auto-complete">
            <p>Customer</p>
            <Autocomplete
              sx={{ width: "100%" }}
              options={customerList || []}
              getOptionLabel={(option) => option?.name}
              renderInput={(params) => (
                <TextField {...params} placeholder="select Customer..." />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option._id}>
                  <span>{option.name}</span>
                </li>
              )}
              onChange={(e, newValue) => setCustomer(newValue?._id)}
              filterOptions={filterOptions}
            />
          </div>
          <div className="global-single-input">
            <p>From</p>
            <input type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
          </div>
          <div className="global-single-input">
            <p>To</p>
            <input type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
          </div>
        </div>
        <div className="expense-report-button-container">
          <button
            onClick={AlterationSubmitBtnFn}
            className="btn btn-primary report-submit-btn"
          >
            Submit
          </button>
        </div>
      </div>

     
      <div className="alteration-container">                
        <div className="alteration-top-container">
          <h3>Alteration</h3>
          <div className="alteration-top-right-container">
            {userRole === "user" ? (
              <div className="alteration-top-right-print-container">
                <ReactToPrint
                  trigger={() => (
                    <IconButton className="print-icon-container alteration-print">
                      <i class="bi bi-printer printer-icon"></i>
                    </IconButton>
                  )}
                  content={() =>
                    alterationSummary === true
                      ? alterationReportSummary
                      : alterationReportPrint
                  }
                />

                <div className="alteration-print-checkbox-container">
                  <input
                    type="checkbox"
                    id="alterationPrint"
                    checked={alterationSummary}
                    onClick={alterationSummaryCheckFn}
                  />
                  <label htmlFor="alterationPrint">Summary</label>
                </div>
              </div>
            ) : userRole === "admin" ? (
              <div className="alteration-top-right-print-container">
                <ReactToPrint
                  trigger={() => (
                    <IconButton className="print-icon-container alteration-print">
                      <i class="bi bi-printer printer-icon"></i>
                    </IconButton>
                  )}
                  content={() =>
                    alterationSummary === true
                      ? alterationAdminReportSummary
                      : alterationAdminReportPrint
                  }
                />

                <div className="alteration-print-checkbox-container">
                  <input
                    type="checkbox"
                    id="alterationPrint"
                    checked={alterationSummary}
                    onClick={alterationSummaryCheckFn}
                  />
                  <label htmlFor="alterationPrint">Summary</label>
                </div>
              </div>
            ) : undefined}
            {userRole === "user" && (
              <button
                onClick={openAddAlterationDialog}
                className="btn btn-primary"
              >
                Add Alteration
              </button>
            )}
          </div>
        </div>

        {userRole === "user" ? (
          <div className="global-table-container">

            <table className="global-table add-alteration-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Date</th>
                  <th>Transaction ID</th>
                  <th>Customer</th>
                  <th>Mobile</th>
                  <th>Type</th>
                  <th>Item</th>
                  {/* <th>Cutting Master</th> */}
                  {/* <th>Tailor</th> */}
                  <th>Amount</th>
                  <th>Paid</th>
                  <th>Discount</th>
                  <th>Balance</th>
                  <th>View</th>
                  {altEdit && userRole === "user" && <th>Edit</th>}
                
                </tr>
              </thead>
              {isLoading ? (
                <tbody>

                  <tr>
                    <td colSpan={15}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={15}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={15}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                </tbody>

              ):
              
               ( <tbody>

                  {extractedData !== undefined &&
                  extractedData[0]?.list?.length !== 0 ? (
                    extractedData[0]?.list
                      ?.slice(0)
                      ?.reverse()
                      ?.map((r, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{convertDateFormat(r?.date)}</td>
                          <td>{r?.altNo}</td>
                          <td>{r?.customer}</td>
                          <td>{r?.mobileNo}</td>
                          <td>{r?.type}</td>

                          <td>
                            {r?.items?.length !== 0
                              ? r?.items?.map((k) => <span>{k?.itemName}</span>)
                              : "-NIL-"}
                          </td>
                          {/* <td>
                    <div className="add-document-documents-btn">
                      <button
                        onMouseDown={extractSingleAlterationObj(r._id)}
                        onClick={
                          r?.cuttingMaster.length !== 0
                            ? handleClick
                            : undefined
                        }
                      >
                        {r?.cuttingMaster.length !== 0 ? " View" : "-NIL-"}
                        <span style={{ marginTop: "4px" }}>
                          {r?.cuttingMaster.length !== 0 ? (
                            <KeyboardArrowDown />
                          ) : undefined}
                        </span>
                      </button>
                    </div>
                  </td>  */}

                          {/* <td>
                    <div className="add-document-documents-btn">
                      <button
                        onMouseDown={extractSingleAlterationObj(r._id)}
                        onClick={
                          r?.tailor.length !== 0 ? handleClick1 : undefined
                        }
                      >
                        {r?.tailor.length !== 0 ? " View " : "-NIL-"}
                        <span style={{ marginTop: "4px" }}>
                          {r?.tailor.length !== 0 ? (
                            <KeyboardArrowDown />
                          ) : undefined}
                        </span>
                      </button>
                    </div>
                  </td> */}
                          <td>{r?.amount}</td>
                          <td>{r?.paidAmount}</td>
                          <td>{r?.discount}</td>
                          <td>
                            {
                              r?.balance?.toFixed(2) > 0 ?(
                                <Tooltip
                                title="Pay balance"
                                placement="top-start"
                                arrow
                                >

                                <button 
                                  className="view-order-balance-pay-btn"
                                  onClick={()=>openBalancePaymentScreen(r)}
                                >{r?.balance?.toFixed(2)}</button>
                                </Tooltip>
                              )
                              :
                              (
                                r?.balance?.toFixed(2)
                              )
                            }
                           </td>
                           
                          <td>
                            <IconButton
                              onClick={() => openSingleViewDialog(r?._id)}
                            >
                              <i class="bi bi-eye visibility-icon view-order-visibility-icon"></i>
                            </IconButton>
                          </td>
                          {altEdit && userRole === "user" && (
                            <td>
                              <IconButton   onClick={() =>
                                  openEditAlterationDialog(r, r?.branchId)
                                } className="icon-btn">
                                <i class="bi bi-pencil-square edit-icon view-order-edit-icon"></i>
                              </IconButton>
                            </td>

                          )}
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan={15}>Search Data From Filter</td>
                    </tr>
                  )}

                </tbody>)}
              {/* )} */}
            </table>

          </div>
        ) : userRole === "admin" ? (
          <>
            {/* map start */}
            {alterationList?.retList?.map((r) => (
              <div>
                <div className="stock-report-admin-view-top-container">
                  <h4 style={{ width: "10%" }}>{r?.branchId}</h4>
                  <h4>{r?.branchName}</h4>
                </div>
                <div className="global-table-container">
            {
              isLoading?
              <table className="global-table add-alteration-table">
              <tbody>
                  <tr>
                    <td colSpan={15}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={15}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={15}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                </tbody>
            </table>:
                  <table className="global-table">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Date</th>
                        <th>Transaction ID</th>
                        <th>Customer</th>
                        <th>Mobile</th>
                        <th>Type</th>
                        <th>Item</th>
                        {/* <th>Cutting Master</th>
                <th>Tailor</th> */}
                        <th>Amount</th>
                        <th>Paid</th>
                        <th>Discount</th>
                        <th>Balance</th>
                        <th>View</th>
                        {userRole === "user" && <th>Edit</th>}
                        <th>Delete</th>
                      </tr>
                    </thead>
                    
                    <tbody>
                      {r?.list?.map((k, i) => (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{k?.date}</td>
                          <td>{k?.altNo}</td>
                          <td>{k?.customer}</td>
                          <td>{k?.mobileNo}</td>
                          <td>{k?.type}</td>

                          <td>
                            {k?.items?.length !== 0
                              ? k?.items?.map((m) => <span>{m?.itemName}</span>)
                              : "-NIL-"}
                          </td>
                          {/* <td>
                        <div className="add-document-documents-btn">
                          <button
                            onMouseDown={extractSingleAlterationObj(r._id)}
                            onClick={
                              k?.cuttingMaster.length !== 0
                                ? handleClick
                                : undefined
                            }
                          >
                            {k?.cuttingMaster.length !== 0 ? " View" : "-NIL-"}
                            <span style={{ marginTop: "4px" }}>
                              {k?.cuttingMaster.length !== 0 ? (
                                <KeyboardArrowDown />
                              ) : undefined}
                            </span>
                          </button>
                        </div>
                      </td> */}

                          {/* <td>
                        <div className="add-document-documents-btn">
                          <button
                            onMouseDown={extractSingleAlterationObj(r._id)}
                            onClick={
                              k?.tailor.length !== 0 ? handleClick1 : undefined
                            }
                          >
                            {k?.tailor.length !== 0 ? " View " : "-NIL-"}
                            <span style={{ marginTop: "4px" }}>
                              {k?.tailor.length !== 0 ? (
                                <KeyboardArrowDown />
                              ) : undefined}
                            </span>
                          </button>
                        </div>
                      </td> */}
                          <td>{k?.amount}</td>
                          <td>{k?.paidAmount}</td>
                          <td>{k?.discount}</td>
                          <td>{k?.balance}</td>
                          <td>
                            <IconButton
                              onClick={() => openSingleViewDialog(k?._id)}
                            >
                              <i class="bi bi-eye visibility-icon view-order-visibility-icon"></i>
                            </IconButton>
                          </td>
                          {userRole === "user" && (
                            <td>
                              <IconButton
                                // disabled
                                // onClick={() =>
                                //   openEditAlterationDialog(k, r?.branchId)
                                // }
                                className="icon-btn"
                              >
                                <i class="bi bi-pencil-square edit-icon view-order-edit-icon"></i>
                              </IconButton>
                            </td>
                           
                          )}
                           <td>
                            <IconButton> <i class="bi bi-trash3 delete-icon"></i></IconButton>
                           </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                }
                </div>
              </div>
            ))}
          </>
        ) : // map end
        undefined}
      </div>
{/* } */}
      {/* add alteration dialog */}
      <Dialog
        open={addAlteration}
        maxWidth="lg"
        sx={{ zIndex: 1210, marginTop: "2%" }}
        onKeyDown={(e)=>e.key === "Escape" && setAddAlteration(false)}
      >
        <div className="add-alteration-dialog-container">
          <h4>Add Alteration</h4>
          <div className="add-alteration-dialog-input-container">
            <div className="global-single-input add-alteration-input">
              <p>Transaction ID</p>
              <input value={addAlterationForm1?.transactionId} disabled />
            </div>
            <div className="global-single-input add-alteration-input auto-complete">
              <p>Customer</p>
              <Autocomplete
                sx={{ width: "100%" }}
                options={customerList || [""]}
                getOptionLabel={(option) => option?.name}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Customer" />
                )}
                // value={addAlterationForm1?.customer}
                // onChange={(e, newValue) =>
                //   setAddAlterationForm1({
                //     ...addAlterationForm1,
                //     customer: newValue?._id,
                //   })
                // }
                value={customerName}
                onChange={(e, newValue) => {
                    setCustomerName(newValue)
                    setCustomerAlert(false)
                    if(newValue===null){
                      setCustomerAlert(true)
                    }
                  }}
                filterOptions={filterOptions}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    <span>{option.name}</span>
                  </li>
                )}
              />
              <span
                onClick={() => {
                  setAddCustomerDialog(true);
                  setIsDialog(true);
                }}
                className="suffix-text-blue"
              >
                Create Customer
              </span>
              {customerAlert?
                <p className="doc-validation-alert">Select a customer !!</p>
                :""
              }
            </div>
            <div className="global-single-input add-alteration-input">
              <p>Date</p>
              <input
                type="date"
                value={addAlterationForm1?.date}
                onChange={getAddAlterationForm1("date")}
              />
            </div>
          </div>
          <hr className="global-hr" />
          <div className="add-alteration-dialog-checkbox">
            <div className="add-alteration-readyMade-workOrder-radio">
              <div>
                <input
                  defaultChecked
                  type="radio"
                  id="addAlterationReadymade"
                  name="addAlterationRadioGroup"
                  value="readymade"
                  onChange={getOrderType}
                />
                <label htmlFor="addAlterationReadymade">Readymade</label>
              
              </div>
              <div>
                <input
                  type="radio"
                  id="addAlterationWorkOrder"
                  name="addAlterationRadioGroup"
                  value="workorder"
                  onChange={getOrderType}
                />
                <label htmlFor="addAlterationWorkOrder">WorkOrder</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="addAlterationOldDelivery"
                  name="addAlterationRadioGroup"
                  value="oldDelivery"
                  onChange={getOrderType}
                />
                <label htmlFor="addAlterationOldDelivery">Old Delivery</label>
              </div>
            </div>
            {/* <div>
              <input type="checkbox" id="outCloth" />
              <label htmlFor="outCloth" onChange={getIsOutCloth}>Out Cloth</label>
            </div> */}
          </div>
          {/* material checkbox */}
          {orderType !== "oldDelivery" && (
            <div className="alteration-material-checkbox-main-container">
              <div className="alteration-material-checkbox">
                <input
                  type="checkbox"
                  id="addAlterationMaterial"
                  checked={alterationMaterialCheck}
                  onClick={alterationMaterialCheckboxFn}
                />
                <label htmlFor="addAlterationMaterial">Material</label>
              </div>
            </div>
          )}

          <div className="add-alteration-dialog-input-container">
            {orderType === "oldDelivery" ? (
              <>
                <div className="global-single-input add-alteration-input">
                  <p>Order No</p>
                  <input
                    placeholder="Enter Order No..."
                    type="text"
                    value={addAlterationForm2?.oldDelOrderNo}
                    onChange={getAddAlterationForm2("oldDelOrderNo")}
                  />
                  {OrderNoAlert?
                    <p className="doc-validation-alert">Enter a order No. !!</p>
                  :""}
                </div>
                <div className="global-single-input add-alteration-input">
                  <p>Qty</p>
                  <input
                    placeholder="Enter Qty..."
                    type="text"
                    value={
                    addAlterationForm2?.oldDelQty
                    }
                    onChange={getAddAlterationForm2("oldDelQty")}
                  />
                  {oldDelQtyAlert?
                    <p className="doc-validation-alert">Fill this Field !!</p>
                  :""}
                </div>
                <div className="global-single-input add-alteration-input">
                  <p>Rate</p>
                  <input
                    placeholder="Enter Rate..."
                    type="text"
                    value={
                     addAlterationForm2?.oldDelPrice
                    }
                    onChange={getAddAlterationForm2("oldDelPrice")}
                  />
                  {oldDelRateAlert?
                    <p className="doc-validation-alert">Fill this field !!</p>
                  :""}
                </div>
                <div className="global-single-input add-alteration-input">
                  <p>Amount</p>
                  <input
                    placeholder="Enter Amount..."
                    type="text"
                    value={
                      isNaN(addAlterationForm2?.oldDelAmount)
                        ? 0
                        : addAlterationForm2?.oldDelAmount
                    }
                    onChange={getAddAlterationForm2("oldDelAmount")}
                  />
                </div>
                <div className="global-single-input add-alteration-input">
                  <p>Notes</p>
                  <input
                    placeholder="Enter Notes..."
                    type="text"
                    value={addAlterationForm2?.oldDelNotes}
                    onChange={getAddAlterationForm2("oldDelNotes")}
                  />
                </div>
              </>
            ) : (
              <>
                {orderType === "workorder" && (
                  <>
                    <div className="global-single-input add-alteration-input auto-complete">
                      <p>Workorder ID</p>
                      <Autocomplete
                        sx={{ width: "100%" }}
                        options={allWorkOrderNosList || [""]}
                        getOptionLabel={(option) => option?.orderNo}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Cutting Master"
                          />
                        )}
                        onChange={(e, newValue) =>{

                          // setAddAlterationForm2({
                          //   ...addAlterationForm2,
                          //   workOrderId: parseFloat(newValue?.orderNo.slice(7)),
                          // })
                           setAddAlterationForm2({
                            ...addAlterationForm2,
                            workOrderId:newValue?.orderNo,
                          })
                          setWorkOrderAlert(false)
                          if(newValue===null){
                            setWorkOrderAlert(true)
                          }
                        }
                        }
                      />
                      {workOrderAlert?
                        <p className="doc-validation-alert">Select a workorder Id !!</p>
                      :""}
                    </div>
                    <div className="global-single-input add-alteration-input auto-complete">
                      <p>Item</p>

                      <Autocomplete
                        sx={{ width: "100%" }}
                        options={models || [""]}
                        getOptionLabel={(option) => option?.name}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Select Item" />
                        )}
                        // value={addAlterationForm2?.item}
                        onChange={(e, newValue) =>
                          setAddAlterationForm2({
                            ...addAlterationForm2,
                            item: newValue?.name,
                          })
                        }
                      />
                    </div>
                  </>
                )}

                <div className="global-single-input add-alteration-input">
                  <p>No Of Clothes</p>
                  <input
                    placeholder="Enter no of cloths..."
                    value={
                      isNaN(addAlterationForm2?.noOfCloths)
                        ? 0
                        : addAlterationForm2?.noOfCloths
                     
                    }
                    onChange={getAddAlterationForm2("noOfCloths")}
                  />
                    {noOfClothsAlert?
                        <p className="doc-validation-alert">Enter Number Of Cloths !!</p>
                      :""}
                </div>
                <div className="global-single-input add-alteration-input">
                  <p>Stitching Rate</p>
                  <input
                    placeholder="Enter Stitching Rate..."
                    value={
                     addAlterationForm2?.stitchingRate
                    }
                    onChange={getAddAlterationForm2("stitchingRate")}
                  />
                    {stitchingRateAlert?
                        <p className="doc-validation-alert">Enter Stitching Rate !!</p>
                      :""}
                </div>

                {alterationMaterialCheck === true && (
                  <>
                    <div className="global-single-input add-alteration-input auto-complete">
                      <p>Material</p>
                      <Autocomplete
                        sx={{ width: "100%" }}
                        options={materialProductList || [""]}
                        getOptionLabel={(option) => option?.productName}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Material..."
                          />
                        )}
                        //  value={addAlterationForm1?.customer}
                        onChange={(e, newValue) =>{
                          setAddAlterationForm2({
                            ...addAlterationForm2,
                            material: newValue?._id,
                          })
                          setMaterialAlert(false)
                          if(newValue===null){
                            setMaterialAlert(true)
                          }
                        }
                        }
                      />
                      {materialAlert?
                        <p className="doc-validation-alert">Select a material !!</p>
                      :""}
                    </div>
                    <div className="global-single-input add-alteration-input">
                      <p>Used Qty</p>
                      <input
                        placeholder="Enter used qty..."
                        value={addAlterationForm2?.usedQty}
                        onChange={getAddAlterationForm2("usedQty")}
                      />
                      {usedQtyAlert?
                        <p className="doc-validation-alert">Fill this field !!</p>
                      :""}
                    </div>
                    <div className="global-single-input add-alteration-input">
                      <p>Unit Rate</p>
                      <input
                        placeholder="Enter Price..."
                        value={addAlterationForm2?.price}
                        onChange={getAddAlterationForm2("price")}
                      />
                      {unitRateAlert?
                        <p className="doc-validation-alert">Fill this field !!</p>
                      :""}
                    </div>
                  </>
                )}
                <div className="global-single-input add-alteration-input">
                  <p>Cutting Master</p>
                  {/* <Select
                value={addAlterationForm2?.cuttingMaster}
                onChange={getAddAlterationForm2("cuttingMaster")}
              >
                <MenuItem value="0">-select cutting master-</MenuItem>
              </Select> */}
                  <Autocomplete
                    multiple
                    sx={{ width: "100%" }}
                    options={cutterList || [""]}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option?.staff_name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option?.staff_name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Cutting Master"
                      />
                    )}
                    value={addAlterationForm1?.cuttingMaster}
                    onChange={(e, newValue) =>
                      setAddAlterationForm2({
                        ...addAlterationForm2,
                        cuttingMaster: newValue,
                      })
                    }
                  />
                </div>
                <div className="global-single-input add-alteration-input">
                  <p>Tailor</p>
                  {/* <Select
                value={addAlterationForm2?.tailor}
                onChange={getAddAlterationForm2("tailor")}
              >
                <MenuItem value="0">-select a tailor-</MenuItem>
              </Select> */}
                  <Autocomplete
                    multiple
                   
                    options={tailorList || [""]}
                    getOptionLabel={(option) => option?.staff_name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option?.staff_name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Tailor" />
                    )}
                    onChange={(e, newValue) =>
                      setAddAlterationForm2({
                        ...addAlterationForm2,
                        tailor: newValue,
                      })
                    }
                  />
                </div>
                <div className="global-single-input add-alteration-input">
                  <p>Tailor Commission</p>
                  <input
                    placeholder="Enter commission of tailor..."
                    value={
                      isNaN(addAlterationForm2?.tailorCommission)
                        ? 0
                        : addAlterationForm2?.tailorCommission
                    }
                    onChange={getAddAlterationForm2("tailorCommission")}
                  />
                </div>
                <div className="global-single-input add-alteration-input">
                  <p>Amount</p>
                  <input
                    placeholder="Enter amount..."
                    value={
                      isNaN(addAlterationForm2?.amountType)
                        ? 0
                        : addAlterationForm2?.amountType
                    }
                    onChange={getAddAlterationForm2("amountType")}
                  />
                </div>
                <div className="global-single-input add-alteration-input">
                  <p>Remarks</p>
                  <input
                    placeholder="Enter remarks..."
                    value={addAlterationForm2?.remarks}
                    onChange={getAddAlterationForm2("remarks")}
                  />
                </div>
              </>
            )}

            <div className="global-single-input add-alteration-radio-button">
              <div className="add-alteration-radio-button-free-container">
                <input
                  type="radio"
                  id="alterationFree"
                  name="alterationRadio"
                  value="free"
                  onChange={changeAlterationRadioType}
                />
                <label htmlFor="alterationFree">Free</label>
              </div>
              <div className="add-alteration-radio-button-paid-container">
                <input
                  defaultChecked
                  type="radio"
                  id="alterationPaid"
                  name="alterationRadio"
                  value="paid"
                  onChange={changeAlterationRadioType}
                />
                <label htmlFor="alterationPaid">Paid</label>
              </div>
            </div>
          </div>
          {alterationRadioType === "free" && (
            <>
              <hr className="add-alteration-hr" />
              <div className="add-alteration-dialog-input-container">
                <div className="global-single-input add-alteration-input auto-complete">
                  <p>Staff</p>
                  <Autocomplete
                    sx={{ width: "100%" }}
                    options={allActiveEmpList || [""]}
                    getOptionLabel={(option) =>
                      `${option?.emp_id}-${option?.staff_name}`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Cutting Master"
                      />
                    )}
                    onChange={(e, newValue) =>
                      setAddAlterationForm3({
                        ...addAlterationForm3,
                        staff: newValue?._id,
                      })
                    }
                  />
                </div>
                <div className="global-single-input add-alteration-input">
                  <p>Reason</p>
                  <input
                    type="text"
                    value={addAlterationForm3?.reason}
                    onChange={getAddAlterationForm3("reason")}
                  />
                </div>
              </div>
            </>
          )}

          <div className="add-alteration-btn-container">
            <button
              disabled={payments === true}
              className={
                payments === true
                  ? "btn btn-secondary-outlined disabled-outline"
                  : "btn btn-secondary-outlined"
              }
              onClick={() => setAddAlteration(false)}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                if (alterationRadioType === "paid") {
                  openPaymentDialog();
                } else {
                  addAlterationSubmitBtnFn();
                }
              }}
              disabled={payments === true}
              className={
                payments === true
                  ? "btn btn-secondary disabled"
                  : "btn btn-secondary"
              }
            >
              {alterationRadioType === "paid" ? "Next" : "Submit"}
            </button>
          </div>
        </div>
      </Dialog>
      {payments === true && (
        <PaymentsWO
          closePayments={closePaymentDialog}
          // width="520px"
          // left="50%"
          parentWidth="84%"
          right="6%"
          boxShadow="0 0 10px 2px #eee"
          subTotal={
            orderType === "oldDelivery"
              ? addAlterationForm2.oldDelAmount
              : addAlterationForm2?.amountType
          }
          clearWOState={clearState}
          bodyWOPayment={finalBodyToApi}
          setOpenSuccessSnack={setSuccessSnackOpen}
          setOpenErrorSnack={setErrorSnackOpen}
          setSnackMsg={setSnackMsg}
          finalAPI={addAlterationAPICall}
          updateListenerProp={updateListener}
          cusId={addAlterationForm1.customer}
          paymentSection="alteration"
          branchId={userBranchId}
        />
      )}

      {/* edit alteration */}
      <Dialog
        open={editAlteration}
        maxWidth="lg"
        sx={{ zIndex: 1210, marginTop: "2%" }}
        onClose={() => setEditAlteration(false)}
        onKeyDown={(e)=>e.key === "Escape" && setEditAlteration(false)}
      >
        <div className="add-alteration-dialog-container">
          <h4>Edit Alteration</h4>
          <div className="add-alteration-dialog-input-container">
            <div className="global-single-input add-alteration-input">
              <p>Transaction ID</p>
              <input disabled value={editAlterationForm.transId} />
            </div>
            <div className="global-single-input add-alteration-input auto-complete">
              <p>Customer</p>
              <Autocomplete
                sx={{ width: "100%" }}
                value={editAlterationForm.customer}
                options={customerList || [""]}
                getOptionLabel={(option) => option?.name}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Customer" />
                )}
                onChange={getAlterationEditFormValues("customer")}
              />
              {/* <span
                onClick={() => {
                  setAddCustomerDialog(true);
                  setIsDialog(true);
                }}
                className="suffix-text-blue"
              >
                Create Customer
              </span> */}
            </div>
            <div className="global-single-input add-alteration-input">
              <p>Date</p>
              <input
                type="date"
                value={editAlterationForm?.date}
                onChange={getAlterationEditFormValues("date")}
                // onChange={getAddAlterationForm1("date")}
              />
            </div>
          </div>
          <hr className="global-hr" />
          <div className="add-alteration-dialog-checkbox">
            <div className="add-alteration-readyMade-workOrder-radio">
              <div>
                <input
                  type="radio"
                  id="addAlterationReadymade"
                  name="addAlterationRadioGroup"
                  value="readymade"
                  checked={orderType === "readymade"}
                  onChange={getOrderType}
                />
                <label htmlFor="addAlterationReadymade">Readymade</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="addAlterationWorkOrder"
                  name="addAlterationRadioGroup"
                  value="workorder"
                  checked={orderType === "workorder"}
                  onChange={getOrderType}
                />
                <label htmlFor="addAlterationWorkOrder">WorkOrder</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="addAlterationOldDelivery"
                  name="addAlterationRadioGroup"
                  value="oldDelivery"
                  checked={orderType === "oldDelivery"}
                  onChange={getOrderType}
                />
                <label htmlFor="addAlterationOldDelivery">Old Delivery</label>
              </div>
            </div>
          </div>
          {/* material checkbox */}
          {orderType !== "oldDelivery" && (
            <div className="alteration-material-checkbox-main-container">
              <div className="alteration-material-checkbox">
                <input
                  type="checkbox"
                  id="addAlterationMaterial"
                  checked={alterationMaterialCheck}
                  onClick={alterationMaterialCheckboxFn}
                />
                <label htmlFor="addAlterationMaterial">Material</label>
              </div>
            </div>
          )}

          <div className="add-alteration-dialog-input-container">
            {orderType === "oldDelivery" ? (
              <>
                <div className="global-single-input add-alteration-input">
                  <p>Order No</p>
                  <input
                    placeholder="Enter Order No..."
                    type="text"
                    value={editAlterationForm.oldOrdNo}
                    onChange={getAlterationEditFormValues("oldOrdNo")}
                    // value={addAlterationForm2?.oldDelOrderNo}
                    // onChange={getAddAlterationForm2("oldDelOrderNo")}
                  />
                </div>
                <div className="global-single-input add-alteration-input">
                  <p>Qty</p>
                  <input
                    placeholder="Enter Qty..."
                    type="text"
                    value={editAlterationForm.oldQty}
                    onChange={getAlterationEditFormValues("oldQty")}
                    // value={
                    //   isNaN(addAlterationForm2?.oldDelQty)
                    //     ? 0
                    //     : addAlterationForm2?.oldDelQty
                    // }
                    // onChange={getAddAlterationForm2("oldDelQty")}
                  />
                </div>
                <div className="global-single-input add-alteration-input">
                  <p>Rate</p>
                  <input
                    placeholder="Enter Rate..."
                    type="text"
                    value={editAlterationForm.oldRate}
                    onChange={getAlterationEditFormValues("oldRate")}
                    // value={
                    //   isNaN(addAlterationForm2?.oldDelPrice)
                    //     ? 0
                    //     : addAlterationForm2?.oldDelPrice
                    // }
                    // onChange={getAddAlterationForm2("oldDelPrice")}
                  />
                </div>
                <div className="global-single-input add-alteration-input">
                  <p>Amount</p>
                  <input
                    placeholder="Enter Amount..."
                    type="text"
                    value={editAlterationForm.oldAmount}
                    onChange={getAlterationEditFormValues("oldAmount")}
                    // value={
                    //   isNaN(addAlterationForm2?.oldDelAmount)
                    //     ? 0
                    //     : addAlterationForm2?.oldDelAmount
                    // }
                    // onChange={getAddAlterationForm2("oldDelAmount")}
                  />
                </div>
                <div className="global-single-input add-alteration-input">
                  <p>Notes</p>
                  <input
                    placeholder="Enter Notes..."
                    type="text"
                    value={editAlterationForm.oldNotes}
                    onChange={getAlterationEditFormValues("notes")}
                    // value={addAlterationForm2?.oldDelNotes}
                    // onChange={getAddAlterationForm2("oldDelNotes")}
                  />
                </div>
              </>
            ) : (
              <>
                {orderType === "workorder" && (
                  <>
                    <div className="global-single-input add-alteration-input auto-complete">
                      <p>Workorder ID</p>
                      <Autocomplete
                        sx={{ width: "100%" }}
                        options={allWorkOrderNosList || [""]}
                        getOptionLabel={(option) => option?.orderNo}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select work order id"
                          />
                        )}
                        value={editAlterationForm.woId}
                        onChange={getAlterationEditFormValues("woId")}
                        // onChange={(e, newValue) =>
                        //   setAddAlterationForm2({
                        //     ...addAlterationForm2,
                        //     workOrderId: parseFloat(newValue?.orderNo.slice(7)),
                        //   })
                        // }
                      />
                    </div>
                    {/* <div className="global-single-input add-alteration-input auto-complete">
                      <p>Item</p>

                      <Autocomplete
                        sx={{ width: "100%" }}
                        options={models || [""]}
                        getOptionLabel={(option) => option?.name}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Select Item" />
                        )}
                        // value={addAlterationForm2?.item}
                        // onChange={(e, newValue) =>
                        //   setAddAlterationForm2({
                        //     ...addAlterationForm2,
                        //     item: newValue?.name,
                        //   })
                        // }
                      />
                    </div> */}
                  </>
                )}

                <div className="global-single-input add-alteration-input">
                  <p>No Of Clothes</p>
                  <input
                    placeholder="Enter no of cloths..."
                    value={editAlterationForm.noOfCloths}
                    onChange={getAlterationEditFormValues("noOfCloth")}
                    // value={
                    //   isNaN(addAlterationForm2?.noOfCloths)
                    //     ? 0
                    //     : addAlterationForm2?.noOfCloths
                    // }
                    // onChange={getAddAlterationForm2("noOfCloths")}
                  />
                </div>
                <div className="global-single-input add-alteration-input">
                  <p>Stitching Rate</p>
                  <input
                    placeholder="Enter Stitching Rate..."
                    value={editAlterationForm.stitchingRate}
                    onChange={getAlterationEditFormValues("stitchingRate")}
                    // value={
                    //   isNaN(addAlterationForm2?.stitchingRate)
                    //     ? 0
                    //     : addAlterationForm2?.stitchingRate
                    // }
                    // onChange={getAddAlterationForm2("stitchingRate")}
                  />
                </div>

                {alterationMaterialCheck === true && (
                  <>
                    <div className="global-single-input add-alteration-input auto-complete">
                      <p>Material</p>
                      <Autocomplete
                        sx={{ width: "100%" }}
                        options={materialProductList || [""]}
                        getOptionLabel={(option) => option?.productName}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Material..."
                          />
                        )}
                        value={editAlterationForm.materials}
                        onChange={getAlterationEditFormValues("materials")}
                        //  value={addAlterationForm1?.customer}
                        // onChange={(e, newValue) =>
                        //   setAddAlterationForm2({
                        //     ...addAlterationForm2,
                        //     material: newValue?._id,
                        //   })
                        // }
                      />
                    </div>
                    <div className="global-single-input add-alteration-input">
                      <p>Used Qty</p>
                      <input
                        placeholder="Enter used qty..."
                        value={editAlterationForm.materialUsedQty}
                        onChange={getAlterationEditFormValues("usedQty")}
                        // onChange={getAddAlterationForm2("usedQty")}
                      />
                    </div>
                    <div className="global-single-input add-alteration-input">
                      <p>Unit Rate</p>
                      <input
                        placeholder="Enter Price..."
                        value={editAlterationForm.materialUnitRate}
                        onChange={getAlterationEditFormValues("unitRate")}
                        // value={addAlterationForm2?.price}
                        // onChange={getAddAlterationForm2("price")}
                      />
                    </div>
                  </>
                )}
                <div className="global-single-input add-alteration-input">
                  <p>Cutting Master</p>

                  <Autocomplete
                    multiple
                    sx={{ width: "100%" }}
                    options={cutterList || [""]}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option?.staff_name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option?.staff_name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Cutting Master"
                      />
                    )}
                    value={editAlterationForm.cuttingMaster}
                    onChange={getAlterationEditFormValues("cuttingMaster")}
                    // value={addAlterationForm1?.cuttingMaster}
                    // onChange={(e, newValue) =>
                    //   setAddAlterationForm2({
                    //     ...addAlterationForm2,
                    //     cuttingMaster: newValue,
                    //   })
                    // }
                  />
                </div>
                <div className="global-single-input add-alteration-input">
                  
                  <p>Tailor</p>

                  <Autocomplete
                    multiple
                    sx={{ width: "100%" }}
                    options={tailorList || [""]}
                    getOptionLabel={(option) => option?.staff_name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option?.staff_name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Tailor" />
                    )}
                    value={editAlterationForm.tailor}
                    onChange={getAlterationEditFormValues("tailor")}
                    // onChange={(e, newValue) =>
                    //   setAddAlterationForm2({
                    //     ...addAlterationForm2,
                    //     tailor: newValue,
                    //   })
                    // }
                  />
                </div>
                <div className="global-single-input add-alteration-input">
                  <p>Tailor Commission</p>
                  <input
                    placeholder="Enter commission of tailor..."
                    value={editAlterationForm.tailorCommission}
                    onChange={getAlterationEditFormValues("tailorCommission")}
                    // value={
                    //   isNaN(addAlterationForm2?.tailorCommission)
                    //     ? 0
                    //     : addAlterationForm2?.tailorCommission
                    // }
                    // onChange={getAddAlterationForm2("tailorCommission")}
                  />
                </div>
                <div className="global-single-input add-alteration-input">
                  <p>Amount</p>
                  <input
                    placeholder="Enter amount..."
                    value={editAlterationForm.amount}
                    onChange={getAlterationEditFormValues("amount")}
                    // value={
                    //   isNaN(addAlterationForm2?.amountType)
                    //     ? 0
                    //     : addAlterationForm2?.amountType
                    // }
                    // onChange={getAddAlterationForm2("amountType")}
                  />
                </div>
                <div className="global-single-input add-alteration-input">
                  <p>Remarks</p>
                  <input
                    placeholder="Enter remarks..."
                    value={editAlterationForm.remarks}
                    onChange={getAlterationEditFormValues("remarks")}
                    // value={addAlterationForm2?.remarks}
                    // onChange={getAddAlterationForm2("remarks")}
                  />
                </div>
              </>
            )}

            <div className="global-single-input add-alteration-radio-button">
              <div className="add-alteration-radio-button-free-container">
                <input
                  type="radio"
                  id="alterationFree"
                  name="alterationRadio"
                  value="free"
                  checked={alterationRadioType === "free"}
                  onChange={changeAlterationRadioType}
                />
                <label htmlFor="alterationFree">Free</label>
              </div>
              <div className="add-alteration-radio-button-paid-container">
                <input
                  defaultChecked
                  type="radio"
                  id="alterationPaid"
                  name="alterationRadio"
                  value="paid"
                  checked={alterationRadioType === "paid"}
                  onChange={changeAlterationRadioType}
                />
                <label htmlFor="alterationPaid">Paid</label>
              </div>
            </div>
          </div>
          {alterationRadioType === "free" && (
            <>
              <hr className="add-alteration-hr" />
              <div className="add-alteration-dialog-input-container">
                <div className="global-single-input add-alteration-input auto-complete">
                  <p>Staff</p>
                  <Autocomplete
                    sx={{ width: "100%" }}
                    options={allActiveEmpList || [""]}
                    getOptionLabel={(option) =>
                      `${option?.emp_id}-${option?.staff_name}`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Cutting Master"
                      />
                    )}
                    value={editAlterationForm.staff}
                    onChange={getAlterationEditFormValues("staff")}
                    // onChange={(e, newValue) =>
                    //   setAddAlterationForm3({
                    //     ...addAlterationForm3,
                    //     staff: newValue?._id,
                    //   })
                    // }
                  />
                </div>
                <div className="global-single-input add-alteration-input">
                  <p>Reason</p>
                  <input
                    type="text"
                    value={editAlterationForm.reason}
                    onChange={getAlterationEditFormValues("reason")}
                    // value={addAlterationForm3?.reason}
                    // onChange={getAddAlterationForm3("reason")}
                  />
                </div>
              </div>
            </>
          )}

          <div className="add-alteration-btn-container">
            <button
              disabled={payments === true}
              className={
                payments === true
                  ? "btn btn-secondary-outlined disabled-outline"
                  : "btn btn-secondary-outlined"
              }
              onClick={() => setEditAlteration(false)}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                if (alterationRadioType === "paid") {
                  openEditPaymentDialog();
                } else {
                  editAlterationSubmitWoPayment();
                }
              }}
              disabled={payments === true}
              className={
                payments === true
                  ? "btn btn-secondary disabled"
                  : "btn btn-secondary"
              }
            >
              {alterationRadioType === "paid" ? "Next" : "Submit"}
            </button>
          </div>
        </div>
      </Dialog>
      {paymentsEdit === true && (
        <PaymentsWO
          closePayments={closePaymentEditDialog}
          // width="520px"
          // left="50%"
          parentWidth="84%"
          right="6%"
          boxShadow="0 0 10px 2px #eee"
          subTotal={
            orderType === "oldDelivery"
            ? Number(editAlterationForm.oldAmount)
            : Number(editAlterationForm.amount)
          }
          clearWOState={clearAlterationEditForm}
          bodyWOPayment={editAlterationFinalBody}
          setOpenSuccessSnack={setSuccessSnackOpen}
          setOpenErrorSnack={setErrorSnackOpen}
          setSnackMsg={setSnackMsg}
          finalAPI={editAlterationAPICall}
          updateListenerProp={updateListener}
          cusId={editAlterationForm.customer?._id}
          paymentSection="alteration"
          branchId={userBranchId}
        />
      )}

      {/* view alteration dialog */}
      <Dialog open={viewAlteration} maxWidth="lg"  onKeyDown={(e)=>e.key === "Escape" && setViewAlteration(false)} >
        <div className="add-alteration-dialog-container">
          <h4>View Alteration</h4>
          <div className="add-alteration-dialog-input-container">
            <div className="global-single-input add-alteration-input">
              <p>Transaction ID</p>
              <input readOnly value={alterationSingleView?.altNo} />
            </div>
            <div className="global-single-input add-alteration-input">
              <p>Customer</p>
              <input readOnly value={alterationSingleView?.customer} />
            </div>
            <div className="global-single-input add-alteration-input">
              <p>Date</p>
              <input readOnly type="date" value={alterationSingleView?.date} />
            </div>
          </div>
          <hr className="global-hr" />

          <div className="add-alteration-dialog-input-container">
            {/* <div className="global-single-input add-alteration-input">
              <p>Item</p>
              <input />
            </div> */}
            <div className="global-single-input add-alteration-input">
              <p>Material</p>
              <input
                readOnly
                value={
                  alterationSingleView?.items.length !== 0
                    ? alterationSingleView?.items[0]?.productName
                    : "-NIL-"
                }
              />
            </div>

            <div className="global-single-input add-alteration-input">
              <p>Used Qty</p>
              <input
                readOnly
                value={
                  alterationSingleView?.items.length !== 0
                    ? alterationSingleView?.items[0]?.usedQty
                    : "-NIL-"
                }
              />
            </div>
            <div className="global-single-input add-alteration-input">
              <p>Amount</p>
              <input
                readOnly
                value={
                  alterationSingleView?.items.length !== 0
                    ? alterationSingleView?.items[0]?.amount
                    : "-NIL-"
                }
              />
            </div>
            <div className="global-single-input add-alteration-input">
              <p>No Of Clothes</p>
              <input
                readOnly
                value={alterationSingleView?.noOfCloths || "-NIL-"}
              />
            </div>
            <div className="global-single-input add-alteration-input">
              <p>Cutting Master</p>
              <Select value={viewCuttingMaster}>
                <MenuItem value="0">-view cutting master-</MenuItem>
                {alterationSingleView?.cuttingMaster?.map((r) => (
                  <MenuItem>{r}</MenuItem>
                ))}
              </Select>
            </div>
            <div className="global-single-input add-alteration-input">
              <p>Remarks</p>
              <input
                readOnly
                value={alterationSingleView?.remarks || "-NIL-"}
              />
            </div>
            <div className="global-single-input add-alteration-input">
              <p>Tailor</p>
              <Select value={viewTailor}>
                <MenuItem value="0">-view tailor-</MenuItem>
                {alterationSingleView?.tailor?.map((r) => (
                  <MenuItem>{r}</MenuItem>
                ))}
              </Select>
            </div>
            <div className="global-single-input add-alteration-input">
              <p>Tailor Commission</p>
              <input
                readOnly
                value={alterationSingleView?.tailoringCommission}
              />
            </div>
          </div>
          <hr className="global-hr" />
          <h4>Payments</h4>
          <div className="add-alteration-dialog-input-container">
            <div className="global-single-input add-alteration-input">
              <p>Total Amount</p>
              <input type="text" value={alterationSingleView?.total} />
              <p className="suffix-button">
                Balance:{alterationSingleView?.balance}
              </p>
            </div>
          </div>
          {alterationSingleView?.payment?.map((r) => (
            <div className="add-alteration-dialog-input-container">
              <div className="global-single-input add-alteration-input">
                <p>Amount</p>
                <input readOnly value={r?.paidAmount} />
              </div>
              <div
                className="global-single-input add-alteration-input"
                style={{ width: "23%" }}
              >
                <p>Payment Type</p>
                <input readOnly value={r?.type} />
              </div>
              {r?.vendor !== null && (
                <div
                  className="global-single-input add-alteration-input"
                  style={{ width: "23%" }}
                >
                  <p>
                    {r?.type === "Card" || r?.type === "card"
                      ? "Card Name"
                      : r?.type === "Upi"
                      ? "Upi Name"
                      : undefined}
                  </p>
                  <input type="text" value={r?.vendor} />
                </div>
              )}
            </div>
          ))}

          <div className="add-alteration-btn-container">
            <button
              className="btn btn-secondary-outlined"
              onClick={() => setViewAlteration(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Dialog>

      {/* single alteration item list */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        {extractedAlteration !== undefined &&
          extractedAlteration[0]?.cuttingMaster?.map((k, index) => (
            <MenuItem>{k}</MenuItem>
          ))}
      </Menu>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl1}
        open={open1}
        onClose={handleClose1}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        {extractedAlteration !== undefined &&
          extractedAlteration[0]?.tailor?.map((k, index) => (
            <MenuItem>{k}</MenuItem>
          ))}
      </Menu>

      {/* pay balance payment screen */}
       {
        paymentDialog  && (
          <PaymentsWO
          closePayments={closeBalancePaymentScreen}
          parentWidth="95%"
          right="17%"
          subTotal={currentPayingObj?.balance} //imp
          bodyWOPayment={{}}
          finalAPI={alterationPayBalanceAPICall} //API call
          setOpenSuccessSnack={setSuccessSnackOpen}
          setOpenErrorSnack={setErrorSnackOpen}
          setSnackMsg={setSnackMsg}
          clearWOState={clearViewOrderUser} //Clear fn
          updateListenerProp={updateListener}
          cusId={currentPayingObj?.cusId} // imp
          // placeOrderClick={undefined}
           isMultiplePayment={setIsMultiplePayment}
          // paidAmount={undefined}
          // shipmentCharge={undefined}
          paymentSection="alteration"
          woId={currentPayingObj?._id}
          branchId={userBranchId}
        />
        )
      } 

      {/* create customer dialog */}
      <Dialog open={addCustomerDialog}>
        <AddCustomer
          isDialog={isDialog}
          closeDialog={closeCreateCustomerDialog}
        />
      </Dialog>
      {/* Snackbars  */}
      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />

      {/* alteraion report print  */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (alterationReportPrint = el)}>
          <div className="print-view-top-container">
            <div
              className="print-view-top-left-container"
              style={{ width: "25%" }}
            >
              <p>BRANCH : {loginResponse?.branchName}</p>
            </div>
            <div
              className="print-view-top-middle-container"
              style={{ width: "50%" }}
            >
              {userRole === "admin" ? (
                <h3>{loginResponse?.data?.profile?.companyName}</h3>
              ) : userRole === "user" ? (
                <h3>{loginResponse?.storeName}</h3>
              ) : undefined}

              <p>ALTERATION REPORT</p>
            </div>
            <div
              className="print-view-top-right-container"
              style={{ width: "25%" }}
            >
              <p>Date : {today}</p>
              <p></p>
            </div>
          </div>
          {/* table */}
          <div className="print-view-table-container alteration-table-print">
            <table className="report-print-view-table-with-clr">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Transaction ID</th>
                  <th>Date</th>
                  <th>Type</th>
                  <th>Customer</th>
                  <th>Mobile</th>
                  <th>Amount</th>
                  <th>Paid</th>
                  <th>Discount</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                {extractedData !== undefined &&
                  extractedData[0]?.list?.map((r, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{r?.altNo}</td>
                      <td>{r?.date}</td>
                      <td>{r?.type}</td>
                      <td>{r?.customer}</td>
                      <td>{r?.mobileNo}</td>
                      <td>{r?.amount}</td>
                      <td>{r?.paidAmount}</td>
                      <td>{r?.discount}</td>
                      <td>{r?.balance}</td>
                    </tr>
                  ))}
                <br />
                <tr
                  style={{
                    height: "50px",
                    borderTop: "1px solid #000",
                    borderBottom: "1px solid #000",
                  }}
                >
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td style={{ fontSize: "0.9rem", fontWeight: 600 }}>Total</td>
                  <td style={{ fontSize: "0.9rem", fontWeight: 600 }}>
                    {extractedData !== undefined && extractedData[0]?.amntTotal}
                  </td>
                  <td style={{ fontSize: "0.9rem", fontWeight: 600 }}>
                    {extractedData !== undefined && extractedData[0]?.paidTotal}
                  </td>
                  <td style={{ fontSize: "0.9rem", fontWeight: 600 }}>
                    {extractedData !== undefined &&
                      extractedData[0]?.discountTotal}
                  </td>
                  <td style={{ fontSize: "0.9rem", fontWeight: 600 }}>
                    {extractedData !== undefined &&
                      extractedData[0]?.balanceTotal}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* alteraion summary  report print  */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (alterationReportSummary = el)}>
          <div className="print-view-top-container">
            <div
              className="print-view-top-left-container"
              style={{ width: "25%" }}
            >
              <p>BRANCH : {loginResponse?.branchName}</p>
            </div>
            <div
              className="print-view-top-middle-container"
              style={{ width: "50%" }}
            >
              {userRole === "admin" ? (
                <h3>{loginResponse?.data?.profile?.companyName}</h3>
              ) : userRole === "user" ? (
                <h3>{loginResponse?.storeName}</h3>
              ) : undefined}

              <p>ALTERATION SUMMARY REPORT</p>
            </div>
            <div
              className="print-view-top-right-container"
              style={{ width: "25%" }}
            >
              <p>Date : {today}</p>
              <p></p>
            </div>
          </div>
          {/* table */}
          <div className="print-view-table-container alteration-table-print">
            <table className="report-print-view-table-with-clr">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Date</th>
                  <th>Transaction ID</th>
                  <th>Customer ID</th>
                  <th>Name</th>
                  <th>Order Amount</th>
                  <th>Paid Amount</th>
                  <th>Discount</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                {extractedData !== undefined &&
                  extractedData[0]?.list?.map((r, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{r?.date}</td>
                      <td>{r?.altNo}</td>
                      <td>{r?.customerId}</td>
                      <td>{r?.customer}</td>
                      <td>{r?.amount}</td>
                      <td>{r?.paidAmount}</td>
                      <td>{r?.discount}</td>
                      <td>{r?.balance}</td>
                    </tr>
                  ))}
                <br />
                <tr
                  style={{
                    height: "50px",
                    borderTop: "1px solid #000",
                    borderBottom: "1px solid #000",
                  }}
                >
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td style={{ fontSize: "0.9rem", fontWeight: 600 }}>Total</td>
                  <td style={{ fontSize: "0.9rem", fontWeight: 600 }}>
                    {extractedData !== undefined && extractedData[0]?.amntTotal}
                  </td>
                  <td style={{ fontSize: "0.9rem", fontWeight: 600 }}>
                    {extractedData !== undefined && extractedData[0]?.paidTotal}
                  </td>
                  <td style={{ fontSize: "0.9rem", fontWeight: 600 }}>
                    {extractedData !== undefined &&
                      extractedData[0]?.discountTotal}
                  </td>
                  <td style={{ fontSize: "0.9rem", fontWeight: 600 }}>
                    {extractedData !== undefined &&
                      extractedData[0]?.balanceTotal}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* alteration report admin print */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (alterationAdminReportPrint = el)}>
          <div className="print-view-top-container">
            <div
              className="print-view-top-left-container"
              style={{ width: "25%" }}
            >
              <p></p>
            </div>
            <div
              className="print-view-top-middle-container"
              style={{ width: "50%" }}
            >
              {userRole === "admin" ? (
                <h3>{loginResponse?.data?.profile?.companyName}</h3>
              ) : userRole === "user" ? (
                <h3>{loginResponse?.storeName}</h3>
              ) : undefined}

              <p>ALTERATION REPORT</p>
            </div>
            <div
              className="print-view-top-right-container"
              style={{ width: "25%" }}
            >
              <p>Date : {today}</p>
            </div>
          </div>
          {/* map start */}
          {alterationList?.retList?.map((r) => (
            <div className="commission-report-summary-container">
              <div className="commission-report-summary-top-container">
                <h3 style={{ width: "20%" }}>{r?.branchId}</h3>
                <h3>{r?.branchName}</h3>
              </div>
              <div
                className="global-table-container alteration-table-print"
                style={{
                  margin: "1% 0",
                  maxHeight: "none",
                  overflowY: "hidden",
                }}
              >
                <table className="global-table report-print-view-table-with-clr ">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Date</th>
                      <th>Transaction ID</th>
                      <th>Customer</th>
                      <th>Mobile</th>
                      <th>Type</th>
                      <th>Item</th>
                      <th>Amount</th>
                      <th>Paid</th>
                      <th>Discount</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {r?.list?.map((k, i) => (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{k?.date}</td>
                        <td>{k?.altNo}</td>
                        <td>{k?.customer}</td>
                        <td>{k?.mobileNo}</td>
                        <td>{k?.type}</td>

                        <td>
                          {k?.items?.length !== 0
                            ? k?.items?.map((m) => <span>{m?.itemName}</span>)
                            : "-NIL-"}
                        </td>
                        {/* <td>
                        <div className="add-document-documents-btn">
                          <button
                            onMouseDown={extractSingleAlterationObj(r._id)}
                            onClick={
                              k?.cuttingMaster.length !== 0
                                ? handleClick
                                : undefined
                            }
                          >
                            {k?.cuttingMaster.length !== 0 ? " View" : "-NIL-"}
                            <span style={{ marginTop: "4px" }}>
                              {k?.cuttingMaster.length !== 0 ? (
                                <KeyboardArrowDown />
                              ) : undefined}
                            </span>
                          </button>
                        </div>
                      </td> */}

                        {/* <td>
                        <div className="add-document-documents-btn">
                          <button
                            onMouseDown={extractSingleAlterationObj(r._id)}
                            onClick={
                              k?.tailor.length !== 0 ? handleClick1 : undefined
                            }
                          >
                            {k?.tailor.length !== 0 ? " View " : "-NIL-"}
                            <span style={{ marginTop: "4px" }}>
                              {k?.tailor.length !== 0 ? (
                                <KeyboardArrowDown />
                              ) : undefined}
                            </span>
                          </button>
                        </div>
                      </td> */}
                        <td>{k?.amount}</td>
                        <td>{k?.paidAmount}</td>
                        <td>{k?.discount}</td>
                        <td>{k?.balance}</td>
                      </tr>
                    ))}

                    <tr
                      style={{
                        height: "50px",
                        borderTop: "1px solid #000",
                        borderBottom: "1px solid #000",
                      }}
                    >
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td style={{ fontSize: "0.9rem", fontWeight: 600 }}>
                        Total
                      </td>
                      <td style={{ fontSize: "0.9rem", fontWeight: 600 }}>
                        {r?.amntTotal}
                      </td>
                      <td style={{ fontSize: "0.9rem", fontWeight: 600 }}>
                        {r?.paidTotal}
                      </td>
                      <td style={{ fontSize: "0.9rem", fontWeight: 600 }}>
                        {r?.discountTotal}
                      </td>
                      <td style={{ fontSize: "0.9rem", fontWeight: 600 }}>
                        {r?.balanceTotal}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ))}

          {/* map end */}
        </div>
      </div>

      {/* alteration admin summary print */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (alterationAdminReportSummary = el)}>
          <div className="print-view-top-container">
            <div
              className="print-view-top-left-container"
              style={{ width: "25%" }}
            >
              <p></p>
            </div>
            <div
              className="print-view-top-middle-container"
              style={{ width: "50%" }}
            >
              {userRole === "admin" ? (
                <h3>{loginResponse?.data?.profile?.companyName}</h3>
              ) : userRole === "user" ? (
                <h3>{loginResponse?.storeName}</h3>
              ) : undefined}

              <p>ALTERATION SUMMARY REPORT</p>
            </div>
            <div
              className="print-view-top-right-container"
              style={{ width: "25%" }}
            >
              <p>Date : {today}</p>
            </div>
          </div>
          {/* TABLE */}
          {/* map start */}
          {alterationList?.retList?.map((r) => (
            <div className="commission-report-summary-container">
              <div className="commission-report-summary-top-container">
                <h3 style={{ width: "20%" }}>{r?.branchId}</h3>
                <h3>{r?.branchName}</h3>
              </div>
              <div
                className="global-table-container alteration-table-print"
                style={{
                  margin: "1% 0",
                  maxHeight: "none",
                  overflowY: "hidden",
                }}
              >
                <table className="global-table report-print-view-table-with-clr">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Date</th>
                      <th>Transaction ID</th>
                      <th>Customer ID</th>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Order Amount</th>
                      <th>Paid Amount</th>
                      <th>Discount</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {r?.list?.map((k, i) => (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{k?.date}</td>
                        <td>{k?.altNo}</td>
                        <td>{k?.customerId}</td>
                        <td>{k?.customer}</td>
                        <td>{k?.type}</td>
                        <td>{k?.amount}</td>
                        <td>{k?.paidAmount}</td>
                        <td>{k?.discount}</td>
                        <td>{k?.balance}</td>
                      </tr>
                    ))}
                    <tr
                      style={{
                        height: "50px",
                        borderTop: "1px solid #000",
                        borderBottom: "1px solid #000",
                      }}
                    >
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td style={{ fontSize: "0.9rem", fontWeight: 600 }}>
                        Total
                      </td>
                      <td style={{ fontSize: "0.9rem", fontWeight: 600 }}>
                        {r?.amntTotal}
                      </td>
                      <td style={{ fontSize: "0.9rem", fontWeight: 600 }}>
                        {r?.paidTotal}
                      </td>
                      <td style={{ fontSize: "0.9rem", fontWeight: 600 }}>
                        {r?.discountTotal}
                      </td>
                      <td style={{ fontSize: "0.9rem", fontWeight: 600 }}>
                        {r?.balanceTotal}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ))}

          {/* map end */}
        </div>
      </div>
      <LoadingForm loading={loadingForm}/>
    </div>
  );
};
