import React,{useState} from 'react'
import { IconButton,Dialog, Skeleton } from '@mui/material'
import '../../../../../css/Purchase Manager/GRN/report.css'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { viewGrnPostedListAPICall } from '../../../../../API/Purchase Manager/grnAPI'
import { convertDateFormat } from '../../../../../Js/Date'
import ErrorSnackbar from '../../../../Single Components/SnackBars/ErrorSnackbar'

export const PostedGrn = () => {
  // grn posted list
  const grnPostedList=useSelector((state)=>state.grnPostedListSlice.value)
  // login response
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  // role based rendering
  const userRole = useSelector((state) => state.userRoleSlice.value);

  const storeCode=localStorage.getItem('branchId')

  const [grnViewDialog,setGrnViewDialog]=useState(false)
  const [singleViewId,setSingleViewId]=useState()
  const [singleViewData,setSingleViewData]=useState([])
  const [isLoading,setIsLoading]=useState(false)
  const [snackMsg, setSnackMsg] = useState("oops!!!");
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [searchKeyword,setSearchKeyword]=useState("")
  const [filteredSearchList,setFilteredSearchList]=useState([])
  const closeErrorSnack = () => {
    setErrorSnackOpen(false);
  };
  

  // open grn post single view
  const openPostSingleView=(id)=>{
    setGrnViewDialog(true)
    setSingleViewId(id)
  } 

  useEffect(()=>{
    viewGrnPostedListAPICall(setIsLoading,setErrorSnackOpen,setSnackMsg,{branchId:storeCode})
  },[])
  useEffect(()=>{
    let singleViewData=[]
    singleViewData=grnPostedList?.filter((obj)=>{
      if(obj._id===singleViewId){
        return obj;
      }
    })
    setSingleViewData(singleViewData)

  },[singleViewId])
  const getSearchKeyword=(e)=>{
    setSearchKeyword(e.target.value)
  }
  const SearchFilter=(chars,listToFilter)=>{
    let searchedArray=[]
    let filteredArray=[]
    if(chars!==""){
      searchedArray=listToFilter.filter((obj)=>
      obj?.transNo?.toLowerCase()?.includes(chars?.toLowerCase())||
      obj?.branchId?.toLowerCase()?.includes(chars?.toLowerCase())||
      obj?.location?.toLowerCase()?.includes(chars?.toLowerCase())||
      obj?.supplierName?.toLowerCase()?.includes(chars?.toLowerCase())
    )
      filteredArray=searchedArray
    }
    else{
      filteredArray=listToFilter
    }
    return filteredArray
  }
  useEffect(()=>{
    if(grnPostedList!==undefined){
      let filteredArray=[]
      setFilteredSearchList(grnPostedList)
      filteredArray=SearchFilter(searchKeyword,grnPostedList)
      setFilteredSearchList(filteredArray)    
    }
    else{
      setFilteredSearchList([])
    }
    },[searchKeyword,grnPostedList])
  return (
    <div className="global-page-parent-container">
    <p className="breadcrumb">Purchase Manage &gt; Goods Received Note &gt; Posted</p>
     <div className='grn-draft-container'>
      <div className="stock-out-product-top-container" style={{alignItems:"center"}}>
          <h3>Posted</h3>
          <div className="view-order-search-container view-order-admin-search-container"
            style={{width:"25%"}}>
                <input
                  placeholder="Search Transaction Id,Location"
                  value={searchKeyword}
                  onChange={getSearchKeyword}
                />
                <IconButton>
                    <i class="bi bi-search search-icon-order"></i>
                </IconButton>
            </div>
        </div>
       <div className='global-table-container'>
        <table className='global-table'>
          <thead>
            <tr>
              <th>No</th>
              <th>Transaction ID</th>
              <th>Branch Code</th>
              <th>Branch Name</th>
              <th>Sales Invoice No</th>
              <th>Date</th>
              <th>Location</th>
              <th>Creditor</th>
              <th>View</th>
            </tr>
          </thead>
          {
            isLoading?
              <tbody>
                  <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr>
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr>
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr>
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr>
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr> 
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr> 
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr> 
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr>
              </tbody>:
              <tbody>
              {filteredSearchList?.length!==0?
                filteredSearchList?.slice(0)?.reverse()?.map((r,i)=>(
                  <tr>
                  <td>{i+1}</td>
                  <td>{r?.transNo}</td>
                  <td>{r?.branchId}</td>
                  <td>{r?.locationName}</td>
                  <td>{r?.invoiceNo}</td>
                  <td>{convertDateFormat(r?.date)}</td>
                  <td>{r?.location}</td>
                  <td>{r?.supplierName}</td>
                  <td>
                  <IconButton onClick={()=>openPostSingleView(r?._id)}>
                    <i class="bi bi-eye visibility-icon"></i>
                  </IconButton>
                  </td>
                </tr>
                ))
                :<tr><td colSpan={9} style={{textAlign:"center"}}>No Records</td></tr>
              }
             
            </tbody>
           }
        
        </table>
       </div>
     </div>

     <Dialog open={grnViewDialog} maxWidth="lg"  onKeyDown={(e)=>e.key === "Escape" && setGrnViewDialog(false)}>
       <div className='grn-report-container'>
        <h3>GRN View</h3>
        <div className='grn-report-input-container'>
            <div className='global-single-input'>
              <p>Transaction ID</p>
              <input value={singleViewData !== undefined && singleViewData[0]?.transNo}/>
            </div>
            <div className='global-single-input'>
              <p>Sales Invoice No</p>
              <input value={singleViewData !== undefined && singleViewData[0]?.invoiceNo}/>
            </div>
            <div className='global-single-input'>
              <p>Date</p>
              <input value={singleViewData !== undefined && singleViewData[0]?.date}/>
            </div>
            <div className='global-single-input'>
              <p>Location</p>
              <input value={singleViewData !== undefined && singleViewData[0]?.location}/>
            </div>
            <div className='global-single-input'>
              <p>Creditor</p>
              <input value={singleViewData !== undefined && singleViewData[0]?.supplierName}/>
            </div>
        </div>
       
       <div className='global-table-container grn-report-table-container'>
        <table className='global-table'>
            <thead>
                <tr>
                    <th>No</th>
                    <th>Product Code</th>
                    <th>Product Name</th>
                    <th>Unit</th>
                    <th>Order Qty</th>
                    <th>Received Qty</th>
                    <th>UP(FC)</th>
                    <th>UP(LC)</th>
                    <th>Amount</th>
                </tr>
            </thead>
            <tbody>
              {
                singleViewData !== undefined && singleViewData[0]?.purchaseInfo?.map((r,i)=>(
                  <tr>
                    <td>{i+1}</td>
                    <td>{r?.code}</td>
                    <td>{r?.productName}</td>
                    <td>{r?.unit}</td>
                    <td>{r?.orderQuantity}</td>
                    <td>{r?.recievedQuantity}</td>
                    <td>{r?.UPFC}</td>
                    <td>{r?.UPLC}</td>
                    <td>{parseFloat(r?.recievedQuantity)*parseFloat(r?.UPLC)}</td>
                </tr>
                ))
              }
                
               
            </tbody>
        </table>
       </div>
       <div className='grn-net-amount-container'>
         <div className='global-single-input grn-report-net-amount-input'>
            <p>Net Amount</p>
            <input
            //  placeholder='500.00'
            value={singleViewData !== undefined && singleViewData[0]?.amount}
            />
         </div>
       </div>
       <div className='grn-report-btn'>
       <button onClick={()=>setGrnViewDialog(false)} className='btn btn-secondary-outlined'>Cancel</button>
       </div>
       </div>
     </Dialog>
     <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnack}
        message={snackMsg}
      />
    </div>
  )
}
