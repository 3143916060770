import React from "react";
import InputBox from "./InputBox";
import DropdownInputBox from "./DropdownInputBox";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const VoucherTransactionPage = ({ onClick }) => {
  return (
    <div className="accounting-voucher-menu-sections">
      <div>
        <div style={{ display: "flex" }}>
          <InputBox label={"G/L Account Code"} />
          <DropdownInputBox
            labelName={"G/L Account "}
            addButton={true}
            addButtonOnClick={() => console.log("add btn")}
          />
          <InputBox label={"Debit"} />
          <InputBox label={"Credit"} />
          <InputBox label={"Debit balance"} />
          <InputBox label={"Credit balance"} />
        </div>
        <div className="voucher-add">
          <button className="add-button">
            <AddIcon />
          </button>
          <button className="delete-button">
          <i class="bi bi-trash3 delete-icon"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default VoucherTransactionPage;
