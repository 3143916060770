import React from 'react'
import '../../../../../css/Purchase Manager/GRN/grnDraft.css'
import { Dialog, IconButton, Skeleton } from '@mui/material'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { deleteDraftedGrnAPICall, viewGrnDraftedListAPICall } from '../../../../../API/Purchase Manager/grnAPI'
import { useState } from 'react'
import { useNavigate } from 'react-router';
import { convertDateFormat } from '../../../../../Js/Date'
import { deleteAlertMessage } from '../../../../../Js/generalFunctions'
import SuccessSnackbar from '../../../../Single Components/SnackBars/SuccessSnackbar'
import ErrorSnackbar from '../../../../Single Components/SnackBars/ErrorSnackbar'
export const GrnDraft = () => {
  const storeCode=localStorage.getItem('branchId')
  const grnDraftList=useSelector((state)=>state.grnDraftedList.value)
  const [graftGrnViewDialog,setDraftGrnViewDialog]=useState(false)
  const [singleViewId,setSingleViewId]=useState()
  const [singleViewData,setSingleViewData]=useState([])
  const [isLoading,setIsLoading]=useState(false)
  const [isModify,setIsModify]=useState(false)
  const [searchKeyword,setSearchKeyword]=useState("")
  const [filteredSearchList,setFilteredSearchList]=useState([])
   //Snackbar States
   const [successSnackOpen, setSuccessSnackOpen] = useState(false);
   const [errorSnackOpen, setErrorSnackOpen] = useState(false);
   const [snackMsg, setSnackMsg] = useState("Error!!");

  //update listner
  const updateListner=()=>{
    setIsModify(!isModify)
  }
  //Close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setSuccessSnackOpen(false);
  };
  //Close ErrorSnackbar
  const closeErrorSnackbar = () => {
    setErrorSnackOpen(false);
  };

  useEffect(()=>{
    viewGrnDraftedListAPICall(setIsLoading,setErrorSnackOpen,setSnackMsg,{branchId:storeCode})
  },[isModify])

  const openDraftGrnView=(id)=>{
    setDraftGrnViewDialog(true)
    setSingleViewId(id)
  }
  const closeGrnView=()=>{
    setDraftGrnViewDialog(false)
  }
  useEffect(()=>{
    let singleViewData=[]
    singleViewData=grnDraftList?.filter((obj)=>{
      if(obj._id===singleViewId){
        return obj;
      }
    })
    setSingleViewData(singleViewData)

  },[singleViewId])


  let navigate = useNavigate();
  const postDraftedProducts=(id)=>{
    localStorage.setItem('draftId',id)
    navigate('/userdashboard/purchasemanager/goodsReceivedNote/addGrn')
 }

 // delete drafted grn
 const deleteDraftedGrn=(id)=>{
  deleteDraftedGrnAPICall(
    id,
    updateListner,
    setSuccessSnackOpen,
    setErrorSnackOpen,
    setSnackMsg
    )
 }

 const editDraftedGrn=(id)=>{
   localStorage.setItem('draftId',id)
   localStorage.setItem('isEdit',true)
   navigate('/userdashboard/purchasemanager/goodsReceivedNote/addGrn')
 }

 const getSearchKeyword=(e)=>{
  setSearchKeyword(e.target.value)
}
const SearchFilter=(chars,listToFilter)=>{
  let searchedArray=[]
  let filteredArray=[]
  if(chars!==""){
    searchedArray=listToFilter.filter((obj)=>
    obj?.transNo?.toLowerCase()?.includes(chars?.toLowerCase())||
    obj?.branchId?.toLowerCase()?.includes(chars?.toLowerCase())||
    obj?.location?.toLowerCase()?.includes(chars?.toLowerCase())||
    obj?.supplierName?.toLowerCase()?.includes(chars?.toLowerCase())
  )
    filteredArray=searchedArray
  }
  else{
    filteredArray=listToFilter
  }
  return filteredArray
}
useEffect(()=>{
  if(grnDraftList!==undefined){
    let filteredArray=[]
    setFilteredSearchList(grnDraftList)
    filteredArray=SearchFilter(searchKeyword,grnDraftList)
    setFilteredSearchList(filteredArray)    
  }
  else{
    setFilteredSearchList([])
  }
  },[searchKeyword,grnDraftList])
  return (
    <>
    <div className="global-page-parent-container">
    <p className="breadcrumb">Purchase Manager &gt; Goods Received Note &gt; Draft</p>
     <div className='grn-draft-container'>
     <div className="stock-out-product-top-container" style={{alignItems:"center"}}>
          <h3>Draft</h3>
            <div className="view-order-search-container view-order-admin-search-container"
              style={{width:"25%"}}>
                  <input
                    placeholder="Search Transaction Id,Location,Creditor name"
                    value={searchKeyword}
                    onChange={getSearchKeyword}
                  />
                  <IconButton>
                      <i class="bi bi-search search-icon-order"></i>
                  </IconButton>
              </div>
          </div>
       <div className='global-table-container'>
        <table className='global-table'>
          <thead>
            <tr>
              <th>No</th>
              <th>Transaction ID</th>
              <th>Branch Code</th>
              <th>Branch Name</th>
              <th>Sales Invoice No</th>
              <th>Date</th>
              <th>Location</th>
              <th>Creditor</th>
              <th>View</th>
              <th>Edit</th>
              <th>Delete</th>
              <th></th>
            </tr>
          </thead>
          {
           isLoading?
           <tbody>
              <tr>
                <td colSpan={11}>
                  {/* <Loader /> */}
                  <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                  />
                </td>
                </tr> 
                <tr>
                <td colSpan={11}>
                  {/* <Loader /> */}
                  <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                  />
                </td>
                </tr> 
                <tr>
                <td colSpan={11}>
                  {/* <Loader /> */}
                  <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                  />
                </td>
                </tr> 
                <tr>
                <td colSpan={11}>
                  {/* <Loader /> */}
                  <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                  />
                </td>
                </tr>
            </tbody>
            :<tbody>
              {
                filteredSearchList?.length !==0 ?filteredSearchList?.slice(0)?.reverse()?.map((r,i)=>(
                <tr>
                <td>{i+1}</td>
                <td>{r?.transNo}</td>
                <td>{r?.branchId}</td>
                <td>{r?.locationName}</td>
                <td>{r?.salesInvoiceNo}</td>
                <td>{convertDateFormat(r?.date)}</td>
                <td>{r?.location}</td>
                <td>{r?.supplierName}</td>
                <td>
                <IconButton onClick={()=>openDraftGrnView(r?._id)}>
                  <i class="bi bi-eye visibility-icon"></i>
                </IconButton>
                </td>
                <td>
                <IconButton onClick={()=>editDraftedGrn(r?._id)}>
                   <i class="bi bi-pencil-square edit-icon"></i>
               </IconButton> 
                </td>
                <td>
                <IconButton onClick={deleteAlertMessage(r?._id,deleteDraftedGrn)}>
                 <i class="bi bi-trash3 delete-icon"></i>
                </IconButton>
                </td>
                <td><button onClick={()=>postDraftedProducts(r?._id)} className='grn-draft-post-btn'>Post</button></td>
              </tr>
                ))
                :
                <tr>
                  <td colSpan={12}>No Data</td>
                </tr>
              }
            </tbody>
          }
        </table>
       </div>
     </div>
    </div>

     {/* single view dialog */}
    <Dialog  open={graftGrnViewDialog} maxWidth="lg"  onKeyDown={(e)=>e.key === "Escape" && setDraftGrnViewDialog(false)}>
    <div className='grn-report-container'>
        <h3>GRN Draft View</h3>
        <div className='grn-report-input-container'>
            <div className='global-single-input'>
              <p>Transaction ID</p>
              <input value={singleViewData !== undefined && singleViewData[0]?.transNo}/>
            </div>
            <div className='global-single-input'>
              <p>Sales Invoice No</p>
              <input value={singleViewData !== undefined && singleViewData[0]?.salesInvoiceNo}/>
            </div>
            <div className='global-single-input'>
              <p>Date</p>
              <input value={singleViewData !== undefined && convertDateFormat(singleViewData[0]?.date)}/>
            </div>
            <div className='global-single-input'>
              <p>Location</p>
              <input value={singleViewData !== undefined && singleViewData[0]?.location}/>
            </div>
            <div className='global-single-input'>
              <p>Creditor</p>
              <input value={singleViewData !== undefined && singleViewData[0]?.supplierName}/>
            </div>
        </div>
       
       <div className='global-table-container grn-report-table-container'>
        <table className='global-table'>
            <thead>
                <tr>
                    <th>No</th>
                    <th>Product Code</th>
                    <th>Product Name</th>
                    <th>Unit</th>
                    <th>Order Qty</th>
                    <th>Received Qty</th>
                    <th>UP(FC)</th>
                    <th>UP(LC)</th>
                    <th>Amount</th>
                </tr>
            </thead>
            <tbody>
              {
                singleViewData !== undefined && singleViewData[0]?.purchaseInfo?.map((r,i)=>(
                  <tr>
                    <td>{i+1}</td>
                    <td>{r?.code}</td>
                    <td>{r?.productName}</td>
                    <td>{r?.unit}</td>
                    <td>{r?.orderQuantity}</td>
                    <td>{r?.recievedQuantity}</td>
                    <td>{r?.UPFC}</td>
                    <td>{r?.UPLC}</td>
                    <td>{parseFloat(r?.recievedQuantity)*parseFloat(r?.UPLC)}</td>
                </tr>
                ))
              }
                
               
            </tbody>
        </table>
       </div>
       <div className='grn-net-amount-container'>
         <div className='global-single-input grn-report-net-amount-input'>
            <p>Net Amount</p>
            <input
            //  placeholder='500.00'
            value={singleViewData !== undefined && singleViewData[0]?.amount}
            />
         </div>
       </div>
       <div className='grn-report-btn'>
       <button onClick={closeGrnView} className='btn btn-secondary-outlined'>Cancel</button>
       </div>
       </div>

    </Dialog>

     {/* snackbar */}
     <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />

      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </>
  )
}
