import {createSlice} from '@reduxjs/toolkit'

export const shiftStatusSlice=createSlice({
    name:"shiftStatusSlice",
    initialState:{
        value:undefined,
        employeeData:undefined
    },
    reducers:{
        update_shift_status:(state,action)=>{
            state.value=action.payload.shiftStatus
        },
        get_employeelist_by_branch:(state,action)=>{
            state.employeeData=action.payload.empoloyeeBranchData
        }
    }
})
export const {update_shift_status,get_employeelist_by_branch} = shiftStatusSlice.actions;
export default shiftStatusSlice.reducer;