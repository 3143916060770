import {createSlice} from '@reduxjs/toolkit'

export const generatePurchasewopoIdSlice=createSlice({
    name:'generatePurchsewopoId',
    initialState:{
        value:undefined
    },
    reducers:{
        get_generate_purchasewopo_id:(state,action)=>{
            state.value=action.payload.purchaseWoPoId
        }
    }
})
export const {get_generate_purchasewopo_id}=generatePurchasewopoIdSlice.actions;
export default generatePurchasewopoIdSlice.reducer