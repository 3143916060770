import React from "react";
import "../../css/Login/login.css";
import dashboardImage from "../../Assets/Images/dashboard.png";
import Logo from "../../Assets/Images/Logo.png";
const LoginRightPanel = (props) => {
  const { companyName, companyLogo } = props;
  return (
    <div className="child-container right">
      {companyLogo !== undefined && (
        <div className="user-company-details-container">
          <div className="user-company-name">
            <p>{companyName}</p>
          </div>
          <div className="user-company-logo">
            {companyLogo!==null?<img src={companyLogo} alt="user logo" />:'No Company Image'}
          </div>
        </div>
      )}

      <div className="logo-dashboard-image-container">
        <div className="logo-dashboard-image">
          <img src={Logo} alt="logo" />
        </div>
        <div className="dashboard-image">
          <img src={dashboardImage} alt="dashboard-img" />
        </div>
      </div>
    </div>
  );
};

export default LoginRightPanel;
