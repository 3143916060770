import {createSlice} from '@reduxjs/toolkit'

export const stockAdjSingleViewSlice=createSlice({
    name:"stockAdjSingleView",
    initialState:{
        value:undefined
    },
    reducers:{
        get_stock_adj_single_view:(state,action)=>{
            state.value=action.payload.stockAdjSingleViewData
        }
    }
})
export const {get_stock_adj_single_view} =stockAdjSingleViewSlice.actions;

export default stockAdjSingleViewSlice.reducer;