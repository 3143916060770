import React, { useEffect, useState } from "react";
import "../../../../../css/Account/createInvoice/accounting.css";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import accountingCreateInvoice from "../../../../../Assets/JSON/accountingCreateInvoice.json";
import currencies from "../../../../../Assets/JSON/currencies.json";
import ContraVoucher from "./accountingGroup/BankInvoice";
import PaymentVoucher from "./accountingGroup/AccountPayable";
import ReceiptVoucher from "./accountingGroup/AccountReceive";
import PurchaseVoucher from "./accountingGroup/PurchaseInvoice";
import PurchaseReturnVoucher from "./accountingGroup/PurchaseReturnInvoice";
import SalesVoucher from "./accountingGroup/SalesInvoice";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import DepreciationVoucher from "./accountingGroup/journal/DepreciationInvoice";
import JournalVoucher from "./accountingGroup/journal/JournalInvoice";
import SalesReturnVoucher from "./accountingGroup/SalesReturnInvoice";

const Accounting = () => {
  const [active, setActive] = useState(0);
  const [selectListItem, setSelectListItem] = useState({ id: null, name: "" });
  const [open, setOpen] = useState(false);
  const [dropListSelected, setDropListSelected] = useState(1);
  const [dropListSelectedName, setDropListSelectedName] = useState(
    "Depreciation Voucher"
  );
  const renderData = [
    {
      glAccountCode: 1000,
      glAccount: "rent",
      debit: 5000,
      credit: 2000,
      debitBalance: 3000,
      creditBalance: 800,
    },
    {
      glAccountCode: 8000,
      glAccount: "sbi",
      debit: 7000,
      credit: 9000,
      debitBalance: 3000,
      creditBalance: 1100,
    },
    {
      glAccountCode: 7000,
      glAccount: "cash",
      debit: 3000,
      credit: 2000,
      debitBalance: 3000,
      creditBalance: 2000,
    },
  ];
  const headings = [
    "No",
    "G/L Account Code",
    "G/L Account",
    "Debit",
    "Credit",
    "Debit Balance",
    "Credit Balance",
  ];

  useEffect(() => {
    handleListItemClick(0, 1, "Bank Invoice");
  }, []);

  const handleListItemClick = (index, id, name) => {
    setActive(index);
    if (index === 7) {
      setOpen(!open);
    } else {
      setOpen(false);
    }

    // console.log(index,id,name);
    setSelectListItem({ id, name });
  };

  const handleDropDownClick = (id, name) => {
    setDropListSelected(id);
    setDropListSelectedName(name);
  };

  const listItemTextStyle = () => {
    return {
      fontWeight: "600",
      fontSize: "0.9rem",
    };
  };

  return (
    <>
      <div className="global-page-parent-container">
        <p className="breadcrumb">
          Accounts &gt; Create invoice &gt; Accounting &gt; {selectListItem.name}{" "}
          {open && `> ${dropListSelectedName}`}
        </p>
        <div className="accounting-container">
          <div className="accounting-side-menu">
            <List
              sx={{
                width: "100%",

                bgcolor: "background.paper",
                marginBlockStart: "10px",
              }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              {accountingCreateInvoice.map(({ id, name }, index) => (
                <>
                  <ListItemButton
                    key={index}
                    sx={{ paddingLeft: "2em" }}
                    className={active === index ? "isactive" : ""}
                    onClick={() => handleListItemClick(index, id, name)}
                  >
                    <ListItemText
                      primary={
                        <Typography sx={{ ...listItemTextStyle() }}>
                          {name}
                        </Typography>
                      }
                    /> 

                    {index === 7 && (open ? <ExpandLess /> : <ExpandMore />)}
                  </ListItemButton>
                  {index === 7 && (
                    <Collapse in={open} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItemButton
                          sx={{ pl: 6 }}
                          onClick={() =>
                            handleDropDownClick(1, "Depreciation Voucher")
                          }
                          className={1 === dropListSelected ? "isactive" : ""}
                        >
                          <ListItemText
                            primary={
                              <Typography sx={{ ...listItemTextStyle() }}>
                                Depreciation Invoice
                              </Typography>
                            }
                          />
                        </ListItemButton>
                        <ListItemButton
                          sx={{ pl: 6 }}
                          onClick={() =>
                            handleDropDownClick(2, "Journal Voucher")
                          }
                          className={2 === dropListSelected ? "isactive" : ""}
                        >
                          <ListItemText
                            primary={
                              <Typography sx={{ ...listItemTextStyle() }}>
                                Journal Invoice
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Collapse>
                  )}
                </>
              ))}
            </List>
          </div>
          <div className="accounting-voucher-menu">
            {selectListItem.id === 1 && (
              <ContraVoucher
                currencies={currencies}
                headings={headings}
                renderData={renderData}
                selectedVoucher={selectListItem.name}
              />
            )}
            {selectListItem.id === 2 && (
              <PaymentVoucher
                headings={headings}
                renderData={renderData}
                selectedVoucher={selectListItem.name}
              />
            )}
            {selectListItem.id === 3 && (
              <ReceiptVoucher
                headings={headings}
                renderData={renderData}
                selectedVoucher={selectListItem.name}
              />
            )}
            {selectListItem.id === 4 && (
              <PurchaseVoucher
                headings={headings}
                renderData={renderData}
                selectedVoucher={selectListItem.name}
              />
            )}
            {selectListItem.id === 5 && (
              <PurchaseReturnVoucher
                headings={headings}
                renderData={renderData}
                selectedVoucher={selectListItem.name}
              />
            )}
            {selectListItem.id === 6 && (
              <SalesVoucher
                headings={headings}
                renderData={renderData}
                selectedVoucher={selectListItem.name}
              />
            )}
            {selectListItem.id === 7 && (
              <SalesReturnVoucher
                headings={headings}
                renderData={renderData}
                selectedVoucher={selectListItem.name}
              />
            )}
            {selectListItem.id === 8 && (
              <>
                {dropListSelected === 1 && (
                  <DepreciationVoucher
                    headings={headings}
                    renderData={renderData}
                    selectedVoucher={dropListSelectedName}
                  />
                )}
                {dropListSelected === 2 && (
                  <JournalVoucher
                    headings={headings}
                    renderData={renderData}
                    selectedVoucher={dropListSelectedName}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Accounting;