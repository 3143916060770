import { createSlice } from "@reduxjs/toolkit";

export const allAccessorySubCategorySlice=createSlice({
    name:'allAccessorySubCategorySlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_accessory_subCategory_list:(state,action)=>{
            state.value=action.payload.accessorySubCategory
        }
    }
})

export const {update_accessory_subCategory_list}=allAccessorySubCategorySlice.actions
export default allAccessorySubCategorySlice.reducer