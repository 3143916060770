import { createSlice } from "@reduxjs/toolkit";

export const paymentListSlice=createSlice({
    name:'utilityPaymentList',
    initialState:{
        value:undefined
    },
    reducers:{
        get_utility_payment_list:(state,action)=>{
            state.value=action.payload.utilityPaymentData
        }
    }
})
export const {get_utility_payment_list} = paymentListSlice.actions;
export default paymentListSlice.reducer