import axios from "axios"
import { HEADERS } from "../../UrlAndPaths"
import { logOutUser } from "../../../Js/generalFunctions";
// import { useSelector } from "react-redux";
// const userRole = useSelector((state) => state.userRoleSlice.value);

export const changePasswordAPICall=(body,setState,state)=>{
    axios.put("admin/changeadminpassword",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
          setState({...state,message:"Success",success:true})
           logOutUser(true,"admin")
        localStorage.clear();
        window.location.replace("/");
        }
    })
    .catch((err)=>{
        if (err.response.status === 500) {
            setState({ ...state, message: "Internal Server error !", error: true })
          } else {
            setState({ ...state, message: err?.response?.data, error: true })
          }
    })
}