import { createSlice } from "@reduxjs/toolkit";

export const taxSlice=createSlice({
    name:'taxSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_tax_slice:(state,action)=>{
            state.value=action.payload.tax
        }
    }
})

export const {update_tax_slice}=taxSlice.actions
export default taxSlice.reducer