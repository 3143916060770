import { createSlice } from "@reduxjs/toolkit";

export const thobModelDataSlice=createSlice({
    name:"thobModelDataSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        update_thobModel:(state,action)=>{
            state.value=action.payload.thobModel
        }
    }
})

export const {update_thobModel}=thobModelDataSlice.actions
export default thobModelDataSlice.reducer