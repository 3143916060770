import { createSlice } from "@reduxjs/toolkit";

export const sizeChartSlice=createSlice({
    name:'sizeChartSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_size_chart:(state,action)=>{
            state.value=action.payload.sizeChart
        }
    }
})

export const {update_size_chart}=sizeChartSlice.actions
export default sizeChartSlice.reducer