import { createSlice } from "@reduxjs/toolkit";

export const toLocationStockSlice=createSlice({
    name:'toLocationStockSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_toLocation_stock:(state,action)=>{
            state.value=action.payload.toLocStock
        }
    }
})

export const {update_toLocation_stock}=toLocationStockSlice.actions
export default toLocationStockSlice.reducer