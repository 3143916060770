import { createSlice } from "@reduxjs/toolkit";

export const glConfigurationSlice = createSlice({
  name: "glConfigurationSlice",
  initialState: {
    apiResList: undefined,
  },
  reducers: {
    get_config_api_res_list: (state, action) => {
      state.apiResList = action.payload.resData;
    }
  },
});

export const { get_config_api_res_list} = glConfigurationSlice.actions;

export default glConfigurationSlice.reducer;
