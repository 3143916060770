import { createSlice } from "@reduxjs/toolkit";

export const jobCompletionFilterResSlice=createSlice({
    name:'jobCompletionFilterResSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_jc_filter_result:(state,action)=>{
            state.value=action.payload.jcFilterRes
        }
    }
})
export const {update_jc_filter_result} = jobCompletionFilterResSlice.actions
export default jobCompletionFilterResSlice.reducer