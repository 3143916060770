import {
  Autocomplete,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import "../../../css/Offer/addOffer.css";
import dummyImg from "../../../Assets/Images/upload-image.png";
import { FilePicker } from "../../Single Components/filePicker";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  viewAllAccessoryCategoryAPICall,
  viewAllAccessorySubCategoryAPICall,
  viewAllMaterialCategoryAPICall,
  viewAllReadymadeCategoriesAPICall,
  viewAllReadymadeSubCategoryAPICall,
} from "../../../API/Product Master/ProductMasterAPI";
import { viewAllProductsAPICall } from "../../../API/Product List/productListAPI";
import { viewAllBranchesAPICall } from "../../../API/Settings/Company Settings/companySettingsAPI";
import { addOfferAPICall } from "../../../API/Offer/OfferAPI";
import SuccessSnackbar from "../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../Single Components/SnackBars/ErrorSnackbar";
import { today } from "../../../Js/Date";

export const AddOffer = (props) => {
  const { breadcrumb, width, closeDialog, updateListener } = props;
  const userRole = useSelector((state) => state.userRoleSlice.value);

  const [breadCrumbCheck, setBreadCrumbCheck] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [offerSelected, setOfferSelected] = useState("one");
  const [showGender, setShowGender] = useState(true);
  const [showCategory, setShowCategory] = useState(true);
  const [showSubcategory, setShowSubCategory] = useState(true);
  const [showOccassion, setShowOccassion] = useState(true);
  const [showProduct, setShowProduct] = useState(true);
  const [showDimension, setShowDimension] = useState(true);
  const [constant, setConstant] = useState("true");
  const [showPercentage, setShowPercentage] = useState(true);
  const [showBuyandGet, setShowBuyandGet] = useState(false);
  const [showQuantity, setShowQuantity] = useState(false);
  const [selectedMaterialGender, setSelectedMaterialGender] = useState("0");
  const [selectedReadymadeGender, setSelectedReadymadeGender] = useState("0");
  const [selectReadymadeCatId, setSelectReadyMadeCatId] = useState("0");
  const [selectMaterialCatId, setSelectMaterialCatId] = useState("0");
  const [selectAccessoryCatId, setSelectAccessoryCatId] = useState("0");
  const [selectedReadymadeSubCategory, setSelectedReadymadeSubCategory] =
    useState("0");
  const [selectedAccesorySubCategory, setSelectedAccessorySubCategory] =
    useState("0");
  const [selectMaterialProduct, setSelectMaterialProduct] = useState("0");
  const [selectAccessoryProduct, setSelectAccessoryProduct] = useState("0");
  const [selectedReadymadeSize, setSelectReadymadeSize] = useState([]);

  const [conditionsArray, setConditionsArray] = useState([]);

  // validationstates
  const [branchAlert, setBranchAlert] = useState(false);
  const [productAlert, setProductAlert] = useState(false);
  const [sizeAlert, setSizeAlert] = useState(false);
  const [startDateAlert, setStartDateAlert] = useState(false);
  const [endDateAlert, setEndDateAlert] = useState(false);
  const [filteredProductData, setFilteredProductData] = useState([]);
  const [showTrue, setShowTrue] = useState(true);
  const [branchName, setBranchName] = useState(null);
  const [snackMsg, setSnackMsg] = useState("oops!!!");
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);

  // All  Category List
  const readyMadeCategoryList = useSelector(
    (state) => state.allReadymadeCategories.value
  );
  const allMaterialCatList = useSelector(
    (state) => state.allMaterialCategorySlice.value
  );
  const allAccessoryCatList = useSelector(
    (state) => state.allAccessoryCategorySlice.value
  );
  // All subcategory list
  const readyMadeSubCategoryList = useSelector(
    (state) => state.allReadymadeSubCategorySlice.value
  );
  const allAccessorySubCatList = useSelector(
    (state) => state.allAccessorySubCategorySlice.value
  );
  //   All ProductList
  const allProductList = useSelector(
    (state) => state.allProductsListSlice.value
  );
  const listOfBranchesStockOut = useSelector(
    (state) => state.allBranchesSlice.value
  );
  const storeCode=localStorage.getItem('branchId')

  // initial form body

  const addOfferFormInitialState = {
    branchId: "",
    type: 0,
    dimension: "",
    gender: null,
    category: "",
    subcategory: "",
    productId: "",
    dimension: "",
    offerType: "percentage",
    percentage: "",
    buyGet: "null",
    get: "null",
    quantity: "null",
    price: "",
    startDate: today,
    endDate: "",
    conditions: "",
  };
  const [addOfferForm, setAddOfferForm] = useState(addOfferFormInitialState);
  // pay load body for Add Offer
  const addOfferPayloadBody = {
    branchId: userRole==="admin"? addOfferForm.branchId:storeCode,
    offerType: addOfferForm.type,
    genderType: addOfferForm.gender,
    category: addOfferForm.category,
    dimension: addOfferForm.dimension,
    subcategory: addOfferForm.subcategory,
    occassion: "null",
    productId: addOfferForm.productId,
    offer: {
      offerTypes: addOfferForm.offerType,
      percentage:
        addOfferForm.offerType === "percentage"
          ? [addOfferForm.percentage]
          : null,
      buyGet:
        addOfferForm.offerType === "buyget"
          ? { buy: addOfferForm.buyGet, get: addOfferForm.get }
          : null,
      quantity:
        addOfferForm.offerType === "quantity"
          ? { quantity: addOfferForm.quantity, price: addOfferForm.price }
          : null,
    },
    startDate: addOfferForm.startDate,
    endDate: addOfferForm.endDate,
    conditions: conditionsArray,
  };
  // console.log(addOfferPayloadBody);
  const percentageAmt =
    addOfferForm.price !== undefined &&
    addOfferForm.price - (addOfferForm.percentage / 100) * addOfferForm.price;
  const QuantityAmt = addOfferForm.price;
  const BuyGetAmt = addOfferForm.buyGet * addOfferForm.price;

  const checkBoxClicked = () => {
    document.getElementById("selectFestival").classList.toggle("disable");
    setShowTrue(!showTrue);
  };

  const handleChangeCategory = (event) => {
    setSelectedCategory(event.target.value);
    if (event.target.value === 0) {
      setShowGender(true);
      setShowCategory(true);
      setShowSubCategory(true);
      setShowOccassion(true);
      setShowProduct(true);
      setShowDimension(true);
      setAddOfferForm({ ...addOfferForm, type: 0 });
    } else if (event.target.value === 2) {
      setShowGender(true);
      setShowCategory(true);
      setShowSubCategory(false);
      setShowOccassion(false);
      setShowProduct(true);
      setShowDimension(false);
      setAddOfferForm({ ...addOfferForm, type: 2 });
    } else if (event.target.value === 1) {
      setShowGender(false);
      setShowCategory(true);
      setShowSubCategory(true);
      setShowOccassion(false);
      setShowProduct(true);
      setShowDimension(true);
      setAddOfferForm({ ...addOfferForm, type: 1 });
    }
  };
  const handlechangeOffer = (event) => {
    setOfferSelected(event.target.value);
    if (event.target.value === "one") {
      setAddOfferForm({ ...addOfferForm, offerType: "percentage" });
      setShowPercentage(true);
      setShowBuyandGet(false);
      setShowQuantity(false);
    } else if (event.target.value === "two") {
      setAddOfferForm({ ...addOfferForm, offerType: "buyget" });
      setShowPercentage(false);
      setShowBuyandGet(true);
      setShowQuantity(false);
    }
    if (event.target.value === "three") {
      setAddOfferForm({ ...addOfferForm, offerType: "quantity" });
      setShowPercentage(false);
      setShowBuyandGet(false);
      setShowQuantity(true);
    }
  };

  //Get Material Gender
  const getGender = (key) => (e) => {
    if (key === "materialGender") {
      setSelectedMaterialGender(e.target.value);
      setAddOfferForm({ ...addOfferForm, gender: e.target.value });
    }
    if (key === "readymadeGender") {
      setSelectedReadymadeGender(e.target.value);
      setAddOfferForm({ ...addOfferForm, gender: e.target.value });
    }
  };

  const getSelectedCatId = (key) => (e) => {
    if (key === "materialCategory") {
      setSelectMaterialCatId(e.target.value);
      setAddOfferForm({ ...addOfferForm, category: e.target.value });
    }
    if (key === "readymadeCategory") {
      setSelectReadyMadeCatId(e.target.value);
      setAddOfferForm({ ...addOfferForm, category: e.target.value });
    }
    if (key === "accessoryCategory") {
      setSelectAccessoryCatId(e.target.value);
      setAddOfferForm({ ...addOfferForm, category: e.target.value });
    }
  };

  const getSubCategory = (key) => (e) => {
    if (key === "readymadeSubCat") {
      setSelectedReadymadeSubCategory(e.target.value);
      setAddOfferForm({ ...addOfferForm, subcategory: e.target.value });
    }
    if (key === "accessorySubCat") {
      setSelectedAccessorySubCategory(e.target.value);
      setAddOfferForm({ ...addOfferForm, subcategory: e.target.value });
    }
  };

  const getSelectedProduct = (key) => (e) => {
    if (key === "readymadeProduct") {
      setAddOfferForm({ ...addOfferForm, productId: e.target.value });
    }
    if (key === "accessoryProduct") {
      setSelectAccessoryProduct(e.target.value);
      setAddOfferForm({ ...addOfferForm, productId: e.target.value });
    }
    if (key === "materialProduct") {
      setSelectMaterialProduct(e.target.value);
      setAddOfferForm({ ...addOfferForm, productId: e.target.value });
    }
    setProductAlert(false);
  };

  const getSelectedSize = (key) => (e, newValue) => {
    setSizeAlert(false);
    if (key === "readymadeSize") {
      setAddOfferForm({ ...addOfferForm, dimension: newValue.brandUnit });
    }
    if (key === "accessorySize") {
      setAddOfferForm({ ...addOfferForm, dimension: newValue.brandUnit });
    }
    if (addOfferForm.dimension === false) {
      setSizeAlert(true);
    }
  };

  const productSelected = (id) => (e) => {
    let filteredData = [];
    filteredData = allProductList?.filter((obj) => {
      if (obj._id === id) {
        return obj;
      }
    });

    setFilteredProductData(filteredData);
  };

  const clearStates = () => {
    setAddOfferForm(addOfferFormInitialState);
    setBranchName(null);
    setConditionsArray([]);
    setSelectMaterialProduct("0");
    setSelectAccessoryProduct("0");
    setSelectedMaterialGender("0");
    setSelectedReadymadeGender("0");
  };
  const closeSuccessSnack = () => {
    setSuccessSnackOpen(false);
  };
  const closeErrorSnack = () => {
    setErrorSnackOpen(false);
  };

  const clickAddOfferSubmit = () => {
    if (userRole==="admin" && addOfferForm.branchId === "" || addOfferForm.branchId === false) {
      setBranchAlert(true);
    } else if (addOfferForm.productId === "") {
      setProductAlert(true);
    }
    // else if(addOfferForm.dimension==="" || addOfferForm.dimension===false){
    //     setSizeAlert(true)
    // }
    else if (addOfferForm.startDate === "") {
      setStartDateAlert(true);
    } else if (addOfferForm.endDate === "") {
      setEndDateAlert(true);
    } else {
      clearStates();
      addOfferAPICall(
        addOfferPayloadBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg
      );
      console.log(addOfferPayloadBody);
      closeDialog();
      updateListener();
    }
  };

  const clickAddCondition = () => {
    if (addOfferForm.conditions !== "") {
      setConditionsArray([...conditionsArray, addOfferForm.conditions]);
      setAddOfferForm({ ...addOfferForm, conditions: "" });
    }
  };
  const deleteTableRow = (index) => {
    conditionsArray.splice(index, 1);
    setConditionsArray([...conditionsArray]);
  };

  useEffect(() => {
    let filteredData = [];
    filteredData =
      filteredProductData !== undefined &&
      filteredProductData[0]?.size?.filter((obj) => {
        if (obj.brandUnit === addOfferForm.dimension) {
          return obj;
        }
      });
    setSelectReadymadeSize(filteredData);
  }, [addOfferForm.dimension]);

  useEffect(() => {
    if (breadcrumb === "false") {
      setBreadCrumbCheck(false);
    }
  }, [breadcrumb]);

  useEffect(() => {
    if (showTrue === true) {
      setConstant("true");
    } else {
      setConstant("false");
    }
  }, [checkBoxClicked]);

  useEffect(() => {
    setAddOfferForm({
      ...addOfferForm,
      branchId: branchName?.storeCode !== undefined && branchName.storeCode,
    });
  }, [branchName]);

  // call view category API
  useEffect(() => {
    viewAllReadymadeCategoriesAPICall();
  }, [selectReadymadeCatId]);
  useEffect(() => {
    viewAllMaterialCategoryAPICall();
  }, [selectMaterialCatId]);
  useEffect(() => {
    viewAllAccessoryCategoryAPICall();
  }, [selectAccessoryCatId]);

  // call view sub-category API
  useEffect(() => {
    viewAllAccessorySubCategoryAPICall({
      accessoryCategoryId: selectAccessoryCatId,
    });
  }, [selectAccessoryCatId, selectedAccesorySubCategory]);
  useEffect(() => {
    viewAllReadymadeSubCategoryAPICall();
  }, [selectedReadymadeSubCategory, selectReadymadeCatId]);

  useEffect(() => {
    setAddOfferForm({
      ...addOfferForm,
      price: filteredProductData[0]?.calculatedPrice,
    });
  }, [filteredProductData]);

  useEffect(() => {
    setAddOfferForm({
      ...addOfferForm,
      price:
        selectedReadymadeSize !== undefined &&
        selectedReadymadeSize[0]?.calculatedPrice,
    });
  }, [selectedReadymadeSize]);

  //   call view products API

  useEffect(() => {
    viewAllProductsAPICall({
      type: selectedCategory,
      branchId:storeCode
    });
  }, [selectedCategory]);

  useEffect(() => {
    viewAllBranchesAPICall();
  }, []);
  return (
    <>
      <div className="global-page-parent-container">
        {breadCrumbCheck ? (
          <p className="breadcrumb">Offer &gt; Add Offer</p>
        ) : (
          ""
        )}
        <div className="add-offer-container" style={width}>
          <h3>Add Offer</h3>
          <hr className="global-hr" />
          {
            userRole==="admin"?
            <div
            className="global-single-input auto-complete validation-input-offer"
            style={{ width: "35%" }}
            >
            <Autocomplete
              sx={{ width: "100%" }}
              options={
                listOfBranchesStockOut || [
                  { branchName: "No Branches Available" },
                ]
              }
              getOptionLabel={(option) => option?.branchName}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Branch" />
              )}
              onChange={(e, newValue) => {
                setBranchName(newValue);
                setBranchAlert(false);
              }}
            />
            {branchAlert ? (
              <p className="doc-validation-alert">Select A Branch</p>
            ) : (
              ""
            )}
          </div>
            :""
          }
          
          <div className="add-offer-container-top">
            <div className="global-single-input">
              <p>Main Category</p>
              <Select value={selectedCategory} onChange={handleChangeCategory}>
                <MenuItem value={0}>ReadyMade</MenuItem>
                <MenuItem value={2}>Material</MenuItem>
                <MenuItem value={1}>Accessories</MenuItem>
              </Select>
            </div>
            {showGender ? (
              <div className="global-single-input">
                <p>Gender</p>

                {selectedCategory === 2 ? (
                  <Select
                    value={selectedMaterialGender}
                    onChange={getGender("materialGender")}
                  >
                    <MenuItem value="0" disabled>
                      -select gender-
                    </MenuItem>
                    <MenuItem value="male">MALE</MenuItem>
                    <MenuItem value="female">FEMALE</MenuItem>
                  </Select>
                ) : selectedCategory === 0 ? (
                  <Select
                    value={selectedReadymadeGender}
                    onChange={getGender("readymadeGender")}
                  >
                    <MenuItem value="0" disabled>
                      -select gender-
                    </MenuItem>
                    <MenuItem value="male">MALE</MenuItem>
                    <MenuItem value="female">FEMALE</MenuItem>
                    <MenuItem value="boy">BOY</MenuItem>
                    <MenuItem value="girl">GIRL</MenuItem>
                  </Select>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {showCategory ? (
              <div className="global-single-input">
                <p>Category</p>
                {selectedCategory === 2 ? (
                  <Select
                    value={addOfferForm.category}
                    onChange={getSelectedCatId("materialCategory")}
                  >
                    <MenuItem value="0" disabled>
                      -select category-
                    </MenuItem>
                    {allMaterialCatList !== undefined ? (
                      allMaterialCatList?.map((r, i) => (
                        <MenuItem value={r?._id} key={i}>
                          {r?.materialCategoryName}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                ) : selectedCategory === 0 ? (
                  <Select
                    value={addOfferForm.category}
                    onChange={getSelectedCatId("readymadeCategory")}
                  >
                    <MenuItem value="0" disabled>
                      -select category-
                    </MenuItem>

                    {readyMadeCategoryList !== undefined ? (
                      readyMadeCategoryList?.map((r, i) => (
                        <MenuItem value={r?._id} key={i}>
                          {r?.categoryName}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                ) : selectedCategory === 1 ? (
                  <Select
                    // value={selectAccessoryCatId}
                    value={addOfferForm.category}
                    onChange={getSelectedCatId("accessoryCategory")}
                  >
                    {allAccessoryCatList !== undefined ? (
                      allAccessoryCatList?.map((r, i) => (
                        <MenuItem value={r?._id} key={i}>
                          {r?.accessoryCategory}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {showSubcategory ? (
              <div className="global-single-input validation-input-offer">
                <p>Sub Category</p>
                {selectedCategory === 0 ? (
                  <Select
                    value={addOfferForm.subcategory}
                    onChange={getSubCategory("readymadeSubCat")}
                  >
                    <MenuItem value="0" disabled>
                      -select sub-category-
                    </MenuItem>
                    {readyMadeSubCategoryList !== undefined ? (
                      readyMadeSubCategoryList
                        ?.slice(0)
                        .reverse()
                        ?.map((item, i) => {
                          if (item?.categoryId === selectReadymadeCatId) {
                            return (
                              <MenuItem value={item?._id} key={item?._id}>
                                {item?.subCategoryName}
                              </MenuItem>
                            );
                          }
                        })
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                ) : selectedCategory === 1 ? (
                  <Select
                    value={addOfferForm.subcategory}
                    onChange={getSubCategory("accessorySubCat")}
                  >
                    <MenuItem value="0" disabled>
                      -select sub-category-
                    </MenuItem>
                    {allAccessorySubCatList !== undefined ? (
                      allAccessorySubCatList
                        ?.slice(0)
                        .reverse()
                        ?.map((item, i) => {
                          if (
                            item?.accessoryCategoryId === selectAccessoryCatId
                          ) {
                            return (
                              <MenuItem value={item?._id} key={item?._id}>
                                {item?.accessorySubCategory}
                              </MenuItem>
                            );
                          }
                        })
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {/* {showOccassion?<div className="global-single-input">
                            <p>Occassion</p>
                            <Select>
                                <MenuItem>ReadyMade</MenuItem>
                                <MenuItem>ReadyMade</MenuItem>
                            </Select>
                        </div>:""} */}
            {showProduct ? (
              <div className="global-single-input validation-input-offer">
                <p>Product</p>
                {selectedCategory === 0 ? (
                  <Select
                    value={addOfferForm.productId}
                    onChange={getSelectedProduct("readymadeProduct")}
                  >
                    <MenuItem value="0" disabled>
                      -select a product-
                    </MenuItem>
                    {allProductList !== undefined ? (
                      allProductList
                        ?.slice(0)
                        .reverse()
                        ?.map((item, i) => {
                          if (
                            item?.subCategoryId === selectedReadymadeSubCategory
                          ) {
                            return (
                              <MenuItem
                                value={item?._id}
                                key={item?._id}
                                onClick={productSelected(item?._id)}
                              >
                                {item?.productName}
                              </MenuItem>
                            );
                          }
                        })
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                ) : selectedCategory === 2 ? (
                  <Select
                    value={selectMaterialProduct}
                    onChange={getSelectedProduct("materialProduct")}
                  >
                    <MenuItem value="0" disabled>
                      -select a product-
                    </MenuItem>
                    {allProductList !== undefined ? (
                      allProductList
                        ?.slice(0)
                        .reverse()
                        ?.map((item, i) => {
                          if (item?.categoryId === selectMaterialCatId) {
                            return (
                              <MenuItem
                                value={item?._id}
                                key={item?._id}
                                onClick={productSelected(item?._id)}
                              >
                                {item?.productName}
                              </MenuItem>
                            );
                          }
                        })
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                ) : selectedCategory === 1 ? (
                  <Select
                    value={selectAccessoryProduct}
                    onChange={getSelectedProduct("accessoryProduct")}
                  >
                    <MenuItem value="0" disabled>
                      -select a product-
                    </MenuItem>
                    {allProductList !== undefined ? (
                      allProductList
                        ?.slice(0)
                        .reverse()
                        ?.map((item, i) => {
                          if (
                            item?.subCategoryId === selectedAccesorySubCategory
                          ) {
                            return (
                              <MenuItem
                                value={item?._id}
                                key={item?._id}
                                onClick={productSelected(item?._id)}
                              >
                                {item?.productName}
                              </MenuItem>
                            );
                          }
                        })
                    ) : (
                      <MenuItem>No Data</MenuItem>
                    )}
                  </Select>
                ) : (
                  ""
                )}
                {productAlert ? (
                  <p className="doc-validation-alert">Select A Product</p>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {showDimension ? (
              <div className="global-single-input auto-complete validation-input-offer">
                <p>Size</p>
                {selectedCategory === 0 ? (
                  <Autocomplete
                    sx={{ width: "100%" }}
                    options={
                      (filteredProductData !== undefined &&
                        filteredProductData[0]?.size) || [
                        { brandUnit: "No Data" },
                      ]
                    }
                    getOptionLabel={(option) => option?.brandUnit}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Size" />
                    )}
                    onChange={getSelectedSize("readymadeSize")}
                  />
                ) : selectedCategory === 1 ? (
                  <Autocomplete
                    sx={{ width: "100%" }}
                    options={
                      (filteredProductData !== undefined &&
                        filteredProductData[0]?.size) || [
                        { brandUnit: "No Data" },
                      ]
                    }
                    getOptionLabel={(option) => option?.brandUnit}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Size" />
                    )}
                    onChange={getSelectedSize("accessorySize")}
                  />
                ) : (
                  ""
                )}
                {sizeAlert ? (
                  <p className="doc-validation-alert">Select A Size</p>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="add-offer-product-details-ctn">
            <h3>Product Details</h3>
            <hr className="global-hr" />
            <div className="add-offer-product-details-body">
              <div className="add-offer-product-details-body-left">
                <p>Image</p>
                {addOfferForm.productId === "" ? (
                  <img src={dummyImg} />
                ) : (
                  <img
                    src={
                      filteredProductData[0]?.imageUrl !== null &&
                      filteredProductData[0]?.imageUrl[0]
                    }
                    alt="productImg"
                  />
                )}
              </div>
              <div className="add-offer-product-details-body-right">
                <h3>
                  Product ID
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                  {filteredProductData[0]?.productId}
                </h3>
                {selectedCategory === 2 ? (
                  <h3>
                    Product Price&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                    {filteredProductData[0]?.calculatedPrice}
                  </h3>
                ) : (
                  <h3>
                    Product Price&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                    {selectedReadymadeSize !== undefined &&
                      selectedReadymadeSize[0]?.calculatedPrice}
                  </h3>
                )}
              </div>
            </div>
          </div>

          <div className="add-offer-product-choose-offer">
            <div className="global-single-input">
              <p>Choose Offer</p>
              <Select value={offerSelected} onChange={handlechangeOffer}>
                <MenuItem value="one">Percentage</MenuItem>
                <MenuItem value="two">Buy And get</MenuItem>
                <MenuItem value="three">Quantity</MenuItem>
              </Select>
            </div>
            {showPercentage ? (
              <div className="global-single-input">
                <p>Add Percentage (%)</p>
                <input
                  type="number"
                  value={addOfferForm.percentage}
                  onChange={(e) =>
                    setAddOfferForm({
                      ...addOfferForm,
                      percentage: e.target.value,
                    })
                  }
                  min="0"
                  max="100"
                  placeholder="0%"
                />
              </div>
            ) : (
              ""
            )}

            {showBuyandGet ? (
              <div className="product-choose-offer-buy-and-get-ctn">
                <div className="product-choose-offer-buy-and-get">
                  <div className="global-single-input">
                    <p>Buy</p>
                    <input
                      type="number"
                      placeholder="0"
                      value={addOfferForm.buyGet}
                      onChange={(e) =>
                        setAddOfferForm({
                          ...addOfferForm,
                          buyGet: e.target.value,
                        })
                      }
                      min="0"
                    />
                  </div>
                  <div className="global-single-input">
                    <p>Get</p>
                    <input
                      type="number"
                      placeholder="$0"
                      value={addOfferForm.get}
                      onChange={(e) =>
                        setAddOfferForm({
                          ...addOfferForm,
                          get: e.target.value,
                        })
                      }
                      min="0"
                    />
                  </div>

                  {/* <div className="product-choose-offer-buy-and-get-circle">
                                +
                            </div> */}
                </div>

                <h4 style={{ color: "red" }}>
                  Current Amount &nbsp;&nbsp;&nbsp;&nbsp;:{" "}
                  {isNaN(BuyGetAmt) ? 0 : BuyGetAmt}
                </h4>
              </div>
            ) : (
              ""
            )}
            {showQuantity ? (
              <div className="product-choose-offer-buy-and-get-ctn">
                <div className="product-choose-offer-buy-and-get">
                  <div className="global-single-input">
                    <p>Quantity</p>
                    <input
                      type="number"
                      placeholder="0"
                      value={addOfferForm.quantity}
                      onChange={(e) =>
                        setAddOfferForm({
                          ...addOfferForm,
                          quantity: e.target.value,
                        })
                      }
                      min="0"
                    />
                  </div>
                  <div className="global-single-input">
                    <p>Price</p>
                    <input
                      type="number"
                      placeholder="$0"
                      value={addOfferForm.price}
                      onChange={(e) =>
                        setAddOfferForm({
                          ...addOfferForm,
                          price: e.target.value,
                        })
                      }
                      min="0"
                    />
                  </div>
                  {/* <div className="product-choose-offer-buy-and-get-circle">
                                +
                            </div> */}
                </div>

                <h4 style={{ color: "red" }}>
                  Current Amount &nbsp;&nbsp;&nbsp;&nbsp;: $
                  {isNaN(QuantityAmt) ? 0 : QuantityAmt}
                </h4>
              </div>
            ) : (
              ""
            )}
            {showPercentage ? (
              <h3 style={{ color: "red", marginLeft: "1%" }}>
                Current Amount &nbsp;&nbsp;&nbsp;&nbsp;:{" "}
                {isNaN(percentageAmt) ? 0 : percentageAmt}
              </h3>
            ) : (
              ""
            )}
          </div>
          <div className="add-offer-product-conditions-body">
            <div className="global-single-input validation-input-offer">
              <p>Sale Start Date</p>
              <input
                type="date"
                value={addOfferForm.startDate}
                onChange={(e) => {
                  setAddOfferForm({
                    ...addOfferForm,
                    startDate: e.target.value,
                  });
                  setStartDateAlert(false);
                }}
              />
              {startDateAlert ? (
                <p className="doc-validation-alert">Select A Start Date</p>
              ) : (
                ""
              )}
            </div>
            <div className="global-single-input validation-input-offer">
              <p>Sale End Date</p>
              <input
                type="date"
                value={addOfferForm.endDate}
                onChange={(e) => {
                  setAddOfferForm({ ...addOfferForm, endDate: e.target.value });
                  setEndDateAlert(false);
                }}
              />
              {endDateAlert ? (
                <p className="doc-validation-alert">Select A End Date</p>
              ) : (
                ""
              )}
            </div>
            <div className="global-single-input validation-input-offer">
              <p>Conditions</p>
              <input
                type="text"
                value={addOfferForm.conditions}
                placeholder="Enter Conditions"
                onChange={(e) =>
                  setAddOfferForm({
                    ...addOfferForm,
                    conditions: e.target.value,
                  })
                }
              />
              <div className="add-condition-btn" onClick={clickAddCondition}>
                +
              </div>

              <div className="add-offer-conditions-list">
                {conditionsArray.slice(0).map((value, i) => {
                  return (
                    <div className="add-offer-condition-row" key={i}>
                      <ul>
                        <li>{value}</li>
                      </ul>
                      <IconButton onClick={() => deleteTableRow(i)}>
                        <i class="bi bi-trash3 delete-icon"></i>
                      </IconButton>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="add-offer-product-submit-button">
            <button className="btn btn-primary" onClick={clickAddOfferSubmit}>
              Submit
            </button>
          </div>
          <hr className="global-hr" />

          <div className="add-offer-bottom-section">
            <div className="add-offer-bottom-section-top">
              <input type="Checkbox" onClick={checkBoxClicked} disabled />
              <h3>For Festival</h3>
            </div>
            <div
              id="selectFestival"
              className="add-offer-bottom-section-bottom disable"
            >
              <div className="global-single-input">
                <p>Select Festival</p>
                <Select value="1" disabled={showTrue}>
                  <MenuItem value="1">Select</MenuItem>
                </Select>
                <a className="designation-footer">Add Designation</a>
              </div>
              <div className="global-single-input">
                <p>Image Upload</p>

                <FilePicker
                  disabled={constant}
                  id="FestivalOfferImage1"
                  // uploadImageUrl={}
                  // setImage={}
                  // onChange={(e) => setRewardImage(e.target.files[0])}
                />
              </div>
            </div>
            <div className="add-offer-bottom-section-button">
              {breadCrumbCheck ? (
                <button className="btn btn-secondary-outlined">Clear</button>
              ) : (
                <button
                  className="btn btn-secondary-outlined"
                  onClick={closeDialog}
                >
                  Close
                </button>
              )}
              <button className="btn btn-primary">Submit</button>
            </div>
          </div>
        </div>
      </div>
      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnack}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnack}
        message={snackMsg}
      />
    </>
  );
};
