import React, { useEffect } from "react";
import "../../../../css/Settings/DeviceSetttings/deviceSettings.css";

import { DeviceSettingsSelectDevice } from "./DeviceSettingsSelectDevice";
import { useState } from "react";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { viewAllBranchesAPICall } from "../../../../API/Settings/Company Settings/companySettingsAPI";

export const DeviceSettings = () => {
  const userRole = useSelector((state) => state.userRoleSlice.value);
  const [activeBtn, setActiveBtn] = useState("deviceSettingsPrinter");
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);

  const clickDeviceSettingsPrinter = () => {
    document.getElementById("deviceSettingsPrinter").classList.add("focus");
    document
      .getElementById("deviceSettingsAttendance")
      .classList.remove("focus");
    document.getElementById("deviceSettingsPayment").classList.remove("focus");
    document.getElementById("deviceSettingsScanning").classList.remove("focus");

    setActiveBtn("deviceSettingsPrinter");
  };
  const clickDeviceSettingsAttendance = () => {
    document.getElementById("deviceSettingsAttendance").classList.add("focus");
    document.getElementById("deviceSettingsPrinter").classList.remove("focus");
    document.getElementById("deviceSettingsPayment").classList.remove("focus");
    document.getElementById("deviceSettingsScanning").classList.remove("focus");

    setActiveBtn("deviceSettingsAttendance");
  };
  const clickDeviceSettingsPayment = () => {
    document.getElementById("deviceSettingsPayment").classList.add("focus");
    document.getElementById("deviceSettingsPrinter").classList.remove("focus");
    document
      .getElementById("deviceSettingsAttendance")
      .classList.remove("focus");
    document.getElementById("deviceSettingsScanning").classList.remove("focus");

    setActiveBtn("deviceSettingsPayment");
  };
  const clickDeviceSettingsScanning = () => {
    document.getElementById("deviceSettingsScanning").classList.add("focus");
    document.getElementById("deviceSettingsPrinter").classList.remove("focus");
    document
      .getElementById("deviceSettingsAttendance")
      .classList.remove("focus");
    document.getElementById("deviceSettingsPayment").classList.remove("focus");

    setActiveBtn("deviceSettingsScanning");

  };

  useEffect(() => {
    viewAllBranchesAPICall();
  }, []);

  ////----/////

  //connected printers

  // connected usb devices

  // const [devices, setDevices] = useState([]);


  // useEffect(() => {
  //   navigator.usb
  //     .requestDevice({ filters: [] })
  //     .then((usbDevice) => {
  //       console.log(navigator.usb)
  //       console.log("usbDevice--->",usbDevice)
  //       // console.log(usbDevice?.productName)
  //       setDevices([usbDevice]);
  //     })
  //     .catch((error) => {
  //       console.log("error occured....")
  //       console.error(error);
  //     });
  // }, []);

  
  //--------//




  return (
    <div className="device-settings-container">
      <div className="device-settings-container-left device-settings-container-left1">
        <p className="breadcrumb">Device Settings</p>
        {userRole === "admin" && (
          <div className="device-settings-container-left-top">
            <div className="device-settings-autocomplete auto-complete">
              <Autocomplete
                sx={{ width: "100%" }}
                options={allBranchesList || ["No Data"]}
                getOptionLabel={(option) => option?.branchName}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select a branch" />
                )}
              />
            </div>
          </div>
        )}

        <div className="device-settings-container-left-section">
          <div
            id="deviceSettingsPrinter"
            onClick={clickDeviceSettingsPrinter}
            className="device-settings-container-left-list focus"
          >
            <ul>
              <li>Printer</li>
            </ul>
          </div>
          <div
            id="deviceSettingsAttendance"
            onClick={clickDeviceSettingsAttendance}
            className="device-settings-container-left-list"
          >
            <ul>
              <li>Attendance Device</li>
            </ul>
          </div>
          <div
            id="deviceSettingsPayment"
            onClick={clickDeviceSettingsPayment}
            className="device-settings-container-left-list"
          >
            <ul>
              <li>Payment Device</li>
            </ul>
          </div>
          <div
            id="deviceSettingsScanning"
            onClick={clickDeviceSettingsScanning}
            className="device-settings-container-left-list"
          >
            <ul>
              <li>Scanning Device</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="device-settings-container-right">
        {activeBtn === "deviceSettingsPrinter" ? (
          <div>
            {/* <DeviceSettingsSelectDevice deviceHead="Select Device" deviceName="HP LaserJet 400 M401"/> */}
            <div className="device-settings-container-right-section">
              <div className="device-settings-container-right-printer">
                <div className="device-settings-container-right-printer-top">
                  <p>Receipt</p>
                  <div className="radio-groups-container settings-radio-group">
                    <div className="global-radio-button radio-group device-settings-radio-group">
                      <input
                        type="radio"
                        name="autoManualRadioReceipt"
                        id="printerReceiptAuto"
                      />
                      <label htmlFor="printerReceiptAuto">Automatic</label>
                    </div>
                    <div
                      className="global-radio-button radio-group device-settings-radio-group"
                      style={{ marginLeft: "15px" }}
                    >
                      <input
                        type="radio"
                        name="autoManualRadioReceipt"
                        id="printerReceiptManual"
                        defaultChecked
                      />
                      <label htmlFor="printerReceiptManual">Manual</label>
                    </div>
                  </div>
                </div>
                <div className="global-single-input" style={{ width: "100%" }}>
                  <Select value="item1">
                    <MenuItem value="item1">HP LaserJet 400 M401</MenuItem>
                    <MenuItem value="item2">Dell LaserJet 400 M401</MenuItem>
                    <MenuItem value="item3">Asus LaserJet 400 M421</MenuItem>
                  </Select>
                </div>
              </div>
            </div>
            <div className="device-settings-container-right-section">
              <div className="device-settings-container-right-printer">
                <div className="device-settings-container-right-printer-top">
                  <p>Paper</p>
                  <div className="radio-groups-container settings-radio-group">
                    <div className="global-radio-button radio-group device-settings-radio-group">
                      <input
                        type="radio"
                        name="autoManualRadioPaper"
                        id="printerPaperAuto"
                      />
                      <label htmlFor="printerPaperAuto">Automatic</label>
                    </div>
                    <div
                      className="global-radio-button radio-group device-settings-radio-group"
                      style={{ marginLeft: "15px" }}
                    >
                      <input
                        type="radio"
                        name="autoManualRadioPaper"
                        id="printerPaperManual"
                        defaultChecked
                      />
                      <label htmlFor="printerPaperManual">Manual</label>
                    </div>
                  </div>
                </div>
                <div className="global-single-input" style={{ width: "100%" }}>
                  <Select value="item1">
                    <MenuItem value="item1">HP LaserJet 400 M401</MenuItem>
                    <MenuItem value="item2">Dell LaserJet 400 M401</MenuItem>
                    <MenuItem value="item3">Asus LaserJet 400 M421</MenuItem>
                  </Select>
                </div>
              </div>
            </div>
            <div className="device-settings-container-right-section">
              <div className="device-settings-container-right-printer">
                <div className="device-settings-container-right-printer-top">
                  <p>Bar Code</p>
                  <div className="radio-groups-container settings-radio-group">
                    <div className="global-radio-button radio-group device-settings-radio-group">
                      <input
                        type="radio"
                        name="autoManualRadioBarcode"
                        id="printerBarcodeAuto"
                      />
                      <label htmlFor="printerBarcodeAuto">Automatic</label>
                    </div>
                    <div
                      className="global-radio-button radio-group device-settings-radio-group"
                      style={{ marginLeft: "15px" }}
                    >
                      <input
                        type="radio"
                        name="autoManualRadioBarcode"
                        id="printerBarcodeManual"
                        defaultChecked
                      />
                      <label htmlFor="printerBarcodeManual">Manual</label>
                    </div>
                  </div>
                </div>
                <div className="global-single-input" style={{ width: "100%" }}>
                  <Select value="item1">
                    <MenuItem value="item1">HP LaserJet 400 M401</MenuItem>
                    <MenuItem value="item2">Dell LaserJet 400 M401</MenuItem>
                    <MenuItem value="item3">Asus LaserJet 400 M421</MenuItem>
                  </Select>
                </div>
              </div>
            </div>
          </div>
        ) : activeBtn === "deviceSettingsAttendance" ? (
          <div>
            {/* <DeviceSettingsSelectDevice deviceHead="Select Device" deviceName="Zkteco"/> */}
            <div
              className="device-settings-container-right-section"
              style={{ paddingBottom: "20px" }}
            >
              <div className="device-settings-container-right-printer">
                <div className="global-single-input">
                  <p>Select Device</p>
                  <Select value="item1">
                    <MenuItem value="item1">HP LaserJet 400 M401</MenuItem>
                    <MenuItem value="item2">Dell LaserJet 400 M401</MenuItem>
                    <MenuItem value="item3">Asus LaserJet 400 M421</MenuItem>
                  </Select>
                </div>
              </div>
              <div
                className="device-settings-container-right-printer"
                style={{ marginTop: "5%" }}
              >
                <div className="global-single-input">
                  <p>Public/Static IP</p>
                  <input type="text" />
                </div>
              </div>
            </div>
            <div className="device-settings-container-right-attendance">
              <p>Username</p>
              <input type="text" />
              <p>Password</p>
              <input type="password" />
            </div>
          </div>
        ) : activeBtn === "deviceSettingsPayment" ? (
          <div>
            {/* <DeviceSettingsSelectDevice deviceHead="Select Device" deviceName="Zkteco" deviceName2="Hpp"/> */}
            <div
              className="device-settings-container-right-section"
              style={{ paddingBottom: "20px" }}
            >
              <div className="device-settings-container-right-printer">
                <div className="global-single-input">
                  <p>POS</p>
                  <Select value="item1">
                    <MenuItem value="item1">HP LaserJet 400 M401</MenuItem>
                    <MenuItem value="item2">Dell LaserJet 400 M401</MenuItem>
                    <MenuItem value="item3">Asus LaserJet 400 M421</MenuItem>
                  </Select>
                </div>
              </div>
            </div>
            <div className="device-settings-container-right-attendance">
              <p>App Key</p>
              <input type="text" />
              <p>Username</p>
              <input type="text" />
              <p>Password</p>
              <input type="password" />
            </div>
          </div>
        ) : activeBtn === "deviceSettingsScanning" ? (
          <div>
            <DeviceSettingsSelectDevice
              deviceName="QR"
              deviceName2="Hpp"
              deviceHead="Select QR Code Device"
            />
            <DeviceSettingsSelectDevice
              deviceName="Barcode"
              deviceName2="Hpp"
              deviceHead="Select BarCode Device"
            />
          </div>
        ) : undefined}
        <div className="device-settings-right-container-button">
          <button className="btn btn-primary">Submit</button>
        </div>
      </div>
    </div>
  );
};
