import React, { useState, useEffect, useRef, useMemo } from "react";
import "../../../../css/Sales/Billing.css";
import {
  Select,
  MenuItem,
  IconButton,
  Dialog,
  Autocomplete,
  TextField,
  Skeleton,
  createFilterOptions,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import kurtiImage from "../../../../Assets/Images/kurti.jpg";
import offerTag from "../../../../Assets/Images/offer-tag.png";
import tShirt from "../../../../Assets/Images/shirt1.jpg";
import Shirt from "../../../../Assets/Images/shirts.jpg";
import pant from "../../../../Assets/Images/pant.jpg";
import emptyCartImg from "../../../../Assets/Images/Cart-Empty.png"
import { BillingInvoice } from "../Billing/BillingInvoice";
import { Invoice } from "../Billing/Invoice";
import { useSelector } from "react-redux";
import {
  viewAllAccessoryCategoryAPICall,
  viewAllAccessorySubCategoryAPICall,
  viewAllMaterialCategoryAPICall,
  viewAllReadymadeCategoriesAPICall,
  viewAllReadymadeSubCategoryAPICall,
} from "../../../../API/Product Master/ProductMasterAPI";
import { viewAllProductsAPICall } from "../../../../API/Product List/productListAPI";
import { AddCustomer } from "../../Customer/AddCustomer";
import { viewAllCustomersAPICall } from "../../../../API/Customer/customerAPI";
import { viewCardManualAPICall } from "../../../../API/Settings/CardAndUpiType/cardManualAPI";
import { getAllActiveEmployeesAPICall } from "../../../../API/Staff/HRM/employeeAPI";
import NoImage from "../../../../Assets/Images/no-image.png";
import { getCurrentTime, today } from "../../../../Js/Date";
import {
  addBillingHoldAPICall,
  finalBillingAPICall,
  getBillingHeldOrdersAPICall,
  viewProductListForBilling,
} from "../../../../API/Sales/billing";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import {
  getCreditOfCustomerAPICall,
  viewWalletTotalByCustomerIdAPICall,
} from "../../../../API/Credit and Wallet/creditAndWalletAPI";
import store from "../../../../Redux/store";
import { update_credit_of_customer } from "../../../../Redux/Customer/singleCustomerCreditSlice";
import cartIcon from "../../../../Assets/SVG/shopping-cart_1_.svg";
import { OrderList } from "../OrderList";
import ReactToPrint from "react-to-print";
import { PrintOutlined } from "@mui/icons-material";
import { getDiscountAPICall } from "../../../../API/Settings/Discount/discountAPI";
import { convertPercentageToAmount } from "../../../../Js/generalFunctions";
import { Datepicker } from "../../../Single Components/Datepicker";
import { viewChequeManualAPICall } from "../../../../API/Settings/CardAndUpiType/ChequeManualAPI";
export const Billing = () => {
  // let randomColor = Math.floor(Math.random() * 16777215).toString(16);
  //Get staff id from local storage
  const userLoginMeta = localStorage.getItem("login_meta");
  const userPk = JSON.parse(userLoginMeta)?.data?._id;
  const branchId = localStorage.getItem("branchId");
  // All Material Category List
  const allMaterialCatList = useSelector(
    (state) => state.allMaterialCategorySlice.value
  );
  //All readymade category list
  const readyMadeCategoryList = useSelector(
    (state) => state.allReadymadeCategories.value
  );
  //All accessory category list
  const accessoryCategoryList = useSelector(
    (state) => state.allAccessoryCategorySlice.value
  );
  //All Accessory subcategory list
  const accessorySubcategoryList = useSelector(
    (state) => state.allAccessorySubCategorySlice.value
  );
  //All ReadyMade subcategory list
  const readyMadeSubcategoryList = useSelector(
    (state) => state.allReadymadeSubCategorySlice.value
  );
  //All products list
  const allProductsList = useSelector(
    (state) => state.allProductsListSlice.value
  );
  //List of All Customers
  const customerList = useSelector((state) => state.allCustomerListSlice.value);
  //Added Cards list
  const cardManual = useSelector((state) => state.cardManualSlice.value);
  //Active Employee List
  const allActiveEmpList = useSelector(
    (state) => state.allActiveEmployeeSlice.value
  );
  //Credits of Selected Customer
  const customerCredit = useSelector(
    (state) => state.singleCustomerCreditSlice.value
  );
  //Held order list
  const heldOrdersList = useSelector(
    (state) => state.billOrderHeldProductSlice.value
  );

  //Invoice data
  const invoiceData = useSelector((state) => state.invoiceSlice.value);

  //added cheque List

  const chequeManual = useSelector((state) => state.chequeManualSlice.value);

  //Maximum Discount data
  const maximumDiscount = useSelector((state) => state.discountSlice.value);

  //Wallet of Selected Customer
  const customerWallet = useSelector(
    (state) => state.singleCustomerWalletSlice.value
  );

  // console.log(readyMadeSubcategoryList);

  //Material Category list - OutCloth excluded (filtered)
  const [materialCategoryListFiltered, setMaterialCategoryListFiltered] =
    useState([]);

  const [isModify, setIsModify] = useState(false);
  //Previous credit dialog
  const [openPreviousCreditDialog, setOpenPreviousCreditDialog] =
    useState(false);
  const [frontendCreditList, setFrontendCreditList] = useState([]);
  const [creditsForBackend, setCreditsForBackend] = useState([]);
  //checked Credits Filtered
  const [selectedCredits, setSelectedCredits] = useState([]);
  //Customer Net credit
  // const [netCredit, setNetCredit] = useState(0);
  //O/s balance
  const [osBalance, setOsBalance] = useState(0);

  const [orderType, setOrderType] = useState(2);
  const [gender, setGender] = useState("male");
  const [selectedSalesManObj, setSelectedSalesManObj] = useState(null);
  const [customerName, setCustomerName] = useState("0");
  const [paymentMethod, setPaymentMethod] = useState("card");
  const [multipleCheck, setMultipleCheck] = useState(false);
  const [heldProductDialog, setHeldProductDialog] = useState(false);
  //Open invoice dialog
  const [invoiceDialog, setInvoiceDialog] = useState(false);
  //Loading State
  const [isLoading, setIsLoading] = useState(false);
  //final place order loading state
  const [billLoading, setBillLoading] = useState(false);
  //Selected material category Object
  const [selectedMaterialCatObj, setSelectedMaterialCatObj] = useState(null);
  //Selected Readymade category object
  const [selectedReadymadeCatObj, setSelectedReadymadeCatObj] = useState(null);
  //Selected accessory category object
  const [selectedAccessoryCatObj, setSelectedAccessoryCatObj] = useState(null);
  //Selected Accessory subcategory
  const [selectedAccessorySubCategory, setSelectedAccessorySubCategory] =
    useState(null);
  //Selected ReadyMade subcategory
  const [selectedReadymadeSubCategory, setSelectedReadymadeSubCategory] =
    useState(null);

  //Search result of products
  const [productsSearched, setProductsSearched] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");

  //snackbar states
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false);
  const [openErrorSnack, setOpenErrorSnack] = useState(false);
  const [snackMsg, setSnackMsg] = useState("");

  //* Selected Products [cart] related states
  const [cart, setCart] = useState([]);
  const [orderInfo, setOrderInfo] = useState([]);
  const [paymentMethodBody, setPaymentMethodBody] = useState([]);
  //hold related
  const [isHeldOrder, setIsHeldOrder] = useState(false);
  const [heldPayingObject, setHeldPayingObject] = useState(null);
  //Final body
  const [finalBody, setFinalBody] = useState();
  //*payment section related states
  const [subTotal, setSubTotal] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const [rewardPoints, setRewardPoints] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);
  const [wallet, setWallet] = useState(0);
  //Customer Net Credit
  const [creditAmount, setCreditAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [balanceAmount, setBalanceAmount] = useState(0);
  const [changeAmount, setChangeAmount] = useState(0);
  ///multiple payments related
  const [paymentMethod1, setPaymentMethod1] = useState("card");
  const [paymentMethod2, setPaymentMethod2] = useState("card");
  const [paymentMethod3, setPaymentMethod3] = useState("card");

  //------//

  const [paidAmount3, setPaidAmount3] = useState();
  const [vendor3, setVendor3] = useState();
  const [vendor4, setVendor4] = useState();
  const [paidAmount4, setPaidAmount4] = useState();
  const [vendor5, setVendor5] = useState();

  const [vendor6, setVendor6] = useState();

  const [chequeNo, setChequeNo] = useState();
  const [chequeNo1, setChequeNo1] = useState();
  const [chequeNo2, setChequeNo2] = useState();

  const [chequeDate, setChequeDate] = useState();
  const [chequeDate1, setChequeDate1] = useState();
  const [chequeDate2, setChequeDate2] = useState();
  const [paidAmount5, setPaidAmount5] = useState(null);
  const [paidAmount6, setPaidAmount6] = useState(null);

  //  ------------ //

  //Vender states
  const [vendor1, setVendor1] = useState(null);
  const [vendor2, setVendor2] = useState(null);
  //paid amount states
  const [paidAmount1, setPaidAmount1] = useState(null);
  const [paidAmount2, setPaidAmount2] = useState(null);

  //Maximum discount amount
  const [maxDiscountAmt, setMaxDiscountAmt] = useState(null);

  //* Add customer dialog related states
  //Open add customer dialog
  const [openAddCusDialog, setOpenAddCusDialog] = useState(false);
  //Selected Customer Object
  const selectedCusInitialState = {
    _id: "cash",
    name: "Cash",
    mobileNo: null,
    buildingName: null,
    streetName: null,
    email: null,
    gst: null,
    status: true,
    __v: 0,
    points: 440,
    cusId: "####",
    branchId: null,
    relId: null,
    relation: null,
  };
  const [selectedCustomerObj, setSelectedCustomerObj] = useState(
    selectedCusInitialState
  );
  // wallet redeem click
  const [redeemClickBtn, setRedeemClickBtn] = useState(false);
  //Customer Id
  const [cusId, setCusId] = useState(null);
  //*bottom button dialog open states
  //View Order dialog open
  const [viewOrderDialogOpen, setViewOrderDialogOpen] = useState(false);
  const [updatedCustomerList, setUpdatedCustomerList] = useState([]);
  let invoicePrint = useRef();
  //Filter by name and mob no in same input field select customer autocomplete
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option?.name + option.mobileNo,
  });
  //*onChanges
  //Get selected Material Category
  const getSelectedMaterialCategory = (key) => (e, newValue) => {
    switch (key) {
      case "material":
        setSelectedMaterialCatObj(newValue);
        break;
      case "readymade":
        setSelectedReadymadeCatObj(newValue);
        break;
      case "accessory":
        setSelectedAccessoryCatObj(newValue);
        break;

      default:
        break;
    }
  };
  //Get payment form states
  const getPaymentFormStates = (key) => (e) => {
    const { value } = e.target;
    switch (key) {
      case "shippingCharge":
        setShippingCharge(Number(value));
        break;
      case "couponCode":
        setCouponCode(value);
        break;
      case "rewardPoints":
        setRewardPoints(value);
        break;
      case "discount":
        if (maxDiscountAmt !== null) {
          if (maxDiscountAmt < value) {
            alert(
              "Discount is greater than allowed limit!! Maximum discount : " +
                maxDiscountAmt
            );
          } else {
            setDiscount(Number(value));
          }
        } else {
          setDiscount(Number(value));
        }

        break;
      case "paidAmount":
        setPaidAmount(Number(value));
        break;
      case "wallet":
        setWallet(Number(value));
        break;
      default:
        break;
    }
  };

  //Order type onChange
  const getOrderType = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setOrderType(Number(value));
      // viewProductsAPITrigger()
    }
  };

  //Order SubCategory onChange
  const getOrderSubCategory = (key) => (e) => {
    const { value, checked } = e.target;

    if (key === "accessory") {
      if (checked) {
        setSelectedAccessorySubCategory(value);
      } else {
        setSelectedAccessorySubCategory(null);
      }
      //Get accessory checked
    }
    if (key === "readymade") {
      //Get readymade checked
      if (checked) {
        setSelectedReadymadeSubCategory(value);
      } else {
        setSelectedReadymadeSubCategory(null);
      }
    }
  };

  //get Search keyword for product search
  const getProductSearchKeyword = (e) => {
    const { value } = e.target;
    searchProducts(value, allProductsList);
    setSearchKeyword(value);
  };

  //Change Unit price of a product
  const getUnitPriceOfProduct = (prodObj, i) => (e) => {
    const { value } = e.target;
    prodObj.unitPrice = value;
    let itemTotal = calculateItemTotal(prodObj?.selectedQty, value);
    cart[i].itemTotal = itemTotal;
    setCart([...cart]);
  };

  //Get material qty entered in case of yard or meter
  const getMaterialQtyEntered = (cart, i) => (e) => {
    const { value } = e.target;
    cart[i].selectedQty = value;

    let itemTotal = calculateItemTotal(value, cart[i]?.unitPrice);
    cart[i].itemTotal = itemTotal;

    setCart([...cart]);
  };

  // console.log(selectedMaterialCatObj);
  // console.log(selectedReadymadeCatObj);
  // console.log(selectedAccessoryCatObj);

  //*Fns

  //Close successSnackbar
  const closeSuccessSnackbar = () => {
    setOpenSuccessSnack(false);
  };
  //Close errorSnackbar
  const closeErrorSnackbar = () => {
    setOpenErrorSnack(false);
  };
  //Update Listener
  const updateListener = () => {
    setIsModify(!isModify);
  };

  //Clear States
  const clearStates = () => {
    setCart([]);
    setSubTotal(0);
    setShippingCharge(0);
    setCouponCode("");
    setRewardPoints(0);

    setDiscount(0);
    setPaidAmount(0);
    setPaidAmount1(0);
    setPaidAmount3(null);
    setPaidAmount4(0);
    setPaidAmount2(0);
    setPaidAmount5(0);
    setPaidAmount6(0);
    setWallet(0);
    
    setCreditAmount(0);
    setTotalAmount(0);
    setBalanceAmount(0);
    setChangeAmount(0);
    setSelectedCustomerObj(selectedCusInitialState);
    setPaymentMethod1("card");
    setPaymentMethod2("card");
    setPaymentMethod3("card");
    // setVendor5(null);
    // setVendor6(null);

    setVendor1(null);
    // setVendor3(null);
    setVendor2(null);
    setVendor4(null);


    setPaidAmount1(null);
    setPaidAmount2(null);
    setPaidAmount4(null);

    setChequeNo(null);
    setChequeNo1(null);
    setChequeNo2(null);

    setChequeDate(null);
    setChequeDate1(null);
    setChequeDate2(null);
    setIsHeldOrder(false);
    setHeldPayingObject(null);

  };

  //View order button click
  const clickViewOrderBtn = () => {
    setViewOrderDialogOpen(true);
  };

  //Filter outCloth from MaterialCategory List response
  const removeOutCloth = (materialCatRes) => {
    let removedList = [];
    if (materialCatRes !== undefined) {
      removedList = materialCatRes?.filter(
        (obj) => obj?.materialCategoryName !== "Outcloth"
      );
      setMaterialCategoryListFiltered(removedList);
    } else {
      setMaterialCategoryListFiltered(undefined);
    }
  };
  // Add Cash menu item in customer list
  // console.log(customerList);
  const addCashOptionInCustomerList = (customerList) => {
    if (customerList !== undefined) {
      let updatedCustomerList = [
        {
          _id: "cash",
          name: "Cash",
          mobileNo: null,
          buildingName: null,
          streetName: null,
          email: null,
          gst: null,
          status: true,
          __v: 0,
          points: 440,
          cusId: "####",
          branchId: null,
          relId: null,
          relation: null,
        },
        ...customerList,
      ];
      setUpdatedCustomerList(updatedCustomerList);
    }
  };

  //hide invoice print button on scroll
  const hideInvoicePrint = () => {
    document.getElementById("invoicePrintIcon").style.display = "none";
  };

  //Create Front end Credit list with isChecked injected
  const createCreditListForFrontend = (apiResponse) => {
    if (apiResponse !== undefined) {
      let newCreditList = apiResponse?.map((obj) => {
        return { ...obj, isChecked: false };
      });
      setFrontendCreditList(newCreditList);
    } else {
      setFrontendCreditList([]);
    }
  };
  //Check or uncheck credit item
  const checkOrUncheckCreditItem = (index) => (e) => {
    const { checked } = e.target;
    if (frontendCreditList?.length > 0) {
      if (checked) {
        frontendCreditList[index].isChecked = true;
        // frontendCreditList?.forEach((obj,i)=>{
        //   if(i===index) obj.isChecked=true
        // })
      } else {
        frontendCreditList[index].isChecked = false;
        // frontendCreditList?.forEach((obj,i)=>{
        //   if(i===index) obj.isChecked=false
        // })
      }

      setFrontendCreditList([...frontendCreditList]);
    }
  };
  //Create Selected(checked) Credit Items Array
  const createSelectedCreditItems = (completeCreditList) => {
    let filteredList = [];
    if (completeCreditList !== undefined) {
      filteredList = completeCreditList?.filter(
        (obj) => obj.isChecked === true
      );
      setSelectedCredits(filteredList);
    } else {
      setSelectedCredits([]);
    }
  };
  //Create Credits for backend
  const createCreditListForBackend = (selectedCredits) => {
    let creditsForBackend = [];

    creditsForBackend = selectedCredits?.map((obj) => {
      return { order_id: obj.purchasePk, balance: obj.balance };
    });
    setCreditsForBackend(creditsForBackend);
  };
  //Calculate Total Credit Amount
  const calTotCreditAmount = (credits) => {
    let creditTot = 0;
    credits?.forEach((obj) => {
      creditTot += parseFloat(obj?.balance);
    });
    // setNetCredit(creditTot);
    setOsBalance(creditTot);
  };
  //Calculate Total amount of credit selected from dialog
  const calculateTotalSelectedCreditAmount = () => {
    let selectedCreditTot = 0;
    selectedCredits?.forEach((obj) => {
      selectedCreditTot += parseFloat(obj?.balance);
    });
    if (isNaN(selectedCreditTot)) {
      setCreditAmount(0);
    } else {
      setCreditAmount(selectedCreditTot);
    }
  };
  //Click Previous Credit dialog Submit Fn
  const submitPreviousCreditDialog = () => {
    createCreditListForBackend(selectedCredits);
    calculateTotalSelectedCreditAmount();
    setOpenPreviousCreditDialog(false);
  };

  // console.log(customerWallet);
  // set customer wallet to state
  useEffect(() => {
    if (customerWallet !== undefined) {
      setWallet(customerWallet?.amount);
    }
    if (customerWallet === undefined) {
      setWallet(0);
    }
  }, [customerWallet]);

  //create Payment method body
  const formPaymentMethodBody = () => {
    let paymentMethodBody = [];
    if (multipleCheck) {
      //in case of multiple payment
      paymentMethodBody = [
        {
          type: paymentMethod1,
          account: null, //get account if Cash null, if Card null, if UPI abc@ybl
          date: `${today} ${getCurrentTime()}`,
          paidAmount: paidAmount1 ?  parseFloat(paidAmount1) : null,
          vendor: paymentMethod1 === 'cash' ? null : vendor1,
          chequeDate: chequeDate ? chequeDate :null,
          chequeNo: chequeNo ? chequeNo : null

        },

        {
          type: paymentMethod2,
          account: null, //get account if Cash null, if Card null, if UPI abc@ybl
          date: `${today} ${getCurrentTime()}`,
          paidAmount: paidAmount2 ? parseFloat(paidAmount2) : null,
          vendor: paymentMethod2 === 'cash' ? null : vendor2,
          chequeDate: chequeDate1 ? chequeDate1 : null,
          chequeNo: chequeNo1 ? chequeNo1 : null 
        },

        {
          type: paymentMethod3,
          account: null, //get account if Cash null, if Card null, if UPI abc@ybl
          date: `${today} ${getCurrentTime()}`,
          paidAmount: paidAmount4 ? parseFloat(paidAmount4) : null,
          vendor: paymentMethod3 === 'cash' ? null : vendor4,
          chequeDate: chequeDate2 ? chequeDate2 : null,
          chequeNo: chequeNo2 ? chequeNo2 : null 
        },
      ];
    } 
    //in case of single payment
    else {

      if(paymentMethod1 === "card"){

        paymentMethodBody = [
          {
            type: paymentMethod1,
            account: null,
            date: `${today} ${getCurrentTime()}`,
            paidAmount: parseFloat(paidAmount),
            vendor: vendor1,
          },
        ];
      }

      if(paymentMethod1 === "cash"){
        paymentMethodBody = [
          {
            type: paymentMethod1,
            account: null,
            date: `${today} ${getCurrentTime()}`,
            paidAmount: parseFloat(paidAmount),
            vendor: null,
          },
        ];
      }

      if(paymentMethod1 === "cheque"){
        paymentMethodBody = [
          {
            type: paymentMethod1,
            account: null,
            date: `${today} ${getCurrentTime()}`,
            paidAmount: parseFloat(paidAmount),
            // paidAmount: parseFloat(paidAmount3),
            vendor: vendor1,
            chequeDate: chequeDate,
            chequeNo: chequeNo,
          },
        ];
      }


      // paymentMethodBody = [
      //   {
      //     type: paymentMethod1,
      //     account: null,
      //     date: `${today} ${getCurrentTime()}`,
      //     paidAmount: parseFloat(paidAmount),
      //     vendor: vendor1,
      //   },
      // ];
    }



      let data1 = paymentMethodBody.filter((item)=>item.paidAmount !== null)
      // console.log("data1--->",data1)
      if(data1[2]){
        if(data1[2].type == 'card' || data1[2].type == 'cheque'){
          if(data1[2].vendor === null){
            data1.pop()
            // console.log(data1)

          }
        }
      }

    // setPaymentMethodBody(paymentMethodBody);

    console.log("data1----->",data1)

    setPaymentMethodBody(data1);

  };
  //Create order info array for backend
  const createOrderInfoArray = (cart) => {
    let orderInfo = [];
    if (cart.length > 0) {
      orderInfo = cart.map((item) => {
        const {
          type,
          _id,
          selectedQty,
          unitPrice,
          unitName,
          selectedSize,
          vatorgst,
        } = item;
        return {
          type,
          itemInfo: _id,
          quantity: selectedQty,
          rate: unitPrice,
          unit: unitName,
          dimension: type === 2 ? "no dimension" : selectedSize,
          offer: null,
          vatorgst,
        };
      });
    } else {
      orderInfo = [];
    }

    setOrderInfo(orderInfo);
  };


  //Create Final Body
  const createFinalBody = () => {
    setFinalBody({
      orderType,
      cus_id: selectedCustomerObj?._id || null,
      orderInfo,
      paymentMethod: paymentMethodBody,
      totalAmount,
      discount,
      shipmentCharge: shippingCharge,
      emp_id: selectedSalesManObj?._id || null,
      usedPoints: 0, //not assigned
      
      usedWallet: redeemClickBtn?customerWallet!==undefined &&customerWallet?.amount-wallet
                  :0
      ,
      
      creditCleared: creditsForBackend.length > 0 ? creditsForBackend : null,
      isWallet: false, //Not assigned yet
      branchId: branchId,
      orderDate: `${today} ${getCurrentTime()}`,
      _id: isHeldOrder ? heldPayingObject?._id : null,
    });
  };

  //Check to alert credit or not
  const alertCreditAndPlaceOrder = () => {
    if (paidAmount < totalAmount) {
      if (
        window.confirm(
          "Paid amount is less than total amount!! \nbalance amount will be added to customer credit \n would you like to continue??"
        ) === true
      ) {
        if (Object.keys(finalBody)?.includes("paymentMethod") === true) {
          // console.log("Ok pressed");
          // Call billing api
          // console.log(finalBody)


          finalBillingAPICall(
            finalBody,
            setBillLoading,
            setOpenSuccessSnack,
            setOpenErrorSnack,
            setSnackMsg,
            clearStates,
            updateListener,
            setInvoiceDialog
          );

        } else {
          alert("No payment method created try again!!");
        }
      } else {
        // console.log("Cancelled");
      }
    } else if (paidAmount > totalAmount) {
      if (
        window.confirm(
          "Paid amount is greater than total amount!! \n Would you like to add change to customer wallet?? \n If not change paid amount!! "
        ) === true
      ) {
        if (Object.keys(finalBody)?.includes("paymentMethod") === true) {
          console.log("Ok pressed");
          // Call billing api
          finalBody.isWallet = true;
          finalBillingAPICall(
            finalBody,
            setBillLoading,
            setOpenSuccessSnack,
            setOpenErrorSnack,
            setSnackMsg,
            clearStates,
            updateListener,
            setInvoiceDialog
          );
        } else {
          alert("No payment method created try again!!");
        }
      } else {
        console.log("Cancelled");
      }
    } else {
      if (Object.keys(finalBody)?.includes("paymentMethod") === true) {
        // Call billing api
        finalBillingAPICall(
          finalBody,
          setBillLoading,
          setOpenSuccessSnack,
          setOpenErrorSnack,
          setSnackMsg,
          clearStates,
          updateListener,
          setInvoiceDialog
        );
      } else {
        alert("No payment method created try again!!");
      }
    }
  };
  // console.log(finalBody);
  //Final API call
  const finalPlaceOrder = () => {
    if (cusId === undefined || cusId === null) {
      alert("Select a customer");
    } else if (multipleCheck) {
      if (paymentMethod1 === "card") {
        if (vendor1 === null) {
          alert("Please select a card vendor!!");
        } else {
          if (paymentMethod2 === "card") {
            if (vendor2 === null) {
              alert("Please select a card vendor");
            } else {
              //Placing order
              if (paidAmount1 === null) {
                alert("Enter split amount 1!!");
              } else if (paidAmount2 === null) {
                alert("Enter split amount 2!!");
              } else {
                alertCreditAndPlaceOrder();
              }
            }
          } else {
            //Placing order
            if (paidAmount1 === null) {
              alert("Enter split amount 1!!");
            } else if (paidAmount2 === null ) {
              alert("Enter split amount 2!!");
            } else {
              alertCreditAndPlaceOrder();
            }
          }
        }
      } else {
        if (paymentMethod2 === "card") {
          if (vendor2 === null) {
            alert("Please select a card vendor");
          } else {
            //Placing order
            if (paidAmount1 === null) {
              alert("Enter split amount 1!!");
            } else if (paidAmount2 === null) {
              alert("Enter split amount 2!!");
            } else {
              alertCreditAndPlaceOrder();
            }
          }
        } else {
          //Placing order
          if (paidAmount1 === null) {
            alert("Enter split amount 1!!");
          } else if (paidAmount2 === null) {
            alert("Enter split amount 2!!");
          } else {
            alertCreditAndPlaceOrder();
          }
        }
      }
    } else if (!multipleCheck) {
      if (paymentMethod1 === "card") {
        if (vendor1 === null) {
          alert("Please select a card vendor!!");
        } else {
          //Placing order
          alertCreditAndPlaceOrder();
        }
      } else {
        //Placing order
        alertCreditAndPlaceOrder();
      }
    }
  };

  //Calculate Cart total amount
  const calculateCartTotal = (cart) => {
    let cartTotal = 0;
    if (cart.length > 0) {
      cart.forEach((item) => (cartTotal += Number(item?.itemTotal)));
    } else {
      cartTotal = 0;
    }

    setSubTotal(cartTotal);
  };
  //Calculate prod Item total [selected qy * unit price]
  const calculateItemTotal = (selectedQty, unitPrice) => {
    let itemTotal = null;

    console.log(selectedQty);
    console.log(unitPrice);

    itemTotal = Number(selectedQty || 1) * Number(unitPrice);

    return itemTotal;
  };

  //delete Products from cart
  const deleteFromCart = (index) => () => {
    cart.splice(index, 1);
    setCart([...cart]);
  };

  //find calculated price of a product item
  const findUnitPrice = (prodObj, size) => {
    let unitPrice = null;

    if (Object.keys(prodObj).includes("size")) {
      // in case of readyMade and accessory products
      let sizeObj = prodObj?.size?.find((prod) => prod.brandUnit === size);
      unitPrice = sizeObj?.calculatedPrice;
    } //in case of material products
    else {
      unitPrice = prodObj?.calculatedPrice;
    }

    return unitPrice;
  };

  //Validate Selected qty is greater than product in stock if greater show a warning
  const validateSelectedQtyWithStock = (itemObj, size, selectedQty) => {
    //1. check product type
    //2. if material check stock outside array
    //3. else check stock inside dimension array
    let productStock = 0;
    let isLargerQtySelected = false;
    let isNotMaterial = Object.keys(itemObj).includes("size");
    if (isNotMaterial) {
      //Item is either readymade or accessory
      console.log("item is not a material");
      productStock = itemObj?.size?.find((obj) => obj.brandUnit === size).stock;
      console.log(productStock);
    } else {
      // item is material
      console.log("item is a material");
      productStock = itemObj?.stock;
    }

    if (selectedQty > productStock) isLargerQtySelected = true;
    else isLargerQtySelected = false;

    return isLargerQtySelected;
  };

  //Click Add to cart button and increment qty btn
  const clickAddToCartBtn = (itemObj, size) => () => {
    //check whether the cart already in cart or not. if exist increase the qty or else add new
    console.log(itemObj);
    //Check Cart already have selected item with selected size
    if (size !== undefined && size !== null) {
      const exist = cart.find(
        (item) => item._id === itemObj._id && item.selectedSize === size
      );

      let unitPrice = findUnitPrice(itemObj, size);

      console.log(itemObj);

      if (exist) {
        //increment the no of item by 1
        // let itemTotal = calculateItemTotal(itemObj?.selectedQty + 1, unitPrice);
        // console.log(itemObj?.selectedQty);
        let isNotValid = validateSelectedQtyWithStock(
          itemObj,
          size,
          Math.floor(exist.selectedQty) + 1
        );
        if (isNotValid) alert("Product is out of stock!!");
        setCart(
          cart.map((item) =>
            item._id === itemObj._id && item.selectedSize === size
              ? {
                  ...exist,
                  selectedQty: isNotValid
                    ? Math.floor(exist.selectedQty)
                    : Math.floor(exist.selectedQty) + 1,
                  itemTotal: calculateItemTotal(
                    isNotValid
                      ? Math.floor(exist.selectedQty)
                      : Math.floor(exist.selectedQty) + 1,
                    item.unitPrice
                  ),
                }
              : item
          )
        );

        console.log("existing item updated");
      } else {
        // add new item to cart array
        let itemTotal = calculateItemTotal(1, unitPrice);
        setCart([
          ...cart,
          {
            ...itemObj,
            selectedQty: 1,
            selectedSize: size,
            unitPrice,
            itemTotal,
          },
        ]);

        console.log("new item Added ");
      }
    }
  };
  // console.log(cart);
  //Decrement Qty from cart
  const decrementQty = (itemObj) => () => {
    const exist = cart.find((item) => item._id === itemObj._id);

    let itemTotal = calculateItemTotal(
      Math.floor(itemObj?.selectedQty) - 1,
      itemObj?.unitPrice
    );

    if (exist.selectedQty == 1) {
      setCart([...cart]);
    } else {
      setCart(
        cart.map((item) =>
          item._id === itemObj._id
            ? {
                ...exist,
                selectedQty: Math.floor(exist.selectedQty) - 1,
                itemTotal,
              }
            : item
        )
      );
    }

    console.log("qty decremented");
  };

  //Extract logged in user Object from employee list
  const extractLoggedInUserObj = (empList, userPrimaryKey) => {
    if (empList !== undefined) {
      let loggedInUser = empList?.filter((obj) => obj?._id === userPrimaryKey);
      setSelectedSalesManObj(loggedInUser[0]);
    }
  };

  // Redeem Amount From Wallet[totalAmount-redeemed]
  const redeemFromWallet=(totalAmountToPay,redeemAmount)=>()=>{
    if(customerWallet !== undefined) {
      let walletTot=customerWallet?.amount;
      let newTotal=0;
      let walletBalance=0;

      if(redeemAmount > walletTot){
          alert(`Redeemed amount must be less than or equal to ${walletTot}`)
      } else {
        if(redeemAmount > totalAmountToPay) {
          alert("Redeemed amount must be less than or equal to total amount!!");
        } else {
          setRedeemClickBtn(true)
          newTotal=parseFloat(totalAmountToPay) - parseFloat(redeemAmount);
          walletBalance=parseFloat(walletTot) - parseFloat(redeemAmount);
          setTotalAmount(newTotal);
          setWallet(walletBalance);
          alert("Amount redeemed from wallet succesfully");
        }
      }
    } else {
      alert("Can not redeem..,Wallet is empty!!");
    }
  };

  //Search product by name
  const searchProducts = (keyword, fullList) => {
    let searchResult = [];

    if (fullList !== undefined) {
      if (keyword !== "") {
        searchResult = fullList?.filter((obj) =>
          obj?.productName?.toLowerCase().includes(keyword?.toLowerCase())
        );
        // searchResult?.map((obj) => {
        //   return {
        //     ...obj,
        //     selectedSize:
        //       (obj?.size && obj?.size[0].brandUnit) || obj?.unitName,
        //   };
        // });
        console.log(searchResult);
      } else
        searchResult = fullList?.map((obj) => {
          return {
            ...obj,
            selectedSize:
              (obj?.size && obj?.size[0].brandUnit) || obj?.unitName,
          };
        });
    } else {
      searchResult = [];
    }
    searchResult = searchResult?.map((obj) => {
      return {
        ...obj,
        selectedSize: (obj?.size && obj?.size[0].brandUnit) || obj?.unitName,
      };
    });
    setProductsSearched(searchResult);

    console.log(keyword);
    console.log(searchResult);
    // console.log(searchResult);
  };

  //Calculate total amount
  const calcTotalAmt = (subTotal, shippingCharge, discount, credit) => {
    let totalAmt =
      Number(subTotal) +
      Number(shippingCharge) +
      Number(credit) -
      Number(discount); //Equation may vary if wallet,offer and reward are working
    setTotalAmount(totalAmt);
  };
  //Calculate balance and change
  const calculateBalanceAndChange = (total, paidAmt) => {
    let balance = 0,
      change = 0;
    if (total > paidAmt) {
      balance = Number(total) - Number(paidAmt);
    } else if (total < paidAmt) {
      change = Number(paidAmt) - Number(total);
    } else {
      balance = 0;
      change = 0;
    }
    setBalanceAmount(balance);
    setChangeAmount(change);
  };

  //Click multiple payment checkbox
  const clickMultipleCheck = (e) => {
    setMultipleCheck(e.target.checked);
  };

  //Open add Customer dialog
  const openAddCusDialogFn = () => {
    setOpenAddCusDialog(true);
  };
  //Close add Customer dialog
  const closeAddCusDialogFn = () => {
    setOpenAddCusDialog(false);
  };

  //Billing hold body
  const holdBody = useMemo(
    () => ({
      cusId: selectedCustomerObj?._id,
      order: { cart, finalBody },
      branchId,
      _id: isHeldOrder ? heldPayingObject?._id : null,
    }),
    [
      selectedCustomerObj?._id,
      branchId,
      finalBody,
      isHeldOrder,
      heldPayingObject?._id,
    ]
  );

  //hold order function
  const holdOrder = () => {
    addBillingHoldAPICall(
      holdBody,
      setOpenSuccessSnack,
      setOpenErrorSnack,
      setSnackMsg,
      clearStates
    );
  };

  //Click held products btn
  const clickHeldProductsBtn = () => {
    setHeldProductDialog(true);
    getBillingHeldOrdersAPICall({ branchId, cusId: selectedCustomerObj?._id });
  };
  //click pay held order btn in held dialog
  const clickPayHeldOrder = (order) => {
    setIsHeldOrder(true);
    setHeldPayingObject(order);
    setHeldProductDialog(false);
    const { cart } = order?.order;
    setCart(cart);
  };

  //----------------------------------------------------
  //*useEffects
  // category apis
  useEffect(() => {
    viewAllMaterialCategoryAPICall();
    viewAllReadymadeCategoriesAPICall();
    viewAllAccessoryCategoryAPICall();
    viewAllCustomersAPICall({ branchId });
    viewCardManualAPICall();
    getAllActiveEmployeesAPICall();
    getDiscountAPICall();
    // viewProductsAPITrigger()
  }, []);

  //Remove outCloth from response
  useEffect(() => {
    if (allMaterialCatList !== undefined) {
      removeOutCloth(allMaterialCatList);
      // setSelectedMaterialCatObj(allMaterialCatList[0]);
    } else {
      setSelectedMaterialCatObj(null);
    }
  }, [allMaterialCatList]);
  //Set first value of category to initial state to 3 types categories
  useEffect(() => {
    if (allMaterialCatList !== undefined) {
      // setSelectedMaterialCatObj(materialCategoryListFiltered[0]);
    } else {
      setSelectedMaterialCatObj(null);
    }
  }, [materialCategoryListFiltered]);
  useEffect(() => {
    if (readyMadeCategoryList !== undefined) {
      // setSelectedReadymadeCatObj(readyMadeCategoryList[0]);
    } else {
      setSelectedReadymadeCatObj(null);
    }
  }, [readyMadeCategoryList]);
  useEffect(() => {
    if (accessoryCategoryList !== undefined) {
      // setSelectedAccessoryCatObj(accessoryCategoryList[0]);
    } else {
      setSelectedAccessoryCatObj(null);
    }
  }, [accessoryCategoryList]);

  //Call Accessory subcategory API
  useEffect(() => {
    if (selectedAccessoryCatObj !== null) {
      const { _id } = selectedAccessoryCatObj;
      viewAllAccessorySubCategoryAPICall({ accessoryCategoryId: _id });
    }
  }, [selectedAccessoryCatObj]);
  //Call Readymade subcategory API
  useEffect(() => {
    if (selectedReadymadeCatObj !== null) {
      const { _id } = selectedReadymadeCatObj;
      viewAllReadymadeSubCategoryAPICall();
    }
  }, [selectedReadymadeCatObj]);
  //Call Product List API onChange of type, category, subCategory
  useEffect(() => {
    setOpenErrorSnack(false);
    if (orderType == 0) {
      //Readymade
      viewProductListForBilling(
        {
          type: orderType,
          categoryId: selectedReadymadeCatObj?._id,
          subcategoryId: selectedReadymadeSubCategory,
          branchId,
          date: today,
        },
        setIsLoading,
        setOpenErrorSnack,
        setSnackMsg
      );
    }
    if (orderType == 1) {
      //Accessories
      viewProductListForBilling(
        {
          type: orderType,
          categoryId: selectedAccessoryCatObj?._id,
          subcategoryId: selectedAccessorySubCategory,
          branchId,
          date: today,
        },
        setIsLoading,
        setOpenErrorSnack,
        setSnackMsg
      );
    }
    if (orderType == 2) {
      //Material
      viewProductListForBilling(
        {
          type: orderType,
          categoryId: selectedMaterialCatObj?._id,
          subcategoryId: null, //get subCategory id and assign here
          branchId,
          date: today,
        },
        setIsLoading,
        setOpenErrorSnack,
        setSnackMsg
      );
    }
  }, [
    orderType,
    selectedMaterialCatObj,
    selectedReadymadeCatObj,
    selectedAccessoryCatObj,
    selectedAccessorySubCategory,
    selectedReadymadeSubCategory,
  ]);

  //Call Calculate total on change of subtotal,shippingCharge,and discount
  useEffect(() => {
    calcTotalAmt(subTotal, shippingCharge, discount, creditAmount);
  }, [subTotal, shippingCharge, discount, creditAmount]);
  //Call calculate balance , change amounts
  useEffect(() => {
    calculateBalanceAndChange(totalAmount, paidAmount);
  }, [paidAmount, totalAmount]);

  //set response to front end product search array
  useEffect(() => {
    if (allProductsList !== undefined)
      setProductsSearched(
        allProductsList?.map((obj) => {
          return {
            ...obj,
            selectedSize:
              (obj?.size && obj?.size[0].brandUnit) || obj?.unitName,
          };
        })
      );
    else setProductsSearched([]);
  }, [allProductsList]);

  // console.log(productsSearched);
  // extract logged  user object
  useEffect(() => {
    extractLoggedInUserObj(allActiveEmpList, userPk);
  }, [userPk, allActiveEmpList]);
  //Calculate sub total amount
  useEffect(() => {
    calculateCartTotal(cart);
    createOrderInfoArray(cart);
  }, [cart]);
  //Set selected Customer id
  useEffect(() => {
    if (selectedCustomerObj !== null) {
      setCusId(selectedCustomerObj?._id);
    } else {
      setCusId(null);
    }
  }, [selectedCustomerObj]);

  //Final body creation
  useEffect(() => {
    createFinalBody();
  }, [paymentMethodBody, orderInfo, totalAmount]);

  //Call View Customer credits by cuId
  useEffect(() => {
    if (cusId !== null) {
      // get credit of customer
      getCreditOfCustomerAPICall({ _id: cusId, prefix: "ORD" });
      // get wallet amount of customer
      viewWalletTotalByCustomerIdAPICall({ cus_id: cusId });
    } else {
      store.dispatch(update_credit_of_customer({ singleCusCredit: undefined }));
    }
  }, [cusId]);

  //Call Create Customer credit for front end as per the customer credit api changes
  useEffect(() => {
    calTotCreditAmount(customerCredit);
    createCreditListForFrontend(customerCredit);
  }, [customerCredit]);

  //Call Create Selected Credit List
  useEffect(() => {
    createSelectedCreditItems(frontendCreditList);
  }, [frontendCreditList]);
  //Call Create Updated Customer List Fn
  useEffect(() => {
    if (customerList !== undefined) addCashOptionInCustomerList(customerList);
  }, [customerList]);

  //Calculate Maximum discount amount
  useEffect(() => {
    if (maximumDiscount !== undefined) {
      const maxDiscount = convertPercentageToAmount(
        subTotal,
        maximumDiscount?.discountAmount
      );
      setMaxDiscountAmt(maxDiscount);
    }
  }, [subTotal, maximumDiscount?.discountAmount]);

  useEffect(() => {
    viewChequeManualAPICall();
  }, [isModify]);

  // console.log(productsSearched);



  return (
    <div className="global-page-parent-container billing-parent-container">
      <p className="breadcrumb">Sales &gt; Billing</p>
      <div className="billing-main-container">
        <div className="billing-left-container">
          <div className="billing-left-first-container">
            <h4>Order Type</h4>
            <div
              className="billing-order-type-radio-btn"
              style={{ width: "15%" }}
            >
              <input
                defaultChecked
                type="radio"
                name="orderType"
                id="materialType"
                value={2}
                onChange={getOrderType}
              />
              <label htmlFor="materialType">Material</label>
            </div>
            <div className="billing-order-type-radio-btn">
              <input
                type="radio"
                name="orderType"
                id="readymadeType"
                value={0}
                onChange={getOrderType}
              />
              <label htmlFor="readymadeType">Readymade</label>
            </div>
            <div className="billing-order-type-radio-btn">
              <input
                type="radio"
                name="orderType"
                id="accessoriesType"
                value={1}
                onChange={getOrderType}
              />
              <label htmlFor="accessoriesType">Accessories</label>
            </div>
            <div className="billing-order-type-radio-btn">
              <input
                type="radio"
                name="orderType"
                id="oldStockType"
                value="oldStock"
                onChange={getOrderType}
              />
              <label htmlFor="oldStockType">Old Stock</label>
            </div>
            {/* <div className='billing-order-type-radio-btn' style={{width:'10%'}}>
            <input type="radio" name="orderType" id="selfType" value="self" onChange ={getOrderType}/>
            <label htmlFor="selfType">Self</label>
          </div> */}
          </div>
          <div className="billing-left-second-container">
            <div className="billing-left-second-dorpdown-container">
              {orderType !== "1" && (
                <div className="global-single-input billing-category-dropdown">
                  <Select
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                  >
                    {/* <MenuItem value="0">-Select Gender-</MenuItem> */}
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    {orderType === "0" && (
                      <>
                        <MenuItem value="boy">Boy</MenuItem>
                        <MenuItem value="girl">Girl</MenuItem>
                      </>
                    )}
                  </Select>
                </div>
              )}

              <div
                className="global-single-input billing-category-dropdown auto-complete"
                style={{ width: "60%" }}
              >
                {/* <Select
               value={category}
               onChange={(e)=>setCategory(e.target.value)}
             >
                <MenuItem value="0">-Select Category-</MenuItem>
             </Select> */}
                {orderType == 2 && (
                  <Autocomplete
                    sx={{ width: "100%" }}
                    value={selectedMaterialCatObj}
                    options={
                      allMaterialCatList || [
                        { materialCategoryName: "No data" },
                      ]
                    }
                    getOptionLabel={(option) => option?.materialCategoryName}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Category" />
                    )}
                    onChange={getSelectedMaterialCategory("material")}
                  />
                )}
                {orderType == 0 && (
                  <Autocomplete
                    sx={{ width: "100%" }}
                    value={selectedReadymadeCatObj}
                    options={
                      readyMadeCategoryList || [
                        { materialCategoryName: "No data" },
                      ]
                    }
                    getOptionLabel={(option) => option?.categoryName}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Category" />
                    )}
                    onChange={getSelectedMaterialCategory("readymade")}
                  />
                )}
                {orderType == 1 && (
                  <Autocomplete
                    sx={{ width: "100%" }}
                    value={selectedAccessoryCatObj}
                    options={
                      accessoryCategoryList || [
                        { materialCategoryName: "No data" },
                      ]
                    }
                    getOptionLabel={(option) => option?.accessoryCategory}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Category" />
                    )}
                    onChange={getSelectedMaterialCategory("accessory")}
                  />
                )}
              </div>
            </div>
            <div className="billing-left-second-search-container">
              <input
                type="text"
                placeholder="Search product by name..."
                value={searchKeyword}
                onChange={getProductSearchKeyword}
              />
              <SearchIcon className="billing-search-icon" />
            </div>
          </div>
          <div className="billing-left-third-container">
            {/* sub category */}
            {orderType == 1 && (
              <div className="billing-left-third-top-container">
                <h4>Sub Category</h4>
                <div className="billing-sub-category-radio-button">
                  {accessorySubcategoryList?.map((cat, i) => (
                    <>
                      <input
                        type="radio"
                        id={`subCategoryRadio${i}`}
                        className="custom-radio-input"
                        value={cat?._id}
                        checked={cat?._id === selectedAccessorySubCategory}
                        onChange={getOrderSubCategory("accessory")}
                      />
                      <label
                        htmlFor={`subCategoryRadio${i}`}
                        className="custom-radio-label"
                      >
                        <p style={{ color: "#0011f8" }}>
                          {cat?.accessorySubCategory}
                        </p>
                      </label>
                    </>
                  )) || <h4>No Subcategories</h4>}
                </div>
              </div>
            )}
            {orderType == 0 && (
              <div className="billing-left-third-top-container">
                <h4>Sub Category</h4>
                <div className="billing-sub-category-radio-button">
                  {readyMadeSubcategoryList?.map((cat, i) => (
                    <>
                      <input
                        type="radio"
                        id={`subCategoryRadio${i}`}
                        className="custom-radio-input"
                        name="subCatRadio"
                        value={cat?._id}
                        checked={cat?._id === selectedReadymadeSubCategory}
                        onChange={getOrderSubCategory("readymade")}
                      />
                      <label
                        htmlFor={`subCategoryRadio${i}`}
                        className="custom-radio-label"
                      >
                        <p style={{ color: "#0011f8" }}>
                          {cat?.subCategoryName}
                        </p>
                      </label>
                    </>
                  )) || <h4>No Subcategories</h4>}
                </div>
              </div>
            )}

            {/* product list */}
            <div className="billing-left-third-top-container">
              <h4>Products</h4>
              <div className="billing-product-list-container">
                {isLoading ? (
                  <>
                    <div className="billing-single-product-container">
                      <Skeleton width={"100%"} height="200px" />
                      <Skeleton width={"100%"} height="20px" />
                      <div className="billing-product-list-select-container">
                        <Skeleton width={"60%"} height="20px" />
                        <Skeleton width={"30%"} height="50px" />
                        {/* button alongside text  */}
                      </div>
                    </div>
                    <div className="billing-single-product-container">
                      <Skeleton width={"100%"} height="200px" />
                      <Skeleton width={"100%"} height="20px" />
                      <div className="billing-product-list-select-container">
                        <Skeleton width={"60%"} height="20px" />
                        <Skeleton width={"30%"} height="50px" />
                        {/* button alongside text  */}
                      </div>
                    </div>
                    <div className="billing-single-product-container">
                      <Skeleton width={"100%"} height="200px" />
                      <Skeleton width={"100%"} height="20px" />
                      <div className="billing-product-list-select-container">
                        <Skeleton width={"60%"} height="20px" />
                        <Skeleton width={"30%"} height="50px" />
                        {/* button alongside text  */}
                      </div>
                    </div>
                    <div className="billing-single-product-container">
                      <Skeleton width={"100%"} height="200px" />
                      <Skeleton width={"100%"} height="20px" />
                      <div className="billing-product-list-select-container">
                        <Skeleton width={"60%"} height="20px" />
                        <Skeleton width={"30%"} height="50px" />
                        {/* button alongside text  */}
                      </div>
                    </div>
                  </>
                ) : openErrorSnack ? (
                  <div> Connection time out !!!</div>
                ) : (
                  <>
                    {(productsSearched.length > 0 &&
                      productsSearched?.map((prod, i) => {
                        if (prod?.closingStock > 0) {
                          return (
                            <div
                              className="billing-single-product-container"
                              key={i}
                            >
                              <img
                                src={
                                  (prod?.imageUrl !== null &&
                                    prod?.imageUrl[0]) ||
                                  NoImage
                                }
                              />
                              {/* <p >Price : 10000.00</p> */}
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <p style={{ fontSize: "0.84" }}>
                                  Price :
                                  {findUnitPrice(prod, prod?.selectedSize)}
                                </p>
                                <p
                                  style={{
                                    fontSize: "0.6rem",
                                    color: "#0011f8",
                                  }}
                                >
                                  {orderType == 2
                                    ? `Stock : ${prod?.stock?.toFixed(2)} `
                                    : `Stock : ${
                                        prod?.size?.filter(
                                          (obj) => obj?.stock > 0
                                        )[0]?.stock
                                      }`}
                                  {/* Stock : {prod?.stock?.toFixed(2)} */}
                                </p>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginTop: "0",
                                }}
                              >
                                <p
                                  style={{
                                    textTransform: "capitalize",
                                    fontWeight: "400",
                                    width: "fit-content",
                                    color: "#7c7d7d",
                                  }}
                                >
                                  {prod?.productName}
                                </p>
                                {/* <p style={{fontSize:'0.6rem'}}>Price : 10000.00</p> */}
                              </div>
                              <div className="billing-product-list-select-container">
                                {orderType == 2 ? (
                                  <p>{prod?.unitName}</p>
                                ) : (
                                  <Select
                                    sx={{
                                      "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                        {
                                          border:
                                            "1px solid rgb(206, 199, 199)",
                                        },
                                    }}
                                    value={prod?.selectedSize}
                                    onChange={(e) =>
                                      (prod.selectedSize = e.target.value)
                                    }
                                  >
                                    {prod?.size?.map((s, i) => {
                                      if (s?.stock > 0) {
                                        return (
                                          <MenuItem
                                            value={s?.brandUnit}
                                            key={i}
                                          >
                                            {s?.brandUnit}
                                          </MenuItem>
                                        );
                                      }
                                    })}
                                  </Select>
                                )}
                                <button
                                  onClick={clickAddToCartBtn(
                                    prod,
                                    prod?.selectedSize
                                  )}
                                >
                                  +
                                </button>
                              </div>
                              {prod?.offer.length !== 0 && (
                                <div className="product-offer-tag">
                                  <img
                                    src={offerTag}
                                    alt="offer-tag"
                                    className="food-offer-tag"
                                  />
                                  {prod?.offer[0].offerTypes ===
                                  "percentage" ? (
                                    <p className="offer-value">{`${prod?.offer[0].percentage[0]} %`}</p>
                                  ) : prod?.offer[0].offerTypes === "buyget" ? (
                                    <p
                                      className="offer-value"
                                      style={{ bottom: "77%" }}
                                    >
                                      {`B${prod?.offer[0].buyGet.buy}`} <br />
                                      {`G${prod?.offer[0].buyGet.get}`}
                                    </p>
                                  ) : prod?.offer[0].offerTypes ===
                                    "quantity" ? (
                                    <p
                                      className="offer-value"
                                      style={{ bottom: "70%" }}
                                    >
                                      {`${prod?.offer[0].quantity.quantity}x`}{" "}
                                      <br />
                                      {`${prod?.offer[0].quantity.price}`}
                                    </p>
                                  ) : (
                                    <p className="offer-value">--</p>
                                  )}
                                </div>
                              )}
                            </div>
                          );
                        }
                      })) || <p>No Products in stock</p>}
                  </>
                )}

                {/* <div className="billing-single-product-container">
                  <img src={tShirt} />
                  <p>T Shirt</p>
                  <div className="billing-product-list-select-container">
                    <Select
                      sx={{
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid rgb(206, 199, 199)",
                        },
                      }}
                    ></Select>
                    <button>+</button>
                  </div>
                </div>

                <div className="billing-single-product-container">
                  <img src={Shirt} />
                  <p>Shirt</p>
                  <div className="billing-product-list-select-container">
                    <Select
                      sx={{
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid rgb(206, 199, 199)",
                        },
                      }}
                    ></Select>
                    <button>+</button>
                  </div>
                  <div className="product-offer-tag">
                    <img
                      src={offerTag}
                      alt="offer-tag"
                      className="food-offer-tag"
                    />
                    <p className="offer-value">10%</p>
                  </div>
                </div>

                <div className="billing-single-product-container">
                  <img src={pant} />
                  <p>Pant</p>
                  <div className="billing-product-list-select-container">
                    <Select
                      sx={{
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid rgb(206, 199, 199)",
                        },
                      }}
                    ></Select>
                    <button>+</button>
                  </div>
                  <div className="product-offer-tag">
                    <img
                      src={offerTag}
                      alt="offer-tag"
                      className="food-offer-tag"
                    />
                    <p className="offer-value">10%</p>
                  </div>
                </div> */}
              </div>
            </div>

            {/* button container  */}
            <div className="billing-left-third-top-container">
              <div className="billing-left-bottom-button-select-container">
                <div className=" billing-select auto-complete">
                  <Autocomplete
                    sx={{ width: "100%" }}
                    value={selectedSalesManObj}
                    options={allActiveEmpList || [{ staff_name: "No data" }]}
                    getOptionLabel={(option) => option?.staff_name}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select salesman" />
                    )}
                    // // value={(option)=>option?._id}
                    // filterOptions={filterOptions}
                    onChange={(e, newValue) => setSelectedSalesManObj(newValue)}
                  />

                  {/* <Select
                    value={salesMan}
                    onChange={(e) => setSalesMan(e.target.value)}
                  >
                    <MenuItem value="0">Salesman</MenuItem>
                  </Select> */}
                </div>
                <div className="billing-left-bottom-button-select-right-container">
                  <div className="billing-select auto-complete">
                    <Autocomplete
                      sx={{ width: "100%" }}
                      value={selectedCustomerObj}
                      options={
                        updatedCustomerList || [{ cusId: "No data", name: "" }]
                      }
                      getOptionLabel={(option) =>
                        `${option?.cusId}-${option?.name}`
                      }
                      renderInput={(params) => (
                        <TextField {...params} placeholder="mobile, name..." />
                      )}
                      // value={(option)=>option?._id}
                      filterOptions={filterOptions}
                      onChange={(e, newValue) =>
                        setSelectedCustomerObj(newValue)
                      }
                    />

                    {/* <Select
                      value={customerName}
                      onChange={(e) => setCustomerName(e.target.value)}
                    >
                      <MenuItem value="0">Customer Name</MenuItem>
                    </Select> */}
                  </div>
                  <button
                    onClick={openAddCusDialogFn}
                    className="billing-add-cus-btn"
                  >
                    New Customer
                  </button>
                </div>
              </div>
              <div className="billing-left-bottom-button-container">
                <button
                  className="billing-view-order-btn"
                  onClick={clickViewOrderBtn}
                >
                  View Order
                </button>
                {/* <button className="billing-new-order-btn">New Order</button> */}
                <button
                  onClick={clickHeldProductsBtn}
                  className="billing-held-product-btn"
                >
                  Held Products
                </button>
                {/* <button className="billing-wallet-btn">Wallet</button> */}
                {/* <button className="billing-view-order-btn">Credit</button> */}
                {/* <button className="billing-add-tips-btn">Add Tips</button> */}
              </div>
            </div>
          </div>
        </div>
        <div className="billing-right-container">
          {/* <div className="billing-right-top-container">
            <p style={{ width: "50%" }}>Salesman</p>
            <p>: {selectedSalesManObj?.staff_name || "-NIL-"}</p>
          </div> */}
          <div className="billing-right-second-container">
            {/* cart */}
            {/* <div className="billing-right-cart-container"></div>
              <div className="billing-cart-left">
                <img src={kurtiImage} alt="" />
              </div>
              <div className="billing-cart-middle">
                <p className="cart-main-head">Kurti</p>
                <p className="cart-sub-head">@ 10% Discount</p>
                <div className="cart-button-container">
                  <button>-</button>
                  <input type="text" value="2" />
                  <button>+</button>
                  <CloseIcon sx={{ fontSize: "1rem" }} />
                  <p>Rs 500</p>
                </div>
              </div>
              <div className="billing-cart-right">
                <i class="bi bi-trash3 delete-icon"></i>

                <div className="billing-cart-total-amt-container">
                  <p>Rs 500</p>
                </div>
              </div>
            </div> */}
            {/* <p className="cart-total-no">Total Items : {cart.length}</p> */}
            <div className="cart-section">
              {cart.length > 0 ?
                cart.map((prod, i) => (
                  <div className="billing-right-cart-container">
                    <div className="billing-cart-left">
                      <img
                        src={
                          (prod?.imageUrl !== null && prod?.imageUrl[0]) ||
                          NoImage
                        }
                        alt="product"
                      />
                    </div>
                    <div className="billing-cart-middle">
                      <p className="cart-main-head">{prod?.productName}</p>
                      {/* <p className="cart-sub-head">@ 10% Discount</p> */}
                      <p className="cart-sub-head">
                        Size : {prod?.selectedSize}
                      </p>
                      <div className="cart-button-container">
                        <button onClick={decrementQty(prod)}>-</button>
                        <input
                          readOnly={
                            prod?.selectedSize?.toLowerCase() === "yard"
                              ? false
                              : prod?.selectedSize?.toLowerCase() === "meter"
                              ? false
                              : true
                          }
                          type="text"
                          value={prod?.selectedQty}
                          onChange={
                            prod?.selectedSize?.toLowerCase() === "yard"
                              ? getMaterialQtyEntered(cart, i)
                              : prod?.selectedSize?.toLowerCase() === "meter"
                              ? getMaterialQtyEntered(cart, i)
                              : undefined
                          }
                        />
                        <button
                          onClick={clickAddToCartBtn(prod, prod?.selectedSize)}
                        >
                          +
                        </button>
                        {/* <CloseIcon sx={{ fontSize: "1rem" }} />  */}
                        <input
                          className="billing-cart-unit-price-input"
                          value={prod.unitPrice}
                          onChange={getUnitPriceOfProduct(prod, i)}
                        />
                        {/* <p>Rs 300</p> */}
                      </div>
                    </div>
                    <div className="billing-cart-right">
                      <IconButton
                        sx={{ width: "fit-content", marginLeft: "auto" }}
                        onClick={deleteFromCart(i)}
                      >
                        {" "}
                        <i class="bi bi-trash3 delete-icon"></i>
                      </IconButton>

                      <div className="billing-cart-total-amt-container">
                        <p>{prod?.itemTotal?.toFixed(2)}</p>
                      </div>
                    </div>
                  </div>
                ))
              :
              <div className="billing-right-empty-cart-container">
                <img src={emptyCartImg} alt="" />
                <p>Your cart is empty</p>
              </div>
              }
            </div>

            {/* payment section */}

            <div className="billing-right-payment-container paymentSection">
              {/* <div className="work-order-payment-second-container"> */}
              <div className="work-order-payments-input-container billing-input">
                <p>Sub Total</p>
                <input readOnly value={subTotal} />
              </div>
              <div className="work-order-payments-input-container billing-input">
                <p>Shipping Charge</p>
                <input
                  type={"number"}
                  min={0}
                  value={shippingCharge}
                  onChange={getPaymentFormStates("shippingCharge")}
                  placeholder="Enter shipping charge..."
                  onWheel={(e) => e.target.blur()}
                />
              </div>
              <div className="work-order-payments-input-container inner-btn billing-input btn-inputs">
                <p>Coupon Code</p>
                <input
                  disabled
                  value={couponCode}
                  onChange={getPaymentFormStates("couponCode")}
                />
                <button disabled className="btn btn-primary payment-coupon-btn">
                  Apply
                </button>
              </div>
              <div className="work-order-payments-input-container inner-btn billing-input btn-inputs">
                <p>Reward Points</p>
                <input
                  disabled
                  type={"number"}
                  min={0}
                  value={rewardPoints}
                  onChange={getPaymentFormStates("rewardPoints")}
                  onWheel={(e) => e.target.blur()}
                />
                <button disabled className="btn btn-primary payment-reward-btn">
                  Add
                </button>
              </div>
              <div className="work-order-payments-input-container billing-input">
                <p>Discount</p>
                <input
                  type={"number"}
                  min={0}
                  value={discount}
                  onChange={getPaymentFormStates("discount")}
                  onWheel={(e) => e.target.blur()}
                />
              </div>
              <div className="work-order-payments-input-container billing-input">
                <p>Paid Amount</p>
                <input
                  type={"number"}
                  min={0}
                  value={paidAmount}
                  onChange={getPaymentFormStates("paidAmount")}
                  onWheel={(e) => e.target.blur()}
                />
              </div>
              
              
              <div className="work-order-payments-input-container inner-btn billing-input btn-inputs">
                <p>Wallet</p>
                <input
                  type={"number"}
                  min={0}
                  value={wallet}
                  onChange={getPaymentFormStates("wallet")}
                  onWheel={(e) => e.target.blur()}
                />
                {cusId !== "cash" && (
                  <button
                    className="btn btn-primary payment-credit-btn"
                    style={{ backgroundColor: "#53d3c2" }}
                    onClick={redeemFromWallet(totalAmount,wallet)}
                  >
                    Redeem
                  </button>
                )}
              </div>
              <div className="work-order-payments-input-container inner-btn credit-amount-container billing-input btn-inputs">
                <p>Credit Amount</p>
                <input readOnly type={"number"} min={0} value={creditAmount} />
                <button
                  className="btn btn-primary payment-credit-btn"
                  onClick={() => setOpenPreviousCreditDialog(true)}
                >
                  Add
                </button>
              </div>
              <div
                className="work-order-payments-total-amount-container"
                style={{ backgroundColor: "#fff",height:"40px" }}
              >
                <p>Total Amount ()</p>
                <p className="payment-total-amount">{totalAmount.toFixed(2)}</p>
              </div>
              <div
                className="work-order-payments-change-amount-container"
                style={{ backgroundColor: "#fff",padding:"0 6.5% 0%" }}
              >
                <p>Change ()</p>
                <p className="payment-total-amount">
                  {changeAmount.toFixed(2)}
                </p>
              </div>
              <div
                className="work-order-payments-change-amount-container "
                style={{ backgroundColor: "#fff",padding:"0 6.5% 0%" }}
              >
                <p>Balance ()</p>
                <p className="payment-total-amount">
                  {balanceAmount.toFixed(2)}
                </p>
              </div>
              <div className="payment-os-balance">
                <p>O/S Balance&nbsp;:&nbsp;{osBalance.toFixed(2)}</p>
              </div>

              <div className="payment-method-container">
                <div className="payment-method-top-container">
                  <p style={{margin:"0"}}>Payment Method</p>
                  <div className="payment-method-checkbox-container">
                    <input
                      onClick={clickMultipleCheck}
                      type="checkbox"
                      disabled={creditAmount > 0}
                    />
                    <label>Multiple</label>
                  </div>
                </div>





                <div className="payment-method-type-container" style={{margin:"1% 0"}}>
                  <div className="payment-method-type-input-container">
                    <Select
                      value={paymentMethod1}
                      onChange={(e) => setPaymentMethod1(e.target.value)}
                      sx={{
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid rgb(206, 199, 199)",
                        },
                      }}
                      // value={paymentMethod}
                      // onChange={(e) => setPaymentMethod(e.target.value)}
                    >
                      <MenuItem value="card">Card</MenuItem>
                      <MenuItem value="cash">Cash</MenuItem>
                      <MenuItem value="cheque">Cheque</MenuItem>
                    </Select>
                  </div>


                  <div className="payment-method-card-type-container">
                    {paymentMethod1 === "card" ? (
                      <>
                        <Select
                          sx={{
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              border: "1px solid rgb(206, 199, 199)",
                            },
                          }}
                          value={vendor1}
                          onChange={(e) => setVendor1(e.target.value)}
                        >
                          {cardManual?.map((card, i) => (
                            <MenuItem value={card?.cardName} key={i}>
                              {card?.cardName?.toUpperCase()}
                            </MenuItem>
                          )) || <MenuItem>No cards added</MenuItem>}
                        </Select>
                        {multipleCheck && (
                          <input
                            placeholder="Enter Amount..."
                            value={paidAmount1}
                            onChange={(e) => setPaidAmount1(e.target.value)}
                          />
                        )}
                      </>
                    ) : paymentMethod1 === "cash" ? (
                      multipleCheck ? (
                        <input
                          placeholder="Enter Amount..."
                          value={paidAmount1}
                          onChange={(e) => setPaidAmount1(e.target.value)}
                        />
                      ) : (
                        <input
                          readOnly
                          value={`Collect amount ${paidAmount}`}
                        />
                      )
                    ) : paymentMethod1 === "cheque" ? (

                      multipleCheck ? (
                        <>
                          <Select
                            value={vendor1}
                            onChange={(e) => setVendor1(e.target.value)}
                          >
                            {chequeManual?.map((Bank, i) => (
                              <MenuItem value={Bank?.bankName} key={i}>
                                {Bank?.bankName?.toUpperCase()}
                              </MenuItem>
                            )) || <MenuItem>No cards added</MenuItem>}
                            {/* <MenuItem value="visa">Visa</MenuItem>
                      <MenuItem value="master card">Master Card</MenuItem> */}
                          </Select>
  
                          <>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                              }}
                            >
                              <input
                                // style={{
                                //   width: "9rem",
                                // }}
                                type="text"
                                placeholder="Cheque No"
                                value={chequeNo}
                                onChange={(e) => setChequeNo(e.target.value)}
                              />
  
                              {/* <input
                              type="text"
                              placeholder="Date"
                              value={paidAmount2}
                              onChange={(e) =>
                                setPaidAmount2(e.target.value)
                              }
  
                            /> */}
                              <Datepicker
                                type="date"
                                // backgroundColor="#f1f2ff"
  
                                onChange={(e) => setChequeDate(e.target.value)}
                                value={chequeDate}
                              />
                            </div>
  
                            <input
                              type="text"
                              placeholder="Enter amount..."
                              value={paidAmount1}
                              onChange={(e) => setPaidAmount1(e.target.value)}
                            />
                          </>
                        </>
                      ) : (

                        <>
                        <Select
                          value={vendor1}
                          onChange={(e) => setVendor1(e.target.value)}
                        >
                          {chequeManual?.map((Bank, i) => (
                            <MenuItem value={Bank?.bankName} key={i}>
                              {Bank?.bankName?.toUpperCase()}
                            </MenuItem>
                          )) || <MenuItem>No cards added</MenuItem>}
                          {/* <MenuItem value="visa">Visa</MenuItem>
                        <MenuItem value="master card">Master Card</MenuItem> */}
                        </Select>

                        <>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                            }}
                          >
                            <input
                              // style={{
                              //   width: "9rem",
                              // }}
                              type="text"
                              placeholder="Cheque No"
                              value={chequeNo}
                              onChange={(e) => setChequeNo(e.target.value)}
                            />

                            {/* <input
                                type="text"
                                placeholder="Date"
                                value={paidAmount2}
                                onChange={(e) =>
                                  setPaidAmount2(e.target.value)
                                }

                              /> */}
                            <Datepicker
                              type="date"
                              // backgroundColor="#f1f2ff"

                              onChange={(e) => setChequeDate(e.target.value)}
                              value={chequeDate}
                            />
                          </div>

                          <input
                            type="text"
                            placeholder="Enter amount..."
                            value={paidAmount}
                            onChange={(e) => setPaidAmount(e.target.value)}
                          />
                        </>
                      </>
                      )
                      
                    ) : undefined}
                  </div>

                </div>

                {multipleCheck === true && (
                  <>
                    <div className="payment-method-type-container">
                      <div className="payment-method-type-input-container">
                        <Select
                          value={paymentMethod2}
                          onChange={(e) => setPaymentMethod2(e.target.value)}
                        >
                          <MenuItem value="card">Card</MenuItem>
                          <MenuItem value="cash">Cash</MenuItem>
                          <MenuItem value="cheque">Cheque</MenuItem>
                        </Select>
                      </div>
                      <div className="payment-method-card-type-container">
                        {paymentMethod2 === "card" ? (
                          <>
                            <Select
                              value={vendor2}
                              onChange={(e) => setVendor2(e.target.value)}
                            >
                              {cardManual?.map((card, i) => (
                                <MenuItem value={card?.cardName} key={i}>
                                  {card?.cardName?.toUpperCase()}
                                </MenuItem>
                              )) || <MenuItem>No cards added</MenuItem>}
                            </Select>
                            {multipleCheck && (
                              <input
                                type="text"
                                placeholder="Enter amount..."
                                value={paidAmount2}
                                onChange={(e) => setPaidAmount2(e.target.value)}
                              />
                            )}
                          </>
                        ) : paymentMethod2 === "cash" ? (
                          <input
                            placeholder="Enter Amount"
                            value={paidAmount2}
                          onChange={(e) => setPaidAmount2(e.target.value)}
                          />
                        ) : paymentMethod2 === "cheque" ? (
                          <>
                            <Select
                              value={vendor2}
                              onChange={(e) => setVendor2(e.target.value)}
                            >
                              {chequeManual?.map((Bank, i) => (
                                <MenuItem value={Bank?.bankName} key={i}>
                                  {Bank?.bankName?.toUpperCase()}
                                </MenuItem>
                              )) || <MenuItem>No cards added</MenuItem>}
                              {/* <MenuItem value="visa">Visa</MenuItem>
                        <MenuItem value="master card">Master Card</MenuItem> */}
                            </Select>

                            <>
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                }}
                              >
                                <input
                                  // style={{
                                  //   width: "9rem",
                                  // }}
                                  type="text"
                                  placeholder="Cheque No"
                                  value={chequeNo1}
                                  onChange={(e) => setChequeNo1(e.target.value)}
                                />

                                {/* <input
                                type="text"
                                placeholder="Date"
                                value={paidAmount2}
                                onChange={(e) =>
                                  setPaidAmount2(e.target.value)
                                }

                              /> */}
                                <Datepicker
                                  type="date"
                                  // backgroundColor="#f1f2ff"

                                  onChange={(e) =>
                                    setChequeDate1(e.target.value)
                                  }
                                  value={chequeDate1}
                                />
                              </div>

                              <input
                                type="text"
                                placeholder="Enter amount..."
                                value={paidAmount2}
                                onChange={(e) => setPaidAmount2(e.target.value)}
                              />
                            </>
                          </>
                        ) : undefined}
                      </div>
                    </div>

                    <div className="payment-method-type-container">
                      <div className="payment-method-type-input-container">
                        <Select
                          value={paymentMethod3}
                          onChange={(e) => setPaymentMethod3(e.target.value)}
                        >
                          <MenuItem value="card">Card</MenuItem>
                          <MenuItem value="cash">Cash</MenuItem>
                          <MenuItem value="cheque">Cheque</MenuItem>
                        </Select>
                      </div>
                      <div className="payment-method-card-type-container">
                        {paymentMethod3 === "card" ? (
                          <>
                            <Select
                              value={vendor4}
                              onChange={(e) => setVendor4(e.target.value)}
                            >
                              {cardManual?.map((card, i) => (
                                <MenuItem value={card?.cardName} key={i}>
                                  {card?.cardName?.toUpperCase()}
                                </MenuItem>
                              )) || <MenuItem>No cards added</MenuItem>}
                            </Select>
                            {multipleCheck && (
                              <input
                                type="text"
                                placeholder="Enter amount..."
                                value={paidAmount4}
                                onChange={(e) => setPaidAmount4(e.target.value)}
                              />
                            )}
                          </>
                        ) : paymentMethod3 === "cash" ? (
                          <input
                          placeholder="Enter Amount"
                          value={paidAmount4}

                        onChange={(e) => setPaidAmount4(e.target.value)}
                        />


                        ) : paymentMethod3 === "cheque" ? (
                          <>
                            <Select
                              value={vendor4}
                              onChange={(e) => setVendor4(e.target.value)}
                            >
                              {chequeManual?.map((Bank, i) => (
                                <MenuItem value={Bank?.bankName} key={i}>
                                  {Bank?.bankName?.toUpperCase()}
                                </MenuItem>
                              )) || <MenuItem>No cards added</MenuItem>}
                              {/* <MenuItem value="visa">Visa</MenuItem>
                        <MenuItem value="master card">Master Card</MenuItem> */}
                            </Select>

                            <>
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                }}
                              >
                                <input
                                  // style={{
                                  //   width: "9rem",
                                  // }}
                                  type="text"
                                  placeholder="Cheque No"
                                  value={chequeNo2}
                                  onChange={(e) => setChequeNo2(e.target.value)}
                                />

                                {/* <input
                                type="text"
                                placeholder="Date"
                                value={paidAmount2}
                                onChange={(e) =>
                                  setPaidAmount2(e.target.value)
                                }

                              /> */}
                                <Datepicker
                                  type="date"
                                  // backgroundColor="#f1f2ff"

                                  onChange={(e) =>
                                    setChequeDate2(e.target.value)
                                  }
                                  value={chequeDate2}
                                />
                              </div>

                              <input
                                type="text"
                                placeholder="Enter amount..."
                                value={paidAmount4}
                                onChange={(e) => setPaidAmount4(e.target.value)}
                              />
                            </>
                          </>
                        ) : undefined}
                      </div>
                    </div>
                  </>
                )}
                <div className="payment-method-bottom-container" style={{margin:"1% 0"}}>
                  <button className="clear-list-button" onClick={clearStates} style={{height:"35px"}}>
                    Clear List
                  </button>
                  <button
                    className="hold-button"
                    onMouseDown={formPaymentMethodBody}
                    onClick={holdOrder} style={{height:"35px"}}
                  >
                    Hold
                  </button>
                </div>
                <button
                  disabled={
                    orderInfo.length == 0 ||
                    (selectedCustomerObj?._id === "cash"
                      ? paidAmount === totalAmount
                        ? false
                        : true
                      : false)
                  }
                  // onClick={() => setInvoiceDialog(true)}
                  onMouseDown={formPaymentMethodBody}
                  onClick={finalPlaceOrder}
                  className="payment-place-order-btn" style={{height:"35px"}}
                >
                  {isHeldOrder ? "Place held order" : "Place Order"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* cart details strip  */}
      {cart.length > 0 && (
        <div className="billing-bottom-strip">
          <div className="cart-strip-child">
            <img src={cartIcon} alt="cart icon" />
          </div>
          <div className="cart-strip-child">
            <div className="cart-strip-head">Total Items</div>
            <div className="cart-strip-value">{cart.length}</div>
          </div>
          <div className="cart-strip-child">|</div>
          <div className="cart-strip-child">
            <div className="cart-strip-head price">Total Amount</div>
            <div className="cart-strip-value price">{subTotal.toFixed(2)}</div>
          </div>
        </div>
      )}
      {/* held product dialog */}
      <Dialog
        open={heldProductDialog}
        maxWidth="lg"
        onKeyDown={(e) => e.key === "Escape" && setHeldProductDialog(false)}
      >
        <div className="held-product-dialog">
          <h3>Held Products</h3>
          <table className="held-products-table">
            <thead>
              <tr>
                <th>Sl. No</th>
                <th>Customer Name</th>
                <th>Total Amount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {heldOrdersList !== undefined ? (
                heldOrdersList?.map((order, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{order?.customerName}</td>
                    <td>{order?.order?.finalBody?.totalAmount}</td>
                    <td>
                      <span
                        className="held-products-pay"
                        onClick={() => clickPayHeldOrder(order)}
                      >
                        Pay
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>No data</td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="held-product-button-container">
            <button
              onClick={() => setHeldProductDialog(false)}
              className="btn btn-secondary-outlined"
            >
              Cancel
            </button>
          </div>
        </div>
      </Dialog>

      {/* INVOICE */}
      <Dialog
        open={invoiceDialog}
        maxWidth="lg"
        onClose={() => setInvoiceDialog(false)}
        onKeyDown={(e) => e.key === "Escape" && setInvoiceDialog(false)}
      >
        {/* <Invoice data={invoiceData} /> */}
        <div className="billing-parent-wrapper" onScroll={hideInvoicePrint}>
          <div ref={(el) => (invoicePrint = el)}>
            <Invoice
              data={invoiceData}
              // invoiceData={generatedInvoice}
            />
          </div>
          <div className="invoice-print-icon-container" id="invoicePrintIcon">
            {/* <Tooltip title="Print" placement="right" arrow> */}
            <ReactToPrint
              trigger={() => (
                <IconButton
                  className="invoice-print-btn"
                  sx={{
                    backgroundColor: "#d3e3ec",
                  }}
                  // onClick={invoiceDialogClose}
                >
                  <PrintOutlined
                    className="print-icon"
                    sx={{ color: "#000" }}
                  />
                </IconButton>
              )}
              content={() => invoicePrint}
            />
            {/* </Tooltip> */}
          </div>
        </div>
      </Dialog>

      {/* Add customer dialog  */}
      <Dialog
        open={openAddCusDialog}
        maxWidth="lg"
        onClose={closeAddCusDialogFn}
        onKeyDown={(e) => e.key === "Escape" && setOpenAddCusDialog(false)}
      >
        <AddCustomer isDialog={true} closeDialog={closeAddCusDialogFn} />
      </Dialog>

      {/* Previous Credits Dialog  */}
      <Dialog
        open={openPreviousCreditDialog}
        maxWidth="lg"
        onKeyDown={(e) =>
          e.key === "Escape" && setOpenPreviousCreditDialog(false)
        }
      >
        <div className="held-product-dialog">
          <h3>Credits</h3>
          <table className="held-products-table">
            <thead>
              <tr>
                <th>Order No</th>
                <th>Order Date</th>
                <th>Last Paid Date</th>
                <th>Net Amount</th>
                <th>Paid Amount</th>
                <th>Discount</th>
                <th>Balance</th>
                <th>select</th>
              </tr>
            </thead>
            <tbody>
              {(frontendCreditList?.length > 0 &&
                frontendCreditList?.map((r, i) => (
                  <tr key={i}>
                    <td>{r?.purchaseId}</td>
                    <td>{r?.purchaseDate}</td>
                    <td>{r?.lastPaidDate}</td>
                    <td>{r?.netAmount}</td>
                    <td>{r?.paidAmount}</td>
                    <td>{r?.discount}</td>
                    <td>{r?.balance}</td>
                    <td>
                      {/* <span className="held-products-pay">Pay</span> */}
                      <input
                        type="checkbox"
                        className="global-checkbox"
                        name=""
                        id=""
                        checked={r?.isChecked}
                        onClick={checkOrUncheckCreditItem(i)}
                      />
                    </td>
                  </tr>
                ))) || (
                <tr>
                  <td colSpan={8}>No data</td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="held-product-button-container">
            <button
              onClick={() => setOpenPreviousCreditDialog(false)}
              className="btn btn-secondary-outlined"
            >
              Cancel
            </button>
            <button
              className="btn btn-secondary"
              onClick={submitPreviousCreditDialog}
            >
              Submit
            </button>
          </div>
        </div>
      </Dialog>

      {/* View orders Dialog  */}
      <Dialog
        open={viewOrderDialogOpen}
        maxWidth="lg"
        onClose={() => setViewOrderDialogOpen(false)}
        onKeyDown={(e) => e.key === "Escape" && setViewOrderDialogOpen(false)}
      >
        <div style={{ width: "1200px" }}>
          <OrderList isDialog={true} />
        </div>
      </Dialog>

      <SuccessSnackbar
        open={openSuccessSnack}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={openErrorSnack}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />

      {/* Backdrop on loading  */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 500 }}
        open={billLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
