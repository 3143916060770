import React,{useState,useRef} from 'react'
import {Select,IconButton,Dialog, Autocomplete, TextField, Skeleton} from '@mui/material'
import '../../../../css/Report/grnReport.css'
import ReactToPrint from "react-to-print";
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { grnReportAPICall } from '../../../../API/Report/grnReportAPI';
import { convertDateFormat, getCurrentTime, today } from '../../../../Js/Date';

import { viewAllBranchesAPICall } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import { getAllGrnNoListAPICall } from '../../../../API/Purchase Manager/grnAPI';
import { sortGeneral } from '../../../../Js/generalFunctions';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

export const GRNReport = () => {
   let grnReportPrint=useRef()
  // grn report list
   const grnReportList=useSelector((state)=>state.grnReportSlice.value)
  // all branch list
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  // role based rendering
  const userRole = useSelector((state) => state.userRoleSlice.value);
  // login response
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
 // grn no list
 const grnNoList=useSelector((state)=>state.allGrnNoSlice.value)


  const [grnReportView,setGrnReportView]=useState(false)
  const [singleViewId,setSingleViewId]=useState(null)
  const [singleViewData,setSingleViewData]=useState([])
  const [time, setTime] = useState(null);

  const [branch,setBranch]=useState(null)
  const [transId,setTransId]=useState(null)
  const [fromDate,setFromDate]=useState('')
  const [toDate,setToDate]=useState('')
  const [isLoading,setIsLoading]=useState(false)
  const [isAscending,setIsAscending]=useState(false)
  // map grn response to stata
  const [grnFilter,setGrnFilter]=useState([])
  const [branchWithAll,setBranchWithAll]=useState([])

  // get current time fn
  useEffect(() => {
    setInterval(() => {
      setTime(`${new Date().getHours()}:${new Date().getMinutes()}`);
    }, 1000);
  }, [time]);
 

  useEffect(()=>{
    grnReportAPICall({},setIsLoading)
    viewAllBranchesAPICall()
},[])

  const grnReportSingleView=(id)=>{
    setGrnReportView(true)
    setSingleViewId(id)
  }

  // grn no list api call
  useEffect(()=>{
    if(userRole==="user"){
      getAllGrnNoListAPICall({branchId:loginResponse.branchId})
    }
    else{
      getAllGrnNoListAPICall({branchId:branch})
    }
    
  },[branch])

 // extract grn single data
  useEffect(()=>{
   let filterData=[]
   filterData=grnReportList?.filter((obj)=>{
    return obj._id===singleViewId
   })
   setSingleViewData(filterData)
  },[singleViewId])


  const getFromDate=(e)=>{
    setFromDate(e.target.value)
  }
  const getToDate=(e)=>{
    setToDate(e.target.value)
  }


  // add -All- into branch list
  useEffect(()=>{
    if(allBranchesList !== undefined){
      let allBranchList=allBranchesList?.map((obj)=>{
        return obj
      })
      setBranchWithAll([{storeCode:"-All",branchName:""},...allBranchList])
    }
  },[allBranchesList])


  // grn submit button function
  const grnSubmitBtnFn=()=>{
    grnReportAPICall({
      branchId:branch==="-All"?null:branch,
      transNo:transId,
      fromDate:fromDate !== '' ? `${fromDate} 00:00:00`:null,
      endDate:toDate !== '' ? `${toDate} 23:59:59`:null
      
    },setIsLoading)
  }

  // map grn response to state
  useEffect(()=>{
    setGrnFilter(grnReportList?.map(obj=>{return {...obj}}))
   },[grnReportList])

  // sorting
  // sort ascending order by date
  const sortDateAscending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(grnFilter, "Date", "date", true);
    setGrnFilter(sorted)
  }
  // sort descending order by date
  const sortDateDescending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(grnFilter, "Date", "date", false);
    setGrnFilter(sorted)
  }

  // sort ascending order by creditor
  const sortCreditorAscending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(grnFilter, "String", "supplierName", true);
    setGrnFilter(sorted)
  }

  // sort descending order by creditor
  const sortCreditorDescending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(grnFilter, "String", "supplierName", false);
    setGrnFilter(sorted)
  }

  // branch code sorting
  const sortBranchCodeAscending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(grnFilter, "String", "branchId", true);
    setGrnFilter(sorted)
  }
  const sortBranchCodeDescending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(grnFilter, "String", "branchId", false);
    setGrnFilter(sorted)
  }
 // branch name sorting
 const sortBranchNameAscending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(grnFilter, "String", "branchName", true);
  setGrnFilter(sorted)
}
const sortBranchNameDescending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(grnFilter, "String", "branchName", false);
  setGrnFilter(sorted)
}





  return (
    <div className="global-page-parent-container">
    <p className="breadcrumb">Report &gt; GRN Report</p>
    <div className="daily-report-top-container">
      <h3>GRN Report</h3>

      <hr className="global-hr"/>
      <div className="daily-report-top-input-container" >
        {
          userRole === 'admin' &&
         <div className="global-single-input auto-complete">
          <p>Branch</p>
        <Autocomplete
             sx={{width:"100%"}}
             options={branchWithAll|| [""]}
             getOptionLabel={(option) => `${option?.storeCode}-${option?.branchName}`}

             renderInput={(params) => <TextField {...params} 
             placeholder="Select Branch"
             />}
            
             onChange={(e, newValue) =>
              setBranch(newValue?.storeCode)
             }
            />
       </div>
        }
       
       <div className="global-single-input auto-complete">
        <p>Transaction ID </p>
        <Autocomplete
             sx={{width:"100%"}}
             options={grnNoList|| [""]}
             getOptionLabel={(option) => `${option?.prefix}${option?.transNo}`}

             renderInput={(params) => <TextField {...params} 
             placeholder="Select Transaction ID"
             />}
            
             onChange={(e, newValue) =>
              setTransId(newValue?.transNo)
             }
            />
        
       </div>
       <div className="global-single-input">
        <p>From</p>
        <input type="date" onChange={getFromDate}/>
       </div>
       <div className="global-single-input">
        <p>To</p>
        <input type="date" onChange={getToDate}/>
       </div>
       <button onClick={grnSubmitBtnFn} className="btn btn-primary report-submit-btn">Submit</button>
      
      </div>
     
    </div>
    <div className="global-white-bg-container">
     <div className="global-table-container">
        <table className="global-table">
           <thead>
             <tr>
              <th>No</th>
              {
                userRole === 'admin' &&
                <>
                <th>
                  Branch Code
                  {
                isAscending ?(
                  <IconButton
                    onClick={sortBranchCodeAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortBranchCodeDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
                </th>
                <th>
                  Branch Name
                  {
                isAscending ?(
                  <IconButton
                    onClick={sortBranchNameAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortBranchNameDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }  
                </th>
                </>
              }
              
              <th>Transaction ID </th>
              <th>Sales Invoice No</th>
              <th>
                Date
                {
                isAscending ?(
                  <IconButton
                    onClick={sortDateAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortDateDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
              </th>
              <th>Location</th>
              <th>
                Creditor
                {
                isAscending ?(
                  <IconButton
                    onClick={sortCreditorAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                    onClick={sortCreditorDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
              </th>
              <th>view</th>
             </tr>
           </thead>
           {
            isLoading ? (
              <tbody>
              <tr>
                   <td colSpan={10}>
                     <Skeleton
                       variant="rectangular"
                       animation="wave"
                       width={"100%"}
                       height={30}
                     />
                   </td>
                 </tr>
                 <tr>
                   <td colSpan={10}>
                     <Skeleton
                       variant="rectangular"
                       animation="wave"
                       width={"100%"}
                       height={30}
                     />
                   </td>
                 </tr>
                 <tr>
                   <td colSpan={10}>
                     <Skeleton
                       variant="rectangular"
                       animation="wave"
                       width={"100%"}
                       height={30}
                     />
                   </td>
                 </tr>
           </tbody>
            ):(
           <tbody>
            {
              grnFilter?.length !==0 && grnFilter !== undefined? grnFilter?.map((r,i)=>(
                <tr>
                <td>{i+1}</td>
                {
                  userRole === 'admin' &&
                  <>
                  <td>{r?.branchId}</td>
                  <td>{r?.branchName}</td>
                  </>
                }
                
                <td>{r?.transNo}</td>
                <td>{r?.invoiceNo}</td>
                <td>{convertDateFormat(r?.date)}</td>
                <td>{r?.locationName}</td>
                <td>{r?.supplierName}</td>
                <td>
                   <IconButton onClick={()=>grnReportSingleView(r?._id)}>
                     <i class="bi bi-eye visibility-icon"></i>
                   </IconButton>
                </td>
              </tr>
              ))
              :
              <tr>
                 <td colSpan={9}>No Data</td>
              </tr>
            }
           
           </tbody>
            )
           }
           
        </table>
     </div>
    </div>

    

    {/* grn report single view */}
    

    <Dialog open={grnReportView} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setGrnReportView(false)}>
    <div className="po-single-view-parent-container">
        <div className="top-bar" style={{display:"flex",justifyContent:"space-between"}}>
          <p>View</p>
          <div className="stock-report-print-download-icon-container">
         <ReactToPrint
        trigger={()=>( 
            <IconButton className="print-icon-container">
              <i class="bi bi-printer printer-icon"></i>
            </IconButton>
         )} 
      content={()=>grnReportPrint} 
      pageStyle="@page { size: auto;  margin: 15mm 0 10mm 0; } }"
       />   
          <IconButton className="download-icon-container">
            <i class="bi bi-arrow-down-circle download-icon"></i>
           </IconButton>
        </div>
        </div>
        <hr />
        <div className="form-1-container">
          <div className="global-single-input po-input">
              <p>Transaction ID</p>
              <input type="text" disabled value={singleViewData !== undefined && singleViewData[0]?.transNo}/>
            </div>
            <div className="global-single-input po-input">
              <p>Sales Invoice No</p>
              <input type="text" disabled value={singleViewData !== undefined && singleViewData[0]?.invoiceNo}/>
            </div>
            <div className="global-single-input po-input">
              <p>Branch</p>
              <input type="text" disabled value={singleViewData !== undefined && singleViewData[0]?.locationName}/>
            </div>
            <div className="global-single-input po-input">
              <p>Date</p>
              <input type="text" disabled value={singleViewData !== undefined && singleViewData[0]?.date}/>
            </div>
            <div className="global-single-input po-input">
              <p>Creditor</p>
              <input type="text" disabled value={singleViewData !== undefined && singleViewData[0]?.supplierName}/>
            </div>
        </div>
        <div className="po-single-view-table-container global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Product Code</th>
                  <th>Product Name</th>
                  <th>Size/Unit</th>
                  <th>Price</th>
                  <th>Qty</th>
                  <th>Received Quantity</th>
                  <th>UP(FC)</th>
                  <th>UP(LC)</th>
                  <th>Amount</th>
                 
                </tr>
              </thead>
              <tbody>
                {
                  singleViewData !== undefined && singleViewData[0]?.purchaseInfo?.map((r,i)=>(
                    <tr>
                      <td>{i+1}</td>
                      <td>{r?.code}</td>
                      <td>{r?.productName}</td>
                      <td>{r?.unit}</td>
                      <td>{r?.price}</td>
                      <td>{r?.orderQuantity}</td>
                      <td>{r?.recievedQuantity}</td>
                      <td>{r?.UPFC}</td>
                      <td>{r?.UPLC}</td>
                      <td>{parseFloat(r?.recievedQuantity)*parseFloat(r?.price)}</td>
                    </tr>
                  ))
                }
              </tbody>
            
            </table>
          </div>
          <div className="cancel-btn-container">
            <button 
            onClick={()=>setGrnReportView(false)}
             className="btn btn-secondary-outlined">Close</button>
          </div>
      </div>
    </Dialog>
    

    {/* grn report print  */}
    <div 
       style={{display:'none'}}
     >
       <div ref={(el)=>(grnReportPrint = el)}>
          <div className='print-view-top-container'>
            <div className='print-view-top-left-container' style={{width:'30%'}}>
               <h6 style={{margin:"0 0 0 2%"}}>Printed On : {convertDateFormat(today)}&nbsp;{getCurrentTime()}</h6>
                <p>Trans ID&nbsp;:&nbsp;{singleViewData !== undefined && singleViewData[0]?.transNo}</p>
                
            </div>
            <div className='print-view-top-middle-container' style={{width:'40%'}}>
            {userRole === "admin" ? (
                <h3>{loginResponse?.data?.profile?.companyName}</h3>
              ) : userRole === "user" ? (
                <h3>{loginResponse?.storeName}</h3>
              ) : undefined}
                 <p>GRN REPORT</p>
            </div>
            <div className='print-view-top-right-container' style={{width:'30%'}}>
            {userRole ==="user" && <p>{loginResponse?.branchId}-{loginResponse?.branchName}</p>}
            </div>
          </div>
          <hr  className="print-report-hr"/>
          <div className='print-view-top-container' style={{padding: "0 6% 2% 6%"}}>
            <div className='print-view-top-left-container' style={{width:'40%'}}>
                <p>Supplier&nbsp;:&nbsp;{singleViewData !== undefined && singleViewData[0]?.supplierName}</p>
                <p>Date&nbsp;:&nbsp;{singleViewData !== undefined && convertDateFormat(singleViewData[0]?.date)}</p>
            </div>
            <div className='print-view-top-middle-container' style={{width:'20%'}}>
             
            </div>
            <div className='print-view-top-right-container' style={{width:'40%'}}>
                <p>Location&nbsp;:&nbsp;{singleViewData !== undefined && singleViewData[0]?.locationName}</p>
                <p>Sales Invoice No&nbsp;-&nbsp;{singleViewData !== undefined && singleViewData[0]?.invoiceNo}</p>
              
            </div>
          </div>
          <div className='print-view-table-container'>
          <table>
          <thead>
            <tr>
                  <th>No</th>
                  <th>Product Code</th>
                  <th>Product Name</th>
                  <th>Size/Unit</th>
                  <th>Price</th>
                  <th>Qty</th>
                  <th>Received Quantity</th>
                  <th>UP(FC)</th>
                  <th>UP(LC)</th>
                  <th>Amount</th>
            </tr>
           </thead>
           <tbody>
                {
                  singleViewData !== undefined && singleViewData[0]?.purchaseInfo?.map((r,i)=>(
                    <tr>
                      <td>{i+1}</td>
                      <td>{r?.code}</td>
                      <td>{r?.productName}</td>
                      <td>{r?.unit}</td>
                      <td>{r?.price}</td>
                      <td>{r?.orderQuantity}</td>
                      <td>{r?.recievedQuantity}</td>
                      <td>{r?.UPFC}</td>
                      <td>{r?.UPLC}</td>
                      <td>{parseFloat(r?.recievedQuantity)*parseFloat(r?.price)}</td>
                    </tr>
                  ))
                }
              </tbody>
        </table>
        
          </div>
     
       </div>
     </div>
    </div>
  )
}
