import {createSlice} from '@reduxjs/toolkit'

export const outletExpenseDocNoSlice=createSlice({
    name:'outletExpenseDocNoSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_outlet_expense_docNo:(state,action)=>{
            state.value=action.payload.outletExpDocNoData
        }
    }
})
export const {get_outlet_expense_docNo} =outletExpenseDocNoSlice.actions;
export default outletExpenseDocNoSlice.reducer