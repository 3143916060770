import React from 'react'

export const AlterationInvoice = (props) => {
    const {data,isCopy} = props;

    // console.log(isCopy);
    // console.log(data);
      return (
    <div className="invoice-main-container">
    <div className="invoice-first-container">
      <div className="invoice-first-left-container">
        <img src={data?.companyLogo} alt="logo" />
        <h4>{data?.companyName}</h4>
        <h4 className="arabic-text"> {data?.nativecompanyName || ""}</h4>
        <p>{data?.website}</p>
      </div>
      <div className="invoice-first-right-container">
        {
          isCopy == true && <h5 style={{textAlign:"right"}}>Copy</h5>
        }
        
        <h5>Date&nbsp;:&nbsp;{data?.date !== undefined && data?.date?.split(" ")[0]}</h5>
        <h5 className="arabic-text date-arabic">تاريخ</h5>
        <h5>Time&nbsp;:&nbsp;{data?.date !== undefined && data?.date?.split(" ")[1]}</h5>
        <h5 className="arabic-text date-arabic">زمن</h5>
      </div>
    </div>
    <hr className="invoice-hr"/>
    <div className="invoice-second-container">
      <div className="invoice-second-label-data-container">
        <div className="vat-label">
          <h5 >VAT</h5>
          <h5 className="arabic-text">ضريبة القيمة المضافة</h5>
        </div>
        <div className="vat-data">
          <h5>:&nbsp;{data?.vat}</h5>
          <h5></h5>
        </div>
        
    
      </div>
      <div className="invoice-second-label-data-container">
        <div className="vat-label">
          <h5 >Customer Care</h5>
          <h5 className="arabic-text">رعاية العميل</h5>
        </div>
        <div className="vat-data">
          <h5>:&nbsp;{data?.customercare}</h5>
          <h5></h5>
        </div>
        
    
      </div>
    </div>
    <hr className="invoice-hr"/>
    <div className="invoice-third-container">
      <div className="invoice-third-left-container">
        <h5>
        {data?.address}
        </h5>
      </div>
      <div className="invoice-third-right-container">
        <h5 className="arabic-text"> {data?.nativeAddress || ""}</h5>
      </div>
    </div>
    <hr className="invoice-hr"/>
    <div className="invoice-fourth-container">
      <div className="invoice-fourth-left-container">
        <div className="invoice-fourth-left-first-container">
        <div className="invoice-fourth-left-first-label-container">
          <h6>Staff ID</h6>
          <h6 className="arabic-text">معرف الموظفين</h6>
        </div>
        <div className="invoice-fourth-left-first-data-container">
           <h6>&nbsp;:&nbsp;{data?.empId}</h6>
           <h6></h6>
        </div>
        </div>
        <div className="invoice-fourth-left-first-container">
        <div className="invoice-fourth-left-first-label-container">
          <h6>Staff Name</h6>
          <h6 className="arabic-text">اسم الموظفين</h6>
        </div>
        <div className="invoice-fourth-left-first-data-container">
           <h6>&nbsp;:&nbsp;{data?.staff_name}</h6>
           <h6></h6>
        </div>
        </div>
        
      </div>
      <div className="invoice-fourth-right-container">
        <div className="invoice-fourth-left-first-container">
         <div className="invoice-fourth-left-first-label-container">
          <h6>Alteration ID</h6>
          <h6 className="arabic-text">رقم الطلب</h6>
         </div>
        <div className="invoice-fourth-left-first-data-container">
           <h6>&nbsp;:&nbsp;{data?.billNo}</h6>
           <h6></h6>
        </div>
      </div>
        <div className="invoice-fourth-left-first-container">
         <div className="invoice-fourth-left-first-label-container">
          <h6>POS ID</h6>
          <h6 className="arabic-text">معرف نقطة البيع</h6>
         </div>
         <div className="invoice-fourth-left-first-data-container">
           <h6>&nbsp;:&nbsp;---</h6>
           <h6></h6>
         </div>
        </div>
      </div>
     
    </div>
     {/* table */}
     <div className="billing-invoice-table-container">
        <table>
          <thead>
            <tr>
              <th>
                <div className="heading-container" >
                  <p>Item</p>
                  <p className="arabic-text">العنصر</p>
                </div>
              </th>
              <th>
                <div className="heading-container" >
                  <p>Product</p>
                  <p className="arabic-text">منتج</p>
                </div>
              </th>
             
              <th>
                <div className="heading-container" >
                  <p>Qty</p>
                  <p className="arabic-text">الكمية</p>
                </div>
              </th>
              <th>
                <div className="heading-container" >
                  <p>Price</p>
                  <p className="arabic-text">سعر</p>
                </div>
              </th>
              <th>
                <div className="heading-container" >
                  <p>Value</p>
                  <p className="arabic-text">سعر</p>
                </div>
              </th>
            </tr>
          </thead>
          {/* <tbody>
            {
              data?.item?.map((r,i)=>(
                <tr key={i}>
                <td>
                  <div className="content-container">
                    <p>{r?.type}</p>
                    <p className="arabic-text">
                    {r?.type === "thob"
                      ? "التوب"
                      : r?.type === "jalabia"
                      ? "جلابية"
                      : r?.type === "salwar"
                      ? "السلوار"
                      : r?.type === "abaya"
                      ? "عباية"
                      : r?.type === "churidhar"
                      ? "شوريدار"
                      : r?.type === "shirt"
                      ? "قميص"
                      : r?.type === "pant"
                      ? "يلهث"
                      : r?.type === "coat"
                      ? "معطف"
                      : r?.type === "sharvani"
                      ? "شرواني"
                      : r?.type === "safari"
                      ? "سفاري"
                      :undefined}
                    </p>
                  </div>
                </td>
                <td>
                  <div className="content-container">
                    <p>{r?.name}</p>
                  
                  </div>
                </td>
                <td>{r?.quantity}</td>
                <td>{r?.price}</td>
                <td>{r?.total}</td>
              </tr>
              ))
            }
           
           
           
          </tbody> */}
          <tbody>
            <tr>
                <td>{data?.items?.type}</td>
                <td>{data?.items?.list?.length>0 && data?.items?.list[0]?.name || '--'}</td>
                <td>{ data?.items?.quantity}</td>
                <td>{ data?.items?.price}</td>
                <td>{ data?.items?.value}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr className="invoice-hr-dashed"/>
      <div className="invoice-sub-total-container">
        <div className="invoice-sub-total-first-container">
          <div className="invoice-sub-total-first-left-container">
            <h5>SUB TOTAL</h5>
            <h5 className="arabic-text">المجموع الفرعي</h5>
          </div>
          <div className="invoice-sub-total-first-right-container">
            <h5>{data?.subTotal}</h5>
            <h5></h5>
          </div>
        </div>
        <div className="invoice-sub-total-first-container">
          <div className="invoice-sub-total-first-left-container">
            <h5>DISCOUNT</h5>
            <h5 className="arabic-text">تخفيض</h5>
          </div>
          <div className="invoice-sub-total-first-right-container">
            <h5>{data?.discount}</h5>
            <h5></h5>
          </div>
        </div>
        {data?.taxamount!==0&&
        <div className="invoice-sub-total-first-container">
          <div className="invoice-sub-total-first-left-container">
            <h5>Add VAT (9.00%) on {data?.subTotal}</h5>
            <h5 className="arabic-text"> أضف ضريبة القيمة المضافة (9.00٪) على</h5>
          </div>
          <div className="invoice-sub-total-first-right-container">
            <h5>{data?.taxamount}</h5>
            <h5></h5>
          </div>
        </div>
        }

      </div>
      <hr className="invoice-hr-dashed"/>
      <div className="invoice-grand-total-container">
        {
          data?.taxamount!==0&&
        <div className="invoice-grand-total-first-container">
        <h5>Inclusive Of All Taxes</h5>
         <h5 className="arabic-text">شاملة لجميع الضرائب</h5>
        </div>
        }
        <div className="invoice-grand-total-second-container">
         <div>
           <h4>Grand Total</h4>
           <h4 className="arabic-text">المبلغ الإجمالي</h4>
         </div>
         <div>
           <h4>{data?.grandTotal}</h4>
           <h4></h4>
         </div>
        </div>
        <div className="invoice-grand-total-second-container">
         <div>
           <h5>Paid Amount</h5>
           <h5 className="arabic-text">المبلغ المدفوع</h5>
         </div>
         <div>
           <h5>{data?.paidAmount}</h5>
           <h5></h5>
         </div>
        </div>
        <div className="invoice-grand-total-second-container">
         <div>
           <h5>Balance</h5>
           <h5 className="arabic-text">الرصيد</h5>
         </div>
         <div>
           <h5>{data?.balance}</h5>
           <h5></h5>
         </div>
        
        </div>
        <h5 className="saved-amount-container">*** YOU HAVE SAVED {data?.discount||0}  *** </h5>

        {/* barcode qrcode */}
        <div className="barcode-qrcode-container">
         <img src={data?.barcode} alt="barcode" className="barcode-qrcode-container-barcode"/>
         <img src={data?.qrcode} alt="qrcode" className="barcode-qrcode-container-qrcode"/>
        </div>
        
      </div>
      <hr className="invoice-hr-dashed"/>
      <div className="terms-conditions-container">
         <div className="terms-conditions-left-container">
          <h5>Terms & Conditions</h5>
          <p>{data?.terms || ""}</p>
         </div>
         <div className="terms-conditions-right-container">
         <h5 className="arabic-text">الأحكام والشروط</h5>
          <p className="arabic-text">{data?.nativeTerms || ""}</p>
         </div>
      </div>
      <hr className="invoice-hr-dashed"/>
      <div className="invoice-last-container">
         <h4>THANK YOU FOR YOUR VISIT</h4>
         <h4>شكرا لزيارتكم</h4>
      </div>
  </div>
  )
}
