import axios from "axios";
import { update_po_id } from "../../Redux/Prchase Manager/Purchase Order/purchaseOrderIdSlice";
import { get_purchaseOrder_single_view } from "../../Redux/Prchase Manager/Purchase Order/singleViewPurchaseOrderSlice";
import { get_purchase_order } from "../../Redux/Prchase Manager/Purchase Order/viewPurchaseOrderSlice";
import store from "../../Redux/store";
import { HEADERS, HEADER_QUERY } from "../UrlAndPaths";

//Generate purchase order id
export const generatePurchaseOrderIdAPICall = async (body) => {
  await axios
    .post("purchase/generateinvoiceno", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_po_id({poId:res.data}))
      }
    })
    .catch((err) => {
        store.dispatch(update_po_id({poId:undefined}))
        console.error(err.response);
    });
};

// add purchase order api call
export const addPurchaseOrderAPICall= async (
  body,
  setSuccessSnackOpen,
  setErrorSnackOpen,
  setSnackMsg,
  form1ClearState,
  updateListener
 
  )=>{
 await axios.post("purchase/addpurchase",body,HEADERS)
 .then((res)=>{
  if(res.status===200){
    // console.log('add purchase added');
    setSuccessSnackOpen(true)
    setSnackMsg('Purchse Added')
    updateListener()
    form1ClearState()
   
  }
 })
 .catch((err)=>{
  setSnackMsg("Oops !!")
  setErrorSnackOpen(true)
  if (err.response === undefined) {
    setSnackMsg("Network Connection error!!");
  }
  if (err.response.status === 409) {
    setSnackMsg("Category Already Exist");
  }

  if (err.response.status === 401) {
    setSnackMsg("Unauthorized");
  }
  if (err.response.status === 403) {
    setSnackMsg("Forbidden");
  }
  console.log(err.response);
 })
}

// view purchase order api call
export const viewPurchaseOrderApiCall= async (setIsLoading)=>{
  setIsLoading(true)
 await axios.get("purchase/viewpurchase",HEADERS)
 .then((res)=>{
   if(res.status===200){
    store.dispatch(get_purchase_order({purchaseOrderData:res.data}))
    setIsLoading(false)
   }
 })
 .catch((err)=>{
  store.dispatch(get_purchase_order({purchaseOrderData:undefined}))
  console.log(err.response);
  setIsLoading(false)
 })
}

// purchse order single view
export const purchaseOrderSingleViewAPICall= async (body)=>{
 await axios.post("purchase/viewpurchaseById",body,HEADERS)
 .then((res)=>{
  if(res.status===200){
    store.dispatch(get_purchaseOrder_single_view({purchaseOrderSingleViewData:res.data}))
  }
 })
 .catch((err)=>{
  store.dispatch(get_purchaseOrder_single_view({purchaseOrderSingleViewData:undefined}))
  console.log(err.response);
 })
}


// delete purchse order
export const deletePurchseOrderAPICall = async (
  _id,
  updateListener,
  setSuccessSnackOpen,
  setErrorSnackOpen,
  setSnackMsg
  )=>{
  await axios.delete("purchase/deletePurchase",{
    headers:HEADER_QUERY,
    data:{
      _id
    }
  })
  .then((res)=>{
    if(res.status===200){
      updateListener()
      setSuccessSnackOpen(true)
      setSnackMsg('Deleted')
    }
  })
  .catch((err)=>{
    console.log(err.response);
    setErrorSnackOpen(true)
    if (err.response === undefined) {
      setSnackMsg("Network Connection error!!");
    }
    if (err.response.status === 409) {
      setSnackMsg("Category Already Exist");
    }
  
    if (err.response.status === 401) {
      setSnackMsg("Unauthorized");
    }
    if (err.response.status === 403) {
      setSnackMsg("Forbidden");
    }
    if(err.response.status === 422){
      setSnackMsg(err.response.data?.msg)
    }

  })
}