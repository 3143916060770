import axios from "axios";
import { HEADERS } from "../../../../../API/UrlAndPaths";
import store from "../../../../../Redux/store";
import { view_Overtime_list,view_Overtime_By_id,view_overtime_type,generate_overtime,generate_overtime_type } from "./OvertimeSlice";

export const viewOvertimeListAPI=async(body)=>{
    await axios
    .post('employee/viewOvertime',body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(view_Overtime_list({viewOvertimeListData:res.data}))

        }
    })
    .catch((err)=>{
        store.dispatch(view_Overtime_list({viewOvertimeListData:undefined}))
    })
}
export const viewOvertimeListByIdAPI=async(body)=>{
    await axios
    .post('employee/viewOvertimeById',body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(view_Overtime_By_id({viewOvertimeById:res.data}))

        }
    })
    .catch((err)=>{
        store.dispatch(view_Overtime_By_id({viewOvertimeById:undefined}))
    })
}
export const viewOvertimeTypeAPI=async(body)=>{
    await axios
    .post('employee/viewOvertimeType',body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(view_overtime_type({viewOvertimeTypeData:res.data}))

        }
    })
    .catch((err)=>{
        store.dispatch(view_overtime_type({viewOvertimeTypeData:undefined}))
    })
}
export const generateOvertimeTypeAPI=async(body)=>{
    await axios
    .post('employee/addOvertimeType',body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            // store.dispatch(generate_overtime_type({addOvertimeTypeData:res.data}))
            viewOvertimeTypeAPI()

        }
    })
    .catch((err)=>{
        store.dispatch(generate_overtime_type({addOvertimeTypeData:undefined}))
    })
}
export const generateOvertimeAPI=async(body)=>{
    await axios
    .post('employee/addOvertime',body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(generate_overtime({addOvertimeData:res.data}))
            viewOvertimeListAPI()

        }
    })
    .catch((err)=>{
        store.dispatch(generate_overtime({addOvertimeData:undefined}))
    })
}

export const  deleteOvertimeTypeAPI = async (body,type,amount) =>{
    try {
        const response = await axios.delete("employee/deleteOvertimeType", {
            data: body,
            headers: HEADERS
        });
        if (response.status === 200){
        viewOvertimeTypeAPI({ otType:type, amount:amount });
        viewOvertimeListAPI()
            
        }

    } catch (error) {
        console.error(error.message)
    }
}
export const updateOvertimeListByIdAPI=async(body)=>{
    await axios
    .put('employee/editOvertime',body,HEADERS)
    .then((res)=>{
        if(res.status===201){
            console.log('updated')
            viewOvertimeListAPI()
        }
    })
    .catch((err)=>{
        store.dispatch(view_Overtime_By_id({viewOvertimeById:undefined}))
    })
}
