import { createSlice } from "@reduxjs/toolkit";

const landedCostSlice = createSlice({
  name: "landedCostSlice",
  initialState: {
    landedCostSequence: undefined,
    savedData :undefined,
    singleViewData :undefined,
    landedCostLis:[],
    serviceProductList:[]
  },
  reducers: {
    landedCostName: (state, action) => {
      state.landedCostSequence = action.payload;
    },
    saveData: (state, action) => {
      state.savedData = action.payload;
    },
    singleView: (state, action) => {
      state.singleViewData = action.payload;
    },
    listLc: (state, action) => {
      state.landedCostLis = action.payload;
    },
    serviceProduct: (state, action) => {
      state.serviceProductList = action.payload;
    },
    

  },
});

export const { landedCostName,saveData,singleView,listLc,serviceProduct } = landedCostSlice.actions;

export default landedCostSlice.reducer;
