import {createSlice} from '@reduxjs/toolkit'

export const staffExpenseSlice=createSlice({
    name:"staffExpenseSlice",
    initialState:{
        value:undefined,
        staffExpPaymentType:undefined
    },
    reducers:{
        get_staff_expense:(state,action)=>{
            state.value=action.payload.staffExpenseData
        },
        get_exp_payment_type:(state,action)=>{
            state.staffExpPaymentType=action.payload.expPaymentTypeData
        }
    }
})
export const {get_staff_expense,get_exp_payment_type} = staffExpenseSlice.actions

export default staffExpenseSlice.reducer