import { createSlice } from "@reduxjs/toolkit";

export const orderNosForJobCompletionSlice=createSlice({
    name:'orderNosForJobCompletionSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_order_numbers_for_job_completion:(state,action)=>{
            state.value=action.payload.orderNosForJobCompletion
        }
    }
})
export const {update_order_numbers_for_job_completion} = orderNosForJobCompletionSlice.actions
export default orderNosForJobCompletionSlice.reducer