import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {useSelector } from 'react-redux';
import store from '../../../../../Redux/store';
import { viewUOMListAPICall } from './unitOfMeasurmentAPICall';
import { update_res_list_by_row } from './unitOfMeasurmentSlice';


export const UnitOfMeasurment = () => {

  let navigateTo=useNavigate()

  const tableListData=useSelector((state)=>state.unitOfMeasurmentSlice.apiResList);

  const handleClickRow=(row)=>{
    // store.dispatch(update_res_list_by_row({singleRowData:row}))
    localStorage.setItem("singleViewById", row?.id);
    navigateTo("/userdashboard/purchaseManager/newUnit")
  }

  useEffect(()=>{
    viewUOMListAPICall()
    localStorage.removeItem("singleViewById");
    store.dispatch(update_res_list_by_row({singleRowData:undefined}))
  },[])
  return (
    <div className='global-page-parent-container'>

      <div className="global-white-bg-container">
        <div className="justify-end">
          <button className='btn btn-primary' onClick={()=>navigateTo("/userdashboard/purchaseManager/newUnit")}>Create</button>
        </div>

        <div className="global-table-container">
          <table className="global-table">
            <thead>
              <th>No</th>
              <th>UOM Category</th>
              <th>UOM</th>
            </thead>
            <tbody>
              {
                tableListData!==undefined?
                tableListData?.slice(0)?.reverse()?.map?.((r,i)=>(
                  <tr key={i} onClick={()=>handleClickRow(r)} style={{cursor:"pointer"}}>
                    <td>{i+1}</td>
                    <td>{r?.uomCat}</td>
                    <td className='table-data-box'>
                    <div 
                    style={{justifyContent:"center"}}
                    className='table-data-round-parent'>

                        { 
                        r?.uom?.map((r,i)=>(
                            r?.isActive &&
                            <div 
                            className='round-border'
                            key={i}>{r?.uomName}</div>
                        ))
                        }
                      </div>
                    </td>
                  </tr>
                ))
                  :
                  <>
                  <tr>
                    <td colSpan={3}>No data</td>
                  </tr>
                  </>
              }
              
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
