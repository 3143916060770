import {createSlice} from '@reduxjs/toolkit'

export const getOrderDetailsForSalesReturnSlice=createSlice({
    name:"orderDetailsForSalesReturn",
    initialState:{
        value:undefined
    },
    reducers:{
        get_order_details_salesReturn:(state,action)=>{
            state.value=action.payload.orderDetails
        }
    }
})
export const {get_order_details_salesReturn} = getOrderDetailsForSalesReturnSlice.actions;
export default getOrderDetailsForSalesReturnSlice.reducer