import React, { useState } from "react";
import { Select, Dialog, IconButton, MenuItem } from "@mui/material";
import { AddCategoryPopup } from "../../../Single Components/AddCategoryPopup";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import {
  addFpModelAPICall,
  addJalabiaModelAPICall,
  addLengthCommissionAPICall,
  addModelAPICall,
  addNeckModelAPICall,
  addStitchingAPICall,
  deleteFpModelAPICall,
  deleteJalabiaModelAPICall,
  deleteLengthCommissionAPICall,
  deleteModelAPICall,
  deleteNeckModelAPICall,
  deleteStitchingAPICall,
  viewFpModelAPICall,
  viewJalabiaModelAPICall,
  viewLengthCommissionAPICall,
  viewModelAPICall,
  viewNeckModelAPICall,
  viewStitchingAPICall,
} from "../../../../API/Orders/workOrderAPI";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { viewCuttingSlipModelAPICall } from "../../../../API/Settings/CuttingSlipModel/cuttingSlipMoldelAPI";

export const JalabiaWO = (props) => {
  const { formValues, onChange } = props;
  //*Redux States
  //Role of User [admin or user]
  const userRole = useSelector((state) => state.userRoleSlice.value);
  const jalabiaModelData = useSelector(
    (state) => state.jalabiaModelSlice.value
  );
  const lengthCommissionData = useSelector(
    (state) => state.lengthCommissionSlice.value
  );
  const neckModelData = useSelector((state) => state.neckModelSlice.value);
  const stitchingData = useSelector((state) => state.stitchingSlice.value);
  const modelData = useSelector((state) => state.modelSlice.value);
  const fpModelData = useSelector((state) => state.fpModelSlice.value);
  // cutting slip template
  const cuttingSlipTemplate=useSelector((state)=>state.cuttingSlipSlice.value)

  const [isModify, setIsModify] = useState(false);
  //Snackbar States
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!");

  const [addJalabiaModel, setAddJalabiaModel] = useState(false);
  const [addLengthCommission, setAddLengthCommission] = useState(false);
  const [addNeckModel, setAddNeckModel] = useState(false);
  const [addStitching, setAddStitching] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [addFpModel, setAddFpModel] = useState(false);

  //Jalabia model Form States
  const jalabiaModelFormInitialState = {
    modelName: "",
    lengthFrom: "",
    lengthTo: "",
    minRate: 0,
    commission: 0,
  };
  const [jalabiaModelForm, setJalabiaModelForm] = useState(
    jalabiaModelFormInitialState
  );

  //Length Commission Form States
  const lengthCommissionInitialState = {
    lengthFrom: "",
    lengthTo: "",
    usedQty: "",
    minRate: 0,
    commission: 0,
  };
  const [lengthCommissionForm, setLengthCommissionForm] = useState(
    lengthCommissionInitialState
  );

  //Neck Model
  const [newNeckModel, setNewNeckModel] = useState("");
  //Stitching
  const [newStitching, setNewStitching] = useState("");
  //Model
  const [newModel, setNewModel] = useState("");
  //FP Model
  const [fpModel, setFpModel] = useState("");

  // VALIDATION STATES
  const [lengthFromAlert,setLengthFromAlert]=useState(false)
  const [lengthToAlert,setLengthToAlert]=useState(false)
  const [usedQtyAlert,setUsedQtyAlert]=useState(false)
  const [modelNameAlert,setModelNameAlert]=useState(false)
  //--------------------------------------------------
  //Update Listener
  const updateListener = () => {
    setIsModify(!isModify);
  };

  //Close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setSuccessSnackOpen(false);
  };
  //Close ErrorSnackbar
  const closeErrorSnackbar = () => {
    setErrorSnackOpen(false);
  };
  // cutting slip template api call
  useEffect(()=>{
    viewCuttingSlipModelAPICall()
  },[])
  //*onChange
  //Get Jalabia model Form
  const getJalabiaModelForm = (key) => (e) => {
    const { value } = e.target;

    if (key === "modelName") {
      setJalabiaModelForm({ ...jalabiaModelForm, modelName: value });
      setModelNameAlert(false)
      if(value===""){
        setModelNameAlert(true)
      }
    }
    if (key === "lengthFrom") {
      setJalabiaModelForm({ ...jalabiaModelForm, lengthFrom: value });
      setLengthFromAlert(false)
      if(value===""){
        setLengthFromAlert(true)
      }
    }
    if (key === "lengthTo") {
      setJalabiaModelForm({ ...jalabiaModelForm, lengthTo: value });
      setLengthToAlert(false)
      if(value===""){
        setLengthToAlert(true)
      }
    }
    if (key === "minRate") {
      setJalabiaModelForm({ ...jalabiaModelForm, minRate: value });
    }
    if (key === "commission") {
      setJalabiaModelForm({ ...jalabiaModelForm, commission: value });
    }
  };
  //Get Length commission Form
  const getLengthCommissionForm = (key) => (e) => {
    const { value } = e.target;

    if (key === "lengthFrom") {
      setLengthCommissionForm({ ...lengthCommissionForm, lengthFrom: value });
      setLengthFromAlert(false)
      if(value===""){
        setLengthFromAlert(true)
      }
    }
    if (key === "lengthTo") {
      setLengthCommissionForm({ ...lengthCommissionForm, lengthTo: value });
      setLengthToAlert(false)
      if(value===""){
        setLengthToAlert(true)
      }
    }
    if (key === "usedQty") {
      setLengthCommissionForm({ ...lengthCommissionForm, usedQty: value });
      setUsedQtyAlert(false)
      if(value===""){
        setUsedQtyAlert(true)
      }
    }
    if (key === "minRate") {
      setLengthCommissionForm({ ...lengthCommissionForm, minRate: value });
    }
    if (key === "commission") {
      setLengthCommissionForm({ ...lengthCommissionForm, commission: value });
    }
  };
  //Get Neck Model
  const getNeckModel = (e) => {
    setNewNeckModel(e.target.value);
  };
  //Get Stitching
  const getStitching = (e) => {
    setNewStitching(e.target.value);
  };
  //Get Model
  const getModel = (e) => {
    setNewModel(e.target.value);
  };
  //Get FP Model
  const getFpModel = (e) => {
    setFpModel(e.target.value);
  };
  //*onClick
  //Add Jalabia model Body
  const addJalabiaModelBody = {
    jalabiaName: jalabiaModelForm?.modelName,
    fromLength: parseFloat(jalabiaModelForm?.lengthFrom),
    toLength: parseFloat(jalabiaModelForm?.lengthTo),
    minRate: parseFloat(jalabiaModelForm?.minRate),
    commission: parseFloat(jalabiaModelForm?.commission),
  };
  //Add Jalabia Model
  const addNewJalabiaModel = () => {
    if (jalabiaModelForm?.modelName === "") {
      setModelNameAlert(true)
    } else if (jalabiaModelForm?.lengthFrom === "") {
      setLengthFromAlert(true)
    } else if (jalabiaModelForm?.lengthTo === "") {
      setLengthToAlert(true)
    } else {
      addJalabiaModelAPICall(
        addJalabiaModelBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        clearJalabiaStates
      );
    }
  };
  //Delete JalabiaModel
  const deleteJalabiaModel = (jalabiaId) => () => {
    deleteJalabiaModelAPICall(
      { jalabiaId },
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      updateListener
    );
  };

  //Add Length Commission body
  const addLengthCommissionBody = {
    fromLength: parseFloat(lengthCommissionForm?.lengthFrom),
    toLength: parseFloat(lengthCommissionForm?.lengthTo),
    usedQty: parseFloat(lengthCommissionForm?.usedQty),
    minRate: parseFloat(lengthCommissionForm?.minRate),
    commission: parseFloat(lengthCommissionForm?.commission),
  };
  //Add Length Commission
  const addNewLengthCommission = () => {
    if (lengthCommissionForm?.lengthFrom === "") {
      setLengthFromAlert(true)
    } else if (lengthCommissionForm?.lengthTo === "") {
      setLengthToAlert(true)
    } else if (lengthCommissionForm?.usedQty === "") {
      setUsedQtyAlert(true)
    } else {
      addLengthCommissionAPICall(
        addLengthCommissionBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        clearLengthCommissionState
      );
    }
  };
  //Delete Length Commission
  const deleteLengthCommission = (lengthCommissionId) => () => {
    deleteLengthCommissionAPICall(
      { lengthCommissionId },
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      updateListener
    );
  };

  //Add Neck Model Body
  const addNeckModelBody = {
    neckName: newNeckModel,
  };
  //Add Neck Model
  const addNewNeckModel = () => {
    if (newNeckModel === "") {
      setLengthFromAlert(false)
    } else {
      addNeckModelAPICall(
        addNeckModelBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        clearNeckModelState
      );
    }
  };
  //Delete Neck Model
  const deleteNeckModel = (neckId) => () => {
    deleteNeckModelAPICall(
      { neckId },
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      updateListener
    );
  };

  //Add Stitching Body
  const addStitchingBody = {
    stitching: newStitching,
  };
  //Add Stitching
  const addNewStitching = () => {
    if (newStitching === "") {
      setLengthFromAlert(false)
    } else {
      addStitchingAPICall(
        addStitchingBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        clearStitchingState
      );
    }
  };
  //Delete Stitching
  const deleteStitching = (stitchingId) => () => {
    deleteStitchingAPICall(
      { stitchingId },
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      updateListener
    );
  };

  //Add Model Body
  const addModelBody = {
    model: newModel,
  };
  //Add Model
  const addNewModel = () => {
    if (newModel === "") {
      setLengthFromAlert(false)
    } else {
      addModelAPICall(
        addModelBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        clearModelState
      );
    }
  };
  //Delete Model
  const deleteModel = (modelId) => () => {
    deleteModelAPICall(
      { modelId },
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      updateListener
    );
  };

  //Add FP Model Body
  const addFpModelBody = {
    fpModel: fpModel,
  };
  //Add FP Model
  const addNewFpModel = () => {
    if (fpModel === "") {
      setLengthFromAlert(false)
    } else {
      addFpModelAPICall(
        addFpModelBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        clearFpModelState
      );
    }
  };
  //Delete FP Model
  const deleteFpModel = (fpModelId) => () => {
    deleteFpModelAPICall(
      { fpModelId },
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      updateListener
    );
  };

  //-----------------------------------------------------------------

  //*useEffects
  useEffect(() => {
    viewJalabiaModelAPICall();
    viewLengthCommissionAPICall();
    viewNeckModelAPICall();
    viewStitchingAPICall();
    viewModelAPICall();
    viewFpModelAPICall();
  }, [isModify]);

  //-----------------------------------------------------------------
  //*Clear States
  //Clear  Jalabia States
  const clearJalabiaStates = () => {
    setJalabiaModelForm(jalabiaModelFormInitialState);
  };
  //Length Commission Clear State
  const clearLengthCommissionState = () => {
    setLengthCommissionForm(lengthCommissionInitialState);
  };
  //Neck Model Clear State
  const clearNeckModelState = () => {
    setNewNeckModel("");
  };
  //Stitching Clear State
  const clearStitchingState = () => {
    setNewStitching("");
  };
  //Model Clear State
  const clearModelState = () => {
    setNewModel("");
  };
  //FP Model Clear State
  const clearFpModelState = () => {
    setFpModel("");
  };
  return (
    <div className="work-order-middle-container">
      <div className="work-order-middle-left-container">
        <div className="global-single-input work-order-input">
          <p>jalabia Model</p>
          <Select
            value={formValues?.jalabiaModel}
            onChange={userRole !== "admin" && onChange("jalabiaModel")}
            disabled={userRole === "admin" ? true : false}
          >
            <MenuItem value="0">-select jalabia model-</MenuItem>
            {jalabiaModelData
              ?.slice(0)
              ?.reverse()
              ?.map((item, i) => (
                <MenuItem key={i} value={item?._id}>
                  {item?.jalabiaName}
                </MenuItem>
              )) || <MenuItem>No data</MenuItem>}
          </Select>
          {userRole === "admin" && (
            <p
              onClick={() => setAddJalabiaModel(true)}
              className="suffix-text-blue"
            >
              Add Jalabia Model
            </p>
          )}
        </div>
        <div className="global-single-input work-order-input">
          <p>Length</p>
          <input
            tabIndex={cuttingSlipTemplate?.template == "template2"&&"1"}
            value={formValues?.length}
            onChange={userRole !== "admin" && onChange("length")}
            placeholder={userRole !== "admin" && "Enter length..."}
            disabled={userRole === "admin" ? true : false}
          />
          {userRole === "admin" && (
            <p
              onClick={() => setAddLengthCommission(true)}
              className="suffix-text-blue"
            >
              Add Length Commission
            </p>
          )}
        </div>
        <div className="global-single-input work-order-input">
          <p>{cuttingSlipTemplate?.template == "template2" ? "Sleeve":"Shoulder"}</p>
          <input
            tabIndex={cuttingSlipTemplate?.template == "template2"&&"4"}
            value={formValues?.shoulder}
            onChange={userRole !== "admin" && onChange("shoulder")}
            // placeholder={userRole !== "admin" && "Enter shoulder..."}
            placeholder={
              cuttingSlipTemplate?.template == "template2" ?
              "Enter Sleeve..."
              :userRole !== "admin"?
              ""
              :"Enter shoulder..."
             }
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        
          {
            cuttingSlipTemplate?.template == "template2" ?
            <div className="global-single-input work-order-input">
            <p>Loose</p>
            <Select
              value={formValues?.neckModel}
              onChange={userRole !== "admin" && onChange("neckModel")}
              disabled={userRole === "admin" ? true : false}
            >
              <MenuItem value="0">-select loose-</MenuItem>
              {neckModelData
                ?.slice(0)
                ?.reverse()
                ?.map((item, i) => (
                  <MenuItem key={i} value={item?._id}>
                    {item?.neckName}
                  </MenuItem>
                )) || <MenuItem>No data</MenuItem>}
            </Select>
            {userRole === "admin" && (
              <p
                onClick={() => setAddNeckModel(true)}
                className="suffix-text-blue"
              >
                Add Loose
              </p>
            )}
          </div>
          :
            <div className="global-single-input work-order-input">
              <p>Sleeve</p>
              <input
                value={formValues?.sleeve}
                onChange={userRole !== "admin" && onChange("sleeve")}
                placeholder={userRole !== "admin" && "Enter sleeve..."}
                disabled={userRole === "admin" ? true : false}
              />
        </div>
          }
          
        <div className="global-single-input work-order-input">
          <p>{cuttingSlipTemplate?.template == "template2" ? "Regal":"Chest"}</p>
          <input
          tabIndex={cuttingSlipTemplate?.template == "template2"&&"6"}
            value={formValues?.chest}
            onChange={userRole !== "admin" && onChange("chest")}
            // placeholder={userRole !== "admin" && "Enter chest..."}
            placeholder={
              cuttingSlipTemplate?.template == "template2" ?
              "Enter Regal..."
              :userRole !== "admin"?
              ""
              :"Enter Chest..."
             }
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>{cuttingSlipTemplate?.template == "template2" ? "Body":"Stomach"}</p>
          <input
           tabIndex={cuttingSlipTemplate?.template == "template2"&&"7"}
            value={formValues?.stomach}
            onChange={userRole !== "admin" && onChange("stomach")}
            // placeholder={userRole !== "admin" && "Enter stomach..."}
            placeholder={
              cuttingSlipTemplate?.template == "template2" ?
              "Enter Body..."
              :userRole !== "admin"?
              ""
              :"Enter Stomach..."
             }
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>{cuttingSlipTemplate?.template == "template2" ? "Mar":"S.L Chest"}</p>
          <input
            tabIndex={cuttingSlipTemplate?.template == "template2"&&"8"}
            value={formValues?.slChest}
            onChange={userRole !== "admin" && onChange("slChest")}
            // placeholder={userRole !== "admin" && "Enter S.L chest..."}
            placeholder={
              cuttingSlipTemplate?.template == "template2" ?
              "Enter Mar..."
              :userRole !== "admin"?
              ""
              :"Enter S.L Chest..."
             }
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>{cuttingSlipTemplate?.template == "template2" ? "PT":"S.L Sto"}</p>
          <input
          tabIndex={cuttingSlipTemplate?.template == "template2"&&"9"}
            value={formValues?.slSto}
            onChange={userRole !== "admin" && onChange("slSto")}
            // placeholder={userRole !== "admin" && "Enter S.L sto..."}
            placeholder={
              cuttingSlipTemplate?.template == "template2" ?
              "Enter PT..."
              :userRole !== "admin"?
              ""
              :"Enter S.L Sto..."
             }
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>Neck</p>
          <input
          tabIndex={cuttingSlipTemplate?.template == "template2"&&"10"}
            value={formValues?.neck}
            onChange={userRole !== "admin" && onChange("neck")}
            placeholder={userRole !== "admin" && "Enter neck..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>{cuttingSlipTemplate?.template == "template2" ? "PT":"Bottom"}</p>
          <input
            tabIndex={cuttingSlipTemplate?.template == "template2"&&"11"}
            value={formValues?.bottom}
            onChange={userRole !== "admin" && onChange("bottom")}
            // placeholder={userRole !== "admin" && "Enter bottom..."}
            placeholder={
              cuttingSlipTemplate?.template == "template2" ?
              "Enter PT..."
              :userRole !== "admin"?
              ""
              :"Enter Bottom..."
             }
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>{cuttingSlipTemplate?.template == "template2" ? "Bottom":"Sleeve Size"}</p>
          <input
            tabIndex={cuttingSlipTemplate?.template == "template2"&&"12"}
            value={formValues?.sleeveSize}
            onChange={userRole !== "admin" && onChange("sleeveSize")}
            // placeholder={userRole !== "admin" && "Enter sleeve size..."}
            placeholder={
              cuttingSlipTemplate?.template == "template2" ?
              "Enter Bottom..."
              :userRole !== "admin"?
              ""
              :"Enter Sleeve Size..."
             }
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        {
           cuttingSlipTemplate?.template == "template2" ?
           <div className="global-single-input work-order-input">
           <p>Notes</p>
           <input
             tabIndex={cuttingSlipTemplate?.template == "template2"&&"13"}
             value={formValues?.sleeve}
             onChange={userRole !== "admin" && onChange("sleeve")}
             placeholder={userRole !== "admin" && "Enter Notes..."}
             disabled={userRole === "admin" ? true : false}
           />
         </div>
         :
         <div className="global-single-input work-order-input">
         <p>Neck Model</p>
         <Select
           value={formValues?.neckModel}
           onChange={userRole !== "admin" && onChange("neckModel")}
           disabled={userRole === "admin" ? true : false}
         >
           <MenuItem value="0">-select neck model-</MenuItem>
           {neckModelData
             ?.slice(0)
             ?.reverse()
             ?.map((item, i) => (
               <MenuItem key={i} value={item?._id}>
                 {item?.neckName}
               </MenuItem>
             )) || <MenuItem>No data</MenuItem>}
         </Select>
         {userRole === "admin" && (
           <p
             onClick={() => setAddNeckModel(true)}
             className="suffix-text-blue"
           >
             Add Neck Model
           </p>
         )}
       </div>
        }
        {
           cuttingSlipTemplate?.template == "template2" ?
           <div className="global-single-input work-order-input" style={{position:"relative"}}>
           <p>Stitching</p>
           <input
            tabIndex={cuttingSlipTemplate?.template == "template2"&&"14"}
             value={formValues?.remark}
             onChange={userRole !== "admin" && onChange("remark")}
             placeholder={userRole !== "admin" && "Enter..."}
             disabled={userRole === "admin" ? true : false}
           />
            {/* <button className="work-order-notes-add-button">+</button>
           <div className="work-order-notes-content">
             <p>abc</p>
             <p>abc</p>
             <p>abc</p>
           </div> */}
         </div>
         :
         <div className="global-single-input work-order-input">
         <p>Stitching</p>
         <Select
           value={formValues?.stitching}
           onChange={userRole !== "admin" && onChange("stitching")}
           disabled={userRole === "admin" ? true : false}
         >
           <MenuItem value="0">-select stitching-</MenuItem>
           {stitchingData
             ?.slice(0)
             ?.reverse()
             ?.map((item, i) => (
               <MenuItem key={i} value={item?._id}>
                 {item?.stitching}
               </MenuItem>
             )) || <MenuItem>No data</MenuItem>}
         </Select>
         {userRole === "admin" && (
           <p
             onClick={() => setAddStitching(true)}
             className="suffix-text-blue"
           >
             Add Stitching
           </p>
         )}
       </div>
        }
       
       {
        cuttingSlipTemplate?.template == "template2" ?
        <div className="global-single-input work-order-input">
        <p>Button</p>
        <Select
          value={formValues?.stitching}
          onChange={userRole !== "admin" && onChange("stitching")}
          disabled={userRole === "admin" ? true : false}
        >
          <MenuItem value="0">-select Button-</MenuItem>
          {stitchingData
            ?.slice(0)
            ?.reverse()
            ?.map((item, i) => (
              <MenuItem key={i} value={item?._id}>
                {item?.stitching}
              </MenuItem>
            )) || <MenuItem>No data</MenuItem>}
        </Select>
        {userRole === "admin" && (
          <p
            onClick={() => setAddStitching(true)}
            className="suffix-text-blue"
          >
            Add Button
          </p>
        )}
      </div>
      :
      <div className="global-single-input work-order-input" style={{position:"relative"}}>
      <p>Notes</p>
      <input
        value={formValues?.remark}
        onChange={userRole !== "admin" && onChange("remark")}
        placeholder={userRole !== "admin" && "Enter remark..."}
        disabled={userRole === "admin" ? true : false}
      />
       {/* <button className="work-order-notes-add-button">+</button>
      <div className="work-order-notes-content">
        <p>abc</p>
        <p>abc</p>
        <p>abc</p>
      </div> */}
    </div>
       }

       
      </div>
      <div className="work-order-middle-right-container">
        <div className="global-single-input work-order-input">
          <p>{cuttingSlipTemplate?.template == "template2" ? "Shoulder":"Regal Size"}</p>
          <input
            tabIndex={cuttingSlipTemplate?.template == "template2"&&"2"}
            value={formValues?.regalSize}
            onChange={userRole !== "admin" && onChange("regalSize")}
            // placeholder={userRole !== "admin" && "Enter regal size..."}
            placeholder={
              cuttingSlipTemplate?.template == "template2" ?
              "Enter Shoulder..."
              :userRole !== "admin"?
              ""
              :"Enter Regal Size..."
             }
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>Neck Down Button</p>
          <input
            tabIndex={cuttingSlipTemplate?.template == "template2"&&"3"}
            value={formValues?.neckDownButton}
            onChange={userRole !== "admin" && onChange("neckDownButton")}
            placeholder={userRole !== "admin" && "Enter neck down button..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        {
          cuttingSlipTemplate?.template !== "template2" &&
          <div className="global-single-input work-order-input">
          <p>Mar</p>
          <input
            value={formValues?.mar}
            onChange={userRole !== "admin" && onChange("mar")}
            placeholder={userRole !== "admin" && "Enter mar..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        }
        {
          cuttingSlipTemplate?.template == "template2"?
          <div className="global-single-input work-order-input">
          <p>{cuttingSlipTemplate?.template == "template2" ? "Centre":"FP Size"}</p>
          {/* <div className="work-order-input-group"> */}
            <input
              tabIndex={cuttingSlipTemplate?.template == "template2"&&"5"}
              className="work-order-input-group-first"
              value={formValues?.fpSize1}
              onChange={userRole !== "admin" && onChange("fpSize1")}
              placeholder={userRole !== "admin" && "Enter Centre..."}
              disabled={userRole === "admin" ? true : false}
            />
            {/* <input
              className="work-order-input-group-second"
              value={formValues?.fpSize2}
              onChange={userRole !== "admin" && onChange("fpSize2")}
              placeholder={userRole !== "admin" && "..."}
              disabled={userRole === "admin" ? true : false}
            /> */}
          {/* </div> */}
        </div>
        :
          <div className="global-single-input work-order-input">
          <p>Model</p>
          <Select
            value={formValues?.model}
            onChange={userRole !== "admin" && onChange("model")}
            disabled={userRole === "admin" ? true : false}
          >
            <MenuItem value="0">-select model-</MenuItem>
            {modelData
              ?.slice(0)
              ?.reverse()
              ?.map((item, i) => (
                <MenuItem key={i} value={item?._id}>
                  {item?.model}
                </MenuItem>
              )) || <MenuItem>No data</MenuItem>}
          </Select>
          {userRole === "admin" && (
            <p onClick={() => setAddModel(true)} className="suffix-text-blue">
              Add Model
            </p>
          )}
        </div>
        }
       
        
        <div className="global-single-input work-order-input">
          <p>{cuttingSlipTemplate?.template == "template2" ? "Side":"FP Model"}</p>
          <Select
            value={formValues?.fpModel}
            onChange={userRole !== "admin" && onChange("fpModel")}
            disabled={userRole === "admin" ? true : false}
          >
            <MenuItem value="0">-select-</MenuItem>
            {fpModelData
              ?.slice(0)
              ?.reverse()
              ?.map((item, i) => (
                <MenuItem key={i} value={item?._id}>
                  {item?.fpModel}
                </MenuItem>
              )) || <MenuItem>No data</MenuItem>}
          </Select>
          {userRole === "admin" && (
            <p onClick={() => setAddFpModel(true)} className="suffix-text-blue">
              {cuttingSlipTemplate?.template == "template2" ? "Add Side":"Add FP Model"}
            </p>
          )}
        </div>
        {
          cuttingSlipTemplate?.template == "template2" ?
          <div className="global-single-input work-order-input">
          <p>Mar Type</p>
          <Select
            value={formValues?.model}
            onChange={userRole !== "admin" && onChange("model")}
            disabled={userRole === "admin" ? true : false}
          >
            <MenuItem value="0">-select Type-</MenuItem>
            {modelData
              ?.slice(0)
              ?.reverse()
              ?.map((item, i) => (
                <MenuItem key={i} value={item?._id}>
                  {item?.model}
                </MenuItem>
              )) || <MenuItem>No data</MenuItem>}
          </Select>
          {userRole === "admin" && (
            <p onClick={() => setAddModel(true)} className="suffix-text-blue">
              Add Type
            </p>
          )}
        </div>
        :
        <div className="global-single-input work-order-input">
        <p>FP Size</p>
        <div className="work-order-input-group">
          <input
            className="work-order-input-group-first"
            value={formValues?.fpSize1}
            onChange={userRole !== "admin" && onChange("fpSize1")}
            placeholder={userRole !== "admin" && "Enter fp size..."}
            disabled={userRole === "admin" ? true : false}
          />
          <input
            className="work-order-input-group-second"
            value={formValues?.fpSize2}
            onChange={userRole !== "admin" && onChange("fpSize2")}
            placeholder={userRole !== "admin" && "..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
      </div>

        }
       
      </div>

      {/* add jalabia model dialog */}
      <Dialog open={addJalabiaModel} maxWidth="lg"  onKeyDown={(e)=>e.key === "Escape" && setAddJalabiaModel(false)}>
        <div className="add-thob-model-dialog-container">
          <h3>Add Jalabia Model</h3>
          <div className="add-thob-model-dialog-input-container">
            <div className="global-single-input">
              <p>Model Name</p>
              <input
                type="text"
                placeholder="Name..."
                value={jalabiaModelForm?.modelName}
                onChange={getJalabiaModelForm("modelName")}
              />
              {
               modelNameAlert?
               <p className="doc-validation-alert">Fill this field !!</p> 
              :""}
            </div>
            <div className="global-single-input from-length">
              <p>Length From</p>
              <input
                type="text"
                placeholder="From..."
                value={jalabiaModelForm?.lengthFrom}
                onChange={getJalabiaModelForm("lengthFrom")}
              />
                {
               lengthFromAlert?
               <p className="doc-validation-alert">Fill this field !!</p> 
              :""}
            </div>
            <div className="global-single-input from-length">
              <p>To</p>
              <input
                type="text"
                placeholder="To..."
                value={jalabiaModelForm?.lengthTo}
                onChange={getJalabiaModelForm("lengthTo")}
              />
                {
                lengthToAlert?
                <p className="doc-validation-alert">Fill this field !!</p> 
              :""}
            </div>
            <div className="global-single-input from-length">
              <p>Min Rate</p>
              <input
                type="text"
                placeholder="Min rate..."
                value={jalabiaModelForm?.minRate}
                onChange={getJalabiaModelForm("minRate")}
              />
            </div>
            <div className="global-single-input from-length">
              <p>Commission</p>
              <input
                type="text"
                placeholder="commission..."
                value={jalabiaModelForm?.commission}
                onChange={getJalabiaModelForm("commission")}
                onKeyDown={(e) => e.key === "Enter" && addNewJalabiaModel()}
              />
            </div>
            <button onClick={addNewJalabiaModel}>+</button>
          </div>
          <div className="add-popup-table">
            <table>
              <thead>
                <tr>
                  <th>Model Name</th>
                  <th>Length(From-To)</th>
                  <th>Min Rate</th>
                  <th>Commission</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {jalabiaModelData
                  ?.slice(0)
                  ?.reverse()
                  ?.map((r, i) => (
                    <tr key={i}>
                      <td>{r?.jalabiaName}</td>
                      <td>{`${r?.fromLength} - ${r?.toLength}`}</td>
                      <td>{r?.minRate}</td>
                      <td>{r?.commission}</td>
                      <td>
                        <IconButton onClick={deleteJalabiaModel(r?._id)}>
                          <i class="bi bi-trash3 delete-icon"></i>
                        </IconButton>
                      </td>
                    </tr>
                  )) || (
                  <tr>
                    <td>No data</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="add-popup-button-container">
            <button
              onClick={() => setAddJalabiaModel(false)}
              className="btn btn-secondary"
            >
              Close
            </button>
          </div>
        </div>
      </Dialog>

      {/* add length commission */}
      <Dialog open={addLengthCommission} maxWidth="lg"  onKeyDown={(e)=>e.key === "Escape" && setAddLengthCommission(false)}>
        <div className="add-thob-model-dialog-container">
          <h3>Add Length Commission</h3>
          <div className="add-thob-model-dialog-input-container">
            <div className="global-single-input from-length">
              <p>Length From</p>
              <input
                type="text"
                placeholder="Length from..."
                value={lengthCommissionForm?.lengthFrom}
                onChange={getLengthCommissionForm("lengthFrom")}
              />
              {
                lengthFromAlert?
                <p className="doc-validation-alert">Fill this field !!</p>
                :""
              }
            </div>
            <div className="global-single-input from-length">
              <p>To</p>
              <input
                type="text"
                placeholder="Length to..."
                value={lengthCommissionForm?.lengthTo}
                onChange={getLengthCommissionForm("lengthTo")}
              />
              {
                lengthToAlert?
                <p className="doc-validation-alert">Fill this field !!</p>
                :""
              }
            </div>
            <div className="global-single-input from-length">
              <p>Used Qty</p>
              <input
                type="text"
                placeholder="Used Qty..."
                value={lengthCommissionForm?.usedQty}
                onChange={getLengthCommissionForm("usedQty")}
              />
              {
                usedQtyAlert?
                <p className="doc-validation-alert">Fill this field !!</p>
                :""
              }
            </div>
            <div className="global-single-input from-length">
              <p>Min Rate</p>
              <input
                type="text"
                placeholder="Minimum rate..."
                value={lengthCommissionForm?.minRate}
                onChange={getLengthCommissionForm("minRate")}
              />
            </div>
            <div className="global-single-input from-length">
              <p>Commission</p>
              <input
                type="text"
                placeholder="Commission..."
                value={lengthCommissionForm?.commission}
                onChange={getLengthCommissionForm("commission")}
                onKeyDown={(e) => e.key === "Enter" && addNewLengthCommission()}
              />
            </div>
            <button onClick={addNewLengthCommission}>+</button>
          </div>
          <div className="add-popup-table">
            <table>
              <thead>
                <tr>
                  <th>Length(From-To)</th>
                  <th>Used Qty</th>
                  <th>Min Rate</th>
                  <th>Commission</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {lengthCommissionData
                  ?.slice(0)
                  ?.reverse()
                  ?.map((r, i) => (
                    <tr key={i}>
                      <td>{`${r?.fromLength} - ${r?.toLength}`}</td>
                      <td>{r?.usedQty}</td>
                      <td>{r?.minRate}</td>
                      <td>{r?.commission}</td>
                      <td>
                        <IconButton onClick={deleteLengthCommission(r?._id)}>
                          <i class="bi bi-trash3 delete-icon"></i>
                        </IconButton>
                      </td>
                    </tr>
                  )) || (
                  <tr>
                    <td colSpan={5}>No Data</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="add-popup-button-container">
            <button
              onClick={() => setAddLengthCommission(false)}
              className="btn btn-secondary"
            >
              Close
            </button>
          </div>
        </div>
      </Dialog>

      {/* add neck model */}
      <AddCategoryPopup
        open={addNeckModel}
        label="Add Neck Model"
        closePopup={() => setAddNeckModel(false)}
        onChange={getNeckModel}
        value={newNeckModel}
        addBtnClick={addNewNeckModel}
      >
        {neckModelData
          ?.slice(0)
          ?.reverse()
          ?.map((r, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{r?.neckName}</td>
              <td>
                <IconButton onClick={deleteNeckModel(r?._id)}>
                  <i class="bi bi-trash3 delete-icon"></i>
                </IconButton>
              </td>
            </tr>
          )) || (
          <tr>
            <td colSpan={3}>No data</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* add stitching */}
      <AddCategoryPopup
        open={addStitching}
        label="Add Stitching"
        closePopup={() => setAddStitching(false)}
        onChange={getStitching}
        value={newStitching}
        addBtnClick={addNewStitching}
      >
        {stitchingData
          ?.slice(0)
          ?.reverse()
          ?.map((r, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{r?.stitching}</td>
              <td>
                <IconButton onClick={deleteStitching(r?._id)}>
                  <i class="bi bi-trash3 delete-icon"></i>
                </IconButton>
              </td>
            </tr>
          )) || (
          <tr>
            <td colSpan={3}>No data</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* add model */}
      <AddCategoryPopup
        open={addModel}
        label="Add Model"
        closePopup={() => setAddModel(false)}
        onChange={getModel}
        value={newModel}
        addBtnClick={addNewModel}
      >
        {modelData
          ?.slice(0)
          ?.reverse()
          ?.map((r, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{r?.model}</td>
              <td>
                <IconButton onClick={deleteModel(r?._id)}>
                  <i class="bi bi-trash3 delete-icon"></i>
                </IconButton>
              </td>
            </tr>
          )) || (
          <tr>
            <td colSpan={3}>No data</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* add fp model */}
      <AddCategoryPopup
        open={addFpModel}
        label="Add FP Model"
        closePopup={() => setAddFpModel(false)}
        onChange={getFpModel}
        value={fpModel}
        addBtnClick={addNewFpModel}
      >
        {fpModelData
          ?.slice(0)
          ?.reverse()
          ?.map((r, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{r?.fpModel}</td>
              <td>
                <IconButton onClick={deleteFpModel(r?._id)}>
                  <i class="bi bi-trash3 delete-icon"></i>
                </IconButton>
              </td>
            </tr>
          )) || (
          <tr>
            <td colSpan={3}>No data</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* SnackBars  */}
      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </div>
  );
};
