import { createSlice } from "@reduxjs/toolkit";

export const attributeSlice=createSlice({
    name:"attributeSlice",
    initialState:{
        value:undefined,
        singleView:undefined
    },
    reducers:{
        get_all_attribute:(state,action)=>{
            state.value=action.payload.attributeData
        },
        singleViewAttribute:(state,action)=>{
            state.singleView = action.payload.singleViewOfAttribute
        }
    }
})
export const {get_all_attribute,singleViewAttribute}=attributeSlice?.actions
export default attributeSlice.reducer