
import { Autocomplete, IconButton, TextField, Tooltip } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import { viewAllBranchesAPICall } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import countryWithState from "../../../../Assets/JSON/countryStates.json"
import SuccessSnackbar from '../../../Single Components/SnackBars/SuccessSnackbar';
import ErrorSnackbar from '../../../Single Components/SnackBars/ErrorSnackbar';
import { addWareHouseAPICall, editWareHouseAPICall, singleViewWareHouseAPICall } from './wareHouseAPI';
import { vieWareHouseLocationAPICall } from './wareHouseLocationAPI';
import {LoadingForm} from "../../../Single Components/LoadingForm" 
export const CreateWareHouse = () => {
   let navigate = useNavigate();
   const singleViewById=localStorage.getItem("singleViewById")
   // all branch list
   const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
   // ware house single view
   const singleWareHouseData=useSelector((state)=>state.wareHouseSlice.singleValue)
   // ware house location list
   const wareHouseLocationList=useSelector((state)=>state.wareHouseLocationSlice.value)
   
    const wareHouseInitialForm={
      name:"",
      branch:null,
      country:null,
      state:null,
      street:"",
      shortCode:"",
      defaultLocation:null
   }
   const [wareHouseForm,setWareHouseForm]=useState(wareHouseInitialForm)
   const [stateList,setStateList]=useState([])
   const [validationAlert,setValidationAlert]=useState({})
   const [successSnackOpen, setSuccessSnackOpen] = useState(false);
   const [errorSnackOpen, setErrorSnackOpen] = useState(false);
   const [snackMsg, setSnackMsg] = useState("Error!!");
   const [loadingForm,setLoadingForm]=useState(false)

   
   const backToMainPage=()=>{
     if (wareHouseForm?.isEdit === false) {
      const userConfirmed = window.confirm("Changes you made may not be saved.");
      
      if (userConfirmed) {
        navigate("/userdashboard/inventory/configuration/warehouse")
      }
    }
    else{
      navigate("/userdashboard/inventory/configuration/warehouse")
    }
    
  }
  
  // snackbar function
  const closeSuccessSnackbar=()=>{
    setSuccessSnackOpen(false)
 }
 
 const closeErrorSnackbar=()=>{
      setErrorSnackOpen(false)
    }
    
  const getWareHouseForm = (key) => (e, newValue) => {
    setValidationAlert({})
    if (key === "name") {
      setWareHouseForm({ ...wareHouseForm, name: e.target.value })
    }
    if (key === "branch") {
      setWareHouseForm({ ...wareHouseForm, branch: newValue })
    }
    if (key === "country") {
      setWareHouseForm({ ...wareHouseForm, country: newValue })
    }
    if (key === "state") {
      setWareHouseForm({ ...wareHouseForm, state: newValue })
    }
    if (key === "street") {
      setWareHouseForm({ ...wareHouseForm, street: e.target.value })
    }
    if (key === "shortCode") {

      if (e.target.value.length > 4) {
        setValidationAlert({ ...validationAlert, shortCodeLimit: "Max Length 4 !!" })
      }
      else {
        setWareHouseForm({ ...wareHouseForm, shortCode: e.target.value })
      }
    }
    if (key === "defaultLocation") {
      setWareHouseForm({ ...wareHouseForm, defaultLocation: newValue })
    }

  }
const bodyToApi={
  name: wareHouseForm?.name,
  country:wareHouseForm?.country?.country,
  state:wareHouseForm?.state,
  street:wareHouseForm?.street,
  branchId:wareHouseForm?.branch?._id,
  shortCode:wareHouseForm?.shortCode,
    defaultLocation:wareHouseForm?.defaultLocation?._id
 }
 // add ware house
 const addWareHouseFunction=()=>{
  if(wareHouseForm?.name===""){
    setValidationAlert({...validationAlert,name:"fill this field"})
  }
  else if(wareHouseForm?.branch===null){
    setValidationAlert({...validationAlert,branch:"fill this field"})
  }
  else if(wareHouseForm?.country===null){
    setValidationAlert({...validationAlert,country:"fill this field"})
  }
  else if(wareHouseForm?.state===null){
    setValidationAlert({...validationAlert,state:"fill this field"})
  }
  else if(wareHouseForm?.street===""){
    setValidationAlert({...validationAlert,street:"fill this field"})
  }
  else if(wareHouseForm?.shortCode===""){
    setValidationAlert({...validationAlert,shortCode:"fill this field"})
  }
  else{
   addWareHouseAPICall(
    bodyToApi,
    cleareState,
    setSuccessSnackOpen,
    setErrorSnackOpen,
    setSnackMsg,setLoadingForm
    )

  }
  
 }


 const cleareState=()=>{
   setWareHouseForm(wareHouseInitialForm)
  }
  const editBody={
   name: wareHouseForm?.name,
   country:wareHouseForm?.country?.country,
   state:wareHouseForm?.state,
   street:wareHouseForm?.street,
   branchId:wareHouseForm?.branch?._id,
   shortCode:wareHouseForm?.shortCode,
   defaultLocation:wareHouseForm?.defaultLocation,
   _id:singleWareHouseData?._id
  }
  const editaveFunction=()=>{
     editWareHouseAPICall(
       editBody,
       setSuccessSnackOpen,
       setErrorSnackOpen,
       setSnackMsg,setLoadingForm
       )
  }
  const editEnableFn=()=>{
     setWareHouseForm({...wareHouseForm,isEdit:false})
  }

const filterObjFromList=(key,list,item)=>{
  let filteredArray=[]
  filteredArray=list?.filter((obj)=>
      obj[key]===item
  )
  return filteredArray?.length!==0&& filteredArray !== undefined ? filteredArray[0]:null;
}


useEffect(()=>{  
  if(wareHouseForm !== undefined){
    setWareHouseForm({...wareHouseForm,
       country:filterObjFromList("country",countryWithState?.countries,wareHouseForm?.branch?.country),
       street:wareHouseForm?.branch?.address
    })
  }
 
},[wareHouseForm?.branch])

useEffect(()=>{
  setWareHouseForm({...wareHouseForm,
    state:wareHouseForm?.branch?.state
  })
},[wareHouseForm?.country])


// filter state from selected country
useEffect(()=>{
  let data =  countryWithState?.countries?.filter((obj)=>{
    if(obj?.country===wareHouseForm.country?.country){
      return obj
    }
  })
  setStateList(data !== undefined && data[0])
},[wareHouseForm.country,countryWithState])

useEffect(()=>{
 viewAllBranchesAPICall();
 vieWareHouseLocationAPICall()
 singleViewWareHouseAPICall({id:singleViewById})
},[])


 /// edit function
 useEffect(()=>{
   if(singleWareHouseData !== undefined){
     setWareHouseForm({
      ...wareHouseForm,
      name:singleWareHouseData?.name,
      branch:filterObjFromList("_id",allBranchesList,singleWareHouseData?.branchId),
      shortCode:singleWareHouseData?.shortCode,
      defaultLocation:filterObjFromList("_id",wareHouseLocationList,singleWareHouseData?.defaultLocation),
      editBtn:true,
      isEdit:true,
     })
   }
 },[singleWareHouseData])




 useEffect(() => {
  const handleBeforeUnload = (event) => {
    const shouldShowAlert = !wareHouseForm?.isEdit;
    if (shouldShowAlert) {
      event.preventDefault();
    }
  };

  window.addEventListener('beforeunload', handleBeforeUnload);

  return () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
  };
}, [wareHouseForm?.isEdit]);


  return (
    <div className='global-page-parent-container'>
       {/* <p className="breadcrumb">Inventory &gt; Configuration &gt;create Ware House</p> */}
       <div className="global-white-bg-container">
       <Tooltip title="Back" onClick={backToMainPage}>
           <IconButton >
             <i class="bi bi-chevron-left"></i>
         </IconButton>
        </Tooltip>
        {
          wareHouseForm?.editBtn=== true &&
          <Tooltip title="Edit">
          <IconButton onClick={wareHouseForm?.isEdit === true ?editEnableFn:editaveFunction}>
             {
              wareHouseForm?.isEdit === true ?
              <i class="bi bi-pencil-square edit-icon1"></i>
              :
              <i class="bi bi-check2-square edit-icon" style={{fontSize:"1.5rem"}}></i>
             }
             
            
              
        
          </IconButton>
        </Tooltip>
        }
        {
           wareHouseForm?.editBtn !== true &&
           <Tooltip title="Save">
           <IconButton onClick={addWareHouseFunction}>
             <i class="bi bi-floppy"></i>
            </IconButton>
           </Tooltip>
        }
         
       </div>
       <div className='global-white-bg-container create-journal-container'>
         <div className='global-single-input create-joutnal-input'>
           <p>Name</p>
           <input type="text" 
              value={wareHouseForm?.name}
              onChange={getWareHouseForm("name")}
              disabled={wareHouseForm?.isEdit}
           />
            <p className="doc-validation-alert">{validationAlert?.name}</p>
         </div>
         <div className='global-single-input create-joutnal-input auto-complete'>
           <p>Branch</p>
           <Autocomplete
                sx={{ width: "100%" }}
                options={allBranchesList||[""]}
                getOptionLabel={(option) => `${option?.storeCode}-${option?.branchName}`}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Branch" />
                )}
                value={wareHouseForm?.branch}
                onChange={getWareHouseForm("branch")}
                disabled={wareHouseForm?.isEdit}
                
             />
               <p className="doc-validation-alert">{validationAlert?.branch}</p>
         </div>
         <div className='global-single-input create-joutnal-input auto-complete'>
           <p>Country</p>
           <Autocomplete
                sx={{ width: "100%" }}
                options={countryWithState?.countries||[""]}
                getOptionLabel={(option) =>option?.country}
                renderInput={(params) => (
                  <TextField {...params} />
                )}
                value={wareHouseForm?.country}
                onChange={getWareHouseForm("country")}
                disabled={wareHouseForm?.isEdit}
                
             />
               <p className="doc-validation-alert">{validationAlert?.country}</p>

         </div>
         <div className='global-single-input create-joutnal-input auto-complete'>
           <p>State</p>
           <Autocomplete
                sx={{ width: "100%" }}
                options={stateList?.states||[""]}
                getOptionLabel={(option) =>option}
                renderInput={(params) => (
                  <TextField {...params} />
                )}
                value={wareHouseForm?.state}
                onChange={getWareHouseForm("state")}
                disabled={wareHouseForm?.isEdit}
                
             />
              <p className="doc-validation-alert">{validationAlert?.state}</p>
         </div>
         <div className='global-single-input create-joutnal-input'>
           <p>Street</p>
           <input type="text" 
             value={wareHouseForm?.street}
             onChange={getWareHouseForm("street")}
             disabled={wareHouseForm?.isEdit}
           />
         </div>
         <p className="doc-validation-alert">{validationAlert?.street}</p>
        
         <div className='global-single-input create-joutnal-input'>
           <p>Short Code</p>
           <input type="text" 
               value={wareHouseForm?.shortCode}
               onChange={getWareHouseForm("shortCode")}
               disabled={wareHouseForm?.isEdit}
           />
            <p className="doc-validation-alert">{validationAlert?.shortCode}</p>
            <p className="doc-validation-alert">{validationAlert?.shortCodeLimit}</p>
         </div>
         <div className='global-single-input auto-complete create-joutnal-input'>
           <p>Default Location</p>
           <Autocomplete
                sx={{ width: "100%" }}
                options={wareHouseLocationList||["No Data"]}
                getOptionLabel={(option) =>option?.name}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Default Account" />
                )}
                value={wareHouseForm?.defaultLocation}
                onChange={getWareHouseForm("defaultLocation")}
                disabled={wareHouseForm?.isEdit}
               
             />
         </div>
       </div>

       <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />

      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />

    <LoadingForm loading={loadingForm}/>

    </div>
  )
}
