import {createSlice} from '@reduxjs/toolkit'

export const stockAdjustmentSlice=createSlice({
    name:'stockAdjustmentSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_stock_adjustment:(state,action)=>{
            state.value=action.payload.stockAdjustmentData
        }
    }

})
export const {get_stock_adjustment} = stockAdjustmentSlice.actions

export default stockAdjustmentSlice.reducer