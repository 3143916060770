import { createSlice } from "@reduxjs/toolkit";

export const wareHouseLocationSlice = createSlice({
  name: "wareHouseLocationSlice",
  initialState: {
    value: undefined,
    singleValue:undefined
  },
  reducers: {
    get_wareHouse_location_list: (state, action) => {
      state.value = action.payload.wareHouseLocationData;
    },
    get_wareHouse_location_single_view:(state,action)=>{
      state.singleValue=action.payload.singleWareHouseLocationData
    }
  
  },
});

export const {get_wareHouse_location_list,get_wareHouse_location_single_view} = wareHouseLocationSlice.actions;

export default wareHouseLocationSlice.reducer;