import { createSlice } from "@reduxjs/toolkit";

export const PurchaseTaxListSlice = createSlice({
  name: "PurchaseTaxListSlice",
  initialState: {
    value: undefined,
  },
  reducers: {
    get_purchse_tax_list: (state, action) => {
      state.value = action.payload.purchseTaxData;
    },
  
  },
});

export const {get_purchse_tax_list} = PurchaseTaxListSlice.actions;

export default PurchaseTaxListSlice.reducer;