import { Autocomplete, IconButton, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { addAttributeAPICall, editAttributeAPICall } from "./AttributeAPI";
import SuccessSnackbar from "../../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
import { useSelector } from "react-redux";
import {LoadingForm} from "../../../../Single Components/LoadingForm"
export const CreateAttribute = () => {
  const singleViewOfAttribute = useSelector(
    (state) => state.attributeSlice?.singleView
  );
  let navigate = useNavigate();
  const initialFormState = {
    AttributeName: "",
    value: "",
  };

  const [FormValue, setFormValue] = useState(initialFormState);
  const [validationAlert, setValidationAlert] = useState({});
  const [formAddedTable, setFormAddedTable] = useState([]);
  const [formAddedTableView, setFormAddedTableView] = useState([]);
  const [showSaveBtn, setSaveBtn] = useState(false);
  const [showEditBtn, setShowEditBtn] = useState(false);
  const [disabledInput,setDisabledInput]= useState(false);
  const [loadingForm,setLoadingForm]=useState(false)

  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
  });

  const backToMainPage = () => {
    if (disabledInput === false) {
      const userConfirmed = window.confirm("Changes you made may not be saved.");

      if (userConfirmed) {
        navigate("/userdashboard/inventory/configuration/attribute");
      }
    }
    else {
      navigate("/userdashboard/inventory/configuration/attribute");
    }

    
  };
  //getFormInfo
  const getFormInfo = (key) => (e) => {
    const { value } = e.target;
    setValidationAlert({});
    setFormValue({ ...FormValue, [key]: value });
  };

  const clearLineData = () => {
    setFormValue({ ...FormValue, value: "" });
  };

  const addTableData = (key) => () => {
    if (FormValue?.AttributeName === "") {
      setValidationAlert({
        ...validationAlert,
        AttributeName: "fill this field !!",
      });
    } else if (FormValue?.value === "") {
      setValidationAlert({ ...validationAlert, value: "fill this field !!" });
    } else {

      const attributeId = FormValue?.value;
      // Check if the attributeId already exists in the attributeTable
      const isAttributeIdExist = formAddedTable.some(item => item.value?.toLowerCase() === attributeId?.toLowerCase());
     
    if(isAttributeIdExist){
        setSnackBarStates({ ...snackBarStates, message: "attribute type already  exist!!", error: true })
    }
    else{
      setFormAddedTable((prevFormAddedTable) => [
        ...prevFormAddedTable,
        {
          value: FormValue?.value,
        },
      ]);
      clearLineData();
    }
    }
  };

  
  //DeleteTable
  const DeleteTable = (id) => {
    const newArray = formAddedTable.filter((item) => item.value !== id);
    setFormAddedTable(newArray);
  };
  
  //saveAttributeFun
  const saveAttributeFun = () => {
    let clearData=()=>{
      setFormAddedTable([])
      setFormAddedTableView([])
      setFormValue(initialFormState)
    }
    addAttributeAPICall(
      {
        attributeName: FormValue?.AttributeName,
        attCatogory: formAddedTableView,
      },
      setSnackBarStates,
      snackBarStates,clearData,setLoadingForm
      );
  };

  //closeSnackbar
  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      message: "",
    });
  };
  
  //editEnable
  const editEnable =()=>{
    setDisabledInput(false)
  }

  //editBody

  const editBody = {
    id: singleViewOfAttribute?._id,
    attributeName:FormValue?.AttributeName,
    attCatogory:formAddedTableView
  }

  //updateAttributeBtn
  const updateAttributeBtn =()=>{
    editAttributeAPICall(
      editBody,
      setSnackBarStates,
      snackBarStates,setDisabledInput,setLoadingForm
    )
  }
  useEffect(() => {
    if (formAddedTable?.length !== 0) {
      const newTableView = formAddedTable.map((entry) => ({
        value: entry.value || "",
      }));
      setFormAddedTableView(newTableView);
    } else {
      setFormAddedTableView([]);
    }
  }, [formAddedTable]);

  useEffect(() => {
    if (singleViewOfAttribute !== undefined) {
      setSaveBtn(true);
      setShowEditBtn(true);
      setDisabledInput(true);
    }
  }, [singleViewOfAttribute]);



  useEffect(() => {
    if (singleViewOfAttribute !== undefined) {
      setFormValue({
        ...FormValue,
        AttributeName: singleViewOfAttribute?.attributeName,
      });
      setFormAddedTable(singleViewOfAttribute?.attCatogory);
    }
  }, [singleViewOfAttribute]);



  
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const shouldShowAlert = !disabledInput;
      if (shouldShowAlert) {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [disabledInput]);
  return (
    <div className="global-page-parent-container">

      <div className="global-white-bg-container">
        <Tooltip title="Back" onClick={backToMainPage}>
          <IconButton>
            <i class="bi bi-chevron-left"></i>
          </IconButton>
        </Tooltip>

        {showEditBtn ? (
          <Tooltip title="Edit">
            <IconButton>
              {disabledInput ? <i class="bi bi-pencil-square edit-icon1" onClick={editEnable}></i> :<i class="bi bi-check2-square edit-icon" style={{fontSize:"1.5rem"}} onClick={updateAttributeBtn}></i> }

              {/* <i class="bi bi-check2-square edit-icon" style={{fontSize:"1.5rem"}}></i> */}
            </IconButton>
          </Tooltip>
        ) : (
          ""
        )}

        {showSaveBtn ? (
          ""
        ) : (
          <Tooltip
            title="Save"
            onClick={saveAttributeFun}
            disabled={formAddedTable?.length === 0}
          >
            <IconButton>
              <i class="bi bi-floppy"></i>
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div className="global-white-bg-container">
        <div className="global-input-field-container" style={{ padding: "0" }}>
          <div className="global-single-input">
            <p>Attribute Name</p>
            <input
              type="text"
              onChange={getFormInfo("AttributeName")}
              value={FormValue?.AttributeName}
              disabled={disabledInput}
            />
            <p className="doc-validation-alert">
              {validationAlert?.AttributeName}
            </p>
          </div>
        </div>

        <div
          className="global-input-field-container"
          style={{ paddingTop: "0" }}
        >
          <div className="global-single-input">
            <p>Value</p>
            <input
              type="text"
              onChange={getFormInfo("value")}
              value={FormValue?.value}
              disabled={disabledInput}
            />
            <p className="doc-validation-alert">{validationAlert?.value}</p>
          </div>

          {/* <button className="plus-btn">
                <IconButton >
                  <i class="bi bi-pencil-square edit-icon1"></i>
                </IconButton>
              </button> */}

          <button className="plus-btn" onClick={addTableData("add")}>
            +
          </button>

          {/* <button className="clear-btn">
                <i className="bi bi-trash3"></i>
              </button>
          */}
        </div>
      </div>

      <div className="global-table-container">
        <table className="global-table" style={{ width: "50%" }}>
          <thead>
            <tr>
              <th>Value</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {formAddedTableView?.length !== 0 ? (
              formAddedTableView.map((r, i) => (
                <tr key={i} >
                  <td>{r?.value}</td>
                  <td>
                    <IconButton
                      onClick={() => DeleteTable(r.value)}
                      style={{ alignSelf: "flex-end" }}
                      disabled={disabledInput}
                    >
                      <i
                        className="bi bi-trash3"
                        style={{ color: "#db0000" }}
                      ></i>
                    </IconButton>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7}>No data</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />

      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />

      <LoadingForm loading={loadingForm}/>

    </div>
  );
};
