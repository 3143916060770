import { Dialog, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
// import { getAllSupplierAPICall } from "../../API/Purchase Manage/Supplier Manage/supplierManageAPI";
import "../../css/Single Components/voucher.css";
import {  today } from "../../Js/Date";
//*Voucher Custom Component
const Voucher = (props) => {
  const { onClose,
      open, supplierData,
      paymentMethodData, cardTypeData,
      idLable,onChange,Cardlist,UpiList,
      finalApiCall,transactionId } =props;
  //List of All Suppliers
  // const allSuppliersList = useSelector((state) => state.suppliers.value);
  //state to show payment option
  const [isPaymentOptionShows, setIsPaymentOptionShows] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("cash");
  
  const [paidAmtAlert,setPaidAmtAlert]=useState(false)
  const [paidDateAlert,setPaidDateAlert]=useState(false)
  const [cardTypeAlert,setCardTypeAlert]=useState(false)
  const [accNoAlert,setAccNoAlert]=useState(false)
  const dateToday=today
  //Get all suppliers
  // useEffect(() => {
  //   getAllSupplierAPICall();
  // }, []);
  //Function to trigger payment options shows or not
  const clickPaymentBtn = () => {
    // console.log(supplierData);
    setIsPaymentOptionShows(!isPaymentOptionShows);
  };
  const clickVoucherSubmit=()=>{
    if(supplierData?.lastPaidAmount===""){
      setPaidAmtAlert(true)
    }
    else if(supplierData?.voucherDate===""){
      setPaidDateAlert(true)
    }
    else if(
      supplierData?.paymentMethod==="card" && supplierData?.cardVendor===""
    ){
      setCardTypeAlert(true)
    }
    else if(
      supplierData?.paymentMethod==="card" && supplierData?.account===""
    ){
      setAccNoAlert(true)
    }
    else if(
      supplierData?.paymentMethod==="upi" && supplierData?.upiVendor===""
    ){
      setCardTypeAlert(true)
    }
    else if(
      supplierData?.paymentMethod==="upi" && supplierData?.upiId===""
    ){
      setAccNoAlert(true)
    }
    else{
      finalApiCall()
    }
  }
  useEffect(()=>{
    if(supplierData?.lastPaidAmount!==""){
      setPaidAmtAlert(false)
    }
     if(supplierData?.voucherDate!==""){
      setPaidDateAlert(false)
    }
     if(
      supplierData?.paymentMethod==="card" && supplierData?.cardVendor!==""
    ){
      setCardTypeAlert(false)
    }
     if(
      supplierData?.paymentMethod==="card" && supplierData?.account!==""
    ){
      setAccNoAlert(false)
    }
     if(
      supplierData?.paymentMethod==="upi" && supplierData?.upiVendor!==""
    ){
      setCardTypeAlert(false)
    }
     if(
      supplierData?.paymentMethod==="upi" && supplierData?.upiId!==""
    ){
      setAccNoAlert(false)
    }
  },[supplierData])
  return (
    <>
      <Dialog maxWidth="lg" open={open}>
        <div className="parent-container-voucher">
          <h3>Create Voucher</h3>
          <div className="voucher-main-form-container">
            <div className="global-single-input voucher-single-input">
              <p>Transaction ID</p>
              <input type="text" disabled value={transactionId} />
            </div>
            <div className="global-single-input voucher-single-input">
              <p>{idLable}</p>
              <input type="text" value={supplierData?.tansactionId} disabled />
            </div>
            <div className="global-single-input voucher-single-input">
              <p>Supplier</p>
              {props.children}
              {/* <input type="text" value={props.children} /> */}
            </div>
            <div className="global-single-input voucher-single-input">
              <p>Date</p>
              <input type="date" name="" id="" value={dateToday} disabled/>
            </div>
            <div className="global-single-input voucher-single-input">
              <p>Credit amount (Balance amount)</p>
              <input type="text" 
              disabled
              value={supplierData?.balanceAmt}
              />
            </div>
            <div className="global-single-input voucher-single-input">
              <p>Last paid amount</p>
              <input type="text" name="" id="" 
              onChange={
                        onChange("lastPaidAmt")
                        }
              />
              {
                paidAmtAlert?
                <p className="doc-validation-alert">
                  Fill this field !!
                </p>:""
              }
            </div>
            <div className="global-single-input voucher-single-input">
              <p>Last paid date</p>
              <input type="date" name="" id="" onChange={onChange("date")}/>
              {
                paidDateAlert?
                <p className="doc-validation-alert">
                  Fill this field !!
                </p>:""
              }
            </div>
            <div className="global-single-input voucher-single-input voucher-btn-wrapper">
              <button
                className="btn voucher-pay-btn"
                onClick={clickPaymentBtn}
                style={{margin:"0"}}
              >
                Payment
              </button>
            </div>
          </div>
          <hr className="voucher-hr" />
          {isPaymentOptionShows && (
            <div className="payment-fields-container">
              <div className="global-single-input voucher-single-input">
                <p>Payment method</p>
                <Select
                  value={supplierData?.paymentMethod}
                  onChange={onChange("paymentMethod")}
                >
                  <MenuItem value="cash">Cash</MenuItem>
                  <MenuItem value="card">Card</MenuItem>
                  <MenuItem value="upi">UPI</MenuItem>
                </Select>
              </div>
              {supplierData?.paymentMethod === "card" ? (
                <>
                  <div className="global-single-input voucher-single-input">
                    <p>Card type</p>
                    <Select 
                      onChange={onChange("cardType")}>
                        {
                          Cardlist!==undefined?(
                            Cardlist?.map((r,i)=>(
                              <MenuItem value={r?.cardName}>{r?.cardName}</MenuItem>
                            ))
                          )
                          : (
                            <MenuItem>No Data</MenuItem>
                          ) 
                        }
                    </Select>
                    {
                      cardTypeAlert?
                      <p className="doc-validation-alert">Select a value !!</p>
                    :""}
                  </div>
                  <div className="global-single-input voucher-single-input">
                    <p>Card No.</p>
                    <input 
                    type="text" 
                    onChange={onChange("accountNo")}
                    name="" id="" />
                    {
                      accNoAlert?
                      <p className="doc-validation-alert">
                        Fill this field !!
                      </p>:""
                    }
                  </div>
                  <div className="global-single-input voucher-single-input">
                    <p>CVV</p>
                    <input type="text" name="" id="" />
                  </div>
                </>
              ) : supplierData?.paymentMethod === "upi" ? (
                <><div className="global-single-input voucher-single-input">
                    <p>UPI Type</p>
                    <Select 
                      onChange={onChange("upiType")}>
                        {
                          UpiList!==undefined?(
                            UpiList?.map((r,i)=>(
                              <MenuItem value={r?.upiName}>{r?.upiName}</MenuItem>
                            ))
                          )
                          : (
                            <MenuItem>No Data</MenuItem>
                          ) 
                        }
                    </Select>
                    {
                      cardTypeAlert?
                      <p className="doc-validation-alert">Select a value !!</p>
                    :""}
                  </div>
                  <div className="global-single-input voucher-single-input">
                    <p>Upi ID</p>
                    <input type="text" 
                    onChange={onChange("upiId")}
                    name="" 
                    id="" />
                    {
                      accNoAlert?
                      <p className="doc-validation-alert">
                        Fill this field !!
                      </p>:""
                    }
                  </div>
                </>
              ) : undefined}
            </div>
          )}

          <div className="cancel-submit-container">
            <button
              className="btn btn-secondary-outlined voucher-action-btn"
              onClick={onClose}
            >
              Cancel
            </button>
            <button 
            onClick={clickVoucherSubmit}
            className="btn btn-secondary voucher-action-btn">
              Submit</button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default Voucher;
