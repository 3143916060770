import {createSlice} from '@reduxjs/toolkit'

export const viewPaymentsSlice=createSlice({
    name:'viewPayments',
    initialState:{
        value:undefined
    },
    reducers:{
        get_payments_list:(state,action)=>{
            state.value=action.payload.viewPaymentsData
        }
    }
})
export const {get_payments_list} = viewPaymentsSlice.actions;
export default viewPaymentsSlice.reducer