import axios from "axios";
import store from "../../../../Redux/store";
import { HEADERS } from "../../../UrlAndPaths";
import { get_stock_order_list } from "../../../../Redux/Settings/Utility/stockCorrection/stockOrderListSlice";


export const viewStockOrderListAPICall=async(body,setIsLoading)=>{
    setIsLoading(true)
    await axios.post("settings/getStockDetails",body,HEADERS)
            .then((res)=>{
                if(res.status===200){
                    store.dispatch(get_stock_order_list({
                        stockOrderData:res.data
                    }))
                    setIsLoading(false);
                }
            })
            .catch((err)=>{
                store.dispatch(get_stock_order_list({
                    stockOrderData:undefined
                }))
                console.error(err.response.status)
                setIsLoading(false);
            })
}

export const editUtilityStockOrderAPICall=async(body,updateListener,setSucces,setError,setSnackMsg)=>{
    await axios.put("settings/editStock",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                setSnackMsg("Stock edited")
                updateListener()
                setSucces(true)
            }
        })
        .catch((err)=>{
            setSnackMsg("Something went wrong!!")
            console.error(err.response.status)
            setError(true)
        })
}