import { createSlice } from "@reduxjs/toolkit";

export const viewWorkOrderSettingsSlice=createSlice({
    name:"viewWorkOrderSettingsSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        view_work_order_settings:(state,action)=>{
            state.value=action.payload.viewWorkOrderSettingsData
        }
    }
})

export const {view_work_order_settings}=viewWorkOrderSettingsSlice.actions
export default viewWorkOrderSettingsSlice.reducer