import axios from 'axios'
import {get_payments_list} from '../../Redux/Sales/Payments/viewPaymentsSlice'
import store from '../../Redux/store'
import {HEADERS,HEADER_QUERY,HEADER_FORM_DATA} from '../../API/UrlAndPaths'
import {get_all_sales_invoice} from '../../Redux/Sales/Payments/invoiceNoListSlice'
import {get_customerSuppList} from '../../Redux/Sales/Payments/customerSupplierListSlice'
// view payment list
export const viewPaymentsAPICall= async (setIsLoading,body)=>{
  setIsLoading(true);
  await axios.post("sales/viewsalespayments",body,HEADER_FORM_DATA
  )
  .then((res)=>{
    if(res.status === 200){
        store.dispatch(get_payments_list({viewPaymentsData:res.data}))
        setIsLoading(false);
    }
  })
  .catch((err)=>{
    store.dispatch(get_payments_list({viewPaymentsData:undefined}))
    console.log(err.response);
    setIsLoading(false);
  })
}

// all invoice list 
export const allInvoiceNoAPICall= async (body)=>{
 await axios.post("sales/getOrderNo",body,HEADERS)
 .then((res)=>{
  if(res.status===200){
    store.dispatch(get_all_sales_invoice({salesInvoiceList:res.data}))
  }
 })
 .catch((err)=>{
   store.dispatch(get_all_sales_invoice({salesInvoiceList:undefined}))
  console.log(err.response);
 })
}

// customer supplier list apicall
export const customerSuppAPICall= async (body)=>{
  await axios.post("sales/getCustomerList",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_customerSuppList({customerSuppData:res.data}))
    }
  })
  .then((err)=>{
    store.dispatch(get_customerSuppList({customerSuppData:undefined}))
    console.log(err.response);
  })
}