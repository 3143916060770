import axios from "axios";
import { HEADERS } from "../../UrlAndPaths";

import store from "../../../Redux/store";
import { get_grn_list_row_data_list } from "../../../component/Modules/Purchase Manager/Pages/GRNUpdated/grnListingSlice";

export const ConfirmPurchaseOrderApiCall = (
  body,
  setState,
  state,
  setFormValues,
  formInitialState,
  setFormAddedTable
) => {
  axios
    .post("purchase/purchaseOrder", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setState({ ...state, message: "AddSuccess fully !!", success: true });
        setFormValues(formInitialState);
        setFormAddedTable([]);
      }
    })
    .catch((err) => {
      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
      } else {
        setState({ ...state, message: err?.response?.data, error: true })
      }
    });
};

//SavePurchaseOrderAPiCall

export const SavePurchaseOrderApiCall = (
  body,
  setState,
  state,
  setFormValues,
  formInitialState,
  setFormAddedTable,
  setshowpurchaseOrderConfirm,
  setsavePurchaseOrderDetails,
  confirmPurchaseOrderBtnFun
) => {
  axios
    .post("purchase/purchaseOrder", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setState({ ...state, message: "AddSuccess fully !!", success: true });
        setshowpurchaseOrderConfirm(true);
        // setFormValues(formInitialState);
        // setFormAddedTable([]);
        setsavePurchaseOrderDetails(res.data);
        // confirmPurchaseOrderBtnFun()
      }
    })
    .catch((err) => {
      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
      } else {
        setState({ ...state, message: err?.response?.data, error: true })
      }
    });
};

//confirmPurchasOrderApiCall
export const confirmPurchasOrderApiCall = (
  body,
  setState,
  state,
  navigateCall
) => {
  axios
    .post("purchase/addGoodsReceivedNote", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setState({
          ...state,
          message: "PurchaseOrder Confirmed!!",
          success: true,
        });
        console.log(res.data);
        store.dispatch(
          get_grn_list_row_data_list({ grnListSingleRowData: res.data })
        );

        setTimeout(() => {
          navigateCall !== undefined && navigateCall();
        }, 2000);
      }
    })
    .catch((err) => {
      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
      } else {
        setState({ ...state, message: err?.response?.data, error: true })
      }
    });
};

//updatePurchaseOrderApiCall

export const updatePurchaseOrderApiCall = (
  body,
  setState,
  state,
  setshowpurchaseOrderConfirm,
  setsavePurchaseOrderDetails
) => {
  axios
    .put("purchase/updatePurchaseOrderById", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("BBBUUU>>>>>>", res.data);
        setState({ ...state, message: "PurchaseOrder edited", success: true });
        setshowpurchaseOrderConfirm(true);
        setsavePurchaseOrderDetails(res.data);
      }
    })
    .catch((err) => {
      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
      } else {
        setState({ ...state, message: err?.response?.data, error: true })
      }
    });
};

//updatePurchaseOrderApiCall through RFQ
export const updatePurchaseOrderApiCallinRFQ = (
  body,
  setState,
  state,
  setsaveRfqDeatails,
  setshowpurchaseOrderConfirm
) => {
  axios
    .put("purchase/updatePurchaseOrderById", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("BBBUUU>>>>>>", res.data);
        setState({ ...state, message: "PurchaseOrder edited", success: true });
        setsaveRfqDeatails(res.data);
        setshowpurchaseOrderConfirm(true);
      }
    })
    .catch((err) => {
      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
      } else {
        setState({ ...state, message: err?.response?.data, error: true })
      }
    });
};

// confirmPurchasOrder_New_ApiCall

export const confirmPurchasOrderNewApiCall = (
  body,
  setState,
  state,
  navigateCall,setLoadingForm
) => {
  
  setLoadingForm!==undefined && setLoadingForm(true)

  axios
    .post("purchase/addGoodsReceivedNote", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        localStorage.setItem('singleViewById',res.data?._id)
        setState({
          ...state,
          message: "PurchaseOrder Confirmed!!",
          success: true,
        });
       

        store.dispatch(
          get_grn_list_row_data_list({ grnListSingleRowData: res.data })
        );

        setTimeout(() => {
          setLoadingForm!==undefined && setLoadingForm(false)
          navigateCall !== undefined && navigateCall();
        }, 1000);
      }
    })
    .catch((err) => {
      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
      } else {
        setState({ ...state, message: err?.response?.data, error: true })
      }
      setLoadingForm!==undefined && setLoadingForm(false)
    });
};

//SavePurchaseOrderNew_ApiCall

export const SavePurchaseOrderNewApiCall = (
  body,
  setState,
  state,
  setshowpurchaseOrderConfirm,
  setsavePurchaseOrderDetails,
  sethideSaveBtn,
  setDisabledInput
) => {
  axios
    .post("purchase/requestForQuatation", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setState({
          ...state,
          message: "PurchaseOrder Saved !!",
          success: true,
        });
        setshowpurchaseOrderConfirm(true);
        setsavePurchaseOrderDetails(res.data);
        sethideSaveBtn(true);
        setDisabledInput(true);
         localStorage.setItem("singleViewById",res?.data?._id)
        console.log(res);
      }
    })
    .catch((err) => {
      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
      } else {
        setState({ ...state, message: err?.response?.data, error: true })
      }
    });
};

//updatePurchaseOrderNewApiCallinRFQ

export const updatePurchaseOrderNewApiCallinRFQ = (
  body,
  setState,
  state,
  setshowpurchaseOrderConfirm,
  setRfqThroughPoEdit,
  setDisabledInput,setLoadingForm
) => {
  setLoadingForm!==undefined && setLoadingForm(true)

  axios
    .put("purchase/updateRequestForQuotationById", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setLoadingForm!==undefined && setLoadingForm(false)

        setState({ ...state, message: "PurchaseOrder Updated", success: true });
        setshowpurchaseOrderConfirm(true);
        setRfqThroughPoEdit(false);
        setDisabledInput(true);
      }
    })
    .catch((err) => {
      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
      } else {
        setState({ ...state, message: err?.response?.data, error: true })
      }
      setLoadingForm!==undefined && setLoadingForm(false)

    });
};

// update PO

export const NewUpdatePOAPiCall = (
  body,
  setState,
  state,
  setEditClicked,
  setDisabledInput,
  setshowpurchaseOrderConfirm,
  setsavePurchaseOrderDetails,setLoadingForm
) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  axios
    .put("purchase/updateRequestForQuotationById", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setLoadingForm!==undefined && setLoadingForm(false)
        setState({ ...state, message: "PurchaseOrder Updated", success: true });
        setEditClicked(false);
        setDisabledInput(true);
        setshowpurchaseOrderConfirm(true);
        setsavePurchaseOrderDetails(res.data);
      }
    })
    .catch((err) => {
      setLoadingForm!==undefined && setLoadingForm(false)
      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
      } else {
        setState({ ...state, message: err?.response?.data, error: true })
      }
    });
};


