import axios from "axios";
import { get_job_completion_workorder_list } from "../../../../Redux/Settings/Utility/JobCompletion/jobCompletionWorkorderListSlice";
import { get_utility_job_completion_list } from "../../../../Redux/Settings/Utility/JobCompletion/utilityJobCompletionListSlice";
import store from "../../../../Redux/store";
import { HEADERS } from "../../../UrlAndPaths";


// get workorder no

export const getUtilityJobCompletionWorkOrderAPICall=async(body)=>{

    await axios.post('workorder/getWorkorderNosjobCompletionEdit',body,HEADERS)
            .then((res)=>{
                if(res.status===200){
                    store.dispatch(get_job_completion_workorder_list({
                        jobCompletionWorkorderData:res.data
                    }))
                }
            })
            .catch((err)=>{
                store.dispatch(get_job_completion_workorder_list({
                    jobCompletionWorkorderData:undefined
                }))
                console.error(err.response.status)
            })
}

// single workOrder items view
export const utilityJobCompletionWorkOrderListAPICall=async(body)=>{

    await axios.post('workorder/jobCompletionEditSingleView',body,HEADERS)
            .then((res)=>{
                if(res.status===200){
                    store.dispatch(get_utility_job_completion_list({
                        utilityJobCompletionData:res.data
                    }))
                }
            })
            .catch((err)=>{
                store.dispatch(get_utility_job_completion_list({
                    utilityJobCompletionData:undefined
                }))
                console.error(err.response.status)
            })
}

// edit single workorder

export const editSingleJobCompletionAPICall=async(body,
                                            updateListener,
                                            clearStates,
                                            successOpen,
                                            errorOpen,
                                            snackMsg
                                            )=>{
    await axios.post('workorder/editJobCompletion',body,HEADERS)
            .then((res)=>{
                if(res.status===200){
                    updateListener()
                    clearStates()
                    successOpen(true)
                    snackMsg("Edit succesfull ")
                }
            })
            .catch((err)=>{
                console.error(err.response.status);
                errorOpen(true)
                switch (err.status) {
                    case 401:
                        snackMsg("Unauthorized!!");
                      break;
                    case 409:
                        snackMsg("Conflict!!");
                      break;
                    case 404:
                        snackMsg("Edit unsuccessful!! Please try again..");
                      break;
                    case 500:
                        snackMsg("Server Error!!");
                      break;
                    case 422:
                        snackMsg("Quantity mismatch")
                      break;
                    default:
                        snackMsg('Can not edit !!')
                      break;
                  }

            })
}