import { createSlice } from "@reduxjs/toolkit";

export const salesReport = createSlice({
    name:"salesReport",
    initialState:{
        salesReportlist:undefined,
        salesReportSinglelist:undefined,
        salesReportBySalesPerson:undefined,
        salesReportBySalesSingleList:undefined,
        dailySalesReport:undefined,
        dailySalesReportSingleView:undefined,
    },

    reducers: {
        setSalesReport:(state,action)=>{
            state.salesReportlist=action.payload.salesReportData
        },
        setSalesReportSingle:(state,action)=>{
            state.salesReportSinglelist=action.payload.salesReportSingleData
        },
        salesReportBySalesPersonList:(state,action)=>{
            state.salesReportBySalesPerson=action.payload.salesReportBySalesPersonData
        },
        salesReportBySalesPersonSingleList:(state,action)=>{
            state.salesReportBySalesSingleList=action.payload.salesReportBySalesSingleListData
        },
        DailySalesReportList:(state,action)=>{
            state.dailySalesReport=action.payload.dailySalesReportData
        },
        DailySalesReportListSingleList:(state,action)=>{
            state.dailySalesReportSingleView=action.payload.dailySalesReportSingleListData
        },
    }
})

export const {DailySalesReportListSingleList,DailySalesReportList,setSalesReport,salesReportBySalesPersonSingleList, setSalesReportSingle,salesReportBySalesPersonList} = salesReport.actions;
export default salesReport.reducer;