import React, { useEffect } from 'react'
import "../../../../css/Settings/Utility/utilitySettings.css"
import Store from '../../../../Redux/store'
import { update_select_utility } from '../../../../Redux/Settings/Utility/utilityMenuSlice'
import store from '../../../../Redux/store'
import { get_shiftTransfer_dialog } from '../../../../Redux/Settings/Utility/shiftTransferReopenDialogSlice'
export const Utility = () => {

      const clickUtilitySettingsCorrections=()=>{
        document.getElementById("utilitySettingsCorrections").classList.add("focus");
        document.getElementById("utilitySettingsStockCorrections").classList.remove("focus");
        document.getElementById("utilitySettingsPayments").classList.remove("focus");
         document.getElementById("utilitySettingsJobCompletion").classList.remove("focus");
        document.getElementById("utilitySettingsForgotPassword").classList.remove("focus");
        document.getElementById("utilitySettingsShiftTransfer").classList.remove("focus");
        Store.dispatch(update_select_utility({selectUtility:'utilitySettingsCorrections'}))
    }
      const clickUtilitySettingsStockCorrections=()=>{
        document.getElementById("utilitySettingsStockCorrections").classList.add("focus");
        document.getElementById("utilitySettingsCorrections").classList.remove("focus");
        document.getElementById("utilitySettingsPayments").classList.remove("focus");
        document.getElementById("utilitySettingsShiftTransfer").classList.remove("focus");
         document.getElementById("utilitySettingsJobCompletion").classList.remove("focus");
        document.getElementById("utilitySettingsForgotPassword").classList.remove("focus");
        Store.dispatch(update_select_utility({selectUtility:'utilitySettingsStockCorrections'}))
        window.location = "#utility-stock-correction-container"
    }
      const clickUtilitySettingsPayment=()=>{
        document.getElementById("utilitySettingsPayments").classList.add("focus");
        document.getElementById("utilitySettingsStockCorrections").classList.remove("focus");
        document.getElementById("utilitySettingsCorrections").classList.remove("focus");
        document.getElementById("utilitySettingsShiftTransfer").classList.remove("focus");
         document.getElementById("utilitySettingsJobCompletion").classList.remove("focus");
        document.getElementById("utilitySettingsForgotPassword").classList.remove("focus");
        Store.dispatch(update_select_utility({selectUtility:'utilitySettingsPayments'}))
        window.location = "#utility-payment-cash-to-card"        
      }

      const clickUtilitySettingsShiftTransfer=()=>{
        document.getElementById("utilitySettingsShiftTransfer").classList.add("focus");
        document.getElementById("utilitySettingsPayments").classList.remove("focus");
        document.getElementById("utilitySettingsStockCorrections").classList.remove("focus");
        document.getElementById("utilitySettingsCorrections").classList.remove("focus");
         document.getElementById("utilitySettingsJobCompletion").classList.remove("focus");
        document.getElementById("utilitySettingsForgotPassword").classList.remove("focus");
        Store.dispatch(update_select_utility({selectUtility:'utilitySettingsShiftTransfer'}))
        Store.dispatch(get_shiftTransfer_dialog({shiftTransferDialogData:true}))
      }
      const clickUtilitySettingsForgotPassword=()=>{
        document.getElementById("utilitySettingsForgotPassword").classList.add("focus");
        document.getElementById("utilitySettingsShiftTransfer").classList.remove("focus");
        document.getElementById("utilitySettingsPayments").classList.remove("focus");
        document.getElementById("utilitySettingsStockCorrections").classList.remove("focus");
        document.getElementById("utilitySettingsCorrections").classList.remove("focus");
        document.getElementById("utilitySettingsJobCompletion").classList.remove("focus");
        Store.dispatch(update_select_utility({selectUtility:'utilitySettingsForgotPassword'}))
        Store.dispatch(get_shiftTransfer_dialog({shiftTransferDialogData:true}))
      }
      const clickUtilitySettingsJobCompletion=()=>{
        document.getElementById("utilitySettingsJobCompletion").classList.add("focus");
        document.getElementById("utilitySettingsShiftTransfer").classList.remove("focus");
        document.getElementById("utilitySettingsPayments").classList.remove("focus");
        document.getElementById("utilitySettingsStockCorrections").classList.remove("focus");
        document.getElementById("utilitySettingsCorrections").classList.remove("focus");
        document.getElementById("utilitySettingsForgotPassword").classList.remove("focus");
        Store.dispatch(update_select_utility({selectUtility:'utilitySettingsJobCompletion'}))
        Store.dispatch(get_shiftTransfer_dialog({shiftTransferDialogData:true}))
        window.location = "#utility-job-completion"
      }

  // useEffect(()=>{
  //   clickUtilitySettingsStockCorrections()
  // },[])
  return (
    <div className='utility-settings-container'>
      <div className="utility-settings-menu-container">
          <div
            style={{display:"none"}}
            id="utilitySettingsCorrections"
            onClick={clickUtilitySettingsCorrections}
            className="utility-settings-menu-list focus">
            <ul><li>Corrections</li></ul> 
          </div>
          <div
            style={{display:"none"}}
            id="utilitySettingsStockCorrections"
            onClick={clickUtilitySettingsStockCorrections}
            className="utility-settings-menu-list ">
            <ul><li>Stock Corrections</li></ul> 
          </div>
          <div
            id="utilitySettingsPayments"
            onClick={clickUtilitySettingsPayment}
            className="utility-settings-menu-list focus">
            <ul><li>Payment Type & Amount</li></ul> 
          </div>
          <div
            id="utilitySettingsShiftTransfer"
            onClick={clickUtilitySettingsShiftTransfer}
            className="utility-settings-menu-list ">
            <ul><li>Shift Transfer</li></ul> 
          </div>
          <div
            id="utilitySettingsForgotPassword"
            onClick={clickUtilitySettingsForgotPassword}
            className="utility-settings-menu-list ">
            <ul><li>Forgot Password Settings</li></ul> 
          </div>
          <div
            id="utilitySettingsJobCompletion"
            onClick={clickUtilitySettingsJobCompletion}
            className="utility-settings-menu-list ">
            <ul><li>Job Completion</li></ul> 
          </div>

      </div>

    </div>
  )
}
