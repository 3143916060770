import {MenuItem, Select } from '@mui/material';
import React, { useState } from 'react'
import "../../../../css/Settings/DeviceSetttings/deviceSettings.css";
export const DeviceSettingsSelectDevice = (props) => {
  
    const {deviceName,deviceName2,deviceHead}=props
    const [selected, setSelected] = useState('item1');
    const handleChange = event => {
    setSelected(event.target.value);
    };
    return(
    <div className="device-settings-container-right-section">
        <div className="device-settings-container-right-printer">
          <div className="global-single-input"> 
          <p>{deviceHead}</p>
          <Select value={selected} onChange={handleChange}>
            <MenuItem value="item1">{deviceName}</MenuItem>
            <MenuItem value="item2">{deviceName2}</MenuItem>
          </Select>
          </div>
        </div>
    </div>
        
    )
}