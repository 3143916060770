import { createSlice } from "@reduxjs/toolkit";

export const bankAccountSlice = createSlice({
  name: "bankAccountSlice",
  initialState: {
    bankAccountId  : "",
    accountNo: "",
    ifscCode: "",
    currency: "",
    region: "",
    street: "",
    city: "",
    branch: "",
    country: "",
    openingBalance:''
  },

  reducers: {
    setBankAccountId: (state, action) => {
      state.bankAccountId = action.payload;
    },
    setAccountNo: (state, action) => {
      state.accountNo = action.payload;
    },
    setIfscCode: (state, action) => {
      state.ifscCode = action.payload;
    },
    setCurrency: (state, action) => {
      state.currency = action.payload;
    },
    setRegion: (state, action) => {
      state.region = action.payload;
    },
    setStreet: (state, action) => {
      state.street = action.payload;
    },
    setCity: (state, action) => {
      state.city = action.payload;
    },
    setBranch: (state, action) => {
      state.branch = action.payload;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
    setOpeningBalance: (state, action) => {
      state.openingBalance = action.payload;
    },
  },
});

export const {setAccountNo,setBankAccountId,setBranch,setCity,setCountry,setCurrency,setIfscCode,setOpeningBalance,setRegion,setStreet} = bankAccountSlice.actions;
export default bankAccountSlice.reducer;
