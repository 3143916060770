import { createSlice } from "@reduxjs/toolkit";

export const viewCreditLimitSlice=createSlice({
    name:'creditLimitSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_view_credit_limit:(state,action)=>{
            state.value=action.payload.creditLimitData
        }
    }
})
export const {get_view_credit_limit} = viewCreditLimitSlice.actions;
export default viewCreditLimitSlice.reducer;