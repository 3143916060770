import axios from "axios"
import { get_discount, get_new_discount } from "../../../Redux/Settings/Discount/getDiscountSlice"
import store from "../../../Redux/store"
import { HEADERS } from "../../UrlAndPaths"

export const addDiscountAPICall= async(
    body,
    updateListner,
    setSuccessSnackbar,
    setErrorSnackbar,
    setSnackMsg,
    setDiscount
    )=>{
    await axios.post("settings/addDiscount",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            // console.log('Add discount');
            updateListner()
            setSuccessSnackbar(true)
            setSnackMsg('Success')
            setDiscount('')
        }
    })
    .catch((err)=>{
        setErrorSnackbar(true)
        if (err.response === undefined) {
            setSnackMsg("Network Connection error!!");
         }
          if (err.response.status === 409) {
            setSnackMsg("Already Exist");
          }
      
          if (err.response.status === 401) {
            setSnackMsg("Unauthorized");
          }
          if (err.response.status === 403) {
            setSnackMsg("Forbidden");
          }
        console.log(err.response);
    })
}

// get discount
export const getDiscountAPICall=async(body)=>{
  await axios.post("settings/getDiscount",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
        store.dispatch(get_discount({getDiscountData:res.data}))
    }
  })
  .catch((err)=>{
    store.dispatch(get_discount({getDiscountData:undefined}))
    console.log(err.response);
  })
}

export const getDiscountForEditAPICall=()=>{
  axios.post("settings/getDiscountforedit",{},HEADERS)
  .then((res)=>{
      if(res.status===200){
        store.dispatch(get_new_discount({getDiscountNewData:res.data}))
      }
  })
  .catch((err)=>{
    store.dispatch(get_new_discount({getDiscountNewData:undefined}))
  })
}