import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router";
import { InventoryAdjustmentFilterAPICall, deleteInventaryAdjustmentAPICall, viewInventoryAdjustmentAPICall } from './InventoryAdjustmentAPI';
import { useSelector } from 'react-redux';
import store from '../../../../../Redux/store';
import { get_inventory_adjustment_single_view } from './InventoryAdjustmentSlice';
import { IconButton, Skeleton } from '@mui/material';
import ErrorSnackbar from '../../../../Single Components/SnackBars/ErrorSnackbar';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import { convertDateFormat, getCurrentTime } from '../../../../../Js/Date';
import CustomPagination from '../../../../Single Components/CustomPagination';
import axios from 'axios';
import { Source } from '@mui/icons-material';
import { setFilterActive } from '../../../../Single Components/listSlice/filterCategorySlice';
export const InventoryAdjustment = () => {
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const inventoryAdjList = useSelector((state) => state.InventoryAdjustmentSlice.value)
  let navigate = useNavigate();
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
    alert: false
  });
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = inventoryAdjList?.pages;
  const [selectedBranch, setSelectedBranch] = useState(null);


  const createFn = () => {
    navigate("/userdashboard/inventory/operation/inventoryAdjustment/create")
  }

  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      alert: false,
      message: "",
    });
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };

  const handleClickRow = (row) => {
    store.dispatch( setFilterActive(window.location.pathname))
    // store.dispatch(get_inventory_adjustment_single_view({singleinventoryAdjustmentrData:row}))
    localStorage.setItem("singleViewById", row?._id)
    navigate("/userdashboard/inventory/operation/inventoryAdjustment/create")
  }

  const deleteJournalEntryClick = (id) => () => {
    deleteInventaryAdjustmentAPICall({ id })
  }
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };

  const deletePostedJournal = () => {
    setSnackBarStates({ ...snackBarStates, message: "posted inventory Adjustment entrie can't be deleted!! ", error: true })
  }
 
  useEffect(() => {
    // viewInventoryAdjustmentAPICall()
    store.dispatch(get_inventory_adjustment_single_view({ singleinventoryAdjustmentrData: undefined }))
    localStorage.removeItem("singleViewById")
  }, [])

  // useEffect(() => {
  //    InventoryAdjustmentFilterAPICall({
  //       // fromDate: `${FormDataInfo.fromDate} ${getCurrentTime()}`,
  //       // toDate: `${FormDataInfo.toDate} ${getCurrentTime()}`,
  //       // branchList: FormDataInfo.branchList,
  //       // createdBy: FormDataInfo.createdBy,
  //       // location: FormDataInfo.location,
  //       // status: FormDataInfo.status,
  //       // search: FormDataInfo.search,
  //       index: currentPage-1,
  //     }, setIsLoading);

  // }, [
  //    currentPage
  //   ]);
  
  useEffect(() => {
  
    if (FormDataInfo.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData && viewInventoryAdjustmentAPICall({
        branchList: FormDataInfo.branchList,
        locationId: FormDataInfo.location,
        search: FormDataInfo.search,
        index: currentPage - 1,
        status: FormDataInfo.status,
      }, setIsLoading);
    }
  
  }, [FormDataInfo, currentPage]);

  return (
    <div className='global-page-parent-container'>
      {/* table */}
      <div className='global-white-bg-container'>
        <div className="justify-space-between"
          style={{ gap: "0 12px", display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}
        >
            {FormDataInfo?.branchList?.length > 0 && (
              <div style={{ marginLeft: '1%' }}>
               <h3>Branch name: 
                  {selectedBranch?.storeCode||filtereDataOptions?.filterCategorylist?.branchList?.storeCode}
                  -{selectedBranch?.branchName||filtereDataOptions?.filterCategorylist?.branchList?.branchName}</h3>
              </div>
            )}
             <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap:'9px',
              width: FormDataInfo?.branchList?.length ? '72%' : '100%'
            }}>
          <CategorySearchandFilter
            statusOptions={['POSTED', 'DRAFT', 'INPROGRESS', 'CANCEL', 'REQUESTED', 'APPROVED']}
            onData={handleDataFromChild}
            isLocation={true}
            isDate={false}
            isStatus={true}
            onBranchSelect={handleBranchSelect}
          />
          <button onClick={createFn} className='create-button-blue'>Create</button>
          </div>
        </div>
        <div className='create-button-blue-container'>
          <h3>Inventory Adjustment</h3>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />
          {/* <button onClick={createFn} className='create-button-blue'>Create</button> */}
        </div>
        <div className='new-global-table-container'>
          <table>
            <thead>
              <tr>
                <th>Adjustment date</th>
                <th>Name</th>
                <th>Branch</th>
                <th>Location</th>
                <th>Created By</th>
                <th>Status</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={8}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : inventoryAdjList?.list !== undefined &&
                inventoryAdjList?.list?.length !== 0 ? (
                inventoryAdjList?.list
                  ?.slice(0)

                  ?.map((r, i) => (
                    <tr key={i} >
                      <td onClick={() => handleClickRow(r)}>{convertDateFormat(r?.adjustmentDate)}</td>
                      <td onClick={() => handleClickRow(r)}>{r?.name}</td>
                      <td onClick={() => handleClickRow(r)}>{r?.branchName}</td>
                      <td onClick={() => handleClickRow(r)}>{r?.locationName}</td>
                      <td onClick={() => handleClickRow(r)}>{r?.CREATEDBY}</td>
                      <td className="table-data-box">
                        {r?.status === "POSTED" ? (
                          <span className="post"> DONE</span>
                        ) : r?.status === "DRAFT" ? (
                          <span className="draft"> DRAFTED</span>
                        ) : r?.status === "INPROGRESS" ? (
                          <span className="inprogress"> IN PROGRESS</span>
                        ) : r?.status === "CANCEL" ? (
                          <span className="cancel"> CANCEL</span>
                        ) : r?.status === "REQUESTED" ? (
                          <span className="requested"> REQUESTED</span>
                        ) : r?.status === "APPROVED" ? (
                          <span className="granted"> GRANTED</span>
                        ) :
                          (
                            "---"
                          )}
                      </td>
                      <td>
                        <IconButton
                          onClick={r?.status !== "CANCEL" ? deletePostedJournal : deleteJournalEntryClick(r?._id)}
                        >
                          <i className={`bi bi-trash3 ${r?.status !== "CANCEL"
                              ? "edit-icon-disabled"
                              : "delete-icon"
                            }`}></i>
                        </IconButton>
                      </td>

                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan={6}>No Data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
    </div>
  )
}
