import {createSlice} from '@reduxjs/toolkit'

export const stockReportSlice=createSlice({
    name:'stockReportSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_stock_report:(state,action)=>{
            state.value=action.payload.stockReportData
        }
    }
})

export const {get_stock_report} = stockReportSlice.actions;
export default stockReportSlice.reducer