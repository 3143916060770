import axios from "axios";
import { HEADERS, HEADER_FORM_DATA } from "../../../../../API/UrlAndPaths";
import store from "../../../../../Redux/store";
import {
  landedCostName,
  saveData,
  listLc,
  serviceProduct,
  singleView
} from "./LandedCostSlice";

//get landed cost name
export const getLandedCostName = async (body) => {
  try {
    let response = await axios.post(
      "inventory/generateLandedCostName",
      body,
      HEADERS
    );
    if (response.status === 200) {
      store.dispatch(landedCostName(response.data));
    }
  } catch (error) {
    console.log("Error: ", error);
    throw new Error(error);
  }
};

// draft landed cost
export const createDraftLCApi = async (body,setLandedCostForm,setSuccessSnackOpen) => {
  try {
    let response = await axios.post("inventory/addLandedCost", body, HEADERS);
    if (response.status === 200) {
      store.dispatch(saveData(response.data));
      setLandedCostForm((prev)=>({...prev,isEdit:true,status:"DRAFT"}))
      setSuccessSnackOpen(true)
    }
  } catch (error) {
    console.log("Error: ", error);
    throw new Error(error);
  }
};

//list landed cost
export const listLCApi = async (body, setIsLoading) => {
  try {
    let response = await axios.post(
      "inventory/viewLandedCost",
      { branchId: body },
      HEADERS
    );
    if (response.status === 200) {
    
        store.dispatch(listLc(response.data));
      
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  } catch (error) {
    console.log("Error: ", error);
    throw new Error(error);
  }
};

//edit landed cost api

export const editLcAPI = async (body) => {
  try {
    let response = await axios.put(
      "inventory/editLandedCost",
       body ,
      HEADERS
    );
    if (response.status === 200) {
      store.dispatch(listLc(response.data));
    }
  } catch (error) {
    console.log("Error: ", error);
    throw new Error(error);
  }
};

// list service products
export const listServiceProductsApi = async () => {
  try {
    let response = await axios.post(
      `readymade/productSearchByType`,
      {
        prodType: "Service",
      },
      HEADERS
    );
    if (response.status === 200) {
      store.dispatch(serviceProduct(response.data));
    }
  } catch (error) {
    console.log("Error: ", error);
    throw new Error(error);
  }
};
// list single view by id
export const singleViewLdByIdApi = async (body) => {
  try {
    let response = await axios.post(
      `inventory/landedCostSingleView`,
      body,
      HEADERS
    );
    if (response.status === 200) {
      store.dispatch(singleView(response.data))
    }
  } catch (error) {
    console.log("Error: ", error);
    throw new Error(error);
  }
};


