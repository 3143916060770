import React, { useState } from "react";
import InputBox from "../../../../components/InputBox";
import DropdownInputBox from "../../../../components/DropdownInputBox";
import TableBox from "../../../../components/TableBox";
import { Dialog, IconButton, Skeleton } from "@mui/material";

const DepreciationVoucher = ({ selectedVoucher }) => {
  const date = new Date();
  const isAscending = false;
  const isLoading = false;

  const renderData = [
    {
      _id: 1,
      Product: "first",
      Percentage: "50%",
      ProductValue: 2000,
      PurchaseDate: date.toLocaleDateString(),
      DepreciatedAmount: 555,
      data: [
        {
          Product: "first",
          Percentage: "50%",
          ProductValue: 2000,
          PurchaseDate: date.toLocaleDateString(),
          DepreciatedAmount: 555,
        },
      ],
    },
    {
      _id: 2,
      Product: "Sample",
      Percentage: "50%",
      ProductValue: 2000,
      PurchaseDate: date.toLocaleDateString(),
      DepreciatedAmount: 555,
      data: [
        {
          Product: "first",
          Percentage: "50%",
          ProductValue: 2000,
          PurchaseDate: date.toLocaleDateString(),
          DepreciatedAmount: 555,
        },
      ],
    },
    {
      _id: 3,
      Product: "ghg",
      Percentage: "50%",
      ProductValue: 2000,
      PurchaseDate: date.toLocaleDateString(),
      DepreciatedAmount: 555,
      data: [
        {
          Product: "first",
          Percentage: "50%",
          ProductValue: 2000,
          PurchaseDate: date.toLocaleDateString(),
          DepreciatedAmount: 555,
        },
      ],
    },
    {
      _id: 4,
      Product: "last",
      Percentage: "50%",
      ProductValue: 2000,
      PurchaseDate: date.toLocaleDateString(),
      DepreciatedAmount: 555,
      data: [
        {
          Product: "first",
          Percentage: "50%",
          ProductValue: 2000,
          PurchaseDate: date.toLocaleDateString(),
          DepreciatedAmount: 555,
        },
      ],
    },
  ];

  const [depreciationViewDialog, setDepreciationViewDialog] = useState(false);
  const [editPercentage, setEditPercentage] = useState({});
  const [product, setProduct] = useState("");
  const [percentage, setPercentage] = useState("");
  const [productValue, setProductValue] = useState("");
  const [purchaseDate, setPurchaseDate] = useState("");
  const [depreciatedAmount, setDepreciatedAmount] = useState("");

  const viewSingleSupplier = (id) => {
    setDepreciationViewDialog(true);
    console.log(id);
  };

  const openEditSupplierDialog = (id, percentage) => {
    setEditPercentage((prevState) => ({
      ...prevState,
      [id]: true,
    }));
    setPercentage(percentage);
  };

  const deleteAlertMessage = (id) => {};

  const openSingleViewDialog = () => {};

  return (
    <div className="purchase-return-voucher">
      <div className="accounting-voucher-menu-sections">
        <h3>{selectedVoucher}</h3>
        <div style={{ display: "flex" }}>
          <InputBox label={"Invoice No"} />
          <InputBox label={"Date"} type={"date"} />
          <DropdownInputBox labelName={"DR"} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
          className="button-container"
        >
          <div></div>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              style={{
                background: "#fff",
                border: "1px solid black",
                color: "black",
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
            <button
              style={{
                background: "#0011f8",
                border: "none",
                color: "white",
              }}
              className="submit-btn"
            >
              Submit
            </button>
          </div>
          <div></div>
        </div>
      </div>
      <div className="accounting-voucher-menu-sections">
        <div className="global-table-container">
          <table className="global-table">
            <thead>
              <tr>
                <th>No</th>
                <th>Product</th>
                <th>Percentage</th>
                <th>Product Value</th>
                <th>Purchase Date</th>
                <th>Depreciated Amount</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  {[...Array(7)].map((_, index) => (
                    <td key={index}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  ))}
                </tr>
              ) : renderData.length !== 0 ? (
                renderData
                  ?.slice(0)
                  ?.reverse()
                  ?.map((r, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{r?.Product}</td>
                      <td>
                        <input
                          style={{
                            border: "none",
                            width: "15%",
                            background: "none",
                          }}
                          value={r?.Percentage}
                          disabled={!editPercentage[r?._id]}
                          onChange={(e) => {
                            // Update the percentage value in the renderData array
                            const updatedRenderData = [...renderData];
                            updatedRenderData[i].Percentage = e.target.value;
                            // Update the renderData state with the updated array
                            // setRenderData(updatedRenderData);
                          }}
                        />
                      </td>
                      <td>{r?.ProductValue}</td>
                      <td>{r?.PurchaseDate}</td>
                      <td>{r?.DepreciatedAmount}</td>
                      <td>
                        <IconButton
                          onMouseDown={() => viewSingleSupplier(r?._id)}
                          onClick={openSingleViewDialog}
                        >
                          <i className="bi bi-eye visibility-icon"></i>
                        </IconButton>

                        <IconButton
                          onClick={() =>
                            openEditSupplierDialog(r?._id, r?.Percentage)
                          }
                        >
                          <i className="bi bi-pencil-square edit-icon"></i>
                        </IconButton>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan={7}>No Records</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* depreciation view dialog */}
        <Dialog
          open={depreciationViewDialog}
          maxWidth="lg"
          onKeyDown={(e) =>
            e.key === "Escape" && setDepreciationViewDialog(false)
          }
        >
          <div className="addSupplierDialog">
            <h3>View Depreciation Dialog</h3>
            <hr />
            <div className="addSupplierDialog-input-container">
              {/* --------------- */}
              <table className="global-table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Product</th>
                    <th>Percentage</th>
                    <th>Product Value</th>
                    <th>Purchase Date</th>
                    <th>Depreciated Amount</th>
                  </tr>
                </thead>
                {isLoading ? (
                  <tbody>
                    <tr>
                      {["", "", ""].map(() => (
                        <td colSpan={10}>
                          {/* <Loader /> */}
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={40}
                          />
                        </td>
                      ))}
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {renderData.length !== 0 ? (
                      renderData
                        ?.slice(0)
                        ?.reverse()
                        ?.map((r, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{r?.Product}</td>
                            <td>{r?.Percentage}</td>
                            <td>{r?.ProductValue}</td>
                            <td>{r?.PurchaseDate}</td>
                            <td>{r?.DepreciatedAmount}</td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td colSpan={7}>No Records</td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
              {/* ----------------- */}
            </div>
          </div>
          <div className="add-supplier-button-container">
            <button
              onClick={() => setDepreciationViewDialog(false)}
              className="btn btn-secondary-outlined"
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              // onClick={editDialogSubmitBtnFn}
            >
              Submit
            </button>
          </div>
        </Dialog>

   
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
          className="depreciation-voucher-input"
        >
          <div style={{ display: "flex", gap: "5px",alignItems:'center' }}>
            <h3
              style={{
                flex: "0 0 110px",
                whiteSpace: "nowrap",
                margin: "0",
              }}
            >
              Grand total
            </h3>
            <InputBox />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
          className="button-container"
        >
          <div></div>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              style={{
                background: "#fff",
                border: "1px solid black",
                color: "black",
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
            <button
              style={{
                background: "#0011f8",
                border: "none",
                color: "white",
              }}
              className="submit-btn"
            >
              Submit
            </button>
          </div>
          <button
            style={{ background: "#f38a00", border: "none", color: "white" }}
            className="save-draft-btn"
          >
            Save Draft
          </button>
        </div>
      </div>
    </div>
  );
};

export default DepreciationVoucher;
