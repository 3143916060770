import React, { useState, useEffect } from "react";
import uploadIcon from "../../../../Assets/SVG/uploadIcon.svg";
import { IconButton, Tooltip } from "@mui/material";
import { viewCardManualAPICall } from "../../../../API/Settings/CardAndUpiType/cardManualAPI";
import { useSelector } from "react-redux";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import {
  addCardManualAPICall,
  editCardManualAPICall,
} from "../../../../API/Settings/CardAndUpiType/cardManualAPI";
import { addChequeManualAPICall, editChequeManualAPIcall, viewChequeManualAPICall } from "../../../../API/Settings/CardAndUpiType/ChequeManualAPI";

export const ChequeManual = () => {

  const chequeManual = useSelector((state) => state.chequeManualSlice.value);

  console.log("chequeManual--->",chequeManual)

  //Update Listener States
  const [isModify, setIsModify] = useState(false);
  // states

  //
//   const [cardName, setCardName] = useState("");
//   const [cardCommission, setCardCommission] = useState("");
  //

  const [BankName, setBankName] = useState("");
  console.log("BankName000",BankName)
  const [chequeCommission, setChequeCommission] = useState("");
  const [selectedImgName, setSelectedImgName] = useState("");
  console.log("selectedImgName--->",selectedImgName)
  const [imgUrl, setImgUrl] = useState();
  console.log("imgUrl",imgUrl)


  // edit states
  const [isEdit, setIsEdit] = useState(false);
  const [editedId, setEditedId] = useState();

  // snackbar states
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [snackMsg, setSnackMsg] = useState("Error!!");

  useEffect(() => {
    viewChequeManualAPICall();
  }, [isModify]);

  //  onChange functions
  const getCheckName = (e) => {
    setBankName(e.target.value);
  };
  const getChequeCommission = (e) => {
    setChequeCommission(e.target.value);
  };
  const getImgUrl = (e) => {
    setImgUrl(e.target.files[0]);
  };

  useEffect(() => {
    setSelectedImgName(imgUrl?.name);
  }, [imgUrl]);

  //*Update Listener
  const updateListener = () => {
    setIsModify(!isModify);
  };

  // snackbar functions
  const closeSuccessSnackbar = () => {
    setSuccessSnackbar(false);
  };
  const closeErrorSnackbar = () => {
    setErrorSnackbar(false);
  };

  const formData = new FormData();
  formData.append("bankName", BankName);
  formData.append("commission", chequeCommission);
  formData.append("file", imgUrl);

  const addChequeManualSubmitBtn = () => {
    addChequeManualAPICall(
      formData,
      updateListener,
      setSuccessSnackbar,
      setErrorSnackbar,
      setSnackMsg,
      clearState
    );
  };

  const clearState = () => {
    setBankName("");
    setChequeCommission("");
    setImgUrl();
    setSelectedImgName("");
  };

  // edit function
  const clickEditBtn = (id, bankname, commission, image) => {
    setIsEdit(true);
    setEditedId(id);
    setBankName(bankname);
    setChequeCommission(commission);
    setImgUrl(image);
    setSelectedImgName(image.split("_").pop());
  };

  const editFormData = new FormData();
  editFormData.append("bankName", BankName);
  editFormData.append("commission", chequeCommission);
  editFormData.append("_id", editedId);
  editFormData.append("file", imgUrl);

  const editUpdateBtnFn = () => {
    editChequeManualAPIcall(
      editFormData,
      updateListener,
      setSuccessSnackbar,
      setErrorSnackbar,
      setSnackMsg,
      clearState
    );
    setIsEdit(false);
  };

  return (
    <div className="card-manual-container">
      <div className="card-manual-top-container">
        <div className="global-single-input card-name-container">
          <p>Bank Name</p>
          <input type="text" value={BankName} onChange={getCheckName} />
        </div>
        <div className="global-single-input card-name-container">
          <p>Commission(%)</p>
          <input
            type="text"
            value={chequeCommission}
            onChange={getChequeCommission}
          />
        </div>
        <div className="global-single-input card-type-logo-container">
          <p>Logo</p>
          <div className="card-type-logo">
            <input type="text" value={selectedImgName} disabled />

            <input type="file" id="card-logo" onChange={getImgUrl} />
            <Tooltip title="Upload Image">
              <label htmlFor="card-logo">
                <img src={uploadIcon} alt="uploadIcon" />
              </label>
            </Tooltip>
          </div>
        </div>
      </div>
      <button
        onClick={isEdit === true ? editUpdateBtnFn : addChequeManualSubmitBtn}
        // className="btn btn-primary card-manual-button"
        className={
            BankName === "" || chequeCommission === "" || imgUrl === undefined
            ? "btn btn-primary-disabled card-manual-button"
            : "btn btn-primary card-manual-button"
        }
        disabled={
            BankName === "" || chequeCommission === "" || imgUrl === undefined
        }
        // disabled={clearState()}
      >
        {isEdit === true ? "Update" : "Submit"}
      </button>
      {chequeManual !== undefined ? (
        <div className="card-manual-table-container">
          <div className="global-table-container" style={{ maxHeight: "51vh" }}>
            <table className="global-table">
              <thead>
                <tr>
                  <th>Bank Name</th>
                  <th>Commission</th>
                  <th>Logo</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {chequeManual?.slice(0)?.reverse()?.map((r, i) => (
                  <tr>
                    <td>{r.bankName?.toUpperCase()}</td>
                    <td>{r.commission}</td>
                    <td>
                      <img
                        src={r.imageUrl}
                        style={{
                          height: 50,
                          width: 50,
                          objectFit: "fill",
                          marginTop: "8%",
                        }}
                      />
                    </td>
                    <td>
                      <IconButton
                        onClick={() =>
                          clickEditBtn(
                            r._id,
                            r.bankName,
                            r.commission,
                            r.imageUrl
                          )
                        }
                      >
                        <i class="bi bi-pencil-square edit-icon"></i>
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="card-manual-table-container">
          <h3 className="no-data">No Records</h3>
        </div>
      )}
      <SuccessSnackbar
        open={successSnackbar}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />

      <ErrorSnackbar
        open={errorSnackbar}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </div>
  );
};
