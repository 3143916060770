import React from "react";
import '../../../../../../css/Staff/HRM/Tab/empDashboard.css'
import { NavLink } from "react-router-dom";
export const EmpDashboardTotalCommission=()=>{
    
    // const ClickTotalCommissionGoback=()=>{
    //     navigateTo("");
    //   }
    return(
        <div className="global-table-container" style={{padding:'2%'}}>
            <div className="employee-dashboard-total-commission-container">
                <h3>Total Commission</h3>
                <NavLink to="/userdashboard/staff/hrm/manageEmployee/viewEmployee"><button className="btn btn-primary-outlined total-commission"><i class="bi bi-arrow-left-short"></i>Back To Dashboard</button></NavLink>
            </div>
            <table className="global-table">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Date</th>
                        <th>Order ID</th>
                        <th>Order Type</th>
                        <th>Material</th>
                        <th>Quantity</th>
                        <th>Amount</th>
                        <th>Commission</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>01</td>
                        <td>10-12-2022</td>
                        <td>OG21SC</td>
                        <td>Thob</td>
                        <td>Linen Cloth</td>
                        <td>5</td>
                        <td>1000</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>01</td>
                        <td>10-12-2022</td>
                        <td>OG21SC</td>
                        <td>Thob</td>
                        <td>Linen Cloth</td>
                        <td>5</td>
                        <td>1000</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>01</td>
                        <td>10-12-2022</td>
                        <td>OG21SC</td>
                        <td>Thob</td>
                        <td>Linen Cloth</td>
                        <td>5</td>
                        <td>1000</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>01</td>
                        <td>10-12-2022</td>
                        <td>OG21SC</td>
                        <td>Thob</td>
                        <td>Linen Cloth</td>
                        <td>5</td>
                        <td>1000</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>01</td>
                        <td>10-12-2022</td>
                        <td>OG21SC</td>
                        <td>Thob</td>
                        <td>Linen Cloth</td>
                        <td>5</td>
                        <td>1000</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>01</td>
                        <td>10-12-2022</td>
                        <td>OG21SC</td>
                        <td>Thob</td>
                        <td>Linen Cloth</td>
                        <td>5</td>
                        <td>1000</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>01</td>
                        <td>10-12-2022</td>
                        <td>OG21SC</td>
                        <td>Thob</td>
                        <td>Linen Cloth</td>
                        <td>5</td>
                        <td>1000</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>01</td>
                        <td>10-12-2022</td>
                        <td>OG21SC</td>
                        <td>Thob</td>
                        <td>Linen Cloth</td>
                        <td>5</td>
                        <td>1000</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>01</td>
                        <td>10-12-2022</td>
                        <td>OG21SC</td>
                        <td>Thob</td>
                        <td>Linen Cloth</td>
                        <td>5</td>
                        <td>1000</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>01</td>
                        <td>10-12-2022</td>
                        <td>OG21SC</td>
                        <td>Thob</td>
                        <td>Linen Cloth</td>
                        <td>5</td>
                        <td>1000</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>01</td>
                        <td>10-12-2022</td>
                        <td>OG21SC</td>
                        <td>Thob</td>
                        <td>Linen Cloth</td>
                        <td>5</td>
                        <td>1000</td>
                        <td>400</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}