import { Autocomplete, Dialog, IconButton, MenuItem, Select, TextField, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from "react-router";
import { useEffect } from 'react';
import { useRef } from 'react';
import logoImage from "../../../../../Assets/Images/Icon ionic-logo-buffer.png"
import { useDispatch, useSelector } from 'react-redux';
import { viewAllAllowedBranchesAPICall } from '../../../../../API/Settings/Company Settings/companySettingsAPI';
import { vieWareHouseLocationAPICall } from '../../Configuration/wareHouseLocationAPI';
import { addInventoryAdjustmentAPICall, cancelInventoryAdjAPICall, editInventoryAdjustmentAPICall, exportInvAdjAPICall, invAdjustmentImportAPICall, postInventoryAdjustmentApicall, requestInventoryAdjustmentApicall, startInventoryAdjuustmentAPICall, viewSingleInventoryAdjustmentAPICall } from './InventoryAdjustmentAPI';
import { categoryListAPICall } from '../../Configuration/Category/CategoryAPI';
import { getAllNewProductList } from '../../../Accounts/Vendor/API/vendorBillAPI';
import uploadFile from "../../../../../Assets/Images/Icon feather-upload.png";
import { getCurrentTime, today } from '../../../../../Js/Date';
import { filterObjFromList } from '../../../../../Js/generalFunctions';
import SuccessSnackbar from '../../../../Single Components/SnackBars/SuccessSnackbar';
import ErrorSnackbar from '../../../../Single Components/SnackBars/ErrorSnackbar';
import doneRibbon from "../../../../../Assets/statusRibbon/done.png";
import draftRibbon from "../../../../../Assets/statusRibbon/draft.png";
import cancelRibbon from "../../../../../Assets/statusRibbon/cancel.png";
import inprogressRibbon from "../../../../../Assets/statusRibbon/inprogress.png"
import requestedRibbon from "../../../../../Assets/statusRibbon/requested.png"
import grantedRibbon from "../../../../../Assets/statusRibbon/granted.png"
import importIcon from "../../../../../Assets/Images/import (1).png";
import exportIcon from "../../../../../Assets/Images/export.png";
import printIcon from "../../../../../Assets/Images/print.png"
import { LoadingForm } from '../../../../Single Components/LoadingForm';
import { get_wareHouse_location_list } from '../../Configuration/wareHouseLocationSlice';
import store from '../../../../../Redux/store';
import { set_Back_button_action, setFilterActive } from '../../../../Single Components/listSlice/filterCategorySlice';

export const CreateInventoryAdjustment = () => {
  let navigate = useNavigate();
  const containerRef = useRef(null);
  const decimalPosition = localStorage.getItem("decimalPosition");
  const userRole=useSelector((state)=>state.userRoleSlice.value)
  const singleViewById=localStorage.getItem("singleViewById")
  const editedData=useSelector((state)=>state.InventoryAdjustmentSlice.singleValue)
     // all allowed branches list
  const allowedBranchList = useSelector((state)=>state.allAllowedBranchesSlice.value)
   // login response
   const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  // ware house location
  const wareHouseLocationList=useSelector((state)=>state.wareHouseLocationSlice.value)
  // table list data
  const tableList=useSelector((state)=>state.InventoryAdjustmentSlice.tableValue)
  const CategoryList = useSelector((state) => state.categorySlice.value);
    // new produuct list
    const allNewProductList = useSelector(
      (state) => state.vendorBillSlice.NewProductList
    );

    const dispatch = useDispatch();
   const addRowData=[{value:"Add Header"},{value:"Add Line"}]
    const [log,setLog]=useState([])
    const [loadingForm,setLoadingForm]=useState(false)
    const [radio,setRadio]=useState(1)
    const [fileToExport,setFileToExport]=useState([])
    const [tableData, setTableData] = useState([
      // {type:'lines',id: Date.now(),productName:"",des:"",uom:"",spMargin:"",onHandQty:"",transferQty:""}
    ]);
    const [logView,setLogView]=useState(false)
    const inventoryAdjInitialState={
      branch:null,
      location:null,
      accountingDate:today,
      adjustmentDate:today,
      reference:"",
      categoryName:null,
      product:null,
      isEdit:false,
      status:"",
      inProgressEdited:false,
      realQty:0
    
    }
    console.log(tableData);
   const [inventoryAdjForm,setInventoryAdjForm]=useState(inventoryAdjInitialState) 
   const [startClicked,setStartClicked]=useState(false)
   const [importClicked,setImportClicked]=useState(false)
   const [validationAlert, setValidationAlert] = useState({});
   const [editDataId,setEditDataId]=useState("")
   const [editClicked, setIsEditClicked] = useState(false);
   const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
    alert: false
  });

  const [actionsSelected,setActionsSelected]=useState("0")
  const [importPopup,setImportPopup]=useState(false)

  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      alert: false,
      message: "",
    });
  };

    const radioChange=(e)=>{
        setRadio(Number(e.target.value))
    }
  const backButtonFn = () => {
    if (inventoryAdjForm?.isEdit === false || inventoryAdjForm?.inProgressEdited===false) {
      const userConfirmed = window.confirm("Changes you made may not be saved.");

      if (userConfirmed) {
        navigate("/userdashboard/inventory/operation/inventoryAdjustment")
        dispatch(set_Back_button_action({ backButtonClickAction: true }));
      }
    }
    else {
      navigate("/userdashboard/inventory/operation/inventoryAdjustment")
      dispatch(set_Back_button_action({ backButtonClickAction: true }));
    }

   }

  const changeImage = (e) => {
    setFileToExport([...fileToExport, e.target.files[0]]);
    setValidationAlert({})
  };

 const submitExportClick=()=>{
  let newFormData=new FormData()

  newFormData.append("_id",singleViewById)
  newFormData.append("file",fileToExport[0])

  if(fileToExport.length===0){
    setValidationAlert({...validationAlert,fileExport:"select a file !!"})
  }
  else{
    exportInvAdjAPICall(newFormData,setSnackBarStates,snackBarStates,closeImportPopup,setStartClicked,setLoadingForm,setImportClicked)
  }
 }

  const handleInputChange = (id, field) =>(e,newValue)=> {
    const {value}=e.target
    console.log(value,'ooooooooooo');
    const updatedData = tableData.map((row,index) => {
      if (index === id) {
        return { ...row, [field]:value?Number(value):""};
      }
      return row;
    });

    setTableData(updatedData);
  };
  
  const handleDelete=(index)=>{
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  }

  const clickLogView=()=>{
    setLogView(!logView)
  }
  const getInventoryAdj=(field)=>(e,newValue)=>{
    const {value}=e.target
    // setInventoryAdjForm({...inventoryAdjForm,[field]:value!==0?value:newValue})
    if(field ==="branch"){
      setInventoryAdjForm({...inventoryAdjForm,branch:newValue})
      // setInventoryAdjForm({...inventoryAdjForm,location:null})
    }
    if(field==="location"){
      setInventoryAdjForm({...inventoryAdjForm,location:newValue})
    }
    if(field==="adjustmentDate"){
      setInventoryAdjForm({...inventoryAdjForm,adjustmentDate:value})
     }
     if(field==="accountingDate"){
      setInventoryAdjForm({...inventoryAdjForm,accountingDate:value})
     }
     if(field==="reference"){
      setInventoryAdjForm({...inventoryAdjForm,reference:value})
     }
     if(field==="categoryName"){
      setInventoryAdjForm({...inventoryAdjForm,categoryName:newValue})
     }
     if(field==="product"){
      setInventoryAdjForm({...inventoryAdjForm,product:newValue})
     }

      setValidationAlert({})
  }

 
  useEffect(()=>{
    setInventoryAdjForm({...inventoryAdjForm,location:null,categoryName:null,product:null})
  },[inventoryAdjForm?.branch])

  const bodyToStartFn={
    locationId:inventoryAdjForm?.location?._id,
    branchId:inventoryAdjForm?.branch?._id,
    mainCatgeoryId:inventoryAdjForm?.categoryName?._id,
    _id:inventoryAdjForm?.product?._id,
    invAdjId:singleViewById
  }
  const startButtonFunction=()=>{
    if(inventoryAdjForm?.branch===null){
      setValidationAlert({...validationAlert,branch:"Select a branch"})
    }
    else if(inventoryAdjForm?.location===null){
      setValidationAlert({...validationAlert,location:"Select a location"})
    }
    else{
      startInventoryAdjuustmentAPICall
      (bodyToStartFn,
      setInventoryAdjForm,
      inventoryAdjForm,setSnackBarStates,snackBarStates,setStartClicked,setLoadingForm)
    }
    
  }


  // on change funciton of aciton
  const actionsOnChange=(e)=>{
    const{value}=e.target
    setActionsSelected(value)
    if(value==="export"){
      if(inventoryAdjForm?.inProgressEdited){
        invAdjustmentImportAPICall({id:singleViewById},setSnackBarStates,snackBarStates,closeImportPopup,setLoadingForm)
      }
      else{
        setActionsSelected("print")
        setSnackBarStates({...snackBarStates,message:"you need to save before exporting !! ",error:true})
      }
    }
    else if(value==="import"){
      setImportPopup(true)
    }
  }
  const closeImportPopup=(e)=>{
    setActionsSelected("0")
    setImportPopup(false)
    setFileToExport([])
  }
  const cancelButtonFunction=()=>{
    let draftFn=()=>{
      setInventoryAdjForm({...inventoryAdjForm,status:"CANCEL"})
    }
    cancelInventoryAdjAPICall({id:singleViewById},draftFn,setSnackBarStates,snackBarStates,setLoadingForm)

  }
 
   const formData=new FormData()
   formData.append("branchId",inventoryAdjForm?.branch?._id)
   formData.append("locationId",inventoryAdjForm?.location?._id)
   formData.append("adjustmentDate",`${inventoryAdjForm?.adjustmentDate} ${getCurrentTime()}`)
   formData.append("accountingDate",`${inventoryAdjForm?.accountingDate} ${getCurrentTime()}`)
  //  formData.append("productInfo",JSON.stringify(tableData))
   formData.append("postDate",`${today} ${getCurrentTime()}`)
   formData.append("reference",inventoryAdjForm?.reference)

// initial save api call
  const SaveFunction=()=>{
    dispatch( setFilterActive(false))
    let draftFn=()=>{
      setInventoryAdjForm({...inventoryAdjForm,isEdit:true,status:"DRAFT",})
    }
    if(inventoryAdjForm?.branch===null){
      setValidationAlert({...validationAlert,branch:"select this field"})
    }
    else if(inventoryAdjForm?.location===null){
      setValidationAlert({...validationAlert,location:"select this field"})
    }
    else{
      addInventoryAdjustmentAPICall(formData,draftFn,setSnackBarStates,snackBarStates,setEditDataId,setLoadingForm)
    }
  }
  const saveBeforeValidate=()=>{
    
    let draftFn=()=>{
      setInventoryAdjForm({...inventoryAdjForm,inProgressEdited:true})
      setIsEditClicked(false)
    }
    formData.append("_id",singleViewById)
    formData.append("mainCatgeoryId",inventoryAdjForm?.categoryName?._id)
    formData.append("productId",inventoryAdjForm?.product?._id)
    formData.append("type",radio)
    formData.append("productInfo",JSON.stringify(tableData))
    formData.append("postDate",`${today} ${getCurrentTime()}`)
    
    editInventoryAdjustmentAPICall(formData,draftFn,setSnackBarStates,snackBarStates)
  }
  const postFormData=new FormData()
  postFormData.append("_id",editDataId)
  postFormData.append("productInfo",JSON.stringify(tableData))
  postFormData.append("postDate",`${today} ${getCurrentTime()}`)
 
//  post adj
  const validateFunction=()=>{
    let postFn=()=>{
      setInventoryAdjForm({...inventoryAdjForm,isEdit:true,status:"POSTED"})
    }
    if(inventoryAdjForm?.inProgressEdited){
      postInventoryAdjustmentApicall(postFormData,postFn,setSnackBarStates,snackBarStates,setLoadingForm)
    }
    else{
      setSnackBarStates({...snackBarStates,message:"you need to save before validating !! ",error:true})
    }
  }
  const requestFunction=()=>{
    let reqBody={
      id: editDataId,
      statusType: 1,
      postDate: `${today} ${getCurrentTime()}`
  }
    let postFn=()=>{
      setInventoryAdjForm({...inventoryAdjForm,isEdit:true,status:"REQUESTED"})
    }
    requestInventoryAdjustmentApicall(reqBody,postFn,setSnackBarStates,snackBarStates,setLoadingForm)
  }
  const approveFunction=()=>{
    let reqBody={
      id: editDataId,
      statusType: 2,
      postDate: `${today} ${getCurrentTime()}`
  }
    let postFn=()=>{
      setInventoryAdjForm({...inventoryAdjForm,isEdit:true,status:"APPROVED"})
    }
    requestInventoryAdjustmentApicall(reqBody,postFn,setSnackBarStates,snackBarStates,setLoadingForm)
  }

  const editBtnEnableFn=()=>{
    setInventoryAdjForm({...inventoryAdjForm,isEdit:false})
    setIsEditClicked(true)
  }
  const editBtnInProgressEnableFn=()=>{
    setInventoryAdjForm({...inventoryAdjForm,inProgressEdited:false})
    setIsEditClicked(true)
  }
  // edit inv adj
  const editSaveFn=()=>{
    formData.append("_id",editDataId)
    let editFn=()=>{
      setIsEditClicked(false)
      setInventoryAdjForm({...inventoryAdjForm,isEdit:true,status:"DRAFT"})
    }
    editInventoryAdjustmentAPICall(formData,editFn,setSnackBarStates,snackBarStates,setLoadingForm)
  }
  
 
  useEffect(()=>{
    if(editedData !== undefined){
      setEditDataId(editedData?._id)
      setInventoryAdjForm({
        ...inventoryAdjForm,
        isEdit:true,
        status:editedData?.status,
        branch:filterObjFromList("_id",allowedBranchList,"branchId",editedData),
        adjustmentDate:editedData?.adjustmentDate,
        accountingDate:editedData?.accountingDate,
        reference:editedData?.reference,
        inProgressEdited:true
      })
      setTableData(editedData?.productInfo || [])
      setLog(editedData?.log || [])
      setRadio(editedData?.type || 1)
    }
    else{
      setTableData([])
      setLog([])
    }
  },[editedData,allowedBranchList])

  useEffect(()=>{
    editedData!==undefined && 
      setInventoryAdjForm({
        ...inventoryAdjForm,
        location: filterObjFromList("_id", wareHouseLocationList, "locationId", editedData)
      })
  },[wareHouseLocationList])
 
  useEffect(() => {
    viewAllAllowedBranchesAPICall({ employeeId: loginResponse?.data?._id })
    categoryListAPICall();
    // getAllNewProductList();
    if (singleViewById !== null ) {
      viewSingleInventoryAdjustmentAPICall({ id: singleViewById })
    }
  }, [])

  // default select barnch
  useEffect(() => {
    let data = allowedBranchList?.filter((obj) => {
      if (obj._id === loginResponse?.branchPk) {
        return obj
      }
    })
    editedData===undefined && setInventoryAdjForm({ ...inventoryAdjForm, branch: data !== undefined && data[0] || null})
  }, [allowedBranchList])

  useEffect(() => {
    if (inventoryAdjForm?.branch !== null) {
      vieWareHouseLocationAPICall({ branchId: inventoryAdjForm?.branch?._id })
    }
    else{
      store.dispatch(get_wareHouse_location_list({wareHouseLocationData:undefined}))
    }
  }, [inventoryAdjForm?.branch])



  useEffect(() => {

    if(startClicked){
      let locationAddedTable=tableList?.map((r) => {
        return {
          ...r,
          location: inventoryAdjForm?.location?.name,
          realQty:(importClicked?Number(r?.realQty) : Number(r?.stock)) || 0,
        }
      })

    setTableData(locationAddedTable)
    
    
    formData.append("_id",singleViewById)
    formData.append("type",radio)
    formData.append("productInfo",JSON.stringify(locationAddedTable))
    formData.append("mainCatgeoryId",inventoryAdjForm?.categoryName?._id)
    formData.append("productId",inventoryAdjForm?.product?._id)

    editInventoryAdjustmentAPICall(formData)
    setStartClicked(false)
    setImportClicked(false)
    }
  }, [tableList,startClicked,importClicked])
  useEffect(()=>{
    editedData!==undefined &&
      setInventoryAdjForm({...inventoryAdjForm,product:filterObjFromList("_id",allNewProductList,"productId",editedData) || null,
                                          categoryName:filterObjFromList("_id",CategoryList,"mainCategoryId",editedData)
                          })
  },[radio,allNewProductList,CategoryList])

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const shouldShowAlert = !(inventoryAdjForm?.isEdit && inventoryAdjForm?.inProgressEdited);
      if (shouldShowAlert) {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [inventoryAdjForm?.isEdit,inventoryAdjForm?.inProgressEdited]);

  return (
     <div className='global-page-parent-container'>
       <div className="new-global-white-bg-container" style={{padding:"0 2%"}}>
         <div className='new-global-white-bg-icon-container'>
          <div className='new-global-white-bg-icon-container-left' style={{display:"flex",alignItems:"center",width:"92%",gap:"5px",justifyContent:"space-between"}} >
            <div  style={{display:"flex",alignItems:"center"}}>
            <Tooltip title="Back">
                <IconButton  onClick={backButtonFn}>
                    <i class="bi bi-chevron-left"></i>
                </IconButton>
            </Tooltip>
            {
               inventoryAdjForm?.status === "DRAFT" &&
               <Tooltip title="Edit">
               <IconButton 
                 onClick={inventoryAdjForm?.isEdit === true ? editBtnEnableFn : editSaveFn }
               >
                   {
                    inventoryAdjForm?.isEdit === true ?
                    <i class="bi bi-pencil-square edit-icon1"></i>
                    :
                    <i class="bi bi-check2-square edit-icon" style={{fontSize:"1.5rem"}}></i>
                   }
                
               </IconButton>
             </Tooltip>
            } 
            
            {
               inventoryAdjForm?.status === "" &&
               <Tooltip title="Save">
                <IconButton onClick={SaveFunction}>
                 <i class="bi bi-floppy save-icon"></i>
               </IconButton>
              </Tooltip>
            } 

            {
               inventoryAdjForm?.status === "INPROGRESS" &&
                <Tooltip title="Edit">
                  <IconButton
                    onClick={inventoryAdjForm?.inProgressEdited === true ? editBtnInProgressEnableFn : saveBeforeValidate}
                  >
                    {
                      inventoryAdjForm?.inProgressEdited === true ?
                        <i class="bi bi-pencil-square edit-icon1"></i>
                        :
                        <i class="bi bi-check2-square edit-icon" style={{ fontSize: "1.5rem" }}></i>
                    }

                  </IconButton>
                </Tooltip>
    
            } 
            {
            inventoryAdjForm?.status === "DRAFT" && !editClicked &&
            <button  onClick={startButtonFunction} className='gray-status' style={{width:"100px",cursor:"pointer",backgroundColor:"#1100ff",color:"#fff"}}>Start</button>
            }
            {  inventoryAdjForm?.status === "INPROGRESS" && !editClicked &&
            // <button onClick={validateFunction}  className='gray-status' style={{width:"100px",cursor:"pointer",backgroundColor:"#1100ff",color:"#fff"}}>Validate</button> 
            <button onClick={requestFunction}  className='gray-status' style={{width:"100px",cursor:"pointer",backgroundColor:"#1100ff",color:"#fff"}}>Request</button> 
            } 
            {  inventoryAdjForm?.status === "APPROVED" && !editClicked &&
            <button onClick={validateFunction}  className='gray-status' style={{width:"100px",cursor:"pointer",backgroundColor:"#1100ff",color:"#fff"}}>Validate</button> 
            // <button onClick={requestFunction}  className='gray-status' style={{width:"100px",cursor:"pointer",backgroundColor:"#1100ff",color:"#fff"}}>Request</button> 
            } 
            {userRole==="admin" && inventoryAdjForm?.status === "REQUESTED" && !editClicked &&
            <>
            <button style={{ cursor: "pointer", width: "100px",backgroundColor:"#bf0d0d",color:"#fff" }} onClick={cancelButtonFunction} className='gray-status' >Reject</button>
            <button onClick={approveFunction}  className='gray-status' style={{width:"100px",cursor:"pointer",backgroundColor:"rgb(65 187 0)",color:"#fff"}}>Approve</button> 
            </>
            } 
            {
              (inventoryAdjForm?.status === "DRAFT" || inventoryAdjForm?.status === "INPROGRESS") && !editClicked &&
              <button style={{ cursor: "pointer", width: "100px" }} onClick={cancelButtonFunction} className='gray-status' >Cancel</button>
            }
            </div>

            {
              inventoryAdjForm?.status !== "" &&
            <div
            className='actions-select-dropdown'>
              <Select
                value={actionsSelected}
                onChange={actionsOnChange}
                sx={{width:"100%",
                    height:"40px",
                    backgroundColor:"rgb(155 199 234)",
                    color:"#fff",
                    border:"none"
                    }}
              >
                <MenuItem
                sx={{gap:"10px",alignItems:"center"}}
                value="0">Actions</MenuItem>
                {
                  inventoryAdjForm?.status === "INPROGRESS" &&   
                <MenuItem
                sx={{gap:"10px",alignItems:"center"}}
                value="import"><img 
                style={{width:"25px"}}
                src={importIcon} alt="" /> 
               <p style={{margin:"0"}}>Import</p>
                </MenuItem>}
                {  inventoryAdjForm?.status === "INPROGRESS" && <MenuItem 
                sx={{gap:"10px",alignItems:"center"}}
                value="export"><img 
                style={{width:"25px"}}
                src={exportIcon} alt="" />
                <p style={{margin:"0"}}>
                Export
                </p>
                </MenuItem>}
                <MenuItem
                sx={{gap:"10px",alignItems:"center"}}
                value="print"><img
                style={{width:"25px"}}
                src={printIcon} alt="" />
                <p style={{margin:"0"}}>
                Print
                </p>
                </MenuItem>
              </Select>
            </div>}
          </div>
         
            <div className='status-container'>
           

            {inventoryAdjForm?.status==="DRAFT" && <img src={draftRibbon} alt="" />}
             {inventoryAdjForm?.status==="INPROGRESS" && <img src={inprogressRibbon} alt="" />}
            {inventoryAdjForm?.status === "POSTED" && <img src={doneRibbon} alt="" />} 
            {inventoryAdjForm?.status === "CANCEL" && <img src={cancelRibbon} alt="" />} 
            {inventoryAdjForm?.status === "REQUESTED" && <img src={requestedRibbon} alt="" />} 
            {inventoryAdjForm?.status === "APPROVED" && <img src={grantedRibbon} alt="" />} 
            </div>
         </div>
         <hr className='global-hr'/>
         <h4 className='global-name'>{editedData?.name}</h4>
      
          <div style={{display:"flex",justifyContent:"space-between"}}>
            <div style={{width:"45%",display:"flex",flexWrap:"wrap",justifyContent:"space-between"}}>
              <div className='new-global-single-input auto-complete-new' style={{width:"45%"}}>
                  <Autocomplete
                     disablePortal
                    id="combo-box-demo"
                    options={allowedBranchList||[]}
                    getOptionLabel={(option) => `${option?.storeCode}-${option?.branchName}`}
                    renderInput={(params)=>(
                      <TextField {...params} label="Branch*" focused/>
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option._id}>
                        <span>{`${option?.storeCode}-${option?.branchName}`}</span>
                      </li>
                    )}
                    value={inventoryAdjForm?.branch}
                    onChange={getInventoryAdj("branch")}
                    disabled={inventoryAdjForm?.isEdit || inventoryAdjForm?.status==="INPROGRESS"}
                  />
                <p className="doc-validation-alert">{validationAlert?.branch}</p>

              </div>
              <div className='new-global-single-input auto-complete-new' style={{width:"45%"}}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={wareHouseLocationList||[]}
                    getOptionLabel={(option) => option?.name}
                    renderInput={(params)=>(
                      <TextField {...params} label="Location*" focused/>
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option._id}>
                        <span>{option.name}</span>
                      </li>
                    )}
                    value={inventoryAdjForm?.location}
                    onChange={getInventoryAdj("location")}
                    disabled={inventoryAdjForm?.isEdit || inventoryAdjForm?.status==="INPROGRESS"}
                  />
                <p className="doc-validation-alert">{validationAlert?.location}</p>

              </div>
              <div className='new-global-single-input' style={{width:"45%"}}>
            <TextField 
              id="outlined-basic" 
              label="Adjustment Date*" 
              variant="outlined" 
              type="date" focused 
              value={inventoryAdjForm?.adjustmentDate}
              onChange={getInventoryAdj("adjustmentDate")}
              disabled={inventoryAdjForm?.isEdit || inventoryAdjForm?.status==="INPROGRESS"}
              
              />
          </div>
          <div className='new-global-single-input' style={{width:"45%"}}>
            <TextField 
              id="outlined-basic" 
              label="Accounting Date" 
              variant="outlined" 
              type="date" focused 
              value={inventoryAdjForm?.accountingDate}
              onChange={getInventoryAdj("accountingDate")}
              disabled={inventoryAdjForm?.isEdit || inventoryAdjForm?.status==="INPROGRESS"}
              
              />
          </div>
          <div className='new-global-single-input' style={{width:"45%"}}>
            <TextField 
              id="outlined-basic" 
              label="Reference" 
              variant="outlined" 
              type="text" focused 
              value={inventoryAdjForm?.reference}
              onChange={getInventoryAdj("reference")}
              disabled={inventoryAdjForm?.isEdit || inventoryAdjForm?.status==="INPROGRESS"}
              
              />
          </div>
            </div>
            <div style={{width:"65%"}}>
            <div style={{margin:"1% 1% 1% 3%",width:"94%"}}>
               <p style={{fontSize:"0.8rem",fontWeight:600,margin:"0 0 8px 0"}}>Adjustment Of</p>
               <div style={{display:"flex",justifyContent:"space-between"}}>
                 <div className='customer-invoice-radio-single-container' style={{marginRight:"2%",width:"25%"}}>
                    <input 
                    type="radio" 
                    id="invoiceAdjAllProd" 
                    name="invoiceAdj" 
                    checked={radio===1}
                    value={1}
                    disabled={inventoryAdjForm?.isEdit || inventoryAdjForm?.status==="INPROGRESS"}
                    onChange={radioChange}
                    />
                    <label htmlFor="invoiceAdjAllProd">All Products</label>
                 </div>
                   <div className='customer-invoice-radio-single-container' style={{marginRight:"2%"}}>
                      <input 
                      type="radio" 
                      id='invoiceAdjcat'  
                      name="invoiceAdj"
                      checked={radio===2}
                      disabled={inventoryAdjForm?.isEdit || inventoryAdjForm?.status==="INPROGRESS"}
                      value={2}
                      onChange={radioChange}
                      />
                      <label htmlFor="invoiceAdjcat">One Product Category</label>
                   </div>

                 <div className='customer-invoice-radio-single-container' style={{marginRight:"2%"}}>
                    <input 
                    type="radio" 
                    id="invoiceAdjProd" 
                    name="invoiceAdj"
                    value={3}
                    checked={radio===3}
                    disabled={inventoryAdjForm?.isEdit || inventoryAdjForm?.status==="INPROGRESS"}
                    onChange={radioChange}
                    />
                    <label htmlFor="invoiceAdjProd">One Product</label>
                 </div>
               </div>
               <div style={{display:"flex",marginTop:"2%",justifyContent:"space-between"}}>
                 <div className='global-single-input' style={{width:"25%",marginRight:"2%"}}>
         
                 </div>
                 {
                    radio===2 ?
                    <div className='new-global-single-input auto-complete-new' style={{width:"35%"}}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={CategoryList||[]}
                    getOptionLabel={(option) => option?.categoryName}
                    renderInput={(params)=>(
                      <TextField {...params} label="Select Category" focused />
                    )}
                    value={inventoryAdjForm?.categoryName}
                    onChange={getInventoryAdj("categoryName")}
                    renderOption={(props, option) => (
                      <li {...props} key={option._id}>
                        <span>{option.categoryName}</span>
                      </li>
                    )}
                    disabled={inventoryAdjForm?.isEdit || inventoryAdjForm?.status==="INPROGRESS"}

                  />
                <p className="doc-validation-alert"></p>

              </div>
                 :
                 <div className='new-global-single-input auto-complete-new' style={{width:"30%",marginRight:"2%"}}></div>
                 }
                 {
                   radio===3 ?
                   <div className='new-global-single-input auto-complete-new' style={{width:"35%"}}>
                   <Autocomplete
                     disablePortal
                     id="combo-box-demo"
                     options={allNewProductList||[]}
                     getOptionLabel={(option) => option?.productName}
                     renderInput={(params)=>(
                       <TextField {...params} label="Select Product*" focused/>
                     )}
                     renderOption={(props, option) => (
                      <li {...props} key={option._id}>
                        <span>{option.productName}</span>
                      </li>
                    )}
                     value={inventoryAdjForm?.product}
                     onChange={getInventoryAdj("product")}
                    disabled={inventoryAdjForm?.isEdit || inventoryAdjForm?.status==="INPROGRESS"}

                   />
                 <p className="doc-validation-alert"></p>
 
               </div>
                :
                <div className='new-global-single-input auto-complete-new' style={{width:"30%",marginRight:"2%"}}></div>
                 }
               
              

               </div>

             </div>
            </div>
          </div>
       </div>
       {
       tableData!==undefined && tableData?.length!==0 && 
        <div className="new-global-white-bg-container">
        <>
        <div className='global-product-table' style={{maxHeight:"45vh"}}>
           <table>
              <thead>
                 <tr>
                   <th>Product</th>
                   <th>UOM</th>
                   <th>Location</th>
                   <th>On Hand Qty</th>
                   <th>Real Qty</th>
                   <th style={{backgroundColor:"#fff"}}></th>
                 </tr>
              </thead>
         
              <tbody>
              {tableData?.map((item, index) => (
       <tr key={index}>
         {item.type === 'Header' ? (
           <>
           <td colSpan="5">
                     {/* <input type="text"
                          value={item.value}
                          onChange={(e) => handleInputChange(e, item.id, 'value')}
                         /> */}
           </td>
           <td  style={{border:"none",width:"4%"}}>
           <IconButton onClick={() => handleDelete(index)} style={{height:"30px"}}>
             <i className="bi bi-trash3" style={{color:"#db0000",fontSize:"1.3rem"}}></i>
           </IconButton>
           </td>
           </>
         ) : (
             <>
                <td>
                         <div className='product-table-auto-complete' >
                         {/* <Autocomplete
                         
                   
                        options= {addRowData||[]}
                        style={{ maxHeight: '100px' }}
                        getOptionLabel={(option) => option?.value}
                        renderInput={(params) => <TextField {...params} />}
                       value={item.product}
                        onChange={handleInputChange(item.id, 'product')}
                       /> */}
                           <input 
                             type="text"
                             value={item?.productName}
                             disabled
                             />
                         </div>
                        
                        
                     
                       </td>
                       <td>
                         <input type="text"
                          value={item.uomName}
                          disabled
                         />
                       </td>
                       <td>
                       <input type="text"
                         value={item?.location}
                         disabled
                       />
                       </td>
                     
                     
                       <td>
                       <input type="text"
                         value={Number(item.stock)?.toFixed(decimalPosition)}
                         disabled
                       />
                       </td>
                       <td>
                       <input type="number" 
                         value={item.realQty}
                         onChange={handleInputChange(index,'realQty')}
                         disabled={inventoryAdjForm?.inProgressEdited}
                       />
                       </td>
                       {/* <td  style={{border:"none",width:"4%"}}>
                         <IconButton onClick={() => handleDelete(index)} style={{height:"30px"}}>
                           <i className="bi bi-trash3" style={{color:"#db0000",fontSize:"1.3rem"}}></i>
                        </IconButton>
                      </td> */}
                       </>
           
          
         )}
       </tr>
     ))}
              </tbody>
           </table>
        
        </div>
        {/* <div className='add-row-btn-container'>
            <div className='global-single-input auto-complete add-line-autocomplete' style={{width:"76%"}}>
         <Autocomplete
         options={addRowData||[]}

         getOptionLabel={(option) => option?.value}
         renderInput={(params) => <TextField {...params} />}
         value={addRowInput}
         onChange={(e,newValue)=>setAddRowInput(newValue)}
         renderClear={() => null}
/>
         </div>
         <button onClick={()=>addRow()} className='add-row-btn'>+</button>
       </div>
        */}
       
       </>
   
        </div>
       }
      
      {
      log?.length!==0 &&
      <div className="new-global-white-bg-container new-log-container" style={{padding:"4px"}}>
       <div style={{padding:"8px",cursor:"pointer"}} onClick={clickLogView}></div>
       <div className={ logView ? 'pointer-up':"pointer"} onClick={clickLogView} style={{cursor:"pointer"}}></div>
        {
          logView &&(
         <div className='single-log-container' >
          {
            log?.map((r,i)=>(
              <div className='single-log-item'>
              <div className='single-log-item-line-image'>
                 <hr className='single-log-item-line'/>
                 <div className='single-log-item-image'>
                    <img src={logoImage} alt="" />
                 </div>
                 <hr className='single-log-item-line'/>
              </div>
              <p className='heading'>{r?.status}</p>
              <p className='name'>{r?.empId}</p>
              <p className='date-time'>{r?.date}</p>
           </div>
            ))
          }
          
           
         </div>
          )
        }

       
       </div>
      }

       {/* import clicked popup */}
       <Dialog 
          open={importPopup} 
          maxWidth="lg"
          onClose={closeImportPopup}
        >
          <div className='actions-select-dropdown-dialog'>
            <h4 style={{margin:"0"}}>Upload</h4>
            <hr className="global-hr" />
            <div className="file-picker-new"  style={{position:"relative",margin:"10px 0"}}>
              <input 
                // disabled={formValues?.isEdit} 
                type="file" 
                id="fileUpload" 
                accept=".xlsx"
                onChange={changeImage}
                disabled={fileToExport?.length!==0}
                 />
              <label htmlFor="fileUpload" 
                style={{cursor:fileToExport?.length!==0 && "not-allowed"}}
              >
                <img src={uploadFile} alt="" />
                Upload File
              </label>

              <p style={{top:"70%"}} className="doc-validation-alert">{validationAlert?.fileExport} 
              </p>
            </div>

           {fileToExport.length!==0 &&
            <div style={{width:"100%"}} className="global-single-input">
              <p>{fileToExport[0]?.name}
              <sup
              onClick={()=>setFileToExport([])}
              style={{fontSize:"1rem",color:"red",fontWeight:"600",cursor:"pointer"}}>X</sup>
              </p>
            </div>}
            <div className='justify-center'>
              <button 
              onClick={submitExportClick}
              className="btn btn-primary">Submit</button>
              <button className="btn btn-secondary-outlined"
                onClick={closeImportPopup} 
              >Cancel</button>
            </div>
          </div>
        </Dialog>

        {/* <div className='single-log-container-scroll-line'></div> */}

        <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />

      <LoadingForm loading={loadingForm}/>
     </div>
  )
}
