import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setAccountNo,
  setBankAccountId,
  setBranch,
  setCity,
  setCountry,
  setCurrency,
  setIfscCode,
  setOpeningBalance,
  setRegion,
  setStreet,
} from "../../../../../Redux/Account/bankAccountSlice";
import InputBox from "../InputBox";
import SubmitBtn from "../SubmitBtn";
import DropdownInputBox from "../DropdownInputBox";
import countryStates from '../../../../../Assets/JSON/countryStates.json'
import currencies from '../../../../../Assets/JSON/currencies.json'

const BankAccount = () => {
  
  const dispatch = useDispatch();
  const {
    bankAccountId,
    accountNo,
    ifscCode,
    currency,
    region,
    street,
    city,
    branch,
    country,
    openingBalance,
  } = useSelector((state) => state.bankAccountSlice);

  const bankAccountSlice = useSelector((state) => state.bankAccountSlice);
  const handleBankAccountSubmit = () => {
    console.log(bankAccountSlice);
  };

  // console.log(currencies.map(countryCode=>countryCode.cc))
  return (
    <div
      style={{ padding: "2% 4%" }}
      className="ledger-section-container-input-bank "
    >
      <div>
        {/*Bank Account ID  */}
        <InputBox
          label={"Bank Account ID"}
          value={bankAccountId}
          onChange={(e) => dispatch(setBankAccountId(e.target.value))}
        />
        {/*Account no  */}
        <InputBox
          label={"Account no"}
          value={accountNo}
          onChange={(e) => dispatch(setAccountNo(e.target.value))}
        />

        {/*IFSC Code  */}
        <InputBox
          label={"IFSC Code"}
          value={ifscCode}
          onChange={(e) => dispatch(setIfscCode(e.target.value))}
        />
      </div>
      <div>
        {/*Currency */}
        <DropdownInputBox labelName={"Currency"} value={currency} options={currencies.map(countryCode=>countryCode.cc)} onChange={(e,value)=>dispatch(setCurrency(value))}  />

        {/*Region */}
        <InputBox
          label={"Region"}
          value={region}
          onChange={(e) => dispatch(setRegion(e.target.value))}
        />

        {/*Street */}
        <InputBox
          label={"Street"}
          value={street}
          onChange={(e) => dispatch(setStreet(e.target.value))}
        />
      </div>
      <div>
        {/*City */}
        <InputBox
          label={"City"}
          value={city}
          onChange={(e) => dispatch(setCity(e.target.value))}
        />

        {/*Branch */}
        <InputBox
          label={"Branch"}
          value={branch}
          onChange={(e) => dispatch(setBranch(e.target.value))}
        />

        {/*Country */}
        <DropdownInputBox labelName={"Country"} value={country} options={countryStates?.countries} onChange={(e,value)=>dispatch(setCountry(value))}  />

        
      </div>
      <div>
        {/*Opening Balance */}
        <InputBox
          label={"Opening Balance"}
          containerStyle={{width:'31%'}}
          value={openingBalance}
          onChange={(e) => dispatch(setOpeningBalance(e.target.value))}
        />
       
      </div>
      {/* submitButton */}
      <SubmitBtn onClick={handleBankAccountSubmit}  />
    </div>
  );
};

export default BankAccount;
