import { createSlice } from "@reduxjs/toolkit";

export const empCountGraphSlice=createSlice({
    name:'empCountGraphSlice',
    initialState:{
        value:0
    },
    reducers:{
        update_empCountGraph:(state,action)=>{
            state.value=action.payload.empCount
        }
    }
})

export const {update_empCountGraph}=empCountGraphSlice.actions
export default empCountGraphSlice.reducer