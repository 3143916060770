import { Autocomplete, IconButton, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { journalListApiCall } from "../../../../../API/AccountUpdated/journalAPI";
import { useDispatch, useSelector } from "react-redux";
import { viewGlConfigAPICall } from "../../../Accounts/Configuration/glConfigAPI";
import { chartOfAccountListApiCall } from "../../../../../API/AccountUpdated/chartOfAccountAPI";
import {
  addCategoryAPICall,
  categorySingleViewAPI,
  editCategoryAPICall,
} from "./CategoryAPI";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
import SuccessSnackbar from "../../../../Single Components/SnackBars/SuccessSnackbar";
import { filterObjFromList } from "../../../../../Js/generalFunctions";
import { LoadingForm } from "../../../../Single Components/LoadingForm";
import { set_Back_button_action, setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";
export const CreateCategory = () => {
  // const configurationList = useSelector((state) => state.glConfigurationSlice.apiResList);
  const dispatch = useDispatch();
  const journalList = useSelector((state) => state.journalSlice.value);
  const singleViewId = localStorage.getItem("singleViewById");

  const singleCategoryView = useSelector(
    (state) => state.categorySlice.categorySingleView
  );

  const chartOfAccountList = useSelector(
    (state) => state.chartOfAccountsSlice.value
  );
  const controlAndExpenseAccList = chartOfAccountList?.filter(
    (item) => item.type === "Cost of revenue" 
    // (item) => item.type === "Cost of revenue" || item.type === "Expenses"
  );
  const incomeAccList = chartOfAccountList?.filter(
    (item) => item.type === "Income"
  );

  const [loadingForm,setLoadingForm]=useState(false)

  const [validationAlert, setValidationAlert] = useState({});
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
  });
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [showEditBtn, setShowEditBtn] = useState(false);

  const [disabledInput, setDisabledInput] = useState(false);

  //closeSnackbar
  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      message: "",
    });
  };

  let navigate = useNavigate();

  const categoryInitialState = {
    mainCategory: "",
    salesJournal: null,
    salesExpense: null,
    salesIncome: null,
  };
  const [categoryForm, setCategoryForm] = useState(categoryInitialState);

  const backToMainPage = () => {
    if (disabledInput === false) {
      const userConfirmed = window.confirm(
        "Changes you made may not be saved."
      );

      if (userConfirmed) {
        localStorage.removeItem("singleViewById");
        navigate("/userdashboard/inventory/configuration/category");
        dispatch(set_Back_button_action({ backButtonClickAction: true }));
      }
    } else {
      navigate("/userdashboard/inventory/configuration/category");
      dispatch(set_Back_button_action({ backButtonClickAction: true }));
    }
  };

  useEffect(() => {
    // viewGlConfigAPICall()
    chartOfAccountListApiCall();
    journalListApiCall();
    if (singleCategoryView === undefined && singleViewId) {
      categorySingleViewAPI({ _id: singleViewId });
    }
  }, []);

  useEffect(() => {
    if (singleCategoryView !== undefined) {
      setShowSaveBtn(true);
      setShowEditBtn(true);

      setDisabledInput(true);
    }
  }, [singleCategoryView]);

  useEffect(() => {
    if (singleCategoryView !== undefined) {
      setCategoryForm({
        ...categoryForm,
        mainCategory: singleCategoryView?.categoryName,
        salesJournal: filterObjFromList(
          "_id",
          journalList,
          "salesJournal",
          singleCategoryView
        ),
        salesExpense: filterObjFromList(
          "_id",
          controlAndExpenseAccList,
          "salesExpenseAccount",
          singleCategoryView
        ),
        salesIncome: filterObjFromList(
          "_id",
          incomeAccList,
          "salesIncome",
          singleCategoryView
        ),
      });
    }
  }, [singleCategoryView]);

  const getFormInfo = (key) => (e, newValue) => {
    const { value } = e.target;
    setValidationAlert({});
    setCategoryForm({ ...categoryForm, [key]: value !== 0 ? value : newValue });
  };

  // saveCategory fun
  const saveCategoryBtnFun = () => {
    dispatch( setFilterActive(false))
    let clearFn=()=>{
      setCategoryForm(categoryInitialState)
    }
    addCategoryAPICall(
      {
        categoryName: categoryForm?.mainCategory,
        // salesJournal: categoryForm?.salesJournal?._id,
        salesExpenseAccount: categoryForm?.salesExpense?._id,
        salesIncome: categoryForm?.salesIncome?._id,
      },
      setSnackBarStates,
      snackBarStates,clearFn,setLoadingForm
    );
  };

  const editEnable = () => {
    setDisabledInput(false);
  };

  //editBody
  const editBody = {
    _id: singleCategoryView?._id,
    categoryName: categoryForm?.mainCategory,
    // salesJournal:  categoryForm?.salesJournal?._id,
    salesExpenseAccount: categoryForm?.salesExpense?._id,
    salesIncome: categoryForm?.salesIncome?._id,
  };

  //updateAttributeBtn
  const updateCategoryBtn = () => {
    editCategoryAPICall(
      editBody,
      setSnackBarStates,
      snackBarStates,
      setDisabledInput,setLoadingForm
    );
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const shouldShowAlert = !disabledInput;
      if (shouldShowAlert) {
        event.preventDefault();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [disabledInput]);
  return (
    <div className="global-page-parent-container">
    
      <div className="global-white-bg-container">
        <Tooltip title="Back" onClick={backToMainPage}>
          <IconButton>
            <i class="bi bi-chevron-left"></i>
          </IconButton>
        </Tooltip>

        {showEditBtn ? (
          <Tooltip title="Edit">
            <IconButton>
              {/* <i class="bi bi-check2-square edit-icon" style={{fontSize:"1.5rem"}}></i> */}
              {disabledInput ? (
                <i
                  class="bi bi-pencil-square edit-icon1"
                  onClick={editEnable}
                ></i>
              ) : (
                <i
                  class="bi bi-check2-square edit-icon"
                  style={{ fontSize: "1.5rem" }}
                  onClick={updateCategoryBtn}
                ></i>
              )}
            </IconButton>
          </Tooltip>
        ) : (
          ""
        )}

        {showSaveBtn ? (
          ""
        ) : (
          <Tooltip
            title="Save"
            onClick={saveCategoryBtnFun}
            disabled={
              categoryForm?.mainCategory == ""
              // categoryForm?.salesJournal == null ||
              // categoryForm?.salesExpense == null ||
              // categoryForm?.salesIncome == null
            }
          >
            <IconButton>
              <i class="bi bi-floppy"></i>
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div
        className="global-white-bg-container create-journal-container"
        // style={{ height: "25vh" }}
      >
        <div className="global-single-input create-joutnal-input">
          <p>Main Category</p>

          <input
            type="text"
            onChange={getFormInfo("mainCategory")}
            value={categoryForm?.mainCategory}
            disabled={disabledInput}
          />

          <p className="doc-validation-alert"></p>
        </div>
        {/* <div className="global-single-input create-joutnal-input auto-complete">
          <p>Sales Journal</p>
          <Autocomplete
            sx={{ width: "100%" }}
            options={journalList || []}
            getOptionLabel={(option) => option?.journalName}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select a journal" />
            )}
            disabled={disabledInput}

            value={categoryForm?.salesJournal}
            onChange={getFormInfo("salesJournal")}
          />
          <p className="doc-validation-alert"></p>
        </div> */}
        <div className="global-single-input create-joutnal-input auto-complete">
          <p>Sales Expense</p>
          <Autocomplete
            sx={{ width: "100%" }}
            options={controlAndExpenseAccList || []}
            getOptionLabel={(option) => option?.accountName}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select a sales Expense" />
            )}
            disabled={disabledInput}
            value={categoryForm?.salesExpense}
            onChange={getFormInfo("salesExpense")}
          />
          <p className="doc-validation-alert"></p>
        </div>

        <div className="global-single-input auto-complete create-joutnal-input">
          <p>Sales Income</p>
          <Autocomplete
            sx={{ width: "100%" }}
            options={incomeAccList || []}
            getOptionLabel={(option) => option?.accountName}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select Income" />
            )}
            disabled={disabledInput}
            value={categoryForm?.salesIncome}
            onChange={getFormInfo("salesIncome")}
          />
        </div>
      </div>

      <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />

      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <LoadingForm loading={loadingForm}/>

    </div>
  );
};
