import React, { useEffect, useState } from "react";
import "../../../../../css/Account/reports/financialReports.css";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import financialReportsGroups from "../../../../../Assets/JSON/financialReportsGroups.json";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import CurrentAssetReport from "./financialReportsGroup/CurrentAssetReport";
import CurrentLiabilityReport from "./financialReportsGroup/CurrentLiabilityReport";
import CashOrBankSummary from "./financialReportsGroup/CashOrBankSummary";
import GroupSummary from "./financialReportsGroup/GroupSummary";
import LedgerReport from "./financialReportsGroup/LedgerReport";
import InvoiceReport from "./financialReportsGroup/InvoiceReport";
import DayBook from "./financialReportsGroup/DayBook";
import TrailBalance from "./financialReportsGroup/TrailBalance";
import FinalReports from "./financialReportsGroup/finalReports/FinalReports";
import OutstandingReports from "./financialReportsGroup/outstandingReports/OutstandingReports";
 

const finalReportsGroup = [
  {
    id: 1,
    name: "Cash Flow Statement ( Direct)",
  },
  {
    id: 2,
    name: "Cash Flow Statement ( Indirect )",
  },
  {
    id: 3,
    name: "Fund Flow Statement",
  },
  {
    id: 4,
    name: "Income Statement",
  },
  {
    id: 5,
    name: "Balance Sheet",
  },
];
const outstandingReportsGroup = [
  {
    id: 1,
    name: "Bill payable Statement",
  },
  {
    id: 2,
    name: "Bill receivable Statement",
  },
  {
    id: 3,
    name: "Group outstanding",
  },
  {
    id: 4,
    name: "Ledger outstanding",
  },
];
//rest data 
const renderData = [
  {
    code: 1000,
    particular: "closing stock",
    opening: 5000,
    debit: 100,
    credit: 2000,
    closing: 3000,
    tableData: [
      {
        code: 1000,
        particular: "closing stock",
        opening: 5000,
        debit: 100,
        credit: 2000,
        closing: 3000,
      },
      {
        code: 1000,
        particular: "closing stock",
        opening: 5000,
        debit: 100,
        credit: 2000,
        closing: 3000,
      },
    ],
  },
  {
    code: 1021,
    particular: "opening stock",
    opening: 5000,
    debit: 100,
    credit: 2000,
    closing: 3000,
  },
  {
    code: 1058,
    particular: "shirt stock",
    opening: 5000,
    debit: 100,
    credit: 2000,
    closing: 3000,
    tableData: [
      {
        code: 1000,
        particular: "closing stock",
        opening: 5000,
        debit: 100,
        credit: 2000,
        closing: 3000,
      },
      {
        code: 1000,
        particular: "closing stock",
        opening: 5000,
        debit: 100,
        credit: 2000,
        closing: 3000,
      },
      {
        code: 1000,
        particular: "closing ",
        opening: 5000,
        debit: 100,
        credit: 2000,
        closing: 3000,
      },
    ],
  },
];

const FinancialReports = () => {
  const [active, setActive] = useState(0);
  const [dropListSelected, setDropListSelected] = useState(1);
  const [dropListSelectedName, setDropListSelectedName] = useState("");

  useEffect(() => {
    handleListItemClick(0, 1, "Cash Flow Statement ( Direct)");
  }, []);

  const [selectedReport, setSelectedReport] = useState({ id: null, name: "" });
  const [openReports, setOpenReports] = useState({
    finalReport: false,
    outstandingReport: false,
  });

  const setDropdownSelection = (id, name) => {
    setDropListSelected(id);
    setDropListSelectedName(name);
  };

  const handleListItemClick = (index, id, name) => {
    setActive(index);

    if (index === 8) {
      setOpenReports({
        finalReport: !openReports.finalReport,
        outstandingReport: false,
      });
      setSelectedReport({ id, name });
      setDropdownSelection(1, "Cash Flow Statement ( Direct)");
    } else if (index === 9) {
      setOpenReports({
        finalReport: false,
        outstandingReport: !openReports.outstandingReport,
      });
      setSelectedReport({ id, name });
      setDropdownSelection(1, "Bill payable Statement");
    } else {
      setOpenReports({ finalReport: false, outstandingReport: false });
      setSelectedReport({ id, name });
      setDropdownSelection(0, "");
    }
  };

  const listItemTextStyle = () => {
    return {
      fontWeight: "600",
      fontSize: "0.9rem",
    };
  };

  return (
    <div className="global-page-parent-container">
      <p className="breadcrumb">
        Accounts &gt; Reports &gt; Financial Reports &gt; {selectedReport.name}{" "}
        {openReports.finalReport && <span>&gt; {dropListSelectedName}</span>}
        {openReports.outstandingReport && (
          <span>&gt; {dropListSelectedName}</span>
        )}
      </p>

      <div className="accounting-container">
        <div className="accounting-side-menu">
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              marginBlockStart: "10px",
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {financialReportsGroups.map(({ id, name }, index) => (
              <React.Fragment key={index}>
                <ListItemButton
                  sx={{ paddingLeft: "2em" }}
                  className={active === index ? "isactive" : ""}
                  onClick={() => handleListItemClick(index, id, name)}
                >
                  <ListItemText
                    primary={
                      <Typography sx={listItemTextStyle}>{name}</Typography>
                    }
                  />
                  {index === 8 &&
                    (openReports.finalReport ? <ExpandLess /> : <ExpandMore />)}
                  {index === 9 &&
                    (openReports.outstandingReport ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    ))}
                </ListItemButton>

                {index === 8 && (
                  <Collapse
                    in={openReports.finalReport}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {finalReportsGroup.map(({ name }, index) => (
                        <ListItemButton
                          key={index}
                          sx={{ pl: 6 }}
                          onClick={() => setDropdownSelection(index + 1, name)}
                          className={
                            index + 1 === dropListSelected ? "isactive" : ""
                          }
                        >
                          <ListItemText
                            primary={
                              <Typography sx={listItemTextStyle}>
                                {name}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                )}

                {index === 9 && (
                  <Collapse
                    in={openReports.outstandingReport}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {outstandingReportsGroup.map(({ name }, index) => (
                        <ListItemButton
                          key={index}
                          sx={{ pl: 6 }}
                          onClick={() => setDropdownSelection(index + 1, name)}
                          className={
                            index + 1 === dropListSelected ? "isactive" : ""
                          }
                        >
                          <ListItemText
                            primary={
                              <Typography sx={listItemTextStyle}>
                                {name}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            ))}
          </List>
        </div>
        <div className="accounting-voucher-menu">
          {selectedReport.id === 1 && (
            <CurrentAssetReport
              renderData={renderData}
              selectListItem={selectedReport.name}
            />
          )}
          {selectedReport.id === 2 && (
            <CurrentLiabilityReport
              renderData={renderData}
              selectListItem={selectedReport.name}
            />
          )}
          {selectedReport.id === 3 && (
            <CashOrBankSummary
              renderData={renderData}
              selectListItem={selectedReport.name}
            />
          )}
          {selectedReport.id === 4 && (
            <GroupSummary
              renderData={renderData}
              selectListItem={selectedReport.name}
            />
          )}
          {selectedReport.id === 5 && (
            <LedgerReport
              renderData={renderData}
              selectListItem={selectedReport.name}
            />
          )}
          {selectedReport.id === 6 && (
            <InvoiceReport
              renderData={renderData}
              selectListItem={selectedReport.name}
            />
          )}
          {selectedReport.id === 7 && (
            <DayBook
              renderData={renderData}
              selectListItem={selectedReport.name}
            />
          )}
          {selectedReport.id === 8 && (
            <TrailBalance
              renderData={renderData}
              selectListItem={selectedReport.name}
            />
          )}
          {selectedReport.id === 9 && (
            <FinalReports
              renderData={renderData}
              selectListItem={dropListSelectedName}
              selectedDropdownList={dropListSelected}
            />
          )}
          {selectedReport.id === 10 && (
            <OutstandingReports
              renderData={renderData}
              selectListItem={dropListSelectedName}
              selectedDropdownList={dropListSelected}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FinancialReports;
