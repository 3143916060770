import React from "react";
import { ViewOrderUser } from "../ViewOrder/ViewOrderUser";
import { ViewOrderAdmin } from "../ViewOrder/ViewOrderAdmin";
import { useSelector } from "react-redux";

export const ViewOrder = () => {
  //Role of User [admin or user]
  const userRole = useSelector((state) => state.userRoleSlice.value);
  return (
    <div>
      {/* {userRole === "user" ? ( */}
        <ViewOrderUser />
      {/* ) : userRole === "admin" ? (
        <ViewOrderAdmin />
      ) : undefined} */}
    </div>
  );
};
