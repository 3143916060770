import axios from "axios"
import { HEADERS } from "../../../../API/UrlAndPaths"
import store from "../../../../Redux/store"
import { get_all_sequence_list } from "./sequenceSlice"

export const viewAllSequenceListAPICall=()=>{
    axios.post("settings/viewSequence",HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_all_sequence_list({sequenceListData:res.data}))
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}

export const addSequenceAPICall=(
    body,
    updateListner,
    clearState,
    setSuccessSnackOpen,
    setErrorSnackOpen,
    setSnackMsg
    )=>{
    axios.post("settings/addSequence",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            setSnackMsg('Sequence Added')
            setSuccessSnackOpen(true)
            updateListner()
            clearState()
        }
    })
    .catch((err)=>{
        switch (err.response.status) {
            case 404:
                setSnackMsg('No data available..')
              break;
            case 500:
                setSnackMsg('Server error!!')
              break;
            case 401:
                setSnackMsg('Unauthorized!!')
              break;
            case 403:
                setSnackMsg('Forbidden!!')
              break;
            case 409:
                setSnackMsg("Duplicated")
            default:
                setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
              break;
          }
        setErrorSnackOpen(true)
        console.log(err.response);
    })
}

export const editSequenceAPICall=(body,updateListner,setSuccessSnackOpen,setErrorSnackOpen,setSnackMsg)=>{
    axios.put("settings/editSequence",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            setSnackMsg('Success')
            setSuccessSnackOpen(true)
            updateListner()
        }
    })
    .catch((err)=>{
        switch (err.response.status) {
            case 404:
                setSnackMsg('No data available..')
              break;
            case 500:
                setSnackMsg('Server error!!')
              break;
            case 401:
                setSnackMsg('Unauthorized!!')
              break;
            case 403:
                setSnackMsg('Forbidden!!')
              break;
            case 409:
                setSnackMsg("Duplicated")
            default:
                setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
              break;
          }
        setErrorSnackOpen(true)
        console.log(err.response);
    })
}