import axios from "axios";
import { HEADERS } from "../../UrlAndPaths";
import store from "../../../Redux/store";
import { getAllRfqList } from "../../../Redux/Prchase Manager/RFQ/RfqSlice";
import { updateNewProductList } from "../../../component/Modules/Accounts/Vendor/Slice/vendorBillSlice";

//all Rfq list

export const AllRfqList = (branchId) => {
  axios
    .post(
      "purchase/viewRequestForQuotation",
      { branchId: branchId === "null" ? null : branchId },
      HEADERS
    )
    .then((res) => {
      if (res.status === 200) {
        // console.log(res.data)
        store.dispatch(getAllRfqList({ RfqList: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(getAllRfqList({ RfqList: undefined }));
    });
};
export const RFQFilterAPICall = (body,setIsLoading) => {
  setIsLoading!==undefined&&setIsLoading(true)
  axios
    .post("purchase/searchRequestForQuotation", body, {
      headers: {
        'Content-Type': 'application/json',
      },
      
    })
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(getAllRfqList({ RfqList: res.data }));
        setIsLoading!==undefined&&setIsLoading(false)
      }
    })
    .catch((err) => {
      store.dispatch(getAllRfqList({ RfqList: undefined }));
      setIsLoading!==undefined&&setIsLoading(false)
    });
};

export const SaveRfqApiCall = (
  body,
  setState,
  state,
  setFormValues,
  formInitialState,
  setFormAddedTable,
  setshowConfirmOrder,
  setsaveRfqDeatails,
  disabledFun
) => {
  axios
    .post("purchase/requestForQuatation", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setState({ ...state, message: "Saved .. !!", success: true });

        // setFormValues(formInitialState)
        // setFormAddedTable([])
        disabledFun();
        setshowConfirmOrder(true);
        setsaveRfqDeatails(res?.data);
      }
    })
    .catch((err) => {
      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
      } else {
        setState({ ...state, message: err?.response?.data, error: true })
      }
      setshowConfirmOrder(false);
    });
};

export const ConfirmRfqApiCall = (
  saveRfqDeatails,
  setsaveRfqDeatails,
  setshowConfirmOrder,
  sethideSaveBtn,
  setshowpurchaseOrderConfirm
) => {
  axios
    .put(
      "purchase/rfqCancelConfirmOrReset",
      { id: saveRfqDeatails?._id, statusType: 2 },
      HEADERS
    )
    .then((res) => {
      // window.alert("Confirmed Success fully....")
      console.log(res.data);
      setsaveRfqDeatails(res?.data);
      setshowConfirmOrder(false);

      sethideSaveBtn(false);
      setshowpurchaseOrderConfirm(true);
    })
    .catch((err) => {
      window.alert("Error");
    });
};

export const cancelRfqAPiCall = (
  saveRfqDeatails,
  setState,
  state,
  setsaveRfqDeatails,
  setResetToDraftShowBtn,
  setShowAllBtn,setLoadingForm
) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  axios
    .put(
      "purchase/rfqCancelConfirmOrReset",
      { id: saveRfqDeatails?._id, statusType: 1 },
      HEADERS
    )
    .then((res) => {
      if (res.status === 200) {
        setsaveRfqDeatails(res?.data);
        setShowAllBtn(true);
        setResetToDraftShowBtn(true);
        setLoadingForm!==undefined && setLoadingForm(false)
        setState({ ...state, message: "RFQ canceled .. !!", success: true });
      }
    })
    .catch((err) => {
      setLoadingForm!==undefined && setLoadingForm(false)
      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
      } else {
        setState({ ...state, message: err?.response?.data, error: true })
      }
    });
};

// resetTo Draft
export const resetToDraftApiCall = (
  saveRfqDeatails,
  setState,
  state,
  setsaveRfqDeatails,
  setResetToDraftShowBtn,
  setShowAllBtn,setLoadingForm
) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  axios
    .put(
      "purchase/rfqCancelConfirmOrReset",
      { id: saveRfqDeatails?._id, statusType: 3 },
      HEADERS
    )
    .then((res) => {
      if (res.status === 200) {
        setsaveRfqDeatails(res?.data);
        setResetToDraftShowBtn(false);
        setShowAllBtn(false);
        setState({ ...state, message: "Reset To Drafted.. !!", success: true });
        setLoadingForm!==undefined && setLoadingForm(false)
      }
    })
    .catch((err) => {
      setLoadingForm!==undefined && setLoadingForm(false)
      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
      } else {
        setState({ ...state, message: err?.response?.data, error: true })
      }

    });
};

//PoCancelAPiCall

export const PoCancelAPiCall = (
  saveRfqDeatails,
  setState,
  state,
  setsaveRfqDeatails,
  setShowAllBtn,
  setResetToDraftShowBtn,setLoadingForm
) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  axios
    .put(
      "purchase/cancelPurchaseOrder",
      { id: saveRfqDeatails?._id, statusType: 1 },
      HEADERS
    )
    .then((res) => {
      if (res.status === 200) {
        setsaveRfqDeatails(res?.data);
        setShowAllBtn(true);
        setResetToDraftShowBtn(true);
        setState({ ...state, message: "Po canceled .. !!", success: true });
        setLoadingForm!==undefined && setLoadingForm(false)
      }
    })
    .catch((err) => {
      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
      } else {
        setState({ ...state, message: err?.response?.data, error: true })
      }
      setLoadingForm!==undefined && setLoadingForm(false)
    });
};

// // CreateNewPoCancel

// export const CreateNewPoCancelApiCall =(savePurchaseOrderDetails, setState, state,setsavePurchaseOrderDetails)=>{
//     axios.put("purchase/cancelPurchaseOrder",{id:savePurchaseOrderDetails?._id,statusType:1},HEADERS).then((res)=>{
//         if(res.status === 200){
//             setsavePurchaseOrderDetails(res?.data)
//             setState({...state,message:"Po canceled .. !!",success:true})
//         }

//     }).catch((err)=>{
//         setState({...state,message:"Something went wrong !!",error:true})

//     })
// }

// GenerateRfqName and generate PurchaseName same

export const GenerateRfqName = (body, setFormValues) => {
  console.log(body?.storeCode);
  axios
    .post(
      "purchase/genaratePurchaseName",
      {
        branchId: body?._id,
      },
      HEADERS
    )
    .then((res) => {
      setFormValues(res.data);
    })
    .catch((err) => {
      // window.alert("GENERATE RFQ NAME ERROR>>>>")
    });
};

// updateRfqApiCall
export const updateRfqApiCall = (
  body,
  setState,
  state,
  setshowConfirmOrder,
  setsaveRfqDeatails
) => {
  axios
    .put("purchase/updateRequestForQuotationById", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setState({ ...state, message: "RFQ edited", success: true });
        setshowConfirmOrder(true);
        setsaveRfqDeatails(res.data);
      }
    })
    .catch((err) => {
      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
      } else {
        setState({ ...state, message: err?.response?.data, error: true })
      }

    });
};

//Create Request For Quotation1 New APi Calls
//-----------------------------------------//

export const Save_NewRequestforQuotationAPiCall = (
  body,
  setState,
  state,
  setshowConfirmOrder,
  setsaveRfqDeatails,
  sethideSaveBtn,
  setDisabledInput,setLoadingForm
) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  axios
    .post("purchase/requestForQuatation", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setState({ ...state, message: "RFQ Saved .. !!", success: true });
        setshowConfirmOrder(true);
        setsaveRfqDeatails(res?.data);
        sethideSaveBtn(true);
        setDisabledInput(true);
        localStorage.setItem("singleViewById", res?.data?._id);
        setLoadingForm!==undefined && setLoadingForm(false)
      }
    })
    .catch((err) => {
      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
      } else {
        setState({ ...state, message: err?.response?.data, error: true })
      }
      setLoadingForm!==undefined && setLoadingForm(false)
      setshowConfirmOrder(false);
    });
};

//ConfirmRFQ New APiCall

export const ConfirmRfqNewAPiCall = (
  saveRfqDeatails,
  setsaveRfqDeatails,
  setshowConfirmOrder,
  sethideSaveBtn,
  setshowpurchaseOrderConfirm,
  setRfqEdit,
  singleViewID,state,setState,setLoadingForm
) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  axios
    .put(
      "purchase/rfqCancelConfirmOrReset",
      { id: saveRfqDeatails?._id || singleViewID, statusType: 2 },
      HEADERS
    )
    .then((res) => {
      // window.alert("Confirmed Success fully....")
      setLoadingForm!==undefined && setLoadingForm(false)

      setsaveRfqDeatails(res?.data);
      setshowConfirmOrder(false);

      sethideSaveBtn(false);
      setshowpurchaseOrderConfirm(true);
      setRfqEdit(false);
    })
    .catch((err) => {
      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
      } else {
        setState({ ...state, message: err?.response?.data, error: true })
      }
      setLoadingForm!==undefined && setLoadingForm(false)


    });
};

//updateRfqApiNewCall

export const updateRfqNewApiCall = (
  body,
  setState,
  state,
  setshowConfirmOrder,
  setsaveRfqDeatails,
  setEditClicked,
  setDisabledInput,setLoadingForm
) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  axios
    .put("purchase/updateRequestForQuotationById", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setState({ ...state, message: "RFQ edited", success: true });
        setshowConfirmOrder(true);
        setsaveRfqDeatails(res?.data);
        setEditClicked(false);
        setDisabledInput(true);
        setLoadingForm!==undefined && setLoadingForm(false)
      }
    })
    .catch((err) => {
      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
      } else {
        setState({ ...state, message: err?.response?.data, error: true })
      }
      setLoadingForm!==undefined && setLoadingForm(false)
    });
};

// updatePOApiNewCall
export const updatePoNewApiCall = (body) => {
  axios
    .put("purchase/updateRequestForQuotationById", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
      }
    })
    .catch((err) => {});
};

//product search
export const productSearchApi = async (body) => {
  try {
    const response = await axios.post(
      "readymade/productSearchByPNB",
      body,
      HEADERS
    );
    if (response.status === 200) {
      store.dispatch(
        updateNewProductList({ NewProductForAccountsData: response.data })
      );
    }
  } catch (e) {
    console.error(e.message);
  }
};
