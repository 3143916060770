import { IconButton, MenuItem, Select } from '@mui/material'
import React, { useState } from 'react'
import "../../../../css/Settings/ReportForward/reportForward.css"
export const ReportForward = () => {
        const [activeBtn,setActiveBtn]=useState('reportForwardSettingsManual')

        const clickreportForwardSettingsManual=()=>{
          document.getElementById("reportForwardSettingsManual").classList.add("focus");
          document.getElementById("reportForwardSettingsAuto").classList.remove("focus");
          document.getElementById("reportForwardSettingsReport").classList.remove("focus");

          setActiveBtn('reportForwardSettingsManual')
        }

        const clickreportForwardSettingsAuto=()=>{
          document.getElementById("reportForwardSettingsAuto").classList.add("focus");
          document.getElementById("reportForwardSettingsManual").classList.remove("focus");
          document.getElementById("reportForwardSettingsReport").classList.remove("focus");
          setActiveBtn('reportForwardSettingsAuto')
        }
        const clickreportForwardSettingsReport=()=>{
          document.getElementById("reportForwardSettingsReport").classList.add("focus");
          document.getElementById("reportForwardSettingsManual").classList.remove("focus");
          document.getElementById("reportForwardSettingsAuto").classList.remove("focus");
          setActiveBtn('reportForwardSettingsReport')
        }


        return (
          <div className="reportForward-settings-container">
          <div className="reportForward-settings-container-left">
                <div
                id="reportForwardSettingsManual"
                onClick={clickreportForwardSettingsManual}
                className="reportForward-settings-container-left-list focus">
                  <div className="reportForward-settings-container-left-list-checkbox">
                  <input type="checkbox" id='reportForwardSms'/>
                  <label htmlFor='reportForwardSms'>SMS</label>
                  </div>
                  <div className="reportForward-settings-container-left-list-checkbox">
                  <input type="checkbox" id='reportForwardKoopa' defaultChecked/>
                  <label htmlFor='reportForwardKoopa'>KOOPA</label>
                  </div>
                  <div className="reportForward-settings-container-left-list-checkbox">
                  <input type="checkbox" id='reportForwardWhatsapp'/>
                  <label htmlFor='reportForwardWhatsapp'>WHATSAPP</label>
                  </div>
                </div>
                <div
                id="reportForwardSettingsAuto"
                onClick={clickreportForwardSettingsAuto}
                className="reportForward-settings-container-left-list">
                Email Address
                </div>
                <div
                id="reportForwardSettingsReport"
                onClick={clickreportForwardSettingsReport}
                className="reportForward-settings-container-left-list">
                Reports
                </div>
                
          </div>
            <div className="reportForward-settings-container-right">
              {activeBtn==='reportForwardSettingsManual'?
              <div className="reportForward-settings-container-right-section">
                <h4>Add Mobile</h4>
                <div className="reportForward-settings-container-right-section-input">
                  <input type="tel" maxLength="15"/>
                  <button className="btn btn-report-forward">+</button>
                </div>
                <div className="reportForward-settings-container-right-section-output">
                  +91 9207557654
                  <IconButton>
                      <i class="bi bi-trash3 delete-icon"></i>
                  </IconButton>
                </div>
                <div className="reportForward-settings-container-right-section-output">
                  +91 9207557654
                  <IconButton>
                      <i class="bi bi-trash3 delete-icon"></i>
                  </IconButton>
                </div>
                <div className="reportForward-settings-container-right-section-output">
                  +91 9207557654
                  <IconButton>
                      <i class="bi bi-trash3 delete-icon"></i>
                  </IconButton>
                </div>
              </div> 
              :activeBtn==='reportForwardSettingsAuto'?
              <div className="reportForward-settings-container-right-section">
                <h4>Add Email</h4>
                <div className="reportForward-settings-container-right-section-input">
                  <input type="email" placeholder='email address' />
                  <button className="btn btn-report-forward">+</button>
                </div>
                <div className="reportForward-settings-container-right-section-output">
                  leeyet@gmail.com
                  <IconButton>
                      <i class="bi bi-trash3 delete-icon"></i>
                  </IconButton>
                </div>
                <div className="reportForward-settings-container-right-section-output">
                  leeyet@gmail.com
                  <IconButton>
                      <i class="bi bi-trash3 delete-icon"></i>
                  </IconButton>
                </div>
                <div className="reportForward-settings-container-right-section-output">
                  leeyet@gmail.com
                  <IconButton>
                      <i class="bi bi-trash3 delete-icon"></i>
                  </IconButton>
                </div>
              </div> 
              :activeBtn==='reportForwardSettingsReport'?
              <div className="reportForward-settings-container-right-section" style={{padding:'40px 20px'}}>
                  
                  <div className="radio-groups-container report-forward-radio-ctn">
                    <div className="global-radio-button radio-group report-forward-radio-group">
                      <input type="radio" name='reportForwardReports' id='reportsDaily'/>
                      <label htmlFor='reportsDaily'>Daily</label>
                    </div>
                    <div className="global-radio-button radio-group report-forward-radio-group">
                      <input type="radio" name='reportForwardReports' id='reportsWeekly' defaultChecked/>
                      <label htmlFor='reportsWeekly'>Weekly</label>
                    </div>
                    <div className="global-radio-button radio-group report-forward-radio-group">
                      <input type="radio" name='reportForwardReports' id='reportsMonthly'/>
                      <label htmlFor='reportsMonthly'>Monthly</label>
                    </div>
                  </div>


                  <div className="reportForward-settings-container-right-section-body">
                    <input type="checkbox" id='reportForwardPurchaseR'/>
                    <label htmlFor='reportForwardPurchaseR'>Purchase Report</label>
                  </div>
                  <div className="reportForward-settings-container-right-section-body">
                    <input type="checkbox" id='reportForwardStockR'/>
                    <label htmlFor='reportForwardStockR'>Stock Report</label>
                  </div>
                  <div className="reportForward-settings-container-right-section-body">
                    <input type="checkbox" id='reportForwardSalesR'/>
                    <label htmlFor='reportForwardSalesR'>Sales Report</label>
                  </div>
                  <div className="reportForward-settings-container-right-section-body">
                    <input type="checkbox" id='reportForwardDailyR'/>
                    <label htmlFor='reportForwardDailyR'>Daily Report</label>
                  </div>
                  <div className="reportForward-settings-container-right-section-body">
                    <input type="checkbox" id='reportForwardDailyCashCardSummary'/>
                    <label htmlFor='reportForwardDailyCashCardSummary'>Daily Cash/Card Summary</label>
                  </div>
                  <div className="reportForward-settings-container-right-section-body">
                    <input type="checkbox" id='reportForwardProfitSale'/>
                    <label htmlFor='reportForwardProfitSale'>Profit And Sale</label>
                  </div>
                  <div className="reportForward-settings-container-right-section-body">
                    <input type="checkbox" id='reportForwardTransferR'/>
                    <label htmlFor='reportForwardTransferR'>Transfer Report</label>
                  </div>
                  <div className="reportForward-settings-container-right-section-body">
                    <input type="checkbox" id='reportForwardStockAdjR'/>
                    <label htmlFor='reportForwardStockAdjR'>Stock Adjustment Report</label>
                  </div>
                  <div className="reportForward-settings-container-right-section-body">
                    <input type="checkbox" id='reportForwardExpenseR'/>
                    <label htmlFor='reportForwardExpenseR'>Expense Report</label>
                  </div>
                  <div className="reportForward-settings-container-right-section-body">
                    <input type="checkbox" id='reportForwardPaymentR'/>
                    <label htmlFor='reportForwardPaymentR'>Payment Report</label>
                  </div>
              </div>
              :undefined
              }
              
          </div>

          </div>
        )
}
