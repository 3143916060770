import axios from "axios";
import store from "../../../Redux/store";
import {HEADERS} from '../../UrlAndPaths'
import {
    update_all_department_list
} from '../../../Redux/Staff/HRM/Department/allDepartmentSlice'
import { update_all_designation_list } from "../../../Redux/Staff/HRM/Designation/allDesignationList";


//Create New Department
export const createDepartmentAPICall = async (body, updateListener,
    setError,
    setSuccess,
    setMsg,
    clearState) => {
    await axios.post('employee/addDepartment', body, HEADERS).then((res) => {
        if (res.status === 200) {
            console.log('New Department Created Successfully...');
            updateListener();
            setMsg("Success");
            setSuccess(true);
            clearState("");
        }
    }).catch((err) => {
        if (err.response.status === undefined) {
            setMsg("Network Connection error!!");
        }
        if (err.response.status === 409) {
            setMsg("Department Already Exist");
        }

        if (err.response.status === 401) {
            setMsg("Unauthorized");
        }
        if (err.response.status === 403) {
            setMsg("Forbidden");
        }

        setError(true);
        console.error(err.response);
    })
}
//Get All Departments
export const getAllDepartmentAPICall = async (setIsLoading,setErrorSnack,setSnackMsg) => {
    if(setIsLoading !== undefined) setIsLoading(true)
    await axios.get('employee/getAllDepartments', HEADERS).then((res) => {
        if (res.status === 200) {
            console.log('All Departments fetched Successfully...');
            store.dispatch(update_all_department_list({
                allDepartments: res.data
            }))
            if(setIsLoading !== undefined) setIsLoading(false)
        }
    }).catch((err) => {
        store.dispatch(update_all_department_list({
            allDepartments: undefined
        }))
        console.error(err.response);
        switch (err.response.status) {
            case 404:
                setSnackMsg('No data available..')
              break;
            case 500:
                setSnackMsg('Server error!!')
              break;
            case 401:
                setSnackMsg('Unauthorized!!')
              break;
            case 403:
                setSnackMsg('Forbidden!!')
              break;
          
            default:
                setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
              break;
          }
          setErrorSnack(true)
        if(setIsLoading !== undefined) setIsLoading(false)
    })
}
//Edit Department
export const editDepartmentAPICall = async (body, updateListener,
    setError,
    setSuccess,
    setMsg) => {
    await axios.put('employee/editDepartment', body, HEADERS).then((res) => {
        if (res.status === 200) {
            console.log('Department Edited Successfully...');
            updateListener();
            setMsg("Edit Success");
            setSuccess(true);
        }
    }).catch((err) => {
        if (err.response.status === undefined) {
            setMsg("Network Connection error!!");
        }
        if (err.response.status === 409) {
            setMsg("Department Already Exist");
        }

        if (err.response.status === 401) {
            setMsg("Unauthorized");
        }
        if (err.response.status === 403) {
            setMsg("Forbidden");
        }

        setError(true);
        console.error(err.response);
    })
}

//Create New Designation
export const createDesignationAPICall = async (body, updateListener,
    setError,
    setSuccess,
    setMsg,
    clearState) => {
    await axios.post('employee/addDesignation', body, HEADERS).then((res) => {
        if (res.status === 200) {
            console.log('New Designation Created Successfully...');
            updateListener();
            setMsg("New Designation created 👍");
            setSuccess(true);
            clearState("");
        }
    }).catch((err) => {
        if (err.response.status === undefined) {
            setMsg("Network Connection error!!");
        }
        if (err.response.status === 409) {
            setMsg("Designation Already Exist");
        }

        if (err.response.status === 401) {
            setMsg("Unauthorized");
        }
        if (err.response.status === 403) {
            setMsg("Forbidden");
        }

        setError(true);
        console.error(err.response);
    })
}
//Edit a Designation
export const editDesignationAPICall = async (body, updateListener,
    setError,
    setSuccess,
    setMsg) => {
    await axios.put('employee/editDesignation', body, HEADERS).then((res) => {
        if (res.status === 200) {
            console.log('Designation Edited Successfully...');
            updateListener();
            setMsg("Designation edited 👍");
            setSuccess(true);
        }
    }).catch((err) => {
        if (err.response.status === undefined) {
            setMsg("Network Connection error!!");
        }
        if (err.response.status === 409) {
            setMsg("Designation Already Exist");
        }

        if (err.response.status === 401) {
            setMsg("Unauthorized");
        }
        if (err.response.status === 403) {
            setMsg("Forbidden");
        }

        setError(true);
        console.error(err.response);
    })
}
//Get All Designations
export const getAllDesignationAPICall = async (setIsLoading,setErrorSnack,setSnackMsg) => {
    if(setIsLoading !== undefined) setIsLoading(true)
    await axios.get('employee/getAllDesignations', HEADERS).then((res) => {
        if (res.status === 200) {
            console.log('All Designation fetched Successfully...');
            store.dispatch(
               update_all_designation_list({allDesignation:res.data})
            )
            
            if(setIsLoading !== undefined) setIsLoading(false)
        }
    }).catch((err) => {
        store.dispatch(update_all_designation_list({allDesignation:undefined}))
        console.error(err.response);
        switch (err.response.status) {
            case 404:
                setSnackMsg('No data available..')
              break;
            case 500:
                setSnackMsg('Server error!!')
              break;
            case 401:
                setSnackMsg('Unauthorized!!')
              break;
            case 403:
                setSnackMsg('Forbidden!!')
              break;
          
            default:
                setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
              break;
          }
          setErrorSnack(true)
        if(setIsLoading !== undefined) setIsLoading(false)
    })
}