import React, { useState } from "react";
import "../../../../css/Settings/CardType/cardAndUpiType.css";
import { CardManual } from "./CardManual";
import { CardAuto } from "./CardAuto";
import { UpiManual } from "./UpiManual";
import { ChequeManual } from "./ChequeManual";
export const CardType = () => {
  const [activeBtn, setActiveBtn] = useState("cardManualBtn");

  const clickCardManualBtn = () => {
    document.getElementById("cardManualBtn").classList.add("focus-device");
    document.getElementById("upiManualBtn").classList.remove("focus-device");
    document.getElementById("chequeManualBtn").classList.remove("focus-device");
    document.getElementById("autoBtn").classList.remove("focus-device");
    setActiveBtn("cardManualBtn");
  };

  const clickUpiManual = () => {
    document.getElementById("cardManualBtn").classList.remove("focus-device");
    document.getElementById("upiManualBtn").classList.add("focus-device");
    document.getElementById("autoBtn").classList.remove("focus-device");
    document.getElementById("chequeManualBtn").classList.remove("focus-device");
    setActiveBtn("upiManualBtn");
  };
  const clickCardAuto = () => {
    document.getElementById("cardManualBtn").classList.remove("focus-device");
    document.getElementById("upiManualBtn").classList.remove("focus-device");
    document.getElementById("chequeManualBtn").classList.remove("focus-device");
    document.getElementById("autoBtn").classList.add("focus-device");
    setActiveBtn("autoBtn");
  };

const ClickChequeManualBtn =()=>{
  document.getElementById("cardManualBtn").classList.remove("focus-device");
  document.getElementById("upiManualBtn").classList.remove("focus-device");
  document.getElementById("autoBtn").classList.remove("focus-device");
  document.getElementById("chequeManualBtn").classList.add("focus-device");
  setActiveBtn("chequeManualBtn")

}



  return (
    <div className="card-type-container">
      <div className="card-type-left-menu-container">
        <div
          className="card-type-left-menu-button focus-device "
          id="cardManualBtn"
          onClick={clickCardManualBtn}
        >
          <ul>
            <li>Card Manual</li>
          </ul>
        </div>
        <div
          className="card-type-left-menu-button"
          id="upiManualBtn"
          onClick={clickUpiManual}
        >
          <ul>
            <li>UPI Manual</li>
          </ul>
        </div>

        {/* cheque */}

        <div
          className="card-type-left-menu-button Manual"
          id="chequeManualBtn"
          onClick={ClickChequeManualBtn}
        >
          <ul>
            <li>Cheque</li>
          </ul>
        </div>



        <div
          className="card-type-left-menu-button card-type-menu-disabled"
          id="autoBtn"
          // onClick={clickCardAuto}
          disabled
        >
          <ul>
            <li>Card Auto</li>
          </ul>
        </div>
      </div>
      <div className="card-type-right-menu-container">
        {activeBtn === "cardManualBtn" ? (
          <CardManual />
        ) : activeBtn === "upiManualBtn" ? (
          <UpiManual />
        ) : activeBtn === "autoBtn" ? (
          <CardAuto />
        ) : activeBtn === "chequeManualBtn" ?(
          <ChequeManual/>
        ) :undefined}
      </div>
    </div>
  );
};
