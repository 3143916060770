import React,{useEffect, useState} from "react";
import "../../../../css/Purchase Manager/stockoutProduct.css";
import { Dialog, Autocomplete, TextField, IconButton, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { createStockOutAPICall, getAllStockOutAPICall, viewAllStockOutProductsAPICall } from "../../../../API/Purchase Manager/StockOut Products/stockOutAPI";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import { viewAllBranchesAPICall } from "../../../../API/Settings/Company Settings/companySettingsAPI";
export const StockoutProducts = () => {
  const userRole = useSelector((state) => state.userRoleSlice.value);
  const [addStockOutDialog, setAddStockOutDialog] = useState(false);
  //product Category [2 for material, 0 for readyMade, 1 for accessory]
  const [productCategory, setProductCategory] = useState("2");
  const [isModify, setIsModify] = useState(false);
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const [singleProduct,setSingleProduct]=useState([])
  const [product,setProduct]=useState(null)
  const [branchName,setBranchName]=useState(null)
  const [showBranchvalidation,setShowBranchvalidation]=useState(false)
  const [showProductvalidation,setShowProductvalidation]=useState(false)
  const [showQuantityvalidation,setShowQuantityvalidation]=useState(false)
  const [showSizevalidation,setShowSizevalidation]=useState(false)
  // loading state
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!!");
  const [isLoading,setIsloading]=useState(false)
  const [searchKeyword,setSearchKeyword]=useState("")
  const [filteredSearchList,setFilteredSearchList]=useState([])
  const userLoginMeta=localStorage.getItem("login_meta")
  const userBranchId=JSON.parse(userLoginMeta)?.branchId

  // console.log(userBranchId);
   // add purchase button 
   const navigate=useNavigate();
   const addPurchaseBtnFn=()=>{
     navigate('/userdashboard/purchaseManager/addPurchaseOrder');
   }
   const addStockOutProductClick=()=>{
    setAddStockOutDialog(true)
    document.getElementById("addStockSubmitButton").setAttribute("disabled","disabled");
    document.getElementById("addStockSubmitButtonTwo").setAttribute("disabled","disabled");
   }
   const updateListener = () => {
    setIsModify(!isModify);
  };
  //list of stockOut products
  const listOfStockOutProducts = useSelector(
    (state) => state.stockOutListSlice.value
  );
  // list of Branches
  const listOfBranchesStockOut = useSelector(
    (state)=>state.allBranchesSlice.value
  );
  // list of stockout
  const allStockOutList=useSelector(
    (state)=>state.allStockOutSlice.value
   );
  //Clear States
  const clearStates = () => {
    setAddStockOutProductForm(addStockOutProductFormInitialState);
    setAddStockOutDialog(false);
    setProductCategory(2)
    setShowBranchvalidation(false)
    setShowProductvalidation(false)
    setShowQuantityvalidation(false)
    setShowSizevalidation(false)

  };
  //Close SnackBar
  const closeSuccessSnack = () => {
    setSuccessSnackOpen(false);
  };
  const closeErrorSnack = () => {
    setErrorSnackOpen(false);
  };
   
 
  //add stockout Data body
  const addStockOutProductFormInitialState ={
    branchId:"",
    quantity:"",
    productId:"",
    productName:"",
    productType:0,
    dimension:""
  };
  const [addStockOutProductForm, setAddStockOutProductForm] = useState(
    addStockOutProductFormInitialState
  );

  // add stock out payload body
  const newStockOutProductBody ={
    productType:parseInt(addStockOutProductForm.productType),
    quantity:parseInt(addStockOutProductForm.quantity),
    productId:addStockOutProductForm.productId,
    dimension:addStockOutProductForm.dimension===""?"no dimension":addStockOutProductForm.dimension,
    branchId:addStockOutProductForm.branchId
  };

  // console.log(newStockOutProductBody);
  const getAddStockOutBranchForm =() => (e,newValue) =>{
    const {value} =e.target;
    setShowBranchvalidation(false)
      setBranchName(newValue)
      setShowBranchvalidation(false);
      setAddStockOutProductForm({ ...addStockOutProductForm, quantity:"" });
      
      if(newValue===null){
        setShowBranchvalidation(true)
      }
  };
  const getAddStockOutProductItemForm =() => (e,newValue) =>{
    const {value} =e.target;
    setProduct(newValue)
    setAddStockOutProductForm({ ...addStockOutProductForm, productId: value });
    setShowProductvalidation(false)
    if(newValue===null){
      setShowProductvalidation(true)
    }
  };
  const getAddStockOutProductItemFormUser =() => (e,newValue) =>{
    const {value} =e.target;
    setProduct(newValue)
    setAddStockOutProductForm({ ...addStockOutProductForm, productId: value });
    setShowProductvalidation(false)
    if(newValue===null){
      setShowProductvalidation(true)
    }
  };


  const getAddStockOutSizeForm =() => (e,newValue) =>{
    setAddStockOutProductForm({ ...addStockOutProductForm, dimension: newValue?.dimension });
    setShowSizevalidation(false);
    if(newValue===null){
      setShowSizevalidation(true)
    }
  };
  const getAddStockOutquantityForm = () => (e) => {
    const { value } = e.target;
    setAddStockOutProductForm({ ...addStockOutProductForm, quantity: value });    
    setShowQuantityvalidation(false);
    if(value===""){
      setShowQuantityvalidation(true)
    }
  };
  const getAddStockOutquantityFormUser = () => (e) => {
    const { value } = e.target;
    setAddStockOutProductForm({ ...addStockOutProductForm, quantity: value });    
    setShowQuantityvalidation(false);
    setShowQuantityvalidation(false);
    if(value===""){
      setShowQuantityvalidation(true)
    }
  };

  const getProductCategory = (e) => {
    const {value} =e.target;
    setAddStockOutProductForm({...addStockOutProductForm, productType: value})
    setProductCategory(e.target.value);
   };
  // Add StockOut Products
  const clickAddNewStockProduct = () => {
      if(addStockOutProductForm.branchId===false || addStockOutProductForm.branchId==="noBranch"){
        setShowBranchvalidation(true)
      }
      else if(addStockOutProductForm.productId===""||addStockOutProductForm.productId===undefined){
        setShowProductvalidation(true)
      }
      else if(productCategory !=="2" && addStockOutProductForm.dimension==="" ||addStockOutProductForm.dimension===undefined){
        setShowSizevalidation(true)
      }
      else if(addStockOutProductForm.quantity===""){
        setShowQuantityvalidation(true)
      }
      else{
        createStockOutAPICall(
          newStockOutProductBody,
          setSuccessSnackOpen,
          setErrorSnackOpen,
          setSnackMsg,
          updateListener,
          clearStates
          )
        }
  };
  const clickAddNewStockProductUser = () => {

    if(addStockOutProductForm.productId===""||addStockOutProductForm.productId===undefined){
      setShowProductvalidation(true)
    }
    else if(productCategory !=="2" && addStockOutProductForm.dimension==="" ||addStockOutProductForm.dimension===undefined){
      setShowSizevalidation(true)
    }
    else if(addStockOutProductForm.quantity===""){
      setShowQuantityvalidation(true)
    }
    else{
      createStockOutAPICall(
        newStockOutProductBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        clearStates
        )
      }
  };
  const getSearchKeyword=(e)=>{
    setSearchKeyword(e.target.value)
  }
  const SearchFilter=(chars,listToFilter)=>{
    let searchedArray=[]
    let filteredArray=[]
    if(chars!==""){
      searchedArray=listToFilter.filter((obj)=>
        obj?.itemName?.toLowerCase()?.includes(chars?.toLowerCase())
      )
      filteredArray=searchedArray
    }
    else{
      filteredArray=listToFilter
    }
    return filteredArray
  }
  useEffect(()=>{
    if(allStockOutList!==undefined){
      let filteredArray=[]
      setFilteredSearchList(allStockOutList)
      filteredArray=SearchFilter(searchKeyword,allStockOutList)
      setFilteredSearchList(filteredArray)    
    }
    else{
      setFilteredSearchList([])
    }
  },[searchKeyword,allStockOutList])
  // extracting product id and productName
  useEffect(()=>{
    setAddStockOutProductForm({...addStockOutProductForm,
      productName:product?.productName,productId:product?._id})    
  },[product]
  
  )
  useEffect(()=>{
    setAddStockOutProductForm({...addStockOutProductForm,
      branchId:branchName?.storeCode !==undefined &&branchName.storeCode})    
  },[branchName])
  useEffect(()=>{
    if(userRole==="user"){
      setAddStockOutProductForm({...addStockOutProductForm,
        branchId:userBranchId})    
      }
  },[userBranchId])

  // call view all stockOut products
  useEffect(() => {
    getAllStockOutAPICall(setIsloading,setErrorSnackOpen,setSnackMsg);
  }, [isModify]);
  //Call StockOutProduct list API
  
  useEffect(() => {
    {userRole === 'admin'?
    viewAllStockOutProductsAPICall({ type: productCategory,branchId:branchName?.storeCode})
    :
    viewAllStockOutProductsAPICall({ type: productCategory,branchId:userBranchId})
    }
  },[productCategory,branchName,userBranchId]);

  useEffect(() => {
    viewAllBranchesAPICall();
  }, []);

  // filtering an obj to get dimension of single
  useEffect(()=>{
    let filteredData=[];
      filteredData=listOfStockOutProducts?.filter((obj)=>{
        if(obj.productName===addStockOutProductForm?.productName){
          return obj;
        }
      })
     setSingleProduct(filteredData)    
  },[addStockOutProductForm?.productName])  

  
  return (
    <div className="global-page-parent-container">
      <p className="breadcrumb">Purchase Manage &gt; Stock Out Products</p>
      <div className="stock-out-product-container">
        <div className="stock-out-product-top-container">
          <h3>Stock out Products</h3>
          <div className="stock-out-product-button-container">
            <div className="view-order-search-container view-order-admin-search-container">
                <input
                  placeholder="Search Product name"
                  value={searchKeyword}
                  onChange={getSearchKeyword}
                />
                <IconButton>
                    <i class="bi bi-search search-icon-order"></i>
                </IconButton>
            </div>
            <button onClick={addPurchaseBtnFn} className="btn btn-primary add-purchase-button">
              Add Purchase
            </button>
            <button onClick={addStockOutProductClick} className="add-stock-out-button">
              Add Stock Out Products
            </button>
          </div>
        </div>
        <div className="global-table">
          <table className="global-table">
            <thead>
              <tr>
                <th>Products</th>
                <th>Quantity</th>
                <th>Size</th>
                <th>Status</th>
              </tr>
            </thead>
            {
                isLoading?
                <tbody>
                  <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr> 
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr> 
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr> 
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
            </tbody>:
            <tbody>
              {filteredSearchList.length !== 0 ? (
                filteredSearchList
                ?.slice(0)
                ?.reverse()
                ?.map((r,i) => (
                  <tr className="stock-out-tr" key={i}>
                    <td>{r?.itemName}</td>
                    <td>{r?.quantity}</td>
                    <td>{r?.dimension==="no dimension"?"-NIL-":r?.dimension}</td>
                    <td>
                      {r?.status==="Accepted"?(
                        <p className="status-text-stock-out-pending">Accepted</p>)
                        :r?.status==="Rejected"?(
                          <p className="status-text-stock-out-reject">Rejected</p>)
                        :(
                        <p className="status-text-stock-out">Pending</p>)
                      }
                    </td>
                </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>No Stock Out Products</td>
                </tr>
              )}
              
            </tbody>
            }
          </table>
        </div>
      </div>
                
      {/* add stock out product dialog */}

      <Dialog open={addStockOutDialog} maxWidth="lg"  onKeyDown={(e)=>e.key === "Escape" && setAddStockOutDialog(false)}>
        <div className="add-stock-out-dialog">
          <h3>Add Stock Out Product</h3>
          <hr className="global-hr"/>
          {
            userRole === 'admin'?
            <div className="add-stock-out-dialog-branch-select global-single-input add-purchase-input add-po-input-item auto-complete" style={{width:'35%'}}>
              <Autocomplete
              sx={{width:"100%"}}
              options={listOfBranchesStockOut || [{branchName:"No Branches Available"}]}
              getOptionLabel={(option) => option?.branchName}  
              renderInput={(params) => <TextField {...params}
              placeholder="Select Branch"
             />}
             onChange={getAddStockOutBranchForm()}
              
              />
              {showBranchvalidation?<p className="validate-stock-out-products">Select a Branch !!</p>:""}
            </div>
            :""
          }

          <div className="radio-groups-container" style={{display:'flex',margin:'3% 0'}}>
            <div className="global-radio-button radio-group">
              <input
                type="radio"
                name="mainCategoryRadio"
                id="materialCategory"
                value={2}
                defaultChecked
                onChange={getProductCategory}
              />
              <label htmlFor="materialCategory">Material</label>
            </div>
            <div className="global-radio-button radio-group">
              <input
                type="radio"
                name="mainCategoryRadio"
                id="readymadeCategory"
                value={0}
                onChange={getProductCategory}
              />
              <label htmlFor="readymadeCategory">Ready Made</label>
            </div>

            <div className="global-radio-button radio-group">
              <input
                type="radio"
                name="mainCategoryRadio"
                id="accessoryCategory"
                value={1}
                onChange={getProductCategory}
              />
              <label htmlFor="accessoryCategory">Accessories</label>
            </div>
          </div>

          
          <div className="add-stock-out-input-container">
          
          <div className="global-single-input add-stock-out add-purchase-input add-po-input-item auto-complete">
             
             <p>Item Name</p>
             { userRole==='admin'?
             <Autocomplete
             sx={{width:"100%"}}
             options={listOfStockOutProducts || [{productName:"No Items Available"}]}
             getOptionLabel={(option) => option?.productName}  
             renderInput={(params) => <TextField {...params}
             placeholder="Select Product"
            />}
             onChange={getAddStockOutProductItemForm()}
             />:
             <Autocomplete
              sx={{width:"100%"}}
              options={listOfStockOutProducts || [{productName:"No Items Available"}]}
              getOptionLabel={(option) => option?.productName}  
              renderInput={(params) => <TextField {...params}
              placeholder="Select Product"
             />}
              onChange={getAddStockOutProductItemFormUser()}
              />
             }
              
              {showProductvalidation?<p className="validate-stock-out-products">Select a Item !!</p>:""}
          </div>
          {productCategory !="2" &&(
            <div className="global-single-input add-stock-out add-purchase-input sm-input add-po-input-item auto-complete">
              <p>Size</p>
              <Autocomplete
              sx={{width:"100%"}}
              options={singleProduct !== undefined && singleProduct[0]?.stock||[{dimension:"No Data"}]}
              getOptionLabel={(option) => option?.dimension}
              renderInput={(params) => <TextField {...params}
                            placeholder="Select Size"
                          />}
              onChange={getAddStockOutSizeForm()}
              /> 
               {showSizevalidation?<p className="validate-stock-out-products">Select a Size !!</p>:""}
            </div>
          )}
            <div className="global-single-input add-stock-out">
             <p>Quantity</p>
             {userRole === 'admin'?<input 
             type="number"
             value={addStockOutProductForm?.quantity}
             onChange={getAddStockOutquantityForm("qty")}
             min="0"
             />:<input 
             type="number"
             value={addStockOutProductForm?.quantity}
             onChange={getAddStockOutquantityFormUser("qty")}
             min="0"
             />}
             
              {showQuantityvalidation?<p className="validate-stock-out-products">Enter Quantity !!</p>:""}
          </div>

          </div>
          <div className="stock-out-button-container">
          <button onClick={clearStates} className="btn btn-secondary-outlined">Cancel</button>
          {userRole ==='admin' ?
          <button id="addStockSubmitButton" className="btn btn-primary" onClick={clickAddNewStockProduct}>Submit</button>:
          <button id="addStockSubmitButtonTwo" className="btn btn-primary" onClick={clickAddNewStockProductUser}>Submit</button>
          }
          
          </div>
         
        </div>
      </Dialog>
      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnack}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnack}
        message={snackMsg}
      />
    </div>
  );
};
