import React, { useRef, useState } from "react";
import InputBox from "../../../components/InputBox";
import ReactToPrint from "react-to-print";
import { IconButton, Skeleton } from "@mui/material";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown"; 

const CashOrBankSummary = ({ renderData, selectListItem }) => {
  const componentRef = useRef();
  const [visibleRows, setVisibleRows] = useState([]);

  const toggleVisibility = (index) => {
    if (visibleRows.includes(index)) {
      setVisibleRows(visibleRows.filter((rowIndex) => rowIndex !== index));
    } else {
      setVisibleRows([...visibleRows, index]);
    }
  };

  const isLoading = false;
  return (
    <div id="currentAsset-report">
      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="accounting-voucher-menu-sections"
      >
         <h3 style={{ margin: "2% 1%" }}>{selectListItem}</h3>
        <div className="input-current-asset-reports">
          <InputBox label={"From"} type={"date"} />
          <InputBox label={"To"} type={"date"} />
        </div>
      </div>
      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="accounting-voucher-menu-sections"
      >
        <div className="action-btn-container">
          <div>
            <ReactToPrint
              trigger={() => (
                <IconButton sx={{ color: "blue" }}>
                  <i className="bi bi-printer "></i>
                </IconButton>
              )}
              content={() => componentRef.current}
            />
            <IconButton className="download-btn">
              <ArrowCircleDownIcon sx={{ fontSize: 30 }} />{" "}
            </IconButton>
          </div>
        </div>
        <div className="table-container">
          <div className="global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>Code/No</th>
                  <th>Particular</th>
                  <th>Opening</th>
                  <th>Debit</th>
                  <th>Credit</th>
                  <th>Closing</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  [...Array(3)].map((_, index) => (
                    <tr key={index}>
                      <td colSpan={7}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                  ))
                ) : renderData.length !== 0 ? (
                  renderData?.reverse()?.map((r, i) => (
                    <React.Fragment key={i}>
                      <tr className="data-table-row-head">
                        <td>{r?.code}</td>
                        <td>{r?.particular}</td>
                        <td>{r?.opening}</td>
                        <td>{r?.debit}</td>
                        <td>{r?.credit}</td>
                        <td>{r?.closing}</td>
                        <td>
                          <IconButton onClick={() => toggleVisibility(i)}>
                            <i className="bi bi-eye visibility-icon"></i>
                          </IconButton>
                        </td>
                      </tr>
                      {r.tableData &&
                        r.tableData.map((d, j) => (
                          <React.Fragment key={j}>
                            {visibleRows.includes(i) && (
                              <tr
                                className={
                                  j === r.tableData.length - 1
                                    ? "bottom-line data-table-row"
                                    : "data-table-row"
                                }
                              >
                                <td>{d?.code}</td>
                                <td>{d?.particular}</td>
                                <td>{d?.opening}</td>
                                <td>{d?.debit}</td>
                                <td>{d?.credit}</td>
                                <td>{d?.closing}</td>
                                <td>
                                  <IconButton>
                                    <i className="bi bi-eye visibility-icon"></i>
                                  </IconButton>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))}
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>No Records</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
     
      

      {/* print preview   */}
      <div style={{ display: "none" }}>
        <div ref={componentRef} id="print-view">
          <div className="print-head-container">
            <div className="brand-name">
              <h2>Leeyet Textile</h2>
            </div>
            <div className="select-table-head">
              <h3>{selectListItem}</h3>
            </div>
            <div className="duration-date">
              <h3>{"01/04/2023"}</h3>
              <strong>TO</strong>
              <h3>{"01/12/2023"}</h3>
            </div>
          </div>
          <div className="print-table-container">
            <div className="accounting-voucher-menu-sections">
              <div className="global-table-container">
                <table className="global-table">
                  <thead>
                    <tr style={{ borderBottom: "1px solid black" }}>
                      <th>Code/No</th>
                      <th>Particular</th>
                      <th>Opening</th>
                      <th>Debit</th>
                      <th>Credit</th>
                      <th>Closing</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderData?.map((r, i) => (
                      <React.Fragment key={i}>
                        <tr className="data-table-row-head">
                          <td>{r?.code}</td>
                          <td>{r?.particular}</td>
                          <td>{r?.opening}</td>
                          <td>{r?.debit}</td>
                          <td>{r?.credit}</td>
                          <td>{r?.closing}</td>
                        </tr>
                        {r.tableData &&
                          r.tableData.map((d, j) => (
                            <React.Fragment key={j}>
                              {visibleRows.includes(i) && (
                                <tr
                                  id="dropdown-table"
                                  className={
                                    j === r.tableData.length - 1
                                      ? "bottom-line data-table-row"
                                      : "data-table-row"
                                  }
                                >
                                  <td>{d?.code}</td>
                                  <td>{d?.particular}</td>
                                  <td>{d?.opening}</td>
                                  <td>{d?.debit}</td>
                                  <td>{d?.credit}</td>
                                  <td>{d?.closing}</td>
                                </tr>
                              )}
                            </React.Fragment>
                          ))}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashOrBankSummary;
