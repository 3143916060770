import { createSlice } from "@reduxjs/toolkit";

export const heldWorkOrderSlice=createSlice({
    name:'heldWorkOrderSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_heldWrokOrder_list:(state,action)=>{
            state.value=action.payload.data
        }
    }
})

export const {update_heldWrokOrder_list}=heldWorkOrderSlice.actions
export default heldWorkOrderSlice.reducer