import { createSlice } from "@reduxjs/toolkit";

export const deliveryPaymentDetailsSlice=createSlice({
    name:'deliveryPaymentDetailsSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_delivery_payment_details:(state,action)=>{
            state.value=action.payload.deliveryPaymentDetails
        }
    }
})
export const {update_delivery_payment_details} = deliveryPaymentDetailsSlice.actions
export default deliveryPaymentDetailsSlice.reducer