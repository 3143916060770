import { createSlice } from "@reduxjs/toolkit";
const chequeManualSlice = createSlice({
    name:"chequeManualSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        get_Cheque_manual:(state,action)=>{
            state.value = action.payload.chequeManualData

        }
    }
})

export const {get_Cheque_manual}= chequeManualSlice.actions
export default chequeManualSlice.reducer;
