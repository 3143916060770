
import axios from "axios"
import Store from '../../../Redux/store'
import {HEADERS} from '../../../API/UrlAndPaths'
import { get_log_data } from "../../../Redux/Settings/Log/LogSlice"

export const logAPICall= async (body,setIsLoading)=>{
   setIsLoading(true)
   await axios.post("settings/getlog",body,HEADERS)
   .then((res)=>{
    if(res.status===200){
        Store.dispatch(get_log_data({logData:res.data}))
        setIsLoading(false)
    }
   })
   .catch((err)=>{
    Store.dispatch(get_log_data({logData:undefined}))
    console.log(err.response);
    setIsLoading(false)
   })
}