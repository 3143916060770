import React from "react";
import "../../../../css/Staff/Attendance/staffAttendance.css"
import totalStaff from '../../../../Assets/SVG/staffDashboardIcons/TOTAL STAFF.svg'
import { Datepicker } from "../../../Single Components/Datepicker";
import { month } from "../../../../Js/Date";
import { Dialog, IconButton, Skeleton } from "@mui/material";
import { useState } from "react";
export const StaffAttendance =()=>{
    
    const [employeeAttendanceView,setEmployeeAttendanceView]=useState(false);
    // loading states
    const [isLoading,setIsLoading]=useState(false)
    const ClickAttendanceView=()=>{
        setEmployeeAttendanceView(true)
    }
    const [currentMonth,setCurrentMonth]=useState(month)
    const getMonth=(e)=>{
        setCurrentMonth(e.target.value)
    }
    return(
        <>
        <div className="global-page-parent-container">
             <p className="breadcrumb">Staff &gt; Attendance</p>
             <div className="staff-attendance-container">
                <div className="staff-attendance-container-top">
                    <div className="staff-attendance-card-container">
                        <div className='staff-attendance-card'>
                                <div className='staff-attendance-card-text-container'>
                                    <h4>Total Staff</h4>
                                    <p>500</p>
                                </div>
                                <div className='staff-attendance-card-icon-container'>
                                    <img src={totalStaff} alt="total salary" />
                                </div>
                            
                        </div>
                    </div>
                    <div className="global-single-input">
                        <p>Select Month</p>
                        <Datepicker type="month" value={currentMonth} onChange={getMonth}  backgroundColor="#3366ff" color="#fff" classNameGiven="staff-date-picker"  borderRadius='5px'/>
                        {/* <input type="month" value={currentMonth} onChange={(e)=>setCurrentMonth(e.target.value)}/> */}
                    </div>
                </div>

                <div className="global-table-container">
                    <table className="global-table">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Employee ID</th>
                                <th>Name</th>
                                <th>In</th>
                                <th>Out</th>
                                <th>Total Work Hours</th>
                                <th>Break In</th>
                                <th>Break Out</th>
                                <th>Total Break Hours</th>
                                <th>Date</th>
                                <th>Leave</th>
                                <th>View</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        {
                        isLoading?
                        <tbody>
                           <tr>
                           <td colSpan={13}>
                              {/* <Loader /> */}
                              <Skeleton
                                 variant="rectangular"
                                 animation="wave"
                                 width={"100%"}
                                 height={40}
                              />
                           </td>
                           </tr>
                           <tr>
                           <td colSpan={13}>
                              {/* <Loader /> */}
                              <Skeleton
                                 variant="rectangular"
                                 animation="wave"
                                 width={"100%"}
                                 height={40}
                              />
                           </td>
                           </tr>
                           <tr>
                           <td colSpan={13}>
                              {/* <Loader /> */}
                              <Skeleton
                                 variant="rectangular"
                                 animation="wave"
                                 width={"100%"}
                                 height={40}
                              />
                           </td>
                           </tr>
                           <tr>
                           <td colSpan={13}>
                              {/* <Loader /> */}
                              <Skeleton
                                 variant="rectangular"
                                 animation="wave"
                                 width={"100%"}
                                 height={40}
                              />
                           </td>
                           </tr>
                           <tr>
                           <td colSpan={13}>
                              {/* <Loader /> */}
                              <Skeleton
                                 variant="rectangular"
                                 animation="wave"
                                 width={"100%"}
                                 height={40}
                              />
                           </td>
                           </tr> 
                           <tr>
                           <td colSpan={13}>
                              {/* <Loader /> */}
                              <Skeleton
                                 variant="rectangular"
                                 animation="wave"
                                 width={"100%"}
                                 height={40}
                              />
                           </td>
                           </tr> 
                           <tr>
                           <td colSpan={13}>
                              {/* <Loader /> */}
                              <Skeleton
                                 variant="rectangular"
                                 animation="wave"
                                 width={"100%"}
                                 height={40}
                              />
                           </td>
                           </tr> 
                           <tr>
                           <td colSpan={13}>
                              {/* <Loader /> */}
                              <Skeleton
                                 variant="rectangular"
                                 animation="wave"
                                 width={"100%"}
                                 height={40}
                              />
                           </td>
                           </tr> 
                        </tbody>:
                        <tbody>
                                <tr>
                                    <td>01</td>
                                    <td>EMP-W2200</td>
                                    <td>Niyhad</td>
                                    <td>9:00 AM</td>
                                    <td>6:00 PM</td>
                                    <td>9 hours</td>
                                    <td>12:00 PM</td>
                                    <td>3:00 PM</td>
                                    <td>4 Hours</td>
                                    <td>09-12-2022</td>
                                    <td>20</td>
                                    <td><IconButton onClick={ClickAttendanceView}>
                                        <i class="bi bi-eye visibility-icon"></i>
                                    </IconButton></td>
                                    <td><button className="btn btn-green-outlined">Active</button></td>
                                </tr>
                                <tr>
                                    <td>01</td>
                                    <td>EMP-W2200</td>
                                    <td>Niyhad</td>
                                    <td>9:00 AM</td>
                                    <td>6:00 PM</td>
                                    <td>9 hours</td>
                                    <td>12:00 PM</td>
                                    <td>3:00 PM</td>
                                    <td>4 Hours</td>
                                    <td>09-12-2022</td>
                                    <td>20</td>
                                    <td><IconButton onClick={ClickAttendanceView}>
                                        <i class="bi bi-eye visibility-icon"></i>
                                    </IconButton></td>
                                    <td><button className="btn btn-green-outlined">Active</button></td>
                                </tr>
                                <tr>
                                    <td>01</td>
                                    <td>EMP-W2200</td>
                                    <td>Niyhad</td>
                                    <td>9:00 AM</td>
                                    <td>6:00 PM</td>
                                    <td>9 hours</td>
                                    <td>12:00 PM</td>
                                    <td>3:00 PM</td>
                                    <td>4 Hours</td>
                                    <td>09-12-2022</td>
                                    <td>20</td>
                                    <td><IconButton onClick={ClickAttendanceView}>
                                        <i class="bi bi-eye visibility-icon"></i>
                                    </IconButton></td>
                                    <td><button className="btn btn-green-outlined">Active</button></td>
                                </tr>
                                <tr>
                                    <td>01</td>
                                    <td>EMP-W2200</td>
                                    <td>Niyhad</td>
                                    <td>9:00 AM</td>
                                    <td>6:00 PM</td>
                                    <td>9 hours</td>
                                    <td>12:00 PM</td>
                                    <td>3:00 PM</td>
                                    <td>4 Hours</td>
                                    <td>09-12-2022</td>
                                    <td>20</td>
                                    <td><IconButton onClick={ClickAttendanceView}>
                                        <i class="bi bi-eye visibility-icon"></i>
                                    </IconButton></td>
                                    <td><button className="btn btn-green-outlined">Active</button></td>
                                </tr>
                                <tr>
                                    <td>01</td>
                                    <td>EMP-W2200</td>
                                    <td>Niyhad</td>
                                    <td>9:00 AM</td>
                                    <td>6:00 PM</td>
                                    <td>9 hours</td>
                                    <td>12:00 PM</td>
                                    <td>3:00 PM</td>
                                    <td>4 Hours</td>
                                    <td>09-12-2022</td>
                                    <td>20</td>
                                    <td><IconButton onClick={ClickAttendanceView}>
                                        <i class="bi bi-eye visibility-icon"></i>
                                    </IconButton></td>
                                    <td><button className="btn btn-green-outlined">Active</button></td>
                                </tr>
                                <tr>
                                    <td>01</td>
                                    <td>EMP-W2200</td>
                                    <td>Niyhad</td>
                                    <td>9:00 AM</td>
                                    <td>6:00 PM</td>
                                    <td>9 hours</td>
                                    <td>12:00 PM</td>
                                    <td>3:00 PM</td>
                                    <td>4 Hours</td>
                                    <td>09-12-2022</td>
                                    <td>20</td>
                                    <td><IconButton onClick={ClickAttendanceView}>
                                        <i class="bi bi-eye visibility-icon"></i>
                                    </IconButton></td>
                                    <td><button className="btn btn-green-outlined">Active</button></td>
                                </tr>
                            </tbody>
                        }
                    </table>
                </div>
             </div>


             <Dialog open={employeeAttendanceView} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setEmployeeAttendanceView(false)}>
                <div className="employee-attendance-view-container">
                    <div className="employee-attendance-view-container-top">
                        
                        <div className="staff-attendance-container-top-left">
                            <h3>Employee Attendance View</h3>
                        </div>        

                        <div className="global-single-input">
                            <p>Select Month</p>
                            <Datepicker type="month" value={month} backgroundColor="#3366ff" color="#fff" classNameGiven="staff-date-picker"  borderRadius='5px'/>
                        </div>
                    </div>  

                    <div className="employee-attendance-view-container-body">
                        <div className="employee-attendance-view-container-body-section">
                            <p>Employee Name:Deepak</p>
                            <p>Employee ID:GSFA54654F</p>
                        </div>
                        <div className="employee-attendance-view-container-body-section">
                            <p>Total Leave : 05</p>    
                        </div>
                    </div> 

                    <div className="global-table-container">
                        <table className="global-table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>In</th>
                                    <th>Out</th>
                                    <th>Total Work Hours</th>
                                    <th>Break In</th>
                                    <th>Break Out</th>
                                    <th>Total Break hours</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>10-12-2022</td>
                                    <td>9:00 AM</td>
                                    <td>6:00 PM</td>
                                    <td>09 Hour</td>
                                    <td>12:00 PM</td>
                                    <td>2:00 PM</td>
                                    <td>2 Hours</td>
                                </tr>
                                <tr>
                                    <td>10-12-2022</td>
                                    <td>9:00 AM</td>
                                    <td>6:00 PM</td>
                                    <td>09 Hour</td>
                                    <td>12:00 PM</td>
                                    <td>2:00 PM</td>
                                    <td>2 Hours</td>
                                </tr>
                                <tr>
                                    <td>10-12-2022</td>
                                    <td>9:00 AM</td>
                                    <td>6:00 PM</td>
                                    <td>09 Hour</td>
                                    <td>12:00 PM</td>
                                    <td>2:00 PM</td>
                                    <td>2 Hours</td>
                                </tr>
                                <tr>
                                    <td>10-12-2022</td>
                                    <td>9:00 AM</td>
                                    <td>6:00 PM</td>
                                    <td>09 Hour</td>
                                    <td>12:00 PM</td>
                                    <td>2:00 PM</td>
                                    <td>2 Hours</td>
                                </tr>
                                <tr>
                                    <td>10-12-2022</td>
                                    <td>9:00 AM</td>
                                    <td>6:00 PM</td>
                                    <td>09 Hour</td>
                                    <td>12:00 PM</td>
                                    <td>2:00 PM</td>
                                    <td>2 Hours</td>
                                </tr>
                                <tr>
                                    <td>10-12-2022</td>
                                    <td>9:00 AM</td>
                                    <td>6:00 PM</td>
                                    <td>09 Hour</td>
                                    <td>12:00 PM</td>
                                    <td>2:00 PM</td>
                                    <td>2 Hours</td>
                                </tr>
                                <tr>
                                    <td>10-12-2022</td>
                                    <td>9:00 AM</td>
                                    <td>6:00 PM</td>
                                    <td>09 Hour</td>
                                    <td>12:00 PM</td>
                                    <td>2:00 PM</td>
                                    <td>2 Hours</td>
                                </tr>
                                <tr>
                                    <td>10-12-2022</td>
                                    <td>9:00 AM</td>
                                    <td>6:00 PM</td>
                                    <td>09 Hour</td>
                                    <td>12:00 PM</td>
                                    <td>2:00 PM</td>
                                    <td>2 Hours</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="btn-attendance-view-container">
                        <button className="btn btn-red-outlined" onClick={()=>{setEmployeeAttendanceView(false)}}>Back</button>
                        <button className="btn btn-secondary">Download</button>
                    </div>
                        
                </div>    
                
            </Dialog>   

        </div>
        </>
    )
}