import { createSlice } from "@reduxjs/toolkit";

export const allAccessoryCategorySlice=createSlice({
    name:'allAccessoryCategorySlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_accessory_category_list:(state,action)=>{
            state.value=action.payload.accessoryCategory
        }
    }
})

export const {update_accessory_category_list}=allAccessoryCategorySlice.actions
export default allAccessoryCategorySlice.reducer