import React, { useState } from "react";
import "../../../../../css/Purchase Manager/StockAdjustment/addStockAdjustment.css";
import { Select, IconButton, MenuItem, Autocomplete, TextField } from "@mui/material";
import { useEffect } from "react";
import { viewAllProductListWithBranchAPICall, viewAllProductsAPICall } from "../../../../../API/Product List/productListAPI";
import { useSelector } from "react-redux";
import { calculateTotalCost, extractObjectForSingleView } from "../../../../../Js/generalFunctions";
import {
  draftStockAdjustmentAPICall,
  finalPostDraftedStockAdjustmentAPICall,
  finalPostStockAdjustmentAPICall,
  generateStockAdjIdAPICall,
  getGlCodeAPICall,
  getGlLinkCodeAPICall,
  getSlCodeAPICall,
  getStockOfToLocation,
  viewSingleDraftedStockAdjustmentAPICall,
} from "../../../../../API/Purchase Manager/stockAdjustmentAPI";
import { getCurrentTime, today } from "../../../../../Js/Date";
import { viewAllBranchesAPICall } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import SuccessSnackbar from "../../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
import store from "../../../../../Redux/store";
import { update_singleDraft } from "../../../../../Redux/Prchase Manager/StockAdjustment/singleDraftViewSlice";
export const AddStockAdjustment = () => {
  const draftId = localStorage.getItem("draft");
  const userLoginMeta=localStorage.getItem("login_meta")
  const userBranchId=JSON.parse(userLoginMeta)?.branchPk
  //*Redux States
  const userRole=useSelector(
    (state) => state.userRoleSlice.value);
  // login response
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);

  //List of all products
  const allProductsList = useSelector(
    (state) => state.allProductListWithBranch.value
  );
  //Generated Stock adjustment ID
  const stockAdjID = useSelector((state) => state.stockAdjIdSlice.value);
  //List of all Branches
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  //List of all Branches
  const glLinkCodes = useSelector((state) => state.glLinkCodeSlice.value);
  //List of all Branches
  const glCodes = useSelector((state) => state.glCodeSlice.value);
  //List of all Branches
  const slCodes = useSelector((state) => state.slCodeSlice.value);
  //Drafted Single Stock Adjustment
  const singleDraftedStockAdj = useSelector(
    (state) => state.singleDraftSlice.value
  );
  //Stock in to branch
  const toBranchStock = useSelector(
    (state) => state.toLocationStockSlice.value
  );

  //same location checkbox checked or not
  const [locationChecked, setLocationChecked] = useState(false);
  //Negative Checked or not
  const [isNegativeStockChecked, setIsNegativeStockChecked] = useState(false);

  const [branchFiltered,setBranchFiltered]=useState([])
  const [toBranchFiltered,setToBranchFiltered]=useState([])

  //Determine is draft edit or new stock adjustment
  const [isDraft,setIsDraft]=useState(false)



  // stock adjustment form one
  const stockAdjFormOneInitialState = {
    transId: "##",
    fromLoc: "0",
    date: today,
    remarks: "",
    glLinkCode: "0",
    glLinkDesc: "",
    glCode: "0",
    glCodeDesc: "",
    slCode: "0",
    slCodeDesc: "",
    toLoc: "0",
    toLocDesc: "",
  };
  const [stockAdjFormOne, setStockAdjFormOne] = useState(
    stockAdjFormOneInitialState
  );

  //Stock Adjustment Form State to get data from front end
  const stockAdjFormTwoInitialState = {
    productId: "0",
    stock: "",
    size: "0",
    adjQty: "",
    unit: "",
    unitCost: "",
    totCost: "",
  };
  const [stockAdjFormTwo, setStockAdjFormTwo] = useState(
    stockAdjFormTwoInitialState
  );
  const [prodObjChosen,setProdObjChosen]=useState(null)
  //Selected Products array for front end purpose
  const [selectedProductsFront, setSelectedProductsFront] = useState([]);
  //Selected Products array for back end purpose
  const [selectedProductsBack, setSelectedProductsBack] = useState([]);

  //Update Listener States
  const [isModify, setIsModify] = useState(false);

  //Snackbar States
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false);
  const [openErrorSnack, setOpenErrorSnack] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!");

  //Product Type States
  const [productType, setProductType] = useState(2);
  //selected Product Obj
  const [selectedProductObj, setSelectedProductObj] = useState([]);
  //Is Loading
  const [isLoading,setIsLoading]=useState(false)

   //To get current time
   const [time, setTime] = useState(null);
  
   //validation states
   const [branchAlert,setbranchAlert]=useState(false)
   const [toLocAlert,setToLocAlert]=useState(false)
   const [productAlert,setProductAlert]=useState(false)
   const [productSizeAlert,setProductSizeAlert]=useState(false)
   const [adjQtyAlert,setAdjQtyAlert]=useState(false)
   const [unitCostAlert,setUnitCostAlert]=useState(false)
  
   // get current time fn
   useEffect(() => {
     setInterval(() => {
      setTime(getCurrentTime())
      //  setTime(`${String(new Date().getHours()).padStart(2,"0")}:${String(new Date().getMinutes()).padStart(2,"0")}:${String(new Date().getSeconds()).padStart(2,"0")}`);
     }, 1000);
   }, [time]);
  
  

  //*onChange Fns
  //Check Same location checkbox checked or not
  const sameLocationChange = (e) => {
    setLocationChecked(e.target.checked);
  };

  // check negative Stock checkbox checked or not
  const getNegativeStockChecked = (e) => {
    setIsNegativeStockChecked(e.target.checked);
  };

  //get Product type radio value
  const getProductTypeRadioValue = (e) => {    
    const { value, checked } = e.target;
    if (checked) {
      setProductType(value);
    }
  };

  //Get StockAdj Form 1 Data
  const getStockAdjFormOneData = (key) => (e) => {
    const { value } = e.target;

    if (key === "fromLoc") {
      setStockAdjFormOne({ ...stockAdjFormOne, fromLoc: value });
      setbranchAlert(false)
    }
    if (key === "date") {
      setStockAdjFormOne({ ...stockAdjFormOne, date: value });
    }
    if (key === "remarks") {
      setStockAdjFormOne({ ...stockAdjFormOne, remarks: value });
    }
    if (key === "glLinkCode") {
      setStockAdjFormOne({ ...stockAdjFormOne, glLinkCode: value });
    }
    if (key === "glLinkDesc") {
      setStockAdjFormOne({ ...stockAdjFormOne, glLinkDesc: value });
    }
    if (key === "glCode") {
      setStockAdjFormOne({ ...stockAdjFormOne, glCode: value });
    }
    if (key === "glCodeDesc") {
      setStockAdjFormOne({ ...stockAdjFormOne, glCodeDesc: value });
    }
    if (key === "slCode") {
      setStockAdjFormOne({ ...stockAdjFormOne, slCode: value });
    }
    if (key === "slCodeDesc") {
      setStockAdjFormOne({ ...stockAdjFormOne, slCodeDesc: value });
    }
    if (key === "toLoc") {
      setStockAdjFormOne({ ...stockAdjFormOne, toLoc: value });
      setToLocAlert(false)
      if(value==="0"){
        setToLocAlert(true)
      }
    }
    if (key === "toLocDesc") {
      setStockAdjFormOne({ ...stockAdjFormOne, toLocDesc: value });
    }
  };

  //Get StockAdj Form 2 Data
  const getStockAdjFormTwoData = (key) => (e) => {
    const { value } = e.target;

    if (key === "productId") {
      setStockAdjFormTwo({ ...stockAdjFormTwo, productId: value });
    }
    if (key === "stock") {
      setStockAdjFormTwo({ ...stockAdjFormTwo, stock: value });
    }
    if (key === "size") {
      setStockAdjFormTwo({ ...stockAdjFormTwo, size: value });
      setProductSizeAlert(false)
    }
    if (key === "adjQty") {
      setStockAdjFormTwo({ ...stockAdjFormTwo, adjQty: value });
      setAdjQtyAlert(false)
      if(value===""){
        setAdjQtyAlert(true)
      }
    }
    if (key === "unit") {
      setStockAdjFormTwo({ ...stockAdjFormTwo, unit: value });
    }
    if (key === "unitCost") {
      setStockAdjFormTwo({ ...stockAdjFormTwo, unitCost: value });
      setUnitCostAlert(false)
      if(value===""){
        setUnitCostAlert(true)
      }
    }
    if (key === "totCost") {
      setStockAdjFormTwo({ ...stockAdjFormTwo, totCost: value });
    }
  };

  //Update listener fn
  const updateListener = () => {
    setIsModify(!isModify);
  };

  //close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setOpenSuccessSnack(false);
  };
  //close ErrorSnackbar
  const closeErrorSnackbar = () => {
    setOpenErrorSnack(false);
  };

  //*useEffects

  useEffect(()=>{
    allBranchesList!==undefined && setBranchFiltered(allBranchesList)
    allBranchesList!==undefined && setToBranchFiltered(allBranchesList)
  },[allBranchesList])

  useEffect(() => {
    if (draftId !== null && draftId !== undefined) {
      viewSingleDraftedStockAdjustmentAPICall({ _id: draftId });
    }
    viewAllBranchesAPICall();
    generateStockAdjIdAPICall();
    getGlLinkCodeAPICall();
    getGlCodeAPICall();
    getSlCodeAPICall();
  }, [isModify]);

  //Assign selected product id to state
  useEffect(()=>{ 
    if(prodObjChosen!==null)
    {
      setStockAdjFormTwo({...stockAdjFormTwo,productId:prodObjChosen?._id,unit:prodObjChosen?.unitName||'-NIL-'})
    }
  },[prodObjChosen?._id])

  //Set Drafted Data to states if it present
  useEffect(() => {
    if (singleDraftedStockAdj !== undefined) {
      const {
        transNo,
        fromLoc,
        date,
        remarks,
        isSameLoc,
        isNegative,
        glLinkCode,
        glCode,
        toLoc,
        toBranchName,
        purchaseInfo
      } = singleDraftedStockAdj;

      setIsDraft(true)
      setStockAdjFormOne({
        transId: transNo,
        fromLoc,
        date,
        remarks,
        glLinkCode: glLinkCode?.code,
        glLinkDesc: glLinkCode?.description,
        glCode: glCode?.code,
        glCodeDesc: glCode?.description,
        toLoc,
        toLocDesc:toBranchName
      });


      let prodArrayFront= purchaseInfo?.map((obj,i)=>{
        return { prodName: obj?.itemName,
                stock: obj?.stock,
                adjustQty: obj?.adjQty,
                unit: obj?.unit,
                unitCost: obj?.unitCost,
                totalCost: obj?.totalCost}
      })
      setSelectedProductsFront(prodArrayFront)

      let prodArrayBack=  purchaseInfo?.map((obj,i)=>{
        return {
                itemId: obj?.itemId,
                itemType: obj?.itemType,
                itemName: obj?.itemName,
                status:'Pending',
                shareQty: parseFloat(obj?.shareQty),
                adjQty: parseFloat(obj?.adjQty),
                unit: obj?.unit,
                unitCost: parseFloat(obj?.unitCost),
                totalCost: parseFloat(obj?.totalCost),
                dimension: obj?.dimension,
              }
      })
      setSelectedProductsBack(prodArrayBack)


      setLocationChecked(isSameLoc)
      setIsNegativeStockChecked(isNegative)

    }
  }, [singleDraftedStockAdj]);

  //Set Stock Adj transaction id to its state
  useEffect(() => {
    if (stockAdjID !== undefined) {
      const { prefix, transNo } = stockAdjID;
      setStockAdjFormOne({
        ...stockAdjFormOne,
        transId: `${prefix}${transNo}`,
      });
    }
  }, [stockAdjID]);

  //Call Product Type API Call on change of product type
  useEffect(() => {
    // viewAllProductsAPICall({ type: productType },setIsLoading);
    viewAllProductListWithBranchAPICall({ type: productType,branchId:
       userRole === 'admin' ?
       stockAdjFormOne.fromLoc
       :
      loginResponse?.branchId})
  }, [productType, stockAdjFormOne.fromLoc]);

  //Call Extract selected products object
  useEffect(() => {
    if (stockAdjFormTwo.productId !== "0") {
      extractSelectedProductObj(stockAdjFormTwo.productId);
    }
  }, [stockAdjFormTwo.productId]);

  //Set Selected products autocomplete fields in case of material product
  useEffect(() => {
    if (selectedProductObj?.length !== 0) {
      if(productType===2)
      {
      const { unitName,closingstock } = selectedProductObj[0];
      setStockAdjFormTwo({ ...stockAdjFormTwo, stock:closingstock, unit: unitName });
    }
    }
  }, [selectedProductObj]);

  //Set Stock into state in case of readymade and accessory product
  useEffect(()=>{
    if(productType!==2)
    {
    const { size } = selectedProductObj[0];
    let selectedSze= size?.filter((obj)=>obj?.brandUnit===stockAdjFormTwo.size)
    setStockAdjFormTwo({...stockAdjFormTwo,stock:selectedSze!==undefined&&selectedSze[0]?.stock})
  }
  },[stockAdjFormTwo.size])



  //Call Calculate TotalCost function
  useEffect(() => {
    let totCost = calculateTotalCost(
      stockAdjFormTwo.unitCost,
      stockAdjFormTwo.adjQty
    );
    setStockAdjFormTwo({ ...stockAdjFormTwo, totCost });
  }, [stockAdjFormTwo.adjQty, stockAdjFormTwo.unitCost]);


  //Call To location Stock api
  useEffect(() => {
    const body = {
      itemId: stockAdjFormTwo.productId,
      branchId: stockAdjFormOne.toLoc,
      dimension: productType === 2 ? "no dimension" : stockAdjFormTwo.size,
    };
    if (!locationChecked) {
      if (stockAdjFormTwo.productId !== "0" && stockAdjFormOne.toLoc !== "0") {
        getStockOfToLocation(body);
      }
    }
  }, [stockAdjFormOne.toLoc, stockAdjFormTwo.productId, stockAdjFormTwo.size]);

  //To extract selected product material
  const extractSelectedProductObj = (selectedProdId) => {
    if (allProductsList !== undefined) {
      let extractedProdObj = allProductsList?.filter(
        (obj) => obj?._id === selectedProdId
      );
      setSelectedProductObj(extractedProdObj);
    }
  };

  //To Extract GL Link Code, GL Code
  const extractGlandGlLinkSingleObj=(selectedCode,completeList)=>{
    let singleObj=null;
    if(completeList!==undefined)
    {
       singleObj=completeList?.filter((obj)=>obj?.code===selectedCode)
    }

    return singleObj
  }


  //Get Gl Link Description
  useEffect(()=>{
      let extractedObj = extractGlandGlLinkSingleObj(stockAdjFormOne.glLinkCode,glLinkCodes)
     if(extractedObj!==null)
     {
      setStockAdjFormOne({...stockAdjFormOne,glLinkDesc:extractedObj[0]?.description})
    }
  },[stockAdjFormOne.glLinkCode])
  //Get Gl Code Description
  useEffect(()=>{
      let extractedObj = extractGlandGlLinkSingleObj(stockAdjFormOne.glCode,glCodes)
     if(extractedObj!==null)
     {
      setStockAdjFormOne({...stockAdjFormOne,glCodeDesc:extractedObj[0]?.description})
    }
  },[stockAdjFormOne.glCode])
  //Extract selected location Code
  useEffect(()=>{
      let extractedObj=null;
      extractedObj=extractObjectForSingleView(stockAdjFormOne.toLoc,allBranchesList)
      if(extractedObj!=null)
      {
        setStockAdjFormOne({...stockAdjFormOne,toLocDesc:extractedObj[0]?.branchName})
      }
  },[stockAdjFormOne.toLoc])




  //**Click Fn */

  //Add Selected Products
  const addSelectedProductOnButtonClick = () => {
    if (stockAdjFormOne.fromLoc === "0") {
      setbranchAlert(true)
    }else if(locationChecked===false &&stockAdjFormOne.toLoc==='0'){
        setToLocAlert(true)
    } else if (stockAdjFormTwo?.productId === "0") {
      setProductAlert(true)
    } else if(productType !==2 && stockAdjFormTwo?.size==='0'){
      setProductSizeAlert(true)
    }
     else if (stockAdjFormTwo?.adjQty === "") {
      setAdjQtyAlert(true)
    } else if (stockAdjFormTwo?.unitCost === "") {
      setUnitCostAlert(true)
    } else {
      createSelectedProductArray();
      clearFormTwoStates();
    }
  };

  //Post Stock Adjustment body
  const finalPostBody = {
    fromLoc: stockAdjFormOne?.fromLoc,
    toLoc: locationChecked ? stockAdjFormOne?.fromLoc : stockAdjFormOne?.toLoc,
    date: `${stockAdjFormOne?.date} ${time}`,
    remarks: stockAdjFormOne?.remarks,
    isSameLoc: locationChecked,
    // isNegative: isNegativeStockChecked,
    glLinkCode: {
      code: stockAdjFormOne?.glLinkCode,
      description: stockAdjFormOne?.glLinkDesc,
    },
    glCode: {
      code: stockAdjFormOne?.glCode,
      description: stockAdjFormOne?.glCodeDesc,
    },
    ccCode: {
      code: locationChecked ? stockAdjFormOne?.fromLoc : stockAdjFormOne?.toLoc,
      description: stockAdjFormOne?.toLocDesc,
    },
    purchaseInfo: selectedProductsBack,
  };
  console.log(finalPostBody);
  //Post Stock adjustment Btn Click
  const finalPostStockAdjustment = () => {
    if(stockAdjFormOne.fromLoc ==='0')
    {
      alert('Select from location!!')
    }
    else if(selectedProductsBack.length == 0) 
    {
      alert('select a product!!')
    }
    else
    {
      finalPostStockAdjustmentAPICall(
        finalPostBody,
        setOpenSuccessSnack,
        setOpenErrorSnack,
        setSnackMsg,
        updateListener,
        clearAllFields
      )
    }

  };


  //Post Drafted Stock Adj Body
  const finalDraftPostBody = {
    _id:singleDraftedStockAdj?._id,
   fromLoc: stockAdjFormOne?.fromLoc,
   toLoc: locationChecked ? stockAdjFormOne?.fromLoc : stockAdjFormOne?.toLoc,
   date: `${stockAdjFormOne?.date} ${getCurrentTime()}`,
   remarks: stockAdjFormOne?.remarks,
   isSameLoc: locationChecked,
  //  isNegative: isNegativeStockChecked,
   glLinkCode: {
     code: stockAdjFormOne?.glLinkCode,
     description: stockAdjFormOne?.glLinkDesc,
   },
   glCode: {
     code: stockAdjFormOne?.glCode,
     description: stockAdjFormOne?.glCodeDesc,
   },
   ccCode: {
     code: locationChecked ? stockAdjFormOne?.fromLoc : stockAdjFormOne?.toLoc,
     description: stockAdjFormOne?.toLocDesc,
   },
   purchaseInfo: selectedProductsBack,
 };
 
  //Post Drafted Stock Adjustment
  const postDraftedStockAdjustment=()=>{
    if (stockAdjFormOne.fromLoc==='0')
    {
      setbranchAlert(true)
    }
    else if(selectedProductsBack.length == 0) 
    {
      setProductAlert(true)
    }
    else 
    {
      finalPostDraftedStockAdjustmentAPICall(
        finalDraftPostBody,
        setOpenSuccessSnack,
        setOpenErrorSnack,
        setSnackMsg,
        updateListener,
        draftClearAllFields
      )
    }
  }





  //Draft Stock Adjustment
  const draftStockAdjustment = () => {
    if (selectedProductsBack?.length > 0) {
      draftStockAdjustmentAPICall(
        finalPostBody,
        setOpenSuccessSnack,
        setOpenErrorSnack,
        setSnackMsg,
        updateListener,
        clearAllFields
      );
    }
  };

  //*--------------------------------------------------------------------*//
  //Create Selected Products Array
  const createSelectedProductArray = () => {
    setSelectedProductsFront([
      ...selectedProductsFront,
      {
        prodName: selectedProductObj[0]?.productName,
        stock: selectedProductObj[0]?.stock,
        adjustQty: stockAdjFormTwo?.adjQty,
        unit: stockAdjFormTwo?.unit,
        unitCost: stockAdjFormTwo?.unitCost,
        totalCost: stockAdjFormTwo?.totCost,
      },
    ]);

    setSelectedProductsBack([
      ...selectedProductsBack,
      {
        itemId: stockAdjFormTwo?.productId,
        itemType: productType,
        itemName: selectedProductObj[0]?.productName,
        shareQty: parseFloat(stockAdjFormTwo?.adjQty),
        status:'Pending',
        adjQty: parseFloat(stockAdjFormTwo?.adjQty),
        unit: stockAdjFormTwo?.unit,
        unitCost: parseFloat(stockAdjFormTwo?.unitCost),
        totalCost: parseFloat(stockAdjFormTwo?.totCost),
        dimension: stockAdjFormTwo?.size,
        isNegative: isNegativeStockChecked,
      },
    ]);
  };

  //Delete a selected product entry from table
  const deleteFromTable = (index) => () => {
    selectedProductsFront.splice(index, 1);
    setSelectedProductsFront([...selectedProductsFront]);
    selectedProductsBack.splice(index, 1);
    setSelectedProductsBack([...selectedProductsBack]);
  };

  //Clear Form Two
  const clearFormTwoStates = () => {
    setStockAdjFormTwo(stockAdjFormTwoInitialState);
  };

  //Clear top form States
  const clearTopFormStates = () => {
    setStockAdjFormOne(stockAdjFormOneInitialState);
    setLocationChecked(false)
    clearFormTwoStates();
    setLocationChecked(false);
    setIsNegativeStockChecked(false);
    updateListener();
  };

  //Clear all Fields
  const clearAllFields = () => {
    clearTopFormStates();
    setSelectedProductsFront([]);
    setSelectedProductsBack([]);
  };

  //Clear All Fields in case of Draft
  const draftClearAllFields=()=>{
    localStorage.removeItem('draft')
    setIsDraft(false)
    clearAllFields()
    store.dispatch(
      update_singleDraft({singleDraft:undefined})
    );
  }
  useEffect(()=>{
    if(userRole==="user"){
      setStockAdjFormOne({...stockAdjFormOne,fromLoc:userBranchId})
    }
  },[userBranchId])

  useEffect(()=>{
    let filteredArray=[]
    if(branchFiltered.length!==0){
      filteredArray=branchFiltered.filter((obj)=>
        obj?._id !==stockAdjFormOne.fromLoc
      )
      setToBranchFiltered(filteredArray)
      setStockAdjFormOne({...stockAdjFormOne,toLoc:"0"})
    }
    // if(stockAdjFormOne.fromLoc===stockAdjFormOne.toLoc){
    //   setStockAdjFormOne({...stockAdjFormOne,toLoc:"0"})
    // } 
  },[stockAdjFormOne.fromLoc,branchFiltered])
  return (
    <>
      <div className="global-page-parent-container">
        <p className="breadcrumb">
          Purchase Manage &gt; Stock Adjustment &gt; Add Stock Adjustment
        </p>
        <div className="add-stock-adjustment-container">
          <div className="add-stock-adjustment-left-main-container">
            <div className="add-stock-adjustment-left-container">
              <div className="global-radio-button add-stock-adj-radio-btn">
                <input
                  type="radio"
                  id="stockAdjMaterial"
                  name="add-stock-adj"
                  value={2}
                  defaultChecked
                  onChange={getProductTypeRadioValue}
                />
                <label htmlFor="stockAdjMaterial">Material</label>
                <input
                  type="radio"
                  id="stockAdjReadymade"
                  name="add-stock-adj"
                  value={0}
                  onChange={getProductTypeRadioValue}
                />
                <label htmlFor="stockAdjReadymade">Readymade</label>
                <input
                  type="radio"
                  id="stockAdjAccessories"
                  name="add-stock-adj"
                  value={1}
                  onChange={getProductTypeRadioValue}
                />
                <label htmlFor="stockAdjAccessories">Accessories</label>
              </div>
              <div className="add-stock-adjustment-left-top-container">
                <div className="global-single-input add-stock-adj-input">
                  <p>Transaction ID</p>
                  <input disabled value={stockAdjFormOne.transId} />
                </div>
                <div className="global-single-input add-stock-adj-input">
                  { branchAlert?
                    <p className="doc-validation-alert" style={{top:"10%"}}>Select a branch</p>
                  :""}
                  
                  <p>From Location</p>
                  <Select
                    value={stockAdjFormOne.fromLoc}
                    onChange={getStockAdjFormOneData("fromLoc")}
                    disabled={userRole==="user"?true:false}
                  >
                    <MenuItem value="0" disabled>-select current location-</MenuItem>
                    {branchFiltered?.map((item, i) => (
                      <MenuItem
                        value={item?._id}
                        key={i}
                      >{`${item?.storeCode}-${item?.branchName}`}</MenuItem>
                    ))}
                  </Select>
                  <div className="add-stock-adj-checkbox">
                    <input
                      type="checkbox"
                      id="sameLoc"
                      className="same-location-input"
                      checked={locationChecked}
                      value={locationChecked}
                      onChange={sameLocationChange}
                    />
                    <label htmlFor="sameLoc" className="same-location-text">
                      Same Location
                    </label>
                    {/* {locationChecked === true && (
                      <>
                        <input
                          type="checkbox"
                          id="negative"
                          className="negative-input"
                          onChange={getNegativeStockChecked}
                        />
                        <label htmlFor="negative" className="negative-text">
                          Negative
                        </label>
                      </>
                    )} */}
                  </div>
                </div>
                <div className="global-single-input add-stock-adj-input">
                  <p>Date</p>
                  <input
                    type="date"
                    value={stockAdjFormOne.date}
                    onChange={getStockAdjFormOneData("date")}
                  />
                </div>
                <div className="global-single-input add-stock-adj-input">
                  <p>Remarks</p>
                  <input
                    placeholder="Enter remarks..."
                    value={stockAdjFormOne.remarks}
                    onChange={getStockAdjFormOneData("remarks")}
                  />
                </div>
              </div>
              <hr className="global-hr" />
              <div className="add-stock-adjustment-left-bottom-container">
                <div className="global-single-input add-stock-adj-input">
                  <p>GL Link Code</p>
                  <Select
                    value={stockAdjFormOne.glLinkCode}
                    onChange={getStockAdjFormOneData("glLinkCode")}
                  >
                    <MenuItem value="0">-select code-</MenuItem>
                    {glLinkCodes?.map((item, i) => (
                      <MenuItem value={item?.code} key={i}>
                        {item?.code}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="global-single-input add-stock-adj-input">
                  <p>Description</p>
                  <input
                    disabled
                    placeholder="GL Link Code name will appear here"
                    value={stockAdjFormOne.glLinkDesc}
                    onChange={getStockAdjFormOneData("glLinkDesc")}
                  />
                </div>
                <div className="global-single-input add-stock-adj-input">
                  <p>GL Code</p>
                  <Select
                    value={stockAdjFormOne.glCode}
                    onChange={getStockAdjFormOneData("glCode")}
                  >
                    <MenuItem value="0">-select code-</MenuItem>
                    {glCodes?.map((item, i) => (
                      <MenuItem value={item?.code} key={i}>
                        {item?.code}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="global-single-input add-stock-adj-input">
                  <p>Description</p>
                  <input
                    disabled
                    placeholder="GL Code name will appear here"
                    value={stockAdjFormOne.glCodeDesc}
                    onChange={getStockAdjFormOneData("glCodeDesc")}
                  />
                </div>
                {/* <div className="global-single-input add-stock-adj-input">
                  <p>SL Code</p>
                  <Select
                    value={stockAdjFormOne.slCode}
                    onChange={getStockAdjFormOneData("slCode")}
                  >
                    <MenuItem value="0">-select code-</MenuItem>
                    {slCodes?.map((code, i) => (
                      <MenuItem value={code} key={i}>
                        {code}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="global-single-input add-stock-adj-input">
                  <p>Description</p>
                  <input
                    disabled
                    placeholder="SL Code name will appear here"
                    value={stockAdjFormOne.slCodeDesc}
                    onChange={getStockAdjFormOneData("slCodeDesc")}
                  />
                </div> */}
                {locationChecked !== true && (
                  <>
                    <div className="global-single-input add-stock-adj-input">
                      <p>CC Code(To Location)</p>
                      <Select
                        value={stockAdjFormOne.toLoc}
                        onChange={getStockAdjFormOneData("toLoc")}
                        >
                         
                        <MenuItem value="0" disabled>-select current location-</MenuItem>
                        {toBranchFiltered?.map((item, i) => (
                          <MenuItem
                            value={item?._id}
                            key={i}
                          >{`${item?.storeCode}-${item?.branchName}`}</MenuItem>
                        ))}
                      </Select>
                      {toBranchStock !== undefined && toBranchStock?.data != 0 && (
                        <p className="suffix-text">
                          stock Qty:
                          {toBranchStock?.data?.stock[0]?.dimensionStock}
                        </p>
                      )}
                      {
                        toLocAlert?
                        <p className="doc-validation-alert">Select a branch !!</p>:""
                      }
                    </div>
                    <div className="global-single-input add-stock-adj-input">
                      <p>Description</p>
                      <input
                        disabled
                        placeholder="CC code name will appear here"
                        value={stockAdjFormOne.toLocDesc}
                        onChange={getStockAdjFormOneData("toLocDesc")}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="add-stock-adjustment-right-container">
            <div className="add-stock-adj-right">
              <div className="global-single-input add-stock-adj-input auto-complete">
                <p>Product Name</p>
                <Autocomplete
                  sx={{width:'100%'}}
                  options={allProductsList|| ['']}
                  getOptionLabel={(option)=>`${option?.productId}-${option?.productName}`}
                  renderInput={(params) => (
                    <TextField {...params} />
                  )}
                  // value={(option)=>option?._id}
                  onChange={(e,newValue)=>{
                    setProdObjChosen(newValue)
                    setProductAlert(false)
                    if(newValue===null){
                      setProductAlert(true)
                    }
                  }}
                />
                {productAlert?
                  <p className="doc-validation-alert">Select a product!!</p>
                :""}
                {/* <Select
                  value={stockAdjFormTwo?.productId}
                  onChange={getStockAdjFormTwoData("productId")}
                >
                  <MenuItem value="0">-select a product-</MenuItem>
                  {allProductsList?.map((item, i) => (
                    <MenuItem
                      value={item?._id}
                      key={i}
                    >{`${item?.productId}-${item?.productName}`}</MenuItem>
                  ))}
                </Select> */}
                {/* <p className="suffix-text">* add product code</p> */}
              </div>
              {productType == 2 && (
                <div className="global-single-input add-stock-adj-input">
                  <p>Stock Qty</p>
                  <input
                    disabled
                    placeholder="Select product to display"
                    value={stockAdjFormTwo?.stock}
                    onChange={getStockAdjFormTwoData("stock")}
                  />
                </div>
              )}
              {productType != 2 && (
                <div className="stock-size-container">
                  <div className="global-single-input add-stock-adj-input stock-adj-size-parent">
                    <p>Size</p>
                    <Select
                      value={stockAdjFormTwo?.size}
                      onChange={getStockAdjFormTwoData("size")}
                    >
                      <MenuItem value="0">-select size-</MenuItem>
                      {selectedProductObj[0]?.size?.map((obj, i) => (
                        <MenuItem value={obj?.brandUnit} key={i}>
                          {obj?.brandUnit}
                        </MenuItem>
                      ))}
                    </Select>

                    {productSizeAlert?
                      <p className="doc-validation-alert">Select a size!!</p>
                    :""}
                  </div>
                  <div className="global-single-input add-stock-adj-input">
                    <p>Stock Qty</p>
                    <input
                      disabled
                      placeholder="Select product to display"
                      value={stockAdjFormTwo?.stock}
                      onChange={getStockAdjFormTwoData("stock")}
                    />
                  </div>
                </div>
              )}
              <div className="global-single-input add-stock-adj-input">
                <p>Adj Qty</p>
                <input
                  placeholder="Enter adjust qty..."
                  value={stockAdjFormTwo?.adjQty}
                  onChange={getStockAdjFormTwoData("adjQty")}
                  type="number"
                  min="0"
                />
                {adjQtyAlert?
                  <p className="doc-validation-alert">fill this field!!</p>
                :""}
                  <div className="add-stock-adj-checkbox">
                  {locationChecked === true && (
                      <>
                        <input
                          type="checkbox"
                          id="negative"
                          className="negative-input"
                          onChange={getNegativeStockChecked}
                        />
                        <label htmlFor="negative" className="negative-text">
                          Negative
                        </label>
                      </>
                    )}
                    </div>
              </div>
              <div className="global-single-input add-stock-adj-input">
                <p>Unit</p>
                <input
                  disabled
                  placeholder="Select product to display"
                  value={stockAdjFormTwo?.unit}
                  onChange={getStockAdjFormTwoData("adjQty")}
                />
              </div>
              <div className="global-single-input add-stock-adj-input">
                <p>Unit Cost</p>
                <input
                  placeholder="Enter unit cost..."
                  value={stockAdjFormTwo?.unitCost}
                  onChange={getStockAdjFormTwoData("unitCost")}
                  type="number"
                  min="0"
                />
                {unitCostAlert?
                  <p className="doc-validation-alert">fill this field!!</p>
                :""}
              </div>
              <div className="global-single-input add-stock-adj-input">
                <p>Total Cost</p>
                <input
                  disabled
                  value={stockAdjFormTwo?.totCost}
                  onChange={getStockAdjFormTwoData("totCost")}
                />
              </div>
            </div>
            <button
              className="btn btn-secondary-outlined add-stock-adj-btn"
              onClick={clearTopFormStates}
            >
              Clear
            </button>
            <button
              className="btn btn-secondary add-stock-adj-btn"
              onClick={addSelectedProductOnButtonClick}
            >
              Submit
            </button>
          </div>
        </div>

        <div className="add-stock-adj-table-container">
          <h3>Adjust Product</h3>
          <div className="global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Product Name</th>
                  <th>Stock Qty</th>
                  <th>Adjust Qty</th>
                  <th>Unit</th>
                  <th>Unit Cost</th>
                  <th>Total Cost</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {selectedProductsFront?.length > 0 ? (
                  selectedProductsFront?.map((r, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{r?.prodName}</td>
                      <td>{r?.stock}</td>
                      <td>{r?.adjustQty}</td>
                      <td>{r?.unit}</td>
                      <td>{r?.unitCost}</td>
                      <td>{r?.totalCost}</td>
                      <td>
                        <IconButton disabled className="disabled-icon-button">
                          <i class="bi bi-pencil-square edit-icon "></i>
                        </IconButton>
                      </td>
                      <td>
                        <IconButton onClick={deleteFromTable(i)}>
                          <i class="bi bi-trash3 delete-icon"></i>
                        </IconButton>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={9} style={{textAlign:"center"}}>No Products Selected</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <button
            className="btn btn-secondary-outlined"
            onClick={isDraft?draftClearAllFields:clearAllFields}
          >
            Cancel
          </button>
          <button
            className={
              selectedProductsBack?.length === 0
                ? "btn btn-secondary disabled"
                : "btn btn-secondary"
            }
            disabled={selectedProductsBack?.length === 0 ? true : false}
            onClick={isDraft?postDraftedStockAdjustment:finalPostStockAdjustment}
          >
            Post
          </button>
          <button
            className={
              selectedProductsBack?.length === 0
                ? "btn btn-secondary add-stock-adj-draft-btn disabled"
                : "btn btn-secondary add-stock-adj-draft-btn"
            }
            disabled={selectedProductsBack?.length === 0 ? true : false}
            onClick={draftStockAdjustment}
          >
            Draft
          </button>
        </div>
      </div>

      <SuccessSnackbar
        open={openSuccessSnack}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={openErrorSnack}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </>
  );
};
