import React from 'react'

const StockMovePrint = (props) => {
    const { data, formValues } = props
    const decimalPosition = localStorage.getItem("decimalPosition");
    const decimalPositionStock = localStorage.getItem("decimalPositionStock");
  
  
    return (
    <div className="print-main-div">
    <div className='header-div'>
      <div>
        <img src={formValues?.companyLogo} alt="" style={{height:"62px"}}/>
      </div>
      <div>
        <h1 style={{ fontSize: "larger", margin: "0" }}>{formValues?.label}</h1>
        {/* <p style={{margin:"0"}}>aaaaaaa</p> */}
      </div>
      <div>
        <h1 style={{ fontSize: "small", margin: "0" }}>companyName</h1>
        <p style={{ margin: "0", fontSize: "smaller" }}>{formValues?.companyName}</p>
        <p style={{ margin: "0", fontSize: "smaller" }}>{formValues?.companyAddress}</p>
        <p style={{ margin: "0", fontSize: "smaller" }}>{formValues?.companyContact}</p>
      </div>
    </div>

    <div className="global-report-table-container" style={{ overflow: "visible" }} >
      <table>
        <thead>
        <tr >
                <th>Item</th>
                <th>UOM</th>
                <th>Opening</th>
                <th>In</th>
                <th>Out</th>
                <th>Closing</th>
                <th>Cost</th>
                <th>Values</th>
              </tr>
        </thead>
        <tbody>
              {data?.map((item, index) => (
                <tr key={index} >
                  <td style={{ textAlignLast: "start", paddingLeft: "10px" }}>{item.productName}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.uom}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.openingBalance}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.totalInDate?.toFixed(decimalPositionStock)}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.totalOutDate?.toFixed(decimalPositionStock)}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.closingBalance?.toFixed(decimalPosition)}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.cost?.toFixed(decimalPosition) || 0?.toFixed(decimalPosition)}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.value?.toFixed(decimalPosition)}</td>
                </tr>
              ))}
            </tbody>
        
       
      </table>
       <div className='div-table-total' style={{justifyContent:"end"}}>
          
            <p style={{width:"15%"}}>Total</p>
            <p>{(data?.reduce((sum, item) => sum + item?.value, 0)?.toFixed(decimalPosition))}</p>
         
        </div>
    </div>
  </div>
  )
}

export default StockMovePrint