import {createSlice} from '@reduxjs/toolkit'

export const salesReportSlice=createSlice({
    name:'salesReportSlice',
    initialState:{
        value:undefined
    },
    reducers:{
     get_sales_report:(state,action)=>{
        state.value=action.payload.salesReportData
     }
    }
})

export const {get_sales_report} = salesReportSlice.actions;
export default salesReportSlice.reducer;