import { createSlice } from "@reduxjs/toolkit";

export const jobCompletionSingleViewSlice=createSlice({
    name:'jobCompletionSingleViewSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_jc_single_view:(state,action)=>{
            state.value=action.payload.jcSingleView
        }
    }
})
export const {update_jc_single_view} = jobCompletionSingleViewSlice.actions
export default jobCompletionSingleViewSlice.reducer