import {
  Alert,
  Autocomplete,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import uploadFile from "../../../../../Assets/Images/Icon feather-upload.png";
import { CloseOutlined } from "@mui/icons-material";
import logoImage from "../../../../../Assets/Images/Icon ionic-logo-buffer.png";
import { useRef } from "react";
import { viewInventoryConfigSettingsAPICall } from "../../Configuration/inventoryConfigurationSettingsAPI";
import { useDispatch, useSelector } from "react-redux";
import {
  viewAllAllowedBranchesAPICall,
  viewAllBranchesAPICall,
} from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import { vieWareHouseLocationAPICall } from "../../Configuration/wareHouseLocationAPI";
import { useNavigate } from "react-router-dom";
import { getAllNewProductList } from "../../../Accounts/Vendor/API/vendorBillAPI";
import { getAllActiveEmployeesAPICall } from "../../../../../API/Staff/HRM/employeeAPI";
import {
  addBranchTransferAPICall,
  branchTransferActionAPI,
  editBranchTransferAPICall,
  generateBranchTransferNo,
  getDefaultLocationProductListApi,
  getLocationProductListApi,
  postBranchTransferAPICall,
  singleViewBtApi,
} from "./BranchTransferAPI";
import { filterObjFromList } from "../../../../../Js/generalFunctions";
import SuccessSnackbar from "../../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
import { getCurrentTime, today } from "../../../../../Js/Date";
import postRibbon from "../../../../../Assets/statusRibbon/post.png";
import draftRibbon from "../../../../../Assets/statusRibbon/draft.png";
import { viewUOMSubListAPICall } from "../../../Purchase Manager/Pages/UnitOfMeasurment/unitOfMeasurmentAPICall";
import requestedRibbon from "../../../../../Assets/statusRibbon/requested.png";
import cancelRibbon from "../../../../../Assets/statusRibbon/cancel.png";
import grantedRibbon from "../../../../../Assets/statusRibbon/granted.png";
import { LoadingForm } from "../../../../Single Components/LoadingForm";
import { set_Back_button_action, setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";


export const CreateBranchTransfer = () => {
  let singleViewId = localStorage.getItem("singleViewById");
  let decimalPoint = localStorage.getItem("decimalPosition");
  let navigate = useNavigate();
  const addRowData = [{ value: "Add Header" }, { value: "Add Line" }];
  // settings
  const configurationList = useSelector(
    (state) => state.inventoryConfigSettingSlice.apiResList
  );
  // all allowed branches list
  const allowedBranchList = useSelector(
    (state) => state.allAllowedBranchesSlice.value
  );
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  // login response
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  // ware house location
  const wareHouseLocationList = useSelector(
    (state) => state.wareHouseLocationSlice.value
  );
  const singleView = useSelector(
    (state) => state.branchTransferSlice.singleValue
  );
  const transNo = useSelector((state) => state.branchTransferSlice.transNo);
  //get uom list
  const uomSubList = useSelector(
    (state) => state.unitOfMeasurmentSlice.subUomList
  );
  console.log("uomSubList", uomSubList);

  //Active Employee List
  const allActiveEmpList = useSelector(
    (state) => state.allActiveEmployeeSlice.value
  );
  console.log(singleView);
  // new produuct list
  let allNewProductListRedux = useSelector(
    (state) => state.branchTransferSlice.productListData
  );
  const userRole = useSelector((state) => state.userRoleSlice.value);

  const [allNewProductList, setAllNewProductList] = useState([]);

  useEffect(() => {
    if (allNewProductListRedux) {
      // Use filter to keep only elements where res.stock > 0
      const sortedArray = allNewProductListRedux?.filter(
        (res) => res.stock > 0
      );
      setAllNewProductList(sortedArray);
    }
  }, [allNewProductListRedux]);

  // let sourceLocation = wareHouseLocationList.filter((res) => res?._id === singleView.source);
  //   let destiLocation = wareHouseLocationList.filter((res) => res?._id === singleView.destination);

  //   console.log("sourceLocation",sourceLocation );
  //   console.log("destiLocation",destiLocation );

  const containerRef = useRef(null);
  // console.log("wareHouseLocationList",wareHouseLocationList)

  // *** intergation ****//
  const branchLocationFormInitial = {
    sourceBranch: null,
    sourceLoc: null,
    destBranch: null,
    destiLoc: null,
    scheduleDate: today,
    reference: "",
    contactPreson: "",
    Grn: "",
    spMargin: 0,
    margin: "",
    customerNote: "",
    vehicleNo: "",
    employeeName: null,
    isSourceChange: true,
    isdestChange: false,
    isEdit: false,
    status: "",
  };
  const [branchLocationForm, setBranchLocationForm] = useState(
    branchLocationFormInitial
  );
  const dispatch = useDispatch();
  const [validationAlert, setValidationAlert] = useState({});
  const [destinationBranch, setDestinationBranch] = useState([]);
  const [sourceBranch, setSourceBranch] = useState([]);
  const [sourceLoc, setSourceLoc] = useState([]);
  const [destinationLoc, setDestinationLoc] = useState([]);
  const [isActiveTable, setIsActiveTable] = useState("invoiceLines");
  const [addRowInput, setAddRowInput] = useState(addRowData[1]);
  const [tableData, setTableData] = useState([
    {
      type: "lines",
      product: null,
      des: "",
      uom: "",
      spMargin: "",
      onHandQty: 0,
      transferQty: 0,
      _id: "",
    },
  ]);
  // console.log("branchLocationForm",branchLocationForm);

  useEffect(() => {
    setTableData([
      {
        type: "lines",
        product: null,
        des: "",
        uom: "",
        spMargin: "",
        onHandQty: 0,
        transferQty: 0,
        _id: "",
        posCat: "",
      },
    ]);
    setAllNewProductList([]);
    if (!configurationList?.multiLocation) {
      if (branchLocationForm.sourceBranch) {
        getDefaultLocationProductListApi({
          storeCode: branchLocationForm.sourceBranch._id,
        });
      }
    }
  }, [branchLocationForm.sourceBranch]);

  useEffect(() => {
    // apiCall for get product
    if (branchLocationForm.sourceBranch && branchLocationForm.sourceLoc) {
      getLocationProductListApi({
        branchId: branchLocationForm.sourceBranch._id,
        locationId: branchLocationForm.sourceLoc._id,
      });
    }
  }, [branchLocationForm.sourceBranch, branchLocationForm.sourceLoc]);

  const [image, setImage] = useState([]);
  const [displayImage, setDisplayImage] = useState([]);

  const [logView, setLogView] = useState(false);
  const [tableDataForBackend, setTableDataForBackend] = useState([]);
  const [otherInfo, setOtherInfo] = useState({
    vehicleNo: "",
    employeeName: null,
  });
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const [loadingForm,setLoadingForm]=useState(false)

  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("Error!!");
  const [editClicked, setEditClicked] = useState(false);
  const [receivedImgUrl, setReceivedImgUrl] = useState([]);
  const [defaultValue, setDefaultValue] = useState();
  const [editDataId, setEditDataId] = useState("");
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
    alert: false,
  });

  const [log, setLog] = useState();
  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      alert: false,
      message: "",
    });
  };

  ///****** */  design section ////******
  const productMenuClick = () => {
    document.getElementById("productMenuBtn").classList.add("active");
    document.getElementById("departmentMenuBtn").classList.remove("active");
    setIsActiveTable("invoiceLines");
  };

  const departmentMenuClick = () => {
    document.getElementById("productMenuBtn").classList.remove("active");
    document.getElementById("departmentMenuBtn").classList.add("active");
    setIsActiveTable("others");
  };
  const [addRowScroll, setAddRowScroll] = useState(false);
  const addRow = () => {
    if (addRowInput?.value === "Add Header") {
      setTableData([
        ...tableData,
        { type: "header", HeaderValue: "", _id: "" },
      ]);
    } else if (addRowInput?.value === "Add Line") {
      setTableData([
        ...tableData,
        {
          type: "lines",
          product: null,
          des: "",
          uom: "",
          spMargin: branchLocationForm?.margin,
          onHandQty: 0,
          transferQty: 0,
          _id: "",
          posCat: "",
        },
      ]);
    }
    setAddRowScroll(!addRowScroll);
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [addRowScroll]);

  const handleInputChange = (id, field) => (e, newValue) => {
    const { value } = e.target;

    const updatedData = tableData.map((row, index) => {
      if (index === id) {
        if (field === "product") {
          console.log("newValue", newValue);
          return {
            ...row,
            [field]: newValue,
            des: newValue.productName ? newValue?.productName : "",
            onHandQty: newValue?.stock || 0,
            _id: newValue?._id,
            uomName: newValue?.uomName,
            uom: newValue?.uom,
            posCat: newValue?.poscat,
          };
        } else {
          return { ...row, [field]: value === 0 ? newValue : e.target.value };
        }
      }
      return row;
    });
    setTableData(updatedData);
  };

  useEffect(() => {
    if (tableData) {
      const updatedTableData = [...tableData];
      if (updatedTableData[0]) {
        updatedTableData[0].spMargin = branchLocationForm?.margin;
        setTableData(updatedTableData);
      }
    }
  }, [branchLocationForm?.margin]);

  const handleDelete = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };

  const changeImage = (e) => {
    // if (typeof e.target.files[0] === "string") {
    setImage([...image, e.target.files[0]]);
    setDisplayImage([
      ...displayImage,
      { url: URL.createObjectURL(e.target.files[0]) },
    ]);
    // } else if (
    //   e.target.files[0] !== undefined &&
    //   e.target.files[0] !== null
    // ) {
    // setImage([...image,URL.createObjectURL(e.target.files[0])])

    // }
  };

  const removeImageFn = (i) => {
    console.log(i);
    if (singleView === undefined) {
      image.splice(i, 1);
    } else {
      receivedImgUrl?.length !== 0 &&
        image.splice(i - receivedImgUrl?.length, 1);
      receivedImgUrl.splice(i, 1);
    }

    displayImage.splice(i, 1);
    setImage([...image]);
    setDisplayImage([...displayImage]);
    setReceivedImgUrl([...receivedImgUrl]);
  };

  const clickLogView = () => {
    setLogView(!logView);
  };

  //*********/ intergation section/***********/
  useEffect(() => {
    viewInventoryConfigSettingsAPICall();
    viewAllAllowedBranchesAPICall({ employeeId: loginResponse?.data?._id });
    viewAllBranchesAPICall();
    getAllNewProductList();
    getAllActiveEmployeesAPICall();
    viewUOMSubListAPICall();
  }, []);
  useEffect(() => {
    if (singleViewId) {
      singleViewBtApi({ id: singleViewId });
    }
  }, [singleViewId]);

  useEffect(() => {
    if (singleView === undefined) {
      if (branchLocationForm.sourceBranch) {
        console.log(branchLocationForm.sourceBranch);
        generateBranchTransferNo(
          { branchId: branchLocationForm.sourceBranch._id },
          setSnackMsg,
          setErrorSnackOpen
        );
      }
    }
  }, [singleView, branchLocationForm.sourceBranch]);

  const backButtonFunction = () => {
    localStorage.removeItem("singleViewById");
    if (branchLocationForm?.isEdit === false) {
      const userConfirmed = window.confirm(
        "Changes you made may not be saved."
      );

      if (userConfirmed) {
        navigate("/userdashboard/inventory/operation/branchtransfer");
        dispatch(set_Back_button_action({ backButtonClickAction: true }));
      }
    } else {
      navigate("/userdashboard/inventory/operation/branchtransfer");
      dispatch(set_Back_button_action({ backButtonClickAction: true }));
    }
  };

  useEffect(() => {
    if (branchLocationForm?.sourceBranch !== null) {
      vieWareHouseLocationAPICall({
        branchId: branchLocationForm?.sourceBranch?._id,
      });
    }
  }, [branchLocationForm?.sourceBranch]);

  useEffect(() => {
    if (branchLocationForm?.destBranch !== null) {
      vieWareHouseLocationAPICall({
        branchId: branchLocationForm?.destBranch?._id,
      });
    }
  }, [branchLocationForm?.destBranch]);

  const getBranchLocForm = (key) => (e, newValue) => {
    setValidationAlert((prevValidationAlert) => ({
      ...prevValidationAlert,
      [key]:
        e.target.value === "" || newValue === null || newValue === ""
          ? "fill this field"
          : "",
    }));

    if (key === "sourceBranch") {
      setBranchLocationForm({
        ...branchLocationForm,
        sourceBranch: newValue,
        isSourceChange: true,
        isdestChange: false,
      });
    }
    if (key === "sourceLoc") {
      setBranchLocationForm({ ...branchLocationForm, sourceLoc: newValue });
    }
    if (key === "destBranch") {
      setBranchLocationForm({
        ...branchLocationForm,
        destBranch: newValue,
        isSourceChange: false,
        isdestChange: true,
      });
    }
    if (key === "destiLoc") {
      setBranchLocationForm({ ...branchLocationForm, destiLoc: newValue });
    }
    if (key === "scheduleDate") {
      setBranchLocationForm({
        ...branchLocationForm,
        scheduleDate: e.target.value,
      });
    }
    if (key === "reference") {
      setBranchLocationForm({
        ...branchLocationForm,
        reference: e.target.value,
      });
    }
    if (key === "contactPreson") {
      setBranchLocationForm({
        ...branchLocationForm,
        contactPreson: e.target.value,
      });
    }
    if (key === "spMargin") {
      setBranchLocationForm({
        ...branchLocationForm,
        spMargin: e.target.value,
      });
    }
    if (key === "margin") {
      setBranchLocationForm({ ...branchLocationForm, margin: e.target.value });
    }
    if (key === "customerNote") {
      setBranchLocationForm({
        ...branchLocationForm,
        customerNote: e.target.value,
      });
    }
    if (key === "vehicleNo") {
      setBranchLocationForm({
        ...branchLocationForm,
        vehicleNo: e.target.value,
      });
    }
    if (key === "employeeName") {
      setBranchLocationForm({ ...branchLocationForm, employeeName: newValue });
    }
  };

  // filter source branch with destination branch
  useEffect(() => {
    if (allowedBranchList?.length !== 0) {
      let data = allowedBranchList?.filter((obj) => {
        if (obj?._id !== branchLocationForm?.destBranch?._id) {
          return obj;
        }
      });
      setSourceBranch(data);
    }
  }, [allowedBranchList, branchLocationForm?.destBranch]);

  // default source branch
  // useEffect(() => {

  //   let singleBranch = sourceBranch?.filter(
  //     (item) => item._id === loginResponse?.branchPk
  //   );
  //   setBranchLocationForm({
  //     ...branchLocationForm,
  //     sourceBranch: singleBranch !== undefined ? singleBranch[0]:null,
  //     // isSourceChange: true,
  //     // isdestChange: false,
  //   });
  // }, [sourceBranch]);

  /// filter destination branch with source branch
  useEffect(() => {
    if (allBranchesList?.length !== 0) {
      let data = allBranchesList?.filter((obj) => {
        if (obj?._id !== branchLocationForm?.sourceBranch?._id) {
          return obj;
        }
      });
      setDestinationBranch(data);
    }
  }, [branchLocationForm?.sourceBranch]);

  useEffect(() => {
    if (wareHouseLocationList !== undefined) {
      console.log(
        "NIHAAD",
        branchLocationForm?.isSourceChange,
        branchLocationForm?.isdestChange
      );

      if (branchLocationForm?.isSourceChange === true) {
        setSourceLoc(wareHouseLocationList);
      }

      if (branchLocationForm?.isdestChange === true) {
        setDestinationLoc(wareHouseLocationList);
      }
    }
  }, [
    wareHouseLocationList,
    branchLocationForm?.isSourceChange,
    branchLocationForm?.isdestChange,
  ]);

  // set table for backend
  useEffect(() => {
    setTableDataForBackend(
      tableData
        ?.map((r) => {
          if (r?.type === "header") {
            return {
              line_type: r?.type,
              label: r?.HeaderValue,
            };
          } else {
            if (r._id) {
              return {
                line_type: r?.type,
                _id: r?._id,
                mainCatgeoryId: r?.product?.mainCatgeoryId,
                product: r?.product?.productName,
                prodType: "goods",
                label: "",
                uomName: r?.uomName,
                uom: r?.product?.uom,
                purchaseUomName: r?.product?.uom,
                purchaseuom: "",
                qty: r?.product?.stock,
                unitPrice: r?.product?.cost,
                cost: "",
                spMargin: r?.spMargin,
                onHandQty: r?.onHandQty?.toFixed(decimalPoint),
                transferQty: Number(r?.transferQty)?.toFixed(decimalPoint),
                recievedQty: 0,
                description: r?.des,
                posCat: r?.posCat,
              };
            } else {
              return null;
            }
          }
        })
        .filter((obj) => obj !== null)
    );
  }, [tableData]);
  // draft function
  const formData = new FormData();
  formData.append("sourcebranchId", branchLocationForm?.sourceBranch?._id);
  branchLocationForm?.sourceLoc &&
    configurationList?.multiLocation &&
    formData.append("sourceLocationId", branchLocationForm?.sourceLoc?._id);
  branchLocationForm?.destBranch?._id &&
    formData.append("destinationBranchId", branchLocationForm?.destBranch?._id);
  configurationList?.multiLocation &&
    formData.append("destinationLocationId", branchLocationForm?.destiLoc?._id);
  formData.append("scheduleDate", `${branchLocationForm?.scheduleDate} ${getCurrentTime()}`);
  formData.append("reference", branchLocationForm?.reference);
  formData.append("contactPerson", branchLocationForm?.contactPreson);
  formData.append("spMarginType", branchLocationForm?.spMargin);
  formData.append("margin", branchLocationForm?.margin);
  formData.append("customerNotes", branchLocationForm?.customerNote);
  // formData.append("uploadFile",JSON.stringify(image))
  // formData.append("uploadFile",image);
  image?.forEach((file, index) => {
    formData.append("uploadFile", file);
  });
  formData.append("products", JSON.stringify(tableDataForBackend));
  formData.append(
    "otherInfo",
    JSON.stringify({
      vehicleNo: branchLocationForm?.vehicleNo,
      employeeName: branchLocationForm?.employeeName?._id,
    })
  );
  formData.append("postDate", today);

  const saveBtnFn = () => {
    dispatch( setFilterActive(false))
    if (branchLocationForm?.sourceBranch === null) {
      setValidationAlert({
        ...validationAlert,
        sourceBranch: "fill this field",
      });
    } else if (
      configurationList?.multiLocation &&
      branchLocationForm?.sourceLoc === null
    ) {
      setValidationAlert({ ...validationAlert, sourceLoc: "fill this field" });
    } else if (branchLocationForm?.destBranch === null) {
      setValidationAlert({ ...validationAlert, destBranch: "fill this field" });
    } else if (
      configurationList?.multiLocation &&
      branchLocationForm?.destiLoc === null
    ) {
      setValidationAlert({ ...validationAlert, destiLoc: "fill this field" });
    } else if (branchLocationForm?.scheduleDate === null) {
      setValidationAlert({
        ...validationAlert,
        scheduleDate: "fill this field",
      });
    } else if (branchLocationForm?.reference === "") {
      setValidationAlert({ ...validationAlert, reference: "fill this field" });
    } else if (branchLocationForm?.contactPreson === "") {
      setValidationAlert({
        ...validationAlert,
        contactPreson: "fill this field",
      });
    }

    // else if (branchLocationForm?.spMargin === null) {
    // setValidationAlert({ ...validationAlert, spMargin: "fill this field" });
    //  else if (branchLocationForm?.margin === "") {
    //   setValidationAlert({ ...validationAlert, margin: "fill this field" });
    // }
    else {
      let draftFn = () => {
        setBranchLocationForm({
          ...branchLocationForm,
          isEdit: true,
          status: "Drafted",
        });
      };

      addBranchTransferAPICall(
        formData,
        draftFn,
        setSnackBarStates,
        snackBarStates,
        setEditDataId,setLoadingForm
      );
    }
  };

  //-------
  const postBranchTransfer = () => {
    let editFn = () => {
      setBranchLocationForm({
        ...branchLocationForm,
        isEdit: true,
        status: "Completed",
      });
    };
    if (singleView !== undefined) {
      postBranchTransferAPICall(
        { _id: singleView?._id, postDate: today },
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        editFn,setLoadingForm
      );
    }
  };

  console.log("tableData", tableData);
  //edit section
  useEffect(() => {
    if (!editClicked) {
      if (singleView !== undefined) {
        setEditDataId(singleView?._id);
        console.log("lll", editClicked);

        setBranchLocationForm({
          ...branchLocationForm,
          sourceBranch: filterObjFromList(
            "_id",
            allBranchesList,
            "sourcebranchId",
            singleView
          ),
          // destBranch:filterObjFromList(
          //   "storeCode",
          //   destinationBranch,
          //   "destinationBranchId",
          //   singleView
          // ),
          isSourceChange: destinationLoc?.length === 0,
          isdestChange: false,
          scheduleDate: singleView?.date,
          reference: singleView?.reference,
          contactPreson: singleView?.contactPerson,
          Grn: singleView?.grnNumber,
          spMargin: singleView?.spMarginType,
          margin: singleView?.margin,
          customerNote: singleView?.customerNotes,
          vehicleNo: singleView?.otherInfo?.vehicleNo,
          employeeName: filterObjFromList(
            "_id",
            allActiveEmpList,
            "employeeName",
            singleView?.otherInfo
          ),
          // employeeName:singleView?.otherInfo?.employeeName,
          editBtn: true,
          isEdit: true,
          status: singleView?.status,
        });
        setTableData(
          singleView?.products?.map((r, i) => {
            if (r?.line_type === "header") {
              return {
                type: r?.line_type,
                HeaderValue: r?.label,
              };
            } else {
              // let product = allNewProductList.filter((res,index)=> res._id === r._id)
              return {
                type: r?.line_type,
                product: filterObjFromList(
                  "productName",
                  allNewProductList,
                  "product",
                  r
                ),

                des: r?.description,
                uom: filterObjFromList("_id", uomSubList, "uom", r),
                uomName: filterObjFromList("_id", uomSubList, "uom", r)
                  ?.uomName,
                onHandQty: r?.qty || 0,
                transferQty: r?.transferQty || 0,
                _id: r?._id,
                spMargin: r?.spMargin,
                posCat: r?.posCat,
              };
            }
          })
        );
        setDisplayImage(
          singleView?.imageUrl?.map((r) => {
            return {
              url: r.url,
            };
          })
        );
        setReceivedImgUrl(
          singleView?.imageUrl?.map((r) => {
            return {
              url: r?.url,
              _id: r?._id,
            };
          })
        );
        setLog(
          singleView?.log?.map((r, i) => {
            return r;
          })
        );
      } else {
        setEditClicked(false);
        // setBranchLocationForm(branchLocationFormInitial)
        // setProductTable([])
      }
    }
  }, [singleView, allBranchesList, allNewProductList]);

  useEffect(() => {
    if (singleView !== undefined && sourceLoc.length !== 0) {
      setBranchLocationForm({
        ...branchLocationForm,
        sourceLoc: filterObjFromList("_id", sourceLoc, "source", singleView),
        isdestChange: true,
        isSourceChange: false,
        destBranch: filterObjFromList(
          "_id",
          allBranchesList,
          "destinationBranchId",
          singleView
        ),
      });
    }
  }, [sourceLoc]);

  useEffect(() => {
    if (singleView !== undefined) {
      setBranchLocationForm({
        ...branchLocationForm,
        destiLoc: filterObjFromList(
          "_id",
          destinationLoc,
          "destination",
          singleView
        ),
      });
    }
  }, [destinationLoc]);

  const editSaveFn = () => {
    formData.append("_id", editDataId);
    formData.append("uploadUrl", JSON.stringify(receivedImgUrl));
    let editFn = () => {
      setEditClicked(false);
      setBranchLocationForm({
        ...branchLocationForm,
        isEdit: true,
        status: "Drafted",
      });
    };
    editBranchTransferAPICall(
      formData,
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      editFn,setLoadingForm
    );
  };

  const editBtnEnableFn = () => {
    console.log("work");

    setBranchLocationForm({ ...branchLocationForm, isEdit: false });
    setEditClicked(true);
  };

  // console.log(sourceLoc);
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const shouldShowAlert = !branchLocationForm?.isEdit;
      if (shouldShowAlert) {
        event.preventDefault();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [branchLocationForm?.isEdit]);

  const actionBtn = (action) => {
    let requestBody = {
      id: singleView?._id,
      statusType: undefined,
    };
    switch (action) {
      case "request":
        requestBody.statusType = 1;
        branchTransferActionAPI(requestBody,setLoadingForm,snackBarStates,setSnackBarStates);
        break;
      case "cancel":
        requestBody.statusType = 3;
        branchTransferActionAPI(requestBody,setLoadingForm,snackBarStates,setSnackBarStates);
        break;
      case "approve":
        requestBody.statusType = 2;
        branchTransferActionAPI(requestBody,setLoadingForm,snackBarStates,setSnackBarStates);
        break;
      case "reject":
        requestBody.statusType = 3;
        branchTransferActionAPI(requestBody,setLoadingForm,snackBarStates,setSnackBarStates);
        break;
      case "post":
        postBranchTransfer();
        break;

      default:
        console.log("please select a valid action");
        break;
    }
  };

  return (
    <div className="global-page-parent-container">
      <div
        className="new-global-white-bg-container"
        style={{ padding: "0 2%" }}
      >
        <div className="new-global-white-bg-icon-container">
          <div
            className="new-global-white-bg-icon-container-left"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Tooltip title="Back">
              <IconButton onClick={backButtonFunction}>
                <i class="bi bi-chevron-left"></i>
              </IconButton>
            </Tooltip>
            {branchLocationForm?.status === "Drafted" && (
              <Tooltip title="Edit">
                <IconButton
                  onClick={editClicked ? editSaveFn : editBtnEnableFn}
                >
                  {editClicked ? (
                    <i
                      class="bi bi-check2-square edit-icon"
                      style={{ fontSize: "1.5rem" }}
                    ></i>
                  ) : (
                    <i class="bi bi-pencil-square edit-icon1"></i>
                  )}
                </IconButton>
              </Tooltip>
            )}

            {branchLocationForm?.status === "" && (
              <Tooltip title="save">
                <IconButton onClick={saveBtnFn}>
                  <i class="bi bi-floppy save-icon"></i>
                </IconButton>
              </Tooltip>
            )}
            {branchLocationForm?.status === "Drafted" && !editClicked && (
              // <button onClick={validateFunction}  className='gray-status' style={{width:"100px",cursor:"pointer",backgroundColor:"#1100ff",color:"#fff"}}>Validate</button>
              <button
                onClick={() => actionBtn("request")}
                className="gray-status"
                style={{
                  width: "100px",
                  cursor: "pointer",
                  backgroundColor: "#1100ff",
                  color: "#fff",
                }}
              >
                Request
              </button>
            )}
            {
              branchLocationForm?.status === "APPROVED" && !editClicked && (
                <button
                  onClick={() => actionBtn("post")}
                  className="gray-status"
                  style={{
                    width: "100px",
                    cursor: "pointer",
                    backgroundColor: "#1100ff",
                    color: "#fff",
                  }}
                >
                  Validate
                </button>
              )
              // <button onClick={requestFunction}  className='gray-status' style={{width:"100px",cursor:"pointer",backgroundColor:"#1100ff",color:"#fff"}}>Request</button>
            }
            {(branchLocationForm?.status === "Drafted" ||
              branchLocationForm?.status === "INPROGRESS") &&
              !editClicked && (
                <button
                  style={{ cursor: "pointer", width: "100px" }}
                  onClick={() => actionBtn("cancel")}
                  className="gray-status"
                >
                  Cancel
                </button>
              )}

            {userRole === "admin" &&
              branchLocationForm?.status === "REQUESTED" &&
              !editClicked && (
                <>
                  <button
                    style={{
                      cursor: "pointer",
                      width: "100px",
                      backgroundColor: "#bf0d0d",
                      color: "#fff",
                    }}
                    onClick={() => actionBtn("reject")}
                    className="gray-status"
                  >
                    Reject
                  </button>
                  <button
                    onClick={() => actionBtn("approve")}
                    className="gray-status"
                    style={{
                      width: "100px",
                      cursor: "pointer",
                      backgroundColor: "rgb(65 187 0)",
                      color: "#fff",
                    }}
                  >
                    Approve
                  </button>
                </>
              )}
            {/* {branchLocationForm?.status === "Drafted" && !editClicked && (
              <button onClick={postBranchTransfer} className="btn btn-primary">
                Post
              </button>
            )} */}

            {/* <Tooltip title="Save" onClick={saveBtnFn}>
                <IconButton>
                  <i class="bi bi-floppy save-icon"></i>
                </IconButton>
            </Tooltip>
            <button  onClick={postBranchTransfer} className='btn btn-post'>Post</button> */}
          </div>

          <div className="status-container">
            {branchLocationForm?.status === "Drafted" && (
              <img src={draftRibbon} alt="" />
            )}
            {branchLocationForm?.status === "REQUESTED" && (
              <img src={requestedRibbon} alt="" />
            )}
            {branchLocationForm?.status === "Completed" && (
              <img src={postRibbon} alt="" />
            )}
            {branchLocationForm?.status === "APPROVED" && (
              <img src={grantedRibbon} alt="" />
            )}
            {branchLocationForm?.status === "Cancelled" && (
              <img src={cancelRibbon} alt="" />
            )}
          </div>
        </div>
        <hr className="global-hr" />
        <h4 className="global-name">
          {singleView?.transNo ? singleView?.transNo : transNo?.codeprefix}
        </h4>
        <div className="new-global-input-container">
          <div className="new-global-single-input auto-complete-new">
            <Autocomplete
              disablePortale
              loading={true}
              id="combo-box-demo"
              options={sourceBranch || []}
              getOptionLabel={(option) =>
                `${option?.storeCode}-${option?.branchName}`
              }
              renderInput={(params) => (
                <TextField {...params} label="Source Branch*" focused />
              )}
              value={branchLocationForm?.sourceBranch}
              onChange={getBranchLocForm("sourceBranch")}
              disabled={branchLocationForm?.isEdit}
            />
            <p className="doc-validation-alert">
              {validationAlert?.sourceBranch}
            </p>
          </div>
          {configurationList?.multiLocation && (
            <div className="new-global-single-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={sourceLoc || []}
                getOptionLabel={(option) => option?.name}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Source Location*" focused />
                )}
                value={branchLocationForm?.sourceLoc}
                onChange={getBranchLocForm("sourceLoc")}
                disabled={branchLocationForm?.isEdit}
              />
              <p className="doc-validation-alert">
                {validationAlert?.sourceLoc}
              </p>
            </div>
          )}

          <div className="new-global-single-input">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={destinationBranch || []}
              getOptionLabel={(option) =>
                `${option?.storeCode}-${option?.branchName}`
              }
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Destination Branch*" focused />
              )}
              value={branchLocationForm?.destBranch}
              onChange={getBranchLocForm("destBranch")}
              disabled={branchLocationForm?.isEdit}
            />
            <p className="doc-validation-alert">
              {validationAlert?.destBranch}
            </p>
          </div>
          {configurationList?.multiLocation && (
            <div className="new-global-single-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={destinationLoc || []}
                getOptionLabel={(option) => option?.name}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Destination Location*"
                    focused
                  />
                )}
                value={branchLocationForm?.destiLoc}
                onChange={getBranchLocForm("destiLoc")}
                disabled={branchLocationForm?.isEdit}
              />
              <p className="doc-validation-alert">
                {validationAlert?.destiLoc}
              </p>
            </div>
          )}

          <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="Schedule Date*"
              variant="outlined"
              type="date"
              focused
              value={branchLocationForm?.scheduleDate}
              onChange={getBranchLocForm("scheduleDate")}
              disabled={branchLocationForm?.isEdit}
            />
            <p className="doc-validation-alert">
              {validationAlert?.scheduleDate}
            </p>
          </div>
          <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="Reference"
              variant="outlined"
              type="text"
              focused
              value={branchLocationForm?.reference}
              onChange={getBranchLocForm("reference")}
              disabled={branchLocationForm?.isEdit}
            />
            <p className="doc-validation-alert">{validationAlert?.reference}</p>
          </div>
          <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="Contact Person*"
              variant="outlined"
              type="text"
              focused
              value={branchLocationForm?.contactPreson}
              onChange={getBranchLocForm("contactPreson")}
              disabled={branchLocationForm?.isEdit}
            />
            <p className="doc-validation-alert">
              {validationAlert?.contactPreson}
            </p>
          </div>
          <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="GRN*"
              variant="outlined"
              type="text"
              focused
              value={branchLocationForm?.Grn}
              // onChange={getBranchLocForm("grn")}
              // disabled={branchLocationForm?.isEdit}
              disabled
            />
          </div>
          {configurationList?.transferMargin && (
            <>
              <div className="global-single-input" style={{ margin: "8px" }}>
                <p>SP Margin</p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="radio"
                      name="spMargin"
                      //  defaultChecked
                      id="percentage"
                      value={0}
                      checked={branchLocationForm?.spMargin == 0}
                      style={{ accentColor: "#000", cursor: "pointer" }}
                      onChange={getBranchLocForm("spMargin")}
                      disabled={branchLocationForm?.isEdit}
                    />
                    <label
                      disabled={branchLocationForm?.isEdit}
                      htmlFor="percentage"
                      style={{
                        fontSize: "0.9rem",
                        marginTop: "4px",
                        cursor: "pointer",
                      }}
                    >
                      Percentage
                    </label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="radio"
                      name="spMargin"
                      id="amount"
                      value={1}
                      checked={branchLocationForm?.spMargin == 1}
                      style={{ accentColor: "#000", cursor: "pointer" }}
                      onChange={getBranchLocForm("spMargin")}
                      disabled={branchLocationForm?.isEdit}
                    />
                    <label
                      disabled={branchLocationForm?.isEdit}
                      htmlFor="amount"
                      style={{
                        fontSize: "0.9rem",
                        marginTop: "4px",
                        cursor: "pointer",
                      }}
                    >
                      Amount
                    </label>
                  </div>
                </div>
              </div>
              <div className="new-global-single-input">
                <TextField
                  id="outlined-basic"
                  label="Margin*"
                  variant="outlined"
                  type="number"
                  focused
                  value={branchLocationForm?.margin}
                  onChange={getBranchLocForm("margin")}
                  disabled={branchLocationForm?.isEdit}
                />
                <p className="doc-validation-alert">
                  {validationAlert?.margin}
                </p>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="new-global-white-bg-container">
        <div
          className="rewards-add-rewards-ctn-top-menu accounts-table-toggle"
          style={{ marginBottom: "1%" }}
        >
          <div
            className="tab-menu-button active"
            id="productMenuBtn"
            onClick={productMenuClick}
          >
            <Typography fontSize={"0.9rem"} fontWeight={600}>
              Invoice Lines
            </Typography>
          </div>
          <div
            className="tab-menu-button"
            id="departmentMenuBtn"
            onClick={departmentMenuClick}
          >
            <Typography fontSize={"0.9rem"} fontWeight={600}>
              Other Info
            </Typography>
          </div>
        </div>
        {isActiveTable === "invoiceLines" ? (
          <>
            <div className="global-product-table">
              <table>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Description</th>
                    <th>UOM</th>
                    {configurationList?.transferMargin && <th>SP Margin</th>}

                    <th>On Hand Qty</th>
                    <th>Transfer Qty</th>
                    <th style={{ backgroundColor: "#fff" }}></th>
                  </tr>
                </thead>

                <tbody>
                  {tableData.length !== 0 &&
                    tableData?.map((item, index) => (
                      <tr
                        key={index}
                        ref={
                          index === tableData.length - 1 ? containerRef : null
                        }
                      >
                        {item.type === "header" ? (
                          <>
                            <td
                              colSpan={
                                configurationList?.transferMargin === false
                                  ? 5
                                  : 6
                              }
                            >
                              <input
                                type="text"
                                value={item.HeaderValue}
                                onChange={handleInputChange(
                                  index,
                                  "HeaderValue"
                                )}
                                disabled={branchLocationForm?.isEdit}
                              />
                            </td>
                            <td style={{ border: "none", width: "4%" }}>
                              <IconButton
                                onClick={() => handleDelete(index)}
                                style={{ height: "30px" }}
                              >
                                <i
                                  className="bi bi-trash3"
                                  style={{
                                    color: "#db0000",
                                    fontSize: "1.3rem",
                                  }}
                                ></i>
                              </IconButton>
                            </td>
                          </>
                        ) : (
                          <>
                            <td style={{ minWidth: "200px" }}>
                              <div className="product-table-auto-complete">
                                <Autocomplete
                                  options={allNewProductList || []}
                                  style={{ maxHeight: "100px" }}
                                  getOptionLabel={(option) =>
                                    option?.productName
                                  }
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  value={item.product}
                                  onChange={handleInputChange(index, "product")}
                                  disabled={branchLocationForm?.isEdit}
                                />
                              </div>
                            </td>
                            <td>
                              <input
                                type="text"
                                value={item.des}
                                onChange={handleInputChange(index, "des")}
                                disabled={branchLocationForm?.isEdit}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={item.uomName}
                                disabled
                              />
                            </td>
                            {configurationList?.transferMargin && (
                              <td>
                                <input
                                  type="text"
                                  value={item.spMargin}
                                  onChange={handleInputChange(
                                    index,
                                    "spMargin"
                                  )}
                                  disabled={branchLocationForm?.isEdit}
                                />
                              </td>
                            )}

                            <td>
                              <input
                                type="text"
                                value={item?.onHandQty}
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={item?.transferQty}
                                onChange={handleInputChange(
                                  index,
                                  "transferQty"
                                )}
                                disabled={branchLocationForm?.isEdit}
                              />
                            </td>
                            <td style={{ border: "none", width: "4%" }}>
                              <IconButton
                                onClick={() => handleDelete(index)}
                                style={{ height: "30px" }}
                                disabled={branchLocationForm?.isEdit}
                              >
                                <i
                                  className="bi bi-trash3"
                                  style={{
                                    color: "#db0000",
                                    fontSize: "1.3rem",
                                  }}
                                ></i>
                              </IconButton>
                            </td>
                          </>
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="add-row-btn-container">
              <div
                className="global-single-input auto-complete add-line-autocomplete"
                style={{ width: "76%" }}
              >
                <Autocomplete
                  options={addRowData || []}
                  getOptionLabel={(option) => option?.value}
                  renderInput={(params) => <TextField {...params} />}
                  value={addRowInput}
                  onChange={(e, newValue) => setAddRowInput(newValue)}
                  renderClear={() => null}
                  disabled={branchLocationForm?.isEdit}
                />
              </div>
              <button
                onClick={() => addRow()}
                className="add-row-btn"
                disabled={branchLocationForm?.isEdit}
              >
                +
              </button>
            </div>
          </>
        ) : (
          <div className="new-global-input-container">
            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="Vehicle No*"
                variant="outlined"
                type="text"
                focused
                value={branchLocationForm?.vehicleNo}
                onChange={getBranchLocForm("vehicleNo")}
                disabled={branchLocationForm?.isEdit}
              />
            </div>
            <div className="new-global-single-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={allActiveEmpList || []}
                getOptionLabel={(option) =>
                  `${option?.emp_id}-${option?.staff_name}`
                }
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Employee Name*" focused />
                )}
                value={branchLocationForm?.employeeName}
                onChange={getBranchLocForm("employeeName")}
                disabled={branchLocationForm?.isEdit}
              />
            </div>
          </div>
        )}
      </div>
      <div
        className="new-global-white-bg-container"
        style={{ display: "flex", padding: "8px 2%" }}
      >
        <div className="new-global-single-input" style={{ width: "40%" }}>
          <p>Customer Notes</p>
          <textarea
            name=""
            id=""
            cols="50"
            rows="5"
            value={branchLocationForm?.customerNote}
            onChange={getBranchLocForm("customerNote")}
            disabled={branchLocationForm?.isEdit}
          ></textarea>
        </div>
        <div style={{ display: "flex", flexDirection: "column", width: "60%" }}>
          <div className="file-picker-new">
            <input
              type="file"
              id="fileUpload"
              onChange={changeImage}
              disabled={branchLocationForm?.isEdit}
            />
            <label htmlFor="fileUpload" disabled={branchLocationForm?.isEdit}>
              <img src={uploadFile} alt="" />
              Upload File
            </label>
          </div>
          <div style={{ display: "flex", margin: "0 0 0 22px" }}>
            {displayImage?.map((r, i) => (
              <div className="uploaded-image-list">
                <img src={r?.url} alt="" />
                <div className="image-remove-icon-new">
                  <IconButton
                    onClick={() => removeImageFn(i)}
                    disabled={branchLocationForm?.isEdit}
                  >
                    <CloseOutlined
                      sx={{ color: "#fd0301", fontSize: "0.9rem" }}
                    />
                  </IconButton>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {singleView !== undefined && (
        <div
          className="new-global-white-bg-container new-log-container"
          style={{ padding: "4px" }}
        >
          <div
            style={{ padding: "8px", cursor: "pointer" }}
            onClick={clickLogView}
          ></div>
          <div
            className={logView ? "pointer-up" : "pointer"}
            onClick={clickLogView}
            style={{ cursor: "pointer" }}
          ></div>
          {logView && (
            <div className="single-log-container">
              {log?.map((r, i) => (
                <div className="single-log-item">
                  <div className="single-log-item-line-image">
                    <hr className="single-log-item-line" />
                    <div className="single-log-item-image">
                      <img src={logoImage} alt="" />
                    </div>
                    <hr className="single-log-item-line" />
                  </div>
                  <p className="heading">{r?.status}</p>
                  <p className="name">{r?.empId}</p>
                  <p className="date-time">{r?.date}</p>
                </div>
              ))}
            </div>
          )}

          {/* <div className='single-log-container-scroll-line'></div> */}
        </div>
      )}

      <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />

    <LoadingForm loading={loadingForm}/>

    </div>
  );
};
