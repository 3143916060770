import axios from "axios";
import { update_invoice } from "../../Redux/Orders/Invoice/invoiceSlice";
import { update_all_products_list } from "../../Redux/Product List/allProductsListSlice";
import { update_bill_order_held } from "../../Redux/Sales/Billing/billOrderHeldProductsSlice";
import store from "../../Redux/store";
import { HEADERS } from "../UrlAndPaths";

//Final billing API
export const finalBillingAPICall = async (
  body,
  setIsLoading,
  setState,
  state,
  clearList,
  openInvoiceDialog
) => {
  setIsLoading(true);
  await axios
    .post("sales/billorder", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setState({...state,message:"Success 👍",success:true})

        setIsLoading(false);
        clearList();
        generateBillingInvoiceAPICall(
          { _id: res.data?._id },
          openInvoiceDialog
        );
      }
    })
    .catch((err) => {
      setIsLoading(false);
      if(err.response.status === 500){
        setState({...state,message:"Internal Server error !",error:true})
      }
      else{
        setState({...state,message:err?.response?.data,error:true})
      }
  
    
    });
};

//Billing Invoice API
export const generateBillingInvoiceAPICall = async (
  body,
  openInvoiceDialog
) => {
  await axios
    .post("sales/orderInvoiceView", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_invoice({ invoice: res.data }));
        openInvoiceDialog(true);
      }
    })
    .catch((err) => {
      store.dispatch(update_invoice({ invoice: undefined }));
      console.error(err.response);
    });
};

//Product List for Billing
export const viewProductListForBilling = async (
  body,
  setIsLoading,
  setErrorSnack,
  setSnackMsg
) => {
  if (setIsLoading !== undefined) setIsLoading(true);

  await axios
    .post("readymade/viewproductsforbilling", body, Headers)
    .then((res)=>{
      if(res.status===200)
      {
        //Store to slice
        store.dispatch(update_all_products_list({ allProductsList: res.data }))
        if (setIsLoading !== undefined) setIsLoading(false);
      }
    })
    .catch((err)=>{
      store.dispatch(update_all_products_list({ allProductsList: undefined }))
      switch (err.response.status) {
        case 401:
          setSnackMsg("Unauthorized!!");
          break;
        case 403:
          setSnackMsg("Forbidden!!");
          break;
        case 409:
          setSnackMsg("Already paid!!");
          break;
        case 500:
          setSnackMsg("Server error!!");
          break;

        default:
          break;
      }
      setErrorSnack(true);
      if (setIsLoading !== undefined) setIsLoading(false);
    });
};

//add billing hold
export const addBillingHoldAPICall= async (body,setState,
  state,clearState)=>{
  await axios.post('sales/addholdorder',body,HEADERS).then((res)=>{
    if(res.status===200)
    {
      setState({...state,message:"Order Held Succesfully",success:true})

      clearState()
    }
  }).catch((err)=>{
    setState({...state,message:err.response.data.msg,error:true})

  })
}

//View billing held orders
export const getBillingHeldOrdersAPICall= async (body)=>{
  await axios.post('sales/viewheldorder',body,HEADERS).then((res)=>{
    if(res.status===200)
    {
      //save to slice
      store.dispatch(update_bill_order_held({data:res.data}))
    }
  }).catch((err)=>{
    console.log(err.response.data?.msg);
    store.dispatch(update_bill_order_held({data:undefined}))
  })
}


