import {createSlice} from '@reduxjs/toolkit'

export const damagedGoodsSlice=createSlice({
    name:'damagedGoodSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_damaged_goods:(state,action)=>{
            state.value=action.payload.damagedGoodsData
        }
    }
})
export const {get_damaged_goods} = damagedGoodsSlice.actions;
export default damagedGoodsSlice.reducer