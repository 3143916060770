import { createSlice } from "@reduxjs/toolkit";

export const rewardsAndPointsSlice=createSlice({
    name:"rewardsAndPointsSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        get_rewards_and_points:(state,action)=>{
            state.value=action.payload.rewardsAndPoints
        }
    }
})
export const {get_rewards_and_points} = rewardsAndPointsSlice.actions;
export default rewardsAndPointsSlice.reducer