import { Autocomplete, Dialog, DialogContent, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import MuiEditButton from '../components/MuiEditButton';
import MuiSaveBtn from '../components/MuiSaveBtn';
import MuiBackBtn from '../components/MuiBackBtn';
import MuiToggleBtn from "../components/MuiToggleBtn";
import { useSelector } from 'react-redux';
import { ListConfigurationAPI } from '../configurationAPI';
import { ViewPayHeadAPI, AddPayHeadAPI, UpdatePayHeadByIdAPI, ViewPayHeadByIdAPI } from '../payHead/payHeadAPI';
import { chartOfAccountListApiCall } from '../../../../../API/AccountUpdated/chartOfAccountAPI';

const PayOther = () => {

  const viewPayHeadRedux = useSelector((state) => state?.payHead?.payHeadListView)
  const viewPayHeadById = useSelector((state) => state?.payHead?.payHeadViewById)

  const chartOfAccountList = useSelector(
    (state) => state.chartOfAccountsSlice.value
  );
  const expenseAccList = chartOfAccountList?.filter(
    (item) => item.type === "Expenses"
  );
  const [viewPayHead, setviewPayHead] = useState()
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState({
    _id: '',
    isPayhead: '',
    code: '',
    category: '',
    name: '',
    calculationType: '',
    amountOrPerc: '',
    expAccnt: null,
    calculationType: '',
    includedInBasic: ''
  });

  const categories = [
    { id: "1", label: "bonus" }
  ];

  const handleEditClick = () => {
    setEditMode(true);
  };
  const handleCardClick = (id) => {
    setFormData({
      _id: '',
      isPayhead: '',
      code: '',
      category: '',
      name: '',
      calculationType: '',
      amountOrPerc: '',
      expAccnt: null,
      calculationType: '',
      includedInBasic: ''
    });
    let payload = {
      id: id
    }
    ViewPayHeadByIdAPI(payload)
    setOpenDialog(true);
    setEditMode(false);
  };
  const handleClickCreatePayHead = () => {
    setOpen(!open);
  };
  const handleBackBtnClicked = () => {
    setOpen(false);
  };
  const handleBackClicked = () => {
    setOpenDialog(false);
  };
  const handleSave = () => {

    

    const includedInBasicValue = formData?.includedInBasic === "True" ? true : false;

    let data = {
      id: formData?._id,
      isPayhead: formData.isPayhead === "true" ? true : false,
      category: formData?.category,
      includedInBasic: formData?.category === "allowance" ? includedInBasicValue : false,
      name: formData?.name,
      code: formData?.code,
      calculationType: formData?.calculationType,
      amountOrPerc: formData?.amountOrPerc,
      expAccnt: formData?.expAccnt?._id,
      calculationType: formData?.calculationType,
    }
    UpdatePayHeadByIdAPI(data, setOpenDialog)
    ViewPayHeadAPI({ isPayhead: false });
    setFormData({
      _id: '',
      isPayhead: '',
      code: '',
      category: '',
      name: '',
      calculationType: '',
      amountOrPerc: '',
      expAccnt: null,
      calculationType: '',
      includedInBasic: ''
    });
    setOpenDialog(false);
  };
  const handleSubmit = () => {
    const includedInBasicValue = formData?.includedInBasic === "True" ? true : false;
    let data = {

      isPayhead: formData.isPayhead,
      category: formData?.category,
      includedInBasic: formData?.category === "allowance" ? includedInBasicValue : false,
      name: formData?.name,
      code: formData?.code,
      // calculationType: formData?.calculationType,
      amountOrPerc: formData?.amountOrPerc,
      expAccnt: formData?.expAccnt?._id,
    }

    if (typeof data.isPayhead === 'string') {
      data.isPayhead = data.isPayhead === 'true';
    }

    AddPayHeadAPI(data, setOpen)
    setFormData({
      _id: '',
      isPayhead: '',
      code: '',
      category: '',
      name: '',
      calculationType: '',
      amountOrPerc: '',
      expAccnt: null,
      calculationType: '',
      includedInBasic: ''
    });
    setOpen(false)
    // ViewPayHeadAPI({ isPayhead: data.isPayhead });
    ViewPayHeadAPI({ isPayhead: false });
    setviewPayHead(viewPayHeadRedux)

  };
  useEffect(() => {
    if (viewPayHeadById) {
      setFormData({
        isPayhead: viewPayHeadById.isPayhead === true ? "true" : 'false',
        _id: viewPayHeadById?._id,
        category: viewPayHeadById?.category,
        name: viewPayHeadById?.name,
        code: viewPayHeadById?.code,
        calculationType: viewPayHeadById?.calculationType,
        amountOrPerc: viewPayHeadById?.amountOrPerc,
        expAccnt: { accountName: viewPayHeadById?.expAccntName },
        calculationType: viewPayHeadById?.calculationType,
        includedInBasic: viewPayHeadById?.includedInBasic === true ? "True" : 'False',
      });
    }
    if (viewPayHeadRedux) {
      setviewPayHead(viewPayHeadRedux)
    }

  }, [
    viewPayHeadById, viewPayHeadRedux])

  useEffect(() => {
    chartOfAccountListApiCall();
    ViewPayHeadAPI({ isPayhead: false });
  }, [])

  return (
    <div className="pay-head-container">
      <div className="pay-head-wrapper">

        <div className="pay-head-nav">
          <h3 className="head-name">Pay Other</h3>
          <div className="pay-head-btn-cntr">
            <button
              onClick={() => handleClickCreatePayHead()}
              className="pay-head-btn"
            >
              Create Pay other
            </button>
          </div>
        </div>
        <div className="card-container">

          {/* viewPayHead */}
          {viewPayHead !== undefined &&
            viewPayHead?.length !== 0 &&
            viewPayHead?.map((item, index) => (
              <div
              onClick={() => handleCardClick(item._id)}
                className="payroll-card-wrapper">
                <div className="payroll-container">
                  <div>
                    <div>
                      <p className="sub-name">CATEGORY</p>
                      <h3 className="name">{item.category}</h3>
                    </div>
                    <div>
                      <p className="sub-name">NAME</p>
                      <h3 className="name">{item.name}</h3>
                    </div>
                    <div>
                      <div className="status-bar" style={{ backgroundColor: "#ffcbcb" }}>
                        <span style={{ color: "red" }}>InActive</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <p className="sub-name">AMOUNT</p>
                      <h3 className="name">{item.amountOrPerc}</h3>
                    </div>
                    <div>
                      <p className="sub-name">DEFAULT ACCOUNT</p>
                      <h3 className="name">{item.expAccnt}</h3>
                    </div>
                  </div>

                </div>
              </div>
            ))}
          {/* <PayrollCard /> */}

        </div>
      </div>
      {/* add dialogue box */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        sx={{ padding: "5em", }}
      >
        <div style={{ width: "63vw" }}>
          <DialogContent dividers>
            <Grid
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid>
                <MuiBackBtn onClick={handleBackBtnClicked} />

                <MuiSaveBtn onClick={handleSubmit} />
              </Grid>
              <Grid
                gap={3}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography fontWeight={500} id="alert-dialog-title">
                  Active
                </Typography>
                <MuiToggleBtn
                  checked={formData.isPayhead === "true"}
                  onChange={(e) => setFormData({ ...formData, isPayhead: e.target.checked ? "true" : "false" })}
                  inputProps={{ 'aria-label': 'toggle pay head' }} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogContent className="pay-head-dialog">

            <div className="new-global-single-input" style={{ width: "22%" }}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={categories || []}
                getOptionLabel={(option) => option?.label}
                sx={{ width: 300 }}
                renderOption={(props, option) => (
                  <li {...props} key={option?._id}>
                    <span>{option?.label}</span>
                  </li>
                )}
                renderInput={(params) => <TextField {...params} label="CATEGORY*" focused />}
                value={categories.find((option) => option.label === formData.category) || null}
                onChange={(e, newValue) => setFormData({ ...formData, category: newValue?.label || "" })}
              />
              {/* <p className="doc-validation-alert">{validationAlert?.dLocation}</p> */}
            </div>
            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="CODE*"
                variant="outlined"
                type="text"
                focused
                value={formData?.code}
                onChange={(e) => setFormData({ ...formData, code: e.target.value })}

              // disabled={internalTransferForm?.isEdit}
              />
            </div>
            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="NAME*"
                variant="outlined"
                type="text"
                focused
                value={formData?.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}

              // disabled={internalTransferForm?.isEdit}
              />
            </div>

            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="AMOUNT"
                variant="outlined"
                type="number"
                focused
                value={formData?.amountOrPerc}
                onChange={(e) => setFormData({ ...formData, amountOrPerc: e.target.value })}

              // disabled={internalTransferForm?.isEdit}
              />
            </div>

            <div className="new-global-single-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={expenseAccList || []}
                getOptionLabel={(option) => option?.accountName || ""}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="DEFAULT ACCOUNT*" focused />
                )}
                value={formData.expAccnt || ""}
                onChange={(e, newValue) => {
                  setFormData({ ...formData, expAccnt: newValue })
                }}
              />



            </div>


          </DialogContent>
        </div>

      </Dialog>

      {/* card dialogu ebox */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        sx={{ padding: "5em", }}
      >
        <div style={{ width: "63vw" }}>
          <DialogContent dividers>
            <Grid
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid>
                <MuiBackBtn  onClick={handleBackClicked}/>
                {/* <MuiEditButton /> */}
                <Tooltip title="Edit">
                  <IconButton onClick={handleEditClick}>
                    <i className="bi bi-pencil-square edit-icon1"></i>
                  </IconButton>
                </Tooltip>
                <MuiSaveBtn onClick={handleSave}/>
              </Grid>
              <Grid
                gap={3}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography fontWeight={500} id="alert-dialog-title">
                  Active
                </Typography>
                <MuiToggleBtn
                  checked={formData.isPayhead === "true"}
                  onChange={(e) => setFormData({ ...formData, isPayhead: e.target.checked ? "true" : "false" })}
                  inputProps={{ 'aria-label': 'toggle pay head' }} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogContent className="pay-head-dialog">

            <div className="new-global-single-input" style={{ width: "22%" }}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={categories || []}
                getOptionLabel={(option) => option?.label}
                sx={{ width: 300 }}
                renderOption={(props, option) => (
                  <li {...props} key={option?._id}>
                    <span>{option?.label}</span>
                  </li>
                )}
                renderInput={(params) => <TextField {...params} label="CATEGORY*" focused />}
                value={categories.find((option) => option.label === formData.category) || null}
                onChange={(e, newValue) => setFormData({ ...formData, category: newValue?.label || "" })}
                disabled={!editMode}
              />
              {/* <p className="doc-validation-alert">{validationAlert?.dLocation}</p> */}
            </div>
            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="CODE*"
                variant="outlined"
                type="text"
                focused
                value={formData?.code}
                onChange={(e) => setFormData({ ...formData, code: e.target.value })}
                disabled={!editMode}
              // disabled={internalTransferForm?.isEdit}
              />
            </div>
            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="NAME*"
                variant="outlined"
                type="text"
                focused
                value={formData?.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                disabled={!editMode}
              // disabled={internalTransferForm?.isEdit}
              />
            </div>

            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="AMOUNT"
                variant="outlined"
                type="number"
                focused
                value={formData?.amountOrPerc}
                onChange={(e) => setFormData({ ...formData, amountOrPerc: e.target.value })}
                disabled={!editMode}
              // disabled={internalTransferForm?.isEdit}
              />
            </div>

            <div className="new-global-single-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={expenseAccList || []}
                getOptionLabel={(option) => option?.accountName || ""}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="DEFAULT ACCOUNT*" focused />
                )}
                value={formData.expAccnt || ""}
                onChange={(e, newValue) => {
                  setFormData({ ...formData, expAccnt: newValue })
                }}
                disabled={!editMode}
              />



            </div>


          </DialogContent>
        </div>
        {/* <DialogActions>
          <Button onClick={"handleClose"}>Disagree</Button>
          <Button onClick={"handleClose"} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  )
}

export default PayOther