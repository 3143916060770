import React from "react";
import "../../css/Single Components/clickableCard.css";
export const ClickableCard = (props) => {
  const { icon, bgColor, label, onClick,marginLeft } = props;
  const style = { backgroundColor: bgColor,marginLeft:marginLeft };
  return (
    <div className="clickable-card-parent-container" style={style} onClick={onClick}>
      <div className="icon-container">
        <img src={icon} alt="icon" />
      </div>
      <div className="label-container">
        <p className="label">{label}</p>
      </div>
    </div>
  );
};
