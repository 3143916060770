import {createSlice} from '@reduxjs/toolkit'

export const receivedTransferSlice=createSlice({
    name:"receivedTransferSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        get_all_received_transfer:(state,action)=>{
            state.value=action.payload.receivedTransferData
        }
    }
})
export const {get_all_received_transfer} = receivedTransferSlice.actions;
export default receivedTransferSlice.reducer;