import axios from "axios";
import { update_all_offer_list } from "../../Redux/Offer/allOfferListSlice";
import store from "../../Redux/store";
import { HEADERS, HEADER_QUERY } from "../UrlAndPaths";

// add StockOut Products

export const addOfferAPICall = async (body, setSuccess, setError, setMsg) => {
  await axios
    .post("offer/addOffer", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setMsg("Offer Added");
        setSuccess(true);
      }
    })
    .catch((err) => {
      console.error(err.response);
      setError(true);
    });
};

// offer List

export const viewAddedOfferAPICall = async (
  setIsLoading,
  setErrorSnack,
  setSnackMsg,
  body
) => {
  if (setIsLoading !== undefined) setIsLoading(true);
  await axios
    .post("offer/viewOffer", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_all_offer_list({
            allOfferData: res.data,
          })
        );
        if (setIsLoading !== undefined) setIsLoading(false);
      }
    })
    .catch((err) => {
      store.dispatch(
        update_all_offer_list({
          allOfferData: undefined,
        })
      );
      console.error(err.response);
      switch (err.response.status) {
        case 404:
          setSnackMsg("No data available..");
          break;
        case 500:
          setSnackMsg("Server error!!");
          break;
        case 401:
          setSnackMsg("Unauthorized!!");
          break;
        case 403:
          setSnackMsg("Forbidden!!");
          break;

        default:
          setSnackMsg(
            "Unseen error occurred,Please contact to EYET Support!!!!"
          );
          break;
      }
      setErrorSnack(true);
      if (setIsLoading !== undefined) setIsLoading(false);
    });
};

// edit offer APICall

export const editOfferAPICall = async (body) => {
  await axios
    .put("offer/editOffer", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("Zz");
      }
    })
    .catch((err) => {
      console.error(err.response);
    });
};

// Delete Offer API Call

export const deleteOfferAPICall = async (body, updateListener) => {
  await axios
    .delete("offer/deleteOffer", {
      headers: HEADER_QUERY,
      data: body,
    })
    .then((res) => {
      if (res.status === 200) {
        updateListener();
      }
    })
    .catch((err) => {
      console.error(err.response);
    });
};
