import { createSlice } from "@reduxjs/toolkit";

export const getInvoiceForDeliverySlice=createSlice({
    name:"deliveryInvoiceNoList",
    initialState:{
        value:undefined
    },
    reducers:{
        get_delivery_invoiceNo:(state,action)=>{
            state.value=action.payload.deliveryInvoiceList
        }
    }
})
export const {get_delivery_invoiceNo} = getInvoiceForDeliverySlice.actions;
export default getInvoiceForDeliverySlice.reducer