import { createSlice } from "@reduxjs/toolkit";

export const unitsSlice=createSlice({
    name:'unitsSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_unit_list:(state,action)=>{
            state.value=action.payload.units
        }
    }
})

export const {update_unit_list}=unitsSlice.actions
export default unitsSlice.reducer