import React, { useEffect, useState } from 'react'
import img1 from "../../../Assets/Images/AI Invoice-04.jpg";
import img2 from "../../../Assets/Images/AI Invoice-02.jpg";
import img3 from "../../../Assets/Images/AI-Invoice-Template-03.png";
import { templateSetAPI } from './SettingsNewAPI';
import { useSelector } from 'react-redux';
import { getTemplateAPI } from '../SalesNew/SalesOrders/SalesQuotation/salesQuatationAPI';


export const TemplateModel = () => {
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const handleTemplateChange = (template) => {
        setSelectedTemplate(template);
        templateSetAPI({templateModel:template})
    }
    console.log(selectedTemplate);
    const template =useSelector((state)=>state?.salesQuatationSlice?.setTemplate)
   console.log(template);
   useEffect(() => {

    if(template){
        setSelectedTemplate(template.templateModel)
    }
    else{
        getTemplateAPI();
    }
   }, [template])
   
    return (
        <div className='settings-template-container'>
              <div>
                <input
                    type="radio"
                    name="template"
                    // value="template1"
                    checked={selectedTemplate === 'template1'}
                    onChange={() => handleTemplateChange('template1')}
                />
                <label>
                    Template 1
                </label>
                <div>
                <img src={img1} alt="" style={{ maxWidth: '256px', maxHeight: '334px', marginLeft: '21px' }} />
            </div>
            </div>
           
            <div>
                <input
                    type="radio"
                    name="template"
                    // value="template2"
                    checked={selectedTemplate === 'template2'}
                    onChange={() => handleTemplateChange('template2')}
                />
                <label>
                    Template 2
                </label>
                <div>
                <img src={img2} alt="" style={{ maxWidth: '256px', maxHeight: '334px', marginLeft: '21px' }} />
            </div>
            </div>
           
            <div>
                <input
                    type="radio"
                    name="template"
                    // value="template3"
                    checked={selectedTemplate === 'template3'}
                    onChange={() => handleTemplateChange('template3')}
                />
                <label>
                    Template 3
                </label>
                <div>
                <img src={img3} alt="" style={{ maxWidth: '256px', maxHeight: '334px', marginLeft: '21px' }} />
            </div>
            </div>
          
        </div>
    )
}
