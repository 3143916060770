import React, { useState } from "react";
import { IconButton, Dialog, Skeleton } from "@mui/material";
import {
  calculateTotalCost,
  extractObjectForSingleView,
} from "../../../../../Js/generalFunctions";
import {
  finalFinishConfirmStockAPICall,
  stockAdjustmentConfirmListAPICall,
  viewAllStockAdjustmentAPICall,
} from "../../../../../API/Purchase Manager/stockAdjustmentAPI";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import SuccessSnackbar from "../../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
import { convertDateFormat,getCurrentTime,today } from "../../../../../Js/Date";

export const ConfirmStock = () => {
  //Current branch ID
  const bId = localStorage.getItem("branchId");
  const loginMeta = localStorage.getItem('login_meta')
  const bPk= JSON.parse(loginMeta)?.branchPk
  const [confirmViewDialog, setConfirmViewDialog] = useState(false);
  const [confirmEditDialog, setConfirmEditDialog] = useState(false);

  //*Redux States
  const allStockAdjList = useSelector((state) => state.allStockAdjSlice.value);
  const StockAdjConfirmList = useSelector((state) => state.stockAdjustmentConfirmListSlice.value);

  //Update Listener States
  const [isModify, setIsModify] = useState(false);

  //Single view Obj
  const [singleViewObj, setSingleViewObj] = useState([]);
  //received Stock Items array
  const [itemsArray, setItemsArray] = useState([]);
  //Editable Row
  const [editableRow, setEditableRow] = useState([]);
  //Editable Row Index
  const [editableRowIndex, setEditableRowIndex] = useState(null);
  //Editable Adj Qty State
  const editableRowFormInitialState = {
    prodName: "",
    shareQty:'',
    adjQty: 0,
    unit: "",
    unitCost: "",
    totalCost: "",
  };
  const [editableRowForm, setEditableRowForm] = useState(
    editableRowFormInitialState
  );

  //SnackBar States
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!");

  // validation states
  const [shareQtyAlert,setShareQtyAlert]=useState(false)
   // loading state
   const [isLoading,setIsloading]=useState(false)
   const [searchKeyword,setSearchKeyword]=useState("")
   const [filteredSearchList,setFilteredSearchList]=useState([])
  //Close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setSuccessSnackOpen(false);
  };
  //Close ErrorSnackbar
  const closeErrorSnackbar = () => {
    setErrorSnackOpen(false);
  };

  //Update Listener
  const updateListener = () => {
    setIsModify(!isModify);
  };
  const dateToday={today}
  const currentTime=getCurrentTime()
  //*useEffects
  //initial API Calls
  useEffect(() => {
    // viewAllStockAdjustmentAPICall({ toLoc: bId !== "null" ? bId : null });
    stockAdjustmentConfirmListAPICall(
      {fromLoc:bPk!=='null'?bPk:null},
      setIsloading,setErrorSnackOpen,setSnackMsg
      )
  }, [isModify]);

  //Assign Selected Object's Purchase Info List into separate state
  useEffect(() => {
    if (singleViewObj !== undefined && singleViewObj?.length > 0) {
      const { purchaseInfo } = singleViewObj[0];
      setItemsArray(purchaseInfo);
    }
  }, [singleViewObj]);

  //Set Editable Row Form by actual values
  useEffect(() => {
    if (editableRow !== undefined && editableRow?.length > 0) {
      const { itemName,shareQty, adjQty, unit, unitCost, totalCost } = editableRow[0];
      console.log(itemName);
      setEditableRowForm({
        ...editableRowForm,
        prodName: itemName,
        shareQty,
        adjQty,
        unit,
        unitCost,
        totalCost,
      });
    }
  }, [editableRow]);
  //Calculate Total Amount onChange of Adjust qty
  useEffect(() => {
    let totalCost = calculateTotalCost(
      editableRowForm?.unitCost,
      editableRowForm?.shareQty
    );
    setEditableRowForm({ ...editableRowForm, totalCost });
  }, [editableRowForm?.shareQty]);

  //Close Editable Popup [2nd Dialog]
  const editConfirmCancel = () => {
    setConfirmEditDialog(false);
    setConfirmViewDialog(true);
  };

  //Close Confirm Stock Dialog [1st Dialog]
  const closeConfirmStockDialog = () => {
    setConfirmViewDialog(false);
  };

  //Find total Cost of an object array
  const findTotalCost = (purchaseInfo) => {
    let totalCost = 0;

    purchaseInfo?.forEach((obj, i) => {
      totalCost += obj?.totalCost;
    });

    // console.log(totalCost);
    return totalCost;
  };

  //*Click Fns
  //Opens Confirm Dialog [1st Dialog]
  const openConfirmStockDialog = (selectedId, completeList) => () => {
    if (completeList !== undefined) {
      let extractedObj = extractObjectForSingleView(selectedId, completeList);
      setSingleViewObj(extractedObj);
      setConfirmViewDialog(true);
    }
  };

  //Open Editable Confirm Dialog [2nd Dialog]
  const openEditableConfirmDialog = (selectedId, completeList, index) => () => {
    if (completeList !== undefined && completeList?.length > 0) {
      let editableObj = extractObjectForSingleView(selectedId, completeList);
      setEditableRowIndex(index);
      setEditableRow(editableObj);
      setConfirmViewDialog(false);
      setConfirmEditDialog(true);
    }
  };

  //Editable Popup Edit Button [2nd Dialog]
  const editShareQty = () => {
    if (editableRowIndex !== null && editableRowForm?.shareQty !== "") {
      let editedRow = itemsArray?.map((obj, i) => {
        if (i === editableRowIndex) {
          return {
            ...obj,
            shareQty: parseFloat(editableRowForm?.shareQty),
            totalCost: parseFloat(editableRowForm?.totalCost),
          };
        } else {
          return obj;
        }
      });
      setItemsArray([...editedRow]);
      editConfirmCancel();
    } else {
      setShareQtyAlert(true)
    }
  };

  //Final Finish Button Click[1st Dialog Finish button]
  const confirmStockAdjBody = {
    _id: singleViewObj[0]?._id,
    purchaseInfo: itemsArray,
    date:`${dateToday.today} ${currentTime}`
  };
  const finalButtonClick = () => {
    if(itemsArray?.length>0)
    {
    finalFinishConfirmStockAPICall(
      confirmStockAdjBody,
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      closeConfirmStockDialog,
      updateListener
    );
  }
  else
  {
    alert('No Items to confirm!!' )
  }
  };

  //*onChange Fn
  //Adj Qty onchange Fn
  const getShareQty = (e) => {
    setEditableRowForm({ ...editableRowForm, shareQty: e.target.value });
    setShareQtyAlert(false)
    if(e.target.value===""){
      setShareQtyAlert(true)
    }
    };
  const getSearchKeyword=(e)=>{
    setSearchKeyword(e.target.value)
  }
  const SearchFilter=(chars,listToFilter)=>{
    let searchedArray=[]
    let filteredArray=[]
    if(chars!==""){
      searchedArray=listToFilter.filter((obj)=>
      obj?.transNo?.toLowerCase()?.includes(chars?.toLowerCase())||
      obj?.frmBranchName?.toLowerCase()?.includes(chars?.toLowerCase())
    )
      filteredArray=searchedArray
    }
    else{
      filteredArray=listToFilter
    }
    return filteredArray
  }
  useEffect(()=>{
    if(StockAdjConfirmList!==undefined){
      let filteredArray=[]
      setFilteredSearchList(StockAdjConfirmList)
      filteredArray=SearchFilter(searchKeyword,StockAdjConfirmList)
      setFilteredSearchList(filteredArray)    
    }
    else{
      setFilteredSearchList([])
    }
    },[searchKeyword,StockAdjConfirmList])
  return (
    <div className="global-page-parent-container">
      <p className="breadcrumb">
        Purchase Manage &gt; Stock Adjustment &gt; Confirm Stock
      </p>
      <div className="received-stock-container">
        <div className="stock-out-product-top-container" style={{alignItems:"center"}}>
          <h3>Confirm Stock</h3>
            <div className="view-order-search-container view-order-admin-search-container"
              style={{width:"25%"}}>
                  <input
                    placeholder="Search Transaction Id,Location"
                    value={searchKeyword}
                    onChange={getSearchKeyword}
                  />
                  <IconButton>
                      <i class="bi bi-search search-icon-order"></i>
                  </IconButton>
              </div>
          </div>
        <div className="global-table-container">
          <table className="global-table">
            <thead>
              <tr>
                {/* <th>No</th> */}
                <th>Transaction ID</th>
                <th>Location</th>
                <th>Date</th>
                <th>Remarks</th>
                <th>Total Cost</th>
                <th>View</th>
                {/* <th>Edit</th> */}
                <th>Delete</th>
              </tr>
            </thead>
            {
              isLoading?
                <tbody>
                  <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr> 
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr> 
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr> 
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
            </tbody>:
            <tbody>
              {
              filteredSearchList?.length!==0 ?
              filteredSearchList?.slice(0)?.reverse()?.map((r, i) => {
                if (r?.status === "Confirmed") {
                  return (
                    <tr key={i}>
                      {/* <td>01</td> */}
                      <td>{r?.transNo}</td>
                      <td>{r?.frmBranchName}</td>
                      <td>{convertDateFormat(r?.date)}</td>
                      <td>{r?.remarks}</td>
                      <td>{findTotalCost(r?.purchaseInfo)}</td>
                      <td>
                        <IconButton
                          onClick={openConfirmStockDialog(
                            r?._id,
                            StockAdjConfirmList
                          )}
                        >
                          <i class="bi bi-eye visibility-icon"></i>
                        </IconButton>
                      </td>
                      {/* <td>
                        <IconButton>
                          <i class="bi bi-pencil-square edit-icon"></i>
                        </IconButton>
                      </td> */}
                      <td>
                        <IconButton disabled className="disabled-icon-button">
                          <i class="bi bi-trash3 delete-icon"></i>
                        </IconButton>
                      </td>
                    </tr>
                  );
                }
              }):
              <tr><td colSpan={8} style={{textAlign:"center"}}>No Records</td></tr>
              }
            </tbody>
            }
          </table>
        </div>
      </div>

      {/* confirm stock view dialog */}
      <Dialog open={confirmViewDialog} maxWidth="lg"  onKeyDown={(e)=>e.key === "Escape" && setConfirmViewDialog(false)}>
        <div className="received-stock-dialog-container">
          <h3>Stock Adjustment Confirm</h3>
          <div className="received-stock-dialog-top-container">
            <div className="global-single-input received-stock-input">
              <p>Transaction ID</p>
              <input disabled value={singleViewObj[0]?.transNo} />
            </div>
            <div className="global-single-input received-stock-input">
              <p>Location</p>
              <input disabled value={singleViewObj[0]?.frmBranchName} />
            </div>
          </div>
          <div className="received-stock-dialog-table-container">
            <h3>Adjust Product</h3>
            <div className="global-table-container received-stock-table">
              <table className="global-table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Product Name</th>
                    <th>Share Qty</th>
                    <th>Adjust Qty</th>
                    <th>Unit</th>
                    <th>Unit Cost</th>
                    <th>Total Cost</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {itemsArray?.length > 0 ? (
                    itemsArray?.map((r, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{r?.itemName}</td>
                        <td>{r?.shareQty}</td>
                        <td>{r?.adjQty}</td>
                        <td>{r?.unit}</td>
                        <td>{r?.unitCost}</td>
                        <td>{r?.totalCost}</td>
                        <td>
                          <IconButton
                            onClick={openEditableConfirmDialog(
                              r?._id,
                              itemsArray,
                              i
                            )}
                          >
                            <i class="bi bi-pencil-square edit-icon"></i>
                          </IconButton>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7}>No Items</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="received-stock-single-view-button-container">
            <button
              onClick={closeConfirmStockDialog}
              className="btn btn-secondary-outlined"
            >
              Cancel
            </button>
            <button className="btn btn-secondary" onClick={finalButtonClick}>Finish</button>
          </div>
        </div>
      </Dialog>

      {/* confirm stock edit dialog */}
      <Dialog open={confirmEditDialog} maxWidth="lg"  onKeyDown={(e)=>e.key === "Escape" && setConfirmEditDialog(false)}>
        <div className="received-stock-dialog-container">
          <h3>Edit</h3>
          <div className="received-stock-dialog-top-container">
            <div className="global-single-input received-stock-input">
              <p>Transaction ID</p>
              <input disabled value={singleViewObj[0]?.transNo} />
            </div>
            <div className="global-single-input received-stock-input">
              <p>Location</p>
              <input disabled value={singleViewObj[0]?.frmBranchName} />
            </div>
          </div>
          <div className="received-stock-dialog-table-container">
            <h3>Adjust Product</h3>
            <div className="received-stock-edit-table-container">
              {/* <div className="global-single-input edit-table-input">
                <p>No</p>
                <input disabled value={"01"} />
              </div> */}
              <div className="global-single-input edit-table-input">
                <p>Product Name</p>
                <input disabled value={editableRowForm?.prodName} />
              </div>
              <div className="global-single-input edit-table-input adj-qty">
                <p>Share Qty</p>
                <input value={editableRowForm?.shareQty} onChange={getShareQty} />
                {shareQtyAlert?
                  <p className="doc-validation-alert">Enter !!</p>
                :""}
              </div>
              <div className="global-single-input edit-table-input">
                <p>Adjust Qty</p>
                <input value={editableRowForm?.adjQty}/>
              </div>
              <div className="global-single-input edit-table-input">
                <p>Unit</p>
                <input disabled value={editableRowForm?.unit} />
              </div>
              <div className="global-single-input edit-table-input">
                <p>Unit Cost</p>
                <input disabled value={editableRowForm?.unitCost} />
              </div>
              <div className="global-single-input edit-table-input">
                <p>Total Cost</p>
                <input disabled value={editableRowForm?.totalCost} />
              </div>
            </div>
          </div>
          <div className="received-stock-single-view-button-container">
            <button
              onClick={editConfirmCancel}
              className="btn btn-secondary-outlined"
            >
              Cancel
            </button>
            <button className="btn btn-secondary" onClick={editShareQty}>
              Edit
            </button>
          </div>
        </div>
      </Dialog>

      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </div>
  );
};
