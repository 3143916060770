import React,{useState,useEffect} from 'react'
import uploadIcon from '../../../../Assets/SVG/uploadIcon.svg'
import {IconButton,Select,MenuItem} from '@mui/material'
import {viewUpiManualAPICall,addUpiManualAPICall,editUpiManualAPICall} from '../../../../API/Settings/CardAndUpiType/UpiManualAPI'
import {useSelector} from 'react-redux'
import SuccessSnackbar from '../../../Single Components/SnackBars/SuccessSnackbar'
import ErrorSnackbar from '../../../Single Components/SnackBars/ErrorSnackbar'
import {viewAllUpiProvider} from '../../../../API/Settings/Company Settings/companySettingsAPI'
export const UpiManual = () => {
   const upiManual=useSelector((state)=>state.upiManualSlice.value)
   const upiProvider=useSelector((state)=>state.upiProviderSlice.value)
   
   //Update Listener States
   const [isModify, setIsModify] = useState(false);

  // states
  const [upiName,setUpiName]=useState('')
  const [upiCommission,setUpiCommission]=useState('')
  const [imgUrl,setImgUrl]=useState()
  const [selectedImgName,setSelectedImgName]=useState('')

  // edit states
  const [isEdit,setIsEdit]=useState(false)
  const [editedId,setEditedId]=useState()
  // snackbar states
  const [successSnackbar,setSuccessSnackbar]=useState(false)
  const [errorSnackbar,setErrorSnackbar]=useState(false)
  const [snackMsg,setSnackMsg]=useState('Error!!')

   useEffect(()=>{
    viewUpiManualAPICall()
    viewAllUpiProvider()
   },[isModify])

   //*Update Listener
   const updateListener = () => {
    setIsModify(!isModify);
    };

  // onChange functions
   const getUpiName=(e)=>{
     setUpiName(e.target.value)
   }
   const getUpiCommission=(e)=>{
     setUpiCommission(e.target.value)
   }
   const getImage=(e)=>{
    setImgUrl(e.target.files[0])
   }
 
   useEffect(()=>{
    setSelectedImgName(imgUrl?.name)
   },[imgUrl])

   // snackbar functions
  const closeSuccessSnackbar=()=>{
    setSuccessSnackbar(false)
   }
 const closeErrorSnackbar=()=>{
    setErrorSnackbar(false)
   }

  // add upi manual commission
  const formData=new FormData();
    formData.append("upiName",upiName)
    formData.append("commission",upiCommission)
    formData.append("file",imgUrl)
    
    const upiManualSubmitBtnFn=()=>{
      addUpiManualAPICall(
        formData,
        updateListener,
        setSuccessSnackbar,
        setErrorSnackbar,
        setSnackMsg,
        clearStates
        )
    }
  const clearStates=()=>{
    setUpiName('')
    setUpiCommission('')
    setImgUrl()
    setSelectedImgName('')
  }
 //  upi manual edit
  const clickEditBtn=(id,name,commission,image)=>{
    setIsEdit(true)
    setEditedId(id)
    setUpiName(name)
    setUpiCommission(commission)
    setImgUrl(image)
    setSelectedImgName(image.split('_').pop())
  }
  
  const editFormData=new FormData()
    editFormData.append("upiName",upiName)
    editFormData.append("commission",upiCommission)
    editFormData.append("_id",editedId)
    editFormData.append("file",imgUrl)
  
  const updateUpiManual=()=>{
    editUpiManualAPICall(
      editFormData,
      updateListener,
      setSuccessSnackbar,
      setErrorSnackbar,
      setSnackMsg,
      clearStates,
      setIsEdit
      )
      
  }
  return (
    <div className="card-manual-container">
    <div className="card-manual-top-container">
      <div className="global-single-input card-name-container">
        <p>Upi Name</p>
        <Select
          value={upiName}
          onChange={getUpiName}
        >
          {upiProvider?.map((r,i)=>(
            <MenuItem value={r.upiName} key={r._id}>{r.upiName}</MenuItem>
          ))}
          {/* <MenuItem value="google pay">GooglePay</MenuItem>
          <MenuItem value="phonepe">PhonePe</MenuItem>
          <MenuItem value="paytm">Paytm</MenuItem> */}
        </Select>
      </div>
      <div className="global-single-input card-name-container">
        <p>Commission(%)</p>
        <input 
        type="text"
        value={upiCommission}
        onChange={getUpiCommission}
        />
      </div>
      <div className="global-single-input card-type-logo-container">
        <p>Logo</p>
        <div className="card-type-logo">
           <input type="text" value={selectedImgName}/>
           <input type="file" id="card-logo" onChange={getImage}/>
           <label htmlFor="card-logo"><img src={uploadIcon} alt="uploadIcon" /></label>
        </div>
      </div>
    </div>
    <button 
    onClick={isEdit===true? updateUpiManual : upiManualSubmitBtnFn} 
    className={upiName===''||upiCommission===''||imgUrl===undefined ?
              "btn btn-primary-disabled card-manual-button"
              :
              "btn btn-primary card-manual-button"
              }

    disabled={upiName===''||upiCommission===''||imgUrl===undefined}
    >
      {isEdit === true ? "Update":"Submit"}
      </button>

   {
    upiManual !== undefined ?
    
    <div className="card-manual-table-container">
      <div className="global-table-container" style={{maxHeight:"51vh"}}>
         <table className="global-table">
            <thead>
              <tr>
                  <th>Card Name</th>
                  <th>Commission</th>
                  <th>Logo</th>
                  <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                upiManual?.slice(0)?.reverse()?.map((r,i)=>(
                  <tr>
                  <td>{r.upiName}</td>
                  <td>{r.commission}</td>
                  <td>
                    <img src={r.imageUrl} alt="upi-logo" 
                      style={{ height: 50, width: 50, objectFit: "fill",marginTop:"8%" }}
                    />
                  </td>
                  <td>
                  <IconButton
                    onClick={()=>clickEditBtn(
                      r._id,
                      r.upiName,
                      r.commission,
                      r.imageUrl
                    )}
                  >
                  <i class="bi bi-pencil-square edit-icon"></i>
                  </IconButton>
                  </td>
              </tr>
                ))
              }
              </tbody>
         </table>
      </div>
    </div>
    :
     <div className="card-manual-table-container">
       <h3  className="no-data">No Records</h3>
     </div>
    }
    <SuccessSnackbar
       open={successSnackbar}
       handleClose={closeSuccessSnackbar}
       message={snackMsg}
     />
     
     <ErrorSnackbar
       open={errorSnackbar}
       handleClose={closeErrorSnackbar}
       message={snackMsg}
     />
  </div>
  )
}
