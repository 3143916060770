import { createSlice } from "@reduxjs/toolkit";

export const printCuttingSlipFilteredDataSlice=createSlice({
    name:'printCuttingSlipFilteredData',
    initialState:{
        value:undefined
    },
    reducers:{
        update_print_cutting_slip_filtered_data:(state,action)=>{
            state.value=action.payload.printCuttingSlipFilteredData
        }
    }
})
export const {update_print_cutting_slip_filtered_data} = printCuttingSlipFilteredDataSlice.actions
export default printCuttingSlipFilteredDataSlice.reducer