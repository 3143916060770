import { Autocomplete, IconButton, Select, TextField } from "@mui/material";
import React, { useState } from "react";
import "../../../../../css/Purchase Manager/goodsReceivedNotes.css";
import Voucher from "../../../../Single Components/Voucher";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { editDraftedGrnAPICall, generateGrnIdAPICall, generateGrnTransactionIdAPICall, graftGrnAPICall, postGrnAPICall, viewGrnDraftedListAPICall, viewInvoiceNumberListAPICall, viewPurchaseByIdAPICall } from "../../../../../API/Purchase Manager/grnAPI";
import { viewAllBranchesAPICall } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import { viewAllSuppliersAPICall } from "../../../../../API/Purchase Manager/supplierAPI";
import SuccessSnackbar from "../../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
import { viewCardManualAPICall } from "../../../../../API/Settings/CardAndUpiType/cardManualAPI";
import { viewUpiManualAPICall } from "../../../../../API/Settings/CardAndUpiType/UpiManualAPI";
import { today,getCurrentTime } from "../../../../../Js/Date";

export const GoodsReceivedNotes = () => {
    // drafted id from local storage
    const graftedId=localStorage.getItem("draftId")
    // edited status from local storage
    const isEdit = localStorage.getItem("isEdit")

    // invoice number list 
    const invoiceNoList= useSelector((state)=>state.invoiceNumberSlice.value)
    // purchase by id
    const purchaseByIdList=useSelector((state)=>state.viewPurchaseByIdSlice.value) 
    //List of all Branches
    const allBranchesList = useSelector((state) => state.allBranchesSlice.value); 
    //List of All Suppliers
    const allSuppliersList = useSelector(
           (state) => state.allSupplierListSlice.value
     );
 

     const storeCode=localStorage.getItem('branchId')
     // upi
    const upiManual=useSelector((state)=>state.upiManualSlice.value)
    const cardManual=useSelector((state)=>state.cardManualSlice.value)

     // generate grn id
     const grnId=useSelector((state)=>state.generateGrnSlice.value)
     // generate grn id
     const voucherTransactionId=useSelector((state)=>state.generateGrnTransactionIdSlice.value)
     // grn drafted list
     const grnDraftList=useSelector((state)=>state.grnDraftedList.value)

     const userMeta=localStorage.getItem("login_meta")

     const userBranchId=JSON.parse(userMeta)?.branchId
     // states
    const [invoiceNumber,setInvoiceNumber]=useState(null)
    //Voucher open dialog
    const [isVoucherOpen,setIsVoucherOpen]=useState(false)
    const grnInitialForm={
      tansactionId:'',
      invoiceNumber:'',
      salesInvoiceNo:'',
      date:'',
      purchseId:'',
      lastPaidAmount:'',
      paymentMethod:'cash',
      account:'',
      voucherDate:'',
      cardVendor:'',
      upiId:'',
      upiVendor:'',
      balanceAmt:''
    }
    
    const [grnForm,setGrnForm]=useState(grnInitialForm)
    const [location,setLocation]=useState(null)
    const [creditor,setCreditor]=useState(null)
    const [itemsArray,setItemsArray]=useState([])
    // const [isChecked,setIsChecked]=useState(false)
    const [receivedProductsArray,setReceivedProductsArray]=useState([])
    // const [receivedQty,setReceivedQty]=useState(null)
    const [netAmount,setNetAmount]=useState(null)
    const [isModify,setIsModify]=useState(false)
    const [selectedTableForBackEnd,setSelectedTableForBackEnd]=useState([]) 
    const [fromDate,setFromDate]=useState('')
    const [toDate,setToDate]=useState('')
    const [singleDraftedData,setSingleDraftedData]=useState([])

    // validation states
    const [invoiceAlert,setInvoiceAlert]=useState(false)
    const [receivedProductAlert,setReceievedProductAlert]=useState(false)
    const [creditorAlert,setCreditorAlert]=useState(false)
    const [voucherAlert,setVoucherAlert]=useState(false)
    //Snackbar States
    const [successSnackOpen, setSuccessSnackOpen] = useState(false);
    const [errorSnackOpen, setErrorSnackOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState("Error!!");

    // current date & time
    const todayDate={today}
    const currentTime=getCurrentTime()
 //Close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setSuccessSnackOpen(false);
  };
  //Close ErrorSnackbar
  const closeErrorSnackbar = () => {
    setErrorSnackOpen(false);
  };
   //*Update Listener
 const updateListener = () => {
  setIsModify(!isModify);
};
    //Close Voucher
    const closeVoucher=()=>{
        setIsVoucherOpen(false)
    }
    // voucher onchange
    const getVoucherChange=(key)=>(e)=>{
      const{value}=e.target
      if(key==="lastPaidAmt"){
        setGrnForm({...grnForm,lastPaidAmount:value})
      }
      if(key ==="paymentMethod"){
        setGrnForm({...grnForm,paymentMethod:value})
      }
      if(key ==="date"){
        setGrnForm({...grnForm,voucherDate:value})
      }
      if(key==="cardType"){
        setGrnForm({...grnForm,cardVendor:value})
      }
      if(key==="accountNo"){
        setGrnForm({...grnForm,account:value})
      }
      if(key==="upiType"){
        setGrnForm({...grnForm,upiVendor:value})
      }
      if(key==="upiId"){
        setGrnForm({...grnForm,upiId:value})
      }
    }
    //OpenVoucher    
    const openVoucher=()=>{
      if(invoiceNumber===null || invoiceNumber===undefined){
        setInvoiceAlert(true)
        setVoucherAlert(true)
       }
      else if(receivedProductsArray?.length ===0){
        setReceievedProductAlert(true)
        setVoucherAlert(true)
       }
      else if(creditor===null || creditor===undefined){
        setCreditorAlert(true)
        setVoucherAlert(true)
      }
      else{
        setIsVoucherOpen(true)
        setVoucherAlert(false)
      }
    }
    //onchange function
    const getSalesInvoiceNo=(e)=>{
      const {value}=e.target
      setGrnForm({...grnForm,salesInvoiceNo:value})
    }

    const getFromDate=(e)=>{
      setFromDate(e.target.value)
    }
    const getToDate=(e)=>{
      setToDate(e.target.value)
    }

   // checkbox checked function
    const checkboxCheckedFn=(index)=>()=>{
      itemsArray[index].isChecked=!itemsArray[index].isChecked;
      setItemsArray([...itemsArray])
    }
  

    
useEffect(()=>{
  generateGrnIdAPICall()
},[isModify])

useEffect(()=>{
    setGrnForm({...grnForm,tansactionId:`${grnId?.prefix}${grnId?.transNo}`})
},[grnId])

    useEffect(()=>{
     
      viewAllBranchesAPICall();
      viewAllSuppliersAPICall();
      viewGrnDraftedListAPICall()
    },[])

    useEffect(()=>{
      viewInvoiceNumberListAPICall(
        {
          type:0,
          fromDate:fromDate !== '' ?`${fromDate} 00:00:00`:null,
          toDate:toDate !=='' ?`${toDate} 23:59:59`:null,
          branchId:storeCode
        })
    },[fromDate,toDate])

      // get invoice number id from invoice object
      useEffect(()=>{
        setGrnForm({
         ...grnForm,
         invoiceNumber:invoiceNumber?._id
        })
       },[invoiceNumber])

    useEffect(()=>{
      viewPurchaseByIdAPICall({_id:grnForm?.invoiceNumber})
    },[grnForm?.invoiceNumber])

   
    useEffect(()=>{
      setGrnForm({
        ...grnForm,
        date:purchaseByIdList?.purchaseDate,
        purchseId:purchaseByIdList?._id
   
       })
       setLocation(purchaseByIdList?.location)
       setCreditor(purchaseByIdList?.supplierId)
    },[purchaseByIdList])

  

    // get location
    useEffect(()=>{
      if(allBranchesList !== undefined){
        let filteredLocation=allBranchesList?.filter((obj)=>{
          if(obj._id===purchaseByIdList?.location){
            return obj
          }
        })
       if(filteredLocation !== undefined) setLocation(filteredLocation[0])
      }
    },[allBranchesList,purchaseByIdList])

   // get supplier
   useEffect(()=>{
     if(allSuppliersList !== undefined){
      let filteredSupplier=[]
      filteredSupplier=allSuppliersList?.filter((obj)=>{
        if(obj._id===purchaseByIdList?.supplierId){
          return obj
        }
      })
      if(filteredSupplier !== undefined) setCreditor(filteredSupplier[0])
     }
   },[allSuppliersList,purchaseByIdList])

//  map response to state
 useEffect(()=>{
   if(purchaseByIdList !== undefined){
    const {purchaseInfo} =purchaseByIdList
    setItemsArray(purchaseInfo?.map(obj=>{return {
      ...obj,
      isChecked:false,
      receivedQty:obj?.quantity,
      fcRate:obj?.rate,
      amount:parseFloat(obj?.quantity)*parseFloat(obj?.rate)
    }}))
   }
 },[purchaseByIdList])

 

 // add to grn button function
 const addToGrnBtnFn=()=>{
   const filteredData=itemsArray?.filter((obj)=>{
     return obj?.isChecked===true
   })
   setReceivedProductsArray(filteredData)
   setReceievedProductAlert(false)
   setVoucherAlert(false)
 }
 



// received all button function
const receivedAllBtnFn=()=>{
  if(purchaseByIdList !== undefined){
    const {purchaseInfo} =purchaseByIdList
    setItemsArray(purchaseInfo?.map(obj=>{return {
      ...obj,
      isChecked:true,
      receivedQty:obj?.quantity,
      fcRate:obj?.rate,
      amount:parseFloat(obj?.quantity)*parseFloat(obj?.rate)
    }}))
   }
}



// delete received product
const receivedProductsDelete=(i)=>()=>{
  receivedProductsArray.splice(i,1)
  setReceivedProductsArray([...receivedProductsArray])
  itemsArray[i].isChecked=!itemsArray[i].isChecked;
  setItemsArray([...itemsArray])

}

// console.log(receivedProductsArray);
// console.log(itemsArray);

// change received qty
const getReceivedQty=(row)=>(e)=>{
   const {value}=e.target
   row.receivedQty=parseFloat(value)
   row.amount=parseFloat(value)*parseFloat(row.rate)
   setReceivedProductsArray([...receivedProductsArray])
}

// change fc
const getFCP=(row)=>(e)=>{
  const {value}=e.target;
  row.fcRate=parseFloat(value)
  setReceivedProductsArray([...receivedProductsArray])
}
 
//calculate net amount
const calculateNetAmount=()=>{
  let netAmount=0;
  receivedProductsArray?.forEach((r,i)=>{
    netAmount +=parseFloat(r?.amount)
  })
  setNetAmount(netAmount?.toFixed(2))
}
useEffect(()=>{
  calculateNetAmount()
},[receivedProductsArray])


// table for back end
useEffect(()=>{
  setSelectedTableForBackEnd(
    receivedProductsArray?.map((r,i)=>{
      return {
        code:r?.productCode,
        productName:r?.name,
        unit:r?.unit,
        orderQuantity:r?.quantity,
        recievedQuantity:r?.receivedQty,
        UPFC:r?.fcRate,
        UPLC:r?.rate,
        price:r?.rate,
        _id:r?.itemInfo,
        type:r?.type,
        dimension:r?.dimension,
        uuid:r?.uuid
      }
    })
  )
},[receivedProductsArray])



// console.log(creditor!==undefined && creditor!==null &&creditor.supplierName);
// console.log(creditor);

const bodyToApi={
  purchase_id:grnForm?.purchseId,
  supplierId:creditor?._id,
  purchaseInfo:selectedTableForBackEnd,
  amount:netAmount,
  location:location?._id,
  invoiceNo:grnForm?.salesInvoiceNo,
  date:`${todayDate.today} ${currentTime}`, 
  branchId:storeCode
}
const bodyToApiWithVoucher={
  purchase_id:grnForm?.purchseId,
  supplierId:creditor?._id,
  purchaseInfo:selectedTableForBackEnd,
  amount:netAmount,
  location:location?._id,
  lastPaidAmount:Number(grnForm?.lastPaidAmount),
  invoiceNo:grnForm?.salesInvoiceNo,
  paymentMethod:{
    type:grnForm?.paymentMethod,
    date:`${grnForm?.voucherDate} ${getCurrentTime()}`,
    paidAmount:Number(grnForm?.lastPaidAmount),
    account:
      grnForm.paymentMethod==="cash"?
      null
      :grnForm.paymentMethod==="card"?
      grnForm?.account
      :grnForm?.upiId,
    vendor:
      grnForm.paymentMethod==="cash"?
      null
      :grnForm.paymentMethod==="card"?
      grnForm?.cardVendor
      :grnForm?.upiVendor
  },
  date:`${todayDate.today} ${currentTime}`,
  branchId:storeCode
}


// grn post function
const grnPostFunction=()=>{
   if(invoiceNumber===null || invoiceNumber===undefined){
    setInvoiceAlert(true)
   }
   else if(receivedProductsArray?.length ===0){
    setReceievedProductAlert(true)
   }
   else{
    postGrnAPICall(
      bodyToApi,
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      updateListener,
      cancelBtnFunction,
      localStorageClearFn
      )
    //  console.log(bodyToApi);
   }
}
// grn with voucher
const grnPostWithVoucher=()=>{
      
     postGrnAPICall(
         bodyToApiWithVoucher,
         setSuccessSnackOpen,
         setErrorSnackOpen,
         setSnackMsg,
         updateListener,
         cancelBtnFunction,
         localStorageClearFn,
         closeVoucher
         )
    
       
}

//  grn draft function
const grnDraftFunction=()=>{
  graftGrnAPICall(
    bodyToApi,
    setSuccessSnackOpen,
    setErrorSnackOpen,
    setSnackMsg,
    updateListener,
    cancelBtnFunction

  )
}



 const editedBodyToApi={
  _id:graftedId,
  invoiceNo:grnForm?.salesInvoiceNo,
  purchase_id:grnForm?.purchseId,
  supplierId:creditor?._id,
  date:grnForm?.date,
  amount:netAmount,
  location:location?._id,
  purchaseInfo:selectedTableForBackEnd
 }

// draft edited function
const grnDraftEditFunction=()=>{
  editDraftedGrnAPICall(
        editedBodyToApi,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        cancelBtnFunction,
        localStorageClearFn
    )
  // console.log(editedBodyToApi);
}

const localStorageClearFn=()=>{
  localStorage.removeItem('draftId')
  localStorage.removeItem('isEdit')
}

// first cancel button function
const clearButtonFn=()=>{
  setInvoiceNumber(null)
  setGrnForm({
    ...grnForm,
    date:'',
    salesInvoiceNo:''
  })
  setItemsArray([])
}

// second cancel button function
const cancelBtnFunction=()=>{
  clearButtonFn()
  setReceivedProductsArray([])
  setNetAmount(null)
  localStorageClearFn()
}

/// filter drafted grn
useEffect(()=>{
   if(graftedId !== null){
    let draftedGrn=[]
    draftedGrn=grnDraftList?.filter((obj)=>{
     if(obj._id===graftedId){
       return obj;
     }
    })
    setSingleDraftedData(draftedGrn)
   }
  
},[grnDraftList,graftedId])

useEffect(()=>{
  if(graftedId !== null){

   setGrnForm({...grnForm,
    tansactionId:singleDraftedData !== undefined && singleDraftedData[0]?.transNo,
    invoiceNumber:invoiceNumber?._id,
    salesInvoiceNo:singleDraftedData !== undefined && singleDraftedData[0]?.salesInvoiceNo
  })
  // setReceivedProductsArray(singleDraftedData !== undefined && singleDraftedData[0]?.purchaseInfo)
    if(singleDraftedData !== undefined){
    setReceivedProductsArray(
      singleDraftedData !== undefined && singleDraftedData[0]?.purchaseInfo?.map((r,i)=>{
        return {
          productCode:r?.code,
          name:r?.productName,
          unit:r?.unit,
          quantity:r?.orderQuantity,
          receivedQty:r?.recievedQuantity,
          fcRate:r?.UPFC,
          rate:r?.UPLC,
          itemInfo:r?._id,
          type:r?.type,
          dimension:r?.dimension,
          amount:parseFloat(r?.recievedQuantity)*parseFloat(r?.UPLC),
          uuid:r?.uuid
        }
      })
    )
    }
    setNetAmount(singleDraftedData !== undefined && singleDraftedData[0]?.amount?.toFixed(2))
 
  }
 },[singleDraftedData])



useEffect(()=>{
  if(invoiceNoList !== undefined && singleDraftedData !== undefined){
      let draftedId=[]
      draftedId=invoiceNoList?.filter((obj)=>{
         if(singleDraftedData !== undefined){
          return obj._id===singleDraftedData[0]?.purchase_id;
         }
    
      
  })
    setInvoiceNumber(draftedId[0])

  }
},[invoiceNoList,singleDraftedData])


useEffect(()=>{
  viewCardManualAPICall()
  viewUpiManualAPICall()
},[])
useEffect(()=>{
  setGrnForm({...grnForm,balanceAmt:creditor?.openingBalance})
 },[creditor])

useEffect(()=>{
  generateGrnTransactionIdAPICall({
    branchId:userBranchId
  })
},[isModify])





 



  return (
    <>
    <div className="global-page-parent-container grn-parent-container">
      <p className="breadcrumb">Purchase Manager &gt; Goods Received Note</p>
      <div className="top-bar">
        <div className="global-single-input auto-complete-input  drop-down auto-complete" style={{position:"relative"}}>
          <p>Invoice No</p>
           <Autocomplete
              sx={{width:"100%"}}
              options={invoiceNoList || [""]}
              getOptionLabel={(option)=>option?.purchaseId}
              renderInput={(params) => <TextField {...params} 
              placeholder="Select Invoice Number"
             
              />}
              value={invoiceNumber}
              onChange={(e,newValue)=>{
                setInvoiceNumber(newValue)
                setInvoiceAlert(false)
                setVoucherAlert(false)
                setGrnForm({...grnForm,salesInvoiceNo:newValue?.purchaseId})
                if(newValue===null){
                  setInvoiceAlert(true)
                  setGrnForm({...grnForm,salesInvoiceNo:""})
                }
              }}
           />
           {invoiceAlert?
            <p className="doc-validation-alert">Select a invoice No !!</p>
           :""}
        </div>
        <div className="global-single-input grn-input">
          <p>PO From</p>
          <input type="date" name="" id=""  onChange={getFromDate}/>
        </div>
        <div className="global-single-input grn-input">
          <p>PO To</p>
          <input type="date" name="" id="" onChange={getToDate}/>
        </div>
      </div>
      <div className="first-row-container">
        <div className="fr-left-container">
          <div className="fr-left-form-container">
            <div className="global-single-input grn-input">
              <p>Transaction ID</p>
              <input type="text" name="" id="" value={grnForm?.tansactionId}/>
            </div>
            <div className="global-single-input grn-input">
              <p>Sales Invoice No</p>
              <input type="text" name="" id="" value={grnForm?.salesInvoiceNo} onChange={getSalesInvoiceNo}/>
            </div>
            <div className="global-single-input grn-input">
              <p>Date</p>
              <input type="date" name="" id="" value={grnForm?.date}/>
            </div>
            <div className="global-single-input grn-input auto-complete">
              <p>Location</p>
              <Autocomplete
             sx={{width:"100%"}}
             options={allBranchesList || [""]}
             getOptionLabel={(option) => `${option?.storeCode}-${option?.branchName}`}

             renderInput={(params) => <TextField {...params} 
                       />}
             value={location}
             onChange={(e,newValue)=>setLocation(newValue)}
             
            />
            </div>
            <div className="global-single-input grn-input  auto-complete">
              <p>Creditor</p>
              <Autocomplete
             sx={{width:"100%"}}
             options={allSuppliersList|| [""]}
             getOptionLabel={(option) => option?.supplierName}

             renderInput={(params) => <TextField {...params} 
             />}
             value={creditor}
             onChange={(e,newValue)=>{
                  setCreditor(newValue)
                  setCreditorAlert(false)
                  setVoucherAlert(false)
                  if(newValue===null){
                    setCreditorAlert(true)
                  }
                }}
            
            />
            {
              creditorAlert?
              <p className="doc-validation-alert">Select a Creditor !!</p>
              :""
            }
            </div>
          </div>
          <div className="fr-left-buttons-container">
            <button onClick={clearButtonFn} className="btn btn-secondary-outlined cancel-btn">
              Cancel
            </button>
            {/* <button className="btn btn-secondary">Submit</button> */}
            <button onClick={receivedAllBtnFn} className="btn btn-secondary received-all-btn">
              Receive All
            </button>
          </div>
        </div>
        <div className="fr-right-container">
          <div className="global-table-container grn-first-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>Product Code</th>
                  <th>Product Name</th>
                  <th>Unit</th>
                  <th>Order Qty</th>
                  <th>Received Qty</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  itemsArray?.length !==0 ? itemsArray?.map((r,i)=>{
                    if(r?.status==="Pending"){
                      return (
                        <tr>
                        <td>{r?.productCode}</td>
                        <td>{r?.name}</td>
                        <td>{r?.unit}</td>
                        <td>{r?.quantity}</td>
                        <td>{r?.receivedQty}</td>
                        <td>
                          {" "}
                          <input
                            className="global-checkbox"
                            type="checkbox"
                            name=""
                            id=""
                           checked={r?.isChecked}
                           onClick={checkboxCheckedFn(i)}
    
                          />
                        </td>
                      </tr>
                      )
                    }
                   
                  })
                  :
                  <tr>
                    <td colSpan={6} style={{textAlign:"center"}}>No Data</td>
                  </tr>
                }
               
              </tbody>
            </table>
          </div>
          <div className="grn-fr-right-btn-container">
            <button onClick={addToGrnBtnFn}>Add to GRN</button>
          </div>
        </div>
      </div>
      <div className="second-row-container">
        {receivedProductAlert?
          <p style={{color:"red",fontWeight:"600"}}>Add recieved products !!</p>
        :""}
        <h3>Received Products</h3>
        <div className="grn-received-products-table global-table-container">
          <table className="global-table">
            <thead>
              <tr>
                <th>No</th>
                <th>Product Code</th>
                <th>Unit</th>
                <th>Order Quantity</th>
                <th>Received Qty</th>
                <th>UP(FC)</th>
                <th>UP(LC)</th>
                <th>Amount</th>
               
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {
                receivedProductsArray?.length !==0 ? receivedProductsArray?.map((r,i)=>(
                  <tr>
                  <td>{i+1}</td>
                  <td>{r?.productCode}</td>
                  <td>{r?.unit}</td>
                  <td>{r?.quantity}</td>
                  <td style={{width:"15%"}}>
                  <input className="grn-table-input"
                    value={isNaN(r?.receivedQty)?'':r?.receivedQty}
                    onChange={getReceivedQty(r)}
                    type="number"
                    /></td>
                   <td style={{width:"15%"}}>
                    <input className="grn-table-input"
                      value={isNaN(r?.fcRate)?'':r?.fcRate}
                      onChange={getFCP(r)}
                  /></td>
                   <td>{r?.rate}</td>
                  <td>{isNaN(r?.amount)?'':r?.amount?.toFixed(2)}</td>
                 
                  <td>
                  <IconButton onClick={receivedProductsDelete(i)}>
                      <i class="bi bi-trash3 delete-icon"></i>
                  </IconButton>
                  </td>
                </tr>
                ))
                :
                 <tr>
                   <td colSpan={9} style={{textAlign:"center"}}>No Data</td>
                 </tr>
              }
             
            </tbody>
          </table>
        </div>
        <div className="net-amount-container">
          <div className="global-single-input grn-net-amount-inputs">
            <p>Net Amount</p>
            <input type="text" name="" id="" value={netAmount}/>
          </div>
        </div>
        <div className="bottom-button-container">
          <button onClick={cancelBtnFunction} className="btn btn-secondary-outlined">Cancel</button>
          <button 
            onClick={grnPostFunction} 
          className="btn btn-secondary">Post</button>
          <button onClick={isEdit ? grnDraftEditFunction : grnDraftFunction} className="btn draft-btn">{isEdit ? 'Update':'Draft'}</button> 
          <button className="btn voucher-btn" onClick={openVoucher}>
            Create Voucher
            { voucherAlert?
            <p className="doc-validation-alert" style={{top:"80%"}}>Fill the above fields first !!</p>
            :""}
            </button>
        </div>
      </div>
    </div>

    <Voucher 
      open={isVoucherOpen} 
      onClose={closeVoucher} 
      idLable="GRN ID" 
      supplierData={grnForm} 
      onChange={getVoucherChange}
      Cardlist={cardManual}
      UpiList={upiManual}
      finalApiCall={grnPostWithVoucher}
      transactionId={voucherTransactionId?.transNo}
      >
        <input type="text" value={creditor!==undefined && creditor!==null &&creditor.supplierName} disabled/>
    </Voucher>
        {/* //TODO Supplier map code here */}
    {/* </Voucher> */}
      
     {/* snackbar  */}
    <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />

      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </>
  );
};
