import React from "react";
import InputBox from "../../../components/InputBox";
import DropdownInputBox from "../../../components/DropdownInputBox";
import TextAreaInputBox from "../../../components/TextAreaInputBox";
import VoucherTransactionPage from "../../../components/VoucherTransactionPage";
import TableBox from "../../../components/TableBox";

const ContraVoucher = ({
  selectedVoucher,
  currencies,
  renderData,
  headings,
}) => {
  return (
    <div>
      <div className="accounting-voucher-menu-sections">
        <h3>{selectedVoucher}</h3>
        <div className="accounting-input-field">
          <InputBox label={"Invoice No"} />
          <InputBox label={"Posting Date"} type={"date"} />
          <InputBox label={"Due Date"} type={"date"} />
          <DropdownInputBox
            labelName={"Currency"}
            options={currencies.map((data) => data.cc)}
          />
          <InputBox label={"Ref.Number"} />
          <TextAreaInputBox label={"Remark"} />
        </div>
      </div>
      <VoucherTransactionPage />
      <div className="accounting-voucher-menu-sections">
        <TableBox
          isLoading={false}
          renderData={renderData}
          headings={headings}
          isNo={true}
        />
        <div class="button-container">
          <button>Save To Draft</button>
          <div>

          <button>Cancel</button>
          <button>Save</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContraVoucher;
