import { createSlice } from "@reduxjs/toolkit";

export const directExpenses = createSlice({
  name: "directExpenses",
  initialState: {
    openingBalance: "",
    bankAccountDetails: {option:'Yes'},
    bankName: "",
    bankAccountNo: "",
    ifscCode: "",
    branchName: "",
    gstApplicable: {option:'yes'},
    natureOfTransaction: {option:'Purchase Taxable'},
    natureOfGoods: {option:'Capital Goods'},
    gstTaxType: {option:'integrated tax'},
    gstRate: '',
    taxType: {option:'integrated tax'},
    rate: "",
    typeOfSupply: {option:'Goods'},
  },
  reducers: {
    setOpeningBalance: (state, action) => {
      state.openingBalance = action.payload;
    },
    setBankAccountDetails: (state, action) => {
      state.bankAccountDetails = action.payload;
    },
    setBankName: (state, action) => {
      state.bankName = action.payload;
    },
    setBankAccountNo: (state, action) => {
      state.bankAccountNo = action.payload;
    },
    setIfscCode: (state, action) => {
      state.ifscCode = action.payload;
    },
    setBranchName: (state, action) => {
      state.branchName = action.payload;
    },
    setGstApplicable: (state, action) => {
      state.gstApplicable = action.payload;
    },
    setNatureOfTransaction: (state, action) => {
      state.natureOfTransaction = action.payload;
    },
    setNatureOfGoods: (state, action) => {
      state.natureOfGoods = action.payload;
    },
    setGstTaxType: (state, action) => {
      state.gstTaxType = action.payload;
    },
    setGstRate: (state, action) => {
      state.gstRate = action.payload;
    },
    setTaxType: (state, action) => {
      state.taxType = action.payload;
    },
    setRate: (state, action) => {
      state.rate = action.payload;
    },
    setTypeOfSupply: (state, action) => {
      state.typeOfSupply = action.payload;
    },
  },
});

export const {
  setBankAccountDetails,
  setBankAccountNo,
  setBankName,
  setBranchName,
  setGstApplicable,
  setGstRate,
  setGstTaxType,
  setIfscCode,
  setNatureOfGoods,
  setNatureOfTransaction,
  setOpeningBalance,
  setRate,
  setTaxType,
  setTypeOfSupply,
} = directExpenses.actions;
export default directExpenses.reducer;
