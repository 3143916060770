import {createSlice} from '@reduxjs/toolkit'

export const transferSingleViewSlice=createSlice({
    name:'transferSingleViewSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_transfer_single_view:(state,action)=>{
            state.value=action.payload.transferSingleViewData
        }
    }
})
export const {get_transfer_single_view} = transferSingleViewSlice.actions;
export default transferSingleViewSlice.reducer