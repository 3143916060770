import React from 'react'
import { useNavigate } from "react-router";
import { get_sales_w_so_row_data_list } from './SalesWSOSlice';
import { SalesWSOFilterAPICall, viewSalesWSOAPICall } from './SalesWSOAPI';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import store from '../../../../../Redux/store';
import { useEffect } from 'react';
import { Skeleton } from '@mui/material';
import { convertDateFormat, getCurrentTime } from '../../../../../Js/Date';
import paidRibbon from "../../../../../Assets/statusRibbon/paid.png"
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import CustomPagination from '../../../../Single Components/CustomPagination';
import axios from 'axios';
import { setFilterActive } from '../../../../Single Components/listSlice/filterCategorySlice';
export const SalesWSO = () => {

  const userRole = useSelector((state) => state.userRoleSlice.value)
  const userBranchId = localStorage.getItem("branchId")
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const tableListData = useSelector((state) => state.salesWSOSlice.apiResList);
  console.log(tableListData);
  const [isLoading, setIsLoading] = useState(false)
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const totalPages = tableListData?.pages;

  let navigate = useNavigate();

  const createFunctuon = () => {
    navigate("/userdashboard/accounts/customers/saleswso/create")
  }
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };
  const handleClickRow = (row) => {
    store.dispatch( setFilterActive(window.location.pathname))
    localStorage.setItem("singleViewById", row._id);
    // store.dispatch(get_sales_w_so_row_data_list({salesWSOsingleData:row}))
    //navigate("/userdashboard/accounts/customers/saleswso/create")
    navigate("/userdashboard/accounts/customers/saleswso/singleView")
  }
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };


  useEffect(() => {
    localStorage.removeItem("singleViewById");
    store.dispatch(get_sales_w_so_row_data_list({ salesWSOsingleData: undefined }))
    // if (userRole === "user") {
    //   viewSalesWSOAPICall({ branchId: userBranchId }, setIsLoading)
    // }
    // else {
    //   viewSalesWSOAPICall({}, setIsLoading)
    // }
  }, [])
  useEffect(() => {
   
    if (FormDataInfo.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData &&   SalesWSOFilterAPICall({
        paymentStatus: FormDataInfo.status.length !== 0 ? (FormDataInfo.status[0] === "PAID") : null,
        fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
      toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
        location_id: FormDataInfo.location,
        branchList: FormDataInfo.branchList,
        status: FormDataInfo.status.length !== 0 ? (FormDataInfo.status[0] === "DRAFT" ? FormDataInfo.status : ["COMPLETED"]) : "",
        search: FormDataInfo.search,
        index: currentPage-1,
      }, setIsLoading);
    }
   
  }, [FormDataInfo, currentPage]);


  return (
    <div className='global-page-parent-container'>
      {/* table */}
      <div className='global-white-bg-container'>
        <div className="justify-space-between"
          style={{ gap: "0 12px", display: 'flex', justifyContent: 'space-between', alignItems: 'end'}}
        >
          
            {FormDataInfo?.branchList?.length > 0 && (
              <div style={{ marginLeft: '1%' }}>
               <h3>Branch name: 
                  {selectedBranch?.storeCode||filtereDataOptions?.filterCategorylist?.branchList?.storeCode}
                  -{selectedBranch?.branchName||filtereDataOptions?.filterCategorylist?.branchList?.branchName}</h3>
              </div>
            )} 
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap:'9px',
              width: FormDataInfo?.branchList?.length ? '72%' : '100%'
            }}>
          <CategorySearchandFilter
            statusOptions={['DRAFT', 'POSTED', 'PAID']}
            onData={handleDataFromChild}
            isCreatedBy={true}
            isStatus={true}
            onBranchSelect={handleBranchSelect}
          />
          <button onClick={createFunctuon} className='create-button-blue'>Create</button>
          </div>
        </div>
        <div className='create-button-blue-container'>
          <h3>Sales W/SO</h3>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />
          {/* <button onClick={createFunctuon}  className='create-button-blue'>Create</button> */}
        </div>
        <div className='new-global-table-container'>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Customer</th>
                <th>Invoice Date</th>
                <th>Invoice Reference</th>
                <th>Total</th>
                <th>Created By</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody >
              {

                isLoading ? (
                  [...Array(10)].map((r, i) => (
                    <tr key={i}>
                      <td colSpan={8}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                  ))
                ) :

                  tableListData?.list !== undefined && tableListData?.list?.length !== 0 ? tableListData?.list?.slice(0)?.map((r, i) => (
                    <tr onClick={() => handleClickRow(r)}>
                      <td>{r?.billDate}</td>
                      <td>{r?.sequenceName || r?.NAME}</td>
                      <td>{r?.vendorName}</td>
                      <td>{convertDateFormat(r?.billDate)}</td>
                      <td>{r?.billRef}</td>
                      <td>{r?.total || "---"}</td>
                      <td>{r?.CREATEDBY || "---"}</td>
                      <td className='table-data-box'>
                        {r?.status === "DRAFT" ? (
                          <span className='draft'> DRAFT</span>
                        ) : r?.status === "POSTED" ? (
                          <span className='post'> POST</span>
                        ) : r?.status === "PAID" ? (
                          <span className='paid'>PAID</span>
                        ) : (
                          <span>{r?.status}</span>
                        )}
                      </td>
                    </tr>
                  ))
                    :
                    <tr>
                      <td colSpan={8}>No Data</td>
                    </tr>
              }




            </tbody>
          </table>
        </div>
      </div>

    </div>
  )
}
