import React from "react";
import InputBox from "../../../components/InputBox";
import "../../../../../../css/Account/reports/gstReports.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Skeleton,
} from "@mui/material";
import { useState } from "react";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ReactToPrint from "react-to-print";
import { useRef } from "react";

const renderData = [
  {
    id: "1",
    description: "B2B Invoices -3,4A",
    igst: "0",
    cgst: "0",
    sgst: "0",
    cess: "0",
    taxableAmount: "5000.00",
  },
  {
    id: "2",
    description: "Credit / debit notes - 6C",
    igst: "0",
    cgst: "0",
    sgst: "0",
    cess: "0",
    taxableAmount: "5000.00",
  },
  {
    id: "3",
    description: "B2B UR Invoices - 4B",
    igst: "0",
    cgst: "0",
    sgst: "0",
    cess: "0",
    taxableAmount: "5000.00",
  },
  {
    id: "4",
    description: "Import of services - 4C",
    igst: "0",
    cgst: "0",
    sgst: "0",
    cess: "0",
    taxableAmount: "5000.00",
  },
  {
    id: "5",
    description: "Import of goods - 5",
    igst: "0",
    cgst: "0",
    sgst: "0",
    cess: "0",
    taxableAmount: "5000.00",
  },
  {
    id: "6",
    description: "Credit / Debit Note unregistered - 6C",
    igst: "0",
    cgst: "0",
    sgst: "0",
    cess: "0",
    taxableAmount: "5000.00",
  },
  {
    id: "7",
    description: "Nil rated Invoices - 7 (summery)",
    igst: "0",
    cgst: "0",
    sgst: "0",
    cess: "0",
    taxableAmount: "5000.00",
  },
  {
    id: "8",
    description: "Advance paid - 10A (Summery)",
    igst: "0",
    cgst: "0",
    sgst: "0",
    cess: "0",
    taxableAmount: "5000.00",
  },
  {
    id: "9",
    description: "Adjustment of Advances - 10B (summery)",
    igst: "0",
    cgst: "0",
    sgst: "0",
    cess: "0",
    taxableAmount: "5000.00",
  },
];

const tableView = [
  {
    thead: [
      "Receiver GSTIN/UIN",
      "Receiver Name",
      "Invoice No",
      "Invoice Date",
      "Total Invoice value",
      "Place of supply",
      "Supply type",
      "invoice type",
      "Applicable % Tax rate",
      "E-commerce GSTIN",
      "Rate %",
      "Total Taxable value",
      "IGST",
      "SGST",
      "CGST",
    ],
    tbody: [
      {
        receivergstinuin: "32CCTPS4542L1ZZ",
        receivername: "DEEPAK",
        invoiceno: "S-01",
        invoicedate: "10/10/23",
        totalinvoicevalue: "0.00",
        placeofsupply: "32-kerala",
        supplytype: "Inter state",
        invoicetype: "Regular B2B",
        applicabletaxrate: "65",
        ecommercgstin: "0.00",
        rate: "25%",
        totaltaxablevalue: "5000",
        igst: "6000",
        sgst: "0.00",
        cgst: "0.00",
      },
      {
        receivergstinuin: "32CCTPS4542L1ZZ",
        receivername: "DEEPAK",
        invoiceno: "S-01",
        invoicedate: "10/10/23",
        totalinvoicevalue: "0.00",
        placeofsupply: "32-kerala",
        supplytype: "Inter state",
        invoicetype: "Regular B2B",
        applicabletaxrate: "65",
        ecommercgstin: "0.00",
        rate: "25%",
        totaltaxablevalue: "5000",
        igst: "6000",
        sgst: "0.00",
        cgst: "0.00",
      },
    ],
  },
  {
    thead: [
      "Invoice No",
      "Invoice Date",
      "Total Invoice value",
      "Place of supply",
      "Supply type",
      "Applicable % Tax rate",
      "E-commerce GSTIN",
      "Total Taxable value",
      "Rate %",
      "SGST",
      "CGST",
      "IGST",
    ],
    tbody: [
      {
        invoiceno: "S-01 ",
        invoicedate: "24-12-22 ",
        totalinvoicevalue: "560000 ",
        placeofsupply: "32-kerala ",
        supplytype: "t / debit note ",
        applicabletaxrate: "0 ",
        ecommercegstin: " ",
        totaltaxablevalue: "500000 ",
        rate: "12% ",
        sgst: "0 ",
        cgst: "0 ",
        igst: "6000 ",
      },
    ],
  },
  {
    thead: [
      "Place of supply",
      "Total taxable value",
      "Supply type",
      "Rate %",
      "IGST",
      "CGST",
      "SGST",
      "CESS",
      "Application % of tax rate",
      "E-commerce GSTIN",
    ],
    tbody: [
      {
        placeofsupply: "Other than E-commerce ",
        totaltaxablevalue: "29-karnataka",
        supplytype: "Intra-state",
        rate: "12%",
        igst: "0",
        cgst: "3000",
        sgst: "3000",
        cess: "0",
        applicationoftaxrate: "0",
        ecommercgstin: "Nil",
      },
    ],
  },
  {
    thead: [
      "Receiver GSTIN",
      "Receiver Name",
      "Note number",
      "Note type",
      "Place of supply",
      "Reverse charge",
      "Note supply Type",
      "Note value",
      "Applicable % of tax rate",
      "Supply type",
      "Total taxable value",
      "integrated tax",
      "CGST",
      "SGST",
      "CESS",
    ],
    tbody: [
      {
        receivergstin: "32CCTPS4542L1ZZ",
        receivername: "DEEPAK",
        notenumber: "SRN-01",
        notetype: "c",
        placeofsupply: "32-KERALA",
        reversecharge: "",
        notesupplytype: "regular B2B",
        notevalue: "5600",
        applicableoftaxrate: "0",
        supplytype: "Intrastate",
        totaltaxablevalue: "500",
        integratedtax: "600",
        cgst: "0",
        sgst: "0",
        cess: "0",
      },
    ],
  },
  {
    thead: [
      "UR Type",
      "Note number",
      "Note date",
      "Note type",
      "Place of supply",
      "Note value",
      "Supply type",
      "Rate",
      "Total taxable value",
      "SGST",
      "CGST",
      "IGST",
      "CESS",
    ],
    tbody: [
      {
        urtype: "B2CL",
        notenumber: "SRN-04",
        notedate: "16.12.22",
        notetype: "c",
        placeofsupply: "32-kerala",
        notevalue: "5600",
        supplytype: "interstate",
        rate: "12%",
        totaltaxablevalue: "5000",
        sgst: "0",
        cgst: "0",
        igst: "600",
        cess: "0",
      },
    ],
  },
  {
    thead: [
      "GST payment",
      "Invoice number",
      "Invoice date",
      "Total invoice value",
      "Port code",
      "Shipping bill number",
      "Shipping bill date",
      "Supply type",
      "Total taxable value",
      "Rate",
      "SGST",
      "CGST",
      "IGST",
      "CESS",
    ],
    tbody: [
      {
        gstpayment: "",
        invoicenumber: "",
        invoicedate: "",
        totalinvoicevalue: "",
        portcode: "",
        shippingbillnumber: "",
        shippingbilldate: "",
        supplytype: "",
        totaltaxablevalue: "",
        rate: "",
        sgst: "",
        cgst: "",
        igst: "",
        cess: "",
      },
    ],
  },
  {
    thead: [
      "Place of supply",
      "Supply type",
      "Applicable % of tax rate",
      "Rate %",
      "Gross advance received",
      "IGST",
      "CGST",
      "SGST",
      "CESS ",
    ],
    tbody: [
      {
        placeofsupply: null,
        supplytype: null,
        applicableoftaxrate: null,
        rate: null,
        grossadvancereceived: null,
        igst: null,
        cgst: null,
        sgst: null,
        cess: null,
      },
    ],
  },
  {
    thead: [
      "Place of supply",
      "Supply type",
      "Applicable % of tax rate",
      "Rate %",
      "Gross advance received",
      "IGST",
      "CGST",
      "SGST",
      "CESS Amount",
    ],
    tbody: [
      {
        placeofsupply: null,
        supplytype: null,
        applicableoftaxrate: null,
        rate: null,
        grossadvancereceived: null,
        igst: null,
        cgst: null,
        sgst: null,
        cess: null,
      },
    ],
  },
  {
    thead: [
      "Description",
      "Nil rates supplies",
      "Exempted (other than nil rated /Non-GST supplies)",
      "Non-GST Supplies",
    ],
    tbody: [
      {
        description: null,
        nilratessupplies: null,
        exemptedotherthannilratednongstsupplies: null,
        nongstsupplies: null,
      },
    ],
  },
  {
    thead: [
      "HSN",
      "Description",
      "Product name as in master",
      "UQC",
      "Total quantity",
      "Total taxable value",
      "Rate %",
      "SGST",
      "CGST",
      "IGST",
      "CESS Amount",
    ],
    tbody: [
      {
        hsn: null,
        description: null,
        productnameasinmaster: null,
        uqc: null,
        totalquantity: null,
        totaltaxablevalue: null,
        rate: null,
        sgst: null,
        cgst: null,
        igst: null,
        cessamount: null,
      },
    ],
  },
];

const Gstr2 = () => {
  const isLoading = false;
  const [open, setOpen] = React.useState(false);
  const [tableData, setTableData] = useState();
  const [viewIndex, setViewIndex] = useState();
  const [lengthState, setLengthState] = useState(0);
  const componentRef = useRef();

  const handleClose = () => {
    setOpen(false);
  };

  const dialogView = (index, data) => {
    setOpen(true);
    setViewIndex(index);
    setTableData(data);
    const length = tableView[index]?.thead.length;
    setLengthState(length);
  };
console.log(renderData);
  return (
    <div className="gstr-1">
      <div className="input-and-submit-wrapper">
        <InputBox label={"Tax period"} type={"date"} />
        <InputBox label={"GSTIN"} />
        <div className="submit-btn">
          <button>submit</button>
        </div>
      </div>
      <div className="input-and-submit-wrapper">
        {/* print and download action bar */}
        <div className="action-btn-wrapper">
          <div className="action-bar">
            <ReactToPrint
              trigger={() => (
                <IconButton sx={{ color: "blue" }}>
                  <i className="bi bi-printer "></i>
                </IconButton>
              )}
              content={() => componentRef.current}
            />
            <IconButton className="download-btn">
              <ArrowCircleDownIcon sx={{ fontSize: 30 }} />{" "}
            </IconButton>
          </div>
        </div>
        <div className="global-table-container ">
          <table className="global-table">
            <thead>
              <tr>
                <th>Discription</th>
                <th>IGST</th>
                <th>CGST</th>
                <th>SGST</th>
                <th>CESS</th>
                <th>Taxable Amount</th>
                <th>Total ITC available</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [...Array(3)].map((_, index) => (
                  <tr key={index}>
                    <td colSpan={lengthState}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : renderData.length !== 0 ? (
                renderData?.map((r, i) => (
                
                    <tr key={r.id} className="data-table-row-head">
                      <td className="description-data">{r?.description}</td>
                      <td>{r?.igst}</td>
                      <td>{r?.cgst}</td>
                      <td>{r?.sgst}</td>
                      <td>{r?.cess}</td>
                      <td>{r?.taxableAmount}</td>
                      <td>&nbsp;</td>
                      <td>
                        <IconButton onClick={() => dialogView(i, r)}>
                          <i className="bi bi-eye visibility-icon" />
                        </IconButton>
                      </td>
                    </tr>
                 
                ))
              ) : (
                <tr>
                  <td colSpan={lengthState}>No Records</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* total-table  */}
        <div className="global-table-container ">
          <table className="global-table total-table">
            <thead>
              <tr>
                <th>&nbsp</th>
                <th>IGST</th>
                <th>CGST</th>
                <th>SGST</th>
                <th>CESS</th>
                <th>Taxable Amount</th>
                <th>Total ITC available</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [...Array(3)].map((_, index) => (
                  <tr key={index}>
                    <td colSpan={lengthState}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : renderData.length !== 0 ? (
                <>
                  <tr className="data-table-row-head">
                    <td className="description-data">Total inward supplies</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>5000.00</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr className="data-table-row-head">
                    <td className="description-data">
                      ITC Reversal / reclaim - 11 summery
                    </td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>5000.00</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr className="data-table-row-head">
                    <td className="description-data">Total invoice number</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>5000.00</td>
                    <td>&nbsp;</td>
                    <td>
                      {/* onClick={() => toggleVisibility(i)} */}
                      <IconButton>
                        <i className="bi bi-eye visibility-icon" />
                      </IconButton>
                    </td>
                  </tr>
                  <tr className="data-table-row-head">
                    <td className="description-data">HSN / SAC Summery - 13</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>5000.00</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td colSpan={lengthState}>No Records</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* dialog - table */}
      <div className="detail-dialog-view">
        <Dialog
          open={open}
          onClose={handleClose}
          className="detail-dialog-view"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "90vw",
              },
            },
          }}
        >
          <DialogContent>
            <div className="input-and-submit-wrapper">
              <strong>{tableData?.description}</strong>
              <div
                style={{ width: "auto" }}
                className="global-table-container "
              >
                <table className="global-table">
                  <thead>
                    <tr>
                      {tableView[viewIndex]?.thead.map((head, i) => (
                        <th>{head}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      [...Array(3)].map((_, index) => (
                        <tr key={index}>
                          <td colSpan={lengthState}>
                            <Skeleton
                              variant="rectangular"
                              animation="wave"
                              width={"100%"}
                              height={40}
                            />
                          </td>
                        </tr>
                      ))
                    ) : tableView[viewIndex]?.tbody &&
                      tableView[viewIndex]?.tbody.length > 0 ? (
                      tableView[viewIndex]?.tbody.map((body, i) => (
                        <React.Fragment key={i}>
                          <tr key={i} className="data-table-row-head">
                            {Object.keys(body).map((key, j) => (
                              <td>{body[key]}</td>
                            ))}
                          </tr>
                        </React.Fragment>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={lengthState}>No Records</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </DialogContent>
          <div className="close-btn">
            <Button onClick={handleClose}>Close</Button>
          </div>
        </Dialog>
      </div>
      {/* print view */}
      <div style={{ display: "none" }}>
        <div
          ref={componentRef}
          id="currentAsset-report"
          className="print-preview-container "
          
        >
          <div className="print-head-container">
            <div className="brand-name">
              <h2>Leeyet Textile</h2>
            </div>
            <div className="select-table-head">
              <h3>GSTR - 2</h3>
            </div>
            <div className="duration-date">
              <h3>{"01/04/2023"}</h3>
              <strong>TO</strong>
              <h3>{"01/12/2023"}</h3>
            </div>
          </div>
          <div className="global-table-wrapper">
            <div className="input-and-submit-wrapper">
              <div className="global-table-container ">
                <table className="global-table">
                  <thead>
                    <tr>
                      <th>Discription</th>
                      <th>IGST</th>
                      <th>CGST</th>
                      <th>SGST</th>
                      <th>CESS</th>
                      <th>Taxable Amount</th>
                      <th>Total ITC available</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderData?.map((r, i) =>  (
                      <React.Fragment key={i}>
                        <tr className="data-table-row-head">
                          <td className="description-data">{r?.description}</td>
                          <td>{r?.igst}</td>
                          <td>{r?.cgst}</td>
                          <td>{r?.sgst}</td>
                          <td>{r?.cess}</td>
                          <td>{r?.taxableAmount}</td>
                          <td>&nbsp;</td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* total-table  */}
              <div className="global-table-container ">
                <table className="global-table total-table">
                  <thead>
                    <tr>
                      <th>&nbsp</th>
                      <th>IGST</th>
                      <th>CGST</th>
                      <th>SGST</th>
                      <th>CESS</th>
                      <th>Taxable Amount</th>
                      <th>Total ITC available</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="data-table-row-head">
                      <td className="description-data">
                        Total inward supplies
                      </td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>5000.00</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr className="data-table-row-head">
                      <td className="description-data">
                        ITC Reversal / reclaim - 11 summery
                      </td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>5000.00</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr className="data-table-row-head">
                      <td className="description-data">Total invoice number</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>5000.00</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr className="data-table-row-head">
                      <td className="description-data">
                        HSN / SAC Summery - 13
                      </td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>5000.00</td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gstr2;
