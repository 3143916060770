import axios from "axios";
import { HEADERS, HEADER_FORM_DATA } from "../../../../../API/UrlAndPaths";
import { generate_journal_entry_name, get_journal_entry_list, update_journal_entry_list_by_row } from "./journalEntrySlice";
import store from "../../../../../Redux/store";

// list journal entry api call
export const viewJournalEntryAPICall=async(body,setIsLoading)=>{
  setIsLoading!==undefined &&setIsLoading(true)
    await axios
    .post(`account/listjournalentries`, body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(get_journal_entry_list({journalEntryData:res.data}))
        setIsLoading!==undefined &&setIsLoading(false)

      }
    })
    .catch((err) => {
        setIsLoading!==undefined &&setIsLoading(false)
        store.dispatch(get_journal_entry_list({journalEntryData:undefined}))
    });
}
export const JournalEntriesFilterAPICall =(body,setIsLoading)=>{

  setIsLoading!==undefined&&setIsLoading(true)
    axios.post("account/searchJournalEntries",body,{
      headers: {
        'Content-Type': 'application/json',
      },
     
    })
    .then((res)=>{
      if(res.status===200){
        store.dispatch(get_journal_entry_list({journalEntryData:res.data}))
        setIsLoading!==undefined&&setIsLoading(false)
      }
    })
    .catch((err)=>{
      store.dispatch(get_journal_entry_list({journalEntryData:undefined}))
      setIsLoading!==undefined&&setIsLoading(false)
    })
  
  }
//Add journal entry
export const draftJournalEntryAPICall = async (
  body,postFn,setState,state,setEditDataId,setLoadingForm
) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  await axios
    .post(`account/draftjournalentries`, body, HEADER_FORM_DATA)
    .then((res) => {
      if (res.status === 200) {
        setLoadingForm!==undefined && setLoadingForm(false)
        setState({...state,message:"Journal EntryDrafted",success:true})
        postFn()
        setEditDataId(res.data?._id)
        JournalEntriesSingleViewApi({_id:res.data._id}) 
    }
    })
    .catch((err) => {
      setLoadingForm!==undefined && setLoadingForm(false)
      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
      } else {
        setState({ ...state, message: err?.response?.data, error: true })
      }
    });
};

// generate vendor bill name
export const generateJournalEntryNameAPICall=async(body)=>{
  await axios
  .post(`account/generateNameforjournalentries`,body, HEADERS)
  .then((res) => {
    if (res.status === 200) {
      store.dispatch(generate_journal_entry_name({journalEntryName:res.data}))
    }
  })
  .catch((err) => {
      store.dispatch(generate_journal_entry_name({journalEntryName:undefined}))
  });
}

export const postJournalEntryAPICall = async (body,postFn,setState,state,setLoadingForm
) => {
  setLoadingForm!==undefined && setLoadingForm(true)

  await axios
    .post(`account/addJournalEntries`, body, HEADER_FORM_DATA)
    .then((res) => {
      if (res.status === 200) {
        setLoadingForm!==undefined && setLoadingForm(false)

        setState({...state,message:"Journal Entry Posted",success:true})
        postFn()
    }
    })
    .catch((err) => {
      setLoadingForm!==undefined && setLoadingForm(false)

      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
      } else {
        setState({ ...state, message: err?.response?.data, error: true })
      }
    });
};
export const editJournalEntryAPICall = async (
  body,
  postFn,setState,state,setLoadingForm
) => {
  setLoadingForm!==undefined && setLoadingForm(true)

  await axios
    .post(`account/editjournalentries`, body, HEADER_FORM_DATA)
    .then((res) => {
      if (res.status === 200) {
        setLoadingForm!==undefined && setLoadingForm(false)

        setState({ ...state, message: "Journal Entry Edited", success: true })
        postFn()
      }
    })
    .catch((err) => {
      setLoadingForm!==undefined && setLoadingForm(false)

      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
      } else {
        setState({ ...state, message: err?.response?.data, error: true })
      }
    });
};


export const deleteSingleJournalEntryAPICall=async(body)=>{
  await axios.post("account/deleteJournalEntry",body,HEADERS)
    .then((res)=>{
      if(res.status===200){
        viewJournalEntryAPICall()
      }
    })
    .catch((err)=>{
      console.error(err.response);
    })
}
export const JournalEntriesSingleViewApi=async(body)=>{
  await axios.post("account/viewJournalEntryById",body,HEADERS)
    .then((res)=>{
      if(res.status===200){
        store.dispatch(update_journal_entry_list_by_row({journalEntryRowData:res.data}))
      }
    })
    .catch((err)=>{
      console.error(err.response);
    })
}
