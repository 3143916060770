import React, { useEffect, useState } from 'react'
import { IconButton, Skeleton } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { filterObjFromList } from '../../../../Js/generalFunctions'; 
import CategorySearchandFilter from '../../../Single Components/CategorySearchandFilter';
import { DailySalesReportAPIcall } from './SalesReportApi';
import axios from 'axios';
import { companyProfileInfoForUser } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import { viewAllCurrencyUpdated } from '../../../../API/Settings/Currency/CurrencyAPI';
import CustomPagination from '../../../Single Components/CustomPagination';
import { convertDateFormat, previousDate, today } from '../../../../Js/Date';
import { setFilterActive } from '../../../Single Components/listSlice/filterCategorySlice';
import store from '../../../../Redux/store';

function DailySalesReport() {
    let navigate = useNavigate();
    let location = useLocation()
    const filtereDataOptions = useSelector((state) => state.listCategory);
    const currencyListUpdated = useSelector(
      (state) => state.viewUpdatedCurrency.value
    );
    const companyProfileData = useSelector(
      (state) => state?.companyProfileSlice?.userValue)
  
    const SingleListData = useSelector((state) => state?.salesReport?.dailySalesReport)
    const totalPaidAmtKeys = SingleListData !== undefined ? Object.keys(SingleListData?.total?.totalPaidAmount) : [];
    
    const [FormDataInfo, setFormDataInfo] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const handleBranchSelect = (selectedBranch) => {
      setSelectedBranch(selectedBranch);
     
    };
    const [symbol, setSymbol] = useState(null);
    const handleDataFromChild = (data) => {
        setFormDataInfo(data);
    };
  
    const totalPages = 10;
    const decimalPosition = localStorage.getItem("decimalPosition");
  
  
    const handlePageChange = (page) => {
  
      setCurrentPage(page);
    };

    const handleClick = (row) => {
      store.dispatch( setFilterActive(window.location.pathname))
      localStorage.setItem("SingleViewId", row?._id);
      navigate("/userdashboard/sales/Report/dailySalesReport/SingleView", { state: { date: row?._id } })
  };

    useEffect(() => {

        if (FormDataInfo?.length !== 0) {
            let body = {
                fromDate: FormDataInfo.fromDate === '' ? `${previousDate} 00:00:00` : `${FormDataInfo.fromDate} 00:00:00`,
                toDate:  FormDataInfo?.toDate === '' ? `${today} 23:59:59` : `${FormDataInfo?.toDate} 23:59:59`,
                branchId: FormDataInfo?.branchList,
            }
            let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData && DailySalesReportAPIcall(body, setIsLoading);
        }

    }, [FormDataInfo])
  
    useEffect(() => {
      let currencyObj = filterObjFromList("_id", currencyListUpdated, "currency", companyProfileData)
      setSymbol(currencyObj?.symbol)
    }, [companyProfileData, currencyListUpdated])

    useEffect(() => {
      viewAllCurrencyUpdated({ status: 0 });
      companyProfileInfoForUser();
  }, [])

  return (
    <div className="global-page-parent-container">
    {/* table */}
    <div className="global-white-bg-container">
      <div className='create-button-blue-container' style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
      {FormDataInfo?.branchList?.length > 0 && (
              <div >
                <h3>Branch name: 
                  {selectedBranch?.storeCode||filtereDataOptions?.filterCategorylist?.branchList?.storeCode}
                  -{selectedBranch?.branchName||filtereDataOptions?.filterCategorylist?.branchList?.branchName}</h3>
              </div>
            )}
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          width: '100%'
        }}>
          <CategorySearchandFilter
            onData={handleDataFromChild}
            style={{ width: "100%" }}
            onBranchSelect={handleBranchSelect}       
            isSearch={false}

          />
          <IconButton
            style={{ width: "100px" }}

            className="print-icon-container">
            <i class="bi bi-printer printer-icon"></i>
          </IconButton>
          <IconButton
            style={{ width: "100px" }}
            className="download-icon-container">
            <i class="bi bi-arrow-down-circle download-icon"></i>
          </IconButton>
        </div>

      </div>
     

      <div className="create-button-blue-container">
        <h3>Daily Sales Report</h3>
        {/* <CustomPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        /> */}
      </div>
      <div className="customer-receipt-table-container">
        <table>
          <thead>
            <tr>

              <th>DATE</th>
              <th>INV COUNT</th>
              <th>INV AMOUNT</th>
              <th>RETURN AMOUNT</th>
              <th>TOTAL PAID</th>
              {
                                        totalPaidAmtKeys?.map(r =>
                                            <th style={{textAlignLast:"center"}}>{r}</th>
                                        )
                                    }
              {/* <th>CASH</th>
              <th>BANK</th> */}
              <th>CREDIT</th>
             
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              [...Array(10)].map((r, i) => (
                <tr key={i}>
                  <td colSpan={10}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
              ))
            ) : SingleListData?.report !== undefined && SingleListData?.report?.length !== 0 ? (
              SingleListData?.report
                ?.slice(0)
                ?.map((r, i) => (
                  <tr key={i} onClick={()=>handleClick(r)} >                  
                    
                    <td>{convertDateFormat(r?._id)}</td>
                    <td>{r?.invoiceCount}</td>
                    <td>{r?.invAmount?.toFixed(decimalPosition)||"0.00"}</td>
                    <td>{r?.returnAmount?.toFixed(decimalPosition)||"0.00"}</td>
                    <td>{r?.total?.toFixed(decimalPosition)||"0.00"}</td>
                    {totalPaidAmtKeys?.map((key) => (

<td  key={key}>{symbol}&nbsp;{r?.totalPaidAmount[key]?.toFixed(decimalPosition) || '0.00'}</td>
))}
                    {/* <td>{r?.totalPaidAmount?.Cash?.toFixed(decimalPosition)}</td>
                    <td>{r?.totalPaidAmount?.Bank?.toFixed(decimalPosition)}</td> */}
                    <td>{r?.amountDue?.toFixed(decimalPosition)||"0.00"}</td>
                    
                  </tr>
                ))
            ) : (
              <tr>
                <td style={{ textAlign: 'center' }} colSpan={10}>No Data</td>
              </tr>
            )}

          </tbody>
          <tfoot >

            <tr>
              <th style={{ textAlign: 'start', fontSize: '1rem', paddingLeft: '5%' }} colSpan={2} >TOTAL(AED)</th>
            
              <th >{SingleListData?.total !== undefined && SingleListData?.total?.invAmount?.toFixed(decimalPosition)|| '0.00'}</th>
              <th >{SingleListData?.total !== undefined && SingleListData?.total?.returnAmountSum?.toFixed(decimalPosition)|| '0.00'}</th>
              <th >{SingleListData?.total !== undefined && SingleListData?.total?.total?.toFixed(decimalPosition)|| '0.00'}</th>
              {totalPaidAmtKeys.map((key) => (

<th  key={key}>{SingleListData?.total?.totalPaidAmount[key]?.toFixed(decimalPosition) || '0.00'}</th>
))}
              {/* <th >{SingleListData?.total !== undefined && SingleListData?.total?.totalPaidAmount?.Cash?.toFixed(decimalPosition)}</th>
              <th >{SingleListData?.total !== undefined && SingleListData?.total?.totalPaidAmount?.Bank?.toFixed(decimalPosition)}</th> */}
              <th >{SingleListData?.total !== undefined && SingleListData?.total?.amountDue?.toFixed(decimalPosition)|| '0.00'}</th>
             
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
  )
}

export default DailySalesReport
