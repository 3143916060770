import { createSlice } from "@reduxjs/toolkit";

export const elasticWidthSlice=createSlice({
    name:"elasticWidthSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        update_elastic_width:(state,action)=>{
            state.value=action.payload.elasticWidth
        }
    }
})

export const {update_elastic_width}=elasticWidthSlice.actions
export default elasticWidthSlice.reducer