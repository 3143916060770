import {createSlice} from '@reduxjs/toolkit'

export const expiredDocumentSlice=createSlice({
    name:"expiredDocumentSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        get_expired_doc:(state,action)=>{
            state.value=action.payload.expiredDocData
        }
    }
})
export const {get_expired_doc}=expiredDocumentSlice.actions;
export default expiredDocumentSlice.reducer