import React from "react";

const SubmitBtn = ({ labelName, containerStyle, buttonStyle, onClick, disabled }) => {
  const changeButtonStyle = () => {
    if (disabled) {
      return {
        backgroundColor: "#e5e5e5",
        color: "#a5a5a5",
        cursor: "not-allowed",
      };
    } else {
      return {
        backgroundColor: "#005dff",
        color: "#ffff",
        height: "3em",
        width: "10em",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        fontWeight: 500,
        fontSize: "15px"
      };
  };
}

  return (
    <div
      className="ledger-input-container-btn-container"
      style={containerStyle ? containerStyle : { justifyContent: "center" }}
    >
      <button
        className="ledger-input-container-btn"
        onClick={onClick}
        style={{ ...buttonStyle, ...changeButtonStyle() }}
        disabled={disabled}
      >
       {labelName?labelName: 'Submit'}
      </button>
    </div>
  );
};

export default SubmitBtn;
