import React, { useEffect, useState } from "react";
import PayrollCard from "../components/PayrollCard";
import "./payHead.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from "@mui/material";

import MuiBackBtn from "../components/MuiBackBtn";
import MuiSaveBtn from "../components/MuiSaveBtn";
import MuiEditButton from "../components/MuiEditButton";
import MuiToggleBtn from "../components/MuiToggleBtn";
import MuiAutocomplete from "../components/MuiAutocomplete";
import { ViewPayHeadAPI, AddPayHeadAPI, UpdatePayHeadByIdAPI, ViewPayHeadByIdAPI } from "./payHeadAPI";
import { useSelector } from "react-redux";
import { chartOfAccountListApiCall } from "../../../../../API/AccountUpdated/chartOfAccountAPI";

const PayHead = () => {

  const chartOfAccountList = useSelector(
    (state) => state.chartOfAccountsSlice.value
  );
  const viewPayHeadRedux = useSelector((state) => state?.payHead?.payHeadListView)
  const viewPayHeadById = useSelector((state) => state?.payHead?.payHeadViewById)

  const expenseAccList = chartOfAccountList?.filter(
    (item) => item.type === "Expenses"
  );


  const viewEstablishmentIdById = useSelector((state) => state?.payHead?.payHeadViewById)
  const [viewPayHead, setviewPayHead] = useState()
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState({
    _id: '',
    isPayhead: '',
    code: '',
    category: '',
    name: '',
    calculationType: '',
    amountOrPerc: '',
    expAccnt: null,
    calculationType: '',
    includedInBasic: ''
  });

  const [calculationTypes, setCalculationTypes] = useState([]);
  const [calculationTypeLabel, setCalculationTypeLabel] = useState('Percentage Of CTC');
  const categories = [
    { id: "1", label: "basic" },
    { id: "2", label: "allowance" },
    { id: "3", label: "deduction" },
    { id: "4", label: "commission" },

  ];
  const IncludedInBasicOptn = [
    { value: true, label: 'True' },
    { value: false, label: 'False' }
  ];
  const handleEditClick = () => {
    setEditMode(true);
  };
  const handleSave = () => {

    console.log(formData?.expAccnt, '....formData?.expAccnt?._id')

    const includedInBasicValue = formData?.includedInBasic === "True" ? true : false;

    let data = {
      id: formData?._id,
      isPayhead: formData.isPayhead === "true" ? true : false,
      category: formData?.category,
      includedInBasic: formData?.category === "allowance" ? includedInBasicValue : false,
      name: formData?.name,
      code: formData?.code,
      calculationType: formData?.calculationType,
      amountOrPerc: formData?.amountOrPerc,
      expAccnt: formData?.expAccnt?._id,
      calculationType: formData?.calculationType,
    }
    UpdatePayHeadByIdAPI(data, setOpenDialog)
    ViewPayHeadAPI({ isPayhead: true });
    setFormData({
      _id: '',
      isPayhead: '',
      code: '',
      category: '',
      name: '',
      calculationType: '',
      amountOrPerc: '',
      expAccnt: null,
      calculationType: '',
      includedInBasic: ''
    });
    setOpenDialog(false);
  };

  useEffect(() => {

    if (formData?.category === "basic") {
      setCalculationTypes(["Fixed Amount", "Percentage Of CTC"]);
    } else {
      setCalculationTypes(["Fixed Amount", "Percentage Of Basic"]);
    }
  }, [formData?.category]);

  useEffect(() => {

    if (formData?.calculationType === "Fixed Amount") {
      setCalculationTypeLabel("Fixed Amount");
    } else if (formData?.calculationType === "Percentage Of Basic") {
      setCalculationTypeLabel("Percentage Of Basic");
    } else {
      setCalculationTypeLabel("Percentage Of CTC");
    }
  }, [formData?.calculationType]);

  const handleClickCreatePayHead = () => {
    setFormData({
      _id: '',
      isPayhead: '',
      code: '',
      category: '',
      name: '',
      calculationType: '',
      amountOrPerc: '',
      expAccnt: null,
      calculationType: '',
      includedInBasic: ''
    });
    setOpen(!open);
  };
  const handleBackBtnClicked = () => {
    setOpen(false);
  };
  const handleBackClicked = () => {
    setOpenDialog(false);
  };
  const handleSubmit = () => {
    const includedInBasicValue = formData?.includedInBasic === "True" ? true : false;
    let data = {

      isPayhead: formData.isPayhead,
      category: formData?.category,
      includedInBasic: formData?.category === "allowance" ? includedInBasicValue : false,
      name: formData?.name,
      code: formData?.code,
      calculationType: formData?.calculationType,
      amountOrPerc: formData?.amountOrPerc,
      expAccnt: formData?.expAccnt?._id,
    }

    if (typeof data.isPayhead === 'string') {
      data.isPayhead = data.isPayhead === 'true';
    }

    AddPayHeadAPI(data, setOpen)
    setFormData({
      _id: '',
      isPayhead: '',
      code: '',
      category: '',
      name: '',
      calculationType: '',
      amountOrPerc: '',
      expAccnt: null,
      calculationType: '',
      includedInBasic: ''
    });
    setOpen(false)
    // ViewPayHeadAPI({ isPayhead: data.isPayhead });
    ViewPayHeadAPI({ isPayhead: true });
    setviewPayHead(viewPayHeadRedux)

  };
  const handleCardClick = (id) => {
    let payload = {
      id: id
    }
    ViewPayHeadByIdAPI(payload)
    setOpenDialog(true);
    setEditMode(false);
  };
  useEffect(() => {
    if (viewPayHeadById) {
      setFormData({
        isPayhead: viewPayHeadById.isPayhead === true ? "true" : 'false',
        _id: viewPayHeadById?._id,
        category: viewPayHeadById?.category,
        name: viewPayHeadById?.name,
        code: viewPayHeadById?.code,
        calculationType: viewPayHeadById?.calculationType,
        amountOrPerc: viewPayHeadById?.amountOrPerc,
        expAccnt: { accountName: viewPayHeadById?.expAccntName },
        calculationType: viewPayHeadById?.calculationType,
        includedInBasic: viewPayHeadById?.includedInBasic === true ? "True" : 'False',
      });
    }
    if (viewPayHeadRedux) {
      setviewPayHead(viewPayHeadRedux)
    }

  }, [
    viewPayHeadById, viewPayHeadRedux])
  // console.log(formData)

  useEffect(() => {
    chartOfAccountListApiCall();
    ViewPayHeadAPI({ isPayhead: true });
  }, [])

  return (
    <div className="pay-head-container">
      <div className="pay-head-wrapper">
        <div className="pay-head-nav">
          <h3 className="head-name">Pay Head</h3>
          <div className="pay-head-btn-cntr">
            <button
              onClick={() => handleClickCreatePayHead()}
              className="pay-head-btn"
            >
              Create Pay Head
            </button>
          </div>
        </div>
        <div className="card-container">
          {/* viewPayHead */}
          {viewPayHead !== undefined &&
            viewPayHead?.length !== 0 &&
            viewPayHead?.map((item, index) => (
              <div
                onClick={() => handleCardClick(item._id)}
                className="payroll-card-wrapper">

                <div className="payroll-container">
                  <div>
                    <div>
                      <p className="sub-name">CATEGORY</p>
                      <h3 className="name">{item.category}</h3>
                    </div>
                    <div>
                      <p className="sub-name">NAME</p>
                      <h3 className="name">{item.name}</h3>
                    </div>
                    <div>
                      <div className="status-bar">
                        <span>Active</span>
                        {/* <h4 className="name">{item.isPayhead.toString()}</h4> */}
                      </div>
                      {/* <div className="status-bar" style={{ backgroundColor: item.isPayhead ? 'lightgreen' : 'lightcoral' }}>
                        <span style={{ color: item.isPayhead ? 'green' : 'red' }}>
                          {item.isPayhead ? 'Active' : 'Inactive'}
                        </span>
                      </div> */}

                    </div>
                  </div>
                  <div>
                    <div>
                      <p className="sub-name">CALCULATION TYPE</p>
                      <h3 className="name">{item.calculationType}</h3>
                    </div>
                    <div>
                      <p className="sub-name">PERCENTAGE OF CTC</p>
                      <h3 className="name">{item.amountOrPerc}</h3>
                    </div>
                  </div>
                  <div>
                    <div>
                      <p className="sub-name">EXPENSE ACCOUNT</p>
                      <h3 className="name">{item.expAccnt}</h3>
                    </div>
                  </div>
                </div>


              </div>
            ))}
          {/* <PayrollCard /> */}

        </div>
      </div>

      {/* add dialogue box */}

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        sx={{ padding: "5em", }}
      >
        <div style={{ minHeight: "50vh" }}>
          <DialogContent dividers>
            <Grid
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid>
                <MuiBackBtn onClick={handleBackBtnClicked} />

                <MuiSaveBtn onClick={handleSubmit} />
              </Grid>
              <Grid
                gap={3}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography fontWeight={500} id="alert-dialog-title">
                  Active
                </Typography>
                <MuiToggleBtn
                  checked={formData.isPayhead === "true"}
                  onChange={(e) => setFormData({ ...formData, isPayhead: e.target.checked ? "true" : "false" })}
                  inputProps={{ 'aria-label': 'toggle pay head' }} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogContent className="pay-head-dialog">

            <div className="new-global-single-input">

              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={categories || []}
                getOptionLabel={(option) => option?.label}
                sx={{ width: 300 }}
                renderOption={(props, option) => (
                  <li {...props} key={option?._id}>
                    <span>{option?.label}</span>
                  </li>
                )}
                renderInput={(params) => <TextField {...params} label="CATEGORY*" focused />}
                value={categories.find((option) => option.label === formData.category) || null}
                onChange={(e, newValue) => setFormData({ ...formData, category: newValue?.label || "" })}
              />


              {/* <p className="doc-validation-alert">{validationAlert?.dLocation}</p> */}
            </div>
            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="CODE*"
                variant="outlined"
                type="text"
                focused
                value={formData?.code}
                onChange={(e) => setFormData({ ...formData, code: e.target.value })}

              // disabled={internalTransferForm?.isEdit}
              />
            </div>
            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="NAME*"
                variant="outlined"
                type="text"
                focused
                value={formData?.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}

              // disabled={internalTransferForm?.isEdit}
              />
            </div>
            {formData.category === 'allowance' && (
              <div className="new-global-single-input">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={IncludedInBasicOptn || []}
                  getOptionLabel={(option) => option?.label}
                  sx={{ width: 300 }}
                  renderOption={(props, option) => (
                    <li {...props} key={option?._id}>
                      <span>{option?.label}</span>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="INCLUDE IN BASIC*" focused />
                  )}

                  // value={formData?.includedInBasic||null}
                  value={IncludedInBasicOptn.find((option) => option.label === formData.includedInBasic) || null}
                  onChange={(e, newValue) => setFormData({ ...formData, includedInBasic: newValue?.label || "" })}

                // disabled={internalTransferForm?.isEdit}
                />

                {/* <p className="doc-validation-alert">{validationAlert?.dLocation}</p> */}
              </div>
            )}
            <div className="new-global-single-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={calculationTypes || []}
                getOptionLabel={(option) => option}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="CALCULATION TYPE*" focused />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option?._id}>
                    <span>{option}</span>
                  </li>
                )}
                value={formData?.calculationType}
                onChange={(e, newValue) => setFormData({ ...formData, calculationType: newValue })}

              // disabled={internalTransferForm?.isEdit}
              />

              {/* <p className="doc-validation-alert">{validationAlert?.dLocation}</p> */}
            </div>
            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label={calculationTypeLabel}
                variant="outlined"
                type="number"
                focused
                value={formData?.amountOrPerc}
                onChange={(e) => setFormData({ ...formData, amountOrPerc: e.target.value })}

              // disabled={internalTransferForm?.isEdit}
              />
            </div>


            <div className="new-global-single-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={expenseAccList || []}
                getOptionLabel={(option) => option?.accountName || ""}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="EXPENSE ACCOUNT*" focused />
                )}
                value={formData.expAccnt || ""}
                onChange={(e, newValue) => {
                  setFormData({ ...formData, expAccnt: newValue })
                }}
              />


              {/* <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={expenseAccList || []}
                getOptionLabel={(option) => option.accountName}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="EXPENSE ACCOUNT"
                    focused
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    <span>{option.accountName}</span>
                  </li>
                )}
                value={formData?.expAccnt || ''}
                onChange={(e, newValue) => {
                  setFormData({ ...formData, expAccnt: newValue })
                }}
                // onChange={(e, newValue) => setFormData({ ...formData, employee: newValue })}
                // disabled={!editMode}
              /> */}
              {/* <p className="doc-validation-alert">{validationAlert?.dLocation}</p> */}
            </div>

          </DialogContent>
        </div>
        {/* <DialogActions>
          <Button onClick={"handleClose"}>Disagree</Button>
          <Button onClick={"handleClose"} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>

      {/* card dialogue box */}

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        sx={{ padding: "5em", }}
      >
        <div style={{ minHeight: "50vh" }}>
          <DialogContent dividers>
            <Grid
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid>
                <MuiBackBtn
                  onClick={handleBackClicked}
                />
                {/* <MuiEditButton
                 onClick={handleEditClick}
                /> */}
                <Tooltip title="Edit">
                  <IconButton onClick={handleEditClick}>
                    <i className="bi bi-pencil-square edit-icon1"></i>
                  </IconButton>
                </Tooltip>
                <MuiSaveBtn
                  onClick={handleSave} />
              </Grid>
              <Grid
                gap={3}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography fontWeight={500} id="alert-dialog-title">
                  Active
                </Typography>
                <MuiToggleBtn
                  checked={formData.isPayhead === "true"}
                  onChange={(e) => setFormData({ ...formData, isPayhead: e.target.checked ? "true" : "false" })}
                  inputProps={{ 'aria-label': 'toggle pay head' }} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogContent className="pay-head-dialog">

            <div className="new-global-single-input">

              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={categories || []}
                getOptionLabel={(option) => option?.label}
                sx={{ width: 300 }}
                renderOption={(props, option) => (
                  <li {...props} key={option?._id}>
                    <span>{option?.label}</span>
                  </li>
                )}
                renderInput={(params) => <TextField {...params} label="CATEGORY*" focused />}
                value={categories.find((option) => option.label === formData.category) || null}
                onChange={(e, newValue) => setFormData({ ...formData, category: newValue?.label || "" })}
                disabled={!editMode}
              />


              {/* <p className="doc-validation-alert">{validationAlert?.dLocation}</p> */}
            </div>
            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="CODE*"
                variant="outlined"
                type="text"
                focused
                value={formData?.code}
                onChange={(e) => setFormData({ ...formData, code: e.target.value })}
                disabled={!editMode}
              // disabled={internalTransferForm?.isEdit}
              />
            </div>
            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="NAME*"
                variant="outlined"
                type="text"
                focused
                value={formData?.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                disabled={!editMode}
              // disabled={internalTransferForm?.isEdit}
              />
            </div>
            {formData.category === 'allowance' && (
              <div className="new-global-single-input">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={IncludedInBasicOptn || []}
                  getOptionLabel={(option) => option?.label}
                  sx={{ width: 300 }}
                  renderOption={(props, option) => (
                    <li {...props} key={option?._id}>
                      <span>{option?.label}</span>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="INCLUDE IN BASIC*" focused />
                  )}

                  value={IncludedInBasicOptn.find((option) => option.label === formData.includedInBasic) || null}
                  // value={formData?.includedInBasic}
                  onChange={(e, newValue) => setFormData({ ...formData, includedInBasic: newValue?.label })}
                  disabled={!editMode}
                // disabled={internalTransferForm?.isEdit}
                />

                {/* <p className="doc-validation-alert">{validationAlert?.dLocation}</p> */}
              </div>
            )}
            <div className="new-global-single-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={calculationTypes || []}
                getOptionLabel={(option) => option}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="CALCULATION TYPE*" focused />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option?._id}>
                    <span>{option}</span>
                  </li>
                )}
                value={formData?.calculationType}
                onChange={(e, newValue) => setFormData({ ...formData, calculationType: newValue })}
                disabled={!editMode}
              // disabled={internalTransferForm?.isEdit}
              />

              {/* <p className="doc-validation-alert">{validationAlert?.dLocation}</p> */}
            </div>
            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label={calculationTypeLabel}
                variant="outlined"
                type="number"
                focused
                value={formData?.amountOrPerc}
                onChange={(e) => setFormData({ ...formData, amountOrPerc: e.target.value })}
                disabled={!editMode}
              // disabled={internalTransferForm?.isEdit}
              />
            </div>

            <div className="new-global-single-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={expenseAccList || []}
                getOptionLabel={(option) => option?.accountName || ""}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="EXPENSE ACCOUNT*" focused />
                )}
                value={formData.expAccnt || ""}
                onChange={(e, newValue) => {
                  setFormData({ ...formData, expAccnt: newValue })
                }}
                disabled={!editMode}
              />

            </div>


          </DialogContent>
        </div>

      </Dialog>
    </div>
  );
};

export default PayHead;
