import {createSlice} from '@reduxjs/toolkit'

export const oldStockSingleViewSlice = createSlice({
    name:'oldStockSingleView',
    initialState:{
        value:undefined
    },
    reducers:{
       get_old_stock_single_view:(state,action)=>{
        state.value=action.payload.oldStockSingleViewData
       }
    }
})
export const {get_old_stock_single_view} = oldStockSingleViewSlice.actions;
export default oldStockSingleViewSlice.reducer