import { createSlice } from "@reduxjs/toolkit";

export const fixedAssetSlice = createSlice({
  name: "fixedAssetSlice",
  initialState: {
    gst: { option: "GST Applicable" },
    natureOfTransaction: { option: "Not Applicable" },
    natureOfGoods: { option: "Not Applicable" },
    gstTaxType: { option: "Integrated" },
    taxType: { option: "Integrated" },
    typeOfSupply: { option: "Goods" },
    rate: null,
    gstRate: null,
  },
  reducers: {
    setOpeningBalance: (state, action) => {
      state.openingBalance = action.payload;
    },
    setGst: (state, action) => {
      state.gst = action.payload;
    },
    setNatureOfTransaction: (state, action) => {
      state.natureOfTransaction = action.payload;
    },
    setNatureOfGoods: (state, action) => {
      state.natureOfGoods = action.payload;
    },
    setTaxType: (state, action) => {
      state.taxType = action.payload;
    },
    setGstTaxType: (state, action) => {
      state.gstTaxType = action.payload;
    },
    setTypeOfSupply: (state, action) => {
      state.typeOfSupply = action.payload;
    },
    setGstRate: (state, action) => {
      state.gstRate = action.payload;
    },
    setRate: (state, action) => {
      state.rate = action.payload;
    },
  },
});

export const {
  setGst,
  setNatureOfTransaction,
  setNatureOfGoods,
  setTaxType,
  setGstTaxType,
  setGstRate,
  setRate,
  setTypeOfSupply,
  setOpeningBalance
} = fixedAssetSlice.actions;
export default fixedAssetSlice.reducer;
