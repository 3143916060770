import { createSlice } from "@reduxjs/toolkit";

export const posCategorySlice=createSlice({
    name:"posCategorySlice1",
    initialState:{
        value:undefined,
        mainCategoryList:undefined,
        posSingleView:undefined,
        tableList:undefined
    },
    reducers:{
        get_all_posCategory:(state,action)=>{
            state.value=action.payload.posCategoryData
        },
        get_mainCategoryList:(state,action)=>{
            state.mainCategoryList = action.payload.mainCategoryList
        },
        posCategorySingleView:(state,action)=>{
            state.posSingleView = action.payload.singleViewOfPos
        },
        pos_category_table_list:(state,action)=>{
            state.tableList = action.payload.tablePosList
        }
    }
})
export const {get_all_posCategory,get_mainCategoryList,posCategorySingleView,pos_category_table_list}=posCategorySlice?.actions
export default posCategorySlice.reducer