import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import InputBox from "../../../components/InputBox";
import DropdownInputBox from "../../../components/DropdownInputBox";
import AddIcon from "@mui/icons-material/Add";
import TableBox from "../../../components/TableBox";
import TextAreaInputBox from "../../../components/TextAreaInputBox";
import DeleteIcon from "@mui/icons-material/Delete";

const TabPanel = ({ value, index, children }) => {
  return (
    <div style={{ width: "100%" }} role="tabpanel" hidden={value !== index}>
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
};

const PurchaseVoucher = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const headings = [
    "No",
    "Item",
    "Name",
    "Rate",
    "Discount",
    "CGST",
    "SGST",
    "IGST",
    "Total",
  ];
  const headings2 = [
    "No",
    "Item Information ",
    " HSN / SAC",
    "Stock Qty",
    "Qty",
    "Unit",
    "Rate",
    "Total",
    "Paid Amount",
  ];
  const renderData = [
    {
      Item: "Furniture",
      Name: "Table",
      Rate: "400",
      Discount: "0%",
      Cgst: "0%",
      Sgst: "0%",
      Igst: "0%",
      Total: "500",
    },
    {
      Item: "Furniture",
      Name: "Table",
      Rate: "400",
      Discount: "0%",
      Cgst: "0%",
      Sgst: "0%",
      Igst: "0%",
      Total: "500",
    },
    {
      Item: "Furniture",
      Name: "Table",
      Rate: "400",
      Discount: "0%",
      Cgst: "0%",
      Sgst: "0%",
      Igst: "0%",
      Total: "500",
    },
  ];
  return (
    <div className="purchase-voucher">
      <div
        style={{
          padding: "10px 20px",
          background: "white",
          width: "100%",
          borderRadius: "10px",
          boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "#fff" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Accounting Invoice" {...a11yProps(0)} />
            <Tab label="Item invoice" {...a11yProps(1)} />
          </Tabs>
        </Box>
      </div>

      {/* tab section - 1*/}
      <TabPanel value={value} index={0}>
        <div className="accounting-voucher-menu-sections">
          <h3>Accounting Invoice</h3>
          <div class="purchase-voucher-input-container">
            <InputBox className="box" label={"Invoice No"} />
            <InputBox className="box" label={"Date"} type={"date"} />
            <InputBox className="box" label={"Supplier Invoice No"} />
            <InputBox className="box" label={"Invoice Date"} type={"date"} />
            <DropdownInputBox className="box" labelName={"Supplier"} />
          </div>
          <div
            style={{
              marginTop: "1em",
              border: "1px solid #e6e6e6",
              padding: "1em ",
              borderRadius: "5px",
            }}
          >
            <div
              style={{
                marginBlockStart: " 0em",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <div style={{ width: "100%", display: "flex" }}>
                <DropdownInputBox labelName={"Item"} />
                <InputBox containerStyle={{ width: "35%" }} label={"Name"} />
                <InputBox containerStyle={{ width: "30%" }} label={"Rate"} />
                <InputBox
                  containerStyle={{ width: "30%" }}
                  label={"Discount(%)"}
                />
                <InputBox label={"CGST"} />
                <InputBox label={"SGST"} />
                <InputBox label={"IGST"} />
              </div>

              <div className="container">
                <InputBox label={"Total"} containerStyle={{ width: "17%" }} />

                <div className="voucher-add">
                  <button className="add-button">
                    <AddIcon />
                  </button>
                  <button className="delete-button">
                    <i class="bi bi-trash3 delete-icon"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <TableBox
              headings={headings}
              isLoading={false}
              renderData={renderData}
              isNo={true}
            />
          </div>
          <div style={{background:'#edeef0',padding:'5px'}}>
        <div
          className="item-purchase-input-data-container"
         
        >
          <div>
            <div className="gst-input-field ">
              <div>
                <h5>IGST</h5>
                <InputBox />
              </div>
              <div>
                <h5>SGST</h5>
                <InputBox />
              </div>
              <div>
                <h5>CGST</h5>
                <InputBox />
              </div>
            </div>
            <div className="gst-input-field">
              <div>
                <h5>Discount</h5>
                <InputBox />
              </div>
            </div>
          </div>
          <div>
            <div className="gst-input-field">
              <div>
                <h5>Total Balance</h5>
                <InputBox  containerStyle={{width:'45%'}} />
              </div>
              <div>
                <h5>Fright / Other Expense</h5>
                <InputBox containerStyle={{width:'45%'}} />
              </div>
              <div>
                <h5>Cust / Adv Tax</h5>
                <InputBox containerStyle={{width:'45%'}} />
              </div>
              <div>
                <h5>CESS</h5>
                <InputBox containerStyle={{width:'45%'}} />
              </div>
              <div>
                <h5>Education & Component CESS</h5>
                <InputBox containerStyle={{width:'45%'}} />
              </div>
              <div>
                <h5>Round Off</h5>
                <InputBox containerStyle={{width:'45%'}} />
              </div>
              <div>
                <h3 style={{flex: '50% 1'}}>Grand Total</h3>
                <InputBox containerStyle={{width:'45%'}} />
              </div>
              <div>
                <h5>Paid Amount</h5>
                <InputBox containerStyle={{width:'45%'}} />
              </div>
            </div>
          </div>
        </div>

        
        
      </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
            className="button-container"
          >
            <div></div>
            <div style={{ display: "flex", gap: "10px" }}>
              <button
                style={{
                  background: "#fff",
                  border: "1px solid black",
                  color: "black",
                }}
                className="cancel-btn"
              >
                Cancel
              </button>
              <button
                style={{
                  background: "#0011f8",
                  border: "none",
                  color: "white",
                }}
                className="submit-btn"
              >
                Submit
              </button>
            </div>
            <button
              style={{ background: "#f38a00", border: "none", color: "white" }}
              className="save-draft-btn"
            >
              Save Draft
            </button>
          </div>
        </div>
      </TabPanel>
      {/* tab section - 2 */}
      <TabPanel value={value} index={1}>
        <div
          style={{ width: "100%", marginBlockEnd: "1em" }}
          className="accounting-voucher-menu-sections"
        >
          <h3>Item Invoice</h3>
          <hr className="global-hr" />
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              gap: "1em 0",
              alignItems: "center",
            }}
            className="item-invoice-input-container"
          >
            <InputBox label={"PO No"} />
            <DropdownInputBox labelName={"Supplier Name"} />
            <InputBox label={"Purchase Date"} type={"Date"} />
            <InputBox label={"Expiry Date"} type={"Date"} />
            <DropdownInputBox labelName={"Location"} />
            <DropdownInputBox labelName={"Payment Type"} />
            <TextAreaInputBox rows={"5"} cols={"100"} label={"Details"} />
          </div>
        </div>
        <div className="accounting-voucher-menu-sections">
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div style={{ width: "100%", display: "flex" }}>
              <DropdownInputBox labelName={"Item Information"} />
              <InputBox label={"HSN / SAC Code"} />
              <InputBox label={"Stock/Qty"} />
              <InputBox label={"Quantity "} />
              <InputBox label={"Unit"} />
              <InputBox label={"Rate"} />
              <InputBox label={"IGST"} />
              <InputBox label={"SGST"} />
              <InputBox label={"CGST"} />
            </div>
            <div className="container">
              <InputBox label={"Total"} containerStyle={{ width: "17%" }} />

              <div className="voucher-add">
                <button className="add-button">
                  <AddIcon />
                </button>
                <button className="delete-button">
                  <i class="bi bi-trash3 delete-icon"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="accounting-voucher-menu-sections "
          style={{ marginBlockStart: "1em" }}
        >
          <TableBox
            headings={headings2}
            isLoading={false}
            renderData={renderData}
            actionButton={true}
            isNo={true}
          />

          <div style={{ background: "#edeef0", padding: "5px" }}>
            <div className="item-purchase-input-data-container">
              <div>
                <div className="gst-input-field ">
                  <div>
                    <h5>IGST</h5>
                    <InputBox />
                  </div>
                  <div>
                    <h5>SGST</h5>
                    <InputBox />
                  </div>
                  <div>
                    <h5>CGST</h5>
                    <InputBox />
                  </div>
                </div>
                <div className="gst-input-field">
                  <div>
                    <h5>Discount</h5>
                    <InputBox />
                  </div>
                </div>
              </div>
              <div>
                <div className="gst-input-field">
                  <div>
                    <h5>Total Balance</h5>
                    <InputBox containerStyle={{ width: "45%" }} />
                  </div>
                  <div>
                    <h5>Fright / Other Expense</h5>
                    <InputBox containerStyle={{ width: "45%" }} />
                  </div>
                  <div>
                    <h5>Cust / Adv Tax</h5>
                    <InputBox containerStyle={{ width: "45%" }} />
                  </div>
                  <div>
                    <h5>CESS</h5>
                    <InputBox containerStyle={{ width: "45%" }} />
                  </div>
                  <div>
                    <h5>Education & Component CESS</h5>
                    <InputBox containerStyle={{ width: "45%" }} />
                  </div>
                  <div>
                    <h5>Round Off</h5>
                    <InputBox containerStyle={{ width: "45%" }} />
                  </div>
                  <div>
                  <h3 style={{flex: '50% 1'}}>Grand Total</h3>
                    <InputBox containerStyle={{ width: "45%" }} />
                  </div>
                  <div>
                    <h5>Paid Amount</h5>
                    <InputBox containerStyle={{ width: "45%" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
            className="button-container"
          >
            <div></div>
            <div style={{ display: "flex", gap: "10px" }}>
              <button
                style={{
                  background: "#fff",
                  border: "1px solid black",
                  color: "black",
                }}
                className="cancel-btn"
              >
                Cancel
              </button>
              <button
                style={{
                  background: "#0011f8",
                  border: "none",
                  color: "white",
                }}
                className="submit-btn"
              >
                Submit
              </button>
            </div>
            <button
              style={{ background: "#f38a00", border: "none", color: "white" }}
              className="save-draft-btn"
            >
              Save Draft
            </button>
          </div>
        </div>
      </TabPanel>
    </div>
  );
};

export default PurchaseVoucher;
