import React, { useState, useRef, useEffect } from "react";
import {
  Select,
  IconButton,
  MenuItem,
  Autocomplete,
  TextField,
  Skeleton,
  Tooltip,
} from "@mui/material";
import "../../../../css/Report/commissionReport.css";
import '../../../../css/Global Css/reportPrintGlobalCss.css'
import ReactToPrint from "react-to-print";
import { viewAllBranchesAPICall } from "../../../../API/Settings/Company Settings/companySettingsAPI";
import { useSelector } from "react-redux";
import { commissionReportAPICall } from "../../../../API/Report/commissionReportAPI";
import { getWorkOrderNosAPICall } from "../../../../API/Orders/workOrderAPI";
import { today, currentTime, previousDate, convertDateFormat, getCurrentTime } from "../../../../Js/Date";
import { getAllActiveEmployeesAPICall } from "../../../../API/Staff/HRM/employeeAPI";
import { orderNoListAPICall } from "../../../../API/Orders/orderNoListAPI";
import { ArrowDownward, ArrowUpward, NoBackpackSharp } from "@mui/icons-material";
import { convertArrayToExcel, sortGeneral } from "../../../../Js/generalFunctions";
export const CommissionReport = () => {
  let commissionReportPrint = useRef();
  let summaryPrint = useRef();

  // all branch list
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  // commission report data
  const commissionReport = useSelector(
    (state) => state.commissionReportSlice.value
  );
  //List of All Work order numbers
  const allWorkOrderNosList = useSelector(
    (state) => state.orderNoListSlice.value
  );
  // login response
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  // role based rendering
  const userRole = useSelector((state) => state.userRoleSlice.value);
  const storeCode=localStorage.getItem('branchId')
  const allActiveEmpList = useSelector(
    (state) => state.allActiveEmployeeSlice.value
  );

  const [branch, setBranch] = useState();
  const [workOrderNo, setWorkOrderNo] = useState();
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [tailor, setTailor] = useState();
  const [branchDetails, setBranchDetails] = useState({
    branchCode: undefined,
    branchId: undefined,
  });
  const [workOrderDetails,setWorkOrderDetails]=useState({
    workOrderId:undefined,
    workOrderType:''
  })
  const [summary, setSummary] = useState(false);
  const [branchWithAll, setBranchWithAll] = useState([]);
  const [isLoading,setIsLoading]=useState(false)
  const [isAscending,setIsAscending]=useState(false)
  const [commissionReportFilter,setCommissionReportFilter]=useState([])
  const [filteredEmployeeList, setFilteredEmployeeList] = useState([]);
  //To get current time
  const [time, setTime] = useState(null);

  
  // get current time fn
  useEffect(() => {
    setInterval(() => {
      setTime(`${new Date().getHours()}:${new Date().getMinutes()}`);
    }, 1000);
  }, [time]);

  useEffect(() => {
    viewAllBranchesAPICall();
    getAllActiveEmployeesAPICall();
  }, []);

  useEffect(() => {
    if (allBranchesList !== undefined) {
      setBranchDetails({
        ...branchDetails,
        branchCode: branch?.storeCode,
        branchId: branch?._id,
      });
    }
  }, [branch]);

  // useEffect(() => {
  //  getWorkOrderNosAPICall({ branchId: branchDetails.branchCode });
  // }, [branchDetails]);


  useEffect(()=>{
    orderNoListAPICall({ branchId: branchDetails.branchCode })
  },[branchDetails])

  // useEffect(()=>{
  //   if(workOrderNo !== undefined && workOrderNo !== null){
  //     setWorkOrderDetails({
  //       workOrderId:workOrderNo._id,
  //       workOrderType:workOrderNo.type
  //     })
  //   }
    
  // },[workOrderNo])

  const downloadList=()=>{
    convertArrayToExcel(commissionReport?.list,"Commission Report")

  }
  const bodyToApi = {
    orderId: workOrderNo !== undefined &&workOrderNo !== null ? workOrderNo._id :null,
    type:workOrderDetails?.workOrderId !== undefined ? workOrderNo.type:null,
    fromDate: fromDate === "" ? null :`${fromDate} 00:00:00`,
    toDate: toDate === "" ? null: `${toDate} 23:59:59`,
    branchId: userRole==="admin"?branchDetails.branchId:userRole==="user"?loginResponse?.branchPk:undefined,
    empid: tailor,
    isSummary:false,
    // branchId:storeCode
  };
  const commissionReportSubmitBtnFn = () => {
   
    commissionReportAPICall(bodyToApi,setIsLoading);
  };
  
 
  
  useEffect(() => {
    let filteredArray = allActiveEmpList?.filter((obj) => {
       if(branch?.storeCode === "-All"){
        // console.log('all');
        if (obj?.designationName.toLowerCase() === "tailor") {
          return obj;
         }
       }
       else{
        // console.log('not all');
        if(obj.branchId===branch?.storeCode || loginResponse?.data?.branchId){
          if (obj.designationName?.toLowerCase() === "tailor") {
            return obj;
           }
           
         }
       }
         
        
       
          
    
    });
    setFilteredEmployeeList(filteredArray);
  }, [allActiveEmpList,branch]);
  console.log(filteredEmployeeList);
 

//   useEffect(() => {
//     // setBranchWithAll(allBranchesList?.map((obj)=>obj))
//     if(allBranchesList!==undefined)
//     {
//      let _allData = allBranchesList?.map((obj, i) => {
//       return obj
//     });
//     setBranchWithAll([{ storeCode: "-All", _id: null,branchName:""},..._allData])
// }
// }, [allBranchesList]);

const summaryChange=()=>{
  setSummary(!summary)
  bodyToApi.isSummary = !summary;
   commissionReportAPICall(bodyToApi,setIsLoading);
}

  // useEffect(() => {
  //   commissionReportAPICall(bodyToApi,setIsLoading);
  // }, [summary]);


  // map commission report response to state
  useEffect(()=>{
    
      setCommissionReportFilter(commissionReport?.list?.map(obj=>{return {...obj}}))
    
  },[commissionReport])

  // date sorting
  const sortDateAscending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(commissionReportFilter, "Date", "date", true);
    setCommissionReportFilter(sorted)
  }
  const sortDateDescending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(commissionReportFilter, "Date", "date", false);
    setCommissionReportFilter(sorted)
  }

  // tailor sorting
  const sortTailorAscending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(commissionReportFilter, "String", "tailor", true);
    setCommissionReportFilter(sorted)
  }

  const sortTailorDescending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(commissionReportFilter, "String", "tailor", false);
    setCommissionReportFilter(sorted)
  }

  /// commission sorting
  const sortCommissionAscending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(commissionReportFilter, "Number", "commission", true);
    setCommissionReportFilter(sorted)
  }
  const sortCommissionDescending =()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(commissionReportFilter, "Number", "commission", false);
    setCommissionReportFilter(sorted)
  }

  // status sorting
  const sortStatusAscending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(commissionReportFilter, "String", "status", true);
    setCommissionReportFilter(sorted)
  }
  const sortStatusDescending =()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(commissionReportFilter, "String", "status", false);
    setCommissionReportFilter(sorted)
  }

  // branch code sorting
  const sortBranchCodeAscending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(commissionReportFilter, "String", "branchCode", true);
    setCommissionReportFilter(sorted)
  }
  const sortBranchCodeDescending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(commissionReportFilter, "String", "branchCode", false);
    setCommissionReportFilter(sorted)
  }

  // branch name sorting
  const sortBranchNameAscending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(commissionReportFilter, "String", "branchName", true);
    setCommissionReportFilter(sorted)
  }
  const sortBranchNameDescending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(commissionReportFilter, "String", "branchName", false);
    setCommissionReportFilter(sorted)
  }

  
 
  return (
    <div className="global-page-parent-container">
      <p className="breadcrumb">Report &gt; Commission Report</p>
      <div className="daily-report-top-container">
        <h3>Commission Report</h3>
        <hr className="global-hr" />
        <div className="daily-report-top-input-container expense-top-main-container">
          {userRole === "admin" && (
            <div className="global-single-input auto-complete">
              <p>Branch</p>
              <Autocomplete
                sx={{ width: "100%" }}
                options={allBranchesList || [""]}
                getOptionLabel={(option) =>
                  `${option?.storeCode}-${option?.branchName}`
                }
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Branch" />
                )}
                onChange={(e, newValue) => setBranch(newValue)}
              />
            </div>
          )}

          <div className="global-single-input auto-complete">
            <p>Work Order No</p>
            <Autocomplete
              sx={{ width: "100%" }}
              options={allWorkOrderNosList || [""]}
              getOptionLabel={(option) => option?.orderNo}
              renderInput={(params) => (
                <TextField {...params} placeholder="select order number..." />
              )}
              onChange={(e, newValue) => setWorkOrderNo(newValue)}
            />
          </div>
          <div className="global-single-input auto-complete">
            <p>Tailor</p>
            <Autocomplete
              sx={{ width: "100%" }}
              options={filteredEmployeeList || [""]}
              getOptionLabel={(option) => option?.staff_name}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Tailor..." />
              )}
              onChange={(e, newValue) =>
                setTailor(newValue?._id)
              }
            />
          </div>
          <div className="global-single-input">
            <p>From</p>
            <input type="date" onChange={(e) => setFromDate(e.target.value)} />
          </div>
          <div
            className="global-single-input"
           
          >
            <p>To</p>
            <input type="date"  onChange={(e) => setToDate(e.target.value)}/>
          </div>
        </div>
        <div className="expense-report-button-container">
          <button
            onClick={commissionReportSubmitBtnFn}
            className="btn btn-primary report-submit-btn"
          >
            Submit
          </button>
        </div>
      </div>
      {/* {commissionReport !== undefined ? ( */}
        <div className="global-white-bg-container">
          <div
            className="stock-report-print-download-icon-container"
            style={{ position: "relative" }}
          >
            <div className="total-commission-container">
              <p>Total Commission</p>
              &nbsp;:&nbsp;
              <p>{commissionReport?.totalCommission?.toFixed(2)}</p>
            </div>
            <div className="commission-report-summary">
              <input
                value={summary}
                onChange={summaryChange}
                type="checkbox"
                id="commission-summary"
              />
              <label htmlFor="commission-summary">Summary</label>
            </div>
            <Tooltip title="Share">
                    <IconButton style={{width:"59px",backgroundColor:"#a5c5f1",margin:"2px",borderRadius:"5px",padding:"0px"}}>
                      <i class="bi bi-share" style={{ color: "#15189b", fontSize: "medium", padding: "10px", borderRadius: "2px" }}></i>
                    </IconButton>
                  </Tooltip>
            <ReactToPrint
              trigger={() => (
                <IconButton  className="print-icon-container"style={{width:"60px"}}>
                  <i class="bi bi-printer printer-icon"></i>
                </IconButton>
              )}
              content={() =>
                summary === true ? summaryPrint : commissionReportPrint
              }
             
              pageStyle="@page { size: auto;  margin: 15mm 0 10mm 0; } }"
            />
            <IconButton onClick={downloadList} className="download-icon-container" style={{width:"60px"}}>
              <i class="bi bi-arrow-down-circle download-icon"></i>
            </IconButton>
          </div>

          <div className="global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>No</th>
                  {userRole === "admin" && (
                    <>
                      <th>
                        Branch Code
                        {
                isAscending ?(
                  <IconButton
                    onClick={sortBranchCodeAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortBranchCodeDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
                      </th>
                      <th>
                        Branch Name
                        {
                isAscending ?(
                  <IconButton
                    onClick={sortBranchNameAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortBranchNameDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }  
                      </th>
                    </>
                  )}
                  <th>
                    Work Order No
                    
                  </th>
                  <th>
                    Date
                    {
                isAscending ?(
                  <IconButton
                    onClick={sortDateAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortDateDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
                  </th>
                  <th>
                    Tailor
                    {
                isAscending ?(
                  <IconButton
                    onClick={sortTailorAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortTailorDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
                  </th>
                  <th>Type</th>
                  <th>Model</th>
                  <th>
                    Commission
                    {
                isAscending ?(
                  <IconButton
                    onClick={sortCommissionAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortCommissionDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
                  </th>
                  <th>
                    Status
                    {
                isAscending ?(
                  <IconButton
                    onClick={sortStatusAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortStatusDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
                  </th>
                </tr>
              </thead>
            
              <tbody>
                {commissionReportFilter!== undefined ? commissionReportFilter?.map((r, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    {userRole === "admin" && (
                      <>
                        <td>{r?.branchCode}</td>
                        <td>{r?.branchName}</td>
                      </>
                    )}
                    <td>{r?.workorderNo}</td>
                    <td>{convertDateFormat(r?.date)}</td>
                    <td>{r?.tailor}</td>
                    <td>{r?.type}</td>
                    <td>{r?.modelName === "" ? "Nill" : r?.modelName}</td>
                    <td>{r?.commission?.toFixed(2)}</td>
                    <td>
                      <p
                        className={
                          r.status === "completed"
                            ? "commission-report-status-delivered"
                            : r.status === "delivery"
                            ? "commission-report-status-pending"
                            : r.status === "delivered"
                            ? "commission-report-status-return"
                            : r.status === "printing"
                            ? "commission-report-status-printing"
                            : r.status === "pending"
                            ? "commission-report-status-booking"
                            : r.status === "deliveryReturned"
                            ? "commission-report-status-del-return"
                            : r.status === "orderReturned"
                            ? "commission-report-status-order-return"
                            : undefined
                        }
                      >
                        {r?.status === "completed"
                          ? "Finished"
                          : r?.status === "delivered"
                          ? "Delivered"
                          : r?.status === "printing"
                          ? "Printing"
                          : r?.status === "delivery"
                          ? "Delivery"
                          : r?.status === "pending"
                          ? "Booking"
                          : r.status === "deliveryReturned"
                          ? "Del Return"
                          : r.status === "orderReturned"
                          ? "Or. Return"
                          : undefined}
                      </p>
                    </td>
                  </tr>
                 
                ))
                :
                   <tr>
                      <td colSpan={9}>Search Records</td>
                   </tr>
              }
              </tbody>
              
              
            </table>
          </div>
        </div>
      {/* ) : (
        <div className="global-white-bg-container">
          <h3 className="no-data">No Records</h3>
        </div>
      )} */}

      {/* commission report print */}
      <div 
           style={{ display: "none" }}
      >
        <div ref={(el) => (commissionReportPrint = el)} >
          <div className="print-view-top-container">
            <div
              className="print-view-top-left-container"
              style={{ width: "25%" }}
            >
              <h6>Printed On :{convertDateFormat(today)}&nbsp;{getCurrentTime()}</h6>
            </div>
            <div
              className="print-view-top-middle-container"
              style={{ width: "50%" }}
            >
              {userRole === "admin" ? (
                <h3>{loginResponse?.data?.profile?.companyName}</h3>
              ) : userRole === "user" ? (
                <h3>{loginResponse?.storeName}</h3>
              ) : undefined}

              <p>COMMISSION REPORT</p>
            </div>
            <div
              className="print-view-top-right-container"
              style={{ width: "25%" }}
            >
              {fromDate !== undefined && fromDate !== ''  && <p>From : {convertDateFormat(fromDate)}</p>}
              {toDate !== undefined && toDate !== '' && <p>To :{convertDateFormat(toDate)}</p>}
             
            </div>
          </div>
          <div className="print-view-table-container report-print-view-table-with-clr">
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  {/* {userRole === "admin" && (
                    <>
                      <th>Branch Code</th>
                      <th>Branch Name</th>
                    </>
                  )} */}

                  <th>Work Order No</th>
                  <th>Date</th>
                  <th>Tailor</th>
                  <th>Type</th>
                  <th>Model</th>
                  <th>Commission</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>

                {
                commissionReportFilter!==undefined &&
                Array.from(new Set(commissionReportFilter.map(r=> r.branchName))).map((branchName,i)=>(
                  <React.Fragment key={i}>
                    {
                      userRole==='admin' &&
                      <tr>
                        <td colSpan={8}>
                            Branch Name :&nbsp;{branchName} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            Branch ID : &nbsp; {commissionReportFilter.find(item => item.branchName === branchName).branchCode}
                        </td>
                      </tr>
                    }
                    {
                      commissionReportFilter.filter(r=>r.branchName === branchName).map((r,j)=>(
                        <tr key={j}>
                          <td>{j+1}</td>
                          <td>{r?.workorderNo}</td>
                    <td>{convertDateFormat(r?.date)}</td>
                    <td>{r?.tailor}</td>
                    <td>{r?.type}</td>
                    <td>{r?.modelName === "" ? "Nill" : r?.modelName}</td>
                    <td>{r?.commission?.toFixed(2)}</td>
                    <td>
                      {r?.status === "completed"
                        ? "Finished"
                        : r?.status === "delivered"
                        ? "Delivered"
                        : r?.status === "printing"
                        ? "Printing"
                        : r?.status === "delivery"
                        ? "Delivery"
                        : r?.status === "pending"
                        ? "Booking"
                        : undefined}
                    </td>
                        </tr>
                      ))

                    }
                  </React.Fragment>
                ))}
                {/* {commissionReportFilter?.map((r, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    {userRole === "admin" && (
                      <>
                        <td>{r?.branchCode}</td>
                        <td>{r?.branchName}</td>
                      </>
                    )}
                    <td>{r?.workorderNo}</td>
                    <td>{convertDateFormat(r?.date)}</td>
                    <td>{r?.tailor}</td>
                    <td>{r?.type}</td>
                    <td>{r?.modelName === "" ? "Nill" : r?.modelName}</td>
                    <td>{r?.commission?.toFixed(2)}</td>
                    <td>
                      {r?.status === "completed"
                        ? "Finished"
                        : r?.status === "delivered"
                        ? "Delivered"
                        : r?.status === "printing"
                        ? "Printing"
                        : r?.status === "delivery"
                        ? "Delivery"
                        : r?.status === "pending"
                        ? "Booking"
                        : undefined}
                    </td>
                  </tr>
                ))} */}
              </tbody>
            </table>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "1% 8%",
            }}
          >
            <p style={{ fontSize: "1rem", fontWeight: 600 }}>
              Total Commission : {commissionReport?.totalCommission?.toFixed(2)}
            </p>
          </div>
        </div>
      </div>

      {/* summary print */}
      <div 
         style={{ display: "none" }}
      >
        <div ref={(el) => (summaryPrint = el)}>
          <div className="print-view-top-container">
            <div
              className="print-view-top-left-container"
              style={{ width: "25%" }}
            >
              <p>{commissionReport?.summary?.branchCount} BRANCHES</p>
            </div>
            <div
              className="print-view-top-middle-container"
              style={{ width: "50%" }}
            >
              {userRole === "admin" ? (
                <h3>{loginResponse?.data?.profile?.companyName}</h3>
              ) : userRole === "user" ? (
                <h3>{loginResponse?.storeName}</h3>
              ) : undefined}

              <p>TAILOR COMMISSION REPORT SUMMARY</p>
            </div>
            <div
              className="print-view-top-right-container"
              style={{ width: "25%" }}
            >
              {fromDate !== undefined && fromDate !== ''  && <p>From : {convertDateFormat(fromDate)}</p>}
              {toDate !== undefined && toDate !== '' &&  <p>To :{convertDateFormat(toDate)}</p>}
            </div>
          </div>
          {/* map start */}
          {commissionReport?.summary?.list?.map((r) => (
            <div className="commission-report-summary-container">
              <div className="commission-report-summary-top-container">
                <h3 style={{ width: "20%" }}>{r?.branchId}</h3>
                <h3>{r?.branchName}</h3>
              </div>
              <div
                className="global-table-container"
                style={{
                  margin: "1% 0",
                  maxHeight: "none",
                  overflowY: "hidden",
                }}
              >
                <table className="global-table report-print-view-table-with-clr">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Employee ID</th>
                      <th style={{textAlign:"left"}}>Tailor</th>
                      <th>Commission</th>
                    </tr>
                  </thead>
                  <tbody style={{ backgroundColor: "#fff" }}>
                    {r?.empCommission?.map((k, i) => (
                      <tr>
                        <td style={{ backgroundColor: "rgb(245 245 245)",height:'34px' }}>{i + 1}</td>
                        <td style={{ backgroundColor: "rgb(245 245 245)",height:'34px' }}>{k?.empId}</td>
                        <td style={{ backgroundColor: "rgb(245 245 245)",height:'34px',textAlign:'left' }}>{k?.tailor}</td>
                        <td style={{ backgroundColor: "rgb(245 245 245)",height:'34px' }}>
                          {k?.commission?.toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="commission-report-summary-total-container">
                <h4 style={{width:"20%"}}>Total</h4>
                <h4>{r?.totalCommission?.toFixed(2)}</h4>
              </div>
            </div>
          ))}


          {/* map end */}
          <div className="commission-report-summary-grand-total-container">
          <h4 style={{width:"20%"}}>Grand Total</h4>
          <h4>{commissionReport?.summary?.grandTotal?.toFixed(2)}</h4>
          </div>
        </div>
      </div>
    </div>
  );
};
