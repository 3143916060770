import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { addCuttingSlipModelAPICall, viewCuttingSlipModelAPICall } from '../../../../API/Settings/CuttingSlipModel/cuttingSlipMoldelAPI'
import '../../../../css/Settings/CuttingSlipModel/custtingSlipModel.css'
import { ThobCuttingSlip } from '../../Orders/PrintCuttingSlip/ThobCuttingSlip'
import { ThobCuttingSlip1 } from '../../Orders/PrintCuttingSlip/ThobCuttingSlip1Copy'
import SuccessSnackbar from '../../../Single Components/SnackBars/SuccessSnackbar'
import ErrorSnackbar from '../../../Single Components/SnackBars/ErrorSnackbar'

export const CuttingSlipModel = () => {
  // cutting slip template
  const cuttingSlipTemplate=useSelector((state)=>state.cuttingSlipSlice.value)

  const [templateType,setTemplateType]=useState()
  const [isModify,setIsModify]=useState()
  //snackbar states
  const [successSnackOpen,setSuccessSnackOpen]=useState(false)
  const [errorSnackOpen,setErrorSnackOpen]=useState(false)
  const [snackMsg, setSnackMsg] = useState("oops!!");

  const updateListner=()=>{
    setIsModify(!isModify)
  }

  useEffect(()=>{
    viewCuttingSlipModelAPICall()
  },[])

useEffect(()=>{
  setTemplateType(cuttingSlipTemplate?.template)
},[cuttingSlipTemplate])
 
 

  const getCheckedType=(e)=>{
   const {value,checked}=e.target;
   if(checked){
    setTemplateType(value)
   }
   
  }
  
  useEffect(()=>{
    if(templateType !== undefined){
      addCuttingSlipModelAPICall(
        {template:templateType},
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListner
        )
    }
   
  },[templateType])

  
 
  return (
    <div className='settings-cutting-slip-model'>
      <div className='settings-cutting-slip-model-one'>
         <div className='settings-cutting-slip-model-radio-button'>
           <input 
           type="radio" 
           id="settings-cutttingSlip-model" 
           name="cuttingSlip-model"
           value="template1"
           onClick={getCheckedType}
           checked={templateType=="template1"}
           />
           <label htmlFor="settings-cutttingSlip-model">Template1</label>
         </div>
         <div>
           <ThobCuttingSlip/>
         </div>
      </div>
      <hr className='settings-cutting-slip-model-divider'/>
      <div className='settings-cutting-slip-model-two'>
      <div className='settings-cutting-slip-model-radio-button'>
           <input 
           type="radio" 
           id="settings-cutttingSlip-model1" 
           name="cuttingSlip-model"
           value="template2"
           onClick={getCheckedType}
           checked={templateType=="template2"}
           />
           <label htmlFor="settings-cutttingSlip-model1">Template2</label>
         </div>
         <div>
           <ThobCuttingSlip1/>
         </div>
      </div>

      <SuccessSnackbar
         open={successSnackOpen}
         handleClose={()=>setSuccessSnackOpen(false)}
         message={snackMsg}
      />
      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={()=>setErrorSnackOpen(false)}
        message={snackMsg}
      />
    </div>
  )
}
