

// view branch settings api call

import axios from "axios"
import { HEADERS } from "../../../../API/UrlAndPaths"
import store from "../../../../Redux/store"
import { get_branch_settings_user_res_list, get_branch_setttings_res_list } from "./branchSettingSlice"

export const viewBranchSettingsAPICall=async(body)=>{
    await axios.post("settings/viewbranchsettings",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(get_branch_setttings_res_list({branchSettingsData:res.data}))
            }
        })
        .catch((err)=>{
            store.dispatch(get_branch_setttings_res_list({branchSettingsData:undefined}))
        })
}

// add branch settings

export const addBranchSettingsAPICall=async(body,postFn,setState,state)=>{
    await axios.post("settings/addbranchsettings",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                setState({...state,message:"Branch settings updated",success:true})
                postFn()
            }
        })
        .catch((err)=>{
          setState({...state,message:"Something went wrong !!",error:true})
        })
}


// edit branch settings

export const editBranchSettingsAPICall=async(body,postFn,setState,state)=>{
    await axios.put("settings/editbranchsettings",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                setState({...state,message:"Branch settings updated",success:true})
                postFn()
            }
        })
        .catch((err)=>{
          setState({...state,message:"Something went wrong !!",error:true})
        })
}

// branch settings user
// export const viewBranchSettingsUserAPICall=async(body)=>{
//      await axios.post("settings/viewUserbranchSettings",body,HEADERS)
//      .then((res)=>{
//         if(res.status===200){
//             store.dispatch(get_branch_settings_user_res_list({branchSettingsUserData:res.data}))
//         }
//      })
//      .catch((err)=>{
//         console.log(err.response);
//      })
// }
export const editBranchSettingsUserAPICall=(body,setState,state)=>{
    axios.put("settings/editbranchsettings",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
        setState({ ...state, message: "Update successfully !!", success: true });
        }
    })
    .catch((err)=>{
        setState({ ...state, message: "Something went wrong !!", error: true });

    })
}