import React from "react";
import "../../css/Single Components/colorPickerPopup.css";
import { Dialog, Tooltip } from "@mui/material";

export const ColorPickerPopup = (props) => {
  const {
    open,
    label,
    closePopup,
    onColorChange,
    colorName,
    onColorNameChange,
    value,
    onAddClick,
  } = props;
  return (
    <>
      <Dialog
        open={open}
        maxWidth="lg"
        onKeyDown={(e) => {
          e.key === "Escape" && closePopup();
        }}
      >
        <div className="add-popup-container">
          <p>{label}</p>
          <div className="add-color-popup-input-container">
            <input
              className="input-container"
              placeholder="Colour Name..."
              value={colorName}
              onChange={onColorNameChange}
            />
            <Tooltip title="Colour Picker">
              <input
                type="color"
                className="color-input-container"
                value={value}
                onChange={onColorChange}
                onKeyDown={(e) => {
                  e.key === "Enter" && onAddClick();
                }}
              />
            </Tooltip>

            <button
              onClick={onAddClick}
              disabled={colorName !== "" ? false : true}
              className={colorName !== "" ? "" : "disabled"}
            >
              +
            </button>
          </div>
          <div className="add-popup-table">
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Color Name</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>{props.children}</tbody>
            </table>
          </div>
          <div className="add-popup-button-container">
            <button onClick={closePopup} className="btn btn-secondary">
              Close
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};
