import { createSlice } from "@reduxjs/toolkit";

export const customerPaymentSlice = createSlice({
  name: "customerPaymentSlice",
  initialState: {
    apiResList: undefined,
    rowData:undefined, 
    generateName:undefined,
    customerPayment:undefined,
    customerInvoice:[],
  },
  reducers: {
    get_customer_payment_api_res_list: (state, action) => {
      state.apiResList = action.payload.customerPaymentResData;
    },
    get_customer_payment_row_data_list: (state, action) => {
      state.rowData = action.payload.customerPaymentSingleRowData;
    },
    generate_customer_payment_name: (state, action) => {
      state.generateName = action.payload.customerPaymentName;
    } ,
    set_customer_invoice: (state, action) => {
      state.customerInvoice = action.payload;
    },
    customer_payment_single_view:(state,action)=>{
      state.customerPayment =action.payload.paymentSingleView
    },
  },
});

export const { 
  set_customer_invoice,
    get_customer_payment_api_res_list,
    get_customer_payment_row_data_list,
    generate_customer_payment_name,
    customer_payment_single_view
} = customerPaymentSlice.actions;

export default customerPaymentSlice.reducer;