import { Dialog, IconButton, Skeleton } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { addHolidayTypeAPICall, deleteHolidayAPICall, editHolidayTypeAPICall, viewHolidayTypesAPICall } from "../../../../API/Staff/HRM/Leave/staffHolidayTypeAPI";
import { convertDateFormat } from "../../../../Js/Date";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
// css from "staffLeaveApplication.css"
export const StaffHoliday=()=>{

    const holidayTypeList=useSelector((state)=>state.holidayTypeViewSlice.value) 

    const[staffHolidayAdd,setStaffHolidayAdd]=useState(false)
    const[staffHolidayEdit,setStaffHolidayEdit]=useState(false)
    const[isModify,setIsModify]=useState(false)
    const[holidayId,setHolidayId]=useState("")
    // loading states
    const [isLoading,setIsLoading]=useState(false)
    const [holidayNameAlert,setHolidayNameAlert]=useState(false)
    const [holidayFrmDateAlert,setHolidayFrmDateAlert]=useState(false)
    const [holidayToDateAlert,setHolidayToDateAlert]=useState(false)
    const [dateExceedAlert,setDateExceedAlert]=useState(false)

    const [successSnackOpen, setSuccessSnackOpen] = useState(false);
    const [errorSnackOpen, setErrorSnackOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState("oops!!!");
    
    const holidayAddFormInitialState={
        holidayName:'',
        fromDate:'',
        toDate:'',
        noOfDays:''
    }
    const [holidayAddForm,setHolidayAddForm]=useState(holidayAddFormInitialState)

    const getHolidayForm=(key)=>(e)=>{
        const{value}=e.target
        if(key==="holidayName"){
            setHolidayAddForm({...holidayAddForm,holidayName:value})
            setHolidayNameAlert(false)
            if(value===""){
                setHolidayNameAlert(true)
            }
        }
        if(key==="fromDate"){
            setHolidayAddForm({...holidayAddForm,fromDate:value})
            setHolidayFrmDateAlert(false)
        }
        if(key==="toDate"){
            setHolidayAddForm({...holidayAddForm,toDate:value})
            setHolidayToDateAlert(false)
        }
    }
    const clearStates=()=>{
        setStaffHolidayAdd(false)
        setStaffHolidayEdit(false)
        setHolidayAddForm(holidayAddFormInitialState)
        setHolidayNameAlert(false)
        setHolidayFrmDateAlert(false)
        setHolidayToDateAlert(false)
    }
    const updateListener=()=>{
        setIsModify(!isModify)
    }
    const addHolidayPayload={
        holidayName:holidayAddForm.holidayName,
        fromDate:holidayAddForm.fromDate,
        toDate:holidayAddForm.toDate,
        numberOfDays:holidayAddForm.noOfDays
    }

    const submitAddHoliday=()=>{
        if(holidayAddForm.holidayName===""){
            setHolidayNameAlert(true)
        }
        else if(holidayAddForm.fromDate===""){
            setHolidayFrmDateAlert(true)
        }
        else if(holidayAddForm.toDate===""){
            setHolidayToDateAlert(true)
        }
        else if(dateExceedAlert===true){
            console.log("selected date exceeds");
        }
        else{
            addHolidayTypeAPICall(
                addHolidayPayload,
                updateListener,
                clearStates,
                setSuccessSnackOpen,
                setErrorSnackOpen,
                setSnackMsg
                )
            }
    }
    const clickDeleteHoliday =(id)=>()=>{
        deleteHolidayAPICall({_id:id},updateListener,setErrorSnackOpen,setSnackMsg)
    }
    const holidayEdit=(id,holidayName,frmDate,toDate)=>()=>{
        setStaffHolidayEdit(true)
        setHolidayAddForm({...holidayAddForm,
            holidayName:holidayName,
            fromDate:frmDate,
            toDate:toDate       
        })
        setHolidayId(id)
    }  
    const holidayEditPayload={
        _id:holidayId,
        holidayName:holidayAddForm.holidayName,
        fromDate:holidayAddForm.fromDate,
        toDate:holidayAddForm.toDate,
        numberOfDays:holidayAddForm.noOfDays
    }
    const submitEditHoliday=()=>{
        if(holidayAddForm.holidayName===""){
            setHolidayNameAlert(true)
        }
        else if(holidayAddForm.fromDate===""){
            setHolidayFrmDateAlert(true)
        }
        else if(holidayAddForm.toDate===""){
            setHolidayToDateAlert(true)
        }
        else if(dateExceedAlert===true){
            console.log("selected date exceeds");
        }
        else{
        editHolidayTypeAPICall(
            holidayEditPayload,
            updateListener,
            clearStates,
            setSuccessSnackOpen,
            setErrorSnackOpen,
            setSnackMsg)
        }
    }
    const closeSuccessSnack = () => {
        setSuccessSnackOpen(false);
    };
    const closeErrorSnack = () => {
        setErrorSnackOpen(false);
    };
    // days b/w dates
    useEffect(()=>{
        let from=new Date(holidayAddForm.fromDate)
        let to=new Date(holidayAddForm.toDate)
        if(from>to){
            setDateExceedAlert(true)
        }
        else{
            setDateExceedAlert(false)
            let timeDiff=Math.abs(to.getTime()-from.getTime())
            setHolidayAddForm(
                {...holidayAddForm,noOfDays:Math.ceil(timeDiff/(1000*3600*24))+1}
                )
            }
    },[holidayAddForm.fromDate,holidayAddForm.toDate])

    useEffect(()=>{
        viewHolidayTypesAPICall(setIsLoading,setErrorSnackOpen,setSnackMsg)
    },[isModify])
    return(
        <>
        <div className="global-page-parent-container">
        <p className="breadcrumb">Staff &gt; Leave &gt; Holidays</p>
        <div className="global-table-container staff-holiday-container">
            <div className="staff-holiday-container-top">
                <h3>Holiday Types</h3>
                <button className="btn" onClick={()=>{setStaffHolidayAdd(true)}}>Add Holiday</button>
            </div>
            <table className="global-table">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Holiday Name</th>
                        <th>From</th>
                        <th>To</th>
                        <th>No of days</th>
                        <th>Action</th>
                    </tr>
                </thead>
                {
                isLoading?
                <tbody>
                    <tr>
                    <td colSpan={10}>
                        {/* <Loader /> */}
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={40}
                        />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={10}>
                        {/* <Loader /> */}
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={40}
                        />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={10}>
                        {/* <Loader /> */}
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={40}
                        />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={10}>
                        {/* <Loader /> */}
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={40}
                        />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={10}>
                        {/* <Loader /> */}
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={40}
                        />
                    </td>
                    </tr> 
                    
                </tbody>:
                <tbody>
                    {
                        holidayTypeList!==undefined?
                        holidayTypeList
                                ?.slice(0)
                                ?.reverse()
                                ?.map((r,i)=>
                                <tr key={i}>
                                    <td>{i+1}</td>
                                    <td>{r?.holidayName?.toUpperCase()}</td>
                                    <td>{convertDateFormat(r?.fromDate)}</td>
                                    <td>{convertDateFormat(r?.toDate)}</td>
                                    <td>{r?.numberOfDays}</td>
                                    <td>
                                        <IconButton onClick={holidayEdit(r?._id,
                                                                         r?.holidayName,
                                                                         r?.fromDate,
                                                                         r?.toDate
                                    )}>
                                            <i class="bi bi-pencil-square edit-icon"></i>
                                        </IconButton>
                                        <IconButton onClick={clickDeleteHoliday(r?._id)}>
                                            <i class="bi bi-trash3 delete-icon"></i>
                                        </IconButton>
                                    </td>
                                </tr>
                                    
                                    ):""
                    }
                </tbody>
                }
            </table>

            <Dialog open={staffHolidayAdd} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setStaffHolidayAdd(false)}>
                <div className="staff-holiday-edit-container">
                    <div className="staff-holiday-edit-container-top">
                        <h3>Add Holiday</h3>
                    </div>
                    <div className="staff-holiday-edit-container-body">
                        <div className="global-single-input">
                            <p>Holiday Name</p>
                            <input 
                            type="text"
                            value={holidayAddForm?.holidayName}
                            onChange={getHolidayForm("holidayName")}
                            />
                            {
                                holidayNameAlert?
                                <p className="doc-validation-alert">Fill this field !!</p>
                                :""
                            }
                        </div>
                        <div className="global-single-input">
                            <p>Holiday From</p>
                            <input type="date" 
                            value={holidayAddForm?.fromDate}
                            onChange={getHolidayForm("fromDate")}
                            />
                            {
                            holidayFrmDateAlert?
                                <p className="doc-validation-alert">Fill this field !!</p>
                                :""
                            }
                        </div>
                        <div className="global-single-input">
                            <p>Holiday To</p>
                            <input type="date"
                            value={holidayAddForm?.toDate}
                            onChange={getHolidayForm("toDate")}
                            />
                            {
                            holidayToDateAlert?
                                <p className="doc-validation-alert">Fill this field !!</p>
                                :""
                            }
                            {
                             holidayToDateAlert===false &&dateExceedAlert?
                             <p className="doc-validation-alert">Selected date is earlier than the from date!!</p>:""   
                            }
                        </div>
                        <div className="global-single-input">
                            <p>Number Of Days</p>
                            <input type="text"
                            value=
                            {isNaN(holidayAddForm?.noOfDays)?0:holidayAddForm?.noOfDays}
                            disabled
                            />
                        </div>
                    </div>
                    <div className="staff-holiday-edit-container-button">
                        <button className="btn btn-secondary-outlined" onClick={clearStates}>Cancel</button>
                        <button className="btn btn-primary" onClick={submitAddHoliday}>Submit</button>
                    </div>
                </div>
            </Dialog>

            <Dialog open={staffHolidayEdit} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setStaffHolidayEdit(false)}>
                <div className="staff-holiday-edit-container">
                    <div className="staff-holiday-edit-container-top">
                        <h3>Edit Holiday</h3>
                    </div>
                    <div className="staff-holiday-edit-container-body">
                        <div className="global-single-input">
                            <p>Holiday Name</p>
                            <input 
                            type="text"
                            value={holidayAddForm?.holidayName}
                            onChange={getHolidayForm("holidayName")}
                            />
                            {
                                holidayNameAlert?
                                <p className="doc-validation-alert">Fill this field !!</p>
                                :""
                            }
                        </div>
                        <div className="global-single-input">
                            <p>Holiday From</p>
                            <input type="date" 
                            value={holidayAddForm?.fromDate}
                            onChange={getHolidayForm("fromDate")}
                            />
                            {
                            holidayFrmDateAlert?
                                <p className="doc-validation-alert">Fill this field !!</p>
                                :""
                            }
                        </div>
                        <div className="global-single-input">
                            <p>Holiday To</p>
                            <input type="date"
                            value={holidayAddForm?.toDate}
                            onChange={getHolidayForm("toDate")}
                            />
                            {
                            holidayToDateAlert?
                                <p className="doc-validation-alert">Fill this field !!</p>
                                :""
                            }{
                                holidayToDateAlert===false &&dateExceedAlert?
                                <p className="doc-validation-alert">Selected date is earlier than the from date!!</p>:""   
                               }
                        </div>
                        <div className="global-single-input">
                            <p>Number Of Days</p>
                            <input type="text"
                            value=
                            {isNaN(holidayAddForm?.noOfDays)?0:holidayAddForm?.noOfDays}
                            disabled
                            />
                        </div>
                    </div>
                    <div className="staff-holiday-edit-container-button">
                        <button className="btn btn-secondary-outlined" onClick={clearStates}>Cancel</button>
                        <button className="btn btn-primary" onClick={submitEditHoliday}>Edit</button>
                    </div>
                </div>
            </Dialog>
            </div>
        </div>
        <SuccessSnackbar
                open={successSnackOpen}
                handleClose={closeSuccessSnack}
                message={snackMsg}
            />
        <ErrorSnackbar
            open={errorSnackOpen}
            handleClose={closeErrorSnack}
            message={snackMsg}
        />
        </>
    )
}