import {  createSlice } from "@reduxjs/toolkit"

export const docTypeSlice=createSlice({
    name:'docTypeSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_all_doc_type_list:(state,action)=>{
            state.value=action.payload.allDocType
        }
    }
})

export const {update_all_doc_type_list}=docTypeSlice.actions
export default docTypeSlice.reducer