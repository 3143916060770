import {
    createSlice
} from '@reduxjs/toolkit'
export const companyProfileSlice = createSlice({
    name: 'companyProfileSlice',
    initialState: {
        value: undefined,
        userValue:undefined
    },
    reducers: {
        update_company_profile: (state, action) => {
            state.value = action.payload.companyProfile
        }
        ,
        update_user_company_info:(state,action)=>{
            state.userValue=action.payload.userCompanyInfo
        }
        
    }
})

export const {
    update_company_profile,update_user_company_info
} = companyProfileSlice.actions
export default companyProfileSlice.reducer

