import { createSlice } from "@reduxjs/toolkit";

export const allDesignationListSlice=createSlice({
    name:'allDesignationListSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_all_designation_list:(state,action)=>{
            state.value=action.payload.allDesignation
        }
    }
})

export const {update_all_designation_list}=allDesignationListSlice.actions
export default allDesignationListSlice.reducer