import { createSlice } from "@reduxjs/toolkit";

export const jobCompletionReportSlice=createSlice({
    name:"jobCompletionReportSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        get_job_completion_report:(state,action)=>{
            state.value=action.payload.jobCompletionReportList
        }
    }
})

export const {get_job_completion_report} = jobCompletionReportSlice?.actions;
export default jobCompletionReportSlice?.reducer