import { createSlice } from "@reduxjs/toolkit";

export const viewCurrencySlice=createSlice({
    name:'viewCurrencySlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_all_currency:(state,action)=>{
            state.value=action.payload.viewCurrencyData
        }
    }
})
export const {get_all_currency} = viewCurrencySlice.actions;
export default viewCurrencySlice.reducer