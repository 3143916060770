import React from "react";
import InputBox from "../InputBox";
import DropdownInputBox from "../DropdownInputBox";
import SubmitBtn from "../SubmitBtn";
import { useDispatch, useSelector } from "react-redux";
import {
  setBankAccountDetails,
  setBankAccountNo,
  setBankName,
  setBranchName,
  setIfscCode,
  setOpeningBalance,
  setEndDate,
  setLoanNo,
  setMonthlyPaidAmount,
  setStartingDate,
  setTotalLoanAmount,
} from "../../../../../Redux/Account/loansAndAdvanceSlice";

const LoansAndAdvanceSlice = () => {
  const bankAccountDetailsOption = [{ option: "Yes" }, { option: "No" }];
  const dispatch = useDispatch();
  const {
    openingBalance,
    bankAccountDetails,
    bankName,
    bankAccountNo,
    ifscCode,
    branchName,
    loanNo,
    startingDate,
    endDate,
    monthlyPaidAmount,
    totalLoanAmount,
  } = useSelector((state) => state.loansAndAdvanceSlice);
  const loansAndAdvanceSlice = useSelector(
    (state) => state.loansAndAdvanceSlice
  );
  const handleloansAndAdvanceSliceSubmit = () => {
    console.log(loansAndAdvanceSlice);
  };
  return (
    <>
      <div className="ledger-section-container-input-bank  ">
        <div style={{ padding: "2% 4%" }} className="bottom-line">
          {/*Opening Balance*/}
          <InputBox
            label={"Opening Balance"}
            value={openingBalance}
            onChange={(e) => dispatch(setOpeningBalance(e.target.value))}
            containerStyle={{width:'31%'}}

          />
        </div>
        <div
          style={{ padding: "2% 4%" }}
          className="ledger-section-container-input-bank bottom-line"
        >
          <div>
            {/*bank account details */}

            <DropdownInputBox
              labelName={"Bank account details"}
              value={bankAccountDetails}
              options={bankAccountDetailsOption}
              onChange={(e, value) => dispatch(setBankAccountDetails(value))}
            />

            {/*bank Name*/}
            <InputBox
              label={"Bank Name"}
              value={bankName}
              onChange={(e) => dispatch(setBankName(e.target.value))}
            />
            {/*bank account number  */}
            <InputBox
              label={"Bank account number"}
              value={bankAccountNo}
              onChange={(e) => dispatch(setBankAccountNo(e.target.value))}
            />
          </div>
          <div style={{ justifyContent: "flex-start" }}>
            {/*IFSC Code  */}
            <InputBox
              label={"IFSC Code"}
              value={ifscCode}
              onChange={(e) => dispatch(setIfscCode(e.target.value))}
              containerStyle={{width:'31%'}}

            />

            {/*branch name */}
            <InputBox
              label={"Branch Name"}
              value={branchName}
              onChange={(e) => dispatch(setBranchName(e.target.value))}
              containerStyle={{width:'31%'}}

            />
          </div>
        </div>
        <div
          style={{ padding: "2% 4%" }}
          className="ledger-section-container-input-bank bottom-line"
        >
          <div>
            {/*loan no */}
            <InputBox
              label={"Loan no"}
              value={loanNo}
              onChange={(e) => dispatch(setLoanNo(e.target.value))}
            />

            {/*starting date*/}
            <InputBox
              label={"Starting Date"}
              value={startingDate}
              onChange={(e) => dispatch(setStartingDate(e.target.value))}
            />
            {/* end date */}
            <InputBox
              label={"End Date"}
              value={endDate}
              onChange={(e) => dispatch(setEndDate(e.target.value))}
            />
          </div>
          <div style={{ justifyContent: "flex-start" }}>
            {/*monthly paid amount  */}
            <InputBox
              label={"Monthly paid amount"}
              value={monthlyPaidAmount}
              onChange={(e) => dispatch(setMonthlyPaidAmount(e.target.value))}
              containerStyle={{width:'31%'}}

            />

            {/* total loan amount */}
            <InputBox
              label={"Total Loan Amount"}
              value={totalLoanAmount}
              onChange={(e) => dispatch(setTotalLoanAmount(e.target.value))}
              containerStyle={{width:'31%'}}

            />
          </div>
        </div>

        {/* submitButton */}
      </div>
      <SubmitBtn onClick={handleloansAndAdvanceSliceSubmit} />
    </>
  );
};

export default LoansAndAdvanceSlice;
