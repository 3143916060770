import { createSlice } from "@reduxjs/toolkit";
 
export const TrailBalanceSlice = createSlice({
    name: "trailbalance",
    initialState: {
       generalLedgerData:undefined,
       generalLedgerSingleData:undefined,
       trailBalanceListData:undefined,
       profitAndLossData:undefined,
       balanceSheetListData:undefined,
    },
    reducers:{
     get_general_list: (state, action)=>{
        state.generalLedgerData=action.payload.trailBalanceList
     },

     get_general_single_list:(state,action)=>{
       state.generalLedgerSingleData=action.payload.trailBalanceSingleList
     },
    get_trial_Balance_List:(state,action)=>{
      state.trailBalanceListData=action.payload.trailBalance
    },

    get_profit_loss_list:(state,action)=>{
      state.profitAndLossData=action.payload.profitAndLossList
    },
    get_balance_sheet_list:(state,action)=>{
      state.balanceSheetListData=action.payload.balanceSheetList
    }



    }
})

export const {get_general_list, get_general_single_list, get_trial_Balance_List, get_profit_loss_list,get_balance_sheet_list}=TrailBalanceSlice.actions;

export default TrailBalanceSlice.reducer;