import React from "react";
import InputBox from "../InputBox";
import { useDispatch, useSelector } from "react-redux";
import { setOpeningBalance } from "../../../../../Redux/Account/longTermLiabilitySlice";
import SubmitBtn from "../SubmitBtn";

const LongTermLiability = () => {
  const dispatch = useDispatch();
  const { openingBalance } = useSelector((state) => state.longTermLiabilitySlice);
  const longTermLiabilitySlice = useSelector((state) => state.longTermLiabilitySlice);
  const handleProvisionsSubmit = () => {
    console.log(longTermLiabilitySlice);
  };
  return (
    <div
      style={{ padding: "2% 4%" }}
      className="ledger-section-container-input-bank "
    >
      <div>
        {/*Opening Balance*/}
        <InputBox
          label={"Opening Balance"}
          value={openingBalance}
          onChange={(e) => dispatch(setOpeningBalance(e.target.value))}
          containerStyle={{width:'31%'}}

        />
      </div>
      {/* submitButton */}
      <SubmitBtn onClick={handleProvisionsSubmit} />
    </div>
  );
};

export default LongTermLiability;
