import axios from "axios";
import { get_tax_settings_list, tax_filtered_list } from "../../../Redux/Settings/TaxSettings/taxSettingsSlice";
import store from "../../../Redux/store";
import { HEADERS } from "../../UrlAndPaths";

export const getAllTaxSettingsListAPICall = () => {
  axios
    .post("settings/taxList", {}, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(get_tax_settings_list({ taxSettingsData: res.data }));
      }
    })
    .catch((err) => {
      console.log(err.response);
    });
};
export const TaxSettingsFilterAPICall =(body,setIsLoading)=>{
  setIsLoading!==undefined&&setIsLoading(true)

  axios.post("settings/searchtaxList",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(tax_filtered_list({filteredListTax:res.data}))
      setIsLoading!==undefined&&setIsLoading(false)
    }
  })
  .catch((err)=>{
    store.dispatch(tax_filtered_list({filteredListTax:undefined}))
    setIsLoading!==undefined&&setIsLoading(false)
  })

}
// addTaxsettings 
export const createTaxSettingsApicall = (
  addCreateTax,
  setSuccessSnackOpen,
  setErrorSnackOpen,
  setSnackMsg,
  setCreateTax
) => {
  // console.log(">>>>>>>>>>", addCreateTax);
  axios
    .post("settings/createTax", addCreateTax, HEADERS)

    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Created Success fully !!");
        setSuccessSnackOpen(true);
        setCreateTax(false);
      }
    })
    .catch((err) => {
      if (err.response.status === 500) {
        setSnackMsg("Internal Server error !");
      } else {
        setSnackMsg(err?.response?.data);
      }
     
      setErrorSnackOpen(true);
    });
};

//updateTaxSettingsApiCall
export const updateTaxSettingsApiCall = (
  body,
  setSuccessSnackOpen,
  setSnackMsg,
  setCreateTax
) => {
  axios
    .put("settings/editTax", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Update Success fully !!");
        setSuccessSnackOpen(true);
        setCreateTax(false);
      }
    })
    .catch((err) => {
      window.alert("Error");
    });
};

// UpdateTaxStatusAPiCall
export const UpdateTaxStatusAPiCall = (id,status,setTaxStatus,TaxStatus) => {
  console.log(status)
  axios
    .post(
      "settings/updateTaxStatus",
      {
        id: id,
        status: !status,
      },
      HEADERS
    )
    .then((res) => {
      if(res.status === 200){
        console.log(res.data)
        setTaxStatus(!TaxStatus)
      }
    })
    .catch((err) => {
      window.alert("ERROR OF STATUS UPDATE>>>")

    });
};
