import { createSlice } from "@reduxjs/toolkit";

export const viewOrderSearchRelationSlice=createSlice({
    name:'viewOrderSearchRelationSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_view_order_relation_list:(state,action)=>{
            state.value=action.payload.searchRelationData
        }
    }
})

export  const{update_view_order_relation_list}=viewOrderSearchRelationSlice.actions
export default viewOrderSearchRelationSlice.reducer