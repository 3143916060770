import { Autocomplete, MenuItem, Select, Skeleton, TextField } from '@mui/material'
import "../../../../css/Settings/Log/logSettings.css"
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { logAPICall } from '../../../../API/Settings/Log/logAPI'
import { convertDateFormat } from '../../../../Js/Date'
import { viewAllBranchesAPICall } from '../../../../API/Settings/Company Settings/companySettingsAPI'

export const Log = () => {
  const logData=useSelector((state)=>state.logSlice.value)
   // all branch list
   const allBranchesList = useSelector((state) => state.allBranchesSlice.value);

  const [selectedLoc, setSelectedLoc] = useState(null);
  const [isLoading,setIsLoading]=useState(false)

 

  useEffect(()=>{
    viewAllBranchesAPICall()
    logAPICall({branchId:selectedLoc},setIsLoading)
  },[selectedLoc])

  return (
    <div className="log-settings-container">
        <div className="log-settings-container-top">
        <h4>Log</h4>
        <div className="global-single-input auto-complete">
        <Autocomplete
                sx={{ width: "100%" }}
                options={allBranchesList || [""]}
                getOptionLabel={(option) =>
                  `${option?.storeCode}-${option?.branchName}`
                }
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Branch" />
                )}
                onChange={(e, newValue) => setSelectedLoc(newValue?.storeCode)}
              />
        </div>       
        </div>
        <div className="global-table-container">
          <table className="global-table">
            <thead>
              <tr>
                <th>No</th>
                <th>Date & Time</th>
                <th>Name</th>
                <th>Type</th>
                <th>Description</th>
              </tr>
            </thead>
            {
              isLoading ? (
                <tbody>
                <tr>
                     <td colSpan={10}>
                       <Skeleton
                         variant="rectangular"
                         animation="wave"
                         width={"100%"}
                         height={30}
                       />
                     </td>
                   </tr>
                   <tr>
                     <td colSpan={10}>
                       <Skeleton
                         variant="rectangular"
                         animation="wave"
                         width={"100%"}
                         height={30}
                       />
                     </td>
                   </tr>
                   <tr>
                     <td colSpan={10}>
                       <Skeleton
                         variant="rectangular"
                         animation="wave"
                         width={"100%"}
                         height={30}
                       />
                     </td>
                   </tr>
             </tbody>
              ):(
                <tbody>
                {
                 logData?.length !==0 ? logData?.slice(0)?.reverse()?.map((r,i)=>(
                 <tr>
                  <td>{i+1}</td>
                  <td>{`${convertDateFormat(r?.date)} ${r?.date?.split(" ")[1]}`}</td>
                  <td>{r?.employeeName}</td>
                  <td>{r?.type}</td>
                  <td style={{color:'blue'}}>{r?.description}</td>
                </tr>
                  ))
                  :
                  <tr>
                    <td colSpan={5}>No Data</td>
                  </tr>
                }
                
               
              </tbody>
              )
            }
          
          </table>
        </div>

    </div>
  )
}
