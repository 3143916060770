// import { createSlice } from "@reduxjs/toolkit";

// export const allOfferListSlice =createSlice({

//     name:'allOfferListSlice',
//     initialState:{
//         value:undefined
//     },
//     reducers:{
//         update_offer_list:(state,action) => {
//             state.value =action.payload.offerData;
//         },
//     },
// });

// export const {update_offer_list}=allOfferListSlice.actions;
// export default allOfferListSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

export const allOfferListSlice=createSlice({
    name:'allOfferListSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_all_offer_list:(state,action)=>{
            state.value=action.payload.allOfferData
        }
    }
})

export const {update_all_offer_list}=allOfferListSlice.actions
export default allOfferListSlice.reducer