import axios from "axios"
import { HEADERS } from "../../../API/UrlAndPaths"
import store from "../../../Redux/store"
import { get_prod_list_qrBarcode, get_prod_list_qrBarcode_single } from "./GenerateQrBarcodeSlice"

export const newProdListForBarcode=(body)=>{
    axios.post("readymade/viewnewproductlistforbarcode",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_prod_list_qrBarcode({prodListqrBarcodeData:res.data}))
         
        }
    })
    .catch((err)=>{
        store.dispatch(get_prod_list_qrBarcode({prodListqrBarcodeData:undefined}))
    })
}

// product single view
export const newProdListSingleViewForBarcode=(body)=>{
    axios.post("readymade/productsingleviewforbarcode",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_prod_list_qrBarcode_single({prodListqrBarcodeSingleData:res.data}))
         
        }
    })
    .catch((err)=>{
        store.dispatch(get_prod_list_qrBarcode_single({prodListqrBarcodeSingleData:undefined}))
    })
}