import { createSlice } from "@reduxjs/toolkit";

export const readymadeStyleSlice=createSlice({
    name:'readymadeStyleSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_readymade_style_list:(state,action)=>{
            state.value=action.payload.readymadeStyle
        }
    }
})

export const {update_readymade_style_list}=readymadeStyleSlice.actions
export default readymadeStyleSlice.reducer