import React, { useEffect, useState } from 'react'
import '../Settings/posSettings.css'
import { Autocomplete, IconButton, TextField, Tooltip } from '@mui/material'
import { useSelector } from 'react-redux'
import { chartOfAccountListApiCall } from '../../../../../API/AccountUpdated/chartOfAccountAPI'
import { journalListApiCall } from '../../../../../API/AccountUpdated/journalAPI'
import { postPosSettingsAPICall, viewPosSettingsAPICall } from './posSettingsAPI'
import SuccessSnackbar from '../../../../Single Components/SnackBars/SuccessSnackbar'
import ErrorSnackbar from '../../../../Single Components/SnackBars/ErrorSnackbar'
import { filterObjFromList } from '../../../../../Js/generalFunctions'
export const PosSettings = () => {
    const chartOfAccountList = useSelector((state) => state.chartOfAccountsSlice.value)
    const journalList = useSelector((state) => state.journalSlice.value)
    const posSettingsList=useSelector((state)=>state.posSettingSlice.apiResList);
    console.log(posSettingsList)

    const receivableList = chartOfAccountList?.filter(item => item.type === "Receivables");
    const incomeAccList = chartOfAccountList?.filter(item => item.type === "Income");
    const expenseAccList = chartOfAccountList?.filter(item =>item.type === "Cost of revenue");
    const bankAndCashAccList=chartOfAccountList?.filter(item =>item.type === "Bank and cash");
    const currentAssetAccList=chartOfAccountList?.filter(item =>item.type === "Current asset");
    const typeCashAndBankJournalList=journalList?.filter(item=>item.type === "cash" || item.type ==="bank")
    const typesalesInJournalList = journalList?.filter(item=>item.type === 'sales')
   
    console.log(typesalesInJournalList)

    const [snackBarStates,setSnackBarStates]=useState({
      success:false,
      error:false,
      message:"",
  })
   const [validationAlert,setValidationAlert]=useState({})
    const [formValues,setFormValues] = useState({
      posAccounts:false,
      posReceivable:null,
      posIncome:null,
      posDiscount:null,
      posBankCharges:null,
      stitchingIncome:null,
      cashGainOrLoss:null,
      finishedGoods:null,
      deliveryChargeAccount:null,
      posJournal:null,

      pettyCash:false,
      pettyCashJournal:null,

      cashInOrOut:false,
      cashInOrOutAccount:null,

      activeWallet:false,
      walletjournal:null,

      activeLoyalty:false,
      loyaltyJournal:null
    })
    const closeSnackbar=()=>{
      setSnackBarStates({...snackBarStates,success:false,
                              error:false,
                              message:"",})
  }
  const getFormInfo = (key) => (e, newValue) => {
   
    setFormValues({...formValues,[key]:newValue})
}
const checkboxChange=(key)=>(e)=>{
  setFormValues({ ...formValues, [key]: e.target.checked })
}

const payload={
  posStatus:formValues?.posAccounts,
  posAccounts:formValues?.posAccounts===false?null:{
    posReceivableAccount:formValues?.posReceivable?._id,
    posIncomeAccount:formValues?.posIncome?._id,
    posDiscountAccount:formValues?.posDiscount?._id,
    posBankChargesAccount:formValues?.posBankCharges?._id,
    posStitchingIncomeAccount:formValues?.stitchingIncome?._id,
    // cashGainOrLossAccount:formValues?.cashGainOrLoss?._id,
    finishGoodsAccount:formValues?.finishedGoods?._id,
    deliveryChargeAccount:formValues?.deliveryChargeAccount?._id,
    posJournalAccount:formValues?.posJournal?._id


  },
  cashInOutStatus:formValues?.cashInOrOut,
  cashInOut:{
    cashInOutAccount:formValues?.cashInOrOut === true?formValues?.cashInOrOutAccount?._id:null
  },
  pettyCashStatus:formValues?.pettyCash,
  pettyCash:{
    PettycashJournel:formValues?.pettyCash === true?formValues?.pettyCashJournal?._id:null
  },
  activeWalletStatus:formValues?.activeWallet,
  activeWallet:{
    activeWalletJournel:formValues?.activeWallet===true?formValues?.walletjournal?._id:null
  },
  activeLoyaltyStatus:formValues?.activeLoyalty,
  activeLoyalty:{
    activeLoyaltyJournel:formValues?.activeLoyalty===true?formValues?.loyaltyJournal?._id:null
  }
}
const saveBtnClick=()=>{
  let message="Select this field"
  if(formValues?.posAccounts===true && formValues?.posReceivable===null){
    setValidationAlert({...validationAlert,receivableAcc:message})
  }
  else if(formValues?.posAccounts===true && formValues?.posIncome===null){
    setValidationAlert({...validationAlert,posIncome:message})
  }
  else if(formValues?.posAccounts===true && formValues?.posDiscount===null){
    setValidationAlert({...validationAlert,posDiscount:message})
  }
  else if(formValues?.posAccounts===true && formValues?.posBankCharges === null){
    setValidationAlert({...validationAlert,posBankCharges:message})
  }
  else if(formValues?.posAccounts===true && formValues?.stitchingIncome === null){
    setValidationAlert({...validationAlert,stitchingIncome:message})
  }
  // else if(formValues?.posAccounts===true && formValues?.cashGainOrLoss === null){
  //   setValidationAlert({...validationAlert,cashGainOrLoss:message})
  // }
  else if(formValues?.posAccounts===true && formValues?.finishedGoods === null){
    setValidationAlert({...validationAlert,finishedGoods:message})
  }
  else if(formValues?.posAccounts===true && formValues?.deliveryChargeAccount === null){
    setValidationAlert({...validationAlert,deliveryCharge:message})
  }



  else if(formValues?.posAccounts===true && formValues?.posJournal === null){
    setValidationAlert({...validationAlert,posJournal:message})
  }


  

  else if(formValues?.pettyCash===true && formValues?.pettyCashJournal === null){
    setValidationAlert({...validationAlert,pettyCashAccount:message})
  }
  else if(formValues?.cashInOrOut===true && formValues?.cashInOrOutAccount === null){
    setValidationAlert({...validationAlert,cashInOrOutAccount:message})
  }
  else if(formValues?.activeWallet===true && formValues?.walletjournal === null){
    setValidationAlert({...validationAlert,walletjournal:message})
  }
  else if(formValues?.activeLoyalty===true && formValues?.loyaltyJournal === null){
    setValidationAlert({...validationAlert,loyaltyJournal:message})
  }
  else{
    postPosSettingsAPICall(payload,setSnackBarStates,snackBarStates)
  }
 

}
useEffect(()=>{
  posSettingsList !== undefined &&
  setFormValues({
    ...formValues,
    posAccounts:posSettingsList?.posStatus,
    posReceivable:filterObjFromList("_id",receivableList,"posReceivableAccount",posSettingsList?.posAccounts),
    posIncome:filterObjFromList("_id",incomeAccList,"posIncomeAccount",posSettingsList?.posAccounts),
    posDiscount:filterObjFromList("_id",expenseAccList,"posDiscountAccount",posSettingsList?.posAccounts),
    posBankCharges:filterObjFromList("_id",expenseAccList,"posBankChargesAccount",posSettingsList?.posAccounts),
    stitchingIncome:filterObjFromList("_id",incomeAccList,"posStitchingIncomeAccount",posSettingsList?.posAccounts),
    cashGainOrLoss:filterObjFromList("_id",expenseAccList,"cashGainOrLossAccount",posSettingsList?.posAccounts),
    finishedGoods:filterObjFromList("_id",currentAssetAccList,"finishGoodsAccount",posSettingsList?.posAccounts),
    deliveryChargeAccount:filterObjFromList("_id",expenseAccList,"deliveryChargeAccount",posSettingsList?.posAccounts),

    posJournal:filterObjFromList("_id",typesalesInJournalList,"posJournalAccount",posSettingsList?.posAccounts),

    pettyCash:posSettingsList?.pettyCashStatus,
    pettyCashJournal:filterObjFromList("_id",typeCashAndBankJournalList,"PettycashJournel",posSettingsList?.pettyCash),
    cashInOrOut:posSettingsList?.cashInOutStatus,
    cashInOrOutAccount:filterObjFromList("_id",bankAndCashAccList,"cashInOutAccount",posSettingsList?.cashInOut),
    activeWallet:posSettingsList?.activeWalletStatus,
    walletjournal:filterObjFromList("_id",typeCashAndBankJournalList,"activeWalletJournel",posSettingsList?.activeWallet),
    activeLoyalty:posSettingsList?.activeLoyaltyStatus,
    loyaltyJournal:filterObjFromList("_id",typeCashAndBankJournalList,"activeLoyaltyJournel",posSettingsList?.activeLoyalty),

  })
},[posSettingsList,chartOfAccountList,journalList])

    useEffect(() => {
      chartOfAccountListApiCall()
      journalListApiCall()
      viewPosSettingsAPICall()
  }, [])


  return (
    <div className="global-page-parent-container">
         <div className="global-white-bg-container">
                        <>
                            <Tooltip title="Save">
                                <IconButton 
                                onClick={saveBtnClick}
                                >
                                    <i class="bi bi-floppy"></i>
                                </IconButton>
                            </Tooltip>
                        </>
            </div>
       <div   className="new-global-white-bg-container">
        {/* 1 */}
          <div className='checkbox-and-dropdown-container'>
            <div className='pos-settings-checkbox-container'>
              <input 
                type="checkbox" 
                id="checkboxDropDown"
                value={formValues?.posAccounts}
                onChange={checkboxChange("posAccounts")}
                checked={formValues?.posAccounts===true}
                />
              <label htmlFor="checkboxDropDown">POS Accounts</label>
            </div>
           {
            formValues?.posAccounts===true &&
           
            <>
               <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
                 <div className="pos-settings-dropdown-container auto-complete-new">
                 <p>POS Receivable</p>
                        <Autocomplete
                            options={receivableList || []}
                            getOptionLabel={(option) => option?.accountName}
                            renderInput={(params) => (<TextField {...params} />)}
                            value={formValues?.posReceivable}
                            onChange={getFormInfo("posReceivable")}
                           
                        />
                        
                    </div>
                    <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.receivableAcc}</p>
               </div>
        
               <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
                <div className='pos-settings-dropdown-container auto-complete-new'>
                <p>POS Income</p>
                <Autocomplete
                            options={incomeAccList || []}
                            getOptionLabel={(option) => option?.accountName}
                            renderInput={(params) => (<TextField {...params} />)}
                            value={formValues?.posIncome}
                            onChange={getFormInfo("posIncome")}
                          
                        />
             
            </div>
            <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.posIncome}</p>
               </div>
               <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
            <div className='pos-settings-dropdown-container auto-complete-new'>
              <p>POS Discount</p>
               <Autocomplete
                            options={expenseAccList || []}
                            getOptionLabel={(option) => option?.accountName}
                            renderInput={(params) => (<TextField {...params} />)}
                            value={formValues?.posDiscount}
                            onChange={getFormInfo("posDiscount")}
                         
                        />
             
            </div>
            <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.posDiscount}</p>
               </div>
               <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
            <div className='pos-settings-dropdown-container auto-complete-new'>
              <p>POS Bank Charges</p>
               <Autocomplete
                            options={expenseAccList || []}
                            getOptionLabel={(option) => option?.accountName}
                            renderInput={(params) => (<TextField {...params} />)}
                            value={formValues?.posBankCharges}
                            onChange={getFormInfo("posBankCharges")}
                           
                        />
             
            </div>
            <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.posBankCharges}</p>
               </div>
               <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
            <div className='pos-settings-dropdown-container auto-complete-new'>
              <p>Stitching Income</p>
                <Autocomplete
                            options={incomeAccList || []}
                            getOptionLabel={(option) => option?.accountName}
                            renderInput={(params) => (<TextField {...params} />)}
                            value={formValues?.stitchingIncome}
                            onChange={getFormInfo("stitchingIncome")}
                           
                        />
             
            </div>
            <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.stitchingIncome}</p>
               </div>
               {/* <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
            <div className='pos-settings-dropdown-container auto-complete-new'>
              <p>Cash Gain or Loss</p>
               <Autocomplete
                            options={expenseAccList || []}
                            getOptionLabel={(option) => option?.accountName}
                            renderInput={(params) => (<TextField {...params} />)}
                            value={formValues?.cashGainOrLoss}
                            onChange={getFormInfo("cashGainOrLoss")}
                           
                        />
             
            </div>
            <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.cashGainOrLoss}</p>
               </div> */}
               <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
            <div className='pos-settings-dropdown-container auto-complete-new'>
              <p>Finished Goods</p>
               <Autocomplete
                            options={currentAssetAccList || []}
                            getOptionLabel={(option) => option?.accountName}
                            renderInput={(params) => (<TextField {...params} />)}
                            value={formValues?.finishedGoods}
                            onChange={getFormInfo("finishedGoods")}
                            
                        />
             
            </div>
            <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.finishedGoods}</p>
               </div>
               <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
            <div className='pos-settings-dropdown-container auto-complete-new'>
              <p>Delivery Charge</p>
               <Autocomplete
                            options={expenseAccList || []}
                            getOptionLabel={(option) => option?.accountName}
                            renderInput={(params) => (<TextField {...params} />)}
                            value={formValues?.deliveryChargeAccount}
                            onChange={getFormInfo("deliveryChargeAccount")}
                            
                        />
             
            </div>
            <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.deliveryCharge}</p>
               </div>

               <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
            <div className='pos-settings-dropdown-container auto-complete-new'>
              <p>Pos journal</p>
               <Autocomplete
                            options={typesalesInJournalList || []}
                            getOptionLabel={(option) => option?.journalName}
                            renderInput={(params) => (<TextField {...params} />)}
                            value={formValues?.posJournal}
                            onChange={getFormInfo("posJournal")}
                            
                        />
             
            </div>
            <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.posJournal}</p>
               </div>
            </>
            }
          </div>
          {/* 2 */}
          <div className='checkbox-and-dropdown-container'>
            <div className='pos-settings-checkbox-container'>
              <input 
                 type="checkbox" 
                 id="PettyCash" 
                 value={formValues?.pettyCash}
                 onChange={checkboxChange("pettyCash")}
                 checked={formValues?.pettyCash === true}
                 />
              <label htmlFor="PettyCash">Petty Cash</label>
            </div>
            {
              formValues?.pettyCash === true &&
              <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
              <div className='pos-settings-dropdown-container auto-complete-new'>
                <p>Petty Cash Journal</p>
                 <Autocomplete
                              options={typeCashAndBankJournalList || []}
                              getOptionLabel={(option) => option?.journalName}
                              renderInput={(params) => (<TextField {...params} />)}
                              value={formValues?.pettyCashJournal}
                              onChange={getFormInfo("pettyCashJournal")}
                             
                          />
               
              </div>
              <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.pettyCashAccount}</p>
              </div>
            }
           
          
          </div>
          {/* 3 */}
          <div className='checkbox-and-dropdown-container'>
            <div className='pos-settings-checkbox-container'>
              <input 
                type="checkbox" 
                id="CashInOrOut"
                value={formValues?.cashInOrOut}
                onChange={checkboxChange("cashInOrOut")}
                checked={formValues?.cashInOrOut === true}
                />
              <label htmlFor="CashInOrOut">Cash In Or Out</label>
            </div>
            {
              formValues?.cashInOrOut === true &&
             <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
            <div className='pos-settings-dropdown-container auto-complete-new'>
              <p>Cash In Or Out Account</p>
              <Autocomplete
               disablePortal
               id="combo-box-demo"
               options={bankAndCashAccList||[]}
               getOptionLabel={(option) => option?.accountName}
               renderInput={(params) => <TextField {...params} 
              
               />}
              
               value={formValues?.cashInOrOutAccount}
               onChange={getFormInfo("cashInOrOutAccount")}
              
              />
             
            </div>
            <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.cashInOrOutAccount}</p>
            </div>
            }
            
          
          </div>
          {/* 4 */}
          <div className='checkbox-and-dropdown-container'>
            <div className='pos-settings-checkbox-container'>
              <input 
                 type="checkbox" 
                 id="ActiveWallet"
                 value={formValues?.activeWallet}
                 onChange={checkboxChange("activeWallet")}
                 checked={formValues?.activeWallet===true}
                 />
              <label htmlFor="ActiveWallet">Active Wallet</label>
            </div>
            {
              formValues?.activeWallet===true &&
             <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
            <div className='pos-settings-dropdown-container auto-complete-new'>
              <p>Wallet Journal</p>
              <Autocomplete
               disablePortal
               id="combo-box-demo"
               options={typeCashAndBankJournalList||[]}
               getOptionLabel={(option) => option?.journalName}
               renderInput={(params) => <TextField {...params} 
               />}
               value={formValues?.walletjournal}
               onChange={getFormInfo("walletjournal")}
              
              
              />
             
            </div>
            <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.walletjournal}</p>
             </div>
            }
            
         
          
          </div>
          {/* 5 */}
          <div className='checkbox-and-dropdown-container'>
            <div className='pos-settings-checkbox-container'>
              <input 
                type="checkbox" 
                id="ActiveLoyalty"
                value={formValues?.activeLoyalty}
                onChange={checkboxChange("activeLoyalty")}
                checked={formValues?.activeLoyalty===true}
                />
              <label htmlFor="ActiveLoyalty">Active Loyalty</label>
            </div>
            {
              formValues?.activeLoyalty===true &&
              <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
            <div className='pos-settings-dropdown-container auto-complete-new'>
              <p>Loyalty Journal</p>
              <Autocomplete
               disablePortal
               id="combo-box-demo"
               options={typeCashAndBankJournalList||[]}
               getOptionLabel={(option) => option?.journalName}
               renderInput={(params) => <TextField {...params} 
              
               />}
              
               value={formValues?.loyaltyJournal}
               onChange={getFormInfo("loyaltyJournal")}
              
              />
             
            </div>
            <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.loyaltyJournal}</p>
              </div>
            }
            
         
          
          </div>

       </div>

       <SuccessSnackbar open={snackBarStates.success} handleClose={closeSnackbar} message={snackBarStates.message}/>
       <ErrorSnackbar open={snackBarStates.error} handleClose={closeSnackbar} message={snackBarStates.message}/>
    </div>
  )
}

