import { createSlice } from "@reduxjs/toolkit";

export const deliveryIdListSlice=createSlice({
    name:'deliveryIdListSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_delivery_id_list:(state,action)=>{
            state.value=action.payload.deliveryIdList
        }
    }
})
export const {update_delivery_id_list} = deliveryIdListSlice.actions
export default deliveryIdListSlice.reducer