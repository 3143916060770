import { createSlice } from "@reduxjs/toolkit";

export const deliveryOldBalanceSlice=createSlice({
    name:'deliveryOldBalanceSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_delivery_old_balances:(state,action)=>{
            state.value=action.payload.deliveryOldBalances
        }
    }
})
export const {update_delivery_old_balances} = deliveryOldBalanceSlice.actions
export default deliveryOldBalanceSlice.reducer