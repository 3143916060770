import {createSlice} from '@reduxjs/toolkit'

export const viewPettyCashBalanceSlice=createSlice({
    name:'pettyCashBalanceSlice',
    initialState:{
        value:undefined
    },
    reducers:{
      get_petty_cash_balance:(state,action)=>{
          state.value=action.payload.pettyCashBalanceData
      }
    }
})
export const {get_petty_cash_balance} =viewPettyCashBalanceSlice.actions;
export default viewPettyCashBalanceSlice.reducer