import {createSlice} from '@reduxjs/toolkit'

export const viewOrderListSlice=createSlice({
    name:'salesOrderList',
    initialState:{
       value:undefined
    },
    reducers:{
      get_sales_orderList:(state,action)=>{
        state.value=action.payload.salesOrderListData
      }
    }
})
export const {get_sales_orderList} = viewOrderListSlice.actions;
export default viewOrderListSlice.reducer