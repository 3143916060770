import { NavigateNextOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import '../../css/Single Components/quickaccess.css'
const QuickAccess = (props) => {
  return (
    <div
      className="quick-access"
      style={{ backgroundColor: props.backgroundColor }}
    >
      <Link to={props.path} style={{ textDecoration: "none", width: "100%" }}>
        <div className="quick-access-content">
          <Typography
            color={props.labelColor}
            fontWeight="600"
            fontSize="0.8rem"
          >
            {props.label}
          </Typography>
          <NavigateNextOutlined style={{ color: props.labelColor }} />
        </div>
      </Link>
    </div>
  );
};

export default QuickAccess;
