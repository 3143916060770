import { createSlice } from "@reduxjs/toolkit";

export const collarModelSlice=createSlice({
    name:"collarModelSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        update_collar_model:(state,action)=>{
            state.value=action.payload.collarModel
        }
    }
})

export const {update_collar_model}=collarModelSlice.actions
export default collarModelSlice.reducer