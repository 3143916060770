import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import {
  Stack,
  Chip,
  Avatar,
  Select,
  MenuItem,
  Tooltip,
  Dialog,
  TextField,
  Autocomplete,
} from "@mui/material";
import { SideMenu } from "./SideMenu";
import "../../css/Dashboard/dashboard.css";
import { HomeDashboard } from "../Modules/HomeDashboard/Homedashboard";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { ProductList } from "../Modules/Product List/Pages/ProductList";
import { ProductMaster } from "../Modules/ProductMaster/Pages/ProductMaster";
import { PurchaseOrder } from "../Modules/Purchase Manager/Pages/PurchaseOrder";
import { logOutUser } from "../../Js/generalFunctions";
import { useDispatch, useSelector } from "react-redux";
import { AddPurchaseOrder } from "../Modules/Purchase Manager/Pages/AddPurchaseOrder";
import { GrnDraft } from "../Modules/Purchase Manager/Pages/GRN/GrnDraft";
import { PostedGrn } from "../Modules/Purchase Manager/Pages/GRN/PostedGrn";

import { PurchaseWoPo } from "../Modules/Purchase Manager/Pages/PurchaseWoPo";
import { SupplierManager } from "../Modules/Purchase Manager/Pages/SupplierManager";
import { SupplierLedger } from "../Modules/Purchase Manager/Pages/SupplierLedger";
import { PendingPaymentVoucher } from "../Modules/Purchase Manager/Pages/PendingPaymentVoucher";
import { PaymentVoucher } from "../Modules/Purchase Manager/Pages/PaymentVoucher";
import { StockoutProducts } from "../Modules/Purchase Manager/Pages/StockoutProducts";
import { ApproveStockOutProducts } from "../Modules/Purchase Manager/Pages/ApproveStockOutProducts";
import { PurchaseReturn } from "../Modules/Purchase Manager/Pages/PurchaseReturn";
import { StockAdjustmentList } from "../Modules/Purchase Manager/Pages/StockAdjustment/StockAdjustmentList";
import { Draft } from "../Modules/Purchase Manager/Pages/StockAdjustment/Draft";
import { AddStockAdjustment } from "../Modules/Purchase Manager/Pages/StockAdjustment/AddStockAdjustment";
import { ReceivedStock } from "../Modules/Purchase Manager/Pages/StockAdjustment/ReceivedStock";
import { ConfirmStock } from "../Modules/Purchase Manager/Pages/StockAdjustment/ConfirmStock";
import { Transfer } from "../Modules/Purchase Manager/Pages/Transfer/Transfer";
import { RecivedTransfer } from "../Modules/Purchase Manager/Pages/Transfer/RecivedTransfer";
import { GoodsReceivedNotes } from "../Modules/Purchase Manager/Pages/GRN/GoodsReceivedNotes";
import { ViewOrder } from "../Modules/Orders/ViewOrder/ViewOrder";
import { Settings } from "../Modules/Settings/Settings";
// import {WorkOrder, WorkOrderUser} from '../Modules/Orders/WorkOrder/WorkOrderUser'
import { WorkOrder } from "../Modules/Orders/WorkOrder/WorkOrder";
import { JobCompletion } from "../Modules/Orders/JobCompletion/JobCompletion";
import { PrintCuttingSlip } from "../Modules/Orders/PrintCuttingSlip/PrintCuttingSlip";
import { Department } from "../Modules/Staff/HRM/Department";
import { Designation } from "../Modules/Staff/HRM/Designation";
import { AddEmployee } from "../Modules/Staff/HRM/AddEmployee/AddEmployee";
import { ManageEmployee } from "../Modules/Staff/HRM/ManageEmployee/ManageEmployee";
import { ViewEmployee } from "../Modules/Staff/HRM/ManageEmployee/ViewEmployee";
import { AddDocument } from "../Modules/Staff/HRM/AddDocument";
import { Alteration } from "../Modules/Orders/Alteration/Alteration";
import { Delivery } from "../Modules/Orders/Delivery/Delivery";
import { Billing } from "../Modules/Sales/Billing/Billing";
import { Credit } from "../Modules/Sales/Credit";
import { Wallet } from "../Modules/Sales/Wallet";
import { DailyReport } from "../Modules/Report/DailyReport/DailyReport";
import { DailyCashCardSummary } from "../Modules/Report/DailyCashCardSummary/DailyCashCardSummary";
import { PurchaseReport } from "../Modules/Report/PurchaseReport/PurchaseReport";
import { StockReport } from "../Modules/Report/StockReport/StockReport";
import { SalesReport } from "../Modules/Report/SalesReport/SalesReport";
import { TransferReport } from "../Modules/Report/TransferReport/TransferReport";
import { StockAdjustmentReport } from "../Modules/Report/StockAdjustmentReport/StockAdjustmentReport";
import { ExpenseReport } from "../Modules/Report/ExpenseReport/ExpenseReport";
import { PaymentReport } from "../Modules/Report/PaymentReport/PaymentReport";
import { GRNReport } from "../Modules/Report/GRNReport/GRNReport";
import { PurchaseWOReport } from "../Modules/Report/PurchaseWOReport/PurchaseWOReport";
import { CommissionReport } from "../Modules/Report/CommissionReport/CommissionReport";
import { QuickBillReport } from "../Modules/Report/QuickBillReport/QuickBillReport";
import { StockSummaryReport } from "../Modules/Report/StockSummaryReport/StockSummaryReport";
import { getCurrentBranchCode } from "../../API/tokens";
import { useEffect } from "react";
import { OrderList } from "../Modules/Sales/OrderList";
import { Payments } from "../Modules/Sales/Payments";
import { QuotationOrEstimate } from "../Modules/Sales/QuotationOrEstimate";
import { SalesReturn } from "../Modules/Sales/SalesReturn";
import { OldStock } from "../Modules/Sales/OldStock";
import { DamagedGoods } from "../Modules/Sales/DamagedGoods";
import { QuickBill } from "../Modules/Sales/QuickBill";
import { AddCustomer } from "../Modules/Customer/AddCustomer";
import { AllCustomer } from "../Modules/Customer/AllCustomer";
import { SingleCustomer } from "../Modules/Customer/SingleCustomer";
import { StaffExpense } from "../Modules/Expense/StaffExpense";
import { OutletExpense } from "../Modules/Expense/OutletExpense";
import { PettyCashReceipt } from "../Modules/Expense/PettyCashReceipt";
import { AddExpenseType } from "../Modules/Expense/AddExpenseType";
import { useState, useRef } from "react";
import { GenerateQrOrBarcode } from "../Modules/GenerateQrOrBarcode/GenerateQrOrBarcode";
import {
  startManualShiftAPICall,
  endManualShiftAPICall,
  addShiftDenominationAPICall,
} from "../../API/Settings/Shift/manualShiftAPI";
import {
  currentTime,
  getCurrentTime,
  newCurrentTime,
  today,
} from "../../Js/Date";
import ErrorSnackbar from "../Single Components/SnackBars/ErrorSnackbar";
import { useCallback } from "react";
import {
  verifyPasswordAPICall,
  verifyPasswordForShiftAPICall,
} from "../../API/Login/VerifyPasswordAPI";
import { getShiftStatusAPICAll } from "../../API/Settings/Shift/viewShiftAPI";
import { ConfirmTransfer } from "../Modules/Purchase Manager/Pages/Transfer/ConfirmTransfer";
import { StaffDashboard } from "../Modules/Staff/DashBoard/StaffDashboard";
import { StaffAttendance } from "../Modules/Staff/Attendance/StaffAttendance";
import { StaffLeaveApplication } from "../Modules/Staff/Leave/StaffLeaveApplication";
import { StaffHoliday } from "../Modules/Staff/Leave/StaffHoliday";
import { StaffAddLeaveType } from "../Modules/Staff/Leave/StaffAddLeaveType";
import { StaffPayrollEmpSalary } from "../Modules/Staff/Payroll/StaffPayrollEmpSalary";
import { StaffPayrollAddPayroll } from "../Modules/Staff/Payroll/StaffPayrollAddPayroll";
import { StaffPayrollItem } from "../Modules/Staff/Payroll/StaffPayrollItem";
import { EmpDashboardTotalCommission } from "../Modules/Staff/HRM/ManageEmployee/Tabs/EmpDashboardTotalCommission";
import { StaffAddNewLoan } from "../Modules/Staff/Loan/StaffAddNewLoan";
import { StaffLoanReport } from "../Modules/Staff/Loan/StaffLoanReport";
import { StaffLoanApprovals } from "../Modules/Staff/Loan/StaffLoanApprovals";
import { RewardsView } from "../Modules/RewardsAndPoints/RewardsView";
import { AddRewards } from "../Modules/RewardsAndPoints/AddRewards";
import { OffersListed } from "../Modules/Offers/OffersListed";
import { AddOffer } from "../Modules/Offers/AddOffer";
import { DocumentPrintView } from "../Modules/Staff/HRM/DocumentPrintView";
import { StaffPaySlip } from "../Modules/Staff/Payroll/StaffPaySlip";
import { JobCompletionReport } from "../Modules/Report/JobCompletionReport/JobCompletionReport";
import { DeviceSettings } from "../Modules/Settings/Device Settings/DeviceSettings";
import CreateLedger from "../Modules/Accounts/CreateLedger";
import Accounting from "../Modules/Accounts/createInvoice/accounting/Accounting";
import Inventory from "../Modules/Accounts/createInvoice/Inventory/Inventory";
import PayRoll from "../Modules/Accounts/createInvoice/payroll/PayRoll";
import RecurringPost from "../Modules/Accounts/createInvoice/recurringPost/RecurringPost";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import BankReports from "../Modules/Accounts/Reports/bankReports/BankReports";
import FinancialReports from "../Modules/Accounts/Reports/financialReports/FinancialReports";
import InventoryReports from "../Modules/Accounts/Reports/inventoryReports/InventoryReports";
import PayrollReports from "../Modules/Accounts/Reports/payrollReports/PayrollReports";
import CashReconciliation from "../Modules/Accounts/AccountReconciliation/CashReconciliation";
import BankReconciliation from "../Modules/Accounts/AccountReconciliation/BankReconciliation";
import SuppliersReconciliation from "../Modules/Accounts/AccountReconciliation/SuppliersReconciliation";
import CustomersReconciliation from "../Modules/Accounts/AccountReconciliation/CustomersReconciliation";
import ReconciliationReports from "../Modules/Accounts/AccountReconciliation/ReconciliationReports";
import GstReports from "../Modules/Accounts/Reports/gstReports/GstReports";
import { Journal } from "../Modules/Accounts/Accounting/Journal";
import { JournalEntries } from "../Modules/Accounts/Accounting/JournalEntries";
import { ReccurringPosting } from "../Modules/Accounts/Accounting/ReccurringPosting";
import { ChartOfAccounts } from "../Modules/Accounts/Accounting/ChartOfAccounts";

import { JournalEntriesForm } from "../Modules/Accounts/Accounting/JournalEntriesForm";

import { CreateJournal } from "../Modules/Accounts/Accounting/CreateJournal";
import { Configuration } from "../Modules/Accounts/Configuration/Configuration";
import { CreateChartOfAccount } from "../Modules/Accounts/Accounting/CreateChartofAccount";
import { UnitOfMeasurment } from "../Modules/Purchase Manager/Pages/UnitOfMeasurment/UnitOfMeasurment";
import { NewUOM } from "../Modules/Purchase Manager/Pages/UnitOfMeasurment/NewUOM";
import { ConfigurationPurchase } from "../Modules/Purchase Manager/Pages/Configuration/ConfigurationPurchase";
import { VendorBills } from "../Modules/Accounts/Vendor/pages/VendorBills";
import { CreateVendorBills } from "../Modules/Accounts/Vendor/pages/CreateVendorBills";
import { CreateVendorBills1 } from "../Modules/Accounts/Vendor/pages/CreateVendorBills1";
import { VendorPayments } from "../Modules/Accounts/Vendor/pages/VendorPayments";
import { DebitNotes } from "../Modules/Accounts/Vendor/pages/DebitNotes";
import { Vendors } from "../Modules/Accounts/Vendor/pages/Vendors";

import { CreateVendorPayments } from "../Modules/Accounts/Vendor/pages/CreateVendorPayments";
import { CreateVendors } from "../Modules/Accounts/Vendor/pages/CreateVendors";

import { WareHouse } from "../Modules/Inventory/Configuration/WareHouse";
import { InventoryLocation } from "../Modules/Inventory/Configuration/InventoryLocation";
import { CreateWareHouse } from "../Modules/Inventory/Configuration/CreateWareHouse";
import { CreateInvLocation } from "../Modules/Inventory/Configuration/CreateInvLocation";

import { InternalTransfer } from "../Modules/Inventory/Operations/InternalTransfer/InternalTransfer";
import { BranchTransfer } from "../Modules/Inventory/Operations/BranchTransfer/BranchTransfer";

import { InventoryAdjustment } from "../Modules/Inventory/Operations/InventoryAdjustment/InventoryAdjustment";
import { LandedCost } from "../Modules/Inventory/Operations/LandedCost/LandedCost";

import { StockMoves } from "../Modules/Inventory/Operations/Stock Moves/StockMoves";
import { CreateInternalTransfer } from "../Modules/Inventory/Operations/InternalTransfer/CreateInternalTransfer";
import { CreateBranchTransfer } from "../Modules/Inventory/Operations/BranchTransfer/CreateBranchTransfer";
import { RequestForQuatation } from "../Modules/Purchase Manager/Pages/RequestForQuatation";
import { NewPurchaseOrder } from "../Modules/Purchase Manager/Pages/NewPurchaseOrder";
import { CreateRequestForQuatation } from "../Modules/Purchase Manager/Pages/CreateRequestForQuatation";
import { CreateNewPurchaseOrder } from "../Modules/Purchase Manager/Pages/CreateNewPurchaseOrder";
import { NewGoodsReceivedNotes } from "../Modules/Purchase Manager/Pages/NewGoodsReceivedNotes";
import { NewGoodsReceivedNotes1 } from "../Modules/Purchase Manager/Pages/NewGoodsReceivedNotes1";

import { GrnListing } from "../Modules/Purchase Manager/Pages/GRNUpdated/GrnListing";
import GoodsReceivedNotesSingleView from "../Modules/Purchase Manager/Pages/GRNUpdated/GoodsReceivedNotesSingleView";
import { VendorListPurchaseManage } from "../Modules/Purchase Manager/Pages/Vendor/VendorListPurchaseManage";
import { CreateVendorListPurchaseManage } from "../Modules/Purchase Manager/Pages/Vendor/CreateVendorListPurchaseManage";
import { SalesOrder } from "../Modules/SalesNew/SalesOrders/SalesOrder/SalesOrder";
import { SalesQuotation } from "../Modules/SalesNew/SalesOrders/SalesQuotation/SalesQuotation";
import { CreateSalesQuotation } from "../Modules/SalesNew/SalesOrders/SalesQuotation/CreateSalesQuotation";
import { CreateSalesOrder } from "../Modules/SalesNew/SalesOrders/SalesOrder/CreateSalesOrder";
import { DeliveryNote } from "../Modules/SalesNew/SalesOrders/DeliveryNote/DeliveryNote";
import { CreateDeliiveryNote } from "../Modules/SalesNew/SalesOrders/DeliveryNote/CreateDeliiveryNote";
import { CustomerInvoice } from "../Modules/Accounts/Customers/CustomerInvoice/CustomerInvoice";
import { CustomerPayments } from "../Modules/Accounts/Customers/Payments/CustomerPayments";
import { CustomerCreditNotes } from "../Modules/Accounts/Customers/CreditNotes/CustomerCreditNotes";
import { Customers } from "../Modules/Accounts/Customers/Customers/Customers";
import { CreateCustomerInvoice } from "../Modules/Accounts/Customers/CustomerInvoice/CreateCustomerInvoice";
import { CreateCustomerPayment } from "../Modules/Accounts/Customers/Payments/CreateCustomerPayment";
import { CreateCustomerCreditNotes } from "../Modules/Accounts/Customers/CreditNotes/CreateCustomerCreditNotes";
import { CreateDebitNote } from "../Modules/Accounts/Vendor/pages/CreateDebitNote";
import { CreateCustomer } from "../Modules/Accounts/Customers/Customers/CreateCustomer";
import { SettingsProductVariant } from "../Modules/Inventory/Configuration/SettingsProductVariant";
import { Attribute } from "../Modules/Inventory/Configuration/Attribute/Attribute";
import { CreateAttribute } from "../Modules/Inventory/Configuration/Attribute/CreateAttribute";
import { POSCategory } from "../Modules/Inventory/Configuration/POSCategory/POSCategory";
import { CreatePOSCategory } from "../Modules/Inventory/Configuration/POSCategory/CreatePOSCategory";
import { Category } from "../Modules/Inventory/Configuration/Category/Category";
import { CreateCategory } from "../Modules/Inventory/Configuration/Category/CreateCategory";
import { CreateInventoryAdjustment } from "../Modules/Inventory/Operations/InventoryAdjustment/CreateInventoryAdjustment";
import { SalesWSO } from "../Modules/Accounts/Customers/SalesWSO/SalesWSO";
import { CreateSalesWSO } from "../Modules/Accounts/Customers/SalesWSO/CreateSalesWSO";
import { BranchReceipt } from "../Modules/Inventory/Operations/BranchReceipt/BranchReceipt";
import { CreateBranchReceipt } from "../Modules/Inventory/Operations/BranchReceipt/CreateBranchReceipt";
import { ProductListUpdated } from "../Modules/Inventory/Products/ProductListUpdated/ProductListUpdated";
import { CreateProductList } from "../Modules/Inventory/Products/ProductListUpdated/CreateProductList";
import { ProductMasterUpdated } from "../Modules/Inventory/Products/ProductMasterUpdated/ProductMasterUpdated";
import { CreateProductMaster } from "../Modules/Inventory/Products/ProductMasterUpdated/CreateProductMaster";
import { CreateRequestForQuotation1 } from "../Modules/Purchase Manager/Pages/CreateRequestForQuotation1";
import { CreateNewPurchaseOrder1 } from "../Modules/Purchase Manager/Pages/CreateNewPurchaseOrder1";
import { GrnListing1 } from "../Modules/Purchase Manager/Pages/GRNUpdated/GrnListing1";

import { VendorBills1 } from "../Modules/Accounts/Vendor/pages/VendorBills1";
import { PurchaseWOPO1 } from "../Modules/Accounts/Vendor/purchaseWPO/PurchaseWOPO1";

import { BillingNew } from "../Modules/Sales/Billing/BillingNew";
import { CreatePurchaseWOPO1 } from "../Modules/Accounts/Vendor/purchaseWPO/CreatePurchaseWOPO1";
// import { ViewOrdersUserNew } from "../Modules/Orders/ViewOrder/ViewOrdersUserNew";
import CreateLandedCost from "../Modules/Inventory/Operations/LandedCost/CreateLandedCost";

import { RequestForQuatationSingleView } from "../Modules/Purchase Manager/Pages/RequestForQuatationSingleView";

import { PosSettings } from "../Modules/PointOfSale/Configuration/Settings/PosSettings";
import { BranchSettings } from "../Modules/PointOfSale/Configuration/BranchSettings/BranchSettings";
import { BranchSettingsForm } from "../Modules/PointOfSale/Configuration/BranchSettings/BranchSettingsForm";
import { SalesQuatationSingleVieww } from "../Modules/SalesNew/SalesOrders/SalesQuotation/SalesQuatationSingleVieww";
import { DeliverNoteSingleView } from "../Modules/SalesNew/SalesOrders/DeliveryNote/DeliverNoteSingleView";
import { SalesCustomers } from "../Modules/SalesNew/SalesOrders/SalesCustomers/SalesCustomers";

import { viewPosSettingsAPICall } from "../Modules/PointOfSale/Configuration/Settings/posSettingsAPI";
import { getAllActiveEmployeesAPICall } from "../../API/Staff/HRM/employeeAPI";

import { SalesWSOsingleView } from "../Modules/Accounts/Customers/SalesWSO/SalesWSOsingleView";
import { DebitNoteSingleView } from "../Modules/Accounts/Vendor/pages/DebitNoteSingleView";
import { VendorBillSingleView } from "../Modules/Accounts/Vendor/pages/VendorBillSingleView";
import { PurchaseWOPOsingleView } from "../Modules/Accounts/Vendor/purchaseWPO/PurchaseWOPOsingleView";
import CreatePriceList from "../Modules/SalesNew/SalesOrders/priceList/CreatePriceList";
import PriceList from "../Modules/SalesNew/SalesOrders/priceList/PriceList";
import { SalesOrderSingleView } from "../Modules/SalesNew/SalesOrders/SalesOrder/SalesOrderSingleView";
import { PurchaseOrderSingleView } from "../Modules/Purchase Manager/Pages/PurchaseOrderSingleView";
import { CreaditNoteSingleView } from "../Modules/Accounts/Customers/CreditNotes/CreaditNoteSingleView";
import { CustomerInvoiceSingleView } from "../Modules/Accounts/Customers/CustomerInvoice/CustomerInvoiceSingleView";

import {
  addCashInOrOutAPICall,
  viewCashInOrCashOutAPICall,
} from "../../API/Dashboards/mainDashboardAPI";
import SuccessSnackbar from "../Single Components/SnackBars/SuccessSnackbar";
import listIcon from "../../Assets/Images/Icon feather-list.png";

import TrailBalance from "../Modules/Accounts/Reports/financialReports/financialReportsGroup/TrailBalance";
import { TrialBalance } from "../Modules/Accounts/ReportsNew/FinancialReports/TrialBalance";

import { viewGlConfigAPICall } from "../Modules/Accounts/Configuration/glConfigAPI";
import { viewAllCurrencyUpdated } from "../../API/Settings/Currency/CurrencyAPI";
import ReactToPrint from "react-to-print";

import { LedgerBalance } from "../Modules/Accounts/ReportsNew/FinancialReports/LedgerBalance";
//import { GernalLedger } from "../Modules/Accounts/ReportsNew/FinancialReports/GernalLedger";
//import { GeneralLedger } from "../Modules/Accounts/ReportsNew/FinancialReports/GernalLedger";
import { GeneralLedgerNw } from "../Modules/Accounts/ReportsNew/FinancialReports/GeneralLedgerNw";
import { ProfitAndLoss } from "../Modules/Accounts/ReportsNew/FinancialReports/ProfitAndLoss";
import { SalesNewReport } from "../Modules/SalesNew/SalesReport/SalesNewReport";
import { AccountPayable } from "../Modules/Accounts/ReportsNew/PartnerReport/AccountPayable";
import AccountPayableSingleView from "../Modules/Accounts/ReportsNew/PartnerReport/AccountPayableSingleView";
import AccountReceivableAgingReport from "../Modules/Accounts/ReportsNew/PartnerReport/AccountReceivableAgingReport";
import { AccountRecievable } from "../Modules/Accounts/ReportsNew/PartnerReport/AccountRecievable";
import { BankAndCashReport } from "../Modules/Accounts/ReportsNew/General Report/BankAndCashReport";
import BankAndCashReportSingleView from "../Modules/Accounts/ReportsNew/General Report/BankAndCashReportSingleView";
import { AccountRecievableSingleView } from "../Modules/Accounts/ReportsNew/PartnerReport/AccountRecievableSingleView";
import { SalesReportSingleView } from "../Modules/SalesNew/SalesReport/SalesReportSingleView";
import BalanceSheet from "../Modules/Accounts/ReportsNew/FinancialReports/BalanceSheet";
import { StockMove } from "../Modules/Inventory/Reports/StockMove";
import { StockMoveSingleView } from "../Modules/Inventory/Reports/StockMoveSingleView";
import PurchaseReportNew from "../Modules/Purchase Manager/Pages/PurchaseReport.jsx/PurchaseReportNew";
import { PurchaseReportSingleView } from "../Modules/Purchase Manager/Pages/PurchaseReport.jsx/PurchaseReportSingleView";

import { BankReconciliationNew } from "../Modules/Accounts/Reconciliation/BankReconciliationNew";
import { CreateBankReconciliation } from "../Modules/Accounts/Reconciliation/CreateBankReconciliation";

import { PosSalesReport } from "../Modules/Report/POSSalesReport/PosSalesReport";
import EstablisId from "../Modules/Payroll/Establishment/EstablisId";
import InputOrOutputReport from "../Modules/Accounts/VatReport/inputOrOutputReport/InputOrOutputReport";

import { getAllNewProductList } from "../Modules/Accounts/Vendor/API/vendorBillAPI";
import { vendorListApi } from "../Modules/Accounts/Vendor/API/vendorListAPICall";
import { customerListApi } from "../../API/Customer/customerAPI";

import OvertimeList from "../Modules/Payroll/Attendance/Overtime/OvertimeList";
import AccountReceivableAgingReportSingleView from "../Modules/Accounts/ReportsNew/PartnerReport/AccountReceivableAgingReportSingleView";
import AccountPayableAgingReport from "../Modules/Accounts/ReportsNew/PartnerReport/AccountPayableAgingReport";
import AccountPayableAgingReportSingleView from "../Modules/Accounts/ReportsNew/PartnerReport/AccountPayableAgingReportSingleView";
import PayHead from "../Modules/Payroll/configuration/payHead/PayHead";
import { QrcodeScanView } from "../Modules/Sales/Billing/QrcodeScanView";
import { ProductListSingleView } from "../Modules/Product List/Pages/ProductListSingleView";
import { viewAllMaterialProductsWithStockAPICall } from "../../API/Product List/productListAPI";
import { paymentTypesForBilling } from "../Modules/Sales/Billing/newBillingAPI";
import InvoiceMarginReport from "../Modules/Accounts/ReportsNew/General Report/InvoiceMarginReport";
import InvoiceMarginReportSingleView from "../Modules/Accounts/ReportsNew/General Report/InvoiceMarginReportSingleView";
import ProductMarginReport from "../Modules/Accounts/ReportsNew/General Report/ProductMarginReport";
import ProductMarginReportSingleView from "../Modules/Accounts/ReportsNew/General Report/ProductMarginReportSingleView";
import StockAgingReport from "../Modules/Inventory/Reports/StockAgingReport";
import CustomerReceiptReport from "../Modules/Accounts/ReportsNew/General Report/CustomerReceiptReport";
import CustomerReceiptReportSingleView from "../Modules/Accounts/ReportsNew/General Report/CustomerReceiptReportSingleView";
import SalesReportBySalesPerson from "../Modules/SalesNew/SalesReport/SalesReportBySalesPerson";
import SalesReportBySalesSingleView from "../Modules/SalesNew/SalesReport/SalesReportBySalesSingleView";
import CustomerCreditReport from "../Modules/Report/POSSalesReport/CustomerCreditReport";
import CustomerCreditReportSingleView1 from "../Modules/Report/POSSalesReport/CustomerCreditReportSingleView1";
import CustomerCreditReportSingleView2 from "../Modules/Report/POSSalesReport/CustomerCreditReportSingleView2";
import ReceivableDueReport from "../Modules/Accounts/ReportsNew/PartnerReport/ReceivableDueReport";
import PyableDueReport from "../Modules/Accounts/ReportsNew/PartnerReport/PyableDueReport";
import ReceivableDueReportSingleView from "../Modules/Accounts/ReportsNew/PartnerReport/ReceivableDueReportSingleView";
import PayableDueReportSingleView from "../Modules/Accounts/ReportsNew/PartnerReport/PayableDueReportSingleView";
import CreateOpeningBalance from "../Modules/Accounts/Accounting/CreateOpeningBalance";
import OpeningBalance from "../Modules/Accounts/Accounting/OpeningBalance";

import ShiftReport from "../Modules/Report/shiftReport/ShiftReport";
import ShiftReportSingleView from "../Modules/Report/shiftReport/ShiftReportSingleView";
import DailySalesReport from "../Modules/SalesNew/SalesReport/DailySalesReport";
import DailySalesReportSingleView from "../Modules/SalesNew/SalesReport/DailySalesReportSingleView";
import Contract from "../Modules/Payroll/Contract";
import PayOther from "../Modules/Payroll/configuration/payOther/PayOther";
import Sponser from "../Modules/Payroll/Sponser";
import { LoadingForm } from "../Single Components/LoadingForm";
import { setErrorSnack, setSnackMsg, setSuccessSnack } from "../../Redux/Dashboard/mainDashBoardSlice";




//import { GrnPurchaseList } from "../Modules/Purchase Manager/Pages/GRNUpdated/GrnPurchaseList";

// import {createDeliveryNote} from "../Modules/SalesNew/SalesOrders/DeliveryNote/CreateDeliveryNote"

const drawerWidth = 290;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

function DashboardContent() {

  const dispatch=useDispatch()
  //Login Response From Redux
  const userInfo = useSelector((state) => state.loginResponseSlice.value);
  // login response
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  // pos settings

  const posSettingsList = useSelector(
    (state) => state.posSettingSlice.apiResList
  );

  //Active Employee List
  const allActiveEmpList = useSelector(
    (state) => state.allActiveEmployeeSlice.value
  );

  const { submitLoading,successSnack,errorSnack,snackMsg } = useSelector((state) => state.mainDashBoardSlice);

  //  cash in or out view
  const cashInOrOutData = useSelector((state) => state.cashInOrOutSlice.value);
  const [branchName, setBranchName] = useState("...");
  const userRole = useSelector((state) => state.userRoleSlice.value);
  const userLoginMeta = localStorage.getItem("login_meta");
  const sideMenuRefresh = localStorage.getItem("refreshSideMenu");
  const userBranchId = JSON.parse(userLoginMeta)?.branchId;
  const userBranchpk = JSON.parse(userLoginMeta)?.branchPk;
  const userShiftStatus = localStorage.getItem("shiftStatus");
  const userShiftId = JSON.parse(userShiftStatus)?.shiftId;
  const userShiftpk = JSON.parse(userShiftStatus)?._id;
  const storeCode = localStorage.getItem("branchId");
  const [open, setOpen] = React.useState(true);

  // let cashInOutPrint = useRef()

  // shift button change state
  const [shiftBtnState, setShiftBtnState] = useState(false);
  // shift end password dialog state
  const [shiftEndPasswordDialog, setShiftEndPasswordDialog] = useState(false);

  const [timer, setTimer] = useState(0);
  const [currentTimer, setCurrentTimer] = useState();
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [verifyPassWord, setVerifyPassword] = useState("");
  //Is Password verified
  const [isVerified, setIsVerified] = useState(false);

  // denomination dialog
  const [denominationDialog, setDenominationDialog] = useState(false);

  const closeSnackbar = () => {
    dispatch(setErrorSnack(false));
    dispatch(setSuccessSnack(false));

    setTimeout(() => {
      dispatch(setSnackMsg(""));
    }, 1000);
  };
  const increment = useRef(null);
  let date = new Date();
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [shiftDenominationTable, setShiftDenominationTable] = useState([]);
  const [denominationGrandTotal, setShiftDenominationGrandTotal] = useState("");
  const shiftDenominationBodyInitialState = {
    currency: "Rs",
    amount: "",
    count: "",
    total: "0.00",
    grandTotal: "",
  };

  const [shiftDenominationBody, setShiftDenominationBody] = useState(
    shiftDenominationBodyInitialState
  );

  const [cashInOrOutDialog, setCashInOrOutDialog] = useState(false);
  const [cashInOrOutState, setCashInOrOutState] = useState("cashIn");
  const [cashInOrOutForm, setCashInOrOutForm] = useState({
    refernce: "",
    amount: 0,
    employee: null,
  });
  const [isDisplayList, setIsDisplayList] = useState(false);

  const shiftDenominationGetForm = (key) => (e) => {
    const { value } = e.target;
    if (key === "currency") {
      setShiftDenominationBody({ ...shiftDenominationBody, currency: value });
    }
    if (key === "amount") {
      setShiftDenominationBody({
        ...shiftDenominationBody,
        amount: value,
        total: shiftDenominationBody.count * value,
      });
    }
    if (key === "count") {
      setShiftDenominationBody({
        ...shiftDenominationBody,
        count: value,
        total: shiftDenominationBody.amount * value,
      });
    }
  };

  useEffect(() => {
    if (shiftDenominationTable.length !== 0) {
      let totalAmtArray = [];
      totalAmtArray = shiftDenominationTable.map((obj) => obj?.total);
      let grandTotal = 0;
      grandTotal = totalAmtArray.reduce((a, b) => a + b, 0);
      setShiftDenominationGrandTotal(grandTotal);
    }
  });

  //set Login response to corresponding states
  useEffect(() => {
    if (userInfo !== undefined) {
      if (userInfo !== null) {
        const { branchName } = userInfo;
        setBranchName(branchName);
      }
    }
  }, []);
  const shiftStatus = useSelector((state) => state.shiftStatusSlice.value);
  useEffect(() => {
    if (userRole === "user") {
      let branchId = localStorage.getItem("branchId");
      getShiftStatusAPICAll({
        branchId,
        curTime: `${today} ${getCurrentTime()}`,
      });
    }
  }, []);
  //  console.log(shiftStatus);
  useEffect(() => {
    if (userRole === "user") {
      // let shiftStatus = null;
      let shiftBtnState = null;
      let timerLS = null;
      // shiftStatus = localStorage.getItem("shiftStatus");
      // shiftBtnState = localStorage.getItem("shitBtnState");
      // timerLS = localStorage.getItem("timer");
      if (shiftStatus !== null) {
        shiftBtnState = shiftStatus?.status === "Active" ? true : false;
        timerLS = shiftStatus?.timer;
        setShiftBtnState(shiftBtnState);
        setTimer(timerLS || 0);
        // console.log(timerLS);
      }

      console.log("refresh state rendered");
      console.count("refresh state render ");
    }
  }, [shiftStatus]);
  //Call resume or pause on change of shift status
  useEffect(() => {
    if (JSON.parse(shiftBtnState)) {
      if (timer !== 0 && timer !== null) handleResume();
    } else {
      handlePause();
      // localStorage.setItem("timer", JSON.stringify(0));
    }
  }, [shiftBtnState]);

  // console.log(userRole);

  const formatTime = () => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);
    return `${getHours} : ${getMinutes} : ${getSeconds}`;
  };
  const handleStart = () => {
    // setIsActive(true);
    setIsPaused(true);
    increment.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
  };

  const handlePause = () => {
    clearInterval(increment.current);
    setIsPaused(false);
    // localStorage.removeItem('timer')
    // localStorage.setItem('timer',0)
  };
  const handleReset = () => {
    clearInterval(increment.current);
    // setIsActive(false);
    setIsPaused(false);
    setTimer(0);
  };

  const handleResume = () => {
    setIsPaused(true);
    increment.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
  };

  // shift button change function
  const shiftStartBtnFn = () => {
    startManualShiftAPICall(
      {
        branchId: storeCode,
        startDate: `${date.getFullYear()}-${String(
          date.getMonth() + 1
        ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")} ${String(
          date.getHours()
        ).padStart(2, "0")}:${String(date.getMinutes()).padStart(
          2,
          "0"
        )}:${String(date.getSeconds()).padStart(2, "0")}`,
      },
      setShiftBtnState,
      shiftBtnState,
      handleReset,
      handleStart
    );
    // handleReset();
    // handleStart();
    // setShiftBtnState(!shiftBtnState);
    // localStorage.setItem("shitBtnState", JSON.stringify(!shiftBtnState));
  };

  const shiftEndBtnClick = () => {
    // setShiftBtnState(!shiftBtnState)
    if (shiftStatus !== null) {
      shiftStatus.isDenomination
        ? setDenominationDialog(true)
        : setShiftEndPasswordDialog(true);
    }
  };
  const shiftEndPasswordSubmitBtnClick = () => {
    endManualShiftAPICall(
      {
        branchId: storeCode,
        endDate: `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
          2,
          "0"
        )}-${String(date.getDate()).padStart(2, "0")} ${String(
          date.getHours()
        ).padStart(2, "0")}:${String(date.getMinutes()).padStart(
          2,
          "0"
        )}:${String(date.getSeconds()).padStart(2, "0")}`,
      },
      handlePause,
      setShiftEndPasswordDialog,
      setShiftBtnState,
      shiftBtnState
    );
    // handlePause();
    // setShiftEndPasswordDialog(false);
    // setShiftBtnState(!shiftBtnState);
    localStorage.setItem("shitBtnState", JSON.stringify(!shiftBtnState));
    localStorage.removeItem("timer");

    // localStorage.setItem("timer", JSON.stringify(0));
  };

  // Verify Password Function (especially for close shift)
  const verifyUserPassword = () => {
    if (verifyPassWord !== "") {
      verifyPasswordForShiftAPICall(
        { password: verifyPassWord },
        shiftEndPasswordSubmitBtnClick
      );
    }
    // else
    // {
    //   alert('Enter password!!')
    // }
  };

  const clickAddSingleDenomination = () => {
    if (shiftDenominationBody.amount === "") {
      console.log("alert");
    } else if (shiftDenominationBody.count === "") {
      console.log("alert");
    } else {
      let singleObj = {
        currency: shiftDenominationBody.currency,
        amount: shiftDenominationBody.amount,
        count: shiftDenominationBody.count,
        total: shiftDenominationBody.total,
      };
      setShiftDenominationTable([...shiftDenominationTable, singleObj]);
      setShiftDenominationBody(shiftDenominationBodyInitialState);
    }
  };
  const clickDelSingleDenomination = (index) => () => {
    let duplicateArray = [...shiftDenominationTable];
    duplicateArray.splice(index, 1);
    setShiftDenominationTable(duplicateArray);
  };
  const clickEditSingleDenomination = (index) => () => {
    let duplicateArray = [...shiftDenominationTable];
    setShiftDenominationBody({
      ...shiftDenominationBody,
      currency: duplicateArray[index].currency,
      amount: duplicateArray[index].amount,
      count: duplicateArray[index].count,
      total: duplicateArray[index].total,
    });
    duplicateArray.splice(index, 1);
    setShiftDenominationTable(duplicateArray);
  };
  const denominationUpdated = () => {
    setDenominationDialog(false);
    setShiftDenominationTable([]);
    setShiftEndPasswordDialog(true);
  };
  const shiftAddDenominationPayload = {
    denomination: shiftDenominationTable,
    branchId: userBranchId,
    shiftId: userShiftId,
    date: today,
  };
  const denominationSubmitBtn = () => {
    if (shiftDenominationTable.length !== 0) {
      addShiftDenominationAPICall(
        shiftAddDenominationPayload,
        denominationUpdated
      );
    }
    // setShiftEndPasswordDialog(true);
  };
  // //*Log out User
  // const logOutUser =
  //   (isBtnClick, userRole, shiftStatus, confirmPasswordDialog, endShift) =>
  //   () => {
  //     //   const history = useHistory();
  //     if (userRole === "user") {
  //       if (shiftStatus === true) {
  //         if (window.confirm("Do you want to end shift on log out?")) {
  //           console.log("confirmed to end shift");
  //           confirmPasswordDialog()
  //           window.location.replace("/");
  //           // endShift();
  //           // localStorage.clear();
  //         } else {
  //           console.log("Canceled!! shift is still running");
  //           localStorage.clear();
  //           window.location.replace("/");
  //           // localStorage.setItem('shitBtnState',true)
  //         }
  //       } else {
  //         localStorage.clear();
  //         window.location.replace("/");
  //       }
  //     } else {
  //       localStorage.clear();
  //       window.location.replace("/");
  //     }

  //     // localStorage.clear();
  //     // localStorage.setItem('shitBtnState',true)
  //     // Store.dispatch(login_status_update({ loginData: false }));
  //     // console.log(isBtnClick);

  //     if (isBtnClick) {
  //       setTimeout(() => {
  //         alert("Successfully Logged Out");
  //       }, 400);
  //     }
  //   };

  const [executeRequiringDialog, setExecuteRecurringDialog] = useState();
  const navigate = useNavigate();
  const showExecuteRecurringPostDialog = () => {
    // add data from localstorage
    const showExecuteRecurring = localStorage.getItem("accountExecute");
    console.log(showExecuteRecurring);
    if (showExecuteRecurring) {
      setExecuteRecurringDialog(true);
    } else {
      setExecuteRecurringDialog(false);
    }
  };

  useEffect(() => {
    showExecuteRecurringPostDialog();
    viewPosSettingsAPICall();
    getAllActiveEmployeesAPICall();

    viewCashInOrCashOutAPICall();
    viewGlConfigAPICall();
    viewAllCurrencyUpdated({ status: 0 });
    getAllNewProductList();
    vendorListApi();
    customerListApi();
    viewAllMaterialProductsWithStockAPICall({
      itemType: 2,
      branchId: userBranchpk,
    });

    paymentTypesForBilling({ branchId: storeCode });
  }, []);

  const displayListFn = () => {
    setIsDisplayList(!isDisplayList);
  };
  const [cashListForBackEnd, setCashListForBackEnd] = useState([]);
  const [cashList, setCashList] = useState([
    { coin: 1000, count: 0, amount: 0 },
    { coin: 500, count: 0, amount: 0 },
    { coin: 200, count: 0, amount: 0 },
    { coin: 100, count: 0, amount: 0 },
    { coin: 50, count: 0, amount: 0 },
    { coin: 20, count: 0, amount: 0 },
    { coin: 10, count: 0, amount: 0 },
    { coin: 5, count: 0, amount: 0 },
    { coin: 2, count: 0, amount: 0 },
    { coin: 1, count: 0, amount: 0 },
    { coin: 0.5, count: 0, amount: 0 },
    { coin: 0.25, count: 0, amount: 0 },
  ]);

  const editTableRow = (index, key) => (e) => {
    const { value } = e.target;
    if (key === "count") {
      cashList[index].count = Number(e.target.value);
      cashList[index].amount = cashList[index].coin * cashList[index].count;
      setCashList([...cashList]);
    }
  };
  const [total, setTotal] = useState(0);
  // CALCULATE TOTAL
  useEffect(() => {
    let total = cashList.reduce((sum, item) => sum + Number(item.amount), 0);
    setTotal(total);
  }, [cashList]);

  const getCashInOrOutForm = (key) => (e, newValue) => {
    if (key === "refernce") {
      setCashInOrOutForm({ ...cashInOrOutForm, refernce: e.target.value });
    } else if (key === "amount") {
      setCashInOrOutForm({ ...cashInOrOutForm, amount: e.target.value });
    } else if (key === "employee") {
      setCashInOrOutForm({ ...cashInOrOutForm, employee: newValue });
    }
  };

  useEffect(() => {
    setCashListForBackEnd(
      cashList?.filter((obj) => {
        if (obj.count > 0) {
          return obj;
        }
      })
    );
  }, [cashList]);

  const bodyToCashInOrOut = {
    shiftId: userShiftId,
    shiftPk: userShiftpk,
    reference: cashInOrOutForm?.refernce,
    inorout: cashInOrOutState === "cashIn" ? true : false,
    total:
      cashInOrOutState === "cashIn"
        ? Number(cashInOrOutForm?.amount)
        : Number(total),
    date: `${today} ${getCurrentTime()}`,
    empId: cashInOrOutForm?.employee?._id,
    branchId: userBranchpk,
    denomination: cashInOrOutState === "cashOut" ? cashListForBackEnd : [],
  };

  const cashInOrOutSubmitFn = () => {
    addCashInOrOutAPICall(
      bodyToCashInOrOut,
      setCashInOrOutDialog
    );
  };

  const handlePrintRow = (rowData) => {
    const printContent = `
      <html>
        <head>
          <title>Print</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 20px;
            }

            h1 {
              color: #333;
            }

            p {
              margin-bottom: 10px;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 15px;
            }

            th, td {
              border: 1px solid #ddd;
              padding: 8px;
              text-align: left;
            }
            .grandTotal-container{
              display:flex;
              border: 1px solid #ddd;
              justify-content: flex-end;
              padding:4px 8px
            }
            .autorized-container{
              display:flex;
              justify-content:space-between;
            }
            h3{
              margin-right:12px;
              font-size:1rem
            }

            /* Add any additional custom styles here */
          </style>
        </head>
        <body>
          <div>
             <h1>logo</h1>
          </div>
          ${
            !rowData.inorout
              ? `
          <h4>Denomination Details:</h4>
          <table>
            <thead>
              <tr>
                <th>Coin</th>
                <th>Count</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              ${rowData.denomination
                .map(
                  (d) => `
                <tr>
                  <td>${d.coin}</td>
                  <td>${d.count}</td>
                  <td>${d.amount}</td>
                </tr>
              `
                )
                .join("")}
            </tbody>
          </table>
          `
              : ""
          }
           <div class="grandTotal-container">
              <h3>Total:${rowData.total}</h3>
           
           </div>
           <div class="autorized-container">
           <h5>Autorized Person :</h5>
           <h5>Collected By :</h5>
        </div>
        </body>
      </html>
    `;

    // Create a hidden iframe for printing
    const printIframe = document.createElement("iframe");
    printIframe.style.visibility = "hidden";
    document.body.appendChild(printIframe);

    // Set the content of the iframe
    printIframe.contentDocument.write(printContent);

    // Trigger the print dialog
    printIframe.contentWindow.print();

    // Remove the iframe after printing
    document.body.removeChild(printIframe);
  };

  return (
    <>
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar position="fixed" open={open}>
            <Toolbar
              sx={{
                pr: "24px", // keep right padding when drawer closed
                backgroundColor: "#fff",
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: "36px",

                  ...(open && { display: "none" }),
                  color: "#000",
                }}
              >
                <MenuIcon />
              </IconButton>
              {/* empty space before topbar input box */}
              {/* custom component */}
              {/* <SearchInput /> */}
              <div className="dashboard-top-container desktop-logout">
                {(userRole === "user" && (
                  <div
                    className="dashboard-top-container-left-side"
                    style={{ width: "50%", justifyContent: "normal" }}
                  >
                    <div
                      className="dashboard-shift-status-main-container"
                      style={{ width: "45%" }}
                    >
                      <div className="shift-status">
                        <p>
                          Shift
                          <span style={{ color: "red", marginLeft: "5px" }}>
                            {shiftStatus?.shiftId}
                          </span>
                        </p>
                        <div className="shift-status-active">
                          {shiftBtnState === true ? (
                            <div className="shift-status-active-round"></div>
                          ) : (
                            <div
                              className="shift-status-active-round"
                              style={{ backgroundColor: "rgb(173 168 168)" }}
                            ></div>
                          )}

                          {/* <p>Active</p> */}
                          {shiftBtnState === true ? (
                            <p>Active</p>
                          ) : (
                            <p style={{ color: "rgb(173 168 168)" }}>
                              Inactive
                            </p>
                          )}
                        </div>
                      </div>
                      {/* <p>00.00</p> */}
                      <p>
                        {formatTime()}
                        {/*                          
                       <span>{("0" + Math.floor((time / 60000) % 60)).slice(-2)}:</span>
                      <span>{("0" + Math.floor((time / 1000) % 60)).slice(-2)}:</span>
                      <span>{("0" + ((time / 10) % 100)).slice(-2)}</span>   */}
                      </p>
                    </div>
                    {shiftBtnState ? (
                      <button
                        style={{ height: "30px", margin: "0 2%" }}
                        onClick={shiftEndBtnClick}
                        className="start-button end-button"
                      >
                        End
                      </button>
                    ) : (
                      <button
                        style={{ height: "30px", margin: "0 2%" }}
                        onClick={shiftStartBtnFn}
                        className="start-button"
                      >
                        Start
                      </button>
                    )}

                    {posSettingsList?.cashInOutStatus === true && (
                      <button
                        style={{
                          width: "25%",
                          borderRadius: "4px",

                          backgroundColor: "#087baf",

                          color: "#fff",
                          border: "none",
                          height: "30px",
                          cursor: "pointer",

                          margin: "0 2%",
                        }}
                        onClick={() => setCashInOrOutDialog(true)}
                      >
                        Cash In Or Out
                      </button>
                    )}
                  </div>
                )) || <div className="dashboard-top-container-left-side"></div>}

                {/* show Execute Recurring Post */}
                <Dialog
                  open={executeRequiringDialog}
                  onClose={() => setExecuteRecurringDialog(false)}
                  maxWidth="lg"
                >
                  <div className="execute-recurring-post">
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <button
                        onClick={() => setExecuteRecurringDialog(false)}
                        className="close-btn"
                      >
                        <HighlightOffIcon fontSize="large" />
                      </button>
                    </div>

                    <div className="execute-recurring-post-container">
                      <h3>Execute Recurring Post</h3>
                      <div className="execute-recurring-btn-container">
                        <button
                          onClick={() => {
                            setExecuteRecurringDialog(false);
                            navigate(
                              "/userdashboard/accounts/createinvoice/recurringposting"
                            );
                          }}
                          className="btn btn-red"
                        >
                          YES
                        </button>
                        <button
                          onClick={() => setExecuteRecurringDialog(false)}
                          className="btn btn-black"
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog>

                {/* password dialog */}
                <Dialog
                  open={shiftEndPasswordDialog}
                  onClose={() => setShiftEndPasswordDialog(false)}
                  maxWidth="lg"
                >
                  <div className="shift-end-button-password-container">
                    <h3>Enter Password</h3>
                    <div className="global-single-input shit-end-password-input">
                      <p>Password</p>
                      <input
                        type="password"
                        value={verifyPassWord}
                        onKeyDown={(e) =>
                          e.key === "Enter"
                            ? verifyUserPassword()
                            : e.key === "Escape"
                            ? () => setShiftEndPasswordDialog(false)
                            : undefined
                        }
                        onChange={(e) => {
                          setVerifyPassword(e.target.value);
                        }}
                      />
                    </div>
                    <button
                      onClick={verifyUserPassword}
                      className="btn btn-primary shift-end-password-btn"
                    >
                      Submit
                    </button>
                  </div>
                </Dialog>

                {/* cash In or OUT dIALOG */}
                <Dialog open={cashInOrOutDialog} maxWidth="lg">
                  <div
                    onClick={displayListFn}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      margin: "2% 5%",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={listIcon}
                      alt=""
                      style={{ width: "20px", height: "20px" }}
                    />
                  </div>
                  {isDisplayList ? (
                    <div
                      className="global-white-bg-container"
                      style={{ width: "60vw", padding: "2% 4%" }}
                    >
                      <div className="create-button-blue-container">
                        <h4 style={{ margin: 0 }}>Cash IN Cash OUT</h4>
                      </div>
                      <div className="new-global-table-container">
                        <table>
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Type</th>
                              <th>Branch</th>
                              <th>Shift</th>
                              <th>Employee</th>
                              <th>Reference</th>
                              <th>Amount</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {cashInOrOutData
                              ?.slice(0)
                              ?.reverse()
                              ?.map((r) => (
                                <tr>
                                  <td>{r?.date}</td>
                                  <td>
                                    {r?.inorout === true ? "CashIn" : "CashOut"}
                                  </td>
                                  <td>{r?.branchId}</td>
                                  <td>{r?.shiftId}</td>
                                  <td>---</td>
                                  <td>{r?.reference}</td>
                                  <td>{r?.total}</td>
                                  <td>
                                    <IconButton
                                      className="print-icon-container"
                                      onClick={() => handlePrintRow(r)}
                                    >
                                      {/* <i class="bi bi-printer printer-icon"></i> */}
                                      <i
                                        class="bi bi-printer"
                                        style={{ fontSize: "1rem" }}
                                      ></i>
                                    </IconButton>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="shift-end-button-password-container"
                      style={{ width: "40vw", padding: "2% 4%" }}
                    >
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            marginRight: "2%",
                            width: "25%",
                            accentColor: "black",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <input
                            type="radio"
                            id="cashIn"
                            name="cashInOut"
                            // defaultChecked
                            value="cashIn"
                            onChange={(e) =>
                              setCashInOrOutState(e.target.value)
                            }
                            checked={cashInOrOutState === "cashIn"}
                          />
                          <label
                            htmlFor="cashIn"
                            style={{
                              fontSize: "0.8rem",
                              fontWeight: 600,
                              margin: "5px 0 0 2px",
                            }}
                          >
                            Cash In
                          </label>
                        </div>
                        <div
                          style={{
                            marginRight: "2%",
                            accentColor: "black",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <input
                            type="radio"
                            id="cashOut"
                            name="cashInOut"
                            value="cashOut"
                            onChange={(e) =>
                              setCashInOrOutState(e.target.value)
                            }
                            checked={cashInOrOutState === "cashOut"}
                          />

                          <label
                            htmlFor="cashOut"
                            style={{
                              fontSize: "0.8rem",
                              fontWeight: 600,
                              margin: "5px 0 0 2px",
                            }}
                          >
                            Cash Out
                          </label>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          margin: "2% 0",
                        }}
                      >
                        <div
                          className="new-global-single-input"
                          style={{ width: "45%" }}
                        >
                          <TextField
                            id="outlined-basic"
                            label="Reference"
                            variant="outlined"
                            type="text"
                            focused
                            value={cashInOrOutForm?.refernce}
                            onChange={getCashInOrOutForm("refernce")}
                          />
                        </div>
                        {cashInOrOutState === "cashIn" && (
                          <div
                            className="new-global-single-input"
                            style={{ width: "45%" }}
                          >
                            <TextField
                              id="outlined-basic"
                              label="Amount*"
                              variant="outlined"
                              type="text"
                              focused
                              value={cashInOrOutForm?.amount}
                              onChange={getCashInOrOutForm("amount")}
                            />
                          </div>
                        )}

                        <div
                          className="new-global-single-input auto-complete-new"
                          style={{ width: "45%" }}
                        >
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={allActiveEmpList || []}
                            getOptionLabel={(option) =>
                              `${option?.emp_id}-${option?.staff_name}`
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Employee*"
                                focused
                              />
                            )}
                            value={cashInOrOutForm?.employee}
                            onChange={getCashInOrOutForm("employee")}
                          />
                        </div>
                      </div>

                      {cashInOrOutState === "cashOut" && (
                        <div className="cash-in-out-table">
                          <table>
                            <thead>
                              <tr>
                                <th>Cash/Coin</th>
                                <th>Count</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {cashList?.map((r, i) => (
                                <tr>
                                  <td>{r?.coin}</td>
                                  <td>
                                    <input
                                      type="text"
                                      value={r?.count}
                                      onChange={editTableRow(i, "count")}
                                    />
                                  </td>
                                  <td>{r?.amount}</td>
                                </tr>
                              ))}

                              {/* <tr style={{backgroundColor:"#f7e4ff"}}>
                                      <td></td>
                                      <td style={{textAlign:"right"}}>Total</td>
                                      <td>000</td>
                                    </tr> */}
                            </tbody>
                            <tbody>
                              <tr style={{ backgroundColor: "#f7e4ff" }}>
                                <td></td>
                                <td style={{ textAlign: "right" }}>Total</td>
                                <td>{total}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          onClick={() => setCashInOrOutDialog(false)}
                          style={{ height: "35px", width: "25%" }}
                          className="btn btn-secondary-outlined"
                        >
                          Cancel
                        </button>
                        <button
                          style={{ height: "35px", width: "25%" }}
                          className="btn btn-primary"
                          onClick={cashInOrOutSubmitFn}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )}
                </Dialog>

                {/* cash in or out print view */}
                {/* <div  style={{ display: "none" }}>
                  <div ref={(el) => (cashInOutPrint = el)}>
CCCCCCCC
                  </div>
                </div> */}

                <div className="dashboard-top-container-right-side">
                  <div className="role-branch-code-container">
                    <div className="role-branch-child">
                      <span className="head-role">Role</span>
                      <span>:&nbsp;&nbsp;</span>
                      <span
                        style={{
                          color: "#1ebe69",
                          textTransform: "capitalize",
                        }}
                      >
                        {userRole}
                      </span>
                    </div>
                    {branchName !== "noBranch" && branchName !== "..." && (
                      <div className="role-branch-child">
                        <span className="head">Branch</span>
                        <span>:&nbsp;&nbsp;</span>
                        <span style={{ color: "#1ebe69" }}>{branchName}</span>
                      </div>
                    )}
                  </div>

                  {/* icons */}
                  <Tooltip title="Live Chat">
                    <IconButton>
                      <i class="bi bi-chat-text chat-icon"></i>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Notification">
                    <IconButton>
                      <NotificationsNoneIcon
                        style={{ fontSize: 24, color: "#000" }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Stack direction={"row"} className="desktop-logout">
                    <Chip
                      label="Log Out"
                      onClick={logOutUser(
                        true,
                        userRole,
                        shiftBtnState,
                        shiftEndBtnClick
                      )}
                      icon={
                        <PowerSettingsNewIcon
                          style={{ fontSize: 16, color: "red" }}
                        />
                      }
                      style={{
                        fontWeight: 600,
                        color: "#000",
                        backgroundColor: "#f4f4f4",
                        marginLeft: 8,
                        cursor: "pointer",
                        border: "2px solid orange",
                      }}
                    ></Chip>
                  </Stack>
                </div>

             

                {/* SHIFT end denomination dialog */}
                <Dialog
                  // open={true}
                  open={denominationDialog}
                  maxWidth="lg"
                  onKeyDown={(e) =>
                    e.key === "Escape" && setDenominationDialog(false)
                  }
                >
                  <div className="shift-add-denomination-dialog">
                    <h4>Add Denomination</h4>
                    <div className="add-denomination-input-container-table">
                      <table>
                        <thead>
                          <tr>
                            <th>
                              <div className="global-single-input">
                                <p>Currency</p>
                                <Select
                                  value={shiftDenominationBody.currency}
                                  onChange={shiftDenominationGetForm(
                                    "currency"
                                  )}
                                >
                                  <MenuItem value="Rs">Rs</MenuItem>
                                  <MenuItem value="dollar">$</MenuItem>
                                  <MenuItem value="SAR">SAR</MenuItem>
                                </Select>
                              </div>
                            </th>
                            <th>
                              <div className="global-single-input">
                                <p>Amount</p>
                                <input
                                  type="number"
                                  value={shiftDenominationBody.amount}
                                  onChange={shiftDenominationGetForm("amount")}
                                  placeholder="0"
                                />
                              </div>
                            </th>
                            <th>
                              <div className="global-single-input">
                                <p>Count</p>
                                <input
                                  type="number"
                                  value={shiftDenominationBody.count}
                                  onChange={shiftDenominationGetForm("count")}
                                  placeholder="0"
                                />
                              </div>
                            </th>
                            <th>
                              <div className="global-single-input">
                                <p>Total</p>
                                <input
                                  type="number"
                                  value={shiftDenominationBody.total}
                                  disabled
                                />
                              </div>
                            </th>
                            <th>
                              <button
                                className="shift-single-add-btn"
                                onClick={clickAddSingleDenomination}
                              >
                                +
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {shiftDenominationTable.length !== 0 ? (
                            shiftDenominationTable.map((r, i) => (
                              <tr key={i}>
                                <td style={{ width: "15%" }}>
                                  {r?.currency.toUpperCase()}
                                </td>
                                <td>{r?.amount}</td>
                                <td>{r?.count}</td>
                                <td>{r?.total}</td>
                                <td
                                  style={{
                                    border: "none",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <IconButton
                                    onClick={clickEditSingleDenomination(i)}
                                  >
                                    <i class="bi bi-pencil-square edit-icon"></i>
                                  </IconButton>
                                  <IconButton
                                    onClick={clickDelSingleDenomination(i)}
                                  >
                                    <i class="bi bi-trash3 delete-icon"></i>
                                  </IconButton>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colspan={5}
                                style={{
                                  backgroundColor: "#eee",
                                  border: "none",
                                }}
                              >
                                Empty
                              </td>
                            </tr>
                          )}
                          {shiftDenominationTable.length !== 0 && (
                            <tr>
                              <td style={{ border: "none" }}>&nbsp;</td>
                              <td style={{ border: "none" }}>&nbsp;</td>
                              <td
                                style={{ fontSize: "1rem", fontWeight: "700" }}
                              >
                                Grand Total
                              </td>
                              <td>{denominationGrandTotal}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="denomination-btn-container">
                      <button
                        onClick={() => setDenominationDialog(false)}
                        className="btn btn-secondary-outlined denomination-btn"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={denominationSubmitBtn}
                        className="btn btn-primary denomination-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Dialog>

                {/* shift container */}
                {/* <div className="dashboard-shift-status-container">
                  
                  {shiftBtnState?
                   <button onClick={shiftEndBtnClick} className="start-button  end-button">END</button>
                   :
                   <button onClick={shiftBtnChange} className=" start-button">START</button>
                  }
                <div className="dashboard-shift-status-main-container">
                  <span>Shift Status</span>
                  <div className="shift-status-box">
                    <div className="shift-status-active-box">
                        <div className="shift-status-active-round"></div>
                        <p>ACTIVE</p>
                    </div>
                    <p>{newCurrentTime}</p>
                  </div>
                </div>
                </div> */}

                {/* <Dialog open={shiftEndPasswordDialog}>
                  <div className="shift-end-button-password-container">
                    <h3>Enter Password</h3>
                    <div className="global-single-input shit-end-password-input">
                      <p>Password</p>
                      <input type="text" />
                    </div>
                    <button
                      onClick={shiftEndPasswordSubmitBtnClick}
                      className="btn btn-primary shift-end-password-btn"
                    >
                      Submit
                    </button>
                  </div>
                </Dialog> */}
              </div>
              {/* closing tag */}
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            open={open}
            className="drawer"
            style={{ overflow: "hidden", height: "100vh" }}
          >
            <Toolbar
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                px: [1],
              }}
            >
              <div className="user-info">
                <Avatar sx={{ marginRight: 2 }} src={userInfo?.imageUrl} />

                <p>{userInfo?.userName}</p>
              </div>
              <Tooltip title="Logout">
                <div className="logout-mob">
                  <IconButton
                    // onClick={logOutUser(true)}
                    onClick={logOutUser(
                      true,
                      userRole,
                      shiftBtnState,
                      shiftEndBtnClick
                    )}
                  >
                    <PowerSettingsNewIcon
                      style={{ fontSize: 16, color: "red" }}
                    />
                  </IconButton>

                  {/* <Chip
                    className="logout-btn-dashboard-chip-mob"
                    icon={
                      <PowerSettingsNewIcon
                        style={{ fontSize: 16, color: "red" }}
                      />
                    }
                    style={{
                      fontWeight: 600,
                      color: "#000",
                      backgroundColor: "#f4f4f4",
                      marginLeft: 8,
                      cursor: "pointer",
                    }}
                    onClick={logOutUser(true)}
                  ></Chip> */}
                </div>
              </Tooltip>
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />

            <List sx={{ width: 280 }}>
              <SideMenu refresh={sideMenuRefresh} />
            </List>
            {/* <Divider /> */}
          </Drawer>

          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? "#edf2f9"
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            {/* this toolbar only for top margin  */}

            <Toolbar />

            <Routes>
              {/* Main Admin Dashboard */}
              <Route path="dashboardhome" element={<HomeDashboard />}></Route>

              {/* Product List Section  */}
              {/* {userRole === "user" ? (
                <>
                  {loginResponse?.permission?.productList === true && (
                    <Route path="productList" element={<ProductList />} />
                  )}
                </>
              ) : (
                <Route path="productList" element={<ProductList />} />
              )} */}

              <Route path="productList" element={<ProductList />} />
              <Route
                path="productList/singleview"
                element={<ProductListSingleView />}
              />

              {/* product master section */}
              <Route path="productmaster" element={<ProductMaster />} />

              {/* Purchase Manager Section  */}

              <Route
                path="purchaseManager/PurchaseOrder"
                element={<PurchaseOrder />}
              />
              <Route
                path="purchaseManager/addPurchaseOrder"
                element={<AddPurchaseOrder />}
              />
              <Route
                path="purchaseManager/PurchaseOrder/singleView"
                element={<PurchaseOrderSingleView />}
              />
              <Route
                path="purchasemanager/goodsReceivedNote/draft"
                element={<GrnDraft />}
              />
              <Route
                path="purchasemanager/goodsReceivedNote/posted"
                element={<PostedGrn />}
              />
              <Route
                path="purchasemanager/goodsReceivedNote/addGrn"
                element={<GoodsReceivedNotes />}
              />
              <Route
                path="purchaseManager/PurchaseW/OPO"
                element={<PurchaseWoPo />}
              />
              <Route
                path="purchaseManager/PendingPaymentVoucher"
                element={<PendingPaymentVoucher />}
              />
              <Route
                path="purchaseManager/PaymentVoucher"
                element={<PaymentVoucher />}
              />
              <Route
                path="purchaseManager/SupplierManager"
                element={<SupplierManager />}
              />
              <Route
                path="purchaseManager/RequestForQuatation"
                element={<RequestForQuatation />}
              />
              <Route
                path="purchaseManager/RequestForQuatation/singleView"
                element={<RequestForQuatationSingleView />}
              />

              <Route
                path="purchaseManager/CreateRequestForQuatation"
                element={<CreateRequestForQuotation1 />}
              />

              <Route
                path="purchaseManager/CreateRequestForQuatation1"
                element={<CreateRequestForQuatation />}
              />

              <Route
                path="purchaseManager/NewPurchaseOrder"
                element={<NewPurchaseOrder />}
              />

              <Route
                path="purchaseManager/CreateNewPurchaseOrder"
                element={<CreateNewPurchaseOrder1 />}
              />

              <Route
                path="purchaseManager/CreateNewPurchaseOrder1"
                element={<CreateNewPurchaseOrder />}
              />

              <Route
                path="purchaseManager/GoodsReceivedNote"
                element={<GrnListing1 />}
              />

              <Route
                path="purchaseManager/GoodsReceivedNote/SingleView"
                element={<GoodsReceivedNotesSingleView />}
              />

              <Route
                path="purchaseManager/GoodsReceivedNote1"
                element={<GrnListing />}
              />

              <Route
                path="purchaseManager/GoodsReceivedNote/add"
                element={<NewGoodsReceivedNotes1 />}
              />

              <Route
                path="purchaseManager/GoodsReceivedNote/add1"
                element={<NewGoodsReceivedNotes />}
              />

              <Route
                path="purchaseManager/supplierLedger"
                element={<SupplierLedger />}
              />
              <Route
                path="purchaseManager/stockOutProducts"
                element={<StockoutProducts />}
              />
              <Route
                path="purchaseManager/approvedStock"
                element={<ApproveStockOutProducts />}
              />
              <Route
                path="purchaseManager/purchaseReturn"
                element={<PurchaseReturn />}
              />
              {/* stock adjustment section */}
              <Route
                path="purchasemanager/stockAdjustment/stockAdjustmentlist"
                element={<StockAdjustmentList />}
              />
              <Route
                path="purchasemanager/stockAdjustment/draft"
                element={<Draft />}
              />
              <Route
                path="purchasemanager/stockAdjustment/addstockAdjustment"
                element={<AddStockAdjustment />}
              />
              <Route path="sales/Report/dailySalesReport" element={<DailySalesReport />} />
              <Route path="sales/Report/dailySalesReport/SingleView" element={<DailySalesReportSingleView />} />
              <Route
                path="purchasemanager/stockAdjustment/receivedStock"
                element={<ReceivedStock />}
              />
              <Route
                path="purchasemanager/stockAdjustment/confirmedStock"
                element={<ConfirmStock />}
              />
              <Route
                path="purchasemanager/unitOfMeasurment"
                element={<UnitOfMeasurment />}
              />

              <Route
                path="purchasemanager/PurchaseReport/PurchaseReportNew"
                element={<PurchaseReportNew />}
              />
              <Route
                path="purchasemanager/PurchaseReport/PurchaseReportNew/SingleView"
                element={<PurchaseReportSingleView />}
              />

              <Route path="purchasemanager/newUnit" element={<NewUOM />} />
              <Route
                path="purchaseManager/vendor/vendors"
                element={<VendorListPurchaseManage />}
              />
              <Route
                path="purchaseManager/vendor/Createvendors"
                element={
                  <SalesCustomers
                    isCustomer={false}
                    isVendor={true}
                    isSales={false}
                    isPurchase={true}
                  />
                }
              />
              {/* Transfer */}
              <Route
                path="purchaseManage/transfer/transfer"
                element={<Transfer />}
              />
              <Route
                path="purchaseManage/transfer/receivedTransfer"
                element={<RecivedTransfer />}
              />
              <Route
                path="purchaseManage/transfer/confirmTransfer"
                element={<ConfirmTransfer />}
              />
              <Route
                path="purchaseManager/configuration"
                element={<ConfigurationPurchase />}
              />

              {/* Orders section */}

              <Route path="orders/viewOrders" element={<ViewOrder />} />
              {/* <Route
                path="orders/viewOrdersnew"
                element={<ViewOrdersUserNew />}
              /> */}
              <Route path="orders/workOrder" element={<WorkOrder />} />
              <Route
                path="orders/printCuttingSlip"
                element={<PrintCuttingSlip />}
              />
              <Route path="orders/jobCompletion" element={<JobCompletion />} />
              <Route path="orders/alteration" element={<Alteration />} />
              <Route path="orders/delivery" element={<Delivery />} />

              {/* sales section */}
              <Route path="sales/billing" element={<Billing />} />
              <Route path="sales/billingnew" element={<BillingNew />} />
              <Route path="sales/orderList" element={<OrderList />} />
              <Route path="sales/payments" element={<Payments />} />
              <Route
                path="sales/quotation/estimate"
                element={<QuotationOrEstimate />}
              />
              <Route path="sales/salesReturn" element={<SalesReturn />} />
              <Route path="sales/oldStock" element={<OldStock />} />
              <Route path="sales/damagedGoods" element={<DamagedGoods />} />
              <Route path="sales/quickBill" element={<QuickBill />} />
              <Route path="sales/credit" element={<Credit />} />
              <Route path="wallet" element={<Wallet />} />

              {/* customer section */}
              <Route path="customer/allCustomer" element={<AllCustomer />} />
              <Route
                path="customer/allCustomer/singleCustomer"
                element={<SingleCustomer />}
              />
              <Route path="customer/addCustomer" element={<AddCustomer />} />

              {/* expense section */}
              <Route path="Expense/staffExpense" element={<StaffExpense />} />
              <Route path="expense/outletExpense" element={<OutletExpense />} />
              <Route
                path="expense/pettyCashReceipt"
                element={<PettyCashReceipt />}
              />
              <Route
                path="expense/addExpenseType"
                element={<AddExpenseType />}
              />

              {/* Report section */}
              <Route
                path="report/posSalesReport"
                element={<PosSalesReport />}
              />
              <Route
                path="report/CustomerCreditReport"
                element={<CustomerCreditReport />}
              />
              <Route
                path="report/CustomerCreditReport/SingleView1"
                element={<CustomerCreditReportSingleView1 />}
              />
              <Route
                path="report/CustomerCreditReport/SingleView2"
                element={<CustomerCreditReportSingleView2 />}
              />
              <Route path="report/stockReport" element={<StockReport />} />
              <Route path="report/salesReport" element={<SalesReport />} />
              <Route
                path="report/stockSummaryReport"
                element={<StockSummaryReport />}
              />
              <Route path="report/dailyReport" element={<DailyReport />} />
              <Route
                path="report/dailyCashCardSummary"
                element={<DailyCashCardSummary />}
              />
              <Route
                path="report/shiftReport"
                element={<ShiftReport />}
              />
              <Route
                path="report/shiftReport/singleView"
                element={<ShiftReportSingleView />}
              />
              <Route
                path="report/transferReport"
                element={<TransferReport />}
              />
              <Route
                path="report/stockAdjustmentReport"
                element={<StockAdjustmentReport />}
              />
              <Route path="report/expenseReport" element={<ExpenseReport />} />
              <Route path="report/paymentReport" element={<PaymentReport />} />
              <Route path="report/grnReport" element={<GRNReport />} />
              <Route path="report/purchasew/o" element={<PurchaseWOReport />} />
              <Route
                path="report/commissionReport"
                element={<CommissionReport />}
              />
              <Route
                path="report/quickBillReport"
                element={<QuickBillReport />}
              />
              <Route
                path="report/jobCompletionReport"
                element={<JobCompletionReport />}
              />
              {/* staff section */}

              <Route path="staff/dashboard" element={<StaffDashboard />} />
              <Route
                path="staff/hrm/manageEmployee/viewEmployee/viewEmpTotalCommission"
                element={<EmpDashboardTotalCommission />}
              />

              <Route path="staff/attendance" element={<StaffAttendance />} />

              <Route
                path="staff/leave/leaveApplication"
                element={<StaffLeaveApplication />}
              />
              <Route path="staff/leave/holiday" element={<StaffHoliday />} />
              <Route
                path="staff/leave/addLeaveType"
                element={<StaffAddLeaveType />}
              />

              <Route
                path="staff/payroll/employeeSalary"
                element={<StaffPayrollEmpSalary />}
              />
              <Route
                path="staff/payroll/addPayRoll"
                element={<StaffPayrollAddPayroll />}
              />
              <Route
                path="staff/payroll/payRollItem"
                element={<StaffPayrollItem />}
              />
              <Route path="staff/payroll/payslip" element={<StaffPaySlip />} />

              <Route
                path="staff/loan/addNewLoan"
                element={<StaffAddNewLoan />}
              />
              <Route
                path="staff/loan/loanReport"
                element={<StaffLoanReport />}
              />
              <Route
                path="staff/loan/loanApprovals"
                element={<StaffLoanApprovals />}
              />

              <Route path="staff/hrm/department" element={<Department />} />
              <Route path="staff/hrm/designation" element={<Designation />} />
              <Route
                path="staff/hrm/manageEmployee"
                element={<ManageEmployee />}
              />

              <Route
                path="staff/hrm/manageEmployee/viewEmployee"
                element={<ViewEmployee />}
              />

              <Route path="staff/hrm/addEmployee" element={<AddEmployee />} />
              <Route path="staff/hrm/addDocument" element={<AddDocument />} />
              <Route
                path="staff/hrm/addDocument/documentprint"
                element={<DocumentPrintView />}
              />

              {/* Device Settings */}

              <Route path="Devicesettings" element={<DeviceSettings />} />

              {/* settings section */}
              <Route path="settings" element={<Settings />} />

              <Route path="qr/barcode" element={<GenerateQrOrBarcode />} />

              {/* rewards and points section */}
              <Route
                path="rewardsAndPoints/rewardView"
                element={<RewardsView />}
              />
              <Route
                path="rewardsAndPoints/addRewards"
                element={<AddRewards />}
              />

              {/* offer section */}
              <Route path="offers/offersList" element={<OffersListed />} />
              <Route path="offers/addOffer" element={<AddOffer />} />

              {/* account section */}
              {/* customers */}

              <Route
                path="accounts/customers/customerInvoices"
                element={<CustomerInvoice />}
              />

              <Route
                path="accounts/customers/customerInvoices/singleView"
                element={<CustomerInvoiceSingleView />}
              />

              <Route
                path="accounts/customers/customerInvoices/create"
                element={<CreateCustomerInvoice />}
              />
              <Route
                path="accounts/customers/saleswso"
                element={<SalesWSO />}
              />
              <Route
                path="accounts/customers/saleswso/create"
                element={<CreateSalesWSO />}
              />
              <Route
                path="accounts/customers/saleswso/singleView"
                element={<SalesWSOsingleView />}
              />
              <Route
                path="accounts/customer/payments"
                element={<CustomerPayments />}
              />
              <Route
                path="accounts/customer/payments/create"
                element={<CreateCustomerPayment />}
              />
              <Route
                path="accounts/customers/creditNotes"
                element={<CustomerCreditNotes />}
              />

              <Route
                path="accounts/customers/creditNotes/SingleView"
                element={<CreaditNoteSingleView />}
              />
              <Route
                path="accounts/customers/creditNotes/create"
                element={<CreateCustomerCreditNotes />}
              />

              {/* <Route
                path="accounts/customers/customers"
                element={<Vendors />}
              /> */}

              <Route
                path="accounts/customers/customers"
                element={<Customers isSales={false} />}
              />

              <Route
                path="accounts/customers/customers/create"
                element={
                  <SalesCustomers
                    isCustomer={true}
                    isVendor={false}
                    isSales={false}
                    isPurchase={false}
                    isAllCustomer={true}
                  />
                }
              />

              <Route
                path="accounts/customers/customers/create1"
                element={<CreateCustomer />}
              />
              <Route
                path="accounts/Reconciliation/BankReconciliation"
                element={<BankReconciliationNew />}
              />

              <Route
                path="accounts/Reconciliation/BankReconciliation/Create"
                element={<CreateBankReconciliation />}
              />
              <Route
                path="accounts/vatReport/inputOrOutputReport"
                element={<InputOrOutputReport />}
              />

              {/* accounting */}
              <Route path="accounts/accounting/journal" element={<Journal />} />
              <Route
                path="accounts/accounting/journal/createjournal"
                element={<CreateJournal />}
              />
              <Route
                path="accounts/accounting/chartofaccounts"
                element={<ChartOfAccounts />}
              />
              <Route
                path="accounts/accounting/createchartofaccounts"
                element={<CreateChartOfAccount />}
              />
              <Route
                path="accounts/accounting/journalEntries"
                element={<JournalEntries />}
              />
              <Route
                path="accounts/accounting/journalEntriesCreate"
                element={<JournalEntriesForm />}
              />
              <Route
                path="accounts/accounting/recurringposting"
                element={<ReccurringPosting />}
              />

              <Route
                path="accounts/accounting/openingBalance"
                element={<OpeningBalance />}
              />
              <Route
                path="accounts/accounting/openingBalance/create"
                element={<CreateOpeningBalance />}
              />
              {/* ACcounts vendor */}

              <Route
                path="accounts/vendor/vendorBills"
                element={<VendorBills1 />}
              />

              <Route
                path="accounts/vendor/vendorBills1"
                element={<VendorBills />}
              />

              <Route
                path="accounts/vendor/SingleView"
                element={<VendorBillSingleView />}
              />
              <Route
                path="accounts/vendor/createvendorBills"
                element={<CreateVendorBills1 />}
              />

              {/* <Route
                path="accounts/vendor/createvendorBills1"
                element={<CreateVendorBills />}
              /> */}

              <Route
                path="accounts/vendor/payments"
                element={<VendorPayments />}
              />
              <Route
                path="accounts/vendor/createvendorPayments"
                element={<CreateVendorPayments />}
              />
              <Route
                path="accounts/vendor/DebitNotes"
                element={<DebitNotes />}
              />
              <Route
                path="accounts/vendor/DebitNotes/create"
                element={<CreateDebitNote />}
              />
              <Route path="accounts/vendor/vendors" element={<Vendors />} />
              <Route
                path="accounts/vendor/Createvendors"
                element={
                  <SalesCustomers
                    isVendor={true}
                    isCustomer={false}
                    isSales={false}
                    isPurchase={false}
                  />
                }
              />

              <Route
                path="accounts/vendor/Createvendors1"
                element={<CreateVendors />}
              />

              <Route
                path="accounts/vendor/purchasewopo"
                element={<PurchaseWOPO1 />}
              />

              <Route
                path="accounts/vendor/purchasewopo/create"
                element={<CreatePurchaseWOPO1 />}
              />
              <Route
                path="accounts/vendor/purchasewopo/SingleView"
                element={<PurchaseWOPOsingleView />}
              />

              <Route
                path="accounts/vendor/DebitNote/SingleView"
                element={<DebitNoteSingleView />}
              />

              {/* Account Report*/}
              <Route
                path="accounts/report/financialReport/generalLedger"
                element={<GeneralLedgerNw />}
              />
              <Route
                path="accounts/report/financialReport/trialbalance"
                element={<TrialBalance />}
              />
              <Route
                path="accounts/report/financialReport/balancesheet"
                element={<BalanceSheet />}
              />
              <Route
                path="accounts/report/financialReport/Profit&Loss"
                element={<ProfitAndLoss />}
              />
              <Route
                path="accounts/report/financialReport/trialbalance/ledgerBalance"
                element={<LedgerBalance />}
              />

              {/* Partner Report */}
              <Route
                path="accounts/report/partnerReport/accountPayable"
                element={<AccountPayable />}
              />
              <Route
                path="accounts/report/partnerReport/accountPayable/singleView"
                element={<AccountPayableSingleView />}
              />

              <Route
                path="accounts/report/partnerReport/accountReceivable"
                element={<AccountRecievable />}
              />

              <Route
                path="accounts/report/partnerReport/accountReceivable/singleView"
                element={<AccountRecievableSingleView />}
              />
              <Route
                path="accounts/report/partnerReport/AccountReceivableAgingReport"
                element={<AccountReceivableAgingReport />}
              />
              <Route
                path="accounts/report/partnerReport/AccountReceivableAgingReportSingleView"
                element={<AccountReceivableAgingReportSingleView />}
              />
              <Route
                path="accounts/report/partnerReport/AccountPayableAgingReport"
                element={<AccountPayableAgingReport />}
              />
              <Route
                path="accounts/report/partnerReport/ReceivableDueReport"
                element={<ReceivableDueReport />}
              />
              <Route
                path="accounts/report/partnerReport/ReceivableDueReport/SingleView"
                element={<ReceivableDueReportSingleView />}
              />
              <Route
                path="accounts/report/partnerReport/PayableDueReport"
                element={<PyableDueReport />}
              />
              <Route
                path="accounts/report/partnerReport/PayableDueReport/SingleView"
                element={<PayableDueReportSingleView />}
              />
              <Route
                path="accounts/report/partnerReport/AccountPayableAgingReportSingleView"
                element={<AccountPayableAgingReportSingleView />}
              />

              {/* General Report */}
              <Route
                path="accounts/report/generalReport/bankAndCashReport"
                element={<BankAndCashReport />}
              />
              <Route
                path="accounts/report/generalReport/bankAndCashReport/singleView"
                element={<BankAndCashReportSingleView />}
              />

              <Route
                path="accounts/report/generalReport/InvoiceMarginReport"
                element={<InvoiceMarginReport />}
              />
              <Route
                path="accounts/report/generalReport/InvoiceMarginReport/SingleView"
                element={<InvoiceMarginReportSingleView />}
              />
              <Route
                path="accounts/report/generalReport/productMarginReport"
                element={<ProductMarginReport />}
              />
              <Route
                path="accounts/report/generalReport/productMarginReport/SingleView"
                element={<ProductMarginReportSingleView />}
              />

              {/* configuration */}

              <Route
                path="accounts/configuration"
                element={<Configuration />}
              />

              <Route path="accounts/createledger" element={<CreateLedger />} />
              <Route
                path="accounts/createinvoice/accounting"
                element={<Accounting />}
              />
              <Route
                path="accounts/createinvoice/inventory"
                element={<Inventory />}
              />
              <Route
                path="accounts/createinvoice/payroll"
                element={<PayRoll />}
              />
              <Route
                path="accounts/createinvoice/recurringposting"
                element={<RecurringPost />}
              />
              <Route
                path="accounts/reports/financialreports"
                element={<FinancialReports />}
              />
              <Route
                path="accounts/reports/inventoryreports"
                element={<InventoryReports />}
              />
              <Route
                path="accounts/reports/payrollreports"
                element={<PayrollReports />}
              />
              <Route
                path="accounts/reports/bankreports"
                element={<BankReports />}
              />
              <Route
                path="accounts/reports/gstreports"
                element={<GstReports />}
              />
              <Route
                path="accounts/accountreconciliation/cashreconciliation"
                element={<CashReconciliation />}
              />
              <Route
                path="accounts/accountreconciliation/bankreconciliation"
                element={<BankReconciliation />}
              />
              <Route
                path="accounts/accountreconciliation/suppliersreconciliation"
                element={<SuppliersReconciliation />}
              />
              <Route
                path="accounts/accountreconciliation/customersreconciliation"
                element={<CustomersReconciliation />}
              />
              <Route
                path="accounts/accountreconciliation/reconciliationreports"
                element={<ReconciliationReports />}
              />

              {/* inventory */}
              <Route
                path="inventory/configuration/warehouse"
                element={<WareHouse />}
              />
              <Route
                path="inventory/configuration/createwarehouse"
                element={<CreateWareHouse />}
              />

              <Route
                path="inventory/configuration/location"
                element={<InventoryLocation />}
              />

              <Route
                path="inventory/configuration/location/create"
                element={<CreateInvLocation />}
              />

              <Route
                path="inventory/configuration/settings"
                element={<SettingsProductVariant />}
              />
              <Route
                path="inventory/configuration/attribute"
                element={<Attribute />}
              />
              <Route
                path="inventory/configuration/attribute/create"
                element={<CreateAttribute />}
              />
              <Route
                path="inventory/configuration/poscategory"
                element={<POSCategory />}
              />
              <Route
                path="inventory/configuration/poscategory/create"
                element={<CreatePOSCategory />}
              />
              <Route
                path="inventory/configuration/category"
                element={<Category />}
              />
              <Route
                path="inventory/reports/stockMove"
                element={<StockMove />}
              />
              <Route
                path="inventory/reports/stockMove/singleView"
                element={<StockMoveSingleView />}
              />

              <Route
                path="inventory/configuration/category/create"
                element={<CreateCategory />}
              />

              <Route
                path="inventory/operation/Interanltransfer"
                element={<InternalTransfer />}
              />
              <Route
                path="inventory/operation/branchtransfer"
                element={<BranchTransfer />}
              />

              <Route
                path="inventory/operation/inventoryAdjustment"
                element={<InventoryAdjustment />}
              />
              <Route
                path="inventory/operation/inventoryAdjustment/create"
                element={<CreateInventoryAdjustment />}
              />
              <Route
                path="inventory/operation/landedcost"
                element={<LandedCost />}
              />
              <Route
                path="inventory/operation/landedcost/create"
                element={<CreateLandedCost />}
              />
              <Route
                path="inventory/operation/stockmoves"
                element={<StockMoves />}
              />
              <Route
                path="inventory/operation/InternalTransfer/create"
                element={<CreateInternalTransfer />}
              />
              <Route
                path="inventory/operation/branchtransfer/create"
                element={<CreateBranchTransfer />}
              />

              <Route
                path="inventory/operation/branchreceipt"
                element={<BranchReceipt />}
              />
              <Route
                path="inventory/operation/branchreceipt/create"
                element={<CreateBranchReceipt />}
              />

              {/* inventory-operation-product */}

              <Route
                path="inventory/products/product"
                element={<ProductListUpdated />}
              />
              <Route
                path="inventory/products/product/create"
                element={<CreateProductList />}
              />
              <Route
                path="inventory/products/productmaster"
                element={<ProductMasterUpdated />}
              />
              <Route
                path="inventory/products/productmaster/create"
                element={<CreateProductMaster />}
              />
              <Route
                path="purchasemanager/PurchaseReport/PurchaseAgingReport"
                element={<StockAgingReport />}
              />

              {/* sales new */}
              <Route
                path="sales/orders/quotation"
                element={<SalesQuotation />}
              />
              <Route
                path="sales/orders/quotation/create"
                element={<CreateSalesQuotation />}
              />
              <Route
                path="sales/orders/quotation/singleView"
                element={<SalesQuatationSingleVieww />}
              />

              <Route path="sales/orders/salesOrder" element={<SalesOrder />} />
              <Route
                path="sales/orders/salesOrder/create"
                element={<CreateSalesOrder />}
              />

              <Route
                path="sales/orders/salesOrder/singleView"
                element={<SalesOrderSingleView />}
              />
              <Route
                path="sales/Report/CustomerReceiptReport"
                element={<CustomerReceiptReport />}
              />
              <Route
                path="sales/Report/CustomerReceiptReportSingleView"
                element={<CustomerReceiptReportSingleView />}
              />

              <Route
                path="sales/orders/deliveryNote"
                element={<DeliveryNote />}
              />

              <Route
                path="sales/orders/customers"
                element={<Customers isSales={true} />}
              />
              <Route
                path="sales/orders/customers/create"
                element={
                  <SalesCustomers
                    isCustomer={true}
                    isVendor={false}
                    isSales={true}
                    isPurchase={false}
                  />
                }
              />

              <Route
                path="sales/orders/deliveryNote/SingleView"
                element={<DeliverNoteSingleView />}
              />
              <Route
                path="sales/orders/deliveryNote/create"
                element={<CreateDeliiveryNote />}
              />
              <Route
                path="sales/orders/pricelist/create"
                element={<CreatePriceList />}
              />
              <Route path="sales/orders/pricelist" element={<PriceList />} />
              <Route
                path="sales/Report/salesReport"
                element={<SalesNewReport />}
              />
              <Route
                path="sales/Report/salesReportSingleView"
                element={<SalesReportSingleView />}
              />
              <Route
                path="sales/Report/salesReportBySalesPerson"
                element={<SalesReportBySalesPerson />}
              />
              <Route
                path="sales/Report/salesReportBySalesSingleView"
                element={<SalesReportBySalesSingleView />}
              />

              {/* point of sale */}
              <Route
                path="pointOfSales/configuration/settings"
                element={<PosSettings />}
              />
              <Route
                path="pointOfSales/configuration/BranchSettings"
                element={<BranchSettings />}
              />
              <Route
                path="pointOfSales/configuration/BranchSettings/create"
                element={<BranchSettingsForm />}
              />

              {/* payroll section */}
              <Route path="payroll/Establishment" element={<EstablisId />} />
              <Route
                path="payroll/Attendance/Overtime"
                element={<OvertimeList />}
              />
              <Route
                path="payroll/configuration/payHead"
                element={<PayHead />}
              />
              <Route
                path="payroll/configuration/payOther"
                element={<PayOther />}
              />
              <Route
                path="payroll/contract"
                element={<Contract/>}
              />
              <Route
                path="payroll/sponser"
                element={<Sponser/>}
              />
             
              <Route path="invoiceView" element={<QrcodeScanView />} />
            </Routes>

            <SuccessSnackbar
              open={successSnack}
              handleClose={closeSnackbar}
              message={snackMsg}
            />
            <ErrorSnackbar
              open={errorSnack}
              handleClose={closeSnackbar}
              message={snackMsg}
            />
            <LoadingForm loading={submitLoading} />
            {/* <Typography>home</Typography> */}
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
