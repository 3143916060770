import React from "react";

import FixedAsset from "./ledgerGroup/FixedAsset";
import BankAccount from "./ledgerGroup/BankAccount";
import BankOverDraft from "./ledgerGroup/BankOverDraft";
import BranchOrDivision from "./ledgerGroup/BranchOrDivision";
import CurrentLiability from "./ledgerGroup/CurrentLiability";
import CashInHand from "./ledgerGroup/CashInHand";
import DirectExpenses from "./ledgerGroup/DirectExpenses";
import DutiesAndTaxes from "./ledgerGroup/DutiesAndTaxes";
import IndirectIncome from "./ledgerGroup/IndirectIncome";
import LongTermInvestment from "./ledgerGroup/LongTermInvestment";
import ReserveSurplus from "./ledgerGroup/ReserveSurplus";
import RetainedEarnings from "./ledgerGroup/RetainedEarnings";
import SecuredLoans from "./ledgerGroup/SecuredLoans";
import CapitalAccount from "./ledgerGroup/CapitalAccount";
import DepositAsset from "./ledgerGroup/DepositAsset";
import IndirectExpenses from "./ledgerGroup/IndirectExpenses";
import LoansLiability from "./ledgerGroup/LoansLiability";
import MiscExpensesAsset from "./ledgerGroup/MiscExpensesAsset";
import Provisions from "./ledgerGroup/Provisions";
import CostOfGoodsSold from "./ledgerGroup/CostOfGoodsSold";
import SalesAccount from "./ledgerGroup/SalesAccount";
import LoansAndAdvance from "./ledgerGroup/LoansAndAdvance";
import Customer from "./ledgerGroup/Customer";
import Suppliers from "./ledgerGroup/Suppliers";
import CurrentAsset from "./ledgerGroup/CurrentAsset";
import LongTermLiability from "./ledgerGroup/LongTermLiability";
import DirectIncome from "./ledgerGroup/DirectIncome";

const LedgerSection = ({ sectionName }) => {
  return (
    <>
      {sectionName === "fixed asset" && <FixedAsset />}
      {sectionName === "bank account" && <BankAccount />}
      {sectionName === "bank over draft" && <BankOverDraft />}
      {sectionName === "branch / division" && <BranchOrDivision />}
      {sectionName === "current liability" && <CurrentLiability />}
      {sectionName === "cash in hand" && <CashInHand />}
      {sectionName === "direct expenses" && <DirectExpenses />}
      {sectionName === "duties & taxes" && <DutiesAndTaxes />}
      {sectionName === "indirect income" && <IndirectIncome />}
      {sectionName === "long term investment" && <LongTermInvestment />}
      {sectionName === "reserve & surplus" && <ReserveSurplus />}
      {sectionName === "retained earnings" && <RetainedEarnings />}
      {sectionName === "secured loans" && <SecuredLoans />}
      {sectionName === "capital account" && <CapitalAccount />}
      {sectionName === "deposit asset" && <DepositAsset />}
      {sectionName === "indirect expenses" && <IndirectExpenses />}
      {sectionName === "loans liability" && <LoansLiability />}
      {sectionName === "misc. expenses asset" && <MiscExpensesAsset />}
      {sectionName === "provisions" && <Provisions />}
      {sectionName === "cost of goods sold" && <CostOfGoodsSold />}
      {sectionName === "sales account" && <SalesAccount />}
      {sectionName === "loans and advance" && <LoansAndAdvance />}
      {sectionName === "customer" && <Customer />}
      {sectionName === "suppliers" && <Suppliers />}
      {sectionName === "current asset" && <CurrentAsset />}
      {sectionName === "long term liability" && <LongTermLiability />}
      {sectionName === "direct income" && <DirectIncome />}
    </>
  );
};

export default LedgerSection;





