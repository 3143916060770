import { createSlice } from "@reduxjs/toolkit";
export const newWorkOrderResponseSlice = createSlice({
  name: "newWorkOrderResponseSlice",
  initialState: {
    value: undefined,
  },
  reducers: {
    update_new_work_order_response: (state, action) => {
      state.value = action.payload.newWORes;
    }
  },
});
export const { update_new_work_order_response } = newWorkOrderResponseSlice.actions;
export default newWorkOrderResponseSlice.reducer;
