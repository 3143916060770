import axios from 'axios'
import {HEADERS} from '../UrlAndPaths'
import {get_exp_payment_type, get_staff_expense} from '../../Redux/Expense/StaffExpense/staffExpenseSlice'
import store from '../../Redux/store'

// view staff expense 
export const viewStaffExpenseAPICall= async (body,setIsLoading,setErrorSnack,setSnackMsg)=>{
  if(setIsLoading!==undefined) setIsLoading(true)
  await axios.post("expense/viewExpense",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
        store.dispatch(get_staff_expense({staffExpenseData:res.data}))
        if(setIsLoading!==undefined) setIsLoading(false)
    }
  })
  .catch((err)=>{
    store.dispatch(get_staff_expense({staffExpenseData:undefined}))
    console.log(err.response);
    switch (err.response.status) {
      case 404:
          setSnackMsg('No data available..')
        break;
      case 500:
          setSnackMsg('Server error!!')
        break;
      case 401:
          setSnackMsg('Unauthorized!!')
        break;
      case 403:
          setSnackMsg('Forbidden!!')
        break;
    
      default:
          setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
        break;
    }
    setErrorSnack(true)
    if(setIsLoading!==undefined) setIsLoading(false)
  })
}

// add expense type
export const addStaffExpenseAPICall= async (
  body,
  updateListener,
  setSuccessSnackbar,
  setErrorSnackbar,
  setSnackMsg,setLoadingForm,setAddExpense
  )=>{
    setLoadingForm!==undefined && setLoadingForm(true)
  await axios.post("expense/addExpense",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      setSuccessSnackbar(true)
      setSnackMsg('Staff Expense Added Successfully')
      updateListener()
      setAddExpense(false)
      setLoadingForm!==undefined && setLoadingForm(false)
    }
  })
  .catch((err)=>{
    setLoadingForm!==undefined && setLoadingForm(false)
    setErrorSnackbar(true)
    if (err.response === undefined) {
      setSnackMsg("Network Connection error!!");
    }
    if (err.response.status === 409) {
      setSnackMsg("Category Already Exist");
    }

    if (err.response.status === 401) {
      setSnackMsg("Unauthorized");
    }
    if (err.response.status === 403) {
      setSnackMsg("Forbidden");
    }
    console.log(err.response);
  })
}

// edit expense type
export const editStaffExpenseAPICall= async (
  body,
  updateListener,
  setSuccessSnackbar,
  setErrorSnackbar,
  setSnackMsg,setLoadingForm,setEditExpDialog
  )=>{
    setLoadingForm!==undefined && setLoadingForm(true)
 await axios.put("expense/editExpense",body,HEADERS)
 .then((res)=>{
   if(res.status===200){
    setSuccessSnackbar(true)
    setSnackMsg('updated Successfully...')
    updateListener()
    setLoadingForm!==undefined && setLoadingForm(false)
    setEditExpDialog(false)
   }
 })
 .catch((err)=>{
  setLoadingForm!==undefined && setLoadingForm(false)
  setErrorSnackbar(true)
  if (err.response.status === undefined) {
    setSnackMsg("Network Connection error!!");
  }
  else if (err.response.status === 409) {
    setSnackMsg("Category Already Exist");
  }

  else if (err.response.status === 401) {
    setSnackMsg("Unauthorized");
  }
  else if (err.response.status === 403) {
    setSnackMsg("Forbidden");
  }
  else if(err.response.status===500){
    setSnackMsg("Internal server error !!");
  }
  else{
    setSnackMsg(err.response.data);
  }

 })
}

//payment type
export const viewExpensePaymentType=()=>{
  axios.post("expense/pettyCashEnabledJournal",{},HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_exp_payment_type({expPaymentTypeData:res.data}))
    }
  })
  .catch((err)=>{
    console.log(err.response);
  })
}