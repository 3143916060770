import React, { useState, useEffect } from "react";
import "../../../../../css/Purchase Manager/Transfer/transfer.css";
import {
  IconButton,
  Dialog,
  Autocomplete,
  TextField,
  Skeleton,
} from "@mui/material";
import { generateTransferIdAPICall } from "../../../../../API/Purchase Manager/transferAPI";
import { useSelector } from "react-redux";
import { viewAllBranchesAPICall } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import { viewAllProductsAPICall } from "../../../../../API/Product List/productListAPI";
import { calculateTotalCost } from "../../../../../Js/generalFunctions";
import { convertDateFormat, today } from "../../../../../Js/Date";
import {
  viewAllTransferList,
  addTransferAPICall,
  transferSingleViewAPICall
} from '../../../../../API/Purchase Manager/transferAPI'
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
export const Transfer = () => {
  // generate transfer id
  const transferId = useSelector((state) => state.transferIdSlice.value);
  //List of all Branches
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  // login response
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  // role based rendering
  const userRole = useSelector((state) => state.userRoleSlice.value);
  //All products list
  const allProductsList = useSelector(
    (state) => state.allProductsListSlice.value
  );
  // all transfer list 
  const allTransferList = useSelector((state)=>state.allTransferListSlice.value)
  // transfer single view 
  const transferSingleViewData=useSelector((state)=>state.transferSingleViewSlice.value)
  
  const storeCode=localStorage.getItem('branchId')
  
  const [isModify,setIsModify]=useState(false)
  const [addTransferDialog, setAddTransferDialog] = useState(false);
  const [transferSingleView,setTransferSingleView]=useState(false)
  const [itemType, setItemType] = useState(2);
  const [product, setProduct] = useState(null);
  const addTransferForm1InitialState = {
    fromLoc:'',
    toLoc: "",
    date: today,
    margin: "",
    marginType: 0,
    remarks: "",
  };
  const [addTransferForm1, setAddTransferForm1] = useState(
    addTransferForm1InitialState
  );
  const [isLoading,setIsLoading]=useState(false)
  const [snackMsg, setSnackMsg] = useState("oops!!!");
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [searchKeyword,setSearchKeyword]=useState("")
  const [filteredSearchList,setFilteredSearchList]=useState([])
  const closeErrorSnack = () => {
    setErrorSnackOpen(false);
  };
  const AddTransferForm2InitialState = {
    //  itemType:2,
    productId: "",
    productName: "",
    size: "",
    stock: "",
    unit: "",
    unitId:'',
    transferQty: "",
    unitCost: "",
    totalCost: 0,
    spMargin: "",
    outletCost: 0,
  };
  const [addTransferForm2, setAddTransferForm2] = useState(
    AddTransferForm2InitialState
  );
  const [extractedSize, setExtractedSize] = useState([]);
  const [tableDataForFrontEnd, setTableDataForFrontEnd] = useState([]);
  const [tableDataForBackEnd,setTableDataForBackEnd]=useState([])
  const [selectedFromLocation,setSelectedFromLocation]=useState(null)
  const [selectedToLocation,setSelectedToLocation]=useState(null)
  const [filteredBranchList,setFilteredBranchList]=useState([])

  // validation states
  const [fromLocAlert,setFromLocAlert]=useState(false)
  const [toLocAlert,setToLocAlert]=useState(false)
  const [marginAlert,setMarginAlert]=useState(false)
  const [addProductAlert,setAddProductAlert]=useState(false)
  const [productNameAlert,setProductNameAlert]=useState(false)
  const [transferQtyAlert,setTransferQtyAlert]=useState(false)
  const [unitCostAlert,setUnitCostAlert]=useState(false)
  const [marginSpAlert,setMarginSpAlert]=useState(false)
  const [productSizeAlert,setProductSizeAlert]=useState(false)


  const updateListner =()=>{
    setIsModify(!isModify)
  }
  useEffect(() => {
    generateTransferIdAPICall();
    viewAllTransferList(setIsLoading,setErrorSnackOpen,setSnackMsg)
    viewAllBranchesAPICall();
  },[isModify]);
    
  useEffect(() => {
    viewAllProductsAPICall({ type: itemType ,branchId:storeCode});
  }, [itemType]);

  // extract fromlocation from list

  useEffect(()=>{
    let filteredFromDate=[]
    if(allBranchesList !== null){
      filteredFromDate=allBranchesList?.filter((obj)=>{
        if(obj._id===loginResponse?.branchPk){
          return obj
        } 
     })
     if(filteredFromDate!==undefined) setSelectedFromLocation(filteredFromDate[0])
    }
     
  },[loginResponse?.branchPk,allBranchesList])
  
  

// branch filter
// console.log(selectedFromLocation);
useEffect(()=>{
  let filterData=[]
  filterData=allBranchesList?.filter((obj)=>{
    if(obj._id !== selectedFromLocation?._id){
      return obj
    }
  })
   
   setFilteredBranchList(filterData)
},[selectedFromLocation])
  

  

  // onchange functions
  const getAddTransferForm1 = (key) => (e) => {
    const { value } = e.target;
    if (key === "date") {
      setAddTransferForm1({ ...addTransferForm1, date: value });
    }
    if (key === "margin") {
      setAddTransferForm1({ ...addTransferForm1, margin: parseFloat(value) });
      setAddTransferForm2({
        ...addTransferForm2,
         spMargin:parseFloat(value)
      });
      setMarginAlert(false)
      if(value===""){
        setMarginAlert(true)        
      }
    }
    if (key === "remarks") {
      setAddTransferForm1({ ...addTransferForm1, remarks: value });
    }
    if (key === "marginType") {
      setAddTransferForm1({ ...addTransferForm1, marginType: value });
    }
  };

  const getAddTransferForm2 = (key) => (e) => {
    const { value } = e.target;
    const limit = addTransferForm2?.stock;
    if (key === "transferQty") {
      if (value > limit) {
        setAddTransferForm2({ ...addTransferForm2, transferQty: limit });
      } else {
        setAddTransferForm2({
          ...addTransferForm2,
          transferQty: parseFloat(value),
        });
      }
      setTransferQtyAlert(false)
      if(value===""){
        setTransferQtyAlert(true)
      }
    }
    if (key === "unitCost") {
      setAddTransferForm2({ ...addTransferForm2, unitCost: parseFloat(value) });
      setUnitCostAlert(false)
      if(value===""){
        setUnitCostAlert(true)
      }
    }
    if (key === "spMargin") {
      setAddTransferForm2({ ...addTransferForm2, spMargin: parseFloat(value) });
      setMarginSpAlert(false)
      if(value===""){
        setMarginSpAlert(true)
      }
    }
  };

  //radio change function
  const radioChangeFn = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setItemType(value);
    }
  };

  // add transfer dialog open function
  const openAddTransferDialog=()=>{
    setAddTransferDialog(true)
    setAddTransferForm1({...addTransferForm1,
      toLoc:"",
      margin:""
    })
    setProduct(null)
    setTableDataForFrontEnd([])
    setTableDataForBackEnd([])
  }

  // extract product id and product name from product
  useEffect(() => {
    setAddTransferForm2({
      ...addTransferForm2,
      productId: product?._id,
      productName: product?.productName,
    });
  }, [product]);

  // extract size from product list
  useEffect(() => {
    let extractedSizeArray = allProductsList?.filter((obj) => {
      if (obj._id === addTransferForm2?.productId) {
        return obj;
      }
    });
    //  console.log(extractedSizeArray);
    setExtractedSize(extractedSizeArray);
  }, [addTransferForm2?.productId]);

  // assign  product qty to state
  useEffect(() => {
    setAddTransferForm2({
      ...addTransferForm2,
      stock: extractedSize !== undefined ? extractedSize[0]?.stock : 0,
      unit: extractedSize !== undefined ? extractedSize[0]?.unitName : "",
      unitId:extractedSize !== undefined ? extractedSize[0]?.unit : ""
    });
  }, [extractedSize]);

  // calculate total cost
  useEffect(() => {
    let totalCost = calculateTotalCost(
      addTransferForm2?.unitCost,
      addTransferForm2?.transferQty
    );
    setAddTransferForm2({ ...addTransferForm2, totalCost: totalCost });
  }, [addTransferForm2?.unitCost, addTransferForm2?.transferQty]);

 
  // calculate outlet cost
  const calculateOutletCost = () => {
    let outletCost = 0;
    
    if (addTransferForm1.marginType == 0) {
      outletCost =
        parseFloat(addTransferForm2?.totalCost) +
        parseFloat(addTransferForm2?.spMargin);
    } else if (addTransferForm1.marginType == 1) {
      outletCost =
        parseFloat(addTransferForm2?.totalCost) +
        (parseFloat(addTransferForm2?.totalCost) *
          parseFloat(addTransferForm2?.spMargin)) /
          100;
    }


    setAddTransferForm2({
      ...addTransferForm2,
      outletCost: isNaN(outletCost) ? 0 : outletCost,
    });
  };

  useEffect(() => {
    calculateOutletCost();
  }, [
    addTransferForm2?.totalCost,
    addTransferForm2?.spMargin,
    addTransferForm1.marginType,
    addTransferForm1?.margin,
  ]);
  // clear states

  const clearProductDet=()=>{
        // setProduct(null)
      setAddTransferForm2({
        ...addTransferForm2,
        
        transferQty:"",
        unitCost:"",
        totalCost:"",
        outletCost:"",
        spMargin:""
      })
  }
  const cancelTransferClick=()=>{
    setAddTransferDialog(false)
    clearProductDet()
  }
  // add transfer plus button function
  const addTransferPlusBtnFn = () => {
    if (product === null || product === undefined) {
      setProductNameAlert(true)
    }
    else if(itemType != 2 &&addTransferForm2?.size===""){
      setProductSizeAlert(true)
    }  else if (addTransferForm2?.transferQty === "") {
      setTransferQtyAlert(true)
    } else if (addTransferForm2?.unitCost === "") {
      setUnitCostAlert(true)
    } 
    else if(addTransferForm2?.spMargin === ""){
      setMarginSpAlert(true)
    }
    else {
      setTableDataForFrontEnd([...tableDataForFrontEnd, addTransferForm2]);
      setTableDataForBackEnd([...tableDataForBackEnd,{
        itemId:addTransferForm2?.productId,
        itemName:addTransferForm2?.productName,
        itemType:parseFloat(itemType),
        dimension:addTransferForm2?.size,
        transferQty:addTransferForm2?.transferQty,
        receivedQty:0,
        unitCost:addTransferForm2?.unitCost,
        unit:addTransferForm2?.unitId,
        spMargin:addTransferForm2?.spMargin,
        status:"Pending"
      }])
      clearProductDet();
      setAddProductAlert(false)
      // setAddTransferForm2(AddTransferForm2InitialState)
    }
  };

  // delete selected row from table 
  const deleteTableRow =(index)=>()=>{
    tableDataForFrontEnd.splice(index,1)
    setTableDataForFrontEnd([...tableDataForFrontEnd])
    tableDataForBackEnd.splice(index,1)
    setTableDataForBackEnd([...tableDataForBackEnd])
  }


  const bodyToApi={
    fromLoc:selectedFromLocation?.storeCode,
    toLoc:selectedToLocation?.storeCode,
    date:addTransferForm1?.date,
    remarks:addTransferForm1?.remarks,
    margin:addTransferForm1?.margin,
    marginType:addTransferForm1?.marginType,
    transferItems:tableDataForBackEnd

  }
  // add transfer submit button function
  const addTransferSubmitBtnFn =()=>{
    if(selectedFromLocation===null || selectedFromLocation === undefined ){
      setFromLocAlert(true)
    }
    else if(selectedToLocation===null || selectedToLocation=== undefined){
      setToLocAlert(true)
    }
    else if(addTransferForm1?.margin===""){
      setMarginAlert(true)
    }
    else if(tableDataForFrontEnd?.length===0){
      setAddProductAlert(true)
    }
    else{
      // console.log(bodyToApi);
      addTransferAPICall(bodyToApi,setAddTransferDialog,updateListner)
    }
    
  }

// transfer single view
// open transfer single view dialog
  const openTransferSingleViewDialog=(id)=>{
      setTransferSingleView(true)
      transferSingleViewAPICall({id})
  } 
  const getSearchKeyword=(e)=>{
    setSearchKeyword(e.target.value)
  }
  const SearchFilter=(chars,listToFilter)=>{
    let searchedArray=[]
    let filteredArray=[]
    if(chars!==""){
      searchedArray=listToFilter.filter((obj)=>
      obj?.transNo?.toLowerCase()?.includes(chars?.toLowerCase())||
      obj?.fromLoc?.toLowerCase()?.includes(chars?.toLowerCase())||
      obj?.toLoc?.toLowerCase()?.includes(chars?.toLowerCase())
    )
      filteredArray=searchedArray
    }
    else{
      filteredArray=listToFilter
    }
    return filteredArray
  }
  useEffect(()=>{
    if(allTransferList!==undefined){
      let filteredArray=[]
      setFilteredSearchList(allTransferList)
      filteredArray=SearchFilter(searchKeyword,allTransferList)
      setFilteredSearchList(filteredArray)    
    }
    else{
      setFilteredSearchList([])
    }
    },[searchKeyword,allTransferList])


  return (
    <div className="global-page-parent-container">
      <p className="breadcrumb">Purchase Manage &gt; Transfer &gt; Transfer</p>
      <div className="transfer-container">
        <div className="transfer-top-container">
          <h3>Transfer</h3>
          <div className="supplier-manage-top-container-right">
              <div className="view-order-search-container view-order-admin-search-container">
                  <input
                    placeholder="Supplier name,mobile,email"
                     value={searchKeyword}
                     onChange={getSearchKeyword}
                  />
                  <IconButton>
                      <i class="bi bi-search search-icon-order"></i>
                  </IconButton>
              </div>
              <button style={{width:"25%"}}
            onClick={openAddTransferDialog}
            className="btn btn-primary"
          >
            New Transfer
          </button>
            </div>
          
        </div>
        <div className="global-table-container transfer-table">
          <table className="global-table">
            <thead>
              <tr>
                <th>No</th>
                <th>Transaction No</th>
                <th>Location From</th>
                <th>Location To </th>
                <th>Date</th>
                <th>Margin</th>
                <th>Status</th>
                {/* <th>Edit</th> */}
                <th>View</th>
              </tr>
            </thead>
            {
            isLoading?
              <tbody>
                  <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr>
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr>
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr>
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr>
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr> 
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr> 
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr> 
                          <tr>
                          <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                          </td>
                          </tr>
              </tbody>:
            <tbody>
              {filteredSearchList?.length!==0?
                filteredSearchList?.slice(0)?.reverse()?.map((r,i)=>(
                  <tr key={i}>
                <td>{i+1}</td>
                <td>{r?.transNo}</td>
                <td>{r?.fromLoc}</td>
                <td>{r?.toLoc}</td>
                <td>{convertDateFormat(r?.transferDate)}</td>
                <td>{r?.margin}</td>
                <td>
                  <p 
                  className={
                    r?.status==="Pending"?
                  "status-pending"
                  :r?.status==="Confirmed"?
                   "status-confirm"
                   :r?.status ==="Completed"?
                   "status-completed"
                   :undefined
                  }
                  >{r?.status}</p>
                </td>
                {/* <td>
                  <IconButton>
                    <i class="bi bi-pencil-square edit-icon"></i>
                  </IconButton>
                </td> */}
                <td>
                  <IconButton onClick={()=>openTransferSingleViewDialog(r?._id)}>
                    <i class="bi bi-eye visibility-icon"></i>
                  </IconButton>
                </td>
              </tr>
                ))
              :<tr><td colSpan={8} style={{textAlign:"center"}}>No Records</td></tr>}
            
            </tbody>}
          </table>
        </div>
      </div>
      {/* add transfer dialog */}
      <Dialog open={addTransferDialog} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setAddTransferDialog(false)}>
        <div className="add-transfer-container">
          <h3>Add Transfer</h3>
          <hr className="global-hr" />
          <div className="add-transfer-input-container">
            <div className="global-single-input">
              <p>Transaction ID</p>
              <input value={`${transferId?.prefix}${transferId?.transNo}`} />
            </div>
            <div className="global-single-input auto-complete" style={{position:"relative"}}>
              <p>Location From</p>
              {/* <input type="text" value={addTransferForm1?.fromLoc} /> */}
              <Autocomplete
                disabled={userRole === 'user'}
                sx={{ width: "100%" }}
                options={ allBranchesList|| [""]}
                getOptionLabel={(option) =>
                  `${option?.storeCode}-${option?.branchName}`
                }
                renderInput={(params) => <TextField {...params} />}
                value={selectedFromLocation}
                onChange={(e, newValue) =>{
                  setSelectedFromLocation(newValue)
                  setFromLocAlert(false) 
                  if(newValue===null){
                    setFromLocAlert(true) 
                  }
                }
                }
              />
              {
                fromLocAlert?
                <p className="doc-validation-alert">Select a branch</p>:""
              }
            </div>
            <div className="global-single-input auto-complete" style={{position:"relative"}}>
              <p>Location To</p>
              <Autocomplete
                sx={{ width: "100%" }}
                options={filteredBranchList || [""]}
                getOptionLabel={(option) =>
                  `${option?.storeCode}-${option?.branchName}`
                }
                renderInput={(params) => <TextField {...params} 
                placeholder="Select Location"
                />}
                onChange={(e, newValue) =>{
                  setSelectedToLocation(newValue)
                  setToLocAlert(false) 
                  if(newValue===null){
                    setToLocAlert(true) 
                  }
                  }
                }
              />
              {
                toLocAlert?
                <p className="doc-validation-alert">Select a branch</p>:""
              }
            </div>
            <div className="global-single-input">
              <p>Date</p>
              <input type="date" value={addTransferForm1?.date} onChange={getAddTransferForm1("date")} />
            </div>
            <div className="global-single-input" style={{position:"relative"}}>
              <p>Margin</p>
              <input
                placeholder="Enter Margin..."
                type="Number"
                min={0}
                onWheel={(e) => e.target.blur()}
                value={isNaN(addTransferForm1?.margin)?'':addTransferForm1?.margin}
                onChange={getAddTransferForm1("margin")}
              />
              <div className="add-transfer-checkbox-container">
                <div className="add-transfer-checkbox">
                  <input
                    value={0}
                    defaultChecked
                    onChange={getAddTransferForm1("marginType")}
                    type="radio"
                    id="transferAmount"
                    name="spMarginType"
                  />
                  <label htmlFor="transferAmount">Amount</label>
                </div>
                <div className="add-transfer-checkbox">
                  <input
                    value={1}
                    onChange={getAddTransferForm1("marginType")}
                    type="radio"
                    id="transferPercentage"
                    name="spMarginType"
                  />
                  <label htmlFor="transferPercentage">Percentage %</label>
                </div>
              </div>
              {
                marginAlert?
                <p className="doc-validation-alert">Enter a margin</p>:""
              }
            </div>
            <div className="global-single-input">
              <p>remarks</p>
              <textarea
              placeholder="Enter Remarks..."
                value={addTransferForm1?.remarks}
                onChange={getAddTransferForm1("remarks")}
              ></textarea>
            </div>
          </div>

          <div className="add-transfer-radio-btn-container">
              {addProductAlert?
              <p className="purchase-wopo-add-product-validation" style={{padding:"0"}}>
                add product details !!</p>
             :""}
            <div className="add-transfer-radio-btn">
              <input
                defaultChecked
                value={2}
                onChange={radioChangeFn}
                type="radio"
                id="transferMaterial"
                name="addTransfer"
              />
              <label htmlFor="transferMaterial">Material</label>
            </div>
            <div className="add-transfer-radio-btn">
              <input
                value={0}
                onChange={radioChangeFn}
                type="radio"
                id="transferReadymade"
                name="addTransfer"
              />
              <label htmlFor="transferReadymade">Readymade</label>
            </div>
            <div className="add-transfer-radio-btn">
              <input
                value={1}
                onChange={radioChangeFn}
                type="radio"
                id="transferAccessories"
                name="addTransfer"
              />
              <label htmlFor="transferAccessories">Accessories</label>
            </div>
          </div>

          <div className="add-transfer-input-second-container">
            <div className="add-transfer-input-container-second">
              <div className="global-single-input add-transfer-product-name auto-complete">
                <p>Product Name</p>
                <Autocomplete
                  sx={{ width: "100%" }}
                  options={allProductsList || [""]}
                  getOptionLabel={(option) =>
                    `${option?.productId}-${option?.productName}`
                  }
                  renderInput={(params) => <TextField 
                    {...params} 
                    placeholder="Select Product"
                    />}
                  onChange={(e, newValue) => {
                            setProduct(newValue)
                            setProductNameAlert(false)
                            if(newValue===null){
                              setProductNameAlert(true)
                            }
                          }}
                />
                {
                productNameAlert?
                <p className="doc-validation-alert">Select a product</p>:""
              }
              </div>
              {itemType != 2 && (
                <div className="global-single-input add-transfer-qty auto-complete">
                  <p>Size</p>
                  <Autocomplete
                    sx={{ width: "100%" }}
                    options={
                      (extractedSize !== undefined &&
                        extractedSize[0]?.size) || [""]
                    }
                    getOptionLabel={(option) => option?.brandUnit}
                    renderInput={(params) => <TextField {...params}
                    placeholder="Select Size"
                    />}
                    onChange={(e, newValue) =>{
                      setProductSizeAlert(false)
                      setAddTransferForm2({
                        ...addTransferForm2,
                        size: newValue?.brandUnit,
                      })
                      if(newValue===null){
                        setProductSizeAlert(true)
                      }
                    }
                    }
                  />
                  {
                    productSizeAlert?
                    <p className="doc-validation-alert">Select a size</p>:""
                  }
                </div>
              )}
              <div className="global-single-input add-transfer-qty">
                <p>Stock Qty</p>
                <input
                  disabled
                  value={addTransferForm2?.stock}
                  // value={extractedSize !== undefined ? extractedSize[0]?.stock :0}
                />
              </div>
              <div className="global-single-input add-transfer-unit">
                <p>Unit</p>
                <input disabled value={addTransferForm2?.unit} />
              </div>
              <div className="global-single-input add-transfer-trns-qty">
                <p>Transfer Qty</p>
                <input
                type="number"
                min={0}
                onWheel={(e) => e.target.blur()}
                placeholder="Enter Qty..."
                  value={
                     isNaN(addTransferForm2?.transferQty)
                      ? ""
                     : addTransferForm2?.transferQty
                  }
                  onChange={getAddTransferForm2("transferQty")}
                />
                  {
                    transferQtyAlert?
                    <p className="doc-validation-alert">Fill this field!!</p>:""
                  }
              </div>
              <div className="global-single-input add-transfer-qty">
                <p>Unit Cost</p>
                <input
                  placeholder="Enter Cost"
                  type="Number"
                  min={0}
                  onWheel={(e) => e.target.blur()}
                  value={
                    isNaN(addTransferForm2?.unitCost)
                      ? ""
                      : 
                      addTransferForm2?.unitCost
                  }
                  onChange={getAddTransferForm2("unitCost")}
                />
                {
                  unitCostAlert?
                  <p className="doc-validation-alert">Fill this field!!</p>:""
                }
              </div>
              <div className="global-single-input">
                <p>Total Cost</p>
                <input disabled value={addTransferForm2?.totalCost} />
              </div>
              <div className="global-single-input" style={{position:"relative"}}>
                <p>SP Margin</p>
                <input
                placeholder="Enter spMargin..."
                type="Number"
                min={0}
                onWheel={(e) => e.target.blur()}
                  value={
                    isNaN(addTransferForm2?.spMargin)
                      ? ""
                      : addTransferForm2?.spMargin
                  }
                  onChange={getAddTransferForm2("spMargin")}
                />
                {
                  marginSpAlert?
                  <p className="doc-validation-alert">Fill this field!!</p>:""
                }
              </div>
              <div className="global-single-input">
                <p>Outlet Cost</p>
                <input value={addTransferForm2?.outletCost} />
              </div>
            </div>
            <div className="add-transfer-add-button-container">
              <button
                onClick={addTransferPlusBtnFn}
                className="add-transfer-add-button"
              >
                +
              </button>
            </div>
          </div>
          <div className="global-table-container add-transfer-table">
            <table className="global-table ">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Product Name</th>
                  <th>Size</th>
                  <th>Stock Qty</th>
                  <th>Unit</th>
                  <th>Transfer Qty</th>
                  <th>Unit Cost</th>
                  <th>Total Cost</th>
                  <th>SP Margin</th>
                  <th>Outlet Cost</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {tableDataForFrontEnd.length !== 0 ? (
                  tableDataForFrontEnd?.map((r, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{r?.productName}</td>
                      <td>{r?.size || "NILL"}</td>
                      <td>{r?.stock}</td>
                      <td>{r?.unit}</td>
                      <td>{r?.transferQty}</td>
                      <td>{r?.unitCost}</td>
                      <td>{r?.totalCost}</td>
                      <td>{r?.spMargin}</td>
                      <td>{r?.outletCost}</td>
                      <td>
                        <IconButton>
                          <i class="bi bi-pencil-square edit-icon"></i>
                        </IconButton>
                      </td>
                      <td>
                        <IconButton onClick={deleteTableRow(i)}>
                          <i class="bi bi-trash3 delete-icon"></i>
                        </IconButton>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={12}>No Data</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="add-transfer-button-container">
            <button
              onClick={cancelTransferClick}
              className="btn btn-secondary-outlined"
            >
              Cancel
            </button>
            <button onClick={addTransferSubmitBtnFn} className="btn btn-primary">Submit</button>
          </div>
        </div>
      </Dialog>
      {/* transfer single view dialog */}
      <Dialog open={transferSingleView} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setTransferSingleView(false)}>
      <div className="stock-adjustment-list-view-container">
          <h3>View</h3>
          <div className="stock-adjustment-list-view-input-container">
            <div className="global-single-input">
              <p>Transaction ID</p>
              <input disabled value={transferSingleViewData?.transNo}/>
            </div>
            <div className="global-single-input">
              <p>Location From</p>
              <input disabled  value={transferSingleViewData?.fromLoc}/>
            </div>
            <div className="global-single-input">
              <p>Location To</p>
              <input disabled  value={transferSingleViewData?.toLoc}/>
            </div>
            <div className="global-single-input">
              <p>Date</p>
              <input disabled value={transferSingleViewData?.transferDate}/>
            </div>
           
          </div>
          <hr className="global-hr-dotted" />
          <div className="global-table-container stock-adjustment-list-view-table">
            <table className="global-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Product Name</th>
                  <th>Stock Qty</th>
                  <th>Transfer Qty</th>
                  <th>Unit</th>
                  <th>Unit Cost</th>
                  <th>Total Cost</th>
                  <th>Sp Margin</th>
                  <th>Outlet Cost</th>
                </tr>
              </thead>
              <tbody>
               {
                transferSingleViewData?.transferItems?.map((r,i)=>(
                  <tr key={i}>
                    <td>{i+1}</td>
                    <td>{r?.itemName}</td>
                    <td>{r?.stockQty}</td>
                    <td>{r?.transferQty}</td>
                    <td>{r?.unit}</td>
                    <td>{r?.unitCost}</td>
                    <td>{r?.totalCost}</td>
                    <td>{r?.spMargin}</td>
                    <td>{r?.outletCost}</td>
                  </tr>
                ))
               }
              </tbody>
            </table>
          </div>
          <div className="stock-adjustment-list-view-button-container">
            <button
              onClick={() =>setTransferSingleView(false)}
              className="btn btn-secondary-outlined"
            >
              Close
            </button>
            {/* <button className="btn btn-secondary">Submit</button> */}
          </div>
        </div>
      </Dialog>
      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnack}
        message={snackMsg}
      />
    </div>
  );
};
