import { createSlice } from "@reduxjs/toolkit";

export const allAlterationSlice=createSlice({
    name:"allAlterationSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        update_alteration_list:(state,action)=>{
            state.value=action.payload.alterationList
        }
    }
})

export const {update_alteration_list}=allAlterationSlice.actions
export default allAlterationSlice.reducer