import { createSlice } from "@reduxjs/toolkit";

export const expenseGraphSlice=createSlice({
    name:'expenseGraphSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_expenseGraph:(state,action)=>{
            state.value=action.payload.expenseGraphData
        }
    }
})

export const {update_expenseGraph}=expenseGraphSlice.actions
export default expenseGraphSlice.reducer