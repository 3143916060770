import React, { useEffect, useRef, useState } from "react";
import { getCurrentTime, previousDate, today } from "../../../../../Js/Date";
import { useSelector } from "react-redux";
import { convertArrayToExcel, filterObjFromList } from "../../../../../Js/generalFunctions";
import { viewAllCurrencyUpdated } from "../../../../../API/Settings/Currency/CurrencyAPI";
import { companyProfileInfoForUser } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import { IconButton, TextField, Tooltip } from "@mui/material";
import { AccountPayableAPIcall } from "./partnerReportApi";
import { useNavigate } from "react-router-dom";
import Template from "./AccountPrint"
import ReactToPrint from "react-to-print";


export const AccountPayable = () => {
  let paymentsPrintNew = useRef();

  const configurationList = useSelector(
    (state) => state.glConfigurationSlice.apiResList
  );
  let navigate = useNavigate();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [symbol, setSymbol] = useState(null);

  const decimalPosition = localStorage.getItem("decimalPosition");

  const accountpayable = useSelector((state) => state?.PartnerReport?.AccountPayable)

  console.log(accountpayable);
  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );

  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );

  const initialvalue = {
    label: "Account Payable Report",
    totalBalance: 0,
    totalCredit: 0,
    totalDebit: 0,

    companyLogo: "",
    companyName: "",
    companyAddress: "",
    companyContact: "",
  }

  const [data, setData] = useState([])
  const [formValues, setFormValues] = useState(initialvalue)

  useEffect(() => {
    setFormValues({
      ...formValues,
      totalBalance: accountpayable?.totalBalance || 0,
      totalCredit: accountpayable?.totalCredit || 0,
      totalDebit: accountpayable?.totalDebit || 0,

      companyLogo: companyProfileData?.logo || "",
      companyName: companyProfileData?.companyName || "",
      companyAddress: companyProfileData?.companyAddress || "",
      companyContact: companyProfileData?.contactNumber || "",

    });
    setData(accountpayable?.vendors)
  }, [accountpayable, companyProfileData])

  const downloadList = () => {
    
    convertArrayToExcel(accountpayable?.vendors, "payableReport")
  }

  const handleClickRow = (row) => {
    localStorage.setItem("singleViewById", row?._id)
    localStorage.setItem("fromDate", `${fromDate} 00:00:00`);
    localStorage.setItem("toDate", `${toDate} 23:59:59`);
    localStorage.setItem("vendor", row?.vendorName)

    // setSelectedHeading("Ledger Balance");

    navigate("/userdashboard/accounts/report/partnerReport/accountPayable/singleView")
  }

  const getFromDate = (e) => {
    setFromDate(e.target.value);
  };
  const getToDate = (e) => {
    setToDate(e.target.value);
  };

  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    // console.log(currencyObj);
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);

  useEffect(() => {
    if (configurationList !== undefined) {
      setToDate(configurationList?.yearEndDate)
      setFromDate(configurationList?.yearStartDate)
    }

  }, [configurationList])

  // useEffect(()=>{
  //   AccountPayableAPIcall({startDate: `${fromDate} ${getCurrentTime()}`,
  //   endDate: `${toDate} ${getCurrentTime()}`})
  // },[fromDate, toDate]);

  useEffect(() => {
    if (toDate !== undefined && fromDate !== undefined) {
      const startDate = fromDate === '' ? '' : `${fromDate} 00:00:00`;
      const endDate = toDate === '' ? '' : `${toDate} 23:59:59`;

      AccountPayableAPIcall({
        startDate: startDate,
        endDate: endDate
      });
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, []);

  return (
    <div className="global-page-parent-container" style={{ margin: "0" }}>
      <div className="global-white-bg-container">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="justify-space-between" style={{ display: "flex", width: "40%", justifyContent: "normal" }}>
            <div className="sharp-arrow"> <p>Account Payable Report</p></div>
            {/* <h4>Account Payable Report</h4> */}
            <div style={{ marginLeft: "19px" }}>
              <Tooltip title="Share">
                <IconButton>
                  <i class="bi bi-share" style={{ backgroundColor: "#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
                </IconButton>
              </Tooltip>

              <ReactToPrint
                trigger={() => (
                  <IconButton className="printer-div" style={{ padding: "1px" }}>
                    <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
                  </IconButton>
                )}
                content={() => paymentsPrintNew}
              />

              <Tooltip title="Download">
                <IconButton onClick={downloadList}>
                  <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
                </IconButton>
              </Tooltip>

            </div>
          </div>

          <div className="date-con-div" style={{ marginRight: "-19px" }}>
            <div className='new-global-single-input' style={{ width: "41%" }}>
              <TextField
                value={fromDate}
                onChange={getFromDate}
                id="outlined-basic"
                label="From"
                variant="outlined"
                type="date"
                focused
                disabled={""}
                InputProps={{
                  inputProps: {
                    max: today,  // Assuming you want to set a max date
                  },
                  inputProps: {
                    style: { textAlign: "center" },  // Adjust the text alignment
                  },
                }} />
              <p className="doc-validation-alert">{""}</p>

            </div>

            <div className='new-global-single-input' style={{ width: "41%" }}>
              <TextField
                value={toDate}
                onChange={getToDate}
                id="outlined-basic"
                label="To"
                variant="outlined"
                type="date"
                focused
                disabled={""}
                InputProps={{
                  inputProps: {
                    max: today,  // Assuming you want to set a max date
                  },
                  inputProps: {
                    style: { textAlign: "center" },  // Adjust the text alignment
                  },
                }} />
              <p className="doc-validation-alert">{""}</p>

            </div>

          </div>
        </div>
        <div className="global-report-table-container" style={{ maxHeight: "65vh" }}>
          <table>
            <thead>
              <tr>
                <th>Vendor</th>
                <th style={{ width: "22%" }}>Debit</th>
                <th style={{ width: "22%" }}>Credit</th>
                <th style={{ width: "22%" }}>Balance</th>
              </tr>
            </thead>
            <tbody>
              {accountpayable?.vendors?.map((item, index) => (
                <tr key={index} onClick={() => handleClickRow(item)}>
                  <td style={{ textAlignLast: "start", paddingLeft: "10px" }}>{item.vendorName}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{symbol}{item.debit?.toFixed(decimalPosition)}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{symbol}{item.credit?.toFixed(decimalPosition)}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{symbol}{item.balance?.toFixed(decimalPosition)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th>Total</th>
                <th >{symbol}{accountpayable?.totalDebit?.toFixed(decimalPosition)}</th>
                <th>{symbol}{accountpayable?.totalCredit?.toFixed(decimalPosition)}</th>
                <th>{symbol}{accountpayable?.totalBalance?.toFixed(decimalPosition)}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div ref={(el) => (paymentsPrintNew = el)}>
          <Template data={data} formValues={formValues} />
        </div>
      </div>
    </div>
  );
};
