//Labels for Line Chart
//Days
export const todayLabels = [
  "1hrs",
  "2hrs",
  "3hrs",
  "4hrs",
  "5hrs",
  "6hrs",
  "7hrs",
  "8hrs",
  "9hrs",
  "10hrs",
  "11hrs",
  "12hrs",
  "13hrs",
  "14hrs",
  "15hrs",
  "16hrs",
  "17hrs",
  "18hrs",
  "19hrs",
  "20hrs",
  "21hrs",
  "22hrs",
  "23hrs",
  "24hrs",
];
//Week
export const weekLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
//Month
export const monthLabels = [
  // "Jan",
  // "Feb",
  // "Mar",
  // "Apr",
  // "May",
  // "Jun",
  // "Jul",
  // "Aug",
  // "Sep",
  // "Oct",
  // "Nov",
  // "Dec",
  "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31"
];


//Create Employee count doughnut data
export const createEmpCountDoughnutData=(response)=>{
    let data=[]

    if(response!==undefined)
    {
        const {female,male} =response
        data=[female,male]
        
    }
    return data
}