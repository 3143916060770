import { IconButton } from "@mui/material";
import React from "react";
import aadharDocImg from "../../../../Assets/Images/adharImage.png"
export const DocumentPrintView=()=>{


    return(
        <>
        <div className="global-page-parent-container">
        <div className="document-view-body-section" style={{background:'#fff',padding:'3%'}}>
        <h4>Driving License</h4>
        <div className="document-view-img-container">
          <img src={aadharDocImg} alt="ProofDoc" />
        </div>
        <button className="btn" style={{alignSelf:'center',marginTop:'3%'}}><IconButton><i class="bi bi-printer printer-icon"></i></IconButton></button>
        </div>
        </div>
        </>
    )
}