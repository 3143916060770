import React, { useEffect, useState } from "react";
import "../../../../../css/Account/reports/financialReports.css";
import "../../../../../css/Account/reports/inventoryReports.css";
import {
  Collapse,
  List,
  ListItemButton, 
  ListItemText,
  Typography,
} from "@mui/material";
import inventoryReportsGroup from "../../../../../Assets/JSON/inventoryReportsGroup.json";
import StockitemList from "./inventoryReportsGroup/StockitemList";
import StockitemMonthlySummary from "./inventoryReportsGroup/StockitemMonthlySummary";
import StockitemVoucherReport from "./inventoryReportsGroup/StockitemVoucherReport";
import LocationSummary from "./inventoryReportsGroup/LocationSummary";

const outstandingReportsGroup = [
  {
    id: 1,
    name: "Bill payable Statement",
  },
  {
    id: 2,
    name: "Bill receivable Statement",
  },
  {
    id: 3,
    name: "Group outstanding",
  },
  {
    id: 4,
    name: "Ledger outstanding",
  },
];
//rest data
const renderData = [
  {
    code: 1000,
    particular: "closing stock",
    opening: 5000,
    debit: 100,
    credit: 2000,
    closing: 3000,
    tableData: [
      {
        code: 1000,
        particular: "closing stock",
        opening: 5000,
        debit: 100,
        credit: 2000,
        closing: 3000,
      },
      {
        code: 1000,
        particular: "closing stock",
        opening: 5000,
        debit: 100,
        credit: 2000,
        closing: 3000,
      },
    ],
  },
  {
    code: 1021,
    particular: "opening stock",
    opening: 5000,
    debit: 100,
    credit: 2000,
    closing: 3000,
  },
  {
    code: 1058,
    particular: "shirt stock",
    opening: 5000,
    debit: 100,
    credit: 2000,
    closing: 3000,
    tableData: [
      {
        code: 1000,
        particular: "closing stock",
        opening: 5000,
        debit: 100,
        credit: 2000,
        closing: 3000,
      },
      {
        code: 1000,
        particular: "closing stock",
        opening: 5000,
        debit: 100,
        credit: 2000,
        closing: 3000,
      },
      {
        code: 1000,
        particular: "closing ",
        opening: 5000,
        debit: 100,
        credit: 2000,
        closing: 3000,
      },
    ],
  },
];

const InventoryReports = () => {
  const [active, setActive] = useState(0);
  const [dropListSelected, setDropListSelected] = useState(1);
  const [dropListSelectedName, setDropListSelectedName] = useState("");

  useEffect(() => {
    handleListItemClick(0, 1, "Stock item List");
  }, []);

  const [selectedReport, setSelectedReport] = useState({ id: null, name: "" });
  const [openReports, setOpenReports] = useState({
    finalReport: false,
    outstandingReport: false,
  });

  const setDropdownSelection = (id, name) => {
    setDropListSelected(id);
    setDropListSelectedName(name);
  };

  const handleListItemClick = (index, id, name) => {
    setActive(index);
      setOpenReports({ finalReport: false, outstandingReport: false });
      setSelectedReport({ id, name });
      setDropdownSelection(0, "");
   
  };

  const listItemTextStyle = () => {
    return {
      fontWeight: "600",
      fontSize: "0.9rem",
    };
  };
  return (
    <div className="global-page-parent-container">
      <p className="breadcrumb">
        Accounts &gt; Reports &gt; Inventory Reports &gt; {selectedReport.name}{" "}
        {openReports.finalReport && <span>&gt; {dropListSelectedName}</span>}
        {openReports.outstandingReport && (
          <span>&gt; {dropListSelectedName}</span>
        )}
      </p>

      <div className="accounting-container">
        <div className="accounting-side-menu">
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              marginBlockStart: "10px",
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {inventoryReportsGroup.map(({ id, name }, index) => (
              <React.Fragment key={index}>
                <ListItemButton
                  sx={{ paddingLeft: "2em" }}
                  className={active === index ? "isactive" : ""}
                  onClick={() => handleListItemClick(index, id, name)}
                >
                  <ListItemText
                    primary={
                      <Typography sx={listItemTextStyle}>{name}</Typography>
                    }
                  />
                </ListItemButton>
              </React.Fragment>
            ))}
          </List>
        </div>
        <div className="accounting-voucher-menu">
          {selectedReport.id === 1 && (
            <StockitemList
              renderData={renderData}
              selectListItem={selectedReport.name}
            />
          )}
          {selectedReport.id === 2 && (
            <StockitemMonthlySummary
              renderData={renderData}
              selectListItem={selectedReport.name}
            />
          )}
          {selectedReport.id === 3 && (
            <StockitemVoucherReport
              renderData={renderData}
              selectListItem={selectedReport.name}
            />
          )}
          {selectedReport.id === 4 && (
            <LocationSummary
              renderData={renderData}
              selectListItem={selectedReport.name}
            />
          )}
         
        </div>
      </div>
    </div>
  );
};

export default InventoryReports;
