import { createSlice } from "@reduxjs/toolkit";

export const viewDraftedSlice=createSlice({
   name:'grnDraftedList',
   initialState:{
    value:undefined
   },
   reducers:{
    get_grn_drafted_list:(state,action)=>{
        state.value=action.payload.grnDraftedList
    }
   }
})
export const {get_grn_drafted_list} = viewDraftedSlice.actions;
export default viewDraftedSlice.reducer