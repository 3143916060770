import { createSlice } from "@reduxjs/toolkit";

export const salesWSOSlice = createSlice({
  name: "salesWSOSlice",
  initialState: {
    apiResList: undefined,
    rowData:undefined,
    generateName:undefined,
    history:undefined
  },
  reducers: {
    get_sales_w_so_list: (state, action) => {
      state.apiResList = action.payload.salesWSOData;
    },
    get_sales_w_so_row_data_list: (state, action) => {
      state.rowData = action.payload.salesWSOsingleData;
    },
    generate_sales_w_so_name: (state, action) => {
      state.generateName = action.payload.salesWSOName;
    },
    get_salesWOSO_history:(state,action)=>{
      state.history=action.payload.salesWSOHistoryData
    }
  },
});

export const {
  get_sales_w_so_list,
  get_sales_w_so_row_data_list,
  generate_sales_w_so_name,
  get_salesWOSO_history
} = salesWSOSlice.actions;

export default salesWSOSlice.reducer;
