import React, { useState, useRef, useEffect } from "react";
import {
  Select,
  IconButton,
  MenuItem,
  Autocomplete,
  TextField,
  Skeleton,
} from "@mui/material";
import "../../../../css/Report/stockReport.css";
import ReactToPrint from "react-to-print";
import { stockReportAPICall } from "../../../../API/Report/stockReportAPI";
import {
  viewAllAllowedBranchesAPICall,
  viewAllBranchesAPICall,
  viewCompanyProfileInfoAPICall,
} from "../../../../API/Settings/Company Settings/companySettingsAPI";
import { useSelector } from "react-redux";
import { viewAllProductsAPICall } from "../../../../API/Product List/productListAPI";
import {
  today,
  currentTime,
  previousDate,
  convertDateFormat,
  getCurrentTime,
} from "../../../../Js/Date";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { filterObjFromList, sortGeneral } from "../../../../Js/generalFunctions";
import { vieWareHouseLocationAPICall } from "../../Inventory/Configuration/wareHouseLocationAPI";
import { productListForStockReportAPICall } from "../../Inventory/Products/inventoryProductsAPI";
import { categoryListAPICall } from "../../Inventory/Configuration/Category/CategoryAPI";
import { LoadingForm } from "../../../Single Components/LoadingForm";
import * as XLSX from "xlsx";

export const StockReport = () => {
  let stockReportPrint = useRef();
  let stockReportAdminReport = useRef();
  // login response
  // role based rendering
  const userRole = useSelector((state) => state.userRoleSlice.value);
  const stockReportData = useSelector((state) => state.stockReportSlice.value);
  // all branch list
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  // all product list
  const allProductsList = useSelector(
    (state) => state.inventoryProductSlice.productListForStockReport
  );
  //Company Profile Data
  const companyProfileData = useSelector(
    (state) => state.companyProfileSlice.value
  );
  const allowedBranchList = useSelector(
    (state) => state.allAllowedBranchesSlice.value
  );
  const storeCode = localStorage.getItem("branchId");

  const configurationList = useSelector((state) => state.inventoryConfigSettingSlice.apiResList)
  const wareHouseLocationList=useSelector((state)=>state.wareHouseLocationSlice.value)
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);


  // states
  const [branch, setBranch] = useState(null);
  const [branchLoc, setBranchLoc] = useState();
  const [productType, setProductType] = useState();
  const [productName, setProductName] = useState();
  const [fromDate, setFromDate] = useState(previousDate);
  const [toDate, setToDate] = useState(today);
  const [isLoading, setIsLoading] = useState(false);
  const [isAscending, setIsAscending] = useState(false);
  const [stockReportListSingle, setStockReportListSingle] = useState([]);
  const [time, setTime] = useState(null);
  const [stockReportFilterData, setStockReportFilterData] = useState([]);
  const [branchWithAll, setBranchWithAll] = useState([]);
  const [stockCostQty, setStockCostQty] = useState("0");
  const [isInactive, setIsInactive] = useState(false);

  const [loadingForm,setLoadingForm]=useState(false)


  const changeStockCostQty = (key) => (e) => {
    if (key === "cost") {
      if (e.target.checked) {
        setStockCostQty("0");
      }
    }
    if (key === "qty") {
      if (e.target.checked) {
        setStockCostQty("1");
      }
    }
  };

  const getInactive = (e) => {
    const { checked } = e.target;
    setIsInactive(!isInactive);
  };

  // search states
  const [searchKeyword, setSearchKeyword] = useState("");
  const [filteredSearchList, setFilteredSearchList] = useState([]);
  // get current time fn
  useEffect(() => {
    setInterval(() => {
      setTime(`${new Date().getHours()}:${new Date().getMinutes()}`);
    }, 1000);
  }, [time]);

  const categoryList = useSelector((state) => state.categorySlice.value);

  

  const getBranch = (e) => {
    setBranch(e.target.value);
  };
  const getProductType = (e) => {
    setProductType(e.target.value);
  };

  const getFromDate = (e) => {
    setFromDate(e.target.value);
  };

  const getToDate = (e) => {
    setToDate(e.target.value);
  };



  // add -all- into branch list
  // useEffect(() => {
  //   if (allBranchesList !== undefined) {
  //     let allBranchList = allBranchesList?.map((obj) => {
  //       return obj;
  //     });
  //     setBranchWithAll([
  //       { storeCode: "-All", branchName: "" },
  //       ...allBranchList,
  //     ]);
  //   }
  // }, [allBranchesList]);
  const formData = new FormData();
  {
   formData.append("branchId", branch?._id);
   formData.append("locationId", branchLoc?._id);
  }

  formData.append("itemType", productType);
  {
      productName !== null &&
      formData.append("itemId", productName?._id);
  }
  {
    fromDate !== "" && formData.append("fromDate", `${fromDate} 00:00:00`);
  }
  {
    toDate !== "" && formData.append("endDate", `${toDate} 23:59:59`);
  }
  formData.append("inactive", isInactive);

  // stock report submit btn fn
  const stockReportSubmitBtnFn = () => {
    stockReportAPICall(formData, setIsLoading,setLoadingForm);
  };
  //  console.log();

  useEffect(() => {
    let extractedData = stockReportData?.rsList?.filter((obj) => {
      if (obj.branchId === loginResponse.branchId) {
        return obj;
      }
    });
    setStockReportListSingle(extractedData);
  }, [stockReportData]);

  /// map stock report data(response) to state
  useEffect(() => {
    if (stockReportData !== undefined) {
      setStockReportFilterData(
        stockReportData?.rsList[0]?.list?.map((obj) => {
          return { ...obj };
        })
      );
    }
  }, [stockReportData]);

  // sorting
  // sort ascending order by product name
  const sortProdutNameAscending = () => {
    setIsAscending(!isAscending);
    let sorted = sortGeneral(
      stockReportFilterData,
      "String",
      "productName",
      true
    );
    setStockReportFilterData(sorted);
  };

  // sort descending order by product name
  const sortProductNameDescending = () => {
    setIsAscending(!isAscending);
    let sorted = sortGeneral(
      stockReportFilterData,
      "String",
      "productName",
      false
    );
    setStockReportFilterData(sorted);
  };
  // sort ascending order by quanity
  const sortQuantityAscending = () => {
    setIsAscending(!isAscending);
    let sorted = sortGeneral(stockReportFilterData, "Number", "quantity", true);
    setStockReportFilterData(sorted);
  };
  // sort descending order by quantity
  const sortQuantityDescending = () => {
    setIsAscending(!isAscending);
    let sorted = sortGeneral(
      stockReportFilterData,
      "Number",
      "quantity",
      false
    );
    setStockReportFilterData(sorted);
  };

  // sort ascending order by sales
  const sortSalesAscending = () => {
    setIsAscending(!isAscending);
    let sorted = sortGeneral(stockReportFilterData, "Number", "sales", true);
    setStockReportFilterData(sorted);
  };
  // sort descending order by sales
  const sortSalesDescending = () => {
    setIsAscending(!isAscending);
    let sorted = sortGeneral(stockReportFilterData, "Number", "sales", false);
    setStockReportFilterData(sorted);
  };
  // sort ascending order by closing stock
  const sortClosingStockAscending = () => {
    setIsAscending(!isAscending);
    let sorted = sortGeneral(
      stockReportFilterData,
      "Number",
      "closingstock",
      true
    );
    setStockReportFilterData(sorted);
  };
  // sort descending order by closing stock
  const sortClosingStockDescending = () => {
    setIsAscending(!isAscending);
    let sorted = sortGeneral(
      stockReportFilterData,
      "Number",
      "closingstock",
      false
    );
    setStockReportFilterData(sorted);
  };

  // sort ascending order by stock price
  const sortStockPriceAscending = () => {
    setIsAscending(!isAscending);
    let sorted = sortGeneral(
      stockReportFilterData,
      "Number",
      "stockPrice",
      true
    );
    setStockReportFilterData(sorted);
  };
  // sort descending order by stock price
  const sortStockPriceDescending = () => {
    setIsAscending(!isAscending);
    let sorted = sortGeneral(
      stockReportFilterData,
      "Number",
      "stockPrice",
      false
    );
    setStockReportFilterData(sorted);
  };

  // sort ascending order by sales price
  const sortSalesPriceAscending = () => {
    setIsAscending(!isAscending);
    let sorted = sortGeneral(
      stockReportFilterData,
      "Number",
      "salesprice",
      true
    );
    setStockReportFilterData(sorted);
  };
  // sort descending order by sales price
  const sortSalesPriceDescending = () => {
    setIsAscending(!isAscending);
    let sorted = sortGeneral(
      stockReportFilterData,
      "Number",
      "salesprice",
      false
    );
    setStockReportFilterData(sorted);
  };
  // sort ascending order by cl stock price
  const sortClStockPriceAscending = () => {
    setIsAscending(!isAscending);
    let sorted = sortGeneral(
      stockReportFilterData,
      "Number",
      "closingstockPrice",
      true
    );
    setStockReportFilterData(sorted);
  };
  // sort descending order by cl stock price
  const sortClStockPriceDescending = () => {
    setIsAscending(!isAscending);
    let sorted = sortGeneral(
      stockReportFilterData,
      "Number",
      "closingstockPrice",
      false
    );
    setStockReportFilterData(sorted);
  };

  const getSearchKeyword = (e) => {
    setSearchKeyword(e.target.value);
  };
  const SearchFilter = (chars, listToFilter) => {
    let searchedArray = [];
    let filteredArray = [];
    if (chars !== "") {
      searchedArray = listToFilter.filter((obj) =>
        obj?.productName?.toLowerCase()?.includes(chars?.toLowerCase())
      );
      filteredArray = searchedArray;
    } else {
      filteredArray = listToFilter;
    }
    return filteredArray;
  };
  
  useEffect(() => {
    // stockReportAPICall({}, setIsLoading);
    viewAllBranchesAPICall();
    viewAllAllowedBranchesAPICall({ employeeId: loginResponse?.data?._id });
    // viewCompanyProfileInfoAPICall();
    categoryListAPICall()
  }, []);

  // get all product api call
  // useEffect(() => {
  //   viewAllProductsAPICall(
  //     { type: productType, branchId: storeCode },
  //     setIsLoading
  //   );
  // }, [productType]);

  const downloadList = () => {
    const worksheet = XLSX.utils.table_to_sheet(document.getElementById("table_data"));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Stock Report.xlsx");
  }

  useEffect(() => {
    if (stockReportFilterData !== undefined) {
      let filteredArray = [];
      setFilteredSearchList(stockReportFilterData);
      filteredArray = SearchFilter(searchKeyword, stockReportFilterData);
      setFilteredSearchList(filteredArray);
    } else {
      setFilteredSearchList([]);
    }
  }, [searchKeyword, stockReportFilterData]);

  useEffect(() => {
      vieWareHouseLocationAPICall({ branchId: branch?._id})
  }, [branch])
 
  useEffect(() => {
    allowedBranchList!==undefined &&  setBranch(filterObjFromList("_id",allowedBranchList,"branchPk",loginResponse))
  }, [allowedBranchList])
  return (
    <>
      <div className="global-page-parent-container">
        <p className="breadcrumb">Report &gt; Stock Report</p>
        <div className="daily-report-top-container">
          <div className="justify-center" style={{justifyContent:"start",gap:"5px"}}>
          <h3 style={{marginRight:"25px"}}>Stock Report</h3>
            <div className="global-single-input auto-complete" style={{ flexDirection:"row",alignItems:"flex-end",gap:"4px",margin:"0"}}>

              <p style={{margin:"0"}}>Branch</p>
              <Autocomplete
                sx={{ width: "100%" }}
                options={
                  userRole==="user"?allowedBranchList:allBranchesList
                  || []}
                getOptionLabel={(option) =>
                  `${option?.storeCode}-${option?.branchName}`
                }
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Branch" />
                )}
                onChange={(e, newValue) => setBranch(newValue)}
                value={branch}
              />
            </div>
            {
              configurationList?.multiLocation &&
            <div className="global-single-input auto-complete" style={{ flexDirection:"row",alignItems:"flex-end",gap:"4px",margin:"0"}}>

              <p style={{margin:"0"}}>Location</p>
              <Autocomplete
                sx={{ width: "100%" }}
                options={wareHouseLocationList || []}
                getOptionLabel={(option) => option?.name }
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Location" />
                )}
                onChange={(e, newValue) => setBranchLoc(newValue)}
              />
            </div>}
          </div>
          <hr className="global-hr" />
          <div className="daily-report-top-input-container">
           
            <div className="global-single-input auto-complete">
              <p>Product Type</p>
              {/* <Select
        value={productType}
        onChange={getProductType}
        
        > 
           <MenuItem value="type" sx={{fontSize:"0.8rem"}}>Select Type</MenuItem>
           <MenuItem value={2}>Material</MenuItem>
           <MenuItem value={0}>Readymade</MenuItem>
           <MenuItem value={1}>Accessories</MenuItem>
        </Select> */}
              <Autocomplete
                sx={{ width: "100%" }}
                options={categoryList || []}
                getOptionLabel={(option) => option?.categoryName}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Type" />
                )}
                onChange={(e, newValue) => {setProductType(newValue?._id)
                  productListForStockReportAPICall({mainCatgeoryId:newValue?._id || ""})
                  setProductName(null)
                }}
              />
            </div>
            {/* {productType !== 2 && ( */}
              <div className="global-single-input auto-complete">
                <p>Item Name</p>
                {/* <Select
         value={productName}
         onChange={getProductName}
        >
          <MenuItem value="0">Select Item</MenuItem>
          {
            allProductsList !== undefined && (
              allProductsList?.map((r,i)=>(
                <MenuItem value={r._id} key={r._id}>{r?.productName}</MenuItem>
              ))
            )
          }
          
        </Select> */}
                <Autocomplete
                  sx={{ width: "100%" }}
                  options={allProductsList || []}
                  getOptionLabel={(option) => option?.productName}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Item" />
                  )}
                  onChange={(e, newValue) => setProductName(newValue)}
                  value={productName}
                />
              </div>
            {/* )} */}

            <div className="global-single-input">
              <p>From</p>
              <input
                type="date"
                defaultValue={fromDate}
                onChange={getFromDate}
              />
            </div>
            <div className="global-single-input">
              <p>To</p>
              <input type="date" defaultValue={toDate} onChange={getToDate} />
            </div>
            <button
              onClick={stockReportSubmitBtnFn}
              //  onClick={extractFunction}
              className=//  {
              //    userRole === ' admin' && branch===undefined ||productType===undefined
              //   ?
              //   "btn btn-primary-disabled report-submit-btn"
              //   :
              "btn btn-primary report-submit-btn"
              //  }

              //  disabled={  userRole === ' admin' && branch===undefined||productType===undefined}
            >
              Submit
            </button>

            <div className="radio-groups-container stock-report-radio-ctn">
              <div className="global-radio-button radio-group stock-report-radio-group">
                <input
                  type="radio"
                  defaultChecked
                  name="stockReportTopRadio"
                  id="stockReportRadioStock"
                  onChange={changeStockCostQty("cost")}
                />
                <label htmlFor="stockReportRadioStock">Cost</label>
              </div>
              <div className="global-radio-button radio-group stock-report-radio-group">
                <input
                  type="radio"
                  name="stockReportTopRadio"
                  id="stockReportRadioSale"
                  onChange={changeStockCostQty("qty")}
                />
                <label htmlFor="stockReportRadioSale">Quantity</label>
              </div>
              <div
                className="global-radio-button radio-group stock-report-radio-group"
                style={{ marginLeft: "20px" }}
              >
                <input
                  type="checkbox"
                  checked={isInactive}
                  id="stockReportChecBoxInactive"
                  onClick={getInactive}
                />
                <label htmlFor="stockReportChecBoxInactive">Inactive</label>
              </div>
            </div>
          </div>
        </div>
        <>
          {/* {stockReportData!==undefined && Object.keys(stockReportData).length !==0 ? */}
          <div
            className="global-white-bg-container"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div className="stock-report-print-download-icon-container">
              <div className="stock-report-stock-price-container total-stock-container">
                <p>Total Stock</p>
                <p>{stockReportData?.totalStock?.toFixed(2)}</p>
              </div>
              <div className="stock-report-stock-price-container total-sale-container">
                <p>Total Sales</p>
                <p>{stockReportData?.totalsales?.toFixed(2)}</p>
              </div>
              <div className="stock-report-stock-price-container total-closing-stock-container">
                <p>Total C.Stock</p>
                <p>{stockReportData?.totalclosingStock?.toFixed(2)}</p>
              </div>
              <div className="stock-report-stock-price-container">
                <p>Stock Price</p>
                <p>{stockReportData?.stkPriceTotal?.toFixed(2)}</p>
              </div>
              <div className="stock-report-stock-price-container total-sales-price">
                <p>Sales Price </p>
                <p>{stockReportData?.salPriceTotal?.toFixed(2)}</p>
              </div>
              <div className="stock-report-stock-price-container total-closing-stock-price">
                <p>Cl.S.Price</p>
                <p>{stockReportData?.clStockPriceTotal?.toFixed(2)}</p>
              </div>
              <ReactToPrint
                trigger={() => (
                  <IconButton className="print-icon-container">
                    <i class="bi bi-printer printer-icon"></i>
                  </IconButton>
                )}
                content={() =>
                  userRole === "admin"
                    ? stockReportAdminReport
                    : userRole === "user"
                    ? stockReportPrint
                    : undefined
                }
                pageStyle="@page { size: auto;  margin: 15mm 0 10mm 0; } }"
              />

              <IconButton onClick={downloadList} className="download-icon-container" >
                <i class="bi bi-arrow-down-circle download-icon"></i>
              </IconButton>
            </div>
            {
              userRole === "user" ? (
                <>
                  <div
                    className="view-order-search-container view-order-admin-search-container"
                    style={{
                      width: "25%",
                      alignSelf: "flex-end",
                      margin: "0 3%",
                    }}
                  >
                    <input
                      placeholder="Search Product Name"
                      value={searchKeyword}
                      onChange={getSearchKeyword}
                    />
                    <IconButton>
                      <i class="bi bi-search search-icon-order"></i>
                    </IconButton>
                  </div>

                  <div className="global-table-container">
                    <table id='table_data' className="global-table" style={{ width: "80vw" }} >
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Prod Code</th>
                          <th>
                            Prod Name
                            {isAscending ? (
                              <IconButton onClick={sortProdutNameAscending}>
                                <ArrowUpward sx={{ fontSize: "1rem" }} />
                              </IconButton>
                            ) : (
                              <IconButton onClick={sortProductNameDescending}>
                                <ArrowDownward sx={{ fontSize: "1rem" }} />
                              </IconButton>
                            )}
                          </th>
                          <th>UOM</th>
                          <th>
                            Qty
                            {isAscending ? (
                              <IconButton onClick={sortQuantityAscending}>
                                <ArrowUpward sx={{ fontSize: "1rem" }} />
                              </IconButton>
                            ) : (
                              <IconButton onClick={sortQuantityDescending}>
                                <ArrowDownward sx={{ fontSize: "1rem" }} />
                              </IconButton>
                            )}
                          </th>
                          <th>Purchase</th>
                          <th>Trans In</th>
                          <th>Sal Return</th>
                          <th>Post Adj</th>
                          <th>Trans Out</th>
                          <th>Neg Adj</th>
                          <th>Pur Return</th>
                          <th>
                            Sales
                            {isAscending ? (
                              <IconButton onClick={sortSalesAscending}>
                                <ArrowUpward sx={{ fontSize: "1rem" }} />
                              </IconButton>
                            ) : (
                              <IconButton onClick={sortSalesDescending}>
                                <ArrowDownward sx={{ fontSize: "1rem" }} />
                              </IconButton>
                            )}
                          </th>
                          <th>
                            Closing Stock
                            {isAscending ? (
                              <IconButton onClick={sortClosingStockAscending}>
                                <ArrowUpward sx={{ fontSize: "1rem" }} />
                              </IconButton>
                            ) : (
                              <IconButton onClick={sortClosingStockDescending}>
                                <ArrowDownward sx={{ fontSize: "1rem" }} />
                              </IconButton>
                            )}
                          </th>
                          <th>
                            Stock Price
                            {isAscending ? (
                              <IconButton onClick={sortStockPriceAscending}>
                                <ArrowUpward sx={{ fontSize: "1rem" }} />
                              </IconButton>
                            ) : (
                              <IconButton onClick={sortStockPriceDescending}>
                                <ArrowDownward sx={{ fontSize: "1rem" }} />
                              </IconButton>
                            )}
                          </th>
                          <th>
                            Sales Price
                            {isAscending ? (
                              <IconButton onClick={sortSalesPriceAscending}>
                                <ArrowUpward sx={{ fontSize: "1rem" }} />
                              </IconButton>
                            ) : (
                              <IconButton onClick={sortSalesPriceDescending}>
                                <ArrowDownward sx={{ fontSize: "1rem" }} />
                              </IconButton>
                            )}
                          </th>
                          <th>
                            Closing Stock Price
                            {isAscending ? (
                              <IconButton onClick={sortClStockPriceAscending}>
                                <ArrowUpward sx={{ fontSize: "1rem" }} />
                              </IconButton>
                            ) : (
                              <IconButton onClick={sortClStockPriceDescending}>
                                <ArrowDownward sx={{ fontSize: "1rem" }} />
                              </IconButton>
                            )}
                          </th>
                        </tr>
                      </thead>
                      {isLoading ? (
                        <tbody>
                          <tr>
                            <td colSpan={17}>
                              <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={30}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={17}>
                              <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={30}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={17}>
                              <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={30}
                              />
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          {filteredSearchList.length !== 0 ? (
                            filteredSearchList?.map((r, i) => (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{r?.productCode}</td>
                                <td>{r.productName}</td>
                                <td>{r.size}</td>
                                <td className="print-view-fixed-two-right">
                                  {r.quantity?.toFixed(2)}
                                </td>
                                <td>{r?.purchases}</td>
                                <td>{r?.transIn}</td>
                                <td>{r?.salesReturn}</td>
                                <td>{r?.postAdj}</td>
                                <td>{r?.transOut}</td>
                                <td className="print-view-fixed-two-right">
                                  {r?.negativeStockAdjustment?.toFixed(2)}
                                </td>
                                <td>{r?.purchaseReturn}</td>
                                <td className="print-view-fixed-two-right">
                                  {r?.sales.toFixed(2)}
                                </td>
                                <td className="print-view-fixed-two-right">
                                  {r?.closingstock.toFixed(2)}
                                </td>
                                <td className="print-view-fixed-two-right">
                                  {r.stockPrice.toFixed(2)}
                                </td>
                                <td className="print-view-fixed-two-right">
                                  {r?.salesprice.toFixed(2)}
                                </td>
                                <td className="print-view-fixed-two-right">
                                  {r?.closingstockPrice.toFixed(2)}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={17} style={{ textAlign: "center" }}>
                                No Data
                              </td>
                            </tr>
                          )}
                        </tbody>
                      )}
                    </table>
                  </div>
                </>
              ) : (
                //  map start
                <>
                  {isLoading ? (
                    <table className="global-table">
                      <tbody>
                        <tr>
                          <td colSpan={17}>
                            <Skeleton
                              variant="rectangular"
                              animation="wave"
                              width={"100%"}
                              height={30}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={17}>
                            <Skeleton
                              variant="rectangular"
                              animation="wave"
                              width={"100%"}
                              height={30}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={17}>
                            <Skeleton
                              variant="rectangular"
                              animation="wave"
                              width={"100%"}
                              height={30}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : stockReportData !== undefined ? (
                    stockReportData?.rsList?.map((r, i) => (
                      <div>
                        <div className="stock-report-admin-view-top-container">
                          <div className="stock-report-admin-view-top-container-right">
                            <h4>{r?.branchId}</h4>
                            <h4 style={{ marginLeft: "5%" }}>
                              {r?.locationName}
                            </h4>
                          </div>
                          <div
                            className="view-order-search-container view-order-admin-search-container"
                            style={{ width: "25%" }}
                          >
                            <input
                              placeholder="Search Product Name"
                              value={searchKeyword}
                              onChange={getSearchKeyword}
                            />
                            <IconButton>
                              <i class="bi bi-search search-icon-order"></i>
                            </IconButton>
                          </div>
                        </div>
                        <div className="global-table-container">
                          <table
                            className="global-table"
                            style={{ width: "80vw" }}
                          >
                            <thead>
                              <tr>
                                <th>No</th>
                                <th>Prod Code</th>
                                <th>
                                  Prod Name
                                  {isAscending ? (
                                    <IconButton
                                      onClick={sortProdutNameAscending}
                                    >
                                      <ArrowUpward sx={{ fontSize: "1rem" }} />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      onClick={sortProductNameDescending}
                                    >
                                      <ArrowDownward
                                        sx={{ fontSize: "1rem" }}
                                      />
                                    </IconButton>
                                  )}
                                </th>
                                <th>Size/Unit</th>

                                <th>
                                  Qty
                                  {isAscending ? (
                                    <IconButton onClick={sortQuantityAscending}>
                                      <ArrowUpward sx={{ fontSize: "1rem" }} />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      onClick={sortQuantityDescending}
                                    >
                                      <ArrowDownward
                                        sx={{ fontSize: "1rem" }}
                                      />
                                    </IconButton>
                                  )}
                                </th>
                                <th>Purchase</th>
                                <th>Trans In</th>
                                <th>Sal Return</th>
                                <th>Post Adj</th>
                                <th>Trans Out</th>
                                <th>Neg Adj</th>
                                <th>Pur Return</th>
                                <th>
                                  Sales
                                  {isAscending ? (
                                    <IconButton onClick={sortSalesAscending}>
                                      <ArrowUpward sx={{ fontSize: "1rem" }} />
                                    </IconButton>
                                  ) : (
                                    <IconButton onClick={sortSalesDescending}>
                                      <ArrowDownward
                                        sx={{ fontSize: "1rem" }}
                                      />
                                    </IconButton>
                                  )}
                                </th>
                                <th>
                                  Closing Stock
                                  {isAscending ? (
                                    <IconButton
                                      onClick={sortClosingStockAscending}
                                    >
                                      <ArrowUpward sx={{ fontSize: "1rem" }} />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      onClick={sortClosingStockDescending}
                                    >
                                      <ArrowDownward
                                        sx={{ fontSize: "1rem" }}
                                      />
                                    </IconButton>
                                  )}
                                </th>
                                <th>
                                  Stock Price
                                  {isAscending ? (
                                    <IconButton
                                      onClick={sortStockPriceAscending}
                                    >
                                      <ArrowUpward sx={{ fontSize: "1rem" }} />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      onClick={sortStockPriceDescending}
                                    >
                                      <ArrowDownward
                                        sx={{ fontSize: "1rem" }}
                                      />
                                    </IconButton>
                                  )}
                                </th>
                                <th>
                                  Sales Price
                                  {isAscending ? (
                                    <IconButton
                                      onClick={sortSalesPriceAscending}
                                    >
                                      <ArrowUpward sx={{ fontSize: "1rem" }} />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      onClick={sortSalesPriceDescending}
                                    >
                                      <ArrowDownward
                                        sx={{ fontSize: "1rem" }}
                                      />
                                    </IconButton>
                                  )}
                                </th>
                                <th>
                                  Closing Stock Price
                                  {isAscending ? (
                                    <IconButton
                                      onClick={sortClStockPriceAscending}
                                    >
                                      <ArrowUpward sx={{ fontSize: "1rem" }} />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      onClick={sortClStockPriceDescending}
                                    >
                                      <ArrowDownward
                                        sx={{ fontSize: "1rem" }}
                                      />
                                    </IconButton>
                                  )}
                                </th>
                              </tr>
                            </thead>
                            {isLoading ? (
                              <tbody>
                                <tr>
                                  <td colSpan={17}>
                                    <Skeleton
                                      variant="rectangular"
                                      animation="wave"
                                      width={"100%"}
                                      height={30}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={17}>
                                    <Skeleton
                                      variant="rectangular"
                                      animation="wave"
                                      width={"100%"}
                                      height={30}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={17}>
                                    <Skeleton
                                      variant="rectangular"
                                      animation="wave"
                                      width={"100%"}
                                      height={30}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            ) : (
                              <tbody>
                                {filteredSearchList?.length !== 0 ? (
                                  filteredSearchList?.map((k, i) => (
                                    <tr>
                                      <td>{i + 1}</td>
                                      <td>{k?.productCode}</td>
                                      <td>{k?.productName}</td>
                                      <td>{k?.size}</td>
                                      <td className="print-view-fixed-two-right">
                                        {k?.quantity?.toFixed(2)}
                                      </td>
                                      <td>{k?.purchases}</td>
                                      <td>{k?.transIn}</td>
                                      <td>{k?.salesReturn}</td>
                                      <td>{k?.postAdj}</td>
                                      <td>{k?.transOut}</td>
                                      <td className="print-view-fixed-two-right">
                                        {k?.negativeStockAdjustment?.toFixed(2)}
                                      </td>
                                      <td>{k?.purchaseReturn}</td>
                                      <td className="print-view-fixed-two-right">
                                        {k?.sales?.toFixed(2)}
                                      </td>
                                      <td className="print-view-fixed-two-right">
                                        {k?.closingstock?.toFixed(2)}
                                      </td>
                                      <td className="print-view-fixed-two-right">
                                        {k.stockPrice?.toFixed(2)}
                                      </td>
                                      <td className="print-view-fixed-two-right">
                                        {k?.salesprice?.toFixed(2)}
                                      </td>
                                      <td className="print-view-fixed-two-right">
                                        {k?.closingstockPrice?.toFixed(2)}
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td
                                      colSpan={17}
                                      style={{ textAlign: "center" }}
                                    >
                                      No Product
                                    </td>
                                  </tr>
                                )}
                                <tr>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>Total</td>
                                  <td className="print-view-fixed-two-right">

                                    {r?.totalStockPrice?.toFixed(2)}

                                  </td>
                                  <td className="print-view-fixed-two-right">
                                    {r?.totalSalesPrice?.toFixed(2)}
                                  </td>
                                  <td className="print-view-fixed-two-right">
                                    {r?.totalclosingStockPrice?.toFixed(2)}
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                        </div>
                      </div>
                    ))
                  ) : (
                    <table className="global-table">
                      <tbody>
                        <tr>
                          <td colSpan={17} style={{ textAlign: "center" }}>
                            No Data
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </>
              )

              //  map end
            }
          </div>
          {/* :
    <div className="global-white-bg-container">
      <h3 className='no-data'>No Records</h3>
    </div>
     } */}
        </>
      </div>

      {/* stock report print view */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (stockReportPrint = el)}>
          <div className="print-view-top-container">
            <div
              className="print-view-top-left-container"
              style={{ width: "35%" }}
            >
              <h6>
                Printed On : {convertDateFormat(today)}&nbsp;{getCurrentTime()}
              </h6>
            </div>
            <div
              className="print-view-top-middle-container"
              style={{ width: "30%" }}
            >
              {userRole === "admin" ? (
                <h3>{loginResponse?.data?.profile?.companyName}</h3>
              ) : userRole === "user" ? (
                <h3>{loginResponse?.storeName}</h3>
              ) : undefined}
              <p>STOCK REPORT</p>
              <h5
                style={{ margin: "0", textAlign: "center" }}
              >{`(${convertDateFormat(fromDate)} - ${convertDateFormat(
                toDate
              )})`}</h5>
            </div>

            <div
              className="print-view-top-right-container"
              style={{ width: "35%" }}
            >
              <p>
                {loginResponse?.branchId}-{loginResponse?.branchName}
              </p>
            </div>
          </div>
          <div className="print-view-table-container">
            <table className="report-print-view-table-with-clr">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Prod Code</th>
                  <th>Prod Name</th>
                  <th>Size/Unit</th>
                  <th>Qty</th>
                  {stockCostQty === "1" && (
                    <>
                      <th>Purchase</th>
                      <th>Trans In</th>
                      <th>Sal Return</th>
                      <th>Post Adj</th>
                      <th>Trans Out</th>
                      <th>Neg Adj</th>
                      <th>Pur Return</th>
                      <th>Sales</th>
                      <th>Closing Stock</th>
                    </>
                  )}
                  {stockCostQty === "0" && (
                    <>
                      <th>Stock Price</th>
                      <th>Sales Price</th>
                      <th>Closing Stock Price</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {stockReportFilterData !== undefined &&
                  stockReportFilterData?.map((r, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{r?.productCode}</td>
                      <td>{r.productName}</td>
                      <td>{r.size}</td>
                      <td>{r.quantity?.toFixed(2)}</td>
                      {stockCostQty === "1" && (
                        <>
                          <td className="print-view-fixed-two-right">
                            {r?.purchases}
                          </td>
                          <td className="print-view-fixed-two-right">
                            {r?.transIn}
                          </td>
                          <td className="print-view-fixed-two-right">
                            {r?.salesReturn}
                          </td>
                          <td className="print-view-fixed-two-right">
                            {r?.postAdj}
                          </td>
                          <td className="print-view-fixed-two-right">
                            {r?.transOut}
                          </td>
                          <td className="print-view-fixed-two-right">
                            {r?.negativeStockAdjustment?.toFixed(2)}
                          </td>
                          <td className="print-view-fixed-two-right">
                            {r?.purchaseReturn}
                          </td>
                          <td className="print-view-fixed-two-right">
                            {r?.sales.toFixed(2)}
                          </td>
                          <td className="print-view-fixed-two-right">
                            {r?.closingstock.toFixed(2)}
                          </td>
                        </>
                      )}
                      {stockCostQty === "0" && (
                        <>
                          <td className="print-view-fixed-two-right">
                            {r.stockPrice.toFixed(2)}
                          </td>
                          <td className="print-view-fixed-two-right">
                            {r?.salesprice.toFixed(2)}
                          </td>
                          <td className="print-view-fixed-two-right">
                            {r?.closingstockPrice.toFixed(2)}
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                <br />
                {stockCostQty === "0" && (
                  <tr className="print-table-total-tr-container">
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>Total</td>
                    <td>
                      {(stockReportListSingle !== undefined &&
                        stockReportListSingle[0]?.stkPriceTotal) ||
                        "0.00"}
                    </td>
                    <td>
                      {(stockReportListSingle !== undefined &&
                        stockReportListSingle[0]?.salPriceTotal) ||
                        "0.00"}
                    </td>
                    <td>
                      {(stockReportListSingle !== undefined &&
                        stockReportListSingle[0]?.clStockPriceTotal) ||
                        "0.00"}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* stock report print view admin */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (stockReportAdminReport = el)}>
          <div className="print-view-top-container">
            <div
              className="print-view-top-left-container"
              style={{ width: "25%" }}
            >
              <h6 style={{ margin: "0" }}>
                Printed On : {today}&nbsp;{time}
              </h6>
            </div>
            <div
              className="print-view-top-middle-container"
              style={{ width: "50%" }}
            >
              {userRole === "admin" ? (
                <h3>{loginResponse?.data?.profile?.companyName}</h3>
              ) : userRole === "user" ? (
                <h3>{loginResponse?.storeName}</h3>
              ) : undefined}

              <p>STOCK REPORT</p>
              <h5
                style={{ margin: "0", textAlign: "center" }}
              >{`(${convertDateFormat(fromDate)} - ${convertDateFormat(
                toDate
              )})`}</h5>
            </div>
            <div
              className="print-view-top-right-container"
              style={{ width: "25%" }}
            >
              {fromDate !== undefined ||
                (fromDate !== null && (
                  <p>From : {convertDateFormat(fromDate)}</p>
                ))}
              {toDate !== undefined ||
                (toDate !== null && <p>To :{convertDateFormat(toDate)}</p>)}
            </div>
          </div>
          {/* map start */}

          {stockReportData?.rsList?.map((r, i) => (
            <div className="commission-report-summary-container">
              <div className="commission-report-summary-top-container">
                <h3 style={{ width: "20%" }}>{r?.branchId}</h3>
                <h3>{r?.locationName}</h3>
              </div>
              <div
                className="global-table-container"
                style={{
                  margin: "1% 0",
                  maxHeight: "none",
                  overflowY: "hidden",
                }}
              >
                <table className="global-table report-print-view-table-with-clr">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Prod Code</th>
                      <th>Prod Name</th>
                      <th>Size/Unit</th>
                      <th className="print-view-fixed-two-right">Qty</th>
                      {stockCostQty === "1" && (
                        <>
                          <th className="print-view-fixed-two-right">
                            Purchase
                          </th>
                          <th className="print-view-fixed-two-right">
                            Trans In
                          </th>
                          <th className="print-view-fixed-two-right">
                            Sal Return
                          </th>
                          <th className="print-view-fixed-two-right">
                            Post Adj
                          </th>
                          <th className="print-view-fixed-two-right">
                            Trans Out
                          </th>
                          <th className="print-view-fixed-two-right">
                            Neg Adj
                          </th>
                          <th className="print-view-fixed-two-right">
                            Pur Return
                          </th>
                          <th className="print-view-fixed-two-right">Sales</th>
                          <th className="print-view-fixed-two-right">
                            Closing Stock
                          </th>
                        </>
                      )}
                      {stockCostQty === "0" && (
                        <>
                          <th className="print-view-fixed-two-right">
                            Stock Price
                          </th>
                          <th className="print-view-fixed-two-right">
                            Sales Price
                          </th>
                          <th className="print-view-fixed-two-right">
                            Closing Stock Price
                          </th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody style={{ backgroundColor: "#fff" }}>
                    {stockReportFilterData?.map((k, i) => (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{k?.productCode}</td>
                        <td>{k?.productName}</td>
                        <td>{k?.size}</td>
                        <td className="print-view-fixed-two-right">
                          {k?.quantity?.toFixed(2)}
                        </td>
                        {stockCostQty === "1" && (
                          <>
                            <td className="print-view-fixed-two-right">
                              {k?.purchases}
                            </td>
                            <td className="print-view-fixed-two-right">
                              {k?.transIn}
                            </td>
                            <td className="print-view-fixed-two-right">
                              {k?.salesReturn}
                            </td>
                            <td className="print-view-fixed-two-right">
                              {k?.postAdj}
                            </td>
                            <td className="print-view-fixed-two-right">
                              {k?.transOut}
                            </td>
                            <td className="print-view-fixed-two-right">
                              {k?.negativeStockAdjustment?.toFixed(2)}
                            </td>
                            <td className="print-view-fixed-two-right">
                              {k?.purchaseReturn}
                            </td>
                            <td className="print-view-fixed-two-right">
                              {k?.sales?.toFixed(2)}
                            </td>
                            <td className="print-view-fixed-two-right">
                              {k?.closingstock?.toFixed(2)}
                            </td>
                          </>
                        )}
                        {stockCostQty === "0" && (
                          <>
                            <td className="print-view-fixed-two-right">
                              {k.stockPrice?.toFixed(2)}
                            </td>
                            <td className="print-view-fixed-two-right">
                              {k?.salesprice?.toFixed(2)}
                            </td>
                            <td className="print-view-fixed-two-right">
                              {k?.closingstockPrice?.toFixed(2)}
                            </td>
                          </>
                        )}
                      </tr>
                    ))}
                    {stockCostQty === "0" && (
                      <tr className="print-table-total-tr-container">
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>Total</td>
                        <td className="print-view-fixed-two-right">
                          {r?.stkPriceTotal?.toFixed(2)}
                        </td>
                        <td className="print-view-fixed-two-right">
                          {r?.salPriceTotal?.toFixed(2)}
                        </td>
                        <td className="print-view-fixed-two-right">
                          {r?.clStockPriceTotal?.toFixed(2)}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ))}

          {/* map end */}
          {/* <div className='global-table-container' style={{margin:"1% 6%"}}>
            <table className="global-table">
              <tbody>
                <tr style={{backgroundColor:"#fff"}}>
              <td style={{width:"3%",backgroundColor:"#fff"}}>&nbsp;</td>
              <td style={{width:"15%",backgroundColor:"#fff"}}>&nbsp;</td>
              <td style={{width:"9%",backgroundColor:"#fff"}}>&nbsp;</td>
              <td style={{width:"9%",backgroundColor:"#fff"}}>&nbsp;</td>
              <td style={{width:"6%",backgroundColor:"#fff"}}>&nbsp;</td>
              <td style={{width:"14%",backgroundColor:"#fff",fontSize:'0.9rem',fontWeight:600}}>Grand Total</td>
              <td style={{width:"12%",backgroundColor:"#fff",fontSize:'0.9rem',fontWeight:600}}>0.00</td>
              <td style={{width:"12%",backgroundColor:"#fff",fontSize:'0.9rem',fontWeight:600}}>0.00</td>
              <td style={{backgroundColor:"#fff",fontSize:'0.9rem',fontWeight:600}}>0.00</td>
                </tr>
              </tbody>
            </table>
          </div> */}
        </div>
      </div>
      <LoadingForm loading={loadingForm}/>

    </>
  );
};
