import axios from "axios"
import { get_sms_header } from "../../../Redux/Settings/SmsSettings/viewSmsHeaderSlice"
import store from "../../../Redux/store"
import { HEADERS } from "../../UrlAndPaths"

// view sms header api call
export const viewSmsHeaderAPICall= async ()=>{
  await axios.post("settings/viewSms",{},HEADERS)
  .then((res)=>{
    if(res.status===200){
        store.dispatch(get_sms_header({smsHeader:res.data}))
    }
  })
  .catch((err)=>{
    store.dispatch(get_sms_header({smsHeader:undefined}))
    console.log(err.response);
  })
}

// add sms header api call
export const addSmsHeaderAPICall= async (
    body,
    updateListner,
    setSuccessSnackOpen,
    setErrorSnackOpen,
    setSnackMsg,
    setHeaderName
    )=>{
 await axios.post("settings/addsms",body,HEADERS)
 .then((res)=>{
    if(res.status===200){
        updateListner()
        setSuccessSnackOpen(true)
        setSnackMsg('Sms Header Added')
        setHeaderName('')
    }
 })
 .catch((err)=>{
    setErrorSnackOpen(true)
    if (err.response === undefined) {
        setSnackMsg("Network Connection error!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Already Exist");
      }
    
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden");
      }
      if(err.response.status === 422){
        setSnackMsg(err.response.data?.msg);
      }
    console.log(err.response);
 })
}
