import { createSlice } from "@reduxjs/toolkit";

export const debitNoteSlice = createSlice({
  name: "debitNoteSlice",
  initialState: {
    apiResList: undefined,
    rowData:undefined,
    generateName:undefined,
    customerInvoices:undefined,
    singleResList:undefined
  },
  reducers: {
    get_bill_lists_of_customer: (state, action) => {
        state.customerInvoices = action.payload.vendorBillListData;
      },
    get_customer_bill_row_data_list: (state, action) => {
    state.singleResList = action.payload.singleVendorBillData;
    },
    get_debit_note_list: (state, action) => {
      state.apiResList = action.payload.debitNoteData;
    },
    get_debit_note_row_data_list: (state, action) => {
      state.rowData = action.payload.debitNoteSingleData;
    },
    generate_debit_note_name: (state, action) => {
      state.generateName = action.payload.debitNoteName;
    }
  },
});

export const {get_debit_note_list,
                get_debit_note_row_data_list,
                generate_debit_note_name,
                get_customer_bill_row_data_list,
                get_bill_lists_of_customer
            } = debitNoteSlice.actions;

export default debitNoteSlice.reducer;
