import { createSlice } from "@reduxjs/toolkit";

export const lengthCommissionSlice=createSlice({
    name:"lengthCommissionSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        update_length_commission:(state,action)=>{
            state.value=action.payload.lengthCommission
        }
    }
})

export const {update_length_commission}=lengthCommissionSlice.actions
export default lengthCommissionSlice.reducer