import { createSlice } from "@reduxjs/toolkit";

export const branchSettingsSlice = createSlice({
  name: "branchSettingsSlice",
  initialState: {
    apiResList: undefined,
    branchSettingUser:undefined
  },
  reducers: {
    get_branch_setttings_res_list: (state, action) => {
      state.apiResList = action.payload.branchSettingsData;
    },
    get_branch_settings_user_res_list:(state,action)=>{
      state.branchSettingUser=action.payload.branchSettingsUserData;
    }
  }
});

export const {
    get_branch_setttings_res_list,
    get_branch_settings_user_res_list
} = branchSettingsSlice.actions;

export default branchSettingsSlice.reducer;
