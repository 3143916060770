import React, { useState } from "react";
import { IconButton, Dialog, Skeleton } from "@mui/material";
import { useEffect } from "react";
import { viewDraftedStockAdjustmentAPICall } from "../../../../../API/Purchase Manager/stockAdjustmentAPI";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { convertDateFormat } from "../../../../../Js/Date";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
// css - stockAdjustmentList.css
export const Draft = () => {
  //BranchId from Local Storage
  const branchId = localStorage.getItem("branchId");
  const [draftView, setDraftView] = useState(false);
  // loading state
  const [isLoading,setIsloading]=useState(false)
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!");
  const [searchKeyword,setSearchKeyword]=useState("")
  const [filteredSearchList,setFilteredSearchList]=useState([])
  const navigate=useNavigate()
  //* Redux states
  //list of drafted stock adjustments
  const draftedStkAdjList = useSelector(
    (state) => state.draftedStockAdjSlice.value
  );
  //Single Object of Draft list
  const [singleDraftObj,setSingleDraftObj]=useState([])
  //Close ErrorSnackbar
  const closeErrorSnackbar = () => {
    setErrorSnackOpen(false);
  };


  //*useEffects
  //Initial API Calls
  useEffect(() => {
    viewDraftedStockAdjustmentAPICall({
      branchId: branchId !== "null" ? branchId : null,
    },
    setIsloading,setErrorSnackOpen,setSnackMsg);
  }, []);



  //extract Selected Draft object for single view [invoke on single view icon button click]
  const extractDraftObjectForSingleView=(id)=>()=>{
    setDraftView(true)
    if(draftedStkAdjList!==undefined)
    {
      let extractedDraftObj = draftedStkAdjList?.filter((obj)=>(obj?._id===id))
      setSingleDraftObj(extractedDraftObj)
    }
  }

  //Edit Drafted Stock Adjustment
  const editDraft=(draftId)=>()=>{
      localStorage.setItem('draft',draftId)
      navigate('/userdashboard/purchasemanager/stockAdjustment/addstockAdjustment')      
  }
  const getSearchKeyword=(e)=>{
    setSearchKeyword(e.target.value)
  }
  const SearchFilter=(chars,listToFilter)=>{
    let searchedArray=[]
    let filteredArray=[]
    if(chars!==""){
      searchedArray=listToFilter.filter((obj)=>
      obj?.transNo?.toLowerCase()?.includes(chars?.toLowerCase())||
      obj?.branchId?.toLowerCase()?.includes(chars?.toLowerCase())||
      obj?.frmBranchName?.toLowerCase()?.includes(chars?.toLowerCase())||
      obj?.toBranchName?.toLowerCase()?.includes(chars?.toLowerCase())||
      obj?.glLinkCode?.code?.toLowerCase()?.includes(chars?.toLowerCase())
    )
      filteredArray=searchedArray
    }
    else{
      filteredArray=listToFilter
    }
    return filteredArray
  }
  useEffect(()=>{
    if(draftedStkAdjList!==undefined){
      let filteredArray=[]
      setFilteredSearchList(draftedStkAdjList)
      filteredArray=SearchFilter(searchKeyword,draftedStkAdjList)
      setFilteredSearchList(filteredArray)    
    }
    else{
      setFilteredSearchList([])
    }
  },[searchKeyword,draftedStkAdjList])




  //TODO draft edit logic save id to lc and redirect to add stock adj page call single api and edit


  return (
    <div className="global-page-parent-container">
      <p className="breadcrumb">
        Purchase Manage &gt; Stock Adjustment &gt; Draft
      </p>
      <div className="stock-adjustment-list-container">
        <div className="stock-out-product-top-container" style={{alignItems:"center"}}>
          <h3>Draft List</h3>
            <div className="view-order-search-container view-order-admin-search-container" style={{width:"30%"}}>
                  <input
                    placeholder="Transaction ID,Branch,GL Link Code"
                    value={searchKeyword}
                    onChange={getSearchKeyword}
                  />
                  <IconButton>
                      <i class="bi bi-search search-icon-order"></i>
                  </IconButton>
              </div>
            </div>
        
        <div className="global-table-container stock-adjustment-list-table">
          <table className="global-table">
            <thead>
              <tr>
                <th>Transaction ID</th>
                <th>From Location</th>
                <th>To Location</th>
                <th>Date</th>
                <th>GL Link Code</th>
                <th>Edit</th>
                <th>View</th>
              </tr>
            </thead>
            {
              isLoading?
                <tbody>
                  <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr> 
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr> 
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr> 
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
            </tbody>:
            <tbody>
              {filteredSearchList.length!==0?filteredSearchList?.slice(0)?.reverse()?.map((r, i) => (
                <tr key={i}>
                  <td>{r?.transNo}</td>
                  <td>{r?.frmBranchName}</td>
                  <td>{r?.toBranchName}</td>
                  <td>{convertDateFormat(r?.date)}</td>
                  <td>{r?.glLinkCode?.code}</td>
                  <td>
                    <IconButton onClick={editDraft(r?._id)}>
                      <i class="bi bi-pencil-square edit-icon"></i>
                    </IconButton>
                  </td>
                  <td>
                    <IconButton onClick={extractDraftObjectForSingleView(r?._id)}>
                      <i class="bi bi-eye visibility-icon"></i>
                    </IconButton>
                  </td>
                </tr>
              )):<tr><td colSpan={7} style={{textAlign:"center"}}>No Records</td></tr>}
            </tbody>
            }
          </table>
        </div>
      </div>
     
     
      <Dialog open={draftView} maxWidth="lg"  onKeyDown={(e)=>e.key === "Escape" && setDraftView(false)}>
        <div className="stock-adjustment-list-view-container">
          <h3>View</h3>
          <div className="stock-adjustment-list-view-input-container">
            <div className="global-single-input">
              <p>Transaction </p>
              <input disabled value={singleDraftObj[0]?.transNo}/>
            </div>
            <div className="global-single-input">
              <p>Location From</p>
              <input disabled value={singleDraftObj[0]?.frmBranchName}/>
            </div>
            <div className="global-single-input">
              <p>Location To</p>
              <input disabled value={singleDraftObj[0]?.toBranchName}/>
            </div>
            <div className="global-single-input">
              <p>Date</p>
              <input type='date' disabled value={singleDraftObj[0]?.date}/>
            </div>
            <div className="global-single-input">
              <p>GL Link Code</p>
              <div className="stock-adjustment-list-view-gl-code">
                <input className="gl-link-code-first" disabled value={singleDraftObj[0]?.glLinkCode?.code} />
                <input className="gl-link-code-second" disabled value={singleDraftObj[0]?.glLinkCode?.description}/>
              </div>
            </div>
            <div className="global-single-input">
              <p>GL Code</p>
              <div className="stock-adjustment-list-view-gl-code">
                <input className="gl-link-code-first" disabled value={singleDraftObj[0]?.glCode?.code} />
                <input className="gl-link-code-second" disabled value={singleDraftObj[0]?.glCode?.description}/>
              </div>
            </div>
            <div className="global-single-input">
              <p>GST Code</p>
              <div className="stock-adjustment-list-view-gl-code">
                <input className="gl-link-code-first" disabled value={singleDraftObj[0]?.gstCode?.code} />
                <input className="gl-link-code-second" disabled value={singleDraftObj[0]?.gstCode?.description}/>
              </div>
            </div>
          </div>
          <hr className="global-hr-dotted" />
          <div className="global-table-container stock-adjustment-list-view-table">
            <table className="global-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Product Name</th>
                  <th>Stock Qty</th>
                  <th>Adjust Qty</th>
                  <th>Unit</th>
                  <th>Unit Cost</th>
                  <th>Total Cost</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>

                {singleDraftObj[0]?.purchaseInfo?.map((r,i)=>(
                  <tr key={i}>
                  <td>{i+1}</td>
                  <td>{r?.itemName}</td>
                  <td>{r?.stock}</td>
                  <td>{r?.adjQty}</td>
                  <td>{r?.unit}</td>
                  <td>{r?.unitCost}</td>
                  <td>{r?.totalCost}</td>
                  <td>-0-</td>
                </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="stock-adjustment-list-view-button-container">
            <button
              onClick={() => setDraftView(false)}
              className="btn btn-secondary-outlined"
            >
              Cancel
            </button>
            <button className="btn btn-secondary" onClick={editDraft(singleDraftObj[0]?._id)}>
              <IconButton>
                <i class="bi bi-pencil-square edit-icon draft-edit-icon"></i>
              </IconButton>
              Edit
            </button>
          </div>
        </div>
      </Dialog>
      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </div>
  );
};
