import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BranchReceiptFilterAPICall, viewAllBranchReceiptAPICall } from "./BranchReceiptAPI";
import { useState } from "react";
import { get_branch_receipt_single_view } from "./BranchReceiptSlice";
import store from "../../../../../Redux/store";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import CustomPagination from "../../../../Single Components/CustomPagination";
import { getCurrentTime } from "../../../../../Js/Date";
import axios from "axios";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";

export const BranchReceipt = () => {
  let navigate = useNavigate();
  // login response
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  const branchReceiptList = useSelector(
    (state) => state.BranchReceiptSlice.value
  );
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = branchReceiptList?.pages;
  const filtereDataOptions = useSelector((state) => state.listCategory);
  
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };

  useEffect(() => {
    localStorage.removeItem("singleViewById");
    // viewAllBranchReceiptAPICall(
    //   { branchId: loginResponse?.data?.branchPk },
    //   setIsLoading
    // );
    store.dispatch(
      get_branch_receipt_single_view({ singleBranchReceiptData: undefined })
    );
  }, []);

  const singleViewFn = (data) => {
    store.dispatch( setFilterActive(window.location.pathname))
    localStorage.setItem("singleViewById", data._id);
    navigate("/userdashboard/inventory/operation/branchreceipt/create");
    // store.dispatch(get_branch_receipt_single_view({singleBranchReceiptData:data}))
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };
  useEffect(() => {
   
    if(FormDataInfo.length!==0){ 
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData &&  BranchReceiptFilterAPICall({
      // fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} ${getCurrentTime()}`,
      // toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} ${getCurrentTime()}`,
    sourcebranchId: FormDataInfo.fromBranch,
    destinationBranchId: FormDataInfo.destinationBranch,
    status: FormDataInfo.status,
    search: FormDataInfo.search,
    index: currentPage-1,
  },setIsLoading);
}

}, [FormDataInfo, currentPage]);

  return (
    <div className="global-page-parent-container">
      {/* table */}
      <div className="global-white-bg-container">
      <div className="justify-space-between"
          style={{ gap: "0 12px", justifyContent: "flex-end" }}
        >
          <CategorySearchandFilter
            statusOptions={['Completed', 'Drafted','Available']}
            onData={handleDataFromChild}
            isStatus={true}
            isFromBranch={true}
            isDestinationBranch={true}
            isBranch={false}
            isDate={false}
            onBranchSelect={handleBranchSelect}
          />
          {/* <button onClick={createBranchTransfer} className='create-button-blue'>Create</button> */}

        </div>
        <div className="create-button-blue-container">
          <h3>Branch Receipt</h3>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />
          {/* <button onClick={createBranchReceipt}  className='create-button-blue'>Create</button> */}
        </div>
        <div className="new-global-table-container">
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>From</th>
                <th>To</th>
                <th>Contact Person</th>
                <th>GRN</th>
                <th>Created By</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {branchReceiptList?.list?.length !== 0 ? (
                branchReceiptList?.list
                  ?.slice(0)
                 
                  ?.map((r, i) => (
                    <tr onClick={() => singleViewFn(r)}>
                      <td>{r?.date}</td>
                      <td>{r?.referenceIn}</td>
                      <td>{r?.sourceLocation}</td>
                      <td>{r?.destinationLocation}</td>
                      <td>{r?.contactPerson}</td>
                      <td>{r?.grnNumber}</td>
                      <td>{r.CREATEDBY}</td>
                      <td className="table-data-box">
                        {r?.status === "Completed" ? (
                          <span className="post"> POST</span>
                        ) : r?.status === "Drafted" ? (
                          <span className="draft"> DRAFT</span>
                        ) : r?.status==="Available"? (
                          <span className='available'> AVAILABLE</span>
                        ): (
                          <span>{r?.status}</span>
                        )}
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan={8}>No Data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
