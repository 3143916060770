import {createSlice} from '@reduxjs/toolkit'

export const viewOldStockSlice=createSlice({
    name:'viewOldStockSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_old_stock_list:(state,action)=>{
            state.value=action.payload.viewOldStockData
        }
    }
})
export const {get_old_stock_list} =viewOldStockSlice.actions;
export default viewOldStockSlice.reducer;