import React, { useEffect } from 'react'
import '../newpayroll.css'
import { Dialog, IconButton, TextField, Tooltip } from '@mui/material'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ViewEstablishmentIdAPI, UpdateEstablishmentIdByIdAPI, ViewEstablishmentIdByIdAPI,AddEstablishmentIdAPI } from './EstablishmentIdAPI';
import { convertDateFormat, today } from '../../../../Js/Date';

function EstablisId() {

    const viewEstablishmentIdRedux = useSelector((state) => state?.establishmentId?.establishmentIdView)
    const viewEstablishmentIdById = useSelector((state) => state?.establishmentId?.viewEstablishIdById)

    const [viewEstablishmentId, setviewEstablishmentId] = useState()
    const [openDialog, setOpenDialog] = useState(false);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [formData, setFormData] = useState({
        _id: '',
        name: '',
        number: '',
        expiryDate: '',
    });
    
    const handleEdit = () => {
        setEditMode(true);
    };
    const handleSave = () => {
        let data = {
            _id: formData?._id,
            name: formData?.name,
            number: formData?.number,
            expiryDate: formData?.expiryDate
        }
        UpdateEstablishmentIdByIdAPI(data, setOpenDialog)

    };
    const handleSubmit = () => {
        let data = {
            _id: formData?._id,
            name: formData?.name,
            number: formData?.number,
            expiryDate: formData?.expiryDate
        }
        AddEstablishmentIdAPI(data, setOpenAddDialog)
        setFormData({
            _id: '',
            name: '',
            number: '',
            expiryDate: '',
        });
        ViewEstablishmentIdAPI()
        setviewEstablishmentId(viewEstablishmentIdRedux);

    };
    const handleCreateClick = (id) => {
        setFormData({
            _id: '',
            name: '',
            number: '',
            expiryDate: '',
        });
        setOpenAddDialog(true);

    };
    const handleCardClick = (id) => {
        let payload = {
            _id: id
        }
        ViewEstablishmentIdByIdAPI(payload)
        setOpenDialog(true);
        setEditMode(false);
    };
    useEffect(() => {
        if (viewEstablishmentIdById) {
            setFormData({
                _id: viewEstablishmentIdById?._id,
                name: viewEstablishmentIdById?.name,
                number: viewEstablishmentIdById?.number,
                expiryDate: viewEstablishmentIdById?.expiryDate,
            });
        }
        if (viewEstablishmentIdRedux) {
            setviewEstablishmentId(viewEstablishmentIdRedux)
        }

    }, [viewEstablishmentIdById, viewEstablishmentIdRedux])
    useEffect(() => {
        ViewEstablishmentIdAPI()
    }, [])

    return (
        <div className="global-page-parent-container">
            <div className="establish-head">
                <div className="create-button-blue-container">
                    <h3>Establishment ID</h3>
                    <button 
                    onClick={handleCreateClick}
                    className="establish-create">
                        Create
                    </button>
                </div>
                <div className='establishment-card-container'>
                    {viewEstablishmentId !== undefined &&
                        viewEstablishmentId?.length !== 0 &&
                        viewEstablishmentId?.map((item, index) => (
                            <div
                                key={index}
                                className='establishment-card'
                                onClick={() => handleCardClick(item._id)}
                            >
                                <div className="establishment-card-text-container">
                                    <h5>{item.name}</h5>
                                    <h5>{item.number}</h5>
                                    <h5>{convertDateFormat(item.expiryDate)}</h5>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
            <Dialog
                open={openDialog}
                onClose={() => {
                    setOpenDialog(false)
                    setEditMode(false)
                    setFormData({
                        _id: '',
                        name: '',
                        number: '',
                        expiryDate: '',
                    });
                }}
                maxWidth="auto"
                sx={{ padding: "7px 36px" }}
            >
                <div className='dialogue-ctn'>
                    <div >
                        <Tooltip title="Edit">
                            <IconButton onClick={handleEdit}>
                                <i className="bi bi-pencil-square edit-icon1"></i>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Save">
                            <IconButton onClick={handleSave}>
                                <i className="bi bi-floppy save-icon"></i>
                            </IconButton>
                        </Tooltip>
                    </div>
                    <hr className="global-hr" />

                    <div className='establish-dialogue-form'>

                        <div style={{ width: "auto" }} className="new-global-single-input">
                            <TextField
                                id="outlined-basic"
                                label="Name"
                                variant="outlined"
                                type="text"
                                focused
                                value={formData?.name}
                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                disabled={!editMode}
                            />
                        </div>

                        <div style={{ width: "auto" }} className="new-global-single-input">
                            <TextField
                                id="outlined-basic"
                                label="Establishment ID No"
                                variant="outlined"
                                type="number"
                                focused
                                value={formData?.number}
                                onChange={(e) => setFormData({ ...formData, number: e.target.value })}
                                disabled={!editMode}

                            />
                        </div>
                    </div>
                    <div style={{ width: "47%" }} className="new-global-single-input">                   
                        <TextField
                            id="outlined-basic"
                            label="Establishment ID Expiry"
                            variant="outlined"
                            type="date"
                            focused
                            value={formData?.expiryDate}
                            onChange={(e) => setFormData({ ...formData, expiryDate: e.target.value })}
                            disabled={!editMode}
                        />
                    </div>
                </div>
            </Dialog>
            <Dialog
                open={openAddDialog}
                onClose={() => setOpenAddDialog(false)}
                maxWidth="auto"
                sx={{ padding: "7px 36px" }}
            >
                <div className='dialogue-ctn'>
                    <div >
                        <Tooltip title="Save">
                            <IconButton onClick={handleSubmit}>
                                <i className="bi bi-floppy save-icon"></i>
                            </IconButton>
                        </Tooltip>
                    </div>
                    <hr className="global-hr" />

                    <div className='establish-dialogue-form'>

                        <div style={{ width: "auto" }} className="new-global-single-input">
                            <TextField
                                id="outlined-basic"
                                label="Name"
                                variant="outlined"
                                type="text"
                                focused
                                value={formData?.name}
                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                // disabled={!editMode}
                            />
                        </div>
                        <div style={{ width: "auto" }} className="new-global-single-input">
                            <TextField
                                id="outlined-basic"
                                label="Establishment ID No"
                                variant="outlined"
                                type="number"
                                focused
                                value={formData?.number}
                                onChange={(e) => setFormData({ ...formData, number: e.target.value })}
                                // disabled={!editMode}

                            />
                        </div>
                    </div>
                    <div style={{ width: "47%" }} className="new-global-single-input">                   
                        <TextField
                            id="outlined-basic"
                            label="Establishment ID Expiry"
                            variant="outlined"
                            type="date"
                            focused
                            value={formData?.expiryDate}
                            onChange={(e) => setFormData({ ...formData, expiryDate: e.target.value })}
                            // disabled={!editMode}
                        />
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default EstablisId