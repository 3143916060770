import axios from "axios";
import store from "../../Redux/store";
import { HEADERS } from "../UrlAndPaths";
import { get_job_completion_report } from "../../Redux/Report/jobCompletionReportSlice";


export const viewJobCompletionReportAPICall=async(body,setIsLoading)=>{
    setIsLoading(true)
    await axios.post("workorder/jobcompletionReport",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(get_job_completion_report({
                    jobCompletionReportList:res.data
                }))
                setIsLoading(false)
            }
        })
        .catch((err)=>{
            store.dispatch(get_job_completion_report({
                jobCompletionReportList:undefined
            }))
            console.error(err.response)
            setIsLoading(false)
        })
}