import React, { useState } from "react";
import "../../../../../css/Purchase Manager/StockAdjustment/receivedStock.css";
import { IconButton, Dialog, Skeleton } from "@mui/material";
import {
  finalFinishReceivedStockAPICall,
  viewAllStockAdjustmentAPICall,
} from "../../../../../API/Purchase Manager/stockAdjustmentAPI";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  calculateTotalCost,
  extractObjectForSingleView,
} from "../../../../../Js/generalFunctions";
import SuccessSnackbar from "../../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
import { convertDateFormat,getCurrentTime,today } from "../../../../../Js/Date";

export const ReceivedStock = () => {
  //Current branch ID
  const bId = localStorage.getItem("branchId");
  //*Redux States
  const allStockAdjList = useSelector((state) => state.allStockAdjSlice.value);

  const [receivedStockDialog, setReceivedStockDialog] = useState(false);
  const [receivedStockEditDialog, setReceivedStockEditDialog] = useState(false);

  //Update Listener States
  const [isModify,setIsModify]=useState(false)
  //Single view Obj
  const [singleViewObj, setSingleViewObj] = useState([]);
  //received Stock Items array
  const [itemsArray, setItemsArray] = useState([]);
   // loading state
   const [isLoading,setIsloading]=useState(false)
   const [searchKeyword,setSearchKeyword]=useState("")
   const [filteredSearchList,setFilteredSearchList]=useState([])
  //Editable Row
  const [editableRow, setEditableRow] = useState([]);
  //Editable Row Index
  const [editableRowIndex, setEditableRowIndex] = useState(null);
  //Editable Adj Qty State
  const editableRowFormInitialState = {
    prodName: "",
    adjQty: 0,
    unit: "",
    unitCost: "",
    totalCost: "",
  };
  const dateToday={today}
  const currentTime=getCurrentTime()
  // validation states
  const [adjustQtyAlert,setAdjustQtyAlert]=useState(false)


  const [editableRowForm, setEditableRowForm] = useState(
    editableRowFormInitialState
  );

  //SnackBar States
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!");

  //Close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setSuccessSnackOpen(false);
  };
  //Close ErrorSnackbar
  const closeErrorSnackbar = () => {
    setErrorSnackOpen(false);
  };

  //Update Listener
  const updateListener=()=>{
    setIsModify(!isModify)
  }



  //*useEffects
  //initial API Calls
  useEffect(() => {
    viewAllStockAdjustmentAPICall(
      { toLoc: bId !== "null" ? bId : null },
      setIsloading,setErrorSnackOpen,setSnackMsg
      );
  }, [isModify]);

  //Set Items Array from response to state
  useEffect(() => {
    if (singleViewObj !== undefined && singleViewObj?.length > 0) {
      const { purchaseInfo } = singleViewObj[0];
      setItemsArray([...purchaseInfo]);
    }
  }, [singleViewObj]);

  //Set Editable Row form to actual values
  useEffect(() => {
    if (editableRow?.length > 0) {
      const { itemName, adjQty, unit, unitCost, totalCost } = editableRow[0];

      setEditableRowForm({
        ...editableRowForm,
        prodName: itemName,
        adjQty,
        unit,
        unitCost,
        totalCost,
      });
    }
  }, [editableRow]);

  //Change totalCost onChange of AdjQty
  useEffect(() => {
    let totalCost = calculateTotalCost(
      editableRowForm?.unitCost,
      editableRowForm?.adjQty
    );
    setEditableRowForm({ ...editableRowForm, totalCost });
  }, [editableRowForm?.adjQty]);

  // open edit dialog
  const openEditDialog = (selectedId, completeList, index) => () => {
    setEditableRow(extractObjectForSingleView(selectedId, completeList));
    setEditableRowIndex(index);
    setReceivedStockEditDialog(true);
    setReceivedStockDialog(false);
  };

  // cancel edit dialog
  const cancelEditDialog = () => {
    setReceivedStockEditDialog(false);
    setReceivedStockDialog(true);
  };

  //*onChange fn
  const getEditedAdjQty = (e) => {
    setEditableRowForm({ ...editableRowForm, adjQty: e.target.value });
    setAdjustQtyAlert(false)
    if(e.target.value===""){
      setAdjustQtyAlert(true)
    }
  };

  //*Click Fns
  //Click Open Single View Icon Button
  const openSingleView = (id) => () => {
    setReceivedStockDialog(true);
    if (allStockAdjList !== undefined) {
      let extractedObj = extractObjectForSingleView(id, allStockAdjList);
      setSingleViewObj(extractedObj);
    }
  };

  //Find total Cost of an object array
  const findTotalCost = (purchaseInfo) => {
    let totalCost = 0;

    purchaseInfo?.forEach((obj, i) => {
      totalCost += obj?.totalCost;
    });

    // console.log(totalCost);
    return totalCost;
  };

  //Click Edit button
  const clickAdjQtyEditBtn = () => {
    if (editableRowIndex !== null && editableRowForm?.adjQty !== "") {
      let editedRow = itemsArray?.map((obj, i) => {
        if (i === editableRowIndex) {
          return {
            ...obj,
            adjQty: parseFloat(editableRowForm?.adjQty),
            totalCost: parseFloat(editableRowForm?.totalCost),
          };
        } else {
          return obj;
        }
      });
      setItemsArray([...editedRow]);
      cancelEditDialog();
    } else {
      setAdjustQtyAlert(true)
    }
  };

  //Click Final Finish button
  const finalFinishBody = {
    _id: singleViewObj[0]?._id,
    purchaseInfo: itemsArray,
    date:`${dateToday.today} ${currentTime}`
  };
  const clickFinalFinishBtn = () => {
    finalFinishReceivedStockAPICall(
      finalFinishBody,
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      closeReceiveDialog,
      updateListener
    );
  };

  const getSearchKeyword=(e)=>{
    setSearchKeyword(e.target.value)
  }
  const SearchFilter=(chars,listToFilter)=>{
    let searchedArray=[]
    let filteredArray=[]
    if(chars!==""){
      searchedArray=listToFilter.filter((obj)=>
      obj?.transNo?.toLowerCase()?.includes(chars?.toLowerCase())||
      obj?.frmBranchName?.toLowerCase()?.includes(chars?.toLowerCase())||
      obj?.toBranchName?.toLowerCase()?.includes(chars?.toLowerCase())
    )
      filteredArray=searchedArray
    }
    else{
      filteredArray=listToFilter
    }
    return filteredArray
  }
  useEffect(()=>{
    if(allStockAdjList!==undefined){
      let filteredArray=[]
      setFilteredSearchList(allStockAdjList)
      filteredArray=SearchFilter(searchKeyword,allStockAdjList)
      setFilteredSearchList(filteredArray)    
    }
    else{
      setFilteredSearchList([])
    }
  },[searchKeyword,allStockAdjList])
  //Close Receive Dialog
  const closeReceiveDialog=()=>{
    setReceivedStockDialog(false)
  }
  return (
    <div className="global-page-parent-container">
      <p className="breadcrumb">
        Purchase Manage &gt; Stock Adjustment &gt; Received Stock
      </p>
      <div className="received-stock-container">
        <div className="stock-out-product-top-container" style={{alignItems:"center"}}>
          <h3>Received Stock</h3>
            <div className="view-order-search-container view-order-admin-search-container" style={{width:"25%"}}>
                  <input
                    placeholder="Search Transaction Id,Location"
                    value={searchKeyword}
                    onChange={getSearchKeyword}
                  />
                  <IconButton>
                      <i class="bi bi-search search-icon-order"></i>
                  </IconButton>
              </div>
            </div>
        <div className="global-table-container">
          <table className="global-table">
            <thead>
              <tr>
                {/* <th>No</th> */}
                <th>Transaction ID</th>
                <th>From Location</th>
                <th>Location</th>
                <th>Date</th>
                <th className="stock-adj-remarks">Remarks</th>
                <th>Total Cost</th>
                <th>View</th>
                {/* <th>Edit</th> */}
                <th>Delete</th>
              </tr>
            </thead>
            {
              isLoading?
                <tbody>
                  <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr> 
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr> 
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr> 
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
            </tbody>:
            <tbody>
              {
              filteredSearchList.length!==0?
              filteredSearchList?.slice(0)?.reverse()?.map((r, i) => {
                if (r?.status === "Pending") {
                  return (
                    <tr key={i}>
                      {/* <td>{i + 1}</td> */}
                      <td>{r?.transNo}</td>
                      <td>{r?.frmBranchName}</td>
                      <td>{r?.toBranchName}</td>
                      <td>{convertDateFormat(r?.date)}</td>
                      <td className="stock-adj-remarks">{r?.remarks}</td>
                      <td>{findTotalCost(r?.purchaseInfo)}</td>
                      <td>
                        <IconButton onClick={openSingleView(r?._id)}>
                          <i class="bi bi-eye visibility-icon"></i>
                        </IconButton>
                      </td>
                      {/* <td>
                        <IconButton>
                          <i class="bi bi-pencil-square edit-icon"></i>
                        </IconButton>
                      </td> */}
                      <td>
                        <IconButton disabled className="disabled-icon-button">
                          <i class="bi bi-trash3 delete-icon"></i>
                        </IconButton>
                      </td>
                    </tr>
                  );
                }
              }):<tr><td colSpan={8} style={{textAlign:"center"}}>No Records</td></tr>}
            </tbody>}
          </table>
        </div>
      </div>

      {/* received stock single view dialog */}
      <Dialog open={receivedStockDialog} maxWidth="lg"  onKeyDown={(e)=>e.key === "Escape" && setReceivedStockDialog(false)}>
        <div className="received-stock-dialog-container">
          <h3>Stock Adjustment Received</h3>
          <div className="received-stock-dialog-top-container">
            <div className="global-single-input received-stock-input">
              <p>Transaction ID</p>
              <input disabled value={singleViewObj[0]?.transNo} />
            </div>
            <div className="global-single-input received-stock-input">
              <p>Location</p>
              <input disabled value={singleViewObj[0]?.frmBranchName} />
            </div>
          </div>
          <div className="received-stock-dialog-table-container">
            <h3>Adjust Product</h3>
            <div className="global-table-container received-stock-table">
              <table className="global-table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Product Name</th>
                    <th>Adjust Qty</th>
                    <th>Unit</th>
                    <th>Unit Cost</th>
                    <th>Total Cost</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {itemsArray?.length > 0 ? (
                    itemsArray?.map((r, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{r?.itemName}</td>
                        <td>{r?.adjQty}</td>
                        <td>{r?.unit}</td>
                        <td>{r?.unitCost}</td>
                        <td>{r?.totalCost}</td>
                        <td>
                          <IconButton
                            onClick={openEditDialog(r?._id, itemsArray, i)}
                          >
                            <i class="bi bi-pencil-square edit-icon"></i>
                          </IconButton>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7}>No Items</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="received-stock-single-view-button-container">
            <button
              onClick={closeReceiveDialog}
              className="btn btn-secondary-outlined"
            >
              Cancel
            </button>
            <button className="btn btn-secondary" onClick={clickFinalFinishBtn}>Finish</button>
          </div>
        </div>
      </Dialog>

      {/* edit dialog */}
      <Dialog open={receivedStockEditDialog} maxWidth="lg"  onKeyDown={(e)=>e.key === "Escape" && setReceivedStockEditDialog(false)}>
        <div className="received-stock-dialog-container">
          <h3>Edit</h3>
          <div className="received-stock-dialog-top-container">
            <div className="global-single-input received-stock-input">
              <p>Transaction ID</p>
              <input disabled value={singleViewObj[0]?.transNo} />
            </div>
            <div className="global-single-input received-stock-input">
              <p>Location</p>
              <input disabled value={singleViewObj[0]?.frmBranchName} />
            </div>
          </div>
          <div className="received-stock-dialog-table-container">
            <h3>Adjust Product</h3>
            <div className="received-stock-edit-table-container">
              {/* <div className="global-single-input edit-table-input">
                <p>No</p>
                <input disabled value={"01"} />
              </div> */}
              <div className="global-single-input edit-table-input">
                <p>Product Name</p>
                <input disabled value={editableRowForm?.prodName} />
              </div>
              <div className="global-single-input edit-table-input adj-qty">
                <p>Adjust Qty</p>
                <input
                  value={editableRowForm?.adjQty}
                  onChange={getEditedAdjQty}
                />
                {
                  adjustQtyAlert?
                  <p className="doc-validation-alert">Fill this field !!</p>
                :""
                }
              </div>
              <div className="global-single-input edit-table-input">
                <p>Unit</p>
                <input disabled value={editableRowForm?.unit} />
              </div>
              <div className="global-single-input edit-table-input">
                <p>Unit Cost</p>
                <input disabled value={editableRowForm?.unitCost} />
              </div>
              <div className="global-single-input edit-table-input">
                <p>Total Cost</p>
                <input disabled value={editableRowForm?.totalCost} />
              </div>
            </div>
          </div>
          <div className="received-stock-single-view-button-container">
            <button
              onClick={cancelEditDialog}
              className="btn btn-secondary-outlined"
            >
              Cancel
            </button>
            <button className="btn btn-secondary" onClick={clickAdjQtyEditBtn}>
              Edit
            </button>
          </div>
        </div>
      </Dialog>

      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </div>
  );
};
