import axios from "axios";
import store from "../../../Redux/store";
import { HEADERS } from "../../UrlAndPaths";
import { update_all_stockout_list } from "../../../Redux/Prchase Manager/StockOut Products/allStockOutSlice";
import { update_all_stock_out_products_list } from "../../../Redux/Prchase Manager/StockOut Products/stockOutListSlice";


// Create New StockOut

export const createStockOutAPICall = async(body,updateListener,
    setError,
    setSuccess,setMsg,clearState)=>{
        await axios.post('purchase/addstockOut',body,HEADERS).then((res)=>{
            if(res.status === 200){
                console.log("New StockOut Created");
                updateListener();
                setMsg("Success");
                setSuccess(true);
                clearState();
            }
        }).catch((err) => {
            if (err.response.status === undefined) {
                setMsg("Network Connection error!!");
            }
            if (err.response.status === 409) {
                setMsg("Department Already Exist");
            }
    
            if (err.response.status === 401) {
                setMsg("Unauthorized");
            }
            if (err.response.status === 403) {
                setMsg("Forbidden");
            }

            setError(true);
            console.error(err.response);
        })
    }



    // Get All StockOut Items 
  
export const getAllStockOutAPICall = async (setIsLoading,setErrorSnack,setSnackMsg) =>{
    if(setIsLoading!==undefined)setIsLoading(true)
    await axios.post('purchase/viewstockOut',{},HEADERS).then((res)=>{
        if(res.status === 200) {
            store.dispatch(update_all_stockout_list({
                allStockOut: res.data
            }))
            if(setIsLoading!==undefined)setIsLoading(false)
        }
    }).catch((err)=>{
        console.error(err.response);
        store.dispatch(update_all_stockout_list({
            allStockOut: undefined
        }))
        switch (err.response.status) {
            case 404:
                setSnackMsg('No data available..')
              break;
            case 500:
                setSnackMsg('Server error!!')
              break;
            case 401:
                setSnackMsg('Unauthorized!!')
              break;
            case 403:
                setSnackMsg('Forbidden!!')
              break;
          
            default:
                setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
              break;
          }
          setErrorSnack(true)
        if(setIsLoading!==undefined)setIsLoading(false)
    })
}


//view all stockOut products (material,readymade,accessories)
export const viewAllStockOutProductsAPICall = async (body) => {
   
   
    await axios
      .post("purchase/viewstockOutProducts", body, HEADERS)
      .then((res) => {
        if (res.status === 200) {
          store.dispatch(update_all_stock_out_products_list({ stockOutList: res.data }));
          
        }
      })
      .catch((err) => {
        store.dispatch(update_all_stock_out_products_list({ stockOutList: undefined }));
        console.error(err.response);
        
      });
  };