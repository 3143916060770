import {MenuItem, Select, Skeleton } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { viewGrnUpdatedListAPICall } from "./grnListingAPI";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import store from "../../../../../Redux/store";
import { get_grn_list_row_data_list } from "./grnListingSlice";
import { convertDateFormat } from "../../../../../Js/Date";


export const GrnListing=()=>{

    let navigateTo=useNavigate()
    const tableData=useSelector((state)=>state.goodsReceivedNoteUpdatedSlice.apiResList)
    
    const [selectCategory,setSelectCategory]=useState("Draft")
    const [filteredArray,setFilteredArray]=useState([])
    const [isLoading,setIsLoading]=useState(false)


    const handleClickRow=(row)=>{
        store.dispatch(get_grn_list_row_data_list({grnListSingleRowData:row}))
        navigateTo("/userdashboard/purchaseManager/GoodsReceivedNote/add")
      }
    useEffect(()=>{
        viewGrnUpdatedListAPICall({branchId:"TESPA1"},setIsLoading)
    },[])
    useEffect(() => {
        let filteredArray = [];
      
        if (tableData !== undefined) {
          if (selectCategory === "Draft") {
            filteredArray = tableData.filter((obj) => obj.status === "Drafted");
          } else if (selectCategory === "Posted") {
            filteredArray = tableData.filter((obj) => obj.status === "Complete");
          }
        } else {
          filteredArray = [];
        }
      
        setFilteredArray(filteredArray);
      }, [selectCategory, tableData]);

    return(
        <>
        <div className="global-page-parent-container">
            <p className="breadcrumb">Purchase Manage &gt; Goods Received Note </p>
            <div className="global-white-bg-container">
                <div className="justify-space-between">
                    <h3>
                        {selectCategory}
                    </h3>
                    <div style={{width:"65%"}} className="justify-end">
                        <div className="global-single-input">
                            <Select value={selectCategory}
                                onChange={(e)=>setSelectCategory(e.target.value)}
                            >
                                <MenuItem value="Draft">Draft</MenuItem>
                                <MenuItem value="Posted">Posted</MenuItem>
                            </Select>
                        </div>
                        {/* <div className="search-box-container">
                            <input
                                type="text"
                                name=""
                                id=""
                                placeholder="Search Location..."
                                onChange={""}
                            />
                            <IconButton
                                className="icon-btn"
                                style={{ position: "absolute", right: "10px" }}
                            >
                                <i class="bi bi-search search-icon"></i>
                            </IconButton>
                        </div> */}
                        {/* <button 
                        onClick={()=>navigateTo("/userdashboard/purchaseManager/GoodsReceivedNote/add")}
                        className="btn btn-primary" style={{width:"30%",backgroundColor:"#ff7700"}}>Add GRN</button> */}
                    </div>
                </div>

                <div className="global-table-container">
                    <table className="global-table">
                        <thead>
                            <tr>
                                <th>No</th>
                                {selectCategory==="Posted" &&<th>Name</th>}
                                <th>Purchase Order</th>
                                <th>Date</th>
                                <th>Branch</th>
                                {selectCategory==="Posted" &&<th>Location</th>}
                                <th>Vendor</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                                {isLoading==="lll" ? (
                                    [...Array(10)].map((r, i) => (
                                        <tr key={i}>
                                            <td colSpan={9}>
                                                <Skeleton
                                                    variant="rectangular"
                                                    animation="wave"
                                                    width={"100%"}
                                                    height={40}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                ) : 
                                filteredArray.length!==0?
                                filteredArray?.slice(0)?.reverse()?.map((r,i)=>(
                                    <tr
                                    onClick={() => handleClickRow(r)}
                                    key={i}>
                                        <td>{i+1}</td>
                                        {selectCategory==="Posted" &&<td>{r?.name}</td>}
                                        <td>{r?.name}</td>
                                        <td>{convertDateFormat(r?.date)}</td>
                                        <td>{r?.branchId}</td>
                                        {selectCategory==="Posted" &&<td>{r?.locationName}</td>} 
                                        <td>{r?.supplierName}</td>
                                        <td className='table-data-box'>{
                                            r?.status === "Complete" ?
                                                <span className='pending'> Posted</span>
                                                : r?.status === "Drafted" ?
                                                    <span className='drafted'> Drafted</span>
                                                    : "---"
                                        }</td>
                                    </tr>
                                        )
                                ):
                                    <tr>
                                        <td colSpan={selectCategory==="Posted"?9:7}>No data</td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        </>
    )
}