import axios from 'axios'
import {get_transfer_id} from '../../Redux/Prchase Manager/Transfer/generateTransferIdSlice'
import {HEADERS} from '../../API/UrlAndPaths'
import store from '../../Redux/store'
import {get_all_transfer_list} from '../../Redux/Prchase Manager/Transfer/viewAllTransferSlice'
import {get_transfer_single_view} from '../../Redux/Prchase Manager/Transfer/transferSingleViewSlice'
import {get_all_received_transfer} from '../../Redux/Prchase Manager/Transfer/receivedTransferSlice'
import {get_received_transfer_single_view} from '../../Redux/Prchase Manager/Transfer/receivedTransferSingleViewSlice'
import { get_confirm_transfer } from '../../Redux/Prchase Manager/Transfer/confirmTransferListSlice'

// generate transfer id
export const generateTransferIdAPICall= async ()=>{
  await axios.get("purchase/generateTransferId",HEADERS)
  .then((res)=>{
    if(res.status===200){
        store.dispatch(get_transfer_id({transferID:res.data}))
    }
  })
  .catch((err)=>{
    store.dispatch(get_transfer_id({transferID:undefined}))
    console.log(err.response);
  })
}

// view all transfer list api call 
export const viewAllTransferList= async (setIsLoading,setErrorSnack,setSnackMsg)=>{
  if(setIsLoading!==undefined) setIsLoading(true)
  await axios.get("purchase/getAllTransfers",HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_all_transfer_list({allTransferListData:res.data}))
      if(setIsLoading!==undefined) setIsLoading(false)
    }
  })
  .catch((err)=>{
    store.dispatch(get_all_transfer_list({allTransferListData:undefined}))
    console.log(err.response);
    switch (err.response.status) {
      case 404:
          setSnackMsg('No data available..')
        break;
      case 500:
          setSnackMsg('Server error!!')
        break;
      case 401:
          setSnackMsg('Unauthorized!!')
        break;
      case 403:
          setSnackMsg('Forbidden!!')
        break;
    
      default:
          setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
        break;
    }
    setErrorSnack(true)
    if(setIsLoading!==undefined) setIsLoading(false)
  })
}

// add transfer api call
export const addTransferAPICall=async(body,setAddTransferDialog,updateListner)=>{

  await axios.post("purchase/addTransfer",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      console.log('transfer added');
      updateListner()
      setAddTransferDialog(false)
    }
  })
  .catch((err)=>{
    console.log(err.response);
  })
}


// transfer single view api call
export const transferSingleViewAPICall= async(body)=>{
  await axios.post("purchase/transferReportSingleView",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_transfer_single_view({transferSingleViewData:res.data}))
    }
  })
  .catch((err)=>{
    store.dispatch(get_transfer_single_view({transferSingleViewData:undefined}))
    console.log(err.response);
  })
}

// received transfer api call
export const receivedTransferAPICall= async (setIsLoading,setErrorSnack,setSnackMsg,body)=>{
  if(setIsLoading!==undefined) setIsLoading(true)
await axios.post("purchase/receivedTransfers",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_all_received_transfer({receivedTransferData:res.data}))
      if(setIsLoading!==undefined) setIsLoading(false)
    }
  })
  .catch((err)=>{
    store.dispatch(get_all_received_transfer({receivedTransferData:undefined}))
    console.log(err.response);
    switch (err.response.status) {
      case 404:
          setSnackMsg('No data available..')
        break;
      case 500:
          setSnackMsg('Server error!!')
        break;
      case 401:
          setSnackMsg('Unauthorized!!')
        break;
      case 403:
          setSnackMsg('Forbidden!!')
        break;
    
      default:
          setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
        break;
    }
    setErrorSnack(true)
    if(setIsLoading!==undefined) setIsLoading(false)
  })
}

// received transfer single view api call
export const receivedTransferSingleViewAPICall= async (body)=>{
  await axios.post("purchase/transferSingleView",body,HEADERS)
  .then((res)=>{
     if(res.status===200){
      store.dispatch(get_received_transfer_single_view({receivedTransferSingleViewData:res.data}))
     }
  })
  .catch((err)=>{
    store.dispatch(get_received_transfer_single_view({receivedTransferSingleViewData:undefined}))
    console.log(err.response);
  })
}

// received transfer edit api call
export const receivedTransferEditAPICall= async (body,setReceivedTransferView,updateListner)=>{
 await axios.put("purchase/editTransfer",body,HEADERS)
 .then((res)=>{
  if(res.status===200){
    console.log('edit success');
    setReceivedTransferView(false)
    updateListner()
  }
 })
 .catch((err)=>{
  console.log(err.response);
 })
}

// confirm transfer list
export const confirmTransferListAPICall= async (setIsLoading,setErrorSnack,setSnackMsg,body)=>{
  if(setIsLoading!==undefined) setIsLoading(true)
  await axios.post("purchase/confirmTransfers",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_confirm_transfer({confirmTransferData:res.data}))
      if(setIsLoading!==undefined) setIsLoading(false)
    }
  })
  .catch((err)=>{
    store.dispatch(get_confirm_transfer({confirmTransferData:undefined}))
    console.log(err.response);
    switch (err.response.status) {
      case 404:
          setSnackMsg('No data available..')
        break;
      case 500:
          setSnackMsg('Server error!!')
        break;
      case 401:
          setSnackMsg('Unauthorized!!')
        break;
      case 403:
          setSnackMsg('Forbidden!!')
        break;
    
      default:
          setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
        break;
    }
    setErrorSnack(true)
    if(setIsLoading!==undefined) setIsLoading(false)
  })
}