import { Dialog, IconButton } from '@mui/material'
import React,{useState} from 'react'
import '../../../../../../css/Staff/HRM/Tab/empPayslip.css'

export const EmpPayslip = () => {
    const [paySlipView,setPaySlipView]=useState(false)
  return (
    <div className='dashboard-employee-leave-details-container'>
         <div className='dashboard-employee-leave-details-top-container'>
         <div className='dashboard-employee-leave-details-top-btn-grp-container'>
           <h4>PaySlip Summary</h4>
         </div>
         <div className='dashboard-employee-leave-details-search-container'>
             <input type="text" placeholder='Search...'/>
             <button>Search</button>
         </div>
      
      </div>
      <div className='global-table-container dashboard-leave-details-table-container'>
         <table className='global-table'>
          <thead>
          <tr>
            <th>No</th>
            <th>Transaction ID</th>
            <th>Month</th>
            <th>Year</th>
            <th>Net Salary</th>
            <th>Date</th>
            <th>View</th>
            <th>Delete</th>
           </tr>
          </thead>
          <tbody>
            <tr>
              <td>01</td>
              <td>001</td>
              <td>March</td>
              <td>2022</td>
              <td>20000</td>
              <td>1-02-2022</td>
              <td>
              <IconButton onClick={()=>setPaySlipView(true)}>
               <i class="bi bi-eye visibility-icon"></i>
              </IconButton>
              </td>
              <td>
              <IconButton>
              <i class="bi bi-trash3 delete-icon"></i>
              </IconButton>
              </td>
            </tr>
          </tbody>
          
         </table>
      </div>

      {/* payslip dialog */}
      <Dialog open={paySlipView} maxWidth="lg" onClose={()=>setPaySlipView(false)} onKeyDown={(e)=>e.key === "Escape" && setPaySlipView(false)}>
         <div className='dashboard-employee-payslip-view-container'>
           <div className='dashboard-employee-payslip-view-top-container'>
              <h3>Payslip</h3>
              <div className='dashboard-employee-payslip-view-top-icon-container'>
              <IconButton className='payslip-print-icon'>
                <i class="bi bi-printer printer-icon"></i>
             </IconButton>
             <IconButton className='payslip-download-icon'>
               <i class="bi bi-arrow-down-circle download-icon"></i>
             </IconButton>
              </div>
           </div>
           <hr  className='global-hr'/>
           <div className='dashboard-employee-payslip-view-bottom-container'>
              <div className='dashboard-employee-payslip-view-bottom-first-container'>
                <p>Employee Name&nbsp;:&nbsp;Deepak Dinesh</p>
                <p>Employee ID &nbsp;:&nbsp;EMPWH001</p>
              </div>
              <div className='dashboard-employee-payslip-view-bottom-first-container'>
                <p>Total Leave&nbsp;:&nbsp;05</p>
              </div>
              <div className='payslip-view-table'>
                 <div className='earnings'>
                    <h3>Earnings</h3>
                    <div className='earnings-sub-heading'>
                      <p className='pay-type'>PayType</p>
                      <p className='amount'>Amount</p>
                    </div>
                    <div className='earnings-content'>
                      <p className='pay-type'>Basic</p>
                      <p className='amount'>Rs,15,000</p>
                    </div>
                    <div className='earnings-content'>
                      <p className='pay-type'>Medical Allowance</p>
                      <p className='amount'>0.00</p>
                    </div>
                    <div className='earnings-content'>
                      <p className='pay-type'>Bonus Allowance</p>
                      <p className='amount'>0.00</p>
                    </div>

                    <div className='total-earnings-salary'> 
                    <p className='pay-type'>Total Earnings</p>
                      <p className='amount'>15,000</p>
                    </div>
                 </div>
                 <div className='earnings'>
                  <h3>Deduction</h3>
                  <div className='earnings-sub-heading'>
                      <p className='pay-type'>PayType</p>
                      <p className='amount'>Amount</p>
                    </div>
                    <div className='earnings-content'>
                      <p className='pay-type'>PF</p>
                      <p className='amount'>0.00</p>
                    </div>
                    <div className='earnings-content'>
                      <p className='pay-type'>Tax</p>
                      <p className='amount'>0.00</p>
                    </div>
                    <div className='earnings-content'>
                      <p className='pay-type'>&nbsp;</p>
                      <p className='amount'>&nbsp;</p>
                    </div>

                    <div className='total-earnings-salary'> 
                    <p className='pay-type'>Total Deductions</p>
                      <p className='amount'>0.00</p>
                    </div>
                 </div>
              </div>
              <div className='payslip-net-salary-container'>
                <div className='payslip-net-salary-left-container'>
                  <h4>Net Salary</h4>
                  <p>In Words</p>
                </div>
                <div className='payslip-net-salary-left-container'>
                  <h4>Rs,15,000</h4>
                  <p>Fifteen Thousand</p>
                </div>
              </div>
              <div className='payslip-address-container'>
                 <h4>Company Address</h4>
                 <p>Govt Cyberpark,Sahya Buliding Palazhi ,Kozhikode</p>
              </div>
           </div>
         </div>
      </Dialog>
    </div>
  )
}
