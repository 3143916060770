import React,{useState} from 'react'
import {Select} from '@mui/material'
import {PaymentsWO} from '../Orders/WorkOrder/PaymentsWO'
// css
// oldStock.css
export const QuickBill = () => {
  const [taxChecked,setTaxChecked]=useState(false)
  const [payment,setPayment]=useState(false)

  const taxCheckboxChange=(e)=>{
    setTaxChecked(e.target.checked)
  }

  const openPaymentPopup=()=>{
    setPayment(true)
}
const closePaymentPopup=()=>{
    setPayment(false)
}
  

  return (
    <div className="global-page-parent-container">
    <p className="breadcrumb">Sales &gt; Quick Bill</p>
    <div className="global-white-bg-container">
      <h3>Quick Bill</h3>
      <hr  className="global-hr"/>
      <div className="quick-bill-input-container">
        <div className="global-single-input">
          <p>Doc No</p>
          <input type="text" value="DOC001" disabled/>
        </div>
        <div className="global-single-input">
          <p>Customer</p>
         <Select></Select>
        </div>
        <div className="global-single-input">
          <p>Order No</p>
          <input type="text"/>
        </div>
        <div className="global-single-input">
          <p>Date</p>
          <input type="date"/>
        </div>
        <div className="global-single-input">
          <p>Mobile</p>
          <input type="text" maxLength="15"/>
        </div>
        <div className="global-single-input">
          <p>Delivery Date</p>
          <input type="text"/>
        </div>
        <div className="global-single-input">
          <p>Product</p>
         <Select></Select>
        </div>
        <div className="global-single-input">
          <p>Product Qty</p>
          <input type="text"/>
        </div>
        <div className="global-single-input">
          <p>Advance Amount</p>
          <input type="text"/>
        </div>
        <div className="global-single-input">
          <div className="quick-bill-input-checkbox">
          <p>Tax</p>
          <input type="checkbox" value={taxChecked} onChange={taxCheckboxChange}/>
          </div>
          <input type="text" disabled={taxChecked===false}/>
        </div>
      </div>

      <div className="sales-quick-bill-button-container">
          <button className="sales-quick-bill-button quick-bill-without-payment">Proceed Without Payment</button>
          <button onClick={openPaymentPopup} className="sales-quick-bill-button quick-bill-payment">Payment</button>

          {
            payment===true&&
            <PaymentsWO 
            closePayments={closePaymentPopup}
            marginTop="3%" width="35%" left="64%" boxShadow="0 0 10px 2px #eee"
            />
          }
      </div>
    </div>
    </div>
  )
}
