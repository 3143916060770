import React,{useState,useEffect,useRef} from 'react'
import '../../../css/Sales/credit.css'
import {CreditAndWallet} from '../../Single Components/CreditAndWallet'
import { Autocomplete, Divider, IconButton,Skeleton, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { CallMadeOutlined, CallReceivedOutlined } from "@mui/icons-material";
import {viewWalletByCustomerIdAPICall, viewWalletSearchListAPICall} from "../../../API/Credit and Wallet/creditAndWalletAPI"
import {useSelector} from 'react-redux'
import ReactToPrint from "react-to-print";
import {convertDateFormat, today} from '../../../Js/Date'
import { viewAllCustomersAPICall } from '../../../API/Customer/customerAPI';
import { companyProfileInfoForUser, viewAllBranchesAPICall } from '../../../API/Settings/Company Settings/companySettingsAPI';
import { viewAllCurrencyUpdated } from '../../../API/Settings/Currency/CurrencyAPI';
import { filterObjFromList } from '../../../Js/generalFunctions';
export const Wallet = () => {
  let walletPrint = useRef()
  let summaryPrint= useRef()
  // wallet data
  const  walletList =useSelector((state)=>state.walletOfCustomerSlice.value)
// login response
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  const userLoginMeta = localStorage.getItem('login_meta')
  const userBranchId=JSON.parse(userLoginMeta)?.branchPk;
// role based rendering
const userRole = useSelector((state) => state.userRoleSlice.value);
 // all branch list
 const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
 //List of All Customers
 const customerList = useSelector((state) => state.allCustomerListSlice.value);
 const currencyListUpdated = useSelector(
  (state) => state.viewUpdatedCurrency.value
);

const companyProfileData =useSelector(
  (state) => state?.companyProfileSlice?.userValue)


 //storeCode
 const storeCode=localStorage.getItem('branchId')
  const [branch,setBranch]=useState(null);
  const [customer,setCustomer]=useState(null)
  const [fromDate,setFromDate]=useState('')
  const [toDate,setToDate]=useState('')
  const [searchInput,setSearchInput]=useState('')
  const [filteredData,setFilteredData]=useState([])
  const [time,setTime]=useState(null)
  const [isLoading,setIsLoading]=useState(false)
  const [summary,setSummary]=useState(false)
  const [symbol, setSymbol] = useState(null);

   // get current time fn
   useEffect(() => {
    setInterval(() => {
      setTime(`${new Date().getHours()}:${new Date().getMinutes()}`);
    }, 1000);
  }, [time]);


  useEffect(()=>{
    viewWalletByCustomerIdAPICall({},setIsLoading);
    viewAllCustomersAPICall({branchId:userRole==="admin"?null:userBranchId})

    viewAllBranchesAPICall();
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  },[])
  useEffect(()=>{
    let currencyObj=filterObjFromList("_id",currencyListUpdated,"currency",companyProfileData)

    setSymbol(currencyObj?.symbol || "")
  },[companyProfileData,currencyListUpdated])
  // onchange functions
  const getFromDate=(e)=>{
    setFromDate(e.target.value)
  }
  const getToDate=(e)=>{
    setToDate(e.target.value)
  }
  const getSearchInput=(e)=>{
    setSearchInput(e.target.value)
  }

// FILTER BY DATE
useEffect(()=>{
    if(walletList !== undefined){
      let filteredArray=walletList?.list?.filter((obj)=>{
        if(fromDate<=obj?.date && toDate>=obj?.date){
          return obj;
        }
        else if(fromDate ===""&& toDate ===""){
          return obj;
        }
        else if(fromDate<=obj?.date  && toDate ===""){
          return obj;
        }
        else if(fromDate === "" && toDate >= obj?.date){
          return obj
        }
      })
      setFilteredData(filteredArray)
    }
},[walletList,fromDate,toDate])

// search filter
useEffect(()=>{
  if(walletList !== undefined){
    let charFilterArray=walletList?.list?.filter((obj)=>{
      if(obj.customerName?.toLowerCase()?.includes(searchInput.toLowerCase())){
        return obj;
      }
      else if(searchInput === ''){
        return obj;
      }
    })
    setFilteredData(charFilterArray)
  }
},[searchInput])

// body to api
const bodyToApi={
  branchId:branch,
  cusId:customer,
  fromDate:fromDate === ''?null:fromDate,
  toDate:toDate === ''?null:toDate,
  isSummary:summary
}

// submit button function
const submitButtonFn=()=>{
  viewWalletByCustomerIdAPICall(bodyToApi,setIsLoading)
}

useEffect(()=>{
  viewWalletByCustomerIdAPICall(bodyToApi,setIsLoading)
},[summary])


useEffect(()=>{
    if(searchInput !== ""){
      viewWalletSearchListAPICall({search:searchInput})
    }
    else{
      // viewWalletByCustomerIdAPICall({},setIsLoading)
      viewWalletByCustomerIdAPICall(bodyToApi,setIsLoading)
    }
},[searchInput])



  return (
    <div className="global-page-parent-container">
    <p className="breadcrumb">Sales &gt; Wallet</p>
      {/* <CreditAndWallet label="Balance" 
      background="linear-gradient(148deg, #162fb5,#043cef)"/> */}
        <div  className="credit-container-top">
        <div className="credit-container-top-left">
        <CreditAndWallet 
        label="Balance" 
        background="linear-gradient(148deg, #162fb5,#043cef)"
        width="370px"
        amount={walletList?.total}
        />
        </div>
        <div className="credit-container-top-right">
        <h4>Total Wallet</h4>
        <p>{symbol} {""} {walletList?.total}</p>
        <div className="credit-container-top-right-amount-container">
            <div className="credit-single-amount-container">
               <CallMadeOutlined sx={{color:"#eb0000",fontSize:"1.3rem"}}/>
               <p>{symbol} {""} {walletList?.debitTotal}</p>
            </div>
            <div className="credit-single-amount-container second-amt-container">
               <CallReceivedOutlined sx={{color:"#00c965",fontSize:"1.3rem"}}/>
               
               <p>{symbol} {""} {walletList?.creditTotal}</p>
            </div>
        </div>
        </div>
      </div>

      <div className='credit-content-container'>
         <h3 style={{margin:"0 1%"}}>Wallet</h3>
         <Divider/>
         <div className='credit-content-top-container'>
          {
            userRole === 'admin' &&
            <div className='global-single-input auto-complete'>
            <p>Branch</p>
            <Autocomplete
               sx={{ width: "100%" }}
               options={allBranchesList||[""]}
               renderInput={(params)=>(
                 <TextField {...params} />
               )}
               getOptionLabel={(option) =>
                 `${option?.storeCode}-${option?.branchName}`
               }
               onChange={(e, newValue) => setBranch(newValue?._id)}
            />
         </div>
          }
         
          <div className='global-single-input auto-complete'>
             <p>Name</p>
             <Autocomplete
               sx={{ width: "100%" }}
               options={customerList||[""]}
               renderInput={(params)=>(
                 <TextField {...params} />
               )}
               getOptionLabel={(option) => option.name}
               onChange={(e, newValue) => setCustomer(newValue?._id)}
             />
          </div>
          <div className='global-single-input'>
             <p>From</p>
             <input type="date"  onChange={getFromDate}/>
          </div>
          <div className='global-single-input'>
             <p>To</p>
             <input type="date" onChange={getToDate}/>
          </div>
          <button onClick={submitButtonFn} className='btn btn-primary' style={{margin:"3% 1% 1% 1%"}}>Submit</button>
         </div>
      </div>

      <div className='credit-content-container'>
        <div className='credit-content-top-container'>
          <h3>Transaction History</h3>
          <div className='credit-content-top-right-container'>
           
            <div style={{margin:"1%",width:"100%",display:"flex"}}>
            <ReactToPrint
              trigger={()=>(
                <IconButton className="print-icon-container credit-print-icon">
                <i class="bi bi-printer printer-icon"></i>
              </IconButton>
              )}
              content={()=> summary === true ? summaryPrint : walletPrint}
              pageStyle="@page { size: auto;  margin: 15mm 0 10mm 0; } }"
            />
         
          
           
            
           
            <div className='credit-search-container'>
               <input value={searchInput} onChange={getSearchInput} type="text" placeholder='Search...'/>
               <IconButton className='credit-search-icon'>
                  <SearchIcon/>
               </IconButton>
            </div>
            </div>
            <div className='wallet-summary-container'>
              <input type="checkbox" id="wallet-summary" 
              value={summary}
              onChange={()=>setSummary(!summary)}
              />
              <label htmlFor="wallet-summary">Summary</label>
            </div>
          </div>
          
        </div>
        <div className='global-table-container'>
            <table className='global-table'>
              <thead>
                <tr>
                    <th>No</th>
                    <th>Name</th>
                    <th>Transaction ID</th>
                    <th>Date</th>
                    <th >
                         <div className="wallet-table-cell">
                         <CallReceivedOutlined sx={{color:"#00c965",fontSize:"1rem"}}/>
                          <p>Credit</p>
                        </div>
                       
                    </th>
                    <th>
                        <div className="wallet-table-cell" style={{color:'#eb0000'}}>
                        <CallMadeOutlined sx={{color:"#eb0000",fontSize:"1rem"}}/>
                         <p>Debit</p>
                        </div>
                       
                    </th>
                </tr>
              </thead>
              {
                isLoading ?
                (
                  <tbody>
                    <tr>
                      <td colSpan={11}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={30}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={11}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={30}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={11}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={30}
                        />
                      </td>
                    </tr>
                  </tbody>
                )
                :
                (
                  <tbody>
                  {
                  walletList?.list?.length !==0 ? walletList?.list?.map((r,i)=>(
                      <tr>
                      <td>{i+1}</td>
                      <td>{r?.name}</td>
                      <td>{r?.transNo}</td>
                      <td>{convertDateFormat(r?.logDate)}</td>
                      <td>{r?.credit}</td>
                      <td>{r?.debit}</td>
                   </tr>
                    ))
                    :
                    <tr>
                      <td colSpan={6}>No Data</td>
                    </tr>
                  }
                  
                </tbody>
                )
              }
             
            </table>
        </div>
      </div>

      {/* wallet print */}
      <div style={{display:"none"}}>
        <div ref={(el)=>(walletPrint = el)}>
          <div className="print-view-top-container">
            <div className="print-view-top-left-container"  style={{ width: "25%" }}>
             <h6>Printed On : {convertDateFormat(today)}&nbsp;{time}</h6>
            </div>
            <div  className="print-view-top-middle-container"  style={{ width: "50%" }}>
            {userRole === "admin" ? (
                <h3>{loginResponse?.data?.profile?.companyName}</h3>
              ) : userRole === "user" ? (
                <h3>{loginResponse?.storeName}</h3>
              ) : undefined}
              <p>TRANSACTION HISTORY</p>
            </div>
            <div
              className="print-view-top-right-container"
              style={{ width: "25%" }}
            >
              <p>{loginResponse?.branchId}-{loginResponse?.branchName}</p>
            </div>
          </div>
          <div className="print-view-table-container report-print-view-table-with-clr">
            <table>
              <thead>
              <tr>
                    <th>No</th>
                    <th>Name</th>
                    <th>Transaction ID</th>
                    <th>Date</th>
                    <th>Credit</th>
                    <th>Debit</th>

                </tr>
              </thead>
              <tbody>
              {
                   walletList?.list?.map((r,i)=>(
                    <tr>
                    <td>{i+1}</td>
                    <td>{r?.name}</td>
                    <td>{r?.transNo}</td>
                    <td>{convertDateFormat(r?.logDate)}</td>
                    <td>{r?.credit}</td>
                    <td>{r?.debit}</td>
                 </tr>
                  ))
                  
                  }
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* summary print */}
      <div 
          style={{display:"none"}}
      >
        <div ref={(el)=>summaryPrint=el}>
           <div className="print-view-top-container">
            <div className="print-view-top-left-container"  style={{ width: "25%" }}>
              <h6>Printed On : {convertDateFormat(today)}&nbsp;{time}</h6>
           </div>
           <div className="print-view-top-middle-container"  style={{ width: "50%" }}>
           {userRole === "admin" ? (
                <h3>{loginResponse?.data?.profile?.companyName}</h3>
              ) : userRole === "user" ? (
                <h3>{loginResponse?.storeName}</h3>
              ) : undefined}
              <p>WALLET REPORT SUMMARY</p>
           </div>
           <div
              className="print-view-top-right-container"
              style={{ width: "25%" }}
            >
              <p>{loginResponse?.branchId}-{loginResponse?.branchName}</p>
            </div>
            </div>
            {/* map start */}
            {walletList?.summary?.list?.map((r)=>(
              <div className="commission-report-summary-container">
                <div className="commission-report-summary-top-container">
                   <h3 style={{ width: "20%" }}>{r?.branchId}</h3>
                   <h3>{r?.branchName}</h3>
                </div>
                <div
                className="global-table-container"
                style={{
                  margin: "1% 0",
                  maxHeight: "none",
                  overflowY: "hidden",
                }}
                >
                  <table className="global-table report-print-view-table-with-clr">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Customer ID</th>
                        <th>Name</th>
                        <th>Credit</th>
                        <th>Debit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        r?.list?.map((k,i)=>(
                          <tr>
                            <td>{i+1}</td>
                            <td>{k?.cusId}</td>
                            <td>{k?.name}</td>
                            <td>{k?.credit}</td>
                            <td>{k?.debit}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                   
                    <tbody>
                      <tr>
                        <td style={{borderTop:"1px solid #eee"}}>&nbsp;</td>
                        <td style={{borderTop:"1px solid #eee"}}>&nbsp;</td>
                        <td style={{borderTop:"1px solid #eee"}}>Total</td>
                        <td style={{borderTop:"1px solid #eee"}}>{r?.creditTotal}</td>
                        <td style={{borderTop:"1px solid #eee"}}>{r?.debitTotal}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
            <div className='wallet-summary-print-bottom-conntainer'>
               <h4 style={{width:"9%"}}>&nbsp;</h4>
               <h4 style={{width:"33%"}}>&nbsp;</h4>
               <h4 style={{width:"26%"}}>Grand Total</h4>
               <h4 style={{width:"17%"}}>{walletList?.summary?.grandCreditTotal}</h4>
               <h4 style={{width:"15%"}}>{walletList?.summary?.grandDebitTotal}</h4>
            </div>
             
        </div>
      </div>


      
    </div>
  )
}
