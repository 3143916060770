import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { addEmployeeCompanyAndBankSettingsAPICall } from "../../../../../API/Staff/HRM/employeeAPI";
import Banks from "../../../../../Assets/JSON/banknames.json";
import { update_employee_companyInfo_form } from "../../../../../Redux/Staff/HRM/Employee/employeeCompanyInfoFormSlice";
import store from "../../../../../Redux/store";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
import SuccessSnackbar from "../../../../Single Components/SnackBars/SuccessSnackbar";
// css
// '../../../../../css/Staff/HRM/AddEmployee/addEmployee.css'

export const BankDetails = (props) => {
  const { moveToBack } = props;

  //*Redux States
  // Employee Company info from previous page
  const empCompanyInfoForm = useSelector(
    (state) => state.employeeCompanyInfoFormSlice.value
  );

  //Snackbar States
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false);
  const [openErrorSnack, setOpenErrorSnack] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!");

  // let employeeFinalData = {};
  const [employeeFinalData,setEmployeeFinalData]=useState({})

  // validation states
  
  const [companyInfoAlert,setCompanyInfoAlert]=useState(false)
  const [accHolderAlert,setAccHolderAlert]=useState(false)
  const [accNumberAlert,setAccNumberAlert]=useState(false)
  const [bankNameAlert,setBankNameAlert]=useState(false)
  const [ifscAlert,setIfscAlert]=useState(false)
  const [branchLocAlert,setBranchLocAlert]=useState(false)
  const [panAlert,setPanAlert]=useState(false)

  //Bank details Form
  const empBankDetailsFormInitialState = {
    accHolder: "",
    accNo: "",
    bankName: "0",
    bankCode: "",
    branch: "",
    taxCode: "",
  };
  const [empBankDetailsForm, setEmpBankDetailsForm] = useState(
    empBankDetailsFormInitialState
  );
  //Selected Bank Object
  const [selectedBankObject, setSelectedBankObject] = useState(null);
  //Close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setOpenSuccessSnack(false);
  };
  //Close ErrorSnackbar
  const closeErrorSnackbar = () => {
    setOpenErrorSnack(false);
  };

  //*useEffect
  //Assign final body structure
  useEffect(() => {
    createFinalBodyStructure();
  }, [empCompanyInfoForm, empBankDetailsForm]);

  //* onChange Function
  const getEmployeeBankDetailsData = (key) => (e) => {
    const { value } = e.target;
    if (key === "accHolder") {
      setEmpBankDetailsForm({ ...empBankDetailsForm, accHolder: value });
      setAccHolderAlert(false)
      if(value===""){
        setAccHolderAlert(true)
      }
    }
    if (key === "accNo") {
      setEmpBankDetailsForm({ ...empBankDetailsForm, accNo: value });
      setAccNumberAlert(false)
      if(value===""){
        setAccNumberAlert(true)
      }
    }
    if (key === "bankName") {
      setEmpBankDetailsForm({ ...empBankDetailsForm, bankName: value });
      setAccHolderAlert(false)
    }
    if (key === "bankCode") {
      setEmpBankDetailsForm({ ...empBankDetailsForm, bankCode: value });
      setIfscAlert(false)
      if(value===""){
        setIfscAlert(true)
      }
    }
    if (key === "branch") {
      setEmpBankDetailsForm({ ...empBankDetailsForm, branch: value });
      setBranchLocAlert(false)
      if(value===""){
        setBranchLocAlert(true)
      }
    }
    if (key === "taxCode") {
      setEmpBankDetailsForm({ ...empBankDetailsForm, taxCode: value });
      setPanAlert(false)
      if(value===""){
        setPanAlert(true)
      }
    }
  };

  //*onClick fns
  //back button fn
  const clickBackButton = () => {
    moveToBack();
  };
  //Submit button Fn
  const clickSubmitButton = () => {
    console.log('submit btn clicked');
    const { accHolder, accNo, bankName, bankCode, branch, taxCode } =
      empBankDetailsForm;
    // if (empCompanyInfoForm === undefined) {
    //   setCompanyInfoAlert(true)
    // } else if (accHolder === "") {  
    //   setCompanyInfoAlert(false)
    //   setAccHolderAlert(true)
    // } else if (accNo === "") {
    //   setAccNumberAlert(true)
    // } else if (bankName === "0") {
    //   setBankNameAlert(true)
    // } else if (bankCode === "") {
    //   setIfscAlert(true)
    // } else if (branch === "") {
    //   setBranchLocAlert(true)
    // } else if (taxCode === "") {
    //   setPanAlert(true)
    // } else {
      addEmployeeCompanyAndBankSettingsAPICall(
        employeeFinalData,
        setOpenSuccessSnack,
        setOpenErrorSnack,
        setSnackMsg,
        clearStates
      );
    // }

    console.log(empCompanyInfoForm);
    console.log(employeeFinalData);
  };

  //Clear States
  const clearStates = () => {
    store.dispatch(
      update_employee_companyInfo_form({ employeeCompanyInfoForm: undefined })
    );
    setEmpBankDetailsForm(empBankDetailsFormInitialState);
  };

  //Create Final body structure
  const createFinalBodyStructure = () => {
    if (empCompanyInfoForm !== undefined) {
      const {
        empId,
        dpt,
        designation,
        doj,
        dol,
        workHours,
        outlet,
        salaryType,
        monthlySalary,
        contractFrom,
        contractTo,
        status,
        qrCode,
        allowedLocFilter
      } = empCompanyInfoForm;
      const { accHolder, accNo, bankName, bankCode, branch, taxCode } =
        empBankDetailsForm;
      // employeeFinalData = {
      //   id: empId,
      //   department: dpt,
      //   designation: designation,
      //   date_of_join: doj,
      //   workHour: parseFloat(workHours),
      //   salaryType: salaryType,
      //   monthlySalary: parseFloat(monthlySalary),
      //   contractPeriodFrm: contractFrom,
      //   contractPeriodTo: contractTo,
      //   status,
      //   ac_holder: accHolder,
      //   ac_no: accNo,
      //   bank: bankName,
      //   bank_code: bankCode,
      //   bankLocation: branch,
      //   pan: taxCode,
      //   qrcode: qrCode,
      //   outletLocation: outlet,
      // };
      setEmployeeFinalData({
        id: empId,
        department: dpt,
        designation: designation,
        date_of_join: doj,
        workHour: parseFloat(workHours),
        salaryType: salaryType,
        monthlySalary: parseFloat(monthlySalary),
        contractPeriodFrm: contractFrom,
        contractPeriodTo: contractTo,
        status,
        ac_holder: accHolder,
        ac_no: accNo,
        bank: bankName,
        bank_code: bankCode,
        bankLocation: branch,
        pan: taxCode,
        qrcode: qrCode,
        outletLocation: outlet,
        allowBranches:allowedLocFilter
      })
      console.log('employee body created');
      // console.log(employeeFinalData);
    }
  };

  //Set Bank Name to state
  useEffect(() => {
    if (selectedBankObject !== null) {
      setEmpBankDetailsForm({
        ...empBankDetailsForm,
        bankName: selectedBankObject?.name,
      });
    } else {
      setEmpBankDetailsForm({ ...empBankDetailsForm, bankName: "0" });
    }
  }, [selectedBankObject]);

  // console.log(employeeFinalData);

  return (
    <>

      {companyInfoAlert?
        <p className="bank-info-validation">Fill the company info section first !!</p>
      :""}
      <div className="personal-info-container">

        <div className="personal-info-input-container">
          <div className="global-single-input add-employee-input">
            <p>Account Holder</p>
            <input
              type="text"
              placeholder="Enter account holder name..."
              value={empBankDetailsForm.accHolder}
              onChange={getEmployeeBankDetailsData("accHolder")}
            />
            {accHolderAlert?
              <p className="doc-validation-alert">Fill this field !!</p>
            :""}
          </div>
          <div className="global-single-input add-employee-input">
            <p>Account Number</p>
            <input
              type="text"
              placeholder="Enter account number..."
              value={empBankDetailsForm.accNo}
              onChange={getEmployeeBankDetailsData("accNo")}
            />
            {accNumberAlert?
              <p className="doc-validation-alert">Fill this field !!</p>
            :""}
          </div>
          <div className="global-single-input add-employee-input auto-complete">
            <p>Bank Name</p>
            <Autocomplete
              sx={{ width: "100%" }}
              options={Banks || [""]}
              getOptionLabel={(option) => option?.name}
              renderInput={(params) => (
                <TextField {...params} placeholder="select bank" />
              )}
              // value={(option)=>option?._id}
              onChange={(e, newValue) => {
                                          setSelectedBankObject(newValue)
                                          setBankNameAlert(false)
                                          if(newValue===null){
                                            setBankNameAlert(true)
                                          }
                                        }}
            />
            {bankNameAlert?
              <p className="doc-validation-alert">Fill this field !!</p>
            :""}
          </div>
          <div className="global-single-input add-employee-input">
            <p>IFSC / IBAN</p>
            <input
              type="text"
              placeholder="Enter IFSC / IBAN..."
              value={empBankDetailsForm.bankCode}
              onChange={getEmployeeBankDetailsData("bankCode")}
            />
            {ifscAlert?
              <p className="doc-validation-alert">Fill this field !!</p>
            :""}
          </div>
          <div className="global-single-input add-employee-input">
            <p>Branch Location</p>
            <input
              type="text"
              placeholder="Enter branch location..."
              value={empBankDetailsForm.branch}
              onChange={getEmployeeBankDetailsData("branch")}
            />
            {branchLocAlert?
              <p className="doc-validation-alert">Fill this field !!</p>
            :""}
          </div>
          <div className="global-single-input add-employee-input">
            <p>PAN / Income Tax Code</p>
            <input
              type="text"
              placeholder="Enter tax code..."
              value={empBankDetailsForm.taxCode}
              onChange={getEmployeeBankDetailsData("taxCode")}
            />
            {panAlert?
              <p className="doc-validation-alert">Fill this field !!</p>
            :""}
          </div>
        </div>
        <div className="add-employee-button-container">
          <button
            className="btn btn-primary-outlined"
            onClick={clickBackButton}
          >
            Back
          </button>
          <button className="btn btn-primary" onClick={clickSubmitButton}>
            Submit
          </button>
        </div>
      </div>

      <SuccessSnackbar
        open={openSuccessSnack}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={openErrorSnack}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </>
  );
};
