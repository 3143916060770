import { createSlice } from "@reduxjs/toolkit";

export const qrForPcsSlice=createSlice({
    name:'qrForPcsSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_qr_for_print_cutting_slip:(state,action)=>{
            state.value=action.payload.printCuttingSlipQr
        }
    }
})
export const {update_qr_for_print_cutting_slip} = qrForPcsSlice.actions
export default qrForPcsSlice.reducer