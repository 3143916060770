import { createSlice } from "@reduxjs/toolkit";

export const invoiceNumberSlice=createSlice({
    name:'invoiceNumberSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_invoice_number:(state,action)=>{
            state.value=action.payload.invoiceNumberList
        }
    }
})
export const {get_invoice_number}=invoiceNumberSlice.actions;
export default invoiceNumberSlice.reducer;