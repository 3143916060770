import React from "react";
import InputBox from "../InputBox";
import { useDispatch, useSelector } from "react-redux";
import { setOpeningBalance } from "../../../../../Redux/Account/branchOrDivisionSlice";
import SubmitBtn from "../SubmitBtn";

const BranchOrDivision = () => {
  const dispatch = useDispatch();
  const { openingBalance } = useSelector(
    (state) => state.branchOrDivisionSlice
  );
  const branchOrDivisionSlice = useSelector(
    (state) => state.branchOrDivisionSlice
  );
  const handleOpeningBalanceSubmit = () => {
    console.log(branchOrDivisionSlice);
  };

  return (
    <div
      style={{ padding: "2% 4%" }}
      className="ledger-section-container-input-bank "
    >
      <div>
        {/*Opening Balance*/}
        <InputBox
          label={"Opening Balance"}
          value={openingBalance}
          onChange={(e) => dispatch(setOpeningBalance(e.target.value))}
          containerStyle={{width:'31%'}}

        />
      </div>
      {/* submitButton */}
      <SubmitBtn onClick={handleOpeningBalanceSubmit} />
    </div>
  );
};

export default BranchOrDivision;
