import React, { useRef } from "react";
import "../../../../../css/Report/TrailBalanace.css";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  TrailBalananceListAPI,
  TrailBalananceSingleListAPI,
} from "./TrailBalanceAPI";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCurrentTime, previousDate, today } from "../../../../../Js/Date";
import { IconButton, Tooltip } from "@mui/material";
import ReactToPrint from "react-to-print";

export const LedgerBalance = () => {
  const userInfo = useSelector((state) => state.loginResponseSlice.value);
  let componentRef = useRef();
  let navigate = useNavigate();
  const [selectedHeading, setSelectedHeading] = useState("Ledger Balance");
  const [startDate, setStartDate] = useState(previousDate);
  const [endDate, setEndDate] = useState(today);

  const singleViewID = localStorage.getItem("singleViewById");
  const StartDate = localStorage.getItem("fromDate");
  const EndDate = localStorage.getItem("toDate");
  const account = localStorage.getItem("accName");
  const trailBalanceSingleList = useSelector(
    (state) => state.trailbalance.generalLedgerSingleData
  );
  console.log(trailBalanceSingleList);

  //initialise values

  const decimalPosition = localStorage.getItem("decimalPosition");

  const getFromDate = (e) => {
    setStartDate(e.target.value);
  };
  const getToDate = (e) => {
    setEndDate(e.target.value);
  };

  const changeColor = (heading) => {
    setSelectedHeading(heading);
  };
  const handleBackpage = () => {
    navigate("/userdashboard/accounts/report/financialReport/generalLedger");
  };
  useEffect(() => {
    //TrailBalananceListAPI()
    TrailBalananceSingleListAPI({
      startDate: `${StartDate}  00:00:00`,
      endDate: `${EndDate}  23:59:59`,
      id: singleViewID,
    });
  }, [startDate, EndDate, singleViewID]);

  return (
    <div className="global-page-parent-container" style={{ margin: "0" }}>
      <div className="global-white-bg-container">
        <div
          className="header-div-path-search"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <div
              className="sharp-arrow"
              style={{ width: "115px" }}
              onClick={() => handleBackpage()}
            >
              {" "}
              <p> General Ledger</p>
            </div>
            <div
              className="sharp-arrow"
              style={{ marginLeft: "18px", width: "auto" }}
            >
              {" "}
              <p>{account}</p>
            </div>
          </div>
          <div style={{display:"flex",alignItems:"center"}}>
            <div className="first-div-container action-btns-container">
              <div>
                <Tooltip title="Share">
                  <IconButton>
                    <i
                      class="bi bi-share"
                      style={{
                        backgroundColor: "#9797e9",
                        color: "white",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                    ></i>
                  </IconButton>
                </Tooltip>
              </div>
              <div>
                <ReactToPrint
                  trigger={() => (
                    <Tooltip title="Print">
                      <IconButton
                        className="printer-div"
                        style={{ padding: "1px" }}
                      >
                        <i
                          style={{
                            color: "white",
                            backgroundColor: " #d787e7",
                            fontSize: "medium",
                            padding: "8px",
                            borderRadius: "2px",
                          }}
                          class="bi bi-printer"
                        ></i>
                      </IconButton>
                    </Tooltip>
                  )}
                  content={() => componentRef}
                />
              </div>
              <div>
                <Tooltip title="Download">
                  <IconButton>
                    <i
                      class="bi bi-arrow-down-circle"
                      style={{
                        backgroundColor: "rgb(255 118 133 / 69%)",
                        color: "white",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                    ></i>
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          <div
            className="view-order-search-container"
            style={{ width: "100%", position: "relative", margin: "7px" }}
          >
            <p className="search-head">Search</p>
            <input value={""} />
            <SearchIcon
              className="billing-search-icon"
              style={{ color: "#736ff3" }}
            />
          </div>
          </div>
        </div>
        <div className="secound-main-container-div" style={{ paddingTop: "0" }}>
          <div className="table-header" style={{ height: "34px" }}>
            <p style={{ fontSize: "14px", marginLeft: "-16px" }}>
              {" "}
              {account} Ledger Balance
            </p>
          </div>
        </div>
        <div className="table-container" style={{ maxHeight: "73vh" }}>
          <table className="table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Branch</th>
                <th
                  style={{
                    textAlign: "start",
                    paddingLeft: "12px",
                  }}
                >
                  Particular
                </th>
                <th>Journal</th>
                <th>Referance</th>
                <th>Debit</th>
                <th>Credit</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
              {trailBalanceSingleList?.journal?.map((item, index) => (
                <tr key={index}>
                  <td>{item.date}</td>
                  <td>{item.branch}</td>
                  <td style={{ textAlign: "start", paddingLeft: "12px" }}>
                    {item.name}
                  </td>
                  <td>{item.journalNo}</td>
                  <td>{item.reference}</td>
                  <td>{item.debit?.toFixed(decimalPosition)}</td>
                  <td>{item.credit?.toFixed(decimalPosition)}</td>
                  <td>{item.balance?.toFixed(decimalPosition)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr style={{ textAlign: "end" }}>
                <th colSpan="5" style={{ width: "55%", paddingLeft: "12px" }}>
                  Total
                </th>

                <th>
                  {trailBalanceSingleList?.debitTotal?.toFixed(decimalPosition)}
                </th>
                <th>
                  {trailBalanceSingleList?.creditTotal?.toFixed(
                    decimalPosition
                  )}
                </th>
                <th>
                  {trailBalanceSingleList?.balanceTotal?.toFixed(
                    decimalPosition
                  )}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
        {/* print container */}
        <div style={{ display: "none" }}>
          <div
            ref={(el) => (componentRef = el)}
            className="print-container-trail-balance"
            style={{ "@media print": { "@page": { size: "landscape" } } }}
          >
            <div className="trailbalnce-print-header">
              <div className="company-logo-container">
                <img
                  height={100}
                  width={100}
                  src="http://placehold.it/100x100"
                  alt=""
                />
              </div>
              <div className="trail-balance-print-title">
                <span> General Ledger Report</span>
                <span style={{ fontSize: "13px" }}>
                  {account}- Ledger Balance
                </span>
                <span style={{ fontSize: "13px" }}> As Off { endDate}</span>
              </div>
              <div className="company-details-container">
                <span>{userInfo?.data?.profile?.companyName} </span>
                <span>{userInfo?.data?.profile?.companyAddress} </span>
                <span>{userInfo?.data?.profile?.companyEmail}</span>
              </div>
            </div>
            <div>
              <div className="table-container">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Branch</th>
                      <th
                        style={{
                          textAlign: "start",
                          paddingLeft: "12px",
                        }}
                      >
                        Particular
                      </th>
                      <th>Journal</th>
                      <th>Referance</th>
                      <th>Debit</th>
                      <th>Credit</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {trailBalanceSingleList?.journal?.map((item, index) => (
                      <tr key={index}>
                        <td>{item.date}</td>
                        <td>{item.branch}</td>
                        <td style={{ textAlign: "start", paddingLeft: "12px" }}>
                          {item.name}
                        </td>
                        <td>{item.journalNo}</td>
                        <td>{item.reference}</td>
                        <td>{item.debit?.toFixed(decimalPosition)}</td>
                        <td>{item.credit?.toFixed(decimalPosition)}</td>
                        <td>{item.balance?.toFixed(decimalPosition)}</td>
                      </tr>
                    ))}
                    <tr>
                      <th
                        colSpan="5"
                        style={{ width: "55%", paddingLeft: "12px" }}
                      >
                        Total
                      </th>

                      <th>
                        {trailBalanceSingleList?.debitTotal?.toFixed(
                          decimalPosition
                        )}
                      </th>
                      <th>
                        {trailBalanceSingleList?.creditTotal?.toFixed(
                          decimalPosition
                        )}
                      </th>
                      <th>
                        {trailBalanceSingleList?.balanceTotal?.toFixed(
                          decimalPosition
                        )}
                      </th>
                    </tr>
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
