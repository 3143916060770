import { createSlice } from "@reduxjs/toolkit";

export const journalSlice = createSlice({
  name: "journalSlice",
  initialState: {
    value: undefined,
    singleValue:undefined,
    tableList:undefined
  },
  reducers: {
    update_journal_slice: (state, action) => {
      state.value = action.payload.journalData;
    },
    save_single_journal:(state,action)=>{
      state.singleValue=action.payload.singleJournalData
    },
    filtered_table_list:(state,action)=>{
      state.tableList=action.payload.tableListJournal
    }
  },
});

export const { update_journal_slice,save_single_journal,filtered_table_list} = journalSlice.actions;

export default journalSlice.reducer;