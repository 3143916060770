import React from "react";
import DropdownInputBox from "../DropdownInputBox";
import SubmitBtn from "../SubmitBtn";
import typeOfDutyOrTaxOption from "../../../../../Assets/JSON/ledgerTypeOfDutiesOrTaxesOption.json";
import { useDispatch, useSelector } from "react-redux";
import {
  setRoundingMethod,
  setTaxType,
  setTypeOfDutyOrTax,
  setOpeningBalance
} from "../../../../../Redux/Account/dutiesAndTaxesSlice";
import InputBox from "../InputBox";

const DutiesAndTaxes = () => {
  const taxTypeOption = [{ option: "Central" }, { option: "State" }];
  const roundingMethodOption = [
    { option: "Not Applicable" },
    { option: "Yes" },
  ];

  const dispatch = useDispatch();
  const { typeOfDutyOrTax, taxType, roundingMethod,openingBalance } = useSelector(
    (state) => state.dutiesAndTaxesSlice
  );
  const dutiesAndTaxesSlice = useSelector((state) => state.dutiesAndTaxesSlice);
  const handleDutiesAndTaxesSubmit = () => {
    console.log(dutiesAndTaxesSlice);
  };
  return (
    <>
      <div style={{ padding: "2% 4%" }} className="bottom-line">
        {/*Opening Balance*/}
        <InputBox
          label={"Opening Balance"}
          value={openingBalance}
          onChange={(e) => dispatch(setOpeningBalance(e.target.value))}
          containerStyle={{width:'31%'}}

        />
      </div>
      <div
        style={{ padding: "2% 4%" }}
        className="ledger-section-container-input-bank "
      >
        <div>
          {/*Type of Duty / Tax */}
          <DropdownInputBox
            labelName={"Type of Duty / Tax"}
            options={typeOfDutyOrTaxOption}
            value={typeOfDutyOrTax}
            onChange={(e, value) => dispatch(setTypeOfDutyOrTax(value))}
          />

          {/*tax type  */}
          <DropdownInputBox
            labelName={"Tax type"}
            options={taxTypeOption}
            onChange={(e, value) => dispatch(setTaxType(value))}
            value={taxType}
          />
          {/*Rounding Method */}
          <DropdownInputBox
            labelName={"Rounding Method"}
            options={roundingMethodOption}
            onChange={(e, value) => dispatch(setRoundingMethod(value))}
            value={roundingMethod}
          />
        </div>
        <SubmitBtn onClick={handleDutiesAndTaxesSubmit} />
      </div>
    </>
  );
};

export default DutiesAndTaxes;
