import React, { useState } from "react";
import "../../../../css/Purchase Manager/supplierManager.css";
import {
  Dialog,
  Select,
  IconButton,
  MenuItem,
  Autocomplete,
  TextField,
  Skeleton,
} from "@mui/material";
import { useEffect } from "react";
import { extractStatesFromCountry } from "../../../../Js/generalFunctions";
import CountryWithState from "../../../../Assets/JSON/countryStates.json";
import Currencies from "../../../../Assets/JSON/currencies.json";
import Banks from "../../../../Assets/JSON/banknames.json";
import GSTStateCodes from "../../../../Assets/JSON/gstStateCodes.json";
import {
  addNewSupplierAPICall,
  viewAllSuppliersAPICall,
  deleteSupplierAPICall,
  editSupplierAPICall,
  viewCreditLimitAPICall,
  addCreditLimitAPICall,
} from "../../../../API/Purchase Manager/supplierAPI";
import { useSelector } from "react-redux";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import { deleteAlertMessage } from "../../../../Js/generalFunctions";
export const SupplierManager = () => {
  //*Current Country
  let currentCountry = localStorage.getItem("country");
  let countryMeta = localStorage.getItem("COUNTRY_META");
  //*Redux States
  const allSuppliersList = useSelector(
    (state) => state.allSupplierListSlice.value
  );
  // view credit limit
  const creditLimitList = useSelector((state) => state.creditLimitSlice.value);
  // login response
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  // role based rendering
  const userRole = useSelector((state) => state.userRoleSlice.value);

  //Update Listener States
  const [isModify, setIsModify] = useState(false);
  //Add Supplier Dialog State
  const [addSupplierDialog, setAddSupplierDialog] = useState(false);
  //States of a particular country
  const [states, setStates] = useState([]);
  //Snackbar States
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!");

  // validation states
  const [supNameAlert, setSupNameAlert] = useState(false);
  const [supMobileAlert, setSupMobileAlert] = useState(false);
  const [supAddressAlert, setSupAddressAlert] = useState(false);
  // search state
  const [searchKeyword, setSearchKeyword] = useState("");
  const [filteredSupplierList, setFilteredSupplierlist] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  //Add Supplier Dialog States
  const addSupplierDialogFormInitialState = {
    supplierName: "",
    mobile: "",
    email: "",
    currency: "0",
    creditLimit: "0",
    taxNo: "",
    country: currentCountry,
    state: "0",
    stateCode: "0",
    openingBalance: "",
    address: "",
    bankName: "0",
    accountNo: "",
    bankCode: "",
  };
  const [addSupplierDialogForm, setAddSupplierDialogForm] = useState(
    addSupplierDialogFormInitialState
  );

  const addCreditLimitInitialForm = {
    creditLimitAmount: "",
    creditLimitDays: "",
  };
  const [addCreditLimitForm, setAddCreditLimitForm] = useState(
    addCreditLimitInitialForm
  );

  const [viewSupplierDialog, setViewSupplierDialog] = useState(false);
  const [editSupplierDialog, setEditSupplierDialog] = useState(false);
  const [addCreditLimitDialog, setAddCreditLimitDialog] = useState(false);
  // single supplier
  const [singleSupplier, setSingleSupplier] = useState([]);
  // edit supplier state
  const [editedId, setEditedId] = useState();
  //*Update Listener
  const updateListener = () => {
    setIsModify(!isModify);
  };

  //Close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setSuccessSnackOpen(false);
  };
  //Close ErrorSnackbar
  const closeErrorSnackbar = () => {
    setErrorSnackOpen(false);
  };
  // search onchange
  const getSearchKeyword = (e) => {
    setSearchKeyword(e.target.value);
  };
  //*onChange Fn
  const getAddSupplierFormData = (key) => (e) => {
    const { value } = e.target;
    if (key === "supplierName") {
      setAddSupplierDialogForm({
        ...addSupplierDialogForm,
        supplierName: value,
      });
      setSupNameAlert(false);
      if (e.target.value === "") {
        setSupNameAlert(true);
      }
    }
    if (key === "mobile") {
      setAddSupplierDialogForm({ ...addSupplierDialogForm, mobile: value });
      setSupMobileAlert(false);
      if (e.target.value === "") {
        setSupMobileAlert(true);
      }
    }
    if (key === "email") {
      setAddSupplierDialogForm({ ...addSupplierDialogForm, email: value });
    }
    if (key === "currency") {
      setAddSupplierDialogForm({ ...addSupplierDialogForm, currency: value });
    }
    if (key === "creditLimit") {
      setAddSupplierDialogForm({
        ...addSupplierDialogForm,
        creditLimit: value,
      });
    }
    if (key === "taxNo") {
      setAddSupplierDialogForm({ ...addSupplierDialogForm, taxNo: value });
    }
    if (key === "country") {
      setAddSupplierDialogForm({ ...addSupplierDialogForm, country: value });
    }
    if (key === "state") {
      setAddSupplierDialogForm({ ...addSupplierDialogForm, state: value });
    }
    if (key === "stateCode") {
      setAddSupplierDialogForm({ ...addSupplierDialogForm, stateCode: value });
    }
    if (key === "openingBalance") {
      setAddSupplierDialogForm({
        ...addSupplierDialogForm,
        openingBalance: parseFloat(value),
      });
    }
    if (key === "address") {
      setAddSupplierDialogForm({ ...addSupplierDialogForm, address: value });
      setSupAddressAlert(false);
      if (e.target.value === "") {
        setSupAddressAlert(true);
      }
    }
    if (key === "bankName") {
      setAddSupplierDialogForm({ ...addSupplierDialogForm, bankName: value });
    }
    if (key === "accountNo") {
      setAddSupplierDialogForm({ ...addSupplierDialogForm, accountNo: value });
    }
    if (key === "bankCode") {
      setAddSupplierDialogForm({ ...addSupplierDialogForm, bankCode: value });
    }
  };

  // credit limit onchange functions
  const getCreditAmount = (e) => {
    setAddCreditLimitForm({
      ...addCreditLimitForm,
      creditLimitAmount: e.target.value,
    });
  };
  const getCreditDays = (e) => {
    setAddCreditLimitForm({
      ...addCreditLimitForm,
      creditLimitDays: e.target.value,
    });
  };

  //*onClick fns
  //add Supplier final Submit
  const newSupplierBody = {
    supplierName: addSupplierDialogForm.supplierName,
    emailAddress: addSupplierDialogForm.email,
    creditLimit: addSupplierDialogForm.creditLimit,
    country: addSupplierDialogForm.country,
    statecode: addSupplierDialogForm.stateCode,
    address: addSupplierDialogForm.address,
    currency: addSupplierDialogForm.currency,
    gstIn: addSupplierDialogForm.taxNo,
    state: addSupplierDialogForm.state,
    bankName: addSupplierDialogForm.bankName,
    accountNo: addSupplierDialogForm.accountNo,
    ifscCode: addSupplierDialogForm.bankCode,
    mobile: addSupplierDialogForm.mobile,
    openingBalance: addSupplierDialogForm.openingBalance,
  };
  const submitNewSupplier = () => {
    const {
      supplierName,
      mobile,
      email,
      currency,
      creditLimit,
      taxNo,
      country,
      state,
      stateCode,
      openingBalance,
      address,
      bankName,
      accountNo,
      bankCode,
    } = addSupplierDialogForm;
    if (supplierName === "") {
      setSupNameAlert(true);
    } else if (mobile === "") {
      setSupMobileAlert(true);
    } else if (address === "") {
      setSupAddressAlert(true);
    } else {
      addNewSupplierAPICall(
        newSupplierBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        clearStates
      );
    }
  };

  //Clear States
  const clearStates = () => {
    setAddSupplierDialogForm(addSupplierDialogFormInitialState);
    setAddSupplierDialog(false);
  };

  // DELETE SUPPLIER
  const deleteSupplier = (id) => {
    deleteSupplierAPICall(id, updateListener);
  };

  // EDIT SUPPLIER FUNCTIONS
  const openEditSupplierDialog = (
    id,
    name,
    mobileNo,
    email,
    currency,
    credit,
    gst,
    country,
    state,
    stateCode,
    openingBalance,
    address,
    bankName,
    accountNo,
    bankCode
  ) => {
    setEditSupplierDialog(true);
    setEditedId(id);
    setAddSupplierDialogForm({
      ...addSupplierDialogForm,
      supplierName: name,
      mobile: mobileNo,
      email: email,
      currency: currency,
      creditLimit: credit,
      taxNo: gst,
      country: country,
      state: state,
      stateCode: stateCode,
      openingBalance: openingBalance,
      address: address,
      bankName: bankName,
      accountNo: accountNo,
      bankCode: bankCode,
    });
  };
  const editedBodyToApi = {
    _id: editedId,
    supplierName: addSupplierDialogForm.supplierName,
    emailAddress: addSupplierDialogForm.email,
    creditLimit: addSupplierDialogForm.creditLimit,
    country: addSupplierDialogForm.country,
    address: addSupplierDialogForm.address,
    currency: addSupplierDialogForm.currency,
    state: addSupplierDialogForm.state,
    openingBalance: addSupplierDialogForm.openingBalance,
    bankName: addSupplierDialogForm.bankName,
    accountNo: addSupplierDialogForm.accountNo,
    ifscCode: addSupplierDialogForm.bankCode,
    mobile: addSupplierDialogForm.mobile,
    gstIn: addSupplierDialogForm.taxNo,
    statecode: addSupplierDialogForm.stateCode,
  };
  const editDialogSubmitBtnFn = () => {
    editSupplierAPICall(
      editedBodyToApi,
      setEditSupplierDialog,
      updateListener,
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg
    );
  };

  // supplier single view
  const viewSingleSupplier = (id) => {
    if (allSuppliersList !== undefined) {
      const filteredData = allSuppliersList?.filter((obj) => {
        if (obj?._id === id) {
          return obj;
        }
      });
      setSingleSupplier(filteredData);
    }
  };

  const openSingleViewDialog = () => {
    setViewSupplierDialog(true);
  };

  const bodyToApi = {
    enterAmount: parseFloat(addCreditLimitForm?.creditLimitAmount),
    enterDays: parseFloat(addCreditLimitForm?.creditLimitDays),
  };
  // add credit limit
  const addCreditLimitSubmit = () => {
    addCreditLimitAPICall(bodyToApi, updateListener, setAddCreditLimitDialog);
  };
  const searchInSupplierList = (chars, supplierList) => {
    let searchedArray = [];
    let filteredArray = [];
    if (chars !== "") {
      searchedArray = supplierList.filter(
        (obj) =>
          obj?.supplierName?.toLowerCase()?.includes(chars?.toLowerCase()) ||
          obj?.emailAddress?.toLowerCase()?.includes(chars?.toLowerCase()) ||
          obj?.mobile?.toLowerCase()?.includes(chars?.toLowerCase())
      );
      filteredArray = searchedArray;
    } else {
      filteredArray = supplierList;
    }
    return filteredArray;
  };
  //*UseEffects
  //Extract State from country list
  useEffect(() => {
    let extractedState = extractStatesFromCountry(
      addSupplierDialogForm.country
    );
    setStates(extractedState);
  }, [addSupplierDialogForm.country]);

  //Assign Local Currency to Currency
  useEffect(() => {
    let parsedCountryMeta = JSON.parse(countryMeta);
    setAddSupplierDialogForm({
      ...addSupplierDialogForm,
      currency: parsedCountryMeta?.currency,
    });
  }, []);

  //Call Get All Suppliers API
  useEffect(() => {
    viewAllSuppliersAPICall(setIsloading, setErrorSnackOpen, setSnackMsg);
    viewCreditLimitAPICall();
  }, [isModify]);
  useEffect(() => {
    if (allSuppliersList !== undefined) {
      let filteredArray = [];
      setFilteredSupplierlist(allSuppliersList);
      filteredArray = searchInSupplierList(searchKeyword, allSuppliersList);
      setFilteredSupplierlist(filteredArray);
    } else {
      setFilteredSupplierlist([]);
    }
  }, [searchKeyword, allSuppliersList]);
  return (
    <>
      <div className="global-page-parent-container">
        <p className="breadcrumb">Purchase Manage &gt; Supplier Manager</p>
        <div className="supplier-manage-container">
          <div className="supplier-manage-top-container">
            <h3>Supplier Manager</h3>
            <div className="supplier-manage-top-container-right">
              <div className="view-order-search-container view-order-admin-search-container">
                <input
                  placeholder="Supplier name,mobile,email"
                  value={searchKeyword}
                  onChange={getSearchKeyword}
                />
                <IconButton>
                  <i class="bi bi-search search-icon-order"></i>
                </IconButton>
              </div>
              <button
                onClick={() => setAddSupplierDialog(true)}
                className="btn btn-primary"
              >
                Add Supplier
              </button>
            </div>
          </div>
          <div className="global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Supplier Name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Address</th>
                  <th>Balance</th>
                  <th>Actions</th>
                </tr>
              </thead>
              {isLoading ? (
                <tbody>
                  <tr>
                    <td colSpan={10}>
                      {/* <Loader /> */}
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={10}>
                      {/* <Loader /> */}
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={10}>
                      {/* <Loader /> */}
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={10}>
                      {/* <Loader /> */}
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {filteredSupplierList.length !== 0 ? (
                    filteredSupplierList
                      ?.slice(0)
                      ?.reverse()
                      ?.map((r, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{r?.supplierName.toUpperCase()}</td>
                          <td>{r?.emailAddress}</td>
                          <td>{r?.mobile}</td>
                          <td>{r?.address}</td>
                          <td>{r?.openingBalance}</td>
                          <td>
                            <IconButton
                              onMouseDown={() => viewSingleSupplier(r?._id)}
                              onClick={openSingleViewDialog}
                            >
                              <i class="bi bi-eye visibility-icon"></i>
                            </IconButton>
                            {userRole === "admin" && (
                              <>
                                <IconButton
                                  onClick={() =>
                                    openEditSupplierDialog(
                                      r?._id,
                                      r?.supplierName,
                                      r?.mobile,
                                      r?.emailAddress,
                                      r?.currency,
                                      r?.creditLimit,
                                      r?.gstIn,
                                      r?.country,
                                      r?.state,
                                      r?.stateCode,
                                      r?.openingBalance,
                                      r?.address,
                                      r?.bankName,
                                      r?.accountNo,
                                      r?.ifscCode
                                    )
                                  }
                                >
                                  <i class="bi bi-pencil-square edit-icon"></i>
                                </IconButton>
                                <IconButton
                                  onClick={deleteAlertMessage(
                                    r._id,
                                    deleteSupplier
                                  )}
                                >
                                  <i class="bi bi-trash3 delete-icon"></i>
                                </IconButton>
                              </>
                            )}
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan={7}>No Records</td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
          </div>
        </div>

        {/* add supplier Dialog */}
        <Dialog
          open={addSupplierDialog}
          maxWidth="lg"
          onKeyDown={(e) => e.key === "Escape" && setAddSupplierDialog(false)}
        >
          <div className="addSupplierDialog">
            <h3>Add Supplier ---</h3>
            <hr />
            <div className="addSupplierDialog-input-container">
              <div className="global-single-input add-supplier-input">
                <p>Supplier Name</p>
                <input
                  placeholder="Enter Supplier name..."
                  value={addSupplierDialogForm.supplierName}
                  onChange={getAddSupplierFormData("supplierName")}
                />
                {supNameAlert ? (
                  <p className="doc-validation-alert">Fill this field !!</p>
                ) : (
                  ""
                )}
              </div>
              <div className="global-single-input add-supplier-input">
                <p>Mobile</p>
                <input
                  placeholder="Enter mobile number..."
                  value={addSupplierDialogForm.mobile}
                  onChange={getAddSupplierFormData("mobile")}
                  maxLength="15"
                />
                {supMobileAlert ? (
                  <p className="doc-validation-alert">Fill this field !!</p>
                ) : (
                  ""
                )}
              </div>
              <div className="global-single-input add-supplier-input">
                <p>Email Address</p>
                <input
                  placeholder="Enter email address..."
                  value={addSupplierDialogForm.email}
                  onChange={getAddSupplierFormData("email")}
                />
              </div>
              <div className="global-single-input add-supplier-input">
                <p>Currency</p>
                <Select
                  value={addSupplierDialogForm.currency}
                  onChange={getAddSupplierFormData("currency")}
                >
                  <MenuItem value="0">-select currency-</MenuItem>
                  {Currencies?.map((currency, i) => (
                    <MenuItem
                      value={currency?.cc}
                      key={i}
                    >{`${currency?.cc}-${currency?.name}`}</MenuItem>
                  ))}
                </Select>
              </div>
              <div className="global-single-input add-supplier-input auto-complete">
                <p>Credit Limit</p>

                <Autocomplete
                  sx={{ width: "100%" }}
                  options={creditLimitList || [""]}
                  getOptionLabel={(option) => option?.enterDays}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Credit Limit" />
                  )}
                  onChange={(e, newValue) =>
                    setAddSupplierDialogForm({
                      ...addSupplierDialogForm,
                      creditLimit: newValue?.enterDays,
                    })
                  }
                />
                <span
                  onClick={() => setAddCreditLimitDialog(true)}
                  className="suffix-text-blue"
                >
                  Add Credit Limit
                </span>
              </div>
              <div className="global-single-input add-supplier-input">
                <p>{currentCountry === "india" ? "GST IN" : "VAT"}</p>
                <input
                  placeholder="Enter tax number..."
                  value={addSupplierDialogForm.taxNo}
                  onChange={getAddSupplierFormData("taxNo")}
                />
              </div>
              <div className="global-single-input add-supplier-input">
                <p>Country</p>
                <Select
                  value={addSupplierDialogForm.country}
                  onChange={getAddSupplierFormData("country")}
                >
                  <MenuItem value="0">-select a country-</MenuItem>
                  {CountryWithState?.countries?.map((item, i) => (
                    <MenuItem value={item?.country?.toLowerCase()} key={i}>
                      {item?.country}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="global-single-input add-supplier-input auto-complete">
                <p>State</p>
                {/* <Select
                  value={addSupplierDialogForm.state}
                  onChange={getAddSupplierFormData("state")}
                >
                  <MenuItem value="0">-select state-</MenuItem>
                  {states[0]?.states?.map((state, i) => (
                    <MenuItem value={state} key={i}>
                      {state}
                    </MenuItem>
                  ))}
                </Select> */}
                <Autocomplete
                  sx={{ width: "100%" }}
                  options={states[0]?.states || [""]}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select state" />
                  )}
                  onChange={(e, newValue) =>
                    setAddSupplierDialogForm({
                      ...addSupplierDialogForm,
                      state: newValue,
                    })
                  }
                />
              </div>
              {currentCountry === "india" && (
                <div className="global-single-input add-supplier-input auto-complete">
                  <p>State Code</p>
                  {/* <Select
                  value={addSupplierDialogForm.stateCode}
                  onChange={getAddSupplierFormData("stateCode")}
                >
                  <MenuItem value="0">-select state code-</MenuItem>
                  {GSTStateCodes?.map((item,i)=>(
                    <MenuItem value={item?.code} key={i}>{`${item?.tinNo}-${item?.code}`}</MenuItem>
                  ))}
                </Select> */}
                  <Autocomplete
                    sx={{ width: "100%" }}
                    options={GSTStateCodes || [""]}
                    getOptionLabel={(option) =>
                      `${option?.tinNo}-${option?.code}`
                    }
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select state  code" />
                    )}
                    onChange={(e, newValue) =>
                      setAddSupplierDialogForm({
                        ...addSupplierDialogForm,
                        stateCode: newValue?.code,
                      })
                    }
                  />
                </div>
              )}
              <div className="global-single-input add-supplier-input">
                <p>Opening Balance</p>
                <input
                  placeholder="Enter opening balance..."
                  value={
                    isNaN(addSupplierDialogForm.openingBalance)
                      ? ""
                      : addSupplierDialogForm.openingBalance
                  }
                  onChange={getAddSupplierFormData("openingBalance")}
                />
              </div>
              <div className="global-single-input add-supplier-input">
                <p>Address</p>
                <textarea
                  rows={4}
                  placeholder="Enter supplier address..."
                  value={addSupplierDialogForm.address}
                  onChange={getAddSupplierFormData("address")}
                />
                {supAddressAlert ? (
                  <p className="doc-validation-alert">Fill this field !!</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <hr className="add-supplier-divider" />
            <h3>Bank Details </h3>
            <div className="addSupplierDialog-input-container">
              <div className="global-single-input add-supplier-input auto-complete">
                <p>Bank Name</p>
                {/* <Select
                  value={addSupplierDialogForm.bankName}
                  onChange={getAddSupplierFormData("bankName")}
                >
                  <MenuItem value="0">-select a bank-</MenuItem>
                  {Banks?.map((bank, i) => (
                    <MenuItem key={i} value={bank?.name}>
                      {bank?.name}
                    </MenuItem>
                  ))}
                </Select> */}
                <Autocomplete
                  sx={{ width: "100%" }}
                  options={Banks || [""]}
                  getOptionLabel={(option) => option?.name}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Bank" />
                  )}
                  onChange={(e, newValue) =>
                    setAddSupplierDialogForm({
                      ...addSupplierDialogForm,
                      bankName: newValue?.name,
                    })
                  }
                />
              </div>
              <div className="global-single-input add-supplier-input">
                <p>Account Number</p>
                <input
                  placeholder="Enter account number..."
                  value={addSupplierDialogForm.accountNo}
                  onChange={getAddSupplierFormData("accountNo")}
                />
              </div>
              <div className="global-single-input add-supplier-input">
                <p>{currentCountry === "india" ? "IFS Code" : "IBAN"}</p>
                <input
                  placeholder="Enter bank code..."
                  value={addSupplierDialogForm.bankCode}
                  onChange={getAddSupplierFormData("bankCode")}
                />
              </div>
            </div>
            <div className="add-supplier-button-container">
              <button
                onClick={clearStates}
                className="btn btn-secondary-outlined"
              >
                Cancel
              </button>
              <button className="btn btn-primary" onClick={submitNewSupplier}>
                Submit
              </button>
            </div>
          </div>
        </Dialog>

        {/* view supplier dialog */}

        <Dialog
          open={viewSupplierDialog}
          maxWidth="lg"
          onKeyDown={(e) => e.key === "Escape" && setViewSupplierDialog(false)}
        >
          <div className="addSupplierDialog">
            <h3>View Supplier</h3>
            <hr />
            <div className="addSupplierDialog-input-container">
              <div className="global-single-input add-supplier-input">
                <p>Supplier Name</p>
                <input value={singleSupplier[0]?.supplierName} disabled />
              </div>
              <div className="global-single-input add-supplier-input">
                <p>Mobile</p>
                <input value={singleSupplier[0]?.mobile} disabled />
              </div>
              <div className="global-single-input add-supplier-input">
                <p>Email Address</p>
                <input value={singleSupplier[0]?.emailAddress} disabled />
              </div>
              <div className="global-single-input add-supplier-input">
                <p>Currency</p>
                <input value={singleSupplier[0]?.currency} disabled />
              </div>
              <div className="global-single-input add-supplier-input">
                <p>Credit Limit</p>
                <input value={singleSupplier[0]?.creditLimit} disabled />
              </div>
              <div className="global-single-input add-supplier-input">
                <p>{currentCountry === "india" ? "GST IN" : "VAT"}</p>
                <input value={singleSupplier[0]?.gstIn} disabled />
              </div>
              <div className="global-single-input add-supplier-input">
                <p>Country</p>
                <input value={singleSupplier[0]?.country} disabled />
              </div>
              <div className="global-single-input add-supplier-input">
                <p>State</p>
                <input value={singleSupplier[0]?.state} disabled />
              </div>
              {currentCountry === "india" && (
                <div className="global-single-input add-supplier-input">
                  <p>State Code</p>
                  <input value={singleSupplier[0]?.stateCode} disabled />
                </div>
              )}
              <div className="global-single-input add-supplier-input">
                <p>Opening Balance</p>
                <input value={singleSupplier[0]?.openingBalance} disabled />
              </div>
              <div className="global-single-input add-supplier-input">
                <p>Address</p>
                <textarea
                  rows={4}
                  value={singleSupplier[0]?.address}
                  disabled
                />
              </div>
            </div>
            <hr className="add-supplier-divider" />
            <h3>Bank Details</h3>
            <div className="addSupplierDialog-input-container">
              <div className="global-single-input add-supplier-input">
                <p>Bank Name</p>
                <input value={singleSupplier[0]?.bankName} disabled />
              </div>
              <div className="global-single-input add-supplier-input">
                <p>Account Number</p>
                <input value={singleSupplier[0]?.accountNo} disabled />
              </div>
              <div className="global-single-input add-supplier-input">
                <p>{currentCountry === "india" ? "IFS Code" : "IBAN"}</p>
                <input value={singleSupplier[0]?.ifscCode} disabled />
              </div>
            </div>
            <div className="add-supplier-button-container">
              <button
                onClick={() => setViewSupplierDialog(false)}
                className="btn btn-secondary-outlined"
                style={{ width: "23%" }}
              >
                Close
              </button>
            </div>
          </div>
        </Dialog>

        {/* edit supplier Dialog */}
        {/* using same state for add and edit */}
        <Dialog
          open={editSupplierDialog}
          maxWidth="lg"
          onKeyDown={(e) => e.key === "Escape" && setEditSupplierDialog(false)}
        >
          <div className="addSupplierDialog">
            <h3>Add Supplier </h3>
            <hr />
            <div className="addSupplierDialog-input-container">
              <div className="global-single-input add-supplier-input">
                <p>Supplier Name</p>
                <input
                  value={addSupplierDialogForm.supplierName}
                  onChange={getAddSupplierFormData("supplierName")}
                />
              </div>
              <div className="global-single-input add-supplier-input">
                <p>Mobile</p>
                <input
                  value={addSupplierDialogForm.mobile}
                  onChange={getAddSupplierFormData("mobile")}
                  maxLength="15"
                />
              </div>
              <div className="global-single-input add-supplier-input">
                <p>Email Address</p>
                <input
                  value={addSupplierDialogForm.email}
                  onChange={getAddSupplierFormData("email")}
                />
              </div>
              <div className="global-single-input add-supplier-input">
                <p>Currency</p>
                <Select
                  value={addSupplierDialogForm.currency}
                  onChange={getAddSupplierFormData("currency")}
                >
                  <MenuItem value="0">-select currency-</MenuItem>
                  {Currencies?.map((currency, i) => (
                    <MenuItem
                      value={currency?.cc}
                      key={i}
                    >{`${currency?.cc}-${currency?.name}`}</MenuItem>
                  ))}
                </Select>
              </div>
              <div className="global-single-input add-supplier-input">
                <p>Credit Limit</p>
                <Select
                  value={addSupplierDialogForm.creditLimit}
                  onChange={getAddSupplierFormData("creditLimit")}
                >
                  <MenuItem value="0">-select credit limit-</MenuItem>
                  {creditLimitList?.map((r, i) => (
                    <MenuItem value={r?.enterDays}>{r?.enterDays}</MenuItem>
                  ))}
                  {/* <MenuItem value="5">5 days</MenuItem>
                  <MenuItem value="15">15 days</MenuItem> */}
                </Select>
              </div>
              <div className="global-single-input add-supplier-input">
                <p>{currentCountry === "india" ? "GST IN" : "VAT"}</p>
                <input
                  value={addSupplierDialogForm.taxNo}
                  onChange={getAddSupplierFormData("taxNo")}
                />
              </div>
              <div className="global-single-input add-supplier-input">
                <p>Country</p>
                <Select
                  value={addSupplierDialogForm.country}
                  onChange={getAddSupplierFormData("country")}
                >
                  <MenuItem value="0">-select a country-</MenuItem>
                  {CountryWithState?.countries?.map((item, i) => (
                    <MenuItem value={item?.country?.toLowerCase()} key={i}>
                      {item?.country}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="global-single-input add-supplier-input">
                <p>State</p>
                <Select
                  value={addSupplierDialogForm.state}
                  onChange={getAddSupplierFormData("state")}
                >
                  <MenuItem value="0">-select state-</MenuItem>
                  {states[0]?.states?.map((state, i) => (
                    <MenuItem value={state} key={i}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              {currentCountry === "india" && (
                <div className="global-single-input add-supplier-input">
                  <p>State Code</p>
                  <Select
                    value={addSupplierDialogForm.stateCode}
                    onChange={getAddSupplierFormData("stateCode")}
                  >
                    <MenuItem value="0">-select state code-</MenuItem>
                    {GSTStateCodes?.map((item, i) => (
                      <MenuItem
                        value={item?.code}
                        key={i}
                      >{`${item?.tinNo}-${item?.code}`}</MenuItem>
                    ))}
                  </Select>
                </div>
              )}
              <div className="global-single-input add-supplier-input">
                <p>Opening Balance</p>
                <input
                  disabled
                  value={addSupplierDialogForm.openingBalance}
                  onChange={getAddSupplierFormData("openingBalance")}
                />
              </div>
              <div className="global-single-input add-supplier-input">
                <p>Address</p>
                <textarea
                  rows={4}
                  value={addSupplierDialogForm.address}
                  onChange={getAddSupplierFormData("address")}
                />
              </div>
            </div>
            <hr className="add-supplier-divider" />
            <h3>Bank Details</h3>
            <div className="addSupplierDialog-input-container">
              <div className="global-single-input add-supplier-input">
                <p>Bank Name</p>
                <Select
                  value={addSupplierDialogForm.bankName}
                  onChange={getAddSupplierFormData("bankName")}
                >
                  <MenuItem value="0">-select a bank-</MenuItem>
                  {Banks?.map((bank, i) => (
                    <MenuItem key={i} value={bank?.name}>
                      {bank?.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="global-single-input add-supplier-input">
                <p>Account Number</p>
                <input
                  value={addSupplierDialogForm.accountNo}
                  onChange={getAddSupplierFormData("accountNo")}
                />
              </div>
              <div className="global-single-input add-supplier-input">
                <p>{currentCountry === "india" ? "IFS Code" : "IBAN"}</p>
                <input
                  value={addSupplierDialogForm.bankCode}
                  onChange={getAddSupplierFormData("bankCode")}
                />
              </div>
            </div>
            <div className="add-supplier-button-container">
              <button
                onClick={() => setEditSupplierDialog(false)}
                className="btn btn-secondary-outlined"
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                onClick={editDialogSubmitBtnFn}
              >
                Submit
              </button>
            </div>
          </div>
        </Dialog>

        {/* add credit limit dialog */}
        <Dialog
          open={addCreditLimitDialog}
          maxWidth="lg"
          onKeyDown={(e) =>
            e.key === "Escape" && setAddCreditLimitDialog(false)
          }
        >
          <div className="supplier-add-credit-limit-container">
            <h3>Add Credit Limit</h3>
            <hr />
            <div className="add-credit-limit-input-container">
              <div className="global-single-input add-credit-limit-input">
                <p>Enter Amount</p>
                <input
                  value={addCreditLimitForm?.creditLimitAmount}
                  onChange={getCreditAmount}
                />
              </div>
              <div className="global-single-input add-credit-limit-input">
                <p>Enter Days</p>
                <input
                  value={addCreditLimitForm?.creditLimitDays}
                  onChange={getCreditDays}
                />
              </div>
            </div>
            <div className="add-credit-btn-container">
              <button
                onClick={() => setAddCreditLimitDialog(false)}
                className="btn btn-secondary-outlined"
              >
                Cancel
              </button>
              <button
                onClick={addCreditLimitSubmit}
                className="btn btn-secondary"
              >
                Submit
              </button>
            </div>
          </div>
        </Dialog>
      </div>

      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </>
  );
};
