import { createSlice } from "@reduxjs/toolkit";

export const allLeaveTypeSlice =createSlice({
    name:'allLeaveTypeSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_all_leave_type_list:(state,action)=>{
            state.value=action.payload.allLeaveType
        }
    }


})

export const{update_all_leave_type_list}=allLeaveTypeSlice.actions
export default allLeaveTypeSlice.reducer