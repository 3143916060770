import { createSlice } from "@reduxjs/toolkit";

export const neckStyleSlice=createSlice({
    name:'neckStyleSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_neckStyle_list:(state,action)=>{
            state.value=action.payload.neckStyle
        }
    }
})

export const {update_neckStyle_list}=neckStyleSlice.actions
export default neckStyleSlice.reducer