import axios from "axios";
import { update_print_cutting_slip_filtered_data } from "../../Redux/Orders/Print Cutting slip/printCuttingSlipFilteredDataSlice";
import { update_qr_for_print_cutting_slip } from "../../Redux/Orders/Print Cutting slip/qrForPCSSlice";
import store from "../../Redux/store";
import { HEADERS } from "../UrlAndPaths";

//Print Cutting Slip Filter
export const printCuttingSlipFilterAPICall = async (body) => {
  await axios
    .post("workorder/viewFilterCuttingSlip", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_print_cutting_slip_filtered_data({
            printCuttingSlipFilteredData: res.data,
          })
        );
      }
    })
    .catch((err) => {
      store.dispatch(
        update_print_cutting_slip_filtered_data({
          printCuttingSlipFilteredData: undefined,
        })
      );
      console.error(err);
    });
};

//Generate Qr for Print Cutting Slip
export const generateQRCodeForPCSAPICall = async (body) => {
  await axios
    .put("workorder/updateqrcode", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_qr_for_print_cutting_slip({printCuttingSlipQr:res.data}))
      }
    })
    .catch((err)=>{
        store.dispatch(update_qr_for_print_cutting_slip({printCuttingSlipQr:undefined}))
        console.error(err.response);
    });
};


//Update Status on Print cutting slip  [booking(pending)-->printing]
export const updateStatusOnPrintCuttingSlipAPICall=async (body)=>{
  await axios.post('workorder/statusUpdateWithprint',body,HEADERS).then((res)=>{
    if(res.data===200)
    {
      console.log('Status updated successfully');
    }
  }).catch((err)=>{
    console.error(err.response);
  })
}
