import { createSlice } from "@reduxjs/toolkit";

export const generatePurchaseReturnIdSlice=createSlice({
    name:'purchaseReturnIdSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_purchade_return_id:(state,action)=>{
            state.value=action.payload.purchaseReturnId
        }
    }
})
export const {get_purchade_return_id} =generatePurchaseReturnIdSlice.actions;
export default generatePurchaseReturnIdSlice.reducer