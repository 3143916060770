import axios from "axios";
import { update_all_employee_docs_list } from "../../../Redux/Staff/HRM/Documents/allEmployeeDocSlice";
import { update_all_doc_type_list } from "../../../Redux/Staff/HRM/Documents/docTypeViewSlice";
import { update_employee_doc_proof_list } from "../../../Redux/Staff/HRM/Documents/employeeDocViewSlice";
import store from "../../../Redux/store";
import { HEADERS, HEADER_FORM_DATA, HEADER_QUERY } from "../../UrlAndPaths";

//Add Employee Document by admin
export const addEmployeeDocumentByAdminAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates,
  closeDialog
) => {
  await axios
    .put("employee/addDocuments", body, HEADER_FORM_DATA)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Document added 👍");
        setSuccess(true);
        updateListener();
        clearStates();
        closeDialog();
        console.log("Document added successfully!!");
      }
    })
    .catch((err) => {
      switch (err.response.status) {
        case undefined:
          setSnackMsg("Network error!!");
          break;
        case 401:
          setSnackMsg("Unauthorized!!");
          break;
        case 409:
          setSnackMsg("Document already exist!!");
          break;
        case 403:
          setSnackMsg("Forbidden!!");
          break;
        case 500:
          setSnackMsg("Server error!!");
          break;

        default:
          break;
      }
      console.error(err.response.status);
    });
};

//View Documents of All employees
export const viewDocumentsOfAllEmployeeAPICall = async (setIsLoading,setErrorSnack,setSnackMsg) => {
  if(setIsLoading !== undefined) setIsLoading(true)
  await axios
    .get("employee/viewallempdocs", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_all_employee_docs_list({ allEmployeeDocs: res.data })
        );
        if(setIsLoading !== undefined) setIsLoading(false)
      }
    })
    .catch((err) => {
      store.dispatch(
        update_all_employee_docs_list({ allEmployeeDocs: undefined })
      );
      console.error(err.response);
      switch (err.response.status) {
        case 404:
            setSnackMsg('No data available..')
          break;
        case 500:
            setSnackMsg('Server error!!')
          break;
        case 401:
            setSnackMsg('Unauthorized!!')
          break;
        case 403:
            setSnackMsg('Forbidden!!')
          break;
      
        default:
            setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
          break;
      }
      setErrorSnack(true)
      if(setIsLoading !== undefined) setIsLoading(false)
    });
};


// view document proofs
export const viewDocumentProofsAPICall = async (body) =>{

  await axios.post("employee/viewDocuments",body,HEADERS)
              .then((res)=>{
                if(res.status===200){
                  store.dispatch(update_employee_doc_proof_list({ employeeDocView:res.data }));
                }
              })
              .catch((err) =>{
                store.dispatch(update_employee_doc_proof_list({ employeeDocView:undefined}))
                console.error(err.response);
              });
};


// delete document

export const deleteSingledocumentAPICall =async(body,updateListener)=>{

  await axios.delete("employee/deleteDocuments",{
    headers:HEADER_QUERY,
    data:body
  })
  .then((res)=>{
    if(res.status === 200){
      updateListener()
    }
  })
  .catch((err)=>{
    console.error(err.response);
  })
}

// view document types

export const getAllDocumentTypesAPICall = async()=>{
  await axios.post("employee/viewDocumentType",{},HEADERS)
              .then((res)=>{
                if(res.status===200){
                  store.dispatch(update_all_doc_type_list({
                    allDocType:res.data
                  }))
                }
              })
              .catch((err)=>{
                store.dispatch(update_all_doc_type_list({
                  allDocType:undefined
                }))
                console.error(err.response)
              });
};

export const addDocTypesAPICall =async(body,setSuccess,setError,setMsg)=>{
  await axios.post("employee/addDocumentsType",body,HEADERS)
               .then((res)=>{
                if(res.status===200){
                  setSuccess(true)
                  setMsg("New Doucment Type Added")
                }
               })
               .catch((err)=>{
                console.error(err.response);
                setError(true)
                setMsg("Something Went Wrong")
               })
}

export const editDocTypeAPICall =async(body,setSuccess,setError,setMsg)=>{
  await axios.put("employee/editDocumentsTypes",body,HEADERS)
              .then((res)=>{
                if(res.status===200){
                  setSuccess(true)
                  setMsg("Edited Succesfully")
                }
              })
              .catch((err)=>{
                console.error(err.response);
                setError(true)
                setMsg("Already Exist")
              })
}

export const delDocTypeAPICall =async(body,setError,setMsg)=>{
  await axios.delete("employee/deletedocumentTypes",{
    headers:HEADER_QUERY,
    data:body
  })
  .then((res)=>{
    if(res.status===200){
      setError(true)
      setMsg("Document Type Deleted")
    }
  })
  .catch((err)=>{
    console.error(err.response)
  })
}