import React, { useState } from "react";
import { Select, IconButton, MenuItem, Dialog } from "@mui/material";
import { AddCategoryPopup } from "../../../Single Components/AddCategoryPopup";
import { useSelector } from "react-redux";
import {
  addElasticWidthAPICall,
  addLengthCommissionAPICall,
  deleteElasticWidthAPICall,
  deleteLengthCommissionAPICall,
  viewElasticWidthAPICall,
  viewLengthCommissionAPICall,
  viewStitchingAPICall,
} from "../../../../API/Orders/workOrderAPI";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import { useEffect } from "react";
import { viewCuttingSlipModelAPICall } from "../../../../API/Settings/CuttingSlipModel/cuttingSlipMoldelAPI";

export const SalwarWO = (props) => {
  const { formValues, onChange } = props;
  //*Redux States
  //Role of User [admin or user]
  const userRole = useSelector((state) => state.userRoleSlice.value);
  const elasticWidthData = useSelector(
    (state) => state.elasticWidthSlice.value
  );
  const lengthCommissionData = useSelector(
    (state) => state.lengthCommissionSlice.value
  );
  // cutting slip template
  const cuttingSlipTemplate=useSelector((state)=>state.cuttingSlipSlice.value)

  const [isModify, setIsModify] = useState(false);
  //Snackbar States
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!");
  const [addElasticWidth, setAddElasticWidth] = useState(false);
  const [addLengthCommission, setAddLengthCommission] = useState(false);

  // VALIDATION STATES
  const [lengthFromAlert,setLengthFromAlert]=useState(false)
  const [lengthToAlert,setLengthToAlert]=useState(false)
  const [usedQtyAlert,setUsedQtyAlert]=useState(false)


  //Length Commission Form States
  const lengthCommissionInitialState = {
    lengthFrom: "",
    lengthTo: "",
    usedQty: "",
    minRate: 0,
    commission: 0,
  };
  const [lengthCommissionForm, setLengthCommissionForm] = useState(
    lengthCommissionInitialState
  );
  //Elastic Width
  const [newElasticWidth, setNewElasticWidth] = useState("");
  //-----------------------------------------------
  //Update Listener
  const updateListener = () => {
    setIsModify(!isModify);
  };

  //Close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setSuccessSnackOpen(false);
  };
  //Close ErrorSnackbar
  const closeErrorSnackbar = () => {
    setErrorSnackOpen(false);
  };
// cutting slip template api call
  useEffect(()=>{
    viewCuttingSlipModelAPICall()
  },[])

  //----------------------------------------------
  //*onChange
  //Get Length commission Form
  const getLengthCommissionForm = (key) => (e) => {
    const { value } = e.target;

    if (key === "lengthFrom") {
      setLengthCommissionForm({ ...lengthCommissionForm, lengthFrom: value });
      setLengthFromAlert(false)
      if(value===""){
        setLengthFromAlert(true)
      }
    }
    if (key === "lengthTo") {
      setLengthCommissionForm({ ...lengthCommissionForm, lengthTo: value });
      setLengthToAlert(false)
      if(value===""){
        setLengthToAlert(true)
      }
    }
    if (key === "usedQty") {
      setLengthCommissionForm({ ...lengthCommissionForm, usedQty: value });
      setUsedQtyAlert(false)
      if(value===""){
        setUsedQtyAlert(true)
      }
    }
    if (key === "minRate") {
      setLengthCommissionForm({ ...lengthCommissionForm, minRate: value });
    }
    if (key === "commission") {
      setLengthCommissionForm({ ...lengthCommissionForm, commission: value });
    }
  };

  //Get elastic width
  const getElasticWidth = (e) => {
    setNewElasticWidth(e.target.value);
  };

  //------------------------------------------------
  //*onClick
  //Add Length Commission body
  const addLengthCommissionBody = {
    fromLength: parseFloat(lengthCommissionForm?.lengthFrom),
    toLength: parseFloat(lengthCommissionForm?.lengthTo),
    usedQty: parseFloat(lengthCommissionForm?.usedQty),
    minRate: parseFloat(lengthCommissionForm?.minRate),
    commission: parseFloat(lengthCommissionForm?.commission),
  };
  //Add Length Commission
  const addNewLengthCommission = () => {
    if (lengthCommissionForm?.lengthFrom === "") {
      setLengthFromAlert(true)
    } else if (lengthCommissionForm?.lengthTo === "") {
      setLengthToAlert(true)
    } else if (lengthCommissionForm?.usedQty === "") {
      setUsedQtyAlert(true)
    } else {
      addLengthCommissionAPICall(
        addLengthCommissionBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        clearLengthCommissionState
      );
    }
  };
  //Delete Length Commission
  const deleteLengthCommission = (lengthCommissionId) => () => {
    deleteLengthCommissionAPICall(
      { lengthCommissionId },
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      updateListener
    );
  };

  //Add Elastic width body
  const addElasticWidthBody = {
    elasticWidth: newElasticWidth,
  };
  //Add Elastic width
  const addNewElasticWidth = () => {
    if (newElasticWidth === "") {
      setUsedQtyAlert(true)
    } else {
      addElasticWidthAPICall(
        addElasticWidthBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        clearElasticWidthState
      );
    }
  };
  //Delete Elastic Width
  const deleteElasticWidth = (elasticWidthId) => () => {
    deleteElasticWidthAPICall(
      { elasticWidthId },
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      updateListener
    );
  };

  //-----------------------------------------------
  //*useEffects
  useEffect(() => {
    viewElasticWidthAPICall();
    viewLengthCommissionAPICall();
  }, [isModify]);

  //*clear State
  //Length Commission Clear State
  const clearLengthCommissionState = () => {
    setLengthCommissionForm(lengthCommissionInitialState);
  };
  //Elastic width clear state
  const clearElasticWidthState = () => {
    setNewElasticWidth("");
  };

  return (
    <div className="work-order-middle-container">
      <div className="work-order-middle-left-container">
        <div className="global-single-input work-order-input">
          <p>Length</p>
          <input
            value={formValues?.length}
            onChange={userRole !== "admin" && onChange("length")}
            placeholder={userRole !== "admin" && "Enter length..."}
            disabled={userRole === "admin" ? true : false}
          />
          {userRole === "admin" && (
            <p
              onClick={() => setAddLengthCommission(true)}
              className="suffix-text-blue"
            >
              Add Length Commission
            </p>
          )}
        </div>
        <div className="global-single-input work-order-input">
          <p>Waist</p>
          <input
            value={formValues?.waist}
            onChange={userRole !== "admin" && onChange("waist")}
            placeholder={userRole !== "admin" && "Enter waist..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>Bottom</p>
          <input
            value={formValues?.bottom}
            onChange={userRole !== "admin" && onChange("bottom")}
            placeholder={userRole !== "admin" && "Enter bottom..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>Elastic Size</p>
          <input
            value={formValues?.elasticSize}
            onChange={userRole !== "admin" && onChange("elasticSize")}
            placeholder={userRole !== "admin" && "Enter elastic size..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        {
          cuttingSlipTemplate?.template == "template2" ?
          <div className="global-single-input work-order-input" style={{position:"relative"}}>
          <p>Inside Loose</p>
          <input
            value={formValues?.remarks}
            onChange={userRole !== "admin" && onChange("remarks")}
            placeholder={userRole !== "admin" && "Enter Inside loose..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        :

        <div className="global-single-input work-order-input">
          <p>Elastic Width</p>
          <Select
            value={formValues?.elasticWidth}
            onChange={userRole !== "admin" && onChange("elasticWidth")}
            disabled={userRole === "admin" ? true : false}
          >
             <MenuItem value="0">-select elastic width-</MenuItem>
            {elasticWidthData
              ?.slice(0)
              ?.reverse()
              ?.map((r, i) => (
                <MenuItem key={i} value={r?._id}>
                  {r?.elasticWidth}
                </MenuItem>
              )) || <MenuItem>No data</MenuItem>}
          </Select>
          {userRole === "admin" && (
            <p
              onClick={() => setAddElasticWidth(true)}
              className="suffix-text-blue"
            >
              Add Elastic Width
            </p>
          )}
        </div>
        }
        
        <div className="global-single-input work-order-input">
          <p>{cuttingSlipTemplate?.template == "template2" ? "Thigh Loose":"Ply"}</p>
          <input
            value={formValues?.ply}
            onChange={userRole !== "admin" && onChange("ply")}
            // placeholder={userRole !== "admin" && "Enter ply..."}
            placeholder={
              cuttingSlipTemplate?.template == "template2" ?
              "Enter Thigh Loose..."
              :userRole !== "admin"?
              ""
              :"Enter Ply..."
             }
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>{cuttingSlipTemplate?.template == "template2" ? "Notes":"Thigh Loose"}</p>
          <input
            value={formValues?.thighLoose}
            onChange={userRole !== "admin" && onChange("thighLoose")}
            // placeholder={userRole !== "admin" && "Enter thigh loose..."}
            placeholder={
              cuttingSlipTemplate?.template == "template2" ?
              "Enter Notes..."
              :userRole !== "admin"?
              ""
              :"Enter Thigh Loose..."
             }
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        {
          cuttingSlipTemplate?.template == "template2" ?
          <div className="global-single-input work-order-input">
          <p>Type</p>
          <Select
            value={formValues?.elasticWidth}
            onChange={userRole !== "admin" && onChange("elasticWidth")}
            disabled={userRole === "admin" ? true : false}
          >
             <MenuItem value="0">-select Type-</MenuItem>
            {elasticWidthData
              ?.slice(0)
              ?.reverse()
              ?.map((r, i) => (
                <MenuItem key={i} value={r?._id}>
                  {r?.elasticWidth}
                </MenuItem>
              )) || <MenuItem>No data</MenuItem>}
          </Select>
          {userRole === "admin" && (
            <p
              onClick={() => setAddElasticWidth(true)}
              className="suffix-text-blue"
            >
              Add Type
            </p>
          )}
        </div>
        :
        <div className="global-single-input work-order-input" style={{position:"relative"}}>
        <p>Notes</p>
        <input
          value={formValues?.remarks}
          onChange={userRole !== "admin" && onChange("remarks")}
          placeholder={userRole !== "admin" && "Enter remarks..."}
          disabled={userRole === "admin" ? true : false}
        />
        {/* <button className="work-order-notes-add-button">+</button>
        <div className="work-order-notes-content">
          <p>abc</p>
          <p>abc</p>
          <p>abc</p>
        </div> */}
      </div>

        }
       
      </div>

      {/* add length commission */}
      <Dialog open={addLengthCommission} maxWidth="lg"  onKeyDown={(e)=>e.key === "Escape" && setAddLengthCommission(false)}>
        <div className="add-thob-model-dialog-container">
          <h3>Add Length Commission</h3>
          <div className="add-thob-model-dialog-input-container">
            <div className="global-single-input from-length">
              <p>Length From</p>
              <input
                type="text"
                placeholder="Length from..."
                value={lengthCommissionForm?.lengthFrom}
                onChange={getLengthCommissionForm("lengthFrom")}
              />
              {
                lengthFromAlert?
                <p className="doc-validation-alert">Fill this field !!</p>
                :""
              }
            </div>
            <div className="global-single-input from-length">
              <p>To</p>
              <input
                type="text"
                placeholder="Length to..."
                value={lengthCommissionForm?.lengthTo}
                onChange={getLengthCommissionForm("lengthTo")}
              />
               {
                lengthToAlert?
                <p className="doc-validation-alert">Fill this field !!</p>
                :""
              }
            </div>
            <div className="global-single-input from-length">
              <p>Used Qty</p>
              <input
                type="text"
                placeholder="Used Qty..."
                value={lengthCommissionForm?.usedQty}
                onChange={getLengthCommissionForm("usedQty")}
              />
              {
                usedQtyAlert?
                <p className="doc-validation-alert">Fill this field !!</p>
                :""
              }
            </div>
            <div className="global-single-input from-length">
              <p>Min Rate</p>
              <input
                type="text"
                placeholder="Minimum rate..."
                value={lengthCommissionForm?.minRate}
                onChange={getLengthCommissionForm("minRate")}
              />
            </div>
            <div className="global-single-input from-length">
              <p>Commission</p>
              <input
                type="text"
                placeholder="Commission..."
                value={lengthCommissionForm?.commission}
                onChange={getLengthCommissionForm("commission")}
                onKeyDown={(e) => e.key === "Enter" && addNewLengthCommission()}
              />
            </div>
            <button onClick={addNewLengthCommission}>+</button>
          </div>
          <div className="add-popup-table">
            <table>
              <thead>
                <tr>
                  <th>Length(From-To)</th>
                  <th>Used Qty</th>
                  <th>Min Rate</th>
                  <th>Commission</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {lengthCommissionData
                  ?.slice(0)
                  ?.reverse()
                  ?.map((r, i) => (
                    <tr key={i}>
                      <td>{`${r?.fromLength} - ${r?.toLength}`}</td>
                      <td>{r?.usedQty}</td>
                      <td>{r?.minRate}</td>
                      <td>{r?.commission}</td>
                      <td>
                        <IconButton onClick={deleteLengthCommission(r?._id)}>
                          <i class="bi bi-trash3 delete-icon"></i>
                        </IconButton>
                      </td>
                    </tr>
                  )) || (
                  <tr>
                    <td colSpan={5}>No Data</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="add-popup-button-container">
            <button
              onClick={() => setAddLengthCommission(false)}
              className="btn btn-secondary"
            >
              Close
            </button>
          </div>
        </div>
      </Dialog>

      {/* add elastic width */}
      <AddCategoryPopup
        open={addElasticWidth}
        label="Add Elastic Width"
        closePopup={() => setAddElasticWidth(false)}
        onChange={getElasticWidth}
        value={newElasticWidth}
        addBtnClick={addNewElasticWidth}
      >
        {elasticWidthData
          ?.slice(0)
          ?.reverse()
          ?.map((r, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{r?.elasticWidth}</td>
              <td>
                <IconButton onClick={deleteElasticWidth(r?._id)}>
                  <i class="bi bi-trash3 delete-icon"></i>
                </IconButton>
              </td>
            </tr>
          )) || (
          <tr>
            <td colSpan={3}>No data</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* SnackBars  */}
      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </div>
  );
};
