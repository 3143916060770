import React,{useState,useRef,useEffect} from 'react'
import {Select,IconButton,MenuItem, Autocomplete, TextField, Skeleton} from '@mui/material'
import '../../../../css/Report/StockSummaryReport.css'
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";
import { viewAllBranchesAPICall } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import {viewAllProductsAPICall} from '../../../../API/Product List/productListAPI'
import {
  viewAllReadymadeCategoriesAPICall,
  viewAllAccessoryCategoryAPICall,
  viewAllMaterialCategoryAPICall,
  viewAllReadymadeSubCategoryAPICall,
  viewAllAccessorySubCategoryAPICall
} from '../../../../API/Product Master/ProductMasterAPI'
import {stockSummaryReportAPICall} from '../../../../API/Report/stockSummaryReportAPI'

import { convertDateFormat, currentTime, getCurrentTime, previousDate, today } from '../../../../Js/Date';

export const StockSummaryReport = () => {
    let stockSummaryReportPrint=useRef()
   
  // role based rendering
  const userRole = useSelector((state) => state.userRoleSlice.value);
  // stock summary data
  const stockSummaryReportData=useSelector((state)=>state.stockSummarySlice.value)

    // all branch list
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  //readymade category list
  const readyMadeCategoryList = useSelector(
    (state) => state.allReadymadeCategories.value
  );
 // All Accessory Category List
 const allAccessoryCatList = useSelector(
  (state) => state.allAccessoryCategorySlice.value
);
// All Material Category List
const allMaterialCatList = useSelector(
  (state) => state.allMaterialCategorySlice.value
);
// all product list
const allProductsList = useSelector(
  (state) => state.allProductsListSlice.value
);
 //readymade sub category list
 const readyMadeSubCategoryList = useSelector(
  (state) => state.allReadymadeSubCategorySlice.value
);
  // All Accessory SubCategory List
  const allAccessorySubCatList = useSelector(
    (state) => state.allAccessorySubCategorySlice.value
  );
   // login response
   const loginResponse = useSelector((state) => state.loginResponseSlice.value);

   const storeCode=localStorage.getItem('branchId')
  const mainCategoryList=[
    {
      name:"Material",
      value:2
    },
    {
      name:"Readymade",
      value:0
    },
    {
      name:"Accessories",
      value:1
    }
   
  ]

  const [branch,setBranch]=useState(null)
  const [mainCategory,setMainCategory]=useState(null)
  const [category,setCategory]=useState(null)
  const [subCategory,setSubCategory]=useState(null)
  const [product,setProduct]=useState(null)
  const [size,setSize]=useState(null)
  const [time, setTime] = useState(null);
  const [isLoading,setIsLoading]=useState(false)
  const [fromDate,setFromDate]=useState(previousDate)
  const [toDate,setToDate]=useState(today)

  // filtered states
  const [filteredMaterialProduct,setFilteredMaterialProduct]=useState([])
  const [filteredMaterialUnit,setFilterMaterialUnit]=useState([])
  
  // redaymade subcategory filter
  const [filteredReadymadeSubCat,setFilteredReadymadeSubCat]=useState([])  
  // readymade product list filter
  const [filteredReadymadeProduct,setFilteredReadymadeProduct]=useState([])
  // readymade unit filter
  const [filteredReadymadeUnit,setFilteredReadymadeUnit]=useState([])
  // acc product list
  const [filteredAccessoriesProduct,setFilteredAccessoriesProduct]=useState([])
  // accessories unit filter
  const [filteredAccessoriesUnit,setFilteredAccessoriesUnit]=useState([])





  // get current time fn
  useEffect(() => {
    setInterval(() => {
      setTime(`${new Date().getHours()}:${new Date().getMinutes()}`);
    }, 1000);
  }, [time]);

  useEffect(()=>{
    viewAllBranchesAPICall()
    stockSummaryReportAPICall({branchId:storeCode},setIsLoading)
  },[])

  // category apis
  useEffect(() => {
    viewAllReadymadeCategoriesAPICall()
    viewAllAccessoryCategoryAPICall()
    viewAllMaterialCategoryAPICall()
  }, [category]);

  // subcategory apis 
  useEffect(() => {
    viewAllReadymadeSubCategoryAPICall();
  }, [subCategory]);

  // accessories subcategory api call
  useEffect(()=>{
    viewAllAccessorySubCategoryAPICall({
      accessoryCategoryId:category
    })
  },[category,subCategory])
   
  useEffect(()=>{
    viewAllProductsAPICall({ type: mainCategory,branchId:storeCode });
  },[mainCategory])

  const getMainCategory =(e)=>{
    setMainCategory(e.target.value)
  }
  const getCategory=(e)=>{
    setCategory(e.target.value)
  }
  const getSubCategory=(e)=>{
    setSubCategory(e.target.value)
  }
  const getSize=(e)=>{
     setSize(e.target.value)
  }
  const getProduct =(e)=>{
    setProduct(e.target.value)
  }
  const getBranch=(e)=>{
    setBranch(e.target.value)
  }


  // material product filter
  useEffect(()=>{
    if(allProductsList !== undefined){
      let productArray=allProductsList?.filter((obj)=>{
        return obj.categoryId === category
      })
      setFilteredMaterialProduct(productArray)
    }
    
  },[category])

  // redaymade subcategory filter
  useEffect(()=>{
    if(readyMadeSubCategoryList !== undefined){
      let readymadeSubCatData= readyMadeSubCategoryList?.filter((obj)=>{
        return obj.categoryId === category
       })
       setFilteredReadymadeSubCat(readymadeSubCatData)
    }
    
  },[category])
  
   
    // readymade product list filter
    useEffect(()=>{
      if(allProductsList !== undefined){
        let readymadeProductData=allProductsList?.filter((obj)=>{
          return  obj.subCategoryId===subCategory
          })
          setFilteredReadymadeProduct(readymadeProductData)
      }
       
    },[subCategory])

    // accessories product list filter
    useEffect(()=>{
      if(allProductsList !== undefined){
        let accProduct=allProductsList?.filter((obj)=>{
          return  obj.subCategoryId===subCategory
      })
      setFilteredAccessoriesProduct(accProduct)
      }
     
    },[subCategory])

  

    // readymade unit filter
    useEffect(()=>{
      if(filteredReadymadeProduct !== undefined){
        let readymadeUnit=filteredReadymadeProduct?.filter((obj)=>{
          return obj._id===product
       })
       if(readymadeUnit === undefined){
        setFilteredReadymadeUnit([])
       } 
       else{
        setFilteredReadymadeUnit(readymadeUnit)
       }
      
      }
      
    },[product])
   
    

    // material unit filter
    useEffect(()=>{
      if(filteredMaterialProduct !== undefined){
        let materialUnit=filteredMaterialProduct?.filter((obj)=>{
          return obj._id===product
        })
        setFilterMaterialUnit(materialUnit)
      }
      
    },[product])
    
    
    // accessories unit filter 
    useEffect(()=>{

      if(filteredAccessoriesProduct !== undefined){
        let accessoriesUnit=[]
        accessoriesUnit=filteredAccessoriesProduct?.filter((obj)=>{
         if(obj._id===product){
           return obj
         }
      })
        if(accessoriesUnit === undefined){
          setFilteredAccessoriesUnit([])
        } 
        else{
          setFilteredAccessoriesUnit(accessoriesUnit)
        }
      }
     

     
    },[product])
    


    // stock summary report submit button function
    //changed form data to json on 17/02/2023 by vaishnav
    const stockSummaryBody={
      branchId:userRole==='user'?loginResponse.branchId: branch,
      itemId:product,
      itemType:mainCategory,
      dimension:size,
      categoryId:category,
      subCategoryId:subCategory,
      fromDate:`${fromDate} 00:00:00`,
      endDate:`${toDate} 23:59:59`
    }
    // const formData=new FormData();
    //   //  {branch !== "0" &&
    //    formData.append("branchId",branch)
    //   //  }
      
    //    formData.append("itemId",product)
    //    formData.append("itemType",mainCategory)
    //    formData.append("dimension",size)

    const stockSummarySubmitBtnFn=()=>{
      stockSummaryReportAPICall(stockSummaryBody,setIsLoading)
    }
   



   
    

 
  console.log(stockSummaryReportData);
   
 
  return (
    <>
    <div className="global-page-parent-container">
    <p className="breadcrumb">Report &gt; Stock Summary Report</p>
    <div className="daily-report-top-container">
      <h3>Stock Summary Report</h3>
      <hr className="global-hr"/>
      <div className="daily-report-top-input-container" style={{flexWrap:"wrap",justifyContent:"flex-start"}} >
      {userRole === 'admin' && 
       <div className="global-single-input stock-summary-input auto-complete">
        <p>Branch</p>
         <Autocomplete
             sx={{width:"100%"}}
             options={allBranchesList|| [""]}
             getOptionLabel={(option) => `${option?.storeCode}-${option?.branchName}`}

             renderInput={(params) => <TextField {...params} 
             placeholder="Select Branch"
             />}
            
             onChange={(e, newValue) =>
              setBranch(newValue?.storeCode)
             }
            />
       </div>
        } 
       <div className="global-single-input stock-summary-input auto-complete">
        <p>Main Category</p>
        <Autocomplete
             sx={{width:"100%"}}
             options={mainCategoryList|| [""]}
             getOptionLabel={(option) => option?.name}

             renderInput={(params) => <TextField {...params} 
             placeholder="Select MainCaterory"
             />}
            
             onChange={(e, newValue) =>
              setMainCategory(newValue?.value)
             }
            />
       </div>
  
       <div className="global-single-input stock-summary-input auto-complete">
        <p>Category</p>
       
          <Autocomplete
          sx={{width:"100%"}}
          options={
            mainCategory===0 ?
            readyMadeCategoryList||[{"categoryName":"No options"}]
            :mainCategory === 1 ?
            allAccessoryCatList||[{"accessoryCategory":"No options"}]
            :mainCategory===2?
            allMaterialCatList||[{"materialCategoryName":"No options"}]
            :undefined
            || [""]}
          getOptionLabel={(option) => 
            mainCategory===0 ?
            option?.categoryName
            :mainCategory === 1 ?
            option?.accessoryCategory
            :mainCategory===2?
            option?.materialCategoryName
            :undefined
          }

          renderInput={(params) => <TextField {...params} 
          placeholder="Select Category"
          />}
         
          onChange={(e, newValue) =>
           setCategory(newValue?._id)
          }
         />
        
      
          {/* <Select
            value={category}
            onChange={getCategory}
          >
            {mainCategory===0 ?
             readyMadeCategoryList !== undefined && 
              readyMadeCategoryList?.map((item,i)=>(
               <MenuItem value={item?._id} key={item?._id}>
                {item?.categoryName}
               </MenuItem>
              ))
             
          :
          
          mainCategory === 1 ?
            allAccessoryCatList !== undefined&&
            allAccessoryCatList?.map((r, i) => (
              <MenuItem key={i} value={r?._id}>
                {r?.accessoryCategory}
              </MenuItem>
            ))

          :mainCategory===2?
            allMaterialCatList !== undefined &&
            allMaterialCatList?.map((r,i)=>(
              <MenuItem key={i} value={r?._id}>{r?.materialCategoryName}</MenuItem>
            ))
          :undefined
              
           }  
        
        </Select> */}
        </div>
        {
          mainCategory !==2 && 
          <div className="global-single-input stock-summary-input auto-complete">
          <p>Sub Category</p>
          <Autocomplete
             sx={{width:"100%"}}
             options={
              mainCategory===0?
              filteredReadymadeSubCat||[{"subCategoryName":"No options"}]
              : mainCategory===1?
              allAccessorySubCatList||[{"accessorySubCategory":"No options"}]
              :undefined
              || [""]}
             getOptionLabel={(option) => 
              mainCategory===0?
              option?.subCategoryName
              : mainCategory===1?
              option?.accessorySubCategory
              :undefined
            }

             renderInput={(params) => <TextField {...params} 
             placeholder="Select SubCategory"
             />}
            
             onChange={(e, newValue) =>
              setSubCategory(newValue?._id)
             }
            />
          {/* <Select
            value={subCategory}
            onChange={getSubCategory}
          >
            {
              mainCategory===0?
              filteredReadymadeSubCat?.map((r,i)=>(
                <MenuItem value={r._id} key={r._id}>{r.subCategoryName}</MenuItem>
              ))
              : mainCategory===1?
              allAccessorySubCatList?.map((r,i)=>(
                <MenuItem value={r._id} key={r._id}>{r.accessorySubCategory}</MenuItem>
              ))
              :undefined
            }

          </Select> */}
         </div>

        }
     
       <div className="global-single-input stock-summary-input auto-complete">
        <p>Product</p>
        <Autocomplete
             sx={{width:"100%"}}
             options={
              mainCategory === 2 ?
              filteredMaterialProduct||[{"productName":"No options"}]
              : mainCategory === 0 ?
              filteredReadymadeProduct||[{"productName":"No options"}]
              :mainCategory === 1 ?
              filteredAccessoriesProduct||[{"productName":"No options"}]
              :undefined
             
              || [""]}
             getOptionLabel={(option) =>option?.productName}

             renderInput={(params) => <TextField {...params} 
             placeholder="Select Product"
             />}
            
             onChange={(e, newValue) =>
              setProduct(newValue?._id)
             }
            />
        {/* <Select
          value={product}
          onChange={getProduct}
        >
          {
            mainCategory === 2 ?
            filteredMaterialProduct?.map((r,i)=>(
              <MenuItem value={r._id} key={r._id}>{r.productName}</MenuItem>
            ))
            : mainCategory === 0 ?
            filteredReadymadeProduct?.map((r,i)=>(
              <MenuItem value={r._id} key={r._id}>{r.productName}</MenuItem>
            ))
            :mainCategory === 1 ?
            filteredAccessoriesProduct?.map((r,i)=>(
              <MenuItem value={r._id} key={r._id}>{r.productName}</MenuItem>
            ))
            :undefined
          }
        </Select> */}
       </div>
       {
          mainCategory !==2&&
          <div className="global-single-input stock-summary-input auto-complete">
       
          <p>Size/Unit</p>
          <Autocomplete
             sx={{width:"100%"}}
             options={
               mainCategory === 0?
                filteredReadymadeUnit[0]?.size||[{"brandUnit":"No options"}]
               :mainCategory===1?
                filteredAccessoriesUnit[0]?.size||[{"brandUnit":"No options"}]
              :undefined
              || [""]
            }
             getOptionLabel={(option) => option?.brandUnit}

             renderInput={(params) => <TextField {...params} 
             placeholder="Select Size"
             />}
            
             onChange={(e, newValue) =>
              setSize(newValue?.brandUnit)
             }
            />
            {/* <Select
              value={size}
              onChange={getSize}
            >
               {
                mainCategory === 0?
                filteredReadymadeUnit?.map((r,i)=>{
                     return r.size?.map((k,i)=>(
                    <MenuItem value={k.brandUnit} key={i}>{k.brandUnit}</MenuItem>
                   ))
                  
                   })
                  :mainCategory===1?
                  filteredAccessoriesUnit?.map((r,i)=>{
                    return r.size?.map((k,i)=>(
                      <MenuItem value={k.brandUnit} key={i}>{k.brandUnit}</MenuItem>
                     ))
                  })
              :undefined
            }
          
           </Select> */}
          
         
         </div>
          
        }
      
       <div className="global-single-input stock-summary-input">
        <p>From</p>
        <input type="date" value={fromDate} onChange={(e)=>setFromDate(e.target.value)}/>
       </div>
       <div className="global-single-input stock-summary-input">
        <p>To</p>
        <input type="date" value={toDate} onChange={(e)=>setToDate(e.target.value)}/>
       </div>
      
      </div>
     <div className="expense-report-button-container">
     <button onClick={stockSummarySubmitBtnFn} className="btn btn-primary report-submit-btn">Submit</button>
      </div> 
     
     </div>
     
     <div className="global-white-bg-container">
     <div className="stock-report-print-download-icon-container">
      <ReactToPrint
        trigger={()=>(
            <IconButton className="print-icon-container">
              <i class="bi bi-printer printer-icon"></i>
            </IconButton>
        )}
        content={()=>stockSummaryReportPrint}
        pageStyle="@page { size: auto;  margin: 15mm 0 10mm 0; } }"
      />  
    
     <IconButton className="download-icon-container">
       <i class="bi bi-arrow-down-circle download-icon"></i>
     </IconButton>
     </div>
     <div className="global-table-container">
        <table className="global-table">
           <thead>
             <tr>
              {
                userRole==="admin"&&
                <>
                <th>Branch Code</th>
                <th>Branch Name</th>
                </>
              }
              
              <th>Prod.ID</th>
              <th>Product</th>
              <th>Date</th>
              <th>OP Bal</th>
              <th>Purchases</th>
              <th>Trans IN</th>
              <th>Sal Return</th>
              <th>Post Adj</th>
              <th>Sales</th>
              <th>Trans OUT</th>
              <th>Neg Adj</th>
              <th>Pur Return</th>
              <th>Size/Unit</th>
              <th>Cl Stock</th>
             </tr>
           </thead>
           {
            isLoading ?(
              <tbody>
              <tr>
                   <td colSpan={16}>
                     <Skeleton
                       variant="rectangular"
                       animation="wave"
                       width={"100%"}
                       height={30}
                     />
                   </td>
                 </tr>
                 <tr>
                   <td colSpan={16}>
                     <Skeleton
                       variant="rectangular"
                       animation="wave"
                       width={"100%"}
                       height={30}
                     />
                   </td>
                 </tr>
                 <tr>
                   <td colSpan={16}>
                     <Skeleton
                       variant="rectangular"
                       animation="wave"
                       width={"100%"}
                       height={30}
                     />
                   </td>
                 </tr>
           </tbody>
            ):(
              <tbody>
              {stockSummaryReportData!==undefined && stockSummaryReportData.length!==0? stockSummaryReportData?.map((r,i)=>(
                <tr>
                  {
                    userRole === 'admin' &&
                    <>
                     <td>{r.branchId}</td>
                     <td>{r.locationName}</td>
                    </>
                  }
               
                <td>{r.productId}</td>
                <td>{r.productName}</td>
                <td>{convertDateFormat(r.date)}</td>
                <td>{r.opbalance?.toFixed(2)}</td>
                <td>{r.purchases}</td>
                <td>{r.transin}</td>
                <td>{r.salesReturn}</td>
                <td>{r.postadj?.toFixed(2)}</td>
                <td>{r.sales}</td>
                <td>{r.transout}</td>
                <td>{r.isNegative}</td>
                <td>{r.purchasereturn}</td>
                <td>{r.dimension}</td>
                <td>{r.closingstock?.toFixed(2)}</td>
               
              </tr>
              ))
              :
              <tr>
                <td style={{textAlign:"center"}} colSpan={16}>No Data</td>
              </tr>
            }
              
             </tbody>
            )
           }
          
        </table>
     </div>
    </div>
   
    </div>

 {/* stock summary print view */}

     <div 
       style={{display:'none'}}
     >
       <div ref={(el)=>(stockSummaryReportPrint = el)}>
          <div className='print-view-top-container'>
            <div className='print-view-top-left-container' style={{width:"30%"}}>
              <h6>Printed On:{convertDateFormat(today)}&nbsp;{getCurrentTime()}</h6>
            </div>
            <div className='print-view-top-middle-container' style={{width:"40%"}}>
            {userRole === "admin" ? (
                <h3>{loginResponse?.data?.profile?.companyName}</h3>
              ) : userRole === "user" ? (
                <h3>{loginResponse?.storeName}</h3>
              ) : undefined}
                 <p>STOCK SUMMARY REPORT</p>
                 {
                  fromDate!=="" && toDate !==""&&
                   <p>{`(${convertDateFormat(fromDate)} to ${convertDateFormat(toDate)})`}</p>
                 }
            </div>

           <div className='print-view-top-right-container' style={{width:"30%",textAlign:"right"}}>
                <p>Supplier&nbsp;:&nbsp;---</p>
                 {userRole ==="user" && <p>{loginResponse?.branchId}-{loginResponse?.branchName}</p>}

            </div>
          </div>
          <div className='print-view-table-container report-print-view-table-with-clr'>
            {
              userRole==="admin" &&
              <div className="commission-report-summary-top-container">
                <h3 style={{ width: "20%" }}>{stockSummaryReportData!==undefined && stockSummaryReportData.length!==0 && stockSummaryReportData[0].branchId}</h3>
                <h3>{stockSummaryReportData!==undefined && stockSummaryReportData.length!==0 &&  stockSummaryReportData[0].locationName}</h3>
              </div>
            }
          <table >
          <thead>

             <tr>
              {/* {
                userRole==="admin"&&
                <>
                <th>Branch Code</th>
                <th>Branch Name</th>
                </>
              } */}
              <th>No</th>
              <th>Prod.ID</th>
              <th>Product</th>
              <th>Date</th>
              <th>OP Bal</th>
              <th>Purchases</th>
              <th>Trans IN</th>
              <th>Sal Return</th>
              <th>Post Adj</th>
              <th>Sales</th>
              <th>Trans OUT</th>
              <th>Neg Adj</th>
              <th>Pur Return</th>
              <th>Size/Unit</th>
              <th>Cl Stock</th>
             </tr>
           </thead>
           <tbody>
           {stockSummaryReportData?.map((r,i)=>(
              <tr>
                {/* {
                  userRole === 'admin' &&
                  <>
                  <td>{r.branchId}</td>
                  <td>{r.locationName}</td>
                  </>
                } */}
              <td>{i+1}</td>
              <td>{r.productId}</td>
              <td>{r.productName}</td>
              <td>{convertDateFormat(r.date)}</td>
              <td>{r.opbalance?.toFixed(2)}</td>
              <td>{r.purchases}</td>
              <td>{r.transin}</td>
              <td>{r?.salesReturn}</td>
              <td>{r.postadj?.toFixed(2)}</td>
              <td>{r.sales}</td>
              <td>{r.transout}</td>
              <td>{r.isNegative}</td>
              <td>{r.purchasereturn}</td>
              <td>{r.dimension}</td>
              <td>{r.closingstock?.toFixed(2)}</td>   
            </tr>
            ))}
            
           </tbody>
          </table>
          </div>
     
       </div>
     </div>
</>
  )
}
