import { createSlice } from "@reduxjs/toolkit";

export const dutiesAndTaxes = createSlice({
  name: "dutiesAndTaxes",
  initialState: {
    typeOfDutyOrTax: { option: "GST" },
    taxType: { option: "Central" },
    roundingMethod: { option: "Not Applicable" },
    openingBalance:''
  },
  reducers: {
    setTypeOfDutyOrTax: (state, action) => {
      state.typeOfDutyOrTax = action.payload;
    },
    setTaxType: (state, action) => {
      state.taxType = action.payload;
    },
    setRoundingMethod: (state, action) => {
      state.roundingMethod = action.payload;
    },
    setOpeningBalance: (state, action) => {
      state.openingBalance = action.payload;
    },
  },
});
// 
export const { setRoundingMethod, setTaxType, setTypeOfDutyOrTax,setOpeningBalance } =
  dutiesAndTaxes.actions;
export default dutiesAndTaxes.reducer;
