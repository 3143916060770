import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setAddress,
  setBankAccountDetails,
  setBankAccountNo,
  setBankName,
  setBranchName,
  setCountry,
  setEmailId,
  setIfscCode,
  setName,
  setOpeningBalance,
  setPhoneNo,
  setStreet,
  setCity,
  setState,
} from "../../../../../Redux/Account/capitalAccountSlice";
import InputBox from "../InputBox";
import SubmitBtn from "../SubmitBtn";
import DropdownInputBox from "../DropdownInputBox";
import countryState from '../../../../../Assets/JSON/countryStates.json'

const CapitalAccount = () => {
  const bankAccountDetailsOption = [{ option: "Yes" }, { option: "No" }];
  const dispatch = useDispatch();
  const {
    openingBalance,
    bankAccountDetails,
    bankName,
    bankAccountNo,
    ifscCode,
    branchName,
    name,
    address,
    street,
    city,
    state,
    country,
    phoneNo,
    emailId,
  } = useSelector((state) => state.capitalAccountSlice);

  const capitalAccountSlice = useSelector((state) => state.capitalAccountSlice);
  const handleCapitalAccountSubmit = () => {
    console.log(capitalAccountSlice);
  };


  return (
    <>
      <div
        style={{ padding: "2% 4%" }}
        className="ledger-section-container-input-bank bottom-line"
      >
        <div>
          {/*name */}

          <InputBox
            label={"Name"}
            value={name}
            onChange={(e) => dispatch(setName(e.target.value))}
          />

          {/*Address*/}
          <InputBox
            label={"Address"}
            value={address}
            onChange={(e) => dispatch(setAddress(e.target.value))}
          />
          {/*street */}
          <InputBox
            label={"Street"}
            value={street}
            onChange={(e) => dispatch(setStreet(e.target.value))}
          />
        </div>
        <div>
          {/*city */}
          <InputBox
            label={"City"}
            value={city}
            onChange={(e) => dispatch(setCity(e.target.value))}
          />

          {/* country */}
          <DropdownInputBox labelName={"Country"} value={country} options={countryState.countries} onChange={(e,value)=>{
            dispatch(setCountry(value) )
            dispatch(setState(null) )}} />
          {/* State */}
          <DropdownInputBox labelName={"State"} value={state} options={country?.states} onChange={(e,value)=>dispatch(setState(value))} />
        </div>
        <div>
          {/*Phone no*/}
          <InputBox
            label={"Phone no"}
            value={phoneNo}
            onChange={(e) => dispatch(setPhoneNo(e.target.value))}
          />
          {/*email Id*/}
          <InputBox
            label={"Email"}
            value={emailId}
            onChange={(e) => dispatch(setEmailId(e.target.value))}
          />
          {/*opening balance*/}
          <InputBox
            label={"Opening Balance"}
            value={openingBalance}
            onChange={(e) => dispatch(setOpeningBalance(e.target.value))}
          />
        </div>
      </div>
      <div
        style={{ padding: "2% 4%" }}
        className="ledger-section-container-input-bank bottom-line"
      >
        <div>
          {/*bank account details */}

          <DropdownInputBox
            labelName={"Bank account details"}
            value={bankAccountDetails}
            options={bankAccountDetailsOption}
            onChange={(e, value) => dispatch(setBankAccountDetails(value))}
          />

          {/*bank Name*/}
          <InputBox
            label={"Bank Name"}
            value={bankName}
            onChange={(e) => dispatch(setBankName(e.target.value))}
          />
          {/*bank account number  */}
          <InputBox
            label={"Bank account number"}
            value={bankAccountNo}
            onChange={(e) => dispatch(setBankAccountNo(e.target.value))}
          />
        </div>
        <div style={{ justifyContent: "flex-start" }}>
          {/*IFSC Code  */}
          <InputBox
            label={"IFSC Code"}
            value={ifscCode}
            onChange={(e) => dispatch(setIfscCode(e.target.value))}
            containerStyle={{width:'31%'}}

          />

          {/*branch name */}
          <InputBox
            label={"Branch Name"}
            value={branchName}
            onChange={(e) => dispatch(setBranchName(e.target.value))}
            containerStyle={{width:'31%'}}

          />
        </div>
      </div>
      
      <SubmitBtn onClick={handleCapitalAccountSubmit} />
    </>
  );
};

export default CapitalAccount;
