import axios from "axios"
import { get_sales_return_id } from "../../Redux/Sales/SalesReturn/generateSalesRetunIdSlice"
import { get_all_sales_return_invoiceNo } from "../../Redux/Sales/SalesReturn/getAllSalesReturnInvoiceNoSlice"
import { get_all_sales_return } from "../../Redux/Sales/SalesReturn/getAllSalesReturnSlice"
import { get_delivery_invoiceNo } from "../../Redux/Sales/SalesReturn/getInvoiceForDeliverySlice"
import { get_billReturn_invoiceNo } from "../../Redux/Sales/SalesReturn/getInvoiceNoForBillReturnSlice"
import { get_invoiceNo_workOrder } from "../../Redux/Sales/SalesReturn/getInvoiceNoForWorkOrderSlice"
import { get_order_details_salesReturn } from "../../Redux/Sales/SalesReturn/getOrderDetailsForSalesReturnSlice"
import { get_sales_return_details } from "../../Redux/Sales/SalesReturn/getWorkOrderDetailsForSalesReturnSlice"
import { get_sales_return_singleView } from "../../Redux/Sales/SalesReturn/salesReturnSingleViewSlice"
import store from "../../Redux/store"
import {HEADERS, HEADER_QUERY} from '../UrlAndPaths'

// get all sales return api call
export const getAllSalesReturnAPICall= async(body,setIsLoading)=>{
    setIsLoading(true)
    await axios.post("sales/getSalesReturns",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_all_sales_return({salesReturnData:res.data}))
            setIsLoading(false)
        }
    })
    .catch((err)=>{
        store.dispatch(get_all_sales_return({salesReturnData:undefined}))
        console.log(err.response);
        setIsLoading(false)
    })
}

// get all sales return invoice no api call
export const getAllSalesReturnInvoiceNoAPICall=async(body)=>{
    await axios.post("sales/getSalesReturnInvoices",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_all_sales_return_invoiceNo({salesRetunInvoiceNo:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(get_all_sales_return_invoiceNo({salesRetunInvoiceNo:undefined}))
        console.log(err.response);
    })
}

// generate sales return id 
export const generateSalesReturnIdAPICall=async()=>{
    await axios.get("sales/generateSalesReturnId",HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_sales_return_id({salesReturnId:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(get_sales_return_id({salesReturnId:undefined}))
        console.log(err.response);
    })
}

// delivery invoice no list
export const getInvoiceNoForDeliveryAPICall=async(body)=>{
  await axios.post("sales/getWorkorderNosSalesReturnDelivery",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
        store.dispatch(get_delivery_invoiceNo({deliveryInvoiceList:res.data}))
    }
  })
  .catch((err)=>{
    store.dispatch(get_delivery_invoiceNo({deliveryInvoiceList:undefined}))
    console.log(err.response);
  })
}

//  bill return invoice no list
export const getInvoiceNoForBillReturnAPICall= async (body)=>{
   await axios.post("sales/getOrderNosSalesReturn",body,HEADERS)
   .then((res)=>{
    if(res.status===200){
        store.dispatch(get_billReturn_invoiceNo({billReturnInvoiceNoList:res.data}))
    }
   })
   .catch((err)=>{
    store.dispatch(get_billReturn_invoiceNo({billReturnInvoiceNoList:undefined}))
    console.log(err.response);
   })
}

// work order invoice no list
export const getInvoiceNoForWorkOrderAPICall= async (body)=>{
    await axios.post("sales/getWorkorderNosSalesReturn",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_invoiceNo_workOrder({workOrderInvoiceNo:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(get_invoiceNo_workOrder({workOrderInvoiceNo:undefined}))
        console.log(err.response);
    })
}

//  get work order details api call
export const getWorkOrderDetailsForSalesReturnAPICall=async(body)=>{
    await axios.post("sales/workorderDetailsSalesReturn",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_sales_return_details({workOrderDetails:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(get_sales_return_details({workOrderDetails:undefined}))
        console.log(err.response);
    })
}

// get sales order details api call
export const getOrderDetailsForSalesReturnAPICall=async(body)=>{
    await axios.post("sales/orderDetailsSalesReturn",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_order_details_salesReturn({orderDetails:res.data}))
        }
    })
    .catch((err)=>{
        console.log(err.response);
        store.dispatch(get_order_details_salesReturn({orderDetails:undefined}))
    })
} 


//  add sales return api call
export const addSalesReturnAPCall= async (
    body,
    setIsLoading,
    setSuccessSnackOpen,
    setErrorSnackOpen,
    setSnackMsg,
    updateListner,
    clearState,
    openInvoiceDialog,
    clearBillingForm,
    setAddReturn,
    setPaymnetDialog
    )=>{
      setIsLoading!==undefined && setIsLoading(true)
  await axios.post("sales/addsalesreturn",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
        setSuccessSnackOpen(true)
        setSnackMsg("Sales Return Added")
        updateListner()
        clearState()
        clearBillingForm()
        setAddReturn(false)
      setIsLoading!==undefined && setIsLoading(false)
       
        
       
        setPaymnetDialog(false)
    }
  })
  .catch((err)=>{
    setIsLoading!==undefined && setIsLoading(false)

    switch (err.response.status) {
      case 401:
        setSnackMsg("Unauthorized!!");
        break;
      case 409:
        setSnackMsg("Conflit!!");
        break;
      case 403:
        setSnackMsg("Forbidden!!");
        break;
      case 500:
        setSnackMsg("Server error!!");
        break;

      default:
        setSnackMsg(err.response.data);
        break;
    }
    
      setErrorSnackOpen(true)
     
  })
}

// sales return single view
export const salesReturnSingleViewAPICall=async(body)=>{
  await axios.post("sales/getSingleSalesReturn",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
        store.dispatch(get_sales_return_singleView({salesReturnSingleViewData:res.data}))
    }
  })
  .catch((err)=>{
    store.dispatch(get_sales_return_singleView({salesReturnSingleViewData:undefined}))
    console.log(err.response);
  })
}

// delete sales return api call
export const deleteSalesReturnAPICall=async(returnId,updateListner)=>{
  axios.delete("sales/deleteSalesReturn",{
    headers:HEADER_QUERY,
    data:{
        returnId
    }
  })
  .then((res)=>{
     if(res.status===200){
        updateListner()
     }
  })
  .catch((err)=>{
    console.log(err.response);
  })
}

// edit sales return api call
export const editSalesReturnAPICall=async(
    body,
    setEditSalesReturn,
    updateListner,
    setSuccessSnackOpen,
    setErrorSnackOpen,
    setSnackMsg
    )=>{
    axios.put("sales/editSalesReturn",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            setEditSalesReturn(false)
            updateListner()
            setSnackMsg('Updated')
            setSuccessSnackOpen(true)
           

        }
    })
    .catch((err)=>{
        console.log(err.response);
        setErrorSnackOpen(true)
        if (err.response === undefined) {
            setSnackMsg("Network Connection error!!");
          }
          if (err.response.status === 409) {
            setSnackMsg("Category Already Exist");
          }
        
          if (err.response.status === 401) {
            setSnackMsg("Unauthorized");
          }
          if (err.response.status === 403) {
            setSnackMsg("Forbidden");
          }

    })
}