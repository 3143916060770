import { createSlice } from "@reduxjs/toolkit";

export const singleEmployeeSlice=createSlice({
    name:'singleEmployeeSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_single_employee:(state,action)=>{
            state.value=action.payload.singleEmp
        }
    }
})

export const {update_single_employee}=singleEmployeeSlice.actions
export default singleEmployeeSlice.reducer