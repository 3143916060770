import { createSlice } from "@reduxjs/toolkit";

export const unitOfMeasurmentSlice = createSlice({
  name: "unitOfMeasurmentSlice",
  initialState: {
    apiResList: undefined,
    editClickData:undefined,
    subUomList:undefined,
    filterByPurchaseList:undefined
  },
  reducers: {
    get_api_res_list: (state, action) => {
      state.apiResList = action.payload.resData;
    },
    update_res_list_by_row: (state, action) => {
      state.editClickData = action.payload.singleRowData;
    },
    get_sub_uom_list: (state, action) => {
      state.subUomList = action.payload.subUomData;
    },
    get_uom_filter_by_purchaseuom:(state,action)=>{
      state.filterByPurchaseList=action.payload.filterByPurchseUOMData
    }
  },
});

export const { get_api_res_list,update_res_list_by_row ,get_sub_uom_list,get_uom_filter_by_purchaseuom} = unitOfMeasurmentSlice.actions;

export default unitOfMeasurmentSlice.reducer;
