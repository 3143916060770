import { createSlice } from "@reduxjs/toolkit";

export const getInvoiceNoForBillReturnSlice=createSlice({
    name:'billReturnInvoiceNoList',
    initialState:{
        value:undefined
    },
    reducers:{
        get_billReturn_invoiceNo:(state,action)=>{
            state.value=action.payload.billReturnInvoiceNoList
        }
    }
})
export const {get_billReturn_invoiceNo} = getInvoiceNoForBillReturnSlice.actions;
export default getInvoiceNoForBillReturnSlice.reducer;