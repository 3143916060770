import React,{useState} from 'react'
import '../../../../css/Settings/Shift/shift.css'
import {update_select_shift} from '../../../../Redux/Settings/Shift/SelectShiftSlice'
import Store from '../../../../Redux/store'
import {get_shift_reopen_dialog} from '../../../../Redux/Settings/Shift/shiftReopenDialogSlice'
import {get_shift_denomination_dialog} from '../../../../Redux/Settings/Shift/shiftDenominationDialogSlice'
import { AddShift } from './AddShift'
export const Shift = () => {
    const [activeBtn,setActiveBtn]=useState('addShiftButton')


    const clickAddShiftButton=()=>{
        document.getElementById("addShiftButton").classList.add("focus");
        document.getElementById("shiftReopenButton").classList.remove("focus");
        // document.getElementById("shiftEndButton").classList.remove("focus");
        setActiveBtn('addShiftButton')
        // Store.dispatch(update_select_shift({selectShift:'addShiftButton'}))
     }

     const clickShiftReopenButton=()=>{
        document.getElementById("addShiftButton").classList.remove("focus");
        document.getElementById("shiftReopenButton").classList.add("focus");
        // document.getElementById("shiftEndButton").classList.remove("focus");
        setActiveBtn('shiftReopenButton')
        Store.dispatch(update_select_shift({selectShift:'shiftReopenButton'}))
        Store.dispatch(get_shift_reopen_dialog({shiftReopen:true}))
     }
    //  const clickShiftEndButton=()=>{
    //     document.getElementById("addShiftButton").classList.remove("focus");
    //     document.getElementById("shiftReopenButton").classList.remove("focus");
    //     document.getElementById("shiftEndButton").classList.add("focus");
    //     setActiveBtn('shiftEndButton')
    //     Store.dispatch(update_select_shift({selectShift:'shiftEndButton'}))
    //     Store.dispatch(get_shift_denomination_dialog({shiftDenomination:true}))
    //  }
  return (
    <>
    <div className="shift-container">
          <div
            id="addShiftButton"
            className="non-focus focus shift-single-menu"
            onClick={clickAddShiftButton}
          >
           <ul>
            <li>Add Shift</li>
           </ul>
          </div>
          <div
            id="shiftReopenButton"
            className="non-focus shift-single-menu"
            onClick={clickShiftReopenButton}
          >
            <ul>
                <li>Shift Reopen</li>
            </ul>
          </div>
          {/* <div
            id="shiftEndButton"
            className="non-focus shift-single-menu"
            onClick={clickShiftEndButton}
          >
             <ul>
                <li>Shift End</li>
             </ul>
          </div> */}
    </div>
    
       {
        activeBtn==="addShiftButton"?
        <div  className="shift-container add-shift-bottom-container">
        <AddShift />
        </div>
        :undefined
       }
   
    </>
  )
}
