import {createSlice} from '@reduxjs/toolkit'

export const receivedTransferSingleViewSlice=createSlice({
    name:'receivedTransferSingleViewSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_received_transfer_single_view:(state,action)=>{
            state.value=action.payload.receivedTransferSingleViewData
        }
    }
})
export const {get_received_transfer_single_view} = receivedTransferSingleViewSlice.actions;
export default receivedTransferSingleViewSlice.reducer