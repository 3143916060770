import { createSlice } from "@reduxjs/toolkit";

export const shiftTransferOrderListSlice=createSlice({
    name:"shiftTransferOrderList",
    initialState:{
        value:undefined
    },
    reducers:{
        get_shift_transfer_order_list:(state,action)=>{
            state.value=action.payload.shiftTransferOrderListData
        }
    }
})
export const {get_shift_transfer_order_list}=shiftTransferOrderListSlice.actions;
export default shiftTransferOrderListSlice.reducer