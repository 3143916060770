import React from "react";

const InputBox = ({
  isCurrentBalance,
  currentBalance,
  label,
  onChange,
  value,
  disabled,
  placeholder,
  containerStyle,
  inputBoxStyle,
  type,
  labelStyle,
  isCheckBox,
  checkBoxOnChange,
  isChecked
}) => {

  const dynamicLabelStyle = disabled ? { ...labelStyle, color: "#888992" } : labelStyle;
  const dynamicInputStyle = disabled ? { ...inputBoxStyle, backgroundColor : "#f8f8f8" } : inputBoxStyle;
  return (
    <div
      className="global-single-input add-purchase-input auto-complete"
      style={{...containerStyle}}
    >
     {!isCheckBox && <p style={labelStyle}>{label}</p>}

      {isCheckBox && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: '25px'
          }}
        >
          <p style={dynamicLabelStyle}>{label}</p>
          <input onChange={checkBoxOnChange} style={{ width: "20px" }}  checked={isChecked} type="checkbox" name="" id="" />
        </div>
      )}

      <input
        style={dynamicInputStyle}
        type={type ? type : "text"}
        disabled={disabled ? disabled : false}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
      {isCurrentBalance && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBlockStart: "5px",
          }}
        >
          <p style={{ color: "red" }}>
            Current Balance : {currentBalance ? currentBalance : "00"}
          </p>
        </div>
      )}
    </div>
  );
};

export default InputBox;
