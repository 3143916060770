import axios from "axios";
import { HEADERS } from "../../../../API/UrlAndPaths";
import store from "../../../../Redux/store";
import { edit_establishment_id, view_establishid_by_id, view_establishmentId,generate_establishId } from "./EstablishmentIdSlice";

export const ViewEstablishmentIdAPI =async(body)=>{
    await axios
    .post('employee/viewSponsor',body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(view_establishmentId({establishmentIdViewData:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(view_establishmentId({establishmentIdViewData:undefined}))
    })
}
export const ViewEstablishmentIdByIdAPI =async(body)=>{
    await axios
    .post('employee/viewSponsorById',body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(view_establishid_by_id({establishmentIdViewDataById:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(view_establishid_by_id({establishmentIdViewDataById:undefined}))
    })
}
export const AddEstablishmentIdAPI =async(body,setDialogueClose)=>{
    await axios
    .post('employee/addSponsor',body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(generate_establishId({addEstablishId:res.data}))
            setDialogueClose(false)
            ViewEstablishmentIdAPI()
        }
    })
    .catch((err)=>{
        store.dispatch(generate_establishId({addEstablishId:undefined}))
    })
}
export const UpdateEstablishmentIdByIdAPI =async(body,setDialogueClose)=>{
    await axios
    .put('employee/editSponsor',body,HEADERS)
    .then((res)=>{
        if(res.status===201){
            // store.dispatch(edit_establishment_id({updateEstablishmentId:res.data}))
            ViewEstablishmentIdAPI()
            setDialogueClose(false)
        }
    })
    .catch((err)=>{
        store.dispatch(edit_establishment_id({updateEstablishmentId:undefined}))
    })
}