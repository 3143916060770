import axios from "axios";
import store from "../../../../../Redux/store";
import {
  generate_updated_grn_name,
  get_grn_list_of_purchase_order,
  get_grn_list_row_data_list,
  get_updated_grn_list_api_res_list,
} from "./grnListingSlice";
import { HEADERS } from "../../../../../API/UrlAndPaths";

// list vendor bill apicall
export const viewGrnUpdatedListAPICall = async (body, setIsLoading) => {
  setIsLoading !== undefined && setIsLoading(true);
  await axios
    .post(`purchase/viewGoodsReceivedNote`, body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          get_updated_grn_list_api_res_list({ grnUpdatedResData: res.data })
        );
        setIsLoading !== undefined && setIsLoading(false);
      }
    })
    .catch((err) => {
      store.dispatch(
        get_updated_grn_list_api_res_list({ grnUpdatedResData: undefined })
      );
      setIsLoading !== undefined && setIsLoading(false);
    });
};
export const GRNFilterAPICall =(body,setIsLoading)=>{
  
  setIsLoading!==undefined&&setIsLoading(true)

  axios.post("purchase/searchGoodsReceivedNote",body,{
    headers: {
      'Content-Type': 'application/json',
    },
    
  })
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_updated_grn_list_api_res_list({grnUpdatedResData:res.data}))
      setIsLoading!==undefined&&setIsLoading(false)
    }
  })
  .catch((err)=>{
    store.dispatch(get_updated_grn_list_api_res_list({grnUpdatedResData:undefined}))
    setIsLoading!==undefined&&setIsLoading(false)
  })

}


// edit grn draft api

export const editUpdatedGRNDraftAPICall = async (
  body,
  setState,
  state,
  setShowPostBtn,
  setEditClicked,setLoadingForm
) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  await axios
    .put("purchase/editGoodsReceivedNote", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setState({
          ...state,
          message: "GRN Edited Successfully",
          success: true,
        });
        setShowPostBtn(true);
        setEditClicked(false);
        setLoadingForm!==undefined && setLoadingForm(false)
      }
    })
    .catch((err) => {

      if(err.response.status===500){
        setState({...state,message:"Internal Server error !",error:true})
      }else{
        setState({...state,message:err?.response?.data,error:true})
      }
      setLoadingForm!==undefined && setLoadingForm(false)
    });
};

//postUpdatedGRNAPICall

export const postUpdatedGRNAPICall = async (
  body,
  setState,
  state,
  postSuccessFn,
  setShowPostBtn,
  setShowEditBtn,setLoadingForm
) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  await axios
    .post("purchase/postGrn", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setShowPostBtn(false);
        setShowEditBtn(true);
        
        setState({
          ...state,
          message: "GRN Posted Successfully",
          success: true,
        });
        setLoadingForm!==undefined && setLoadingForm(false)
        postSuccessFn !== undefined && postSuccessFn(res?.data);
      }
    })
    .catch((err) => {
      setLoadingForm!==undefined && setLoadingForm(false)
      if(err.response.status===500){
        setState({...state,message:"Internal Server error !",error:true})
      }else{
        setState({...state,message:err?.response?.data,error:true})
      }
    });
};

// generate vendor bill name
export const generateGrnUpdatedNameAPICall = async (body) => {
  await axios
    .post(`purchase/genarateGRNName`, body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(generate_updated_grn_name({ grnUpdatedName: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(generate_updated_grn_name({ grnUpdatedName: undefined }));
    });
};
//grn single view
export const getGRNsingleViewAPICall = async (body) => {
  axios.post('purchase/viewGoodsReceivedNoteById',body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_grn_list_row_data_list({ grnListSingleRowData: res.data }));
    }
  })
  .catch((err)=>{
    store.dispatch(get_grn_list_row_data_list({singleRowData:undefined}))
})
}
//grn of purchaseOrder
export const getGRNpoViewAPICall = async (body,setIsLoading) => {
  setIsLoading !== undefined && setIsLoading(true);

  axios.post('purchase/viewAllGrnsofPurchaseOrder',body,HEADERS)
  .then((res)=>{
    if(res.status===200){
  setIsLoading !== undefined && setIsLoading(false);

      store.dispatch(get_grn_list_of_purchase_order({ grnPoViewList: res.data }));
    }
  })
  .catch((err)=>{
  setIsLoading !== undefined && setIsLoading(false);

    store.dispatch(get_grn_list_of_purchase_order({grnPoViewList:undefined}))
})
}
//post return product 
export const postReturnGRNAPICall = async (
  body,
  setSnackBarStates,
  setReturnProduct,
  id,setLoadingForm
  
) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  await axios
    .post("purchase/postGrnReturn", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        getGRNsingleViewAPICall({_id:id})
        setReturnProduct(false)
        setSnackBarStates((prev)=> ({
          ...prev,
          message: "Return product Posted Successfully",
          success: true,
        }));
        setLoadingForm!==undefined && setLoadingForm(false)
      }
    })
    .catch((err) => {


      if(err.response.status===500){
        setSnackBarStates((prev)=> ({
          ...prev,
          message:"Internal Server error !", error: true 
        }));
      }else{
        setSnackBarStates((prev)=> ({
          ...prev,
          message:err?.response?.data, error: true 
        }));
      }
      
      setLoadingForm!==undefined && setLoadingForm(false)
    });
};