import { createSlice } from "@reduxjs/toolkit";

export const marModelDataSlice=createSlice({
    name:"marModelDataSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        update_marModel:(state,action)=>{
            state.value=action.payload.marModel
        }
    }
})

export const {update_marModel}=marModelDataSlice.actions
export default marModelDataSlice.reducer