import axios from "axios";
import store from "../../../../Redux/store";
import { HEADERS, HEADER_QUERY } from "../../../UrlAndPaths";
import { update_all_staff_payroll_slice } from "../../../../Redux/Staff/Payroll/staffAllPayrollSlice";
import { update_single_payslip_list } from "../../../../Redux/Staff/Payroll/staffSinglePayslipSlice";
import { update_staff_payroll_voucher_no } from "../../../../Redux/Staff/Payroll/generatePayrollVoucherSlice";


// view payroll

export const viewEmployeePayrollAPICall= async(setIsLoading)=>{
    if(setIsLoading !== undefined) setIsLoading(true)
    await axios 
        .get("employee/viewPayroll",HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(
                    update_all_staff_payroll_slice({staffAllPayrollData:res.data})
                )
                setIsLoading(false)
            }
        })
        .catch((err)=>{
            store.dispatch(
                update_all_staff_payroll_slice({staffAllPayrollData:undefined})
            )
            setIsLoading(false)
            console.error(err.response)
        })
}

// view single payslip

export const viewSinglePayslipEmpAPICall= async(body)=>{
    await axios.post("employee/payrollViewById",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(
                    update_single_payslip_list({singlePayslipData:res.data})
                    )
            }
        })
        .catch((err)=>{
            store.dispatch(
                update_single_payslip_list({singlePayslipData:undefined})
                )
            console.error(err.response.status)
        })
}

// add payroll
export const staffAddPayrollAPICall=async(body,clearStates,
                                        setSuccessSnackOpen,
                                        setErrorSnackOpen,
                                        setSnackMsg)=>{
    await axios.post("employee/addPayroll",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            setSnackMsg("Payroll added")
            setSuccessSnackOpen(true)
            clearStates()
        }
    })
    .catch((err)=>{
        console.error(err.response);
        setSnackMsg("Something went bad wrong !!")
        setErrorSnackOpen(true)
    })
}

// generate payroll voucher no

export const generatePayrollVoucherAPICall=async(body)=>{
    await axios.post("employee/payrollNotranseGen",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(update_staff_payroll_voucher_no({
                    staffPayrollVoucherNo:res.data
                }))
            }
        })
        .catch((err)=>{
            store.dispatch(update_staff_payroll_voucher_no({
                staffPayrollVoucherNo:undefined
            }))
            console.error(err.response);
        })
    
}

// edit single payroll

export const editSinglePayrollAPICall=async(body,closeDialog,updateListener,setSuccessSnackOpen,
                                            setErrorSnackOpen,setSnackMsg)=>{
    await axios.put("employee/editPayroll",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                console.log("edit success");
                closeDialog(false)
                updateListener()
                setSnackMsg("Edit succesfull")
                setSuccessSnackOpen(true)
            }
        })
        .catch((err)=>{
            console.error(err.respones.status)
            setSnackMsg("something went wrong!!")
            setErrorSnackOpen(true)
        })
}

// delete single payroll

export const deleteSinglePayrollAPICall=async(body,updateListener,setSuccessSnackOpen,
                                            setErrorSnackOpen,setSnackMsg)=>{
    await axios.delete("employee/deletePayroll",{headers:HEADER_QUERY,data:body})
      .then((res)=>{
        if(res.status===200){
            updateListener()
            setSnackMsg("Deleted")
            setSuccessSnackOpen(true)
        }
      })
      .catch((err)=>{
        console.error(err.res.status);
            setSnackMsg("something went wrong!!")
            setErrorSnackOpen(true)
      })
}