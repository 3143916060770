import { createSlice } from "@reduxjs/toolkit";
export const penSlice = createSlice({
  name: "penSlice",
  initialState: {
    value: undefined,
  },
  reducers: {
    update_pen: (state, action) => {
      state.value = action.payload.pen;
    }
  },
});
export const { update_pen } = penSlice.actions;
export default penSlice.reducer;
