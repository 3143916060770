import { createSlice } from "@reduxjs/toolkit";

export const BranchTransferSlice = createSlice({
  name: "branchTransferSlice",
  initialState: {
    value: undefined,
    singleValue:undefined,
    transNo:undefined,
    productListData:undefined,
  
  },
  reducers: {
    get_branch_transfer_list: (state, action) => {
      state.value = action.payload.branchTransferData;
    },
    get_branch_transfer_single_view:(state,action)=>{
      state.singleValue=action.payload.singleBranchTransferData
    },
    get_branch_transfer_transNo:(state,action)=>{
      state.transNo=action.payload.branchTransferTransNo
    },
    get_branch_transfer_product_list:(state,action)=>{
      state.productListData = action.payload
    },
  
    
  
  },
});

export const {get_branch_transfer_product_list,get_branch_transfer_list,get_branch_transfer_single_view,get_branch_transfer_transNo,} = BranchTransferSlice.actions;

export default BranchTransferSlice.reducer;