import axios from "axios";
import { HEADERS } from "../../../../API/UrlAndPaths";
import store from "../../../../Redux/store";
import { setStockMove, setStockMoveSigleView, set_Stock_Move_Aging_View } from "./ReportSlice";
import { setSubmitLoading, setTableLoadingGlobal } from "../../../../Redux/Dashboard/mainDashBoardSlice";

export const StockMoveAPIcall=async(body,setIsLoading)=>{
    setIsLoading!==undefined&&setIsLoading(true)
    store.dispatch(setTableLoadingGlobal(true))
    await axios
    .post("inventory/stockMoveReport",body, HEADERS)
    .then((res)=>{
        store.dispatch(setTableLoadingGlobal(false))
        if(res.status===200){
            store.dispatch(setStockMove({stockMoveList:res.data}))
            setIsLoading!==undefined&&setIsLoading(false)
        }
    })
    .catch((err)=>{
        store.dispatch(setTableLoadingGlobal(false))
        store.dispatch(setStockMove({stockMoveList:undefined}))
        setIsLoading!==undefined&&setIsLoading(false)
        console.error(err.response);
    })
}

export const StockMoveSingleAPIcall=async(body)=>{
    store.dispatch(setSubmitLoading(true))
    await axios
    .post("inventory/stockMoveReportSingleView",body, HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(setSubmitLoading(false))
            store.dispatch(setStockMoveSigleView({stockMoveSingleList:res.data}))
            store.dispatch(setTableLoadingGlobal(false))
            
        }
    })
    .catch((err)=>{
        store.dispatch(setTableLoadingGlobal(false))
        store.dispatch(setSubmitLoading(false))
        store.dispatch( setStockMoveSigleView({stockMoveSingleList:[]}))
    })
}

export const StockMoveAgingReportAPIcall=async(body)=>{
    await axios
    .post("inventory/stockAgingReport",body, HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(set_Stock_Move_Aging_View({StockMoveAgingReportList:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(set_Stock_Move_Aging_View({StockMoveAgingReportList:undefined}))
        console.error(err.response);
    })
}