import { IconButton, Skeleton } from "@mui/material";
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

const renderData = [
  {
    stockName: "pants",
    balanceQuantity: 200,
  },
  {
    stockName: "shirt",
    balanceQuantity: 150,
  },
  {
    stockName: "shorts",
    balanceQuantity: 20,
  },
];
const StockitemList = ({ selectListItem }) => {
  const componentRef = useRef();
  const isLoading = false;
  return (
    <div id="currentAsset-report">
      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="accounting-voucher-menu-sections"
      >
       <h3 style={{ margin: "2% 1%" }}>{selectListItem}</h3>

        <div className="action-btn-container">
          <div>
            <ReactToPrint
              trigger={() => (
                <IconButton sx={{ color: "blue" }}>
                  <i className="bi bi-printer "></i>
                </IconButton>
              )}
              content={() => componentRef.current}
            />
            <IconButton className="download-btn">
              <ArrowCircleDownIcon sx={{ fontSize: 30 }} />{" "}
            </IconButton>
          </div>
        </div>
        <div className="table-container">
          <div className="global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>SL No</th>
                  <th>Stock Name</th>
                  <th>Balance Quantity</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  [...Array(3)].map((_, index) => (
                    <tr key={index}>
                      <td colSpan={7}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                  ))
                  
                ) : renderData.length !== 0 ? (
                  renderData?.reverse()?.map((r, i) => (
                    <React.Fragment key={i}>
                      <tr className="data-table-row-head">
                        <td>{i + 1}</td>
                        <td>{r?.stockName}</td>
                        <td>{r?.balanceQuantity}</td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>No Records</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* print preview   */}
      <div style={{ display: "none" }}>
        <div ref={componentRef} id="print-view">
          <div className="print-head-container">
            <div className="brand-name">
              <h2>Leeyet Textile</h2>
            </div>
            <div className="select-table-head">
              <h3>{selectListItem}</h3>
            </div>
            <div className="duration-date">
              <h3>{"01/04/2023"}</h3>
              <strong>TO</strong>
              <h3>{"01/12/2023"}</h3>
            </div>
          </div>
          <div className="print-table-container">
            <div className="accounting-voucher-menu-sections">
              <div className="global-table-container">
                <table className="global-table">
                  <thead>
                    <tr>
                      <th>SL No</th>
                      <th>Stock Name</th>
                      <th>Balance Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      [...Array(3)].map((_, index) => (
                        <tr key={index}>
                          <td colSpan={7}>
                            <Skeleton
                              variant="rectangular"
                              animation="wave"
                              width={"100%"}
                              height={40}
                            />
                          </td>
                        </tr>
                      ))
                    ) : renderData.length !== 0 ? (
                      renderData?.reverse()?.map((r, i) => (
                        <React.Fragment key={i}>
                          <tr className="data-table-row-head">
                            <td>{i + 1}</td>
                            <td>{r?.stockName}</td>
                            <td>{r?.balanceQuantity}</td>
                          </tr>
                        </React.Fragment>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={7}>No Records</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockitemList;
