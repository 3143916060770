import axios from "axios"
import { get_orderNo_List } from "../../Redux/Orders/WorkOrder/orderNoListSlice"
import store from "../../Redux/store"
import { HEADERS } from "../UrlAndPaths"

export const orderNoListAPICall= async (body)=>{
  await axios.post("workorder/getOrderNosCommissionReport",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
        store.dispatch(get_orderNo_List({orderNoList:res.data}))
    }
  })
  .catch((err)=>{
    store.dispatch(get_orderNo_List({orderNoList:undefined}))
    console.log(err.response);
  })
}