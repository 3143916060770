import axios from "axios";
import store from "../../../Redux/store";
import { HEADERS } from "../../../API/UrlAndPaths";
import { setTemplate } from "./SettingsSlice";
import { getTemplateAPI } from "../SalesNew/SalesOrders/SalesQuotation/salesQuatationAPI";

export const templateSetAPI=async(body)=>{
    await axios
    .post("admin/setTemplateModel",body, HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(setTemplate({templatedSetting:res.data}))
            getTemplateAPI();
        }
    })
    .catch((err)=>{
        store.dispatch(setTemplate({templatedSetting:undefined}))
        console.error(err.response);
    })
}
