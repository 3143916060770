import { CircularProgress, Modal } from "@mui/material";
import React from "react";

export const LoadingForm=(props)=>{

    const {loading}=props;
    return(
        <>
            <Modal
                open={loading}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <CircularProgress size={100} color="primary" sx={{outline:"none"}}/>
            </Modal>
        </>
    )
}