import React from "react";
import {
  Autocomplete,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { today } from "../../../../../Js/Date";
import { useSelector } from "react-redux";
import Banner from "../../../../../Assets/Images/banner-_2_.png";
import draftRibbon from "../../../../../Assets/statusRibbon/draft.png";
import logoImage from "../../../../../Assets/Images/Icon ionic-logo-buffer.png";
import { useRef } from "react";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
import SuccessSnackbar from "../../../../Single Components/SnackBars/SuccessSnackbar";
import { useEffect } from "react";
import { viewAllAllowedBranchesAPICall } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import {
  createDraftLC,
  createDraftLCApi,
  editLcAPI,
  getLandedCostName,
  listServiceProductsApi,
  singleViewLdByIdApi,
} from "./LandedCostAPI";
import { viewVendorListAPICall } from "../../../Accounts/Vendor/API/vendorListAPICall";
import { viewGrnUpdatedListAPICall } from "../../../Purchase Manager/Pages/GRNUpdated/grnListingAPI";
import { journalListApiCall } from "../../../../../API/AccountUpdated/journalAPI";
import { getAllNewProductList } from "../../../Accounts/Vendor/API/vendorBillAPI";
import { viewGlConfigAPICall } from "../../../Accounts/Configuration/glConfigAPI";
import { chartOfAccountListApiCall } from "../../../../../API/AccountUpdated/chartOfAccountAPI";

const CreateLandedCost = () => {
  const containerRef = useRef(null);
  //local storage
  let loginResponse = JSON.parse(localStorage.getItem("login_meta"));
  let singleViewId = localStorage.getItem("singleViewById");
  //navigate
  let navigate = useNavigate();

  //redux store
  // ===============
  const { landedCostSequence, singleViewData, serviceProductList } =
    useSelector((state) => state.landedCostSlice);
  // ===============
  const allowedBranchList = useSelector(
    (state) => state.allAllowedBranchesSlice.value
  );
  const vendorList = useSelector((state) => state.vendorsListSlice.apiResList);
  const goodsReceiveNoteList = useSelector(
    (state) => state.goodsReceivedNoteUpdatedSlice.apiResList
  );
  const journalList = useSelector((state) => state.journalSlice.value);
  const configurationList = useSelector(
    (state) => state.glConfigurationSlice.apiResList
  );
  const chartOfAccountList = useSelector(
    (state) => state.chartOfAccountsSlice.value
  );

  //state
  const addRowData = [{ value: "Add Line" }];
  const [isLoading, setIsLoading] = useState(true);
  const [validationAlert, setValidationAlert] = useState({});
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("landed cost added!!");
  const [editClicked, setEditClicked] = useState(false);
  const [addRowInput, setAddRowInput] = useState(addRowData[1]);
  const [isActiveTable, setIsActiveTable] = useState("extraCharge");
  const [logView, setLogView] = useState(false);
  const [accountDetails, setAccountDetails] = useState();
  const [addRowScroll,setAddRowScroll]=useState(false)
  const log = [
    { name: "munshid", type: "rfq", date: "27-10-2023 15:48" },
    { name: "munshid", type: "rfq", date: "27-10-2023 15:48" },
    { name: "munshid", type: "rfq", date: "27-10-2023 15:48" },
    { name: "munshid", type: "rfq", date: "27-10-2023 15:48" },
    { name: "munshid", type: "rfq", date: "27-10-2023 15:48" },
    { name: "munshid", type: "rfq", date: "27-10-2023 15:48" },
    { name: "munshid", type: "rfq", date: "27-10-2023 15:48" },
    { name: "munshid", type: "rfq", date: "27-10-2023 15:48" },
  ];
  const landedCostInitialState = {
    branch: null,
    date: today,
    vendor: null,
    grn: null,
    isInventory: true,
    journal: null,
    status: "",
    name: "###",
    isEdit: false,
  };
  const [landedCostForm, setLandedCostForm] = useState(landedCostInitialState);

  useEffect(() => {
    if (landedCostSequence) {
      setLandedCostForm({ ...landedCostForm, name: landedCostSequence });
    }
  }, [landedCostSequence]);

  useEffect(() => {
    if (singleViewId) {
      singleViewLdByIdApi({ id: singleViewId });
    }
  }, []);

  //line table
  const [tableData, setTableData] = useState([
    {
      type: "lines",
      id: Date.now(),
      product: null,
      description: "",
      account: null,
      cost: "",
    },
  ]);

  //initial api calls
  useEffect(() => {
    viewAllAllowedBranchesAPICall({ employeeId: loginResponse?.data?._id });
    viewVendorListAPICall();
    journalListApiCall();
    viewGlConfigAPICall();
    chartOfAccountListApiCall();
    listServiceProductsApi();

    // serviceProductList
  }, []);

  useEffect(() => {
    if (singleViewData) {
      let setFormData = {
        name: singleViewData?.name,
        branch: { storeCode: singleViewData?.branchId },
        date: singleViewData?.date,
        vendor: { _id: singleViewData?.vendorId, name: singleViewData?.vendor },
        grn: { _id: singleViewData?.grnId, name: singleViewData?.grn },
        isInventory: singleViewData?.isInventory,
        journal: {
          _id: singleViewData?.journalId,
          journalName: singleViewData?.journal,
        },
        status: singleViewData?.status,
        isEdit: true,
        id: singleViewData?._id,
      };
      setLandedCostForm(setFormData);
      setTableData(singleViewData.extraCharge);
    }
  }, [singleViewData]);

  // generate name
  useEffect(() => {
    if (
      landedCostForm.branch &&
      landedCostForm.date &&
      landedCostForm.status === ""
    ) {
      getLandedCostName({
        branchId: landedCostForm.branch.storeCode,
        date: landedCostForm.date,
      });
    }
  }, [landedCostForm.branch, landedCostForm.date]);
  //get grn list
  useEffect(() => {
    if (landedCostForm.branch) {
      viewGrnUpdatedListAPICall({ branchId: landedCostForm.branch.storeCode });
    }
  }, [landedCostForm.branch]);

  // get account
  useEffect(() => {
    if (chartOfAccountList && configurationList) {
      let findAccount;
      if (landedCostForm.isInventory) {
        let account = configurationList.inventory.stockValuationAccount;
        findAccount = chartOfAccountList.filter((res) => res._id === account);
      } else {
        findAccount = findAccount = chartOfAccountList.filter(
          (res) => res.type === "Expenses" || res.type === "Cost of revenue"
        );
      }
      //find account
      // console.log("findAccount--------------------->", findAccount);
      setAccountDetails(findAccount);
    }
  }, [landedCostForm.isInventory, chartOfAccountList, configurationList]);

  //function
  const backButtonFn = () => {
    if (landedCostForm?.isEdit === false) {
      const userConfirmed = window.confirm("Changes you made may not be saved.");

      if (userConfirmed) {
        navigate("/userdashboard/inventory/operation/landedcost");
        localStorage.removeItem("singleViewById");

      }
    }
    else {
      navigate("/userdashboard/inventory/operation/landedcost");
      localStorage.removeItem("singleViewById");
    }
    
  };

  //tab switching
  const productMenuClick = () => {
    document.getElementById("productMenuBtn").classList.add("active");
    document.getElementById("departmentMenuBtn").classList.remove("active");
    setIsActiveTable("extraCharge");
  };

  const departmentMenuClick = () => {
    document.getElementById("productMenuBtn").classList.remove("active");
    document.getElementById("departmentMenuBtn").classList.add("active");
    setIsActiveTable("valuation");
  };

  //handle field change
  const handleFieldChange = (value, key) => {
    console.log("value", value, "key:", key);
    setLandedCostForm((pre) => ({ ...pre, [key]: value }));
  };

  useEffect(() => {
    if (isActiveTable === "valuation") {
      if (!landedCostForm.isInventory) {
        document.getElementById("productMenuBtn").classList.add("active");
        setIsActiveTable("extraCharge");
      }
    }
  }, [landedCostForm]);

  //handle table change
  const handleTableInputChange = (id, field) => (e, newValue) => {
    const { value } = e.target;
    const updatedData = tableData.map((row) => {
      if (row.id === id) {
        if (value == 0 || value == undefined) {
          return {
            ...row,
            [field]: newValue ? newValue : null,
          };
        } else {
          return {
            ...row,
            [field]: value,
          };
        }
      }
      return row;
    });
    setTableData(updatedData);
  };

  // table
  const addRow = () => {
    if (addRowInput?.value === "Add Header") {
      setTableData([
        ...tableData,
        { type: "header", id: Date.now(), HeaderValue: "" },
      ]);
    } else if (addRowInput?.value === "Add Line") {
      setTableData([
        ...tableData,
        {
          type: "lines",
          id: Date.now(),
          product: null,
          des: "",
          account: null,
          cost: "",
        },
      ]);
    }
    setAddRowScroll(!addRowScroll)
  };
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [addRowScroll]);

  //save
  const saveLandedCostFunc = () => {
    if (landedCostForm?.branch === null) {
      setValidationAlert({ ...validationAlert, branch: "fill this field" });
    } else if (landedCostForm?.date === null) {
      setValidationAlert({ ...validationAlert, wareHouse: "fill this field" });
    } else if (landedCostForm?.grn === null) {
      setValidationAlert({ ...validationAlert, sLocation: "fill this field" });
    } else if (landedCostForm?.vendor === null) {
      setValidationAlert({ ...validationAlert, dLocation: "fill this field" });
    } else if (landedCostForm?.journal === null) {
      setValidationAlert({ ...validationAlert, dLocation: "fill this field" });
    } else {
      console.log("main data:", landedCostForm);
      // console.log("tableData :", tableData);

      let sendData = {
        branchId: landedCostForm.branch.storeCode,
        date: landedCostForm.date,
        vendorId: landedCostForm.vendor._id,
        grnId: landedCostForm.grn._id,
        journalId: landedCostForm.journal._id,
        isInventory: landedCostForm.isInventory,
        postDate: today,
        extraCharge: tableData,
        id: landedCostForm.id,
      };
      // console.log("sendData",sendData)
      createDraftLCApi(sendData, setLandedCostForm, setSuccessSnackOpen);
    }
  };

  const handleDelete = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };
  //edit btn click
  const editBtnEnableFn = () => {
    setLandedCostForm({ ...landedCostForm, isEdit: false });
    setEditClicked(true);
  };
  //edit save
  const editSaveFn = () => {
    console.log("edit save function called ");
    if (landedCostForm?.branch === null) {
      setValidationAlert({ ...validationAlert, branch: "fill this field" });
    } else if (landedCostForm?.date === null) {
      setValidationAlert({ ...validationAlert, wareHouse: "fill this field" });
    } else if (landedCostForm?.grn === null) {
      setValidationAlert({ ...validationAlert, sLocation: "fill this field" });
    } else if (landedCostForm?.vendor === null) {
      setValidationAlert({ ...validationAlert, dLocation: "fill this field" });
    } else if (landedCostForm?.journal === null) {
      setValidationAlert({ ...validationAlert, dLocation: "fill this field" });
    } else {
      let sendData = {
        branchId: landedCostForm.branch.storeCode,
        date: landedCostForm.date,
        vendorId: landedCostForm.vendor._id,
        grnId: landedCostForm.grn._id,
        journalId: landedCostForm.journal._id,
        isInventory: landedCostForm.isInventory,
        postDate: today,
        extraCharge: tableData,
        id: landedCostForm.id,
      };
      editLcAPI(sendData);
    }

    setEditClicked(false);
    setLandedCostForm({ ...landedCostForm, isEdit: true });
  };

  //post
  const postLandedCost = () => {
    // console.log("serviceProductList,", serviceProductList);
    console.log("tableData =========>1", landedCostForm);

    // let editFn=()=>{
    //   setlandedCostForm({...landedCostForm,status:"Completed"})
    // }
    // if(singleView!==undefined){
    //   postLandedCostAPICall({_id:singleView?._id},
    //     setSuccessSnackOpen,
    //     setErrorSnackOpen,
    //     setSnackMsg,
    //     editFn)
    // }
  };

  //   ============================END================================================

  //log**********
  const clickLogView = () => {
    setLogView(!logView);
  };

  // snackbar ********
  const closeSuccessSnackbar = () => {
    setSuccessSnackOpen(false);
  };

  const closeErrorSnackbar = () => {
    setErrorSnackOpen(false);
  };


  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const shouldShowAlert = !landedCostForm?.isEdit;
      if (shouldShowAlert) {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [landedCostForm?.isEdit]);
  return (
    <div className="global-page-parent-container">
      {/* input field */}
      <div
        className="new-global-white-bg-container"
        style={{ padding: "0 2%" }}
      >
        <div className="new-global-white-bg-icon-container">
          <div className="new-global-white-bg-icon-container-left">
            <Tooltip title="Back" onClick={backButtonFn}>
              <IconButton>
                <i class="bi bi-chevron-left"></i>
              </IconButton>
            </Tooltip>
            {/* edit */}
            {landedCostForm.status === "DRAFT" && (
              <Tooltip title="Edit">
                <IconButton
                  onClick={
                    landedCostForm?.isEdit === true
                      ? editBtnEnableFn
                      : editSaveFn
                  }
                >
                  {landedCostForm?.isEdit === true ? (
                    <i class="bi bi-pencil-square edit-icon1"></i>
                  ) : (
                    <i
                      class="bi bi-check2-square edit-icon"
                      style={{ fontSize: "1.5rem" }}
                    ></i>
                  )}
                </IconButton>
              </Tooltip>
            )}
            {/* save */}

            {landedCostForm.status === "" && (
              <Tooltip title="Save" onClick={saveLandedCostFunc}>
                <IconButton>
                  <i class="bi bi-floppy save-icon"></i>
                </IconButton>
              </Tooltip>
            )}
            {/* post */}

            {landedCostForm.status === "DRAFT" && !editClicked && (
              <button onClick={postLandedCost} className="btn btn-post">
                Confirm
              </button>
            )}
          </div>

          <div className="status-container">
            <img
              src={
                landedCostForm.status === ""
                  ? Banner
                  : landedCostForm.status === "DRAFT"
                  ? draftRibbon
                  : landedCostForm.status === "POST"
                  ? ""
                  : ""
              }
              alt=""
            />
          </div>
        </div>
        <hr className="global-hr" />
        {/* transaction number  */}
        <h4 className="global-name">
          {landedCostForm.name ? landedCostForm.name : "###"}
        </h4>
        <div className="new-global-input-container">
          <div className="new-global-single-input auto-complete-new">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={allowedBranchList || []}
              sx={{ width: 300 }}
              getOptionLabel={(option) =>
                `${option?.storeCode} - ${option?.branchName}`
              }
              renderInput={(params) => (
                <TextField {...params} label="Branch*" focused />
              )}
              value={landedCostForm?.branch}
              onChange={(e, v) => handleFieldChange(v, "branch")}
              disabled={landedCostForm?.isEdit}
            />
            <p className="doc-validation-alert">{validationAlert?.branch}</p>
          </div>
          <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="Date*"
              variant="outlined"
              type="date"
              focused
              value={landedCostForm?.date}
              onChange={(e) => handleFieldChange(e.target.value, "date")}
              disabled={landedCostForm?.isEdit}
            />
          </div>
          <div className="new-global-single-input auto-complete-new">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={vendorList || []}
              sx={{ width: 300 }}
              getOptionLabel={(option) => `${option?.name}`}
              renderInput={(params) => (
                <TextField {...params} label="Vendor*" focused />
              )}
              value={landedCostForm?.vendor}
              onChange={(e, v) => handleFieldChange(v, "vendor")}
              disabled={landedCostForm?.isEdit}
            />
            <p className="doc-validation-alert">{validationAlert?.branch}</p>
          </div>
          <div className="new-global-single-input auto-complete-new">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={goodsReceiveNoteList || []}
              sx={{ width: 300 }}
              getOptionLabel={(option) => `${option?.name}`}
              renderInput={(params) => (
                <TextField {...params} label="GRN*" focused />
              )}
              value={landedCostForm?.grn}
              onChange={(e, v) => handleFieldChange(v, "grn")}
              disabled={landedCostForm?.isEdit}
            />
            <p className="doc-validation-alert">{validationAlert?.branch}</p>
          </div>
          {
            <>
              <div className="global-single-input" style={{ margin: "8px" }}>
                <p>Apply On</p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="radio"
                      name="applyOn"
                      defaultChecked
                      id="inventory"
                      checked={landedCostForm?.isInventory == true}
                      style={{ accentColor: "#000", cursor: "pointer" }}
                      onChange={() => handleFieldChange(true, "isInventory")}
                      disabled={landedCostForm?.isEdit}
                    />
                    <label
                      htmlFor="inventory"
                      style={{
                        fontSize: "0.9rem",
                        marginTop: "4px",
                        cursor: "pointer",
                      }}
                    >
                      Inventory
                    </label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="radio"
                      name="applyOn"
                      id="expense"
                      checked={landedCostForm?.isInventory == false}
                      style={{ accentColor: "#000", cursor: "pointer" }}
                      onChange={() => handleFieldChange(false, "isInventory")}
                      disabled={landedCostForm?.isEdit}
                    />
                    <label
                      htmlFor="expense"
                      style={{
                        fontSize: "0.9rem",
                        marginTop: "4px",
                        cursor: "pointer",
                      }}
                    >
                      Expense
                    </label>
                  </div>
                </div>
              </div>
            </>
          }
          <div className="new-global-single-input auto-complete-new">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={journalList || []}
              sx={{ width: 300 }}
              getOptionLabel={(option) => `${option?.journalName}`}
              renderInput={(params) => (
                <TextField {...params} label="Journal*" focused />
              )}
              value={landedCostForm?.journal}
              onChange={(e, v) => handleFieldChange(v, "journal")}
              disabled={landedCostForm?.isEdit}
            />
            <p className="doc-validation-alert">{validationAlert?.branch}</p>
          </div>
        </div>
      </div>
      {/* table section  */}
      <div className="new-global-white-bg-container">
        <div
          className="rewards-add-rewards-ctn-top-menu accounts-table-toggle"
          style={{ marginBottom: "1%" }}
        >
          <div
            className="tab-menu-button active"
            id="productMenuBtn"
            onClick={productMenuClick}
          >
            <Typography fontSize={"0.9rem"} fontWeight={600}>
              Extra Charge
            </Typography>
          </div>
          {landedCostForm.isInventory && (
            <div
              className="tab-menu-button"
              id="departmentMenuBtn"
              onClick={departmentMenuClick}
            >
              <Typography fontSize={"0.9rem"} fontWeight={600}>
                Valuation
              </Typography>
            </div>
          )}
        </div>
        {isActiveTable === "extraCharge" ? (
          <>
            <div className="global-product-table">
              <table>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Description</th>
                    <th>Account</th>
                    <th>Cost</th>
                    <th style={{ backgroundColor: "#fff" }}></th>
                  </tr>
                </thead>

                <tbody>
                  {tableData?.map((item, index) => (
                    <tr
                      key={index}
                      ref={index === tableData.length - 1 ? containerRef : null}
                    >
                      <>
                        <td style={{ minWidth: "200px" }}>
                          <div className="product-table-auto-complete">
                            <Autocomplete
                              options={serviceProductList || []}
                              style={{ maxHeight: "100px" }}
                              getOptionLabel={(option) => option?.name}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              value={item.product}
                              onChange={handleTableInputChange(
                                item.id,
                                "product"
                              )}
                              disabled={landedCostForm?.isEdit}
                            />
                          </div>
                        </td>
                        <td>
                          <input
                            type="text"
                            value={item.description}
                            onChange={handleTableInputChange(
                              item.id,
                              "description"
                            )}
                            disabled={landedCostForm?.isEdit}
                          />
                        </td>

                        <td style={{ minWidth: "200px" }}>
                          <div className="product-table-auto-complete">
                            <Autocomplete
                              options={accountDetails || []}
                              style={{ maxHeight: "100px" }}
                              getOptionLabel={(option) => option?.accountName}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              value={item.account}
                              onChange={handleTableInputChange(
                                item.id,
                                "account"
                              )}
                              disabled={landedCostForm?.isEdit}
                            />
                          </div>
                        </td>

                        <td>
                          <input
                            type="text"
                            value={item.cost}
                            onChange={handleTableInputChange(item.id, "cost")}
                            disabled={landedCostForm?.isEdit}
                          />
                        </td>
                        <td style={{ border: "none", width: "4%" }}>
                          <IconButton
                            onClick={() => handleDelete(index)}
                            style={{ height: "30px" }}
                            disabled={landedCostForm?.isEdit}
                          >
                            <i
                              className="bi bi-trash3"
                              style={{ color: "#db0000", fontSize: "1.3rem" }}
                            ></i>
                          </IconButton>
                        </td>
                      </>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="add-row-btn-container">
              <div
                className="global-single-input auto-complete add-line-autocomplete"
                style={{ width: "76%" }}
              >
                <Autocomplete
                  options={addRowData || []}
                  getOptionLabel={(option) => option?.value}
                  renderInput={(params) => <TextField {...params} />}
                  value={addRowInput}
                  onChange={(e, newValue) => setAddRowInput(newValue)}
                  renderClear={() => null}
                  disabled={landedCostForm?.isEdit}
                />
              </div>
              <button
                onClick={() => addRow()}
                className="add-row-btn"
                disabled={landedCostForm?.isEdit}
              >
                +
              </button>
            </div>
          </>
        ) : (
          <div className="global-product-table">
            <table>
              <thead>
                <tr>
                  <th>Cost Line</th>
                  <th>Product</th>
                  <th>Quantity</th>
                  <th>Original Value</th>
                  <th>New Value</th>
                  <th>Additional Land Cost</th>
                </tr>
              </thead>

              <tbody>
                {tableData?.map((item, index) => (
                  <tr
                    key={index}
                    ref={index === tableData.length - 1 ? containerRef : null}
                  >
                    <td>
                      <input
                        type="text"
                        value={item.des}
                        onChange={handleTableInputChange(item.id, "des")}
                        disabled={landedCostForm?.isEdit}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={item.des}
                        onChange={handleTableInputChange(item.id, "des")}
                        disabled={landedCostForm?.isEdit}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={item.des}
                        onChange={handleTableInputChange(item.id, "des")}
                        disabled={landedCostForm?.isEdit}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={item.des}
                        onChange={handleTableInputChange(item.id, "des")}
                        disabled={landedCostForm?.isEdit}
                      />
                    </td>
                    <td>
                      <input type="text" value={item.uom} disabled />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={item.transferQty}
                        onChange={handleTableInputChange(
                          item.id,
                          "transferQty"
                        )}
                        disabled={landedCostForm?.isEdit}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div
        className="new-global-white-bg-container new-log-container"
        style={{ padding: "4px" }}
      >
        <div
          style={{ padding: "8px", cursor: "pointer" }}
          onClick={clickLogView}
        ></div>
        <div
          className={logView ? "pointer-up" : "pointer"}
          onClick={clickLogView}
          style={{ cursor: "pointer" }}
        ></div>
        {logView && (
          <div className="single-log-container">
            {log?.map((r, i) => (
              <div className="single-log-item">
                <div className="single-log-item-line-image">
                  <hr className="single-log-item-line" />
                  <div className="single-log-item-image">
                    <img src={logoImage} alt="" />
                  </div>
                  <hr className="single-log-item-line" />
                </div>
                <p className="heading">{r?.type}</p>
                <p className="name">{r?.name}</p>
                <p className="date-time">{r?.date}</p>
              </div>
            ))}
          </div>
        )}

        {/* <div className='single-log-container-scroll-line'></div> */}
      </div>

      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />

      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </div>
  );
};

export default CreateLandedCost;
