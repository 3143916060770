import { createSlice } from "@reduxjs/toolkit";

export const vendorPaymentSlice = createSlice({
  name: "vendorPaymentSlice",
  initialState: {
    apiResList: undefined,
    rowData:undefined,
    generateName:undefined,
    vendorPayment:undefined,
    vendorBills:[]
  },
  reducers: {
    get_vendor_payment_api_res_list: (state, action) => {
      state.apiResList = action.payload.vendorPaymentResData;
    },
    get_vendor_payment_row_data_list: (state, action) => {
      state.rowData = action.payload.vendorPaymentSingleRowData;
    },
    generate_vendor_payment_name: (state, action) => {
      state.generateName = action.payload.vendorPaymentName;
    },
    set_vendor_bills: (state, action) => {
      state.vendorBills = action.payload;
    },
    vendor_payment_single_view:(state,action)=>{
      state.vendorPayment =action.payload.paymentSingleView
    }
  },
});

export const {set_vendor_bills, get_vendor_payment_api_res_list,get_vendor_payment_row_data_list,generate_vendor_payment_name,vendor_payment_single_view} = vendorPaymentSlice.actions;

export default vendorPaymentSlice.reducer;                                        