import { createSlice } from "@reduxjs/toolkit";

export const bankOverDraftSlice = createSlice({
  name: "bankOverDraftSlice",
  initialState: {
    accountHolderName  : "",
    accountNo: "",
    ifscCode: "",
    overDraftLimit: "",
    region: "",
    street: "",
    city: "",
    branch: "",
    country: "",
    openingBalance:''
  },

  reducers: {
    setAccountHolderName: (state, action) => {
      state.accountHolderName = action.payload;
    },
    setAccountNo: (state, action) => {
      state.accountNo = action.payload;
    },
    setIfscCode: (state, action) => {
      state.ifscCode = action.payload;
    },
    setOverDraftLimit: (state, action) => {
      state.overDraftLimit = action.payload;
    },
    setRegion: (state, action) => {
      state.region = action.payload;
    },
    setStreet: (state, action) => {
      state.street = action.payload;
    },
    setCity: (state, action) => {
      state.city = action.payload;
    },
    setBranch: (state, action) => {
      state.branch = action.payload;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
    setOpeningBalance: (state, action) => {
      state.openingBalance = action.payload;
    },
  },
});

export const {setAccountHolderName,setAccountNo,setBranch,setCity,setCountry,setIfscCode,setOpeningBalance,setOverDraftLimit,setRegion,setStreet} = bankOverDraftSlice.actions;
export default bankOverDraftSlice.reducer;
