import { createSlice } from "@reduxjs/toolkit";

export const longTermInvestmentSlice = createSlice({
  name: "longTermInvestmentSlice",
  initialState: {
    openingBalance:'',
  },
  reducers: {
    setOpeningBalance: (state, action) => {
      state.openingBalance = action.payload;
    },
  },
});

export const {setOpeningBalance} = longTermInvestmentSlice.actions;
export default longTermInvestmentSlice.reducer;
