import axios from "axios"
import { HEADERS } from "../../../UrlAndPaths"


export const forgotPasswordSettingsAPICall=(body,setState,state)=>{
    axios.put("admin/updatepasswordsettings",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            setState({...state,message:"Success",success:true})

        }
    })
    .catch((err)=>{
        if (err.response.status === 500) {
            setState({ ...state, message: "Internal Server error !", error: true })
          } else {
            setState({ ...state, message: err?.response?.data, error: true })
          }
    })
}


// view forgott password settings
export const viewForgottPasswordSettings=()=>{
    
}