import React,{useState,useEffect,useRef} from 'react'
import {IconButton,Dialog,Autocomplete,TextField,Skeleton, createFilterOptions} from  '@mui/material'
import '../../../css/Sales/quotation.css'
import {viewAllCustomersAPICall} from '../../../API/Customer/customerAPI'
import {useSelector} from 'react-redux'
import {convertDateFormat, today} from '../../../Js/Date'
import {viewAllProductsAPICall} from '../../../API/Product List/productListAPI'
import {
  viewQuotationAPICall,
  addQuotationAPICall,
  quotationIdListAPICall
} from '../../../API/Sales/QuotataionAPI'
import {generateQuotationIdAPICall} from '../../../API/Sales/QuotataionAPI'
import SuccessSnackbar from '../../Single Components/SnackBars/SuccessSnackbar'
import ErrorSnackbar from '../../Single Components/SnackBars/ErrorSnackbar'
import ReactToPrint from "react-to-print"
import { viewAllBranchesAPICall } from '../../../API/Settings/Company Settings/companySettingsAPI'
import { ForkRight } from '@mui/icons-material'

export const QuotationOrEstimate = () => {
  let quotationPrint=useRef()
// login response
const loginResponse = useSelector((state) => state.loginResponseSlice.value);
// role based rendering
const userRole = useSelector((state) => state.userRoleSlice.value);
// view quotation
const quotation = useSelector((state) => state.viewQuotation.value)
//List of All Customers
const customerList = useSelector((state) => state.allCustomerListSlice.value);

const branchList=useSelector((state)=>state.allBranchesSlice.value)
//All products list
const allProductsList = useSelector(
  (state) => state.allProductsListSlice.value
);
// generate quotation id
const quotationId = useSelector((state) => state.generateQuotationId.value)
// view all quotation id
const quotationIdList = useSelector((state)=>state.quotationId.value)

//storeCode
const storeCode=localStorage.getItem('branchId')

const [isModify,setIsModify]=useState(false)
const [isLoading,setIsLoading]=useState(false)


const [invId,setInvId]=useState(null)
const [customer,setCustomer]=useState(null)
const [fromDate,setFromDate]=useState(null)
const [toDate,setToDate]=useState(null)
const [addQuotation,setAddQuotation]=useState(false)
const [viewQuotation,setQuotation]=useState(false)
const [itemType,setItemType]=useState(2)
const [product,setProduct]=useState(null)
const [singleViewId,setSingleViewId]=useState(null)
const [filteredData,setFilteredData]=useState([])
const [time,setTime]=useState(null)

// validation states

const [customerAlert,setCustomerAlert]=useState(false)
const [expDateAlert,setExpDateAlert]=useState(false)
const [addProductAlert,setAddProductAlert]=useState(false)
const [itemsAlert,setItemsAlert]=useState(false)
const [unitCostAlert,setUnitCostAlert]=useState(false)
const [quantityAlert,setQuantityAlert]=useState(false)
const [branchId,setBranchId]=useState(null)

const addQuotationForm1InitialState={
  customerName:'',
  contact:'',
  email:'',
  estimateDate:today,
  expiryDate:'',
  customerAddress:'',
  billingAddress:''
}
const [addQuotationForm1,setAddQuotationForm1]=useState(addQuotationForm1InitialState)

const addQuotationForm2InitialState={
  type:0,
  itemName:'',
  itemId:'',
  description:'',
  unitCost:'',
  qty:'',
  uom:'',
  amount:''
}
const [addQuotationForm2,setAddQuotationForm2]=useState(addQuotationForm2InitialState)

const addQuotationForm3InitialState={
  total:'',
  tax:'',
  discount:'',
  grandTotal:''
}
const [addQuotationForm3,setAddQuotationForm3]=useState(addQuotationForm3InitialState)
const [singleCustomerData,setSingleCustomerData]=useState([])
const [selectedProductTableForFrontEnd,setSelectedProductTableForFrontEnd]=useState([])

 //Snackbar States
const [successSnackOpen, setSuccessSnackOpen] = useState(false);
const [errorSnackOpen, setErrorSnackOpen] = useState(false);
const [snackMsg, setSnackMsg] = useState("Error!!");



// open add quotation dialog
const openAddQuotationDialog=()=>{
  setAddQuotation(true)
  clearState()
}
// update listener
const updateListener=()=>{
  setIsModify(!isModify)
}

  //Close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setSuccessSnackOpen(false);
  };
  //Close ErrorSnackbar
  const closeErrorSnackbar = () => {
    setErrorSnackOpen(false);
  };
   // get current time fn
   useEffect(() => {
    setInterval(() => {
      setTime(`${new Date().getHours()}:${new Date().getMinutes()}`);
    }, 1000);
  }, [time]);

// api call
useEffect(()=>{
  generateQuotationIdAPICall()
  
},[isModify])

useEffect(()=>{
  if(userRole==="user" && branchId!==null){
    viewQuotationAPICall({branchId:branchId},setIsLoading)
    viewAllCustomersAPICall({branchId:branchId})
    quotationIdListAPICall({branchId:branchId})
  }
  if(userRole==="admin"){
    viewQuotationAPICall({branchId:branchId},setIsLoading)
    viewAllCustomersAPICall({branchId:branchId})
    quotationIdListAPICall({branchId:branchId}) 
  }
   
  
},[branchId,isModify])
useEffect(()=>{
  if(userRole==="user" && branchId!==null){
    viewAllProductsAPICall({type:itemType,branchId:branchId})
  }
  if(userRole==="admin"){
    viewAllProductsAPICall({type:itemType,branchId:branchId})
  }
    
  
},[itemType,branchId])

useEffect(()=>{
  userRole==="user" ? setBranchId(storeCode):viewAllBranchesAPICall()
},[storeCode,userRole])
// radio button change function
const getItemType=(e)=>{
   const {value} = e.target
   setItemType(parseFloat(value))
  }
// form1 onChange functions 
const getAddQuotationForm1=(key)=>(e)=>{
   const {value} = e.target;
   if(key === "expDate"){
    setAddQuotationForm1({...addQuotationForm1,expiryDate:value})
    setExpDateAlert(false)
   }
   if(key === "billingAdd"){
    setAddQuotationForm1({...addQuotationForm1,billingAddress:value})
   }
  
}

// form2 onChange function
const getAddQuotationForm2=(key)=>(e)=>{
  const {value}=e.target
  if(key==="description"){
    setAddQuotationForm2({...addQuotationForm2,description:value})
  }
  if(key==="unitCost"){
    setAddQuotationForm2({...addQuotationForm2,unitCost:parseFloat(value)})
    setUnitCostAlert(false)
    if(value===""){
      setUnitCostAlert(true)
    }
  }
  if(key === "qty"){
    setAddQuotationForm2({...addQuotationForm2,qty:parseFloat(value)})
    setQuantityAlert(false)
    if(value===""){
      setQuantityAlert(true)
    }
  }

}

// form3 onChange function
const getAddQuotationForm3=(key)=>(e)=>{
  const {value}=e.target
  if(key === "tax"){
    setAddQuotationForm3({
      ...addQuotationForm3,tax:parseFloat(value)
    })
  }
  if(key==="discount"){
    setAddQuotationForm3({
      ...addQuotationForm3,discount:parseFloat(value)
    })
  }
}

// submit button function
const quotationSubmitBtnFn =()=>{
  viewQuotationAPICall({branchId},setIsLoading,invId,customer,fromDate,toDate)
}

const deleteTableRow=(index)=>{
  selectedProductTableForFrontEnd.splice(index,1)
  setSelectedProductTableForFrontEnd([...selectedProductTableForFrontEnd])
}

// extract single customer data 
useEffect(()=>{
    let filteredData=customerList?.filter((obj)=>{
      if(obj._id===addQuotationForm1?.customerName){
        return obj;
      }
    })
    setSingleCustomerData(filteredData)
},[customerList,addQuotationForm1?.customerName])

// assign single customer data to state
useEffect(()=>{
   setAddQuotationForm1({
      ...addQuotationForm1,
      contact:singleCustomerData !== undefined && singleCustomerData[0]?.mobileNo || 'No Data',
      email:singleCustomerData !== undefined && singleCustomerData[0]?.email || 'No Data',
      customerAddress:
      `${singleCustomerData !== undefined && singleCustomerData[0]?.buildingName || '##'}\n${singleCustomerData !== undefined && singleCustomerData[0]?.streetName || '##'}`
   })
},[singleCustomerData])

// extract product id and name from product 
useEffect(()=>{
  setAddQuotationForm2({
    ...addQuotationForm2,itemName:product?.productName,
    itemId:product?._id,
    uom:product?.unitName
  })
},[product])

// calculate amount
useEffect(()=>{
  setAddQuotationForm2({...addQuotationForm2,
    amount:parseFloat((addQuotationForm2?.unitCost)*(addQuotationForm2?.qty))
  })
},[addQuotationForm2?.unitCost,addQuotationForm2?.qty])

/// add button function
const addButtonFunction =()=>{


  if( product===null){
    setItemsAlert(true)
  }
  else if(addQuotationForm2.unitCost===""){
    setUnitCostAlert(true)
  }
  else if(addQuotationForm2.qty===""){
    setQuantityAlert(true)
  }
  else{
  setSelectedProductTableForFrontEnd([
    ...selectedProductTableForFrontEnd,
    {
      itemType:addQuotationForm2?.type,
      itemName:addQuotationForm2?.itemName,
      itemId:addQuotationForm2?.itemId,
      description:addQuotationForm2?.description,
      unitCost:addQuotationForm2?.unitCost,
      quantity:addQuotationForm2?.qty,
      uom:addQuotationForm2?.uom,
      amount:addQuotationForm2?.amount
    }

  ])
  setAddProductAlert(false)
  setAddQuotationForm2(addQuotationForm2InitialState)
  setProduct(null)
  }
}
// clear state
const clearState=()=>{
  setAddQuotationForm1(addQuotationForm1InitialState)
  setAddQuotationForm2(addQuotationForm2InitialState)
  setAddQuotationForm3(addQuotationForm3InitialState)
  setSelectedProductTableForFrontEnd([])
}
// calculate total
const calculateTableTotal=()=>{
  let newTotal = 0;
  selectedProductTableForFrontEnd?.forEach((item, i) => {
    newTotal += parseFloat(item.amount);
  });
  setAddQuotationForm3({ ...addQuotationForm3, total: newTotal });
}
useEffect(()=>{
  calculateTableTotal()
},[selectedProductTableForFrontEnd])

// calculate grand total
useEffect(()=>{
   setAddQuotationForm3({
    ...addQuotationForm3,
    grandTotal:(((addQuotationForm3?.total)+(addQuotationForm3?.tax)-addQuotationForm3?.discount))
   })
},[addQuotationForm3?.total,addQuotationForm3?.tax,addQuotationForm3?.discount])


const bodyToApi={
  customerId:addQuotationForm1?.customerName,
  contact:addQuotationForm1?.contact,
  email:addQuotationForm1?.email,
  estDate:addQuotationForm1?.estimateDate,
  expDate:addQuotationForm1?.expiryDate,
  custAdd:addQuotationForm1?.customerAddress,
  billAdd:addQuotationForm1?.billingAddress,
  items:selectedProductTableForFrontEnd,
  total:addQuotationForm3?.total,
  tax:addQuotationForm3?.tax,
  discount:addQuotationForm3?.discount,
  grandTotal:addQuotationForm3?.grandTotal,
  branchId:branchId
}
// add quotation 
const addQuotationSubmitBtn =()=>{
  if(addQuotationForm1.customerName===""){
    setCustomerAlert(true)
  }
  else if(addQuotationForm1.expiryDate===""){
    setExpDateAlert(true)
  }
  else if(selectedProductTableForFrontEnd.length===0){
    setAddProductAlert(true)
  }
  else{
  addQuotationAPICall(
    bodyToApi,
    updateListener,
    // setAddQuotation,
    // clearState,
    // setSuccessSnackOpen,
    // setErrorSnackOpen,
    // setSnackMsg
    )
    setAddQuotation(false)
    clearState()
    }
  // console.log(bodyToApi,);
}

// console.log(addQuotationForm1);
// console.log(addQuotationForm2);
// console.log(addQuotationForm3);

// EXTRACT SINGLE QUOTATION DATA
// open single view dialog

const openSingleViewDialog=(id)=>{
  setQuotation(true)
  setSingleViewId(id)
}


useEffect(()=>{
  let filteredArray=quotation?.filter((obj)=>{
    if(obj._id===singleViewId){
      return obj
    }
  })
  setFilteredData(filteredArray)
},[quotation,singleViewId])




const filterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (option) => option?.name + option.mobileNo,
});



  return (
    <div className="global-page-parent-container">
    <p className="breadcrumb">Sales &gt; Quotation / Estimate</p>
     <div className="global-white-bg-container sales-payments-container">
       <h3>Quotation/Estimate</h3>
       <hr className="global-hr"/>
       <div className="daily-report-top-input-container">
        {

          userRole==="admin" &&
          <div className="global-single-input auto-complete">
            <p>Branch</p>
            <Autocomplete
              sx={{ width: "100%" }}
              options={branchList || []}
              getOptionLabel={(option) => `${option?.storeCode}-${option?.branchName}`}
              renderInput={(params) => (
                <TextField {...params} placeholder="select Branch..." />
              )}
              onChange={(e, newValue) => {
                                          setBranchId(newValue?.storeCode)
                                          if(newValue===null) setBranchId(null)
                                        }}
            />
          </div>
        }
         
         <div className="global-single-input auto-complete">
           <p>Invoice ID</p>
           <Autocomplete
              sx={{ width: "100%" }}
              options={quotationIdList || [""]}
              getOptionLabel={(option) => option?.quotNo}
              renderInput={(params) => (
                <TextField {...params} placeholder="select Invoice ID..." />
              )}
              onChange={(e, newValue) => setInvId(newValue?.quotationNo)}
            />
           
         </div>
         <div className="global-single-input auto-complete">
           <p>Customer</p>
           <Autocomplete
              sx={{ width: "100%" }}
              options={customerList || [""]}
              getOptionLabel={(option) => `${option?.cusId}-${option?.name}`}
              renderInput={(params) => (
                <TextField {...params} placeholder="select Customer..." />
              )}
              onChange={(e, newValue) => setCustomer(newValue?._id)}
              filterOptions={filterOptions}
            />
         </div>
         <div className="global-single-input">
           <p>From</p>
            <input type="date" onChange={(e)=>setFromDate(e.target.value)}/>
         </div>
         <div className="global-single-input">
           <p>To</p>
            <input type="date" onChange={(e)=>setToDate(e.target.value)}/>
         </div>
         <button onClick={quotationSubmitBtnFn} className="btn btn-primary report-submit-btn">Submit</button>
       </div>
     </div>

     {/* table container */}
     <div className="global-white-bg-container sales-payments-container">
        <div className="sales-payments-table-top-container">
           <div className="sales-payments-table-top-left-container">
             {/* <Datepicker type="month" boxShadow="0 0 8px 2px #eee" height="40px"/> */}
            
           </div>
           <div className="stock-report-print-download-icon-container sales-quotation-icons">
              <button onClick={openAddQuotationDialog} className="btn btn-primary" style={{width:'43%',marginRight:"0"}}>Add Quotation</button>
              <div style={{display:"flex",alignItems:"center",justifyContent:"space-evenly"}}>
                <ReactToPrint
                  trigger={()=>(
                    <IconButton className="print-icon-container">
                    <i class="bi bi-printer printer-icon"></i>
                  </IconButton>
                  )}
                  content={()=>quotationPrint}
                />
              
      
                <IconButton className="download-icon-container">
                  <i class="bi bi-arrow-down-circle download-icon"></i>
                </IconButton>
              </div>
              
            </div>
        </div>

        {/* table */}
          <div className="global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                    <th>No</th>
                    {
                      userRole==="admin"&&
                      <>
                      <th>Branch Code</th>
                      <th>Branch Name</th>
                      </>
                    }
                    
                    <th>Invoice ID</th>
                    <th>Customer</th>
                    <th>Date</th>
                    <th>Paid Amount</th>
                    <th>View</th>
                </tr>
              </thead>
              {
                isLoading ?(
                  <tbody>
                  <tr>
                      <td colSpan={10}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={30}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={10}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={30}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={10}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={30}
                        />
                      </td>
                    </tr>
                </tbody>
                )
                :(
                  <tbody>
                  {
                    quotation?.slice(0)
                    .reverse()?.map((r,i)=>(
                   <tr>
                      <td>{i+1}</td>
                      {
                        userRole === "admin" &&
                        <>
                          <td>{r?.branchId}</td>
                          <td>{r?.branchName}</td>
                        </>
                      }
                    
                      <td>{r?.quotNo}</td>
                      <td>{r?.customerName}</td>
                      <td>{convertDateFormat(r?.estDate)}</td>
                      <td>{r?.grandTotal}</td>
                       <td>
                         <IconButton onClick={()=>openSingleViewDialog(r?._id)}>
                           <i class="bi bi-eye visibility-icon"></i>
                        </IconButton>
                       </td>
                  </tr>
                    ))
                  }
                  
                </tbody>
                )
              }
             
            </table>
          </div>


     </div>

     {/* add quation  */}
     <Dialog open={addQuotation} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setAddQuotation(false)}>
         <div className='sales-add-quotation-dialog-container'>
            <h3>Add Quotation / Estimate</h3>
            <hr  className='global-hr'/>
              {
                userRole==="admin" &&
                <div className="global-single-input auto-complete">
                  <p>Branch Id</p>
                  <Autocomplete
                  sx={{ width: "100%" }}
                  options={branchList || []}
                  getOptionLabel={(option) => `${option?.storeCode}-${option?.branchName}`}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="select branch..." />
                  )}
                  onChange={(e, newValue) => {
                                              setBranchId(newValue?.storeCode)
                                              if(newValue===null) setBranchId(null)
                                            }}
                />
                </div>
              }

            <div className='sales-add-quotation-dialog-input-container'>
              <div className='global-single-input'>
                <p>Transaction ID</p>
                 <input disabled value={`${quotationId?.prefix}${quotationId?.ORDERNO}`} type="text" />
              </div>
              <div className='global-single-input auto-complete' style={{position:"relative"}}>
                <p>Customer</p>
                <Autocomplete
              sx={{ width: "100%" }}
              options={customerList || [""]}
              getOptionLabel={(option) => `${option?.cusId}-${option?.name}`}
              renderInput={(params) => (
                <TextField {...params} placeholder="select Customer..." />
              )}
              onChange={(e, newValue) => {setAddQuotationForm1({...addQuotationForm1,customerName:newValue._id})
                                          setCustomerAlert(false)
                                          if(newValue===null){
                                            setCustomerAlert(true)
                                          }
                                          }}
              filterOptions={filterOptions}
            />
            {customerAlert?
              <p className="doc-validation-alert">Select a customer </p>
            :""}
              </div>
              <div className='global-single-input'>
                <p>Contact</p>
                 <input type="text" value={addQuotationForm1?.contact}/>
              </div>
              <div className='global-single-input'>
                <p>Email</p>
                 <input type="text" value={addQuotationForm1?.email}/>
              </div>
              <div className='global-single-input'>
                <p>Estimate Date</p>
                 <input type="date" value={addQuotationForm1?.estimateDate}/>
              </div>
              <div className='global-single-input' style={{position:"relative"}}>
                <p>Expiry Date</p>
                 <input type="date" onChange={getAddQuotationForm1('expDate')}/>
                 {expDateAlert?
                    <p className="doc-validation-alert" style={{top:"70%"}}>Select a date </p>
                  :""}
              </div>
              <div className='global-single-input' >
                <p>Customer Address</p>
                  <textarea name="" id=""  rows="3" value={addQuotationForm1?.customerAddress}></textarea>
              </div>
              <div className='global-single-input'>
                <p>Billing Address</p>
                  <textarea name="" id=""  rows="3" value={addQuotationForm1?.billingAddress} onChange={getAddQuotationForm1("billingAdd")}></textarea>
              </div>
            </div>
            <div className="add-quotation-product-container">
              {addProductAlert?
                <p className="purchase-wopo-add-product-validation" style={{padding:"1%"}}>
                add product details !!</p>
              :""}
            <div className="add-quotation-product-container-radio">
                <div>
                <input defaultChecked value={2} onChange={getItemType} type="radio" id="quotationMaterial" name="quotationRadio"/>
                <label htmlFor="quotationMaterial">Material</label>
                </div>
                <div>
                <input value={0} onChange={getItemType} type="radio" id="quotationReadymade" name="quotationRadio"/>
                <label htmlFor="quotationReadymade">Readymade</label>
                </div>
                <div>
                <input value={1} onChange={getItemType} type="radio" id="quotationAccessories" name="quotationRadio"/>
                <label htmlFor="quotationAccessories">Accessories</label>
                </div>
              </div>
              <div className='sales-add-quotation-dialog-item-container'>
            
            <div className='global-single-input auto-complete'style={{position:"relative"}}>
              <p>Item Name</p>
              <Autocomplete
                  sx={{width:'100%'}}
                  options={allProductsList|| ['']}
                  getOptionLabel={(option)=>`${option?.productId}-${option?.productName}`}
                  renderInput={(params) => (
                    <TextField {...params} />
                  )}
                  onChange={(e,newValue)=>{
                    setProduct(newValue)
                    setItemsAlert(false)
                    if(newValue===null){
                      setItemsAlert(true)
                    }
                  }
                  }
                />
                {itemsAlert?
                  <p className="doc-validation-alert">Select a item</p>
                :""}
            </div>
            <div className='global-single-input'>
              <p>Description</p>
               <input type="text" value={addQuotationForm2?.description} onChange={getAddQuotationForm2('description')} name="" id="" />
            </div>
            <div className='global-single-input sales-quotation-amount-input'style={{position:"relative"}}>
              <p>Unit Cost</p>
               <input type="text" value={isNaN(addQuotationForm2?.unitCost)?0:addQuotationForm2?.unitCost} onChange={getAddQuotationForm2('unitCost')} name="" id="" />
               {unitCostAlert?
                  <p className="doc-validation-alert">Enter unit cost</p>
                :""}
            </div>
            <div className='global-single-input sales-quotation-small-input'style={{position:"relative"}}>
              <p>Quantity</p>
               <input type="text" value={isNaN(addQuotationForm2?.qty)?0:addQuotationForm2?.qty} onChange={getAddQuotationForm2('qty')} name="" id="" />
                {quantityAlert?
                  <p className="doc-validation-alert">Enter Quantity</p>
                :""}
            </div>
            <div className='global-single-input sales-quotation-small-input'>
              <p>UOM</p>
               <input type="text" value={addQuotationForm2?.uom} name="" id="" />
            </div>
            <div className='global-single-input sales-quotation-amount-input'>
              <p>Amount</p>
               <input type="text" value={isNaN(addQuotationForm2?.amount)?0:addQuotationForm2?.amount} name="" id="" />
            </div>
            <button onClick={addButtonFunction} className="add-quotation-item-container-add-button">+</button>
          </div>

            </div>
           
            <div className='global-table-container quotation-table'>
              <table className='global-table'>
                 <thead>
                    <tr>
                        <th>No</th>
                        <th>Item Name</th>
                        <th>Description</th>
                        <th>Unit Cost</th>
                        <th>Qty</th>
                        <th>UOM</th>
                        <th>Amount</th>
                        <th>Action</th>
                    </tr>
                 </thead>
                 <tbody>
                  {
                   selectedProductTableForFrontEnd?.length !== 0? selectedProductTableForFrontEnd?.map((r,i)=>(
                      <tr>
                        <td>{i+1}</td>
                        <td>{r?.itemName}</td>
                        <td>{r?.description}</td>
                        <td>{r?.unitCost}</td>
                        <td>{r?.quantity}</td>
                        <td>{r?.uom}</td>
                        <td>{r?.amount}</td>
                        <td>
                        <IconButton>
                          <i class="bi bi-pencil-square edit-icon"></i>
                        </IconButton>
                        <IconButton onClick={()=>deleteTableRow(i)}>
                          <i class="bi bi-trash3 delete-icon"></i>
                         </IconButton>
                        </td>
                    </tr>
                    ))
                    :
                    <tr>
                      <td colSpan={10}>No Data</td>
                    </tr>
                  }
                    
                 </tbody>
              </table>
            </div>
            <div className='sales-add-quotation-bottom-container'>
              <div className='global-single-input quotation-total-amt-input'>
                <p>Total</p>
                <input type="text"  value={addQuotationForm3?.total}/>
              </div>
              <div className='global-single-input quotation-total-amt-input' >
                <p>Tax</p>
                <input type="text" value={isNaN(addQuotationForm3?.tax)?0:addQuotationForm3?.tax} onChange={getAddQuotationForm3('tax')} style={{backgroundColor:"#fff",border:"1px solid #000"}}/>
              </div>
              <div className='global-single-input quotation-total-amt-input'>
                <p>Discount</p>
                <input type="text" value={isNaN(addQuotationForm3?.discount)?0:addQuotationForm3?.discount} onChange={getAddQuotationForm3("discount")}/>
              </div>
              <div className='global-single-input quotation-total-amt-input'>
                <p>Grand Total</p>
                <input type="text" value={addQuotationForm3?.grandTotal} />
              </div>
            </div>
            <div style={{display:'flex',justifyContent:"center",alignItems:'center'}}>
                <button onClick={()=>{setAddQuotation(false) 
                                    clearState()}} className='btn btn-secondary-outlined'>Cancel</button>
                <button onClick={addQuotationSubmitBtn} className='btn btn-primary'>Submit</button>
            </div>
         </div>
     </Dialog>

     {/* view quotation */}
     <Dialog open={viewQuotation} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setQuotation(false)}>
     <div className='sales-add-quotation-dialog-container'>
            <h3>View Quotation / Estimate</h3>
            <hr  className='global-hr'/>
            <div className='sales-add-quotation-dialog-input-container'>
              <div className='global-single-input'>
                <p>Transaction ID</p>
                 <input type="text" value={filteredData !== undefined && filteredData[0]?.quotNo}/>
              </div>
              <div className='global-single-input'>
                <p>Customer</p>
                <input type="text" value={filteredData !== undefined && filteredData[0]?.customerName}/>
              </div>
              <div className='global-single-input'>
                <p>Contact</p>
                 <input type="text" value={filteredData !== undefined && filteredData[0]?.contact}/>
              </div>
              <div className='global-single-input'>
                <p>Email</p>
                 <input type="text" value={filteredData !== undefined && filteredData[0]?.email}/>
              </div>
              <div className='global-single-input'>
                <p>Estimate Date</p>
                 <input type="date" value={filteredData !== undefined && filteredData[0]?.estDate}/>
              </div>
              <div className='global-single-input'>
                <p>Expiry Date</p>
                 <input type="date" value={filteredData !== undefined && filteredData[0]?.expDate}/>
              </div>
              <div className='global-single-input'>
                <p>Customer Address</p>
                  <textarea  value={filteredData !== undefined && filteredData[0]?.custAdd} name="" id=""  rows="3"></textarea>
              </div>
              <div className='global-single-input'>
                <p>Billing Address</p>
                  <textarea value={filteredData !== undefined && filteredData[0]?.billAdd} name="" id=""  rows="3"></textarea>
              </div>
            </div>
            
            <div className='global-table-container quotation-table'>
              <table className='global-table'>
                 <thead>
                    <tr>
                        <th>No</th>
                        <th>Item Name</th>
                        <th>Description</th>
                        <th>Unit Cost</th>
                        <th>Qty</th>
                        <th>UOM</th>
                        <th>Amount</th>
                      
                    </tr>
                 </thead>
                 <tbody>
                  {
                    filteredData !== undefined && filteredData[0]?.items?.map((r,i)=>(
<tr>
                        <td>{i+1}</td>
                        <td>{r?.itemName}</td>
                        <td>{r?.description}</td>
                        <td>{r?.unitCost}</td>
                        <td>{r?.quantity}</td>
                        <td>{r?.uom}</td>
                        <td>{r?.amount}</td>
                     
                    </tr>
                    ))
                  }
                    
                 </tbody>
              </table>
            </div>
            <div className='sales-add-quotation-bottom-container'>
              <div className='global-single-input quotation-total-amt-input'>
                <p>Total</p>
                <input type="text" value={filteredData !== undefined && filteredData[0]?.total}/>
              </div>
              <div className='global-single-input quotation-total-amt-input' >
                <p>Tax</p>
                <input value={filteredData !== undefined &&filteredData[0]?.tax || 0} type="text" style={{backgroundColor:"#fff",border:"1px solid #000"}}/>
              </div>
              <div className='global-single-input quotation-total-amt-input'>
                <p>Discount</p>
                <input type="text" value={filteredData !== undefined &&filteredData[0]?.discount || 0}/>
              </div>
              <div className='global-single-input quotation-total-amt-input'>
                <p>Grand Total</p>
                <input type="text" value={filteredData !== undefined &&filteredData[0]?.grandTotal}/>
              </div>
            </div>
            <div style={{display:'flex',justifyContent:"center",alignItems:'center'}}>
                <button onClick={()=>setQuotation(false)} className='btn btn-secondary'>Close</button>
            </div>
         </div>
     </Dialog>

       {/* snackbar */}
       <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />

      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />

      <div style={{display:"none"}}>
        <div ref={(el)=>(quotationPrint = el)}>
          <div className="print-view-top-container">
            <div
              className="print-view-top-left-container"
              style={{ width: "25%" }}
            >
              <p></p>
              <p></p>
            </div>
            <div
              className="print-view-top-middle-container"
              style={{ width: "50%" }}
            >
              {userRole === "admin" ? (
                <h3>{loginResponse?.data?.profile?.companyName}</h3>
              ) : userRole === "user" ? (
                <h3>{loginResponse?.storeName}</h3>
              ) : undefined}

              <p>QUOTATION / ESTIMATE</p>
            </div>
            <div
              className="print-view-top-right-container"
              style={{ width: "25%" }}
            >
              <p>{today}</p>
              <p>{time}</p>
            </div>
          </div>
          <div className="print-view-table-container report-print-view-table-with-clr">
            <table>
              <thead>
                 <tr>
                    <th>No</th>
                    {
                      userRole === 'admin' &&
                      <>
                       <th>Branch Code</th>
                       <th>Branch Name</th>
                      </>
                    }
                    
                    <th>Invoice ID</th>
                    <th>Customer</th>
                    <th>Date</th>
                    <th>Paid Amount</th>
                 </tr>
              </thead>
              <tbody>
              {
                    quotation?.map((r,i)=>(
                   <tr>
                      <td>{i+1}</td>
                      {
                        userRole === 'admin' &&
                        <>
                         <td>{r?.branchId}</td>
                         <td>{r?.branchName}</td>
                        </>
                      }
                     
                      <td>{r?.quotNo}</td>
                      <td>{r?.customerName}</td>
                      <td>{r?.estDate}</td>
                      <td>{r?.grandTotal}</td>
                  </tr>
                    ))
                  }
                  
              </tbody>
            </table>
          </div>
        </div>
      </div>

     
    </div>
  )
}
