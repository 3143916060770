import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import './overtime.css'
import { getAllActiveEmployeesAPICall } from "../../../../../API/Staff/HRM/employeeAPI";
import { viewOvertimeListAPI, generateOvertimeAPI, viewOvertimeListByIdAPI, updateOvertimeListByIdAPI, viewOvertimeTypeAPI, generateOvertimeTypeAPI, deleteOvertimeTypeAPI } from "./NewOvertimeAPI";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import CustomPagination from "../../../../Single Components/CustomPagination";
import { convertDateFormat } from "../../../../../Js/Date";
import { Autocomplete, Dialog, DialogTitle, IconButton, Skeleton, TextField, Tooltip, Typography } from "@mui/material";

function OvertimeList() {

    const tableListData = useSelector((state) => state?.overtime?.ViewOvertimeList)
    const allActiveEmpList = useSelector(
        (state) => state.allActiveEmployeeSlice.value
    );
    const viewOvertimeById = useSelector((state) => state?.overtime?.ViewOvertimeListById)
    const viewOvertimeTypeList = useSelector((state) => state?.overtime?.viewOvertimeType)

    const [FormDataInfo, setFormDataInfo] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [openDialog1, setOpenDialog1] = useState(false);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openDialog2, setOpenDialog2] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [isLoading, setIsLoading] = useState("");
    const [calculatedOvertimeAmount, setCalculatedOvertimeAmount] = useState('');
    const [showEmployeeDetails, setShowEmployeeDetails] = useState(false);
    const [formData, setFormData] = useState({
        _id: '',
        amount: '',
        date: '',
        duration: '',
        employee: '',
        otAmount: '',
        otType: '',
        discription: '',
        sequence: ''
    });
    const [newOvertimeType, setNewOvertimeType] = useState({
        type: "",
        amount: "",
    });
    const [selectedOvertimeType, setSelectedOvertimeType] = useState(null);
    const [validationMessages, setValidationMessages] = useState({
        employee: '',
        date: '',
        otType: '',
        duration: '',
        amount: '',
        otAmount: '',
        discription: '',
    });


    const resetForm = () => {
        setFormData({
            _id: '',
            date: '',
            amount: '',
            duration: '',
            employee: '',
            otAmount: '',
            otType: '',
            discription: '',
        });
        setValidationMessages({
            employee: '',
            date: '',
            otType: '',
            duration: '',
            amount: '',
            otAmount: '',
            discription: '',
        });
    };
    const validateForm = () => {
        // Check each form field's validation message
        const isValid = Object.values(validationMessages).every(message => message === '');
        return isValid;
    };
    const handlePageChange = (page) => {

        setCurrentPage(page);
    };
    const handleDataFromChild = (data) => {
        setFormDataInfo(data)
    };
    const handleRowClick = async (id) => {
        let payload = {
            _id: id
        };
        await viewOvertimeListByIdAPI(payload);
        setOpenDialog1(true);
        setEditMode(false);
        const clickedRowData = tableListData.find(item => item._id === id);
        const { otType, amount } = clickedRowData;
        viewOvertimeTypeAPI({ otType, amount });
    };
    const openOvertimeType = () => {
        setOpenDialog2(true);
    }
    const handleCreateButton = () => {
        setOpenAddDialog(true);

    };

    const handleEdit = () => {
        setEditMode(true);
    };
    const handleSave = () => {
        // let data = {
        //     _id: formData?._id,
        //     date: formData?.date,
        //     duration: formData?.duration,
        //     employeeId: formData?.employee._id,
        //     otAmount: calculatedOvertimeAmount,
        //     otTypeId: formData?.otType._id,
        //     discription: formData?.discription
        // }
        // updateOvertimeListByIdAPI(data)
        // setFormData({
        //     _id: '',
        //     date: '',
        //     amount: '',
        //     duration: '',
        //     employee: '',
        //     otAmount: '',
        //     otType: '',
        //     discription: '',
        // });
        if (validateForm()) {
            // Perform save operation
            let data = {
                _id: formData?._id,
                date: formData?.date,
                duration: formData?.duration,
                employeeId: formData?.employee._id,
                otAmount: calculatedOvertimeAmount,
                otTypeId: formData?.otType._id,
                discription: formData?.discription
            }
            updateOvertimeListByIdAPI(data);
            // Reset form and close dialog
            resetForm();
            setOpenDialog1(false);
        }

    };
    const handleCreate = () => {
        // let data = {
        //     date: formData?.date,
        //     duration: formData?.duration,
        //     employeeId: formData?.employee._id,
        //     otAmount: calculatedOvertimeAmount,
        //     otTypeId: formData?.otType._id,
        //     discription: formData?.discription,
        // };
        // generateOvertimeAPI(data);
        // setFormData({
        //     _id: '',
        //     date: '',
        //     amount: '',
        //     duration: '',
        //     employee: '',
        //     otAmount: '',
        //     otType: '',
        //     discription: '',
        // });
        // setOpenAddDialog(false);
        if (validateForm()) {
            // Perform create operation
            let data = {
                date: formData?.date,
                duration: formData?.duration,
                employeeId: formData?.employee._id,
                otAmount: calculatedOvertimeAmount,
                otTypeId: formData?.otType._id,
                discription: formData?.discription,
            };
            generateOvertimeAPI(data);
            // Reset form and close dialog
            resetForm();
            setOpenAddDialog(false);
        }
    };
    const handleEmployeeChange = (e, newValue) => {
        setFormData({ ...formData, employee: newValue });
        setValidationMessages({
            ...validationMessages,
            employee: newValue ? '' : 'Please select an employee.',
        });
    };

    const handleDateChange = (e) => {
        setFormData({ ...formData, date: e.target.value });
        setValidationMessages({
            ...validationMessages,
            date: e.target.value ? '' : 'Please enter a valid date.',
        });
    };

    const handleOtTypeChange = (e, newValue) => {
        setFormData({ ...formData, otType: newValue });
        setSelectedOvertimeType(newValue);
        setValidationMessages({
            ...validationMessages,
            otType: newValue ? '' : 'Please select an overtime type.',
        });
    };

    const handleDurationChange = (e) => {
        setFormData({ ...formData, duration: e.target.value });
        setValidationMessages({
            ...validationMessages,
            duration: e.target.value ? '' : 'Please enter a valid duration.',
        });
    };

    const handleAmountChange = (e) => {
        setFormData({ ...formData, amount: e.target.value });
        setValidationMessages({
            ...validationMessages,
            amount: e.target.value ? '' : 'Please enter a valid amount.',
        });
    };

    const handleOtAmountChange = (e) => {
        setFormData({ ...formData, otAmount: e.target.value });
        setValidationMessages({
            ...validationMessages,
            otAmount: e.target.value ? '' : 'Please enter a valid overtime amount.',
        });
    };

    const handleDiscriptionChange = (e) => {
        setFormData({ ...formData, discription: e.target.value });
        setValidationMessages({
            ...validationMessages,
            discription: e.target.value ? '' : 'Please enter a description.',
        });
    };

    const handleSubmitButton = () => {

        let data = {
            type: newOvertimeType.type,
            amount: newOvertimeType.amount,
        }
        generateOvertimeTypeAPI(data)
        setOpenDialog2(false);
    };

    const handleDeleteOvertimeType = (id) => {
        deleteOvertimeTypeAPI({ _id: id }, newOvertimeType.type, newOvertimeType.amount);
    };
    useEffect(() => {
        viewOvertimeTypeAPI()
    }, []);

    useEffect(() => {
        if (viewOvertimeById) {
            let element = viewOvertimeById
            console.log(element)
            setFormData({
                _id: element?._id,
                amount: element?.amount,
                date: element?.date,
                duration: element?.duration,
                employee: allActiveEmpList?.find((res) => res._id === element?.employeeId),
                otAmount: element?.otAmount,
                sequence: element?.sequence,
                otType: viewOvertimeTypeList?.find((res) => res._id === element?.otTypeId),
            });
        }


    }, [viewOvertimeById, viewOvertimeTypeList, allActiveEmpList])
    useEffect(() => {

        if (formData.otType && formData.duration) {
            const calculatedAmount = formData.otType.amount * formData.duration;
            setCalculatedOvertimeAmount(calculatedAmount);
        } else {
            setCalculatedOvertimeAmount('');
        }
    }, [formData.otType, formData.duration]);

    //   useEffect(() => {
    //     if (FormDataInfo?.length !== 0) {
    //       salesQuotationFilterAPICall({
    //         statusType: 1,
    //         fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} ${getCurrentTime()}`,
    //         toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} ${getCurrentTime()}`,
    //         createdBy: FormDataInfo?.createdBy,
    //         branchList: FormDataInfo?.branchList,
    //         status: FormDataInfo?.status?.length === 0 ? '' : FormDataInfo?.status[0],
    //         search: FormDataInfo?.search,
    //         index: currentPage-1,
    //       },setIsLoading);
    //     }

    //   }, [FormDataInfo, currentPage]);
    useEffect(() => {
        viewOvertimeListAPI()
    }, [])

    return (
        <div className="global-page-parent-container">
            <div className="global-white-bg-container">
                <div className="justify-space-between"
                    style={{ gap: "0 12px", justifyContent: "flex-end" }}
                >
                    <CategorySearchandFilter
                        statusOptions={['DRAFT', 'CANCEL']}
                        onData={handleDataFromChild}
                        isCreatedBy={true}
                        isStatus={true} />
                    <button
                        className="establish-create"
                        onClick={() => handleCreateButton()}

                    >
                        Create
                    </button>
                </div>
                <div className="create-button-blue-container">
                    <h3>Overtime</h3>
                    <CustomPagination
                        currentPage={currentPage}
                        totalPages={tableListData?.pages}
                        onPageChange={handlePageChange}
                        setFormDataInfo={setFormDataInfo}
                    />
                </div>
                <div className="new-global-table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Employee</th>
                                <th>Date</th>
                                <th>Overtime Type</th>
                                <th>Hour</th>
                                <th>Amount</th>
                                <th>Overtime Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ? (
                                [...Array(10)].map((r, i) => (
                                    <tr key={i}>
                                        <td colSpan={8}>
                                            <Skeleton
                                                variant="rectangular"
                                                animation="wave"
                                                width={"100%"}
                                                height={40}
                                            />
                                        </td>
                                    </tr>
                                ))
                            ) : tableListData !== undefined &&
                                tableListData?.length !== 0 ? (
                                tableListData
                                    ?.slice(0)
                                    ?.reverse()
                                    ?.map((item, index) => (
                                        <tr
                                            onClick={() => handleRowClick(item._id)}
                                            key={index}>
                                            <td>{item?.employee}</td>
                                            <td>{convertDateFormat(item?.date)}</td>
                                            <td>{item?.otType}</td>
                                            <td>{item?.duration}</td>
                                            <td>{item?.amount}</td>
                                            <td>{item?.otAmount}</td>
                                        </tr>
                                    ))
                            ) : (
                                <tr>
                                    <td colSpan={6}>No Data</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <Dialog
                    open={openDialog1}
                    onClose={() => {
                        setOpenDialog1(false);
                        setEditMode(false);
                        setFormData({
                            _id: '',
                            date: '',
                            amount: '',
                            duration: '',
                            employee: '',
                            otAmount: '',
                            otType: '',
                            discription: '',
                        });
                    }}
                    maxWidth="xl"
                    fullWidth
                    sx={{ padding: "7px 36px" }}
                >
                    <div
                        className="new-global-white-bg-container"
                        style={{ padding: "0 2%" }}
                    >
                        <Tooltip title="Back">
                            <IconButton>
                                <i
                                    onClick={() => {
                                        setOpenDialog1(false)
                                        setEditMode(false);
                                        setFormData({
                                            ...formData,
                                            _id: '',
                                            date: '',
                                            amount: '',
                                            duration: '',
                                            employee: '',
                                            otAmount: '',
                                            discription: '',
                                        });
                                    }}
                                    className="bi bi-chevron-left"
                                    style={{ fontSize: "27px", color: "black", cursor: "pointer" }}

                                ></i>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit">
                            <IconButton
                                onClick={handleEdit}>
                                <i className="bi bi-pencil-square edit-icon1"></i>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Save">
                            <IconButton onClick={handleSave} >
                                <i className="bi bi-floppy save-icon"></i>
                            </IconButton>
                        </Tooltip>
                        <hr className="global-hr" />
                        <h4 className="global-name">{formData?.sequence}</h4>
                        <div className="new-global-input-container" style={{ gap: '10px' }}>
                            <div className="new-global-single-input auto-complete-new">
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={allActiveEmpList || []}
                                    getOptionLabel={(option) => option ? `${option.emp_id} - ${option.staff_name}` : ""}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Employee"
                                            focused
                                        />
                                    )}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option._id}>
                                            <span>{`${option.emp_id} - ${option.staff_name}`}</span>
                                        </li>
                                    )}
                                    value={formData?.employee || ''}
                                    onChange={handleEmployeeChange}
                                    // onChange={(e, newValue) => setFormData({ ...formData, employee: newValue })}
                                    disabled={!editMode}
                                />
                                <p className="doc-validation-alert">{validationMessages.employee}</p>

                            </div>
                            <div className="new-global-single-input">
                                <TextField
                                    id="outlined-basic"
                                    label="Create Date"
                                    variant="outlined"
                                    type="date"
                                    focused
                                    value={formData?.date}
                                    onChange={handleDateChange}
                                    // onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                                    disabled={!editMode}
                                />
                                <p className="doc-validation-alert">{validationMessages.date}</p>

                            </div>
                            <div className="new-global-single-input auto-complete-new">
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={viewOvertimeTypeList || []}
                                    getOptionLabel={(option) => option ? option.type : ''}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Overtime Type"
                                            focused
                                        />
                                    )}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option._id}>
                                            <span>{option.type}</span>
                                        </li>
                                    )}
                                    value={formData?.otType || ''}
                                    onChange={handleOtTypeChange}
                                    disabled={!editMode}
                                />
                                {validationMessages.otType ? (
                                    <p className="doc-validation-alert">{validationMessages.otType}</p>
                                ) : (
                                    <p
                                        onClick={openOvertimeType}
                                        style={{ cursor: 'pointer' }}
                                        className="doc-validation-alert">
                                        Add overtime type
                                    </p>
                                )}
                            </div>


                            <div className="new-global-single-input">
                                <TextField
                                    id="outlined-basic"
                                    label="Hour"
                                    variant="outlined"
                                    type="number"
                                    focused
                                    value={formData?.duration}
                                    // onChange={(e) => setFormData({ ...formData, duration: e.target.value })}
                                    onChange={handleDurationChange}
                                    disabled={!editMode}
                                />
                                <p className="doc-validation-alert">{validationMessages.duration}</p>
                            </div>
                            <div className="new-global-single-input">
                                <TextField
                                    id="outlined-basic"
                                    label="Amount"
                                    variant="outlined"
                                    type="number"
                                    focused
                                    value={selectedOvertimeType ? selectedOvertimeType.amount : formData?.amount}
                                    // onChange={(e) => setFormData({ ...formData, amount: e.target.value })}
                                    onChange={handleAmountChange}
                                    disabled={!editMode}
                                />
                                <p className="doc-validation-alert">{validationMessages.amount}</p>
                            </div>
                            <div className="new-global-single-input">
                                <TextField
                                    id="outlined-basic"
                                    label="Overtime Amount"
                                    variant="outlined"
                                    type="number"
                                    focused
                                    value={calculatedOvertimeAmount}
                                    // onChange={(e) => setFormData({ ...formData, otAmount: e.target.value })}
                                    onChange={handleOtAmountChange}
                                    disabled={!editMode}
                                />
                                <p className="doc-validation-alert">{validationMessages.otAmount}</p>

                            </div>
                            <div className="new-global-single-input"
                            >
                                <TextField
                                    id="outlined-basic"
                                    label=" Description"
                                    variant="outlined"
                                    type="description"
                                    focused
                                    value={formData?.discription}
                                    onChange={(e) => setFormData({ ...formData, discription: e.target.value })}
                                    disabled={!editMode}
                                />
                            </div>
                        </div>
                        <div className="ot-details">
                            <div>
                                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                                    {showEmployeeDetails ? "Employee previous OT details" : "Previous OT details"}
                                </DialogTitle>
                            </div>
                            <div>
                                <h4 onClick={() => setShowEmployeeDetails(!showEmployeeDetails)}>
                                    {showEmployeeDetails ? "Hide" : "Show"}
                                </h4>
                            </div>
                        </div>
                        {showEmployeeDetails && (
                            <>
                                <div className="ot-detail-cards">
                                    <div className='ot-card-container'>
                                        {
                                            [...Array(12)].map((r, i) => (
                                                <div
                                                    className='ot-card'
                                                >

                                                    <h5>03/17/2023</h5>
                                                    <h5>OT-2Hrs</h5>
                                                    <h5>Amount/Hr-10.00</h5>
                                                    <h5>Total-20.00</h5>

                                                </div>
                                            ))
                                        }
                                    </div>
                                    <hr className="ot-hr" />
                                    <h4 className="global-name"> Net pay amount</h4>
                                    <h2 style={{ color: '#361ecf' }}>Rs 5,0000.00</h2>
                                </div>
                            </>
                        )}
                    </div>
                </Dialog>
                <Dialog
                    open={openAddDialog}
                    onClose={() => setOpenAddDialog(false)}
                    maxWidth="xl"
                    fullWidth
                    sx={{ padding: "7px 36px" }}
                >
                    <div
                        className="new-global-white-bg-container"
                        style={{ padding: "0 2%" }}
                    >
                        <Tooltip title="Back">
                            <IconButton>
                                <i
                                    onClick={() => setOpenAddDialog(false)}
                                    className="bi bi-chevron-left"
                                    style={{ fontSize: "27px", color: "black", cursor: "pointer" }}

                                ></i>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Save">
                            <IconButton onClick={handleCreate} >
                                <i className="bi bi-floppy save-icon"></i>
                            </IconButton>
                        </Tooltip>
                        <hr className="global-hr" />
                        <h4 className="global-name">{formData?.sequence}</h4>
                        <div className="new-global-input-container" style={{ gap: '10px' }}>
                            <div className="new-global-single-input auto-complete-new">
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={allActiveEmpList || []}
                                    getOptionLabel={(option) => option ? `${option.emp_id} - ${option.staff_name}` : ""}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Employee"
                                            focused
                                        />
                                    )}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option._id}>
                                            <span>{`${option.emp_id} - ${option.staff_name}`}</span>
                                        </li>
                                    )}
                                    value={formData?.employee || ''}
                                    onChange={(e, newValue) => setFormData({ ...formData, employee: newValue })}
                                />
                                <p className="doc-validation-alert">{validationMessages.employee}</p>
                            </div>
                            <div className="new-global-single-input">
                                <TextField
                                    id="outlined-basic"
                                    label="Create Date"
                                    variant="outlined"
                                    type="date"
                                    focused
                                    value={formData?.date}
                                    onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                                />
                                <p className="doc-validation-alert">{validationMessages.date}</p>
                            </div>
                            <div className="new-global-single-input auto-complete-new">
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={viewOvertimeTypeList || []}
                                    getOptionLabel={(option) => option ? option.type : ''}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Overtime Type"
                                            focused
                                        />
                                    )}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option._id}>
                                            <span>{option.type}</span>
                                        </li>
                                    )}
                                    value={formData?.otType || ''}
                                    onChange={(e, newValue) => {
                                        setFormData({ ...formData, otType: newValue });
                                        setSelectedOvertimeType(newValue);
                                    }}
                                />
                                {validationMessages.otType ? (
                                    <p className="doc-validation-alert">{validationMessages.otType}</p>
                                ) : (
                                    <p
                                        onClick={openOvertimeType}
                                        style={{ cursor: 'pointer' }}
                                        className="doc-validation-alert">
                                        Add overtime type
                                    </p>
                                )}
                            </div>

                            <div className="new-global-single-input">
                                <TextField
                                    id="outlined-basic"
                                    label="Hour"
                                    variant="outlined"
                                    type="number"
                                    focused
                                    value={formData?.duration}
                                    onChange={(e) => setFormData({ ...formData, duration: e.target.value })}
                                />
                                <p className="doc-validation-alert">{validationMessages.duration}</p>
                            </div>
                            <div className="new-global-single-input">
                                <TextField
                                    id="outlined-basic"
                                    label="Amount"
                                    variant="outlined"
                                    type="number"
                                    focused
                                    value={selectedOvertimeType ? selectedOvertimeType.amount : formData?.amount}
                                    onChange={(e) => setFormData({ ...formData, amount: e.target.value })}
                                />
                                <p className="doc-validation-alert">{validationMessages.amount}</p>
                            </div>
                            <div className="new-global-single-input">
                                <TextField
                                    id="outlined-basic"
                                    label="Overtime Amount"
                                    variant="outlined"
                                    type="number"
                                    focused
                                    value={calculatedOvertimeAmount}
                                    onChange={(e) => setFormData({ ...formData, otAmount: e.target.value })}
                                />
                                <p className="doc-validation-alert">{validationMessages.otAmount}</p>
                            </div>
                            <div className="new-global-single-input"
                            >
                                <TextField
                                    id="outlined-basic"
                                    label=" Description"
                                    variant="outlined"
                                    type="description"
                                    focused
                                    value={formData?.discription}
                                    onChange={(e) => setFormData({ ...formData, discription: e.target.value })}
                                />
                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog
                    open={openDialog2}
                    onClose={() => setOpenDialog2(false)}
                    maxWidth="auto"
                    sx={{ padding: "7px 36px" }}
                >
                    <div className='dialogue-ctn'>
                        <div >
                            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                                Add Overtime Type
                            </DialogTitle>
                        </div>
                        <div className="new-global-input-container">
                            <div style={{ width: "auto" }} className="new-global-single-input">
                                <h4>OT Type</h4>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    type="text"
                                    value={newOvertimeType.type}
                                    onChange={(e) => setNewOvertimeType({ ...newOvertimeType, type: e.target.value })}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                                border: "none",
                                            },
                                            backgroundColor: "#e6ebf2",
                                        },
                                    }}
                                    focused
                                />
                            </div>
                            <div style={{ width: "auto" }} className="new-global-single-input">
                                <h4>Amount</h4>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    type="number"
                                    value={newOvertimeType.amount}
                                    onChange={(e) => setNewOvertimeType({ ...newOvertimeType, amount: e.target.value })}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                                border: "none",
                                            },
                                            backgroundColor: "#e6ebf2",
                                        },
                                    }}
                                    focused
                                />
                            </div>
                        </div>
                        <div className="overType-table-container">
                            <table className="overType-table">
                                <thead>
                                    <tr>
                                        <th>OT Name</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading ? (
                                        [...Array(10)].map((r, i) => (
                                            <tr key={i}>
                                                <td colSpan={8}>
                                                    <Skeleton
                                                        variant="rectangular"
                                                        animation="wave"
                                                        width={"100%"}
                                                        height={40}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    ) : viewOvertimeTypeList !== undefined &&
                                        viewOvertimeTypeList?.length !== 0 ? (
                                        viewOvertimeTypeList
                                            ?.slice(0)
                                            ?.map((item, index) => (
                                                <tr

                                                    key={index}>
                                                    <td>{item?.type}</td>
                                                    <td>{item?.amount}</td>
                                                    <td>
                                                        <IconButton onClick={() => handleDeleteOvertimeType(item._id)}>
                                                            <i className="bi bi-trash3 delete-icon" style={{ fontSize: "15px" }}></i>
                                                        </IconButton>
                                                    </td>
                                                </tr>
                                            ))
                                    ) : (
                                        <tr>
                                            <td colSpan={6}>No Data</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className="add-customer-button-container">
                            <button
                                onClick={() => setOpenDialog2(false)}
                                className="btn btn-secondary-outlined">Cancel</button>
                            <button
                                className="btn btn-primary"
                                onClick={handleSubmitButton}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </Dialog>
            </div>
        </div>
    )
}

export default OvertimeList