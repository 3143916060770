import {
  Autocomplete,
  Checkbox,
  createFilterOptions,
  Dialog,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { viewAllCustomersAPICall } from "../../../../API/Customer/customerAPI";
import {
  finalPostJobCompletionAPICall,
  getAllWorkOrderNosForJobCompletionAPICall,
  getSingleViewJobCompletionAPICall,
  jobCompletionFilterAPICall,
} from "../../../../API/Orders/jobCompletionAPI";
import {
  viewBranchWiseRackListAPICall,
  viewRackListAPICall,
} from "../../../../API/Orders/workOrderAPI";
import { getAllActiveEmployeesAPICall } from "../../../../API/Staff/HRM/employeeAPI";
import "../../../../css/Orders/jobCompletion.css";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import store from "../../../../Redux/store";
import { update_jc_single_view } from "../../../../Redux/Orders/Job completion/jcSingleViewSlice";
import { convertDateFormat, getCurrentTime, today } from "../../../../Js/Date";
import Swal from "sweetalert2";
import { LoadingForm } from "../../../Single Components/LoadingForm";
import { filterObjFromList } from "../../../../Js/generalFunctions";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export const JobCompletion = () => {
  const containerRef = useRef(null);
  //Order Id from local storage saved from view orders
  const orderIdFromLS = localStorage.getItem("JC_WO_ID");
  const storeCode = localStorage.getItem('branchId')
  const userLoginMeta = localStorage.getItem('login_meta')
  const userBranchId=JSON.parse(userLoginMeta)?.branchPk;
  //* Redux state
  //List of work orders eligible for job completion
  const woNosForJobCompletion = useSelector(
    (state) => state.orderNosForJobCompletionSlice.value
  );
  //List of All Customers
  const customerList = useSelector((state) => state.allCustomerListSlice.value);
  //Rack List
  const branchWiseRackList = useSelector(
    (state) => state.branchWiseRackListSlice.value
  );
  //Filter result
  const jcFilterResult = useSelector(
    (state) => state.jobCompletionFilterResSlice.value
  );
  //Single Order Data
  const singleOrderData = useSelector(
    (state) => state.jobCompletionSingleViewSlice.value
  );
  //Active Employee List
  const allActiveEmpList = useSelector(
    (state) => state.allActiveEmployeeSlice.value
  );

  //Snackbar States
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false);
  const [openErrorSnack, setOpenErrorSnack] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!");
  const [isModify, setIsModify] = useState(false);
  //Job completion filter form
  const JCFilterFormInitialState = {
    orderId: null,
    cusId: null,
    mobile: null,
    orderDate: "",
    deliveryDate: "",
    rack: null,
  };
  const [JCFilterForm, setJCFilterForm] = useState(JCFilterFormInitialState);
  const [selectedOrderNoObj, setSelectedOrderNoObj] = useState(null);
  const [selectedCustomerObj, setSelectedCustomerObj] = useState(null);

  //Single Order data Front end State
  const [singleOrderDataFrontend, setSingleOrderDataFrontend] = useState([]);
  //Current work order id
  const [currentWOId, setCurrentWOId] = useState(null);
  //Selected Rack Description
  const [selectedRack, setSelectedRack] = useState(null);
  const [selectedCutterObjs, setSelectedCutterObjs] = useState([]);
  //Extracted Cutter Ids
  const [selectedCutterIds, setSelectedCutterIds] = useState([]);
  //Checked Order Types Extracted
  const [checkedOrderTypes, setCheckedOrderTypes] = useState([]);
  //Completed Order Type Items Array for backend
  const [jobCompletedItemsArray, setJobCompletedItemsArray] = useState([]);
  //Select all checkbox
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);

  //Job completion qty used validation state
  const singleViewValidationInitialState = {
    isTotalQtyUsedZero: true,
    isCompletedQtyZero: false,
    isSelectedTailorNull: true,
    isTailorsLessThanQty: true,
  };
  const [singleViewValidation, setSingleViewValidation] = useState(
    singleViewValidationInitialState
  );

  //*Filtered List by designation
  //filtered by 'TAILOR
  const [tailorList, setTailorList] = useState([]);
  //filtered by 'CUTTING MASTER'
  const [cutterList, setCutterList] = useState([]);
  const [addRowMaterial,setAddRowMaterial]=useState(false)
  const addRowData = [{ value: "Add Header" }, { value: "Add Line" }];
  const [addRowInput, setAddRowInput] = useState(addRowData[1]);
  const [loadingForm, setLoadingForm] = useState(false)
  const [tableData, setTableData] = useState([
    {
      type: "lines",
      id: Date.now(),
      product:null,
      uom:null,
      qty:"",
     
  
    },
  ]);

  //*Fns
  //Update Listener
  const updateListener = () => {
    setIsModify(!isModify);
  };
  //Close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setOpenSuccessSnack(false);
  };
  //Close errorSnackbar
  const closeErrorSnackbar = () => {
    setOpenErrorSnack(false);
  };

  //Job completion body
  const jobCompletionFilterBody = {
    orderId: JCFilterForm?.orderId,
    cusId: JCFilterForm?.cusId,
    mobile: JCFilterForm?.mobile,
    orderDate: JCFilterForm?.orderDate === "" ? null : JCFilterForm?.orderDate,
    deliveryDate:
      JCFilterForm?.deliveryDate === "" ? null : JCFilterForm?.deliveryDate,
    rack: JCFilterForm?.rack,
    branchId:storeCode
  };
  //Click Filter submit button
  const filterSubmitBtnClick = () => {
    jobCompletionFilterAPICall(jobCompletionFilterBody);
  };

  //Click single view  icon button of an order
  const clickSingleOrderViewBtn = (orderId) => () => {
    getSingleViewJobCompletionAPICall({ orderId });
  };

  //Create single data Front end
  // const createSingleDataFrontEnd = (response) => {
  //   if (response !== undefined) {
  //     let newArray = response?.map((obj, i) => {
  //       return {
  //         ...obj,
  //         totQtyUsed: 0,
  //         usedQty: 0,
  //         stockBalance: obj?.stock,
  //         isSelected: false,
          
  //       };
  //     });
  //     setSingleOrderDataFrontend(newArray);
  //   }
  // };

  // new 12/03/2024 set single view to state
   const createSingleDataFrontEnd = (response) => {
    if (response !== undefined) {
      let newArray = response?.map((obj, i) => {
        return {
          amount:obj?.amount,
          customerName:obj?.customerName,
          cuttingMaster:(obj?.cuttingMaster.length > 0) ?allActiveEmpList?.filter(item => obj?.cuttingMaster?.includes(item._id)) :[],
          // cuttingMaster: obj?.cuttingMaster,
          date:obj?.date,
          itemId:obj?.itemId,
          length:obj?.length,
          model:obj?.model,
          orderNo:obj?.orderNo,
          prodId:obj?.prodId,
          productName:obj?.productName,
          qty:obj?.qty,
          qtyUsed:obj?.qtyUsed,
          rack:obj?.rack,
          stock:obj?.stock,
          tailor:(obj?.tailor.length > 0) ?
          allActiveEmpList?.filter(item => obj?.tailor?.includes(item._id)) : [],
          // tailor:allActiveEmpList?.filter(item => obj?.tailor?.includes(item._id)),
          tailor:obj?.tailor,
          tailorCommission:obj?.tailorCommission,
          totalAmount:obj?.totalAmount,
          totalLength:obj?.totalLength,
          totalTailorCommission:obj?.totalTailorCommission,
          type:obj?.type,
          uuid:obj?.uuid,
          wastage:obj?.wastage,
          workorderId:obj?.workorderId,
          totQtyUsed:0,
          usedQty:0,
          stockBalance: obj?.stock,
          isSelected: false,
          
        };
      });
      setSingleOrderDataFrontend(newArray);
      // setSelectedCutterObjs(allActiveEmpList?.filter(item => response !== undefined && response[0]?.cuttingMaster?.includes(item._id)))
      setSelectedCutterObjs(
        (response && response[0]?.cuttingMaster && response[0].cuttingMaster.length > 0) ?
        allActiveEmpList?.filter(item => response !== undefined && response[0]?.cuttingMaster?.includes(item._id)) :
        []
      );
      const filterRack=branchWiseRackList?.filter(item => item.description === response !== undefined && response[0]?.rack)
      setSelectedRack(filterRack !== undefined && filterRack[0])
    }
  };

  // console.log(selectedCutterObjs);


  //Extract selected Cutter ids
  const extractSelectedCutterIds = (fullList) => {
    let _extracted = fullList?.map((obj) => obj?._id);
    setSelectedCutterIds(_extracted);
  };

  //Extract checked OrderTypes
  const extractCheckedOrderTypes = (list) => {
    let _extracted = list?.filter((obj) => obj?.isSelected === true);
    setCheckedOrderTypes(_extracted);
  };

  //Construct Items Array for backend
  const createItemsArrayForBackend = (checkedTypes) => {
    console.log(checkedTypes);
    if (checkedTypes !== undefined) {
      let _itemsArray = checkedTypes?.map((obj, i) => {
        return {
          id: obj?.itemId,
          uuid: obj?.uuid,
          itemId: obj?.prodId,
          completedQty: obj?.qty,
          tailor: obj?.tailor?.map((r)=>r?._id),
          cuttingMaster: selectedCutterIds,
          tailorCommission: obj?.tailorCommission,
          materialQty: obj?.usedQty,
          wastage: obj?.wastage,
          totalQtyUsed: obj?.totQtyUsed,
          jobCompletionDate: `${today} ${getCurrentTime()}`, //change the date to current pc time and date
          // jobCompletionDate: obj?.date, //change the date to current pc time and date
        };
      });
      setJobCompletedItemsArray(_itemsArray);
    }
  };

 console.log(jobCompletedItemsArray);

  //Final Body for Job Completion
  const finalBodyForJobCompletion = {
    orderId: currentWOId,
    rack: selectedRack?.description || null,
    items: jobCompletedItemsArray,
    date:`${today} ${getCurrentTime()}`
  };



  //Validate qtyUsed>0  and completed qty 1

  const validateQtyUsedAndItemQty = () => {
    let _isUsedQtyNull = true;
    let _isQtyLess = false;
    let _indexAffected = null;

    for (let i = 0; i < jobCompletedItemsArray?.length; i++) {
      const obj = jobCompletedItemsArray[i];


      if (obj?.completedQty === 0) {
        _isQtyLess = true;
        _indexAffected = i;
        setSingleViewValidation({
          ...singleViewValidation,
          isCompletedQtyZero: _isQtyLess,
          // isSelectedTailorNull:false,
          // isTailorsLessThanQty:false,
          // isTotalQtyUsedZero:false
        });
        break;
      } else if (obj?.totalQtyUsed === 0) {
        _isUsedQtyNull = true;
        _indexAffected = i;
        setSingleViewValidation({
          ...singleViewValidation,
          isTotalQtyUsedZero: _isUsedQtyNull,
          // isCompletedQtyZero:false,
          // isSelectedTailorNull:false,
          // isTailorsLessThanQty:false
        });
        break;
      } else {
        // if (obj?.tailor === null) {
        //   setSingleViewValidation({
        //     ...singleViewValidation,
        //     isSelectedTailorNull: true,
        //     isCompletedQtyZero:false,
        //     isTailorsLessThanQty:false,
        //     isTotalQtyUsedZero:false
        //   });
        //   break;
        // } else if (obj?.completedQty > obj?.tailor.length) {
        //   setSingleViewValidation({
        //     ...singleViewValidation,
        //     isTailorsLessThanQty: true,
        //     isCompletedQtyZero:false,isSelectedTailorNull:false,
        //     isTotalQtyUsedZero:false
        //   });
        //   break;
        // } else {
        //   _isQtyLess = false;
        //   _isUsedQtyNull = false;
        //   setSingleViewValidation({
        //     ...singleViewValidation,
        //     isTotalQtyUsedZero: false,
        //     isCompletedQtyZero: false,
        //     isSelectedTailorNull: false,
        //     isTailorsLessThanQty: false,
        //   });
        // }

        _isQtyLess = false;
        _isUsedQtyNull = false;
        setSingleViewValidation({
          ...singleViewValidation,
          isTotalQtyUsedZero: false,
          isCompletedQtyZero: false,
          isSelectedTailorNull: false,
          isTailorsLessThanQty: false,
        });
      }
    }

    // jobCompletedItemsArray?.every((obj,i)=>{
    //     if(obj?.completedQty===0)
    //     {
    //       _isQtyLess=true
    //       _indexAffected=i

    //       return false

    //     }
    //     else if(obj?.totalQtyUsed===0)
    //     {
    //       _isUsedQtyNull=true
    //       _indexAffected=i
    //       return false
    //     }
    //     else
    //     {
    //       _isQtyLess=false
    //       _isUsedQtyNull=false
    //       return false
    //     }

    // })


  };

  //Job Completion Finish Button Click
  const clickJobCompletionFinishBtn = () => {
    if (currentWOId !== null) {
      if (selectedCutterIds.length === 0) {
        alert("select cutter!!");
      } else if (selectedRack === null || selectedRack === undefined) {
        alert("Select a rack!!");
      } else if (jobCompletedItemsArray.length === 0) {
        alert("Select items for job completion!!");
      } else if (singleViewValidation?.isCompletedQtyZero) {
        alert("Item Qty can not be 0!!");
      } else if (singleViewValidation?.isTotalQtyUsedZero) {
        alert("Total Qty used can not be 0!!");
      } else {
      
        finalPostJobCompletionAPICall(
          finalBodyForJobCompletion,
          setOpenSuccessSnack,
          setOpenErrorSnack,
          setSnackMsg,
          clearStates,
          updateListener,
          setLoadingForm,
          
        );
        console.log(finalBodyForJobCompletion);
      }
    } else {
      alert("Work order id!!");
    }
  };

  //Clear states
  const clearStates = () => {
    setJobCompletedItemsArray([]);
    setCheckedOrderTypes([]);
    setSelectedCutterIds([]);
    setSelectedCutterObjs([]);
    setCurrentWOId(null);
    setSingleOrderDataFrontend([]);
    store.dispatch(update_jc_single_view({ jcSingleView: undefined }));
    localStorage.removeItem("JC_WO_ID");
    setSingleViewValidation(singleViewValidationInitialState);
  };

  //Cancel or clear button
  const clickCancelBtn = () => {
    clearStates();
  };
 

  //*onChange
  const getEditableRowData = (index, key) => (e, newValue) => {
    const { value, checked } = e.target;
    if (key === "qty") {
      let limit = null;
      let newQty = null;
      if (singleOrderData !== undefined) {
        limit = singleOrderData[index].qty;

        if (value > limit) {
          newQty = Number(limit);
          singleOrderDataFrontend[index].qty = Number(newQty);
            singleOrderDataFrontend[index].totQtyUsed =
              Number(newQty) * Number(singleOrderDataFrontend[index].usedQty) +
              Number(singleOrderDataFrontend[index].wastage);
            singleOrderDataFrontend[index].totalTailorCommission =
              Number(newQty) *
              Number(singleOrderDataFrontend[index].tailorCommission);
            singleOrderDataFrontend[index].stockBalance =
              Number(singleOrderDataFrontend[index].stock) -
              Number(singleOrderDataFrontend[index].totQtyUsed);
            setSingleOrderDataFrontend([...singleOrderDataFrontend]);
        } else {
          Swal.fire({
            title: `Ordered Qty :${limit}`,
            html: '<div style="font-size: 16px; color: #f00;">Less items entered than ordered quantity. Do you want to continue?</div>',
            icon: 'warning',
            iconColor:'#f00',
            showCancelButton: true,
            confirmButtonColor: '#0019ff',
            confirmButtonText: 'Continue',
            customClass:{
              cancelButton:'sweet-alert-cancel-button',
              confirmButton:'sweet-alert-submit-button-blue',
              popup: 'custom-swal-popup',
              text: 'custom-swal-title',
            },
          }).then((result)=>{
            if(result.isConfirmed) {
              newQty = Number(value);
            }
            else if(result.isDismissed){
              newQty=Number(limit)
            }
          
            singleOrderDataFrontend[index].qty = Number(newQty);
            singleOrderDataFrontend[index].totQtyUsed =
              Number(newQty) * Number(singleOrderDataFrontend[index].usedQty) +
              Number(singleOrderDataFrontend[index].wastage);
            singleOrderDataFrontend[index].totalTailorCommission =
              Number(newQty) *
              Number(singleOrderDataFrontend[index].tailorCommission);
            singleOrderDataFrontend[index].stockBalance =
              Number(singleOrderDataFrontend[index].stock) -
              Number(singleOrderDataFrontend[index].totQtyUsed);
            setSingleOrderDataFrontend([...singleOrderDataFrontend]);
          })
        }
      }

      
    }
    if (key === "tailorCommission") {
      singleOrderDataFrontend[index].tailorCommission = Number(value);
      singleOrderDataFrontend[index].totalTailorCommission =
        Number(value) * Number(singleOrderDataFrontend[index].qty);
      setSingleOrderDataFrontend([...singleOrderDataFrontend]);
    }
    if (key === "totalTailorCommission") {
      singleOrderDataFrontend[index].totalTailorCommission = Number(value);
      setSingleOrderDataFrontend([...singleOrderDataFrontend]);
    }
    if (key === "usedQty") {
      singleOrderDataFrontend[index].usedQty = Number(value);
      singleOrderDataFrontend[index].totQtyUsed =
        Number(value) * Number(singleOrderDataFrontend[index].qty) +
        Number(singleOrderDataFrontend[index].wastage);
      if (
        singleOrderDataFrontend[index].productName?.toLowerCase() !==
        "out cloth"
      ) {
        if (
          singleOrderDataFrontend[index].totQtyUsed >
          singleOrderDataFrontend[index].stock
        ) {
          singleOrderDataFrontend[index].usedQty = 0;
          singleOrderDataFrontend[index].totQtyUsed = 0;
          singleOrderDataFrontend[index].stockBalance =
            Number(singleOrderDataFrontend[index].stock) -
            Number(singleOrderDataFrontend[index].totQtyUsed);
          alert("Used Qty is greater than stock!!");
        } else {
          singleOrderDataFrontend[index].stockBalance =
            Number(singleOrderDataFrontend[index].stock) -
            Number(singleOrderDataFrontend[index].totQtyUsed);
        }
      } else {
        singleOrderDataFrontend[index].stockBalance =
          Number(singleOrderDataFrontend[index].stock) -
          Number(singleOrderDataFrontend[index].totQtyUsed);
      }

      setSingleOrderDataFrontend([...singleOrderDataFrontend]);
    }
    if (key === "tailor") {
      let qty=singleOrderDataFrontend[index].qty
      if(qty>newValue?.length || qty<newValue?.length){

        Swal.fire({
          text:'You have chosen more or less tailors than the quantity',
          title: `Do you want to continue`,
          icon: 'warning',
          iconColor:'#f00',
          showCancelButton: true,
          confirmButtonColor: '#0019ff',
          confirmButtonText: 'Continue',
          customClass:{
            cancelButton:'sweet-alert-cancel-button',
            confirmButton:'sweet-alert-submit-button-blue',
            popup: 'custom-swal-popup',
            title: 'custom-swal-title',
          },
        }).then((result)=>{
          if(result.isConfirmed) {
            qty = Number(value);
            singleOrderDataFrontend[index].tailor = newValue?.map((obj) => obj);
            setSingleOrderDataFrontend([...singleOrderDataFrontend]);
          }
          else if(result.isDismissed){
            qty=Number(qty)
          }
          
        })
        
      }
      else{
        singleOrderDataFrontend[index].tailor = newValue?.map((obj) => obj);
        setSingleOrderDataFrontend([...singleOrderDataFrontend]);

      }
    }
    if (key === "wastage") {
      singleOrderDataFrontend[index].wastage = Number(value);
      singleOrderDataFrontend[index].totQtyUsed =
        Number(singleOrderDataFrontend[index].usedQty) *
          Number(singleOrderDataFrontend[index].qty) +
        Number(value);
      singleOrderDataFrontend[index].stockBalance =
        Number(singleOrderDataFrontend[index].stock) -
        Number(singleOrderDataFrontend[index].totQtyUsed);
      if (
        singleOrderDataFrontend[index].productName?.toLowerCase() !==
        "out cloth"
      ) {
        if (
          singleOrderDataFrontend[index].totQtyUsed >
          singleOrderDataFrontend[index].stock
        ) {
          singleOrderDataFrontend[index].wastage = 0;
          singleOrderDataFrontend[index].totQtyUsed = 0;
          singleOrderDataFrontend[index].stockBalance =
            Number(singleOrderDataFrontend[index].stock) -
            Number(singleOrderDataFrontend[index].totQtyUsed);
          alert("Total qty is greater than stock!!");
        } else {
          singleOrderDataFrontend[index].stockBalance =
            Number(singleOrderDataFrontend[index].stock) -
            Number(singleOrderDataFrontend[index].totQtyUsed);
        }
      } else {
        singleOrderDataFrontend[index].stockBalance =
          Number(singleOrderDataFrontend[index].stock) -
          Number(singleOrderDataFrontend[index].totQtyUsed);
      }

      setSingleOrderDataFrontend([...singleOrderDataFrontend]);
    }
    if (key === "date") {
      singleOrderDataFrontend[index].date = value;
      setSingleOrderDataFrontend([...singleOrderDataFrontend]);
    }
    if (key === "checkBox") {
      if(singleOrderDataFrontend[index].totalTailorCommission===0){

        let limit=singleOrderDataFrontend[index]?.tailor?.length || 0


        Swal.fire({
          title: `No. of Tailors selected:${limit}`,
          html: '<div style="font-size: 16px; color: #f00;">Tailor commission is zero do you want to continue?</div>',
          icon: 'warning',
          iconColor:'#f00',
          showCancelButton: true,
          confirmButtonColor: '#0019ff',
          confirmButtonText: 'Continue',
          customClass:{
            cancelButton:'sweet-alert-cancel-button',
            confirmButton:'sweet-alert-submit-button-blue',
            popup: 'custom-swal-popup',
            text: 'custom-swal-title',
          },
        }).then((result)=>{
          if(result.isConfirmed) {
            singleOrderDataFrontend[index].isSelected = checked;
            setSingleOrderDataFrontend([...singleOrderDataFrontend]);
            selectAllCheckbox();
          }
          else if(result.isDismissed){

          }
        })
      }
      else{
        singleOrderDataFrontend[index].isSelected = checked;
        setSingleOrderDataFrontend([...singleOrderDataFrontend]);
        selectAllCheckbox();
      }
    }
  };

 

  //Select select all Checkbox
  const checkSelectAllCheckBox = (e) => {
    setIsSelectAllChecked(!isSelectAllChecked);
    selectChildCheckboxes(e.target.checked);
  };

  //Check all child checkboxes if select all checked
  const selectChildCheckboxes = (checked) => {
    let _isAllChecked = checked;
    if (_isAllChecked) {
      singleOrderDataFrontend?.forEach((obj) => {
        obj.isSelected = true;
      });
      setSingleOrderDataFrontend([...singleOrderDataFrontend]);
    } else {
      singleOrderDataFrontend?.forEach((obj) => {
        obj.isSelected = false;
      });
      setSingleOrderDataFrontend([...singleOrderDataFrontend]);
    }
  };

  //select all checkbox if children selected
  const selectAllCheckbox = () => {
    let _childrenCheckedArray = singleOrderDataFrontend?.map((obj) => {
      if (obj.isSelected) return true;
      else return false;
    });

    if (!_childrenCheckedArray.includes(false)) {
      setIsSelectAllChecked(true);
    } else {
      setIsSelectAllChecked(false);
    }
  };

  //Filter Tailor by designation (TAILOR)
  const filterTailorByDesignation = (fullList) => {
    let _tailors = fullList?.filter(
      (obj) => obj?.designationName?.toLowerCase() === "TAILOR".toLowerCase()
    );

    setTailorList(_tailors);
  };
  //Filter cuttingMaster by designation (CUTTING MASTER)
  const filterCutterByDesignation = (fullList) => {
    let _cutter = fullList?.filter(
      (obj) =>
        obj?.designationName?.toLowerCase() === "CUTTING MASTER".toLowerCase()
    );

    setCutterList(_cutter);
  };


  //*useEffects
  //call single view job completion on initial load only if order id present in LS
  useEffect(() => {
    if (orderIdFromLS !== null && orderIdFromLS !== undefined) {
      getSingleViewJobCompletionAPICall({ orderId: orderIdFromLS });
    }
  }, []);
  useEffect(() => {
    getAllWorkOrderNosForJobCompletionAPICall({branchId:storeCode});
    // viewAllCustomersAPICall({branchId:userBranchId});
    viewBranchWiseRackListAPICall();
    jobCompletionFilterAPICall(jobCompletionFilterBody);
    viewBranchWiseRackListAPICall();
    getAllActiveEmployeesAPICall();
  }, [isModify]);
  //Setting AutoComplete objects in states
  useEffect(() => {
    if (selectedOrderNoObj !== null) {
      setJCFilterForm({ ...JCFilterForm, orderId: selectedOrderNoObj?._id });
    } else {
      setJCFilterForm({ ...JCFilterForm, orderId: null });
    }
  }, [selectedOrderNoObj]);
  useEffect(() => {
    if (selectedCustomerObj !== null) {
      setJCFilterForm({
        ...JCFilterForm,
        cusId: selectedCustomerObj?._id,
        mobile: selectedCustomerObj?.mobileNo,
      });
    } else {
      setJCFilterForm({ ...JCFilterForm, cusId: null, mobile: null });
    }
  }, [selectedCustomerObj]);
  //Set Single Order data to front end state
  useEffect(() => {
    if (singleOrderData !== undefined) {
      // setSingleOrderDataFrontend(singleOrderData)
      createSingleDataFrontEnd(singleOrderData);
      setCurrentWOId(singleOrderData[0]?.workorderId);
    } else {
      setSingleOrderDataFrontend([]);
      setCurrentWOId(null);
    }
  }, [singleOrderData]);

  console.log(singleOrderData);
  console.log(singleOrderDataFrontend);
  //Extract selected Cutter ids
  useEffect(() => {
    extractSelectedCutterIds(selectedCutterObjs);
  }, [selectedCutterObjs]);
  //Call extract checked Order Types
  useEffect(() => {
    extractCheckedOrderTypes(singleOrderDataFrontend);
  }, [singleOrderDataFrontend]);
  //Call fn for create Job completed Items Array for backend
  useEffect(() => {
    createItemsArrayForBackend(checkedOrderTypes);
  }, [checkedOrderTypes, selectedCutterIds]);

  //Call Filter employees by designation fn
  useEffect(() => {
    if (allActiveEmpList !== undefined) {
      filterTailorByDesignation(allActiveEmpList);
      filterCutterByDesignation(allActiveEmpList);
    }
  }, [allActiveEmpList]);

  // //Check all
  // useEffect(() => {
  //   selectChildCheckboxes();
  // }, [isSelectAllChecked]);

  //Filter by name and mob no in same input field
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option?.name + option.mobileNo,
  });


  // new table
  const addRow = () => {
    if (addRowInput?.value === "Add Header") {
      setTableData([
        ...tableData,
        { type: "header", id: Date.now(), headerValue: "" },
      ]);
    } else if (addRowInput?.value === "Add Line") {
      setTableData([
        ...tableData,
        {
          type: "lines",
          id: Date.now(),
          product:null,
          uom:null,
          qty:"",
        
         
        },
      ]);
    }

    // setAddRowInput(null);
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [tableData]);

  const handleInputChange = (id, field) => (e, newValue) => {
    const { value } = e.target;
    const updatedData = tableData.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: value === 0 ? newValue : e.target.value };
      }
      return row;
    });
    setTableData(updatedData);
  };



  const handleDelete = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };



  return (
    <>
      <div className="global-page-parent-container">
        <p className="breadcrumb">Orders &gt; Job Completion</p>
        <div className="job-completion-top-container">
          <h3>Job Completion</h3>
          <div className="job-completion-top-input-container">
            <div className="global-single-input auto-complete">
              <p>Order No</p>
              <Autocomplete
                sx={{ width: "100%" }}
                options={woNosForJobCompletion || []}
                getOptionLabel={(option) => option?.orderNo}
                renderInput={(params) => (
                  <TextField {...params} placeholder="select order number..." />
                )}
                onChange={(e, newValue) => setSelectedOrderNoObj(newValue)}
              />
            </div>
            <div className="global-single-input auto-complete">
              <p>Customer</p>
              <Autocomplete
                sx={{ width: "100%" }}
                value={selectedCustomerObj}
                options={customerList || []}
                getOptionLabel={(option) => option?.name}
                renderInput={(params) => (
                  <TextField {...params} placeholder="select customer..." />
                )}
                onChange={(e, newValue) => setSelectedCustomerObj(newValue)}
                filterOptions={filterOptions}
              />
            </div>
            <div className="global-single-input auto-complete">
              <p>Contact</p>
              <Autocomplete
                sx={{ width: "100%" }}
                defaultValue={selectedCustomerObj}
                value={selectedCustomerObj}
                options={customerList || []}
                getOptionLabel={(option) => option?.mobileNo}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Enter contact..." />
                )}
                onChange={(e, newValue) => setSelectedCustomerObj(newValue)}
              />
            </div>
            <div className="global-single-input">
              <p>Order Date</p>
              <input
                type="date"
                value={JCFilterForm?.orderDate}
                onChange={(e) =>
                  setJCFilterForm({
                    ...JCFilterForm,
                    orderDate: e.target.value,
                  })
                }
              />
            </div>
            <div className="global-single-input">
              <p>Delivery Date</p>
              <input
                type="date"
                value={JCFilterForm?.deliveryDate}
                onChange={(e) =>
                  setJCFilterForm({
                    ...JCFilterForm,
                    deliveryDate: e.target.value,
                  })
                }
              />
            </div>
            <div className="global-single-input auto-complete">
              <p>Rack</p>
              <Autocomplete
                sx={{ width: "100%" }}
                options={branchWiseRackList || []}
                getOptionLabel={(option) => option?.description}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select rack..." />
                )}
                onChange={(e, newValue) =>
                  setJCFilterForm({
                    ...JCFilterForm,
                    rack: newValue?.description,
                  })
                }
              />
            </div>
          </div>
          <div className="job-completion-btn-container">
            <button className="btn btn-secondary-outlined">Clear</button>
            <button className="btn btn-primary" onClick={filterSubmitBtnClick}>
              Submit
            </button>
          </div>
        </div>

        <div className="global-white-bg-container">
          <div className="global-table-container job-completion-order-list-table">
            <table className="global-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Work Order No</th>
                  <th>Customer</th>
                  <th>Mobile</th>
                  <th>Order Date</th>
                  <th>Delivery Date</th>
                  <th>Amount</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {jcFilterResult?.slice(0)?.reverse()?.map((r, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{r?.orderNo}</td>
                    <td>{r?.customer}</td>
                    <td>{r?.mobile}</td>
                    <td>{convertDateFormat(r?.orderDate)}</td>
                    <td>{convertDateFormat(r?.deliveryDate)}</td>
                    <td>{r?.amount?.toFixed(2)}</td>
                    <td>
                      <IconButton onClick={clickSingleOrderViewBtn(r?.orderId)}>
                        <i class="bi bi-eye visibility-icon"></i>
                      </IconButton>
                    </td>
                  </tr>
                )) || (
                  <tr>
                    <td colSpan={8}>No records</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {singleOrderData && (
          <div className="job-completion-table-container" style={{position:'relative'}}>
            <div className="job-completion-table-top-container">
              <div className="job-completion-table-top-left-container">
                <div className="job-completion-table-top-left-single-container">
                  <p style={{ width: "32%" }}>Order No</p>
                  <p>
                    : &nbsp;{" "}
                    {(singleOrderData && singleOrderData[0]?.orderNo) || ""}
                  </p>
                </div>
                <div className="job-completion-table-top-left-single-container">
                  <p style={{ width: "32%" }}>Customer</p>
                  <p>
                    : &nbsp;{" "}
                    {(singleOrderData && singleOrderData[0]?.customerName) ||
                      ""}
                  </p>
                </div>
              </div>

              <div className="job-completion-table-top-right-container">
                <div className="job-completeion-cutter-auto-complete">
                  <p>Rack</p>
                  <Autocomplete
                    sx={{ width: "100%" }}
                    options={branchWiseRackList || []}
                    getOptionLabel={(option) => option?.description}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="select rack..." />
                    )}
                    value={selectedRack}
                    onChange={(e, newValue) =>
                      setSelectedRack(newValue)
                    }
                  />
                </div>
                <div className="job-completeion-cutter-new-auto-complete">
                  <p>Cutter</p>
                  <Autocomplete
                    multiple
                    sx={{ width: "100%" }}
                    options={cutterList || []}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option?.staff_name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option?.staff_name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="select cutter..." />
                    )}
                    value={selectedCutterObjs}
                    onChange={(e, newValue) => setSelectedCutterObjs(newValue)}
                  />
                </div>
              </div>
            </div>
            <div className="global-table-container job-completion-table" >
              <table className="global-table">
                <thead style={{height:"35%"}}>
                  <tr>
                    <th>No</th>
                    <th>Type</th>
                    <th>Item</th>
                    <th>Item Qty</th>
                    <th>Model</th>
                    <th>Length</th>
                    <th>Amount</th>
                    <th>Tailor Com.</th>
                    <th>Tailor Total Com.</th>
                    <th>Tailor</th>
                    <th>Stock</th>
                    <th>Used Qty</th>
                    <th>Wastage</th>
                    <th>Tot Qty Used</th>
                    <th>Stock Balance</th>
                    <th>Date</th>
                    {/* <th>Raw Mterial</th> */}
                    <th>
                      Sel. All
                      <input
                        type="checkbox"
                        className="job-completion-checkbox global-checkbox"
                        checked={isSelectAllChecked}
                        onClick={checkSelectAllCheckBox}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(singleOrderDataFrontend?.length > 0 &&
                    singleOrderDataFrontend?.map((r, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{r?.type?.toUpperCase()}</td>
                        <td>{r?.productName}</td>
                        <td className="job-completion-table-used-qty">
                          <input
                            name="itemQty"
                            value={r?.qty}
                            onChange={getEditableRowData(i, "qty")}
                          />
                        </td>
                        <td>{r?.model || "-NIL-"}</td>
                        <td>{r?.length}</td>
                        <td>{r?.amount?.toFixed(2)}</td>
                        <td className="job-completion-table-used-qty">
                          <input
                            name="tailorCommission"
                            type={"number"}
                            min={"0"}
                            value={r?.tailorCommission}
                            onChange={getEditableRowData(i, "tailorCommission")}
                          />
                        </td>
                        <td className="job-completion-table-used-qty">
                          <input
                            name="tailorTotCommission"
                            readOnly
                            value={r?.totalTailorCommission?.toFixed(2)}
                          />
                        </td>
                        <td style={{width:"160px"}}>
                          <div className="job-completeion-table-auto-complete">
                          <Autocomplete
                            multiple
                            size="medium"
                            sx={{ width: "100%", marginLeft: "8%" }}
                            options={tailorList || [{ staff_name: "No data" }]}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option?.staff_name}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option?.staff_name}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="select tailor..."
                              />
                            )}
                            value={r?.tailor}
                            onChange={getEditableRowData(i, "tailor")}
                          />
                          </div>
                         
                        </td>

                        <td>{r?.stock?.toFixed(2)}</td>
                        <td className="job-completion-table-used-qty">
                          <input
                            name="usedQty"
                            type={"number"}
                            min={"0"}
                            placeholder="Enter.."
                            value={r?.usedQty}
                            onChange={getEditableRowData(i, "usedQty")}
                          />
                        </td>
                        <td className="job-completion-table-used-qty">
                          <input
                            name="wastage"
                            placeholder="Enter..."
                            type={"number"}
                            min={"0"}
                            value={r?.wastage}
                            onChange={getEditableRowData(i, "wastage")}
                          />
                        </td>
                        <td className="job-completion-table-used-qty">
                          <input
                            name="totalQtyUsed"
                            readOnly
                            value={r?.totQtyUsed.toFixed(2)}
                          />
                        </td>
                        <td>{r?.stockBalance?.toFixed(2)}</td>
                        <td className="job-completion-table-used-qty">
                          <input
                            type="date"
                            value={r?.date}
                            onChange={getEditableRowData(i, "date")}
                          />
                        </td>
                        {/* <td><span
                        title="Add Row Materials"
                         style={{
                          backgroundColor:"#07b707",
                          color:"#fff",
                          width:"20px",
                          height:"20px",
                          border:"none",
                          display:"flex",
                          justifyContent:"center",
                          alignItems:"center",
                          margin:"auto",
                          borderRadius:"4px"
                        }}
                        onClick={()=>setAddRowMaterial(true)}
                        >+</span></td> */}
                        <td>
                          <input
                            type="checkbox"
                            checked={r?.isSelected}
                            onClick={getEditableRowData(i, "checkBox")}
                            className="job-completion-checkbox global-checkbox"
                          />
                        </td>
                      </tr>
                    ))) || (
                    <tr>
                      <td colSpan={17}>No data</td>
                    </tr>
                  )}
                </tbody>
              </table>
              {/* <div open={addRowMaterial} > */}
              {/* {
                addRowMaterial &&
               
                <div className="job-completion-dialog-new">
                  
                   <div className="global-product-table">
                     <table>
                       <thead>
                          <tr>
                           <th>Product</th>
                           <th>UOM</th>
                           <th>Qty</th>
                         </tr>
                        </thead>
                        <tbody>
                        {tableData?.map((item, index) => (
                  <tr
                    key={index}
                    ref={index === tableData.length - 1 ? containerRef : null}
                  >
                    {item.type === "header" ? (
                      <>
                        <td colSpan="5">
                           <input type="text"
                               value={item.headerValue}
                               onChange={handleInputChange(item.id,'headerValue')}
                             
                              />
                        </td>
                        <td style={{ border: "none", width: "4%" }}>
                          <IconButton
                         
                            onClick={() => handleDelete(index)}
                            style={{ height: "30px" }}
                          >
                            <i
                              className="bi bi-trash3"
                              style={{ color: "#db0000", fontSize: "1.3rem" }}
                            ></i>
                          </IconButton>
                        </td>
                      </>
                    ) : (
                      <>
                        <td>
                          <div className="product-table-auto-complete">
                          <Autocomplete
                              options={[]}
                              style={{ maxHeight: "100px" }}
                              getOptionLabel={(option) => option}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              value={item?.product}
                              onChange={handleInputChange(item.id, "product")}
                              
                            />
                          </div>
                        </td>
                        <td>
                        <div className="product-table-auto-complete">
                          <Autocomplete
                              options={[]}
                              style={{ maxHeight: "100px" }}
                              getOptionLabel={(option) => option}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              value={item?.uom}
                              onChange={handleInputChange(item.id, "uom")}
                              
                            />
                            </div>
                        </td>
                       
                      
                         <td>
                          <input 
                             type="text" 
                             value={item?.qty}
                             onChange={handleInputChange(item.id, "qty")}
                           
                             />
                        </td>
                        <td style={{ border: "none", width: "4%" }}>
                          <IconButton
                            
                            onClick={() => handleDelete(index)}
                            style={{ height: "30px" }}
                          >
                            <i
                              className="bi bi-trash3"
                              style={{ color: "#db0000", fontSize: "1.3rem" }}
                            ></i>
                          </IconButton>
                        </td>
                      </>
                    )}
                  </tr>
                ))}
                        </tbody>
                     </table>
                   </div>
                   <div className="add-row-btn-container" style={{width:"27%"}}>
            <div
              className="global-single-input auto-complete"
              style={{ width: "76%" }}
            >
              <Autocomplete
                options={addRowData || []}
                getOptionLabel={(option) => option?.value}
                renderInput={(params) => <TextField {...params} />}
                value={addRowInput}
                onChange={(e, newValue) => setAddRowInput(newValue)}
                
              />
            </div>
            <button onClick={() => addRow()}    className="add-row-btn">
              +
            </button>
                   </div>
                   <div className="job-completion-final-btn-container">
                    <button
                       className="btn btn-secondary-outlined"
                       style={{height:"30px",width:"20%"}}
                       onClick={()=>setAddRowMaterial(false)}
                      >
                       Cancel
                     </button>
                     <button
                        style={{height:"30px",width:"20%"}}
                        className="btn btn-primary"
             
                       >
                       Submit
                       </button>
                   </div>
                </div>
              
              
              } */}
        
        {/* </div> */}

            </div>
            <div className="job-completion-final-btn-container">
              <button
                className="btn btn-secondary-outlined"
                onClick={clickCancelBtn}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                onMouseDown={validateQtyUsedAndItemQty}
                onClick={clickJobCompletionFinishBtn}
              >
                Finish
              </button>
            </div>
          </div>
        )}

        
      </div>
      <SuccessSnackbar
        open={openSuccessSnack}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={openErrorSnack}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
      <LoadingForm loading={loadingForm} />
    </>
  );
};
