import React, { useState } from "react";
import InputBox from "../../../components/InputBox";
import TableBox from "../../../components/TableBox";
import DropdownInputBox from "../../../components/DropdownInputBox";
import AddIcon from "@mui/icons-material/Add";
import SubmitBtn from "../../../components/SubmitBtn";

const ManufacturingVoucherDashboard = () => {
  const heading = ["Raw Material", "Quantity", " Unit"];

  const renderData = [
    {
      removeEventListenerawMaterial: "item 1",
      quantity: "5",
      unit: "kg",
    },
    {
      removeEventListenerawMaterial: "item 1",
      quantity: "5",
      unit: "kg",
    },
    {
      removeEventListenerawMaterial: "item 1",
      quantity: "5",
      unit: "kg",
    },
    {
      removeEventListenerawMaterial: "item 1",
      quantity: "5",
      unit: "kg",
    },
  ];

  const heading1 = ["Raw Material", "Quantity", "Rate", "Amount"];

  const renderData1 = [
    {
      removeEventListenerawMaterial: "item 1",
      quantity: "5",
      rate: "2000",
      amount: "1000",
    },

    {
      removeEventListenerawMaterial: "item 1",
      quantity: "5",
      rate: "2000",
      amount: "1000",
    },

    {
      removeEventListenerawMaterial: "item 1",
      quantity: "5",
      rate: "2000",
      amount: "1000",
    },
  ];
  const heading2 = ["Particular", "percentage", "Amount"];

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <>
      {/* bill of material  */}
      <div style={{ padding: "3em" }} className="global-white-bg-container">
        <div>
          <strong>Bill Of Materials </strong>
        </div>

        <div className="manufacturing-voucher-dashboard">
          <div>
            <InputBox label={"Product Name"} />
            <InputBox
              label={"Components"}
              isCheckBox={true}
              disabled={!isChecked}
              isChecked={isChecked}
              checkBoxOnChange={handleCheckboxChange}
            />
          </div>
          <hr className="global-hr" />
          <div>
            <div className="PhysicalStockVerification-input-box-container">
              <div>
                <DropdownInputBox labelName={"Raw Material"} />
                <InputBox label={"Quantity"} />
                <DropdownInputBox labelName={"Unit"} />
                <div
                  className="voucher-add"
                  style={{
                    marginBlockEnd: " 0em",
                    marginBlockStart: "1.5em",
                    width: "50%",
                    justifyContent: "flex-start",
                    gap: "10px",
                  }}
                >
                  <button
                    style={{
                      height: "3em",
                      width: "3em",
                      background: "#00b112",
                    }}
                  >
                    <AddIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <TableBox
            isLoading={false}
            headings={heading}
            renderData={renderData}
          />
          <SubmitBtn
            // onClick={handleSubmit}
            containerStyle={{
              justifyContent: "center",
              marginBlockStart: "40px",
            }}
          />
        </div>
      </div>
      {/* manufacturing journal */}
      <div style={{ padding: "3em" }} className="global-white-bg-container">
        <div>
          <strong>Manufacturing Journal</strong>
        </div>
        <hr className="global-hr" />

        <div className="manufacturing-voucher-dashboard">
          <div>
            <InputBox label={"Invoice No"} />
            <InputBox label={"Date"} />
            <InputBox label={"Product Name"} />
            <InputBox label={"Manufacturing Qty"} />
          </div>
          <SubmitBtn
            // onClick={handleSubmit}
            containerStyle={{
              justifyContent: "center",
              marginBlockStart: "40px",
            }}
          />
        </div>
      </div>

      <div style={{ padding: "3em" }} className="global-white-bg-container">
        <TableBox
          isLoading={false}
          headings={heading1}
          renderData={renderData1}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "1em",
            padding: "0 10%",
          }}
        >
          <strong>Raw Material Coast </strong>
          <strong> 39500 </strong>
        </div>
        <div className="manufacturing-journal">
          <div className="PhysicalStockVerification-input-box-container">
            <strong>CO products</strong>

            <TableBox
              isLoading={false}
              headings={heading1}
              renderData={renderData1}
              
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "1em",
                padding: "0 10%",
              }}
            >
              <strong>Total</strong>
              <strong> 39500 </strong>
            </div>
          </div>
        </div>

        <SubmitBtn
          // onClick={handleSubmit}
          containerStyle={{
            justifyContent: "center",
            marginBlockStart: "40px",
          }}
          labelName={"Next"}
        />
      </div>
      {/* additional coast  */}
      <div style={{ padding: "3em" }} className="global-white-bg-container">
        <div>
          <strong>Additional Coast </strong>
        </div>

        <div className="manufacturing-voucher-dashboard">
          <div style={{ alignItems: "center" }}>
            <InputBox label={"Product Name"} />
            <InputBox label={"Product Name"} />
            <InputBox label={"Product Name"} />

            <div
              className="voucher-add"
              style={{
                marginBlockEnd: " 0em",
                marginBlockStart: "1.5em",
                width: "50%",
                justifyContent: "flex-start",
                gap: "10px",
              }}
            >
              <button
              className="add-button"
              >
                <AddIcon />
              </button>
            </div>
          </div>
          <hr className="global-hr" />

          <TableBox
            isLoading={false}
            headings={heading2}
            renderData={renderData}
          />

          <div className="set-profit-input-box   ">
            <div>
              <h4>Effective Cost</h4>
              <input type="number" disabled value={1000} />
            </div>
            <div>
              <h4>Profit</h4>

              <input type="number" className="profit-input" />
            </div>
            <div>
              <h4>Total</h4>

              <input type="number" disabled value={1000} />
            </div>
            <div>
              <h4>Selling Rate</h4>

              <input type="text" disabled value={"1000/unit"} />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
            className="button-container"
          >
            <div></div>
            <div style={{ display: "flex", gap: "10px" }}>
              <button
                style={{
                  background: "#fff",
                  border: "1px solid black",
                  color: "black",
                }}
                className="cancel-btn"
              >
                Cancel
              </button>
              <button
                style={{
                  background: "#0011f8",
                  border: "none",
                  color: "white",
                }}
                className="submit-btn"
              >
                Submit
              </button>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManufacturingVoucherDashboard;
