import {createSlice} from '@reduxjs/toolkit'

export const singleAlterationSlice=createSlice({
    name:"singleAlterationSlice",
    initialState:{
      value:undefined
    },
    reducers:{
        get_single_alteration_list:(state,action)=>{
            state.value=action.payload.singleAlterationList
        }
    }
})
export const {get_single_alteration_list} = singleAlterationSlice.actions
export default singleAlterationSlice.reducer