import { createSlice } from "@reduxjs/toolkit";

export const pendingPaymentVoucherSlice=createSlice({
    name:'pendingPaymentVoucherSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_pending_payment_voucher_list:(state,action)=>{
            state.value=action.payload.pendingPaymentVoucherData
        }
    }
})

export const {update_pending_payment_voucher_list} =pendingPaymentVoucherSlice.actions;
export default pendingPaymentVoucherSlice.reducer;