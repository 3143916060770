import { createSlice } from "@reduxjs/toolkit";

export const generateSalesReturnIdSlice=createSlice({
    name:"generateSalesReturnId",
    initialState:{
        value:undefined
    },
    reducers:{
        get_sales_return_id:(state,action)=>{
            state.value=action.payload.salesReturnId
        }
    }
})
export const {get_sales_return_id} = generateSalesReturnIdSlice.actions;
export default generateSalesReturnIdSlice.reducer;