import {
  Autocomplete,
  Dialog,
  IconButton,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { viewAllProductsAPICall } from "../../../API/Product List/productListAPI";
import {
  viewAllAccessoryCategoryAPICall,
  viewAllMaterialCategoryAPICall,
  viewAllReadymadeCategoriesAPICall,
} from "../../../API/Product Master/ProductMasterAPI";
import {
  convertPointToAmountAPICall,
  deleteRewardsAndPointsAPICall,
  editRewardAndPointsAPICall,
  rewardsAndPointsAPICall,
} from "../../../API/RewardsAndPoints/RewardsAndPointsAPI";
import "../../../css/RewardsAndPoints/rewardsAndPoints.css";
import {
  deleteAlertMessage,
  extractSingleObjectGeneral,
} from "../../../Js/generalFunctions";
import ErrorSnackbar from "../../Single Components/SnackBars/ErrorSnackbar";
import SuccessSnackbar from "../../Single Components/SnackBars/SuccessSnackbar";
import { newProdListForBarcode } from "../GenerateQrOrBarcode/GenearteQrBarcodeAPI";
import { categoryListAPICall } from "../Inventory/Configuration/Category/CategoryAPI";
export const RewardsView = () => {
  const rewardsAndPoints = useSelector(
    (state) => state.rewardsAndPointsSlice.value
  );
  const storeCode = localStorage.getItem("branchId");

  //readymade category list
  const readyMadeCategoryList = useSelector(
    (state) => state.allReadymadeCategories.value
  );
  // All Material Category List
  const allMaterialCatList = useSelector(
    (state) => state.allMaterialCategorySlice.value
  );
  // All Accessory Category List
  const allAccessoryCatList = useSelector(
    (state) => state.allAccessoryCategorySlice.value
  );
  const CategoryList = useSelector((state) => state.categorySlice.value);
  const allProductsList = useSelector(
    (state) => state.GenerateQrBarcodeSlice.apiResList
  );
  const mainCategoryList = [
    {
      category: "Material",
      value: 2,
    },
    {
      category: "Readymade",
      value: 0,
    },
    {
      category: "Accessoires",
      value: 1,
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState("product");
  const [mainCategory, setMainCategory] = useState(2);
  const [productName, setProductName] = useState(null);
  const [isModify, setIsModify] = useState(false);

  // snackbar state
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [snackMsg, setSnackMsg] = useState("ERR_CODE999!");

  // edit reward state
  const [editProduct, setEditProduct] = useState(false);
  const [editCategory, setEditCategory] = useState(false);
  const [prodType, setProdType] = useState(2);

  const rewardEditFormInitialState = {
    productName: null,
    productId: "",
    image: "",
    point: 0,
    amount: 0.0,
    category: null,
  };
  const [rewardEditForm, setRewardEditForm] = useState(
    rewardEditFormInitialState
  );
  const [isEdit, setIsEdit] = useState(false);
  const [editingObj, setEditingObj] = useState();
  //Convert POint to amount api response
  const [pointToAmountRes, setPointToAmountRes] = useState(null);

  const [isEditCat, setIsEditCat] = useState(false);

  // update listner
  const updateListner = () => {
    setIsModify(!isModify);
  };

  //Close Snackbars
  const closeSuccessSnackbar = () => {
    setOpenSuccessSnackbar(false);
  };
  const closeErrorSnackbar = () => {
    setOpenErrorSnackbar(false);
  };

  // api call
  useEffect(() => {
    rewardsAndPointsAPICall({
      isCategory:
        isActive === "product"
          ? false
          : isActive === "category"
          ? true
          : undefined,
    });
  }, [isActive, isModify]);

  useEffect(() => {
    viewAllProductsAPICall({ type: mainCategory, branchId: storeCode });
  }, [mainCategory]);

  const productMenuClick = () => {
    document.getElementById("productMenuBtn").classList.add("active");
    document.getElementById("departmentMenuBtn").classList.remove("active");
    setIsActive("product");
  };

  const departmentMenuClick = () => {
    document.getElementById("productMenuBtn").classList.remove("active");
    document.getElementById("departmentMenuBtn").classList.add("active");
    setIsActive("category");
  };

  // product submit button function


  // delete reward and points
  const deleteRewardsAndPoints = (id) => {
    deleteRewardsAndPointsAPICall(
      id,
      updateListner,
      setOpenSuccessSnackbar,
      setOpenErrorSnackbar,
      setSnackMsg
    );
  };

  // edit rewards ans points

  useEffect(() => {
    if (isEdit === true) {
      viewAllProductsAPICall(
        { type: prodType, branchId: storeCode },
        setIsLoading,
        setOpenErrorSnackbar,
        setSnackMsg
      );
    }
  }, [prodType]);

  // prod type onChange function
  const getSelectedProductType = (e) => {
    const { value, checked } = e.target;
    if (checked) setProdType(value);
  };

  // edit product
  const openEditProdutDialog = (row) => () => {
    console.log(row);
    setEditProduct(true);
    setEditingObj(row);
    setIsEdit(true);
    setProdType(row?.type);
  };

  useEffect(() => {
    if (isEdit === true) {
      let selectedProduct = extractSingleObjectGeneral(
        allProductsList,
        "_id",
        editingObj?.productId
      );
      setRewardEditForm({
        ...rewardEditForm,
        productName: selectedProduct[0],
        point: editingObj?.point,
        amount: editingObj?.amount,
      });
    }
  }, [allProductsList, prodType, editingObj]);

  useEffect(() => {
    setRewardEditForm({
      ...rewardEditForm,
      productId: rewardEditForm?.productName?.productId,
      image: rewardEditForm?.productName?.imageUrl,
    });
  }, [rewardEditForm?.productName]);

  // edit category
  const openEditCategoryDialog = (row) => () => {
    console.log(row);
    setEditCategory(true);
    setEditingObj(row);
    setIsEditCat(true);
    setProdType(row?.type);
  };

  console.log(editingObj);
  useEffect(() => {
    if (isEditCat === true) {
      if (
        allAccessoryCatList !== undefined &&
        allMaterialCatList !== undefined &&
        readyMadeCategoryList !== undefined
      ) {
        let selectedAccCategory = extractSingleObjectGeneral(
          allAccessoryCatList,
          "_id",
          editingObj?.productId
        );
        let selectedMaterialCat = extractSingleObjectGeneral(
          allMaterialCatList,
          "_id",
          editingObj?.productId
        );
        let selectedReadymadeCat = extractSingleObjectGeneral(
          readyMadeCategoryList,
          "_id",
          editingObj?.productId
        );

        if (prodType == 2) {
          if (
            selectedMaterialCat !== null &&
            selectedMaterialCat !== undefined
          ) {
            setRewardEditForm({
              ...rewardEditForm,
              category: selectedMaterialCat[0],
              point: editingObj?.point,
              amount: editingObj?.amount,
            });
          }
        }

        if (prodType == 0) {
          if (
            selectedReadymadeCat !== null &&
            selectedReadymadeCat !== undefined
          ) {
            setRewardEditForm({
              ...rewardEditForm,
              category: selectedReadymadeCat[0],
              point: editingObj?.point,
              amount: editingObj?.amount,
            });
          }
        }

        if (prodType == 1) {
          if (
            selectedAccCategory !== null &&
            selectedAccCategory !== undefined
          ) {
            setRewardEditForm({
              ...rewardEditForm,
              category: selectedAccCategory[0],
              point: editingObj?.point,
              amount: editingObj?.amount,
            });
          }
        }
      }
    }
  }, [
    allMaterialCatList,
    readyMadeCategoryList,
    allAccessoryCatList,
    editingObj,
    prodType,
  ]);

  useEffect(() => {
    newProdListForBarcode({})
    categoryListAPICall();
  }, []);

  useEffect(() => {
    if (isEditCat === true) {
      if (prodType == 2) {
        viewAllMaterialCategoryAPICall(); //material category
      }
      if (prodType == 0) {
        viewAllReadymadeCategoriesAPICall();
      }
      if (prodType == 1) {
        viewAllAccessoryCategoryAPICall(); //accessory category
      }
    }
  }, [prodType]);
  // console.log(rewardEditForm?.category);

  // point to amount conversion
  //Convert Points to Amount from front end by response data
  const calculatePointsByAmount = (point = 0, ratio = 0) => {
    let amount = 0;
    amount = Number(point) * Number(ratio);
    return amount;
  };

  //Set Converted amount and value to state
  const setConvertedAmountAndValueToState = (rType, value, convertedAmount) => {
    if (rType === "product")
      setRewardEditForm({
        ...rewardEditForm,
        point: value,
        amount: convertedAmount,
      });

    if (rType === "category")
      setRewardEditForm({
        ...rewardEditForm,
        point: value,
        amount: convertedAmount,
      });
  };

  const getPointsEntered = (rType) => (e) => {
    const { value } = e.target;
    let convertedAmount = 0;
    let res = convertPointToAmountAPICall();
    res
      .then((res) => {
        setPointToAmountRes(res.data);
        convertedAmount = calculatePointsByAmount(value, res.data?.ratio);
        setConvertedAmountAndValueToState(rType, value, convertedAmount);
      })
      .catch((err) => {
        setPointToAmountRes(null);
        convertedAmount = calculatePointsByAmount(value, 0);
        setConvertedAmountAndValueToState(rType, value, convertedAmount);
        console.error(err.response);
      });
  };

  //edited body to api
  const bodyToApi = {
    _id: editingObj?._id,
    productName:
      isActive === "product"
        ? rewardEditForm?.productName?.productName
        : isActive === "category"
        ? prodType == 2
          ? rewardEditForm?.category?.materialCategoryName
          : prodType == 0
          ? rewardEditForm?.category?.categoryName
          : prodType == 1
          ? rewardEditForm?.category?.accessoryCategory
          : null
        : null,
    productId:
      isActive === "product"
        ? rewardEditForm?.productName?._id
        : isActive === "category"
        ? rewardEditForm?.category?._id
        : null,
    itemType: Number(prodType),
    point: Number(rewardEditForm?.point),
    amount: rewardEditForm?.amount,
    isCategory:
      isActive === "product" ? false : isActive === "category" ? true : null,
  };

  const clickEditSubmitBtnFn = () => {
    // console.log(bodyToApi);
    editRewardAndPointsAPICall(
      bodyToApi,
      updateListner,
      setOpenErrorSnackbar,
      setOpenSuccessSnackbar,
      setSnackMsg,
      setEditProduct,
      setEditCategory
    );
  };

  return (
    <>
      <div className="global-page-parent-container">
        <p className="breadcrumb">Rewards & Points &gt; Rewards View</p>
        <div className="offer-listed-container">
          <div className="offer-listed-container-top">
            <h3 style={{ margin: "1% 0%" }}>Reward List</h3>
          </div>
          <div className="rewards-add-rewards-ctn-top-menu">
            <div
              className="tab-menu-button active"
              id="productMenuBtn"
              onClick={productMenuClick}
            >
              <Typography fontSize={"0.9rem"} fontWeight={600}>
                PRODUCT
              </Typography>
            </div>
            <div
              className="tab-menu-button"
              id="departmentMenuBtn"
              onClick={departmentMenuClick}
            >
              <Typography fontSize={"0.9rem"} fontWeight={600}>
                CATEGORY
              </Typography>
            </div>
          </div>

          {isActive === "product" ? (
            <>
              <div className="rewards-points-filter-container">
             
               
                <div className="global-single-input auto-complete">
                  <p>Product Name</p>
                  <Autocomplete
                    sx={{ width: "100%" }}
                    options={allProductsList || []}
                    getOptionLabel={(option) => option?.productName}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Product" />
                    )}
                    onChange={(e, newValue) => rewardsAndPointsAPICall({
                      isCategory:false,
                      productId: newValue?._id,
                    })}
                  />
                </div>
                {/* <button
                  onClick={productSubmitbtnFn}
                  className="btn btn-primary"
                  style={{ margin: "4% 2% 2% 2%" }}
                >
                  Submit
                </button> */}
              </div>
              <div className="global-table-container" style={{ margin: 0 }}>
                <table className="global-table">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Product ID</th>
                      <th>Product Name</th>
                      <th>Image</th>
                      <th>Point</th>
                      <th>Amount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rewardsAndPoints
                      ?.slice(0)
                      ?.reverse()
                      ?.map((r, i) => (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{r?.prodCode}</td>
                          <td>{r?.productName}</td>
                          <td>
                            <img
                              src={r?.imageUrl}
                              alt=""
                              style={{
                                width: "50px",
                                height: "50px",
                                marginTop: "6px",
                              }}
                            />
                          </td>
                          <td>{r?.point}</td>
                          <td>{r?.amount}</td>
                          <td>
                            <IconButton onClick={openEditProdutDialog(r)}>
                              <i class="bi bi-pencil-square edit-icon"></i>
                            </IconButton>
                            <IconButton
                              onClick={deleteAlertMessage(
                                r?._id,
                                deleteRewardsAndPoints
                              )}
                            >
                              <i class="bi bi-trash3 delete-icon"></i>
                            </IconButton>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : isActive === "category" ? (
            <>
              <div className="rewards-points-filter-container">
              <div className="global-single-input auto-complete">
                  <p>Main Category</p>
                  <Autocomplete
                    sx={{ width: "100%" }}
                    options={CategoryList || []}
                    getOptionLabel={(option) => option?.categoryName}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Category" />
                    )}
                    onChange={(e, newValue) => {
                      rewardsAndPointsAPICall({
                        isCategory:true,
                        productId: newValue?._id,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="global-table-container" style={{ margin: 0 }}>
                <table className="global-table">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Category</th>
                      <th>Point</th>
                      <th>Amount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rewardsAndPoints
                      ?.slice(0)
                      ?.reverse()
                      ?.map((r, i) => (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{r?.productName}</td>
                          <td>{r?.point}</td>
                          <td>{r?.amount}</td>
                          <td>
                            <IconButton onClick={openEditCategoryDialog(r)}>
                              <i class="bi bi-pencil-square edit-icon"></i>
                            </IconButton>

                            <IconButton
                              onClick={deleteAlertMessage(
                                r?._id,
                                deleteRewardsAndPoints
                              )}
                            >
                              <i class="bi bi-trash3 delete-icon"></i>
                            </IconButton>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : undefined}

          {/* edit product dialog */}
          <Dialog open={editProduct} maxWidth="lg">
            <div
              className="rewards-add-rewards-ctn-body"
              style={{ padding: "2% 3%" }}
            >
              <h4>Edit Rewards</h4>
              <hr className="global-hr" />
              <div className="rewards-add-rewards-ctn-body-section">
                <div className="rewards-add-rewards-ctn-body-section-ctn">
                  <div className="add-reward-type-radio-container">
                    
                  </div>
                  <div className="global-single-input add-reward-product-input auto-complete">
                    <p>Product Name</p>
                    <Autocomplete
                      sx={{ width: "100%" }}
                      options={allProductsList || []}
                      getOptionLabel={(option) => option?.productName}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Select product.." />
                      )}
                      value={rewardEditForm?.productName}
                      onChange={(e, newValue) =>
                        setRewardEditForm({
                          ...rewardEditForm,
                          productName: newValue,
                        })
                      }
                    />

                    {/* <Select
                        value={selected}
                        onChange={handleChangeDepartment}
                        style={{ fontSize: "0.8rem" }}
                      >
                        <MenuItem value="one" disabled="disabled">
                          Select Product
                        </MenuItem>
                        <MenuItem value="two">23</MenuItem>
                      </Select> */}
                  </div>
                  <div className="global-single-input add-employee-input">
                    <p>Image Preview</p>
                    <img alt="" src={rewardEditForm?.image} />
                    {/* <FilePicker
                        id="staffImage1"
                        uploadImageUrl={rewardImage}
                        setImage={setRewardImage}
                        onChange={(e) => setRewardImage(e.target.files[0])}
                      /> */}
                  </div>
                </div>
                <div className="rewards-add-rewards-ctn-body-section-ctn">
                  <div className="global-single-input">
                    <p>Product ID</p>
                    <input
                      type="text"
                      placeholder="S12D23"
                      readOnly
                      value={rewardEditForm?.productId}
                    />
                  </div>

                  <div className="rewards-add-rewards-ctn-body-section-right">
                    <div className="global-single-input">
                      <p>Point</p>
                      <input
                        type="text"
                        value={rewardEditForm?.point}
                        onChange={getPointsEntered("product")}
                      />
                    </div>
                    <div className="global-single-input">
                      <p>Amount</p>
                      <div className="add-reward-amount-input">
                        <input
                          type="text"
                          readOnly
                          value={rewardEditForm?.amount}
                        />
                        {/* <Select
                            value={selectedAmount}
                            onChange={handleChangeAmount}
                            style={{ width: "55%", fontSize: "0.8rem" }}
                          >
                            <MenuItem value="one">Rs</MenuItem>
                            <MenuItem value="two">$</MenuItem>
                          </Select> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="rewards-add-rewards-ctn-body-button"
                style={{ alignSelf: "center" }}
              >
                <button
                  className="btn btn-secondary-outlined"
                  onClick={() => setEditProduct(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  onClick={clickEditSubmitBtnFn}
                >
                  Submit
                </button>
              </div>
            </div>
          </Dialog>

          {/* edit category dialog */}
          <Dialog open={editCategory} maxWidth="lg">
            <div className="edit-reward-and-points-container">
              <h4>Edit Rewards</h4>
              <hr className="global-hr" />
              <div className="edit-reward-and-points-bottom-container">
                <div
                  className="add-reward-type-radio-container"
                  style={{ width: "61%" }}
                >

                </div>
                <div className="edit-reward-input-container">
                  <div className="global-single-input auto-complete edit-reward-input">
                    <p>Category</p>
                    <Autocomplete
                      sx={{ width: "100%" }}
                      options={
                        CategoryList || []
                      }
                      getOptionLabel={(option)=>option?.categoryName}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select category..."
                        />
                      )}
                      value={rewardEditForm?.category}
                      onChange={(e, newValue) =>
                        setRewardEditForm({
                          ...rewardEditForm,
                          category: newValue,
                        })
                      }
                    />
                  </div>
                  <div className="global-single-input edit-reward-input">
                    <p>Point</p>
                    <input
                      type="text"
                      value={rewardEditForm?.point}
                      onChange={getPointsEntered("category")}
                    />
                  </div>
                  <div className="global-single-input edit-reward-input">
                    <p>Amount</p>
                    <input type="text" value={rewardEditForm?.amount} />
                  </div>
                </div>
                <div className="edit-reward-button-container">
                  <button
                    onClick={() => setEditCategory(false)}
                    className="btn btn-secondary-outlined"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={clickEditSubmitBtnFn}
                    className="btn btn-primary"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
      {/* snackbars */}
      <SuccessSnackbar
        open={openSuccessSnackbar}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={openErrorSnackbar}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </>
  );
};
