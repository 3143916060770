import { createSlice } from "@reduxjs/toolkit";

export const allLeaveApplicationSlice =createSlice({
    name:'allLeaveApplicationSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_all_leave_application_list:(state,action)=>{
            state.value=action.payload.allLeaveApplication
        }
    }
})

export const{update_all_leave_application_list}=allLeaveApplicationSlice.actions
export default allLeaveApplicationSlice.reducer