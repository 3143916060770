import { createSlice } from "@reduxjs/toolkit";

export const contractSlice = createSlice({
name:"contractSlice",
initialState:{
    contractList:undefined,
    contractTypeList:undefined,
    sponserList:undefined,
    sponserSingle:undefined,
    contractSingle:undefined
},
reducers:{
    //contract
    get_contract_list:(state, action)=>{
        state.contractList = action.payload
    },
    get_contract_single_id:(state, action)=>{
        state.contractSingle = action.payload
    },

    //contract type
    get_contract_type_list:(state, action)=>{
        state.contractTypeList = action.payload
    },

    //sponser
     get_sponser_list:(state, action)=>{
        state.sponserList = action.payload
    },
    get_sponser_id:(state, action)=>{
        state.sponserSingle = action.payload
    },
}

});
export const {
    get_contract_list,get_contract_type_list,get_sponser_list,get_sponser_id,get_contract_single_id
} = contractSlice.actions;

export default contractSlice.reducer;