import { createSlice } from "@reduxjs/toolkit";

export const purchaseReportNewSlice = createSlice({
    name:"purchaseReportNewSlice",
    initialState:{
    purhaseReportNwList:undefined
},
reducers:{
    get_purchase_report_list:(state, action) =>{
        state.purhaseReportNwList = action.payload.purchaseReportNwData;
    },
    get_purchase_report_single_list:(state, action) =>{
        state.purhaseReportNwSingleList = action.payload.purchaseReportNwSingleData;
    }
}
});
export const {get_purchase_report_list,get_purchase_report_single_list}=purchaseReportNewSlice.actions;
export default purchaseReportNewSlice.reducer;