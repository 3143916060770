import React,{useState,useRef} from 'react'
import {Select,IconButton,MenuItem,Dialog, Autocomplete, TextField, Skeleton} from '@mui/material'
import ReactToPrint from "react-to-print";
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { purchaseWoPoAPICall } from '../../../../API/Report/purchaseWoPoAPI';
import { convertDateFormat, getCurrentTime, today } from '../../../../Js/Date';
import { allPurchaseWopoIdAPICall } from '../../../../API/Purchase Manager/purchaseWOPOAPI';
import { viewAllBranchesAPICall } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { sortGeneral } from '../../../../Js/generalFunctions';
export const PurchaseWOReport = () => {
    let purchaseWOReportPrint=useRef()
// purchase wopo report list
const purchaseWoPo=useSelector((state)=>state.purchaseWoPoSlice.value)
// all branch list
 const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
// const all purchse wopo id list
const allPurchsewopoId=useSelector((state)=>state.purchasewopoIdSlice.value)
// role based rendering
const userRole = useSelector((state) => state.userRoleSlice.value);
// login response
const loginResponse = useSelector((state) => state.loginResponseSlice.value);


  const [purchaseWOReportView,setPurchseWOReportView]=useState(false)
  const [singleViewId,setSingleViewId]=useState(null)
  const [singleViewData,setSingleViewData]=useState([])


  // filter state
  const [branch,setBranch]=useState(null)
  const [transNo,setTransNo]=useState(null)
  const [fromDate,setFromDate]=useState('')
  const [toDate,setToDate]=useState('')
  const [time, setTime] = useState(null);
  const [isLoading,setIsLoading]=useState(false)
  const [isAscending,setIsAscending]=useState(false)
  // map response to state 
  const [purchseWopoFilte,setPurchaseWopoFilter]=useState([])
  const [branchWithAll,setBranchWithAll]=useState([])
    
  
  // get current time fn
  useEffect(() => {
    setInterval(() => {
      setTime(`${new Date().getHours()}:${new Date().getMinutes()}`);
    }, 1000);
  }, [time]);

  // onChange functions
  const getFromDate=(e)=>{
    setFromDate(e.target.value)
  }
  const getToDate=(e)=>{
    setToDate(e.target.value)
  }

  
  useEffect(()=>{
    purchaseWoPoAPICall({},setIsLoading)
    
    viewAllBranchesAPICall()
  },[])

  useEffect(()=>{
    if(userRole==="admin"){
      allPurchaseWopoIdAPICall({branchId:branch})
    }
    else{
      allPurchaseWopoIdAPICall({branchId:loginResponse?.branchId})
    }
  },[branch])

  const purchseWoPoSingleView=(id)=>{
    setPurchseWOReportView(true)
    setSingleViewId(id)

  }
  useEffect(()=>{
    let filteredData=[]
    filteredData=purchaseWoPo?.filter((obj)=>{
      return obj._id===singleViewId
    })
    setSingleViewData(filteredData)
  },[singleViewId])


  // add all into branch list 
  useEffect(()=>{
    if(allBranchesList !== undefined){
      let branchList=allBranchesList?.map((obj)=>{
        return obj
      })
      setBranchWithAll([{storeCode:"-All",branchName:""},...branchList])
    }
  },[allBranchesList])


  // submit button function
  const purchsewopoSubmitBtnFn=()=>{
    purchaseWoPoAPICall({
      branchId:branch==="-All" ? null :branch,
      transNo:transNo,
      fromDate:fromDate !== "" ? `${fromDate} 00:00:00`:null,
      toDate:toDate !=="" ?`${toDate} 23:59:59`:null
    },setIsLoading)
  }

  // map purchse wopo response to state
  useEffect(()=>{
    
      setPurchaseWopoFilter(purchaseWoPo?.map(obj=>{return {...obj}}))
    
  },[purchaseWoPo])
 


  // date sorting
  const sortDateAscending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(purchseWopoFilte, "Date", "date", true);
    setPurchaseWopoFilter(sorted)
  }
  
  const sortDateDescending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(purchseWopoFilte, "Date", "date", false);
    setPurchaseWopoFilter(sorted)
  }

  /// supplier sorting

  const sortSupplierAscending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(purchseWopoFilte, "String", "supplierName", true);
    setPurchaseWopoFilter(sorted)
  }
  
  const sortSupplierDescending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(purchseWopoFilte, "String", "supplierName", false);
    setPurchaseWopoFilter(sorted)
  }

  // received by sorting
  const sortReceivedByAscending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(purchseWopoFilte, "String", "recievedBy", true);
    setPurchaseWopoFilter(sorted)
  }
  
  const sortReceivedByDescending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(purchseWopoFilte, "String", "recievedBy", false);
    setPurchaseWopoFilter(sorted)
  }
   // branch code sorting
   const sortBranchCodeAscending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(purchseWopoFilte, "String", "branch", true);
    setPurchaseWopoFilter(sorted)
  }
  const sortBranchCodeDescending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(purchseWopoFilte, "String", "branch", false);
    setPurchaseWopoFilter(sorted)
  }
 // branch name sorting
 const sortBranchNameAscending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(purchseWopoFilte, "String", "branchName", true);
  setPurchaseWopoFilter(sorted)
}
const sortBranchNameDescending=()=>{
  setIsAscending(!isAscending)
  let sorted = sortGeneral(purchseWopoFilte, "String", "branchName", false);
  setPurchaseWopoFilter(sorted)
}
  

  return (
    <div className="global-page-parent-container">
    <p className="breadcrumb">Report &gt; Purchase W/0 Report</p>
    <div className="daily-report-top-container">
      <h3>Purchase W/O Report</h3>
      <hr className="global-hr"/>
      <div className="daily-report-top-input-container" >
        {
          userRole === "admin" &&
          <div className="global-single-input auto-complete">
          <p>Branch</p>
          <Autocomplete
            sx={{width:'100%'}}
            options={branchWithAll||[""]}
            getOptionLabel={(option)=>`${option?.storeCode}-${option?.branchName}`}
            renderInput={(params)=>
              <TextField  {...params}
               placeholder="Select Branch"
              />
            }
            onChange={(e,newValue)=>setBranch(newValue?.storeCode)}
          />
         </div>
        }
      
       <div className="global-single-input auto-complete">
        <p>Transaction ID </p>
        <Autocomplete
          sx={{width:'100%'}}
          options={allPurchsewopoId||[""]}
          getOptionLabel={(option)=>option?.invoiceNo}
          renderInput={(params)=>
            <TextField  {...params}
             placeholder="Select Transaction ID"
            />
          }
          onChange={(e,newValue)=>setTransNo(newValue?.transNo)}
        />
       </div>
       <div className="global-single-input">
        <p>From</p>
        <input type="date" onChange={getFromDate}/>
       </div>
       <div className="global-single-input" onChange={getToDate}>
        <p>To</p>
        <input type="date"/>
       </div>
       <button onClick={purchsewopoSubmitBtnFn} className="btn btn-primary report-submit-btn">Submit</button>
      
      </div>
     
    </div>
    <div className="global-white-bg-container">
    
     <div className="global-table-container">
        <table className="global-table">
           <thead>
             <tr>
              <th>No</th>
              {
                userRole==="admin" &&
                <>
                 <th>
                  Branch Code
                  {
                isAscending ?(
                  <IconButton
                    onClick={sortBranchCodeAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortBranchCodeDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
                </th>
                 <th>
                  Branch Name
                  {
                isAscending ?(
                  <IconButton
                    onClick={sortBranchNameAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortBranchNameDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }  
                </th>
                </>
              }
              
              <th>Transaction ID </th>
              <th>Sales Invoice No</th>
              <th>
                Date
                {
                isAscending ?(
                  <IconButton
                    onClick={sortDateAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                    onClick={sortDateDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
              </th>
              <th>
                Supplier
                {
                isAscending ?(
                  <IconButton
                    onClick={sortSupplierAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                    onClick={sortSupplierDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
              </th>
              <th>
                Received By
                {
                isAscending ?(
                  <IconButton
                    onClick={sortReceivedByAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                    onClick={sortReceivedByDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
              </th>
              <th>view</th>
             </tr>
           </thead>
           {
            isLoading ? (
              <tbody>
              <tr>
                   <td colSpan={10}>
                     <Skeleton
                       variant="rectangular"
                       animation="wave"
                       width={"100%"}
                       height={30}
                     />
                   </td>
                 </tr>
                 <tr>
                   <td colSpan={10}>
                     <Skeleton
                       variant="rectangular"
                       animation="wave"
                       width={"100%"}
                       height={30}
                     />
                   </td>
                 </tr>
                 <tr>
                   <td colSpan={10}>
                     <Skeleton
                       variant="rectangular"
                       animation="wave"
                       width={"100%"}
                       height={30}
                     />
                   </td>
                 </tr>
           </tbody>
            ) : (
          <tbody>
            {
              purchseWopoFilte?.map((r,i)=>(
                <tr>
                <td>{i+1}</td>
                {
                  userRole==='admin'&&
                  <>
                   <td>{r?.branch}</td>
                   <td>{r?.branchName}</td>
                  </>
                }
               
                <td>{r?.transNo}</td>
                <td>{r?.saleInvNo}</td>
                <td>{convertDateFormat(r?.date)}</td>
                <td>{r?.supplierName}</td>
                <td>{r?.recievedBy}</td>
                <td>
                   <IconButton onClick={()=>purchseWoPoSingleView(r?._id)}>
                     <i class="bi bi-eye visibility-icon"></i>
                   </IconButton>
                </td>
              </tr>
              ))
            }
           
           </tbody>
            )
           }
           
        </table>
     </div>
    </div>

    {/* purchase wo report single view */}
    <Dialog open={purchaseWOReportView} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setPurchseWOReportView(false)}>
    <div className="po-single-view-parent-container">
        <div className="top-bar" style={{display:"flex",justifyContent:"space-between"}}>
          <p>View</p>
          <div className="stock-report-print-download-icon-container">
         <ReactToPrint
        trigger={()=>( 
            <IconButton className="print-icon-container">
              <i class="bi bi-printer printer-icon"></i>
            </IconButton>
         )} 
      content={()=>purchaseWOReportPrint} 
      pageStyle="@page { size: auto;  margin: 15mm 0 10mm 0; } }"
       />   
          <IconButton className="download-icon-container">
            <i class="bi bi-arrow-down-circle download-icon"></i>
           </IconButton>
        </div>
        </div>
        <hr />
        <div className="form-1-container">
          <div className="global-single-input po-input">
              <p>Transaction ID</p>
              <input type="text" disabled value={singleViewData !== undefined && singleViewData[0]?.transNo}/>
            </div>
            <div className="global-single-input po-input">
              <p>Sales Invoice No</p>
              <input type="text" disabled value={singleViewData !== undefined && singleViewData[0]?.saleInvNo}/>
            </div>
            <div className="global-single-input po-input">
              <p>Branch</p>
              <input type="text" disabled value={singleViewData !== undefined && singleViewData[0]?.branch}/>
            </div>
            <div className="global-single-input po-input">
              <p>Date</p>
              <input type="text" disabled value={singleViewData !== undefined && singleViewData[0]?.date}/>
            </div>
            <div className="global-single-input po-input">
              <p>Supplier</p>
              <input type="text" disabled value={singleViewData !== undefined && singleViewData[0]?.supplierName}/>
            </div>
            <div className="global-single-input po-input">
              <p>Received By</p>
              <input type="text" disabled value={singleViewData !== undefined && singleViewData[0]?.recievedBy}/>
            </div>
        </div>
        <div className="po-single-view-table-container global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Product Code</th>
                  <th>Product Name</th>
                  <th>Size/Unit</th>
                  <th>Qty</th>
                  <th>Rate</th>
                  <th>Amount</th>
                 
                </tr>
              </thead>
              <tbody>
                {
                  singleViewData !== undefined && singleViewData[0]?.purchaseInfo?.map((r,i)=>(
                   <tr>
                     <td>{i+1}</td>
                     <td>{r?.productCode}</td>
                     <td>{r?.productName}</td>
                     <td>{r?.dimension}</td>
                     <td>{r?.quantity}</td>
                     <td>{r?.rate}</td>
                     <td>{parseFloat(r?.quantity)*parseFloat(r?.rate)}</td>
                   </tr>
                  ))
                }
              </tbody>
            
            </table>
          </div>
          <div className="cancel-btn-container">
            <button 
            onClick={()=> setPurchseWOReportView(false)}
             className="btn btn-secondary-outlined">Close</button>
          </div>
      </div>



   
    </Dialog>

    {/* purchase wo report print */}
    <div 
       style={{display:'none'}}
     >
       <div ref={(el)=>(purchaseWOReportPrint = el)}>
          <div className='print-view-top-container'>
            <div className='print-view-top-left-container' style={{width:'30%'}}>
                <h6 style={{margin:"0"}}>Printed On:{convertDateFormat(today)}&nbsp;{getCurrentTime()}</h6>
                <p>Trans ID&nbsp;:&nbsp;{singleViewData !== undefined && singleViewData[0]?.transNo}</p>
                <p>Sales Invoice No&nbsp;-&nbsp;{singleViewData !== undefined && singleViewData[0]?.saleInvNo}</p>
            </div>
            <div className='print-view-top-middle-container' style={{width:'40%'}}>
                <h3>TEXTILE NAME</h3>
                 <p>PURCHASE W/O REPORT</p>
            </div>
            <div className='print-view-top-right-container' style={{width:'30%'}}>
               
            </div>
          </div>
          <hr  className="print-report-hr"/>
          <div className='print-view-top-container' style={{padding: "0 6% 2% 6%"}}>
            <div className='print-view-top-left-container' style={{width:'40%'}}>
                <p>Supplier&nbsp;:&nbsp;{singleViewData !== undefined && singleViewData[0]?.supplierName}</p>
                <p>Date&nbsp;:&nbsp;{singleViewData !== undefined && convertDateFormat(singleViewData[0]?.date)}</p>
            </div>
            <div className='print-view-top-middle-container' style={{width:'20%'}}>
             
            </div>
            <div className='print-view-top-right-container' style={{width:'40%'}}>
                <p>Location&nbsp;:&nbsp;{singleViewData !== undefined && singleViewData[0]?.branch}</p>
                <p>Received&nbsp;:&nbsp;{singleViewData !== undefined && singleViewData[0]?.recievedBy}</p>
            </div>
          </div>
          <div className='print-view-table-container report-print-view-table-with-clr'>
          <table>
          <thead>
            <tr>
              <th>No</th>
              <th>Product Code</th>
              <th>Product Name</th>
              <th>Unit</th>
              <th>Qty</th>
              <th>Rate</th>
              <th>Amount</th>
            </tr>
           </thead>
           <tbody>
                {
                  singleViewData !== undefined && singleViewData[0]?.purchaseInfo?.map((r,i)=>(
                   <tr>
                     <td>{i+1}</td>
                     <td>{r?.productCode}</td>
                     <td>{r?.productName}</td>
                     <td>{r?.dimension}</td>
                     <td>{r?.quantity}</td>
                     <td>{r?.rate}</td>
                     <td>{parseFloat(r?.quantity)*parseFloat(r?.rate)}</td>
                   </tr>
                  ))
                }
              </tbody>
        </table>
        
          </div>
     
       </div>
     </div>
    </div>
  )
}
