import { createSlice } from "@reduxjs/toolkit";
 
export const ShiftReportSlice =createSlice({
    name:"shiftReport",
    initialState:{
        shiftReportList:undefined,
        shiftReportSingleList:undefined
    },
    reducers:{
        shift_report_list:(state,action)=>{
            state.shiftReportList=action.payload.shiftReportListData
        },
        shift_report_single_list:(state,action)=>{
            state.shiftReportSingleList=action.payload.shiftReportSingleListData
        },
    }
})

export const {shift_report_list,shift_report_single_list}=ShiftReportSlice.actions
export default ShiftReportSlice.reducer;