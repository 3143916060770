import axios from "axios"
import { HEADERS } from "../../../../API/UrlAndPaths"
import store from "../../../../Redux/store"
import { get_payTemplate_list } from "./configurationSlice"

//list contarct
export const ListConfigurationAPI = async () => {
    await axios.post("employee/viewPayTemplateList", {}, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(get_payTemplate_list(res.data))
            }
        })
        .catch((err) => {
            store.dispatch(get_payTemplate_list(undefined))
        })
}