import { createSlice } from "@reduxjs/toolkit";

export const vendorBillSlice = createSlice({
  name: "vendorBillSlice",
  initialState: {
    apiResList: undefined,
    rowData: undefined,
    generateName: undefined,
    productsList: undefined,
    NewProductList: undefined,
    PoVendorBill: undefined,
  },
  reducers: {
    get_vendor_bill_api_res_list: (state, action) => {
      state.apiResList = action.payload.vendorBillData;
    },
    get_vendor_bill_row_data_list: (state, action) => {
      state.rowData = action.payload.vendorBillSingleRowData;
      // state.rowData = action.payload.vendorBillSingleData;
    },
    generate_vendor_bill_name: (state, action) => {
      state.generateName = action.payload.name;
    },
    update_all_products_list_accounts: (state, action) => {
      state.productsList = action.payload.productForAccountsData;
    },
    updateNewProductList: (state, action) => {
      state.NewProductList = action.payload.NewProductForAccountsData;
    },
    PoVendorBill: (state, action) => {
      state.PoVendorList = action.payload.purchaseVendorList;
    },
  },
});

export const {
  updateNewProductList,
  get_vendor_bill_api_res_list,
  get_vendor_bill_row_data_list,
  generate_vendor_bill_name,
  update_all_products_list_accounts,
  PoVendorBill,
} = vendorBillSlice.actions;

export default vendorBillSlice.reducer;
