import { createSlice } from "@reduxjs/toolkit";

export const draftedStockAdjSlice=createSlice({
    name:'draftedStockAdjSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_draftedStockAdj_list:(state,action)=>{
            state.value=action.payload.draftedStockAdjList
        }
    }
})

export const {update_draftedStockAdj_list}=draftedStockAdjSlice.actions
export default draftedStockAdjSlice.reducer