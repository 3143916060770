import React, { useRef } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { convertArrayToExcel, filterObjFromList } from '../../../../../Js/generalFunctions';
import { viewAllCurrencyUpdated } from '../../../../../API/Settings/Currency/CurrencyAPI';
import { companyProfileInfoForUser } from '../../../../../API/Settings/Company Settings/companySettingsAPI';
import { AccountPayableSingleAPIcall } from './partnerReportApi';
import Template from "./AccountSingleViewPrint"
import { IconButton, Tooltip } from '@mui/material';
import ReactToPrint from 'react-to-print';

const AccountPayableSingleView = () => {
  let paymentsPrintNew = useRef();
  let navigate = useNavigate();
  const [symbol, setSymbol] = useState(null);

  const decimalPosition = localStorage.getItem("decimalPosition");
  const vendor = localStorage.getItem("vendor")
  const singleViewId = localStorage.getItem("singleViewById")
  const StartDate = localStorage.getItem("fromDate");
  const EndDate = localStorage.getItem("toDate");

  const singleView = useSelector((state) => state?.PartnerReport?.AccountPayableSingleView)

  console.log(singleView);
  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );

  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );

  const initialvalue ={
    totalBalance:0,
    totalCredit:0,
    totalDebit:0,
    label:"Account Payable Report",

    companyLogo: "",
    companyName: "",
    companyAddress: "",
    companyContact: "",
    }
    const [data,setData]=useState([])
    const [formValues,setFormValues]=useState(initialvalue)

    useEffect(()=>{
      setFormValues({...formValues,
        totalBalance:singleView?.totalBalance || 0,
        totalCredit:singleView?.totalCredit || 0,
        totalDebit:singleView?.totalDebit || 0,
    
        companyLogo: companyProfileData?.logo || "",
        companyName: companyProfileData?.companyName || "",
        companyAddress: companyProfileData?.companyAddress || "",
        companyContact: companyProfileData?.contactNumber || "",
    
      });
      setData(singleView?.entries)
    },[singleView,companyProfileData])

    const downloadList=()=>{
      convertArrayToExcel(singleView?.entries,"payableReport")
    }

  const handleback = () => {

    navigate("/userdashboard/accounts/report/partnerReport/accountPayable")
  }
  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    console.log(currencyObj);
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);

  useEffect(() => {

    AccountPayableSingleAPIcall({
      startDate: StartDate,
      endDate: EndDate,
      id: singleViewId,


    })

  }, [StartDate, EndDate, singleViewId])


  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, []);
  return (
    <div className="global-page-parent-container" style={{ margin: "0" }}>
      <div className="global-white-bg-container">
        <div style={{ paddingBottom: "30px",display:"flex" }}>
          <div className="sharp-arrow" onClick={handleback}> <p>Account Payable Report</p></div>
          <div className="sharp-arrow" style={{ marginLeft: "18px", width: "auto" }}> <p>{vendor}</p></div>
          <div style={{paddingInline:"15px",marginTop:"-13px"}}>
          <Tooltip title="Share">
          <IconButton>
            <i class="bi bi-share" style={{ backgroundColor: "#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>

        <ReactToPrint
          trigger={() => (
            <IconButton className="printer-div" style={{ padding: "1px" }}>
              <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
            </IconButton>
          )}
          content={() => paymentsPrintNew}
        />

        <Tooltip title="Download">
          <IconButton onClick={downloadList}>
            <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>

        </div>
       </div>

        <div className='global-report-table-container' style={{ maxHeight: "80vh" }}>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Journal</th>
                <th>Referance</th>
                <th>Debit</th>
                <th>Credit</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
              {singleView?.entries?.map((item, index) => (
                <tr key={index} style={{ height: "38px" }}>
                  <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.date}</td>
                  <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.name}</td>
                  <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.journalNo}</td>
                  <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.reference}</td>
                  <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.debit?.toFixed(decimalPosition)}</td>
                  <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.credit?.toFixed(decimalPosition)}</td>
                  <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.balance?.toFixed(decimalPosition)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th colSpan="4">Total</th>
                <th>{symbol}&nbsp;{singleView?.totalDebit?.toFixed(decimalPosition)}</th>
                <th>{symbol}&nbsp;{singleView?.totalCredit?.toFixed(decimalPosition)}</th>
                <th>{symbol}&nbsp;{singleView?.balanceTotal?.toFixed(decimalPosition)}</th>
              </tr>
            </tfoot>
          </table>

        </div>
      </div>
      <div style={{ display: "none" }}>
  <div ref={(el) => (paymentsPrintNew = el)}>
      <Template  data={data} formValues={formValues}/> 
  </div>
</div>
    </div>

  )
}

export default AccountPayableSingleView
