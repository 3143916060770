///All functions related to work order user

import { makeCuttingSlipSingleViewExtensible } from "./newModelCuttingSlip";

//Get Measurements of products from work order single view response
//used in edit work order
export const getProductMeasurements = (item = {}, model = "thob") => {
  //Extract each model measurement and assign to a variable
  //return that variable
  let measurements = {};
  const { type } = item;

  if (type === model) {
    switch (model) {
      case "thob":
        //Extract thob measurements and return
        measurements = {
          thobModel: item?.thobModel,
          length: item?.length,
          shoulder: item?.shoulder,
          sleeve: item?.sleeve,
          chest: item?.chest,
          stomach: item?.stomach,
          slChest: item?.slChest,
          slSo: item?.slSo,
          neck: item?.neck,
          bottom: item?.bottom,
          marSize: item?.marSize,
          marModel: item?.marModel,
          cuff: item?.cuff,
          cuffSize1: item?.cuffSize1,
          cuffSize2: item?.cuffSize2,
          cuffCloth: item?.cuffCloth,
          remark: item?.remark,
          cuffModel: item?.cuffModel,
          collar: item?.collar,
          collarSize: item?.collarSize,
          collarCloth: item?.collarCloth,
          collarModel: item?.collarModel,
          regalSize: item?.regalSize,
          kneeLoose: item?.kneeLoose,
          fpDown: item?.fpDown,
          fpModel: item?.fpModel,
          fpSize1: item?.fpSize1,
          fpSize2: item?.fpSize2,
          pen: item?.pen,
          sidePtModel: item?.sidePtModel,
          stitching: item?.stitching,
          button: item?.button,
          neckDButton: item?.neckDButton,
          sidePTSize: item?.sidePTSize,
          buttonNo: item?.buttonNo,
          mobilePocket: item?.mobilePocket,
          down:item?.down,
          veedi:item?.veedi,
          bag:item?.bag,
          open:item?.open,
        };
        break;
      case "jalabia":
        //Extract jalabia measurements and return
        measurements = {
          jalabiaModel: item?.jalabiaModel,
          length: item?.length,
          shoulder: item?.shoulder,
          sleeve: item?.sleeve,
          chest: item?.chest,
          stomach: item?.stomach,
          slChest: item?.slChest,
          slSto: item?.slSto,
          neck: item?.neck,
          bottom: item?.bottom,
          sleeveSize: item?.sleeveSize,
          neckModel: item?.neckModel,
          stitching: item?.stitching,
          remark: item?.remark,
          regalSize: item?.regalSize,
          neckDownButton: item?.neckDownButton,
          mar: item?.mar,
          model: item?.model,
          fpModel: item?.fpModel,
          fpSize1: item?.fpSize1,
          fpSize2: item?.fpSize2,
        };
        break;
      case "salwar":
        //Extract salwar measurements and return
        measurements = {
          length: item?.length,
          waist: item?.waist,
          bottom: item?.bottom,
          elasticSize: item?.elasticSize,
          elasticWidth: item?.elasticWidth,
          ply: item?.ply,
          thighLoose: item?.thighLoose,
          remarks: item?.remark,
        };
        break;
      case "abaya":
        //Extract abaya measurements and return
        measurements = {
          sleeveFromNeck: item?.sleeveFromNeck,
          sleeveFromShoulder: item?.sleeveFromShoulder,
          sleeveWidth: item?.sleeveWidth,
          arms: item?.arms,
          bust: item?.bust,
          waist: item?.waist,
          hip: item?.hip,
          length: item?.length,
          remarks: item?.remarks,
        };
        break;
      case "churidhar":
        //Extract churidhar measurements and return
        measurements = {
          shoulder: item?.shoulder,
          frontNeck: item?.frontNeck,
          backNeck: item?.backNeck,
          waist: item?.waist,
          hip: item?.hip,
          fullLength: item?.fullLength,
          armRound: item?.armRound,
          armHole: item?.armHole,
          sleeveLength: item?.sleeveLength,
          remarks: item?.remarks,
        };
        break;
      case "shirt":
        //Extract shirt measurements and return
        measurements = {
          length: item?.length,
          shoulder: item?.shoulder,
          sleeveFull: item?.sleeveFull,
          chest: item?.chest,
          waist: item?.waist,
          body: item?.body,
          neck: item?.neck,
          cup: item?.cup,
          regal: item?.regal,
          kneeLoose: item?.kneeLoose,
          disco: item?.disco,
          canvas: item?.canvas,
          remarks: item?.remarks,
          stitching: item?.stitching,
          marModel: item?.marModel,
          bottomModel: item?.bottomModel,
          colour: item?.colour,
          seat1: item?.seat1,
          seat2: item?.seat2,
          seat3: item?.seat3,
        };
        break;
      case "pant":
        //Extract pant measurements and return
        measurements = {
          length: item?.length,
          inside: item?.inside,
          waist: item?.waist,
          seat: item?.seat,
          thigh: item?.thigh,
          bottom: item?.bottom,
          ply: item?.ply,
          belt: item?.belt,
          sidePocket: item?.sidePocket,
          backPocket: item?.backPocket,
          pleat: item?.pleat,
          loops: item?.loops,
          remarks: item?.remarks,
        };
        break;
      case "coat":
        //Extract coat measurements and return
        measurements = {
          length: item?.length,
          shoulder: item?.shoulder,
          sleeve: item?.sleeve,
          body: item?.body,
          waist: item?.waist,
          seat: item?.seat,
          front: item?.front,
          back: item?.back,
          sleeveLoose: item?.sleeveLoose,
          fpButton: item?.fpButton,
          backOpen: item?.backOpen,
          remarks: item?.remarks,
        };
        break;
      case "sharvani":
        //Extract sharvani measurements and return
        measurements = {
          length: item?.length,
          shoulder: item?.shoulder,
          sleeveFull: item?.sleeveFull,
          chest: item?.chest,
          waist: item?.waist,
          body: item?.body,
          neck: item?.neck,
          cup: item?.cup,
          regal: item?.regal,
          kneeLoose: item?.kneeLoose,
          disco: item?.disco,
          canvas: item?.canvas,
          remark: item?.remark,
          stitching: item?.stitching,
          marModel: item?.marModel,
          bottomModel: item?.bottomModel,
          colour: item?.colour,
          seat1: item?.seat1,
          seat2: item?.seat2,
          seat3: item?.seat3,
        };
        break;
      case "safari":
        //Extract safari measurements and return
        measurements = {
          length: item?.length,
          shoulder: item?.shoulder,
          sleeveFull: item?.sleeveFull,
          chest: item?.chest,
          waist: item?.waist,
          body: item?.body,
          neck: item?.neck,
          cup: item?.cup,
          regal: item?.regal,
          kneeLoose: item?.kneeLoose,
          disco: item?.disco,
          canvas: item?.canvas,
          remark: item?.remark,
          stitching: item?.stitching,
          marModel: item?.marModel,
          bottomModel: item?.bottomModel,
          colour: item?.colour,
          seat1: item?.seat1,
          seat2: item?.seat2,
          seat3: item?.seat3,
        };
        break;

      default:
        break;
    }
  }
  return measurements;
};

//Create Backend itemsDemoArray (simply make single view res extensible)
export const createItemsDemoArrayInCaseOfEdit = (singleViewRes = {}) => {
  //copy(extensible) of response
  let singleViewResExtensible =
    makeCuttingSlipSingleViewExtensible(singleViewRes);
  const { items } = singleViewResExtensible;

  return items;
};

//change Measurement structure in add body to  response of viewMeasurement api call structure
export const changeMeasurementStructure = (currentMeasurement = []) => {
  let changedMeasurement = {};

  // console.log(currentMeasurement);
  currentMeasurement.forEach((measurementObj) => {
    const { type, measurement } = measurementObj;
    changedMeasurement[type] = [{ measurement, type }]; //assigns latest type measurement into measurement object with key as the name of the type
  });

  // console.log("converted measurement :", changedMeasurement);
  return changedMeasurement;
};
