import React, { useEffect, useRef, useState } from 'react'
import { companyProfileInfoForUser } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import { viewAllCurrencyUpdated } from '../../../../API/Settings/Currency/CurrencyAPI';
import { filterObjFromList } from '../../../../Js/generalFunctions';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { StockMoveSingleAPIcall } from './ReportAPI';
import Template from "./StockMoveSinglePrint"
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import ReactToPrint from 'react-to-print';
import * as XLSX from "xlsx";


export const StockMoveSingleView = () => {
  let paymentsPrintNew = useRef();

  let navigate = useNavigate();
  const [symbol, setSymbol] = useState(null);

  // const singleView = useSelector(
  //   (state) => state?.Report?.StockMoveSingle
  // );
 
  const {tableLoading} = useSelector((state) => state.mainDashBoardSlice);

  const singleView = useSelector((state) => state.ReportSlice.StockMoveSingle)


  const decimalPosition = localStorage.getItem("decimalPosition");
  const decimalPositionStock = localStorage.getItem("decimalPositionStock");

  const currencyListUpdated = useSelector(
    (state) => state?.viewUpdatedCurrency?.value
  );

  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );
  const branch = localStorage.getItem("branch")
  const location = localStorage.getItem("location")
  const FromDate = localStorage.getItem("fromDate")
  const ToDate = localStorage.getItem("ToDate")
  const Stock_id = localStorage.getItem("stock_id")
  const vendor = localStorage.getItem("vendor")

  const [unitCost,setUnitCost]=useState(0)
  const handleback = () => {

    navigate("/userdashboard/inventory/reports/stockMove")
  }
  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);

  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, []);

  const initialvalue = {
    label: "Stock Move",
    totalBalance: 0,
    totalCredit: 0,
    totalDebit: 0,

    companyLogo: "",
    companyName: "",
    companyAddress: "",
    companyContact: "",
  }

  const [data, setData] = useState([])
  const [formValues, setFormValues] = useState(initialvalue)

  const [lineTotals,setLineTotals]=useState({
    totalIn:0,
    totalOut:0,
    totalClosing:0,
    totalValue:0
  })
  useEffect(() => {
    setFormValues({
      ...formValues,
      companyLogo: companyProfileData?.logo || "",
      companyName: companyProfileData?.companyName || "",
      companyAddress: companyProfileData?.companyAddress || "",
      companyContact: companyProfileData?.contactNumber || "",

    });
    setData(singleView)
    if(singleView.length!==0){
      setUnitCost(singleView[0]?.cost)

      let inQuantityTotal=singleView[0]?.stockMoves?.reduce((acc, row) => acc + row.inQuantity, 0)?.toFixed(decimalPositionStock)
      let openingBalance=singleView[0]?.openingBalance?.toFixed(decimalPositionStock)
      let outQuantityTotal=singleView[0]?.stockMoves?.reduce((acc, row) => acc + row.outQuantity, 0)?.toFixed(decimalPositionStock)
      let totalValue=singleView[0]?.stockMoves?.reduce((acc, row) => acc + row.value, 0)?.toFixed(decimalPosition)
      setLineTotals({...lineTotals,
        totalIn:(Number(inQuantityTotal)+Number(openingBalance))?.toFixed(decimalPosition),
        totalOut:outQuantityTotal,
        totalClosing:(Number(inQuantityTotal)-Number(outQuantityTotal)+Number(openingBalance))?.toFixed(decimalPosition),
        totalValue
      })
    }
  }, [singleView, companyProfileData])

  const downloadList = () => {
    const worksheet = XLSX.utils.table_to_sheet(document.getElementById("table_data"));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Stock Move.xlsx");
  }

  useEffect(() => {
    StockMoveSingleAPIcall(
      {
        fromDate: FromDate===''?'':`${FromDate} 00:00:00`,
        endDate: ToDate===''?'':`${ToDate} 23:59:59`,
        branchId: branch,
        location: location,
        productId: Stock_id
      }
    )
  }, [FromDate, ToDate, branch, location, Stock_id])

  return (
    <div className="global-page-parent-container" style={{ margin: "0" }}>
      <div className="global-white-bg-container">
        <div style={{display:"flex"}}>
          <div className="sharp-arrow" style={{ width: "136px" }} onClick={handleback}> <p>Stock Move Report</p></div>
          <div className="sharp-arrow" style={{ marginLeft: "18px", width: "auto" }}> <p>{vendor}</p></div>
          <div style={{paddingInline:"15px",marginTop:"-13px"}}>
          <Tooltip title="Share">
          <IconButton>
            <i class="bi bi-share" style={{ backgroundColor: "#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>

        <ReactToPrint
          trigger={() => (
            <IconButton className="printer-div" style={{ padding: "1px" }}>
              <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
            </IconButton>
          )}
          content={() => paymentsPrintNew}
        />

        <Tooltip title="Download">
          <IconButton onClick={downloadList}>
            <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>

        </div>
        </div>
        {/* <h4 style={{ margin: "32px 0px 4px 0px" }}>Stock Move</h4> */}
        <div className='global-report-table-container' style={{ maxHeight: "80vh" }}>
          <table id='table_data'>
            <thead>
              <tr>
                <th>Date</th>
                <th>Particular</th>
                <th>In</th>
                <th>Out</th>
                <th>Closing</th>
                {/* <th>Unit Cost</th> */}
                <th>Value</th>
              </tr>
              
            </thead>
            <tbody>
              {
              tableLoading?
                [...Array(6)].map((_,i)=>(
                  <tr>
                    <td colSpan={15}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                ))
              :singleView.length!==0?
              <>
                  <tr style={{ height: "38px" }}>
                    <td style={{ backgroundColor: "#e7e7e7" }}>&nbsp;</td>
                    <td style={{ backgroundColor: "#e7e7e7" ,fontSize:"1rem"}}>Opening Balance</td>
                    <td style={{ backgroundColor: "#e7e7e7" }}>{singleView[0]?.openingBalance?.toFixed(decimalPositionStock)}</td>
                    <td style={{ backgroundColor: "#e7e7e7" }}>{0?.toFixed(decimalPositionStock)}</td>
                    <td style={{ backgroundColor: "#e7e7e7" }}>{singleView[0]?.openingBalance?.toFixed(decimalPosition)}</td>
                    {/* <td style={{ backgroundColor: "#e7e7e7" }}>&nbsp;</td> */}
                    <td style={{ backgroundColor: "#e7e7e7" }}>{symbol}&nbsp;{isNaN((Number(unitCost) * Number(singleView[0]?.openingBalance?.toFixed(decimalPositionStock)))?.toFixed(decimalPosition))?(0.00):(Number(unitCost) * Number(singleView[0]?.openingBalance?.toFixed(decimalPositionStock)))?.toFixed(decimalPosition)}</td>
                  </tr>
              {singleView[0]?.stockMoves?.map((item, index) => (
                
                <tr style={{ height: "38px" }} key={index}>
                   <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.convertedDate}</td>
                  <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.reference}</td>
                  <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.inQuantity?.toFixed(decimalPositionStock)}</td>
                  <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.outQuantity?.toFixed(decimalPositionStock)}</td>
                  <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.closingBalance?.toFixed(decimalPosition)}</td>
                  {/* <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{symbol}&nbsp;{item.unitCost?.toFixed(decimalPosition)}</td> */}
                  <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{symbol}&nbsp;{item.value?.toFixed(decimalPosition)}</td>
                </tr>
              ))}
              </>
              :
              <tr>
                <td colSpan={6}>No Records</td>
              </tr>
            }
            </tbody>
           {
           
           singleView.length!==0 &&
           <tfoot>
              <tr>
                <th colSpan={2}>Total</th>
                <th>{lineTotals?.totalIn}</th>
                <th>{lineTotals?.totalOut}</th>
                <th>{lineTotals?.totalClosing}</th>
                {/* <th>{singleView[0]?.stockMoves?.reduce((acc, row) => acc + row.closingBalance, 0)?.toFixed(decimalPosition)}</th> */}
                {/* <th>{symbol}&nbsp;{singleView[0]?.stockMoves?.reduce((acc, row) => acc + row.unitCost, 0)?.toFixed(decimalPosition)}</th> */}
                <th>{symbol}&nbsp;{lineTotals?.totalValue}</th>
              </tr>
            </tfoot>}
          </table>

        </div>
      </div>
      <div style={{ display: "none" }}>
        <div ref={(el) => (paymentsPrintNew = el)}>
          <Template data={data} formValues={formValues} lineTotals={lineTotals}/>
        </div>
      </div>
    </div>

  )
}
