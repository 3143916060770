import { createSlice } from "@reduxjs/toolkit";

export const getDiscountSlice=createSlice({
    name:'discountSlice',
    initialState:{
        value:undefined,
        valueNew:undefined,
        tableData:undefined
    },
    reducers:{
        get_discount:(state,action)=>{
            state.value=action.payload.getDiscountData
        },
        get_new_discount:(state,action)=>{
            state.valueNew=action.payload.getDiscountNewData
        },
        table_data:(state,action)=>{
            state.tableData=action.payload.tableFullData
        }
    }
})
export const {get_discount,get_new_discount,table_data}=getDiscountSlice.actions;
export default getDiscountSlice.reducer;
