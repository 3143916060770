import { MenuItem, Select } from "@mui/material";
import React, { useRef, useState } from "react";
import "../../css/Login/loginComponent.css"
import { LoginUpdatePassword } from "./LoginUpdatePassWord";
  export const LoginMobileVerification=(props)=>{
    const{backToLogin}=props;
    const [showOtpField,setShowOtpField]=useState("mobileNumberField")

    // useref
    const input1Ref=useRef(null);
    const input2Ref=useRef(null);
    const input3Ref=useRef(null);
    const input4Ref=useRef(null);

    const moveToNext=(input,nextInputRef,prevInputRef)=>{
        if (input.value.length >= input.maxLength) {
          nextInputRef.current.focus();
        }
        else if (input.value.length === 0 && prevInputRef) {
          prevInputRef.current.focus();
        }
    }
    const clickVerifyOtp=()=>{
      setShowOtpField("updatePassWordField")
    }
    return(
          <div className="login-content-wrapper login-forgot-pass-ctn">
            <h3 style={{marginLeft:"5%"}}>Forgot Password</h3>

            {
              showOtpField==="mobileNumberField"?
            <>
            <div className="login-forgot-pass-body-section">
                <h3>Mobile Verification</h3>
                <p>Please enter your mobile number</p>
                <div className="global-single-input loginforgot-pass-input">
                    <p>Mobile</p>
                    <div className="global-single-input login-double-input">
                    <Select value="one" style={{width:"20%",height:"56px"}}>
                      <MenuItem value="one">+91</MenuItem>
                      <MenuItem>+80</MenuItem>
                    </Select>
                    <input type="number" style={{marginLeft:"4px"}} maxLength="15"/>
                    </div>
                </div>
            </div>
            <div className="login-forgot-pass-button-ctn">
                <button 
                className="btn btn-secondary loginforgot-pass-btn"
                onClick={()=>setShowOtpField("otpField")}
                >Send OTP</button>
                <div 
                onClick={backToLogin}
                className="login-content-wrapper-forgot-password" style={{alignSelf:"center",fontSize:"0.8rem"}}>
                    Back to Login
                  </div>
            </div>
            </>
            :
            showOtpField==="otpField"?
            <>
            <div className="login-forgot-pass-body-section">
                <h3>Enter OTP</h3>
                <p>We have sent you access code</p>
                <p>Via SMS for mobile number verification</p>
                <div className="global-single-input login-forgot-pass-otp-ctn">
                    <input type="text" ref={input1Ref} maxlength="1" 
                    onKeyUp={()=>moveToNext(input1Ref.current,input2Ref,null)}/>
                    <input type="text" ref={input2Ref} maxlength="1" 
                    onKeyUp={()=>moveToNext(input2Ref.current,input3Ref,input1Ref)}/>
                    <input type="text" ref={input3Ref} maxlength="1" 
                    onKeyUp={()=>moveToNext(input3Ref.current,input4Ref,input2Ref)}/>
                    <input type="text" ref={input4Ref} maxlength="1" 
                    onKeyUp={() => moveToNext(input4Ref.current, null, input3Ref)}/>
                </div>
            </div>
            <div className="login-forgot-pass-otp-button-ctn">
                <button 
                className="btn btn-secondary-outlined"
                onClick={()=>setShowOtpField("mobileNumberField")}
                >Back</button>
                <button 
                className="btn btn-secondary loginforgot-pass-btn"
                onClick={clickVerifyOtp}
                >Verify</button>
            </div>
            <div className="login-forgot-pass-otp-footer">
              <p>Don't Receive the OTP?</p>
              <div 
                  className="login-content-wrapper-forgot-password" style={{alignSelf:"center",fontSize:"0.7rem"}}>
                  Resend Code
              </div>
            </div>
            </>
            :
            showOtpField==="updatePassWordField"?
              <LoginUpdatePassword backToLogin={backToLogin} label="answer"/>
              :null
            }

          </div>
      )
  }