import {
  SpeedOutlined,
  ShoppingBagOutlined,
  MonetizationOnOutlined,
  StarsOutlined,
  LocalOfferOutlined,
  AccountBalanceWalletOutlined,
  DescriptionOutlined,
  AccountTreeOutlined,
  ManageAccountsOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  dashboardStyle,
  productListStyle,
  productMasterStyle,
  purchaseManageStyle,
  purchaseManageSubStyle,
  ordersStyle,
  salesStyle,
  customerStyle,
  expenseStyle,
  rewardStyle,
  offerStyle,
  walletStyle,
  reportStyle,
  accountsStyle,
  staffStyle,
  settingsStyle,
  qrCodeStyle,
  deviceStyle,
  inventorySubStyle,
  inventorySubcloseStyle,
  payrollNewStyle,
} from "./NavLinkStyles";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import ShopTwoOutlinedIcon from "@mui/icons-material/ShopTwoOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import sales from "../../Assets/SVG/SALES.svg";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import QrCodeScannerOutlinedIcon from "@mui/icons-material/QrCodeScannerOutlined";
import { useSelector } from "react-redux";
import { viewCompanyProfileInfoAPICall } from "../../API/Settings/Company Settings/companySettingsAPI";

import SettingsBrightnessIcon from "@mui/icons-material/SettingsBrightness";
import InventoryIcon from "@mui/icons-material/Inventory";
import { viewInventoryConfigSettingsAPICall } from "../Modules/Inventory/Configuration/inventoryConfigurationSettingsAPI";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
export const SideMenu = (props) => {
  const { refresh } = props;
  //*Redux States
  //userRole
  const userRole = useSelector((state) => state.userRoleSlice.value);
  // login response
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);

  const inventorySettingsConfigurationList = useSelector(
    (state) => state.inventoryConfigSettingSlice.apiResList
  );

  const [dashboardFocus, setDashboardFocus] = useState(false);
  const [productListFocus, setProductListFocus] = useState(false);
  const [productMasterFocus, setProductMasterFocus] = useState(false);
  const [purchaseManageFocus, setPurchaseManageFocus] = useState(false);
  const [stockAdjustmentFocus, setStockAdjustmentFocus] = useState(false);
  const [transferFocus, setTransferFocus] = useState(false);
  const [ordersFocus, setOrdersFocus] = useState(false);
  const [salesFocus, setSalesFocus] = useState(false);
  const [customerFocus, setCustomerFocus] = useState(false);
  const [expenseFocus, setExpenseFocus] = useState(false);
  const [rewardPointsFocus, setRewardPointsFocus] = useState(false);
  const [offersFocus, setOffersFocus] = useState(false);
  const [walletFocus, setWalletFocus] = useState(false);
  const [reportFocus, setReportFocus] = useState(false);
  const [accountsFocus, setAccountsFocus] = useState(false);
  const [newPayrollFocus, setNewPayrollFocus] = useState(false);
  const [staffFocus, setStaffFocus] = useState(false);
  const [hrmFocus, setHrmFocus] = useState(false);
  const [attendanceFocus, setAttendanceFocus] = useState(false);
  const [leaveFocus, setLeaveFocus] = useState(false);
  const [payrollFocus, setPayRollFocus] = useState(false);
  const [loanFocus, setLoanFocus] = useState(false);
  const [settingsFocus, setSettingsFocus] = useState(false);
  const [qrCodeFocus, setQrCodeFocus] = useState(false);

  const [devicefocus, setDevicefocus] = useState(false);

  const [grnFocus, setGrnFocus] = useState(false);

  const [purchaseManager, setPurchaseManager] = useState(false);
  const [grn, setGrn] = useState(false);
  const [stockAdjustment, setStockAdjustment] = useState(false);
  const [transfer, setTransfer] = useState(false);
  const [orders, setOrders] = useState(false);
  const [sales, setSales] = useState(false);
  const [customers, setCustomers] = useState(false);
  const [expense, setExpense] = useState(false);
  const [rewardPoints, setRewardPoints] = useState(false);
  const [offers, setOffers] = useState(false);
  const [report, setReport] = useState(false);
  const [accounts, setAccounts] = useState(false);
  const [newPayroll, setNewPayroll] = useState(false);
  const [attendance, setAttendance] = useState(false);
  const [payrollConfiguration, setPayrollConfiguration] = useState(false);
  const [staff, setStaff] = useState(false);
  const [hrm, setHrm] = useState(false);
  const [leave, setLeave] = useState(false);
  const [payRoll, setPayRoll] = useState(false);
  const [loan, setLoan] = useState(false);
  const [inventory, setInventory] = useState(false);
  const [inventoryOperations, setInventoryOperations] = useState(false);
  const [inventoryProducts, setInventoryProducts] = useState(false);
  const [inventoryConfiguration, setInventoryConfiguration] = useState(false);
  const [AccountsCustomers, setAccountsCustomers] = useState(false);
  const [inventoryReport, setInventoryReport] = useState(false);
  const [contraVoucher, setContraVoucher] = useState(false);
  const [reportNew, setReportNew] = useState(false);
  const [reportNewFinancial, setreportNewFinancial] = useState(false);
  const [partnerReport, setpartnerReport] = useState(false);
  const [generalReport, setGeneralReport] = useState(false);
  const [accountingVendor, setAccountingVendor] = useState(false);
  const [reports, setReports] = useState(false);
  const [accountReconciliation, setAccountReconciliation] = useState(false);
  const [salesNew, setSalesNew] = useState(false);
  const [salesNewOrder, setSalesNewOrder] = useState(false);
  const [salesNewConfiguration, setSalesNewConfiguration] = useState(false);
  const [salseNewReport, setSalesNewReport] = useState(false);
  const [reconciliation, setReconciliation] = useState(false);
  const [pointOfSale, setPointOfSale] = useState(false);
  const [posConfiguration, setPosConfiguration] = useState(false);
  const [purchaseReport, setPurchaseReport] = useState(false);
  const [vatReport, setVatReport] = useState(false);

  // purchase manage array
  const [purchaseManagerArray, setPurchaseManagerArray] = useState([]);

  // useEffect(()=>{
  //   setPurchaseManagerArray(Object.values(loginResponse?.permission?.purchaseManage!==undefined && loginResponse?.permission?.purchaseManage))
  // },[loginResponse])

  // console.log(purchaseManagerArray);

  const purchaseManagerClick = () => {
    setPurchaseManager(!purchaseManager);
  };
  const grnClick = () => {
    setGrn(!grn);
  };
  const stockAdjustmentClick = () => {
    setStockAdjustment(!stockAdjustment);
  };
  const transferClick = () => {
    setTransfer(!transfer);
  };
  const ordersClick = () => {
    setOrders(!orders);
  };
  const salesClick = () => {
    setSales(!sales);
  };
  const customerClick = () => {
    setCustomers(!customers);
  };
  const expenseClick = () => {
    setExpense(!expense);
  };
  const rewardPointClick = () => {
    setRewardPoints(!rewardPoints);
  };
  const offersClick = () => {
    setOffers(!offers);
  };
  const reportClick = () => {
    setReport(!report);
  };
  const accountsClick = () => {
    setAccounts(!accounts);
  };
  const newPayrollClick = () => {
    setNewPayroll(!newPayroll);
  };
  const AccountContraVoucherClick = () => {
    setContraVoucher(!contraVoucher);
  };
  const ReconciliationClick = () => {
    setReconciliation(!reconciliation);
  };
  const AccountReportNewClick = () => {
    setReportNew(!reportNew);
  };
  const FinancialReportNewClick = () => {
    setreportNewFinancial(!reportNewFinancial);
  };

  const PartnerReportOnClick = () => {
    setpartnerReport(!partnerReport);
  };

  const GeneralReportOnClick = () => {
    setGeneralReport(!generalReport);
  };

  const AccountingVendorClick = () => {
    setAccountingVendor(!accountingVendor);
  };
  const AccountReportsClick = () => {
    setReports(!reports);
  };
  const AccountReconciliationClick = () => {
    setAccountReconciliation(!accountReconciliation);
  };
  const AccountsCustomerClick = () => {
    setAccountsCustomers(!AccountsCustomers);
  };
  const staffClick = () => {
    setStaff(!staff);
  };
  const staffHrmClick = () => {
    setHrm(!hrm);
  };
  const newPayrollAttendanceClick = () => {
    setAttendance(!attendance);
  };
  const handlePayrollConfigurationClick = () => {
    setPayrollConfiguration(!payrollConfiguration);
  };
  const leaveClick = () => {
    setLeave(!leave);
  };
  const payrollClick = () => {
    setPayRoll(!payRoll);
  };
  const loanClick = () => {
    setLoan(!loan);
  };
  const inventoryClick = () => {
    setInventory(!inventory);
  };
  const inventoryOperationClick = () => {
    setInventoryOperations(!inventoryOperations);
  };
  const inventoryProductsClick = () => {
    setInventoryProducts(!inventoryProducts);
  };
  const inventoryConfigurationClick = () => {
    setInventoryConfiguration(!inventoryConfiguration);
  };
  const inventoryReportssClick = () => {
    setInventoryReport(!inventoryReport);
  };
  const salesNewClick = () => {
    setSalesNew(!salesNew);
  };
  const salesNewOrderClick = () => {
    setSalesNewOrder(!salesNewOrder);
  };
  const salesNewConfigurationClick = () => {
    setSalesNewConfiguration(!salesNewConfiguration);
  };
  const salesNewReportClick = () => {
    setSalesNewReport(!salseNewReport);
  };

  const pointOfSalesClick = () => {
    setPointOfSale(!pointOfSale);
  };
  const posConfigurationClick = () => {
    setPosConfiguration(!posConfiguration);
  };
  const purchaseReportClick = () => {
    setPurchaseReport(!purchaseReport);
  };
  const VatReportClick = () => {
    setVatReport(!vatReport);
  };

  useEffect(() => {
    viewInventoryConfigSettingsAPICall();
    console.log("sidemenu refressshh");
  }, [refresh]);

  return (
    <div>
      {/* dashboard */}
      {loginResponse?.permission?.dashboard === true && (
        <div
          // id="dashboard"
          className={
            dashboardFocus ? "dashboard-sidemenu-container" : "focus-none"
          }
        >
          <NavLink
            to="dashboardhome"
            style={({ isActive }) =>
              isActive
                ? dashboardStyle
                : { textDecoration: "none", color: "#000" }
            }
            className={({ isActive }) =>
              isActive ? setDashboardFocus(true) : setDashboardFocus(false)
            }
          >
            <ListItem button>
              <ListItemIcon>
                <SpeedOutlined
                  sx={dashboardFocus ? dashboardStyle : { color: "#000" }}
                />
              </ListItemIcon>
              <ListItemText primary="Dashboard"></ListItemText>
            </ListItem>
          </NavLink>
        </div>
      )}

      {/* product list */}
      {loginResponse?.permission?.products === true && (
        <div
          // id="productList"
          className={
            productListFocus ? "productlist-sidemenu-container" : "focus-none"
          }
        >
          <NavLink
            to="productList"
            style={({ isActive }) =>
              isActive
                ? productListStyle
                : { textDecoration: "none", color: "#000" }
            }
            className={({ isActive }) =>
              isActive ? setProductListFocus(true) : setProductListFocus(false)
            }
          >
            <ListItem button>
              <ListItemIcon>
                <i
                  className={
                    productListFocus
                      ? "bi bi-box2 product-list-icon"
                      : "bi bi-box2 focus-none-icon"
                  }
                ></i>
              </ListItemIcon>
              <ListItemText primary="Products"></ListItemText>
            </ListItem>
          </NavLink>
        </div>
      )}

      {/* product master */}

      {/* <div
              // id="productmaster"
              className={
                productMasterFocus
                  ? "productmaster-sidemenu-container"
                  : "focus-none"
              }
            >
              <NavLink
                to="productmaster"
                style={({ isActive }) =>
                  isActive
                    ? productMasterStyle
                    : { textDecoration: "none", color: "#000" }
                }
                className={({ isActive }) =>
                  isActive
                    ? setProductMasterFocus(true)
                    : setProductMasterFocus(false)
                }
              >
                <ListItem button>
                  <ListItemIcon>
                    <InventoryOutlinedIcon
                      sx={
                        productMasterFocus
                          ? productMasterStyle
                          : { color: "#000" }
                      }
                    />
                  </ListItemIcon>
                  <ListItemText primary="Product Master"></ListItemText>
                </ListItem>
              </NavLink>
            </div> */}

      {/* purchase manager */}

      <div
        // id="purchasemanager"
        className={
          purchaseManageFocus || purchaseManager
            ? "purchaseManager-sidemenu-container"
            : "focus-none"
        }
      >
        <ListItem button onClick={purchaseManagerClick}>
          <ListItemIcon>
            <ShopTwoOutlinedIcon
              sx={
                purchaseManageFocus || purchaseManager
                  ? purchaseManageStyle
                  : { color: "#000" }
              }
            />
          </ListItemIcon>
          <ListItemText
            primary="Purchase"
            sx={
              purchaseManageFocus || purchaseManager
                ? purchaseManageStyle
                : { color: "#000" }
            }
          ></ListItemText>
          {purchaseManager ? (
            <ExpandMoreIcon
              sx={
                purchaseManageFocus || purchaseManager
                  ? purchaseManageStyle
                  : { color: "#000" }
              }
            />
          ) : (
            <KeyboardArrowRightIcon
              sx={
                purchaseManageFocus || purchaseManager
                  ? purchaseManageStyle
                  : { color: "#000" }
              }
            />
          )}
        </ListItem>
      </div>

      <Collapse in={purchaseManager} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {/* rfq */}
          {loginResponse?.permission?.purchase?.rfq === true && (
            <div className="purchase-manage-item">
              <NavLink
                to="purchaseManager/RequestForQuatation"
                style={({ isActive }) =>
                  isActive
                    ? purchaseManageSubStyle
                    : { textDecoration: "none", color: "#000" }
                }
              >
                <ListItem button>
                  <ul>
                    <li>
                      <ListItemText primary="Request For Quotation"></ListItemText>
                    </li>
                  </ul>
                </ListItem>
              </NavLink>
            </div>
          )}

          {/* new purchase order */}
          {loginResponse?.permission?.purchase?.purchaseOrder === true && (
            <div className="purchase-manage-item">
              <NavLink
                to="purchaseManager/NewPurchaseOrder"
                style={({ isActive }) =>
                  isActive
                    ? purchaseManageSubStyle
                    : { textDecoration: "none", color: "#000" }
                }
              >
                <ListItem button>
                  <ul>
                    <li>
                      <ListItemText primary="Purchase Order"></ListItemText>
                    </li>
                  </ul>
                </ListItem>
              </NavLink>
            </div>
          )}

          {/* grn */}
          {loginResponse?.permission?.purchase?.grn === true && (
            <div className="purchase-manage-item">
              <NavLink
                to="purchaseManager/GoodsReceivedNote"
                style={({ isActive }) =>
                  isActive
                    ? purchaseManageSubStyle
                    : { textDecoration: "none", color: "#000" }
                }
                onClick={() => {
                  localStorage.removeItem("singleViewById");
                  localStorage.removeItem("singleViewByIdgrn");
                }}
              >
                <ListItem button>
                  <ul>
                    <li>
                      <ListItemText primary="Goods Received Note"></ListItemText>
                    </li>
                  </ul>
                </ListItem>
              </NavLink>
            </div>
          )}

          {/* uom */}
          {loginResponse?.permission?.purchase?.uom === true && (
            <div
              // id="stockoutproducts"
              className="purchase-manage-item"
            >
              <NavLink
                to="purchaseManager/unitOfMeasurment"
                style={({ isActive }) =>
                  isActive
                    ? purchaseManageSubStyle
                    : { textDecoration: "none", color: "#000" }
                }
              >
                <ListItem button>
                  <ul>
                    <li>
                      <ListItemText primary="Unit of Measurment"></ListItemText>
                    </li>
                  </ul>
                </ListItem>
              </NavLink>
            </div>
          )}

          {/* vendor */}
          {loginResponse?.permission?.purchase?.uom === true && (
            <div
              // id="vendor"
              className="purchase-manage-item"
            >
              <NavLink
                to="purchaseManager/vendor/vendors"
                style={({ isActive }) =>
                  isActive
                    ? purchaseManageSubStyle
                    : { textDecoration: "none", color: "#000" }
                }
              >
                <ListItem button>
                  <ul>
                    <li>
                      <ListItemText primary="Vendors"></ListItemText>
                    </li>
                  </ul>
                </ListItem>
              </NavLink>
            </div>
          )}

          {/* configuration */}
          {loginResponse?.permission?.purchase?.configuration === true && (
            <div
              // id="purchasereturn"
              className="purchase-manage-item"
            >
              <NavLink
                to="purchaseManager/configuration"
                style={({ isActive }) =>
                  isActive
                    ? purchaseManageSubStyle
                    : { textDecoration: "none", color: "#000" }
                }
              >
                <ListItem button>
                  <ul>
                    <li>
                      <ListItemText primary="Configuration"></ListItemText>
                    </li>
                  </ul>
                </ListItem>
              </NavLink>
            </div>
          )}
          {/* purchase report */}
          <div>
            <ListItem button onClick={purchaseReportClick}>
              <ListItemText
                sx={purchaseReport ? staffStyle : { color: "#000" }}
              >
                <ul>
                  <li>Purchase Report</li>
                </ul>
              </ListItemText>
              {purchaseReport ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
            </ListItem>
          </div>
          <Collapse in={purchaseReport} timeout="auto" unmountOnExit>
            <div className="purchase-manage-item">
              <NavLink
                to="purchasemanager/PurchaseReport/PurchaseReportNew"
                style={({ isActive }) =>
                  isActive
                    ? rewardStyle
                    : { textDecoration: "none", color: "#000" }
                }
              >
                <ListItem button>
                  <ul>
                    <li>
                      <ListItemText primary="Purchase Report"></ListItemText>
                    </li>
                  </ul>
                </ListItem>
              </NavLink>
            </div>
            <div
                    // id="accounting"
                    className="purchase-manage-item"
                  >
                    <NavLink
                      to="purchasemanager/PurchaseReport/PurchaseAgingReport"
                      style={({ isActive }) =>
                        isActive
                          ? staffStyle
                          : { textDecoration: "none", color: "#000" }
                      }
                    >
                      <ListItem>
                        <ul>
                          <li>
                            <ListItemText primary="Purchase Aging Report"></ListItemText>
                          </li>
                        </ul>
                      </ListItem>
                    </NavLink>
            </div>
          </Collapse>
        </List>
      </Collapse>

      {/* inventory */}
      <div
        // id="purchasemanager"
        className={
          purchaseManageFocus || inventory
            ? "purchaseManager-sidemenu-container"
            : "focus-none"
        }
      >
        <ListItem button onClick={inventoryClick}>
          <ListItemIcon>
            <InventoryIcon
              sx={
                purchaseManageFocus || inventory
                  ? purchaseManageStyle
                  : { color: "#000" }
              }
            />
          </ListItemIcon>
          <ListItemText
            primary="Inventory"
            sx={
              purchaseManageFocus || inventory
                ? purchaseManageStyle
                : { color: "#000" }
            }
          ></ListItemText>
          {inventory ? (
            <ExpandMoreIcon
              sx={
                purchaseManageFocus || inventory
                  ? purchaseManageStyle
                  : { color: "#000" }
              }
            />
          ) : (
            <KeyboardArrowRightIcon
              sx={
                purchaseManageFocus || inventory
                  ? purchaseManageStyle
                  : { color: "#000" }
              }
            />
          )}
        </ListItem>
      </div>
      <Collapse in={inventory} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {/* product */}
          <div>
            <ListItem button onClick={inventoryProductsClick}>
              <ListItemText
                sx={inventoryProducts ? staffStyle : { color: "#000" }}
              >
                <ul>
                  <li>Products</li>
                </ul>
              </ListItemText>
              {inventoryProducts ? (
                <ExpandMoreIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </ListItem>
          </div>
          <Collapse in={inventoryProducts} timeout="auto" unmountOnExit>
            {inventorySettingsConfigurationList?.productVarient &&
              loginResponse?.permission?.inventory?.productMaster === true && (
                <div className="purchase-manage-item">
                  <NavLink
                    to="inventory/products/productmaster"
                    style={({ isActive }) =>
                      isActive
                        ? rewardStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem button>
                      <ul>
                        <li>
                          <ListItemText primary="Product Master"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}
            {loginResponse?.permission?.inventory?.product === true && (
              <div className="purchase-manage-item">
                <NavLink
                  to="inventory/products/product"
                  style={({ isActive }) =>
                    isActive
                      ? rewardStyle
                      : { textDecoration: "none", color: "#000" }
                  }
                >
                  <ListItem button>
                    <ul>
                      <li>
                        <ListItemText primary="Product"></ListItemText>
                      </li>
                    </ul>
                  </ListItem>
                </NavLink>
              </div>
            )}
          </Collapse>
          {/* operation */}
          <div>
            <ListItem button onClick={inventoryOperationClick}>
              <ListItemText
                sx={inventoryOperations ? staffStyle : { color: "#000" }}
              >
                <ul>
                  <li>Operations</li>
                </ul>
              </ListItemText>
              {inventoryOperations ? (
                <ExpandMoreIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </ListItem>
          </div>

          <Collapse in={inventoryOperations} timeout="auto" unmountOnExit>
            {inventorySettingsConfigurationList?.multiLocation &&
              loginResponse?.permission?.inventory?.internalTransfer ===
                true && (
                <div className="purchase-manage-item">
                  <NavLink
                    to="inventory/operation/Interanltransfer"
                    style={({ isActive }) =>
                      isActive
                        ? rewardStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem button>
                      <ul>
                        <li>
                          <ListItemText primary="Internal Transfer"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}
            {loginResponse?.permission?.inventory?.branchTransfer === true && (
              <div className="purchase-manage-item">
                <NavLink
                  to="inventory/operation/branchtransfer"
                  style={({ isActive }) =>
                    isActive
                      ? rewardStyle
                      : { textDecoration: "none", color: "#000" }
                  }
                >
                  <ListItem button>
                    <ul>
                      <li>
                        <ListItemText primary="Branch Transfer"></ListItemText>
                      </li>
                    </ul>
                  </ListItem>
                </NavLink>
              </div>
            )}
            {loginResponse?.permission?.inventory?.branchReceipts === true && (
              <div className="purchase-manage-item">
                <NavLink
                  to="inventory/operation/branchreceipt"
                  style={({ isActive }) =>
                    isActive
                      ? rewardStyle
                      : { textDecoration: "none", color: "#000" }
                  }
                >
                  <ListItem button>
                    <ul>
                      <li>
                        <ListItemText primary="Branch Receipt"></ListItemText>
                      </li>
                    </ul>
                  </ListItem>
                </NavLink>
              </div>
            )}
            {loginResponse?.permission?.inventory?.stockMoves === true && (
              <div className="purchase-manage-item">
                <NavLink
                  to="inventory/operation/stockmoves"
                  style={({ isActive }) =>
                    isActive
                      ? rewardStyle
                      : { textDecoration: "none", color: "#000" }
                  }
                >
                  <ListItem button>
                    <ul>
                      <li>
                        <ListItemText primary="Stock Moves"></ListItemText>
                      </li>
                    </ul>
                  </ListItem>
                </NavLink>
              </div>
            )}
            {loginResponse?.permission?.inventory?.inventoryAdjustment ===
              true && (
              <div className="purchase-manage-item">
                <NavLink
                  to="inventory/operation/inventoryAdjustment"
                  style={({ isActive }) =>
                    isActive
                      ? rewardStyle
                      : { textDecoration: "none", color: "#000" }
                  }
                >
                  <ListItem button>
                    <ul>
                      <li>
                        <ListItemText primary="Inventory Adjustment"></ListItemText>
                      </li>
                    </ul>
                  </ListItem>
                </NavLink>
              </div>
            )}

            {/* <div className="purchase-manage-item">
              <NavLink
                to="inventory/operation/landedcost"
                style={({ isActive }) =>
                  isActive
                    ? rewardStyle
                    : { textDecoration: "none", color: "#000" }
                }
              >
                <ListItem button>
                  <ul>
                    <li>
                      <ListItemText primary="Landed Cost"></ListItemText>
                    </li>
                  </ul>
                </ListItem>
              </NavLink>
            </div> */}
          </Collapse>
          {/* report */}
          <div>
            <ListItem button onClick={inventoryReportssClick}>
              <ListItemText
                sx={inventoryReport ? staffStyle : { color: "#000" }}
              >
                <ul>
                  <li>Reports</li>
                </ul>
              </ListItemText>
              {inventoryReport ? (
                <ExpandMoreIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </ListItem>
          </div>
          <Collapse in={inventoryReport} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <>
                {
                  // loginResponse?.permission?.staff?.position === true &&
                  <div
                    // id="accounting"
                    className="stock-adjustment-item"
                  >
                    <NavLink
                      to="inventory/reports/stockMove"
                      style={({ isActive }) =>
                        isActive
                          ? staffStyle
                          : { textDecoration: "none", color: "#000" }
                      }
                    >
                      <ListItem>
                        <ul>
                          <li>
                            <ListItemText primary="Stock Move"></ListItemText>
                          </li>
                        </ul>
                      </ListItem>
                    </NavLink>
                  </div>
                }
              </>
            </List>
          </Collapse>
          {/* configuration */}
          <div>
            <ListItem button onClick={inventoryConfigurationClick}>
              <ListItemText
                sx={inventoryConfiguration ? staffStyle : { color: "#000" }}
              >
                <ul>
                  <li>Configuration</li>
                </ul>
              </ListItemText>
              {inventoryConfiguration ? (
                <ExpandMoreIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </ListItem>
          </div>

          <Collapse in={inventoryConfiguration} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <>
                {loginResponse?.permission?.inventory?.warehouse === true && (
                  <div
                    // id="accounting"
                    className="stock-adjustment-item"
                  >
                    <NavLink
                      to="inventory/configuration/warehouse"
                      style={({ isActive }) =>
                        isActive
                          ? staffStyle
                          : { textDecoration: "none", color: "#000" }
                      }
                    >
                      <ListItem>
                        <ul>
                          <li>
                            <ListItemText primary="Ware House"></ListItemText>
                          </li>
                        </ul>
                      </ListItem>
                    </NavLink>
                  </div>
                )}
              </>
              <>
                {loginResponse?.permission?.inventory?.location === true && (
                  <div
                    // id="accounting"
                    className="stock-adjustment-item"
                  >
                    <NavLink
                      to="inventory/configuration/location"
                      style={({ isActive }) =>
                        isActive
                          ? staffStyle
                          : { textDecoration: "none", color: "#000" }
                      }
                    >
                      <ListItem>
                        <ul>
                          <li>
                            <ListItemText primary="Location"></ListItemText>
                          </li>
                        </ul>
                      </ListItem>
                    </NavLink>
                  </div>
                )}
              </>

              <>
                {loginResponse?.permission?.inventory?.settings === true && (
                  <div
                    // id="accounting"
                    className="stock-adjustment-item"
                  >
                    <NavLink
                      to="inventory/configuration/settings"
                      style={({ isActive }) =>
                        isActive
                          ? staffStyle
                          : { textDecoration: "none", color: "#000" }
                      }
                    >
                      <ListItem>
                        <ul>
                          <li>
                            <ListItemText primary="Settings"></ListItemText>
                          </li>
                        </ul>
                      </ListItem>
                    </NavLink>
                  </div>
                )}
              </>
              <>
                {loginResponse?.permission?.inventory?.attribute === true && (
                  <div
                    // id="accounting"
                    className="stock-adjustment-item"
                  >
                    <NavLink
                      to="inventory/configuration/attribute"
                      style={({ isActive }) =>
                        isActive
                          ? staffStyle
                          : { textDecoration: "none", color: "#000" }
                      }
                    >
                      <ListItem>
                        <ul>
                          <li>
                            <ListItemText primary="Attribute"></ListItemText>
                          </li>
                        </ul>
                      </ListItem>
                    </NavLink>
                  </div>
                )}
              </>
              <>
                {loginResponse?.permission?.inventory?.posCategory === true && (
                  <div
                    // id="accounting"
                    className="stock-adjustment-item"
                  >
                    <NavLink
                      to="inventory/configuration/poscategory"
                      style={({ isActive }) =>
                        isActive
                          ? staffStyle
                          : { textDecoration: "none", color: "#000" }
                      }
                    >
                      <ListItem>
                        <ul>
                          <li>
                            <ListItemText primary="POS Category"></ListItemText>
                          </li>
                        </ul>
                      </ListItem>
                    </NavLink>
                  </div>
                )}
              </>
              <>
                {loginResponse?.permission?.inventory?.category === true && (
                  <div
                    // id="accounting"
                    className="stock-adjustment-item"
                  >
                    <NavLink
                      to="inventory/configuration/category"
                      style={({ isActive }) =>
                        isActive
                          ? staffStyle
                          : { textDecoration: "none", color: "#000" }
                      }
                    >
                      <ListItem>
                        <ul>
                          <li>
                            <ListItemText primary="Category"></ListItemText>
                          </li>
                        </ul>
                      </ListItem>
                    </NavLink>
                  </div>
                )}
              </>
            </List>
          </Collapse>
        </List>
      </Collapse>

      {/* sales */}
      <div
        // id="purchasemanager"
        className={
          purchaseManageFocus || salesNew
            ? "purchaseManager-sidemenu-container"
            : "focus-none"
        }
      >
        <ListItem button onClick={salesNewClick}>
          <ListItemIcon>
            <ShoppingCartIcon
              sx={
                purchaseManageFocus || salesNew
                  ? purchaseManageStyle
                  : { color: "#000" }
              }
            />
          </ListItemIcon>
          <ListItemText
            primary="Sales"
            sx={
              purchaseManageFocus || salesNew
                ? purchaseManageStyle
                : { color: "#000" }
            }
          ></ListItemText>
          {salesNew ? (
            <ExpandMoreIcon
              sx={
                purchaseManageFocus || salesNew
                  ? purchaseManageStyle
                  : { color: "#000" }
              }
            />
          ) : (
            <KeyboardArrowRightIcon
              sx={
                purchaseManageFocus || salesNew
                  ? purchaseManageStyle
                  : { color: "#000" }
              }
            />
          )}
        </ListItem>
      </div>

      <Collapse in={salesNew} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {loginResponse?.permission?.sales?.quotation === true && (
            <div>
              <NavLink
                to="sales/orders/quotation"
                style={({ isActive }) =>
                  isActive
                    ? rewardStyle
                    : { textDecoration: "none", color: "#000" }
                }
              >
                <ListItem button>
                  <ul>
                    <li>
                      <ListItemText primary="Quotation"></ListItemText>
                    </li>
                  </ul>
                </ListItem>
              </NavLink>
            </div>
          )}
          {loginResponse?.permission?.sales?.salesOrder === true && (
            <div>
              <NavLink
                to="sales/orders/salesOrder"
                style={({ isActive }) =>
                  isActive
                    ? rewardStyle
                    : { textDecoration: "none", color: "#000" }
                }
              >
                <ListItem button>
                  <ul>
                    <li>
                      <ListItemText primary="Sales Order"></ListItemText>
                    </li>
                  </ul>
                </ListItem>
              </NavLink>
            </div>
          )}
          {loginResponse?.permission?.sales?.deliveryNote === true && (
            <div>
              <NavLink
                to="sales/orders/deliveryNote"
                style={({ isActive }) =>
                  isActive
                    ? rewardStyle
                    : { textDecoration: "none", color: "#000" }
                }
              >
                <ListItem button>
                  <ul>
                    <li>
                      <ListItemText primary="Delivery Note"></ListItemText>
                    </li>
                  </ul>
                </ListItem>
              </NavLink>
            </div>
          )}
          {loginResponse?.permission?.sales?.customer === true && (
            <div>
              <NavLink
                to="sales/orders/customers"
                style={({ isActive }) =>
                  isActive
                    ? rewardStyle
                    : { textDecoration: "none", color: "#000" }
                }
              >
                <ListItem button>
                  <ul>
                    <li>
                      <ListItemText primary="Customers"></ListItemText>
                    </li>
                  </ul>
                </ListItem>
              </NavLink>
            </div>
          )}
          {/* <div>
              <NavLink
                to="sales/orders/pricelist"
                style={({ isActive }) =>
                  isActive
                    ? rewardStyle
                    : { textDecoration: "none", color: "#000" }
                }
              >
                <ListItem button>
                  <ul>
                    <li>
                      <ListItemText primary="Price List"></ListItemText>
                    </li>
                  </ul>
                </ListItem>
              </NavLink>
            </div> */}

          <div>
            <ListItem button onClick={salesNewReportClick}>
              <ListItemText
                sx={salseNewReport ? staffStyle : { color: "#000" }}
              >
                <ul>
                  <li>Report</li>
                </ul>
              </ListItemText>
              {salseNewReport ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
            </ListItem>
          </div>
          <Collapse in={salseNewReport} timeout="auto" unmountOnExit>
            <div className="purchase-manage-item">
              <NavLink
                to="sales/Report/salesReport"
                style={({ isActive }) =>
                  isActive
                    ? rewardStyle
                    : { textDecoration: "none", color: "#000" }
                }
              >
                <ListItem button>
                  <ul>
                    <li>
                      <ListItemText primary="Sales Report"></ListItemText>
                    </li>
                  </ul>
                </ListItem>
              </NavLink>
            </div>
            <div className="purchase-manage-item">
              <NavLink
                to="sales/Report/salesReportBySalesPerson"
                style={({ isActive }) =>
                  isActive
                    ? rewardStyle
                    : { textDecoration: "none", color: "#000" }
                }
              >
                <ListItem button>
                  <ul>
                    <li>
                      <ListItemText primary="Sales Report By Sales Person"></ListItemText>
                    </li>
                  </ul>
                </ListItem>
              </NavLink>
            </div>
            <div className="purchase-manage-item">
              <NavLink
                to="sales/Report/dailySalesReport"
                style={({ isActive }) =>
                  isActive
                    ? rewardStyle
                    : { textDecoration: "none", color: "#000" }
                }
              >
                <ListItem button>
                  <ul>
                    <li>
                      <ListItemText primary="Daily Sales Report"></ListItemText>
                    </li>
                  </ul>
                </ListItem>
              </NavLink>
            </div>
          </Collapse>
        </List>
      </Collapse>

      {/* point of sale */}
      <div
        // id="purchasemanager"
        className={
          purchaseManageFocus || salesNew
            ? "purchaseManager-sidemenu-container"
            : "focus-none"
        }
      >
        <ListItem button onClick={pointOfSalesClick}>
          <ListItemIcon>
            <PointOfSaleIcon
              sx={
                purchaseManageFocus || salesNew
                  ? purchaseManageStyle
                  : { color: "#000" }
              }
            />
          </ListItemIcon>
          <ListItemText
            primary="Point Of Sales"
            sx={
              purchaseManageFocus || salesNew
                ? purchaseManageStyle
                : { color: "#000" }
            }
          ></ListItemText>
          {pointOfSale ? (
            <ExpandMoreIcon
              sx={
                purchaseManageFocus || salesNew
                  ? purchaseManageStyle
                  : { color: "#000" }
              }
            />
          ) : (
            <KeyboardArrowRightIcon
              sx={
                purchaseManageFocus || salesNew
                  ? purchaseManageStyle
                  : { color: "#000" }
              }
            />
          )}
        </ListItem>
      </div>
      <Collapse in={pointOfSale} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <div
            // id="orders"
            className={
              ordersFocus || orders ? "orders-sidemenu-container" : "focus-none"
            }
          >
            <ListItem button onClick={ordersClick}>
              {/* <ListItemIcon>
            <ShoppingBagOutlined
              sx={orders || ordersFocus ? ordersStyle : { color: "#000" }}
            />
          </ListItemIcon> */}
              <ListItemText
                // primary="Orders"
                sx={orders || ordersFocus ? ordersStyle : { color: "#000" }}
              >
                <ul>
                  <li>Orders</li>
                </ul>
              </ListItemText>
              {orders ? (
                <ExpandMoreIcon
                  sx={orders || ordersFocus ? ordersStyle : { color: "#000" }}
                />
              ) : (
                <KeyboardArrowRightIcon
                  sx={orders || ordersFocus ? ordersStyle : { color: "#000" }}
                />
              )}
            </ListItem>
          </div>
          <Collapse in={orders} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {loginResponse?.permission?.pointOfSale?.orders?.viewOrders ===
                true && (
                <div
                  // id="viewOrders"
                  className="purchase-manage-item"
                >
                  <NavLink
                    to="orders/viewOrders"
                    style={({ isActive }) =>
                      isActive
                        ? ordersStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem button>
                      <ul>
                        <li>
                          <ListItemText primary="View Orders"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}

              {loginResponse?.permission?.pointOfSale?.orders?.workOrder ===
                true && (
                <div
                  // id="workOrder"
                  className="purchase-manage-item"
                >
                  <NavLink
                    to="orders/workOrder"
                    style={({ isActive }) =>
                      isActive
                        ? ordersStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem button>
                      <ul>
                        <li>
                          <ListItemText primary="Work Order"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}
              {loginResponse?.permission?.pointOfSale?.orders
                ?.printCuttingSlip === true && (
                <div
                  //  id="printCuttingSlip"
                  className="purchase-manage-item"
                >
                  <NavLink
                    to="orders/printCuttingSlip"
                    style={({ isActive }) =>
                      isActive
                        ? ordersStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem button>
                      <ul>
                        <li>
                          <ListItemText primary="Print Cutting Slip"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}

              {loginResponse?.permission?.pointOfSale?.orders?.jobCompletion ===
                true && (
                <div
                  // id="jobCompletion"
                  className="purchase-manage-item"
                >
                  <NavLink
                    to="orders/jobCompletion"
                    style={({ isActive }) =>
                      isActive
                        ? ordersStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem button>
                      <ul>
                        <li>
                          <ListItemText primary="Job Completion"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}
              {loginResponse?.permission?.pointOfSale?.orders?.alteration ===
                true && (
                <div
                  //  id="alteration"
                  className="purchase-manage-item"
                >
                  <NavLink
                    to="orders/alteration"
                    style={({ isActive }) =>
                      isActive
                        ? ordersStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem button>
                      <ul>
                        <li>
                          <ListItemText primary="Alteration"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}

              {loginResponse?.permission?.pointOfSale?.orders?.delivery ===
                true && (
                <div
                  // id="delivery"
                  className="purchase-manage-item"
                >
                  <NavLink
                    to="orders/delivery"
                    style={({ isActive }) =>
                      isActive
                        ? ordersStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem button>
                      <ul>
                        <li>
                          <ListItemText primary="Delivery"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}
            </List>
          </Collapse>
          <div
            // id="sales"
            className={
              salesFocus || sales ? "sales-sidemenu-container" : "focus-none"
            }
          >
            <ListItem button onClick={salesClick}>
              {/* <ListItemIcon>
            <MonetizationOnOutlined
              sx={salesFocus || sales ? salesStyle : { color: "#000" }}
            />
          </ListItemIcon> */}
              <ListItemText
                // primary="Billing"
                sx={salesFocus || sales ? salesStyle : { color: "#000" }}
              >
                <ul>
                  <li>Billing</li>
                </ul>
              </ListItemText>
              {sales ? (
                <ExpandMoreIcon
                  sx={salesFocus || sales ? salesStyle : { color: "#000" }}
                />
              ) : (
                <KeyboardArrowRightIcon
                  sx={salesFocus || sales ? salesStyle : { color: "#000" }}
                />
              )}
            </ListItem>
          </div>
          <Collapse in={sales} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {userRole==="user" && loginResponse?.permission?.pointOfSale?.billing?.billing ===
                true && (
                <div
                  // id="billing"
                  className="purchase-manage-item"
                >
                  <NavLink
                    to="sales/billingnew"
                    style={({ isActive }) =>
                      isActive
                        ? salesStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem button>
                      <ul>
                        <li>
                          <ListItemText primary="Billing"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}

              {loginResponse?.permission?.pointOfSale?.billing?.orderList ===
                true && (
                <div
                  //  id="orderList"
                  className="purchase-manage-item"
                >
                  <NavLink
                    to="sales/orderList"
                    style={({ isActive }) =>
                      isActive
                        ? salesStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem button>
                      <ul>
                        <li>
                          <ListItemText primary="Order List"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}

              {loginResponse?.permission?.pointOfSale?.billing?.receipts ===
                true && (
                <div
                  // id="payments"
                  className="purchase-manage-item"
                >
                  <NavLink
                    to="sales/payments"
                    style={({ isActive }) =>
                      isActive
                        ? salesStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem button>
                      <ul>
                        <li>
                          <ListItemText primary="Receipts"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}

              {loginResponse?.permission?.pointOfSale?.billing?.return ===
                true && (
                <div
                  //  id="salesReturn"
                  className="purchase-manage-item"
                >
                  <NavLink
                    to="sales/salesReturn"
                    style={({ isActive }) =>
                      isActive
                        ? salesStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem button>
                      <ul>
                        <li>
                          <ListItemText primary="Return"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}

              {loginResponse?.permission?.pointOfSale?.billing?.credit ===
                true && (
                <div
                  //  id="credit"
                  className="purchase-manage-item"
                >
                  <NavLink
                    to="sales/credit"
                    style={({ isActive }) =>
                      isActive
                        ? salesStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem button>
                      <ul>
                        <li>
                          <ListItemText primary="Credit"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}

              {loginResponse?.permission?.pointOfSale?.billing?.wallet ===
                true && (
                <div
                  // id="wallet"
                  className="purchase-manage-item"
                >
                  <NavLink
                    to="wallet"
                    style={({ isActive }) =>
                      isActive
                        ? salesStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                    className={({ isActive }) =>
                      isActive ? setWalletFocus(true) : setWalletFocus(false)
                    }
                  >
                    <ListItem button>
                      <ul>
                        <li>
                          <ListItemText primary="Wallet"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}

              {loginResponse?.permission?.pointOfSale?.billing?.oldStock ===
                true && (
                <div
                  //  id="oldStock"
                  className="purchase-manage-item"
                >
                  <NavLink
                    to="sales/oldStock"
                    style={({ isActive }) =>
                      isActive
                        ? salesStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem button>
                      <ul>
                        <li>
                          <ListItemText primary="Old Stock"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}

              {loginResponse?.permission?.pointOfSale?.billing?.damagedGoods ===
                true && (
                <div
                  // id="damagedGoods"
                  className="purchase-manage-item"
                >
                  <NavLink
                    to="sales/damagedGoods"
                    style={({ isActive }) =>
                      isActive
                        ? salesStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem button>
                      <ul>
                        <li>
                          <ListItemText primary="Damaged Goods"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}

              {loginResponse?.permission?.pointOfSale?.billing?.quickBill ===
                true && (
                <div
                  //  id="quickBill"
                  className="purchase-manage-item"
                >
                  <NavLink
                    to="sales/quickBill"
                    style={({ isActive }) =>
                      isActive
                        ? salesStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem button>
                      <ul>
                        <li>
                          <ListItemText primary="Quick Bill"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}
            </List>
          </Collapse>

          <div
            // id="expense"
            className={
              expenseFocus || expense
                ? "expense-sidemenu-container"
                : "focus-none"
            }
          >
            <ListItem button onClick={expenseClick}>
              {/* <ListItemIcon>
            <AccountCircleOutlinedIcon
              sx={expenseFocus || expense ? expenseStyle : { color: "#000" }}
            />
          </ListItemIcon> */}
              <ListItemText
                // primary="Expense"
                sx={expenseFocus || expense ? expenseStyle : { color: "#000" }}
              >
                <ul>
                  <li>Expense</li>
                </ul>
              </ListItemText>
              {expense ? (
                <ExpandMoreIcon
                  sx={
                    expenseFocus || expense ? expenseStyle : { color: "#000" }
                  }
                />
              ) : (
                <KeyboardArrowRightIcon
                  sx={
                    expenseFocus || expense ? expenseStyle : { color: "#000" }
                  }
                />
              )}
            </ListItem>
          </div>
          <Collapse in={expense} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {loginResponse?.permission?.pointOfSale?.expense?.staffExpense ===
                true && (
                <div
                  // id="staffExpense"
                  className="purchase-manage-item"
                >
                  <NavLink
                    to="Expense/staffExpense"
                    style={({ isActive }) =>
                      isActive
                        ? expenseStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem button>
                      <ul>
                        <li>
                          <ListItemText primary="Staff Expense"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}
              {loginResponse?.permission?.pointOfSale?.expense
                ?.outletExpense === true && (
                <div
                  // id="outletExpense"
                  className="purchase-manage-item"
                >
                  <NavLink
                    to="expense/outletExpense"
                    style={({ isActive }) =>
                      isActive
                        ? expenseStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem button>
                      <ul>
                        <li>
                          <ListItemText primary="Outlet Expense"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}
              {userRole === "admin" &&
                loginResponse?.permission?.pointOfSale?.expense
                  ?.pettyCashReceipt === true && (
                  <div
                    // id="pettyCashReceipt"
                    className="purchase-manage-item"
                  >
                    <NavLink
                      to="expense/pettyCashReceipt"
                      style={({ isActive }) =>
                        isActive
                          ? expenseStyle
                          : { textDecoration: "none", color: "#000" }
                      }
                    >
                      <ListItem button>
                        <ul>
                          <li>
                            <ListItemText primary="Petty Cash Receipt"></ListItemText>
                          </li>
                        </ul>
                      </ListItem>
                    </NavLink>
                  </div>
                )}
              {loginResponse?.permission?.pointOfSale?.expense
                ?.addExpenseType === true && (
                <div
                  // id="addExpenseType"
                  className="purchase-manage-item"
                >
                  <NavLink
                    to="expense/addExpenseType"
                    style={({ isActive }) =>
                      isActive
                        ? expenseStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem button>
                      <ul>
                        <li>
                          <ListItemText primary="Add Expense Type"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}
            </List>
          </Collapse>
          <div
            // id="rewards"
            className={
              rewardPointsFocus || rewardPoints
                ? "rewards-sidemenu-container"
                : "focus-none"
            }
          >
            <ListItem button onClick={rewardPointClick}>
              {/* <ListItemIcon>
            <StarsOutlined
              sx={
                rewardPointsFocus || rewardPoints
                  ? rewardStyle
                  : { color: "#000" }
              }
            />
          </ListItemIcon> */}
              <ListItemText
                // primary="Rewards & Points"
                sx={
                  rewardPointsFocus || rewardPoints
                    ? rewardStyle
                    : { color: "#000" }
                }
              >
                <ul>
                  <li>Rewards</li>
                </ul>
              </ListItemText>
              {rewardPoints ? (
                <ExpandMoreIcon
                  sx={
                    rewardPointsFocus || rewardPoints
                      ? rewardStyle
                      : { color: "#000" }
                  }
                />
              ) : (
                <KeyboardArrowRightIcon
                  sx={
                    rewardPointsFocus || rewardPoints
                      ? rewardStyle
                      : { color: "#000" }
                  }
                />
              )}
            </ListItem>
          </div>
          <Collapse in={rewardPoints} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {loginResponse?.permission?.pointOfSale?.rewards?.rewardsView ===
                true && (
                <div
                  //  id="rewardView"
                  className="purchase-manage-item"
                >
                  <NavLink
                    to="rewardsAndPoints/rewardView"
                    style={({ isActive }) =>
                      isActive
                        ? rewardStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem button>
                      <ul>
                        <li>
                          <ListItemText primary="Rewards View"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}
              {loginResponse?.permission?.pointOfSale?.rewards?.addRewards ===
                true && (
                <div
                  // id="addRewards"
                  className="purchase-manage-item"
                >
                  <NavLink
                    to="rewardsAndPoints/addRewards"
                    style={({ isActive }) =>
                      isActive
                        ? rewardStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem button>
                      <ul>
                        <li>
                          <ListItemText primary="Add Rewards"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}
            </List>
          </Collapse>
          <div
            // id="offers"
            className={
              offersFocus || offers ? "offer-sidemenu-container" : "focus-none"
            }
          >
            <ListItem button onClick={offersClick}>
              {/* <ListItemIcon>
            <LocalOfferOutlined
              sx={offersFocus || offers ? offerStyle : { color: "#000" }}
            />
          </ListItemIcon> */}
              <ListItemText
                // primary="Offers"
                sx={offersFocus || offers ? offerStyle : { color: "#000" }}
              >
                <ul>
                  <li>Offers</li>
                </ul>
              </ListItemText>
              {offers ? (
                <ExpandMoreIcon
                  sx={offersFocus || offers ? offerStyle : { color: "#000" }}
                />
              ) : (
                <KeyboardArrowRightIcon
                  sx={offersFocus || offers ? offerStyle : { color: "#000" }}
                />
              )}
            </ListItem>
          </div>
          <Collapse in={offers} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {loginResponse?.permission?.pointOfSale?.offers?.offerListed ===
                true && (
                <div
                  //  id="offersList"
                  className="purchase-manage-item"
                >
                  <NavLink
                    to="offers/offersList"
                    style={({ isActive }) =>
                      isActive
                        ? offerStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem button>
                      <ul>
                        <li>
                          <ListItemText primary="Offer List"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}
              {loginResponse?.permission?.pointOfSale?.offers?.addOffer ===
                true && (
                <div
                  // id="addOffer"
                  className="purchase-manage-item"
                >
                  <NavLink
                    to="offers/addOffer"
                    style={({ isActive }) =>
                      isActive
                        ? offerStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem button>
                      <ul>
                        <li>
                          <ListItemText primary="Add Offer"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}
            </List>
          </Collapse>
          <div>
            <ListItem button onClick={posConfigurationClick}>
              <ListItemText
                sx={posConfiguration ? staffStyle : { color: "#000" }}
              >
                <ul>
                  <li>Configuration</li>
                </ul>
              </ListItemText>
              {posConfiguration ? (
                <ExpandMoreIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </ListItem>
          </div>
          <Collapse in={posConfiguration} timeout="auto" unmountOnExit>
            {loginResponse?.permission?.pointOfSale?.configuration?.settings ===
              true && (
              <div className="purchase-manage-item">
                <NavLink
                  to="pointOfSales/configuration/settings"
                  style={({ isActive }) =>
                    isActive
                      ? rewardStyle
                      : { textDecoration: "none", color: "#000" }
                  }
                >
                  <ListItem button>
                    <ul>
                      <li>
                        <ListItemText primary="Settings"></ListItemText>
                      </li>
                    </ul>
                  </ListItem>
                </NavLink>
              </div>
            )}
            {loginResponse?.permission?.pointOfSale?.configuration
              ?.branchSettings === true && (
              <div className="purchase-manage-item">
                <NavLink
                  to="pointOfSales/configuration/BranchSettings"
                  style={({ isActive }) =>
                    isActive
                      ? rewardStyle
                      : { textDecoration: "none", color: "#000" }
                  }
                >
                  <ListItem button>
                    <ul>
                      <li>
                        <ListItemText primary="Branch Settings"></ListItemText>
                      </li>
                    </ul>
                  </ListItem>
                </NavLink>
              </div>
            )}
          </Collapse>
        </List>
      </Collapse>

      {/* customer */}

      <div
        // id="customers"
        className={
          customerFocus || customers
            ? "customer-sidemenu-container"
            : "focus-none"
        }
      >
        <ListItem button onClick={customerClick}>
          <ListItemIcon>
            <AccountCircleOutlinedIcon
              sx={
                customerFocus || customers ? customerStyle : { color: "#000" }
              }
            />
          </ListItemIcon>
          <ListItemText
            primary="Customer"
            sx={customerFocus || customers ? customerStyle : { color: "#000" }}
          ></ListItemText>
          {customers ? (
            <ExpandMoreIcon
              sx={
                customerFocus || customers ? customerStyle : { color: "#000" }
              }
            />
          ) : (
            <KeyboardArrowRightIcon
              sx={
                customerFocus || customers ? customerStyle : { color: "#000" }
              }
            />
          )}
        </ListItem>
      </div>
      <Collapse in={customers} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <div
            // id="allCustomer"
            className="purchase-manage-item"
          >
            <NavLink
              to="customer/allCustomer"
              style={({ isActive }) =>
                isActive
                  ? customerStyle
                  : { textDecoration: "none", color: "#000" }
              }
            >
              <ListItem button>
                <ul>
                  <li>
                    <ListItemText primary="All Customer"></ListItemText>
                  </li>
                </ul>
              </ListItem>
            </NavLink>
          </div>

          {/* <div
                  // id="addCustomer"
                  className="purchase-manage-item"
                >
                  <NavLink
                    to="customer/addCustomer"
                    style={({ isActive }) =>
                      isActive
                        ? customerStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem button>
                      <ul>
                        <li>
                          <ListItemText primary="Add Customer"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div> */}
        </List>
      </Collapse>

      {/* Reports */}

      <div
        // id="report"
        className={
          reportFocus || report ? "report-sidemenu-container" : "focus-none"
        }
      >
        <ListItem button onClick={reportClick}>
          <ListItemIcon>
            <DescriptionOutlined
              sx={reportFocus || report ? reportStyle : { color: "#000" }}
            />
          </ListItemIcon>
          <ListItemText
            primary="Report"
            sx={reportFocus || report ? reportStyle : { color: "#000" }}
          ></ListItemText>
          {report ? (
            <ExpandMoreIcon
              sx={reportFocus || report ? reportStyle : { color: "#000" }}
            />
          ) : (
            <KeyboardArrowRightIcon
              sx={reportFocus || report ? reportStyle : { color: "#000" }}
            />
          )}
        </ListItem>
      </div>
      <Collapse in={report} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <div
            // id="stockReport"
            className="purchase-manage-item"
          >
            <NavLink
              to="report/posSalesReport"
              style={({ isActive }) =>
                isActive
                  ? reportStyle
                  : { textDecoration: "none", color: "#000" }
              }
            >
              <ListItem button>
                <ul>
                  <li>
                    <ListItemText primary="POS Sales Report"></ListItemText>
                  </li>
                </ul>
              </ListItem>
            </NavLink>
          </div>
          <div
            // id="stockReport"
            className="purchase-manage-item"
          >
            <NavLink
              to="report/CustomerCreditReport"
              style={({ isActive }) =>
                isActive
                  ? reportStyle
                  : { textDecoration: "none", color: "#000" }
              }
            >
              <ListItem button>
                <ul>
                  <li>
                    <ListItemText primary="Customer Credit Report"></ListItemText>
                  </li>
                </ul>
              </ListItem>
            </NavLink>
          </div>
          {loginResponse?.permission?.report?.stockReport === true && (
            <div
              // id="stockReport"
              className="purchase-manage-item"
            >
              <NavLink
                to="report/stockReport"
                style={({ isActive }) =>
                  isActive
                    ? reportStyle
                    : { textDecoration: "none", color: "#000" }
                }
              >
                <ListItem button>
                  <ul>
                    <li>
                      <ListItemText primary="Stock Report"></ListItemText>
                    </li>
                  </ul>
                </ListItem>
              </NavLink>
            </div>
          )}
          {loginResponse?.permission?.report?.dailyReport === true && (
            <div
              // id="dailyReport"
              className="purchase-manage-item"
            >
              <NavLink
                to="report/dailyReport"
                style={({ isActive }) =>
                  isActive
                    ? reportStyle
                    : { textDecoration: "none", color: "#000" }
                }
              >
                <ListItem button>
                  <ul>
                    <li>
                      <ListItemText primary="Daily Report"></ListItemText>
                    </li>
                  </ul>
                </ListItem>
              </NavLink>
            </div>
          )}
          {loginResponse?.permission?.report?.dailyReport === true && (
            <div
              //  id="dailyCashCardSummary"
              className="purchase-manage-item"
            >
              <NavLink
                to="report/dailyCashCardSummary"
                style={({ isActive }) =>
                  isActive
                    ? reportStyle
                    : { textDecoration: "none", color: "#000" }
                }
              >
                <ListItem button>
                  <ul>
                    <li>
                      <ListItemText primary="Daily Cash/Card Summary"></ListItemText>
                    </li>
                  </ul>
                </ListItem>
              </NavLink>
            </div>
          )}

<div
              //  id="dailyCashCardSummary"
              className="purchase-manage-item"
            >
              <NavLink
                to="report/shiftReport"
                style={({ isActive }) =>
                  isActive
                    ? reportStyle
                    : { textDecoration: "none", color: "#000" }
                }
              >
                <ListItem button>
                  <ul>
                    <li>
                      <ListItemText primary="Shift Report"></ListItemText>
                    </li>
                  </ul>
                </ListItem>
              </NavLink>
            </div>

          {loginResponse?.permission?.report?.expenseReport === true && (
            <div
              //  id="expenseReport"
              className="purchase-manage-item"
            >
              <NavLink
                to="report/expenseReport"
                style={({ isActive }) =>
                  isActive
                    ? reportStyle
                    : { textDecoration: "none", color: "#000" }
                }
              >
                <ListItem button>
                  <ul>
                    <li>
                      <ListItemText primary="Expense Report"></ListItemText>
                    </li>
                  </ul>
                </ListItem>
              </NavLink>
            </div>
          )}
          {loginResponse?.permission?.report?.paymentReport === true && (
            <div
              //  id="paymentReport"
              className="purchase-manage-item"
            >
              <NavLink
                to="report/paymentReport"
                style={({ isActive }) =>
                  isActive
                    ? reportStyle
                    : { textDecoration: "none", color: "#000" }
                }
              >
                <ListItem button>
                  <ul>
                    <li>
                      <ListItemText primary="Payment Report"></ListItemText>
                    </li>
                  </ul>
                </ListItem>
              </NavLink>
            </div>
          )}
          {loginResponse?.permission?.report?.commissionReport === true && (
            <div
              //  id="commissionReport"
              className="purchase-manage-item"
            >
              <NavLink
                to="report/commissionReport"
                style={({ isActive }) =>
                  isActive
                    ? reportStyle
                    : { textDecoration: "none", color: "#000" }
                }
              >
                <ListItem button>
                  <ul>
                    <li>
                      <ListItemText primary="Commission Report"></ListItemText>
                    </li>
                  </ul>
                </ListItem>
              </NavLink>
            </div>
          )}
          {loginResponse?.permission?.report?.quickbillReport === true && (
            <div
              // id="quickBillReport"
              className="purchase-manage-item"
            >
              <NavLink
                to="report/quickBillReport"
                style={({ isActive }) =>
                  isActive
                    ? reportStyle
                    : { textDecoration: "none", color: "#000" }
                }
              >
                <ListItem button>
                  <ul>
                    <li>
                      <ListItemText primary="Quick Bill Report"></ListItemText>
                    </li>
                  </ul>
                </ListItem>
              </NavLink>
            </div>
          )}
          {loginResponse?.permission?.report?.jobCompletion === true && (
            <div
              // id="jobCompletionReport"
              className="purchase-manage-item"
            >
              <NavLink
                to="report/jobCompletionReport"
                style={({ isActive }) =>
                  isActive
                    ? reportStyle
                    : { textDecoration: "none", color: "#000" }
                }
              >
                <ListItem button>
                  <ul>
                    <li>
                      <ListItemText primary="Job Completion Report"></ListItemText>
                    </li>
                  </ul>
                </ListItem>
              </NavLink>
            </div>
          )}
        </List>
      </Collapse>

      {/* Accounts */}
      <div
        // id="accounts"
        className={
          accountsFocus || accounts
            ? "accounts-sidemenu-container"
            : "focus-none"
        }
      >
        <ListItem button onClick={accountsClick}>
          <ListItemIcon>
            <AccountTreeOutlined
              sx={accountsFocus || accounts ? accountsStyle : { color: "#000" }}
            />
          </ListItemIcon>
          <ListItemText
            primary="Accounts"
            sx={accountsFocus || accounts ? accountsStyle : { color: "#000" }}
          ></ListItemText>
          {accounts ? (
            <ExpandMoreIcon
              sx={accountsFocus || accounts ? accountsStyle : { color: "#000" }}
            />
          ) : (
            <KeyboardArrowRightIcon
              sx={accountsFocus || accounts ? accountsStyle : { color: "#000" }}
            />
          )}
        </ListItem>
      </div>

      <Collapse in={accounts} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <div
            // id="Create invoice"
            className="purchase-manage-item stock-adjustment-sidemenu"
          >
            <ListItem button onClick={AccountsCustomerClick}>
              <ListItemText
                sx={AccountsCustomers ? staffStyle : { color: "#000" }}
              >
                <ul>
                  <li>Customers</li>
                </ul>
              </ListItemText>
              {AccountsCustomers ? (
                <ExpandMoreIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </ListItem>
          </div>

          <Collapse in={AccountsCustomers} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {loginResponse?.permission?.account?.customer
                ?.customerInvoices === true && (
                <div
                  // id="accounting"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="accounts/customers/customerInvoices"
                    style={({ isActive }) =>
                      isActive
                        ? staffStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Customer Invoices"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}

              {loginResponse?.permission?.account?.customer?.salesWso ===
                true && (
                <div
                  // id="accounting"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="accounts/customers/saleswso"
                    style={({ isActive }) =>
                      isActive
                        ? staffStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Sales W/SO"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}

              {loginResponse?.permission?.account?.customer?.payments ===
                true && (
                <div
                  // id="inventory"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="accounts/customer/payments"
                    style={({ isActive }) =>
                      isActive
                        ? staffStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Payments"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}

              {loginResponse?.permission?.account?.customer?.creditNotes ===
                true && (
                <div
                  // id="Payroll"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="accounts/customers/creditNotes"
                    style={({ isActive }) =>
                      isActive
                        ? staffStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Credit Notes"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}

              {loginResponse?.permission?.account?.customer?.customers ===
                true && (
                <div
                  // id="Recurring Posting"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="accounts/customers/customers"
                    style={({ isActive }) =>
                      isActive
                        ? staffStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Customers"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}
            </List>
          </Collapse>
          <div
            // id="Create invoice"
            className="purchase-manage-item stock-adjustment-sidemenu"
          >
            <ListItem button onClick={AccountingVendorClick}>
              <ListItemText
                sx={accountingVendor ? staffStyle : { color: "#000" }}
              >
                <ul>
                  <li>Vendor</li>
                </ul>
              </ListItemText>
              {accountingVendor ? (
                <ExpandMoreIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </ListItem>
          </div>

          <Collapse in={accountingVendor} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {loginResponse?.permission?.account?.vendor?.vendorBills ===
                true && (
                <div
                  // id="accounting"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="accounts/vendor/vendorBills"
                    style={({ isActive }) =>
                      isActive
                        ? staffStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Vendor Bills"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}

              {loginResponse?.permission?.account?.vendor?.purchaseWpo ===
                true && (
                <div
                  // id="accounting"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="accounts/vendor/purchasewopo"
                    style={({ isActive }) =>
                      isActive
                        ? staffStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Purchase W/PO"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}
              {loginResponse?.permission?.account?.vendor?.payments ===
                true && (
                <div
                  // id="inventory"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="accounts/vendor/payments"
                    style={({ isActive }) =>
                      isActive
                        ? staffStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Payments"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}
              {loginResponse?.permission?.account?.vendor?.debitNotes ===
                true && (
                <div
                  // id="Payroll"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="accounts/vendor/DebitNotes"
                    style={({ isActive }) =>
                      isActive
                        ? staffStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Debit Notes"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}
              {loginResponse?.permission?.account?.vendor?.vendor === true && (
                <div
                  // id="Recurring Posting"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="accounts/vendor/vendors"
                    style={({ isActive }) =>
                      isActive
                        ? staffStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Vendors"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}
            </List>
          </Collapse>

          <div
            // id="Create invoice"
            className="purchase-manage-item stock-adjustment-sidemenu"
          >
            <ListItem button onClick={AccountContraVoucherClick}>
              <ListItemText sx={contraVoucher ? staffStyle : { color: "#000" }}>
                <ul>
                  <li>Accounting</li>
                </ul>
              </ListItemText>
              {contraVoucher ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
            </ListItem>
          </div>
          <Collapse in={contraVoucher} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {loginResponse?.permission?.account?.accounting?.journal ===
                true && (
                <div
                  // id="accounting"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="accounts/accounting/journal"
                    style={({ isActive }) =>
                      isActive
                        ? staffStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Journal"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}
              {loginResponse?.permission?.account?.accounting
                ?.chartOfAccounts === true && (
                <div
                  // id="inventory"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="accounts/accounting/chartofaccounts"
                    style={({ isActive }) =>
                      isActive
                        ? staffStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Chart Of Account"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}
                <div
                  // id="inventory"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="accounts/accounting/openingBalance"
                    style={({ isActive }) =>
                      isActive
                        ? staffStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Opening Balance"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              {loginResponse?.permission?.account?.accounting
                ?.journalEntries === true && (
                <div
                  // id="Payroll"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="accounts/accounting/journalEntries"
                    style={({ isActive }) =>
                      isActive
                        ? staffStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Journal Entries"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}

              {/* <div
                    // id="Recurring Posting"
                    className="stock-adjustment-item"
                  >
                    <NavLink
                      to="accounts/accounting/recurringposting"
                      style={({ isActive }) =>
                        isActive
                          ? staffStyle
                          : { textDecoration: "none", color: "#000" }
                      }
                    >
                      <ListItem>
                        <ul>
                          <li>
                            <ListItemText primary="Recurring Posting"></ListItemText>
                          </li>
                        </ul>
                      </ListItem>
                    </NavLink>
                  </div> */}
            </List>
          </Collapse>

          <div
            // id="Create invoice"
            className="purchase-manage-item stock-adjustment-sidemenu"
          >
            <ListItem button onClick={ReconciliationClick}>
              <ListItemText
                sx={reconciliation ? staffStyle : { color: "#000" }}
              >
                <ul>
                  <li>Reconciliation</li>
                </ul>
              </ListItemText>
              {reconciliation ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
            </ListItem>
          </div>
          <Collapse in={reconciliation} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <div
                // id="accounting"
                className="stock-adjustment-item"
              >
                <NavLink
                  to="accounts/Reconciliation/BankReconciliation"
                  style={({ isActive }) =>
                    isActive
                      ? staffStyle
                      : { textDecoration: "none", color: "#000" }
                  }
                >
                  <ListItem>
                    <ul>
                      <li>
                        <ListItemText primary="Bank Reconciliation"></ListItemText>
                      </li>
                    </ul>
                  </ListItem>
                </NavLink>
              </div>
            </List>
          </Collapse>
          {/* VAT REPORT  */}
          <div
            // id="Create invoice"
            className="purchase-manage-item stock-adjustment-sidemenu"
          >
            <ListItem button onClick={VatReportClick}>
              <ListItemText sx={vatReport ? staffStyle : { color: "#000" }}>
                <ul>
                  <li>VAT Report</li>
                </ul>
              </ListItemText>
              {vatReport ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
            </ListItem>
          </div>
          <Collapse in={vatReport} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <div
                // id="accounting"
                className="stock-adjustment-item"
              >
                <NavLink
                  to="accounts/vatReport/inputOrOutputReport"
                  style={({ isActive }) =>
                    isActive
                      ? staffStyle
                      : { textDecoration: "none", color: "#000" }
                  }
                >
                  <ListItem>
                    <ul>
                      <li>
                        <ListItemText primary="Input / Output Report"></ListItemText>
                      </li>
                    </ul>
                  </ListItem>
                </NavLink>
              </div>
            </List>
          </Collapse>

          {/* reports side menu  */}
          <div
            // id="Create invoice"
            className="purchase-manage-item stock-adjustment-sidemenu"
          >
            <ListItem button onClick={AccountReportNewClick}>
              <ListItemText sx={reportNew ? staffStyle : { color: "#000" }}>
                <ul>
                  <li>Reports</li>
                </ul>
              </ListItemText>
              {reportNew ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
            </ListItem>
          </div>
          <Collapse in={reportNew} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <>
                <div
                  // id="Create invoice"
                  className="purchase-manage-item stock-adjustment-sidemenu"
                  style={{ marginLeft: "12%" }}
                >
                  <ListItem button onClick={FinancialReportNewClick}>
                    <ListItemText
                      sx={
                        reportNewFinancial ? settingsStyle : { color: "#000" }
                      }
                    >
                      <ul>
                        <li>Financial Reports</li>
                      </ul>
                    </ListItemText>
                    {reportNewFinancial ? (
                      <ExpandMoreIcon />
                    ) : (
                      <KeyboardArrowRightIcon />
                    )}
                  </ListItem>
                </div>
                <Collapse
                  in={reportNewFinancial}
                  timeout="auto"
                  unmountOnExit
                  sx={{ marginLeft: "8%" }}
                >
                  <List component="div" disablePadding>
                    {loginResponse?.permission?.account?.reports
                      ?.trialBalance === true && (
                      <div
                        // id="Payroll"
                        className="stock-adjustment-item"
                      >
                        <NavLink
                          to="accounts/report/financialReport/trialbalance"
                          style={({ isActive }) =>
                            isActive
                              ? settingsStyle
                              : { textDecoration: "none", color: "#000" }
                          }
                        >
                          <ListItem>
                            <ul>
                              <li>
                                <ListItemText primary="Trial Balance "></ListItemText>
                              </li>
                            </ul>
                          </ListItem>
                        </NavLink>
                      </div>
                    )}

                    <div
                      // id="Payroll"
                      className="stock-adjustment-item"
                    >
                      <NavLink
                        to="accounts/report/financialReport/balancesheet"
                        style={({ isActive }) =>
                          isActive
                            ? settingsStyle
                            : { textDecoration: "none", color: "#000" }
                        }
                      >
                        <ListItem>
                          <ul>
                            <li>
                              <ListItemText primary="Balance Sheet "></ListItemText>
                            </li>
                          </ul>
                        </ListItem>
                      </NavLink>
                    </div>

                    {loginResponse?.permission?.account?.reports
                      ?.generalLedger === true && (
                      <div
                        // id="Payroll"
                        className="stock-adjustment-item"
                      >
                        <NavLink
                          to="accounts/report/financialReport/generalLedger"
                          style={({ isActive }) =>
                            isActive
                              ? settingsStyle
                              : { textDecoration: "none", color: "#000" }
                          }
                        >
                          <ListItem>
                            <ul>
                              <li>
                                <ListItemText primary="General Ledger"></ListItemText>
                              </li>
                            </ul>
                          </ListItem>
                        </NavLink>
                      </div>
                    )}

                    {loginResponse?.permission?.account?.reports
                      ?.profitAndLoss === true && (
                      <div
                        // id="Payroll"
                        className="stock-adjustment-item"
                      >
                        <NavLink
                          to="accounts/report/financialReport/Profit&Loss"
                          style={({ isActive }) =>
                            isActive
                              ? settingsStyle
                              : { textDecoration: "none", color: "#000" }
                          }
                        >
                          <ListItem>
                            <ul>
                              <li>
                                <ListItemText primary="Profit and Loss Report "></ListItemText>
                              </li>
                            </ul>
                          </ListItem>
                        </NavLink>
                      </div>
                    )}
                  </List>
                </Collapse>
                <div
                  // id="Create invoice"
                  className="purchase-manage-item stock-adjustment-sidemenu"
                  style={{ marginLeft: "12%" }}
                >
                  <ListItem button onClick={PartnerReportOnClick}>
                    <ListItemText
                      sx={partnerReport ? settingsStyle : { color: "#000" }}
                    >
                      <ul>
                        <li>Partner Reports</li>
                      </ul>
                    </ListItemText>
                    {partnerReport ? (
                      <ExpandMoreIcon />
                    ) : (
                      <KeyboardArrowRightIcon />
                    )}
                  </ListItem>
                </div>
                <Collapse
                  in={partnerReport}
                  timeout="auto"
                  unmountOnExit
                  sx={{ marginLeft: "8%" }}
                >
                  <List component="div" disablePadding>
                    {loginResponse?.permission?.account?.reports
                      ?.accountPayable === true && (
                      <div
                        // id="Payroll"
                        className="stock-adjustment-item"
                      >
                        <NavLink
                          to="accounts/report/partnerReport/accountPayable"
                          style={({ isActive }) =>
                            isActive
                              ? settingsStyle
                              : { textDecoration: "none", color: "#000" }
                          }
                        >
                          <ListItem>
                            <ul>
                              <li>
                                <ListItemText primary="Account Payable "></ListItemText>
                              </li>
                            </ul>
                          </ListItem>
                        </NavLink>
                      </div>
                    )}
                    {loginResponse?.permission?.account?.reports
                      ?.accountReceivable === true && (
                      <div
                        // id="Payroll"
                        className="stock-adjustment-item"
                      >
                        <NavLink
                          to="accounts/report/partnerReport/accountReceivable"
                          style={({ isActive }) =>
                            isActive
                              ? settingsStyle
                              : { textDecoration: "none", color: "#000" }
                          }
                        >
                          <ListItem>
                            <ul>
                              <li>
                                <ListItemText primary="Accounts Receivable "></ListItemText>
                              </li>
                            </ul>
                          </ListItem>
                        </NavLink>
                      </div>
                    )}
                    {/* {loginResponse?.permission?.account?.reports
                      ?.AccountReceivableAgingReport === true && ( */}
                      <div
                        // id="Payroll"
                        className="stock-adjustment-item"
                      >
                        <NavLink
                          to="accounts/report/partnerReport/AccountReceivableAgingReport"
                          style={({ isActive }) =>
                            isActive
                              ? settingsStyle
                              : { textDecoration: "none", color: "#000" }
                          }
                        >
                          <ListItem>
                            <ul>
                              <li>
                                <ListItemText primary="Aging Report Receivable "></ListItemText>
                              </li>
                            </ul>
                          </ListItem>
                        </NavLink>
                      </div>
                    {/* )} */}
                    {/* {loginResponse?.permission?.account?.reports
                      ?.AccountPayableAgingReport === true && ( */}
                      <div
                        // id="Payroll"
                        className="stock-adjustment-item"
                      >
                        <NavLink
                          to="accounts/report/partnerReport/AccountPayableAgingReport"
                          style={({ isActive }) =>
                            isActive
                              ? settingsStyle
                              : { textDecoration: "none", color: "#000" }
                          }
                        >
                          <ListItem>
                            <ul>
                              <li>
                                <ListItemText primary="Aging Report Payable "></ListItemText>
                              </li>
                            </ul>
                          </ListItem>
                        </NavLink>
                      </div>
                      <div
                        // id="Payroll"
                        className="stock-adjustment-item"
                      >
                        <NavLink
                          to="accounts/report/partnerReport/ReceivableDueReport"
                          style={({ isActive }) =>
                            isActive
                              ? settingsStyle
                              : { textDecoration: "none", color: "#000" }
                          }
                        >
                          <ListItem>
                            <ul>
                              <li>
                                <ListItemText primary="Receivable Due Report"></ListItemText>
                              </li>
                            </ul>
                          </ListItem>
                        </NavLink>
                      </div>
                      <div
                        // id="Payroll"
                        className="stock-adjustment-item"
                      >
                        <NavLink
                          to="accounts/report/partnerReport/PayableDueReport"
                          style={({ isActive }) =>
                            isActive
                              ? settingsStyle
                              : { textDecoration: "none", color: "#000" }
                          }
                        >
                          <ListItem>
                            <ul>
                              <li>
                                <ListItemText primary="Payable Due Report"></ListItemText>
                              </li>
                            </ul>
                          </ListItem>
                        </NavLink>
                      </div>
                    {/* )} */}
                  </List>
                </Collapse>
              </>

              {/* General Report    */}
              <div
                // id="Create invoice"
                className="purchase-manage-item stock-adjustment-sidemenu"
                style={{ marginLeft: "12%" }}
              >
                <ListItem button onClick={GeneralReportOnClick}>
                  <ListItemText
                    sx={generalReport ? settingsStyle : { color: "#000" }}
                  >
                    <ul>
                      <li>General Reports</li>
                    </ul>
                  </ListItemText>
                  {generalReport ? (
                    <ExpandMoreIcon />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )}
                </ListItem>
              </div>
              <Collapse
                in={generalReport}
                timeout="auto"
                unmountOnExit
                sx={{ marginLeft: "8%" }}
              >
                <List component="div" disablePadding>
                  {loginResponse?.permission?.account?.reports
                    ?.bankAndCashReport === true && (
                    <div
                      // id="Payroll"
                      className="stock-adjustment-item"
                    >
                      <NavLink
                        to="accounts/report/generalReport/bankAndCashReport"
                        style={({ isActive }) =>
                          isActive
                            ? settingsStyle
                            : { textDecoration: "none", color: "#000" }
                        }
                      >
                        <ListItem>
                          <ul>
                            <li>
                              <ListItemText primary="Bank & Cash Report"></ListItemText>
                            </li>
                          </ul>
                        </ListItem>
                      </NavLink>
                    </div>
                  )}
                   <div
                      // id="Payroll"
                      className="stock-adjustment-item"
                    >
                      <NavLink
                        to="accounts/report/generalReport/InvoiceMarginReport"
                        style={({ isActive }) =>
                          isActive
                            ? settingsStyle
                            : { textDecoration: "none", color: "#000" }
                        }
                      >
                        <ListItem>
                          <ul>
                            <li>
                              <ListItemText primary="Invoice Margin Report"></ListItemText>
                            </li>
                          </ul>
                        </ListItem>
                      </NavLink>
                    </div>
                 
                    <div
                      // id="Payroll"
                      className="stock-adjustment-item"
                    >
                      <NavLink
                        to="accounts/report/generalReport/productMarginReport"
                        style={({ isActive }) =>
                          isActive
                            ? settingsStyle
                            : { textDecoration: "none", color: "#000" }
                        }
                      >
                        <ListItem>
                          <ul>
                            <li>
                              <ListItemText primary="Product Margin Report"></ListItemText>
                            </li>
                          </ul>
                        </ListItem>
                      </NavLink>
                    </div>
                    <div className="stock-adjustment-item">
              <NavLink
                to="sales/Report/CustomerReceiptReport"
                style={({ isActive }) =>
                  isActive
                    ? rewardStyle
                    : { textDecoration: "none", color: "#000" }
                }
              >
                <ListItem button>
                  <ul>
                    <li>
                      <ListItemText primary="Customer Receipt Report"></ListItemText>
                    </li>
                  </ul>
                </ListItem>
              </NavLink>
            </div>
                </List>
              </Collapse>
            </List>
          </Collapse>

          {userRole === "admin" && (
            <div
              // id="Create invoice"
              className="purchase-manage-item stock-adjustment-sidemenu"
            >
              <NavLink
                to="accounts/configuration"
                style={({ isActive }) =>
                  isActive
                    ? staffStyle
                    : { textDecoration: "none", color: "#000" }
                }
              >
                <ListItem>
                  <ul>
                    <li>
                      <ListItemText primary="Configuration"></ListItemText>
                    </li>
                  </ul>
                </ListItem>
              </NavLink>
            </div>
          )}
        </List>
      </Collapse>

      {/* {payroll} */}
      <div
        // id="accounts"
        className={
          newPayrollFocus || accounts
            ? "accounts-sidemenu-container"
            : "focus-none"
        }
      >
        <ListItem button onClick={newPayrollClick}>
          <ListItemIcon>
            <AccountTreeOutlined
              sx={
                newPayrollFocus || newPayroll
                  ? payrollNewStyle
                  : { color: "#000" }
              }
            />
          </ListItemIcon>
          <ListItemText
            primary="Payroll"
            sx={
              newPayrollFocus || newPayroll
                ? payrollNewStyle
                : { color: "#000" }
            }
          ></ListItemText>
          {newPayroll ? (
            <ExpandMoreIcon
              sx={
                newPayrollFocus || newPayroll
                  ? payrollNewStyle
                  : { color: "#000" }
              }
            />
          ) : (
            <KeyboardArrowRightIcon
              sx={
                newPayrollFocus || newPayroll
                  ? payrollNewStyle
                  : { color: "#000" }
              }
            />
          )}
        </ListItem>
      </div>
      <Collapse in={newPayroll} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <div
            // id="staffDashboard"
            className="purchase-manage-item"
          >
            <NavLink
              to="payroll/Establishment"
              style={({ isActive }) =>
                isActive
                  ? payrollNewStyle
                  : { textDecoration: "none", color: "#000" }
              }
            >
              <ListItem button>
                <ul>
                  <li>
                    <ListItemText primary="Establishment ID"></ListItemText>
                  </li>
                </ul>
              </ListItem>
            </NavLink>
          </div>
          <div
            // id="hrm"
            className="purchase-manage-item stock-adjustment-sidemenu"
          >
            <ListItem button onClick={newPayrollAttendanceClick}>
              <ListItemText
                sx={attendanceFocus || attendance ? payrollNewStyle : { color: "#000" }}
              >
                <ul>
                  <li>Attendance</li>
                </ul>
              </ListItemText>

              {attendance ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
            </ListItem>
          </div>
          <div
            // id="staffDashboard"
            className="purchase-manage-item"
          >
            <NavLink
              to="payroll/contract"
              style={({ isActive }) =>
                isActive
                  ? payrollNewStyle
                  : { textDecoration: "none", color: "#000" }
              }
            >
              <ListItem button>
                <ul>
                  <li>
                    <ListItemText primary="Contract"></ListItemText>
                  </li>
                </ul>
              </ListItem>
            </NavLink>
          </div>
          <div
            // id="staffDashboard"
            className="purchase-manage-item"
          >
            <NavLink
              to="payroll/sponser"
              style={({ isActive }) =>
                isActive
                  ? payrollNewStyle
                  : { textDecoration: "none", color: "#000" }
              }
            >
              <ListItem button>
                <ul>
                  <li>
                    <ListItemText primary="Sponser"></ListItemText>
                  </li>
                </ul>
              </ListItem>
            </NavLink>
          </div>
          <div
            // id="hrm"
            className="purchase-manage-item stock-adjustment-sidemenu"
          >
            <ListItem button onClick={newPayrollAttendanceClick}>
              <ListItemText
                sx={attendanceFocus || attendance ? payrollNewStyle : { color: "#000" }}
              >
                <ul>
                  <li>Attendance</li>
                </ul>
              </ListItemText>

              {attendance ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
            </ListItem>
          </div>
          <Collapse in={attendance} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
            
                <div
                  // id="department"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="payroll/Attendance/Overtime"
                    style={({ isActive }) =>
                      isActive
                        ? payrollNewStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Overtime"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
             
            </List>
          </Collapse>
          <div
            // id="hrm"
            className="purchase-manage-item stock-adjustment-sidemenu"
          >
            <ListItem button onClick={handlePayrollConfigurationClick}>
              <ListItemText
                sx={payrollConfiguration ? payrollNewStyle : { color: "#000" }}
              >
                <ul>
                  <li>Configuration</li>
                </ul>
              </ListItemText>

              {payrollConfiguration ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
            </ListItem>
          </div>
          <Collapse in={payrollConfiguration} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
            
                <div
                  // id="department"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="payroll/configuration/payHead"
                    style={({ isActive }) =>
                      isActive
                        ? payrollNewStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Pay Head"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
             
            </List>
            <List component="div" disablePadding>
            
                <div
                  // id="department"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="payroll/configuration/payOther"
                    style={({ isActive }) =>
                      isActive
                        ? payrollNewStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Pay Other"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
             
            </List>
            <List component="div" disablePadding>
            
                <div
                  // id="department"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="payroll/configuration/payTemplate"
                    style={({ isActive }) =>
                      isActive
                        ? payrollNewStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Pay Template"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
             
            </List>
            <List component="div" disablePadding>
            
                <div
                  // id="department"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="payroll/configuration/settings"
                    style={({ isActive }) =>
                      isActive
                        ? payrollNewStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Settings"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
             
            </List>
          </Collapse>
        </List>
      </Collapse>

      {/* staff */}
      <div
        // id="staff"
        className={
          staffFocus || staff ? "staff-sidemenu-container" : "focus-none"
        }
      >
        <ListItem button onClick={staffClick}>
          <ListItemIcon>
            <ManageAccountsOutlined
              sx={staffFocus || staff ? staffStyle : { color: "#000" }}
            />
          </ListItemIcon>
          <ListItemText
            primary="Staff"
            sx={staffFocus || staff ? staffStyle : { color: "#000" }}
          >
            {" "}
          </ListItemText>
          {staff ? (
            <ExpandMoreIcon
              sx={staffFocus || staff ? staffStyle : { color: "#000" }}
            />
          ) : (
            <KeyboardArrowRightIcon
              sx={staffFocus || staff ? staffStyle : { color: "#000" }}
            />
          )}
        </ListItem>
      </div>
      <Collapse in={staff} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {loginResponse?.permission?.staff?.dashboard?.dashboard === true && (
            <div
              // id="staffDashboard"
              className="purchase-manage-item"
            >
              <NavLink
                to="staff/dashboard"
                style={({ isActive }) =>
                  isActive
                    ? staffStyle
                    : { textDecoration: "none", color: "#000" }
                }
              >
                <ListItem button>
                  <ul>
                    <li>
                      <ListItemText primary="Dashboard"></ListItemText>
                    </li>
                  </ul>
                </ListItem>
              </NavLink>
            </div>
          )}

          <div
            // id="hrm"
            className="purchase-manage-item stock-adjustment-sidemenu"
          >
            <ListItem button onClick={staffHrmClick}>
              <ListItemText
                sx={hrmFocus || hrm ? staffStyle : { color: "#000" }}
              >
                <ul>
                  <li>HRM</li>
                </ul>
              </ListItemText>

              {hrm ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
            </ListItem>
          </div>
          <Collapse in={hrm} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {loginResponse?.permission?.staff?.HRM?.department === true && (
                <div
                  // id="department"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="staff/hrm/department"
                    style={({ isActive }) =>
                      isActive
                        ? staffStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Department"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}
              {loginResponse?.permission?.staff?.HRM?.designation === true && (
                <div
                  // id="designation"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="staff/hrm/designation"
                    style={({ isActive }) =>
                      isActive
                        ? staffStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Designation"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}
              {loginResponse?.permission?.staff?.HRM?.manageEmployee ===
                true && (
                <div
                  // id="manageEmployee"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="staff/hrm/manageEmployee"
                    style={({ isActive }) =>
                      isActive
                        ? staffStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Manage Employee"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}

              {loginResponse?.permission?.staff?.HRM?.addEmployee === true && (
                <div
                  // id="addEmployee"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="staff/hrm/addEmployee"
                    style={({ isActive }) =>
                      isActive
                        ? staffStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Add Employee"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}

              {loginResponse?.permission?.staff?.HRM?.addDocument === true && (
                <div
                  // id="addDocument"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="staff/hrm/addDocument"
                    style={({ isActive }) =>
                      isActive
                        ? staffStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Add Document"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}
            </List>
          </Collapse>

          {loginResponse?.permission?.staff?.attendance?.attendance ===
            true && (
            <div
              // id="attendance"
              className="purchase-manage-item"
            >
              <NavLink
                to="staff/attendance"
                style={({ isActive }) =>
                  isActive
                    ? staffStyle
                    : { textDecoration: "none", color: "#000" }
                }
              >
                <ListItem button>
                  <ul>
                    <li>
                      <ListItemText primary="Attendance"></ListItemText>
                    </li>
                  </ul>
                </ListItem>
              </NavLink>
            </div>
          )}

          <div
            // id="leave"
            className="purchase-manage-item stock-adjustment-sidemenu"
          >
            <ListItem button onClick={leaveClick}>
              <ListItemText
                sx={leaveFocus || leave ? staffStyle : { color: "#000" }}
              >
                <ul>
                  <li>Leave</li>
                </ul>
              </ListItemText>

              {leave ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
            </ListItem>
          </div>
          <Collapse in={leave} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {loginResponse?.permission?.staff?.leave?.leaveApplication ===
                true && (
                <div
                  // id="leaveApplication"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="staff/leave/leaveApplication"
                    style={({ isActive }) =>
                      isActive
                        ? staffStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Leave Application"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}
              {loginResponse?.permission?.staff?.leave?.holiday === true && (
                <div
                  // id="holiday"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="staff/leave/holiday"
                    style={({ isActive }) =>
                      isActive
                        ? staffStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Holiday"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}

              {loginResponse?.permission?.staff?.leave?.addLeaveType ===
                true && (
                <div
                  // id="addLLeaveType"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="staff/leave/addLeaveType"
                    style={({ isActive }) =>
                      isActive
                        ? staffStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Add Leave Type"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}
            </List>
          </Collapse>

          <div
            // id="payroll"
            className="purchase-manage-item stock-adjustment-sidemenu"
          >
            <ListItem button onClick={payrollClick}>
              <ListItemText
                sx={payrollFocus || payRoll ? staffStyle : { color: "#000" }}
              >
                <ul>
                  <li>Payroll</li>
                </ul>
              </ListItemText>

              {payRoll ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
            </ListItem>
          </div>
          <Collapse in={payRoll} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {loginResponse?.permission?.staff?.payroll?.employeeSalary ===
                true && (
                <div
                  // id="employeeSalary"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="staff/payroll/employeeSalary"
                    style={({ isActive }) =>
                      isActive
                        ? staffStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Employee Salary"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}

              {loginResponse?.permission?.staff?.payroll?.addPayroll ===
                true && (
                <div
                  // id="addPayroll"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="staff/payroll/addPayRoll"
                    style={({ isActive }) =>
                      isActive
                        ? staffStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Add Payroll"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}
              {loginResponse?.permission?.staff?.payroll?.payrollItem ===
                true && (
                <div
                  // id="payrollItem"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="staff/payroll/payRollItem"
                    style={({ isActive }) =>
                      isActive
                        ? staffStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Payroll Item"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}

              {/* Staff payroll update */}
              {loginResponse?.permission?.staff?.payroll?.paySlip === true && (
                <div
                  // id="paySlip"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="staff/payroll/payslip"
                    style={({ isActive }) =>
                      isActive
                        ? staffStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Payslip"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}
            </List>
          </Collapse>

          <div
            //  id="loan"
            className="purchase-manage-item stock-adjustment-sidemenu"
          >
            <ListItem button onClick={loanClick}>
              <ListItemText
                sx={loanFocus || loan ? staffStyle : { color: "#000" }}
              >
                <ul>
                  <li>Loan</li>
                </ul>
              </ListItemText>

              {loan ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
            </ListItem>
          </div>
          <Collapse in={loan} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {loginResponse?.permission?.staff?.loan?.addNewLoan === true && (
                <div
                  // id="addNewLoan"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="staff/loan/addNewLoan"
                    style={({ isActive }) =>
                      isActive
                        ? staffStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Add New Loan"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}
              {loginResponse?.permission?.staff?.loan?.loanReport === true && (
                <div
                  // id="loanReport"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="staff/loan/loanReport"
                    style={({ isActive }) =>
                      isActive
                        ? staffStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Loan Report"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}
              {loginResponse?.permission?.staff?.loan?.loanApprovals ===
                true && (
                <div
                  // id="loanApprovals"
                  className="stock-adjustment-item"
                >
                  <NavLink
                    to="staff/loan/loanApprovals"
                    style={({ isActive }) =>
                      isActive
                        ? staffStyle
                        : { textDecoration: "none", color: "#000" }
                    }
                  >
                    <ListItem>
                      <ul>
                        <li>
                          <ListItemText primary="Loan Approvals"></ListItemText>
                        </li>
                      </ul>
                    </ListItem>
                  </NavLink>
                </div>
              )}
            </List>
          </Collapse>
        </List>
      </Collapse>

      {/* device settings */}
      {loginResponse?.permission?.deviceSettings === true && (
        <div
          // id="qrCode"
          className={
            devicefocus ? "devicesettings-sidemenu-container" : "focus-none"
          }
        >
          <NavLink
            to="Devicesettings"
            style={({ isActive }) =>
              isActive ? deviceStyle : { textDecoration: "none", color: "#000" }
            }
            className={({ isActive }) =>
              isActive ? setDevicefocus(true) : setDevicefocus(false)
            }
          >
            <ListItem button>
              <ListItemIcon>
                <SettingsBrightnessIcon
                  sx={devicefocus ? deviceStyle : { color: "#000" }}
                />
              </ListItemIcon>
              <ListItemText primary="Device Settings"></ListItemText>
            </ListItem>
          </NavLink>
        </div>
      )}

      {/* settings */}

      {userRole === "admin" && (
        <div
          // id="settings"
          className={
            settingsFocus ? "settings-sidemenu-container" : "focus-none"
          }
        >
          <NavLink
            to="settings"
            style={({ isActive }) =>
              isActive
                ? settingsStyle
                : { textDecoration: "none", color: "#000" }
            }
            className={({ isActive }) =>
              isActive ? setSettingsFocus(true) : setSettingsFocus(false)
            }
          >
            <ListItem button>
              <ListItemIcon>
                <SettingsOutlined
                  sx={settingsFocus ? settingsStyle : { color: "#000" }}
                />
              </ListItemIcon>
              <ListItemText primary="Settings"></ListItemText>
            </ListItem>
          </NavLink>
        </div>
      )}

      {/* generate qr/barcode */}
      {loginResponse?.permission?.generateQrBarcode === true && (
        <div
          // id="qrCode"
          className={qrCodeFocus ? "qrcode-sidemenu-container" : "focus-none"}
        >
          <NavLink
            to="qr/barcode"
            style={({ isActive }) =>
              isActive ? qrCodeStyle : { textDecoration: "none", color: "#000" }
            }
            className={({ isActive }) =>
              isActive ? setQrCodeFocus(true) : setQrCodeFocus(false)
            }
          >
            <ListItem button>
              <ListItemIcon>
                <QrCodeScannerOutlinedIcon
                  sx={qrCodeFocus ? qrCodeStyle : { color: "#000" }}
                />
              </ListItemIcon>
              <ListItemText primary="Generate QR/Barcode"></ListItemText>
            </ListItem>
          </NavLink>
        </div>
      )}
    </div>
  );
};
