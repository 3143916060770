import React, { useState, useEffect, useRef } from "react";
import "../../../css/Sales/orderList.css";
import { Datepicker } from "../../Single Components/Datepicker";
import SearchIcon from "@mui/icons-material/Search";
import {
  IconButton,
  Dialog,
  Skeleton,
  Autocomplete,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  deleteOrderListAPICall,
  salesOrderListAPICall,
  singleViewSalesOrderListAPICall,
} from "../../../API/Sales/orderListAPI";
import { useSelector } from "react-redux";
import { convertDateFormat, today } from "../../../Js/Date";
import ErrorSnackbar from "../../Single Components/SnackBars/ErrorSnackbar";
import {
  deleteAlertMessage,
  filterObjFromList,
} from "../../../Js/generalFunctions";
import SuccessSnackbar from "../../Single Components/SnackBars/SuccessSnackbar";
import { viewAllBranchesAPICall } from "../../../API/Settings/Company Settings/companySettingsAPI";
import ReactToPrint from "react-to-print";
import CategorySearchandFilter from "../../Single Components/CategorySearchandFilter";
import CustomPagination from "../../Single Components/CustomPagination";
import { customerListApi } from "../../../API/Customer/customerAPI";

export const OrderList = (props) => {
  const { isDialog } = props;
  const storeCode = localStorage.getItem("branchId");
  // login response
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  // role based rendering
  const userRole = useSelector((state) => state.userRoleSlice.value);
  // order list data
  const orderListData = useSelector((state) => state.salesOrderList.value);
  console.log(orderListData,".........");
  // order list single view
  const orderListSingleViewData = useSelector(
    (state) => state.singleViewOrderList.value
  );
  const customerList = useSelector((state) => state.allCustomerListSlice.value);
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  const allowedBranchList = useSelector(
    (state) => state.allAllowedBranchesSlice.value
  );

  let paymentsPrintNew = useRef();
  const [FormDataInfo, setFormDataInfo] = useState([]);
  const [orderListView, setOrderListView] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [branchId, setBranchId] = useState(null);
  const [orderId, setOrderId] = useState("");
  const [customerId, setCustomerId] = useState(null);
  const [status, setStatus] = useState(null);

  const [filteredData, setFilteredData] = useState([]);
  // loading states
  const [isLoading, setIsLoading] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!!");
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [successSnackOpen, setSuccesSnackOpen] = useState(false);
  const [isModify, setIsModify] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [dateValidationMsg, setDateValidationMsg] = useState("");
  const [branchValidationMsg, setBranchValidationMsg] = useState("");
  const [selectedBranch, setSelectedBranch] = useState(null);
  
  const statusOptions=[
    {label:"Delivered",value:"delivered"},
    {label:"Finished",value:"completed"},
    {label:"Printing",value:"printing"},
    {label:"Booking",value:"pending"},
    {label:"O.return",value:"orderReturned"},
    {label:"D.return",value:"deliveryReturned"},
  ]
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch);
   
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleDataFromChild = (data) => {
    setFormDataInfo(data);
  };
  const updateListner = () => {
    setIsModify(!isModify);
  };

  const closeErrorSnack = () => {
    setErrorSnackOpen(false);
  };
  const filterOptions = (options, { inputValue }) => {
    const lowerCasedInput = inputValue.toLowerCase();
    return options.filter(option =>
      option.name.toLowerCase().includes(lowerCasedInput) ||
      option.mobileNo.includes(lowerCasedInput)
    );
  };
  useEffect(() => {
    singleViewSalesOrderListAPICall();
    viewAllBranchesAPICall();
    if((fromDate&&!toDate)||(!fromDate&&toDate)){
      setDateValidationMsg('please select both date')
    }else{
      setDateValidationMsg('');
    }
    
    clickOrderListSubmit()
    // salesOrderListAPICall(setIsLoading, setErrorSnackOpen, setSnackMsg, {
    //   // branchId:
    //   //   loginResponse?.data?.branchId === undefined
    //   //     ? branchId
    //   //     : loginResponse?.data?.branchId,
    //   branchId:branchId?._id,
    //   search:FormDataInfo?.search,
    //   index:currentPage-1
    //   // fromDate: fromDate,
    //   // endDate: toDate,
    // });
  }, [isModify, FormDataInfo, currentPage]);

  const openOrderListSingleView = (id) => {
    setOrderListView(true);
    singleViewSalesOrderListAPICall({ _id: id });
  };
  const totalPages =orderListData?.pages;
  useEffect(() => {
    let filteredArray = orderListData?.obj?.orderList
   
    // console.log(filteredArray);
    setFilteredData(filteredArray);
  }, [orderListData, searchInput]);

  const onChangeBranch = (e, newValue) => {
    // setSearchKeywordRelation("");
    // setSearchMobile("");
    if (userRole === "user") {
      newValue === null
        ? setBranchId(
            filterObjFromList("storeCode", allowedBranchList, "branchId", {
              branchId: storeCode,
            })
          )
        : setBranchId(newValue);
    } else {
      setBranchId(newValue);
    }
    if(userRole==="admin"&&branchId){
      customerListApi({
       branchId:branchId?._id
      })
    }
  };
 
  const deleteOrderList = (id) => {
    deleteOrderListAPICall(
      id,
      updateListner,
      setSuccesSnackOpen,
      setErrorSnackOpen,
      setSnackMsg
    );
  };
  const clickOrderListSubmit = () => {
    // Check if at least two dropdowns have been selected and fromDate/toDate validation
    if ((toDate || fromDate || branchId || customerId || status||orderId) &&
        Object.keys({ toDate, fromDate, branchId, customerId, status,orderId })
          .filter(key => !!{ toDate, fromDate, branchId, customerId, status,orderId }[key]).length >= 2 &&
        ((toDate && fromDate) || (!toDate && !fromDate))) {
      salesOrderListAPICall(setIsLoading, setErrorSnackOpen, setSnackMsg, {
        // branchId:
        // loginResponse?.data?.branchId === undefined
        //   ? branchId?._id
        //   : loginResponse?.data?.branchId,
        branchId: branchId?._id,
        fromDate: fromDate === "" ? "" : `${fromDate} 00:00:00`,
        toDate: toDate === "" ? "" : `${toDate} 23:59:59`,
        referenceNo: Number(orderId),
        customerId: customerId?._id,
        status: status?.value,
        search:FormDataInfo?.search,
        index:currentPage-1
      });
    } else {
      // If less than two dropdowns are selected or fromDate/toDate validation fails, show an error message or handle it as per your requirement
      setErrorSnackOpen(true);
      setSnackMsg("Please select at least two options from the dropdown.");
    }
  };

  useEffect(() => {
    let userBranch = {
      branchId: storeCode,
    };
    allowedBranchList !== undefined &&
      userRole === "user" &&
      setBranchId(
        filterObjFromList(
          "storeCode",
          allowedBranchList,
          "branchId",
          userBranch
        )
      );
     
  }, [userRole, allowedBranchList]);
  

  return (
    <div className="global-page-parent-container">
      {/* {!isDialog && userRole === "admin" && ( */}
      <>
        <p className="breadcrumb">Sales &gt; Order List</p>

        <div className="global-white-bg-container">
          {/* <h4>Order List</h4> */}
          <div className="order-list-filter-submit-ctn">
            <div className="new-global-single-input auto-complete-new">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={
                  (userRole === "user" ? allowedBranchList : allBranchesList) ||
                  []
                }
                getOptionLabel={(option) =>
                  `${option?.storeCode}-${option?.branchName}`
                }
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Branch" focused />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    <span>{`${option?.storeCode} - ${option?.branchName}`}</span>
                  </li>
                )}
                value={branchId}
                onChange={onChangeBranch}
                // onChange={(e, newValue) => setFormData({ ...formData, employee: newValue })}
                // disabled={!editMode}
              />
              {userRole === "admin" && !branchId && (
      <p className="doc-validation-alert">Please select a branch.</p>
    )}
            </div>
            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="Order Id"
                variant="outlined"
                type="number"
                focused
                value={orderId}
                onChange={(e) => {
                  setOrderId(e.target.value)
                }}
               
              />
              {/* <p className="doc-validation-alert">{validationMessages.duration}</p> */}
            </div>
            <div className="new-global-single-input auto-complete-new">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={customerList || []}
                getOptionLabel={(option) => option.name}
                filterOptions={filterOptions}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Customers" focused />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    <span>{option.name}</span>
                  </li>
                )}
                value={customerId}
                onChange={(e,newValue)=>{
                  setCustomerId(newValue)
                }}
                // onChange={(e, newValue) => setFormData({ ...formData, employee: newValue })}
                // disabled={!editMode}
              />
              {/* <p className="doc-validation-alert">{validationMessages.employee}</p> */}
            </div>
           
            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="From"
                variant="outlined"
                type="date"
                focused
                value={fromDate}
                onChange={(e)=>{
                  setFromDate(e.target.value)
                  setDateValidationMsg("");
                }}
                // disabled={!editMode}
              />
              {dateValidationMsg && toDate && (
    <p className="doc-validation-alert">{dateValidationMsg}</p>
  )}
            </div>
            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="To"
                variant="outlined"
                type="date"
                focused
                value={toDate}
                onChange={(e)=>{
                  setToDate(e.target.value)
                  setDateValidationMsg("");
                }}             
                // disabled={!editMode}
              />
             {dateValidationMsg && fromDate && (
    <p className="doc-validation-alert">{dateValidationMsg}</p>
  )}
            </div>
            <div className="new-global-single-input auto-complete-new">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={statusOptions}
                getOptionLabel={(option) => option?.label}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Status" focused />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option?._id}>
                    <span>{option?.label}</span>
                  </li>
                )}
                value={status}
                onChange={(e,newValue)=>{
                  setStatus(newValue)
                }}

                // disabled={!editMode}
              />
              {/* <p className="doc-validation-alert">{validationMessages.employee}</p> */}
            </div>

            {/* <div className="global-single-input">
            <p>From</p>
            <input type="date" value={fromDate} onChange={(e)=>setFromDate(e.target.value)}/>
          </div> */}
            {/* <div className="global-single-input">
            <p>To</p>
            <input type="date" value={toDate} onChange={(e)=>setToDate(e.target.value)}/>
          </div> */}
            <button
              className="btn btn-primary"
              style={{ margin: "1%" }}
              onClick={clickOrderListSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </>

      <div className="global-white-bg-container">
        <div
          className="order-list-top-container"
          style={{
            justifyContent: "space-between",
            marginRight: "0",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "30%",
              // justifyContent: "space-between",
            }}
          >
            
            <div
              className="sales-order-list-total-amt-container"
              style={{ backgroundColor: "#ffe5fc", color: "#df06ef" }}
            >
              <p>Total Order</p>        
              <p>{orderListData?.obj?.totalOrder?.toFixed(2)}</p>
            </div>
            <div
              className="sales-order-list-total-amt-container"
              style={{ backgroundColor: "#b5e4c4", color: "#0d5308" }}
            >
              <p>Total paid</p>        
              <p>{orderListData?.obj?.totalPaid?.toFixed(2)}</p>
            </div>
            <div
              className="sales-order-list-total-amt-container"
              style={{ backgroundColor: "#e5ebff", color: "#1166ff" }}
            >
              <p>Total Disc</p>        
              <p>{orderListData?.obj?.totalDiscount?.toFixed(2)}</p>
            </div>
            <div className="sales-order-list-total-amt-container">
              <p>Total credit</p>
              <p>{orderListData?.obj?.totalCredit?.toFixed(2)}</p>
             
            </div>
          </div>

          <div style={{ display: "flex",alignItems: "center" }}>
            <Tooltip title="Share">
              <IconButton>
                <i
                  class="bi bi-share"
                  style={{
                    backgroundColor: "#9797e9",
                    color: "white",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                ></i>
              </IconButton>
            </Tooltip>

            <ReactToPrint
              trigger={() => (
                <IconButton className="printer-div" style={{ padding: "1px" }}>
                  <i
                    style={{
                      color: "white",
                      backgroundColor: " #d787e7",
                      fontSize: "medium",
                      padding: "8px",
                      borderRadius: "2px",
                    }}
                    class="bi bi-printer"
                  ></i>
                </IconButton>
              )}
              content={() => paymentsPrintNew}
            />

            <Tooltip title="Download">
              <IconButton>
                <i
                  class="bi bi-arrow-down-circle"
                  style={{
                    backgroundColor: "rgb(255 118 133 / 69%)",
                    color: "white",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                ></i>
              </IconButton>
            </Tooltip>

            <CategorySearchandFilter
              onData={handleDataFromChild}
              isFilter={false}
              onBranchSelect={handleBranchSelect} 
            />
            <CustomPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              setFormDataInfo={setFormDataInfo}
            />
            {/* <div className="sales-order-list-search-container">
              <input
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                type="text"
                placeholder="Search Order ID,Customer..."
              />
              <SearchIcon className="search-icon" />
            </div> */}
          </div>
        </div>

        {/* table */}
        <div className="global-table-container">
          <table className="global-table">
            <thead>
              <tr>
                <th>No</th>
                {/* {userRole === "admin" && (
                  <>
                    <th>Branch Code</th>
                    <th>Branch Name</th>
                  </>
                )} */}

                <th>Order ID</th>
                <th>Date</th>
                <th>Customer</th>
                <th>Mobile</th>
                <th>Order Item</th>
                <th>Amount</th>
                <th>Paid amount</th>
                <th>Discount</th>
                <th>Return Amount</th>
                <th>Balance</th>
                <th>Status</th>

                <th>View</th>
                {/* {userRole === "admin" && <th>Delete</th>} */}
              </tr>
            </thead>
            {isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={14}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={14}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={14}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={14}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={14}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={14}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={14}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={14}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {filteredData?.length !== 0 ? (
                  filteredData
                    ?.slice(0)
                   
                    ?.map((r, i) => (
                      <tr>
                        <td>{i + 1}</td>
                        {/* {userRole === "admin" && (
                          <>
                            <td>{r?.branchId}</td>
                            <td>{r?.branchName}</td>
                          </>
                        )} */}

                        <td>{r?.orderNo}</td>
                        <td>{convertDateFormat(r?.date)}</td>
                        <td>{r?.customer}</td>
                        <td>{r?.mobile}</td>
                        <td>{r?.types.join(', ')}</td>
                        <td>{r?.totalAmount}</td>
                        <td>{r?.paidAmount}</td>
                        <td>{r?.discount}</td>
                        <td>{r?.returnAmount}</td>
                        <td>{r?.balance?.toFixed(2)}</td>
                        {/* <td><span className="sales-order-list-payment-status">{r?.paymentstatus}</span></td> */}
                        <td>
                          <span
                            className={
                              r?.status === "pending"
                                ? "sales-order-list-status-pending"
                                : r?.status === "INVOICED"
                                ? "sales-order-list-status"
                                : r?.status === "returned"
                                ? "sales-order-list-status-returned"
                                : undefined
                            }
                          >
                            {r?.status.toUpperCase()}
                          </span>
                        </td>
                        <td>
                          <IconButton
                            onClick={() => openOrderListSingleView(r?._id)}
                          >
                            <i class="bi bi-eye visibility-icon"></i>
                          </IconButton>
                        </td>
                        {/* {userRole === "admin" && (
                          <td>
                            <IconButton
                              onClick={deleteAlertMessage(
                                r._id,
                                deleteOrderList
                              )}
                            >
                              <i class="bi bi-trash3 delete-icon"></i>
                            </IconButton>
                          </td>
                        )} */}
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan={14}>No Data</td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
      </div>

      {/* order list single view */}
      <Dialog
        open={orderListView}
        maxWidth="lg"
        onClose={() => setOrderListView(false)}
        onKeyDown={(e) => e.key === "Escape" && setOrderListView(false)}
      >
        <div className="sales-order-list-view-container">
          <h2>Sales Order</h2>
          <div className="sales-order-list-view-top-container">
            <div className="sales-order-list-view-top-left">
              <div className="sales-order-list-view-top-left-first">
                <p>Order ID&nbsp;:&nbsp;{orderListSingleViewData?.orderId}</p>
                <p>
                  Order Date&nbsp;:&nbsp;{orderListSingleViewData?.orderDate}
                </p>
              </div>
              <div className="sales-order-list-view-top-left-Second">
                {/* <p>Expected Shipment Date &nbsp;&nbsp; 12-02-2022</p>
                <p>Delivery Method &nbsp;&nbsp; Bike</p> */}
                <p>
                  Sales Person &nbsp;&nbsp; :&nbsp;
                  {orderListSingleViewData?.salesman}
                </p>
              </div>
            </div>
            <div className="sales-order-list-view-top-right">
              <div className="sales-order-list-view-top-right-address">
                <h4>Billing Address:</h4>
                <p>{orderListSingleViewData?.billingAddress}</p>
              </div>
              {/* <div className='sales-order-list-view-top-right-address'>
                 <h4>Shipping  Address:</h4>
                 <p>Deepak Dinesh</p>
                 <p>Cyber Park kozhikode,Kerala</p>
               </div> */}
              <p style={{ fontSize: "0.9rem", textAlign: "right" }}>
                {orderListSingleViewData?.gstin?.includes("No gst")
                  ? ""
                  : `GSTIN - ${orderListSingleViewData?.gstin}`}
              </p>
            </div>
          </div>

          {/* TABLE */}
          <div className="sales-order-list-view-table">
            <table>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Qty</th>
                  <th>Gross Amount</th>
                  <th>Discount</th>
                  <th>Taxable Value</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {orderListSingleViewData?.list?.map((r, i) => (
                  <tr>
                    <td>{r?.itemName}</td>
                    <td>{r?.quantity}</td>
                    <td>{r?.grossAmount}</td>
                    <td>{r?.discount}</td>
                    <td>{r?.taxableavalue}</td>
                    <td>{r?.totalAmount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* bottom container */}
          <div className="sales-order-list-view-bottom-container">
            <div className="sales-order-list-view-bottom-left-container">
              {/* <p>Customer Notes</p> */}
              {/* <p>More Information</p> */}
            </div>
            <div className="sales-order-list-view-bottom-right-container">
              <div className="sales-order-list-view-bottom-right-single-container">
                <h4 style={{ width: "44%", textAlign: "right" }}>
                  Grand Total
                </h4>
                <h4 style={{ textAlign: "end" }}>
                  {orderListSingleViewData?.grandTotal}
                </h4>
              </div>
              <div className="sales-order-list-view-bottom-right-single-container">
                <p style={{ width: "44%", textAlign: "right" }}>
                  Shipping Charges
                </p>
                <p>{orderListSingleViewData?.shippingTotal}</p>
              </div>
              <div className="sales-order-list-view-bottom-right-single-container">
                <p style={{ width: "44%", textAlign: "right" }}>Discount</p>
                <p>{orderListSingleViewData?.discount}</p>
              </div>
              {/* <div className='sales-order-list-view-bottom-right-single-container'>
                    <p style={{width:"44%",textAlign:"right"}}>Adjustment</p>
                    <p>---</p>
                  </div> */}
              <hr className="global-hr" />
              <div className="sales-order-list-view-bottom-right-single-container">
                <h4 style={{ width: "44%", textAlign: "right" }}>Total</h4>
                <h4 style={{ textAlign: "end" }}>
                  {orderListSingleViewData?.subTotal}
                </h4>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              onClick={() => setOrderListView(false)}
              className="btn btn-secondary-outlined"
            >
              Close
            </button>
          </div>
        </div>
      </Dialog>
      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnack}
        message={snackMsg}
      />
      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={() => setSuccesSnackOpen(false)}
        message={snackMsg}
      />
    </div>
  );
};
