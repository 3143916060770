import { IconButton, MenuItem, Select,Autocomplete,TextField} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { viewAllProductsAPICall } from "../../../../API/Product List/productListAPI";
import { generatePurchaseOrderIdAPICall,addPurchaseOrderAPICall} from "../../../../API/Purchase Manager/purchaseOrderAPI";
import { viewAllSuppliersAPICall } from "../../../../API/Purchase Manager/supplierAPI";
import "../../../../css/Purchase Manager/addPurchaseOrder.css";
import { today } from "../../../../Js/Date";
import { extractObjectForSingleView } from "../../../../Js/generalFunctions";
import {viewAllBranchesAPICall} from '../../../../API/Settings/Company Settings/companySettingsAPI'
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import uuid from "react-uuid";
import { red } from "@mui/material/colors";
import jwtDecode from "jwt-decode";

export const AddPurchaseOrder = () => {
  const currentCountry=localStorage.getItem('country')
  const TOKEN = localStorage.getItem("userToken");
  const isTax=jwtDecode(TOKEN)?.isTax
  const paymentTypes=[
    {
      name:'Upi',
   },
    {
      name:'Card',
    },
    {
      name:'Cash',
    }
  ]
  //*Redux States
  //List of All Suppliers
  const allSuppliersList = useSelector(
    (state) => state.allSupplierListSlice.value
  );
  //List of Products
  const listOfProducts = useSelector(
    (state) => state.allProductsListSlice.value
  );
  //Login response for branch id
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  //Generated PO id
  const poId = useSelector((state) => state.purchaseOrderIdSlice.value);
//List of all Branches
const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
 // role based rendering
 const userRole = useSelector((state) => state.userRoleSlice.value);

 const storeCode=localStorage.getItem('branchId')

  //Update Listener States
  const [isModify, setIsModify] = useState(false);
  //Add Purchase Form 1
  const addPurchaseForm1InitialState = {
    poNo: "##",
    invoiceNo: "",
    supplierName: "0",
    payTerms: "0",
    purchaseDate: today,
    expiryDate: "",
    location: "0",
    locationId:'',
    locationCode:'',
    remarks: "",
  };
  const [addPurchaseForm1, setAddPurchaseForm1] = useState(
    addPurchaseForm1InitialState
  );
  //product Category [2 for material, 0 for readyMade, 1 for accessory]
  const [productCategory, setProductCategory] = useState(2);
  //Extracted Product single obj
  const [singleProduct,setSingleProduct]=useState([])
  const [singleProductAmount,setSingleProductAmount]=useState([])
  const [supplier,setSupplier]=useState(null)
  // validation states
  const [locationAlert,setlocationAlert]=useState(false)
  const [supplierAlert,setSupplierAlert]=useState(false)
  const [ExpDateAlert,setExpDateAlert]=useState(false)
  const [addProductsAlert,setAddProductsAlert]=useState(false)
  const [selectProductAlert,setSelectProductAlert]=useState(false)
  const [selectProductQtyAlert,setSelectQtyProductAlert]=useState(false)
  const [selectProductSizeAlert,setSelectSizeProductAlert]=useState(false)
  //Add purchase form 2
  const addPurchaseForm2initialState = {
    itemId: "0",
    itemName:'',
    unit: "",
    size:0,
    stockQty: "",
    qty:'',
    rate: "",
    igst:0,
    gst:0,
    // sgst:0,
    total:0,
    paidAmount:0,
    balance:0,
    taxAmount:0
  };
  const [addPurchaseForm2, setAddPurchaseForm2] = useState(
    addPurchaseForm2initialState
  );

  const addPurchaseForm3InitialState={
    igstTotal:0,
    gstTotal:0,
    amountTotal:0,
    discountPercent:'',
    discountAmt:'',
    netAmount:0,
    totalPiadAmount:0
  }
 const [addPurchaseForm3,setAddPurchaseForm3]=useState(addPurchaseForm3InitialState)

 const [product,setProduct]=useState(null)
 const [tableDataForFrontEnd,setTableDataForFrontEnd]=useState([])
 const [tableDataForBackEnd,setTableDataForBackEnd]=useState([])

  //Snackbar States
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("Error!!");

 //Close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setSuccessSnackOpen(false);
  };
  //Close ErrorSnackbar
  const closeErrorSnackbar = () => {
    setErrorSnackOpen(false);
  };
//*Update Listener
 const updateListener = () => {
  setIsModify(!isModify);
};

  // console.log(productSingle[0]);
  //*onchange
  // console.log(addPurchaseForm2);
  const getAddPurchaseForm1 = (key) => (e) => {
    const { value } = e.target;
    switch (key) {
      case "invoiceNo":
        setAddPurchaseForm1({ ...addPurchaseForm1, invoiceNo: value });
        break;
      case "supplierName":
        setAddPurchaseForm1({ ...addPurchaseForm1, supplierName: value });
        break;
      case "payTerms":
        setAddPurchaseForm1({ ...addPurchaseForm1, payTerms: value });
        break;
      case "purchaseDate":
        setAddPurchaseForm1({ ...addPurchaseForm1, purchaseDate: value });
        break;
      case "expiryDate":{
        setExpDateAlert(false)
        setAddPurchaseForm1({ ...addPurchaseForm1, expiryDate: value });
        if (value==="") {
          setExpDateAlert(true)
        }
      }
        break;
      case "location":
        setAddPurchaseForm1({ ...addPurchaseForm1, location: value });
        break;
      case "remarks":
        setAddPurchaseForm1({ ...addPurchaseForm1, remarks: value });
        break;

      default:
        break;
    }
  };
  const getAddPurchaseForm2 = (key) => (e) => {
    const { value } = e.target;
    switch (key) {
      case "itemId":
        setAddPurchaseForm2({ ...addPurchaseForm2, itemId: value });
        break;
      case "unit":
        setAddPurchaseForm2({ ...addPurchaseForm2, unit: value });
        break;
      case "size":
        setAddPurchaseForm2({ ...addPurchaseForm2, size: value });
        break;
      case "qty":{
        setAddPurchaseForm2({ ...addPurchaseForm2, qty: value });
        setSelectQtyProductAlert(false)
        if(value===""){
          setSelectQtyProductAlert(true)
        }
      }
        break;
      case "rate":
        setAddPurchaseForm2({ ...addPurchaseForm2, rate: value });
        break;
      case "igst":
        setAddPurchaseForm2({ ...addPurchaseForm2, igst: value ,gst:0 });
        break;
      case "sgst":
        setAddPurchaseForm2({ ...addPurchaseForm2, gst: value , igst:0 });
        break;
    
      case "paidAmount":
        setAddPurchaseForm2({ ...addPurchaseForm2, paidAmount: value });
        break;

      default:
        break;
    }
  };

  const getAddPurchaseForm3=(key)=>(e)=>{
    const {value} =e.target
    if(key==="percentage"){
      setAddPurchaseForm3({...addPurchaseForm3,discountPercent:value,
        discountAmt: (parseFloat(addPurchaseForm3?.amountTotal)*parseFloat(value))/100
      })
    }
    if(key ==="amount"){
      setAddPurchaseForm3({...addPurchaseForm3,discountAmt:value,
        discountPercent:(parseFloat(value)*100)/parseFloat(addPurchaseForm3?.amountTotal)
      })
    }
  }

 
  //TODO add onchange and assign to inputs
  //get product Category
  const getProductCategory = (e) => {
    setProductCategory(e.target.value);
  };

  //*useEffects
   useEffect(()=>{
    viewAllBranchesAPICall();
   },[])
   useEffect(()=>{
    setAddPurchaseForm1({...addPurchaseForm1,
    locationId:addPurchaseForm1?.location?._id,
    locationCode:addPurchaseForm1?.location?.storeCode
    })
 },[addPurchaseForm1?.location])  


  //Initial API Calls
  useEffect(() => {
    viewAllSuppliersAPICall();
    if (loginResponse !== null) {
      const { branchId } = loginResponse;
      if(userRole==="user"){
        generatePurchaseOrderIdAPICall({ branchId});
      }
      else{
        generatePurchaseOrderIdAPICall({ branchId:addPurchaseForm1?.locationCode});
      }
     
    }
  }, [isModify,loginResponse,addPurchaseForm1?.locationCode]);

  //Call Product list API
  useEffect(() => {
    viewAllProductsAPICall({ type: productCategory,branchId:storeCode });
  }, [productCategory]);
  
  //Set Po id to state
  useEffect(() => {
    if (poId !== undefined) {
      const { prefix, data } = poId;
      setAddPurchaseForm1({ ...addPurchaseForm1, poNo: `${prefix}${data}` });
    } else {
      setAddPurchaseForm1({ ...addPurchaseForm1, poNo: "##" });
    }
  }, [poId]);


  //extract location
  useEffect(()=>{
    let filteredData=[]
    if(allBranchesList !== null){
      filteredData=allBranchesList?.filter((obj)=>{
        if(obj._id===loginResponse?.branchPk){
          return obj
        }
      }) 
      if(filteredData !== undefined) setAddPurchaseForm1({...addPurchaseForm1,location:filteredData[0]})
    }
  },[loginResponse?.branchPk,allBranchesList])

 
 // extract supplier
 useEffect(()=>{
  setAddPurchaseForm1({...addPurchaseForm1,supplierName:supplier !== null && supplier._id,
    payTerms:supplier?.creditLimit
  })
 },[supplier])

  useEffect(()=>{
    setAddPurchaseForm2({...addPurchaseForm2,itemId:product?._id,itemName:product?.productName})
  },[product])

  // extract selected product from list
  useEffect(()=>{
    let filteredData=[];
    filteredData=listOfProducts?.filter((obj)=>{
      if(obj._id===addPurchaseForm2?.itemId){
         return obj;
      }
    })
    // console.log(filteredData);
    setSingleProduct(filteredData)
  },[addPurchaseForm2?.itemId])
  

  useEffect(()=>{
    setAddPurchaseForm2({
      ...addPurchaseForm2,
      unit:singleProduct !== undefined ? singleProduct[0]?.unitName:'',
      stockQty:singleProduct !== undefined ? singleProduct[0]?.stock:'',
      rate:singleProduct !== undefined ? singleProduct[0]?.calculatedPrice:'',

    })
  },[singleProduct])

  // extract amount
  useEffect(()=>{
    let sizeFilter=[]
     sizeFilter=singleProduct !== undefined && singleProduct[0]?.size?.filter((obj)=>{
      if(obj.brandUnit===addPurchaseForm2?.size){
        return obj
      }
     })
     setSingleProductAmount(sizeFilter)
  },[addPurchaseForm2?.size])

  useEffect(()=>{
    setAddPurchaseForm2({...addPurchaseForm2,rate:singleProductAmount !== undefined ? singleProductAmount[0]?.calculatedPrice:""})
  },[singleProductAmount])

  //calculate total amount
  const calculateProductTotal=()=>{
   let productTotal=0
   let taxAmount=0
   let ProductTotalIncludeTax=0
   productTotal=parseFloat(addPurchaseForm2?.qty)*parseFloat(addPurchaseForm2?.rate)
   if(addPurchaseForm2?.igst === 0){
    taxAmount=(parseFloat(productTotal)*parseFloat((addPurchaseForm2?.gst)))/100
    ProductTotalIncludeTax=parseFloat(productTotal)+parseFloat(taxAmount)
   }
   else if(addPurchaseForm2?.gst===0){
    taxAmount=(parseFloat(productTotal)*parseFloat(addPurchaseForm2?.igst))/100
    ProductTotalIncludeTax=parseFloat(productTotal)+parseFloat(taxAmount)
   }

   setAddPurchaseForm2({...addPurchaseForm2,total:ProductTotalIncludeTax || productTotal,taxAmount})

  }
  
  useEffect(()=>{
    calculateProductTotal()
  },[addPurchaseForm2?.qty,addPurchaseForm2?.rate,addPurchaseForm2?.gst,addPurchaseForm2?.igst])

  // calculate balance
  useEffect(()=>{
    let balance=0
    balance=parseFloat(addPurchaseForm2?.total)-parseFloat(addPurchaseForm2?.paidAmount)
    setAddPurchaseForm2({...addPurchaseForm2,balance})
  },[addPurchaseForm2?.total,addPurchaseForm2?.paidAmount])

 

  // add button function
   const addBtnClickFn=()=>{
    if(product === null){
      setSelectProductAlert(true)
    }
    else if(productCategory != 2 && addPurchaseForm2?.size===0){
      setSelectSizeProductAlert(true)
    }
    else if(addPurchaseForm2?.qty===""){
    setSelectQtyProductAlert(true)
    }
    else{
      setTableDataForFrontEnd([...tableDataForFrontEnd,
        {
          itemName:addPurchaseForm2?.itemName,
          stock:addPurchaseForm2?.stockQty,
          qty:addPurchaseForm2?.qty,
          // size:addPurchaseForm2?.size,
          unit:addPurchaseForm2?.unit,
          rate:addPurchaseForm2?.rate,
          igst:parseFloat(addPurchaseForm2?.igst),
          gst:parseFloat(addPurchaseForm2?.gst),
          sgst:parseFloat((addPurchaseForm2?.gst)/2),
          cgst:parseFloat((addPurchaseForm2?.gst)/2),
          total:addPurchaseForm2?.total,
          paidAmt:addPurchaseForm2?.paidAmount,
          taxAmount:addPurchaseForm2?.taxAmount
  
  
        }
       ])
       setTableDataForBackEnd([...tableDataForBackEnd,
        {
         type:productCategory,
         itemInfo:addPurchaseForm2?.itemId,
         quantity:parseFloat(addPurchaseForm2?.qty),
         rate:addPurchaseForm2?.rate,
         unit:addPurchaseForm2?.unit,
         igst:parseFloat(addPurchaseForm2?.igst),
         sgst:parseFloat((addPurchaseForm2?.gst)/2),
         cgst:parseFloat((addPurchaseForm2?.gst)/2),
         dimension:addPurchaseForm2?.size,
         status:"Pending",
         uuid:uuid()
         
        }
       ])
       setAddProductsAlert(false)
       clearStateForm2()
    }
    
   
   }
 
  

  

   // clear form 2
   const clearStateForm2=()=>{
    setProduct(null)
    setAddPurchaseForm2(addPurchaseForm2initialState)
   }
  
   // delete table row
   const deleteTableRow=(index)=>{
    tableDataForFrontEnd.splice(index,1)
    tableDataForBackEnd.splice(index,1)
    setTableDataForFrontEnd([...tableDataForFrontEnd])
    setTableDataForBackEnd([...tableDataForBackEnd])
   }

   // calculate grand total of igst,cgst,sgst and total 
   const calculateTotal=()=>{
     let netTotal =0;
     let igstTotal=0;
     let gstTotal=0;
     let totalPiadAmount=0

     
     tableDataForFrontEnd.forEach((r,i)=>{
       if(r.igst === 0){
        gstTotal += parseFloat(r?.taxAmount)
       }
       if(r?.sgst === 0 && r?.cgst === 0){
        igstTotal += parseFloat(r?.taxAmount)
       }
        netTotal += parseFloat(r?.total)
        totalPiadAmount +=parseFloat(r?.paidAmt)

     })
     setAddPurchaseForm3({...addPurchaseForm3,igstTotal,gstTotal,amountTotal:netTotal,totalPiadAmount})
   }
  useEffect(()=>{
    calculateTotal()
  },[tableDataForFrontEnd])

  // calculate net amount
  useEffect(()=>{
    setAddPurchaseForm3({...addPurchaseForm3,netAmount:
    parseFloat(addPurchaseForm3?.amountTotal)-parseFloat(addPurchaseForm3?.discountAmt) || parseFloat(addPurchaseForm3?.amountTotal)
    })
  },[addPurchaseForm3?.amountTotal,addPurchaseForm3?.discountAmt])


  
  // final api call
   const finalBodyToApi={
    supplierId:addPurchaseForm1?.supplierName,
    payTerms:parseFloat(addPurchaseForm1?.payTerms),
    purchaseDate:addPurchaseForm1.purchaseDate,
    expiryDate:addPurchaseForm1.expiryDate,
    location:addPurchaseForm1?.locationId,
    remarks:addPurchaseForm1.remarks,
    purchaseInfo:tableDataForBackEnd,
    paidAmount:addPurchaseForm3?.totalPiadAmount,
    grandTotal:addPurchaseForm3?.netAmount,
    branchId:addPurchaseForm1?.locationCode,
    invoiceNo:addPurchaseForm1.invoiceNo,
    
   }
  const finalSubmitBtnFn=()=>{
    if(addPurchaseForm1?.location === undefined){
      setlocationAlert(true)
    }
    else if(supplier===null){
      setSupplierAlert(true)
    }
    else if(addPurchaseForm1.expiryDate===''){
      setExpDateAlert(true)
    } 
    else if(tableDataForFrontEnd?.length ===  0){
      setAddProductsAlert(true)
    }
    else{
      addPurchaseOrderAPICall(
        finalBodyToApi,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        form1ClearState,
        updateListener
       
        )
      // console.log(finalBodyToApi);
       
    }
   
  }
  /// from1 clear state
  const form1ClearState=()=>{
    setSupplier(null)
    setAddPurchaseForm1({...addPurchaseForm1,
      expiryDate:"",
      remarks:"",
      payTerms:""
    })
    setTableDataForFrontEnd([])
    setTableDataForBackEnd([])
    setAddPurchaseForm3(addPurchaseForm3InitialState)
  }
  // from3 clear state
  const form3ClearState=()=>{
    setAddPurchaseForm3(addPurchaseForm3InitialState)
  }



  return (
    <div className="global-page-parent-container">
      <p className="breadcrumb">Purchase Manage &gt; Purchase Order</p>
      <div className="add-po-main-container">
        <div className="top-bar">
          <h3>Add Purchase </h3>
        </div>
        <hr />
        <div className="form1-container">
          
          <div className="global-single-input add-purchase-input auto-complete" style={{position:"relative"}}>
            <p>Location</p>
            <Autocomplete
             disabled={userRole==="user"}
             sx={{width:"100%"}}
             options={allBranchesList || [""]}
             getOptionLabel={(option) => `${option?.storeCode}-${option?.branchName}`}

             renderInput={(params) => <TextField {...params} 
             placeholder="Select Location"
             />}
             value={addPurchaseForm1?.location}
             onChange={(e, newValue) =>{
               setAddPurchaseForm1({...addPurchaseForm1,location:newValue})
               setlocationAlert(false)
               if(newValue===null){
                setlocationAlert(true)
               }
             }
             }
            />
            {
              locationAlert?
              <p className="doc-validation-alert">Select a location !!</p>
              :""
            }
          </div>
          <div className="global-single-input add-purchase-input ">
            <p>PO No</p>
            <input 
            type="text" 
            value={addPurchaseForm1?.location !== undefined && addPurchaseForm1?.location !== null ?addPurchaseForm1.poNo:""} 
            placeholder="Choose Location"
            disabled

            />
          </div>
          <div className="global-single-input add-purchase-input auto-complete" style={{position:"relative"}}>
            <p>Supplier Name</p>
            <Autocomplete
             sx={{width:"100%"}}
             options={allSuppliersList|| [""]}
             getOptionLabel={(option) => option?.supplierName}

             renderInput={(params) => <TextField {...params} 
             placeholder="Select Supplier"
             />}
             value={supplier}
             onChange={(e, newValue) =>{
               setSupplier(newValue)
               setSupplierAlert(false)
               if(newValue===null){
                setSupplierAlert(true)
               } 
             }
             }
            />
            {
              supplierAlert?
            <p className="doc-validation-alert">Select a supplier !!</p>
              :""
            }
          </div>
          <div className="global-single-input add-purchase-input auto-complete">
            <p>Pay Terms</p>
            <input type="text" value={addPurchaseForm1?.payTerms} />
             {/* <Autocomplete
             sx={{width:"100%"}}
             options={paymentTypes || [""]}
             getOptionLabel={(option) => option?.name}

             renderInput={(params) => <TextField {...params} 
             placeholder="Select Payment Type"
             />}
             onChange={(e, newValue) =>
             setAddPurchaseForm1({...addPurchaseForm1,payTerms:newValue.name})
             } 
            /> */}
           

          </div>
          <div className="global-single-input add-purchase-input ">
            <p>Purchase Date</p>
            <input
              type="date"
              value={addPurchaseForm1.purchaseDate}
              onChange={getAddPurchaseForm1("purchaseDate")}
            />
          </div>
          <div className="global-single-input add-purchase-input"style={{position:"relative"}}>
            <p>Expiry Date</p>
            <input
              type="date"
              value={addPurchaseForm1.expiryDate}
              onChange={getAddPurchaseForm1("expiryDate")}
            />
            {
              ExpDateAlert?
              <p className="doc-validation-alert">Fill this field !!</p>
              :""
            }
          </div>
         
          <div className="global-single-input add-purchase-input ">
            <p>Remarks</p>
            <textarea
              rows="6"
              placeholder="Enter remarks..."
              value={addPurchaseForm1.remarks}
              onChange={getAddPurchaseForm1("remarks")}
            />
          </div>
        </div>
      </div>
      <div className="add-po-main-container">
        <div className="form2-container" style={{position:"relative"}}>
          {addProductsAlert?
          <p className="add-products-validation" style={{alignSelf:"flex-end"}}>
            add product details !!</p>
          :""
          }
          <div className="radio-groups-container">
            <div className="global-radio-button radio-group">
              <input
                type="radio"
                name="mainCategoryRadio"
                id="materialCategory"
                value={2}
                defaultChecked
                onChange={getProductCategory}
              />
              <label htmlFor="materialCategory">Material</label>
            </div>
            <div className="global-radio-button radio-group">
              <input
                type="radio"
                name="mainCategoryRadio"
                id="readymadeCategory"
                value={0}
                onChange={getProductCategory}
              />
              <label htmlFor="readymadeCategory">Ready Made</label>
            </div>

            <div className="global-radio-button radio-group">
              <input
                type="radio"
                name="mainCategoryRadio"
                id="accessoryCategory"
                value={1}
                onChange={getProductCategory}
              />
              <label htmlFor="accessoryCategory">Accessories</label>
            </div>
          </div>
          <div className="item-information-container">
            <div className="global-single-input add-purchase-input add-po-input-item auto-complete" style={{position:"relative"}}>
              <p>Item Information</p>
              
               <Autocomplete
             sx={{width:"100%"}}
             options={listOfProducts || [""]}
             getOptionLabel={(option) => `${option?.productId}-${option?.productName}`}

             renderInput={(params) => <TextField {...params} 
             placeholder="Select Product"
             />}
             value={product}
             onChange={(e, newValue) =>{
               
               setProduct(newValue)
               setSelectProductAlert(false)
               if(newValue===null){
                setSelectProductAlert(true)
               }
              }
              }
           
            />
            {selectProductAlert?
              <p className="doc-validation-alert">Select a product</p>
              :""
            }
            </div>
            {/* {productCategory == 2 ? "Unit" : "Size"} */}
            {productCategory == 2 && (
              <div className="global-single-input add-purchase-input sm-input add-po-input">
                <p>Unit</p>
                <input  disabled value={addPurchaseForm2?.unit} />
              </div>
            )}
            {productCategory != 2 && (
              <div className="global-single-input add-purchase-input sm-input add-po-input-item auto-complete" style={{position:"relative"}}>
                <p>Size</p>
                <Autocomplete
                 sx={{width:"100%"}}
                 options={singleProduct !== undefined && singleProduct[0]?.size||[""]}
                 getOptionLabel={(option) => option?.brandUnit}
                 renderInput={(params) => <TextField {...params}
                 placeholder="Select Size"
                 />}
                 onChange={(e, newValue) =>{
                   setAddPurchaseForm2({
                     ...addPurchaseForm2,
                     size: newValue?.brandUnit,
                    })
                    setSelectSizeProductAlert(false)
                    if(newValue===null){
                      setSelectSizeProductAlert(true)
                    }
                  }
                }
                />
              {selectProductSizeAlert?
                <p className="doc-validation-alert">Enter!</p>:""
              }
              </div>
            )}
            <div className="global-single-input add-purchase-input md-input add-po-input">
              <p>Stock / Qty</p>
              <input
                type="text"
                value={addPurchaseForm2?.stockQty}
                onChange={getAddPurchaseForm2("stockQty")}
                disabled
              />
            </div>
            <div className="global-single-input add-purchase-input sm-input add-po-input"
                  style={{position:"relative"}}>
              <p>Qty</p>
              <input
              placeholder="Enter Qty..."
              type="number"
                value={addPurchaseForm2?.qty}
                onChange={getAddPurchaseForm2("qty")}
              />
              {selectProductQtyAlert?
              <p className="doc-validation-alert">Enter!</p>
              :""
            }
            </div>

            <div className="global-single-input add-purchase-input add-po-input">
              <p>Rate</p>
              <input
              placeholder="Enter rate..."
                type="text"
                value={addPurchaseForm2?.rate}
                onChange={getAddPurchaseForm2("rate")}
              />
            </div>
            {
              isTax === true &&
            
            <>
            <div className="global-single-input add-purchase-input sm-input add-po-input">
            <p>{ currentCountry === 'india'? 'IGST %': 'VAT %'}</p>
              <input
              
               placeholder={currentCountry === 'india'? "IGST...":"VAT..."}
                type="text"
                value={addPurchaseForm2?.igst}
                onChange={getAddPurchaseForm2("igst")}
              />
            </div>
            {
              currentCountry === 'india' &&
              <>
               <div className="global-single-input add-purchase-input sm-input add-po-input">
              <p>GST %</p>
              <input
              placeholder="SGST..."
                type="text"
                value={addPurchaseForm2?.gst}
                onChange={getAddPurchaseForm2("sgst")}
              />
            </div>
                 <div className="global-single-input add-purchase-input sm-input add-po-input">
              <p>SGST %</p>
              <input
              placeholder="SGST..."
                type="text"
                value={(addPurchaseForm2?.gst)/2}
                disabled
                // onChange={getAddPurchaseForm2("sgst")}
              />
            </div>
            <div className="global-single-input add-purchase-input sm-input add-po-input">
              <p>CGST %</p>
              <input
              placeholder="CGST..."
                type="text"
                value={(addPurchaseForm2?.gst)/2}
                disabled
                // onChange={getAddPurchaseForm2("cgst")}
              />
            </div>
              </>
           
            }

            </>
}
          </div>
          <div className="total-balance-container">
            <div className="total-balance-container-child">
              <p>Total</p>
              <input
                type="text"
                value={isNaN(addPurchaseForm2?.total)?0:addPurchaseForm2?.total}
                onChange={getAddPurchaseForm2("total")}
              />
            </div>
            <div className="total-balance-container-child">
              <p>Paid Amount</p>
              <input
                type="text"
                value={addPurchaseForm2?.paidAmount}
                onChange={getAddPurchaseForm2("paidAmount")}
              />
            </div>
            <div className="total-balance-container-child">
              <p>Balance</p>
              <input
                type="text"
                value={isNaN(addPurchaseForm2?.balance)?0:addPurchaseForm2?.balance}
                onChange={getAddPurchaseForm2("balance")}
              />
            </div>
          </div>
          <button onClick={addBtnClickFn} className="plus-btn">+</button>
        </div>
        <hr />
        <div className="selected-items-table-container global-table-container ">
          <table className="global-table">
            <thead>
              <tr>
                <th>No</th>
                <th>Item Information</th>
                <th>Stock Qty</th>
                <th>Qty</th>
                <th>Unit</th>
                <th>Unit Cost</th>
{
             isTax=== true &&
                <>
                  <th>IGST %</th>
                  <th>GST %</th>
                  <th>SGST %</th>
                   <th>CGST %</th>
                </>
}
               
                <th>Amount</th>
                <th>Paid Amount</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {tableDataForFrontEnd?.length!==0 ? tableDataForFrontEnd?.map((r,i)=>(
                <tr>
                 <td>{i+1}</td>
                 <td>{r?.itemName}</td>
                 <td>{r?.stock}</td>
                 <td>{r?.qty}</td>
                 <td>{r?.unit}</td>
                 <td>{r?.rate}</td>
                 {
                  isTax === true &&
                  <>
                  <td>{r?.igst}</td>
                  <td>{r?.gst}</td>
                  <td>{r?.sgst}</td>
                  <td>{r?.cgst}</td>
                  </>
                 }
                
                
                 <td>{r?.total}</td>
                 <td>{r?.paidAmt}</td>
                 <td>
                    <IconButton>
                        <i class="bi bi-pencil-square edit-icon"></i>
                    </IconButton>
                 </td>
                 <td>
                    <IconButton onClick={()=>deleteTableRow(i)}>
                        <i class="bi bi-trash3 delete-icon"></i>
                    </IconButton>
                  </td>
                </tr>
              ))
              :
              <tr>
                <td colSpan={14}>No Data</td>
              </tr>
            }
             
            </tbody>
          </table>
        </div>
        <div className="tax-amount-container">
          {
            isTax === true ?
          
          <div className="tax-amount-container-child">
            <div className="total-input-container">
              <p>{currentCountry === 'india' ? 'IGST' : 'VAT'}</p>
              <input value={addPurchaseForm3?.igstTotal} type="text" name="" id="" />
            </div>
            {
              currentCountry === 'india' &&
              <>
              <div className="total-input-container">
              <p>GST</p>
              <input value={addPurchaseForm3?.gstTotal} type="text" name="" id="" />
            </div>
               <div className="total-input-container">
              <p>SGST</p>
              <input value={(addPurchaseForm3?.gstTotal)/2} type="text" name="" id="" />
            </div>
            <div className="total-input-container">
              <p>CGST</p>
              <input value={(addPurchaseForm3?.gstTotal)/2} type="text" name="" id="" />
            </div>
              </>
            }
           
          </div>
          :
           <div className="tax-amount-container-child"></div>
         } 

          <div className="tax-amount-container-child second">
            <div className="total-input-container">
              <p>Amount</p>
              <input value={addPurchaseForm3?.amountTotal} type="text" name="" id="" />
            </div>
            <div className="total-input-container">
              <p>Disc %</p>
              <div className="disc-container">
                <input 
                value={isNaN(addPurchaseForm3?.discountPercent)?'':addPurchaseForm3?.discountPercent} 
                onChange={getAddPurchaseForm3("percentage")} 
                type="text" 
                className="small"
                    
                   />
                <input 
                value={isNaN(addPurchaseForm3?.discountAmt)?'':addPurchaseForm3?.discountAmt} 
                onChange={getAddPurchaseForm3("amount")} 
                type="text" 
                className="large" 
                />
              </div>
            </div>
            <div className="total-input-container">
              <p>Net Amount</p>
              <input value={isNaN(addPurchaseForm3?.netAmount)?'':addPurchaseForm3?.netAmount} type="text" name="" id="" />
            </div>
          </div>
        </div>

        <div className="cancel-submit-container">
          <button className="btn btn-secondary-outlined">Cancel</button>
          <button onClick={finalSubmitBtnFn} className="btn btn-primary">Submit</button>
        </div>
      </div>
      {/* snackbar */}
      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />

      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </div>
  );
};
