import React from "react";
import { useSelector } from "react-redux";

export const ChuridharWO = (props) => {
  const { formValues, onChange,addButtonClickFn,remarksList} = props;
  //*Redux States
  //Role of User [admin or user]
  const userRole = useSelector((state) => state.userRoleSlice.value);

  return (
    <div className="work-order-middle-container">
      <div className="work-order-middle-left-container">
        <div className="global-single-input work-order-input">
          <p>Shoulder</p>
          <input
            value={formValues?.shoulder}
            onChange={userRole !== "admin" && onChange("shoulder")}
            placeholder={userRole !== "admin" && "Enter shoulder..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>Front Neck</p>
          <input
            value={formValues?.frontNeck}
            onChange={userRole !== "admin" && onChange("frontNeck")}
            placeholder={userRole !== "admin" && "Enter front neck..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>Back Neck</p>
          <input
            value={formValues?.backNeck}
            onChange={userRole !== "admin" && onChange("backNeck")}
            placeholder={userRole !== "admin" && "Enter back neck..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>Waist</p>
          <input
            value={formValues?.waist}
            onChange={userRole !== "admin" && onChange("waist")}
            placeholder={userRole !== "admin" && "Enter waist..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>Hip</p>
          <input
            value={formValues?.hip}
            onChange={userRole !== "admin" && onChange("hip")}
            placeholder={userRole !== "admin" && "Enter hip..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>Full Length</p>
          <input
            value={formValues?.fullLength}
            onChange={userRole !== "admin" && onChange("fullLength")}
            placeholder={userRole !== "admin" && "Enter full length..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>Arm Round</p>
          <input
            value={formValues?.armRound}
            onChange={userRole !== "admin" && onChange("armRound")}
            placeholder={userRole !== "admin" && "Enter arm round..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>Arm Hole</p>
          <input
            value={formValues?.armHole}
            onChange={userRole !== "admin" && onChange("armHole")}
            placeholder={userRole !== "admin" && "Enter arm hole..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>Sleeve Length</p>
          <input
            value={formValues?.sleeveLength}
            onChange={userRole !== "admin" && onChange("sleeveLength")}
            placeholder={userRole !== "admin" && "Enter sleeve length..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input" style={{position:"relative"}}>
          <p>Notes</p>
          <input
            value={formValues?.remarks}
            onChange={userRole !== "admin" && onChange("remarks")}
           
            placeholder={userRole !== "admin" && "Enter remarks..."}
            disabled={userRole === "admin" ? true : false}
          />
           {/* <button onClick={addButtonClickFn} className="work-order-notes-add-button">+</button>
          <div className="work-order-notes-content">
            {
              remarksList?.map((r)=>(
                 <p>{r}</p>
              ))
            }
          </div>  */}
        </div>
      </div>
    </div>
  );
};
