import { Dialog, IconButton, Skeleton } from "@mui/material";
import React from "react";
import { useState } from "react";

export const StaffPaySlip = () => {
  const [paySlipOpen, setPaySlipOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <div className="global-page-parent-container">
        <p className="breadcrumb">Staff &gt; Payroll &gt;Employee Salary</p>
        <div className="global-white-bg-container payroll-emp-salary-container">
          <div className="payroll-emp-salary-top">
            <h3>Payslip</h3>
          </div>
          <table className="global-table payroll-emp-salary-table">
            <thead>
              <tr>
                <th>No</th>
                <th>Image</th>
                <th>Name</th>
                <th>Employee ID</th>
                <th>Mobile</th>
                <th>Joining Date</th>
                <th>Designation</th>
                <th>Status</th>
                <th>Payslip</th>
                <th>Download</th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={12}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={12}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={12}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={12}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={12}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td>01</td>
                  <td>
                    <img src="" alt="emp img" />
                  </td>
                  <td>Nihad</td>
                  <td>EMS98765</td>
                  <td>9209876543</td>
                  <td>10-12-2020</td>
                  <td>Cashier</td>
                  <td>
                    <button className="btn btn-approved">Paid</button>
                  </td>
                  <td>
                    <IconButton
                      onClick={() => {
                        setPaySlipOpen(true);
                      }}
                    >
                      <i class="bi bi-eye visibility-icon"></i>
                    </IconButton>
                  </td>
                  <td>
                    <IconButton>
                      <i
                        class="bi bi-arrow-down-circle"
                        style={{ color: "#f19800" }}
                      ></i>
                    </IconButton>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>

      <Dialog
        open={paySlipOpen}
        maxWidth="lg"
        onClose={() => setPaySlipOpen(false)}
        onKeyDown={(e) => e.key === "Escape" && setPaySlipOpen(false)}
      >
        <div className="payroll-payslip-container">
          <div className="payroll-payslip-container-header">
            <h3>Payslip</h3>
            <div className="payroll-payslip-container-header-right">
              <button className="btn btn-payslip-red">
                <IconButton>
                  <i
                    class="bi bi-arrow-down-circle"
                    style={{ color: "red" }}
                  ></i>
                </IconButton>
              </button>
              <button className="btn btn-payslip-blue">
                <IconButton>
                  <i
                    class="bi bi-printer printer-icon"
                    style={{ color: "blue" }}
                  ></i>
                </IconButton>
              </button>
              <button
                className="btn payroll-payslip-container-header-right-close"
                onClick={() => {
                  setPaySlipOpen(false);
                }}
              >
                X
              </button>
            </div>
          </div>
          <div className="payroll-payslip-container-body">
            <div className="payroll-payslip-container-body-section">
              <div className="payroll-payslip-container-body-top">
                <div className="payroll-payslip-container-body-top-section">
                  <p>Employee Name: Nihad</p>
                  <p>Employee ID: GSFA5464AF</p>
                </div>
                <div className="payroll-payslip-container-body-top-section">
                  <p>Total Leave: 05</p>
                </div>
              </div>
              <div className="payroll-payslip-container-body-table">
                <div className="payroll-payslip-container-body-table-container">
                  <div className="payroll-payslip-container-body-table-section">
                    <div className="payroll-payslip-container-body-table-section-top">
                      <h3>Earnings</h3>
                    </div>
                    <div className="payroll-payslip-container-body-table-section-body">
                      <div className="payroll-payslip-container-body-table-section-body-left">
                        <div className="payroll-payslip-container-body-table-section-body-left-top">
                          <h5>Pay Type</h5>
                        </div>
                        <div className="payroll-payslip-container-body-table-section-body-left-body">
                          <h5>Basic</h5>
                          <h5>Medical allowance</h5>
                          <h5>Bonus Allowance</h5>
                        </div>
                        <div className="payroll-payslip-container-body-table-section-body-left-total">
                          <h4>Total Earnings</h4>
                        </div>
                      </div>
                      <div className="payroll-payslip-container-body-table-section-body-right">
                        <div className="payroll-payslip-container-body-table-section-body-right-top">
                          <h5>Amount</h5>
                        </div>
                        <div className="payroll-payslip-container-body-table-section-body-right-body">
                          <h5>RS 15,000</h5>
                          <h5>0.00</h5>
                          <h5>0.00</h5>
                        </div>
                        <div className="payroll-payslip-container-body-table-section-body-right-total">
                          <h5>Rs 15,000</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="payroll-payslip-container-body-table-section">
                    <div className="payroll-payslip-container-body-table-section-top">
                      <h3>Deduction</h3>
                    </div>
                    <div className="payroll-payslip-container-body-table-section-body">
                      <div className="payroll-payslip-container-body-table-section-body-left">
                        <div className="payroll-payslip-container-body-table-section-body-left-top">
                          <h5>Pay Type</h5>
                        </div>
                        <div className="payroll-payslip-container-body-table-section-body-left-body">
                          <h5>PF</h5>
                          <h5>Tax</h5>
                        </div>
                        <div className="payroll-payslip-container-body-table-section-body-left-total">
                          <h4>Total Deductions</h4>
                        </div>
                      </div>
                      <div className="payroll-payslip-container-body-table-section-body-right">
                        <div className="payroll-payslip-container-body-table-section-body-right-top">
                          <h5>Amount</h5>
                        </div>
                        <div className="payroll-payslip-container-body-table-section-body-right-body">
                          <h5>0.00</h5>
                          <h5>0.00</h5>
                        </div>
                        <div className="payroll-payslip-container-body-table-section-body-right-total">
                          <h5>0.00</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="payroll-payslip-container-body-total">
              <div className="payroll-payslip-container-body-total-section">
                <h3>Net Salary</h3>
                <p>Inwords</p>
              </div>
              <div className="payroll-payslip-container-body-total-section">
                <h3>Rs 15,000</h3>
                <p>Fifteen Thousand</p>
              </div>
            </div>
            <div className="payroll-payslip-container-body-footer">
              <h4>Company Address</h4>
              <p>Lorem Ipsum is simply dummy text of the </p>
              <p>printing and typesetting industry.</p>
              <p>Lorem Ipsum</p>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};
