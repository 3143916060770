import axios from "axios";
import { HEADERS } from "../../UrlAndPaths";
import store from "../../../Redux/store";
import { get_shift } from "../../../Redux/Settings/Shift/viewShiftSlice";
import { get_employeelist_by_branch, update_shift_status } from "../../../Redux/Settings/Shift/shiftStatusSlice";

//View all shift API Call
export const viewShiftAPICall = async (body) => {

  await axios
    .post("settings/viewshifts",body, HEADERS)

    .then((res) => {
      if (res.status === 200) {
        store.dispatch(get_shift({ viewShiftData: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(get_shift({ viewShiftData: undefined }));
      console.log(err.response);
    });
};

//Know shift status
//body eg-- { "branchId":"TESPA1"}
export const getShiftStatusAPICAll = async (body) => {
  await axios.post("settings/viewactiveshift", body, HEADERS).then((res)=>{
    if(res.status===200)
    {
      store.dispatch(update_shift_status({shiftStatus:res.data}))
      localStorage.setItem('shiftStatus',JSON.stringify(res.data))
    }
  }).catch((err)=>{
      store.dispatch(update_shift_status({shiftStatus:undefined}))
      console.error(err.response);
  });
};

//Add Shift 
export const addShiftAPICall=async (body,setSuccess,setError,setSnackMsg,closeDialog)=>{
  await axios.post('settings/addshift',body,HEADERS).then((res)=>{
    if(res.status===200)
    {
        setSnackMsg('Shift added 👍')
        setSuccess(true)
        // closeDialog(false)
    }
  }).catch((err)=>{
    if(err.response.status === 500){
      setSnackMsg('Internal Server error !')
    }
    else{
      setSnackMsg(err?.response?.data?.msg)
    }
    setError(true)
    console.error(err.response);
  })
}


///  View employee by branch in add manual shift
export const viewEmployeeByBranchInShift=(body)=>{
  axios.post('settings/viewshiftsettingsbyid',body,HEADERS)
  .then((res)=>{
    if(res.status===200){
       store.dispatch(get_employeelist_by_branch({empoloyeeBranchData:res.data}))
    }
  })
  .catch((err)=>{
    store.dispatch(get_employeelist_by_branch({empoloyeeBranchData:undefined}))
    
  })
}

