import axios from "axios"
import { get_all_order_invoiceNo } from "../../../../Redux/Settings/Utility/ShiftTransfer/shiftTransferOrderListInvoiceNoSlice"
import { get_shift_transfer_order_list } from "../../../../Redux/Settings/Utility/ShiftTransfer/shiftTransferOrderListSlice"
import store from "../../../../Redux/store"
import { HEADERS } from "../../../UrlAndPaths"


// get all order list by order type
export const shiftTransferOrderListAPICall = async (body) => {
 await axios.post("settings/shifttransferorderview",body,HEADERS)
 .then((res)=>{
    if(res.status===200){
        store.dispatch(get_shift_transfer_order_list({shiftTransferOrderListData:res.data}))
    }
 })
 .catch((err)=>{
    store.dispatch(get_shift_transfer_order_list({shiftTransferOrderListData:undefined}))
    console.log(err.response);
 })
}

// get all invoice no of all  orders 
export const shiftTransferOrdersInvoiceNoAPICall=async(body)=>{
  await axios.post("settings/shifttransferorderinvoices",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_all_order_invoiceNo({allOrdersInvoiveNoList:res.data}))
    }
  })
  .catch((err)=>{
   store.dispatch(get_all_order_invoiceNo({allOrdersInvoiveNoList:undefined}))
   console.log(err.response);
  })
}

// add shift transfer api call
export const addShiftTransferAPICall=async(body,updateListner,clearState,setShiftTransfer)=>{
 await axios.post("settings/shiftTransfer",body,HEADERS)
 .then((res)=>{
    if(res.status===200){
      updateListner()
      clearState()
      setShiftTransfer(false)
    }
 })
 .catch((err)=>{
  console.log(err.response);
 })
}

// add shift transfer payment  api call
export const addShiftTransferPaymentAPICall=async(body,updateListner,clearState,setShiftTransfer)=>{
  await axios.post("credit/paymentShiftIdUpdate",body,HEADERS)
  .then((res)=>{
     if(res.status === 200){
        updateListner()
        clearState()
        setShiftTransfer(false)
     }
  })
  .catch((err)=>{
    console.log(err.response);
  })
}