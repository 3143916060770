import React from "react";
import { Dialog, IconButton, Select } from "@mui/material";
import { useSelector } from "react-redux";
import {
  addLengthCommissionAPICall,
  deleteLengthCommissionAPICall,
  viewLengthCommissionAPICall,
} from "../../../../API/Orders/workOrderAPI";
import { useEffect } from "react";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import { useState } from "react";

export const AbayaWO = (props) => {
  const { formValues, onChange } = props;
  //*Redux States
  //Role of User [admin or user]
  const userRole = useSelector((state) => state.userRoleSlice.value);
  const lengthCommissionData = useSelector(
    (state) => state.lengthCommissionSlice.value
  );

  const [isModify, setIsModify] = useState(false);
  //Snackbar States
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!");

  const [addLengthCommission, setAddLengthCommission] = useState(false);
  
  // validation states
  const [usedQtyAlert,setUsedQtyAlert]=useState(false)
  const [lengthFromAlert,setLengthFromAlert]=useState(false)
  const [lengthToAlert,setLengthToAlert]=useState(false)
  //Length Commission Form States
  const lengthCommissionInitialState = {
    lengthFrom: "",
    lengthTo: "",
    usedQty: "",
    minRate: 0,
    commission: 0,
  };
  const [lengthCommissionForm, setLengthCommissionForm] = useState(
    lengthCommissionInitialState
  );
  //--------------------------------------------------
  //Update Listener
  const updateListener = () => {
    setIsModify(!isModify);
  };

  //Close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setSuccessSnackOpen(false);
  };
  //Close ErrorSnackbar
  const closeErrorSnackbar = () => {
    setErrorSnackOpen(false);
  };
  //-------------------------------------------------
  //*onChange
  //Get Length commission Form
  const getLengthCommissionForm = (key) => (e) => {
    const { value } = e.target;

    if (key === "lengthFrom") {
      setLengthCommissionForm({ ...lengthCommissionForm, lengthFrom: value });
      setLengthFromAlert(false)
      if(value===""){
        setLengthFromAlert(true)
      }
    }
    if (key === "lengthTo") {
      setLengthCommissionForm({ ...lengthCommissionForm, lengthTo: value });
      setLengthToAlert(false)
      if(value===""){
        setLengthToAlert(true)
      }
    }
    if (key === "usedQty") {
      setLengthCommissionForm({ ...lengthCommissionForm, usedQty: value });
      setUsedQtyAlert(false)
      if(value===""){
        setUsedQtyAlert(true)
      }
    }
    if (key === "minRate") {
      setLengthCommissionForm({ ...lengthCommissionForm, minRate: value });
    }
    if (key === "commission") {
      setLengthCommissionForm({ ...lengthCommissionForm, commission: value });
    }
  };

  //------------------------------------------------
  //*onClick
  //Add Length Commission body
  const addLengthCommissionBody = {
    fromLength: parseFloat(lengthCommissionForm?.lengthFrom),
    toLength: parseFloat(lengthCommissionForm?.lengthTo),
    usedQty: parseFloat(lengthCommissionForm?.usedQty),
    minRate: parseFloat(lengthCommissionForm?.minRate),
    commission: parseFloat(lengthCommissionForm?.commission),
  };
  //Add Length Commission
  const addNewLengthCommission = () => {
    if (lengthCommissionForm?.lengthFrom === "") {
      setLengthFromAlert(true)
    } else if (lengthCommissionForm?.lengthTo === "") {
      setLengthToAlert(true)
    } else if (lengthCommissionForm?.usedQty === "") {
      setUsedQtyAlert(true)
    } else {
      addLengthCommissionAPICall(
        addLengthCommissionBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        clearLengthCommissionState
      );
    }
  };
  //Delete Length Commission
  const deleteLengthCommission = (lengthCommissionId) => () => {
    deleteLengthCommissionAPICall(
      { lengthCommissionId },
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      updateListener
    );
  };
  //-----------------------------------------------
  //*useEffects
  useEffect(() => {
    viewLengthCommissionAPICall();
  }, [isModify]);

  //----------------------------------------------
  //*Clear States
  //Length Commission Clear State
  const clearLengthCommissionState = () => {
    setLengthCommissionForm(lengthCommissionInitialState);
  };
  return (
    <div className="work-order-middle-container">
      <div className="work-order-middle-left-container">
        <div className="global-single-input work-order-input">
          <p>Sleeve From Neck</p>
          <input
            value={formValues?.sleeveFromNeck}
            onChange={userRole !== "admin" && onChange("sleeveFromNeck")}
            placeholder={userRole !== "admin" && "Enter sleeve from neck..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>Sleeve From Shoulder</p>
          <input
            value={formValues?.sleeveFromShoulder}
            onChange={userRole !== "admin" && onChange("sleeveFromShoulder")}
            placeholder={userRole !== "admin" && "Enter sleeve from shoulder..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>Sleeve Width</p>
          <input
            value={formValues?.sleeveWidth}
            onChange={userRole !== "admin" && onChange("sleeveWidth")}
            placeholder={userRole !== "admin" && "Enter sleeve width..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>Arms</p>
          <input
            value={formValues?.arms}
            onChange={userRole !== "admin" && onChange("arms")}
            placeholder={userRole !== "admin" && "Enter arms..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>Bust</p>
          <input
            value={formValues?.bust}
            onChange={userRole !== "admin" && onChange("bust")}
            placeholder={userRole !== "admin" && "Enter bust..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>Waist</p>
          <input
            value={formValues?.waist}
            onChange={userRole !== "admin" && onChange("waist")}
            placeholder={userRole !== "admin" && "Enter waist..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>Hip</p>
          <input
            value={formValues?.hip}
            onChange={userRole !== "admin" && onChange("hip")}
            placeholder={userRole !== "admin" && "Enter hip..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>Length</p>
          <input
            value={formValues?.length}
            onChange={userRole !== "admin" && onChange("length")}
            placeholder={userRole !== "admin" && "Enter length..."}
            disabled={userRole === "admin" ? true : false}
          />
          {userRole === "admin" && (
            <p
              onClick={() => setAddLengthCommission(true)}
              className="suffix-text-blue"
            >
              Add Length Commission
            </p>
          )}
        </div>
        <div className="global-single-input work-order-input" style={{position:"relative"}}>
          <p>Notes</p>
          <input
            value={formValues?.remarks}
            onChange={userRole !== "admin" && onChange("remarks")}
            placeholder={userRole !== "admin" && "Enter remarks..."}
            disabled={userRole === "admin" ? true : false}
          />
          {/* <button className="work-order-notes-add-button">+</button>
          <div className="work-order-notes-content">
            <p>abc</p>
            <p>abc</p>
            <p>abc</p>
          </div> */}
        </div>
      </div>

      {/* add length commission */}
      <Dialog open={addLengthCommission} maxWidth="lg"  onKeyDown={(e)=>e.key === "Escape" && setAddLengthCommission(false)}>
        <div className="add-thob-model-dialog-container">
          <h3>Add Length Commission</h3>
          <div className="add-thob-model-dialog-input-container">
            <div className="global-single-input from-length">
              <p>Length From</p>
              <input
                type="text"
                placeholder="Length from..."
                value={lengthCommissionForm?.lengthFrom}
                onChange={getLengthCommissionForm("lengthFrom")}
              />
              {
                lengthFromAlert?
                <p className="doc-validation-alert">Fill this field !!</p>
                :""
              }
            </div>
            <div className="global-single-input from-length">
              <p>To</p>
              <input
                type="text"
                placeholder="Length to..."
                value={lengthCommissionForm?.lengthTo}
                onChange={getLengthCommissionForm("lengthTo")}
              />
              {
                lengthToAlert?
                <p className="doc-validation-alert">Fill this field !!</p>
                :""
              }
            </div>
            <div className="global-single-input from-length">
              <p>Used Qty</p>
              <input
                type="text"
                placeholder="Used Qty..."
                value={lengthCommissionForm?.usedQty}
                onChange={getLengthCommissionForm("usedQty")}
              />
              {
                usedQtyAlert?
                <p className="doc-validation-alert">Fill this field !!</p>
                :""
              }
            </div>
            <div className="global-single-input from-length">
              <p>Min Rate</p>
              <input
                type="text"
                placeholder="Minimum rate..."
                value={lengthCommissionForm?.minRate}
                onChange={getLengthCommissionForm("minRate")}
              />
            </div>
            <div className="global-single-input from-length">
              <p>Commission</p>
              <input
                type="text"
                placeholder="Commission..."
                value={lengthCommissionForm?.commission}
                onChange={getLengthCommissionForm("commission")}
                onKeyDown={(e) => e.key === "Enter" && addNewLengthCommission()}
              />
            </div>
            <button onClick={addNewLengthCommission}>+</button>
          </div>
          <div className="add-popup-table">
            <table>
              <thead>
                <tr>
                  <th>Length(From-To)</th>
                  <th>Used Qty</th>
                  <th>Min Rate</th>
                  <th>Commission</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {lengthCommissionData
                  ?.slice(0)
                  ?.reverse()
                  ?.map((r, i) => (
                    <tr key={i}>
                      <td>{`${r?.fromLength} - ${r?.toLength}`}</td>
                      <td>{r?.usedQty}</td>
                      <td>{r?.minRate}</td>
                      <td>{r?.commission}</td>
                      <td>
                        <IconButton onClick={deleteLengthCommission(r?._id)}>
                          <i class="bi bi-trash3 delete-icon"></i>
                        </IconButton>
                      </td>
                    </tr>
                  )) || (
                  <tr>
                    <td colSpan={5}>No Data</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="add-popup-button-container">
            <button
              onClick={() => setAddLengthCommission(false)}
              className="btn btn-secondary"
            >
              Close
            </button>
          </div>
        </div>
      </Dialog>

      {/* SnackBars  */}
      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </div>
  );
};
