import React, { useState } from "react";
import "../../../../css/Purchase Manager/purchaseReturn.css";
import { Select, MenuItem, IconButton, Dialog, Autocomplete, TextField, Skeleton, sliderClasses } from "@mui/material";
import { Datepicker } from "../../../Single Components/Datepicker";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { addPurchseReturnAPICall, deleteSinglePurchaseReturnAPICall, generatePurchaseRetunAPICall, viewpurchaseAndPurchaseWOPOByIdAPICall, viewPurchaseReturnAPICall, viewPurchaseReturnInvoiceNoList } from "../../../../API/Purchase Manager/purchaseReturnAPI";
import { viewAllSuppliersAPICall } from "../../../../API/Purchase Manager/supplierAPI";
import { viewInvoiceNumberListAPICall } from "../../../../API/Purchase Manager/grnAPI";
import { convertDateFormat, currentTime, getCurrentTime, today } from "../../../../Js/Date";
import { viewAllBranchesAPICall } from "../../../../API/Settings/Company Settings/companySettingsAPI";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
export const PurchaseReturn = () => {
// generate purchase return id
const purchaseReturnTransId=useSelector((state)=>state.purchaseReturnIdSlice.value)
// invoice number list 
const invoiceNoList= useSelector((state)=>state.addPurchseReturnInvoiceList.value)
//purchase and purchase without po by id
const purchaseAndPurchaseWOPOById =useSelector((state)=>state.purchaseAndPurchaseWOPOByIdSlice.value)
//List of all Branches
const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
//Login response for branch id
const loginResponse = useSelector((state) => state.loginResponseSlice.value);
// role based rendering
const userRole = useSelector((state) => state.userRoleSlice.value);
// purchase return list
const purchaseReturnList=useSelector((state)=>state.viewPurchaseReturnList.value) 
 //List of All Suppliers
const allSuppliersList = useSelector(
  (state) => state.allSupplierListSlice.value
);
const storeCode=localStorage.getItem('branchId')

  const [editReturnDialog, setEditReturnDialog] = useState(false);
  const [addReturnDialog, setAddReturnDialog] = useState(false);
  const [invoiceNumber,setInvoiceNumber]=useState(null)

  const invoiceNumberInitialState={
    invoiceId:'',
    type:''
  }
  const [invoiceForm,setInvoiceForm]=useState(invoiceNumberInitialState)
  const purhaseReturnForm={
    supplireName:'',
    supplierId:'',
    invoice:'',
    returnDate:today,
    returnQty:'',
    rate:'',
    amount:'',
    reason:'',
    product:'',
    totalAmount:0,
    purchasePk:'',
    type:'',
    itemsTotal:''

  }
  const [productCode,setProductCode]=useState(null)
  const [purchaseReturnForm,setPurchaseReturnForm]=useState(purhaseReturnForm)
  const purchaseReturnEditFormInitial={
    invoice:'',
    returnDate:'',
    returnQty:'',
    rate:'',
    amount:'',
    returnInfo:'',
    reason:'',
    originalQty: '',
    originalRate: '',
    originalAmt: '',
    productName:'',
    totalAmount:0,
    purchasePk:'',
    type:'',
    itemsTotal:''
  }
  const [purchaseReturnEditForm,setPurchaseReturnEditForm]=useState(purchaseReturnEditFormInitial)
  const [filteredTableData,setFilteredTableData]=useState([])
  const [addPurchaseReturnTable,setAddPurchaseReturnTable]=useState([])
  const [addPurchaseReturnTableForBackEnd,setAddPurchaseReturnTableForBackEnd]=useState([])
  const [branch,setBranch]=useState(null)
  const [isModify,setIsModify]=useState(false)
  const [purchseReturnSingleView,setPurchseReturnSingleView]=useState(false)
  const [singleViewId,setSingleViewId]=useState()
  const [returnInfoArray,setReturnInfoArray]=useState([])
  const [singleViewData,setSingleViewData]=useState([])
  const [supplier,setSupplier]=useState(null)
  const [fromDate,setFromDate]=useState('')
  const [toDate,setToDate]=useState('')
  const [branchName,setBranchName]=useState(null)
  const [isLoading,setIsLoading]=useState(false)

  // validation states
  const [branchAlert,setBranchAlert]=useState(false)
  const [invoiceAlert,setInvoiceAlert]=useState(false)
  const [addProductAlert,setAddProductAlert]=useState(false)
  const [productCodeAlert,setProductCodeAlert]=useState(false)
  const [returnQtyAlert,setReturnQtyAlert]=useState(false)
  const [returnRateAlert,setReturnRateAlert]=useState(false)

  const [snackMsg, setSnackMsg] = useState("oops!");
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false);
  const [openErrorSnack, setOpenErrorSnack] = useState(false);
  const [openEditContainer, setOpenEditContainer] = useState(false);

  //close Success Snack
  const closeSuccessSnack = () => {
    setOpenSuccessSnack(false);
  };
  //close Error Snack
  const closeErrorSnack = () => {
    setOpenErrorSnack(false);
  };
  const updateLister=()=>{
    setIsModify(!isModify)
  }

  // purchase return api call
  useEffect(()=>{
    generatePurchaseRetunAPICall()
    viewAllSuppliersAPICall();
    // viewInvoiceNumberListAPICall({type:1,branchId:branch})
    viewAllBranchesAPICall()
    {
      userRole==="user"?
      viewPurchaseReturnAPICall({branchId:storeCode,
                                fromDate:fromDate !== '' ? `${fromDate} 00:00:00`:null,
                                toDate:toDate !== '' ? `${toDate} 23:59:59` :null,
                                supplierId:supplier !== null ? supplier :null,
                                },setIsLoading)
      :
      viewPurchaseReturnAPICall({fromDate:fromDate !== '' ? `${fromDate} 00:00:00`:null,
                                  toDate:toDate !== '' ? `${toDate} 23:59:59` :null,
                                  supplierId:supplier !== null ? supplier :null,
                                  branchId:branchName !== null ? branchName :null},setIsLoading)
    }
    viewAllSuppliersAPICall();
    viewAllBranchesAPICall();
  },[isModify])

  useEffect(()=>{
    // viewInvoiceNumberListAPICall({type:1,branchId:branch})
    viewPurchaseReturnInvoiceNoList({branchId:userRole==="admin"?branch:storeCode})
  },[branch])


  /// view purchse return
 const submitBtnFn=()=>{
  viewPurchaseReturnAPICall({
    fromDate:fromDate !== '' ? `${fromDate} 00:00:00`:null,
    toDate:toDate !== '' ? `${toDate} 23:59:59` :null,
    supplierId:supplier !== null ? supplier :null,
    branchId:userRole === "user" ? storeCode:branchName
  }
  ,setIsLoading)
 }
   
  

  useEffect(()=>{
    setInvoiceForm({...invoiceForm,
      invoiceId:invoiceNumber?._id,
      type:invoiceNumber?.type
    })
  },[invoiceNumber])

  useEffect(()=>{
    viewpurchaseAndPurchaseWOPOByIdAPICall({
      _id:invoiceForm?.invoiceId,
      type:invoiceForm?.type
    })
  },[invoiceForm])


 
  useEffect(()=>{
    setPurchaseReturnForm({
      ...purchaseReturnForm,
      supplireName:purchaseAndPurchaseWOPOById?.supplierName,
      supplierId:purchaseAndPurchaseWOPOById?.supplierId,
      invoice:purchaseAndPurchaseWOPOById?.invoiceNo,
      totalAmount:purchaseAndPurchaseWOPOById?.amountTotal,
      purchasePk:purchaseAndPurchaseWOPOById?._id,
      type:purchaseAndPurchaseWOPOById?.type
     
    })
  },[purchaseAndPurchaseWOPOById])

  useEffect(()=>{
    setPurchaseReturnForm({
      ...purchaseReturnForm,
      product:productCode?.code
    })
  },[productCode])

  useEffect(()=>{
    let filteredProduct=[]
    filteredProduct=purchaseAndPurchaseWOPOById?.purchaseInfo?.filter((obj)=>{
      if(obj?.code===purchaseReturnForm?.product){
        return obj
      }
    })
    setFilteredTableData(filteredProduct)
  },[purchaseReturnForm?.product])



  const getPurchaseReturnForm=(key)=>(e)=>{
    const {value} = e.target;
    if(key==="returnQty"){
      const limit=filteredTableData !== undefined && filteredTableData[0]?.qty
      if(value>limit){
        setPurchaseReturnForm({
          ...purchaseReturnForm,
          returnQty:limit
        })
      }
      else{
        setPurchaseReturnForm({
          ...purchaseReturnForm,
          returnQty:value
        })
      }
      setReturnQtyAlert(false)
      if(value===""){
        setReturnQtyAlert(true)
      }
    }
    if(key==="rate"){
      setPurchaseReturnForm({
        ...purchaseReturnForm,
        rate:value
      })
      setReturnRateAlert(false)
      if(value===""){
        setReturnRateAlert(true)
      }
    }
    if(key==="reason"){
    
     
      setPurchaseReturnForm({
        ...purchaseReturnForm,
        reason:value
      })
    }
    if(key==="fromDate"){
      setFromDate(value)
    }
    if(key==="toDate"){
      setToDate(value)
    }
  }

  const calculateTotalAmount=()=>{
    let total=0;
    total=parseFloat(purchaseReturnForm?.returnQty)*parseFloat(purchaseReturnForm?.rate)
    setPurchaseReturnForm({
      ...purchaseReturnForm,
      amount:isNaN(total)?'':total
    })
  }
  useEffect(()=>{
    calculateTotalAmount()
  },[purchaseReturnForm?.returnQty,purchaseReturnForm?.rate])

  /// add purchase return add button function
  const purchaseReturnAddBtnFn=()=>{
    if(productCode===null){
      setProductCodeAlert(true)
    }
    else if(purchaseReturnForm?.returnQty===""){
      setReturnQtyAlert(true)
    }
    else if(purchaseReturnForm?.rate===""){
      setReturnRateAlert(true)
    }
    else{
      setAddPurchaseReturnTable([...addPurchaseReturnTable,
        {
          returnDate:purchaseReturnForm?.product,
          productCode:purchaseReturnForm?.returnQty,
          productName:filteredTableData !== undefined && filteredTableData[0]?.name,
          productType:filteredTableData !== undefined && filteredTableData[0]?.type,
          productId:filteredTableData !== undefined && filteredTableData[0]?._id,
          qty:filteredTableData !== undefined && filteredTableData[0]?.qty,
          rate:filteredTableData !== undefined && filteredTableData[0]?.rate,
          amount:filteredTableData !== undefined && filteredTableData[0]?.amount,
          returnQty:purchaseReturnForm?.returnQty,
          returnRate:purchaseReturnForm?.rate,
          returnAmount:purchaseReturnForm?.amount,
          reason:purchaseReturnForm?.reason,
          dimension:filteredTableData !== undefined && filteredTableData[0]?.dimension
          }
        ])
        setAddPurchaseReturnTableForBackEnd([...addPurchaseReturnTableForBackEnd,
        {
          itemCode:purchaseReturnForm?.product,
          itemName:filteredTableData !== undefined && filteredTableData[0]?.name,
          itemType:filteredTableData !== undefined && filteredTableData[0]?.type,
          returnQty:parseFloat(purchaseReturnForm?.returnQty),
          rate:parseFloat(purchaseReturnForm?.rate),
          amount:purchaseReturnForm?.amount,
          reason:purchaseReturnForm?.reason,
          originalQty:filteredTableData !== undefined && filteredTableData[0]?.qty,
          originalRate:filteredTableData !== undefined && filteredTableData[0]?.rate,
          originalAmt:filteredTableData !== undefined && filteredTableData[0]?.amount,
          itemInfo:filteredTableData !== undefined && filteredTableData[0]?.itemInfo,
          dimension:filteredTableData !== undefined && filteredTableData[0]?.dimension,
          uuid:filteredTableData !== undefined && filteredTableData[0]?.uuid
        }
        ])
        setAddProductAlert(false)
        setProductCode(null)
        setPurchaseReturnForm({
          ...purchaseReturnForm,
          returnQty:'',
          rate:'',
          amount:'',
          reason:''
        })
    }
   
  }
  // console.log(addPurchaseReturnTableForBackEnd);
  const bodyToApi={
    invoiceNo:purchaseReturnForm?.invoice,
    supplierId:purchaseReturnForm?.supplierId,
    returnInfo:addPurchaseReturnTableForBackEnd,
    branchId:userRole==="user"?loginResponse?.branchId:branch,
    returnDate:`${purchaseReturnForm?.returnDate} ${getCurrentTime()}`,
    purchasePk:purchaseReturnForm?.purchasePk,
    type:purchaseReturnForm?.type,
    total:addPurchaseReturnTableForBackEnd?.length!==0 && addPurchaseReturnTableForBackEnd.reduce((total,obj)=>{
      return total +obj?.amount
    },0)
  }

  // add purchase return submit button foumction
  const addPurchaseReturnSubmitBtnFn=()=>{
  // if(branch===null){
  //   setBranchAlert(true)
  // }
  // else 
  if(invoiceNumber===null){
    setInvoiceAlert(true)
   }
  else if(addPurchaseReturnTable?.length === 0){
    setAddProductAlert(true)
  }
   else{
    // console.log(bodyToApi);
    addPurchseReturnAPICall(bodyToApi,updateLister,clearState,setAddReturnDialog)
   
   }
   
  }
  // clear state
  const clearState=()=>{
    setInvoiceNumber(null)
    setAddPurchaseReturnTable([])
    setAddPurchaseReturnTableForBackEnd([])
  }

  // add purchse return cancel buttton function
  const cancelButtonFunction=()=>{
    setAddReturnDialog(false)
    clearState()
  }

  // purchase return single view
  const OpenPurchaseReturnDialog=(id)=>{
    setPurchseReturnSingleView(true)
    setSingleViewId(id)
  }

  /// extract single purchse return info
  useEffect(()=>{
    let filteredData=[]
    filteredData= purchaseReturnList?.filter((obj)=>{
     return  obj._id===singleViewId
    })
    setSingleViewData(filteredData)

    filteredData?.length!==0 && filteredData !==undefined&& setReturnInfoArray(filteredData[0]?.returnInfo)
  },[singleViewId,purchaseReturnList])
  // delete add purchse return table data
  const deleteAddReturnTableData=(i)=>{
    addPurchaseReturnTable.splice(i,1)
    addPurchaseReturnTableForBackEnd.splice(i,1)
    setAddPurchaseReturnTable([...addPurchaseReturnTable])
    setAddPurchaseReturnTableForBackEnd([...addPurchaseReturnTableForBackEnd])

  }
  
  const clickDeletePurchaseReturn=(id)=>()=>{
    deleteSinglePurchaseReturnAPICall({_id:id},
                                      submitBtnFn,
                                      setOpenSuccessSnack,
                                      setOpenErrorSnack,
                                      setSnackMsg)
  }
  const clickEditPurchaseReturn=(id)=>()=>{
        setEditReturnDialog(true)
        setSingleViewId(id)
  }

  const clickEditSingleItemPurchaseReturn=(index)=>()=>{
    // console.log(index);
    setOpenEditContainer(true)
    if(singleViewData?.length!==0 && singleViewData!==undefined){
      // setPurchaseReturnEditForm({...purchaseReturnEditForm,
      //                               invoice:singleViewData[0]?.invoiceNo,
      //                               returnDate:singleViewData[0]?.returnDate,
      //                               returnInfo:singleViewData[0]?.returnInfo[index]
      //                             })      
      
      setPurchaseReturnEditForm(prevState => ({
        ...prevState,
        invoice: singleViewData[0]?.invoiceNo,
        returnDate: singleViewData[0]?.returnDate,
        returnInfo:returnInfoArray[index]
        }));
    }

  }
  return (
    <div className="global-page-parent-container">
      <p className="breadcrumb">Purchase Manage &gt; Purchase Return</p>
      <div className="purchase-return-container">
        <h3>Purchase Return</h3>
        <hr className="global-hr" />
        <div className="purchase-return-top-container">
          {
            userRole ==="admin"&&
          <div className="global-single-input auto-complete">
            <p>Branch</p>
            <Autocomplete
             sx={{width:"100%"}}
             options={allBranchesList|| [""]}
             getOptionLabel={(option) => `${option?.storeCode}-${option?.branchName}`}

             renderInput={(params) => <TextField {...params} 
             placeholder="Select Branch"
             />}
            
             onChange={(e, newValue) =>
              setBranchName(newValue?.storeCode)
             }
            />
          </div>
          }
        
          <div className="global-single-input auto-complete">
            <p>Supplier Name</p>
            <Autocomplete
             sx={{width:"100%"}}
             options={allSuppliersList|| [""]}
             getOptionLabel={(option) => option?.supplierName}

             renderInput={(params) => <TextField {...params} 
             placeholder="Select Supplier"
             />}
             
             onChange={(e, newValue) =>
              setSupplier(newValue?._id)
             }
            />
          </div>
          <div className="global-single-input">
            <p>From</p>
             <input type="date" onChange={getPurchaseReturnForm("fromDate")}/>
          </div>
          <div className="global-single-input">
            <p>To</p>
             <input type="date" onChange={getPurchaseReturnForm("toDate")}/>
          </div>

          <button onClick={submitBtnFn} className="btn btn-primary purchase-return-btn">
            Search
          </button>
        </div>
      </div>
      <div className="purchase-return-container">
        <div className="purchase-return-top-container">
          <h3>Return Invoice</h3>
          <div className="return-invoice-top-container">
            <button
              onClick={() => setAddReturnDialog(true)}
              className="btn btn-primary add-return-purchase"
            >
              Add Return
            </button>
            <Datepicker type="date" backgroundColor="#f1f2ff" value={today}/>
          </div>
        </div>
        <div className="global-table-container purchase-return-table">
          <table className="global-table">
            <thead>
              <tr>
                <th>No</th>
                {
                  userRole === 'admin' &&
                  <>
                  <th>Branch Code</th>
                  <th>Branch Name</th>
                  </>
                
                }
               
                <th>Transaction ID</th>
                <th>Supplier Name</th>
                <th>Invoice No</th>
                <th>Date</th>
                <th>Price</th>
                <th>View</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            {
              isLoading ?(
                <tbody>
                    <tr>
                      <td colSpan={11}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={30}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={11}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={30}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={11}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={30}
                        />
                      </td>
                    </tr>
                </tbody>

              ):(
                <tbody>
                {
                  purchaseReturnList!==undefined ? purchaseReturnList?.slice(0)?.reverse()?.map((r,i)=>(
                 <tr>
                  <td>{i+1}</td>
                  {
                    userRole ==="admin"&&
                    <>
                     <td>{r?.branchId}</td>
                     <td>{r?.locationName}</td>
                    </>
                  }
                 
                  <td>{r?.transNo}</td>
                  <td>{r?.supplierName}</td>
                  <td>{r?.invoiceNo}</td>
                  <td>{convertDateFormat(r?.returnDate)}</td>
                  <td>{r?.Price}</td>
                  <td>
                    <IconButton onClick={()=>OpenPurchaseReturnDialog(r?._id)}>
                      <i class="bi bi-eye visibility-icon"></i>
                    </IconButton>
                  </td>
                  <td>
                    <IconButton onClick={clickEditPurchaseReturn(r?._id)}>
                      <i class="bi bi-pencil-square edit-icon"></i>
                    </IconButton>
                  </td>
                  <td>
                    <IconButton onClick={clickDeletePurchaseReturn(r?._id)}>
                      <i class="bi bi-trash3 delete-icon"></i>
                    </IconButton>
                  </td>
                </tr>
                  ))
                  :
                  <tr>
                    <td  colSpan={11}>No Data</td>
                  </tr>
                }
              </tbody>
              )
            }
           
          </table>
        </div>
      </div>

      {/* add return dialog */}
      <Dialog
        open={addReturnDialog}
        maxWidth="lg"
        onKeyDown={(e)=>e.key === "Escape" && setAddReturnDialog(false)}
      >
        <div className="purchase-add-return-dialog-container">
          <h3>Purchase Return</h3>
          <hr className="global-hr" />
          <div className="purchase-return-dialog-top-container">
            <div className="global-single-input">
              <p>Transaction ID</p>
              <input value={`${purchaseReturnTransId?.prefix}${purchaseReturnTransId?.transNo}`}/>
            </div>
            {
              userRole ==='admin' &&
              <div className="global-single-input auto-complete" style={{position:"relative"}}>
              <p>Location</p>
              <Autocomplete
                 sx={{width:"100%"}}
                 options={allBranchesList || [""]}
                 getOptionLabel={(option)=>`${option?.storeCode}-${option?.branchName}`}
                 renderInput={(params) => <TextField {...params} 
                 placeholder="Select Branch"
                
                 />}
                //  value={branch}
                 onChange={(e,newValue)=>{
                      setBranch(newValue?.storeCode)
                      setBranchAlert(false)
                      if(newValue===null){
                        setBranchAlert(true)
                      }
                    }}
              />{branchAlert?
                <p className="doc-validation-alert" style={{top:"10%"}}>Select a branch</p>
                :""}
            </div>
            }
           
          
            <div className="global-single-input auto-complete" style={{position:"relative"}}>
              <p>Invoice</p>
              <Autocomplete
              disabled={!(addPurchaseReturnTableForBackEnd?.length===0)}
              sx={{width:"100%"}}
              options={invoiceNoList?.slice(0)?.reverse() || [""]}
              getOptionLabel={(option)=>option?.purchaseId}
              renderInput={(params) => <TextField {...params} 
              placeholder="Select Invoice Number"
             
              />}
              value={invoiceNumber}
              onChange={(e,newValue)=>{
                setInvoiceNumber(newValue)
                setInvoiceAlert(false)
                if(newValue===null){
                  setInvoiceAlert(true)
                }
                setProductCode(null)
                setPurchaseReturnForm({
                  ...purchaseReturnForm,
                  returnQty:'',
                  rate:'',
                  amount:'',
                  reason:''
                })
              }}
           />
              <p className="return-dialog-text">Total Amount:{invoiceNumber !== null ?purchaseReturnForm?.totalAmount:0}</p>
                {invoiceAlert?
                <p className="doc-validation-alert" style={{top:"10%"}}>Select a Invoice No</p>
                :""}
            </div>
            <div className="global-single-input auto-complete">
              <p>Supplier Name</p>
              <input type="text" value={invoiceNumber !== null ?purchaseReturnForm?.supplireName:''} />
            </div>
          </div>
          <div className="purchase-return-dialog-middle-container" style={{position:"relative"}}>
            {addProductAlert?
             <p className="purchase-return-add-product-validation">
             add product details !!</p>
            :""}
            <div className="global-single-input purchase-return-inv-id">
              <p className="disabled-p">Invoice ID</p>
              <input disabled value={invoiceNumber !== null ? purchaseReturnForm?.invoice:''}/>
            </div>
            <div className="global-single-input purchase-return-date">
              <p className="disabled-p">Return Date</p>
              <input disabled type="date" value={purchaseReturnForm?.returnDate}/>
            </div>
            <div className="global-single-input purchase-return-prod-code auto-complete" style={{position:"relative"}}>
              <p>Product Code</p>
              <Autocomplete
              sx={{width:"100%"}}
              options={purchaseAndPurchaseWOPOById?.purchaseInfo || [""]}
              getOptionLabel={(option)=>option?.code}
              renderInput={(params) => <TextField {...params} 
              placeholder="Select Invoice Number"             
              />}
              value={productCode}
              onChange={(e,newValue)=>{
                setProductCode(newValue)
                setProductCodeAlert(false)
                if(newValue===null){
                  setProductCodeAlert(true)
                }
              }}
           />{productCodeAlert?
            <p className="doc-validation-alert">Select</p>
            :""}
            </div>
            <div className="global-single-input purchase-return-prod-name">
              <p>Product Name</p>
               <input type="text"  value={filteredTableData !== undefined  && productCode !== null? filteredTableData[0]?.name:''}/>
             
            </div>
            <div className="global-single-input purchase-return-unit">
              <p className="disabled-p">Unit</p>
              <input disabled value={filteredTableData !== undefined && productCode !== null? filteredTableData[0]?.unit:''}/>
            </div>
            <div className="global-single-input purchase-return-unit">
              <p className="disabled-p">Qty</p>
              <input disabled value={filteredTableData !== undefined && productCode !== null? filteredTableData[0]?.qty:''}/>
            </div>
            <div className="global-single-input purchase-return-unit">
              <p className="disabled-p">Rate</p>
              <input disabled value={filteredTableData !== undefined && productCode !== null? filteredTableData[0]?.rate:''}/>
            </div>
            <div className="global-single-input purchase-return-unit">
              <p className="disabled-p">Amount</p>
              <input disabled value={filteredTableData !== undefined && productCode !== null? filteredTableData[0]?.amount:''}/>
            </div>
            <div className="global-single-input purchase-return-inv-id"style={{position:"relative"}}>
              <p>Return Qty</p>
              <input 
              value={purchaseReturnForm?.returnQty}
              onChange={getPurchaseReturnForm("returnQty")}
              />
              {returnQtyAlert?
                <p className="doc-validation-alert" style={{top:"85%"}}>fill this field!!</p>
              :""}
            </div>
            <div className="global-single-input purchase-return-date"style={{position:"relative"}}>
              <p>Return Rate</p>
              <input 
              value={purchaseReturnForm?.rate}
              onChange={getPurchaseReturnForm("rate")}
              />
              {returnRateAlert?
                <p className="doc-validation-alert" style={{top:"85%"}}>fill this field!!</p>
              :""}
            </div>
            <div className="global-single-input">
              <p>Amount</p>
              <input value={purchaseReturnForm?.amount}/>
            </div>
            <div className="global-single-input">
              <p>Reason</p>
              <textarea 
              value={purchaseReturnForm?.reason} 
              onChange={getPurchaseReturnForm("reason")}
              />
            </div>
            <div className="purchase-return-add-button">
              <button onClick={purchaseReturnAddBtnFn}>+</button>
            </div>
          </div>

          <div className="global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Product Code</th>
                  <th>Product Name</th>
                  <th>Qty</th>
                  <th>Rate</th>
                  <th>Amount</th>
                  <th>Return Qty</th>
                  <th>Rate</th>
                  <th>Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                 addPurchaseReturnTable?.length !==0 ? addPurchaseReturnTable?.map((r,i)=>(
                    <tr>
                    <td>{i+1}</td>
                    <td>{r?.returnDate}</td>
                    <td>{r?.productName}</td>
                    <td>{r?.qty}</td>
                    <td>{r?.rate}</td>
                    <td>{r?.amount}</td>
                    <td>{r?.returnQty}</td>
                    <td>{r?.returnRate}</td>
                    <td>{r?.returnAmount}</td>
                    <td>
                      {/* <IconButton>
                        <i class="bi bi-pencil-square edit-icon"></i>
                      </IconButton> */}
                      <IconButton 
                      onClick={()=>deleteAddReturnTableData(i)}
                      >
                        <i class="bi bi-trash3 delete-icon"></i>
                      </IconButton>
                    </td>
                  </tr>
                  ))
                  :
                  <tr>
                    <td colSpan={10}>No Data</td>
                  </tr>
                }
               
              </tbody>
            </table>
          </div>
          <div className="purchase-return-dialog-button-container">
            <button onClick={cancelButtonFunction}  className="btn btn-secondary-outlined">Cancel</button>
            <button onClick={addPurchaseReturnSubmitBtnFn} className="btn btn-primary">Submit</button>
          </div>
        </div>
      </Dialog>
      {/* add return dialog */}
      <Dialog
        open={editReturnDialog}
        maxWidth="lg"
        onKeyDown={(e)=>e.key === "Escape" && setEditReturnDialog(false)}
      >
        <div className="purchase-add-return-dialog-container">
          <h3>Edit Details</h3>
          <hr className="global-hr" />
          <div className="purchase-return-dialog-top-container">
            <div className="global-single-input">
              <p>Transaction ID</p>
              <input type="text" disabled value={singleViewData !== undefined && singleViewData[0]?.transNo}/>
            </div>

            <div className="global-single-input auto-complete">
              <p>Supplier Name</p>
              <input type="text" disabled value={singleViewData !== undefined && singleViewData[0]?.supplierName}/>
            </div>

            <div className="global-single-input auto-complete" style={{position:"relative"}}>
              <p>Invoice</p>
              <input type="text" disabled value={singleViewData !== undefined && singleViewData[0]?.invoiceNo}/>
            </div>
            
          </div>
          {
          openEditContainer &&
          <div className="purchase-return-dialog-middle-container" style={{position:"relative"}}>            
            <div className="global-single-input" style={{width:"18%"}}>
              <p className="disabled-p">Invoice ID</p>
              <input disabled value={purchaseReturnEditForm?.invoice}/>
            </div>
            <div className="global-single-input purchase-return-date" style={{width:"18%"}}>
              <p className="disabled-p">Return Date</p>
              <input disabled type="text" value={convertDateFormat(purchaseReturnEditForm?.returnDate)}/>
            </div>
            <div className="global-single-input purchase-return-prod-code disabled-p" style={{position:"relative"}}>
              <p>Product Code</p>
              <input type="text" disabled value={purchaseReturnEditForm?.returnInfo!=='' && purchaseReturnEditForm?.returnInfo?.itemCode}/>
            </div>
            <div className="global-single-input disabled-p" style={{width:"18%"}}>
              <p>Product Name</p>
               <input type="text" disabled value={purchaseReturnEditForm?.returnInfo!=='' && purchaseReturnEditForm?.returnInfo?.itemName}/>
             
            </div>
            {/* <div className="global-single-input purchase-return-unit">
              <p className="disabled-p">Unit</p>
              <input disabled value={purchaseReturnEditForm?.returnInfo!=='' && purchaseReturnEditForm?.returnInfo?.itemName}/>
            </div> */}
            <div className="global-single-input purchase-return-unit">
              <p className="disabled-p">Qty</p>
              <input disabled value={purchaseReturnEditForm?.returnInfo!=='' && purchaseReturnEditForm?.returnInfo?.originalQty}/>
            </div>
            <div className="global-single-input purchase-return-unit">
              <p className="disabled-p">Rate</p>
              <input disabled value={purchaseReturnEditForm?.returnInfo!=='' && purchaseReturnEditForm?.returnInfo?.originalRate}/>
            </div>
            <div className="global-single-input purchase-return-unit">
              <p className="disabled-p">Amount</p>
              <input disabled value={purchaseReturnEditForm?.returnInfo!=='' && purchaseReturnEditForm?.returnInfo?.originalAmt}/>
            </div>
            <div className="global-single-input purchase-return-inv-id"style={{position:"relative"}}>
              <p>Return Qty</p>
              <input 
              value={purchaseReturnEditForm?.returnInfo!=='' && purchaseReturnEditForm?.returnInfo?.returnQty}
              onChange={getPurchaseReturnForm("returnQty")}
              />
              {returnQtyAlert?
                <p className="doc-validation-alert" style={{top:"85%"}}>fill this field!!</p>
              :""}
            </div>
            <div className="global-single-input purchase-return-date"style={{position:"relative"}}>
              <p>Return Rate</p>
              <input 
              value={purchaseReturnEditForm?.returnInfo!=='' && purchaseReturnEditForm?.returnInfo?.rate}
              onChange={getPurchaseReturnForm("rate")}
              />
              {returnRateAlert?
                <p className="doc-validation-alert" style={{top:"85%"}}>fill this field!!</p>
              :""}
            </div>
            <div className="global-single-input">
              <p>Amount</p>
              <input value={purchaseReturnEditForm?.returnInfo!=='' && purchaseReturnEditForm?.returnInfo?.amount}/>
            </div>
            <div className="global-single-input">
              <p>Reason</p>
              <textarea 
              value={purchaseReturnEditForm?.returnInfo!=='' && purchaseReturnEditForm?.returnInfo?.reason} 
              onChange={getPurchaseReturnForm("reason")}
              />
            </div>
            <div className="purchase-return-add-button" style={{width:"20%"}}>
              <button onClick={purchaseReturnAddBtnFn} style={{fontSize:"0.9rem"}}>edit</button>
            </div>
          </div> 
        }

          <div className="global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Return Date</th>
                  <th>Product Name</th>
                  <th>Qty</th>
                  <th>Rate</th>
                  <th>Amount</th>
                  <th>Return Qty</th>
                  <th>Rate</th>
                  <th>Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                 singleViewData !== undefined && singleViewData[0]?.returnInfo?.map((r,i)=>(
                    <tr>
                    <td>{i+1}</td>
                    <td>{singleViewData[0]?.returnDate}</td>
                    <td>{r?.itemName}</td>
                    <td>{r?.originalQty}</td>
                    <td>{r?.originalRate}</td>
                    <td>{r?.originalAmt}</td>
                    <td>{r?.returnQty}</td>
                    <td>{r?.rate}</td>
                    <td>{r?.amount}</td>
                    <td>
                      <IconButton 
                      // disabled
                      onClick={clickEditSingleItemPurchaseReturn(i)}
                      >
                        <i class="bi bi-pencil-square edit-icon"></i>
                      </IconButton>
                    </td>
                  </tr>
                  ))
                }
               
              </tbody>
            </table>
          </div>
          <div className="purchase-return-dialog-button-container">
            <button onClick={()=>{setEditReturnDialog(false)
                                  setOpenEditContainer(false)}}  
                    className="btn btn-secondary-outlined">
                      Cancel</button>
            <button className="btn btn-primary">Return</button>
          </div>
        </div>
      </Dialog>

      {/* return single view */}
      <Dialog open={purchseReturnSingleView} maxWidth="lg"  onKeyDown={(e)=>e.key === "Escape" && setPurchseReturnSingleView(false)}>
      <div className="po-single-view-parent-container">
        <div className="top-bar">
          <p>Purchase Return View</p>
        </div>
        <hr />
        <div className="form-1-container">
          <div className="global-single-input po-input">
              <p>Transaction ID</p>
              <input type="text" disabled value={singleViewData !== undefined && singleViewData[0]?.transNo}/>
            </div>
            <div className="global-single-input po-input">
              <p>Invoice</p>
              <input type="text" disabled value={singleViewData !== undefined && singleViewData[0]?.invoiceNo}/>
            </div>
            <div className="global-single-input po-input">
              <p>Supplier</p>
              <input type="text" disabled value={singleViewData !== undefined && singleViewData[0]?.supplierName}/>
            </div>
            <div className="global-single-input po-input">
              <p>Return Date</p>
              <input type="text" disabled value={singleViewData !== undefined && singleViewData[0]?.returnDate}/>
            </div>
        </div>
        <div className="po-single-view-table-container global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Product Code</th>
                  <th>Product Name</th>
                  <th>Dimension</th>
                  <th>Return Qty</th>
                  <th>Rate</th>
                  <th>Amount</th>
                  <th>Reason</th>
                 
                </tr>
              </thead>
              <tbody>
               {
                singleViewData !== undefined && singleViewData[0]?.returnInfo?.map((r,i)=>(
                  <tr>
                    <td>{i+1}</td>
                    <td>{r?.itemCode}</td>
                    <td>{r?.itemName}</td>
                    <td>{r?.dimension}</td>
                    <td>{r?.returnQty}</td>
                    <td>{r?.rate}</td>
                    <td>{r?.amount}</td>
                    <td>{r?.reason}</td>
                  </tr>
                ))
               }
               
              </tbody>
            </table>
          </div>
          <div className="cancel-btn-container">
            <button onClick={()=>setPurchseReturnSingleView(false)} className="btn btn-secondary-outlined">Close</button>
          </div>
      </div>

      </Dialog>

      <SuccessSnackbar
        open={openSuccessSnack}
        handleClose={closeSuccessSnack}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={openErrorSnack}
        handleClose={closeErrorSnack}
        message={snackMsg}
      />
    </div>
  );
};
