import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { IconButton, Skeleton } from "@mui/material";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import InputBox from "../../../../../components/InputBox";

const renderData = [
  {
    date: "12/06/23",
    refNo: "0004",
    partyName: "Malabar",
    pendingAmount: "1000",
    dueOn: "200",
    overDueByDays: "3000",
  },
  {
    date: "12/06/23",
    refNo: "0004",
    partyName: "Malabar",
    pendingAmount: "1000",
    dueOn: "200",
    overDueByDays: "3000",
  },
  {
    date: "12/06/23",
    refNo: "0004",
    partyName: "Malabar",
    pendingAmount: "1000",
    dueOn: "200",
    overDueByDays: "3000",
  },
];
const BillReceivableStatement = ({ selectListItem }) => {
  const componentRef = useRef();
  const isLoading = false;
  return (
    <div className="global-wrapper" id="currentAsset-report">
      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="accounting-voucher-menu-sections"
      >
        <h3 style={{ margin: "2% 1%" }}>{selectListItem}</h3>
        <div className="input-current-asset-reports">
          <InputBox label={"Ledger"} />
          <InputBox label={"From"} type={"date"} />
          <InputBox label={"To"} type={"date"} />
        </div>
      </div>
      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="accounting-voucher-menu-sections"
      >
        <div className="action-btn-container">
          <div>
            <ReactToPrint
              trigger={() => (
                <IconButton sx={{ color: "blue" }}>
                  <i className="bi bi-printer "></i>
                </IconButton>
              )}
              content={() => componentRef.current}
            />
            <IconButton className="download-btn">
              <ArrowCircleDownIcon sx={{ fontSize: 30 }} />
            </IconButton>
          </div>
        </div>
        <div className="table-container">
          <div className="global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Ref no</th>
                  <th>Party Name</th>
                  <th>Pending Amount</th>
                  <th>Due On</th>
                  <th>Overdue by Days</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  [...Array(3)].map((_, index) => (
                    <tr key={index}>
                      <td colSpan={7}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                  ))
                ) : renderData.length !== 0 ? (
                  renderData?.reverse()?.map((r, i) => (
                    <React.Fragment key={i}>
                      <tr className="data-table-row-head">
                        <td>{r?.date}</td>
                        <td>{r?.refNo}</td>
                        <td>{r?.partyName}</td>
                        <td>{r?.pendingAmount}</td>
                        <td>{r?.dueOn}</td>
                        <td>{r?.overDueByDays}</td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>No Records</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* total  */}
      <div className="total-container">
        <div style={{ width: "64%" }}>
          <h3 className="total-head">Total</h3>
          <InputBox inputBoxStyle={{ width: "100%", fontWeight: "600" }} />
        </div>
      </div>
      {/* print out  */}

      <div style={{ display: "none" }}>
        <div
          id="currentAsset-report"
          ref={componentRef}
          className="print-preview-container"
        >
          <div className="print-head-container">
            <div className="brand-name">
              <h2>Leeyet Textile</h2>
            </div>
            <div className="select-table-head">
              <h3>{selectListItem}</h3>
            </div>
            <div className="duration-date">
              <h3>{"01/04/2023"}</h3>
              <strong>TO</strong>
              <h3>{"01/12/2023"}</h3>
            </div>
          </div>

          <div className="global-table-wrapper">
            <div className="table-container">
              <div className="global-table-container">
                <table className="global-table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Ref no</th>
                      <th>Party Name</th>
                      <th>Pending Amount</th>
                      <th>Due On</th>
                      <th>Overdue by Days</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      [...Array(3)].map((_, index) => (
                        <tr key={index}>
                          <td colSpan={7}>
                            <Skeleton
                              variant="rectangular"
                              animation="wave"
                              width={"100%"}
                              height={40}
                            />
                          </td>
                        </tr>
                      ))
                    ) : renderData.length !== 0 ? (
                      renderData?.reverse()?.map((r, i) => (
                        <React.Fragment key={i}>
                          <tr className="data-table-row-head">
                            <td>{r?.date}</td>
                            <td>{r?.refNo}</td>
                            <td>{r?.partyName}</td>
                            <td>{r?.pendingAmount}</td>
                            <td>{r?.dueOn}</td>
                            <td>{r?.overDueByDays}</td>
                          </tr>
                        </React.Fragment>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={7}>No Records</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* total  */}
            <div className="total-container">
              <div style={{ width: "64%" }}>
                <h3 className="total-head">Total</h3>
                <InputBox
                  inputBoxStyle={{ width: "100%", fontWeight: "600" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillReceivableStatement;
