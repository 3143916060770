import {createSlice} from '@reduxjs/toolkit'
export const allAllowedBranchesSlice = createSlice({
    name: 'allAllowedBranchesSlice',
    initialState: {
        value: undefined
    },
    reducers: {
        get_all_allowed_branches: (state, action) => {
            state.value = action.payload.allowedBranches
        }
    }
})

export const {get_all_allowed_branches} = allAllowedBranchesSlice.actions
export default allAllowedBranchesSlice.reducer

