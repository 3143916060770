import React, { useState } from "react";
import InputBox from "../../../components/InputBox";
import DropdownInputBox from "../../../components/DropdownInputBox";
import { Dialog } from "@mui/material";

const PayrollVoucher = () => {
  const [paymentDetails, setPaymentDetails] = useState(false);

  const handlePaymentClick = () => {
    setPaymentDetails(true);
  };

  const handleCloseDialog = () => {
    setPaymentDetails(false);
  };

  return (
    <>
      <div className="global-white-bg-container">
        <div className="employee-group-creation">
          <div>
            <strong>Payroll Invoice</strong>
          </div>
          <div className="payroll-voucher-container">
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "1em 5%",
                margin: "5% 0%",
              }}
              className="item-invoice-input-container"
            >
              <InputBox label={"Invoice No"} />
              <InputBox label={"Date"} type={"date"} />
              <InputBox label={"Employee ID"} />
              <DropdownInputBox labelName={"Employee"} />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
              className="button-container"
            >
              <div></div>
              <div style={{ display: "flex", gap: "10px" }}>
                <button
                  style={{
                    background: "#fff",
                    border: "1px solid black",
                    color: "black",
                  }}
                  className="cancel-btn"
                >
                  clear
                </button>
                <button
                  style={{
                    background: "#0011f8",
                    border: "none",
                    color: "white",
                  }}
                  className="submit-btn"
                >
                  Submit
                </button>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>

      <div className="payroll-salary-information">
        <div className="global-white-bg-container">
          <div className="headings">
            <h3>Salary Information</h3>
            <h4 style={{ color: "green" }}>Earnings</h4>
            <div className="salary-input-box">
              <InputBox label={"Basic Salary"} />
              <InputBox label={"HRA Allowance"} />
              <InputBox label={"DA Allowance"} />
              <InputBox label={"Bonus"} />
              <InputBox label={"Travel Allowance"} />
              <InputBox label={"Over Time(H)"} />
            </div>
            <hr style={{ margin: "2em 0" }} className="global-hr" />
            <div>
              <DropdownInputBox labelName={"Other Allowance"} />
              <div className="education-allowance">
                <h3>Education Allowance</h3>
                <InputBox />
              </div>
            </div>
          </div>
          <div className="total-earnings">
            <h3>Total Earning</h3>
            <InputBox inputBoxStyle={{ background: "#fff" }} />
          </div>
        </div>
        <div className="global-white-bg-container">
          <div className="headings">
            <h3>Salary Information</h3>
            <h4 style={{ color: "red" }}>Deduction</h4>
            <div className="salary-input-box">
              <div style={{ display: "flex", gap: "1em" }}>
                <InputBox label={"Loan"} />
                <InputBox label={"EMI (Monthly)"} />
              </div>

              <InputBox label={"EPF"} />
              <InputBox label={"ESI"} />
              <InputBox label={"TDS"} />
              <InputBox label={"Leave"} />
            </div>
            <hr style={{ margin: "2em 0" }} className="global-hr" />
            <div>
              <DropdownInputBox labelName={"Other Allowance"} />
              <div className="education-allowance">
                <h3>Medi Claim</h3>
                <InputBox />
              </div>
            </div>
          </div>
          <div className="total-earnings">
            <h3>Total Deduction</h3>
            <InputBox inputBoxStyle={{ background: "#fff" }} />
          </div>
        </div>
      </div>

      <div className="total-payable-salary">
        <div className="global-white-bg-container">
          <div className="total-payable-salary-input-container">
            <div className="input-row">
              <h3>Total Payable Salary</h3>
              <InputBox />
            </div>
            <div className="input-row">
              <h5>Paid Amount</h5>
              <InputBox />
            </div>
            <div className="input-row">
              <h5>Balance Amount</h5>
              <InputBox />
            </div>
          </div>
        </div>
      </div>

      <div className="payroll-voucher-button">
        <div className="button-container">
          <button>Clear</button>
          <div>
            <button onClick={handlePaymentClick}>Payment</button>
            <button>Create Voucher</button>
          </div>
        </div>
      </div>


      <Dialog
        open={paymentDetails}
        maxWidth="lg"
        onClose={handleCloseDialog}
        onKeyDown={(e) => e.key === "Escape" && setPaymentDetails(false)}
      >
        <div className="payment-details-container">
          <h3>Payment</h3>

          <div className="payment-details-input-container">
            <InputBox label={"Account"} />
            <DropdownInputBox labelName={'Payment Method'} />
            <DropdownInputBox labelName={'Bank Name'} />
           
            <InputBox label={'Account No'} />
            <InputBox label={'IFSC'} />
          </div>
        </div>
        <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
              className="button-container"
            >
              <div></div>
              <div style={{ display: "flex", gap: "10px" }}>
                <button
                  style={{
                    background: "#fff",
                    border: "1px solid black",
                    color: "black",
                  }}
                  className="cancel-btn"
                  onClick={handleCloseDialog}
                >
                  Cancel
                </button>
                <button
                  style={{
                    background: "#0011f8",
                    border: "none",
                    color: "white",
                  }}
                  className="submit-btn"
                  
                >
                  Payment
                </button>
              </div>
              <div></div>
            </div>
      </Dialog>
   
    </>
  );
};

export default PayrollVoucher;
