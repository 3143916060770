import axios from "axios"
import { get_invoice_number } from "../../Redux/Prchase Manager/GRN/invoiceNumberSlice"
import store from "../../Redux/store"
import {HEADERS, HEADER_QUERY} from '../../API/UrlAndPaths'
import { get_viewPurchase_byId } from "../../Redux/Prchase Manager/GRN/viewPurchaseByIdSlice"
import { get_grn_id } from "../../Redux/Prchase Manager/GRN/generateGrnIdSlice"
import { get_grn_posted_list } from "../../Redux/Prchase Manager/GRN/viewPostedListSlice"
import { get_grn_drafted_list } from "../../Redux/Prchase Manager/GRN/viewDraftedSlice"
import { get_all_grn_no } from "../../Redux/Prchase Manager/GRN/getAllGrnNoSlice"
import { create_grn_transaction_id } from "../../Redux/Prchase Manager/GRN/generateGrnTransactionIdSlice"


// invoice number list api call
export const viewInvoiceNumberListAPICall= async (body)=>{
  await axios.post("purchase/viewInvoiceNumbers",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
        store.dispatch(get_invoice_number({invoiceNumberList:res.data}))
    }
  })
  .catch((err)=>{
    store.dispatch(get_invoice_number({invoiceNumberList:undefined}))
    console.log(err.response);
  })
}

/// view purchase by id
export const viewPurchaseByIdAPICall= async (body)=>{
    await axios.post("purchase/viewpurchaseinfobyid",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_viewPurchase_byId({viewPurchaseByIdList:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(get_viewPurchase_byId({viewPurchaseByIdList:undefined}))
        console.log(err.response);
    })
}

// generate grn id api call
export const generateGrnIdAPICall= async ()=>{
  await axios.get("purchase/generateGrnId",HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_grn_id({grnId:res.data}))
    }
})
.catch((err)=>{
  console.log(err.response);
  store.dispatch(get_grn_id({grnId:undefined}))
})
  
}

// post grn api call
export const postGrnAPICall= async (
  body,
  setSuccessSnackOpen,
  setErrorSnackOpen,
  setSnackMsg,
  updateListener,
  cancelBtnFunction,
  localStorageClearFn,
  closeVoucher
  )=>{
  await axios.post("purchase/addgrn",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      setSnackMsg('GRN Posted')
      setSuccessSnackOpen(true)
      cancelBtnFunction()
      updateListener()
      localStorageClearFn()
      closeVoucher()

    }
  })
  .catch((err)=>{
    // if (err.response === undefined) {
    //   setSnackMsg("Network error!!");
    // }
    if (err.response.status === 500) {
      setSnackMsg("Server error!!");
    }
    if (err.response.status === 401) {
      setSnackMsg("Unauthorized!!");
    }
    if (err.response.status === 403) {
      setSnackMsg("Forbidden!!");
    }
    setErrorSnackOpen(true);
  })
}

/// draft grn api call
export const graftGrnAPICall= async (
  body,
  setSuccessSnackOpen,
  setErrorSnackOpen,
  setSnackMsg,
  updateListener,
  cancelBtnFunction
  )=>{
  await axios.post("purchase/draftgrn",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      setSuccessSnackOpen(true)
      setSnackMsg('GRN Drafted')
      cancelBtnFunction()
      updateListener()

    }
  })
  .catch((err)=>{
    if (err.response === undefined) {
      setSnackMsg("Network error!!");
    }
    if (err.response.status === 500) {
      setSnackMsg("Server error!!");
    }
    if (err.response.status === 401) {
      setSnackMsg("Unauthorized!!");
    }
    if (err.response.status === 403) {
      setSnackMsg("Forbidden!!");
    }
    setErrorSnackOpen(true);
    console.log(err.response);
  })
}


/// get grn posted list api call
export const viewGrnPostedListAPICall= async (setIsLoading,setErrorSnack,setSnackMsg,body)=>{
 if(setIsLoading!==undefined) setIsLoading(true)
 await axios.post("purchase/viewgrns",body,HEADERS)
 .then((res)=>{
   if(res.status===200){
     store.dispatch(get_grn_posted_list({grnPostedList:res.data}))
     if(setIsLoading!==undefined) setIsLoading(false)
    }
  })
  .catch((err)=>{
    store.dispatch(get_grn_posted_list({grnPostedList:undefined}))
    console.log(err.response);
    switch (err.response.status) {
      case 404:
          setSnackMsg('No data available..')
        break;
      case 500:
          setSnackMsg('Server error!!')
        break;
      case 401:
          setSnackMsg('Unauthorized!!')
        break;
      case 403:
          setSnackMsg('Forbidden!!')
        break;
    
      default:
          setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
        break;
    }
    setErrorSnack(true)
    if(setIsLoading!==undefined) setIsLoading(false)
  })
}

// get grn drafted list api call
export const viewGrnDraftedListAPICall= async (setIsLoading,setErrorSnack,setSnackMsg,body)=>{
  if(setIsLoading!==undefined) setIsLoading(true)
  await axios.post("purchase/viewdraftedgrn",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_grn_drafted_list({grnDraftedList:res.data}))
      if(setIsLoading!==undefined) setIsLoading(false)
    }
  })
  .catch((err)=>{
    store.dispatch(get_grn_drafted_list({grnDraftedList:undefined}))
    console.log(err.response);
    switch (err.response.status) {
      case 404:
          setSnackMsg('No data available..')
        break;
      case 500:
          setSnackMsg('Server error!!')
        break;
      case 401:
          setSnackMsg('Unauthorized!!')
        break;
      case 403:
          setSnackMsg('Forbidden!!')
        break;
    
      default:
          setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
        break;
    }
    setErrorSnack(true)
    if(setIsLoading!==undefined) setIsLoading(false)
  })
}

// get all grn no list
export const getAllGrnNoListAPICall = async (body)=>{
  await axios.post('purchase/getGrnNos',body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_all_grn_no({allGrnNoList:res.data}))
    }
  })
  .catch((err)=>{
    store.dispatch(get_all_grn_no({allGrnNoList:undefined}))
    console.log(err.response);
  })
}

//delete drafted grn
export const deleteDraftedGrnAPICall = async (
  _id,
  updateListener,
  setSuccessSnackOpen,
  setErrorSnackOpen,
  setSnackMsg
  )=>{
  await axios.delete("purchase/deleteGrnDraft",{
    headers:HEADER_QUERY,
    data:{
      _id
    }
  })
  .then((res)=>{
    if(res.status===200){
      // console.log('deleted');
      updateListener()
      setSuccessSnackOpen(true)
      setSnackMsg('Deleted')
    }
  })
  .catch((err)=>{
    console.log(err.response);
    setErrorSnackOpen(true)
    if (err.response === undefined) {
      setSnackMsg("Network Connection error!!");
    }
    if (err.response.status === 409) {
      setSnackMsg("Category Already Exist");
    }
  
    if (err.response.status === 401) {
      setSnackMsg("Unauthorized");
    }
    if (err.response.status === 403) {
      setSnackMsg("Forbidden");
    }
    if(err.response.status === 422){
      setSnackMsg(err.response.data?.msg)
    }
  })
}

// edit drafted grn
export const editDraftedGrnAPICall = async (
  body,
  setSuccessSnackOpen,
  setErrorSnackOpen,
  setSnackMsg,
  updateListener,
  cancelBtnFunction,
  localStorageClearFn
  
  )=>{
 await axios.put("purchase/editGrnDraft",body,HEADERS)
 .then((res)=>{
    if(res.status===200){
      // console.log('updated');
      setSuccessSnackOpen(true)
      setSnackMsg('Updated')
      updateListener()
      localStorageClearFn()
      cancelBtnFunction()
    }
 })
 .catch((err)=>{
  console.log(err.response);
  setErrorSnackOpen(true)
  if (err.response === undefined) {
    setSnackMsg("Network Connection error!!");
  }
  if (err.response.status === 409) {
    setSnackMsg("Category Already Exist");
  }

  if (err.response.status === 401) {
    setSnackMsg("Unauthorized");
  }
  if (err.response.status === 403) {
    setSnackMsg("Forbidden");
  }
  if(err.response.status === 422){
    setSnackMsg(err.response.data?.msg)
  }
 })
}

// generate grn transaction id voucher

export const generateGrnTransactionIdAPICall= async(body)=>{
  await axios.post("purchase/generateTrasactionId",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(create_grn_transaction_id({
        grnTransactionId:res.data
      })
      )
    }
    
  }).catch((err)=>{
    console.log(err.response);
    store.dispatch(create_grn_transaction_id({grnTransactionId:undefined}))
  })
}