import {createSlice} from '@reduxjs/toolkit'

export const dailyReportSlice=createSlice({
    name:"dailyReportSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        get_daily_report:(state,action)=>{
           state.value=action.payload. dailyReportData
        }
    }
})
export const {get_daily_report} =dailyReportSlice.actions
export default dailyReportSlice.reducer
