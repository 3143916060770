import React, { useRef, useState } from "react";
import InputBox from "../../../components/InputBox";
import ReactToPrint from "react-to-print";
import { IconButton, Skeleton } from "@mui/material";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import DropdownInputBox from "../../../components/DropdownInputBox";
import SubmitBtn from "../../../components/SubmitBtn";

const LedgerReport = ({ renderData, selectListItem }) => {
  const componentRef = useRef();
  const isLoading = false;

  
  return (
    <div id="currentAsset-report">
      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="accounting-voucher-menu-sections"
      >
        <h3 style={{ margin: "2% 1%" }}>{selectListItem}</h3>
        <div
          style={{ marginBlockEnd: "1em" }}
          className="input-current-asset-reports"
        >
          <InputBox label={"G/L Code"} />
          <InputBox label={"Ledger"} />
          <InputBox label={"From"} type={"date"} />
          <InputBox label={"To"} type={"date"} />
        </div>
        <SubmitBtn />
      </div>
      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="accounting-voucher-menu-sections"
      >
        <div className="action-btn-container">
          <div>
            <ReactToPrint
              trigger={() => (
                <IconButton sx={{ color: "blue" }}>
                  <i className="bi bi-printer "></i>
                </IconButton>
              )}
              content={() => componentRef.current}
            />
            <IconButton className="download-btn">
              <ArrowCircleDownIcon sx={{ fontSize: 30 }} />{" "}
            </IconButton>
          </div>
        </div>

        <div className="table-container">
          <div className="global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Particular</th>
                  <th>Invoice Type</th>
                  <th>Invoice no</th>
                  <th>Debit</th>
                  <th>Credit</th>
                </tr>
              </thead>
              <thead className="opening-balance-head">
                <th
                  style={{ textAlign: "start", paddingLeft: "10px" }}
                  colSpan={4}
                >
                  Opening Balance
                </th>
                <th>222</th>
                <th>0.00</th>
              </thead>
              <tbody>
                {isLoading ? (
                  [...Array(3)].map((_, index) => (
                    <tr key={index}>
                      <td colSpan={7}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                  ))
                ) : renderData.length !== 0 ? (
                  renderData?.reverse()?.map((r, i) => (
                    <React.Fragment key={i}>
                      <tr className="data-table-row-head">
                        <td>{r?.code}</td>
                        <td>{r?.particular}</td>
                        <td>{r?.opening}</td>
                        <td>{r?.debit}</td>
                        <td>{r?.credit}</td>
                        <td>{r?.closing}</td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>No Records</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {/* ledger-report-total */}
        <div style={{ padding: "0 1%" }} className="ledger-report-total">
          <div className="ledger-total-container">
            <div>
              <h5>Current Total</h5>
              <h5 className="total-amount">106000.00</h5>
              <h5 className="total-amount">106000.00</h5>
            </div>
            <div>
              <h5 className="closing-balance">Closing Balance</h5>
              <h5 className="total-amount">00</h5>
              <h5 className="total-amount">00</h5>
            </div>
            <div>
              <h5>Total</h5>
              <h5 className="total-amount">00</h5>
              <h5 className="total-amount">00</h5>
            </div>
          </div>
        </div>
      </div>

      {/* print preview   */}
      <div style={{ display: "none" }}>
        <div ref={componentRef} id="print-view">
          <div className="print-head-container">
            <div className="brand-name">
              <h2>Leeyet Textile</h2>
            </div>
            <div className="select-table-head">
              <h3>{selectListItem}</h3>
            </div>
            <div className="duration-date">
              <h3>{"01/04/2023"}</h3>
              <strong>TO</strong>
              <h3>{"01/12/2023"}</h3>
            </div>
          </div>
          <div className="print-table-container">
            <div className="table-container">
              <div className="global-table-container">
                <table className="global-table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Particular</th>
                      <th>Invoice Type</th>
                      <th>Invoice no</th>
                      <th >Debit</th>
                      <th >Credit</th>
                    </tr>
                  </thead>
                  <thead className="opening-balance-head">
                    <th
                      style={{ textAlign: "start", paddingLeft: "10px" }}
                      colSpan={4}
                    >
                      Opening Balance
                    </th>
                    <th >222</th>
                    <th >0.00</th>
                  </thead>
                  <tbody>
                    {renderData?.reverse()?.map((r, i) => (
                      <React.Fragment key={i}>
                        <tr className="data-table-row-head">
                          <td>{r?.code}</td>
                          <td>{r?.particular}</td>
                          <td>{r?.opening}</td>
                          <td>{r?.debit}</td>
                          <td >{r?.credit}</td>
                          <td  >{r?.closing}</td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {/* ledger-report-total */}
            <div
              style={{
                padding: "0 2%",
                justifyContent: "flex-end",
                display: "flex",
              }}
              className="ledger-report-total"
            >
              <div style={{ width: "50%" }} className="ledger-total-container">
                <div>
                  <h5  style={{width:'60%'}}>Current Total</h5>
                  <h5 className="total-amount">106000.00</h5>
                  <h5 className="total-amount">106000.00</h5>
                </div>
                <div>
                  <h5 style={{width:'60%'}} className="closing-balance">Closing Balance</h5>
                  <h5 className="total-amount">00</h5>
                  <h5 className="total-amount">00</h5>
                </div>
                <div>
                  <h5 style={{width:'60%'}}>Total</h5>
                  <h5 className="total-amount">00</h5>
                  <h5 className="total-amount">00</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LedgerReport;
