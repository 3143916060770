import axios from "axios";
import { update_accessory_category_list } from "../../Redux/Product Master/allAccessoryCategorySlice";
import { update_accessory_subCategory_list } from "../../Redux/Product Master/allAccessorySubCategorySlice";
import { update_fittings_list } from "../../Redux/Product Master/allFittingsSlice";
import { update_material_category_list } from "../../Redux/Product Master/allMaterialCategorySlice";
import { update_readymade_brand_list } from "../../Redux/Product Master/allReadymadeBrandsSlice";
import { update_allReadymade_categories } from "../../Redux/Product Master/allReadymadeCategoriesSlice";
import { update_readymadeSubCategory_list } from "../../Redux/Product Master/allReadymadeSubCategorySlice";
import { update_color_list } from "../../Redux/Product Master/colorListSlice";
import { update_material_list } from "../../Redux/Product Master/materialSlice";
import { update_neckStyle_list } from "../../Redux/Product Master/neckStyleSlice";
import { update_pattern_list } from "../../Redux/Product Master/patternSlice";
import { update_productCare_list } from "../../Redux/Product Master/productCareSlice";
import { update_productID } from "../../Redux/Product Master/productIDSlice";
import { update_readymade_occasion } from "../../Redux/Product Master/readymadeOccasionSlice";
import { update_readymade_style_list } from "../../Redux/Product Master/readymadeStyleSlice";
import { update_size_chart } from "../../Redux/Product Master/sizeChartSlice";
import { update_unit_list } from "../../Redux/Product Master/unitsSlice";
import store from "../../Redux/store";
import { HEADERS, HEADER_FORM_DATA, HEADER_QUERY } from "../UrlAndPaths";

// generate Product ID
export const generateProductIdAPICall = async (body) => {
  await axios
    .post(`readymade/generatepoductid`, body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_productID({ productID: res.data }));
      }
    })
    .catch((err) => {
      console.error(err.response);
    });
};

//Add Readymade  Category
export const addReadyMadeCategoryAPICall = async (
  data,
  updateListener,
  setError,
  setSuccess,
  setMsg,
  clearState
) => {
  await axios
    .post(`category/addCategory`, data, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("Readymade Category Added Successfully");
        updateListener();
        setMsg("Success");
        setSuccess(true);
        clearState("");
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setMsg("Network Connection error!!");
      }
      if (err.response.status === 409) {
        setMsg("Category Already Exist");
      }

      if (err.response.status === 401) {
        setMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setMsg("Forbidden");
      }

      setError(true);
      console.error(err.response);
    });
};

//View All Readymade Categories
export const viewAllReadymadeCategoriesAPICall = async () => {
  await axios
    .post("category/viewCategory", {}, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_allReadymade_categories({ allReadymadeCategories: res.data })
        );
        console.log("get all readymade Categories success");
      }
    })
    .catch((err) => {
      update_allReadymade_categories({ allReadymadeCategories: undefined });
      console.error(err.response);
    });
};

//Add Readymade SubCategory
export const addReadymadeSubCategoyAPICall = async (
  data,
  updateListener,
  setError,
  setSuccess,
  setMsg,
  clearState
) => {
  await axios
    .post(`category/addSubCategory`, data, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("Readymade Sub Category Added Successfully");
        updateListener();
        setMsg("Success...");
        setSuccess(true);
        clearState("");
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setMsg("Network Connection error!!");
      }
      if (err.response.status === 409) {
        setMsg("Category Already Exist");
      }

      if (err.response.status === 401) {
        setMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setMsg("Forbidden");
      }
      setError(true);
      console.error(err.response);
    });
};

//View All Readymade SubCategories
export const viewAllReadymadeSubCategoryAPICall = async () => {
  await axios
    .post("category/viewSubcategory", {}, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_readymadeSubCategory_list({ readymadeSubCategory: res.data })
        );
        console.log("get all readymade sub Categories success");
      }
    })
    .catch((err) => {
      store.dispatch(
        update_readymadeSubCategory_list({ readymadeSubCategory: undefined })
      );
      console.error(err.response);
    });
};

//Add Readymade Occasion
export const addReadyMadeOccasionAPICall = async (
  data,
  updateListener,
  setError,
  setSuccess,
  setMsg,
  clearState
) => {
  await axios
    .post(`category/addOccassion`, data, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("Readymade Occasion Added Successfully");
        updateListener();
        setMsg("Success");
        setSuccess(true);
        clearState("");
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setMsg("Network Connection error!!");
      }
      if (err.response.status === 409) {
        setMsg("Category Already Exist");
      }

      if (err.response.status === 401) {
        setMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setMsg("Forbidden");
      }

      setError(true);
      console.error(err.response);
    });
};

//View All Readymade Occasion
export const viewAllReadymadeOccasionAPICall = async () => {
  await axios
    .post("category/viewOccassion", {}, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_readymade_occasion({ readymadeOccasion: res.data })
        );
        console.log("get all readymade occasion success");
      }
    })
    .catch((err) => {
      store.dispatch(
        update_readymade_occasion({ readymadeOccasion: undefined })
      );
      console.error(err.response);
    });
};

//Add New Color
export const addNewColorAPICall = async (
  data,
  updateListener,
  setMsg,
  setError,
  setSuccess
) => {
  await axios
    .post(`readymade/color/add`, data, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        updateListener();
        viewAllColorsAPICall()
        setMsg("New color added")
        setSuccess(true)
      }
    })
    .catch((err) => {

      if (err.response.status === undefined) {
        setMsg("Network Connection error!!");
      }
      if (err.response.status === 409) {
        setMsg("Category Already Exist");
      }

      if (err.response.status === 401) {
        setMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setMsg("Forbidden");
      }

      setError(true);
    });
};

//View All colors
export const viewAllColorsAPICall = async () => {
  await axios
    .post("readymade/color/view", {}, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_color_list({ colorList: res.data }));
        console.log("get all colour is success");
      }
    })
    .catch((err) => {
      store.dispatch(update_color_list({ colorList: undefined }));
      console.error(err.response);
    });
};

//  delete colour

export const deleteAddedColorAPICall=async(body,
                                          setSnackMsg,
                                          setOpenErrorSnack,
                                          setOpenSuccessSnack)=>{
  await axios.delete("readymade/color/delete",{headers:HEADER_QUERY,data:body})
    .then((res)=>{
      if(res.status===200){
        setSnackMsg("color type deleted !!")
        setOpenSuccessSnack(true)
        viewAllColorsAPICall()
      }
    })
    .catch((err)=>{
      console.error(err.response);
      setSnackMsg("something went wrong!")
      setOpenErrorSnack(true)
    })
}
//Add New Readymade Brand
export const addNewReadymadeBrandAPICall = async (
  data,
  updateListener,
  setError,
  setSuccess,
  setMsg,
  clearState
) => {
  await axios
    .post(`readymade/addBrand`, data, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("New Readymade Brand Added Successfully");
        updateListener();
        setMsg("Success");
        setSuccess(true);
        clearState("");
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setMsg("Network Connection error!!");
      }
      if (err.response.status === 409) {
        setMsg("Category Already Exist");
      }

      if (err.response.status === 401) {
        setMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setMsg("Forbidden");
      }

      setError(true);
      console.error(err.response);
    });
};
//View All Readymade Brands
export const viewAllReadymadeBrandAPICall = async () => {
  await axios
    .get("readymade/getAllBrands", {}, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_readymade_brand_list({ readymadeBrand: res.data })
        );
        console.log("get all colour is success");
      }
    })
    .catch((err) => {
      store.dispatch(
        update_readymade_brand_list({ readymadeBrand: undefined })
      );
      console.error(err.response);
    });
};

//Add New Fitting
export const addNewFittingAPICall = async (
  data,
  updateListener,
  setError,
  setSuccess,
  setMsg,
  clearState
) => {
  await axios
    .post(`readymade/fitting/add`, data, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("New Fitting Added Successfully");
        updateListener();
        setMsg("Success");
        setSuccess(true);
        clearState("");
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setMsg("Network Connection error!!");
      }
      if (err.response.status === 409) {
        setMsg("Category Already Exist");
      }

      if (err.response.status === 401) {
        setMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setMsg("Forbidden");
      }

      setError(true);
      console.error(err.response);
    });
};

//View All Fittings
export const viewAllFittingsAPICall = async () => {
  await axios
    .get("readymade/fitting/view", {}, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_fittings_list({ allFittings: res.data }));
        console.log("get all fitting is success");
      }
    })
    .catch((err) => {
      store.dispatch(update_fittings_list({ allFittings: undefined }));
      console.error(err.response);
    });
};

//Add New Product Care
export const addNewProductCareAPICall = async (
  data,
  updateListener,
  setError,
  setSuccess,
  setMsg,
  clearState
) => {
  await axios
    .post(`readymade/productcare/add`, data, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("New Product Care Added Successfully");
        updateListener();
        setMsg("Success");
        setSuccess(true);
        clearState("");
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setMsg("Network Connection error!!");
      }
      if (err.response.status === 409) {
        setMsg("Category Already Exist");
      }

      if (err.response.status === 401) {
        setMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setMsg("Forbidden");
      }

      setError(true);
      console.error(err.response);
    });
};

//View All Product Care
export const viewAllProductCareAPICall = async () => {
  await axios
    .get("readymade/productcare/view", {}, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_productCare_list({ productCareList: res.data }));
        console.log("get all product care is success");
      }
    })
    .catch((err) => {
      store.dispatch(update_productCare_list({ productCareList: undefined }));
      console.error(err.response);
    });
};

//Add New Neck Style
export const addNeckStyleAPICall = async (
  data,
  updateListener,
  setError,
  setSuccess,
  setMsg,
  clearState
) => {
  await axios
    .post(`readymade/neckstyle/add`, data, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("New Neck Style Added Successfully");
        updateListener();
        setMsg("Success");
        setSuccess(true);
        clearState("");
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setMsg("Network Connection error!!");
      }
      if (err.response.status === 409) {
        setMsg("Neck Style Already Exist");
      }

      if (err.response.status === 401) {
        setMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setMsg("Forbidden");
      }

      setError(true);
      console.error(err.response);
    });
};
//View All Neck Style
export const viewAllNeckStyleAPICall = async () => {
  await axios
    .get("readymade/neckstyle/view", {}, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_neckStyle_list({ neckStyle: res.data }));
        console.log("get all product care is success");
      }
    })
    .catch((err) => {
      store.dispatch(update_neckStyle_list({ neckStyle: undefined }));
      console.error(err.response);
    });
};

//Add New Unit
export const addNewUnitAPICall = async (
  data,
  updateListener,
  setError,
  setSuccess,
  setMsg,
  clearState
) => {
  await axios
    .post(`readymade/addUnit`, data, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("New Unit Added Successfully");
        updateListener();
        setMsg("Success");
        setSuccess(true);
        clearState("");
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setMsg("Network Connection error!!");
      }
      if (err.response.status === 409) {
        setMsg("Unit Already Exist");
      }

      if (err.response.status === 401) {
        setMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setMsg("Forbidden");
      }

      setError(true);
      console.error(err.response);
    });
};

//View All Units
export const viewAllUnitsAPICall = async () => {
  await axios
    .get("readymade/getAllUnits", {}, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_unit_list({ units: res.data }));
        console.log("get all product care is success");
      }
    })
    .catch((err) => {
      store.dispatch(update_unit_list({ units: undefined }));
      console.error(err.response);
    });
};
//Add New Style
export const addNewStyleAPICall = async (
  data,
  updateListener,
  setError,
  setSuccess,
  setMsg,
  clearState
) => {
  await axios
    .post(`readymade/addStyle`, data, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("New Style Added Successfully");
        updateListener();
        setMsg("Success");
        setSuccess(true);
        clearState("");
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setMsg("Network Connection error!!");
      }
      if (err.response.status === 409) {
        setMsg("Style Already Exist");
      }

      if (err.response.status === 401) {
        setMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setMsg("Forbidden");
      }

      setError(true);
      console.error(err.response);
    });
};
//View All Styles
export const viewAllStylesAPICall = async () => {
  await axios
    .get("readymade/getAllStyles", {}, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_readymade_style_list({ readymadeStyle: res.data })
        );
        console.log("get readymade Style is success");
      }
    })
    .catch((err) => {
      store.dispatch(
        update_readymade_style_list({ readymadeStyle: undefined })
      );
      console.error(err.response);
    });
};
//Add New Pattern
export const addNewPatternAPICall = async (
  data,
  updateListener,
  setError,
  setSuccess,
  setMsg,
  clearState
) => {
  await axios
    .post(`readymade/pattern/add`, data, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("New Pattern Added Successfully");
        updateListener();
        setMsg("Success");
        setSuccess(true);
        clearState("");
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setMsg("Network Connection error!!");
      }
      if (err.response.status === 409) {
        setMsg("Pattern Already Exist");
      }

      if (err.response.status === 401) {
        setMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setMsg("Forbidden");
      }

      setError(true);
      console.error(err.response);
    });
};
//View All Patterns
export const viewAllPatternsAPICall = async () => {
  await axios
    .get("readymade/pattern/view", {}, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_pattern_list({ pattern: res.data }));
        console.log("get Pattern List is success");
      }
    })
    .catch((err) => {
      store.dispatch(update_pattern_list({ pattern: undefined }));
      console.error(err.response);
    });
};
//Add New Material
export const addNewMaterialAPICall = async (
  data,
  updateListener,
  setError,
  setSuccess,
  setMsg,
  clearState
) => {
  await axios
    .post(`readymade/clothMaterial/add`, data, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("New Material Added Successfully");
        updateListener();
        setMsg("Success");
        setSuccess(true);
        clearState("");
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setMsg("Network Connection error!!");
      }
      if (err.response.status === 409) {
        setMsg("Material Already Exist");
      }

      if (err.response.status === 401) {
        setMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setMsg("Forbidden");
      }

      setError(true);
      console.error(err.response);
    });
};
//View All Material
export const viewAllMaterialAPICall = async () => {
  await axios
    .get("readymade/clothMaterial/view", {}, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_material_list({ materials: res.data }));
        console.log("get Material List is success");
      }
    })
    .catch((err) => {
      store.dispatch(update_material_list({ materials: undefined }));
      console.error(err.response);
    });
};

//*Add Material Category
//Add New Material Category
export const addNewMaterialCategoryAPICall = async (
  data,
  updateListener,
  setError,
  setSuccess,
  setMsg,
  clearState
) => {
  await axios
    .post(`category/addmaterialCategory`, data, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("New Material Category Added Successfully");
        updateListener();
        setMsg("Success");
        setSuccess(true);
        clearState("");
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setMsg("Network Connection error!!");
      }
      if (err.response.status === 409) {
        setMsg("Material Category Already Exist");
      }

      if (err.response.status === 401) {
        setMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setMsg("Forbidden");
      }

      setError(true);
      console.error(err.response);
    });
};
//View All Material Category
export const viewAllMaterialCategoryAPICall = async () => {
  await axios
    .post("category/viewmaterialCategory", {}, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_material_category_list({ materialCategory: res.data })
        );
        console.log("get Material Category List is success");
      }
    })
    .catch((err) => {
      store.dispatch(
        update_material_category_list({ materialCategory: undefined })
      );
      console.error(err.response);
    });
};

//*Add Accessory Category
//Add New Accessory Category
export const addNewAccessoryCategoryAPICall = async (
  data,
  updateListener,
  setError,
  setSuccess,
  setMsg,
  clearState
) => {
  await axios
    .post(`accessoryCategory/addAccessoryCategory`, data, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("New Accessory Category Added Successfully");
        updateListener();
        setMsg("Success");
        setSuccess(true);
        clearState("");
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setMsg("Network Connection error!!");
      }
      if (err.response.status === 409) {
        setMsg("Accessory Category Already Exist");
      }

      if (err.response.status === 401) {
        setMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setMsg("Forbidden");
      }

      setError(true);
      console.error(err.response);
    });
};
//View All Accessory Category
export const viewAllAccessoryCategoryAPICall = async () => {
  await axios
    .post("accessoryCategory/getAllAccessoryCategory", {}, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_accessory_category_list({ accessoryCategory: res.data })
        );
        console.log("get Accessory Category List is success");
      }
    })
    .catch((err) => {
      store.dispatch(
        update_accessory_category_list({ accessoryCategory: undefined })
      );
      console.error(err.response);
    });
};
//Add New Accessory Subcategory
export const addNewAccessorySubCategoryAPICall = async (
  data,
  updateListener,
  setError,
  setSuccess,
  setMsg,
  clearState
) => {
  await axios
    .post(`accessoryCategory/addAccessorySubCategory`, data, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("New Accessory Subcategory Added Successfully");
        updateListener();
        setMsg("Success");
        setSuccess(true);
        clearState("");
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setMsg("Network Connection error!!");
      }
      if (err.response.status === 409) {
        setMsg("Accessory Subcategory Already Exist");
      }

      if (err.response.status === 401) {
        setMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setMsg("Forbidden");
      }

      setError(true);
      console.error(err.response);
    });
};
//View All Accessory Sub Category
export const viewAllAccessorySubCategoryAPICall = async (body) => {
  await axios
    .post("accessoryCategory/getAllAccessorySubCategory", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_accessory_subCategory_list({ accessorySubCategory: res.data })
        );
        console.log("get Accessory Sub Category List is success");
      }
    })
    .catch((err) => {
      store.dispatch(
        update_accessory_subCategory_list({ accessorySubCategory: undefined })
      );
      console.error(err.response);
    });
};

//*size chart
//Add New size into size chart
export const addNewSizeIntoSizeChartAPICall = async (
  data,
  updateListener,
  setError,
  setSuccess,
  setMsg,
  clearState
) => {
  await axios
    .post(`readymade/sizechart/add`, data, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("New Size into size chart Added Successfully");
        updateListener();
        setMsg("Success");
        setSuccess(true);
        clearState();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setMsg("Network Connection error!!");
      }
      if (err.response.status === 409) {
        setMsg("Size Already Exist");
      }

      if (err.response.status === 401) {
        setMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setMsg("Forbidden");
      }

      setError(true);
      console.error(err.response);
    });
};
//View size chart
export const viewSizeChartAPICall = async (body) => {
  await axios
    .post("readymade/sizechart/view", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_size_chart({ sizeChart: res.data }));
        console.log("get sie chart is success");
      }
    })
    .catch((err) => {
      store.dispatch(update_size_chart({ sizeChart: undefined }));
      console.error(err.response);
    });
};

//* Material Product Final APIs
//Add New Material Product
export const addNewMaterialProductAPICall = async (
  formData,
  updateListener,
  setError,
  setSuccess,
  setMsg,
  clearState
) => {
  await axios
    .post(`materialproduct/addmaterialProduct`, formData, HEADER_FORM_DATA)
    .then((res) => {
      if (res.status === 200) {
        console.log("New Material Product Added Successfully");
        updateListener();
        setMsg("Success");
        setSuccess(true);
        clearState();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setMsg("Network Connection error!!");
      }
      if (err.response.status === 409) {
        setMsg("Product Already Exist");
      }

      if (err.response.status === 401) {
        setMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setMsg("Forbidden");
      }
      if (err.response.status === 500) {
        setMsg("Server Error");
      }

      setError(true);
      console.error(err.response);
    });
};

//Edit Material Product
export const editMaterialProductAPICall = async (
  body,
  successSnackbar,
  errorSnackbar,
  setSnackMsg,
  updateListener
) => {
  await axios
    .put("materialproduct/editmaterial", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("Edited Successfully...");
        setSnackMsg("Edit success..");
        successSnackbar(true);
        updateListener();
      }
    })
    .catch((err) => {
      console.error(err.response);
      switch (err.status) {
        case 401:
          setSnackMsg("Unauthorized!!");
          break;
        case 409:
          setSnackMsg("Conflict!!");
          break;
        case 404:
          setSnackMsg("Edit unsuccessful!! Please try again..");
          break;
        case 500:
          setSnackMsg("Server Error!!");
          break;

        default:
          setSnackMsg('Can not edit !!')
          break;
      }
      errorSnackbar(true);
    });
};
//Edit Material Product Images
export const editMaterialProductImagesAPICall = async (
  formData,
  successSnackbar,
  errorSnackbar,
  setSnackMsg
) => {
  console.log(formData);
  await axios
    .put("materialproduct/editmaterialimage", formData, HEADER_FORM_DATA)
    .then((res) => {
      if (res.status === 200) {
        console.log("Images Updated..");
        setSnackMsg("Image Updated..");
        successSnackbar(true);
      }
    })
    .catch((err) => {
      console.error(err.response);
      switch (err.response.status) {
        case 401:
          setSnackMsg("Unauthorized!!");
          break;
        case 409:
          setSnackMsg("Conflict!!");
          break;
        case 400:
          setSnackMsg("Invalid input!!");
          break;
        case 500:
          setSnackMsg("Server error!!");
          break;

        default:
          setSnackMsg("Unseen Error!!");
          break;
      }
      errorSnackbar(true);
    });
};
// //Delete Material Product
// export const deleteMaterialProductAPICall = async (
//   body,
//   successSnackbar,
//   errorSnackbar,
//   setSnackMsg,
//   updateListener
// ) => {
//   await axios
//     .delete("readymade/removeproduct", {
//       headers: HEADER_QUERY,
//       data: body,
//     })
//     .then(res=>{
//       if(res.status===200)
//       {
//         console.log('Deleted Successfully..');
//         setSnackMsg('Product Deleted..')
//         successSnackbar(true)
//         updateListener()
//       }
//     })
//     .catch(err=>{
//       switch (err.response) {
//         case 401:
//             setSnackMsg('Unauthorized...')
//           break;
//         case 404:
//             setSnackMsg('Product not found...')
//           break;
//         case 409:
//             setSnackMsg('Conflict...')
//           break;
//         case 500:
//             setSnackMsg('Server Error...')
//           break;
      
//         default:
//           setSnackMsg('Unseen error!!')
//           break;
//       }
//       errorSnackbar(true)
//     });
// };

//* Readymade Product Final APIs
//Add New Readymade Product
export const addReadymadeProductAPICall = async (
  formData,
  updateListener,
  setError,
  setSuccess,
  setMsg,
  clearState
) => {
  await axios
    .post(`readymade/addreadymade`, formData, HEADER_FORM_DATA)
    .then((res) => {
      if (res.status === 200) {
        console.log("New Readymade Product Added Successfully");
        updateListener();
        setMsg("Success");
        setSuccess(true);
        clearState();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setMsg("Network Connection error!!");
      }
      if (err.response.status === 409) {
        setMsg("Product Already Exist");
      }

      if (err.response.status === 401) {
        setMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setMsg("Forbidden");
      }
      if (err.response.status === 500) {
        setMsg("Server Error");
      }

      setError(true);
      console.error(err.response);
    });
};
//Edit Readymade Product
export const editReadymadeProductAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener
) => {
  await axios
    .put("readymade/editReadymadeProduct", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        //TODO EDIT success
        setSnackMsg("Product Edit Success");
        setSuccess(true);
        updateListener();
      }
    })
    .catch((err) => {
      switch (err.response.status) {
        case 401:
          setSnackMsg("Unauthorized!!");
          break;
        case 409:
          setSnackMsg("Conflict!!");
          break;
        case 500:
          setSnackMsg("Server Error!!");
          break;

        default:
          setSnackMsg('Can not edit !!')
          break;
      }

      setError(true);
      console.log(err.response);
    });
};
//Edit Readymade Product Image
export const editReadymadeProductImageAPICall = async (
  formData,
  successSnackbar,
  errorSnackbar,
  setSnackMsg
) => {
  await axios
    .put(
      "readymade/addDeleteReadymadeProductImages",
      formData,
      HEADER_FORM_DATA
    )
    .then((res) => {
      if (res.status === 200) {
        console.log("Image Updated successfully...");
        setSnackMsg("Image Updated..");
        successSnackbar(true);
      }
    })
    .catch((err) => {
      console.error(err.response);
      switch (err.response.status) {
        case 401:
          setSnackMsg("Unauthorized!!");
          break;
        case 409:
          setSnackMsg("Conflict!!");
          break;
        case 400:
          setSnackMsg("Invalid input!!");
          break;
        case 500:
          setSnackMsg("Server error!!");
          break;

        default:
          setSnackMsg("Unseen Error!!");
          break;
      }
      errorSnackbar(true);
    });
};
// //Delete Readymade Product
// export const deleteReadymadeProductAPICall = async (
//   body,
//   successSnackbar,
//   errorSnackbar,
//   setSnackMsg,
//   updateListener
// ) => {
//   await axios
//     .delete("readymade/removeproduct", {
//       headers: HEADER_QUERY,
//       data: body,
//     })
//     .then(res=>{
//       if(res.status===200)
//       {
//         console.log('Deleted Successfully..');
//         setSnackMsg('Product Deleted..')
//         successSnackbar(true)
//         updateListener()
//       }
//     })
//     .catch(err=>{
//       switch (err.response) {
//         case 401:
//             setSnackMsg('Unauthorized...')
//           break;
//         case 404:
//             setSnackMsg('Product not found...')
//           break;
//         case 409:
//             setSnackMsg('Conflict...')
//           break;
//         case 500:
//             setSnackMsg('Server Error...')
//           break;
      
//         default:
//           setSnackMsg('Unseen error!!')
//           break;
//       }
//       errorSnackbar(true)
//     });
// };

//* Accessory Product Final APIs
//Add New Accessory Product
export const addAccessoryProductAPICall = async (
  formData,
  updateListener,
  setError,
  setSuccess,
  setMsg,
  clearState
) => {
  await axios
    .post(`accessoryProduct/addAccessoryProduct`, formData, HEADER_FORM_DATA)
    .then((res) => {
      if (res.status === 200) {
        console.log("New Readymade Product Added Successfully");
        updateListener();
        setMsg("Success");
        setSuccess(true);
        clearState();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setMsg("Network Connection error!!");
      }
      if (err.response.status === 409) {
        setMsg("Product Already Exist");
      }

      if (err.response.status === 401) {
        setMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setMsg("Forbidden");
      }
      if (err.response.status === 500) {
        setMsg("Server Error");
      }

      setError(true);
      console.error(err.response);
    });
};

//Edit Accessory Product
export const editAccessoryProductAPICall = async (
  body,
  successSnackbar,
  errorSnackbar,
  setSnackMsg,
  updateListener
) => {
  await axios
    .put("accessoryProduct/editAccessoryproduct", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("Accessory edited..");
        setSnackMsg("Edit Success...");
        successSnackbar(true);
        updateListener();
      }
    })
    .catch((err) => {
      switch (err.response.status) {
        case 401:
          setSnackMsg("Unauthorized!!!");
          break;
        case 403:
          setSnackMsg("Product in stock, Edit not allowed!!!");
          break;
        case 404:
          setSnackMsg("Please try again!!!");
          break;
        case 409:
          setSnackMsg("Conflict!!!");
          break;
        case 500:
          setSnackMsg("Server Error!!!");
          break;

        default:
          setSnackMsg('Can not edit !!')
          break;
      }
      errorSnackbar(true);
      console.error(err.response);
    });
};
//Edit Accessory Product Image
export const editAccessoryProductImageAPICall = async (
  formData,
  successSnackbar,
  errorSnackbar,
  setSnackMsg
) => {
  await axios
    .put(
      "accessoryProduct/addDeleteAccessoryProductImages",
      formData,
      HEADER_FORM_DATA
    )
    .then((res) => {
      if (res.status === 200) {
        console.log("Image Updated successfully...");
        setSnackMsg("Image Updated..");
        successSnackbar(true);
      }
    })
    .catch((err) => {
      console.error(err.response);
      switch (err.response.status) {
        case 401:
          setSnackMsg("Unauthorized!!");
          break;
        case 409:
          setSnackMsg("Conflict!!");
          break;
        case 400:
          setSnackMsg("Invalid input!!");
          break;
        case 500:
          setSnackMsg("Server error!!");
          break;

        default:
          setSnackMsg("Unseen Error!!");
          break;
      }
      errorSnackbar(true);
    });
};

// //Delete Accessory Product
// export const deleteAccessoryProductAPICall = async (
//   body,
//   successSnackbar,
//   errorSnackbar,
//   setSnackMsg,
//   updateListener
// ) => {
//   await axios
//     .delete("accessoryProduct/deletAccessoryMaterial", {
//       headers: HEADER_QUERY,
//       data: body,
//     })
//     .then(res=>{
//       if(res.status===200)
//       {
//         console.log('Deleted Successfully..');
//         setSnackMsg('Product Deleted..')
//         successSnackbar(true)
//         updateListener()
//       }
//     })
//     .catch(err=>{
//       switch (err.response) {
//         case 401:
//             setSnackMsg('Unauthorized...')
//           break;
//         case 404:
//             setSnackMsg('Product not found...')
//           break;
//         case 409:
//             setSnackMsg('Conflict...')
//           break;
//         case 500:
//             setSnackMsg('Server Error...')
//           break;
      
//         default:
//           setSnackMsg('Unseen error!!')
//           break;
//       }
//       errorSnackbar(true)
//     });
// };


//Delete All 3 products Products by passing _id
export const deleteProductsAPICall = async (
  body,
  successSnackbar,
  errorSnackbar,
  setSnackMsg,
  updateListener
) => {
  await axios
    .delete("readymade/removeproduct", {
      headers: HEADER_QUERY,
      data: body,
    })
    .then(res=>{
      if(res.status===200)
      {
        console.log('Deleted Successfully..');
        setSnackMsg('Product Deleted..')
        successSnackbar(true)
        updateListener()
      }
    })
    .catch(err=>{
      switch (err.response) {
        case 401:
            setSnackMsg('Unauthorized...')
          break;
        case 404:
            setSnackMsg('Product not found...')
          break;
        case 409:
            setSnackMsg('Conflict...')
          break;
        case 500:
            setSnackMsg('Server Error...')
          break;
      
        default:
          setSnackMsg('Unseen error!!')
          break;
      }
      errorSnackbar(true)
    });
};

// delete category api call
// readymade category

export const deleteReadymadeCategoryAPICall=async(body,updateListener,successSnackbar,
                                                  errorSnackbar,
                                                  setSnackMsg,)=>{
  await axios.delete("category/deleteCategory",{headers:HEADER_QUERY,data:body})
            .then(res=>{
              if(res.status===200){
                setSnackMsg("Deleted")
                updateListener()
                successSnackbar(true)
              }
            })
            .catch(err=>{
              console.error(err.response);
              errorSnackbar(true)
              setSnackMsg("oops..")
            })
}
// material category

export const deleteMaterialCategoryAPICall=async(body,updateListener,successSnackbar,
                                                  errorSnackbar,
                                                  setSnackMsg,)=>{
  await axios.delete("category/deletematerialCategory",{headers:HEADER_QUERY,data:body})
            .then(res=>{
              if(res.status===200){
                setSnackMsg("Deleted")
                updateListener()
                successSnackbar(true)
              }
            })
            .catch(err=>{
              console.error(err.response);
              errorSnackbar(true)
              setSnackMsg("oops..")
            })
}
// accessory category

export const deleteAccessoryCategoryAPICall=async(body,updateListener,successSnackbar,
                                                  errorSnackbar,
                                                  setSnackMsg,)=>{
  await axios.delete("accessoryCategory/deleteAccessoryCategory",{headers:HEADER_QUERY,data:body})
            .then(res=>{
              if(res.status===200){
                setSnackMsg("Deleted")
                updateListener()
                successSnackbar(true)
              }
            })
            .catch(err=>{
              console.error(err.response);
              errorSnackbar(true)
              setSnackMsg("oops..")
            })
}


// category/view

export const MainCategoryAPiCall =()=>{
  axios.post('category/view',{},HEADERS).then((res)=>{

    if(res.status === 200){
      console.log(res.data)
      
    }

  }).catch((err)=>{
    console.log("ERROR")
  })
}