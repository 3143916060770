import axios from "axios";
import { HEADERS, HEADER_QUERY } from "../../../../../API/UrlAndPaths";
import store from "../../../../../Redux/store";
import { get_all_attribute } from "./AttributeSlice";

// list api
export const attributeListAPICall = () => {
  axios
    .get("readymade/viewAttributes", {}, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(get_all_attribute({ attributeData: res.data }));
      }
    })
    .catch((err) => {
      console.log(err.response);
    });
};

// add api
export const addAttributeAPICall = (body, setState, state,clear,setLoadingForm) => {
  setLoadingForm!==undefined && setLoadingForm(true)

  axios
    .post("readymade/addAttribute", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setLoadingForm!==undefined && setLoadingForm(false)
        clear()
        setState({ ...state, message: "AddSuccess fully !!", success: true });
      }
    })
    .catch((err) => {
      setLoadingForm!==undefined && setLoadingForm(false)

      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
      } else {
        setState({ ...state, message: err?.response?.data, error: true })
      }
    });
};

// edit api
export const editAttributeAPICall = (body,setState, state,setDisable,setLoadingForm) => {
  setLoadingForm!==undefined && setLoadingForm(true)

  axios
    .put("readymade/editAttribute", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setLoadingForm!==undefined && setLoadingForm(false)
        setDisable(true)
        setState({ ...state, message: "Update successfully !!", success: true });
      }
    })
    .catch((err) => {
      setLoadingForm!==undefined && setLoadingForm(false)

      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
      } else {
        setState({ ...state, message: err?.response?.data, error: true })
      }
    });
};



//Delete api
export const deleteAttributeApiCall = (id,updateListner) => {
    console.log(id)
  axios
    .delete("readymade/deleteAttribute", {
      headers: HEADER_QUERY,
      data: {
        id:id
      },
    })
    .then((res) => {
      if (res.status === 200) {
        // window.alert("DELETE SUCESS FULLYYYYY")
        updateListner()
      }
    })
    .catch((err) => {
        window.alert("-->ERROR ")
    });
};
