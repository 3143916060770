import React, { useRef } from "react";
import InputBox from "../../../components/InputBox";
import SubmitBtn from "../../../components/SubmitBtn";
import DropdownInputBox from "../../../components/DropdownInputBox";
import ReactToPrint from "react-to-print";
import { IconButton, Skeleton } from "@mui/material";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

const renderData = [
  {
    group: "Top Wear",
    item: "shirt",
    hsnCode: "123",
    batchNo: "1234",
    quantity: "12",
    rate: "3000",
    value:'0.00',

  },
  {
    group: "Top Wear",
    item: "shirt",
    hsnCode: "123",
    batchNo: "1234",
    quantity: "12",
    rate: "3000",
    value:'0.00',

  },
  {
    group: "Top Wear",
    item: "shirt",
    hsnCode: "123",
    batchNo: "1234",
    quantity: "12",
    rate: "3000",
    value:'0.00',

  },
 
];

const LocationSummary = ({ selectListItem }) => {
  const componentRef = useRef();
  const isLoading = false;
  return (
    <div id="currentAsset-report" className="location-summary">
      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="accounting-voucher-menu-sections"
      >
        <h3 style={{ margin: "2% 1%" }}>{selectListItem}</h3>
        <div
          style={{ marginBlockEnd: "1em" }}
          className="input-current-asset-reports"
        >
          <DropdownInputBox labelName={"Location Name"} />
          <DropdownInputBox labelName={"Financial Year"} />
          <InputBox label={"Select Date"} type={"date"} />
          <InputBox label={"From"} type={"date"} />
          <InputBox label={"To"} type={"date"} />
        </div>
        <SubmitBtn />
      </div>
      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="accounting-voucher-menu-sections"
      >
        <div className="action-btn-container">
          <div>
            <ReactToPrint
              trigger={() => (
                <IconButton sx={{ color: "blue" }}>
                  <i className="bi bi-printer "></i>
                </IconButton>
              )}
              content={() => componentRef.current}
            />
            <IconButton className="download-btn">
              <ArrowCircleDownIcon sx={{ fontSize: 30 }} />{" "}
            </IconButton>
          </div>
        </div>
        <div className="table-container">
          <div className="global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>Group</th>
                  <th>Item</th>
                  <th>HSN Code</th>
                  <th>Batch No</th>
                  <th>Quantity</th>
                  <th>Rate</th>
                  <th>Value</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  [...Array(3)].map((_, index) => (
                    <tr key={index}>
                      <td colSpan={7}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                  ))
                ) : renderData.length !== 0 ? (
                  renderData?.reverse()?.map((r, i) => (
                    <React.Fragment key={i}>
                      <tr className="data-table-row-head">
                        <td>{r.group}</td>
                        <td>{r.item}</td>
                        <td>{r.hsnCode }</td>
                        <td>{r.batchNo }</td>
                        <td>{r.quantity }</td>
                        <td>{r.rate }</td>
                        <td>{r.value }</td>
                        <td><button className="transfer-btn">Transfer</button></td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>No Records</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* print */}
      <div style={{ display: "none" }}>
        <div
          ref={componentRef}
          id="currentAsset-report"
          className="print-preview-container"
        >
          <div className="print-head-container">
            <div className="brand-name">
              <h2>Leeyet Textile</h2>
            </div>
            <div className="select-table-head">
              <h3>{selectListItem}</h3>
            </div>
            <div className="duration-date">
              <h3>{"01/04/2023"}</h3>
              <strong>TO</strong>
              <h3>{"01/12/2023"}</h3>
            </div>
          </div>
          <div className="global-table-wrapper">
          <div className="global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>Group</th>
                  <th>Item</th>
                  <th>HSN Code</th>
                  <th>Batch No</th>
                  <th>Quantity</th>
                  <th>Rate</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  [...Array(3)].map((_, index) => (
                    <tr key={index}>
                      <td colSpan={7}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                  ))
                ) : renderData.length !== 0 ? (
                  renderData?.reverse()?.map((r, i) => (
                    <React.Fragment key={i}>
                      <tr className="data-table-row-head">
                        <td>{r.group}</td>
                        <td>{r.item}</td>
                        <td>{r.hsnCode }</td>
                        <td>{r.batchNo }</td>
                        <td>{r.quantity }</td>
                        <td>{r.rate }</td>
                        <td>{r.value }</td>
                        
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>No Records</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationSummary;
