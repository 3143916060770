import { createSlice } from "@reduxjs/toolkit";

export const bottomModelSlice=createSlice({
    name:"bottomModelSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        update_bottom_model:(state,action)=>{
            state.value=action.payload.bottomModel
        }
    }
})

export const {update_bottom_model}=bottomModelSlice.actions
export default bottomModelSlice.reducer