import React, { useState } from "react";
import { Select, Dialog, IconButton, MenuItem } from "@mui/material";
import "../../../../css/Orders/workOrderAdminAddThobModel.css";
import { AddCategoryPopup } from "../../../Single Components/AddCategoryPopup";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import {
  addButtonAPICall,
  addCollarAPICall,
  addCollarClothAPICall,
  addCollarModelAPICall,
  addCuffAPICall,
  addCuffClothAPICall,
  addCuffModelAPICall,
  addFpModelAPICall,
  addLengthCommissionAPICall,
  addMarModelAPICall,
  addPenAPICall,
  addSidePtModelAPICall,
  addStitchingAPICall,
  addThobModelAPICall,
  deleteButtonAPICall,
  deleteCollarAPICall,
  deleteCollarClothAPICall,
  deleteCollarModelAPICall,
  deleteCuffAPICall,
  deleteCuffClothAPICall,
  deleteCuffModelAPICall,
  deleteFpModelAPICall,
  deleteLengthCommissionAPICall,
  deleteMarModelAPICall,
  deletePenAPICall,
  deleteSidePtModelAPICall,
  deleteStitchingAPICall,
  deleteThobModelAPICall,
  viewButtonAPICall,
  viewCollarAPICall,
  viewCollarClothAPICall,
  viewCollarModelAPICall,
  viewCuffAPICall,
  viewCuffClothAPICall,
  viewCuffModelAPICall,
  viewFpModelAPICall,
  viewLengthCommissionAPICall,
  viewMarModelAPICall,
  viewPenAPICall,
  viewSidePtModelAPICall,
  viewStitchingAPICall,
  viewThobModelAPICall,
} from "../../../../API/Orders/workOrderAPI";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import { viewCuttingSlipModelAPICall } from "../../../../API/Settings/CuttingSlipModel/cuttingSlipMoldelAPI";
export const ThobWO = (props) => {
  const { formValues, onChange } = props;
  // console.log(formValues);
  //*Redux State
  //Role of User [admin or user]
  const userRole = useSelector((state) => state.userRoleSlice.value);
  const thobModelData = useSelector((state) => state.thobModelDataSlice.value);
  const lengthCommissionData = useSelector(
    (state) => state.lengthCommissionSlice.value
  );
  const marModelData = useSelector((state) => state.marModelDataSlice.value);
  const cuffData = useSelector((state) => state.cuffDataSlice.value);
  const cuffClothData = useSelector((state) => state.cuffClothSlice.value);
  const cuffModelData = useSelector((state) => state.cuffModelSlice.value);
  const collarData = useSelector((state) => state.collarSlice.value);
  const collarClothData = useSelector((state) => state.collarClothSlice.value);
  const collarModelData = useSelector((state) => state.collarModelSlice.value);
  const penData = useSelector((state) => state.penSlice.value);
  const sidePtModelData = useSelector((state) => state.sidePTModelSlice.value);
  const stitchingData = useSelector((state) => state.stitchingSlice.value);
  const buttonData = useSelector((state) => state.buttonSlice.value);
  const fpModelData = useSelector((state) => state.fpModelSlice.value);
  // cutting slip template
  const cuttingSlipTemplate=useSelector((state)=>state.cuttingSlipSlice.value)




  const [isModify, setIsModify] = useState(false);
  //Snackbar States
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!");

  const [thobModelDialog, setThobModelDialog] = useState(false);
  const [addLengthCommission, setAddLengthCommission] = useState(false);
  const [addMarModal, setAddMarModal] = useState(false);
  const [addCuff, setAddCuff] = useState(false);
  const [addCuffCloth, setAddCuffCloth] = useState(false);
  const [addCuffModal, setAddCuffModal] = useState(false);
  const [addCollar, setAddCollar] = useState(false);
  const [addCollarCloth, setAddCollarCloth] = useState(false);
  const [addCollarModal, setAddCollarModal] = useState(false);
  const [addPen, setAddPen] = useState(false);
  const [addSidePTModal, setAddSidePTModal] = useState(false);
  const [addStitching, setAddStitching] = useState(false);
  const [addButton, setAddButton] = useState(false);
  const [addFpModel, setAddFpModel] = useState(false);

  //Thob model Form States
  const thobModelFormInitialState = {
    modelName: "",
    lengthFrom: "",
    lengthTo: "",
    minRate: 0,
    commission: 0,
  };
  const [thobModelForm, setThobModelForm] = useState(thobModelFormInitialState);

  //Length Commission Form States
  const lengthCommissionInitialState = {
    lengthFrom: "",
    lengthTo: "",
    usedQty: "",
    minRate: 0,
    commission: 0,
  };
  const [lengthCommissionForm, setLengthCommissionForm] = useState(
    lengthCommissionInitialState
  );

  //Mar Model State
  const [newMarModelName, setNewMarModelName] = useState("");
  //Cuff state
  const [newCuffName, setNewCuffName] = useState("");
  //Cuff cloth State
  const [newCuffCloth, setNewCuffCloth] = useState("");
  //Cuff Model State
  const [newCuffModel, setNewCuffModel] = useState("");
  //Collar State
  const [newCollar, setNewCollar] = useState("");
  //Collar Cloth
  const [collarCloth, setCollarCloth] = useState("");
  //Collar Model
  const [collarModel, setCollarModel] = useState("");
  //Pen
  const [newPenName, setNewPenName] = useState("");
  //Side PT Model
  const [newSidePtModel, setNewSidePtModel] = useState("");
  //Stitching
  const [newStitching, setNewStitching] = useState("");
  //Button
  const [newButton, setNewButton] = useState("");
  //FP Model
  const [fpModel, setFpModel] = useState("");

  // validation states
  const [modelNameAlert,setModelNameAlert]=useState(false)
  const [lengthFromAlert,setLengthFromAlert]=useState(false)
  const [lengthToAlert,setLengthToAlert]=useState(false)
  const [usedQtyAlert,setUsedQtyAlert]=useState(false)


  //Update Listener
  const updateListener = () => {
    setIsModify(!isModify);
  };

  //Close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setSuccessSnackOpen(false);
  };
  //Close ErrorSnackbar
  const closeErrorSnackbar = () => {
    setErrorSnackOpen(false);
  };
// cutting slip template api call
  useEffect(()=>{
    viewCuttingSlipModelAPICall()
  },[])

  //*onChange Fns
  //Get Thob Model form
  const getThobModelForm = (key) => (e) => {
    const { value } = e.target;

    if (key === "modelName") {
      setThobModelForm({ ...thobModelForm, modelName: value });
      setModelNameAlert(false)
      if(value===""){
        setModelNameAlert(true)
      }
    }
    if (key === "lengthFrom") {
      setThobModelForm({ ...thobModelForm, lengthFrom: value });
      setLengthFromAlert(false)
      if(value===""){
        setLengthFromAlert(true)
      }
    }
    if (key === "lengthTo") {
      setThobModelForm({ ...thobModelForm, lengthTo: value });
      setLengthToAlert(false)
      if(value===""){
        setLengthToAlert(true)
      }
    }
    if (key === "minRate") {
      setThobModelForm({ ...thobModelForm, minRate: value });
    }
    if (key === "commission") {
      setThobModelForm({ ...thobModelForm, commission: value });
    }
  };

  //Get Length commission Form
  const getLengthCommissionForm = (key) => (e) => {
    const { value } = e.target;

    if (key === "lengthFrom") {
      setLengthCommissionForm({ ...lengthCommissionForm, lengthFrom: value });
      setLengthFromAlert(false)
      if(value===""){
        setLengthFromAlert(true)
      }
    }
    if (key === "lengthTo") {
      setLengthCommissionForm({ ...lengthCommissionForm, lengthTo: value });
      setLengthToAlert(false)
      if(value===""){
        setLengthToAlert(true)
      }
    }
    if (key === "usedQty") {
      setLengthCommissionForm({ ...lengthCommissionForm, usedQty: value });
      setUsedQtyAlert(false)
      if(value===""){
        setUsedQtyAlert(true)
      }
    }
    if (key === "minRate") {
      setLengthCommissionForm({ ...lengthCommissionForm, minRate: value });
    }
    if (key === "commission") {
      setLengthCommissionForm({ ...lengthCommissionForm, commission: value });
    }
  };

  //Get Mar Model
  const getMarModel = (e) => {
    setNewMarModelName(e.target.value);
  };

  //Get Cuff
  const getCuff = (e) => {
    setNewCuffName(e.target.value);
  };

  //Get Cuff cloth
  const getCuffCloth = (e) => {
    setNewCuffCloth(e.target.value);
  };

  //Get Cuff Model
  const getCuffModel = (e) => {
    setNewCuffModel(e.target.value);
  };

  //Get Collar
  const getCollar = (e) => {
    setNewCollar(e.target.value);
  };
  //Get Collar Cloth
  const getCollarCloth = (e) => {
    setCollarCloth(e.target.value);
  };
  //Get Collar Model
  const getCollarModel = (e) => {
    setCollarModel(e.target.value);
  };
  //Get Pen
  const getPenName = (e) => {
    setNewPenName(e.target.value);
  };
  //Get Side PT Model
  const getSidePtModel = (e) => {
    setNewSidePtModel(e.target.value);
  };
  //Get Stitching
  const getStitching = (e) => {
    setNewStitching(e.target.value);
  };
  //Get Button
  const getButton = (e) => {
    setNewButton(e.target.value);
  };
 //Get FP Model
 const getFpModel = (e) => {
  setFpModel(e.target.value);
};
//FP Model Clear State
const clearFpModelState = () => {
  setFpModel("");
};
  // -------------------------------------------------------
  //*onClick
  //add Thob Model Body
  const addThobModelBody = {
    thobName: thobModelForm?.modelName,
    fromLength: parseFloat(thobModelForm?.lengthFrom),
    toLength: parseFloat(thobModelForm?.lengthTo),
    minRate: parseFloat(thobModelForm?.minRate),
    commission: parseFloat(thobModelForm?.commission),
  };
  //Add Thob Model
  const addThobModel = () => {
    if (thobModelForm?.modelName === ""){
      setModelNameAlert(true)
    } 
    else if(thobModelForm?.lengthFrom === ""){
      setLengthFromAlert(true)
    }
    else if(thobModelForm?.lengthTo === ""){
      setLengthToAlert(true)
    }
    else {
      addThobModelAPICall(
        addThobModelBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        clearStates
      );
    }
  };
  //Delete Thob Model
  const deleteThobModel = (thobId) => () => {
    deleteThobModelAPICall(
      { thobId },
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      updateListener
    );
  };

  //Add Length Commission body
  const addLengthCommissionBody = {
    fromLength: parseFloat(lengthCommissionForm?.lengthFrom),
    toLength: parseFloat(lengthCommissionForm?.lengthTo),
    usedQty: parseFloat(lengthCommissionForm?.usedQty),
    minRate: parseFloat(lengthCommissionForm?.minRate),
    commission: parseFloat(lengthCommissionForm?.commission),
  };
  //Add Length Commission
  const addNewLengthCommission = () => {
    if (lengthCommissionForm?.lengthFrom === "") {
      setLengthFromAlert(true)
    } else if (lengthCommissionForm?.lengthTo === "") {
      setLengthToAlert(true)
    } else if (lengthCommissionForm?.usedQty === "") {
      setUsedQtyAlert(true)
    } else {
      addLengthCommissionAPICall(
        addLengthCommissionBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        clearLengthCommissionState
      );
    }
  };
  //Delete Length Commission
  const deleteLengthCommission = (lengthCommissionId) => () => {
    deleteLengthCommissionAPICall(
      { lengthCommissionId },
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      updateListener
    );
  };

  //Add Mar Model Body
  const addMarModelBody = {
    marName: newMarModelName,
  };
  //Add MarModel
  const addMarModel = () => {
    if (newMarModelName === "") {
      setLengthToAlert(true)
    } else {
      addMarModelAPICall(
        addMarModelBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        clearMarModel
      );
    }
  };
  //Delete Mar Model
  const deleteMarModel = (marId) => () => {
    deleteMarModelAPICall(
      { marId },
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      updateListener
    );
  };

  //Add Cuff Body
  const addCuffBody = {
    cuff: newCuffName,
  };
  //Add Cuff
  const addNewCuff = () => {
    if (newCuffName === "") {
      setLengthToAlert(false)
    } else {
      addCuffAPICall(
        addCuffBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        clearCuffState
      );
    }
  };
  //Delete Cuff
  const deleteCuff = (cuffId) => () => {
    deleteCuffAPICall(
      { cuffId },
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      updateListener
    );
  };

  //Add Cuff Cloth Body
  const addCuffClothBody = {
    cuffCloth: newCuffCloth,
  };
  //Add Cuff cloth
  const addNewCuffCloth = () => {
    if (newCuffCloth === "") {
      setLengthToAlert(false)
    } else {
      addCuffClothAPICall(
        addCuffClothBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        clearCuffClothState
      );
    }
  };
  //Delete Cuff Cloth
  const deleteCuffCloth = (cuffClothId) => () => {
    deleteCuffClothAPICall(
      { cuffClothId },
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      updateListener
    );
  };

  //Add Cuff Model Body
  const addCuffModelBody = {
    cuffModel: newCuffModel,
  };
  //Add Cuff Model
  const addNewCuffModel = () => {
    if (newCuffModel === "") {
      setLengthToAlert(false)
    } else {
      addCuffModelAPICall(
        addCuffModelBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        clearCuffModelState
      );
    }
  };
  //Delete Cuff Model
  const deleteCuffModel = (cuffModelId) => () => {
    deleteCuffModelAPICall(
      { cuffModelId },
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      updateListener
    );
  };

  //Add Collar body
  const addCollarBody = {
    collar: newCollar,
  };
  //Add Collar
  const addNewCollar = () => {
    if (newCollar === "") {
      setLengthToAlert(false)
    } else {
      addCollarAPICall(
        addCollarBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        clearCollarState
      );
    }
  };
  //Delete Collar
  const deleteCollar = (collarId) => () => {
    deleteCollarAPICall(
      { collarId },
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      updateListener
    );
  };

  //Add Collar Cloth body
  const addCollarClothBody = {
    collarCloth: collarCloth,
  };
  //Add collar cloth
  const addNewCollarCloth = () => {
    if (collarCloth === "") {
      setLengthToAlert(false)
    } else {
      addCollarClothAPICall(
        addCollarClothBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        clearCollarClothState
      );
    }
  };
  //Delete Collar Cloth
  const deleteCollarCloth = (collarClothId) => () => {
    deleteCollarClothAPICall(
      { collarClothId },
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      updateListener
    );
  };

  //Add Collar Model Body
  const addCollarModelBody = {
    collarModel: collarModel,
  };
  //Add Collar Model
  const addCollarModel = () => {
    if (collarModel === "") {
      setLengthToAlert(false)
    } else {
      addCollarModelAPICall(
        addCollarModelBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        clearCollarModelState
      );
    }
  };
  //Delete Collar Model
  const deleteCollarModel = (collarModelId) => () => {
    deleteCollarModelAPICall(
      { collarModelId },
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      updateListener
    );
  };

  //Add Pen Body
  const addPenBody = {
    pen: newPenName,
  };
  //Add Pen
  const addNewPen = () => {
    if (newPenName === "") {
      setLengthToAlert(false)
    } else {
      addPenAPICall(
        addPenBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        clearPenState
      );
    }
  };
  //Delete Pen
  const deletePen = (penId) => () => {
    deletePenAPICall(
      { penId },
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      updateListener
    );
  };

  //Add Side PT Model Body
  const addSidePtModelBody = {
    sidePT: newSidePtModel,
  };
  //Add Side PT Model
  const addNewSidePtModel = () => {
    if (newSidePtModel === "") {
      setLengthToAlert(false)
    } else {
      addSidePtModelAPICall(
        addSidePtModelBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        clearSidePtModelState
      );
    }
  };
  //Delete Side PT Model
  const deleteSidePtModel = (sidePTId) => () => {
    deleteSidePtModelAPICall(
      { sidePTId },
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      updateListener
    );
  };

  //Add Stitching Body
  const addStitchingBody = {
    stitching: newStitching,
  };
  //Add Stitching
  const addNewStitching = () => {
    if (newStitching === "") {
      setLengthToAlert(false)
    } else {
      addStitchingAPICall(
        addStitchingBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        clearStitchingState
      );
    }
  };
  //Delete Stitching
  const deleteStitching = (stitchingId) => () => {
    deleteStitchingAPICall(
      { stitchingId },
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      updateListener
    );
  };

 //Add FP Model Body
 const addFpModelBody = {
  fpModel: fpModel,
};
//Add FP Model
const addNewFpModel = () => {
  if (fpModel === "") {
    setLengthToAlert(false)
  } else {
    addFpModelAPICall(
      addFpModelBody,
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      updateListener,
      clearFpModelState
    );
  }
};
//Delete FP Model
const deleteFpModel = (fpModelId) => () => {
  deleteFpModelAPICall(
    { fpModelId },
    setSuccessSnackOpen,
    setErrorSnackOpen,
    setSnackMsg,
    updateListener
  );
};



  //Add Button Body
  const addButtonBody = {
    button: newButton,
  };
  //Add Button
  const addNewButton = () => {
    if (newButton === "") {
      setLengthToAlert(false)
    } else {
      addButtonAPICall(
        addButtonBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        clearButtonState
      );
    }
  };
  //Delete Button
  const deleteButton = (buttonId) => () => {
    deleteButtonAPICall(
      { buttonId },
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      updateListener
    );
  };

  //----------------------------------------------
  //*useEffects
  useEffect(() => {
    viewThobModelAPICall();
    viewLengthCommissionAPICall();
    viewMarModelAPICall();
    viewCuffAPICall();
    viewCuffClothAPICall();
    viewCuffModelAPICall();
    viewCollarAPICall();
    viewCollarClothAPICall();
    viewCollarModelAPICall();
    viewPenAPICall();
    viewSidePtModelAPICall();
    viewStitchingAPICall();
    viewButtonAPICall();
    viewFpModelAPICall();
  }, [isModify]);

  //*Clear States
  //Thob model Clear State
  const clearStates = () => {
    setThobModelForm(thobModelFormInitialState);
  };
  //Length Commission Clear State
  const clearLengthCommissionState = () => {
    setLengthCommissionForm(lengthCommissionInitialState);
  };

  //Mar Model Clear State
  const clearMarModel = () => {
    setNewMarModelName("");
  };
  //Cuff Clear State
  const clearCuffState = () => {
    setNewCuffName("");
  };
  //Cuff cloth clear state
  const clearCuffClothState = () => {
    setNewCuffCloth("");
  };
  //Cuff Model Clear State
  const clearCuffModelState = () => {
    setNewCuffModel("");
  };
  //Collar Clear State
  const clearCollarState = () => {
    setNewCollar("");
  };
  //Collar cloth clear state
  const clearCollarClothState = () => {
    setCollarCloth("");
  };
  //Collar model clear state
  const clearCollarModelState = () => {
    setCollarModel("");
  };
  //Pen Clear state
  const clearPenState = () => {
    setNewPenName("");
  };
  //Side PT Model Clear State
  const clearSidePtModelState = () => {
    setNewSidePtModel("");
  };
  //Stitching Clear State
  const clearStitchingState = () => {
    setNewStitching("");
  };
  //Button Clear State
  const clearButtonState = () => {
    setNewButton("");
  };

  return (
    <div className="work-order-middle-container">
      <div className="work-order-middle-left-container">
        <div className="global-single-input work-order-input">
          <p>Thob Model</p>
          <Select
            value={formValues?.thobModel}
            onChange={userRole !== "admin" && onChange("thobModel")}
            disabled={userRole === "admin" ? true : false}
          >
            <MenuItem value="0">-select thob model-</MenuItem>
            {thobModelData
              ?.slice(0)
              ?.reverse()
              ?.map((item, i) => (
                <MenuItem key={i} value={item?._id}>
                  {item?.thobName}
                </MenuItem>
              )) || <MenuItem>No data</MenuItem>}
          </Select>
          {userRole === "admin" && (
            <p
              onClick={() => setThobModelDialog(true)}
              className="suffix-text-blue"
            >
              Add Thob Model
            </p>
          )}
        </div>
        <div className="global-single-input work-order-input">
          <p>Length</p>
          <input
            tabIndex={cuttingSlipTemplate?.template == "template2" && "1"}
            value={formValues?.length}
            onChange={userRole !== "admin" && onChange("length")}
            placeholder={userRole !== "admin" && "Enter length..."}
            disabled={userRole === "admin" ? true : false}
          />
          {userRole === "admin" && (
            <p
              onClick={() => setAddLengthCommission(true)}
              className="suffix-text-blue"
            >
              Add Length Commission
            </p>
          )}
        </div>
        <div className="global-single-input work-order-input">
          <p>{cuttingSlipTemplate?.template == "template2" ? "Sleeve":"Shoulder"}</p>
          <input
            tabIndex={cuttingSlipTemplate?.template == "template2" && "4"}
            value={formValues?.shoulder}
            onChange={userRole !== "admin" && onChange("shoulder")}
            // placeholder={userRole !== "admin" &&  "Enter shoulder..."}
            placeholder={
               cuttingSlipTemplate?.template == "template2" ?
               "Enter Sleeve..."
               :userRole !== "admin"?
               ""
               :"Enter shoulder..."
              }
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>{cuttingSlipTemplate?.template == "template2" ? "Sada":"Sleeve"}</p>
          <input
          tabIndex={cuttingSlipTemplate?.template == "template2" && "5"}
            value={formValues?.sleeve}
            onChange={userRole !== "admin" && onChange("sleeve")}
            // placeholder={userRole !== "admin" && "Enter sleeve..."}
            placeholder={
              cuttingSlipTemplate?.template == "template2" ?
              "Enter Sada..."
              :userRole !== "admin"?
              ""
              :"Enter Sleeve..."
            }
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>{cuttingSlipTemplate?.template == "template2" ? "Regal":"Chest"}</p>
          <input
            tabIndex={cuttingSlipTemplate?.template == "template2" && "8"}
            value={formValues?.chest}
            onChange={userRole !== "admin" && onChange("chest")}
            // placeholder={userRole !== "admin" && "Enter chest..."}
            placeholder={
              cuttingSlipTemplate?.template == "template2" ?
              "Enter Regal..."
              :userRole !== "admin"?
              ""
              :"Enter Chest..."
            }
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>{cuttingSlipTemplate?.template == "template2" ? "Body":"Stomach"}</p>
          <input
            tabIndex={cuttingSlipTemplate?.template == "template2" && "9"}
            value={formValues?.stomach}
            onChange={userRole !== "admin" && onChange("stomach")}
            // placeholder={userRole !== "admin" && "Enter stomach..."}
            placeholder={
              cuttingSlipTemplate?.template == "template2" ?
              "Enter Body..."
              :userRole !== "admin"?
              ""
              :"Enter Stomach..."
            }
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        {
          cuttingSlipTemplate?.template !== "template2" &&
          <div className="global-single-input work-order-input">
          <p>S.L Chest</p>
          <input
            value={formValues?.slChest}
            onChange={userRole !== "admin" && onChange("slChest")}
            // placeholder={userRole !== "admin" && "Enter S.L Chest..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        
        // <div className="global-single-input work-order-input">
        //   <p>Mar Model</p>
        //   <Select
        //     value={formValues?.cuff}
        //     onChange={userRole !== "admin" && onChange("cuff")}
        //     disabled={userRole === "admin" ? true : false}
        //   >
        //     <MenuItem value="0">-select MarModel-</MenuItem>
        //     {cuffData
        //       ?.slice(0)
        //       ?.reverse()
        //       ?.map((item, i) => (
        //         <MenuItem key={i} value={item?._id}>
        //           {item?.cuff}
        //         </MenuItem>
        //       )) || <MenuItem>No data</MenuItem>}
        //   </Select>
        //   {userRole === "admin" && (
        //     <p onClick={() => setAddCuff(true)} className="suffix-text-blue">
        //       Add mar model
        //     </p>
        //   )}
        // </div>

        }
       
        <div className="global-single-input work-order-input">
          <p>{cuttingSlipTemplate?.template == "template2" ? "Notes":"S.L So"}</p>
          <input
            tabIndex={cuttingSlipTemplate?.template == "template2" && "12"}
            value={formValues?.slSo}
            onChange={userRole !== "admin" && onChange("slSo")}
            // placeholder={userRole !== "admin" && "Enter S.L So..."}
            placeholder={
              cuttingSlipTemplate?.template == "template2" ?
              "Enter Notes..."
              :userRole !== "admin"?
              ""
              :"Enter S.L So..."
            }
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>Neck</p>
          <input
            tabIndex={cuttingSlipTemplate?.template == "template2" && "13"}
            value={formValues?.neck}
            onChange={userRole !== "admin" && onChange("neck")}
            placeholder={userRole !== "admin" && "Enter neck..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>{cuttingSlipTemplate?.template == "template2" ? "PC":"Bottom"}</p>
          <input
            tabIndex={cuttingSlipTemplate?.template == "template2" && "16"}
            value={formValues?.bottom}
            onChange={userRole !== "admin" && onChange("bottom")}
            // placeholder={userRole !== "admin" && "Enter bottom..."}
            placeholder={
              cuttingSlipTemplate?.template == "template2" ?
              "Enter PC..."
              :userRole !== "admin"?
              ""
              :"Enter Bottom..."
            }
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>{cuttingSlipTemplate?.template == "template2" ? "Bottom":"Mar Size"}</p>
          <input
            tabIndex={cuttingSlipTemplate?.template == "template2" && "17"}
            value={formValues?.marSize}
            onChange={userRole !== "admin" && onChange("marSize")}
            // placeholder={userRole !== "admin" && "Enter mar size..."}
            placeholder={
              cuttingSlipTemplate?.template == "template2" ?
              "Enter Bottom..."
              :userRole !== "admin"?
              ""
              :"Enter Mar Size..."
            }
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>Mar Model</p>
          <Select
            value={formValues?.marModel}
            onChange={userRole !== "admin" && onChange("marModel")}
            disabled={userRole === "admin" ? true : false}
          >
            <MenuItem value="0">-select mar model-</MenuItem>
            {marModelData
              ?.slice(0)
              ?.reverse()
              ?.map((item, i) => (
                <MenuItem key={i} value={item?._id}>
                  {item?.marName}
                </MenuItem>
              )) || <MenuItem>No data</MenuItem>}
          </Select>
          {userRole === "admin" && (
            <p
              onClick={() => setAddMarModal(true)}
              className="suffix-text-blue"
            >
              Add Mar Model
            </p>
          )}
        </div>
        {
          cuttingSlipTemplate?.template !== "template2" &&
          <div className="global-single-input work-order-input">
          <p>Cuff</p>
          <Select
            value={formValues?.cuff}
            onChange={userRole !== "admin" && onChange("cuff")}
            disabled={userRole === "admin" ? true : false}
          >
            <MenuItem value="0">-select cuff-</MenuItem>
            {cuffData
              ?.slice(0)
              ?.reverse()
              ?.map((item, i) => (
                <MenuItem key={i} value={item?._id}>
                  {item?.cuff}
                </MenuItem>
              )) || <MenuItem>No data</MenuItem>}
          </Select>
          {userRole === "admin" && (
            <p onClick={() => setAddCuff(true)} className="suffix-text-blue">
              Add Cuff
            </p>
          )}
        </div>
        }
        {
          cuttingSlipTemplate?.template !== "template2" &&
          <div className="global-single-input work-order-input">
          <p>Cuff Size</p>
          <div className="work-order-input-group">
            <input
              className="work-order-input-group-first"
              value={formValues?.cuffSize1}
              onChange={userRole !== "admin" && onChange("cuffSize1")}
              placeholder={userRole !== "admin" && "Enter cuff size..."}
              disabled={userRole === "admin" ? true : false}
            />
            <input
              className="work-order-input-group-second"
              value={formValues?.cuffSize2}
              onChange={userRole !== "admin" && onChange("cuffSize2")}
              placeholder={userRole !== "admin" && "..."}
              disabled={userRole === "admin" ? true : false}
            />
          </div>
        </div>
        }
       
       

        <div className="global-single-input work-order-input">
          <p>{cuttingSlipTemplate?.template == "template2" ? "Stitching Model":"Cuff Cloth"}</p>
          <Select
            value={formValues?.cuffCloth}
            onChange={userRole !== "admin" && onChange("cuffCloth")}
            disabled={userRole === "admin" ? true : false}
          >
            <MenuItem value="0">-select-</MenuItem>
            {cuffClothData
              ?.slice(0)
              ?.reverse()
              ?.map((item, i) => (
                <MenuItem key={i} value={item?._id}>
                  {item?.cuffCloth}
                </MenuItem>
              )) || <MenuItem>No data</MenuItem>}
          </Select>
          {userRole === "admin" && (
            <p
              onClick={() => setAddCuffCloth(true)}
              className="suffix-text-blue"
            >
              {cuttingSlipTemplate?.template == "template2" ? " Add Stitching Model":"Add Cuff Cloth"}
            </p>
          )}
        </div>
        <div className="global-single-input work-order-input">
          <p>{cuttingSlipTemplate?.template == "template2" ? "Button":"Cuff Model"}</p>
          <Select
            value={formValues?.cuffModel}
            onChange={userRole !== "admin" && onChange("cuffModel")}
            disabled={userRole === "admin" ? true : false}
          >
            <MenuItem value="0">-select-</MenuItem>
            {cuffModelData
              ?.slice(0)
              ?.reverse()
              ?.map((item, i) => (
                <MenuItem key={i} value={item?._id}>
                  {item?.cuffModel}
                </MenuItem>
              )) || <MenuItem>No data</MenuItem>}
          </Select>
          {userRole === "admin" && (
            <p
              onClick={() => setAddCuffModal(true)}
              className="suffix-text-blue"
            >
              {cuttingSlipTemplate?.template == "template2" ? "Add Button":"Add Cuff Model"}
            </p>
          )}
        </div>
        {
          cuttingSlipTemplate?.template == "template2"?
          <div className="global-single-input work-order-input">
          <p>Type</p>
          <Select
            value={formValues?.collar}
            onChange={userRole !== "admin" && onChange("collar")}
            disabled={userRole === "admin" ? true : false}
          >
            <MenuItem value="0">-select Type-</MenuItem>
            {collarData
              ?.slice(0)
              ?.reverse()
              ?.map((item, i) => (
                <MenuItem key={i} value={item?._id}>
                  {item?.collar}
                </MenuItem>
              )) || <MenuItem>No data</MenuItem>}
          </Select>
          {userRole === "admin" && (
            <p onClick={() => setAddCollar(true)} className="suffix-text-blue">
              Add Type
            </p>
          )}
        </div>
        :
        <div className="global-single-input work-order-input">
        <p>Neck D Button</p>
        <input
          value={formValues?.neckDButton}
          onChange={userRole !== "admin" && onChange("neckDButton")}
          placeholder="Enter neck d button..."
          disabled={userRole === "admin" ? true : false}
        />
        {/* {userRole === "admin" && (
          <p
            onClick={() => setAddCuffModal(true)}
            className="suffix-text-blue"
          >
            Add Cuff Model
          </p>
        )} */}
       </div>

        }
       {/* {
        cuttingSlipTemplate?.template == "template2"?
        <div className="global-single-input work-order-input">
        <p>FB</p>
        <Select
          value={formValues?.collarCloth}
          onChange={userRole !== "admin" && onChange("collarCloth")}
          disabled={userRole === "admin" ? true : false}
        >
          <MenuItem value="0">-select FB-</MenuItem>
          {collarClothData
            ?.slice(0)
            ?.reverse()
            ?.map((item, i) => (
              <MenuItem key={i} value={item?._id}>
                {item?.collarCloth}
              </MenuItem>
            )) || <MenuItem>No data</MenuItem>}
        </Select>
        {userRole === "admin" && (
          <p
            onClick={() => setAddCollarCloth(true)}
            className="suffix-text-blue"
          >
            Add FB
          </p>
        )}
      </div>
      : */}
      <div className="global-single-input work-order-input">
      <p>{cuttingSlipTemplate?.template == "template2"?"FB":"Side PT Size"}</p>
      <input
      tabIndex={cuttingSlipTemplate?.template == "template2" && "21"}
        value={formValues?.sidePTSize}
        onChange={userRole !== "admin" && onChange("sidePTSize")}
        placeholder="Enter ..."
        disabled={userRole === "admin" ? true : false}
      />
    </div>
       {/* } */}
       
      </div>
      <div className="work-order-middle-right-container">
        {/* <div className="global-single-input work-order-input">
          <p>Cuff Model</p>
          <Select
            value={formValues?.cuffModel}
            onChange={userRole !== "admin" && onChange("cuffModel")}
            disabled={userRole === "admin" ? true : false}
          >
            <MenuItem value="0">-select cuff model-</MenuItem>
            {cuffModelData
              ?.slice(0)
              ?.reverse()
              ?.map((item, i) => (
                <MenuItem key={i} value={item?._id}>
                  {item?.cuffModel}
                </MenuItem>
              )) || <MenuItem>No data</MenuItem>}
          </Select>
          {userRole === "admin" && (
            <p
              onClick={() => setAddCuffModal(true)}
              className="suffix-text-blue"
            >
              Add Cuff Model
            </p>
          )}
        </div> */}
        {
          cuttingSlipTemplate?.template == "template2"?
          <div className="global-single-input work-order-input">
          <p>Shoulder</p>
          <input
            tabIndex={cuttingSlipTemplate?.template == "template2" && "2"}
            value={formValues?.neckDButton}
            onChange={userRole !== "admin" && onChange("neckDButton")}
            placeholder="Enter Shoulder..."
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        :
        <div className="global-single-input work-order-input">
        <p>Collar</p>
        <Select
          value={formValues?.collar}
          onChange={userRole !== "admin" && onChange("collar")}
          disabled={userRole === "admin" ? true : false}
        >
          <MenuItem value="0">-select collar-</MenuItem>
          {collarData
            ?.slice(0)
            ?.reverse()
            ?.map((item, i) => (
              <MenuItem key={i} value={item?._id}>
                {item?.collar}
              </MenuItem>
            )) || <MenuItem>No data</MenuItem>}
        </Select>
        {userRole === "admin" && (
          <p onClick={() => setAddCollar(true)} className="suffix-text-blue">
            Add Collar
          </p>
        )}
      </div>
        }
       
        <div className="global-single-input work-order-input">
          <p>{cuttingSlipTemplate?.template == "template2" ? "L Shape":"Collar Size"}</p>
          <input
            tabIndex={cuttingSlipTemplate?.template == "template2" && "3"}
            value={formValues?.collarSize}
            onChange={userRole !== "admin" && onChange("collarSize")}
            // placeholder={userRole !== "admin" && "Enter ..."}
            placeholder={
              cuttingSlipTemplate?.template == "template2" ?
              "Enter L Shape..."
              :userRole !== "admin"?
              ""
              :"Collar Size..."
             }
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        {
           cuttingSlipTemplate?.template == "template2"?
           <div className="global-single-input work-order-input">
           <p>Sleeve</p>
           <input
             tabIndex={cuttingSlipTemplate?.template == "template2" && "6"}
             value={formValues?.collarCloth}
             onChange={userRole !== "admin" && onChange("collarCloth")}
             placeholder="Enter sleeve..."
             disabled={userRole === "admin" ? true : false}
           />
         </div>
         :
         <div className="global-single-input work-order-input">
         <p>Collar Cloth</p>
         <Select
           value={formValues?.collarCloth}
           onChange={userRole !== "admin" && onChange("collarCloth")}
           disabled={userRole === "admin" ? true : false}
         >
           <MenuItem value="0">-select collar cloth-</MenuItem>
           {collarClothData
             ?.slice(0)
             ?.reverse()
             ?.map((item, i) => (
               <MenuItem key={i} value={item?._id}>
                 {item?.collarCloth}
               </MenuItem>
             )) || <MenuItem>No data</MenuItem>}
         </Select>
         {userRole === "admin" && (
           <p
             onClick={() => setAddCollarCloth(true)}
             className="suffix-text-blue"
           >
             Add Collar Cloth
           </p>
         )}
       </div>
        }

        {
          cuttingSlipTemplate?.template == "template2" ?
          <div className="global-single-input work-order-input">
          <p>Sleeve Model</p>
          <input
            tabIndex={cuttingSlipTemplate?.template == "template2" && "7"}
            value={formValues?.collarModel}
            onChange={userRole !== "admin" && onChange("collarModel")}
            placeholder="Enter Sleeve Model..."
            disabled={userRole === "admin" ? true : false}
          />
        </div>
          :
          <div className="global-single-input work-order-input">
          <p>Collar Model</p>
          <Select
            value={formValues?.collarModel}
            onChange={userRole !== "admin" && onChange("collarModel")}
            disabled={userRole === "admin" ? true : false}
          >
            <MenuItem value="0">-select-</MenuItem>
            {collarModelData
              ?.slice(0)
              ?.reverse()
              ?.map((item, i) => (
                <MenuItem key={i} value={item?._id}>
                  {item?.collarModel}
                </MenuItem>
              )) || <MenuItem>No data</MenuItem>}
          </Select>
          {userRole === "admin" && (
            <p
              onClick={() => setAddCollarModal(true)}
              className="suffix-text-blue"
            >
             {cuttingSlipTemplate?.template == "template2" ? "Add Sleeve Model":"Add Collar Model"}
            </p>
          )}
        </div>
        }
       
        <div className="global-single-input work-order-input">
          <p>{cuttingSlipTemplate?.template == "template2" ? "Side":"Regal Size"}</p>
          <input
            tabIndex={cuttingSlipTemplate?.template == "template2" && "10"}
            value={formValues?.regalSize}
            onChange={userRole !== "admin" && onChange("regalSize")}
            // placeholder={userRole !== "admin" && "Enter regal size..."}
            placeholder={
              cuttingSlipTemplate?.template == "template2" ?
              "Enter Side..."
              :userRole !== "admin"?
              ""
              :"Enter Regal Size..."
             }
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>{cuttingSlipTemplate?.template == "template2" ? "Centre":"Knee Loose"}</p>
          <input
            tabIndex={cuttingSlipTemplate?.template == "template2" && "11"}
            value={formValues?.kneeLoose}
            onChange={userRole !== "admin" && onChange("kneeLoose")}
            // placeholder={userRole !== "admin" && "Enter knee loose..."}
            placeholder={
              cuttingSlipTemplate?.template == "template2" ?
              "Enter Centre..."
              :userRole !== "admin"?
              ""
              :"Enter Knee Loose..."
             }
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>{cuttingSlipTemplate?.template == "template2" ? "Mar":"FP Down"}</p>
          <input
            tabIndex={cuttingSlipTemplate?.template == "template2" && "14"}
            value={formValues?.fpDown}
            onChange={userRole !== "admin" && onChange("fpDown")}
            // placeholder={userRole !== "admin" && "Enter fp down..."}
            placeholder={
              cuttingSlipTemplate?.template == "template2" ?
              "Enter Mar..."
              :userRole !== "admin"?
              ""
              :"Enter FP Down..."
             }
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        {
             cuttingSlipTemplate?.template == "template2"?
             <div className="global-single-input work-order-input">
             <p>PT Down</p>
        
            <input
              tabIndex={cuttingSlipTemplate?.template == "template2" && "15"}
              className="work-order-input-group-first"
              value={formValues?.cuffSize1}
              onChange={userRole !== "admin" && onChange("cuffSize1")}
              placeholder={userRole !== "admin" && "Enter PT Down..."}
              disabled={userRole === "admin" ? true : false}
            />
           
            </div>
            :
            <div className="global-single-input work-order-input">
          <p>FP Model</p>
          <Select
            value={formValues?.fpModel}
            onChange={userRole !== "admin" && onChange("fpModel")}
            disabled={userRole === "admin" ? true : false}
          >
            <MenuItem value="0">-select -</MenuItem>
            {fpModelData
              ?.slice(0)
              ?.reverse()
              ?.map((item, i) => (
                <MenuItem key={i} value={item?._id}>
                  {item?.fpModel}
                </MenuItem>
              )) || <MenuItem>No data</MenuItem>}
          </Select>
          {userRole === "admin" && (
            <p onClick={() => setAddFpModel(true)} className="suffix-text-blue">
              {cuttingSlipTemplate?.template == "template2" ? "Add PT Down":"Add FP Model"}
            </p>
          )}
          {/* <input
            value={formValues?.fpModel}
            onChange={userRole !== "admin" && onChange("fpModel")}
            placeholder={userRole !== "admin" && "Enter fp model..."}
            disabled={userRole === "admin" ? true : false}
          /> */}
        </div>

        }
        
        {
          cuttingSlipTemplate?.template == "template2"?
          <div style={{display:"flex",width:"48%",margin:"1%"}}>
            <div className="global-single-input work-order-input">
              <p>PT Size</p>
        
              <input
              tabIndex={cuttingSlipTemplate?.template == "template2" && "18"}
              className="work-order-input-group-first"
              value={formValues?.fpSize1}
              onChange={userRole !== "admin" && onChange("fpSize1")}
              placeholder={userRole !== "admin" && "Enter pt size..."}
              disabled={userRole === "admin" ? true : false}
             />
          
        
            </div>
            <div className="global-single-input work-order-input">
         <p>PT Canvas</p>
         <Select
           value={formValues?.stitching}
           onChange={userRole !== "admin" && onChange("stitching")}
           disabled={userRole === "admin" ? true : false}
         >
           <MenuItem value="0">-select PT Canvas-</MenuItem>
           {stitchingData
             ?.slice(0)
             ?.reverse()
             ?.map((item, i) => (
               <MenuItem key={i} value={item?._id}>
                 {item?.stitching}
               </MenuItem>
             )) || <MenuItem>No data</MenuItem>}
         </Select>
         {userRole === "admin" && (
           <p
             onClick={() => setAddStitching(true)}
             className="suffix-text-blue"
           >
             Add PT Canvas
           </p>
         )}
            </div>

          </div>
         
        :
        <div className="global-single-input work-order-input">
        <p>FP Size</p>
        <div className="work-order-input-group">
          <input
            className="work-order-input-group-first"
            value={formValues?.fpSize1}
            onChange={userRole !== "admin" && onChange("fpSize1")}
            placeholder={userRole !== "admin" && "Enter fp size..."}
            disabled={userRole === "admin" ? true : false}
          />
          <input
            className="work-order-input-group-second"
            value={formValues?.fpSize2}
            onChange={userRole !== "admin" && onChange("fpSize2")}
            placeholder={userRole !== "admin" && "..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
      </div>
        }
      
        <div className="global-single-input work-order-input">
          <p>Pen</p>
          <Select
            value={formValues?.pen}
            onChange={userRole !== "admin" && onChange("pen")}
            disabled={userRole === "admin" ? true : false}
          >
            <MenuItem value="0">-select pen-</MenuItem>
            {penData
              ?.slice(0)
              ?.reverse()
              ?.map((item, i) => (
                <MenuItem key={i} value={item?._id}>
                  {item?.pen}
                </MenuItem>
              )) || <MenuItem>No data</MenuItem>}
          </Select>
          {userRole === "admin" && (
            <p onClick={() => setAddPen(true)} className="suffix-text-blue">
              Add Pen
            </p>
          )}
        </div>
        <div className="global-single-input work-order-input">
          <p>Side PT Model</p>
          <Select
            value={formValues?.sidePtModel}
            onChange={userRole !== "admin" && onChange("sidePtModel")}
            disabled={userRole === "admin" ? true : false}
          >
            <MenuItem value="0">-select side pt model-</MenuItem>
            {sidePtModelData
              ?.slice(0)
              ?.reverse()
              ?.map((item, i) => (
                <MenuItem key={i} value={item?._id}>
                  {item?.sidePT}
                </MenuItem>
              )) || <MenuItem>No data</MenuItem>}
          </Select>
          {userRole === "admin" && (
            <p
              onClick={() => setAddSidePTModal(true)}
              className="suffix-text-blue"
            >
              Add Side PT Model
            </p>
          )}
        </div>
        {
           cuttingSlipTemplate?.template == "template2"?
           <div className="global-single-input work-order-input">
           <p>Down,Veedi,Bag,Open</p>
          
             {/* <input
               tabIndex={cuttingSlipTemplate?.template == "template2" && "19"}
               className="work-order-input-group-second"
               value={formValues?.fpSize2}
               onChange={userRole !== "admin" && onChange("fpSize2")}
               placeholder={userRole !== "admin" && "Enter..."}
               disabled={userRole === "admin" ? true : false}
             /> */}
             <div style={{display:"flex"}}>
               <input 
                  type="text" 
                  style={{margin:"0px 2px"}}
                  value={formValues?.down}
                  onChange={userRole !== "admin" && onChange("down")}
                  />
               <input 
                 type="text" 
                 style={{margin:"0px 2px"}}
                 value={formValues?.veedi}
                 onChange={userRole !== "admin" && onChange("veedi")}
                 />
               <input 
                 type="text" 
                 style={{margin:"0px 2px"}}
                 value={formValues?.bag}
                 onChange={userRole !== "admin" && onChange("bag")}
                 />
               <input 
                  type="text" 
                  style={{margin:"0px 2px"}}
                  value={formValues?.open}
                  onChange={userRole !== "admin" && onChange("open")}
                  />
             </div>
             
           
         </div>
         :
         <div className="global-single-input work-order-input">
         <p>Stitching</p>
         <Select
           value={formValues?.stitching}
           onChange={userRole !== "admin" && onChange("stitching")}
           disabled={userRole === "admin" ? true : false}
         >
           <MenuItem value="0">-select stitching-</MenuItem>
           {stitchingData
             ?.slice(0)
             ?.reverse()
             ?.map((item, i) => (
               <MenuItem key={i} value={item?._id}>
                 {item?.stitching}
               </MenuItem>
             )) || <MenuItem>No data</MenuItem>}
         </Select>
         {userRole === "admin" && (
           <p
             onClick={() => setAddStitching(true)}
             className="suffix-text-blue"
           >
             Add Stitching
           </p>
         )}
       </div>
        }

        <div className="global-single-input work-order-input">
          <p>Button</p>
          <Select
            value={formValues?.button}
            onChange={userRole !== "admin" && onChange("button")}
            disabled={userRole === "admin" ? true : false}
          >
            <MenuItem value="0">-select button-</MenuItem>
            {buttonData
              ?.slice(0)
              ?.reverse()
              ?.map((item, i) => (
                <MenuItem key={i} value={item?._id}>
                  {item?.button}
                </MenuItem>
              )) || <MenuItem>No data</MenuItem>}
          </Select>
          {userRole === "admin" && (
            <p onClick={() => setAddButton(true)} className="suffix-text-blue">
              Add Button
            </p>
          )}
        </div>
        <div className="global-single-input work-order-input">
          <p>Button No</p>
          <input
            tabIndex={cuttingSlipTemplate?.template == "template2" && "20"}
            value={formValues?.buttonNo}
            onChange={userRole !== "admin" && onChange("buttonNo")}
            placeholder="Enter button no..."
            disabled={userRole === "admin" ? true : false}
          />
          {/* {userRole === "admin" && (
            <p
              onClick={() => setAddCuffModal(true)}
              className="suffix-text-blue"
            >
              Add Cuff Model
            </p>
          )} */}
        </div>
        <div className="global-single-input work-order-input">
          <p>Mobile Pocket</p>
          <Select
            value={formValues?.mobilePocket}
            onChange={userRole !== "admin" && onChange("mobilePocket")}
            // placeholder="Enter side pt size..."
            disabled={userRole === "admin" ? true : false}
          >
            <MenuItem value='yes'>Yes</MenuItem>
            <MenuItem value='no'>No</MenuItem>
            </Select>
          {/* {userRole === "admin" && (
            <p
              onClick={() => setAddCuffModal(true)}
              className="suffix-text-blue"
            >
              Add Cuff Model
            </p>
          )} */}
        </div>
        <div className="global-single-input work-order-input" style={{position:"relative"}}>
          <p>Notes</p>
          <input
            tabIndex={cuttingSlipTemplate?.template == "template2" && "22"}
            value={formValues?.remark}
            onChange={userRole !== "admin" && onChange("remark")}
            placeholder={userRole !== "admin" && "Enter notes..."}
            disabled={userRole === "admin" ? true : false}
          />
          {/* <button className="work-order-notes-add-button">+</button>
          <div className="work-order-notes-content">
            <p>abc</p>
            <p>abc</p>
            <p>abc</p>
          </div> */}
        </div>
      </div>

      {/* add thob model dialog */}
      <Dialog
        open={thobModelDialog}
        maxWidth="lg"
        onKeyDown={(e) => e?.key === "Escape" && setThobModelDialog(false)}
      >
        <div className="add-thob-model-dialog-container">
          <h3>Add Thob Model</h3>
          <div className="add-thob-model-dialog-input-container">
            <div className="global-single-input">
              <p>Model Name</p>
              <input
                placeholder="New model name..."
                type="text"
                value={thobModelForm?.modelName}
                onChange={getThobModelForm("modelName")}
              />
              {modelNameAlert?
                <p className="doc-validation-alert">Fill this field !!</p>
              :""}
            </div>
            <div className="global-single-input from-length">
              <p>Length From</p>
              <input
                type="text"
                placeholder="Length from..."
                value={thobModelForm?.lengthFrom}
                onChange={getThobModelForm("lengthFrom")}
              />
              {lengthFromAlert?
                <p className="doc-validation-alert">Fill this field !!</p>
              :""}
            </div>
            <div className="global-single-input from-length">
              <p>To</p>
              <input
                type="text"
                placeholder="Length to..."
                value={thobModelForm?.lengthTo}
                onChange={getThobModelForm("lengthTo")}
              />
              {lengthToAlert?
                <p className="doc-validation-alert">Fill this field !!</p>
              :""}
            </div>
            <div className="global-single-input from-length">
              <p>Min Rate</p>
              <input
                type="text"
                placeholder="Minimum rate..."
                value={thobModelForm?.minRate}
                onChange={getThobModelForm("minRate")}
              />
            </div>
            <div className="global-single-input from-length">
              <p>Commission</p>
              <input
                type="text"
                placeholder="Commission..."
                value={thobModelForm?.commission}
                onChange={getThobModelForm("commission")}
                onKeyDown={(e) => {
                  e.key === "Enter" && addThobModel();
                }}
              />
            </div>
            <button
              disabled={thobModelForm?.modelName !== "" ? false : true}
              className={thobModelForm?.modelName !== "" ? "" : "disabled"}
              onClick={addThobModel}
            >
              +
            </button>
          </div>
          <div className="add-popup-table">
            <table>
              <thead>
                <tr>
                  <th>Model Name</th>
                  <th>Length(From-To)</th>
                  <th>Min Rate</th>
                  <th>Commission</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {thobModelData
                  ?.slice(0)
                  ?.reverse()
                  ?.map((r, i) => (
                    <tr key={i}>
                      <td>{r?.thobName}</td>
                      <td>{`${r?.fromLength}-${r?.toLength}`}</td>
                      <td>{r?.minRate}</td>
                      <td>{r?.commission}</td>
                      <td>
                        <IconButton onClick={deleteThobModel(r?._id)}>
                          <i class="bi bi-trash3 delete-icon"></i>
                        </IconButton>
                      </td>
                    </tr>
                  )) || (
                  <tr>
                    <td colSpan={5}>No Data</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="add-popup-button-container">
            <button
              onClick={() => setThobModelDialog(false)}
              className="btn btn-secondary"
            >
              Close
            </button>
          </div>
        </div>
      </Dialog>

      {/* add length commission */}
      <Dialog open={addLengthCommission} maxWidth="lg"  onKeyDown={(e)=>e.key === "Escape" && setAddLengthCommission(false)}>
        <div className="add-thob-model-dialog-container">
          <h3>Add Length Commission</h3>
          <div className="add-thob-model-dialog-input-container">
            <div className="global-single-input from-length">
              <p>Length From</p>
              <input
                type="text"
                placeholder="Length from..."
                value={lengthCommissionForm?.lengthFrom}
                onChange={getLengthCommissionForm("lengthFrom")}
              />
              {lengthFromAlert?
                <p className="doc-validation-alert">Fill this field</p>
              :""}
            </div>
            <div className="global-single-input from-length">
              <p>To</p>
              <input
                type="text"
                placeholder="Length to..."
                value={lengthCommissionForm?.lengthTo}
                onChange={getLengthCommissionForm("lengthTo")}
              />
              {lengthToAlert?
                <p className="doc-validation-alert">Fill this field</p>
              :""}
            </div>
            <div className="global-single-input from-length">
              <p>Used Qty</p>
              <input
                type="text"
                placeholder="Used Qty..."
                value={lengthCommissionForm?.usedQty}
                onChange={getLengthCommissionForm("usedQty")}
              />
              {usedQtyAlert?
                <p className="doc-validation-alert">Fill this field</p>
              :""}
            </div>
            <div className="global-single-input from-length">
              <p>Min Rate</p>
              <input
                type="text"
                placeholder="Minimum rate..."
                value={lengthCommissionForm?.minRate}
                onChange={getLengthCommissionForm("minRate")}
              />
            </div>
            <div className="global-single-input from-length">
              <p>Commission</p>
              <input
                type="text"
                placeholder="Commission..."
                value={lengthCommissionForm?.commission}
                onChange={getLengthCommissionForm("commission")}
                onKeyDown={(e) => e.key === "Enter" && addNewLengthCommission()}
              />
            </div>
            <button onClick={addNewLengthCommission}>+</button>
          </div>
          <div className="add-popup-table">
            <table>
              <thead>
                <tr>
                  <th>Length(From-To)</th>
                  <th>Used Qty</th>
                  <th>Min Rate</th>
                  <th>Commission</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {lengthCommissionData
                  ?.slice(0)
                  ?.reverse()
                  ?.map((r, i) => (
                    <tr key={i}>
                      <td>{`${r?.fromLength} - ${r?.toLength}`}</td>
                      <td>{r?.usedQty}</td>
                      <td>{r?.minRate}</td>
                      <td>{r?.commission}</td>
                      <td>
                        <IconButton onClick={deleteLengthCommission(r?._id)}>
                          <i class="bi bi-trash3 delete-icon"></i>
                        </IconButton>
                      </td>
                    </tr>
                  )) || (
                  <tr>
                    <td colSpan={5}>No Data</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="add-popup-button-container">
            <button
              onClick={() => setAddLengthCommission(false)}
              className="btn btn-secondary"
            >
              Close
            </button>
          </div>
        </div>
      </Dialog>

      {/* add mar modal */}
      <AddCategoryPopup
        open={addMarModal}
        label="Add Mar Modal"
        closePopup={() => setAddMarModal(false)}
        onChange={getMarModel}
        value={newMarModelName}
        addBtnClick={addMarModel}
      >
        {marModelData
          ?.slice(0)
          ?.reverse()
          ?.map((r, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{r?.marName}</td>
              <td>
                <IconButton onClick={deleteMarModel(r?._id)}>
                  <i class="bi bi-trash3 delete-icon"></i>
                </IconButton>
              </td>
            </tr>
          )) || (
          <tr>
            <td colSpan={3}>No Data</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* add cuff */}
      <AddCategoryPopup
        open={addCuff}
        label="Add Cuff"
        closePopup={() => setAddCuff(false)}
        onChange={getCuff}
        value={newCuffName}
        addBtnClick={addNewCuff}
      >
        {cuffData
          ?.slice(0)
          ?.reverse()
          ?.map((r, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{r?.cuff}</td>
              <td>
                <IconButton onClick={deleteCuff(r?._id)}>
                  <i class="bi bi-trash3 delete-icon"></i>
                </IconButton>
              </td>
            </tr>
          )) || (
          <tr>
            <td colSpan={3}>No Data</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* add cuff Cloth */}
      <AddCategoryPopup
        open={addCuffCloth}
        label="Add Cuff Cloth"
        closePopup={() => setAddCuffCloth(false)}
        onChange={getCuffCloth}
        value={newCuffCloth}
        addBtnClick={addNewCuffCloth}
      >
        {cuffClothData
          ?.slice(0)
          ?.reverse()
          ?.map((r, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{r?.cuffCloth}</td>
              <td>
                <IconButton onClick={deleteCuffCloth(r?._id)}>
                  <i class="bi bi-trash3 delete-icon"></i>
                </IconButton>
              </td>
            </tr>
          )) || (
          <tr>
            <td colSpan={3}>No Data</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* add cuff modal */}
      <AddCategoryPopup
        open={addCuffModal}
        label="Add Cuff Modal"
        closePopup={() => setAddCuffModal(false)}
        onChange={getCuffModel}
        value={newCuffModel}
        addBtnClick={addNewCuffModel}
      >
        {cuffModelData
          ?.slice(0)
          ?.reverse()
          ?.map((r, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{r?.cuffModel}</td>
              <td>
                <IconButton onClick={deleteCuffModel(r?._id)}>
                  <i class="bi bi-trash3 delete-icon"></i>
                </IconButton>
              </td>
            </tr>
          )) || (
          <tr>
            <td colSpan={3}>No data</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* add collar */}
      <AddCategoryPopup
        open={addCollar}
        label="Add Collar"
        closePopup={() => setAddCollar(false)}
        onChange={getCollar}
        value={newCollar}
        addBtnClick={addNewCollar}
      >
        {collarData
          ?.slice(0)
          ?.reverse()
          ?.map((r, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{r?.collar}</td>
              <td>
                <IconButton onClick={deleteCollar(r?._id)}>
                  <i class="bi bi-trash3 delete-icon"></i>
                </IconButton>
              </td>
            </tr>
          )) || (
          <tr>
            <td colSpan={3}>No data</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* add collar Cloth */}
      <AddCategoryPopup
        open={addCollarCloth}
        label="Add Collar Cloth"
        closePopup={() => setAddCollarCloth(false)}
        onChange={getCollarCloth}
        value={collarCloth}
        addBtnClick={addNewCollarCloth}
      >
        {collarClothData
          ?.slice(0)
          ?.reverse()
          ?.map((r, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{r?.collarCloth}</td>
              <td>
                <IconButton onClick={deleteCollarCloth(r?._id)}>
                  <i class="bi bi-trash3 delete-icon"></i>
                </IconButton>
              </td>
            </tr>
          )) || (
          <tr>
            <td colSpan={3}>No Data</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* add collar modal */}
      <AddCategoryPopup
        open={addCollarModal}
        label="Add Collar Modal"
        closePopup={() => setAddCollarModal(false)}
        onChange={getCollarModel}
        value={collarModel}
        addBtnClick={addCollarModel}
      >
        {collarModelData
          ?.slice(0)
          ?.reverse()
          ?.map((r, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{r?.collarModel}</td>
              <td>
                <IconButton onClick={deleteCollarModel(r?._id)}>
                  <i class="bi bi-trash3 delete-icon"></i>
                </IconButton>
              </td>
            </tr>
          )) || (
          <tr>
            <td colSpan={3}>No data</td>
          </tr>
        )}
      </AddCategoryPopup>

{/* add fp model */}
      <AddCategoryPopup
        open={addFpModel}
        label="Add FP Model"
        closePopup={() => setAddFpModel(false)}
        onChange={getFpModel}
        value={fpModel}
        addBtnClick={addNewFpModel}
      >
        {fpModelData
          ?.slice(0)
          ?.reverse()
          ?.map((r, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{r?.fpModel}</td>
              <td>
                <IconButton onClick={deleteFpModel(r?._id)}>
                  <i class="bi bi-trash3 delete-icon"></i>
                </IconButton>
              </td>
            </tr>
          )) || (
          <tr>
            <td colSpan={3}>No data</td>
          </tr>
        )}
      </AddCategoryPopup>




      {/* add pen */}
      <AddCategoryPopup
        open={addPen}
        label="Add Pen"
        closePopup={() => setAddPen(false)}
        onChange={getPenName}
        value={newPenName}
        addBtnClick={addNewPen}
      >
        {penData
          ?.slice(0)
          ?.reverse()
          ?.map((r, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{r?.pen}</td>
              <td>
                <IconButton onClick={deletePen(r?._id)}>
                  <i class="bi bi-trash3 delete-icon"></i>
                </IconButton>
              </td>
            </tr>
          )) || (
          <tr>
            <td colSpan={3}>No data</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* add side PT modal */}
      <AddCategoryPopup
        open={addSidePTModal}
        label="Add Side PT Modal"
        closePopup={() => setAddSidePTModal(false)}
        onChange={getSidePtModel}
        value={newSidePtModel}
        addBtnClick={addNewSidePtModel}
      >
        {sidePtModelData
          ?.slice(0)
          ?.reverse()
          ?.map((r, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{r?.sidePT}</td>
              <td>
                <IconButton onClick={deleteSidePtModel(r?._id)}>
                  <i class="bi bi-trash3 delete-icon"></i>
                </IconButton>
              </td>
            </tr>
          )) || (
          <tr>
            <td colSpan={3}>No data</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* add stitching */}
      <AddCategoryPopup
        open={addStitching}
        label="Add Stitching"
        closePopup={() => setAddStitching(false)}
        onChange={getStitching}
        value={newStitching}
        addBtnClick={addNewStitching}
      >
        {stitchingData
          ?.slice(0)
          ?.reverse()
          ?.map((r, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{r?.stitching}</td>
              <td>
                <IconButton onClick={deleteStitching(r?._id)}>
                  <i class="bi bi-trash3 delete-icon"></i>
                </IconButton>
              </td>
            </tr>
          )) || (
          <tr>
            <td colSpan={3}>No data</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* add button */}
      <AddCategoryPopup
        open={addButton}
        label="Add Button"
        closePopup={() => setAddButton(false)}
        onChange={getButton}
        value={newButton}
        addBtnClick={addNewButton}
      >
        {buttonData
          ?.slice(0)
          ?.reverse()
          ?.map((r, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{r?.button}</td>
              <td>
                <IconButton onClick={deleteButton(r?._id)}>
                  <i class="bi bi-trash3 delete-icon"></i>
                </IconButton>
              </td>
            </tr>
          )) || (
          <tr>
            <td colSpan={3}>No data</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* SnackBars  */}
      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </div>
  );
};
