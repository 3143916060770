import React, { useEffect, useState } from 'react'
import { viewAllCurrencyUpdated } from '../../../../API/Settings/Currency/CurrencyAPI';
import { companyProfileInfoForUser } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import { filterObjFromList } from '../../../../Js/generalFunctions';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IconButton, Skeleton } from '@mui/material';
import CustomPagination from '../../../Single Components/CustomPagination';
import { convertDateFormat } from '../../../../Js/Date';
import CategorySearchandFilter from '../../../Single Components/CategorySearchandFilter';
import { ShiftReportSingleViewAPIcall } from './ShiftReportAPI';

function ShiftReportSingleView() {
    let navigate = useNavigate();
    let location = useLocation()
    const filtereDataOptions = useSelector((state) => state.listCategory);
    const currencyListUpdated = useSelector(
        (state) => state.viewUpdatedCurrency.value
    );
    const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue)

    const SingleListData = useSelector((state) => state?.shiftReport?.shiftReportSingleList)

    const [FormDataInfo, setFormDataInfo] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const handleBranchSelect = (selectedBranch) => {
        setSelectedBranch(selectedBranch);

    };
    const [symbol, setSymbol] = useState(null);
    const handleDataFromChild = (data) => {
        setFormDataInfo(data);
    };

    const totalPages = SingleListData?.pages;
    const decimalPosition = localStorage.getItem("decimalPosition");

    const handlePageChange = (page) => {

        setCurrentPage(page);
    };
    const SingleViewId = localStorage.getItem("SingleViewId")
    const handleClick = (row) => {
        navigate("/userdashboard/report/shiftReport/singleView")
    };
    useEffect(() => {
        let currencyObj = filterObjFromList("_id", currencyListUpdated, "currency", companyProfileData)
        setSymbol(currencyObj?.symbol)
    }, [companyProfileData, currencyListUpdated])

    useEffect(() => {
        viewAllCurrencyUpdated({ status: 0 });
        companyProfileInfoForUser();
    }, [])

    useEffect(() => {
        if (FormDataInfo?.length !== 0) {
        ShiftReportSingleViewAPIcall({
          _id: SingleViewId,
          fromDate: FormDataInfo?.fromDate === '' ? '' : `${FormDataInfo?.fromDate} 00:00:00`,
          toDate: FormDataInfo?.toDate === '' ? '' : `${FormDataInfo?.toDate} 23:59:59`,
          branchId: FormDataInfo?.branchList,
          search: FormDataInfo?.search,
          index:currentPage-1
    
        })
    }
      }, [currentPage,FormDataInfo,SingleViewId])

    return (
        <div className="global-page-parent-container">
            {/* table */}
            <div className="global-white-bg-container">
                <div className='create-button-blue-container' style={{ margin: "1%", display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>

                    <div style={{ marginLeft: '1%' }}>
                        <h3>Shift Report</h3>
                    </div>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        //   width: '100%'
                    }}>
                        <CategorySearchandFilter
                            onData={handleDataFromChild}
                            style={{ width: "100%" }}


                            onBranchSelect={handleBranchSelect}


                        />
                        <IconButton
                            style={{ width: "100px" }}

                            className="print-icon-container">
                            <i class="bi bi-printer printer-icon"></i>
                        </IconButton>
                        <IconButton
                            style={{ width: "100px" }}
                            className="download-icon-container">
                            <i class="bi bi-arrow-down-circle download-icon"></i>
                        </IconButton>
                    </div>

                </div>


                <div className="create-button-blue-container">
                    <h3 style={{ marginLeft: '1%' }}>Branch Name/ 02-03-2024 TO 04-03-2024/ SHF22</h3>
                    <CustomPagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </div>
                <div className="customer-receipt-table-container">
                    <table>
                        <thead>
                            <tr>

                                <th rowSpan="2" >CUSTOMER</th>
                                <th rowSpan="2" >PHONE</th>
                                <th rowSpan="2" >REFERENCE</th>
                                <th rowSpan="2" >INVOICE AMOUNT</th>
                                <th rowSpan="2" >PAID</th>
                                <th rowSpan="2" >BANK</th>
                                <th rowSpan="2" >CHEQUE</th>
                                <th rowSpan="2" >CASH</th>
                                <th rowSpan="2" >BALANCE</th>
                            </tr>
                           

                        </thead>
                        <tbody>
                          
                            {isLoading ? (
      [...Array(10)].map((r, i) => (
        <tr key={i}>
          <td colSpan={10}>
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={40}
            />
          </td>
        </tr>
      ))
    ) : SingleListData?.report !== undefined && SingleListData?.report?.length !== 0 ? (
      SingleListData?.report
        ?.slice(0)
        ?.map((r, i) => (
          <tr key={i} onClick={()=>handleClick(r)} >                  
            <td>{r?.customer}</td>
            <td>{r?.customerMobile}</td>
            <td>{r?.referance}</td>
            <td>{r?.invAmount}</td>
            <td>{r?.paidAmount}</td>
            <td>{r?.bank}</td>
            <td>{r?.cheque}</td>
            <td>{r?.cash}</td>
            <td>{r?.balance}</td>
          </tr>
        ))
    ) : (
      <tr>
        <td style={{ textAlign: 'center' }} colSpan={10}>No Data</td>
      </tr>
    )}

                        </tbody>
                        <tfoot >
                           
                            <tr>
                        <th style={{ textAlign: 'start', fontSize: '1rem', paddingLeft: '3%' }} colSpan={3}>TOTAL</th>
                        <th >{SingleListData?.total !== undefined && SingleListData?.total?.invAmount?.toFixed(decimalPosition)}</th>
                        <th >{SingleListData?.total !== undefined && SingleListData?.total?.paidAmount?.toFixed(decimalPosition)}</th>
                        <th >{SingleListData?.total !== undefined && SingleListData?.total?.bank?.toFixed(decimalPosition)}</th>
                        <th >{SingleListData?.total !== undefined && SingleListData?.total?.cheque?.toFixed(decimalPosition)}</th>
                        <th >{SingleListData?.total !== undefined && SingleListData?.total?.cash?.toFixed(decimalPosition)}</th>
                        <th >{SingleListData?.total !== undefined && SingleListData?.total?.balance?.toFixed(decimalPosition)}</th>
                      
                    </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ShiftReportSingleView