import {createSlice} from '@reduxjs/toolkit'

export const pettyCashReceiptDocNoSlice=createSlice({
    name:"pettyCashReceiptDocNoSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        get_petty_cash_docNo:(state,action)=>{
            state.value=action.payload.pettyCashDocNo
        }
    }
})
export const {get_petty_cash_docNo}=pettyCashReceiptDocNoSlice.actions;
export default pettyCashReceiptDocNoSlice.reducer