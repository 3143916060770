import { Dialog, IconButton, MenuItem, Select, Skeleton } from "@mui/material";
import React from "react";
import { useState } from "react";
import "../../../../css/Staff/Loan/staffLoanReport.css"
import addLoanTableImg from "../../../../Assets/Images/upload-image.png"
import DocImgTable from "../../../../Assets/Images/PaymentDoc.png"
export const StaffLoanReport= ()=>{

    const[loanReportView,setLoanReportView]=useState(false);
    // loading states
    const [isLoading,setIsLoading]=useState(false)
    return(
        <>
         <div className="global-page-parent-container">
            <p className="breadcrumb">Staff &gt; Loan &gt; Loan report</p>
            <div className="staff-loan-report-container">
                <h3>Loan</h3>
                <table className="global-table">
                    <thead>
                        <tr>
                            <th>Loan ID</th>
                            <th>Employee ID</th>
                            <th>Name</th>
                            <th>Image</th>
                            <th>Mobile</th>
                            <th>Loan Amount</th>
                            <th>Interest</th>
                            <th>Actual Loan Amount</th>
                            <th>View</th>
                            <th>Download</th>
                        </tr>
                    </thead>
                    {
                    isLoading?
                    <tbody>
                        <tr>
                        <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                        </td>
                        </tr> 
                        <tr>
                        <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                        </td>
                        </tr> 
                        <tr>
                        <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                        </td>
                        </tr> 
                        <tr>
                        <td colSpan={10}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                        </td>
                        </tr>
                    </tbody>:

                    <tbody>
                        <tr>
                            <td>SLQ2102</td>
                            <td>EMS098</td>
                            <td>Nijad</td>
                            <td><img src="" alt="Emp Img" /></td>
                            <td>9495896461</td>
                            <td>1,00,000</td>
                            <td>5%</td>
                            <td>1,12,000</td>
                            <td><IconButton onClick={()=>{setLoanReportView(true)}}><i class="bi bi-eye visibility-icon"></i></IconButton></td>
                            <td><IconButton><i class="bi bi-arrow-down-circle" style={{color:'#f19800'}}></i></IconButton></td>
                        </tr>
                        <tr>
                            <td>SLQ2102</td>
                            <td>EMS098</td>
                            <td>Nijad</td>
                            <td><img src="" alt="Emp Img" /></td>
                            <td>9495896461</td>
                            <td>1,00,000</td>
                            <td>5%</td>
                            <td>1,12,000</td>
                            <td><IconButton onClick={()=>{setLoanReportView(true)}}><i class="bi bi-eye visibility-icon"></i></IconButton></td>
                            <td><IconButton><i class="bi bi-arrow-down-circle" style={{color:'#f19800'}}></i></IconButton></td>
                        </tr>
                        <tr>
                            <td>SLQ2102</td>
                            <td>EMS098</td>
                            <td>Nijad</td>
                            <td><img src="" alt="Emp Img" /></td>
                            <td>9495896461</td>
                            <td>1,00,000</td>
                            <td>5%</td>
                            <td>1,12,000</td>
                            <td><IconButton onClick={()=>{setLoanReportView(true)}}><i class="bi bi-eye visibility-icon"></i></IconButton></td>
                            <td><IconButton><i class="bi bi-arrow-down-circle" style={{color:'#f19800'}}></i></IconButton></td>
                        </tr>
                        <tr>
                            <td>SLQ2102</td>
                            <td>EMS098</td>
                            <td>Nijad</td>
                            <td><img src="" alt="Emp Img" /></td>
                            <td>9495896461</td>
                            <td>1,00,000</td>
                            <td>5%</td>
                            <td>1,12,000</td>
                            <td><IconButton onClick={()=>{setLoanReportView(true)}}><i class="bi bi-eye visibility-icon"></i></IconButton></td>
                            <td><IconButton><i class="bi bi-arrow-down-circle" style={{color:'#f19800'}}></i></IconButton></td>
                        </tr>
                        <tr>
                            <td>SLQ2102</td>
                            <td>EMS098</td>
                            <td>Nijad</td>
                            <td><img src="" alt="Emp Img" /></td>
                            <td>9495896461</td>
                            <td>1,00,000</td>
                            <td>5%</td>
                            <td>1,12,000</td>
                            <td><IconButton onClick={()=>{setLoanReportView(true)}}><i class="bi bi-eye visibility-icon"></i></IconButton></td>
                            <td><IconButton><i class="bi bi-arrow-down-circle" style={{color:'#f19800'}}></i></IconButton></td>
                        </tr>
                        <tr>
                            <td>SLQ2102</td>
                            <td>EMS098</td>
                            <td>Nijad</td>
                            <td><img src="" alt="Emp Img" /></td>
                            <td>9495896461</td>
                            <td>1,00,000</td>
                            <td>5%</td>
                            <td>1,12,000</td>
                            <td><IconButton onClick={()=>{setLoanReportView(true)}}><i class="bi bi-eye visibility-icon"></i></IconButton></td>
                            <td><IconButton><i class="bi bi-arrow-down-circle" style={{color:'#f19800'}}></i></IconButton></td>
                        </tr>
                        <tr>
                            <td>SLQ2102</td>
                            <td>EMS098</td>
                            <td>Nijad</td>
                            <td><img src="" alt="Emp Img" /></td>
                            <td>9495896461</td>
                            <td>1,00,000</td>
                            <td>5%</td>
                            <td>1,12,000</td>
                            <td><IconButton onClick={()=>{setLoanReportView(true)}}><i class="bi bi-eye visibility-icon"></i></IconButton></td>
                            <td><IconButton><i class="bi bi-arrow-down-circle" style={{color:'#f19800'}}></i></IconButton></td>
                        </tr>
                        <tr>
                            <td>SLQ2102</td>
                            <td>EMS098</td>
                            <td>Nijad</td>
                            <td><img src="" alt="Emp Img" /></td>
                            <td>9495896461</td>
                            <td>1,00,000</td>
                            <td>5%</td>
                            <td>1,12,000</td>
                            <td><IconButton onClick={()=>{setLoanReportView(true)}}><i class="bi bi-eye visibility-icon"></i></IconButton></td>
                            <td><IconButton><i class="bi bi-arrow-down-circle" style={{color:'#f19800'}}></i></IconButton></td>
                        </tr>
                        <tr>
                            <td>SLQ2102</td>
                            <td>EMS098</td>
                            <td>Nijad</td>
                            <td><img src="" alt="Emp Img" /></td>
                            <td>9495896461</td>
                            <td>1,00,000</td>
                            <td>5%</td>
                            <td>1,12,000</td>
                            <td><IconButton onClick={()=>{setLoanReportView(true)}}><i class="bi bi-eye visibility-icon"></i></IconButton></td>
                            <td><IconButton><i class="bi bi-arrow-down-circle" style={{color:'#f19800'}}></i></IconButton></td>
                        </tr>
                    </tbody>
                }

                </table>
            </div>

            <Dialog open={loanReportView} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setLoanReportView(false)}>
                <div className="staff-add-new-loan-ctn staff-loan-report-view-dialog">
                    <h2>Loan ID : EMPWHL001</h2>
                    <div className="staff-add-new-loan-ctn-section">
                        <h4>Employee Details</h4>
                        <div className="staff-add-new-loan-ctn-section-container">
                            <div className="staff-add-new-loan-ctn-section-column">
                                <div className="global-single-input">
                                    <p>Employee Name</p>
                                    <input type="text" placeholder="Nihad Ali" />
                                </div>
                                <div className="global-single-input">
                                    <p>Department</p>
                                    <input type="text" placeholder="Account" />
                                </div>
                                <div className="global-single-input">
                                    <p>Branch Name</p>
                                    <input type="text" placeholder="Calicut" />
                                </div>
                            </div>
                            <div className="staff-add-new-loan-ctn-section-column">
                                <div className="global-single-input">
                                    <p>Employee ID</p>
                                    <input type="text" placeholder="EMP5462" />
                                </div>
                                <div className="global-single-input">
                                    <p>Designation</p>
                                    <input type="text" placeholder="Cashier" />
                                </div>
                            </div>
                            <div className="staff-add-new-loan-ctn-section-column">
                                <div className="global-single-input">
                                    <p>Contact</p>
                                    <input type="text" placeholder="945668722" />
                                </div>
                                <div className="global-single-input">
                                    <p>Department Manager</p>
                                    <input type="text" placeholder="Nihad" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="staff-add-new-loan-ctn-section">
                        <h4>Loan Details</h4>
                        <div className="staff-add-new-loan-ctn-section-container">
                            <div className="staff-add-new-loan-ctn-section-column">
                                <div className="global-single-input">
                                    <p>Payment Method</p>
                                    <input type="text" placeholder="Salary" />
                                </div>
                                <div className="global-single-input">
                                    <p>Start Date</p>
                                    <input type="text" placeholder="17-10-2022" />
                                </div>
                                <div className="global-single-input">
                                    <p>Interest Amount</p>
                                    <input type="text" placeholder="10000" />
                                </div>
                                <div className="global-single-input">
                                    <p>Interest Type</p>
                                    <input type="text" placeholder="Compounding Interest" />
                                </div>
                            </div>
                            <div className="staff-add-new-loan-ctn-section-column">
                                <div className="global-single-input">
                                    <p>Loan Type</p>
                                    <input type="text" placeholder="Personal Loan" />
                                </div>
                                <div className="global-single-input">
                                    <p>Interest Rate</p>
                                    <input type="text" placeholder="4.5%" />
                                </div>
                                <div className="global-single-input">
                                    <p>End Date</p>
                                    <input type="text" placeholder="12-12-2022" />
                                </div>
                                <div className="global-single-input">
                                    <p>Remaining Amount</p>
                                    <input type="text" placeholder="40000" />
                                </div>
                            </div>
                            <div className="staff-add-new-loan-ctn-section-column">
                                <div className="global-single-input">
                                    <p>Loan Amount</p>
                                    <input type="text" placeholder="100000" />
                                </div>
                                <div className="global-single-input">
                                    <p>Tenure</p>
                                    <input type="text" placeholder="10 Months" />
                                </div>
                                <div className="global-single-input">
                                    <p>Extra Interest Amount</p>
                                    <input type="text" placeholder="10000" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="staff-add-new-loan-ctn-section">
                        <h4>Bank Details</h4>
                        <div className="staff-add-new-loan-ctn-section-container">
                            <div className="staff-add-new-loan-ctn-section-column">
                                <div className="global-single-input">
                                    <p>Bank Name</p>
                                    <input type="text" placeholder="SBI" />
                                </div>
                                <div className="global-single-input">
                                    <p>IFSC Code</p>
                                    <input type="text" placeholder="SIBL00021" />
                                </div>
                            </div>
                            <div className="staff-add-new-loan-ctn-section-column">
                                <div className="global-single-input">
                                    <p>Account Holder Name</p>
                                    <input type="text" placeholder="NIhad ali" />
                                </div>
                                <div className="global-single-input">
                                    <p>Branch</p>
                                    <input type="text" placeholder="Kannur" />
                                </div>
                            </div>
                            <div className="staff-add-new-loan-ctn-section-column">
                                <div className="global-single-input">
                                    <p>Account No</p>
                                    <input type="text" placeholder="126945668722" />
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="global-table-container">
                        <h3>Documents</h3>
                        <table className="global-table staff-add-loan-table">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Document No</th>
                                    <th>Image</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>PAN Card</td>
                                    <td>GHSCERT</td>
                                    <td><img src={DocImgTable} alt="Doc Img" /></td>
                                    <td><IconButton disabled="disabled">
                                            <i class="bi bi-trash3 delete-icon"></i>
                                        </IconButton></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="staff-add-new-loan-ctn-bottom">
                        <button className="btn btn-secondary" onClick={()=>{setLoanReportView(false)}}>Close</button>
                    </div>
                </div>
            </Dialog>
         </div>
        </>
    );
};