import axios from "axios";
import { HEADERS, HEADER_FORM_DATA } from "../../../../../API/UrlAndPaths";
import store from "../../../../../Redux/store";
import { generate_sales_order_quot_name, get_sales_order_quot_res_list, get_sales_order_quot_row_data_list,generate_sales_filter_quot, get_template_design } from "./salesQuatationSlice";
import { get_sales_order_row_data_list } from "../SalesOrder/salesOrderSlice";
import { salesOrderSingleViewAPICall } from "../SalesOrder/salesOrderAPI";
// sales order quatation listing
export const viewSalesOrdersQuatationAPICall=async(body,setIsLoading)=>{
  setIsLoading !== undefined && setIsLoading(true)
  await axios
    .post(`sales/viewQuotation`,body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(get_sales_order_quot_res_list({orderQuotationData:res.data}))
        setIsLoading !== undefined && setIsLoading(false)
      }
    })
    .catch((err) => {
        setIsLoading !== undefined && setIsLoading(false)
        store.dispatch(get_sales_order_quot_res_list({orderQuotationData:undefined}))
    });
}


// generate vquatation name
export const generateSalesQuatationAPICall=async(body)=>{
    await axios
    .post(`account/generateVendorBillNo`,body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(generate_sales_order_quot_name({salesQuotname:res.data}))
      }
    })
    .catch((err) => {
        store.dispatch(generate_sales_order_quot_name({salesQuotname:undefined}))
    });
}
// draft sales quatation api call

export const draftSalesQuatationAPICall=async(body,postFn,setState,state,setEditDataId,setLoadingForm)=>{
  setLoadingForm!==undefined && setLoadingForm(true)

    await axios.post("sales/quotationAdd",body,HEADER_FORM_DATA)
     .then((res)=>{
        if(res.status===200){
          setLoadingForm!==undefined && setLoadingForm(false)
          postFn()
          setEditDataId(res.data?._id)
          setState({...state,message:"quatation drafted",success:true})
          localStorage.setItem("singleViewById",res.data._id)
        }
     })
     .catch((err)=>{
      setLoadingForm!==undefined && setLoadingForm(false)
      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
      } else {
        setState({ ...state, message: err?.response?.data, error: true })
      }
     })
}
// // edit quatation api call

export const editSalesQuatationAPICall=async(body,postFn,setState,state,setLoadingForm)=>{
  setLoadingForm!==undefined && setLoadingForm(true)

  await axios.post("sales/editQuotation",body,HEADER_FORM_DATA)
   .then((res)=>{
      if(res.status===200){
       setLoadingForm!==undefined && setLoadingForm(false)

        setState({...state,message:"sales quatation edited",success:true})
          postFn()
      }
   })
   .catch((err)=>{
    setLoadingForm!==undefined && setLoadingForm(false)

    if (err.response.status === 500) {
      setState({ ...state, message: "Internal Server error !", error: true })
    } else {
      setState({ ...state, message: err?.response?.data, error: true })
    }
   })
}

// // confirm quatation api call

export const confirmSalesQuatationAPICall=async(body,postFn,setState,state,setLoadingForm)=>{
  setLoadingForm!==undefined && setLoadingForm(true)

  await axios.post("sales/confirmQuotation",body,HEADER_FORM_DATA)
   .then((res)=>{
      if(res.status===200){
        salesOrderSingleViewAPICall({id:res.data._id})
        localStorage.setItem("singleViewById",res.data._id)
    setLoadingForm!==undefined && setLoadingForm(false)

        // store.dispatch(get_sales_order_row_data_list({salesOrderSingleData:res.data}))
        setState({...state,message:"sales quatation confirmed",success:true})
          postFn()
      }
   })
   .catch((err)=>{
    setLoadingForm!==undefined && setLoadingForm(false)

    if (err.response.status === 500) {
      setState({ ...state, message: "Internal Server error !", error: true })
    } else {
      setState({ ...state, message: err?.response?.data, error: true })
    }
   })
}

// // cancel quatation api call
export const cancelSalesQuatationAPICall=async(body,postFn,setState,state,setLoadingForm)=>{
  setLoadingForm!==undefined && setLoadingForm(true)

  await axios.post("sales/cancelQuotation",body,HEADERS)
   .then((res)=>{
      if(res.status===200){
        setLoadingForm!==undefined && setLoadingForm(false)

        setState({...state,message:"sales quatation canceled",success:true})
          postFn()
      }
   })
   .catch((err)=>{
    setLoadingForm!==undefined && setLoadingForm(false)

    if (err.response.status === 500) {
      setState({ ...state, message: "Internal Server error !", error: true })
    } else {
      setState({ ...state, message: err?.response?.data, error: true })
    }
   })
}

// SALES QUOTATION SINGLE VIEW
export const salesQuotationSingleViewAPICall=(body)=>{
  axios.post("sales/viewQuotationById",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_sales_order_quot_row_data_list({orderQuotationSingleData:res.data}))
    }
  })
  .catch((err)=>{
    store.dispatch(get_sales_order_quot_row_data_list({orderQuotationSingleData:undefined}))
  })
}
export const salesQuotationFilterAPICall =(body,setIsLoading)=>{
  setIsLoading!==undefined&&setIsLoading(false)
  axios.post("sales/salesOrderAndQuotationSearch",body,{
    headers: {
      'Content-Type': 'application/json',
    },
  
  })
  .then((res)=>{
    if(res.status===200){
      store.dispatch(generate_sales_filter_quot({salesQuotfilter:res.data}))
      setIsLoading!==undefined&&setIsLoading(false)

    }
  })
  .catch((err)=>{
    store.dispatch(generate_sales_filter_quot({salesQuotfilter:undefined}))
    setIsLoading!==undefined&&setIsLoading(false)

  })

}

export const getTemplateAPI=async(body)=>{
  await axios
  .get("admin/getTemplateModel",body, HEADERS)
  .then((res)=>{
      if(res.status===200){
          store.dispatch(get_template_design({setDesign:res.data}))
      }
  })
  .catch((err)=>{
      store.dispatch(get_template_design({setDesign:undefined}))
      console.error(err.response);
  })
}

