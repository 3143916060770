import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setAddress,
  setBankAccountDetails,
  setBankAccountNo,
  setBankName,
  setBranchName,
  setCountry,
  setDefaultCreditPeriod,
  setGstInOrUin,
  setIfscCode,
  setName,
  setOpeningBalance,
  setPanOrItNumber,
  setPhoneNumber,
  setPinCode,
  setRegistrationType,
  setState,
} from "../../../../../Redux/Account/suppliersSlice";
import InputBox from "../InputBox";
import SubmitBtn from "../SubmitBtn";
import DropdownInputBox from "../DropdownInputBox";

const Suppliers = () => {
  const bankAccountDetailsOption = [{ option: "Yes" }, { option: "No" }];
  const dispatch = useDispatch();
  const {
    openingBalance,
    bankAccountDetails,
    bankName,
    bankAccountNo,
    ifscCode,
    branchName,
    name,
    address,
    street,
    pinCode,
    state,
    country,
    phoneNumber,
    defaultCreditPeriod,
    panOrItNumber,
    gstInOrUin,
    registrationType,
  } = useSelector((state) => state.suppliersSlice);

  const suppliersSlice = useSelector((state) => state.suppliersSlice);
  const handleSuppliersSubmit = () => {
    console.log(suppliersSlice);
  };
  return (
    <>
      <div
        style={{ padding: "2% 4%" }}
        className="ledger-section-container-input-bank bottom-line"
      >
        <div>
          {/*name */}

          <InputBox
            label={"Name"}
            value={name}
            onChange={(e) => dispatch(setName(e.target.value))}
          />

          {/*Address*/}
          <InputBox
            label={"Address"}
            value={address}
            onChange={(e) => dispatch(setAddress(e.target.value))}
          />
          {/* State */}
          <DropdownInputBox labelName={"State"} value={state} />
        </div>
        <div>
          {/* country */}
          <DropdownInputBox labelName={"Country"} value={country} />

          {/*pin code */}
          <InputBox
            label={"Pin Code"}
            value={pinCode}
            onChange={(e) => dispatch(setPinCode(e.target.value))}
          />
          {/*opening balance*/}
          <InputBox
            label={"Opening Balance"}
            value={openingBalance}
            onChange={(e) => dispatch(setOpeningBalance(e.target.value))}
          />
        </div>
        <div>
          {/*default credit period*/}
          <InputBox
            label={"Default Credit Period"}
            value={defaultCreditPeriod}
            onChange={(e) => dispatch(setDefaultCreditPeriod(e.target.value))}
          />
          {/*Phone number*/}
          <InputBox
            label={"Phone no"}
            value={phoneNumber}
            onChange={(e) => dispatch(setPhoneNumber(e.target.value))}
          />
          {/*PAN / IT NO*/}
          <InputBox
            label={"PAN / IT NO"}
            value={panOrItNumber}
            onChange={(e) => dispatch(setPanOrItNumber(e.target.value))}
          />
        </div>
        <div style={{ justifyContent: "flex-start" }}>
          {/*GST IN/UIN*/}
          <InputBox
            label={"GST IN/UIN"}
            value={gstInOrUin}
            onChange={(e) => dispatch(setGstInOrUin(e.target.value))}
            containerStyle={{width:'31%'}}

          />
          {/*REGISTRATION TYPE*/}
          <InputBox
            label={"Registration Type"}
            value={registrationType}
            onChange={(e) => dispatch(setRegistrationType(e.target.value))}
            containerStyle={{width:'31%'}}

          />
        </div>
      </div>

      <div
        style={{ padding: "2% 4%" }}
        className="ledger-section-container-input-bank bottom-line"
      >
        <div>
          {/*bank account details */}

          <DropdownInputBox
            labelName={"Bank account details"}
            value={bankAccountDetails}
            options={bankAccountDetailsOption}
            onChange={(e, value) => dispatch(setBankAccountDetails(value))}
          />

          {/*bank Name*/}
          <InputBox
            label={"Bank Name"}
            value={bankName}
            onChange={(e) => dispatch(setBankName(e.target.value))}
          />
          {/*bank account number  */}
          <InputBox
            label={"Bank account number"}
            value={bankAccountNo}
            onChange={(e) => dispatch(setBankAccountNo(e.target.value))}
          />
        </div>
        <div style={{ justifyContent: "flex-start" }}>
          {/*IFSC Code  */}
          <InputBox
            label={"IFSC Code"}
            value={ifscCode}
            onChange={(e) => dispatch(setIfscCode(e.target.value))}
            containerStyle={{width:'31%'}}

          />

          {/*branch name */}
          <InputBox
            label={"Branch Name"}
            value={branchName}
            onChange={(e) => dispatch(setBranchName(e.target.value))}
            containerStyle={{width:'31%'}}

          />
        </div>
      </div>
      <SubmitBtn onClick={handleSuppliersSubmit} />
    </>
  );
};

export default Suppliers;
