import { createSlice } from "@reduxjs/toolkit";

export const InventoryAdjustmentSlice = createSlice({
  name: "InventoryAdjustmentSlice",
  initialState: {
    value: undefined,
    singleValue:undefined,
    transNo:undefined,
    tableValue:undefined,
  },
  reducers: {
    get_inventory_adjustment_list: (state, action) => {
      state.value = action.payload.inventoryAdjustmentData;
    },
    get_inventory_adjustment_single_view:(state,action)=>{
      state.singleValue=action.payload.singleinventoryAdjustmentrData
    },
    get_inventory_adjustment_transNo:(state,action)=>{
      state.transNo=action.payload.inventoryAdjustmentTransNo
    },
    get_inventory_adjustment_table_data:(state,action)=>{
      state.tableValue=action.payload.inventoryAdjTableData
    }
   
  },
});

export const {
  get_inventory_adjustment_list,
  get_inventory_adjustment_single_view,
  get_inventory_adjustment_transNo,
  get_inventory_adjustment_table_data
} = InventoryAdjustmentSlice.actions;

export default InventoryAdjustmentSlice.reducer;