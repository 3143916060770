import React from "react";
import DropdownInputBox from "../../../components/DropdownInputBox";
import InputBox from "../../../components/InputBox";
import AddIcon from "@mui/icons-material/Add";
import TableBox from "../../../components/TableBox";

const EmployeeGroupCreation = () => {
const renderedData =[
  {
    
    department: "sales ",
    designation:'Employee'
  },
  {

    department: "sjhjd ",
    designation:'Employee'
  },


]


const heading = ['No', 'Department', 'Designation']
  return (
    <>
      <div className="global-white-bg-container">
        <div className="employee-group-creation">
          <div>
            <strong>Employee Group Creation</strong>
          </div>

          <div className="add-employee-group-container">
            <div>
              <DropdownInputBox
                labelName={"Department"}
                isGroup={true}
                label={"Create Department"}
              />
              <DropdownInputBox
                labelName={"Designation"}
                isGroup={true}
                label={"Create Designation"}
              />

              <div className="voucher-add">
                <button className="add-button">
                  <AddIcon />
                </button>
              </div>
            </div>
          </div>
          <div style={{margin:'2em 0'}}>
          <TableBox  actionBtnIconDelete={true}  isLoading={false} isNo={true}   headings={heading} renderData={renderedData} actionButton={true} />
          </div>
          <div className="button-container">
            <div></div>
            <div className="button-box">
             <button  
                className="cancel-btn"
                
              >
                Back
              </button>
              <button className="submit-btn">Submit</button>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeGroupCreation;
