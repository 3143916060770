// DialogComponent.js
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  TextField,
  List,
  ListItem,
  IconButton,
  ThemeProvider,
  createTheme,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import { useSelector } from "react-redux";
import {
  ViewPaymentTermsListApiCall,
  addPaymentTermsApicall,
  deletePaymentTermApiCall,
  updatePaymentTermsApicall,
} from "../../../Accounts/Vendor/API/vendorListAPICall";
// import MuiEditButton from "../../../Payroll/configuration/components/MuiEditButton";
import SuccessSnackbar from "../../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";

const theme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          "&.Mui-focused": {
            color: "#000000",
            top: "0px",
          },
          top: "-8px",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "#009700",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#bebebe",
            borderWidth: "2px",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#bebebe",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#bebebe",
          },
        },
        input: {
          height: "1.5em",
          padding: "0.5em",
          paddingLeft: "1em",
        },
      },
    },
  },
});

const PaymentTerms = ({ handleDialog, setHandleDialog }) => {
  const paymentTermsList = useSelector(
    (state) => state.vendorsListSlice?.paymentTermsList
  );
  const [isEdit, setIsEdit] = useState();

  const initialState = {
    paymentTerm: "",
    due: 0,
  };
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
    alert: false
  });

  const [paymentTermsDetails, setPaymentTermsDetails] = useState(initialState);

  const handleChangeInput = (keyName, value) => {
    setPaymentTermsDetails((prev) => {
      return { ...prev, [keyName]: value };
    });
  };
  const handleAddPaymentTerms = () => {
    // console.log(paymentTermsDetails);
    if (!isEdit) {
      addPaymentTermsApicall(paymentTermsDetails,setSnackBarStates);
    } else {
      updatePaymentTermsApicall(paymentTermsDetails, setIsEdit,setSnackBarStates);
    } 
  };


  const handleActionButtonClick = (action, data) => {
    switch (action) {
      case "edit":
        setPaymentTermsDetails({
          paymentTerm: data.paymentTerm || "",
          due: data.due || "",
          _id: data._id,
        });
        setIsEdit(true);

        break;
      case "delete":
        deletePaymentTermApiCall(data._id);

        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (!paymentTermsList) {
      ViewPaymentTermsListApiCall();
    }
  }, []);

  useEffect(() => {
    if (!isEdit) {
      setPaymentTermsDetails(initialState);
    }
  }, [isEdit]);

  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      alert: false,
      message: "",
    });
  };
  return (
    <Dialog
      open={handleDialog}
      onClose={() => setHandleDialog(false)}
      sx={{ p: 5 }}
      maxWidth={"md"}
    >
      <Grid
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <DialogContent dividers sx={{ width: "100%" }}>
          <h3>Add Payment Terms</h3>
        </DialogContent>
        <DialogContent>
          <Grid mt={2} display={"flex"} gap={2} minWidth={300}>
            <Grid display={"flex"} gap={2}>
              <ThemeProvider theme={theme}>
                <TextField
                  sx={{ minWidth: "15em" }}
                  value={paymentTermsDetails.paymentTerm}
                  onChange={(e) =>
                    handleChangeInput("paymentTerm", e.target.value)
                  }
                  focused={true}
                  variant="outlined"
                  label="Payment Terms"
                />
              </ThemeProvider>

              <ThemeProvider theme={theme}>
                <TextField
                  type="number"
                  sx={{ minWidth: "15em" }}
                  value={paymentTermsDetails.due}
                  onChange={(e) =>
                    handleChangeInput(
                      "due",
                      e.target.value === "" ? "" : Number(e.target.value)
                    )
                  }
                  focused={true}
                  variant="outlined"
                  label="Due Days"
                />
              </ThemeProvider>
            </Grid>
            <Grid>
              <ThemeProvider theme={theme}>
                <IconButton
                  onClick={() => handleAddPaymentTerms()}
                  sx={{
                    p: 1,
                    backgroundColor: "#00b900",
                    borderRadius: "5px",
                    color: "white",
                  }}
                  color="primary"
                >
                  {isEdit ? <CheckIcon /> : <AddIcon />}
                </IconButton>
              </ThemeProvider>
            </Grid>
          </Grid>
          <Grid mt={2}>
            <TableContainer
              sx={{ boxShadow: "none", border: "none", maxHeight: 300 }}
              component={Paper}
            >
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{ maxHeight: "50vh" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography fontWeight={600}>Category</Typography>{" "}
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight={600}>Due</Typography>{" "}
                    </TableCell>
                    {!isEdit && (
                      <TableCell>
                        <Typography fontWeight={600}>Action </Typography>
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paymentTermsList?.length > 0 ? (
                    [...paymentTermsList].reverse()?.map((r, i) => (
                      <TableRow
                        key={i}
                        sx={{
                          "&:nth-of-type(even)": { backgroundColor: "#edeef0" },
                        }}
                      >
                        <TableCell
                          sx={{ borderBottom: "none", minWidth: "20em" }}
                        >
                          {r?.paymentTerm || "---"}
                        </TableCell>
                        <TableCell
                          sx={{ borderBottom: "none", minWidth: "2em" }}
                        >
                          {r?.due || "---"}
                        </TableCell>
                        {!isEdit && (
                          <TableCell sx={{ borderBottom: "none" }}>
                            <List>
                              <ListItem sx={{ p: 0 }}>
                                <Grid display={"flex"} gap={2}>
                                  <IconButton
                                    onClick={() =>
                                      handleActionButtonClick("edit", r)
                                    }
                                    edge="end"
                                    aria-label="edit"
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  {/* <IconButton
                                    onClick={() =>
                                      handleActionButtonClick("delete", r)
                                    }
                                    edge="end"
                                    aria-label="delete"
                                  >
                                    <DeleteIcon />
                                  </IconButton> */}
                                </Grid>
                              </ListItem>
                            </List>
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Typography textAlign={"center"}>No Data</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </DialogContent>
      </Grid>
      <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
    </Dialog>
  );
};

export default PaymentTerms;
