import { createSlice } from "@reduxjs/toolkit";

export const inventoryConfigSettingSlice = createSlice({
  name: "inventoryConfigSettingSlice",
  initialState: {
    apiResList: undefined,
  },
  reducers: {
    get_inventory_config_settings_res_list: (state, action) => {
      state.apiResList = action.payload.invConfigSettingResData;
    }
  },
});

export const { get_inventory_config_settings_res_list} = inventoryConfigSettingSlice.actions;

export default inventoryConfigSettingSlice.reducer;
