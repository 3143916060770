import { createSlice } from "@reduxjs/toolkit";

export const allEmployeeLoanSlice =createSlice({
    name:'employeeAllLoanSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_all_employee_loan_list:(state,action)=>{
            state.value=action.payload.allemployeeLoanData
        }
    }

})

export const {update_all_employee_loan_list}=allEmployeeLoanSlice.actions
export default allEmployeeLoanSlice.reducer