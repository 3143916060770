import { Dialog } from '@mui/material'
import React from 'react'
import '../../css/Single Components/addTailorPopup.css'
export const AddTailorPopup = (props) => {
    const {open,label,cancelBtn,submitFn}=props
  return (
    <>
    <Dialog open={open}>
       <div className='add-tailor-pop-up-container'>
          <h3>{label}</h3>
          <div className="add-popup-table">
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Select</th>
                </tr>
              </thead>
              <tbody>{props.children}</tbody>
            </table>
          </div>
          <div className='add-tailor-popup-container'>
             <button onClick={cancelBtn} className='btn btn-secondary-outlined add-tailor-popup-btn'>Cancel</button>
             <button className='btn btn-secondary add-tailor-popup-btn' onClick={submitFn}>Submit</button>
          </div>
       </div>
    </Dialog>
    </>
  )
}
