import axios from 'axios'
import {get_damaged_goods} from '../../Redux/Sales/DamagedGoods/damagedGoodsSlice'
import {HEADERS} from '../../API/UrlAndPaths'
import store from '../../Redux/store'
import {get_damaged_goods_single} from '../../Redux/Sales/DamagedGoods/damagedGoodsSingleViewSlice'


// damaged goods api call
export const damagedGoodsAPICall= async (body,setIsLoading,setErrorSnack,setSnackMsg)=>{
  if(setIsLoading!==undefined) setIsLoading(true)
  await axios.post("sales/viewdamagegoods",body,HEADERS)
  .then((res)=>{
    if(res.status === 200){
        store.dispatch(get_damaged_goods({damagedGoodsData:res.data}))
        if(setIsLoading!==undefined) setIsLoading(false)
      }
  })
  .catch((err)=>{
    store.dispatch(get_damaged_goods({damagedGoodsData:undefined}))
    console.log(err.response);
    switch (err.response.status) {
      case 404:
          setSnackMsg('No data available..')
        break;
      case 500:
          setSnackMsg('Server error!!')
        break;
      case 401:
          setSnackMsg('Unauthorized!!')
        break;
      case 403:
          setSnackMsg('Forbidden!!')
        break;
    
      default:
          setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
        break;
    }
    setErrorSnack(true)
    if(setIsLoading!==undefined) setIsLoading(false)
  })
}

// damaged good single view api call
export const damagedGoodsSingleViewAPICall= async (body)=>{
await axios.post("sales/viewdamagegoodsbyid",body,HEADERS)
.then((res)=>{
    if(res.status === 200){
        store.dispatch(get_damaged_goods_single({damagedGoodsSingleViewData:res.data}))
    }
})
.catch((err)=>{
    console.log(err.response);
    store.dispatch(get_damaged_goods_single({damagedGoodsSingleViewData:undefined}))
})
}