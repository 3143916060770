import React,{useState} from 'react'
import {useSelector} from "react-redux";
import {Autocomplete, Dialog,IconButton, TextField} from '@mui/material'
import {get_shift_reopen_dialog} from '../../../../Redux/Settings/Shift/shiftReopenDialogSlice'
import Store from '../../../../Redux/store'
import '../../../../css/Settings/Shift/shiftReopen.css'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import DoneIcon from '@mui/icons-material/Done';
export const ShiftReopen = () => {
  const shiftReopenDialog=useSelector((state)=>state.shiftReopenDialog.value)

  const [shiftTableDialog,setShiftTableDialog]=useState(false)
  const [shiftReopenConfirmDialog,setShiftReopenConfirmDialog]=useState(false)
  const [successMsgDialog,setSuccessMsgDialog]=useState(false)

  const closePasswordDialog=()=>{
    Store.dispatch(get_shift_reopen_dialog({shiftReopen:false}))
  }

  const PasswordSubmitBtn =()=>{
    Store.dispatch(get_shift_reopen_dialog({shiftReopen:false}))
    setShiftTableDialog(true)
  }

  const shiftReopenTableSubmitFn=()=>{
    setShiftTableDialog(false)
    setShiftReopenConfirmDialog(true)
  }
  const confirmDialogSubmitFn=()=>{
    setShiftReopenConfirmDialog(false)
    setSuccessMsgDialog(true)
    setTimeout(() => {
      setSuccessMsgDialog(false)
    }, 1000);

  }
  console.log(shiftReopenDialog);
  return (
    <div>
       {/* password dialog */}
      <Dialog open={shiftReopenDialog} maxWidth="lg" onClose={closePasswordDialog} onKeyDown={(e)=>e.key === "Escape" && closePasswordDialog()}>
        <div className="shift-reopen-password-dialog-container">
          <h4>Enter Password</h4>
           <div className="global-single-input shift-reopen-password-input">
            <p>Password</p>
            <input type="password" />
           </div>
           <button onClick={PasswordSubmitBtn} className="btn btn-primary shit-reopen-btn">Submit</button>
        </div>
      </Dialog>

      {/* shift reopen table dialog */}
      <Dialog open={shiftTableDialog} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setShiftTableDialog(false)}>
         <div className="shift-reopen-table-dialog-container">
            <div className="shift-reopen-table-dialog-top-container">
              <h4>Shift Reopen</h4>
              <hr className="global-hr" />
              <div className="shift-reopen-table-dialog-top-container-section">
                <div className="global-single-input auto-complete shift-reopen-input">
                  <p>Branch</p>
                  <Autocomplete 
                    sx={{width:"100%"}}
                    options={[{branch:"Pala"},{branch:"palli"}]}
                    getOptionLabel={(option)=>option?.branch}
                    renderInput={(params)=>(
                      <TextField {...params}/>
                    )}
                  />
                </div>
                <div className="global-single-input auto-complete shift-reopen-input">
                  <p>Customer</p>
                  <Autocomplete 
                    sx={{width:"100%"}}
                    options={[{id:"SHIFT"},{id:"Swift"}]}
                    getOptionLabel={(option)=>option?.id}
                    renderInput={(params) => (
                      <TextField {...params} />
                    )}
                  />
                </div>
                <div className="view-order-search-container shift-reopen-admin-search-container">
                  <input
                    placeholder="Search shift"
                    // value={searchKeyword}
                    // onChange={getSearchKeyword}
                  />
                  <IconButton>
                      <i class="bi bi-search search-icon-order"></i>
                  </IconButton>
                </div>
                <button className='btn btn-primary' style={{marginRight:"0px"}}>Submit</button>
              </div>
            </div>
           <div className="global-table-container">
              <table className='global-table shift-reopen-table'>
                 <thead>
                    <tr>
                      <th><input type="checkbox" /></th>
                      <th>Shift No</th>
                      <th>Shift Date</th>
                      <th>Shift Open Time</th>
                      <th>Shift Closing Time</th>
                      <th>Shift User</th>
                      <th>Delete</th>
                    </tr>
                 </thead>
                 <tbody>
                   <tr>
                    <td><input type="checkbox" /></td>
                    <td>SH001</td>
                     <td>12-02-2022</td>
                     <td>9.00 AM</td>
                     <td>4.00 PM</td>
                     <td>Admin</td>
                     <td>
                     <IconButton>
                         <i class="bi bi-trash3 delete-icon"></i>
                      </IconButton>
                     </td>
                   </tr>
                   <tr>
                    <td><input type="checkbox" /></td>
                    <td>SH001S</td>
                     <td>12-02-2022</td>
                     <td>9.00 AM</td>
                     <td>4.00 PM</td>
                     <td>Admin</td>
                     <td>
                     <IconButton>
                         <i class="bi bi-trash3 delete-icon"></i>
                      </IconButton>
                     </td>
                   </tr>
                 </tbody>
              </table>
           </div>
           <div className='shift-reopen-table-btn-container'>
             <button className='btn btn-secondary-outlined' onClick={()=>setShiftTableDialog(false)}>Cancel</button>
             <button onClick={shiftReopenTableSubmitFn} className='btn btn-primary'>Submit</button>
           </div>
         </div>
      </Dialog>

      {/* shift reopen confirm dialog */}
        <Dialog open={shiftReopenConfirmDialog} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setShiftReopenConfirmDialog(false)}>
          <div className="shift-reopen-confirm-dialog-container">
            <div className='top-container'>
              <PriorityHighIcon sx={{fontSize:'2.2rem',color:"red"}}/>
            </div>
             <h5>Are you sure want to reopen the shift</h5>
            <div className='button-container'>
              <button onClick={()=>setShiftReopenConfirmDialog(false)} className='btn btn-red'>Cancel</button>
              <button onClick={confirmDialogSubmitFn} className='btn btn-primary'>Submit</button>
            </div>
          </div>
       </Dialog>

       {/* succcess dialog */}
       <Dialog open={successMsgDialog} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setSuccessMsgDialog(false )}>
          <div className='shift-reopen-success-dialog-container'>
            <div className='icon-container'>
              <DoneIcon sx={{fontSize:'3rem',color:"#00c14d"}}/>
            </div>
            <h5>Shift reopen successfully</h5>
          </div>
       </Dialog>
    </div>
  )
}
