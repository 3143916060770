import { createSlice } from "@reduxjs/toolkit";

export const customerCreditReport=createSlice({
    name:'customerCreditReport',
    initialState:{
        value:undefined,
        customerCreditFirstSingleView:undefined,
        customerCreditSecondSingleView:undefined,
    },
    reducers:{
        view_customer_credit_report_list:(state,action)=>{
            state.value=action.payload.customerCreditReportData
        },
        customer_credit_report_single_list1:(state,action)=>{
            state.customerCreditFirstSingleView=action.payload.custmrCreditRprtFirstSingleView
        },
        customer_credit_report_single_list2:(state,action)=>{
            state.customerCreditSecondSingleView=action.payload.custmrCreditRprtSecondSingleView
        }
    }
})

export const {view_customer_credit_report_list,customer_credit_report_single_list1,customer_credit_report_single_list2}=customerCreditReport.actions
export default customerCreditReport.reducer