import {createSlice} from '@reduxjs/toolkit'

export const viewAllTransferSlice=createSlice({
    name:'allTransferListSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_all_transfer_list:(state,action)=>{
            state.value=action.payload.allTransferListData
        }
    }
})
export const {get_all_transfer_list} = viewAllTransferSlice.actions;
export default viewAllTransferSlice.reducer;