import axios from "axios"
import { HEADERS } from "../../../../../API/UrlAndPaths"
import store from "../../../../../Redux/store";
import { get_pos_settings_api_res_list } from "./PosSettingsSlice";

export const postPosSettingsAPICall=(body,setState,state)=>{
    axios.post("settings/posConfigurationSettings",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            setState({...state,message:"pos Configuration Updated",success:true})
        }
    })
    .catch((err)=>{
        setState({...state,message:"Something went wrong !!",error:true})
    })
}

export const viewPosSettingsAPICall=()=>{
    axios.post("settings/viewPosConfigurationSettings",{},HEADERS)
    .then((res)=>{
        if(res.status===200){
           store.dispatch(get_pos_settings_api_res_list({posSettingsResData:res.data}))
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}