import axios from "axios"
import { HEADERS, HEADER_FORM_DATA } from "../../../../API/UrlAndPaths";
import store from "../../../../Redux/store";
import { setListInputOrOutputReport } from "./vatReportSlice";

// list input or output report API
export const listInputOrOutputAPI = async (body)=>{
    try {
        const response = await axios.post('account/inOutVatReport',body,HEADERS)
        if(response.status === 200){
            store.dispatch(setListInputOrOutputReport(response.data))
        }
    } catch (error) {
        store.dispatch(setListInputOrOutputReport([]))
        
    }
}