import {createSlice} from '@reduxjs/toolkit'

export const upiManualSlice=createSlice({
    name:"upiManualSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        get_upi_manual:(state,action)=>{
            state.value=action.payload.upiManualData
        }
    }
})
export const {get_upi_manual}=upiManualSlice.actions;
export default upiManualSlice.reducer