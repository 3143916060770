import { createSlice } from "@reduxjs/toolkit";

export const utilityJobCompletionListSlice=createSlice({
    name:'utilityJobCompletionListSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_utility_job_completion_list:(state,action)=>{
            state.value=action.payload.utilityJobCompletionData
        }
    }
})

export const {get_utility_job_completion_list}=utilityJobCompletionListSlice.actions;
export default utilityJobCompletionListSlice.reducer