import React, { useEffect, useState } from 'react'
import { viewAllCurrencyUpdated } from '../../../../API/Settings/Currency/CurrencyAPI';
import { companyProfileInfoForUser } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import { filterObjFromList } from '../../../../Js/generalFunctions';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IconButton, Skeleton } from '@mui/material';
import CustomPagination from '../../../Single Components/CustomPagination';
import { convertDateFormat } from '../../../../Js/Date';
import CategorySearchandFilter from '../../../Single Components/CategorySearchandFilter';
import { CustomerCreditReportFirstSingleViewAPICall } from './CustomerCreditReportAPI';
import { LoadingForm } from '../../../Single Components/LoadingForm';

function CustomerCreditReportSingleView1() {
  let navigate = useNavigate();
  let location = useLocation()
  const customerName = location.state.customerName;
  
  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue)

  const SingleListData = useSelector((state) => state?.customerCreditReport?.customerCreditFirstSingleView)
console.log(SingleListData,'kkkkk')
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [symbol, setSymbol] = useState(null);
  const handleDataFromChild = (data) => {
    setFormDataInfo(data);
  };

  const singleViewId = localStorage.getItem("SingleViewId")

  const totalPages = 10;
  const decimalPosition = localStorage.getItem("decimalPosition");

  const handlePageChange = (page) => {

    setCurrentPage(page);
  };


  const handleClick = (row) => {
    localStorage.setItem("SingleViewId1", row?._id);
    localStorage.setItem("Ref", Number(row?.ref));
    navigate('/userdashboard/report/CustomerCreditReport/SingleView2', 
    {
       state: { 
        customerName: location.state.customerName,
        invoiceNo: row?.invoiceNo 
        }
   });
  };
  
  
  const handleBackClick = (row) => {
    localStorage.removeItem("SingleViewId", row?._id);
    navigate('/userdashboard/report/CustomerCreditReport')

  };
  useEffect(() => {

    CustomerCreditReportFirstSingleViewAPICall({
      _id: singleViewId,

    },setIsLoading)
  }, [singleViewId])

  useEffect(() => {
    let currencyObj = filterObjFromList("_id", currencyListUpdated, "currency", companyProfileData)
    setSymbol(currencyObj?.symbol)
  }, [companyProfileData, currencyListUpdated])
  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, [])
  return (
    <div className="global-page-parent-container">

      <div className="global-white-bg-container">
       
        <div className='create-button-blue-container' style={{ margin: "1%", display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
          <div>
            <h3 style={{ display: 'inline-block' }}>
              <span
                style={{ color: 'gray', cursor: 'pointer' }}
                onClick={handleBackClick}
              >
                Customer credit Report
              </span>
              <ArrowForwardIosIcon style={{ fontSize: '0.9rem' }} />
              {location.state.customerName}
            </h3>
          </div>

          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '50%'
          }}>
            <CategorySearchandFilter
              onData={handleDataFromChild}
              style={{ width: "100%" }}       
            />
            <IconButton
              style={{ width: "100px" }}

              className="print-icon-container">
              <i class="bi bi-printer printer-icon"></i>
            </IconButton>
            <IconButton
              style={{ width: "100px" }}
              className="download-icon-container">
              <i class="bi bi-arrow-down-circle download-icon"></i>
            </IconButton>
          </div>

        </div>

        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}

          />
        </div>
        <div className="customer-receipt-table-container">
          <table>
            <thead>
              <tr>

                <th>DATE</th>
                <th>PARTICULAR</th>
                <th>REFERENCE</th>
                <th>AMOUNT</th>
                <th>DISCOUNT</th>
                <th>PAID AMOUNT</th>
                <th>BALANCE</th>
                <th>STATUS</th>

              </tr>
            </thead>
            <tbody>

           
              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={10}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : SingleListData?.report !== undefined && SingleListData?.report?.length !== 0 ? (
                SingleListData?.report
                  ?.slice(0)
                  ?.map((r, i) => (
                    <tr key={i} onClick={() => handleClick(r)}>
                      <td>{convertDateFormat(r?.date)}</td>
                      <td>{r?.invoiceNo}</td>
                      <td>
                        {r?.ref === 1 ? "work order" :
                          r?.ref === 2 ? "billing" :
                            r?.ref === 3 ? "alteration" :
                              r?.ref === 4 ? "return" : ""}
                      </td>
                      <td>{symbol}&nbsp;{r?.amount?.toFixed(decimalPosition)}</td>
                      <td>{symbol}&nbsp;{r?.discount?.toFixed(decimalPosition)}</td>
                      <td>{symbol}&nbsp;{r?.paidAmount?.toFixed(decimalPosition)}</td>
                      <td>{symbol}&nbsp;{r?.balance?.toFixed(decimalPosition)}</td>
                      <td>{r?.balance>0?'credit':'paid'}</td>

                    </tr>
                  ))
              ) : (
                <tr>
                  <td style={{ textAlign: 'center' }} colSpan={9}>No Data</td>
                </tr>
              )}

            </tbody>
            <tfoot >
              <tr>
              <th style={{ textAlign: 'start', fontSize: '1rem', paddingLeft: '3%' }} colSpan={3}>TOTAL</th>
              <th >{symbol}&nbsp;{SingleListData?.total !== undefined && SingleListData?.total?.amount?.toFixed(decimalPosition)}</th>
              <th >{symbol}&nbsp;{SingleListData?.total !== undefined && SingleListData?.total?.discount?.toFixed(decimalPosition)}</th>
              <th >{symbol}&nbsp;{SingleListData?.total !== undefined && SingleListData?.total?.paidAmount?.toFixed(decimalPosition)}</th>   
              <th style={{paddingRight:'8%'}} colSpan={2} >{symbol}&nbsp;{SingleListData?.total !== undefined && SingleListData?.total?.balance?.toFixed(decimalPosition)}</th>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
      {/* <LoadingForm loading={isLoading} /> */}
    </div>
  )
}

export default CustomerCreditReportSingleView1