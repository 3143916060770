import { Dialog, IconButton, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import "../../../../../../css/Staff/HRM/Tab/empPersonalInfo.css";
import { FilePicker } from "../../../../../Single Components/filePicker";
import Country from "../../../../../../Assets/JSON/countryStates.json";
import { useEffect } from "react";
import { extractStatesFromCountry } from "../../../../../../Js/generalFunctions";
import { editEmployeePersonalInfoAPICall } from "../../../../../../API/Staff/HRM/employeeAPI";
import SuccessSnackbar from "../../../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../../../Single Components/SnackBars/ErrorSnackbar";
import { updateEmpUsernamePasswordAPICall } from "../../../../../../API/Login/LoginAPI";
import { useSelector } from "react-redux";

export const EmpPersonalInfo = (props) => {
  const { singleEmpData, updateListener } = props;
  //User Role
  const userRole = useSelector((state) => state.userRoleSlice.value);
  const currentEmpID = localStorage.getItem("EMP_ID");
  const [staffImage1, setStaffImage1] = useState(null);

  //Input States
  const empPersonalInfoInitialState = {
    staffName: singleEmpData?.staff_name,
    gender: singleEmpData?.gender,
    fatherName: singleEmpData?.fathersName,
    maritalStatus: singleEmpData?.maritialStatus,
    contactNo: singleEmpData?.contactnumber,
    bloodGroup: singleEmpData?.bloodGroup,
    emergencyContactNo: singleEmpData?.emergencyContactNumber,
    address: singleEmpData?.address,
    email: singleEmpData?.email,
    dob: singleEmpData?.dob,
    country: singleEmpData?.country,
    state: singleEmpData?.state,
    userName: singleEmpData?.username,
    password: singleEmpData?.password,
  };
  const [empPersonalInfoStates, setEmpPersonalInfoStates] = useState(
    empPersonalInfoInitialState
  );

  //Extracted States
  const [extractedStates, setExtractedStates] = useState([]);
  //Snackbar states
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState();
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState();
  const [snackMsg, setSnackMsg] = useState("oops!!");

  //Open Edit Username and password
  const [openEditLoginInfo, setOpenEditLoginInfo] = useState(false);
  //New password Form
  const newPasswordFormInitialState = {
    userName: "",
    newPassword: "",
    confirmPassword: "",
  };
  const [newPasswordForm, setNewPasswordForm] = useState(
    newPasswordFormInitialState
  );
// visibility states
const [isVisible,setIsVisible]=useState(false)
  //*useEffects
  useEffect(() => {
    let extractedStates = extractStatesFromCountry(
      empPersonalInfoStates?.country
    );
    setExtractedStates(extractedStates);
  }, [empPersonalInfoStates?.country]);

  //*OnChange functions
  const getEmpPersonalInfoUpdatedValues = (key) => (e) => {
    const { value } = e.target;
    if (key === "staffName") {
      setEmpPersonalInfoStates({ ...empPersonalInfoStates, staffName: value });
    }
    if (key === "gender") {
      setEmpPersonalInfoStates({ ...empPersonalInfoStates, gender: value });
    }
    if (key === "fatherName") {
      setEmpPersonalInfoStates({ ...empPersonalInfoStates, fatherName: value });
    }
    if (key === "maritalStatus") {
      setEmpPersonalInfoStates({
        ...empPersonalInfoStates,
        maritalStatus: value,
      });
    }
    if (key === "contactNo") {
      setEmpPersonalInfoStates({ ...empPersonalInfoStates, contactNo: value });
    }
    if (key === "bloodGroup") {
      setEmpPersonalInfoStates({ ...empPersonalInfoStates, bloodGroup: value });
    }
    if (key === "emergencyContactNo") {
      setEmpPersonalInfoStates({
        ...empPersonalInfoStates,
        emergencyContactNo: value,
      });
    }
    if (key === "address") {
      setEmpPersonalInfoStates({ ...empPersonalInfoStates, address: value });
    }
    if (key === "email") {
      setEmpPersonalInfoStates({ ...empPersonalInfoStates, email: value });
    }
    if (key === "dob") {
      setEmpPersonalInfoStates({ ...empPersonalInfoStates, dob: value });
    }
    if (key === "country") {
      setEmpPersonalInfoStates({ ...empPersonalInfoStates, country: value });
    }
    if (key === "state") {
      setEmpPersonalInfoStates({ ...empPersonalInfoStates, state: value });
    }
  };

  //Close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setOpenSuccessSnackbar(false);
  };
  //Close ErrorSnackbar
  const closeErrorSnackbar = () => {
    setOpenErrorSnackbar(false);
  };

  //Get New password
  const getNewPassword = (key) => (e) => {
    const { value } = e.target;

    switch (key) {
      case "userName":
        setNewPasswordForm({ ...newPasswordForm, userName: value });
        break;
      case "newPassword":
        setNewPasswordForm({ ...newPasswordForm, newPassword: value });
        break;
      case "confirmPassword":
        setNewPasswordForm({ ...newPasswordForm, confirmPassword: value });
        break;

      default:
        break;
    }
  };

  //Clear new password States
  const clearNewPasswordStates = () => {
    setNewPasswordForm({
      ...newPasswordForm,
      newPassword: "",
      confirmPassword: "",
    });
  };

  //Close Update password dialog
  const cancelUpdatePassword = () => {
    setOpenEditLoginInfo(false);
    clearNewPasswordStates();
  };
  //Click Password update button
  const clickPasswordUpdate = () => {
    if (newPasswordForm.newPassword === "") {
      alert("Enter new password!!");
    } else if (newPasswordForm.confirmPassword === "") {
      alert("Enter confirm password!!");
    } else if (
      newPasswordForm.confirmPassword !== newPasswordForm.newPassword
    ) {
      alert("Passwords not matching!!");
    } else {
      updateEmpUsernamePasswordAPICall(
        {
          username: newPasswordForm.userName,
          password: newPasswordForm.newPassword,
          _id: currentEmpID,
        },
        setOpenSuccessSnackbar,
        setOpenErrorSnackbar,
        setSnackMsg,
        updateListener,
        cancelUpdatePassword
      );
    }
  };

  //* onClick Fns
  //Edit employee form data
  const editEmployeePInfoFormData = new FormData();
  editEmployeePInfoFormData.append(
    "staff_name",
    empPersonalInfoStates.staffName
  );
  editEmployeePInfoFormData.append("email", empPersonalInfoStates.email);
  editEmployeePInfoFormData.append(
    "contactnumber",
    empPersonalInfoStates.contactNo
  );
  editEmployeePInfoFormData.append("gender", empPersonalInfoStates.gender);
  editEmployeePInfoFormData.append("address", empPersonalInfoStates.address);
  editEmployeePInfoFormData.append("file", staffImage1);
  editEmployeePInfoFormData.append(
    "fathersName",
    empPersonalInfoStates.fatherName
  );
  editEmployeePInfoFormData.append(
    "maritialStatus",
    empPersonalInfoStates.maritalStatus
  );
  editEmployeePInfoFormData.append(
    "bloodGroup",
    empPersonalInfoStates.bloodGroup
  );
  editEmployeePInfoFormData.append(
    "emergencyContactNumber",
    empPersonalInfoStates.emergencyContactNo
  );
  editEmployeePInfoFormData.append("country", empPersonalInfoStates.country);
  editEmployeePInfoFormData.append("state", empPersonalInfoStates.state);
  editEmployeePInfoFormData.append("dob", empPersonalInfoStates.dob);
  editEmployeePInfoFormData.append("id", currentEmpID);

  //Personal Info Update Button
  const personalInfoUpdateBtnClick = () => {
    editEmployeePersonalInfoAPICall(
      editEmployeePInfoFormData,
      setOpenSuccessSnackbar,
      setOpenErrorSnackbar,
      setSnackMsg,
      updateListener
    );
  };

  //Open edit login info Dialog
  const openEditLoginInfoDialog = () => {
    setOpenEditLoginInfo(true);
  };

  //Click Visibility icon
  const visibilityFunction=()=>{
    setIsVisible(!isVisible)
   }
  //Set userName to new password dialog state
  useEffect(() => {
    setNewPasswordForm({
      ...newPasswordForm,
      userName: singleEmpData?.username,
    });
  }, [singleEmpData]);

  return (
    <>
      <div className="employee-personal-info-main-container">
        <div className="employee-personal-info-container">
          <div className="employee-personal-info-single-input-container">
            <div className="global-single-input add-employee-input">
              <p>Staff Name</p>
              <input
                type="text"
                placeholder="Enter Staff Name..."
                value={empPersonalInfoStates.staffName}
                onChange={getEmpPersonalInfoUpdatedValues("staffName")}
              />
            </div>
            <div className="global-single-input add-employee-input">
              <p>Gender</p>
              <Select
                value={empPersonalInfoStates.gender}
                onChange={getEmpPersonalInfoUpdatedValues("gender")}
              >
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="trnasgender">Transgender</MenuItem>
              </Select>
            </div>
            <div className="global-single-input add-employee-input">
              <p>Father Name</p>
              <input
                type="text"
                placeholder="Enter Father Name..."
                value={empPersonalInfoStates.fatherName}
                onChange={getEmpPersonalInfoUpdatedValues("fatherName")}
              />
            </div>
            <div className="global-single-input add-employee-input">
              <p>Marital Status</p>
              <Select
                value={empPersonalInfoStates.maritalStatus}
                onChange={getEmpPersonalInfoUpdatedValues("maritalStatus")}
              >
                <MenuItem value={false}>Single</MenuItem>
                <MenuItem value={true}>Married</MenuItem>
              </Select>
            </div>
            <div className="global-single-input add-employee-input">
              <p>Contact Number</p>
              <input
                type="text"
                placeholder="Enter Contact Number..."
                value={empPersonalInfoStates.contactNo}
                onChange={getEmpPersonalInfoUpdatedValues("contactNo")}
              />
            </div>
            <div className="global-single-input add-employee-input">
              <p>Blood Group</p>
              <Select
                value={empPersonalInfoStates.bloodGroup}
                onChange={getEmpPersonalInfoUpdatedValues("bloodGroup")}
              >
                <MenuItem value="A+">A +ve</MenuItem>
                <MenuItem value="A-">A -ve</MenuItem>
                <MenuItem value="B+">B +ve</MenuItem>
                <MenuItem value="B-">B -ve</MenuItem>
                <MenuItem value="AB+">AB +ve</MenuItem>
                <MenuItem value="AB-">AB -ve</MenuItem>
                <MenuItem value="O+">O +ve</MenuItem>
                <MenuItem value="O-">O -ve</MenuItem>
              </Select>
            </div>
            <div className="global-single-input add-employee-input">
              <p>Emergency Contact Number</p>
              <input
                type="text"
                placeholder="Enter Contact Number..."
                value={empPersonalInfoStates.emergencyContactNo}
                onChange={getEmpPersonalInfoUpdatedValues("emergencyContactNo")}
              />
            </div>
            <div className="global-single-input add-employee-input">
              <p>Address</p>
              <input
                type="text"
                placeholder="Enter Address..."
                value={empPersonalInfoStates.address}
                onChange={getEmpPersonalInfoUpdatedValues("address")}
              />
            </div>
            <div className="global-single-input add-employee-input">
              <p>Email</p>
              <input
                type="text"
                placeholder="abc@gmail.com"
                value={empPersonalInfoStates.email}
                onChange={getEmpPersonalInfoUpdatedValues("email")}
              />
            </div>
            <div className="global-single-input add-employee-input">
              <p>DOB</p>
              <input
                type="date"
                value={empPersonalInfoStates.dob}
                onChange={getEmpPersonalInfoUpdatedValues("dob")}
              />
            </div>
            <div className="global-single-input add-employee-input">
              <p>Country</p>
              <Select
                value={empPersonalInfoStates.country}
                onChange={getEmpPersonalInfoUpdatedValues("country")}
              >
                {Country?.countries?.map((item, i) => (
                  <MenuItem value={item?.country?.toLowerCase()} key={i}>
                    {item?.country}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="global-single-input add-employee-input">
              <p>State</p>
              <Select
                value={empPersonalInfoStates.state}
                onChange={getEmpPersonalInfoUpdatedValues("country")}
              >
                {extractedStates[0]?.states?.map((state, i) => (
                  <MenuItem value={state} key={i}>
                    {state}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="global-single-input add-employee-input">
              <p>Image Upload</p>
              <FilePicker
                id="staffImage1"
                uploadImageUrl={staffImage1}
                setImage={setStaffImage1}
                onChange={(e) => setStaffImage1(e.target.files[0])}
              />
            </div>
          </div>
          <button
            className="btn btn-primary employee-upadte-btn btn-font-size-less"
            onClick={personalInfoUpdateBtnClick}
          >
            Update
          </button>
        </div>
        <hr className="global-hr" />
        <div className="employee-personal-info-account-login-container emp-personal-login-cred-parent">
          <div className="emp-personal-info-left-container">
            <h3>Account Login</h3>
            <div className="employee-personal-info-account-login-input-container">
              <div className="account-login">
                <p>UserName</p>
                <input
                  type="text"
                  disabled
                  value={empPersonalInfoStates?.userName}
                />
              </div>
              <div className="account-login">
                <p>Password</p>
                <input
                  type="text"
                  disabled
                  value={empPersonalInfoStates?.password}
                />
              </div>
              {/* <div className="account-login">
             <button className="btn btn-primary user-login-update-btn" onClick={openEditLoginInfoDialog}>Edit </button>
            </div> */}
            </div>
          </div>
          <div className="emp-personal-info-right-container">
            {userRole === "admin" && (
              <button
                className="btn btn-primary user-login-update-btn btn-font-size-less"
                onClick={openEditLoginInfoDialog}
              >
                Edit Password
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Edit Username and Password dialog  */}
      <Dialog open={openEditLoginInfo} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setOpenEditLoginInfo(false)}>
        <div className="edit-login-info-parent">
          <h3>Change password</h3>
          <hr className="global-hr" />
          <div>
            <div className="global-single-input login-cred-single-input">
              <p>Username</p>
              <input 
                type="text" 
                value={newPasswordForm?.userName} 
                onChange={getNewPassword("userName")}
               
               />
            </div>
            <div className="global-single-input login-cred-single-input">
              <p>New password</p>
              <input
                type="password"
                value={newPasswordForm.newPassword}
                onChange={getNewPassword("newPassword")}
              />
            </div>
            <div className="global-single-input login-cred-single-input">
              <p>Confirm password</p>
              <input
                type={isVisible?'text':'password'}
                value={newPasswordForm.confirmPassword}
                onChange={getNewPassword("confirmPassword")}
                onKeyDown={(e) =>
                  e.key === "Enter"
                    ? clickPasswordUpdate()
                    : e.key === "Escape"
                    ? cancelUpdatePassword()
                    : undefined
                }
              />
              <div className="password-visibility-icon">
              <IconButton
               onClick={visibilityFunction}
               >

            {
              isVisible?
              <i class="bi bi-eye-slash"></i>
              :
              <i class="bi bi-eye"></i>
            }
         
          </IconButton>
          </div>
            </div>
          </div>
          <div className="login-cred-cancel-submit-btn-container">
            <button
              className="btn btn-secondary-outlined"
              onClick={cancelUpdatePassword}
            >
              Cancel
            </button>
            <button className="btn btn-primary" onClick={clickPasswordUpdate}>
              Update
            </button>
          </div>
        </div>
      </Dialog>

      <SuccessSnackbar
        open={openSuccessSnackbar}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={openErrorSnackbar}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </>
  );
};
