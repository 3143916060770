import { createSlice } from "@reduxjs/toolkit";

const allWorkOrderListSlice = createSlice({
    name:'allWorkOrderListSlice',
    initialState:{
        value:undefined,
    },
    reducers:{
        allWorkOrderList:(state,action)=>{
            state.value = action.payload.allWorkOrderAlldata
        }
    }
});

export const {allWorkOrderList} = allWorkOrderListSlice.actions
export default allWorkOrderListSlice.reducer;