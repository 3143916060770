import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { addDiscountAPICall, getDiscountAPICall, getDiscountForEditAPICall } from '../../../../API/Settings/Discount/discountAPI'
import "../../../../css/Settings/Discount/discountSettings.css"
import ErrorSnackbar from '../../../Single Components/SnackBars/ErrorSnackbar'
import SuccessSnackbar from '../../../Single Components/SnackBars/SuccessSnackbar'
import { viewAllBranchesAPICall } from '../../../../API/Settings/Company Settings/companySettingsAPI'
import store from '../../../../Redux/store'
import { table_data } from '../../../../Redux/Settings/Discount/getDiscountSlice'
export const Discount = () => {
  const discountData=useSelector((state)=>state.discountSlice.valueNew)
  const tableData=useSelector((state)=>state.discountSlice.tableData)
  const [discount,setDiscount]=useState('')
  const [isModify,setIsModify]=useState(false)
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
    // snackbar states
    const [successSnackbar,setSuccessSnackbar]=useState(false)
    const [errorSnackbar,setErrorSnackbar]=useState(false)
    const [snackMsg,setSnackMsg]=useState('Error!!')
    const [branchWiseDiscount,setBranchwiseDiscount]=useState([])
    const [allBranchCheck,setAllBranchCheck]=useState(false)

  ///updateListener
  const updateListner=()=>{
    setIsModify(!isModify)
  }
   // snackbar functions
   const closeSuccessSnackbar=()=>{
    setSuccessSnackbar(false)
   }
 const closeErrorSnackbar=()=>{
    setErrorSnackbar(false)
   }

  const getDiscount=(e)=>{
    setDiscount(e.target.value)
  }
  const changeBranch=(index)=>(e)=>{
    // extract()
    branchWiseDiscount[index].discountAmount=Number(e.target.value)
    setBranchwiseDiscount([...branchWiseDiscount])
  }

  // set branchwise discount array
   useEffect(() => {
  //  const extract=()=>{
   
    const extractedBranchNames = allBranchesList?.map(branch => {
      return {
       _id:branch._id,
       name:branch.branchName,
       discountAmount:0
      }
      });

    store.dispatch(table_data({tableFullData:extractedBranchNames}))
  // setBranchwiseDiscount(extractedBranchNames);
  //  }

   
   
    
 }, [allBranchesList]); 

 useEffect(() => {
  setBranchwiseDiscount(tableData?.map((obj)=>{
      return {
        ...obj
      }
  }));
}, [tableData]);
// console.log(tableData);

 

  const allBranchCheckBody={
    isGlobal:true,
    discountAmount:parseFloat(discount)
  }
  const allBranchUncheckBody={
    "isGlobal": false,
     branchList:branchWiseDiscount,
     discountAmount:0
  }
  
  const submitBtnFunction=()=>{
    addDiscountAPICall(
      allBranchCheck?allBranchCheckBody:allBranchUncheckBody,
      updateListner,
      setSuccessSnackbar,
      setErrorSnackbar,
      setSnackMsg,
      setDiscount
      )
  }
  // get discount api call
  useEffect(()=>{
    // getDiscountAPICall()
    getDiscountForEditAPICall()
  
  },[isModify])

  // useEffect(()=>{
  
  //   if(discountData?.branchList?.length ===0){
  //     viewAllBranchesAPICall();
  //   }
   
  // },[discountData])

  useEffect(()=>{
    if(discountData !== undefined){
      setDiscount(discountData?.discountAmount)
      setAllBranchCheck(discountData?.isGlobal)
      setBranchwiseDiscount(discountData?.branchList?.map((r,i)=>{
         return{
          _id:r?._id,
          name:r?.name,
          discountAmount:r?.discountAmount
         }
      }))
    }
    
    if(discountData?.branchList?.length ===0){
      viewAllBranchesAPICall();
    }
     
  
    
  },[discountData,])

// console.log(discountData);
// console.log(branchWiseDiscount);
  
  return (
      <div>
          <div className="discount-settings-container">
              <div className="discount-settings-container-body">
                <div className="discount-settings-container-body-section">
                  <div style={{display:"flex"}}>
                  <div>
                       <p>Discount Amount</p>
                        <input type="number"
                         value={discount} 
                         onChange={getDiscount} 
                         style={{height:"30px"}}
                         disabled={!allBranchCheck}
                         />
                        <p className="discount-settings-container-body-section-percentage">Discount :{discountData?.discountAmount}%</p>
                  </div>
                  <div className="discount-all-branch-checkbox">
                    <input 
                      type="checkbox" 
                      checked={allBranchCheck}
                      onChange={(e)=>setAllBranchCheck(e.target.checked)}
                      />
                    <label htmlFor="">All Branches</label>
                  </div>
                  </div>
                  {!allBranchCheck && 
                  <div className="branch-list-container">
                      <div className="branch-list-container-top" >
                        <p></p>
                        <p>Branch Name</p>
                        <p>%</p>
                      </div>
                      <div className="branch-list-container-bottom">
                        {
                          branchWiseDiscount?.map((r,i)=>(
                          <div className="branch-list-container-top">
                          <p>{i+1}</p>
                          <p>{r?.name}</p>
                          <input 
                             type="text"
                             value={r?.discountAmount}
                             onChange={changeBranch(i)}
                             />
                         
                        </div>
                        ))
                      }
                      </div>
                     
                    
                  </div>
                   }
                
                </div>  
                <div className="discount-settings-container-button">
                  <button onClick={submitBtnFunction} className="btn btn-primary">Submit</button>
                </div>   
              </div>
                
          </div>
          <SuccessSnackbar
       open={successSnackbar}
       handleClose={closeSuccessSnackbar}
       message={snackMsg}
     />
     
     <ErrorSnackbar
       open={errorSnackbar}
       handleClose={closeErrorSnackbar}
       message={snackMsg}
     />
        
      </div>
  )
}
