import { createSlice } from "@reduxjs/toolkit";

export const workOrderSingleDataSlice=createSlice({
    name:'workOrderSingleDataSlice',
    initialState:{
        value:undefined,
        viewOrderSingleInfo:undefined
    },
    reducers:{
        update_work_order_single:(state,action)=>{
            state.value=action.payload.workOrderSingle
        },
        view_order_single_table_info:(state,action)=>{
            state.viewOrderSingleInfo=action.payload
        },
    }
})
export const {update_work_order_single,view_order_single_table_info} = workOrderSingleDataSlice.actions
export default workOrderSingleDataSlice.reducer