import React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import SaveIcon from "@mui/icons-material/Save";
import { styled } from "@mui/material";
const StyledButton = styled(IconButton)({
  padding: "1em",
  height: "0.5em",
  width: "0.5em",
  color: "black",
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
});

const MuiSaveBtn = ({ onClick }) => {
  return (
    <Tooltip title="Save" arrow>
      <StyledButton onClick={onClick}>
        {/* <SaveIcon fontSize="medium" /> */}
        <i class="bi bi-floppy save-icon"></i>
      </StyledButton>
    </Tooltip>
  );
};

export default MuiSaveBtn;
