import { Autocomplete, Dialog, IconButton, MenuItem, Select, Skeleton, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import "../../../../css/Staff/Payroll/staffPayrollEmpSalary.css"
import { useSelector } from "react-redux";
import { getAllActiveEmployeesAPICall } from "../../../../API/Staff/HRM/employeeAPI";
import { getAllDesignationAPICall } from "../../../../API/Staff/HRM/departmentAndDesignationAPI";
import { deleteSinglePayrollAPICall, editSinglePayrollAPICall, viewEmployeePayrollAPICall, viewSinglePayslipEmpAPICall } from "../../../../API/Staff/HRM/payroll/staffPayrollAPI";
import { convertDateFormat } from "../../../../Js/Date";
import { useNavigate } from "react-router-dom";
import Banks from "../../../../Assets/JSON/banknames.json"
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
export const StaffPayrollEmpSalary=()=>{

    const allActiveEmpList = useSelector(
        (state) => state.allActiveEmployeeSlice.value
      );
    const allDesignationList = useSelector(
        (state) => state.allDesignationListSlice.value
      );
    const allEmpPayrollList = useSelector(
        (state) => state.staffAllPayrollSlice.value
      );
    const singleEmpPaySlipList = useSelector(
        (state) => state.staffSinglePayslipSlice.value
      );
    const numToWords=require('num-words')
    const navigateTo=useNavigate()
    const[paySlipOpen,setPaySlipOpen]=useState(false);
    const[empPayrollEditOpen,setEmpPayrollEditOpen]=useState(false);
    const[isModify,setIsModify]=useState(false);

    const [selectedEmp,setSelectedEmp]=useState(null)
    const [employeeSelected,setEmployeeSelected]=useState(null)
    const [filteredEmpList,setFilteredEmpList]=useState([])
    const [selectedDesignation,setSelectedDesignation]=useState(null)
    const [filteredStaffPayroll,setFilteredStaffPayroll]=useState([])
    // loading states
    const [isLoading,setIsLoading]=useState(false)
    const [successSnackOpen, setSuccessSnackOpen] = useState(false);
    const [errorSnackOpen, setErrorSnackOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState("oops!!!");

    const [amountAlert,setAmountAlert]=useState(false)
    const [bankNameAlert,setBankNameAlert]=useState(false)
    const [accNoAlert,setAccNoAlert]=useState(false)
    const [ifscAlert,setIfscAlert]=useState(false)

    const [selectedBankName,setSelectedBankName]=useState({name:""})
    const clickAddnewPayroll=()=>{
        navigateTo("/userdashboard/staff/payroll/addPayRoll")
    }
    const empLoyeeSalaryInitialForm={
        empName:"",
        empId:"",
        designation:"",
        fromDate:"",
        toDate:"",
    }
    const [empSalaryForm,setEmpSalaryForm]=useState(empLoyeeSalaryInitialForm)
    const closeSuccessSnack = () => {
        setSuccessSnackOpen(false);
    };
    const closeErrorSnack = () => {
        setErrorSnackOpen(false);
    };

    const empPayslipSingleBodyInitial={
        empName:"",
        empId:"",
        earnings:[],
        deductions:[],
        netSalary:0
    }
    const [isChanged,setIsChanged]=useState(false)
    const [empPayslipSingleBody,setEmpPayslipSingleBody]=useState(empPayslipSingleBodyInitial)
    const addPayrollInitialBody={
            voucherNo:"",
            date:"",
            empId:"",
            branchId:"",
            voucherNo:"",
            
            basicSalary:"",
            hraAllowance:"",
            daAllowance:"",
            bonus:"",
            travelAllowance:"",
            overTimeHr:"",
            otherAllowance:"",
            educationAllowance:"",

            loan:"",
            emiMonthly:"",
            epf:"",
            esi:"",
            tds:"",
            leave:"",
            otherDeduction:"",
            medicalClaim:"",
            
            totalEarning:0,
            totalDeduction:0,
            paidAmount:"",

            paymentAmt:"",
            paymentMethod:"cheque",
            bankName:"",
            accNo:"",
            ifscCode:"",

            empId:"",
            branchId:"",
            id:""
        }
        
    const [addPayrollBody,setAddPayrollBody]=useState(addPayrollInitialBody)
    const addPayrollOnChange=(key)=>(e,newValue)=>{
        const{value}=e.target
        
        if(key==="basicSalary"){
            setAddPayrollBody({...addPayrollBody,basicSalary:value})
            setIsChanged(!isChanged)
        }
        if(key==="hraAllowance"){
            setAddPayrollBody({...addPayrollBody,hraAllowance:value})
            setIsChanged(!isChanged)
        }
        if(key==="daAllowance"){
            setAddPayrollBody({...addPayrollBody,daAllowance:value})
            setIsChanged(!isChanged)
        }
        if(key==="bonus"){
            setAddPayrollBody({...addPayrollBody,bonus:value})
            setIsChanged(!isChanged)
        }
        if(key==="travelAllowance"){
            setAddPayrollBody({...addPayrollBody,travelAllowance:value})
            setIsChanged(!isChanged)
        }
        if(key==="educationAllowance"){
            setAddPayrollBody({...addPayrollBody,educationAllowance:value})
            setIsChanged(!isChanged)
        }
        if(key==="overTimeHr"){
            setAddPayrollBody({...addPayrollBody,overTimeHr:value})
            setIsChanged(!isChanged)
        }
        if(key==="loan"){
            setAddPayrollBody({...addPayrollBody,loan:value})
            setIsChanged(!isChanged)
        }
        if(key==="emiMonthly"){
            setAddPayrollBody({...addPayrollBody,emiMonthly:value})
            setIsChanged(!isChanged)
        }
        if(key==="epf"){
            setAddPayrollBody({...addPayrollBody,epf:value})
            setIsChanged(!isChanged)
        }
        if(key==="esi"){
            setAddPayrollBody({...addPayrollBody,esi:value})
            setIsChanged(!isChanged)
        }
        if(key==="tds"){
            setAddPayrollBody({...addPayrollBody,tds:value})
            setIsChanged(!isChanged)
        }
        if(key==="paidAmount"){
            setAddPayrollBody({...addPayrollBody,paidAmount:value})
            setIsChanged(!isChanged)
        }
        if(key==="leave"){
            setAddPayrollBody({...addPayrollBody,leave:value})
            setIsChanged(!isChanged)
        }
        if(key==="medicalClaim"){
            setAddPayrollBody({...addPayrollBody,medicalClaim:value})
            setIsChanged(!isChanged)
        }
        if(key==="otherAllowance"){
            setAddPayrollBody({...addPayrollBody,otherAllowance:value})
            setIsChanged(!isChanged)
        }
        if(key==="otherDeduction"){
            setAddPayrollBody({...addPayrollBody,otherDeduction:value})
            setIsChanged(!isChanged)
        }
        if(key==="paymentAmt"){
            setAddPayrollBody({...addPayrollBody,paymentAmt:value})
            setAmountAlert(false)
            if(value===""){
                setAmountAlert(true)
            }
        }
        if(key==="paymentMethod"){
            setAddPayrollBody({...addPayrollBody,paymentMethod:value})
        }
        if(key==="accNo"){
            setAddPayrollBody({...addPayrollBody,accNo:value})
            setAccNoAlert(false)
            if(value===""){
                setAccNoAlert(true)
            }
        }
        if(key==="ifscCode"){
            setAddPayrollBody({...addPayrollBody,ifscCode:value})
            setIfscAlert(false)
            if(value===""){
                setIfscAlert(true)
            }
        }
    }
    const clickEmpSubmit=()=>{
        if(allEmpPayrollList!==undefined){
            if(empSalaryForm?.empId==="" && empSalaryForm?.designation===""){
                setFilteredStaffPayroll(allEmpPayrollList)
                console.log(empSalaryForm.designation.toLowerCase());
                console.log(empSalaryForm.designation.toLowerCase());
            }
            else{
                console.log(empSalaryForm.designation.toLowerCase());
                let filteredArray=allEmpPayrollList?.filter((obj)=>
                    obj?.employeeId.toLowerCase()===empSalaryForm.empId.toLowerCase() ||
                    obj?.designation.toLowerCase()===empSalaryForm.designation.toLowerCase()
                )
                setFilteredStaffPayroll(filteredArray)
            }
        }
    }

    const clickPayslipSingleView=(id,empName,empId)=>()=>{
        viewSinglePayslipEmpAPICall({_id:id})
        setEmpPayslipSingleBody({...empPayslipSingleBody,empName:empName,empId:empId})
        setPaySlipOpen(true)
    }
    const editSinglePayroll=(id)=>()=>{
        setAddPayrollBody({...addPayrollBody,id:id})
        viewSinglePayslipEmpAPICall({_id:id})
        setEmpPayrollEditOpen(true)
    }
    const delSinglePayroll=(id)=>()=>{
        deleteSinglePayrollAPICall({_id:id},updateListener,setSuccessSnackOpen,
                                            setErrorSnackOpen,setSnackMsg)
    }
    const editPayrollPayloadBody={
        _id:addPayrollBody?.id,
        emp_id:addPayrollBody?.empId,
        // branchId:addPayrollBody?.branchId,
        earnings:{
            basic_salary: Number(addPayrollBody?.basicSalary),
            hra_allowance: Number(addPayrollBody?.hraAllowance),
            da_allowance: Number(addPayrollBody?.daAllowance),
            travel_allowance: Number(addPayrollBody?.travelAllowance),
            over_time: Number(addPayrollBody?.overTimeHr),
            other_allowance: Number(addPayrollBody?.otherAllowance),
            education_allowance: Number(addPayrollBody?.educationAllowance),
            bonus: Number(addPayrollBody?.bonus),
            total_earnings: Number(addPayrollBody?.totalEarning)
            },
        deductions:{
            emi: Number(addPayrollBody?.emiMonthly),
            epf: Number(addPayrollBody?.epf),
            esi: Number(addPayrollBody?.esi),
            TDS: Number(addPayrollBody?.tds),
            other_deduction: Number(addPayrollBody?.otherDeduction),
            medical_claim: Number(addPayrollBody?.medicalClaim),
            loan: Number(addPayrollBody?.loan),
            total_deduction: Number(addPayrollBody?.totalDeduction)
        },
        payment:{
            amount:Number(addPayrollBody?.paymentAmt),
            paymentMethod:addPayrollBody?.paymentMethod,
            bankName:addPayrollBody?.bankName,
            accountNo:addPayrollBody?.accNo,
            IFSC:addPayrollBody?.ifscCode
        }
    }
    const updateListener=()=>{
        setIsModify(!isModify)
        setAddPayrollBody(addPayrollInitialBody)
    }
    const editSinglePayrollSubmit=()=>{

        if(addPayrollBody?.paymentAmt===""){
            setAmountAlert(true)
        }
        else if(addPayrollBody?.accNo===""){
            setAccNoAlert(true)
        }
        else if(addPayrollBody?.ifscCode===""){
            setIfscAlert(true)
        }
        else{
            editSinglePayrollAPICall(editPayrollPayloadBody,
                setEmpPayrollEditOpen,
                updateListener,setSuccessSnackOpen,
                setErrorSnackOpen,setSnackMsg)
            }
    }
    useEffect(()=>{
        getAllActiveEmployeesAPICall();
        getAllDesignationAPICall();
        viewEmployeePayrollAPICall(setIsLoading);
    },[isModify])
    useEffect(()=>{
     if(allDesignationList!==undefined){
        let filteredArray=allDesignationList.filter((obj)=>
            obj?._id===selectedEmp?.designation
        )
        setSelectedDesignation(filteredArray[0])
        setEmpSalaryForm({...empSalaryForm,designation:filteredArray[0]===undefined?"":filteredArray[0]?.position})  
     }
    },[selectedEmp])

    useEffect(()=>{
        if(allActiveEmpList!==undefined){
        setFilteredEmpList(allActiveEmpList)
        if(selectedDesignation!==null && selectedDesignation!==undefined){
            let filteredArray=[]
            filteredArray=allActiveEmpList.filter((obj)=>
            obj?.designation===selectedDesignation?._id
            )
            setFilteredEmpList(filteredArray)
        }
    }
    },[selectedDesignation,allActiveEmpList])
    useEffect(()=>{
        if(allEmpPayrollList!==undefined){
            setFilteredStaffPayroll(allEmpPayrollList)
        }
    },[allEmpPayrollList])

    useEffect(()=>{
        if(singleEmpPaySlipList!==undefined){
            if(paySlipOpen===true){

                setEmpPayslipSingleBody({...empPayslipSingleBody,
                    earnings:singleEmpPaySlipList?.earnings,
                    deductions:singleEmpPaySlipList?.deductions,
                    netSalary:singleEmpPaySlipList?.earnings.total_earnings-singleEmpPaySlipList?.deductions.total_deduction
                })
            }
            else if(empPayrollEditOpen===true){
                setAddPayrollBody({...addPayrollBody,
                                        basicSalary:singleEmpPaySlipList?.earnings.basic_salary,
                                        hraAllowance:singleEmpPaySlipList?.earnings.hra_allowance,
                                        daAllowance:singleEmpPaySlipList?.earnings.da_allowance,
                                        bonus:singleEmpPaySlipList?.earnings.bonus,
                                        travelAllowance:singleEmpPaySlipList?.earnings.travel_allowance,
                                        overTimeHr:singleEmpPaySlipList?.earnings.over_time,
                                        otherAllowance:singleEmpPaySlipList?.earnings.other_allowance,
                                        educationAllowance:singleEmpPaySlipList?.earnings.education_allowance,
                                        loan:singleEmpPaySlipList?.deductions.loan,
                                        emiMonthly:singleEmpPaySlipList?.deductions.emi,
                                        epf:singleEmpPaySlipList?.deductions.epf,
                                        esi:singleEmpPaySlipList?.deductions.esi,
                                        tds:singleEmpPaySlipList?.deductions.TDS,
                                        // leave:singleEmpPaySlipList?.deductions.emi,
                                        otherDeduction:singleEmpPaySlipList?.deductions.other_deduction,
                                        medicalClaim:singleEmpPaySlipList?.deductions.medical_claim,

                                        paymentAmt:singleEmpPaySlipList?.payment.amount,
                                        paymentMethod:singleEmpPaySlipList?.payment.paymentMethod,
                                        bankName:singleEmpPaySlipList?.payment.bankName,
                                        accNo:singleEmpPaySlipList?.payment.accountNo,
                                        ifscCode:singleEmpPaySlipList?.payment.IFSC,
                                        empId:singleEmpPaySlipList?.emp_id,
                                        branchId:singleEmpPaySlipList?.branchId
                                    })
                setIsChanged(!isChanged)
                
            }
        }
    },[singleEmpPaySlipList])

    useEffect(()=>{
        setSelectedBankName({...selectedBankName,name:addPayrollBody?.bankName})
    },[addPayrollBody?.bankName])

    useEffect(()=>{
        let parsedObj=Object.fromEntries(Object.entries(addPayrollBody).map(([key,value])=>[key,isNaN(parseFloat(value))?0:parseFloat(value)]))

        let sum=parsedObj.basicSalary+parsedObj.hraAllowance
                    +parsedObj.daAllowance+parsedObj.bonus+
                    parsedObj.travelAllowance+parsedObj.educationAllowance+parsedObj.overTimeHr+parsedObj.otherAllowance
        let sumDeductions=parsedObj.emiMonthly+parsedObj.epf
                    +parsedObj.esi+parsedObj.tds+parsedObj.leave+parsedObj.medicalClaim+parsedObj.otherDeduction
         
        setAddPayrollBody({...addPayrollBody,
            totalEarning:sum,
            totalDeduction:sumDeductions,
            paymentAmt:sum-sumDeductions
        })
    },[isChanged])
    return(
        <>
            <div className="global-page-parent-container">
                <p className="breadcrumb">Staff &gt; Payroll &gt;Employee Salary11</p>


                <div className="payroll-emp-top-container">
                    <h3>Employee Salary</h3>
                    <hr className="global-hr"/>
                    <div className="payroll-emp-top-container-input-field">
                        <div className="global-single-input auto-complete">
                            <p>Name/ID</p>
                            <Autocomplete
                             sx={{width:"100%"}}
                             options={filteredEmpList || [{emp_id:"No",staff_name:"Data"}]}
                             getOptionLabel={(option)=>`${option?.emp_id} - ${option?.staff_name}`}
                             renderInput={(params)=>(
                                <TextField {...params} placeholder="Select an Employee"
                                />
                             )}
                             value={employeeSelected}
                             onChange={(e,newValue)=>{setSelectedEmp(newValue)
                                                      setEmployeeSelected(newValue)
                                                      setEmpSalaryForm({...empSalaryForm,empName:newValue?.staff_name,empId:newValue?.emp_id})
                                                      if(newValue===null){
                                                        setEmpSalaryForm(empLoyeeSalaryInitialForm)
                                                      }
                                                    }}
                            noOptionsText="No Employees Available"
                            />
                        </div>
                        <div className="global-single-input auto-complete">
                            <p>Designation</p>
                            <Autocomplete
                             sx={{width:"100%"}}
                             options={allDesignationList || [{position:"No data"}]}
                             getOptionLabel={(option)=>option?.position}
                             renderInput={(params)=>(
                                <TextField {...params}  placeholder="Select a designation"/>
                             )}
                             value={selectedDesignation}
                             onChange={(e,newValue)=>{setSelectedDesignation(newValue)
                                                     setEmployeeSelected(null) 
                                                     setEmpSalaryForm({...empSalaryForm,designation:newValue?.position})                                                     
                                                     if(newValue===null){
                                                        setEmpSalaryForm(empLoyeeSalaryInitialForm)
                                                      } 
                                                    }}
                            />
                        </div>
                        <div className="global-single-input">
                            <p>From</p>
                            <input type="date" 
                            onChange={(e)=>setEmpSalaryForm({...empSalaryForm,fromDate:e.target.value})}
                            />
                        </div>
                        <div className="global-single-input">
                            <p>To</p>
                            <input type="date" 
                             onChange={(e)=>setEmpSalaryForm({...empSalaryForm,toDate:e.target.value})}
                            />
                        </div>
                    </div>
                    <button className="btn btn-primary payroll-emp-top-container-button" onClick={clickEmpSubmit}>Submit</button>
                </div>


                
                <div className="global-white-bg-container payroll-emp-salary-container">
                    <div className="payroll-emp-salary-top">
                        <h3>Employee Salary</h3>
                        <div className="payroll-emp-salary-top-right">
                            <button className="btn btn-new-payroll" onClick={clickAddnewPayroll}>Add New Payroll</button>
                            <button className="btn btn-download-payroll">Download Monthly Report</button>
                        </div>
                    </div>
                    <table className="global-table payroll-emp-salary-table">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Image</th>
                                <th>Name</th>
                                <th>Employee ID</th>
                                <th>Mobile</th>
                                <th>Joining Date</th>
                                <th>Designation</th>
                                <th>Status</th>
                                <th>Payslip</th>
                                <th>Edit</th>
                                <th>Download</th>
                                <th>Remove</th>
                            </tr>
                        </thead>
                    {
                        isLoading?
                        <tbody>
                           <tr>
                            <td colSpan={12}>
                                {/* <Loader /> */}
                                <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td colSpan={12}>
                                {/* <Loader /> */}
                                <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td colSpan={12}>
                                {/* <Loader /> */}
                                <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td colSpan={12}>
                                {/* <Loader /> */}
                                <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                                />
                            </td>
                            </tr>
                            <tr>
                            <td colSpan={12}>
                                {/* <Loader /> */}
                                <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                                />
                            </td>
                            </tr>            
                        </tbody>
                        :
                        <tbody>
                            {
                            filteredStaffPayroll.length !==0?
                            filteredStaffPayroll
                            .slice()
                            .reverse()
                            .map((r,i)=>(
                                <tr key={i}>
                                    <td>{i+1}</td>
                                    <td style={{padding:"2px 2px"}}>
                                        <img src={r?.image} alt="emp img" style={{width:"50px",height:"50px"}}/></td>
                                    <td>{r?.employeeName}</td>
                                    <td>{r?.employeeId}</td>
                                    <td>{r?.mobile}</td>
                                    <td>{convertDateFormat(r?.joiningdate.substring(0,10))}</td>
                                    <td>{r?.designation}</td>
                                    <td><button 
                                        className={r?.status==="paid"?"btn btn-approved":"btn btn-rejected"}>{r?.status}</button></td>
                                    <td>
                                        <IconButton onClick={clickPayslipSingleView(r?._id,r?.employeeName,r?.employeeId)}><i class="bi bi-eye visibility-icon"></i></IconButton>
                                    </td>
                                    <td><IconButton onClick={editSinglePayroll(r?._id)}><i class="bi bi-pencil-square edit-icon"></i></IconButton>
                                    </td>
                                    <td><IconButton><i class="bi bi-arrow-down-circle" style={{color:'#f19800'}}></i></IconButton></td>
                                    <td> <IconButton onClick={delSinglePayroll(r?._id)}><i class="bi bi-trash3 delete-icon"></i></IconButton></td>
                                </tr>
                                ))
                                :
                                <tr>
                                    <td colSpan={12}>No Records</td>
                                </tr>
                            }
                            
                        </tbody>
                }
                    </table>
                </div>
            </div>

            <Dialog open={paySlipOpen} maxWidth="lg" onClose={()=>setPaySlipOpen(false)} onKeyDown={(e)=>e.key === "Escape" && setPaySlipOpen(false)}>
                <div className="payroll-payslip-container">
                    <div className="payroll-payslip-container-header">
                        <h3>Payslip</h3>
                        <div className="payroll-payslip-container-header-right">
                            <button className="btn btn-payslip-red"><IconButton><i class="bi bi-arrow-down-circle" style={{color:'red'}}></i></IconButton></button>
                            <button className="btn btn-payslip-blue"><IconButton><i class="bi bi-printer printer-icon" style={{color:'blue'}}></i></IconButton></button>
                            <button className="btn payroll-payslip-container-header-right-close" onClick={()=>{setPaySlipOpen(false)}}>X</button>
                        </div>
                    </div>
                    <div className="payroll-payslip-container-body">
                        <div className="payroll-payslip-container-body-section">
                                <div className="payroll-payslip-container-body-top">
                                    <div className="payroll-payslip-container-body-top-section">
                                        <p>Employee Name: {empPayslipSingleBody?.empName}</p>
                                        <p>Employee ID: {empPayslipSingleBody?.empId}</p>
                                    </div>
                                    <div className="payroll-payslip-container-body-top-section">
                                        <p>Total Leave: ---</p>
                                    </div>
                                </div>
                                <div className="payroll-payslip-container-body-table">
                                    <div className="payroll-payslip-container-body-table-container">
                                        <div className="payroll-payslip-container-body-table-section">
                                            <div className="payroll-payslip-container-body-table-section-top">
                                                <h3>Earnings</h3>
                                            </div>
                                            <div className="payroll-payslip-container-body-table-section-body">
                                                <div className="payroll-payslip-container-body-table-section-body-left">
                                                    <div className="payroll-payslip-container-body-table-section-body-left-top">
                                                        <h5>Pay Type</h5>
                                                    </div>
                                                    <div className="payroll-payslip-container-body-table-section-body-left-body">
                                                        <h5>Basic Salary</h5>
                                                        <h5>HRA allowance</h5>
                                                        <h5>DA Allowance</h5>
                                                        <h5>Bonus</h5>
                                                        <h5>Travel Allowance</h5>
                                                        <h5>Other Allowance</h5>
                                                        <h5>Over Time</h5>
                                                    </div>
                                                    <div className="payroll-payslip-container-body-table-section-body-left-total">
                                                        <h4>Total Earnings</h4>
                                                    </div>
                                                </div>
                                                <div className="payroll-payslip-container-body-table-section-body-right">
                                                    <div className="payroll-payslip-container-body-table-section-body-right-top">
                                                        <h5>Amount</h5>
                                                    </div>
                                                    <div className="payroll-payslip-container-body-table-section-body-right-body">
                                                        {
                                                            empPayslipSingleBody.earnings.length!==0 &&
                                                        <>
                                                        <h5>{ empPayslipSingleBody.earnings?.basic_salary}</h5>
                                                        <h5>{ empPayslipSingleBody.earnings?.hra_allowance}</h5>
                                                        <h5>{ empPayslipSingleBody.earnings?.da_allowance}</h5>
                                                        <h5>{ empPayslipSingleBody.earnings?.bonus}</h5>
                                                        <h5>{ empPayslipSingleBody.earnings?.travel_allowance}</h5>
                                                        <h5>{ empPayslipSingleBody.earnings?.other_allowance}</h5>
                                                        <h5>{ empPayslipSingleBody.earnings?.over_time}</h5>
                                                        </>
                                                        }
                                                    </div>
                                                    <div className="payroll-payslip-container-body-table-section-body-right-total">
                                                        <h5>{empPayslipSingleBody.earnings.length!==0 && empPayslipSingleBody.earnings?.total_earnings}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="payroll-payslip-container-body-table-section">
                                            <div className="payroll-payslip-container-body-table-section-top">
                                                <h3>Deduction</h3>
                                            </div>
                                            <div className="payroll-payslip-container-body-table-section-body">
                                                <div className="payroll-payslip-container-body-table-section-body-left">
                                                    <div className="payroll-payslip-container-body-table-section-body-left-top">
                                                        <h5>Pay Type</h5>
                                                    </div>
                                                    <div className="payroll-payslip-container-body-table-section-body-left-body">
                                                        <h5>Loan</h5>
                                                        <h5>EMI</h5>
                                                        <h5>EPF</h5>
                                                        <h5>ESI</h5>
                                                        <h5>TDS</h5>
                                                        <h5>Medical Claim</h5>
                                                    </div>
                                                    <div className="payroll-payslip-container-body-table-section-body-left-total">
                                                        <h4>Total Deductions</h4>
                                                    </div>
                                                </div>
                                                <div className="payroll-payslip-container-body-table-section-body-right">
                                                    <div className="payroll-payslip-container-body-table-section-body-right-top">
                                                        <h5>Amount</h5>
                                                    </div>
                                                    <div className="payroll-payslip-container-body-table-section-body-right-body">
                                                        {
                                                        empPayslipSingleBody.deductions.length!==0 &&
                                                        <>
                                                        <h5>{ empPayslipSingleBody.deductions?.loan}</h5>
                                                        <h5>{ empPayslipSingleBody.deductions?.emi}</h5>
                                                        <h5>{ empPayslipSingleBody.deductions?.da_allowance}</h5>
                                                        <h5>{ empPayslipSingleBody.deductions?.epf}</h5>
                                                        <h5>{ empPayslipSingleBody.deductions?.esi}</h5>
                                                        <h5>{ empPayslipSingleBody.deductions?.TDS}</h5>
                                                        <h5>{ empPayslipSingleBody.deductions?.medical_claim}</h5>
                                                        </>
                                                        }
                                                    </div>
                                                    <div className="payroll-payslip-container-body-table-section-body-right-total">
                                                    <h5>{empPayslipSingleBody.deductions.length!==0 && empPayslipSingleBody.deductions?.total_deduction}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                        </div>
                        <div className="payroll-payslip-container-body-total">
                            <div className="payroll-payslip-container-body-total-section">
                                <h3>Net Salary</h3>
                                <p>Inwords</p>
                            </div>
                            <div className="payroll-payslip-container-body-total-section" style={{width:"75%"}}>
                                <h3>{`Rs ${empPayslipSingleBody?.netSalary}`}</h3>
                                <p>{numToWords(empPayslipSingleBody?.netSalary)?.toUpperCase()}</p>
                            </div>
                        </div>
                        <div className="payroll-payslip-container-body-footer">
                            <h4>Company Address</h4>
                            <p>Lorem Ipsum is simply dummy text of the </p>
                            <p>printing and typesetting industry.</p>
                            <p>Lorem Ipsum</p>
                        </div>
                    </div>
                </div>
            </Dialog>

             {/* edit payroll dialog */}
            <Dialog open={empPayrollEditOpen} 
                    onClose={()=>setEmpPayrollEditOpen(false)}
                    maxWidth="md"
                    PaperProps={{style:{width:"60vw"}}}
                    >
            <>
            <div className="staff-add-payroll-body-ctn" style={{padding:"0 3%"}}>
                <div className="staff-add-payroll-body-container-parent">  
                    <div className="staff-add-payroll-body-section">
                        <h4>Salary Information Edit</h4>
                        <p className="add-payroll-body-earning-p">Earnings</p>
                        <div className="staff-add-payroll-body-section-input-field">
                            <div className="global-single-input">
                                <p>Basic Salary</p>
                                <input type="text" placeholder="0.00"
                                value={addPayrollBody.basicSalary}
                                onChange={addPayrollOnChange("basicSalary")}
                                />
                            </div>
                            <div className="global-single-input">
                                <p>HRA Allowance</p>
                                <input type="text" placeholder="0.00"
                                value={addPayrollBody.hraAllowance}
                                onChange={addPayrollOnChange("hraAllowance")}
                                />
                            </div>
                            <div className="global-single-input">
                                <p>DA Allowance</p>
                                <input type="text" placeholder="0.00"
                                value={addPayrollBody.daAllowance}
                                onChange={addPayrollOnChange("daAllowance")}
                                />
                            </div>
                            <div className="global-single-input">
                                <p>Bonus</p>
                                <input type="text" placeholder="0.00"
                                value={addPayrollBody.bonus}
                                onChange={addPayrollOnChange("bonus")}
                                />
                            </div>
                            <div className="global-single-input">
                                <p>Travel Allowance</p>
                                <input type="text" placeholder="0.00"
                                value={addPayrollBody.travelAllowance}
                                onChange={addPayrollOnChange("travelAllowance")}
                                />
                            </div>
                            <div className="global-single-input">
                                <p>Education Allowance</p>
                                <input type="text" placeholder="0.00"
                                value={addPayrollBody.educationAllowance}
                                onChange={addPayrollOnChange("educationAllowance")}
                                />
                            </div>
                            <div className="global-single-input">
                                <p>Other Allowance</p>
                                <input type="text" placeholder="0.00"
                                value={addPayrollBody.otherAllowance}
                                onChange={addPayrollOnChange("otherAllowance")}
                                />
                            </div>
                            <div className="global-single-input">
                                <p>Over Time(H)</p>
                                <input type="text" placeholder="0.00"
                                value={addPayrollBody.overTimeHr}
                                onChange={addPayrollOnChange("overTimeHr")}
                                />
                            </div>
                        </div>
                        {/* <div className="staff-add-payroll-body-section-bottom-ctn">
                            <hr className="global-hr"/>
                            <div className="global-single-input auto-complete">
                                <p>Other Allowance</p>
                                <Autocomplete
                                 sx={{width:"100%"}}
                                 options={allowanceList}
                                 getOptionLabel={(option)=>option}
                                 renderInput={(params)=>
                                    <TextField {...params}/>
                                 }
                                 onChange={addPayrollOnChange("otherAllowanceSelect")}
                                />
                            </div>
                            {
                             selectedAllowance!==null &&
                            <div className="payroll-body-section-bottom-ctn-extra">
                              <p>{selectedAllowance}</p>
                              <div className="global-single-input">
                                  <input type="text" placeholder="0.00" onChange={addPayrollOnChange("otherAllowance")} 
                                     value={addPayrollBody.otherAllowance}/>
                              </div>
                            </div>
                            }
                        </div> */}
                    </div>
                    <div className="staff-add-payroll-body-section-footer">
                        <p>Total Earnings</p>
                        <div className="global-single-input">
                            <input type="text" placeholder="0.00"
                             value={addPayrollBody.totalEarning} disabled
                            />
                        </div>
                    </div>
                   </div> 
                   <div className="staff-add-payroll-body-container-parent"> 
                    <div className="staff-add-payroll-body-section">
                        <h4>&nbsp;</h4>
                        {/* <h4>Salary Information</h4> */}
                        <p className="add-payroll-body-earning-p" style={{color:"red"}}>Deductions</p>
                        <div className="staff-add-payroll-body-section-input-field">
                            <div className="staff-add-payroll-body-section-input-two">
                                <div className="global-single-input">
                                    <p>Loan</p>
                                    <input type="text"  placeholder="0.00"
                                    value={addPayrollBody.loan}
                                    onChange={addPayrollOnChange("loan")}
                                    />
                                </div>
                                <div className="global-single-input" style={{marginRight:0}}>
                                    <p>EMI (Monthly)</p>
                                    <input type="text" placeholder="0.00" 
                                    value={addPayrollBody.emiMonthly}
                                    onChange={addPayrollOnChange("emiMonthly")}
                                    />
                                </div>
                            </div>
                            <div className="global-single-input">
                                <p>EPF</p>
                                <input type="text"  placeholder="0.00"
                                value={addPayrollBody.epf}
                                onChange={addPayrollOnChange("epf")}
                                />
                            </div>
                            <div className="global-single-input">
                                <p>ESI</p>
                                <input type="text" placeholder="0.00"
                                value={addPayrollBody.esi}
                                onChange={addPayrollOnChange("esi")} 
                                />
                            </div>
                            <div className="global-single-input">
                                <p>TDS</p>
                                <input type="text" placeholder="0.00"
                                value={addPayrollBody.tds}
                                onChange={addPayrollOnChange("tds")}
                                 />
                            </div>
                            <div className="global-single-input">
                                <p>Leave</p>
                                <input type="text" placeholder="0"
                                value={addPayrollBody.leave}
                                onChange={addPayrollOnChange("leave")} 
                                />
                            </div>
                            <div className="global-single-input">
                                <p>Medical Claim</p>
                                <input type="text" placeholder="0.00"
                                value={addPayrollBody.medicalClaim}
                                onChange={addPayrollOnChange("medicalClaim")} 
                                />
                            </div>
                            <div className="global-single-input">
                                <p>Other Deduction</p>
                                <input type="text" placeholder="0.00"
                                value={addPayrollBody.otherDeduction}
                                onChange={addPayrollOnChange("otherDeduction")} 
                                />
                            </div>
                        </div>
                        {/* <div className="staff-add-payroll-body-section-bottom-ctn">
                            <hr className="global-hr" />
                            <div className="global-single-input auto-complete">
                                <p>Other Deductions</p>
                                <Autocomplete
                                 sx={{width:"100%"}}
                                 options={deductionList}
                                 getOptionLabel={(option)=>option}
                                 renderInput={(params)=>
                                    <TextField {...params}/>
                                 }
                                 onChange={addPayrollOnChange("otherDeductionSelect")}
                                />
                            </div>
                            {
                            selectedDeduction!==null &&
                            <div className="payroll-body-section-bottom-ctn-extra">
                                <p>{selectedDeduction}</p>
                                <div className="global-single-input">
                                    <input type="text" placeholder="0.00" value={addPayrollBody.otherDeduction}
                                        onChange={addPayrollOnChange("otherDeduction")}
                                    />
                                </div>
                            </div>
                            }
                        </div> */}
                    </div>
                        <div className="staff-add-payroll-body-section-footer">
                                <p>Total Deduction</p>
                                <div className="global-single-input">
                                    <input type="text" placeholder="0.00" 
                                    value={addPayrollBody.totalDeduction} disabled
                                    />
                                </div>
                        </div>
                   </div> 
                </div>
                <div className="staff-payroll-payment-ctn" style={{width:"auto"}}>
                <h4>Payment</h4>
                <div className="staff-payroll-payment-ctn-input-field">
                    <div className="global-single-input">
                        <p>Amount</p>
                        <input type="number" 
                            value={addPayrollBody?.paymentAmt} 
                            onChange={addPayrollOnChange("paymentAmt")}
                            />
                        {
                         amountAlert?   
                        <p className="doc-validation-alert">fill this field !!</p>
                        :""
                        }
                    </div>
                    <div className="global-single-input">
                        <p>Payment Method</p>
                        <Select 
                        value={addPayrollBody?.paymentMethod} onChange={addPayrollOnChange("paymentMethod")}
                        >
                            <MenuItem value="cheque">Cheque</MenuItem>
                            <MenuItem value="netbanking">NET Banking</MenuItem>
                            <MenuItem value="upi">UPI</MenuItem>
                        </Select>
                    </div>
                    <div className="global-single-input auto-complete">
                        <p>Bank Name</p>
                        <Autocomplete
                         sx={{width:"100%"}}
                         options={Banks}
                         getOptionLabel={(option)=>option?.name}
                         renderInput={(params)=>(
                            <TextField {...params} placeholder="Select Bank Name"/>
                         )}
                         onChange={(e,newValue)=>{
                            setAddPayrollBody({...addPayrollBody,bankName:newValue?.name})
                        }    
                        }
                         value={selectedBankName}
                         disableClearable={true}
                        />
                    </div>
                    <div className="global-single-input">
                        <p>Account No</p>
                        <input type="text"
                        value={addPayrollBody?.accNo} 
                        onChange={addPayrollOnChange("accNo")}
                        />
                        {
                         accNoAlert?   
                        <p className="doc-validation-alert">fill this field !!</p>
                        :""
                        }
                    </div>
                    <div className="global-single-input">
                        <p>IFSC</p>
                        <input type="text" 
                        value={addPayrollBody?.ifscCode} 
                        onChange={addPayrollOnChange("ifscCode")}
                        />
                        {
                         ifscAlert?   
                        <p className="doc-validation-alert">fill this field !!</p>
                        :""
                        }
                    </div>
                </div>
                {/* <div className="staff-payroll-payment-ctn-buttons">
                    <button className="btn btn-secondary-outlined"
                     onClick={()=>setAddPayrollPayment(false)}
                    >
                        Cancel
                    </button>
                    <button className="btn btn-primary" 
                    onClick={addPayrollFinalSubmit}>
                        Payment
                    </button>
                </div> */}
            </div>
                <div className="staff-payroll-edit-bottom-container">
                    <button className="btn btn-secondary-outlined" onClick={()=>setEmpPayrollEditOpen(false)}>Cancel</button>
                    <button className="btn btn-primary" onClick={editSinglePayrollSubmit}>Edit</button>
                </div>
            </>
            </Dialog>

            <SuccessSnackbar
                open={successSnackOpen}
                handleClose={closeSuccessSnack}
                message={snackMsg}
            />
            <ErrorSnackbar
                open={errorSnackOpen}
                handleClose={closeErrorSnack}
                message={snackMsg}
            />
        </>
    )
}