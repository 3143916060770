import React, { useState } from "react";
import "../../../../css/Product List/Pages/productList.css";
import { Dialog, IconButton, Skeleton, Tooltip } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import DemoBarcode from "../../../../Assets/Images/barcode.png";
import NoImage from "../../../../Assets/Images/no-image.png";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect } from "react";
import {
  ProductListFilterAPICall,
  viewAllProductsForProductListAPICall,
} from "../../../../API/Product List/productListAPI";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";

import { sortGeneral } from "../../../../Js/generalFunctions";
import { useSelector } from "react-redux";
import CategorySearchandFilter from "../../../Single Components/CategorySearchandFilter";
import { getCurrentTime } from "../../../../Js/Date";
import CustomPagination from "../../../Single Components/CustomPagination";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { setFilterActive } from "../../../Single Components/listSlice/filterCategorySlice";
import store from "../../../../Redux/store";
export const ProductList = () => {
    const navigateTo = useNavigate()

  // store
  const allProductsList = useSelector(
    (state) => state.allProductsListSlice.value
  );
console.log(allProductsList);
const filtereDataOptions = useSelector((state) => state.listCategory);


  //Loading State
  const [isLoading, setIsLoading] = useState(false);
  //Stock Ascending or descending
  const [isAscending, setIsAscending] = useState(false);
  //Snackbar States
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [snackMsg, setSnackMsg] = useState(
    "Failed to fetch data, refresh page to try again!!"
  );
  const [importPopup, setImportPopup] = useState(false)

  const [productsFiltered, setProductsFiltered] = useState([]);
  //Single View Dialog States
  const [openSingleView, setOpenSingleView] = useState(false);
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const totalPages =productsFiltered?.pages;

  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };
  //Search Filtered Array
 

  //Single View States
  const singleViewInitialStates = {
    image1: null,
    image2: null,
    image3: null,
    image4: null,
    barcode: "",
    productName: "",
    nativeproductName: "",
    productId: "",
    releaseDate: "",
    mrp: "",
    rate: "",
    calculatedPrice: "",
    size: [],
    color: "",
    fitting: "",
    productCare: "",
    materialComposition: "",
    stock: "",
    styleName: "",
    pattern: "",
    unit: "",
    material: "",
    brand: "",
    countryOfOrigin: "",
    returnPolicy: "",
    description: "",
    additionalInfos: [],
    youtubeUrl: "",
  };
  const [singleViewStates, setSingleViewStates] = useState(
    singleViewInitialStates
  );

  //Close Success Snackbar
  const closeSuccessSnackbar = () => {
    setOpenSuccessSnackbar(false);
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };

  //Close Snackbar
  const closeErrorSnackbar = () => {
    setOpenErrorSnackbar(false);
  };
  //Close Single View
  const closeProductSingleView = () => {
    setOpenSingleView(false);
  };
  //Sort Ascending Order by Stock
  const sortStockAscending = () => {
    setIsAscending(!isAscending);
    let sorted = sortGeneral(productsFiltered, "Number", "closingStock", true);
    setProductsFiltered(sorted);
  };
  //Sort descending Order by Stock
  const sortStockDescending = () => {
    setIsAscending(!isAscending);
    let sorted = sortGeneral(productsFiltered, "Number", "closingStock", false);
    setProductsFiltered(sorted);
  };

  //Sort Ascending Order by Name
  //TODO Sot by string is not accurate(19-10-22)
  const sortProdNameAscending = () => {
    setIsAscending(!isAscending);
    let sorted = sortGeneral(productsFiltered, "String", "name", true);
    setProductsFiltered(sorted);
  };
  //Sort Descending order by Name
  const sortProdNameDescending = () => {
    setIsAscending(!isAscending);
    let sorted = sortGeneral(productsFiltered, "String", "name", false);
    setProductsFiltered(sorted);
  }
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };

  const clickRow=(row)=>{
    store.dispatch( setFilterActive(window.location.pathname))
    localStorage.setItem("singleViewById", row?._id)
    navigateTo("/userdashboard/productList/singleview")
  }
  //*useEffects
  useEffect(() => {
    // viewAllProductsForProductListAPICall(
    //   {},
    //   setIsLoading,
    //   setOpenErrorSnackbar,
    //   setSnackMsg
    // );
  }, []);


  useEffect(() => {

    setProductsFiltered(allProductsList || []);
  }, [allProductsList]);

    console.log( FormDataInfo?.branchList,'lll')


  useEffect(() => {
   
    if (FormDataInfo?.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData &&  ProductListFilterAPICall({
        // fromDate: `${FormDataInfo.fromDate} ${getCurrentTime()}`,
        // toDate: `${FormDataInfo.toDate} ${getCurrentTime()}`,
        branchList: FormDataInfo?.branchList,
        location: FormDataInfo?.location,
        mainCategory: FormDataInfo?.category,
        posCategory: FormDataInfo?.posCategory,
        search: FormDataInfo?.search,
        index: currentPage-1,
      }, setIsLoading);

    };
  
  }, [FormDataInfo,
     currentPage
  ]);
  console.log(selectedBranch)
  console.log(selectedBranch?.storeCode)
  return (
    <>
      <div className="global-page-parent-container">
        {/* <p className="breadcrumb">Product List </p> */}
        <div className='global-white-bg-container'>

          <div className='create-button-blue-container'  style={{ margin: "1%", display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
          {FormDataInfo?.branchList?.length > 0 && (
            <div>
            <h3>Branch name: 
                  {selectedBranch?.storeCode||filtereDataOptions?.filterCategorylist?.branchList?.storeCode}
                  -{selectedBranch?.branchName||filtereDataOptions?.filterCategorylist?.branchList?.branchName}</h3>
            </div>
          )}
            {/* <div className="justify-space-between" style={{ justifyContent: "flex-end", width: "55%" }}> */}
            <div style={{
              display: 'flex',
              justifyContent:'flex-end',
              alignItems: 'center',
              width: FormDataInfo?.branchList?.length ? 'auto' : '100%'
            }}>
              <CategorySearchandFilter
                // statusOptions={['POSTED', 'DRAFT']}
                onData={handleDataFromChild}
                style={{ width: "100%" }}
                isLocation={true}
                isCategory={true}
                isposCategory={true}
                isDate={false}
                onBranchSelect={handleBranchSelect}
              // isStatus={true}
              />

              <IconButton
                style={{ width: "100px" }}

                className="print-icon-container">
                <i class="bi bi-printer printer-icon"></i>
              </IconButton>
              <IconButton
                style={{ width: "100px" }}
                className="download-icon-container">
                <i class="bi bi-arrow-down-circle download-icon"></i>
              </IconButton>
            </div>
          </div>
          <div className='create-button-blue-container' style={{ margin: "0 1%" }}>
           
            <h3>Products List</h3>
            <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />
          </div>
          <div className='new-global-table-container'>
            <table>
              <thead>
                <tr>
                  <th>Product ID</th>
                  <th>
                    Product Name
                    {isAscending ? (
                      <IconButton onClick={sortProdNameAscending}>
                        <ArrowUpward sx={{ fontSize: "1rem" }} />
                      </IconButton>
                    ) : (
                      <IconButton onClick={sortProdNameDescending}>
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                      </IconButton>
                    )}
                  </th>
                  <th>Attributes</th>
                  <th>UOM</th>
                  <th>Purchase UOM</th>
                  <th>POS Category</th>
                  <th>Barcode</th>
                  <th className="closing-stock-column" style={{ color: "red" }}>
                    Closing Stock{" "}
                    {isAscending ? (
                      <IconButton onClick={sortStockAscending}>
                        <ArrowUpward sx={{ fontSize: "1rem" }} />
                      </IconButton>
                    ) : (
                      <IconButton onClick={sortStockDescending}>
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                      </IconButton>
                    )}
                  </th>
                  <th style={{color:"blue"}}>Unit Cost</th>
                  <th className="closing-stock-column">
                    Stock Value
                  </th>
                </tr>
              </thead>
              <tbody >
                {

                  isLoading ? (
                    [...Array(10)].map((r, i) => (
                      <tr key={i}>
                        <td colSpan={11}>
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={40}
                          />
                        </td>
                      </tr>
                    ))
                  ) :

                    productsFiltered?.list?.length !== 0 ? productsFiltered?.list?.slice(0)?.reverse()?.map((r, i) => (
                      // {productsFiltered?.length > 0 ? (
                      //   productsFiltered
                      //     ?.map((r, i) => (
                      <tr key={i} onClick={()=>clickRow(r)}>
                        <td>{r?.prodId}</td>
                        <td>{r?.name}</td>
                        <td className='table-data-box' style={{ textAlign: "center" }}>
                          <div className='table-data-round-parent'>

                            {
                              r?.attribute?.map((r, i) => (
                                <div className='round-border' key={i}>{r?.value}</div>
                              ))
                            }
                          </div>
                        </td>
                        <td>{r?.UOMNAME}</td>
                        <td>{r?.PUCHASEUOMNAME}</td>
                        <td>{r?.POSCATNAME}</td>
                        <td>{r?.barcode}</td>
                        <td className="closing-stock-column" style={{ color: "red" }}>
                          {Number(r?.closingStock)?.toFixed(2)}
                        </td>
                        <td>{r?.cost?.toFixed(2)}</td>
                        <td className="closing-stock-column">
                          {Number(r?.stockvalue)?.toFixed(2)}
                        </td>
                      </tr>
                    ))
                      :
                      <tr>
                        <td colSpan={11}>No Data</td>
                      </tr>
                }

              </tbody>
            </table>
          </div>
        </div>

      </div>

      {/* Product Single View Dialog */}
      <Dialog open={openSingleView} maxWidth="lg" onKeyDown={(e) => e.key === "Escape" && setOpenSingleView(false)}>
        <div className="product-single-view-parent">
          <div className="product-single-view-top-bar">
            <h2>Product View</h2>
          </div>
          <div className="product-single-view-content">
            <div className="product-single-left">
              <div className="carousel-container">
                <Carousel>
                  <img src={singleViewStates.image1 || NoImage} alt="" />
                  <img src={singleViewStates.image2 || NoImage} alt="" />
                  <img src={singleViewStates.image3 || NoImage} alt="" />
                  <img src={singleViewStates.image4 || NoImage} alt="" />
                </Carousel>
              </div>
              <div className="barcode-container">
                <p>Barcode</p>
                <div className="barcode-img-container">
                  <img src={DemoBarcode} alt="barcode" />
                </div>
              </div>
            </div>
            <div className="product-single-right">
              <div className="single-data-row">
                <p className="label">Product Name</p>
                <p className="colon">:</p>
                <p className="prod-name">
                  {singleViewStates.productName || "-NIL-"}
                </p>
              </div>
              <div className="single-data-row">
                <p className="label">Product ID</p>
                <p className="colon">:</p>
                <p className="prod-name">
                  {singleViewStates.productId || "-NIL-"}
                </p>
              </div>
              {/* show MRP only in India  */}
              <div className="single-data-row">
                <p className="label">MRP </p>
                <p className="colon">:</p>
                <p className="prod-name">{singleViewStates.mrp || "-NIL-"}</p>
              </div>
              <div className="single-data-row">
                <p className="label"> Rate </p>
                <p className="colon">:</p>
                <p className="prod-name">{singleViewStates.rate || "-NIL-"}</p>
              </div>
              <div className="single-data-row">
                <p className="label">Calculated Selling Price</p>
                <p className="colon">:</p>
                <p className="prod-name">
                  {singleViewStates.calculatedPrice || "-NIL"}
                </p>
              </div>
              <div className="single-data-row">
                <p className="label">Size</p>
                <p className="colon">:</p>
                <p className="prod-name">
                  {singleViewStates?.size?.map((s) => (
                    <span>{s?.brandUnit},</span>
                  )) || "-NIL-"}
                </p>
              </div>
              <div className="single-data-row">
                <p className="label">Color</p>
                <p className="colon">:</p>
                <p className="prod-name"> {singleViewStates.color || "-NIL"}</p>
              </div>
              <div className="single-data-row">
                <p className="label">Fitting</p>
                <p className="colon">:</p>
                <p className="prod-name">
                  {" "}
                  {singleViewStates.fitting || "-NIL"}
                </p>
              </div>
              <div className="single-data-row">
                <p className="label">Product Care</p>
                <p className="colon">:</p>
                <p className="prod-name">
                  {singleViewStates.productCare || "-NIL"}
                </p>
              </div>
              <div className="single-data-row">
                <p className="label">Material Composition</p>
                <p className="colon">:</p>
                <p className="prod-name">
                  {singleViewStates.materialComposition || "-NIL"}
                </p>
              </div>
              <div className="single-data-row">
                <p className="label">Stock</p>
                <p className="colon">:</p>
                <p className="prod-name">{singleViewStates.stock || "-NIL"}</p>
              </div>

              <div className="single-data-row">
                <p className="label">Style Name</p>
                <p className="colon">:</p>
                <p className="prod-name">
                  {singleViewStates.styleName || "-NIL"}
                </p>
              </div>
              <div className="single-data-row">
                <p className="label">Pattern</p>
                <p className="colon">:</p>
                <p className="prod-name">
                  {singleViewStates.pattern || "-NIL"}
                </p>
              </div>
              <div className="single-data-row">
                <p className="label">Unit</p>
                <p className="colon">:</p>
                <p className="prod-name">{singleViewStates.unit || "-NIL"}</p>
              </div>
              <div className="single-data-row">
                <p className="label">Material</p>
                <p className="colon">:</p>
                <p className="prod-name">
                  {singleViewStates.material || "-NIL"}
                </p>
              </div>
              <div className="single-data-row">
                <p className="label">Brand</p>
                <p className="colon">:</p>
                <p className="prod-name">{singleViewStates.brand || "-NIL"}</p>
              </div>
              <div className="single-data-row">
                <p className="label">Country of Origin</p>
                <p className="colon">:</p>
                <p className="prod-name">
                  {singleViewStates.countryOfOrigin || "-NIL"}
                </p>
              </div>
              <div className="single-data-row">
                <p className="label">Return Policy</p>
                <p className="colon">:</p>
                <p className="prod-name">
                  {(singleViewStates.returnPolicy &&
                    `within ${singleViewStates.returnPolicy} days`) ||
                    "-NIl-"}
                </p>
              </div>
              <div className="single-data-row">
                <p className="label">Description</p>
                <p className="colon">:</p>
                <p className="prod-name">
                  {singleViewStates.description || "-NIL"}
                </p>
              </div>
              <div className="single-data-row">
                <p className="label">Additional Info</p>
                <p className="colon">:</p>
                <p className="prod-name">
                  {singleViewStates.additionalInfos?.map((info) => (
                    <span>{info},</span>
                  )) || "-NIL"}
                </p>
              </div>
              <div className="single-data-row">
                <p className="label">Youtube URL</p>
                <p className="colon">:</p>
                <p className="prod-name">
                  {singleViewStates.youtubeUrl || "-NIL"}
                </p>
              </div>
            </div>
          </div>
          <div className="close-btn-container">
            <button
              className="btn btn-primary"
              onClick={closeProductSingleView}
            >
              CLOSE
            </button>
          </div>
        </div>
      </Dialog>
      

      <SuccessSnackbar
        open={openSuccessSnackbar}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={openErrorSnackbar}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </>
  );
};
