import {createSlice} from '@reduxjs/toolkit'

export const purchaseReportSlice=createSlice({
    name:"purchaseReportSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        get_purchase_report:(state,action)=>{
            state.value=action.payload.purchaseReportData
        }
    }
})
export const {get_purchase_report} =purchaseReportSlice.actions;
export default purchaseReportSlice.reducer;
