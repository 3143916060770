import React, { useEffect, useState } from 'react'
import './InvoiceMarginReport.css'
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import { ProductMarginReportSingleViewAPIcall } from './GeneralReportAPI';
import { useSelector } from 'react-redux';
import { filterObjFromList } from '../../../../../Js/generalFunctions';
import { convertDateFormat } from '../../../../../Js/Date';
import { useLocation, useNavigate } from 'react-router-dom';
import { viewAllCurrencyUpdated } from '../../../../../API/Settings/Currency/CurrencyAPI';
import { companyProfileInfoForUser } from '../../../../../API/Settings/Company Settings/companySettingsAPI';

function ProductMarginReportSingleView() {
    let navigate = useNavigate();
    let location=useLocation()

    const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue)
    const currencyListUpdated = useSelector(
        (state) => state.viewUpdatedCurrency.value
    );

    const productMarginSingleList = useSelector((state) => state?.generalReport?.ProductMarginReportSingleList)
    console.log(productMarginSingleList,'jjj')
    const decimalPosition = localStorage.getItem("decimalPosition");
    const singleViewId = localStorage.getItem("productSingleViewId")

    const [FormDataInfo, setFormDataInfo] = useState([])
    const [symbol, setSymbol] = useState(null);
    const [isLoading, setIsLoading] = useState(false);


    const handleDataFromChild = (data) => {
        setFormDataInfo(data);
    };

    useEffect(() => {
        if (FormDataInfo.length !== 0) {
            ProductMarginReportSingleViewAPIcall({
                _id: singleViewId,
                search: FormDataInfo?.search,
                employee: FormDataInfo?.createdBy.length === 0 ? '' : FormDataInfo?.createdBy,
                vendor: FormDataInfo?.customer?.length === 0 ? '' : FormDataInfo?.customer,
                branchId: FormDataInfo?.branchList,

            })
        }
    }, [FormDataInfo, singleViewId])

    useEffect(() => {
        let currencyObj = filterObjFromList("_id", currencyListUpdated, "currency", companyProfileData)
        setSymbol(currencyObj?.symbol)
    }, [companyProfileData, currencyListUpdated])

    useEffect(() => {
        viewAllCurrencyUpdated({ status: 0 });
        companyProfileInfoForUser();
    }, [])

    return (
        <div className="in-or-out-container">
            <div className="in-or-out-report-wrapper">
                <div className="sub-nav-head">

                    {/* <div>
                        <div className="path-name-wrapper" style={{ backgroundColor: '#c3d4f7', maxWidth: "17em" }}>
                            <h3 style={{ color: '#06066f' }} className="path-name">Product Margin Report</h3>
                        </div>
                    </div> */}
                     <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "0.8em",
            }}
          >
           
            <div
              style={{
                background: "#d2deff",
                borderRadius: "0",
                maxWidth: "20em",
                cursor:'pointer'
              }}
              className="path-name-wrapper"
              onClick={() => {
                navigate(
                  "/userdashboard/accounts/report/generalReport/productMarginReport"
                );
                localStorage.removeItem("singleViewById");
              }}
            >
              <h3 className="path-name" style={{ color: "black" }}>
              Product Margin Report
              </h3>
            </div>
            <div
              className="path-name-wrapper"
              style={{
                maxWidth: "17em",
                transition: "max-width 1s ease-in-out",
              }}
            >
              <h3 className="path-name">
              {location.state.productName}
              </h3>
            </div>
          </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width:'69%'
                    }}>
                        <CategorySearchandFilter
                            onData={handleDataFromChild}
                            isCustomer={true}
                            isCreatedBy={true}

                        />
                        <IconButton
                            style={{ width: "100px" }}

                            className="print-icon-container">
                            <i class="bi bi-printer printer-icon"></i>
                        </IconButton>
                        <IconButton
                            style={{ width: "100px" }}
                            className="download-icon-container">
                            <i class="bi bi-arrow-down-circle download-icon"></i>
                        </IconButton>
                    </div>
                </div>
                <div className="invoice-table-container">
                    <div className="invoice-main-div">
                        <h2>Product Margin Report</h2>
                        <table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Invoice</th>
                                    <th>Employee</th>
                                    <th>Customer</th>
                                    <th>Qty</th>
                                    <th>Cost</th>
                                  
                                    <th>Revenue</th>
                                    <th>Profit </th>
                                    <th>Profit Margin %</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? (
                                    [...Array(10)].map((r, i) => (
                                        <tr key={i}>
                                            <td colSpan={8}>
                                                <Skeleton
                                                    variant="rectangular"
                                                    animation="wave"
                                                    width={"100%"}
                                                    height={40}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                ) : productMarginSingleList?.report !== undefined && productMarginSingleList?.report?.length !== 0 ? (
                                    productMarginSingleList?.report
                                        ?.slice(0)
                                        ?.map((r, i) => (
                                            <tr key={i}>
                                                <td>{convertDateFormat(r?.date)}</td>
                                                <td>{r?.invoice}</td>
                                                <td>{r?.employee}</td>
                                                <td>{r?.vendorName}</td>
                                                <td>{r?.qty?.toFixed(decimalPosition)}</td>
                                                <td>{symbol}&nbsp;{r?.cost?.toFixed(decimalPosition)}</td>
                                               
                                                <td>{symbol}&nbsp;{r?.revenue?.toFixed(decimalPosition)}</td>
                                                <td>{symbol}&nbsp;{r?.profit?.toFixed(decimalPosition)}</td>
                                                <td>{r?.profitMargin?.toFixed(decimalPosition)}</td>
                                            </tr>
                                        ))
                                ) : (
                                    <tr>
                                        <td style={{ textAlign: 'center' }} colSpan={8}>No Data</td>
                                    </tr>
                                )}
                            </tbody>
                            <tr style={{ textAlign: "end", position: "sticky", bottom: "0" }}>
                                <td colSpan={4} style={{ fontWeight: 'bold' }}>Total</td>
                                <td style={{ fontWeight: 'bold' }}>{productMarginSingleList?.total !== undefined && productMarginSingleList?.total?.qty?.toFixed(decimalPosition)}</td>
                                <td style={{ fontWeight: 'bold' }}>{symbol}&nbsp;{productMarginSingleList?.total !== undefined && productMarginSingleList?.total?.cost?.toFixed(decimalPosition)}</td>
                               
                                <td style={{ fontWeight: 'bold' }}>{symbol}&nbsp;{productMarginSingleList?.total !== undefined && productMarginSingleList?.total?.revenue?.toFixed(decimalPosition)}</td>
                                <td style={{ fontWeight: 'bold' }}>{symbol}&nbsp;{productMarginSingleList?.total !== undefined && productMarginSingleList?.total?.profit?.toFixed(decimalPosition)}</td>
                                <td style={{ fontWeight: 'bold' }}>{productMarginSingleList?.total !== undefined && productMarginSingleList?.total?.profitMargin?.toFixed(decimalPosition)}</td>
                            </tr>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ProductMarginReportSingleView