import { Dialog, IconButton, MenuItem, Select, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { viewAllBranchesAPICall } from "../../../../API/Settings/Company Settings/companySettingsAPI";
import {
  approveSingleLoanAPICall,
  viewAllEmployeeLoan,
} from "../../../../API/Staff/HRM/employeeLoanAPI";
import { convertDateFormat } from "../../../../Js/Date";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import { ProductList } from "../../Product List/Pages/ProductList";
// CSS from staffLoanReport css
export const StaffLoanApprovals = () => {
  const [isModify, setIsModify] = useState(false);
  const [empLoanDetailsView, setEmpLoanDetailsView] = useState(false);
  const [singleLoanView, setSingleLoanView] = useState([]);
  const [filteredBranchName, setFilteredBranchName] = useState([]);
  //Search KeyWord State
  const [searchKeyword, setSearchKeyword] = useState("");
  //Search Filtered Array
  const [loanFiltered, setLoanFiltered] = useState([]);
  // loading states
  const [isLoading, setIsLoading] = useState(false);
  // snackbar states
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [snackMsg, setSnackMsg] = useState("Error!!");
  //Get search keyword
  const getSearchKeyword = (e) => {
    setSearchKeyword(e.target.value);
  };
  const closeErrorSnackbar = () => {
    setErrorSnackbar(false);
  };
  // use selector slice viewLoan all employees,view allbranches
  const empAllLoanList = useSelector(
    (state) => state.allEmployeeLoanSlice.value
  );
  const allBranchList = useSelector((state) => state.allBranchesSlice.value);

  // update listener to modify viewAllloanAPI call
  const updateListener = () => {
    setIsModify(!isModify);
  };

  //  loan id of single employee
  const loanIdOfList = singleLoanView[0]?._id;
  // Loan accept sending body
  const loanEmpApprovedBody = {
    type: 0,
    loanId: loanIdOfList,
  };
  // Loan reject sending body
  const loanEmpRejectedBody = {
    type: 1,
    loanId: loanIdOfList,
  };
  // filter loan list

  const filterLoanList = (chars, loanList) => {
    let searchResult = [];
    let filteredArray = [];

    if (chars !== "") {
      searchResult = loanList?.filter(
        (obj) =>
          obj?.NAME?.toLowerCase()?.includes(chars?.toLowerCase()) ||
          obj?.EMPLOYEEID?.toLowerCase()?.includes(chars?.toLowerCase())
      );
      filteredArray = searchResult;
    } else {
      filteredArray = loanList;
    }
    return filteredArray;
  };
  // extracting singleLoan object from allLoans
  const viewSingleAppliedLoan = (id) => {
    if (empAllLoanList !== undefined) {
      const filteredSingleView = empAllLoanList?.filter((obj) => {
        if (obj?._id === id) {
          return obj;
        }
      });
      setSingleLoanView(filteredSingleView);
    }
  };

  // extracting branchName from branchId
  const filterBranchNameById = () => {
    setEmpLoanDetailsView(true);
    let filteredBranchName = allBranchList?.filter(
      (obj, i) => obj?.storeCode === singleLoanView[0].branchId
    );
    setFilteredBranchName(filteredBranchName);
  };

  //  click loan approved
  const clickLoanEmpApproved = () => {
    approveSingleLoanAPICall(loanEmpApprovedBody, updateListener);
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Loan Has Been Approved",
      showConfirmButton: false,
      timer: 1500,
    });
  };
  //  click loan rejected
  const clickLoanEmpRejected = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f80000",
      confirmButtonText: "Submit",
      customClass: {
        cancelButton: "sweet-alert-cancel-button",
        confirmButton: "sweet-alert-submit-button",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        approveSingleLoanAPICall(loanEmpRejectedBody, updateListener);
        Swal.fire("Rejected!", "Loan Request Is Declined.", "error");
      }
    });
  };
  let navigate = useNavigate();
  const switchToAddNewLoan = (id, loanId) => {
    localStorage.setItem("loanIdApprove", id);
    localStorage.setItem("singleLoaniId", loanId);
    navigate("/userdashboard/staff/loan/addNewLoan");
  };
  //  API Calls
  useEffect(() => {
    viewAllBranchesAPICall();
    viewAllEmployeeLoan(setIsLoading, setErrorSnackbar, setSnackMsg);
  }, [isModify]);

  useEffect(() => {
    if (empAllLoanList !== undefined) {
      setLoanFiltered(empAllLoanList);
      let filteredArray = filterLoanList(searchKeyword, empAllLoanList);
      setLoanFiltered(filteredArray);
    } else {
      setLoanFiltered([]);
    }
  }, [searchKeyword, empAllLoanList]);

  // const filterLoanlist=(char,loanList)=>{

  //    let searchResult=[]
  //    let filteredArray=[]

  //    if(char!==""){
  //       searchResult=loanList?.filter((obj)=>
  //          obj?.NAME?.toLowerCase()?.includes(char?.toLowerCase())||
  //          obj?.NAME?.toLowerCase()?.includes(char?.toLowerCase())
  //          )
  //       filteredArray=searchResult
  //    }
  //    else(
  //       filteredArray=loanList
  //    )
  //    return filteredArray;
  // }

  // useEffect(()=>{
  //    if(empAllLoanList!==undefined){
  //       setLoanFiltered(empAllLoanList);
  //       let filteredArray=filterLoanlist(searchKeyword,empAllLoanList);
  //       setLoanFiltered(filteredArray)
  //    }
  //    else {
  //       setLoanFiltered([]);
  //    }
  // },[searchKeyword,empAllLoanList])
  return (
    <>
      <div className="global-page-parent-container">
        <p className="breadcrumb">Staff &gt; Loan &gt; Loan Approval</p>

        <div className="staff-loan-approval-top-header">
          <h3>Approvals</h3>
          <div className="staff-loan-approval-top-header-right">
            <div className="view-order-search-container view-order-admin-search-container">
              <input
                placeholder="Search Employee,Employee ID"
                value={searchKeyword}
                onChange={getSearchKeyword}
              />
              <IconButton>
                <i class="bi bi-search search-icon-order"></i>
              </IconButton>
            </div>
          </div>
        </div>
        <div className="global-table-container">
          <table className="global-table">
            <thead>
              <tr>
                <th>Loan ID</th>
                <th>Employee ID</th>
                <th>Name</th>
                <th>Image</th>
                <th>Mobile</th>
                <th>Loan Amount</th>
                <th>Actual Amount</th>
                <th>Applied Date</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={10}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={10}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={10}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={10}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={10}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={10}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={10}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={10}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {loanFiltered?.length !== 0 ? (
                  loanFiltered
                    ?.slice(0)
                    ?.reverse()
                    ?.map((r, i) => (
                      <tr key={i}>
                        <td>{r?.loanId}</td>
                        <td>{r?.EMPLOYEEID}</td>
                        <td>{r?.NAME}</td>
                        <td>
                          <img
                            className="loan-approval-table-img"
                            src={r?.IMAGE}
                          />
                        </td>
                        <td>{r?.MOBILE}</td>
                        <td>{r?.loanAmount}</td>

                        <td>{r?.actualLoanAmount}</td>
                        <td>
                          {convertDateFormat(
                            r?.loanRequestedDate !== undefined &&
                              r?.loanRequestedDate?.split(" ")[0]
                          )}
                        </td>

                        <td>
                          {r?.loanStatus === "Approved" ? (
                            <p
                              className="status-loan-request-accepted"
                              onClick={() =>
                                switchToAddNewLoan(r?.empId, r?._id)
                              }
                            >
                              Accepted
                            </p>
                          ) : r?.loanStatus === "Rejected" ? (
                            <p className="status-text-stock-out-reject">
                              Rejected
                            </p>
                          ) : (
                            <>
                              <button
                                className="staff-table-check-box-tick"
                                onClick={clickLoanEmpApproved}
                                onMouseDown={() =>
                                  viewSingleAppliedLoan(r?._id)
                                }
                              >
                                <i class="bi bi-check2"></i>
                              </button>

                              <button
                                className="staff-table-check-box-wrong"
                                onClick={clickLoanEmpRejected}
                                onMouseDown={() =>
                                  viewSingleAppliedLoan(r?._id)
                                }
                              >
                                X
                              </button>
                            </>
                          )}
                        </td>
                        <td>
                          {/* {r?.loanStatus==="Approved"?(""):(
                                             
                                               
                                               
                                          )} */}

                          <IconButton
                            onClick={filterBranchNameById}
                            onMouseDown={() => viewSingleAppliedLoan(r?._id)}
                          >
                            <i class="bi bi-eye visibility-icon"></i>
                          </IconButton>
                          <IconButton>
                            <i class="bi bi-trash3 delete-icon"></i>
                          </IconButton>
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan={10}>No Records</td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
      </div>

      <Dialog
        open={empLoanDetailsView}
        maxWidth="lg"
        onKeyDown={(e) => e.key === "Escape" && setEmpLoanDetailsView(false)}
      >
        <h3 style={{ margin: "4% 0 0 4%" }}>
          Loan ID :{singleLoanView[0]?.loanId}
        </h3>
        <div className="dashboard-employee-loan-apply-loan">
          <h4>Loan Details</h4>
          <div className="dashboard-employee-loan-apply-loan-input-container">
            <div className="global-single-input apply-loan-input">
              <p>Employee Name</p>
              <input type="text" value={singleLoanView[0]?.NAME} disabled />
            </div>
            <div className="global-single-input apply-loan-input">
              <p>Employee ID</p>
              <input
                type="text"
                value={singleLoanView[0]?.EMPLOYEEID}
                disabled
              />
            </div>
            <div className="global-single-input apply-loan-input">
              <p>Contact</p>
              <input type="text" value={singleLoanView[0]?.MOBILE} disabled />
            </div>
            <div className="global-single-input apply-loan-input">
              <p>Department</p>
              <Select value="1" disabled>
                <MenuItem value="1">{singleLoanView[0]?.DEPARTMENT}</MenuItem>
              </Select>
            </div>

            <div className="global-single-input apply-loan-input">
              <p>Designation</p>
              <Select value="1" disabled>
                <MenuItem value="1">{singleLoanView[0]?.DESIGNATION}</MenuItem>
              </Select>
            </div>

            {filteredBranchName[0] === undefined ? (
              <div className="global-single-input apply-loan-input">
                <p>Branch</p>
                <input type="text" value="No Branches Available" disabled />
              </div>
            ) : (
              <div className="global-single-input apply-loan-input">
                <p>Branch</p>
                <input
                  type="text"
                  value={filteredBranchName[0].branchName}
                  disabled
                />
              </div>
            )}

            {/* <div className='global-single-input apply-loan-input'>
                   <p>Cheque No</p>
                   <input type="text" 
                     disabled
                     value={singleLoanView[0]?.chequeNo}
                  />
                </div> */}
          </div>
        </div>
        <div className="dashboard-employee-loan-apply-loan">
          <h4>Loan Details</h4>
          <div className="dashboard-employee-loan-apply-loan-input-container">
            <div className="global-single-input apply-loan-input">
              <p>Loan Amount</p>
              <input
                type="number"
                disabled
                value={singleLoanView[0]?.actualLoanAmount}
              />
            </div>

            {/* <div className='global-single-input apply-loan-input'>
                   <p>Per Month To Pay</p>
                   <input type="number"
                   disabled
                   value={singleLoanView[0]?.monthlyPay}/>
                </div>
                 */}

            <div className="global-single-input apply-loan-input">
              <p>Balance Amount</p>
              <input
                type="number"
                disabled
                value={singleLoanView[0]?.balanceAmount}
              />
            </div>

            {/* <div className='global-single-input apply-loan-input'>
                   <p>Guarentor's ID</p>
                   <input type="text"
                   disabled 
                   value={singleLoanView[0]?.guarantes}/>
                </div> */}
          </div>
        </div>
        <div className="dashboard-employee-loan-apply-loan">
          <h4>Account Information</h4>
          <div className="dashboard-employee-loan-apply-loan-input-container">
            <div className="global-single-input apply-loan-input">
              <p>Bank Name</p>
              <input type="text" value={singleLoanView[0]?.BANK} disabled />
            </div>
            <div className="global-single-input apply-loan-input">
              <p>Account Holder Name</p>
              <input
                type="text"
                value={singleLoanView[0]?.ACC_HOLDER_NAME}
                disabled
              />
            </div>
            <div className="global-single-input apply-loan-input">
              <p>Account No</p>
              <input type="text" value={singleLoanView[0]?.ACC_No} disabled />
            </div>
            <div className="global-single-input apply-loan-input">
              <p>IFSC</p>
              <input type="text" value={singleLoanView[0]?.IFSC} disabled />
            </div>

            <div className="global-single-input apply-loan-input">
              <p>Branch</p>
              <input
                type="text"
                value={singleLoanView[0]?.BANKBRANCH}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="dashboard-employee-loan-apply-loan">
          <h4>Dates</h4>
          <div className="dashboard-employee-loan-apply-loan-input-container">
            <div className="global-single-input apply-loan-input">
              <p>Loan Requested Date</p>
              <input
                type="text"
                value={
                  singleLoanView[0]?.loanRequestedDate !== undefined &&
                  convertDateFormat(
                    singleLoanView[0]?.loanRequestedDate?.split(" ")[0]
                  )
                }
                disabled
              />
            </div>
          </div>
        </div>
        <div className="dashboard-employee-loan-apply-loan-btn-container">
          <button
            onClick={() => setEmpLoanDetailsView(false)}
            className="btn btn-secondary"
          >
            Close
          </button>
        </div>
      </Dialog>
      <ErrorSnackbar
        open={errorSnackbar}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </>
  );
};
