import axios from "axios"
import { HEADERS } from "../../../../API/UrlAndPaths"
import store from "../../../../Redux/store"
import { set_bank_reconciliation_Singlelist, set_bank_reconciliation_list, set_bank_reconciliation_save, set_bank_reconciliation_table } from "./BankReconciliationSlice"

export const BankReconciliationTableAPI =async(body)=>{
    await axios
    .post("account/fetchdataforbankreconcilation",body, HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(set_bank_reconciliation_table({bankReconciliationTableData:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(set_bank_reconciliation_table({bankReconciliationTableData:undefined}))
    })
}

export const BankReconciliationSaveAPI=async(body,setState,setLoadingForm,savefunction)=>{
    setLoadingForm(true)
    await axios
    .post("account/addbankreconcilation",body, HEADERS)
    .then((res)=>{
        if(res.status===200){
            setLoadingForm(false)
            savefunction()
            // store.dispatch(set_bank_reconciliation_Singlelist({bankReconciliationSingleList:res.data}))
            store.dispatch( set_bank_reconciliation_save({bankReconciliationSaveData:res.data}))
            setState((prev)=>({...prev,message:"Drafted ",success:true}))
        }
    })
    .catch((err)=>{
        setLoadingForm(false)
        // store.dispatch( set_bank_reconciliation_save({bankReconciliationSaveData:undefined}))
        store.dispatch(set_bank_reconciliation_Singlelist({bankReconciliationSingleList:undefined}))

        if(err.res.status===500){
            setState((prev)=>({...prev,message:"Internal Server Error ",error:true}))

        }else{
            setState((prev)=>({...prev,message:err.res.data,error:true}))

        }
  
    })
}

export const BankReconciliationUpdateAPI=async(body,setState, setLoadingForm, setNotReflectedInCompanyBook,setNotReflectedInBank,postFunction)=>{
    setLoadingForm(true)
    await axios
    .post("account/importbankreconcilation",body, HEADERS)
    .then((res)=>{
        if(res.status===200){
            setLoadingForm(false)
            console.log("success",res.data)
            postFunction()
            setNotReflectedInCompanyBook(res.data.notReflectedInCompanyBook||[])
            setNotReflectedInBank(res.data.notReflectedInBank ||[])
            setState((prev)=>({...prev,message:"Imported ",success:true}))
            BankReconciliationSingleListAPI({_id:res.data._id})
        }
        
    })
    .catch((err)=>{
        setLoadingForm(false)
       console.log("error");
       if(err.res.status===500){
        setState((prev)=>({...prev,message:"Internal Server Error ",error:true}))

    }else{
        setState((prev)=>({...prev,message:err.res.data,error:true}))

    }

    })
}

export const BankReconciliationListAPI=async(body)=>{
    await axios
    .post("account/viewbankreconcilation",body, HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(set_bank_reconciliation_list({bankReconciliationfullList:res.data}))
        }

    })
    .catch((err)=>{
        store.dispatch(set_bank_reconciliation_list({bankReconciliationfullList:undefined}))
    })
}
export const BankReconciliationSingleListAPI=async(body)=>{
    await axios
    .post("account/viewsinglebankreconcilation",body, HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(set_bank_reconciliation_Singlelist({bankReconciliationSingleList:res.data}))
        }

    })
    .catch((err)=>{
        store.dispatch(set_bank_reconciliation_Singlelist({bankReconciliationSingleList:undefined}))
    })
}

export const BankReconciliationComputeAPI=async(body,setState, setLoadingForm, setNotReflectedInCompanyBook,setNotReflectedInBank)=>{
    setLoadingForm(true)
    await axios
    .post("account/computeBankReconcilation",body, HEADERS)
    .then((res)=>{
        if(res.status===200){
            setLoadingForm(false)
            setState((prev)=>({...prev,message:"Compute",success:true}))
        }
        
    })
    .catch((err)=>{
        setLoadingForm(false)
       console.log("error");
       if(err.res.status===500){
        setState((prev)=>({...prev,message:"Internal Server Error ",error:true}))

    }else{
        setState((prev)=>({...prev,message:err.res.data,error:true}))

    }
    
})
}

export const BankReconciliationMatchAPI=async(body,setState, setLoadingForm, setNotReflectedInCompanyBook,setNotReflectedInBank)=>{
    setLoadingForm(true)
    await axios
    .put("account/matchAndUpdateReconcilation",body, HEADERS)
    .then((res)=>{
        if(res.status===200){
            setLoadingForm(false)
            setState((prev)=>({...prev,message:"Match",success:true}))
            store.dispatch(set_bank_reconciliation_table({bankReconciliationTableData:res.data.reconcileTable}))
            BankReconciliationSingleListAPI({_id:res.data._id})
        }
        
    })
    .catch((err)=>{        
        setLoadingForm(false)
       console.log("error");
       if(err.res.status===500){
        setState((prev)=>({...prev,message:"Internal Server Error ",error:true}))
        store.dispatch(set_bank_reconciliation_table({bankReconciliationTableData:undefined}))

    }else{
        setState((prev)=>({...prev,message:err.res.data,error:true}))

    }
    
})
}

export const BankReconciliationEditAPI=async(body,setState,setLoadingForm)=>{
    setLoadingForm(true)
    await axios
    .put("account/editBankReconcilationTransactionNotReflectedInBank",body, HEADERS)
    .then((res)=>{
        if(res.status===200){
            setLoadingForm(false)
            setState((prev)=>({...prev,message:"Edited",success:true}))
        }
        
    })
    .catch((err)=>{
        setLoadingForm(false)
       console.log("error");
       if(err.res.status===500){
        setState((prev)=>({...prev,message:"Internal Server Error ",error:true}))

    }else{
        setState((prev)=>({...prev,message:err.res.data,error:true}))

    }
    
})
}
export const BankReconciliationDraftEditAPI=async(body,setState,setLoadingForm)=>{
    setLoadingForm(true)
    await axios
    .post("account/updatedraftedreconcilations",body, HEADERS)
    .then((res)=>{
        if(res.status===200){
            setLoadingForm(false)
            setState((prev)=>({...prev,message:"Edited",success:true}))
        }
        
    })
    .catch((err)=>{
        setLoadingForm(false)
       console.log("error");
       if(err.res.status===500){
        setState((prev)=>({...prev,message:"Internal Server Error ",error:true}))

    }else{
        setState((prev)=>({...prev,message:err.res.data,error:true}))

    }
    
})
}

export const BankReconciliationPostAPI=async(body,setState,setLoadingForm)=>{
    setLoadingForm(true)
    await axios
    .post("account/postBankReconcilation",body, HEADERS)
    .then((res)=>{
        if(res.status===200){
            console.log("hellloo");
            BankReconciliationSingleListAPI({_id:res.data._id})

            setLoadingForm(false)
       
            setState((prev)=>({...prev,message:"Posted",success:true}))
        }
        
    })
    .catch((err)=>{
        setLoadingForm(false)
       console.log("error");
       if(err.res.status===500){
        setState((prev)=>({...prev,message:"Internal Server Error ",error:true}))

    }else{
        setState((prev)=>({...prev,message:err.res.data,error:true}))

    }
    
})
}