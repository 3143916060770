import axios from "axios"
import { get_payment_report } from "../../Redux/Report/paymentReportSlice"
import store from "../../Redux/store"
import { HEADERS } from "../UrlAndPaths"

export const paymentReportAPICall= async (body,setIsLoading)=>{
    setIsLoading(true)
    await axios.post("report/paymentReport",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_payment_report({paymentReportData:res.data}))
            setIsLoading(false)
        }
    })
    .catch((err)=>{
        store.dispatch(get_payment_report({paymentReportData:undefined}))
        console.log(err.response);
        setIsLoading(false)
    })
}