import axios from 'axios'
import {get_old_stock_list} from '../../Redux/Sales/OldStock/viewOldStockSlice'
import {HEADERS} from '../../API/UrlAndPaths'
import store from '../../Redux/store'
import {get_old_stock_single_view} from '../../Redux/Sales/OldStock/oldStockSingleViewSlice'

// view all old stock
export const viewOldStockAPICall= async (body,setIsLoading,setErrorSnack,setSnackMsg)=>{
  setIsLoading(true)
  await axios.post("sales/viewoldstocks",body,HEADERS)
  .then((res)=>{
    if(res.status === 200){
        store.dispatch(get_old_stock_list({viewOldStockData:res.data}))
        setIsLoading(false)
    }
  })
  .catch((err)=>{
    console.log(err.response);
    store.dispatch(get_old_stock_list({viewOldStockData:undefined}))
    switch (err.response.status) {
      case 404:
          setSnackMsg('No data available..')
        break;
      case 500:
          setSnackMsg('Server error!!')
        break;
      case 401:
          setSnackMsg('Unauthorized!!')
        break;
      case 403:
          setSnackMsg('Forbidden!!')
        break;
    
      default:
          setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
        break;
    }
    setErrorSnack(true)
    setIsLoading(true)
  })
}

// old stock single view api call
export const oldStockSingleViewAPICall= async (body)=>{
  await axios.post("sales/viewoldstockbyid",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_old_stock_single_view({oldStockSingleViewData:res.data}))
    }
  })
  .catch((err)=>{
    store.dispatch(get_old_stock_single_view({oldStockSingleViewData:undefined}))
    console.log(err.response);
  })
}