import { createSlice } from "@reduxjs/toolkit";

export const confirmTransferListSlice=createSlice({
    name:'confirmTransfer',
    initialState:{
        value:undefined
    },
    reducers:{
        get_confirm_transfer:(state,action)=>{
            state.value=action.payload.confirmTransferData
        }
    }
})
export const {get_confirm_transfer} = confirmTransferListSlice.actions;
export default confirmTransferListSlice.reducer