import React from 'react'
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { StockOutApproveAPICall } from '../../../../API/Purchase Manager/StockOut Products/stockOutApproveAPI';
import { useState } from 'react';
import { getAllStockOutAPICall } from '../../../../API/Purchase Manager/StockOut Products/stockOutAPI';
import { IconButton, Skeleton } from '@mui/material';
import ErrorSnackbar from '../../../Single Components/SnackBars/ErrorSnackbar';
import { currentTime, getCurrentTime, today } from '../../../../Js/Date';

export const ApproveStockOutProducts = () => {
 
  const [isModify, setIsModify] = useState(false);
  const[singleStatus,setSingleStatus]=useState([])
  // loading state
  const [searchKeyword,setSearchKeyword]=useState("")
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!!");
  const [isLoading,setIsloading]=useState(false)
  const [filteredSearchList,setFilteredSearchList]=useState([])
  // list of stockout products
  const allStockOutApproveList = useSelector((state) => state.allStockOutSlice.value);
  
  // update listener
  const updateListener = () => {
    setIsModify(!isModify);
  };
  const todayT={today}
  const currentTimed=getCurrentTime()

  // extracting single productId
  const viewSingleStatus=(id)=>{
    if(allStockOutApproveList!== undefined){
      const filteredData=allStockOutApproveList?.filter((obj)=>{
        if(obj?._id === id){
          return obj;
        }
      })
      setSingleStatus(filteredData)
    }
  }

  const stockOutApproveBody ={
    id:singleStatus[0]?._id
  };

  // accept payload body
  const stockOutApproveBodyAccept={
    status:0,
    _id:stockOutApproveBody.id,
    date:`${todayT.today} ${currentTimed}`
    // date:
  }
  // reject payload body
  const stockOutApproveBodyReject={
    status:1,
    _id:stockOutApproveBody.id,
    date:`${todayT.today} ${currentTimed}`
  }


  // accept btn onclick
  const approveSuccessMsg=()=>{

      StockOutApproveAPICall(
      stockOutApproveBodyAccept,
      updateListener
    )

    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Success',
      showConfirmButton: false,
      timer: 1500
    })
  }

  // reject btn onClick
  const rejectButtonMsg=()=>{
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#f80000',
      confirmButtonText: 'Submit',
      customClass:{
        cancelButton:'sweet-alert-cancel-button',
        confirmButton:'sweet-alert-submit-button',
      }
    }).then((result) => {
      if (result.isConfirmed) {
        StockOutApproveAPICall(
          stockOutApproveBodyReject,
          updateListener
        )
        Swal.fire(
          'Rejected!',
          'Request has been rejected.',
          'error'
        )
      }
    })
  }

  // add purchase button 
  const navigate=useNavigate();
  const addPurchaseBtnFn=()=>{
    navigate('/userdashboard/purchaseManager/addPurchaseOrder');
  }
  const closeErrorSnack = () => {
    setErrorSnackOpen(false);
  };
  const getSearchKeyword=(e)=>{
    setSearchKeyword(e.target.value)
  }
  const SearchFilter=(chars,listToFilter)=>{
    let searchedArray=[]
    let filteredArray=[]
    if(chars!==""){
      searchedArray=listToFilter.filter((obj)=>
        obj?.itemName?.toLowerCase()?.includes(chars?.toLowerCase())||
        obj?.branchName?.toLowerCase()?.includes(chars?.toLowerCase())||
        obj?.branchId?.toLowerCase()?.includes(chars?.toLowerCase())
      )
      filteredArray=searchedArray
    }
    else{
      filteredArray=listToFilter
    }
    return filteredArray
  }
  useEffect(()=>{
    if(allStockOutApproveList!==undefined){
      let filteredArray=[]
      setFilteredSearchList(allStockOutApproveList)
      filteredArray=SearchFilter(searchKeyword,allStockOutApproveList)
      setFilteredSearchList(filteredArray)    
    }
    else{
      setFilteredSearchList([])
    }
  },[searchKeyword,allStockOutApproveList])

  // call view stockoutProducts
  useEffect(()=>{
    getAllStockOutAPICall(setIsloading,setErrorSnackOpen,setSnackMsg)
  },[isModify])

  return (
    <div className="global-page-parent-container">
    <p className="breadcrumb">Purchase Manage &gt; Supplier Ledger</p>
    <div className="approve-stock-container">
      <div className="approve-stock-top-container">
         <h3>Approve Stock Out Product</h3>
         <div className="stock-out-product-button-container" style={{justifyContent:"flex-end"}}>
          <div className="view-order-search-container view-order-admin-search-container">
              <input
                placeholder="Search Product name,Branch name,BranchID"
                value={searchKeyword}
                onChange={getSearchKeyword}
              />
              <IconButton>
                  <i class="bi bi-search search-icon-order"></i>
              </IconButton>
              </div>
              <button style={{width:"20%",fontSize:"0.9rem"}}
              onClick={addPurchaseBtnFn} 
              className='btn btn-primary'>Add Purchase</button>
          </div>
         
      </div>
      <div className="global-table-container">
        <table className="global-table">
          <thead>
          <tr>
          <th>Products</th>
          <th>Qty</th>
          <th>Branch Code</th>
          <th>Location</th>
          <th>Status</th>
         </tr>
          </thead>
          {
                isLoading?
                <tbody>
                  <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr> 
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr> 
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr> 
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
            </tbody>:
          <tbody>
            {filteredSearchList.length!==0?filteredSearchList
            ?.slice(0)
            ?.reverse()
            ?.map((r,i)=>(
              <tr key={i}>
                <td>{r?.itemName}</td>
                <td>{r?.quantity}</td>
                <td>{r?.branchId}</td>
                <td>{r?.branchName}</td>
                <td>{
                  r?.status==="Accepted"?(
                    <div className="approve-stock-status">
                    <p className="status-text-stock-out-pending">Approved</p>
                    </div>)
                  :r?.status==="Rejected"?(
                    <p className="status-text-stock-out-reject">Rejected</p>)
                  :(
                    <div className="approve-stock-status">
                      <p onClick={approveSuccessMsg} onMouseDown={()=>viewSingleStatus(r?._id)} className="approve-stock-accept">Accept</p>
                      <p onClick={rejectButtonMsg} onMouseDown={()=>viewSingleStatus(r?._id)} className="approve-stock-accept approve-stock-reject">Reject</p>
                    </div>)

                    }</td> 
              </tr>
            )): <tr><td colSpan={5}>No Records</td></tr>}          
          </tbody>
          }
        </table>
      </div>
    </div>
    <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnack}
        message={snackMsg}
      />
    </div>
  )
}
