
import React, { useState } from 'react'
import "../../../../css/Settings/BackupAndRestore/backupAndRestore.css"
import uploadIcon from "../../../../Assets/SVG/uploadIcon.svg"
import infoIcon from "../../../../Assets/Images/infoAlertIcon.png"
import confirmAlertIcon from "../../../../Assets/Images/confirmAlert.png"
import { Autocomplete, Dialog, TextField } from '@mui/material'
import { viewAllBranchesAPICall } from '../../../../API/Settings/Company Settings/companySettingsAPI'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { verifyPasswordForShiftAPICall } from '../../../../API/Login/VerifyPasswordAPI'
import ErrorSnackbar from '../../../Single Components/SnackBars/ErrorSnackbar'
import { clearBranchDataAPICall } from '../../../../API/Settings/BackupAndRestore/clearBranchDataAPI'
export const BackupAndRestore = () => {

  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);

  const [activeBtn,setActiveBtn]=useState('backupSettingsBackup')
  const [clearDataConfirmationDialog,setClearDataConfirmationDialog]=useState(false)
  const [clearDataConfirmationPopup,setClearDataConfirmationPopup]=useState(false)
  const [confirmPasswordDialog,setConfirmPasswordDialog]=useState(false)
  const [verifyPassWord, setVerifyPassword] = useState("");
  const [branchId, setBranchId] = useState("");
  const [branchAlert, setBranchAlert] = useState(false);
   // snackbar state
  const [errorSnack, setErrorSnack] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error!!");

  const clickBackupSettingsBackup=()=>{
    document.getElementById("backupSettingsBackup").classList.add("focus");
    document.getElementById("backupSettingsRestore").classList.remove("focus");
    document.getElementById("backupSettingsClearData").classList.remove("focus");

    setActiveBtn('backupSettingsBackup')
  }

  const clickBackupSettingsRestore=()=>{
    document.getElementById("backupSettingsRestore").classList.add("focus");
    document.getElementById("backupSettingsBackup").classList.remove("focus");
    document.getElementById("backupSettingsClearData").classList.remove("focus");

    setActiveBtn('backupSettingsRestore')
  }

  const clickBackupSettingsClearData=()=>{
    document.getElementById("backupSettingsClearData").classList.add("focus");
    document.getElementById("backupSettingsRestore").classList.remove("focus");
    document.getElementById("backupSettingsBackup").classList.remove("focus");
    setActiveBtn('backupSettingsClearData')
  }
  // snackbar function
  const errorSnackbarClose = () => {
    setErrorSnack(false);
  };
  const clickClearDataSubmit=()=>{
    if(branchId===""){
      setBranchAlert(true)
    }
    else{
      setClearDataConfirmationDialog(true)
    }
  }
  const clearDataConfirmSubmit=()=>{
    setClearDataConfirmationDialog(false)
    setConfirmPasswordDialog(true)
  }
  const clearBranchDataClick=()=>{
    clearBranchDataAPICall({branchId:branchId},setConfirmPasswordDialog,setClearDataConfirmationPopup,setErrorSnack)
    setVerifyPassword("")
  }
  const verifyUserPassword = () => {
    if (verifyPassWord !== "") {
      verifyPasswordForShiftAPICall(
        { password: verifyPassWord },
        setErrorSnack,
        setErrorMsg,
        clearBranchDataClick
      );
    }
  };

  useEffect(()=>{
    viewAllBranchesAPICall()
  },[])

  console.log(branchId);
  return (
    <div className="backup-settings-container">
    <div className="backup-settings-container-left">
          <div
          id="backupSettingsBackup"
          onClick={clickBackupSettingsBackup}
          className="backup-settings-container-left-list focus">
            <ul><li>Backup</li></ul>
          </div>
          <div
          id="backupSettingsRestore"
          onClick={clickBackupSettingsRestore}
          className="backup-settings-container-left-list">
          <ul><li>Restore</li></ul>
          </div>
          <div
          id="backupSettingsClearData"
          onClick={clickBackupSettingsClearData}
          className="backup-settings-container-left-list">
          <ul><li>Clear Data</li></ul>
          </div>
          
    </div>
      <div className="backup-settings-container-right">
        {activeBtn==='backupSettingsBackup'?
        <>
        <div className="backup-settings-container-right-section">
          <div className="global-single-input" style={{width:'85%',margin:'0'}}>
              <p>Backup Path</p>
              <input type="text" />
          </div>
          <button className="btn btn-primary backup-setting-path-button"><img src={uploadIcon} alt="" /></button>
        </div> 
        <div className="backup-settings-right-container-button">
          <button className="btn btn-primary">Submit</button>
        </div>
        </>
        :activeBtn==='backupSettingsRestore'?
        <>
        <div className="backup-settings-container-right-section">
            <div className="global-single-input" style={{width:'85%',margin:'0'}}>
              <p>Restore Path</p>
              <input type="text" />
          </div>
          <button className="btn btn-primary backup-setting-path-button"><img src={uploadIcon} alt="" /></button>
        </div>
        <div className="backup-settings-right-container-button">
          <button className="btn btn-primary">Submit</button>
        </div>
        </>
        :
        activeBtn==='backupSettingsClearData'?
        <>
        <div className="backup-settings-container-right-section">
            <div className="global-single-input auto-complete" style={{width:'85%',margin:'0'}}>
              <p>Branch</p>
              <Autocomplete
                sx={{width:"100%"}}
                options={allBranchesList || [{storeCode:"no",branchName:"data"}]}
                getOptionLabel={(option)=>`${option?.storeCode}-${option?.branchName}`}
                renderInput={(params)=>(
                    <TextField {...params} placeholder="Select a Branch"/>
                )}
                onChange={(e,newValue)=>{
                  if(newValue!==null){
                    setBranchId(newValue?.storeCode)
                    setBranchAlert(false)
                  }
                  else{
                    setBranchId("")
                  }
                }}
              />
              {
                branchAlert?
                <p className="doc-validation-alert">
                  Select a branch !!
                </p>:""
              }
          </div>
        </div>
        <div className="backup-settings-right-container-button">
          <button style={{width:"30%"}} className="btn btn-primary" onClick={clickClearDataSubmit}>Clear Data</button>
        </div>
        </>
        :
        undefined
        }
        
    </div>

    {/* clear data confirmation dialog */}
    <Dialog open={clearDataConfirmationDialog}
            maxWidth="lg"
    >
      <div className="backup-clear-data-confirmation-ctn">
        <div className="backup-clear-data-confirmation-img">
          <img src={infoIcon} alt="" />
        </div>
        <h2>Are you sure you want to clear below listed data?</h2>

        <div className="backup-clear-data-confirmation-body">
            <p>Workorder, Commission, Measurement, Alteration, Payment, Credit, Wallet, Sales return, Delivered Product, 
              Return payment, Purchase, Purchase WOPO, Payment Voucher, GRN, Stock Out, Stock Adjustment, Stock Transfer, 
              Quotation, Order, Damaged Goods, Stock, Stock Log, Old Stock log, Log, Shift,
              Shift log, Shift Transfer Log, Shift End, Offer, Coupon, Expense, Petty cash, Customer, Relation.</p>
        </div>
        <div className="backup-clear-data-confirmation-btn">
          <button className="btn btn-red" onClick={()=>setClearDataConfirmationDialog(false)}>Cancel</button>
          <button className="btn btn-primary" onClick={clearDataConfirmSubmit}>Yes</button>
        </div>
      </div>
    </Dialog>
    {/* password dialog */}
    <Dialog
        open={confirmPasswordDialog}
        onClose={() => setConfirmPasswordDialog(false)}
        maxWidth="lg"
      >
        <div className="shift-end-button-password-container" style={{width:"50vw"}}>
          <h3>Enter Password</h3>
          <div className="global-single-input shit-end-password-input">
            <p>Password</p>
            <input
              type="password"
              value={verifyPassWord}
              onKeyDown={(e) =>
                e.key === "Enter"
                  ? verifyUserPassword()
                  : e.key === "Escape"
                  ? () => setConfirmPasswordDialog(false)
                  : undefined
              }
              onChange={(e) => {
                setVerifyPassword(e.target.value);
              }}
            />
          </div>
          <button
            onClick={verifyUserPassword}
            className="btn btn-primary shift-end-password-btn"
          >
            Submit
          </button>
        </div>
    </Dialog>


    {/* confirmation popup */}
    <Dialog open={clearDataConfirmationPopup} maxWidth="lg" onClose={()=>setClearDataConfirmationPopup(false)}>
        <div className="backup-clear-data-popup-ctn">
          <div className="backup-clear-data-popup-img">
            <img src={confirmAlertIcon} alt=""/>  
          </div> 
          <h2>Data Cleared</h2>
        </div>
    </Dialog>


    {/* error snackbar */}
    <ErrorSnackbar
                  open={errorSnack}
                  handleClose={errorSnackbarClose}
                  message={errorMsg}
                />
    </div>
  )
}
