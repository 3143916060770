import { Skeleton } from '@mui/material';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';

export const EmpDocumentDetails = (props) => {

  const {singleEmpData} =props;
  const [isLoading,setIsLoading]=useState(false)
  console.log(singleEmpData);
  useEffect(()=>{
    if(singleEmpData===undefined){
      setIsLoading(true)
    }
    else{
      setIsLoading(false)
    }
  },[singleEmpData])
  return (
    <div className='dashboard-employee-leave-details-container'>
    <div className='dashboard-employee-leave-details-top-container'>
    <div className='dashboard-employee-leave-details-top-btn-grp-container'>
      <h4>Documents</h4>
    </div>
 </div>
 <div className='global-table-container dashboard-leave-details-table-container'>
    <table className='global-table'>
     <thead>
     <tr>
       <th>No</th>
       <th>Documents</th>
       <th>Documents ID</th>
       <th>Expiry Date</th>
      </tr>
     </thead>
     {
      isLoading?
      <tbody>
        <tr>
              <td colSpan={4}>
                {/* <Loader /> */}
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width={"100%"}
                  height={40}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                {/* <Loader /> */}
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width={"100%"}
                  height={40}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                {/* <Loader /> */}
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width={"100%"}
                  height={40}
                />
              </td>
            </tr>
      </tbody>
      
      :
      <tbody>
        {singleEmpData?.documents !== undefined ?(
          singleEmpData?.documents
            ?.map((r,i)=>(
              <tr key={i}>
                <td>{i+1}</td>
                <td>{r?.documentType}</td>
                <td>{r?.documentNumber}</td>
                <td>{r?.expiryDate}</td>
              </tr>
            ))
            ):(
              <tr>
            <td colSpan={4}>No Documents Added</td>
          </tr>
        )}
     </tbody>
     }
     
    </table>
 </div>
</div>
  )
}
