import React,{useState,useEffect} from 'react'
import '../../../css/Expense/expense.css'
import {IconButton,Dialog,Select,MenuItem,Autocomplete,TextField, Skeleton} from '@mui/material'
import {viewStaffExpenseAPICall,addStaffExpenseAPICall,editStaffExpenseAPICall, viewExpensePaymentType} from '../../../API/Expense/staffExpenseAPI'
import { useSelector } from 'react-redux'
import { viewAddExpenseTypeAPICall} from '../../../API/Expense/addExpenseTypeAPI'
import {getAllActiveEmployeesAPICall} from '../../../API/Staff/HRM/employeeAPI'
import {viewAllBranchesAPICall} from '../../../API/Settings/Company Settings/companySettingsAPI'
import SuccessSnackbar from '../../Single Components/SnackBars/SuccessSnackbar'
import ErrorSnackbar from '../../Single Components/SnackBars/ErrorSnackbar'
import {today,previousDate, convertDateFormat, getCurrentTime} from '../../../Js/Date'
import { viewPosSettingsAPICall } from '../PointOfSale/Configuration/Settings/posSettingsAPI'
import { filterObjFromList } from '../../../Js/generalFunctions'
import { LoadingForm } from '../../Single Components/LoadingForm'

export const StaffExpense = () => {
 
 
  // role based rendering
  const userRole = useSelector((state) => state.userRoleSlice.value);
  // view staff expense
  const staffExpense=useSelector((state)=>state.staffExpenseSlice.value)
  // view all expense type
  const expenseTypeData = useSelector((state) => state.addExpenseType.value);
   //Active Employee List
  const allActiveEmpList = useSelector(
  (state) => state.allActiveEmployeeSlice.value
  );
   // all branch list
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  
  const expPymentType=useSelector((state)=>state.staffExpenseSlice.staffExpPaymentType)
  const posSettingsList=useSelector((state)=>state.posSettingSlice.apiResList)

  console.log(expPymentType);

  // user info
  const userLoginMeta=localStorage.getItem("login_meta")
  const userBranchId=JSON.parse(userLoginMeta)?.branchId
  const userBranchpk = JSON.parse(userLoginMeta)?.branchPk;
  
  const storeCode=localStorage.getItem('branchId')

  const paymentType=[
    {type:"UPI"},
    {type:"Net Banking"},
    {type:"Cash"}]



  // validation states
  const [expenseTypeAlert,setExpenseTypeAlert]=useState(false)
  const [employeeIdAlert,setEmployeeIdAlert]=useState(false)
  const [paymentTypeAlert,setPaymentTypeAlert]=useState(false)
  const [amountAlert,setAmountTypeAlert]=useState(false)
  //Update Listener States
  const [isModify, setIsModify] = useState(false);
  // add expense dialog
  const [addExpense,setAddExpense]=useState(false)

  const [branch,setBranch]=useState('0')
  const [fromDate,setFromDate]=useState(previousDate)
  const [toDate,setToDate]=useState(today)
  
  const [searchKeyword,setSearchKeyword]=useState("")
  const [filteredSearchList,setFilteredSearchList]=useState([])
  const addExpenseFormInitialState={
    expenseType:null,
    employeeId:null,
    
    expenseDate:today,
    paymentType:null,
    amount:'',
    expenseId:'',
    empId:'',
    empName:'',
    notes:''
  }
  const [addExpenseDialogForm,setAddExpenseDialogForm]=useState(
    addExpenseFormInitialState
  )
  // edit expense dialog state
  const [editExpDialog,setEditExpDialog]=useState(false)
  const [editedId,setEditedId]=useState(false)
  const [loadingForm,setLoadingForm]=useState(false)

// snackbar states
const [successSnackbar,setSuccessSnackbar]=useState(false)
const [errorSnackbar,setErrorSnackbar]=useState(false)
const [snackMsg,setSnackMsg]=useState('Error!!')

// loading states
const [isLoading,setIsLoading]=useState(false)


   useEffect(()=>{
    viewStaffExpenseAPICall({branchId:storeCode},setIsLoading,setErrorSnackbar,setSnackMsg);
    viewAddExpenseTypeAPICall();
    getAllActiveEmployeesAPICall();
    viewAllBranchesAPICall()
  
     viewPosSettingsAPICall()
   },[isModify])

   useEffect(()=>{
    if(posSettingsList?.posStatus === true && posSettingsList?.pettyCashStatus=== true){
      viewExpensePaymentType()
    }
    },[posSettingsList])

  

    //*Update Listener
  const updateListener = () => {
    setIsModify(!isModify);
  };

 // admin view staff expense filter function
 const viewStaffExpenseAdmin=()=>{
  viewStaffExpenseAPICall({
    branchId:branch,
    fromDate:`${fromDate} 00:00:00`,
    endDate:`${toDate} 23:59:59`
  },setIsLoading,setErrorSnackbar,setSnackMsg);
 }


  //  onChange functions
  // admin view onchange fn
  const getBranch=(e)=>{
    setBranch(e.target.value)
  }
  const getFromDate=(e)=>{
    setFromDate(e.target.value)
  }
  const getToDate=(e)=>{
    setToDate(e.target.value)
  }
  // add staff expense onchange function
  const getExpenseType=(e)=>{
    setAddExpenseDialogForm({...addExpenseDialogForm,expenseType:e.target.value})
  }
  const getEmployee=(e)=>{
    setAddExpenseDialogForm({...addExpenseDialogForm,employeeId:e.target.value})
  }
  const getExpenseDate=(e)=>{
    setAddExpenseDialogForm({...addExpenseDialogForm,expenseDate:e.target.value})
  }
  const getPaymentType=(e,newValue)=>{
    setAddExpenseDialogForm({...addExpenseDialogForm,paymentType:newValue})
    setPaymentTypeAlert(false)
  }
  const getAmount=(e)=>{
    setAddExpenseDialogForm({...addExpenseDialogForm,amount:parseFloat(e.target.value)})
    setAmountTypeAlert(false)
    if(e.target.value===""){
      setAmountTypeAlert(true)
    }
  }
  const getNotes=(e)=>{
    setAddExpenseDialogForm({...addExpenseDialogForm,notes:e.target.value})
  }
  // add expense dialog open function
  const addExpenseDialogOpen=()=>{
    setAddExpense(true)
    setAddExpenseDialogForm(addExpenseFormInitialState)
  }
  const bodyToApi={
    expense:addExpenseDialogForm.expenseType?._id,
    expenseAccountId:addExpenseDialogForm?.expenseType?.accountId,
    expenseDate:`${addExpenseDialogForm.expenseDate} ${getCurrentTime()}`,
    // paymentType:addExpenseDialogForm.paymentType?.type,
    paymentMethod: {
      journalId:addExpenseDialogForm.paymentType?.journalId,
      type:addExpenseDialogForm.paymentType?.type
  },
   
    empId:addExpenseDialogForm.employeeId,
    amount:addExpenseDialogForm.amount,
    branchId:userBranchpk,
    narration:addExpenseDialogForm.notes
  }
  // add staff expense
  const addStaffExpenseSubmitFn=()=>{
    if(addExpenseDialogForm.expenseType===null || addExpenseDialogForm.expenseType===undefined){
      setExpenseTypeAlert(true)
    }
    else if(addExpenseDialogForm.employeeId===null || addExpenseDialogForm.employeeId===undefined){
      setEmployeeIdAlert(true)
    }
    else if(addExpenseDialogForm.paymentType===null){
      setPaymentTypeAlert(true)
    }
    else if(addExpenseDialogForm.amount===""){
      setAmountTypeAlert(true)
    }
    else{
      addStaffExpenseAPICall(bodyToApi,updateListener,setSuccessSnackbar,setErrorSnackbar,setSnackMsg,setLoadingForm,setAddExpense)
    }
  }

  // edit expense dialog
  const editExpenseDialogOpen=(
    id,expenseName,employeeId,expDate,paymentType,amount,expenseId,empName,empId,data)=>{
      
    setEditExpDialog(true)
    setEditedId(id)
    setAddExpenseDialogForm({
      ...addExpenseDialogForm,
      expenseType:expenseName,
      employeeId:employeeId,
      expenseDate:expDate,
      paymentType:filterObjFromList(
        "type",
        posSettingsList?.posStatus === true && posSettingsList?.pettyCashStatus=== true?expPymentType:paymentType,
        "paymentType",
        data
        ),
      amount:amount,
      expenseId:expenseId,
      empName:empName,
      empId:empId,
      notes:data?.narration
    })
  }

 const editedBodyToApi={
    id:editedId,
    expense:addExpenseDialogForm.expenseId,
    expenseDate:addExpenseDialogForm.expenseDate,
    paymentType:addExpenseDialogForm.paymentType?.type,
    empId:addExpenseDialogForm.employeeId,
    amount:addExpenseDialogForm.amount,
    narration:addExpenseDialogForm?.notes
  }
  // edit submit btn fn
  const staffExpenseUpdateFn=()=>{
    editStaffExpenseAPICall(
      editedBodyToApi,
      updateListener,
      setSuccessSnackbar,
      setErrorSnackbar,
      setSnackMsg,setLoadingForm,setEditExpDialog
      )
  }

  // snackbar functions
  const closeSuccessSnackbar=()=>{
    setSuccessSnackbar(false)
  }
  const closeErrorSnackbar=()=>{
    setErrorSnackbar(false)
  }
  const getSearchKeyword=(e)=>{
    setSearchKeyword(e.target.value)
  }
  const SearchFilter=(chars,listToFilter)=>{
    let searchedArray=[]
    let filteredArray=[]
    if(chars!==""){
      searchedArray=listToFilter.filter((obj)=>
      obj?.expenseName?.toLowerCase()?.includes(chars?.toLowerCase())||
      obj?.locationName?.branchName?.toLowerCase()?.includes(chars?.toLowerCase())||
      obj?.branchId?.toLowerCase()?.includes(chars?.toLowerCase())
    )
      filteredArray=searchedArray
    }
    else{
      filteredArray=listToFilter
    }
    return filteredArray
  }
  useEffect(()=>{
    if(staffExpense!==undefined){
      let filteredArray=[]
      setFilteredSearchList(staffExpense)
      filteredArray=SearchFilter(searchKeyword,staffExpense)
      setFilteredSearchList(filteredArray)    
    }
    else{
      setFilteredSearchList([])
    }
    },[searchKeyword,staffExpense])
  return (
    <div className="global-page-parent-container">
    <p className="breadcrumb">Expense &gt; Staff Expense</p>
    {
      userRole==='admin'&&
      <div className="add-expense-top-container">
      <div className="add-expense-input-container">
        <div className="global-single-input auto-complete">
          <p>Branch</p>
          {/* <Select
            value={branch}
            onChange={getBranch}
          > 
               <MenuItem value="0">Select Branch</MenuItem>
               {allBranchesList?.map((item, i) => (
                  <MenuItem value={item?.storeCode} key={i}>
                    {`${item?.storeCode}-${item?.branchName}`}
                  </MenuItem>
                ))}
          </Select> */}
            <Autocomplete
             sx={{width:"100%"}}
             options={allBranchesList||[""]}
             getOptionLabel={(option)=>`${option?.storeCode}-${option?.branchName}`}
             renderInput={(params)=>
               <TextField  {...params}
                placeholder="Select Branch"
               />
             }
             onChange={(e,newValue)=>setBranch(
               newValue?.storeCode
             )}
          />
        </div>
        <div className="global-single-input">
          <p>From</p>
          <input type="date" onChange={getFromDate} value={fromDate}/>
        </div>
        <div className="global-single-input">
          <p>To</p>
          <input type="date" onChange={getToDate} value={toDate}/>
        </div>
        <button
          className="btn btn-primary add-expense-button"
          onClick={viewStaffExpenseAdmin}
     >
       Submit
     </button>
      </div>
    </div>

    }
     <div className="global-white-bg-container">
        <div className="expense-top-container">
           <h3>Staff Expense</h3>
           <div className="supplier-manage-top-container-right">
              <div className="view-order-search-container view-order-admin-search-container">
                  <input
                      placeholder="Expense Type,Branch"
                       value={searchKeyword}
                       onChange={getSearchKeyword}
                      />
                  <IconButton>
                      <i class="bi bi-search search-icon-order"></i>
                  </IconButton>
              </div>
              <button style={{width:"30%"}}
              onClick={addExpenseDialogOpen} 
              className={userRole==="admin"?"btn staff-expense-button-disabled":"btn staff-expense-button"}
              disabled={userRole==="admin"}
              >Add Expense</button>
            </div>
          
        </div>
        <div className="global-table-container">
          <table className="global-table">
            <thead>
               <tr>
                 <th>No</th>
                 {userRole === 'admin' &&
                  <>
                 <th>Branch Code</th>
                 <th>Branch Name</th>
                 </>
                 }
                 <th>Expense</th>
                 <th>Employee Name</th>
                 <th>Date</th>
                 <th>Amount</th>
                 <th>Action</th>
               </tr>
            </thead>
            {
              isLoading?
              <tbody>
                <tr>
                  <td colSpan={10}>
                    {/* <Loader /> */}
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                    />
                  </td>
                  </tr> 
                  <tr>
                  <td colSpan={10}>
                    {/* <Loader /> */}
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                    />
                  </td>
                  </tr> 
                  <tr>
                  <td colSpan={10}>
                    {/* <Loader /> */}
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                    />
                  </td>
                  </tr> 
                  <tr>
                  <td colSpan={10}>
                    {/* <Loader /> */}
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                    />
                  </td>
                  </tr>
              </tbody>:
            <tbody>
              {filteredSearchList?.length!==0?
              filteredSearchList?.slice(0)
                    .reverse().map((r,i)=>(
                  <tr key={i}>
                  <td>{i+1}</td>
                  {userRole === 'admin' &&
                  <>
                  <td>{r.branchId}</td>
                  <td>{r.locationName?.branchName}</td>
                  </>
                 }
                  <td>{r.expenseName}</td>
                  <td>{r.employeeName}</td>
                  <td>{convertDateFormat(r.expenseDate)}</td>
                  <td>{r.amount}</td>
                  <td>
                 <IconButton onClick={()=>editExpenseDialogOpen(
                   r._id,
                   r.expenseName,
                   r.empId,
                   r.expenseDate,
                   r.paymentType,
                   r.amount,
                   r.expense,
                   r.employeeName,
                   r.EMPID,
                   r
                   )}>
                    <i class="bi bi-pencil-square edit-icon"></i>
                 </IconButton>
                  </td>
                </tr>
              )):
              <tr><td colSpan={8} style={{textAlign:"center"}}>No Records</td></tr>
              }
             
            </tbody>
          }
          </table>
        </div>
     </div>

     {/* add expense */}
     <Dialog open={addExpense} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setAddExpense(false)}>
       <div className="add-expense-dialog-container">
          <h3>Add Expense</h3>
          <hr className="global-hr"/>
          <div className="global-single-input staff-expense-input auto-complete">
           <p>Expense Type</p>
          
           <Autocomplete
             sx={{width:"100%"}}
             options={expenseTypeData||[""]}
             getOptionLabel={(option)=>option?.expenseType}
             renderInput={(params)=>
              <TextField  {...params}
               placeholder="Select Expense Type"
              />
            }
            onChange={(e,newValue)=>{setAddExpenseDialogForm({
              ...addExpenseDialogForm,expenseType:newValue
            })
            setExpenseTypeAlert(false)
            if(newValue===null){
              setExpenseTypeAlert(true)
            }
          }
          }
           />
           {expenseTypeAlert?
            <p className="doc-validation-alert">Select a expense type !!</p>
           :""}
          </div>
          <div className="global-single-input staff-expense-input auto-complete">
           <p>Employee</p>
           
           <Autocomplete
             sx={{width:"100%"}}
             options={allActiveEmpList||[""]}
             getOptionLabel={(option)=>`${option.emp_id}-${option.staff_name}`}
             renderInput={(params)=>
              <TextField  {...params}
              placeholder="Select Employee"
              />
            }
            onChange={(e,newValue)=>{setAddExpenseDialogForm({
              ...addExpenseDialogForm,employeeId:newValue?._id
            })
            setEmployeeIdAlert(false)
            if(newValue===null){
              setEmployeeIdAlert(true)
              }
            }
          }
           />
           {employeeIdAlert?
            <p className="doc-validation-alert">Select a employee !!</p>
           :""}
          </div>
          <div className="global-single-input staff-expense-input">
           <p>Expense Date</p>
           <input type="date" onChange={getExpenseDate} value={addExpenseDialogForm.expenseDate}/>
          </div>
          <div className="global-single-input staff-expense-input auto-complete">
           <p>Payment Type</p>
           <Autocomplete
             sx={{width:"100%"}}
             options={posSettingsList?.posStatus === true && posSettingsList?.pettyCashStatus=== true?expPymentType:paymentType||[]}
             getOptionLabel={(option)=>option?.type}
             renderInput={(params)=>
              <TextField  {...params}
              placeholder="Select Payment Type"
              />
              
            }
            value={addExpenseDialogForm.paymentType}
            onChange={getPaymentType}
          
           />
        
           {paymentTypeAlert?
            <p className="doc-validation-alert">Select a payment type !!</p>
           :""}
          </div>
          <div className="global-single-input staff-expense-input">
           <p>Amount</p>
           <input type="number" 
           value={addExpenseDialogForm.amount}
           onChange={getAmount}
           />
           {amountAlert?
            <p className="doc-validation-alert">Fill this field !!</p>
           :""}
          </div>
          <div className="global-single-input staff-expense-input">
           <p>Notes</p>
           <input type="text" 
           value={addExpenseDialogForm.notes}
           onChange={getNotes}
           />
         
          </div>
          <div className="expense-button-container">
           <button onClick={()=>setAddExpense(false)} className="btn btn-secondary-outlined">Cancel</button>
           <button onClick={addStaffExpenseSubmitFn} className="btn btn-primary">Submit</button>
          </div>
       </div>
     </Dialog>

     {/* edit expense dialog */}
     <Dialog open={editExpDialog} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setEditExpDialog(false)}>
       <div className="add-expense-dialog-container">
          <h3>Edit Expense</h3>
          <hr className="global-hr"/>
          <div className="global-single-input staff-expense-input auto-complete">
           <p>Expense Type</p>
           <Autocomplete
             sx={{width:"100%"}}
             defaultValue={{expenseType:addExpenseDialogForm.expenseType}}
             options={expenseTypeData||[""]}
             getOptionLabel={(option)=>option?.expenseType}
             renderInput={(params)=>
              <TextField  {...params}
               placeholder="Select Expense Type"
              />
            }
            onChange={(e,newValue)=>setAddExpenseDialogForm({
              ...addExpenseDialogForm,expenseId:newValue?._id
            })}
           />
          </div>
          <div className="global-single-input staff-expense-input auto-complete">
           <p>Employee ID</p>
           <Autocomplete
             sx={{width:"100%"}}
             defaultValue={{emp_id:addExpenseDialogForm.empId,staff_name:addExpenseDialogForm.empName}}
             options={allActiveEmpList||[""]}
             getOptionLabel={(option)=>`${option.emp_id}-${option.staff_name}`}
             renderInput={(params)=>
              <TextField  {...params}
              placeholder="Select Employee"
              />
            }
            onChange={(e,newValue)=>setAddExpenseDialogForm({
              ...addExpenseDialogForm,employeeId:newValue?._id
            })}
           />
          </div>
          <div className="global-single-input staff-expense-input">
           <p>Expense Date</p>
           <input type="date" onChange={getExpenseDate} value={addExpenseDialogForm.expenseDate}/>
          </div>
          <div className="global-single-input staff-expense-input auto-complete">
           <p>Payment Type</p>
           <Autocomplete
             sx={{width:"100%"}}
             options={posSettingsList?.posStatus === true && posSettingsList?.pettyCashStatus=== true?expPymentType:paymentType||[]}
             getOptionLabel={(option)=>option?.type}
             renderInput={(params)=>
              <TextField  {...params}
              placeholder="Select Payment Type"
              />
              
            }
            value={addExpenseDialogForm.paymentType}
            onChange={getPaymentType}
          
           />
          </div>
          <div className="global-single-input staff-expense-input">
           <p>Amount</p>
           <input type="text" 
           value={isNaN(addExpenseDialogForm.amount)?"0":addExpenseDialogForm.amount}
           onChange={getAmount}
           />
          </div>

          <div className="global-single-input staff-expense-input">
           <p>Notes</p>
           <input type="text" 
           value={addExpenseDialogForm.notes}
           onChange={getNotes}
           />
         
          </div>
          <div className="expense-button-container">
           <button onClick={()=>setEditExpDialog(false)} className="btn btn-secondary-outlined">Cancel</button>
           <button onClick={staffExpenseUpdateFn} className="btn btn-primary">Update</button>
          </div>
       </div>
     </Dialog>

     {/* snackbars */}

     <SuccessSnackbar
       open={successSnackbar}
       handleClose={closeSuccessSnackbar}
       message={snackMsg}
     />
     
     <ErrorSnackbar
       open={errorSnackbar}
       handleClose={closeErrorSnackbar}
       message={snackMsg}
     />
     <LoadingForm loading={loadingForm}/>
    </div>

   
  )
}
