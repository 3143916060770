import React, { useEffect, useState } from "react";
import { viewAllCurrencyUpdated } from "../../../../../API/Settings/Currency/CurrencyAPI";
import { filterObjFromList } from "../../../../../Js/generalFunctions";
import { companyProfileInfoForUser } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import { useSelector } from "react-redux";

const AccountRecievableSingleViewPrint = (props) => {
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  const today = new Date();
  const formattedToday = `${today.getDate().toString().padStart(2, "0")}-${(
    today.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${today.getFullYear()}`;

  const { data, formValues } = props;
  const [symbol, setSymbol] = useState(null);
  const decimalPosition = localStorage.getItem("decimalPosition");

  const currencyListUpdated = useSelector(
    (state) => state?.viewUpdatedCurrency?.value
  );

  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );
  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    console.log(currencyObj);
    setSymbol(currencyObj?.symbol);
  }, [currencyListUpdated]);

  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, []);
  console.log(formValues);
  return (
    <div className="print-main-div">
      <div className="header-div">
        <div style={{ maxWidth: "100px", height: "62px" }}>
          <img
            src={loginResponse?.logo}
            alt=""
            style={{ maxWidth: "100%", height: "100%", objectFit: "contain" }}
          />
        </div>
        <div>
          <h3 style={{ fontSize: "1em", margin: "0" }}>{formValues?.label}</h3>
          <span style={{ textAlign: "center", display: "block" }}>
            {formattedToday}
          </span>
        </div>
        <div>
          <p style={{ margin: "0", fontSize: "smaller" }}>
            {loginResponse?.data?.profile?.companyName}
          </p>
          <p style={{ margin: "0", fontSize: "smaller" }}>
            {loginResponse?.data?.profile?.companyAddress}
          </p>
          <p style={{ margin: "0", fontSize: "smaller" }}>
            {loginResponse?.data?.profile?.contactNumber}
          </p>
        </div>
      </div>

      <div
        className="global-report-table-container"
        style={{ overflow: "visible" }}
      >
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Name</th>
              <th>Journal</th>
              <th>Referance</th>
              <th>Debit</th>
              <th>Credit</th>
              <th>Balance</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index}>
                <td style={{ textAlignLast: "start", paddingLeft: "10px" }}>
                  {item.date}
                </td>
                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
                  {item.name}
                </td>
                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
                  {item.journalNo}
                </td>
                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
                  {item.reference}
                </td>
                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
                  {item.debit?.toFixed(decimalPosition)}
                </td>
                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
                  {item.credit?.toFixed(decimalPosition)}
                </td>
                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
                  {item.balance.toFixed(decimalPosition)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="div-table-total">
          <p style={{ width: "70%", textAlign: "end" }}>Total</p>
          <p>
            {symbol} {formValues?.totalDebit?.toFixed(decimalPosition)}
          </p>
          <p>
            {symbol} {formValues?.totalCredit?.toFixed(decimalPosition)}
          </p>
          <p>
            {symbol} {formValues?.totalBalance?.toFixed(decimalPosition)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AccountRecievableSingleViewPrint;
