import { createSlice } from "@reduxjs/toolkit";

export const InternalTransferSlice = createSlice({
  name: "internalTransferSlice",
  initialState: {
    value: undefined,
    singleValue:undefined,
    name:undefined,
  },
  reducers: {
    get_internal_transfer_list: (state, action) => {
      state.value = action.payload.internalTransferData;
    },
    get_internal_transfer_single_view:(state,action)=>{
      state.singleValue=action.payload.singleInternalTransferData
    },
    get_internal_transfer_name_view:(state,action)=>{
      state.name=action.payload.InternalTransferNameData
    }

  
  },
});

export const {
  get_internal_transfer_list,
  get_internal_transfer_single_view,
  get_internal_transfer_name_view
} = InternalTransferSlice.actions;

export default InternalTransferSlice.reducer;