import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import "../../../../css/Settings/SMS Settings/smsSettings.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { addSmsHeaderAPICall, viewSmsHeaderAPICall } from "../../../../API/Settings/SmsSetting/SmsHeaderAPI";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import { width } from "@mui/system";
export const SmsSettings = () => {
  const viewSmsHeader=useSelector((state)=>state.smsHeaderSlice.value)

  const [show, setShow] = useState(true);
  const [isModify,setIsModify]=useState(false)
  const [headerName,setHeaderName]=useState('')

  //Snackbar States
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("Error!!");

  const [listOfTemplates,setListOfTemplates]=useState(["Work Order"])
  const [selectedTemplates,setSelectedTemplates]=useState("Work Order")
  const [templateCreditCat,setTemplateCreditCat]=useState("monthly")

  const clickSmsSettingsHeader=()=>{
    document.getElementById("smsSettingsHeader").classList.add("focus");
    document.getElementById("smsSettingsTemplate").classList.remove("focus");
    setShow(true);
  }

  const clickSmsSettingsTemplate=()=>{
    document.getElementById("smsSettingsTemplate").classList.add("focus");
    document.getElementById("smsSettingsHeader").classList.remove("focus");
    setShow(false);
  }

  /// updare listner
  const updateListner=()=>{
    setIsModify(!isModify)
  }
   //Close SuccessSnackbar
   const closeSuccessSnackbar = () => {
    setSuccessSnackOpen(false);
  };
  //Close ErrorSnackbar
  const closeErrorSnackbar = () => {
    setErrorSnackOpen(false);
  };
  useEffect(()=>{
    viewSmsHeaderAPICall()
  },[isModify])

  //  onChange fn
  const getHeaderName=(e)=>{
    setHeaderName(e.target.value)
  }

  // submit button function
  const submitBtnFn=()=>{
    addSmsHeaderAPICall(
      {headerName},
      updateListner,
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      setHeaderName
      )
    // console.log(headerName);
  }
  const templateListChange=(key)=>(e)=>{
    if (key==="workOrder") {
      if (e.target.checked) {
        setListOfTemplates([...listOfTemplates, 'Work Order']);
      } else {
        setListOfTemplates(listOfTemplates.filter((label) => label !== 'Work Order'));
      }
    }
    if (key==="billing") {
      if (e.target.checked) {
        setListOfTemplates([...listOfTemplates, 'Billing']);
      } else {
        setListOfTemplates(listOfTemplates.filter((label) => label !== 'Billing'));
      }
    }
    if (key==="credit") {
      if (e.target.checked) {
        setListOfTemplates([...listOfTemplates, 'Credit']);
      } else {
        setListOfTemplates(listOfTemplates.filter((label) => label !== 'Credit'));
      }
    }
    if (key==="offer") {
      if (e.target.checked) {
        setListOfTemplates([...listOfTemplates, 'Offer']);
      } else {
        setListOfTemplates(listOfTemplates.filter((label) => label !== 'Offer'));
      }
    }
    if (key==="festival") {
      if (e.target.checked) {
        setListOfTemplates([...listOfTemplates, 'Festival']);
      } else {
        setListOfTemplates(listOfTemplates.filter((label) => label !== 'Festival'));
      }
    }
    if (key==="rewards") {
      if (e.target.checked) {
        setListOfTemplates([...listOfTemplates, 'Rewards']);
      } else {
        setListOfTemplates(listOfTemplates.filter((label) => label !== 'Rewards'));
      }
    }
    if (key==="jobCompletion") {
      if (e.target.checked) {
        setListOfTemplates([...listOfTemplates, 'Job Completion']);
      } else {
        setListOfTemplates(listOfTemplates.filter((label) => label !== 'Job Completion'));
      }
    }
    if (key==="delivery") {
      if (e.target.checked) {
        setListOfTemplates([...listOfTemplates, 'Delivery']);
      } else {
        setListOfTemplates(listOfTemplates.filter((label) => label !== 'Delivery'));
      }
    }
    if (key==="alteration") {
      if (e.target.checked) {
        setListOfTemplates([...listOfTemplates, 'Alteration']);
      } else {
        setListOfTemplates(listOfTemplates.filter((label) => label !== 'Alteration'));
      }
    }
    if (key==="staffExpDoc") {
      if (e.target.checked) {
        setListOfTemplates([...listOfTemplates, 'Staff Exp Doc']);
      } else {
        setListOfTemplates(listOfTemplates.filter((label) => label !== 'Staff Exp Doc'));
      }
    }
    if (key==="loan") {
      if (e.target.checked) {
        setListOfTemplates([...listOfTemplates, 'Loan']);
      } else {
        setListOfTemplates(listOfTemplates.filter((label) => label !== 'Loan'));
      }
    }
    if (key==="leave") {
      if (e.target.checked) {
        setListOfTemplates([...listOfTemplates, 'Leave']);
      } else {
        setListOfTemplates(listOfTemplates.filter((label) => label !== 'Leave'));
      }
    }
  }
  useEffect(()=>{
    if(listOfTemplates.length===0)
    setSelectedTemplates("empty")
  },[listOfTemplates])
  return (
    <div className="sms-settings-container">
      <div className="sms-settings-left-container">
        <div className="sms-settings-container-left-list focus"
            id="smsSettingsHeader"
            onClick={clickSmsSettingsHeader}>
            <ul>
              <li>Add SMS Header</li>
            </ul>
        </div>

        <div className="sms-settings-container-left-list"
             id="smsSettingsTemplate"
             onClick={clickSmsSettingsTemplate}>
            <ul>
              <li>
              <div>
                <Accordion sx={{ boxShadow: "none",background:'transparent' }}>
                  <AccordionSummary
                    sx={{ padding: "0", minHeight: "0", maxHeight: "40px" }}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <p>Add SMS Template</p>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: "0 16px" }}>
                      <div className="sms-settings-accordian-section">
                        <input type="checkbox" id="workOrder" defaultChecked onChange={templateListChange("workOrder")}/>
                        <label htmlFor="workOrder">Work Order</label>
                      </div>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: "0 16px" }}>
                      <div className="sms-settings-accordian-section">
                        <input type="checkbox"id="billing"  onChange={templateListChange("billing")}/>
                        <label htmlFor="billing">Billing</label>
                      </div>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: "0 16px" }}>
                      <div className="sms-settings-accordian-section">
                        <input type="checkbox" id="credit" onChange={templateListChange("credit")}/>
                        <label htmlFor="credit">Credit</label>
                      </div>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: "0 16px" }}>
                      <div className="sms-settings-accordian-section">
                        <input type="checkbox"id="offer" onChange={templateListChange("offer")} />
                        <label htmlFor="offer">Offer</label>
                      </div>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: "0 16px" }}>
                      <div className="sms-settings-accordian-section">
                        <input type="checkbox" id="festival" onChange={templateListChange("festival")}/>
                        <label htmlFor="festival">Festival</label>
                      </div>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: "0 16px" }}>
                      <div className="sms-settings-accordian-section">
                        <input type="checkbox" id="rewards" onChange={templateListChange("rewards")}/>
                        <label htmlFor="rewards">Rewards</label>
                      </div>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: "0 16px" }}>
                      <div className="sms-settings-accordian-section">
                        <input type="checkbox" id="jobCompletion" onChange={templateListChange("jobCompletion")}/>
                        <label htmlFor="jobCompletion">Job Completion</label>
                      </div>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: "0 16px" }}>
                      <div className="sms-settings-accordian-section">
                        <input type="checkbox" id="delivery" onChange={templateListChange("delivery")}/>
                        <label htmlFor="delivery">Delivery</label>
                      </div>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: "0 16px" }}>
                      <div className="sms-settings-accordian-section">
                        <input type="checkbox" id="alteration" onChange={templateListChange("alteration")}/>
                        <label htmlFor="alteration">Alteration</label>
                      </div>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: "0 16px" }}>
                      <div className="sms-settings-accordian-section">
                        <input type="checkbox" id="staffExpDoc" onChange={templateListChange("staffExpDoc")}/>
                        <label htmlFor="staffExpDoc">Staff Expired documents</label>
                      </div>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: "0 16px" }}>
                      <div className="sms-settings-accordian-section">
                        <input type="checkbox" id="loan" onChange={templateListChange("loan")}/>
                        <label htmlFor="loan">Loan</label>
                      </div>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: "0 16px" }}>
                      <div className="sms-settings-accordian-section">
                        <input type="checkbox" id="leave" onChange={templateListChange("leave")}/>
                        <label htmlFor="leave">Leave</label>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </li>
            </ul>
          </div>
      </div>

      <div className="sms-settings-right-container">
        {show ? (
          <div className="sms-settings-right-container-header">
            <div className="global-single-input sms-settings-right-container-top-auto-complete">
              <Select value="one" style={{paddingLeft:"10px",fontSize:"0.8rem"}}>
                <MenuItem value="one" disabled >Select a provider</MenuItem>
                <MenuItem>Airtel</MenuItem>
              </Select>
            </div>

            <div className="sms-settings-right-container-header-top">
              <p>Header Name</p>
              <input type="text" placeholder="Enter header Name..." value={headerName} onChange={getHeaderName}/>
              <p style={{color:"red",fontSize:"0.7rem"}}>Header Name should be 5 character</p>
              <div className="sms-settings-right-container-header-top-button">
                <button
                 disabled={headerName?.length !== 5}
                 className="btn btn-primary"
                 onClick={submitBtnFn}
                 >Submit</button>
              </div>
            </div>
            <div className="sms-settings-right-container-header-bottom global-table-container">
              <table className="global-table">
                <thead className="sms-settings-right-container-header-bottom-thead">
                  <tr>
                    <th>No</th>
                    <th>Header Name</th>
                    <th>Status</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {
                   viewSmsHeader !==0 ? viewSmsHeader?.slice(0)?.reverse()?.map((r,i)=>(
                   <tr>
                    <td>{i+1}</td>
                    <td>{r?.headerName}</td>
                    <td className="approved">{r?.status}</td>
                    <td>
                      <IconButton>
                        <i class="bi bi-pencil-square edit-icon"></i>
                      </IconButton>
                    </td>
                  </tr>
                    ))
                    :
                    <tr>
                      <td style={{textAlign:"center"}}>No Data</td>
                    </tr>
                  }
                  
                 
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="sms-settings-right-container-template">
            <div className="global-single-input sms-settings-right-container-top-auto-complete">
              <Select value={selectedTemplates} onChange={(e)=>setSelectedTemplates(e.target.value)} style={{paddingLeft:"10px",fontSize:"0.9rem"}}>
                { listOfTemplates.length!==0 ?
                    listOfTemplates.map((label)=>
                    <MenuItem value={label} >
                      {label}</MenuItem>
                    )
                    :
                    <MenuItem value="empty" disabled>Select any template</MenuItem>
                }
              </Select>
            </div>
            <div className="sms-settings-right-container-header-top">
              <div className="sms-settings-right-container-top-auto-complete">
                <div className="radio-groups-container" style={{display:"flex",width:"45%"}}>
                  <div className="global-radio-button radio-group template-msg-radio">
                    <input type="radio" name="smsTemplateLanguage" id="templateLanguageEnglish" value="0" defaultChecked/>
                    <label htmlFor="templateLanguageEnglish">English</label>
                  </div>
                  <div className="global-radio-button radio-group template-msg-radio">
                    <input type="radio" name="smsTemplateLanguage" id="templateLanguageArabic" value="1"/>
                    <label htmlFor="templateLanguageArabic">Arabic</label>
                  </div>
                 
                </div>
                {
                   listOfTemplates.includes("Credit") && selectedTemplates==="Credit"?
                  <div className="global-single-input" style={{width:"40%"}}>
                    <Select style={{height:"30px",fontSize:"0.8rem"}}value={templateCreditCat} onChange={(e)=>setTemplateCreditCat(e.target.value)}>
                      <MenuItem value="daily">Daily</MenuItem>
                      <MenuItem value="weekly">Weekly</MenuItem>
                      <MenuItem value="monthly">Monthly</MenuItem>
                    </Select>
                  </div>:""
                  }
              </div>
              
              <p>Template </p>
              <textarea name="" id="" cols="30" rows="10"></textarea>
              {/* <input type="message" placeholder="Add Template Content..." /> */}
              <div className="sms-settings-right-container-header-top-button">
                <button className="btn btn-primary">Submit</button>
              </div>
            </div>
            {/* <div className="sms-settings-right-container-header-bottom global-table-container">
              <table className="global-table">
                <thead className="sms-settings-right-container-header-bottom-thead">
                  <tr>
                    <th>No</th>
                    <th>Header Name</th>
                    <th>Status</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>01</td>
                    <td>Tajma</td>
                    <td className="approved">Approved</td>
                    <td>
                      <IconButton>
                        <i class="bi bi-pencil-square edit-icon"></i>
                      </IconButton>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>Tajma</td>
                    <td className="approved">Approved</td>
                    <td>
                      <IconButton>
                        <i class="bi bi-pencil-square edit-icon"></i>
                      </IconButton>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> */}
          </div>
        )}
      </div>

      {/* snackbar */}
      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />

      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </div>
  );
};
