import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { IconButton } from "@mui/material";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import InputBox from "../../../../../components/InputBox";

const FundFlowStatement = ({ selectListItem }) => {
  const componentRef = useRef();
  return (
    <div id="currentAsset-report">
      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="accounting-voucher-menu-sections"
      >
        <h3 style={{ margin: "2% 1%" }}>{selectListItem}</h3>
        <div className="input-current-asset-reports">
          <InputBox label={"From"} type={"date"} />
          <InputBox label={"To"} type={"date"} />
        </div>
      </div>
      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="accounting-voucher-menu-sections"
        id="cashflow-statement-direct"
      >
        <div className="action-btn-container">
          <div>
            <ReactToPrint
              trigger={() => (
                <IconButton sx={{ color: "blue" }}>
                  <i className="bi bi-printer "></i>
                </IconButton>
              )}
              content={() => componentRef.current}
            />
            <IconButton className="download-btn">
              <ArrowCircleDownIcon sx={{ fontSize: 30 }} />
            </IconButton>
          </div>
        </div>
        <div className="global-table-wrapper">
          <table className="global-table">
            <thead>
              <tr>
                <th></th>
                <th className="amount-column">Amount</th>
                <th className="amount-column">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr className="data-table-row-head">
                <td className="data-row">
                  <h3 className="main-head">Source of Fund</h3>
                  <ul className="data-row-list">
                    <li>Fund From Operation</li>
                    <li>Sale of Fixed Asset</li>
                    <li>Sale of Investment</li>
                    <li>Issue of Shares</li>
                    <li>Long Term Borrowings</li>
                  </ul>
                  <p className="net-cash-flow">Total</p>
                </td>
                <td className="data-row ">
                  <h3 className="main-head">&nbsp;</h3>
                  <ul className="data-row-amount ">
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                  </ul>
                  <p className=" total-amount">&nbsp;</p>

                </td>
                <td className="data-row ">
                  <h3 className="main-head">&nbsp;</h3>
                  <ul className="data-row-amount ">
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                  </ul>
                  <p className=" total-amount">10000</p>
                </td>
              </tr>

              <tr className="data-table-row-head">
                <td className="data-row">
                  <h3 className="main-head">Application of Fund</h3>
                  <ul className="data-row-list">
                    <li>Loss from Operations</li>
                    <li>Payment to Dividend</li>
                    <li>Payment to Taxes</li>
                    <li>Purchase of Fixed Assets</li>
                    <li>Repayment of Loans</li>
                    <li>Redemption of Debentures</li>
                    <li>Redemption of preference shares</li>
                  </ul>
                  <p className="net-cash-flow">Total</p>
                </td>
                <td className="data-row ">
                <h3 className="main-head">&nbsp;</h3>
                  <ul  className="data-row-amount ">
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                  </ul>
                  <p className="net-cash-flow">&nbsp;</p>
                </td>
                <td className="data-row ">
                  <h3 className="main-head">&nbsp;</h3>
                  <ul className="data-row-amount ">
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                  </ul>
                  <p className=" total-amount">10000</p>
                </td>
              </tr>

              <tr id="total-box-input" className="data-table-row-head">
                <td>
                  <ul className="list-total">
                    <li></li>
                    <li></li>
                    <li>
                      <h3>Net Increase / Decrease in working capital</h3>
                    </li>
                  </ul>
                </td>
                <td></td>
                <td>
                  <div className="list-total-wrapper">
                    <div>
                      <input type="text" />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* print out  */}

      <div style={{ display: "none" }}>
        <div
          id="cashflow-statement-indirect"
          ref={componentRef}
          className="print-preview-container"
        >
          <div className="print-head-container">
            <div className="brand-name">
              <h2>Leeyet Textile</h2>
            </div>
            <div className="select-table-head">
              <h3>{selectListItem}</h3>
            </div>
            <div className="duration-date">
              <h3>{"01/04/2023"}</h3>
              <strong>TO</strong>
              <h3>{"01/12/2023"}</h3>
            </div>
          </div>

          <div className="global-table-wrapper">
          <table className="global-table">
            <thead>
              <tr>
                <th></th>
                <th className="amount-column">Amount</th>
                <th className="amount-column">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr className="data-table-row-head">
                <td className="data-row">
                  <h3 className="main-head">Source of Fund</h3>
                  <ul className="data-row-list">
                    <li>Fund From Operation</li>
                    <li>Sale of Fixed Asset</li>
                    <li>Sale of Investment</li>
                    <li>Issue of Shares</li>
                    <li>Long Term Borrowings</li>
                  </ul>
                  <p className="net-cash-flow">Total</p>
                </td>
                <td className="data-row ">
                  <h3 className="main-head">&nbsp;</h3>
                  <ul className="data-row-amount ">
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                  </ul>
                  <p className=" total-amount">&nbsp;</p>

                </td>
                <td className="data-row ">
                  <h3 className="main-head">&nbsp;</h3>
                  <ul className="data-row-amount ">
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                  </ul>
                  <p className=" total-amount">10000</p>
                </td>
              </tr>

              <tr className="data-table-row-head">
                <td className="data-row">
                  <h3 className="main-head">Application of Fund</h3>
                  <ul className="data-row-list">
                    <li>Loss from Operations</li>
                    <li>Payment to Dividend</li>
                    <li>Payment to Taxes</li>
                    <li>Purchase of Fixed Assets</li>
                    <li>Repayment of Loans</li>
                    <li>Redemption of Debentures</li>
                    <li>Redemption of preference shares</li>
                  </ul>
                  <p className="net-cash-flow">Total</p>
                </td>
                <td className="data-row ">
                <h3 className="main-head">&nbsp;</h3>
                  <ul  className="data-row-amount ">
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                  </ul>
                  <p className="net-cash-flow">&nbsp;</p>
                </td>
                <td className="data-row ">
                  <h3 className="main-head">&nbsp;</h3>
                  <ul className="data-row-amount ">
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                  </ul>
                  <p className=" total-amount">10000</p>
                </td>
              </tr>

              <tr id="total-box-input" className="data-table-row-head">
                <td>
                  <ul className="list-total">
                    <li></li>
                    <li></li>
                    <li>
                      <h3>Net Increase / Decrease in working capital</h3>
                    </li>
                  </ul>
                </td>
                <td></td>
                <td>
                  <div className="list-total-wrapper">
                    <div>
                      <input type="text" />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
          </div>
          </div>
    </div>
  );
};

export default FundFlowStatement;
