import { createSlice } from "@reduxjs/toolkit";

export const LogSlice=createSlice({
    name:'logSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_log_data:(state,action)=>{
            state.value=action.payload.logData
        }
    }
})
export const {get_log_data} = LogSlice.actions;
export default LogSlice.reducer