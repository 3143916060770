import { createSlice } from "@reduxjs/toolkit";

export const reserveAndSurplus = createSlice({
  name: "reserveAndSurplus",
  initialState: {
    openingBalance: "",
    bankAccountDetails: { option: "Yes" },
    bankName: "",
    bankAccountNo: "",
    ifscCode: "",
    branchName: "",
   
  },
  reducers: {
    setOpeningBalance: (state, action) => {
      state.openingBalance = action.payload;
    },
    setBankAccountDetails: (state, action) => {
      state.bankAccountDetails = action.payload;
    },
    setBankName: (state, action) => {
      state.bankName = action.payload;
    },
    setBankAccountNo: (state, action) => {
      state.bankAccountNo = action.payload;
    },
    setIfscCode: (state, action) => {
      state.ifscCode = action.payload;
    },
    setBranchName: (state, action) => {
      state.branchName = action.payload;
    },
  },
});

export const {
  setBankAccountDetails,
  setBankAccountNo,
  setBankName,
  setBranchName,
  setIfscCode,
  setOpeningBalance,
} = reserveAndSurplus.actions;
export default reserveAndSurplus.reducer;
