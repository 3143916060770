import {createSlice} from '@reduxjs/toolkit'

export const generateTransferIdSlice=createSlice({
    name:"transferIdSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        get_transfer_id:(state,action)=>{
            state.value=action.payload.transferID
        }
    }
})
export const {get_transfer_id} = generateTransferIdSlice.actions;
export default generateTransferIdSlice.reducer;