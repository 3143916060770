import { createSlice } from "@reduxjs/toolkit";

export const modelSlice=createSlice({
    name:"modelSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        update_model:(state,action)=>{
            state.value=action.payload.model
        }
    }
})

export const {update_model}=modelSlice.actions
export default modelSlice.reducer