import React, { useState } from "react";
import { Select, IconButton, Dialog, MenuItem } from "@mui/material";
import { AddCategoryPopup } from "../../../Single Components/AddCategoryPopup";
import { useSelector } from "react-redux";
import {
  addBottomModelAPICall,
  addCupAPICall,
  addLengthCommissionAPICall,
  addMarModelAPICall,
  addStitchingAPICall,
  deleteBottomModelAPICall,
  deleteCupAPICall,
  deleteLengthCommissionAPICall,
  deleteMarModelAPICall,
  deleteStitchingAPICall,
  viewBottomModelAPICall,
  viewCupAPICall,
  viewLengthCommissionAPICall,
  viewMarModelAPICall,
  viewStitchingAPICall,
} from "../../../../API/Orders/workOrderAPI";
import { useEffect } from "react";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
export const SafariWO = (props) => {
  const { formValues, onChange } = props;
  //*Redux States
  //Role of User [admin or user]
  const userRole = useSelector((state) => state.userRoleSlice.value);
  const lengthCommissionData = useSelector(
    (state) => state.lengthCommissionSlice.value
  );
  const cupData = useSelector((state) => state.cupSlice.value);
  const stitchingData = useSelector((state) => state.stitchingSlice.value);
  const marModelData = useSelector((state) => state.marModelDataSlice.value);
  const bottomModelData = useSelector((state) => state.bottomModelSlice.value);

  const [isModify, setIsModify] = useState(false);
  //Snackbar States
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!");

  const [addLengthCommission, setAddLengthCommission] = useState(false);
  const [addCup, setAddCup] = useState(false);
  const [addStitching, setAddStitching] = useState(false);
  const [addMarModel, setAddMarModel] = useState(false);
  const [addBottomModel, setAddBottomModel] = useState(false);
  //Length Commission Form States
  const lengthCommissionInitialState = {
    lengthFrom: "",
    lengthTo: "",
    usedQty: "",
    minRate: 0,
    commission: 0,
  };
  const [lengthCommissionForm, setLengthCommissionForm] = useState(
    lengthCommissionInitialState
  );
  //Cup
  const [newCup, setNewCup] = useState("");
  //Stitching
  const [newStitching, setNewStitching] = useState("");
  //Mar Model State
  const [newMarModelName, setNewMarModelName] = useState("");
  //Bottom Model State
  const [newBottomModel, setNewBottomModel] = useState("");
  // VALIDATION STATES
  const [lengthFromAlert,setLengthFromAlert]=useState(false)
  const [lengthToAlert,setLengthToAlert]=useState(false)
  const [usedQtyAlert,setUsedQtyAlert]=useState(false)
  //-----------------------------------------------
  //Update Listener
  const updateListener = () => {
    setIsModify(!isModify);
  };
  //Close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setSuccessSnackOpen(false);
  };
  //Close ErrorSnackbar
  const closeErrorSnackbar = () => {
    setErrorSnackOpen(false);
  };
  //---------------------------------
  //*onChange
  //Get Length commission Form
  const getLengthCommissionForm = (key) => (e) => {
    const { value } = e.target;

    if (key === "lengthFrom") {
      setLengthCommissionForm({ ...lengthCommissionForm, lengthFrom: value });
      setLengthFromAlert(false)
      if(value===""){
        setLengthFromAlert(true)
      }
    }
    if (key === "lengthTo") {
      setLengthCommissionForm({ ...lengthCommissionForm, lengthTo: value });
      setLengthToAlert(false)
      if(value===""){
        setLengthToAlert(true)
      }
    }
    if (key === "usedQty") {
      setLengthCommissionForm({ ...lengthCommissionForm, usedQty: value });
      setUsedQtyAlert(false)
      if(value===""){
        setUsedQtyAlert(true)
      }
    }
    if (key === "minRate") {
      setLengthCommissionForm({ ...lengthCommissionForm, minRate: value });
    }
    if (key === "commission") {
      setLengthCommissionForm({ ...lengthCommissionForm, commission: value });
    }
  };
  //Get Cup
  const getCup = (e) => {
    setNewCup(e.target.value);
  };
  //Get Stitching
  const getStitching = (e) => {
    setNewStitching(e.target.value);
  };
  //Get Mar Model
  const getMarModel = (e) => {
    setNewMarModelName(e.target.value);
  };
  //Get Bottom Model
  const getBottomModel = (e) => {
    setNewBottomModel(e.target.value);
  };
  //----------------------------------
  //*onClick
  //Add Length Commission body
  const addLengthCommissionBody = {
    fromLength: parseFloat(lengthCommissionForm?.lengthFrom),
    toLength: parseFloat(lengthCommissionForm?.lengthTo),
    usedQty: parseFloat(lengthCommissionForm?.usedQty),
    minRate: parseFloat(lengthCommissionForm?.minRate),
    commission: parseFloat(lengthCommissionForm?.commission),
  };
  //Add Length Commission
  const addNewLengthCommission = () => {
    if (lengthCommissionForm?.lengthFrom === "") {
      setLengthFromAlert(true)
    } else if (lengthCommissionForm?.lengthTo === "") {
      setLengthToAlert(true)
    } else if (lengthCommissionForm?.usedQty === "") {
      setUsedQtyAlert(true)
    } else {
      addLengthCommissionAPICall(
        addLengthCommissionBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        clearLengthCommissionState
      );
    }
  };
  //Delete Length Commission
  const deleteLengthCommission = (lengthCommissionId) => () => {
    deleteLengthCommissionAPICall(
      { lengthCommissionId },
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      updateListener
    );
  };

  //Add Cup body
  const addCupBody = {
    cup: newCup,
  };
  //Add Cup
  const addNewCup = () => {
    if (newCup === "") {
      setUsedQtyAlert(false)
    } else {
      addCupAPICall(
        addCupBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        clearCupState
      );
    }
  };
  //Delete Cup
  const deleteCup = (cupId) => () => {
    deleteCupAPICall(
      { cupId },
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      updateListener
    );
  };

  //Add Stitching Body
  const addStitchingBody = {
    stitching: newStitching,
  };
  //Add Stitching
  const addNewStitching = () => {
    if (newStitching === "") {
      setUsedQtyAlert(false)
    } else {
      addStitchingAPICall(
        addStitchingBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        clearStitchingState
      );
    }
  };
  //Delete Stitching
  const deleteStitching = (stitchingId) => () => {
    deleteStitchingAPICall(
      { stitchingId },
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      updateListener
    );
  };

  //Add Mar Model Body
  const addMarModelBody = {
    marName: newMarModelName,
  };
  //Add MarModel
  const addNewMarModel = () => {
    if (newMarModelName === "") {
      setUsedQtyAlert(false)
    } else {
      addMarModelAPICall(
        addMarModelBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        clearMarModel
      );
    }
  };
  //Delete Mar Model
  const deleteMarModel = (marId) => () => {
    deleteMarModelAPICall(
      { marId },
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      updateListener
    );
  };
  //Add Bottom Model Body
  const addBottomModelBody = {
    name: newBottomModel,
  };
  //Add Bottom model
  const addNewBottomModel = () => {
    if (newBottomModel === "") {
      setUsedQtyAlert(false)
    } else {
      addBottomModelAPICall(
        addBottomModelBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener,
        clearBottomModelState
      );
    }
  };
  //Delete Bottom Model
  const deleteBottomModel = (_id) => () => {
    deleteBottomModelAPICall(
      { _id },
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      updateListener
    );
  };
  //-------------------------------
  //*useEffects
  useEffect(() => {
    viewLengthCommissionAPICall();
    viewCupAPICall();
    viewStitchingAPICall();
    viewMarModelAPICall();
    viewBottomModelAPICall();
  }, [isModify]);

  //-------------------------------
  //*Clear States
  //Length Commission Clear State
  const clearLengthCommissionState = () => {
    setLengthCommissionForm(lengthCommissionInitialState);
  };
  //clear cup state
  const clearCupState = () => {
    setNewCup("");
  };
  //Stitching Clear State
  const clearStitchingState = () => {
    setNewStitching("");
  };
  //Mar Model Clear State
  const clearMarModel = () => {
    setNewMarModelName("");
  };
  //Bottom Model Clear state
  const clearBottomModelState = () => {
    setNewBottomModel("");
  };
  return (
    <div className="work-order-middle-container">
      <div className="work-order-middle-left-container">
        <div className="global-single-input work-order-input">
          <p>Length</p>
          <input
            value={formValues?.length}
            onChange={userRole !== "admin" && onChange("length")}
            placeholder={userRole !== "admin" && "Enter length..."}
            disabled={userRole === "admin" ? true : false}
          />
          {userRole === "admin" && (
            <p
              onClick={() => setAddLengthCommission(true)}
              className="suffix-text-blue"
            >
              Add Length Commission
            </p>
          )}
        </div>
        <div className="global-single-input work-order-input">
          <p>Shoulder</p>
          <input
            value={formValues?.shoulder}
            onChange={userRole !== "admin" && onChange("shoulder")}
            placeholder={userRole !== "admin" && "Enter shoulder..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>Sleeve Full</p>
          <input
            value={formValues?.sleeveFull}
            onChange={userRole !== "admin" && onChange("sleeveFull")}
            placeholder={userRole !== "admin" && "Enter sleeve full..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>Chest</p>
          <input
            value={formValues?.chest}
            onChange={userRole !== "admin" && onChange("chest")}
            placeholder={userRole !== "admin" && "Enter chest..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>Waist</p>
          <input
            value={formValues?.waist}
            onChange={userRole !== "admin" && onChange("waist")}
            placeholder={userRole !== "admin" && "Enter waist..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>Body</p>
          <input
            value={formValues?.body}
            onChange={userRole !== "admin" && onChange("body")}
            placeholder={userRole !== "admin" && "Enter body..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>Neck</p>
          <input
            value={formValues?.neck}
            onChange={userRole !== "admin" && onChange("neck")}
            placeholder={userRole !== "admin" && "Enter neck..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>Cup</p>
          <Select
            value={formValues?.cup}
            onChange={userRole !== "admin" && onChange("cup")}
            placeholder={userRole !== "admin" && "Enter cup..."}
            disabled={userRole === "admin" ? true : false}
          >
            <MenuItem value='0'>-select cup-</MenuItem>
            {cupData
              ?.slice(0)
              ?.reverse()
              ?.map((item, i) => (
                <MenuItem key={i} value={item?._id}>
                  {item?.cup}
                </MenuItem>
              )) || <MenuItem>No data</MenuItem>}
          </Select>
          {userRole === "admin" && (
            <p onClick={() => setAddCup(true)} className="suffix-text-blue">
              Add Cup
            </p>
          )}
        </div>
        <div className="global-single-input work-order-input">
          <p>Regal</p>
          <input
            value={formValues?.regal}
            onChange={userRole !== "admin" && onChange("regal")}
            placeholder={userRole !== "admin" && "Enter regal..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>Knee Loose</p>
          <input
            value={formValues?.kneeLoose}
            onChange={userRole !== "admin" && onChange("kneeLoose")}
            placeholder={userRole !== "admin" && "Enter knee loose..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>Disco</p>
          <input
            value={formValues?.disco}
            onChange={userRole !== "admin" && onChange("disco")}
            placeholder={userRole !== "admin" && "Enter disco..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input">
          <p>Canvas</p>
          <input
            value={formValues?.canvas}
            onChange={userRole !== "admin" && onChange("canvas")}
            placeholder={userRole !== "admin" && "Enter canvas..."}
            disabled={userRole === "admin" ? true : false}
          />
        </div>
        <div className="global-single-input work-order-input" style={{position:"relative"}}>
          <p>Notes</p>
          <input
            value={formValues?.remark}
            onChange={userRole !== "admin" && onChange("remark")}
            placeholder={userRole !== "admin" && "Enter remark..."}
            disabled={userRole === "admin" ? true : false}
          />
          {/* <button className="work-order-notes-add-button">+</button>
          <div className="work-order-notes-content">
            <p>abc</p>
            <p>abc</p>
            <p>abc</p>
          </div> */}
        </div>
      </div>
      <div className="work-order-middle-right-container">
        <div className="global-single-input work-order-input">
          <p>Stitching</p>
          <Select
            value={formValues?.stitching}
            onChange={userRole !== "admin" && onChange("stitching")}
            disabled={userRole === "admin" ? true : false}
          >
            <MenuItem value='0'>-select stitching-</MenuItem>
            {stitchingData
              ?.slice(0)
              ?.reverse()
              ?.map((item, i) => (
                <MenuItem key={i} value={item?._id}>
                  {item?.stitching}
                </MenuItem>
              )) || <MenuItem>No data</MenuItem>}
          </Select>
          {userRole === "admin" && (
            <p
              onClick={() => setAddStitching(true)}
              className="suffix-text-blue"
            >
              Stitching
            </p>
          )}
        </div>
        <div className="global-single-input work-order-input">
          <p>Mar Model</p>
          <Select
            value={formValues?.marModel}
            onChange={userRole !== "admin" && onChange("marModel")}
            disabled={userRole === "admin" ? true : false}
          >
            <MenuItem value='0'>-select mar model-</MenuItem>
            {marModelData
              ?.slice(0)
              ?.reverse()
              ?.map((item, i) => (
                <MenuItem key={i} value={item?._id}>
                  {item?.marName}
                </MenuItem>
              )) || <MenuItem>No data</MenuItem>}
          </Select>
          {userRole === "admin" && (
            <p
              onClick={() => setAddMarModel(true)}
              className="suffix-text-blue"
            >
              Add Mar Model
            </p>
          )}
        </div>
        <div className="global-single-input work-order-input">
          <p>Bottom Model</p>
          <Select
            value={formValues?.bottomModel}
            onChange={userRole !== "admin" && onChange("bottomModel")}
            disabled={userRole === "admin" ? true : false}
          >
            <MenuItem value='0'>-select bottom model-</MenuItem>
            {bottomModelData
              ?.slice(0)
              ?.reverse()
              ?.map((r, i) => (
                <MenuItem key={i} value={r?._id}>
                  {r?.name}
                </MenuItem>
              )) || <MenuItem>No data</MenuItem>}
          </Select>
          {userRole === "admin" && (
            <p
              onClick={() => setAddBottomModel(true)}
              className="suffix-text-blue"
            >
              Add Bottom Model
            </p>
          )}
        </div>
        {/* <div className="global-single-input work-order-input">
          <p>Colour</p>
          <Select
            value={formValues?.colour}
            onChange={userRole !== "admin" && onChange("colour")}
            disabled={userRole === "admin" ? true : false}
          >
            <MenuItem value="0">-select colour-</MenuItem>
          </Select>
        </div> */}

        <div className="global-single-input work-order-input">
          <p>Seat</p>
          <div className="work-order-input-group">
            <input
              className="work-order-input-group-first"
              value={formValues?.seat1}
              onChange={userRole !== "admin" && onChange("seat1")}
              placeholder={userRole !== "admin" && "Enter seat..."}
            disabled={userRole === "admin" ? true : false}
            />
            <input
              className="work-order-input-group-second"
              value={formValues?.seat2}
              onChange={userRole !== "admin" && onChange("seat2")}
              placeholder={userRole !== "admin" && "..."}
            disabled={userRole === "admin" ? true : false}
            />
            <input
              className="work-order-input-group-second"
              value={formValues?.seat3}
              onChange={userRole !== "admin" && onChange("seat3")}
              placeholder={userRole !== "admin" && "..."}
            disabled={userRole === "admin" ? true : false}
            />
          </div>
        </div>
      </div>

      {/* add length commission */}
      <Dialog open={addLengthCommission} maxWidth="lg"  onKeyDown={(e)=>e.key === "Escape" && setAddLengthCommission(false)}>
        <div className="add-thob-model-dialog-container">
          <h3>Add Length Commission</h3>
          <div className="add-thob-model-dialog-input-container">
            <div className="global-single-input from-length">
              <p>Length From</p>
              <input
                type="text"
                placeholder="Length from..."
                value={lengthCommissionForm?.lengthFrom}
                onChange={getLengthCommissionForm("lengthFrom")}
              />
              {
                lengthFromAlert?
                <p className="doc-validation-alert">Fill this field !!</p>
                :""
              }
            </div>
            <div className="global-single-input from-length">
              <p>To</p>
              <input
                type="text"
                placeholder="Length to..."
                value={lengthCommissionForm?.lengthTo}
                onChange={getLengthCommissionForm("lengthTo")}
              />
              {
                lengthToAlert?
                <p className="doc-validation-alert">Fill this field !!</p>
                :""
              }
            </div>
            <div className="global-single-input from-length">
              <p>Used Qty</p>
              <input
                type="text"
                placeholder="Used Qty..."
                value={lengthCommissionForm?.usedQty}
                onChange={getLengthCommissionForm("usedQty")}
              />
              {
                usedQtyAlert?
                <p className="doc-validation-alert">Fill this field !!</p>
                :""
              }
            </div>
            <div className="global-single-input from-length">
              <p>Min Rate</p>
              <input
                type="text"
                placeholder="Minimum rate..."
                value={lengthCommissionForm?.minRate}
                onChange={getLengthCommissionForm("minRate")}
              />
            </div>
            <div className="global-single-input from-length">
              <p>Commission</p>
              <input
                type="text"
                placeholder="Commission..."
                value={lengthCommissionForm?.commission}
                onChange={getLengthCommissionForm("commission")}
                onKeyDown={(e) => e.key === "Enter" && addNewLengthCommission()}
              />
            </div>
            <button onClick={addNewLengthCommission}>+</button>
          </div>
          <div className="add-popup-table">
            <table>
              <thead>
                <tr>
                  <th>Length(From-To)</th>
                  <th>Used Qty</th>
                  <th>Min Rate</th>
                  <th>Commission</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {lengthCommissionData
                  ?.slice(0)
                  ?.reverse()
                  ?.map((r, i) => (
                    <tr key={i}>
                      <td>{`${r?.fromLength} - ${r?.toLength}`}</td>
                      <td>{r?.usedQty}</td>
                      <td>{r?.minRate}</td>
                      <td>{r?.commission}</td>
                      <td>
                        <IconButton onClick={deleteLengthCommission(r?._id)}>
                          <i class="bi bi-trash3 delete-icon"></i>
                        </IconButton>
                      </td>
                    </tr>
                  )) || (
                  <tr>
                    <td colSpan={5}>No Data</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="add-popup-button-container">
            <button
              onClick={() => setAddLengthCommission(false)}
              className="btn btn-secondary"
            >
              Close
            </button>
          </div>
        </div>
      </Dialog>

      {/* add cup */}
      <AddCategoryPopup
        open={addCup}
        label="Add Cup"
        closePopup={() => setAddCup(false)}
        onChange={getCup}
        value={newCup}
        addBtnClick={addNewCup}
      >
        {cupData
          ?.slice(0)
          ?.reverse()
          ?.map((r, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{r?.cup}</td>
              <td>
                <IconButton onClick={deleteCup(r?._id)}>
                  <i class="bi bi-trash3 delete-icon"></i>
                </IconButton>
              </td>
            </tr>
          )) || (
          <tr>
            <td colSpan={3}>No data</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* add stitching */}
      <AddCategoryPopup
        open={addStitching}
        label="Add Stitching"
        closePopup={() => setAddStitching(false)}
        onChange={getStitching}
        value={newStitching}
        addBtnClick={addNewStitching}
      >
        {stitchingData
          ?.slice(0)
          ?.reverse()
          ?.map((r, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{r?.stitching}</td>
              <td>
                <IconButton onClick={deleteStitching(r?._id)}>
                  <i class="bi bi-trash3 delete-icon"></i>
                </IconButton>
              </td>
            </tr>
          )) || (
          <tr>
            <td colSpan={3}>No data</td>
          </tr>
        )}
      </AddCategoryPopup>
      {/* add mar model */}
      <AddCategoryPopup
        open={addMarModel}
        label="Add Mar Model"
        closePopup={() => setAddMarModel(false)}
        onChange={getMarModel}
        value={newMarModelName}
        addBtnClick={addNewMarModel}
      >
        {marModelData
          ?.slice(0)
          ?.reverse()
          ?.map((r, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{r?.marName}</td>
              <td>
                <IconButton onClick={deleteMarModel(r?._id)}>
                  <i class="bi bi-trash3 delete-icon"></i>
                </IconButton>
              </td>
            </tr>
          )) || (
          <tr>
            <td colSpan={3}>No Data</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* add bottom model */}
      <AddCategoryPopup
        open={addBottomModel}
        label="Add Bottom Model"
        closePopup={() => setAddBottomModel(false)}
        onChange={getBottomModel}
        value={newBottomModel}
        addBtnClick={addNewBottomModel}
      >
        {bottomModelData
          ?.slice(0)
          ?.reverse()
          ?.map((r, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{r?.name}</td>
              <td>
                <IconButton onClick={deleteBottomModel(r?._id)}>
                  <i class="bi bi-trash3 delete-icon"></i>
                </IconButton>
              </td>
            </tr>
          )) || (
          <tr>
            <td colSpan={3}>No data</td>
          </tr>
        )}
      </AddCategoryPopup>

      {/* SnackBars  */}
      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </div>
  );
};
