import {
    createSlice
} from '@reduxjs/toolkit'
export const allBranchesSlice = createSlice({
    name: 'allBranchesSlice',
    initialState: {
        value: undefined
    },
    reducers: {
        update_company_branches: (state, action) => {
            state.value = action.payload.branches
        }
    }
})

export const {
    update_company_branches
} = allBranchesSlice.actions
export default allBranchesSlice.reducer

