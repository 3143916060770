import { Snackbar, Button } from '@mui/material';
import React, { useState } from 'react';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ErrorSnackbar = ({ open, handleClose, message, style, showButtons,wallet,credit }) => {
  
  const handleYes=()=>{
    wallet !==undefined && wallet !==false && wallet(true)
    credit !==undefined && credit !==false && credit(true)
  }
  const handleCancel=()=>{
    handleClose()
    wallet !==undefined && wallet !==false && wallet(false)
    credit !==undefined && credit !==false && credit(false)
  }
  
  return (
    <>
      <Snackbar open={open} autoHideDuration={showButtons?null:5000} onClose={showButtons?null:handleClose} style={style}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {message}
          {showButtons && ( // Conditionally render buttons if showButtons is true
            <div className='snakcbar-alert-popup'>
              <Button color="inherit" size="small"
               onClick={handleYes}
               >
                Ok
              </Button>
              <Button color="inherit" size="small"
               onClick={handleCancel}
               >
                Cancel
              </Button>
            </div>
          )}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ErrorSnackbar;



// import {  Snackbar } from '@mui/material'
// import React from 'react'
// import MuiAlert from '@mui/material/Alert';



// const Alert = React.forwardRef(function Alert(props, ref) {
//     return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
//   });

// const ErrorSnackbar = ({open,handleClose,message}) => {
//   return (
//     <>
//       <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
//           <Alert onClose={handleClose} severity="error"  sx={{width:"100%"}}>
//                 {message}
//           </Alert>
//       </Snackbar>   
//     </>
//   )
// }

// export default ErrorSnackbar
