import { MenuItem, Select } from "@mui/material";
import React, { useMemo, useState } from "react";
import { addRewardAndPointSettingsAPICall } from "../../../../API/RewardsAndPoints/RewardsAndPointsAPI";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
// css from settings.css

const inputFormKeys = {
  POINTS: "points",
  AMOUNT: "amount",
};

export const RewardsAndPointsSettings = () => {
  //input states
  const inputFormInitialState = useMemo(
    () => ({
      points: "",
      amount: "",
    }),
    []
  );
  const [inputForm, setInputForm] = useState(inputFormInitialState);
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false);
  const [openErrorSnack, setOpenErrorSnack] = useState(false);
  const [snackMsg, setSnackMsg] = useState("default999...");

  //close SuccessSnack
  const closeSuccessSnackbar = () => {
    setOpenSuccessSnack(false);
  };
  const closeErrorSnackbar = () => {
    setOpenErrorSnack(false);
  };

  //clear states
  const clearStates = () => {
    setInputForm(inputFormInitialState);
  };

  //Onchange handler
  const getInputFormValues = (key) => (e) => {
    const { value } = e.target;
    switch (key) {
      case inputFormKeys.POINTS:
        setInputForm({ ...inputForm, points: value });
        break;
      case inputFormKeys.AMOUNT:
        setInputForm({ ...inputForm, amount: value });
        break;

      default:
        break;
    }
  };

  //click final submit
  const clickFinalSubmit = () => {
    if (inputForm.points == "") {
      alert("Enter Points!!");
    } else if (inputForm.amount == "") {
      alert("Enter amount!!");
    } else {
      addRewardAndPointSettingsAPICall(
        { point: Number(inputForm.points), amount: Number(inputForm.amount) },
        setOpenSuccessSnack,
        setOpenErrorSnack,
        setSnackMsg
      );
    }
  };

  return (
    <>
      <div className="settings-rewards-container">
        <div className="settings-rewards-container-top">
          <h4>Rewards & Points</h4>
          <div className="settings-rewards-body">
            <div className="global-single-input">
              <p>Points</p>
              <input
                type="text"
                value={inputForm.points}
                onChange={getInputFormValues(inputFormKeys.POINTS)}
              />
            </div>
            <div className="global-single-input settings-reward-input">
              <p>Amount</p>
              <input
                type="text"
                value={inputForm.amount}
                onChange={getInputFormValues(inputFormKeys.AMOUNT)}
              />
              {/* <Select value="two" className="settings-reward-drop-down">
                        <MenuItem value="two"><sub> Rs</sub></MenuItem>
                        <MenuItem value="one"><sub>$</sub></MenuItem>
                        <MenuItem value="one"><sub>SR</sub></MenuItem>
                    </Select> */}
            </div>
          </div>
        </div>
        <button
          className="btn btn-primary settings-reward-btn"
          onClick={clickFinalSubmit}
        >
          Submit
        </button>
      </div>

      <SuccessSnackbar
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
        open={openSuccessSnack}
      />
      <ErrorSnackbar
        handleClose={closeErrorSnackbar}
        message={snackMsg}
        open={openErrorSnack}
      />
    </>
  );
};
