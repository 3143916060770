import {createSlice} from '@reduxjs/toolkit'

export const dailyCashCardSummarySlice=createSlice({
    name:'dailyCashCardSummary',
    initialState:{
        value:undefined
    },
    reducers:{
        get_daily_cash_card_summary:(state,action)=>{
            state.value=action.payload.dailyCashCardData;
        }
    }
})
export const {get_daily_cash_card_summary} =dailyCashCardSummarySlice.actions;
export default dailyCashCardSummarySlice.reducer