import axios from "axios";
import { HEADERS } from "../../UrlAndPaths";
export const StockOutApproveAPICall = async (body,updateListener) => {
    await axios
      .post("purchase/approveStokeout", body, HEADERS)
      .then((res) => {
        if (res.status === 200) {
          updateListener()
        }
      })
      .catch((err) => {
        console.error(err.response);
      });
  };