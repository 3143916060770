import axios from "axios";
import { update_all_supplier_list } from "../../Redux/Prchase Manager/Supplier/allSupplierList";
import { update_supplier_ledger } from "../../Redux/Prchase Manager/Supplier/supplierLedgerSlice";
import { get_view_credit_limit } from "../../Redux/Prchase Manager/Supplier/viewCreditLimitSlice";
import store from "../../Redux/store";
import { HEADERS,HEADER_QUERY } from "../UrlAndPaths";

//Add New Supplier
export const addNewSupplierAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates
) => {
  await axios
    .post(`purchase/addsupplier`, body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSuccess(true);
        setSnackMsg("Supplier Creation Success..");
        updateListener();
        console.log("New Supplier Added Successfully");
        clearStates();
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network Connection error!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Category Already Exist");
      }

      if (err.response.status === 401) {
        setSnackMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden");
      }

      setError(true);
      console.log(err.response);
    });
};

//View All Suppliers
export const viewAllSuppliersAPICall = async (setIsLoading,setErrorSnack,setSnackMsg) => {
  if(setIsLoading!==undefined)setIsLoading(true)
  await axios
  .post("purchase/viewsupplier",{}, HEADERS)
  .then((res) => {
    if (res.status === 200) {
      store.dispatch(update_all_supplier_list({ allSupplierList: res.data }));
      if(setIsLoading!==undefined)setIsLoading(false)
      console.log("All Suppliers Fetched..");
    }
  })
  .catch((err) => {
    console.error(err.response);
    store.dispatch(update_all_supplier_list({ allSupplierList: undefined }));
    switch (err.response.status) {
      case 404:
          setSnackMsg('No data available..')
        break;
      case 500:
          setSnackMsg('Server error!!')
        break;
      case 401:
          setSnackMsg('Unauthorized!!')
        break;
      case 403:
          setSnackMsg('Forbidden!!')
        break;
    
      default:
          setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
        break;
    }
    setErrorSnack(true)
    if(setIsLoading!==undefined)setIsLoading(false)
    });
};

// View Supplier Ledger
export const viewSupplierLedgerAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates
) => {
  await axios
    .post("purchase/supplierledger", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSuccess(true);
        store.dispatch(update_supplier_ledger({supplierLedger:res.data}))
        setSnackMsg("Supplier Ledger Fetched 👍");
        updateListener();
        console.log("Supplier Ledger fetched successfully");
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network Connection error!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Category Already Exist");
      }

      if (err.response.status === 401) {
        setSnackMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden");
      }
      if (err.response.status === 404) {
        setSnackMsg("No data found!!!");
      }

      setError(true);
      console.log(err.response);
      store.dispatch(update_supplier_ledger({supplierLedger:undefined}))
    });
};

// delete supplier 
export const deleteSupplierAPICall = async (_id,updateListener)=>{
  await axios.delete("purchase/deletesupplier",{
    headers:HEADER_QUERY,
    data:{
      _id,
    },
  })
  .then((res)=>{
    if(res.status === 200){
      updateListener()
    }
  })
  .catch((err)=>{
    console.log(err.response);
  })
}


// edit supplier
export const editSupplierAPICall = async (
  body,
  setEditSupplierDialog,
  updateListener,
  setSuccess,
  setError,
  setSnackMsg,
  )=>{
 await axios.put("purchase/editsupplier",body,HEADERS)
 .then((res)=>{
  if(res.status === 200){
    setSuccess(true);
    setSnackMsg("Success..");
    updateListener()
    setEditSupplierDialog(false)
  }
 })
 .catch((err)=>{
  if (err.response === undefined) {
    setSnackMsg("Network Connection error!!");
  }
  if (err.response.status === 409) {
    setSnackMsg("Category Already Exist");
  }

  if (err.response.status === 401) {
    setSnackMsg("Unauthorized");
  }
  if (err.response.status === 403) {
    setSnackMsg("Forbidden");
  }

  setError(true);
  console.log(err.response);

 })
}


// view credit limit
export const viewCreditLimitAPICall = async () =>{
  await axios.post("purchase/viewcreditLimit",{},HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_view_credit_limit({creditLimitData:res.data}))
    }
  })
  .catch((err)=>{
    store.dispatch(get_view_credit_limit({creditLimitData:undefined}))
    console.log(err.response);
  })
}

// add credit limit 
export const addCreditLimitAPICall = async(body,updateListener,setAddCreditLimitDialog)=>{
  await axios.post("purchase/addcreditLimit",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      // console.log('credit added');
      updateListener()
      setAddCreditLimitDialog(false)
    }
  })
  .catch((err)=>{
    console.log(err.response);
  })
}

