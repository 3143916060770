import { createSlice } from "@reduxjs/toolkit";

export const holidayTypeViewSlice =createSlice({
    name:'holidayTypeViewSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_holiday_type_list:(state,action)=>{
            state.value=action.payload.holidayType
        }
    }
})

export const{update_holiday_type_list}=holidayTypeViewSlice.actions
export default holidayTypeViewSlice.reducer