import axios from "axios";
import { update_all_employee_loan_list } from "../../../Redux/Staff/HRM/Employee/allEmployeeLoanSlice";
import { update_single_employee_loan } from "../../../Redux/Staff/HRM/Employee/singleEmployeeLoanSlice";
import store from "../../../Redux/store";
import { HEADERS } from "../../UrlAndPaths";



// View All Loans

export const viewAllEmployeeLoan =async(setIsLoading,setErrorSnack,setSnackMsg)=>{
    if(setIsLoading!==undefined) setIsLoading(true)
    await axios.get('employee/viewLoans',HEADERS).then((res)=>{
        if(res.status === 200){
            store.dispatch(update_all_employee_loan_list({
                allemployeeLoanData: res.data
            }))
            if(setIsLoading!==undefined) setIsLoading(false)
        }
    }).catch((err)=>{
        store.dispatch(update_single_employee_loan({
            allemployeeLoanData:undefined
        }))
        switch (err.response.status) {
            case 404:
                setSnackMsg('No data available..')
              break;
            case 500:
                setSnackMsg('Server error!!')
              break;
            case 401:
                setSnackMsg('Unauthorized!!')
              break;
            case 403:
                setSnackMsg('Forbidden!!')
              break;
          
            default:
                setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
              break;
          }
          setErrorSnack(true)
        if(setIsLoading!==undefined) setIsLoading(false)
    })
}



// View SIngle Loan

export  const viewEmployeeSingleLoan =async (body) =>{
    await axios.post('employee/viewLoanSingle',body,HEADERS).then((res)=>{
        if(res.status === 200){
            store.dispatch(update_single_employee_loan({ singleEmpLoan:res.data}));
        }
    })
    .catch((err)=>{
            store.dispatch(update_single_employee_loan({ singleEmpLoan: undefined}));
    })
}

// Apply new loan


export const applyForSingleLoanAPI = async(body,updateListener,setError,setSucces,setMsg,clearState)=>{
        await axios.post('employee/addLoan',body,HEADERS).then((res)=>{
            if(res.status === 200){
                updateListener();
                setMsg("Added Succesfully");
                setSucces(true);
                

                clearState();
            }
        }).catch((err) =>{
            if (err.response.status === undefined) {
                setMsg("Network Connection error!!");
            }
            if (err.response.status === 409) {
                setMsg("Already Exist");
            }
    
            if (err.response.status === 401) {
                setMsg("Unauthorized");
            }
            if (err.response.status === 403) {
                setMsg("Forbidden");
            }

            setError(true);
            console.error(err.response);
        })
}


// Approve Loan

export const approveSingleLoanAPICall =async(body,updateListener)=>{
    await axios.put("employee/approveLoan",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            updateListener()
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}

// admin add new loan

export const addNewLoanAdminAPICall=async(body,clearStates)=>{
    await axios.post("employee/addNewLoan",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            clearStates()
        }
    })
    .catch((err)=>{
        console.error(err.response);
    })
}