import React, { useEffect } from "react";
import "../../../../css/Purchase Manager/supplierLedger.css";
import { Select, MenuItem, IconButton, Autocomplete, TextField } from "@mui/material";
import {
  viewAllSuppliersAPICall,
  viewSupplierLedgerAPICall,
} from "../../../../API/Purchase Manager/supplierAPI";
import { useSelector } from "react-redux";
import { useState } from "react";
import { convertDateFormat, currentTime, previousDate, today } from "../../../../Js/Date";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";

export const SupplierLedger = () => {
  //*Redux States
  const allSuppliersList = useSelector(
    (state) => state.allSupplierListSlice.value
  );
  const supplierLedgerData = useSelector(
    (state) => state.supplierLedgerSlice.value
  );
   

  //Update Listener States
  const [isModify, setIsModify] = useState(false);
  //Snackbar States
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!");
  const [supplierValidationAlert, setSupplierValidationAlert] = useState(false);


  //Supplier Ledger form
  const supplierLedgerInitialData = {
    supplier: "",
    fromDate: previousDate,
    toDate: today,
  };
  const [supplierLedgerForm, setSupplierLedgerForm] = useState(
    supplierLedgerInitialData
  );
  const [time, setTime] = useState(null);
   // get current time fn
   useEffect(() => {
    setInterval(() => {
      setTime(`${new Date().getHours()}:${new Date().getMinutes()}`);
    }, 1000);
  }, [time]);

  //Update Listener
  const updateListener = () => {
    setIsModify(!isModify);
  };

  //Close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setSuccessSnackOpen(false);
  };
  //Close ErrorSnackbar
  const closeErrorSnackbar = () => {
    setErrorSnackOpen(false);
  };

  //*useEffects
  //Call Get All Suppliers API
  useEffect(() => {
    viewAllSuppliersAPICall();
  }, []);

  //*onchange fn
  //get form fields from front end
  const getSupplierLedgerForm = (key) => (e) => {
    const { value } = e.target;

    if (key === "supplier") {
      setSupplierLedgerForm({ ...supplierLedgerForm, supplier: value });
      
    }
    if (key === "fromDate") {
      setSupplierLedgerForm({ ...supplierLedgerForm, fromDate: value });
    }
    if (key === "toDate") {
      setSupplierLedgerForm({ ...supplierLedgerForm, toDate: value });
    }
  };

  //*Click Functions
  //Submit fn
  const supplierLedgerBody = {
    suppId: supplierLedgerForm.supplier,
    frmDate:supplierLedgerForm.fromDate !== ''?supplierLedgerForm.fromDate:null,
    toDate:supplierLedgerForm.toDate !==''?supplierLedgerForm.toDate:null,
  };
  // console.log(supplierLedgerBody);
  const supplierLedgerSubmit = () => {
    if (supplierLedgerForm.supplier !== "") {
      viewSupplierLedgerAPICall(
        supplierLedgerBody,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        updateListener
      );
    } else {
      setSupplierValidationAlert(true)
    }
  };

  return (
    <>
      <div className="global-page-parent-container">
        <p className="breadcrumb">Purchase Manager &gt; Supplier Ledger</p>
        <div className="supplier-ledger-container">
          <h3>Supplier Ledger</h3>
          <hr className="global-hr" />
          <div className="suppler-ledger-input-container">
            <div className="global-single-input supplier-ledger auto-complete" style={{position:"relative"}}>
              <p>Select Supplier</p>
              <Autocomplete
                sx={{width:'100%'}}
                options={allSuppliersList?.slice(0)?.reverse()||[""]}
                getOptionLabel={(option)=>
                  option?.supplierName
                }
                renderInput={(params) => <TextField {...params}
                placeholder="Select Supplier"
                />
               }
               onChange={(e,newValue)=>{
                 setSupplierValidationAlert(false)
                 setSupplierLedgerForm({...supplierLedgerForm,supplier:newValue?._id})
                 if(newValue===null){
                  setSupplierValidationAlert(true)
                 }
                }
               }
              />
              {
                supplierValidationAlert?
                <p className="doc-validation-alert">Select a  supplier</p>
                :""
              }
            </div>
            <div className="global-single-input supplier-ledger">
              <p>From</p>
              <input
                type="date"
                value={supplierLedgerForm.fromDate}
                onChange={getSupplierLedgerForm("fromDate")}
              />
            </div>
            <div className="global-single-input supplier-ledger">
              <p>To</p>
              <input
                type="date"
                value={supplierLedgerForm.toDate}
                onChange={getSupplierLedgerForm("toDate")}
              />
            </div>
          </div>
          <button
            className="btn btn-primary supplier-ledger-button"
            onClick={supplierLedgerSubmit}
          >
            Submit
          </button>
        </div>
        {supplierLedgerData !== undefined && (
          <div className="supplier-ledger-second-container">
            <div className="supplier-ledger-second-top-container">
              <div className="supplier-ledger-second-top-left-container">
                <div className="supplier-ledger-name-container">
                  <p className="supplier-ledger-name">Name</p>
                  <p className="supplier-ledger-content">
                    :&nbsp;{supplierLedgerData?.supplierName}
                  </p>
                </div>
                <div className="supplier-ledger-name-container">
                  <p className="supplier-ledger-name">Opening Balance</p>
                  <p className="supplier-ledger-content">
                    :&nbsp;{supplierLedgerData?.supplierOpeningBalance}
                  </p>
                </div>
                <div className="supplier-ledger-name-container">
                  <p className="supplier-ledger-name">Address</p>
                  <p className="supplier-ledger-content">
                    :&nbsp;{supplierLedgerData?.supplierAddress}
                  </p>
                </div>
              </div>
              <div className="supplier-ledger-second-top-right-container">
                <div className="supplier-ledger-icon-container">
                  <IconButton className="print-icon-container">
                    <i class="bi bi-printer printer-icon"></i>
                  </IconButton>
                  <IconButton className="download-icon-container">
                    <i class="bi bi-arrow-down-circle download-icon"></i>
                  </IconButton>
                </div>
                <p>

                  Date&nbsp;: {convertDateFormat(today)} , {currentTime}

                </p>
              </div>
            </div>
            <div className="global-table-container">
              <table className="global-table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Invoice No</th>
                    <th>Date</th>
                    <th>Description</th>

                    <th>Paid</th>
                    <th>Debit</th>
                    <th>Credit</th>
                    <th>Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {supplierLedgerData?.rslist?.length !== 0 ? supplierLedgerData?.rslist?.map((r, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{r?.invoiceNo}</td>
                      <td>{r?.date?.split(" ")[0]}</td>
                      <td>-no field-</td>

                      <td>{r?.paid}</td>


                      <td>{r?.debit?.toFixed(2)}</td>
                      <td>{r?.credit?.toFixed(2)}</td>
                      <td>{r?.balance?.toFixed(2)}</td>
                    </tr>
                  ))
                  :
                  <tr>
                    <td colSpan={8} style={{textAlign:"center"}}>No Data</td>
                  </tr>
                }
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </>
  );
};
