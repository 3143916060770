import { createSlice } from "@reduxjs/toolkit";

export const sidePTModelSlice=createSlice({
    name:"sidePTModelSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        update_sidePT_model:(state,action)=>{
            state.value=action.payload.sidePtModel
        }
    }
})

export const {update_sidePT_model}=sidePTModelSlice.actions
export default sidePTModelSlice.reducer