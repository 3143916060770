import { createSlice } from "@reduxjs/toolkit";

export const expenseReportSlice=createSlice({
    name:'expenseReportSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_expense_report:(state,action)=>{
            state.value=action.payload.expenseReportData
        }
    }
})
export const {get_expense_report} = expenseReportSlice.actions
export default expenseReportSlice.reducer
