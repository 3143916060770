import React,{useState,useRef,useEffect} from 'react'
import {Select,IconButton,Dialog,MenuItem, Autocomplete, TextField, Skeleton} from '@mui/material'
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";
import { viewAllBranchesAPICall } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import {stockAdjustmentTransAPICall,stockAdjustmentAPICall,stockAdjSingleViewAPICall} from '../../../../API/Report/stockAdjustmentReportAPI'
import { today,currentTime,previousDate, convertDateFormat, getCurrentTime } from "../../../../Js/Date";
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { sortGeneral } from '../../../../Js/generalFunctions';
// css
//import '../../../../css/Report/stockReport.css'
export const StockAdjustmentReport = () => {
   let stockAdjustmentPrint=useRef()
  // stock adj trans no
  const stockAdjTransNo=useSelector((state)=>state.stockAdjTransNo.value)
  // stock adjustment data
  const stockAdjustmentData=useSelector((state)=>state.stockAdjustmentSlice.value)
  // all branch list
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  // stock adjustmnet single view data
  const stockAdjSingleView=useSelector((state)=>state.stockAdjSingleView.value)
 // role based rendering
 const userRole = useSelector((state) => state.userRoleSlice.value);
 // login response
const loginResponse = useSelector((state) => state.loginResponseSlice.value);

  const [stockAdjReportView,setStockAdjReportView]=useState(false)

  const [transactionNo,setTransactionNo]=useState(null)
  const [fromBranch,setFromBranch]=useState(null)
  const [toBranch,setToBranch]=useState(null)
  const [time, setTime] = useState(null);
  const [isLoading,setIsLoading]=useState(false)
  const [isAscending,setIsAscending]=useState(false)
  const [stockAdjustmentFilter,setStockAdjustmentFilter]=useState([])
  

  useEffect(()=>{
    stockAdjustmentTransAPICall()
    viewAllBranchesAPICall()
    stockAdjustmentAPICall({},setIsLoading)
  },[])

   // get current time fn
   useEffect(() => {
    setInterval(() => {
      setTime(`${new Date().getHours()}:${new Date().getMinutes()}`);
    }, 1000);
  }, [time]);

  // onChange functions
  const getTransactionNo=(e)=>{
    setTransactionNo(e.target.value)
  }
  const getFromBranch=(e)=>{
    setFromBranch(e.target.value)
  }
  const getToBranch=(e)=>{
     setToBranch(e.target.value)
  }

  // stock addjustmnt submit button function
   const formData=new FormData();
   {transactionNo !== null && transactionNo !== undefined&&
    formData.append("transNo",transactionNo)
   }
   {
    fromBranch !== null &&  fromBranch !== undefined &&
    formData.append("fromLoc",fromBranch)
   }
   {
      toBranch !== null && toBranch !== undefined &&
      formData.append("toLoc",toBranch)
   }

   const bodyToApi={
    transNo:transactionNo !== null && transactionNo !== undefined ? transactionNo:null,
    fromLoc: fromBranch !== null &&  fromBranch !== undefined ? fromBranch?._id : null,
    toLoc:toBranch !== null && toBranch !== undefined ? toBranch?._id :null
   }
    
  


  const stockAdjustmentSubmitBtnFn=()=>{
    stockAdjustmentAPICall(bodyToApi,setIsLoading)
    // console.log(stockAdjustmentData);
  }

  // stock adjustment single view function
  const stockAdjSingleViewBtnFn=(id)=>{
    setStockAdjReportView(true)
    stockAdjSingleViewAPICall({id:id})
  } 

  /// extract from location
  useEffect(()=>{
    let branchFilter=allBranchesList?.filter((obj)=>{
     return obj._id===loginResponse.branchPk
    })
    if(branchFilter !== undefined) setFromBranch(branchFilter[0])
  },[allBranchesList,loginResponse.branchPk])


  // map stock adjustment(response) data to state
  useEffect(()=>{
    if(stockAdjustmentData !== undefined){
      setStockAdjustmentFilter(stockAdjustmentData?.map(obj=>{return {...obj}}))
    }
  },[stockAdjustmentData])
  // sorting
  // sort ascending order by date
  const sortDateAscending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(stockAdjustmentFilter, "Date", "transferDate", true);
    setStockAdjustmentFilter(sorted)
  }
  // sort descending order by date
  const sortDateDescending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(stockAdjustmentFilter, "Date", "transferDate", false);
    setStockAdjustmentFilter(sorted)
  }

  // status sorting
  const sortStatusAscending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(stockAdjustmentFilter, "String", "status", true);
    setStockAdjustmentFilter(sorted)
  }
  const sortStatusDescending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(stockAdjustmentFilter, "String", "status", false);
    setStockAdjustmentFilter(sorted)
  }

  // branch code sorting
  
  const sortBranchCodeAscending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(stockAdjustmentFilter, "String", "fromLocStoreCode", true);
    setStockAdjustmentFilter(sorted)
  }
  const sortBranchCodeDescending=()=>{
    setIsAscending(!isAscending)
    let sorted = sortGeneral(stockAdjustmentFilter, "String", "fromLocStoreCode", false);
    setStockAdjustmentFilter(sorted)
  }


  return (
    <div className="global-page-parent-container">
    <p className="breadcrumb">Report &gt; Stock Adjustment</p>
    <div className="daily-report-top-container">
      <h3>Stock Adjustment Report</h3>
      <hr className="global-hr"/>
      <div className="daily-report-top-input-container" >
       {/* <div className="global-single-input">
        <p>Branch</p>
        <Select></Select>
       </div> */}
       <div className="global-single-input auto-complete">
        <p>Transaction ID</p>
          <Autocomplete
             sx={{width:"100%"}}
             options={stockAdjTransNo|| [""]}
             getOptionLabel={(option) => `${option.prefix}${option.transNo}`}

             renderInput={(params) => <TextField {...params} 
             placeholder="Select Transaction ID"
             />}
            
             onChange={(e, newValue) =>
              setTransactionNo(newValue?.transNo)
             }
            />

        {/* <Select
          value={transactionNo}
          onChange={getTransactionNo}
        >
          {stockAdjTransNo?.map((r,i)=>(
            <MenuItem key={i} value={r.transNo}>{`${r.prefix}${r.transNo}`}</MenuItem>
          ))}
        </Select> */}

       </div>
       <div className="global-single-input auto-complete">
        <p>Location From</p>
        <Autocomplete
             sx={{width:"100%"}}
             options={allBranchesList|| [""]}
             getOptionLabel={(option) => `${option?.storeCode}-${option?.branchName}`}

             renderInput={(params) => <TextField {...params} 
             placeholder="Select Location"
             />}
             disabled={userRole==="user"}
             value={fromBranch}
             onChange={(e, newValue) =>
              setFromBranch(newValue)
             }
            />
        {/* <Select
          value={fromBranch}
          onChange={getFromBranch}
        >
              {allBranchesList?.map((item, i) => (
                  <MenuItem value={item?._id} key={i}>
                    {`${item?.storeCode}-${item?.branchName}`}
                  </MenuItem>
                ))}
        </Select> */}
       </div>
       <div className="global-single-input auto-complete">
        <p>Location To</p>
        <Autocomplete
             sx={{width:"100%"}}
             options={allBranchesList|| [""]}
             getOptionLabel={(option) => `${option?.storeCode}-${option?.branchName}`}

             renderInput={(params) => <TextField {...params} 
             placeholder="Select Location"
             />}
            
             onChange={(e, newValue) =>
              setToBranch(newValue)
             }
            />
        {/* <Select
          value={toBranch}
          onChange={getToBranch}
        >
             {allBranchesList?.map((item, i) => (
                  <MenuItem value={item?._id} key={i}>
                    {`${item?.storeCode}-${item?.branchName}`}
                  </MenuItem>
                ))}
        </Select> */}
       </div>
       <button onClick={stockAdjustmentSubmitBtnFn} className="btn btn-primary report-submit-btn">Submit</button>
      
      </div>
     
    </div>
    
    <div className="global-white-bg-container">
    <div className="global-table-container">
        <table className="global-table">
           <thead>
             <tr>
              <th>No</th>
              {
                userRole ==="admin" &&
                <th>
                  Branch Code
                  {
                isAscending ?(
                  <IconButton
                    onClick={sortBranchCodeAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortBranchCodeDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
                </th>
              }
             
              <th>Transaction ID</th>
             
              <th>Location From </th>
              <th>Location To</th>
              <th>
                Date
                {
                isAscending ?(
                  <IconButton
                    onClick={sortDateAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortDateDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
              </th>
              <th>
                Status
                {
                isAscending ?(
                  <IconButton
                    onClick={sortStatusAscending}
                  >
                     <ArrowUpward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                ):
                (
                  <IconButton 
                  onClick={sortStatusDescending}
                  >
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                  </IconButton>
                )
              }
                </th>
              <th>View</th>

             </tr>
           </thead>
           {
            isLoading ? (
              <tbody>
              <tr>
                   <td colSpan={10}>
                     <Skeleton
                       variant="rectangular"
                       animation="wave"
                       width={"100%"}
                       height={30}
                     />
                   </td>
                 </tr>
                 <tr>
                   <td colSpan={10}>
                     <Skeleton
                       variant="rectangular"
                       animation="wave"
                       width={"100%"}
                       height={30}
                     />
                   </td>
                 </tr>
                 <tr>
                   <td colSpan={10}>
                     <Skeleton
                       variant="rectangular"
                       animation="wave"
                       width={"100%"}
                       height={30}
                     />
                   </td>
                 </tr>
           </tbody>
            ):(
              <tbody>
              {stockAdjustmentFilter?.slice(0)?.reverse()?.map((r,i)=>(
                  <tr key={i}>
                  <td>{i+1}</td>{
                    userRole === 'admin' &&
                      <td>{r.fromLocStoreCode}</td>
                     }
                  
                  <td>{r.transNo}</td>
                 
                  <td>{r.fromLoc}</td>
                  <td>{r.toLoc}</td>
                  <td>{convertDateFormat(r.transferDate)}</td>
                  <td>
                    <p
                     className={
                      r.status==="Pending"?
                       'stock-adj-status-pending'
                      :r.status==='Completed'?
                      'stock-adj-status-confirm'
                      :r.status==='Confirmed'?
                      'stock-adj-status-complete'
                      :r.status==='Drafted'?
                     'stock-adj-status-drafted'
                      :undefined
                    }
                    >{r.status}</p>
                  </td>
                  <td>
                   <IconButton onClick={()=>stockAdjSingleViewBtnFn(r._id)}>
                     <i class="bi bi-eye visibility-icon"></i>
                   </IconButton>
                  </td>
                </tr>
              ))}
            
             </tbody>
            )
           }
          
        </table>
     </div>
    </div>
    
    {/* stock adjustment report single view */}
    <Dialog open={stockAdjReportView} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setStockAdjReportView(false)}>
      <div className="stock-adj-report-view-container">
       
        <div style={{display:"flex",justifyContent:"space-between"}}>
        <h3>Stock Adjustment Report View</h3>
        <div className="stock-report-print-download-icon-container">
        <ReactToPrint
        trigger={()=>(
            <IconButton className="print-icon-container">
              <i class="bi bi-printer printer-icon"></i>
            </IconButton>
        )}
        content={()=>stockAdjustmentPrint}
        pageStyle="@page { size: auto;  margin: 15mm 0 10mm 0; } }"
      />  
          <IconButton className="download-icon-container">
            <i class="bi bi-arrow-down-circle download-icon"></i>
           </IconButton>
        </div>
        </div>
        <div className="stock-adj-report-view-input-container">
          <div className="global-single-input">
             <p>Transaction ID</p>
             <input type="text" disabled value={stockAdjSingleView?.transNo}/>
          </div>
          <div className="global-single-input">
             <p>Location</p>
             <input type="text" disabled value={stockAdjSingleView?.fromLoc}/>
          </div>
          <div className="global-single-input">
             <p>Date</p>
             <input type="text" disabled value={stockAdjSingleView?.date}/>
          </div>
          <div className="global-single-input">
             <p>Remarks</p>
             <input type="text" disabled value={stockAdjSingleView?.remarks}/>
          </div>
          <div className="global-single-input">
             <p>GL Link Code</p>
             <input type="text" disabled value={stockAdjSingleView?.glLinkCode}/>
          </div>
          <div className="global-single-input">
             <p>Description</p>
             <input type="text" disabled value={stockAdjSingleView?.glLinkCodeDescription}/>
          </div>
          <div className="global-single-input">
             <p>GL Code</p>
             <input type="text" disabled value={stockAdjSingleView?.glCode}/>
          </div>
          <div className="global-single-input">
             <p>Description</p>
             <input type="text" disabled value={stockAdjSingleView?.glCodeDescription}/>
          </div>
          {/* <div className="global-single-input">
             <p>SL Code</p>
             <input type="text" disabled value={stockAdjSingleView?.glCodeDescription}/>
          </div>
          <div className="global-single-input">
             <p>Description</p>
             <input type="text" disabled/>
          </div> */}
          <div className="global-single-input">
             <p>CC Code</p>
             <input type="text" disabled value={stockAdjSingleView?.ccCode}/>
          </div>
          <div className="global-single-input">
             <p>Description</p>
             <input type="text" disabled value={stockAdjSingleView?.ccCodeDescription}/>
          </div>
        </div>
        <div className="stock-adj-report-view-table-container">
          <h4>Adjust Products</h4>
          <div className="global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Product Name</th>
                  <th>Size/Unit</th>
                  <th>Stock Qty</th>
                  <th>Adj Qty</th>
                  <th>Unit Cost</th>
                  <th>Total Cost</th>
                </tr>
              </thead>
                 <tbody>
                  {stockAdjSingleView?.purchaseInfo?.map((r,i)=>(
                       <tr key={i}>
                       <td>{i+1}</td>
                       <td>{r.itemName}</td>
                       <td>{r.dimension ==="no dimension"? r?.unit :r.dimension }</td>
                       <td>{r.dimensionstock?.toFixed(2)}</td>
                       <td>{r.adjQty}</td>
                       <td>{r.unitCost}</td>
                       <td>{r.totalCost}</td>
                      
                     </tr>
                  ))}
               
                 </tbody>
            </table>
          </div>
        </div>

        <div className="stock-adj-report-view-btn-container">
          <button onClick={()=>setStockAdjReportView(false)} className="btn btn-primary">Close</button>
        </div>
      </div>
    </Dialog>

     {/* stock adjustment report print */}
     <div 
       style={{display:'none'}}
     >
       <div ref={(el)=>(stockAdjustmentPrint = el)}>
          <div className='print-view-top-container'>
            <div className='print-view-top-left-container' style={{width:'30%'}}>
               <h6 style={{margin:"0 0 0 2%"}}>Printed On : {convertDateFormat(today)}&nbsp;{getCurrentTime()}</h6>
                <p>Trans ID&nbsp;:&nbsp;{stockAdjSingleView?.transNo}</p>
                
            </div>
            <div className='print-view-top-middle-container' style={{width:'40%'}}>
            {userRole === "admin" ? (
                <h3>{loginResponse?.data?.profile?.companyName}</h3>
              ) : userRole === "user" ? (
                <h3>{loginResponse?.storeName}</h3>
              ) : undefined}
                 <p>STOCK ADJUSTMENT REPORT</p>
            </div>
            <div className='print-view-top-right-container' style={{width:'30%'}}>
            {userRole ==="user" && <p>{loginResponse?.branchId}-{loginResponse?.branchName}</p>}
            </div>
          </div>
          <hr  className="print-report-hr"/>
          <div className='print-view-top-container' style={{padding: "0 6% 2% 6%"}}>
            <div className='print-view-top-left-container' style={{width:'40%'}}>
                <p>From&nbsp;:&nbsp;{stockAdjSingleView?.fromLoc}</p>
                <p>To&nbsp;:&nbsp;{stockAdjSingleView?.toLoc}</p>
            </div>
            <div className='print-view-top-middle-container' style={{width:'20%'}}>
             
            </div>
            <div className='print-view-top-right-container' style={{width:'40%'}}>
                <p>Transfer Date&nbsp;:&nbsp;{convertDateFormat(stockAdjSingleView?.date)}</p>
                <div className="print-report-input-container">
                  <p>Remarks</p>
                  <input type="text" name="" id="" value={stockAdjSingleView?.remarks}/>
                </div>
            </div>
          </div>
          <div className='print-view-table-container'>
          <table>
          <thead>
                <tr>
                  <th>No</th>
                  <th>Product Name</th>
                  <th>Size/Unit</th>
                  <th>Stock Qty</th>
                  <th>Adj Qty</th>
                  <th>Unit Cost</th>
                  <th>Total Cost</th>
                </tr>
              </thead>
                 <tbody>
                  {stockAdjSingleView?.purchaseInfo?.map((r,i)=>(
                       <tr key={i}>
                       <td>{i+1}</td>
                       <td>{r.itemName}</td>
                       <td>{r.dimension ==="no dimension"? "Nill":r.dimension }</td>
                       <td>{r.dimensionstock}</td>
                       <td>{r.adjQty}</td>
                       <td>{r.unitCost}</td>
                       <td>{r.totalCost}</td>
                      
                     </tr>
                  ))}
               
                 </tbody>
        </table>
        
          </div>
     
       </div>
     </div>
    </div>
  )
}
