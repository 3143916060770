import { createSlice } from "@reduxjs/toolkit";

export const cashInHandSlice = createSlice({
  name: "cashInHandSlice",
  initialState: {
    openingBalance:'',
  },
  reducers: {
    setOpeningBalance: (state, action) => {
      state.openingBalance = action.payload;
    },
  },
});

export const {setOpeningBalance} = cashInHandSlice.actions;
export default cashInHandSlice.reducer;
