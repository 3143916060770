import React, { useState } from 'react'
import { changePasswordAPICall } from '../../../API/Settings/AdminPassword/AdminPassword';
import SuccessSnackbar from '../../Single Components/SnackBars/SuccessSnackbar';
import ErrorSnackbar from '../../Single Components/SnackBars/ErrorSnackbar';

export const ChangePasswordSettings = () => {
   const [currentPassword, setCurrentPassword] = useState('');
   const [newPassword, setNewPassword] = useState('');
   const [confirmNewPassword, setConfirmNewPassword] = useState('');
   const [message, setMessage] = useState('');
   const [snackBarStates, setSnackBarStates] = useState({
      success: false,
      error: false,
      message: "",
      alert: false,
    });

    const closeSnackbar = () => {
      setSnackBarStates({
        ...snackBarStates,
        success: false,
        error: false,
        alert: false,
        message: "",
      });
    };
  
   const handleChange = (e) => {
      const { name, value } = e.target;
      if (name === 'currentPassword') {
        setCurrentPassword(value);
      } else if (name === 'newPassword') {
        setNewPassword(value);
      } else if (name === 'confirmNewPassword') {
        setConfirmNewPassword(value);
      }
    };

    const submitBtnFn=()=>{
      if (newPassword !== confirmNewPassword) {
         setMessage('New password and confirmation do not match.');
         return;
       }
       else{
         //api call
         changePasswordAPICall({currentPassword:currentPassword,password:confirmNewPassword},setSnackBarStates,snackBarStates)
       }
    }
  return (
     <div className="profile-info-container" style={{width:"65%",margin:"0"}}>
        <div className="profile-input-container">
           <p>Current Password</p>
           <input 
             type="password"
             name="currentPassword"
             value={currentPassword}
             onChange={handleChange}
             
             />
        </div>
        <div className="profile-input-container">
           <p>New Password</p>
           <input 
             type="password"
             name="newPassword"
             value={newPassword}
             onChange={handleChange}/>
        </div>
        <div className="profile-input-container">
           <p>Confirm New Password</p>
           <input type="password" 
             name="confirmNewPassword"
             value={confirmNewPassword}
             onChange={handleChange}
           />
        </div>
        {message && <p style={{margin:"0 0 0 auto",color:"red",fontSize:"0.8rem",width:"64%"}}>{message}</p>}
        <div style={{display:"flex",justifyContent:"flex-end"}}>
            <button
            style={{width:"30%"}}
            className="btn btn-primary profile-info-save-btn"
            onClick={submitBtnFn}
            >Set New Password</button>
        </div>


        <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
     </div>
  )
}
