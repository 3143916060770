import React, { useState, useRef } from "react";
import "../../../css/Customer/singleCustomer.css";
import {
  IconButton,
  Divider,
  Tooltip,
  Dialog,
  Select,
  Box,
  Rating,
  TextField,
  Autocomplete,
} from "@mui/material";
import { Datepicker } from "../../Single Components/Datepicker";
import image from "../../../Assets/Images/man1.jpg";
import DoubleArrowRoundedIcon from "@mui/icons-material/DoubleArrowRounded";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import {
  addNewCustomerRelationshipAPICall,
  deleteRelationAPICall,
  editRelationAPICall,
  generateCustomerIdAPICall,
  generateCustomerRelationIdAPICall,
  validateRelationExistsAPICall,
  viewAllCustomerRelationsAPICall,
  viewAllCustomersAPICall,
  viewOrderByCustomerId,
  viewingleCustomerAPICall,
} from "../../../API/Customer/customerAPI";
import { useSelector } from "react-redux";
import { extractObjectForSingleView } from "../../../Js/generalFunctions";
import SuccessSnackbar from "../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../Single Components/SnackBars/ErrorSnackbar";
import { today, currentTime, previousDate } from "../../../Js/Date";
import { getCurrentTime } from "../../../Js/Date";
import ReactToPrint from "react-to-print";
import { viewCompanyProfileInfoAPICall } from "../../../API/Settings/Company Settings/companySettingsAPI";
import { convertDateFormat } from "../../../Js/Date";
import { LoadingForm } from "../../Single Components/LoadingForm";
export const SingleCustomer = () => {
  let customerOrderListPrint = useRef();
  const storeCodeLs = localStorage.getItem("cusBranchId");
  //Customer Id from LS
  const custId = localStorage.getItem("custId");
  
  const userRole = useSelector((state) => state.userRoleSlice.value);
  //*Redux States
  //Generated Customer Id
  const customerId = useSelector((state) => state.customerRelationIdSlice.value);

  //single customer
  const SingleCustomer = useSelector((state) => state.customerRelationIdSlice.customerValue );
  console.log(SingleCustomer);
  //List of All Customers
  const customerList = useSelector((state) => state.allCustomerListSlice.value);
  //List of All Customer relationships
  const relationList = useSelector((state) => state.relationshipSlice.value);
  // single customer order list
  const singleCustomerOrderList = useSelector(
    (state) => state.singleCustomerOrderSlice.value
  );
  //Company Profile Data
  const companyProfileData = useSelector(
    (state) => state.companyProfileSlice.value
  );

  //Update listener states
  const [isModify, setIsModify] = useState(false);
  // get current time
  const [time, setTime] = useState();

  // Snackbar States
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false);
  const [openErrorSnack, setOpenErrorSnack] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops..");
  // determine is editing or not
  const [isEditing, setIsEditing] = useState(false);
  const [addRelationship, setAddRelationShip] = useState(false);

  //Relation Extensible response
  const [relationExtensible, setRelationExtensible] = useState([]);
  const [editingRelationId,setEditingRelationId]=useState(null)

  //Single Customer Obj
  const [singleCustomerData, setSingleCustomerData] = useState([]);

  //validation states
  const [relationNameAlert, setRelationNameAlert] = useState(false);
  const [relationMobAlert, setRelationMobAlert] = useState(false);

  
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  //Add Relationship form
  const addRelationshipFormInitialState = {
    customerId: "##",
    name: "",
    relationship: "",
    buildingName: "",
    streetName: "",
    landmark: "",
    mobileNo: "",
    email: "",
    reference: "",
  };
  const [addRelationshipForm, setAddRelationshipForm] = useState(
    addRelationshipFormInitialState
  );

  const [loadingForm, setLoadingForm] = useState(false);

  //Relation validate State
  const [relationStatusCode, setRelationStatusCode] = useState(null);
  const [isEnableInputs, setIsEnableInputs] = useState(false);
  // Date filter
  const [dateFilter, setDateFilter] = useState({
    fromDate: previousDate,
    toDate: today,
  });
  const [filteredData, setFilteredData] = useState([]);

  //update listener
  const updateListener = () => {
    setIsModify(!isModify);
  };

  // get current time fn
  useEffect(() => {
    setInterval(() => {
      setTime(`${new Date().getHours()}:${new Date().getMinutes()}`);
    }, 1000);
  }, [time]);

  //Clear add relationship form
  const clearAddRelationshipForm = () => {
    setAddRelationshipForm(addRelationshipFormInitialState);
    updateListener();
  };
  //Close Success snackbar
  const closeSuccessSnackbar = () => {
    setOpenSuccessSnack(false);
  };
  //Close Error snackbar
  const closeErrorSnackbar = () => {
    setOpenErrorSnack(false);
  };

  // console.log(addRelationshipForm);
  const customerBranchId =
    singleCustomerData !== undefined && singleCustomerData[0]?.branchId;

  //*onChange Fn
  const getAddRelationshipForm = (key) => (e) => {
    const { value } = e.target;
    if (key === "name") {
      setAddRelationshipForm({ ...addRelationshipForm, name: value });
      // if (addRelationshipForm?.mobileNo !== "") {
      // validateRelationExistsAPICall(
      //   { name: value, mobileNo: addRelationshipForm?.mobileNo },
      //   setRelationStatusCode
      // );
      setRelationNameAlert(false);
      if (value === "") {
        setRelationNameAlert(true);
      }

      // }
    }
    if (key === "relationship") {
      setAddRelationshipForm({ ...addRelationshipForm, relationship: value });
    }
    if (key === "buildingName") {
      setAddRelationshipForm({ ...addRelationshipForm, buildingName: value });
    }
    if (key === "streetName") {
      setAddRelationshipForm({ ...addRelationshipForm, streetName: value });
    }
    if (key === "landmark") {
      setAddRelationshipForm({ ...addRelationshipForm, landmark: value });
    }
    if (key === "mobileNo") {
      setAddRelationshipForm({ ...addRelationshipForm, mobileNo: value });
        
        validateRelationExistsAPICall(
          { name: addRelationshipForm?.name, mobileNo: value },
         setRelationStatusCode
        );
      setRelationMobAlert(false);
      if (value === "") {
        setRelationMobAlert(true);
      }
    }
    if (key === "email") {
      setAddRelationshipForm({ ...addRelationshipForm, email: value });
    }
    if (key === "reference") {
      setAddRelationshipForm({ ...addRelationshipForm, reference: value });
    }
    // switch (key) {
    //   case "name":
    //     setAddRelationshipForm({ ...addRelationshipForm, name: value });
    //     if (addRelationshipForm?.mobileNo !== "") {
    //       validateRelationExistsAPICall(
    //         { name: value, mobileNo: addRelationshipForm?.mobileNo },
    //         setRelationStatusCode
    //       );
    //     }
    //     break;
    //   case "relationship":
    //     setAddRelationshipForm({ ...addRelationshipForm, relationship: value });
    //     break;
    //   case "buildingName":
    //     setAddRelationshipForm({ ...addRelationshipForm, buildingName: value });
    //     break;
    //   case "streetName":
    //     setAddRelationshipForm({ ...addRelationshipForm, streetName: value });
    //     break;
    //   case "landmark":
    //     setAddRelationshipForm({ ...addRelationshipForm, landmark: value });
    //     break;
    //   case "mobileNo":
    //     setAddRelationshipForm({ ...addRelationshipForm, mobileNo: value });
    //     if (addRelationshipForm?.name !== "") {
    //       validateRelationExistsAPICall(
    //         { name: addRelationshipForm?.name, mobileNo: value },
    //         setRelationStatusCode
    //       );
    //     }
    //     break;
    //   case "email":
    //     setAddRelationshipForm({ ...addRelationshipForm, email: value });
    //     break;
    //   case "reference":
    //     setAddRelationshipForm({ ...addRelationshipForm, reference: value });
    //     break;

    //   default:
    //     break;
    // }
  };

  //*onClick
  //add new relationship body
  const addNewRelationshipBody = {
    refferedBy: custId,
    name: addRelationshipForm?.name,
    mobileNo: addRelationshipForm?.mobileNo,
    buildingName: addRelationshipForm?.buildingName,
    streetName: addRelationshipForm?.streetName,
    landMark: addRelationshipForm?.landmark,
    email: addRelationshipForm?.email,
    reference: addRelationshipForm?.reference,
    relType: addRelationshipForm?.relationship,
    branchId: userRole==='user' ?storeCodeLs:customerBranchId,
  };
  const addNewRelationship = () => {
    const { name, mobileNo } = addRelationshipForm;
    if (name === "") {
      setRelationNameAlert(true);
    } else if (mobileNo === "") {
      setRelationMobAlert(true);
    } else {
      addNewCustomerRelationshipAPICall(
        addNewRelationshipBody,
        setOpenSuccessSnack,
        setOpenErrorSnack,
        setSnackMsg,
        updateListener,
        clearAddRelationshipForm,setLoadingForm
      );
    }
  };

  //Create Relationship list extensible
  const makeRelationListExtensible = (relationRes) => {
    if (relationRes !== undefined) {
      let extensibleRel = relationRes.map((obj) => ({ ...obj }));

      setRelationExtensible(extensibleRel);
    } else {
      setRelationExtensible([]);
    }
  };
  //assign  values to add relation form in case of edit
  const assignValueToAddRelationForm = (relation) => {
    const {
      _id,
      name,
      mobileNo,
      buildingName,
      streetName,
      landMark,
      email,
      reference,
      refferedBy,
      relType,
      branchId,
      dataStatus,
      relationId,
      relatedto,
      ID,
    } = relation;

    setAddRelationshipForm({
      ...addRelationshipForm,
      customerId: ID,
      name,
      mobileNo,
      relationship: relType,
      buildingName,
      streetName,
      landmark: landMark,
      email,
      reference,
    });
  };

  //Edit Relation Icon Button Click
  const editRelation = (relation, index) => {
    console.log(index);
    console.log(relationExtensible);
    setIsEditing(true);
    setEditingRelationId(relation?._id)
    relationExtensible.splice(relationExtensible?.length - (index + 1), 1);
    setRelationExtensible([...relationExtensible]);
    //assign relation values to add state
    assignValueToAddRelationForm(relation);
    setIsEnableInputs(true);
  };

  //Delete Relation icon button click
  const deleteRelation = (relation) => {
    deleteRelationAPICall(
      { _id: relation?._id },
      setOpenSuccessSnack,
      setOpenErrorSnack,
      setSnackMsg,
      updateListener
    );
  };

  //Edit relationship body
  const editRelationshipBody = {
    _id: editingRelationId,
    name: addRelationshipForm.name,
    mobileNo: addRelationshipForm.mobileNo,
    buildingName: addRelationshipForm.buildingName,
    streetName: addRelationshipForm.streetName,
    landMark: addRelationshipForm.landmark,
    email: addRelationshipForm.email,
    reference: addRelationshipForm.reference,
  };

  //Final Update Relation
  const updateRelation = () => {
    //todo call API
    editRelationAPICall(
      editRelationshipBody,
      setOpenSuccessSnack,
      setOpenErrorSnack,
      setSnackMsg,
      updateListener,
      cancelEdit
    );
  };

  //cancel edit
  const cancelEdit = () => {
    setIsEditing(false);
    setAddRelationshipForm({
      ...addRelationshipFormInitialState,
      customerId: customerId?.prefix,
    });
    if (custId !== null) {
      viewAllCustomerRelationsAPICall({ _id: custId });
    }
  };

  // name auto select
  const handleInputChange=(e,newValue)=>{
        console.log(newValue);
        // if(typeof newValue === "string"){
          let filteredSuggestions=customerList?.filter((obj)=>
          obj.name.toLowerCase().includes(newValue.toLowerCase()));
            setSuggestions(filteredSuggestions);
      
            setShowSuggestions(newValue !=='');
         if(newValue===""){
            setSuggestions([])            
         } 
        // }
    
  }
  useEffect(()=>{
    if (customerId!==undefined && suggestions.length===0) { 
      
      setShowSuggestions(false)
      setAddRelationshipForm({ ...addRelationshipForm, mobileNo:'',
                                                      buildingName:'',
                                                      streetName:'',
                                                      landmark:'',
                                                      email:'' });
    }
  },[suggestions])
  // console.log(addRelationshipForm.customerId);

  const handleCusNameChange = (event, newValue) => {
    console.log(newValue);
    if(newValue!==null){
        setShowSuggestions(false)
        // setCusName(newValue.name);
        setAddRelationshipForm({...addRelationshipForm,name:newValue.name,
                                                      mobileNo:newValue.mobileNo,
                                                      buildingName:newValue.buildingName,
                                                      streetName:newValue.streetName,
                                                      landmark:newValue.landMark,
                                                      email:newValue.email,customerId:newValue.cusId        
        })
        setIsEnableInputs(true)
        // setAddRelationshipForm({...addRelationshipForm,customerId:newValue.cusId})
    }
    else{
        // setCusName(event.target.value)
        setAddRelationshipForm({...addRelationshipForm,name:event.target.value})
        setShowSuggestions(true)
    }
  };

  const handleTextFieldChange=(e)=>{
    setAddRelationshipForm({...addRelationshipForm,name:e.target.value})
    // setCusName(e.target.value)
    if(e.target.value==="" || suggestions.length===0){
        setShowSuggestions(false)
    }
  }

  //*useEffect
  //Initial Calls
  useEffect(() => {
    viewCompanyProfileInfoAPICall();
    generateCustomerRelationIdAPICall({branchId:userRole==='user'?storeCodeLs:customerBranchId});
    viewAllCustomersAPICall({ branchId: storeCodeLs });
    if (custId !== null) {
      viewAllCustomerRelationsAPICall({ _id: custId });
    }
  }, [isModify,customerBranchId]);
  //Get single customer Data using pre defined fn
  useEffect(() => {
    if (custId !== null) {
      let singleObj = extractObjectForSingleView(custId, customerList);
      setSingleCustomerData(singleObj);
    }
  }, [customerList]);
  //set Customer id into state
  useEffect(() => {
    if (customerId !== undefined) {
      console.log(customerId);
      setAddRelationshipForm({ ...addRelationshipForm, customerId: customerId.prefix });
    }
  }, [customerId]);
  const customerOrderListPayloadBody = {
    branchId: customerBranchId,
    cusId: custId,
  };
  // customer order list by customer id
  useEffect(() => {
    viewOrderByCustomerId(customerOrderListPayloadBody);
  }, [custId, customerBranchId]);

  useEffect(() => {
    if (singleCustomerOrderList !== undefined) {
      setFilteredData(singleCustomerOrderList.list);
    }
  }, [singleCustomerOrderList]);

  // date filter
  useEffect(() => {
    if (singleCustomerOrderList !== undefined) {
      let filteredDateArray = singleCustomerOrderList.list?.filter((obj) => {
        if (
          dateFilter.fromDate <= obj?.orderDate &&
          dateFilter.toDate >= obj?.orderDate
        ) {
          return obj;
        } else if (dateFilter.fromDate === "" && dateFilter.toDate === "") {
          return obj;
        }
        if (dateFilter.fromDate <= obj?.orderDate && dateFilter.toDate === "") {
          return obj;
        }
        if (dateFilter.fromDate === "" && dateFilter.toDate >= obj?.orderDate) {
          return obj;
        }
      });
      setFilteredData(filteredDateArray);
    }
  }, [dateFilter.fromDate, dateFilter.toDate]);
  // console.log(filteredData.list);
  useEffect(() => {
    if (relationStatusCode == 409) {
      if (
        window.confirm(
          "Mobile No already registered!! would you like to add new relation??"
        ) === true
      ) {
        setIsEnableInputs(true);
      } else {

        let filterByNum=customerList?.filter((obj)=>
          obj.mobileNo.includes(addRelationshipForm?.mobileNo)
        )
        console.log(filterByNum);
        if(filterByNum.length!==0){
          setAddRelationshipForm({...addRelationshipForm,name:filterByNum[0].name,
                                    customerId:filterByNum[0].cusId,buildingName:filterByNum[0].buildingName,
                                    streetName:filterByNum[0].streetName,landmark:filterByNum[0].landmark!==null && filterByNum[0].landmark,
                                    email:filterByNum[0].email
                                  })
        }
        setIsEnableInputs(false);
      }
    }
    if (relationStatusCode == 403) {
      setIsEnableInputs(false);
      alert("Customer already exist!!");
    }
    if (relationStatusCode == 404) {
      setIsEnableInputs(true);
    }
  }, [relationStatusCode]);

  //make Relation response extensible
  useEffect(() => {
    makeRelationListExtensible(relationList);
  }, [relationList]);
useEffect(()=>{
  viewingleCustomerAPICall({cusId:custId})
},[])
  // console.log(relationStatusCode);
  // useEffect(()=>{
  //   validateRelationExistsAPICall(
  //     { name: addRelationshipForm?.name, mobileNo: addRelationshipForm?.mobileNo },
  //     setRelationStatusCode
  //   );
  // },[addRelationshipForm?.name,addRelationshipForm?.mobileNo])
  return (
    <div className="global-page-parent-container">
      {/* <p className="breadcrumb">
        Customer &gt; All Customer &gt; customer name
      </p> */}

      <div className="single-customer-container">
        <div className="single-customer-left-container">
          <div className="single-customer-left-first-container">
            <div className="image-container">
              <img src={image} alt="customer Img" />
            </div>
            <span className="customer-id">{singleCustomerData[0]?.cusId}</span>
            <p className="customer-name">{singleCustomerData[0]?.name}</p>
            <p className="phone-number">{singleCustomerData[0]?.mobileNo}</p>
            <p className="customer-mail">
              {singleCustomerData[0]?.email || "####@###.##"}
            </p>
            <Divider className="divider" />
            {/* <p className="joining-date">Joining Date : 12-02-2022</p> */}
            <Tooltip title="Add Relationship">
              <button onClick={() => setAddRelationShip(true)}>
                Add Relationship{" "}
                <DoubleArrowRoundedIcon
                  sx={{ fontSize: "0.9rem", margin: "2px 0 0 8px" }}
                />
              </button>
            </Tooltip>
          </div>
          <div className="single-customer-left-second-container">
            <div className="single-ustomer-new-points-container">
               <div className="single-customer-couts-container">
                <h5>Total Orders</h5>
                <p>{SingleCustomer?.totalOrderAmount}</p>
               </div>
               <div className="single-customer-couts-container">
                <h5>Total Credit</h5>
                <p>{SingleCustomer?.totalCredit}</p>
               </div>
               <div className="single-customer-couts-container">
                <h5>Total Alteration</h5>
                <p>{SingleCustomer?.totalAlterationAmount}</p>
               </div>
               <div className="single-customer-couts-container">
                <h5>Total Billing</h5>
                <p>{SingleCustomer?.totalBillingAmount}</p>
               </div>
               <div className="single-customer-couts-container">
                <h5>Total Pending Delivery</h5>
                <p>{SingleCustomer?.totalPendingDelCnt}</p>
               </div>
               <div className="single-customer-couts-container">
                <h5>Total Return</h5>
                <p>{SingleCustomer?.totalReturn}</p>
               </div>
            </div>
            <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
              <Box className="customer-rating-star-icon">
                <Rating   value={SingleCustomer?.totalRewardPoint} />
              </Box>
             <p className="reward-points">Total Reward Points</p>
            </div>
            <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
            <Box className="total-credit-star-icon">
                <Rating value={SingleCustomer?.totalCreditRating}/>
              </Box>
             <p className="credit-points-customer">Total Credit</p>
            </div>
            <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
            <Box className="total-order-star-icon">
                <Rating value={SingleCustomer?.totalOrderRating}/>
              </Box>
             <p className="order-points-customer">Total Orders</p>
            </div>
         
            <p className="point">{singleCustomerData[0]?.points}</p>
          </div>
        </div>
        <div className="single-customer-right-container">
          <div className="single-customer-right-top-container">
            <h4>Order History</h4>
            <div className="single-customer-right-top-right-container">
              <ReactToPrint
                trigger={() => (
                  <IconButton className="print-icon">
                    <i class="bi bi-printer"></i>
                  </IconButton>
                )}
                content={() => customerOrderListPrint}
              />

              <IconButton className="download-icon">
                <i class="bi bi-arrow-down-circle"></i>
              </IconButton>
              <Datepicker
                type="date"
                backgroundColor="#f1f2ff"
                //  value={dateFilter.fromDate}
                defaultValue={dateFilter.fromDate}
                onChange={(e) =>
                  setDateFilter({
                    ...dateFilter,
                    fromDate: e.target.value,
                  })
                }
              />
              <Datepicker
                type="date"
                backgroundColor="#f1f2ff"
                // value={dateFilter.toDate}
                defaultValue={dateFilter.toDate}
                onChange={(e) =>
                  setDateFilter({
                    ...dateFilter,
                    toDate: e.target.value,
                  })
                }
              />
            </div>
          </div>
          {/* table */}
          {(singleCustomerOrderList !== undefined && singleCustomerOrderList?.list.length!==0) ? (
            <div
              className="global-table-container"
              style={{ padding: "0", margin: "2% 0" }}
            >
              <table className="global-table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Order ID</th>
                    <th>Date</th>
                    <th>Location</th>
                    <th>Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>
                {/* <tbody>
                {
                  singleCustomerOrderList?.map((r,i)=>(
                    <tr key={i}>
                       <td>{i+1}</td>
                       <td>{r.orderId}</td>
                       <td>{r.orderDate}</td>
                       <td>{r.locationName}</td>
                       <td>{r.totalAmount}</td>
                       <td>
                        <p
                          className={
                            r.status==='pending'?
                            "customer-single-status-pending"
                            : r.status==='complete'?
                            "customer-single-status-completed"
                            :r.status==='cancel'?
                            "customer-single-status-cancelled"
                            :undefined
                           }
                        >
                          {r.status}
                        </p>
                       </td>
                    </tr>
                  ))
                }
               
              </tbody> */}
                <tbody>
                  {filteredData?.map((r, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{r.orderId}</td>
                      <td>{convertDateFormat(r.date)}</td>
                      <td>{r.location}</td>
                      <td>{r.Amount}</td>
                      <td>
                        <p
                          className={
                            r.status === "pending"
                              ? "customer-single-status status-pending"
                              : r.status === "completed"
                              ? "customer-single-status status-completed"
                              : r.status === "deliveryReturned" ||
                                r.status === "orderReturned"
                              ? "customer-single-status status-returned"
                              : r.status === "cancel"
                              ? "customer-single-status status-cancelled"
                              : r.status === "printing"
                              ? "customer-single-status status-printing"
                              : r.status === "delivered"
                              ? "customer-single-status status-delivered"
                              : r.status === "delivery"
                              ? "customer-single-status status-delivery"
                              : undefined
                          }
                        >
                          {r.status === "pending"
                            ? "Pending"
                            : r.status === "completed"
                            ? "Finished"
                            : r.status === "deliveryReturned"
                            ? "Delivery Return"
                            : r.status === "orderReturned"
                            ? "O.Return"
                            : r.status === "cancel"
                            ? "Cancelled"
                            : r.status === "printing"
                            ? "Printing"
                            : r.status === "delivered"
                            ? "Delivered"
                            : r.status === "delivery"
                            ? "Delivery"
                            : r.status}
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <h3 className="no-data" style={{ marginTop: "18px" }}>
              No Records
            </h3>
          )}
        </div>
      </div>

      {/* add relationship */}
      <Dialog
        open={addRelationship}
        maxWidth="lg"
        onKeyDown={(e) => e.key === "Escape" && setAddRelationShip(false)}
      >
        <div className="add-relationship-dialog">
          <div className="add-relationship-dialog-top-container">
            <div className="add-relationship-dialog-top-heading-container">
              <h3>Relationships</h3>
              <div
                onClick={() => setAddRelationShip(false)}
                className="add-relationship-dialog-top-heading-close-icon-container"
              >
                <CloseIcon sx={{ color: "red" }} />
              </div>
            </div>
            <hr className="global-hr" />
            <div className="add-relationship-dialog-top-heading-container">
              <div className="add-relationship-dialog-top-left-container">
                <div className="add-relation-name-content-single-container">
                  <h4 className="name">Name</h4>
                  <h4>:&nbsp;{singleCustomerData[0]?.name}</h4>
                </div>
                <div className="add-relation-name-content-single-container">
                  <p className="name">Contact</p>
                  <p>:&nbsp;{singleCustomerData[0]?.mobileNo}</p>
                </div>
              </div>
              <div className="add-relationship-dialog-top-right-container">
                <div className="add-relation-name-content-single-container">
                  <p className="name">Email</p>
                  <p>:&nbsp;{singleCustomerData[0]?.email || "-NIL-"}</p>
                </div>
                {/* <div className="add-relation-name-content-single-container">
                  <p className="name">Joining Date</p>
                  <p>:&nbsp;12-02-2022</p>
                </div> */}
              </div>
            </div>
          </div>

          <div className="add-relationship-dialog-top-container">
            <h3>Add Relationship</h3>
            <div className="add-relationship-dialog-input-container">
              <div className="global-single-input">
                <p>Relation ID</p>
                <input
                  type="text"
                  style={{ backgroundColor: "#fff" }}
                  value={addRelationshipForm?.customerId}
                  disabled
                />
              </div>
              <div className="global-single-input auto-complete auto-complete-name">
                <p>Name</p>
                <Autocomplete
                  id="customer-name"
                  options={suggestions}
                  getOptionLabel={(option) => option.name}
                  inputValue={addRelationshipForm?.name}
                  onInputChange={handleInputChange}
                  open={showSuggestions}
                  onChange={handleCusNameChange}
                  getOptionSelected={(option, value) => option.name === value.name}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      value={addRelationshipForm?.name}
                      onChange={handleTextFieldChange}
                      placeholder="Enter Name ..."
                  />
                  )}
                  clearOnBlur={false}
                  clearOnEscape={false}
                  disableClearable
                  disableOpenOnFocus
                />
                {/* <input
                  type="text"
                  style={{ backgroundColor: "#fff" }}
                  placeholder="Enter name..."
                  value={addRelationshipForm?.name}
                  onChange={getAddRelationshipForm("name")}
                /> */}
                {relationNameAlert ? (
                  <p className="doc-validation-alert">Fill this field !!</p>
                ) : (
                  ""
                )}
              </div>
              <div className="global-single-input">
                <p>Mobile No</p>
                <input
                  type="text"
                  style={{ backgroundColor: "#fff" }}
                  placeholder="Enter mobile no..."
                  value={addRelationshipForm?.mobileNo}
                  onChange={getAddRelationshipForm("mobileNo")}
                  maxLength="15"
                />
                {relationMobAlert ? (
                  <p className="doc-validation-alert">Fill this field !!</p>
                ) : (
                  ""
                )}
              </div>
              <div className="global-single-input">
                <p>Relationship With Customer</p>
                <input
                  type="text"
                  disabled={!isEnableInputs}
                  style={{ backgroundColor: "#fff" }}
                  placeholder="Enter relation..."
                  value={addRelationshipForm?.relationship}
                  onChange={getAddRelationshipForm("relationship")}
                />
              </div>
              <div className="global-single-input">
                <p>Building Name</p>
                <input
                  type="text"
                  disabled={!isEnableInputs}
                  style={{ backgroundColor: "#fff" }}
                  placeholder="Enter building name..."
                  value={addRelationshipForm?.buildingName}
                  onChange={getAddRelationshipForm("buildingName")}
                />
              </div>
              <div className="global-single-input">
                <p>Street Name</p>
                <input
                  type="text"
                  disabled={!isEnableInputs}
                  style={{ backgroundColor: "#fff" }}
                  placeholder="Enter street name..."
                  value={addRelationshipForm?.streetName}
                  onChange={getAddRelationshipForm("streetName")}
                />
              </div>
              <div className="global-single-input">
                <p>Landmark</p>
                <input
                  type="text"
                  disabled={!isEnableInputs}
                  style={{ backgroundColor: "#fff" }}
                  placeholder="Enter landmark..."
                  value={addRelationshipForm?.landmark}
                  onChange={getAddRelationshipForm("landmark")}
                />
              </div>

              <div className="global-single-input">
                <p>Email</p>
                <input
                  type="text"
                  disabled={!isEnableInputs}
                  style={{ backgroundColor: "#fff" }}
                  placeholder="Enter email..."
                  value={addRelationshipForm?.email}
                  onChange={getAddRelationshipForm("email")}
                />
              </div>
              <div className="global-single-input">
                <p>Reference</p>
                <input
                  type="text"
                  disabled={!isEnableInputs}
                  style={{ backgroundColor: "#fff" }}
                  placeholder="Enter reference..."
                  value={addRelationshipForm?.reference}
                  onChange={getAddRelationshipForm("reference")}
                  onKeyDown={(e) => e.key === "Enter" && addNewRelationship()}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                className="btn btn-secondary-outlined"
                onClick={isEditing ? cancelEdit : clearAddRelationshipForm}
              >
                {isEditing ? "Cancel" : "Clear"}
              </button>
              <button className="btn btn-primary" onClick={isEditing?updateRelation:addNewRelationship}>
                {isEditing ? "Update" : "Submit"}
              </button>
            </div>
          </div>
          {/* table */}
          <div
            className="global-table-container"
            style={{ border: "1px solid rgb(209 206 206)", margin: "2% 0" }}
          >
            <table className="global-table">
              <thead>
                <tr>
                  <th>Relation ID</th>
                  <th>Name</th>
                  <th>Relationship</th>
                  <th>Street Name</th>
                  <th>Contact</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {relationExtensible
                  ?.slice(0)
                  ?.reverse(0)
                  ?.map((r, i) => (
                    <tr key={i}>
                      <td>{r?.ID}</td>
                      <td>{r?.name}</td>
                      <td>{r?.relType}</td>
                      <td>{r?.streetName}</td>
                      <td>{r?.mobileNo}</td>
                      <td>{r?.email}</td>
                      <td>
                        <IconButton
                          onClick={() => editRelation(r, i)}
                          disabled={isEditing}
                        >
                          <i class="bi bi-pencil-square edit-icon"></i>
                        </IconButton>
                        <IconButton onClick={() => deleteRelation(r)}>
                          <i class="bi bi-trash3 delete-icon"></i>
                        </IconButton>
                      </td>
                    </tr>
                  )) || (
                  <tr>
                    <td colSpan={7}>No data</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Dialog>

      <SuccessSnackbar
        open={openSuccessSnack}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={openErrorSnack}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />

      <div style={{ display: "none" }}>
        <div ref={(el) => (customerOrderListPrint = el)}>
          <div className="print-view-top-container">
            <div className="print-view-top-left-container"></div>
            <div className="print-view-top-middle-container">
              <h3>{companyProfileData?.profile?.companyName}</h3>
              <p>CUSTOMER ORDER LIST</p>
            </div>
            <div className="print-view-top-right-container">
              <p>{today}</p>
              <p>{time}</p>
            </div>
          </div>
          <div className="print-view-table-container">
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>OrderID</th>
                  <th>Date</th>
                  <th>Location</th>
                  <th>Amount</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((r, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{r.orderId}</td>
                    <td>{convertDateFormat(r.date)}</td>
                    <td>{r.location}</td>
                    <td>{r.Amount}</td>
                    <td>{r.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <LoadingForm loading={loadingForm} />

    </div>
  );
};
