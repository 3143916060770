import React, { useEffect, useState } from 'react'
import { filterObjFromList } from '../../../../../Js/generalFunctions';
import { companyProfileInfoForUser } from '../../../../../API/Settings/Company Settings/companySettingsAPI';
import { viewAllCurrencyUpdated } from '../../../../../API/Settings/Currency/CurrencyAPI';
import { useSelector } from 'react-redux';

const BankAndCashSinglePrint = (props) => {
    const { data, formValues } = props
    const [symbol,setSymbol] =useState(null)
    const decimalPosition = localStorage.getItem("decimalPosition");
    
    const currencyListUpdated = useSelector(
        (state) => state?.viewUpdatedCurrency?.value
      );

      const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue
      );
      useEffect(() => {
        let currencyObj = filterObjFromList(
          "_id",
          currencyListUpdated,
          "currency",
          companyProfileData
        );
        console.log(currencyObj);
        setSymbol(currencyObj?.symbol);
      }, [ currencyListUpdated]);

      useEffect(() => {
        viewAllCurrencyUpdated({ status: 0 });
        companyProfileInfoForUser();
      }, []);
      console.log(formValues
      );
    return (
        <div className="print-main-div">
            <div className='header-div'>
                <div>
                    <img src={formValues?.companyLogo} alt=""  style={{height:"62px"}}/>
                </div>
                <div>
                    <h1 style={{ fontSize: "larger", margin: "0" }}>{formValues?.label}</h1>
                    {/* <p style={{margin:"0"}}>aaaaaaa</p> */}
                </div>
                <div>
                    <h1 style={{ fontSize: "small", margin: "0" }}>companyName</h1>
                    <p style={{ margin: "0", fontSize: "smaller" }}>{formValues?.companyName}</p>
                    <p style={{ margin: "0", fontSize: "smaller" }}>{formValues?.companyAddress}</p>
                    <p style={{ margin: "0", fontSize: "smaller" }}>{formValues?.companyContact}</p>
                </div>
            </div>

            <div className="global-report-table-container" style={{ overflow: "visible" }} >
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Journal</th>
                            <th>Referance</th>
                            <th>Debit</th>
                            <th>Credit</th>
                            <th>Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((item, index) => (
                            <tr key={index} >
                                <td style={{ textAlignLast: "start", paddingLeft: "10px" }}>{item.date}</td>
                                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.journalNo}</td>
                                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.reference}</td>
                                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.debit?.toFixed(decimalPosition)}</td>
                                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.credit?.toFixed(decimalPosition)}</td>
                                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.balance.toFixed(decimalPosition)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className='div-table-total'>
            
            <p style={{width:"62%",textAlign:"end"}}>Total</p>
            <p>{symbol} {formValues?.totalDebit?.toFixed(decimalPosition)}</p>
            <p>{symbol} {formValues?.totalCredit?.toFixed(decimalPosition)}</p>
            <p>{symbol} {formValues?.totalBalance?.toFixed(decimalPosition)}</p>
         
        </div>
            </div>
        </div>
  )
}

export default BankAndCashSinglePrint