import { Autocomplete, IconButton, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { addUnitOfMeasurmentAPICall, editUnitOfMeasurmentAPICall, viewUOMByIdAPICall } from './unitOfMeasurmentAPICall';
import { useSelector } from 'react-redux';
import { filterObjFromList } from '../../../../../Js/generalFunctions';
import { LoadingForm } from '../../../../Single Components/LoadingForm';
import SuccessSnackbar from '../../../../Single Components/SnackBars/SuccessSnackbar';
import ErrorSnackbar from '../../../../Single Components/SnackBars/ErrorSnackbar';

export const NewUOM = () => {
  const singleRowId = localStorage.getItem("singleViewById");
  let navigateTo = useNavigate();
  const containerRef = useRef(null);
  const types=[
    {name:'Bigger than the reference Unit of Measure',backendName:"bigger"},
    {name:'Reference Unit of Measure for this category',backendName:"reference"},
    {name:'Smaller than the reference Unit of Measure',backendName:"smaller"}
  ]
  const editedData = useSelector(
    (state) => state.unitOfMeasurmentSlice?.editClickData
  );
  // table initial values
  const tableDataInitial = {
       uom:"",
       type:null,
       ratio:"",
       status:true,
       id: Date.now(),
  };
  const [tableData, setTableData] = useState([tableDataInitial]);
  const [addRowScroll, setAddRowScroll] = useState(false);
  const [uomCategory,setUOMCategory]=useState("")
  const [loadingForm, setLoadingForm] = useState(false);
  const [editClicked, setEditClicked] = useState(false);
  const [validationAlert, setValidationAlert] = useState({});
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
  });
  const [visibileOnly,setVisibleOnly]=useState(false)

  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      message: "",
    });
  };
  const addRow=()=>{
    setTableData([
      ...tableData,
      {
        id: Date.now(),
        uom:"",
        type:null,
        ratio:"",
       status:true
      },
    ]);
    setAddRowScroll(!addRowScroll);
  }
  const handleDelete = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };

  const getFormInfo = (id, key) => (e, newValue) => {
    const { value } = e.target;
    const updatedData = tableData.map((row, index) => {
        if (index === id) {
            if (key === "status") {
                return { ...row, [key]: e.target.checked };
            }
            if (key === "type") {
              if(newValue?.name==="Reference Unit of Measure for this category"){
                return {
                  ...row,
                  [key]: newValue,
                  ratio: 1
              };
              }
              else{
               
                return {
                  ...row,
                  [key]: newValue,
                  ratio: editedData !== undefined && editedData?.uom[id] ? editedData?.uom[id].ratio : value
              };
              }
            }
         
            return { ...row, [key]: (value || "")||(newValue || null) };
        }
        return row;
    });
    setTableData(updatedData);
};

 

  

 
  const tableDataToBackend = tableData?.map((item)=>{
      return {
        uomName:item?.uom,
        type:item?.type?.backendName,
        ratio:Number(item?.ratio),
        isActive:item?.status,
        _id:item?._id
      }
  })
 

  const saveFunction=()=>{
    const specificTypeItems = tableDataToBackend.filter(item => item.type === "reference");
    const hasEmptyValues = tableDataToBackend.some(item => {
      return item.uomName === "" || item.type === null || item.ratio === "";
  });
    if (specificTypeItems.length === 1 && !hasEmptyValues) {
      addUnitOfMeasurmentAPICall({
            uomCat:uomCategory,
            uom:tableDataToBackend
          },setSnackBarStates,snackBarStates,setLoadingForm,clearFunction)
    } 
    else {
        if (uomCategory==="") {
          setValidationAlert({...validationAlert,uomName:"Required"})
        }
        else if (specificTypeItems.length === 0) {
            alert("Please select the Reference Unit of Measure for this category");
        } else if(specificTypeItems.length > 1) {
            alert("Only one 'Reference Unit of Measure for this category' allowed");
        }
     
        else {
          alert("Please make sure all values (uom, type, ratio) are present for each item.");
      }
    }
   
  }

  const clearFunction=()=>{
    setTableData([tableDataInitial])
    setUOMCategory("")
  }
  const editSubmitFunction=()=>{
     const specificTypeItems = tableDataToBackend.filter(item => item.type === "reference");
     if (specificTypeItems.length === 1) {
      editUnitOfMeasurmentAPICall(
      {
        id: editedData?._id,
        uomCat:uomCategory,
        uom:tableDataToBackend,
      },
      setSnackBarStates,snackBarStates,setLoadingForm,setVisibleOnly
    );
  } else {
      if (specificTypeItems.length === 0) {
          alert("Please select the Reference Unit of Measure for this category");
      } else {
          alert("Only one 'Reference Unit of Measure for this category' allowed");
      }
  }

  }
  const edienableFn=()=>{
    setVisibleOnly(false)
  }

    // for adding new row in table
    useEffect(() => {
      if (containerRef.current) {
        containerRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, [addRowScroll]);

    useEffect(()=>{
      if(singleRowId){
        viewUOMByIdAPICall({_id:singleRowId})
      }
    },[])

    useEffect(()=>{
       if(editedData !== undefined){
        setUOMCategory(editedData.uomCat)
        setVisibleOnly(true)
        setEditClicked(true)
        const updatedArray=editedData?.uom?.map((r,i)=>{
           let type=filterObjFromList("backendName",types,"type",r)
          
           return{
            uom:r?.uomName,
            type:type,
            ratio:r?.ratio,
            status:r?.isActive,
            _id:r?._id
            
           }
        })
        setTableData(updatedArray)
       }
       else{
        setEditClicked(false)
       }
    },[editedData])

   

    

  return (
    <div className="global-page-parent-container">
      <div className="new-global-white-bg-container" style={{ padding: "0 2%" }}>
        <div className="new-global-white-bg-icon-container">
            <div className="new-global-white-bg-icon-container-left">
              <Tooltip title="Back">
                <IconButton onClick={() => navigateTo("/userdashboard/purchaseManager/unitOfMeasurment")}>
                  <i class="bi bi-chevron-left"></i>
                </IconButton>
              </Tooltip>
             {
              editClicked === true ?(
                 <Tooltip title="Edit">
                  <IconButton
                    onClick={ visibileOnly===true ? edienableFn:editSubmitFunction}
                  >
                    <i
                      class={
                        visibileOnly===true
                          ? "bi bi-pencil-square edit-icon1"
                          : 
                          "bi bi-check2-square edit-icon"
                      }
                    ></i>
                  </IconButton>
                </Tooltip>
            
              ):(
                <Tooltip title="Save">
                <IconButton
                   onClick={saveFunction}
                >
                  <i class="bi bi-floppy save-icon"></i>
                </IconButton>
              </Tooltip>
              )
             }
                

          
               
            
           

           
            </div>
        </div>
        <hr className="global-hr" />
        <div className="new-global-input-container">
           <div className="new-global-single-input">
              <TextField
             
                id="outlined-basic"
                label="UOM Category"
                variant="outlined"
                type="text"
                focused
                value={uomCategory}
                onChange={(e)=>setUOMCategory(e.target.value)}
                disabled={visibileOnly===true}
              />
                <p className="doc-validation-alert">{validationAlert?.uomName}</p>
            
            </div>
        </div>
        <div className="new-global-white-bg-container">
           <div className="global-product-table">
             <table>
                <thead>
                   <tr>
                     <th>UOM</th>
                     <th>Type</th>
                     <th>Ratio</th>
                     <th>Status</th>
                   </tr>
                </thead>
                <tbody>
                  {
                    tableData?.map((r,index)=>(
                      <tr
                     
                      key={index}
                      ref={
                        index === tableData?.length - 1
                          ? containerRef
                          : null
                      }
                      >
                      <td style={{ maxWidth: "180px" }}>
                         <input 
                           type="text"
                           value={r.uom}
                           onChange={getFormInfo(index,"uom")}
                           disabled={visibileOnly===true}
                           />
                       </td>
                       <td style={{ minWidth: "180px" }}>
                           <div className="product-table-auto-complete">
                               <Autocomplete
                                     options={types||[]}
                                     style={{ maxHeight: "100px" }}
                                     getOptionLabel={(option)=>option?.name}
                                     renderInput={(params) => (
                                       <TextField {...params} />
                                     )}
                                    //  renderOption={(props, option) => (
                                    //    <li {...props} key={option.name}>
                                    //      <span>{option?.name}</span>
                                    //    </li>
                                    //  )}
                                    value={r?.type}
                                    onChange={getFormInfo(index,"type")}
                                    disabled={visibileOnly===true}
                                   />
                                 </div>
                       </td>
                       <td style={{ maxWidth: "180px" }}>
                         <input
                           type="text"
                           value={r?.ratio}
                           onChange={getFormInfo(index,"ratio")}
                           disabled={visibileOnly===true||r?.type?.name==="Reference Unit of Measure for this category"}

                           />
                       </td>
                       <td>
                       <input
                        style={{height:"20px",width:"40px"}}
                           className="toggle-checkbox"
                           type="checkbox"
                           checked={r?.status}
                           onChange={getFormInfo(index,"status")}
                           disabled={visibileOnly===true}
                         />
                       </td>
                         <td style={{ border: "none", width: "4%" }}>
                                <IconButton
                                  onClick={() => handleDelete(index)}
                                  style={{ height: "30px" }}
                                 
                                >
                                  <i
                                    className="bi bi-trash3"
                                    style={{
                                      color: "#db0000",
                                      fontSize: "1.3rem",
                                    }}
                                  ></i>
                                </IconButton>
                              </td>
                   </tr>
                    ))
                  }
               
                </tbody>
             </table>
           </div>
           <div className="add-row-btn-container">
             <button
                    onClick={() => addRow()}
                     style={{border:"none",backgroundColor:"#fff",color:"rgb(119 9 9)",fontSize:"0.9rem",fontWeight:600,cursor:"pointer"}}
                  >
                    Add Line
                  </button>
           </div>
        </div>
      </div>
      <LoadingForm loading={loadingForm} />
      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <SuccessSnackbar
         open={snackBarStates.success}
         handleClose={closeSnackbar}
         message={snackBarStates.message}
      />
    </div>
  )
}
