import {createSlice} from '@reduxjs/toolkit'

export const damagedGoodsSingleViewSlice=createSlice({
    name:'damagedGoodsSingle',
    initialState:{
        value:undefined
    },
    reducers:{
        get_damaged_goods_single:(state,action)=>{
            state.value=action.payload.damagedGoodsSingleViewData
        }
    }
})
export const {get_damaged_goods_single} = damagedGoodsSingleViewSlice.actions;
export default damagedGoodsSingleViewSlice.reducer