import { createSlice } from "@reduxjs/toolkit";

export const employeeGenderCountSlice = createSlice({
  name: "employeeGenderCountSlice",
  initialState: {
    value: undefined,
  },
  reducers: {
    update_employee_genderCount: (state, action) => {
      state.value = action.payload.employeeGenderCount;
    },
  },
});

export const { update_employee_genderCount} = employeeGenderCountSlice.actions;

export default employeeGenderCountSlice.reducer;
