import { createSlice } from "@reduxjs/toolkit";

export const allReadymadeSubCategorySlice=createSlice({
    name:'allReadymadeSubCategorySlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_readymadeSubCategory_list:(state,action)=>{
            state.value=action.payload.readymadeSubCategory
        }
    }
})

export const {update_readymadeSubCategory_list}=allReadymadeSubCategorySlice.actions
export default allReadymadeSubCategorySlice.reducer