import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CategoryFilterAPICall, categoryListAPICall, deleteApiCall } from "./CategoryAPI";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import store from "../../../../../Redux/store";
import { singleViewCategory } from "./categorySlice";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import CustomPagination from "../../../../Single Components/CustomPagination";
import axios from "axios";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";

export const Category = () => {
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const CategoryList = useSelector((state) => state.categorySlice?.tableList);
  let navigate = useNavigate();
  const createCategory = () => {
    navigate("/userdashboard/inventory/configuration/category/create");
  };
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [IsModify, setIsModify] = useState(false);
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = CategoryList?.pages;
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };


  const updateListner = () => {
    setIsModify(!IsModify)
  }
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };

  //DeleteSingleCategory
  const DeleteSingleCategory = (id) => {
    deleteApiCall(id, updateListner)
  }

  //singleViewCateGry
  const singleViewCateGry = (r) => {
    store.dispatch( setFilterActive(window.location.pathname))
    localStorage.setItem("singleViewById", r._id)
    navigate("/userdashboard/inventory/configuration/category/create");
    // store.dispatch(singleViewCategory({singleViewCategoryList:r}));
  }
  useEffect(() => {
   
    if (FormDataInfo?.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData &&  CategoryFilterAPICall({
        search: FormDataInfo.search,
        index: currentPage-1,
      }, setIsLoading);
    }
    

    store.dispatch(singleViewCategory({ singleViewCategoryList: undefined }));
    localStorage.removeItem("singleViewById")
  }, [FormDataInfo, currentPage,IsModify]);

  return (
    <div className="global-page-parent-container">
      {/* <p className="breadcrumb">Inventory &gt; Configuration &gt; Category</p> */}
      {/* table */}
      <div className="global-white-bg-container">
        <div className="justify-space-between"
          style={{ gap: "0 12px", justifyContent: "flex-end" }}
        >
          <CategorySearchandFilter
            onData={handleDataFromChild}
            isBranch={false}
            isFilter={false}
            onBranchSelect={handleBranchSelect}
          // isDate={false}
          />
          <button onClick={createCategory} className="create-button-blue">
            Create
          </button>
        </div>
        <div className="justify-center" style={{ justifyContent: "flex-end" }}>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />
        </div>
        <div className="create-button-blue-container">
          {/* <button onClick={createCategory} className="create-button-blue">
            Create
          </button> */}
        </div>
        <div className="global-table-container">
          <table className="global-table">
            <thead>
              <tr>
                <th>Category Name</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {CategoryList?.list?.length > 0 ? (
                CategoryList?.list?.slice(0).map((r, i) => (
                  <tr>
                    <td onClick={() => singleViewCateGry(r)}>{r?.categoryName}</td>
                    <td>
                      <IconButton onClick={() => DeleteSingleCategory(r?._id)}>
                        <i class="bi bi-trash3 delete-icon"></i>
                      </IconButton>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>No Data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
