import { Autocomplete, IconButton, TextField, Tooltip } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { addSequenceAPICall, editSequenceAPICall, viewAllSequenceListAPICall } from './SequenceAPI'
import SuccessSnackbar from '../../../Single Components/SnackBars/SuccessSnackbar'
import ErrorSnackbar from '../../../Single Components/SnackBars/ErrorSnackbar'

export const Sequence = () => {
    const sequenceList=useSelector((state)=>state.sequenceSlice.value) 
    const [createSequence,setCreateSequence]=useState(false)
    const sequenceName=[
      {name:"Purchase order"},
      {name:"Sales order"},
      {name:"Good Received Note"},
      {name:"Delivery note"},
      {name:"Debit note"},
      {name:"Credit note"},
      {name:"Payment"},
      {name:"Recceipt"},
      {name:"Payroll"},
      {name:"Recurring Posting"},
      {name:"Internal Transfer"},
      {name:"Branch Transfer"},
      {name:"Stock Move"},
      {name:"Inventory Adjustment"},
      {name:"Request for Quotation"},
      {name:"Quotation"},
      {name:"Purchase Wpo"},
      {name:"Sales Wpo"},
      {name:"Vendor Bill"},
      {name:"Vendor Payment"},
      {name:"Customer Invoice"},
      {name:"Customer Payment"},
      {name:"Landed Cost"},
      {name:"Employee"},
    ]
    const sequenceFormInitState={
        sequenceName:null,
        prefix:"",
        sequenceSize:"",
        nextNumber:""
   }
   const [sequenceForm,setSequenceForm]=useState(sequenceFormInitState)
   const [isModify,setIsModify]=useState(false)
   const [successSnackOpen, setSuccessSnackOpen] = useState(false);
   const [errorSnackOpen, setErrorSnackOpen] = useState(false);
   const [snackMsg, setSnackMsg] = useState("Error!!");
   const [editedId,setEditedId]=useState()
   const [isDisabled,setIsDisabled]=useState(false)
   const [fullDisable,setFullDisable]=useState(false)
   const [isEdit,setIsEdit]=useState(false)
   const updateListner=()=>{
    setIsModify(!isModify)
   }

   useEffect(()=>{
    viewAllSequenceListAPICall()
   },[isModify])

    const craeteSequenceBtnFn=()=>{
        setSequenceForm(sequenceFormInitState)
        setIsDisabled(true)
        setFullDisable(true)
        setCreateSequence(true)
        setIsEdit(false)
      
    }
    const backButtonFn=()=>{
      setCreateSequence(false)
    }

     // snackbar function
    const closeSuccessSnackbar=()=>{
        setSuccessSnackOpen(false)
   }
  
   const closeErrorSnackbar=()=>{
        setErrorSnackOpen(false)
     }
  

  const getSequenceForm=(key)=>(e,newValue)=>{
     if(key==="sequencename"){
      setSequenceForm({...sequenceForm,sequenceName:newValue})
     }
     if(key==="prefix"){
      setSequenceForm({...sequenceForm,prefix:e.target.value.replace(/[^A-Za-z]/ig, '').toUpperCase()})
     }
     if(key==="sequenceSize"){
      setSequenceForm({...sequenceForm,sequenceSize:e.target.value.replace(/[^0-9]/g, "")})
     }
     if(key==="nextNumber"){
      setSequenceForm({...sequenceForm,nextNumber:e.target.value.replace(/[^0-9]/g, "")})
     }
  }


  const bodyToApi={
    sequenceName:sequenceForm?.sequenceName?.name,
    prefix:sequenceForm?.prefix,
    sequenceSize:Number(sequenceForm?.sequenceSize),
    nextNumber:Number(sequenceForm?.nextNumber)
  }
  const submitFunction=()=>{
    addSequenceAPICall(bodyToApi,updateListner,clearState,setSuccessSnackOpen,setErrorSnackOpen,setSnackMsg)
  }

  const clearState=()=>{
    setSequenceForm(sequenceFormInitState)
  }

  const filterObjFromList=(key,list,item)=>{
    let filteredArray=[]
    filteredArray=list?.filter((obj)=>
        obj[key]===item
    )
    return filteredArray?.length!==0&& filteredArray !== undefined ? filteredArray[0]:[];
  }

  const singleRowClickFn=(data)=>()=>{
     console.log(data);
     setIsDisabled(false)
     setFullDisable(false)
     setCreateSequence(true)
     setIsEdit(true)
     setEditedId(data?.id)
     setSequenceForm({
      ...sequenceForm,
      sequenceName:filterObjFromList("name",sequenceName,data?.sequenceName),
      prefix:data?.prefix,
      sequenceSize:data?.sequenceSize,
      nextNumber:data?.nextNumber
    })

  }

  const editBody={
    id:editedId,
    prefix:sequenceForm?.prefix,
    sequenceSize:Number(sequenceForm?.sequenceSize)
  }
  const editSubmitFunction=()=>{
    editSequenceAPICall(
      editBody,
      updateListner,
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg
      
      )
  }
  const editenableFn=()=>{
    setIsDisabled(true)
  }
  

   
    
  return (
    <>
        {
            createSequence?
            <>
            <div className="global-white-bg-container" style={{margin:"1% 1%",borderRadius:"4px"}}>
       <Tooltip title="Back">
          <IconButton onClick={backButtonFn}>
            <i class="bi bi-chevron-left"></i>
         </IconButton>
       </Tooltip>
    {
      isEdit === true &&
      <Tooltip title="Edit">
      <IconButton onClick={isDisabled?editSubmitFunction:editenableFn}>
      {
          isDisabled?
           <i class="bi bi-check2-square edit-icon" style={{fontSize:"1.5rem"}}></i>
          :
           <i class="bi bi-pencil-square edit-icon1"></i>
        }
     
      </IconButton>
    </Tooltip>
    }
       
       {
        isEdit === false && 
        <Tooltip title="Save">
         <IconButton onClick={submitFunction}>
           <i class="bi bi-floppy"></i>
         </IconButton>
      </Tooltip>
       }
       
     
       
     </div>
           <div  className="utility-settings-menu-container" style={{padding:"0px 4px",flexDirection:"row",flexWrap:"wrap",width:"98%"}}>
               <div className='global-single-input currency-updated-single-input auto-complete'>
                  <p>Sequence Name</p>
                  <Autocomplete
                sx={{ width: "100%" }}
                options={sequenceName||[""]}
                getOptionLabel={(option) =>option?.name}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Sequence Name" />
                )}
                value={sequenceForm?.sequenceName}
                onChange={getSequenceForm("sequencename")}
                disabled={fullDisable===false}
              
             />
               </div>
               <div className='global-single-input currency-updated-single-input'>
                  <p>Prefix</p>
                  <input 
                   type="text"
                   value={sequenceForm?.prefix}
                   onChange={getSequenceForm("prefix")}
                   disabled={isDisabled===false}
                   />
               </div>
               <div className='global-single-input currency-updated-single-input'>
                  <p>Sequence Size</p>
                  <input 
                    type="text"
                    value={sequenceForm?.sequenceSize}
                    onChange={getSequenceForm("sequenceSize")}
                    disabled={isDisabled===false}
                    />
               </div>
               <div className='global-single-input currency-updated-single-input'>
                  <p>Next Number</p>
                  <input 
                    type="text"
                    value={sequenceForm?.nextNumber}
                    onChange={getSequenceForm("nextNumber")}
                    disabled={fullDisable===false}
                    />
               </div>
              
           </div>
           </>
           :
            <div  className='utility-settings-container'>
            <div className="utility-settings-menu-container" style={{padding:"0px 4px"}}>
            <div className='create-button-blue-container'>
               {/* <button onClick={craeteSequenceBtnFn} className='create-button-blue'>Create</button> */}
             </div>
             <div className='global-table-container'>
                <table className='global-table'>
                <thead>
                     <tr>
                        <th>No</th>
                        <th>Sequence Name</th>
                        <th>Sequence No</th>
                     </tr>
                   </thead>
                   <tbody>
                    {
                      sequenceList?.length !== 0 ? sequenceList?.map((r,i)=>(
                       <tr onClick={singleRowClickFn(r)} style={{cursor:"pointer"}} title='View Details'>
                         <td>{i+1}</td>
                         <td>{r?.sequenceName}</td>
                         <td>{r?.sequence}</td>
                       </tr>
                      ))
                      :
                      <tr>
                        <td colSpan={7}>No Data</td>
                      </tr>
                      
                    }
                      
                    </tbody>
                </table>
             </div>
            </div>
            </div>
        }

      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />

      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />

            
    </>
  )
}
