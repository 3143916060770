import { createSlice } from "@reduxjs/toolkit";

export const cuffDataSlice=createSlice({
    name:"cuffDataSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        update_cuff:(state,action)=>{
            state.value=action.payload.cuff
        }
    }
})

export const {update_cuff}=cuffDataSlice.actions
export default cuffDataSlice.reducer