import axios from "axios"
import { get_all_currency } from "../../../Redux/Settings/Currency/viewCurrencySlice"
import store from "../../../Redux/store"
import { HEADERS } from "../../UrlAndPaths"
import { all_currency_updated, set_currency_filtered_list } from "../../../Redux/Settings/Currency/viewCurrencyUpdatedSlice"


// add currency manual api call
export const addCurrencyManualAPICall=async(
    body,
    updateListener,
    setSuccessSnackbar,
    setErrorSnackbar,
    setSnackMsg,
    
    )=>{
  await axios.post("settings/addCurrency",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
        // console.log('currency added');
        updateListener()
        setSuccessSnackbar(true)
        setSnackMsg('Success')
        

    }
  })
  .catch((err)=>{
    
    setErrorSnackbar(true)
    if (err.response === undefined) {
        setSnackMsg("Network Connection error!!");
     }
      if (err.response.status === 409) {
        setSnackMsg("Already Exist");
      }
  
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden");
      }
      console.log(err.response);
  })
}

/// view currency api call
export const viewAllCurrencyAPICall=async()=>{
  await axios.post("settings/viewallCurrency",{},HEADERS)
  .then((res)=>{
    if(res.status===200){
        store.dispatch(get_all_currency({viewCurrencyData:res.data}))
    }
  })
  .catch((err)=>{
    store.dispatch(get_all_currency({viewCurrencyData:undefined}))
    console.log(err.response);
  })
}


// edit currency manual

export const editCurrencyManualAPICall=async(body,updateListener)=>{
  await axios.put('settings/editcurrency',body,HEADERS)
          .then((res)=>{
            if(res.status===200){
                updateListener()
            }
          })
          .catch((err)=>{
            console.log(err.response)
          })
}

/// CURRENCY UPDATED
export const viewAllCurrencyUpdated=(body)=>{
  axios.post("settings/viewCurrency",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(all_currency_updated({currencyUpdatedData:res.data}))
    }
  })
  .catch((err)=>{
    console.log(err.response);
  })
}
export const CurrencyUpdatedFilterAPICall =(body,setIsLoading)=>{
  axios.post("settings/searchCurrency",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(set_currency_filtered_list(res.data))
      setIsLoading!==undefined&&setIsLoading(false)
    }
  })
  .catch((err)=>{
    store.dispatch(set_currency_filtered_list(undefined))
    setIsLoading!==undefined&&setIsLoading(false)
  })

}

export const addCurrencyUpdated=(
  body,
  clearState,
  updateListner,
  setSuccessSnackOpen,
  setErrorSnackOpen,
  setSnackMsg
  )=>{
  axios.post("settings/addCurrency",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      console.log('added');
      setSnackMsg('Currency Added')
      setSuccessSnackOpen(true)
      updateListner()
      clearState()
    }
  })
  .catch((err)=>{
    switch (err.response.status) {
      case 404:
          setSnackMsg('No data available..')
        break;
      case 500:
          setSnackMsg('Server error!!')
        break;
      case 401:
          setSnackMsg('Unauthorized!!')
        break;
      case 403:
          setSnackMsg('Forbidden!!')
        break;
      case 409:
          setSnackMsg("Duplicated")
      default:
          setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
        break;
    }
  setErrorSnackOpen(true)
    console.log(err.response);
  })
}
export const editCurrecyUpdated=(
  body,
 
  updateListner,
  setSuccessSnackOpen,
  setErrorSnackOpen,
  setSnackMsg
  )=>{
  axios.put("settings/editcurrency",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      console.log("edited");
      setSnackMsg('Currency Edited')
      setSuccessSnackOpen(true)
      updateListner()
    }
  })
  .catch((err)=>{
    switch (err.response.status) {
      case 404:
          setSnackMsg('No data available..')
        break;
      case 500:
          setSnackMsg('Server error!!')
        break;
      case 401:
          setSnackMsg('Unauthorized!!')
        break;
      case 403:
          setSnackMsg('Forbidden!!')
        break;
      case 409:
          setSnackMsg("Duplicated")
      default:
          setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
        break;
    }
  setErrorSnackOpen(true)
    console.log(err.response);
  })
}