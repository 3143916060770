import React,{useState,useEffect} from 'react'
import uploadIcon from '../../../../Assets/SVG/uploadIcon.svg'
import {Autocomplete, IconButton,TextField,Tooltip} from '@mui/material'
import {viewCardManualAPICall} from '../../../../API/Settings/CardAndUpiType/cardManualAPI'
import {useSelector} from 'react-redux'
import SuccessSnackbar from '../../../Single Components/SnackBars/SuccessSnackbar'
import ErrorSnackbar from '../../../Single Components/SnackBars/ErrorSnackbar'
import {addCardManualAPICall,editCardManualAPICall} from '../../../../API/Settings/CardAndUpiType/cardManualAPI'
import { journalListApiCall } from '../../../../API/AccountUpdated/journalAPI'
import { filterObjFromList } from '../../../../Js/generalFunctions'


export const CardManual = () => {
   const cardManual=useSelector((state)=>state.cardManualSlice.value)
   const journalList = useSelector((state) => state.journalSlice.value)
   const typeCashAndBankJournalList=journalList?.filter(item=>item.type ==="bank")
  //Update Listener States
   const [isModify, setIsModify] = useState(false);
  // states
  const [banckJournal,setBankJournal]=useState(null)
  const [cardName,setCardName]=useState('')
  const [cardCommission,setCardCommission]=useState('')
  const [imgUrl,setImgUrl]=useState()
  const [selectedImgName,setSelectedImgName]=useState('')

  // edit states
  const [isEdit,setIsEdit]=useState(false)
  const [editedId,setEditedId]=useState()

  // snackbar states
  const [successSnackbar,setSuccessSnackbar]=useState(false)
  const [errorSnackbar,setErrorSnackbar]=useState(false)
  const [snackMsg,setSnackMsg]=useState('Error!!')

   useEffect(()=>{
    viewCardManualAPICall()
    journalListApiCall()
   },[isModify])

  //  onChange functions
   const getCardName=(e)=>{
     setCardName(e.target.value)
   }
   const getCardCommission=(e)=>{
    setCardCommission(e.target.value)
   }
   const getImgUrl=(e)=>{
     setImgUrl(e.target.files[0])
   }

   useEffect(()=>{
    setSelectedImgName(imgUrl?.name)
   },[imgUrl])
   
    //*Update Listener
    const updateListener = () => {
      setIsModify(!isModify);
    };

   // snackbar functions
  const closeSuccessSnackbar=()=>{
     setSuccessSnackbar(false)
    }
  const closeErrorSnackbar=()=>{
     setErrorSnackbar(false)
    }

    const formData= new FormData();
      formData.append("journalId",banckJournal?._id)
      formData.append("cardName",cardName)
      formData.append("commission",cardCommission)
      formData.append("file",imgUrl) 
     
    const addCardManualSubmitBtn=()=>{
      addCardManualAPICall(
        formData,
        updateListener,
        setSuccessSnackbar,
        setErrorSnackbar,
        setSnackMsg,
        clearState
        )
      
    }
    
    
    const clearState=()=>{
      setCardName('')
      setCardCommission('')
      setImgUrl()
      setSelectedImgName('')

    }

    // edit function
    const clickEditBtn=(id,data,card,commission,image)=>{
      setIsEdit(true)
      setEditedId(id)
      setBankJournal(filterObjFromList("_id",typeCashAndBankJournalList,"journalId",data))
      setCardName(card)
      setCardCommission(commission)
      setImgUrl(image)
      setSelectedImgName(image.split('_').pop())
    }
     
    const editFormData=new FormData();
    editFormData.append("journalId",banckJournal?._id)
    editFormData.append("cardName",cardName)
    editFormData.append("commission",cardCommission)
    editFormData.append("_id",editedId)
    editFormData.append("file",imgUrl)

    const editUpdateBtnFn=()=>{
       editCardManualAPICall(
        editFormData,
        updateListener,
        setSuccessSnackbar,
        setErrorSnackbar,
        setSnackMsg,
        clearState
        )
        setIsEdit(false)
     }
   
  return (
    <div className="card-manual-container">
      <div className="card-manual-top-container">
      <div className="global-single-input card-name-container auto-complete">
          <p>Bank Journal</p>
          <Autocomplete
              options={typeCashAndBankJournalList || []}
              getOptionLabel={(option) => option?.journalName}
              renderInput={(params) => (<TextField {...params} />)}
              value={banckJournal}
              onChange={(e,newValue)=>setBankJournal(newValue)}
                        />
        
        </div>
        <div className="global-single-input card-name-container">
          <p>Card Name</p>
          <input 
          type="text" 
          value={cardName}
          onChange={getCardName}
          />
        </div>
        <div className="global-single-input card-name-container">
          <p>Commission(%)</p>
          <input 
          type="text" 
          value={cardCommission}
          onChange={getCardCommission}
          />
        </div>
        <div className="global-single-input card-type-logo-container">
          <p>Logo</p>
          <div className="card-type-logo">
             <input type="text" value={selectedImgName} disabled/>
           
             <input type="file" id="card-logo" onChange={getImgUrl}/>
             <Tooltip title="Upload Image">
             <label htmlFor="card-logo">
               <img src={uploadIcon} alt="uploadIcon" />
             </label> 
            </Tooltip>
          </div>
        </div>
      </div>
      <button 
      onClick={isEdit===true ? editUpdateBtnFn: addCardManualSubmitBtn} 
      // className="btn btn-primary card-manual-button"
      className={cardName===''||cardCommission===''||imgUrl===undefined ? 
      'btn btn-primary-disabled card-manual-button'
      :
      'btn btn-primary card-manual-button'}
      disabled={cardName===''||cardCommission===''||imgUrl===undefined||banckJournal===null}
      // disabled={clearState()}
      >
        {isEdit=== true ? 'Update': 'Submit'}
      </button>
      { 
        cardManual !== undefined ?
      
      <div className="card-manual-table-container">
        <div className="global-table-container" style={{maxHeight:"51vh"}}>
           <table className="global-table">
              <thead>
                <tr>
                    <th>Bank Journal</th>
                    <th>Card Name</th>
                    <th>Commission</th>
                    <th>Logo</th>
                    <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  cardManual?.slice(0)?.reverse()?.map((r,i)=>(
                    <tr>
                     <td>{r?.journalName}</td>                    <td>{r.cardName?.toUpperCase()}</td>
                    <td>{r.commission}</td>
                    <td>
                       <img src={r.imageUrl}
                     style={{ height: 50, width: 50, objectFit: "fill",marginTop:"8%" }}
                    /> 
                    </td>
                    <td>
                    <IconButton
                      onClick={()=>clickEditBtn(
                        r._id,
                        r,
                        r.cardName,
                        r.commission,
                        r.imageUrl
                        )}
                    >
                    <i class="bi bi-pencil-square edit-icon"></i>
                    </IconButton>
                    </td>
                </tr>
                  ))
                }
              </tbody>
           </table>
        </div>
      </div>
      :
      <div className="card-manual-table-container">
      <h3 className="no-data">No Records</h3>
      </div>
}
      <SuccessSnackbar
       open={successSnackbar}
       handleClose={closeSuccessSnackbar}
       message={snackMsg}
     />
     
     <ErrorSnackbar
       open={errorSnackbar}
       handleClose={closeErrorSnackbar}
       message={snackMsg}
     />
    </div>
  )
}
