import React from 'react'
import "../../../../../css/Purchase Manager/configurationPurchase.css"
import { Autocomplete, TextField } from '@mui/material'
import { useState } from 'react'
import { useEffect } from 'react'
import { addCostingMethodAPICall, getCostingMethodDataAPICall } from '../../../../../API/Purchase Manager/configuration'
import { useSelector } from 'react-redux'
import ErrorSnackbar from '../../../../Single Components/SnackBars/ErrorSnackbar'
import SuccessSnackbar from '../../../../Single Components/SnackBars/SuccessSnackbar'

export const CostingMethod = () => {
  const constMethodData = useSelector((state) => state.ConfigurationSlice.value)
  const constMethodArray = [
    {
      name: "Average",
      value: 0
    },
    {
      name: "Fixed",
      value: 1
    }
  ]
  const [costType, setCostType] = useState(null)
  const [costAmount, setCostAmount] = useState()
  const [isModify, setIsModify] = useState(false)
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
    alert: false
  });

  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      alert: false,
      message: "",
    });
  };
  const updateListner = () => {
    setIsModify(!isModify)
  }

  useEffect(() => {
    getCostingMethodDataAPICall()
  }, [])
  const filterObjFromList = (key, list, item) => {
    let filteredArray = []
    filteredArray = list?.filter((obj) =>
      obj[key] === item
    )
    return filteredArray?.length !== 0 && filteredArray !== undefined ? filteredArray[0] : [];
  }

  useEffect(() => {
    // setCostType(constMethodData[0]?.costType)
    if (constMethodData !== undefined) {
      setCostType(filterObjFromList("value", constMethodArray, constMethodData !== undefined && constMethodData[0]?.costType))
      setCostAmount(constMethodData !== undefined && constMethodData[0]?.price)
    }

  }, [constMethodData])

  const getCostType = (e, newValue) => {
    setCostType(newValue)
  }
  const getCostAmount = (e) => {
    setCostAmount(e.target.value.replace(/[^0-9]/g, ""))
  }


  useEffect(() => {
    if (constMethodData !== undefined) {
      addCostingMethodAPICall({
        costType: costType?.value,
        price: costType?.value !== 1 ? Number(costAmount) : null
      }, updateListner,setSnackBarStates)
    }

  }, [costType, costAmount])

  return (
    <div className='costing-method-container'>
      <div className='global-single-input' style={{ justifyContent: "center" }}>
        <p>Cost Type</p>
      </div>
      <div className='global-single-input auto-complete' style={{ width: "30%", border: "1px solid #000" }}>
        <Autocomplete
          sx={{ width: "100%" }}
          options={constMethodArray || ["No Data"]}
          getOptionLabel={(option) => option?.name}
          renderInput={(params) => (
            <TextField {...params} />
          )}
          value={costType}
          onChange={getCostType}



        />
      </div>
      {
        costType?.value !== 1 &&
        <div className='global-single-input' style={{ width: "30%", border: "1px solid #000" }}>
          <input
            value={costAmount}
            onChange={getCostAmount}
          />
        </div>
      }
      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />

      <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
    </div>
  )
}
