import { createSlice } from "@reduxjs/toolkit";

export const empQrSlice=createSlice({
    name:'empQrSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_emp_qr:(state,action)=>{
            state.value=action.payload.empQr
        }
    }
})

export const {update_emp_qr}=empQrSlice.actions
export default empQrSlice.reducer