import axios from "axios"
import { HEADERS } from "../../../../API/UrlAndPaths"
import store from "../../../../Redux/store"
import { get_wareHouse_list, get_wareHouse_single_view } from "./wareHouseSlice"

export const wareHouseListAPICall=(body)=>{
    axios.post("inventory/listwarehouse",body,HEADERS)
    .then((res)=>{
       if(res.status===200){
        store.dispatch(get_wareHouse_list({wareHouseData:res.data}))
       }   
    })
    .catch((err)=>{
        console.log(err.response);
    })
}

export const addWareHouseAPICall=(
    body,
    cleareState,
    setSuccessSnackOpen,
    setErrorSnackOpen,
    setSnackMsg,setLoadingForm
    )=>{
      setLoadingForm!==undefined && setLoadingForm(true)

  axios.post("inventory/addwarehouse",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      setLoadingForm!==undefined && setLoadingForm(false)
        setSnackMsg('Ware House Added')
        setSuccessSnackOpen(true)
        cleareState()
    }
  })
  .catch((err)=>{
    setLoadingForm!==undefined && setLoadingForm(false)

    switch (err.response.status) {

        case 404:
            setSnackMsg(err?.response?.data)
          break;
        case 500:
            setSnackMsg('Server error!!')
          break;
        case 401:
            setSnackMsg('Unauthorized!!')
          break;
        case 403:
            setSnackMsg('Forbidden!!')
          break;
        case 409:
            setSnackMsg("Duplicated")
        default:
            setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
          break;
      }
    setErrorSnackOpen(true)
    console.log(err.response);
  })
}

export const editWareHouseAPICall=(
  body,
  setSuccessSnackOpen,
  setErrorSnackOpen,
  setSnackMsg,setLoadingForm
  )=>{
    setLoadingForm!==undefined && setLoadingForm(true)

    axios.put("inventory/editwarehouses",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
          setLoadingForm!==undefined && setLoadingForm(false)
          setSnackMsg('Edited')
          setSuccessSnackOpen(true)
        }
    })
    .catch((err)=>{
      setLoadingForm!==undefined && setLoadingForm(false)

      switch (err.response.status) {
        case 404:
            setSnackMsg(err?.response?.data)
          break;
        case 500:
            setSnackMsg('Server error!!')
          break;
        case 401:
            setSnackMsg('Unauthorized!!')
          break;
        case 403:
            setSnackMsg('Forbidden!!')
          break;
        case 409:
            setSnackMsg("Duplicated")
        default:
            setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
          break;
      }
    setErrorSnackOpen(true)
    })
}

export const singleViewWareHouseAPICall=(body)=>{
   axios.post("inventory/singleViewWarehouse",body,HEADERS)
   .then((res)=>{
    if(res.status===200){
      store.dispatch(get_wareHouse_single_view({singleWareHouseData:res.data}))

    }
   })
   .catch((err)=>{
    store.dispatch(get_wareHouse_single_view({singleWareHouseData:undefined}))

   })
}