import { createSlice } from "@reduxjs/toolkit";

export const noticeboardSlice=createSlice({
    name:'noticeboardSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_noticeboard:(state,action)=>{
            state.value=action.payload.noticeboard
        }
    }
})

export const {update_noticeboard}=noticeboardSlice.actions
export default noticeboardSlice.reducer