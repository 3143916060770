import { Autocomplete, IconButton, TextField, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { styled, lighten, darken } from "@mui/system";
import { AddChartOfAccountApiCall, editChartOfAccountsAPICall } from "../../../../API/ChartOfAccount/ChartOfAccount";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { filterObjFromList } from "../../../../Js/generalFunctions";
import {viewAllBranchesAPICall} from "../../../../API/Settings/Company Settings/companySettingsAPI"
import { LoadingForm } from "../../../Single Components/LoadingForm";
import { set_Back_button_action, setFilterActive } from "../../../Single Components/listSlice/filterCategorySlice";

// const GroupHeader = styled('div')(({ theme }) => ({
//   position: 'sticky',
//   top: '-8px',
//   padding: '4px 10px',
//   color: theme.palette.primary.main,
//   backgroundColor:
//     theme.palette.mode === 'light'
//       ? lighten(theme.palette.primary.light, 0.85)
//       : darken(theme.palette.primary.main, 0.8),
// }));

// const GroupItems = styled('ul')({
//   padding: 0,
// });
const options = [
  { label: "ASSETS", type: "heading" },
  { label: "Receivables", type: "subheading" },
  { label: "Bank and cash", type: "subheading" },
  { label: "Current asset", type: "subheading" },
  { label: "Non current asset", type: "subheading" },
  { label: "Prepayments", type: "subheading" },
  { label: "Fixed assets", type: "subheading" },

  { label: "LIABILITY", type: "heading" },
  { label: "Payable", type: "subheading" },
  { label: "Credit card", type: "subheading" },
  { label: "Current liability", type: "subheading" },
  { label: "Non current liability", type: "subheading" },
  { label: "EQUITY", type: "heading" },
  { label: "Equity", type: "subheading" },
  { label: "Current year earnings", type: "subheading" },

  { label: "INCOME", type: "heading" },
  { label: "Income", type: "subheading" },
  { label: "Other Income", type: "subheading" },

  { label: "EXPENSES", type: "heading" },
  { label: "Expenses", type: "subheading" },
  { label: "Depriciations", type: "subheading" },
  { label: "Cost of revenue", type: "subheading" },

  { label: "OTHER", type: "heading" },
  { label: "Off -balance sheet", type: "subheading" },
];

const renderOption = (props, option) => (
  <li {...props}>
    {option.type === "subheading" ? (
      <span>{option.label}</span>
    ) : (
      <strong
        style={{
          backgroundColor: "non",
          padding: "5px",
          width: "100%",
          color: "BLue",
        }}
      >
        {option.label}
      </strong>
    )}
  </li>
);

export const CreateChartOfAccount = () => {
  const singleView=useSelector((state)=>state.chartOfAccountsSlice.singleValue)
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  //
  // const top100Films = [
  //   { title: "The Shawshank Redemption", year: 1994 },
  //   { title: "The Godfather", year: 1972 },
  //   { title: "The Godfather: Part II", year: 1974 },
  //   { title: "The Dark Knight", year: 2008 },
  //   { title: "12 Angry Men", year: 1957 },
  //   { title: "Schindler's List", year: 1993 },
  //   { title: "Pulp Fiction", year: 1994 },
  //   { title: "The Shawshank Redemption", year: 1994 },
  //   { title: "The Godfather", year: 1972 },
  //   { title: "The Godfather: Part II", year: 1974 },
  //   { title: "The Dark Knight", year: 2008 },
  //   { title: "12 Angry Men", year: 1957 },
  //   { title: "Schindler's List", year: 1993 },
  //   { title: "Pulp Fiction", year: 1994 }
  // ];

  // const options = top100Films.map((option) => {
  //   const firstLetter = option.title[0].toUpperCase();
  //   return {
  //     firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
  //     ...option,
  //   };
  // });

  // const options = [
  //   { section: 'Fruits', data: ['Mango' , 'Orange'] },
  //   { section: 'Vegetables', data: ['Carrot', 'Broccoli', 'Spinach'] },
  // ];
  //
  let navigateTo=useNavigate()
  const Type = [
    "Receivables",
    "Bank and cash",
    "Current asset",
    "Non current asset",
    "Prepayments",
    "Fixed assets",
    "Payable",
    "Credit card",
    "Current liability",
    "Non current liability",
    "Equity",
    "Current year earnings",
    "Income",
    "Other Income",
    "Expenses",
    "Depriciations",
    "Cost of revenue",
    "Off balance sheet",
  ];

  const [addChartOfAccount, setAddChartOfAccount] = useState({
    code: "",
    accountName: "",
    type: null,
    defaultTaxes: "",
    status: true,
    branch:null
  });

  // Alert State
  const [addChartOfAccountAlert, setAddChartOfAccountAlert] = useState({
    codeAlert: false,
    accountNameAlert: false,
    typeAlert: false,
    defaultTaxesAlert: false,
    statusAlert: false,
    branchAlert:false
  });

  // console.log(addChartOfAccountAlert);

  const [TypeName, setTypeName] = useState(null);
  
  const [loadingForm, setLoadingForm] = useState(false);
  const dispatch = useDispatch();
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!!");

  //getCreateChartOfAccount

  const getCreateChartOfAccount = (e) => {
    setAddChartOfAccount({
      ...addChartOfAccount,
      status: e.target.checked,
    });
  };

  const closeSuccessSnack = () => {
    setSuccessSnackOpen(false);
  };

  const closeErrorSnack = () => {
    setErrorSnackOpen(false);
  };

  // clearState

  const clearState = () => {
    setAddChartOfAccount({
      code: "",
      accountName: "",
      type: null,
      defaultTaxes: "",
      status: true,
    });
    setTypeName(null);
  };

  const CreateChartOfAccountFun = (key) => (e,newValue) => {
    const { value } = e.target;

    //Code

    if (key === "Code") {
      setAddChartOfAccount({
        ...addChartOfAccount,
        code: value,
      });

      setAddChartOfAccountAlert({
        ...addChartOfAccountAlert,
        codeAlert: false,
      });

      if (value === "" || value.length < 6) {
        console.log(value.length);
        setAddChartOfAccountAlert({
          ...addChartOfAccountAlert,
          codeAlert: true,
        });
      }
      // if()
    }

    // AccountName
    if (key === "AccountName") {
      setAddChartOfAccount({
        ...addChartOfAccount,
        accountName: value,
      });

      setAddChartOfAccountAlert({
        ...addChartOfAccountAlert,
        accountNameAlert: false,
      });

      if (value === "") {
        setAddChartOfAccountAlert({
          ...addChartOfAccountAlert,
          accountNameAlert: true,
        });
      }
    }

    //Type

    //defaultTaxes
    if (key === "defaultTaxes") {
      console.log(value);
      setAddChartOfAccount({
        ...addChartOfAccount,
        defaultTaxes: value,
      });

      setAddChartOfAccountAlert({
        ...addChartOfAccountAlert,
        defaultTaxesAlert: false,
      });

      if (value === "") {
        setAddChartOfAccountAlert({
          ...addChartOfAccountAlert,
          defaultTaxesAlert: true,
        });
      }
    }

    if(key==="branch"){
      setAddChartOfAccount({...addChartOfAccount,branch:newValue})
      setAddChartOfAccountAlert({
        ...addChartOfAccountAlert,
        branchAlert: false,
      });

      // if (newValue === null) {
      //   setAddChartOfAccountAlert({
      //     ...addChartOfAccountAlert,
      //     branchAlert: true,
      //   });
      // }
    }

  };

  const bodyToApi={
    code:addChartOfAccount?.code,
    accountName:addChartOfAccount?.accountName,
    type:addChartOfAccount?.type?.label,
    // defaultTaxes:addChartOfAccount?.defaultTaxes,
    status:addChartOfAccount?.status,
    branchId:addChartOfAccount?.branch?._id
  }
  // saveBtn Fun
  const SaveBtnFun = () => {
    dispatch( setFilterActive(false))
    const { code, accountName, type, defaultTaxes, status, branch } = addChartOfAccount;

    if (code === "" || code.length < 6) {
      setAddChartOfAccountAlert({
        ...addChartOfAccountAlert,
        codeAlert: true,
      });
    } else if (accountName === "") {
      setAddChartOfAccountAlert({
        ...addChartOfAccountAlert,
        accountNameAlert: true,
      });
    } else if (type === null) {
      setAddChartOfAccountAlert({
        ...addChartOfAccountAlert,
        typeAlert: true,
      });
    }


    // else if(branch===null){
    //     setAddChartOfAccountAlert({
    //       ...addChartOfAccountAlert,
    //       branchAlert:true
    //     })
    //   }
    else {
      AddChartOfAccountApiCall(
        bodyToApi,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        clearState,setLoadingForm
      );
    }
  };


  const backButtonFunction = () => {
    if (addChartOfAccount?.isEdit === false) {
      const userConfirmed = window.confirm("Changes you made may not be saved.");
      
      if (userConfirmed) {
        navigateTo("/userdashboard/accounts/accounting/chartofaccounts");
        dispatch(set_Back_button_action({ backButtonClickAction: true }));
      }
  }
  else{
    navigateTo("/userdashboard/accounts/accounting/chartofaccounts");
    dispatch(set_Back_button_action({ backButtonClickAction: true }));
  }
    
  };

  useEffect(()=>{
    viewAllBranchesAPICall()
  },[])

  // edit section
  useEffect(()=>{
    console.log(singleView);
    if(singleView !== undefined){
      setAddChartOfAccount({...addChartOfAccount,
        code:singleView?.code,
        accountName:singleView?.accountName,
        type:filterObjFromList("label",options,"type",singleView),
        defaultTaxes:singleView?.defaultTaxes,
        status:singleView?.status,
        branch:filterObjFromList("_id",allBranchesList,"branchId",singleView),
        isEdit:true,
        editBtn:true
      })
    }
  },[singleView])

  console.log(addChartOfAccount);
 const editBody={
    code:addChartOfAccount?.code,
    accountName:addChartOfAccount?.accountName,
    type:addChartOfAccount?.type?.label,
    defaultTaxes:addChartOfAccount?.defaultTaxes,
    status:addChartOfAccount?.status,
    _id:singleView?._id,
    branchId:addChartOfAccount?.branch?._id
 }
 const isEditTrueFn=()=>{
  setAddChartOfAccount({...addChartOfAccount,
    isEdit:true,
  })
 }
 const editSaveFn=()=>{
  editChartOfAccountsAPICall(
    editBody,
    isEditTrueFn,
    setSuccessSnackOpen,
    setErrorSnackOpen,
    setSnackMsg,setLoadingForm
    )
 }

 const editEnableFn=()=>{
  setAddChartOfAccount({...addChartOfAccount,
    isEdit:false,
  })
 }

 useEffect(() => {
  const handleBeforeUnload = (event) => {
    const shouldShowAlert = !addChartOfAccount?.isEdit;
    if (shouldShowAlert) {
      event.preventDefault();
    }
  };

  window.addEventListener('beforeunload', handleBeforeUnload);

  return () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
  };
}, [addChartOfAccount?.isEdit]);
  return (
    <div className="global-page-parent-container">
      {/* <p className="breadcrumb">
        Accounts &gt; Accounting &gt; Chart Of Accounts &gt; Create Chart Of
        Account
      </p> */}
      <div className="global-white-bg-container">
        <Tooltip title="Back">
          <IconButton onClick={backButtonFunction}>
            <i class="bi bi-chevron-left"></i>
          </IconButton>
        </Tooltip>
        {
          addChartOfAccount?.editBtn === true &&
          <Tooltip title={addChartOfAccount?.isEdit?"Edit":"Save"}>
          <IconButton onClick={addChartOfAccount?.isEdit? editEnableFn : editSaveFn}>
          {
                addChartOfAccount?.isEdit?
                <i class="bi bi-pencil-square edit-icon1"></i>
                :
                <i class="bi bi-check2-square edit-icon" style={{fontSize:"1.5rem"}}></i>
              }
          </IconButton>
        </Tooltip>
        }
        {
           addChartOfAccount?.editBtn !== true &&
           <Tooltip title="Save">
           <IconButton onClick={SaveBtnFun}>
             <i class="bi bi-floppy"></i>
           </IconButton>
         </Tooltip>
        }
       
      </div>

      <div className="global-white-bg-container create-journal-container">
        <div className="global-single-input create-joutnal-input">
          <p>Code</p>
          <input
            type="Number"
            onChange={CreateChartOfAccountFun("Code")}
            value={addChartOfAccount?.code}
            disabled={addChartOfAccount?.isEdit}
          />
          {addChartOfAccountAlert?.codeAlert && (
            <p className="doc-validation-alert">Min 6 Digit !!</p>
          )}
        </div>
        <div className="global-single-input auto-complete create-joutnal-input">
          <p>Account Name</p>
          {/* <Autocomplete
            sx={{ width: "100%" }}
            options={[""]}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select Type" />
            )}
          /> */}
          <input
            type="text"
            onChange={CreateChartOfAccountFun("AccountName")}
            value={addChartOfAccount?.accountName}
            disabled={addChartOfAccount?.isEdit}
          />
          {addChartOfAccountAlert?.accountNameAlert && (
            <p className="doc-validation-alert">Fill AccountName!!</p>
          )}
        </div>
        <div className="global-single-input create-joutnal-input auto-complete">
          <p>Type</p>

          <Autocomplete
            options={options||[""]}
            getOptionLabel={(option) => option.label}
            renderOption={renderOption}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
            getOptionDisabled={(option) => option.type === "heading"}
            value={addChartOfAccount?.type}
            onChange={(e, newValue) => {
              setAddChartOfAccount({
                ...addChartOfAccount,
                type: newValue,
              });
              setAddChartOfAccountAlert({
                ...addChartOfAccountAlert,
                typeAlert: false,
              });

              if (newValue === null) {
                setAddChartOfAccountAlert({
                  ...addChartOfAccountAlert,
                  typeAlert: true,
                });
              }
            }}
            disabled={addChartOfAccount?.isEdit}
            
          />

          {addChartOfAccountAlert?.typeAlert && (
            <p className="doc-validation-alert">Select Type!!</p>
          )}
        </div>
        <div className="global-single-input create-joutnal-input auto-complete">
           <p>Branch</p>
           <Autocomplete
                    options={allBranchesList||[]}
                    getOptionLabel={(option) => `${option?.storeCode}-${option?.branchName}`}
                    renderInput={(params)=>(
                      <TextField {...params} placeholder="select Branch"/>
                    )}
                    value={addChartOfAccount?.branch}
                    onChange={CreateChartOfAccountFun("branch")}
                    disabled={addChartOfAccount?.isEdit}
                  />
                     {addChartOfAccountAlert?.branchAlert && (
                      <p className="doc-validation-alert">Select Branch!!</p>
          )}
        </div>
        {/* <div className="global-single-input auto-complete create-joutnal-input">
          <p>Default Taxes</p>
          <input
            type="Number"
            value={addChartOfAccount?.defaultTaxes}
            onChange={CreateChartOfAccountFun("defaultTaxes")}
            disabled={addChartOfAccount?.isEdit}
          />
          {addChartOfAccountAlert?.defaultTaxesAlert && (
            <p className="doc-validation-alert">Fill Tax!!</p>
          )}
        </div> */}
        <div className="global-single-input create-joutnal-input">
          <p>Active/Deactive</p>
          <input
            className="toggle-checkbox"
            type="checkbox"
            onChange={(e) => getCreateChartOfAccount(e)}
            checked={addChartOfAccount?.status}
            disabled={addChartOfAccount?.isEdit}
          />
        </div>
        {/* <div className='global-single-input auto-complete create-joutnal-input'>
             <p>Default Account</p>
             <Autocomplete
                sx={{ width: "100%" }}
                options={[""]}
                getOptionLabel={(option) =>option}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Type" />
                )}
             />
           </div> */}
        {/* <div className='create-journal-button-container'>
               <button onClick={''} className='btn btn-secondary-outlined'>Cancel</button>
               <button className='btn btn-primary'>Submit</button>
           </div> */}
      </div>
      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnack}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnack}
        message={snackMsg}
      />
      <LoadingForm loading={loadingForm} />

    </div>
  );
};
