import React,{useRef} from 'react'
import {Select,IconButton} from '@mui/material'
import ReactToPrint from "react-to-print";
export const QuickBillReport = () => {
  let quickBillReport =useRef()
  return (
    <div className="global-page-parent-container">
    <p className="breadcrumb">Report &gt; Quick Bill Report</p>
    <div className="daily-report-top-container">
      <h3>Quick Bill Report</h3>
      <hr className="global-hr"/>
      <div className="daily-report-top-input-container expense-top-main-container" >
      <div className="global-single-input">
        <p>Branch</p>
        <Select></Select>
       </div>
       <div className="global-single-input">
        <p>Doc No</p>
        <Select></Select>
       </div>
       <div className="global-single-input">
        <p>Customer</p>
        <Select></Select>
       </div>
       <div className="global-single-input">
        <p>Mobile</p>
        <input type="text" maxLength="15"/>
       </div>
       <div className="global-single-input">
        <p>Date</p>
        <input type="date"/>
       </div>
      </div>
      <div className="expense-report-button-container">
      <button className="btn btn-primary report-submit-btn">Submit</button>
      </div>
     
     
    </div>
    
    {/* table */}
    <div className="global-white-bg-container">
     <div className="stock-report-print-download-icon-container">
     <ReactToPrint
             trigger={()=>(
            <IconButton className="print-icon-container">
              <i class="bi bi-printer printer-icon"></i>
            </IconButton>
           )}
            content={()=>quickBillReport}
            
            />  
     <IconButton className="download-icon-container">
       <i class="bi bi-arrow-down-circle download-icon"></i>
     </IconButton>
     </div>
     <div className="global-table-container">
        <table className="global-table">
           <thead>
             <tr>
              <th>Branch Code</th>
              <th>Branch Name</th>
              <th>Doc No</th>
              <th>Date</th>
              <th>Customer</th>
              <th>Del Date</th>
              <th>Product</th>
              <th>Qty</th>
              <th>Adv Amount</th>
              <th>WO No</th>
              <th>Status</th>
             </tr>
           </thead>
           <tbody>
              <tr>
              <td>WH0001</td>
              <td>Calicut</td>
              <td>0123545</td>
              <td>12-02-2022</td>
              <td>Akshay</td>
              <td>10-02-2022</td>
              <td>shirt</td>
              <td>2</td>
              <td>500</td>
              <td>WHWOA001</td>
              <td><p className="commission-report-status-delivered" style={{width:'95%'}}>Delivered</p></td>
              </tr>
              <tr>
              <td>WH0001</td>
              <td>Calicut</td>
              <td>0123545</td>
              <td>12-02-2022</td>
              <td>Akshay</td>
              <td>10-02-2022</td>
              <td>shirt</td>
              <td>2</td>
              <td>500</td>
              <td>WHWOA001</td>
              <td><p className="commission-report-status-delivered" style={{width:'95%',backgroundColor:"#e61a1a"}}>Pending</p></td>
              </tr>
           </tbody>
        </table>
     </div>
    </div>

     {/* quick bill report print */}
     <div style={{display:'none'}}>
   
     <div ref={(el)=>(quickBillReport = el)} >
          <div className='print-view-top-container'>
            <div className='print-view-top-left-container'>
            </div>
            <div className='print-view-top-middle-container'>
                <h3>TEXTILE NAME</h3>
                 <p>QUICK BILL REPORT</p>
            </div>
            <div className='print-view-top-right-container'>
                <p>22-09-2022</p>
                <p>17:33</p>
            </div>
          </div>
          <div className='print-view-table-container'>

          <table>
          <thead>
             <tr>
              <th>Branch Code</th>
              <th>Branch Name</th>
              <th>Doc No</th>
              <th>Date</th>
              <th>Customer</th>
              <th>Del Date</th>
              <th>Product</th>
              <th>Qty</th>
              <th>Adv Amount</th>
              <th>WO No</th>
              <th>Status</th>
             </tr>
           </thead>
           <tbody>
              <tr>
              <td>WH0001</td>
              <td>Calicut</td>
              <td>0123545</td>
              <td>12-02-2022</td>
              <td>Akshay</td>
              <td>10-02-2022</td>
              <td>shirt</td>
              <td>2</td>
              <td>500</td>
              <td>WHWOA001</td>
              <td>Delivered</td>
              </tr>
              <tr>
              <td>WH0001</td>
              <td>Calicut</td>
              <td>0123545</td>
              <td>12-02-2022</td>
              <td>Akshay</td>
              <td>10-02-2022</td>
              <td>shirt</td>
              <td>2</td>
              <td>500</td>
              <td>WHWOA001</td>
              <td>Pending</td>
              </tr>
           </tbody>
          </table>
          </div>
     
       </div>
    </div>
    </div>
  )
}
