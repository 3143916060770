import React, { useRef } from "react";
import DropdownInputBox from "../components/DropdownInputBox";
import { Button, IconButton, Skeleton } from "@mui/material";
import "../../../../css/Account/AccountReconciliation/bankReconciliation.css";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ReactToPrint from "react-to-print";

//rest data
const renderData = [
  {
    date: "10/06/23",
    particular: "samsung",
    voucherType: "Bank",
    transitionType: "0001",
    instrumentNo: "10000",
    instrumentDate: "10000",
    bankDate: "10/06/23",
    debit: "5566",
    credit: "4544",
  },
];

const BankReconciliation = () => {
  const componentRef = useRef();
  const isLoading = false;
  return (
    <div className="bank-reconciliation ">
      <p className="breadcrumb">
        Accounts &gt; AccountReconciliation &gt; Bank Reconciliation
      </p>
      {/* select bank name container  */}
      <div className="select-bank-name-container">
        <DropdownInputBox
          containerStyle={{ width: "25%" }}
          labelName={"Bank Name"}
        />
        <div className="bank-name-submit-btn">
          <button>submit</button>
        </div>
      </div>

      {/* body */}
      <div className="table-wrapper-container">
        {/* print and download action bar */}
        <div className="action-bar">
          <ReactToPrint
            trigger={() => (
              <IconButton sx={{ color: "blue" }}>
                <i className="bi bi-printer "></i>
              </IconButton>
            )}
            content={() => componentRef.current}
          />
          <IconButton className="download-btn">
            <ArrowCircleDownIcon sx={{ fontSize: 30 }} />{" "}
          </IconButton>
        </div>
        {/* table  */}
        <div className="table-container">
          <div className="global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Particular</th>
                  <th>Voucher Type</th>
                  <th>Transition Type</th>
                  <th>Instrument No</th>
                  <th>Instrument Date</th>
                  <th>Bank Date</th>
                  <th>Debit</th>
                  <th>credit</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  [...Array(3)].map((_, index) => (
                    <tr key={index}>
                      <td colSpan={7}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                  ))
                ) : renderData.length !== 0 ? (
                  renderData?.reverse()?.map((r, i) => (
                    <React.Fragment key={i}>
                      <tr className="data-table-row-head">
                        <td>{r?.date}</td>
                        <td>{r?.particular}</td>
                        <td>{r?.voucherType}</td>
                        <td>{r?.transitionType}</td>
                        <td>{r?.instrumentNo}</td>
                        <td>{r?.instrumentDate}</td>
                        <td>
                          {" "}
                          <input className="bank-date" type="date" />{" "}
                        </td>
                        <td>{r?.debit}</td>
                        <td>{r?.credit}</td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>No Records</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {/* total */}
        <div className="cash-book-wrapper-container">
          <div className="cash-book">
            <div className="head-cash-book">
              <strong>Debit</strong>
              <strong>Credit</strong>
            </div>
            <div className="balance-as-per-company-book">
              <h4>Balance as per Company Book</h4>
              <input type="text" />
              <input type="text" />
            </div>

            <div className="amount-not-reflected-in-cash">
              <h4>Amount not Reflected in Cash</h4>
              <input type="text" />
              <input type="text" />
            </div>

            <div className="balance-as-per-cash-book">
              <h4>Balance as per Bank</h4>
              <input type="text" />
              <input type="text" />
            </div>
          </div>
        </div>

        {/* cancel and save button */}
        <div className="save-cancel-container">
          <button className="cancel-btn">Cancel</button>
          <button className="save-btn">Save</button>
        </div>
      </div>
      {/* print view */}
      <div style={{ display: "none" }}>
        <div
          ref={componentRef}
          id="currentAsset-report"
          className="print-preview-container"
        >
          <div className="print-head-container">
            <div className="brand-name">
              <h2>Leeyet Textile</h2>
            </div>
            <div className="select-table-head">
              <h3>Bank Reconciliation</h3>
            </div>
            <div className="duration-date">
              <h3>{"01/04/2023"}</h3>
              <strong>TO</strong>
              <h3>{"01/12/2023"}</h3>
            </div>
          </div>
          <div className="global-table-wrapper">
            <div className="table-container">
              <div className="global-table-container">
                <table className="global-table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Particular</th>
                      <th>Voucher Type</th>
                      <th>Transition Type</th>
                      <th>Instrument No</th>
                      <th>Instrument Date</th>
                      <th>Bank Date</th>
                      <th>Debit</th>
                      <th>credit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      [...Array(3)].map((_, index) => (
                        <tr key={index}>
                          <td colSpan={7}>
                            <Skeleton
                              variant="rectangular"
                              animation="wave"
                              width={"100%"}
                              height={40}
                            />
                          </td>
                        </tr>
                      ))
                    ) : renderData.length !== 0 ? (
                      renderData?.reverse()?.map((r, i) => (
                        <React.Fragment key={i}>
                          <tr className="data-table-row-head">
                            <td>{r?.date}</td>
                            <td>{r?.particular}</td>
                            <td>{r?.voucherType}</td>
                            <td>{r?.transitionType}</td>
                            <td>{r?.instrumentNo}</td>
                            <td>{r?.instrumentDate}</td>
                            <td>
                              {" "}
                              <input className="bank-date" type="date" />{" "}
                            </td>
                            <td>{r?.debit}</td>
                            <td>{r?.credit}</td>
                          </tr>
                        </React.Fragment>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={7}>No Records</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* total */}
            <div className="cash-book-wrapper-container">
              <div className="cash-book">
                <div className="head-cash-book">
                  <strong>Debit</strong>
                  <strong>Credit</strong>
                </div>
                <div className="balance-as-per-company-book">
                  <h4>Balance as per Company Book</h4>
                  <input type="text" />
                  <input type="text" />
                </div>

                <div className="amount-not-reflected-in-cash">
                  <h4>Amount not Reflected in Cash</h4>
                  <input type="text" />
                  <input type="text" />
                </div>

                <div className="balance-as-per-cash-book">
                  <h4>Balance as per Cash Book</h4>
                  <input type="text" />
                  <input type="text" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankReconciliation;
