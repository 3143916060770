import { createSlice } from "@reduxjs/toolkit";

export const configurationSlice=createSlice({
    name:"configurationSlice",
    initialState:{
        payTemplateList:undefined
    },
    reducers:{
        get_payTemplate_list:(state, action)=>{
            state.payTemplateList = action.payload
        },
    }
   
})
export const {
    get_payTemplate_list
}=configurationSlice.actions;
export default configurationSlice.reducer;