import axios from 'axios'
import {HEADERS,HEADER_QUERY} from '../../API/UrlAndPaths'
import store from '../../Redux/store'
import {get_quotation} from '../../Redux/Sales/Quotation/viewQuotationSlice'
import {generate_quotation_id} from '../../Redux/Sales/Quotation/generateQuotationIdSlice'
import {get_quotation_id} from '../../Redux/Sales/Quotation/viewQuotationIdSlice'
// generate quotation id api call
export const generateQuotationIdAPICall=async()=>{
    await  axios.get("sales/generateQuotaionNO",HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(generate_quotation_id({generateQuotationId:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(generate_quotation_id({generateQuotationId:undefined}))
        console.log(err.response);
    })
}

// view quotation
export const viewQuotationAPICall = async (body,setIsLoading,invoiceId,customerId,fromDate,toDate)=>{
setIsLoading(true)
await axios.post("sales/viewQuotation",body,
 {
    params:{
        invoiceId,
        customerId,
        fromDate,
        toDate
    },
    headers:HEADER_QUERY
 }
)
.then((res)=>{
    if(res.status === 200){
        store.dispatch(get_quotation({viewQuotationData:res.data}))
        setIsLoading(false)
    }
})
.catch((err)=>{
    store.dispatch(get_quotation({viewQuotationData:undefined}))
    setIsLoading(false)
    console.log(err.response);
})
}

// Add quotation
export const addQuotationAPICall =async(
    body,
    updateListener,
    // setAddQuotation,
    // clearState,
    // setSuccessSnackOpen,
    // setErrorSnackOpen,
    // setSnackMsg
    )=>{
    await axios.post("sales/addQuotation",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            console.log('quotation added');
            updateListener()
            // clearState()
            // setAddQuotation(false)
            // setSuccessSnackOpen(true)
            // setSnackMsg('Added Successfully')

        }
    })
    .catch((err)=>{
        // setErrorSnackOpen(true);
        // if (err.response === undefined) {
        //   setSnackMsg("Network Connection error!!");
        // }
        // if (err.response.status === 409) {
        //   setSnackMsg("Category Already Exist");
        // }
    
        // if (err.response.status === 401) {
        //   setSnackMsg("Unauthorized");
        // }
        // if (err.response.status === 403) {
        //   setSnackMsg("Forbidden");
        // }
        console.log(err.res);
    })
}

// view quotationIdList api call
export const quotationIdListAPICall= async (body)=>{
 await axios.post("sales/quotaionNO",body,HEADERS)
 .then((res)=>{
    if(res.status===200){
        store.dispatch(get_quotation_id({quotationIdList:res.data}))
    }
 })
 .catch((err)=>{
    store.dispatch(get_quotation_id({quotationIdList:undefined}))
    console.log(err.response);
 })
}