import { createSlice } from "@reduxjs/toolkit";

export const getAllSalesReturnInvoiceNoSlice=createSlice({
    name:'getAllSalesReturnNo',
    initialState:{
        value:undefined
    },
    reducers:{
        get_all_sales_return_invoiceNo:(state,action)=>{
            state.value=action.payload.salesRetunInvoiceNo
        }
    }
})
export const {get_all_sales_return_invoiceNo} = getAllSalesReturnInvoiceNoSlice.actions;
export default getAllSalesReturnInvoiceNoSlice.reducer;