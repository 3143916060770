import { createSlice } from "@reduxjs/toolkit";

export const viewPOAndPWOPOByIdSlice=createSlice({
    name:'purchaseAndPurchaseWOPOByIdSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_po_pwopo_by_id:(state,action)=>{
            state.value=action.payload.purchaseAndPurchaseWOPOById
        }
    }
})
export const {get_po_pwopo_by_id}=viewPOAndPWOPOByIdSlice.actions;
export default viewPOAndPWOPOByIdSlice.reducer