import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { IconButton } from "@mui/material";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import InputBox from "../../../../../components/InputBox";

const CashFlowStatementIndirect = ({ selectListItem }) => {
  const componentRef = useRef();

  return (
    <div id="currentAsset-report">
      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="accounting-voucher-menu-sections"
      >
        <h3 style={{ margin: "2% 1%" }}>{selectListItem}</h3>
        <div className="input-current-asset-reports">
          <InputBox label={"From"} type={"date"} />
          <InputBox label={"To"} type={"date"} />
        </div>
      </div>
      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="accounting-voucher-menu-sections"
        id="cashflow-statement-direct"
      >
        <div className="action-btn-container">
          <div>
            <ReactToPrint
              trigger={() => (
                <IconButton sx={{ color: "blue" }}>
                  <i className="bi bi-printer "></i>
                </IconButton>
              )}
              content={() => componentRef.current}
            />
            <IconButton className="download-btn">
              <ArrowCircleDownIcon sx={{ fontSize: 30 }} />
            </IconButton>
          </div>
        </div>

        <div className="global-table-wrapper">
          <table className="global-table">
            <thead>
              <tr>
                <th></th>
                <th className="amount-column">Amount</th>
                <th className="amount-column">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr className="data-table-row-head">
                <td className="data-row">
                  <h3 className="main-head">
                    Cash Flow From Operating Activities
                  </h3>
                  <ul className="data-row-list">
                    <li>Net Profit for the Year</li>
                    <li>Non Cash and Non Operating Expenses</li>
                    <ul>
                      <li>Transfer to General Reserve</li>
                      <li>Goodwill Written off</li>
                      <li>Loss on Sale of Fixed Asset or Investment</li>
                      <li>All Provisions</li>
                    </ul>
                    <li>Non Cash and Non Operating Income</li>
                    <ul>
                      <li>
                        {" "}
                        [ - ] Profit on Sale of Fixed Asset or Investment
                      </li>
                      <li> [ - ] Dividend Received</li>
                      <li> [ - ] Rent Received</li>
                    </ul>
                  </ul>
                </td>
                <td className="data-row ">
                  <ul className="data-row-amount ">
                    <li>&nbsp;</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <ul className="data-row-amount">
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                    </ul>
                    <li className="total-amount">0.00</li>
                    <ul className="data-row-amount">
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                    </ul>
                  </ul>
                </td>
                <td className="data-row "></td>
              </tr>

              <tr className="data-table-row-head">
                <td className="data-row">
                  <p style={{ color: "green" }} className="net-cash-flow ">
                    Operating Profit before Working Capital Changes
                  </p>
                  <ul className="data-row-list">
                    <ul>
                      <li>Purchase of Fixed Assets</li>
                      <li>Plant and Machinery</li>
                      <li>Furniture and Fixtures</li>
                    </ul>
                    <p style={{ color: "green" }} className="net-cash-flow ">
                      Cash Generated from Operation
                    </p>
                    <ul>
                      <li>Income Tax Paid</li>
                      <li>[ - / + ] Adjusted For Extra Ordinary Items</li>
                    </ul>
                  </ul>
                </td>

                <td className="data-row ">
                  <p className=" profit-amount ">000</p>
                  <ul className="data-row-amount ">
                    <ul className="data-row-amount ">
                      <li>00</li>
                      <li>00</li>
                      <li>00</li>
                    </ul>
                    <p className="profit-amount">00</p>
                    <ul className="data-row-amount ">
                      <li>00</li>
                      <li>00</li>
                    </ul>
                  </ul>
                </td>
                <td className="data-row "></td>
              </tr>

              <tr className="data-table-row-head">
                <td className="data-row">
                  <p className="net-cash-flow">
                    Net cash in Flow / Outflow from Operating Activities
                  </p>
                  <h3 className="main-head">
                    Cash Flow From Investing Activities
                  </h3>
                  <ul className="data-row-list">
                    <li>Cash Flow From Investing Activities</li>
                    <li>Sale Of Investment</li>
                    <li>Rent , Dividend etc Received</li>
                    <li>Collection of Loans from Borrowers</li>
                    <li>[ - ] Purchase Of Fixed Asset</li>
                    <li>[ - ] Purchase of Investment</li>
                    <li>[ - ] Loans given Borrows</li>
                  </ul>
                  <p className="net-cash-flow">
                    Net cash in Flow / Out Flow From Investing Activities
                  </p>
                  <h3 className="main-head">
                    Cash Flow From Financing Activities
                  </h3>
                  <ul className="data-row-list">
                    <li>Issue of Shares</li>
                    <li>Issue of Debentures</li>
                    <li>Issue of Loans</li>
                    <li>[ - ] Redemption of Shares</li>
                    <li>[ - ] Redemption of Debentures</li>
                    <li>[ - ] Redemption of Loans</li>
                    <li>[ - ] Dividend , Interest etc paid</li>
                  </ul>
                  <p className="net-cash-flow">
                    Net cash in Flow / Out Flow From Investing Activities
                  </p>
                </td>

                <td className="data-row  ">
                  <p className=" important">0.00</p>
                  <h3 className="main-head">&nbsp;</h3>
                  <ul className="data-row-amount">
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                  </ul>
                  <p className=" important">0.00</p>
                  <h3 className="main-head">&nbsp;</h3>
                  <ul className="data-row-amount">
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                    <li>0.00</li>
                  </ul>
                  <p className="important">0.00</p>
                </td>
                <td className="data-row  ">
                  <p className=" total-amount">1000</p>
                  <h3 className="main-head">&nbsp;</h3>
                  <ul className="data-row-amount">
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                  </ul>
                  <p className=" total-amount">1000</p>
                  <h3 className="main-head">&nbsp;</h3>
                  <ul className="data-row-amount">
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                  </ul>
                  <p className="total-amount">1000</p>
                </td>
              </tr>

              <tr id="total-box-input" className="data-table-row-head">
                <td>
                  <ul className="list-total">
                    <li>
                      <h3>Net Increase / Decrease in Cash for the period</h3>
                    </li>
                    <li>
                      <h5>Cash in the Beginning for the period</h5>
                    </li>
                    <li>
                      <h3>Cash at the End of period</h3>
                    </li>
                  </ul>
                </td>
                <td></td>
                <td>
                  <div className="list-total-wrapper">
                    <div>
                      <input type="text" />
                    </div>
                    <div>
                      <input type="text" />
                    </div>
                    <div>
                      <input type="text" />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* print out  */}
      <div style={{ display: "none" }}>
        <div
          id="cashflow-statement-indirect"
          ref={componentRef}
          className="print-preview-container"
        >
          <div className="print-head-container">
            <div className="brand-name">
              <h2>Leeyet Textile</h2>
            </div>
            <div className="select-table-head">
              <h3>{selectListItem}</h3>
            </div>
            <div className="duration-date">
              <h3>{"01/04/2023"}</h3>
              <strong>TO</strong>
              <h3>{"01/12/2023"}</h3>
            </div>
          </div>

          <div className="global-table-wrapper">
            <table className="global-table">
              <thead>
                <tr>
                  <th></th>
                  <th className="amount-column">Amount</th>
                  <th className="amount-column">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr className="data-table-row-head">
                  <td className="data-row">
                    <h3 className="main-head">
                      Cash Flow From Operating Activities
                    </h3>
                    <ul className="data-row-list">
                      <li>Net Profit for the Year</li>
                      <li>Non Cash and Non Operating Expenses</li>
                      <ul>
                        <li>Transfer to General Reserve</li>
                        <li>Goodwill Written off</li>
                        <li>Loss on Sale of Fixed Asset or Investment</li>
                        <li>All Provisions</li>
                      </ul>
                      <li>Non Cash and Non Operating Income</li>
                      <ul>
                        <li>
                          {" "}
                          [ - ] Profit on Sale of Fixed Asset or Investment
                        </li>
                        <li> [ - ] Dividend Received</li>
                        <li> [ - ] Rent Received</li>
                      </ul>
                    </ul>
                  </td>
                  <td className="data-row ">
                    <ul className="data-row-amount ">
                      <li>&nbsp;</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <ul className="data-row-amount">
                        <li>0.00</li>
                        <li>0.00</li>
                        <li>0.00</li>
                        <li>0.00</li>
                      </ul>
                      <li className="total-amount">0.00</li>
                      <ul className="data-row-amount">
                        <li>0.00</li>
                        <li>0.00</li>
                        <li>0.00</li>
                      </ul>
                    </ul>
                  </td>
                  <td className="data-row "></td>
                </tr>

                <tr className="data-table-row-head">
                  <td className="data-row">
                    <p style={{ color: "green" }} className="net-cash-flow ">
                      Operating Profit before Working Capital Changes
                    </p>
                    <ul className="data-row-list">
                      <ul>
                        <li>Purchase of Fixed Assets</li>
                        <li>Plant and Machinery</li>
                        <li>Furniture and Fixtures</li>
                      </ul>
                      <p style={{ color: "green" }} className="net-cash-flow ">
                        Cash Generated from Operation
                      </p>
                      <ul>
                        <li>Income Tax Paid</li>
                        <li>[ - / + ] Adjusted For Extra Ordinary Items</li>
                      </ul>
                    </ul>
                  </td>

                  <td className="data-row ">
                    <p className=" profit-amount ">000</p>
                    <ul className="data-row-amount ">
                      <ul className="data-row-amount ">
                        <li>00</li>
                        <li>00</li>
                        <li>00</li>
                      </ul>
                      <p className="profit-amount">00</p>
                      <ul className="data-row-amount ">
                        <li>00</li>
                        <li>00</li>
                      </ul>
                    </ul>
                  </td>
                  <td className="data-row "></td>
                </tr>

                <tr className="data-table-row-head">
                  <td className="data-row">
                    <p className="net-cash-flow">
                      Net cash in Flow / Outflow from Operating Activities
                    </p>
                    <h3 className="main-head">
                      Cash Flow From Investing Activities
                    </h3>
                    <ul className="data-row-list">
                      <li>Cash Flow From Investing Activities</li>
                      <li>Sale Of Investment</li>
                      <li>Rent , Dividend etc Received</li>
                      <li>Collection of Loans from Borrowers</li>
                      <li>[ - ] Purchase Of Fixed Asset</li>
                      <li>[ - ] Purchase of Investment</li>
                      <li>[ - ] Loans given Borrows</li>
                    </ul>
                    <p className="net-cash-flow">
                      Net cash in Flow / Out Flow From Investing Activities
                    </p>
                    <h3 className="main-head">
                      Cash Flow From Financing Activities
                    </h3>
                    <ul className="data-row-list">
                      <li>Issue of Shares</li>
                      <li>Issue of Debentures</li>
                      <li>Issue of Loans</li>
                      <li>[ - ] Redemption of Shares</li>
                      <li>[ - ] Redemption of Debentures</li>
                      <li>[ - ] Redemption of Loans</li>
                      <li>[ - ] Dividend , Interest etc paid</li>
                    </ul>
                    <p className="net-cash-flow">
                      Net cash in Flow / Out Flow From Investing Activities
                    </p>
                  </td>

                  <td className="data-row  ">
                    <p className=" important">0.00</p>
                    <h3 className="main-head">&nbsp;</h3>
                    <ul className="data-row-amount">
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                    </ul>
                    <p className=" important">0.00</p>
                    <h3 className="main-head">&nbsp;</h3>
                    <ul className="data-row-amount">
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                      <li>0.00</li>
                    </ul>
                    <p className="important">0.00</p>
                  </td>
                  <td className="data-row  ">
                    <p className=" total-amount">1000</p>
                    <h3 className="main-head">&nbsp;</h3>
                    <ul className="data-row-amount">
                      <li>&nbsp;</li>
                      <li>&nbsp;</li>
                      <li>&nbsp;</li>
                      <li>&nbsp;</li>
                      <li>&nbsp;</li>
                      <li>&nbsp;</li>
                      <li>&nbsp;</li>
                    </ul>
                    <p className=" total-amount">1000</p>
                    <h3 className="main-head">&nbsp;</h3>
                    <ul className="data-row-amount">
                      <li>&nbsp;</li>
                      <li>&nbsp;</li>
                      <li>&nbsp;</li>
                      <li>&nbsp;</li>
                      <li>&nbsp;</li>
                      <li>&nbsp;</li>
                      <li>&nbsp;</li>
                    </ul>
                    <p className="total-amount">1000</p>
                  </td>
                  
                </tr>

                <tr id="total-box-input" className="data-table-row-head">
                  <td >
                    <ul className="list-total">
                      <li>
                        <h3>Net Increase / Decrease in Cash for the period</h3>
                      </li>
                      <li>
                        <h5>Cash in the Beginning for the period</h5>
                      </li>
                      <li>
                        <h3>Cash at the End of period</h3>
                      </li> 
                    </ul>
                  </td>
                  <td></td>
                  <td>
                    <div className="list-total-wrapper">
                      <div>
                        <input type="text" />
                      </div>
                      <div>
                        <input type="text" />
                      </div>
                      <div>
                        <input type="text" />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashFlowStatementIndirect;
