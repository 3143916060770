import axios from "axios";
import { update_roles_and_permission } from "../../../Redux/Settings/Roles and permission/viewRolesAndPermissionSlice";
import store from "../../../Redux/store";
import { HEADERS } from "../../UrlAndPaths";

// Add Roles and Permission by employee id
export const addEmployeeRolesAndPermissionAPICall = async (
  body,
  setState,
  state
) => {
  await axios
    .post("settings/addsettings", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setState({...state,message:"Permission Updated",success:true})

        
      }
    })
    .catch((err) => {
      setState({...state,message:"Something went wrong !!",error:true})
     
    });
};

//Get roles and permission of an employee or by designation
export const getRolesAndPermissionAPICall = async (body) => {
  await axios
    .post("settings/viewuserpermission", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_roles_and_permission({rolesAndPermission:res.data}))
      }
    })
    .catch((err) => {
        store.dispatch(update_roles_and_permission({rolesAndPermission:undefined}))
        console.error(err.response);
    });
};
