import React from 'react'
import '../../../../css/Settings/Shift/shiftEnd.css'
import {Dialog} from '@mui/material'
import {useSelector} from "react-redux";
import {get_shift_denomination_dialog} from '../../../../Redux/Settings/Shift/shiftDenominationDialogSlice'
import Store from '../../../../Redux/store'
export const ShiftEnd = () => {
  const denominationDialog=useSelector((state)=>state.shiftDenominationDialog.value)

  const denominationSubmitBtn=()=>{
    Store.dispatch(get_shift_denomination_dialog({shiftDenomination:false}))
  }
  return (
    <div>
      <Dialog open={denominationDialog} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && denominationSubmitBtn()}>
        <div className="shift-add-denomination-dialog">
          <h4>Add Denomination</h4>
          <div className="add-denomination-input-container">
            <div className="global-single-input">
              <p>Currency</p>
              <input type="text" />
            </div>
            <div className="global-single-input denomination-input">
              <p>Amount</p>
              <input type="text" />
            </div>
            <div className="global-single-input denomination-input">
              <p>Count</p>
              <input type="text" />
            </div>
            <button>+</button>
          </div>
        
          <div className='denomination-btn-container'>
            <button onClick={denominationSubmitBtn} className="btn btn-secondary-outlined denomination-btn">Cancel</button>
            <button onClick={denominationSubmitBtn} className="btn btn-primary denomination-btn">Submit</button>
          </div>
          
        </div>
      </Dialog>
    </div>
  )
}
