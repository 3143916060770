import axios from "axios";
import { get_cutting_SlipModel } from "../../../Redux/Settings/CuttingSlipModel/cuttingSlipModelSlice";
import store from "../../../Redux/store"
import { HEADERS } from "../../UrlAndPaths"


// add template type
export const addCuttingSlipModelAPICall= async (body,setSuccessSnackOpen,setErrorSnackOpen,setSnackMsg,updateListner)=>{
    await axios.post("admin/setTemplate",body,HEADERS)
    .then((res)=>{
         if(res.status===200){
            console.log('added');
            updateListner()
            setSnackMsg('Updated')
            setSuccessSnackOpen(true)
         }
    })
    .catch((err)=>{
        console.log(err.response);
        switch (err.response.status) {
            case 401:
              setSnackMsg("Unauthorized!!");
              break;
            case 409:
              setSnackMsg("Conflit!!");
              break;
            case 403:
              setSnackMsg("Forbidden!!");
              break;
            case 500:
              setSnackMsg("Server error!!");
              break;
    
            default:
              setSnackMsg("Something wrong!!");
              break;
          }
          setErrorSnackOpen(true);
    })
}


// view template type
export const viewCuttingSlipModelAPICall=async()=>{
    await axios.get("admin/getTemplate",HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_cutting_SlipModel({cuttingSlipTemplate:res.data}))
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}