import {
    createSlice
} from '@reduxjs/toolkit'
export const allLocationsSlice = createSlice({
    name: 'allLocationsSlice',
    initialState: {
        value: undefined
    },
    reducers: {
        update_company_locations: (state, action) => {
            state.value = action.payload.locations
        }
    }
})

export const {
    update_company_locations
} = allLocationsSlice.actions
export default allLocationsSlice.reducer

