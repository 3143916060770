import {createSlice} from '@reduxjs/toolkit'

export const generateQuotationIdSlice=createSlice({
    name:'generateQuotationId',
    initialState:{
        value:undefined
    },
    reducers:{
        generate_quotation_id:(state,action)=>{
            state.value=action.payload.generateQuotationId
        }
    }
})
export const {generate_quotation_id} = generateQuotationIdSlice.actions;
export default generateQuotationIdSlice.reducer