import { createSlice } from "@reduxjs/toolkit";

export const stockOutListSlice=createSlice({
    name:'stockOutListSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_all_stock_out_products_list:(state,action)=>{
            state.value=action.payload.stockOutList
        }
    }
})

export const {update_all_stock_out_products_list}=stockOutListSlice.actions
export default stockOutListSlice.reducer
