import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  addDiscountConfiguration,
  viewDiscountConfiguration,
} from "../../../../../API/Purchase Manager/configuration";
import { useSelector } from "react-redux";


export const DiscountConfig = () => {
  const discountView = useSelector(
    (state) => state.ConfigurationSlice.discountValue
  );
  const [globalDiscount, setGlobalDiscount] = useState(false);
  const [isModify, setIsModify] = useState(false);

  const updateListner = () => {
    setIsModify(!isModify);
  };
  useEffect(() => {
    viewDiscountConfiguration();
  }, [isModify]);

  useEffect(() => {
    setGlobalDiscount(discountView?.isGlobal);
  }, [discountView]);

  const getGlobalDiscount = (e) => {
    addDiscountConfiguration({ isGlobal: e.target.checked }, updateListner);
  };

  return (
    <div
      className="costing-method-container"
      style={{ flexDirection: "column" }}
    >
      <div className="global-single-input">
        <p>Purchase</p>
      </div>
      <div className="discount-config-checkbox-container">
        <input
          type="checkbox"
          id="dicountConfig"
          checked={globalDiscount}
          onChange={getGlobalDiscount}
        />
        <label htmlFor="dicountConfig">Global Discount</label>
      </div>
    </div>
  );
};
