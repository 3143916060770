import React, { useRef } from "react";
import InputBox from "../../../components/InputBox";
import ReactToPrint from "react-to-print";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import DropdownInputBox from "../../../components/DropdownInputBox";
import SubmitBtn from "../../../components/SubmitBtn";

//rest data
const renderData = [
  {
    userId: "123",
    earnings: [
      {
        payType: "Basic",
        amount: "2222",
      },
      {
        payType: "DA",
        amount: "545",
      },
      {
        payType: "HRA",
        amount: "545",
      },
      {
        payType: "OTA",
        amount: "5555",
      },
      {
        payType: "Personal Loan",
        amount: "54544",
      },
    ],
    deduction: [
      {
        payType: "personal loan",
        amount: "2222",
      },
      {
        payType: "leave",
        amount: "555",
      },
    ],
  },
];

const PayInSlipEmployee = ({ selectListItem }) => {
  const componentRef = useRef();
  const numToWords = require("num-words");
  const netSalary = 1565;

  return (
    <div id="currentAsset-report" className="pay-in-slip pay-in-slip-employee">
      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="accounting-voucher-menu-sections"
      >
        <h3 style={{ margin: "2% 1%" }}>{selectListItem}</h3>
        <div className="input-current-asset-reports">
          <DropdownInputBox
            labelName={"Select"}
            placeholder={"Select Option"}
          />
          <InputBox label={"From"} type={"date"} />
          <InputBox label={"To"} type={"date"} />
        </div>
        <SubmitBtn
          containerStyle={{ justifyContent: "flex-end", paddingRight: "2%" }}
        />
      </div>
      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="accounting-voucher-menu-sections"
      >
        <div className="pay-in-slip-employee-wrapper">
          <div className="slip-header">
            <h3 className="title">Pay In Slip Employee </h3>
            <div className="save-document">
              <ReactToPrint
                trigger={() => (
                  <button className="print-btn">
                    <i className="bi bi-printer "></i>
                  </button>
                )}
                content={() => componentRef.current}
              />

              <button className="download-btn">
                <ArrowCircleDownIcon sx={{ fontSize: 30 }} />
              </button>
            </div>
          </div>
          <hr className="global-hr" />
          <div className="slip-details">
            <div className="employee-details">
              <div>
                <ul>
                  <li>
                    <h5 className="employee-details-user-data">
                      Employee Name
                    </h5>
                    <h5 className="employee-details-user-data">
                      {" "}
                      <span>:</span>&nbsp;john
                    </h5>
                  </li>
                  <li>
                    <h5 className="employee-details-user-data">Designation</h5>
                    <h5 className="employee-details-user-data">
                      {" "}
                      <span>:</span>&nbsp;Accountant
                    </h5>
                  </li>
                  <li>
                    <h5 className="employee-details-user-data">Location</h5>
                    <h5 className="employee-details-user-data">
                      {" "}
                      <span>:</span>&nbsp;calicut
                    </h5>
                  </li>
                </ul>
              </div>
              <div>
                <ul>
                  <li>
                    <h5 className="employee-details-user-data">Employee ID</h5>
                    <h5 className="employee-details-user-data">
                      {" "}
                      <span>:</span>&nbsp;GSHDA14525
                    </h5>
                  </li>
                  <li>
                    <h5 className="employee-details-user-data">Function</h5>
                    <h5 className="employee-details-user-data">
                      {" "}
                      <span>:</span>&nbsp;Finance
                    </h5>
                  </li>
                  <li>
                    <h5 className="employee-details-user-data">
                      Date of joining
                    </h5>
                    <h5 className="employee-details-user-data">
                      {" "}
                      <span>:</span>&nbsp;19-07-2022
                    </h5>
                  </li>
                </ul>
              </div>
            </div>
            <div className="employee-bank-details employee-details">
              <div>
                <ul>
                  <li>
                    <h5 className="employee-details-user-data">
                      Employee Name
                    </h5>
                    <h5 className="employee-details-user-data">
                      {" "}
                      <span>:</span>&nbsp;john
                    </h5>
                  </li>
                  <li>
                    <h5 className="employee-details-user-data">Designation</h5>
                    <h5 className="employee-details-user-data">
                      {" "}
                      <span>:</span>&nbsp;Accountant
                    </h5>
                  </li>
                  <li>
                    <h5 className="employee-details-user-data">Location</h5>
                    <h5 className="employee-details-user-data">
                      {" "}
                      <span>:</span>&nbsp;calicut
                    </h5>
                  </li>
                </ul>
              </div>
              <div>
                <ul>
                  <li>
                    <h5 className="employee-details-user-data">Employee ID</h5>
                    <h5 className="employee-details-user-data">
                      {" "}
                      <span>:</span>&nbsp;GSHDA14525
                    </h5>
                  </li>
                  <li>
                    <h5 className="employee-details-user-data">Function</h5>
                    <h5 className="employee-details-user-data">
                      {" "}
                      <span>:</span>&nbsp;Finance
                    </h5>
                  </li>
                  <li>
                    <h5 className="employee-details-user-data">
                      Date of joining
                    </h5>
                    <h5 className="employee-details-user-data">
                      {" "}
                      <span>:</span>&nbsp;19-07-2022
                    </h5>
                  </li>
                </ul>
              </div>
            </div>
            <div className="attendance-value-table">
              <table id="attendance-table">
                <thead className="attendance-head">
                  <tr>
                    <th>Attendance</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody className="attendance-body">
                  <tr>
                    <td>Over Time</td>
                    <td>48 hrs</td>
                  </tr>
                  <tr>
                    <td>Present</td>
                    <td>23 days</td>
                  </tr>
                  <tr>
                    <td>Leave</td>
                    <td>2 days</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr className="pay-in-slip-employee-hr" />
            <div className="earnings-and-deduction attendance-value-table">
              <table id="earnings-and-deduction-table">
                <thead className="earnings-and-deduction-head">
                  <tr id="main-head">
                    <th colSpan={2}>Earnings</th>
                    <th colSpan={2}>Deduction</th>
                  </tr>
                  <tr id="sub-head">
                    <th>Pay Type</th>
                    <th>Amount</th>
                    <th>Pay Type</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody className="earnings-and-deduction-body">
                  {renderData.map((data, i) => (
                    <React.Fragment key={i}>
                      {data.earnings.map((earning, j) => (
                        <tr key={j}>
                          <td className="earnings">{earning.payType}</td>
                          <td className="earnings">{earning.amount}</td>
                          {data.deduction[j] ? (
                            <>
                              <td className="earnings">
                                {data.deduction[j].payType}
                              </td>
                              <td className="deduction">
                                {data.deduction[j].amount}
                              </td>
                            </>
                          ) : (
                            // If no deduction is available for this earning, leave the cells empty
                            <>
                              <td className="earnings"></td>
                            </>
                          )}
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                  <tr>
                    <td className="earnings table-total">Total Earnings</td>
                    <td className="earnings table-total">Rs21,600.00</td>
                    <td className="earnings table-total">Total Deduction</td>
                    <td className="table-total">Rs1,000.00</td>
                  </tr>
                </tbody>
              </table>
              <div className="payroll-payslip-container-body-total">
                <div className="payroll-payslip-container-body-total-section">
                  <h3>Net Salary</h3>
                  <p>Inwords</p>
                </div>
                <div
                  className="payroll-payslip-container-body-total-section"
                  style={{ width: "75%" }}
                >
                  <h3>{`Rs ${netSalary}`}</h3>
                  <p>{numToWords(netSalary)?.toUpperCase()}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="payroll-payslip-container-body-footer">
            <h4>Company Address</h4>
            <p>Lorem Ipsum is simply dummy text of the </p>
            <p>printing and typesetting industry.</p>
            <p>Lorem Ipsum</p>
          </div>
        </div>
        {/* print view  */}
        <div style={{ display: "none" }}>
          <div
            id="print-slip"
            ref={componentRef}
            className="pay-in-slip-employee-wrapper "
          >
            <div className="slip-header">
              <h3 className="title">Pay In Slip Employee </h3>
            </div>
            <hr className="global-hr" />
            <div className="slip-details">
              <div className="employee-details">
                <div>
                  <ul>
                    <li>
                      <h5 className="employee-details-user-data">
                        Employee Name
                      </h5>
                      <h5 className="employee-details-user-data">
                        {" "}
                        <span>:</span>&nbsp;john
                      </h5>
                    </li>
                    <li>
                      <h5 className="employee-details-user-data">
                        Designation
                      </h5>
                      <h5 className="employee-details-user-data">
                        {" "}
                        <span>:</span>&nbsp;Accountant
                      </h5>
                    </li>
                    <li>
                      <h5 className="employee-details-user-data">Location</h5>
                      <h5 className="employee-details-user-data">
                        {" "}
                        <span>:</span>&nbsp;calicut
                      </h5>
                    </li>
                  </ul>
                </div>
                <div>
                  <ul>
                    <li>
                      <h5 className="employee-details-user-data">
                        Employee ID
                      </h5>
                      <h5 className="employee-details-user-data">
                        {" "}
                        <span>:</span>&nbsp;GSHDA14525
                      </h5>
                    </li>
                    <li>
                      <h5 className="employee-details-user-data">Function</h5>
                      <h5 className="employee-details-user-data">
                        {" "}
                        <span>:</span>&nbsp;Finance
                      </h5>
                    </li>
                    <li>
                      <h5 className="employee-details-user-data">
                        Date of joining
                      </h5>
                      <h5 className="employee-details-user-data">
                        {" "}
                        <span>:</span>&nbsp;19-07-2022
                      </h5>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="employee-bank-details employee-details">
                <div>
                  <ul>
                    <li>
                      <h5 className="employee-details-user-data">
                        Employee Name
                      </h5>
                      <h5 className="employee-details-user-data">
                        {" "}
                        <span>:</span>&nbsp;john
                      </h5>
                    </li>
                    <li>
                      <h5 className="employee-details-user-data">
                        Designation
                      </h5>
                      <h5 className="employee-details-user-data">
                        {" "}
                        <span>:</span>&nbsp;Accountant
                      </h5>
                    </li>
                    <li>
                      <h5 className="employee-details-user-data">Location</h5>
                      <h5 className="employee-details-user-data">
                        {" "}
                        <span>:</span>&nbsp;calicut
                      </h5>
                    </li>
                  </ul>
                </div>
                <div>
                  <ul>
                    <li>
                      <h5 className="employee-details-user-data">
                        Employee ID
                      </h5>
                      <h5 className="employee-details-user-data">
                        {" "}
                        <span>:</span>&nbsp;GSHDA14525
                      </h5>
                    </li>
                    <li>
                      <h5 className="employee-details-user-data">Function</h5>
                      <h5 className="employee-details-user-data">
                        {" "}
                        <span>:</span>&nbsp;Finance
                      </h5>
                    </li>
                    <li>
                      <h5 className="employee-details-user-data">
                        Date of joining
                      </h5>
                      <h5 className="employee-details-user-data">
                        {" "}
                        <span>:</span>&nbsp;19-07-2022
                      </h5>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="attendance-value-table">
                <table id="attendance-table">
                  <thead className="attendance-head">
                    <tr>
                      <th>Attendance</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody className="attendance-body">
                    <tr>
                      <td>Over Time</td>
                      <td>48 hrs</td>
                    </tr>
                    <tr>
                      <td>Present</td>
                      <td>23 days</td>
                    </tr>
                    <tr>
                      <td>Leave</td>
                      <td>2 days</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <hr className="pay-in-slip-employee-hr" />
              <div className="earnings-and-deduction attendance-value-table">
                <table id="earnings-and-deduction-table">
                  <thead className="earnings-and-deduction-head">
                    <tr id="main-head">
                      <th colSpan={2}>Earnings</th>
                      <th colSpan={2}>Deduction</th>
                    </tr>
                    <tr id="sub-head">
                      <th>Pay Type</th>
                      <th>Amount</th>
                      <th>Pay Type</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody className="earnings-and-deduction-body">
                    {renderData.map((data, i) => (
                      <React.Fragment key={i}>
                        {data.earnings.map((earning, j) => (
                          <tr key={j}>
                            <td className="earnings">{earning.payType}</td>
                            <td className="earnings">{earning.amount}</td>
                            {data.deduction[j] ? (
                              <>
                                <td className="earnings">
                                  {data.deduction[j].payType}
                                </td>
                                <td className="deduction">
                                  {data.deduction[j].amount}
                                </td>
                              </>
                            ) : (
                              // If no deduction is available for this earning, leave the cells empty
                              <>
                                <td className="earnings"></td>
                              </>
                            )}
                          </tr>
                        ))}
                      </React.Fragment>
                    ))}
                    <tr>
                      <td className="earnings table-total">Total Earnings</td>
                      <td className="earnings table-total">Rs21,600.00</td>
                      <td className="earnings table-total">Total Deduction</td>
                      <td className="table-total">Rs1,000.00</td>
                    </tr>
                  </tbody>
                </table>
                <div className="payroll-payslip-container-body-total">
                  <div className="payroll-payslip-container-body-total-section">
                    <h3>Net Salary</h3>
                    <p>Inwords</p>
                  </div>
                  <div
                    className="payroll-payslip-container-body-total-section"
                    style={{ width: "75%" }}
                  >
                    <h3>{`Rs ${netSalary}`}</h3>
                    <p>{numToWords(netSalary)?.toUpperCase()}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="payroll-payslip-container-body-footer">
              <h4>Company Address</h4>
              <p>Lorem Ipsum is simply dummy text of the </p>
              <p>printing and typesetting industry.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayInSlipEmployee;
