import { createSlice } from "@reduxjs/toolkit";

export const allReadymadeBrandSlice=createSlice({
    name:'readymadeBrandSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_readymade_brand_list:(state,action)=>{
            state.value=action.payload.readymadeBrand
        }
    }
})

export const {update_readymade_brand_list}=allReadymadeBrandSlice.actions
export default allReadymadeBrandSlice.reducer