import React from "react";
import InputBox from "../../../components/InputBox";
import DropdownInputBox from "../../../components/DropdownInputBox";
import TableBox from "../../../components/TableBox";

const PurchaseReturnVoucher = ({ selectedVoucher }) => {
  const heading = [
    "Item",
    " HSN/ SAC Code",
    "Stock Qty",
    "Qty",
    "Unit",
    "Rate",
    "Total",
    "Paid",
    "Amount",
  ];

  const renderData = [
    {
      item: "gfh",
      hsnSasCode: 555454,
      StockQty: 20,
      qty: 454,
      unit: "KG",
      rate: 599,
      total: 699,
      paid: 699,
      amount: 500,
    },
    {
      item: "gfh",
      hsnSasCode: 555454,
      StockQty: 20,
      qty: 454,
      unit: "KG",
      rate: 599,
      total: 699,
      paid: 699,
      amount: 500,
    },
    {
      item: "kdfgh",
      hsnSasCode: 555454,
      StockQty: 20,
      qty: 454,
      unit: "KG",
      rate: 599,
      total: 699,
      paid: 699,
      amount: 500,
    },
    {
      item: "njd",
      hsnSasCode: 555454,
      StockQty: 20,
      qty: 454,
      unit: "KG",
      rate: 599,
      total: 699,
      paid: 699,
      amount: 500,
    },
    {
      item: "hnfdg",
      hsnSasCode: 555454,
      StockQty: 20,
      qty: 454,
      unit: "KG",
      rate: 599,
      total: 699,
      paid: 699,
      amount: 500,
    },
    {
      item: "gfh",
      hsnSasCode: 555454,
      StockQty: 20,
      qty: 454,
      unit: "KG",
      rate: 599,
      total: 699,
      paid: 699,
      amount: 500,
    },
  ];
  return (
    <div className="purchase-return-voucher">
      <div className="accounting-voucher-menu-sections">
        <h3>{selectedVoucher}</h3>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "1em 0" }}>
          <InputBox label={"Invoice No"} />
          <InputBox label={"Invoice No"} />
          <InputBox label={"Date"} type={"date"} />
          <DropdownInputBox
            labelName={"Supplier"}
            isCurrentBalance={true}
            currentBalance={500}
          />
          <InputBox
            isCurrentBalance={true}
            currentBalance={2000}
            label={"Ledger Account"}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
          className="button-container"
        >
          <div></div>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              style={{
                background: "#fff",
                border: "1px solid black",
                color: "black",
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
            <button
              style={{
                background: "#0011f8",
                border: "none",
                color: "white",
              }}
              className="submit-btn"
            >
              Submit
            </button>
          </div>
          <div></div>
        </div>
      </div>

      <div className="accounting-voucher-menu-sections">
        <TableBox
          headings={heading}
          isLoading={false}
          actionButton={true}
          renderData={renderData}
          actionBtnIconEdit={true}
          actionBtnIconDelete={true}
        />

        <div style={{ background: "#edeef0", padding: "5px" }}>
          <div className="item-purchase-input-data-container">
            <div>
              <div className="gst-input-field ">
                <div>
                  <h5>IGST</h5>
                  <InputBox />
                </div>
                <div>
                  <h5>SGST</h5>
                  <InputBox />
                </div>
                <div>
                  <h5>CGST</h5>
                  <InputBox />
                </div>
              </div>
              <div className="gst-input-field">
                <div>
                  <h5>Discount</h5>
                  <InputBox />
                </div>
              </div>
            </div>
            <div>
              <div className="gst-input-field">
                <div>
                  <h5>Total Balance</h5>
                  <InputBox containerStyle={{ width: "45%" }} />
                </div>
                <div>
                  <h5>Fright / Other Expense</h5>
                  <InputBox containerStyle={{ width: "45%" }} />
                </div>
                <div>
                  <h5>Cust / Adv Tax</h5>
                  <InputBox containerStyle={{ width: "45%" }} />
                </div>
                <div>
                  <h5>CESS</h5>
                  <InputBox containerStyle={{ width: "45%" }} />
                </div>
                <div>
                  <h5>Education & Component CESS</h5>
                  <InputBox containerStyle={{ width: "45%" }} />
                </div>
                <div>
                  <h5>Round Off</h5>
                  <InputBox containerStyle={{ width: "45%" }} />
                </div>
                <div>
                  <h3 style={{ flex: "50% 1" }}>Grand Total</h3>
                  <InputBox containerStyle={{ width: "45%" }} />
                </div>
                <div>
                  <h5>Paid Amount</h5>
                  <InputBox containerStyle={{ width: "45%" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
          className="button-container"
        >
          <div></div>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              style={{
                background: "#fff",
                border: "1px solid black",
                color: "black",
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
            <button
              style={{
                background: "#0011f8",
                border: "none",
                color: "white",
              }}
              className="submit-btn"
            >
              Submit
            </button>
          </div>
          <button
            style={{ background: "#f38a00", border: "none", color: "white" }}
            className="save-draft-btn"
          >
            Save Draft
          </button>
        </div>
      </div>
    </div>
  );
};

export default PurchaseReturnVoucher;
