import {createSlice} from '@reduxjs/toolkit'

export const viewOrderInvoiceNoSlice=createSlice({
    name:'viewOrderInvoiceNoSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_view_order_invoice_no:(state,action)=>{
            state.value=action.payload.viewOrderInvoiceNoData
        }
    }
})
export const {get_view_order_invoice_no} = viewOrderInvoiceNoSlice.actions;
export default viewOrderInvoiceNoSlice.reducer