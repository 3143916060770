import React, { useEffect, useState } from "react";
import DropdownInputBox from "../DropdownInputBox";
import natureOfTransactionOption from "../../../../../Assets/JSON/ledgerNatureOfTransactionOption.json";
import natureOfGoodsOption from "../../../../../Assets/JSON/ledgerNatureOfGoodsOptions.json";
import taxTypeOption from "../../../../../Assets/JSON/ledgerTaxTypeOption.json";
import typeOfSupplyOption from "../../../../../Assets/JSON/ledgerTypeOfSupply.json";
import gstOption from "../../../../../Assets/JSON/ledgerGstOption.json";
import { useSelector, useDispatch } from "react-redux";
import {
  setGst,
  setNatureOfTransaction,
  setNatureOfGoods,
  setTaxType,
  setGstTaxType,
  setGstRate,
  setRate,
  setTypeOfSupply,
  setOpeningBalance,
} from "../../../../../Redux/Account/fixedAssetSlice";
import SubmitBtn from "../SubmitBtn";
import InputBox from "../InputBox";

const FixedAsset = () => {
  const dispatch = useDispatch();
  const {
    gst,
    natureOfTransaction,
    natureOfGoods,
    taxType,
    gstTaxType,
    rate,
    gstRate,
    typeOfSupply,
    openingBalance,
  } = useSelector((state) => state.fixedAssetSlice);
  console.log(gst);

  const value = useSelector((state) => state.fixedAssetSlice);
  console.log(value);

  const fixedAssetInputData = useSelector((state) => state.fixedAssetSlice);

  const handleFixedAssetSubmit = () => {
    console.log(fixedAssetInputData);
  };
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    const option = gst?.option;
    if (option === "GST Applicable") {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [gst?.option]);

  return (
    <>
      <div style={{ padding: "2% 4%" }} className="bottom-line">
        {/*Opening Balance*/}
        <InputBox
          label={"Opening Balance"}
          value={openingBalance}
          onChange={(e) => dispatch(setOpeningBalance(e.target.value))}
          containerStyle={{width:'31%'}}

        />
      </div>

      <div
        style={{ padding: "2% 4%" }}
        className="ledgerSection-input-container"
      >
        <div>
          {/* gst input-box */}
          <DropdownInputBox
            options={gstOption}
            value={gst}
            labelName={"GST"}
            onChange={(e, value) => {
              console.log(value);
              dispatch(setGst(value));
            }}
          />

          {/* Nature of Transaction */}

          <DropdownInputBox
            options={natureOfTransactionOption}
            value={natureOfTransaction}
            labelName={"Nature of Transaction"}
            onChange={(e, value) => dispatch(setNatureOfTransaction(value))}
            disabled={disable}
          />
          {/* Nature of Goods */}

          <DropdownInputBox
            options={natureOfGoodsOption}
            value={natureOfGoods}
            labelName={"Nature of Goods"}
            onChange={(e, value) => dispatch(setNatureOfGoods(value))}
            disabled={disable}
          />
        </div>

        <div>
          <div className="tax-rate-input-section">
            {/* gst Tax Type */}
            <div style={{ width: "15em" }}>
              <DropdownInputBox
                options={taxTypeOption}
                value={gstTaxType}
                labelName={"GST Tax Type"}
                onChange={(e, value) => dispatch(setGstTaxType(value))}
                style={{ width: "13.5em" }}
                disabled={disable}
              />
            </div>
            {/*gst  Rate */}
            <div
              className="global-single-input add-purchase-input auto-complete"
              style={{ width: "5em" }}
            >
              <p>GST Rate(%)</p>
              <input
                type="number"
                value={gstRate}
                onChange={(e) => dispatch(setGstRate(e.target.value))}
                disabled={disable}
              />
            </div>
          </div>
          <div className="tax-rate-input-section">
            {/*  Tax Type */}
            <div style={{ width: "15em" }}>
              <DropdownInputBox
                options={taxTypeOption}
                value={taxType}
                labelName={"Tax Type"}
                onChange={(e, value) => dispatch(setTaxType(value))}
                style={{ width: "14em" }}
                disabled={disable}
              />
            </div>

            {/* Rate */}
            <div
              className="global-single-input add-purchase-input auto-complete"
              style={{ width: "5em" }}
            >
              <p>Rate(%)</p>
              <input
                type="number"
                value={rate}
                onChange={(e) => dispatch(setRate(e.target.value))}
                disabled={disable}
              />
            </div>
          </div>
        </div>

        <div>
          {/* Type of Supply */}
          <DropdownInputBox
            options={typeOfSupplyOption}
            value={typeOfSupply}
            labelName={"Type of Supply"}
            onChange={(e, value) => dispatch(setTypeOfSupply(value))}
            disabled={disable}
            containerStyle={{width:'31%'}}

          />
        </div>
        {/* submitButton */}
        <SubmitBtn
          onClick={handleFixedAssetSubmit}
          disabled={disable}
          buttonStyle={{ transition: "0.3s all ease-in-out" }}
        />
      </div>
    </>
  );
};

export default FixedAsset;
