import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  CurrencyUpdatedFilterAPICall,
  addCurrencyUpdated,
  editCurrecyUpdated
} from "../../../../API/Settings/Currency/CurrencyAPI";
import { today } from "../../../../Js/Date";
import { Autocomplete, IconButton, TextField, Tooltip } from "@mui/material";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import { filterObjFromList } from "../../../../Js/generalFunctions";
import countryList from "../../../../Assets/JSON/countryListWithCode.json"
import CategorySearchandFilter from "../../../Single Components/CategorySearchandFilter";
import CustomPagination from "../../../Single Components/CustomPagination";
export const CurrencyUpdated = () => {
  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.filteredCurrencyList
  );
  const [createCurrency, setCreateCurrency] = useState(false);
  const createCurrencyInitialState = {
    name: "",
    symbol: "",
    country: null,
    currencySubUnit: "",
    currencyUnit: "",
    currencyCode: "",
    conversationUnitPerUst: "",
    USTPerUnit: "",
    status: false,
    symbolPositions: null,
  };
  const [createCurrencyForm, setCreateCurrencyForm] = useState(
    createCurrencyInitialState
  );
  const sumbolPositions = [{ pos: "Left", value: false }, { pos: "Right", value: true }]
  const [editedId, setEditedId] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isModify, setIsModify] = useState(false);
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("Error!!");
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = currencyListUpdated?.pages;
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [isLoading, setIsLoading] = useState(false)


  const [isEdit, setIsEdit] = useState(false)


  const updateListner = () => {
    setIsModify(!isModify);
  };

  // snackbar function
  const closeSuccessSnackbar = () => {
    setSuccessSnackOpen(false);
  };

  const closeErrorSnackbar = () => {
    setErrorSnackOpen(false);
  };

  const createCurrencyFn = () => {
    clearState();
    setCreateCurrency(true);
    setIsDisabled(true);

    setIsEdit(false)

  };

  const createCurrencyChangeFn = (key) => (e, newValue) => {
    if (key === "name") {
      setCreateCurrencyForm({ ...createCurrencyForm, name: e.target.value });
    }
    if (key === "symbol") {
      setCreateCurrencyForm({ ...createCurrencyForm, symbol: e.target.value });
    }
    if (key === "country") {
      setCreateCurrencyForm({ ...createCurrencyForm, country: newValue });

    }
    if (key === "currencySubUnit") {
      setCreateCurrencyForm({
        ...createCurrencyForm,
        currencySubUnit: e.target.value,
      });
    }
    if (key === "currencyUnit") {
      setCreateCurrencyForm({
        ...createCurrencyForm,
        currencyUnit: e.target.value,
      });
    }
    if (key === "currencyCode") {
      setCreateCurrencyForm({
        ...createCurrencyForm,
        currencyCode: e.target.value,
      });
    }
    if (key === "conversationUnitPerUST") {
      setCreateCurrencyForm({
        ...createCurrencyForm,
        conversationUnitPerUst: e.target.value,
      });

    }
    if (key === "USTPerUnit") {
      setCreateCurrencyForm({
        ...createCurrencyForm,
        USTPerUnit: e.target.value,
      });
    }

    if (key === "status") {
      setCreateCurrencyForm({
        ...createCurrencyForm,
        status: e.target.checked,
      });
    }
    if (key === "symbolPosition") {
      setCreateCurrencyForm({
        ...createCurrencyForm,
        symbolPositions: newValue
      })
    }
  };
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };

  useEffect(() => {
    let ustPerUnit = (1 / createCurrencyForm?.conversationUnitPerUst)
    setCreateCurrencyForm({ ...createCurrencyForm, USTPerUnit: ustPerUnit })
  }, [createCurrencyForm?.conversationUnitPerUst])

  const currencyBody = {
    name: createCurrencyForm?.name,
    symbol: createCurrencyForm?.symbol,
    currencySubUnit: createCurrencyForm?.currencySubUnit,
    currencyUnit: createCurrencyForm?.currencyUnit,
    currencyCode: createCurrencyForm?.currencyCode,
    lastUpdatedDate: today,
    currencyRate: Number(createCurrencyForm?.conversationUnitPerUst),
    usdperunit: createCurrencyForm?.USTPerUnit,
    status: createCurrencyForm?.status,
    symbolPosition: createCurrencyForm?.symbolPositions?.value,
    country: createCurrencyForm?.country?.name
  };
  const submitCurrencyFn = () => {
    addCurrencyUpdated(
      currencyBody,
      clearState,
      updateListner,
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg
    );
  };

  const clearState = () => {
    setCreateCurrencyForm(createCurrencyInitialState);
  };
  const filterObjFromListt = (key, list, keyTomatch, listToFilter) => {
    let filteredArray = []
    filteredArray = list?.filter((obj) =>
      obj[key] === listToFilter[keyTomatch]
    )
    return filteredArray?.length !== 0 && filteredArray !== undefined ? filteredArray[0] : [];
  }


  const singleRowClickFn = (data) => () => {

    setIsEdit(true)

    setCreateCurrency(true);
    setIsDisabled(false);
    setEditedId(data?._id);
    setCreateCurrencyForm({
      ...createCurrencyForm,
      name: data?.name,
      symbol: data?.symbol,
      currencySubUnit: data?.currencySubUnit,
      currencyUnit: data?.currencyUnit,
      currencyCode: data?.currencyCode,
      conversationUnitPerUst: data?.currencyRate,
      USTPerUnit: data?.usdperunit,
      status: data?.status,
      symbolPositions: filterObjFromListt("value", sumbolPositions, "symbolPosition", data),
      country: filterObjFromList("name", countryList, "country", data)
    });
  };

  const editBtnFn = () => {
    setIsDisabled(true);
  };

  const editBody = {
    name: createCurrencyForm?.name,
    symbol: createCurrencyForm?.symbol,
    currencySubUnit: createCurrencyForm?.currencySubUnit,
    currencyUnit: createCurrencyForm?.currencyUnit,
    currencyCode: createCurrencyForm?.currencyCode,
    lastUpdatedDate: today,
    currencyRate: createCurrencyForm?.conversationUnitPerUst,
    usdperunit: createCurrencyForm?.USTPerUnit,
    status: createCurrencyForm?.status,
    _id: editedId,
    symbolPosition: createCurrencyForm?.symbolPositions?.value,
    country: createCurrencyForm?.country?.name
  };

  const editSubmitFn = () => {
    editCurrecyUpdated(
      editBody,
      updateListner,
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg
    );
  };
  const backButtonFunction = () => {
    setCreateCurrency(false);
  };
  useEffect(() => {
    if (FormDataInfo.length !== 0) {
      CurrencyUpdatedFilterAPICall({
        status: FormDataInfo.status.length !== 0 ? (FormDataInfo.status[0] === "ACTIVE" ? true : false) : null,
        search: FormDataInfo.search,
        index: currentPage - 1,
      }, setIsLoading);
    }
  }, [FormDataInfo,currentPage]);

  return (
    <>
      {createCurrency === true ? (
        <>
          <div
            className="global-white-bg-container"
            style={{ margin: "1% 1%", borderRadius: "4px" }}
          >
            <Tooltip title="Back">
              <IconButton onClick={backButtonFunction}>
                <i class="bi bi-chevron-left"></i>
              </IconButton>
            </Tooltip>

            {
              isEdit === true &&
              <Tooltip title="Edit">
                <IconButton onClick={isDisabled ? editSubmitFn : editBtnFn}>

                  {isDisabled ? (
                    <i
                      class="bi bi-check2-square edit-icon"
                      style={{ fontSize: "1.5rem" }}
                    ></i>
                  ) : (
                    <i class="bi bi-pencil-square edit-icon1"></i>
                  )}
                </IconButton>
              </Tooltip>

            }
            {
              isEdit === false &&
              <Tooltip title="Save">
                <IconButton
                  onClick={submitCurrencyFn}
                >
                  <i class="bi bi-floppy"></i>
                </IconButton>
              </Tooltip>
            }


          </div>
          <div
            className="utility-settings-menu-container"
            style={{
              padding: "0px 4px",
              flexDirection: "row",
              flexWrap: "wrap",
              width: "98%",
            }}
          >
            <div className="global-single-input currency-updated-single-input">
              <p>Name</p>
              <input
                type="text"
                value={createCurrencyForm?.name}
                onChange={createCurrencyChangeFn("name")}
                disabled={isDisabled === false}
              />
            </div>
            <div className="global-single-input currency-updated-single-input">
              <p>Symbol</p>
              <input
                type="text"
                value={createCurrencyForm?.symbol}
                onChange={createCurrencyChangeFn("symbol")}
                disabled={isDisabled === false}
              />

            </div>
            <div className="global-single-input currency-updated-single-input auto-complete">
              <p>Country</p>
              <Autocomplete
                options={countryList || []}
                getOptionLabel={(option) => option?.name}
                renderInput={(params) => (
                  <TextField {...params} placeholder="select" />
                )}
                value={createCurrencyForm?.country}
                onChange={createCurrencyChangeFn("country")}
                disabled={isDisabled === false}

              />
            </div>

            <div className="global-single-input currency-updated-single-input">
              <p>Currency Sub Unit</p>
              <input
                type="text"
                value={createCurrencyForm?.currencySubUnit}
                onChange={createCurrencyChangeFn("currencySubUnit")}
                disabled={isDisabled === false}
              />

            </div>
            <div className="global-single-input currency-updated-single-input">
              <p>Currency Unit</p>
              <input
                type="text"
                value={createCurrencyForm?.currencyUnit}
                onChange={createCurrencyChangeFn("currencyUnit")}
                disabled={isDisabled === false}
              />
            </div>
            <div className="global-single-input currency-updated-single-input">
              <p>Currency Code</p>
              <input
                type="text"
                value={createCurrencyForm?.currencyCode}
                onChange={createCurrencyChangeFn("currencyCode")}
                disabled={isDisabled === false}
              />
            </div>
            <div className="global-single-input currency-updated-single-input">
              <p>Unit Per USD</p>
              <input
                type="text"
                value={createCurrencyForm?.conversationUnitPerUst}
                onChange={createCurrencyChangeFn("conversationUnitPerUST")}
                disabled={isDisabled === false}
              />
            </div>
            <div className="global-single-input currency-updated-single-input">
              <p>USD Per Unit</p>
              <input
                type="number"
                value={createCurrencyForm?.USTPerUnit}
                disabled

              />
            </div>
            <div className="global-single-input currency-updated-single-input auto-complete">
              <p>Symbol Position</p>
              <Autocomplete
                options={sumbolPositions || []}
                getOptionLabel={(option) => option?.pos}
                renderInput={(params) => (
                  <TextField {...params} placeholder="select" />
                )}
                value={createCurrencyForm?.symbolPositions}
                onChange={createCurrencyChangeFn("symbolPosition")}
                disabled={isDisabled === false}

              />
            </div>

            <div className="global-single-input currency-updated-single-input">
              <p>Active/Inactive</p>
              <input
                className="toggle-checkbox"
                type="checkbox"
                checked={createCurrencyForm?.status}
                onChange={createCurrencyChangeFn("status")}
                disabled={isDisabled === false}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="utility-settings-container">
          <div
            className="utility-settings-menu-container"
            style={{ padding: "0px 4px" }}
          >
            <div className="justify-space-between"
              style={{ gap: "0 12px", justifyContent: "flex-end" }}
            >
              <CategorySearchandFilter
                statusOptions={['ACTIVE', 'INACTIVE']}
                onData={handleDataFromChild}
                isStatus={true}
                isBranch={false}
                isDate={false}
              />
              <button onClick={createCurrencyFn} className="create-button-blue">
                Create
              </button>
            </div>
            <div className="justify-center" style={{ justifyContent: "flex-end" }}>
              <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
            <div className="create-button-blue-container">
              {/* <button onClick={createCurrencyFn} className="create-button-blue">
                Create
              </button> */}
            </div>
            <div className="global-table-container">
              <table className="global-table">
                <thead>
                  <tr>
                    <th>Currency</th>
                    <th>Symbol</th>
                    <th>Name</th>
                    <th>Last Update</th>
                    <th>Currency Rate</th>
                    <th>Active</th>
                  </tr>
                </thead>
                <tbody>
                  {currencyListUpdated?.list?.map((r, i) => (
                    <tr
                      onClick={singleRowClickFn(r)}
                      style={{ cursor: "pointer" }}
                      title="View Details"
                    >
                      <td>{r?.currencyCode}</td>
                      <td>{r?.symbol}</td>
                      <td>{r?.name}</td>
                      <td>{r?.lastUpdatedDate}</td>
                      <td>{r?.currencyRate}</td>
                      <td>
                        <input
                          className="toggle-checkbox"
                          type="checkbox"
                          checked={r?.status}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

          </div>
        </div>
      )}


      {/* snackbar */}
      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />

      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </>
  );
};
