import React from "react";
import { useNavigate } from "react-router-dom";
import store from "../../../../../Redux/store";
import { get_purchase_w_po_row_data_list } from "./purchaseWPOlist";
import { useEffect } from "react";
import { PurchaseWOPOFilterAPICall, viewPurchaseWPOAPICall } from "./purchaseWPOAPI";
import { convertDateFormat, getCurrentTime } from "../../../../../Js/Date";
import { Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
import { useState } from "react";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import CustomPagination from "../../../../Single Components/CustomPagination";
import axios from "axios";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";

export const PurchaseWOPO1 = () => {
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const userRole = useSelector((state) => state.userRoleSlice.value);
  const userBranchId = localStorage.getItem("branchId");
  const [FormDataInfo, setFormDataInfo] = useState([])


  const tableListData = useSelector(
    (state) => state.purchaseWPOSlice.apiResList
  );

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const totalPages = tableListData?.pages;
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };

  let navigate = useNavigate();

  const createFunctuon = () => {
    navigate("/userdashboard/accounts/vendor/purchasewopo/create");
  };
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };

  const handleClickRow = (row) => {
    store.dispatch( setFilterActive(window.location.pathname))
    localStorage.setItem("singleViewById", row._id);
    // store.dispatch(get_purchase_w_po_row_data_list({purchaseWPOSingleData:row}))
    // navigate("/userdashboard/accounts/vendor/purchasewopo/create")
    navigate("/userdashboard/accounts/vendor/purchasewopo/SingleView");
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };

  useEffect(() => {
    localStorage.removeItem("singleViewById");
    store.dispatch(
      get_purchase_w_po_row_data_list({ purchaseWPOSingleData: undefined })
    );
    // if (userRole === "user") {
    //   viewPurchaseWPOAPICall({ branchId: userBranchId }, setIsLoading);
    // } else {
    //   viewPurchaseWPOAPICall({}, setIsLoading);
    // }
  }, []);
  console.log(FormDataInfo.status);
  useEffect(() => {
   
    if (FormDataInfo.length !== 0) {

      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData &&   PurchaseWOPOFilterAPICall({
        fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
        toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
        createdBy: FormDataInfo.createdBy,
        branchList: FormDataInfo.branchList,
        status: FormDataInfo.status,
        search: FormDataInfo.search,
        index: currentPage-1,
      }, setIsLoading);
    }
   
  }, [FormDataInfo, currentPage]);

  return (
    <div className="global-page-parent-container">
      {/* table */}
      <div className="global-white-bg-container">
        <div className="justify-space-between"
          style={{ gap: "0 12px",  display: 'flex', justifyContent: 'space-between', alignItems: 'end'}}
        >
          {FormDataInfo?.branchList?.length > 0 && (
              <div style={{ marginLeft: '1%' }}>
                <h3>Branch name: 
                  {selectedBranch?.storeCode||filtereDataOptions?.filterCategorylist?.branchList?.storeCode}
                  -{selectedBranch?.branchName||filtereDataOptions?.filterCategorylist?.branchList?.branchName}</h3>
              </div>
            )} 
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap:'9px',
              width: FormDataInfo?.branchList?.length ? '72%' : '100%'
            }}>
          <CategorySearchandFilter
            statusOptions={['DRAFT', 'POSTED', 'PAID']}
            onData={handleDataFromChild}
            isCreatedBy={true}
            isStatus={true}
            onBranchSelect={handleBranchSelect}
          />
          <button onClick={createFunctuon} className="create-button-blue">
            Create
          </button>
          </div>
        </div>
        <div className="create-button-blue-container" >
          <h4>Purchase W/PO</h4>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />

        </div>
        <div className="new-global-table-container">
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Vendor</th>
                <th>Bill Date</th>
                <th>Bill Reference</th>
                <th>Total</th>
                <th>Created By</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={8}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : tableListData?.list !== undefined && tableListData?.list?.length !== 0 ? (
                tableListData?.list
                  ?.slice(0)
                 
                  ?.map((r, i) => (
                    <tr onClick={() => handleClickRow(r)}>
                      <td>{r?.billDate}</td>
                      <td>{r?.sequenceName || r?.NAME}</td>
                      <td>{r?.vendorName}</td>
                      <td>{convertDateFormat(r?.billDate)}</td>
                      <td>{r?.billRef}</td>
                      <td>{r?.total || "---"}</td>
                      <td>{r?.CREATEDBY || "---"}</td>
                      <td className="table-data-box">
                        {r?.status === "DRAFT" ? (
                          <span className="draft"> DRAFT</span>
                        ) : r?.status === "POSTED" ? (
                          <span className="post"> POST</span>
                        ) : r?.status === "PAID" ? (
                          <span className='paid'>PAID</span>
                        ) : (
                          <span>{r?.status}</span>
                        )}
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan={8}>No Data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
