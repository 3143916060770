import { createSlice } from "@reduxjs/toolkit";

export const glCodeSlice=createSlice({
    name:'glCodeSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_glCode_list:(state,action)=>{
            state.value=action.payload.glCode
        }
    }
})

export const {update_glCode_list}=glCodeSlice.actions
export default glCodeSlice.reducer