import { createSlice } from "@reduxjs/toolkit";

export const salesQuatationSlice = createSlice({
  name: "salesQuatationSlice",
  initialState: {
    apiResList: undefined,
    rowData:undefined,
    generateName:undefined,
    quotationFilter:undefined,
    setTemplate:undefined,
  },
  reducers: {
    get_sales_order_quot_res_list: (state, action) => {
      state.apiResList = action.payload.orderQuotationData;
    },
    get_sales_order_quot_row_data_list: (state, action) => {
      state.rowData = action.payload.orderQuotationSingleData;
    },
    generate_sales_order_quot_name: (state, action) => {
      state.generateName = action.payload.salesQuotname;
    },
    generate_sales_filter_quot: (state, action) => {
      state.quotationFilter = action.payload.salesQuotfilter;
    },
    get_template_design:(state,action)=>{
      state.setTemplate=action.payload.setDesign;
    }
  },
});

export const {get_sales_order_quot_res_list,
    get_sales_order_quot_row_data_list,
    generate_sales_order_quot_name,
    generate_sales_filter_quot,
    get_template_design
} = salesQuatationSlice.actions;

export default salesQuatationSlice.reducer;
