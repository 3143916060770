import axios from "axios";
import { HEADERS } from "../../../../../API/UrlAndPaths";
import { get_api_res_list, get_sub_uom_list, get_uom_filter_by_purchaseuom, update_res_list_by_row } from "./unitOfMeasurmentSlice";
import store from "../../../../../Redux/store";

// list uom apicall
export const viewUOMListAPICall=async()=>{
    await axios
    .post(`purchase/viewActiveUom`, {}, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(get_api_res_list({resData:res.data}))
      }
    })
    .catch((err) => {
        store.dispatch(get_api_res_list({resData:undefined}))
    });
}
// add uom api call

export const addUnitOfMeasurmentAPICall=async(body,setState,state,setLoadingForm,clearFunction)=>{
  setLoadingForm!==undefined && setLoadingForm(true)
  await axios.post("purchase/createNewUom",body,HEADERS)
   .then((res)=>{
      if(res.status===200){
        clearFunction()
          setState({...state,message:"UOM Added",success:true})
          setLoadingForm!==undefined && setLoadingForm(false)
      }
   })
   .catch((err)=>{
      setLoadingForm!==undefined && setLoadingForm(false)

      if(err.response.status===409){
        setState({...state,message:"UOM Category already exist !!",error:true})

      }
      else{
        setState({...state,message:err?.response?.data?.msg,error:true})
      }
   })
}
// edit uom api call

export const editUnitOfMeasurmentAPICall=async(body,setState,state,setLoadingForm,setVisibleOnly)=>{
  setLoadingForm!==undefined && setLoadingForm(true)

    await axios.put("purchase/updateUomById",body,HEADERS)
     .then((res)=>{
        if(res.status===200){
            setState({...state,message:"Success",success:true})
            setLoadingForm!==undefined && setLoadingForm(false)
            setVisibleOnly(true)
        }
     })
     .catch((err)=>{
        setLoadingForm!==undefined && setLoadingForm(false)

        if(err.response.status===409){
          setState({...state,message:"UOM Category already exist !!",error:true})
        }
        else{
          setState({...state,message:err?.response?.data,error:true})
        }
     })
}

export const viewUOMSubListAPICall=async()=>{
  await axios
  .post(`purchase/listAllUoms`, {}, HEADERS)
  .then((res) => {
    if (res.status === 200) {
      store.dispatch(get_sub_uom_list({subUomData:res.data}))
    }
  })
  .catch((err) => {
      store.dispatch(get_sub_uom_list({subUomData:undefined}))
  });
}

export const filterUomByPurchseUomAPICall=(body)=>{
  axios.post("purchase/filteruombypurchaseuom",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
       store.dispatch(get_uom_filter_by_purchaseuom({filterByPurchseUOMData:res.data}))
    } 
  })
  .catch((err)=>{
    store.dispatch(get_uom_filter_by_purchaseuom({filterByPurchseUOMData:undefined}))
    
  })
}
export const filterUomByPurchseUomEditAPICall=(body,index,filter)=>{
  axios.post("purchase/filteruombypurchaseuom",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      //  store.dispatch(get_uom_filter_by_purchaseuom({filterByPurchseUOMData:res.data}))
       filter[index]=res.data
    } 
  })
  .catch((err)=>{
    store.dispatch(get_uom_filter_by_purchaseuom({filterByPurchseUOMData:undefined}))
    
  })
}

//  view uom by id
export const viewUOMByIdAPICall=(body)=>{
    axios.post("purchase/viewUomById",body,HEADERS)
    .then((res)=>{
      if(res.status===200){
        store.dispatch(update_res_list_by_row({singleRowData:res.data}))
      }
    })
    .catch((err)=>{
      store.dispatch(update_res_list_by_row({singleRowData:undefined}))
    })
}