import axios from "axios";
import { get_credit_summary } from "../../Redux/Credit and Wallet/creditSummarySlice";
import { update_credit_to_supplier } from "../../Redux/Credit and Wallet/creditToSupplierSlice";
import { update_wallet_of_customer } from "../../Redux/Credit and Wallet/walletOfCustomerSlice";
import { update_credit_of_customer } from "../../Redux/Customer/singleCustomerCreditSlice";
import { update_wallet_total_of_customer } from "../../Redux/Customer/singleCustomerWalletSlice";
import store from "../../Redux/store";
import { HEADERS } from "../UrlAndPaths";

//*Credit
//View All Credit of supplier
export const viewAllCreditAPICall = async (body,setIsLoading) => {
  if(setIsLoading !== undefined)  setIsLoading(true)
  
  await axios
    .post("credit/viewCredit", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_credit_to_supplier({ creditToSupplier: res.data })
          
        );
        if(setIsLoading !== undefined)  setIsLoading(false)
      }
    })
    .catch((err) => {
      store.dispatch(
        update_credit_to_supplier({ creditToSupplier: undefined })
        
      );
      if(setIsLoading !== undefined)  setIsLoading(false)
      console.error(err.response);
    });
};

// credit summary
export const viewAllCreditSummary= async (body)=>{
  await axios.post("credit/viewCreditSummary",body,HEADERS)
  .then((res)=>{
     if(res.status === 200){
      store.dispatch(get_credit_summary({creditSummaryData:res.data}))
      
     }
  })
  .catch((err)=>{
    console.log(err.response);
    store.dispatch(get_credit_summary({creditSummaryData:undefined}))
   
  })
}

//View Credits of Single customer
export const getCreditOfCustomerAPICall = async (body) => {
  await axios
    .post("customer/getcreditsofacustomer", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_credit_of_customer({ singleCusCredit: res.data })
        );
      }
    })
    .catch((err) => {
      store.dispatch(update_credit_of_customer({ singleCusCredit: undefined }));
      console.error(err.response);
    });
};

//*Wallet
//View All Wallet of a customer (for Wallet single page UI)
export const viewWalletByCustomerIdAPICall = async (body,setIsLoading) => {
  if(setIsLoading !== undefined)  setIsLoading(true)
  await axios
    .post("credit/viewWallet", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_wallet_of_customer({ walletOfCustomer: res.data })
        );
        if(setIsLoading !== undefined)  setIsLoading(false)
      }
    })
    .catch((err) => {
      store.dispatch(
        update_wallet_of_customer({ walletOfCustomer: undefined })
      );
      if(setIsLoading !== undefined)  setIsLoading(false)
      console.error(err.response);
    });
};

// view wallet search api 
export const viewWalletSearchListAPICall=async(body)=>{
  await axios.post("credit/searchWallet",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(
        update_wallet_of_customer({ walletOfCustomer: res.data })
      );
    }
  })
  .catch((err)=>{
    store.dispatch(
      update_wallet_of_customer({ walletOfCustomer: undefined })
    );
    console.log(err.response);
  })
}


//View total Wallet of Customer (for payments)
export const viewWalletTotalByCustomerIdAPICall = async (body) => {
  await axios
    .post("customer/viewwalletofcustomer", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_wallet_total_of_customer({singleCusWallet:res.data})
        );
      }
    })
    .catch((err) => {
      store.dispatch(
        update_wallet_total_of_customer({singleCusWallet:undefined})
      );
      console.error(err.response);
    });
};
