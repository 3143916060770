import { createSlice } from "@reduxjs/toolkit";

export const billOrderHeldProductsSlice=createSlice({
    name:"billOrderHeldProductSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        update_bill_order_held:(state,action)=>{
            state.value=action.payload.data
        }
    }
})

export const {update_bill_order_held} = billOrderHeldProductsSlice.actions
export default billOrderHeldProductsSlice.reducer