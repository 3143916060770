import { createSlice } from "@reduxjs/toolkit";

export const shiftTransferReopenDialogSlice=createSlice({
    name:'shiftTransferDialog',
    initialState:{
        value:undefined
    },
    reducers:{
        get_shiftTransfer_dialog:(state,action)=>{
            state.value=action.payload.shiftTransferDialogData
        }
    }
})
export const {get_shiftTransfer_dialog} = shiftTransferReopenDialogSlice.actions;
export default shiftTransferReopenDialogSlice.reducer