import { createSlice } from "@reduxjs/toolkit";

export const allActiveEmployeeSlice=createSlice({
    name:'allActiveEmployeeSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_all_active_employee_list:(state,action)=>{
            state.value=action.payload.activeEmployees
        }
    }
})

export const {update_all_active_employee_list}=allActiveEmployeeSlice.actions
export default allActiveEmployeeSlice.reducer