import { createSlice } from "@reduxjs/toolkit";
export const selectShiftSlice = createSlice({
  name: "selectShiftSlice",
  initialState: {
    value: 'addShiftButton',
  },
  reducers: {
    update_select_shift: (state, action) => {
      state.value = action.payload.selectShift;
    }
  },
});
export const { update_select_shift } = selectShiftSlice.actions;
export default selectShiftSlice.reducer;
