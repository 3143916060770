import React, { useState } from "react";
import "../../../../../css/Staff/HRM/ManageEmployee/viewEmployee.css";
import image from "../../../../../Assets/Images/man1.jpg";
import { Typography } from "@mui/material";
import { EmployeeDashboard } from "./Tabs/EmployeeDashboard";
import { EmpPersonalInfo } from "./Tabs/EmpPersonalInfo";
import { EmpCompanyInfo } from "./Tabs/EmpComapnyInfo";
import { EmpBankDetails } from "./Tabs/EmpBankDetails";
import { EmpLoanDetails } from "./Tabs/EmpLoanDetails";
import { EmpPayslip } from "./Tabs/EmpPayslip";
import { EmpLeaveDetails } from "./Tabs/EmpLeaveDetails";
import { EmpDocumentDetails } from "./Tabs/EmpDocumentDetails";
import { useEffect } from "react";
import { getSingleEmployeeAPICall } from "../../../../../API/Staff/HRM/employeeAPI";
import { useSelector } from "react-redux";


export const ViewEmployee = () => {
  const currentEmpId = localStorage.getItem("EMP_ID");
  //*redux state
  const singleEmpData = useSelector((state) => state.singleEmployeeSlice.value);
  //Currently active tab state
  const [activeTab, setActiveTab] = useState("dashboard");
  const [isModify,setIsModify]=useState(false)

  //UpdateListener
  const updateListener=()=>{
    setIsModify(!isModify)
  }

  useEffect(() => {
    getSingleEmployeeAPICall({ id: currentEmpId });
  }, [isModify]);

  const dashBoardMenuClick = () => {
    document.getElementById("dashboardBtn").classList.add("active");
    document.getElementById("personalInfoBtn").classList.remove("active");
    document.getElementById("companyInfoBtn").classList.remove("active");
    document.getElementById("bankDetailsBtn").classList.remove("active");
    document.getElementById("loanDetailsBtn").classList.remove("active");
    document.getElementById("leaveDetailsBtn").classList.remove("active");
    document.getElementById("paySlipsBtn").classList.remove("active");
    document.getElementById("documentBtn").classList.remove("active");
    setActiveTab("dashboard");
  };
  const personalInfoMenuClick = () => {
    document.getElementById("dashboardBtn").classList.remove("active");
    document.getElementById("personalInfoBtn").classList.add("active");
    document.getElementById("companyInfoBtn").classList.remove("active");
    document.getElementById("bankDetailsBtn").classList.remove("active");
    document.getElementById("loanDetailsBtn").classList.remove("active");
    document.getElementById("leaveDetailsBtn").classList.remove("active");
    document.getElementById("paySlipsBtn").classList.remove("active");
    document.getElementById("documentBtn").classList.remove("active");
    setActiveTab("personalInfo");
  };
  const companyInfoMenuClick = () => {
    document.getElementById("dashboardBtn").classList.remove("active");
    document.getElementById("personalInfoBtn").classList.remove("active");
    document.getElementById("companyInfoBtn").classList.add("active");
    document.getElementById("bankDetailsBtn").classList.remove("active");
    document.getElementById("loanDetailsBtn").classList.remove("active");
    document.getElementById("leaveDetailsBtn").classList.remove("active");
    document.getElementById("paySlipsBtn").classList.remove("active");
    document.getElementById("documentBtn").classList.remove("active");
    setActiveTab("companyInfo");
  };
  const bankDetailsMenuClick = () => {
    document.getElementById("dashboardBtn").classList.remove("active");
    document.getElementById("personalInfoBtn").classList.remove("active");
    document.getElementById("companyInfoBtn").classList.remove("active");
    document.getElementById("bankDetailsBtn").classList.add("active");
    document.getElementById("loanDetailsBtn").classList.remove("active");
    document.getElementById("leaveDetailsBtn").classList.remove("active");
    document.getElementById("paySlipsBtn").classList.remove("active");
    document.getElementById("documentBtn").classList.remove("active");
    setActiveTab("bankDetails");
  };
  const loanDetailsMenuClick = () => {
    document.getElementById("dashboardBtn").classList.remove("active");
    document.getElementById("personalInfoBtn").classList.remove("active");
    document.getElementById("companyInfoBtn").classList.remove("active");
    document.getElementById("bankDetailsBtn").classList.remove("active");
    document.getElementById("loanDetailsBtn").classList.add("active");
    document.getElementById("leaveDetailsBtn").classList.remove("active");
    document.getElementById("paySlipsBtn").classList.remove("active");
    document.getElementById("documentBtn").classList.remove("active");
    setActiveTab("loanDetails");
  };
  const leaveDetailsMenuClick = () => {
    document.getElementById("dashboardBtn").classList.remove("active");
    document.getElementById("personalInfoBtn").classList.remove("active");
    document.getElementById("companyInfoBtn").classList.remove("active");
    document.getElementById("bankDetailsBtn").classList.remove("active");
    document.getElementById("loanDetailsBtn").classList.remove("active");
    document.getElementById("leaveDetailsBtn").classList.add("active");
    document.getElementById("paySlipsBtn").classList.remove("active");
    document.getElementById("documentBtn").classList.remove("active");
    setActiveTab("leaveDetails");
  };
  const paySlipMenuClick = () => {
    document.getElementById("dashboardBtn").classList.remove("active");
    document.getElementById("personalInfoBtn").classList.remove("active");
    document.getElementById("companyInfoBtn").classList.remove("active");
    document.getElementById("bankDetailsBtn").classList.remove("active");
    document.getElementById("loanDetailsBtn").classList.remove("active");
    document.getElementById("leaveDetailsBtn").classList.remove("active");
    document.getElementById("paySlipsBtn").classList.add("active");
    document.getElementById("documentBtn").classList.remove("active");
    setActiveTab("paySlip");
  };
  const documentMenuClick = () => {
    document.getElementById("dashboardBtn").classList.remove("active");
    document.getElementById("personalInfoBtn").classList.remove("active");
    document.getElementById("companyInfoBtn").classList.remove("active");
    document.getElementById("bankDetailsBtn").classList.remove("active");
    document.getElementById("loanDetailsBtn").classList.remove("active");
    document.getElementById("leaveDetailsBtn").classList.remove("active");
    document.getElementById("paySlipsBtn").classList.remove("active");
    document.getElementById("documentBtn").classList.add("active");
    setActiveTab("document");
  };
  return (
    <div className="global-page-parent-container">
      <p className="breadcrumb">
        Staff &gt; HRM &gt; Manage Employee &gt; View Employee{" "}
      </p>

      <div className="manage-employee-view-employee-top-container">
        <div className="manage-employee-view-employee-top-left-container">
          <div className="view-employee-employee-image">
            <img src={singleEmpData?.imageUrl} alt="employee" />
          </div>
          <h4>{singleEmpData?.staff_name}</h4>
          <p>{singleEmpData?.designationName}</p>
        </div>
        <div className="manage-employee-view-employee-top-right-container">
          <div className="view-employee-top-right-sub-container">
            <h4>Attendance</h4>
            <div className="view-employee-top-right-attendence">
              <h5>January</h5>
              <p>
                26 <span>/31</span>
              </p>
            </div>
          </div>
          <div className="view-employee-top-right-sub-container">
            <h4>Leaves</h4>
            <div className="view-employee-top-right-attendence">
              <h5>2022</h5>
              <p style={{ color: "red" }}>
                0 <span>/50</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* tabs */}

      <div className="company-settings-container">
        <div className="company-settings-tab-container view-employee-tab-container">
          <div className="company-settings-tab-menu-container">
            <div className="company-settings-tab-menus-container">
              <div
                className="tab-menu-button active"
                id="dashboardBtn"
                onClick={dashBoardMenuClick}
              >
                <Typography fontSize={"0.9rem"} fontWeight={600}>
                  Dashboard
                </Typography>
              </div>
              <div
                className="tab-menu-button"
                id="personalInfoBtn"
                onClick={personalInfoMenuClick}
              >
                <Typography fontSize={"0.9rem"} fontWeight={600}>
                  Personal Info
                </Typography>
              </div>
              <div
                className="tab-menu-button"
                id="companyInfoBtn"
                onClick={companyInfoMenuClick}
              >
                <Typography fontSize={"0.9rem"} fontWeight={600}>
                  Company Info
                </Typography>
              </div>
              <div
                className="tab-menu-button"
                id="bankDetailsBtn"
                onClick={bankDetailsMenuClick}
              >
                <Typography fontSize={"0.9rem"} fontWeight={600}>
                  Bank Details
                </Typography>
              </div>
              <div
                className="tab-menu-button"
                id="loanDetailsBtn"
                onClick={loanDetailsMenuClick}
              >
                <Typography fontSize={"0.9rem"} fontWeight={600}>
                  Loan Details
                </Typography>
              </div>
              <div
                className="tab-menu-button"
                id="leaveDetailsBtn"
                onClick={leaveDetailsMenuClick}
              >
                <Typography fontSize={"0.9rem"} fontWeight={600}>
                  Leave Details
                </Typography>
              </div>
              <div
                className="tab-menu-button"
                id="paySlipsBtn"
                onClick={paySlipMenuClick}
              >
                <Typography fontSize={"0.9rem"} fontWeight={600}>
                  Payslip
                </Typography>
              </div>
              <div
                className="tab-menu-button"
                id="documentBtn"
                onClick={documentMenuClick}
              >
                <Typography fontSize={"0.9rem"} fontWeight={600}>
                  Document Details
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div>
          {activeTab === "dashboard" ? (
            <EmployeeDashboard />
          ) : activeTab === "personalInfo" ? (
            <EmpPersonalInfo singleEmpData={singleEmpData} updateListener={updateListener}/>
          ) : activeTab === "companyInfo" ? (
            <EmpCompanyInfo singleEmpData={singleEmpData} updateListener={updateListener}/>
          ) : activeTab === "bankDetails" ? (
            <EmpBankDetails singleEmpData={singleEmpData} updateListener={updateListener}/>
          ) : activeTab === "loanDetails" ? (
            <EmpLoanDetails singleEmpData={singleEmpData} updateListener={updateListener} />
          ) : activeTab === "leaveDetails" ? (
            <EmpLeaveDetails />
          ) : activeTab === "paySlip" ? (
            <EmpPayslip />
          ) : activeTab === "document" ? (
            <EmpDocumentDetails singleEmpData={singleEmpData} updateListener={updateListener} />
          ) : undefined}
        </div>
      </div>
    </div>
  );
};
