import {createSlice} from '@reduxjs/toolkit'

export const dashboardProductListSlice=createSlice({
    name:"dashboardProductListSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        get_dashboard_product_list:(state,action)=>{
            state.value=action.payload.dashboardProductList
        }
    }
})
export const {get_dashboard_product_list} =dashboardProductListSlice.actions;
export default dashboardProductListSlice.reducer