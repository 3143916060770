import { Dialog, IconButton, Skeleton } from "@mui/material";
import React from "react";
import { useEffect } from "react";
// css from"staffLeaveApplication.css"
import { useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { addLeaveTypeAPICall, deleteLeaveTypeAPICall, editLeaveTypeAPICall, viewLeaveTypesAPI } from "../../../../API/Staff/HRM/Leave/staffLeaveTypeAPI";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";

export const StaffAddLeaveType=()=>{
    const[staffLeaveType,setStaffLeaveType]=useState(false)
    const[staffLeaveTypeEdit,setStaffLeaveTypeEdit]=useState(false)
    const[isModify,setIsModify]=useState(false)
    const [successSnackOpen, setSuccessSnackOpen] = useState(false);
    const [errorSnackOpen, setErrorSnackOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState("oops!!!");
    const [leaveTypeDetails,setLeaveTypeDetails]=useState([]);


    const [leaveTypeAlert, setLeaveTypeAlert] = useState(false);
    const [noOfDaysAlert, setNoOfDaysAlert] = useState(false);
    // loading state
    const [isLoading,setIsLoading]=useState(false)
    // list of All leave types
    const leaveTypeList=useSelector((state)=>
        state.allLeaveTypeSlice.value
    );

    const leaveTypeinfoInitialState={
        leaveType:"",
        noOfleave:""
    }
    const [leaveTypeInfo,setLeaveTypeInfo]=useState(leaveTypeinfoInitialState)
    

    const updateListener=()=>{
        setIsModify(!isModify);
    }
    const clearStates=()=>{
        setStaffLeaveType(false);
        setLeaveTypeInfo(leaveTypeinfoInitialState)
        setLeaveTypeAlert(false)
        setNoOfDaysAlert(false)
    }
    const closeSuccessSnack = () => {
        setSuccessSnackOpen(false);
    };
    const closeErrorSnack = () => {
        setErrorSnackOpen(false);
    };

// input field changing
    const getLeaveTypeInfo=(key)=>(e)=>{
        const{value}=e.target
        if(key==="leaveType"){
           setLeaveTypeInfo({...leaveTypeInfo,leaveType:value}) 
           setLeaveTypeAlert(false)
           if(value===""){
            setLeaveTypeAlert(true)
           }
        }
        if(key==="noOfLeave"){
            if(leaveTypeInfo?.leaveType===""){
                setLeaveTypeAlert(true)
               }
            if(value===""){
                setNoOfDaysAlert(true)
               }
            setLeaveTypeInfo({...leaveTypeInfo,noOfleave:value}) 
            setNoOfDaysAlert(false)
           
        }
    }

//add new leaveType payload 
    const leaveTypePayloadBody={
        leaveType:leaveTypeInfo?.leaveType,
        numberOfDays:leaveTypeInfo?.noOfleave
    }

// edit leave type payload body 
    const leaveTypeEditPayloadBody={
        _id:leaveTypeDetails[0]?._id,
        leaveType:leaveTypeInfo?.leaveType,
        numberOfDays:leaveTypeInfo?.noOfleave
    }

// add leave type submit click 
    const addLeaveTypeSubmit=()=>{
        if(leaveTypeInfo?.leaveType===""){
            setLeaveTypeAlert(true)
        }
        else if(leaveTypeInfo?.noOfleave===""){
            setNoOfDaysAlert(true)
        }

        else{
            addLeaveTypeAPICall(
                leaveTypePayloadBody,
                setSuccessSnackOpen,
                setErrorSnackOpen,
                setSnackMsg,
                updateListener,
                clearStates,
            )
        }
    }
    console.log(leaveTypeInfo);
//  edit leave type submit click
    const LeaveTypeEditSubmit=()=>{
        if(leaveTypeInfo?.leaveType===""){
            setLeaveTypeAlert(true)
        }
        else if(leaveTypeInfo?.noOfleave===""){
            setNoOfDaysAlert(true)
        }

        else{
            editLeaveTypeAPICall(
            leaveTypeEditPayloadBody,
            setSuccessSnackOpen,
            setErrorSnackOpen,
            setSnackMsg,
            updateListener
            );
            setStaffLeaveTypeEdit(false)
        }
    }
    
//filtering single leavetype details by leaveId 
    const clickEditLeaveType=(id)=>{
        setStaffLeaveTypeEdit(true);
        console.log(id);
        let filteredData=leaveTypeList?.filter((obj)=>{
            if(obj._id === id){
                return obj;
            }
        });
        setLeaveTypeDetails(filteredData)
    }


// delete a leave type API Call
    const clickDeleteLeaveType=(id)=>{

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#f80000',
            confirmButtonText: 'Delete',
            customClass:{
                cancelButton:'sweet-alert-cancel-button',
                confirmButton:'sweet-alert-submit-button',
                }
        
            }).then((result)=>{
                if(result.isConfirmed){
                    deleteLeaveTypeAPICall(
                        {_id:id},
                        updateListener()
                    )
                    Swal.fire({
                        title: 'Deleted!',
                        text: 'Leave Type Is Deleted.',
                        icon: 'error',
                        showConfirmButton: false
                      })
                }
            })
        
    }

// setting leaveType&no of  days Value
    useEffect(()=>{
        if(leaveTypeDetails[0]!==undefined){
                setLeaveTypeInfo({...leaveTypeInfo,leaveType:leaveTypeDetails[0]?.leaveType,noOfleave:leaveTypeDetails[0]?.numberOfDays})
            }
    },[leaveTypeDetails])

// listing all leaveType API call  
    useEffect(()=>{
        viewLeaveTypesAPI(
            setIsLoading,
            setErrorSnackOpen,
            setSnackMsg
        );
    },[isModify])
    return(
        <>
            <div className="global-page-parent-container">
            <p className="breadcrumb">Staff &gt; Leave &gt; Add Leave Type</p>
                <div className="staff-add-leave-type-container-top">
                    <h3>Leave Type</h3>
                    <button className="btn" onClick={()=>{setStaffLeaveType(true)}}>Add Leave Type</button>
                </div>
            <div className="global-table-container staff-holiday-container">
                <table className="global-table">
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>Leave Type</th>
                            <th>Total Leave Days</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    {
                    isLoading?
                    <tbody>
                        <tr>
                            <td colSpan={4}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={4}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={4}>
                            {/* <Loader /> */}
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={40}
                            />
                            </td>
                        </tr>
                        <tr>
                        <td colSpan={4}>
                            {/* <Loader /> */}
                            <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={40}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4}>
                            {/* <Loader /> */}
                            <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={40}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4}>
                            {/* <Loader /> */}
                            <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={40}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4}>
                            {/* <Loader /> */}
                            <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={40}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4}>
                            {/* <Loader /> */}
                            <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={40}
                            />
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={4}>
                            {/* <Loader /> */}
                            <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={40}
                            />
                        </td>
                        </tr>
                    </tbody>
                    :
                    <tbody>
                        {leaveTypeList !==undefined?
                            leaveTypeList
                            ?.slice(0)
                            ?.reverse()
                            ?.map((r,i)=>(
                                <tr key={i}>
                                    <td>{i+1}</td>
                                    <td>{r?.leaveType.toUpperCase()}</td>
                                    <td>{r?.numberOfDays}</td>
                                    <td>
                                        <IconButton onClick={()=>clickEditLeaveType(r?._id)}><i class="bi bi-pencil-square edit-icon"></i></IconButton>
                                        <IconButton onClick={()=>clickDeleteLeaveType(r?._id)}><i class="bi bi-trash3 delete-icon"></i></IconButton></td>
                                </tr>
                            ))
                            :
                            <tr>
                            <td colSpan={4}>No Records</td>
                        </tr>}           
                    </tbody>
            }
                </table>

            <Dialog open={staffLeaveType} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setStaffLeaveType(false)}>
                <div className="staff-holiday-edit-container">
                    <div className="staff-holiday-edit-container-top">
                        <h3>Add Leave Type</h3>
                    </div>
                    <div className="staff-holiday-edit-container-body">
                        <div className="global-single-input validating-input-filed">
                            <p>Leave Type</p>
                            <input type="text" 
                            value={leaveTypeInfo?.leaveType}
                            onChange={getLeaveTypeInfo("leaveType")}
                            />
                            {leaveTypeAlert?
                                    <p className="doc-validation-alert">Enter Leave Type !!</p>:""}
                        </div>
                        <div className="global-single-input validating-input-filed">
                            <p>Number Of Leave days</p>
                            <input type="number" 
                            onChange={getLeaveTypeInfo("noOfLeave")}
                            min={0}
                            />
                            {noOfDaysAlert?
                                    <p className="doc-validation-alert">Enter No Of Days !!</p>:""}
                        </div>
                    </div>
                    <div className="staff-holiday-edit-container-button">
                        <button className="btn btn-secondary-outlined" onClick={clearStates}>Cancel</button>
                        <button className="btn btn-primary" onClick={addLeaveTypeSubmit}>Submit</button>
                    </div>
                </div>
            </Dialog>
            <Dialog open={staffLeaveTypeEdit} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setStaffLeaveTypeEdit(false)}>
                <div className="staff-holiday-edit-container">
                    <div className="staff-holiday-edit-container-top">
                        <h3>Edit Leave Type</h3>
                    </div>
                    <div className="staff-holiday-edit-container-body">
                        <div className="global-single-input validating-input-filed">
                            <p>Leave Type</p>
                            <input type="text" 
                            value={leaveTypeInfo?.leaveType}
                            onChange={getLeaveTypeInfo("leaveType")}
                            />
                            {leaveTypeAlert?
                                    <p className="doc-validation-alert">Enter Leave Type !!</p>:""}
                        </div>
                        <div className="global-single-input validating-input-filed">
                            <p>Number Of Leave days</p>
                            <input type="number" 
                            value={leaveTypeInfo?.noOfleave}
                            onChange={getLeaveTypeInfo("noOfLeave")}
                            min={0}
                            />
                            {noOfDaysAlert?
                                    <p className="doc-validation-alert">Enter No Of Days !!</p>:""}
                        </div>
                    </div>
                    <div className="staff-holiday-edit-container-button">
                        <button className="btn btn-secondary-outlined" onClick={()=>{setStaffLeaveTypeEdit(false);setLeaveTypeAlert(false);setNoOfDaysAlert(false)}}>Cancel</button>
                        <button className="btn btn-primary" onClick={LeaveTypeEditSubmit}>Edit</button>
                    </div>
                </div>
            </Dialog>

            <SuccessSnackbar
                open={successSnackOpen}
                handleClose={closeSuccessSnack}
                message={snackMsg}
            />
            <ErrorSnackbar
                open={errorSnackOpen}
                handleClose={closeErrorSnack}
                message={snackMsg}
            />

            </div>
        </div>
        </>
    )
}