import axios from "axios"
import { get_grn_report } from "../../Redux/Report/grnReportSlice"
import store from "../../Redux/store"
import {HEADERS} from '../../API/UrlAndPaths'

export const grnReportAPICall= async (body,setIsLoading)=>{
   setIsLoading(true) 
   await axios.post("report/GrnReport",body,HEADERS)
   .then((res)=>{
    if(res.status===200){
        store.dispatch(get_grn_report({grnReportList:res.data}))
        setIsLoading(false)
    }
   })
   .catch((err)=>{
    console.log(err.response);
    store.dispatch(get_grn_report({grnReportList:undefined}))
    setIsLoading(false)
   })
}