import { createSlice } from "@reduxjs/toolkit";

export const allProductListWithBranchSlice = createSlice({
    name:"allProductListWithBranch",
    initialState:{
        value:undefined
    },
    reducers:{
        get_product_list_branch:(state,action)=>{
            state.value=action.payload.productListWithBranch
        }
    }
})
export const {get_product_list_branch} = allProductListWithBranchSlice.actions;
export default  allProductListWithBranchSlice.reducer