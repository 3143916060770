import axios from "axios"
import { get_purchade_return_id } from "../../Redux/Prchase Manager/PurchaseReturn/generatePurchaseReturnIdSlice"
import { get_po_pwopo_by_id } from "../../Redux/Prchase Manager/PurchaseReturn/viewPOAndPWOPOByIdSlice"
import { get_purchase_return_list } from "../../Redux/Prchase Manager/PurchaseReturn/viewPurchaseReturnListSlice"
import store from "../../Redux/store"
import { HEADERS, HEADER_QUERY } from "../UrlAndPaths"
import { get_paurchseReturn_invoiceNO } from "../../Redux/Prchase Manager/PurchaseReturn/viewInvoiceNoListAddPurchaseReturnSlice"

// generate purchase return id
export const generatePurchaseRetunAPICall= async ()=>{
   await axios.get("purchase/generatepurchaseReturnId",HEADERS)
   .then((res)=>{
    if(res.status===200){
        store.dispatch(get_purchade_return_id({purchaseReturnId:res.data}))
    }
   })
   .catch((err)=>{
    store.dispatch(get_purchade_return_id({purchaseReturnId:undefined}))
    console.log(err.response);
   })
}

// add purchase return invoice no list
export const viewPurchaseReturnInvoiceNoList= async (body)=>{
  await axios.post("purchase/viewReturnInvoiceNumbers",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_paurchseReturn_invoiceNO({purchaseReturnInvoice:res.data}))
    }
  })
  .catch((err)=>{
    store.dispatch(get_paurchseReturn_invoiceNO({purchaseReturnInvoice:undefined}))
  })
}


// view purchase and purchase without po by id
export const viewpurchaseAndPurchaseWOPOByIdAPICall= async (body)=>{
  await axios.post("purchase/viewpurchasereturninfos",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
        store.dispatch(get_po_pwopo_by_id({purchaseAndPurchaseWOPOById:res.data}))
    }
  })
  .catch((err)=>{
    store.dispatch(get_po_pwopo_by_id({purchaseAndPurchaseWOPOById:undefined}))
    console.log(err.response);
  })
}

// add purchase return 
export const addPurchseReturnAPICall= async (
  body,
  updateLister,
  clearState,
  setAddReturnDialog  
  )=>{
  await axios.post("purchase/addpurchasereturn",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      console.log('add purchse return');
      updateLister()
      clearState()
      setAddReturnDialog(false)
    }
  })
  .catch((err)=>{
    console.log(err.response);
  })
}

// view purchase return
export const viewPurchaseReturnAPICall= async (body,setIsLoading)=>{
  setIsLoading(true)
  await axios.post("purchase/viewpurchasereturn",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_purchase_return_list({purchaseReturnList:res.data}))
      setIsLoading(false)
    }
  })
  .catch((err)=>{
    console.log(err.response);
    store.dispatch(get_purchase_return_list({purchaseReturnList:undefined}))
    setIsLoading(false)
  })
}

// delete purchase return

  export const deleteSinglePurchaseReturnAPICall= async(body,updateLister,successSnackBar,errorSnackBar,snackMsg)=>{
    await axios.delete("purchase/deletePurchaseReturn",{headers:HEADER_QUERY,data:body})
      .then((res)=>{
        if(res.status===200){
          snackMsg("deleted !!")
          successSnackBar(true)
          updateLister()
        }
      })
      .catch((err)=>{
        console.error(err.res.status);
        switch(err.response.status) {
          case 500:
            snackMsg("Server error!!")
            break;
          case 401:
            snackMsg("Unauthorized!!");
            break;
          case 403:
            snackMsg("Forbidden!!");
            break;
          default:
            snackMsg(
              "Unseen error occurred,Please contact to EYET Support!!!!"
            );
            break;
        }
      errorSnackBar(true)
      })
  }