import { HEADERS, HEADER_FORM_DATA } from "../../UrlAndPaths";
import axios from "axios";
import store from "../../../Redux/store";
import {
  update_company_profile,
  update_user_company_info,
} from "../../../Redux/Settings/Company Settings/companyProfileSlice";
import { update_company_locations } from "../../../Redux/Settings/Company Settings/allLocationsSlice";
import { update_company_branches } from "../../../Redux/Settings/Company Settings/allBranchesSlice";
import { get_upi_provider } from "../../../Redux/Settings/Company Settings/allUpiProviderSlice";
import { get_all_allowed_branches } from "../../../Redux/Settings/Company Settings/allAllowedBranchesSlice";
import { get_debit_note_row_data_list } from "../../../component/Modules/Accounts/Vendor/Slice/debitNoteSlice";

//View Company Profile Info
// shh

export const viewCompanyProfileInfoAPICall = async () => {
  await axios
    .post("admin/viewprofile", {}, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_company_profile({
            companyProfile: res.data,
          })
        );
      }
    })
    .catch((err) => {
      console.error(err.response);
      store.dispatch(
        update_company_profile({
          companyProfile: undefined,
        })
      );
    });
};

//   await axios
//     .post("admin/viewcompanyinfosforemployee", {}, HEADERS)
//     .then((res) => {
//       if (res.status === 200) {
//         store.dispatch(
//           update_user_company_info({
//             userCompanyInfo: res.data,
//           })
//         );
//       }
//     });

// };
export const companyProfileInfoForUser = async () => {
  await axios
    .post("admin/viewcompanyinfosforemployee", {}, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_user_company_info({
            userCompanyInfo: res.data,
          })
        );
      }
    })
    .catch((err) => {
      console.error(err.response);
      store.dispatch(
        update_user_company_info({
          userCompanyInfo: undefined,
        })
      );
    });
};
//View All Locations
export const viewAllLocationsAPICall = async () => {
  await axios
    .post("admin/viewlocation", {}, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_company_locations({
            locations: res.data,
          })
        );
      }
    })
    .catch((err) => {
      console.error(err.response);
      store.dispatch(
        update_company_locations({
          locations: undefined,
        })
      );
    });
};

//Add New Locations
export const addNewLocationsAPICall = async (
  body,
  setSuccessSnack,
  setErrorSnack,
  setSnackMsg,
  clearState,
  updateListener
) => {
  await axios
    .post("admin/addlocation", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("New Location Added Successfully...");
        setSnackMsg("New Location Added Successfully");
        setSuccessSnack(true);
        clearState("");
        updateListener();
      }
    })
    .catch((err) => {
      console.error(err.response);
      if (err.response.status === 409) {
        setSnackMsg("Location already exists!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      if (err.response === undefined) {
        setSnackMsg("Network Error!!");
      }
      setErrorSnack(true);
    });
};

//Update Branch Logo(after successful creation of branch)
export const updateBranchLogoAPICall = async (formData) => {
  await axios
    .put("admin/addBranchLogo", formData, HEADER_FORM_DATA)
    .then((res) => {
      if (res.status === 200) console.log("Logo updated successfully...");
    })
    .catch((err) => {
      console.error(err.response);
    });
};

//Add New Branch(Outlet)
export const addNewOutletAPICall = async (
  body,
  setSuccessSnack,
  setErrorSnack,
  setSnackMsg,
  clearStates,
  updateListener,
  branchLogoFormData
) => {
  await axios
    .post("admin/addbranch", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("New branch Added Successfully...");
        setSnackMsg("New Outlet Added Successfully");
        setSuccessSnack(true);
        branchLogoFormData.append("branchId", res.data?._id);
        updateBranchLogoAPICall(branchLogoFormData);
        clearStates();
        updateListener();
      }
    })
    .catch((err) => {
      console.error(err.response);
      if (err.response.status === 409) {
        setSnackMsg("Outlet Name already exists!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      if (err.response === undefined) {
        setSnackMsg("Network Error!!");
      }
      setErrorSnack(true);
    });
};

//Edit Outlet
export const editOutletAPICall = async (
  formData,
  setSuccessSnack,
  setErrorSnack,
  setSnackMsg,
  clearStates,
  updateListener
) => {
  await axios
    .put("admin/editBranchDetails", formData, HEADER_FORM_DATA)
    .then((res) => {
      if (res.status === 200) {
        console.log("Branch edited Successfully...");
        setSnackMsg("Branch edited Successfully");
        setSuccessSnack(true);
        clearStates();
        updateListener();
      }
    })
    .catch((err) => {
      console.error(err.response);
      if (err.response.status === 409) {
        setSnackMsg("Conflict!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server Error!!");
      }
      if (err.response === undefined) {
        setSnackMsg("Network Error!!");
      }
      setErrorSnack(true);
    });
};

//View All branches
export const viewAllBranchesAPICall = async (
  setIsLoading,
  setErrorSnack,
  setSnackMsg
) => {
  if (setIsLoading !== undefined) setIsLoading(true);
  await axios
    .post("admin/viewbranches", {}, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_company_branches({
            branches: res.data,
          })
        );
        if (setIsLoading !== undefined) setIsLoading(false);
      }
    })
    .catch((err) => {
      console.error(err.response);
      store.dispatch(
        update_company_branches({
          branches: undefined,
        })
      );
      switch (err.response.status) {
        case 404:
          setSnackMsg("No data available..");
          break;
        case 500:
          setSnackMsg("Server error!!");
          break;
        case 401:
          setSnackMsg("Unauthorized!!");
          break;
        case 403:
          setSnackMsg("Forbidden!!");
          break;

        default:
          setSnackMsg(
            "Unseen error occurred,Please contact to EYET Support!!!!"
          );
          break;
      }
      setErrorSnack(true);
      if (setIsLoading !== undefined) setIsLoading(false);
    });
};

// view all allowed branches
export const viewAllAllowedBranchesAPICall = (body) => {
  axios
    .post("employee/listEmployeeBranches", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(get_all_allowed_branches({ allowedBranches: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(get_all_allowed_branches({ allowedBranches: undefined }));
      console.log(err.response);
    });
};

//Edit Company Settings
export const editCompanyProfileInfoAPICall = async (
  formData,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener
) => {
  await axios
    .put(`admin/editprofile`, formData, HEADER_FORM_DATA)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Updated successfully 👍");
        updateListener();
        setSuccess(true);
        console.log("Updated successfully");
      }
    })
    .catch((err) => {
      if (err.response.status === 404) {
        setSnackMsg("Entry not found!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Conflict!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      console.error(err.response);
      setError(true);
    });
};

// get all upi provider api call
export const viewAllUpiProvider = async () => {
  await axios
    .get("settings/viewupiprovider", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(get_upi_provider({ upiProviderData: res.data }));
      }
    })
    .catch((err) => {
      console.log(err.response);
      store.dispatch(get_upi_provider({ upiProviderData: undefined }));
    });
};
// add upi provider api call
export const addUpiProviderAPICall = async (
  formData,
  setIsModify,
  isModify,
  clearState,
  setOpenSuccessSnack,
  setOpenErrorSnack,
  setSnackMsg
) => {
  await axios
    .post("settings/addupiprovider", formData, HEADER_FORM_DATA)
    .then((res) => {
      if (res.status === 200) {
        setOpenSuccessSnack(true);
        setSnackMsg("Added Successfully");
        setIsModify(!isModify);
        clearState();
      }
    })
    .catch((err) => {
      if (err.response.status === 404) {
        setSnackMsg("Entry not found!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Conflict!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      setOpenErrorSnack(true);

      console.log(err.response);
    });
};



//debitNoteSingleViewApiCall
export const debitNoteSingleViewApiCall =(body)=>{

axios.post('account/debitNoteSingleView',body,HEADERS)
.then((res)=>{
  console.log("debitNote>>>>",res?.data)
  store.dispatch(get_debit_note_row_data_list({debitNoteSingleData:res?.data}));
}).catch((err)=>{
  store.dispatch(get_debit_note_row_data_list({debitNoteSingleData:undefined}));
})


}