import { createSlice } from "@reduxjs/toolkit";

export const singleCustomerWalletSlice = createSlice({
  name: "singleCustomerWalletSlice",
  initialState: {
    value: undefined,
  },
  reducers: {
    update_wallet_total_of_customer: (state, action) => {
      state.value = action.payload.singleCusWallet;
    },
  },
});

export const { update_wallet_total_of_customer } = singleCustomerWalletSlice.actions;
export default singleCustomerWalletSlice.reducer;
