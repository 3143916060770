import { Dialog, IconButton, MenuItem, Select, Skeleton } from "@mui/material";
import React, { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { viewPurchaseVoucherAPICall } from "../../../../API/Purchase Manager/Payment Voucher/paymentVoucherAPI";
import "../../../../css/Purchase Manager/paymentVoucher.css";
import { convertDateFormat } from "../../../../Js/Date";
import { today } from "../../../../Js/Date";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
export const PaymentVoucher = () => {

  let paymentVoucherSinglePrint=useRef
  let paymentVoucherPrintFullBody=useRef

  // Single View Dialog
  const [openSingleViewPV, setOpenSingleViewPV] = useState(false);
  const [singleRowId,setSingleRowId]=useState(null)
  const [searchKeyword,setSearchKeyword]=useState("")
  // loading state
  const [isLoading,setIsloading]=useState(false)
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!");
  const [filteredPaymentList,setFilteredPaymentList]=useState([])
  const [time, setTime] = useState(null);
  const getSearchKeyword=(e)=>{
    setSearchKeyword(e.target.value)
  }
  // close Single View Dialog
  const closeSingleViewDialog = () => {
    setOpenSingleViewPV(false);
  };
  // open Single View Dialog
  const openSingleViewDialog = () => {
    setOpenSingleViewPV(true);
  };
  //Close ErrorSnackbar
  const closeErrorSnackbar = () => {
    setErrorSnackOpen(false);
  };
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  const userRole = useSelector((state) => state.userRoleSlice.value);
  // single payment Voucher 
  const [singlePaymentVoucher,setSinglePaymentVoucher]=useState([])
  // viewPaymentVoucher
  const paymentVoucherData = useSelector(
    (state) => state.paymentVoucherSlice.value
  );
  const viewSinglePaymentVoucher=(id)=>{
    if(paymentVoucherData !== undefined){
      const filteredData=paymentVoucherData?.filter((obj)=>{
        if(obj?._id === id){
         return obj
        }
       
      })
      setSinglePaymentVoucher(filteredData)
    }
  }
  const SearchFilter=(chars,paymentList)=>{
    let searchedArray=[]
    let filteredArray=[]
    if(chars!==""){
      searchedArray=paymentList.filter((obj)=>
        obj?.transNo?.toLowerCase()?.includes(chars?.toLowerCase())||
        obj?.wpoId?.toLowerCase()?.includes(chars?.toLowerCase())||
        obj?.supplierName?.toLowerCase()?.includes(chars?.toLowerCase())
      )
      filteredArray=searchedArray
    }
    else{
      filteredArray=paymentList
    }
    return filteredArray
  }
  useEffect(()=>{
    if(paymentVoucherData!==undefined){
      let filteredArray=[]
      setFilteredPaymentList(paymentVoucherData)
      filteredArray=SearchFilter(searchKeyword,paymentVoucherData)
      setFilteredPaymentList(filteredArray)    
    }
    else{
      setFilteredPaymentList([])
    }
  },[searchKeyword,paymentVoucherData])
  useEffect(()=>{
    setInterval(()=>{
      setTime(`${new Date().getHours()}:${new Date().getMinutes()}`)
    })
  },[time])
  useEffect(() =>{
    viewPurchaseVoucherAPICall(
      setIsloading,setErrorSnackOpen,setSnackMsg
    );
  },[])

  return (
    <>
      <div className="global-page-parent-container payment-voucher-parent-container">
        <p className="breadcrumb">Purchase Manager &gt; Payment Voucher</p>
        <div className="payment-voucher-main-container">
          <div className="pending-payment-voucher-main-container-top">

            <h3>Payment Voucher</h3>
            <div className="supplier-manage-top-container-right">
              <div className="view-order-search-container view-order-admin-search-container">
                  <input
                    placeholder="Transaction ID,Supplier Name,GRN ID"
                    value={searchKeyword}
                    onChange={getSearchKeyword}
                  />
                  <IconButton>
                      <i class="bi bi-search search-icon-order"></i>
                  </IconButton>
              </div>
              <ReactToPrint
                trigger={()=>( 
                  <IconButton className="print-icon-container" >
                    <i class="bi bi-printer printer-icon"></i>
                  </IconButton>
                  )}
                content={()=>paymentVoucherPrintFullBody}
                pageStyle=" @page { size: auto;  margin:15mm 0 10mm 0 } }"
                /> 
            </div>
          </div>
          <div className="global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Transaction ID</th>
                  <th>GRN ID</th>
                  <th>Branch Code</th>
                  <th>Branch Name</th>
                  <th>Supplier</th>
                  <th>Date</th>
                  <th>Credit Amount</th>
                  <th>Last Paid Date</th>
                  <th>Last Paid Amount</th>
                  <th>View</th>
                  <th>Print</th>
                </tr>
              </thead>
              {
                isLoading?
                <tbody>
                  <tr>
                    <td colSpan={12}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={12}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={12}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={12}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={12}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr> 
                    <tr>
                    <td colSpan={12}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr> 
                    <tr>
                    <td colSpan={12}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr> 
                    <tr>
                    <td colSpan={12}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
            </tbody>:
            <tbody>
                {filteredPaymentList.length !==0?(
                  filteredPaymentList
                  ?.slice(0)
                  ?.reverse()
                  ?.map((r,i) => (
                    <tr key={i}>
                      <td>{i+1}</td>
                      <td>{r?.transNo}</td>
                      <td>{r?.wpoId}</td>
                      <td>{r?.branchId}</td>
                      <td>{r?.branchName}</td>
                      <td>{r?.supplierName}</td>
                      <td>{convertDateFormat(r?.paymentMethod.date)}</td>
                      <td>{r?.creditAmount}</td>
                      <td>{convertDateFormat(r?.lastPaidDate)}</td>
                      <td>{r?.lastPaidAmount}</td>
                      <td>
                        <IconButton
                        onMouseDown={()=>viewSinglePaymentVoucher(r?._id)}
                        onClick={openSingleViewDialog}>
                          <i class="bi bi-eye visibility-icon"></i>
                        </IconButton>
                      </td>
                      <td>
                        <ReactToPrint
                        trigger={()=>( 
                          <IconButton >
                            <i class="bi bi-printer printer-icon"></i>
                          </IconButton>
                          )}
                        
                        onBeforeGetContent={()=>
                        {
                          setSingleRowId(r);
                          return Promise.resolve()
                        }
                        } 
                        content={()=>paymentVoucherSinglePrint}
                      /> 
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                  <td colSpan={12}>No Records</td>
                </tr>
                )}
              </tbody>
              }
            </table>
          </div>
        </div>
      </div>

      {/* Single View  */}
      <Dialog
        open={openSingleViewPV}
        maxWidth="lg"
        onKeyDown={(e)=>e.key === "Escape" && setOpenSingleViewPV(false)}
      >
        <div className="payment-voucher-single-view-container">
          <h3>View</h3>
          <div className="payment-voucher-single-view-form-container">
            <div className="global-single-input pvsv">
              <p>transaction ID</p>
              <input type="text" name="" id="" value={singlePaymentVoucher[0]?.transNo}  disabled/>
            </div>
            <div className="global-single-input pvsv">
              <p>GRN ID</p>
              <input type="text" name="" id=""  value={singlePaymentVoucher[0]?.wpoId} disabled/>
            </div>
            <div className="global-single-input pvsv">
              <p>Supplier</p>
              <input type="text" name="" id=""  value={singlePaymentVoucher[0]?.supplierName} disabled/>
            </div>
            <div className="global-single-input pvsv">
              <p>Date</p>
              <input type="date"  value={singlePaymentVoucher[0]?.paymentMethod.date} disabled/>
            </div>
            <div className="global-single-input pvsv">
              <p>Credit Amount (Balance Amount)</p>
              <input type="text" name="" id=""  value={singlePaymentVoucher[0]?.creditAmount} disabled/>
            </div>
            <div className="global-single-input pvsv">
              <p>Last Paid Date</p>
              <input type="date" name="" id="" value={singlePaymentVoucher[0]?.lastPaidDate} disabled />
            </div>
            <div className="global-single-input pvsv">
              <p>Last Paid Amount</p>
              <input type="text" name="" id=""  value={singlePaymentVoucher[0]?.lastPaidAmount} disabled/>
            </div>
            <div className="global-single-input pvsv">
              <p>Payment Method</p>
              <input type="text" name="" id=""  value={singlePaymentVoucher[0]?.paymentMethod.type} disabled/>
            </div>
            <div className="global-single-input pvsv">
              <p>Card Type</p>
              <input type="text" name="" id=""  value="__" disabled/>
            </div>
            <div className="global-single-input pvsv">
              <p>Card No</p>
              <input type="text" name="" id=""  value="__" disabled/>
            </div>
            <div className="global-single-input pvsv">
              <p>CVV</p>
              <input type="text" name="" id=""  value="__" disabled/>
            </div>
          </div>
          <div className="cancel-submit-container">
            <button className="btn btn-secondary-outlined" onClick={closeSingleViewDialog}>Close</button>
          </div>
        </div>
      </Dialog>


      {/*  payment voucher single print */}
      <div 
       style={{display:"none"}}
      >
        {singleRowId !== null &&
         <div ref={(el)=>(paymentVoucherSinglePrint = el)}>
         <div className='print-view-top-container'>
            <div className='print-view-top-left-container' style={{width:"30%"}}>
              <h4>Trans ID : {singleRowId?.transNo}</h4>
            </div>
            <div className='print-view-top-middle-container' style={{width:"40%"}}>
                 <h3> PAYMENT VOUCHER</h3>
            </div>
            <div className='print-view-top-right-container' style={{width:"30%"}}>
                <h4 style={{textAlign:'right'}}>{convertDateFormat(today)}</h4>
            </div>
          </div>
           <div className="pending-payment-voucher-single-print">
                  <div className="pending-payment-voucher-body-section">
                    <div className="pending-payment-voucher-body-section-left">
                      <p>GRN ID &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        :{singleRowId?.wpoId}</p>
                    </div>
                    <div className="pending-payment-voucher-body-section-left">
                      <p>Supplier Name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        :{singleRowId?.supplierName}</p>
                    </div>

                  </div>
                  <div className="pending-payment-voucher-body-section">
                    <div className="pending-payment-voucher-body-section-right">
                      <p>Credit Amount &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        :{singleRowId?.creditAmount}</p>
                    </div>
                    <div className="pending-payment-voucher-body-section-right">
                      <p>Credit Date &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        :{convertDateFormat(singleRowId?.paymentMethod.date)}</p>
                    </div>
                  </div>
                  <div className="pending-payment-voucher-body-section">
                    <div className="pending-payment-voucher-body-section-right">
                      <p>Last Paid Amount &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        :{singleRowId?.lastPaidAmount}</p>
                    </div>
                    <div className="pending-payment-voucher-body-section-right">
                      <p>Last Paid Date &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        :{convertDateFormat(singleRowId?.lastPaidDate)}</p>
                    </div>
                  </div>
                  <div className="pending-payment-voucher-body-signature-section">
                        <div className="pending-payment-voucher-body-signature-section-box"></div>
                        <div className="pending-payment-voucher-body-signature-section-box">
                          <p>Signature</p>
                        </div>
                  </div>
           </div>
         </div>
        }
      </div>
      <div style={{display:'none'}}>
        <div ref={(el)=>(paymentVoucherPrintFullBody=el)}>
          <div className='print-view-top-container'>
              <div className='print-view-top-left-container' style={{width:"35%"}}>
              <h6 style={{margin:"0 0 0 2%"}}>Printed On : {convertDateFormat(today)}&nbsp;{time}</h6>
              
              </div>
              <div className='print-view-top-middle-container' style={{width:"30%"}}>
              {userRole === "admin" ? (
                  <h3>{loginResponse?.data?.profile?.companyName}</h3>
                ) : userRole === "user" ? (
                  <h3>{loginResponse?.storeName}</h3>
                ) : undefined}

                  <p> PAYMENT VOUCHER</p>
              </div>
              <div className='print-view-top-right-container' style={{width:"35%"}}>
              {
                userRole==="user" ?(
                  <p>{loginResponse?.branchId}-{loginResponse?.branchName}</p>
                ):undefined  
              }
              </div>
            </div>
            <div className='print-view-table-container report-print-view-table-with-clr'>

            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Transaction <br />ID</th>
                  {
                    userRole === 'admin' &&
                    <>
                    <th>Branch <br />Code</th>
                    <th>Branch<br /> Name</th>
                    </>
                  }
                  
                  <th>GRN ID</th>
                  <th>Supplier</th>
                  <th>Date</th>
                  <th>Credit <br /> Amount</th>
                  <th>Last Paid <br />Date</th>
                  <th>Last Paid <br />amount</th>
                </tr>
              </thead>
              <tbody>
              {
                paymentVoucherData?.length !==0 ? 
                paymentVoucherData
                ?.slice(0)
                ?.reverse()
                ?.map((r,i)=>(
                    <tr>
                    <td>{i+1}</td>
                    <td>{r?.transNo}</td>
                    <td>{r?.wpoId}</td>
                    {
                      userRole === 'admin' &&
                      <>
                      <td>{r?.branchId}</td>
                      <td>{r?.branchName}</td>
                      </>
                    }
                    
                    <td>{r?.supplierName}</td>
                    <td>{convertDateFormat(r?.paymentMethod.date)}</td>
                    <td>{r?.creditAmount}</td>
                    <td>{convertDateFormat(r?.lastPaidDate)}</td>
                    <td>{r?.lastPaidAmount}</td>
                  </tr>
                  ))
                  :
                  <tr>
                    <td colSpan={11}>No Data</td>
                  </tr>
                }
              </tbody>
            </table>
            </div>
        </div>
      </div>
      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </>
  );
};
