import axios from 'axios'
import { HEADERS,HEADER_FORM_DATA } from '../../UrlAndPaths'
import {get_upi_manual} from '../../../Redux/Settings/CardAndUpiType/upiManualSlice'
import store from '../../../Redux/store'

// view upi manual data api call
export const viewUpiManualAPICall=async()=>{
    await axios.post("settings/viewUpi",{},HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_upi_manual({upiManualData:res.data}))
        }
    })
    .catch((err)=>{
        console.log(err.response);
        store.dispatch(get_upi_manual({upiManualData:undefined}))
    })
}

// add upi manual api call
export const addUpiManualAPICall= async (
    formData,
    updateListener,
    setSuccessSnackbar,
    setErrorSnackbar,
    setSnackMsg,
    clearStates
    )=>{
 await axios.post("settings/addUpi",formData,HEADER_FORM_DATA)
 .then((res)=>{
    if(res.status===200){
        setSuccessSnackbar(true)
        setSnackMsg('Success')
        clearStates()
        updateListener()

    }
 })
 .catch((err)=>{
    setErrorSnackbar(true)
    if (err.response === undefined) {
        setSnackMsg("Network Connection error!!");
     }
      if (err.response.status === 409) {
        setSnackMsg("Already Exist");
      }
  
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden");
      }
    console.log(err.response);
 })
}

/// edit upi manual
export const editUpiManualAPICall= async (
    formData,
    updateListener,
    setSuccessSnackbar,
    setErrorSnackbar,
    setSnackMsg,
    clearStates,
    setIsEdit
    
    )=>{
 await axios.put("settings/editUpi",formData,HEADER_FORM_DATA)
 .then((res)=>{
    if(res.status===200){
        setSuccessSnackbar(true)
        setSnackMsg('Success')
        updateListener()
        clearStates()
        setIsEdit(false)
    }
 })
 .catch((err)=>{
     setErrorSnackbar(true)
    if (err.response === undefined) {
        setSnackMsg("Network Connection error!!");
     }
      if (err.response.status === 409) {
        setSnackMsg("Already Exist");
      }
  
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden");
      }
    console.log(err.response);
 })
}