import React from "react";
import InputBox from "../InputBox";
import DropdownInputBox from "../DropdownInputBox";
import SubmitBtn from "../SubmitBtn";
import { useDispatch, useSelector } from "react-redux";
import {
  setBankAccountDetails,
  setBankAccountNo,
  setBankName,
  setBranchName,
  setGstApplicable,
  setGstRate,
  setGstTaxType,
  setIfscCode,
  setNatureOfGoods,
  setNatureOfTransaction,
  setOpeningBalance,
  setRate,
  setTaxType,
  setTypeOfSupply,
} from "../../../../../Redux/Account/directIncomeSlice";
import natureOfTransactionOption from "../../../../../Assets/JSON/ledgerNatureOfTransactionOption.json";
import natureOfGoodsOption from "../../../../../Assets/JSON/ledgerNatureOfGoodsOptions.json";
import taxTypeOption from "../../../../../Assets/JSON/ledgerTaxTypeOption.json";
import typeOfSupplyOption from "../../../../../Assets/JSON/ledgerTypeOfSupply.json";

const DirectIncome = () => {
    const gstApplicableOption = [{ option: "yes" }, { option: "No" }];
  const dispatch = useDispatch();
  const {
    openingBalance,
    gstApplicable,
    natureOfTransaction,
    natureOfGoods,
    gstTaxType,
    gstRate,
    taxType,
    rate,
    typeOfSupply,
  } = useSelector((state) => state.directIncomeSlice);
  const directIncomeSlice = useSelector((state) => state.directIncomeSlice);
  const handleIndirectIncomeSubmit = () => {
    console.log(directIncomeSlice);
  };
  return (
    <>
    <div className="ledger-section-container-input-bank  ">
      <div style={{ padding: "2% 4%" }} className="bottom-line">
        {/*Opening Balance*/}
        <InputBox
          label={"Opening Balance"}
          value={openingBalance}
          onChange={(e) => dispatch(setOpeningBalance(e.target.value))}
          containerStyle={{width:'31%'}}

        />
      </div>

      <div
        style={{ padding: "2% 4%" }}
        className="ledgerSection-input-container"
      >
        <div>
          {/* gst applicable */}
          <DropdownInputBox
            options={gstApplicableOption}
            value={gstApplicable}
            labelName={"GST Applicable"}
            onChange={(e, value) => dispatch(setGstApplicable(value))}
          />

          {/* Nature of Transaction */}

          <DropdownInputBox
            options={natureOfTransactionOption}
            value={natureOfTransaction}
            labelName={"Nature of Transaction"}
            onChange={(e, value) => dispatch(setNatureOfTransaction(value))}
          />
          {/* Nature of Goods */}

          <DropdownInputBox
            options={natureOfGoodsOption}
            value={natureOfGoods}
            labelName={"Nature of Goods"}
            onChange={(e, value) => dispatch(setNatureOfGoods(value))}
          />
        </div>

        <div>
          <div className="tax-rate-input-section">
            {/* gst Tax Type */}
            <div style={{ width: "15em" }}>
              <DropdownInputBox
                options={taxTypeOption}
                value={gstTaxType}
                labelName={"GST Tax Type"}
                onChange={(e, value) => dispatch(setGstTaxType(value))}
                style={{ width: "13.5em" }}
              />
            </div>
            {/*gst  Rate */}
            <div
              className="global-single-input add-purchase-input auto-complete"
              style={{ width: "5em" }}
            >
              <p>GST Rate(%)</p>
              <input
                type="number"
                value={gstRate}
                onChange={(e) => dispatch(setGstRate(e.target.value))}
              />
            </div>
          </div>
          <div className="tax-rate-input-section">
            {/*  Tax Type */}
            <div style={{ width: "15em" }}>
              <DropdownInputBox
                options={taxTypeOption}
                value={taxType}
                labelName={"Tax Type"}
                onChange={(e, value) => dispatch(setTaxType(value))}
                style={{ width: "14em" }}
              />
            </div>

            {/* Rate */}
            <div
              className="global-single-input add-purchase-input auto-complete"
              style={{ width: "5em" }}
            >
              <p>Rate(%)</p>
              <input
                type="number"
                value={rate}
                onChange={(e) => dispatch(setRate(e.target.value))}
              />
            </div>
          </div>
        </div>

        <div>
          {/* Type of Supply */}
          <DropdownInputBox
            options={typeOfSupplyOption}
            value={typeOfSupply}
            labelName={"Type of Supply"}
            onChange={(e, value) => dispatch(setTypeOfSupply(value))}
            containerStyle={{width:'31%'}}

          />
        </div>
      </div>

      {/* submitButton */}
    </div>
    <SubmitBtn onClick={handleIndirectIncomeSubmit} />
  </>
  )
}

export default DirectIncome