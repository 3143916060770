import React, { useEffect, useState } from "react";
import "../../../css/Account/createLedger.css";
import groups from "../../../Assets/JSON/ledgerGroupsOption.json";
import categorize from "../../../Assets/JSON/fixedAssetCategory.json";
import LedgerSection from "./components/LedgerSection";
import SubmitBtn from "./components/SubmitBtn";
import PlainInputBox from "./components/InputBox";
import DropdownInputBox from "./components/DropdownInputBox";

const lowercaseGroups = groups.map((group) => {
  return {
    name: group.name.toLowerCase(),
  };
});

const CreateLedger = () => {
  const [name, setName] = useState("");
  const [group, setGroup] = useState({ name: "fixed asset" });
  const [category, setCategory] = useState({ name: "Furniture and fixtures" });
  const [code, setCode] = useState(0);
  const [showGroup, setShowGroup] = useState(false);
  const [section, setSection] = useState(false);
  const [label, setLabel] = useState("");

  useEffect(() => {
    const name = group?.name.toLowerCase();
    setSection(false);
    setShowGroup(false);

    switch (name) {
      case "fixed asset":
        setShowGroup(true);
        break;
      case "bank account":
        setLabel("Current Asset");
        break;
      case "bank over draft":
        setLabel("Loans Liability");
        break;
      case "cash in hand":
        setLabel("Current Asset");
        break;
      case "reserve & surplus":
        setLabel("Capital Account");
        break;
      case "retained earnings":
        setLabel("Capital Account");
        break;
      case "secured loans":
        setLabel("Loans Liability");
        break;
      case "deposit asset":
        setLabel("Current Asset");
        break;
      case "misc. expenses asset":
        setLabel("Current Asset");
        break;
      case "provisions":
        setLabel("Current Liability");
        break;
      case "customer":
        setLabel("Current Asset");
        break;
      case "suppliers":
        setLabel("Current Liability");
        break;
      default:
        setShowGroup(false);
        setLabel("");
        break;
    }
  }, [group]);

  const handleSubmit = () => {
    setSection(true);
    console.log("name--->", name);
    console.log("group--->", group);
    console.log("category--->", category);
    console.log("code--->", code);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };


  return (
    <>
      <div className="global-page-parent-container ">
        <p className="breadcrumb">Accounts &gt; Create Ledger</p>
        <div className="global-white-bg-container ">
          <div className="ledger-container bottom-line">
            <div className="add-customer-heading-radio-container ledger-input-container-box ">
              <div className="ledger-input-container">
                <PlainInputBox
                  onChange={(e) => setName(e.target.value)}
                  label={"Name"}
                />
                <DropdownInputBox
                  labelName={"Groups"}
                  options={lowercaseGroups}
                  value={group}
                  onChange={(e, value) => setGroup(value ? value : null)}
                  onKeyDown={handleKeyPress}
                  isGroup={true}
                  label={label}
                />

                {showGroup && (
                  <DropdownInputBox
                    labelName={"Category"}
                    options={categorize}
                    value={category}
                    onChange={(e, value) => setCategory(value ? value : null)}
                  />
                )}
                <PlainInputBox disabled={true} label={"Code"} value={0} />
              </div>
              <SubmitBtn
                onClick={handleSubmit}
                containerStyle={{ justifyContent: "flex-end" }}
              />
            </div>
          </div>
          {/*  ------ */}
          {section && <LedgerSection sectionName={group?.name} />}
        </div>
      </div>
    </>
  );
};

export default CreateLedger;
