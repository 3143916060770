import axios from 'axios'
import {get_stock_adj_transNo} from '../../Redux/Report/StockAdjustmentReport/stockAdjustmenTransNoSlice'
import {HEADERS,HEADER_FORM_DATA} from './../UrlAndPaths'
import store from  '../../Redux/store'
import {get_stock_adjustment} from '../../Redux/Report/StockAdjustmentReport/stockAdjustmentSlice'
import {get_stock_adj_single_view} from '../../Redux/Report/StockAdjustmentReport/stockAdjSingleViewSlice'
// get stock adjustment trans no
export const stockAdjustmentTransAPICall= async ()=>{
   await axios.get("purchase/getStockAdjustmentNos",HEADERS)
   .then((res)=>{
    if(res.status===200){
    store.dispatch(get_stock_adj_transNo({stockAdjTransNoData:res.data}))
    }
   })
   .catch((err)=>{
    store.dispatch(get_stock_adj_transNo({stockAdjTransNoData:undefined}))
    console.log(err.response);
   })
}

// get stock adjustment data api call
export const stockAdjustmentAPICall= async (formData,setIsLoading)=>{
  setIsLoading(true)
  await axios.post("report/stockadjustmentReport",formData,HEADER_FORM_DATA)
  .then((res)=>{
    if(res.status===200){
        store.dispatch(get_stock_adjustment({stockAdjustmentData:res.data}))
        setIsLoading(false)
    }
  })
  .catch((err)=>{
    store.dispatch(get_stock_adjustment({stockAdjustmentData:undefined}))
    console.log(err.response);
    setIsLoading(false)
  })
}

// stock adjustment single view 
export const stockAdjSingleViewAPICall= async (id)=>{
  await axios.post("report/stockadjustmentReportSingleView",id,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_stock_adj_single_view({stockAdjSingleViewData:res.data}))
    }
  })
  .catch((err)=>{
    store.dispatch(get_stock_adj_single_view({stockAdjSingleViewData:undefined})) 
    console.log(err.response);
  })
}

