import { Skeleton } from '@mui/material';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { viewAllBranchSettingsAPICall } from './BranchSettingsAPI';
import { useSelector } from 'react-redux';
import store from '../../../../../Redux/store';
import { get_branch_settings_row_data_list } from './BranchSettingsSlice';

export const BranchSettings = () => {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const tableListData=useSelector((state)=>state.branchSettingsSliceNew.apiResList);
    // const createFunction=()=>{
    //     navigate("/userdashboard/pointOfSales/configuration/BranchSettings/create")
    // }

    useEffect(()=>{
      viewAllBranchSettingsAPICall({},setIsLoading)
      localStorage.removeItem("singleViewById")
      store.dispatch(get_branch_settings_row_data_list({branchSettingsSingleData:undefined}))

    },[])

    const rowClick=(data)=>{
      localStorage.setItem("singleViewById",data?._id)
      navigate("/userdashboard/pointOfSales/configuration/BranchSettings/create")
    }
  return (
    <div  className="global-page-parent-container">
     <div className="global-white-bg-container">
        <div className="create-button-blue-container">
          <h3>Branch Settings</h3>
          {/* <button onClick={createFunction} className="create-button-blue">
            Create
          </button> */}
        </div>
        <div className="new-global-table-container">
          <table>
            <thead>
              <tr>
                <th>Branch</th>
                <th>Location</th>
                <th>Payment Type</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={6}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) :
              tableListData?.length !==0 ? tableListData?.map((r,i)=>(
                <tr onClick={()=>rowClick(r)}>
                  <td>{r?.branchName}</td>
                  <td>{r?.locationName}</td>
                  <td className='table-data-box'>
                       <div className='table-data-round-parent'>

                        { 
                        r?.paymentTypes?.map((r,i)=>(
                          <div
                          className='round-border'
                          key={i}>{r?.type}</div>
                          ))
                        }
                        </div>
                    </td>
                </tr>
              ))
              :  
              (
                <tr>
                  <td colSpan={7}>No Data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

      </div>

    </div>
  )
}
