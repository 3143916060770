import axios from "axios";
import { HEADER_QUERY } from "../../UrlAndPaths";

// clear data of a branch

export const clearBranchDataAPICall=async(body,setPasswordDialog,setPopupDialog,setError)=>{
    await axios.delete("admin/deleteBranchData",{headers:HEADER_QUERY,data:body})
    .then((res)=>{
        if(res.status===200){
            setPasswordDialog(false)
            setPopupDialog(true)
        }
    })
    .catch((err)=>{
        console.error(err.res);
        setError(true)
    })
}