import { Dialog, IconButton, Skeleton } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { deletePurchseOrderAPICall, purchaseOrderSingleViewAPICall, viewPurchaseOrderApiCall } from "../../../../API/Purchase Manager/purchaseOrderAPI";
import "../../../../css/Purchase Manager/purchaseOrder.css";
import { convertDateFormat, today } from "../../../../Js/Date";
import { deleteAlertMessage } from "../../../../Js/generalFunctions";
import { Datepicker } from "../../../Single Components/Datepicker";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
export const PurchaseOrder = () => {
  // purchase order list
  const purchaseOrderData=useSelector((state)=>state.viewPurchaseOrderSlice.value)
 
  // purchase order single view
 const purchseOrderSingle=useSelector((state)=>state.singleViewPurchaseOrder.value) 


  //Purchase Order Single View Dialog Open State
  const [openPOSingleView, setOpenPOSingleView] = useState(false);
  const [isLoading,setIsLoading]=useState(false)
  const [searchInput,setSearchInput]=useState(null)
  const [filteredData,setFilteredData]=useState([])
  const [isModify,setIsModify]=useState(false)

  //Snackbar States
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("Error!!");


//Close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setSuccessSnackOpen(false);
  };
  //Close ErrorSnackbar
  const closeErrorSnackbar = () => {
    setErrorSnackOpen(false);
  };
  const updateListner=()=>{
    setIsModify(!isModify)
  }
 
  // Close Purchase Order Dialog
  const closePODialog = () => {
    setOpenPOSingleView(false);
  };

  useEffect(()=>{
    viewPurchaseOrderApiCall(setIsLoading)
   },[isModify])

   // open Purchase Order Dialog
   const openPODialog = (id) => {
    setOpenPOSingleView(true);
    purchaseOrderSingleViewAPICall({_id:id})
  };

  // map response to state
  useEffect(()=>{
    if(purchaseOrderData !== undefined){
      setFilteredData(purchaseOrderData?.map(obj=>{return {...obj}})) 
    }
  },[purchaseOrderData])
  // purchse order search function
  useEffect(()=>{
    if(purchaseOrderData !== undefined){
      let filteredData=purchaseOrderData?.filter((obj)=>{
        if(obj.PURCHASEID?.toLowerCase()?.includes(searchInput?.toLowerCase())){
          return obj;
        }
        else if(obj.supplierName?.toLowerCase()?.includes(searchInput?.toLowerCase())){
          return obj;
        }
       
       
      })
      setFilteredData(filteredData)
    }
   
  },[searchInput])

  // delete purchse order
   const deletePurchaseOrder=(id)=>{
     deletePurchseOrderAPICall(
      id,
      updateListner,
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg
      )
   }



  return (
    <>
      <div className="global-page-parent-container">
        <p className="breadcrumb">Purchase Manage &gt; Purchase Order</p>
        <div className="purchase-order-main-container">
          <div className="top-bar">
            <h3>Purchase Order</h3>
            <div className="search-box-container">
              <input
                type="text"
                name=""
                id=""
                placeholder="Search Location..."
                value={searchInput}
                onChange={(e)=>setSearchInput(e.target.value)}
              />
              <IconButton className="icon-btn" style={{position:"absolute",right:"10px"}}>
                <i class="bi bi-search search-icon"></i>
              </IconButton>
            </div>
            <div className="date-container">
              <Datepicker type="date" backgroundColor="#f1f2ff" value={today}/>
            </div>
          </div>
          <div className="purchase-order-table-container global-table-container">
            <table className="global-table" >
              <thead>
                <tr>
                  <th>No</th>
                  <th>PO No</th>
                  <th>Branch Code</th>
                  <th>Branch Name</th>
                  <th>Supplier Name</th>
                  <th>Date</th>
                  <th>Price</th>
                  <th>View</th>
                  <th>Delete</th>
                
                </tr>
              </thead>
              {
                isLoading ?(
                   <tbody>
                    <tr>
                      <td colSpan={10}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={30}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={10}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={30}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={10}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={30}
                        />
                      </td>
                    </tr>
                   </tbody>
                ):(
               <tbody>
                {
                  filteredData?.length !==0 ? filteredData?.slice(0)?.reverse()?.map((r,i)=>(
                    <tr key={i}>
                      <td>{i+1}</td>
                      <td>{r?.PURCHASEID}</td>
                      <td>{r?.branchId}</td>
                      <td>{r?.locationName}</td>
                      <td>{r?.supplierName}</td>
                      <td>{convertDateFormat(r?.purchaseDate)}</td>
                      <td>{r?.grandTotal.toFixed(2)}</td>
                      
                      <td>
                    {" "}
                    <IconButton onClick={()=>openPODialog(r?._id)}>
                      <i class="bi bi-eye visibility-icon"></i>
                    </IconButton>
                  </td>
                  <td>
                    {" "}
                    <IconButton onClick={deleteAlertMessage(r?._id,deletePurchaseOrder)}>
                      <i class="bi bi-trash3 delete-icon"></i>
                    </IconButton>
                  </td>
                    </tr>
                  ))
                  : 
                  <tr>
                    <td colSpan={10}>No Data</td>
                  </tr>
                }
               
              </tbody>
                )
              }
              
            </table>
          </div>
        </div>
      </div>

      {/* //SingleView */}
      <Dialog open={openPOSingleView} maxWidth="lg"  onKeyDown={(e)=>e.key === "Escape" && setOpenPOSingleView(false)}>
        <div className="po-single-view-parent-container">
          <div className="top-bar">
            <p>Purchase View</p>
          </div>
          <hr />
          <div className="form-1-container">
            <div className="global-single-input po-input">
              <p>PO No.</p>
              <input type="text" disabled value={purchseOrderSingle?.purchaseID}/>
            </div>
            <div className="global-single-input po-input">
              <p>Supplier Name</p>
              <input type="text" disabled value={purchseOrderSingle?.supplierName}/>
            </div>
            <div className="global-single-input po-input">
              <p>Purchase Date</p>
              <input type="date" disabled value={purchseOrderSingle?.purchaseDate}/>
            </div>
            <div className="global-single-input po-input">
              <p>Pay Terms</p>
              <input type="text" disabled value={purchseOrderSingle?.payTerms}/>
            </div>
           
            <div className="global-single-input po-input">
              <p>Remarks</p>
              <textarea rows={4} disabled value={purchseOrderSingle?.remarks} />
            </div>
          </div>
          <div className="po-single-view-table-container global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Item Name</th>
                  <th>Stock Qty</th>
                  <th>Qty</th>
                  <th>Unit</th>
                  <th>Unit Cost</th>
                  <th>Amount</th>
                  <th>GRN Status</th>
                </tr>
              </thead>
              <tbody>
                {
                  purchseOrderSingle?.purchaseInfo?.map((r,i)=>(
                    <tr>
                    <td>{i+1}</td>
                    <td>{r?.itemName}</td>
                    <td>{r?.stock[0]?.dimensionStock}</td>
                    <td>{r?.quantity}</td>
                    <td>{r?.unit}</td>
                    <td>{r?.rate}</td>
                    <td>{parseFloat(r?.quantity)*parseFloat(r?.rate)}</td>
                    <td>---</td>
                  </tr>
                  ))
                }
               
              </tbody>
            </table>
          </div>
          <div className="cancel-btn-container">
            <button className="btn btn-secondary-outlined" onClick={closePODialog}>Close</button>
          </div>
        </div>
      </Dialog>

        {/* snackbar */}
        <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />

      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </>
  );
};
