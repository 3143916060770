import React, { useState, useEffect } from "react";
import {
  IconButton,
  Dialog,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  Skeleton,
} from "@mui/material";
import { PaymentsWO } from "../Orders/WorkOrder/PaymentsWO";
import {
  viewOutletExpenseAPICall,
  outletExpenseDocNoAPICall,
  addOutletExpenseAPICall,
  editOutletExpenseAPICall,
} from "../../../API/Expense/outletExpenseAPI";
import { useSelector } from "react-redux";
import { viewAllBranchesAPICall } from "../../../API/Settings/Company Settings/companySettingsAPI";
import { today, previousDate, convertDateFormat } from "../../../Js/Date";
import { viewAddExpenseTypeAPICall } from "../../../API/Expense/addExpenseTypeAPI";
import { verifyPasswordAPICall } from "../../../API/Login/VerifyPasswordAPI";
import SuccessSnackbar from "../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../Single Components/SnackBars/ErrorSnackbar";
import { viewPettyCashBalanceAPICall } from "../../../API/Expense/viewPettyCashBalanceAPI";
import { viewExpensePaymentType } from "../../../API/Expense/staffExpenseAPI";
import { filterObjFromList } from "../../../Js/generalFunctions";
import { viewPosSettingsAPICall } from "../PointOfSale/Configuration/Settings/posSettingsAPI";
import { LoadingForm } from "../../Single Components/LoadingForm";
export const OutletExpense = () => {
  // role based rendering
  const userRole = useSelector((state) => state.userRoleSlice.value);
  //view outlet expense
  const outletExpenseData = useSelector(
    (state) => state.outletExpenseSlice.value
  );
  // outlet expense doc no
  const outletExpenseDocNo = useSelector(
    (state) => state.outletExpenseDocNoSlice.value
  );
  // all branch list
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  // login response
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  // view all expense type
  const expenseTypeData = useSelector((state) => state.addExpenseType.value);
  // view petty cash balance
  const viewPettyCashBalance = useSelector(
    (state) => state.pettyCashBalanceSlice.value
  );

  const storeCode=localStorage.getItem('branchId')
  // user info
  const userLoginMeta=localStorage.getItem("login_meta")
  const userBranchId=JSON.parse(userLoginMeta)?.branchId
  const userBranchpk = JSON.parse(userLoginMeta)?.branchPk;

  const expPymentType=useSelector((state)=>state.staffExpenseSlice.staffExpPaymentType)
  const posSettingsList=useSelector((state)=>state.posSettingSlice.apiResList)
  const paymentType=[
    {type:"UPI"},
    {type:"Net Banking"},
    {type:"Cash"}]



  //Update Listener States
  const [isModify, setIsModify] = useState(false);
  // visibility states
  const [isVisible, setIsVisible] = useState(false);

  const [outletExpense, setOutletExpense] = useState(false);
  const [payments, setPayments] = useState(false);
  const [editPasswordDialog, setEditPasswordDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);

  const [branch, setBranch] = useState();
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [fromDate, setFromDate] = useState(previousDate);
  const [toDate, setToDate] = useState(today);
  // validation states
  const [branchAlert,setBranchAlert]=useState(false)
  const [expenseTypeAlert,setExpenseTypeAlert]=useState(false)
  const [amountAlert,setAmountAlert]=useState(false)
  const [paidByAlert,setPaidByAlert]=useState(false)
  // add outlet form
  const addOutletExpenseInitialStates = {
    branchName: "",
    branch: "",
    expenseType: "",
    date: today,
    amount: "",
    paidBy: null,
    creditAmount: viewPettyCashBalance?.data?.creditAmount,
    lastPaidAmount: viewPettyCashBalance?.data?.lastPaidAmount,
    lastPaidDate: viewPettyCashBalance?.data?.lastPaidDate,
    notes:""
  };
  const [addOutletExpenseForm, setAddOutletExpenseForm] = useState(
    addOutletExpenseInitialStates
  );
  // loading states
  const [isLoading,setIsLoading]=useState(false)
  const [searchKeyword,setSearchKeyword]=useState("")
  const [filteredSearchList,setFilteredSearchList]=useState([])
  // edit outlet expense state
  const [editedId, setEditedId] = useState();
  const [transNo, setTransNo] = useState();
  const [editPassword, setEditPassword] = useState();

  // snackbar state
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [snackMsg, setSnackMsg] = useState("Error!!");
  //Is Password verified
  const [isVerified, setIsVerified] = useState(false);

  //*Update Listener
  const updateListener = () => {
    setIsModify(!isModify);
  };
  // visibility function
  const visibilityFunction = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    viewOutletExpenseAPICall({branchId:storeCode},setIsLoading,setErrorSnackbar,setSnackMsg);
    viewAllBranchesAPICall();
    outletExpenseDocNoAPICall();
    viewAddExpenseTypeAPICall();
    viewPettyCashBalanceAPICall();
    viewPosSettingsAPICall()
  }, [isModify]);

  useEffect(()=>{
    if(posSettingsList?.posStatus === true && posSettingsList?.pettyCashStatus=== true){
      viewExpensePaymentType()
    }
    },[posSettingsList])

  //  onChange functions
  // admin view onchange fn
  const getBranch = (e) => {
    setBranch(e.target.value);
  };
  const getFromDate = (e) => {
    setFromDate(e.target.value);
  };
  const getToDate = (e) => {
    setToDate(e.target.value);
  };

  // Add outlet expense onChange fn
  const getDate = (e) => {
    setAddOutletExpenseForm({ ...addOutletExpenseForm, date: e.target.value });
  };
  const getAmount = (e) => {
    setAddOutletExpenseForm({
      ...addOutletExpenseForm,
      amount: e.target.value,
    });
    setAmountAlert(false)
    if(e.target.value===""){
      setAmountAlert(true)
    }
  };
  const getPaidBy = (e,newValue) => {
    setAddOutletExpenseForm({
      ...addOutletExpenseForm,
      paidBy:newValue ,
    });
    setPaidByAlert(false)
  };
  const getCreditAmount = (e) => {
    setAddOutletExpenseForm({
      ...addOutletExpenseForm,
      creditAmount: e.target.value,
    });
  };
  const lastPaidAmount = (e) => {
    setAddOutletExpenseForm({
      ...addOutletExpenseForm,
      lastPaidAmount: e.target.value,
    });
  };
  const lastPaidDate = (e) => {
    setAddOutletExpenseForm({
      ...addOutletExpenseForm,
      lastPaidDate: e.target.value,
    });
  };
  const getNotes=(e)=>{
    setAddOutletExpenseForm({...addOutletExpenseForm,notes:e.target.value})
  }
  // password dialog change fn
  const getPassword = (e) => {
    setEditPassword(e.target.value);
  };

  // snackbar functions
  const closeSuccessSnackbar = () => {
    setSuccessSnackbar(false);
  };
  const closeErrorSnackbar = () => {
    setErrorSnackbar(false);
  };
  // admin view outlet expense submit btn fn
  const adminViewOutletExpenseSubmitFn = () => {
    viewOutletExpenseAPICall({
      branchId: branch,
      fromDate: `${fromDate} 00:00:00`,
      endDate: `${toDate} 23:59:59`,
    },setIsLoading,setErrorSnackbar,setSnackMsg);
  };

  // add outlet expense open dialog
  const openAddOutletExpenseDialog = () => {
    setAddOutletExpenseForm(addOutletExpenseInitialStates);
    setOutletExpense(true);
    pettyCashBalance();
  };

  // useEffect(()=>{
  //   setAddOutletExpenseForm({
  //     ...addOutletExpenseForm,
  //     creditAmount:viewPettyCashBalance?.data?.creditAmount,
  //     lastPaidAmount:viewPettyCashBalance?.data?.lastPaidAmount,
  //     lastPaidDate:viewPettyCashBalance?.data?.lastPaidDate
  //    })
  // },[viewPettyCashBalance])

  const pettyCashBalance = () => {
    setAddOutletExpenseForm({
      ...addOutletExpenseForm,
      creditAmount: viewPettyCashBalance?.data?.creditAmount,
      lastPaidAmount: viewPettyCashBalance?.data?.lastPaidAmount,
      lastPaidDate: viewPettyCashBalance?.data?.lastPaidDate,
    });
  };

  const bodyToApi = {
    expenseType: addOutletExpenseForm.expenseType?._id,
    expenseAccountId:addOutletExpenseForm.expenseType?.accountId,
    amount: Number(addOutletExpenseForm.amount),
    date: addOutletExpenseForm.date,
    // paidBy: addOutletExpenseForm.paidBy?.type,
    branchId:userBranchpk,
    creditAmount: addOutletExpenseForm.creditAmount,
    lastPaidAmount: addOutletExpenseForm.lastPaidAmount,
    lastPaidDate: addOutletExpenseForm.lastPaidDate,
    paymentMethod:{
      type: addOutletExpenseForm.paidBy?.type,
      journalId: addOutletExpenseForm.paidBy?.journalId,
    },
    narration:addOutletExpenseForm.notes
  };
  /// add outlet expense
  const addOutletExpenseSubmitFn = () => {

    if(addOutletExpenseForm.branchName===""||addOutletExpenseForm.branchName===undefined){
      setBranchAlert(true)
    }
    else if(addOutletExpenseForm.expenseType===""||addOutletExpenseForm.expenseType===undefined){
      setExpenseTypeAlert(true)
    }
    else if(addOutletExpenseForm.amount===""){
      setAmountAlert(true)
    }
    else if(addOutletExpenseForm.paidBy===""){
      setPaidByAlert(true)
    }
    else{

      addOutletExpenseAPICall(
        bodyToApi,
        updateListener,
        setSuccessSnackbar,
        setErrorSnackbar,
        setSnackMsg,
      setOutletExpense,setLoadingForm
      );
      // console.log(bodyToApi);
    }
    };

  // edit outlet expense
  const editOutletExpenseBtnClick = (
    id,
    transNo,
    branchId,
    branchName,
    type,
    date,
    amount,
    paidBy,
    creditAmt,
    lastPaidAmount,
    lastPaidDate,
    data
  ) => {
    setEditPasswordDialog(true);
    setEditPassword();
    setIsVisible(false);
    pettyCashBalance();
    //  setEditDialog(true)
    setEditedId(id);
    setTransNo(transNo);
    setAddOutletExpenseForm({
      ...addOutletExpenseForm,
      branchName: branchId,
      branch: branchName,
      expenseType: type,
      date: date,
      amount: amount,
      paidBy: filterObjFromList("type",
      posSettingsList?.posStatus === true && posSettingsList?.pettyCashStatus=== true?expPymentType:paymentType,
      "paidBy",
      data
      ),
      creditAmount: creditAmt,
      lastPaidAmount: lastPaidAmount,
      lastPaidDate: lastPaidDate,
      notes:data?.narration
    });
  };

  // edit password dialog submit btn fn
  const passwordDialogSubmitBtn = () => {
    // setEditPasswordDialog(false)
    // setEditDialog(true)
    verifyPasswordAPICall(
      { password: editPassword },
      setEditPasswordDialog,
      setErrorSnackbar,
      setSnackMsg,
      setEditDialog
    );
  };

  const editedBodyToApi = {
    expenseType: addOutletExpenseForm.expenseType,
    amount: addOutletExpenseForm.amount,
    paidBy: addOutletExpenseForm.paidBy?.type,
    _id: editedId,
    narration:addOutletExpenseForm.notes,
  };
  // outlet expense edit submit btn fn
  const editSubmitButton = () => {
    editOutletExpenseAPICall(
      editedBodyToApi,
      updateListener,
      setEditDialog,
      setSuccessSnackbar,
      setErrorSnackbar,
      setSnackMsg,setLoadingForm
    );
  };

  // payment dialog functions
  const openPaymentDialog = () => {
    setPayments(true);
  };
  const closePaymentDialog = () => {
    setPayments(false);
  };
  const getSearchKeyword=(e)=>{
    setSearchKeyword(e.target.value)
  }
  const SearchFilter=(chars,listToFilter)=>{
    let searchedArray=[]
    let filteredArray=[]
    if(chars!==""){
      searchedArray=listToFilter.filter((obj)=>
      obj?.expenseName?.toLowerCase()?.includes(chars?.toLowerCase())||
      obj?.shiftId?.toString()?.toLowerCase()?.includes(chars?.toLowerCase())||
      obj?.branchId?.toLowerCase()?.includes(chars?.toLowerCase())||
      obj?.locationName?.toLowerCase()?.includes(chars?.toLowerCase())||
      obj?.transNo?.toLowerCase()?.includes(chars?.toLowerCase())
    )
      filteredArray=searchedArray
    }
    else{
      filteredArray=listToFilter
    }
    return filteredArray
  }
  useEffect(()=>{
    if(outletExpenseData!==undefined){
      let filteredArray=[]
      setFilteredSearchList(outletExpenseData)
      filteredArray=SearchFilter(searchKeyword,outletExpenseData)
      setFilteredSearchList(filteredArray)    
    }
    else{
      setFilteredSearchList([])
    }
    },[searchKeyword,outletExpenseData])

  useEffect(()=>{
    setAddOutletExpenseForm({
      ...addOutletExpenseForm,
      branchName: storeCode,
    })
    let filteredArray=allBranchesList !==undefined && allBranchesList.filter((obj)=>
        obj?.storeCode===storeCode
    )
    setSelectedBranch(filteredArray.length!==0 &&filteredArray[0])
  },[storeCode,allBranchesList])
  
  return (
    <div className="global-page-parent-container">
      <p className="breadcrumb">Expense &gt; Outlet Expense</p>
      {userRole === "admin" && (
        <div className="add-expense-top-container">
          <div className="add-expense-input-container">
            <div className="global-single-input auto-complete">
              <p>Branch</p>
              <Autocomplete
                sx={{ width: "100%" }}
                options={allBranchesList || [""]}
                getOptionLabel={(option) =>
                  `${option?.storeCode}-${option?.branchName}`
                }
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Branch" />
                )}
                onChange={(e, newValue) => setBranch(newValue?.storeCode)}
              />
            </div>
            <div className="global-single-input">
              <p>From</p>
              <input type="date" onChange={getFromDate} value={fromDate} />
            </div>
            <div className="global-single-input">
              <p>To</p>
              <input type="date" onChange={getFromDate}value={toDate} />
            </div>
            <button
              className="btn btn-primary add-expense-button"
              onClick={adminViewOutletExpenseSubmitFn}
            >
              Submit
            </button>
          </div>
        </div>
      )}
      <div className="global-white-bg-container">
        <div className="expense-top-container">
          <h3>Outlet Expense</h3>
          <div className="supplier-manage-top-container-right">
              <div className="view-order-search-container view-order-admin-search-container">
                  <input
                      placeholder="Expense Type,Doc No,Shift Id,Branch"
                       value={searchKeyword}
                       onChange={getSearchKeyword}
                      />
                  <IconButton>
                      <i class="bi bi-search search-icon-order"></i>
                  </IconButton>
              </div>
              <button style={{width:"30%"}}
              onClick={openAddOutletExpenseDialog}
              className={
                userRole === "admin"
                  ? "btn btn-primary-disabled expense-button"
                  : "btn btn-primary expense-button"
              }
              disabled={userRole === "admin"}
              >
              Add Expense
              </button>
            </div>
            
        </div>
        {/* table */}
        
          <div className="global-table-container">
            <table className="global-table">
              <thead style={{ zIndex: "0" }}>
                <tr>
                  <th>No</th>
                  {userRole === "admin" && (
                    <>
                      <th>Branch Code</th>
                      <th>Branch Name</th>
                    </>
                  )}
                  <th>Shift ID</th>
                  <th>Doc No</th>
                  <th>Expense Type</th>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Paid By</th>
                  {userRole === "admin" && <th>Action</th>}
                </tr>
              </thead>
              {
              isLoading?
              <tbody>
                <tr>
                  <td colSpan={10}>
                    {/* <Loader /> */}
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                    />
                  </td>
                  </tr> 
                  <tr>
                  <td colSpan={10}>
                    {/* <Loader /> */}
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                    />
                  </td>
                  </tr> 
                  <tr>
                  <td colSpan={10}>
                    {/* <Loader /> */}
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                    />
                  </td>
                  </tr> 
                  <tr>
                  <td colSpan={10}>
                    {/* <Loader /> */}
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                    />
                  </td>
                  </tr>
              </tbody>:
              <tbody>
                {filteredSearchList?.length!==0?
                  filteredSearchList
                  ?.slice(0)
                  .reverse()
                  .map((r, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      {userRole === "admin" && (
                        <>
                          <td>{r.branchId}</td>
                          <td>{r.locationName}</td>
                        </>
                      )}
                      <td>{r?.shiftId}</td>
                      <td>{r.transNo}</td>
                      <td>{r.expenseName}</td>
                      <td>{convertDateFormat(r.date)}</td>
                      <td>{r.amount}</td>
                      <td>{r.paidBy}</td>
                      {userRole === "admin" && (
                        <td>
                          <IconButton
                            onClick={() =>
                              editOutletExpenseBtnClick(
                                r._id,
                                r.transNo,
                                r.branchId,
                                r.locationName,
                                r.expenseName,
                                r.date,
                                r.amount,
                                r.paidBy,
                                r.creditAmount,
                                r.lastPaidAmount,
                                r.lastPaidDate,
                                r
                              )
                            }
                          >
                            <i class="bi bi-pencil-square edit-icon"></i>
                          </IconButton>
                        </td>
                      )}
                    </tr>
                  )):
                  <tr><td colSpan={10} style={{textAlign:"center"}}>No Records</td></tr>
                  }
              </tbody>}
            </table>
          </div>
       
      </div>

      {/* add expense */}
      <Dialog
        open={outletExpense}
        maxWidth="lg"
        sx={{ zIndex: 1210, marginTop: "2%" }}
        onKeyDown={(e)=>e.key === "Escape" && setOutletExpense(false)}
      >
        <div className="add-expense-dialog-container">
          <h3>Add Outlet Expense</h3>
          <hr className="global-hr" />
          <div className="add-outlet-expense-container">
            <div className="global-single-input outlet-expense-input">
              <p>Doc No</p>
              <input type="text" value={outletExpenseDocNo?.transNo} disabled />
            </div>
            <div className="global-single-input outlet-expense-input auto-complete">
              <p>Branch</p>
              <Autocomplete
                sx={{ width: "100%" }}
                options={allBranchesList || [""]}
                getOptionLabel={(option) =>
                  `${option?.storeCode}-${option?.branchName}`
                }
                renderInput={(params) => <TextField {...params} />}
                onChange={(e, newValue) =>{

                  setAddOutletExpenseForm({
                    ...addOutletExpenseForm,
                    branchName: newValue?.storeCode,
                  })
                  setSelectedBranch(newValue)
                  setBranchAlert(false)
                  if(newValue===null){
                    setBranchAlert(true)
                  }
                }
                }
                disabled={userRole==="user"}
                value={selectedBranch}
              />
              {branchAlert?
                <p className="doc-validation-alert">
                  Select a Branch !!
                </p>
              :""}
            </div>
            <div className="global-single-input outlet-expense-input auto-complete">
              <p>Expense Type</p>
              <Autocomplete
                sx={{ width: "100%" }}
                options={expenseTypeData || [""]}
                getOptionLabel={(option) => option?.expenseType}
                renderInput={(params) => <TextField {...params} />}
                onChange={(e, newValue) =>{

                  setAddOutletExpenseForm({
                    ...addOutletExpenseForm,
                    expenseType: newValue,
                  })
                  setExpenseTypeAlert(false)
                  if(newValue===null){
                    setExpenseTypeAlert(true)
                  }
                }
                }
              />
              {expenseTypeAlert?
                <p className="doc-validation-alert">
                  Select a expense type !!
                </p>
              :""}
            </div>
            <div className="global-single-input outlet-expense-input">
              <p>Date</p>
              <input
                type="date"
                value={addOutletExpenseForm.date}
                onChange={getDate}
              />
            </div>
            <div className="global-single-input outlet-expense-input">
              <p>Amount</p>
              <input
                type="text"
                value={addOutletExpenseForm.amount}
                onChange={getAmount}
              />
              {amountAlert?
                <p className="doc-validation-alert">
                  Fill this field !!
                </p>
              :""}
            </div>
            <div className="global-single-input outlet-expense-input auto-complete">
              <p>Payment Type</p>
              <Autocomplete
             sx={{width:"100%"}}
             options={
              posSettingsList?.posStatus === true 
              && posSettingsList?.pettyCashStatus=== true?
              expPymentType:paymentType||[]}
             getOptionLabel={(option)=>option?.type}
             renderInput={(params)=>
              <TextField  {...params}
              placeholder="Select Payment Type"
              />
              
            }
            value={addOutletExpenseForm.paidBy} 
            onChange={getPaidBy}
          
           />
        
              {/* <Select value={addOutletExpenseForm.paidBy} onChange={getPaidBy}>
                <MenuItem value="Cash">Cash</MenuItem>
                <MenuItem value="Card">Card</MenuItem>
                <MenuItem value="UPI">UPI</MenuItem>
              </Select> */}
              {paidByAlert?
                <p className="doc-validation-alert">
                  Select a value !!
                </p>
              :""}
            </div>
            <div className="global-single-input outlet-expense-input">
              <p>Credit Amount</p>
              <input
                type="text"
                disabled
                value={viewPettyCashBalance?.data?.creditAmount}
                //  value={addOutletExpenseForm.creditAmount}
                // onChange={getCreditAmount}
              />
            </div>
            <div className="global-single-input outlet-expense-input">
              <p>Last Paid Amount</p>
              <input
                type="text"
                disabled
                value={viewPettyCashBalance?.data?.lastPaidAmount}
                // value={addOutletExpenseForm.lastPaidAmount}
                // onChange={lastPaidAmount}
              />
            </div>
            <div className="global-single-input outlet-expense-input">
              <p>Last Paid Date</p>
              <input
                type="date"
                // value={addOutletExpenseForm.lastPaidDate}
                // onChange={lastPaidDate}
                disabled
                value={viewPettyCashBalance?.data?.lastPaidDate}
              />
            </div>
            <div className="global-single-input outlet-expense-input">
              <p>Notes</p>
              <input
                type="text"
                value={addOutletExpenseForm.notes}
                onChange={getNotes}
              />
             
            </div>
            {/* <div className="global-single-input outlet-expense-input">
              <button
                className="outlet-expense-payment-button"
                onClick={() => openPaymentDialog()}
              >
                Payment
              </button>
            </div> */}
          </div>

          <div className="expense-button-container">
            <button
              disabled={payments === true}
              onClick={() => setOutletExpense(false)}
              className={
                payments === true
                  ? "btn btn-secondary-outlined disabled-outline"
                  : "btn btn-secondary-outlined"
              }
            >
              Cancel
            </button>
            <button
              disabled={payments === true}
              className={
                payments === true
                  ? "btn btn-primary disabled"
                  : "btn btn-primary"
              }
              onClick={addOutletExpenseSubmitFn}
            >
              Submit
            </button>
          </div>
        </div>
      </Dialog>

      {/* payment dialog */}
      {payments === true && (
        <PaymentsWO
          closePayments={closePaymentDialog}
          // width="35%"
          parentWidth='78%'
          right='17%'
          // left="64%"
          boxShadow="0 0 8px 2px #eee"
        />
      )}

      {/* edit password dialog */}
      <Dialog open={editPasswordDialog} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setEditPasswordDialog(false)}>
        <div className="add-expense-dialog-container">
          <h3>Edit Outlet Expense</h3>
          <div className="global-single-input outlet-expense-password-input">
            <p>Password</p>
            <input
              type={isVisible ? "text" : "password"}
              value={editPassword}
              onChange={getPassword}
              onKeyDown={(e) => {
                e.key === "Enter" && passwordDialogSubmitBtn();
              }}
            />
            <div className="password-visibility-icon">
              <IconButton onClick={visibilityFunction}>
                {isVisible ? (
                  <i class="bi bi-eye-slash"></i>
                ) : (
                  <i class="bi bi-eye"></i>
                )}
              </IconButton>
            </div>
          </div>
          <div className="expense-button-container">
            <button
              onClick={() => setEditPasswordDialog(false)}
              className="btn btn-secondary-outlined"
            >
              Cancel
            </button>
            <button
              onClick={passwordDialogSubmitBtn}
              className="btn btn-primary"
              style={{ width: "30%" }}
            >
              Submit
            </button>
          </div>
        </div>
      </Dialog>

      {/* edit dialog */}
      <Dialog open={editDialog} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setEditDialog(false)}>
        <div className="add-expense-dialog-container">
          <h3>Edit Outlet Expense</h3>
          <hr className="global-hr" />
          <div className="add-outlet-expense-container">
            <div className="global-single-input outlet-expense-input">
              <p>Doc No</p>
              <input type="text" value={transNo} disabled />
            </div>
            <div className="global-single-input outlet-expense-input auto-complete">
              <p>Branch</p>
              <Autocomplete
                disabled
                sx={{ width: "100%" }}
                // value={addOutletExpenseForm.branchName}
                defaultValue={{
                  storeCode: addOutletExpenseForm.branchName,
                  branchName: addOutletExpenseForm.branch,
                }}
                options={allBranchesList || [""]}
                getOptionLabel={(option) =>
                  `${option?.storeCode}-${option?.branchName}`
                }
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Branch" />
                )}
                onChange={(e, newValue) =>
                  setAddOutletExpenseForm({
                    ...addOutletExpenseForm,
                    branchName: newValue?.storeCode,
                  })
                }
              />
            </div>
            <div className="global-single-input outlet-expense-input auto-complete">
              <p>Expense Type</p>
              <Autocomplete
                sx={{ width: "100%" }}
                // value={addOutletExpenseForm.expenseType}
                defaultValue={{ expenseType: addOutletExpenseForm.expenseType }}
                options={expenseTypeData || [""]}
                getOptionLabel={(option) => option?.expenseType}
                renderInput={(params) => <TextField {...params} />}
                onChange={(e, newValue) => {
                  setAddOutletExpenseForm({
                    ...addOutletExpenseForm,
                    expenseType: newValue?._id,
                  });
                  console.log(newValue);
                }}
              />
            </div>
            <div className="global-single-input outlet-expense-input">
              <p>Date</p>
              <input
                type="date"
                disabled
                value={addOutletExpenseForm.date}
                onChange={getDate}
              />
            </div>
            <div className="global-single-input outlet-expense-input">
              <p>Amount</p>
              <input
                type="text"
                value={addOutletExpenseForm.amount}
                onChange={getAmount}
              />
            </div>
            <div className="global-single-input outlet-expense-input auto-complete">
              <p>Paid By</p>
              {/* <Select value={addOutletExpenseForm.paidBy} onChange={getPaidBy}>
                <MenuItem value="Cash">Cash</MenuItem>
                <MenuItem value="Card">Card</MenuItem>
                <MenuItem value="UPI">UPI</MenuItem>
              </Select> */}
              <Autocomplete
             sx={{width:"100%"}}
             options={posSettingsList?.posStatus === true && posSettingsList?.pettyCashStatus=== true?expPymentType:paymentType||[]}
             getOptionLabel={(option)=>option?.type}
             renderInput={(params)=>
              <TextField  {...params}
              placeholder="Select Payment Type"
              />
              
            }
            value={addOutletExpenseForm.paidBy} 
            onChange={getPaidBy}
          
           />
            </div>
            <div className="global-single-input outlet-expense-input">
              <p>Credit Amount</p>
              <input
                type="text"
                disabled
                value={addOutletExpenseForm.creditAmount}
                onChange={getCreditAmount}
              />
            </div>
            <div className="global-single-input outlet-expense-input">
              <p>Last Paid Amount</p>
              <input
                type="text"
                disabled
                value={addOutletExpenseForm.lastPaidAmount}
                onChange={lastPaidAmount}
              />
            </div>
            <div className="global-single-input outlet-expense-input">
              <p>Last Paid Date</p>
              <input
                type="date"
                disabled
                value={addOutletExpenseForm.lastPaidDate}
                onChange={lastPaidDate}
              />
            </div>
            <div className="global-single-input outlet-expense-input">
              <p>Notes</p>
              <input
                type="text"
                value={addOutletExpenseForm.notes}
                onChange={getNotes}
              />
             
            </div>
          </div>

          <div className="expense-button-container">
            <button
              onClick={() => setEditDialog(false)}
              className="btn btn-secondary-outlined"
            >
              Cancel
            </button>
            <button onClick={editSubmitButton} className="btn btn-primary">
              Submit
            </button>
          </div>
        </div>
      </Dialog>
      <SuccessSnackbar
        open={successSnackbar}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />

      <ErrorSnackbar
        open={errorSnackbar}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
      <LoadingForm loading={loadingForm}/>
    </div>
  );
};
