import { createSlice } from "@reduxjs/toolkit";

export const longTermLiabilitySlice = createSlice({
  name: "longTermLiabilitySlice",
  initialState: {
    openingBalance:'',
  },
  reducers: {
    setOpeningBalance: (state, action) => {
      state.openingBalance = action.payload;
    },
  },
});

export const {setOpeningBalance} = longTermLiabilitySlice.actions;
export default longTermLiabilitySlice.reducer;
