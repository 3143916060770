import { createSlice } from "@reduxjs/toolkit";

export const jobCompletionWorkorderListSlice=createSlice({
    name:'jobCompletionWorkorderListSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_job_completion_workorder_list:(state,action)=>{
            state.value=action.payload.jobCompletionWorkorderData
        }
    }
})
export const {get_job_completion_workorder_list}=jobCompletionWorkorderListSlice.actions;
export default jobCompletionWorkorderListSlice.reducer