import { createSlice } from "@reduxjs/toolkit";


export const newBillingSlice=createSlice({
    name:"newBillingSlice",
    initialState:{
        productsList:undefined,
        subCategoryList:undefined,
        paymentTypeList:undefined,
        barCodeList:undefined,
    },
    reducers:{
        get_products_list_for_billing:(state,action)=>{
            state.productsList=action.payload.billingProductsList
        },
        get_sub_category_list_for_billing:(state,action)=>{
            state.subCategoryList=action.payload.billingSubCategories
        },
        get_payment_types_list_for_billing:(state,action)=>{
            state.paymentTypeList=action.payload.billingPaymentTypes
        },
        get_barcode_product_list_add_billing:(state,action)=>{
            state.barCodeList=action.payload.barCodeItems
        }
    }
})

export const {get_products_list_for_billing,
              get_sub_category_list_for_billing,
              get_payment_types_list_for_billing,
              get_barcode_product_list_add_billing
            }=newBillingSlice.actions;

export default newBillingSlice.reducer