import { createSlice } from "@reduxjs/toolkit";

export const userRoleSlice = createSlice({
  name: "userRoleSlice",
  initialState: {
    value: undefined,
  },
  reducers: {
    update_userRole: (state, action) => {
      state.value = action.payload.userRole;
    },
  },
});

export const { update_userRole } = userRoleSlice.actions;

export default userRoleSlice.reducer;
