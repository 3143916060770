import React from "react";
import InputBox from "../InputBox";
import DropdownInputBox from "../DropdownInputBox";
import SubmitBtn from "../SubmitBtn";
import { useDispatch, useSelector } from "react-redux";
import {
  setBankAccountDetails,
  setBankAccountNo,
  setBankName,
  setBranchName,
  setIfscCode,
  setOpeningBalance,
} from "../../../../../Redux/Account/depositAssetSlice";

const DepositAsset = () => {
  const bankAccountDetailsOption = [{ option: "Yes" }, { option: "No" }];
  const dispatch = useDispatch();
  const {
    openingBalance,
    bankAccountDetails,
    bankName,
    bankAccountNo,
    ifscCode,
    branchName,
  } = useSelector((state) => state.depositAssetSlice);
  const depositAssetSlice = useSelector((state) => state.depositAssetSlice);
  const handleDepositAssetSubmit = () => {
    console.log(depositAssetSlice);
  };
  return (
    <>
      <div className="ledger-section-container-input-bank  ">
        <div style={{ padding: "2% 4%" }} className="bottom-line">
          {/*Opening Balance*/}
          <InputBox
            label={"Opening Balance"}
            value={openingBalance}
            containerStyle={{ width: "31%" }}
            onChange={(e) => dispatch(setOpeningBalance(e.target.value))}
          />
        </div>
        <div
          style={{ padding: "2% 4%" }}
          className="ledger-section-container-input-bank bottom-line"
        >
          <div>
            {/*bank account details */}

            <DropdownInputBox
              labelName={"Bank account details"}
              value={bankAccountDetails}
              options={bankAccountDetailsOption}
              onChange={(e, value) => dispatch(setBankAccountDetails(value))}
            />

            {/*bank Name*/}
            <InputBox
              label={"Bank Name"}
              value={bankName}
              onChange={(e) => dispatch(setBankName(e.target.value))}
            />
            {/*bank account number  */}
            <InputBox
              label={"Bank account number"}
              value={bankAccountNo}
              onChange={(e) => dispatch(setBankAccountNo(e.target.value))}
            />
          </div>
          <div style={{ justifyContent: "flex-start" }}>
            {/*IFSC Code  */}
            <InputBox
              label={"IFSC Code"}
              value={ifscCode}
              onChange={(e) => dispatch(setIfscCode(e.target.value))}
              containerStyle={{width:'31%'}}

            />

            {/*branch name */}
            <InputBox
              label={"Branch Name"}
              value={branchName}
              containerStyle={{width:'31%'}}
              onChange={(e) => dispatch(setBranchName(e.target.value))}
            />
           
          </div>
        </div>

        {/* submitButton */}
      </div>
      <SubmitBtn onClick={handleDepositAssetSubmit} />
    </>
  );
};

export default DepositAsset;
