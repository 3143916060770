import { createSlice } from "@reduxjs/toolkit";

export const ConfigurationSlice = createSlice({
  name: "ConfigurationSlice",
  initialState: {
    value: undefined,
    discountValue:undefined,
    taxValue:undefined,
    MultiCurrency:undefined
  },
  reducers: {
    get_costing_method: (state, action) => {
      state.value = action.payload.constingMethodData;
    },
    get_discount_configuration:(state,action)=>{
      state.discountValue=action.payload.discountConfigData;
    },
    get_tax_configuration:(state,action)=>{
      state.taxValue=action.payload.taxConfigData
    },
    get_MultiCurrencyConfiguration :(state,action)=>{
      state.MultiCurrency = action.payload.MultiConfiguration
    }
  },
});

export const { get_costing_method,get_discount_configuration,get_tax_configuration,get_MultiCurrencyConfiguration} = ConfigurationSlice.actions;

export default ConfigurationSlice.reducer;