import { update_login_response } from "../Redux/Login/loginResponseSlice";
import store from "../Redux/store";
import Swal from "sweetalert2";
import * as XLSX from 'xlsx';
import CountryWithStates from "../Assets/JSON/countryStates.json";
//Search by word Fn
export const searchByWord = (
  originalArray,
  filterArray,
  keyword,
  stringKeyOfObj
) => {
  let searchResult = [];
  if (keyword !== "") {
    searchResult = originalArray?.filter((obj) =>
      obj?.stringKeyOfObj?.toLowerCase()?.includes(keyword?.toLowerCase())
    );

    filterArray = searchResult;
  } else {
    filterArray = originalArray;
  }

  return filterArray;
};

//*Logout User
export const logOutUser =
  (isBtnClick, userRole, shiftStatus, confirmPasswordDialog) => () => {
    //   const history = useHistory();
    if (userRole === "user") {
      if (shiftStatus === true) {
        Swal.fire({
          // title: 'Are you sure?',
          text: 'Do you want to end shift or log out?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Log Out',
          cancelButtonText: 'Shift End',
          reverseButtons: true, // To swap the positions of the buttons
        }).then((result) => {
          if (result.isConfirmed) {
            // Handle save action
            localStorage.clear();
            window.location.replace("/");
            if (isBtnClick) {
              setTimeout(() => {
                alert("Successfully Logged Out");
              }, 400);
            }
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            
            confirmPasswordDialog();
            
          }
        });
      } else {
        localStorage.clear();
        window.location.replace("/");
        if (isBtnClick) {
          setTimeout(() => {
            alert("Successfully Logged Out");
          }, 400);
        }
      }
    } else {
      localStorage.clear();
      window.location.replace("/");
      if (isBtnClick) {
        setTimeout(() => {
          alert("Successfully Logged Out");
        }, 400);
      }
    }

    // localStorage.clear();
    // localStorage.setItem('shitBtnState',true)
    // Store.dispatch(login_status_update({ loginData: false }));
    // console.log(isBtnClick);
  };

//* update UserName and Image from local Storage
const userInfo = localStorage.getItem("userInfo");
store.dispatch(
  update_login_response({
    loginResponse: userInfo !== null && JSON.parse(userInfo),
  })
);

//*Success Alert Message
export const successAlertMessage = (title) => () => {
  Swal.fire({
    position: "center",
    icon: "success",
    title: title,
    showConfirmButton: false,
    showCancelButton: false,
    // timer: 2500,
    confirmButtonText: "Submit",
    customClass: {
      confirmButton: "success-alert-message-submit-btn",
      cancelButton: "success-alert-message-cancel-btn",
    },
  });
};
export const deleteAlertMessage = (id, deleteFunction) => () => {
  Swal.fire({
    position: "center",
    icon: "warning",
    title: "Are you sure want to delete this data",

    // text: "You won't be able to revert this!",
    showCancelButton: true,
    confirmButtonColor: "#f80000",
    confirmButtonText: "Delete",
    customClass: {
      cancelButton: "sweet-alert-cancel-button",
      confirmButton: "sweet-alert-submit-button",
      icon: "icon-color",
    },
  }).then((result) => {
    if (result.isConfirmed) {
      deleteFunction(id);
    }
    // else if (result.isDenied) {
    //    cancelFunction()
    // }
  });
};

//*Calculate CalculatedPrice(tax included)
export const calculatePrice = (price, tax) => {
  let calculatedPrice = 0;
  let taxAmount = 0;

  taxAmount = (parseFloat(price) * parseFloat(tax)) / 100;
  calculatedPrice = parseFloat(price) + parseFloat(taxAmount);

  return calculatedPrice;
};

//*Extract States of selected country
export const extractStatesFromCountry = (selectedCountry) => {
  let extractedStates = CountryWithStates.countries?.filter(
    (obj, i) => obj?.country.toLowerCase() === selectedCountry
  );

  return extractedStates;
};

//*Calculate Total Cost (unit Cost * adjust qty)
export const calculateTotalCost = (unitCost, adjQty) => {
  if (unitCost === "") {
    unitCost = 0;
  } else {
    unitCost = unitCost;
  }
  if (adjQty === "") {
    adjQty = 0;
  } else {
    adjQty = adjQty;
  }
  let totalCost = parseFloat(unitCost) * parseFloat(adjQty);

  return totalCost;
};

//* Extract single object from Object List for Single view
//extract Selected  object for single view [invoke on single view icon button click]
export const extractObjectForSingleView = (selectedId, completeList) => {
  let extractedObj = [];
  if (completeList !== undefined) {
    extractedObj = completeList?.filter((obj) => obj?._id === selectedId);
  }
  return extractedObj;
};

//Extract Single Object General Fn
// fullList--[]
//key--string
//compareWith---string||number

export const extractSingleObjectGeneral = (fullList, key, compareWith) => {
  let result = null;
  if (fullList !== undefined) {
    result = fullList?.filter((obj) => obj[key] === compareWith);
  }
  return result;
};

//*calculate size of a file and validate
export const validateFileSize = (file, minSize, maxSize) => {
  let actualFileSize = null;
  let fileSizeInKb = null;
  let returnObject = {
    actualFileSize,
    minSize,
    maxSize,
    isValid: false,
  };
  //Get Size of file
  actualFileSize = file?.size;

  //convert file size from bytes to kb
  if (actualFileSize !== null) {
    fileSizeInKb = parseFloat(actualFileSize) / 1024;
  }

  //Check file size
  if (fileSizeInKb <= maxSize && fileSizeInKb >= minSize) {
    returnObject.actualFileSize = fileSizeInKb;
    returnObject.isValid = true;
  } else {
    returnObject.actualFileSize = fileSizeInKb;
    returnObject.isValid = false;
  }

  return returnObject;
};

//*Calculate work order total amount [itemRate*stitchRate*qty]
export const calculateWorkOrderTotalAmount = (itemRate, stitchRate, qty) => {
  let totalAmount = 0;
  // if (itemRate !== "" && stitchRate !== "" && qty !== "") {
    totalAmount =
      (parseFloat(itemRate===""?0:itemRate) + parseFloat(stitchRate===""?0:stitchRate)) * parseFloat(qty===""?0:qty);
  // }
  return totalAmount;
};

//Sorting By String value, Date Value, Integer value(7/jan/23)
export const sortGeneral = (list, type, keyName, isAscending) => {
  let sortedArray = null;
  if (list !== undefined) {
    if (type === "String") {
      sortedArray = [...list].sort((a, b) => {
        const key1 = a[keyName]?.toUpperCase();
        const key2 = b[keyName]?.toUpperCase();
        if (isAscending) {
          if (key1 < key2) {
            return -1;
          }
          if (key1 > key2) {
            return 1;
          }
          //2 strings must be equal
          return 0;
        } else {
          if (key1 < key2) {
            return 1;
          }
          if (key1 > key2) {
            return -1;
          }
          //2 strings must be equal
          return 0;
        }
      });
    }
    if (type === "Number" || type === "Date") {
      sortedArray = [...list].sort((a, b) => {
        const key1 = a[keyName];
        const key2 = b[keyName];
        if (isAscending) {
          if (key1 < key2) {
            return -1;
          }
          if (key1 > key2) {
            return 1;
          }
          //2 strings must be equal
          return 0;
        } else {
          if (key1 < key2) {
            return 1;
          }
          if (key1 > key2) {
            return -1;
          }
          //2 strings must be equal
          return 0;
        }
      });
    }
  }

  return sortedArray;
};

//Remove Duplicate from Array of Objects
export const removeDuplicateFromAoO = (list) => {
  if (list !== undefined) {
    let unique = [...new Set(list?.map((obj) => JSON.stringify(obj)))].map(
      (obj) => JSON.parse(obj)
    );
    return unique;
  }
};

//Convert percentage to amount
export const convertPercentageToAmount = (total = 0, percentage = 0) => {
  let amount = null;

  amount = (total * percentage) / 100;

  return amount;
};

// filter obj from array

export const filterObjFromList=(key,list,keyToMatch,listToFilter)=>{
// console.log(key,list,keyToMatch,listToFilter);
if(Array.isArray(list)){
  if (listToFilter && listToFilter[keyToMatch]) {
    let filteredArray = list?.filter((obj) => obj[key] === listToFilter[keyToMatch]);
    return filteredArray !== undefined ? (filteredArray?.length !== 0 ? filteredArray[0] : null) : null;
    
  } else {
    return null;
  }
}
 

}

// set decimal position when input


export const roundNumericFields = (array, keysToRound) => {
  const newArray = array.map((item) => {
    keysToRound.forEach((key) => {
      if (item[key]) {
        item[key] = parseFloat(item[key]).toFixed(2);
      }
    });
    return item;
  });
  return newArray;
};
//function to convert json to excel
export const convertExcelToJson = (file) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject(new Error('No file provided'));
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

      resolve(sheetData);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsBinaryString(file);
  });
};

export const convertArrayToExcel = (dataArray, filename) => {
  const dataArrayLength = dataArray.length;
  const worksheet = XLSX.utils.json_to_sheet(dataArray);
  XLSX.utils.sheet_add_aoa(worksheet, [[filename]], { origin: "A1" });
  worksheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: dataArrayLength } }];
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  XLSX.writeFile(workbook, `${filename}.xlsx`);
};

// filter an obj from the list

export const findObjFromList = (key, list, keyToMatch, listToFilter) => {
  if (Array.isArray(list)) {
    if (listToFilter && listToFilter[keyToMatch]) {
      return list.find(obj => obj[key] === listToFilter[keyToMatch]) || null;
    } else {
      return null;
    }
  }
  return null;
};