import {createSlice} from '@reduxjs/toolkit'

export const viewPurchaseOrderSlice=createSlice({
    name:'viewPurchaseOrderSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_purchase_order:(state,action)=>{
            state.value=action.payload.purchaseOrderData
        }
    }
})
export const {get_purchase_order} = viewPurchaseOrderSlice.actions;
export default viewPurchaseOrderSlice.reducer