import axios from "axios";
import { update_allStockAdj_list } from "../../Redux/Prchase Manager/StockAdjustment/allStockAdjSlice";
import { update_stockAdjConfirm_list } from "../../Redux/Prchase Manager/StockAdjustment/confirmStockAdjustmentSlice";
import { update_draftedStockAdj_list } from "../../Redux/Prchase Manager/StockAdjustment/draftedStockAdjListSlice";
import { update_glCode_list } from "../../Redux/Prchase Manager/StockAdjustment/glCodeSlice";
import { update_glLinkCode_list } from "../../Redux/Prchase Manager/StockAdjustment/glLinkCodeSlice";
import { update_singleDraft } from "../../Redux/Prchase Manager/StockAdjustment/singleDraftViewSlice";
import { update_slCode_list } from "../../Redux/Prchase Manager/StockAdjustment/slCodeSlice";
import { update_stock_adj_id } from "../../Redux/Prchase Manager/StockAdjustment/stockAdjId";
import { update_toLocation_stock } from "../../Redux/Prchase Manager/StockAdjustment/toLocationStockSlice";
import store from "../../Redux/store";
import { HEADERS } from "../UrlAndPaths";

//Generate Stock adjustment Id
export const generateStockAdjIdAPICall = async () => {
  await axios
    .get("purchase/generateAdjustmentId", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_stock_adj_id({ stockAdjId: res.data }));
        console.log("stock adjustment id generated successfully...");
      }
    })
    .catch((err) => {
      console.error(err.response);
      store.dispatch(update_stock_adj_id({ stockAdjId: undefined }));
    });
};

//Get GL Link Code
export const getGlLinkCodeAPICall = async () => {
  await axios
    .get("purchase/getGlLinkCode", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_glLinkCode_list({ glLinkCode: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_glLinkCode_list({ glLinkCode: undefined }));
      console.error(err.response);
    });
};
//Get GL  Code
export const getGlCodeAPICall = async () => {
  await axios
    .get("purchase/getGlCode", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_glCode_list({ glCode: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_glCode_list({ glCode: undefined }));
      console.error(err.response);
    });
};
//Get SL Code
export const getSlCodeAPICall = async () => {
  await axios
    .get("purchase/getSlCode", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_slCode_list({ slCode: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_slCode_list({ slCode: undefined }));
      console.error(err.response);
    });
};

//Stock of to Location
export const getStockOfToLocation = async (body) => {
  await axios
    .post("purchase/viewstockfrombranch", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_toLocation_stock({ toLocStock: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_toLocation_stock({ toLocStock: undefined }));
      console.error(err.response);
    });
};

//Final Post Stock adjustment
export const finalPostStockAdjustmentAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates
) => {
  await axios
    .post("purchase/addstockadj", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Stock adjustment posted 👍");
        setSuccess(true);
        updateListener();
        clearStates();
        console.log("Stock adjustment posted successfully...");
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      setError(true);
      console.error(err.response);
    });
};
//Final Post Drafted Stock adjustment
export const finalPostDraftedStockAdjustmentAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates
) => {
  await axios
    .post("purchase/poststkadj", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Stock adjustment posted 👍");
        setSuccess(true);
        updateListener();
        clearStates();
        console.log("Stock adjustment posted successfully...");
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      setError(true);
      console.error(err.response);
    });
};

//Draft Stock adjustment
export const draftStockAdjustmentAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates
) => {
  await axios
    .post("purchase/draftstkadj", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Stock adjustment drafted 👍");
        setSuccess(true);
        updateListener();
        clearStates();
        console.log("Stock adjustment drafted successfully...");
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network error!!");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden!!");
      }
      setError(true);
      console.error(err.response);
    });
};

//View all drafted stock adjustment
export const viewDraftedStockAdjustmentAPICall = async (body,setIsLoading,setErrorSnack,setSnackMsg) => {
  if(setIsLoading!==undefined)setIsLoading(true)
  await axios
    .post("purchase/viewstkadj", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_draftedStockAdj_list({ draftedStockAdjList: res.data })
        );
        if(setIsLoading!==undefined)setIsLoading(false)
      }
    })
    .catch((err) => {
      console.error(err.response);
      store.dispatch(
        update_draftedStockAdj_list({ draftedStockAdjList: undefined })
      );
      switch (err.response.status) {
        case 404:
            setSnackMsg('No data available..')
          break;
        case 500:
            setSnackMsg('Server error!!')
          break;
        case 401:
            setSnackMsg('Unauthorized!!')
          break;
        case 403:
            setSnackMsg('Forbidden!!')
          break;
      
        default:
            setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
          break;
      }
      setErrorSnack(true)
    if(setIsLoading!==undefined)setIsLoading(false)
    });
};
//View single drafted stock adjustment
export const viewSingleDraftedStockAdjustmentAPICall = async (body) => {
  await axios
    .post("purchase/viewstkadjbyid", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_singleDraft({ singleDraft: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(update_singleDraft({ singleDraft: undefined }));
      console.error(err.response);
    });
};

//View all stock adjusted data with status
export const viewAllStockAdjustmentAPICall = async (body,setIsLoading,setErrorSnack,setSnackMsg) => {
  if(setIsLoading!==undefined)setIsLoading(true)
  await axios
    .post("purchase/viewstockadj", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_allStockAdj_list({ allStockAdj: res.data }));
        if(setIsLoading!==undefined)setIsLoading(false)
      }
    })
    .catch((err) => {
      console.error(err.response);
      store.dispatch(update_allStockAdj_list({ allStockAdj: undefined }));
      switch (err.response.status) {
        case 404:
          setSnackMsg('No data available..')
          break;
          case 500:
          setSnackMsg('Server error!!')
        break;
        case 401:
          setSnackMsg('Unauthorized!!')
          break;
          case 403:
          setSnackMsg('Forbidden!!')
        break;
    
        default:
          setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
        break;
    }
    setErrorSnack(true)
    if(setIsLoading!==undefined)setIsLoading(false)
  });
};

//Final Finish Received Stock
export const finalFinishReceivedStockAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  closeDialog,
  updateListener
) => {
  await axios
    .put("purchase/recievestock", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Received Stock Verified 👍");
        setSuccess(true);
        updateListener();
        closeDialog();
        console.log("Received Stock Verified");
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network Error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server error!!");
      }
      setError(true);
    });
};
//Final Finish Confirm Stock
export const finalFinishConfirmStockAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  closeDialog,
  updateListener
) => {
  await axios
    .put("purchase/confirmstock", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setSnackMsg("Stock Adjustment Confirmed 👍");
        setSuccess(true);
        updateListener();
        closeDialog();
        console.log("Stock Adjustment Confirmed");
      }
    })
    .catch((err) => {
      if (err.response.status === undefined) {
        setSnackMsg("Network Error!!");
      }
      if (err.response.status === 401) {
        setSnackMsg("Unauthorized!!");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden");
      }
      if (err.response.status === 500) {
        setSnackMsg("Server error!!");
      }
      setError(true);
    });
};

//Confirm Stock Adjustment
export const stockAdjustmentConfirmListAPICall = async (body,setIsLoading,setErrorSnack,setSnackMsg) => {
  if(setIsLoading!==undefined) setIsLoading(true)
  await axios
  .post("purchase/confirmstkadj", body, HEADERS)
  .then((res) => {
    if(res.status===200)
    {
      //TODO create Slice and save
      store.dispatch(update_stockAdjConfirm_list({stockAdjConfirmList:res.data}))
      if(setIsLoading!==undefined) setIsLoading(false)
      }
    })
    .catch(err=>{
      console.error(err.response);
      switch (err.response.status) {
        case 404:
          setSnackMsg('No data available..')
          break;
          case 500:
          setSnackMsg('Server error!!')
        break;
        case 401:
          setSnackMsg('Unauthorized!!')
          break;
          case 403:
          setSnackMsg('Forbidden!!')
        break;
    
        default:
          setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
        break;
    }
    setErrorSnack(true)
    if(setIsLoading!==undefined) setIsLoading(false)
    });
};
