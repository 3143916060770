import { createSlice } from "@reduxjs/toolkit";

export const supplierLedgerSlice=createSlice({
    name:'supplierLedgerSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_supplier_ledger:(state,action)=>{
            state.value=action.payload.supplierLedger
        }
    }
})

export const {update_supplier_ledger}=supplierLedgerSlice.actions
export default supplierLedgerSlice.reducer