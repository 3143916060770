import { createSlice } from "@reduxjs/toolkit";

export const cuffClothSlice=createSlice({
    name:"cuffClothSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        update_cuff_cloth:(state,action)=>{
            state.value=action.payload.cuffCloth
        }
    }
})

export const {update_cuff_cloth}=cuffClothSlice.actions
export default cuffClothSlice.reducer