import React, { useRef } from 'react'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { convertArrayToExcel, filterObjFromList } from '../../../../../Js/generalFunctions';
import { useEffect } from 'react';
import { viewAllCurrencyUpdated } from '../../../../../API/Settings/Currency/CurrencyAPI';
import { companyProfileInfoForUser } from '../../../../../API/Settings/Company Settings/companySettingsAPI';
import { AccountRecievableSingleAPIcall } from './partnerReportApi';
import { IconButton, Tooltip } from '@mui/material';
import ReactToPrint from 'react-to-print';
import Template from "./AccountSingleViewPrint"

export const AccountRecievableSingleView = () => {
  let paymentsPrintNew = useRef();

  const configurationList = useSelector(
    (state) => state.glConfigurationSlice.apiResList
);

    let navigate = useNavigate();
    const [symbol, setSymbol] = useState(null);
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const account = localStorage.getItem("account")
    const singleViewId =localStorage.getItem("singleViewById")
    // const StartDate = localStorage.getItem("fromDate");
    // const EndDate = localStorage.getItem("toDate");

    const selectedFromDate = localStorage.getItem("selectedFromDate");
const selectedToDate = localStorage.getItem("selectedToDate");

    const singleView =useSelector((state)=>state?.PartnerReport?.AccountRecievableSingleView)
     console.log(singleView);
    const decimalPosition = localStorage.getItem("decimalPosition");

    const currencyListUpdated = useSelector(
        (state) => state?.viewUpdatedCurrency?.value
      );
     const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue
      );
      const downloadList=()=>{
        convertArrayToExcel(singleView?.entries,"Account Receivable Report")
      }

      const initialvalue ={
        totalBalance:0,
        totalCredit:0,
        totalDebit:0,
        label:"Account Receivable Report",
        
        companyLogo: "",
        companyName: "",
        companyAddress: "",
        companyContact: "",
        }
        const [data,setData]=useState([])
        const [formValues,setFormValues]=useState(initialvalue)

        useEffect(()=>{
          setFormValues({...formValues,
            totalBalance:singleView?.totalBalance || 0,
            totalCredit:singleView?.totalCredit || 0,
            totalDebit:singleView?.totalDebit || 0,
        
            companyLogo: companyProfileData?.logo || "",
            companyName: companyProfileData?.companyName || "",
            companyAddress: companyProfileData?.companyAddress || "",
            companyContact: companyProfileData?.contactNumber || "",
        
          });
          setData(singleView?.entries)
        },[singleView,companyProfileData])
      const handleback =()=>{
        localStorage.removeItem("fromDate")
        localStorage.removeItem("toDate")
          navigate("/userdashboard/accounts/report/partnerReport/accountReceivable")
        }

        useEffect(() => {
          if (configurationList !== undefined) {
              setToDate(configurationList?.yearEndDate)
              setFromDate(configurationList?.yearStartDate)
          }
  
      }, [configurationList])

      useEffect(() => {
        let currencyObj = filterObjFromList(
          "_id",
          currencyListUpdated,
          "currency",
          companyProfileData
        );
        console.log(currencyObj);
        setSymbol(currencyObj?.symbol);
      }, [companyProfileData, currencyListUpdated]);

    //   },[StartDate,EndDate,singleViewId])
  //   useEffect(()=>{
  //     if (toDate !== undefined && fromDate !== undefined) {
  //       const startDate = selectedFromDate === '' ? '' : `${fromDate} 00:00:00`;
  //       const endDate = toDate === '' ? '' : `${toDate} 23:59:59`;
  
  //       AccountPayableAPIcall({
  //           startDate: startDate,
  //           endDate: endDate
  //       });
  //   }
  // }, [fromDate, toDate]);


    // useEffect(() => {
    //   AccountRecievableSingleAPIcall({
    //     startDate:  `${selectedFromDate} 00:00:00`,
    //     endDate:  `${selectedToDate} 23:59:59`,
    //     id: singleViewId,
    //   });
    // }, [selectedFromDate, selectedToDate, singleViewId]);

   
    useEffect(() => {
      if (toDate !== undefined && fromDate !== undefined) {
      const startDate = fromDate === '' ? '' : `${fromDate} 00:00:00`;
      const endDate = toDate === '' ? '' : `${toDate} 23:59:59`;
      AccountRecievableSingleAPIcall({
        startDate:  selectedFromDate===''?startDate:`${selectedFromDate} 00:00:00`,
        endDate:selectedToDate===''?endDate: `${selectedToDate} 23:59:59`,
        id: singleViewId,
      });
    }
    }, [fromDate, toDate,selectedFromDate, selectedToDate, singleViewId]);

   
      
      useEffect(() => {
        viewAllCurrencyUpdated({ status: 0 });
        companyProfileInfoForUser();
      }, []);

  return (
    <div className="global-page-parent-container"  style={{margin:"0"}}>
            <div className="global-white-bg-container">
             <div style={{paddingBottom:"36px",paddingTop:"8px",display:"flex"}}>
             <div className="sharp-arrow" style={{width:"182px"}} onClick={() => handleback()} > <p>Account Recievable Report</p></div>
             <div className="sharp-arrow" style={{marginLeft:"18px",width:"auto"}}> <p>{account}</p></div>
             <div style={{paddingInline:"15px",marginTop:"-13px"}}>
          <Tooltip title="Share">
          <IconButton>
            <i class="bi bi-share" style={{ backgroundColor: "#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>

        <ReactToPrint
          trigger={() => (
            <IconButton className="printer-div" style={{ padding: "1px" }}>
              <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
            </IconButton>
          )}
          content={() => paymentsPrintNew}
        />

        <Tooltip title="Download">
          <IconButton onClick={downloadList}>
            <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>

        </div>
            </div>
           

            <div className='global-report-table-container' style={{maxHeight:"80vh"}}>
            <table>
                <thead>
                    <tr>
                       <th>Date</th>
                       <th>Name</th>
                       <th>Journal</th>
                       <th>Referance</th>
                       <th>Debit</th>
                       <th>Credit</th>
                       <th>Balance</th>
                    </tr>
                </thead>
                <tbody>
                    {singleView?.entries?.map((item,index)=>(
                <tr key={index} style={{height:"38px"}}>
                        <td style={{backgroundColor:"white",border:"0.8px solid #c3d4f7"}}>{item.date}</td>
                        <td style={{backgroundColor:"white",border:"0.8px solid #c3d4f7"}}>{item.name}</td>
                        <td style={{backgroundColor:"white",border:"0.8px solid #c3d4f7"}}>{item.journalNo}</td>
                        <td style={{backgroundColor:"white",border:"0.8px solid #c3d4f7"}}>{item.reference}</td>
                        <td style={{backgroundColor:"white",border:"0.8px solid #c3d4f7"}}>{item.debit?.toFixed(decimalPosition)}</td>
                        <td style={{backgroundColor:"white",border:"0.8px solid #c3d4f7"}}>{item.credit?.toFixed(decimalPosition)}</td>
                        <td style={{backgroundColor:"white",border:"0.8px solid #c3d4f7"}}>{item.balance?.toFixed(decimalPosition)}</td>
                    </tr>
                    ))}
                </tbody>
                <tfoot>
              <tr>
                <th colSpan="4">Total</th>
                <th>{symbol}&nbsp;{singleView?.totalDebit?.toFixed(decimalPosition)}</th>
                <th>{symbol}&nbsp;{singleView?.totalCredit?.toFixed(decimalPosition)}</th>
                <th>{symbol}&nbsp;{singleView?.totalBalance?.toFixed(decimalPosition)}</th>
              </tr>
            </tfoot>
             </table>

            </div>
         </div>  
          <div  style={{ display: "none" }}>
  <div ref={(el) => (paymentsPrintNew = el)}>
      <Template  data={data} formValues={formValues}/> 
  </div>
</div>
        </div>

  )
}