import { createSlice } from "@reduxjs/toolkit";

export const productsWithStockSlice=createSlice({
    name:'productsWithStockSlice',
    initialState:{
        value:undefined,
        loadingProductList:false
    },
    reducers:{
        update_products_with_stock:(state,action)=>{
            state.value=action.payload.productsWithStock
        },
        setLoadingProductList:(state,action)=>{
            state.loadingProductList=action.payload
        },
    }
})

export const {update_products_with_stock,setLoadingProductList} =productsWithStockSlice.actions
export default productsWithStockSlice.reducer
