import axios from 'axios'
import {get_view_order_invoice_no} from '../../Redux/Sales/viewOrderInvoiceNoSlice'
import {HEADERS} from '../../API/UrlAndPaths'
import store from '../../Redux/store'

export const viewOrderInvoiceNoAPICall= async (body)=>{
    await axios.post("sales/viewOrderInvoiceNo",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_view_order_invoice_no({viewOrderInvoiceNoData:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(get_view_order_invoice_no({viewOrderInvoiceNoData:undefined}))
        console.log(err.response);
    })
}