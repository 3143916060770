import { Autocomplete, Dialog, IconButton, Select, Skeleton, TextField } from '@mui/material'
import React,{useState} from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { applyLeaveStaffAPICall } from '../../../../../../API/Staff/HRM/employeeLeaveAPI'
import { viewLeaveTypesAPI } from '../../../../../../API/Staff/HRM/Leave/staffLeaveTypeAPI'
import '../../../../../../css/Staff/HRM/Tab/empLeaveDetails.css'
import { FilePicker } from '../../../../../Single Components/filePicker'
import ErrorSnackbar from '../../../../../Single Components/SnackBars/ErrorSnackbar'
import SuccessSnackbar from '../../../../../Single Components/SnackBars/SuccessSnackbar'
import { convertDateFormat, currentTime, today } from "../../../../../../Js/Date"
import { getSingleEmployeeAPICall } from '../../../../../../API/Staff/HRM/employeeAPI'
import { viewLeaveApplicationsAPICall } from '../../../../../../API/Staff/HRM/Leave/staffLeaveApplicationAPI'
import { NavigateNextOutlined } from '@mui/icons-material'
export const EmpLeaveDetails = () => {
  const currentEmpID = localStorage.getItem("EMP_ID");
  const userRole = useSelector((state) => state.userRoleSlice.value);
  const dateAndTime=`${today} ${currentTime}`;
  const [applyLeaveDialog,setApplyLeaveDialog]=useState(false)
  const[isModify,setIsModify]=useState(false)
  const [leaveApplicationView,setLeaveApplicationView]=useState(false)
  const[showAlertLimit,setShowAlertLimit]=useState(false)
  const[showAlertLeaveType,setShowAlertLeaveType]=useState(false)
  const[showAlertDate,setShowAlertDate]=useState(false)
  const[filteredLeaveList,setFilteredLeaveList]=useState([])
  const[showLeaveSummary,setShowLeaveSummary]=useState(false)
  const[showLeaveSummaryH4,setShowLeaveSummaryH4]=useState(true)

  const [searchKeyword,setSearchKeyword]=useState("")
  const [filteredSearchList,setFilteredSearchList]=useState(filteredLeaveList)
  const [leaveSingleNote,setLeaveSingleNote]=useState("")
  const [leaveSingleStatus,setLeaveSingleStatus]=useState("")
  const [leaveSingleId,setLeaveSingleId]=useState("")
  //Loading State
  const [isLoading, setIsLoading] = useState(false);
  const leaveTypeList=useSelector((state)=>
        state.allLeaveTypeSlice.value
    );
  const viewLeaveApplications=useSelector((state)=>
    state.allLeaveApplicationSlice.value
    )
  const singleEmpData = useSelector((state) => state.singleEmployeeSlice.value);

  // calculate date diff states
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [diffDays, setDiffDays] = useState(0);

 // snackbar states
  const [successSnackbar,setSuccessSnackbar]=useState(false)
  const [errorSnackbar,setErrorSnackbar]=useState(false)
  const [snackMsg,setSnackMsg]=useState('Error!!')

  const [addDocImg, setAddDocImg] = useState(null);
  const applyLeaveBodyinitial={
        leaveType:null,
        startDate:{today},
        endDate:"",
        reason:"",
        document:"",
        appliedDate:dateAndTime,
        branchId:singleEmpData?.branchId,
        employeeId:singleEmpData?.emp_id
  }
  const [applyLeaveBody,setApplyLeaveBody]=useState(applyLeaveBodyinitial)
 
  const applyLeaveAPIBody={
      leaveType:applyLeaveBody.leaveType?.leaveType,
      leaveFromeDate:applyLeaveBody.startDate.today,
      leaveToDate:applyLeaveBody.endDate,
      reason:applyLeaveBody.reason,
      emp_id:currentEmpID,
      emp:applyLeaveBody.document,
      appliedDate:applyLeaveBody.appliedDate,
      branchId:applyLeaveBody.branchId
  }

  const getLeaveTypeValue=(e,newValue)=>{
      setApplyLeaveBody({...applyLeaveBody,leaveType:newValue})
      setShowAlertLeaveType(false)
      if(newValue===null){
        setShowAlertLeaveType(true)
      }
    }
  

  const filePickerChange=(e)=>{
    setAddDocImg(e.target.files[0]);
    setApplyLeaveBody({
      ...applyLeaveBody,document:e.target.files[0]
    })
  }
  const clearStates=()=>{
    setApplyLeaveBody(applyLeaveBodyinitial)
    setDiffDays(0)
    setApplyLeaveDialog(false)
    setAddDocImg(null)
    setShowAlertLeaveType(false)
    setShowAlertDate(false)
  }
  const updateListener=()=>{
    setIsModify(!isModify)
  }

  const closeSuccessSnackbar=()=>{
    setSuccessSnackbar(false)
  }
  const closeErrorSnackbar=()=>{
    setErrorSnackbar(false)
  }


  const submitApplyNewleave=()=>{

    if(applyLeaveAPIBody.leaveType===undefined){
      setShowAlertLeaveType(true)
    }
    else if(applyLeaveAPIBody.leaveToDate===""){
      setShowAlertDate(true)
    }
    else if(showAlertLimit!==true){

      applyLeaveStaffAPICall(
          applyLeaveAPIBody,
          updateListener,
          setSuccessSnackbar,
          setErrorSnackbar,
          setSnackMsg
          )
          clearStates()
          buttonDemoClick()
          // updateListener()
        }
  }
  const leaveReasonSingleView=(reason,status,id)=>()=>{
    setLeaveApplicationView(true)
    setLeaveSingleNote(reason)
    setLeaveSingleStatus(status)
    setLeaveSingleId(id)
  }
  

  const searchLeaveFilter=(chars,leaveList)=>{
    let searchFilter=[]
    let filteredArray=[]
    if(chars!==""){
      searchFilter=leaveList?.filter((obj)=>
                      obj?.leaveType?.toLowerCase()?.includes(chars?.toLowerCase())
      )
      filteredArray=searchFilter
    }
    else{
      filteredArray=leaveList
    }
    return filteredArray
  }
  const buttonDemoClick=()=>{
    viewLeaveApplicationsAPICall(
      {branchId:singleEmpData?.branchId},
      setIsLoading,
      setErrorSnackbar,
      setSnackMsg
      );
      setShowLeaveSummary(true)
      setShowLeaveSummaryH4(false)
    }

  useEffect(() => {
    getSingleEmployeeAPICall({ id: currentEmpID });
  }, []);
  useEffect(()=>{
    let timeDiff =Math.abs(endDate.getTime()-startDate.getTime());
    setDiffDays(Math.ceil(timeDiff /(1000*3600*24)))
  },[endDate,startDate])
   
  useEffect(()=>{
      if(applyLeaveBody.leaveType!==null && diffDays>applyLeaveBody.leaveType.numberOfDays){
        setShowAlertLimit(true)
      }
      else(
        setShowAlertLimit(false)
      )
  },[diffDays])
  // listing all leaveType API call  
  useEffect(()=>{
    viewLeaveTypesAPI();
    },[])

  useEffect(()=>{
    setFilteredLeaveList([])
    let filtereData=viewLeaveApplications?.filter((obj)=>{
      if(obj?.employeeId===applyLeaveBody.employeeId){
        return obj;
      }
    })
    setFilteredLeaveList(filtereData)
  },[viewLeaveApplications])

  useEffect(()=>{
    if(filteredLeaveList!==undefined){
      setFilteredSearchList(filteredLeaveList)
      let filteredArray=searchLeaveFilter(searchKeyword,filteredLeaveList)
      setFilteredSearchList(filteredArray)
    }
    else{
      setFilteredSearchList([])
    }
  },[searchKeyword,filteredLeaveList])

  return (
    <div className='dashboard-employee-leave-details-container'>
      <div className='dashboard-employee-leave-details-top-container'>
         <div className='dashboard-employee-leave-details-top-btn-grp-container'>
          <button className='btn btn-primary' style={{backgroundColor:"#149114",cursor:"context-menu"}}>Paid Leave &nbsp; 15&nbsp;/&nbsp;365</button>
          <button className='btn btn-primary' style={{backgroundColor:"#ff0000",cursor:"context-menu"}}>Sick Leave &nbsp; 4&nbsp;/&nbsp;10</button>
          <button className='btn btn-primary' style={{backgroundColor:"#ff5700",cursor:"context-menu"}}>Unpaid Leave &nbsp; 5&nbsp;/&nbsp;365</button>
         </div>

         {/* {
           userRole==="user"? */}
           <button onClick={()=>setApplyLeaveDialog(true)} className='btn btn-primary btn-font-size-less'>Apply For Leave</button>
         {/* :""} */}
      </div>
      <div className='dashboard-employee-leave-details-top-container'>
         <div className='dashboard-employee-leave-details-top-btn-grp-container'>
           {showLeaveSummaryH4?<button 
            className="btn btn-primary show-leave-btn"  
            onClick={buttonDemoClick}>
            Leave Summary<NavigateNextOutlined/>
            </button>
              :<h4>Leave Summary</h4>}
         </div>
         {showLeaveSummaryH4?
           "":
         <div className="view-order-search-container view-order-admin-search-container" style={{width:"25%"}}>
              <input
              placeholder="Search LeaveType"
              value={searchKeyword}
              onChange={(e)=>setSearchKeyword(e.target.value)}
              />
              <IconButton>
              <i class="bi bi-search search-icon-order"></i>
              </IconButton>
          </div>
          }
      
      </div>
      {
        showLeaveSummary?
        <div className='global-table-container dashboard-leave-details-table-container'>
         <table className='global-table'>
          <thead>
          <tr>
            <th>No</th>
            <th>Leave Type</th>
            <th>Date From</th>
            <th>Date To</th>
            <th>Days</th>
            <th>Applied On</th>
            <th>Status</th>
            <th>View</th>
            <th>Delete</th>
           </tr>
          </thead>
          {isLoading ? (
          <tbody>
            <tr>
              <td colSpan={9}>
                {/* <Loader /> */}
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width={"100%"}
                  height={40}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={9}>
                {/* <Loader /> */}
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width={"100%"}
                  height={40}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={9}>
                {/* <Loader /> */}
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width={"100%"}
                  height={40}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={9}>
                {/* <Loader /> */}
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width={"100%"}
                  height={40}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={9}>
                {/* <Loader /> */}
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width={"100%"}
                  height={40}
                />
              </td>
            </tr>
          </tbody>
              ) : (
          <tbody>
             {
               filteredSearchList?.length!==0?
               filteredSearchList
               ?.map((r,i)=>
               <tr key={i}>
              <td>{i+1}</td>
              <td>{r?.leaveType}</td>
              <td>{convertDateFormat(r?.DateFrom)}</td>
              <td>{convertDateFormat(r?.DateTo)}</td>
              <td>{r?.days}</td>
              <td>{convertDateFormat(r?.appliedDate)}</td>
              <td>
                    {r?.status==="APPROVED"?(
                      <p className="status-text-stock-out-pending">Accepted</p>)
                      :r?.status==="REJECTED"?(
                        <p className="status-text-stock-out-reject">Rejected</p>)
                        :(
                          <p className="status-text-stock-out">Pending</p>)
                    }
              </td>
              <td>
              <IconButton onClick={leaveReasonSingleView(r?.reason,r?.status,r?._id)}>
               <i class="bi bi-eye visibility-icon"></i>
              </IconButton>
              </td>
              <td>
              <IconButton>
              <i class="bi bi-trash3 delete-icon"></i>
              </IconButton>
              </td>
            </tr>
              ):(
                <tr>
                  <td colSpan={10}>No Records</td>
                </tr>
              )

             }
            
          </tbody>
              )}
         </table>
      </div>
            :""}


      <Dialog open={applyLeaveDialog} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setApplyLeaveDialog(false)}>
        <div className='dashboard-employee-leave-details-dialog-container'>
          <div className='dashboard-employee-leave-details-dialog-top-container'>
            <h3>Apply For Leave</h3>
            <p>Selected Days&nbsp;:&nbsp;<span>{diffDays}</span></p>
            {showAlertLimit?
            <p className="alert-leave-limit">
              Allotted Days For this Leave Type is {applyLeaveBody.leaveType!==null &&applyLeaveBody.leaveType.numberOfDays} days
              </p>:""
            }
          </div>
          <hr  className='global-hr'/>
           <div>
            <div className='global-single-input dashboard-emp-apply-leave-input auto-complete'>
              <p>Select Leave Type</p>
              <Autocomplete
              sx={{width:"100%"}}
              options={leaveTypeList || [""]}
              getOptionLabel={(option)=>option.leaveType}
              renderInput={(params) => <TextField {...params} />}
              onChange={getLeaveTypeValue}
              />
              {showAlertLeaveType?
                <p className="validation-alert-red">
                  Select a leave type!!</p>:""
                }
            </div>
            <div className='global-single-input dashboard-emp-apply-leave-input'>
              <p>Leave Date From</p>
              <input type="date"
              value={applyLeaveBody.startDate.today}
              onChange={(e)=>{setApplyLeaveBody({...applyLeaveBody,startDate:e.target.value})
                              setStartDate(new Date(e.target.value))
            }}
              />
            </div>
            <div className='global-single-input dashboard-emp-apply-leave-input'>
              <p>Leave Date To</p>
              <input type="date"
              onChange={(e)=>{setApplyLeaveBody({...applyLeaveBody,endDate:e.target.value})
                              setEndDate(new Date(e.target.value))
                              setShowAlertDate(false)
                            }}
              />
              {showAlertDate?
                <p className="validation-alert-red">
                  Fill this field!!</p>:""
                }
            </div>
            <div className='global-single-input dashboard-emp-apply-leave-input'>
              <p>Reason</p>
              <textarea  rows={4}
              onChange={(e)=>{setApplyLeaveBody({...applyLeaveBody,reason:e.target.value})}}
              />
            </div>
            <div className='global-single-input dashboard-emp-apply-leave-input'>
              <p>Document Upload</p>
              <FilePicker
                id="staffLeaveApplyDoc"
                uploadImageUrl={addDocImg}
                setImage={setAddDocImg}
                onChange={filePickerChange}
              />
            </div>


           </div>
           <div className='dashboard-employee-leave-details-dialog-button-container'>
            <button  onClick={clearStates} className='btn btn-secondary-outlined'>Cancel</button>
            
            <button onClick={submitApplyNewleave} className='btn btn-primary'>Submit</button>
           </div>
        </div>
      </Dialog>
 
    {/* leaveApplicationView */}
      <Dialog open={leaveApplicationView} maxWidth="lg" onClose={()=>setLeaveApplicationView(false)}>
          <div className="leave-application-view-container">
              <div className="leave-application-view-container-top">
                  <h3>Leave Application</h3>
              </div>
              <div className="leave-application-view-container-body">
                  <p>{leaveSingleNote}
                      </p>
              </div>
              <div className="leave-application-view-container-button">
                            {leaveSingleStatus==="APPROVED"?(
                              <p className="status-text-stock-out-pending">Accepted</p>)
                            :leaveSingleStatus==="REJECTED"?(
                              <p className="status-text-stock-out-reject">Rejected</p>)
                            :(
                              <p className="status-text-stock-out">Pending</p>)
                            }
              </div>
          </div>
      </Dialog>
      <SuccessSnackbar
      open={successSnackbar}
      handleClose={closeSuccessSnackbar}
      message={snackMsg}
      />
      <ErrorSnackbar
      open={errorSnackbar}
      handleClose={closeErrorSnackbar}
      message={snackMsg}
      />
    </div>
  )
}
