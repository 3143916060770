import { createSlice } from "@reduxjs/toolkit";

export const grnReportSlice=createSlice({
    name:"grnReportSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        get_grn_report:(state,action)=>{
            state.value=action.payload.grnReportList
        }
    }
})
export const {get_grn_report} = grnReportSlice?.actions;
export default grnReportSlice?.reducer