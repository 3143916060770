import { createSlice } from "@reduxjs/toolkit";

export const deliveryNoteSlice = createSlice({
  name: "deliveryNoteSlice",
  initialState: {
    apiResList: undefined,
    rowData:undefined,
    generateName:undefined
  },
  reducers: {
    get_delivery_noteres_list: (state, action) => {
      state.apiResList = action.payload.deliveryNoteData;
    },
    get_delivery_noterow_data_list: (state, action) => {
      state.rowData = action.payload.deliveryNoteSingleData;
    },
    generate_delivery_notename: (state, action) => {
      state.generateName = action.payload.deliveryNoteQuotname;
    }
  },
});

export const {get_delivery_noteres_list,
    get_delivery_noterow_data_list,
    generate_delivery_notename
} = deliveryNoteSlice.actions;

export default deliveryNoteSlice.reducer;
