import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import store from '../../../../../Redux/store';
import { Skeleton } from '@mui/material';
import { get_debit_note_row_data_list } from '../Slice/debitNoteSlice';
import { DebitNotesFilterAPICall, viewDebitNotesAPICall } from '../API/debitNoteAPI';
import { convertDateFormat, getCurrentTime } from '../../../../../Js/Date';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import CustomPagination from '../../../../Single Components/CustomPagination';
import axios from 'axios';
import { setFilterActive } from '../../../../Single Components/listSlice/filterCategorySlice';

export const DebitNotes = () => {

  const filtereDataOptions = useSelector((state) => state.listCategory);

  const userRole = useSelector((state) => state.userRoleSlice.value)
  const userBranchId = localStorage.getItem("branchId")

  let navigate = useNavigate();
  const tableListData = useSelector((state) => state.debitNoteSlice.apiResList);

  const createButton = () => {
    navigate("/userdashboard/accounts/vendor/DebitNotes/create")

  }
  const [isLoading, setIsLoading] = useState(false)
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const totalPages = tableListData?.pages;


  const handleClickRow = (row) => {
    store.dispatch( setFilterActive(window.location.pathname))
    store.dispatch(get_debit_note_row_data_list({ debitNoteSingleData: row }));
    localStorage.setItem('singleViewById', row?._id)

    //navigate("/userdashboard/accounts/customers/creditNotes/create")
    navigate("/userdashboard/accounts/vendor/DebitNote/SingleView")

  }
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch);

  };

  useEffect(() => {
    localStorage.removeItem("singleViewById");
    store.dispatch(get_debit_note_row_data_list({ debitNoteSingleData: undefined }))
    // if (userRole === "user") {
    //   viewDebitNotesAPICall({ branchId: userBranchId }, setIsLoading)
    // }
    // else {
    //   viewDebitNotesAPICall({}, setIsLoading)
    // }
  }, [])
  useEffect(() => {
   
    if (FormDataInfo.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData &&  DebitNotesFilterAPICall({
        fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
        toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
        branchList: FormDataInfo.branchList,
        status: FormDataInfo.status,
        search: FormDataInfo.search,
        index: currentPage - 1,
      }, setIsLoading);
    };
   
  }, [FormDataInfo, currentPage]);



  return (
    <div className='global-page-parent-container'>
      {/* table */}
      <div className='global-white-bg-container'>
        <div className="justify-space-between"
          style={{ gap: "0 12px", display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}
        >
          {FormDataInfo?.branchList?.length > 0 && (
            <div style={{ marginLeft: '1%' }}>
              <h3>Branch name:
                {selectedBranch?.storeCode || filtereDataOptions?.filterCategorylist?.branchList?.storeCode}
                -{selectedBranch?.branchName || filtereDataOptions?.filterCategorylist?.branchList?.branchName}</h3>
            </div>
          )}
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '9px',
            width: FormDataInfo?.branchList?.length ? '72%' : '100%'
          }}>
            <CategorySearchandFilter
              statusOptions={['DRAFT', 'POSTED']}
              onData={handleDataFromChild}
              isStatus={true}
              onBranchSelect={handleBranchSelect}
            />
            <button onClick={createButton} className='create-button-blue'>Create</button>
          </div>
        </div>
        {/* <div className='create-button-blue-container' style={{justifyContent:"flex-end"}}>
        <button onClick={createButton}  className='create-button-blue'>Create</button>
      </div> */}
        <div className='create-button-blue-container' style={{ margin: "0 1%" }}>
          <h3>Debit Notes</h3>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />
        </div>
        <div className='new-global-table-container'>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Vendor</th>
                <th>Bill Date</th>
                {/* <th>Due Date</th> */}
                <th>BIll Reference</th>
                <th>Total</th>
                <th>Created By</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody >
              {

                isLoading ? (
                  [...Array(10)].map((r, i) => (
                    <tr key={i}>
                      <td colSpan={9}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                  ))
                ) :

                  tableListData?.list !== undefined && tableListData?.list?.length !== 0 ? tableListData?.list?.slice(0)?.map((r, i) => (
                    <tr onClick={() => handleClickRow(r)}>
                      <td>{convertDateFormat(r?.billDate)}</td>
                      <td>{r?.name}</td>
                      <td>{r?.vendorName}</td>
                      <td>{convertDateFormat(r?.billDate)}</td>
                      {/* <td>{r?.dueDate}</td> */}
                      <td>{r?.billRef || "---"}</td>
                      <td>{r?.total || "---"}</td>
                      <td>{r?.createdBy || "---"}</td>
                      <td className='table-data-box'>
                        {r?.status === "DRAFT" ? (
                          <span className='draft'> DRAFT</span>
                        ) : r?.status === "POSTED" ? (
                          <span className='post'> POST</span>
                        ) : r?.status === "PAID" ? (
                          <span className='paid'>PAID</span>
                        ) : (
                          <span>{r?.status}</span>
                        )}
                      </td>
                    </tr>
                  ))
                    :
                    <tr>
                      <td colSpan={9}>No Data</td>
                    </tr>
              }

            </tbody>

          </table>
        </div>
      </div>

    </div>
  )
}
