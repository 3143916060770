import {
  Autocomplete,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { viewVendorListAPICall } from "../../Accounts/Vendor/API/vendorListAPICall";
import { viewAllBranchesAPICall } from "../../../../API/Settings/Company Settings/companySettingsAPI";
import { today } from "../../../../Js/Date";
import { getAllProductsForAccountsAPICall } from "../../Accounts/Vendor/API/vendorBillAPI";
import { getAllTaxSettingsListAPICall } from "../../../../API/Settings/TaxSettings/taxSettingsAPI";
import { filterObjFromList } from "../../../../Js/generalFunctions";
import {
  ConfirmRfqApiCall,
  GenerateRfqName,
  SaveRfqApiCall,
  cancelRfqAPiCall,
  updateRfqApiCall,
} from "../../../../API/Purchase Manager/RFQ/ConfirmRfqApiCall";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import {
  SavePurchaseOrderApiCall,
  confirmPurchasOrderApiCall,
  updatePurchaseOrderApiCall,
  updatePurchaseOrderApiCallinRFQ,
} from "../../../../API/Purchase Manager/Purchase Order/PurchaseOrder";
import axios from "axios";
import { viewUOMListAPICall } from "./UnitOfMeasurment/unitOfMeasurmentAPICall";
import store from "../../../../Redux/store";
import { resetRfqSingleView } from "../../../../Redux/Prchase Manager/RFQ/RfqSlice";

export const CreateRequestForQuatation = () => {
  const [selectedTaxAmtCalculated, setSelectedTaxAmtCalculated] = useState([]);

  const RfqSingleList = useSelector((state) => state?.RfqSlice?.rfqSingleView);
  const VendorList = useSelector((state) => state.vendorsListSlice.apiResList);
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  const decimalPosition = localStorage.getItem("decimalPosition");

  const allProductList = useSelector(
    (state) => state.vendorBillSlice.productsList
  );

  const allTaxList = useSelector((state) => state.taxSettingsSlice.value);
  const UomList = useSelector(
    (state) => state.unitOfMeasurmentSlice.apiResList
  );
  let navigateTo = useNavigate();
  const [formAddedTable, setFormAddedTable] = useState([]);

  const [rowIndex, setRowIndex] = useState(-1);
  const [AlertSnack, setAlertSnack] = useState(false);
  const [hideSaveBtn, sethideSaveBtn] = useState(false);

  const [taxList, setTaxList] = useState([]);

  const [isActiveTable, setIsActiveTable] = useState("invoiceLines");
  const [formAddedTableView, setFormAddedTableView] = useState([]);
  const [selectedTaxes, setSelectedTaxes] = useState([]);
  const [validationAlert, setValidationAlert] = useState({});
  const [showConfirmOrder, setshowConfirmOrder] = useState(false);
  const [showpurchaseOrderConfirm, setshowpurchaseOrderConfirm] =
    useState(false);
  const [savePurchaseOrderDetails, setsavePurchaseOrderDetails] = useState({});
  const [saveRfqDeatails, setsaveRfqDeatails] = useState({});

  const [RfqThroughPoEdit, setRfqThroughPoEdit] = useState(false);

  // console.log(showConfirmOrder);
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
  });

  const [MultiCurrency, setMultiCurrency] = useState(false);
  const [RfqEdit, setRfqEdit] = useState(false);
  const [SaveResponse, setSaveResponse] = useState({});

  // total sum of quantity
  const totalSum = saveRfqDeatails?.purchaseInfo?.reduce(
    (sum, obj) => sum + obj.orderQuantity,
    0
  );

  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      message: "",
    });
  };

  // closeAlertSnackbar
  const closeAlertSnackbar = () => {
    setAlertSnack(false);
  };

  const editClicked = false;

  const editListClick = () => {
    //   editUnitOfMeasurmentAPICall({
    //       id:editedData?.id,
    //       uomCat:formValues?.uomCategory,
    //       uom:formAddedTable
    //   },postFunction)
  };

  const formInitialState = {
    name: "###",
    vendor: null,
    branch: null,
    quotationDate: today,
    billDate: "",
    paymentReference: "",
    accountingDate: "",
    journal: null,
    product: null,
    Description: "",
    account: null,
    qty: "",
    // uom: "",
    uom: null,
    unitPrice: "",
    taxes: null,
    discount: "",
    subTotal: "",
    dueDate: "",
    visibleOnly: false,
    itemTotal: 0,
    taxAmt: 0,
    payterms: "",
    expireDate: "",
    Note: "",
  };
  const [formValues, setFormValues] = useState(formInitialState);

  // console.log("FormValues-->>>>", formValues);

  //CreateReqInvoiceLines

  const CreateReqInvoiceLines = () => {
    document.getElementById("productMenuBtn").classList.add("active");
    document.getElementById("departmentMenuBtn").classList.remove("active");
    setIsActiveTable("invoiceLines");
  };

  //CreateReqOtherInfo
  const CreateReqOtherInfo = () => {
    document.getElementById("productMenuBtn").classList.remove("active");
    document.getElementById("departmentMenuBtn").classList.add("active");
    setIsActiveTable("others");
  };

  useEffect(() => {
    viewVendorListAPICall();
    viewAllBranchesAPICall();
    getAllProductsForAccountsAPICall();
    getAllTaxSettingsListAPICall();
    viewUOMListAPICall();
  }, []);

  // deleteTableData();
  const deleteTableData = () => {
    clearLineData();
  };

  // GenerateRFQ and PO name(same Name)
  useEffect(() => {
    if (formValues?.branch !== null && hideSaveBtn === false) {
      // console.count("rendering");
      GenerateRfqName(formValues.branch, setFormValues, formValues);
    }
  }, [formValues?.branch]);

  useEffect(() => {
    if (RfqSingleList) {
      setRfqEdit(true);
      sethideSaveBtn(true);
    }
  }, [RfqSingleList]);

  // SIngle view of RFQ
  useEffect(() => {
    if (RfqSingleList !== undefined) {
      setFormValues({
        ...formValues,
        branch: filterObjFromList(
          "storeCode",
          allBranchesList,
          "branchId",
          RfqSingleList
        ),
        quotationDate: RfqSingleList?.purchaseDate,
        vendor: filterObjFromList(
          "name",
          VendorList,
          "supplierName",
          RfqSingleList
        ),
        name: RfqSingleList?.sequence,
        visibleOnly: true,
        payterms: RfqSingleList?.payTerms,
        expireDate: RfqSingleList?.expiryDate,
        Note: RfqSingleList?.note,
      });
      setFormAddedTable(RfqSingleList?.purchaseInfo);
    }
  }, [RfqSingleList, allBranchesList]);

  //UseEffect
  useEffect(() => {
    if (formValues?.product !== null) {
      setFormValues({
        ...formValues,
        // uom: formValues?.product?.unitName,
        uom: filterObjFromList(
          "uomCat",
          UomList,
          "unitName",
          formValues?.product
        ),
        qty: formValues?.product?.stock,
        Description: formValues?.product?.name,
        unitPrice: formValues?.product?.calculatedPrice,
      });
    } else {
      setFormValues({
        ...formValues,
        Description: "",
        uom: "",
        unitPrice: "",
        qty: "",
      });
    }
  }, [formValues?.product]);

  // ///////
  // useEffect(() => {
  //   let subTotalBeforeTax = 0;
  //   const totalItemAmt = Number(formValues.qty) * Number(formValues.unitPrice);
  //   const discount = (Number(formValues.discount) / 100) * totalItemAmt;

  //   subTotalBeforeTax = totalItemAmt - discount;
  //   let inclSubTotal = subTotalBeforeTax;

  //   if (selectedTaxes.length !== 0) {
  //     const isIncluded = selectedTaxes[0].includedPrice;

  //     if (!isIncluded) {
  //       let totalTaxPercentage = selectedTaxes.reduce(
  //         (sum, item) => sum + item?.amount,
  //         0
  //       );

  //       let totalTax = (totalTaxPercentage / 100) * subTotalBeforeTax;

  //       setFormValues({
  //         ...formValues,
  //         subTotal: subTotalBeforeTax + totalTax,
  //         taxAmt: totalTax,
  //         itemTotal: subTotalBeforeTax,
  //       });
  //     } else {
  //       for (let i = 0; i < selectedTaxes.length; i++) {
  //         const taxPercentage = selectedTaxes[i]?.amount;

  //         const amount = (inclSubTotal / (100 + taxPercentage)) * 100;
  //         inclSubTotal = amount;
  //       }

  //       setFormValues({
  //         ...formValues,
  //         subTotal: subTotalBeforeTax,
  //         itemTotal: subTotalBeforeTax,
  //         taxAmt: subTotalBeforeTax - inclSubTotal,
  //       });
  //     }
  //   } else {
  //     setFormValues({
  //       ...formValues,
  //       subTotal: subTotalBeforeTax,
  //       itemTotal: subTotalBeforeTax,
  //     });
  //   }
  // }, [
  //   formValues.qty,
  //   formValues.unitPrice,
  //   formValues.discount,
  //   selectedTaxes,
  // ]);

  // ///

  useEffect(() => {
    let subTotalBeforeTax = 0;
    const totalItemAmt = Number(formValues.qty) * Number(formValues.unitPrice);
    const discount = (Number(formValues.discount) / 100) * totalItemAmt;

    subTotalBeforeTax = totalItemAmt - discount;
    let inclSubTotal = subTotalBeforeTax;

    if (selectedTaxes.length !== 0) {
      const isIncluded = selectedTaxes[0].includedPrice;

      if (!isIncluded) {
        let updatedSelectedTaxes = selectedTaxes.map((tax) => ({
          ...tax,
          calculatedAmt: (tax.amount / 100) * subTotalBeforeTax,
        }));

        setSelectedTaxAmtCalculated(updatedSelectedTaxes);
        let totalTaxPercentage = selectedTaxes.reduce(
          (sum, item) => sum + item?.amount,
          0
        );

        let totalTax = (totalTaxPercentage / 100) * subTotalBeforeTax;

        setFormValues({
          ...formValues,
          subTotal: subTotalBeforeTax + totalTax,
          taxAmt: totalTax,
          itemTotal: totalItemAmt,
        });
      } else {
        const updatedSelectedTaxes = selectedTaxes.map((tax) => ({
          ...tax,
          calculatedAmt: 0,
        }));
        for (let i = 0; i < selectedTaxes.length; i++) {
          const taxPercentage = selectedTaxes[i]?.amount;

          const amount = (inclSubTotal / (100 + taxPercentage)) * 100;
          let taxAmt = inclSubTotal - amount;
          updatedSelectedTaxes[i].calculatedAmt = taxAmt;
          inclSubTotal = amount;
        }
        setSelectedTaxAmtCalculated(updatedSelectedTaxes);
        setFormValues({
          ...formValues,
          subTotal: subTotalBeforeTax,
          itemTotal: totalItemAmt,
          taxAmt: subTotalBeforeTax - inclSubTotal,
        });
      }
    } else {
      setFormValues({
        ...formValues,
        subTotal: subTotalBeforeTax,
        itemTotal: totalItemAmt,
      });
    }
  }, [
    formValues.qty,
    formValues.unitPrice,
    formValues.discount,
    selectedTaxes,
  ]);

  //----//
  useEffect(() => {
    if (formAddedTable?.length !== 0) {
      const newTableView = formAddedTable?.map((entry) => ({
        product:
          filterObjFromList("_id", allProductList, "_id", entry)?.name || "",
        // account:
        //   filterObjFromList(
        //     "_id",
        //     chartOfAccountList,
        //     "chartOfAccountId",
        //     entry
        //   )?.accountName || "",
        taxesName: entry?.tax,
        Description: entry?.Description,
        quantity: entry?.orderQuantity,
        unit: entry?.unit,
        rate: entry?.rate,
        discount: entry?.discount,
        subTotal: entry?.subTotal,

        productId:
          filterObjFromList("_id", allProductList, "itemInfo", entry)?._id ||
          "",

        // accountId:
        //   filterObjFromList(
        //     "_id",
        //     chartOfAccountList,
        //     "chartOfAccountId",
        //     entry
        //   )?._id || "",
      }));

      setFormAddedTableView(newTableView);
    } else {
      setFormAddedTableView([]);
    }
  }, [formAddedTable, allProductList]);
  // --- //

  //-----//
  //useEffect

  // useEffect(()=>{
  // if(saveRfqDeatails !==undefined){
  //   setFormValues(saveRfqDeatails)
  //   }
  // },[showConfirmOrder === false])

  // ---- //

  const clearLineData = () => {
    setRowIndex(-1);
    setFormValues({
      ...formValues,
      product: null,
      Description: "",
      qty: "",
      uom: "",
      unitPrice: "",
      taxes: null,
      discount: "",
      subTotal: "",
      taxAmt: 0,
      itemTotal: 0,
    });
    setSelectedTaxes([]);
    setSelectedTaxAmtCalculated([]);
  };

  //getFormInfo
  const getFormInfo = (key) => (e, newValue) => {
    const { value } = e.target;
    setValidationAlert({});
    setFormValues({ ...formValues, [key]: value !== 0 ? value : newValue });
  };

  const addTableData = (key) => () => {
    if (formValues?.product === null || formValues?.product === undefined) {
      setValidationAlert({ ...validationAlert, product: "fill this field" });
    } else if (
      formValues?.Description === "" ||
      formValues?.Description === undefined
    ) {
      setValidationAlert({
        ...validationAlert,
        Description: "fill this field",
      });
    } else if (formValues?.uom === "" || formValues?.uom === undefined) {
      setValidationAlert({ ...validationAlert, uom: "fill this field" });
    } else if (formValues?.qty === "" || formValues?.qty === undefined) {
      setValidationAlert({ ...validationAlert, qty: "fill this field" });
    } else if (
      formValues?.unitPrice === "" ||
      formValues?.unitPrice === undefined
    ) {
      setValidationAlert({ ...validationAlert, unitPrice: "fill this field" });
    } else if (
      formValues?.subTotal === "" ||
      formValues?.subTotal === undefined
    ) {
      setValidationAlert({ ...validationAlert, subTotal: "fill this field" });
    } else {
      setFormAddedTable((prevFormAddedTable) => [
        ...prevFormAddedTable,
        {
          type: 0,
          _id: formValues.product?._id,
          Description: formValues.Description,
          unit: formValues.uom?.uomCat,
          orderQuantity: Number(formValues.qty),
          recievedQuantity: 0,
          rate: formValues?.unitPrice,
          discount:
            formValues?.discount === "" ? 0 : Number(formValues?.discount),
          // tax:
          //   selectedTaxes.length !== 0
          //     ? selectedTaxes.map(
          //         ({ _id: taxId, amount, taxName, includedPrice }) => ({
          //           taxId,
          //           amount,
          //           taxName,
          //           includedPrice,
          //         })
          //       )
          //     : null,
          tax:
            selectedTaxAmtCalculated.length !== 0
              ? selectedTaxAmtCalculated.map((tax) => ({
                  taxId: tax._id || tax.taxId,
                  amount: tax.amount,
                  taxName: tax.taxName,
                  includedPrice: tax.includedPrice,
                  calculatedAmt: tax.calculatedAmt,
                }))
              : null,

          subTotal: Number(formValues?.subTotal),
          itemTotal: formValues?.itemTotal,
          taxAmt: formValues?.taxAmt,
        },
      ]);

      clearLineData();
    }
  };

  //Payload
  const payload = {
    vendor: formValues.vendor?._id,
    branchId: formValues.branch?.storeCode,
    quotationDate: formValues.quotationDate,
    purchaseInfo: formAddedTable,
    untaxedAmount:
      formAddedTable?.reduce((sum, item) => sum + item?.subTotal, 0) -
      formAddedTable?.reduce((sum, item) => sum + item?.taxAmt, 0),
    totalTax: formAddedTable?.reduce((sum, item) => sum + item?.taxAmt, 0),
    total: formAddedTable?.reduce((sum, item) => sum + item?.subTotal, 0),
    note: formValues?.Note,
    totalDiscount: 5, // static value
    expiryDate: formValues?.expireDate,
    payTerms: formValues?.payterms,
  };

  const PurchaseOrder_payload = {
    vendor: formValues.vendor?._id,
    branchId: formValues.branch?.storeCode,
    purchaseDate: formValues.quotationDate,
    purchaseInfo: formAddedTable,
    untaxedAmount:
      formAddedTable?.reduce((sum, item) => sum + item?.subTotal, 0) -
      formAddedTable?.reduce((sum, item) => sum + item?.taxAmt, 0),
    totalTax: formAddedTable?.reduce((sum, item) => sum + item?.taxAmt, 0),
    total: formAddedTable?.reduce((sum, item) => sum + item?.subTotal, 0),
    note: formValues?.Note,
    totalDiscount: 5, // static value
    payTerms: formValues?.payterms,
    expiryDate: formValues?.expireDate,
  };

  //cancelBtn
  const cancelBtn = () => {
    // setFormValues(formInitialState);

    cancelRfqAPiCall(saveRfqDeatails);
  };

  const disabledFun = () => {
    // console.log("YESSSS");
    setFormValues({
      ...formValues,
      visibleOnly: true,
    });
  };

  const SaveBtnFun = (key) => () => {
    
    if (formValues.name === "###1") {
      alert("name not generated");
    } else if (
      formValues?.vendor === null ||
      formValues?.vendor === undefined
    ) {
      setValidationAlert({ ...validationAlert, vendor: "select a vendor" });
    } else if (formValues.branch === null || formValues.branch === undefined) {
      setValidationAlert({ ...validationAlert, branch: "select a branch" });
    } else if (formValues?.expireDate === "") {
      // alert("select a due date !! on other info!!");
      setAlertSnack(true);
    } else {
      if (key === "draft") {
        SaveRfqApiCall(
          payload,
          setSnackBarStates,
          snackBarStates,
          setFormValues,
          formInitialState,
          setFormAddedTable,
          setshowConfirmOrder,
          setsaveRfqDeatails,
          disabledFun
        );
      } else if (key === "edit") {
        // console.log("UPDATE");
        let editPayload = { ...payload };
        editPayload._id = RfqSingleList?._id;
        // console.log(editPayload);
        updateRfqApiCall(
          editPayload,
          setSnackBarStates,
          snackBarStates,
          setshowConfirmOrder,
          setsaveRfqDeatails
        );
      }
    }
  };

  const SavePurchaseOrderFun = (key) => () => {
    // console.log("MNMNMNM>>>>>>");
    if (formValues.name === "###1") {
      alert("name not generated");
    } else if (formValues?.vendor === null) {
      setValidationAlert({ ...validationAlert, vendor: "select a vendor" });
    } else if (formValues.branch === null) {
      setValidationAlert({ ...validationAlert, branch: "select a branch" });
    } else if (formValues?.expireDate === "") {
      alert("select a due date !! on other info!!");
    } else {
      if (key === "draft") {
        SavePurchaseOrderApiCall(
          PurchaseOrder_payload,
          setSnackBarStates,
          snackBarStates,
          setFormValues,
          formInitialState,
          setFormAddedTable,
          setshowpurchaseOrderConfirm,
          setsavePurchaseOrderDetails
          // confirmPurchaseOrderBtnFun
          // setshowConfirmOrder,
          // setsaveRfqDeatails
        );
      } else if (key === "edit") {
        let editPayload = { ...PurchaseOrder_payload };
        editPayload._id = saveRfqDeatails?._id;
        updatePurchaseOrderApiCallinRFQ(
          editPayload,
          setSnackBarStates,
          snackBarStates,
          setsaveRfqDeatails,
          setshowpurchaseOrderConfirm
        );
      }
    }
  };

  //nihad

  const ConfirmOrderBtn = () => {
    ConfirmRfqApiCall(
      saveRfqDeatails,
      setsaveRfqDeatails,
      setshowConfirmOrder,
      sethideSaveBtn,
      setshowpurchaseOrderConfirm
    );
  };

  // const PurchaseOrder_ConfirmBody_1 = {
  //   purchaseOrderId: savePurchaseOrderDetails?._id,
  //   date: today,
  //   name: formValues?.name,
  //   branchId: savePurchaseOrderDetails?.branchId,
  //   // location: "652530fc3c37562c281ab104",
  //   vendor: savePurchaseOrderDetails?.supplierId,
  //   // reference: "Ref123",
  //   purchaseDate: savePurchaseOrderDetails?.purchaseDate,
  //   totalDiscount: savePurchaseOrderDetails?.totalDiscount,
  //   note: savePurchaseOrderDetails?.note,
  //   untaxedAmount: savePurchaseOrderDetails?.untaxedAmount,
  //   totalTax: savePurchaseOrderDetails?.totalTax,
  //   total: savePurchaseOrderDetails?.total,
  //   totalOrderQuantity: totalSum,
  //   purchaseInfo: savePurchaseOrderDetails?.purchaseInfo,
  // };

  const PurchaseOrder_ConfirmBody = {
    purchaseOrderId: saveRfqDeatails?._id,
    date: today,
    name: formValues?.name,
    branchId: saveRfqDeatails?.branchId,
    // location: "652530fc3c37562c281ab104",
    vendor: saveRfqDeatails?.supplierId,
    // reference: "Ref123",
    purchaseDate: saveRfqDeatails?.purchaseDate,
    totalDiscount: saveRfqDeatails?.totalDiscount,
    note: saveRfqDeatails?.note,
    untaxedAmount: saveRfqDeatails?.untaxedAmount,
    totalTax: saveRfqDeatails?.totalTax,
    total: saveRfqDeatails?.total,
    totalOrderQuantity: totalSum,
    purchaseInfo: saveRfqDeatails?.purchaseInfo,
  };

  //confirmPurchaseOrderBtnFun
  const navigateToGrn = () => {
    navigateTo("/userdashboard/purchaseManager/GoodsReceivedNote/add");
  };

  const confirmPurchaseOrderBtnFun = () => {
    // console.count("KGL");
    confirmPurchasOrderApiCall(
      PurchaseOrder_ConfirmBody,
      setSnackBarStates,
      snackBarStates,
      navigateToGrn
    );
  };

  // useEffect(()=>{
  //   if(PurchaseOrder_ConfirmBody?.purchaseOrderId !== undefined){
  //     console.count("USEEFFECT--->>>")
  //     confirmPurchasOrderApiCall(
  //       PurchaseOrder_ConfirmBody,
  //       setSnackBarStates,
  //       snackBarStates,
  //       navigateToGrn
  //     );
  //   }

  // },[savePurchaseOrderDetails])

  // purchaseOrderCancelBTNfun
  const purchaseOrderCancelBtn = () => {
    // console.log("CCCC");
  };

  const handleClickRow = (data, index) => {
    // console.log("ZZZZZ--->>>>", data);
    setRowIndex(index);
    setFormValues({
      ...formValues,
      product: filterObjFromList("name", allProductList, "product", data),
      // account: filterObjFromList("_id", chartOfAccountList, "accountId", data),
      // qty: data?.quantity,
      // discount: data?.discount,
      // label: data?.label,
      // uom: data?.unit,
      // unitPrice: data?.rate,

      discount: data?.discount,
      unitPrice: data?.rate,
      qty: data?.quantity,
      uom: filterObjFromList("uomCat", UomList, "unit", data),
      Description: data?.Description,
    });

    setSelectedTaxes(data?.taxesName !== null ? data?.taxesName : []);
    const updatedTable = [
      ...formAddedTable.slice(0, index),
      ...formAddedTable.slice(index + 1),
    ];
    setFormAddedTable(updatedTable);
  };

  // editRfqBtn
  const editRfqBtn = () => {
    // console.log("bbbb");
    setFormValues({
      ...formValues,
      visibleOnly: false,
    });
    setRfqEdit(false);
  };

  useEffect(() => {
    if (selectedTaxes.length === 0) {
      setTaxList(allTaxList !== undefined ? allTaxList : []);
    } else {
      let filteredArray = taxList.filter(
        (option) => option.includedPrice === selectedTaxes[0]?.includedPrice
      );
      setTaxList(filteredArray);
    }
  }, [allTaxList, selectedTaxes]);

  //editPurchaseOrderInRfqEnableBtn
  const editPurchaseOrderInRfqEnableBtn = () => {
    setRfqThroughPoEdit(true);
    setFormValues({
      ...formValues,
      visibleOnly: false,
    });
    setshowpurchaseOrderConfirm(false);
  };

  //editPurchaseOrderInRfq
  const editPurchaseOrderInRfq = () => {
    // console.log("HHHH");
  };

  const confirmOne = () => {
    // console.log("80005555>>>", PurchaseOrder_payload);
    // let editPayload = { ...PurchaseOrder_ConfirmBody_1 };
    // editPayload._id = saveRfqDeatails?._id;
    // confirmPurchasOrderApiCall(editPayload,setSnackBarStates,snackBarStates,navigateToGrn);
  };

  return (
    <div className="global-page-parent-container">
      <p className="breadcrumb">
        Purchase Manager &gt;{" "}
        {saveRfqDeatails?.type === "PURCHASE ORDER"
          ? "Purchase Order"
          : "Request For Quotation"}{" "}
      </p>

      <div
        className="global-white-bg-container justify-space-between1"
        style={{ padding: "3px 1%" }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Tooltip title="Back">
            <IconButton
              onClick={() =>
                navigateTo("/userdashboard/purchaseManager/RequestForQuatation")
              }
            >
              <i class="bi bi-chevron-left"></i>
            </IconButton>
          </Tooltip>

          {RfqEdit ? (
            <Tooltip title="Edit" onClick={editRfqBtn}>
              <IconButton>
                <i class="bi bi-pencil-square edit-icon1"></i>
              </IconButton>
            </Tooltip>
          ) : hideSaveBtn ? (
            <Tooltip title="Update RFQ" onClick={SaveBtnFun("edit")}>
              <IconButton>
                <i
                  class="bi bi-check2-square edit-icon"
                  style={{ fontSize: "1.5rem" }}
                ></i>
              </IconButton>
            </Tooltip>
          ) : (
            ""
          )}


          {editClicked ? (
            <IconButton
              onClick={
                " formValues?.visibleOnly" === true
                  ? () =>
                      setFormValues({
                        ...formValues,
                        visibleOnly: false,
                      })
                  : editListClick
              }
            >
              {"formValues?.visibleOnly " === true ? (
                <i class="bi bi-pencil-square edit-icon1"></i>
              ) : (
                <i
                  class="bi bi-check2-square edit-icon"
                  style={{ fontSize: "1.5rem" }}
                ></i>
              )}
            </IconButton>
          ) : saveRfqDeatails?.type === "PURCHASE ORDER" ? (
            <Tooltip title="PurchaseOrder Edit1111">
              <IconButton>
                {RfqThroughPoEdit ? (
                  <i
                    class="bi bi-check2-square edit-icon"
                    style={{ fontSize: "1.5rem" }}
                    onClick={SavePurchaseOrderFun("edit")}
                  ></i>
                ) : (
                  <i
                    class="bi bi-pencil-square edit-icon1"
                    onClick={editPurchaseOrderInRfqEnableBtn}
                  ></i>
                )}
                {/* <i class="bi bi-pencil-square edit-icon1"></i> */}
              </IconButton>
            </Tooltip>
          ) : hideSaveBtn ? (
            ""
          ) : (
            <Tooltip title="Save" onClick={SaveBtnFun("draft")}>
              <IconButton>
                <i class="bi bi-floppy save-icon"></i>
              </IconButton>
            </Tooltip>
          )}

          {showConfirmOrder && (
            <>
              <div className="showConfirmBtn">
                <button
                  onClick={cancelBtn}
                  className="btn btn-secondary-outlined"
                  style={{ width: "auto", marginLeft: "10%" }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  onClick={ConfirmOrderBtn}
                  // disabled={formAddedTable?.length === 0}
                  style={{ width: "auto", margin: "0" }}
                >
                  Confirm
                </button>
              </div>
            </>
          )}

          {showpurchaseOrderConfirm && (
            <>
              <div className="showConfirmBtn">
                <button
                  onClick={purchaseOrderCancelBtn}
                  className="btn btn-secondary-outlined"
                  style={{ width: "auto", marginLeft: "10%" }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  // onClick={SavePurchaseOrderFun}
                  onClick={confirmPurchaseOrderBtnFun}
                  // disabled={formAddedTable?.length === 0}
                  style={{ width: "auto", margin: "0" }}
                >
                  Confirm
                </button>
              </div>
            </>
          )}

          <div className="Rfqstaus">
            <td className="table-data-box">
              {saveRfqDeatails?.type === "RFQ" ? (
                <span
                  className="pending"
                  style={{ backgroundColor: "#e1dedc", color: "#000000" }}
                >
                  {" "}
                  RFQ
                </span>
              ) : saveRfqDeatails?.type === "PURCHASE ORDER" ? (
                <span
                  className="pending"
                  style={{ backgroundColor: "#e1dedc", color: "#000000" }}
                >
                  {" "}
                  PURCHASE ORDER
                </span>
              ) : (
                "---"
              )}
            </td>
          </div>
        </div>
        {editClicked && (
          <div
            style={{ width: "10%" }}
            className={
              "editedData?.status " === "Completed"
                ? "table-data-box-pending"
                : "table-data-box-drafted"
            }
          >
            {"editedData?.status" === "Completed" ? "Posted" : "Drafted"}
          </div>
        )}
      </div>
      <div className="global-white-bg-container" style={{ padding: "0 1%" }}>
        <div className="global-input-field-container" style={{ padding: "0" }}>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              marginLeft: "1%",
              padding: "1% 0",
            }}
          >
            <h2 className="accounts-name-list" style={{ fontWeight: "bold" }}>
              {/* RFQ{" "} */}

              {saveRfqDeatails?.type === "PURCHASE ORDER"
                ? "Purchase Order"
                : "RFQ"}
            </h2>
            <span className="accounts-name-list-value">
              /{formValues?.name}
            </span>
          </div>
        </div>
        <div className="name-ctn-section">
          <div
            className="global-single-input auto-complete"
            style={{ margin: "2rem" }}
          >
            <p>Vendor</p>
            <Autocomplete
              options={VendorList || []}
              getOptionLabel={(option) => option?.name}
              renderInput={(params) => (
                <TextField {...params} placeholder="select vendor" />
              )}
              onChange={getFormInfo("vendor")}
              value={formValues?.vendor}
              disabled={formValues?.visibleOnly}
            />
            <p className="doc-validation-alert">{validationAlert?.vendor}</p>
          </div>

          <div
            className="global-single-input auto-complete"
            style={{ margin: "2rem" }}
          >
            <p>Branch</p>
            <Autocomplete
              options={allBranchesList || []}
              getOptionLabel={(option) =>
                `${option?.storeCode}-${option?.branchName}`
              }
              renderInput={(params) => (
                <TextField {...params} placeholder="select branch" />
              )}
              onChange={getFormInfo("branch")}
              value={formValues.branch}
              disabled={formValues?.visibleOnly}
            />
            <p className="doc-validation-alert">{validationAlert?.branch}</p>
          </div>
          <div className="global-single-input" style={{ margin: "2rem" }}>
            <p>Quotation Date</p>
            <input
              type="date"
              value={formValues?.quotationDate}
              onChange={getFormInfo("quotationDate")}
              disabled={formValues?.visibleOnly}
            />
            {/* <p className="doc-validation-alert">
                {'validationAlert?.billReference'}
              </p> */}
          </div>
          {MultiCurrency && (
            <div
              className="global-single-input auto-complete"
              style={{ margin: "2rem" }}
            >
              <p>Currency</p>
              <Autocomplete
                options={[]}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField {...params} placeholder="select Currency" />
                )}
                onChange={""}
              />
              {/* <p className="doc-validation-alert">{validationAlert?.branch}</p> */}
            </div>
          )}

          {/* <div className="global-single-input">
              <p>Bill Date</p>
              <input
                type="date"
               
              />
              <p className="doc-validation-alert">
                {'validationAlert?.billDate'}
              </p>
            </div> */}
          {/* <div className="global-single-input">
              <p>Payment Reference</p>
              <input
                type="text"
                
              />
              <p className="doc-validation-alert">
                {'validationAlert?.paymentReference'}
              </p>
            </div> */}
          {/* <div className="global-single-input">
              <p>Accounting Date</p>
              <input
                type="date"
                
                
              />
              <p className="doc-validation-alert">
                {'validationAlert?.accountingDate'}
              </p>
            </div> */}
          {/* <div className="global-single-input auto-complete">
              <p>Journals</p>
              <Autocomplete
                options={[]}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField {...params} placeholder="select journal" />
                )}
              
            
              />
              <p className="doc-validation-alert">{'validationAlert?.journal'}</p>
            </div> */}
        </div>
      </div>
      <div className="global-white-bg-container add-table-selection">
        <div className="rewards-add-rewards-ctn-top-menu accounts-table-toggle">
          <div
            className="tab-menu-button active"
            id="productMenuBtn"
            onClick={CreateReqInvoiceLines}
          >
            <Typography fontSize={"0.9rem"} fontWeight={600}>
              Invoice Lines
            </Typography>
          </div>
          <div
            className="tab-menu-button"
            id="departmentMenuBtn"
            onClick={CreateReqOtherInfo}
          >
            <Typography fontSize={"0.9rem"} fontWeight={600}>
              Other Info
            </Typography>
          </div>
        </div>
        {isActiveTable === "invoiceLines" ? (
          <>
            {formValues?.visibleOnly === false && (
              <div
                className="global-input-field-container"
                style={{
                  paddingTop: "0",
                  alignItems: "end",
                  paddingLeft: "2rem",
                }}
              >
                <div
                  className="global-single-input auto-complete"
                  style={{ width: "15%" }}
                >
                  <p>Product </p>
                  <Autocomplete
                    options={allProductList || []}
                    getOptionLabel={(option) => option?.name}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="select product" />
                    )}
                    onChange={getFormInfo("product")}
                    value={formValues?.product}
                  />
                  <p className="doc-validation-alert">
                    {validationAlert?.product}
                  </p>
                </div>
                <div className="global-single-input" style={{ width: "15%" }}>
                  <p>Description</p>
                  <input
                    type="text"
                    placeholder="Description"
                    onChange={getFormInfo("Description")}
                    value={formValues?.Description}
                  />
                  <p className="doc-validation-alert">
                    {validationAlert?.Description}
                  </p>
                </div>
                <div
                  className="global-single-input auto-complete"
                  // style={{ width: "auto" }}
                  style={{ width: "8%" }}
                >
                  <p>UOM</p>
                  <Autocomplete
                    options={UomList || []}
                    getOptionLabel={(option) => option?.uomCat}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Uom" />
                    )}
                    onChange={getFormInfo("uom")}
                    value={formValues?.uom}
                  />

                  {/* <input
                    type="text"
                    value={formValues?.uom}
                    placeholder="UOM"
                    onChange={getFormInfo("uom")}
                  /> */}

                  <p className="doc-validation-alert">{validationAlert?.uom}</p>
                </div>
                <div className="global-single-input" style={{ width: "auto" }}>
                  <p>Qty</p>
                  <input
                    type="text"
                    placeholder="qty"
                    value={formValues?.qty}
                    onChange={getFormInfo("qty")}
                  />
                  <p className="doc-validation-alert">{validationAlert?.qty}</p>
                </div>

                <div className="global-single-input" style={{ width: "auto" }}>
                  <p>Unit Price</p>
                  <input
                    type="text"
                    placeholder="Unit Price"
                    value={formValues?.unitPrice}
                    onChange={getFormInfo("unitPrice")}
                  />
                  <p className="doc-validation-alert">
                    {validationAlert?.unitPrice}
                  </p>

                  {/* <p className="doc-validation-alert">{'validationAlert?.uom'}</p> */}
                </div>
                <div className="global-single-input" style={{ width: "auto" }}>
                  <p>Disc (%)</p>

                  <input
                    type="text"
                    placeholder="Disc"
                    value={formValues?.discount}
                    onChange={getFormInfo("discount")}
                  />

                  {/* <p className="doc-validation-alert">
                    {'validationAlert?.unitPrice'}
                  </p> */}
                </div>

                {/* <div
                  className="global-single-input"
                  style={{ width: "8%", margin: "0 1% 0 2%" }}
                >
                  <p>Unit Price Aft/Dis</p>
                  <input type="text" placeholder="%" />
                 
                </div> */}

                <div
                  className="global-single-input auto-complete"
                  // style={{ margin: "0 1% 0 1%" }}
                >
                  <p>Tax</p>
                  <Autocomplete
                    multiple
                    options={taxList || []}
                    getOptionLabel={(option) => option?.taxName}
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" />
                    )}
                    value={selectedTaxes}
                    getOptionDisabled={(option) => option === selectedTaxes}
                    onChange={(e, newValue) => setSelectedTaxes(newValue)}
                  />
                  {/* <p className="doc-validation-alert">
                    {'validationAlert?.taxes'}
                  </p> */}
                </div>
                <div className="global-single-input" style={{ width: "auto" }}>
                  <p>Sub Total</p>
                  <input
                    type="text"
                    value={formValues?.subTotal}
                    // onChange={getFormInfo("subTotal")}
                    placeholder="sub Total"
                  />
                  <p className="doc-validation-alert">
                    {validationAlert?.subTotal}
                  </p>
                </div>
                {rowIndex !== -1 ? (
                  <IconButton onClick={addTableData("edit")}>
                    <i class="bi bi-pencil-square edit-icon1"></i>
                  </IconButton>
                ) : (
                  <button
                    onClick={addTableData("add")}
                    className="plus-btn"
                    disabled={formValues?.visibleOnly}
                  >
                    +
                  </button>
                )}
                {rowIndex !== -1 && (
                  <IconButton
                    onClick={deleteTableData}
                    style={{ alignSelf: "flex-end" }}
                    // style={{border:"2px solid orange"}}
                  >
                    <i
                      className="bi bi-trash3"
                      style={{ color: "#db0000" }}
                    ></i>
                  </IconButton>
                )}
              </div>
            )}
            <div
              className="global-table-container"
              style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "0",
              }}
            >
              <table className="global-table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Product</th>
                    <th>Description</th>
                    <th>UOM</th>
                    <th>Qty</th>
                    <th>Unit Price</th>
                    <th>Discount</th>

                    <th>Tax</th>
                    <th>SubTotal</th>
                  </tr>
                </thead>
                <tbody>
                  {formAddedTableView?.length !== 0 ? (
                    formAddedTableView?.map((r, i) => (
                      <tr
                        key={i}
                        className={rowIndex === i && "edit-clicked-row"}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          !formValues?.visibleOnly && handleClickRow(r, i)
                        }
                      >
                        <td>{i + 1}</td>
                        <td>{r?.product}</td>
                        <td>{r?.Description}</td>
                        <td>{r?.unit}</td>
                        <td>{r?.quantity}</td>
                        <td>{r?.rate}</td>
                        <td>{r?.discount}</td>
                        <td
                          className="table-data-box"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            borderBottom: "4px solid #fff",
                            height: "auto",
                            textAlign: "center",
                            alignItems: "center",
                          }}
                        >
                          {r?.taxesName?.map((item, i) => (
                            <div
                              style={{
                                padding: "0 2px",
                                fontSize: "0.7rem",
                                width: "75%",
                              }}
                              key={i + 1}
                            >{`${item?.taxName}(${item?.amount?.toFixed(decimalPosition)}%)`}</div>
                          ))}
                        </td>
                        {/* <td>{r?.discount}</td> */}
                        <td>{r?.subTotal?.toFixed(2)}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={10}>No data</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="table-total-right-section1">
                <div className="table-total-right-section2">
                  {/* <div className="CreateReqDisc">
                    <p style={{ margin: "0", fontWeight: "600" }}>Disc %</p>
                    <input type="text" className="CreateReqDiscInput" />
                  </div> */}
                  <div className=" global-single-input">
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "600",
                      }}
                    >
                      Note{" "}
                    </p>
                    <textarea
                      type="text"
                      rows={3}
                      // className="CreateReqDiscInput"
                      onChange={getFormInfo("Note")}
                      value={formValues?.Note}
                      disabled={formValues?.visibleOnly}
                    />
                  </div>
                </div>

                <div className="table-total-right-section">
                  <div>
                    <p>Taxable Amount : </p>
                    <span>
                      {(
                        formAddedTable?.reduce(
                          (sum, item) => sum + item?.subTotal,
                          0
                        ) -
                        formAddedTable?.reduce(
                          (sum, item) => sum + item?.taxAmt,
                          0
                        )
                      )?.toFixed(2)}
                    </span>
                  </div>
                  <div>
                    <p>Tax : </p>
                    <span>
                      {formAddedTable
                        ?.reduce((sum, item) => sum + item?.taxAmt, 0)
                        ?.toFixed(2)}
                    </span>
                  </div>
                  <div>
                    <p>Total : </p>
                    <span>
                      {formAddedTable
                        ?.reduce((sum, item) => sum + item?.subTotal, 0)
                        ?.toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ display: "flex" }}>
              <div className="other-info-ctn justify-space-between">
                <div className="global-single-input" style={{ width: "47%" }}>
                  <p>Pay Terms</p>
                  <input
                    type="number"
                    value={formValues?.payterms}
                    onChange={getFormInfo("payterms")}
                    disabled={formValues?.visibleOnly}
                  />
                </div>
                <div className="global-single-input" style={{ width: "47%" }}>
                  <p>Expiry Date</p>
                  <input
                    type="date"
                    value={formValues?.expireDate}
                    onChange={getFormInfo("expireDate")}
                    disabled={formValues?.visibleOnly}
                  />
                </div>
                {/* <button className="btn btn-primary">Journal Entry</button> */}
              </div>
              {/* <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <button className="plus-btn">+</button>
              </div> */}
            </div>
          </>
        )}
      </div>
      <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />

      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />

      <ErrorSnackbar
        style={{ top: "8%", left: "34%", height: "20px", width: "35%" }}
        open={AlertSnack}
        handleClose={closeAlertSnackbar}
        message={"Select Expiry Date !! on other info!!"}
      />
    </div>
  );
};
