import React, { useState } from 'react'
import { convertDateFormat } from '../../../../Js/Date'

const PosSalesReportPrint = (props) => {
    const { data, formValues,CategoryList,category } = props
    
    const [isLoading, setIsLoading] = useState(false)
    const decimalPosition = localStorage.getItem("decimalPosition");
  
  console.log(category);
  console.log(CategoryList);
    return (
    <div className="print-main-div">
 <div className='header-div'>
        <div>
          <img src={formValues?.companyLogo} alt="" style={{height:"62px"}}/>
        </div>
        <div>
          <h1 style={{ fontSize: "larger", margin: "0" }}>{formValues?.label}</h1>
          {/* <p style={{margin:"0"}}>aaaaaaa</p> */}
        </div>
        <div>
          <h1 style={{ fontSize: "small", margin: "0" }}>companyName</h1>
          <p style={{ margin: "0", fontSize: "smaller" }}>{formValues?.companyName}</p>
          <p style={{ margin: "0", fontSize: "smaller" }}>{formValues?.companyAddress}</p>
          <p style={{ margin: "0", fontSize: "smaller" }}>{formValues?.companyContact}</p>
        </div>
      </div>
      <div className='global-report-table-container' style={{ maxHeight: "80vh",overflow:"visible" }}>
          <table>
            <thead>
              <tr>
                <th style={{ width: "8%" }}>Date</th>
                <th>Shift Code</th>
                <th>Invoice No</th>
                <th>Customer</th>
                {/* {
                paymentMethod &&
                  <th>Payment Method</th>
               } */}

                {
                  category === true && CategoryList?.map((r) => (
                    <th>{r?.categoryName}</th>
                  ))
                }

                <th>Stitching</th>
                <th>Discount</th>


                <th>Order Total</th>
                <th>Additional Discount</th>
                <th>Paid</th>
                <th>Current Status</th>
              </tr>
            </thead>
            <tbody>
            
           
             
              {!isLoading && data === undefined &&
                <tr>
                  <td style={{fontSize:'1rem'}} colSpan={20}>USE FILTER TO FETCH DATA</td>
                </tr>
              }
              {/* { posSalesReportsList?.report === undefined &&
                <tr>
                  <td style={{fontSize:'1rem'}} colSpan={20}>NO DATA</td>
                </tr>
              } */}

              {data !== undefined &&
                data?.map((r, i) => (
                  <>

              <tr key={i}>
                <td style={{color:"blue",backgroundColor: "transparent", border: "none",fontWeight:"600",fontSize:"1rem"}}>{r?.branchName}</td>
              </tr>
              {
                r?.data.map((k, i) => (
                  <tr style={{ height: "38px" }} key={i}>
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{convertDateFormat(k?.dateMillisec)}</td>
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{k?.shiftId}</td>
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{k?.orderNo}</td>
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{k?.customer}</td>
                    {/* {
                        paymentMethod &&
                        <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>
                        {k?.payment[0]?.type?.map((m,i)=>(
                          <span>
                          {i === k?.payment[0]?.type?.length - 1
                            ?m
                            :m + ","}
                        </span>
                        ))}
                      </td>
                    } */}
                   
                    {
                      category &&  CategoryList?.map((category) => (
                        <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>
                          {k.lines.find((line) => line.categoryId === category._id)
                            ? k.lines.find((line) => line.categoryId === category._id).amount?.toFixed(decimalPosition)

                            : 0?.toFixed(decimalPosition)}

                        </td>
                      ))
                    }
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{k?.totalStitchingRate?.toFixed(decimalPosition)}</td>
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{k?.discount?.toFixed(decimalPosition)}</td>
                  
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{k?.orderTotal?.toFixed(decimalPosition)}</td>
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{k?.deliveryDiscount?.toFixed(decimalPosition)}</td>

                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{k?.paymentTotal?.toFixed(decimalPosition)}</td>

                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }} className='table-data-box'>
                      {
                        k?.status?.toLowerCase()==="complete"?
                        <span className='post'>Complete</span>
                        :k?.status?.toLowerCase()==="pending"?
                        <span className='draft'>Pending</span>
                        :k?.status?.toLowerCase()==="printing"?
                        <span className='rfq'>Printing</span>
                        :k?.status?.toLowerCase()==="delivered"?
                        <span className='po'>Delivered</span>
                        : k?.status?.toLowerCase()==='orderreturned'?
                        <span className='cancel'>Order Returned</span>
                        :k?.status==='delivey Returned'?
                        <span className='cancel'>Delivery Returned</span>
                        :k?.status?.toLowerCase()==='return'?
                        <span className='sales-order'>Return</span>
                        :k?.status
                      }
                     
                      </td>
                  </tr>
               
                ))
              }  
              <tr>
                 <td colSpan="4" style={{textAlign:"right"}}>
                    <p style={{margin:"0 8px",fontSize:"0.9rem",fontWeight:600}}>Total</p>
                 </td>

                 {category &&
            CategoryList?.map((category) => (
              <td key={category._id}>
                {/* Calculate and display the grand total for each category */}
                {r.data.reduce((orderAcc, k) => {
                  const line = k.lines.find((line) => line.categoryId === category._id);
                  return orderAcc + (line ? line.amount : 0);
                }, 0)?.toFixed(decimalPosition)}
              </td>
            ))}
               
             
                 <td>{r?.subTotal?.stitchingRate?.toFixed(decimalPosition)}</td>
                 <td>{r?.subTotal?.discount?.toFixed(decimalPosition)}</td>
               
                 <td>{r?.subTotal?.orderTotal?.toFixed(decimalPosition)}</td>
                 <td>{r?.subTotal?.deliveryDiscount?.toFixed(decimalPosition)}</td>
                 <td>{r?.subTotal?.paidTotal?.toFixed(decimalPosition)}</td>

                 <td></td>
              </tr>
             
              </>
                ))
              }
            </tbody>



            <tfoot>
              <tr>
                <th colSpan="4">Total</th>
                {category &&
                  CategoryList?.map((category) => (
                    <th key={category._id}>
                      {/* Calculate and display the grand total for each category */}
                      {data?.reduce((acc, r) => {
                        return (
                          acc +
                          r.data.reduce((orderAcc, k) => {
                            const line = k.lines.find((line) => line.categoryId === category._id);
                            return orderAcc + (line ? line.amount : 0);
                          }, 0)
                        );
                      }, 0)?.toFixed(decimalPosition)}
                    </th>
                  ))}
                <th>{formValues?.netStitchingRate?.toFixed(decimalPosition)}</th>
                <th>{formValues?.netDiscount?.toFixed(decimalPosition)}</th>

                <th>{formValues?.netOrderTotal?.toFixed(decimalPosition)}</th>
                <th>{formValues?.netDeliveryDiscount?.toFixed(decimalPosition)}</th>
                <th>{formValues?.netPaidTotal?.toFixed(decimalPosition)}</th>

                <th></th>
              </tr>
            </tfoot>
          </table>

        </div>
    </div>
  )
}

export default PosSalesReportPrint