import {createSlice} from '@reduxjs/toolkit'

export const invoiceNoListSlice=createSlice({
    name:'invoiceNoList',
    initialState:{
        value:undefined
    },
    reducers:{
        get_all_sales_invoice:(state,action)=>{
            state.value=action.payload.salesInvoiceList
        }
    }
})
export const {get_all_sales_invoice} = invoiceNoListSlice.actions;
export default invoiceNoListSlice.reducer