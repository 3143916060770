import React, { useEffect, useState } from 'react'
// import './InvoiceMarginReport.css'
import { FormControlLabel, IconButton, Skeleton, Switch } from '@mui/material';
// import { ViewInvoiceMarginReportAPIcall } from './GeneralReportAPI';
import { useSelector } from 'react-redux';
// import { filterObjFromList } from '../../../../../Js/generalFunctions';
// import { convertDateFormat } from '../../../../../Js/Date';
import { useNavigate } from 'react-router-dom';
import { Bar } from "react-chartjs-2";
import { styled } from '@mui/material/styles';
import { StockMoveAgingReportAPIcall } from './ReportAPI';
import CategorySearchandFilter from '../../../Single Components/CategorySearchandFilter';
import { convertDateFormat } from '../../../../Js/Date';
import CustomPagination from '../../../Single Components/CustomPagination';
import { today } from '../../../../Js/Date';
import { filterObjFromList } from '../../../../Js/generalFunctions';
import { viewAllCurrencyUpdated } from '../../../../API/Settings/Currency/CurrencyAPI';
import { companyProfileInfoForUser } from '../../../../API/Settings/Company Settings/companySettingsAPI';

function StockAgingReport() {
    const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue)
    const currencyListUpdated = useSelector(
        (state) => state.viewUpdatedCurrency.value
    );
    const filtereDataOptions = useSelector((state) => state.listCategory);

    const tableListData = useSelector((state) => state?.ReportSlice?.StockMoveAgingReport)
    console.log(tableListData, 'tableListData')
    const decimalPosition = localStorage.getItem("decimalPosition");

    const [FormDataInfo, setFormDataInfo] = useState([])
    const [symbol, setSymbol] = useState(null);


    console.log(tableListData?.grandSumOf30Qty, 'kkk')

    const handleDataFromChild = (data) => {
        setFormDataInfo(data);
    };

    useEffect(() => {
        let currencyObj = filterObjFromList("_id", currencyListUpdated, "currency", companyProfileData)
        setSymbol(currencyObj?.symbol)
    }, [companyProfileData, currencyListUpdated])
    useEffect(() => {
        viewAllCurrencyUpdated({ status: 0 });
        companyProfileInfoForUser();
    }, [])

    useEffect(() => {
        if (FormDataInfo.length !== 0) {
            let loadData=window.location.pathname===filtereDataOptions?.filterActive
            !loadData &&  StockMoveAgingReportAPIcall({
                todayDate: FormDataInfo.fromDate === '' ? today : `${FormDataInfo.fromDate} 00:00:00`,
                search: FormDataInfo?.search,
                branchFilter: FormDataInfo?.branchList,
                categoryFilter: FormDataInfo?.category,
            })
        }
    }, [FormDataInfo])

    return (
        <div className="in-or-out-container">
            <div className="in-or-out-report-wrapper">
                <div className="sub-nav-head">
                    <div>
                        <div className="path-name-wrapper" style={{ backgroundColor: '#c3d4f7', maxWidth: "17em" }}>
                            <h3 style={{ color: '#06066f' }} className="path-name">Purchase Ageing Report</h3>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}>
                        <CategorySearchandFilter
                            onData={handleDataFromChild}
                            isCategory={true}
                            isToDate={false}

                        />
                        <IconButton
                            style={{ width: "100px" }}

                            className="print-icon-container">
                            <i class="bi bi-printer printer-icon"></i>
                        </IconButton>
                        <IconButton
                            style={{ width: "100px" }}
                            className="download-icon-container">
                            <i class="bi bi-arrow-down-circle download-icon"></i>
                        </IconButton>
                    </div>
                </div>
                <div className="invoice-table-container">
                    <div className="invoice-main-div">
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: "98%"
                        }}>
                            <h3>Purchase Ageing Report</h3>

                        </div>
                        {tableListData?.list !== undefined &&
                            tableListData?.list?.length !== 0 &&
                            tableListData?.list
                                ?.slice(0)
                                ?.map((r, i) => (

                                    <div key={i}>
                                        <h3 className='branch-head'>{r?.branchName}</h3>
                                        <table >

                                            <thead>
                                                <tr>
                                                    <th rowSpan="2">Code</th>
                                                    <th rowSpan="2">Product</th>
                                                    <th rowSpan="2">UOM</th>
                                                    <th style={{ textAlign: "center" }} colSpan="2">0-30</th>
                                                    <th style={{ textAlign: "center" }} colSpan="2">31-60</th>
                                                    <th style={{ textAlign: "center" }} colSpan="2">61-90</th>
                                                    <th style={{ textAlign: "center" }} colSpan="2">91-120 </th>
                                                    <th style={{ textAlign: "center" }} colSpan="2">121-150</th>
                                                    <th style={{ textAlign: "center" }} colSpan="2">151 Above</th>
                                                    <th style={{ textAlign: "center" }} colSpan="2">Total</th>
                                                </tr>
                                                <tr className="invoice-sub-head">
                                                    <td>QTY</td>
                                                    <td>Value</td>
                                                    <td>QTY</td>
                                                    <td>Value</td>
                                                    <td>QTY</td>
                                                    <td>Value</td>
                                                    <td>QTY</td>
                                                    <td>Value</td>
                                                    <td>QTY</td>
                                                    <td>Value</td>
                                                    <td>QTY</td>
                                                    <td>Value</td>
                                                    <td>QTY</td>
                                                    <td>Value</td>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {r?.productList.map((stockMove, idx) => (
                                                    <tr key={idx}>
                                                        <td>{stockMove?.productCode}</td>
                                                        <td>{stockMove?.productName}</td>
                                                        <td>{stockMove?.uomName}</td>
                                                        <td>{stockMove?.categories?.upto30daysQty?.toFixed(decimalPosition)}</td>
                                                        <td>{symbol}&nbsp;{stockMove?.categories?.upto30daysValue?.toFixed(decimalPosition)}</td>
                                                        <td>{stockMove?.categories?.upto60daysQty?.toFixed(decimalPosition)}</td>
                                                        <td>{symbol}&nbsp;{stockMove?.categories?.upto60daysValue?.toFixed(decimalPosition)}</td>
                                                        <td>{stockMove?.categories?.upto90daysQty?.toFixed(decimalPosition)}</td>
                                                        <td>{symbol}&nbsp;{stockMove?.categories?.upto90daysValue?.toFixed(decimalPosition)}</td>
                                                        <td>{stockMove?.categories?.upto120daysQty?.toFixed(decimalPosition)}</td>
                                                        <td>{symbol}&nbsp;{stockMove?.categories?.upto120daysValue?.toFixed(decimalPosition)}</td>
                                                        <td>{stockMove?.categories?.upto150daysQty?.toFixed(decimalPosition)}</td>
                                                        <td>{symbol}&nbsp;{stockMove?.categories?.upto150daysValue?.toFixed(decimalPosition)}</td>
                                                        <td>{stockMove?.categories?.morethan150daysQty?.toFixed(decimalPosition)}</td>
                                                        <td>{symbol}&nbsp;{stockMove?.categories?.morethan150daysValue?.toFixed(decimalPosition)}</td>
                                                        <td>{stockMove?.sumOfTotalQty?.toFixed(decimalPosition)}</td>
                                                        <td>{symbol}&nbsp;{stockMove?.sumOfTotalValue?.toFixed(decimalPosition)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            <tr style={{ textAlign: "end", position: "sticky", bottom: "0" }}>

                                                <th style={{ textAlign: "end" }} colSpan={3}>Total</th>
                                                <th> {r?.totalUpto30daysQty?.toFixed(decimalPosition)}</th>
                                                <th> {symbol}&nbsp;{r?.totalUpto30daysValue?.toFixed(decimalPosition)}</th>
                                                <th>{r?.totalUpto60daysQty?.toFixed(decimalPosition)}</th>
                                                <th> {symbol}&nbsp;{r?.totalUpto60daysValue?.toFixed(decimalPosition)}</th>
                                                <th> {r?.totalUpto90daysQty?.toFixed(decimalPosition)}</th>
                                                <th> {symbol}&nbsp;{r?.totalUpto90daysValue?.toFixed(decimalPosition)}</th>
                                                <th> {r?.totalUpto120daysQty?.toFixed(decimalPosition)}</th>
                                                <th> {symbol}&nbsp;{r?.totalUpto120daysValue?.toFixed(decimalPosition)}</th>
                                                <th>{r?.totalUpto150daysQty?.toFixed(decimalPosition)}</th>
                                                <th> {symbol}&nbsp;{r?.totalUpto150daysValue?.toFixed(decimalPosition)}</th>
                                                <th> {r?.totalMorethan150daysQty?.toFixed(decimalPosition)}</th>
                                                <th> {symbol}&nbsp;{r?.totalMorethan150daysValue?.toFixed(decimalPosition)}</th>
                                                <th>{r?.totalQty?.toFixed(decimalPosition)}</th>
                                                <th> {symbol}&nbsp;{r?.totalValue?.toFixed(decimalPosition)}</th>
                                            </tr>
                                        </table>
                                    </div>

                                ))}


                        {/* <table style={{ width: "100%" }}>
                       
                                 <tr className='grand-row' style={{ textAlign: "end", position: "sticky", bottom: "0" }}  >

                                    <th style={{ textAlign: "end",width:'20%' }} colSpan={3} >Grand Total</th>
                                    <th>{symbol}&nbsp;{tableListData?.grandSumOf30Qty?.toFixed(decimalPosition)}</th>
                                    <th>{symbol}&nbsp;{tableListData?.grandSumOf30Value?.toFixed(decimalPosition)}</th>
                                    <th>{symbol}&nbsp;{tableListData?.grandSumOf60Qty?.toFixed(decimalPosition)}</th>
                                    <th>{symbol}&nbsp;{tableListData?.grandSumOf60Value?.toFixed(decimalPosition)}</th>
                                    <th>{symbol}&nbsp;{tableListData?.grandSumOf90Qty?.toFixed(decimalPosition)}</th>
                                    <th>{symbol}&nbsp;{tableListData?.grandSumOf90Value?.toFixed(decimalPosition)}</th>
                                    <th>{symbol}&nbsp;{tableListData?.grandSumOf120Qty?.toFixed(decimalPosition)}</th>
                                    <th>{symbol}&nbsp;{tableListData?.grandSumOf120Value?.toFixed(decimalPosition)}</th>
                                    <th>{symbol}&nbsp;{tableListData?.grandSumOf150Qty?.toFixed(decimalPosition)}</th>
                                    <th>{symbol}&nbsp;{tableListData?.grandSumOf150Value?.toFixed(decimalPosition)}</th>
                                    <th>{symbol}&nbsp;{tableListData?.grandSumOfAbove150Qty?.toFixed(decimalPosition)}</th>
                                    <th>{symbol}&nbsp;{tableListData?.grandSumOfAbove150Value?.toFixed(decimalPosition)}</th>
                                    <th>{symbol}&nbsp;{tableListData?.grandSumOfTotalQty?.toFixed(decimalPosition)}</th>
                                    <th>{symbol}&nbsp;{tableListData?.grandSumOfTotalValue?.toFixed(decimalPosition)}</th>
                                </tr>

                        </table> */}

                        {tableListData?.list !== undefined &&
                            tableListData?.list?.length !== 0 &&
                            tableListData?.list
                                ?.slice(0)
                                ?.map((r, i) => (

                                    <div key={i}>
                                        {i === 0 && (
                                            <table >

                                                <thead style={{ backgroundColor: 'white', visibility: 'collapse' }}>
                                                    <tr>
                                                        <th rowSpan="2">Code</th>
                                                        <th rowSpan="2">Product</th>
                                                        <th rowSpan="2">UOM</th>
                                                        <th style={{ textAlign: "center" }} colSpan="2">0-30</th>
                                                        <th style={{ textAlign: "center" }} colSpan="2">31-60</th>
                                                        <th style={{ textAlign: "center" }} colSpan="2">61-90</th>
                                                        <th style={{ textAlign: "center" }} colSpan="2">91-120 </th>
                                                        <th style={{ textAlign: "center" }} colSpan="2">121-150</th>
                                                        <th style={{ textAlign: "center" }} colSpan="2">151 Above</th>
                                                        <th style={{ textAlign: "center" }} colSpan="2">Total</th>
                                                    </tr>
                                                    <tr className="invoice-sub-head">
                                                        <td>QTY</td>
                                                        <td>Value</td>
                                                        <td>QTY</td>
                                                        <td>Value</td>
                                                        <td>QTY</td>
                                                        <td>Value</td>
                                                        <td>QTY</td>
                                                        <td>Value</td>
                                                        <td>QTY</td>
                                                        <td>Value</td>
                                                        <td>QTY</td>
                                                        <td>Value</td>
                                                        <td>QTY</td>
                                                        <td>Value</td>

                                                    </tr>
                                                </thead>
                                                <tbody style={{ backgroundColor: 'white', visibility: 'collapse' }}>
                                                    {r?.productList.map((stockMove, idx) => (
                                                        <tr key={idx}>
                                                            <td>{stockMove?.productCode}</td>
                                                            <td>{stockMove?.productName}</td>
                                                            <td>{stockMove?.uomName}</td>
                                                            <td>{stockMove?.categories?.upto30daysQty?.toFixed(decimalPosition)}</td>
                                                            <td>{symbol}&nbsp;{stockMove?.categories?.upto30daysValue?.toFixed(decimalPosition)}</td>
                                                            <td>{stockMove?.categories?.upto60daysQty?.toFixed(decimalPosition)}</td>
                                                            <td>{symbol}&nbsp;{stockMove?.categories?.upto60daysValue?.toFixed(decimalPosition)}</td>
                                                            <td>{stockMove?.categories?.upto90daysQty?.toFixed(decimalPosition)}</td>
                                                            <td>{symbol}&nbsp;{stockMove?.categories?.upto90daysValue?.toFixed(decimalPosition)}</td>
                                                            <td>{stockMove?.categories?.upto120daysQty?.toFixed(decimalPosition)}</td>
                                                            <td>{symbol}&nbsp;{stockMove?.categories?.upto120daysValue?.toFixed(decimalPosition)}</td>
                                                            <td>{stockMove?.categories?.upto150daysQty?.toFixed(decimalPosition)}</td>
                                                            <td>{symbol}&nbsp;{stockMove?.categories?.upto150daysValue?.toFixed(decimalPosition)}</td>
                                                            <td>{stockMove?.categories?.morethan150daysQty?.toFixed(decimalPosition)}</td>
                                                            <td>{symbol}&nbsp;{stockMove?.categories?.morethan150daysValue?.toFixed(decimalPosition)}</td>
                                                            <td>{stockMove?.sumOfTotalQty?.toFixed(decimalPosition)}</td>
                                                            <td>{symbol}&nbsp;{stockMove?.sumOfTotalValue?.toFixed(decimalPosition)}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                                <tr style={{ textAlign: "end", position: "sticky", bottom: "0", backgroundColor: 'white', visibility: 'collapse' }}>

                                                    <th style={{ textAlign: "end" }} colSpan={3}>Total</th>
                                                    <th> {r?.totalUpto30daysQty?.toFixed(decimalPosition)}</th>
                                                    <th> {symbol}&nbsp;{r?.totalUpto30daysValue?.toFixed(decimalPosition)}</th>
                                                    <th> {r?.totalUpto60daysQty?.toFixed(decimalPosition)}</th>
                                                    <th> {symbol}&nbsp;{r?.totalUpto60daysValue?.toFixed(decimalPosition)}</th>
                                                    <th> {r?.totalUpto90daysQty?.toFixed(decimalPosition)}</th>
                                                    <th> {symbol}&nbsp;{r?.totalUpto90daysValue?.toFixed(decimalPosition)}</th>
                                                    <th> {r?.totalUpto120daysQty?.toFixed(decimalPosition)}</th>
                                                    <th> {symbol}&nbsp;{r?.totalUpto120daysValue?.toFixed(decimalPosition)}</th>
                                                    <th> {r?.totalUpto150daysQty?.toFixed(decimalPosition)}</th>
                                                    <th> {symbol}&nbsp;{r?.totalUpto150daysValue?.toFixed(decimalPosition)}</th>
                                                    <th> {r?.totalMorethan150daysQty?.toFixed(decimalPosition)}</th>
                                                    <th> {symbol}&nbsp;{r?.totalMorethan150daysValue?.toFixed(decimalPosition)}</th>
                                                    <th> {r?.totalQty?.toFixed(decimalPosition)}</th>
                                                    <th> {symbol}&nbsp;{r?.totalValue?.toFixed(decimalPosition)}</th>
                                                </tr>
                                                <tr className='grand-row' style={{ textAlign: "end", position: "sticky", bottom: "0" }}  >

                                                    <th style={{ textAlign: "end", }} colSpan={3} >Grand Total</th>
                                                    <th>{tableListData?.grandSumOf30Qty?.toFixed(decimalPosition)}</th>
                                                    <th>{symbol}&nbsp;{tableListData?.grandSumOf30Value?.toFixed(decimalPosition)}</th>
                                                    <th>{tableListData?.grandSumOf60Qty?.toFixed(decimalPosition)}</th>
                                                    <th>{symbol}&nbsp;{tableListData?.grandSumOf60Value?.toFixed(decimalPosition)}</th>
                                                    <th>{tableListData?.grandSumOf90Qty?.toFixed(decimalPosition)}</th>
                                                    <th>{symbol}&nbsp;{tableListData?.grandSumOf90Value?.toFixed(decimalPosition)}</th>
                                                    <th>{tableListData?.grandSumOf120Qty?.toFixed(decimalPosition)}</th>
                                                    <th>{symbol}&nbsp;{tableListData?.grandSumOf120Value?.toFixed(decimalPosition)}</th>
                                                    <th>{tableListData?.grandSumOf150Qty?.toFixed(decimalPosition)}</th>
                                                    <th>{symbol}&nbsp;{tableListData?.grandSumOf150Value?.toFixed(decimalPosition)}</th>
                                                    <th>{tableListData?.grandSumOfAbove150Qty?.toFixed(decimalPosition)}</th>
                                                    <th>{symbol}&nbsp;{tableListData?.grandSumOfAbove150Value?.toFixed(decimalPosition)}</th>
                                                    <th>{tableListData?.grandSumOfTotalQty?.toFixed(decimalPosition)}</th>
                                                    <th>{symbol}&nbsp;{tableListData?.grandSumOfTotalValue?.toFixed(decimalPosition)}</th>
                                                </tr>
                                            </table>
                                        )}
                                    </div>

                                ))}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default StockAgingReport