import axios from "axios";
import { HEADERS, HEADER_QUERY } from "../../../UrlAndPaths";
import store from "../../../../Redux/store";
import { update_all_leave_application_list } from "../../../../Redux/Staff/LEAVE/allLeaveApplicationSlice";

// view leave Application

export const viewLeaveApplicationsAPICall=async(body,setIsLoading)=>{
    if(setIsLoading !== undefined) setIsLoading(true)

    await axios.post("employee/viewStaffLeave",body,HEADERS)
                .then((res)=>{
                    if(res.status===200){
                        store.dispatch(update_all_leave_application_list(
                            {allLeaveApplication:res.data}
                        ))
                        if(setIsLoading !== undefined)  setIsLoading(false)
                    }
                }).catch((err)=>{
                    store.dispatch(update_all_leave_application_list({
                        allLeaveApplication:undefined
                    }))
                    console.error(err.response)
                    // switch (err.response.status) {
                    //     case 404:
                    //         setSnackMsg('No data available..')
                    //       break;
                    //     case 500:
                    //         setSnackMsg('Server error!!')
                    //       break;
                    //     case 401:
                    //         setSnackMsg('Unauthorized!!')
                    //       break;
                    //     case 403:
                    //         setSnackMsg('Forbidden!!')
                    //       break;
                      
                    //     default:
                    //         setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
                    //       break;
                    //   }
                    //   setErrorSnack(true)
                    if(setIsLoading !== undefined)  setIsLoading(false)
                })
}


// approve/reject leave application

export const approveLeaveApplicationsAPICall=async(body)=>{
    await axios.put("employee/approveLeave",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            console.log("Done");
        }
    })
    .catch((err)=>{
        console.error(err.response)
    })
}

// delete leave application

export const deleteLeaveApplicationsAPICall=async(body,updateListener,setErrorSnackbar,setSnackMsg)=>{
    await axios.delete("employee/deleteStaffLeave",{headers:HEADER_QUERY,data:body})
            .then((res)=>{
                if(res.status===200){
                    updateListener()
                    setSnackMsg("Leave Application Deleted !!")
                    setErrorSnackbar(true)
                }
            })
            .catch((err)=>{
                console.error(err.response.status);
            })
}