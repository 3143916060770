import { createSlice } from "@reduxjs/toolkit";

export const customerInvoiceSlice = createSlice({
  name: "customerInvoiceSlice",
  initialState: {
    apiResList: undefined,
    rowData:undefined,
    generateName:undefined,
    customerInvoices:undefined,
    singleResList:undefined
  },
  reducers: {
    get_customer_invoice_list: (state, action) => {
      state.apiResList = action.payload.customerInvoiceData;
    },
    get_customer_invoice_row_data_list: (state, action) => {
      state.rowData = action.payload.singleCustomerInvoiceData;
    },
    generate_customer_invoice_name: (state, action) => {
      state.generateName = action.payload.customerInvoiceName;
    },
    get_single_customer_invoice_list: (state, action) => {
      state.singleResList = action.payload.singleInvoiceData;
    },
    get_all_invoice_customer_list: (state, action) => {
      state.customerInvoices = action.payload.customerInvoiceData;
    },
  },
});

export const { get_customer_invoice_list,
    get_customer_invoice_row_data_list,
    generate_customer_invoice_name,
    get_single_customer_invoice_list,
    get_all_invoice_customer_list
} = customerInvoiceSlice.actions;

export default customerInvoiceSlice.reducer;
