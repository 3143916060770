import axios from 'axios'
import { HEADERS } from '../UrlAndPaths';
import store from '../../Redux/store';
import { setErrorSnack, setSnackMsg } from '../../Redux/Dashboard/mainDashBoardSlice';

export const verifyPasswordAPICall= async (
    body,
    setEditPasswordDialog,
    setErrorSnackbar,
    setSnackMsg,
    setEditDialog
    )=>{
    await axios.post("admin/verifypassword",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            // console.log('login success');
            setEditPasswordDialog(false)
            setEditDialog(true)
        }
    })
    .catch((err)=>{
        console.log(err.response);
        setErrorSnackbar(true)
        if (err.response.status===401) {
            setSnackMsg('Invalid Password')
        }
        if (err === undefined) {
            setSnackMsg("Network Error!");
          }
          if (err.response.status === 500) {
            setSnackMsg("Server error!");
          }
    })
}
export const verifyPasswordForShiftAPICall= async (
    body,
    shiftEndFn
    )=>{
    await axios.post("admin/verifypassword",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            // console.log('login success');
            shiftEndFn()
        }
    })
    .catch((err)=>{
        let errorMsg=""
        if(err.response.status===404){
            errorMsg='Server Error'
        }
        if (err.response.status === 403) {
            errorMsg='Forbidden'
        }
        if (err.response.status === 401) {
            errorMsg='Unauthorized'
        }
        if (err.response.status === 500) {
            errorMsg='Server Error'
        }
        if(err.response.status===undefined){
            errorMsg='Network Connection error!!'
        }

        store.dispatch(setSnackMsg(errorMsg))
        store.dispatch(setErrorSnack(true))
    })
}