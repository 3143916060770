import {createSlice} from '@reduxjs/toolkit'

export const generateGrnIdSlice=createSlice({
    name:'generateGrnSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_grn_id:(state,action)=>{
            state.value=action.payload.grnId
        }
    }
})
export const {get_grn_id} = generateGrnIdSlice.actions;
export default generateGrnIdSlice.reducer;
