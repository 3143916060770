import axios from "axios";
import { HEADERS, HEADER_QUERY } from "../../../UrlAndPaths";
import store from "../../../../Redux/store";
import { update_holiday_type_list } from "../../../../Redux/Staff/LEAVE/holidayTypeViewSlice";

// view Holiday list

export const viewHolidayTypesAPICall=async(setIsLoading,setErrorSnack,setSnackMsg)=>{
    if(setIsLoading !==undefined)setIsLoading(true)
    await axios.get("employee/viewHoliday",HEADERS)
                .then((res)=>{
                    if(res.status===200){
                        store.dispatch(update_holiday_type_list({
                            holidayType:res.data
                        }))
                        if(setIsLoading !==undefined)setIsLoading(false)
                    }
                })
                .catch((err)=>{
                    store.dispatch(update_holiday_type_list({
                        holidayType:undefined
                    }))
                    console.error(err.response)
                    switch (err.response.status) {
                        case 404:
                            setSnackMsg('No data available..')
                          break;
                        case 500:
                            setSnackMsg('Server error!!')
                          break;
                        case 401:
                            setSnackMsg('Unauthorized!!')
                          break;
                        case 403:
                            setSnackMsg('Forbidden!!')
                          break;
                      
                        default:
                            setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
                          break;
                      }
                      setErrorSnack(true)
                    if(setIsLoading !==undefined)setIsLoading(false)
                })
    }

// add holiday type

export const addHolidayTypeAPICall=async(body,
                                        updateListener,
                                        clearStates,
                                        setSuccess,
                                        setError,
                                        setMsg)=>{
    await axios.post("employee/addHoliday",body,HEADERS)
            .then((res)=>{
                if(res.status===200){
                    updateListener()
                    clearStates()
                    setMsg("Holiday Type Added Succesfully");
                    setSuccess(true);
                }
            })
            .catch((err)=>{
                console.error(err.response)
                setError(true)
            })
}
// edit holiday type

export const editHolidayTypeAPICall=async(body,
                                        updateListener,
                                        clearStates,
                                        setSuccess,
                                        setError,
                                        setMsg
                                        )=>{
    await axios.put("employee/editHoliday",body,HEADERS)
            .then((res)=>{
                if(res.status===200){
                    updateListener()
                    clearStates()
                    setMsg("Holiday Type Edited Succesfully");
                    setSuccess(true);
                }
            })
            .catch((err)=>{
                console.error(err.response);
                setError(true)
            })
}

// delete holiday type

export const deleteHolidayAPICall=async(body,updateListener,setError,setSnackMsg)=>{
    await axios.delete('employee/deleteHoliday',{
        headers:HEADER_QUERY,
        data:body
    })
    .then((res)=>{
        if(res.status===200){
            updateListener()
            setSnackMsg("Holiday Type Deleted !!")
            setError(true)
        }
    })
    .catch((err)=>{
        console.error(err.response)
        setSnackMsg("Oops Something went wrong !!")
        setError(true)
    })
}