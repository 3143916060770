import React, { useState } from "react";
import "../../../../css/Settings/Shift/addShift.css";
import {
  Autocomplete,
  Checkbox,
  Dialog,
  Select,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllActiveEmployeesAPICall } from "../../../../API/Staff/HRM/employeeAPI";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { viewAllBranchesAPICall } from "../../../../API/Settings/Company Settings/companySettingsAPI";
import { today } from "../../../../Js/Date";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import { addShiftAPICall, viewEmployeeByBranchInShift } from "../../../../API/Settings/Shift/viewShiftAPI";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const AddShift = () => {
  //Branch id from LS
  const branchId = localStorage.getItem("branchId");
  //Active Employee List
  const allActiveEmpList = useSelector(
    (state) => state.allActiveEmployeeSlice.value
  );
  // all branch list
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  const employeeData=useSelector((state)=>state.shiftStatusSlice.employeeData)
  const [radioValue, setRadioValue] = useState();
  const [autoShiftDialog, setAutoShiftDialog] = useState(false);
  const [manualShiftDialog, setManualShiftDialog] = useState(false);
  const [manualShiftAddEmployeeDialog, setManualShiftAddEmployeeDialog] =
    useState(false);
  //Snackbar states
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false);
  const [openErrorSnack, setOpenErrorSnack] = useState(false);
  const [denominationChecked, setDenominationChecked] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!");

  //Selected Branch Obj
  const [selectedBranchObj, setSelectedBranchObj] = useState([]);
  //Branch wise filtered employee data
  const [filteredEmpData, setFilteredEmpData] = useState([]);
  //Selected Employees
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  //Selected employee pk for backend
  const [selectedEmpPk,setSelectedEmpPk]=useState([])
  const [isBranchWise,setIsBranchWise]=useState(false)

  const changeRadioBtnValue = (e) => {
    setRadioValue(e.target.value);
  };
  const addShiftButtonFn = () => {
    if (radioValue === "autoShift") {
      setAutoShiftDialog(true);
    } else if (radioValue === "manualShift") {
      setSelectedEmployees([])
      setSelectedBranchObj([])
      setManualShiftDialog(true);
     
    }
  };

  const closeManualShift=()=>{
    // setSelectedEmployees([])
    // setSelectedBranchObj([])
    setManualShiftDialog(false);
  }

  const manualShiftAddEmployeeDialogOpen = () => {
    setManualShiftAddEmployeeDialog(true);
  };

  //Close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setOpenSuccessSnack(false);
  };
  //Close errorSnackbar
  const closeErrorSnackbar = () => {
    setOpenErrorSnack(false);
  };

  //Filter Employees in branch wise
  const filterEmployeesInBranchWise = (
    selectedBranchCode,
    allEmployeesList
  ) => {
    let filteredData = allEmployeesList?.filter(
      (obj) => obj?.branchId === selectedBranchCode
    );
    setFilteredEmpData(filteredData);
  };

  //Construct selected emp pk
  const filterEmpPkArray=()=>{
    let filtered= selectedEmployees?.map((obj)=>obj?._id)
    setSelectedEmpPk(filtered)
  }

console.log(selectedEmpPk);
  //Add shift api call body
  const addShiftBody = {
    branchId: selectedBranchObj?.storeCode,
    duration: null,
    startTime: null,
    endTime: null,
    workingDays: [],
    employee: selectedEmpPk,
    shiftType: 1,
    isDenomination:denominationChecked,
    isBranchWise:isBranchWise === true ? false :true
  };

  //Click Add Shift final Submit
  const addShiftFinalSubmit = () => {
    if(addShiftBody?.branchId===undefined)
    {
      alert('Select branch!!')
    }
    else if(selectedEmpPk.length===0)
    {
      alert('Select employees!!')
    }
    else
    {

      addShiftAPICall(addShiftBody,setOpenSuccessSnack,setOpenErrorSnack,setSnackMsg,setManualShiftDialog);
    }

  };

  //*useEffects
  //initial Calls
  useEffect(() => {
    viewAllBranchesAPICall();
    getAllActiveEmployeesAPICall();
  }, []);
  //Call filter employee in branch wise fn
  useEffect(() => {
    if (allActiveEmpList !== undefined) {
      if (selectedBranchObj !== undefined) {
        filterEmployeesInBranchWise(
          selectedBranchObj?.storeCode,
          allActiveEmpList
        );
      }
    }
  }, [selectedBranchObj]);
//Extract employee pk array
useEffect(()=>{
  filterEmpPkArray()
},[selectedEmployees])


// select already aaded employee in branch wise
useEffect(()=>{
  if(selectedBranchObj !== null){
    viewEmployeeByBranchInShift({branchId:selectedBranchObj?._id})

  }
 

},[selectedBranchObj])

console.log(selectedBranchObj);

useEffect(()=>{
  
  if (employeeData !== undefined&&selectedBranchObj !== null) {
    let employeeArray = [];
    if (employeeData.employee?.length > 0 && allActiveEmpList?.length > 0) {
      employeeArray = allActiveEmpList.filter(item =>
        employeeData.employee.includes(item?._id)
      );
    }
    setSelectedEmployees(employeeArray);
  } else {
    
    setSelectedEmployees([]);
  }
},[employeeData,allActiveEmpList,selectedBranchObj])

 

  return (
    <div className="add-shift-container">
      <div className="add-shift-radio-btn-container">
        {/* <div className="add-shift-single-radio-btn">
          <input
            type="radio"
            value="autoShift"
            onChange={changeRadioBtnValue}
            id="autoShift"
            name="addShiftBtn"
          />
          <label htmlFor="autoShift">Auto Shift</label>
        </div> */}
        <div className="add-shift-single-radio-btn">
          <input
            type="radio"
            value="manualShift"
            onChange={changeRadioBtnValue}
            id="manualShift"
            name="addShiftBtn"
          />
          <label htmlFor="manualShift">Manual Shift</label>
        </div>
        <div className="add-shift-single-radio-btn">
          <input
            type="radio"
            value="none"
            onChange={changeRadioBtnValue}
            id="ShiftNone"
            name="addShiftBtn"
          />
          <label htmlFor="ShiftNone">None</label>
        </div>
      </div>
      <div className="add-shift-button-container">
        <button onClick={addShiftButtonFn} className="btn btn-primary">
          Submit
        </button>
        <div className="add-shift-denomination-checkbox">
          <input type="checkbox" id="shiftDenomination" onChange={(e)=>{
            if(e.target.checked) setDenominationChecked(true)
            else setDenominationChecked(false)
          }}/>
          <label htmlFor="shiftDenomination">Denomination</label>
        </div>
      </div>

      {/* auto shift dialog  */}
      <Dialog open={autoShiftDialog} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setAutoShiftDialog(false)}>
        <div className="auto-shift-dialog-container">
          <h3>Add Auto Shift</h3>
          <div className="global-single-input auto-shift-outlet-input">
            <p>Outlet</p>
            <Select></Select>
          </div>
          <h4>Current Date & Time</h4>
          <div className="auto-shift-input-container">
            <div className="global-single-input date-time-input">
              <p>Start Date</p>
              <input type="date" />
            </div>
            <div className="global-single-input date-time-input">
              <p>Duration</p>
              <input type="text" />
              <p className="auto-shift-suffix-text">
                End Date&nbsp;:&nbsp;31/01/2021
              </p>
            </div>
            <div className="global-single-input date-time-input">
              <p>Start Time</p>
              <input type="time" />
            </div>
            <div className="global-single-input date-time-input">
              <p>End Time</p>
              <input type="time" />
            </div>
          </div>
          <h4>Select Working Days</h4>
          <div className="auto-shift-working-days-container">
            <div className="auto-shift-single-working-days-container">
              <input type="checkbox" id="shiftSunday" />
              <label htmlFor="shiftSunday">Sunday</label>
            </div>
            <div className="auto-shift-single-working-days-container">
              <input type="checkbox" id="shiftMonday" />
              <label htmlFor="shiftMonday">Monday</label>
            </div>
            <div className="auto-shift-single-working-days-container">
              <input type="checkbox" id="shiftTuesday" />
              <label htmlFor="shiftTuesday">Tuesday</label>
            </div>
            <div className="auto-shift-single-working-days-container">
              <input type="checkbox" id="shiftWednesday" />
              <label htmlFor="shiftWednesday">Wednesday</label>
            </div>
            <div className="auto-shift-single-working-days-container">
              <input type="checkbox" id="shiftThursday" />
              <label htmlFor="shiftThursday">Thursday</label>
            </div>
            <div className="auto-shift-single-working-days-container">
              <input type="checkbox" id="shiftFriday" />
              <label htmlFor="shiftFriday">Friday</label>
            </div>
            <div className="auto-shift-single-working-days-container">
              <input type="checkbox" id="shiftSaturday" />
              <label htmlFor="shiftSaturday">Saturday</label>
            </div>
          </div>

          <div className="auto-shift-button-container">
            <button
              onClick={() => setAutoShiftDialog(false)}
              className="btn btn-red-outlined"
            >
              Cancel
            </button>
            <button className="btn btn-primary">Submit</button>
          </div>
        </div>
      </Dialog>

      {/* manual shift dialog */}
      <Dialog open={manualShiftDialog} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setManualShiftDialog(false)}>
        <div className="auto-shift-dialog-container manual-shift-container">
          <div className="manual-shift-top-container">
            <h3>Add Manual Shift</h3>
            <div>
              <input 
                 type="checkbox" 
                 id="isbranchWise" 
                 style={{accentColor:"red",cursor:"pointer"}} 
                 onChange={(e)=>setIsBranchWise(e.target.checked)}
                 checked={isBranchWise}
                 />
              <label htmlFor="isbranchWise" style={{fontSize:"0.8rem",fontWeight:600,color:"red",cursor:"pointer"}}>Employee Wise</label>
              {
                isBranchWise === true &&
              <p style={{fontSize:"0.7rem",color:"red",width:"92%"}}>Shift Will be enabled For Each Selected Employees</p>

              }
            </div>
            <div
              className="close-icon-manual-shift"
              onClick={closeManualShift}
            >
              <CloseIcon className="close-icon" />
            </div>
          </div>
          <div className="global-single-input auto-shift-outlet-input auto-complete">
            <p>Branch</p>
            {/* <input type="text" placeholder="Select Employee" onClick={manualShiftAddEmployeeDialogOpen}/> */}
            <Autocomplete
              // size="small"
              // value={selectedEmployees}
              options={allBranchesList || [{ branchName: "No data" }]}
              getOptionLabel={(option) => `${option?.branchName}`}
              renderInput={(params) => (
                <TextField {...params} placeholder="select Branch..." />
              )}
              onChange={(e, newValue) => setSelectedBranchObj(newValue)}
            />
          </div>
          <div className="global-single-input auto-shift-outlet-input auto-complete">
            <p>Employee</p>
            {/* <input type="text" placeholder="Select Employee" onClick={manualShiftAddEmployeeDialogOpen}/> */}
            <Autocomplete
              // size="small"
              value={selectedEmployees}
              multiple
              options={filteredEmpData || [{ staff_name: "No data" }]}
              disableCloseOnSelect
              getOptionLabel={(option) => `${option?.staff_name}`}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option?.staff_name}
                </li>
              )}
              // style={{ width: 500 }}
              renderInput={(params) => (
                <TextField {...params} placeholder="select employees" />
              )}
              onChange={(e, newValue) => setSelectedEmployees(newValue)}
            />
          </div>
          <h4>Current Date</h4>
          <div className="global-single-input auto-shift-outlet-input">
            <p>Date</p>
            <input type="date" value={today} disabled />
          </div>
          {/* <div className="global-single-input auto-shift-outlet-input">
            <p>Start Time</p>
            <input type="time" />
          </div> */}
          <div className="manual-shift-btn-container">
            <button
              // onClick={() => setManualShiftDialog(false)}
              onClick={addShiftFinalSubmit}
              className="btn btn-primary"
            >
              Submit
            </button>
          </div>
        </div>
      </Dialog>

      {/* add employee dialog */}
      <Dialog open={manualShiftAddEmployeeDialog} onKeyDown={(e)=>e.key === "Escape" && setManualShiftAddEmployeeDialog(false)}>
        <div className="manual-shift-select-employee-container">
          <div className="label-search-box-container">
            <h3>Select Employee</h3>
            <input type="text" placeholder="Search Employee..." />
            <SearchIcon className="search-icon-manual-shift" />
          </div>

          <div className="employee-checkbox-container">
            <input type="checkbox" id="manual-shift-employee" />
            <label htmlFor="manual-shift-employee">Akshay</label>
          </div>
          <div className="employee-checkbox-container">
            <input type="checkbox" id="manual-shift-employee" />
            <label htmlFor="manual-shift-employee">Akshay</label>
          </div>
          <div className="employee-checkbox-container">
            <input type="checkbox" id="manual-shift-employee" />
            <label htmlFor="manual-shift-employee">Akshay</label>
          </div>
          <div className="employee-checkbox-container">
            <input type="checkbox" id="manual-shift-employee" />
            <label htmlFor="manual-shift-employee">Akshay</label>
          </div>
          <div className="employee-checkbox-container">
            <input type="checkbox" id="manual-shift-employee" />
            <label htmlFor="manual-shift-employee">Akshay</label>
          </div>
          <div className="employee-checkbox-container">
            <input type="checkbox" id="manual-shift-employee" />
            <label htmlFor="manual-shift-employee">Akshay</label>
          </div>

          <div className="manual-shift-select-employee-dialog">
            <button
              onClick={() => setManualShiftAddEmployeeDialog(false)}
              className="btn btn-secondary-outlined"
            >
              Cancel
            </button>
            <button className="btn btn-secondary">Submit</button>
          </div>
        </div>
      </Dialog>

      <SuccessSnackbar
        open={openSuccessSnack}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={openErrorSnack}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </div>
  );
};
