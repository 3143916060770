import axios from "axios"
import store from "../../../Redux/store"

import { add_contract_type, get_contract_list, get_contract_single_id, get_contract_type_list, get_sponser_id, get_sponser_list } from "./contractSlice";
import { HEADERS, HEADER_QUERY } from "../../../API/UrlAndPaths";


//list contarct
export const ListContractAPI = async () => {
    await axios.post("employee/viewContract", {}, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(get_contract_list(res.data))
            }
        })
        .catch((err) => {
            store.dispatch(get_contract_list(undefined))
        })
}
//add contract
export const AddContractAPI = async (body,setSuccess,setError,setMsg) => {
    await axios.post("employee/addContract", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                setSuccess(true)
                setMsg("New Contract Added")
                singleViewContractAPI({_id:res.data._id}) 
            }
        })
        .catch((err) => {
            setError(true)
                setMsg("Something Went Wrong")
        })
}

//singleView contract
export const singleViewContractAPI = async (body) => {
    await axios.post("employee/viewContractById", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(get_contract_single_id(res.data))
           
            }
        })
        .catch((err) => {
            store.dispatch(get_contract_single_id(undefined))
        })
}
//edit contract
export const editContractAPICall =async(body,draftFn,setSuccess,setError,setMsg)=>{
    await axios.put("employee/editContract",body,HEADERS)
                .then((res)=>{
                  if(res.status===200){
                    setSuccess(true)
                    setMsg("Edited Succesfully")
                    draftFn()
                    ListContractAPI()
                  }
                })
                .catch((err)=>{
                  console.error(err.response);
                  setError(true)
                  setMsg("Already Exist")
                })
  }

//add contarct type
export const AddContractTypeAPI = async (body,setSuccess,setError,setMsg) => {
    await axios.post("employee/addContractType", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                setSuccess(true)
                setMsg("New Contract Type Added")
                ListContractTypeAPI()
            }
        })
        .catch((err) => {
            setError(true)
                setMsg("Something Went Wrong")
        })
}
//list contract type
export const ListContractTypeAPI = async () => {
    await axios.post("employee/viewContractType", {}, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(get_contract_type_list(res.data))
            }
        })
        .catch((err) => {
            store.dispatch(get_contract_type_list(undefined))
        })
}
//contract type edit 

export const editConTypeAPICall =async(body,setSuccess,setError,setMsg)=>{
    await axios.put("employee/editContractType",body,HEADERS)
                .then((res)=>{
                  if(res.status===201){
                    setSuccess(true)
                    setMsg("Edited Succesfully")
                    ListContractTypeAPI()
                  }
                })
                .catch((err)=>{
                  console.error(err.response);
                  setError(true)
                  setMsg("Already Exist")
                })
  }

//delete contract type
  export const delConTypeAPICall =async(body,setError,setMsg)=>{
    await axios.delete("employee/deleteContractType",{
        headers:HEADER_QUERY,
        data:body
      })
    .then((res)=>{
      if(res.status===201){
        setError(true)
        setMsg("Document Type Deleted")
        ListContractTypeAPI()
      }
    })
    .catch((err)=>{
      console.error(err.response)
    })
  }

  //list sponser
  
export const ListSponserAPI = async () => {
    await axios.post("employee/viewSponsor", {}, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(get_sponser_list(res.data))
            }
        })
        .catch((err) => {
            store.dispatch(get_sponser_list(undefined))
        })
}

//add sponser

export const AddSponseAPI = async (body,setSuccess,setError,setMsg) => {
    await axios.post("employee/addSponsor", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                setSuccess(true)
                setMsg("New Sponser Added")
                ListSponserAPI()
                singleViewSponserAPI({_id:res.data?._id})
            }
        })
        .catch((err) => {
            setError(true)
                setMsg("Something Went Wrong")
        })
}

//singleView sponser
export const singleViewSponserAPI = async (body) => {
    await axios.post("employee/viewSponsorById", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(get_sponser_id(res.data))
            }
        })
        .catch((err) => {
            store.dispatch(get_sponser_id(undefined))
        })
}


//edit sponser 
export const editSponserAPICall =async(body,draftFn,setSuccess,setError,setMsg)=>{
    await axios.put("employee/editSponsor",body,HEADERS)
                .then((res)=>{
                  if(res.status===201){
                    setSuccess(true)
                    setMsg("Edited Succesfully")
                    draftFn()
                    ListSponserAPI()
                  }
                })
                .catch((err)=>{
                  console.error(err.response);
                  setError(true)
                  setMsg("Already Exist")
                })
  }