import { createSlice } from "@reduxjs/toolkit";

export const collarSlice=createSlice({
    name:"collarSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        update_collar:(state,action)=>{
            state.value=action.payload.collar
        }
    }
})

export const {update_collar}=collarSlice.actions
export default collarSlice.reducer