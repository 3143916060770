import React from "react";
import { Dialog, DialogTitle } from "@mui/material";
import "../../css/Single Components/addCategoryPopup.css";
import { Category } from "@mui/icons-material";

export const AddCategoryPopup = (props) => {
  const {
    open,
    label,
    closePopup,
    value,
    onChange,
    addBtnClick,
    categoryName,
  } = props;
  return (
    <>
      <Dialog open={open} maxWidth="lg" onKeyDown={(e) => {
                e.key === "Escape" && closePopup();
              }}
             
              >
        <div className="add-popup-container">
          <p>{label}</p>
          {categoryName !== undefined && (
            <div className="category-info">
              <span>Category Name : &nbsp;</span>
              <span>{categoryName}</span>
            </div>
          )}
          <div className="add-popup-input-container">
            <input
              placeholder={label + "..."}
              value={value}
              onChange={onChange}
              onKeyDown={(e) => {
                e.key === "Enter" && addBtnClick();
              }}
            />
            <button
              disabled={value !== "" ? false : true}
              onClick={addBtnClick}
              className={value !== "" ? "" : "disabled"}
            >
              +
            </button>
          </div>
          <div className="add-popup-table">
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>{props.children}</tbody>
            </table>
          </div>
          <div className="add-popup-button-container">
            <button onClick={closePopup} className="btn btn-secondary">
              Close
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};
