import { createSlice } from "@reduxjs/toolkit";

export const stockAdjustmentConfirmListSlice=createSlice({
    name:'stockAdjustmentConfirmListSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_stockAdjConfirm_list:(state,action)=>{
            state.value=action.payload.stockAdjConfirmList
        }
    }
})

export const {update_stockAdjConfirm_list}=stockAdjustmentConfirmListSlice.actions
export default stockAdjustmentConfirmListSlice.reducer