import { Autocomplete, Dialog, IconButton, MenuItem, Select, Skeleton, TextField } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { viewAllBranchesAPICall } from "../../../../API/Settings/Company Settings/companySettingsAPI";
import { approveLeaveApplicationsAPICall, deleteLeaveApplicationsAPICall, viewLeaveApplicationsAPICall } from "../../../../API/Staff/HRM/Leave/staffLeaveApplicationAPI";
import "../../../../css/Staff/Leave/staffLeaveApplication.css"
import { convertDateFormat } from "../../../../Js/Date";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
export const StaffLeaveApplication=()=>{

    const userRole = useSelector((state) => state.userRoleSlice.value);
    const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
    const userMeta=localStorage.getItem('login_meta')
    const userBranchId=JSON.parse(userMeta)?.branchId

    const [selected, setSelected] = useState('New');
    const [leaveApplicationView,setLeaveApplicationView]=useState(false)
    const [searchKeyword,setSearchKeyword] = useState("");
    const [leaveApplFiltered,setLeaveApplFiltered]=useState([])
    const [leaveSingleNote,setLeaveSingleNote]=useState("")
    const [leaveSingleStatus,setLeaveSingleStatus]=useState("")
    const [leaveSingleId,setLeaveSingleId]=useState("")
    const [singleLeaveView, setSingleLeaveView] = useState([]);
    const [isModify,setisModify]=useState(false)
    const [branchId,setBranchId]=useState(null)
    //Loading State
    const [isLoading, setIsLoading] = useState(false);
    const [errorSnackbar,setErrorSnackbar]=useState(false)
    const [snackMsg,setSnackMsg]=useState('Error!!')
    const viewLeaveApplications=useSelector((state)=>
        state.allLeaveApplicationSlice.value
    )
    
    
    const handleChange = event => {
        setSelected(event.target.value);
    };
    const leaveReasonSingleView=(reason,status,id)=>()=>{
        setLeaveApplicationView(true)
        setLeaveSingleNote(reason)
        setLeaveSingleStatus(status)
        setLeaveSingleId(id)
    }
    const updateListener=()=>{
        setisModify(!isModify)
    }
    
    const filterLeaveApplication=(chars,listToFilter)=>{
        let searchResult=[];
        let filteredArray=[];

        if(chars!==""){
            searchResult=listToFilter?.filter(
                (obj)=>
                obj?.employeeName?.toLowerCase()?.includes(chars?.toLowerCase())||
                obj?.employeeId?.toLowerCase()?.includes(chars?.toLowerCase())
                )
                filteredArray=searchResult
            }
            else{
                filteredArray=listToFilter
            }
            return filteredArray;
        }
    const viewSingleAppliedLeave=(id)=>{
        if(leaveApplFiltered !== undefined){
            const filteredSingleView=leaveApplFiltered?.filter((obj)=>{
                if(obj?._id === id){
                    return obj
                }
            })
            setSingleLeaveView(filteredSingleView)
        }
        }
    const leaveEmpApprovedBody ={
        type:0,
        _id:singleLeaveView[0]?._id
        }
    const leaveEmpRejectBody ={
        type:1,
        _id:singleLeaveView[0]?._id
        }


    const clickLeaveEmpApproved =()=>{
        approveLeaveApplicationsAPICall(
            leaveEmpApprovedBody
            )
            updateListener()
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Leave Has Been Approved',
                showConfirmButton: false,
            timer: 1500
            })
            setLeaveApplicationView(false)
        }
        //  click loan rejected
    const clickLeaveEmpRejected =()=>{
        approveLeaveApplicationsAPICall(
            leaveEmpRejectBody,
                )
            updateListener()
                Swal.fire(
                    'Rejected!',
                    'Leave Request Is Declined.',
                    'error'
                )
            setLeaveApplicationView(false)
            }
    const closeErrorSnackbar=()=>{
        setErrorSnackbar(false)
        }

    const deleteLeaveApplicationClicked=(id)=>()=>{
        deleteLeaveApplicationsAPICall({
            _id:id
        },updateListener,setErrorSnackbar,setSnackMsg)
    }
    useEffect(()=>{
        viewAllBranchesAPICall();
    },[])
    useEffect(()=>{
        if(userRole==="user"){
            setBranchId(userBranchId)
        }
    },[userRole])
    useEffect(()=>{
        viewLeaveApplicationsAPICall(
            {branchId:branchId},
            setIsLoading
            );
        },[branchId,isModify])
    useEffect(()=>{
        if(viewLeaveApplications !==undefined){
            setLeaveApplFiltered(viewLeaveApplications)
            let filteredArray=filterLeaveApplication(searchKeyword,viewLeaveApplications)
            setLeaveApplFiltered(filteredArray)
        }
        else{
            setLeaveApplFiltered([])
        }
    },[searchKeyword,viewLeaveApplications])
   return(
       <>
            <div className="global-page-parent-container">
                <p className="breadcrumb">Staff &gt; Leave &gt; Leave Applications</p>
                <div className="staff-leave-application-top">
                    <div className="staff-leave-application-top-left">
                        <h3>Total Leave Applications</h3>
                        <div className="staff-leave-application-top-section">

                            <div className="global-single-input auto-complete">
                               {
                                userRole==='admin' &&
                                   <Autocomplete 
                                   sx={{width:"100%"}}
                                   options={allBranchesList ||["No data"]}
                                   getOptionLabel={(option)=>option?.branchName}
                                   renderInput={(params)=>
                                    <TextField {...params}
                                    placeholder="Select a branch"/>
                                }
                                onChange={(e,newValue)=>setBranchId(newValue?.storeCode)}
                                />
                                }
                            </div>
                            <div className="view-order-search-container view-order-admin-search-container">
                                <input
                                placeholder="Search Employee,Employee ID"
                                value={searchKeyword}
                                onChange={(e)=>setSearchKeyword(e.target.value)}
                                />
                                <IconButton>
                                <i class="bi bi-search search-icon-order"></i>
                                </IconButton>
                            </div>
                        </div>    
                    </div>
                    <div className="staff-leave-application-top-right">
                        <div className="global-single-input">
                            <Select value={selected} onChange={handleChange}>
                                <MenuItem value="New">New</MenuItem>
                                <MenuItem value="Current">Current</MenuItem>
                            </Select>
                        </div>
                    </div>

                </div>

                <div className="global-table-container">
                    <table className="global-table">
                        <thead>
                            <tr>
                                <th>Employee ID</th>
                                <th>Employee Name</th>
                                <th>Image</th>
                                <th>Leave Type</th>
                                <th>Date From</th>
                                <th>Date To</th>
                                <th>Days</th>
                                <th>Applied On</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        {
                            isLoading?
                            <tbody>
                                <tr>
                                <td colSpan={10}>
                                    {/* <Loader /> */}
                                    <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    width={"100%"}
                                    height={40}
                                    />
                                </td>
                                </tr>
                                <tr>
                                <td colSpan={10}>
                                    {/* <Loader /> */}
                                    <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    width={"100%"}
                                    height={40}
                                    />
                                </td>
                                </tr>
                                <tr>
                                <td colSpan={10}>
                                    {/* <Loader /> */}
                                    <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    width={"100%"}
                                    height={40}
                                    />
                                </td>
                                </tr>
                                <tr>
                                <td colSpan={10}>
                                    {/* <Loader /> */}
                                    <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    width={"100%"}
                                    height={40}
                                    />
                                </td>
                                </tr>
                                <tr>
                                <td colSpan={10}>
                                    {/* <Loader /> */}
                                    <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    width={"100%"}
                                    height={40}
                                    />
                                </td>
                                </tr>
                            </tbody>
                            :
                            <tbody>{leaveApplFiltered?.length !==0?
                                leaveApplFiltered
                                ?.slice(0)
                                ?.reverse()
                                ?.map((r,i)=>(
                                    
                                    <tr key={i}>
                                    <td>{r?.employeeId}</td>
                                    <td>{r?.employeeName}</td>
                                    <td><img src={r?.imageUrl} alt="Emp Img" style={{width:"50px",height:"50px",borderRadius:"50%",marginTop:"3%"}}/></td>
                                    <td>{r?.leaveType}</td>
                                    <td>{convertDateFormat(r?.DateFrom)}</td>
                                    <td>{convertDateFormat(r?.DateTo)}</td>
                                    <td>{r?.days}</td>
                                    <td>{convertDateFormat(r?.appliedDate)}</td>
                                    <td>{r?.status==="APPROVED"?(
                                        <p className="status-loan-request-accepted">Approved</p>)
                                        :r?.status==="REJECTED"?(
                                        <p className="status-text-stock-out-reject">Rejected</p>)
                                        :(
                                            userRole==="admin" ?
                                            <>
                                                <button className="staff-table-check-box-tick"
                                                onClick={clickLeaveEmpApproved}
                                                onMouseDown={()=>viewSingleAppliedLeave(r?._id)}
                                                >
                                                <i class="bi bi-check2"></i></button>
    
                                                <button className="staff-table-check-box-wrong"
                                                onClick={clickLeaveEmpRejected}
                                                onMouseDown={()=>viewSingleAppliedLeave(r?._id)}
                                                >
                                                X</button>
                                            </>:<p className="status-text-stock-out">Pending</p>
                                            
                                        )
                                        }</td>
                                    <td>
                                        <IconButton onClick={leaveReasonSingleView(r?.reason,r?.status,r?._id)}>
                                            <i class="bi bi-eye visibility-icon"></i>
                                        </IconButton>
                                        {
                                        userRole==="admin" &&
                                        <>
                                        {
                                            r?.status==="APPROVED" ?
                                            <IconButton disabled className="disabled-icon-button"><i class="bi bi-trash3 delete-icon"></i></IconButton>
                                            :
                                            <IconButton onClick={deleteLeaveApplicationClicked(r?._id)}><i class="bi bi-trash3 delete-icon"></i></IconButton>
                                        }
                                        </>
                                        }
                                    </td>
                                </tr>
                                ))
                                :
                                (
                                    <tr>
                                        <td colSpan={10}>Select a Branch</td>
                                    </tr>)}
                            </tbody>
                        }


                        
                    </table>
                </div>

                <Dialog open={leaveApplicationView} maxWidth="lg" onClose={()=>setLeaveApplicationView(false)}>
                    <div className="leave-application-view-container">
                        <div className="leave-application-view-container-top">
                            <h3>Leave Application</h3>
                        </div>
                        <div className="leave-application-view-container-body">
                            <p>{leaveSingleNote}
                                </p>
                        </div>
                        <div className="leave-application-view-container-button">
                        
                             {leaveSingleStatus==="APPROVED"?(
                                    <p className="status-loan-request-accepted">Approved</p>)
                                    :leaveSingleStatus==="REJECTED"?(
                                    <p className="status-text-stock-out-reject">Rejected</p>)
                                    :(userRole==="admin" ?
                                        <>

                                        <button className="btn btn-red"
                                        onClick={clickLeaveEmpRejected}
                                        onMouseDown={()=>viewSingleAppliedLeave(leaveSingleId)}
                                        >
                                        Reject</button>
                                        <button className="btn btn-green"
                                        onClick={clickLeaveEmpApproved}
                                        onMouseDown={()=>viewSingleAppliedLeave(leaveSingleId)}
                                        >
                                        Accept</button>
                                        </>:<p className="status-text-stock-out">Pending</p>
                                    )
                                    }
                            {/* <button className="btn btn-red" onClick={()=>{setLeaveApplicationView(false)}}>Reject</button>
                            <button className="btn btn-green" onClick={()=>{setLeaveApplicationView(false)}}>Accept</button> */}
                        </div>
                    </div>
                </Dialog>
            </div>
            <SuccessSnackbar
            open={errorSnackbar}
            handleClose={closeErrorSnackbar}
            message={snackMsg}
            />
        </>
    )
}