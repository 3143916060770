import React from "react";
import InputBox from "../../../components/InputBox";
import VoucherTransactionPage from "../../../components/VoucherTransactionPage";
import TableBox from "../../../components/TableBox";
import TextAreaInputBox from "../../../components/TextAreaInputBox";

const ReceiptVoucher = ({ renderData, headings,selectedVoucher }) => {
  return (
    <div>
      <div
        style={{ display: "flex",flexWrap: 'wrap' }}
        className="accounting-voucher-menu-sections"
        
      >
         <h3>{selectedVoucher}</h3>
        <div className="accounting-payment-voucher-input-field">
          <InputBox label={"Invoice No"} />
          <InputBox label={"Posting Date"} type={"date"} />
          <InputBox label={"Due Date"} type={"date"} />
          <InputBox label={"Document Date"} type={"date"} />
          <InputBox label={"Document Name"} />
          <InputBox label={"Ref. No."} />
          <InputBox label={"Currency"} />
          <TextAreaInputBox label={"Remark"} />
        </div>
      </div>
      <VoucherTransactionPage />
      <div className="accounting-voucher-menu-sections">
        <TableBox
          isLoading={false}
          renderData={renderData}
          headings={headings}
          isNo={true}
        />
        <div class="button-container">
          <button>Save To Draft</button>
          <div>
            <button>Cancel</button>
            <button>Save</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceiptVoucher;
