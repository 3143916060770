import { createSlice } from "@reduxjs/toolkit";

export const priceListSlice = createSlice({
  name: "priceListSlice",
  initialState: {
    getApiSaveDataResult: undefined,
    priceListData:[],
    singleViewData:[]
  },
  reducers: {
    getPriceList: (state, action) => {
      state.getApiSaveDataResult = action.payload;
    },
    listPriceList: (state, action) => {
      state.priceListData = action.payload;
    },
    singleView: (state, action) => {
      state.singleViewData = action.payload;
    }
  },
});

export const {
    getPriceList,listPriceList,singleView
} = priceListSlice.actions;

export default priceListSlice.reducer;
