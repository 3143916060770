import { createSlice } from "@reduxjs/toolkit";

export const viewPostedSlice=createSlice({
    name:'grnPostedListSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_grn_posted_list:(state,action)=>{
            state.value=action.payload.grnPostedList
        }
    }
})
export const {get_grn_posted_list}=viewPostedSlice.actions;
export default viewPostedSlice.reducer;