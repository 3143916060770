import React, { useState } from "react";
import "../../../../../css/Purchase Manager/StockAdjustment/stockAdjustmentList.css";
import { IconButton, Dialog, Skeleton } from "@mui/material";
import { useEffect } from "react";
import { viewAllStockAdjustmentAPICall } from "../../../../../API/Purchase Manager/stockAdjustmentAPI";
import { useSelector } from "react-redux";
import { extractObjectForSingleView } from "../../../../../Js/generalFunctions";
import { convertDateFormat } from "../../../../../Js/Date";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
export const StockAdjustmentList = () => {
  //Current branch ID
  const bId = localStorage.getItem("branchId");
  const [stockAdjustmentListView, setStockAdjustmentListView] = useState(false);
  //*Redux States
  const allStockAdjList = useSelector((state) => state.allStockAdjSlice.value);

  //Single view Obj
  const [singleViewObj, setSingleViewObj] = useState([]);
  // loading states
  const [isLoading,setIsLoading]=useState(false)
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!");
  const [searchKeyword,setSearchKeyword]=useState("")
  const [filteredSearchList,setFilteredSearchList]=useState([])
  //Close ErrorSnackbar
  const closeErrorSnackbar = () => {
    setErrorSnackOpen(false);
  };
  //*useEffects
  //initial API Calls
  useEffect(() => {
    viewAllStockAdjustmentAPICall(
      { toLoc: bId !== "null" ? bId : null },
      setIsLoading,
      setErrorSnackOpen,
      setSnackMsg
          );
  }, []);
  const getSearchKeyword=(e)=>{
    setSearchKeyword(e.target.value)
  }
  const SearchFilter=(chars,listToFilter)=>{
    let searchedArray=[]
    let filteredArray=[]
    if(chars!==""){
      searchedArray=listToFilter.filter((obj)=>
        obj?.transNo?.toLowerCase()?.includes(chars?.toLowerCase())||
        obj?.branchId?.toLowerCase()?.includes(chars?.toLowerCase())||
        obj?.frmBranchName?.toLowerCase()?.includes(chars?.toLowerCase())||
        obj?.toBranchName?.toLowerCase()?.includes(chars?.toLowerCase())||
        obj?.glLinkCode?.code?.toLowerCase()?.includes(chars?.toLowerCase())
      )
      filteredArray=searchedArray
    }
    else{
      filteredArray=listToFilter
    }
    return filteredArray
  }
  useEffect(()=>{
    if(allStockAdjList!==undefined){
      let filteredArray=[]
      setFilteredSearchList(allStockAdjList)
      filteredArray=SearchFilter(searchKeyword,allStockAdjList)
      setFilteredSearchList(filteredArray)    
    }
    else{
      setFilteredSearchList([])
    }
  },[searchKeyword,allStockAdjList])
  //*Click Fns
  //Click Open Single View Icon Button
  const openSingleView = (id) => () => {
    setStockAdjustmentListView(true);
    if (allStockAdjList !== undefined) {
      let extractedObj = extractObjectForSingleView(id, allStockAdjList);
      setSingleViewObj(extractedObj);
    }
  };

  return (
    <div className="global-page-parent-container">
      <p className="breadcrumb">
        Purchase Manage &gt; Stock Adjustment &gt; Stock Adjustment List
      </p>
      <div className="stock-adjustment-list-container">
        <div className="stock-out-product-top-container" style={{alignItems:"center"}}>
          <h3>Stock Adjustment List</h3>
          <div className="view-order-search-container view-order-admin-search-container" style={{width:"30%"}}>
                <input
                  placeholder="Transaction ID,Branch,GL Link Code"
                  value={searchKeyword}
                  onChange={getSearchKeyword}
                />
                <IconButton>
                    <i class="bi bi-search search-icon-order"></i>
                </IconButton>
            </div>
          </div>
        <div className="global-table-container stock-adjustment-list-table">
          <table className="global-table">
            <thead>
              <tr>
                <th>Transaction ID</th>
                <th>Branch Code</th>
                <th>Branch Name</th>
                <th>From Location</th>
                <th>To Location</th>
                <th>Date</th>
                <th>GL Link Code</th>
                <th>Status</th>
                <th>View</th>
              </tr>
            </thead>
            {
              isLoading?
                <tbody>
                  <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr> 
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr> 
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr> 
                    <tr>
                    <td colSpan={11}>
                      {/* <Loader /> */}
                      <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                      />
                    </td>
                    </tr>
            </tbody>:
            <tbody>
              {filteredSearchList.length!==0?filteredSearchList?.slice(0)?.reverse()?.map((r, i) => (
                <tr key={i}>
                  <td>{r?.transNo}</td>
                  <td>{r?.branchId}</td>
                  <td>{r?.frmBranchName}</td>
                  <td>{r?.frmBranchName}</td>
                  <td>{r?.toBranchName}</td>
                  <td>{convertDateFormat(r?.date)}</td>
                  <td>{r?.glLinkCode?.code}</td>
                  <td>
                    <p
                      className={
                        r?.status === "Confirmed"
                          ? "status-confirm"
                          : r?.status === "Pending"
                          ? "status-pending"
                          : r?.status === "Recieved"
                          ? "status-received"
                          : r?.status === "Completed"
                          ? "status-completed"
                          : r?.status === "Drafted"
                          ? "status-drafted"
                          : undefined
                      }
                    >
                      {r?.status}
                    </p>
                  </td>
                  <td>
                    <IconButton onClick={openSingleView(r?._id)}>
                      <i class="bi bi-eye visibility-icon"></i>
                    </IconButton>
                  </td>
                </tr>
              )):<tr><td colSpan={9} style={{textAlign:"center"}}>No Records</td></tr>}
            </tbody>
            }
          </table>
        </div>
      </div>

      {/* stock adjustment view dialog */}
      <Dialog open={stockAdjustmentListView} maxWidth="lg"  onKeyDown={(e)=>e.key === "Escape" && setStockAdjustmentListView(false)}>
        <div className="stock-adjustment-list-view-container">
          <h3>View</h3>
          <div className="stock-adjustment-list-view-input-container">
            <div className="global-single-input">
              <p>Transaction ID</p>
              <input disabled value={singleViewObj[0]?.transNo} />
            </div>
            <div className="global-single-input">
              <p>Location From</p>
              <input disabled value={singleViewObj[0]?.frmBranchName} />
            </div>
            <div className="global-single-input">
              <p>Location To</p>
              <input disabled value={singleViewObj[0]?.toBranchName} />
            </div>
            <div className="global-single-input">
              <p>Date</p>
              <input disabled value={convertDateFormat(singleViewObj[0]?.date)} />
            </div>
            <div className="global-single-input">
              <p>GL Link Code</p>
              <div className="stock-adjustment-list-view-gl-code">
                <input
                  className="gl-link-code-first"
                  disabled
                  value={singleViewObj[0]?.glLinkCode?.code}
                />
                <input
                  className="gl-link-code-second"
                  disabled
                  value={singleViewObj[0]?.glLinkCode?.description}
                />
              </div>
            </div>
            <div className="global-single-input">
              <p>GL Code</p>
              <div className="stock-adjustment-list-view-gl-code">
                <input
                  className="gl-link-code-first"
                  disabled
                  value={singleViewObj[0]?.glCode?.code}
                />
                <input
                  className="gl-link-code-second"
                  disabled
                  value={singleViewObj[0]?.glCode?.description}
                />
              </div>
            </div>
            <div className="global-single-input">
              <p>GST Code</p>
              <div className="stock-adjustment-list-view-gl-code">
                <input
                  className="gl-link-code-first"
                  disabled
                  value={singleViewObj[0]?.gstCode?.code}
                />
                <input
                  className="gl-link-code-second"
                  disabled
                  value={singleViewObj[0]?.gstCode?.description}
                />
              </div>
            </div>
          </div>
          <hr className="global-hr-dotted" />
          <div className="global-table-container stock-adjustment-list-view-table">
            <table className="global-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Product Name</th>
                  <th>Stock Qty</th>
                  <th>Adjust Qty</th>
                  <th>Unit</th>
                  <th>Unit Cost</th>
                  <th>Total Cost</th>
                  {/* <th>Amount</th> */}
                </tr>
              </thead>
              <tbody>
                {singleViewObj[0]?.purchaseInfo?.map((r, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{r?.itemName}</td>
                    <td>{r?.closingstock}</td>
                    <td>{r?.adjQty}</td>
                    <td>{r?.unit}</td>
                    <td>{r?.unitCost?.toFixed(2)}</td>
                    <td>{r?.totalCost?.toFixed(2)}</td>
                    {/* <td>-0-</td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="stock-adjustment-list-view-button-container">
            <button
              onClick={() => setStockAdjustmentListView(false)}
              className="btn btn-secondary-outlined"
            >
              Close
            </button>
            {/* <button className="btn btn-secondary">Submit</button> */}
          </div>
        </div>
      </Dialog>
      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </div>
  );
};
