
import { createSlice } from "@reduxjs/toolkit";

export const customerCreditNoteSlice = createSlice({
  name: "customerCreditNoteSlice",
  initialState: {
    apiResList: undefined,
    rowData:undefined,
    generateName:undefined
  },
  reducers: {
    get_customer_credit_note_list: (state, action) => {
      state.apiResList = action.payload.customerCreditNoteData;
    },
    get_customer_credit_note_row_data_list: (state, action) => {
      state.rowData = action.payload.customerCreditNoteSingleData;
    },
    generate_customer_credit_note_name: (state, action) => {
      state.generateName = action.payload.customerCreditNoteName;
    }
  },
});

export const {get_customer_credit_note_list,get_customer_credit_note_row_data_list,generate_customer_credit_note_name } = customerCreditNoteSlice.actions;

export default customerCreditNoteSlice.reducer;
