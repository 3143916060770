import React, { useEffect, useState } from 'react'
import { viewAllCurrencyUpdated } from '../../../../API/Settings/Currency/CurrencyAPI';
import { companyProfileInfoForUser } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import { filterObjFromList } from '../../../../Js/generalFunctions';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IconButton, Skeleton } from '@mui/material';
import CustomPagination from '../../../Single Components/CustomPagination';
import { convertDateFormat } from '../../../../Js/Date';
import CategorySearchandFilter from '../../../Single Components/CategorySearchandFilter';
import { ShiftReportAPIcall } from './ShiftReportAPI';
import axios from 'axios';
import { setFilterActive } from '../../../Single Components/listSlice/filterCategorySlice';
import store from '../../../../Redux/store';

function ShiftReport() {
    let navigate = useNavigate();
    let location = useLocation()
    const filtereDataOptions = useSelector((state) => state.listCategory);
    const currencyListUpdated = useSelector(
        (state) => state.viewUpdatedCurrency.value
    );
    const companyProfileData = useSelector((state) => state?.companyProfileSlice?.userValue)

    const SingleListData = useSelector((state) => state?.shiftReport?.shiftReportList)

    const [FormDataInfo, setFormDataInfo] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [selectedFromDate, setSelectedFromDate] = useState(null);
    const [selectedToDate, setSelectedToDate] = useState(null);
    const handleBranchSelect = (selectedBranch) => {
        setSelectedBranch(selectedBranch);

    };
    const handleFromDateSelect = (selectedFromDate) => {
        setSelectedFromDate(selectedFromDate);

    };
    const handleToDateSelect = (selectedToDate) => {
        setSelectedToDate(selectedToDate);

    };
    const [symbol, setSymbol] = useState(null);
    const handleDataFromChild = (data) => {
        setFormDataInfo(data);
    };

    const totalPages = SingleListData?.pages;
    const decimalPosition = localStorage.getItem("decimalPosition");


    const handlePageChange = (page) => {

        setCurrentPage(page);
    };

    const handleClick = (row) => {
        store.dispatch( setFilterActive(window.location.pathname))
        localStorage.setItem("SingleViewId", row?._id);
        // navigate("/userdashboard/report/shiftReport/singleView", { state: { customerName: row?.customerName } })
        navigate("/userdashboard/report/shiftReport/singleView")
    };

    useEffect(() => {
        let currencyObj = filterObjFromList("_id", currencyListUpdated, "currency", companyProfileData)
        setSymbol(currencyObj?.symbol)
    }, [companyProfileData, currencyListUpdated])

    useEffect(() => {
        viewAllCurrencyUpdated({ status: 0 });
        companyProfileInfoForUser();
    }, [])
    useEffect(() => {
        
        if (FormDataInfo?.length !== 0) {
            let body = {
                fromDate: FormDataInfo?.fromDate === '' ? '' : `${FormDataInfo?.fromDate} 00:00:00`,
                toDate: FormDataInfo?.toDate === '' ? '' : `${FormDataInfo?.toDate} 23:59:59`,
                branchId: FormDataInfo?.branchList,
                search: FormDataInfo?.search,
                index: currentPage - 1
            }
            let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData && ShiftReportAPIcall(body, setIsLoading);
        }
        
    }, [FormDataInfo, currentPage])
    return (
        <div className="global-page-parent-container">
            {/* table */}
            <div className="global-white-bg-container">
                <div className='create-button-blue-container' style={{ margin: "1%", display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>

                    <div style={{ marginLeft: '1%' }}>
                        <h3>Shift Report</h3>
                    </div>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        //   width: '100%'
                    }}>
                        <CategorySearchandFilter
                            onData={handleDataFromChild}
                            style={{ width: "100%" }}
                            onBranchSelect={handleBranchSelect}
                            onFromDateSelect={handleFromDateSelect}
                            onToDateSelect={handleToDateSelect}


                        />
                        <IconButton
                            style={{ width: "100px" }}

                            className="print-icon-container">
                            <i class="bi bi-printer printer-icon"></i>
                        </IconButton>
                        <IconButton
                            style={{ width: "100px" }}
                            className="download-icon-container">
                            <i class="bi bi-arrow-down-circle download-icon"></i>
                        </IconButton>
                    </div>

                </div>



                <div className="create-button-blue-container">

                    {FormDataInfo?.branchList?.length > 0 && (
                        <h3 style={{ marginLeft: '1%' }}>
                            {selectedBranch?.storeCode || filtereDataOptions?.filterCategorylist?.branchList?.storeCode}
                            -{selectedBranch?.branchName || filtereDataOptions?.filterCategorylist?.branchList?.branchName}
                            {FormDataInfo?.fromDate?.length > 0 && FormDataInfo?.toDate?.length > 0 ? ' ' : null}
                        </h3>
                    )}
                    {FormDataInfo?.fromDate?.length > 0 && FormDataInfo?.toDate?.length > 0 && (
                        <h3>
                            {selectedFromDate?.fromDate || filtereDataOptions?.filterCategorylist?.fromDate}
                            TO {selectedToDate?.toDate || filtereDataOptions?.filterCategorylist?.toDate}
                        </h3>
                    )}


                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        gap: '9px',
                        width: FormDataInfo?.branchList?.length ? '62%' : '100%'
                    }}>
                        <CustomPagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                        />
                    </div>
                </div>
                <div className="customer-receipt-table-container">
                    <table>
                        <thead>
                            <tr>

                                <th>DATE</th>
                                <th>SHIFT</th>
                                <th>INVOICE AMOUNT</th>
                                <th>RECEIVED AMOUNT</th>
                                <th>CASH</th>
                                <th>BANK</th>
                                <th>CHEQUE</th>
                                <th>BALANCE</th>
                            </tr>

                        </thead>
                        <tbody>

                            {isLoading ? (
                                [...Array(10)].map((r, i) => (
                                    <tr key={i}>
                                        <td colSpan={10}>
                                            <Skeleton
                                                variant="rectangular"
                                                animation="wave"
                                                width={"100%"}
                                                height={40}
                                            />
                                        </td>
                                    </tr>
                                ))
                            ) : SingleListData?.report !== undefined && SingleListData?.report?.length !== 0 ? (
                                SingleListData?.report
                                    ?.slice(0)
                                    ?.map((r, i) => (
                                        <tr key={i} onClick={() => handleClick(r)} >

                                            <td>{convertDateFormat(r?.date)}</td>
                                            <td>{r?.shift}</td>
                                            <td>{r?.invAmt}</td>
                                            <td>{r?.receivedAmt}</td>
                                            <td>{r?.cash}</td>
                                            <td>{r?.bank}</td>
                                            <td>{r?.cheque}</td>
                                            <td>{r?.balance}</td>
                                        </tr>
                                    ))
                            ) : (
                                <tr>
                                    <td style={{ textAlign: 'center' }} colSpan={10}>No Data</td>
                                </tr>
                            )}

                        </tbody>
                        <tfoot >
                            <tr>
                                <th style={{ textAlign: 'start', fontSize: '1rem', paddingLeft: '3%' }} colSpan={2}>TOTAL</th>
                                <th >{SingleListData?.total !== undefined && SingleListData?.total?.invAmt?.toFixed(decimalPosition)}</th>
                                <th >{SingleListData?.total !== undefined && SingleListData?.total?.receivedAmt?.toFixed(decimalPosition)}</th>
                                <th >{SingleListData?.total !== undefined && SingleListData?.total?.cash?.toFixed(decimalPosition)}</th>
                                <th >{SingleListData?.total !== undefined && SingleListData?.total?.bank?.toFixed(decimalPosition)}</th>
                                <th >{SingleListData?.total !== undefined && SingleListData?.total?.cheque?.toFixed(decimalPosition)}</th>
                                <th >{SingleListData?.total !== undefined && SingleListData?.total?.balance?.toFixed(decimalPosition)}</th>

                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ShiftReport