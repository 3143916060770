import { createSlice } from "@reduxjs/toolkit";

export const getAllTransferNoSlice=createSlice({
    name:'allTransferNoSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_all_transfer_no:(state,action)=>{
            state.value=action.payload.allTransferNoList
        }
    }
})
export const {get_all_transfer_no} = getAllTransferNoSlice.actions;
export default getAllTransferNoSlice.reducer