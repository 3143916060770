import React from "react";
import "../Configuration/configuration.css";
import {
  Autocomplete,
  IconButton,
  Skeleton,
  TextField,
  Tooltip,
} from "@mui/material";
import { useSelector } from "react-redux";
import { chartOfAccountListApiCall } from "../../../../API/AccountUpdated/chartOfAccountAPI";
import { journalListApiCall } from "../../../../API/AccountUpdated/journalAPI";
import { useEffect } from "react";
import { useState } from "react";
import { filterObjFromList } from "../../../../Js/generalFunctions";
import { setGlConfigAPICall, viewGlConfigAPICall } from "./glConfigAPI";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import { LoadingForm } from "../../../Single Components/LoadingForm";
export const Configuration = () => {
  const decimalPointArray = [
    { number: 2 },
    { number: 3 },
    { number: 4 },
    { number: 5 },
  ];
  const configurationList = useSelector(
    (state) => state.glConfigurationSlice.apiResList
  );
  const chartOfAccountList = useSelector(
    (state) => state.chartOfAccountsSlice.value
  );
  const journalList = useSelector((state) => state.journalSlice.value);
  // filtered accnt listes
  const journalPurchaseList = journalList?.filter(
    (item) => item.type === "purchase"
  );
  const journalSalesList = journalList?.filter(
    (item) => item.type === "sales"
  );
  const stockValuationAccList = chartOfAccountList?.filter(
    (item) => item.type === "Current asset"
  );
  const controlAndExpenseAccList = chartOfAccountList?.filter(
    (item) => item.type === "Cost of revenue" /*|| item.type === "Expenses"*/
  );
  const incomeAccList = chartOfAccountList?.filter(
    (item) => item.type === "Income"
  );
  const receivableList = chartOfAccountList?.filter(
    (item) => item.type === "Receivables"
  );
  const payableList = chartOfAccountList?.filter(
    (item) => item.type === "Payable"
  );
  const expenseAccList = chartOfAccountList?.filter(
    (item) => item.type === "Expenses"
  );
  const [validationAlert, setValidationAlert] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
  });
  const [formValues, setFormValues] = useState({
    stockJournal: null,
    stockValuationAcc: null,
    stockAdjustmentAcc: null,
    goodsInternalTransfer: null,
    purchaseJournal: null,
    purchaseControlAccount: null,
    salesJournal: null,
    salesExpenseAcc: null,
    incomeAcc: null,
    defaultReceivable: null,
    defaultPayable: null,
    decimalPoints: null,
    PurchaseDiscount: null,
    salesDiscount: null,
    financialYearEndDate: null,
    financialYearStartDate: "",
    accountsLockDate: null,
    stockDecimalPoint: null,
  });

  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      message: "",
    });
  };
  const getFormInfo = (key) => (e, newValue) => {
    setValidationAlert({});
    setFormValues({ ...formValues, [key]: newValue });
  };

  const payload = {
    inventory: {
      stockJournal: formValues.stockJournal?._id,
      stockValuationAccount: formValues.stockValuationAcc?._id,
      stockAdjustmentAccount: formValues?.stockAdjustmentAcc?._id,
      goodsInternalTransfer: formValues.goodsInternalTransfer?._id,
    },
    purchase: {
      purchaseJournal: formValues.purchaseJournal?._id,
      purchaseControlAccount: formValues.purchaseControlAccount?._id,
      purchaseDiscount: formValues.PurchaseDiscount?._id,
    },
    sales: {
      salesJournal: formValues.salesJournal?._id,
      salesExpenseAccount: formValues.salesExpenseAcc?._id,
      incomeAccount: formValues.incomeAcc?._id,
      salesDiscount: formValues.salesDiscount?._id,
    },
    accounts: {
      defaultPayable: formValues.defaultPayable?._id,
      defaultRecievable: formValues.defaultReceivable?._id,
      yearEndDate: formValues?.financialYearEndDate===''?'':`${formValues?.financialYearEndDate}  23:59:59`,
      accountsLockDate: formValues?.accountsLockDate===''?'':`${formValues?.accountsLockDate}  23:59:59`,
      decimalPoint: formValues?.decimalPoints?.number,
      stockDecimalPoint: formValues?.stockDecimalPoint?.number,
      yearStartDate:formValues?.financialYearStartDate===''?'':`${formValues?.financialYearStartDate}  00:00:00`,
    },
  };

  const saveBtnClick = () => {
    let message = "Select this field";

    if (formValues.stockJournal === null) {
      setValidationAlert({ ...validationAlert, stockJournal: message });
    } else if (formValues.stockValuationAcc === null) {
      setValidationAlert({ ...validationAlert, stockValuationAcc: message });
    } else if (formValues.stockAdjustmentAcc === null) {
      setValidationAlert({ ...validationAlert, stockAdjustmentAcc: message });
    } else if (formValues.goodsInternalTransfer === null) {
      setValidationAlert({
        ...validationAlert,
        goodsInternalTransfer: message,
      });
    } else if (formValues.purchaseJournal === null) {
      setValidationAlert({ ...validationAlert, purchaseJournal: message });
    } else if (formValues.purchaseControlAccount === null) {
      setValidationAlert({
        ...validationAlert,
        purchaseControlAccount: message,
      });
    } else if (formValues.PurchaseDiscount === null) {
      setValidationAlert({ ...validationAlert, PurchaseDiscount: message });
    } else if (formValues.salesJournal === null) {
      setValidationAlert({ ...validationAlert, salesJournal: message });
    } else if (formValues.salesExpenseAcc === null) {
      setValidationAlert({ ...validationAlert, salesExpenseAcc: message });
    } else if (formValues.incomeAcc === null) {
      setValidationAlert({ ...validationAlert, incomeAcc: message });
    } else if (formValues?.salesDiscount === null) {
      setValidationAlert({ ...validationAlert, salesDiscount: message });
    } else if (formValues.defaultReceivable === null) {
      setValidationAlert({ ...validationAlert, defaultReceivable: message });
    } else if (formValues.defaultPayable === null) {
      setValidationAlert({ ...validationAlert, defaultPayable: message });
    } else if (formValues?.decimalPoints === null) {
      setValidationAlert({ ...validationAlert, decimalPoints: message });
    }else if (formValues?.financialYearStartDate === "") {
      setValidationAlert({ ...validationAlert, financialYearStartDate: message });
    } else if (formValues?.financialYearEndDate === "") {
      setValidationAlert({ ...validationAlert, financialYearEndDate: message });
    }  else if (formValues?.accountsLockDate === "") {
      setValidationAlert({ ...validationAlert, accountsLockDate: message });
    } else {
      setGlConfigAPICall(payload, setSnackBarStates, snackBarStates,setLoadingForm);
    }
  };

  useEffect(() => {
    if (configurationList !== undefined) {
      
      setFormValues({
        ...formValues,
        stockJournal: filterObjFromList(
          "_id",
          journalList,
          "stockJournal",
          configurationList?.inventory
        ),
        stockValuationAcc: filterObjFromList(
          "_id",
          stockValuationAccList,
          "stockValuationAccount",
          configurationList?.inventory
        ),
        stockAdjustmentAcc: filterObjFromList(
          "_id",
          stockValuationAccList,
          "stockAdjustmentAccount",
          configurationList?.inventory
        ),
        goodsInternalTransfer: filterObjFromList(
          "_id",
          stockValuationAccList,
          "goodsInternalTransfer",
          configurationList?.inventory
        ),
        purchaseJournal: filterObjFromList(
          "_id",
          journalList,
          "purchaseJournal",
          configurationList?.purchase
        ),
        purchaseControlAccount: filterObjFromList(
          "_id",
          controlAndExpenseAccList,
          "purchaseControlAccount",
          configurationList?.purchase
        ),
        salesJournal: filterObjFromList(
          "_id",
          journalList,
          "salesJournal",
          configurationList?.sales
        ),
        salesExpenseAcc: filterObjFromList(
          "_id",
          controlAndExpenseAccList,
          "salesExpenseAccount",
          configurationList?.sales
        ),
        incomeAcc: filterObjFromList(
          "_id",
          incomeAccList,
          "incomeAccount",
          configurationList?.sales
        ),
        defaultReceivable: filterObjFromList(
          "_id",
          chartOfAccountList,
          "defaultRecievable",
          configurationList?.accounts
        ),
        defaultPayable: filterObjFromList(
          "_id",
          chartOfAccountList,
          "defaultPayable",
          configurationList?.accounts
        ),
        PurchaseDiscount: filterObjFromList(
          "_id",
          incomeAccList,
          "purchaseDiscount",
          configurationList?.purchase
        ),
        salesDiscount: filterObjFromList(
          "_id",
          controlAndExpenseAccList,
          "salesDiscount",
          configurationList?.sales
        ),
        decimalPoints: filterObjFromList(
          "number",
          decimalPointArray,
          "decimalPoint",
          configurationList?.accounts
        ),
        stockDecimalPoint: filterObjFromList(
          "number",
          decimalPointArray,
          "stockDecimalPoint",
          configurationList?.accounts
        ),
        financialYearEndDate: configurationList?.yearEndDate,
        financialYearStartDate: configurationList?.yearStartDate,
        accountsLockDate: configurationList?.accountsLockDate,
      });
      localStorage.setItem(
        "decimalPosition",
        configurationList?.accounts?.decimalPoint || 0
      );
    }
  }, [configurationList, journalList, chartOfAccountList]);

  console.log(configurationList);
  console.log(formValues);

  useEffect(() => {
    chartOfAccountListApiCall();
    journalListApiCall();
    viewGlConfigAPICall(setLoadingForm);
  }, []);

  return (
    <div className="global-page-parent-container">
      <p className="breadcrumb">
        Accounts &gt; Configuration &gt; GL Configurations
      </p>

      <div className="global-white-bg-container">
        <>
          <Tooltip title="Save">
            <IconButton onClick={saveBtnClick}>
              <i class="bi bi-floppy"></i>
            </IconButton>
          </Tooltip>
        </>
      </div>

      <div
        className="global-white-bg-container"
        style={{ paddingLeft: "2%", paddingBottom: "3%" }}
      >
        <p className="primary-text">GL Configurations</p>
        <div style={{ width: "15%" }}>
          <hr
            style={{ border: "none", borderBottom: "2px solid rgb(10 51 254)" }}
          />
        </div>

        <div className="accounts-configuration-ctn">
          <p className="head">Inventory</p>
          <div className="configuration-single-input global-single-input auto-complete">
            <p>Stock Journal</p>
            
              <Autocomplete
                options={journalList || []}
                getOptionLabel={(option) => option?.journalName}
                renderInput={(params) => (
                  <TextField {...params} placeHolder="select a journal" />
                )}
                value={formValues.stockJournal}
                onChange={getFormInfo("stockJournal")}
              />
          

            <p className="doc-validation-alert">
              {validationAlert?.stockJournal}
            </p>
          </div>
          <div className="configuration-single-input global-single-input auto-complete">
            <p>Stock Valuation Account</p>
           
              <Autocomplete
                options={stockValuationAccList || []}
                getOptionLabel={(option) => option?.accountName}
                renderInput={(params) => (
                  <TextField {...params} placeHolder="select an account" />
                )}
                value={formValues.stockValuationAcc}
                onChange={getFormInfo("stockValuationAcc")}
              />
          
            <p className="doc-validation-alert">
              {validationAlert?.stockValuationAcc}
            </p>
          </div>
          <div className="configuration-single-input global-single-input auto-complete">
            <p>Stock Adjustment Account</p>
            
              <Autocomplete
                options={stockValuationAccList || []}
                getOptionLabel={(option) => option?.accountName}
                renderInput={(params) => (
                  <TextField {...params} placeHolder="select an account" />
                )}
                value={formValues.stockAdjustmentAcc}
                onChange={getFormInfo("stockAdjustmentAcc")}
              />
          
            <p className="doc-validation-alert">
              {validationAlert?.stockAdjustmentAcc}
            </p>
          </div>
          <div className="configuration-single-input global-single-input auto-complete">
            <p>Goods Internal Transfer</p>
           
              <Autocomplete
                options={stockValuationAccList || []}
                getOptionLabel={(option) => option?.accountName}
                renderInput={(params) => (
                  <TextField {...params} placeHolder="select an account" />
                )}
                value={formValues.goodsInternalTransfer}
                onChange={getFormInfo("goodsInternalTransfer")}
              />
            
            <p className="doc-validation-alert">
              {validationAlert?.goodsInternalTransfer}
            </p>
          </div>
        </div>
        <div className="accounts-configuration-ctn">
          <p className="head">Purchase</p>
          <div className="configuration-single-input global-single-input auto-complete">
            <p>Purchase Journal</p>
          
              <Autocomplete
                options={journalPurchaseList || []}
                getOptionLabel={(option) => option?.journalName}
                renderInput={(params) => (
                  <TextField {...params} placeHolder="select a journal" />
                )}
                value={formValues.purchaseJournal}
                onChange={getFormInfo("purchaseJournal")}
              />
          
            <p className="doc-validation-alert">
              {validationAlert?.purchaseJournal}
            </p>
          </div>
          <div className="configuration-single-input global-single-input auto-complete">
            <p>Purchase Control Acount</p>
           
              <Autocomplete
                options={controlAndExpenseAccList || []}
                getOptionLabel={(option) => option?.accountName}
                renderInput={(params) => (
                  <TextField {...params} placeHolder="select an account" />
                )}
                value={formValues.purchaseControlAccount}
                onChange={getFormInfo("purchaseControlAccount")}
              />
          
            <p className="doc-validation-alert">
              {validationAlert?.purchaseControlAccount}
            </p>
          </div>

          <div className="configuration-single-input global-single-input auto-complete">
            <p>Purchase Discount</p>
          
              <Autocomplete
                options={incomeAccList || []}
                getOptionLabel={(option) => option?.accountName}
                renderInput={(params) => (
                  <TextField {...params} placeHolder="select an account" />
                )}
                value={formValues.PurchaseDiscount}
                onChange={getFormInfo("PurchaseDiscount")}
              />
          
            <p className="doc-validation-alert">
              {validationAlert?.PurchaseDiscount}
            </p>
          </div>
        </div>

        <div className="accounts-configuration-ctn">
          <p className="head">Sales</p>
          <div className="configuration-single-input global-single-input auto-complete">
            <p>Sales Journal</p>
           
              <Autocomplete
                options={journalSalesList || []}
                getOptionLabel={(option) => option?.journalName}
                renderInput={(params) => (
                  <TextField {...params} placeHolder="select a journal" />
                )}
                value={formValues.salesJournal}
                onChange={getFormInfo("salesJournal")}
              />
           
            <p className="doc-validation-alert">
              {validationAlert?.salesJournal}
            </p>
          </div>
          <div className="configuration-single-input global-single-input auto-complete">
            <p>Sales Expenses Acount</p>
           
              <Autocomplete
                options={controlAndExpenseAccList || []}
                getOptionLabel={(option) => option?.accountName}
                renderInput={(params) => (
                  <TextField {...params} placeHolder="select an account" />
                )}
                value={formValues.salesExpenseAcc}
                onChange={getFormInfo("salesExpenseAcc")}
              />
          
            <p className="doc-validation-alert">
              {validationAlert?.salesExpenseAcc}
            </p>
          </div>
          <div className="configuration-single-input global-single-input auto-complete">
            <p>Income Account</p>
           
              <Autocomplete
                options={incomeAccList || []}
                getOptionLabel={(option) => option?.accountName}
                renderInput={(params) => (
                  <TextField {...params} placeHolder="select an account" />
                )}
                value={formValues.incomeAcc}
                onChange={getFormInfo("incomeAcc")}
              />
           
            <p className="doc-validation-alert">{validationAlert?.incomeAcc}</p>
          </div>

          <div className="configuration-single-input global-single-input auto-complete">
            <p>Sales Discount</p>
           
              <Autocomplete
                options={controlAndExpenseAccList || []}
                getOptionLabel={(option) => option?.accountName}
                renderInput={(params) => (
                  <TextField {...params} placeHolder="select an account" />
                )}
                value={formValues?.salesDiscount}
                onChange={getFormInfo("salesDiscount")}
              />
           
            <p className="doc-validation-alert">
              {validationAlert?.salesDiscount}
            </p>
          </div>
        </div>

        <div className="accounts-configuration-ctn">
          <p className="head">Accounts</p>
          <div className="configuration-single-input global-single-input auto-complete">
            <p>Default Payable</p>
           
              <Autocomplete
                options={payableList || []}
                getOptionLabel={(option) => option?.accountName}
                renderInput={(params) => (
                  <TextField {...params} placeHolder="select an account" />
                )}
                value={formValues.defaultPayable}
                onChange={getFormInfo("defaultPayable")}
              />
           
            <p className="doc-validation-alert">
              {validationAlert?.defaultPayable}
            </p>
          </div>
          <div className="configuration-single-input global-single-input auto-complete">
            <p>Default Receivable</p>
          
              <Autocomplete
                options={receivableList || []}
                getOptionLabel={(option) => option?.accountName}
                renderInput={(params) => (
                  <TextField {...params} placeHolder="select an account" />
                )}
                value={formValues.defaultReceivable}
                onChange={getFormInfo("defaultReceivable")}
              />
           
            <p className="doc-validation-alert">
              {validationAlert?.defaultReceivable}
            </p>
          </div>
          <div
            className="configuration-single-input global-single-input auto-complete"
            style={{ width: "40%" }}
          >
            <p>Currency Decimal Points</p>
            <Autocomplete
              style={{ width: "40%" }}
              options={decimalPointArray || []}
              getOptionLabel={(option) => option?.number}
              renderInput={(params) => (
                <TextField {...params} placeHolder="select" />
              )}
              value={formValues.decimalPoints}
              onChange={getFormInfo("decimalPoints")}
            />
          </div>
          <div
            className="configuration-single-input global-single-input auto-complete"
            style={{ width: "40%" }}
          >
            <p>Stock Decimal Points</p>
            <Autocomplete
              style={{ width: "40%" }}
              options={decimalPointArray || []}
              getOptionLabel={(option) => option?.number}
              renderInput={(params) => (
                <TextField {...params} placeHolder="select" />
              )}
              value={formValues.stockDecimalPoint}
              onChange={getFormInfo("stockDecimalPoint")}
            />
          </div>
          <div
            className="configuration-single-input global-single-input auto-complete"
            style={{ width: "40%" }}
          >
            <p style={{ width: "50%" }}>Financial Year Start Date</p>
            <input
              type="date"
              style={{ width: "40%" }}
              value={formValues?.financialYearStartDate}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  financialYearStartDate: e.target.value,
                })
              }
            />
            <p className="doc-validation-alert">{validationAlert.financialYearStartDate}</p>
          </div>
          <div
            className="configuration-single-input global-single-input auto-complete"
            style={{ width: "40%" }}
          >
            <p style={{ width: "50%" }}>Financial Year End Date</p>
            <input
              type="date"
              style={{ width: "40%" }}
              value={formValues?.financialYearEndDate}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  financialYearEndDate: e.target.value,
                })
              }
            />

            <p className="doc-validation-alert">{validationAlert.financialYearEndDate}</p>
          </div>
          
          <div
            className="configuration-single-input global-single-input auto-complete"
            style={{ width: "40%" }}
          >
            <p style={{ width: "50%" }}>Accounts Lock Date</p>
            <input
              type="date"
              style={{ width: "40%" }}
              value={formValues?.accountsLockDate}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  accountsLockDate: e.target.value,
                })
              }
            />
          </div>
        </div>
      </div>

      <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />

      <LoadingForm loading={loadingForm}/>
    </div>
  );
};
