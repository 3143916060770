import { createSlice } from "@reduxjs/toolkit";

export const settingReport = createSlice({
    name:"settingReport",
    initialState:{
        templateSelect:undefined
    },
    reducers:{
        setTemplate:(state,action)=>{
            state.templateSelect=action.payload.templatedSetting
        }
    }
})
export const {setTemplate}=settingReport.actions;
export default settingReport.reducer;