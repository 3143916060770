import {
  Autocomplete,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { useEffect } from "react";
import { editUpdatedGRNDraftAPICall, generateGrnUpdatedNameAPICall, postUpdatedGRNAPICall } from "./GRNUpdated/grnListingAPI";
import {vieWareHouseLocationAPICall} from "../../Inventory/Configuration/wareHouseLocationAPI"
import { today } from "../../../../Js/Date";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import { filterObjFromList } from "../../../../Js/generalFunctions";
import { addVendorBillAPICall } from "../../Accounts/Vendor/API/vendorBillAPI";
import { viewGlConfigAPICall } from "../../Accounts/Configuration/glConfigAPI";
export const NewGoodsReceivedNotes = () => {
  let navigateTo = useNavigate();

  const editedData=useSelector((state)=> state.goodsReceivedNoteUpdatedSlice.rowData);



  const locationList=useSelector((state)=>state.wareHouseLocationSlice.value)
  const configurationList = useSelector((state) => state.glConfigurationSlice.apiResList)

  const generatedName = useSelector((state)=>state.goodsReceivedNoteUpdatedSlice.generateName);

  const [formAddedTable, setFormAddedTable] = useState([]);
  const [validationAlert, setValidationAlert] = useState({});
  const [rowIndex, setRowIndex] = useState(-1);
  const [editClicked, setEditClicked] = useState(false);

  const [draftName,setDraftName]=useState("###");

  const formInitialState = {
      status:"",
      purchaseOrder:null,
      branch:null,
      location:null,
      vendor:null,
      date:today,
      reference:"",

      purchaseId:"",
      purchaseDate:"",
      vendorId:"",
      id:"",
      journalId:"",
      chartOfAccountId:""

  };
  const [formValues, setFormValues] = useState(formInitialState);

  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
    alert:false
  });
  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      alert: false,
      message: "",
    });
  };
  const getFormInfo=(key)=>(e,newValue)=>{
    setValidationAlert({})
    setEditClicked(true)
    const {value}=e.target;
      setFormValues({...formValues,[key]:value!==0?value:newValue})
  }



  const handleReceivedQtyChange = (qty,index) => (e) => {
    const { value } = e.target;
    setRowIndex(index)
    setValidationAlert({});
    const receivedQty = Number(value);

    if (receivedQty > qty) {
        setValidationAlert({...validationAlert,maxQty:true});
    }
    formAddedTable[index].recievedQuantity =receivedQty > qty?qty: Number(value);
    setFormAddedTable([...formAddedTable]);
  };

  const payload={
    _id:formValues.id,
    branchId: formValues?.branch,
    location: formValues?.location?._id,
    date: formValues?.date,
    reference: formValues?.reference,
    totalOrderQuantity: formAddedTable?.reduce((sum,item)=>sum+item?.orderQuantity,0),
    totalReceivedQuantity: formAddedTable?.reduce((sum,item)=>sum+item?.recievedQuantity,0),
    purchaseInfo:formAddedTable
}
const postPayload={
  _id: formValues.id,
  branchId: formValues?.branch,
  purchaseInfo:formAddedTable,
  location:formValues?.location?._id,
  total:formAddedTable?.reduce((sum,item)=>sum+item?.subTotal,0)
}
const generateBillPayload = {
  vendorId: formValues.vendorId,
  branchId: formValues.branch,
  accountingDate: formValues.accountingDate,
  itemInfo: formAddedTable?.map((item)=>({...item,chartOfAccountId:formValues?.chartOfAccountId})),
  unTaxedAmount: formValues?.unTaxedAmount,
  tax: formValues?.tax,
  total:formValues?.total,
  amountDue: 0,
  journelId:formValues?.journalId,
  billDate:today,
  billreference:draftName
};
const navigateAfterGeneration=()=>{
  navigateTo("/userdashboard/accounts/vendor/createvendorBills")
}
const postSuccessFn=()=>{
  setFormValues({...formValues,status:"Complete"})
}

const submitClick =(key)=> () => {
  if(formValues.location===null || formValues.location==="" || formValues.location===undefined){
    setValidationAlert({...validationAlert,location:"Select a location !!"})
  } 
  else if(formValues.date===""){
    setValidationAlert({...validationAlert,date:"Fill this field!!"})
  } 
  // else if(formValues.reference===""){
  //   setValidationAlert({...validationAlert,reference:"Fill this field !!"})
  // }
  else if(draftName==="###"){
    setValidationAlert({...validationAlert,draftName:"name not generated !!"})
  }
  else if(key==="generateBill" && formValues?.journalId===""){
    setSnackBarStates({...snackBarStates,message:"purchase journal id not fetched",alert:true})
  }
  else if(formValues?.reference===""){
    setValidationAlert({...validationAlert,reference:"fill this field !!"})
  }
  else{
    if(key==="edit"){
      editUpdatedGRNDraftAPICall(payload,setSnackBarStates,snackBarStates)
    }
    else if(key==="post"){
      postUpdatedGRNAPICall(postPayload,setSnackBarStates,snackBarStates,postSuccessFn)
    }
    else if(key==="generateBill"){
      addVendorBillAPICall(generateBillPayload,navigateAfterGeneration,setSnackBarStates,snackBarStates,navigateAfterGeneration)
    }
  }
};

useEffect(() => {
  setDraftName(generatedName===undefined?"###":generatedName)
  viewGlConfigAPICall()
}, [generatedName]);

useEffect(()=>{
  if(configurationList!==undefined){
    setFormValues({...formValues,
                  journalId:configurationList?.purchase?.purchaseJournal,
                  chartOfAccountId:configurationList?.purchase?.purchaseControlAccount
                })
  }
  else{
    setFormValues({...formValues,journalId:""})
  }
},[configurationList])
useEffect(()=>{
  if(locationList!==undefined){

    if(editedData?.location===""){
      locationList?.length===1 && 
      setFormValues({...formValues,location:locationList[0]})
    }else{
      setFormValues({...formValues,location:filterObjFromList("_id",locationList,"location",editedData)})
    }
    
  }
},[locationList])

  useEffect(()=>{
        setFormValues({
          ...formValues,
          purchaseId:editedData?.purchase_id,
          branch:editedData?.branchId,
          vendor:editedData?.supplierName,
          vendorId:editedData?.supplierId,
          purchaseDate:editedData?.purchaseDate,
          status:editedData?.status,
          purchaseOrder:editedData?.name,
          id:editedData?._id,
          reference:editedData?.reference,
          unTaxedAmount: editedData?.untaxedAmount,
          tax: editedData?.totalTax,
          total:editedData?.total,
        });
         const updatedPurchaseInfo = editedData?.purchaseInfo.map(item => {
        return {
          ...item,
          recievedQuantity:item.recievedQuantity===0? item.orderQuantity:item.recievedQuantity,
          dimension:item.unit,
        };
      });
      setFormAddedTable(updatedPurchaseInfo);
      generateGrnUpdatedNameAPICall({branchId:editedData?.branchId})
      vieWareHouseLocationAPICall({branchId:editedData?.branchId})
  },[editedData])

  return (
    <div className="global-page-parent-container">
      <p className="breadcrumb">Purchase Manage &gt; Goods Received Note &gt; Add GRN</p>
      <div
        className="global-white-bg-container justify-space-between"
        style={{ padding: "3px 1%" }}
      >
        <div style={{width:"70%"}}>
          <Tooltip title="Back">

            <IconButton onClick={()=>navigateTo("/userdashboard/purchaseManager/GoodsReceivedNote")}>

              <i class="bi bi-chevron-left"></i>
            </IconButton>
          </Tooltip>
          {formValues?.status==="Drafted" && (
            <IconButton
                  onClick={submitClick("edit")}
            >
              <i class="bi bi-pencil-square edit-icon1"></i>
            </IconButton>
          ) }
          { formValues?.status === "Complete" ? 
            <button
            onClick={submitClick("generateBill")}
            className="btn btn-primary" style={{width:"auto"}}>Generate Bill</button>:
            formValues?.status === "Drafted" ? 
            <button
            onClick={submitClick("post")}
            className="btn btn-secondary">Post</button>:""
          }
        </div>
        {
          <div
            style={{ width: "10%" }}
            className={
              formValues?.status === "Complete"
                ? "table-data-box-pending"
                : "table-data-box-drafted"
            }
          >
            {formValues?.status === "Complete" ? "Posted":formValues?.status === "Drafted"?"Drafted":"---"}
          </div>
        }
      </div>
      <div className="global-white-bg-container" style={{ padding: "0 1%" }}>
        <div className="global-input-field-container" style={{ padding: "0" }}>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              marginLeft: "1%",
              padding: "1% 0",
              width:"40%",
              position:"relative"
            }}
          >
            <h2 className="accounts-name-list" style={{ fontWeight: "bold" }}>
              Name
            </h2>
            <span className="accounts-name-list-value">{draftName}</span>
            <p
            style={{top:"50%",right:"auto"}}
            className="doc-validation-alert">{validationAlert?.draftName}</p>
          </div>
        </div>
        <div className="global-white-bg-container create-journal-container" style={{padding:"0"}}>
          <div className="global-single-input auto-complete create-joutnal-input"style={{width:"20%"}}>
            <p>Purchase Order</p>
            <input type="text" value={formValues.purchaseOrder} className="disabled-input"/>
            {/* <Autocomplete
              options={purchaseOrderList || []}
              getOptionLabel={(option) => option?.supplierName}
              renderInput={(params) => (
                <TextField {...params} placeholder="select purchase order" />
              )}
              value={formValues.purchaseOrder}
              onChange={getFormInfo("purchaseOrder")}
              disabled={formValues?.visibleOnly}
            /> */}
          </div>
          <div className="global-single-input auto-complete create-joutnal-input"style={{width:"20%"}}>
            <p>Branch</p>
            <input type="text" value={formValues.branch} className="disabled-input"/>
            {/* <Autocomplete
              options={allBranchesList || []}
              getOptionLabel={(option) => `${option?.storeCode}-${option?.branchName}`}
              renderInput={(params) => (
                <TextField {...params} placeholder="select branch" />
              )}
              value={formValues.branch}
              onChange={getFormInfo("branch")}
              disabled={formValues?.visibleOnly}
            /> */}
          </div>
          <div className="global-single-input auto-complete create-joutnal-input" style={{width:"20%"}}>
            <p>Location</p>
            <Autocomplete
              options={locationList || []}
              getOptionLabel={(option) => option?.name}
              renderInput={(params) => (
                <TextField {...params} placeholder="select location" />
              )}
              value={formValues.location}
              onChange={getFormInfo("location")}
              disabled={formValues?.status==="Complete"}
            />
            <p className="doc-validation-alert">{validationAlert?.location}</p>
          </div>

          <div className="global-single-input auto-complete create-joutnal-input" style={{width:"20%"}}>
            <p>Vendor</p>
            <input type="text" value={formValues.vendor} className="disabled-input"/>
            {/* <Autocomplete
              options={vendorList || []}
              getOptionLabel={(option) => option?.name}
              renderInput={(params) => (
                <TextField {...params} placeholder="select a vendor" />
              )}
              value={formValues.vendor}
              onChange={getFormInfo("vendor")}
              disabled={formValues?.visibleOnly}
            /> */}
          </div>
          <div className="global-single-input create-joutnal-input" style={{width:"20%"}}>
            <p>Date</p>
            <input type="date" 
              value={formValues.date}
              onChange={getFormInfo("date")}
              disabled={formValues?.status==="Complete"}
              />
            <p className="doc-validation-alert">{validationAlert?.date}</p>
          </div>
          <div className="global-single-input create-joutnal-input" style={{width:"20%"}}>
            <p>Reference</p>
            <input type="text" 
              value={formValues.reference}
              onChange={getFormInfo("reference")}
              disabled={formValues?.status==="Complete"}
            />
            <p className="doc-validation-alert">{validationAlert?.reference}</p>
          </div>
        </div>
      </div>
      <div className="global-white-bg-container add-table-selection">
        <div className="global-page-parent-container">
          <div className="purchase-order-main-container second-row-container">
            <div className="top-bar">
              <h3>Received Products</h3>
            </div>
            <div className="purchase-order-table-container global-table-container">
              <table className="global-table">
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Name</th>
                    <th>UOM</th>

                    <th>Order Quantity</th>
                    <th>Received Quantity</th>

                  </tr>
                </thead>
                
                  <tbody>
                    {formAddedTable?.length !== 0 ? (
                      formAddedTable
                        ?.map((r, i) => (
                          <tr key={i} style={{cursor:formValues?.status==="Complete" && "not-allowed"}}>
                            <td>{r?.code}</td>
                            <td>{r?.Description}</td>
                            <td>{r?.unit}</td>
                            <td style={{width:"20%"}}>{r?.orderQuantity}</td>
                            <td className='table-data-box' style={{width:"20%",textAlign:"center",position:"relative"}}>
                             {
                             formValues?.status==="Drafted"?
                             <input 
                             type="number"
                             id={i}
                             value={r?.recievedQuantity}
                              onChange={handleReceivedQtyChange(r?.orderQuantity,i)}
                              />:
                              r?.recievedQuantity
                              }
                              { 
                               rowIndex===i && validationAlert?.maxQty&&
                                <p
                                style={{top:"50%",right:"25%",fontWeight:"600"}}
                                className="doc-validation-alert">max : {r?.orderQuantity}</p>

                              }
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td colSpan={10}>No Data</td>
                      </tr>
                    )}
                
                  </tbody>
              </table>
            </div>
            {
              formAddedTable?.length!==0 &&
              <div className="net-amount-container" style={{ backgroundColor: "rgb(216 217 220)" }}>
                <div className="global-single-input grn-net-amount-inputs" style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                  <p style={{ width: "20%", color: "#000",textAlign:"center" }}>{formAddedTable?.reduce((sum,item)=>sum+item?.orderQuantity,0)}</p>
                  <p style={{ width: "20%", color: "#000",textAlign:"center" }}>{formAddedTable?.reduce((sum,item)=>sum+item?.recievedQuantity,0)}</p>
                </div>
              </div>
            }
          </div>
        </div>
      </div>

      <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <ErrorSnackbar
        style={{top:"8%",left:"34%",height:"20px",width:"35%"}}
        open={snackBarStates.alert}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
    </div>
  );
};
