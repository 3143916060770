import { createSlice } from "@reduxjs/toolkit";

export const readymadeOccasionSlice=createSlice({
    name:'readymadeOccasionSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        update_readymade_occasion:(state,action)=>{
            state.value=action.payload.readymadeOccasion
        }
    }
})

export const {update_readymade_occasion}= readymadeOccasionSlice.actions
export default readymadeOccasionSlice.reducer