import React, { useEffect, useState } from "react";
import "../../../../../css/Account/reports/financialReports.css";
import "../../../../../css/Account/reports/inventoryReports.css";
import "../../../../../css/Account/reports/payrollReport.css";
import "../../../../../css/Account/reports/bankReports.css";
import {
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import bankReportsGroup from "../../../../../Assets/JSON/bankReportsGroup.json";
import PostdatedChequeSummary from "./bankReportsGroup/PostdatedChequeSummary";
import ChequeRegister from "./bankReportsGroup/ChequeRegister";
import InstrumentWiseReport from "./bankReportsGroup/InstrumentWiseReport";

const BankReports = () => {
  const [active, setActive] = useState(0);

  useEffect(() => {
    handleListItemClick(0, 1, "Post dated Cheque Summary");
  }, []);

  const [selectedReport, setSelectedReport] = useState({ id: null, name: "" });

  const handleListItemClick = (index, id, name) => {
    setActive(index);
    setSelectedReport({ id, name });
  };

  const listItemTextStyle = () => {
    return {
      fontWeight: "600",
      fontSize: "0.9rem",
    };
  };
  
  return (
    <div className="global-page-parent-container">
      <p className="breadcrumb">
        Accounts &gt; Reports &gt; Bank Reports &gt; {selectedReport.name}
      </p>

      <div className="accounting-container">
        <div className="accounting-side-menu">
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              marginBlockStart: "10px",
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {bankReportsGroup.map(({ id, name }, index) => (
              <React.Fragment key={index}>
                <ListItemButton
                  sx={{ paddingLeft: "2em" }}
                  className={active === index ? "isactive" : ""}
                  onClick={() => handleListItemClick(index, id, name)}
                >
                  <ListItemText
                    primary={
                      <Typography sx={listItemTextStyle}>{name}</Typography>
                    }
                  />
                </ListItemButton>
              </React.Fragment>
            ))}
          </List>
        </div>
        <div className="accounting-voucher-menu">
          {selectedReport.id === 1 && (
            <PostdatedChequeSummary selectListItem={selectedReport.name} />
          )}
          {selectedReport.id === 2 && (
            <ChequeRegister selectListItem={selectedReport.name} />
          )}
          {selectedReport.id === 3 && (
            <InstrumentWiseReport selectListItem={selectedReport.name} />
          )}
        </div>
      </div>
    </div>
  );
};

export default BankReports;
