import { createSlice } from "@reduxjs/toolkit";

export const stockMoveSlice = createSlice({
  name: "stockMoveSlice",
  initialState: {
    apiResList: undefined,
    rowData:undefined
  },
  reducers: {
    get_stock_move_list: (state, action) => {
      state.apiResList = action.payload.stockMoveData;
    },
    get_stock_move_row_data_list: (state, action) => {
      state.rowData = action.payload.stockMoveSingleData;
    }
  },
});

export const { get_stock_move_list,get_stock_move_row_data_list} = stockMoveSlice.actions;

export default stockMoveSlice.reducer;
