import {createSlice} from '@reduxjs/toolkit'

export const stockAdjustmentTransNoSlice=createSlice({
    name:"stockAdjTransNo",
    initialState:{
        value:undefined
    },
    reducers:{
        get_stock_adj_transNo:(state,action)=>{
            state.value=action.payload.stockAdjTransNoData
        }
    }
})
export const {get_stock_adj_transNo} = stockAdjustmentTransNoSlice.actions;
export default stockAdjustmentTransNoSlice.reducer