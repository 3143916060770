import React from "react";
import InputBox from "../../../components/InputBox";
import DropdownInputBox from "../../../components/DropdownInputBox";
import AddIcon from "@mui/icons-material/Add";
import SubmitBtn from "../../../components/SubmitBtn";
import TableBox from "../../../components/TableBox";

const TransferMaterials = () => {
    const heading = [
        "No",
        "Item Name",
        " HSN/ SAC Code",
        "Batch No",
        "Mfg",
        "Expiry Date",
        "Rate",
        "Qty",
        "Total Amount",
      ];
    
      const renderData = [
        {
          itemName: "furniture",
          hsn: "000",
          batch: "12345",
          mfg: "11/06/23",
          expiryDate: "22/3/24",
          rate: "5000.00",
          qty: "10",
          totalAmount: "50000",
        },
        {
          itemName: "furniture",
          hsn: "000",
          batch: "12345",
          mfg: "11/06/23",
          expiryDate: "22/3/24",
          rate: "5000.00",
          qty: "10",
          totalAmount: "50000",
        },
        {
          itemName: "furniture",
          hsn: "000",
          batch: "12345",
          mfg: "11/06/23",
          expiryDate: "22/3/24",
          rate: "5000.00",
          qty: "10",
          totalAmount: "50000",
        },
        {
          itemName: "furniture",
          hsn: "000",
          batch: "12345",
          mfg: "11/06/23",
          expiryDate: "22/3/24",
          rate: "5000.00",
          qty: "10",
          totalAmount: "50000",
        },
      ];
    
  return (
    <>
      <div style={{ padding: "3em" }} className="global-white-bg-container">
        <div className="PhysicalStockVerification">
          <div className="PhysicalStockVerification-input-box-container">
            <strong>Inventory</strong>
            <hr className="global-hr" />
            <div>
              <InputBox label={"Invoice No"} />
              <InputBox label={"Date"} type={"date"} />
              <DropdownInputBox labelName={'Location ( From )'} />
              <DropdownInputBox labelName={'Location ( To )'} />
            </div>
          </div>

          <div
            style={{
              marginTop: "1em",
            }}
          >
            <div
              style={{
                marginBlockStart: " 0em",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <div style={{ width: "100%", display: "flex" }}>
                <DropdownInputBox labelName={"Item"} />
                <InputBox containerStyle={{ width: "35%" }} label={"Name"} />
                <InputBox containerStyle={{ width: "30%" }} label={"Rate"} />
                <InputBox
                  containerStyle={{ width: "30%" }}
                  label={"Discount(%)"}
                />
                <InputBox label={"CGST"} />
                <InputBox label={"SGST"} />
                <InputBox label={"IGST"} />
              </div>

              <div
                style={{
                  width: "50%",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "5px",
                }}
              >
                <InputBox containerStyle={{ width: "45%" }} label={"Total"} />

                <div className="voucher-add">
                  <button className="add-button">
                    <AddIcon />
                  </button>
                  <button className="delete-button">
                    <i class="bi bi-trash3 delete-icon"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <SubmitBtn
            // onClick={handleSubmit}
            containerStyle={{
              justifyContent: "center",
              marginBlockStart: "40px",
            }}
          />
        </div>
      </div>
      <div style={{ padding: "3em" }} className="global-white-bg-container">
        <TableBox
          isLoading={false}
          isNo={true}
          headings={heading}
          renderData={renderData}
          actionBtnIconEdit={true}
          actionButton={true}
        />
      <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBlockStart:'2em'
          }}
          className="button-container"
        >
          <div></div>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              style={{
                background: "#fff",
                border: "1px solid black",
                color: "black",
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
            <button
              style={{
                background: "#0011f8",
                border: "none",
                color: "white",
              }}
              className="submit-btn"
            >
              Transfer
            </button>
          </div>
          <div></div>
        </div>
      </div>
    </>
  )
}

export default TransferMaterials