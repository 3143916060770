import axios from "axios";
import jwtDecode from "jwt-decode";
import { update_login_response } from "../Redux/Login/loginResponseSlice";
import { update_userRole } from "../Redux/Login/userRoleSlice";
import { update_shift_status } from "../Redux/Settings/Shift/shiftStatusSlice";
import store from "../Redux/store";




export const TOKEN = localStorage.getItem("userToken");
//creates axios authorization token from local storage
axios.defaults.headers.common["Authorization"] = `Bearer ${
  TOKEN !== null && TOKEN
}`;




//Get Current Branch Code
export const getCurrentBranchCode = () => {
  let branchCode = null;
  if (axios.defaults.headers.common["Authorization"] !== null) {
    let decodedToken = jwtDecode(
      axios.defaults.headers.common["Authorization"]
    );
    branchCode = decodedToken?.branchId;
  }
  return branchCode;
};

//Get User Role from LC
const userRole=localStorage.getItem('userRole')
store.dispatch(update_userRole({userRole}))


//Get Login Response from local storage
const loginResponse=localStorage.getItem('login_meta')
store.dispatch(update_login_response({loginResponse:JSON.parse(loginResponse)}))

//Shift Status Persistance
const shiftStatus=localStorage.getItem('shiftStatus')
store.dispatch(update_shift_status({shiftStatus:JSON.parse(shiftStatus)}))

// const isTax=jwtDecode(TOKEN)?.isTax
// store.dispatch(tax_value({taxData:isTax}))
